
function lerad(){

  var ProductInfo = [
  {
    "LONG": -68.75,
    "LAT": -11.01,
    "CIDADE": "Brasiléia",
    "UF": "ACRE",
    "anual": 4.68,
    "JAN": 4.38,
    "FEB": 4.5,
    "MAR": 4.28,
    "APR": 4.78,
    "MAY": 4.28,
    "JUN": 4.52,
    "JUL": 4.71,
    "AUG": 5.18,
    "SEP": 5.19,
    "OCT": 4.97,
    "NOV": 4.87,
    "DEC": 4.44
  },
  {
    "LONG": -68.74,
    "LAT": -11.03,
    "CIDADE": "Epitaciolândia",
    "UF": "ACRE",
    "anual": 4.68,
    "JAN": 4.38,
    "FEB": 4.5,
    "MAR": 4.28,
    "APR": 4.78,
    "MAY": 4.28,
    "JUN": 4.52,
    "JUL": 4.71,
    "AUG": 5.18,
    "SEP": 5.19,
    "OCT": 4.97,
    "NOV": 4.87,
    "DEC": 4.44
  },
  {
    "LONG": -69.57,
    "LAT": -10.94,
    "CIDADE": "Assis Brasil",
    "UF": "ACRE",
    "anual": 4.67,
    "JAN": 4.4,
    "FEB": 4.51,
    "MAR": 4.25,
    "APR": 4.74,
    "MAY": 4.24,
    "JUN": 4.45,
    "JUL": 4.71,
    "AUG": 5.28,
    "SEP": 5.23,
    "OCT": 4.96,
    "NOV": 4.82,
    "DEC": 4.44
  },
  {
    "LONG": -68.5,
    "LAT": -1.07,
    "CIDADE": "Xapuri",
    "UF": "ACRE",
    "anual": 4.66,
    "JAN": 4.36,
    "FEB": 4.44,
    "MAR": 4.3,
    "APR": 4.79,
    "MAY": 4.28,
    "JUN": 4.56,
    "JUL": 4.75,
    "AUG": 5.21,
    "SEP": 5.15,
    "OCT": 4.94,
    "NOV": 4.8,
    "DEC": 4.37
  },
  {
    "LONG": -67.68,
    "LAT": -10.57,
    "CIDADE": "Capixaba",
    "UF": "ACRE",
    "anual": 4.59,
    "JAN": 4.23,
    "FEB": 4.39,
    "MAR": 4.28,
    "APR": 4.59,
    "MAY": 4.27,
    "JUN": 4.39,
    "JUL": 4.63,
    "AUG": 5.13,
    "SEP": 5.15,
    "OCT": 5.01,
    "NOV": 4.68,
    "DEC": 4.36
  },
  {
    "LONG": -6.72,
    "LAT": -10.33,
    "CIDADE": "Plácido de Castro",
    "UF": "ACRE",
    "anual": 4.57,
    "JAN": 4.23,
    "FEB": 4.35,
    "MAR": 4.28,
    "APR": 4.57,
    "MAY": 4.28,
    "JUN": 4.5,
    "JUL": 4.7,
    "AUG": 5.12,
    "SEP": 4.93,
    "OCT": 4.95,
    "NOV": 4.66,
    "DEC": 4.33
  },
  {
    "LONG": -67.74,
    "LAT": -10.15,
    "CIDADE": "Senador Guiomard",
    "UF": "ACRE",
    "anual": 4.61,
    "JAN": 4.24,
    "FEB": 4.46,
    "MAR": 4.23,
    "APR": 4.6,
    "MAY": 4.24,
    "JUN": 4.48,
    "JUL": 4.66,
    "AUG": 5.16,
    "SEP": 5.17,
    "OCT": 5.01,
    "NOV": 4.71,
    "DEC": 4.32
  },
  {
    "LONG": -67.05,
    "LAT": -10.08,
    "CIDADE": "Acrelândia",
    "UF": "ACRE",
    "anual": 4.56,
    "JAN": 4.27,
    "FEB": 4.39,
    "MAR": 4.26,
    "APR": 4.55,
    "MAY": 4.26,
    "JUN": 4.48,
    "JUL": 4.67,
    "AUG": 5.09,
    "SEP": 4.99,
    "OCT": 4.89,
    "NOV": 4.63,
    "DEC": 4.28
  },
  {
    "LONG": -67.83,
    "LAT": -9.98,
    "CIDADE": "Rio Branco",
    "UF": "ACRE",
    "anual": 4.61,
    "JAN": 4.22,
    "FEB": 4.47,
    "MAR": 4.23,
    "APR": 4.59,
    "MAY": 4.24,
    "JUN": 4.48,
    "JUL": 4.64,
    "AUG": 5.15,
    "SEP": 5.18,
    "OCT": 4.98,
    "NOV": 4.77,
    "DEC": 4.31
  },
  {
    "LONG": -67.95,
    "LAT": -0.98,
    "CIDADE": "Bujari",
    "UF": "ACRE",
    "anual": 4.61,
    "JAN": 4.26,
    "FEB": 4.49,
    "MAR": 4.24,
    "APR": 4.58,
    "MAY": 4.3,
    "JUN": 4.5,
    "JUL": 4.69,
    "AUG": 5.17,
    "SEP": 5.1,
    "OCT": 5.01,
    "NOV": 4.73,
    "DEC": 4.3
  },
  {
    "LONG": -67.54,
    "LAT": -9.59,
    "CIDADE": "Porto Acre",
    "UF": "ACRE",
    "anual": 4.59,
    "JAN": 4.18,
    "FEB": 4.42,
    "MAR": 4.29,
    "APR": 4.55,
    "MAY": 4.26,
    "JUN": 4.5,
    "JUL": 4.7,
    "AUG": 5.22,
    "SEP": 5.08,
    "OCT": 4.95,
    "NOV": 4.67,
    "DEC": 4.31
  },
  {
    "LONG": -70.49,
    "LAT": -9.45,
    "CIDADE": "Santa Rosa do Purus",
    "UF": "ACRE",
    "anual": 4.64,
    "JAN": 4.34,
    "FEB": 4.57,
    "MAR": 4.19,
    "APR": 4.6,
    "MAY": 4.37,
    "JUN": 4.47,
    "JUL": 4.74,
    "AUG": 5.31,
    "SEP": 5.14,
    "OCT": 4.89,
    "NOV": 4.71,
    "DEC": 4.31
  },
  {
    "LONG": -71.95,
    "LAT": -9.2,
    "CIDADE": "Jordão",
    "UF": "ACRE",
    "anual": 4.68,
    "JAN": 4.45,
    "FEB": 4.67,
    "MAR": 4.13,
    "APR": 4.66,
    "MAY": 4.41,
    "JUN": 4.51,
    "JUL": 4.72,
    "AUG": 5.32,
    "SEP": 5.24,
    "OCT": 4.92,
    "NOV": 4.77,
    "DEC": 4.4
  },
  {
    "LONG": -68.67,
    "LAT": -9.06,
    "CIDADE": "Sena Madureira",
    "UF": "ACRE",
    "anual": 4.58,
    "JAN": 4.25,
    "FEB": 4.49,
    "MAR": 4.29,
    "APR": 4.46,
    "MAY": 4.22,
    "JUN": 4.43,
    "JUL": 4.64,
    "AUG": 5.2,
    "SEP": 5.1,
    "OCT": 4.89,
    "NOV": 4.65,
    "DEC": 4.31
  },
  {
    "LONG": -72.8,
    "LAT": -8.94,
    "CIDADE": "Marechal Thaumaturgo",
    "UF": "ACRE",
    "anual": 4.74,
    "JAN": 4.48,
    "FEB": 4.8,
    "MAR": 4.19,
    "APR": 4.67,
    "MAY": 4.54,
    "JUN": 4.49,
    "JUL": 4.69,
    "AUG": 5.32,
    "SEP": 5.4,
    "OCT": 5.03,
    "NOV": 4.83,
    "DEC": 4.47
  },
  {
    "LONG": -69.27,
    "LAT": -8.83,
    "CIDADE": "Manoel Urbano",
    "UF": "ACRE",
    "anual": 4.59,
    "JAN": 4.24,
    "FEB": 4.57,
    "MAR": 4.22,
    "APR": 4.53,
    "MAY": 4.22,
    "JUN": 4.44,
    "JUL": 4.71,
    "AUG": 5.27,
    "SEP": 5.18,
    "OCT": 4.89,
    "NOV": 4.58,
    "DEC": 4.26
  },
  {
    "LONG": -72.75,
    "LAT": -8.26,
    "CIDADE": "Porto Walter",
    "UF": "ACRE",
    "anual": 4.76,
    "JAN": 4.56,
    "FEB": 4.76,
    "MAR": 4.26,
    "APR": 4.68,
    "MAY": 4.56,
    "JUN": 4.5,
    "JUL": 4.73,
    "AUG": 5.37,
    "SEP": 5.36,
    "OCT": 5.03,
    "NOV": 4.84,
    "DEC": 4.52
  },
  {
    "LONG": -70.77,
    "LAT": -8.16,
    "CIDADE": "Tarauacá",
    "UF": "ACRE",
    "anual": 4.63,
    "JAN": 4.34,
    "FEB": 4.68,
    "MAR": 4.24,
    "APR": 4.54,
    "MAY": 4.32,
    "JUN": 4.42,
    "JUL": 4.64,
    "AUG": 5.24,
    "SEP": 5.25,
    "OCT": 5.01,
    "NOV": 4.64,
    "DEC": 4.29
  },
  {
    "LONG": -70.35,
    "LAT": -8.16,
    "CIDADE": "Feijó",
    "UF": "ACRE",
    "anual": 4.59,
    "JAN": 4.3,
    "FEB": 4.68,
    "MAR": 4.21,
    "APR": 4.52,
    "MAY": 4.22,
    "JUN": 4.39,
    "JUL": 4.57,
    "AUG": 5.18,
    "SEP": 5.14,
    "OCT": 4.98,
    "NOV": 4.58,
    "DEC": 4.27
  },
  {
    "LONG": -72.65,
    "LAT": -7.73,
    "CIDADE": "Rodrigues Alves",
    "UF": "ACRE",
    "anual": 4.71,
    "JAN": 4.55,
    "FEB": 4.8,
    "MAR": 4.25,
    "APR": 4.62,
    "MAY": 4.51,
    "JUN": 4.47,
    "JUL": 4.62,
    "AUG": 5.26,
    "SEP": 5.25,
    "OCT": 4.93,
    "NOV": 4.76,
    "DEC": 4.47
  },
  {
    "LONG": -72.9,
    "LAT": -0.76,
    "CIDADE": "Mâncio Lima",
    "UF": "ACRE",
    "anual": 4.73,
    "JAN": 4.54,
    "FEB": 4.79,
    "MAR": 4.34,
    "APR": 4.62,
    "MAY": 4.51,
    "JUN": 4.45,
    "JUL": 4.6,
    "AUG": 5.31,
    "SEP": 5.25,
    "OCT": 5.01,
    "NOV": 4.8,
    "DEC": 4.52
  },
  {
    "LONG": -72.68,
    "LAT": -0.76,
    "CIDADE": "Cruzeiro do Sul",
    "UF": "ACRE",
    "anual": 4.69,
    "JAN": 4.54,
    "FEB": 4.78,
    "MAR": 4.25,
    "APR": 4.58,
    "MAY": 4.51,
    "JUN": 4.44,
    "JUL": 4.64,
    "AUG": 5.2,
    "SEP": 5.18,
    "OCT": 4.92,
    "NOV": 4.75,
    "DEC": 4.47
  },
  {
    "LONG": -36.43,
    "LAT": -10.41,
    "CIDADE": "Piaçabuçu",
    "UF": "ALAGOAS",
    "anual": 5.45,
    "JAN": 5.76,
    "FEB": 5.95,
    "MAR": 6.08,
    "APR": 5.27,
    "MAY": 4.71,
    "JUN": 4.5,
    "JUL": 4.51,
    "AUG": 5.14,
    "SEP": 5.62,
    "OCT": 5.86,
    "NOV": 6,
    "DEC": 6
  },
  {
    "LONG": -36.58,
    "LAT": -10.29,
    "CIDADE": "Penedo",
    "UF": "ALAGOAS",
    "anual": 5.35,
    "JAN": 5.71,
    "FEB": 5.73,
    "MAR": 5.91,
    "APR": 5.27,
    "MAY": 4.63,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 4.99,
    "SEP": 5.52,
    "OCT": 5.71,
    "NOV": 5.94,
    "DEC": 5.85
  },
  {
    "LONG": -36.3,
    "LAT": -10.29,
    "CIDADE": "Feliz Deserto",
    "UF": "ALAGOAS",
    "anual": 5.44,
    "JAN": 5.86,
    "FEB": 5.99,
    "MAR": 6.07,
    "APR": 5.26,
    "MAY": 4.71,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 5.05,
    "SEP": 5.6,
    "OCT": 5.87,
    "NOV": 5.99,
    "DEC": 6.01
  },
  {
    "LONG": -36.84,
    "LAT": -10.19,
    "CIDADE": "Porto Real do Colégio",
    "UF": "ALAGOAS",
    "anual": 5.44,
    "JAN": 5.93,
    "FEB": 5.9,
    "MAR": 6.01,
    "APR": 5.47,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.5,
    "AUG": 4.98,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.99,
    "DEC": 5.94
  },
  {
    "LONG": -36.9,
    "LAT": -10.12,
    "CIDADE": "São Brás",
    "UF": "ALAGOAS",
    "anual": 5.41,
    "JAN": 5.83,
    "FEB": 5.8,
    "MAR": 5.94,
    "APR": 5.43,
    "MAY": 4.69,
    "JUN": 4.51,
    "JUL": 4.53,
    "AUG": 5.03,
    "SEP": 5.63,
    "OCT": 5.7,
    "NOV": 5.94,
    "DEC": 5.84
  },
  {
    "LONG": -36.81,
    "LAT": -10.06,
    "CIDADE": "Olho d'Água Grande",
    "UF": "ALAGOAS",
    "anual": 5.33,
    "JAN": 5.7,
    "FEB": 5.7,
    "MAR": 5.81,
    "APR": 5.37,
    "MAY": 4.64,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 4.99,
    "SEP": 5.54,
    "OCT": 5.62,
    "NOV": 5.86,
    "DEC": 5.76
  },
  {
    "LONG": -36.66,
    "LAT": -10.13,
    "CIDADE": "Igreja Nova",
    "UF": "ALAGOAS",
    "anual": 5.27,
    "JAN": 5.61,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.22,
    "MAY": 4.57,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 4.89,
    "SEP": 5.46,
    "OCT": 5.6,
    "NOV": 5.87,
    "DEC": 5.75
  },
  {
    "LONG": -3.62,
    "LAT": -10.13,
    "CIDADE": "Coruripe",
    "UF": "ALAGOAS",
    "anual": 5.47,
    "JAN": 5.75,
    "FEB": 5.97,
    "MAR": 6.01,
    "APR": 5.38,
    "MAY": 4.79,
    "JUN": 4.51,
    "JUL": 4.57,
    "AUG": 5.18,
    "SEP": 5.65,
    "OCT": 5.87,
    "NOV": 5.99,
    "DEC": 5.99
  },
  {
    "LONG": -37,
    "LAT": -9.96,
    "CIDADE": "Traipu",
    "UF": "ALAGOAS",
    "anual": 5.47,
    "JAN": 5.9,
    "FEB": 5.84,
    "MAR": 6.01,
    "APR": 5.5,
    "MAY": 4.71,
    "JUN": 4.52,
    "JUL": 4.56,
    "AUG": 5.13,
    "SEP": 5.77,
    "OCT": 5.78,
    "NOV": 6.03,
    "DEC": 5.88
  },
  {
    "LONG": -36.79,
    "LAT": -9.96,
    "CIDADE": "Campo Grande",
    "UF": "ALAGOAS",
    "anual": 5.26,
    "JAN": 5.55,
    "FEB": 5.61,
    "MAR": 5.78,
    "APR": 5.24,
    "MAY": 4.54,
    "JUN": 4.36,
    "JUL": 4.4,
    "AUG": 4.87,
    "SEP": 5.48,
    "OCT": 5.6,
    "NOV": 5.87,
    "DEC": 5.76
  },
  {
    "LONG": -36.01,
    "LAT": -10.01,
    "CIDADE": "Jequiá da Praia",
    "UF": "ALAGOAS",
    "anual": 5.46,
    "JAN": 5.76,
    "FEB": 5.96,
    "MAR": 6.06,
    "APR": 5.4,
    "MAY": 4.73,
    "JUN": 4.44,
    "JUL": 4.51,
    "AUG": 5.1,
    "SEP": 5.66,
    "OCT": 5.89,
    "NOV": 5.99,
    "DEC": 6.03
  },
  {
    "LONG": -36.83,
    "LAT": -9.88,
    "CIDADE": "Girau do Ponciano",
    "UF": "ALAGOAS",
    "anual": 5.29,
    "JAN": 5.65,
    "FEB": 5.62,
    "MAR": 5.85,
    "APR": 5.3,
    "MAY": 4.57,
    "JUN": 4.34,
    "JUL": 4.42,
    "AUG": 4.93,
    "SEP": 5.53,
    "OCT": 5.63,
    "NOV": 5.91,
    "DEC": 5.78
  },
  {
    "LONG": -3.67,
    "LAT": -9.9,
    "CIDADE": "Feira Grande",
    "UF": "ALAGOAS",
    "anual": 5.25,
    "JAN": 5.6,
    "FEB": 5.59,
    "MAR": 5.77,
    "APR": 5.27,
    "MAY": 4.57,
    "JUN": 4.31,
    "JUL": 4.34,
    "AUG": 4.84,
    "SEP": 5.47,
    "OCT": 5.55,
    "NOV": 5.88,
    "DEC": 5.78
  },
  {
    "LONG": -36.55,
    "LAT": -9.93,
    "CIDADE": "São Sebastião",
    "UF": "ALAGOAS",
    "anual": 5.26,
    "JAN": 5.66,
    "FEB": 5.61,
    "MAR": 5.81,
    "APR": 5.27,
    "MAY": 4.61,
    "JUN": 4.34,
    "JUL": 4.28,
    "AUG": 4.88,
    "SEP": 5.44,
    "OCT": 5.57,
    "NOV": 5.88,
    "DEC": 5.77
  },
  {
    "LONG": -36.48,
    "LAT": -9.91,
    "CIDADE": "Junqueiro",
    "UF": "ALAGOAS",
    "anual": 5.24,
    "JAN": 5.63,
    "FEB": 5.61,
    "MAR": 5.81,
    "APR": 5.28,
    "MAY": 4.58,
    "JUN": 4.28,
    "JUL": 4.29,
    "AUG": 4.79,
    "SEP": 5.42,
    "OCT": 5.54,
    "NOV": 5.86,
    "DEC": 5.76
  },
  {
    "LONG": -3.64,
    "LAT": -9.91,
    "CIDADE": "Teotônio Vilela",
    "UF": "ALAGOAS",
    "anual": 5.23,
    "JAN": 5.6,
    "FEB": 5.61,
    "MAR": 5.82,
    "APR": 5.25,
    "MAY": 4.59,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 4.82,
    "SEP": 5.38,
    "OCT": 5.52,
    "NOV": 5.86,
    "DEC": 5.75
  },
  {
    "LONG": -37.28,
    "LAT": -9.82,
    "CIDADE": "Belo Monte",
    "UF": "ALAGOAS",
    "anual": 5.38,
    "JAN": 5.74,
    "FEB": 5.75,
    "MAR": 5.91,
    "APR": 5.42,
    "MAY": 4.67,
    "JUN": 4.42,
    "JUL": 4.48,
    "AUG": 5.04,
    "SEP": 5.63,
    "OCT": 5.66,
    "NOV": 6,
    "DEC": 5.82
  },
  {
    "LONG": -36.74,
    "LAT": -9.83,
    "CIDADE": "Lagoa da Canoa",
    "UF": "ALAGOAS",
    "anual": 5.32,
    "JAN": 5.7,
    "FEB": 5.64,
    "MAR": 5.86,
    "APR": 5.32,
    "MAY": 4.55,
    "JUN": 4.32,
    "JUL": 4.44,
    "AUG": 4.92,
    "SEP": 5.52,
    "OCT": 5.67,
    "NOV": 5.98,
    "DEC": 5.85
  },
  {
    "LONG": -36.66,
    "LAT": -9.76,
    "CIDADE": "Arapiraca",
    "UF": "ALAGOAS",
    "anual": 5.29,
    "JAN": 5.68,
    "FEB": 5.62,
    "MAR": 5.84,
    "APR": 5.3,
    "MAY": 4.55,
    "JUN": 4.3,
    "JUL": 4.39,
    "AUG": 4.88,
    "SEP": 5.47,
    "OCT": 5.62,
    "NOV": 5.95,
    "DEC": 5.85
  },
  {
    "LONG": -36.35,
    "LAT": -9.78,
    "CIDADE": "Campo Alegre",
    "UF": "ALAGOAS",
    "anual": 5.24,
    "JAN": 5.6,
    "FEB": 5.63,
    "MAR": 5.85,
    "APR": 5.26,
    "MAY": 4.54,
    "JUN": 4.23,
    "JUL": 4.34,
    "AUG": 4.8,
    "SEP": 5.38,
    "OCT": 5.55,
    "NOV": 5.84,
    "DEC": 5.83
  },
  {
    "LONG": -36.1,
    "LAT": -9.78,
    "CIDADE": "São Miguel dos Campos",
    "UF": "ALAGOAS",
    "anual": 5.32,
    "JAN": 5.65,
    "FEB": 5.77,
    "MAR": 5.95,
    "APR": 5.23,
    "MAY": 4.64,
    "JUN": 4.31,
    "JUL": 4.37,
    "AUG": 4.85,
    "SEP": 5.51,
    "OCT": 5.7,
    "NOV": 5.98,
    "DEC": 5.87
  },
  {
    "LONG": -3.59,
    "LAT": -9.84,
    "CIDADE": "Barra de São Miguel",
    "UF": "ALAGOAS",
    "anual": 5.4,
    "JAN": 5.76,
    "FEB": 5.9,
    "MAR": 6.03,
    "APR": 5.35,
    "MAY": 4.68,
    "JUN": 4.34,
    "JUL": 4.49,
    "AUG": 5.01,
    "SEP": 5.57,
    "OCT": 5.76,
    "NOV": 5.98,
    "DEC": 5.94
  },
  {
    "LONG": -35.98,
    "LAT": -9.84,
    "CIDADE": "Roteiro",
    "UF": "ALAGOAS",
    "anual": 5.4,
    "JAN": 5.76,
    "FEB": 5.9,
    "MAR": 6.03,
    "APR": 5.35,
    "MAY": 4.68,
    "JUN": 4.34,
    "JUL": 4.49,
    "AUG": 5.01,
    "SEP": 5.57,
    "OCT": 5.76,
    "NOV": 5.98,
    "DEC": 5.94
  },
  {
    "LONG": -37.44,
    "LAT": -9.74,
    "CIDADE": "Pão de Açúcar",
    "UF": "ALAGOAS",
    "anual": 5.45,
    "JAN": 5.83,
    "FEB": 5.87,
    "MAR": 6,
    "APR": 5.54,
    "MAY": 4.74,
    "JUN": 4.48,
    "JUL": 4.55,
    "AUG": 5.1,
    "SEP": 5.64,
    "OCT": 5.72,
    "NOV": 6.08,
    "DEC": 5.86
  },
  {
    "LONG": -37.34,
    "LAT": -9.67,
    "CIDADE": "Palestina",
    "UF": "ALAGOAS",
    "anual": 5.34,
    "JAN": 5.71,
    "FEB": 5.71,
    "MAR": 5.91,
    "APR": 5.4,
    "MAY": 4.69,
    "JUN": 4.35,
    "JUL": 4.44,
    "AUG": 4.9,
    "SEP": 5.53,
    "OCT": 5.62,
    "NOV": 6.03,
    "DEC": 5.82
  },
  {
    "LONG": -37.13,
    "LAT": -9.67,
    "CIDADE": "Batalha",
    "UF": "ALAGOAS",
    "anual": 5.31,
    "JAN": 5.71,
    "FEB": 5.59,
    "MAR": 5.82,
    "APR": 5.36,
    "MAY": 4.57,
    "JUN": 4.31,
    "JUL": 4.41,
    "AUG": 4.96,
    "SEP": 5.57,
    "OCT": 5.59,
    "NOV": 5.99,
    "DEC": 5.83
  },
  {
    "LONG": -37,
    "LAT": -9.66,
    "CIDADE": "Jaramataia",
    "UF": "ALAGOAS",
    "anual": 5.27,
    "JAN": 5.67,
    "FEB": 5.54,
    "MAR": 5.79,
    "APR": 5.28,
    "MAY": 4.53,
    "JUN": 4.31,
    "JUL": 4.36,
    "AUG": 4.88,
    "SEP": 5.49,
    "OCT": 5.57,
    "NOV": 6.01,
    "DEC": 5.78
  },
  {
    "LONG": -36.5,
    "LAT": -9.74,
    "CIDADE": "Limoeiro de Anadia",
    "UF": "ALAGOAS",
    "anual": 5.29,
    "JAN": 5.64,
    "FEB": 5.61,
    "MAR": 5.83,
    "APR": 5.29,
    "MAY": 4.54,
    "JUN": 4.27,
    "JUL": 4.35,
    "AUG": 4.9,
    "SEP": 5.52,
    "OCT": 5.62,
    "NOV": 6.02,
    "DEC": 5.87
  },
  {
    "LONG": -36.31,
    "LAT": -9.69,
    "CIDADE": "Anadia",
    "UF": "ALAGOAS",
    "anual": 5.23,
    "JAN": 5.58,
    "FEB": 5.62,
    "MAR": 5.8,
    "APR": 5.26,
    "MAY": 4.49,
    "JUN": 4.23,
    "JUL": 4.26,
    "AUG": 4.78,
    "SEP": 5.45,
    "OCT": 5.56,
    "NOV": 5.9,
    "DEC": 5.84
  },
  {
    "LONG": -3.59,
    "LAT": -9.71,
    "CIDADE": "Marechal Deodoro",
    "UF": "ALAGOAS",
    "anual": 5.46,
    "JAN": 5.78,
    "FEB": 5.9,
    "MAR": 6.04,
    "APR": 5.4,
    "MAY": 4.72,
    "JUN": 4.42,
    "JUL": 4.55,
    "AUG": 5.15,
    "SEP": 5.67,
    "OCT": 5.86,
    "NOV": 6.01,
    "DEC": 5.97
  },
  {
    "LONG": -35.7,
    "LAT": -9.66,
    "CIDADE": "Maceió",
    "UF": "ALAGOAS",
    "anual": 5.53,
    "JAN": 5.8,
    "FEB": 5.95,
    "MAR": 6.11,
    "APR": 5.47,
    "MAY": 4.77,
    "JUN": 4.51,
    "JUL": 4.65,
    "AUG": 5.28,
    "SEP": 5.81,
    "OCT": 5.89,
    "NOV": 6.07,
    "DEC": 6.06
  },
  {
    "LONG": -37.76,
    "LAT": -9.61,
    "CIDADE": "Piranhas",
    "UF": "ALAGOAS",
    "anual": 5.47,
    "JAN": 5.94,
    "FEB": 5.86,
    "MAR": 5.98,
    "APR": 5.53,
    "MAY": 4.78,
    "JUN": 4.41,
    "JUL": 4.51,
    "AUG": 5.08,
    "SEP": 5.73,
    "OCT": 5.81,
    "NOV": 6.09,
    "DEC": 5.93
  },
  {
    "LONG": -37.38,
    "LAT": -9.56,
    "CIDADE": "São José da Tapera",
    "UF": "ALAGOAS",
    "anual": 5.4,
    "JAN": 5.79,
    "FEB": 5.78,
    "MAR": 5.96,
    "APR": 5.48,
    "MAY": 4.66,
    "JUN": 4.35,
    "JUL": 4.48,
    "AUG": 4.97,
    "SEP": 5.61,
    "OCT": 5.77,
    "NOV": 6.14,
    "DEC": 5.87
  },
  {
    "LONG": -37.21,
    "LAT": -9.64,
    "CIDADE": "Jacaré dos Homens",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.74,
    "FEB": 5.69,
    "MAR": 5.93,
    "APR": 5.43,
    "MAY": 4.65,
    "JUN": 4.31,
    "JUL": 4.43,
    "AUG": 4.91,
    "SEP": 5.53,
    "OCT": 5.71,
    "NOV": 6.12,
    "DEC": 5.84
  },
  {
    "LONG": -37.25,
    "LAT": -0.96,
    "CIDADE": "Monteirópolis",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.74,
    "FEB": 5.69,
    "MAR": 5.93,
    "APR": 5.43,
    "MAY": 4.65,
    "JUN": 4.31,
    "JUL": 4.43,
    "AUG": 4.91,
    "SEP": 5.53,
    "OCT": 5.71,
    "NOV": 6.12,
    "DEC": 5.84
  },
  {
    "LONG": "#VALOR!",
    "LAT": -9.62,
    "CIDADE": "Craíbas",
    "UF": "ALAGOAS",
    "anual": 5.39,
    "JAN": 5.75,
    "FEB": 5.65,
    "MAR": 5.98,
    "APR": 5.44,
    "MAY": 4.61,
    "JUN": 4.31,
    "JUL": 4.45,
    "AUG": 4.97,
    "SEP": 5.66,
    "OCT": 5.76,
    "NOV": 6.08,
    "DEC": 5.95
  },
  {
    "LONG": -36.58,
    "LAT": -9.63,
    "CIDADE": "Coité do Nóia",
    "UF": "ALAGOAS",
    "anual": 5.33,
    "JAN": 5.73,
    "FEB": 5.71,
    "MAR": 5.88,
    "APR": 5.33,
    "MAY": 4.55,
    "JUN": 4.23,
    "JUL": 4.33,
    "AUG": 4.88,
    "SEP": 5.58,
    "OCT": 5.71,
    "NOV": 6.08,
    "DEC": 5.98
  },
  {
    "LONG": -36.49,
    "LAT": -9.57,
    "CIDADE": "Belém",
    "UF": "ALAGOAS",
    "anual": 5.24,
    "JAN": 5.64,
    "FEB": 5.64,
    "MAR": 5.78,
    "APR": 5.32,
    "MAY": 4.48,
    "JUN": 4.15,
    "JUL": 4.21,
    "AUG": 4.77,
    "SEP": 5.44,
    "OCT": 5.58,
    "NOV": 6,
    "DEC": 5.89
  },
  {
    "LONG": -36.49,
    "LAT": -9.65,
    "CIDADE": "Taquarana",
    "UF": "ALAGOAS",
    "anual": 5.24,
    "JAN": 5.64,
    "FEB": 5.64,
    "MAR": 5.78,
    "APR": 5.32,
    "MAY": 4.48,
    "JUN": 4.15,
    "JUL": 4.21,
    "AUG": 4.77,
    "SEP": 5.44,
    "OCT": 5.58,
    "NOV": 6,
    "DEC": 5.89
  },
  {
    "LONG": -36.3,
    "LAT": -0.96,
    "CIDADE": "Maribondo",
    "UF": "ALAGOAS",
    "anual": 5.23,
    "JAN": 5.61,
    "FEB": 5.61,
    "MAR": 5.78,
    "APR": 5.28,
    "MAY": 4.49,
    "JUN": 4.15,
    "JUL": 4.21,
    "AUG": 4.75,
    "SEP": 5.46,
    "OCT": 5.59,
    "NOV": 5.96,
    "DEC": 5.86
  },
  {
    "LONG": -36.21,
    "LAT": -9.64,
    "CIDADE": "Boca da Mata",
    "UF": "ALAGOAS",
    "anual": 5.14,
    "JAN": 5.5,
    "FEB": 5.51,
    "MAR": 5.76,
    "APR": 5.18,
    "MAY": 4.43,
    "JUN": 4.08,
    "JUL": 4.17,
    "AUG": 4.64,
    "SEP": 5.37,
    "OCT": 5.47,
    "NOV": 5.87,
    "DEC": 5.74
  },
  {
    "LONG": -35.95,
    "LAT": -9.6,
    "CIDADE": "Pilar",
    "UF": "ALAGOAS",
    "anual": 5.31,
    "JAN": 5.62,
    "FEB": 5.72,
    "MAR": 5.89,
    "APR": 5.3,
    "MAY": 4.62,
    "JUN": 4.32,
    "JUL": 4.42,
    "AUG": 4.94,
    "SEP": 5.56,
    "OCT": 5.62,
    "NOV": 5.9,
    "DEC": 5.79
  },
  {
    "LONG": -3.58,
    "LAT": -9.57,
    "CIDADE": "Satuba",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.69,
    "FEB": 5.81,
    "MAR": 5.96,
    "APR": 5.29,
    "MAY": 4.61,
    "JUN": 4.35,
    "JUL": 4.46,
    "AUG": 5,
    "SEP": 5.58,
    "OCT": 5.73,
    "NOV": 5.97,
    "DEC": 5.88
  },
  {
    "LONG": -35.82,
    "LAT": -9.6,
    "CIDADE": "Santa Luzia do Norte",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.69,
    "FEB": 5.81,
    "MAR": 5.96,
    "APR": 5.29,
    "MAY": 4.61,
    "JUN": 4.35,
    "JUL": 4.46,
    "AUG": 5,
    "SEP": 5.58,
    "OCT": 5.73,
    "NOV": 5.97,
    "DEC": 5.88
  },
  {
    "LONG": -35.8,
    "LAT": -9.64,
    "CIDADE": "Coqueiro Seco",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.69,
    "FEB": 5.81,
    "MAR": 5.96,
    "APR": 5.29,
    "MAY": 4.61,
    "JUN": 4.35,
    "JUL": 4.46,
    "AUG": 5,
    "SEP": 5.58,
    "OCT": 5.73,
    "NOV": 5.97,
    "DEC": 5.88
  },
  {
    "LONG": -3.78,
    "LAT": -9.5,
    "CIDADE": "Olho d'Água do Casado",
    "UF": "ALAGOAS",
    "anual": 5.48,
    "JAN": 5.95,
    "FEB": 5.88,
    "MAR": 5.99,
    "APR": 5.54,
    "MAY": 4.77,
    "JUN": 4.41,
    "JUL": 4.5,
    "AUG": 5.09,
    "SEP": 5.75,
    "OCT": 5.81,
    "NOV": 6.14,
    "DEC": 5.96
  },
  {
    "LONG": -37.46,
    "LAT": -9.47,
    "CIDADE": "Senador Rui Palmeira",
    "UF": "ALAGOAS",
    "anual": 5.42,
    "JAN": 5.81,
    "FEB": 5.81,
    "MAR": 5.95,
    "APR": 5.46,
    "MAY": 4.67,
    "JUN": 4.34,
    "JUL": 4.45,
    "AUG": 5.01,
    "SEP": 5.68,
    "OCT": 5.81,
    "NOV": 6.14,
    "DEC": 5.91
  },
  {
    "LONG": -37.38,
    "LAT": -9.48,
    "CIDADE": "Carneiros",
    "UF": "ALAGOAS",
    "anual": 5.43,
    "JAN": 5.78,
    "FEB": 5.77,
    "MAR": 6,
    "APR": 5.51,
    "MAY": 4.68,
    "JUN": 4.34,
    "JUL": 4.44,
    "AUG": 5.02,
    "SEP": 5.7,
    "OCT": 5.83,
    "NOV": 6.2,
    "DEC": 5.91
  },
  {
    "LONG": -37.3,
    "LAT": -9.53,
    "CIDADE": "Olho d'Água das Flores",
    "UF": "ALAGOAS",
    "anual": 5.41,
    "JAN": 5.78,
    "FEB": 5.74,
    "MAR": 6.02,
    "APR": 5.48,
    "MAY": 4.67,
    "JUN": 4.32,
    "JUL": 4.41,
    "AUG": 4.96,
    "SEP": 5.64,
    "OCT": 5.81,
    "NOV": 6.19,
    "DEC": 5.92
  },
  {
    "LONG": -37.19,
    "LAT": -9.52,
    "CIDADE": "Olivença",
    "UF": "ALAGOAS",
    "anual": 5.38,
    "JAN": 5.82,
    "FEB": 5.73,
    "MAR": 5.98,
    "APR": 5.4,
    "MAY": 4.63,
    "JUN": 4.29,
    "JUL": 4.38,
    "AUG": 4.89,
    "SEP": 5.57,
    "OCT": 5.79,
    "NOV": 6.15,
    "DEC": 5.91
  },
  {
    "LONG": -36.99,
    "LAT": -9.53,
    "CIDADE": "Major Isidoro",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.76,
    "FEB": 5.64,
    "MAR": 5.93,
    "APR": 5.4,
    "MAY": 4.61,
    "JUN": 4.28,
    "JUL": 4.4,
    "AUG": 4.9,
    "SEP": 5.57,
    "OCT": 5.75,
    "NOV": 6.12,
    "DEC": 5.94
  },
  {
    "LONG": -36.64,
    "LAT": -9.54,
    "CIDADE": "Igaci",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.72,
    "FEB": 5.67,
    "MAR": 5.93,
    "APR": 5.4,
    "MAY": 4.6,
    "JUN": 4.27,
    "JUL": 4.37,
    "AUG": 4.93,
    "SEP": 5.6,
    "OCT": 5.74,
    "NOV": 6.13,
    "DEC": 5.95
  },
  {
    "LONG": -36.43,
    "LAT": -9.53,
    "CIDADE": "Tanque d'Arca",
    "UF": "ALAGOAS",
    "anual": 5.28,
    "JAN": 5.64,
    "FEB": 5.65,
    "MAR": 5.82,
    "APR": 5.31,
    "MAY": 4.53,
    "JUN": 4.15,
    "JUL": 4.23,
    "AUG": 4.81,
    "SEP": 5.53,
    "OCT": 5.68,
    "NOV": 6.09,
    "DEC": 5.9
  },
  {
    "LONG": -36.29,
    "LAT": -9.47,
    "CIDADE": "Pindoba",
    "UF": "ALAGOAS",
    "anual": 5.14,
    "JAN": 5.48,
    "FEB": 5.55,
    "MAR": 5.76,
    "APR": 5.21,
    "MAY": 4.45,
    "JUN": 4.05,
    "JUL": 4.16,
    "AUG": 4.64,
    "SEP": 5.34,
    "OCT": 5.49,
    "NOV": 5.84,
    "DEC": 5.71
  },
  {
    "LONG": -36.01,
    "LAT": -9.51,
    "CIDADE": "Atalaia",
    "UF": "ALAGOAS",
    "anual": 5.21,
    "JAN": 5.53,
    "FEB": 5.6,
    "MAR": 5.8,
    "APR": 5.3,
    "MAY": 4.54,
    "JUN": 4.24,
    "JUL": 4.3,
    "AUG": 4.8,
    "SEP": 5.44,
    "OCT": 5.48,
    "NOV": 5.8,
    "DEC": 5.72
  },
  {
    "LONG": -35.84,
    "LAT": -9.48,
    "CIDADE": "Rio Largo",
    "UF": "ALAGOAS",
    "anual": 5.24,
    "JAN": 5.55,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.22,
    "MAY": 4.51,
    "JUN": 4.28,
    "JUL": 4.36,
    "AUG": 4.87,
    "SEP": 5.46,
    "OCT": 5.55,
    "NOV": 5.86,
    "DEC": 5.74
  },
  {
    "LONG": -35.55,
    "LAT": -9.46,
    "CIDADE": "Paripueira",
    "UF": "ALAGOAS",
    "anual": 5.55,
    "JAN": 5.8,
    "FEB": 5.95,
    "MAR": 6.17,
    "APR": 5.46,
    "MAY": 4.8,
    "JUN": 4.52,
    "JUL": 4.64,
    "AUG": 5.26,
    "SEP": 5.87,
    "OCT": 5.95,
    "NOV": 6.15,
    "DEC": 6.05
  },
  {
    "LONG": -3.8,
    "LAT": -9.39,
    "CIDADE": "Delmiro Gouveia",
    "UF": "ALAGOAS",
    "anual": 5.46,
    "JAN": 5.95,
    "FEB": 5.87,
    "MAR": 5.97,
    "APR": 5.48,
    "MAY": 4.73,
    "JUN": 4.34,
    "JUL": 4.46,
    "AUG": 5.01,
    "SEP": 5.75,
    "OCT": 5.85,
    "NOV": 6.17,
    "DEC": 5.96
  },
  {
    "LONG": -37.25,
    "LAT": -9.37,
    "CIDADE": "Santana do Ipanema",
    "UF": "ALAGOAS",
    "anual": 5.46,
    "JAN": 5.84,
    "FEB": 5.8,
    "MAR": 6.07,
    "APR": 5.51,
    "MAY": 4.71,
    "JUN": 4.33,
    "JUL": 4.43,
    "AUG": 4.99,
    "SEP": 5.68,
    "OCT": 5.9,
    "NOV": 6.22,
    "DEC": 5.99
  },
  {
    "LONG": -37.09,
    "LAT": -9.39,
    "CIDADE": "Dois Riachos",
    "UF": "ALAGOAS",
    "anual": 5.41,
    "JAN": 5.82,
    "FEB": 5.73,
    "MAR": 5.96,
    "APR": 5.44,
    "MAY": 4.67,
    "JUN": 4.28,
    "JUL": 4.42,
    "AUG": 4.97,
    "SEP": 5.66,
    "OCT": 5.83,
    "NOV": 6.14,
    "DEC": 5.98
  },
  {
    "LONG": -36.99,
    "LAT": -9.4,
    "CIDADE": "Cacimbinhas",
    "UF": "ALAGOAS",
    "anual": 5.38,
    "JAN": 5.74,
    "FEB": 5.69,
    "MAR": 5.92,
    "APR": 5.38,
    "MAY": 4.65,
    "JUN": 4.27,
    "JUL": 4.41,
    "AUG": 4.94,
    "SEP": 5.64,
    "OCT": 5.78,
    "NOV": 6.13,
    "DEC": 5.97
  },
  {
    "LONG": "#VALOR!",
    "LAT": -0.94,
    "CIDADE": "Estrela de Alagoas",
    "UF": "ALAGOAS",
    "anual": 5.44,
    "JAN": 5.76,
    "FEB": 5.75,
    "MAR": 5.98,
    "APR": 5.47,
    "MAY": 4.65,
    "JUN": 4.29,
    "JUL": 4.42,
    "AUG": 5.05,
    "SEP": 5.79,
    "OCT": 5.89,
    "NOV": 6.24,
    "DEC": 6.02
  },
  {
    "LONG": -36.63,
    "LAT": -9.41,
    "CIDADE": "Palmeira dos Índios",
    "UF": "ALAGOAS",
    "anual": 5.38,
    "JAN": 5.7,
    "FEB": 5.69,
    "MAR": 5.93,
    "APR": 5.42,
    "MAY": 4.61,
    "JUN": 4.24,
    "JUL": 4.36,
    "AUG": 4.98,
    "SEP": 5.68,
    "OCT": 5.81,
    "NOV": 6.17,
    "DEC": 5.96
  },
  {
    "LONG": -36.39,
    "LAT": -9.45,
    "CIDADE": "Mar Vermelho",
    "UF": "ALAGOAS",
    "anual": 5.23,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.82,
    "APR": 5.27,
    "MAY": 4.5,
    "JUN": 4.07,
    "JUL": 4.18,
    "AUG": 4.73,
    "SEP": 5.46,
    "OCT": 5.6,
    "NOV": 6.02,
    "DEC": 5.85
  },
  {
    "LONG": -36.38,
    "LAT": -9.37,
    "CIDADE": "Paulo Jacinto",
    "UF": "ALAGOAS",
    "anual": 5.23,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.82,
    "APR": 5.27,
    "MAY": 4.5,
    "JUN": 4.07,
    "JUL": 4.18,
    "AUG": 4.73,
    "SEP": 5.46,
    "OCT": 5.6,
    "NOV": 6.02,
    "DEC": 5.85
  },
  {
    "LONG": -36.24,
    "LAT": -9.37,
    "CIDADE": "Viçosa",
    "UF": "ALAGOAS",
    "anual": 5.19,
    "JAN": 5.52,
    "FEB": 5.63,
    "MAR": 5.8,
    "APR": 5.29,
    "MAY": 4.47,
    "JUN": 4.07,
    "JUL": 4.2,
    "AUG": 4.69,
    "SEP": 5.39,
    "OCT": 5.53,
    "NOV": 5.91,
    "DEC": 5.78
  },
  {
    "LONG": -36.16,
    "LAT": -9.4,
    "CIDADE": "Cajueiro",
    "UF": "ALAGOAS",
    "anual": 5.11,
    "JAN": 5.43,
    "FEB": 5.49,
    "MAR": 5.69,
    "APR": 5.26,
    "MAY": 4.47,
    "JUN": 4.08,
    "JUL": 4.15,
    "AUG": 4.62,
    "SEP": 5.3,
    "OCT": 5.37,
    "NOV": 5.78,
    "DEC": 5.65
  },
  {
    "LONG": -36.08,
    "LAT": -9.42,
    "CIDADE": "Capela",
    "UF": "ALAGOAS",
    "anual": 5.15,
    "JAN": 5.45,
    "FEB": 5.49,
    "MAR": 5.74,
    "APR": 5.24,
    "MAY": 4.48,
    "JUN": 4.14,
    "JUL": 4.2,
    "AUG": 4.71,
    "SEP": 5.39,
    "OCT": 5.45,
    "NOV": 5.82,
    "DEC": 5.69
  },
  {
    "LONG": -35.84,
    "LAT": -9.39,
    "CIDADE": "Messias",
    "UF": "ALAGOAS",
    "anual": 5.22,
    "JAN": 5.53,
    "FEB": 5.64,
    "MAR": 5.85,
    "APR": 5.25,
    "MAY": 4.52,
    "JUN": 4.21,
    "JUL": 4.3,
    "AUG": 4.79,
    "SEP": 5.45,
    "OCT": 5.53,
    "NOV": 5.84,
    "DEC": 5.7
  },
  {
    "LONG": -35.51,
    "LAT": -9.4,
    "CIDADE": "Barra de Santo Antônio",
    "UF": "ALAGOAS",
    "anual": 5.44,
    "JAN": 5.73,
    "FEB": 5.83,
    "MAR": 6.12,
    "APR": 5.38,
    "MAY": 4.72,
    "JUN": 4.42,
    "JUL": 4.51,
    "AUG": 5.1,
    "SEP": 5.62,
    "OCT": 5.78,
    "NOV": 6.05,
    "DEC": 5.97
  },
  {
    "LONG": -38,
    "LAT": -9.26,
    "CIDADE": "Pariconha",
    "UF": "ALAGOAS",
    "anual": 5.52,
    "JAN": 5.95,
    "FEB": 5.92,
    "MAR": 5.99,
    "APR": 5.57,
    "MAY": 4.83,
    "JUN": 4.34,
    "JUL": 4.53,
    "AUG": 5.08,
    "SEP": 5.85,
    "OCT": 5.94,
    "NOV": 6.25,
    "DEC": 5.99
  },
  {
    "LONG": -37.94,
    "LAT": -9.26,
    "CIDADE": "Água Branca",
    "UF": "ALAGOAS",
    "anual": 5.47,
    "JAN": 5.91,
    "FEB": 5.86,
    "MAR": 6,
    "APR": 5.49,
    "MAY": 4.74,
    "JUN": 4.29,
    "JUL": 4.38,
    "AUG": 5.03,
    "SEP": 5.78,
    "OCT": 5.89,
    "NOV": 6.23,
    "DEC": 5.97
  },
  {
    "LONG": -37.28,
    "LAT": -9.3,
    "CIDADE": "Poço das Trincheiras",
    "UF": "ALAGOAS",
    "anual": 5.45,
    "JAN": 5.82,
    "FEB": 5.76,
    "MAR": 6.01,
    "APR": 5.5,
    "MAY": 4.69,
    "JUN": 4.27,
    "JUL": 4.48,
    "AUG": 4.96,
    "SEP": 5.82,
    "OCT": 5.93,
    "NOV": 6.21,
    "DEC": 5.94
  },
  {
    "LONG": -3.69,
    "LAT": -9.31,
    "CIDADE": "Minador do Negrão",
    "UF": "ALAGOAS",
    "anual": 5.4,
    "JAN": 5.73,
    "FEB": 5.73,
    "MAR": 5.92,
    "APR": 5.47,
    "MAY": 4.66,
    "JUN": 4.26,
    "JUL": 4.38,
    "AUG": 4.96,
    "SEP": 5.7,
    "OCT": 5.85,
    "NOV": 6.14,
    "DEC": 5.98
  },
  {
    "LONG": -36.47,
    "LAT": -9.32,
    "CIDADE": "Quebrangulo",
    "UF": "ALAGOAS",
    "anual": 5.26,
    "JAN": 5.61,
    "FEB": 5.67,
    "MAR": 5.84,
    "APR": 5.38,
    "MAY": 4.52,
    "JUN": 4.04,
    "JUL": 4.18,
    "AUG": 4.75,
    "SEP": 5.5,
    "OCT": 5.69,
    "NOV": 6.1,
    "DEC": 5.88
  },
  {
    "LONG": -36.3,
    "LAT": -0.93,
    "CIDADE": "Chã Preta",
    "UF": "ALAGOAS",
    "anual": 5.17,
    "JAN": 5.54,
    "FEB": 5.58,
    "MAR": 5.75,
    "APR": 5.28,
    "MAY": 4.47,
    "JUN": 4.02,
    "JUL": 4.09,
    "AUG": 4.68,
    "SEP": 5.36,
    "OCT": 5.5,
    "NOV": 5.94,
    "DEC": 5.8
  },
  {
    "LONG": -35.94,
    "LAT": -9.31,
    "CIDADE": "Murici",
    "UF": "ALAGOAS",
    "anual": 5.18,
    "JAN": 5.48,
    "FEB": 5.63,
    "MAR": 5.83,
    "APR": 5.27,
    "MAY": 4.46,
    "JUN": 4.15,
    "JUL": 4.18,
    "AUG": 4.78,
    "SEP": 5.41,
    "OCT": 5.51,
    "NOV": 5.81,
    "DEC": 5.69
  },
  {
    "LONG": -35.71,
    "LAT": -9.27,
    "CIDADE": "Flexeiras",
    "UF": "ALAGOAS",
    "anual": 5.11,
    "JAN": 5.39,
    "FEB": 5.52,
    "MAR": 5.73,
    "APR": 5.19,
    "MAY": 4.41,
    "JUN": 4.13,
    "JUL": 4.21,
    "AUG": 4.68,
    "SEP": 5.32,
    "OCT": 5.37,
    "NOV": 5.75,
    "DEC": 5.62
  },
  {
    "LONG": -35.56,
    "LAT": -9.32,
    "CIDADE": "São Luís do Quitunde",
    "UF": "ALAGOAS",
    "anual": 5.33,
    "JAN": 5.64,
    "FEB": 5.73,
    "MAR": 6,
    "APR": 5.31,
    "MAY": 4.63,
    "JUN": 4.42,
    "JUL": 4.41,
    "AUG": 5.03,
    "SEP": 5.53,
    "OCT": 5.6,
    "NOV": 5.87,
    "DEC": 5.82
  },
  {
    "LONG": -35.38,
    "LAT": -9.27,
    "CIDADE": "São Miguel dos Milagres",
    "UF": "ALAGOAS",
    "anual": 5.59,
    "JAN": 5.9,
    "FEB": 5.91,
    "MAR": 6.24,
    "APR": 5.54,
    "MAY": 4.82,
    "JUN": 4.55,
    "JUL": 4.67,
    "AUG": 5.34,
    "SEP": 5.88,
    "OCT": 5.97,
    "NOV": 6.18,
    "DEC": 6.1
  },
  {
    "LONG": -37.75,
    "LAT": -9.23,
    "CIDADE": "Inhapi",
    "UF": "ALAGOAS",
    "anual": 5.52,
    "JAN": 6,
    "FEB": 5.93,
    "MAR": 6.06,
    "APR": 5.54,
    "MAY": 4.73,
    "JUN": 4.25,
    "JUL": 4.4,
    "AUG": 5.07,
    "SEP": 5.82,
    "OCT": 6.03,
    "NOV": 6.32,
    "DEC": 6.04
  },
  {
    "LONG": -37.36,
    "LAT": -9.16,
    "CIDADE": "Ouro Branco",
    "UF": "ALAGOAS",
    "anual": 5.48,
    "JAN": 5.87,
    "FEB": 5.83,
    "MAR": 6.03,
    "APR": 5.5,
    "MAY": 4.69,
    "JUN": 4.32,
    "JUL": 4.52,
    "AUG": 5.05,
    "SEP": 5.81,
    "OCT": 5.99,
    "NOV": 6.17,
    "DEC": 5.99
  },
  {
    "LONG": -37.35,
    "LAT": -9.23,
    "CIDADE": "Maravilha",
    "UF": "ALAGOAS",
    "anual": 5.48,
    "JAN": 5.87,
    "FEB": 5.83,
    "MAR": 6.03,
    "APR": 5.5,
    "MAY": 4.69,
    "JUN": 4.32,
    "JUL": 4.52,
    "AUG": 5.05,
    "SEP": 5.81,
    "OCT": 5.99,
    "NOV": 6.17,
    "DEC": 5.99
  },
  {
    "LONG": -36.22,
    "LAT": -9.17,
    "CIDADE": "Santana do Mundaú",
    "UF": "ALAGOAS",
    "anual": 5.12,
    "JAN": 5.52,
    "FEB": 5.55,
    "MAR": 5.7,
    "APR": 5.28,
    "MAY": 4.43,
    "JUN": 3.96,
    "JUL": 4.04,
    "AUG": 4.55,
    "SEP": 5.33,
    "OCT": 5.47,
    "NOV": 5.86,
    "DEC": 5.77
  },
  {
    "LONG": -36.02,
    "LAT": -9.16,
    "CIDADE": "União dos Palmares",
    "UF": "ALAGOAS",
    "anual": 5.17,
    "JAN": 5.45,
    "FEB": 5.57,
    "MAR": 5.73,
    "APR": 5.27,
    "MAY": 4.49,
    "JUN": 4.11,
    "JUL": 4.16,
    "AUG": 4.71,
    "SEP": 5.45,
    "OCT": 5.51,
    "NOV": 5.83,
    "DEC": 5.72
  },
  {
    "LONG": -36.02,
    "LAT": -9.23,
    "CIDADE": "Branquinha",
    "UF": "ALAGOAS",
    "anual": 5.17,
    "JAN": 5.45,
    "FEB": 5.57,
    "MAR": 5.73,
    "APR": 5.27,
    "MAY": 4.49,
    "JUN": 4.11,
    "JUL": 4.16,
    "AUG": 4.71,
    "SEP": 5.45,
    "OCT": 5.51,
    "NOV": 5.83,
    "DEC": 5.72
  },
  {
    "LONG": -35.52,
    "LAT": -9.15,
    "CIDADE": "Matriz de Camaragibe",
    "UF": "ALAGOAS",
    "anual": 5.22,
    "JAN": 5.56,
    "FEB": 5.59,
    "MAR": 5.85,
    "APR": 5.17,
    "MAY": 4.59,
    "JUN": 4.28,
    "JUL": 4.33,
    "AUG": 4.88,
    "SEP": 5.43,
    "OCT": 5.48,
    "NOV": 5.78,
    "DEC": 5.71
  },
  {
    "LONG": -35.49,
    "LAT": -9.24,
    "CIDADE": "Passo de Camaragibe",
    "UF": "ALAGOAS",
    "anual": 5.28,
    "JAN": 5.62,
    "FEB": 5.63,
    "MAR": 5.94,
    "APR": 5.19,
    "MAY": 4.61,
    "JUN": 4.3,
    "JUL": 4.38,
    "AUG": 4.94,
    "SEP": 5.48,
    "OCT": 5.61,
    "NOV": 5.88,
    "DEC": 5.81
  },
  {
    "LONG": -35.31,
    "LAT": -9.16,
    "CIDADE": "Porto de Pedras",
    "UF": "ALAGOAS",
    "anual": 5.42,
    "JAN": 5.67,
    "FEB": 5.79,
    "MAR": 6.12,
    "APR": 5.32,
    "MAY": 4.69,
    "JUN": 4.47,
    "JUL": 4.51,
    "AUG": 5.19,
    "SEP": 5.64,
    "OCT": 5.78,
    "NOV": 5.97,
    "DEC": 5.85
  },
  {
    "LONG": -37.73,
    "LAT": -9.12,
    "CIDADE": "Mata Grande",
    "UF": "ALAGOAS",
    "anual": 5.61,
    "JAN": 6.05,
    "FEB": 5.95,
    "MAR": 6.1,
    "APR": 5.63,
    "MAY": 4.86,
    "JUN": 4.37,
    "JUL": 4.52,
    "AUG": 5.25,
    "SEP": 6,
    "OCT": 6.13,
    "NOV": 6.34,
    "DEC": 6.11
  },
  {
    "LONG": -37.6,
    "LAT": -9.12,
    "CIDADE": "Canapi",
    "UF": "ALAGOAS",
    "anual": 5.52,
    "JAN": 5.96,
    "FEB": 5.88,
    "MAR": 6.09,
    "APR": 5.59,
    "MAY": 4.76,
    "JUN": 4.26,
    "JUL": 4.43,
    "AUG": 5,
    "SEP": 5.82,
    "OCT": 6.04,
    "NOV": 6.35,
    "DEC": 6.06
  },
  {
    "LONG": -35.75,
    "LAT": -9.13,
    "CIDADE": "Joaquim Gomes",
    "UF": "ALAGOAS",
    "anual": 4.98,
    "JAN": 5.23,
    "FEB": 5.38,
    "MAR": 5.64,
    "APR": 5.09,
    "MAY": 4.35,
    "JUN": 4.06,
    "JUL": 4.05,
    "AUG": 4.58,
    "SEP": 5.19,
    "OCT": 5.21,
    "NOV": 5.59,
    "DEC": 5.43
  },
  {
    "LONG": -35.4,
    "LAT": -9.05,
    "CIDADE": "Porto Calvo",
    "UF": "ALAGOAS",
    "anual": 5.35,
    "JAN": 5.62,
    "FEB": 5.73,
    "MAR": 6.05,
    "APR": 5.24,
    "MAY": 4.65,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 5.09,
    "SEP": 5.54,
    "OCT": 5.7,
    "NOV": 5.93,
    "DEC": 5.84
  },
  {
    "LONG": -35.26,
    "LAT": -9.09,
    "CIDADE": "Japaratinga",
    "UF": "ALAGOAS",
    "anual": 5.52,
    "JAN": 5.77,
    "FEB": 5.85,
    "MAR": 6.21,
    "APR": 5.43,
    "MAY": 4.77,
    "JUN": 4.55,
    "JUL": 4.59,
    "AUG": 5.29,
    "SEP": 5.78,
    "OCT": 5.99,
    "NOV": 6.06,
    "DEC": 5.99
  },
  {
    "LONG": -36.05,
    "LAT": -9.01,
    "CIDADE": "São José da Laje",
    "UF": "ALAGOAS",
    "anual": 5.07,
    "JAN": 5.41,
    "FEB": 5.46,
    "MAR": 5.64,
    "APR": 5.2,
    "MAY": 4.43,
    "JUN": 3.99,
    "JUL": 4.05,
    "AUG": 4.63,
    "SEP": 5.32,
    "OCT": 5.38,
    "NOV": 5.78,
    "DEC": 5.63
  },
  {
    "LONG": -35.94,
    "LAT": -8.98,
    "CIDADE": "Ibateguara",
    "UF": "ALAGOAS",
    "anual": 5.14,
    "JAN": 5.53,
    "FEB": 5.63,
    "MAR": 5.76,
    "APR": 5.23,
    "MAY": 4.38,
    "JUN": 3.95,
    "JUL": 4.04,
    "AUG": 4.65,
    "SEP": 5.41,
    "OCT": 5.52,
    "NOV": 5.89,
    "DEC": 5.74
  },
  {
    "LONG": -3.52,
    "LAT": -9.01,
    "CIDADE": "Maragogi",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.63,
    "FEB": 5.75,
    "MAR": 6.05,
    "APR": 5.29,
    "MAY": 4.69,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 5.11,
    "SEP": 5.52,
    "OCT": 5.7,
    "NOV": 5.84,
    "DEC": 5.8
  },
  {
    "LONG": -35.72,
    "LAT": -8.92,
    "CIDADE": "Colônia Leopoldina",
    "UF": "ALAGOAS",
    "anual": 5.01,
    "JAN": 5.29,
    "FEB": 5.44,
    "MAR": 5.6,
    "APR": 5.09,
    "MAY": 4.4,
    "JUN": 4.02,
    "JUL": 4.07,
    "AUG": 4.6,
    "SEP": 5.22,
    "OCT": 5.3,
    "NOV": 5.59,
    "DEC": 5.5
  },
  {
    "LONG": -35.66,
    "LAT": -8.94,
    "CIDADE": "Novo Lino",
    "UF": "ALAGOAS",
    "anual": 5.08,
    "JAN": 5.36,
    "FEB": 5.46,
    "MAR": 5.64,
    "APR": 5.16,
    "MAY": 4.46,
    "JUN": 4.17,
    "JUL": 4.17,
    "AUG": 4.73,
    "SEP": 5.32,
    "OCT": 5.39,
    "NOV": 5.65,
    "DEC": 5.48
  },
  {
    "LONG": -35.55,
    "LAT": -8.94,
    "CIDADE": "Jundiá",
    "UF": "ALAGOAS",
    "anual": 5.15,
    "JAN": 5.41,
    "FEB": 5.49,
    "MAR": 5.74,
    "APR": 5.18,
    "MAY": 4.51,
    "JUN": 4.24,
    "JUL": 4.25,
    "AUG": 4.8,
    "SEP": 5.41,
    "OCT": 5.48,
    "NOV": 5.7,
    "DEC": 5.58
  },
  {
    "LONG": -35.57,
    "LAT": -8.85,
    "CIDADE": "Campestre",
    "UF": "ALAGOAS",
    "anual": 5.15,
    "JAN": 5.38,
    "FEB": 5.47,
    "MAR": 5.74,
    "APR": 5.18,
    "MAY": 4.5,
    "JUN": 4.22,
    "JUL": 4.22,
    "AUG": 4.81,
    "SEP": 5.45,
    "OCT": 5.51,
    "NOV": 5.76,
    "DEC": 5.6
  },
  {
    "LONG": -35.46,
    "LAT": -8.84,
    "CIDADE": "Jacuípe",
    "UF": "ALAGOAS",
    "anual": 5.18,
    "JAN": 5.39,
    "FEB": 5.51,
    "MAR": 5.76,
    "APR": 5.19,
    "MAY": 4.5,
    "JUN": 4.27,
    "JUL": 4.26,
    "AUG": 4.85,
    "SEP": 5.49,
    "OCT": 5.53,
    "NOV": 5.81,
    "DEC": 5.63
  },
  {
    "LONG": -52.43,
    "LAT": "#VALOR!",
    "CIDADE": "Vitória do Jari",
    "UF": "AMAPÁ",
    "anual": 4.67,
    "JAN": 4.42,
    "FEB": 4.29,
    "MAR": 4.33,
    "APR": 4.35,
    "MAY": 4.57,
    "JUN": 4.7,
    "JUL": 4.78,
    "AUG": 5.03,
    "SEP": 5.1,
    "OCT": 5.06,
    "NOV": 4.9,
    "DEC": 4.47
  },
  {
    "LONG": -52.52,
    "LAT": "#VALOR!",
    "CIDADE": "Laranjal do Jari",
    "UF": "AMAPÁ",
    "anual": 4.66,
    "JAN": 4.33,
    "FEB": 4.34,
    "MAR": 4.36,
    "APR": 4.37,
    "MAY": 4.55,
    "JUN": 4.73,
    "JUL": 4.75,
    "AUG": 5.05,
    "SEP": 5.12,
    "OCT": 5.05,
    "NOV": 4.87,
    "DEC": 4.4
  },
  {
    "LONG": -51.29,
    "LAT": "#VALOR!",
    "CIDADE": "Mazagão",
    "UF": "AMAPÁ",
    "anual": 4.85,
    "JAN": 4.56,
    "FEB": 4.24,
    "MAR": 4.21,
    "APR": 4.23,
    "MAY": 4.64,
    "JUN": 4.91,
    "JUL": 5.08,
    "AUG": 5.31,
    "SEP": 5.49,
    "OCT": 5.4,
    "NOV": 5.28,
    "DEC": 4.79
  },
  {
    "LONG": -51.17,
    "LAT": "#VALOR!",
    "CIDADE": "Santana",
    "UF": "AMAPÁ",
    "anual": 4.83,
    "JAN": 4.53,
    "FEB": 4.19,
    "MAR": 4.12,
    "APR": 4.17,
    "MAY": 4.63,
    "JUN": 4.9,
    "JUL": 4.99,
    "AUG": 5.37,
    "SEP": 5.56,
    "OCT": 5.41,
    "NOV": 5.33,
    "DEC": 4.78
  },
  {
    "LONG": -51.07,
    "LAT": "#VALOR!",
    "CIDADE": "Macapá",
    "UF": "AMAPÁ",
    "anual": 4.94,
    "JAN": 4.71,
    "FEB": 4.32,
    "MAR": 4.23,
    "APR": 4.29,
    "MAY": 4.71,
    "JUN": 4.97,
    "JUL": 5.09,
    "AUG": 5.48,
    "SEP": 5.62,
    "OCT": 5.48,
    "NOV": 5.46,
    "DEC": 4.98
  },
  {
    "LONG": -50.7,
    "LAT": "#VALOR!",
    "CIDADE": "Itaubal",
    "UF": "AMAPÁ",
    "anual": 4.74,
    "JAN": 4.32,
    "FEB": 4.05,
    "MAR": 3.98,
    "APR": 3.98,
    "MAY": 4.44,
    "JUN": 4.8,
    "JUL": 5.03,
    "AUG": 5.45,
    "SEP": 5.52,
    "OCT": 5.46,
    "NOV": 5.15,
    "DEC": 4.65
  },
  {
    "LONG": -51.4,
    "LAT": "#VALOR!",
    "CIDADE": "Porto Grande",
    "UF": "AMAPÁ",
    "anual": 4.55,
    "JAN": 4.19,
    "FEB": 4.06,
    "MAR": 4.05,
    "APR": 4.06,
    "MAY": 4.31,
    "JUN": 4.51,
    "JUL": 4.66,
    "AUG": 5.05,
    "SEP": 5.3,
    "OCT": 5.15,
    "NOV": 4.92,
    "DEC": 4.35
  },
  {
    "LONG": -51.95,
    "LAT": "#VALOR!",
    "CIDADE": "Pedra Branca do Amapari",
    "UF": "AMAPÁ",
    "anual": 4.56,
    "JAN": 4.18,
    "FEB": 4.04,
    "MAR": 4.09,
    "APR": 4.14,
    "MAY": 4.36,
    "JUN": 4.48,
    "JUL": 4.66,
    "AUG": 5.02,
    "SEP": 5.29,
    "OCT": 5.14,
    "NOV": 4.96,
    "DEC": 4.35
  },
  {
    "LONG": -5.2,
    "LAT": "#VALOR!",
    "CIDADE": "Serra do Navio",
    "UF": "AMAPÁ",
    "anual": 4.58,
    "JAN": 4.2,
    "FEB": 4.02,
    "MAR": 4.11,
    "APR": 4.17,
    "MAY": 4.36,
    "JUN": 4.45,
    "JUL": 4.65,
    "AUG": 5.01,
    "SEP": 5.33,
    "OCT": 5.23,
    "NOV": 4.97,
    "DEC": 4.41
  },
  {
    "LONG": -51.18,
    "LAT": "#VALOR!",
    "CIDADE": "Ferreira Gomes",
    "UF": "AMAPÁ",
    "anual": 4.69,
    "JAN": 4.26,
    "FEB": 4.02,
    "MAR": 4.05,
    "APR": 4.08,
    "MAY": 4.41,
    "JUN": 4.65,
    "JUL": 4.86,
    "AUG": 5.34,
    "SEP": 5.48,
    "OCT": 5.45,
    "NOV": 5.18,
    "DEC": 4.5
  },
  {
    "LONG": -5.08,
    "LAT": "#VALOR!",
    "CIDADE": "Cutias",
    "UF": "AMAPÁ",
    "anual": 4.72,
    "JAN": 4.33,
    "FEB": 4.03,
    "MAR": 3.93,
    "APR": 4,
    "MAY": 4.41,
    "JUN": 4.76,
    "JUL": 5,
    "AUG": 5.4,
    "SEP": 5.51,
    "OCT": 5.47,
    "NOV": 5.24,
    "DEC": 4.55
  },
  {
    "LONG": -50.91,
    "LAT": 1.51,
    "CIDADE": "Tartarugalzinho",
    "UF": "AMAPÁ",
    "anual": 4.6,
    "JAN": 4.18,
    "FEB": 3.94,
    "MAR": 3.95,
    "APR": 3.94,
    "MAY": 4.31,
    "JUN": 4.57,
    "JUL": 4.82,
    "AUG": 5.22,
    "SEP": 5.4,
    "OCT": 5.3,
    "NOV": 5.17,
    "DEC": 4.37
  },
  {
    "LONG": -50.78,
    "LAT": 1.74,
    "CIDADE": "Pracuúba",
    "UF": "AMAPÁ",
    "anual": 4.65,
    "JAN": 4.26,
    "FEB": 3.98,
    "MAR": 3.93,
    "APR": 3.92,
    "MAY": 4.31,
    "JUN": 4.5,
    "JUL": 4.81,
    "AUG": 5.21,
    "SEP": 5.54,
    "OCT": 5.48,
    "NOV": 5.3,
    "DEC": 4.59
  },
  {
    "LONG": -5.08,
    "LAT": 2.05,
    "CIDADE": "Amapá",
    "UF": "AMAPÁ",
    "anual": 4.8,
    "JAN": 4.32,
    "FEB": 4.15,
    "MAR": 4.12,
    "APR": 4.15,
    "MAY": 4.53,
    "JUN": 4.76,
    "JUL": 5.04,
    "AUG": 5.43,
    "SEP": 5.54,
    "OCT": 5.49,
    "NOV": 5.29,
    "DEC": 4.74
  },
  {
    "LONG": -50.95,
    "LAT": 2.49,
    "CIDADE": "Calçoene",
    "UF": "AMAPÁ",
    "anual": 4.78,
    "JAN": 4.21,
    "FEB": 4.08,
    "MAR": 4.08,
    "APR": 4.17,
    "MAY": 4.54,
    "JUN": 4.83,
    "JUL": 5.09,
    "AUG": 5.44,
    "SEP": 5.54,
    "OCT": 5.49,
    "NOV": 5.27,
    "DEC": 4.61
  },
  {
    "LONG": -51.83,
    "LAT": 3.84,
    "CIDADE": "Oiapoque",
    "UF": "AMAPÁ",
    "anual": 4.71,
    "JAN": 4.12,
    "FEB": 4.1,
    "MAR": 3.97,
    "APR": 4.41,
    "MAY": 4.31,
    "JUN": 4.54,
    "JUL": 4.88,
    "AUG": 5.35,
    "SEP": 5.73,
    "OCT": 5.52,
    "NOV": 5.25,
    "DEC": 4.39
  },
  {
    "LONG": -67.39,
    "LAT": -8.74,
    "CIDADE": "Boca do Acre",
    "UF": "AMAZONAS",
    "anual": 4.55,
    "JAN": 4.16,
    "FEB": 4.4,
    "MAR": 4.25,
    "APR": 4.48,
    "MAY": 4.16,
    "JUN": 4.48,
    "JUL": 4.71,
    "AUG": 5.17,
    "SEP": 5.14,
    "OCT": 4.86,
    "NOV": 4.54,
    "DEC": 4.29
  },
  {
    "LONG": -66.99,
    "LAT": -7.71,
    "CIDADE": "Pauini",
    "UF": "AMAZONAS",
    "anual": 4.5,
    "JAN": 4.04,
    "FEB": 4.37,
    "MAR": 4.26,
    "APR": 4.37,
    "MAY": 4.1,
    "JUN": 4.5,
    "JUL": 4.67,
    "AUG": 5.18,
    "SEP": 5.09,
    "OCT": 4.76,
    "NOV": 4.45,
    "DEC": 4.16
  },
  {
    "LONG": -72.59,
    "LAT": -7.54,
    "CIDADE": "Guajará",
    "UF": "AMAZONAS",
    "anual": 4.69,
    "JAN": 4.48,
    "FEB": 4.79,
    "MAR": 4.24,
    "APR": 4.57,
    "MAY": 4.51,
    "JUN": 4.48,
    "JUL": 4.63,
    "AUG": 5.28,
    "SEP": 5.18,
    "OCT": 4.93,
    "NOV": 4.75,
    "DEC": 4.44
  },
  {
    "LONG": -63.03,
    "LAT": -7.51,
    "CIDADE": "Humaitá",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.01,
    "FEB": 4.27,
    "MAR": 4.38,
    "APR": 4.4,
    "MAY": 4.13,
    "JUN": 4.73,
    "JUL": 4.81,
    "AUG": 5.14,
    "SEP": 5.01,
    "OCT": 4.8,
    "NOV": 4.47,
    "DEC": 4.19
  },
  {
    "LONG": -70.02,
    "LAT": -7.43,
    "CIDADE": "Envira",
    "UF": "AMAZONAS",
    "anual": 4.57,
    "JAN": 4.22,
    "FEB": 4.59,
    "MAR": 4.34,
    "APR": 4.44,
    "MAY": 4.21,
    "JUN": 4.33,
    "JUL": 4.57,
    "AUG": 5.14,
    "SEP": 5.2,
    "OCT": 4.9,
    "NOV": 4.52,
    "DEC": 4.3
  },
  {
    "LONG": -64.8,
    "LAT": -7.26,
    "CIDADE": "Lábrea",
    "UF": "AMAZONAS",
    "anual": 4.46,
    "JAN": 4.03,
    "FEB": 4.29,
    "MAR": 4.28,
    "APR": 4.29,
    "MAY": 4.01,
    "JUN": 4.52,
    "JUL": 4.64,
    "AUG": 5.07,
    "SEP": 5.01,
    "OCT": 4.77,
    "NOV": 4.46,
    "DEC": 4.15
  },
  {
    "LONG": -59.89,
    "LAT": -7.2,
    "CIDADE": "Apuí",
    "UF": "AMAZONAS",
    "anual": 4.46,
    "JAN": 3.89,
    "FEB": 4.26,
    "MAR": 4.3,
    "APR": 4.15,
    "MAY": 4.16,
    "JUN": 4.78,
    "JUL": 4.82,
    "AUG": 5.15,
    "SEP": 4.77,
    "OCT": 4.67,
    "NOV": 4.47,
    "DEC": 4.14
  },
  {
    "LONG": -71.69,
    "LAT": -0.71,
    "CIDADE": "Ipixuna",
    "UF": "AMAZONAS",
    "anual": 4.63,
    "JAN": 4.34,
    "FEB": 4.71,
    "MAR": 4.28,
    "APR": 4.52,
    "MAY": 4.37,
    "JUN": 4.35,
    "JUL": 4.61,
    "AUG": 5.25,
    "SEP": 5.21,
    "OCT": 4.89,
    "NOV": 4.64,
    "DEC": 4.36
  },
  {
    "LONG": -69.87,
    "LAT": -6.66,
    "CIDADE": "Eirunepé",
    "UF": "AMAZONAS",
    "anual": 4.55,
    "JAN": 4.15,
    "FEB": 4.64,
    "MAR": 4.35,
    "APR": 4.45,
    "MAY": 4.23,
    "JUN": 4.34,
    "JUL": 4.52,
    "AUG": 5.15,
    "SEP": 5.15,
    "OCT": 4.83,
    "NOV": 4.55,
    "DEC": 4.24
  },
  {
    "LONG": -64.4,
    "LAT": -6.53,
    "CIDADE": "Canutama",
    "UF": "AMAZONAS",
    "anual": 4.47,
    "JAN": 4.05,
    "FEB": 4.35,
    "MAR": 4.27,
    "APR": 4.33,
    "MAY": 4.03,
    "JUN": 4.48,
    "JUL": 4.62,
    "AUG": 5.11,
    "SEP": 4.96,
    "OCT": 4.81,
    "NOV": 4.5,
    "DEC": 4.15
  },
  {
    "LONG": -68.26,
    "LAT": -0.64,
    "CIDADE": "Itamarati",
    "UF": "AMAZONAS",
    "anual": 4.48,
    "JAN": 4.07,
    "FEB": 4.44,
    "MAR": 4.28,
    "APR": 4.32,
    "MAY": 4.12,
    "JUN": 4.4,
    "JUL": 4.49,
    "AUG": 5.13,
    "SEP": 5.14,
    "OCT": 4.75,
    "NOV": 4.47,
    "DEC": 4.18
  },
  {
    "LONG": -61.29,
    "LAT": -0.58,
    "CIDADE": "Manicoré",
    "UF": "AMAZONAS",
    "anual": 4.43,
    "JAN": 4.05,
    "FEB": 4.21,
    "MAR": 4.27,
    "APR": 4.18,
    "MAY": 3.95,
    "JUN": 4.5,
    "JUL": 4.63,
    "AUG": 5.12,
    "SEP": 4.94,
    "OCT": 4.69,
    "NOV": 4.52,
    "DEC": 4.13
  },
  {
    "LONG": -63.18,
    "LAT": -5.62,
    "CIDADE": "Tapauá",
    "UF": "AMAZONAS",
    "anual": 4.42,
    "JAN": 4.02,
    "FEB": 4.31,
    "MAR": 4.25,
    "APR": 4.2,
    "MAY": 3.93,
    "JUN": 4.38,
    "JUL": 4.54,
    "AUG": 5.07,
    "SEP": 5,
    "OCT": 4.79,
    "NOV": 4.47,
    "DEC": 4.12
  },
  {
    "LONG": -60.37,
    "LAT": -5.13,
    "CIDADE": "Novo Aripuanã",
    "UF": "AMAZONAS",
    "anual": 4.47,
    "JAN": 4,
    "FEB": 4.22,
    "MAR": 4.26,
    "APR": 4.1,
    "MAY": 4.08,
    "JUN": 4.56,
    "JUL": 4.65,
    "AUG": 5.22,
    "SEP": 5.07,
    "OCT": 4.82,
    "NOV": 4.56,
    "DEC": 4.16
  },
  {
    "LONG": -66.91,
    "LAT": -4.88,
    "CIDADE": "Carauari",
    "UF": "AMAZONAS",
    "anual": 4.44,
    "JAN": 4,
    "FEB": 4.43,
    "MAR": 4.36,
    "APR": 4.29,
    "MAY": 3.97,
    "JUN": 4.3,
    "JUL": 4.43,
    "AUG": 5.01,
    "SEP": 4.98,
    "OCT": 4.87,
    "NOV": 4.49,
    "DEC": 4.18
  },
  {
    "LONG": -70.19,
    "LAT": -4.37,
    "CIDADE": "Atalaia do Norte",
    "UF": "AMAZONAS",
    "anual": 4.52,
    "JAN": 4.22,
    "FEB": 4.6,
    "MAR": 4.49,
    "APR": 4.45,
    "MAY": 4.09,
    "JUN": 4.26,
    "JUL": 4.22,
    "AUG": 4.9,
    "SEP": 5.1,
    "OCT": 4.84,
    "NOV": 4.72,
    "DEC": 4.3
  },
  {
    "LONG": -70.03,
    "LAT": -4.38,
    "CIDADE": "Benjamin Constant",
    "UF": "AMAZONAS",
    "anual": 4.54,
    "JAN": 4.25,
    "FEB": 4.64,
    "MAR": 4.52,
    "APR": 4.47,
    "MAY": 4.14,
    "JUN": 4.3,
    "JUL": 4.25,
    "AUG": 4.96,
    "SEP": 5.09,
    "OCT": 4.85,
    "NOV": 4.73,
    "DEC": 4.3
  },
  {
    "LONG": -59.59,
    "LAT": -4.39,
    "CIDADE": "Borba",
    "UF": "AMAZONAS",
    "anual": 4.42,
    "JAN": 3.88,
    "FEB": 4.09,
    "MAR": 4.12,
    "APR": 4.04,
    "MAY": 4.07,
    "JUN": 4.45,
    "JUL": 4.57,
    "AUG": 5.16,
    "SEP": 5.09,
    "OCT": 4.9,
    "NOV": 4.54,
    "DEC": 4.15
  },
  {
    "LONG": -69.94,
    "LAT": -0.42,
    "CIDADE": "Tabatinga",
    "UF": "AMAZONAS",
    "anual": 4.51,
    "JAN": 4.16,
    "FEB": 4.61,
    "MAR": 4.5,
    "APR": 4.42,
    "MAY": 4.12,
    "JUN": 4.26,
    "JUL": 4.22,
    "AUG": 4.96,
    "SEP": 5.06,
    "OCT": 4.83,
    "NOV": 4.67,
    "DEC": 4.25
  },
  {
    "LONG": -63.14,
    "LAT": -4.09,
    "CIDADE": "Coari",
    "UF": "AMAZONAS",
    "anual": 4.52,
    "JAN": 4.19,
    "FEB": 4.48,
    "MAR": 4.49,
    "APR": 4.17,
    "MAY": 4,
    "JUN": 4.45,
    "JUL": 4.48,
    "AUG": 5.08,
    "SEP": 5.06,
    "OCT": 4.89,
    "NOV": 4.59,
    "DEC": 4.31
  },
  {
    "LONG": -61.36,
    "LAT": -3.9,
    "CIDADE": "Beruri",
    "UF": "AMAZONAS",
    "anual": 4.37,
    "JAN": 4.05,
    "FEB": 4.12,
    "MAR": 4.18,
    "APR": 3.95,
    "MAY": 3.91,
    "JUN": 4.29,
    "JUL": 4.43,
    "AUG": 5.04,
    "SEP": 4.97,
    "OCT": 4.81,
    "NOV": 4.51,
    "DEC": 4.15
  },
  {
    "LONG": -59.09,
    "LAT": -3.9,
    "CIDADE": "Nova Olinda do Norte",
    "UF": "AMAZONAS",
    "anual": 4.39,
    "JAN": 3.82,
    "FEB": 4.06,
    "MAR": 4,
    "APR": 3.93,
    "MAY": 4.04,
    "JUN": 4.49,
    "JUL": 4.6,
    "AUG": 5.12,
    "SEP": 5.09,
    "OCT": 4.84,
    "NOV": 4.61,
    "DEC": 4.09
  },
  {
    "LONG": -62.06,
    "LAT": -0.38,
    "CIDADE": "Codajás",
    "UF": "AMAZONAS",
    "anual": 4.37,
    "JAN": 3.98,
    "FEB": 4.18,
    "MAR": 4.24,
    "APR": 4,
    "MAY": 3.92,
    "JUN": 4.35,
    "JUL": 4.52,
    "AUG": 5.02,
    "SEP": 4.89,
    "OCT": 4.72,
    "NOV": 4.48,
    "DEC": 4.12
  },
  {
    "LONG": -60.37,
    "LAT": -3.77,
    "CIDADE": "Careiro",
    "UF": "AMAZONAS",
    "anual": 4.33,
    "JAN": 3.88,
    "FEB": 4,
    "MAR": 4.04,
    "APR": 3.87,
    "MAY": 3.88,
    "JUN": 4.37,
    "JUL": 4.5,
    "AUG": 5.02,
    "SEP": 4.97,
    "OCT": 4.75,
    "NOV": 4.6,
    "DEC": 4.05
  },
  {
    "LONG": -61.66,
    "LAT": -3.75,
    "CIDADE": "Anori",
    "UF": "AMAZONAS",
    "anual": 4.47,
    "JAN": 4.04,
    "FEB": 4.21,
    "MAR": 4.31,
    "APR": 4.08,
    "MAY": 4.05,
    "JUN": 4.5,
    "JUL": 4.62,
    "AUG": 5.18,
    "SEP": 5.01,
    "OCT": 4.87,
    "NOV": 4.56,
    "DEC": 4.2
  },
  {
    "LONG": -61.4,
    "LAT": -3.57,
    "CIDADE": "Anamã",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.12,
    "FEB": 4.23,
    "MAR": 4.36,
    "APR": 4.12,
    "MAY": 4.11,
    "JUN": 4.64,
    "JUL": 4.66,
    "AUG": 5.24,
    "SEP": 5.09,
    "OCT": 4.95,
    "NOV": 4.65,
    "DEC": 4.22
  },
  {
    "LONG": -5.91,
    "LAT": -3.59,
    "CIDADE": "Autazes",
    "UF": "AMAZONAS",
    "anual": 4.44,
    "JAN": 3.95,
    "FEB": 4.15,
    "MAR": 4.11,
    "APR": 3.92,
    "MAY": 4.06,
    "JUN": 4.49,
    "JUL": 4.51,
    "AUG": 5.12,
    "SEP": 5.15,
    "OCT": 4.92,
    "NOV": 4.68,
    "DEC": 4.18
  },
  {
    "LONG": -6.9,
    "LAT": -3.46,
    "CIDADE": "São Paulo de Olivença",
    "UF": "AMAZONAS",
    "anual": 4.52,
    "JAN": 4.16,
    "FEB": 4.6,
    "MAR": 4.54,
    "APR": 4.43,
    "MAY": 4.12,
    "JUN": 4.26,
    "JUL": 4.16,
    "AUG": 4.9,
    "SEP": 5.06,
    "OCT": 4.94,
    "NOV": 4.73,
    "DEC": 4.34
  },
  {
    "LONG": -66.07,
    "LAT": -3.48,
    "CIDADE": "Juruá",
    "UF": "AMAZONAS",
    "anual": 4.37,
    "JAN": 4.06,
    "FEB": 4.34,
    "MAR": 4.37,
    "APR": 4.19,
    "MAY": 3.88,
    "JUN": 4.14,
    "JUL": 4.27,
    "AUG": 4.83,
    "SEP": 4.84,
    "OCT": 4.74,
    "NOV": 4.53,
    "DEC": 4.22
  },
  {
    "LONG": -68.2,
    "LAT": -3.36,
    "CIDADE": "Amaturá",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.25,
    "FEB": 4.65,
    "MAR": 4.51,
    "APR": 4.41,
    "MAY": 4.05,
    "JUN": 4.27,
    "JUL": 4.22,
    "AUG": 4.93,
    "SEP": 5.06,
    "OCT": 4.96,
    "NOV": 4.68,
    "DEC": 4.35
  },
  {
    "LONG": -64.72,
    "LAT": -3.37,
    "CIDADE": "Tefé",
    "UF": "AMAZONAS",
    "anual": 4.54,
    "JAN": 4.24,
    "FEB": 4.49,
    "MAR": 4.54,
    "APR": 4.3,
    "MAY": 4.02,
    "JUN": 4.35,
    "JUL": 4.43,
    "AUG": 5.03,
    "SEP": 5.05,
    "OCT": 4.95,
    "NOV": 4.65,
    "DEC": 4.42
  },
  {
    "LONG": -60.46,
    "LAT": -3.44,
    "CIDADE": "Manaquiri",
    "UF": "AMAZONAS",
    "anual": 4.5,
    "JAN": 4.06,
    "FEB": 4.19,
    "MAR": 4.21,
    "APR": 4.02,
    "MAY": 4.07,
    "JUN": 4.57,
    "JUL": 4.67,
    "AUG": 5.17,
    "SEP": 5.1,
    "OCT": 4.98,
    "NOV": 4.76,
    "DEC": 4.18
  },
  {
    "LONG": -57.71,
    "LAT": -3.39,
    "CIDADE": "Maués",
    "UF": "AMAZONAS",
    "anual": 4.47,
    "JAN": 3.87,
    "FEB": 4.08,
    "MAR": 4.04,
    "APR": 3.88,
    "MAY": 4.07,
    "JUN": 4.63,
    "JUL": 4.66,
    "AUG": 5.24,
    "SEP": 5.1,
    "OCT": 5.05,
    "NOV": 4.73,
    "DEC": 4.28
  },
  {
    "LONG": -6.12,
    "LAT": -3.32,
    "CIDADE": "Caapiranga",
    "UF": "AMAZONAS",
    "anual": 4.38,
    "JAN": 3.99,
    "FEB": 4.16,
    "MAR": 4.18,
    "APR": 4.03,
    "MAY": 3.92,
    "JUN": 4.47,
    "JUL": 4.59,
    "AUG": 5.04,
    "SEP": 4.89,
    "OCT": 4.79,
    "NOV": 4.44,
    "DEC": 4.09
  },
  {
    "LONG": -6.06,
    "LAT": -0.33,
    "CIDADE": "Manacapuru",
    "UF": "AMAZONAS",
    "anual": 4.47,
    "JAN": 4.04,
    "FEB": 4.16,
    "MAR": 4.22,
    "APR": 4.05,
    "MAY": 4.03,
    "JUN": 4.59,
    "JUL": 4.66,
    "AUG": 5.1,
    "SEP": 4.98,
    "OCT": 4.9,
    "NOV": 4.69,
    "DEC": 4.2
  },
  {
    "LONG": -60.19,
    "LAT": -3.28,
    "CIDADE": "Iranduba",
    "UF": "AMAZONAS",
    "anual": 4.52,
    "JAN": 4.06,
    "FEB": 4.11,
    "MAR": 4.17,
    "APR": 4.07,
    "MAY": 4.14,
    "JUN": 4.67,
    "JUL": 4.75,
    "AUG": 5.23,
    "SEP": 5.15,
    "OCT": 4.96,
    "NOV": 4.8,
    "DEC": 4.16
  },
  {
    "LONG": -64.8,
    "LAT": -3.23,
    "CIDADE": "Alvarães",
    "UF": "AMAZONAS",
    "anual": 4.55,
    "JAN": 4.22,
    "FEB": 4.52,
    "MAR": 4.51,
    "APR": 4.35,
    "MAY": 4.08,
    "JUN": 4.39,
    "JUL": 4.47,
    "AUG": 5.04,
    "SEP": 5.05,
    "OCT": 4.96,
    "NOV": 4.68,
    "DEC": 4.39
  },
  {
    "LONG": -59.83,
    "LAT": -3.2,
    "CIDADE": "Careiro da Várzea",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.03,
    "FEB": 4.14,
    "MAR": 4.19,
    "APR": 4.07,
    "MAY": 4.11,
    "JUN": 4.69,
    "JUL": 4.72,
    "AUG": 5.24,
    "SEP": 5.23,
    "OCT": 4.95,
    "NOV": 4.78,
    "DEC": 4.23
  },
  {
    "LONG": -67.95,
    "LAT": -3.1,
    "CIDADE": "Santo Antônio do Içá",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.25,
    "FEB": 4.65,
    "MAR": 4.5,
    "APR": 4.44,
    "MAY": 4.09,
    "JUN": 4.24,
    "JUL": 4.25,
    "AUG": 4.92,
    "SEP": 5.04,
    "OCT": 4.95,
    "NOV": 4.67,
    "DEC": 4.32
  },
  {
    "LONG": -60.02,
    "LAT": -3.12,
    "CIDADE": "Manaus",
    "UF": "AMAZONAS",
    "anual": 4.33,
    "JAN": 3.87,
    "FEB": 3.97,
    "MAR": 4.04,
    "APR": 3.87,
    "MAY": 3.95,
    "JUN": 4.5,
    "JUL": 4.53,
    "AUG": 5.01,
    "SEP": 4.9,
    "OCT": 4.73,
    "NOV": 4.54,
    "DEC": 4.03
  },
  {
    "LONG": -58.45,
    "LAT": -0.31,
    "CIDADE": "Itacoatiara",
    "UF": "AMAZONAS",
    "anual": 4.54,
    "JAN": 4.03,
    "FEB": 4.13,
    "MAR": 4.14,
    "APR": 4.02,
    "MAY": 4.06,
    "JUN": 4.69,
    "JUL": 4.69,
    "AUG": 5.28,
    "SEP": 5.27,
    "OCT": 5.04,
    "NOV": 4.8,
    "DEC": 4.34
  },
  {
    "LONG": -58.15,
    "LAT": -3.13,
    "CIDADE": "Urucurituba",
    "UF": "AMAZONAS",
    "anual": 4.44,
    "JAN": 3.89,
    "FEB": 4.1,
    "MAR": 4.05,
    "APR": 3.88,
    "MAY": 4.03,
    "JUN": 4.62,
    "JUL": 4.59,
    "AUG": 5.17,
    "SEP": 5.12,
    "OCT": 4.93,
    "NOV": 4.67,
    "DEC": 4.23
  },
  {
    "LONG": -65.11,
    "LAT": -3,
    "CIDADE": "Uarini",
    "UF": "AMAZONAS",
    "anual": 4.57,
    "JAN": 4.34,
    "FEB": 4.65,
    "MAR": 4.58,
    "APR": 4.36,
    "MAY": 4.03,
    "JUN": 4.38,
    "JUL": 4.41,
    "AUG": 5.01,
    "SEP": 5.01,
    "OCT": 4.99,
    "NOV": 4.65,
    "DEC": 4.46
  },
  {
    "LONG": -57.59,
    "LAT": -2.97,
    "CIDADE": "Boa Vista do Ramos",
    "UF": "AMAZONAS",
    "anual": 4.38,
    "JAN": 3.84,
    "FEB": 4.01,
    "MAR": 3.99,
    "APR": 3.77,
    "MAY": 3.89,
    "JUN": 4.49,
    "JUL": 4.48,
    "AUG": 5.11,
    "SEP": 5.07,
    "OCT": 4.94,
    "NOV": 4.72,
    "DEC": 4.23
  },
  {
    "LONG": -67.79,
    "LAT": -2.87,
    "CIDADE": "Tonantins",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.31,
    "FEB": 4.7,
    "MAR": 4.51,
    "APR": 4.41,
    "MAY": 4.07,
    "JUN": 4.2,
    "JUL": 4.21,
    "AUG": 4.88,
    "SEP": 5.07,
    "OCT": 4.92,
    "NOV": 4.7,
    "DEC": 4.4
  },
  {
    "LONG": -58.21,
    "LAT": -2.84,
    "CIDADE": "Silves",
    "UF": "AMAZONAS",
    "anual": 4.45,
    "JAN": 3.9,
    "FEB": 4.05,
    "MAR": 4.05,
    "APR": 3.92,
    "MAY": 4.04,
    "JUN": 4.67,
    "JUL": 4.6,
    "AUG": 5.23,
    "SEP": 5.18,
    "OCT": 4.88,
    "NOV": 4.67,
    "DEC": 4.27
  },
  {
    "LONG": -57.07,
    "LAT": -2.8,
    "CIDADE": "Barreirinha",
    "UF": "AMAZONAS",
    "anual": 4.57,
    "JAN": 4.04,
    "FEB": 4.18,
    "MAR": 4.15,
    "APR": 4,
    "MAY": 4.15,
    "JUN": 4.75,
    "JUL": 4.65,
    "AUG": 5.29,
    "SEP": 5.13,
    "OCT": 5.17,
    "NOV": 4.96,
    "DEC": 4.42
  },
  {
    "LONG": -66.77,
    "LAT": -0.27,
    "CIDADE": "Jutaí",
    "UF": "AMAZONAS",
    "anual": 4.49,
    "JAN": 4.3,
    "FEB": 4.66,
    "MAR": 4.51,
    "APR": 4.32,
    "MAY": 3.97,
    "JUN": 4.23,
    "JUL": 4.2,
    "AUG": 4.85,
    "SEP": 5.01,
    "OCT": 4.87,
    "NOV": 4.65,
    "DEC": 4.38
  },
  {
    "LONG": -59.69,
    "LAT": -2.7,
    "CIDADE": "Rio Preto da Eva",
    "UF": "AMAZONAS",
    "anual": 4.33,
    "JAN": 3.93,
    "FEB": 4.04,
    "MAR": 4.02,
    "APR": 3.82,
    "MAY": 3.86,
    "JUN": 4.37,
    "JUL": 4.38,
    "AUG": 5.01,
    "SEP": 5.03,
    "OCT": 4.82,
    "NOV": 4.59,
    "DEC": 4.13
  },
  {
    "LONG": -58.03,
    "LAT": -2.74,
    "CIDADE": "Itapiranga",
    "UF": "AMAZONAS",
    "anual": 4.54,
    "JAN": 3.92,
    "FEB": 4.09,
    "MAR": 4.12,
    "APR": 4.02,
    "MAY": 4.15,
    "JUN": 4.69,
    "JUL": 4.63,
    "AUG": 5.26,
    "SEP": 5.3,
    "OCT": 5.08,
    "NOV": 4.86,
    "DEC": 4.36
  },
  {
    "LONG": -60.94,
    "LAT": -2.64,
    "CIDADE": "Novo Airão",
    "UF": "AMAZONAS",
    "anual": 4.54,
    "JAN": 4.11,
    "FEB": 4.26,
    "MAR": 4.24,
    "APR": 4.07,
    "MAY": 4.12,
    "JUN": 4.6,
    "JUL": 4.68,
    "AUG": 5.18,
    "SEP": 5.15,
    "OCT": 5,
    "NOV": 4.74,
    "DEC": 4.3
  },
  {
    "LONG": -57.87,
    "LAT": -2.56,
    "CIDADE": "São Sebastião do Uatumã",
    "UF": "AMAZONAS",
    "anual": 4.57,
    "JAN": 3.96,
    "FEB": 4.08,
    "MAR": 4.11,
    "APR": 4,
    "MAY": 4.14,
    "JUN": 4.8,
    "JUL": 4.73,
    "AUG": 5.32,
    "SEP": 5.26,
    "OCT": 5.14,
    "NOV": 4.9,
    "DEC": 4.39
  },
  {
    "LONG": -56.73,
    "LAT": -2.64,
    "CIDADE": "Parintins",
    "UF": "AMAZONAS",
    "anual": 4.62,
    "JAN": 4.17,
    "FEB": 4.23,
    "MAR": 4.16,
    "APR": 3.95,
    "MAY": 4.12,
    "JUN": 4.69,
    "JUL": 4.63,
    "AUG": 5.33,
    "SEP": 5.2,
    "OCT": 5.32,
    "NOV": 5.1,
    "DEC": 4.53
  },
  {
    "LONG": -66.09,
    "LAT": -2.52,
    "CIDADE": "Fonte Boa",
    "UF": "AMAZONAS",
    "anual": 4.58,
    "JAN": 4.39,
    "FEB": 4.68,
    "MAR": 4.59,
    "APR": 4.37,
    "MAY": 4.1,
    "JUN": 4.3,
    "JUL": 4.32,
    "AUG": 4.94,
    "SEP": 5.11,
    "OCT": 4.93,
    "NOV": 4.74,
    "DEC": 4.48
  },
  {
    "LONG": -57.75,
    "LAT": -2.53,
    "CIDADE": "Urucará",
    "UF": "AMAZONAS",
    "anual": 4.52,
    "JAN": 3.89,
    "FEB": 4.02,
    "MAR": 4.01,
    "APR": 3.92,
    "MAY": 4.07,
    "JUN": 4.76,
    "JUL": 4.73,
    "AUG": 5.34,
    "SEP": 5.25,
    "OCT": 5.1,
    "NOV": 4.8,
    "DEC": 4.33
  },
  {
    "LONG": -56.71,
    "LAT": -2.21,
    "CIDADE": "Nhamundá",
    "UF": "AMAZONAS",
    "anual": 4.65,
    "JAN": 4.1,
    "FEB": 4.16,
    "MAR": 4.21,
    "APR": 4.12,
    "MAY": 4.23,
    "JUN": 4.83,
    "JUL": 4.83,
    "AUG": 5.38,
    "SEP": 5.23,
    "OCT": 5.26,
    "NOV": 5.01,
    "DEC": 4.46
  },
  {
    "LONG": -60.02,
    "LAT": -2.03,
    "CIDADE": "Presidente Figueiredo",
    "UF": "AMAZONAS",
    "anual": 4.37,
    "JAN": 4.09,
    "FEB": 4.13,
    "MAR": 4.14,
    "APR": 3.9,
    "MAY": 3.89,
    "JUN": 4.38,
    "JUL": 4.34,
    "AUG": 4.95,
    "SEP": 4.97,
    "OCT": 4.85,
    "NOV": 4.56,
    "DEC": 4.26
  },
  {
    "LONG": -67,
    "LAT": -1.88,
    "CIDADE": "Japurá",
    "UF": "AMAZONAS",
    "anual": 4.56,
    "JAN": 4.45,
    "FEB": 4.71,
    "MAR": 4.56,
    "APR": 4.34,
    "MAY": 4.01,
    "JUN": 4.21,
    "JUL": 4.24,
    "AUG": 4.9,
    "SEP": 5.06,
    "OCT": 4.94,
    "NOV": 4.75,
    "DEC": 4.5
  },
  {
    "LONG": -65.57,
    "LAT": -1.85,
    "CIDADE": "Maraã",
    "UF": "AMAZONAS",
    "anual": 4.59,
    "JAN": 4.48,
    "FEB": 4.65,
    "MAR": 4.55,
    "APR": 4.38,
    "MAY": 4.06,
    "JUN": 4.31,
    "JUL": 4.39,
    "AUG": 5.01,
    "SEP": 5.12,
    "OCT": 4.96,
    "NOV": 4.7,
    "DEC": 4.47
  },
  {
    "LONG": -62.93,
    "LAT": "#VALOR!",
    "CIDADE": "Barcelos",
    "UF": "AMAZONAS",
    "anual": 4.61,
    "JAN": 4.49,
    "FEB": 4.65,
    "MAR": 4.52,
    "APR": 4.24,
    "MAY": 3.96,
    "JUN": 4.38,
    "JUL": 4.43,
    "AUG": 4.98,
    "SEP": 5.17,
    "OCT": 5.03,
    "NOV": 4.8,
    "DEC": 4.68
  },
  {
    "LONG": -65.01,
    "LAT": "#VALOR!",
    "CIDADE": "Santa Isabel do Rio Negro",
    "UF": "AMAZONAS",
    "anual": 4.6,
    "JAN": 4.56,
    "FEB": 4.72,
    "MAR": 4.57,
    "APR": 4.39,
    "MAY": 3.97,
    "JUN": 4.31,
    "JUL": 4.35,
    "AUG": 4.89,
    "SEP": 5.13,
    "OCT": 5.01,
    "NOV": 4.76,
    "DEC": 4.57
  },
  {
    "LONG": -67.08,
    "LAT": "#VALOR!",
    "CIDADE": "São Gabriel da Cachoeira",
    "UF": "AMAZONAS",
    "anual": 4.66,
    "JAN": 4.67,
    "FEB": 4.86,
    "MAR": 4.92,
    "APR": 4.55,
    "MAY": 4.21,
    "JUN": 4.32,
    "JUL": 4.3,
    "AUG": 4.74,
    "SEP": 5.02,
    "OCT": 5.01,
    "NOV": 4.78,
    "DEC": 4.58
  },
  {
    "LONG": -39.56,
    "LAT": -18.08,
    "CIDADE": "Mucuri",
    "UF": "BAHIA",
    "anual": 5.26,
    "JAN": 5.78,
    "FEB": 6.18,
    "MAR": 5.73,
    "APR": 5.23,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.56,
    "AUG": 5.24,
    "SEP": 5.42,
    "OCT": 5.28,
    "NOV": 5,
    "DEC": 5.56
  },
  {
    "LONG": -39.37,
    "LAT": -17.89,
    "CIDADE": "Nova Viçosa",
    "UF": "BAHIA",
    "anual": 5.18,
    "JAN": 5.64,
    "FEB": 6.01,
    "MAR": 5.63,
    "APR": 5.09,
    "MAY": 4.62,
    "JUN": 4.42,
    "JUL": 4.51,
    "AUG": 5.14,
    "SEP": 5.3,
    "OCT": 5.25,
    "NOV": 5,
    "DEC": 5.56
  },
  {
    "LONG": -40.11,
    "LAT": -17.68,
    "CIDADE": "Ibirapuã",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.6,
    "FEB": 5.89,
    "MAR": 5.47,
    "APR": 5,
    "MAY": 4.47,
    "JUN": 4.13,
    "JUL": 4.27,
    "AUG": 4.82,
    "SEP": 5.1,
    "OCT": 5.07,
    "NOV": 4.83,
    "DEC": 5.52
  },
  {
    "LONG": "#VALOR!",
    "LAT": -17.73,
    "CIDADE": "Caravelas",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.7,
    "FEB": 6.02,
    "MAR": 5.61,
    "APR": 4.94,
    "MAY": 4.48,
    "JUN": 4.29,
    "JUL": 4.42,
    "AUG": 5.04,
    "SEP": 5.2,
    "OCT": 5.25,
    "NOV": 5.06,
    "DEC": 5.67
  },
  {
    "LONG": -40.34,
    "LAT": -17.61,
    "CIDADE": "Lajedão",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.58,
    "FEB": 5.97,
    "MAR": 5.51,
    "APR": 5.05,
    "MAY": 4.46,
    "JUN": 4.15,
    "JUL": 4.21,
    "AUG": 4.78,
    "SEP": 5.15,
    "OCT": 5.11,
    "NOV": 4.86,
    "DEC": 5.46
  },
  {
    "LONG": -39.74,
    "LAT": -17.54,
    "CIDADE": "Teixeira de Freitas",
    "UF": "BAHIA",
    "anual": 4.98,
    "JAN": 5.6,
    "FEB": 5.8,
    "MAR": 5.32,
    "APR": 4.92,
    "MAY": 4.43,
    "JUN": 4.1,
    "JUL": 4.27,
    "AUG": 4.84,
    "SEP": 5.09,
    "OCT": 5.04,
    "NOV": 4.86,
    "DEC": 5.49
  },
  {
    "LONG": -39.2,
    "LAT": -17.52,
    "CIDADE": "Alcobaça",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.83,
    "FEB": 6.17,
    "MAR": 5.65,
    "APR": 4.98,
    "MAY": 4.5,
    "JUN": 4.32,
    "JUL": 4.49,
    "AUG": 5.06,
    "SEP": 5.3,
    "OCT": 5.3,
    "NOV": 5.15,
    "DEC": 5.74
  },
  {
    "LONG": -40.22,
    "LAT": -17.37,
    "CIDADE": "Medeiros Neto",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.61,
    "FEB": 5.93,
    "MAR": 5.56,
    "APR": 5.04,
    "MAY": 4.43,
    "JUN": 4.11,
    "JUL": 4.24,
    "AUG": 4.83,
    "SEP": 5.2,
    "OCT": 5.14,
    "NOV": 4.94,
    "DEC": 5.55
  },
  {
    "LONG": -39.22,
    "LAT": -17.34,
    "CIDADE": "Prado",
    "UF": "BAHIA",
    "anual": 5.22,
    "JAN": 5.76,
    "FEB": 6.15,
    "MAR": 5.69,
    "APR": 5,
    "MAY": 4.57,
    "JUN": 4.39,
    "JUL": 4.51,
    "AUG": 5.11,
    "SEP": 5.36,
    "OCT": 5.33,
    "NOV": 5.13,
    "DEC": 5.71
  },
  {
    "LONG": -40.33,
    "LAT": -17.16,
    "CIDADE": "Itanhém",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.54,
    "FEB": 5.95,
    "MAR": 5.57,
    "APR": 5.07,
    "MAY": 4.44,
    "JUN": 4.14,
    "JUL": 4.28,
    "AUG": 4.9,
    "SEP": 5.29,
    "OCT": 5.15,
    "NOV": 4.92,
    "DEC": 5.59
  },
  {
    "LONG": -40.1,
    "LAT": -17.22,
    "CIDADE": "Vereda",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.59,
    "FEB": 5.99,
    "MAR": 5.61,
    "APR": 5.04,
    "MAY": 4.45,
    "JUN": 4.13,
    "JUL": 4.27,
    "AUG": 4.84,
    "SEP": 5.22,
    "OCT": 5.15,
    "NOV": 4.94,
    "DEC": 5.57
  },
  {
    "LONG": -39.54,
    "LAT": -17.04,
    "CIDADE": "Itamaraju",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.45,
    "FEB": 5.8,
    "MAR": 5.47,
    "APR": 4.85,
    "MAY": 4.39,
    "JUN": 4.08,
    "JUL": 4.24,
    "AUG": 4.77,
    "SEP": 5.03,
    "OCT": 4.98,
    "NOV": 4.84,
    "DEC": 5.43
  },
  {
    "LONG": -40.16,
    "LAT": -16.84,
    "CIDADE": "Jucuruçu",
    "UF": "BAHIA",
    "anual": 4.98,
    "JAN": 5.44,
    "FEB": 5.84,
    "MAR": 5.52,
    "APR": 5.02,
    "MAY": 4.42,
    "JUN": 4.13,
    "JUL": 4.23,
    "AUG": 4.75,
    "SEP": 5.2,
    "OCT": 5.01,
    "NOV": 4.79,
    "DEC": 5.46
  },
  {
    "LONG": -3.98,
    "LAT": -16.58,
    "CIDADE": "Guaratinga",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.38,
    "FEB": 5.73,
    "MAR": 5.49,
    "APR": 5.01,
    "MAY": 4.49,
    "JUN": 4.13,
    "JUL": 4.32,
    "AUG": 4.69,
    "SEP": 5.04,
    "OCT": 4.93,
    "NOV": 4.77,
    "DEC": 5.39
  },
  {
    "LONG": -39.56,
    "LAT": -16.57,
    "CIDADE": "Itabela",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.37,
    "FEB": 5.78,
    "MAR": 5.45,
    "APR": 4.92,
    "MAY": 4.42,
    "JUN": 4.08,
    "JUL": 4.27,
    "AUG": 4.69,
    "SEP": 5.04,
    "OCT": 4.96,
    "NOV": 4.78,
    "DEC": 5.4
  },
  {
    "LONG": -39.58,
    "LAT": -1.64,
    "CIDADE": "Eunápolis",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.38,
    "FEB": 5.69,
    "MAR": 5.41,
    "APR": 4.97,
    "MAY": 4.41,
    "JUN": 4.09,
    "JUL": 4.27,
    "AUG": 4.68,
    "SEP": 5.04,
    "OCT": 4.96,
    "NOV": 4.78,
    "DEC": 5.39
  },
  {
    "LONG": -39.06,
    "LAT": -16.44,
    "CIDADE": "Porto Seguro",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.68,
    "FEB": 6.07,
    "MAR": 5.79,
    "APR": 5.1,
    "MAY": 4.61,
    "JUN": 4.33,
    "JUL": 4.53,
    "AUG": 5.07,
    "SEP": 5.32,
    "OCT": 5.31,
    "NOV": 5.1,
    "DEC": 5.66
  },
  {
    "LONG": -39.02,
    "LAT": -16.28,
    "CIDADE": "Santa Cruz Cabrália",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.64,
    "FEB": 6,
    "MAR": 5.71,
    "APR": 5.08,
    "MAY": 4.54,
    "JUN": 4.32,
    "JUL": 4.48,
    "AUG": 5.06,
    "SEP": 5.25,
    "OCT": 5.25,
    "NOV": 5.02,
    "DEC": 5.6
  },
  {
    "LONG": -39.61,
    "LAT": -16.08,
    "CIDADE": "Itagimirim",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.42,
    "FEB": 5.75,
    "MAR": 5.44,
    "APR": 4.99,
    "MAY": 4.35,
    "JUN": 4.07,
    "JUL": 4.21,
    "AUG": 4.69,
    "SEP": 5.08,
    "OCT": 5,
    "NOV": 4.89,
    "DEC": 5.44
  },
  {
    "LONG": -39.53,
    "LAT": -15.97,
    "CIDADE": "Itapebi",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.43,
    "FEB": 5.73,
    "MAR": 5.46,
    "APR": 4.98,
    "MAY": 4.33,
    "JUN": 4.08,
    "JUL": 4.23,
    "AUG": 4.74,
    "SEP": 5.01,
    "OCT": 4.95,
    "NOV": 4.82,
    "DEC": 5.41
  },
  {
    "LONG": -38.88,
    "LAT": -15.86,
    "CIDADE": "Belmonte",
    "UF": "BAHIA",
    "anual": 5.36,
    "JAN": 5.77,
    "FEB": 6.19,
    "MAR": 5.87,
    "APR": 5.24,
    "MAY": 4.73,
    "JUN": 4.41,
    "JUL": 4.62,
    "AUG": 5.3,
    "SEP": 5.5,
    "OCT": 5.54,
    "NOV": 5.37,
    "DEC": 5.8
  },
  {
    "LONG": -40.07,
    "LAT": -15.65,
    "CIDADE": "Itarantim",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.51,
    "FEB": 5.81,
    "MAR": 5.58,
    "APR": 5.03,
    "MAY": 4.41,
    "JUN": 4.03,
    "JUL": 4.18,
    "AUG": 4.68,
    "SEP": 5.19,
    "OCT": 5.07,
    "NOV": 4.9,
    "DEC": 5.56
  },
  {
    "LONG": -3.9,
    "LAT": -15.67,
    "CIDADE": "Canavieiras",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.73,
    "FEB": 6.12,
    "MAR": 5.84,
    "APR": 5.13,
    "MAY": 4.57,
    "JUN": 4.29,
    "JUL": 4.53,
    "AUG": 5.11,
    "SEP": 5.41,
    "OCT": 5.43,
    "NOV": 5.28,
    "DEC": 5.82
  },
  {
    "LONG": -40.42,
    "LAT": -1.56,
    "CIDADE": "Macarani",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.5,
    "FEB": 5.88,
    "MAR": 5.53,
    "APR": 5.04,
    "MAY": 4.4,
    "JUN": 3.98,
    "JUL": 4.14,
    "AUG": 4.56,
    "SEP": 5.11,
    "OCT": 4.96,
    "NOV": 4.84,
    "DEC": 5.54
  },
  {
    "LONG": -40.26,
    "LAT": -15.62,
    "CIDADE": "Maiquinique",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.4,
    "FEB": 5.78,
    "MAR": 5.42,
    "APR": 5.02,
    "MAY": 4.41,
    "JUN": 4.01,
    "JUL": 4.15,
    "AUG": 4.53,
    "SEP": 5.08,
    "OCT": 4.94,
    "NOV": 4.79,
    "DEC": 5.44
  },
  {
    "LONG": -39.87,
    "LAT": -15.59,
    "CIDADE": "Potiraguá",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.41,
    "FEB": 5.75,
    "MAR": 5.46,
    "APR": 4.94,
    "MAY": 4.34,
    "JUN": 4.01,
    "JUL": 4.17,
    "AUG": 4.67,
    "SEP": 5.05,
    "OCT": 4.95,
    "NOV": 4.82,
    "DEC": 5.46
  },
  {
    "LONG": -39.3,
    "LAT": -15.56,
    "CIDADE": "Mascote",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.39,
    "FEB": 5.68,
    "MAR": 5.51,
    "APR": 4.88,
    "MAY": 4.33,
    "JUN": 4,
    "JUL": 4.24,
    "AUG": 4.72,
    "SEP": 5.04,
    "OCT": 4.93,
    "NOV": 4.82,
    "DEC": 5.41
  },
  {
    "LONG": -41.24,
    "LAT": -15.51,
    "CIDADE": "Cândido Sales",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.52,
    "FEB": 5.87,
    "MAR": 5.42,
    "APR": 5.06,
    "MAY": 4.51,
    "JUN": 4.2,
    "JUL": 4.43,
    "AUG": 4.93,
    "SEP": 5.65,
    "OCT": 5.42,
    "NOV": 5.01,
    "DEC": 5.51
  },
  {
    "LONG": -40.91,
    "LAT": -15.53,
    "CIDADE": "Encruzilhada",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.57,
    "FEB": 5.75,
    "MAR": 5.38,
    "APR": 4.98,
    "MAY": 4.43,
    "JUN": 4.13,
    "JUL": 4.34,
    "AUG": 4.8,
    "SEP": 5.48,
    "OCT": 5.26,
    "NOV": 4.97,
    "DEC": 5.54
  },
  {
    "LONG": -40.74,
    "LAT": -15.45,
    "CIDADE": "Ribeirão do Largo",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.51,
    "FEB": 5.77,
    "MAR": 5.43,
    "APR": 4.96,
    "MAY": 4.4,
    "JUN": 4.06,
    "JUL": 4.25,
    "AUG": 4.68,
    "SEP": 5.3,
    "OCT": 5.18,
    "NOV": 4.88,
    "DEC": 5.47
  },
  {
    "LONG": -39.64,
    "LAT": -15.46,
    "CIDADE": "Pau Brasil",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.36,
    "FEB": 5.71,
    "MAR": 5.4,
    "APR": 4.91,
    "MAY": 4.32,
    "JUN": 4.02,
    "JUL": 4.22,
    "AUG": 4.77,
    "SEP": 5.23,
    "OCT": 5.08,
    "NOV": 4.86,
    "DEC": 5.4
  },
  {
    "LONG": -39.49,
    "LAT": -15.41,
    "CIDADE": "Camacan",
    "UF": "BAHIA",
    "anual": 4.84,
    "JAN": 5.31,
    "FEB": 5.56,
    "MAR": 5.44,
    "APR": 4.81,
    "MAY": 4.35,
    "JUN": 3.92,
    "JUL": 4.09,
    "AUG": 4.6,
    "SEP": 4.97,
    "OCT": 4.85,
    "NOV": 4.78,
    "DEC": 5.43
  },
  {
    "LONG": -3.93,
    "LAT": -15.43,
    "CIDADE": "Santa Luzia",
    "UF": "BAHIA",
    "anual": 4.81,
    "JAN": 5.32,
    "FEB": 5.58,
    "MAR": 5.37,
    "APR": 4.75,
    "MAY": 4.25,
    "JUN": 3.92,
    "JUL": 4.09,
    "AUG": 4.62,
    "SEP": 4.93,
    "OCT": 4.81,
    "NOV": 4.75,
    "DEC": 5.39
  },
  {
    "LONG": -39.42,
    "LAT": -15.27,
    "CIDADE": "Arataca",
    "UF": "BAHIA",
    "anual": 4.75,
    "JAN": 5.15,
    "FEB": 5.51,
    "MAR": 5.37,
    "APR": 4.77,
    "MAY": 4.26,
    "JUN": 3.88,
    "JUL": 4.07,
    "AUG": 4.48,
    "SEP": 4.83,
    "OCT": 4.75,
    "NOV": 4.65,
    "DEC": 5.29
  },
  {
    "LONG": -3.91,
    "LAT": -15.29,
    "CIDADE": "Una",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.6,
    "FEB": 5.94,
    "MAR": 5.65,
    "APR": 4.99,
    "MAY": 4.42,
    "JUN": 4.19,
    "JUL": 4.4,
    "AUG": 4.92,
    "SEP": 5.21,
    "OCT": 5.17,
    "NOV": 5.1,
    "DEC": 5.67
  },
  {
    "LONG": -40.63,
    "LAT": -15.24,
    "CIDADE": "Itambé",
    "UF": "BAHIA",
    "anual": 5,
    "JAN": 5.57,
    "FEB": 5.81,
    "MAR": 5.57,
    "APR": 5.02,
    "MAY": 4.4,
    "JUN": 3.96,
    "JUL": 4.17,
    "AUG": 4.55,
    "SEP": 5.24,
    "OCT": 5.14,
    "NOV": 4.99,
    "DEC": 5.57
  },
  {
    "LONG": -40.25,
    "LAT": -1.52,
    "CIDADE": "Itapetinga",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.4,
    "FEB": 5.79,
    "MAR": 5.48,
    "APR": 4.9,
    "MAY": 4.34,
    "JUN": 3.96,
    "JUL": 4.16,
    "AUG": 4.63,
    "SEP": 5.17,
    "OCT": 5.06,
    "NOV": 4.95,
    "DEC": 5.55
  },
  {
    "LONG": -39.49,
    "LAT": -1.52,
    "CIDADE": "Jussari",
    "UF": "BAHIA",
    "anual": 4.79,
    "JAN": 5.16,
    "FEB": 5.46,
    "MAR": 5.34,
    "APR": 4.81,
    "MAY": 4.31,
    "JUN": 4,
    "JUL": 4.2,
    "AUG": 4.59,
    "SEP": 4.86,
    "OCT": 4.78,
    "NOV": 4.65,
    "DEC": 5.27
  },
  {
    "LONG": -40.07,
    "LAT": -15.11,
    "CIDADE": "Itororó",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.39,
    "FEB": 5.71,
    "MAR": 5.44,
    "APR": 4.93,
    "MAY": 4.39,
    "JUN": 3.97,
    "JUL": 4.17,
    "AUG": 4.59,
    "SEP": 5.05,
    "OCT": 5,
    "NOV": 4.91,
    "DEC": 5.45
  },
  {
    "LONG": -39.72,
    "LAT": -15.14,
    "CIDADE": "Itaju do Colônia",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.45,
    "FEB": 5.57,
    "MAR": 5.48,
    "APR": 4.88,
    "MAY": 4.34,
    "JUN": 4.01,
    "JUL": 4.25,
    "AUG": 4.64,
    "SEP": 5.04,
    "OCT": 5.01,
    "NOV": 4.9,
    "DEC": 5.43
  },
  {
    "LONG": -39.34,
    "LAT": -15.08,
    "CIDADE": "São José da Vitória",
    "UF": "BAHIA",
    "anual": 4.77,
    "JAN": 5.25,
    "FEB": 5.52,
    "MAR": 5.27,
    "APR": 4.71,
    "MAY": 4.2,
    "JUN": 3.9,
    "JUL": 4.13,
    "AUG": 4.54,
    "SEP": 4.91,
    "OCT": 4.78,
    "NOV": 4.66,
    "DEC": 5.32
  },
  {
    "LONG": -42.37,
    "LAT": -15.02,
    "CIDADE": "Mortugaba",
    "UF": "BAHIA",
    "anual": 5.43,
    "JAN": 5.64,
    "FEB": 6.08,
    "MAR": 5.66,
    "APR": 5.24,
    "MAY": 4.91,
    "JUN": 4.64,
    "JUL": 4.97,
    "AUG": 5.57,
    "SEP": 5.93,
    "OCT": 5.76,
    "NOV": 5.1,
    "DEC": 5.59
  },
  {
    "LONG": -41.93,
    "LAT": -15.04,
    "CIDADE": "Cordeiros",
    "UF": "BAHIA",
    "anual": 5.4,
    "JAN": 5.7,
    "FEB": 6.08,
    "MAR": 5.64,
    "APR": 5.25,
    "MAY": 4.89,
    "JUN": 4.5,
    "JUL": 4.82,
    "AUG": 5.42,
    "SEP": 5.91,
    "OCT": 5.73,
    "NOV": 5.17,
    "DEC": 5.68
  },
  {
    "LONG": -4.14,
    "LAT": -14.98,
    "CIDADE": "Tremedal",
    "UF": "BAHIA",
    "anual": 5.33,
    "JAN": 5.67,
    "FEB": 6.04,
    "MAR": 5.68,
    "APR": 5.23,
    "MAY": 4.8,
    "JUN": 4.45,
    "JUL": 4.7,
    "AUG": 5.16,
    "SEP": 5.8,
    "OCT": 5.61,
    "NOV": 5.21,
    "DEC": 5.59
  },
  {
    "LONG": -41.27,
    "LAT": -15.03,
    "CIDADE": "Belo Campo",
    "UF": "BAHIA",
    "anual": 5.31,
    "JAN": 5.65,
    "FEB": 5.89,
    "MAR": 5.6,
    "APR": 5.17,
    "MAY": 4.81,
    "JUN": 4.39,
    "JUL": 4.67,
    "AUG": 5.22,
    "SEP": 5.86,
    "OCT": 5.59,
    "NOV": 5.22,
    "DEC": 5.6
  },
  {
    "LONG": -40.41,
    "LAT": -14.98,
    "CIDADE": "Caatiba",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.47,
    "FEB": 5.76,
    "MAR": 5.5,
    "APR": 4.86,
    "MAY": 4.35,
    "JUN": 3.93,
    "JUL": 4.11,
    "AUG": 4.55,
    "SEP": 5.24,
    "OCT": 5.14,
    "NOV": 4.96,
    "DEC": 5.53
  },
  {
    "LONG": -39.92,
    "LAT": -14.99,
    "CIDADE": "Firmino Alves",
    "UF": "BAHIA",
    "anual": 4.87,
    "JAN": 5.34,
    "FEB": 5.61,
    "MAR": 5.4,
    "APR": 4.87,
    "MAY": 4.32,
    "JUN": 4.01,
    "JUL": 4.17,
    "AUG": 4.57,
    "SEP": 5.02,
    "OCT": 4.9,
    "NOV": 4.83,
    "DEC": 5.42
  },
  {
    "LONG": -39.81,
    "LAT": -14.96,
    "CIDADE": "Santa Cruz da Vitória",
    "UF": "BAHIA",
    "anual": 4.89,
    "JAN": 5.38,
    "FEB": 5.59,
    "MAR": 5.42,
    "APR": 4.89,
    "MAY": 4.32,
    "JUN": 4.01,
    "JUL": 4.2,
    "AUG": 4.6,
    "SEP": 5.02,
    "OCT": 4.91,
    "NOV": 4.87,
    "DEC": 5.43
  },
  {
    "LONG": -39.3,
    "LAT": -14.96,
    "CIDADE": "Buerarema",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.37,
    "FEB": 5.63,
    "MAR": 5.4,
    "APR": 4.83,
    "MAY": 4.26,
    "JUN": 3.97,
    "JUL": 4.22,
    "AUG": 4.65,
    "SEP": 5.02,
    "OCT": 4.93,
    "NOV": 4.79,
    "DEC": 5.44
  },
  {
    "LONG": -41.97,
    "LAT": -14.9,
    "CIDADE": "Condeúba",
    "UF": "BAHIA",
    "anual": 5.43,
    "JAN": 5.71,
    "FEB": 6.08,
    "MAR": 5.73,
    "APR": 5.33,
    "MAY": 4.91,
    "JUN": 4.56,
    "JUL": 4.85,
    "AUG": 5.45,
    "SEP": 5.94,
    "OCT": 5.77,
    "NOV": 5.18,
    "DEC": 5.67
  },
  {
    "LONG": -41.72,
    "LAT": -14.94,
    "CIDADE": "Piripá",
    "UF": "BAHIA",
    "anual": 5.41,
    "JAN": 5.72,
    "FEB": 6.11,
    "MAR": 5.71,
    "APR": 5.37,
    "MAY": 4.85,
    "JUN": 4.46,
    "JUL": 4.82,
    "AUG": 5.39,
    "SEP": 5.87,
    "OCT": 5.73,
    "NOV": 5.2,
    "DEC": 5.63
  },
  {
    "LONG": -40.84,
    "LAT": -14.86,
    "CIDADE": "Vitória da Conquista",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.59,
    "FEB": 5.75,
    "MAR": 5.48,
    "APR": 5.08,
    "MAY": 4.59,
    "JUN": 4.25,
    "JUL": 4.43,
    "AUG": 4.91,
    "SEP": 5.62,
    "OCT": 5.46,
    "NOV": 5.04,
    "DEC": 5.53
  },
  {
    "LONG": -40.58,
    "LAT": -14.87,
    "CIDADE": "Barra do Choça",
    "UF": "BAHIA",
    "anual": 5.01,
    "JAN": 5.45,
    "FEB": 5.74,
    "MAR": 5.49,
    "APR": 4.92,
    "MAY": 4.47,
    "JUN": 4.05,
    "JUL": 4.27,
    "AUG": 4.66,
    "SEP": 5.38,
    "OCT": 5.28,
    "NOV": 4.95,
    "DEC": 5.46
  },
  {
    "LONG": -39.66,
    "LAT": -14.86,
    "CIDADE": "Floresta Azul",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.47,
    "FEB": 5.61,
    "MAR": 5.48,
    "APR": 4.88,
    "MAY": 4.33,
    "JUN": 4.04,
    "JUL": 4.26,
    "AUG": 4.66,
    "SEP": 5.08,
    "OCT": 4.99,
    "NOV": 4.86,
    "DEC": 5.42
  },
  {
    "LONG": -39.59,
    "LAT": -14.86,
    "CIDADE": "Ibicaraí",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.42,
    "FEB": 5.6,
    "MAR": 5.47,
    "APR": 4.88,
    "MAY": 4.35,
    "JUN": 4.02,
    "JUL": 4.22,
    "AUG": 4.65,
    "SEP": 5.06,
    "OCT": 4.96,
    "NOV": 4.86,
    "DEC": 5.43
  },
  {
    "LONG": -39.42,
    "LAT": -14.89,
    "CIDADE": "Itapé",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.39,
    "FEB": 5.64,
    "MAR": 5.49,
    "APR": 4.88,
    "MAY": 4.35,
    "JUN": 4.02,
    "JUL": 4.22,
    "AUG": 4.67,
    "SEP": 5.05,
    "OCT": 4.93,
    "NOV": 4.89,
    "DEC": 5.46
  },
  {
    "LONG": -42.66,
    "LAT": -14.77,
    "CIDADE": "Urandi",
    "UF": "BAHIA",
    "anual": 5.9,
    "JAN": 6.07,
    "FEB": 6.39,
    "MAR": 6.06,
    "APR": 5.86,
    "MAY": 5.49,
    "JUN": 5.41,
    "JUL": 5.54,
    "AUG": 6.06,
    "SEP": 6.29,
    "OCT": 6.15,
    "NOV": 5.54,
    "DEC": 5.97
  },
  {
    "LONG": -4.24,
    "LAT": -14.84,
    "CIDADE": "Jacaraci",
    "UF": "BAHIA",
    "anual": 5.46,
    "JAN": 5.62,
    "FEB": 6.04,
    "MAR": 5.62,
    "APR": 5.33,
    "MAY": 4.94,
    "JUN": 4.7,
    "JUL": 5.08,
    "AUG": 5.68,
    "SEP": 5.95,
    "OCT": 5.82,
    "NOV": 5.14,
    "DEC": 5.58
  },
  {
    "LONG": -40.14,
    "LAT": -1.48,
    "CIDADE": "Nova Canaã",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.41,
    "FEB": 5.76,
    "MAR": 5.52,
    "APR": 4.87,
    "MAY": 4.35,
    "JUN": 3.99,
    "JUL": 4.11,
    "AUG": 4.5,
    "SEP": 5.1,
    "OCT": 5.04,
    "NOV": 5,
    "DEC": 5.56
  },
  {
    "LONG": -40.09,
    "LAT": -14.75,
    "CIDADE": "Iguaí",
    "UF": "BAHIA",
    "anual": 4.9,
    "JAN": 5.34,
    "FEB": 5.69,
    "MAR": 5.5,
    "APR": 4.91,
    "MAY": 4.34,
    "JUN": 3.99,
    "JUL": 4.16,
    "AUG": 4.53,
    "SEP": 5.04,
    "OCT": 4.95,
    "NOV": 4.91,
    "DEC": 5.49
  },
  {
    "LONG": -39.99,
    "LAT": -14.85,
    "CIDADE": "Ibicuí",
    "UF": "BAHIA",
    "anual": 4.89,
    "JAN": 5.4,
    "FEB": 5.62,
    "MAR": 5.51,
    "APR": 4.88,
    "MAY": 4.35,
    "JUN": 4.06,
    "JUL": 4.15,
    "AUG": 4.57,
    "SEP": 4.95,
    "OCT": 4.85,
    "NOV": 4.81,
    "DEC": 5.48
  },
  {
    "LONG": -39.48,
    "LAT": -14.8,
    "CIDADE": "Barro Preto",
    "UF": "BAHIA",
    "anual": 4.81,
    "JAN": 5.22,
    "FEB": 5.48,
    "MAR": 5.41,
    "APR": 4.83,
    "MAY": 4.32,
    "JUN": 3.99,
    "JUL": 4.17,
    "AUG": 4.5,
    "SEP": 4.87,
    "OCT": 4.78,
    "NOV": 4.78,
    "DEC": 5.36
  },
  {
    "LONG": -39.28,
    "LAT": -1.48,
    "CIDADE": "Itabuna",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.4,
    "FEB": 5.71,
    "MAR": 5.53,
    "APR": 4.88,
    "MAY": 4.36,
    "JUN": 4.06,
    "JUL": 4.28,
    "AUG": 4.71,
    "SEP": 5.06,
    "OCT": 4.99,
    "NOV": 4.95,
    "DEC": 5.5
  },
  {
    "LONG": -39.05,
    "LAT": -14.79,
    "CIDADE": "Ilhéus",
    "UF": "BAHIA",
    "anual": 5.24,
    "JAN": 5.69,
    "FEB": 5.94,
    "MAR": 5.83,
    "APR": 5.09,
    "MAY": 4.56,
    "JUN": 4.29,
    "JUL": 4.52,
    "AUG": 4.98,
    "SEP": 5.42,
    "OCT": 5.4,
    "NOV": 5.34,
    "DEC": 5.82
  },
  {
    "LONG": -42.51,
    "LAT": -1.47,
    "CIDADE": "Licínio de Almeida",
    "UF": "BAHIA",
    "anual": 5.69,
    "JAN": 5.81,
    "FEB": 6.18,
    "MAR": 5.85,
    "APR": 5.61,
    "MAY": 5.22,
    "JUN": 5.09,
    "JUL": 5.32,
    "AUG": 5.88,
    "SEP": 6.13,
    "OCT": 6.06,
    "NOV": 5.37,
    "DEC": 5.78
  },
  {
    "LONG": -41.68,
    "LAT": -14.69,
    "CIDADE": "Presidente Jânio Quadros",
    "UF": "BAHIA",
    "anual": 5.39,
    "JAN": 5.65,
    "FEB": 6.03,
    "MAR": 5.67,
    "APR": 5.27,
    "MAY": 4.88,
    "JUN": 4.49,
    "JUL": 4.81,
    "AUG": 5.29,
    "SEP": 5.86,
    "OCT": 5.73,
    "NOV": 5.26,
    "DEC": 5.67
  },
  {
    "LONG": -41.49,
    "LAT": -14.66,
    "CIDADE": "Maetinga",
    "UF": "BAHIA",
    "anual": 5.37,
    "JAN": 5.76,
    "FEB": 5.97,
    "MAR": 5.71,
    "APR": 5.25,
    "MAY": 4.82,
    "JUN": 4.47,
    "JUL": 4.76,
    "AUG": 5.25,
    "SEP": 5.89,
    "OCT": 5.69,
    "NOV": 5.24,
    "DEC": 5.66
  },
  {
    "LONG": -41.26,
    "LAT": -14.73,
    "CIDADE": "Caraíbas",
    "UF": "BAHIA",
    "anual": 5.41,
    "JAN": 5.77,
    "FEB": 6.04,
    "MAR": 5.8,
    "APR": 5.36,
    "MAY": 4.85,
    "JUN": 4.5,
    "JUL": 4.75,
    "AUG": 5.21,
    "SEP": 5.83,
    "OCT": 5.7,
    "NOV": 5.37,
    "DEC": 5.78
  },
  {
    "LONG": -40.47,
    "LAT": -1.47,
    "CIDADE": "Planalto",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.38,
    "FEB": 5.63,
    "MAR": 5.41,
    "APR": 4.85,
    "MAY": 4.48,
    "JUN": 4.12,
    "JUL": 4.29,
    "AUG": 4.7,
    "SEP": 5.35,
    "OCT": 5.27,
    "NOV": 4.97,
    "DEC": 5.42
  },
  {
    "LONG": -39.64,
    "LAT": -14.71,
    "CIDADE": "Almadina",
    "UF": "BAHIA",
    "anual": 4.74,
    "JAN": 5.17,
    "FEB": 5.45,
    "MAR": 5.31,
    "APR": 4.71,
    "MAY": 4.18,
    "JUN": 3.91,
    "JUL": 4.07,
    "AUG": 4.4,
    "SEP": 4.84,
    "OCT": 4.78,
    "NOV": 4.75,
    "DEC": 5.33
  },
  {
    "LONG": -39.37,
    "LAT": -14.68,
    "CIDADE": "Itajuípe",
    "UF": "BAHIA",
    "anual": 4.87,
    "JAN": 5.32,
    "FEB": 5.59,
    "MAR": 5.49,
    "APR": 4.83,
    "MAY": 4.35,
    "JUN": 4.06,
    "JUL": 4.19,
    "AUG": 4.56,
    "SEP": 4.95,
    "OCT": 4.86,
    "NOV": 4.8,
    "DEC": 5.41
  },
  {
    "LONG": -42.94,
    "LAT": -14.57,
    "CIDADE": "Sebastião Laranjeiras",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 6.1,
    "FEB": 6.36,
    "MAR": 6.03,
    "APR": 5.93,
    "MAY": 5.52,
    "JUN": 5.55,
    "JUL": 5.68,
    "AUG": 6.13,
    "SEP": 6.26,
    "OCT": 6.2,
    "NOV": 5.45,
    "DEC": 5.87
  },
  {
    "LONG": -4.11,
    "LAT": -14.61,
    "CIDADE": "Anagé",
    "UF": "BAHIA",
    "anual": 5.37,
    "JAN": 5.75,
    "FEB": 5.98,
    "MAR": 5.82,
    "APR": 5.28,
    "MAY": 4.84,
    "JUN": 4.47,
    "JUL": 4.67,
    "AUG": 5.14,
    "SEP": 5.8,
    "OCT": 5.66,
    "NOV": 5.33,
    "DEC": 5.75
  },
  {
    "LONG": -39.56,
    "LAT": -14.64,
    "CIDADE": "Coaraci",
    "UF": "BAHIA",
    "anual": 4.78,
    "JAN": 5.2,
    "FEB": 5.52,
    "MAR": 5.42,
    "APR": 4.8,
    "MAY": 4.25,
    "JUN": 3.95,
    "JUL": 4.08,
    "AUG": 4.45,
    "SEP": 4.83,
    "OCT": 4.79,
    "NOV": 4.75,
    "DEC": 5.33
  },
  {
    "LONG": -39.29,
    "LAT": -14.59,
    "CIDADE": "Uruçuca",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.42,
    "FEB": 5.66,
    "MAR": 5.56,
    "APR": 4.84,
    "MAY": 4.29,
    "JUN": 4.09,
    "JUL": 4.27,
    "AUG": 4.68,
    "SEP": 5.03,
    "OCT": 4.92,
    "NOV": 4.87,
    "DEC": 5.42
  },
  {
    "LONG": -42.69,
    "LAT": -14.49,
    "CIDADE": "Pindaí",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 6.16,
    "FEB": 6.39,
    "MAR": 6.12,
    "APR": 5.9,
    "MAY": 5.52,
    "JUN": 5.42,
    "JUL": 5.6,
    "AUG": 6.12,
    "SEP": 6.2,
    "OCT": 6.31,
    "NOV": 5.63,
    "DEC": 5.94
  },
  {
    "LONG": -42.22,
    "LAT": -14.5,
    "CIDADE": "Caculé",
    "UF": "BAHIA",
    "anual": 5.51,
    "JAN": 5.81,
    "FEB": 6.15,
    "MAR": 5.74,
    "APR": 5.29,
    "MAY": 4.93,
    "JUN": 4.63,
    "JUL": 4.97,
    "AUG": 5.56,
    "SEP": 5.96,
    "OCT": 5.95,
    "NOV": 5.35,
    "DEC": 5.76
  },
  {
    "LONG": -41.94,
    "LAT": -14.55,
    "CIDADE": "Guajeru",
    "UF": "BAHIA",
    "anual": 5.49,
    "JAN": 5.82,
    "FEB": 6.07,
    "MAR": 5.79,
    "APR": 5.28,
    "MAY": 4.89,
    "JUN": 4.57,
    "JUL": 4.89,
    "AUG": 5.48,
    "SEP": 5.95,
    "OCT": 5.92,
    "NOV": 5.41,
    "DEC": 5.85
  },
  {
    "LONG": -40.36,
    "LAT": -14.52,
    "CIDADE": "Poções",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.4,
    "FEB": 5.61,
    "MAR": 5.45,
    "APR": 4.92,
    "MAY": 4.55,
    "JUN": 4.15,
    "JUL": 4.36,
    "AUG": 4.72,
    "SEP": 5.32,
    "OCT": 5.25,
    "NOV": 5.02,
    "DEC": 5.46
  },
  {
    "LONG": -43.55,
    "LAT": -14.41,
    "CIDADE": "Iuiú",
    "UF": "BAHIA",
    "anual": 5.88,
    "JAN": 5.98,
    "FEB": 6.35,
    "MAR": 5.99,
    "APR": 5.96,
    "MAY": 5.49,
    "JUN": 5.45,
    "JUL": 5.75,
    "AUG": 6.08,
    "SEP": 6.07,
    "OCT": 6.11,
    "NOV": 5.48,
    "DEC": 5.89
  },
  {
    "LONG": -4.29,
    "LAT": -14.41,
    "CIDADE": "Candiba",
    "UF": "BAHIA",
    "anual": 5.91,
    "JAN": 6.06,
    "FEB": 6.38,
    "MAR": 6.1,
    "APR": 5.86,
    "MAY": 5.48,
    "JUN": 5.37,
    "JUL": 5.56,
    "AUG": 6.1,
    "SEP": 6.21,
    "OCT": 6.27,
    "NOV": 5.55,
    "DEC": 5.92
  },
  {
    "LONG": -42.07,
    "LAT": -14.41,
    "CIDADE": "Rio do Antônio",
    "UF": "BAHIA",
    "anual": 5.54,
    "JAN": 5.86,
    "FEB": 6.15,
    "MAR": 5.76,
    "APR": 5.31,
    "MAY": 5,
    "JUN": 4.66,
    "JUL": 4.95,
    "AUG": 5.55,
    "SEP": 5.96,
    "OCT": 6.01,
    "NOV": 5.45,
    "DEC": 5.83
  },
  {
    "LONG": -41.88,
    "LAT": -14.39,
    "CIDADE": "Malhada de Pedras",
    "UF": "BAHIA",
    "anual": 5.52,
    "JAN": 5.91,
    "FEB": 6.14,
    "MAR": 5.86,
    "APR": 5.3,
    "MAY": 4.9,
    "JUN": 4.59,
    "JUL": 4.87,
    "AUG": 5.47,
    "SEP": 5.96,
    "OCT": 5.95,
    "NOV": 5.46,
    "DEC": 5.86
  },
  {
    "LONG": -41.47,
    "LAT": -14.43,
    "CIDADE": "Aracatu",
    "UF": "BAHIA",
    "anual": 5.45,
    "JAN": 5.86,
    "FEB": 6.09,
    "MAR": 5.74,
    "APR": 5.24,
    "MAY": 4.92,
    "JUN": 4.5,
    "JUL": 4.76,
    "AUG": 5.31,
    "SEP": 5.95,
    "OCT": 5.81,
    "NOV": 5.45,
    "DEC": 5.82
  },
  {
    "LONG": -40.5,
    "LAT": -1.44,
    "CIDADE": "Bom Jesus da Serra",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.48,
    "FEB": 5.7,
    "MAR": 5.55,
    "APR": 4.87,
    "MAY": 4.54,
    "JUN": 4.2,
    "JUL": 4.31,
    "AUG": 4.82,
    "SEP": 5.48,
    "OCT": 5.34,
    "NOV": 5.11,
    "DEC": 5.5
  },
  {
    "LONG": -40.21,
    "LAT": -14.36,
    "CIDADE": "Boa Nova",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.45,
    "FEB": 5.73,
    "MAR": 5.55,
    "APR": 4.94,
    "MAY": 4.56,
    "JUN": 4.13,
    "JUL": 4.35,
    "AUG": 4.77,
    "SEP": 5.34,
    "OCT": 5.27,
    "NOV": 5.06,
    "DEC": 5.49
  },
  {
    "LONG": -39.9,
    "LAT": -14.43,
    "CIDADE": "Dário Meira",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.35,
    "FEB": 5.69,
    "MAR": 5.56,
    "APR": 4.85,
    "MAY": 4.41,
    "JUN": 4.04,
    "JUL": 4.17,
    "AUG": 4.49,
    "SEP": 4.94,
    "OCT": 4.84,
    "NOV": 4.84,
    "DEC": 5.43
  },
  {
    "LONG": -39.56,
    "LAT": -1.44,
    "CIDADE": "Itapitanga",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.37,
    "FEB": 5.61,
    "MAR": 5.49,
    "APR": 4.81,
    "MAY": 4.27,
    "JUN": 4.01,
    "JUL": 4.2,
    "AUG": 4.54,
    "SEP": 4.97,
    "OCT": 4.94,
    "NOV": 4.95,
    "DEC": 5.44
  },
  {
    "LONG": -43.77,
    "LAT": -1.43,
    "CIDADE": "Carinhanha",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 6.02,
    "FEB": 6.42,
    "MAR": 6.1,
    "APR": 6.01,
    "MAY": 5.71,
    "JUN": 5.61,
    "JUL": 5.89,
    "AUG": 6.22,
    "SEP": 6.23,
    "OCT": 6.2,
    "NOV": 5.49,
    "DEC": 5.85
  },
  {
    "LONG": -4.38,
    "LAT": -14.34,
    "CIDADE": "Malhada",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 6.02,
    "FEB": 6.42,
    "MAR": 6.1,
    "APR": 6.01,
    "MAY": 5.71,
    "JUN": 5.61,
    "JUL": 5.89,
    "AUG": 6.22,
    "SEP": 6.23,
    "OCT": 6.2,
    "NOV": 5.49,
    "DEC": 5.85
  },
  {
    "LONG": -43.16,
    "LAT": -1.43,
    "CIDADE": "Palmas de Monte Alto",
    "UF": "BAHIA",
    "anual": 5.93,
    "JAN": 6.03,
    "FEB": 6.33,
    "MAR": 6.03,
    "APR": 5.93,
    "MAY": 5.47,
    "JUN": 5.52,
    "JUL": 5.75,
    "AUG": 6.23,
    "SEP": 6.29,
    "OCT": 6.22,
    "NOV": 5.52,
    "DEC": 5.87
  },
  {
    "LONG": -42.26,
    "LAT": -14.27,
    "CIDADE": "Ibiassucê",
    "UF": "BAHIA",
    "anual": 5.58,
    "JAN": 5.81,
    "FEB": 6.14,
    "MAR": 5.77,
    "APR": 5.36,
    "MAY": 5,
    "JUN": 4.72,
    "JUL": 5.14,
    "AUG": 5.69,
    "SEP": 6.04,
    "OCT": 6.02,
    "NOV": 5.42,
    "DEC": 5.8
  },
  {
    "LONG": "#VALOR!",
    "LAT": -14.34,
    "CIDADE": "Caetanos",
    "UF": "BAHIA",
    "anual": 5.3,
    "JAN": 5.68,
    "FEB": 5.91,
    "MAR": 5.72,
    "APR": 5.18,
    "MAY": 4.79,
    "JUN": 4.38,
    "JUL": 4.55,
    "AUG": 5.04,
    "SEP": 5.68,
    "OCT": 5.59,
    "NOV": 5.34,
    "DEC": 5.69
  },
  {
    "LONG": -39.84,
    "LAT": -14.28,
    "CIDADE": "Itagibá",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.38,
    "FEB": 5.66,
    "MAR": 5.55,
    "APR": 4.89,
    "MAY": 4.35,
    "JUN": 4.02,
    "JUL": 4.22,
    "AUG": 4.58,
    "SEP": 4.97,
    "OCT": 4.93,
    "NOV": 4.88,
    "DEC": 5.45
  },
  {
    "LONG": -39.47,
    "LAT": -14.32,
    "CIDADE": "Gongogi",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.44,
    "FEB": 5.63,
    "MAR": 5.53,
    "APR": 4.84,
    "MAY": 4.29,
    "JUN": 3.99,
    "JUL": 4.24,
    "AUG": 4.6,
    "SEP": 5.09,
    "OCT": 5.01,
    "NOV": 4.94,
    "DEC": 5.47
  },
  {
    "LONG": -39.32,
    "LAT": -14.3,
    "CIDADE": "Ubaitaba",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.43,
    "FEB": 5.6,
    "MAR": 5.55,
    "APR": 4.81,
    "MAY": 4.27,
    "JUN": 3.98,
    "JUL": 4.2,
    "AUG": 4.6,
    "SEP": 5.05,
    "OCT": 5.01,
    "NOV": 4.93,
    "DEC": 5.49
  },
  {
    "LONG": -39.32,
    "LAT": -14.32,
    "CIDADE": "Aurelino Leal",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.43,
    "FEB": 5.6,
    "MAR": 5.55,
    "APR": 4.81,
    "MAY": 4.27,
    "JUN": 3.98,
    "JUL": 4.2,
    "AUG": 4.6,
    "SEP": 5.05,
    "OCT": 5.01,
    "NOV": 4.93,
    "DEC": 5.49
  },
  {
    "LONG": -39,
    "LAT": -14.28,
    "CIDADE": "Itacaré",
    "UF": "BAHIA",
    "anual": 5.34,
    "JAN": 5.84,
    "FEB": 6.06,
    "MAR": 5.96,
    "APR": 5.13,
    "MAY": 4.63,
    "JUN": 4.3,
    "JUL": 4.51,
    "AUG": 5.06,
    "SEP": 5.61,
    "OCT": 5.57,
    "NOV": 5.49,
    "DEC": 5.93
  },
  {
    "LONG": -44.54,
    "LAT": -14.18,
    "CIDADE": "Cocos",
    "UF": "BAHIA",
    "anual": 6,
    "JAN": 5.93,
    "FEB": 6.28,
    "MAR": 5.96,
    "APR": 6.08,
    "MAY": 5.83,
    "JUN": 5.79,
    "JUL": 6.08,
    "AUG": 6.45,
    "SEP": 6.45,
    "OCT": 6.11,
    "NOV": 5.34,
    "DEC": 5.71
  },
  {
    "LONG": -44.28,
    "LAT": -14.21,
    "CIDADE": "Feira da Mata",
    "UF": "BAHIA",
    "anual": 5.97,
    "JAN": 5.92,
    "FEB": 6.28,
    "MAR": 5.94,
    "APR": 6.05,
    "MAY": 5.8,
    "JUN": 5.72,
    "JUL": 6.05,
    "AUG": 6.34,
    "SEP": 6.34,
    "OCT": 6.07,
    "NOV": 5.34,
    "DEC": 5.75
  },
  {
    "LONG": -42.78,
    "LAT": -14.22,
    "CIDADE": "Guanambi",
    "UF": "BAHIA",
    "anual": 5.95,
    "JAN": 6.12,
    "FEB": 6.42,
    "MAR": 6.17,
    "APR": 5.91,
    "MAY": 5.54,
    "JUN": 5.39,
    "JUL": 5.62,
    "AUG": 6.08,
    "SEP": 6.13,
    "OCT": 6.35,
    "NOV": 5.62,
    "DEC": 6
  },
  {
    "LONG": -41.67,
    "LAT": -14.2,
    "CIDADE": "Brumado",
    "UF": "BAHIA",
    "anual": 5.51,
    "JAN": 5.89,
    "FEB": 6.14,
    "MAR": 5.81,
    "APR": 5.29,
    "MAY": 4.9,
    "JUN": 4.56,
    "JUL": 4.85,
    "AUG": 5.4,
    "SEP": 5.92,
    "OCT": 5.9,
    "NOV": 5.51,
    "DEC": 5.93
  },
  {
    "LONG": -40.77,
    "LAT": -14.24,
    "CIDADE": "Mirante",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.56,
    "FEB": 5.78,
    "MAR": 5.64,
    "APR": 5.04,
    "MAY": 4.67,
    "JUN": 4.31,
    "JUL": 4.47,
    "AUG": 4.99,
    "SEP": 5.64,
    "OCT": 5.49,
    "NOV": 5.31,
    "DEC": 5.59
  },
  {
    "LONG": -40,
    "LAT": -14.16,
    "CIDADE": "Itagi",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.42,
    "FEB": 5.73,
    "MAR": 5.54,
    "APR": 4.82,
    "MAY": 4.4,
    "JUN": 4.03,
    "JUL": 4.16,
    "AUG": 4.55,
    "SEP": 5.06,
    "OCT": 5.03,
    "NOV": 4.99,
    "DEC": 5.47
  },
  {
    "LONG": -39.6,
    "LAT": -14.21,
    "CIDADE": "Barra do Rocha",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.44,
    "FEB": 5.71,
    "MAR": 5.63,
    "APR": 4.87,
    "MAY": 4.28,
    "JUN": 3.99,
    "JUL": 4.23,
    "AUG": 4.65,
    "SEP": 5.1,
    "OCT": 5.02,
    "NOV": 4.97,
    "DEC": 5.43
  },
  {
    "LONG": -39.52,
    "LAT": -14.22,
    "CIDADE": "Ubatã",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.38,
    "FEB": 5.6,
    "MAR": 5.58,
    "APR": 4.81,
    "MAY": 4.27,
    "JUN": 4,
    "JUL": 4.22,
    "AUG": 4.59,
    "SEP": 5.08,
    "OCT": 5.04,
    "NOV": 4.95,
    "DEC": 5.41
  },
  {
    "LONG": -39.37,
    "LAT": -14.16,
    "CIDADE": "Ibirapitanga",
    "UF": "BAHIA",
    "anual": 4.86,
    "JAN": 5.38,
    "FEB": 5.53,
    "MAR": 5.49,
    "APR": 4.75,
    "MAY": 4.24,
    "JUN": 3.95,
    "JUL": 4.16,
    "AUG": 4.54,
    "SEP": 5,
    "OCT": 4.97,
    "NOV": 4.91,
    "DEC": 5.43
  },
  {
    "LONG": -42.49,
    "LAT": -14.07,
    "CIDADE": "Caetité",
    "UF": "BAHIA",
    "anual": 5.69,
    "JAN": 5.87,
    "FEB": 6.23,
    "MAR": 5.82,
    "APR": 5.51,
    "MAY": 5.1,
    "JUN": 4.96,
    "JUL": 5.28,
    "AUG": 5.9,
    "SEP": 6.17,
    "OCT": 6.13,
    "NOV": 5.47,
    "DEC": 5.81
  },
  {
    "LONG": -40.24,
    "LAT": -14.15,
    "CIDADE": "Manoel Vitorino",
    "UF": "BAHIA",
    "anual": 5,
    "JAN": 5.42,
    "FEB": 5.67,
    "MAR": 5.52,
    "APR": 4.87,
    "MAY": 4.44,
    "JUN": 4.08,
    "JUL": 4.22,
    "AUG": 4.65,
    "SEP": 5.27,
    "OCT": 5.15,
    "NOV": 5.09,
    "DEC": 5.57
  },
  {
    "LONG": -3.99,
    "LAT": -14.13,
    "CIDADE": "Aiquara",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.45,
    "FEB": 5.75,
    "MAR": 5.63,
    "APR": 4.87,
    "MAY": 4.36,
    "JUN": 3.99,
    "JUL": 4.16,
    "AUG": 4.61,
    "SEP": 5.13,
    "OCT": 5.04,
    "NOV": 5.02,
    "DEC": 5.49
  },
  {
    "LONG": -39.74,
    "LAT": -14.13,
    "CIDADE": "Ipiaú",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.42,
    "FEB": 5.7,
    "MAR": 5.57,
    "APR": 4.84,
    "MAY": 4.32,
    "JUN": 3.98,
    "JUL": 4.18,
    "AUG": 4.59,
    "SEP": 5.11,
    "OCT": 5.03,
    "NOV": 4.95,
    "DEC": 5.42
  },
  {
    "LONG": -39.64,
    "LAT": -14.06,
    "CIDADE": "Ibirataia",
    "UF": "BAHIA",
    "anual": 4.89,
    "JAN": 5.41,
    "FEB": 5.57,
    "MAR": 5.55,
    "APR": 4.77,
    "MAY": 4.26,
    "JUN": 3.94,
    "JUL": 4.15,
    "AUG": 4.55,
    "SEP": 5.08,
    "OCT": 5.01,
    "NOV": 4.94,
    "DEC": 5.39
  },
  {
    "LONG": -3.9,
    "LAT": -1.41,
    "CIDADE": "Maraú",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.51,
    "FEB": 5.78,
    "MAR": 5.7,
    "APR": 4.87,
    "MAY": 4.42,
    "JUN": 4.13,
    "JUL": 4.34,
    "AUG": 4.81,
    "SEP": 5.17,
    "OCT": 5.14,
    "NOV": 5.17,
    "DEC": 5.63
  },
  {
    "LONG": -42.14,
    "LAT": -1.4,
    "CIDADE": "Lagoa Real",
    "UF": "BAHIA",
    "anual": 5.63,
    "JAN": 5.89,
    "FEB": 6.23,
    "MAR": 5.86,
    "APR": 5.37,
    "MAY": 4.97,
    "JUN": 4.81,
    "JUL": 5.11,
    "AUG": 5.74,
    "SEP": 6.11,
    "OCT": 6.11,
    "NOV": 5.46,
    "DEC": 5.91
  },
  {
    "LONG": -41.25,
    "LAT": -14.02,
    "CIDADE": "Tanhaçu",
    "UF": "BAHIA",
    "anual": 5.41,
    "JAN": 5.79,
    "FEB": 6.01,
    "MAR": 5.81,
    "APR": 5.22,
    "MAY": 4.82,
    "JUN": 4.5,
    "JUL": 4.63,
    "AUG": 5.21,
    "SEP": 5.84,
    "OCT": 5.75,
    "NOV": 5.47,
    "DEC": 5.87
  },
  {
    "LONG": -39.89,
    "LAT": -14.02,
    "CIDADE": "Jitaúna",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.4,
    "FEB": 5.69,
    "MAR": 5.59,
    "APR": 4.82,
    "MAY": 4.35,
    "JUN": 3.93,
    "JUL": 4.11,
    "AUG": 4.54,
    "SEP": 5.08,
    "OCT": 4.99,
    "NOV": 5,
    "DEC": 5.46
  },
  {
    "LONG": -42.84,
    "LAT": -13.91,
    "CIDADE": "Matina",
    "UF": "BAHIA",
    "anual": 5.93,
    "JAN": 6.14,
    "FEB": 6.34,
    "MAR": 6.1,
    "APR": 5.9,
    "MAY": 5.56,
    "JUN": 5.45,
    "JUL": 5.66,
    "AUG": 6.11,
    "SEP": 6.24,
    "OCT": 6.29,
    "NOV": 5.51,
    "DEC": 5.91
  },
  {
    "LONG": -4.01,
    "LAT": -13.85,
    "CIDADE": "Jequié",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.48,
    "FEB": 5.78,
    "MAR": 5.62,
    "APR": 4.88,
    "MAY": 4.41,
    "JUN": 4.06,
    "JUL": 4.25,
    "AUG": 4.64,
    "SEP": 5.26,
    "OCT": 5.19,
    "NOV": 5.1,
    "DEC": 5.55
  },
  {
    "LONG": -39.74,
    "LAT": -13.86,
    "CIDADE": "Apuarema",
    "UF": "BAHIA",
    "anual": 4.8,
    "JAN": 5.27,
    "FEB": 5.5,
    "MAR": 5.43,
    "APR": 4.71,
    "MAY": 4.24,
    "JUN": 3.79,
    "JUL": 3.95,
    "AUG": 4.45,
    "SEP": 4.94,
    "OCT": 4.92,
    "NOV": 4.99,
    "DEC": 5.38
  },
  {
    "LONG": -39.11,
    "LAT": -13.94,
    "CIDADE": "Camamu",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.46,
    "FEB": 5.71,
    "MAR": 5.57,
    "APR": 4.76,
    "MAY": 4.29,
    "JUN": 3.94,
    "JUL": 4.17,
    "AUG": 4.61,
    "SEP": 5.04,
    "OCT": 5.02,
    "NOV": 5.03,
    "DEC": 5.5
  },
  {
    "LONG": -44.46,
    "LAT": -13.83,
    "CIDADE": "Coribe",
    "UF": "BAHIA",
    "anual": 5.93,
    "JAN": 5.9,
    "FEB": 6.17,
    "MAR": 5.78,
    "APR": 5.95,
    "MAY": 5.77,
    "JUN": 5.72,
    "JUL": 6.01,
    "AUG": 6.39,
    "SEP": 6.39,
    "OCT": 6.1,
    "NOV": 5.28,
    "DEC": 5.68
  },
  {
    "LONG": -42.72,
    "LAT": "#VALOR!",
    "CIDADE": "Igaporã",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 6,
    "FEB": 6.28,
    "MAR": 5.99,
    "APR": 5.94,
    "MAY": 5.53,
    "JUN": 5.43,
    "JUL": 5.69,
    "AUG": 6.24,
    "SEP": 6.38,
    "OCT": 6.24,
    "NOV": 5.47,
    "DEC": 5.84
  },
  {
    "LONG": -41.77,
    "LAT": -13.76,
    "CIDADE": "Dom Basílio",
    "UF": "BAHIA",
    "anual": 5.68,
    "JAN": 5.96,
    "FEB": 6.24,
    "MAR": 5.96,
    "APR": 5.52,
    "MAY": 5.05,
    "JUN": 4.81,
    "JUL": 5.09,
    "AUG": 5.62,
    "SEP": 6.17,
    "OCT": 6.13,
    "NOV": 5.61,
    "DEC": 5.95
  },
  {
    "LONG": -41.3,
    "LAT": -13.81,
    "CIDADE": "Ituaçu",
    "UF": "BAHIA",
    "anual": 5.33,
    "JAN": 5.7,
    "FEB": 5.9,
    "MAR": 5.68,
    "APR": 5.06,
    "MAY": 4.71,
    "JUN": 4.4,
    "JUL": 4.61,
    "AUG": 5.19,
    "SEP": 5.87,
    "OCT": 5.77,
    "NOV": 5.35,
    "DEC": 5.76
  },
  {
    "LONG": -4.1,
    "LAT": -13.76,
    "CIDADE": "Contendas do Sincorá",
    "UF": "BAHIA",
    "anual": 5.25,
    "JAN": 5.64,
    "FEB": 5.88,
    "MAR": 5.73,
    "APR": 5.07,
    "MAY": 4.63,
    "JUN": 4.31,
    "JUL": 4.52,
    "AUG": 5.03,
    "SEP": 5.74,
    "OCT": 5.53,
    "NOV": 5.26,
    "DEC": 5.68
  },
  {
    "LONG": -39.63,
    "LAT": -1.38,
    "CIDADE": "Nova Ibiá",
    "UF": "BAHIA",
    "anual": 4.85,
    "JAN": 5.43,
    "FEB": 5.58,
    "MAR": 5.49,
    "APR": 4.7,
    "MAY": 4.25,
    "JUN": 3.9,
    "JUL": 4.01,
    "AUG": 4.43,
    "SEP": 4.94,
    "OCT": 4.96,
    "NOV": 5.04,
    "DEC": 5.42
  },
  {
    "LONG": -39.68,
    "LAT": -13.78,
    "CIDADE": "Itamari",
    "UF": "BAHIA",
    "anual": 4.85,
    "JAN": 5.43,
    "FEB": 5.58,
    "MAR": 5.49,
    "APR": 4.7,
    "MAY": 4.25,
    "JUN": 3.9,
    "JUL": 4.01,
    "AUG": 4.43,
    "SEP": 4.94,
    "OCT": 4.96,
    "NOV": 5.04,
    "DEC": 5.42
  },
  {
    "LONG": -39.38,
    "LAT": -13.76,
    "CIDADE": "Piraí do Norte",
    "UF": "BAHIA",
    "anual": 4.84,
    "JAN": 5.4,
    "FEB": 5.56,
    "MAR": 5.53,
    "APR": 4.68,
    "MAY": 4.22,
    "JUN": 3.92,
    "JUL": 4.11,
    "AUG": 4.47,
    "SEP": 4.95,
    "OCT": 4.93,
    "NOV": 4.97,
    "DEC": 5.4
  },
  {
    "LONG": -39.14,
    "LAT": -13.82,
    "CIDADE": "Igrapiúna",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.54,
    "FEB": 5.64,
    "MAR": 5.67,
    "APR": 4.84,
    "MAY": 4.32,
    "JUN": 4.01,
    "JUL": 4.22,
    "AUG": 4.58,
    "SEP": 5.08,
    "OCT": 5.04,
    "NOV": 5.08,
    "DEC": 5.5
  },
  {
    "LONG": -40.21,
    "LAT": -1.37,
    "CIDADE": "Lafaiete Coutinho",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.41,
    "FEB": 5.66,
    "MAR": 5.5,
    "APR": 4.73,
    "MAY": 4.33,
    "JUN": 4,
    "JUL": 4.16,
    "AUG": 4.62,
    "SEP": 5.35,
    "OCT": 5.17,
    "NOV": 5.06,
    "DEC": 5.43
  },
  {
    "LONG": -39.48,
    "LAT": -13.69,
    "CIDADE": "Wenceslau Guimarães",
    "UF": "BAHIA",
    "anual": 4.87,
    "JAN": 5.45,
    "FEB": 5.62,
    "MAR": 5.58,
    "APR": 4.73,
    "MAY": 4.23,
    "JUN": 3.86,
    "JUL": 4.13,
    "AUG": 4.51,
    "SEP": 5,
    "OCT": 4.95,
    "NOV": 5,
    "DEC": 5.42
  },
  {
    "LONG": -39.49,
    "LAT": -13.75,
    "CIDADE": "Gandu",
    "UF": "BAHIA",
    "anual": 4.87,
    "JAN": 5.45,
    "FEB": 5.62,
    "MAR": 5.58,
    "APR": 4.73,
    "MAY": 4.23,
    "JUN": 3.86,
    "JUL": 4.13,
    "AUG": 4.51,
    "SEP": 5,
    "OCT": 4.95,
    "NOV": 5,
    "DEC": 5.42
  },
  {
    "LONG": -39.15,
    "LAT": -1.37,
    "CIDADE": "Ituberá",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.54,
    "FEB": 5.61,
    "MAR": 5.62,
    "APR": 4.85,
    "MAY": 4.33,
    "JUN": 4.01,
    "JUL": 4.26,
    "AUG": 4.6,
    "SEP": 5.09,
    "OCT": 4.99,
    "NOV": 5.06,
    "DEC": 5.45
  },
  {
    "LONG": -4.45,
    "LAT": -13.61,
    "CIDADE": "Jaborandi",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 5.94,
    "FEB": 6.08,
    "MAR": 5.77,
    "APR": 5.91,
    "MAY": 5.75,
    "JUN": 5.67,
    "JUL": 6.01,
    "AUG": 6.36,
    "SEP": 6.32,
    "OCT": 6.08,
    "NOV": 5.38,
    "DEC": 5.71
  },
  {
    "LONG": -42.94,
    "LAT": -1.36,
    "CIDADE": "Riacho de Santana",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 6.01,
    "FEB": 6.28,
    "MAR": 6.04,
    "APR": 5.9,
    "MAY": 5.6,
    "JUN": 5.5,
    "JUL": 5.76,
    "AUG": 6.21,
    "SEP": 6.36,
    "OCT": 6.09,
    "NOV": 5.4,
    "DEC": 5.85
  },
  {
    "LONG": -41.81,
    "LAT": -13.58,
    "CIDADE": "Rio de Contas",
    "UF": "BAHIA",
    "anual": 5.56,
    "JAN": 5.8,
    "FEB": 6.02,
    "MAR": 5.81,
    "APR": 5.29,
    "MAY": 4.92,
    "JUN": 4.72,
    "JUL": 5.01,
    "AUG": 5.64,
    "SEP": 6.18,
    "OCT": 6.05,
    "NOV": 5.41,
    "DEC": 5.83
  },
  {
    "LONG": -41.84,
    "LAT": -13.64,
    "CIDADE": "Livramento de Nossa Senhora",
    "UF": "BAHIA",
    "anual": 5.56,
    "JAN": 5.8,
    "FEB": 6.02,
    "MAR": 5.81,
    "APR": 5.29,
    "MAY": 4.92,
    "JUN": 4.72,
    "JUL": 5.01,
    "AUG": 5.64,
    "SEP": 6.18,
    "OCT": 6.05,
    "NOV": 5.41,
    "DEC": 5.83
  },
  {
    "LONG": -41.33,
    "LAT": -13.63,
    "CIDADE": "Barra da Estiva",
    "UF": "BAHIA",
    "anual": 5.28,
    "JAN": 5.68,
    "FEB": 5.92,
    "MAR": 5.65,
    "APR": 4.97,
    "MAY": 4.62,
    "JUN": 4.33,
    "JUL": 4.45,
    "AUG": 5.12,
    "SEP": 5.87,
    "OCT": 5.75,
    "NOV": 5.31,
    "DEC": 5.73
  },
  {
    "LONG": -39.49,
    "LAT": -13.59,
    "CIDADE": "Teolândia",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.49,
    "FEB": 5.55,
    "MAR": 5.59,
    "APR": 4.74,
    "MAY": 4.3,
    "JUN": 3.89,
    "JUL": 4.11,
    "AUG": 4.5,
    "SEP": 5.04,
    "OCT": 4.93,
    "NOV": 5,
    "DEC": 5.41
  },
  {
    "LONG": -39.11,
    "LAT": -13.6,
    "CIDADE": "Nilo Peçanha",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.5,
    "FEB": 5.56,
    "MAR": 5.58,
    "APR": 4.81,
    "MAY": 4.35,
    "JUN": 4.01,
    "JUL": 4.24,
    "AUG": 4.63,
    "SEP": 5.1,
    "OCT": 5,
    "NOV": 5.11,
    "DEC": 5.46
  },
  {
    "LONG": -43.58,
    "LAT": -13.54,
    "CIDADE": "Serra do Ramalho",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 6.15,
    "FEB": 6.33,
    "MAR": 6.03,
    "APR": 6.03,
    "MAY": 5.73,
    "JUN": 5.6,
    "JUL": 5.82,
    "AUG": 6.13,
    "SEP": 6.22,
    "OCT": 6.28,
    "NOV": 5.61,
    "DEC": 5.91
  },
  {
    "LONG": -42.49,
    "LAT": -13.55,
    "CIDADE": "Tanque Novo",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 6.05,
    "FEB": 6.33,
    "MAR": 6.07,
    "APR": 5.85,
    "MAY": 5.58,
    "JUN": 5.4,
    "JUL": 5.62,
    "AUG": 6.1,
    "SEP": 6.31,
    "OCT": 6.27,
    "NOV": 5.56,
    "DEC": 5.96
  },
  {
    "LONG": -41.59,
    "LAT": -1.35,
    "CIDADE": "Jussiape",
    "UF": "BAHIA",
    "anual": 5.47,
    "JAN": 5.75,
    "FEB": 5.95,
    "MAR": 5.79,
    "APR": 5.19,
    "MAY": 4.85,
    "JUN": 4.58,
    "JUL": 4.77,
    "AUG": 5.45,
    "SEP": 6.08,
    "OCT": 5.94,
    "NOV": 5.44,
    "DEC": 5.89
  },
  {
    "LONG": -40.22,
    "LAT": -13.47,
    "CIDADE": "Lajedo do Tabocal",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.28,
    "FEB": 5.62,
    "MAR": 5.52,
    "APR": 4.73,
    "MAY": 4.41,
    "JUN": 3.97,
    "JUL": 4.18,
    "AUG": 4.67,
    "SEP": 5.39,
    "OCT": 5.23,
    "NOV": 5.11,
    "DEC": 5.39
  },
  {
    "LONG": -40.15,
    "LAT": -13.53,
    "CIDADE": "Itiruçu",
    "UF": "BAHIA",
    "anual": 4.9,
    "JAN": 5.33,
    "FEB": 5.59,
    "MAR": 5.46,
    "APR": 4.69,
    "MAY": 4.3,
    "JUN": 3.87,
    "JUL": 4.08,
    "AUG": 4.56,
    "SEP": 5.29,
    "OCT": 5.11,
    "NOV": 5.06,
    "DEC": 5.4
  },
  {
    "LONG": -39.96,
    "LAT": -13.53,
    "CIDADE": "Jaguaquara",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.32,
    "FEB": 5.6,
    "MAR": 5.54,
    "APR": 4.71,
    "MAY": 4.28,
    "JUN": 3.97,
    "JUL": 4.09,
    "AUG": 4.62,
    "SEP": 5.27,
    "OCT": 5.12,
    "NOV": 5.09,
    "DEC": 5.43
  },
  {
    "LONG": -39.42,
    "LAT": -13.45,
    "CIDADE": "Presidente Tancredo Neves",
    "UF": "BAHIA",
    "anual": 4.83,
    "JAN": 5.44,
    "FEB": 5.47,
    "MAR": 5.56,
    "APR": 4.68,
    "MAY": 4.29,
    "JUN": 3.87,
    "JUL": 4.07,
    "AUG": 4.45,
    "SEP": 4.94,
    "OCT": 4.85,
    "NOV": 4.97,
    "DEC": 5.33
  },
  {
    "LONG": -39.1,
    "LAT": -13.54,
    "CIDADE": "Taperoá",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.52,
    "FEB": 5.66,
    "MAR": 5.6,
    "APR": 4.78,
    "MAY": 4.32,
    "JUN": 4,
    "JUL": 4.22,
    "AUG": 4.63,
    "SEP": 5.12,
    "OCT": 5.02,
    "NOV": 5.17,
    "DEC": 5.51
  },
  {
    "LONG": -39.05,
    "LAT": -13.49,
    "CIDADE": "Cairu",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.63,
    "FEB": 5.81,
    "MAR": 5.74,
    "APR": 4.84,
    "MAY": 4.39,
    "JUN": 4.06,
    "JUL": 4.31,
    "AUG": 4.68,
    "SEP": 5.26,
    "OCT": 5.2,
    "NOV": 5.36,
    "DEC": 5.68
  },
  {
    "LONG": -44.2,
    "LAT": -13.39,
    "CIDADE": "Santa Maria da Vitória",
    "UF": "BAHIA",
    "anual": 5.97,
    "JAN": 6.06,
    "FEB": 6.11,
    "MAR": 5.9,
    "APR": 5.97,
    "MAY": 5.81,
    "JUN": 5.73,
    "JUL": 6.03,
    "AUG": 6.34,
    "SEP": 6.35,
    "OCT": 6.13,
    "NOV": 5.43,
    "DEC": 5.77
  },
  {
    "LONG": -44.19,
    "LAT": -13.41,
    "CIDADE": "São Félix do Coribe",
    "UF": "BAHIA",
    "anual": 5.97,
    "JAN": 6.06,
    "FEB": 6.11,
    "MAR": 5.89,
    "APR": 5.97,
    "MAY": 5.77,
    "JUN": 5.71,
    "JUL": 5.99,
    "AUG": 6.29,
    "SEP": 6.39,
    "OCT": 6.17,
    "NOV": 5.46,
    "DEC": 5.79
  },
  {
    "LONG": -42.52,
    "LAT": -13.38,
    "CIDADE": "Botuporã",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 6.08,
    "FEB": 6.26,
    "MAR": 6.02,
    "APR": 5.78,
    "MAY": 5.53,
    "JUN": 5.48,
    "JUL": 5.64,
    "AUG": 6.18,
    "SEP": 6.35,
    "OCT": 6.21,
    "NOV": 5.56,
    "DEC": 5.92
  },
  {
    "LONG": -42.24,
    "LAT": -13.44,
    "CIDADE": "Paramirim",
    "UF": "BAHIA",
    "anual": 5.95,
    "JAN": 6.05,
    "FEB": 6.33,
    "MAR": 6.15,
    "APR": 5.91,
    "MAY": 5.61,
    "JUN": 5.47,
    "JUL": 5.58,
    "AUG": 6.02,
    "SEP": 6.28,
    "OCT": 6.3,
    "NOV": 5.68,
    "DEC": 5.99
  },
  {
    "LONG": -42.14,
    "LAT": -13.42,
    "CIDADE": "Érico Cardoso",
    "UF": "BAHIA",
    "anual": 5.87,
    "JAN": 5.91,
    "FEB": 6.2,
    "MAR": 6.01,
    "APR": 5.81,
    "MAY": 5.51,
    "JUN": 5.42,
    "JUL": 5.59,
    "AUG": 6.11,
    "SEP": 6.38,
    "OCT": 6.18,
    "NOV": 5.5,
    "DEC": 5.86
  },
  {
    "LONG": -41.28,
    "LAT": -13.41,
    "CIDADE": "Ibicoara",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.52,
    "FEB": 5.82,
    "MAR": 5.62,
    "APR": 4.88,
    "MAY": 4.51,
    "JUN": 4.17,
    "JUL": 4.34,
    "AUG": 4.92,
    "SEP": 5.67,
    "OCT": 5.51,
    "NOV": 5.15,
    "DEC": 5.54
  },
  {
    "LONG": -40.43,
    "LAT": -13.44,
    "CIDADE": "Maracás",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.31,
    "FEB": 5.59,
    "MAR": 5.44,
    "APR": 4.71,
    "MAY": 4.41,
    "JUN": 4.17,
    "JUL": 4.32,
    "AUG": 4.86,
    "SEP": 5.58,
    "OCT": 5.34,
    "NOV": 5.09,
    "DEC": 5.39
  },
  {
    "LONG": -39.94,
    "LAT": -13.45,
    "CIDADE": "Itaquara",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.35,
    "FEB": 5.58,
    "MAR": 5.51,
    "APR": 4.71,
    "MAY": 4.22,
    "JUN": 3.87,
    "JUL": 4.07,
    "AUG": 4.56,
    "SEP": 5.25,
    "OCT": 5.07,
    "NOV": 5.01,
    "DEC": 5.42
  },
  {
    "LONG": -3.98,
    "LAT": -13.36,
    "CIDADE": "Cravolândia",
    "UF": "BAHIA",
    "anual": 4.89,
    "JAN": 5.32,
    "FEB": 5.57,
    "MAR": 5.51,
    "APR": 4.71,
    "MAY": 4.24,
    "JUN": 3.91,
    "JUL": 4.14,
    "AUG": 4.59,
    "SEP": 5.22,
    "OCT": 5.08,
    "NOV": 4.99,
    "DEC": 5.4
  },
  {
    "LONG": -39.07,
    "LAT": -13.37,
    "CIDADE": "Valença",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.71,
    "FEB": 5.79,
    "MAR": 5.83,
    "APR": 4.82,
    "MAY": 4.4,
    "JUN": 4.05,
    "JUL": 4.26,
    "AUG": 4.68,
    "SEP": 5.3,
    "OCT": 5.29,
    "NOV": 5.42,
    "DEC": 5.72
  },
  {
    "LONG": -44.63,
    "LAT": -13.34,
    "CIDADE": "Correntina",
    "UF": "BAHIA",
    "anual": 5.96,
    "JAN": 5.94,
    "FEB": 6.09,
    "MAR": 5.85,
    "APR": 6.03,
    "MAY": 5.88,
    "JUN": 5.76,
    "JUL": 6.15,
    "AUG": 6.45,
    "SEP": 6.36,
    "OCT": 6.05,
    "NOV": 5.29,
    "DEC": 5.7
  },
  {
    "LONG": -43.41,
    "LAT": -1.33,
    "CIDADE": "Bom Jesus da Lapa",
    "UF": "BAHIA",
    "anual": 6,
    "JAN": 6.1,
    "FEB": 6.38,
    "MAR": 6.12,
    "APR": 6.11,
    "MAY": 5.74,
    "JUN": 5.62,
    "JUL": 5.83,
    "AUG": 6.13,
    "SEP": 6.23,
    "OCT": 6.25,
    "NOV": 5.57,
    "DEC": 5.91
  },
  {
    "LONG": -42.29,
    "LAT": -13.33,
    "CIDADE": "Caturama",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 5.97,
    "FEB": 6.32,
    "MAR": 6.12,
    "APR": 5.84,
    "MAY": 5.58,
    "JUN": 5.49,
    "JUL": 5.61,
    "AUG": 6.16,
    "SEP": 6.36,
    "OCT": 6.23,
    "NOV": 5.64,
    "DEC": 6
  },
  {
    "LONG": -41.66,
    "LAT": -13.25,
    "CIDADE": "Abaíra",
    "UF": "BAHIA",
    "anual": 5.37,
    "JAN": 5.64,
    "FEB": 5.9,
    "MAR": 5.64,
    "APR": 5.11,
    "MAY": 4.72,
    "JUN": 4.51,
    "JUL": 4.72,
    "AUG": 5.36,
    "SEP": 5.94,
    "OCT": 5.75,
    "NOV": 5.34,
    "DEC": 5.75
  },
  {
    "LONG": -40.96,
    "LAT": -13.29,
    "CIDADE": "Iramaia",
    "UF": "BAHIA",
    "anual": 5.12,
    "JAN": 5.42,
    "FEB": 5.79,
    "MAR": 5.66,
    "APR": 4.98,
    "MAY": 4.57,
    "JUN": 4.24,
    "JUL": 4.37,
    "AUG": 4.89,
    "SEP": 5.62,
    "OCT": 5.36,
    "NOV": 5.12,
    "DEC": 5.43
  },
  {
    "LONG": -40.37,
    "LAT": -13.26,
    "CIDADE": "Planaltino",
    "UF": "BAHIA",
    "anual": 5.03,
    "JAN": 5.39,
    "FEB": 5.72,
    "MAR": 5.56,
    "APR": 4.77,
    "MAY": 4.46,
    "JUN": 4.12,
    "JUL": 4.3,
    "AUG": 4.81,
    "SEP": 5.5,
    "OCT": 5.29,
    "NOV": 5.06,
    "DEC": 5.36
  },
  {
    "LONG": -40.09,
    "LAT": -13.26,
    "CIDADE": "Irajuba",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.37,
    "FEB": 5.51,
    "MAR": 5.49,
    "APR": 4.68,
    "MAY": 4.21,
    "JUN": 3.89,
    "JUL": 4.09,
    "AUG": 4.56,
    "SEP": 5.29,
    "OCT": 5.1,
    "NOV": 5.01,
    "DEC": 5.33
  },
  {
    "LONG": -39.82,
    "LAT": -13.3,
    "CIDADE": "Santa Inês",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.35,
    "FEB": 5.56,
    "MAR": 5.55,
    "APR": 4.71,
    "MAY": 4.18,
    "JUN": 3.84,
    "JUL": 4.08,
    "AUG": 4.53,
    "SEP": 5.21,
    "OCT": 5.11,
    "NOV": 5.06,
    "DEC": 5.44
  },
  {
    "LONG": -39.66,
    "LAT": -13.27,
    "CIDADE": "Ubaíra",
    "UF": "BAHIA",
    "anual": 4.86,
    "JAN": 5.39,
    "FEB": 5.63,
    "MAR": 5.59,
    "APR": 4.72,
    "MAY": 4.22,
    "JUN": 3.86,
    "JUL": 4.04,
    "AUG": 4.48,
    "SEP": 5.08,
    "OCT": 4.97,
    "NOV": 4.99,
    "DEC": 5.42
  },
  {
    "LONG": -39.57,
    "LAT": -1.33,
    "CIDADE": "Jiquiriçá",
    "UF": "BAHIA",
    "anual": 4.85,
    "JAN": 5.36,
    "FEB": 5.6,
    "MAR": 5.58,
    "APR": 4.74,
    "MAY": 4.28,
    "JUN": 3.92,
    "JUL": 4.06,
    "AUG": 4.42,
    "SEP": 4.99,
    "OCT": 4.86,
    "NOV": 4.96,
    "DEC": 5.38
  },
  {
    "LONG": -41.77,
    "LAT": -13.15,
    "CIDADE": "Piatã",
    "UF": "BAHIA",
    "anual": 5.32,
    "JAN": 5.5,
    "FEB": 5.84,
    "MAR": 5.56,
    "APR": 5.03,
    "MAY": 4.68,
    "JUN": 4.51,
    "JUL": 4.72,
    "AUG": 5.38,
    "SEP": 5.99,
    "OCT": 5.76,
    "NOV": 5.26,
    "DEC": 5.61
  },
  {
    "LONG": -39.5,
    "LAT": -13.23,
    "CIDADE": "Mutuípe",
    "UF": "BAHIA",
    "anual": 4.83,
    "JAN": 5.37,
    "FEB": 5.53,
    "MAR": 5.59,
    "APR": 4.74,
    "MAY": 4.27,
    "JUN": 3.87,
    "JUL": 4.04,
    "AUG": 4.43,
    "SEP": 4.98,
    "OCT": 4.86,
    "NOV": 4.93,
    "DEC": 5.38
  },
  {
    "LONG": -39.42,
    "LAT": -13.17,
    "CIDADE": "Laje",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.43,
    "FEB": 5.54,
    "MAR": 5.64,
    "APR": 4.81,
    "MAY": 4.32,
    "JUN": 3.99,
    "JUL": 4.14,
    "AUG": 4.53,
    "SEP": 5.09,
    "OCT": 4.99,
    "NOV": 5.07,
    "DEC": 5.42
  },
  {
    "LONG": -44.2,
    "LAT": -13.07,
    "CIDADE": "Canápolis",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 5.93,
    "FEB": 6.02,
    "MAR": 5.74,
    "APR": 5.86,
    "MAY": 5.85,
    "JUN": 5.76,
    "JUL": 6.13,
    "AUG": 6.49,
    "SEP": 6.44,
    "OCT": 6.09,
    "NOV": 5.32,
    "DEC": 5.63
  },
  {
    "LONG": -4.35,
    "LAT": -13.08,
    "CIDADE": "Sítio do Mato",
    "UF": "BAHIA",
    "anual": 6,
    "JAN": 6.14,
    "FEB": 6.31,
    "MAR": 6.1,
    "APR": 6.07,
    "MAY": 5.74,
    "JUN": 5.65,
    "JUL": 5.86,
    "AUG": 6.19,
    "SEP": 6.24,
    "OCT": 6.23,
    "NOV": 5.57,
    "DEC": 5.92
  },
  {
    "LONG": -4.23,
    "LAT": -1.31,
    "CIDADE": "Rio do Pires",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 6.01,
    "FEB": 6.33,
    "MAR": 6.11,
    "APR": 5.82,
    "MAY": 5.57,
    "JUN": 5.41,
    "JUL": 5.56,
    "AUG": 6.14,
    "SEP": 6.38,
    "OCT": 6.24,
    "NOV": 5.67,
    "DEC": 6
  },
  {
    "LONG": -39.79,
    "LAT": -1.31,
    "CIDADE": "Brejões",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.41,
    "FEB": 5.68,
    "MAR": 5.6,
    "APR": 4.73,
    "MAY": 4.3,
    "JUN": 4,
    "JUL": 4.16,
    "AUG": 4.64,
    "SEP": 5.23,
    "OCT": 5.14,
    "NOV": 5.09,
    "DEC": 5.43
  },
  {
    "LONG": -39,
    "LAT": -13.08,
    "CIDADE": "Aratuípe",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.65,
    "FEB": 5.77,
    "MAR": 5.82,
    "APR": 4.91,
    "MAY": 4.43,
    "JUN": 4.06,
    "JUL": 4.26,
    "AUG": 4.76,
    "SEP": 5.27,
    "OCT": 5.21,
    "NOV": 5.32,
    "DEC": 5.59
  },
  {
    "LONG": -38.89,
    "LAT": -13.11,
    "CIDADE": "Jaguaripe",
    "UF": "BAHIA",
    "anual": 5.25,
    "JAN": 5.8,
    "FEB": 5.92,
    "MAR": 5.91,
    "APR": 5,
    "MAY": 4.52,
    "JUN": 4.18,
    "JUL": 4.4,
    "AUG": 4.94,
    "SEP": 5.5,
    "OCT": 5.5,
    "NOV": 5.54,
    "DEC": 5.8
  },
  {
    "LONG": -4.41,
    "LAT": -12.98,
    "CIDADE": "Santana",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 6.06,
    "FEB": 6.16,
    "MAR": 5.92,
    "APR": 5.99,
    "MAY": 5.82,
    "JUN": 5.74,
    "JUL": 5.98,
    "AUG": 6.33,
    "SEP": 6.33,
    "OCT": 6.14,
    "NOV": 5.51,
    "DEC": 5.83
  },
  {
    "LONG": -42.69,
    "LAT": -1.3,
    "CIDADE": "Macaúbas",
    "UF": "BAHIA",
    "anual": 5.96,
    "JAN": 6.14,
    "FEB": 6.37,
    "MAR": 6.09,
    "APR": 5.92,
    "MAY": 5.57,
    "JUN": 5.48,
    "JUL": 5.65,
    "AUG": 6.14,
    "SEP": 6.27,
    "OCT": 6.21,
    "NOV": 5.7,
    "DEC": 6.01
  },
  {
    "LONG": -41.37,
    "LAT": -13.01,
    "CIDADE": "Mucugê",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.41,
    "FEB": 5.75,
    "MAR": 5.57,
    "APR": 4.94,
    "MAY": 4.55,
    "JUN": 4.29,
    "JUL": 4.41,
    "AUG": 5.02,
    "SEP": 5.66,
    "OCT": 5.34,
    "NOV": 4.99,
    "DEC": 5.41
  },
  {
    "LONG": -40.96,
    "LAT": -12.98,
    "CIDADE": "Itaeté",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.49,
    "FEB": 5.89,
    "MAR": 5.68,
    "APR": 5.02,
    "MAY": 4.62,
    "JUN": 4.3,
    "JUL": 4.47,
    "AUG": 5.03,
    "SEP": 5.62,
    "OCT": 5.35,
    "NOV": 5.08,
    "DEC": 5.37
  },
  {
    "LONG": -40.53,
    "LAT": -13.01,
    "CIDADE": "Marcionílio Souza",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.61,
    "FEB": 5.87,
    "MAR": 5.79,
    "APR": 5.06,
    "MAY": 4.62,
    "JUN": 4.23,
    "JUL": 4.45,
    "AUG": 4.93,
    "SEP": 5.62,
    "OCT": 5.45,
    "NOV": 5.32,
    "DEC": 5.56
  },
  {
    "LONG": -40.07,
    "LAT": -13.03,
    "CIDADE": "Nova Itarana",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.35,
    "FEB": 5.53,
    "MAR": 5.54,
    "APR": 4.78,
    "MAY": 4.32,
    "JUN": 3.97,
    "JUL": 4.22,
    "AUG": 4.66,
    "SEP": 5.3,
    "OCT": 5.13,
    "NOV": 5.06,
    "DEC": 5.39
  },
  {
    "LONG": -39.6,
    "LAT": -13.03,
    "CIDADE": "Amargosa",
    "UF": "BAHIA",
    "anual": 5.03,
    "JAN": 5.47,
    "FEB": 5.71,
    "MAR": 5.68,
    "APR": 4.88,
    "MAY": 4.46,
    "JUN": 4.1,
    "JUL": 4.27,
    "AUG": 4.7,
    "SEP": 5.29,
    "OCT": 5.18,
    "NOV": 5.17,
    "DEC": 5.46
  },
  {
    "LONG": -39.46,
    "LAT": -13.04,
    "CIDADE": "São Miguel das Matas",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.41,
    "FEB": 5.61,
    "MAR": 5.63,
    "APR": 4.78,
    "MAY": 4.32,
    "JUN": 3.95,
    "JUL": 4.14,
    "AUG": 4.57,
    "SEP": 5.14,
    "OCT": 5.05,
    "NOV": 5.12,
    "DEC": 5.44
  },
  {
    "LONG": -39.39,
    "LAT": -12.97,
    "CIDADE": "Varzedo",
    "UF": "BAHIA",
    "anual": 4.97,
    "JAN": 5.49,
    "FEB": 5.65,
    "MAR": 5.69,
    "APR": 4.84,
    "MAY": 4.35,
    "JUN": 3.99,
    "JUL": 4.15,
    "AUG": 4.6,
    "SEP": 5.15,
    "OCT": 5.09,
    "NOV": 5.17,
    "DEC": 5.47
  },
  {
    "LONG": -39.26,
    "LAT": -12.96,
    "CIDADE": "Santo Antônio de Jesus",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.55,
    "FEB": 5.69,
    "MAR": 5.68,
    "APR": 4.85,
    "MAY": 4.38,
    "JUN": 4.04,
    "JUL": 4.19,
    "AUG": 4.65,
    "SEP": 5.24,
    "OCT": 5.19,
    "NOV": 5.27,
    "DEC": 5.52
  },
  {
    "LONG": -39.11,
    "LAT": -1.3,
    "CIDADE": "Muniz Ferreira",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.58,
    "FEB": 5.69,
    "MAR": 5.74,
    "APR": 4.88,
    "MAY": 4.42,
    "JUN": 4.03,
    "JUL": 4.22,
    "AUG": 4.73,
    "SEP": 5.3,
    "OCT": 5.22,
    "NOV": 5.35,
    "DEC": 5.59
  },
  {
    "LONG": -39.01,
    "LAT": -13.03,
    "CIDADE": "Nazaré",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.6,
    "FEB": 5.72,
    "MAR": 5.78,
    "APR": 4.9,
    "MAY": 4.4,
    "JUN": 4.06,
    "JUL": 4.28,
    "AUG": 4.75,
    "SEP": 5.27,
    "OCT": 5.21,
    "NOV": 5.33,
    "DEC": 5.61
  },
  {
    "LONG": -38.62,
    "LAT": -12.96,
    "CIDADE": "Vera Cruz",
    "UF": "BAHIA",
    "anual": 5.36,
    "JAN": 5.96,
    "FEB": 6.04,
    "MAR": 5.99,
    "APR": 5.08,
    "MAY": 4.47,
    "JUN": 4.29,
    "JUL": 4.51,
    "AUG": 5.06,
    "SEP": 5.63,
    "OCT": 5.68,
    "NOV": 5.67,
    "DEC": 5.89
  },
  {
    "LONG": -38.5,
    "LAT": -12.97,
    "CIDADE": "Salvador",
    "UF": "BAHIA",
    "anual": 5.4,
    "JAN": 5.96,
    "FEB": 6.04,
    "MAR": 6.08,
    "APR": 5.13,
    "MAY": 4.53,
    "JUN": 4.36,
    "JUL": 4.54,
    "AUG": 5.13,
    "SEP": 5.66,
    "OCT": 5.7,
    "NOV": 5.7,
    "DEC": 5.92
  },
  {
    "LONG": -42.49,
    "LAT": -12.88,
    "CIDADE": "Ibipitanga",
    "UF": "BAHIA",
    "anual": 6.01,
    "JAN": 6.16,
    "FEB": 6.33,
    "MAR": 6.22,
    "APR": 5.92,
    "MAY": 5.6,
    "JUN": 5.49,
    "JUL": 5.64,
    "AUG": 6.22,
    "SEP": 6.37,
    "OCT": 6.35,
    "NOV": 5.73,
    "DEC": 6.08
  },
  {
    "LONG": -39.86,
    "LAT": -12.87,
    "CIDADE": "Milagres",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.45,
    "FEB": 5.67,
    "MAR": 5.64,
    "APR": 4.78,
    "MAY": 4.33,
    "JUN": 4.06,
    "JUL": 4.26,
    "AUG": 4.72,
    "SEP": 5.33,
    "OCT": 5.14,
    "NOV": 5.07,
    "DEC": 5.42
  },
  {
    "LONG": -3.95,
    "LAT": -12.95,
    "CIDADE": "Elísio Medrado",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.48,
    "FEB": 5.68,
    "MAR": 5.68,
    "APR": 4.84,
    "MAY": 4.38,
    "JUN": 4.15,
    "JUL": 4.29,
    "AUG": 4.7,
    "SEP": 5.32,
    "OCT": 5.19,
    "NOV": 5.13,
    "DEC": 5.45
  },
  {
    "LONG": -39.19,
    "LAT": -1.29,
    "CIDADE": "Dom Macedo Costa",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.61,
    "FEB": 5.71,
    "MAR": 5.75,
    "APR": 4.9,
    "MAY": 4.45,
    "JUN": 4.06,
    "JUL": 4.25,
    "AUG": 4.71,
    "SEP": 5.31,
    "OCT": 5.24,
    "NOV": 5.29,
    "DEC": 5.6
  },
  {
    "LONG": -38.76,
    "LAT": -12.87,
    "CIDADE": "Salinas da Margarida",
    "UF": "BAHIA",
    "anual": 5.34,
    "JAN": 5.94,
    "FEB": 5.97,
    "MAR": 6.04,
    "APR": 5.05,
    "MAY": 4.49,
    "JUN": 4.2,
    "JUL": 4.46,
    "AUG": 4.94,
    "SEP": 5.63,
    "OCT": 5.72,
    "NOV": 5.73,
    "DEC": 5.9
  },
  {
    "LONG": -38.68,
    "LAT": -12.89,
    "CIDADE": "Itaparica",
    "UF": "BAHIA",
    "anual": 5.37,
    "JAN": 5.9,
    "FEB": 6,
    "MAR": 6.07,
    "APR": 5.09,
    "MAY": 4.48,
    "JUN": 4.22,
    "JUL": 4.48,
    "AUG": 5,
    "SEP": 5.7,
    "OCT": 5.79,
    "NOV": 5.76,
    "DEC": 5.94
  },
  {
    "LONG": -38.32,
    "LAT": -12.9,
    "CIDADE": "Lauro de Freitas",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.79,
    "FEB": 5.88,
    "MAR": 5.9,
    "APR": 4.91,
    "MAY": 4.47,
    "JUN": 4.21,
    "JUL": 4.46,
    "AUG": 4.93,
    "SEP": 5.54,
    "OCT": 5.62,
    "NOV": 5.67,
    "DEC": 5.84
  },
  {
    "LONG": -43.95,
    "LAT": -12.76,
    "CIDADE": "Serra Dourada",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 6.08,
    "FEB": 6.14,
    "MAR": 5.93,
    "APR": 5.99,
    "MAY": 5.79,
    "JUN": 5.71,
    "JUL": 5.92,
    "AUG": 6.29,
    "SEP": 6.25,
    "OCT": 6.19,
    "NOV": 5.61,
    "DEC": 5.92
  },
  {
    "LONG": -42.73,
    "LAT": -1.28,
    "CIDADE": "Boquira",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 6.15,
    "FEB": 6.27,
    "MAR": 6.11,
    "APR": 5.85,
    "MAY": 5.57,
    "JUN": 5.59,
    "JUL": 5.74,
    "AUG": 6.27,
    "SEP": 6.36,
    "OCT": 6.27,
    "NOV": 5.73,
    "DEC": 5.99
  },
  {
    "LONG": -42.17,
    "LAT": -12.81,
    "CIDADE": "Novo Horizonte",
    "UF": "BAHIA",
    "anual": 5.82,
    "JAN": 5.87,
    "FEB": 6.05,
    "MAR": 5.92,
    "APR": 5.6,
    "MAY": 5.31,
    "JUN": 5.27,
    "JUL": 5.49,
    "AUG": 6.13,
    "SEP": 6.45,
    "OCT": 6.22,
    "NOV": 5.56,
    "DEC": 5.93
  },
  {
    "LONG": -41.33,
    "LAT": -12.8,
    "CIDADE": "Andaraí",
    "UF": "BAHIA",
    "anual": 5.03,
    "JAN": 5.38,
    "FEB": 5.73,
    "MAR": 5.62,
    "APR": 4.93,
    "MAY": 4.48,
    "JUN": 4.22,
    "JUL": 4.3,
    "AUG": 4.84,
    "SEP": 5.5,
    "OCT": 5.21,
    "NOV": 4.9,
    "DEC": 5.26
  },
  {
    "LONG": -41.07,
    "LAT": -12.82,
    "CIDADE": "Nova Redenção",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.34,
    "FEB": 5.65,
    "MAR": 5.62,
    "APR": 4.94,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.31,
    "AUG": 4.89,
    "SEP": 5.55,
    "OCT": 5.27,
    "NOV": 5,
    "DEC": 5.29
  },
  {
    "LONG": -40.21,
    "LAT": -12.77,
    "CIDADE": "Iaçu",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.55,
    "FEB": 5.7,
    "MAR": 5.78,
    "APR": 4.94,
    "MAY": 4.39,
    "JUN": 4.1,
    "JUL": 4.25,
    "AUG": 4.73,
    "SEP": 5.42,
    "OCT": 5.22,
    "NOV": 5.2,
    "DEC": 5.46
  },
  {
    "LONG": -39.52,
    "LAT": -12.77,
    "CIDADE": "Santa Teresinha",
    "UF": "BAHIA",
    "anual": 4.98,
    "JAN": 5.43,
    "FEB": 5.63,
    "MAR": 5.62,
    "APR": 4.79,
    "MAY": 4.33,
    "JUN": 4.09,
    "JUL": 4.27,
    "AUG": 4.67,
    "SEP": 5.23,
    "OCT": 5.14,
    "NOV": 5.11,
    "DEC": 5.41
  },
  {
    "LONG": -39.43,
    "LAT": -12.77,
    "CIDADE": "Castro Alves",
    "UF": "BAHIA",
    "anual": 4.98,
    "JAN": 5.44,
    "FEB": 5.64,
    "MAR": 5.6,
    "APR": 4.81,
    "MAY": 4.37,
    "JUN": 4.09,
    "JUL": 4.32,
    "AUG": 4.74,
    "SEP": 5.22,
    "OCT": 5.11,
    "NOV": 5.08,
    "DEC": 5.38
  },
  {
    "LONG": -39.17,
    "LAT": -1.28,
    "CIDADE": "Conceição do Almeida",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.53,
    "FEB": 5.69,
    "MAR": 5.72,
    "APR": 4.88,
    "MAY": 4.4,
    "JUN": 4.08,
    "JUL": 4.29,
    "AUG": 4.71,
    "SEP": 5.29,
    "OCT": 5.3,
    "NOV": 5.34,
    "DEC": 5.56
  },
  {
    "LONG": -39.09,
    "LAT": -12.85,
    "CIDADE": "São Felipe",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.63,
    "FEB": 5.68,
    "MAR": 5.74,
    "APR": 4.86,
    "MAY": 4.35,
    "JUN": 4.03,
    "JUL": 4.24,
    "AUG": 4.69,
    "SEP": 5.31,
    "OCT": 5.32,
    "NOV": 5.4,
    "DEC": 5.59
  },
  {
    "LONG": -38.92,
    "LAT": -12.78,
    "CIDADE": "Maragogipe",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.77,
    "FEB": 5.78,
    "MAR": 5.83,
    "APR": 4.92,
    "MAY": 4.36,
    "JUN": 4.04,
    "JUL": 4.29,
    "AUG": 4.71,
    "SEP": 5.37,
    "OCT": 5.41,
    "NOV": 5.53,
    "DEC": 5.72
  },
  {
    "LONG": -38.4,
    "LAT": -1.28,
    "CIDADE": "Simões Filho",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.71,
    "FEB": 5.73,
    "MAR": 5.73,
    "APR": 4.79,
    "MAY": 4.33,
    "JUN": 4.09,
    "JUL": 4.32,
    "AUG": 4.74,
    "SEP": 5.3,
    "OCT": 5.42,
    "NOV": 5.47,
    "DEC": 5.68
  },
  {
    "LONG": -44.01,
    "LAT": -12.7,
    "CIDADE": "Tabocas do Brejo Velho",
    "UF": "BAHIA",
    "anual": 5.97,
    "JAN": 5.98,
    "FEB": 6.1,
    "MAR": 5.88,
    "APR": 5.97,
    "MAY": 5.82,
    "JUN": 5.78,
    "JUL": 6.01,
    "AUG": 6.36,
    "SEP": 6.31,
    "OCT": 6.18,
    "NOV": 5.49,
    "DEC": 5.82
  },
  {
    "LONG": -43.18,
    "LAT": -12.69,
    "CIDADE": "Paratinga",
    "UF": "BAHIA",
    "anual": 6,
    "JAN": 6.12,
    "FEB": 6.27,
    "MAR": 6.12,
    "APR": 6.02,
    "MAY": 5.67,
    "JUN": 5.56,
    "JUL": 5.81,
    "AUG": 6.35,
    "SEP": 6.29,
    "OCT": 6.21,
    "NOV": 5.63,
    "DEC": 5.95
  },
  {
    "LONG": -42.22,
    "LAT": -12.65,
    "CIDADE": "Ibitiara",
    "UF": "BAHIA",
    "anual": 5.85,
    "JAN": 5.9,
    "FEB": 6.05,
    "MAR": 5.96,
    "APR": 5.61,
    "MAY": 5.41,
    "JUN": 5.34,
    "JUL": 5.53,
    "AUG": 6.16,
    "SEP": 6.47,
    "OCT": 6.25,
    "NOV": 5.61,
    "DEC": 5.94
  },
  {
    "LONG": -41.83,
    "LAT": -12.71,
    "CIDADE": "Boninal",
    "UF": "BAHIA",
    "anual": 5.53,
    "JAN": 5.68,
    "FEB": 5.86,
    "MAR": 5.8,
    "APR": 5.24,
    "MAY": 4.95,
    "JUN": 4.78,
    "JUL": 4.99,
    "AUG": 5.63,
    "SEP": 6.13,
    "OCT": 6.01,
    "NOV": 5.48,
    "DEC": 5.76
  },
  {
    "LONG": -40.61,
    "LAT": -12.66,
    "CIDADE": "Boa Vista do Tupim",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.5,
    "FEB": 5.77,
    "MAR": 5.68,
    "APR": 4.94,
    "MAY": 4.45,
    "JUN": 4.21,
    "JUL": 4.28,
    "AUG": 4.85,
    "SEP": 5.48,
    "OCT": 5.23,
    "NOV": 5.07,
    "DEC": 5.38
  },
  {
    "LONG": -39.7,
    "LAT": -12.71,
    "CIDADE": "Itatim",
    "UF": "BAHIA",
    "anual": 5.03,
    "JAN": 5.53,
    "FEB": 5.68,
    "MAR": 5.71,
    "APR": 4.86,
    "MAY": 4.34,
    "JUN": 4.07,
    "JUL": 4.27,
    "AUG": 4.7,
    "SEP": 5.3,
    "OCT": 5.18,
    "NOV": 5.22,
    "DEC": 5.47
  },
  {
    "LONG": -39.1,
    "LAT": -1.27,
    "CIDADE": "Cruz das Almas",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.63,
    "FEB": 5.66,
    "MAR": 5.71,
    "APR": 4.88,
    "MAY": 4.37,
    "JUN": 4.08,
    "JUL": 4.27,
    "AUG": 4.73,
    "SEP": 5.35,
    "OCT": 5.34,
    "NOV": 5.35,
    "DEC": 5.53
  },
  {
    "LONG": -3.92,
    "LAT": -12.73,
    "CIDADE": "Sapeaçu",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.63,
    "FEB": 5.66,
    "MAR": 5.71,
    "APR": 4.88,
    "MAY": 4.37,
    "JUN": 4.08,
    "JUL": 4.27,
    "AUG": 4.73,
    "SEP": 5.35,
    "OCT": 5.34,
    "NOV": 5.35,
    "DEC": 5.53
  },
  {
    "LONG": -3.88,
    "LAT": -12.74,
    "CIDADE": "Saubara",
    "UF": "BAHIA",
    "anual": 5.34,
    "JAN": 5.89,
    "FEB": 5.97,
    "MAR": 6.04,
    "APR": 5.13,
    "MAY": 4.55,
    "JUN": 4.24,
    "JUL": 4.51,
    "AUG": 4.98,
    "SEP": 5.62,
    "OCT": 5.65,
    "NOV": 5.65,
    "DEC": 5.84
  },
  {
    "LONG": -38.62,
    "LAT": -12.75,
    "CIDADE": "Madre de Deus",
    "UF": "BAHIA",
    "anual": 5.25,
    "JAN": 5.76,
    "FEB": 5.84,
    "MAR": 5.98,
    "APR": 5.08,
    "MAY": 4.51,
    "JUN": 4.23,
    "JUL": 4.48,
    "AUG": 4.89,
    "SEP": 5.49,
    "OCT": 5.47,
    "NOV": 5.48,
    "DEC": 5.76
  },
  {
    "LONG": -38.54,
    "LAT": -12.67,
    "CIDADE": "Candeias",
    "UF": "BAHIA",
    "anual": 5,
    "JAN": 5.54,
    "FEB": 5.52,
    "MAR": 5.64,
    "APR": 4.77,
    "MAY": 4.29,
    "JUN": 4.04,
    "JUL": 4.29,
    "AUG": 4.6,
    "SEP": 5.2,
    "OCT": 5.26,
    "NOV": 5.32,
    "DEC": 5.56
  },
  {
    "LONG": -38.33,
    "LAT": -12.7,
    "CIDADE": "Camaçari",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.67,
    "FEB": 5.71,
    "MAR": 5.7,
    "APR": 4.73,
    "MAY": 4.25,
    "JUN": 4.11,
    "JUL": 4.3,
    "AUG": 4.72,
    "SEP": 5.21,
    "OCT": 5.34,
    "NOV": 5.45,
    "DEC": 5.58
  },
  {
    "LONG": -41.39,
    "LAT": -12.56,
    "CIDADE": "Lençóis",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.49,
    "FEB": 5.72,
    "MAR": 5.63,
    "APR": 5.01,
    "MAY": 4.53,
    "JUN": 4.26,
    "JUL": 4.39,
    "AUG": 4.96,
    "SEP": 5.47,
    "OCT": 5.29,
    "NOV": 5.05,
    "DEC": 5.29
  },
  {
    "LONG": -40.93,
    "LAT": -12.65,
    "CIDADE": "Ibiquera",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.45,
    "FEB": 5.67,
    "MAR": 5.54,
    "APR": 4.79,
    "MAY": 4.4,
    "JUN": 4.18,
    "JUL": 4.33,
    "AUG": 4.89,
    "SEP": 5.63,
    "OCT": 5.33,
    "NOV": 5.09,
    "DEC": 5.31
  },
  {
    "LONG": -39.04,
    "LAT": -12.6,
    "CIDADE": "Governador Mangabeira",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.64,
    "FEB": 5.74,
    "MAR": 5.83,
    "APR": 4.91,
    "MAY": 4.42,
    "JUN": 4.09,
    "JUL": 4.35,
    "AUG": 4.83,
    "SEP": 5.46,
    "OCT": 5.45,
    "NOV": 5.43,
    "DEC": 5.61
  },
  {
    "LONG": -3.9,
    "LAT": -12.6,
    "CIDADE": "Cachoeira",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.66,
    "FEB": 5.75,
    "MAR": 5.79,
    "APR": 4.93,
    "MAY": 4.39,
    "JUN": 4.06,
    "JUL": 4.36,
    "AUG": 4.77,
    "SEP": 5.41,
    "OCT": 5.4,
    "NOV": 5.44,
    "DEC": 5.59
  },
  {
    "LONG": -38.97,
    "LAT": -12.61,
    "CIDADE": "São Félix",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.66,
    "FEB": 5.75,
    "MAR": 5.79,
    "APR": 4.93,
    "MAY": 4.39,
    "JUN": 4.06,
    "JUL": 4.36,
    "AUG": 4.77,
    "SEP": 5.41,
    "OCT": 5.4,
    "NOV": 5.44,
    "DEC": 5.59
  },
  {
    "LONG": -38.99,
    "LAT": "#VALOR!",
    "CIDADE": "Muritiba",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.66,
    "FEB": 5.75,
    "MAR": 5.79,
    "APR": 4.93,
    "MAY": 4.39,
    "JUN": 4.06,
    "JUL": 4.36,
    "AUG": 4.77,
    "SEP": 5.41,
    "OCT": 5.4,
    "NOV": 5.44,
    "DEC": 5.59
  },
  {
    "LONG": -38.68,
    "LAT": -12.62,
    "CIDADE": "São Francisco do Conde",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.64,
    "FEB": 5.58,
    "MAR": 5.76,
    "APR": 4.9,
    "MAY": 4.35,
    "JUN": 4.07,
    "JUL": 4.3,
    "AUG": 4.65,
    "SEP": 5.25,
    "OCT": 5.32,
    "NOV": 5.4,
    "DEC": 5.64
  },
  {
    "LONG": -3.83,
    "LAT": -12.62,
    "CIDADE": "Dias d'Ávila",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.75,
    "FEB": 5.87,
    "MAR": 5.88,
    "APR": 4.93,
    "MAY": 4.35,
    "JUN": 4.16,
    "JUL": 4.37,
    "AUG": 4.78,
    "SEP": 5.43,
    "OCT": 5.56,
    "NOV": 5.62,
    "DEC": 5.78
  },
  {
    "LONG": -43.96,
    "LAT": -12.49,
    "CIDADE": "Brejolândia",
    "UF": "BAHIA",
    "anual": 5.96,
    "JAN": 5.98,
    "FEB": 6.05,
    "MAR": 5.86,
    "APR": 5.97,
    "MAY": 5.83,
    "JUN": 5.78,
    "JUL": 6.01,
    "AUG": 6.35,
    "SEP": 6.3,
    "OCT": 6.15,
    "NOV": 5.46,
    "DEC": 5.78
  },
  {
    "LONG": -41.58,
    "LAT": -12.51,
    "CIDADE": "Palmeiras",
    "UF": "BAHIA",
    "anual": 5.33,
    "JAN": 5.61,
    "FEB": 5.86,
    "MAR": 5.73,
    "APR": 5.15,
    "MAY": 4.79,
    "JUN": 4.52,
    "JUL": 4.66,
    "AUG": 5.21,
    "SEP": 5.83,
    "OCT": 5.71,
    "NOV": 5.33,
    "DEC": 5.61
  },
  {
    "LONG": -40.31,
    "LAT": -12.52,
    "CIDADE": "Itaberaba",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.45,
    "FEB": 5.66,
    "MAR": 5.74,
    "APR": 4.86,
    "MAY": 4.33,
    "JUN": 4.07,
    "JUL": 4.2,
    "AUG": 4.68,
    "SEP": 5.3,
    "OCT": 5.09,
    "NOV": 5.09,
    "DEC": 5.35
  },
  {
    "LONG": -39.19,
    "LAT": -12.53,
    "CIDADE": "Cabaceiras do Paraguaçu",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.7,
    "FEB": 5.77,
    "MAR": 5.8,
    "APR": 4.97,
    "MAY": 4.41,
    "JUN": 4.05,
    "JUL": 4.31,
    "AUG": 4.82,
    "SEP": 5.38,
    "OCT": 5.38,
    "NOV": 5.44,
    "DEC": 5.63
  },
  {
    "LONG": -39,
    "LAT": -12.51,
    "CIDADE": "Conceição da Feira",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.62,
    "FEB": 5.7,
    "MAR": 5.75,
    "APR": 4.9,
    "MAY": 4.37,
    "JUN": 4.04,
    "JUL": 4.31,
    "AUG": 4.74,
    "SEP": 5.39,
    "OCT": 5.4,
    "NOV": 5.4,
    "DEC": 5.58
  },
  {
    "LONG": -3.87,
    "LAT": -12.55,
    "CIDADE": "Santo Amaro",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.68,
    "FEB": 5.67,
    "MAR": 5.76,
    "APR": 4.9,
    "MAY": 4.37,
    "JUN": 4.05,
    "JUL": 4.28,
    "AUG": 4.64,
    "SEP": 5.27,
    "OCT": 5.34,
    "NOV": 5.4,
    "DEC": 5.66
  },
  {
    "LONG": -38.49,
    "LAT": -12.51,
    "CIDADE": "São Sebastião do Passé",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.61,
    "FEB": 5.64,
    "MAR": 5.72,
    "APR": 4.79,
    "MAY": 4.25,
    "JUN": 4.04,
    "JUL": 4.24,
    "AUG": 4.67,
    "SEP": 5.27,
    "OCT": 5.4,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -38.3,
    "LAT": -12.53,
    "CIDADE": "Mata de São João",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.65,
    "FEB": 5.69,
    "MAR": 5.76,
    "APR": 4.85,
    "MAY": 4.3,
    "JUN": 4.14,
    "JUL": 4.31,
    "AUG": 4.75,
    "SEP": 5.3,
    "OCT": 5.39,
    "NOV": 5.51,
    "DEC": 5.67
  },
  {
    "LONG": -44.97,
    "LAT": -12.36,
    "CIDADE": "São Desidério",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 5.83,
    "FEB": 5.85,
    "MAR": 5.75,
    "APR": 5.87,
    "MAY": 5.88,
    "JUN": 5.77,
    "JUL": 6.18,
    "AUG": 6.59,
    "SEP": 6.31,
    "OCT": 5.97,
    "NOV": 5.38,
    "DEC": 5.67
  },
  {
    "LONG": -41.77,
    "LAT": -12.42,
    "CIDADE": "Seabra",
    "UF": "BAHIA",
    "anual": 5.28,
    "JAN": 5.55,
    "FEB": 5.74,
    "MAR": 5.68,
    "APR": 5.08,
    "MAY": 4.74,
    "JUN": 4.45,
    "JUL": 4.64,
    "AUG": 5.21,
    "SEP": 5.82,
    "OCT": 5.67,
    "NOV": 5.26,
    "DEC": 5.49
  },
  {
    "LONG": -40.9,
    "LAT": -12.36,
    "CIDADE": "Lajedinho",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.48,
    "FEB": 5.63,
    "MAR": 5.55,
    "APR": 4.79,
    "MAY": 4.38,
    "JUN": 4.18,
    "JUL": 4.29,
    "AUG": 4.87,
    "SEP": 5.59,
    "OCT": 5.36,
    "NOV": 5.17,
    "DEC": 5.4
  },
  {
    "LONG": -3.95,
    "LAT": -12.41,
    "CIDADE": "Rafael Jambeiro",
    "UF": "BAHIA",
    "anual": 5.04,
    "JAN": 5.58,
    "FEB": 5.61,
    "MAR": 5.72,
    "APR": 4.89,
    "MAY": 4.35,
    "JUN": 4.03,
    "JUL": 4.26,
    "AUG": 4.76,
    "SEP": 5.3,
    "OCT": 5.2,
    "NOV": 5.28,
    "DEC": 5.46
  },
  {
    "LONG": -39.25,
    "LAT": -12.43,
    "CIDADE": "Santo Estêvão",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.6,
    "FEB": 5.71,
    "MAR": 5.81,
    "APR": 4.96,
    "MAY": 4.37,
    "JUN": 4.11,
    "JUL": 4.28,
    "AUG": 4.8,
    "SEP": 5.33,
    "OCT": 5.31,
    "NOV": 5.36,
    "DEC": 5.53
  },
  {
    "LONG": -39.12,
    "LAT": -12.43,
    "CIDADE": "Antônio Cardoso",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.65,
    "FEB": 5.73,
    "MAR": 5.74,
    "APR": 4.98,
    "MAY": 4.42,
    "JUN": 4.07,
    "JUL": 4.3,
    "AUG": 4.79,
    "SEP": 5.32,
    "OCT": 5.31,
    "NOV": 5.34,
    "DEC": 5.54
  },
  {
    "LONG": -38.95,
    "LAT": -12.44,
    "CIDADE": "São Gonçalo dos Campos",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.6,
    "FEB": 5.63,
    "MAR": 5.7,
    "APR": 4.89,
    "MAY": 4.32,
    "JUN": 3.98,
    "JUL": 4.24,
    "AUG": 4.66,
    "SEP": 5.36,
    "OCT": 5.37,
    "NOV": 5.39,
    "DEC": 5.56
  },
  {
    "LONG": -38.75,
    "LAT": -12.4,
    "CIDADE": "Amélia Rodrigues",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.65,
    "FEB": 5.66,
    "MAR": 5.72,
    "APR": 4.91,
    "MAY": 4.35,
    "JUN": 4.03,
    "JUL": 4.22,
    "AUG": 4.62,
    "SEP": 5.32,
    "OCT": 5.35,
    "NOV": 5.4,
    "DEC": 5.64
  },
  {
    "LONG": -38.62,
    "LAT": -12.4,
    "CIDADE": "Terra Nova",
    "UF": "BAHIA",
    "anual": 5.03,
    "JAN": 5.55,
    "FEB": 5.54,
    "MAR": 5.67,
    "APR": 4.86,
    "MAY": 4.3,
    "JUN": 4,
    "JUL": 4.21,
    "AUG": 4.6,
    "SEP": 5.29,
    "OCT": 5.32,
    "NOV": 5.39,
    "DEC": 5.59
  },
  {
    "LONG": -38.34,
    "LAT": -12.43,
    "CIDADE": "Pojuca",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.63,
    "FEB": 5.68,
    "MAR": 5.73,
    "APR": 4.89,
    "MAY": 4.31,
    "JUN": 4.17,
    "JUL": 4.32,
    "AUG": 4.75,
    "SEP": 5.3,
    "OCT": 5.38,
    "NOV": 5.48,
    "DEC": 5.65
  },
  {
    "LONG": -38.38,
    "LAT": -12.35,
    "CIDADE": "Catu",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.63,
    "FEB": 5.68,
    "MAR": 5.73,
    "APR": 4.89,
    "MAY": 4.31,
    "JUN": 4.17,
    "JUL": 4.32,
    "AUG": 4.75,
    "SEP": 5.3,
    "OCT": 5.38,
    "NOV": 5.48,
    "DEC": 5.65
  },
  {
    "LONG": -44.86,
    "LAT": -12.32,
    "CIDADE": "Catolândia",
    "UF": "BAHIA",
    "anual": 5.88,
    "JAN": 5.73,
    "FEB": 5.76,
    "MAR": 5.62,
    "APR": 5.81,
    "MAY": 5.87,
    "JUN": 5.82,
    "JUL": 6.17,
    "AUG": 6.55,
    "SEP": 6.36,
    "OCT": 5.93,
    "NOV": 5.33,
    "DEC": 5.64
  },
  {
    "LONG": -44.54,
    "LAT": -12.31,
    "CIDADE": "Baianópolis",
    "UF": "BAHIA",
    "anual": 5.91,
    "JAN": 5.81,
    "FEB": 5.83,
    "MAR": 5.72,
    "APR": 5.87,
    "MAY": 5.84,
    "JUN": 5.82,
    "JUL": 6.08,
    "AUG": 6.57,
    "SEP": 6.41,
    "OCT": 5.97,
    "NOV": 5.41,
    "DEC": 5.65
  },
  {
    "LONG": -42.89,
    "LAT": -12.32,
    "CIDADE": "Oliveira dos Brejinhos",
    "UF": "BAHIA",
    "anual": 6.04,
    "JAN": 6.09,
    "FEB": 6.2,
    "MAR": 6.15,
    "APR": 6,
    "MAY": 5.73,
    "JUN": 5.66,
    "JUL": 5.81,
    "AUG": 6.35,
    "SEP": 6.43,
    "OCT": 6.36,
    "NOV": 5.78,
    "DEC": 5.94
  },
  {
    "LONG": -41.17,
    "LAT": -12.28,
    "CIDADE": "Wagner",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.37,
    "FEB": 5.46,
    "MAR": 5.48,
    "APR": 4.8,
    "MAY": 4.32,
    "JUN": 4.06,
    "JUL": 4.16,
    "AUG": 4.79,
    "SEP": 5.45,
    "OCT": 5.24,
    "NOV": 5.04,
    "DEC": 5.23
  },
  {
    "LONG": -40.49,
    "LAT": -12.28,
    "CIDADE": "Ruy Barbosa",
    "UF": "BAHIA",
    "anual": 5,
    "JAN": 5.46,
    "FEB": 5.64,
    "MAR": 5.75,
    "APR": 4.9,
    "MAY": 4.35,
    "JUN": 4.08,
    "JUL": 4.17,
    "AUG": 4.66,
    "SEP": 5.35,
    "OCT": 5.15,
    "NOV": 5.16,
    "DEC": 5.39
  },
  {
    "LONG": -39.31,
    "LAT": -12.32,
    "CIDADE": "Ipecaetá",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.53,
    "FEB": 5.71,
    "MAR": 5.78,
    "APR": 4.95,
    "MAY": 4.37,
    "JUN": 4.06,
    "JUL": 4.27,
    "AUG": 4.76,
    "SEP": 5.32,
    "OCT": 5.29,
    "NOV": 5.33,
    "DEC": 5.52
  },
  {
    "LONG": -38.97,
    "LAT": -12.27,
    "CIDADE": "Feira de Santana",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.63,
    "FEB": 5.66,
    "MAR": 5.77,
    "APR": 4.87,
    "MAY": 4.31,
    "JUN": 3.95,
    "JUL": 4.26,
    "AUG": 4.64,
    "SEP": 5.35,
    "OCT": 5.38,
    "NOV": 5.38,
    "DEC": 5.6
  },
  {
    "LONG": -38.77,
    "LAT": -12.33,
    "CIDADE": "Conceição do Jacuípe",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.65,
    "FEB": 5.68,
    "MAR": 5.75,
    "APR": 4.89,
    "MAY": 4.33,
    "JUN": 4.05,
    "JUL": 4.29,
    "AUG": 4.68,
    "SEP": 5.37,
    "OCT": 5.35,
    "NOV": 5.42,
    "DEC": 5.59
  },
  {
    "LONG": -38.64,
    "LAT": -12.3,
    "CIDADE": "Teodoro Sampaio",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.61,
    "FEB": 5.64,
    "MAR": 5.73,
    "APR": 4.91,
    "MAY": 4.3,
    "JUN": 4.05,
    "JUL": 4.29,
    "AUG": 4.67,
    "SEP": 5.34,
    "OCT": 5.34,
    "NOV": 5.45,
    "DEC": 5.58
  },
  {
    "LONG": -38.04,
    "LAT": -12.25,
    "CIDADE": "Itanagra",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.71,
    "FEB": 5.76,
    "MAR": 5.88,
    "APR": 4.99,
    "MAY": 4.41,
    "JUN": 4.31,
    "JUL": 4.43,
    "AUG": 4.76,
    "SEP": 5.41,
    "OCT": 5.47,
    "NOV": 5.59,
    "DEC": 5.79
  },
  {
    "LONG": -44.42,
    "LAT": -12.24,
    "CIDADE": "Cristópolis",
    "UF": "BAHIA",
    "anual": 5.93,
    "JAN": 5.84,
    "FEB": 5.82,
    "MAR": 5.67,
    "APR": 5.79,
    "MAY": 5.85,
    "JUN": 5.83,
    "JUL": 6.15,
    "AUG": 6.57,
    "SEP": 6.48,
    "OCT": 6.05,
    "NOV": 5.44,
    "DEC": 5.66
  },
  {
    "LONG": -4.32,
    "LAT": -12.18,
    "CIDADE": "Ibotirama",
    "UF": "BAHIA",
    "anual": 6.08,
    "JAN": 6.11,
    "FEB": 6.27,
    "MAR": 6.26,
    "APR": 6.13,
    "MAY": 5.83,
    "JUN": 5.7,
    "JUL": 5.84,
    "AUG": 6.35,
    "SEP": 6.38,
    "OCT": 6.3,
    "NOV": 5.8,
    "DEC": 5.98
  },
  {
    "LONG": -41.62,
    "LAT": -12.24,
    "CIDADE": "Iraquara",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.59,
    "FEB": 5.71,
    "MAR": 5.68,
    "APR": 5.09,
    "MAY": 4.62,
    "JUN": 4.4,
    "JUL": 4.54,
    "AUG": 5.14,
    "SEP": 5.84,
    "OCT": 5.69,
    "NOV": 5.38,
    "DEC": 5.61
  },
  {
    "LONG": -39.74,
    "LAT": -12.16,
    "CIDADE": "Ipirá",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.61,
    "FEB": 5.69,
    "MAR": 5.77,
    "APR": 4.89,
    "MAY": 4.33,
    "JUN": 4.13,
    "JUL": 4.26,
    "AUG": 4.77,
    "SEP": 5.39,
    "OCT": 5.31,
    "NOV": 5.37,
    "DEC": 5.47
  },
  {
    "LONG": -3.93,
    "LAT": -12.16,
    "CIDADE": "Serra Preta",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.59,
    "FEB": 5.67,
    "MAR": 5.71,
    "APR": 4.91,
    "MAY": 4.35,
    "JUN": 4.01,
    "JUL": 4.23,
    "AUG": 4.73,
    "SEP": 5.35,
    "OCT": 5.27,
    "NOV": 5.33,
    "DEC": 5.48
  },
  {
    "LONG": -39.24,
    "LAT": -12.15,
    "CIDADE": "Anguera",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.58,
    "FEB": 5.64,
    "MAR": 5.69,
    "APR": 4.88,
    "MAY": 4.29,
    "JUN": 3.98,
    "JUL": 4.21,
    "AUG": 4.67,
    "SEP": 5.26,
    "OCT": 5.23,
    "NOV": 5.32,
    "DEC": 5.52
  },
  {
    "LONG": -38.75,
    "LAT": -12.25,
    "CIDADE": "Coração de Maria",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.63,
    "FEB": 5.62,
    "MAR": 5.76,
    "APR": 4.95,
    "MAY": 4.32,
    "JUN": 4.09,
    "JUL": 4.3,
    "AUG": 4.75,
    "SEP": 5.39,
    "OCT": 5.38,
    "NOV": 5.46,
    "DEC": 5.6
  },
  {
    "LONG": -3.82,
    "LAT": -12.21,
    "CIDADE": "Araças",
    "UF": "BAHIA",
    "anual": 5.12,
    "JAN": 5.61,
    "FEB": 5.65,
    "MAR": 5.75,
    "APR": 4.93,
    "MAY": 4.36,
    "JUN": 4.23,
    "JUL": 4.35,
    "AUG": 4.71,
    "SEP": 5.31,
    "OCT": 5.38,
    "NOV": 5.51,
    "DEC": 5.66
  },
  {
    "LONG": -45.8,
    "LAT": -1.21,
    "CIDADE": "Luís Eduardo Magalhães",
    "UF": "BAHIA",
    "anual": 5.73,
    "JAN": 5.64,
    "FEB": 5.56,
    "MAR": 5.38,
    "APR": 5.6,
    "MAY": 5.73,
    "JUN": 5.71,
    "JUL": 5.98,
    "AUG": 6.38,
    "SEP": 6.36,
    "OCT": 5.85,
    "NOV": 5.25,
    "DEC": 5.37
  },
  {
    "LONG": -45,
    "LAT": -12.14,
    "CIDADE": "Barreiras",
    "UF": "BAHIA",
    "anual": 5.96,
    "JAN": 5.91,
    "FEB": 5.9,
    "MAR": 5.85,
    "APR": 5.88,
    "MAY": 5.85,
    "JUN": 5.79,
    "JUL": 6.11,
    "AUG": 6.54,
    "SEP": 6.4,
    "OCT": 6.1,
    "NOV": 5.52,
    "DEC": 5.74
  },
  {
    "LONG": -43.9,
    "LAT": -12.11,
    "CIDADE": "Wanderley",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 6.05,
    "FEB": 6.07,
    "MAR": 5.97,
    "APR": 6,
    "MAY": 5.87,
    "JUN": 5.69,
    "JUL": 5.9,
    "AUG": 6.34,
    "SEP": 6.34,
    "OCT": 6.12,
    "NOV": 5.62,
    "DEC": 5.87
  },
  {
    "LONG": -43.55,
    "LAT": -12.07,
    "CIDADE": "Muquém de São Francisco",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 6.07,
    "FEB": 6.08,
    "MAR": 6.09,
    "APR": 6.02,
    "MAY": 5.83,
    "JUN": 5.65,
    "JUL": 5.85,
    "AUG": 6.29,
    "SEP": 6.31,
    "OCT": 6.13,
    "NOV": 5.67,
    "DEC": 5.89
  },
  {
    "LONG": -41.64,
    "LAT": -12.09,
    "CIDADE": "Souto Soares",
    "UF": "BAHIA",
    "anual": 5.34,
    "JAN": 5.65,
    "FEB": 5.72,
    "MAR": 5.73,
    "APR": 5.1,
    "MAY": 4.66,
    "JUN": 4.43,
    "JUL": 4.58,
    "AUG": 5.24,
    "SEP": 5.94,
    "OCT": 5.84,
    "NOV": 5.48,
    "DEC": 5.7
  },
  {
    "LONG": -41.1,
    "LAT": -12.08,
    "CIDADE": "Utinga",
    "UF": "BAHIA",
    "anual": 5,
    "JAN": 5.42,
    "FEB": 5.52,
    "MAR": 5.52,
    "APR": 4.76,
    "MAY": 4.28,
    "JUN": 4.05,
    "JUL": 4.22,
    "AUG": 4.82,
    "SEP": 5.56,
    "OCT": 5.35,
    "NOV": 5.18,
    "DEC": 5.34
  },
  {
    "LONG": -40.36,
    "LAT": -12.14,
    "CIDADE": "Macajuba",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.44,
    "FEB": 5.65,
    "MAR": 5.69,
    "APR": 4.8,
    "MAY": 4.29,
    "JUN": 3.99,
    "JUL": 4.11,
    "AUG": 4.6,
    "SEP": 5.29,
    "OCT": 5.1,
    "NOV": 5.15,
    "DEC": 5.35
  },
  {
    "LONG": -38.76,
    "LAT": -12.05,
    "CIDADE": "Irará",
    "UF": "BAHIA",
    "anual": 5.12,
    "JAN": 5.66,
    "FEB": 5.59,
    "MAR": 5.75,
    "APR": 4.96,
    "MAY": 4.32,
    "JUN": 4.09,
    "JUL": 4.33,
    "AUG": 4.75,
    "SEP": 5.4,
    "OCT": 5.4,
    "NOV": 5.51,
    "DEC": 5.64
  },
  {
    "LONG": -38.64,
    "LAT": -12.15,
    "CIDADE": "Pedrão",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.66,
    "FEB": 5.62,
    "MAR": 5.74,
    "APR": 4.96,
    "MAY": 4.32,
    "JUN": 4.1,
    "JUL": 4.33,
    "AUG": 4.73,
    "SEP": 5.39,
    "OCT": 5.38,
    "NOV": 5.52,
    "DEC": 5.63
  },
  {
    "LONG": -38.42,
    "LAT": -1.21,
    "CIDADE": "Alagoinhas",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.65,
    "FEB": 5.65,
    "MAR": 5.74,
    "APR": 4.96,
    "MAY": 4.25,
    "JUN": 4.08,
    "JUL": 4.3,
    "AUG": 4.67,
    "SEP": 5.31,
    "OCT": 5.35,
    "NOV": 5.48,
    "DEC": 5.66
  },
  {
    "LONG": -38.5,
    "LAT": -12.09,
    "CIDADE": "Aramari",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.65,
    "FEB": 5.65,
    "MAR": 5.74,
    "APR": 4.96,
    "MAY": 4.25,
    "JUN": 4.08,
    "JUL": 4.3,
    "AUG": 4.67,
    "SEP": 5.31,
    "OCT": 5.35,
    "NOV": 5.48,
    "DEC": 5.66
  },
  {
    "LONG": -44.7,
    "LAT": -12.01,
    "CIDADE": "Angical",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 5.93,
    "FEB": 5.96,
    "MAR": 5.92,
    "APR": 5.92,
    "MAY": 5.88,
    "JUN": 5.79,
    "JUL": 6.05,
    "AUG": 6.48,
    "SEP": 6.34,
    "OCT": 6.09,
    "NOV": 5.59,
    "DEC": 5.79
  },
  {
    "LONG": -44.26,
    "LAT": -12.03,
    "CIDADE": "Cotegipe",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 6.03,
    "FEB": 5.98,
    "MAR": 5.87,
    "APR": 5.97,
    "MAY": 5.87,
    "JUN": 5.76,
    "JUL": 6.01,
    "AUG": 6.42,
    "SEP": 6.33,
    "OCT": 6.06,
    "NOV": 5.63,
    "DEC": 5.85
  },
  {
    "LONG": -42.63,
    "LAT": -12,
    "CIDADE": "Brotas de Macaúbas",
    "UF": "BAHIA",
    "anual": 5.88,
    "JAN": 5.86,
    "FEB": 5.92,
    "MAR": 5.87,
    "APR": 5.75,
    "MAY": 5.54,
    "JUN": 5.54,
    "JUL": 5.74,
    "AUG": 6.4,
    "SEP": 6.57,
    "OCT": 6.11,
    "NOV": 5.5,
    "DEC": 5.75
  },
  {
    "LONG": -41.64,
    "LAT": -11.97,
    "CIDADE": "Mulungu do Morro",
    "UF": "BAHIA",
    "anual": 5.43,
    "JAN": 5.73,
    "FEB": 5.78,
    "MAR": 5.82,
    "APR": 5.17,
    "MAY": 4.75,
    "JUN": 4.54,
    "JUL": 4.71,
    "AUG": 5.38,
    "SEP": 6.06,
    "OCT": 5.98,
    "NOV": 5.56,
    "DEC": 5.75
  },
  {
    "LONG": -4.13,
    "LAT": -11.97,
    "CIDADE": "Bonito",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.49,
    "FEB": 5.58,
    "MAR": 5.56,
    "APR": 4.77,
    "MAY": 4.32,
    "JUN": 4.09,
    "JUL": 4.24,
    "AUG": 4.91,
    "SEP": 5.6,
    "OCT": 5.51,
    "NOV": 5.3,
    "DEC": 5.46
  },
  {
    "LONG": -40.17,
    "LAT": -11.96,
    "CIDADE": "Baixa Grande",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.53,
    "FEB": 5.66,
    "MAR": 5.75,
    "APR": 4.85,
    "MAY": 4.26,
    "JUN": 4,
    "JUL": 4.12,
    "AUG": 4.67,
    "SEP": 5.36,
    "OCT": 5.23,
    "NOV": 5.28,
    "DEC": 5.47
  },
  {
    "LONG": -39.1,
    "LAT": -11.98,
    "CIDADE": "Tanquinho",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.64,
    "FEB": 5.69,
    "MAR": 5.73,
    "APR": 4.96,
    "MAY": 4.38,
    "JUN": 4.08,
    "JUL": 4.3,
    "AUG": 4.7,
    "SEP": 5.31,
    "OCT": 5.31,
    "NOV": 5.39,
    "DEC": 5.53
  },
  {
    "LONG": -38.97,
    "LAT": -11.95,
    "CIDADE": "Santa Bárbara",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.69,
    "FEB": 5.69,
    "MAR": 5.71,
    "APR": 4.93,
    "MAY": 4.32,
    "JUN": 4.06,
    "JUL": 4.33,
    "AUG": 4.71,
    "SEP": 5.3,
    "OCT": 5.3,
    "NOV": 5.42,
    "DEC": 5.6
  },
  {
    "LONG": -38.87,
    "LAT": -12.03,
    "CIDADE": "Santanópolis",
    "UF": "BAHIA",
    "anual": 5.12,
    "JAN": 5.65,
    "FEB": 5.68,
    "MAR": 5.74,
    "APR": 4.99,
    "MAY": 4.29,
    "JUN": 4.09,
    "JUL": 4.34,
    "AUG": 4.77,
    "SEP": 5.44,
    "OCT": 5.37,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -38.63,
    "LAT": -12.01,
    "CIDADE": "Ouriçangas",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.68,
    "FEB": 5.65,
    "MAR": 5.74,
    "APR": 4.97,
    "MAY": 4.31,
    "JUN": 4.11,
    "JUL": 4.37,
    "AUG": 4.73,
    "SEP": 5.41,
    "OCT": 5.41,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -40.47,
    "LAT": -1.19,
    "CIDADE": "Mundo Novo",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.5,
    "FEB": 5.58,
    "MAR": 5.69,
    "APR": 4.82,
    "MAY": 4.24,
    "JUN": 3.97,
    "JUL": 4.07,
    "AUG": 4.67,
    "SEP": 5.32,
    "OCT": 5.15,
    "NOV": 5.19,
    "DEC": 5.33
  },
  {
    "LONG": -38.76,
    "LAT": -11.86,
    "CIDADE": "Água Fria",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.67,
    "FEB": 5.61,
    "MAR": 5.69,
    "APR": 4.97,
    "MAY": 4.33,
    "JUN": 4.1,
    "JUL": 4.37,
    "AUG": 4.76,
    "SEP": 5.42,
    "OCT": 5.38,
    "NOV": 5.49,
    "DEC": 5.58
  },
  {
    "LONG": -38.09,
    "LAT": -11.94,
    "CIDADE": "Entre Rios",
    "UF": "BAHIA",
    "anual": 5.19,
    "JAN": 5.69,
    "FEB": 5.67,
    "MAR": 5.8,
    "APR": 5.05,
    "MAY": 4.44,
    "JUN": 4.32,
    "JUL": 4.45,
    "AUG": 4.74,
    "SEP": 5.4,
    "OCT": 5.44,
    "NOV": 5.56,
    "DEC": 5.69
  },
  {
    "LONG": -37.95,
    "LAT": -11.94,
    "CIDADE": "Cardeal da Silva",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.66,
    "FEB": 5.65,
    "MAR": 5.77,
    "APR": 5.01,
    "MAY": 4.41,
    "JUN": 4.28,
    "JUL": 4.43,
    "AUG": 4.75,
    "SEP": 5.33,
    "OCT": 5.42,
    "NOV": 5.55,
    "DEC": 5.69
  },
  {
    "LONG": -42.61,
    "LAT": -1.18,
    "CIDADE": "Ipupiara",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 5.98,
    "FEB": 6.07,
    "MAR": 6.08,
    "APR": 5.94,
    "MAY": 5.64,
    "JUN": 5.58,
    "JUL": 5.73,
    "AUG": 6.3,
    "SEP": 6.49,
    "OCT": 6.3,
    "NOV": 5.78,
    "DEC": 5.95
  },
  {
    "LONG": -42.06,
    "LAT": -11.81,
    "CIDADE": "Barra do Mendes",
    "UF": "BAHIA",
    "anual": 5.64,
    "JAN": 5.79,
    "FEB": 5.85,
    "MAR": 5.93,
    "APR": 5.35,
    "MAY": 5.04,
    "JUN": 4.88,
    "JUL": 5.11,
    "AUG": 5.77,
    "SEP": 6.32,
    "OCT": 6.12,
    "NOV": 5.7,
    "DEC": 5.76
  },
  {
    "LONG": -41.91,
    "LAT": -11.76,
    "CIDADE": "Barro Alto",
    "UF": "BAHIA",
    "anual": 5.64,
    "JAN": 5.85,
    "FEB": 5.9,
    "MAR": 5.95,
    "APR": 5.34,
    "MAY": 5.03,
    "JUN": 4.88,
    "JUL": 5.11,
    "AUG": 5.71,
    "SEP": 6.28,
    "OCT": 6.13,
    "NOV": 5.7,
    "DEC": 5.79
  },
  {
    "LONG": -4.08,
    "LAT": -11.85,
    "CIDADE": "Tapiramutá",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.51,
    "FEB": 5.56,
    "MAR": 5.58,
    "APR": 4.81,
    "MAY": 4.24,
    "JUN": 4.02,
    "JUL": 4.14,
    "AUG": 4.75,
    "SEP": 5.51,
    "OCT": 5.26,
    "NOV": 5.21,
    "DEC": 5.34
  },
  {
    "LONG": -39.91,
    "LAT": -11.81,
    "CIDADE": "Pintadas",
    "UF": "BAHIA",
    "anual": 5.04,
    "JAN": 5.65,
    "FEB": 5.65,
    "MAR": 5.75,
    "APR": 4.92,
    "MAY": 4.24,
    "JUN": 4.03,
    "JUL": 4.12,
    "AUG": 4.6,
    "SEP": 5.35,
    "OCT": 5.27,
    "NOV": 5.38,
    "DEC": 5.52
  },
  {
    "LONG": -39.61,
    "LAT": -11.83,
    "CIDADE": "Pé de Serra",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.65,
    "FEB": 5.74,
    "MAR": 5.84,
    "APR": 5.02,
    "MAY": 4.32,
    "JUN": 4.07,
    "JUL": 4.28,
    "AUG": 4.74,
    "SEP": 5.43,
    "OCT": 5.41,
    "NOV": 5.47,
    "DEC": 5.57
  },
  {
    "LONG": -39.38,
    "LAT": -11.81,
    "CIDADE": "Riachão do Jacuípe",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.77,
    "FEB": 5.73,
    "MAR": 5.8,
    "APR": 5.01,
    "MAY": 4.33,
    "JUN": 4.08,
    "JUL": 4.31,
    "AUG": 4.73,
    "SEP": 5.43,
    "OCT": 5.4,
    "NOV": 5.49,
    "DEC": 5.52
  },
  {
    "LONG": -39.12,
    "LAT": -11.81,
    "CIDADE": "Candeal",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.76,
    "FEB": 5.79,
    "MAR": 5.8,
    "APR": 5.06,
    "MAY": 4.39,
    "JUN": 4.11,
    "JUL": 4.38,
    "AUG": 4.73,
    "SEP": 5.37,
    "OCT": 5.36,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -38.89,
    "LAT": -11.77,
    "CIDADE": "Lamarão",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.68,
    "FEB": 5.63,
    "MAR": 5.69,
    "APR": 4.96,
    "MAY": 4.33,
    "JUN": 4.08,
    "JUL": 4.31,
    "AUG": 4.64,
    "SEP": 5.25,
    "OCT": 5.29,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -38.36,
    "LAT": -11.79,
    "CIDADE": "Inhambupe",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.63,
    "FEB": 5.6,
    "MAR": 5.71,
    "APR": 5.03,
    "MAY": 4.48,
    "JUN": 4.22,
    "JUL": 4.4,
    "AUG": 4.78,
    "SEP": 5.37,
    "OCT": 5.37,
    "NOV": 5.52,
    "DEC": 5.62
  },
  {
    "LONG": -37.95,
    "LAT": -1.18,
    "CIDADE": "Esplanada",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.67,
    "FEB": 5.68,
    "MAR": 5.71,
    "APR": 5.02,
    "MAY": 4.37,
    "JUN": 4.26,
    "JUL": 4.35,
    "AUG": 4.68,
    "SEP": 5.33,
    "OCT": 5.46,
    "NOV": 5.63,
    "DEC": 5.71
  },
  {
    "LONG": -37.61,
    "LAT": -11.82,
    "CIDADE": "Conde",
    "UF": "BAHIA",
    "anual": 5.38,
    "JAN": 5.87,
    "FEB": 5.92,
    "MAR": 6.07,
    "APR": 5.2,
    "MAY": 4.66,
    "JUN": 4.42,
    "JUL": 4.51,
    "AUG": 4.94,
    "SEP": 5.54,
    "OCT": 5.64,
    "NOV": 5.8,
    "DEC": 5.95
  },
  {
    "LONG": -44.91,
    "LAT": -1.17,
    "CIDADE": "Riachão das Neves",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 5.81,
    "FEB": 5.8,
    "MAR": 5.71,
    "APR": 5.81,
    "MAY": 5.89,
    "JUN": 5.93,
    "JUL": 6.13,
    "AUG": 6.51,
    "SEP": 6.45,
    "OCT": 6.03,
    "NOV": 5.51,
    "DEC": 5.65
  },
  {
    "LONG": -4.18,
    "LAT": -11.69,
    "CIDADE": "Canarana",
    "UF": "BAHIA",
    "anual": 5.63,
    "JAN": 5.87,
    "FEB": 5.94,
    "MAR": 5.97,
    "APR": 5.33,
    "MAY": 4.97,
    "JUN": 4.83,
    "JUL": 5.02,
    "AUG": 5.65,
    "SEP": 6.23,
    "OCT": 6.14,
    "NOV": 5.75,
    "DEC": 5.9
  },
  {
    "LONG": -41.47,
    "LAT": -11.69,
    "CIDADE": "Cafarnaum",
    "UF": "BAHIA",
    "anual": 5.62,
    "JAN": 5.92,
    "FEB": 5.93,
    "MAR": 5.94,
    "APR": 5.28,
    "MAY": 4.9,
    "JUN": 4.79,
    "JUL": 4.92,
    "AUG": 5.58,
    "SEP": 6.2,
    "OCT": 6.18,
    "NOV": 5.84,
    "DEC": 5.92
  },
  {
    "LONG": -40.55,
    "LAT": -11.73,
    "CIDADE": "Piritiba",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.61,
    "FEB": 5.6,
    "MAR": 5.66,
    "APR": 4.9,
    "MAY": 4.26,
    "JUN": 4.03,
    "JUL": 4.12,
    "AUG": 4.7,
    "SEP": 5.47,
    "OCT": 5.26,
    "NOV": 5.29,
    "DEC": 5.39
  },
  {
    "LONG": -40.15,
    "LAT": -1.17,
    "CIDADE": "Mairi",
    "UF": "BAHIA",
    "anual": 5.04,
    "JAN": 5.58,
    "FEB": 5.62,
    "MAR": 5.75,
    "APR": 4.89,
    "MAY": 4.31,
    "JUN": 4.04,
    "JUL": 4.23,
    "AUG": 4.67,
    "SEP": 5.36,
    "OCT": 5.25,
    "NOV": 5.3,
    "DEC": 5.45
  },
  {
    "LONG": -39.84,
    "LAT": -11.67,
    "CIDADE": "Capela do Alto Alegre",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.68,
    "FEB": 5.71,
    "MAR": 5.8,
    "APR": 4.94,
    "MAY": 4.28,
    "JUN": 3.97,
    "JUL": 4.19,
    "AUG": 4.68,
    "SEP": 5.43,
    "OCT": 5.36,
    "NOV": 5.46,
    "DEC": 5.57
  },
  {
    "LONG": -39.19,
    "LAT": -11.75,
    "CIDADE": "Ichu",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.7,
    "FEB": 5.65,
    "MAR": 5.78,
    "APR": 5.04,
    "MAY": 4.35,
    "JUN": 4.12,
    "JUL": 4.33,
    "AUG": 4.71,
    "SEP": 5.41,
    "OCT": 5.43,
    "NOV": 5.54,
    "DEC": 5.58
  },
  {
    "LONG": -39.01,
    "LAT": -11.66,
    "CIDADE": "Serrinha",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.78,
    "FEB": 5.71,
    "MAR": 5.79,
    "APR": 5.01,
    "MAY": 4.37,
    "JUN": 4.07,
    "JUL": 4.31,
    "AUG": 4.73,
    "SEP": 5.42,
    "OCT": 5.44,
    "NOV": 5.59,
    "DEC": 5.65
  },
  {
    "LONG": -38.02,
    "LAT": -1.17,
    "CIDADE": "Acajutiba",
    "UF": "BAHIA",
    "anual": 5.19,
    "JAN": 5.69,
    "FEB": 5.66,
    "MAR": 5.75,
    "APR": 5.05,
    "MAY": 4.43,
    "JUN": 4.29,
    "JUL": 4.44,
    "AUG": 4.71,
    "SEP": 5.37,
    "OCT": 5.47,
    "NOV": 5.64,
    "DEC": 5.74
  },
  {
    "LONG": -38.08,
    "LAT": -11.66,
    "CIDADE": "Aporá",
    "UF": "BAHIA",
    "anual": 5.19,
    "JAN": 5.69,
    "FEB": 5.66,
    "MAR": 5.75,
    "APR": 5.05,
    "MAY": 4.43,
    "JUN": 4.29,
    "JUL": 4.44,
    "AUG": 4.71,
    "SEP": 5.37,
    "OCT": 5.47,
    "NOV": 5.64,
    "DEC": 5.74
  },
  {
    "LONG": -43.28,
    "LAT": -11.56,
    "CIDADE": "Morpará",
    "UF": "BAHIA",
    "anual": 6.04,
    "JAN": 5.96,
    "FEB": 6.06,
    "MAR": 6.13,
    "APR": 6.04,
    "MAY": 5.84,
    "JUN": 5.67,
    "JUL": 5.91,
    "AUG": 6.41,
    "SEP": 6.56,
    "OCT": 6.27,
    "NOV": 5.79,
    "DEC": 5.88
  },
  {
    "LONG": -42.02,
    "LAT": -11.64,
    "CIDADE": "Ibipeba",
    "UF": "BAHIA",
    "anual": 5.65,
    "JAN": 5.85,
    "FEB": 5.82,
    "MAR": 5.91,
    "APR": 5.39,
    "MAY": 5.06,
    "JUN": 4.92,
    "JUL": 5.16,
    "AUG": 5.81,
    "SEP": 6.35,
    "OCT": 6.11,
    "NOV": 5.64,
    "DEC": 5.76
  },
  {
    "LONG": -40.14,
    "LAT": -1.16,
    "CIDADE": "Várzea da Roça",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.63,
    "FEB": 5.67,
    "MAR": 5.75,
    "APR": 4.9,
    "MAY": 4.32,
    "JUN": 4.09,
    "JUL": 4.25,
    "AUG": 4.71,
    "SEP": 5.49,
    "OCT": 5.31,
    "NOV": 5.39,
    "DEC": 5.49
  },
  {
    "LONG": -39.63,
    "LAT": -11.61,
    "CIDADE": "Nova Fátima",
    "UF": "BAHIA",
    "anual": 5.17,
    "JAN": 5.77,
    "FEB": 5.78,
    "MAR": 5.86,
    "APR": 5.06,
    "MAY": 4.34,
    "JUN": 4.08,
    "JUL": 4.32,
    "AUG": 4.76,
    "SEP": 5.49,
    "OCT": 5.45,
    "NOV": 5.54,
    "DEC": 5.6
  },
  {
    "LONG": -39.28,
    "LAT": -11.56,
    "CIDADE": "Conceição do Coité",
    "UF": "BAHIA",
    "anual": 5.18,
    "JAN": 5.74,
    "FEB": 5.72,
    "MAR": 5.82,
    "APR": 5.06,
    "MAY": 4.43,
    "JUN": 4.15,
    "JUL": 4.34,
    "AUG": 4.85,
    "SEP": 5.49,
    "OCT": 5.48,
    "NOV": 5.55,
    "DEC": 5.54
  },
  {
    "LONG": -38.81,
    "LAT": -11.62,
    "CIDADE": "Biritinga",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.74,
    "FEB": 5.69,
    "MAR": 5.75,
    "APR": 5.08,
    "MAY": 4.37,
    "JUN": 4.17,
    "JUL": 4.37,
    "AUG": 4.73,
    "SEP": 5.42,
    "OCT": 5.42,
    "NOV": 5.55,
    "DEC": 5.61
  },
  {
    "LONG": -38.58,
    "LAT": -1.16,
    "CIDADE": "Sátiro Dias",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.63,
    "FEB": 5.67,
    "MAR": 5.78,
    "APR": 5.05,
    "MAY": 4.42,
    "JUN": 4.22,
    "JUL": 4.38,
    "AUG": 4.79,
    "SEP": 5.39,
    "OCT": 5.38,
    "NOV": 5.59,
    "DEC": 5.6
  },
  {
    "LONG": -37.79,
    "LAT": -11.56,
    "CIDADE": "Jandaíra",
    "UF": "BAHIA",
    "anual": 5.24,
    "JAN": 5.72,
    "FEB": 5.77,
    "MAR": 5.84,
    "APR": 5.1,
    "MAY": 4.48,
    "JUN": 4.38,
    "JUL": 4.43,
    "AUG": 4.81,
    "SEP": 5.39,
    "OCT": 5.49,
    "NOV": 5.66,
    "DEC": 5.78
  },
  {
    "LONG": -41.98,
    "LAT": -11.54,
    "CIDADE": "Ibititá",
    "UF": "BAHIA",
    "anual": 5.75,
    "JAN": 5.97,
    "FEB": 5.99,
    "MAR": 6.03,
    "APR": 5.5,
    "MAY": 5.16,
    "JUN": 5.02,
    "JUL": 5.24,
    "AUG": 5.93,
    "SEP": 6.36,
    "OCT": 6.2,
    "NOV": 5.76,
    "DEC": 5.88
  },
  {
    "LONG": -41.16,
    "LAT": -11.55,
    "CIDADE": "Morro do Chapéu",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.67,
    "FEB": 5.71,
    "MAR": 5.75,
    "APR": 4.95,
    "MAY": 4.49,
    "JUN": 4.3,
    "JUL": 4.46,
    "AUG": 5.1,
    "SEP": 5.89,
    "OCT": 5.83,
    "NOV": 5.51,
    "DEC": 5.59
  },
  {
    "LONG": -40.32,
    "LAT": -11.53,
    "CIDADE": "Várzea do Poço",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.6,
    "FEB": 5.62,
    "MAR": 5.63,
    "APR": 4.83,
    "MAY": 4.18,
    "JUN": 3.98,
    "JUL": 4.11,
    "AUG": 4.66,
    "SEP": 5.39,
    "OCT": 5.24,
    "NOV": 5.3,
    "DEC": 5.38
  },
  {
    "LONG": -40.02,
    "LAT": -11.51,
    "CIDADE": "São José do Jacuípe",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.67,
    "FEB": 5.69,
    "MAR": 5.77,
    "APR": 4.88,
    "MAY": 4.31,
    "JUN": 4.06,
    "JUL": 4.22,
    "AUG": 4.71,
    "SEP": 5.45,
    "OCT": 5.36,
    "NOV": 5.45,
    "DEC": 5.49
  },
  {
    "LONG": -39.78,
    "LAT": -11.47,
    "CIDADE": "Gavião",
    "UF": "BAHIA",
    "anual": 5.17,
    "JAN": 5.78,
    "FEB": 5.74,
    "MAR": 5.83,
    "APR": 5.01,
    "MAY": 4.37,
    "JUN": 4.07,
    "JUL": 4.31,
    "AUG": 4.82,
    "SEP": 5.5,
    "OCT": 5.44,
    "NOV": 5.58,
    "DEC": 5.53
  },
  {
    "LONG": -39.53,
    "LAT": -11.47,
    "CIDADE": "São Domingos",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.68,
    "FEB": 5.71,
    "MAR": 5.86,
    "APR": 5.03,
    "MAY": 4.36,
    "JUN": 4.1,
    "JUL": 4.33,
    "AUG": 4.76,
    "SEP": 5.5,
    "OCT": 5.43,
    "NOV": 5.52,
    "DEC": 5.52
  },
  {
    "LONG": -39.43,
    "LAT": -11.49,
    "CIDADE": "Retirolândia",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.7,
    "FEB": 5.68,
    "MAR": 5.86,
    "APR": 5.05,
    "MAY": 4.38,
    "JUN": 4.13,
    "JUL": 4.36,
    "AUG": 4.77,
    "SEP": 5.5,
    "OCT": 5.44,
    "NOV": 5.53,
    "DEC": 5.54
  },
  {
    "LONG": -39.08,
    "LAT": -11.53,
    "CIDADE": "Barrocas",
    "UF": "BAHIA",
    "anual": 5.2,
    "JAN": 5.73,
    "FEB": 5.71,
    "MAR": 5.83,
    "APR": 5.1,
    "MAY": 4.42,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 4.78,
    "SEP": 5.49,
    "OCT": 5.48,
    "NOV": 5.62,
    "DEC": 5.61
  },
  {
    "LONG": -39,
    "LAT": -1.15,
    "CIDADE": "Teofilândia",
    "UF": "BAHIA",
    "anual": 5.2,
    "JAN": 5.76,
    "FEB": 5.73,
    "MAR": 5.81,
    "APR": 5.12,
    "MAY": 4.45,
    "JUN": 4.23,
    "JUL": 4.44,
    "AUG": 4.81,
    "SEP": 5.44,
    "OCT": 5.44,
    "NOV": 5.58,
    "DEC": 5.62
  },
  {
    "LONG": -38.15,
    "LAT": -11.51,
    "CIDADE": "Crisópolis",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.68,
    "FEB": 5.7,
    "MAR": 5.75,
    "APR": 5.11,
    "MAY": 4.48,
    "JUN": 4.36,
    "JUL": 4.45,
    "AUG": 4.85,
    "SEP": 5.37,
    "OCT": 5.43,
    "NOV": 5.67,
    "DEC": 5.65
  },
  {
    "LONG": -37.93,
    "LAT": -11.48,
    "CIDADE": "Rio Real",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.77,
    "FEB": 5.77,
    "MAR": 5.79,
    "APR": 5.1,
    "MAY": 4.47,
    "JUN": 4.34,
    "JUL": 4.44,
    "AUG": 4.8,
    "SEP": 5.4,
    "OCT": 5.5,
    "NOV": 5.66,
    "DEC": 5.74
  },
  {
    "LONG": -4.25,
    "LAT": -11.43,
    "CIDADE": "Gentio do Ouro",
    "UF": "BAHIA",
    "anual": 6.06,
    "JAN": 5.99,
    "FEB": 6.06,
    "MAR": 6.1,
    "APR": 5.89,
    "MAY": 5.66,
    "JUN": 5.66,
    "JUL": 5.94,
    "AUG": 6.57,
    "SEP": 6.75,
    "OCT": 6.39,
    "NOV": 5.84,
    "DEC": 5.92
  },
  {
    "LONG": -4.18,
    "LAT": -11.39,
    "CIDADE": "Lapão",
    "UF": "BAHIA",
    "anual": 5.79,
    "JAN": 6,
    "FEB": 5.99,
    "MAR": 6.03,
    "APR": 5.51,
    "MAY": 5.2,
    "JUN": 5.09,
    "JUL": 5.3,
    "AUG": 5.96,
    "SEP": 6.38,
    "OCT": 6.28,
    "NOV": 5.85,
    "DEC": 5.93
  },
  {
    "LONG": -41.44,
    "LAT": -11.44,
    "CIDADE": "América Dourada",
    "UF": "BAHIA",
    "anual": 5.65,
    "JAN": 5.9,
    "FEB": 5.92,
    "MAR": 5.94,
    "APR": 5.32,
    "MAY": 4.94,
    "JUN": 4.91,
    "JUL": 5.03,
    "AUG": 5.66,
    "SEP": 6.27,
    "OCT": 6.21,
    "NOV": 5.85,
    "DEC": 5.87
  },
  {
    "LONG": -40.6,
    "LAT": -11.43,
    "CIDADE": "Miguel Calmon",
    "UF": "BAHIA",
    "anual": 5.18,
    "JAN": 5.67,
    "FEB": 5.73,
    "MAR": 5.8,
    "APR": 4.99,
    "MAY": 4.31,
    "JUN": 4.09,
    "JUL": 4.26,
    "AUG": 4.92,
    "SEP": 5.77,
    "OCT": 5.59,
    "NOV": 5.53,
    "DEC": 5.56
  },
  {
    "LONG": -4.03,
    "LAT": -11.42,
    "CIDADE": "Serrolândia",
    "UF": "BAHIA",
    "anual": 5.01,
    "JAN": 5.65,
    "FEB": 5.62,
    "MAR": 5.63,
    "APR": 4.84,
    "MAY": 4.2,
    "JUN": 3.98,
    "JUL": 4.13,
    "AUG": 4.69,
    "SEP": 5.42,
    "OCT": 5.22,
    "NOV": 5.31,
    "DEC": 5.36
  },
  {
    "LONG": -40.13,
    "LAT": -11.41,
    "CIDADE": "Quixabeira",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.67,
    "FEB": 5.75,
    "MAR": 5.78,
    "APR": 4.92,
    "MAY": 4.35,
    "JUN": 4.1,
    "JUL": 4.25,
    "AUG": 4.76,
    "SEP": 5.51,
    "OCT": 5.33,
    "NOV": 5.4,
    "DEC": 5.46
  },
  {
    "LONG": -40.01,
    "LAT": -11.38,
    "CIDADE": "Capim Grosso",
    "UF": "BAHIA",
    "anual": 5.12,
    "JAN": 5.67,
    "FEB": 5.71,
    "MAR": 5.78,
    "APR": 4.9,
    "MAY": 4.34,
    "JUN": 4.09,
    "JUL": 4.28,
    "AUG": 4.8,
    "SEP": 5.5,
    "OCT": 5.38,
    "NOV": 5.46,
    "DEC": 5.5
  },
  {
    "LONG": -39.47,
    "LAT": -11.41,
    "CIDADE": "Valente",
    "UF": "BAHIA",
    "anual": 5.17,
    "JAN": 5.69,
    "FEB": 5.7,
    "MAR": 5.84,
    "APR": 5.07,
    "MAY": 4.4,
    "JUN": 4.16,
    "JUL": 4.36,
    "AUG": 4.78,
    "SEP": 5.48,
    "OCT": 5.42,
    "NOV": 5.56,
    "DEC": 5.56
  },
  {
    "LONG": -38.33,
    "LAT": -11.36,
    "CIDADE": "Olindina",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.73,
    "FEB": 5.71,
    "MAR": 5.82,
    "APR": 5.13,
    "MAY": 4.44,
    "JUN": 4.27,
    "JUL": 4.41,
    "AUG": 4.81,
    "SEP": 5.4,
    "OCT": 5.44,
    "NOV": 5.69,
    "DEC": 5.62
  },
  {
    "LONG": -42.14,
    "LAT": -11.34,
    "CIDADE": "Uibaí",
    "UF": "BAHIA",
    "anual": 5.88,
    "JAN": 5.99,
    "FEB": 5.99,
    "MAR": 6.04,
    "APR": 5.65,
    "MAY": 5.37,
    "JUN": 5.32,
    "JUL": 5.54,
    "AUG": 6.16,
    "SEP": 6.49,
    "OCT": 6.29,
    "NOV": 5.83,
    "DEC": 5.91
  },
  {
    "LONG": -41.98,
    "LAT": -11.29,
    "CIDADE": "Presidente Dutra",
    "UF": "BAHIA",
    "anual": 5.84,
    "JAN": 5.96,
    "FEB": 6.01,
    "MAR": 6.01,
    "APR": 5.57,
    "MAY": 5.27,
    "JUN": 5.2,
    "JUL": 5.43,
    "AUG": 6.06,
    "SEP": 6.47,
    "OCT": 6.29,
    "NOV": 5.83,
    "DEC": 5.92
  },
  {
    "LONG": -41.85,
    "LAT": -11.3,
    "CIDADE": "Irecê",
    "UF": "BAHIA",
    "anual": 5.82,
    "JAN": 5.94,
    "FEB": 6.01,
    "MAR": 5.99,
    "APR": 5.53,
    "MAY": 5.23,
    "JUN": 5.16,
    "JUL": 5.38,
    "AUG": 6.02,
    "SEP": 6.4,
    "OCT": 6.3,
    "NOV": 5.88,
    "DEC": 5.95
  },
  {
    "LONG": -41.66,
    "LAT": -11.35,
    "CIDADE": "João Dourado",
    "UF": "BAHIA",
    "anual": 5.8,
    "JAN": 6,
    "FEB": 5.97,
    "MAR": 6.01,
    "APR": 5.47,
    "MAY": 5.18,
    "JUN": 5.13,
    "JUL": 5.3,
    "AUG": 5.95,
    "SEP": 6.4,
    "OCT": 6.29,
    "NOV": 5.95,
    "DEC": 5.95
  },
  {
    "LONG": -40.94,
    "LAT": -11.26,
    "CIDADE": "Várzea Nova",
    "UF": "BAHIA",
    "anual": 5.36,
    "JAN": 5.8,
    "FEB": 5.73,
    "MAR": 5.82,
    "APR": 5.09,
    "MAY": 4.63,
    "JUN": 4.44,
    "JUL": 4.65,
    "AUG": 5.15,
    "SEP": 5.93,
    "OCT": 5.84,
    "NOV": 5.65,
    "DEC": 5.65
  },
  {
    "LONG": -39.38,
    "LAT": -11.25,
    "CIDADE": "Santaluz",
    "UF": "BAHIA",
    "anual": 5.17,
    "JAN": 5.74,
    "FEB": 5.69,
    "MAR": 5.81,
    "APR": 5.03,
    "MAY": 4.35,
    "JUN": 4.13,
    "JUL": 4.33,
    "AUG": 4.72,
    "SEP": 5.47,
    "OCT": 5.44,
    "NOV": 5.69,
    "DEC": 5.61
  },
  {
    "LONG": -38.96,
    "LAT": -11.33,
    "CIDADE": "Araci",
    "UF": "BAHIA",
    "anual": 5.22,
    "JAN": 5.79,
    "FEB": 5.75,
    "MAR": 5.81,
    "APR": 5.14,
    "MAY": 4.46,
    "JUN": 4.23,
    "JUL": 4.37,
    "AUG": 4.77,
    "SEP": 5.44,
    "OCT": 5.49,
    "NOV": 5.67,
    "DEC": 5.67
  },
  {
    "LONG": -38.22,
    "LAT": -11.31,
    "CIDADE": "Itapicuru",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.78,
    "FEB": 5.77,
    "MAR": 5.83,
    "APR": 5.16,
    "MAY": 4.49,
    "JUN": 4.3,
    "JUL": 4.44,
    "AUG": 4.81,
    "SEP": 5.39,
    "OCT": 5.47,
    "NOV": 5.69,
    "DEC": 5.68
  },
  {
    "LONG": -41.88,
    "LAT": -11.23,
    "CIDADE": "São Gabriel",
    "UF": "BAHIA",
    "anual": 5.82,
    "JAN": 5.93,
    "FEB": 5.97,
    "MAR": 5.95,
    "APR": 5.52,
    "MAY": 5.25,
    "JUN": 5.19,
    "JUL": 5.42,
    "AUG": 6.04,
    "SEP": 6.49,
    "OCT": 6.27,
    "NOV": 5.88,
    "DEC": 5.92
  },
  {
    "LONG": -4.05,
    "LAT": -11.18,
    "CIDADE": "Jacobina",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.8,
    "FEB": 5.79,
    "MAR": 5.92,
    "APR": 5.09,
    "MAY": 4.39,
    "JUN": 4.19,
    "JUL": 4.36,
    "AUG": 5,
    "SEP": 5.79,
    "OCT": 5.58,
    "NOV": 5.64,
    "DEC": 5.66
  },
  {
    "LONG": -3.85,
    "LAT": -11.24,
    "CIDADE": "Nova Soure",
    "UF": "BAHIA",
    "anual": 5.24,
    "JAN": 5.74,
    "FEB": 5.81,
    "MAR": 5.88,
    "APR": 5.22,
    "MAY": 4.48,
    "JUN": 4.28,
    "JUL": 4.45,
    "AUG": 4.82,
    "SEP": 5.41,
    "OCT": 5.46,
    "NOV": 5.67,
    "DEC": 5.64
  },
  {
    "LONG": -43.15,
    "LAT": -11.09,
    "CIDADE": "Barra",
    "UF": "BAHIA",
    "anual": 6.03,
    "JAN": 5.97,
    "FEB": 6.05,
    "MAR": 6.14,
    "APR": 6.02,
    "MAY": 5.74,
    "JUN": 5.7,
    "JUL": 5.84,
    "AUG": 6.29,
    "SEP": 6.46,
    "OCT": 6.33,
    "NOV": 5.9,
    "DEC": 5.92
  },
  {
    "LONG": -4.21,
    "LAT": -1.11,
    "CIDADE": "Central",
    "UF": "BAHIA",
    "anual": 5.89,
    "JAN": 5.92,
    "FEB": 5.92,
    "MAR": 6.03,
    "APR": 5.64,
    "MAY": 5.44,
    "JUN": 5.36,
    "JUL": 5.54,
    "AUG": 6.22,
    "SEP": 6.58,
    "OCT": 6.27,
    "NOV": 5.86,
    "DEC": 5.88
  },
  {
    "LONG": -40.43,
    "LAT": -11.07,
    "CIDADE": "Caém",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.68,
    "FEB": 5.65,
    "MAR": 5.71,
    "APR": 4.91,
    "MAY": 4.25,
    "JUN": 3.97,
    "JUL": 4.2,
    "AUG": 4.83,
    "SEP": 5.56,
    "OCT": 5.44,
    "NOV": 5.45,
    "DEC": 5.45
  },
  {
    "LONG": -38.52,
    "LAT": -11.1,
    "CIDADE": "Cipó",
    "UF": "BAHIA",
    "anual": 5.22,
    "JAN": 5.75,
    "FEB": 5.77,
    "MAR": 5.92,
    "APR": 5.2,
    "MAY": 4.46,
    "JUN": 4.25,
    "JUL": 4.34,
    "AUG": 4.73,
    "SEP": 5.41,
    "OCT": 5.5,
    "NOV": 5.66,
    "DEC": 5.65
  },
  {
    "LONG": -45.19,
    "LAT": -11.04,
    "CIDADE": "Formosa do Rio Preto",
    "UF": "BAHIA",
    "anual": 5.87,
    "JAN": 5.59,
    "FEB": 5.58,
    "MAR": 5.58,
    "APR": 5.68,
    "MAY": 5.91,
    "JUN": 5.95,
    "JUL": 6.19,
    "AUG": 6.65,
    "SEP": 6.53,
    "OCT": 5.89,
    "NOV": 5.41,
    "DEC": 5.53
  },
  {
    "LONG": -44.52,
    "LAT": -11,
    "CIDADE": "Santa Rita de Cássia",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 5.82,
    "FEB": 5.73,
    "MAR": 5.68,
    "APR": 5.77,
    "MAY": 5.92,
    "JUN": 5.85,
    "JUL": 6.08,
    "AUG": 6.52,
    "SEP": 6.57,
    "OCT": 6.08,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -42.4,
    "LAT": -11.01,
    "CIDADE": "Itaguaçu da Bahia",
    "UF": "BAHIA",
    "anual": 6.06,
    "JAN": 6.09,
    "FEB": 6.12,
    "MAR": 6.24,
    "APR": 5.84,
    "MAY": 5.65,
    "JUN": 5.64,
    "JUL": 5.81,
    "AUG": 6.43,
    "SEP": 6.56,
    "OCT": 6.34,
    "NOV": 6.04,
    "DEC": 6
  },
  {
    "LONG": -41.97,
    "LAT": -11.04,
    "CIDADE": "Jussara",
    "UF": "BAHIA",
    "anual": 5.89,
    "JAN": 5.94,
    "FEB": 5.99,
    "MAR": 6.03,
    "APR": 5.59,
    "MAY": 5.35,
    "JUN": 5.32,
    "JUL": 5.51,
    "AUG": 6.19,
    "SEP": 6.58,
    "OCT": 6.27,
    "NOV": 5.99,
    "DEC": 5.95
  },
  {
    "LONG": -41.08,
    "LAT": -10.97,
    "CIDADE": "Ourolândia",
    "UF": "BAHIA",
    "anual": 5.44,
    "JAN": 5.84,
    "FEB": 5.72,
    "MAR": 5.85,
    "APR": 5.13,
    "MAY": 4.73,
    "JUN": 4.54,
    "JUL": 4.7,
    "AUG": 5.37,
    "SEP": 6.09,
    "OCT": 5.9,
    "NOV": 5.76,
    "DEC": 5.69
  },
  {
    "LONG": -40.57,
    "LAT": -10.95,
    "CIDADE": "Mirangaba",
    "UF": "BAHIA",
    "anual": 5.29,
    "JAN": 5.79,
    "FEB": 5.77,
    "MAR": 5.85,
    "APR": 5.08,
    "MAY": 4.45,
    "JUN": 4.21,
    "JUL": 4.43,
    "AUG": 5.08,
    "SEP": 5.81,
    "OCT": 5.68,
    "NOV": 5.68,
    "DEC": 5.64
  },
  {
    "LONG": -40.3,
    "LAT": -11.03,
    "CIDADE": "Caldeirão Grande",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.81,
    "FEB": 5.66,
    "MAR": 5.7,
    "APR": 4.93,
    "MAY": 4.33,
    "JUN": 4.11,
    "JUL": 4.33,
    "AUG": 4.82,
    "SEP": 5.53,
    "OCT": 5.39,
    "NOV": 5.48,
    "DEC": 5.47
  },
  {
    "LONG": -39.63,
    "LAT": -1.1,
    "CIDADE": "Queimadas",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.81,
    "FEB": 5.78,
    "MAR": 5.84,
    "APR": 5.09,
    "MAY": 4.38,
    "JUN": 4.12,
    "JUL": 4.35,
    "AUG": 4.8,
    "SEP": 5.54,
    "OCT": 5.53,
    "NOV": 5.63,
    "DEC": 5.66
  },
  {
    "LONG": -38.79,
    "LAT": -10.96,
    "CIDADE": "Tucano",
    "UF": "BAHIA",
    "anual": 5.34,
    "JAN": 5.84,
    "FEB": 5.86,
    "MAR": 6,
    "APR": 5.32,
    "MAY": 4.59,
    "JUN": 4.32,
    "JUL": 4.5,
    "AUG": 4.92,
    "SEP": 5.59,
    "OCT": 5.61,
    "NOV": 5.8,
    "DEC": 5.72
  },
  {
    "LONG": -38.42,
    "LAT": -11.04,
    "CIDADE": "Ribeira do Amparo",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.71,
    "FEB": 5.63,
    "MAR": 5.82,
    "APR": 5.13,
    "MAY": 4.46,
    "JUN": 4.18,
    "JUL": 4.28,
    "AUG": 4.7,
    "SEP": 5.3,
    "OCT": 5.38,
    "NOV": 5.65,
    "DEC": 5.62
  },
  {
    "LONG": -40.42,
    "LAT": -10.94,
    "CIDADE": "Saúde",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.8,
    "FEB": 5.73,
    "MAR": 5.76,
    "APR": 4.99,
    "MAY": 4.34,
    "JUN": 4.08,
    "JUL": 4.34,
    "AUG": 4.95,
    "SEP": 5.73,
    "OCT": 5.63,
    "NOV": 5.63,
    "DEC": 5.57
  },
  {
    "LONG": -40.13,
    "LAT": -10.86,
    "CIDADE": "Ponto Novo",
    "UF": "BAHIA",
    "anual": 5.18,
    "JAN": 5.79,
    "FEB": 5.75,
    "MAR": 5.79,
    "APR": 4.94,
    "MAY": 4.35,
    "JUN": 4.13,
    "JUL": 4.32,
    "AUG": 4.79,
    "SEP": 5.61,
    "OCT": 5.49,
    "NOV": 5.65,
    "DEC": 5.54
  },
  {
    "LONG": -42.72,
    "LAT": -10.82,
    "CIDADE": "Xique-Xique",
    "UF": "BAHIA",
    "anual": 6.08,
    "JAN": 6.01,
    "FEB": 6.05,
    "MAR": 6.26,
    "APR": 5.92,
    "MAY": 5.76,
    "JUN": 5.69,
    "JUL": 5.88,
    "AUG": 6.46,
    "SEP": 6.59,
    "OCT": 6.36,
    "NOV": 6.01,
    "DEC": 5.95
  },
  {
    "LONG": -39.43,
    "LAT": -10.83,
    "CIDADE": "Nordestina",
    "UF": "BAHIA",
    "anual": 5.26,
    "JAN": 5.76,
    "FEB": 5.78,
    "MAR": 5.86,
    "APR": 5.15,
    "MAY": 4.46,
    "JUN": 4.21,
    "JUL": 4.36,
    "AUG": 4.88,
    "SEP": 5.65,
    "OCT": 5.56,
    "NOV": 5.71,
    "DEC": 5.7
  },
  {
    "LONG": -39.21,
    "LAT": -10.75,
    "CIDADE": "Quijingue",
    "UF": "BAHIA",
    "anual": 5.26,
    "JAN": 5.74,
    "FEB": 5.77,
    "MAR": 5.91,
    "APR": 5.16,
    "MAY": 4.52,
    "JUN": 4.17,
    "JUL": 4.38,
    "AUG": 4.84,
    "SEP": 5.66,
    "OCT": 5.57,
    "NOV": 5.8,
    "DEC": 5.64
  },
  {
    "LONG": -38.54,
    "LAT": -10.84,
    "CIDADE": "Ribeira do Pombal",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.73,
    "FEB": 5.71,
    "MAR": 5.89,
    "APR": 5.26,
    "MAY": 4.54,
    "JUN": 4.21,
    "JUL": 4.36,
    "AUG": 4.75,
    "SEP": 5.43,
    "OCT": 5.46,
    "NOV": 5.74,
    "DEC": 5.67
  },
  {
    "LONG": -44.04,
    "LAT": -10.72,
    "CIDADE": "Mansidão",
    "UF": "BAHIA",
    "anual": 5.88,
    "JAN": 5.63,
    "FEB": 5.67,
    "MAR": 5.63,
    "APR": 5.71,
    "MAY": 5.82,
    "JUN": 5.82,
    "JUL": 5.99,
    "AUG": 6.53,
    "SEP": 6.59,
    "OCT": 6.06,
    "NOV": 5.5,
    "DEC": 5.61
  },
  {
    "LONG": -4.36,
    "LAT": -10.72,
    "CIDADE": "Buritirama",
    "UF": "BAHIA",
    "anual": 5.97,
    "JAN": 5.75,
    "FEB": 5.71,
    "MAR": 5.79,
    "APR": 5.8,
    "MAY": 5.78,
    "JUN": 5.82,
    "JUL": 6.1,
    "AUG": 6.63,
    "SEP": 6.6,
    "OCT": 6.25,
    "NOV": 5.71,
    "DEC": 5.69
  },
  {
    "LONG": -41.33,
    "LAT": -10.73,
    "CIDADE": "Umburanas",
    "UF": "BAHIA",
    "anual": 5.62,
    "JAN": 5.95,
    "FEB": 5.75,
    "MAR": 5.93,
    "APR": 5.23,
    "MAY": 4.92,
    "JUN": 4.75,
    "JUL": 5.02,
    "AUG": 5.71,
    "SEP": 6.29,
    "OCT": 6.11,
    "NOV": 5.96,
    "DEC": 5.8
  },
  {
    "LONG": -40.37,
    "LAT": -10.74,
    "CIDADE": "Pindobaçu",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.65,
    "FEB": 5.63,
    "MAR": 5.7,
    "APR": 4.87,
    "MAY": 4.19,
    "JUN": 3.92,
    "JUL": 4.17,
    "AUG": 4.75,
    "SEP": 5.65,
    "OCT": 5.52,
    "NOV": 5.56,
    "DEC": 5.54
  },
  {
    "LONG": -40.13,
    "LAT": -10.74,
    "CIDADE": "Filadélfia",
    "UF": "BAHIA",
    "anual": 5.2,
    "JAN": 5.7,
    "FEB": 5.71,
    "MAR": 5.87,
    "APR": 5.02,
    "MAY": 4.39,
    "JUN": 4.13,
    "JUL": 4.34,
    "AUG": 4.82,
    "SEP": 5.65,
    "OCT": 5.52,
    "NOV": 5.65,
    "DEC": 5.57
  },
  {
    "LONG": -39.85,
    "LAT": -10.69,
    "CIDADE": "Itiúba",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.78,
    "FEB": 5.73,
    "MAR": 5.82,
    "APR": 5.03,
    "MAY": 4.47,
    "JUN": 4.13,
    "JUL": 4.37,
    "AUG": 4.9,
    "SEP": 5.69,
    "OCT": 5.59,
    "NOV": 5.71,
    "DEC": 5.6
  },
  {
    "LONG": -39.49,
    "LAT": -10.67,
    "CIDADE": "Cansanção",
    "UF": "BAHIA",
    "anual": 5.26,
    "JAN": 5.69,
    "FEB": 5.67,
    "MAR": 5.89,
    "APR": 5.16,
    "MAY": 4.53,
    "JUN": 4.2,
    "JUL": 4.42,
    "AUG": 4.86,
    "SEP": 5.71,
    "OCT": 5.58,
    "NOV": 5.8,
    "DEC": 5.68
  },
  {
    "LONG": -3.83,
    "LAT": -10.68,
    "CIDADE": "Heliópolis",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.75,
    "FEB": 5.7,
    "MAR": 5.85,
    "APR": 5.26,
    "MAY": 4.55,
    "JUN": 4.24,
    "JUL": 4.36,
    "AUG": 4.75,
    "SEP": 5.39,
    "OCT": 5.46,
    "NOV": 5.81,
    "DEC": 5.67
  },
  {
    "LONG": -37.86,
    "LAT": -10.69,
    "CIDADE": "Paripiranga",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.73,
    "FEB": 5.63,
    "MAR": 5.78,
    "APR": 5.31,
    "MAY": 4.59,
    "JUN": 4.23,
    "JUL": 4.38,
    "AUG": 4.81,
    "SEP": 5.36,
    "OCT": 5.45,
    "NOV": 5.81,
    "DEC": 5.69
  },
  {
    "LONG": -40.28,
    "LAT": -10.57,
    "CIDADE": "Antônio Gonçalves",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.61,
    "FEB": 5.64,
    "MAR": 5.78,
    "APR": 4.93,
    "MAY": 4.29,
    "JUN": 4.06,
    "JUL": 4.28,
    "AUG": 4.79,
    "SEP": 5.66,
    "OCT": 5.5,
    "NOV": 5.55,
    "DEC": 5.51
  },
  {
    "LONG": -38.61,
    "LAT": -1.06,
    "CIDADE": "Banzaê",
    "UF": "BAHIA",
    "anual": 5.29,
    "JAN": 5.79,
    "FEB": 5.75,
    "MAR": 5.89,
    "APR": 5.28,
    "MAY": 4.56,
    "JUN": 4.22,
    "JUL": 4.38,
    "AUG": 4.86,
    "SEP": 5.54,
    "OCT": 5.58,
    "NOV": 5.87,
    "DEC": 5.75
  },
  {
    "LONG": -38.38,
    "LAT": -10.6,
    "CIDADE": "Cícero Dantas",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.73,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.32,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.3,
    "AUG": 4.72,
    "SEP": 5.45,
    "OCT": 5.49,
    "NOV": 5.84,
    "DEC": 5.73
  },
  {
    "LONG": -38.21,
    "LAT": -1.06,
    "CIDADE": "Fátima",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.83,
    "APR": 5.26,
    "MAY": 4.52,
    "JUN": 4.2,
    "JUL": 4.29,
    "AUG": 4.71,
    "SEP": 5.41,
    "OCT": 5.42,
    "NOV": 5.81,
    "DEC": 5.72
  },
  {
    "LONG": -40.32,
    "LAT": -1.05,
    "CIDADE": "Campo Formoso",
    "UF": "BAHIA",
    "anual": 5.33,
    "JAN": 5.8,
    "FEB": 5.76,
    "MAR": 5.88,
    "APR": 5.11,
    "MAY": 4.47,
    "JUN": 4.18,
    "JUL": 4.46,
    "AUG": 5.11,
    "SEP": 5.88,
    "OCT": 5.78,
    "NOV": 5.8,
    "DEC": 5.75
  },
  {
    "LONG": -40.19,
    "LAT": -10.46,
    "CIDADE": "Senhor do Bonfim",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.65,
    "FEB": 5.64,
    "MAR": 5.73,
    "APR": 4.96,
    "MAY": 4.35,
    "JUN": 4.04,
    "JUL": 4.28,
    "AUG": 4.79,
    "SEP": 5.65,
    "OCT": 5.51,
    "NOV": 5.63,
    "DEC": 5.57
  },
  {
    "LONG": -39.02,
    "LAT": -10.51,
    "CIDADE": "Euclides da Cunha",
    "UF": "BAHIA",
    "anual": 5.37,
    "JAN": 5.9,
    "FEB": 5.83,
    "MAR": 5.94,
    "APR": 5.32,
    "MAY": 4.6,
    "JUN": 4.21,
    "JUL": 4.44,
    "AUG": 4.92,
    "SEP": 5.8,
    "OCT": 5.7,
    "NOV": 5.98,
    "DEC": 5.76
  },
  {
    "LONG": -38.12,
    "LAT": -10.54,
    "CIDADE": "Adustina",
    "UF": "BAHIA",
    "anual": 5.22,
    "JAN": 5.75,
    "FEB": 5.65,
    "MAR": 5.76,
    "APR": 5.28,
    "MAY": 4.5,
    "JUN": 4.24,
    "JUL": 4.31,
    "AUG": 4.73,
    "SEP": 5.41,
    "OCT": 5.49,
    "NOV": 5.79,
    "DEC": 5.74
  },
  {
    "LONG": -39.33,
    "LAT": -10.44,
    "CIDADE": "Monte Santo",
    "UF": "BAHIA",
    "anual": 5.3,
    "JAN": 5.75,
    "FEB": 5.77,
    "MAR": 5.87,
    "APR": 5.2,
    "MAY": 4.55,
    "JUN": 4.2,
    "JUL": 4.4,
    "AUG": 4.87,
    "SEP": 5.78,
    "OCT": 5.62,
    "NOV": 5.88,
    "DEC": 5.73
  },
  {
    "LONG": -38.33,
    "LAT": -10.41,
    "CIDADE": "Antas",
    "UF": "BAHIA",
    "anual": 5.24,
    "JAN": 5.76,
    "FEB": 5.67,
    "MAR": 5.81,
    "APR": 5.3,
    "MAY": 4.54,
    "JUN": 4.22,
    "JUL": 4.35,
    "AUG": 4.79,
    "SEP": 5.44,
    "OCT": 5.46,
    "NOV": 5.8,
    "DEC": 5.71
  },
  {
    "LONG": -38.2,
    "LAT": -10.37,
    "CIDADE": "Sítio do Quinto",
    "UF": "BAHIA",
    "anual": 5.25,
    "JAN": 5.76,
    "FEB": 5.66,
    "MAR": 5.8,
    "APR": 5.3,
    "MAY": 4.58,
    "JUN": 4.24,
    "JUL": 4.39,
    "AUG": 4.79,
    "SEP": 5.45,
    "OCT": 5.48,
    "NOV": 5.84,
    "DEC": 5.73
  },
  {
    "LONG": -40.2,
    "LAT": -10.26,
    "CIDADE": "Jaguarari",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.72,
    "FEB": 5.68,
    "MAR": 5.83,
    "APR": 5.04,
    "MAY": 4.5,
    "JUN": 4.23,
    "JUL": 4.4,
    "AUG": 5.01,
    "SEP": 5.81,
    "OCT": 5.68,
    "NOV": 5.76,
    "DEC": 5.61
  },
  {
    "LONG": -39.83,
    "LAT": -10.34,
    "CIDADE": "Andorinha",
    "UF": "BAHIA",
    "anual": 5.32,
    "JAN": 5.74,
    "FEB": 5.79,
    "MAR": 5.84,
    "APR": 5.13,
    "MAY": 4.56,
    "JUN": 4.28,
    "JUL": 4.42,
    "AUG": 4.95,
    "SEP": 5.83,
    "OCT": 5.71,
    "NOV": 5.87,
    "DEC": 5.71
  },
  {
    "LONG": -3.84,
    "LAT": -10.33,
    "CIDADE": "Novo Triunfo",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.72,
    "FEB": 5.71,
    "MAR": 5.83,
    "APR": 5.23,
    "MAY": 4.52,
    "JUN": 4.16,
    "JUL": 4.26,
    "AUG": 4.77,
    "SEP": 5.47,
    "OCT": 5.5,
    "NOV": 5.82,
    "DEC": 5.8
  },
  {
    "LONG": -37.93,
    "LAT": -10.28,
    "CIDADE": "Coronel João Sá",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.66,
    "FEB": 5.71,
    "MAR": 5.78,
    "APR": 5.3,
    "MAY": 4.57,
    "JUN": 4.27,
    "JUL": 4.5,
    "AUG": 4.93,
    "SEP": 5.5,
    "OCT": 5.5,
    "NOV": 5.77,
    "DEC": 5.73
  },
  {
    "LONG": -3.83,
    "LAT": -10.08,
    "CIDADE": "Jeremoabo",
    "UF": "BAHIA",
    "anual": 5.29,
    "JAN": 5.75,
    "FEB": 5.73,
    "MAR": 5.86,
    "APR": 5.35,
    "MAY": 4.57,
    "JUN": 4.22,
    "JUL": 4.35,
    "AUG": 4.86,
    "SEP": 5.54,
    "OCT": 5.57,
    "NOV": 5.88,
    "DEC": 5.8
  },
  {
    "LONG": -4.25,
    "LAT": -1,
    "CIDADE": "Pilão Arcado",
    "UF": "BAHIA",
    "anual": 6.06,
    "JAN": 6.09,
    "FEB": 6.01,
    "MAR": 6.05,
    "APR": 5.86,
    "MAY": 5.85,
    "JUN": 5.7,
    "JUL": 5.96,
    "AUG": 6.37,
    "SEP": 6.45,
    "OCT": 6.38,
    "NOV": 6.11,
    "DEC": 5.93
  },
  {
    "LONG": -37.89,
    "LAT": -10.01,
    "CIDADE": "Pedro Alexandre",
    "UF": "BAHIA",
    "anual": 5.25,
    "JAN": 5.66,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.26,
    "MAY": 4.56,
    "JUN": 4.28,
    "JUL": 4.38,
    "AUG": 4.9,
    "SEP": 5.48,
    "OCT": 5.5,
    "NOV": 5.85,
    "DEC": 5.74
  },
  {
    "LONG": -39.03,
    "LAT": -9.9,
    "CIDADE": "Canudos",
    "UF": "BAHIA",
    "anual": 5.41,
    "JAN": 5.93,
    "FEB": 5.76,
    "MAR": 5.89,
    "APR": 5.39,
    "MAY": 4.67,
    "JUN": 4.31,
    "JUL": 4.48,
    "AUG": 5,
    "SEP": 5.85,
    "OCT": 5.82,
    "NOV": 5.99,
    "DEC": 5.9
  },
  {
    "LONG": -39.48,
    "LAT": -9.84,
    "CIDADE": "Uauá",
    "UF": "BAHIA",
    "anual": 5.49,
    "JAN": 5.88,
    "FEB": 5.88,
    "MAR": 5.91,
    "APR": 5.35,
    "MAY": 4.8,
    "JUN": 4.5,
    "JUL": 4.66,
    "AUG": 5.22,
    "SEP": 6.03,
    "OCT": 5.9,
    "NOV": 5.96,
    "DEC": 5.86
  },
  {
    "LONG": -41.88,
    "LAT": -9.74,
    "CIDADE": "Sento Sé",
    "UF": "BAHIA",
    "anual": 6.06,
    "JAN": 6.05,
    "FEB": 6.07,
    "MAR": 6.29,
    "APR": 5.9,
    "MAY": 5.85,
    "JUN": 5.86,
    "JUL": 6.02,
    "AUG": 6.27,
    "SEP": 6.05,
    "OCT": 6.16,
    "NOV": 6.22,
    "DEC": 5.94
  },
  {
    "LONG": -38.13,
    "LAT": -0.97,
    "CIDADE": "Santa Brígida",
    "UF": "BAHIA",
    "anual": 5.36,
    "JAN": 5.81,
    "FEB": 5.83,
    "MAR": 5.87,
    "APR": 5.41,
    "MAY": 4.67,
    "JUN": 4.25,
    "JUL": 4.44,
    "AUG": 4.89,
    "SEP": 5.57,
    "OCT": 5.7,
    "NOV": 6.01,
    "DEC": 5.87
  },
  {
    "LONG": -42.09,
    "LAT": -9.62,
    "CIDADE": "Remanso",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 6.07,
    "FEB": 5.98,
    "MAR": 6.12,
    "APR": 5.88,
    "MAY": 5.79,
    "JUN": 5.7,
    "JUL": 5.84,
    "AUG": 5.96,
    "SEP": 6.02,
    "OCT": 6.35,
    "NOV": 6.2,
    "DEC": 5.92
  },
  {
    "LONG": -43.01,
    "LAT": -0.95,
    "CIDADE": "Campo Alegre de Lourdes",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 5.65,
    "FEB": 5.56,
    "MAR": 5.66,
    "APR": 5.63,
    "MAY": 5.7,
    "JUN": 5.75,
    "JUL": 6.12,
    "AUG": 6.79,
    "SEP": 6.71,
    "OCT": 6.29,
    "NOV": 5.8,
    "DEC": 5.64
  },
  {
    "LONG": -40.79,
    "LAT": -9.47,
    "CIDADE": "Sobradinho",
    "UF": "BAHIA",
    "anual": 5.83,
    "JAN": 6.03,
    "FEB": 5.89,
    "MAR": 6.02,
    "APR": 5.45,
    "MAY": 5.3,
    "JUN": 5.13,
    "JUL": 5.38,
    "AUG": 5.99,
    "SEP": 6.42,
    "OCT": 6.27,
    "NOV": 6.23,
    "DEC": 5.9
  },
  {
    "LONG": -40.5,
    "LAT": -9.42,
    "CIDADE": "Juazeiro",
    "UF": "BAHIA",
    "anual": 5.78,
    "JAN": 6.01,
    "FEB": 5.97,
    "MAR": 6.04,
    "APR": 5.45,
    "MAY": 5.16,
    "JUN": 4.97,
    "JUL": 5.2,
    "AUG": 5.85,
    "SEP": 6.41,
    "OCT": 6.25,
    "NOV": 6.16,
    "DEC": 5.95
  },
  {
    "LONG": -38.22,
    "LAT": -9.4,
    "CIDADE": "Paulo Afonso",
    "UF": "BAHIA",
    "anual": 5.54,
    "JAN": 5.97,
    "FEB": 6.04,
    "MAR": 6.07,
    "APR": 5.6,
    "MAY": 4.85,
    "JUN": 4.39,
    "JUL": 4.51,
    "AUG": 5.07,
    "SEP": 5.87,
    "OCT": 5.95,
    "NOV": 6.09,
    "DEC": 6.04
  },
  {
    "LONG": -38.26,
    "LAT": -9.34,
    "CIDADE": "Glória",
    "UF": "BAHIA",
    "anual": 5.6,
    "JAN": 5.94,
    "FEB": 6.11,
    "MAR": 6.12,
    "APR": 5.69,
    "MAY": 4.95,
    "JUN": 4.49,
    "JUL": 4.68,
    "AUG": 5.23,
    "SEP": 5.89,
    "OCT": 5.91,
    "NOV": 6.16,
    "DEC": 6.02
  },
  {
    "LONG": -40.97,
    "LAT": -9.17,
    "CIDADE": "Casa Nova",
    "UF": "BAHIA",
    "anual": 5.8,
    "JAN": 5.96,
    "FEB": 5.79,
    "MAR": 5.9,
    "APR": 5.4,
    "MAY": 5.24,
    "JUN": 5.13,
    "JUL": 5.39,
    "AUG": 6.04,
    "SEP": 6.45,
    "OCT": 6.26,
    "NOV": 6.14,
    "DEC": 5.84
  },
  {
    "LONG": -39.05,
    "LAT": -9.16,
    "CIDADE": "Macururé",
    "UF": "BAHIA",
    "anual": 5.57,
    "JAN": 5.87,
    "FEB": 5.81,
    "MAR": 5.91,
    "APR": 5.5,
    "MAY": 4.88,
    "JUN": 4.55,
    "JUL": 4.72,
    "AUG": 5.38,
    "SEP": 6.11,
    "OCT": 6.05,
    "NOV": 6.13,
    "DEC": 5.92
  },
  {
    "LONG": -39.91,
    "LAT": -0.9,
    "CIDADE": "Curaçá",
    "UF": "BAHIA",
    "anual": 5.68,
    "JAN": 5.92,
    "FEB": 5.78,
    "MAR": 5.96,
    "APR": 5.5,
    "MAY": 5.02,
    "JUN": 4.76,
    "JUL": 4.98,
    "AUG": 5.71,
    "SEP": 6.33,
    "OCT": 6.17,
    "NOV": 6.11,
    "DEC": 5.85
  },
  {
    "LONG": -39.1,
    "LAT": -8.97,
    "CIDADE": "Chorrochó",
    "UF": "BAHIA",
    "anual": 5.62,
    "JAN": 5.87,
    "FEB": 5.78,
    "MAR": 5.98,
    "APR": 5.57,
    "MAY": 4.92,
    "JUN": 4.56,
    "JUL": 4.79,
    "AUG": 5.45,
    "SEP": 6.14,
    "OCT": 6.13,
    "NOV": 6.23,
    "DEC": 5.99
  },
  {
    "LONG": -38.78,
    "LAT": -8.85,
    "CIDADE": "Rodelas",
    "UF": "BAHIA",
    "anual": 5.8,
    "JAN": 6.12,
    "FEB": 6.1,
    "MAR": 6.3,
    "APR": 5.83,
    "MAY": 5.14,
    "JUN": 4.71,
    "JUL": 4.93,
    "AUG": 5.55,
    "SEP": 6.21,
    "OCT": 6.22,
    "NOV": 6.25,
    "DEC": 6.21
  },
  {
    "LONG": -39.11,
    "LAT": -8.72,
    "CIDADE": "Abaré",
    "UF": "BAHIA",
    "anual": 5.82,
    "JAN": 6.1,
    "FEB": 6.01,
    "MAR": 6.27,
    "APR": 5.82,
    "MAY": 5.17,
    "JUN": 4.81,
    "JUL": 4.97,
    "AUG": 5.64,
    "SEP": 6.25,
    "OCT": 6.31,
    "NOV": 6.35,
    "DEC": 6.19
  },
  {
    "LONG": -39.07,
    "LAT": -7.83,
    "CIDADE": "Penaforte",
    "UF": "CEARÁ",
    "anual": 5.75,
    "JAN": 5.7,
    "FEB": 5.84,
    "MAR": 5.98,
    "APR": 5.64,
    "MAY": 5.08,
    "JUN": 4.8,
    "JUL": 5.07,
    "AUG": 5.76,
    "SEP": 6.51,
    "OCT": 6.3,
    "NOV": 6.32,
    "DEC": 5.96
  },
  {
    "LONG": -39.01,
    "LAT": -7.69,
    "CIDADE": "Jati",
    "UF": "CEARÁ",
    "anual": 5.72,
    "JAN": 5.66,
    "FEB": 5.8,
    "MAR": 5.88,
    "APR": 5.62,
    "MAY": 5.08,
    "JUN": 4.78,
    "JUL": 5.04,
    "AUG": 5.77,
    "SEP": 6.48,
    "OCT": 6.27,
    "NOV": 6.27,
    "DEC": 5.94
  },
  {
    "LONG": -39.28,
    "LAT": -7.59,
    "CIDADE": "Jardim",
    "UF": "CEARÁ",
    "anual": 5.71,
    "JAN": 5.68,
    "FEB": 5.64,
    "MAR": 5.79,
    "APR": 5.52,
    "MAY": 4.98,
    "JUN": 4.73,
    "JUL": 5,
    "AUG": 5.85,
    "SEP": 6.56,
    "OCT": 6.38,
    "NOV": 6.39,
    "DEC": 6.01
  },
  {
    "LONG": -39.12,
    "LAT": -7.53,
    "CIDADE": "Porteiras",
    "UF": "CEARÁ",
    "anual": 5.77,
    "JAN": 5.67,
    "FEB": 5.66,
    "MAR": 5.78,
    "APR": 5.54,
    "MAY": 5.15,
    "JUN": 4.92,
    "JUL": 5.22,
    "AUG": 6.01,
    "SEP": 6.6,
    "OCT": 6.36,
    "NOV": 6.35,
    "DEC": 5.99
  },
  {
    "LONG": -38.99,
    "LAT": -0.75,
    "CIDADE": "Brejo Santo",
    "UF": "CEARÁ",
    "anual": 5.88,
    "JAN": 5.8,
    "FEB": 5.89,
    "MAR": 6.02,
    "APR": 5.85,
    "MAY": 5.32,
    "JUN": 5.04,
    "JUL": 5.31,
    "AUG": 5.99,
    "SEP": 6.57,
    "OCT": 6.38,
    "NOV": 6.37,
    "DEC": 5.99
  },
  {
    "LONG": -39.05,
    "LAT": -7.36,
    "CIDADE": "Abaiara",
    "UF": "CEARÁ",
    "anual": 5.92,
    "JAN": 5.74,
    "FEB": 5.79,
    "MAR": 5.89,
    "APR": 5.81,
    "MAY": 5.45,
    "JUN": 5.25,
    "JUL": 5.55,
    "AUG": 6.21,
    "SEP": 6.62,
    "OCT": 6.43,
    "NOV": 6.34,
    "DEC": 5.94
  },
  {
    "LONG": -38.77,
    "LAT": -7.39,
    "CIDADE": "Mauriti",
    "UF": "CEARÁ",
    "anual": 5.9,
    "JAN": 5.76,
    "FEB": 5.81,
    "MAR": 6,
    "APR": 5.87,
    "MAY": 5.36,
    "JUN": 5.12,
    "JUL": 5.4,
    "AUG": 6.1,
    "SEP": 6.62,
    "OCT": 6.43,
    "NOV": 6.38,
    "DEC": 5.93
  },
  {
    "LONG": -4.05,
    "LAT": -7.28,
    "CIDADE": "Salitre",
    "UF": "CEARÁ",
    "anual": 5.74,
    "JAN": 5.21,
    "FEB": 5.27,
    "MAR": 5.39,
    "APR": 5.43,
    "MAY": 5.42,
    "JUN": 5.48,
    "JUL": 5.79,
    "AUG": 6.4,
    "SEP": 6.56,
    "OCT": 6.29,
    "NOV": 6.06,
    "DEC": 5.59
  },
  {
    "LONG": -39.31,
    "LAT": -7.3,
    "CIDADE": "Barbalha",
    "UF": "CEARÁ",
    "anual": 5.85,
    "JAN": 5.63,
    "FEB": 5.68,
    "MAR": 5.8,
    "APR": 5.6,
    "MAY": 5.35,
    "JUN": 5.25,
    "JUL": 5.5,
    "AUG": 6.21,
    "SEP": 6.58,
    "OCT": 6.38,
    "NOV": 6.29,
    "DEC": 5.9
  },
  {
    "LONG": -38.94,
    "LAT": -7.3,
    "CIDADE": "Milagres",
    "UF": "CEARÁ",
    "anual": 5.91,
    "JAN": 5.74,
    "FEB": 5.79,
    "MAR": 5.93,
    "APR": 5.83,
    "MAY": 5.45,
    "JUN": 5.23,
    "JUL": 5.54,
    "AUG": 6.15,
    "SEP": 6.61,
    "OCT": 6.41,
    "NOV": 6.33,
    "DEC": 5.87
  },
  {
    "LONG": -40.14,
    "LAT": -7.21,
    "CIDADE": "Araripe",
    "UF": "CEARÁ",
    "anual": 5.77,
    "JAN": 5.23,
    "FEB": 5.32,
    "MAR": 5.49,
    "APR": 5.38,
    "MAY": 5.34,
    "JUN": 5.46,
    "JUL": 5.8,
    "AUG": 6.46,
    "SEP": 6.61,
    "OCT": 6.32,
    "NOV": 6.12,
    "DEC": 5.65
  },
  {
    "LONG": -39.74,
    "LAT": -7.18,
    "CIDADE": "Santana do Cariri",
    "UF": "CEARÁ",
    "anual": 5.73,
    "JAN": 5.35,
    "FEB": 5.35,
    "MAR": 5.54,
    "APR": 5.4,
    "MAY": 5.28,
    "JUN": 5.24,
    "JUL": 5.64,
    "AUG": 6.33,
    "SEP": 6.53,
    "OCT": 6.25,
    "NOV": 6.14,
    "DEC": 5.73
  },
  {
    "LONG": -39.41,
    "LAT": -0.72,
    "CIDADE": "Crato",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.56,
    "FEB": 5.61,
    "MAR": 5.68,
    "APR": 5.61,
    "MAY": 5.41,
    "JUN": 5.3,
    "JUL": 5.65,
    "AUG": 6.37,
    "SEP": 6.64,
    "OCT": 6.43,
    "NOV": 6.28,
    "DEC": 5.87
  },
  {
    "LONG": -39.32,
    "LAT": -7.22,
    "CIDADE": "Juazeiro do Norte",
    "UF": "CEARÁ",
    "anual": 5.89,
    "JAN": 5.65,
    "FEB": 5.73,
    "MAR": 5.85,
    "APR": 5.71,
    "MAY": 5.43,
    "JUN": 5.26,
    "JUL": 5.6,
    "AUG": 6.23,
    "SEP": 6.57,
    "OCT": 6.44,
    "NOV": 6.33,
    "DEC": 5.91
  },
  {
    "LONG": -39.14,
    "LAT": -7.25,
    "CIDADE": "Missão Velha",
    "UF": "CEARÁ",
    "anual": 5.88,
    "JAN": 5.74,
    "FEB": 5.74,
    "MAR": 5.93,
    "APR": 5.79,
    "MAY": 5.4,
    "JUN": 5.16,
    "JUL": 5.48,
    "AUG": 6.16,
    "SEP": 6.58,
    "OCT": 6.4,
    "NOV": 6.33,
    "DEC": 5.87
  },
  {
    "LONG": -38.78,
    "LAT": -0.72,
    "CIDADE": "Barro",
    "UF": "CEARÁ",
    "anual": 5.89,
    "JAN": 5.65,
    "FEB": 5.76,
    "MAR": 5.84,
    "APR": 5.71,
    "MAY": 5.39,
    "JUN": 5.21,
    "JUL": 5.54,
    "AUG": 6.17,
    "SEP": 6.62,
    "OCT": 6.44,
    "NOV": 6.41,
    "DEC": 5.91
  },
  {
    "LONG": -40.38,
    "LAT": -7.07,
    "CIDADE": "Campos Sales",
    "UF": "CEARÁ",
    "anual": 5.81,
    "JAN": 5.32,
    "FEB": 5.43,
    "MAR": 5.61,
    "APR": 5.5,
    "MAY": 5.38,
    "JUN": 5.34,
    "JUL": 5.8,
    "AUG": 6.41,
    "SEP": 6.64,
    "OCT": 6.35,
    "NOV": 6.23,
    "DEC": 5.72
  },
  {
    "LONG": -40.03,
    "LAT": -0.71,
    "CIDADE": "Potengi",
    "UF": "CEARÁ",
    "anual": 5.8,
    "JAN": 5.31,
    "FEB": 5.39,
    "MAR": 5.61,
    "APR": 5.47,
    "MAY": 5.37,
    "JUN": 5.37,
    "JUL": 5.74,
    "AUG": 6.43,
    "SEP": 6.63,
    "OCT": 6.36,
    "NOV": 6.17,
    "DEC": 5.72
  },
  {
    "LONG": -39.68,
    "LAT": -7.09,
    "CIDADE": "Nova Olinda",
    "UF": "CEARÁ",
    "anual": 5.88,
    "JAN": 5.58,
    "FEB": 5.64,
    "MAR": 5.78,
    "APR": 5.63,
    "MAY": 5.45,
    "JUN": 5.36,
    "JUL": 5.65,
    "AUG": 6.3,
    "SEP": 6.61,
    "OCT": 6.42,
    "NOV": 6.27,
    "DEC": 5.88
  },
  {
    "LONG": -39.74,
    "LAT": -7,
    "CIDADE": "Altaneira",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.52,
    "FEB": 5.57,
    "MAR": 5.76,
    "APR": 5.56,
    "MAY": 5.48,
    "JUN": 5.41,
    "JUL": 5.72,
    "AUG": 6.38,
    "SEP": 6.68,
    "OCT": 6.43,
    "NOV": 6.22,
    "DEC": 5.78
  },
  {
    "LONG": -39.28,
    "LAT": -7.03,
    "CIDADE": "Caririaçu",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.55,
    "FEB": 5.67,
    "MAR": 5.78,
    "APR": 5.63,
    "MAY": 5.42,
    "JUN": 5.34,
    "JUL": 5.62,
    "AUG": 6.29,
    "SEP": 6.57,
    "OCT": 6.4,
    "NOV": 6.32,
    "DEC": 5.8
  },
  {
    "LONG": -39.87,
    "LAT": -6.87,
    "CIDADE": "Assaré",
    "UF": "CEARÁ",
    "anual": 5.83,
    "JAN": 5.41,
    "FEB": 5.52,
    "MAR": 5.72,
    "APR": 5.48,
    "MAY": 5.49,
    "JUN": 5.41,
    "JUL": 5.7,
    "AUG": 6.38,
    "SEP": 6.6,
    "OCT": 6.42,
    "NOV": 6.17,
    "DEC": 5.71
  },
  {
    "LONG": -39.57,
    "LAT": -6.92,
    "CIDADE": "Farias Brito",
    "UF": "CEARÁ",
    "anual": 5.85,
    "JAN": 5.48,
    "FEB": 5.61,
    "MAR": 5.8,
    "APR": 5.61,
    "MAY": 5.45,
    "JUN": 5.39,
    "JUL": 5.68,
    "AUG": 6.35,
    "SEP": 6.57,
    "OCT": 6.35,
    "NOV": 6.2,
    "DEC": 5.73
  },
  {
    "LONG": -39.22,
    "LAT": -6.89,
    "CIDADE": "Granjeiro",
    "UF": "CEARÁ",
    "anual": 5.9,
    "JAN": 5.61,
    "FEB": 5.72,
    "MAR": 5.82,
    "APR": 5.65,
    "MAY": 5.44,
    "JUN": 5.44,
    "JUL": 5.69,
    "AUG": 6.33,
    "SEP": 6.58,
    "OCT": 6.43,
    "NOV": 6.3,
    "DEC": 5.84
  },
  {
    "LONG": -38.97,
    "LAT": -6.93,
    "CIDADE": "Aurora",
    "UF": "CEARÁ",
    "anual": 5.91,
    "JAN": 5.67,
    "FEB": 5.79,
    "MAR": 5.86,
    "APR": 5.76,
    "MAY": 5.46,
    "JUN": 5.29,
    "JUL": 5.6,
    "AUG": 6.26,
    "SEP": 6.55,
    "OCT": 6.42,
    "NOV": 6.36,
    "DEC": 5.9
  },
  {
    "LONG": -39.99,
    "LAT": -6.77,
    "CIDADE": "Antonina do Norte",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.3,
    "FEB": 5.45,
    "MAR": 5.7,
    "APR": 5.49,
    "MAY": 5.41,
    "JUN": 5.29,
    "JUL": 5.64,
    "AUG": 6.38,
    "SEP": 6.59,
    "OCT": 6.36,
    "NOV": 6.11,
    "DEC": 5.63
  },
  {
    "LONG": -39.3,
    "LAT": -6.79,
    "CIDADE": "Várzea Alegre",
    "UF": "CEARÁ",
    "anual": 5.91,
    "JAN": 5.63,
    "FEB": 5.75,
    "MAR": 5.82,
    "APR": 5.71,
    "MAY": 5.49,
    "JUN": 5.45,
    "JUL": 5.71,
    "AUG": 6.34,
    "SEP": 6.52,
    "OCT": 6.41,
    "NOV": 6.27,
    "DEC": 5.83
  },
  {
    "LONG": -38.72,
    "LAT": -6.78,
    "CIDADE": "Ipaumirim",
    "UF": "CEARÁ",
    "anual": 5.98,
    "JAN": 5.77,
    "FEB": 5.9,
    "MAR": 5.99,
    "APR": 5.87,
    "MAY": 5.51,
    "JUN": 5.34,
    "JUL": 5.63,
    "AUG": 6.28,
    "SEP": 6.55,
    "OCT": 6.44,
    "NOV": 6.43,
    "DEC": 6
  },
  {
    "LONG": -39.76,
    "LAT": -6.68,
    "CIDADE": "Tarrafas",
    "UF": "CEARÁ",
    "anual": 5.81,
    "JAN": 5.39,
    "FEB": 5.55,
    "MAR": 5.67,
    "APR": 5.51,
    "MAY": 5.43,
    "JUN": 5.39,
    "JUL": 5.7,
    "AUG": 6.38,
    "SEP": 6.59,
    "OCT": 6.33,
    "NOV": 6.11,
    "DEC": 5.62
  },
  {
    "LONG": -38.97,
    "LAT": -6.75,
    "CIDADE": "Lavras da Mangabeira",
    "UF": "CEARÁ",
    "anual": 5.95,
    "JAN": 5.73,
    "FEB": 5.83,
    "MAR": 5.89,
    "APR": 5.75,
    "MAY": 5.45,
    "JUN": 5.38,
    "JUL": 5.62,
    "AUG": 6.31,
    "SEP": 6.57,
    "OCT": 6.49,
    "NOV": 6.4,
    "DEC": 5.93
  },
  {
    "LONG": -3.87,
    "LAT": -6.72,
    "CIDADE": "Baixio",
    "UF": "CEARÁ",
    "anual": 5.99,
    "JAN": 5.8,
    "FEB": 5.9,
    "MAR": 6.02,
    "APR": 5.86,
    "MAY": 5.5,
    "JUN": 5.36,
    "JUL": 5.66,
    "AUG": 6.29,
    "SEP": 6.51,
    "OCT": 6.51,
    "NOV": 6.45,
    "DEC": 5.96
  },
  {
    "LONG": -40.12,
    "LAT": -6.57,
    "CIDADE": "Aiuaba",
    "UF": "CEARÁ",
    "anual": 5.74,
    "JAN": 5.28,
    "FEB": 5.31,
    "MAR": 5.51,
    "APR": 5.3,
    "MAY": 5.31,
    "JUN": 5.42,
    "JUL": 5.75,
    "AUG": 6.47,
    "SEP": 6.61,
    "OCT": 6.33,
    "NOV": 6.07,
    "DEC": 5.51
  },
  {
    "LONG": -39.06,
    "LAT": -6.6,
    "CIDADE": "Cedro",
    "UF": "CEARÁ",
    "anual": 5.95,
    "JAN": 5.66,
    "FEB": 5.84,
    "MAR": 5.88,
    "APR": 5.74,
    "MAY": 5.51,
    "JUN": 5.44,
    "JUL": 5.67,
    "AUG": 6.38,
    "SEP": 6.54,
    "OCT": 6.44,
    "NOV": 6.42,
    "DEC": 5.88
  },
  {
    "LONG": -38.7,
    "LAT": -6.64,
    "CIDADE": "Umari",
    "UF": "CEARÁ",
    "anual": 5.95,
    "JAN": 5.66,
    "FEB": 5.78,
    "MAR": 5.88,
    "APR": 5.71,
    "MAY": 5.46,
    "JUN": 5.39,
    "JUL": 5.67,
    "AUG": 6.37,
    "SEP": 6.56,
    "OCT": 6.54,
    "NOV": 6.46,
    "DEC": 5.87
  },
  {
    "LONG": -39.9,
    "LAT": -0.65,
    "CIDADE": "Saboeiro",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.33,
    "FEB": 5.47,
    "MAR": 5.59,
    "APR": 5.41,
    "MAY": 5.35,
    "JUN": 5.38,
    "JUL": 5.74,
    "AUG": 6.42,
    "SEP": 6.6,
    "OCT": 6.34,
    "NOV": 6.12,
    "DEC": 5.55
  },
  {
    "LONG": -3.95,
    "LAT": -6.52,
    "CIDADE": "Jucás",
    "UF": "CEARÁ",
    "anual": 5.82,
    "JAN": 5.39,
    "FEB": 5.59,
    "MAR": 5.73,
    "APR": 5.48,
    "MAY": 5.4,
    "JUN": 5.39,
    "JUL": 5.69,
    "AUG": 6.39,
    "SEP": 6.57,
    "OCT": 6.32,
    "NOV": 6.2,
    "DEC": 5.67
  },
  {
    "LONG": -39.5,
    "LAT": -6.53,
    "CIDADE": "Cariús",
    "UF": "CEARÁ",
    "anual": 5.85,
    "JAN": 5.45,
    "FEB": 5.62,
    "MAR": 5.78,
    "APR": 5.56,
    "MAY": 5.44,
    "JUN": 5.41,
    "JUL": 5.7,
    "AUG": 6.39,
    "SEP": 6.53,
    "OCT": 6.34,
    "NOV": 6.23,
    "DEC": 5.73
  },
  {
    "LONG": -39.29,
    "LAT": -6.36,
    "CIDADE": "Iguatu",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.49,
    "FEB": 5.67,
    "MAR": 5.84,
    "APR": 5.65,
    "MAY": 5.48,
    "JUN": 5.41,
    "JUL": 5.69,
    "AUG": 6.34,
    "SEP": 6.46,
    "OCT": 6.31,
    "NOV": 6.27,
    "DEC": 5.78
  },
  {
    "LONG": -38.86,
    "LAT": -6.4,
    "CIDADE": "Icó",
    "UF": "CEARÁ",
    "anual": 5.93,
    "JAN": 5.63,
    "FEB": 5.77,
    "MAR": 5.96,
    "APR": 5.83,
    "MAY": 5.47,
    "JUN": 5.37,
    "JUL": 5.66,
    "AUG": 6.35,
    "SEP": 6.47,
    "OCT": 6.48,
    "NOV": 6.36,
    "DEC": 5.82
  },
  {
    "LONG": -40.17,
    "LAT": -6.32,
    "CIDADE": "Arneiroz",
    "UF": "CEARÁ",
    "anual": 5.7,
    "JAN": 5.18,
    "FEB": 5.31,
    "MAR": 5.43,
    "APR": 5.31,
    "MAY": 5.31,
    "JUN": 5.32,
    "JUL": 5.73,
    "AUG": 6.43,
    "SEP": 6.56,
    "OCT": 6.3,
    "NOV": 6.05,
    "DEC": 5.49
  },
  {
    "LONG": -38.91,
    "LAT": -6.25,
    "CIDADE": "Orós",
    "UF": "CEARÁ",
    "anual": 5.9,
    "JAN": 5.6,
    "FEB": 5.71,
    "MAR": 5.94,
    "APR": 5.75,
    "MAY": 5.49,
    "JUN": 5.38,
    "JUL": 5.69,
    "AUG": 6.36,
    "SEP": 6.44,
    "OCT": 6.35,
    "NOV": 6.31,
    "DEC": 5.8
  },
  {
    "LONG": -40.69,
    "LAT": -6.21,
    "CIDADE": "Parambu",
    "UF": "CEARÁ",
    "anual": 5.64,
    "JAN": 5.08,
    "FEB": 5.18,
    "MAR": 5.36,
    "APR": 5.16,
    "MAY": 5.19,
    "JUN": 5.31,
    "JUL": 5.71,
    "AUG": 6.46,
    "SEP": 6.59,
    "OCT": 6.27,
    "NOV": 6,
    "DEC": 5.38
  },
  {
    "LONG": -39.2,
    "LAT": -6.25,
    "CIDADE": "Quixelô",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.51,
    "FEB": 5.75,
    "MAR": 5.86,
    "APR": 5.6,
    "MAY": 5.48,
    "JUN": 5.41,
    "JUL": 5.71,
    "AUG": 6.39,
    "SEP": 6.45,
    "OCT": 6.34,
    "NOV": 6.26,
    "DEC": 5.73
  },
  {
    "LONG": -3.99,
    "LAT": -6.12,
    "CIDADE": "Catarina",
    "UF": "CEARÁ",
    "anual": 5.73,
    "JAN": 5.27,
    "FEB": 5.42,
    "MAR": 5.52,
    "APR": 5.29,
    "MAY": 5.27,
    "JUN": 5.34,
    "JUL": 5.69,
    "AUG": 6.41,
    "SEP": 6.6,
    "OCT": 6.28,
    "NOV": 6.11,
    "DEC": 5.51
  },
  {
    "LONG": -39.45,
    "LAT": -6.09,
    "CIDADE": "Acopiara",
    "UF": "CEARÁ",
    "anual": 5.73,
    "JAN": 5.3,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.36,
    "MAY": 5.25,
    "JUN": 5.27,
    "JUL": 5.62,
    "AUG": 6.35,
    "SEP": 6.57,
    "OCT": 6.34,
    "NOV": 6.1,
    "DEC": 5.54
  },
  {
    "LONG": -40.3,
    "LAT": -5.99,
    "CIDADE": "Tauá",
    "UF": "CEARÁ",
    "anual": 5.65,
    "JAN": 5.06,
    "FEB": 5.25,
    "MAR": 5.4,
    "APR": 5.18,
    "MAY": 5.22,
    "JUN": 5.27,
    "JUL": 5.67,
    "AUG": 6.4,
    "SEP": 6.61,
    "OCT": 6.29,
    "NOV": 6.06,
    "DEC": 5.44
  },
  {
    "LONG": -38.46,
    "LAT": -6.04,
    "CIDADE": "Pereiro",
    "UF": "CEARÁ",
    "anual": 5.94,
    "JAN": 5.67,
    "FEB": 5.78,
    "MAR": 5.93,
    "APR": 5.72,
    "MAY": 5.49,
    "JUN": 5.37,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 6.52,
    "OCT": 6.54,
    "NOV": 6.41,
    "DEC": 5.87
  },
  {
    "LONG": -38.35,
    "LAT": -6.03,
    "CIDADE": "Ererê",
    "UF": "CEARÁ",
    "anual": 5.92,
    "JAN": 5.63,
    "FEB": 5.77,
    "MAR": 5.96,
    "APR": 5.77,
    "MAY": 5.52,
    "JUN": 5.34,
    "JUL": 5.65,
    "AUG": 6.27,
    "SEP": 6.49,
    "OCT": 6.46,
    "NOV": 6.34,
    "DEC": 5.79
  },
  {
    "LONG": -39.26,
    "LAT": -5.92,
    "CIDADE": "Deputado Irapuan Pinheiro",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.29,
    "FEB": 5.52,
    "MAR": 5.74,
    "APR": 5.42,
    "MAY": 5.31,
    "JUN": 5.25,
    "JUL": 5.64,
    "AUG": 6.31,
    "SEP": 6.56,
    "OCT": 6.38,
    "NOV": 6.25,
    "DEC": 5.64
  },
  {
    "LONG": -3.86,
    "LAT": -5.9,
    "CIDADE": "Jaguaribe",
    "UF": "CEARÁ",
    "anual": 5.92,
    "JAN": 5.65,
    "FEB": 5.76,
    "MAR": 6,
    "APR": 5.69,
    "MAY": 5.5,
    "JUN": 5.35,
    "JUL": 5.62,
    "AUG": 6.25,
    "SEP": 6.47,
    "OCT": 6.49,
    "NOV": 6.35,
    "DEC": 5.85
  },
  {
    "LONG": -40.7,
    "LAT": -5.84,
    "CIDADE": "Quiterianópolis",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 4.9,
    "FEB": 5.05,
    "MAR": 5.23,
    "APR": 5.02,
    "MAY": 5.08,
    "JUN": 5.24,
    "JUL": 5.62,
    "AUG": 6.4,
    "SEP": 6.56,
    "OCT": 6.27,
    "NOV": 5.97,
    "DEC": 5.34
  },
  {
    "LONG": -39.42,
    "LAT": -5.8,
    "CIDADE": "Piquet Carneiro",
    "UF": "CEARÁ",
    "anual": 5.7,
    "JAN": 5.2,
    "FEB": 5.37,
    "MAR": 5.58,
    "APR": 5.28,
    "MAY": 5.23,
    "JUN": 5.27,
    "JUL": 5.61,
    "AUG": 6.28,
    "SEP": 6.52,
    "OCT": 6.35,
    "NOV": 6.18,
    "DEC": 5.59
  },
  {
    "LONG": -38.29,
    "LAT": -5.81,
    "CIDADE": "Iracema",
    "UF": "CEARÁ",
    "anual": 5.92,
    "JAN": 5.67,
    "FEB": 5.81,
    "MAR": 5.97,
    "APR": 5.74,
    "MAY": 5.51,
    "JUN": 5.32,
    "JUL": 5.63,
    "AUG": 6.22,
    "SEP": 6.47,
    "OCT": 6.5,
    "NOV": 6.39,
    "DEC": 5.82
  },
  {
    "LONG": -39.63,
    "LAT": -5.74,
    "CIDADE": "Mombaça",
    "UF": "CEARÁ",
    "anual": 5.67,
    "JAN": 5.16,
    "FEB": 5.36,
    "MAR": 5.59,
    "APR": 5.31,
    "MAY": 5.19,
    "JUN": 5.22,
    "JUL": 5.6,
    "AUG": 6.24,
    "SEP": 6.5,
    "OCT": 6.29,
    "NOV": 6.08,
    "DEC": 5.47
  },
  {
    "LONG": -39.19,
    "LAT": -5.67,
    "CIDADE": "Milhã",
    "UF": "CEARÁ",
    "anual": 5.77,
    "JAN": 5.38,
    "FEB": 5.47,
    "MAR": 5.72,
    "APR": 5.39,
    "MAY": 5.22,
    "JUN": 5.25,
    "JUL": 5.59,
    "AUG": 6.26,
    "SEP": 6.53,
    "OCT": 6.41,
    "NOV": 6.29,
    "DEC": 5.7
  },
  {
    "LONG": -3.9,
    "LAT": -5.72,
    "CIDADE": "Solonópole",
    "UF": "CEARÁ",
    "anual": 5.81,
    "JAN": 5.42,
    "FEB": 5.56,
    "MAR": 5.78,
    "APR": 5.44,
    "MAY": 5.29,
    "JUN": 5.28,
    "JUL": 5.61,
    "AUG": 6.22,
    "SEP": 6.54,
    "OCT": 6.5,
    "NOV": 6.34,
    "DEC": 5.78
  },
  {
    "LONG": -38.16,
    "LAT": -5.71,
    "CIDADE": "Potiretama",
    "UF": "CEARÁ",
    "anual": 5.93,
    "JAN": 5.69,
    "FEB": 5.84,
    "MAR": 5.95,
    "APR": 5.73,
    "MAY": 5.51,
    "JUN": 5.32,
    "JUL": 5.67,
    "AUG": 6.24,
    "SEP": 6.45,
    "OCT": 6.5,
    "NOV": 6.4,
    "DEC": 5.8
  },
  {
    "LONG": -39.37,
    "LAT": -5.58,
    "CIDADE": "Senador Pompeu",
    "UF": "CEARÁ",
    "anual": 5.69,
    "JAN": 5.25,
    "FEB": 5.4,
    "MAR": 5.62,
    "APR": 5.23,
    "MAY": 5.2,
    "JUN": 5.18,
    "JUL": 5.58,
    "AUG": 6.21,
    "SEP": 6.51,
    "OCT": 6.35,
    "NOV": 6.19,
    "DEC": 5.54
  },
  {
    "LONG": -38.76,
    "LAT": -5.61,
    "CIDADE": "Jaguaretama",
    "UF": "CEARÁ",
    "anual": 5.92,
    "JAN": 5.66,
    "FEB": 5.79,
    "MAR": 6.06,
    "APR": 5.6,
    "MAY": 5.51,
    "JUN": 5.32,
    "JUL": 5.66,
    "AUG": 6.3,
    "SEP": 6.46,
    "OCT": 6.47,
    "NOV": 6.31,
    "DEC": 5.87
  },
  {
    "LONG": -40.77,
    "LAT": -5.53,
    "CIDADE": "Novo Oriente",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 4.9,
    "FEB": 5.13,
    "MAR": 5.36,
    "APR": 5.13,
    "MAY": 5.11,
    "JUN": 5.26,
    "JUL": 5.62,
    "AUG": 6.31,
    "SEP": 6.51,
    "OCT": 6.24,
    "NOV": 6,
    "DEC": 5.36
  },
  {
    "LONG": -39.71,
    "LAT": -5.45,
    "CIDADE": "Pedra Branca",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 5.17,
    "FEB": 5.3,
    "MAR": 5.48,
    "APR": 5.14,
    "MAY": 5.04,
    "JUN": 5.13,
    "JUL": 5.55,
    "AUG": 6.18,
    "SEP": 6.45,
    "OCT": 6.22,
    "NOV": 6.09,
    "DEC": 5.48
  },
  {
    "LONG": -38.46,
    "LAT": -0.55,
    "CIDADE": "Jaguaribara",
    "UF": "CEARÁ",
    "anual": 5.85,
    "JAN": 5.58,
    "FEB": 5.7,
    "MAR": 5.92,
    "APR": 5.66,
    "MAY": 5.57,
    "JUN": 5.33,
    "JUL": 5.61,
    "AUG": 6.23,
    "SEP": 6.35,
    "OCT": 6.35,
    "NOV": 6.2,
    "DEC": 5.74
  },
  {
    "LONG": -38.27,
    "LAT": -5.51,
    "CIDADE": "Alto Santo",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.53,
    "FEB": 5.76,
    "MAR": 5.89,
    "APR": 5.66,
    "MAY": 5.47,
    "JUN": 5.27,
    "JUL": 5.61,
    "AUG": 6.22,
    "SEP": 6.48,
    "OCT": 6.47,
    "NOV": 6.28,
    "DEC": 5.77
  },
  {
    "LONG": -40.31,
    "LAT": -5.39,
    "CIDADE": "Independência",
    "UF": "CEARÁ",
    "anual": 5.68,
    "JAN": 5.15,
    "FEB": 5.28,
    "MAR": 5.47,
    "APR": 5.21,
    "MAY": 5.23,
    "JUN": 5.3,
    "JUL": 5.62,
    "AUG": 6.3,
    "SEP": 6.6,
    "OCT": 6.39,
    "NOV": 6.1,
    "DEC": 5.54
  },
  {
    "LONG": -38.91,
    "LAT": -5.3,
    "CIDADE": "Banabuiú",
    "UF": "CEARÁ",
    "anual": 5.76,
    "JAN": 5.39,
    "FEB": 5.53,
    "MAR": 5.77,
    "APR": 5.39,
    "MAY": 5.36,
    "JUN": 5.2,
    "JUL": 5.56,
    "AUG": 6.23,
    "SEP": 6.5,
    "OCT": 6.36,
    "NOV": 6.18,
    "DEC": 5.65
  },
  {
    "LONG": -38.27,
    "LAT": -5.28,
    "CIDADE": "São João do Jaguaribe",
    "UF": "CEARÁ",
    "anual": 5.83,
    "JAN": 5.48,
    "FEB": 5.68,
    "MAR": 5.92,
    "APR": 5.59,
    "MAY": 5.47,
    "JUN": 5.24,
    "JUL": 5.6,
    "AUG": 6.2,
    "SEP": 6.42,
    "OCT": 6.47,
    "NOV": 6.23,
    "DEC": 5.66
  },
  {
    "LONG": -4.07,
    "LAT": -5.17,
    "CIDADE": "Crateús",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 4.93,
    "FEB": 5.21,
    "MAR": 5.42,
    "APR": 5.09,
    "MAY": 5.12,
    "JUN": 5.2,
    "JUL": 5.6,
    "AUG": 6.24,
    "SEP": 6.54,
    "OCT": 6.32,
    "NOV": 6.1,
    "DEC": 5.4
  },
  {
    "LONG": -39.29,
    "LAT": -5.19,
    "CIDADE": "Quixeramobim",
    "UF": "CEARÁ",
    "anual": 5.69,
    "JAN": 5.28,
    "FEB": 5.39,
    "MAR": 5.63,
    "APR": 5.26,
    "MAY": 5.28,
    "JUN": 5.13,
    "JUL": 5.5,
    "AUG": 6.19,
    "SEP": 6.48,
    "OCT": 6.35,
    "NOV": 6.17,
    "DEC": 5.6
  },
  {
    "LONG": -38.13,
    "LAT": -5.24,
    "CIDADE": "Tabuleiro do Norte",
    "UF": "CEARÁ",
    "anual": 5.84,
    "JAN": 5.57,
    "FEB": 5.7,
    "MAR": 5.93,
    "APR": 5.57,
    "MAY": 5.44,
    "JUN": 5.29,
    "JUL": 5.57,
    "AUG": 6.2,
    "SEP": 6.42,
    "OCT": 6.48,
    "NOV": 6.23,
    "DEC": 5.74
  },
  {
    "LONG": -3.97,
    "LAT": -0.51,
    "CIDADE": "Boa Viagem",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 5.11,
    "FEB": 5.29,
    "MAR": 5.46,
    "APR": 5.09,
    "MAY": 5.14,
    "JUN": 5.12,
    "JUL": 5.45,
    "AUG": 6.13,
    "SEP": 6.52,
    "OCT": 6.31,
    "NOV": 6.12,
    "DEC": 5.49
  },
  {
    "LONG": -38.37,
    "LAT": -5.1,
    "CIDADE": "Morada Nova",
    "UF": "CEARÁ",
    "anual": 5.85,
    "JAN": 5.56,
    "FEB": 5.69,
    "MAR": 5.93,
    "APR": 5.55,
    "MAY": 5.48,
    "JUN": 5.27,
    "JUL": 5.66,
    "AUG": 6.23,
    "SEP": 6.45,
    "OCT": 6.48,
    "NOV": 6.24,
    "DEC": 5.7
  },
  {
    "LONG": -3.81,
    "LAT": -5.14,
    "CIDADE": "Limoeiro do Norte",
    "UF": "CEARÁ",
    "anual": 5.8,
    "JAN": 5.57,
    "FEB": 5.7,
    "MAR": 5.86,
    "APR": 5.52,
    "MAY": 5.45,
    "JUN": 5.2,
    "JUL": 5.52,
    "AUG": 6.18,
    "SEP": 6.34,
    "OCT": 6.38,
    "NOV": 6.22,
    "DEC": 5.68
  },
  {
    "LONG": -37.98,
    "LAT": -5.07,
    "CIDADE": "Quixeré",
    "UF": "CEARÁ",
    "anual": 5.81,
    "JAN": 5.49,
    "FEB": 5.72,
    "MAR": 5.79,
    "APR": 5.57,
    "MAY": 5.47,
    "JUN": 5.29,
    "JUL": 5.58,
    "AUG": 6.15,
    "SEP": 6.37,
    "OCT": 6.4,
    "NOV": 6.2,
    "DEC": 5.69
  },
  {
    "LONG": -39.02,
    "LAT": -4.97,
    "CIDADE": "Quixadá",
    "UF": "CEARÁ",
    "anual": 5.69,
    "JAN": 5.31,
    "FEB": 5.45,
    "MAR": 5.6,
    "APR": 5.24,
    "MAY": 5.3,
    "JUN": 5.16,
    "JUL": 5.52,
    "AUG": 6.21,
    "SEP": 6.54,
    "OCT": 6.34,
    "NOV": 6.09,
    "DEC": 5.54
  },
  {
    "LONG": -38.64,
    "LAT": -4.97,
    "CIDADE": "Ibicuitinga",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.45,
    "FEB": 5.6,
    "MAR": 5.76,
    "APR": 5.38,
    "MAY": 5.38,
    "JUN": 5.26,
    "JUL": 5.63,
    "AUG": 6.26,
    "SEP": 6.5,
    "OCT": 6.44,
    "NOV": 6.12,
    "DEC": 5.61
  },
  {
    "LONG": -4.08,
    "LAT": -0.49,
    "CIDADE": "Ipaporanga",
    "UF": "CEARÁ",
    "anual": 5.54,
    "JAN": 4.88,
    "FEB": 5.16,
    "MAR": 5.37,
    "APR": 4.98,
    "MAY": 5.09,
    "JUN": 5.03,
    "JUL": 5.49,
    "AUG": 6.14,
    "SEP": 6.55,
    "OCT": 6.35,
    "NOV": 6.08,
    "DEC": 5.35
  },
  {
    "LONG": -37.97,
    "LAT": -4.93,
    "CIDADE": "Russas",
    "UF": "CEARÁ",
    "anual": 5.79,
    "JAN": 5.57,
    "FEB": 5.69,
    "MAR": 5.84,
    "APR": 5.5,
    "MAY": 5.43,
    "JUN": 5.27,
    "JUL": 5.52,
    "AUG": 6.12,
    "SEP": 6.35,
    "OCT": 6.36,
    "NOV": 6.15,
    "DEC": 5.69
  },
  {
    "LONG": -40.32,
    "LAT": -4.83,
    "CIDADE": "Tamboril",
    "UF": "CEARÁ",
    "anual": 5.62,
    "JAN": 4.96,
    "FEB": 5.21,
    "MAR": 5.29,
    "APR": 5.06,
    "MAY": 5.25,
    "JUN": 5.24,
    "JUL": 5.56,
    "AUG": 6.3,
    "SEP": 6.64,
    "OCT": 6.41,
    "NOV": 6.15,
    "DEC": 5.34
  },
  {
    "LONG": -4.01,
    "LAT": -4.79,
    "CIDADE": "Monsenhor Tabosa",
    "UF": "CEARÁ",
    "anual": 5.48,
    "JAN": 4.98,
    "FEB": 5.09,
    "MAR": 5.19,
    "APR": 4.81,
    "MAY": 4.96,
    "JUN": 4.91,
    "JUL": 5.42,
    "AUG": 6.14,
    "SEP": 6.52,
    "OCT": 6.28,
    "NOV": 6.07,
    "DEC": 5.35
  },
  {
    "LONG": -39.57,
    "LAT": -4.85,
    "CIDADE": "Madalena",
    "UF": "CEARÁ",
    "anual": 5.52,
    "JAN": 5.02,
    "FEB": 5.22,
    "MAR": 5.3,
    "APR": 5.02,
    "MAY": 5.05,
    "JUN": 5,
    "JUL": 5.37,
    "AUG": 6.1,
    "SEP": 6.5,
    "OCT": 6.3,
    "NOV": 6.03,
    "DEC": 5.36
  },
  {
    "LONG": -39.13,
    "LAT": -4.84,
    "CIDADE": "Choró",
    "UF": "CEARÁ",
    "anual": 5.64,
    "JAN": 5.22,
    "FEB": 5.34,
    "MAR": 5.54,
    "APR": 5.13,
    "MAY": 5.27,
    "JUN": 5.21,
    "JUL": 5.57,
    "AUG": 6.23,
    "SEP": 6.57,
    "OCT": 6.29,
    "NOV": 6,
    "DEC": 5.36
  },
  {
    "LONG": -38.82,
    "LAT": -4.82,
    "CIDADE": "Ibaretama",
    "UF": "CEARÁ",
    "anual": 5.71,
    "JAN": 5.35,
    "FEB": 5.47,
    "MAR": 5.65,
    "APR": 5.21,
    "MAY": 5.41,
    "JUN": 5.17,
    "JUL": 5.54,
    "AUG": 6.29,
    "SEP": 6.52,
    "OCT": 6.39,
    "NOV": 6.05,
    "DEC": 5.48
  },
  {
    "LONG": -37.78,
    "LAT": -4.83,
    "CIDADE": "Jaguaruana",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.56,
    "FEB": 5.68,
    "MAR": 5.81,
    "APR": 5.46,
    "MAY": 5.47,
    "JUN": 5.23,
    "JUL": 5.53,
    "AUG": 6.11,
    "SEP": 6.27,
    "OCT": 6.31,
    "NOV": 6.21,
    "DEC": 5.78
  },
  {
    "LONG": -40.92,
    "LAT": -4.75,
    "CIDADE": "Poranga",
    "UF": "CEARÁ",
    "anual": 5.8,
    "JAN": 5.14,
    "FEB": 5.3,
    "MAR": 5.51,
    "APR": 5.16,
    "MAY": 5.33,
    "JUN": 5.42,
    "JUL": 5.83,
    "AUG": 6.47,
    "SEP": 6.82,
    "OCT": 6.63,
    "NOV": 6.42,
    "DEC": 5.62
  },
  {
    "LONG": -40.83,
    "LAT": -4.75,
    "CIDADE": "Ararendá",
    "UF": "CEARÁ",
    "anual": 5.53,
    "JAN": 4.84,
    "FEB": 5,
    "MAR": 5.16,
    "APR": 4.81,
    "MAY": 4.97,
    "JUN": 5.01,
    "JUL": 5.55,
    "AUG": 6.31,
    "SEP": 6.7,
    "OCT": 6.47,
    "NOV": 6.16,
    "DEC": 5.42
  },
  {
    "LONG": -40.56,
    "LAT": -4.71,
    "CIDADE": "Nova Russas",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 4.95,
    "FEB": 5.26,
    "MAR": 5.44,
    "APR": 5.13,
    "MAY": 5.27,
    "JUN": 5.17,
    "JUL": 5.5,
    "AUG": 6.18,
    "SEP": 6.55,
    "OCT": 6.34,
    "NOV": 6.06,
    "DEC": 5.36
  },
  {
    "LONG": -37.97,
    "LAT": -4.74,
    "CIDADE": "Palhano",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.55,
    "FEB": 5.74,
    "MAR": 5.82,
    "APR": 5.4,
    "MAY": 5.48,
    "JUN": 5.25,
    "JUL": 5.52,
    "AUG": 6.11,
    "SEP": 6.31,
    "OCT": 6.27,
    "NOV": 6.15,
    "DEC": 5.76
  },
  {
    "LONG": -37.83,
    "LAT": -4.67,
    "CIDADE": "Itaiçaba",
    "UF": "CEARÁ",
    "anual": 5.82,
    "JAN": 5.61,
    "FEB": 5.78,
    "MAR": 5.85,
    "APR": 5.41,
    "MAY": 5.5,
    "JUN": 5.27,
    "JUL": 5.56,
    "AUG": 6.15,
    "SEP": 6.36,
    "OCT": 6.28,
    "NOV": 6.21,
    "DEC": 5.84
  },
  {
    "LONG": -37.35,
    "LAT": -4.71,
    "CIDADE": "Icapuí",
    "UF": "CEARÁ",
    "anual": 5.98,
    "JAN": 6.09,
    "FEB": 6.2,
    "MAR": 6.18,
    "APR": 5.82,
    "MAY": 5.73,
    "JUN": 5.56,
    "JUL": 5.73,
    "AUG": 6.24,
    "SEP": 6,
    "OCT": 5.99,
    "NOV": 6.1,
    "DEC": 6.17
  },
  {
    "LONG": -40.2,
    "LAT": -4.64,
    "CIDADE": "Catunda",
    "UF": "CEARÁ",
    "anual": 5.66,
    "JAN": 5.09,
    "FEB": 5.34,
    "MAR": 5.45,
    "APR": 5.09,
    "MAY": 5.18,
    "JUN": 5.12,
    "JUL": 5.5,
    "AUG": 6.22,
    "SEP": 6.67,
    "OCT": 6.47,
    "NOV": 6.21,
    "DEC": 5.52
  },
  {
    "LONG": -38.93,
    "LAT": -4.56,
    "CIDADE": "Itapiúna",
    "UF": "CEARÁ",
    "anual": 5.59,
    "JAN": 5.2,
    "FEB": 5.38,
    "MAR": 5.44,
    "APR": 5.07,
    "MAY": 5.25,
    "JUN": 5.03,
    "JUL": 5.45,
    "AUG": 6.18,
    "SEP": 6.43,
    "OCT": 6.24,
    "NOV": 5.99,
    "DEC": 5.42
  },
  {
    "LONG": -37.77,
    "LAT": -4.56,
    "CIDADE": "Aracati",
    "UF": "CEARÁ",
    "anual": 5.91,
    "JAN": 5.88,
    "FEB": 6.01,
    "MAR": 6.02,
    "APR": 5.49,
    "MAY": 5.52,
    "JUN": 5.31,
    "JUL": 5.63,
    "AUG": 6.19,
    "SEP": 6.24,
    "OCT": 6.26,
    "NOV": 6.24,
    "DEC": 6.11
  },
  {
    "LONG": -40.71,
    "LAT": -4.54,
    "CIDADE": "Ipueiras",
    "UF": "CEARÁ",
    "anual": 5.47,
    "JAN": 4.81,
    "FEB": 5.05,
    "MAR": 5.22,
    "APR": 4.9,
    "MAY": 5.01,
    "JUN": 5.06,
    "JUL": 5.45,
    "AUG": 6.13,
    "SEP": 6.5,
    "OCT": 6.26,
    "NOV": 6,
    "DEC": 5.24
  },
  {
    "LONG": -39.62,
    "LAT": -4.53,
    "CIDADE": "Itatira",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 5.08,
    "FEB": 5.25,
    "MAR": 5.33,
    "APR": 4.92,
    "MAY": 5.03,
    "JUN": 5.03,
    "JUL": 5.49,
    "AUG": 6.26,
    "SEP": 6.65,
    "OCT": 6.43,
    "NOV": 6.09,
    "DEC": 5.38
  },
  {
    "LONG": -38.91,
    "LAT": -4.46,
    "CIDADE": "Capistrano",
    "UF": "CEARÁ",
    "anual": 5.52,
    "JAN": 5.17,
    "FEB": 5.27,
    "MAR": 5.34,
    "APR": 4.9,
    "MAY": 5.13,
    "JUN": 5.01,
    "JUL": 5.4,
    "AUG": 6.13,
    "SEP": 6.38,
    "OCT": 6.18,
    "NOV": 5.96,
    "DEC": 5.38
  },
  {
    "LONG": -38.59,
    "LAT": -4.49,
    "CIDADE": "Ocara",
    "UF": "CEARÁ",
    "anual": 5.68,
    "JAN": 5.32,
    "FEB": 5.46,
    "MAR": 5.58,
    "APR": 5.22,
    "MAY": 5.4,
    "JUN": 5.27,
    "JUL": 5.62,
    "AUG": 6.18,
    "SEP": 6.38,
    "OCT": 6.24,
    "NOV": 5.98,
    "DEC": 5.5
  },
  {
    "LONG": -37.8,
    "LAT": -4.45,
    "CIDADE": "Fortim",
    "UF": "CEARÁ",
    "anual": 5.67,
    "JAN": 5.69,
    "FEB": 5.85,
    "MAR": 5.82,
    "APR": 5.41,
    "MAY": 5.48,
    "JUN": 5.25,
    "JUL": 5.36,
    "AUG": 5.81,
    "SEP": 5.83,
    "OCT": 5.9,
    "NOV": 5.91,
    "DEC": 5.72
  },
  {
    "LONG": -40.9,
    "LAT": -4.41,
    "CIDADE": "Croatá",
    "UF": "CEARÁ",
    "anual": 5.74,
    "JAN": 5.21,
    "FEB": 5.35,
    "MAR": 5.48,
    "APR": 5.14,
    "MAY": 5.24,
    "JUN": 5.3,
    "JUL": 5.66,
    "AUG": 6.34,
    "SEP": 6.69,
    "OCT": 6.51,
    "NOV": 6.34,
    "DEC": 5.63
  },
  {
    "LONG": -40.41,
    "LAT": "#VALOR!",
    "CIDADE": "Hidrolândia",
    "UF": "CEARÁ",
    "anual": 5.62,
    "JAN": 5.12,
    "FEB": 5.3,
    "MAR": 5.49,
    "APR": 5.14,
    "MAY": 5.27,
    "JUN": 5.17,
    "JUL": 5.4,
    "AUG": 6.14,
    "SEP": 6.57,
    "OCT": 6.37,
    "NOV": 6.08,
    "DEC": 5.41
  },
  {
    "LONG": -39.32,
    "LAT": -0.44,
    "CIDADE": "Canindé",
    "UF": "CEARÁ",
    "anual": 5.64,
    "JAN": 5.14,
    "FEB": 5.37,
    "MAR": 5.46,
    "APR": 5.08,
    "MAY": 5.25,
    "JUN": 5.15,
    "JUL": 5.52,
    "AUG": 6.22,
    "SEP": 6.62,
    "OCT": 6.35,
    "NOV": 6.06,
    "DEC": 5.4
  },
  {
    "LONG": -39.05,
    "LAT": -4.41,
    "CIDADE": "Aratuba",
    "UF": "CEARÁ",
    "anual": 5.63,
    "JAN": 5.24,
    "FEB": 5.3,
    "MAR": 5.31,
    "APR": 4.87,
    "MAY": 5.18,
    "JUN": 5.11,
    "JUL": 5.55,
    "AUG": 6.36,
    "SEP": 6.66,
    "OCT": 6.39,
    "NOV": 6.16,
    "DEC": 5.49
  },
  {
    "LONG": -38.81,
    "LAT": -4.37,
    "CIDADE": "Aracoiaba",
    "UF": "CEARÁ",
    "anual": 5.45,
    "JAN": 5.11,
    "FEB": 5.24,
    "MAR": 5.33,
    "APR": 4.89,
    "MAY": 5.07,
    "JUN": 4.92,
    "JUL": 5.34,
    "AUG": 6.02,
    "SEP": 6.23,
    "OCT": 6.06,
    "NOV": 5.86,
    "DEC": 5.31
  },
  {
    "LONG": -40.71,
    "LAT": -4.32,
    "CIDADE": "Ipu",
    "UF": "CEARÁ",
    "anual": 5.5,
    "JAN": 4.95,
    "FEB": 5.09,
    "MAR": 5.21,
    "APR": 4.88,
    "MAY": 4.93,
    "JUN": 5.07,
    "JUL": 5.47,
    "AUG": 6.22,
    "SEP": 6.54,
    "OCT": 6.31,
    "NOV": 6.02,
    "DEC": 5.3
  },
  {
    "LONG": -40.15,
    "LAT": -4.34,
    "CIDADE": "Santa Quitéria",
    "UF": "CEARÁ",
    "anual": 5.64,
    "JAN": 5.15,
    "FEB": 5.33,
    "MAR": 5.41,
    "APR": 5.1,
    "MAY": 5.19,
    "JUN": 5.18,
    "JUL": 5.45,
    "AUG": 6.19,
    "SEP": 6.59,
    "OCT": 6.42,
    "NOV": 6.18,
    "DEC": 5.48
  },
  {
    "LONG": -38.93,
    "LAT": -4.26,
    "CIDADE": "Guaramiranga",
    "UF": "CEARÁ",
    "anual": 5.44,
    "JAN": 5.09,
    "FEB": 5.08,
    "MAR": 5.1,
    "APR": 4.66,
    "MAY": 4.98,
    "JUN": 4.88,
    "JUL": 5.43,
    "AUG": 6.22,
    "SEP": 6.43,
    "OCT": 6.18,
    "NOV": 5.97,
    "DEC": 5.31
  },
  {
    "LONG": -39,
    "LAT": -4.3,
    "CIDADE": "Mulungu",
    "UF": "CEARÁ",
    "anual": 5.44,
    "JAN": 5.09,
    "FEB": 5.08,
    "MAR": 5.1,
    "APR": 4.66,
    "MAY": 4.98,
    "JUN": 4.88,
    "JUL": 5.43,
    "AUG": 6.22,
    "SEP": 6.43,
    "OCT": 6.18,
    "NOV": 5.97,
    "DEC": 5.31
  },
  {
    "LONG": -38.88,
    "LAT": -4.33,
    "CIDADE": "Baturité",
    "UF": "CEARÁ",
    "anual": 5.3,
    "JAN": 4.91,
    "FEB": 4.99,
    "MAR": 5.12,
    "APR": 4.67,
    "MAY": 4.93,
    "JUN": 4.74,
    "JUL": 5.27,
    "AUG": 5.96,
    "SEP": 6.15,
    "OCT": 5.92,
    "NOV": 5.75,
    "DEC": 5.15
  },
  {
    "LONG": -38.64,
    "LAT": -4.29,
    "CIDADE": "Barreira",
    "UF": "CEARÁ",
    "anual": 5.53,
    "JAN": 5.21,
    "FEB": 5.33,
    "MAR": 5.3,
    "APR": 4.91,
    "MAY": 5.19,
    "JUN": 5.07,
    "JUL": 5.47,
    "AUG": 6.1,
    "SEP": 6.31,
    "OCT": 6.13,
    "NOV": 5.94,
    "DEC": 5.41
  },
  {
    "LONG": -38.5,
    "LAT": -4.3,
    "CIDADE": "Chorozinho",
    "UF": "CEARÁ",
    "anual": 5.62,
    "JAN": 5.31,
    "FEB": 5.42,
    "MAR": 5.47,
    "APR": 5.03,
    "MAY": 5.31,
    "JUN": 5.18,
    "JUL": 5.53,
    "AUG": 6.14,
    "SEP": 6.29,
    "OCT": 6.2,
    "NOV": 6.03,
    "DEC": 5.49
  },
  {
    "LONG": -40.94,
    "LAT": -4.16,
    "CIDADE": "Carnaubal",
    "UF": "CEARÁ",
    "anual": 5.62,
    "JAN": 5.15,
    "FEB": 5.27,
    "MAR": 5.32,
    "APR": 4.96,
    "MAY": 5.07,
    "JUN": 5.17,
    "JUL": 5.53,
    "AUG": 6.22,
    "SEP": 6.58,
    "OCT": 6.39,
    "NOV": 6.24,
    "DEC": 5.53
  },
  {
    "LONG": -40.75,
    "LAT": -4.16,
    "CIDADE": "Guaraciaba do Norte",
    "UF": "CEARÁ",
    "anual": 5.5,
    "JAN": 4.98,
    "FEB": 5.12,
    "MAR": 5.18,
    "APR": 4.84,
    "MAY": 4.93,
    "JUN": 5.09,
    "JUL": 5.46,
    "AUG": 6.21,
    "SEP": 6.54,
    "OCT": 6.33,
    "NOV": 5.99,
    "DEC": 5.28
  },
  {
    "LONG": -40.64,
    "LAT": -4.24,
    "CIDADE": "Pires Ferreira",
    "UF": "CEARÁ",
    "anual": 5.41,
    "JAN": 4.88,
    "FEB": 5.13,
    "MAR": 5.17,
    "APR": 4.89,
    "MAY": 4.97,
    "JUN": 5.01,
    "JUL": 5.31,
    "AUG": 6,
    "SEP": 6.39,
    "OCT": 6.2,
    "NOV": 5.87,
    "DEC": 5.1
  },
  {
    "LONG": -40.47,
    "LAT": -4.19,
    "CIDADE": "Varjota",
    "UF": "CEARÁ",
    "anual": 5.61,
    "JAN": 5.12,
    "FEB": 5.31,
    "MAR": 5.43,
    "APR": 5.12,
    "MAY": 5.24,
    "JUN": 5.25,
    "JUL": 5.48,
    "AUG": 6.16,
    "SEP": 6.53,
    "OCT": 6.25,
    "NOV": 6.04,
    "DEC": 5.38
  },
  {
    "LONG": -39.19,
    "LAT": -4.23,
    "CIDADE": "Caridade",
    "UF": "CEARÁ",
    "anual": 5.69,
    "JAN": 5.15,
    "FEB": 5.42,
    "MAR": 5.32,
    "APR": 5.01,
    "MAY": 5.39,
    "JUN": 5.32,
    "JUL": 5.58,
    "AUG": 6.34,
    "SEP": 6.63,
    "OCT": 6.43,
    "NOV": 6.19,
    "DEC": 5.46
  },
  {
    "LONG": -38.92,
    "LAT": -4.23,
    "CIDADE": "Pacoti",
    "UF": "CEARÁ",
    "anual": 5.62,
    "JAN": 5.21,
    "FEB": 5.25,
    "MAR": 5.2,
    "APR": 4.79,
    "MAY": 5.23,
    "JUN": 5.17,
    "JUL": 5.59,
    "AUG": 6.36,
    "SEP": 6.63,
    "OCT": 6.39,
    "NOV": 6.15,
    "DEC": 5.48
  },
  {
    "LONG": -38.71,
    "LAT": -4.22,
    "CIDADE": "Acarape",
    "UF": "CEARÁ",
    "anual": 5.42,
    "JAN": 4.99,
    "FEB": 5.11,
    "MAR": 5.12,
    "APR": 4.76,
    "MAY": 5.1,
    "JUN": 5.04,
    "JUL": 5.49,
    "AUG": 6.13,
    "SEP": 6.28,
    "OCT": 5.99,
    "NOV": 5.81,
    "DEC": 5.19
  },
  {
    "LONG": -3.87,
    "LAT": -4.22,
    "CIDADE": "Redenção",
    "UF": "CEARÁ",
    "anual": 5.42,
    "JAN": 4.99,
    "FEB": 5.11,
    "MAR": 5.12,
    "APR": 4.76,
    "MAY": 5.1,
    "JUN": 5.04,
    "JUL": 5.49,
    "AUG": 6.13,
    "SEP": 6.28,
    "OCT": 5.99,
    "NOV": 5.81,
    "DEC": 5.19
  },
  {
    "LONG": -38.47,
    "LAT": -4.17,
    "CIDADE": "Pacajus",
    "UF": "CEARÁ",
    "anual": 5.7,
    "JAN": 5.48,
    "FEB": 5.58,
    "MAR": 5.51,
    "APR": 5.04,
    "MAY": 5.39,
    "JUN": 5.29,
    "JUL": 5.59,
    "AUG": 6.17,
    "SEP": 6.26,
    "OCT": 6.25,
    "NOV": 6.14,
    "DEC": 5.68
  },
  {
    "LONG": -38.13,
    "LAT": -4.18,
    "CIDADE": "Beberibe",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.77,
    "FEB": 5.95,
    "MAR": 5.8,
    "APR": 5.29,
    "MAY": 5.5,
    "JUN": 5.51,
    "JUL": 5.74,
    "AUG": 6.23,
    "SEP": 6.1,
    "OCT": 6.35,
    "NOV": 6.27,
    "DEC": 5.98
  },
  {
    "LONG": -40.58,
    "LAT": -4.14,
    "CIDADE": "Reriutaba",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 5.01,
    "FEB": 5.3,
    "MAR": 5.32,
    "APR": 5.06,
    "MAY": 5.17,
    "JUN": 5.22,
    "JUL": 5.47,
    "AUG": 6.18,
    "SEP": 6.52,
    "OCT": 6.3,
    "NOV": 5.91,
    "DEC": 5.27
  },
  {
    "LONG": -3.92,
    "LAT": -4.09,
    "CIDADE": "Paramoti",
    "UF": "CEARÁ",
    "anual": 5.61,
    "JAN": 5.09,
    "FEB": 5.3,
    "MAR": 5.27,
    "APR": 4.99,
    "MAY": 5.33,
    "JUN": 5.23,
    "JUL": 5.49,
    "AUG": 6.2,
    "SEP": 6.57,
    "OCT": 6.36,
    "NOV": 6.08,
    "DEC": 5.46
  },
  {
    "LONG": -38.84,
    "LAT": -4.14,
    "CIDADE": "Palmácia",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 5.18,
    "FEB": 5.31,
    "MAR": 5.31,
    "APR": 4.91,
    "MAY": 5.32,
    "JUN": 5.29,
    "JUL": 5.59,
    "AUG": 6.28,
    "SEP": 6.43,
    "OCT": 6.2,
    "NOV": 6,
    "DEC": 5.36
  },
  {
    "LONG": -38.49,
    "LAT": -4.11,
    "CIDADE": "Horizonte",
    "UF": "CEARÁ",
    "anual": 5.72,
    "JAN": 5.57,
    "FEB": 5.62,
    "MAR": 5.52,
    "APR": 4.98,
    "MAY": 5.34,
    "JUN": 5.33,
    "JUL": 5.63,
    "AUG": 6.19,
    "SEP": 6.23,
    "OCT": 6.3,
    "NOV": 6.21,
    "DEC": 5.73
  },
  {
    "LONG": -38.24,
    "LAT": -4.13,
    "CIDADE": "Cascavel",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.77,
    "FEB": 5.9,
    "MAR": 5.75,
    "APR": 5.23,
    "MAY": 5.58,
    "JUN": 5.53,
    "JUL": 5.77,
    "AUG": 6.21,
    "SEP": 6.12,
    "OCT": 6.34,
    "NOV": 6.26,
    "DEC": 5.92
  },
  {
    "LONG": -40.86,
    "LAT": -4.05,
    "CIDADE": "São Benedito",
    "UF": "CEARÁ",
    "anual": 5.42,
    "JAN": 4.83,
    "FEB": 4.9,
    "MAR": 4.98,
    "APR": 4.61,
    "MAY": 4.86,
    "JUN": 5.02,
    "JUL": 5.45,
    "AUG": 6.19,
    "SEP": 6.56,
    "OCT": 6.29,
    "NOV": 6.06,
    "DEC": 5.33
  },
  {
    "LONG": -40.7,
    "LAT": -3.98,
    "CIDADE": "Pacujá",
    "UF": "CEARÁ",
    "anual": 5.41,
    "JAN": 4.84,
    "FEB": 5.08,
    "MAR": 5.12,
    "APR": 4.85,
    "MAY": 5,
    "JUN": 5.08,
    "JUL": 5.38,
    "AUG": 6.05,
    "SEP": 6.41,
    "OCT": 6.2,
    "NOV": 5.83,
    "DEC": 5.14
  },
  {
    "LONG": -40.75,
    "LAT": -4.04,
    "CIDADE": "Graça",
    "UF": "CEARÁ",
    "anual": 5.41,
    "JAN": 4.84,
    "FEB": 5.08,
    "MAR": 5.12,
    "APR": 4.85,
    "MAY": 5,
    "JUN": 5.08,
    "JUL": 5.38,
    "AUG": 6.05,
    "SEP": 6.41,
    "OCT": 6.2,
    "NOV": 5.83,
    "DEC": 5.14
  },
  {
    "LONG": -40.48,
    "LAT": -3.95,
    "CIDADE": "Cariré",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 5.03,
    "FEB": 5.38,
    "MAR": 5.3,
    "APR": 5.02,
    "MAY": 5.14,
    "JUN": 5.17,
    "JUL": 5.41,
    "AUG": 6.11,
    "SEP": 6.53,
    "OCT": 6.28,
    "NOV": 5.96,
    "DEC": 5.34
  },
  {
    "LONG": -39.58,
    "LAT": -3.99,
    "CIDADE": "Tejuçuoca",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 5.02,
    "FEB": 5.3,
    "MAR": 5.3,
    "APR": 5.02,
    "MAY": 5.32,
    "JUN": 5.17,
    "JUL": 5.5,
    "AUG": 6.25,
    "SEP": 6.57,
    "OCT": 6.35,
    "NOV": 6.01,
    "DEC": 5.37
  },
  {
    "LONG": -39.45,
    "LAT": -4.04,
    "CIDADE": "General Sampaio",
    "UF": "CEARÁ",
    "anual": 5.55,
    "JAN": 4.96,
    "FEB": 5.24,
    "MAR": 5.23,
    "APR": 4.99,
    "MAY": 5.27,
    "JUN": 5.14,
    "JUL": 5.46,
    "AUG": 6.17,
    "SEP": 6.52,
    "OCT": 6.27,
    "NOV": 5.97,
    "DEC": 5.34
  },
  {
    "LONG": -38.62,
    "LAT": -3.98,
    "CIDADE": "Pacatuba",
    "UF": "CEARÁ",
    "anual": 5.44,
    "JAN": 5.18,
    "FEB": 5.25,
    "MAR": 5.16,
    "APR": 4.66,
    "MAY": 5.05,
    "JUN": 5.08,
    "JUL": 5.47,
    "AUG": 6.09,
    "SEP": 6.17,
    "OCT": 5.96,
    "NOV": 5.88,
    "DEC": 5.32
  },
  {
    "LONG": -38.64,
    "LAT": -0.4,
    "CIDADE": "Guaiúba",
    "UF": "CEARÁ",
    "anual": 5.44,
    "JAN": 5.18,
    "FEB": 5.25,
    "MAR": 5.16,
    "APR": 4.66,
    "MAY": 5.05,
    "JUN": 5.08,
    "JUL": 5.47,
    "AUG": 6.09,
    "SEP": 6.17,
    "OCT": 5.96,
    "NOV": 5.88,
    "DEC": 5.32
  },
  {
    "LONG": -38.53,
    "LAT": -3.97,
    "CIDADE": "Itaitinga",
    "UF": "CEARÁ",
    "anual": 5.59,
    "JAN": 5.37,
    "FEB": 5.44,
    "MAR": 5.39,
    "APR": 4.85,
    "MAY": 5.24,
    "JUN": 5.27,
    "JUL": 5.55,
    "AUG": 6.15,
    "SEP": 6.2,
    "OCT": 6.13,
    "NOV": 6.01,
    "DEC": 5.49
  },
  {
    "LONG": -38.31,
    "LAT": -4.02,
    "CIDADE": "Pindoretama",
    "UF": "CEARÁ",
    "anual": 5.8,
    "JAN": 5.65,
    "FEB": 5.82,
    "MAR": 5.63,
    "APR": 5.09,
    "MAY": 5.47,
    "JUN": 5.51,
    "JUL": 5.71,
    "AUG": 6.19,
    "SEP": 6.21,
    "OCT": 6.31,
    "NOV": 6.26,
    "DEC": 5.8
  },
  {
    "LONG": -40.92,
    "LAT": -3.85,
    "CIDADE": "Ubajara",
    "UF": "CEARÁ",
    "anual": 5.67,
    "JAN": 5.08,
    "FEB": 5.21,
    "MAR": 5.29,
    "APR": 4.91,
    "MAY": 5.16,
    "JUN": 5.31,
    "JUL": 5.62,
    "AUG": 6.36,
    "SEP": 6.68,
    "OCT": 6.47,
    "NOV": 6.31,
    "DEC": 5.57
  },
  {
    "LONG": -40.89,
    "LAT": -3.92,
    "CIDADE": "Ibiapina",
    "UF": "CEARÁ",
    "anual": 5.33,
    "JAN": 4.72,
    "FEB": 4.9,
    "MAR": 4.92,
    "APR": 4.59,
    "MAY": 4.82,
    "JUN": 4.96,
    "JUL": 5.35,
    "AUG": 6.07,
    "SEP": 6.4,
    "OCT": 6.18,
    "NOV": 5.89,
    "DEC": 5.14
  },
  {
    "LONG": -4.07,
    "LAT": -0.39,
    "CIDADE": "Mucambo",
    "UF": "CEARÁ",
    "anual": 5.44,
    "JAN": 4.82,
    "FEB": 5.11,
    "MAR": 5.17,
    "APR": 4.91,
    "MAY": 5.07,
    "JUN": 5.1,
    "JUL": 5.4,
    "AUG": 6.05,
    "SEP": 6.43,
    "OCT": 6.24,
    "NOV": 5.84,
    "DEC": 5.16
  },
  {
    "LONG": -40.39,
    "LAT": -3.92,
    "CIDADE": "Groaíras",
    "UF": "CEARÁ",
    "anual": 5.53,
    "JAN": 5.02,
    "FEB": 5.25,
    "MAR": 5.25,
    "APR": 4.96,
    "MAY": 5.1,
    "JUN": 5.17,
    "JUL": 5.41,
    "AUG": 6.11,
    "SEP": 6.5,
    "OCT": 6.3,
    "NOV": 6,
    "DEC": 5.34
  },
  {
    "LONG": -39.44,
    "LAT": -3.95,
    "CIDADE": "Apuiarés",
    "UF": "CEARÁ",
    "anual": 5.51,
    "JAN": 5.01,
    "FEB": 5.26,
    "MAR": 5.24,
    "APR": 4.87,
    "MAY": 5.21,
    "JUN": 5.1,
    "JUL": 5.41,
    "AUG": 6.09,
    "SEP": 6.49,
    "OCT": 6.23,
    "NOV": 5.92,
    "DEC": 5.34
  },
  {
    "LONG": -38.63,
    "LAT": -3.87,
    "CIDADE": "Maracanaú",
    "UF": "CEARÁ",
    "anual": 5.45,
    "JAN": 5.19,
    "FEB": 5.33,
    "MAR": 5.2,
    "APR": 4.67,
    "MAY": 5.1,
    "JUN": 5.1,
    "JUL": 5.42,
    "AUG": 6.03,
    "SEP": 6.1,
    "OCT": 6.02,
    "NOV": 5.89,
    "DEC": 5.36
  },
  {
    "LONG": -38.68,
    "LAT": -3.89,
    "CIDADE": "Maranguape",
    "UF": "CEARÁ",
    "anual": 5.45,
    "JAN": 5.19,
    "FEB": 5.33,
    "MAR": 5.2,
    "APR": 4.67,
    "MAY": 5.1,
    "JUN": 5.1,
    "JUL": 5.42,
    "AUG": 6.03,
    "SEP": 6.1,
    "OCT": 6.02,
    "NOV": 5.89,
    "DEC": 5.36
  },
  {
    "LONG": -38.46,
    "LAT": -3.89,
    "CIDADE": "Eusébio",
    "UF": "CEARÁ",
    "anual": 5.73,
    "JAN": 5.56,
    "FEB": 5.72,
    "MAR": 5.6,
    "APR": 4.97,
    "MAY": 5.35,
    "JUN": 5.41,
    "JUL": 5.61,
    "AUG": 6.06,
    "SEP": 6.2,
    "OCT": 6.29,
    "NOV": 6.22,
    "DEC": 5.79
  },
  {
    "LONG": -38.39,
    "LAT": -3.9,
    "CIDADE": "Aquiraz",
    "UF": "CEARÁ",
    "anual": 5.73,
    "JAN": 5.65,
    "FEB": 5.8,
    "MAR": 5.66,
    "APR": 5.08,
    "MAY": 5.43,
    "JUN": 5.43,
    "JUL": 5.57,
    "AUG": 5.89,
    "SEP": 6,
    "OCT": 6.15,
    "NOV": 6.22,
    "DEC": 5.89
  },
  {
    "LONG": -40.83,
    "LAT": -3.77,
    "CIDADE": "Frecheirinha",
    "UF": "CEARÁ",
    "anual": 5.39,
    "JAN": 4.74,
    "FEB": 5.03,
    "MAR": 5.03,
    "APR": 4.77,
    "MAY": 4.93,
    "JUN": 5.08,
    "JUL": 5.41,
    "AUG": 6.06,
    "SEP": 6.38,
    "OCT": 6.19,
    "NOV": 5.89,
    "DEC": 5.15
  },
  {
    "LONG": -40.26,
    "LAT": -3.8,
    "CIDADE": "Forquilha",
    "UF": "CEARÁ",
    "anual": 5.54,
    "JAN": 5.07,
    "FEB": 5.23,
    "MAR": 5.24,
    "APR": 4.93,
    "MAY": 5.15,
    "JUN": 5.08,
    "JUL": 5.39,
    "AUG": 6.12,
    "SEP": 6.51,
    "OCT": 6.31,
    "NOV": 6.03,
    "DEC": 5.37
  },
  {
    "LONG": -39.27,
    "LAT": -3.79,
    "CIDADE": "Pentecoste",
    "UF": "CEARÁ",
    "anual": 5.61,
    "JAN": 5.17,
    "FEB": 5.37,
    "MAR": 5.29,
    "APR": 4.92,
    "MAY": 5.36,
    "JUN": 5.25,
    "JUL": 5.5,
    "AUG": 6.22,
    "SEP": 6.47,
    "OCT": 6.27,
    "NOV": 6.06,
    "DEC": 5.45
  },
  {
    "LONG": -40.99,
    "LAT": "#VALOR!",
    "CIDADE": "Tianguá",
    "UF": "CEARÁ",
    "anual": 5.48,
    "JAN": 4.92,
    "FEB": 4.95,
    "MAR": 4.99,
    "APR": 4.7,
    "MAY": 4.96,
    "JUN": 5.15,
    "JUL": 5.51,
    "AUG": 6.22,
    "SEP": 6.54,
    "OCT": 6.36,
    "NOV": 6.11,
    "DEC": 5.38
  },
  {
    "LONG": -40.35,
    "LAT": -3.69,
    "CIDADE": "Sobral",
    "UF": "CEARÁ",
    "anual": 5.49,
    "JAN": 5.06,
    "FEB": 5.23,
    "MAR": 5.25,
    "APR": 4.96,
    "MAY": 5.12,
    "JUN": 5.06,
    "JUL": 5.34,
    "AUG": 5.98,
    "SEP": 6.41,
    "OCT": 6.23,
    "NOV": 5.98,
    "DEC": 5.29
  },
  {
    "LONG": -39.78,
    "LAT": -3.75,
    "CIDADE": "Irauçuba",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 5.07,
    "FEB": 5.24,
    "MAR": 5.18,
    "APR": 4.83,
    "MAY": 5.09,
    "JUN": 5.16,
    "JUL": 5.47,
    "AUG": 6.2,
    "SEP": 6.54,
    "OCT": 6.35,
    "NOV": 6.08,
    "DEC": 5.47
  },
  {
    "LONG": -39.59,
    "LAT": -3.68,
    "CIDADE": "Itapajé",
    "UF": "CEARÁ",
    "anual": 5.42,
    "JAN": 4.95,
    "FEB": 5.12,
    "MAR": 5.11,
    "APR": 4.72,
    "MAY": 5.05,
    "JUN": 5.02,
    "JUL": 5.33,
    "AUG": 6.1,
    "SEP": 6.37,
    "OCT": 6.12,
    "NOV": 5.85,
    "DEC": 5.29
  },
  {
    "LONG": -39.35,
    "LAT": -3.68,
    "CIDADE": "Umirim",
    "UF": "CEARÁ",
    "anual": 5.52,
    "JAN": 5.04,
    "FEB": 5.23,
    "MAR": 5.17,
    "APR": 4.88,
    "MAY": 5.28,
    "JUN": 5.16,
    "JUL": 5.46,
    "AUG": 6.18,
    "SEP": 6.4,
    "OCT": 6.18,
    "NOV": 5.92,
    "DEC": 5.31
  },
  {
    "LONG": -39.24,
    "LAT": -3.67,
    "CIDADE": "São Luís do Curu",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 5.15,
    "FEB": 5.27,
    "MAR": 5.2,
    "APR": 4.89,
    "MAY": 5.33,
    "JUN": 5.22,
    "JUL": 5.51,
    "AUG": 6.19,
    "SEP": 6.37,
    "OCT": 6.19,
    "NOV": 6,
    "DEC": 5.37
  },
  {
    "LONG": -38.66,
    "LAT": -3.73,
    "CIDADE": "Caucaia",
    "UF": "CEARÁ",
    "anual": 5.7,
    "JAN": 5.6,
    "FEB": 5.61,
    "MAR": 5.52,
    "APR": 4.86,
    "MAY": 5.29,
    "JUN": 5.36,
    "JUL": 5.63,
    "AUG": 6.12,
    "SEP": 6.16,
    "OCT": 6.24,
    "NOV": 6.19,
    "DEC": 5.83
  },
  {
    "LONG": -38.54,
    "LAT": -3.72,
    "CIDADE": "Fortaleza",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.72,
    "FEB": 5.72,
    "MAR": 5.64,
    "APR": 4.97,
    "MAY": 5.43,
    "JUN": 5.47,
    "JUL": 5.69,
    "AUG": 6.04,
    "SEP": 6.1,
    "OCT": 6.27,
    "NOV": 6.29,
    "DEC": 5.97
  },
  {
    "LONG": -41.09,
    "LAT": -3.57,
    "CIDADE": "Viçosa do Ceará",
    "UF": "CEARÁ",
    "anual": 5.49,
    "JAN": 4.85,
    "FEB": 5.02,
    "MAR": 5.01,
    "APR": 4.78,
    "MAY": 5.02,
    "JUN": 5.18,
    "JUL": 5.46,
    "AUG": 6.21,
    "SEP": 6.53,
    "OCT": 6.36,
    "NOV": 6.14,
    "DEC": 5.33
  },
  {
    "LONG": -40.65,
    "LAT": -3.55,
    "CIDADE": "Coreaú",
    "UF": "CEARÁ",
    "anual": 5.48,
    "JAN": 4.85,
    "FEB": 5.1,
    "MAR": 5.11,
    "APR": 4.88,
    "MAY": 5.16,
    "JUN": 5.18,
    "JUL": 5.46,
    "AUG": 6.11,
    "SEP": 6.47,
    "OCT": 6.3,
    "NOV": 5.95,
    "DEC": 5.15
  },
  {
    "LONG": -40.54,
    "LAT": -3.58,
    "CIDADE": "Alcântaras",
    "UF": "CEARÁ",
    "anual": 5.5,
    "JAN": 4.84,
    "FEB": 5.06,
    "MAR": 4.99,
    "APR": 4.75,
    "MAY": 5.08,
    "JUN": 5.22,
    "JUL": 5.49,
    "AUG": 6.19,
    "SEP": 6.59,
    "OCT": 6.4,
    "NOV": 6.1,
    "DEC": 5.34
  },
  {
    "LONG": -39.97,
    "LAT": -3.57,
    "CIDADE": "Miraíma",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 5.04,
    "FEB": 5.19,
    "MAR": 5.1,
    "APR": 4.88,
    "MAY": 5.26,
    "JUN": 5.25,
    "JUL": 5.49,
    "AUG": 6.27,
    "SEP": 6.55,
    "OCT": 6.33,
    "NOV": 6.04,
    "DEC": 5.32
  },
  {
    "LONG": -3.95,
    "LAT": -3.62,
    "CIDADE": "Uruburetama",
    "UF": "CEARÁ",
    "anual": 5.36,
    "JAN": 4.77,
    "FEB": 4.93,
    "MAR": 4.88,
    "APR": 4.57,
    "MAY": 4.98,
    "JUN": 5.11,
    "JUL": 5.44,
    "AUG": 6.23,
    "SEP": 6.42,
    "OCT": 6.13,
    "NOV": 5.8,
    "DEC": 5.08
  },
  {
    "LONG": "#VALOR!",
    "LAT": -3.58,
    "CIDADE": "Tururu",
    "UF": "CEARÁ",
    "anual": 5.34,
    "JAN": 4.73,
    "FEB": 4.99,
    "MAR": 4.97,
    "APR": 4.67,
    "MAY": 5.07,
    "JUN": 5.12,
    "JUL": 5.4,
    "AUG": 6.1,
    "SEP": 6.3,
    "OCT": 5.97,
    "NOV": 5.73,
    "DEC": 5
  },
  {
    "LONG": -3.9,
    "LAT": -3.61,
    "CIDADE": "São Gonçalo do Amarante",
    "UF": "CEARÁ",
    "anual": 5.77,
    "JAN": 5.59,
    "FEB": 5.62,
    "MAR": 5.47,
    "APR": 4.8,
    "MAY": 5.36,
    "JUN": 5.42,
    "JUL": 5.72,
    "AUG": 6.29,
    "SEP": 6.37,
    "OCT": 6.33,
    "NOV": 6.37,
    "DEC": 5.88
  },
  {
    "LONG": -40.68,
    "LAT": -3.47,
    "CIDADE": "Moraújo",
    "UF": "CEARÁ",
    "anual": 5.55,
    "JAN": 5.05,
    "FEB": 5.2,
    "MAR": 5.11,
    "APR": 4.88,
    "MAY": 5.19,
    "JUN": 5.28,
    "JUL": 5.5,
    "AUG": 6.14,
    "SEP": 6.48,
    "OCT": 6.34,
    "NOV": 6.04,
    "DEC": 5.44
  },
  {
    "LONG": -40.45,
    "LAT": -3.54,
    "CIDADE": "Meruoca",
    "UF": "CEARÁ",
    "anual": 5.61,
    "JAN": 4.99,
    "FEB": 5.21,
    "MAR": 5.19,
    "APR": 4.87,
    "MAY": 5.25,
    "JUN": 5.27,
    "JUL": 5.6,
    "AUG": 6.27,
    "SEP": 6.63,
    "OCT": 6.46,
    "NOV": 6.16,
    "DEC": 5.44
  },
  {
    "LONG": -40.34,
    "LAT": -0.35,
    "CIDADE": "Massapê",
    "UF": "CEARÁ",
    "anual": 5.52,
    "JAN": 4.82,
    "FEB": 5.14,
    "MAR": 5.19,
    "APR": 4.92,
    "MAY": 5.3,
    "JUN": 5.22,
    "JUL": 5.48,
    "AUG": 6.15,
    "SEP": 6.48,
    "OCT": 6.33,
    "NOV": 5.94,
    "DEC": 5.25
  },
  {
    "LONG": -40.21,
    "LAT": -3.46,
    "CIDADE": "Santana do Acaraú",
    "UF": "CEARÁ",
    "anual": 5.51,
    "JAN": 4.92,
    "FEB": 5.09,
    "MAR": 5.15,
    "APR": 4.92,
    "MAY": 5.25,
    "JUN": 5.16,
    "JUL": 5.42,
    "AUG": 6.15,
    "SEP": 6.47,
    "OCT": 6.3,
    "NOV": 5.99,
    "DEC": 5.31
  },
  {
    "LONG": -39.58,
    "LAT": -3.5,
    "CIDADE": "Itapipoca",
    "UF": "CEARÁ",
    "anual": 5.57,
    "JAN": 5.04,
    "FEB": 5.16,
    "MAR": 5.12,
    "APR": 4.76,
    "MAY": 5.32,
    "JUN": 5.44,
    "JUL": 5.66,
    "AUG": 6.26,
    "SEP": 6.47,
    "OCT": 6.25,
    "NOV": 6.03,
    "DEC": 5.3
  },
  {
    "LONG": -40.47,
    "LAT": -3.35,
    "CIDADE": "Senador Sá",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 4.96,
    "FEB": 5.13,
    "MAR": 5.15,
    "APR": 4.95,
    "MAY": 5.36,
    "JUN": 5.34,
    "JUL": 5.59,
    "AUG": 6.18,
    "SEP": 6.51,
    "OCT": 6.38,
    "NOV": 6.03,
    "DEC": 5.32
  },
  {
    "LONG": -39.83,
    "LAT": -3.36,
    "CIDADE": "Amontada",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 5.18,
    "FEB": 5.29,
    "MAR": 5.2,
    "APR": 4.81,
    "MAY": 5.25,
    "JUN": 5.26,
    "JUL": 5.41,
    "AUG": 6.15,
    "SEP": 6.46,
    "OCT": 6.31,
    "NOV": 6.14,
    "DEC": 5.51
  },
  {
    "LONG": -39.15,
    "LAT": -3.44,
    "CIDADE": "Paraipaba",
    "UF": "CEARÁ",
    "anual": 5.68,
    "JAN": 5.59,
    "FEB": 5.67,
    "MAR": 5.46,
    "APR": 4.81,
    "MAY": 5.28,
    "JUN": 5.36,
    "JUL": 5.57,
    "AUG": 6,
    "SEP": 6.16,
    "OCT": 6.14,
    "NOV": 6.24,
    "DEC": 5.91
  },
  {
    "LONG": -39.03,
    "LAT": -3.41,
    "CIDADE": "Paracuru",
    "UF": "CEARÁ",
    "anual": 5.69,
    "JAN": 5.64,
    "FEB": 5.75,
    "MAR": 5.53,
    "APR": 4.86,
    "MAY": 5.37,
    "JUN": 5.41,
    "JUL": 5.61,
    "AUG": 5.98,
    "SEP": 5.98,
    "OCT": 6.04,
    "NOV": 6.22,
    "DEC": 5.91
  },
  {
    "LONG": -40.56,
    "LAT": -3.31,
    "CIDADE": "Uruoca",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 4.94,
    "FEB": 5.04,
    "MAR": 5.09,
    "APR": 4.95,
    "MAY": 5.31,
    "JUN": 5.4,
    "JUL": 5.6,
    "AUG": 6.14,
    "SEP": 6.52,
    "OCT": 6.35,
    "NOV": 6.01,
    "DEC": 5.36
  },
  {
    "LONG": -39.27,
    "LAT": -3.27,
    "CIDADE": "Trairi",
    "UF": "CEARÁ",
    "anual": 5.59,
    "JAN": 5.5,
    "FEB": 5.6,
    "MAR": 5.44,
    "APR": 4.78,
    "MAY": 5.25,
    "JUN": 5.3,
    "JUL": 5.51,
    "AUG": 5.88,
    "SEP": 5.98,
    "OCT": 5.96,
    "NOV": 6.14,
    "DEC": 5.78
  },
  {
    "LONG": -4.07,
    "LAT": -3.22,
    "CIDADE": "Martinópole",
    "UF": "CEARÁ",
    "anual": 5.54,
    "JAN": 4.98,
    "FEB": 4.97,
    "MAR": 5.01,
    "APR": 4.83,
    "MAY": 5.28,
    "JUN": 5.42,
    "JUL": 5.52,
    "AUG": 6.15,
    "SEP": 6.55,
    "OCT": 6.39,
    "NOV": 6.03,
    "DEC": 5.3
  },
  {
    "LONG": -40.12,
    "LAT": -3.23,
    "CIDADE": "Morrinhos",
    "UF": "CEARÁ",
    "anual": 5.5,
    "JAN": 5.05,
    "FEB": 5.08,
    "MAR": 5.01,
    "APR": 4.69,
    "MAY": 5.25,
    "JUN": 5.3,
    "JUL": 5.47,
    "AUG": 6.13,
    "SEP": 6.42,
    "OCT": 6.2,
    "NOV": 6.03,
    "DEC": 5.41
  },
  {
    "LONG": -4.08,
    "LAT": -3.12,
    "CIDADE": "Granja",
    "UF": "CEARÁ",
    "anual": 5.46,
    "JAN": 4.94,
    "FEB": 4.93,
    "MAR": 4.91,
    "APR": 4.81,
    "MAY": 5.19,
    "JUN": 5.3,
    "JUL": 5.43,
    "AUG": 6.04,
    "SEP": 6.45,
    "OCT": 6.35,
    "NOV": 5.94,
    "DEC": 5.23
  },
  {
    "LONG": -40.15,
    "LAT": -3.12,
    "CIDADE": "Marco",
    "UF": "CEARÁ",
    "anual": 5.53,
    "JAN": 5.11,
    "FEB": 5.11,
    "MAR": 4.98,
    "APR": 4.63,
    "MAY": 5.23,
    "JUN": 5.42,
    "JUL": 5.52,
    "AUG": 6.14,
    "SEP": 6.38,
    "OCT": 6.18,
    "NOV": 6.07,
    "DEC": 5.55
  },
  {
    "LONG": -41.24,
    "LAT": -3.04,
    "CIDADE": "Chaval",
    "UF": "CEARÁ",
    "anual": 5.57,
    "JAN": 5.22,
    "FEB": 5.11,
    "MAR": 4.97,
    "APR": 4.82,
    "MAY": 5.17,
    "JUN": 5.42,
    "JUL": 5.65,
    "AUG": 6.19,
    "SEP": 6.3,
    "OCT": 6.16,
    "NOV": 6.17,
    "DEC": 5.67
  },
  {
    "LONG": -41.14,
    "LAT": -3.02,
    "CIDADE": "Barroquinha",
    "UF": "CEARÁ",
    "anual": 5.61,
    "JAN": 5.21,
    "FEB": 5.07,
    "MAR": 4.92,
    "APR": 4.84,
    "MAY": 5.14,
    "JUN": 5.42,
    "JUL": 5.69,
    "AUG": 6.26,
    "SEP": 6.41,
    "OCT": 6.34,
    "NOV": 6.24,
    "DEC": 5.71
  },
  {
    "LONG": -40.17,
    "LAT": -3.05,
    "CIDADE": "Bela Cruz",
    "UF": "CEARÁ",
    "anual": 5.57,
    "JAN": 5.26,
    "FEB": 5.19,
    "MAR": 5.04,
    "APR": 4.6,
    "MAY": 5.25,
    "JUN": 5.39,
    "JUL": 5.63,
    "AUG": 6.13,
    "SEP": 6.32,
    "OCT": 6.14,
    "NOV": 6.16,
    "DEC": 5.69
  },
  {
    "LONG": -4.08,
    "LAT": -2.9,
    "CIDADE": "Camocim",
    "UF": "CEARÁ",
    "anual": 5.53,
    "JAN": 5.27,
    "FEB": 5.15,
    "MAR": 4.91,
    "APR": 4.72,
    "MAY": 5.11,
    "JUN": 5.33,
    "JUL": 5.56,
    "AUG": 6.02,
    "SEP": 6.22,
    "OCT": 6.14,
    "NOV": 6.14,
    "DEC": 5.77
  },
  {
    "LONG": -4.04,
    "LAT": -0.29,
    "CIDADE": "Jijoca de Jericoacoara",
    "UF": "CEARÁ",
    "anual": 5.46,
    "JAN": 5.16,
    "FEB": 5.15,
    "MAR": 4.94,
    "APR": 4.57,
    "MAY": 5.11,
    "JUN": 5.26,
    "JUL": 5.5,
    "AUG": 6,
    "SEP": 6.14,
    "OCT": 6,
    "NOV": 6.06,
    "DEC": 5.59
  },
  {
    "LONG": -40.18,
    "LAT": -2.92,
    "CIDADE": "Cruz",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 5.38,
    "FEB": 5.34,
    "MAR": 5.14,
    "APR": 4.62,
    "MAY": 5.22,
    "JUN": 5.35,
    "JUL": 5.6,
    "AUG": 6.07,
    "SEP": 6.15,
    "OCT": 6.02,
    "NOV": 6.22,
    "DEC": 5.81
  },
  {
    "LONG": -40.12,
    "LAT": -2.89,
    "CIDADE": "Acaraú",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 5.38,
    "FEB": 5.34,
    "MAR": 5.14,
    "APR": 4.62,
    "MAY": 5.22,
    "JUN": 5.35,
    "JUL": 5.6,
    "AUG": 6.07,
    "SEP": 6.15,
    "OCT": 6.02,
    "NOV": 6.22,
    "DEC": 5.81
  },
  {
    "LONG": -3.99,
    "LAT": -2.93,
    "CIDADE": "Itarema",
    "UF": "CEARÁ",
    "anual": 5.63,
    "JAN": 5.53,
    "FEB": 5.5,
    "MAR": 5.36,
    "APR": 4.77,
    "MAY": 5.33,
    "JUN": 5.43,
    "JUL": 5.66,
    "AUG": 5.97,
    "SEP": 5.93,
    "OCT": 5.95,
    "NOV": 6.21,
    "DEC": 5.92
  },
  {
    "LONG": -47.93,
    "LAT": -15.78,
    "CIDADE": "Brasília",
    "UF": "DISTRITO FEDERAL",
    "anual": 5.43,
    "JAN": 4.9,
    "FEB": 5.41,
    "MAR": 5.07,
    "APR": 5.39,
    "MAY": 5.5,
    "JUN": 5.57,
    "JUL": 5.85,
    "AUG": 6.57,
    "SEP": 5.93,
    "OCT": 5.4,
    "NOV": 4.68,
    "DEC": 4.89
  },
  {
    "LONG": -41.57,
    "LAT": -21.15,
    "CIDADE": "Apiacá",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.11,
    "JAN": 5.58,
    "FEB": 6.11,
    "MAR": 5.39,
    "APR": 5.11,
    "MAY": 4.68,
    "JUN": 4.52,
    "JUL": 4.56,
    "AUG": 5.21,
    "SEP": 5.21,
    "OCT": 5.05,
    "NOV": 4.64,
    "DEC": 5.27
  },
  {
    "LONG": -41.67,
    "LAT": -21.12,
    "CIDADE": "Bom Jesus do Norte",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.07,
    "JAN": 5.5,
    "FEB": 5.97,
    "MAR": 5.3,
    "APR": 5.06,
    "MAY": 4.67,
    "JUN": 4.57,
    "JUL": 4.61,
    "AUG": 5.24,
    "SEP": 5.22,
    "OCT": 4.98,
    "NOV": 4.6,
    "DEC": 5.17
  },
  {
    "LONG": -41.36,
    "LAT": -21.06,
    "CIDADE": "Mimoso do Sul",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.03,
    "JAN": 5.55,
    "FEB": 6,
    "MAR": 5.25,
    "APR": 5.08,
    "MAY": 4.6,
    "JUN": 4.56,
    "JUL": 4.5,
    "AUG": 5.15,
    "SEP": 5.16,
    "OCT": 4.94,
    "NOV": 4.57,
    "DEC": 5.05
  },
  {
    "LONG": -41.05,
    "LAT": -21.1,
    "CIDADE": "Presidente Kennedy",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.15,
    "JAN": 5.7,
    "FEB": 6.31,
    "MAR": 5.44,
    "APR": 5.13,
    "MAY": 4.66,
    "JUN": 4.59,
    "JUL": 4.54,
    "AUG": 5.22,
    "SEP": 5.18,
    "OCT": 5.05,
    "NOV": 4.72,
    "DEC": 5.24
  },
  {
    "LONG": -41.66,
    "LAT": -21.03,
    "CIDADE": "São José do Calçado",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.99,
    "JAN": 5.36,
    "FEB": 5.85,
    "MAR": 5.18,
    "APR": 5,
    "MAY": 4.56,
    "JUN": 4.55,
    "JUL": 4.58,
    "AUG": 5.18,
    "SEP": 5.21,
    "OCT": 4.9,
    "NOV": 4.51,
    "DEC": 5.04
  },
  {
    "LONG": -41.35,
    "LAT": -20.95,
    "CIDADE": "Muqui",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.98,
    "JAN": 5.44,
    "FEB": 5.98,
    "MAR": 5.22,
    "APR": 4.96,
    "MAY": 4.56,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 5.12,
    "SEP": 5.18,
    "OCT": 4.87,
    "NOV": 4.48,
    "DEC": 5.03
  },
  {
    "LONG": -4.08,
    "LAT": "#VALOR!",
    "CIDADE": "Itapemirim",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.21,
    "JAN": 5.88,
    "FEB": 6.32,
    "MAR": 5.52,
    "APR": 5.15,
    "MAY": 4.63,
    "JUN": 4.6,
    "JUL": 4.57,
    "AUG": 5.29,
    "SEP": 5.29,
    "OCT": 5.17,
    "NOV": 4.77,
    "DEC": 5.36
  },
  {
    "LONG": -40.84,
    "LAT": -21.04,
    "CIDADE": "Marataízes",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.21,
    "JAN": 5.88,
    "FEB": 6.32,
    "MAR": 5.52,
    "APR": 5.15,
    "MAY": 4.63,
    "JUN": 4.6,
    "JUL": 4.57,
    "AUG": 5.29,
    "SEP": 5.29,
    "OCT": 5.17,
    "NOV": 4.77,
    "DEC": 5.36
  },
  {
    "LONG": -41.2,
    "LAT": -20.91,
    "CIDADE": "Atilio Vivacqua",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.14,
    "JAN": 5.62,
    "FEB": 6.19,
    "MAR": 5.43,
    "APR": 5.13,
    "MAY": 4.69,
    "JUN": 4.6,
    "JUL": 4.59,
    "AUG": 5.2,
    "SEP": 5.26,
    "OCT": 5.04,
    "NOV": 4.69,
    "DEC": 5.25
  },
  {
    "LONG": -40.94,
    "LAT": -20.86,
    "CIDADE": "Rio Novo do Sul",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.06,
    "JAN": 5.59,
    "FEB": 6.12,
    "MAR": 5.34,
    "APR": 5.03,
    "MAY": 4.59,
    "JUN": 4.57,
    "JUL": 4.49,
    "AUG": 5.13,
    "SEP": 5.14,
    "OCT": 4.95,
    "NOV": 4.56,
    "DEC": 5.16
  },
  {
    "LONG": -41.67,
    "LAT": -20.77,
    "CIDADE": "Guaçuí",
    "UF": "ESPÍRITO SANTO",
    "anual": 5,
    "JAN": 5.4,
    "FEB": 5.9,
    "MAR": 5.12,
    "APR": 4.91,
    "MAY": 4.57,
    "JUN": 4.54,
    "JUL": 4.62,
    "AUG": 5.24,
    "SEP": 5.26,
    "OCT": 4.94,
    "NOV": 4.48,
    "DEC": 4.96
  },
  {
    "LONG": -41.54,
    "LAT": -20.76,
    "CIDADE": "Alegre",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.09,
    "JAN": 5.52,
    "FEB": 6.05,
    "MAR": 5.3,
    "APR": 5.02,
    "MAY": 4.65,
    "JUN": 4.61,
    "JUL": 4.64,
    "AUG": 5.25,
    "SEP": 5.32,
    "OCT": 5.02,
    "NOV": 4.62,
    "DEC": 5.1
  },
  {
    "LONG": -41.4,
    "LAT": -20.8,
    "CIDADE": "Jerônimo Monteiro",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.15,
    "JAN": 5.56,
    "FEB": 6.08,
    "MAR": 5.41,
    "APR": 5.16,
    "MAY": 4.77,
    "JUN": 4.67,
    "JUL": 4.67,
    "AUG": 5.24,
    "SEP": 5.32,
    "OCT": 5.06,
    "NOV": 4.69,
    "DEC": 5.22
  },
  {
    "LONG": -41.12,
    "LAT": -20.85,
    "CIDADE": "Cachoeiro de Itapemirim",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.21,
    "JAN": 5.72,
    "FEB": 6.21,
    "MAR": 5.51,
    "APR": 5.21,
    "MAY": 4.77,
    "JUN": 4.7,
    "JUL": 4.66,
    "AUG": 5.26,
    "SEP": 5.32,
    "OCT": 5.12,
    "NOV": 4.76,
    "DEC": 5.32
  },
  {
    "LONG": -40.81,
    "LAT": -20.79,
    "CIDADE": "Iconha",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.85,
    "JAN": 5.39,
    "FEB": 5.93,
    "MAR": 5.1,
    "APR": 4.79,
    "MAY": 4.41,
    "JUN": 4.38,
    "JUL": 4.35,
    "AUG": 4.94,
    "SEP": 4.96,
    "OCT": 4.71,
    "NOV": 4.34,
    "DEC": 4.89
  },
  {
    "LONG": -40.73,
    "LAT": -20.83,
    "CIDADE": "Piúma",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.05,
    "JAN": 5.61,
    "FEB": 6.2,
    "MAR": 5.4,
    "APR": 5.05,
    "MAY": 4.57,
    "JUN": 4.53,
    "JUL": 4.48,
    "AUG": 5.09,
    "SEP": 5.11,
    "OCT": 4.93,
    "NOV": 4.56,
    "DEC": 5.12
  },
  {
    "LONG": -40.64,
    "LAT": -2.08,
    "CIDADE": "Anchieta",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.21,
    "JAN": 5.76,
    "FEB": 6.33,
    "MAR": 5.58,
    "APR": 5.28,
    "MAY": 4.71,
    "JUN": 4.62,
    "JUL": 4.61,
    "AUG": 5.23,
    "SEP": 5.25,
    "OCT": 5.12,
    "NOV": 4.76,
    "DEC": 5.31
  },
  {
    "LONG": -41.84,
    "LAT": -20.69,
    "CIDADE": "Dores do Rio Preto",
    "UF": "ESPÍRITO SANTO",
    "anual": 5,
    "JAN": 5.32,
    "FEB": 5.77,
    "MAR": 5.04,
    "APR": 4.93,
    "MAY": 4.57,
    "JUN": 4.65,
    "JUL": 4.71,
    "AUG": 5.36,
    "SEP": 5.27,
    "OCT": 4.95,
    "NOV": 4.44,
    "DEC": 5.01
  },
  {
    "LONG": -41.01,
    "LAT": -20.67,
    "CIDADE": "Vargem Alta",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.01,
    "JAN": 5.51,
    "FEB": 5.87,
    "MAR": 5.21,
    "APR": 5.03,
    "MAY": 4.62,
    "JUN": 4.58,
    "JUL": 4.51,
    "AUG": 5.14,
    "SEP": 5.19,
    "OCT": 4.89,
    "NOV": 4.5,
    "DEC": 5.08
  },
  {
    "LONG": -40.51,
    "LAT": -20.68,
    "CIDADE": "Guarapari",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.14,
    "JAN": 5.78,
    "FEB": 6.26,
    "MAR": 5.48,
    "APR": 5.15,
    "MAY": 4.7,
    "JUN": 4.57,
    "JUL": 4.54,
    "AUG": 5.17,
    "SEP": 5.2,
    "OCT": 4.95,
    "NOV": 4.65,
    "DEC": 5.24
  },
  {
    "LONG": -41.68,
    "LAT": -20.62,
    "CIDADE": "Divino de São Lourenço",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.98,
    "JAN": 5.33,
    "FEB": 5.88,
    "MAR": 5.11,
    "APR": 4.87,
    "MAY": 4.49,
    "JUN": 4.58,
    "JUL": 4.66,
    "AUG": 5.28,
    "SEP": 5.27,
    "OCT": 4.9,
    "NOV": 4.46,
    "DEC": 4.94
  },
  {
    "LONG": -41.2,
    "LAT": -20.6,
    "CIDADE": "Castelo",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.09,
    "JAN": 5.5,
    "FEB": 5.98,
    "MAR": 5.33,
    "APR": 5.07,
    "MAY": 4.69,
    "JUN": 4.63,
    "JUL": 4.66,
    "AUG": 5.25,
    "SEP": 5.28,
    "OCT": 4.98,
    "NOV": 4.63,
    "DEC": 5.12
  },
  {
    "LONG": -40.75,
    "LAT": -20.64,
    "CIDADE": "Alfredo Chaves",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.89,
    "JAN": 5.37,
    "FEB": 5.91,
    "MAR": 5.13,
    "APR": 4.84,
    "MAY": 4.44,
    "JUN": 4.44,
    "JUL": 4.41,
    "AUG": 4.97,
    "SEP": 5.03,
    "OCT": 4.75,
    "NOV": 4.42,
    "DEC": 4.95
  },
  {
    "LONG": -41.67,
    "LAT": -20.55,
    "CIDADE": "Ibitirama",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.93,
    "JAN": 5.22,
    "FEB": 5.82,
    "MAR": 5.06,
    "APR": 4.83,
    "MAY": 4.48,
    "JUN": 4.54,
    "JUL": 4.62,
    "AUG": 5.22,
    "SEP": 5.23,
    "OCT": 4.83,
    "NOV": 4.36,
    "DEC": 4.96
  },
  {
    "LONG": -4.14,
    "LAT": -20.47,
    "CIDADE": "Muniz Freire",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.06,
    "JAN": 5.45,
    "FEB": 5.93,
    "MAR": 5.28,
    "APR": 5.1,
    "MAY": 4.66,
    "JUN": 4.67,
    "JUL": 4.64,
    "AUG": 5.27,
    "SEP": 5.22,
    "OCT": 4.92,
    "NOV": 4.53,
    "DEC": 5.08
  },
  {
    "LONG": -41.64,
    "LAT": -20.35,
    "CIDADE": "Irupi",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.91,
    "JAN": 5.21,
    "FEB": 5.78,
    "MAR": 5.1,
    "APR": 4.89,
    "MAY": 4.5,
    "JUN": 4.52,
    "JUL": 4.57,
    "AUG": 5.14,
    "SEP": 5.12,
    "OCT": 4.79,
    "NOV": 4.38,
    "DEC": 4.98
  },
  {
    "LONG": -41.53,
    "LAT": -20.35,
    "CIDADE": "Iúna",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.31,
    "FEB": 5.86,
    "MAR": 5.16,
    "APR": 4.98,
    "MAY": 4.55,
    "JUN": 4.55,
    "JUL": 4.54,
    "AUG": 5.17,
    "SEP": 5.12,
    "OCT": 4.85,
    "NOV": 4.46,
    "DEC": 5.06
  },
  {
    "LONG": -41.24,
    "LAT": -20.36,
    "CIDADE": "Conceição do Castelo",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.94,
    "JAN": 5.32,
    "FEB": 5.84,
    "MAR": 5.14,
    "APR": 4.88,
    "MAY": 4.47,
    "JUN": 4.53,
    "JUL": 4.57,
    "AUG": 5.13,
    "SEP": 5.17,
    "OCT": 4.83,
    "NOV": 4.46,
    "DEC": 4.97
  },
  {
    "LONG": -40.66,
    "LAT": -20.36,
    "CIDADE": "Domingos Martins",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.84,
    "JAN": 5.28,
    "FEB": 5.86,
    "MAR": 5.2,
    "APR": 4.83,
    "MAY": 4.45,
    "JUN": 4.36,
    "JUL": 4.34,
    "AUG": 4.84,
    "SEP": 4.96,
    "OCT": 4.65,
    "NOV": 4.35,
    "DEC": 4.94
  },
  {
    "LONG": -40.68,
    "LAT": -20.41,
    "CIDADE": "Marechal Floriano",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.84,
    "JAN": 5.28,
    "FEB": 5.86,
    "MAR": 5.2,
    "APR": 4.83,
    "MAY": 4.45,
    "JUN": 4.36,
    "JUL": 4.34,
    "AUG": 4.84,
    "SEP": 4.96,
    "OCT": 4.65,
    "NOV": 4.35,
    "DEC": 4.94
  },
  {
    "LONG": -40.49,
    "LAT": -2.04,
    "CIDADE": "Viana",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.04,
    "JAN": 5.52,
    "FEB": 6.15,
    "MAR": 5.47,
    "APR": 5.07,
    "MAY": 4.62,
    "JUN": 4.51,
    "JUL": 4.46,
    "AUG": 5.02,
    "SEP": 5.09,
    "OCT": 4.8,
    "NOV": 4.58,
    "DEC": 5.19
  },
  {
    "LONG": -41.14,
    "LAT": -20.33,
    "CIDADE": "Venda Nova do Imigrante",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.86,
    "JAN": 5.27,
    "FEB": 5.79,
    "MAR": 5.1,
    "APR": 4.88,
    "MAY": 4.44,
    "JUN": 4.41,
    "JUL": 4.45,
    "AUG": 5.02,
    "SEP": 4.98,
    "OCT": 4.73,
    "NOV": 4.38,
    "DEC": 4.86
  },
  {
    "LONG": -40.42,
    "LAT": -20.26,
    "CIDADE": "Cariacica",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.95,
    "JAN": 5.45,
    "FEB": 6.05,
    "MAR": 5.4,
    "APR": 4.97,
    "MAY": 4.54,
    "JUN": 4.43,
    "JUL": 4.42,
    "AUG": 4.89,
    "SEP": 4.98,
    "OCT": 4.68,
    "NOV": 4.49,
    "DEC": 5.1
  },
  {
    "LONG": -40.31,
    "LAT": -20.32,
    "CIDADE": "Vitória",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.13,
    "JAN": 5.58,
    "FEB": 6.23,
    "MAR": 5.57,
    "APR": 5.16,
    "MAY": 4.71,
    "JUN": 4.52,
    "JUL": 4.55,
    "AUG": 5.13,
    "SEP": 5.19,
    "OCT": 4.91,
    "NOV": 4.73,
    "DEC": 5.3
  },
  {
    "LONG": -40.29,
    "LAT": -20.34,
    "CIDADE": "Vila Velha",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.2,
    "JAN": 5.69,
    "FEB": 6.3,
    "MAR": 5.62,
    "APR": 5.24,
    "MAY": 4.75,
    "JUN": 4.54,
    "JUL": 4.59,
    "AUG": 5.19,
    "SEP": 5.26,
    "OCT": 5.04,
    "NOV": 4.85,
    "DEC": 5.37
  },
  {
    "LONG": -4.15,
    "LAT": -20.24,
    "CIDADE": "Ibatiba",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.88,
    "JAN": 5.25,
    "FEB": 5.7,
    "MAR": 5.09,
    "APR": 5.02,
    "MAY": 4.52,
    "JUN": 4.43,
    "JUL": 4.49,
    "AUG": 5.04,
    "SEP": 5.02,
    "OCT": 4.72,
    "NOV": 4.41,
    "DEC": 4.9
  },
  {
    "LONG": -41.29,
    "LAT": "#VALOR!",
    "CIDADE": "Brejetuba",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.79,
    "JAN": 5.1,
    "FEB": 5.67,
    "MAR": 5.02,
    "APR": 4.93,
    "MAY": 4.47,
    "JUN": 4.33,
    "JUL": 4.39,
    "AUG": 4.94,
    "SEP": 4.88,
    "OCT": 4.63,
    "NOV": 4.33,
    "DEC": 4.84
  },
  {
    "LONG": -41.13,
    "LAT": -20.08,
    "CIDADE": "Afonso Cláudio",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.93,
    "JAN": 5.33,
    "FEB": 5.87,
    "MAR": 5.21,
    "APR": 5.09,
    "MAY": 4.55,
    "JUN": 4.43,
    "JUL": 4.45,
    "AUG": 5.04,
    "SEP": 4.98,
    "OCT": 4.76,
    "NOV": 4.45,
    "DEC": 4.96
  },
  {
    "LONG": -40.53,
    "LAT": -20.1,
    "CIDADE": "Santa Leopoldina",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.75,
    "JAN": 5.17,
    "FEB": 5.69,
    "MAR": 5.15,
    "APR": 4.78,
    "MAY": 4.41,
    "JUN": 4.28,
    "JUL": 4.26,
    "AUG": 4.71,
    "SEP": 4.8,
    "OCT": 4.52,
    "NOV": 4.27,
    "DEC": 4.9
  },
  {
    "LONG": -40.31,
    "LAT": -20.12,
    "CIDADE": "Serra",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.07,
    "JAN": 5.51,
    "FEB": 6.13,
    "MAR": 5.56,
    "APR": 5.09,
    "MAY": 4.68,
    "JUN": 4.51,
    "JUL": 4.56,
    "AUG": 5,
    "SEP": 5.13,
    "OCT": 4.84,
    "NOV": 4.62,
    "DEC": 5.24
  },
  {
    "LONG": -40.74,
    "LAT": -20.03,
    "CIDADE": "Santa Maria de Jetibá",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.78,
    "JAN": 5.25,
    "FEB": 5.77,
    "MAR": 5.15,
    "APR": 4.87,
    "MAY": 4.44,
    "JUN": 4.29,
    "JUL": 4.28,
    "AUG": 4.78,
    "SEP": 4.81,
    "OCT": 4.51,
    "NOV": 4.33,
    "DEC": 4.84
  },
  {
    "LONG": -41.06,
    "LAT": -19.9,
    "CIDADE": "Laranja da Terra",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.01,
    "JAN": 5.46,
    "FEB": 5.91,
    "MAR": 5.4,
    "APR": 5.25,
    "MAY": 4.7,
    "JUN": 4.49,
    "JUL": 4.51,
    "AUG": 5.03,
    "SEP": 4.98,
    "OCT": 4.79,
    "NOV": 4.52,
    "DEC": 5.07
  },
  {
    "LONG": -40.88,
    "LAT": -19.88,
    "CIDADE": "Itarana",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.38,
    "FEB": 5.99,
    "MAR": 5.37,
    "APR": 5.14,
    "MAY": 4.67,
    "JUN": 4.38,
    "JUL": 4.48,
    "AUG": 4.95,
    "SEP": 4.98,
    "OCT": 4.75,
    "NOV": 4.53,
    "DEC": 4.99
  },
  {
    "LONG": -40.6,
    "LAT": -19.94,
    "CIDADE": "Santa Teresa",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.69,
    "JAN": 5.14,
    "FEB": 5.64,
    "MAR": 5.14,
    "APR": 4.71,
    "MAY": 4.35,
    "JUN": 4.2,
    "JUL": 4.17,
    "AUG": 4.63,
    "SEP": 4.72,
    "OCT": 4.43,
    "NOV": 4.26,
    "DEC": 4.85
  },
  {
    "LONG": -40.41,
    "LAT": -19.94,
    "CIDADE": "Fundão",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.75,
    "JAN": 5.23,
    "FEB": 5.74,
    "MAR": 5.22,
    "APR": 4.75,
    "MAY": 4.39,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 4.65,
    "SEP": 4.75,
    "OCT": 4.5,
    "NOV": 4.3,
    "DEC": 4.94
  },
  {
    "LONG": -40.86,
    "LAT": -19.8,
    "CIDADE": "Itaguaçu",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.99,
    "JAN": 5.43,
    "FEB": 6.02,
    "MAR": 5.41,
    "APR": 5.14,
    "MAY": 4.65,
    "JUN": 4.43,
    "JUL": 4.49,
    "AUG": 4.95,
    "SEP": 4.99,
    "OCT": 4.76,
    "NOV": 4.5,
    "DEC": 5.05
  },
  {
    "LONG": -40.39,
    "LAT": -19.76,
    "CIDADE": "João Neiva",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.91,
    "JAN": 5.39,
    "FEB": 5.87,
    "MAR": 5.42,
    "APR": 4.98,
    "MAY": 4.58,
    "JUN": 4.4,
    "JUL": 4.43,
    "AUG": 4.8,
    "SEP": 4.89,
    "OCT": 4.62,
    "NOV": 4.45,
    "DEC": 5.04
  },
  {
    "LONG": -40.37,
    "LAT": -19.84,
    "CIDADE": "Ibiraçu",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.91,
    "JAN": 5.39,
    "FEB": 5.87,
    "MAR": 5.42,
    "APR": 4.98,
    "MAY": 4.58,
    "JUN": 4.4,
    "JUL": 4.43,
    "AUG": 4.8,
    "SEP": 4.89,
    "OCT": 4.62,
    "NOV": 4.45,
    "DEC": 5.04
  },
  {
    "LONG": -40.28,
    "LAT": -19.82,
    "CIDADE": "Aracruz",
    "UF": "ESPÍRITO SANTO",
    "anual": 5,
    "JAN": 5.5,
    "FEB": 5.97,
    "MAR": 5.51,
    "APR": 5.04,
    "MAY": 4.65,
    "JUN": 4.48,
    "JUL": 4.48,
    "AUG": 4.9,
    "SEP": 4.96,
    "OCT": 4.74,
    "NOV": 4.57,
    "DEC": 5.15
  },
  {
    "LONG": -40.65,
    "LAT": -19.74,
    "CIDADE": "São Roque do Canaã",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.47,
    "FEB": 5.91,
    "MAR": 5.42,
    "APR": 5.08,
    "MAY": 4.66,
    "JUN": 4.45,
    "JUL": 4.45,
    "AUG": 4.86,
    "SEP": 4.98,
    "OCT": 4.72,
    "NOV": 4.53,
    "DEC": 5.12
  },
  {
    "LONG": -41.01,
    "LAT": -19.52,
    "CIDADE": "Baixo Guandu",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.12,
    "JAN": 5.62,
    "FEB": 6.04,
    "MAR": 5.49,
    "APR": 5.25,
    "MAY": 4.75,
    "JUN": 4.5,
    "JUL": 4.59,
    "AUG": 5.09,
    "SEP": 5.15,
    "OCT": 4.96,
    "NOV": 4.71,
    "DEC": 5.28
  },
  {
    "LONG": -40.63,
    "LAT": -19.54,
    "CIDADE": "Colatina",
    "UF": "ESPÍRITO SANTO",
    "anual": 5,
    "JAN": 5.52,
    "FEB": 5.92,
    "MAR": 5.48,
    "APR": 5.09,
    "MAY": 4.66,
    "JUN": 4.44,
    "JUL": 4.43,
    "AUG": 4.92,
    "SEP": 5.02,
    "OCT": 4.81,
    "NOV": 4.6,
    "DEC": 5.14
  },
  {
    "LONG": -4.05,
    "LAT": -19.41,
    "CIDADE": "Marilândia",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.89,
    "JAN": 5.43,
    "FEB": 5.81,
    "MAR": 5.35,
    "APR": 4.93,
    "MAY": 4.5,
    "JUN": 4.34,
    "JUL": 4.32,
    "AUG": 4.78,
    "SEP": 4.9,
    "OCT": 4.74,
    "NOV": 4.51,
    "DEC": 5.12
  },
  {
    "LONG": -40.06,
    "LAT": -19.4,
    "CIDADE": "Linhares",
    "UF": "ESPÍRITO SANTO",
    "anual": 5,
    "JAN": 5.48,
    "FEB": 5.85,
    "MAR": 5.41,
    "APR": 4.98,
    "MAY": 4.65,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 4.89,
    "SEP": 5.07,
    "OCT": 4.85,
    "NOV": 4.66,
    "DEC": 5.27
  },
  {
    "LONG": -40.46,
    "LAT": -19.25,
    "CIDADE": "Governador Lindenberg",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.82,
    "JAN": 5.36,
    "FEB": 5.74,
    "MAR": 5.3,
    "APR": 4.85,
    "MAY": 4.44,
    "JUN": 4.26,
    "JUL": 4.27,
    "AUG": 4.67,
    "SEP": 4.83,
    "OCT": 4.64,
    "NOV": 4.43,
    "DEC": 5.06
  },
  {
    "LONG": -40.34,
    "LAT": -19.27,
    "CIDADE": "Rio Bananal",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.86,
    "JAN": 5.37,
    "FEB": 5.75,
    "MAR": 5.35,
    "APR": 4.88,
    "MAY": 4.52,
    "JUN": 4.33,
    "JUL": 4.33,
    "AUG": 4.68,
    "SEP": 4.89,
    "OCT": 4.64,
    "NOV": 4.44,
    "DEC": 5.1
  },
  {
    "LONG": -40.85,
    "LAT": -19.22,
    "CIDADE": "Pancas",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.89,
    "JAN": 5.34,
    "FEB": 5.76,
    "MAR": 5.29,
    "APR": 4.94,
    "MAY": 4.48,
    "JUN": 4.32,
    "JUL": 4.37,
    "AUG": 4.79,
    "SEP": 4.98,
    "OCT": 4.82,
    "NOV": 4.51,
    "DEC": 5.01
  },
  {
    "LONG": -40.1,
    "LAT": -19.19,
    "CIDADE": "Sooretama",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.95,
    "JAN": 5.52,
    "FEB": 5.8,
    "MAR": 5.41,
    "APR": 4.94,
    "MAY": 4.56,
    "JUN": 4.31,
    "JUL": 4.34,
    "AUG": 4.85,
    "SEP": 5.02,
    "OCT": 4.8,
    "NOV": 4.62,
    "DEC": 5.27
  },
  {
    "LONG": -41.02,
    "LAT": -19.06,
    "CIDADE": "Alto Rio Novo",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.95,
    "JAN": 5.44,
    "FEB": 5.82,
    "MAR": 5.32,
    "APR": 5.03,
    "MAY": 4.56,
    "JUN": 4.32,
    "JUL": 4.37,
    "AUG": 4.88,
    "SEP": 5.09,
    "OCT": 4.93,
    "NOV": 4.57,
    "DEC": 5.13
  },
  {
    "LONG": -40.62,
    "LAT": -19.14,
    "CIDADE": "São Domingos do Norte",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.94,
    "JAN": 5.48,
    "FEB": 5.87,
    "MAR": 5.45,
    "APR": 5.03,
    "MAY": 4.55,
    "JUN": 4.38,
    "JUL": 4.33,
    "AUG": 4.81,
    "SEP": 4.95,
    "OCT": 4.78,
    "NOV": 4.57,
    "DEC": 5.14
  },
  {
    "LONG": -4.07,
    "LAT": -18.99,
    "CIDADE": "Águia Branca",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.94,
    "JAN": 5.47,
    "FEB": 5.81,
    "MAR": 5.41,
    "APR": 5.07,
    "MAY": 4.56,
    "JUN": 4.41,
    "JUL": 4.36,
    "AUG": 4.78,
    "SEP": 4.91,
    "OCT": 4.81,
    "NOV": 4.53,
    "DEC": 5.15
  },
  {
    "LONG": -40.54,
    "LAT": -19.02,
    "CIDADE": "São Gabriel da Palha",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.94,
    "JAN": 5.52,
    "FEB": 5.91,
    "MAR": 5.41,
    "APR": 5.05,
    "MAY": 4.5,
    "JUN": 4.34,
    "JUL": 4.32,
    "AUG": 4.72,
    "SEP": 4.92,
    "OCT": 4.78,
    "NOV": 4.57,
    "DEC": 5.17
  },
  {
    "LONG": -40.39,
    "LAT": -19,
    "CIDADE": "Vila Valério",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.92,
    "JAN": 5.51,
    "FEB": 5.83,
    "MAR": 5.42,
    "APR": 4.99,
    "MAY": 4.52,
    "JUN": 4.33,
    "JUL": 4.29,
    "AUG": 4.75,
    "SEP": 4.94,
    "OCT": 4.77,
    "NOV": 4.53,
    "DEC": 5.15
  },
  {
    "LONG": -41.12,
    "LAT": -18.86,
    "CIDADE": "Mantenópolis",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.92,
    "JAN": 5.4,
    "FEB": 5.72,
    "MAR": 5.28,
    "APR": 4.91,
    "MAY": 4.48,
    "JUN": 4.3,
    "JUL": 4.33,
    "AUG": 4.85,
    "SEP": 5.07,
    "OCT": 4.94,
    "NOV": 4.58,
    "DEC": 5.14
  },
  {
    "LONG": -40.08,
    "LAT": -18.91,
    "CIDADE": "Jaguaré",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.94,
    "JAN": 5.48,
    "FEB": 5.84,
    "MAR": 5.44,
    "APR": 4.93,
    "MAY": 4.46,
    "JUN": 4.29,
    "JUL": 4.27,
    "AUG": 4.81,
    "SEP": 5.03,
    "OCT": 4.89,
    "NOV": 4.64,
    "DEC": 5.23
  },
  {
    "LONG": -40.9,
    "LAT": -18.76,
    "CIDADE": "Barra de São Francisco",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.98,
    "JAN": 5.56,
    "FEB": 5.92,
    "MAR": 5.47,
    "APR": 5.11,
    "MAY": 4.54,
    "JUN": 4.38,
    "JUL": 4.34,
    "AUG": 4.76,
    "SEP": 4.99,
    "OCT": 4.83,
    "NOV": 4.6,
    "DEC": 5.22
  },
  {
    "LONG": -40.41,
    "LAT": -18.72,
    "CIDADE": "Nova Venécia",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.93,
    "JAN": 5.51,
    "FEB": 5.83,
    "MAR": 5.41,
    "APR": 5.04,
    "MAY": 4.51,
    "JUN": 4.26,
    "JUL": 4.29,
    "AUG": 4.76,
    "SEP": 4.99,
    "OCT": 4.8,
    "NOV": 4.58,
    "DEC": 5.17
  },
  {
    "LONG": -39.86,
    "LAT": -18.72,
    "CIDADE": "São Mateus",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.12,
    "JAN": 5.68,
    "FEB": 6.09,
    "MAR": 5.64,
    "APR": 5.03,
    "MAY": 4.61,
    "JUN": 4.34,
    "JUL": 4.43,
    "AUG": 5.01,
    "SEP": 5.25,
    "OCT": 5.1,
    "NOV": 4.87,
    "DEC": 5.44
  },
  {
    "LONG": -40.61,
    "LAT": -18.61,
    "CIDADE": "Vila Pavão",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.92,
    "JAN": 5.43,
    "FEB": 5.82,
    "MAR": 5.38,
    "APR": 5.09,
    "MAY": 4.57,
    "JUN": 4.3,
    "JUL": 4.28,
    "AUG": 4.7,
    "SEP": 4.95,
    "OCT": 4.79,
    "NOV": 4.53,
    "DEC": 5.23
  },
  {
    "LONG": -39.74,
    "LAT": -18.59,
    "CIDADE": "Conceição da Barra",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.27,
    "JAN": 5.77,
    "FEB": 6.17,
    "MAR": 5.74,
    "APR": 5.14,
    "MAY": 4.76,
    "JUN": 4.45,
    "JUL": 4.54,
    "AUG": 5.15,
    "SEP": 5.5,
    "OCT": 5.33,
    "NOV": 5.06,
    "DEC": 5.57
  },
  {
    "LONG": -40.99,
    "LAT": -18.55,
    "CIDADE": "Água Doce do Norte",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.89,
    "JAN": 5.37,
    "FEB": 5.79,
    "MAR": 5.32,
    "APR": 5,
    "MAY": 4.43,
    "JUN": 4.29,
    "JUL": 4.33,
    "AUG": 4.71,
    "SEP": 5,
    "OCT": 4.8,
    "NOV": 4.53,
    "DEC": 5.12
  },
  {
    "LONG": -40.29,
    "LAT": -18.54,
    "CIDADE": "Boa Esperança",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.51,
    "FEB": 5.85,
    "MAR": 5.39,
    "APR": 5.03,
    "MAY": 4.5,
    "JUN": 4.27,
    "JUL": 4.32,
    "AUG": 4.81,
    "SEP": 5.06,
    "OCT": 4.93,
    "NOV": 4.66,
    "DEC": 5.28
  },
  {
    "LONG": -4.08,
    "LAT": -18.37,
    "CIDADE": "Ecoporanga",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.9,
    "JAN": 5.42,
    "FEB": 5.84,
    "MAR": 5.37,
    "APR": 5.08,
    "MAY": 4.43,
    "JUN": 4.25,
    "JUL": 4.26,
    "AUG": 4.68,
    "SEP": 4.97,
    "OCT": 4.81,
    "NOV": 4.58,
    "DEC": 5.18
  },
  {
    "LONG": -40.22,
    "LAT": -18.41,
    "CIDADE": "Pinheiros",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.98,
    "JAN": 5.56,
    "FEB": 5.88,
    "MAR": 5.41,
    "APR": 5,
    "MAY": 4.46,
    "JUN": 4.29,
    "JUL": 4.32,
    "AUG": 4.78,
    "SEP": 5.06,
    "OCT": 4.97,
    "NOV": 4.66,
    "DEC": 5.32
  },
  {
    "LONG": -39.95,
    "LAT": -18.3,
    "CIDADE": "Pedro Canário",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.01,
    "JAN": 5.55,
    "FEB": 5.9,
    "MAR": 5.43,
    "APR": 4.97,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.34,
    "AUG": 4.91,
    "SEP": 5.12,
    "OCT": 4.99,
    "NOV": 4.77,
    "DEC": 5.39
  },
  {
    "LONG": -40.54,
    "LAT": -18.12,
    "CIDADE": "Ponto Belo",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.57,
    "FEB": 5.92,
    "MAR": 5.45,
    "APR": 5.05,
    "MAY": 4.4,
    "JUN": 4.15,
    "JUL": 4.28,
    "AUG": 4.76,
    "SEP": 5.03,
    "OCT": 5,
    "NOV": 4.67,
    "DEC": 5.36
  },
  {
    "LONG": -40.52,
    "LAT": -18.09,
    "CIDADE": "Mucurici",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.57,
    "FEB": 5.92,
    "MAR": 5.45,
    "APR": 5.05,
    "MAY": 4.4,
    "JUN": 4.15,
    "JUL": 4.28,
    "AUG": 4.76,
    "SEP": 5.03,
    "OCT": 5,
    "NOV": 4.67,
    "DEC": 5.36
  },
  {
    "LONG": -40.36,
    "LAT": -18.13,
    "CIDADE": "Montanha",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.01,
    "JAN": 5.63,
    "FEB": 5.89,
    "MAR": 5.48,
    "APR": 5.06,
    "MAY": 4.43,
    "JUN": 4.27,
    "JUL": 4.32,
    "AUG": 4.8,
    "SEP": 5.07,
    "OCT": 5.05,
    "NOV": 4.75,
    "DEC": 5.39
  },
  {
    "LONG": -51.4,
    "LAT": -19.19,
    "CIDADE": "Lagoa Santa",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.22,
    "FEB": 5.62,
    "MAR": 5.53,
    "APR": 5.68,
    "MAY": 5.32,
    "JUN": 5.12,
    "JUL": 5.36,
    "AUG": 6.02,
    "SEP": 5.49,
    "OCT": 5.46,
    "NOV": 5.34,
    "DEC": 5.47
  },
  {
    "LONG": -51.55,
    "LAT": -19.07,
    "CIDADE": "Itajá",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.23,
    "FEB": 5.61,
    "MAR": 5.54,
    "APR": 5.69,
    "MAY": 5.33,
    "JUN": 5.16,
    "JUL": 5.35,
    "AUG": 6,
    "SEP": 5.47,
    "OCT": 5.42,
    "NOV": 5.3,
    "DEC": 5.42
  },
  {
    "LONG": -51.93,
    "LAT": -1.9,
    "CIDADE": "Aporé",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 5.14,
    "FEB": 5.59,
    "MAR": 5.48,
    "APR": 5.51,
    "MAY": 5.23,
    "JUN": 5.16,
    "JUL": 5.29,
    "AUG": 6,
    "SEP": 5.4,
    "OCT": 5.32,
    "NOV": 5.21,
    "DEC": 5.4
  },
  {
    "LONG": -50.55,
    "LAT": -19,
    "CIDADE": "São Simão",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.22,
    "FEB": 5.65,
    "MAR": 5.53,
    "APR": 5.64,
    "MAY": 5.33,
    "JUN": 5.15,
    "JUL": 5.3,
    "AUG": 5.96,
    "SEP": 5.43,
    "OCT": 5.44,
    "NOV": 5.37,
    "DEC": 5.4
  },
  {
    "LONG": -50.65,
    "LAT": -18.91,
    "CIDADE": "Paranaiguara",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.16,
    "FEB": 5.49,
    "MAR": 5.46,
    "APR": 5.64,
    "MAY": 5.36,
    "JUN": 5.16,
    "JUL": 5.41,
    "AUG": 6.09,
    "SEP": 5.49,
    "OCT": 5.4,
    "NOV": 5.26,
    "DEC": 5.32
  },
  {
    "LONG": -51.35,
    "LAT": -1.88,
    "CIDADE": "Itarumã",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.25,
    "FEB": 5.58,
    "MAR": 5.53,
    "APR": 5.66,
    "MAY": 5.33,
    "JUN": 5.17,
    "JUL": 5.34,
    "AUG": 6.08,
    "SEP": 5.48,
    "OCT": 5.46,
    "NOV": 5.32,
    "DEC": 5.39
  },
  {
    "LONG": -50.94,
    "LAT": -18.76,
    "CIDADE": "Cachoeira Alta",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.24,
    "FEB": 5.54,
    "MAR": 5.45,
    "APR": 5.64,
    "MAY": 5.36,
    "JUN": 5.14,
    "JUL": 5.38,
    "AUG": 6.11,
    "SEP": 5.49,
    "OCT": 5.4,
    "NOV": 5.29,
    "DEC": 5.33
  },
  {
    "LONG": -51.13,
    "LAT": -18.56,
    "CIDADE": "Caçu",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.18,
    "FEB": 5.49,
    "MAR": 5.49,
    "APR": 5.66,
    "MAY": 5.37,
    "JUN": 5.15,
    "JUL": 5.43,
    "AUG": 6.17,
    "SEP": 5.49,
    "OCT": 5.37,
    "NOV": 5.32,
    "DEC": 5.28
  },
  {
    "LONG": -49.99,
    "LAT": -18.49,
    "CIDADE": "Inaciolândia",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.27,
    "FEB": 5.48,
    "MAR": 5.37,
    "APR": 5.59,
    "MAY": 5.41,
    "JUN": 5.27,
    "JUL": 5.44,
    "AUG": 6.19,
    "SEP": 5.59,
    "OCT": 5.43,
    "NOV": 5.3,
    "DEC": 5.33
  },
  {
    "LONG": -4.95,
    "LAT": -18.49,
    "CIDADE": "Cachoeira Dourada",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.11,
    "FEB": 5.5,
    "MAR": 5.31,
    "APR": 5.64,
    "MAY": 5.42,
    "JUN": 5.31,
    "JUL": 5.45,
    "AUG": 6.18,
    "SEP": 5.48,
    "OCT": 5.4,
    "NOV": 5.24,
    "DEC": 5.21
  },
  {
    "LONG": -52.66,
    "LAT": -18.39,
    "CIDADE": "Chapadão do Céu",
    "UF": "GOIÁS",
    "anual": 5.27,
    "JAN": 5.05,
    "FEB": 5.32,
    "MAR": 5.21,
    "APR": 5.48,
    "MAY": 5.25,
    "JUN": 5.13,
    "JUL": 5.25,
    "AUG": 5.9,
    "SEP": 5.36,
    "OCT": 5.1,
    "NOV": 4.99,
    "DEC": 5.2
  },
  {
    "LONG": -50.46,
    "LAT": -18.45,
    "CIDADE": "Quirinópolis",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.12,
    "FEB": 5.42,
    "MAR": 5.37,
    "APR": 5.63,
    "MAY": 5.4,
    "JUN": 5.29,
    "JUL": 5.4,
    "AUG": 6.21,
    "SEP": 5.57,
    "OCT": 5.41,
    "NOV": 5.25,
    "DEC": 5.2
  },
  {
    "LONG": -5.01,
    "LAT": -1.84,
    "CIDADE": "Gouvelândia",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.22,
    "FEB": 5.44,
    "MAR": 5.42,
    "APR": 5.65,
    "MAY": 5.46,
    "JUN": 5.32,
    "JUL": 5.48,
    "AUG": 6.31,
    "SEP": 5.6,
    "OCT": 5.48,
    "NOV": 5.32,
    "DEC": 5.32
  },
  {
    "LONG": -49.22,
    "LAT": -18.41,
    "CIDADE": "Itumbiara",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.08,
    "FEB": 5.44,
    "MAR": 5.29,
    "APR": 5.62,
    "MAY": 5.48,
    "JUN": 5.39,
    "JUL": 5.5,
    "AUG": 6.32,
    "SEP": 5.58,
    "OCT": 5.43,
    "NOV": 5.16,
    "DEC": 5.19
  },
  {
    "LONG": -47.78,
    "LAT": -1.84,
    "CIDADE": "Três Ranchos",
    "UF": "GOIÁS",
    "anual": 5.57,
    "JAN": 5.18,
    "FEB": 5.76,
    "MAR": 5.43,
    "APR": 5.8,
    "MAY": 5.6,
    "JUN": 5.45,
    "JUL": 5.56,
    "AUG": 6.31,
    "SEP": 5.81,
    "OCT": 5.53,
    "NOV": 5.15,
    "DEC": 5.23
  },
  {
    "LONG": -51.96,
    "LAT": -18.31,
    "CIDADE": "Serranópolis",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 5.06,
    "FEB": 5.32,
    "MAR": 5.35,
    "APR": 5.57,
    "MAY": 5.33,
    "JUN": 5.18,
    "JUL": 5.34,
    "AUG": 6.01,
    "SEP": 5.46,
    "OCT": 5.21,
    "NOV": 5.15,
    "DEC": 5.24
  },
  {
    "LONG": -5.12,
    "LAT": -18.29,
    "CIDADE": "Aparecida do Rio Doce",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 5.05,
    "FEB": 5.43,
    "MAR": 5.38,
    "APR": 5.57,
    "MAY": 5.37,
    "JUN": 5.26,
    "JUL": 5.39,
    "AUG": 6.18,
    "SEP": 5.49,
    "OCT": 5.3,
    "NOV": 5.18,
    "DEC": 5.16
  },
  {
    "LONG": -4.82,
    "LAT": -1.83,
    "CIDADE": "Anhanguera",
    "UF": "GOIÁS",
    "anual": 5.53,
    "JAN": 5.13,
    "FEB": 5.66,
    "MAR": 5.33,
    "APR": 5.7,
    "MAY": 5.56,
    "JUN": 5.4,
    "JUL": 5.58,
    "AUG": 6.32,
    "SEP": 5.8,
    "OCT": 5.54,
    "NOV": 5.13,
    "DEC": 5.23
  },
  {
    "LONG": -48.15,
    "LAT": -18.26,
    "CIDADE": "Cumari",
    "UF": "GOIÁS",
    "anual": 5.51,
    "JAN": 5.1,
    "FEB": 5.6,
    "MAR": 5.29,
    "APR": 5.67,
    "MAY": 5.52,
    "JUN": 5.41,
    "JUL": 5.6,
    "AUG": 6.3,
    "SEP": 5.8,
    "OCT": 5.54,
    "NOV": 5.11,
    "DEC": 5.2
  },
  {
    "LONG": -49.74,
    "LAT": -18.22,
    "CIDADE": "Bom Jesus de Goiás",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 5.16,
    "FEB": 5.39,
    "MAR": 5.22,
    "APR": 5.56,
    "MAY": 5.4,
    "JUN": 5.28,
    "JUL": 5.51,
    "AUG": 6.22,
    "SEP": 5.57,
    "OCT": 5.36,
    "NOV": 5.12,
    "DEC": 5.15
  },
  {
    "LONG": -49.35,
    "LAT": -1.82,
    "CIDADE": "Panamá",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 5.05,
    "FEB": 5.43,
    "MAR": 5.21,
    "APR": 5.61,
    "MAY": 5.47,
    "JUN": 5.32,
    "JUL": 5.5,
    "AUG": 6.17,
    "SEP": 5.54,
    "OCT": 5.39,
    "NOV": 5.1,
    "DEC": 5.14
  },
  {
    "LONG": -47.94,
    "LAT": -18.17,
    "CIDADE": "Catalão",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.15,
    "FEB": 5.62,
    "MAR": 5.26,
    "APR": 5.64,
    "MAY": 5.51,
    "JUN": 5.42,
    "JUL": 5.65,
    "AUG": 6.39,
    "SEP": 5.8,
    "OCT": 5.51,
    "NOV": 5.09,
    "DEC": 5.15
  },
  {
    "LONG": -47.83,
    "LAT": -18.23,
    "CIDADE": "Ouvidor",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.14,
    "FEB": 5.64,
    "MAR": 5.27,
    "APR": 5.66,
    "MAY": 5.53,
    "JUN": 5.44,
    "JUL": 5.68,
    "AUG": 6.41,
    "SEP": 5.81,
    "OCT": 5.5,
    "NOV": 5.09,
    "DEC": 5.13
  },
  {
    "LONG": -47.56,
    "LAT": -18.15,
    "CIDADE": "Davinópolis",
    "UF": "GOIÁS",
    "anual": 5.58,
    "JAN": 5.17,
    "FEB": 5.76,
    "MAR": 5.31,
    "APR": 5.73,
    "MAY": 5.59,
    "JUN": 5.49,
    "JUL": 5.78,
    "AUG": 6.45,
    "SEP": 5.92,
    "OCT": 5.55,
    "NOV": 5.08,
    "DEC": 5.19
  },
  {
    "LONG": -50.21,
    "LAT": -18.09,
    "CIDADE": "Castelândia",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.24,
    "FEB": 5.52,
    "MAR": 5.42,
    "APR": 5.67,
    "MAY": 5.5,
    "JUN": 5.32,
    "JUL": 5.47,
    "AUG": 6.29,
    "SEP": 5.6,
    "OCT": 5.42,
    "NOV": 5.25,
    "DEC": 5.26
  },
  {
    "LONG": -49.04,
    "LAT": -18.14,
    "CIDADE": "Buriti Alegre",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.03,
    "FEB": 5.52,
    "MAR": 5.24,
    "APR": 5.66,
    "MAY": 5.5,
    "JUN": 5.39,
    "JUL": 5.51,
    "AUG": 6.23,
    "SEP": 5.56,
    "OCT": 5.44,
    "NOV": 5.1,
    "DEC": 5.1
  },
  {
    "LONG": -48.76,
    "LAT": -18.08,
    "CIDADE": "Água Limpa",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.11,
    "FEB": 5.56,
    "MAR": 5.36,
    "APR": 5.7,
    "MAY": 5.55,
    "JUN": 5.39,
    "JUL": 5.52,
    "AUG": 6.12,
    "SEP": 5.64,
    "OCT": 5.46,
    "NOV": 5.15,
    "DEC": 5.18
  },
  {
    "LONG": -4.86,
    "LAT": -1.81,
    "CIDADE": "Corumbaíba",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.37,
    "APR": 5.71,
    "MAY": 5.57,
    "JUN": 5.4,
    "JUL": 5.56,
    "AUG": 6.25,
    "SEP": 5.76,
    "OCT": 5.48,
    "NOV": 5.17,
    "DEC": 5.17
  },
  {
    "LONG": -48.26,
    "LAT": -18.06,
    "CIDADE": "Nova Aurora",
    "UF": "GOIÁS",
    "anual": 5.53,
    "JAN": 5.13,
    "FEB": 5.62,
    "MAR": 5.33,
    "APR": 5.73,
    "MAY": 5.58,
    "JUN": 5.44,
    "JUL": 5.63,
    "AUG": 6.37,
    "SEP": 5.78,
    "OCT": 5.5,
    "NOV": 5.12,
    "DEC": 5.16
  },
  {
    "LONG": -48.09,
    "LAT": -18.14,
    "CIDADE": "Goiandira",
    "UF": "GOIÁS",
    "anual": 5.54,
    "JAN": 5.18,
    "FEB": 5.65,
    "MAR": 5.31,
    "APR": 5.68,
    "MAY": 5.54,
    "JUN": 5.43,
    "JUL": 5.65,
    "AUG": 6.37,
    "SEP": 5.84,
    "OCT": 5.55,
    "NOV": 5.13,
    "DEC": 5.18
  },
  {
    "LONG": -50.34,
    "LAT": -17.97,
    "CIDADE": "Maurilândia",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.22,
    "FEB": 5.53,
    "MAR": 5.43,
    "APR": 5.63,
    "MAY": 5.52,
    "JUN": 5.32,
    "JUL": 5.52,
    "AUG": 6.34,
    "SEP": 5.61,
    "OCT": 5.42,
    "NOV": 5.23,
    "DEC": 5.23
  },
  {
    "LONG": -49.37,
    "LAT": -1.8,
    "CIDADE": "Goiatuba",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.08,
    "FEB": 5.44,
    "MAR": 5.25,
    "APR": 5.56,
    "MAY": 5.45,
    "JUN": 5.32,
    "JUL": 5.51,
    "AUG": 6.2,
    "SEP": 5.61,
    "OCT": 5.41,
    "NOV": 5.13,
    "DEC": 5.14
  },
  {
    "LONG": -48.64,
    "LAT": -17.98,
    "CIDADE": "Marzagão",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.13,
    "FEB": 5.57,
    "MAR": 5.33,
    "APR": 5.66,
    "MAY": 5.56,
    "JUN": 5.36,
    "JUL": 5.55,
    "AUG": 6.21,
    "SEP": 5.75,
    "OCT": 5.47,
    "NOV": 5.17,
    "DEC": 5.13
  },
  {
    "LONG": -51.72,
    "LAT": -17.88,
    "CIDADE": "Jataí",
    "UF": "GOIÁS",
    "anual": 5.36,
    "JAN": 5.08,
    "FEB": 5.26,
    "MAR": 5.28,
    "APR": 5.57,
    "MAY": 5.42,
    "JUN": 5.27,
    "JUL": 5.35,
    "AUG": 6.1,
    "SEP": 5.46,
    "OCT": 5.2,
    "NOV": 5.14,
    "DEC": 5.21
  },
  {
    "LONG": -50.3,
    "LAT": -17.85,
    "CIDADE": "Turvelândia",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.24,
    "FEB": 5.54,
    "MAR": 5.4,
    "APR": 5.7,
    "MAY": 5.56,
    "JUN": 5.35,
    "JUL": 5.56,
    "AUG": 6.3,
    "SEP": 5.61,
    "OCT": 5.4,
    "NOV": 5.17,
    "DEC": 5.2
  },
  {
    "LONG": -50.92,
    "LAT": -17.79,
    "CIDADE": "Rio Verde",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 5.12,
    "FEB": 5.34,
    "MAR": 5.26,
    "APR": 5.57,
    "MAY": 5.46,
    "JUN": 5.33,
    "JUL": 5.43,
    "AUG": 6.27,
    "SEP": 5.57,
    "OCT": 5.28,
    "NOV": 4.97,
    "DEC": 5.13
  },
  {
    "LONG": -50.6,
    "LAT": -1.78,
    "CIDADE": "Santa Helena de Goiás",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.16,
    "FEB": 5.5,
    "MAR": 5.39,
    "APR": 5.61,
    "MAY": 5.53,
    "JUN": 5.31,
    "JUL": 5.49,
    "AUG": 6.29,
    "SEP": 5.57,
    "OCT": 5.37,
    "NOV": 5.13,
    "DEC": 5.2
  },
  {
    "LONG": -50.17,
    "LAT": -17.81,
    "CIDADE": "Porteirão",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.23,
    "FEB": 5.54,
    "MAR": 5.44,
    "APR": 5.66,
    "MAY": 5.57,
    "JUN": 5.36,
    "JUL": 5.53,
    "AUG": 6.31,
    "SEP": 5.59,
    "OCT": 5.44,
    "NOV": 5.19,
    "DEC": 5.17
  },
  {
    "LONG": -49.63,
    "LAT": -17.81,
    "CIDADE": "Joviânia",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.11,
    "FEB": 5.44,
    "MAR": 5.3,
    "APR": 5.63,
    "MAY": 5.5,
    "JUN": 5.29,
    "JUL": 5.5,
    "AUG": 6.27,
    "SEP": 5.59,
    "OCT": 5.44,
    "NOV": 5.15,
    "DEC": 5.16
  },
  {
    "LONG": -48.76,
    "LAT": -17.77,
    "CIDADE": "Rio Quente",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.11,
    "FEB": 5.56,
    "MAR": 5.38,
    "APR": 5.69,
    "MAY": 5.6,
    "JUN": 5.45,
    "JUL": 5.64,
    "AUG": 6.33,
    "SEP": 5.73,
    "OCT": 5.49,
    "NOV": 5.17,
    "DEC": 5.13
  },
  {
    "LONG": -49.81,
    "LAT": -17.73,
    "CIDADE": "Vicentinópolis",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.21,
    "FEB": 5.61,
    "MAR": 5.42,
    "APR": 5.65,
    "MAY": 5.56,
    "JUN": 5.28,
    "JUL": 5.53,
    "AUG": 6.29,
    "SEP": 5.6,
    "OCT": 5.44,
    "NOV": 5.18,
    "DEC": 5.18
  },
  {
    "LONG": -49.48,
    "LAT": -17.73,
    "CIDADE": "Aloândia",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.18,
    "FEB": 5.53,
    "MAR": 5.43,
    "APR": 5.67,
    "MAY": 5.53,
    "JUN": 5.36,
    "JUL": 5.52,
    "AUG": 6.2,
    "SEP": 5.62,
    "OCT": 5.46,
    "NOV": 5.17,
    "DEC": 5.15
  },
  {
    "LONG": -49.11,
    "LAT": -17.73,
    "CIDADE": "Morrinhos",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.13,
    "FEB": 5.53,
    "MAR": 5.41,
    "APR": 5.7,
    "MAY": 5.57,
    "JUN": 5.35,
    "JUL": 5.54,
    "AUG": 6.26,
    "SEP": 5.68,
    "OCT": 5.44,
    "NOV": 5.13,
    "DEC": 5.15
  },
  {
    "LONG": -4.86,
    "LAT": -17.74,
    "CIDADE": "Caldas Novas",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.14,
    "FEB": 5.6,
    "MAR": 5.32,
    "APR": 5.69,
    "MAY": 5.54,
    "JUN": 5.38,
    "JUL": 5.58,
    "AUG": 6.26,
    "SEP": 5.69,
    "OCT": 5.46,
    "NOV": 5.1,
    "DEC": 5.16
  },
  {
    "LONG": -48.16,
    "LAT": -1.77,
    "CIDADE": "Ipameri",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.21,
    "FEB": 5.54,
    "MAR": 5.24,
    "APR": 5.64,
    "MAY": 5.53,
    "JUN": 5.4,
    "JUL": 5.63,
    "AUG": 6.36,
    "SEP": 5.66,
    "OCT": 5.46,
    "NOV": 5.02,
    "DEC": 5.12
  },
  {
    "LONG": -52.55,
    "LAT": -17.57,
    "CIDADE": "Mineiros",
    "UF": "GOIÁS",
    "anual": 5.37,
    "JAN": 5.11,
    "FEB": 5.39,
    "MAR": 5.32,
    "APR": 5.61,
    "MAY": 5.44,
    "JUN": 5.24,
    "JUL": 5.4,
    "AUG": 6.05,
    "SEP": 5.5,
    "OCT": 5.09,
    "NOV": 5.16,
    "DEC": 5.17
  },
  {
    "LONG": -5.06,
    "LAT": -17.56,
    "CIDADE": "Santo Antônio da Barra",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.12,
    "FEB": 5.47,
    "MAR": 5.35,
    "APR": 5.64,
    "MAY": 5.54,
    "JUN": 5.35,
    "JUL": 5.51,
    "AUG": 6.27,
    "SEP": 5.57,
    "OCT": 5.34,
    "NOV": 5.13,
    "DEC": 5.15
  },
  {
    "LONG": -47.78,
    "LAT": -17.64,
    "CIDADE": "Campo Alegre de Goiás",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.11,
    "FEB": 5.65,
    "MAR": 5.16,
    "APR": 5.6,
    "MAY": 5.48,
    "JUN": 5.47,
    "JUL": 5.68,
    "AUG": 6.34,
    "SEP": 5.75,
    "OCT": 5.5,
    "NOV": 4.98,
    "DEC": 5.08
  },
  {
    "LONG": -52.07,
    "LAT": -17.53,
    "CIDADE": "Perolândia",
    "UF": "GOIÁS",
    "anual": 5.36,
    "JAN": 5.04,
    "FEB": 5.31,
    "MAR": 5.24,
    "APR": 5.56,
    "MAY": 5.47,
    "JUN": 5.31,
    "JUL": 5.35,
    "AUG": 6.14,
    "SEP": 5.51,
    "OCT": 5.15,
    "NOV": 5.13,
    "DEC": 5.07
  },
  {
    "LONG": -49.45,
    "LAT": -17.52,
    "CIDADE": "Pontalina",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.11,
    "FEB": 5.46,
    "MAR": 5.46,
    "APR": 5.68,
    "MAY": 5.55,
    "JUN": 5.25,
    "JUL": 5.46,
    "AUG": 6.2,
    "SEP": 5.62,
    "OCT": 5.46,
    "NOV": 5.11,
    "DEC": 5.12
  },
  {
    "LONG": -48.2,
    "LAT": -17.47,
    "CIDADE": "Urutaí",
    "UF": "GOIÁS",
    "anual": 5.55,
    "JAN": 5.23,
    "FEB": 5.61,
    "MAR": 5.41,
    "APR": 5.78,
    "MAY": 5.62,
    "JUN": 5.45,
    "JUL": 5.66,
    "AUG": 6.34,
    "SEP": 5.68,
    "OCT": 5.54,
    "NOV": 5.1,
    "DEC": 5.2
  },
  {
    "LONG": -52.68,
    "LAT": -17.36,
    "CIDADE": "Portelândia",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 5.07,
    "FEB": 5.32,
    "MAR": 5.27,
    "APR": 5.56,
    "MAY": 5.46,
    "JUN": 5.26,
    "JUL": 5.34,
    "AUG": 5.96,
    "SEP": 5.54,
    "OCT": 5.15,
    "NOV": 5.15,
    "DEC": 5.15
  },
  {
    "LONG": -51.17,
    "LAT": -17.44,
    "CIDADE": "Montividiu",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 5.1,
    "FEB": 5.27,
    "MAR": 5.17,
    "APR": 5.56,
    "MAY": 5.46,
    "JUN": 5.34,
    "JUL": 5.39,
    "AUG": 6.14,
    "SEP": 5.43,
    "OCT": 5.21,
    "NOV": 5,
    "DEC": 5.11
  },
  {
    "LONG": -50.38,
    "LAT": -17.4,
    "CIDADE": "Acreúna",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.17,
    "FEB": 5.52,
    "MAR": 5.35,
    "APR": 5.6,
    "MAY": 5.58,
    "JUN": 5.28,
    "JUL": 5.58,
    "AUG": 6.27,
    "SEP": 5.61,
    "OCT": 5.33,
    "NOV": 5.12,
    "DEC": 5.14
  },
  {
    "LONG": -49.66,
    "LAT": -17.42,
    "CIDADE": "Edealina",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.15,
    "FEB": 5.55,
    "MAR": 5.47,
    "APR": 5.7,
    "MAY": 5.53,
    "JUN": 5.34,
    "JUL": 5.51,
    "AUG": 6.24,
    "SEP": 5.67,
    "OCT": 5.49,
    "NOV": 5.17,
    "DEC": 5.13
  },
  {
    "LONG": -53.2,
    "LAT": -17.33,
    "CIDADE": "Santa Rita do Araguaia",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 5.01,
    "FEB": 5.34,
    "MAR": 5.24,
    "APR": 5.59,
    "MAY": 5.41,
    "JUN": 5.32,
    "JUL": 5.4,
    "AUG": 6.11,
    "SEP": 5.56,
    "OCT": 5.11,
    "NOV": 5.02,
    "DEC": 5.06
  },
  {
    "LONG": -49.93,
    "LAT": -17.34,
    "CIDADE": "Edéia",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.19,
    "FEB": 5.49,
    "MAR": 5.49,
    "APR": 5.65,
    "MAY": 5.51,
    "JUN": 5.32,
    "JUL": 5.51,
    "AUG": 6.2,
    "SEP": 5.64,
    "OCT": 5.43,
    "NOV": 5.16,
    "DEC": 5.12
  },
  {
    "LONG": -49.49,
    "LAT": -1.73,
    "CIDADE": "Mairipotaba",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.14,
    "FEB": 5.47,
    "MAR": 5.4,
    "APR": 5.67,
    "MAY": 5.53,
    "JUN": 5.37,
    "JUL": 5.58,
    "AUG": 6.29,
    "SEP": 5.67,
    "OCT": 5.43,
    "NOV": 5.08,
    "DEC": 5.08
  },
  {
    "LONG": -49.38,
    "LAT": -17.29,
    "CIDADE": "Cromínia",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.14,
    "FEB": 5.46,
    "MAR": 5.37,
    "APR": 5.66,
    "MAY": 5.55,
    "JUN": 5.36,
    "JUL": 5.57,
    "AUG": 6.3,
    "SEP": 5.68,
    "OCT": 5.43,
    "NOV": 5.07,
    "DEC": 5.1
  },
  {
    "LONG": -49.25,
    "LAT": -17.25,
    "CIDADE": "Professor Jamil",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.12,
    "FEB": 5.47,
    "MAR": 5.39,
    "APR": 5.67,
    "MAY": 5.59,
    "JUN": 5.33,
    "JUL": 5.56,
    "AUG": 6.24,
    "SEP": 5.68,
    "OCT": 5.44,
    "NOV": 5.06,
    "DEC": 5.1
  },
  {
    "LONG": -4.9,
    "LAT": -17.3,
    "CIDADE": "Piracanjuba",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.13,
    "FEB": 5.49,
    "MAR": 5.36,
    "APR": 5.67,
    "MAY": 5.58,
    "JUN": 5.4,
    "JUL": 5.53,
    "AUG": 6.25,
    "SEP": 5.69,
    "OCT": 5.48,
    "NOV": 5.07,
    "DEC": 5.13
  },
  {
    "LONG": -48.43,
    "LAT": -17.33,
    "CIDADE": "Palmelo",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.18,
    "FEB": 5.53,
    "MAR": 5.36,
    "APR": 5.73,
    "MAY": 5.57,
    "JUN": 5.49,
    "JUL": 5.69,
    "AUG": 6.38,
    "SEP": 5.66,
    "OCT": 5.55,
    "NOV": 5,
    "DEC": 5.12
  },
  {
    "LONG": -48.48,
    "LAT": -1.73,
    "CIDADE": "Santa Cruz de Goiás",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.18,
    "FEB": 5.53,
    "MAR": 5.36,
    "APR": 5.73,
    "MAY": 5.57,
    "JUN": 5.49,
    "JUL": 5.69,
    "AUG": 6.38,
    "SEP": 5.66,
    "OCT": 5.55,
    "NOV": 5,
    "DEC": 5.12
  },
  {
    "LONG": -48.28,
    "LAT": -17.3,
    "CIDADE": "Pires do Rio",
    "UF": "GOIÁS",
    "anual": 5.55,
    "JAN": 5.2,
    "FEB": 5.63,
    "MAR": 5.39,
    "APR": 5.81,
    "MAY": 5.58,
    "JUN": 5.48,
    "JUL": 5.64,
    "AUG": 6.39,
    "SEP": 5.67,
    "OCT": 5.55,
    "NOV": 5.05,
    "DEC": 5.16
  },
  {
    "LONG": -48.7,
    "LAT": -17.2,
    "CIDADE": "Cristianópolis",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.14,
    "FEB": 5.49,
    "MAR": 5.28,
    "APR": 5.67,
    "MAY": 5.53,
    "JUN": 5.5,
    "JUL": 5.68,
    "AUG": 6.34,
    "SEP": 5.77,
    "OCT": 5.5,
    "NOV": 5.01,
    "DEC": 5.05
  },
  {
    "LONG": -49.99,
    "LAT": -17.14,
    "CIDADE": "Indiara",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.16,
    "FEB": 5.55,
    "MAR": 5.47,
    "APR": 5.65,
    "MAY": 5.56,
    "JUN": 5.32,
    "JUL": 5.52,
    "AUG": 6.21,
    "SEP": 5.68,
    "OCT": 5.43,
    "NOV": 5.1,
    "DEC": 5.04
  },
  {
    "LONG": -48.66,
    "LAT": -17.06,
    "CIDADE": "São Miguel do Passa Quatro",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.14,
    "FEB": 5.53,
    "MAR": 5.27,
    "APR": 5.64,
    "MAY": 5.59,
    "JUN": 5.5,
    "JUL": 5.68,
    "AUG": 6.39,
    "SEP": 5.78,
    "OCT": 5.5,
    "NOV": 4.9,
    "DEC": 5.07
  },
  {
    "LONG": -51.81,
    "LAT": -16.95,
    "CIDADE": "Caiapônia",
    "UF": "GOIÁS",
    "anual": 5.36,
    "JAN": 5.03,
    "FEB": 5.32,
    "MAR": 5.27,
    "APR": 5.6,
    "MAY": 5.54,
    "JUN": 5.27,
    "JUL": 5.43,
    "AUG": 6.13,
    "SEP": 5.54,
    "OCT": 5.18,
    "NOV": 4.97,
    "DEC": 5.05
  },
  {
    "LONG": -50.15,
    "LAT": -17.05,
    "CIDADE": "Jandaia",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.11,
    "FEB": 5.56,
    "MAR": 5.39,
    "APR": 5.61,
    "MAY": 5.57,
    "JUN": 5.34,
    "JUL": 5.51,
    "AUG": 6.12,
    "SEP": 5.66,
    "OCT": 5.43,
    "NOV": 5.11,
    "DEC": 5.05
  },
  {
    "LONG": -49.78,
    "LAT": -16.97,
    "CIDADE": "Cezarina",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.12,
    "FEB": 5.48,
    "MAR": 5.42,
    "APR": 5.7,
    "MAY": 5.58,
    "JUN": 5.37,
    "JUL": 5.6,
    "AUG": 6.29,
    "SEP": 5.66,
    "OCT": 5.43,
    "NOV": 5.05,
    "DEC": 5.05
  },
  {
    "LONG": -49.63,
    "LAT": -1.7,
    "CIDADE": "Varjão",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.08,
    "FEB": 5.42,
    "MAR": 5.43,
    "APR": 5.69,
    "MAY": 5.57,
    "JUN": 5.41,
    "JUL": 5.59,
    "AUG": 6.3,
    "SEP": 5.67,
    "OCT": 5.4,
    "NOV": 5.06,
    "DEC": 5.06
  },
  {
    "LONG": -49.23,
    "LAT": -16.97,
    "CIDADE": "Hidrolândia",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.05,
    "FEB": 5.4,
    "MAR": 5.28,
    "APR": 5.57,
    "MAY": 5.52,
    "JUN": 5.43,
    "JUL": 5.59,
    "AUG": 6.29,
    "SEP": 5.7,
    "OCT": 5.43,
    "NOV": 5,
    "DEC": 5.06
  },
  {
    "LONG": -48.95,
    "LAT": -16.97,
    "CIDADE": "Bela Vista de Goiás",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.09,
    "FEB": 5.47,
    "MAR": 5.33,
    "APR": 5.61,
    "MAY": 5.57,
    "JUN": 5.5,
    "JUL": 5.6,
    "AUG": 6.29,
    "SEP": 5.79,
    "OCT": 5.48,
    "NOV": 5.03,
    "DEC": 5.06
  },
  {
    "LONG": -4.83,
    "LAT": -17.03,
    "CIDADE": "Orizona",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.17,
    "FEB": 5.56,
    "MAR": 5.21,
    "APR": 5.55,
    "MAY": 5.55,
    "JUN": 5.5,
    "JUL": 5.7,
    "AUG": 6.37,
    "SEP": 5.86,
    "OCT": 5.51,
    "NOV": 4.91,
    "DEC": 5.13
  },
  {
    "LONG": -50.45,
    "LAT": -16.95,
    "CIDADE": "Paraúna",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.04,
    "FEB": 5.37,
    "MAR": 5.34,
    "APR": 5.68,
    "MAY": 5.56,
    "JUN": 5.4,
    "JUL": 5.6,
    "AUG": 6.13,
    "SEP": 5.63,
    "OCT": 5.39,
    "NOV": 5.09,
    "DEC": 5.06
  },
  {
    "LONG": -4.94,
    "LAT": -16.91,
    "CIDADE": "Aragoiânia",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.06,
    "FEB": 5.34,
    "MAR": 5.37,
    "APR": 5.61,
    "MAY": 5.56,
    "JUN": 5.41,
    "JUL": 5.57,
    "AUG": 6.2,
    "SEP": 5.69,
    "OCT": 5.41,
    "NOV": 5.03,
    "DEC": 4.99
  },
  {
    "LONG": -50.41,
    "LAT": -1.68,
    "CIDADE": "São João da Paraúna",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.11,
    "FEB": 5.45,
    "MAR": 5.42,
    "APR": 5.72,
    "MAY": 5.6,
    "JUN": 5.43,
    "JUL": 5.56,
    "AUG": 6.24,
    "SEP": 5.67,
    "OCT": 5.45,
    "NOV": 5.16,
    "DEC": 5.08
  },
  {
    "LONG": -50.17,
    "LAT": -16.79,
    "CIDADE": "Palminópolis",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.14,
    "FEB": 5.49,
    "MAR": 5.36,
    "APR": 5.63,
    "MAY": 5.56,
    "JUN": 5.38,
    "JUL": 5.56,
    "AUG": 6.18,
    "SEP": 5.71,
    "OCT": 5.41,
    "NOV": 5.13,
    "DEC": 5.03
  },
  {
    "LONG": -49.92,
    "LAT": -16.8,
    "CIDADE": "Palmeiras de Goiás",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.11,
    "FEB": 5.49,
    "MAR": 5.37,
    "APR": 5.7,
    "MAY": 5.57,
    "JUN": 5.39,
    "JUL": 5.57,
    "AUG": 6.27,
    "SEP": 5.7,
    "OCT": 5.41,
    "NOV": 5,
    "DEC": 5.03
  },
  {
    "LONG": -49.69,
    "LAT": -16.76,
    "CIDADE": "Campestre de Goiás",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.07,
    "FEB": 5.4,
    "MAR": 5.39,
    "APR": 5.67,
    "MAY": 5.56,
    "JUN": 5.41,
    "JUL": 5.55,
    "AUG": 6.22,
    "SEP": 5.68,
    "OCT": 5.41,
    "NOV": 5.02,
    "DEC": 5.03
  },
  {
    "LONG": -49.53,
    "LAT": -16.83,
    "CIDADE": "Guapó",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.11,
    "FEB": 5.41,
    "MAR": 5.4,
    "APR": 5.67,
    "MAY": 5.58,
    "JUN": 5.44,
    "JUL": 5.59,
    "AUG": 6.22,
    "SEP": 5.72,
    "OCT": 5.44,
    "NOV": 5.07,
    "DEC": 5.06
  },
  {
    "LONG": -49.44,
    "LAT": -16.76,
    "CIDADE": "Abadia de Goiás",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.05,
    "FEB": 5.34,
    "MAR": 5.33,
    "APR": 5.6,
    "MAY": 5.6,
    "JUN": 5.47,
    "JUL": 5.55,
    "AUG": 6.26,
    "SEP": 5.74,
    "OCT": 5.41,
    "NOV": 5.03,
    "DEC": 4.99
  },
  {
    "LONG": -49.25,
    "LAT": -16.82,
    "CIDADE": "Aparecida de Goiânia",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5,
    "FEB": 5.33,
    "MAR": 5.26,
    "APR": 5.54,
    "MAY": 5.51,
    "JUN": 5.42,
    "JUL": 5.6,
    "AUG": 6.31,
    "SEP": 5.81,
    "OCT": 5.47,
    "NOV": 5.03,
    "DEC": 5.04
  },
  {
    "LONG": -47.61,
    "LAT": -16.77,
    "CIDADE": "Cristalina",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.17,
    "FEB": 5.59,
    "MAR": 5.22,
    "APR": 5.54,
    "MAY": 5.49,
    "JUN": 5.49,
    "JUL": 5.77,
    "AUG": 6.43,
    "SEP": 5.83,
    "OCT": 5.51,
    "NOV": 4.83,
    "DEC": 5.05
  },
  {
    "LONG": -52.31,
    "LAT": -16.72,
    "CIDADE": "Doverlândia",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.04,
    "FEB": 5.4,
    "MAR": 5.39,
    "APR": 5.71,
    "MAY": 5.56,
    "JUN": 5.35,
    "JUL": 5.46,
    "AUG": 6.04,
    "SEP": 5.58,
    "OCT": 5.19,
    "NOV": 5.15,
    "DEC": 5.14
  },
  {
    "LONG": -51.53,
    "LAT": -16.74,
    "CIDADE": "Palestina de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.06,
    "FEB": 5.38,
    "MAR": 5.34,
    "APR": 5.7,
    "MAY": 5.58,
    "JUN": 5.36,
    "JUL": 5.44,
    "AUG": 6.07,
    "SEP": 5.58,
    "OCT": 5.37,
    "NOV": 5.1,
    "DEC": 5.03
  },
  {
    "LONG": -50.64,
    "LAT": -16.67,
    "CIDADE": "Cachoeira de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.03,
    "FEB": 5.34,
    "MAR": 5.31,
    "APR": 5.66,
    "MAY": 5.56,
    "JUN": 5.41,
    "JUL": 5.51,
    "AUG": 6.09,
    "SEP": 5.59,
    "OCT": 5.38,
    "NOV": 5.08,
    "DEC": 5.04
  },
  {
    "LONG": -50.46,
    "LAT": -16.68,
    "CIDADE": "Aurilândia",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.18,
    "FEB": 5.47,
    "MAR": 5.49,
    "APR": 5.73,
    "MAY": 5.62,
    "JUN": 5.49,
    "JUL": 5.57,
    "AUG": 6.21,
    "SEP": 5.66,
    "OCT": 5.46,
    "NOV": 5.22,
    "DEC": 5.1
  },
  {
    "LONG": -49.49,
    "LAT": -16.65,
    "CIDADE": "Trindade",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.05,
    "FEB": 5.35,
    "MAR": 5.31,
    "APR": 5.59,
    "MAY": 5.59,
    "JUN": 5.48,
    "JUL": 5.58,
    "AUG": 6.31,
    "SEP": 5.76,
    "OCT": 5.41,
    "NOV": 5.01,
    "DEC": 5.01
  },
  {
    "LONG": -49.27,
    "LAT": -16.69,
    "CIDADE": "Goiânia",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5,
    "FEB": 5.29,
    "MAR": 5.21,
    "APR": 5.5,
    "MAY": 5.52,
    "JUN": 5.45,
    "JUL": 5.63,
    "AUG": 6.36,
    "SEP": 5.81,
    "OCT": 5.46,
    "NOV": 5.01,
    "DEC": 5
  },
  {
    "LONG": -49.09,
    "LAT": -16.71,
    "CIDADE": "Senador Canedo",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.04,
    "FEB": 5.42,
    "MAR": 5.28,
    "APR": 5.61,
    "MAY": 5.62,
    "JUN": 5.52,
    "JUL": 5.64,
    "AUG": 6.35,
    "SEP": 5.8,
    "OCT": 5.47,
    "NOV": 4.99,
    "DEC": 4.98
  },
  {
    "LONG": -49,
    "LAT": -16.72,
    "CIDADE": "Caldazinha",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.04,
    "FEB": 5.42,
    "MAR": 5.28,
    "APR": 5.61,
    "MAY": 5.62,
    "JUN": 5.52,
    "JUL": 5.64,
    "AUG": 6.35,
    "SEP": 5.8,
    "OCT": 5.47,
    "NOV": 4.99,
    "DEC": 4.98
  },
  {
    "LONG": -48.61,
    "LAT": -16.66,
    "CIDADE": "Silvânia",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.05,
    "FEB": 5.51,
    "MAR": 5.19,
    "APR": 5.55,
    "MAY": 5.54,
    "JUN": 5.47,
    "JUL": 5.65,
    "AUG": 6.41,
    "SEP": 5.75,
    "OCT": 5.46,
    "NOV": 4.84,
    "DEC": 5
  },
  {
    "LONG": -48.51,
    "LAT": -16.75,
    "CIDADE": "Vianópolis",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.26,
    "APR": 5.61,
    "MAY": 5.56,
    "JUN": 5.51,
    "JUL": 5.71,
    "AUG": 6.43,
    "SEP": 5.83,
    "OCT": 5.53,
    "NOV": 4.92,
    "DEC": 5.04
  },
  {
    "LONG": -51.09,
    "LAT": "#VALOR!",
    "CIDADE": "Amorinópolis",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.08,
    "FEB": 5.38,
    "MAR": 5.4,
    "APR": 5.77,
    "MAY": 5.66,
    "JUN": 5.34,
    "JUL": 5.52,
    "AUG": 6.1,
    "SEP": 5.61,
    "OCT": 5.42,
    "NOV": 5.06,
    "DEC": 5.05
  },
  {
    "LONG": -50.79,
    "LAT": -16.61,
    "CIDADE": "Ivolândia",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.11,
    "FEB": 5.37,
    "MAR": 5.38,
    "APR": 5.69,
    "MAY": 5.63,
    "JUN": 5.37,
    "JUL": 5.61,
    "AUG": 6.16,
    "SEP": 5.62,
    "OCT": 5.41,
    "NOV": 5.08,
    "DEC": 5.05
  },
  {
    "LONG": -50.3,
    "LAT": -16.58,
    "CIDADE": "Firminópolis",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.17,
    "FEB": 5.39,
    "MAR": 5.37,
    "APR": 5.71,
    "MAY": 5.63,
    "JUN": 5.42,
    "JUL": 5.58,
    "AUG": 6.19,
    "SEP": 5.68,
    "OCT": 5.42,
    "NOV": 5.2,
    "DEC": 5.05
  },
  {
    "LONG": -50.14,
    "LAT": -16.61,
    "CIDADE": "Turvânia",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.09,
    "FEB": 5.42,
    "MAR": 5.37,
    "APR": 5.66,
    "MAY": 5.56,
    "JUN": 5.43,
    "JUL": 5.64,
    "AUG": 6.33,
    "SEP": 5.69,
    "OCT": 5.43,
    "NOV": 5.09,
    "DEC": 5.05
  },
  {
    "LONG": -49.88,
    "LAT": -16.58,
    "CIDADE": "Nazário",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.05,
    "FEB": 5.37,
    "MAR": 5.31,
    "APR": 5.69,
    "MAY": 5.56,
    "JUN": 5.48,
    "JUL": 5.62,
    "AUG": 6.38,
    "SEP": 5.72,
    "OCT": 5.42,
    "NOV": 4.97,
    "DEC": 5.02
  },
  {
    "LONG": -49.7,
    "LAT": -16.58,
    "CIDADE": "Santa Bárbara de Goiás",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.03,
    "FEB": 5.35,
    "MAR": 5.32,
    "APR": 5.7,
    "MAY": 5.58,
    "JUN": 5.45,
    "JUL": 5.61,
    "AUG": 6.35,
    "SEP": 5.69,
    "OCT": 5.39,
    "NOV": 4.96,
    "DEC": 5
  },
  {
    "LONG": -4.9,
    "LAT": -16.62,
    "CIDADE": "Bonfinópolis",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.01,
    "FEB": 5.39,
    "MAR": 5.25,
    "APR": 5.54,
    "MAY": 5.62,
    "JUN": 5.47,
    "JUL": 5.7,
    "AUG": 6.36,
    "SEP": 5.75,
    "OCT": 5.43,
    "NOV": 4.86,
    "DEC": 4.88
  },
  {
    "LONG": -48.74,
    "LAT": -16.62,
    "CIDADE": "Leopoldo de Bulhões",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.04,
    "FEB": 5.46,
    "MAR": 5.22,
    "APR": 5.56,
    "MAY": 5.55,
    "JUN": 5.51,
    "JUL": 5.68,
    "AUG": 6.41,
    "SEP": 5.77,
    "OCT": 5.43,
    "NOV": 4.81,
    "DEC": 4.92
  },
  {
    "LONG": -50.74,
    "LAT": -16.54,
    "CIDADE": "Moiporá",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.11,
    "FEB": 5.36,
    "MAR": 5.35,
    "APR": 5.72,
    "MAY": 5.63,
    "JUN": 5.38,
    "JUL": 5.57,
    "AUG": 6.11,
    "SEP": 5.61,
    "OCT": 5.41,
    "NOV": 5.1,
    "DEC": 5.03
  },
  {
    "LONG": -5.04,
    "LAT": -16.52,
    "CIDADE": "São Luís de Montes Belos",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.15,
    "FEB": 5.4,
    "MAR": 5.37,
    "APR": 5.7,
    "MAY": 5.63,
    "JUN": 5.41,
    "JUL": 5.6,
    "AUG": 6.21,
    "SEP": 5.67,
    "OCT": 5.43,
    "NOV": 5.19,
    "DEC": 5.03
  },
  {
    "LONG": -49.96,
    "LAT": -1.65,
    "CIDADE": "Anicuns",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.01,
    "FEB": 5.42,
    "MAR": 5.3,
    "APR": 5.64,
    "MAY": 5.58,
    "JUN": 5.51,
    "JUL": 5.67,
    "AUG": 6.38,
    "SEP": 5.7,
    "OCT": 5.4,
    "NOV": 4.97,
    "DEC": 5.01
  },
  {
    "LONG": -49.76,
    "LAT": -16.47,
    "CIDADE": "Avelinópolis",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.05,
    "FEB": 5.35,
    "MAR": 5.29,
    "APR": 5.68,
    "MAY": 5.62,
    "JUN": 5.45,
    "JUL": 5.63,
    "AUG": 6.41,
    "SEP": 5.67,
    "OCT": 5.43,
    "NOV": 4.96,
    "DEC": 4.98
  },
  {
    "LONG": -49.42,
    "LAT": -16.51,
    "CIDADE": "Goianira",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.03,
    "FEB": 5.34,
    "MAR": 5.28,
    "APR": 5.6,
    "MAY": 5.6,
    "JUN": 5.49,
    "JUL": 5.66,
    "AUG": 6.44,
    "SEP": 5.78,
    "OCT": 5.4,
    "NOV": 4.91,
    "DEC": 4.94
  },
  {
    "LONG": -49.31,
    "LAT": -16.49,
    "CIDADE": "Santo Antônio de Goiás",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.04,
    "FEB": 5.35,
    "MAR": 5.27,
    "APR": 5.58,
    "MAY": 5.63,
    "JUN": 5.49,
    "JUL": 5.68,
    "AUG": 6.41,
    "SEP": 5.76,
    "OCT": 5.42,
    "NOV": 4.92,
    "DEC": 4.94
  },
  {
    "LONG": -49.02,
    "LAT": -16.5,
    "CIDADE": "Goianápolis",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.01,
    "FEB": 5.32,
    "MAR": 5.22,
    "APR": 5.56,
    "MAY": 5.59,
    "JUN": 5.5,
    "JUL": 5.7,
    "AUG": 6.38,
    "SEP": 5.74,
    "OCT": 5.4,
    "NOV": 4.86,
    "DEC": 4.9
  },
  {
    "LONG": -48.64,
    "LAT": -16.48,
    "CIDADE": "Gameleira de Goiás",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.03,
    "FEB": 5.49,
    "MAR": 5.17,
    "APR": 5.53,
    "MAY": 5.52,
    "JUN": 5.56,
    "JUL": 5.79,
    "AUG": 6.47,
    "SEP": 5.79,
    "OCT": 5.42,
    "NOV": 4.74,
    "DEC": 5.01
  },
  {
    "LONG": -51.82,
    "LAT": -16.43,
    "CIDADE": "Piranhas",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.08,
    "FEB": 5.49,
    "MAR": 5.36,
    "APR": 5.83,
    "MAY": 5.66,
    "JUN": 5.39,
    "JUL": 5.39,
    "AUG": 6.01,
    "SEP": 5.58,
    "OCT": 5.4,
    "NOV": 5.13,
    "DEC": 5.07
  },
  {
    "LONG": -51.56,
    "LAT": -16.39,
    "CIDADE": "Arenópolis",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.08,
    "FEB": 5.44,
    "MAR": 5.43,
    "APR": 5.75,
    "MAY": 5.66,
    "JUN": 5.43,
    "JUL": 5.42,
    "AUG": 6.1,
    "SEP": 5.6,
    "OCT": 5.42,
    "NOV": 5.12,
    "DEC": 5.05
  },
  {
    "LONG": -51.12,
    "LAT": -16.44,
    "CIDADE": "Iporá",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.04,
    "FEB": 5.31,
    "MAR": 5.3,
    "APR": 5.74,
    "MAY": 5.6,
    "JUN": 5.46,
    "JUL": 5.54,
    "AUG": 6.15,
    "SEP": 5.59,
    "OCT": 5.35,
    "NOV": 5.01,
    "DEC": 5.01
  },
  {
    "LONG": -50.17,
    "LAT": -16.42,
    "CIDADE": "Adelândia",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.05,
    "FEB": 5.36,
    "MAR": 5.3,
    "APR": 5.61,
    "MAY": 5.59,
    "JUN": 5.48,
    "JUL": 5.63,
    "AUG": 6.3,
    "SEP": 5.66,
    "OCT": 5.41,
    "NOV": 5.04,
    "DEC": 5.03
  },
  {
    "LONG": -49.68,
    "LAT": -16.36,
    "CIDADE": "Araçu",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.04,
    "FEB": 5.33,
    "MAR": 5.25,
    "APR": 5.63,
    "MAY": 5.66,
    "JUN": 5.46,
    "JUL": 5.66,
    "AUG": 6.43,
    "SEP": 5.72,
    "OCT": 5.41,
    "NOV": 4.95,
    "DEC": 4.95
  },
  {
    "LONG": -49.5,
    "LAT": -16.36,
    "CIDADE": "Inhumas",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.01,
    "FEB": 5.3,
    "MAR": 5.24,
    "APR": 5.59,
    "MAY": 5.65,
    "JUN": 5.42,
    "JUL": 5.67,
    "AUG": 6.47,
    "SEP": 5.73,
    "OCT": 5.38,
    "NOV": 4.88,
    "DEC": 4.93
  },
  {
    "LONG": -49.5,
    "LAT": -16.45,
    "CIDADE": "Caturaí",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5,
    "FEB": 5.33,
    "MAR": 5.27,
    "APR": 5.55,
    "MAY": 5.63,
    "JUN": 5.45,
    "JUL": 5.65,
    "AUG": 6.47,
    "SEP": 5.78,
    "OCT": 5.38,
    "NOV": 4.91,
    "DEC": 4.95
  },
  {
    "LONG": -49.39,
    "LAT": -16.43,
    "CIDADE": "Brazabrantes",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.02,
    "FEB": 5.33,
    "MAR": 5.27,
    "APR": 5.6,
    "MAY": 5.65,
    "JUN": 5.45,
    "JUL": 5.67,
    "AUG": 6.45,
    "SEP": 5.76,
    "OCT": 5.4,
    "NOV": 4.93,
    "DEC": 4.97
  },
  {
    "LONG": -49.32,
    "LAT": -16.37,
    "CIDADE": "Nova Veneza",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.02,
    "FEB": 5.33,
    "MAR": 5.27,
    "APR": 5.6,
    "MAY": 5.65,
    "JUN": 5.45,
    "JUL": 5.67,
    "AUG": 6.45,
    "SEP": 5.76,
    "OCT": 5.4,
    "NOV": 4.93,
    "DEC": 4.97
  },
  {
    "LONG": -49.22,
    "LAT": -16.41,
    "CIDADE": "Nerópolis",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.06,
    "FEB": 5.3,
    "MAR": 5.23,
    "APR": 5.59,
    "MAY": 5.6,
    "JUN": 5.47,
    "JUL": 5.69,
    "AUG": 6.43,
    "SEP": 5.77,
    "OCT": 5.4,
    "NOV": 4.9,
    "DEC": 4.93
  },
  {
    "LONG": -49.08,
    "LAT": -1.64,
    "CIDADE": "Terezópolis de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 4.95,
    "FEB": 5.32,
    "MAR": 5.18,
    "APR": 5.56,
    "MAY": 5.59,
    "JUN": 5.5,
    "JUL": 5.67,
    "AUG": 6.4,
    "SEP": 5.76,
    "OCT": 5.4,
    "NOV": 4.85,
    "DEC": 4.87
  },
  {
    "LONG": -50.91,
    "LAT": -16.31,
    "CIDADE": "Israelândia",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.08,
    "FEB": 5.43,
    "MAR": 5.47,
    "APR": 5.81,
    "MAY": 5.59,
    "JUN": 5.42,
    "JUL": 5.46,
    "AUG": 5.97,
    "SEP": 5.58,
    "OCT": 5.42,
    "NOV": 5.09,
    "DEC": 5.04
  },
  {
    "LONG": -50.55,
    "LAT": -16.3,
    "CIDADE": "Córrego do Ouro",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.06,
    "FEB": 5.35,
    "MAR": 5.33,
    "APR": 5.69,
    "MAY": 5.63,
    "JUN": 5.45,
    "JUL": 5.62,
    "AUG": 6.11,
    "SEP": 5.63,
    "OCT": 5.4,
    "NOV": 5.1,
    "DEC": 4.97
  },
  {
    "LONG": -49.98,
    "LAT": -1.63,
    "CIDADE": "Americano do Brasil",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 4.97,
    "FEB": 5.28,
    "MAR": 5.23,
    "APR": 5.53,
    "MAY": 5.6,
    "JUN": 5.51,
    "JUL": 5.67,
    "AUG": 6.36,
    "SEP": 5.72,
    "OCT": 5.38,
    "NOV": 4.9,
    "DEC": 4.94
  },
  {
    "LONG": -49.37,
    "LAT": -16.25,
    "CIDADE": "Damolândia",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.96,
    "FEB": 5.34,
    "MAR": 5.25,
    "APR": 5.63,
    "MAY": 5.64,
    "JUN": 5.53,
    "JUL": 5.68,
    "AUG": 6.5,
    "SEP": 5.78,
    "OCT": 5.38,
    "NOV": 4.9,
    "DEC": 4.97
  },
  {
    "LONG": -49.09,
    "LAT": -16.29,
    "CIDADE": "Campo Limpo de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 4.93,
    "FEB": 5.29,
    "MAR": 5.13,
    "APR": 5.52,
    "MAY": 5.61,
    "JUN": 5.53,
    "JUL": 5.75,
    "AUG": 6.47,
    "SEP": 5.75,
    "OCT": 5.35,
    "NOV": 4.8,
    "DEC": 4.92
  },
  {
    "LONG": -48.95,
    "LAT": -16.33,
    "CIDADE": "Anápolis",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 4.93,
    "FEB": 5.3,
    "MAR": 5.12,
    "APR": 5.47,
    "MAY": 5.52,
    "JUN": 5.51,
    "JUL": 5.75,
    "AUG": 6.42,
    "SEP": 5.76,
    "OCT": 5.35,
    "NOV": 4.77,
    "DEC": 4.88
  },
  {
    "LONG": -47.95,
    "LAT": -16.25,
    "CIDADE": "Luziânia",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.17,
    "FEB": 5.57,
    "MAR": 5.28,
    "APR": 5.59,
    "MAY": 5.55,
    "JUN": 5.57,
    "JUL": 5.8,
    "AUG": 6.44,
    "SEP": 5.87,
    "OCT": 5.49,
    "NOV": 4.86,
    "DEC": 5.07
  },
  {
    "LONG": -52.54,
    "LAT": -16.2,
    "CIDADE": "Baliza",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.07,
    "FEB": 5.4,
    "MAR": 5.34,
    "APR": 5.8,
    "MAY": 5.65,
    "JUN": 5.41,
    "JUL": 5.46,
    "AUG": 6.06,
    "SEP": 5.64,
    "OCT": 5.31,
    "NOV": 5.14,
    "DEC": 5.1
  },
  {
    "LONG": -52.18,
    "LAT": -16.2,
    "CIDADE": "Bom Jardim de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.07,
    "FEB": 5.41,
    "MAR": 5.3,
    "APR": 5.75,
    "MAY": 5.62,
    "JUN": 5.37,
    "JUL": 5.42,
    "AUG": 6.01,
    "SEP": 5.57,
    "OCT": 5.34,
    "NOV": 5.1,
    "DEC": 5.08
  },
  {
    "LONG": -51.26,
    "LAT": -16.23,
    "CIDADE": "Diorama",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.11,
    "FEB": 5.36,
    "MAR": 5.35,
    "APR": 5.79,
    "MAY": 5.62,
    "JUN": 5.43,
    "JUL": 5.41,
    "AUG": 6.02,
    "SEP": 5.64,
    "OCT": 5.38,
    "NOV": 5,
    "DEC": 5.02
  },
  {
    "LONG": -50.95,
    "LAT": -16.18,
    "CIDADE": "Jaupaci",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.07,
    "FEB": 5.43,
    "MAR": 5.45,
    "APR": 5.8,
    "MAY": 5.66,
    "JUN": 5.41,
    "JUL": 5.53,
    "AUG": 6,
    "SEP": 5.61,
    "OCT": 5.44,
    "NOV": 5.09,
    "DEC": 5.07
  },
  {
    "LONG": -50.78,
    "LAT": "#VALOR!",
    "CIDADE": "Fazenda Nova",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.1,
    "FEB": 5.4,
    "MAR": 5.43,
    "APR": 5.77,
    "MAY": 5.68,
    "JUN": 5.43,
    "JUL": 5.5,
    "AUG": 6.06,
    "SEP": 5.63,
    "OCT": 5.49,
    "NOV": 5.1,
    "DEC": 5.01
  },
  {
    "LONG": -50.43,
    "LAT": -16.18,
    "CIDADE": "Buriti de Goiás",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.12,
    "FEB": 5.37,
    "MAR": 5.42,
    "APR": 5.74,
    "MAY": 5.64,
    "JUN": 5.47,
    "JUL": 5.58,
    "AUG": 6.17,
    "SEP": 5.6,
    "OCT": 5.46,
    "NOV": 5.16,
    "DEC": 5.01
  },
  {
    "LONG": -50.3,
    "LAT": -16.21,
    "CIDADE": "Sanclerlândia",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.08,
    "FEB": 5.38,
    "MAR": 5.41,
    "APR": 5.73,
    "MAY": 5.63,
    "JUN": 5.53,
    "JUL": 5.57,
    "AUG": 6.28,
    "SEP": 5.64,
    "OCT": 5.45,
    "NOV": 5.12,
    "DEC": 5.03
  },
  {
    "LONG": -49.6,
    "LAT": -16.2,
    "CIDADE": "Itauçu",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5,
    "FEB": 5.36,
    "MAR": 5.21,
    "APR": 5.59,
    "MAY": 5.6,
    "JUN": 5.48,
    "JUL": 5.62,
    "AUG": 6.36,
    "SEP": 5.71,
    "OCT": 5.39,
    "NOV": 4.92,
    "DEC": 4.91
  },
  {
    "LONG": -49.2,
    "LAT": -16.22,
    "CIDADE": "Ouro Verde de Goiás",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.94,
    "FEB": 5.3,
    "MAR": 5.2,
    "APR": 5.55,
    "MAY": 5.58,
    "JUN": 5.5,
    "JUL": 5.67,
    "AUG": 6.45,
    "SEP": 5.75,
    "OCT": 5.38,
    "NOV": 4.86,
    "DEC": 4.93
  },
  {
    "LONG": -48.71,
    "LAT": -16.2,
    "CIDADE": "Abadiânia",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.97,
    "FEB": 5.37,
    "MAR": 5.21,
    "APR": 5.55,
    "MAY": 5.63,
    "JUN": 5.54,
    "JUL": 5.77,
    "AUG": 6.46,
    "SEP": 5.84,
    "OCT": 5.41,
    "NOV": 4.77,
    "DEC": 4.93
  },
  {
    "LONG": -50.21,
    "LAT": -16.12,
    "CIDADE": "Mossâmedes",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.99,
    "FEB": 5.32,
    "MAR": 5.31,
    "APR": 5.69,
    "MAY": 5.67,
    "JUN": 5.45,
    "JUL": 5.58,
    "AUG": 6.33,
    "SEP": 5.67,
    "OCT": 5.47,
    "NOV": 5.01,
    "DEC": 4.93
  },
  {
    "LONG": -49.6,
    "LAT": -1.61,
    "CIDADE": "Taquaral de Goiás",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.01,
    "FEB": 5.42,
    "MAR": 5.27,
    "APR": 5.62,
    "MAY": 5.63,
    "JUN": 5.49,
    "JUL": 5.55,
    "AUG": 6.29,
    "SEP": 5.71,
    "OCT": 5.42,
    "NOV": 4.91,
    "DEC": 4.94
  },
  {
    "LONG": -49.49,
    "LAT": -16.08,
    "CIDADE": "Santa Rosa de Goiás",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.99,
    "FEB": 5.4,
    "MAR": 5.29,
    "APR": 5.65,
    "MAY": 5.64,
    "JUN": 5.52,
    "JUL": 5.65,
    "AUG": 6.39,
    "SEP": 5.74,
    "OCT": 5.41,
    "NOV": 4.89,
    "DEC": 4.95
  },
  {
    "LONG": -49.34,
    "LAT": -16.1,
    "CIDADE": "Petrolina de Goiás",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.03,
    "FEB": 5.43,
    "MAR": 5.33,
    "APR": 5.71,
    "MAY": 5.65,
    "JUN": 5.54,
    "JUL": 5.67,
    "AUG": 6.42,
    "SEP": 5.79,
    "OCT": 5.45,
    "NOV": 4.94,
    "DEC": 4.99
  },
  {
    "LONG": -4.85,
    "LAT": -16.08,
    "CIDADE": "Alexânia",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.98,
    "FEB": 5.31,
    "MAR": 5.1,
    "APR": 5.49,
    "MAY": 5.57,
    "JUN": 5.52,
    "JUL": 5.78,
    "AUG": 6.49,
    "SEP": 5.79,
    "OCT": 5.42,
    "NOV": 4.76,
    "DEC": 4.96
  },
  {
    "LONG": -48.03,
    "LAT": -16.05,
    "CIDADE": "Novo Gama",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.05,
    "FEB": 5.46,
    "MAR": 5.2,
    "APR": 5.53,
    "MAY": 5.57,
    "JUN": 5.63,
    "JUL": 5.81,
    "AUG": 6.5,
    "SEP": 5.84,
    "OCT": 5.4,
    "NOV": 4.8,
    "DEC": 4.93
  },
  {
    "LONG": -47.93,
    "LAT": -16.08,
    "CIDADE": "Cidade Ocidental",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.02,
    "FEB": 5.48,
    "MAR": 5.16,
    "APR": 5.53,
    "MAY": 5.55,
    "JUN": 5.6,
    "JUL": 5.83,
    "AUG": 6.49,
    "SEP": 5.89,
    "OCT": 5.37,
    "NOV": 4.74,
    "DEC": 4.92
  },
  {
    "LONG": -47.98,
    "LAT": -16.07,
    "CIDADE": "Valparaíso de Goiás",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.02,
    "FEB": 5.48,
    "MAR": 5.16,
    "APR": 5.53,
    "MAY": 5.55,
    "JUN": 5.6,
    "JUL": 5.83,
    "AUG": 6.49,
    "SEP": 5.89,
    "OCT": 5.37,
    "NOV": 4.74,
    "DEC": 4.92
  },
  {
    "LONG": -51.4,
    "LAT": -16.01,
    "CIDADE": "Montes Claros de Goiás",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.07,
    "FEB": 5.44,
    "MAR": 5.39,
    "APR": 5.75,
    "MAY": 5.63,
    "JUN": 5.42,
    "JUL": 5.42,
    "AUG": 5.98,
    "SEP": 5.63,
    "OCT": 5.37,
    "NOV": 5,
    "DEC": 5.05
  },
  {
    "LONG": -50.71,
    "LAT": -16.03,
    "CIDADE": "Novo Brasil",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.08,
    "FEB": 5.48,
    "MAR": 5.47,
    "APR": 5.85,
    "MAY": 5.7,
    "JUN": 5.44,
    "JUL": 5.5,
    "AUG": 5.99,
    "SEP": 5.65,
    "OCT": 5.5,
    "NOV": 5.11,
    "DEC": 5.01
  },
  {
    "LONG": -49.81,
    "LAT": -1.6,
    "CIDADE": "Itaberaí",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.9,
    "FEB": 5.41,
    "MAR": 5.25,
    "APR": 5.59,
    "MAY": 5.62,
    "JUN": 5.49,
    "JUL": 5.59,
    "AUG": 6.42,
    "SEP": 5.73,
    "OCT": 5.4,
    "NOV": 4.93,
    "DEC": 4.91
  },
  {
    "LONG": -49.38,
    "LAT": -15.96,
    "CIDADE": "Jesúpolis",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.01,
    "FEB": 5.44,
    "MAR": 5.37,
    "APR": 5.71,
    "MAY": 5.65,
    "JUN": 5.52,
    "JUL": 5.65,
    "AUG": 6.38,
    "SEP": 5.77,
    "OCT": 5.45,
    "NOV": 4.98,
    "DEC": 5
  },
  {
    "LONG": -5.22,
    "LAT": -15.9,
    "CIDADE": "Aragarças",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.03,
    "FEB": 5.37,
    "MAR": 5.28,
    "APR": 5.76,
    "MAY": 5.7,
    "JUN": 5.43,
    "JUL": 5.5,
    "AUG": 6.09,
    "SEP": 5.49,
    "OCT": 5.34,
    "NOV": 5.05,
    "DEC": 5.04
  },
  {
    "LONG": -50.87,
    "LAT": -15.87,
    "CIDADE": "Jussara",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.06,
    "FEB": 5.41,
    "MAR": 5.47,
    "APR": 5.84,
    "MAY": 5.63,
    "JUN": 5.48,
    "JUL": 5.44,
    "AUG": 6.04,
    "SEP": 5.64,
    "OCT": 5.45,
    "NOV": 5.04,
    "DEC": 5.01
  },
  {
    "LONG": -50.14,
    "LAT": -15.94,
    "CIDADE": "Goiás",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.94,
    "FEB": 5.34,
    "MAR": 5.33,
    "APR": 5.65,
    "MAY": 5.66,
    "JUN": 5.51,
    "JUL": 5.5,
    "AUG": 6.32,
    "SEP": 5.66,
    "OCT": 5.43,
    "NOV": 4.96,
    "DEC": 4.88
  },
  {
    "LONG": -49.6,
    "LAT": -15.92,
    "CIDADE": "Itaguari",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.01,
    "FEB": 5.46,
    "MAR": 5.31,
    "APR": 5.61,
    "MAY": 5.62,
    "JUN": 5.52,
    "JUL": 5.55,
    "AUG": 6.33,
    "SEP": 5.71,
    "OCT": 5.42,
    "NOV": 4.92,
    "DEC": 4.92
  },
  {
    "LONG": -49.26,
    "LAT": -1.59,
    "CIDADE": "São Francisco de Goiás",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.09,
    "FEB": 5.43,
    "MAR": 5.32,
    "APR": 5.63,
    "MAY": 5.68,
    "JUN": 5.49,
    "JUL": 5.65,
    "AUG": 6.35,
    "SEP": 5.75,
    "OCT": 5.47,
    "NOV": 4.98,
    "DEC": 4.96
  },
  {
    "LONG": -48.95,
    "LAT": -15.85,
    "CIDADE": "Pirenópolis",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 4.94,
    "FEB": 5.23,
    "MAR": 5.18,
    "APR": 5.55,
    "MAY": 5.63,
    "JUN": 5.45,
    "JUL": 5.7,
    "AUG": 6.36,
    "SEP": 5.74,
    "OCT": 5.36,
    "NOV": 4.78,
    "DEC": 4.8
  },
  {
    "LONG": -4.88,
    "LAT": -15.93,
    "CIDADE": "Corumbá de Goiás",
    "UF": "GOIÁS",
    "anual": 5.37,
    "JAN": 4.87,
    "FEB": 5.2,
    "MAR": 5.09,
    "APR": 5.44,
    "MAY": 5.59,
    "JUN": 5.44,
    "JUL": 5.75,
    "AUG": 6.47,
    "SEP": 5.74,
    "OCT": 5.33,
    "NOV": 4.75,
    "DEC": 4.77
  },
  {
    "LONG": -4.83,
    "LAT": -15.95,
    "CIDADE": "Santo Antônio do Descoberto",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 4.96,
    "FEB": 5.4,
    "MAR": 5.14,
    "APR": 5.42,
    "MAY": 5.64,
    "JUN": 5.72,
    "JUL": 5.87,
    "AUG": 6.61,
    "SEP": 5.84,
    "OCT": 5.38,
    "NOV": 4.78,
    "DEC": 4.96
  },
  {
    "LONG": -51.1,
    "LAT": -15.77,
    "CIDADE": "Santa Fé de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.07,
    "FEB": 5.4,
    "MAR": 5.36,
    "APR": 5.74,
    "MAY": 5.63,
    "JUN": 5.4,
    "JUL": 5.5,
    "AUG": 6.02,
    "SEP": 5.61,
    "OCT": 5.35,
    "NOV": 4.98,
    "DEC": 5.02
  },
  {
    "LONG": -50.61,
    "LAT": -15.82,
    "CIDADE": "Itapirapuã",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.04,
    "FEB": 5.42,
    "MAR": 5.43,
    "APR": 5.75,
    "MAY": 5.64,
    "JUN": 5.49,
    "JUL": 5.54,
    "AUG": 6.19,
    "SEP": 5.66,
    "OCT": 5.44,
    "NOV": 5.02,
    "DEC": 5
  },
  {
    "LONG": -4.96,
    "LAT": -15.76,
    "CIDADE": "Itaguaru",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.98,
    "FEB": 5.46,
    "MAR": 5.33,
    "APR": 5.64,
    "MAY": 5.64,
    "JUN": 5.47,
    "JUL": 5.42,
    "AUG": 6.27,
    "SEP": 5.69,
    "OCT": 5.45,
    "NOV": 4.91,
    "DEC": 4.97
  },
  {
    "LONG": -48.77,
    "LAT": -15.78,
    "CIDADE": "Cocalzinho de Goiás",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 4.96,
    "FEB": 5.26,
    "MAR": 5.08,
    "APR": 5.45,
    "MAY": 5.6,
    "JUN": 5.5,
    "JUL": 5.74,
    "AUG": 6.57,
    "SEP": 5.81,
    "OCT": 5.35,
    "NOV": 4.72,
    "DEC": 4.84
  },
  {
    "LONG": -46.92,
    "LAT": -15.81,
    "CIDADE": "Cabeceiras",
    "UF": "GOIÁS",
    "anual": 5.69,
    "JAN": 5.34,
    "FEB": 5.86,
    "MAR": 5.46,
    "APR": 5.71,
    "MAY": 5.71,
    "JUN": 5.7,
    "JUL": 5.94,
    "AUG": 6.49,
    "SEP": 6.18,
    "OCT": 5.7,
    "NOV": 4.91,
    "DEC": 5.23
  },
  {
    "LONG": -49.83,
    "LAT": -15.72,
    "CIDADE": "Heitoraí",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 4.93,
    "FEB": 5.38,
    "MAR": 5.22,
    "APR": 5.64,
    "MAY": 5.61,
    "JUN": 5.52,
    "JUL": 5.45,
    "AUG": 6.34,
    "SEP": 5.64,
    "OCT": 5.4,
    "NOV": 4.85,
    "DEC": 4.84
  },
  {
    "LONG": -49.33,
    "LAT": -15.75,
    "CIDADE": "Jaraguá",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.02,
    "FEB": 5.48,
    "MAR": 5.33,
    "APR": 5.63,
    "MAY": 5.63,
    "JUN": 5.52,
    "JUL": 5.57,
    "AUG": 6.36,
    "SEP": 5.81,
    "OCT": 5.51,
    "NOV": 4.97,
    "DEC": 4.95
  },
  {
    "LONG": -48.26,
    "LAT": -15.74,
    "CIDADE": "Águas Lindas de Goiás",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.96,
    "FEB": 5.32,
    "MAR": 5.13,
    "APR": 5.35,
    "MAY": 5.57,
    "JUN": 5.62,
    "JUL": 5.79,
    "AUG": 6.6,
    "SEP": 5.9,
    "OCT": 5.38,
    "NOV": 4.78,
    "DEC": 4.91
  },
  {
    "LONG": -50.03,
    "LAT": -15.63,
    "CIDADE": "Guaraíta",
    "UF": "GOIÁS",
    "anual": 5.38,
    "JAN": 4.84,
    "FEB": 5.28,
    "MAR": 5.23,
    "APR": 5.6,
    "MAY": 5.67,
    "JUN": 5.48,
    "JUL": 5.52,
    "AUG": 6.29,
    "SEP": 5.66,
    "OCT": 5.38,
    "NOV": 4.8,
    "DEC": 4.86
  },
  {
    "LONG": -49.94,
    "LAT": -15.56,
    "CIDADE": "Itapuranga",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 4.9,
    "FEB": 5.38,
    "MAR": 5.28,
    "APR": 5.68,
    "MAY": 5.67,
    "JUN": 5.47,
    "JUL": 5.45,
    "AUG": 6.25,
    "SEP": 5.69,
    "OCT": 5.42,
    "NOV": 4.86,
    "DEC": 4.9
  },
  {
    "LONG": -49.68,
    "LAT": -1.55,
    "CIDADE": "Uruana",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.98,
    "FEB": 5.46,
    "MAR": 5.36,
    "APR": 5.67,
    "MAY": 5.7,
    "JUN": 5.47,
    "JUL": 5.45,
    "AUG": 6.28,
    "SEP": 5.74,
    "OCT": 5.49,
    "NOV": 4.92,
    "DEC": 4.91
  },
  {
    "LONG": -48.88,
    "LAT": -1.55,
    "CIDADE": "Vila Propício",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.09,
    "FEB": 5.49,
    "MAR": 5.31,
    "APR": 5.65,
    "MAY": 5.65,
    "JUN": 5.51,
    "JUL": 5.58,
    "AUG": 6.48,
    "SEP": 5.85,
    "OCT": 5.5,
    "NOV": 4.91,
    "DEC": 4.96
  },
  {
    "LONG": -47.61,
    "LAT": -15.45,
    "CIDADE": "Planaltina",
    "UF": "GOIÁS",
    "anual": 5.58,
    "JAN": 5.07,
    "FEB": 5.61,
    "MAR": 5.3,
    "APR": 5.59,
    "MAY": 5.63,
    "JUN": 5.68,
    "JUL": 5.94,
    "AUG": 6.69,
    "SEP": 6.14,
    "OCT": 5.52,
    "NOV": 4.79,
    "DEC": 5.03
  },
  {
    "LONG": -47.34,
    "LAT": -15.54,
    "CIDADE": "Formosa",
    "UF": "GOIÁS",
    "anual": 5.62,
    "JAN": 5.22,
    "FEB": 5.63,
    "MAR": 5.35,
    "APR": 5.6,
    "MAY": 5.64,
    "JUN": 5.65,
    "JUL": 6.02,
    "AUG": 6.58,
    "SEP": 6.16,
    "OCT": 5.62,
    "NOV": 4.84,
    "DEC": 5.12
  },
  {
    "LONG": -5.07,
    "LAT": -15.43,
    "CIDADE": "Matrinchã",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.02,
    "FEB": 5.38,
    "MAR": 5.44,
    "APR": 5.71,
    "MAY": 5.63,
    "JUN": 5.32,
    "JUL": 5.49,
    "AUG": 6.13,
    "SEP": 5.64,
    "OCT": 5.43,
    "NOV": 5.03,
    "DEC": 4.98
  },
  {
    "LONG": -50.36,
    "LAT": -15.45,
    "CIDADE": "Faina",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.96,
    "FEB": 5.34,
    "MAR": 5.4,
    "APR": 5.7,
    "MAY": 5.7,
    "JUN": 5.42,
    "JUL": 5.58,
    "AUG": 6.27,
    "SEP": 5.69,
    "OCT": 5.47,
    "NOV": 5.03,
    "DEC": 4.95
  },
  {
    "LONG": -49.71,
    "LAT": -15.36,
    "CIDADE": "Carmo do Rio Verde",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.92,
    "FEB": 5.37,
    "MAR": 5.28,
    "APR": 5.62,
    "MAY": 5.68,
    "JUN": 5.52,
    "JUL": 5.58,
    "AUG": 6.38,
    "SEP": 5.69,
    "OCT": 5.41,
    "NOV": 4.85,
    "DEC": 4.9
  },
  {
    "LONG": -49.51,
    "LAT": -15.45,
    "CIDADE": "Rianápolis",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.96,
    "FEB": 5.41,
    "MAR": 5.31,
    "APR": 5.56,
    "MAY": 5.64,
    "JUN": 5.48,
    "JUL": 5.53,
    "AUG": 6.39,
    "SEP": 5.72,
    "OCT": 5.41,
    "NOV": 4.9,
    "DEC": 4.9
  },
  {
    "LONG": -50.06,
    "LAT": -15.32,
    "CIDADE": "Morro Agudo de Goiás",
    "UF": "GOIÁS",
    "anual": 5.36,
    "JAN": 4.83,
    "FEB": 5.19,
    "MAR": 5.15,
    "APR": 5.46,
    "MAY": 5.66,
    "JUN": 5.48,
    "JUL": 5.63,
    "AUG": 6.32,
    "SEP": 5.64,
    "OCT": 5.35,
    "NOV": 4.84,
    "DEC": 4.8
  },
  {
    "LONG": -49.82,
    "LAT": -15.35,
    "CIDADE": "São Patrício",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 4.84,
    "FEB": 5.23,
    "MAR": 5.15,
    "APR": 5.49,
    "MAY": 5.65,
    "JUN": 5.43,
    "JUL": 5.53,
    "AUG": 6.34,
    "SEP": 5.66,
    "OCT": 5.33,
    "NOV": 4.82,
    "DEC": 4.79
  },
  {
    "LONG": -49.6,
    "LAT": -15.31,
    "CIDADE": "Ceres",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 4.88,
    "FEB": 5.31,
    "MAR": 5.18,
    "APR": 5.56,
    "MAY": 5.66,
    "JUN": 5.46,
    "JUL": 5.61,
    "AUG": 6.4,
    "SEP": 5.72,
    "OCT": 5.38,
    "NOV": 4.8,
    "DEC": 4.84
  },
  {
    "LONG": -49.58,
    "LAT": -15.31,
    "CIDADE": "Rialma",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.94,
    "FEB": 5.41,
    "MAR": 5.29,
    "APR": 5.64,
    "MAY": 5.64,
    "JUN": 5.43,
    "JUL": 5.57,
    "AUG": 6.39,
    "SEP": 5.75,
    "OCT": 5.42,
    "NOV": 4.87,
    "DEC": 4.9
  },
  {
    "LONG": -4.94,
    "LAT": -15.3,
    "CIDADE": "Santa Isabel",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.94,
    "FEB": 5.43,
    "MAR": 5.29,
    "APR": 5.58,
    "MAY": 5.63,
    "JUN": 5.42,
    "JUL": 5.57,
    "AUG": 6.4,
    "SEP": 5.76,
    "OCT": 5.38,
    "NOV": 4.86,
    "DEC": 4.92
  },
  {
    "LONG": -49.12,
    "LAT": -15.31,
    "CIDADE": "Goianésia",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 4.93,
    "FEB": 5.37,
    "MAR": 5.26,
    "APR": 5.59,
    "MAY": 5.64,
    "JUN": 5.51,
    "JUL": 5.61,
    "AUG": 6.3,
    "SEP": 5.79,
    "OCT": 5.37,
    "NOV": 4.84,
    "DEC": 4.89
  },
  {
    "LONG": -51.17,
    "LAT": -15.24,
    "CIDADE": "Britânia",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.19,
    "FEB": 5.5,
    "MAR": 5.52,
    "APR": 5.79,
    "MAY": 5.66,
    "JUN": 5.44,
    "JUL": 5.57,
    "AUG": 6.08,
    "SEP": 5.73,
    "OCT": 5.41,
    "NOV": 5.05,
    "DEC": 5.08
  },
  {
    "LONG": -49.8,
    "LAT": -15.16,
    "CIDADE": "Rubiataba",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 4.85,
    "FEB": 5.23,
    "MAR": 5.19,
    "APR": 5.52,
    "MAY": 5.66,
    "JUN": 5.48,
    "JUL": 5.6,
    "AUG": 6.37,
    "SEP": 5.74,
    "OCT": 5.37,
    "NOV": 4.85,
    "DEC": 4.83
  },
  {
    "LONG": -49.67,
    "LAT": -15.17,
    "CIDADE": "Ipiranga de Goiás",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 4.89,
    "FEB": 5.32,
    "MAR": 5.25,
    "APR": 5.56,
    "MAY": 5.65,
    "JUN": 5.41,
    "JUL": 5.57,
    "AUG": 6.3,
    "SEP": 5.76,
    "OCT": 5.38,
    "NOV": 4.85,
    "DEC": 4.86
  },
  {
    "LONG": -48.29,
    "LAT": -15.16,
    "CIDADE": "Padre Bernardo",
    "UF": "GOIÁS",
    "anual": 5.6,
    "JAN": 5.22,
    "FEB": 5.63,
    "MAR": 5.49,
    "APR": 5.7,
    "MAY": 5.72,
    "JUN": 5.57,
    "JUL": 5.67,
    "AUG": 6.47,
    "SEP": 5.94,
    "OCT": 5.62,
    "NOV": 5,
    "DEC": 5.12
  },
  {
    "LONG": -50.63,
    "LAT": -15.09,
    "CIDADE": "Araguapaz",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.01,
    "FEB": 5.38,
    "MAR": 5.46,
    "APR": 5.7,
    "MAY": 5.61,
    "JUN": 5.37,
    "JUL": 5.57,
    "AUG": 6.07,
    "SEP": 5.62,
    "OCT": 5.44,
    "NOV": 5.03,
    "DEC": 4.94
  },
  {
    "LONG": -49.57,
    "LAT": -15.15,
    "CIDADE": "Nova Glória",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 4.89,
    "FEB": 5.36,
    "MAR": 5.29,
    "APR": 5.58,
    "MAY": 5.58,
    "JUN": 5.39,
    "JUL": 5.55,
    "AUG": 6.28,
    "SEP": 5.79,
    "OCT": 5.44,
    "NOV": 4.87,
    "DEC": 4.88
  },
  {
    "LONG": -49.12,
    "LAT": -15.14,
    "CIDADE": "Santa Rita do Novo Destino",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.98,
    "FEB": 5.43,
    "MAR": 5.34,
    "APR": 5.61,
    "MAY": 5.66,
    "JUN": 5.5,
    "JUL": 5.62,
    "AUG": 6.28,
    "SEP": 5.89,
    "OCT": 5.46,
    "NOV": 4.9,
    "DEC": 4.91
  },
  {
    "LONG": -48.16,
    "LAT": -15.06,
    "CIDADE": "Mimoso de Goiás",
    "UF": "GOIÁS",
    "anual": 5.57,
    "JAN": 5.09,
    "FEB": 5.54,
    "MAR": 5.39,
    "APR": 5.66,
    "MAY": 5.76,
    "JUN": 5.62,
    "JUL": 5.79,
    "AUG": 6.58,
    "SEP": 5.93,
    "OCT": 5.54,
    "NOV": 4.91,
    "DEC": 5.04
  },
  {
    "LONG": -49.9,
    "LAT": -1.5,
    "CIDADE": "Nova América",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 4.75,
    "FEB": 5.18,
    "MAR": 5.07,
    "APR": 5.44,
    "MAY": 5.6,
    "JUN": 5.48,
    "JUL": 5.65,
    "AUG": 6.36,
    "SEP": 5.69,
    "OCT": 5.36,
    "NOV": 4.84,
    "DEC": 4.79
  },
  {
    "LONG": -49.55,
    "LAT": -14.95,
    "CIDADE": "Itapaci",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 4.87,
    "FEB": 5.34,
    "MAR": 5.26,
    "APR": 5.57,
    "MAY": 5.57,
    "JUN": 5.4,
    "JUL": 5.57,
    "AUG": 6.29,
    "SEP": 5.8,
    "OCT": 5.37,
    "NOV": 4.88,
    "DEC": 4.88
  },
  {
    "LONG": -4.89,
    "LAT": -14.97,
    "CIDADE": "Barro Alto",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.89,
    "FEB": 5.33,
    "MAR": 5.23,
    "APR": 5.58,
    "MAY": 5.65,
    "JUN": 5.47,
    "JUL": 5.55,
    "AUG": 6.51,
    "SEP": 5.86,
    "OCT": 5.44,
    "NOV": 4.8,
    "DEC": 4.9
  },
  {
    "LONG": -47.78,
    "LAT": -14.98,
    "CIDADE": "Água Fria de Goiás",
    "UF": "GOIÁS",
    "anual": 5.55,
    "JAN": 5.1,
    "FEB": 5.56,
    "MAR": 5.32,
    "APR": 5.67,
    "MAY": 5.66,
    "JUN": 5.56,
    "JUL": 5.81,
    "AUG": 6.58,
    "SEP": 6.06,
    "OCT": 5.51,
    "NOV": 4.7,
    "DEC": 5.02
  },
  {
    "LONG": -47.05,
    "LAT": -15.03,
    "CIDADE": "Vila Boa",
    "UF": "GOIÁS",
    "anual": 5.74,
    "JAN": 5.46,
    "FEB": 5.85,
    "MAR": 5.57,
    "APR": 5.79,
    "MAY": 5.68,
    "JUN": 5.7,
    "JUL": 6.07,
    "AUG": 6.5,
    "SEP": 6.17,
    "OCT": 5.72,
    "NOV": 5.06,
    "DEC": 5.3
  },
  {
    "LONG": -51.08,
    "LAT": -14.93,
    "CIDADE": "Aruanã",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.1,
    "FEB": 5.45,
    "MAR": 5.49,
    "APR": 5.78,
    "MAY": 5.62,
    "JUN": 5.33,
    "JUL": 5.58,
    "AUG": 5.95,
    "SEP": 5.69,
    "OCT": 5.43,
    "NOV": 5.07,
    "DEC": 5
  },
  {
    "LONG": -49.33,
    "LAT": -1.49,
    "CIDADE": "São Luiz do Norte",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 4.96,
    "FEB": 5.44,
    "MAR": 5.32,
    "APR": 5.58,
    "MAY": 5.61,
    "JUN": 5.36,
    "JUL": 5.54,
    "AUG": 6.26,
    "SEP": 5.77,
    "OCT": 5.42,
    "NOV": 4.9,
    "DEC": 4.92
  },
  {
    "LONG": -49.58,
    "LAT": -14.76,
    "CIDADE": "Pilar de Goiás",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 4.91,
    "FEB": 5.23,
    "MAR": 5.11,
    "APR": 5.43,
    "MAY": 5.6,
    "JUN": 5.46,
    "JUL": 5.67,
    "AUG": 6.55,
    "SEP": 5.75,
    "OCT": 5.32,
    "NOV": 4.81,
    "DEC": 4.87
  },
  {
    "LONG": -46.25,
    "LAT": -14.8,
    "CIDADE": "Sítio d'Abadia",
    "UF": "GOIÁS",
    "anual": 5.69,
    "JAN": 5.47,
    "FEB": 5.74,
    "MAR": 5.48,
    "APR": 5.76,
    "MAY": 5.58,
    "JUN": 5.6,
    "JUL": 6.04,
    "AUG": 6.49,
    "SEP": 6.23,
    "OCT": 5.68,
    "NOV": 4.95,
    "DEC": 5.31
  },
  {
    "LONG": -50.57,
    "LAT": -14.75,
    "CIDADE": "Mozarlândia",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.99,
    "FEB": 5.43,
    "MAR": 5.38,
    "APR": 5.68,
    "MAY": 5.58,
    "JUN": 5.39,
    "JUL": 5.59,
    "AUG": 6.17,
    "SEP": 5.66,
    "OCT": 5.36,
    "NOV": 4.98,
    "DEC": 4.92
  },
  {
    "LONG": -49.7,
    "LAT": -14.73,
    "CIDADE": "Guarinos",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 4.98,
    "FEB": 5.37,
    "MAR": 5.33,
    "APR": 5.59,
    "MAY": 5.6,
    "JUN": 5.54,
    "JUL": 5.73,
    "AUG": 6.46,
    "SEP": 5.77,
    "OCT": 5.47,
    "NOV": 4.94,
    "DEC": 4.94
  },
  {
    "LONG": -49.47,
    "LAT": -14.72,
    "CIDADE": "Hidrolina",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 4.91,
    "FEB": 5.25,
    "MAR": 5.21,
    "APR": 5.47,
    "MAY": 5.57,
    "JUN": 5.5,
    "JUL": 5.7,
    "AUG": 6.48,
    "SEP": 5.8,
    "OCT": 5.33,
    "NOV": 4.82,
    "DEC": 4.88
  },
  {
    "LONG": -47.52,
    "LAT": -14.71,
    "CIDADE": "São João d'Aliança",
    "UF": "GOIÁS",
    "anual": 5.58,
    "JAN": 5.16,
    "FEB": 5.56,
    "MAR": 5.34,
    "APR": 5.55,
    "MAY": 5.66,
    "JUN": 5.74,
    "JUL": 5.92,
    "AUG": 6.66,
    "SEP": 6.13,
    "OCT": 5.5,
    "NOV": 4.69,
    "DEC": 5.03
  },
  {
    "LONG": -46.17,
    "LAT": -14.56,
    "CIDADE": "Damianópolis",
    "UF": "GOIÁS",
    "anual": 5.74,
    "JAN": 5.54,
    "FEB": 5.78,
    "MAR": 5.54,
    "APR": 5.81,
    "MAY": 5.69,
    "JUN": 5.69,
    "JUL": 6.03,
    "AUG": 6.53,
    "SEP": 6.23,
    "OCT": 5.74,
    "NOV": 4.95,
    "DEC": 5.37
  },
  {
    "LONG": -49.98,
    "LAT": -14.54,
    "CIDADE": "Crixás",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.98,
    "FEB": 5.38,
    "MAR": 5.29,
    "APR": 5.64,
    "MAY": 5.62,
    "JUN": 5.44,
    "JUL": 5.68,
    "AUG": 6.28,
    "SEP": 5.75,
    "OCT": 5.47,
    "NOV": 4.93,
    "DEC": 4.95
  },
  {
    "LONG": "#VALOR!",
    "LAT": -14.52,
    "CIDADE": "Uruaçu",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.97,
    "FEB": 5.46,
    "MAR": 5.34,
    "APR": 5.57,
    "MAY": 5.65,
    "JUN": 5.41,
    "JUL": 5.64,
    "AUG": 6.29,
    "SEP": 5.82,
    "OCT": 5.45,
    "NOV": 4.84,
    "DEC": 4.98
  },
  {
    "LONG": -48.46,
    "LAT": -14.47,
    "CIDADE": "Niquelândia",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.01,
    "FEB": 5.38,
    "MAR": 5.21,
    "APR": 5.67,
    "MAY": 5.69,
    "JUN": 5.46,
    "JUL": 5.85,
    "AUG": 6.53,
    "SEP": 5.88,
    "OCT": 5.51,
    "NOV": 4.78,
    "DEC": 4.93
  },
  {
    "LONG": -46.43,
    "LAT": -14.46,
    "CIDADE": "Buritinópolis",
    "UF": "GOIÁS",
    "anual": 5.76,
    "JAN": 5.6,
    "FEB": 5.88,
    "MAR": 5.64,
    "APR": 5.92,
    "MAY": 5.71,
    "JUN": 5.64,
    "JUL": 5.96,
    "AUG": 6.44,
    "SEP": 6.16,
    "OCT": 5.72,
    "NOV": 5.05,
    "DEC": 5.42
  },
  {
    "LONG": -46.49,
    "LAT": -14.46,
    "CIDADE": "Simolândia",
    "UF": "GOIÁS",
    "anual": 5.76,
    "JAN": 5.6,
    "FEB": 5.88,
    "MAR": 5.64,
    "APR": 5.92,
    "MAY": 5.71,
    "JUN": 5.64,
    "JUL": 5.96,
    "AUG": 6.44,
    "SEP": 6.16,
    "OCT": 5.72,
    "NOV": 5.05,
    "DEC": 5.42
  },
  {
    "LONG": -46.49,
    "LAT": -14.48,
    "CIDADE": "Alvorada do Norte",
    "UF": "GOIÁS",
    "anual": 5.76,
    "JAN": 5.6,
    "FEB": 5.88,
    "MAR": 5.64,
    "APR": 5.92,
    "MAY": 5.71,
    "JUN": 5.64,
    "JUL": 5.96,
    "AUG": 6.44,
    "SEP": 6.16,
    "OCT": 5.72,
    "NOV": 5.05,
    "DEC": 5.42
  },
  {
    "LONG": -4.61,
    "LAT": -14.5,
    "CIDADE": "Mambaí",
    "UF": "GOIÁS",
    "anual": 5.76,
    "JAN": 5.57,
    "FEB": 5.79,
    "MAR": 5.57,
    "APR": 5.87,
    "MAY": 5.72,
    "JUN": 5.73,
    "JUL": 6.06,
    "AUG": 6.57,
    "SEP": 6.22,
    "OCT": 5.73,
    "NOV": 4.94,
    "DEC": 5.37
  },
  {
    "LONG": -49.71,
    "LAT": -14.43,
    "CIDADE": "Santa Terezinha de Goiás",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5,
    "FEB": 5.38,
    "MAR": 5.35,
    "APR": 5.69,
    "MAY": 5.63,
    "JUN": 5.46,
    "JUL": 5.73,
    "AUG": 6.29,
    "SEP": 5.78,
    "OCT": 5.48,
    "NOV": 4.95,
    "DEC": 4.99
  },
  {
    "LONG": -47.04,
    "LAT": -14.44,
    "CIDADE": "Flores de Goiás",
    "UF": "GOIÁS",
    "anual": 5.74,
    "JAN": 5.49,
    "FEB": 5.85,
    "MAR": 5.6,
    "APR": 5.83,
    "MAY": 5.71,
    "JUN": 5.73,
    "JUL": 6.03,
    "AUG": 6.42,
    "SEP": 6.22,
    "OCT": 5.71,
    "NOV": 5,
    "DEC": 5.35
  },
  {
    "LONG": -49.92,
    "LAT": -1.43,
    "CIDADE": "Uirapuru",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.99,
    "FEB": 5.35,
    "MAR": 5.29,
    "APR": 5.67,
    "MAY": 5.55,
    "JUN": 5.45,
    "JUL": 5.71,
    "AUG": 6.3,
    "SEP": 5.78,
    "OCT": 5.45,
    "NOV": 4.94,
    "DEC": 4.96
  },
  {
    "LONG": -49.66,
    "LAT": -14.27,
    "CIDADE": "Campos Verdes",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.05,
    "FEB": 5.38,
    "MAR": 5.37,
    "APR": 5.63,
    "MAY": 5.61,
    "JUN": 5.37,
    "JUL": 5.74,
    "AUG": 6.27,
    "SEP": 5.8,
    "OCT": 5.52,
    "NOV": 4.97,
    "DEC": 5
  },
  {
    "LONG": -49.39,
    "LAT": -14.29,
    "CIDADE": "Nova Iguaçu de Goiás",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.07,
    "FEB": 5.43,
    "MAR": 5.4,
    "APR": 5.6,
    "MAY": 5.64,
    "JUN": 5.43,
    "JUL": 5.81,
    "AUG": 6.32,
    "SEP": 5.83,
    "OCT": 5.55,
    "NOV": 4.95,
    "DEC": 4.98
  },
  {
    "LONG": -49.15,
    "LAT": -14.31,
    "CIDADE": "Campinorte",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.01,
    "FEB": 5.41,
    "MAR": 5.34,
    "APR": 5.56,
    "MAY": 5.66,
    "JUN": 5.48,
    "JUL": 5.75,
    "AUG": 6.22,
    "SEP": 5.81,
    "OCT": 5.49,
    "NOV": 4.88,
    "DEC": 4.94
  },
  {
    "LONG": -49.34,
    "LAT": -14.2,
    "CIDADE": "Alto Horizonte",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.05,
    "FEB": 5.43,
    "MAR": 5.38,
    "APR": 5.59,
    "MAY": 5.63,
    "JUN": 5.45,
    "JUL": 5.85,
    "AUG": 6.29,
    "SEP": 5.83,
    "OCT": 5.55,
    "NOV": 4.94,
    "DEC": 4.98
  },
  {
    "LONG": -48.08,
    "LAT": -14.15,
    "CIDADE": "Colinas do Sul",
    "UF": "GOIÁS",
    "anual": 5.53,
    "JAN": 5.02,
    "FEB": 5.34,
    "MAR": 5.34,
    "APR": 5.6,
    "MAY": 5.7,
    "JUN": 5.66,
    "JUL": 5.9,
    "AUG": 6.56,
    "SEP": 5.97,
    "OCT": 5.51,
    "NOV": 4.86,
    "DEC": 4.96
  },
  {
    "LONG": -5.03,
    "LAT": -14.1,
    "CIDADE": "Nova Crixás",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 5.01,
    "FEB": 5.4,
    "MAR": 5.26,
    "APR": 5.55,
    "MAY": 5.52,
    "JUN": 5.33,
    "JUL": 5.67,
    "AUG": 6.18,
    "SEP": 5.69,
    "OCT": 5.33,
    "NOV": 4.84,
    "DEC": 4.94
  },
  {
    "LONG": -47.52,
    "LAT": "#VALOR!",
    "CIDADE": "Alto Paraíso de Goiás",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.85,
    "FEB": 5.17,
    "MAR": 4.97,
    "APR": 5.35,
    "MAY": 5.62,
    "JUN": 5.83,
    "JUL": 6.26,
    "AUG": 6.83,
    "SEP": 6.04,
    "OCT": 5.22,
    "NOV": 4.39,
    "DEC": 4.74
  },
  {
    "LONG": -46.63,
    "LAT": -14.1,
    "CIDADE": "Iaciara",
    "UF": "GOIÁS",
    "anual": 5.76,
    "JAN": 5.54,
    "FEB": 5.78,
    "MAR": 5.58,
    "APR": 5.79,
    "MAY": 5.72,
    "JUN": 5.75,
    "JUL": 6.09,
    "AUG": 6.44,
    "SEP": 6.17,
    "OCT": 5.7,
    "NOV": 5.08,
    "DEC": 5.43
  },
  {
    "LONG": -46.36,
    "LAT": -14.09,
    "CIDADE": "Posse",
    "UF": "GOIÁS",
    "anual": 5.62,
    "JAN": 5.35,
    "FEB": 5.55,
    "MAR": 5.35,
    "APR": 5.54,
    "MAY": 5.59,
    "JUN": 5.67,
    "JUL": 6.06,
    "AUG": 6.58,
    "SEP": 6.13,
    "OCT": 5.58,
    "NOV": 4.81,
    "DEC": 5.21
  },
  {
    "LONG": -4.92,
    "LAT": -14.02,
    "CIDADE": "Mara Rosa",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.02,
    "FEB": 5.3,
    "MAR": 5.2,
    "APR": 5.53,
    "MAY": 5.63,
    "JUN": 5.5,
    "JUL": 5.86,
    "AUG": 6.39,
    "SEP": 5.84,
    "OCT": 5.42,
    "NOV": 4.84,
    "DEC": 4.9
  },
  {
    "LONG": -49.3,
    "LAT": -13.93,
    "CIDADE": "Amaralina",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.01,
    "FEB": 5.37,
    "MAR": 5.19,
    "APR": 5.6,
    "MAY": 5.62,
    "JUN": 5.39,
    "JUL": 5.81,
    "AUG": 6.45,
    "SEP": 5.84,
    "OCT": 5.43,
    "NOV": 4.88,
    "DEC": 4.94
  },
  {
    "LONG": -49.07,
    "LAT": -13.87,
    "CIDADE": "Estrela do Norte",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.96,
    "FEB": 5.28,
    "MAR": 5.18,
    "APR": 5.49,
    "MAY": 5.64,
    "JUN": 5.6,
    "JUL": 5.92,
    "AUG": 6.46,
    "SEP": 5.86,
    "OCT": 5.42,
    "NOV": 4.81,
    "DEC": 4.9
  },
  {
    "LONG": -46.49,
    "LAT": -13.95,
    "CIDADE": "Guarani de Goiás",
    "UF": "GOIÁS",
    "anual": 5.7,
    "JAN": 5.48,
    "FEB": 5.7,
    "MAR": 5.54,
    "APR": 5.73,
    "MAY": 5.68,
    "JUN": 5.7,
    "JUL": 6.03,
    "AUG": 6.55,
    "SEP": 6.16,
    "OCT": 5.55,
    "NOV": 4.98,
    "DEC": 5.31
  },
  {
    "LONG": -50.28,
    "LAT": -13.77,
    "CIDADE": "Mundo Novo",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.98,
    "FEB": 5.42,
    "MAR": 5.3,
    "APR": 5.54,
    "MAY": 5.55,
    "JUN": 5.43,
    "JUL": 5.81,
    "AUG": 6.18,
    "SEP": 5.82,
    "OCT": 5.39,
    "NOV": 4.88,
    "DEC": 4.97
  },
  {
    "LONG": -48.57,
    "LAT": -13.79,
    "CIDADE": "Campinaçu",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 4.88,
    "FEB": 5.22,
    "MAR": 5.13,
    "APR": 5.46,
    "MAY": 5.64,
    "JUN": 5.51,
    "JUL": 5.91,
    "AUG": 6.45,
    "SEP": 5.9,
    "OCT": 5.37,
    "NOV": 4.72,
    "DEC": 4.79
  },
  {
    "LONG": -4.75,
    "LAT": -13.79,
    "CIDADE": "Cavalcante",
    "UF": "GOIÁS",
    "anual": 5.55,
    "JAN": 5.09,
    "FEB": 5.3,
    "MAR": 5.16,
    "APR": 5.51,
    "MAY": 5.7,
    "JUN": 5.84,
    "JUL": 6.16,
    "AUG": 6.63,
    "SEP": 6.18,
    "OCT": 5.45,
    "NOV": 4.72,
    "DEC": 4.88
  },
  {
    "LONG": -47.27,
    "LAT": -13.78,
    "CIDADE": "Teresina de Goiás",
    "UF": "GOIÁS",
    "anual": 5.58,
    "JAN": 5.17,
    "FEB": 5.42,
    "MAR": 5.25,
    "APR": 5.49,
    "MAY": 5.67,
    "JUN": 5.74,
    "JUL": 6.16,
    "AUG": 6.57,
    "SEP": 6.19,
    "OCT": 5.55,
    "NOV": 4.77,
    "DEC": 5.02
  },
  {
    "LONG": -49.83,
    "LAT": -13.65,
    "CIDADE": "Bonópolis",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.98,
    "FEB": 5.36,
    "MAR": 5.28,
    "APR": 5.59,
    "MAY": 5.58,
    "JUN": 5.47,
    "JUL": 5.8,
    "AUG": 6.23,
    "SEP": 5.88,
    "OCT": 5.41,
    "NOV": 4.88,
    "DEC": 4.96
  },
  {
    "LONG": -49.27,
    "LAT": -13.73,
    "CIDADE": "Mutunópolis",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.98,
    "FEB": 5.33,
    "MAR": 5.2,
    "APR": 5.57,
    "MAY": 5.64,
    "JUN": 5.49,
    "JUL": 5.83,
    "AUG": 6.32,
    "SEP": 5.87,
    "OCT": 5.49,
    "NOV": 4.85,
    "DEC": 4.89
  },
  {
    "LONG": -49.01,
    "LAT": -13.71,
    "CIDADE": "Santa Tereza de Goiás",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 4.99,
    "FEB": 5.36,
    "MAR": 5.24,
    "APR": 5.58,
    "MAY": 5.64,
    "JUN": 5.53,
    "JUL": 5.83,
    "AUG": 6.39,
    "SEP": 5.86,
    "OCT": 5.46,
    "NOV": 4.85,
    "DEC": 4.89
  },
  {
    "LONG": -46.89,
    "LAT": -13.74,
    "CIDADE": "Nova Roma",
    "UF": "GOIÁS",
    "anual": 5.75,
    "JAN": 5.51,
    "FEB": 5.68,
    "MAR": 5.53,
    "APR": 5.81,
    "MAY": 5.77,
    "JUN": 5.83,
    "JUL": 6.13,
    "AUG": 6.5,
    "SEP": 6.18,
    "OCT": 5.67,
    "NOV": 5.01,
    "DEC": 5.36
  },
  {
    "LONG": -48.88,
    "LAT": -13.65,
    "CIDADE": "Formoso",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 4.97,
    "FEB": 5.34,
    "MAR": 5.22,
    "APR": 5.56,
    "MAY": 5.72,
    "JUN": 5.52,
    "JUL": 5.89,
    "AUG": 6.42,
    "SEP": 5.91,
    "OCT": 5.45,
    "NOV": 4.84,
    "DEC": 4.94
  },
  {
    "LONG": -48.74,
    "LAT": -13.51,
    "CIDADE": "Trombas",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 4.98,
    "FEB": 5.35,
    "MAR": 5.2,
    "APR": 5.55,
    "MAY": 5.71,
    "JUN": 5.59,
    "JUL": 5.94,
    "AUG": 6.5,
    "SEP": 5.95,
    "OCT": 5.47,
    "NOV": 4.84,
    "DEC": 4.92
  },
  {
    "LONG": -4.82,
    "LAT": -13.53,
    "CIDADE": "Minaçu",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.02,
    "FEB": 5.29,
    "MAR": 5.25,
    "APR": 5.51,
    "MAY": 5.65,
    "JUN": 5.41,
    "JUL": 5.79,
    "AUG": 6.29,
    "SEP": 5.86,
    "OCT": 5.39,
    "NOV": 4.81,
    "DEC": 4.9
  },
  {
    "LONG": -49.15,
    "LAT": -13.44,
    "CIDADE": "Porangatu",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.01,
    "FEB": 5.34,
    "MAR": 5.2,
    "APR": 5.61,
    "MAY": 5.61,
    "JUN": 5.52,
    "JUL": 5.82,
    "AUG": 6.37,
    "SEP": 5.92,
    "OCT": 5.47,
    "NOV": 4.86,
    "DEC": 4.95
  },
  {
    "LONG": -46.31,
    "LAT": -13.4,
    "CIDADE": "São Domingos",
    "UF": "GOIÁS",
    "anual": 5.68,
    "JAN": 5.48,
    "FEB": 5.54,
    "MAR": 5.38,
    "APR": 5.65,
    "MAY": 5.76,
    "JUN": 5.82,
    "JUL": 6.1,
    "AUG": 6.53,
    "SEP": 6.19,
    "OCT": 5.61,
    "NOV": 4.87,
    "DEC": 5.25
  },
  {
    "LONG": -50.16,
    "LAT": -13.27,
    "CIDADE": "São Miguel do Araguaia",
    "UF": "GOIÁS",
    "anual": 5.38,
    "JAN": 4.94,
    "FEB": 5.2,
    "MAR": 5.17,
    "APR": 5.4,
    "MAY": 5.53,
    "JUN": 5.5,
    "JUL": 5.78,
    "AUG": 6.15,
    "SEP": 5.8,
    "OCT": 5.3,
    "NOV": 4.84,
    "DEC": 4.93
  },
  {
    "LONG": -48.69,
    "LAT": -13.35,
    "CIDADE": "Montividiu do Norte",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.97,
    "FEB": 5.24,
    "MAR": 5.18,
    "APR": 5.53,
    "MAY": 5.59,
    "JUN": 5.61,
    "JUL": 5.95,
    "AUG": 6.48,
    "SEP": 5.96,
    "OCT": 5.39,
    "NOV": 4.76,
    "DEC": 4.85
  },
  {
    "LONG": -46.89,
    "LAT": -13.26,
    "CIDADE": "Monte Alegre de Goiás",
    "UF": "GOIÁS",
    "anual": 5.68,
    "JAN": 5.47,
    "FEB": 5.54,
    "MAR": 5.46,
    "APR": 5.78,
    "MAY": 5.75,
    "JUN": 5.71,
    "JUL": 6.04,
    "AUG": 6.41,
    "SEP": 6.11,
    "OCT": 5.58,
    "NOV": 5.02,
    "DEC": 5.33
  },
  {
    "LONG": -46.4,
    "LAT": -13.29,
    "CIDADE": "Divinópolis de Goiás",
    "UF": "GOIÁS",
    "anual": 5.65,
    "JAN": 5.47,
    "FEB": 5.54,
    "MAR": 5.28,
    "APR": 5.53,
    "MAY": 5.65,
    "JUN": 5.8,
    "JUL": 6.15,
    "AUG": 6.5,
    "SEP": 6.23,
    "OCT": 5.59,
    "NOV": 4.82,
    "DEC": 5.2
  },
  {
    "LONG": -49.51,
    "LAT": -13.24,
    "CIDADE": "Novo Planalto",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.96,
    "FEB": 5.24,
    "MAR": 5.16,
    "APR": 5.52,
    "MAY": 5.58,
    "JUN": 5.52,
    "JUL": 5.83,
    "AUG": 6.37,
    "SEP": 5.85,
    "OCT": 5.43,
    "NOV": 4.88,
    "DEC": 4.99
  },
  {
    "LONG": -46.77,
    "LAT": -13.04,
    "CIDADE": "Campos Belos",
    "UF": "GOIÁS",
    "anual": 5.66,
    "JAN": 5.43,
    "FEB": 5.44,
    "MAR": 5.32,
    "APR": 5.68,
    "MAY": 5.72,
    "JUN": 5.71,
    "JUL": 6.07,
    "AUG": 6.48,
    "SEP": 6.09,
    "OCT": 5.63,
    "NOV": 5.12,
    "DEC": 5.26
  },
  {
    "LONG": -45.93,
    "LAT": -9.11,
    "CIDADE": "Alto Parnaíba",
    "UF": "MARANHÃO",
    "anual": 5.57,
    "JAN": 5.05,
    "FEB": 5.09,
    "MAR": 5.12,
    "APR": 5.37,
    "MAY": 5.67,
    "JUN": 5.78,
    "JUL": 5.9,
    "AUG": 6.5,
    "SEP": 6.49,
    "OCT": 5.68,
    "NOV": 5.12,
    "DEC": 5.07
  },
  {
    "LONG": -45.75,
    "LAT": -8.47,
    "CIDADE": "Tasso Fragoso",
    "UF": "MARANHÃO",
    "anual": 5.53,
    "JAN": 4.91,
    "FEB": 5.06,
    "MAR": 5.11,
    "APR": 5.43,
    "MAY": 5.68,
    "JUN": 5.81,
    "JUL": 5.92,
    "AUG": 6.25,
    "SEP": 6.52,
    "OCT": 5.69,
    "NOV": 5.11,
    "DEC": 4.91
  },
  {
    "LONG": -46.04,
    "LAT": -7.53,
    "CIDADE": "Balsas",
    "UF": "MARANHÃO",
    "anual": 5.39,
    "JAN": 4.73,
    "FEB": 4.94,
    "MAR": 5.07,
    "APR": 5.26,
    "MAY": 5.48,
    "JUN": 5.68,
    "JUL": 5.68,
    "AUG": 6.04,
    "SEP": 6.46,
    "OCT": 5.6,
    "NOV": 4.97,
    "DEC": 4.81
  },
  {
    "LONG": -46.62,
    "LAT": -7.36,
    "CIDADE": "Riachão",
    "UF": "MARANHÃO",
    "anual": 5.26,
    "JAN": 4.56,
    "FEB": 4.77,
    "MAR": 4.82,
    "APR": 5.15,
    "MAY": 5.49,
    "JUN": 5.68,
    "JUL": 5.59,
    "AUG": 6.13,
    "SEP": 6.26,
    "OCT": 5.36,
    "NOV": 4.72,
    "DEC": 4.56
  },
  {
    "LONG": -47.46,
    "LAT": -7.34,
    "CIDADE": "Carolina",
    "UF": "MARANHÃO",
    "anual": 5.23,
    "JAN": 4.66,
    "FEB": 4.81,
    "MAR": 4.81,
    "APR": 4.99,
    "MAY": 5.39,
    "JUN": 5.73,
    "JUL": 5.66,
    "AUG": 6.19,
    "SEP": 6.09,
    "OCT": 5.24,
    "NOV": 4.65,
    "DEC": 4.54
  },
  {
    "LONG": -44.55,
    "LAT": -7.22,
    "CIDADE": "Benedito Leite",
    "UF": "MARANHÃO",
    "anual": 5.54,
    "JAN": 4.8,
    "FEB": 4.97,
    "MAR": 5.02,
    "APR": 5.26,
    "MAY": 5.57,
    "JUN": 5.76,
    "JUL": 5.96,
    "AUG": 6.27,
    "SEP": 6.62,
    "OCT": 5.92,
    "NOV": 5.34,
    "DEC": 5.04
  },
  {
    "LONG": -4.63,
    "LAT": -0.71,
    "CIDADE": "Nova Colinas",
    "UF": "MARANHÃO",
    "anual": 5.26,
    "JAN": 4.42,
    "FEB": 4.7,
    "MAR": 4.68,
    "APR": 5.05,
    "MAY": 5.36,
    "JUN": 5.74,
    "JUL": 5.81,
    "AUG": 6.34,
    "SEP": 6.31,
    "OCT": 5.38,
    "NOV": 4.79,
    "DEC": 4.54
  },
  {
    "LONG": -45.35,
    "LAT": -7.14,
    "CIDADE": "Sambaíba",
    "UF": "MARANHÃO",
    "anual": 5.46,
    "JAN": 4.66,
    "FEB": 4.91,
    "MAR": 5.02,
    "APR": 5.23,
    "MAY": 5.54,
    "JUN": 5.76,
    "JUL": 5.87,
    "AUG": 6.25,
    "SEP": 6.59,
    "OCT": 5.73,
    "NOV": 5.08,
    "DEC": 4.84
  },
  {
    "LONG": -45.14,
    "LAT": -7.09,
    "CIDADE": "Loreto",
    "UF": "MARANHÃO",
    "anual": 5.49,
    "JAN": 4.66,
    "FEB": 5.03,
    "MAR": 5.09,
    "APR": 5.3,
    "MAY": 5.51,
    "JUN": 5.68,
    "JUL": 5.94,
    "AUG": 6.3,
    "SEP": 6.6,
    "OCT": 5.78,
    "NOV": 5.13,
    "DEC": 4.89
  },
  {
    "LONG": -44.81,
    "LAT": -7.08,
    "CIDADE": "São Félix de Balsas",
    "UF": "MARANHÃO",
    "anual": 5.52,
    "JAN": 4.76,
    "FEB": 5,
    "MAR": 5.04,
    "APR": 5.26,
    "MAY": 5.54,
    "JUN": 5.76,
    "JUL": 5.85,
    "AUG": 6.28,
    "SEP": 6.62,
    "OCT": 5.88,
    "NOV": 5.26,
    "DEC": 5
  },
  {
    "LONG": -4.67,
    "LAT": -6.96,
    "CIDADE": "Feira Nova do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.44,
    "FEB": 4.62,
    "MAR": 4.73,
    "APR": 4.99,
    "MAY": 5.28,
    "JUN": 5.7,
    "JUL": 5.8,
    "AUG": 6.28,
    "SEP": 6.24,
    "OCT": 5.33,
    "NOV": 4.67,
    "DEC": 4.52
  },
  {
    "LONG": -46.18,
    "LAT": -6.96,
    "CIDADE": "Fortaleza dos Nogueiras",
    "UF": "MARANHÃO",
    "anual": 5.27,
    "JAN": 4.44,
    "FEB": 4.71,
    "MAR": 4.73,
    "APR": 5.01,
    "MAY": 5.36,
    "JUN": 5.71,
    "JUL": 5.82,
    "AUG": 6.34,
    "SEP": 6.34,
    "OCT": 5.48,
    "NOV": 4.77,
    "DEC": 4.54
  },
  {
    "LONG": -45.48,
    "LAT": -7.02,
    "CIDADE": "São Raimundo das Mangabeiras",
    "UF": "MARANHÃO",
    "anual": 5.4,
    "JAN": 4.55,
    "FEB": 4.79,
    "MAR": 4.87,
    "APR": 5.13,
    "MAY": 5.47,
    "JUN": 5.72,
    "JUL": 5.85,
    "AUG": 6.35,
    "SEP": 6.54,
    "OCT": 5.69,
    "NOV": 5.03,
    "DEC": 4.75
  },
  {
    "LONG": -46.53,
    "LAT": -6.82,
    "CIDADE": "São Pedro dos Crentes",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.44,
    "FEB": 4.67,
    "MAR": 4.74,
    "APR": 4.94,
    "MAY": 5.25,
    "JUN": 5.64,
    "JUL": 5.77,
    "AUG": 6.24,
    "SEP": 6.21,
    "OCT": 5.41,
    "NOV": 4.71,
    "DEC": 4.61
  },
  {
    "LONG": -44.64,
    "LAT": -6.81,
    "CIDADE": "São Domingos do Azeitão",
    "UF": "MARANHÃO",
    "anual": 5.41,
    "JAN": 4.56,
    "FEB": 4.85,
    "MAR": 4.94,
    "APR": 5.08,
    "MAY": 5.36,
    "JUN": 5.61,
    "JUL": 5.89,
    "AUG": 6.26,
    "SEP": 6.56,
    "OCT": 5.78,
    "NOV": 5.13,
    "DEC": 4.87
  },
  {
    "LONG": -43.03,
    "LAT": -6.76,
    "CIDADE": "Barão de Grajaú",
    "UF": "MARANHÃO",
    "anual": 5.67,
    "JAN": 5.02,
    "FEB": 5.18,
    "MAR": 5.26,
    "APR": 5.32,
    "MAY": 5.48,
    "JUN": 5.69,
    "JUL": 5.89,
    "AUG": 6.32,
    "SEP": 6.63,
    "OCT": 6.23,
    "NOV": 5.75,
    "DEC": 5.29
  },
  {
    "LONG": -44.05,
    "LAT": -6.73,
    "CIDADE": "Nova Iorque",
    "UF": "MARANHÃO",
    "anual": 5.54,
    "JAN": 4.69,
    "FEB": 4.93,
    "MAR": 5.14,
    "APR": 5.25,
    "MAY": 5.46,
    "JUN": 5.79,
    "JUL": 5.9,
    "AUG": 6.22,
    "SEP": 6.59,
    "OCT": 6.07,
    "NOV": 5.44,
    "DEC": 5.02
  },
  {
    "LONG": -47.44,
    "LAT": -6.56,
    "CIDADE": "Estreito",
    "UF": "MARANHÃO",
    "anual": 5.2,
    "JAN": 4.62,
    "FEB": 4.79,
    "MAR": 4.89,
    "APR": 5.04,
    "MAY": 5.17,
    "JUN": 5.55,
    "JUL": 5.73,
    "AUG": 6.1,
    "SEP": 5.95,
    "OCT": 5.25,
    "NOV": 4.69,
    "DEC": 4.56
  },
  {
    "LONG": -44.08,
    "LAT": -6.6,
    "CIDADE": "Pastos Bons",
    "UF": "MARANHÃO",
    "anual": 5.5,
    "JAN": 4.68,
    "FEB": 4.87,
    "MAR": 4.97,
    "APR": 5.14,
    "MAY": 5.36,
    "JUN": 5.73,
    "JUL": 5.95,
    "AUG": 6.44,
    "SEP": 6.65,
    "OCT": 5.95,
    "NOV": 5.34,
    "DEC": 4.96
  },
  {
    "LONG": -47.06,
    "LAT": -6.47,
    "CIDADE": "São João do Paraíso",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.47,
    "FEB": 4.68,
    "MAR": 4.7,
    "APR": 4.85,
    "MAY": 5.16,
    "JUN": 5.6,
    "JUL": 5.74,
    "AUG": 6.24,
    "SEP": 5.99,
    "OCT": 5.25,
    "NOV": 4.64,
    "DEC": 4.56
  },
  {
    "LONG": -44.19,
    "LAT": -6.48,
    "CIDADE": "Sucupira do Norte",
    "UF": "MARANHÃO",
    "anual": 5.45,
    "JAN": 4.5,
    "FEB": 4.8,
    "MAR": 4.85,
    "APR": 5.08,
    "MAY": 5.38,
    "JUN": 5.74,
    "JUL": 5.99,
    "AUG": 6.51,
    "SEP": 6.61,
    "OCT": 5.86,
    "NOV": 5.22,
    "DEC": 4.83
  },
  {
    "LONG": -4.37,
    "LAT": -6.49,
    "CIDADE": "São João dos Patos",
    "UF": "MARANHÃO",
    "anual": 5.46,
    "JAN": 4.57,
    "FEB": 4.85,
    "MAR": 4.93,
    "APR": 5.05,
    "MAY": 5.31,
    "JUN": 5.7,
    "JUL": 5.97,
    "AUG": 6.47,
    "SEP": 6.58,
    "OCT": 5.9,
    "NOV": 5.32,
    "DEC": 4.87
  },
  {
    "LONG": -46.19,
    "LAT": -6.44,
    "CIDADE": "Formosa da Serra Negra",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.51,
    "FEB": 4.7,
    "MAR": 4.78,
    "APR": 4.86,
    "MAY": 5.23,
    "JUN": 5.59,
    "JUL": 5.78,
    "AUG": 6.28,
    "SEP": 6.14,
    "OCT": 5.37,
    "NOV": 4.75,
    "DEC": 4.59
  },
  {
    "LONG": -44.37,
    "LAT": -0.64,
    "CIDADE": "Mirador",
    "UF": "MARANHÃO",
    "anual": 5.45,
    "JAN": 4.58,
    "FEB": 4.84,
    "MAR": 4.94,
    "APR": 5.04,
    "MAY": 5.33,
    "JUN": 5.7,
    "JUL": 5.93,
    "AUG": 6.49,
    "SEP": 6.59,
    "OCT": 5.83,
    "NOV": 5.25,
    "DEC": 4.86
  },
  {
    "LONG": -43.99,
    "LAT": -0.64,
    "CIDADE": "Paraibano",
    "UF": "MARANHÃO",
    "anual": 5.48,
    "JAN": 4.61,
    "FEB": 4.82,
    "MAR": 4.94,
    "APR": 5.03,
    "MAY": 5.3,
    "JUN": 5.68,
    "JUL": 5.97,
    "AUG": 6.52,
    "SEP": 6.65,
    "OCT": 5.91,
    "NOV": 5.37,
    "DEC": 4.91
  },
  {
    "LONG": -43.55,
    "LAT": -6.42,
    "CIDADE": "Sucupira do Riachão",
    "UF": "MARANHÃO",
    "anual": 5.52,
    "JAN": 4.63,
    "FEB": 4.95,
    "MAR": 4.99,
    "APR": 5.12,
    "MAY": 5.33,
    "JUN": 5.68,
    "JUL": 6,
    "AUG": 6.53,
    "SEP": 6.56,
    "OCT": 5.98,
    "NOV": 5.47,
    "DEC": 4.94
  },
  {
    "LONG": -47.4,
    "LAT": -6.34,
    "CIDADE": "Porto Franco",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.59,
    "FEB": 4.77,
    "MAR": 4.87,
    "APR": 5.03,
    "MAY": 5.14,
    "JUN": 5.48,
    "JUL": 5.65,
    "AUG": 6.12,
    "SEP": 5.84,
    "OCT": 5.19,
    "NOV": 4.71,
    "DEC": 4.59
  },
  {
    "LONG": -47.36,
    "LAT": -6.17,
    "CIDADE": "Campestre do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.57,
    "FEB": 4.77,
    "MAR": 4.87,
    "APR": 4.97,
    "MAY": 5.1,
    "JUN": 5.47,
    "JUL": 5.64,
    "AUG": 6.1,
    "SEP": 5.78,
    "OCT": 5.2,
    "NOV": 4.72,
    "DEC": 4.56
  },
  {
    "LONG": -47.04,
    "LAT": -6.19,
    "CIDADE": "Lajeado Novo",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.5,
    "FEB": 4.69,
    "MAR": 4.77,
    "APR": 4.97,
    "MAY": 5.15,
    "JUN": 5.58,
    "JUL": 5.7,
    "AUG": 6.17,
    "SEP": 5.9,
    "OCT": 5.23,
    "NOV": 4.71,
    "DEC": 4.59
  },
  {
    "LONG": -44.9,
    "LAT": -6.16,
    "CIDADE": "Fernando Falcão",
    "UF": "MARANHÃO",
    "anual": 5.35,
    "JAN": 4.45,
    "FEB": 4.78,
    "MAR": 4.85,
    "APR": 5.03,
    "MAY": 5.22,
    "JUN": 5.61,
    "JUL": 5.86,
    "AUG": 6.45,
    "SEP": 6.52,
    "OCT": 5.64,
    "NOV": 5.03,
    "DEC": 4.75
  },
  {
    "LONG": -43.78,
    "LAT": -6.18,
    "CIDADE": "Passagem Franca",
    "UF": "MARANHÃO",
    "anual": 5.48,
    "JAN": 4.6,
    "FEB": 4.86,
    "MAR": 4.89,
    "APR": 5.05,
    "MAY": 5.28,
    "JUN": 5.68,
    "JUL": 5.97,
    "AUG": 6.54,
    "SEP": 6.59,
    "OCT": 5.95,
    "NOV": 5.38,
    "DEC": 4.91
  },
  {
    "LONG": -42.86,
    "LAT": -6.25,
    "CIDADE": "São Francisco do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.65,
    "JAN": 4.86,
    "FEB": 5.1,
    "MAR": 5.2,
    "APR": 5.26,
    "MAY": 5.5,
    "JUN": 5.72,
    "JUL": 5.96,
    "AUG": 6.36,
    "SEP": 6.6,
    "OCT": 6.16,
    "NOV": 5.78,
    "DEC": 5.26
  },
  {
    "LONG": -44.25,
    "LAT": -6.03,
    "CIDADE": "Colinas",
    "UF": "MARANHÃO",
    "anual": 5.43,
    "JAN": 4.54,
    "FEB": 4.86,
    "MAR": 4.99,
    "APR": 5.04,
    "MAY": 5.24,
    "JUN": 5.64,
    "JUL": 5.89,
    "AUG": 6.42,
    "SEP": 6.53,
    "OCT": 5.79,
    "NOV": 5.31,
    "DEC": 4.9
  },
  {
    "LONG": -43.53,
    "LAT": -6.05,
    "CIDADE": "Lagoa do Mato",
    "UF": "MARANHÃO",
    "anual": 5.52,
    "JAN": 4.72,
    "FEB": 4.97,
    "MAR": 4.97,
    "APR": 5.16,
    "MAY": 5.34,
    "JUN": 5.68,
    "JUL": 5.88,
    "AUG": 6.41,
    "SEP": 6.53,
    "OCT": 6.02,
    "NOV": 5.51,
    "DEC": 5.04
  },
  {
    "LONG": -47.39,
    "LAT": -5.93,
    "CIDADE": "Ribamar Fiquene",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.49,
    "FEB": 4.64,
    "MAR": 4.76,
    "APR": 4.9,
    "MAY": 5.06,
    "JUN": 5.43,
    "JUL": 5.63,
    "AUG": 6.04,
    "SEP": 5.7,
    "OCT": 5.18,
    "NOV": 4.77,
    "DEC": 4.59
  },
  {
    "LONG": -46.7,
    "LAT": -5.88,
    "CIDADE": "Sítio Novo",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.57,
    "FEB": 4.72,
    "MAR": 4.85,
    "APR": 4.93,
    "MAY": 5.08,
    "JUN": 5.45,
    "JUL": 5.66,
    "AUG": 6.08,
    "SEP": 5.86,
    "OCT": 5.26,
    "NOV": 4.75,
    "DEC": 4.67
  },
  {
    "LONG": -47.07,
    "LAT": -5.83,
    "CIDADE": "Montes Altos",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.54,
    "FEB": 4.71,
    "MAR": 4.8,
    "APR": 4.98,
    "MAY": 5.15,
    "JUN": 5.51,
    "JUL": 5.64,
    "AUG": 6.04,
    "SEP": 5.79,
    "OCT": 5.18,
    "NOV": 4.8,
    "DEC": 4.64
  },
  {
    "LONG": -46.16,
    "LAT": -5.82,
    "CIDADE": "Grajaú",
    "UF": "MARANHÃO",
    "anual": 5.14,
    "JAN": 4.49,
    "FEB": 4.72,
    "MAR": 4.77,
    "APR": 4.92,
    "MAY": 5.1,
    "JUN": 5.39,
    "JUL": 5.63,
    "AUG": 6.06,
    "SEP": 5.97,
    "OCT": 5.34,
    "NOV": 4.77,
    "DEC": 4.58
  },
  {
    "LONG": -44.22,
    "LAT": -5.82,
    "CIDADE": "Jatobá",
    "UF": "MARANHÃO",
    "anual": 5.36,
    "JAN": 4.53,
    "FEB": 4.77,
    "MAR": 4.95,
    "APR": 5.01,
    "MAY": 5.16,
    "JUN": 5.54,
    "JUL": 5.82,
    "AUG": 6.37,
    "SEP": 6.44,
    "OCT": 5.7,
    "NOV": 5.18,
    "DEC": 4.86
  },
  {
    "LONG": -43.84,
    "LAT": -5.83,
    "CIDADE": "Buriti Bravo",
    "UF": "MARANHÃO",
    "anual": 5.45,
    "JAN": 4.64,
    "FEB": 4.89,
    "MAR": 4.98,
    "APR": 5.04,
    "MAY": 5.28,
    "JUN": 5.63,
    "JUL": 5.85,
    "AUG": 6.45,
    "SEP": 6.49,
    "OCT": 5.88,
    "NOV": 5.35,
    "DEC": 4.95
  },
  {
    "LONG": -4.74,
    "LAT": -5.75,
    "CIDADE": "Governador Edison Lobão",
    "UF": "MARANHÃO",
    "anual": 5.06,
    "JAN": 4.46,
    "FEB": 4.59,
    "MAR": 4.75,
    "APR": 4.91,
    "MAY": 5.04,
    "JUN": 5.41,
    "JUL": 5.58,
    "AUG": 5.93,
    "SEP": 5.65,
    "OCT": 5.11,
    "NOV": 4.75,
    "DEC": 4.58
  },
  {
    "LONG": -44.16,
    "LAT": -5.73,
    "CIDADE": "Fortuna",
    "UF": "MARANHÃO",
    "anual": 5.38,
    "JAN": 4.55,
    "FEB": 4.85,
    "MAR": 4.98,
    "APR": 5.07,
    "MAY": 5.19,
    "JUN": 5.55,
    "JUL": 5.8,
    "AUG": 6.36,
    "SEP": 6.4,
    "OCT": 5.71,
    "NOV": 5.2,
    "DEC": 4.89
  },
  {
    "LONG": -43.11,
    "LAT": -5.68,
    "CIDADE": "Parnarama",
    "UF": "MARANHÃO",
    "anual": 5.56,
    "JAN": 4.77,
    "FEB": 5.04,
    "MAR": 5.09,
    "APR": 5.16,
    "MAY": 5.23,
    "JUN": 5.63,
    "JUL": 5.87,
    "AUG": 6.41,
    "SEP": 6.56,
    "OCT": 6.12,
    "NOV": 5.61,
    "DEC": 5.29
  },
  {
    "LONG": -47.01,
    "LAT": -5.6,
    "CIDADE": "Buritirana",
    "UF": "MARANHÃO",
    "anual": 5.08,
    "JAN": 4.44,
    "FEB": 4.61,
    "MAR": 4.72,
    "APR": 4.92,
    "MAY": 5.02,
    "JUN": 5.43,
    "JUL": 5.59,
    "AUG": 5.93,
    "SEP": 5.7,
    "OCT": 5.21,
    "NOV": 4.76,
    "DEC": 4.59
  },
  {
    "LONG": -46.75,
    "LAT": -5.57,
    "CIDADE": "Amarante do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.43,
    "FEB": 4.68,
    "MAR": 4.74,
    "APR": 4.94,
    "MAY": 5.03,
    "JUN": 5.39,
    "JUL": 5.6,
    "AUG": 5.94,
    "SEP": 5.78,
    "OCT": 5.22,
    "NOV": 4.8,
    "DEC": 4.61
  },
  {
    "LONG": -44.38,
    "LAT": -5.58,
    "CIDADE": "São Domingos do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.34,
    "JAN": 4.57,
    "FEB": 4.69,
    "MAR": 4.94,
    "APR": 5.05,
    "MAY": 5.15,
    "JUN": 5.48,
    "JUL": 5.75,
    "AUG": 6.29,
    "SEP": 6.37,
    "OCT": 5.67,
    "NOV": 5.19,
    "DEC": 4.87
  },
  {
    "LONG": -47.48,
    "LAT": -5.52,
    "CIDADE": "Imperatriz",
    "UF": "MARANHÃO",
    "anual": 5.05,
    "JAN": 4.48,
    "FEB": 4.63,
    "MAR": 4.79,
    "APR": 4.93,
    "MAY": 4.97,
    "JUN": 5.4,
    "JUL": 5.49,
    "AUG": 5.88,
    "SEP": 5.62,
    "OCT": 5.1,
    "NOV": 4.76,
    "DEC": 4.56
  },
  {
    "LONG": -4.74,
    "LAT": -5.53,
    "CIDADE": "Davinópolis",
    "UF": "MARANHÃO",
    "anual": 5.08,
    "JAN": 4.49,
    "FEB": 4.63,
    "MAR": 4.79,
    "APR": 4.97,
    "MAY": 5.03,
    "JUN": 5.46,
    "JUL": 5.57,
    "AUG": 5.93,
    "SEP": 5.64,
    "OCT": 5.13,
    "NOV": 4.75,
    "DEC": 4.54
  },
  {
    "LONG": -45.25,
    "LAT": -5.5,
    "CIDADE": "Barra do Corda",
    "UF": "MARANHÃO",
    "anual": 5.23,
    "JAN": 4.56,
    "FEB": 4.79,
    "MAR": 4.92,
    "APR": 4.92,
    "MAY": 5.1,
    "JUN": 5.36,
    "JUL": 5.63,
    "AUG": 6.1,
    "SEP": 6.14,
    "OCT": 5.45,
    "NOV": 5.02,
    "DEC": 4.73
  },
  {
    "LONG": -44.56,
    "LAT": -5.5,
    "CIDADE": "Santa Filomena do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.3,
    "JAN": 4.6,
    "FEB": 4.72,
    "MAR": 4.92,
    "APR": 4.93,
    "MAY": 5.11,
    "JUN": 5.46,
    "JUL": 5.72,
    "AUG": 6.27,
    "SEP": 6.32,
    "OCT": 5.58,
    "NOV": 5.18,
    "DEC": 4.85
  },
  {
    "LONG": -44.07,
    "LAT": -5.47,
    "CIDADE": "Governador Luiz Rocha",
    "UF": "MARANHÃO",
    "anual": 5.42,
    "JAN": 4.59,
    "FEB": 4.85,
    "MAR": 5.02,
    "APR": 5.08,
    "MAY": 5.23,
    "JUN": 5.48,
    "JUL": 5.83,
    "AUG": 6.38,
    "SEP": 6.47,
    "OCT": 5.82,
    "NOV": 5.31,
    "DEC": 4.94
  },
  {
    "LONG": -43.2,
    "LAT": -0.55,
    "CIDADE": "Matões",
    "UF": "MARANHÃO",
    "anual": 5.53,
    "JAN": 4.78,
    "FEB": 4.97,
    "MAR": 5.02,
    "APR": 5.07,
    "MAY": 5.25,
    "JUN": 5.6,
    "JUL": 5.85,
    "AUG": 6.41,
    "SEP": 6.57,
    "OCT": 6.08,
    "NOV": 5.59,
    "DEC": 5.21
  },
  {
    "LONG": -47.41,
    "LAT": -0.54,
    "CIDADE": "João Lisboa",
    "UF": "MARANHÃO",
    "anual": 5.04,
    "JAN": 4.46,
    "FEB": 4.61,
    "MAR": 4.77,
    "APR": 4.93,
    "MAY": 4.99,
    "JUN": 5.43,
    "JUL": 5.55,
    "AUG": 5.88,
    "SEP": 5.58,
    "OCT": 5.08,
    "NOV": 4.72,
    "DEC": 4.54
  },
  {
    "LONG": -47.3,
    "LAT": -5.45,
    "CIDADE": "Senador La Rocque",
    "UF": "MARANHÃO",
    "anual": 5.04,
    "JAN": 4.44,
    "FEB": 4.57,
    "MAR": 4.72,
    "APR": 4.91,
    "MAY": 5.01,
    "JUN": 5.46,
    "JUL": 5.56,
    "AUG": 5.87,
    "SEP": 5.62,
    "OCT": 5.11,
    "NOV": 4.73,
    "DEC": 4.53
  },
  {
    "LONG": -4.56,
    "LAT": -5.37,
    "CIDADE": "Jenipapo dos Vieiras",
    "UF": "MARANHÃO",
    "anual": 5.12,
    "JAN": 4.33,
    "FEB": 4.61,
    "MAR": 4.78,
    "APR": 4.92,
    "MAY": 5.02,
    "JUN": 5.37,
    "JUL": 5.59,
    "AUG": 6.09,
    "SEP": 5.99,
    "OCT": 5.32,
    "NOV": 4.82,
    "DEC": 4.59
  },
  {
    "LONG": -44.34,
    "LAT": -5.41,
    "CIDADE": "Graça Aranha",
    "UF": "MARANHÃO",
    "anual": 5.34,
    "JAN": 4.54,
    "FEB": 4.75,
    "MAR": 4.9,
    "APR": 5,
    "MAY": 5.18,
    "JUN": 5.45,
    "JUL": 5.74,
    "AUG": 6.32,
    "SEP": 6.38,
    "OCT": 5.7,
    "NOV": 5.22,
    "DEC": 4.86
  },
  {
    "LONG": -44.64,
    "LAT": -5.26,
    "CIDADE": "Tuntum",
    "UF": "MARANHÃO",
    "anual": 5.3,
    "JAN": 4.62,
    "FEB": 4.84,
    "MAR": 4.92,
    "APR": 5,
    "MAY": 5.06,
    "JUN": 5.41,
    "JUL": 5.61,
    "AUG": 6.18,
    "SEP": 6.23,
    "OCT": 5.57,
    "NOV": 5.23,
    "DEC": 4.87
  },
  {
    "LONG": -44.5,
    "LAT": -5.29,
    "CIDADE": "Presidente Dutra",
    "UF": "MARANHÃO",
    "anual": 5.32,
    "JAN": 4.67,
    "FEB": 4.79,
    "MAR": 4.95,
    "APR": 5.01,
    "MAY": 5.14,
    "JUN": 5.4,
    "JUL": 5.66,
    "AUG": 6.22,
    "SEP": 6.29,
    "OCT": 5.63,
    "NOV": 5.2,
    "DEC": 4.89
  },
  {
    "LONG": -44.25,
    "LAT": -5.32,
    "CIDADE": "Governador Eugênio Barros",
    "UF": "MARANHÃO",
    "anual": 5.34,
    "JAN": 4.55,
    "FEB": 4.78,
    "MAR": 4.94,
    "APR": 5,
    "MAY": 5.11,
    "JUN": 5.43,
    "JUL": 5.75,
    "AUG": 6.25,
    "SEP": 6.38,
    "OCT": 5.74,
    "NOV": 5.24,
    "DEC": 4.86
  },
  {
    "LONG": -44.06,
    "LAT": "#VALOR!",
    "CIDADE": "Senador Alexandre Costa",
    "UF": "MARANHÃO",
    "anual": 5.36,
    "JAN": 4.54,
    "FEB": 4.81,
    "MAR": 4.96,
    "APR": 5,
    "MAY": 5.14,
    "JUN": 5.43,
    "JUL": 5.71,
    "AUG": 6.27,
    "SEP": 6.41,
    "OCT": 5.81,
    "NOV": 5.33,
    "DEC": 4.91
  },
  {
    "LONG": -4.81,
    "LAT": -0.52,
    "CIDADE": "Vila Nova dos Martírios",
    "UF": "MARANHÃO",
    "anual": 4.95,
    "JAN": 4.3,
    "FEB": 4.53,
    "MAR": 4.68,
    "APR": 4.92,
    "MAY": 4.96,
    "JUN": 5.45,
    "JUL": 5.45,
    "AUG": 5.81,
    "SEP": 5.46,
    "OCT": 4.88,
    "NOV": 4.6,
    "DEC": 4.36
  },
  {
    "LONG": -47.78,
    "LAT": -5.18,
    "CIDADE": "Cidelândia",
    "UF": "MARANHÃO",
    "anual": 4.97,
    "JAN": 4.36,
    "FEB": 4.56,
    "MAR": 4.66,
    "APR": 4.87,
    "MAY": 4.93,
    "JUN": 5.38,
    "JUL": 5.51,
    "AUG": 5.79,
    "SEP": 5.52,
    "OCT": 4.97,
    "NOV": 4.67,
    "DEC": 4.44
  },
  {
    "LONG": -48.43,
    "LAT": -5.09,
    "CIDADE": "São Pedro da Água Branca",
    "UF": "MARANHÃO",
    "anual": 4.9,
    "JAN": 4.26,
    "FEB": 4.49,
    "MAR": 4.64,
    "APR": 4.81,
    "MAY": 4.87,
    "JUN": 5.3,
    "JUL": 5.41,
    "AUG": 5.78,
    "SEP": 5.4,
    "OCT": 4.8,
    "NOV": 4.65,
    "DEC": 4.35
  },
  {
    "LONG": -47.39,
    "LAT": -5.13,
    "CIDADE": "São Francisco do Brejão",
    "UF": "MARANHÃO",
    "anual": 4.93,
    "JAN": 4.35,
    "FEB": 4.61,
    "MAR": 4.66,
    "APR": 4.8,
    "MAY": 4.9,
    "JUN": 5.23,
    "JUL": 5.38,
    "AUG": 5.72,
    "SEP": 5.46,
    "OCT": 4.92,
    "NOV": 4.6,
    "DEC": 4.49
  },
  {
    "LONG": -45.79,
    "LAT": -5.14,
    "CIDADE": "Itaipava do Grajaú",
    "UF": "MARANHÃO",
    "anual": 5.14,
    "JAN": 4.51,
    "FEB": 4.77,
    "MAR": 4.88,
    "APR": 4.95,
    "MAY": 4.98,
    "JUN": 5.3,
    "JUL": 5.55,
    "AUG": 5.91,
    "SEP": 5.93,
    "OCT": 5.34,
    "NOV": 4.91,
    "DEC": 4.7
  },
  {
    "LONG": -45.08,
    "LAT": -5.11,
    "CIDADE": "São Raimundo do Doca Bezerra",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.51,
    "FEB": 4.83,
    "MAR": 4.97,
    "APR": 5.01,
    "MAY": 5.08,
    "JUN": 5.28,
    "JUL": 5.53,
    "AUG": 6.05,
    "SEP": 6.15,
    "OCT": 5.46,
    "NOV": 5.04,
    "DEC": 4.73
  },
  {
    "LONG": -44.58,
    "LAT": -5.06,
    "CIDADE": "São José dos Basílios",
    "UF": "MARANHÃO",
    "anual": 5.26,
    "JAN": 4.63,
    "FEB": 4.76,
    "MAR": 4.89,
    "APR": 4.93,
    "MAY": 5.11,
    "JUN": 5.38,
    "JUL": 5.61,
    "AUG": 6.14,
    "SEP": 6.24,
    "OCT": 5.56,
    "NOV": 5.16,
    "DEC": 4.74
  },
  {
    "LONG": -44.3,
    "LAT": -5.15,
    "CIDADE": "Gonçalves Dias",
    "UF": "MARANHÃO",
    "anual": 5.34,
    "JAN": 4.63,
    "FEB": 4.83,
    "MAR": 4.91,
    "APR": 4.96,
    "MAY": 5.13,
    "JUN": 5.38,
    "JUL": 5.7,
    "AUG": 6.28,
    "SEP": 6.37,
    "OCT": 5.72,
    "NOV": 5.27,
    "DEC": 4.86
  },
  {
    "LONG": -43.82,
    "LAT": -5.11,
    "CIDADE": "São João do Soter",
    "UF": "MARANHÃO",
    "anual": 5.41,
    "JAN": 4.65,
    "FEB": 4.9,
    "MAR": 4.99,
    "APR": 5.03,
    "MAY": 5.2,
    "JUN": 5.35,
    "JUL": 5.71,
    "AUG": 6.36,
    "SEP": 6.47,
    "OCT": 5.91,
    "NOV": 5.41,
    "DEC": 4.94
  },
  {
    "LONG": -42.83,
    "LAT": -5.1,
    "CIDADE": "Timon",
    "UF": "MARANHÃO",
    "anual": 5.58,
    "JAN": 4.94,
    "FEB": 5.12,
    "MAR": 5.14,
    "APR": 5.13,
    "MAY": 5.23,
    "JUN": 5.41,
    "JUL": 5.69,
    "AUG": 6.19,
    "SEP": 6.49,
    "OCT": 6.25,
    "NOV": 5.97,
    "DEC": 5.43
  },
  {
    "LONG": -4.54,
    "LAT": -4.99,
    "CIDADE": "Lagoa Grande do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.09,
    "JAN": 4.43,
    "FEB": 4.67,
    "MAR": 4.79,
    "APR": 4.88,
    "MAY": 4.95,
    "JUN": 5.21,
    "JUL": 5.46,
    "AUG": 5.88,
    "SEP": 5.94,
    "OCT": 5.33,
    "NOV": 4.89,
    "DEC": 4.64
  },
  {
    "LONG": -44.99,
    "LAT": -5.02,
    "CIDADE": "São Roberto",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.53,
    "FEB": 4.81,
    "MAR": 4.93,
    "APR": 5.02,
    "MAY": 5.13,
    "JUN": 5.29,
    "JUL": 5.51,
    "AUG": 6.05,
    "SEP": 6.13,
    "OCT": 5.5,
    "NOV": 5.07,
    "DEC": 4.72
  },
  {
    "LONG": -44.7,
    "LAT": -4.99,
    "CIDADE": "Joselândia",
    "UF": "MARANHÃO",
    "anual": 5.27,
    "JAN": 4.63,
    "FEB": 4.82,
    "MAR": 4.95,
    "APR": 4.99,
    "MAY": 5.09,
    "JUN": 5.35,
    "JUL": 5.59,
    "AUG": 6.07,
    "SEP": 6.18,
    "OCT": 5.58,
    "NOV": 5.16,
    "DEC": 4.78
  },
  {
    "LONG": -44.44,
    "LAT": -5.04,
    "CIDADE": "Dom Pedro",
    "UF": "MARANHÃO",
    "anual": 5.29,
    "JAN": 4.63,
    "FEB": 4.79,
    "MAR": 4.87,
    "APR": 4.89,
    "MAY": 5.09,
    "JUN": 5.34,
    "JUL": 5.65,
    "AUG": 6.19,
    "SEP": 6.33,
    "OCT": 5.73,
    "NOV": 5.22,
    "DEC": 4.81
  },
  {
    "LONG": -44.28,
    "LAT": -5.02,
    "CIDADE": "Governador Archer",
    "UF": "MARANHÃO",
    "anual": 5.33,
    "JAN": 4.64,
    "FEB": 4.84,
    "MAR": 4.99,
    "APR": 4.97,
    "MAY": 5.13,
    "JUN": 5.34,
    "JUL": 5.62,
    "AUG": 6.19,
    "SEP": 6.31,
    "OCT": 5.75,
    "NOV": 5.32,
    "DEC": 4.88
  },
  {
    "LONG": -47.5,
    "LAT": -4.95,
    "CIDADE": "Açailândia",
    "UF": "MARANHÃO",
    "anual": 4.86,
    "JAN": 4.31,
    "FEB": 4.56,
    "MAR": 4.66,
    "APR": 4.84,
    "MAY": 4.82,
    "JUN": 5.18,
    "JUL": 5.21,
    "AUG": 5.54,
    "SEP": 5.34,
    "OCT": 4.86,
    "NOV": 4.58,
    "DEC": 4.44
  },
  {
    "LONG": -46.01,
    "LAT": -4.89,
    "CIDADE": "Arame",
    "UF": "MARANHÃO",
    "anual": 4.97,
    "JAN": 4.4,
    "FEB": 4.62,
    "MAR": 4.8,
    "APR": 4.78,
    "MAY": 4.89,
    "JUN": 5.14,
    "JUL": 5.38,
    "AUG": 5.72,
    "SEP": 5.72,
    "OCT": 5.11,
    "NOV": 4.63,
    "DEC": 4.51
  },
  {
    "LONG": -4.47,
    "LAT": -4.88,
    "CIDADE": "Esperantinópolis",
    "UF": "MARANHÃO",
    "anual": 5.25,
    "JAN": 4.58,
    "FEB": 4.85,
    "MAR": 4.95,
    "APR": 4.98,
    "MAY": 5.08,
    "JUN": 5.3,
    "JUL": 5.56,
    "AUG": 6.04,
    "SEP": 6.2,
    "OCT": 5.62,
    "NOV": 5.17,
    "DEC": 4.74
  },
  {
    "LONG": -44.36,
    "LAT": -4.87,
    "CIDADE": "Santo Antônio dos Lopes",
    "UF": "MARANHÃO",
    "anual": 5.31,
    "JAN": 4.67,
    "FEB": 4.79,
    "MAR": 4.94,
    "APR": 4.93,
    "MAY": 5.11,
    "JUN": 5.31,
    "JUL": 5.61,
    "AUG": 6.18,
    "SEP": 6.32,
    "OCT": 5.76,
    "NOV": 5.31,
    "DEC": 4.85
  },
  {
    "LONG": -43.36,
    "LAT": -4.87,
    "CIDADE": "Caxias",
    "UF": "MARANHÃO",
    "anual": 5.49,
    "JAN": 4.74,
    "FEB": 5.01,
    "MAR": 5.1,
    "APR": 5.11,
    "MAY": 5.16,
    "JUN": 5.38,
    "JUL": 5.65,
    "AUG": 6.21,
    "SEP": 6.44,
    "OCT": 6.13,
    "NOV": 5.78,
    "DEC": 5.24
  },
  {
    "LONG": -44.94,
    "LAT": -4.75,
    "CIDADE": "Poção de Pedras",
    "UF": "MARANHÃO",
    "anual": 5.19,
    "JAN": 4.54,
    "FEB": 4.79,
    "MAR": 4.85,
    "APR": 4.94,
    "MAY": 5,
    "JUN": 5.24,
    "JUL": 5.46,
    "AUG": 5.98,
    "SEP": 6.11,
    "OCT": 5.55,
    "NOV": 5.08,
    "DEC": 4.78
  },
  {
    "LONG": -44.86,
    "LAT": -4.66,
    "CIDADE": "Igarapé Grande",
    "UF": "MARANHÃO",
    "anual": 5.2,
    "JAN": 4.51,
    "FEB": 4.81,
    "MAR": 4.88,
    "APR": 4.93,
    "MAY": 5.01,
    "JUN": 5.25,
    "JUL": 5.48,
    "AUG": 5.96,
    "SEP": 6.11,
    "OCT": 5.56,
    "NOV": 5.11,
    "DEC": 4.77
  },
  {
    "LONG": -44.33,
    "LAT": -0.47,
    "CIDADE": "Capinzal do Norte",
    "UF": "MARANHÃO",
    "anual": 5.31,
    "JAN": 4.68,
    "FEB": 4.8,
    "MAR": 4.91,
    "APR": 4.95,
    "MAY": 5.04,
    "JUN": 5.26,
    "JUL": 5.59,
    "AUG": 6.12,
    "SEP": 6.3,
    "OCT": 5.83,
    "NOV": 5.34,
    "DEC": 4.93
  },
  {
    "LONG": -45.46,
    "LAT": -4.63,
    "CIDADE": "Marajá do Sena",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.49,
    "FEB": 4.76,
    "MAR": 4.92,
    "APR": 4.97,
    "MAY": 4.96,
    "JUN": 5.21,
    "JUL": 5.36,
    "AUG": 5.69,
    "SEP": 5.87,
    "OCT": 5.37,
    "NOV": 4.91,
    "DEC": 4.65
  },
  {
    "LONG": -45.13,
    "LAT": -4.56,
    "CIDADE": "Lago da Pedra",
    "UF": "MARANHÃO",
    "anual": 5.11,
    "JAN": 4.5,
    "FEB": 4.67,
    "MAR": 4.87,
    "APR": 4.93,
    "MAY": 4.88,
    "JUN": 5.2,
    "JUL": 5.38,
    "AUG": 5.82,
    "SEP": 5.96,
    "OCT": 5.45,
    "NOV": 4.95,
    "DEC": 4.67
  },
  {
    "LONG": -45.06,
    "LAT": -4.61,
    "CIDADE": "Lago do Junco",
    "UF": "MARANHÃO",
    "anual": 5.13,
    "JAN": 4.5,
    "FEB": 4.71,
    "MAR": 4.87,
    "APR": 4.93,
    "MAY": 4.92,
    "JUN": 5.22,
    "JUL": 5.39,
    "AUG": 5.89,
    "SEP": 6.01,
    "OCT": 5.46,
    "NOV": 5,
    "DEC": 4.7
  },
  {
    "LONG": -44.98,
    "LAT": -4.61,
    "CIDADE": "Lago dos Rodrigues",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.55,
    "FEB": 4.75,
    "MAR": 4.9,
    "APR": 4.92,
    "MAY": 4.96,
    "JUN": 5.18,
    "JUL": 5.36,
    "AUG": 5.88,
    "SEP": 6.03,
    "OCT": 5.48,
    "NOV": 5.02,
    "DEC": 4.79
  },
  {
    "LONG": -44.76,
    "LAT": -4.63,
    "CIDADE": "Bernardo do Mearim",
    "UF": "MARANHÃO",
    "anual": 5.2,
    "JAN": 4.57,
    "FEB": 4.73,
    "MAR": 4.88,
    "APR": 4.92,
    "MAY": 4.97,
    "JUN": 5.26,
    "JUL": 5.49,
    "AUG": 5.96,
    "SEP": 6.17,
    "OCT": 5.6,
    "NOV": 5.12,
    "DEC": 4.78
  },
  {
    "LONG": -4.46,
    "LAT": -4.57,
    "CIDADE": "Pedreiras",
    "UF": "MARANHÃO",
    "anual": 5.24,
    "JAN": 4.61,
    "FEB": 4.77,
    "MAR": 4.94,
    "APR": 4.94,
    "MAY": 4.98,
    "JUN": 5.27,
    "JUL": 5.49,
    "AUG": 5.96,
    "SEP": 6.21,
    "OCT": 5.65,
    "NOV": 5.17,
    "DEC": 4.85
  },
  {
    "LONG": -44.62,
    "LAT": -4.57,
    "CIDADE": "Trizidela do Vale",
    "UF": "MARANHÃO",
    "anual": 5.24,
    "JAN": 4.61,
    "FEB": 4.77,
    "MAR": 4.94,
    "APR": 4.94,
    "MAY": 4.98,
    "JUN": 5.27,
    "JUL": 5.49,
    "AUG": 5.96,
    "SEP": 6.21,
    "OCT": 5.65,
    "NOV": 5.17,
    "DEC": 4.85
  },
  {
    "LONG": -43.47,
    "LAT": -4.63,
    "CIDADE": "Aldeias Altas",
    "UF": "MARANHÃO",
    "anual": 5.5,
    "JAN": 4.81,
    "FEB": 4.99,
    "MAR": 5.11,
    "APR": 5.13,
    "MAY": 5.21,
    "JUN": 5.42,
    "JUL": 5.66,
    "AUG": 6.26,
    "SEP": 6.42,
    "OCT": 6.07,
    "NOV": 5.68,
    "DEC": 5.23
  },
  {
    "LONG": -47.53,
    "LAT": -4.46,
    "CIDADE": "Itinga do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.82,
    "JAN": 4.31,
    "FEB": 4.59,
    "MAR": 4.68,
    "APR": 4.87,
    "MAY": 4.82,
    "JUN": 5.04,
    "JUL": 5.14,
    "AUG": 5.37,
    "SEP": 5.24,
    "OCT": 4.83,
    "NOV": 4.53,
    "DEC": 4.4
  },
  {
    "LONG": -46.85,
    "LAT": -4.48,
    "CIDADE": "Bom Jesus das Selvas",
    "UF": "MARANHÃO",
    "anual": 4.94,
    "JAN": 4.45,
    "FEB": 4.69,
    "MAR": 4.84,
    "APR": 4.85,
    "MAY": 4.88,
    "JUN": 5.06,
    "JUL": 5.24,
    "AUG": 5.47,
    "SEP": 5.51,
    "OCT": 5.09,
    "NOV": 4.65,
    "DEC": 4.57
  },
  {
    "LONG": -4.45,
    "LAT": -4.52,
    "CIDADE": "Lima Campos",
    "UF": "MARANHÃO",
    "anual": 5.27,
    "JAN": 4.57,
    "FEB": 4.74,
    "MAR": 4.89,
    "APR": 4.95,
    "MAY": 5,
    "JUN": 5.28,
    "JUL": 5.51,
    "AUG": 6.04,
    "SEP": 6.28,
    "OCT": 5.77,
    "NOV": 5.29,
    "DEC": 4.88
  },
  {
    "LONG": -43.89,
    "LAT": -0.45,
    "CIDADE": "Codó",
    "UF": "MARANHÃO",
    "anual": 5.39,
    "JAN": 4.72,
    "FEB": 4.9,
    "MAR": 5.03,
    "APR": 4.95,
    "MAY": 5.03,
    "JUN": 5.28,
    "JUL": 5.51,
    "AUG": 6.1,
    "SEP": 6.41,
    "OCT": 6.04,
    "NOV": 5.58,
    "DEC": 5.16
  },
  {
    "LONG": -45.24,
    "LAT": -4.45,
    "CIDADE": "Paulo Ramos",
    "UF": "MARANHÃO",
    "anual": 5.07,
    "JAN": 4.45,
    "FEB": 4.74,
    "MAR": 4.89,
    "APR": 4.92,
    "MAY": 4.92,
    "JUN": 5.12,
    "JUL": 5.32,
    "AUG": 5.73,
    "SEP": 5.88,
    "OCT": 5.3,
    "NOV": 4.88,
    "DEC": 4.65
  },
  {
    "LONG": -45.04,
    "LAT": -4.37,
    "CIDADE": "Bom Lugar",
    "UF": "MARANHÃO",
    "anual": 5.12,
    "JAN": 4.49,
    "FEB": 4.77,
    "MAR": 4.85,
    "APR": 4.94,
    "MAY": 4.94,
    "JUN": 5.13,
    "JUL": 5.36,
    "AUG": 5.8,
    "SEP": 5.97,
    "OCT": 5.43,
    "NOV": 4.96,
    "DEC": 4.78
  },
  {
    "LONG": -4.47,
    "LAT": -4.38,
    "CIDADE": "São Luís Gonzaga do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.23,
    "JAN": 4.54,
    "FEB": 4.73,
    "MAR": 4.89,
    "APR": 4.94,
    "MAY": 4.98,
    "JUN": 5.24,
    "JUL": 5.51,
    "AUG": 5.98,
    "SEP": 6.21,
    "OCT": 5.68,
    "NOV": 5.21,
    "DEC": 4.85
  },
  {
    "LONG": -44.33,
    "LAT": -0.44,
    "CIDADE": "Peritoró",
    "UF": "MARANHÃO",
    "anual": 5.31,
    "JAN": 4.56,
    "FEB": 4.81,
    "MAR": 4.95,
    "APR": 4.95,
    "MAY": 5.04,
    "JUN": 5.24,
    "JUL": 5.57,
    "AUG": 6.08,
    "SEP": 6.32,
    "OCT": 5.87,
    "NOV": 5.39,
    "DEC": 4.95
  },
  {
    "LONG": -46.45,
    "LAT": -4.32,
    "CIDADE": "Buriticupu",
    "UF": "MARANHÃO",
    "anual": 4.89,
    "JAN": 4.33,
    "FEB": 4.67,
    "MAR": 4.78,
    "APR": 4.85,
    "MAY": 4.82,
    "JUN": 4.98,
    "JUL": 5.18,
    "AUG": 5.49,
    "SEP": 5.52,
    "OCT": 5.02,
    "NOV": 4.62,
    "DEC": 4.48
  },
  {
    "LONG": -45.58,
    "LAT": -4.33,
    "CIDADE": "Brejo de Areia",
    "UF": "MARANHÃO",
    "anual": 5.01,
    "JAN": 4.42,
    "FEB": 4.68,
    "MAR": 4.82,
    "APR": 4.95,
    "MAY": 4.9,
    "JUN": 5.1,
    "JUL": 5.27,
    "AUG": 5.65,
    "SEP": 5.77,
    "OCT": 5.23,
    "NOV": 4.77,
    "DEC": 4.59
  },
  {
    "LONG": -45.25,
    "LAT": -4.29,
    "CIDADE": "Vitorino Freire",
    "UF": "MARANHÃO",
    "anual": 5.07,
    "JAN": 4.5,
    "FEB": 4.72,
    "MAR": 4.9,
    "APR": 4.92,
    "MAY": 4.91,
    "JUN": 5.1,
    "JUL": 5.32,
    "AUG": 5.66,
    "SEP": 5.84,
    "OCT": 5.33,
    "NOV": 4.93,
    "DEC": 4.7
  },
  {
    "LONG": -43.93,
    "LAT": -4.26,
    "CIDADE": "Timbiras",
    "UF": "MARANHÃO",
    "anual": 5.38,
    "JAN": 4.71,
    "FEB": 4.86,
    "MAR": 5.01,
    "APR": 4.97,
    "MAY": 5.05,
    "JUN": 5.3,
    "JUL": 5.57,
    "AUG": 6.15,
    "SEP": 6.34,
    "OCT": 5.96,
    "NOV": 5.53,
    "DEC": 5.09
  },
  {
    "LONG": -43.01,
    "LAT": -4.25,
    "CIDADE": "Coelho Neto",
    "UF": "MARANHÃO",
    "anual": 5.59,
    "JAN": 4.96,
    "FEB": 5.17,
    "MAR": 5.23,
    "APR": 5.23,
    "MAY": 5.24,
    "JUN": 5.36,
    "JUL": 5.64,
    "AUG": 6.2,
    "SEP": 6.47,
    "OCT": 6.29,
    "NOV": 5.88,
    "DEC": 5.43
  },
  {
    "LONG": -4.55,
    "LAT": -4.17,
    "CIDADE": "Altamira do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.03,
    "JAN": 4.45,
    "FEB": 4.7,
    "MAR": 4.85,
    "APR": 4.96,
    "MAY": 4.92,
    "JUN": 5.01,
    "JUL": 5.21,
    "AUG": 5.6,
    "SEP": 5.82,
    "OCT": 5.3,
    "NOV": 4.85,
    "DEC": 4.62
  },
  {
    "LONG": -44.78,
    "LAT": -4.22,
    "CIDADE": "Bacabal",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.59,
    "FEB": 4.8,
    "MAR": 4.89,
    "APR": 4.97,
    "MAY": 4.98,
    "JUN": 5.16,
    "JUL": 5.42,
    "AUG": 5.9,
    "SEP": 6.17,
    "OCT": 5.68,
    "NOV": 5.2,
    "DEC": 4.88
  },
  {
    "LONG": -44.45,
    "LAT": -4.21,
    "CIDADE": "Alto Alegre do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.3,
    "JAN": 4.61,
    "FEB": 4.8,
    "MAR": 4.95,
    "APR": 4.92,
    "MAY": 4.98,
    "JUN": 5.27,
    "JUL": 5.53,
    "AUG": 6.08,
    "SEP": 6.33,
    "OCT": 5.85,
    "NOV": 5.34,
    "DEC": 4.96
  },
  {
    "LONG": -42.94,
    "LAT": -4.15,
    "CIDADE": "Duque Bacelar",
    "UF": "MARANHÃO",
    "anual": 5.59,
    "JAN": 4.97,
    "FEB": 5.2,
    "MAR": 5.27,
    "APR": 5.26,
    "MAY": 5.22,
    "JUN": 5.35,
    "JUL": 5.65,
    "AUG": 6.11,
    "SEP": 6.42,
    "OCT": 6.25,
    "NOV": 5.91,
    "DEC": 5.42
  },
  {
    "LONG": -45.12,
    "LAT": -4.14,
    "CIDADE": "Olho d'Água das Cunhãs",
    "UF": "MARANHÃO",
    "anual": 5.13,
    "JAN": 4.57,
    "FEB": 4.82,
    "MAR": 4.96,
    "APR": 4.95,
    "MAY": 4.92,
    "JUN": 5.11,
    "JUL": 5.3,
    "AUG": 5.72,
    "SEP": 5.92,
    "OCT": 5.43,
    "NOV": 5.06,
    "DEC": 4.78
  },
  {
    "LONG": -44.12,
    "LAT": -4.13,
    "CIDADE": "Coroatá",
    "UF": "MARANHÃO",
    "anual": 5.36,
    "JAN": 4.69,
    "FEB": 4.86,
    "MAR": 5.02,
    "APR": 5.01,
    "MAY": 5.07,
    "JUN": 5.3,
    "JUL": 5.58,
    "AUG": 6.05,
    "SEP": 6.35,
    "OCT": 5.95,
    "NOV": 5.44,
    "DEC": 5.03
  },
  {
    "LONG": -43.33,
    "LAT": -4.13,
    "CIDADE": "Afonso Cunha",
    "UF": "MARANHÃO",
    "anual": 5.53,
    "JAN": 4.92,
    "FEB": 5.15,
    "MAR": 5.1,
    "APR": 5.16,
    "MAY": 5.14,
    "JUN": 5.41,
    "JUL": 5.58,
    "AUG": 6.12,
    "SEP": 6.4,
    "OCT": 6.22,
    "NOV": 5.8,
    "DEC": 5.31
  },
  {
    "LONG": -45.66,
    "LAT": -3.96,
    "CIDADE": "Santa Luzia",
    "UF": "MARANHÃO",
    "anual": 4.98,
    "JAN": 4.45,
    "FEB": 4.71,
    "MAR": 4.86,
    "APR": 4.91,
    "MAY": 4.8,
    "JUN": 5.02,
    "JUL": 5.17,
    "AUG": 5.53,
    "SEP": 5.68,
    "OCT": 5.2,
    "NOV": 4.82,
    "DEC": 4.64
  },
  {
    "LONG": -45.24,
    "LAT": -4.04,
    "CIDADE": "Satubinha",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.53,
    "FEB": 4.77,
    "MAR": 4.92,
    "APR": 4.98,
    "MAY": 4.89,
    "JUN": 5.13,
    "JUL": 5.22,
    "AUG": 5.68,
    "SEP": 5.86,
    "OCT": 5.42,
    "NOV": 5.03,
    "DEC": 4.79
  },
  {
    "LONG": -44.93,
    "LAT": -4.04,
    "CIDADE": "Lago Verde",
    "UF": "MARANHÃO",
    "anual": 5.17,
    "JAN": 4.64,
    "FEB": 4.77,
    "MAR": 4.97,
    "APR": 4.95,
    "MAY": 4.97,
    "JUN": 5.16,
    "JUL": 5.32,
    "AUG": 5.8,
    "SEP": 6.05,
    "OCT": 5.52,
    "NOV": 5.07,
    "DEC": 4.88
  },
  {
    "LONG": -4.45,
    "LAT": -4.04,
    "CIDADE": "São Mateus do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.32,
    "JAN": 4.77,
    "FEB": 4.88,
    "MAR": 5.06,
    "APR": 5.04,
    "MAY": 5.07,
    "JUN": 5.27,
    "JUL": 5.54,
    "AUG": 5.92,
    "SEP": 6.18,
    "OCT": 5.77,
    "NOV": 5.32,
    "DEC": 5.02
  },
  {
    "LONG": -45.18,
    "LAT": -3.89,
    "CIDADE": "Pio XII",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.57,
    "FEB": 4.85,
    "MAR": 4.94,
    "APR": 4.95,
    "MAY": 4.92,
    "JUN": 5.15,
    "JUL": 5.31,
    "AUG": 5.72,
    "SEP": 5.93,
    "OCT": 5.5,
    "NOV": 5.07,
    "DEC": 4.83
  },
  {
    "LONG": -42.92,
    "LAT": -3.94,
    "CIDADE": "Buriti",
    "UF": "MARANHÃO",
    "anual": 5.57,
    "JAN": 5.03,
    "FEB": 5.21,
    "MAR": 5.22,
    "APR": 5.19,
    "MAY": 5.2,
    "JUN": 5.4,
    "JUL": 5.61,
    "AUG": 6.09,
    "SEP": 6.3,
    "OCT": 6.21,
    "NOV": 5.91,
    "DEC": 5.43
  },
  {
    "LONG": -4.49,
    "LAT": -3.84,
    "CIDADE": "Conceição do Lago-Açu",
    "UF": "MARANHÃO",
    "anual": 5.29,
    "JAN": 4.69,
    "FEB": 4.86,
    "MAR": 5.01,
    "APR": 5.03,
    "MAY": 5.06,
    "JUN": 5.31,
    "JUL": 5.45,
    "AUG": 5.98,
    "SEP": 6.16,
    "OCT": 5.73,
    "NOV": 5.24,
    "DEC": 5
  },
  {
    "LONG": -45.84,
    "LAT": -3.67,
    "CIDADE": "Alto Alegre do Pindaré",
    "UF": "MARANHÃO",
    "anual": 4.93,
    "JAN": 4.42,
    "FEB": 4.71,
    "MAR": 4.85,
    "APR": 4.91,
    "MAY": 4.76,
    "JUN": 4.97,
    "JUL": 5.05,
    "AUG": 5.41,
    "SEP": 5.59,
    "OCT": 5.14,
    "NOV": 4.79,
    "DEC": 4.61
  },
  {
    "LONG": -45.62,
    "LAT": -3.67,
    "CIDADE": "Tufilândia",
    "UF": "MARANHÃO",
    "anual": 5.02,
    "JAN": 4.52,
    "FEB": 4.82,
    "MAR": 4.88,
    "APR": 4.96,
    "MAY": 4.86,
    "JUN": 5.03,
    "JUL": 5.19,
    "AUG": 5.5,
    "SEP": 5.7,
    "OCT": 5.26,
    "NOV": 4.86,
    "DEC": 4.61
  },
  {
    "LONG": -45.38,
    "LAT": -3.65,
    "CIDADE": "Santa Inês",
    "UF": "MARANHÃO",
    "anual": 5.07,
    "JAN": 4.53,
    "FEB": 4.8,
    "MAR": 4.91,
    "APR": 4.94,
    "MAY": 4.86,
    "JUN": 5.05,
    "JUL": 5.12,
    "AUG": 5.61,
    "SEP": 5.8,
    "OCT": 5.43,
    "NOV": 5.03,
    "DEC": 4.81
  },
  {
    "LONG": -45.25,
    "LAT": -3.74,
    "CIDADE": "Bela Vista do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.07,
    "JAN": 4.53,
    "FEB": 4.73,
    "MAR": 4.83,
    "APR": 4.89,
    "MAY": 4.87,
    "JUN": 5.13,
    "JUL": 5.18,
    "AUG": 5.63,
    "SEP": 5.85,
    "OCT": 5.39,
    "NOV": 4.95,
    "DEC": 4.83
  },
  {
    "LONG": -44.23,
    "LAT": -0.37,
    "CIDADE": "Pirapemas",
    "UF": "MARANHÃO",
    "anual": 5.32,
    "JAN": 4.67,
    "FEB": 4.93,
    "MAR": 5.01,
    "APR": 4.94,
    "MAY": 5.08,
    "JUN": 5.29,
    "JUL": 5.48,
    "AUG": 6.01,
    "SEP": 6.18,
    "OCT": 5.87,
    "NOV": 5.41,
    "DEC": 5.04
  },
  {
    "LONG": -43.35,
    "LAT": -3.74,
    "CIDADE": "Chapadinha",
    "UF": "MARANHÃO",
    "anual": 5.51,
    "JAN": 4.9,
    "FEB": 5.08,
    "MAR": 5.2,
    "APR": 5.1,
    "MAY": 5.09,
    "JUN": 5.35,
    "JUL": 5.62,
    "AUG": 6.08,
    "SEP": 6.38,
    "OCT": 6.2,
    "NOV": 5.81,
    "DEC": 5.33
  },
  {
    "LONG": -43.11,
    "LAT": -3.67,
    "CIDADE": "Anapurus",
    "UF": "MARANHÃO",
    "anual": 5.55,
    "JAN": 5.07,
    "FEB": 5.22,
    "MAR": 5.24,
    "APR": 5.19,
    "MAY": 5.17,
    "JUN": 5.39,
    "JUL": 5.55,
    "AUG": 6.07,
    "SEP": 6.32,
    "OCT": 6.18,
    "NOV": 5.87,
    "DEC": 5.4
  },
  {
    "LONG": -4.28,
    "LAT": -3.68,
    "CIDADE": "Brejo",
    "UF": "MARANHÃO",
    "anual": 5.64,
    "JAN": 5.11,
    "FEB": 5.32,
    "MAR": 5.36,
    "APR": 5.2,
    "MAY": 5.27,
    "JUN": 5.47,
    "JUL": 5.68,
    "AUG": 6.15,
    "SEP": 6.43,
    "OCT": 6.31,
    "NOV": 5.95,
    "DEC": 5.41
  },
  {
    "LONG": -46.25,
    "LAT": -3.55,
    "CIDADE": "São João do Carú",
    "UF": "MARANHÃO",
    "anual": 4.9,
    "JAN": 4.38,
    "FEB": 4.59,
    "MAR": 4.71,
    "APR": 4.84,
    "MAY": 4.77,
    "JUN": 5,
    "JUL": 5.12,
    "AUG": 5.42,
    "SEP": 5.48,
    "OCT": 5.08,
    "NOV": 4.73,
    "DEC": 4.62
  },
  {
    "LONG": -45.34,
    "LAT": -3.61,
    "CIDADE": "Pindaré-Mirim",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.61,
    "FEB": 4.89,
    "MAR": 4.97,
    "APR": 5.05,
    "MAY": 4.97,
    "JUN": 5.14,
    "JUL": 5.3,
    "AUG": 5.72,
    "SEP": 5.86,
    "OCT": 5.47,
    "NOV": 5.04,
    "DEC": 4.85
  },
  {
    "LONG": -45.18,
    "LAT": -3.64,
    "CIDADE": "Igarapé do Meio",
    "UF": "MARANHÃO",
    "anual": 5.11,
    "JAN": 4.59,
    "FEB": 4.7,
    "MAR": 4.81,
    "APR": 4.9,
    "MAY": 4.87,
    "JUN": 5.11,
    "JUL": 5.23,
    "AUG": 5.84,
    "SEP": 5.93,
    "OCT": 5.45,
    "NOV": 5.04,
    "DEC": 4.84
  },
  {
    "LONG": -44.55,
    "LAT": -3.62,
    "CIDADE": "Matões do Norte",
    "UF": "MARANHÃO",
    "anual": 5.21,
    "JAN": 4.6,
    "FEB": 4.79,
    "MAR": 4.88,
    "APR": 4.86,
    "MAY": 4.97,
    "JUN": 5.21,
    "JUL": 5.38,
    "AUG": 5.92,
    "SEP": 6.1,
    "OCT": 5.66,
    "NOV": 5.21,
    "DEC": 4.93
  },
  {
    "LONG": -44.58,
    "LAT": -3.56,
    "CIDADE": "Miranda do Norte",
    "UF": "MARANHÃO",
    "anual": 5.21,
    "JAN": 4.6,
    "FEB": 4.79,
    "MAR": 4.88,
    "APR": 4.86,
    "MAY": 4.97,
    "JUN": 5.21,
    "JUL": 5.38,
    "AUG": 5.92,
    "SEP": 6.1,
    "OCT": 5.66,
    "NOV": 5.21,
    "DEC": 4.93
  },
  {
    "LONG": -44.38,
    "LAT": -3.63,
    "CIDADE": "Cantanhede",
    "UF": "MARANHÃO",
    "anual": 5.27,
    "JAN": 4.5,
    "FEB": 4.75,
    "MAR": 4.91,
    "APR": 4.88,
    "MAY": 5,
    "JUN": 5.25,
    "JUL": 5.53,
    "AUG": 5.96,
    "SEP": 6.25,
    "OCT": 5.81,
    "NOV": 5.37,
    "DEC": 4.98
  },
  {
    "LONG": -43.12,
    "LAT": "#VALOR!",
    "CIDADE": "Mata Roma",
    "UF": "MARANHÃO",
    "anual": 5.56,
    "JAN": 4.92,
    "FEB": 5.15,
    "MAR": 5.22,
    "APR": 5.2,
    "MAY": 5.19,
    "JUN": 5.36,
    "JUL": 5.63,
    "AUG": 6.18,
    "SEP": 6.44,
    "OCT": 6.22,
    "NOV": 5.83,
    "DEC": 5.38
  },
  {
    "LONG": -42.61,
    "LAT": -3.58,
    "CIDADE": "Milagres do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.65,
    "JAN": 5.14,
    "FEB": 5.22,
    "MAR": 5.37,
    "APR": 5.23,
    "MAY": 5.33,
    "JUN": 5.49,
    "JUL": 5.68,
    "AUG": 6.2,
    "SEP": 6.43,
    "OCT": 6.28,
    "NOV": 5.96,
    "DEC": 5.47
  },
  {
    "LONG": -45.61,
    "LAT": -3.54,
    "CIDADE": "Bom Jardim",
    "UF": "MARANHÃO",
    "anual": 4.98,
    "JAN": 4.42,
    "FEB": 4.75,
    "MAR": 4.85,
    "APR": 4.89,
    "MAY": 4.83,
    "JUN": 4.99,
    "JUL": 5.1,
    "AUG": 5.5,
    "SEP": 5.63,
    "OCT": 5.27,
    "NOV": 4.89,
    "DEC": 4.68
  },
  {
    "LONG": "#VALOR!",
    "LAT": -3.48,
    "CIDADE": "Monção",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.56,
    "FEB": 4.86,
    "MAR": 4.95,
    "APR": 5.02,
    "MAY": 4.9,
    "JUN": 5.19,
    "JUL": 5.29,
    "AUG": 5.7,
    "SEP": 5.91,
    "OCT": 5.51,
    "NOV": 5.17,
    "DEC": 4.86
  },
  {
    "LONG": -44.86,
    "LAT": -3.45,
    "CIDADE": "Vitória do Mearim",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.66,
    "FEB": 4.88,
    "MAR": 4.94,
    "APR": 4.92,
    "MAY": 4.97,
    "JUN": 5.19,
    "JUL": 5.35,
    "AUG": 5.84,
    "SEP": 6.03,
    "OCT": 5.62,
    "NOV": 5.23,
    "DEC": 4.98
  },
  {
    "LONG": -44.78,
    "LAT": -3.46,
    "CIDADE": "Arari",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.64,
    "FEB": 4.87,
    "MAR": 4.9,
    "APR": 4.9,
    "MAY": 4.97,
    "JUN": 5.19,
    "JUL": 5.38,
    "AUG": 5.87,
    "SEP": 6.07,
    "OCT": 5.64,
    "NOV": 5.22,
    "DEC": 4.94
  },
  {
    "LONG": -43.91,
    "LAT": -3.47,
    "CIDADE": "Nina Rodrigues",
    "UF": "MARANHÃO",
    "anual": 5.33,
    "JAN": 4.67,
    "FEB": 4.84,
    "MAR": 5,
    "APR": 4.9,
    "MAY": 5.08,
    "JUN": 5.26,
    "JUL": 5.45,
    "AUG": 5.99,
    "SEP": 6.28,
    "OCT": 5.99,
    "NOV": 5.48,
    "DEC": 5.05
  },
  {
    "LONG": -43.92,
    "LAT": -3.55,
    "CIDADE": "Vargem Grande",
    "UF": "MARANHÃO",
    "anual": 5.33,
    "JAN": 4.67,
    "FEB": 4.84,
    "MAR": 5,
    "APR": 4.9,
    "MAY": 5.08,
    "JUN": 5.26,
    "JUL": 5.45,
    "AUG": 5.99,
    "SEP": 6.28,
    "OCT": 5.99,
    "NOV": 5.48,
    "DEC": 5.05
  },
  {
    "LONG": -42.56,
    "LAT": -3.5,
    "CIDADE": "Santa Quitéria do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.62,
    "JAN": 5.15,
    "FEB": 5.23,
    "MAR": 5.39,
    "APR": 5.21,
    "MAY": 5.27,
    "JUN": 5.46,
    "JUL": 5.68,
    "AUG": 6.1,
    "SEP": 6.33,
    "OCT": 6.26,
    "NOV": 5.95,
    "DEC": 5.42
  },
  {
    "LONG": -45.66,
    "LAT": -3.43,
    "CIDADE": "Governador Newton Bello",
    "UF": "MARANHÃO",
    "anual": 4.98,
    "JAN": 4.44,
    "FEB": 4.72,
    "MAR": 4.81,
    "APR": 4.87,
    "MAY": 4.82,
    "JUN": 4.99,
    "JUL": 5.11,
    "AUG": 5.46,
    "SEP": 5.65,
    "OCT": 5.3,
    "NOV": 4.93,
    "DEC": 4.69
  },
  {
    "LONG": -44.35,
    "LAT": -3.4,
    "CIDADE": "Itapecuru Mirim",
    "UF": "MARANHÃO",
    "anual": 5.21,
    "JAN": 4.62,
    "FEB": 4.71,
    "MAR": 4.81,
    "APR": 4.84,
    "MAY": 4.97,
    "JUN": 5.18,
    "JUL": 5.34,
    "AUG": 5.88,
    "SEP": 6.12,
    "OCT": 5.77,
    "NOV": 5.33,
    "DEC": 4.97
  },
  {
    "LONG": -44.02,
    "LAT": -3.41,
    "CIDADE": "Presidente Vargas",
    "UF": "MARANHÃO",
    "anual": 5.32,
    "JAN": 4.63,
    "FEB": 4.84,
    "MAR": 4.89,
    "APR": 4.86,
    "MAY": 5.06,
    "JUN": 5.29,
    "JUL": 5.5,
    "AUG": 6.06,
    "SEP": 6.3,
    "OCT": 5.96,
    "NOV": 5.43,
    "DEC": 5
  },
  {
    "LONG": -42.42,
    "LAT": -3.37,
    "CIDADE": "São Bernardo",
    "UF": "MARANHÃO",
    "anual": 5.59,
    "JAN": 5.09,
    "FEB": 5.18,
    "MAR": 5.28,
    "APR": 5.12,
    "MAY": 5.24,
    "JUN": 5.46,
    "JUL": 5.66,
    "AUG": 6.12,
    "SEP": 6.36,
    "OCT": 6.33,
    "NOV": 5.92,
    "DEC": 5.36
  },
  {
    "LONG": -42.2,
    "LAT": -3.4,
    "CIDADE": "Magalhães de Almeida",
    "UF": "MARANHÃO",
    "anual": 5.58,
    "JAN": 5.09,
    "FEB": 5.24,
    "MAR": 5.24,
    "APR": 5.11,
    "MAY": 5.26,
    "JUN": 5.48,
    "JUL": 5.67,
    "AUG": 6.05,
    "SEP": 6.28,
    "OCT": 6.27,
    "NOV": 5.93,
    "DEC": 5.37
  },
  {
    "LONG": -45.66,
    "LAT": -3.27,
    "CIDADE": "Zé Doca",
    "UF": "MARANHÃO",
    "anual": 5.02,
    "JAN": 4.52,
    "FEB": 4.75,
    "MAR": 4.82,
    "APR": 4.89,
    "MAY": 4.88,
    "JUN": 5.05,
    "JUL": 5.14,
    "AUG": 5.48,
    "SEP": 5.71,
    "OCT": 5.33,
    "NOV": 4.95,
    "DEC": 4.71
  },
  {
    "LONG": -45.18,
    "LAT": -3.28,
    "CIDADE": "Penalva",
    "UF": "MARANHÃO",
    "anual": 5.28,
    "JAN": 4.64,
    "FEB": 4.99,
    "MAR": 5.05,
    "APR": 5.06,
    "MAY": 5.05,
    "JUN": 5.28,
    "JUL": 5.39,
    "AUG": 5.84,
    "SEP": 6.06,
    "OCT": 5.71,
    "NOV": 5.3,
    "DEC": 4.97
  },
  {
    "LONG": -45.01,
    "LAT": -3.33,
    "CIDADE": "Cajari",
    "UF": "MARANHÃO",
    "anual": 5.28,
    "JAN": 4.63,
    "FEB": 4.96,
    "MAR": 5.04,
    "APR": 5.05,
    "MAY": 5.05,
    "JUN": 5.26,
    "JUL": 5.38,
    "AUG": 5.86,
    "SEP": 6.08,
    "OCT": 5.74,
    "NOV": 5.32,
    "DEC": 4.97
  },
  {
    "LONG": -44.62,
    "LAT": -3.26,
    "CIDADE": "Anajatuba",
    "UF": "MARANHÃO",
    "anual": 5.21,
    "JAN": 4.61,
    "FEB": 4.83,
    "MAR": 4.89,
    "APR": 4.82,
    "MAY": 4.95,
    "JUN": 5.17,
    "JUL": 5.34,
    "AUG": 5.91,
    "SEP": 6.05,
    "OCT": 5.66,
    "NOV": 5.29,
    "DEC": 4.97
  },
  {
    "LONG": -43.53,
    "LAT": -3.34,
    "CIDADE": "São Benedito do Rio Preto",
    "UF": "MARANHÃO",
    "anual": 5.37,
    "JAN": 4.71,
    "FEB": 4.8,
    "MAR": 4.87,
    "APR": 4.87,
    "MAY": 5.03,
    "JUN": 5.35,
    "JUL": 5.49,
    "AUG": 6.07,
    "SEP": 6.32,
    "OCT": 6.06,
    "NOV": 5.68,
    "DEC": 5.15
  },
  {
    "LONG": -45,
    "LAT": -3.21,
    "CIDADE": "Viana",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.57,
    "FEB": 4.91,
    "MAR": 4.98,
    "APR": 4.97,
    "MAY": 5.05,
    "JUN": 5.25,
    "JUL": 5.35,
    "AUG": 5.83,
    "SEP": 5.98,
    "OCT": 5.62,
    "NOV": 5.25,
    "DEC": 4.89
  },
  {
    "LONG": -43.51,
    "LAT": -3.16,
    "CIDADE": "Belágua",
    "UF": "MARANHÃO",
    "anual": 5.34,
    "JAN": 4.66,
    "FEB": 4.75,
    "MAR": 4.84,
    "APR": 4.83,
    "MAY": 5.02,
    "JUN": 5.32,
    "JUL": 5.5,
    "AUG": 6.08,
    "SEP": 6.31,
    "OCT": 6.05,
    "NOV": 5.66,
    "DEC": 5.09
  },
  {
    "LONG": -43.39,
    "LAT": -3.21,
    "CIDADE": "Urbano Santos",
    "UF": "MARANHÃO",
    "anual": 5.36,
    "JAN": 4.74,
    "FEB": 4.78,
    "MAR": 4.85,
    "APR": 4.86,
    "MAY": 5.07,
    "JUN": 5.31,
    "JUL": 5.48,
    "AUG": 6.04,
    "SEP": 6.32,
    "OCT": 6.07,
    "NOV": 5.73,
    "DEC": 5.1
  },
  {
    "LONG": -45.04,
    "LAT": -3.1,
    "CIDADE": "Matinha",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.55,
    "FEB": 4.83,
    "MAR": 4.88,
    "APR": 4.82,
    "MAY": 4.97,
    "JUN": 5.14,
    "JUL": 5.24,
    "AUG": 5.73,
    "SEP": 5.93,
    "OCT": 5.62,
    "NOV": 5.21,
    "DEC": 4.86
  },
  {
    "LONG": -4.43,
    "LAT": -3.14,
    "CIDADE": "Santa Rita",
    "UF": "MARANHÃO",
    "anual": 5.18,
    "JAN": 4.55,
    "FEB": 4.73,
    "MAR": 4.69,
    "APR": 4.64,
    "MAY": 4.86,
    "JUN": 5.17,
    "JUL": 5.37,
    "AUG": 5.95,
    "SEP": 6.13,
    "OCT": 5.77,
    "NOV": 5.37,
    "DEC": 4.91
  },
  {
    "LONG": -42.41,
    "LAT": -3.11,
    "CIDADE": "Santana do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.46,
    "JAN": 4.91,
    "FEB": 4.88,
    "MAR": 4.92,
    "APR": 4.89,
    "MAY": 5.11,
    "JUN": 5.3,
    "JUL": 5.55,
    "AUG": 6.14,
    "SEP": 6.38,
    "OCT": 6.26,
    "NOV": 5.91,
    "DEC": 5.29
  },
  {
    "LONG": -45.67,
    "LAT": -2.95,
    "CIDADE": "Araguanã",
    "UF": "MARANHÃO",
    "anual": 4.96,
    "JAN": 4.48,
    "FEB": 4.7,
    "MAR": 4.63,
    "APR": 4.72,
    "MAY": 4.81,
    "JUN": 4.98,
    "JUL": 5.1,
    "AUG": 5.52,
    "SEP": 5.66,
    "OCT": 5.32,
    "NOV": 4.97,
    "DEC": 4.63
  },
  {
    "LONG": -45.35,
    "LAT": -2.97,
    "CIDADE": "Pedro do Rosário",
    "UF": "MARANHÃO",
    "anual": 5.04,
    "JAN": 4.55,
    "FEB": 4.69,
    "MAR": 4.69,
    "APR": 4.72,
    "MAY": 4.82,
    "JUN": 5.06,
    "JUL": 5.16,
    "AUG": 5.69,
    "SEP": 5.75,
    "OCT": 5.47,
    "NOV": 5.14,
    "DEC": 4.73
  },
  {
    "LONG": -44.99,
    "LAT": -3,
    "CIDADE": "Olinda Nova do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.51,
    "FEB": 4.78,
    "MAR": 4.77,
    "APR": 4.84,
    "MAY": 4.94,
    "JUN": 5.17,
    "JUL": 5.3,
    "AUG": 5.75,
    "SEP": 5.93,
    "OCT": 5.6,
    "NOV": 5.33,
    "DEC": 4.93
  },
  {
    "LONG": -44.8,
    "LAT": -2.96,
    "CIDADE": "São João Batista",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.68,
    "FEB": 4.82,
    "MAR": 4.79,
    "APR": 4.84,
    "MAY": 4.92,
    "JUN": 5.16,
    "JUL": 5.23,
    "AUG": 5.71,
    "SEP": 6.01,
    "OCT": 5.82,
    "NOV": 5.57,
    "DEC": 5.13
  },
  {
    "LONG": -44.31,
    "LAT": -2.98,
    "CIDADE": "Bacabeira",
    "UF": "MARANHÃO",
    "anual": 5.13,
    "JAN": 4.59,
    "FEB": 4.62,
    "MAR": 4.56,
    "APR": 4.57,
    "MAY": 4.78,
    "JUN": 5.12,
    "JUL": 5.3,
    "AUG": 5.88,
    "SEP": 6.07,
    "OCT": 5.72,
    "NOV": 5.41,
    "DEC": 4.98
  },
  {
    "LONG": -44.88,
    "LAT": -2.89,
    "CIDADE": "São Vicente Ferrer",
    "UF": "MARANHÃO",
    "anual": 5.14,
    "JAN": 4.64,
    "FEB": 4.79,
    "MAR": 4.8,
    "APR": 4.84,
    "MAY": 4.9,
    "JUN": 5.13,
    "JUL": 5.21,
    "AUG": 5.7,
    "SEP": 5.82,
    "OCT": 5.54,
    "NOV": 5.34,
    "DEC": 4.98
  },
  {
    "LONG": -44.67,
    "LAT": -2.87,
    "CIDADE": "Cajapió",
    "UF": "MARANHÃO",
    "anual": 5.27,
    "JAN": 4.75,
    "FEB": 4.82,
    "MAR": 4.76,
    "APR": 4.74,
    "MAY": 4.84,
    "JUN": 5.12,
    "JUL": 5.29,
    "AUG": 5.88,
    "SEP": 6.11,
    "OCT": 5.99,
    "NOV": 5.71,
    "DEC": 5.25
  },
  {
    "LONG": -44.25,
    "LAT": -2.94,
    "CIDADE": "Rosário",
    "UF": "MARANHÃO",
    "anual": 5.17,
    "JAN": 4.61,
    "FEB": 4.59,
    "MAR": 4.56,
    "APR": 4.51,
    "MAY": 4.7,
    "JUN": 5.02,
    "JUL": 5.19,
    "AUG": 5.83,
    "SEP": 6.19,
    "OCT": 5.99,
    "NOV": 5.64,
    "DEC": 5.2
  },
  {
    "LONG": -44.04,
    "LAT": -2.87,
    "CIDADE": "Morros",
    "UF": "MARANHÃO",
    "anual": 4.99,
    "JAN": 4.4,
    "FEB": 4.47,
    "MAR": 4.49,
    "APR": 4.48,
    "MAY": 4.67,
    "JUN": 5.02,
    "JUL": 5.16,
    "AUG": 5.66,
    "SEP": 5.95,
    "OCT": 5.63,
    "NOV": 5.21,
    "DEC": 4.75
  },
  {
    "LONG": -44.05,
    "LAT": -2.93,
    "CIDADE": "Cachoeira Grande",
    "UF": "MARANHÃO",
    "anual": 4.99,
    "JAN": 4.4,
    "FEB": 4.47,
    "MAR": 4.49,
    "APR": 4.48,
    "MAY": 4.67,
    "JUN": 5.02,
    "JUL": 5.16,
    "AUG": 5.66,
    "SEP": 5.95,
    "OCT": 5.63,
    "NOV": 5.21,
    "DEC": 4.75
  },
  {
    "LONG": -44.07,
    "LAT": -2.93,
    "CIDADE": "Presidente Juscelino",
    "UF": "MARANHÃO",
    "anual": 4.99,
    "JAN": 4.4,
    "FEB": 4.47,
    "MAR": 4.49,
    "APR": 4.48,
    "MAY": 4.67,
    "JUN": 5.02,
    "JUL": 5.16,
    "AUG": 5.66,
    "SEP": 5.95,
    "OCT": 5.63,
    "NOV": 5.21,
    "DEC": 4.75
  },
  {
    "LONG": -41.91,
    "LAT": -2.89,
    "CIDADE": "Araioses",
    "UF": "MARANHÃO",
    "anual": 5.68,
    "JAN": 5.45,
    "FEB": 5.31,
    "MAR": 5.23,
    "APR": 5,
    "MAY": 5.23,
    "JUN": 5.44,
    "JUL": 5.64,
    "AUG": 6.24,
    "SEP": 6.31,
    "OCT": 6.12,
    "NOV": 6.25,
    "DEC": 5.92
  },
  {
    "LONG": -45.7,
    "LAT": -2.81,
    "CIDADE": "Nova Olinda do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.42,
    "FEB": 4.66,
    "MAR": 4.54,
    "APR": 4.72,
    "MAY": 4.79,
    "JUN": 5.02,
    "JUL": 5.07,
    "AUG": 5.43,
    "SEP": 5.55,
    "OCT": 5.27,
    "NOV": 5,
    "DEC": 4.58
  },
  {
    "LONG": -44.06,
    "LAT": -2.77,
    "CIDADE": "Icatu",
    "UF": "MARANHÃO",
    "anual": 4.97,
    "JAN": 4.4,
    "FEB": 4.42,
    "MAR": 4.44,
    "APR": 4.43,
    "MAY": 4.59,
    "JUN": 4.89,
    "JUL": 5.13,
    "AUG": 5.64,
    "SEP": 5.95,
    "OCT": 5.65,
    "NOV": 5.27,
    "DEC": 4.79
  },
  {
    "LONG": -44.06,
    "LAT": -2.84,
    "CIDADE": "Axixá",
    "UF": "MARANHÃO",
    "anual": 4.97,
    "JAN": 4.4,
    "FEB": 4.42,
    "MAR": 4.44,
    "APR": 4.43,
    "MAY": 4.59,
    "JUN": 4.89,
    "JUL": 5.13,
    "AUG": 5.64,
    "SEP": 5.95,
    "OCT": 5.65,
    "NOV": 5.27,
    "DEC": 4.79
  },
  {
    "LONG": -42.83,
    "LAT": -2.75,
    "CIDADE": "Barreirinhas",
    "UF": "MARANHÃO",
    "anual": 5.42,
    "JAN": 5.04,
    "FEB": 5.04,
    "MAR": 4.91,
    "APR": 4.79,
    "MAY": 4.99,
    "JUN": 5.09,
    "JUL": 5.32,
    "AUG": 6.04,
    "SEP": 6.36,
    "OCT": 6.07,
    "NOV": 5.9,
    "DEC": 5.52
  },
  {
    "LONG": -42.28,
    "LAT": -2.76,
    "CIDADE": "Tutóia",
    "UF": "MARANHÃO",
    "anual": 5.55,
    "JAN": 5.37,
    "FEB": 5.26,
    "MAR": 5.17,
    "APR": 5.02,
    "MAY": 5.14,
    "JUN": 5.31,
    "JUL": 5.51,
    "AUG": 6,
    "SEP": 6.05,
    "OCT": 5.83,
    "NOV": 6.1,
    "DEC": 5.82
  },
  {
    "LONG": -42.12,
    "LAT": -2.84,
    "CIDADE": "Água Doce do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.6,
    "JAN": 5.4,
    "FEB": 5.28,
    "MAR": 5.19,
    "APR": 5,
    "MAY": 5.17,
    "JUN": 5.36,
    "JUL": 5.58,
    "AUG": 6.11,
    "SEP": 6.16,
    "OCT": 5.9,
    "NOV": 6.17,
    "DEC": 5.85
  },
  {
    "LONG": -44.83,
    "LAT": -2.7,
    "CIDADE": "São Bento",
    "UF": "MARANHÃO",
    "anual": 5.14,
    "JAN": 4.58,
    "FEB": 4.79,
    "MAR": 4.79,
    "APR": 4.89,
    "MAY": 4.93,
    "JUN": 5.23,
    "JUL": 5.28,
    "AUG": 5.72,
    "SEP": 5.81,
    "OCT": 5.52,
    "NOV": 5.3,
    "DEC": 4.9
  },
  {
    "LONG": -4.47,
    "LAT": -2.71,
    "CIDADE": "Bacurituba",
    "UF": "MARANHÃO",
    "anual": 5.2,
    "JAN": 4.73,
    "FEB": 4.85,
    "MAR": 4.8,
    "APR": 4.86,
    "MAY": 4.91,
    "JUN": 5.21,
    "JUL": 5.29,
    "AUG": 5.77,
    "SEP": 5.86,
    "OCT": 5.6,
    "NOV": 5.43,
    "DEC": 5.08
  },
  {
    "LONG": -42.54,
    "LAT": -2.72,
    "CIDADE": "Paulino Neves",
    "UF": "MARANHÃO",
    "anual": 5.29,
    "JAN": 5.2,
    "FEB": 5.23,
    "MAR": 5.13,
    "APR": 5.01,
    "MAY": 5.12,
    "JUN": 5.15,
    "JUL": 5.26,
    "AUG": 5.47,
    "SEP": 5.44,
    "OCT": 5.34,
    "NOV": 5.58,
    "DEC": 5.54
  },
  {
    "LONG": -45.36,
    "LAT": -2.59,
    "CIDADE": "Presidente Sarney",
    "UF": "MARANHÃO",
    "anual": 5.01,
    "JAN": 4.5,
    "FEB": 4.58,
    "MAR": 4.49,
    "APR": 4.6,
    "MAY": 4.76,
    "JUN": 5.05,
    "JUL": 5.15,
    "AUG": 5.68,
    "SEP": 5.82,
    "OCT": 5.53,
    "NOV": 5.21,
    "DEC": 4.78
  },
  {
    "LONG": -44.85,
    "LAT": -2.58,
    "CIDADE": "Peri Mirim",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.6,
    "FEB": 4.67,
    "MAR": 4.66,
    "APR": 4.74,
    "MAY": 4.83,
    "JUN": 5.15,
    "JUL": 5.3,
    "AUG": 5.74,
    "SEP": 5.85,
    "OCT": 5.54,
    "NOV": 5.25,
    "DEC": 4.91
  },
  {
    "LONG": -44.9,
    "LAT": -2.65,
    "CIDADE": "Palmeirândia",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.6,
    "FEB": 4.67,
    "MAR": 4.66,
    "APR": 4.74,
    "MAY": 4.83,
    "JUN": 5.15,
    "JUL": 5.3,
    "AUG": 5.74,
    "SEP": 5.85,
    "OCT": 5.54,
    "NOV": 5.25,
    "DEC": 4.91
  },
  {
    "LONG": -44.06,
    "LAT": -2.55,
    "CIDADE": "São José de Ribamar",
    "UF": "MARANHÃO",
    "anual": 5.32,
    "JAN": 4.93,
    "FEB": 4.83,
    "MAR": 4.73,
    "APR": 4.61,
    "MAY": 4.79,
    "JUN": 5.07,
    "JUL": 5.29,
    "AUG": 5.92,
    "SEP": 6.19,
    "OCT": 6.01,
    "NOV": 5.86,
    "DEC": 5.54
  },
  {
    "LONG": -43.47,
    "LAT": -2.6,
    "CIDADE": "Humberto de Campos",
    "UF": "MARANHÃO",
    "anual": 5.25,
    "JAN": 4.81,
    "FEB": 4.84,
    "MAR": 4.73,
    "APR": 4.67,
    "MAY": 4.8,
    "JUN": 5.01,
    "JUL": 5.21,
    "AUG": 5.9,
    "SEP": 6.22,
    "OCT": 5.91,
    "NOV": 5.64,
    "DEC": 5.3
  },
  {
    "LONG": -46.04,
    "LAT": -2.45,
    "CIDADE": "Centro do Guilherme",
    "UF": "MARANHÃO",
    "anual": 4.85,
    "JAN": 4.37,
    "FEB": 4.58,
    "MAR": 4.56,
    "APR": 4.59,
    "MAY": 4.72,
    "JUN": 4.95,
    "JUL": 5.01,
    "AUG": 5.35,
    "SEP": 5.49,
    "OCT": 5.16,
    "NOV": 4.91,
    "DEC": 4.53
  },
  {
    "LONG": -45.78,
    "LAT": -2.54,
    "CIDADE": "Santa Luzia do Paruá",
    "UF": "MARANHÃO",
    "anual": 4.95,
    "JAN": 4.47,
    "FEB": 4.59,
    "MAR": 4.53,
    "APR": 4.63,
    "MAY": 4.73,
    "JUN": 4.99,
    "JUL": 5.09,
    "AUG": 5.62,
    "SEP": 5.74,
    "OCT": 5.37,
    "NOV": 5.01,
    "DEC": 4.68
  },
  {
    "LONG": -45.08,
    "LAT": -2.52,
    "CIDADE": "Pinheiro",
    "UF": "MARANHÃO",
    "anual": 5.04,
    "JAN": 4.59,
    "FEB": 4.62,
    "MAR": 4.55,
    "APR": 4.53,
    "MAY": 4.79,
    "JUN": 5.07,
    "JUL": 5.21,
    "AUG": 5.73,
    "SEP": 5.83,
    "OCT": 5.5,
    "NOV": 5.23,
    "DEC": 4.82
  },
  {
    "LONG": -44.28,
    "LAT": -2.54,
    "CIDADE": "São Luís",
    "UF": "MARANHÃO",
    "anual": 5.21,
    "JAN": 4.87,
    "FEB": 4.94,
    "MAR": 4.78,
    "APR": 4.68,
    "MAY": 4.76,
    "JUN": 4.94,
    "JUL": 5.17,
    "AUG": 5.73,
    "SEP": 6.01,
    "OCT": 5.69,
    "NOV": 5.55,
    "DEC": 5.37
  },
  {
    "LONG": -44.11,
    "LAT": -2.52,
    "CIDADE": "Paço do Lumiar",
    "UF": "MARANHÃO",
    "anual": 5.17,
    "JAN": 4.82,
    "FEB": 4.86,
    "MAR": 4.76,
    "APR": 4.63,
    "MAY": 4.74,
    "JUN": 4.93,
    "JUL": 5.15,
    "AUG": 5.72,
    "SEP": 5.97,
    "OCT": 5.68,
    "NOV": 5.47,
    "DEC": 5.29
  },
  {
    "LONG": -43.42,
    "LAT": -2.51,
    "CIDADE": "Primeira Cruz",
    "UF": "MARANHÃO",
    "anual": 5.31,
    "JAN": 4.85,
    "FEB": 4.86,
    "MAR": 4.74,
    "APR": 4.73,
    "MAY": 4.95,
    "JUN": 5.12,
    "JUL": 5.3,
    "AUG": 5.99,
    "SEP": 6.18,
    "OCT": 5.94,
    "NOV": 5.69,
    "DEC": 5.32
  },
  {
    "LONG": -43.25,
    "LAT": -2.5,
    "CIDADE": "Santo Amaro do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.36,
    "JAN": 5,
    "FEB": 5.03,
    "MAR": 4.89,
    "APR": 4.87,
    "MAY": 5.05,
    "JUN": 5.28,
    "JUL": 5.46,
    "AUG": 5.88,
    "SEP": 6.02,
    "OCT": 5.86,
    "NOV": 5.65,
    "DEC": 5.35
  },
  {
    "LONG": -45.82,
    "LAT": -0.24,
    "CIDADE": "Presidente Médici",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.44,
    "FEB": 4.51,
    "MAR": 4.46,
    "APR": 4.66,
    "MAY": 4.69,
    "JUN": 5,
    "JUL": 5.1,
    "AUG": 5.58,
    "SEP": 5.69,
    "OCT": 5.35,
    "NOV": 5.01,
    "DEC": 4.6
  },
  {
    "LONG": -44.79,
    "LAT": -2.45,
    "CIDADE": "Bequimão",
    "UF": "MARANHÃO",
    "anual": 5.04,
    "JAN": 4.71,
    "FEB": 4.64,
    "MAR": 4.6,
    "APR": 4.51,
    "MAY": 4.66,
    "JUN": 4.99,
    "JUL": 5.1,
    "AUG": 5.6,
    "SEP": 5.8,
    "OCT": 5.48,
    "NOV": 5.32,
    "DEC": 5.07
  },
  {
    "LONG": -44.42,
    "LAT": -2.41,
    "CIDADE": "Alcântara",
    "UF": "MARANHÃO",
    "anual": 5.24,
    "JAN": 4.93,
    "FEB": 4.91,
    "MAR": 4.79,
    "APR": 4.73,
    "MAY": 4.92,
    "JUN": 5.14,
    "JUL": 5.29,
    "AUG": 5.71,
    "SEP": 5.92,
    "OCT": 5.68,
    "NOV": 5.53,
    "DEC": 5.26
  },
  {
    "LONG": -44.1,
    "LAT": -2.44,
    "CIDADE": "Raposa",
    "UF": "MARANHÃO",
    "anual": 5.38,
    "JAN": 5.15,
    "FEB": 5.09,
    "MAR": 4.95,
    "APR": 4.8,
    "MAY": 4.96,
    "JUN": 5.22,
    "JUL": 5.41,
    "AUG": 5.77,
    "SEP": 5.96,
    "OCT": 5.89,
    "NOV": 5.81,
    "DEC": 5.6
  },
  {
    "LONG": -45.86,
    "LAT": -2.24,
    "CIDADE": "Maranhãozinho",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.49,
    "FEB": 4.43,
    "MAR": 4.39,
    "APR": 4.54,
    "MAY": 4.62,
    "JUN": 4.96,
    "JUL": 5.07,
    "AUG": 5.57,
    "SEP": 5.73,
    "OCT": 5.42,
    "NOV": 5.1,
    "DEC": 4.66
  },
  {
    "LONG": -45.31,
    "LAT": -2.22,
    "CIDADE": "Turilândia",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.53,
    "FEB": 4.45,
    "MAR": 4.37,
    "APR": 4.4,
    "MAY": 4.63,
    "JUN": 4.96,
    "JUL": 5.02,
    "AUG": 5.56,
    "SEP": 5.75,
    "OCT": 5.43,
    "NOV": 5.15,
    "DEC": 4.74
  },
  {
    "LONG": -45.3,
    "LAT": -0.22,
    "CIDADE": "Santa Helena",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.53,
    "FEB": 4.45,
    "MAR": 4.37,
    "APR": 4.4,
    "MAY": 4.63,
    "JUN": 4.96,
    "JUL": 5.02,
    "AUG": 5.56,
    "SEP": 5.75,
    "OCT": 5.43,
    "NOV": 5.15,
    "DEC": 4.74
  },
  {
    "LONG": -44.83,
    "LAT": -2.2,
    "CIDADE": "Central do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.05,
    "JAN": 4.76,
    "FEB": 4.67,
    "MAR": 4.57,
    "APR": 4.5,
    "MAY": 4.71,
    "JUN": 4.99,
    "JUL": 5.12,
    "AUG": 5.63,
    "SEP": 5.8,
    "OCT": 5.48,
    "NOV": 5.36,
    "DEC": 5.07
  },
  {
    "LONG": -46.12,
    "LAT": -0.21,
    "CIDADE": "Centro Novo do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.8,
    "JAN": 4.36,
    "FEB": 4.42,
    "MAR": 4.41,
    "APR": 4.49,
    "MAY": 4.59,
    "JUN": 4.87,
    "JUL": 4.96,
    "AUG": 5.38,
    "SEP": 5.51,
    "OCT": 5.17,
    "NOV": 4.9,
    "DEC": 4.51
  },
  {
    "LONG": -45.89,
    "LAT": -0.21,
    "CIDADE": "Governador Nunes Freire",
    "UF": "MARANHÃO",
    "anual": 4.87,
    "JAN": 4.45,
    "FEB": 4.4,
    "MAR": 4.37,
    "APR": 4.5,
    "MAY": 4.56,
    "JUN": 4.91,
    "JUL": 5.03,
    "AUG": 5.49,
    "SEP": 5.65,
    "OCT": 5.32,
    "NOV": 5.08,
    "DEC": 4.61
  },
  {
    "LONG": -44.78,
    "LAT": -2.07,
    "CIDADE": "Mirinzal",
    "UF": "MARANHÃO",
    "anual": 5.03,
    "JAN": 4.66,
    "FEB": 4.66,
    "MAR": 4.57,
    "APR": 4.48,
    "MAY": 4.73,
    "JUN": 5.03,
    "JUL": 5.16,
    "AUG": 5.64,
    "SEP": 5.74,
    "OCT": 5.43,
    "NOV": 5.3,
    "DEC": 4.97
  },
  {
    "LONG": -44.61,
    "LAT": -2.13,
    "CIDADE": "Guimarães",
    "UF": "MARANHÃO",
    "anual": 5.05,
    "JAN": 4.71,
    "FEB": 4.72,
    "MAR": 4.64,
    "APR": 4.51,
    "MAY": 4.78,
    "JUN": 5.09,
    "JUL": 5.21,
    "AUG": 5.56,
    "SEP": 5.73,
    "OCT": 5.43,
    "NOV": 5.29,
    "DEC": 4.97
  },
  {
    "LONG": -45.96,
    "LAT": -2.05,
    "CIDADE": "Maracaçumé",
    "UF": "MARANHÃO",
    "anual": 4.84,
    "JAN": 4.43,
    "FEB": 4.39,
    "MAR": 4.36,
    "APR": 4.41,
    "MAY": 4.56,
    "JUN": 4.91,
    "JUL": 4.99,
    "AUG": 5.43,
    "SEP": 5.61,
    "OCT": 5.31,
    "NOV": 5.05,
    "DEC": 4.65
  },
  {
    "LONG": -44.53,
    "LAT": -2,
    "CIDADE": "Cedral",
    "UF": "MARANHÃO",
    "anual": 5.13,
    "JAN": 4.86,
    "FEB": 4.87,
    "MAR": 4.78,
    "APR": 4.56,
    "MAY": 4.9,
    "JUN": 5.09,
    "JUL": 5.14,
    "AUG": 5.48,
    "SEP": 5.76,
    "OCT": 5.54,
    "NOV": 5.45,
    "DEC": 5.15
  },
  {
    "LONG": -46.07,
    "LAT": -1.88,
    "CIDADE": "Junco do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.87,
    "JAN": 4.5,
    "FEB": 4.38,
    "MAR": 4.35,
    "APR": 4.4,
    "MAY": 4.55,
    "JUN": 4.89,
    "JUL": 4.98,
    "AUG": 5.46,
    "SEP": 5.68,
    "OCT": 5.36,
    "NOV": 5.16,
    "DEC": 4.68
  },
  {
    "LONG": -45.12,
    "LAT": -1.85,
    "CIDADE": "Serrano do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.9,
    "JAN": 4.58,
    "FEB": 4.47,
    "MAR": 4.32,
    "APR": 4.26,
    "MAY": 4.54,
    "JUN": 4.89,
    "JUL": 5.04,
    "AUG": 5.48,
    "SEP": 5.71,
    "OCT": 5.4,
    "NOV": 5.2,
    "DEC": 4.91
  },
  {
    "LONG": -44.6,
    "LAT": -1.86,
    "CIDADE": "Porto Rico do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.18,
    "JAN": 4.92,
    "FEB": 4.96,
    "MAR": 4.79,
    "APR": 4.59,
    "MAY": 4.9,
    "JUN": 5.12,
    "JUL": 5.16,
    "AUG": 5.52,
    "SEP": 5.81,
    "OCT": 5.64,
    "NOV": 5.54,
    "DEC": 5.23
  },
  {
    "LONG": -46.3,
    "LAT": -1.78,
    "CIDADE": "Boa Vista do Gurupi",
    "UF": "MARANHÃO",
    "anual": 4.82,
    "JAN": 4.36,
    "FEB": 4.32,
    "MAR": 4.31,
    "APR": 4.42,
    "MAY": 4.57,
    "JUN": 4.84,
    "JUL": 5.02,
    "AUG": 5.4,
    "SEP": 5.59,
    "OCT": 5.27,
    "NOV": 5.06,
    "DEC": 4.68
  },
  {
    "LONG": -44.87,
    "LAT": -1.82,
    "CIDADE": "Cururupu",
    "UF": "MARANHÃO",
    "anual": 5.03,
    "JAN": 4.83,
    "FEB": 4.73,
    "MAR": 4.56,
    "APR": 4.44,
    "MAY": 4.67,
    "JUN": 4.97,
    "JUL": 5.06,
    "AUG": 5.46,
    "SEP": 5.76,
    "OCT": 5.46,
    "NOV": 5.38,
    "DEC": 5.04
  },
  {
    "LONG": -46,
    "LAT": -1.68,
    "CIDADE": "Amapá do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.82,
    "JAN": 4.5,
    "FEB": 4.33,
    "MAR": 4.24,
    "APR": 4.29,
    "MAY": 4.46,
    "JUN": 4.78,
    "JUL": 4.92,
    "AUG": 5.46,
    "SEP": 5.69,
    "OCT": 5.35,
    "NOV": 5.11,
    "DEC": 4.68
  },
  {
    "LONG": -45.37,
    "LAT": -1.67,
    "CIDADE": "Turiaçu",
    "UF": "MARANHÃO",
    "anual": 4.83,
    "JAN": 4.56,
    "FEB": 4.43,
    "MAR": 4.2,
    "APR": 4.2,
    "MAY": 4.42,
    "JUN": 4.76,
    "JUL": 4.93,
    "AUG": 5.37,
    "SEP": 5.63,
    "OCT": 5.37,
    "NOV": 5.17,
    "DEC": 4.88
  },
  {
    "LONG": "#VALOR!",
    "LAT": -1.73,
    "CIDADE": "Bacuri",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.65,
    "FEB": 4.5,
    "MAR": 4.31,
    "APR": 4.25,
    "MAY": 4.55,
    "JUN": 4.88,
    "JUL": 5.05,
    "AUG": 5.5,
    "SEP": 5.73,
    "OCT": 5.4,
    "NOV": 5.28,
    "DEC": 4.92
  },
  {
    "LONG": -45.08,
    "LAT": -1.53,
    "CIDADE": "Apicum-Açu",
    "UF": "MARANHÃO",
    "anual": 5.11,
    "JAN": 4.92,
    "FEB": 4.67,
    "MAR": 4.53,
    "APR": 4.44,
    "MAY": 4.7,
    "JUN": 5,
    "JUL": 5.15,
    "AUG": 5.59,
    "SEP": 5.93,
    "OCT": 5.65,
    "NOV": 5.52,
    "DEC": 5.25
  },
  {
    "LONG": -45.78,
    "LAT": -0.14,
    "CIDADE": "Godofredo Viana",
    "UF": "MARANHÃO",
    "anual": 4.88,
    "JAN": 4.69,
    "FEB": 4.45,
    "MAR": 4.31,
    "APR": 4.24,
    "MAY": 4.51,
    "JUN": 4.82,
    "JUL": 4.91,
    "AUG": 5.32,
    "SEP": 5.65,
    "OCT": 5.42,
    "NOV": 5.26,
    "DEC": 4.97
  },
  {
    "LONG": -45.73,
    "LAT": -0.14,
    "CIDADE": "Cândido Mendes",
    "UF": "MARANHÃO",
    "anual": 4.88,
    "JAN": 4.69,
    "FEB": 4.45,
    "MAR": 4.31,
    "APR": 4.24,
    "MAY": 4.51,
    "JUN": 4.82,
    "JUL": 4.91,
    "AUG": 5.32,
    "SEP": 5.65,
    "OCT": 5.42,
    "NOV": 5.26,
    "DEC": 4.97
  },
  {
    "LONG": -45.9,
    "LAT": -1.33,
    "CIDADE": "Luís Domingues",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.74,
    "FEB": 4.49,
    "MAR": 4.36,
    "APR": 4.31,
    "MAY": 4.58,
    "JUN": 4.82,
    "JUL": 4.99,
    "AUG": 5.33,
    "SEP": 5.7,
    "OCT": 5.44,
    "NOV": 5.3,
    "DEC": 4.99
  },
  {
    "LONG": -46.02,
    "LAT": -1.2,
    "CIDADE": "Carutapera",
    "UF": "MARANHÃO",
    "anual": 4.93,
    "JAN": 4.74,
    "FEB": 4.51,
    "MAR": 4.34,
    "APR": 4.31,
    "MAY": 4.53,
    "JUN": 4.83,
    "JUL": 4.94,
    "AUG": 5.28,
    "SEP": 5.67,
    "OCT": 5.51,
    "NOV": 5.37,
    "DEC": 5.07
  },
  {
    "LONG": -53.28,
    "LAT": -17.83,
    "CIDADE": "Alto Taquari",
    "UF": "MATO GROSSO",
    "anual": 5.26,
    "JAN": 4.96,
    "FEB": 5.28,
    "MAR": 5.18,
    "APR": 5.4,
    "MAY": 5.27,
    "JUN": 5.2,
    "JUL": 5.3,
    "AUG": 6.01,
    "SEP": 5.47,
    "OCT": 5.05,
    "NOV": 4.95,
    "DEC": 5.05
  },
  {
    "LONG": -53.22,
    "LAT": -17.32,
    "CIDADE": "Alto Araguaia",
    "UF": "MATO GROSSO",
    "anual": 5.35,
    "JAN": 5.01,
    "FEB": 5.34,
    "MAR": 5.24,
    "APR": 5.59,
    "MAY": 5.41,
    "JUN": 5.32,
    "JUL": 5.4,
    "AUG": 6.11,
    "SEP": 5.56,
    "OCT": 5.11,
    "NOV": 5.02,
    "DEC": 5.06
  },
  {
    "LONG": -5.41,
    "LAT": -17.2,
    "CIDADE": "Itiquira",
    "UF": "MATO GROSSO",
    "anual": 5.35,
    "JAN": 5.11,
    "FEB": 5.28,
    "MAR": 5.32,
    "APR": 5.47,
    "MAY": 5.3,
    "JUN": 5.26,
    "JUL": 5.43,
    "AUG": 6.1,
    "SEP": 5.45,
    "OCT": 5.13,
    "NOV": 5.18,
    "DEC": 5.17
  },
  {
    "LONG": -53.53,
    "LAT": -16.95,
    "CIDADE": "Alto Garças",
    "UF": "MATO GROSSO",
    "anual": 5.32,
    "JAN": 5.04,
    "FEB": 5.3,
    "MAR": 5.19,
    "APR": 5.51,
    "MAY": 5.4,
    "JUN": 5.26,
    "JUL": 5.39,
    "AUG": 5.97,
    "SEP": 5.5,
    "OCT": 5.14,
    "NOV": 5.06,
    "DEC": 5.1
  },
  {
    "LONG": -53.03,
    "LAT": -16.86,
    "CIDADE": "Araguainha",
    "UF": "MATO GROSSO",
    "anual": 5.42,
    "JAN": 4.98,
    "FEB": 5.39,
    "MAR": 5.39,
    "APR": 5.71,
    "MAY": 5.58,
    "JUN": 5.36,
    "JUL": 5.43,
    "AUG": 6.16,
    "SEP": 5.61,
    "OCT": 5.18,
    "NOV": 5.15,
    "DEC": 5.16
  },
  {
    "LONG": -52.84,
    "LAT": -16.77,
    "CIDADE": "Ponte Branca",
    "UF": "MATO GROSSO",
    "anual": 5.43,
    "JAN": 5.05,
    "FEB": 5.46,
    "MAR": 5.32,
    "APR": 5.71,
    "MAY": 5.61,
    "JUN": 5.35,
    "JUL": 5.35,
    "AUG": 6.15,
    "SEP": 5.61,
    "OCT": 5.22,
    "NOV": 5.12,
    "DEC": 5.17
  },
  {
    "LONG": -54.48,
    "LAT": -1.66,
    "CIDADE": "Pedra Preta",
    "UF": "MATO GROSSO",
    "anual": 5.44,
    "JAN": 5.18,
    "FEB": 5.41,
    "MAR": 5.49,
    "APR": 5.64,
    "MAY": 5.44,
    "JUN": 5.36,
    "JUL": 5.44,
    "AUG": 6.13,
    "SEP": 5.45,
    "OCT": 5.23,
    "NOV": 5.25,
    "DEC": 5.2
  },
  {
    "LONG": -54.64,
    "LAT": -16.47,
    "CIDADE": "Rondonópolis",
    "UF": "MATO GROSSO",
    "anual": 5.34,
    "JAN": 5.09,
    "FEB": 5.29,
    "MAR": 5.35,
    "APR": 5.52,
    "MAY": 5.33,
    "JUN": 5.26,
    "JUL": 5.36,
    "AUG": 6.11,
    "SEP": 5.42,
    "OCT": 5.13,
    "NOV": 5.08,
    "DEC": 5.1
  },
  {
    "LONG": -54.25,
    "LAT": -16.47,
    "CIDADE": "São José do Povo",
    "UF": "MATO GROSSO",
    "anual": 5.46,
    "JAN": 5.18,
    "FEB": 5.4,
    "MAR": 5.49,
    "APR": 5.66,
    "MAY": 5.48,
    "JUN": 5.4,
    "JUL": 5.5,
    "AUG": 6.17,
    "SEP": 5.49,
    "OCT": 5.27,
    "NOV": 5.26,
    "DEC": 5.23
  },
  {
    "LONG": -52.69,
    "LAT": -16.49,
    "CIDADE": "Ribeirãozinho",
    "UF": "MATO GROSSO",
    "anual": 5.43,
    "JAN": 5.15,
    "FEB": 5.43,
    "MAR": 5.35,
    "APR": 5.75,
    "MAY": 5.59,
    "JUN": 5.37,
    "JUL": 5.34,
    "AUG": 5.99,
    "SEP": 5.6,
    "OCT": 5.24,
    "NOV": 5.2,
    "DEC": 5.17
  },
  {
    "LONG": -56.63,
    "LAT": -16.27,
    "CIDADE": "Poconé",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.2,
    "MAR": 5.24,
    "APR": 5.26,
    "MAY": 4.87,
    "JUN": 4.97,
    "JUL": 5.12,
    "AUG": 5.82,
    "SEP": 5.23,
    "OCT": 5.19,
    "NOV": 5.12,
    "DEC": 5.23
  },
  {
    "LONG": -53.76,
    "LAT": -16.35,
    "CIDADE": "Guiratinga",
    "UF": "MATO GROSSO",
    "anual": 5.41,
    "JAN": 5,
    "FEB": 5.21,
    "MAR": 5.32,
    "APR": 5.58,
    "MAY": 5.49,
    "JUN": 5.45,
    "JUL": 5.57,
    "AUG": 6.26,
    "SEP": 5.59,
    "OCT": 5.22,
    "NOV": 5.13,
    "DEC": 5.07
  },
  {
    "LONG": -55.97,
    "LAT": -16.2,
    "CIDADE": "Barão de Melgaço",
    "UF": "MATO GROSSO",
    "anual": 5.32,
    "JAN": 5.17,
    "FEB": 5.33,
    "MAR": 5.43,
    "APR": 5.59,
    "MAY": 5.09,
    "JUN": 5.2,
    "JUL": 5.22,
    "AUG": 6.06,
    "SEP": 5.26,
    "OCT": 5.17,
    "NOV": 5.19,
    "DEC": 5.18
  },
  {
    "LONG": -52.56,
    "LAT": -1.62,
    "CIDADE": "Torixoréu",
    "UF": "MATO GROSSO",
    "anual": 5.45,
    "JAN": 5.07,
    "FEB": 5.4,
    "MAR": 5.34,
    "APR": 5.8,
    "MAY": 5.65,
    "JUN": 5.41,
    "JUL": 5.46,
    "AUG": 6.06,
    "SEP": 5.64,
    "OCT": 5.31,
    "NOV": 5.14,
    "DEC": 5.1
  },
  {
    "LONG": -57.68,
    "LAT": -16.08,
    "CIDADE": "Cáceres",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.16,
    "MAR": 5.25,
    "APR": 5.25,
    "MAY": 4.86,
    "JUN": 5.02,
    "JUL": 5.08,
    "AUG": 5.74,
    "SEP": 5.33,
    "OCT": 5.22,
    "NOV": 5.17,
    "DEC": 5.16
  },
  {
    "LONG": -54.89,
    "LAT": -16.05,
    "CIDADE": "Juscimeira",
    "UF": "MATO GROSSO",
    "anual": 5.32,
    "JAN": 5.03,
    "FEB": 5.25,
    "MAR": 5.26,
    "APR": 5.51,
    "MAY": 5.33,
    "JUN": 5.35,
    "JUL": 5.4,
    "AUG": 6.2,
    "SEP": 5.38,
    "OCT": 5.06,
    "NOV": 5.06,
    "DEC": 5.06
  },
  {
    "LONG": -53.55,
    "LAT": -16.08,
    "CIDADE": "Tesouro",
    "UF": "MATO GROSSO",
    "anual": 5.42,
    "JAN": 5.08,
    "FEB": 5.3,
    "MAR": 5.28,
    "APR": 5.7,
    "MAY": 5.58,
    "JUN": 5.45,
    "JUL": 5.55,
    "AUG": 6.17,
    "SEP": 5.61,
    "OCT": 5.21,
    "NOV": 5.09,
    "DEC": 5.04
  },
  {
    "LONG": -54.97,
    "LAT": -15.96,
    "CIDADE": "Jaciara",
    "UF": "MATO GROSSO",
    "anual": 5.3,
    "JAN": 4.94,
    "FEB": 5.15,
    "MAR": 5.2,
    "APR": 5.54,
    "MAY": 5.35,
    "JUN": 5.39,
    "JUL": 5.41,
    "AUG": 6.21,
    "SEP": 5.36,
    "OCT": 5,
    "NOV": 5.02,
    "DEC": 4.99
  },
  {
    "LONG": -54.92,
    "LAT": -16,
    "CIDADE": "São Pedro da Cipa",
    "UF": "MATO GROSSO",
    "anual": 5.3,
    "JAN": 4.94,
    "FEB": 5.15,
    "MAR": 5.2,
    "APR": 5.54,
    "MAY": 5.35,
    "JUN": 5.39,
    "JUL": 5.41,
    "AUG": 6.21,
    "SEP": 5.36,
    "OCT": 5,
    "NOV": 5.02,
    "DEC": 4.99
  },
  {
    "LONG": -58.46,
    "LAT": -15.86,
    "CIDADE": "Porto Esperidião",
    "UF": "MATO GROSSO",
    "anual": 5.1,
    "JAN": 5.02,
    "FEB": 5.11,
    "MAR": 5.13,
    "APR": 5.17,
    "MAY": 4.64,
    "JUN": 4.86,
    "JUL": 4.96,
    "AUG": 5.5,
    "SEP": 5.27,
    "OCT": 5.22,
    "NOV": 5.16,
    "DEC": 5.11
  },
  {
    "LONG": -56.08,
    "LAT": -15.86,
    "CIDADE": "Santo Antônio do Leverger",
    "UF": "MATO GROSSO",
    "anual": 5.29,
    "JAN": 5.1,
    "FEB": 5.25,
    "MAR": 5.35,
    "APR": 5.47,
    "MAY": 5.12,
    "JUN": 5.16,
    "JUL": 5.24,
    "AUG": 6.05,
    "SEP": 5.27,
    "OCT": 5.14,
    "NOV": 5.15,
    "DEC": 5.21
  },
  {
    "LONG": -5.23,
    "LAT": -15.89,
    "CIDADE": "Barra do Garças",
    "UF": "MATO GROSSO",
    "anual": 5.42,
    "JAN": 5.03,
    "FEB": 5.37,
    "MAR": 5.28,
    "APR": 5.76,
    "MAY": 5.7,
    "JUN": 5.43,
    "JUL": 5.5,
    "AUG": 6.09,
    "SEP": 5.49,
    "OCT": 5.34,
    "NOV": 5.05,
    "DEC": 5.04
  },
  {
    "LONG": -52.26,
    "LAT": -1.59,
    "CIDADE": "Pontal do Araguaia",
    "UF": "MATO GROSSO",
    "anual": 5.42,
    "JAN": 5.03,
    "FEB": 5.37,
    "MAR": 5.28,
    "APR": 5.76,
    "MAY": 5.7,
    "JUN": 5.43,
    "JUL": 5.5,
    "AUG": 6.09,
    "SEP": 5.49,
    "OCT": 5.34,
    "NOV": 5.05,
    "DEC": 5.04
  },
  {
    "LONG": -58.31,
    "LAT": -15.77,
    "CIDADE": "Glória D'Oeste",
    "UF": "MATO GROSSO",
    "anual": 5.09,
    "JAN": 5,
    "FEB": 5.08,
    "MAR": 5.12,
    "APR": 5.17,
    "MAY": 4.65,
    "JUN": 4.85,
    "JUL": 4.97,
    "AUG": 5.55,
    "SEP": 5.26,
    "OCT": 5.21,
    "NOV": 5.15,
    "DEC": 5.13
  },
  {
    "LONG": -56.34,
    "LAT": -15.77,
    "CIDADE": "Nossa Senhora do Livramento",
    "UF": "MATO GROSSO",
    "anual": 5.24,
    "JAN": 5.04,
    "FEB": 5.19,
    "MAR": 5.24,
    "APR": 5.33,
    "MAY": 5,
    "JUN": 5.15,
    "JUL": 5.25,
    "AUG": 5.97,
    "SEP": 5.29,
    "OCT": 5.08,
    "NOV": 5.16,
    "DEC": 5.16
  },
  {
    "LONG": -54.92,
    "LAT": -15.81,
    "CIDADE": "Dom Aquino",
    "UF": "MATO GROSSO",
    "anual": 5.27,
    "JAN": 4.9,
    "FEB": 5.06,
    "MAR": 5.15,
    "APR": 5.53,
    "MAY": 5.35,
    "JUN": 5.42,
    "JUL": 5.44,
    "AUG": 6.23,
    "SEP": 5.31,
    "OCT": 4.95,
    "NOV": 4.95,
    "DEC": 4.98
  },
  {
    "LONG": -54.4,
    "LAT": -15.83,
    "CIDADE": "Poxoréu",
    "UF": "MATO GROSSO",
    "anual": 5.31,
    "JAN": 4.91,
    "FEB": 5.1,
    "MAR": 5.2,
    "APR": 5.49,
    "MAY": 5.42,
    "JUN": 5.42,
    "JUL": 5.53,
    "AUG": 6.3,
    "SEP": 5.38,
    "OCT": 5.04,
    "NOV": 5.03,
    "DEC": 4.88
  },
  {
    "LONG": -58.09,
    "LAT": -15.67,
    "CIDADE": "Mirassol d'Oeste",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.98,
    "FEB": 4.99,
    "MAR": 5.08,
    "APR": 5.13,
    "MAY": 4.71,
    "JUN": 4.94,
    "JUL": 4.98,
    "AUG": 5.59,
    "SEP": 5.22,
    "OCT": 5.1,
    "NOV": 5.01,
    "DEC": 5.01
  },
  {
    "LONG": -52.76,
    "LAT": -15.71,
    "CIDADE": "General Carneiro",
    "UF": "MATO GROSSO",
    "anual": 5.44,
    "JAN": 5,
    "FEB": 5.34,
    "MAR": 5.34,
    "APR": 5.8,
    "MAY": 5.72,
    "JUN": 5.44,
    "JUL": 5.59,
    "AUG": 6.12,
    "SEP": 5.59,
    "OCT": 5.28,
    "NOV": 5.01,
    "DEC": 5.01
  },
  {
    "LONG": -51.84,
    "LAT": -15.72,
    "CIDADE": "Araguaiana",
    "UF": "MATO GROSSO",
    "anual": 5.42,
    "JAN": 5.14,
    "FEB": 5.49,
    "MAR": 5.33,
    "APR": 5.8,
    "MAY": 5.63,
    "JUN": 5.3,
    "JUL": 5.48,
    "AUG": 5.82,
    "SEP": 5.56,
    "OCT": 5.37,
    "NOV": 5.03,
    "DEC": 5.07
  },
  {
    "LONG": -58.18,
    "LAT": -1.56,
    "CIDADE": "São José dos Quatro Marcos",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.94,
    "FEB": 5.01,
    "MAR": 5.06,
    "APR": 5.12,
    "MAY": 4.69,
    "JUN": 4.95,
    "JUL": 4.97,
    "AUG": 5.58,
    "SEP": 5.23,
    "OCT": 5.08,
    "NOV": 5.02,
    "DEC": 5.02
  },
  {
    "LONG": -57.91,
    "LAT": -15.61,
    "CIDADE": "Curvelândia",
    "UF": "MATO GROSSO",
    "anual": 5.08,
    "JAN": 4.96,
    "FEB": 4.98,
    "MAR": 5.09,
    "APR": 5.16,
    "MAY": 4.77,
    "JUN": 4.98,
    "JUL": 5.03,
    "AUG": 5.64,
    "SEP": 5.23,
    "OCT": 5.05,
    "NOV": 5.02,
    "DEC": 5.01
  },
  {
    "LONG": -56.13,
    "LAT": -15.65,
    "CIDADE": "Várzea Grande",
    "UF": "MATO GROSSO",
    "anual": 5.27,
    "JAN": 5.06,
    "FEB": 5.16,
    "MAR": 5.27,
    "APR": 5.35,
    "MAY": 5.07,
    "JUN": 5.15,
    "JUL": 5.27,
    "AUG": 6,
    "SEP": 5.31,
    "OCT": 5.17,
    "NOV": 5.18,
    "DEC": 5.23
  },
  {
    "LONG": -56.1,
    "LAT": -15.6,
    "CIDADE": "Cuiabá",
    "UF": "MATO GROSSO",
    "anual": 5.24,
    "JAN": 5.02,
    "FEB": 5.12,
    "MAR": 5.24,
    "APR": 5.31,
    "MAY": 5.04,
    "JUN": 5.14,
    "JUL": 5.26,
    "AUG": 5.99,
    "SEP": 5.32,
    "OCT": 5.17,
    "NOV": 5.16,
    "DEC": 5.17
  },
  {
    "LONG": -54.3,
    "LAT": -15.55,
    "CIDADE": "Primavera do Leste",
    "UF": "MATO GROSSO",
    "anual": 5.25,
    "JAN": 4.98,
    "FEB": 5.05,
    "MAR": 5.06,
    "APR": 5.44,
    "MAY": 5.41,
    "JUN": 5.32,
    "JUL": 5.47,
    "AUG": 6.07,
    "SEP": 5.32,
    "OCT": 4.99,
    "NOV": 4.93,
    "DEC": 4.91
  },
  {
    "LONG": -58.58,
    "LAT": -15.5,
    "CIDADE": "Indiavaí",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.84,
    "FEB": 4.89,
    "MAR": 4.98,
    "APR": 5.07,
    "MAY": 4.62,
    "JUN": 4.9,
    "JUL": 4.92,
    "AUG": 5.59,
    "SEP": 5.16,
    "OCT": 5.1,
    "NOV": 5.07,
    "DEC": 4.91
  },
  {
    "LONG": -58.34,
    "LAT": -15.46,
    "CIDADE": "Araputanga",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.89,
    "FEB": 4.9,
    "MAR": 4.99,
    "APR": 5.08,
    "MAY": 4.63,
    "JUN": 4.92,
    "JUL": 4.97,
    "AUG": 5.65,
    "SEP": 5.19,
    "OCT": 5.03,
    "NOV": 5,
    "DEC": 4.93
  },
  {
    "LONG": -55.75,
    "LAT": -15.46,
    "CIDADE": "Chapada dos Guimarães",
    "UF": "MATO GROSSO",
    "anual": 5.11,
    "JAN": 4.73,
    "FEB": 4.82,
    "MAR": 4.91,
    "APR": 5.15,
    "MAY": 5.07,
    "JUN": 5.26,
    "JUL": 5.39,
    "AUG": 6.06,
    "SEP": 5.26,
    "OCT": 4.95,
    "NOV": 4.88,
    "DEC": 4.87
  },
  {
    "LONG": -5.52,
    "LAT": -15.55,
    "CIDADE": "Campo Verde",
    "UF": "MATO GROSSO",
    "anual": 5.2,
    "JAN": 4.9,
    "FEB": 4.97,
    "MAR": 4.98,
    "APR": 5.34,
    "MAY": 5.32,
    "JUN": 5.33,
    "JUL": 5.43,
    "AUG": 6,
    "SEP": 5.29,
    "OCT": 4.93,
    "NOV": 4.99,
    "DEC": 4.93
  },
  {
    "LONG": -58.74,
    "LAT": -1.54,
    "CIDADE": "Figueirópolis D'Oeste",
    "UF": "MATO GROSSO",
    "anual": 4.99,
    "JAN": 4.8,
    "FEB": 4.84,
    "MAR": 4.91,
    "APR": 5.02,
    "MAY": 4.6,
    "JUN": 4.92,
    "JUL": 4.98,
    "AUG": 5.59,
    "SEP": 5.19,
    "OCT": 5.07,
    "NOV": 5.05,
    "DEC": 4.91
  },
  {
    "LONG": -59.06,
    "LAT": -15.3,
    "CIDADE": "Vale de São Domingos",
    "UF": "MATO GROSSO",
    "anual": 5.05,
    "JAN": 4.86,
    "FEB": 4.84,
    "MAR": 4.87,
    "APR": 5.1,
    "MAY": 4.7,
    "JUN": 5.01,
    "JUL": 5.04,
    "AUG": 5.56,
    "SEP": 5.26,
    "OCT": 5.19,
    "NOV": 5.13,
    "DEC": 5.02
  },
  {
    "LONG": -58.87,
    "LAT": -15.34,
    "CIDADE": "Jauru",
    "UF": "MATO GROSSO",
    "anual": 5,
    "JAN": 4.79,
    "FEB": 4.79,
    "MAR": 4.86,
    "APR": 5,
    "MAY": 4.62,
    "JUN": 4.94,
    "JUL": 4.98,
    "AUG": 5.65,
    "SEP": 5.19,
    "OCT": 5.08,
    "NOV": 5.1,
    "DEC": 4.94
  },
  {
    "LONG": -58,
    "LAT": -15.32,
    "CIDADE": "Lambari D'Oeste",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.72,
    "FEB": 4.85,
    "MAR": 4.96,
    "APR": 5.1,
    "MAY": 4.78,
    "JUN": 5.03,
    "JUL": 5.07,
    "AUG": 5.72,
    "SEP": 5.17,
    "OCT": 4.96,
    "NOV": 4.93,
    "DEC": 4.79
  },
  {
    "LONG": -57.2,
    "LAT": -15.33,
    "CIDADE": "Porto Estrela",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 4.96,
    "FEB": 5.06,
    "MAR": 5.2,
    "APR": 5.25,
    "MAY": 5.01,
    "JUN": 5.15,
    "JUL": 5.23,
    "AUG": 5.65,
    "SEP": 5.3,
    "OCT": 5.19,
    "NOV": 5.14,
    "DEC": 5.11
  },
  {
    "LONG": -59.34,
    "LAT": -15.23,
    "CIDADE": "Pontes e Lacerda",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.8,
    "FEB": 4.82,
    "MAR": 4.93,
    "APR": 5.16,
    "MAY": 4.78,
    "JUN": 5.02,
    "JUL": 5.1,
    "AUG": 5.55,
    "SEP": 5.25,
    "OCT": 5.17,
    "NOV": 5.14,
    "DEC": 4.98
  },
  {
    "LONG": -58.13,
    "LAT": -15.25,
    "CIDADE": "Rio Branco",
    "UF": "MATO GROSSO",
    "anual": 4.92,
    "JAN": 4.63,
    "FEB": 4.71,
    "MAR": 4.78,
    "APR": 5,
    "MAY": 4.68,
    "JUN": 4.99,
    "JUL": 5.05,
    "AUG": 5.7,
    "SEP": 5.09,
    "OCT": 4.87,
    "NOV": 4.86,
    "DEC": 4.67
  },
  {
    "LONG": -56.49,
    "LAT": -15.24,
    "CIDADE": "Jangada",
    "UF": "MATO GROSSO",
    "anual": 5.26,
    "JAN": 4.97,
    "FEB": 5.16,
    "MAR": 5.24,
    "APR": 5.34,
    "MAY": 5.09,
    "JUN": 5.25,
    "JUL": 5.33,
    "AUG": 5.97,
    "SEP": 5.3,
    "OCT": 5.14,
    "NOV": 5.18,
    "DEC": 5.16
  },
  {
    "LONG": -56.36,
    "LAT": -15.19,
    "CIDADE": "Acorizal",
    "UF": "MATO GROSSO",
    "anual": 5.27,
    "JAN": 5,
    "FEB": 5.16,
    "MAR": 5.24,
    "APR": 5.35,
    "MAY": 5.09,
    "JUN": 5.23,
    "JUL": 5.32,
    "AUG": 6,
    "SEP": 5.29,
    "OCT": 5.16,
    "NOV": 5.21,
    "DEC": 5.19
  },
  {
    "LONG": -58.38,
    "LAT": -15.12,
    "CIDADE": "Reserva do Cabaçal",
    "UF": "MATO GROSSO",
    "anual": 4.85,
    "JAN": 4.52,
    "FEB": 4.59,
    "MAR": 4.66,
    "APR": 4.87,
    "MAY": 4.59,
    "JUN": 4.96,
    "JUL": 5.02,
    "AUG": 5.71,
    "SEP": 5.04,
    "OCT": 4.82,
    "NOV": 4.79,
    "DEC": 4.65
  },
  {
    "LONG": -58.13,
    "LAT": -15.13,
    "CIDADE": "Salto do Céu",
    "UF": "MATO GROSSO",
    "anual": 4.92,
    "JAN": 4.55,
    "FEB": 4.67,
    "MAR": 4.78,
    "APR": 5.02,
    "MAY": 4.73,
    "JUN": 5.02,
    "JUL": 5.08,
    "AUG": 5.71,
    "SEP": 5.09,
    "OCT": 4.88,
    "NOV": 4.85,
    "DEC": 4.65
  },
  {
    "LONG": -57.19,
    "LAT": -15.07,
    "CIDADE": "Barra do Bugres",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 4.95,
    "FEB": 5.07,
    "MAR": 5.15,
    "APR": 5.29,
    "MAY": 4.99,
    "JUN": 5.17,
    "JUL": 5.22,
    "AUG": 5.75,
    "SEP": 5.29,
    "OCT": 5.18,
    "NOV": 5.13,
    "DEC": 5.06
  },
  {
    "LONG": -59.95,
    "LAT": -15,
    "CIDADE": "Vila Bela da Santíssima Trindade",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.73,
    "FEB": 4.72,
    "MAR": 4.96,
    "APR": 5.14,
    "MAY": 4.73,
    "JUN": 5,
    "JUL": 5.1,
    "AUG": 5.62,
    "SEP": 5.19,
    "OCT": 5.13,
    "NOV": 5.01,
    "DEC": 4.81
  },
  {
    "LONG": -54.97,
    "LAT": -14.93,
    "CIDADE": "Nova Brasilândia",
    "UF": "MATO GROSSO",
    "anual": 5.32,
    "JAN": 4.99,
    "FEB": 5.08,
    "MAR": 5.22,
    "APR": 5.55,
    "MAY": 5.45,
    "JUN": 5.45,
    "JUL": 5.46,
    "AUG": 6.07,
    "SEP": 5.44,
    "OCT": 5.13,
    "NOV": 5,
    "DEC": 4.99
  },
  {
    "LONG": -53.03,
    "LAT": -14.91,
    "CIDADE": "Novo São Joaquim",
    "UF": "MATO GROSSO",
    "anual": 5.44,
    "JAN": 5.03,
    "FEB": 5.31,
    "MAR": 5.36,
    "APR": 5.82,
    "MAY": 5.67,
    "JUN": 5.48,
    "JUL": 5.69,
    "AUG": 6.03,
    "SEP": 5.54,
    "OCT": 5.3,
    "NOV": 4.98,
    "DEC": 5.03
  },
  {
    "LONG": -57.28,
    "LAT": -14.78,
    "CIDADE": "Nova Olímpia",
    "UF": "MATO GROSSO",
    "anual": 5.09,
    "JAN": 4.83,
    "FEB": 4.96,
    "MAR": 5.02,
    "APR": 5.13,
    "MAY": 4.9,
    "JUN": 5.06,
    "JUL": 5.18,
    "AUG": 5.7,
    "SEP": 5.15,
    "OCT": 5.09,
    "NOV": 5.05,
    "DEC": 4.96
  },
  {
    "LONG": -5.64,
    "LAT": -14.83,
    "CIDADE": "Rosário Oeste",
    "UF": "MATO GROSSO",
    "anual": 5.18,
    "JAN": 4.76,
    "FEB": 4.99,
    "MAR": 5.07,
    "APR": 5.2,
    "MAY": 5.07,
    "JUN": 5.19,
    "JUL": 5.35,
    "AUG": 5.97,
    "SEP": 5.31,
    "OCT": 5.08,
    "NOV": 5.09,
    "DEC": 5.05
  },
  {
    "LONG": -53.61,
    "LAT": -14.81,
    "CIDADE": "Santo Antônio do Leste",
    "UF": "MATO GROSSO",
    "anual": 5.34,
    "JAN": 4.97,
    "FEB": 5.24,
    "MAR": 5.21,
    "APR": 5.55,
    "MAY": 5.49,
    "JUN": 5.43,
    "JUL": 5.62,
    "AUG": 6.04,
    "SEP": 5.44,
    "OCT": 5.16,
    "NOV": 4.98,
    "DEC": 4.96
  },
  {
    "LONG": -57.05,
    "LAT": -1.47,
    "CIDADE": "Denise",
    "UF": "MATO GROSSO",
    "anual": 5.1,
    "JAN": 4.8,
    "FEB": 5,
    "MAR": 5.05,
    "APR": 5.13,
    "MAY": 4.93,
    "JUN": 5.13,
    "JUL": 5.24,
    "AUG": 5.76,
    "SEP": 5.15,
    "OCT": 5.08,
    "NOV": 4.99,
    "DEC": 4.95
  },
  {
    "LONG": -56.33,
    "LAT": -14.72,
    "CIDADE": "Nobres",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 4.81,
    "FEB": 4.98,
    "MAR": 5.09,
    "APR": 5.18,
    "MAY": 5.09,
    "JUN": 5.23,
    "JUL": 5.41,
    "AUG": 6.01,
    "SEP": 5.34,
    "OCT": 5.09,
    "NOV": 5.06,
    "DEC": 5.03
  },
  {
    "LONG": -54.78,
    "LAT": -14.66,
    "CIDADE": "Planalto da Serra",
    "UF": "MATO GROSSO",
    "anual": 5.32,
    "JAN": 4.91,
    "FEB": 5.1,
    "MAR": 5.2,
    "APR": 5.6,
    "MAY": 5.46,
    "JUN": 5.47,
    "JUL": 5.53,
    "AUG": 6.08,
    "SEP": 5.38,
    "OCT": 5.17,
    "NOV": 5.02,
    "DEC": 4.93
  },
  {
    "LONG": -52.35,
    "LAT": -14.67,
    "CIDADE": "Nova Xavantina",
    "UF": "MATO GROSSO",
    "anual": 5.45,
    "JAN": 5.06,
    "FEB": 5.41,
    "MAR": 5.39,
    "APR": 5.72,
    "MAY": 5.62,
    "JUN": 5.39,
    "JUL": 5.62,
    "AUG": 6.02,
    "SEP": 5.66,
    "OCT": 5.38,
    "NOV": 5.06,
    "DEC": 5.02
  },
  {
    "LONG": -57.49,
    "LAT": -14.62,
    "CIDADE": "Tangará da Serra",
    "UF": "MATO GROSSO",
    "anual": 5.08,
    "JAN": 4.73,
    "FEB": 4.9,
    "MAR": 5.02,
    "APR": 5.11,
    "MAY": 4.91,
    "JUN": 5.1,
    "JUL": 5.23,
    "AUG": 5.8,
    "SEP": 5.22,
    "OCT": 5.08,
    "NOV": 4.94,
    "DEC": 4.94
  },
  {
    "LONG": -59.59,
    "LAT": -14.48,
    "CIDADE": "Nova Lacerda",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.66,
    "FEB": 4.66,
    "MAR": 4.79,
    "APR": 5.01,
    "MAY": 4.82,
    "JUN": 5.02,
    "JUL": 5.19,
    "AUG": 5.58,
    "SEP": 5.18,
    "OCT": 5.13,
    "NOV": 5.07,
    "DEC": 4.94
  },
  {
    "LONG": -59.54,
    "LAT": -14.54,
    "CIDADE": "Conquista D'Oeste",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.66,
    "FEB": 4.66,
    "MAR": 4.79,
    "APR": 5.01,
    "MAY": 4.82,
    "JUN": 5.02,
    "JUL": 5.19,
    "AUG": 5.58,
    "SEP": 5.18,
    "OCT": 5.13,
    "NOV": 5.07,
    "DEC": 4.94
  },
  {
    "LONG": -57.01,
    "LAT": -14.49,
    "CIDADE": "Santo Afonso",
    "UF": "MATO GROSSO",
    "anual": 5.12,
    "JAN": 4.75,
    "FEB": 4.95,
    "MAR": 5.03,
    "APR": 5.17,
    "MAY": 4.98,
    "JUN": 5.15,
    "JUL": 5.28,
    "AUG": 5.83,
    "SEP": 5.27,
    "OCT": 5.1,
    "NOV": 4.99,
    "DEC": 4.97
  },
  {
    "LONG": -56.8,
    "LAT": -14.45,
    "CIDADE": "Nortelândia",
    "UF": "MATO GROSSO",
    "anual": 5.16,
    "JAN": 4.88,
    "FEB": 5.01,
    "MAR": 5.02,
    "APR": 5.17,
    "MAY": 5.08,
    "JUN": 5.19,
    "JUL": 5.33,
    "AUG": 5.88,
    "SEP": 5.26,
    "OCT": 5.11,
    "NOV": 5,
    "DEC": 4.94
  },
  {
    "LONG": -56.84,
    "LAT": -14.46,
    "CIDADE": "Arenápolis",
    "UF": "MATO GROSSO",
    "anual": 5.16,
    "JAN": 4.88,
    "FEB": 5.01,
    "MAR": 5.02,
    "APR": 5.17,
    "MAY": 5.08,
    "JUN": 5.19,
    "JUL": 5.33,
    "AUG": 5.88,
    "SEP": 5.26,
    "OCT": 5.11,
    "NOV": 5,
    "DEC": 4.94
  },
  {
    "LONG": -56.48,
    "LAT": -14.51,
    "CIDADE": "Alto Paraguai",
    "UF": "MATO GROSSO",
    "anual": 5.2,
    "JAN": 4.84,
    "FEB": 4.99,
    "MAR": 5.08,
    "APR": 5.24,
    "MAY": 5.1,
    "JUN": 5.26,
    "JUL": 5.41,
    "AUG": 6.03,
    "SEP": 5.31,
    "OCT": 5.1,
    "NOV": 5.04,
    "DEC": 5
  },
  {
    "LONG": -5.29,
    "LAT": -14.52,
    "CIDADE": "Campinápolis",
    "UF": "MATO GROSSO",
    "anual": 5.44,
    "JAN": 5.02,
    "FEB": 5.32,
    "MAR": 5.34,
    "APR": 5.71,
    "MAY": 5.62,
    "JUN": 5.53,
    "JUL": 5.79,
    "AUG": 6.14,
    "SEP": 5.54,
    "OCT": 5.26,
    "NOV": 4.98,
    "DEC": 4.99
  },
  {
    "LONG": -56.98,
    "LAT": -14.37,
    "CIDADE": "Nova Marilândia",
    "UF": "MATO GROSSO",
    "anual": 5.15,
    "JAN": 4.79,
    "FEB": 4.96,
    "MAR": 5.02,
    "APR": 5.19,
    "MAY": 5.05,
    "JUN": 5.21,
    "JUL": 5.34,
    "AUG": 5.97,
    "SEP": 5.28,
    "OCT": 5.09,
    "NOV": 4.97,
    "DEC": 4.94
  },
  {
    "LONG": -56.44,
    "LAT": -14.4,
    "CIDADE": "Diamantino",
    "UF": "MATO GROSSO",
    "anual": 5.18,
    "JAN": 4.85,
    "FEB": 4.95,
    "MAR": 4.99,
    "APR": 5.19,
    "MAY": 5.14,
    "JUN": 5.24,
    "JUL": 5.42,
    "AUG": 6.03,
    "SEP": 5.31,
    "OCT": 5.1,
    "NOV": 4.94,
    "DEC": 4.98
  },
  {
    "LONG": -54.05,
    "LAT": -14.43,
    "CIDADE": "Paranatinga",
    "UF": "MATO GROSSO",
    "anual": 5.31,
    "JAN": 4.91,
    "FEB": 5.13,
    "MAR": 5.13,
    "APR": 5.56,
    "MAY": 5.43,
    "JUN": 5.44,
    "JUL": 5.6,
    "AUG": 6.05,
    "SEP": 5.4,
    "OCT": 5.22,
    "NOV": 4.94,
    "DEC": 4.93
  },
  {
    "LONG": -51,
    "LAT": -1.44,
    "CIDADE": "Cocalinho",
    "UF": "MATO GROSSO",
    "anual": 5.41,
    "JAN": 5.01,
    "FEB": 5.43,
    "MAR": 5.36,
    "APR": 5.68,
    "MAY": 5.52,
    "JUN": 5.34,
    "JUL": 5.63,
    "AUG": 5.98,
    "SEP": 5.67,
    "OCT": 5.37,
    "NOV": 4.99,
    "DEC": 4.97
  },
  {
    "LONG": -52.16,
    "LAT": -14.05,
    "CIDADE": "Água Boa",
    "UF": "MATO GROSSO",
    "anual": 5.4,
    "JAN": 4.87,
    "FEB": 5.32,
    "MAR": 5.24,
    "APR": 5.62,
    "MAY": 5.63,
    "JUN": 5.45,
    "JUL": 5.78,
    "AUG": 6.07,
    "SEP": 5.61,
    "OCT": 5.32,
    "NOV": 4.95,
    "DEC": 4.94
  },
  {
    "LONG": -51.79,
    "LAT": -1.4,
    "CIDADE": "Nova Nazaré",
    "UF": "MATO GROSSO",
    "anual": 5.45,
    "JAN": 5,
    "FEB": 5.38,
    "MAR": 5.34,
    "APR": 5.68,
    "MAY": 5.64,
    "JUN": 5.48,
    "JUL": 5.79,
    "AUG": 6.17,
    "SEP": 5.67,
    "OCT": 5.35,
    "NOV": 4.98,
    "DEC": 4.94
  },
  {
    "LONG": -56.08,
    "LAT": -13.82,
    "CIDADE": "Nova Mutum",
    "UF": "MATO GROSSO",
    "anual": 5.15,
    "JAN": 4.83,
    "FEB": 4.88,
    "MAR": 4.97,
    "APR": 5.17,
    "MAY": 5.13,
    "JUN": 5.22,
    "JUL": 5.46,
    "AUG": 5.84,
    "SEP": 5.32,
    "OCT": 5.13,
    "NOV": 4.95,
    "DEC": 4.95
  },
  {
    "LONG": -55.27,
    "LAT": -13.82,
    "CIDADE": "Santa Rita do Trivelato",
    "UF": "MATO GROSSO",
    "anual": 5.21,
    "JAN": 4.82,
    "FEB": 4.99,
    "MAR": 5.02,
    "APR": 5.38,
    "MAY": 5.37,
    "JUN": 5.38,
    "JUL": 5.49,
    "AUG": 5.88,
    "SEP": 5.28,
    "OCT": 5.15,
    "NOV": 4.91,
    "DEC": 4.89
  },
  {
    "LONG": -59.79,
    "LAT": -13.66,
    "CIDADE": "Comodoro",
    "UF": "MATO GROSSO",
    "anual": 4.79,
    "JAN": 4.39,
    "FEB": 4.39,
    "MAR": 4.52,
    "APR": 4.76,
    "MAY": 4.69,
    "JUN": 4.9,
    "JUL": 5.16,
    "AUG": 5.48,
    "SEP": 5.01,
    "OCT": 4.91,
    "NOV": 4.69,
    "DEC": 4.61
  },
  {
    "LONG": -59.26,
    "LAT": -13.69,
    "CIDADE": "Campos de Júlio",
    "UF": "MATO GROSSO",
    "anual": 4.86,
    "JAN": 4.54,
    "FEB": 4.56,
    "MAR": 4.71,
    "APR": 4.96,
    "MAY": 4.76,
    "JUN": 4.92,
    "JUL": 5.01,
    "AUG": 5.41,
    "SEP": 4.98,
    "OCT": 4.9,
    "NOV": 4.78,
    "DEC": 4.8
  },
  {
    "LONG": -57.89,
    "LAT": -13.66,
    "CIDADE": "Campo Novo do Parecis",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.66,
    "FEB": 4.8,
    "MAR": 4.79,
    "APR": 5.04,
    "MAY": 4.96,
    "JUN": 5.1,
    "JUL": 5.18,
    "AUG": 5.7,
    "SEP": 5.13,
    "OCT": 4.98,
    "NOV": 4.86,
    "DEC": 4.91
  },
  {
    "LONG": "#VALOR!",
    "LAT": -13.55,
    "CIDADE": "Canarana",
    "UF": "MATO GROSSO",
    "anual": 5.36,
    "JAN": 4.94,
    "FEB": 5.21,
    "MAR": 5.14,
    "APR": 5.5,
    "MAY": 5.52,
    "JUN": 5.46,
    "JUL": 5.85,
    "AUG": 6.19,
    "SEP": 5.52,
    "OCT": 5.19,
    "NOV": 4.85,
    "DEC": 4.95
  },
  {
    "LONG": -58.82,
    "LAT": -13.55,
    "CIDADE": "Sapezal",
    "UF": "MATO GROSSO",
    "anual": 4.91,
    "JAN": 4.59,
    "FEB": 4.62,
    "MAR": 4.85,
    "APR": 4.98,
    "MAY": 4.77,
    "JUN": 4.96,
    "JUL": 5.05,
    "AUG": 5.46,
    "SEP": 5,
    "OCT": 4.92,
    "NOV": 4.85,
    "DEC": 4.82
  },
  {
    "LONG": -5.67,
    "LAT": -13.45,
    "CIDADE": "São José do Rio Claro",
    "UF": "MATO GROSSO",
    "anual": 5.17,
    "JAN": 4.79,
    "FEB": 4.83,
    "MAR": 5.03,
    "APR": 5.21,
    "MAY": 5.26,
    "JUN": 5.29,
    "JUL": 5.4,
    "AUG": 5.79,
    "SEP": 5.36,
    "OCT": 5.28,
    "NOV": 4.96,
    "DEC": 4.87
  },
  {
    "LONG": -53.26,
    "LAT": -13.18,
    "CIDADE": "Gaúcha do Norte",
    "UF": "MATO GROSSO",
    "anual": 5.29,
    "JAN": 4.87,
    "FEB": 5.17,
    "MAR": 5.15,
    "APR": 5.5,
    "MAY": 5.46,
    "JUN": 5.38,
    "JUL": 5.72,
    "AUG": 6.01,
    "SEP": 5.39,
    "OCT": 5.14,
    "NOV": 4.87,
    "DEC": 4.82
  },
  {
    "LONG": -55.91,
    "LAT": -13.07,
    "CIDADE": "Lucas do Rio Verde",
    "UF": "MATO GROSSO",
    "anual": 5.1,
    "JAN": 4.82,
    "FEB": 4.82,
    "MAR": 4.99,
    "APR": 5.27,
    "MAY": 5.2,
    "JUN": 5.11,
    "JUL": 5.24,
    "AUG": 5.58,
    "SEP": 5.25,
    "OCT": 5.13,
    "NOV": 4.92,
    "DEC": 4.89
  },
  {
    "LONG": -57.09,
    "LAT": -13.03,
    "CIDADE": "Nova Maringá",
    "UF": "MATO GROSSO",
    "anual": 5.1,
    "JAN": 4.76,
    "FEB": 4.81,
    "MAR": 4.93,
    "APR": 5.13,
    "MAY": 5.15,
    "JUN": 5.26,
    "JUL": 5.35,
    "AUG": 5.61,
    "SEP": 5.3,
    "OCT": 5.18,
    "NOV": 4.86,
    "DEC": 4.86
  },
  {
    "LONG": -55.25,
    "LAT": -13.04,
    "CIDADE": "Nova Ubiratã",
    "UF": "MATO GROSSO",
    "anual": 5.16,
    "JAN": 4.8,
    "FEB": 4.95,
    "MAR": 5.03,
    "APR": 5.32,
    "MAY": 5.31,
    "JUN": 5.18,
    "JUL": 5.44,
    "AUG": 5.72,
    "SEP": 5.28,
    "OCT": 5.14,
    "NOV": 4.9,
    "DEC": 4.88
  },
  {
    "LONG": -51.82,
    "LAT": -12.94,
    "CIDADE": "Ribeirão Cascalheira",
    "UF": "MATO GROSSO",
    "anual": 5.31,
    "JAN": 4.75,
    "FEB": 5.12,
    "MAR": 5.1,
    "APR": 5.37,
    "MAY": 5.51,
    "JUN": 5.5,
    "JUL": 5.86,
    "AUG": 6.14,
    "SEP": 5.55,
    "OCT": 5.15,
    "NOV": 4.83,
    "DEC": 4.85
  },
  {
    "LONG": -56.51,
    "LAT": -12.74,
    "CIDADE": "Tapurah",
    "UF": "MATO GROSSO",
    "anual": 5.11,
    "JAN": 4.79,
    "FEB": 4.83,
    "MAR": 4.95,
    "APR": 5.09,
    "MAY": 5.16,
    "JUN": 5.28,
    "JUL": 5.42,
    "AUG": 5.64,
    "SEP": 5.27,
    "OCT": 5.22,
    "NOV": 4.85,
    "DEC": 4.85
  },
  {
    "LONG": -52.21,
    "LAT": -12.59,
    "CIDADE": "Querência",
    "UF": "MATO GROSSO",
    "anual": 5.24,
    "JAN": 4.82,
    "FEB": 5.19,
    "MAR": 4.98,
    "APR": 5.25,
    "MAY": 5.45,
    "JUN": 5.33,
    "JUL": 5.78,
    "AUG": 5.86,
    "SEP": 5.4,
    "OCT": 5.16,
    "NOV": 4.84,
    "DEC": 4.85
  },
  {
    "LONG": -55.72,
    "LAT": -1.25,
    "CIDADE": "Sorriso",
    "UF": "MATO GROSSO",
    "anual": 5.13,
    "JAN": 4.83,
    "FEB": 4.87,
    "MAR": 5.02,
    "APR": 5.21,
    "MAY": 5.26,
    "JUN": 5.23,
    "JUL": 5.45,
    "AUG": 5.62,
    "SEP": 5.22,
    "OCT": 5.17,
    "NOV": 4.85,
    "DEC": 4.86
  },
  {
    "LONG": -54.94,
    "LAT": -12.38,
    "CIDADE": "Feliz Natal",
    "UF": "MATO GROSSO",
    "anual": 5.18,
    "JAN": 4.79,
    "FEB": 4.97,
    "MAR": 5.01,
    "APR": 5.27,
    "MAY": 5.44,
    "JUN": 5.31,
    "JUL": 5.56,
    "AUG": 5.7,
    "SEP": 5.27,
    "OCT": 5.15,
    "NOV": 4.83,
    "DEC": 4.85
  },
  {
    "LONG": -55.3,
    "LAT": -12.29,
    "CIDADE": "Vera",
    "UF": "MATO GROSSO",
    "anual": 5.14,
    "JAN": 4.79,
    "FEB": 4.9,
    "MAR": 4.97,
    "APR": 5.19,
    "MAY": 5.32,
    "JUN": 5.28,
    "JUL": 5.53,
    "AUG": 5.68,
    "SEP": 5.21,
    "OCT": 5.13,
    "NOV": 4.84,
    "DEC": 4.89
  },
  {
    "LONG": -50.97,
    "LAT": -12.29,
    "CIDADE": "Novo Santo Antônio",
    "UF": "MATO GROSSO",
    "anual": 5.38,
    "JAN": 4.81,
    "FEB": 5.2,
    "MAR": 5.22,
    "APR": 5.4,
    "MAY": 5.59,
    "JUN": 5.39,
    "JUL": 5.83,
    "AUG": 6.31,
    "SEP": 5.72,
    "OCT": 5.26,
    "NOV": 4.94,
    "DEC": 4.88
  },
  {
    "LONG": -56.64,
    "LAT": -12.24,
    "CIDADE": "Itanhangá",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.73,
    "FEB": 4.71,
    "MAR": 4.84,
    "APR": 5.01,
    "MAY": 5.12,
    "JUN": 5.29,
    "JUL": 5.47,
    "AUG": 5.68,
    "SEP": 5.21,
    "OCT": 5.16,
    "NOV": 4.77,
    "DEC": 4.75
  },
  {
    "LONG": -56.15,
    "LAT": -12.24,
    "CIDADE": "Ipiranga do Norte",
    "UF": "MATO GROSSO",
    "anual": 5.05,
    "JAN": 4.78,
    "FEB": 4.82,
    "MAR": 4.88,
    "APR": 5.06,
    "MAY": 5.14,
    "JUN": 5.12,
    "JUL": 5.26,
    "AUG": 5.6,
    "SEP": 5.17,
    "OCT": 5.1,
    "NOV": 4.77,
    "DEC": 4.84
  },
  {
    "LONG": -51.5,
    "LAT": -1.22,
    "CIDADE": "Bom Jesus do Araguaia",
    "UF": "MATO GROSSO",
    "anual": 5.26,
    "JAN": 4.79,
    "FEB": 5.03,
    "MAR": 5,
    "APR": 5.23,
    "MAY": 5.52,
    "JUN": 5.47,
    "JUL": 5.74,
    "AUG": 6.03,
    "SEP": 5.47,
    "OCT": 5.15,
    "NOV": 4.91,
    "DEC": 4.84
  },
  {
    "LONG": -58,
    "LAT": -12.12,
    "CIDADE": "Brasnorte",
    "UF": "MATO GROSSO",
    "anual": 4.98,
    "JAN": 4.58,
    "FEB": 4.68,
    "MAR": 4.84,
    "APR": 4.98,
    "MAY": 4.97,
    "JUN": 5.09,
    "JUL": 5.19,
    "AUG": 5.57,
    "SEP": 5.17,
    "OCT": 5.12,
    "NOV": 4.79,
    "DEC": 4.79
  },
  {
    "LONG": -51.4,
    "LAT": -12.09,
    "CIDADE": "Serra Nova Dourada",
    "UF": "MATO GROSSO",
    "anual": 5.27,
    "JAN": 4.74,
    "FEB": 5.01,
    "MAR": 5.01,
    "APR": 5.21,
    "MAY": 5.5,
    "JUN": 5.46,
    "JUL": 5.76,
    "AUG": 6.18,
    "SEP": 5.49,
    "OCT": 5.15,
    "NOV": 4.87,
    "DEC": 4.82
  },
  {
    "LONG": -55.28,
    "LAT": -11.98,
    "CIDADE": "Santa Carmem",
    "UF": "MATO GROSSO",
    "anual": 5.12,
    "JAN": 4.81,
    "FEB": 4.86,
    "MAR": 4.97,
    "APR": 5.15,
    "MAY": 5.24,
    "JUN": 5.19,
    "JUL": 5.49,
    "AUG": 5.62,
    "SEP": 5.24,
    "OCT": 5.16,
    "NOV": 4.85,
    "DEC": 4.86
  },
  {
    "LONG": -55.51,
    "LAT": -11.86,
    "CIDADE": "Sinop",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.72,
    "FEB": 4.81,
    "MAR": 4.97,
    "APR": 5.08,
    "MAY": 5.07,
    "JUN": 5.14,
    "JUL": 5.4,
    "AUG": 5.56,
    "SEP": 5.17,
    "OCT": 5.16,
    "NOV": 4.76,
    "DEC": 4.83
  },
  {
    "LONG": -51.38,
    "LAT": -11.67,
    "CIDADE": "Alto Boa Vista",
    "UF": "MATO GROSSO",
    "anual": 5.24,
    "JAN": 4.79,
    "FEB": 5.03,
    "MAR": 5.02,
    "APR": 5.09,
    "MAY": 5.39,
    "JUN": 5.38,
    "JUL": 5.6,
    "AUG": 6.02,
    "SEP": 5.54,
    "OCT": 5.14,
    "NOV": 4.89,
    "DEC": 4.93
  },
  {
    "LONG": -50.68,
    "LAT": -11.62,
    "CIDADE": "São Félix do Araguaia",
    "UF": "MATO GROSSO",
    "anual": 5.31,
    "JAN": 4.81,
    "FEB": 5.07,
    "MAR": 5.1,
    "APR": 5.18,
    "MAY": 5.45,
    "JUN": 5.49,
    "JUL": 5.85,
    "AUG": 6.17,
    "SEP": 5.6,
    "OCT": 5.16,
    "NOV": 4.89,
    "DEC": 4.9
  },
  {
    "LONG": -57.41,
    "LAT": -11.53,
    "CIDADE": "Porto dos Gaúchos",
    "UF": "MATO GROSSO",
    "anual": 4.95,
    "JAN": 4.59,
    "FEB": 4.61,
    "MAR": 4.7,
    "APR": 4.89,
    "MAY": 4.97,
    "JUN": 5.2,
    "JUL": 5.34,
    "AUG": 5.58,
    "SEP": 5.13,
    "OCT": 5.05,
    "NOV": 4.7,
    "DEC": 4.68
  },
  {
    "LONG": -54.88,
    "LAT": -1.15,
    "CIDADE": "Cláudia",
    "UF": "MATO GROSSO",
    "anual": 5.09,
    "JAN": 4.73,
    "FEB": 4.89,
    "MAR": 4.89,
    "APR": 5.12,
    "MAY": 5.2,
    "JUN": 5.19,
    "JUL": 5.54,
    "AUG": 5.66,
    "SEP": 5.22,
    "OCT": 5.08,
    "NOV": 4.83,
    "DEC": 4.75
  },
  {
    "LONG": -54.37,
    "LAT": -11.53,
    "CIDADE": "União do Sul",
    "UF": "MATO GROSSO",
    "anual": 5.13,
    "JAN": 4.72,
    "FEB": 4.89,
    "MAR": 4.87,
    "APR": 5.16,
    "MAY": 5.31,
    "JUN": 5.29,
    "JUL": 5.64,
    "AUG": 5.84,
    "SEP": 5.26,
    "OCT": 5.04,
    "NOV": 4.79,
    "DEC": 4.76
  },
  {
    "LONG": -58.77,
    "LAT": -1.14,
    "CIDADE": "Juína",
    "UF": "MATO GROSSO",
    "anual": 4.85,
    "JAN": 4.36,
    "FEB": 4.45,
    "MAR": 4.66,
    "APR": 4.74,
    "MAY": 4.75,
    "JUN": 5.06,
    "JUL": 5.19,
    "AUG": 5.59,
    "SEP": 5.1,
    "OCT": 5.05,
    "NOV": 4.64,
    "DEC": 4.62
  },
  {
    "LONG": -57.32,
    "LAT": -11.39,
    "CIDADE": "Novo Horizonte do Norte",
    "UF": "MATO GROSSO",
    "anual": 4.96,
    "JAN": 4.61,
    "FEB": 4.64,
    "MAR": 4.73,
    "APR": 4.85,
    "MAY": 4.94,
    "JUN": 5.15,
    "JUL": 5.39,
    "AUG": 5.56,
    "SEP": 5.15,
    "OCT": 5.08,
    "NOV": 4.73,
    "DEC": 4.68
  },
  {
    "LONG": -57.51,
    "LAT": -11.25,
    "CIDADE": "Juara",
    "UF": "MATO GROSSO",
    "anual": 4.94,
    "JAN": 4.5,
    "FEB": 4.61,
    "MAR": 4.71,
    "APR": 4.83,
    "MAY": 4.93,
    "JUN": 5.12,
    "JUL": 5.3,
    "AUG": 5.58,
    "SEP": 5.14,
    "OCT": 5.1,
    "NOV": 4.74,
    "DEC": 4.66
  },
  {
    "LONG": -56.82,
    "LAT": -11.31,
    "CIDADE": "Tabaporã",
    "UF": "MATO GROSSO",
    "anual": 4.95,
    "JAN": 4.54,
    "FEB": 4.67,
    "MAR": 4.71,
    "APR": 4.8,
    "MAY": 4.98,
    "JUN": 5.16,
    "JUL": 5.37,
    "AUG": 5.72,
    "SEP": 5.13,
    "OCT": 4.99,
    "NOV": 4.62,
    "DEC": 4.67
  },
  {
    "LONG": -5.07,
    "LAT": -11.22,
    "CIDADE": "Luciara",
    "UF": "MATO GROSSO",
    "anual": 5.27,
    "JAN": 4.76,
    "FEB": 5.1,
    "MAR": 5.01,
    "APR": 5.09,
    "MAY": 5.41,
    "JUN": 5.6,
    "JUL": 5.75,
    "AUG": 6.08,
    "SEP": 5.57,
    "OCT": 5.15,
    "NOV": 4.84,
    "DEC": 4.87
  },
  {
    "LONG": -58.61,
    "LAT": -11.14,
    "CIDADE": "Castanheira",
    "UF": "MATO GROSSO",
    "anual": 4.86,
    "JAN": 4.32,
    "FEB": 4.47,
    "MAR": 4.65,
    "APR": 4.77,
    "MAY": 4.77,
    "JUN": 5.07,
    "JUL": 5.18,
    "AUG": 5.59,
    "SEP": 5.13,
    "OCT": 5.06,
    "NOV": 4.66,
    "DEC": 4.64
  },
  {
    "LONG": -5.45,
    "LAT": -11.08,
    "CIDADE": "Marcelândia",
    "UF": "MATO GROSSO",
    "anual": 5.09,
    "JAN": 4.72,
    "FEB": 4.81,
    "MAR": 4.83,
    "APR": 5.07,
    "MAY": 5.19,
    "JUN": 5.18,
    "JUL": 5.64,
    "AUG": 5.89,
    "SEP": 5.21,
    "OCT": 5.02,
    "NOV": 4.77,
    "DEC": 4.7
  },
  {
    "LONG": -55.24,
    "LAT": -11.01,
    "CIDADE": "Itaúba",
    "UF": "MATO GROSSO",
    "anual": 4.99,
    "JAN": 4.63,
    "FEB": 4.66,
    "MAR": 4.73,
    "APR": 4.85,
    "MAY": 5.09,
    "JUN": 5.21,
    "JUL": 5.56,
    "AUG": 5.79,
    "SEP": 5.13,
    "OCT": 4.94,
    "NOV": 4.69,
    "DEC": 4.61
  },
  {
    "LONG": -51.83,
    "LAT": "#VALOR!",
    "CIDADE": "Canabrava do Norte",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 4.77,
    "FEB": 5.01,
    "MAR": 5,
    "APR": 5.08,
    "MAY": 5.34,
    "JUN": 5.4,
    "JUL": 5.48,
    "AUG": 6.01,
    "SEP": 5.36,
    "OCT": 5.08,
    "NOV": 4.84,
    "DEC": 4.87
  },
  {
    "LONG": -51.64,
    "LAT": -10.88,
    "CIDADE": "Porto Alegre do Norte",
    "UF": "MATO GROSSO",
    "anual": 5.13,
    "JAN": 4.69,
    "FEB": 4.98,
    "MAR": 4.95,
    "APR": 4.97,
    "MAY": 5.34,
    "JUN": 5.19,
    "JUL": 5.48,
    "AUG": 5.87,
    "SEP": 5.36,
    "OCT": 5.09,
    "NOV": 4.85,
    "DEC": 4.84
  },
  {
    "LONG": -61.47,
    "LAT": -1.08,
    "CIDADE": "Rondolândia",
    "UF": "MATO GROSSO",
    "anual": 4.66,
    "JAN": 4.15,
    "FEB": 4.13,
    "MAR": 4.4,
    "APR": 4.62,
    "MAY": 4.4,
    "JUN": 4.97,
    "JUL": 5.08,
    "AUG": 5.34,
    "SEP": 5.03,
    "OCT": 4.97,
    "NOV": 4.56,
    "DEC": 4.31
  },
  {
    "LONG": -55.46,
    "LAT": -10.81,
    "CIDADE": "Colíder",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.7,
    "FEB": 4.78,
    "MAR": 4.83,
    "APR": 4.96,
    "MAY": 5.08,
    "JUN": 5.18,
    "JUL": 5.46,
    "AUG": 5.83,
    "SEP": 5.22,
    "OCT": 5.08,
    "NOV": 4.82,
    "DEC": 4.72
  },
  {
    "LONG": -55.18,
    "LAT": -1.08,
    "CIDADE": "Nova Santa Helena",
    "UF": "MATO GROSSO",
    "anual": 5.04,
    "JAN": 4.68,
    "FEB": 4.75,
    "MAR": 4.76,
    "APR": 4.91,
    "MAY": 5.15,
    "JUN": 5.19,
    "JUL": 5.5,
    "AUG": 5.86,
    "SEP": 5.2,
    "OCT": 5,
    "NOV": 4.79,
    "DEC": 4.7
  },
  {
    "LONG": -52.74,
    "LAT": -10.8,
    "CIDADE": "São José do Xingu",
    "UF": "MATO GROSSO",
    "anual": 5.13,
    "JAN": 4.67,
    "FEB": 4.91,
    "MAR": 4.85,
    "APR": 5,
    "MAY": 5.28,
    "JUN": 5.41,
    "JUL": 5.65,
    "AUG": 5.98,
    "SEP": 5.32,
    "OCT": 4.95,
    "NOV": 4.76,
    "DEC": 4.82
  },
  {
    "LONG": -55.95,
    "LAT": -10.56,
    "CIDADE": "Nova Canaã do Norte",
    "UF": "MATO GROSSO",
    "anual": 4.97,
    "JAN": 4.59,
    "FEB": 4.7,
    "MAR": 4.77,
    "APR": 4.88,
    "MAY": 5.04,
    "JUN": 5.13,
    "JUL": 5.29,
    "AUG": 5.66,
    "SEP": 5.14,
    "OCT": 5.03,
    "NOV": 4.74,
    "DEC": 4.71
  },
  {
    "LONG": -55.12,
    "LAT": -10.6,
    "CIDADE": "Terra Nova do Norte",
    "UF": "MATO GROSSO",
    "anual": 5.05,
    "JAN": 4.73,
    "FEB": 4.77,
    "MAR": 4.8,
    "APR": 4.94,
    "MAY": 5.09,
    "JUN": 5.17,
    "JUL": 5.5,
    "AUG": 5.82,
    "SEP": 5.2,
    "OCT": 5.07,
    "NOV": 4.82,
    "DEC": 4.73
  },
  {
    "LONG": -51.57,
    "LAT": -10.64,
    "CIDADE": "Confresa",
    "UF": "MATO GROSSO",
    "anual": 5.09,
    "JAN": 4.59,
    "FEB": 4.85,
    "MAR": 4.81,
    "APR": 4.86,
    "MAY": 5.3,
    "JUN": 5.39,
    "JUL": 5.51,
    "AUG": 6.02,
    "SEP": 5.32,
    "OCT": 4.96,
    "NOV": 4.74,
    "DEC": 4.73
  },
  {
    "LONG": -50.51,
    "LAT": -1.05,
    "CIDADE": "Santa Terezinha",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 4.73,
    "FEB": 4.98,
    "MAR": 4.9,
    "APR": 5,
    "MAY": 5.33,
    "JUN": 5.47,
    "JUL": 5.73,
    "AUG": 6.08,
    "SEP": 5.51,
    "OCT": 5.05,
    "NOV": 4.79,
    "DEC": 4.77
  },
  {
    "LONG": -58.5,
    "LAT": -10.34,
    "CIDADE": "Juruena",
    "UF": "MATO GROSSO",
    "anual": 4.78,
    "JAN": 4.22,
    "FEB": 4.37,
    "MAR": 4.59,
    "APR": 4.61,
    "MAY": 4.73,
    "JUN": 5.12,
    "JUL": 5.23,
    "AUG": 5.42,
    "SEP": 5,
    "OCT": 5.03,
    "NOV": 4.53,
    "DEC": 4.46
  },
  {
    "LONG": -55.41,
    "LAT": -10.31,
    "CIDADE": "Nova Guarita",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.64,
    "FEB": 4.74,
    "MAR": 4.83,
    "APR": 4.92,
    "MAY": 5.08,
    "JUN": 5.09,
    "JUL": 5.36,
    "AUG": 5.63,
    "SEP": 5.23,
    "OCT": 5.14,
    "NOV": 4.78,
    "DEC": 4.73
  },
  {
    "LONG": -59.46,
    "LAT": -10.17,
    "CIDADE": "Aripuanã",
    "UF": "MATO GROSSO",
    "anual": 4.74,
    "JAN": 4.17,
    "FEB": 4.3,
    "MAR": 4.59,
    "APR": 4.58,
    "MAY": 4.6,
    "JUN": 5.04,
    "JUL": 5.05,
    "AUG": 5.37,
    "SEP": 5.11,
    "OCT": 4.96,
    "NOV": 4.62,
    "DEC": 4.47
  },
  {
    "LONG": -54.95,
    "LAT": -10.18,
    "CIDADE": "Matupá",
    "UF": "MATO GROSSO",
    "anual": 4.99,
    "JAN": 4.65,
    "FEB": 4.74,
    "MAR": 4.77,
    "APR": 4.88,
    "MAY": 5.12,
    "JUN": 4.99,
    "JUL": 5.28,
    "AUG": 5.67,
    "SEP": 5.17,
    "OCT": 5.13,
    "NOV": 4.78,
    "DEC": 4.69
  },
  {
    "LONG": -54.98,
    "LAT": -10.23,
    "CIDADE": "Peixoto de Azevedo",
    "UF": "MATO GROSSO",
    "anual": 4.99,
    "JAN": 4.65,
    "FEB": 4.74,
    "MAR": 4.77,
    "APR": 4.88,
    "MAY": 5.12,
    "JUN": 4.99,
    "JUL": 5.28,
    "AUG": 5.67,
    "SEP": 5.17,
    "OCT": 5.13,
    "NOV": 4.78,
    "DEC": 4.69
  },
  {
    "LONG": -52.4,
    "LAT": -10.15,
    "CIDADE": "Santa Cruz do Xingu",
    "UF": "MATO GROSSO",
    "anual": 5.04,
    "JAN": 4.56,
    "FEB": 4.84,
    "MAR": 4.8,
    "APR": 4.8,
    "MAY": 5.14,
    "JUN": 5.23,
    "JUL": 5.36,
    "AUG": 5.89,
    "SEP": 5.29,
    "OCT": 5.02,
    "NOV": 4.78,
    "DEC": 4.73
  },
  {
    "LONG": -57.53,
    "LAT": -10,
    "CIDADE": "Nova Monte Verde",
    "UF": "MATO GROSSO",
    "anual": 4.82,
    "JAN": 4.37,
    "FEB": 4.47,
    "MAR": 4.53,
    "APR": 4.67,
    "MAY": 4.78,
    "JUN": 5.03,
    "JUL": 5.14,
    "AUG": 5.56,
    "SEP": 5.02,
    "OCT": 5.01,
    "NOV": 4.69,
    "DEC": 4.57
  },
  {
    "LONG": -55.18,
    "LAT": -9.97,
    "CIDADE": "Novo Mundo",
    "UF": "MATO GROSSO",
    "anual": 4.96,
    "JAN": 4.57,
    "FEB": 4.64,
    "MAR": 4.76,
    "APR": 4.83,
    "MAY": 5.05,
    "JUN": 5.06,
    "JUL": 5.3,
    "AUG": 5.75,
    "SEP": 5.15,
    "OCT": 5.04,
    "NOV": 4.73,
    "DEC": 4.64
  },
  {
    "LONG": -54.91,
    "LAT": -9.96,
    "CIDADE": "Guarantã do Norte",
    "UF": "MATO GROSSO",
    "anual": 4.95,
    "JAN": 4.53,
    "FEB": 4.62,
    "MAR": 4.74,
    "APR": 4.8,
    "MAY": 5.03,
    "JUN": 5.15,
    "JUL": 5.32,
    "AUG": 5.74,
    "SEP": 5.09,
    "OCT": 5.02,
    "NOV": 4.7,
    "DEC": 4.61
  },
  {
    "LONG": -5.11,
    "LAT": -10.01,
    "CIDADE": "Vila Rica",
    "UF": "MATO GROSSO",
    "anual": 5.11,
    "JAN": 4.67,
    "FEB": 4.83,
    "MAR": 4.83,
    "APR": 4.95,
    "MAY": 5.15,
    "JUN": 5.41,
    "JUL": 5.6,
    "AUG": 6.02,
    "SEP": 5.36,
    "OCT": 4.97,
    "NOV": 4.75,
    "DEC": 4.79
  },
  {
    "LONG": -58.57,
    "LAT": -9.9,
    "CIDADE": "Cotriguaçu",
    "UF": "MATO GROSSO",
    "anual": 4.72,
    "JAN": 4.14,
    "FEB": 4.34,
    "MAR": 4.47,
    "APR": 4.57,
    "MAY": 4.53,
    "JUN": 5.05,
    "JUL": 5.1,
    "AUG": 5.43,
    "SEP": 4.99,
    "OCT": 5,
    "NOV": 4.58,
    "DEC": 4.42
  },
  {
    "LONG": -56.09,
    "LAT": -9.87,
    "CIDADE": "Alta Floresta",
    "UF": "MATO GROSSO",
    "anual": 4.95,
    "JAN": 4.54,
    "FEB": 4.67,
    "MAR": 4.68,
    "APR": 4.76,
    "MAY": 4.91,
    "JUN": 5.15,
    "JUL": 5.22,
    "AUG": 5.69,
    "SEP": 5.2,
    "OCT": 5.11,
    "NOV": 4.77,
    "DEC": 4.67
  },
  {
    "LONG": -55.84,
    "LAT": -9.95,
    "CIDADE": "Carlinda",
    "UF": "MATO GROSSO",
    "anual": 4.94,
    "JAN": 4.56,
    "FEB": 4.65,
    "MAR": 4.67,
    "APR": 4.82,
    "MAY": 4.98,
    "JUN": 5.13,
    "JUL": 5.25,
    "AUG": 5.58,
    "SEP": 5.17,
    "OCT": 5.06,
    "NOV": 4.8,
    "DEC": 4.66
  },
  {
    "LONG": -57.83,
    "LAT": -9.85,
    "CIDADE": "Nova Bandeirantes",
    "UF": "MATO GROSSO",
    "anual": 4.78,
    "JAN": 4.31,
    "FEB": 4.44,
    "MAR": 4.48,
    "APR": 4.59,
    "MAY": 4.71,
    "JUN": 4.99,
    "JUL": 5.16,
    "AUG": 5.52,
    "SEP": 4.99,
    "OCT": 4.99,
    "NOV": 4.6,
    "DEC": 4.52
  },
  {
    "LONG": -56.48,
    "LAT": -9.66,
    "CIDADE": "Paranaíta",
    "UF": "MATO GROSSO",
    "anual": 4.86,
    "JAN": 4.47,
    "FEB": 4.57,
    "MAR": 4.58,
    "APR": 4.62,
    "MAY": 4.82,
    "JUN": 5.1,
    "JUL": 5.19,
    "AUG": 5.58,
    "SEP": 5.12,
    "OCT": 5.01,
    "NOV": 4.68,
    "DEC": 4.58
  },
  {
    "LONG": -59.23,
    "LAT": -9.46,
    "CIDADE": "Colniza",
    "UF": "MATO GROSSO",
    "anual": 4.66,
    "JAN": 4.15,
    "FEB": 4.31,
    "MAR": 4.41,
    "APR": 4.46,
    "MAY": 4.49,
    "JUN": 4.94,
    "JUL": 5.03,
    "AUG": 5.27,
    "SEP": 4.95,
    "OCT": 4.92,
    "NOV": 4.62,
    "DEC": 4.38
  },
  {
    "LONG": -57.46,
    "LAT": -9.54,
    "CIDADE": "Apiacás",
    "UF": "MATO GROSSO",
    "anual": 4.76,
    "JAN": 4.28,
    "FEB": 4.42,
    "MAR": 4.49,
    "APR": 4.61,
    "MAY": 4.66,
    "JUN": 5.03,
    "JUL": 5.15,
    "AUG": 5.49,
    "SEP": 5.02,
    "OCT": 4.93,
    "NOV": 4.59,
    "DEC": 4.46
  },
  {
    "LONG": -55.04,
    "LAT": -2.4,
    "CIDADE": "Sete Quedas",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.02,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.6,
    "APR": 5.02,
    "MAY": 4.28,
    "JUN": 3.88,
    "JUL": 4.15,
    "AUG": 5.08,
    "SEP": 4.89,
    "OCT": 5.23,
    "NOV": 5.48,
    "DEC": 5.65
  },
  {
    "LONG": -55.43,
    "LAT": -23.89,
    "CIDADE": "Paranhos",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5,
    "JAN": 5.46,
    "FEB": 5.46,
    "MAR": 5.59,
    "APR": 5,
    "MAY": 4.25,
    "JUN": 3.89,
    "JUL": 4.05,
    "AUG": 5.01,
    "SEP": 4.9,
    "OCT": 5.2,
    "NOV": 5.45,
    "DEC": 5.7
  },
  {
    "LONG": -54.41,
    "LAT": -23.89,
    "CIDADE": "Japorã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.04,
    "JAN": 5.43,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.09,
    "MAY": 4.34,
    "JUN": 3.95,
    "JUL": 4.19,
    "AUG": 5.14,
    "SEP": 4.92,
    "OCT": 5.21,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -54.28,
    "LAT": -2.39,
    "CIDADE": "Mundo Novo",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.05,
    "JAN": 5.48,
    "FEB": 5.53,
    "MAR": 5.59,
    "APR": 5.11,
    "MAY": 4.34,
    "JUN": 3.97,
    "JUL": 4.19,
    "AUG": 5.11,
    "SEP": 4.91,
    "OCT": 5.22,
    "NOV": 5.49,
    "DEC": 5.65
  },
  {
    "LONG": -54.28,
    "LAT": -23.79,
    "CIDADE": "Eldorado",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.06,
    "JAN": 5.44,
    "FEB": 5.53,
    "MAR": 5.6,
    "APR": 5.1,
    "MAY": 4.36,
    "JUN": 4,
    "JUL": 4.2,
    "AUG": 5.15,
    "SEP": 4.93,
    "OCT": 5.24,
    "NOV": 5.5,
    "DEC": 5.66
  },
  {
    "LONG": -54.56,
    "LAT": -2.37,
    "CIDADE": "Iguatemi",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.03,
    "JAN": 5.39,
    "FEB": 5.53,
    "MAR": 5.55,
    "APR": 5.04,
    "MAY": 4.34,
    "JUN": 3.96,
    "JUL": 4.2,
    "AUG": 5.12,
    "SEP": 4.93,
    "OCT": 5.22,
    "NOV": 5.45,
    "DEC": 5.61
  },
  {
    "LONG": -55.01,
    "LAT": -23.64,
    "CIDADE": "Tacuru",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5,
    "JAN": 5.34,
    "FEB": 5.44,
    "MAR": 5.52,
    "APR": 5.05,
    "MAY": 4.33,
    "JUN": 3.98,
    "JUL": 4.15,
    "AUG": 5.07,
    "SEP": 4.95,
    "OCT": 5.2,
    "NOV": 5.44,
    "DEC": 5.59
  },
  {
    "LONG": -54.19,
    "LAT": -23.48,
    "CIDADE": "Itaquiraí",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.08,
    "JAN": 5.38,
    "FEB": 5.51,
    "MAR": 5.55,
    "APR": 5.1,
    "MAY": 4.38,
    "JUN": 4.08,
    "JUL": 4.3,
    "AUG": 5.18,
    "SEP": 4.97,
    "OCT": 5.24,
    "NOV": 5.51,
    "DEC": 5.71
  },
  {
    "LONG": -55.53,
    "LAT": -23.27,
    "CIDADE": "Coronel Sapucaia",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.01,
    "JAN": 5.39,
    "FEB": 5.38,
    "MAR": 5.52,
    "APR": 5.04,
    "MAY": 4.31,
    "JUN": 4.08,
    "JUL": 4.18,
    "AUG": 5.07,
    "SEP": 4.97,
    "OCT": 5.2,
    "NOV": 5.34,
    "DEC": 5.66
  },
  {
    "LONG": -55.23,
    "LAT": -23.11,
    "CIDADE": "Amambai",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5,
    "JAN": 5.28,
    "FEB": 5.33,
    "MAR": 5.46,
    "APR": 5.03,
    "MAY": 4.33,
    "JUN": 4.12,
    "JUL": 4.21,
    "AUG": 5.19,
    "SEP": 4.95,
    "OCT": 5.17,
    "NOV": 5.33,
    "DEC": 5.6
  },
  {
    "LONG": -54.2,
    "LAT": -23.06,
    "CIDADE": "Naviraí",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.07,
    "JAN": 5.33,
    "FEB": 5.51,
    "MAR": 5.55,
    "APR": 5.12,
    "MAY": 4.39,
    "JUN": 4.13,
    "JUL": 4.32,
    "AUG": 5.19,
    "SEP": 4.98,
    "OCT": 5.25,
    "NOV": 5.5,
    "DEC": 5.61
  },
  {
    "LONG": -55.63,
    "LAT": -22.94,
    "CIDADE": "Aral Moreira",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5,
    "JAN": 5.33,
    "FEB": 5.37,
    "MAR": 5.53,
    "APR": 5.04,
    "MAY": 4.28,
    "JUN": 4.08,
    "JUL": 4.14,
    "AUG": 5.14,
    "SEP": 4.96,
    "OCT": 5.2,
    "NOV": 5.36,
    "DEC": 5.54
  },
  {
    "LONG": -54.61,
    "LAT": -22.86,
    "CIDADE": "Juti",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.08,
    "JAN": 5.31,
    "FEB": 5.49,
    "MAR": 5.57,
    "APR": 5.09,
    "MAY": 4.41,
    "JUN": 4.19,
    "JUL": 4.32,
    "AUG": 5.24,
    "SEP": 5.01,
    "OCT": 5.23,
    "NOV": 5.44,
    "DEC": 5.61
  },
  {
    "LONG": -53.86,
    "LAT": -22.66,
    "CIDADE": "Novo Horizonte do Sul",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.16,
    "JAN": 5.28,
    "FEB": 5.57,
    "MAR": 5.59,
    "APR": 5.25,
    "MAY": 4.58,
    "JUN": 4.31,
    "JUL": 4.47,
    "AUG": 5.33,
    "SEP": 5.08,
    "OCT": 5.29,
    "NOV": 5.51,
    "DEC": 5.64
  },
  {
    "LONG": -54.82,
    "LAT": -22.64,
    "CIDADE": "Caarapó",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.09,
    "JAN": 5.28,
    "FEB": 5.46,
    "MAR": 5.53,
    "APR": 5.14,
    "MAY": 4.5,
    "JUN": 4.28,
    "JUL": 4.34,
    "AUG": 5.27,
    "SEP": 5.04,
    "OCT": 5.22,
    "NOV": 5.4,
    "DEC": 5.57
  },
  {
    "LONG": -55.72,
    "LAT": -22.53,
    "CIDADE": "Ponta Porã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.03,
    "JAN": 5.21,
    "FEB": 5.35,
    "MAR": 5.5,
    "APR": 5.11,
    "MAY": 4.37,
    "JUN": 4.2,
    "JUL": 4.26,
    "AUG": 5.26,
    "SEP": 5.06,
    "OCT": 5.22,
    "NOV": 5.32,
    "DEC": 5.52
  },
  {
    "LONG": -55.15,
    "LAT": -22.55,
    "CIDADE": "Laguna Carapã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.05,
    "JAN": 5.26,
    "FEB": 5.44,
    "MAR": 5.46,
    "APR": 5.1,
    "MAY": 4.43,
    "JUN": 4.25,
    "JUL": 4.31,
    "AUG": 5.26,
    "SEP": 5.06,
    "OCT": 5.21,
    "NOV": 5.35,
    "DEC": 5.5
  },
  {
    "LONG": -54.31,
    "LAT": -22.48,
    "CIDADE": "Jateí",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.16,
    "JAN": 5.33,
    "FEB": 5.61,
    "MAR": 5.6,
    "APR": 5.21,
    "MAY": 4.55,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 5.35,
    "SEP": 5.13,
    "OCT": 5.26,
    "NOV": 5.48,
    "DEC": 5.65
  },
  {
    "LONG": -53.35,
    "LAT": -2.25,
    "CIDADE": "Taquarussu",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.21,
    "JAN": 5.37,
    "FEB": 5.61,
    "MAR": 5.64,
    "APR": 5.35,
    "MAY": 4.62,
    "JUN": 4.36,
    "JUL": 4.51,
    "AUG": 5.37,
    "SEP": 5.12,
    "OCT": 5.31,
    "NOV": 5.53,
    "DEC": 5.69
  },
  {
    "LONG": -54.51,
    "LAT": -22.38,
    "CIDADE": "Fátima do Sul",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.12,
    "JAN": 5.31,
    "FEB": 5.5,
    "MAR": 5.53,
    "APR": 5.21,
    "MAY": 4.54,
    "JUN": 4.31,
    "JUL": 4.42,
    "AUG": 5.29,
    "SEP": 5.09,
    "OCT": 5.22,
    "NOV": 5.42,
    "DEC": 5.63
  },
  {
    "LONG": -54.44,
    "LAT": -22.41,
    "CIDADE": "Vicentina",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.15,
    "JAN": 5.39,
    "FEB": 5.57,
    "MAR": 5.57,
    "APR": 5.23,
    "MAY": 4.54,
    "JUN": 4.34,
    "JUL": 4.44,
    "AUG": 5.32,
    "SEP": 5.11,
    "OCT": 5.25,
    "NOV": 5.45,
    "DEC": 5.64
  },
  {
    "LONG": -54.23,
    "LAT": -2.24,
    "CIDADE": "Glória de Dourados",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.18,
    "JAN": 5.35,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.25,
    "MAY": 4.57,
    "JUN": 4.34,
    "JUL": 4.49,
    "AUG": 5.37,
    "SEP": 5.12,
    "OCT": 5.3,
    "NOV": 5.52,
    "DEC": 5.64
  },
  {
    "LONG": -54.17,
    "LAT": -22.28,
    "CIDADE": "Deodápolis",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.19,
    "JAN": 5.35,
    "FEB": 5.59,
    "MAR": 5.6,
    "APR": 5.25,
    "MAY": 4.62,
    "JUN": 4.37,
    "JUL": 4.52,
    "AUG": 5.36,
    "SEP": 5.13,
    "OCT": 5.32,
    "NOV": 5.53,
    "DEC": 5.65
  },
  {
    "LONG": -53.82,
    "LAT": -2.23,
    "CIDADE": "Ivinhema",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.17,
    "JAN": 5.26,
    "FEB": 5.54,
    "MAR": 5.61,
    "APR": 5.28,
    "MAY": 4.61,
    "JUN": 4.35,
    "JUL": 4.5,
    "AUG": 5.37,
    "SEP": 5.12,
    "OCT": 5.33,
    "NOV": 5.45,
    "DEC": 5.63
  },
  {
    "LONG": -53.27,
    "LAT": -22.29,
    "CIDADE": "Batayporã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.2,
    "JAN": 5.29,
    "FEB": 5.56,
    "MAR": 5.59,
    "APR": 5.37,
    "MAY": 4.67,
    "JUN": 4.4,
    "JUL": 4.54,
    "AUG": 5.39,
    "SEP": 5.15,
    "OCT": 5.32,
    "NOV": 5.51,
    "DEC": 5.61
  },
  {
    "LONG": -55.95,
    "LAT": -2.22,
    "CIDADE": "Antônio João",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.08,
    "JAN": 5.37,
    "FEB": 5.39,
    "MAR": 5.53,
    "APR": 5.19,
    "MAY": 4.45,
    "JUN": 4.31,
    "JUL": 4.32,
    "AUG": 5.28,
    "SEP": 5.05,
    "OCT": 5.26,
    "NOV": 5.34,
    "DEC": 5.49
  },
  {
    "LONG": -54.81,
    "LAT": -22.22,
    "CIDADE": "Dourados",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.1,
    "JAN": 5.31,
    "FEB": 5.46,
    "MAR": 5.47,
    "APR": 5.15,
    "MAY": 4.55,
    "JUN": 4.31,
    "JUL": 4.4,
    "AUG": 5.29,
    "SEP": 5.09,
    "OCT": 5.19,
    "NOV": 5.38,
    "DEC": 5.59
  },
  {
    "LONG": -53.77,
    "LAT": -22.15,
    "CIDADE": "Angélica",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.19,
    "JAN": 5.24,
    "FEB": 5.57,
    "MAR": 5.62,
    "APR": 5.34,
    "MAY": 4.65,
    "JUN": 4.4,
    "JUL": 4.54,
    "AUG": 5.39,
    "SEP": 5.12,
    "OCT": 5.3,
    "NOV": 5.49,
    "DEC": 5.66
  },
  {
    "LONG": -53.34,
    "LAT": -22.24,
    "CIDADE": "Nova Andradina",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.19,
    "JAN": 5.21,
    "FEB": 5.52,
    "MAR": 5.54,
    "APR": 5.35,
    "MAY": 4.66,
    "JUN": 4.41,
    "JUL": 4.55,
    "AUG": 5.42,
    "SEP": 5.16,
    "OCT": 5.32,
    "NOV": 5.47,
    "DEC": 5.61
  },
  {
    "LONG": -52.72,
    "LAT": -22.19,
    "CIDADE": "Anaurilândia",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.22,
    "JAN": 5.29,
    "FEB": 5.57,
    "MAR": 5.63,
    "APR": 5.43,
    "MAY": 4.73,
    "JUN": 4.43,
    "JUL": 4.6,
    "AUG": 5.4,
    "SEP": 5.16,
    "OCT": 5.33,
    "NOV": 5.49,
    "DEC": 5.63
  },
  {
    "LONG": -56.53,
    "LAT": -22.11,
    "CIDADE": "Bela Vista",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.05,
    "JAN": 5.45,
    "FEB": 5.44,
    "MAR": 5.51,
    "APR": 5.2,
    "MAY": 4.37,
    "JUN": 4.12,
    "JUL": 4.17,
    "AUG": 5.1,
    "SEP": 4.97,
    "OCT": 5.25,
    "NOV": 5.41,
    "DEC": 5.59
  },
  {
    "LONG": -54.79,
    "LAT": -22.08,
    "CIDADE": "Itaporã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.13,
    "JAN": 5.34,
    "FEB": 5.48,
    "MAR": 5.49,
    "APR": 5.22,
    "MAY": 4.61,
    "JUN": 4.37,
    "JUL": 4.42,
    "AUG": 5.25,
    "SEP": 5.12,
    "OCT": 5.22,
    "NOV": 5.42,
    "DEC": 5.6
  },
  {
    "LONG": -57.03,
    "LAT": -22.01,
    "CIDADE": "Caracol",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.04,
    "JAN": 5.42,
    "FEB": 5.41,
    "MAR": 5.5,
    "APR": 5.2,
    "MAY": 4.37,
    "JUN": 4.07,
    "JUL": 4.15,
    "AUG": 5.1,
    "SEP": 4.98,
    "OCT": 5.26,
    "NOV": 5.44,
    "DEC": 5.61
  },
  {
    "LONG": -54.61,
    "LAT": "#VALOR!",
    "CIDADE": "Douradina",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.16,
    "JAN": 5.35,
    "FEB": 5.54,
    "MAR": 5.57,
    "APR": 5.24,
    "MAY": 4.62,
    "JUN": 4.38,
    "JUL": 4.46,
    "AUG": 5.29,
    "SEP": 5.12,
    "OCT": 5.27,
    "NOV": 5.45,
    "DEC": 5.61
  },
  {
    "LONG": -54.54,
    "LAT": -21.8,
    "CIDADE": "Rio Brilhante",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.15,
    "JAN": 5.32,
    "FEB": 5.48,
    "MAR": 5.53,
    "APR": 5.25,
    "MAY": 4.63,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 5.35,
    "SEP": 5.09,
    "OCT": 5.24,
    "NOV": 5.42,
    "DEC": 5.6
  },
  {
    "LONG": -5.79,
    "LAT": -21.7,
    "CIDADE": "Porto Murtinho",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.09,
    "JAN": 5.61,
    "FEB": 5.54,
    "MAR": 5.6,
    "APR": 5.28,
    "MAY": 4.36,
    "JUN": 4.02,
    "JUL": 4.1,
    "AUG": 5.04,
    "SEP": 4.94,
    "OCT": 5.33,
    "NOV": 5.51,
    "DEC": 5.73
  },
  {
    "LONG": -52.42,
    "LAT": -21.72,
    "CIDADE": "Bataguassu",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.29,
    "JAN": 5.24,
    "FEB": 5.64,
    "MAR": 5.65,
    "APR": 5.48,
    "MAY": 4.87,
    "JUN": 4.59,
    "JUL": 4.71,
    "AUG": 5.59,
    "SEP": 5.17,
    "OCT": 5.33,
    "NOV": 5.57,
    "DEC": 5.63
  },
  {
    "LONG": -55.17,
    "LAT": -2.16,
    "CIDADE": "Maracaju",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.12,
    "JAN": 5.3,
    "FEB": 5.43,
    "MAR": 5.44,
    "APR": 5.24,
    "MAY": 4.61,
    "JUN": 4.38,
    "JUL": 4.51,
    "AUG": 5.3,
    "SEP": 5.11,
    "OCT": 5.23,
    "NOV": 5.35,
    "DEC": 5.57
  },
  {
    "LONG": -56.11,
    "LAT": -21.46,
    "CIDADE": "Guia Lopes da Laguna",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.15,
    "JAN": 5.3,
    "FEB": 5.48,
    "MAR": 5.64,
    "APR": 5.35,
    "MAY": 4.54,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.32,
    "SEP": 5.07,
    "OCT": 5.31,
    "NOV": 5.42,
    "DEC": 5.51
  },
  {
    "LONG": -56.15,
    "LAT": -21.48,
    "CIDADE": "Jardim",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.15,
    "JAN": 5.3,
    "FEB": 5.48,
    "MAR": 5.64,
    "APR": 5.35,
    "MAY": 4.54,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.32,
    "SEP": 5.07,
    "OCT": 5.31,
    "NOV": 5.42,
    "DEC": 5.51
  },
  {
    "LONG": -54.38,
    "LAT": -21.47,
    "CIDADE": "Nova Alvorada do Sul",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.16,
    "JAN": 5.2,
    "FEB": 5.47,
    "MAR": 5.52,
    "APR": 5.31,
    "MAY": 4.7,
    "JUN": 4.48,
    "JUL": 4.55,
    "AUG": 5.38,
    "SEP": 5.13,
    "OCT": 5.26,
    "NOV": 5.37,
    "DEC": 5.47
  },
  {
    "LONG": -52.83,
    "LAT": -21.3,
    "CIDADE": "Santa Rita do Pardo",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.29,
    "JAN": 5.21,
    "FEB": 5.62,
    "MAR": 5.59,
    "APR": 5.44,
    "MAY": 4.92,
    "JUN": 4.68,
    "JUL": 4.79,
    "AUG": 5.63,
    "SEP": 5.18,
    "OCT": 5.31,
    "NOV": 5.48,
    "DEC": 5.64
  },
  {
    "LONG": -52.04,
    "LAT": -21.25,
    "CIDADE": "Brasilândia",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.34,
    "JAN": 5.27,
    "FEB": 5.69,
    "MAR": 5.62,
    "APR": 5.52,
    "MAY": 4.98,
    "JUN": 4.74,
    "JUL": 4.85,
    "AUG": 5.66,
    "SEP": 5.28,
    "OCT": 5.44,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -55.83,
    "LAT": -21.16,
    "CIDADE": "Nioaque",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.18,
    "JAN": 5.28,
    "FEB": 5.48,
    "MAR": 5.6,
    "APR": 5.36,
    "MAY": 4.63,
    "JUN": 4.44,
    "JUL": 4.56,
    "AUG": 5.36,
    "SEP": 5.12,
    "OCT": 5.35,
    "NOV": 5.48,
    "DEC": 5.56
  },
  {
    "LONG": -5.65,
    "LAT": -21.13,
    "CIDADE": "Bonito",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.11,
    "JAN": 5.23,
    "FEB": 5.36,
    "MAR": 5.49,
    "APR": 5.31,
    "MAY": 4.54,
    "JUN": 4.39,
    "JUL": 4.52,
    "AUG": 5.31,
    "SEP": 5.07,
    "OCT": 5.26,
    "NOV": 5.42,
    "DEC": 5.41
  },
  {
    "LONG": -54.97,
    "LAT": -20.93,
    "CIDADE": "Sidrolândia",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.22,
    "JAN": 5.17,
    "FEB": 5.52,
    "MAR": 5.56,
    "APR": 5.39,
    "MAY": 4.75,
    "JUN": 4.6,
    "JUL": 4.7,
    "AUG": 5.45,
    "SEP": 5.24,
    "OCT": 5.32,
    "NOV": 5.45,
    "DEC": 5.49
  },
  {
    "LONG": -51.7,
    "LAT": -20.79,
    "CIDADE": "Três Lagoas",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.37,
    "JAN": 5.32,
    "FEB": 5.7,
    "MAR": 5.64,
    "APR": 5.48,
    "MAY": 4.97,
    "JUN": 4.8,
    "JUL": 4.97,
    "AUG": 5.76,
    "SEP": 5.28,
    "OCT": 5.46,
    "NOV": 5.49,
    "DEC": 5.61
  },
  {
    "LONG": -55.28,
    "LAT": -20.69,
    "CIDADE": "Dois Irmãos do Buriti",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.24,
    "JAN": 5.19,
    "FEB": 5.53,
    "MAR": 5.6,
    "APR": 5.41,
    "MAY": 4.75,
    "JUN": 4.65,
    "JUL": 4.73,
    "AUG": 5.44,
    "SEP": 5.22,
    "OCT": 5.36,
    "NOV": 5.48,
    "DEC": 5.51
  },
  {
    "LONG": -56.67,
    "LAT": -20.55,
    "CIDADE": "Bodoquena",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.13,
    "JAN": 5.21,
    "FEB": 5.32,
    "MAR": 5.49,
    "APR": 5.38,
    "MAY": 4.54,
    "JUN": 4.48,
    "JUL": 4.57,
    "AUG": 5.33,
    "SEP": 5.09,
    "OCT": 5.3,
    "NOV": 5.35,
    "DEC": 5.42
  },
  {
    "LONG": -55.81,
    "LAT": -20.48,
    "CIDADE": "Anastácio",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.25,
    "JAN": 5.24,
    "FEB": 5.58,
    "MAR": 5.58,
    "APR": 5.43,
    "MAY": 4.75,
    "JUN": 4.61,
    "JUL": 4.72,
    "AUG": 5.42,
    "SEP": 5.15,
    "OCT": 5.41,
    "NOV": 5.52,
    "DEC": 5.58
  },
  {
    "LONG": -55.79,
    "LAT": -2.05,
    "CIDADE": "Aquidauana",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.24,
    "JAN": 5.24,
    "FEB": 5.46,
    "MAR": 5.58,
    "APR": 5.44,
    "MAY": 4.72,
    "JUN": 4.61,
    "JUL": 4.72,
    "AUG": 5.45,
    "SEP": 5.16,
    "OCT": 5.38,
    "NOV": 5.53,
    "DEC": 5.58
  },
  {
    "LONG": -54.87,
    "LAT": -20.44,
    "CIDADE": "Terenos",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.27,
    "JAN": 5.16,
    "FEB": 5.52,
    "MAR": 5.58,
    "APR": 5.47,
    "MAY": 4.85,
    "JUN": 4.73,
    "JUL": 4.88,
    "AUG": 5.58,
    "SEP": 5.23,
    "OCT": 5.3,
    "NOV": 5.42,
    "DEC": 5.48
  },
  {
    "LONG": -54.63,
    "LAT": -20.45,
    "CIDADE": "Campo Grande",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.24,
    "JAN": 5.11,
    "FEB": 5.42,
    "MAR": 5.51,
    "APR": 5.43,
    "MAY": 4.87,
    "JUN": 4.74,
    "JUL": 4.85,
    "AUG": 5.6,
    "SEP": 5.23,
    "OCT": 5.28,
    "NOV": 5.4,
    "DEC": 5.4
  },
  {
    "LONG": -53.76,
    "LAT": -2.04,
    "CIDADE": "Ribas do Rio Pardo",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.25,
    "JAN": 5.16,
    "FEB": 5.54,
    "MAR": 5.55,
    "APR": 5.43,
    "MAY": 4.86,
    "JUN": 4.7,
    "JUL": 4.86,
    "AUG": 5.62,
    "SEP": 5.26,
    "OCT": 5.29,
    "NOV": 5.32,
    "DEC": 5.44
  },
  {
    "LONG": -52.88,
    "LAT": -20.45,
    "CIDADE": "Água Clara",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.31,
    "JAN": 5.17,
    "FEB": 5.62,
    "MAR": 5.62,
    "APR": 5.46,
    "MAY": 4.96,
    "JUN": 4.8,
    "JUL": 4.97,
    "AUG": 5.67,
    "SEP": 5.3,
    "OCT": 5.29,
    "NOV": 5.34,
    "DEC": 5.52
  },
  {
    "LONG": -51.42,
    "LAT": -20.36,
    "CIDADE": "Selvíria",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.38,
    "JAN": 5.28,
    "FEB": 5.68,
    "MAR": 5.63,
    "APR": 5.54,
    "MAY": 5.04,
    "JUN": 4.86,
    "JUL": 5.01,
    "AUG": 5.75,
    "SEP": 5.29,
    "OCT": 5.48,
    "NOV": 5.45,
    "DEC": 5.58
  },
  {
    "LONG": -5.64,
    "LAT": -2.02,
    "CIDADE": "Miranda",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.18,
    "JAN": 5.25,
    "FEB": 5.45,
    "MAR": 5.48,
    "APR": 5.43,
    "MAY": 4.67,
    "JUN": 4.56,
    "JUL": 4.68,
    "AUG": 5.32,
    "SEP": 5.15,
    "OCT": 5.33,
    "NOV": 5.4,
    "DEC": 5.49
  },
  {
    "LONG": -54.4,
    "LAT": -20.14,
    "CIDADE": "Jaraguari",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.25,
    "JAN": 5.08,
    "FEB": 5.4,
    "MAR": 5.5,
    "APR": 5.46,
    "MAY": 4.92,
    "JUN": 4.8,
    "JUL": 4.96,
    "AUG": 5.6,
    "SEP": 5.25,
    "OCT": 5.32,
    "NOV": 5.37,
    "DEC": 5.38
  },
  {
    "LONG": -51.1,
    "LAT": -20.09,
    "CIDADE": "Aparecida do Taboado",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.39,
    "JAN": 5.21,
    "FEB": 5.69,
    "MAR": 5.6,
    "APR": 5.61,
    "MAY": 5.11,
    "JUN": 4.89,
    "JUL": 5.04,
    "AUG": 5.77,
    "SEP": 5.27,
    "OCT": 5.48,
    "NOV": 5.49,
    "DEC": 5.57
  },
  {
    "LONG": -54.89,
    "LAT": -19.95,
    "CIDADE": "Rochedo",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.26,
    "JAN": 5.1,
    "FEB": 5.43,
    "MAR": 5.55,
    "APR": 5.49,
    "MAY": 4.86,
    "JUN": 4.77,
    "JUL": 4.88,
    "AUG": 5.61,
    "SEP": 5.28,
    "OCT": 5.34,
    "NOV": 5.38,
    "DEC": 5.42
  },
  {
    "LONG": -5.44,
    "LAT": -19.92,
    "CIDADE": "Bandeirantes",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.23,
    "JAN": 5.1,
    "FEB": 5.32,
    "MAR": 5.41,
    "APR": 5.41,
    "MAY": 4.93,
    "JUN": 4.83,
    "JUL": 4.98,
    "AUG": 5.6,
    "SEP": 5.26,
    "OCT": 5.27,
    "NOV": 5.28,
    "DEC": 5.36
  },
  {
    "LONG": -54.83,
    "LAT": -19.83,
    "CIDADE": "Corguinho",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.28,
    "JAN": 5.12,
    "FEB": 5.4,
    "MAR": 5.57,
    "APR": 5.48,
    "MAY": 4.9,
    "JUN": 4.81,
    "JUL": 4.96,
    "AUG": 5.63,
    "SEP": 5.3,
    "OCT": 5.34,
    "NOV": 5.4,
    "DEC": 5.41
  },
  {
    "LONG": -51.93,
    "LAT": -19.73,
    "CIDADE": "Inocência",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.38,
    "JAN": 5.13,
    "FEB": 5.58,
    "MAR": 5.5,
    "APR": 5.56,
    "MAY": 5.18,
    "JUN": 5.03,
    "JUL": 5.15,
    "AUG": 5.89,
    "SEP": 5.41,
    "OCT": 5.35,
    "NOV": 5.34,
    "DEC": 5.49
  },
  {
    "LONG": -51.19,
    "LAT": -19.68,
    "CIDADE": "Paranaíba",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.45,
    "JAN": 5.15,
    "FEB": 5.69,
    "MAR": 5.59,
    "APR": 5.66,
    "MAY": 5.19,
    "JUN": 5.03,
    "JUL": 5.23,
    "AUG": 6,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.41,
    "DEC": 5.49
  },
  {
    "LONG": -54.04,
    "LAT": -19.54,
    "CIDADE": "Camapuã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.35,
    "JAN": 5.15,
    "FEB": 5.56,
    "MAR": 5.57,
    "APR": 5.52,
    "MAY": 5.04,
    "JUN": 5.04,
    "JUL": 5.1,
    "AUG": 5.71,
    "SEP": 5.39,
    "OCT": 5.39,
    "NOV": 5.38,
    "DEC": 5.39
  },
  {
    "LONG": -54.99,
    "LAT": -19.45,
    "CIDADE": "Rio Negro",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.29,
    "JAN": 5,
    "FEB": 5.47,
    "MAR": 5.58,
    "APR": 5.52,
    "MAY": 4.93,
    "JUN": 4.91,
    "JUL": 5.01,
    "AUG": 5.64,
    "SEP": 5.34,
    "OCT": 5.33,
    "NOV": 5.34,
    "DEC": 5.37
  },
  {
    "LONG": -54.58,
    "LAT": -19.39,
    "CIDADE": "São Gabriel do Oeste",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.21,
    "JAN": 5.06,
    "FEB": 5.27,
    "MAR": 5.29,
    "APR": 5.34,
    "MAY": 4.94,
    "JUN": 4.96,
    "JUL": 5.02,
    "AUG": 5.62,
    "SEP": 5.31,
    "OCT": 5.2,
    "NOV": 5.18,
    "DEC": 5.28
  },
  {
    "LONG": -51.73,
    "LAT": -19.12,
    "CIDADE": "Cassilândia",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.44,
    "JAN": 5.16,
    "FEB": 5.59,
    "MAR": 5.51,
    "APR": 5.67,
    "MAY": 5.29,
    "JUN": 5.17,
    "JUL": 5.34,
    "AUG": 6.02,
    "SEP": 5.44,
    "OCT": 5.42,
    "NOV": 5.28,
    "DEC": 5.4
  },
  {
    "LONG": -57.65,
    "LAT": -19.01,
    "CIDADE": "Corumbá",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.12,
    "JAN": 5.18,
    "FEB": 5.35,
    "MAR": 5.41,
    "APR": 5.49,
    "MAY": 4.54,
    "JUN": 4.48,
    "JUL": 4.65,
    "AUG": 5.28,
    "SEP": 5.04,
    "OCT": 5.3,
    "NOV": 5.26,
    "DEC": 5.43
  },
  {
    "LONG": -57.6,
    "LAT": -19.01,
    "CIDADE": "Ladário",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.14,
    "JAN": 5.2,
    "FEB": 5.31,
    "MAR": 5.37,
    "APR": 5.5,
    "MAY": 4.55,
    "JUN": 4.54,
    "JUL": 4.74,
    "AUG": 5.36,
    "SEP": 5.05,
    "OCT": 5.33,
    "NOV": 5.28,
    "DEC": 5.44
  },
  {
    "LONG": -53.01,
    "LAT": -19.02,
    "CIDADE": "Paraíso das Águas",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.36,
    "JAN": 5.26,
    "FEB": 5.44,
    "MAR": 5.44,
    "APR": 5.53,
    "MAY": 5.2,
    "JUN": 5.08,
    "JUL": 5.18,
    "AUG": 5.94,
    "SEP": 5.37,
    "OCT": 5.28,
    "NOV": 5.21,
    "DEC": 5.37
  },
  {
    "LONG": -54.84,
    "LAT": -18.92,
    "CIDADE": "Rio Verde de Mato Grosso",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.3,
    "JAN": 5.06,
    "FEB": 5.4,
    "MAR": 5.45,
    "APR": 5.49,
    "MAY": 5.01,
    "JUN": 4.99,
    "JUL": 5.11,
    "AUG": 5.69,
    "SEP": 5.41,
    "OCT": 5.35,
    "NOV": 5.29,
    "DEC": 5.32
  },
  {
    "LONG": -52.63,
    "LAT": -18.79,
    "CIDADE": "Chapadão do Sul",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.27,
    "JAN": 5.15,
    "FEB": 5.39,
    "MAR": 5.18,
    "APR": 5.44,
    "MAY": 5.21,
    "JUN": 5.08,
    "JUL": 5.23,
    "AUG": 5.89,
    "SEP": 5.33,
    "OCT": 5.08,
    "NOV": 5.01,
    "DEC": 5.31
  },
  {
    "LONG": -53.64,
    "LAT": -18.68,
    "CIDADE": "Figueirão",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.43,
    "JAN": 5.16,
    "FEB": 5.59,
    "MAR": 5.58,
    "APR": 5.65,
    "MAY": 5.28,
    "JUN": 5.12,
    "JUL": 5.21,
    "AUG": 5.88,
    "SEP": 5.49,
    "OCT": 5.4,
    "NOV": 5.44,
    "DEC": 5.38
  },
  {
    "LONG": -54.75,
    "LAT": -18.5,
    "CIDADE": "Coxim",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.35,
    "JAN": 5.08,
    "FEB": 5.47,
    "MAR": 5.59,
    "APR": 5.51,
    "MAY": 5.05,
    "JUN": 5.08,
    "JUL": 5.1,
    "AUG": 5.84,
    "SEP": 5.39,
    "OCT": 5.38,
    "NOV": 5.36,
    "DEC": 5.35
  },
  {
    "LONG": -53.13,
    "LAT": -18.54,
    "CIDADE": "Costa Rica",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.3,
    "JAN": 5.06,
    "FEB": 5.36,
    "MAR": 5.31,
    "APR": 5.44,
    "MAY": 5.22,
    "JUN": 5.12,
    "JUL": 5.26,
    "AUG": 5.94,
    "SEP": 5.41,
    "OCT": 5.21,
    "NOV": 5.11,
    "DEC": 5.14
  },
  {
    "LONG": -53.7,
    "LAT": -1.83,
    "CIDADE": "Alcinópolis",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.43,
    "JAN": 5.13,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.64,
    "MAY": 5.33,
    "JUN": 5.16,
    "JUL": 5.26,
    "AUG": 5.94,
    "SEP": 5.5,
    "OCT": 5.36,
    "NOV": 5.34,
    "DEC": 5.33
  },
  {
    "LONG": -54.55,
    "LAT": -18.1,
    "CIDADE": "Pedro Gomes",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.35,
    "JAN": 5.05,
    "FEB": 5.42,
    "MAR": 5.56,
    "APR": 5.57,
    "MAY": 5.18,
    "JUN": 5.17,
    "JUL": 5.17,
    "AUG": 5.92,
    "SEP": 5.35,
    "OCT": 5.3,
    "NOV": 5.27,
    "DEC": 5.28
  },
  {
    "LONG": -54.75,
    "LAT": -17.58,
    "CIDADE": "Sonora",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.22,
    "JAN": 4.95,
    "FEB": 5.27,
    "MAR": 5.2,
    "APR": 5.21,
    "MAY": 5.12,
    "JUN": 5.08,
    "JUL": 5.24,
    "AUG": 5.89,
    "SEP": 5.25,
    "OCT": 5.11,
    "NOV": 5.14,
    "DEC": 5.2
  },
  {
    "LONG": -46.32,
    "LAT": -22.85,
    "CIDADE": "Extrema",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 4.75,
    "FEB": 5.26,
    "MAR": 4.99,
    "APR": 5.2,
    "MAY": 4.68,
    "JUN": 4.55,
    "JUL": 4.63,
    "AUG": 5.54,
    "SEP": 5.14,
    "OCT": 5.17,
    "NOV": 4.97,
    "DEC": 5.14
  },
  {
    "LONG": -46.22,
    "LAT": -2.28,
    "CIDADE": "Itapeva",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 4.62,
    "FEB": 5.2,
    "MAR": 4.93,
    "APR": 5.17,
    "MAY": 4.69,
    "JUN": 4.62,
    "JUL": 4.74,
    "AUG": 5.57,
    "SEP": 5.22,
    "OCT": 5.17,
    "NOV": 4.93,
    "DEC": 5.03
  },
  {
    "LONG": -46.15,
    "LAT": -2.28,
    "CIDADE": "Camanducaia",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.65,
    "FEB": 5.25,
    "MAR": 4.99,
    "APR": 5.21,
    "MAY": 4.72,
    "JUN": 4.62,
    "JUL": 4.77,
    "AUG": 5.59,
    "SEP": 5.25,
    "OCT": 5.24,
    "NOV": 5.02,
    "DEC": 5.09
  },
  {
    "LONG": -46.37,
    "LAT": -22.74,
    "CIDADE": "Toledo",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 4.67,
    "FEB": 5.21,
    "MAR": 4.92,
    "APR": 5.14,
    "MAY": 4.72,
    "JUN": 4.65,
    "JUL": 4.78,
    "AUG": 5.6,
    "SEP": 5.25,
    "OCT": 5.23,
    "NOV": 4.95,
    "DEC": 5.03
  },
  {
    "LONG": -4.59,
    "LAT": -2.27,
    "CIDADE": "Gonçalves",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 4.57,
    "FEB": 5.15,
    "MAR": 4.75,
    "APR": 4.93,
    "MAY": 4.56,
    "JUN": 4.45,
    "JUL": 4.68,
    "AUG": 5.56,
    "SEP": 5.13,
    "OCT": 5.05,
    "NOV": 4.68,
    "DEC": 4.89
  },
  {
    "LONG": -45.74,
    "LAT": -22.74,
    "CIDADE": "Sapucaí-Mirim",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 4.74,
    "FEB": 5.36,
    "MAR": 5,
    "APR": 5.09,
    "MAY": 4.63,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.55,
    "SEP": 5.15,
    "OCT": 5.14,
    "NOV": 4.85,
    "DEC": 5.07
  },
  {
    "LONG": -46.36,
    "LAT": -22.61,
    "CIDADE": "Munhoz",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.77,
    "FEB": 5.28,
    "MAR": 5.04,
    "APR": 5.19,
    "MAY": 4.77,
    "JUN": 4.68,
    "JUL": 4.84,
    "AUG": 5.64,
    "SEP": 5.31,
    "OCT": 5.27,
    "NOV": 5.05,
    "DEC": 5.11
  },
  {
    "LONG": -46.18,
    "LAT": -22.59,
    "CIDADE": "Senador Amaral",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 4.59,
    "FEB": 5.14,
    "MAR": 4.82,
    "APR": 5.01,
    "MAY": 4.67,
    "JUN": 4.57,
    "JUL": 4.77,
    "AUG": 5.59,
    "SEP": 5.18,
    "OCT": 5.07,
    "NOV": 4.74,
    "DEC": 4.88
  },
  {
    "LONG": -46.02,
    "LAT": -22.63,
    "CIDADE": "Córrego do Bom Jesus",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 4.72,
    "FEB": 5.29,
    "MAR": 4.95,
    "APR": 5.18,
    "MAY": 4.74,
    "JUN": 4.6,
    "JUL": 4.79,
    "AUG": 5.61,
    "SEP": 5.22,
    "OCT": 5.16,
    "NOV": 4.84,
    "DEC": 4.96
  },
  {
    "LONG": -46.06,
    "LAT": -2.26,
    "CIDADE": "Cambuí",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 4.72,
    "FEB": 5.29,
    "MAR": 4.95,
    "APR": 5.18,
    "MAY": 4.74,
    "JUN": 4.6,
    "JUL": 4.79,
    "AUG": 5.61,
    "SEP": 5.22,
    "OCT": 5.16,
    "NOV": 4.84,
    "DEC": 4.96
  },
  {
    "LONG": -45.78,
    "LAT": -22.55,
    "CIDADE": "Paraisópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.81,
    "FEB": 5.4,
    "MAR": 5.09,
    "APR": 5.21,
    "MAY": 4.73,
    "JUN": 4.61,
    "JUL": 4.79,
    "AUG": 5.58,
    "SEP": 5.27,
    "OCT": 5.24,
    "NOV": 4.93,
    "DEC": 5.11
  },
  {
    "LONG": -46.14,
    "LAT": -2.25,
    "CIDADE": "Bom Repouso",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 4.65,
    "FEB": 5.22,
    "MAR": 4.86,
    "APR": 5.05,
    "MAY": 4.67,
    "JUN": 4.55,
    "JUL": 4.8,
    "AUG": 5.64,
    "SEP": 5.23,
    "OCT": 5.13,
    "NOV": 4.8,
    "DEC": 4.94
  },
  {
    "LONG": -46.02,
    "LAT": -22.46,
    "CIDADE": "Estiva",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 4.64,
    "FEB": 5.26,
    "MAR": 4.87,
    "APR": 5.08,
    "MAY": 4.66,
    "JUN": 4.53,
    "JUL": 4.78,
    "AUG": 5.56,
    "SEP": 5.18,
    "OCT": 5.11,
    "NOV": 4.79,
    "DEC": 4.96
  },
  {
    "LONG": -45.93,
    "LAT": -22.55,
    "CIDADE": "Consolação",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 4.75,
    "FEB": 5.38,
    "MAR": 4.99,
    "APR": 5.23,
    "MAY": 4.73,
    "JUN": 4.58,
    "JUL": 4.82,
    "AUG": 5.61,
    "SEP": 5.25,
    "OCT": 5.22,
    "NOV": 4.89,
    "DEC": 5.05
  },
  {
    "LONG": -45.61,
    "LAT": -22.47,
    "CIDADE": "Brazópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.85,
    "FEB": 5.42,
    "MAR": 5.07,
    "APR": 5.21,
    "MAY": 4.72,
    "JUN": 4.59,
    "JUL": 4.83,
    "AUG": 5.55,
    "SEP": 5.29,
    "OCT": 5.24,
    "NOV": 4.93,
    "DEC": 5.13
  },
  {
    "LONG": -45.49,
    "LAT": -22.53,
    "CIDADE": "Piranguçu",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.86,
    "FEB": 5.43,
    "MAR": 5.12,
    "APR": 5.24,
    "MAY": 4.74,
    "JUN": 4.62,
    "JUL": 4.81,
    "AUG": 5.58,
    "SEP": 5.33,
    "OCT": 5.29,
    "NOV": 4.93,
    "DEC": 5.16
  },
  {
    "LONG": -4.54,
    "LAT": -22.54,
    "CIDADE": "Wenceslau Braz",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.84,
    "FEB": 5.39,
    "MAR": 5.07,
    "APR": 5.16,
    "MAY": 4.73,
    "JUN": 4.65,
    "JUL": 4.82,
    "AUG": 5.6,
    "SEP": 5.32,
    "OCT": 5.28,
    "NOV": 4.9,
    "DEC": 5.05
  },
  {
    "LONG": -45.28,
    "LAT": -22.5,
    "CIDADE": "Delfim Moreira",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 4.71,
    "FEB": 5.18,
    "MAR": 4.83,
    "APR": 4.96,
    "MAY": 4.64,
    "JUN": 4.62,
    "JUL": 4.78,
    "AUG": 5.58,
    "SEP": 5.23,
    "OCT": 5.1,
    "NOV": 4.73,
    "DEC": 4.84
  },
  {
    "LONG": -46.57,
    "LAT": -2.24,
    "CIDADE": "Monte Sião",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 4.92,
    "FEB": 5.41,
    "MAR": 5.24,
    "APR": 5.38,
    "MAY": 4.85,
    "JUN": 4.71,
    "JUL": 4.91,
    "AUG": 5.62,
    "SEP": 5.3,
    "OCT": 5.3,
    "NOV": 5.12,
    "DEC": 5.13
  },
  {
    "LONG": -46.35,
    "LAT": -22.44,
    "CIDADE": "Bueno Brandão",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 4.91,
    "FEB": 5.45,
    "MAR": 5.23,
    "APR": 5.33,
    "MAY": 4.87,
    "JUN": 4.7,
    "JUL": 4.91,
    "AUG": 5.64,
    "SEP": 5.35,
    "OCT": 5.31,
    "NOV": 5.09,
    "DEC": 5.14
  },
  {
    "LONG": -46.1,
    "LAT": -22.37,
    "CIDADE": "Tocos do Moji",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.72,
    "FEB": 5.36,
    "MAR": 5,
    "APR": 5.16,
    "MAY": 4.72,
    "JUN": 4.57,
    "JUL": 4.86,
    "AUG": 5.64,
    "SEP": 5.26,
    "OCT": 5.2,
    "NOV": 4.89,
    "DEC": 5.03
  },
  {
    "LONG": "#VALOR!",
    "LAT": -22.41,
    "CIDADE": "Conceição dos Ouros",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.82,
    "FEB": 5.44,
    "MAR": 5.05,
    "APR": 5.26,
    "MAY": 4.79,
    "JUN": 4.66,
    "JUL": 4.86,
    "AUG": 5.66,
    "SEP": 5.29,
    "OCT": 5.29,
    "NOV": 4.96,
    "DEC": 5.09
  },
  {
    "LONG": -45.78,
    "LAT": -22.35,
    "CIDADE": "Cachoeira de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.89,
    "FEB": 5.41,
    "MAR": 5.1,
    "APR": 5.26,
    "MAY": 4.78,
    "JUN": 4.67,
    "JUL": 4.84,
    "AUG": 5.57,
    "SEP": 5.29,
    "OCT": 5.27,
    "NOV": 4.99,
    "DEC": 5.13
  },
  {
    "LONG": -45.53,
    "LAT": -22.4,
    "CIDADE": "Piranguinho",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 4.89,
    "FEB": 5.45,
    "MAR": 5.13,
    "APR": 5.29,
    "MAY": 4.75,
    "JUN": 4.65,
    "JUL": 4.85,
    "AUG": 5.61,
    "SEP": 5.34,
    "OCT": 5.3,
    "NOV": 4.96,
    "DEC": 5.15
  },
  {
    "LONG": -45.46,
    "LAT": -2.24,
    "CIDADE": "Itajubá",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.87,
    "FEB": 5.42,
    "MAR": 5.11,
    "APR": 5.22,
    "MAY": 4.77,
    "JUN": 4.67,
    "JUL": 4.86,
    "AUG": 5.6,
    "SEP": 5.34,
    "OCT": 5.3,
    "NOV": 4.95,
    "DEC": 5.12
  },
  {
    "LONG": -45.16,
    "LAT": -22.45,
    "CIDADE": "Marmelópolis",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 4.53,
    "FEB": 5.14,
    "MAR": 4.74,
    "APR": 4.92,
    "MAY": 4.62,
    "JUN": 4.54,
    "JUL": 4.75,
    "AUG": 5.59,
    "SEP": 5.26,
    "OCT": 5.06,
    "NOV": 4.59,
    "DEC": 4.8
  },
  {
    "LONG": -44.97,
    "LAT": -22.39,
    "CIDADE": "Passa Quatro",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.82,
    "FEB": 5.44,
    "MAR": 5.08,
    "APR": 5.15,
    "MAY": 4.79,
    "JUN": 4.62,
    "JUL": 4.79,
    "AUG": 5.57,
    "SEP": 5.34,
    "OCT": 5.26,
    "NOV": 4.81,
    "DEC": 5.07
  },
  {
    "LONG": -4.66,
    "LAT": -22.29,
    "CIDADE": "Jacutinga",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.88,
    "FEB": 5.3,
    "MAR": 5.14,
    "APR": 5.3,
    "MAY": 4.83,
    "JUN": 4.74,
    "JUL": 4.92,
    "AUG": 5.67,
    "SEP": 5.3,
    "OCT": 5.28,
    "NOV": 5.03,
    "DEC": 5.08
  },
  {
    "LONG": -4.64,
    "LAT": -22.28,
    "CIDADE": "Ouro Fino",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.79,
    "FEB": 5.32,
    "MAR": 5.09,
    "APR": 5.29,
    "MAY": 4.84,
    "JUN": 4.71,
    "JUL": 4.92,
    "AUG": 5.63,
    "SEP": 5.32,
    "OCT": 5.27,
    "NOV": 5.01,
    "DEC": 5.05
  },
  {
    "LONG": -46.33,
    "LAT": -2.23,
    "CIDADE": "Inconfidentes",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.79,
    "FEB": 5.32,
    "MAR": 5.09,
    "APR": 5.29,
    "MAY": 4.84,
    "JUN": 4.71,
    "JUL": 4.92,
    "AUG": 5.63,
    "SEP": 5.32,
    "OCT": 5.27,
    "NOV": 5.01,
    "DEC": 5.05
  },
  {
    "LONG": -46.17,
    "LAT": -22.27,
    "CIDADE": "Borda da Mata",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.72,
    "FEB": 5.33,
    "MAR": 5.06,
    "APR": 5.21,
    "MAY": 4.81,
    "JUN": 4.61,
    "JUL": 4.91,
    "AUG": 5.66,
    "SEP": 5.34,
    "OCT": 5.25,
    "NOV": 4.96,
    "DEC": 5.03
  },
  {
    "LONG": -45.53,
    "LAT": -22.32,
    "CIDADE": "São José do Alegre",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.82,
    "FEB": 5.36,
    "MAR": 5.04,
    "APR": 5.19,
    "MAY": 4.73,
    "JUN": 4.68,
    "JUL": 4.86,
    "AUG": 5.62,
    "SEP": 5.29,
    "OCT": 5.23,
    "NOV": 4.85,
    "DEC": 5.02
  },
  {
    "LONG": -45.38,
    "LAT": -22.3,
    "CIDADE": "Maria da Fé",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 4.76,
    "FEB": 5.26,
    "MAR": 4.92,
    "APR": 5.06,
    "MAY": 4.71,
    "JUN": 4.66,
    "JUL": 4.86,
    "AUG": 5.63,
    "SEP": 5.29,
    "OCT": 5.16,
    "NOV": 4.78,
    "DEC": 4.91
  },
  {
    "LONG": -45.16,
    "LAT": -22.25,
    "CIDADE": "Dom Viçoso",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 4.59,
    "FEB": 5.25,
    "MAR": 4.83,
    "APR": 4.99,
    "MAY": 4.7,
    "JUN": 4.48,
    "JUL": 4.77,
    "AUG": 5.57,
    "SEP": 5.24,
    "OCT": 5.09,
    "NOV": 4.63,
    "DEC": 4.85
  },
  {
    "LONG": -45.1,
    "LAT": -22.33,
    "CIDADE": "Virgínia",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.79,
    "FEB": 5.45,
    "MAR": 5.08,
    "APR": 5.21,
    "MAY": 4.83,
    "JUN": 4.59,
    "JUL": 4.84,
    "AUG": 5.61,
    "SEP": 5.36,
    "OCT": 5.28,
    "NOV": 4.86,
    "DEC": 5.06
  },
  {
    "LONG": -44.94,
    "LAT": -22.29,
    "CIDADE": "Itanhandu",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 4.96,
    "FEB": 5.55,
    "MAR": 5.2,
    "APR": 5.31,
    "MAY": 4.88,
    "JUN": 4.67,
    "JUL": 4.86,
    "AUG": 5.59,
    "SEP": 5.4,
    "OCT": 5.37,
    "NOV": 4.96,
    "DEC": 5.15
  },
  {
    "LONG": -44.87,
    "LAT": -22.29,
    "CIDADE": "Itamonte",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.87,
    "FEB": 5.41,
    "MAR": 5.12,
    "APR": 5.2,
    "MAY": 4.8,
    "JUN": 4.68,
    "JUL": 4.86,
    "AUG": 5.6,
    "SEP": 5.37,
    "OCT": 5.34,
    "NOV": 4.86,
    "DEC": 5.08
  },
  {
    "LONG": -46.61,
    "LAT": -22.2,
    "CIDADE": "Albertina",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.83,
    "FEB": 5.27,
    "MAR": 5.04,
    "APR": 5.26,
    "MAY": 4.83,
    "JUN": 4.73,
    "JUL": 4.93,
    "AUG": 5.64,
    "SEP": 5.31,
    "OCT": 5.27,
    "NOV": 5.04,
    "DEC": 5.07
  },
  {
    "LONG": -46.18,
    "LAT": -22.16,
    "CIDADE": "Senador José Bento",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 4.62,
    "FEB": 5.21,
    "MAR": 4.92,
    "APR": 5.11,
    "MAY": 4.77,
    "JUN": 4.59,
    "JUL": 4.89,
    "AUG": 5.65,
    "SEP": 5.28,
    "OCT": 5.15,
    "NOV": 4.81,
    "DEC": 4.89
  },
  {
    "LONG": -45.94,
    "LAT": -22.23,
    "CIDADE": "Pouso Alegre",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.76,
    "FEB": 5.31,
    "MAR": 4.99,
    "APR": 5.22,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.91,
    "AUG": 5.67,
    "SEP": 5.33,
    "OCT": 5.2,
    "NOV": 4.91,
    "DEC": 4.97
  },
  {
    "LONG": -45.7,
    "LAT": -22.25,
    "CIDADE": "Santa Rita do Sapucaí",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.86,
    "FEB": 5.45,
    "MAR": 5.08,
    "APR": 5.25,
    "MAY": 4.8,
    "JUN": 4.68,
    "JUL": 4.87,
    "AUG": 5.69,
    "SEP": 5.29,
    "OCT": 5.25,
    "NOV": 4.89,
    "DEC": 5.05
  },
  {
    "LONG": -4.58,
    "LAT": -22.16,
    "CIDADE": "São Sebastião da Bela Vista",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.86,
    "FEB": 5.45,
    "MAR": 5.08,
    "APR": 5.25,
    "MAY": 4.8,
    "JUN": 4.68,
    "JUL": 4.87,
    "AUG": 5.69,
    "SEP": 5.29,
    "OCT": 5.25,
    "NOV": 4.89,
    "DEC": 5.05
  },
  {
    "LONG": -45.46,
    "LAT": -22.16,
    "CIDADE": "Conceição das Pedras",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.79,
    "FEB": 5.34,
    "MAR": 4.93,
    "APR": 5.12,
    "MAY": 4.72,
    "JUN": 4.67,
    "JUL": 4.9,
    "AUG": 5.66,
    "SEP": 5.29,
    "OCT": 5.2,
    "NOV": 4.78,
    "DEC": 4.95
  },
  {
    "LONG": -45.47,
    "LAT": -22.24,
    "CIDADE": "Pedralva",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.79,
    "FEB": 5.34,
    "MAR": 4.93,
    "APR": 5.12,
    "MAY": 4.72,
    "JUN": 4.67,
    "JUL": 4.9,
    "AUG": 5.66,
    "SEP": 5.29,
    "OCT": 5.2,
    "NOV": 4.78,
    "DEC": 4.95
  },
  {
    "LONG": -45.27,
    "LAT": -22.21,
    "CIDADE": "Cristina",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.82,
    "FEB": 5.35,
    "MAR": 5,
    "APR": 5.12,
    "MAY": 4.77,
    "JUN": 4.63,
    "JUL": 4.86,
    "AUG": 5.63,
    "SEP": 5.32,
    "OCT": 5.21,
    "NOV": 4.77,
    "DEC": 4.92
  },
  {
    "LONG": -44.98,
    "LAT": -22.2,
    "CIDADE": "Pouso Alto",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 4.95,
    "FEB": 5.52,
    "MAR": 5.15,
    "APR": 5.3,
    "MAY": 4.88,
    "JUN": 4.66,
    "JUL": 4.87,
    "AUG": 5.61,
    "SEP": 5.38,
    "OCT": 5.36,
    "NOV": 4.9,
    "DEC": 5.08
  },
  {
    "LONG": -44.98,
    "LAT": -22.22,
    "CIDADE": "São Sebastião do Rio Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 4.95,
    "FEB": 5.52,
    "MAR": 5.15,
    "APR": 5.3,
    "MAY": 4.88,
    "JUN": 4.66,
    "JUL": 4.87,
    "AUG": 5.61,
    "SEP": 5.38,
    "OCT": 5.36,
    "NOV": 4.9,
    "DEC": 5.08
  },
  {
    "LONG": -44.64,
    "LAT": -22.17,
    "CIDADE": "Alagoa",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 4.63,
    "FEB": 5.12,
    "MAR": 4.7,
    "APR": 4.9,
    "MAY": 4.61,
    "JUN": 4.59,
    "JUL": 4.78,
    "AUG": 5.53,
    "SEP": 5.18,
    "OCT": 4.89,
    "NOV": 4.43,
    "DEC": 4.68
  },
  {
    "LONG": -44.4,
    "LAT": -22.17,
    "CIDADE": "Bocaina de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.63,
    "FEB": 5.33,
    "MAR": 4.79,
    "APR": 4.75,
    "MAY": 4.45,
    "JUN": 4.46,
    "JUL": 4.45,
    "AUG": 5.3,
    "SEP": 4.9,
    "OCT": 4.65,
    "NOV": 4.26,
    "DEC": 4.78
  },
  {
    "LONG": -44.24,
    "LAT": -22.21,
    "CIDADE": "Passa-Vinte",
    "UF": "MINAS GERAIS",
    "anual": 4.71,
    "JAN": 4.73,
    "FEB": 5.36,
    "MAR": 4.81,
    "APR": 4.72,
    "MAY": 4.39,
    "JUN": 4.44,
    "JUL": 4.41,
    "AUG": 5.25,
    "SEP": 4.82,
    "OCT": 4.59,
    "NOV": 4.27,
    "DEC": 4.73
  },
  {
    "LONG": -46.57,
    "LAT": "#VALOR!",
    "CIDADE": "Andradas",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 4.77,
    "FEB": 5.22,
    "MAR": 4.97,
    "APR": 5.2,
    "MAY": 4.83,
    "JUN": 4.75,
    "JUL": 4.9,
    "AUG": 5.66,
    "SEP": 5.29,
    "OCT": 5.2,
    "NOV": 4.93,
    "DEC": 4.93
  },
  {
    "LONG": -46.44,
    "LAT": -22.06,
    "CIDADE": "Ibitiúra de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.71,
    "FEB": 5.18,
    "MAR": 4.91,
    "APR": 5.15,
    "MAY": 4.79,
    "JUN": 4.75,
    "JUL": 4.92,
    "AUG": 5.69,
    "SEP": 5.3,
    "OCT": 5.16,
    "NOV": 4.88,
    "DEC": 4.88
  },
  {
    "LONG": -46.19,
    "LAT": -22.1,
    "CIDADE": "Ipuiúna",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 4.62,
    "FEB": 5.15,
    "MAR": 4.78,
    "APR": 4.99,
    "MAY": 4.72,
    "JUN": 4.6,
    "JUL": 4.87,
    "AUG": 5.67,
    "SEP": 5.26,
    "OCT": 5.07,
    "NOV": 4.7,
    "DEC": 4.78
  },
  {
    "LONG": -46.04,
    "LAT": -22.15,
    "CIDADE": "Congonhal",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 4.62,
    "FEB": 5.17,
    "MAR": 4.73,
    "APR": 5,
    "MAY": 4.7,
    "JUN": 4.59,
    "JUL": 4.85,
    "AUG": 5.68,
    "SEP": 5.23,
    "OCT": 5.09,
    "NOV": 4.7,
    "DEC": 4.84
  },
  {
    "LONG": -45.55,
    "LAT": -22.06,
    "CIDADE": "Heliodora",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.88,
    "FEB": 5.45,
    "MAR": 5.01,
    "APR": 5.2,
    "MAY": 4.82,
    "JUN": 4.71,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.32,
    "OCT": 5.28,
    "NOV": 4.84,
    "DEC": 5.04
  },
  {
    "LONG": -45.51,
    "LAT": -22.12,
    "CIDADE": "Natércia",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.88,
    "FEB": 5.45,
    "MAR": 5.01,
    "APR": 5.2,
    "MAY": 4.82,
    "JUN": 4.71,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.32,
    "OCT": 5.28,
    "NOV": 4.84,
    "DEC": 5.04
  },
  {
    "LONG": -45.27,
    "LAT": -2.21,
    "CIDADE": "Olímpio Noronha",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 4.83,
    "FEB": 5.42,
    "MAR": 5.01,
    "APR": 5.17,
    "MAY": 4.77,
    "JUN": 4.62,
    "JUL": 4.87,
    "AUG": 5.61,
    "SEP": 5.33,
    "OCT": 5.22,
    "NOV": 4.8,
    "DEC": 4.97
  },
  {
    "LONG": -45.13,
    "LAT": -22.12,
    "CIDADE": "Carmo de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.82,
    "FEB": 5.43,
    "MAR": 5.07,
    "APR": 5.2,
    "MAY": 4.81,
    "JUN": 4.62,
    "JUL": 4.84,
    "AUG": 5.59,
    "SEP": 5.34,
    "OCT": 5.25,
    "NOV": 4.83,
    "DEC": 4.99
  },
  {
    "LONG": -45.05,
    "LAT": -22.06,
    "CIDADE": "Soledade de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.91,
    "FEB": 5.51,
    "MAR": 5.14,
    "APR": 5.26,
    "MAY": 4.82,
    "JUN": 4.66,
    "JUL": 4.85,
    "AUG": 5.6,
    "SEP": 5.39,
    "OCT": 5.32,
    "NOV": 4.93,
    "DEC": 5.06
  },
  {
    "LONG": -4.51,
    "LAT": -22.12,
    "CIDADE": "São Lourenço",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.91,
    "FEB": 5.51,
    "MAR": 5.14,
    "APR": 5.26,
    "MAY": 4.82,
    "JUN": 4.66,
    "JUL": 4.85,
    "AUG": 5.6,
    "SEP": 5.39,
    "OCT": 5.32,
    "NOV": 4.93,
    "DEC": 5.06
  },
  {
    "LONG": -44.1,
    "LAT": -22.15,
    "CIDADE": "Santa Rita de Jacutinga",
    "UF": "MINAS GERAIS",
    "anual": 4.75,
    "JAN": 4.8,
    "FEB": 5.42,
    "MAR": 4.89,
    "APR": 4.8,
    "MAY": 4.39,
    "JUN": 4.43,
    "JUL": 4.44,
    "AUG": 5.21,
    "SEP": 4.83,
    "OCT": 4.64,
    "NOV": 4.34,
    "DEC": 4.79
  },
  {
    "LONG": -43.83,
    "LAT": -22.09,
    "CIDADE": "Rio Preto",
    "UF": "MINAS GERAIS",
    "anual": 4.76,
    "JAN": 4.86,
    "FEB": 5.48,
    "MAR": 4.92,
    "APR": 4.79,
    "MAY": 4.38,
    "JUN": 4.39,
    "JUL": 4.41,
    "AUG": 5.17,
    "SEP": 4.87,
    "OCT": 4.64,
    "NOV": 4.36,
    "DEC": 4.79
  },
  {
    "LONG": -46.34,
    "LAT": -22.03,
    "CIDADE": "Santa Rita de Caldas",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 4.66,
    "FEB": 5.22,
    "MAR": 4.79,
    "APR": 5.02,
    "MAY": 4.75,
    "JUN": 4.66,
    "JUL": 4.9,
    "AUG": 5.71,
    "SEP": 5.28,
    "OCT": 5.1,
    "NOV": 4.71,
    "DEC": 4.84
  },
  {
    "LONG": -45.96,
    "LAT": -22.05,
    "CIDADE": "Espírito Santo do Dourado",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 4.67,
    "FEB": 5.25,
    "MAR": 4.81,
    "APR": 5,
    "MAY": 4.71,
    "JUN": 4.61,
    "JUL": 4.87,
    "AUG": 5.64,
    "SEP": 5.26,
    "OCT": 5.13,
    "NOV": 4.68,
    "DEC": 4.87
  },
  {
    "LONG": -45.84,
    "LAT": -22.03,
    "CIDADE": "Silvianópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.76,
    "FEB": 5.37,
    "MAR": 4.92,
    "APR": 5.13,
    "MAY": 4.78,
    "JUN": 4.68,
    "JUL": 4.89,
    "AUG": 5.7,
    "SEP": 5.29,
    "OCT": 5.18,
    "NOV": 4.75,
    "DEC": 4.93
  },
  {
    "LONG": -45.7,
    "LAT": -22.04,
    "CIDADE": "Careaçu",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.9,
    "FEB": 5.47,
    "MAR": 5.04,
    "APR": 5.24,
    "MAY": 4.81,
    "JUN": 4.71,
    "JUL": 4.89,
    "AUG": 5.68,
    "SEP": 5.32,
    "OCT": 5.27,
    "NOV": 4.84,
    "DEC": 5.02
  },
  {
    "LONG": -45.35,
    "LAT": -21.97,
    "CIDADE": "Lambari",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.87,
    "FEB": 5.33,
    "MAR": 4.95,
    "APR": 5.12,
    "MAY": 4.78,
    "JUN": 4.63,
    "JUL": 4.89,
    "AUG": 5.64,
    "SEP": 5.33,
    "OCT": 5.23,
    "NOV": 4.72,
    "DEC": 4.91
  },
  {
    "LONG": -45.29,
    "LAT": -21.99,
    "CIDADE": "Jesuânia",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.91,
    "FEB": 5.41,
    "MAR": 5.05,
    "APR": 5.2,
    "MAY": 4.85,
    "JUN": 4.64,
    "JUL": 4.91,
    "AUG": 5.68,
    "SEP": 5.36,
    "OCT": 5.26,
    "NOV": 4.85,
    "DEC": 4.99
  },
  {
    "LONG": -44.93,
    "LAT": -21.98,
    "CIDADE": "Caxambu",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 4.95,
    "FEB": 5.53,
    "MAR": 5.17,
    "APR": 5.27,
    "MAY": 4.8,
    "JUN": 4.71,
    "JUL": 4.93,
    "AUG": 5.64,
    "SEP": 5.41,
    "OCT": 5.31,
    "NOV": 4.92,
    "DEC": 5.09
  },
  {
    "LONG": -44.89,
    "LAT": -21.96,
    "CIDADE": "Baependi",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 4.96,
    "FEB": 5.54,
    "MAR": 5.21,
    "APR": 5.29,
    "MAY": 4.84,
    "JUN": 4.73,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.46,
    "OCT": 5.3,
    "NOV": 4.91,
    "DEC": 5.07
  },
  {
    "LONG": -44.6,
    "LAT": -21.97,
    "CIDADE": "Aiuruoca",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 4.83,
    "FEB": 5.4,
    "MAR": 5.04,
    "APR": 5.09,
    "MAY": 4.67,
    "JUN": 4.62,
    "JUL": 4.85,
    "AUG": 5.57,
    "SEP": 5.29,
    "OCT": 5.12,
    "NOV": 4.72,
    "DEC": 4.94
  },
  {
    "LONG": -44.46,
    "LAT": -22,
    "CIDADE": "Carvalhos",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 4.89,
    "FEB": 5.48,
    "MAR": 5.06,
    "APR": 5.05,
    "MAY": 4.62,
    "JUN": 4.54,
    "JUL": 4.72,
    "AUG": 5.5,
    "SEP": 5.21,
    "OCT": 5.01,
    "NOV": 4.66,
    "DEC": 4.93
  },
  {
    "LONG": -44.32,
    "LAT": -2.2,
    "CIDADE": "Liberdade",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 4.87,
    "FEB": 5.5,
    "MAR": 5.03,
    "APR": 5.04,
    "MAY": 4.55,
    "JUN": 4.53,
    "JUL": 4.63,
    "AUG": 5.46,
    "SEP": 5.13,
    "OCT": 4.92,
    "NOV": 4.63,
    "DEC": 4.89
  },
  {
    "LONG": -43.7,
    "LAT": -21.96,
    "CIDADE": "Santa Bárbara do Monte Verde",
    "UF": "MINAS GERAIS",
    "anual": 4.72,
    "JAN": 4.83,
    "FEB": 5.47,
    "MAR": 4.88,
    "APR": 4.75,
    "MAY": 4.34,
    "JUN": 4.39,
    "JUL": 4.41,
    "AUG": 5.14,
    "SEP": 4.83,
    "OCT": 4.6,
    "NOV": 4.28,
    "DEC": 4.75
  },
  {
    "LONG": -43.31,
    "LAT": -21.96,
    "CIDADE": "Simão Pereira",
    "UF": "MINAS GERAIS",
    "anual": 4.8,
    "JAN": 5.06,
    "FEB": 5.61,
    "MAR": 5.05,
    "APR": 4.85,
    "MAY": 4.32,
    "JUN": 4.28,
    "JUL": 4.4,
    "AUG": 4.98,
    "SEP": 4.88,
    "OCT": 4.78,
    "NOV": 4.47,
    "DEC": 4.97
  },
  {
    "LONG": -43.16,
    "LAT": -21.95,
    "CIDADE": "Santana do Deserto",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 5.2,
    "FEB": 5.7,
    "MAR": 5.13,
    "APR": 4.91,
    "MAY": 4.32,
    "JUN": 4.31,
    "JUL": 4.42,
    "AUG": 5.01,
    "SEP": 4.88,
    "OCT": 4.8,
    "NOV": 4.5,
    "DEC": 5
  },
  {
    "LONG": -4.31,
    "LAT": -22,
    "CIDADE": "Chiador",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.23,
    "FEB": 5.72,
    "MAR": 5.19,
    "APR": 4.93,
    "MAY": 4.35,
    "JUN": 4.29,
    "JUL": 4.44,
    "AUG": 5.03,
    "SEP": 4.9,
    "OCT": 4.82,
    "NOV": 4.56,
    "DEC": 5.03
  },
  {
    "LONG": -46.38,
    "LAT": -21.92,
    "CIDADE": "Caldas",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 4.72,
    "FEB": 5.27,
    "MAR": 4.85,
    "APR": 5.11,
    "MAY": 4.79,
    "JUN": 4.69,
    "JUL": 4.94,
    "AUG": 5.73,
    "SEP": 5.32,
    "OCT": 5.14,
    "NOV": 4.73,
    "DEC": 4.86
  },
  {
    "LONG": -45.93,
    "LAT": -21.93,
    "CIDADE": "São João da Mata",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 4.76,
    "FEB": 5.33,
    "MAR": 4.88,
    "APR": 5.08,
    "MAY": 4.77,
    "JUN": 4.63,
    "JUL": 4.89,
    "AUG": 5.71,
    "SEP": 5.3,
    "OCT": 5.16,
    "NOV": 4.7,
    "DEC": 4.95
  },
  {
    "LONG": -45.79,
    "LAT": -21.87,
    "CIDADE": "Turvolândia",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.9,
    "FEB": 5.45,
    "MAR": 5.05,
    "APR": 5.18,
    "MAY": 4.83,
    "JUN": 4.75,
    "JUL": 4.93,
    "AUG": 5.68,
    "SEP": 5.33,
    "OCT": 5.26,
    "NOV": 4.83,
    "DEC": 5.02
  },
  {
    "LONG": -45.59,
    "LAT": -21.89,
    "CIDADE": "São Gonçalo do Sapucaí",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.87,
    "FEB": 5.43,
    "MAR": 4.98,
    "APR": 5.21,
    "MAY": 4.79,
    "JUN": 4.7,
    "JUL": 4.89,
    "AUG": 5.65,
    "SEP": 5.34,
    "OCT": 5.29,
    "NOV": 4.79,
    "DEC": 4.97
  },
  {
    "LONG": "#VALOR!",
    "LAT": -21.85,
    "CIDADE": "Cambuquira",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.86,
    "FEB": 5.42,
    "MAR": 5.02,
    "APR": 5.2,
    "MAY": 4.8,
    "JUN": 4.69,
    "JUL": 4.92,
    "AUG": 5.67,
    "SEP": 5.35,
    "OCT": 5.27,
    "NOV": 4.8,
    "DEC": 4.98
  },
  {
    "LONG": -45.09,
    "LAT": -21.88,
    "CIDADE": "Conceição do Rio Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.9,
    "FEB": 5.49,
    "MAR": 5.13,
    "APR": 5.23,
    "MAY": 4.78,
    "JUN": 4.69,
    "JUL": 4.89,
    "AUG": 5.6,
    "SEP": 5.36,
    "OCT": 5.27,
    "NOV": 4.87,
    "DEC": 5.04
  },
  {
    "LONG": -44.51,
    "LAT": -21.89,
    "CIDADE": "Serranos",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 4.94,
    "FEB": 5.44,
    "MAR": 5.07,
    "APR": 5.08,
    "MAY": 4.62,
    "JUN": 4.54,
    "JUL": 4.74,
    "AUG": 5.51,
    "SEP": 5.22,
    "OCT": 5.1,
    "NOV": 4.71,
    "DEC": 4.94
  },
  {
    "LONG": -44.52,
    "LAT": -21.91,
    "CIDADE": "Seritinga",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 4.94,
    "FEB": 5.44,
    "MAR": 5.07,
    "APR": 5.08,
    "MAY": 4.62,
    "JUN": 4.54,
    "JUL": 4.74,
    "AUG": 5.51,
    "SEP": 5.22,
    "OCT": 5.1,
    "NOV": 4.71,
    "DEC": 4.94
  },
  {
    "LONG": -44.26,
    "LAT": -21.91,
    "CIDADE": "Arantina",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 4.91,
    "FEB": 5.5,
    "MAR": 5.02,
    "APR": 4.93,
    "MAY": 4.54,
    "JUN": 4.53,
    "JUL": 4.61,
    "AUG": 5.45,
    "SEP": 5.13,
    "OCT": 4.92,
    "NOV": 4.62,
    "DEC": 4.88
  },
  {
    "LONG": -44.19,
    "LAT": -21.95,
    "CIDADE": "Bom Jardim de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 4.89,
    "FEB": 5.46,
    "MAR": 5,
    "APR": 4.88,
    "MAY": 4.52,
    "JUN": 4.5,
    "JUL": 4.6,
    "AUG": 5.42,
    "SEP": 5.12,
    "OCT": 4.88,
    "NOV": 4.57,
    "DEC": 4.83
  },
  {
    "LONG": -43.94,
    "LAT": -21.86,
    "CIDADE": "Olaria",
    "UF": "MINAS GERAIS",
    "anual": 4.79,
    "JAN": 4.85,
    "FEB": 5.44,
    "MAR": 4.9,
    "APR": 4.8,
    "MAY": 4.41,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 5.28,
    "SEP": 4.99,
    "OCT": 4.71,
    "NOV": 4.4,
    "DEC": 4.79
  },
  {
    "LONG": -43.41,
    "LAT": -21.94,
    "CIDADE": "Belmiro Braga",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.92,
    "FEB": 5.52,
    "MAR": 4.96,
    "APR": 4.72,
    "MAY": 4.29,
    "JUN": 4.26,
    "JUL": 4.37,
    "AUG": 5.02,
    "SEP": 4.81,
    "OCT": 4.71,
    "NOV": 4.38,
    "DEC": 4.84
  },
  {
    "LONG": -43.31,
    "LAT": -21.87,
    "CIDADE": "Matias Barbosa",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 5.03,
    "FEB": 5.59,
    "MAR": 4.99,
    "APR": 4.76,
    "MAY": 4.28,
    "JUN": 4.27,
    "JUL": 4.41,
    "AUG": 5,
    "SEP": 4.85,
    "OCT": 4.76,
    "NOV": 4.4,
    "DEC": 4.87
  },
  {
    "LONG": -43.01,
    "LAT": -21.87,
    "CIDADE": "Mar de Espanha",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5.17,
    "FEB": 5.66,
    "MAR": 5.11,
    "APR": 4.83,
    "MAY": 4.31,
    "JUN": 4.23,
    "JUL": 4.42,
    "AUG": 4.97,
    "SEP": 4.89,
    "OCT": 4.82,
    "NOV": 4.46,
    "DEC": 4.96
  },
  {
    "LONG": -4.27,
    "LAT": -21.88,
    "CIDADE": "Além Paraíba",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.25,
    "FEB": 5.81,
    "MAR": 5.28,
    "APR": 5.05,
    "MAY": 4.41,
    "JUN": 4.3,
    "JUL": 4.44,
    "AUG": 5.05,
    "SEP": 4.95,
    "OCT": 4.82,
    "NOV": 4.51,
    "DEC": 5.07
  },
  {
    "LONG": -46.57,
    "LAT": -21.78,
    "CIDADE": "Poços de Caldas",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.77,
    "FEB": 5.27,
    "MAR": 4.88,
    "APR": 5.14,
    "MAY": 4.81,
    "JUN": 4.74,
    "JUL": 5,
    "AUG": 5.78,
    "SEP": 5.35,
    "OCT": 5.19,
    "NOV": 4.84,
    "DEC": 4.93
  },
  {
    "LONG": -45.97,
    "LAT": -21.78,
    "CIDADE": "Poço Fundo",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.84,
    "FEB": 5.4,
    "MAR": 4.95,
    "APR": 5.13,
    "MAY": 4.82,
    "JUN": 4.7,
    "JUL": 4.92,
    "AUG": 5.74,
    "SEP": 5.35,
    "OCT": 5.18,
    "NOV": 4.76,
    "DEC": 4.98
  },
  {
    "LONG": -45.84,
    "LAT": -2.18,
    "CIDADE": "Carvalhópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.91,
    "FEB": 5.45,
    "MAR": 4.98,
    "APR": 5.18,
    "MAY": 4.84,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.72,
    "SEP": 5.35,
    "OCT": 5.22,
    "NOV": 4.81,
    "DEC": 5
  },
  {
    "LONG": -45.7,
    "LAT": -21.79,
    "CIDADE": "Cordislândia",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 4.93,
    "FEB": 5.46,
    "MAR": 5.04,
    "APR": 5.2,
    "MAY": 4.84,
    "JUN": 4.73,
    "JUL": 4.95,
    "AUG": 5.7,
    "SEP": 5.37,
    "OCT": 5.25,
    "NOV": 4.83,
    "DEC": 5.01
  },
  {
    "LONG": -45.54,
    "LAT": -21.76,
    "CIDADE": "Monsenhor Paulo",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.9,
    "FEB": 5.46,
    "MAR": 5.02,
    "APR": 5.21,
    "MAY": 4.81,
    "JUN": 4.71,
    "JUL": 4.91,
    "AUG": 5.63,
    "SEP": 5.35,
    "OCT": 5.26,
    "NOV": 4.81,
    "DEC": 5.01
  },
  {
    "LONG": -45.4,
    "LAT": -21.84,
    "CIDADE": "Campanha",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.89,
    "FEB": 5.47,
    "MAR": 5.08,
    "APR": 5.21,
    "MAY": 4.82,
    "JUN": 4.7,
    "JUL": 4.9,
    "AUG": 5.65,
    "SEP": 5.37,
    "OCT": 5.27,
    "NOV": 4.83,
    "DEC": 5.04
  },
  {
    "LONG": -44.81,
    "LAT": -21.84,
    "CIDADE": "Cruzília",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.88,
    "FEB": 5.45,
    "MAR": 5.09,
    "APR": 5.15,
    "MAY": 4.72,
    "JUN": 4.62,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.39,
    "OCT": 5.21,
    "NOV": 4.75,
    "DEC": 4.95
  },
  {
    "LONG": -43.79,
    "LAT": -21.84,
    "CIDADE": "Lima Duarte",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.85,
    "FEB": 5.44,
    "MAR": 4.92,
    "APR": 4.71,
    "MAY": 4.33,
    "JUN": 4.26,
    "JUL": 4.42,
    "AUG": 5.15,
    "SEP": 4.88,
    "OCT": 4.69,
    "NOV": 4.34,
    "DEC": 4.84
  },
  {
    "LONG": -43.34,
    "LAT": "#VALOR!",
    "CIDADE": "Juiz de Fora",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.99,
    "FEB": 5.55,
    "MAR": 4.92,
    "APR": 4.67,
    "MAY": 4.25,
    "JUN": 4.23,
    "JUL": 4.38,
    "AUG": 4.99,
    "SEP": 4.85,
    "OCT": 4.72,
    "NOV": 4.36,
    "DEC": 4.84
  },
  {
    "LONG": -43.11,
    "LAT": -21.83,
    "CIDADE": "Pequeri",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.04,
    "APR": 4.74,
    "MAY": 4.26,
    "JUN": 4.22,
    "JUL": 4.41,
    "AUG": 4.96,
    "SEP": 4.88,
    "OCT": 4.75,
    "NOV": 4.36,
    "DEC": 4.85
  },
  {
    "LONG": -42.94,
    "LAT": -2.18,
    "CIDADE": "Senador Cortes",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5.17,
    "FEB": 5.66,
    "MAR": 5.11,
    "APR": 4.81,
    "MAY": 4.32,
    "JUN": 4.21,
    "JUL": 4.41,
    "AUG": 4.98,
    "SEP": 4.93,
    "OCT": 4.83,
    "NOV": 4.41,
    "DEC": 4.96
  },
  {
    "LONG": -42.81,
    "LAT": -21.76,
    "CIDADE": "Santo Antônio do Aventureiro",
    "UF": "MINAS GERAIS",
    "anual": 4.76,
    "JAN": 5.08,
    "FEB": 5.63,
    "MAR": 5.03,
    "APR": 4.78,
    "MAY": 4.26,
    "JUN": 4.16,
    "JUL": 4.35,
    "AUG": 4.91,
    "SEP": 4.88,
    "OCT": 4.75,
    "NOV": 4.32,
    "DEC": 4.92
  },
  {
    "LONG": -42.54,
    "LAT": -21.77,
    "CIDADE": "Volta Grande",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.31,
    "FEB": 5.81,
    "MAR": 5.29,
    "APR": 5.02,
    "MAY": 4.46,
    "JUN": 4.32,
    "JUL": 4.43,
    "AUG": 5.03,
    "SEP": 4.97,
    "OCT": 4.85,
    "NOV": 4.56,
    "DEC": 5.12
  },
  {
    "LONG": -46.38,
    "LAT": -21.73,
    "CIDADE": "Bandeira do Sul",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 4.85,
    "FEB": 5.38,
    "MAR": 5.01,
    "APR": 5.17,
    "MAY": 4.84,
    "JUN": 4.8,
    "JUL": 5.02,
    "AUG": 5.78,
    "SEP": 5.41,
    "OCT": 5.25,
    "NOV": 4.82,
    "DEC": 4.95
  },
  {
    "LONG": -46.24,
    "LAT": -21.71,
    "CIDADE": "Campestre",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.83,
    "FEB": 5.37,
    "MAR": 4.97,
    "APR": 5.15,
    "MAY": 4.83,
    "JUN": 4.72,
    "JUL": 4.99,
    "AUG": 5.79,
    "SEP": 5.41,
    "OCT": 5.23,
    "NOV": 4.8,
    "DEC": 4.95
  },
  {
    "LONG": -45.92,
    "LAT": -21.68,
    "CIDADE": "Machado",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.89,
    "FEB": 5.43,
    "MAR": 4.93,
    "APR": 5.15,
    "MAY": 4.84,
    "JUN": 4.74,
    "JUL": 4.96,
    "AUG": 5.76,
    "SEP": 5.37,
    "OCT": 5.18,
    "NOV": 4.77,
    "DEC": 4.94
  },
  {
    "LONG": -45.25,
    "LAT": -21.69,
    "CIDADE": "Três Corações",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.94,
    "FEB": 5.4,
    "MAR": 5.01,
    "APR": 5.23,
    "MAY": 4.77,
    "JUN": 4.66,
    "JUL": 4.9,
    "AUG": 5.63,
    "SEP": 5.39,
    "OCT": 5.27,
    "NOV": 4.79,
    "DEC": 5.02
  },
  {
    "LONG": -44.99,
    "LAT": -21.73,
    "CIDADE": "São Thomé das Letras",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.9,
    "FEB": 5.39,
    "MAR": 5.05,
    "APR": 5.15,
    "MAY": 4.76,
    "JUN": 4.66,
    "JUL": 4.91,
    "AUG": 5.63,
    "SEP": 5.39,
    "OCT": 5.24,
    "NOV": 4.79,
    "DEC": 4.95
  },
  {
    "LONG": -44.61,
    "LAT": -21.68,
    "CIDADE": "Minduri",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 4.96,
    "FEB": 5.48,
    "MAR": 5.04,
    "APR": 5.1,
    "MAY": 4.66,
    "JUN": 4.6,
    "JUL": 4.82,
    "AUG": 5.61,
    "SEP": 5.33,
    "OCT": 5.15,
    "NOV": 4.76,
    "DEC": 4.94
  },
  {
    "LONG": -44.44,
    "LAT": -21.7,
    "CIDADE": "São Vicente de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 4.87,
    "FEB": 5.51,
    "MAR": 5.01,
    "APR": 5,
    "MAY": 4.54,
    "JUN": 4.55,
    "JUL": 4.71,
    "AUG": 5.53,
    "SEP": 5.22,
    "OCT": 5.05,
    "NOV": 4.72,
    "DEC": 4.92
  },
  {
    "LONG": -44.31,
    "LAT": -21.74,
    "CIDADE": "Andrelândia",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 4.91,
    "FEB": 5.56,
    "MAR": 5.07,
    "APR": 4.94,
    "MAY": 4.54,
    "JUN": 4.54,
    "JUL": 4.69,
    "AUG": 5.51,
    "SEP": 5.24,
    "OCT": 5.04,
    "NOV": 4.72,
    "DEC": 4.97
  },
  {
    "LONG": -43.74,
    "LAT": -21.71,
    "CIDADE": "Pedro Teixeira",
    "UF": "MINAS GERAIS",
    "anual": 4.75,
    "JAN": 4.88,
    "FEB": 5.45,
    "MAR": 4.91,
    "APR": 4.7,
    "MAY": 4.34,
    "JUN": 4.31,
    "JUL": 4.42,
    "AUG": 5.16,
    "SEP": 4.9,
    "OCT": 4.71,
    "NOV": 4.31,
    "DEC": 4.85
  },
  {
    "LONG": -43.22,
    "LAT": -21.67,
    "CIDADE": "Chácara",
    "UF": "MINAS GERAIS",
    "anual": 4.75,
    "JAN": 5.05,
    "FEB": 5.59,
    "MAR": 4.96,
    "APR": 4.71,
    "MAY": 4.26,
    "JUN": 4.23,
    "JUL": 4.39,
    "AUG": 4.99,
    "SEP": 4.89,
    "OCT": 4.76,
    "NOV": 4.35,
    "DEC": 4.87
  },
  {
    "LONG": -43.06,
    "LAT": -21.72,
    "CIDADE": "Bicas",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.19,
    "FEB": 5.72,
    "MAR": 5.16,
    "APR": 4.81,
    "MAY": 4.33,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 5.03,
    "SEP": 4.95,
    "OCT": 4.83,
    "NOV": 4.42,
    "DEC": 4.97
  },
  {
    "LONG": -43.03,
    "LAT": -21.73,
    "CIDADE": "Guarará",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.19,
    "FEB": 5.72,
    "MAR": 5.16,
    "APR": 4.81,
    "MAY": 4.33,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 5.03,
    "SEP": 4.95,
    "OCT": 4.83,
    "NOV": 4.42,
    "DEC": 4.97
  },
  {
    "LONG": -4.3,
    "LAT": -21.7,
    "CIDADE": "Maripá de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.19,
    "FEB": 5.71,
    "MAR": 5.13,
    "APR": 4.82,
    "MAY": 4.36,
    "JUN": 4.22,
    "JUL": 4.39,
    "AUG": 5.03,
    "SEP": 4.96,
    "OCT": 4.84,
    "NOV": 4.42,
    "DEC": 5.01
  },
  {
    "LONG": -42.46,
    "LAT": -21.74,
    "CIDADE": "Estrela Dalva",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.32,
    "FEB": 5.77,
    "MAR": 5.26,
    "APR": 4.99,
    "MAY": 4.42,
    "JUN": 4.33,
    "JUL": 4.41,
    "AUG": 5,
    "SEP": 5,
    "OCT": 4.88,
    "NOV": 4.57,
    "DEC": 5.11
  },
  {
    "LONG": -42.34,
    "LAT": -21.66,
    "CIDADE": "Pirapetinga",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.41,
    "FEB": 5.81,
    "MAR": 5.31,
    "APR": 5,
    "MAY": 4.49,
    "JUN": 4.37,
    "JUL": 4.46,
    "AUG": 5.04,
    "SEP": 5.07,
    "OCT": 4.94,
    "NOV": 4.6,
    "DEC": 5.18
  },
  {
    "LONG": -46.39,
    "LAT": -21.64,
    "CIDADE": "Botelhos",
    "UF": "MINAS GERAIS",
    "anual": 5.13,
    "JAN": 4.91,
    "FEB": 5.42,
    "MAR": 5.02,
    "APR": 5.18,
    "MAY": 4.89,
    "JUN": 4.83,
    "JUL": 5.04,
    "AUG": 5.79,
    "SEP": 5.43,
    "OCT": 5.27,
    "NOV": 4.84,
    "DEC": 4.96
  },
  {
    "LONG": -45.57,
    "LAT": -21.61,
    "CIDADE": "Elói Mendes",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 5,
    "FEB": 5.54,
    "MAR": 5.13,
    "APR": 5.24,
    "MAY": 4.86,
    "JUN": 4.75,
    "JUL": 5.02,
    "AUG": 5.72,
    "SEP": 5.45,
    "OCT": 5.28,
    "NOV": 4.86,
    "DEC": 5.05
  },
  {
    "LONG": -45.44,
    "LAT": -21.56,
    "CIDADE": "Varginha",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.95,
    "FEB": 5.46,
    "MAR": 5.05,
    "APR": 5.23,
    "MAY": 4.82,
    "JUN": 4.71,
    "JUL": 4.99,
    "AUG": 5.7,
    "SEP": 5.41,
    "OCT": 5.26,
    "NOV": 4.79,
    "DEC": 5.05
  },
  {
    "LONG": -45.07,
    "LAT": -21.58,
    "CIDADE": "São Bento Abade",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.93,
    "FEB": 5.38,
    "MAR": 4.94,
    "APR": 5.14,
    "MAY": 4.74,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.64,
    "SEP": 5.38,
    "OCT": 5.21,
    "NOV": 4.8,
    "DEC": 4.96
  },
  {
    "LONG": -44.11,
    "LAT": -21.6,
    "CIDADE": "Santana do Garambéu",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.01,
    "FEB": 5.56,
    "MAR": 5.04,
    "APR": 4.98,
    "MAY": 4.54,
    "JUN": 4.55,
    "JUL": 4.72,
    "AUG": 5.53,
    "SEP": 5.25,
    "OCT": 5.01,
    "NOV": 4.66,
    "DEC": 4.98
  },
  {
    "LONG": -43.92,
    "LAT": -21.57,
    "CIDADE": "Santa Rita de Ibitipoca",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 4.88,
    "FEB": 5.48,
    "MAR": 5,
    "APR": 4.85,
    "MAY": 4.53,
    "JUN": 4.52,
    "JUL": 4.65,
    "AUG": 5.43,
    "SEP": 5.14,
    "OCT": 4.94,
    "NOV": 4.51,
    "DEC": 4.92
  },
  {
    "LONG": -43.76,
    "LAT": -21.6,
    "CIDADE": "Bias Fortes",
    "UF": "MINAS GERAIS",
    "anual": 4.75,
    "JAN": 4.85,
    "FEB": 5.45,
    "MAR": 4.9,
    "APR": 4.71,
    "MAY": 4.39,
    "JUN": 4.35,
    "JUL": 4.48,
    "AUG": 5.16,
    "SEP": 4.94,
    "OCT": 4.71,
    "NOV": 4.27,
    "DEC": 4.83
  },
  {
    "LONG": -43.26,
    "LAT": -21.59,
    "CIDADE": "Coronel Pacheco",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5.07,
    "FEB": 5.68,
    "MAR": 5.1,
    "APR": 4.82,
    "MAY": 4.33,
    "JUN": 4.27,
    "JUL": 4.47,
    "AUG": 5.08,
    "SEP": 4.94,
    "OCT": 4.8,
    "NOV": 4.41,
    "DEC": 4.92
  },
  {
    "LONG": -43.02,
    "LAT": -21.63,
    "CIDADE": "Rochedo de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.21,
    "FEB": 5.74,
    "MAR": 5.18,
    "APR": 4.88,
    "MAY": 4.37,
    "JUN": 4.25,
    "JUL": 4.43,
    "AUG": 5.04,
    "SEP": 5,
    "OCT": 4.87,
    "NOV": 4.47,
    "DEC": 5.04
  },
  {
    "LONG": -42.83,
    "LAT": -21.61,
    "CIDADE": "Argirita",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.22,
    "FEB": 5.72,
    "MAR": 5.25,
    "APR": 4.89,
    "MAY": 4.4,
    "JUN": 4.3,
    "JUL": 4.44,
    "AUG": 5.09,
    "SEP": 5.03,
    "OCT": 4.91,
    "NOV": 4.47,
    "DEC": 5.07
  },
  {
    "LONG": -46.39,
    "LAT": -21.47,
    "CIDADE": "Cabo Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.89,
    "FEB": 5.39,
    "MAR": 4.94,
    "APR": 5.18,
    "MAY": 4.84,
    "JUN": 4.75,
    "JUL": 5.04,
    "AUG": 5.85,
    "SEP": 5.4,
    "OCT": 5.22,
    "NOV": 4.8,
    "DEC": 4.9
  },
  {
    "LONG": -46.19,
    "LAT": -21.51,
    "CIDADE": "Divisa Nova",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 5.06,
    "FEB": 5.53,
    "MAR": 5.08,
    "APR": 5.24,
    "MAY": 4.89,
    "JUN": 4.73,
    "JUL": 4.99,
    "AUG": 5.81,
    "SEP": 5.43,
    "OCT": 5.28,
    "NOV": 4.88,
    "DEC": 5.02
  },
  {
    "LONG": -46.04,
    "LAT": -21.54,
    "CIDADE": "Serrania",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.15,
    "FEB": 5.59,
    "MAR": 5.12,
    "APR": 5.26,
    "MAY": 4.89,
    "JUN": 4.69,
    "JUL": 4.99,
    "AUG": 5.77,
    "SEP": 5.44,
    "OCT": 5.32,
    "NOV": 4.92,
    "DEC": 5.07
  },
  {
    "LONG": -45.74,
    "LAT": -2.15,
    "CIDADE": "Paraguaçu",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.09,
    "FEB": 5.57,
    "MAR": 5.13,
    "APR": 5.31,
    "MAY": 4.89,
    "JUN": 4.73,
    "JUL": 4.99,
    "AUG": 5.73,
    "SEP": 5.44,
    "OCT": 5.3,
    "NOV": 4.93,
    "DEC": 5.1
  },
  {
    "LONG": -45.22,
    "LAT": -21.46,
    "CIDADE": "Carmo da Cachoeira",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.93,
    "FEB": 5.4,
    "MAR": 4.98,
    "APR": 5.14,
    "MAY": 4.76,
    "JUN": 4.76,
    "JUL": 5.03,
    "AUG": 5.76,
    "SEP": 5.42,
    "OCT": 5.23,
    "NOV": 4.76,
    "DEC": 4.99
  },
  {
    "LONG": -44.9,
    "LAT": -2.15,
    "CIDADE": "Luminárias",
    "UF": "MINAS GERAIS",
    "anual": 5.13,
    "JAN": 5.02,
    "FEB": 5.5,
    "MAR": 5.06,
    "APR": 5.17,
    "MAY": 4.78,
    "JUN": 4.72,
    "JUL": 4.99,
    "AUG": 5.73,
    "SEP": 5.41,
    "OCT": 5.25,
    "NOV": 4.84,
    "DEC": 5.04
  },
  {
    "LONG": -44.64,
    "LAT": -21.49,
    "CIDADE": "Carrancas",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.04,
    "FEB": 5.52,
    "MAR": 5.1,
    "APR": 5.11,
    "MAY": 4.75,
    "JUN": 4.64,
    "JUL": 4.9,
    "AUG": 5.69,
    "SEP": 5.42,
    "OCT": 5.23,
    "NOV": 4.78,
    "DEC": 5
  },
  {
    "LONG": -44.33,
    "LAT": -21.48,
    "CIDADE": "Madre de Deus de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.01,
    "FEB": 5.55,
    "MAR": 5.12,
    "APR": 5.01,
    "MAY": 4.58,
    "JUN": 4.54,
    "JUL": 4.73,
    "AUG": 5.55,
    "SEP": 5.31,
    "OCT": 5.1,
    "NOV": 4.7,
    "DEC": 5.03
  },
  {
    "LONG": -44.19,
    "LAT": -21.47,
    "CIDADE": "Piedade do Rio Grande",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.01,
    "FEB": 5.49,
    "MAR": 5.03,
    "APR": 4.96,
    "MAY": 4.6,
    "JUN": 4.57,
    "JUL": 4.71,
    "AUG": 5.55,
    "SEP": 5.25,
    "OCT": 5.02,
    "NOV": 4.61,
    "DEC": 4.99
  },
  {
    "LONG": -43.51,
    "LAT": -21.55,
    "CIDADE": "Ewbank da Câmara",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.82,
    "FEB": 5.52,
    "MAR": 4.85,
    "APR": 4.67,
    "MAY": 4.28,
    "JUN": 4.31,
    "JUL": 4.47,
    "AUG": 5.12,
    "SEP": 4.94,
    "OCT": 4.71,
    "NOV": 4.29,
    "DEC": 4.82
  },
  {
    "LONG": -43.55,
    "LAT": -21.46,
    "CIDADE": "Santos Dumont",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.82,
    "FEB": 5.52,
    "MAR": 4.85,
    "APR": 4.67,
    "MAY": 4.28,
    "JUN": 4.31,
    "JUL": 4.47,
    "AUG": 5.12,
    "SEP": 4.94,
    "OCT": 4.71,
    "NOV": 4.29,
    "DEC": 4.82
  },
  {
    "LONG": -43.31,
    "LAT": -21.51,
    "CIDADE": "Piau",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 4.92,
    "FEB": 5.61,
    "MAR": 5,
    "APR": 4.74,
    "MAY": 4.31,
    "JUN": 4.26,
    "JUL": 4.41,
    "AUG": 5.06,
    "SEP": 4.93,
    "OCT": 4.74,
    "NOV": 4.32,
    "DEC": 4.89
  },
  {
    "LONG": -43.12,
    "LAT": -21.47,
    "CIDADE": "Rio Novo",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.17,
    "FEB": 5.67,
    "MAR": 5.09,
    "APR": 4.83,
    "MAY": 4.37,
    "JUN": 4.34,
    "JUL": 4.49,
    "AUG": 5.13,
    "SEP": 5.01,
    "OCT": 4.86,
    "NOV": 4.45,
    "DEC": 4.99
  },
  {
    "LONG": -4.32,
    "LAT": -21.54,
    "CIDADE": "Goianá",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.17,
    "FEB": 5.67,
    "MAR": 5.09,
    "APR": 4.83,
    "MAY": 4.37,
    "JUN": 4.34,
    "JUL": 4.49,
    "AUG": 5.13,
    "SEP": 5.01,
    "OCT": 4.86,
    "NOV": 4.45,
    "DEC": 4.99
  },
  {
    "LONG": -43.01,
    "LAT": -21.54,
    "CIDADE": "São João Nepomuceno",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.14,
    "APR": 4.9,
    "MAY": 4.39,
    "JUN": 4.34,
    "JUL": 4.5,
    "AUG": 5.13,
    "SEP": 5.04,
    "OCT": 4.89,
    "NOV": 4.5,
    "DEC": 5.01
  },
  {
    "LONG": -42.96,
    "LAT": -21.46,
    "CIDADE": "Descoberto",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.29,
    "FEB": 5.74,
    "MAR": 5.17,
    "APR": 4.92,
    "MAY": 4.4,
    "JUN": 4.35,
    "JUL": 4.52,
    "AUG": 5.14,
    "SEP": 5.06,
    "OCT": 4.9,
    "NOV": 4.5,
    "DEC": 5.02
  },
  {
    "LONG": -42.64,
    "LAT": -21.53,
    "CIDADE": "Leopoldina",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.36,
    "FEB": 5.91,
    "MAR": 5.38,
    "APR": 5,
    "MAY": 4.52,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.17,
    "SEP": 5.07,
    "OCT": 5,
    "NOV": 4.62,
    "DEC": 5.22
  },
  {
    "LONG": -4.25,
    "LAT": -21.53,
    "CIDADE": "Recreio",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.41,
    "FEB": 5.93,
    "MAR": 5.38,
    "APR": 5.02,
    "MAY": 4.51,
    "JUN": 4.42,
    "JUL": 4.52,
    "AUG": 5.15,
    "SEP": 5.11,
    "OCT": 5.02,
    "NOV": 4.66,
    "DEC": 5.28
  },
  {
    "LONG": -46.94,
    "LAT": -21.36,
    "CIDADE": "Arceburgo",
    "UF": "MINAS GERAIS",
    "anual": 5.27,
    "JAN": 5.02,
    "FEB": 5.51,
    "MAR": 5.21,
    "APR": 5.5,
    "MAY": 5,
    "JUN": 4.93,
    "JUL": 5.11,
    "AUG": 5.84,
    "SEP": 5.37,
    "OCT": 5.37,
    "NOV": 5.17,
    "DEC": 5.17
  },
  {
    "LONG": -46.52,
    "LAT": -21.37,
    "CIDADE": "Muzambinho",
    "UF": "MINAS GERAIS",
    "anual": 5.13,
    "JAN": 4.92,
    "FEB": 5.36,
    "MAR": 4.96,
    "APR": 5.23,
    "MAY": 4.87,
    "JUN": 4.8,
    "JUL": 5.09,
    "AUG": 5.83,
    "SEP": 5.43,
    "OCT": 5.25,
    "NOV": 4.89,
    "DEC": 4.93
  },
  {
    "LONG": -46.14,
    "LAT": -21.36,
    "CIDADE": "Areado",
    "UF": "MINAS GERAIS",
    "anual": 5.15,
    "JAN": 5.04,
    "FEB": 5.52,
    "MAR": 5.07,
    "APR": 5.23,
    "MAY": 4.84,
    "JUN": 4.7,
    "JUL": 5,
    "AUG": 5.83,
    "SEP": 5.41,
    "OCT": 5.28,
    "NOV": 4.84,
    "DEC": 5
  },
  {
    "LONG": -45.95,
    "LAT": -21.43,
    "CIDADE": "Alfenas",
    "UF": "MINAS GERAIS",
    "anual": 5.17,
    "JAN": 5.15,
    "FEB": 5.61,
    "MAR": 5.14,
    "APR": 5.26,
    "MAY": 4.84,
    "JUN": 4.65,
    "JUL": 4.97,
    "AUG": 5.71,
    "SEP": 5.42,
    "OCT": 5.33,
    "NOV": 4.92,
    "DEC": 5.09
  },
  {
    "LONG": -4.58,
    "LAT": -21.41,
    "CIDADE": "Fama",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.11,
    "FEB": 5.61,
    "MAR": 5.16,
    "APR": 5.27,
    "MAY": 4.87,
    "JUN": 4.7,
    "JUL": 5.01,
    "AUG": 5.68,
    "SEP": 5.41,
    "OCT": 5.31,
    "NOV": 4.91,
    "DEC": 5.13
  },
  {
    "LONG": -45.51,
    "LAT": -21.37,
    "CIDADE": "Três Pontas",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.03,
    "FEB": 5.48,
    "MAR": 5.01,
    "APR": 5.18,
    "MAY": 4.87,
    "JUN": 4.79,
    "JUL": 5.05,
    "AUG": 5.8,
    "SEP": 5.45,
    "OCT": 5.27,
    "NOV": 4.78,
    "DEC": 4.97
  },
  {
    "LONG": -44.92,
    "LAT": -21.4,
    "CIDADE": "Ingaí",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.02,
    "FEB": 5.49,
    "MAR": 5.08,
    "APR": 5.23,
    "MAY": 4.79,
    "JUN": 4.76,
    "JUL": 5,
    "AUG": 5.75,
    "SEP": 5.44,
    "OCT": 5.27,
    "NOV": 4.83,
    "DEC": 5.03
  },
  {
    "LONG": -43.96,
    "LAT": -21.43,
    "CIDADE": "Ibertioga",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 4.98,
    "FEB": 5.53,
    "MAR": 4.99,
    "APR": 4.9,
    "MAY": 4.56,
    "JUN": 4.54,
    "JUL": 4.69,
    "AUG": 5.47,
    "SEP": 5.22,
    "OCT": 4.98,
    "NOV": 4.57,
    "DEC": 4.96
  },
  {
    "LONG": -43.24,
    "LAT": -21.36,
    "CIDADE": "Tabuleiro",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5,
    "FEB": 5.64,
    "MAR": 5.02,
    "APR": 4.74,
    "MAY": 4.35,
    "JUN": 4.32,
    "JUL": 4.45,
    "AUG": 5.12,
    "SEP": 4.99,
    "OCT": 4.85,
    "NOV": 4.38,
    "DEC": 4.99
  },
  {
    "LONG": -43.03,
    "LAT": -21.36,
    "CIDADE": "Guarani",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.22,
    "FEB": 5.73,
    "MAR": 5.13,
    "APR": 4.9,
    "MAY": 4.42,
    "JUN": 4.38,
    "JUL": 4.51,
    "AUG": 5.16,
    "SEP": 5.07,
    "OCT": 4.92,
    "NOV": 4.49,
    "DEC": 5.02
  },
  {
    "LONG": -42.81,
    "LAT": -21.42,
    "CIDADE": "Itamarati de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.26,
    "FEB": 5.77,
    "MAR": 5.22,
    "APR": 4.86,
    "MAY": 4.4,
    "JUN": 4.35,
    "JUL": 4.51,
    "AUG": 5.14,
    "SEP": 5.05,
    "OCT": 4.94,
    "NOV": 4.52,
    "DEC": 5.05
  },
  {
    "LONG": "#VALOR!",
    "LAT": -21.39,
    "CIDADE": "Cataguases",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.35,
    "FEB": 5.85,
    "MAR": 5.29,
    "APR": 4.98,
    "MAY": 4.49,
    "JUN": 4.42,
    "JUL": 4.53,
    "AUG": 5.19,
    "SEP": 5.1,
    "OCT": 4.98,
    "NOV": 4.6,
    "DEC": 5.19
  },
  {
    "LONG": -42.47,
    "LAT": -2.14,
    "CIDADE": "Laranjal",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.38,
    "FEB": 5.88,
    "MAR": 5.31,
    "APR": 5,
    "MAY": 4.5,
    "JUN": 4.4,
    "JUL": 4.51,
    "AUG": 5.15,
    "SEP": 5.1,
    "OCT": 4.99,
    "NOV": 4.57,
    "DEC": 5.21
  },
  {
    "LONG": -42.31,
    "LAT": -21.38,
    "CIDADE": "Palma",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.38,
    "FEB": 5.84,
    "MAR": 5.25,
    "APR": 4.97,
    "MAY": 4.48,
    "JUN": 4.38,
    "JUL": 4.48,
    "AUG": 5.16,
    "SEP": 5.11,
    "OCT": 5,
    "NOV": 4.54,
    "DEC": 5.2
  },
  {
    "LONG": -46.71,
    "LAT": -21.31,
    "CIDADE": "Guaxupé",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 4.96,
    "FEB": 5.39,
    "MAR": 5.02,
    "APR": 5.37,
    "MAY": 4.98,
    "JUN": 4.94,
    "JUL": 5.14,
    "AUG": 5.91,
    "SEP": 5.44,
    "OCT": 5.29,
    "NOV": 4.94,
    "DEC": 4.98
  },
  {
    "LONG": -46.8,
    "LAT": -21.3,
    "CIDADE": "Guaranésia",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 4.96,
    "FEB": 5.39,
    "MAR": 5.02,
    "APR": 5.37,
    "MAY": 4.98,
    "JUN": 4.94,
    "JUL": 5.14,
    "AUG": 5.91,
    "SEP": 5.44,
    "OCT": 5.29,
    "NOV": 4.94,
    "DEC": 4.98
  },
  {
    "LONG": -46.36,
    "LAT": -21.33,
    "CIDADE": "Monte Belo",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 4.91,
    "FEB": 5.44,
    "MAR": 4.95,
    "APR": 5.18,
    "MAY": 4.81,
    "JUN": 4.71,
    "JUL": 5.02,
    "AUG": 5.88,
    "SEP": 5.43,
    "OCT": 5.26,
    "NOV": 4.81,
    "DEC": 4.92
  },
  {
    "LONG": -44.87,
    "LAT": -21.32,
    "CIDADE": "Itumirim",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.13,
    "FEB": 5.6,
    "MAR": 5.19,
    "APR": 5.25,
    "MAY": 4.78,
    "JUN": 4.7,
    "JUL": 4.98,
    "AUG": 5.78,
    "SEP": 5.46,
    "OCT": 5.29,
    "NOV": 4.84,
    "DEC": 5.1
  },
  {
    "LONG": -44.66,
    "LAT": -21.3,
    "CIDADE": "Itutinga",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.11,
    "FEB": 5.55,
    "MAR": 5.17,
    "APR": 5.2,
    "MAY": 4.76,
    "JUN": 4.64,
    "JUL": 4.86,
    "AUG": 5.7,
    "SEP": 5.44,
    "OCT": 5.25,
    "NOV": 4.78,
    "DEC": 5.03
  },
  {
    "LONG": -43.75,
    "LAT": -21.32,
    "CIDADE": "Antônio Carlos",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 4.87,
    "FEB": 5.46,
    "MAR": 4.92,
    "APR": 4.78,
    "MAY": 4.43,
    "JUN": 4.44,
    "JUL": 4.62,
    "AUG": 5.39,
    "SEP": 5.14,
    "OCT": 4.93,
    "NOV": 4.45,
    "DEC": 4.93
  },
  {
    "LONG": -43.41,
    "LAT": -21.29,
    "CIDADE": "Paiva",
    "UF": "MINAS GERAIS",
    "anual": 4.79,
    "JAN": 4.92,
    "FEB": 5.53,
    "MAR": 4.98,
    "APR": 4.73,
    "MAY": 4.35,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.19,
    "SEP": 4.99,
    "OCT": 4.78,
    "NOV": 4.31,
    "DEC": 4.86
  },
  {
    "LONG": -43.45,
    "LAT": -21.34,
    "CIDADE": "Oliveira Fortes",
    "UF": "MINAS GERAIS",
    "anual": 4.79,
    "JAN": 4.92,
    "FEB": 5.53,
    "MAR": 4.98,
    "APR": 4.73,
    "MAY": 4.35,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.19,
    "SEP": 4.99,
    "OCT": 4.78,
    "NOV": 4.31,
    "DEC": 4.86
  },
  {
    "LONG": -4.34,
    "LAT": -21.35,
    "CIDADE": "Aracitaba",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 4.99,
    "FEB": 5.58,
    "MAR": 5.01,
    "APR": 4.78,
    "MAY": 4.37,
    "JUN": 4.36,
    "JUL": 4.48,
    "AUG": 5.15,
    "SEP": 4.99,
    "OCT": 4.86,
    "NOV": 4.38,
    "DEC": 4.91
  },
  {
    "LONG": "#VALOR!",
    "LAT": -21.27,
    "CIDADE": "Rio Pomba",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.13,
    "FEB": 5.65,
    "MAR": 5.07,
    "APR": 4.84,
    "MAY": 4.41,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.17,
    "SEP": 5.08,
    "OCT": 4.9,
    "NOV": 4.46,
    "DEC": 4.94
  },
  {
    "LONG": -43.02,
    "LAT": -2.13,
    "CIDADE": "Piraúba",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.19,
    "FEB": 5.72,
    "MAR": 5.14,
    "APR": 4.9,
    "MAY": 4.44,
    "JUN": 4.41,
    "JUL": 4.56,
    "AUG": 5.21,
    "SEP": 5.11,
    "OCT": 4.96,
    "NOV": 4.52,
    "DEC": 5
  },
  {
    "LONG": -42.81,
    "LAT": -21.32,
    "CIDADE": "Dona Eusébia",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.3,
    "FEB": 5.83,
    "MAR": 5.29,
    "APR": 4.99,
    "MAY": 4.48,
    "JUN": 4.44,
    "JUL": 4.59,
    "AUG": 5.21,
    "SEP": 5.17,
    "OCT": 5.06,
    "NOV": 4.64,
    "DEC": 5.11
  },
  {
    "LONG": -42.86,
    "LAT": -21.32,
    "CIDADE": "Astolfo Dutra",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.3,
    "FEB": 5.83,
    "MAR": 5.29,
    "APR": 4.99,
    "MAY": 4.48,
    "JUN": 4.44,
    "JUL": 4.59,
    "AUG": 5.21,
    "SEP": 5.17,
    "OCT": 5.06,
    "NOV": 4.64,
    "DEC": 5.11
  },
  {
    "LONG": -42.55,
    "LAT": -21.29,
    "CIDADE": "Santana de Cataguases",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.33,
    "FEB": 5.82,
    "MAR": 5.18,
    "APR": 4.92,
    "MAY": 4.46,
    "JUN": 4.4,
    "JUL": 4.53,
    "AUG": 5.19,
    "SEP": 5.1,
    "OCT": 4.96,
    "NOV": 4.52,
    "DEC": 5.1
  },
  {
    "LONG": -46.98,
    "LAT": -21.19,
    "CIDADE": "Monte Santo de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 4.89,
    "FEB": 5.41,
    "MAR": 5.01,
    "APR": 5.33,
    "MAY": 4.99,
    "JUN": 4.93,
    "JUL": 5.15,
    "AUG": 5.91,
    "SEP": 5.42,
    "OCT": 5.27,
    "NOV": 5,
    "DEC": 5.02
  },
  {
    "LONG": -46.57,
    "LAT": -21.25,
    "CIDADE": "Juruaia",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 4.91,
    "FEB": 5.35,
    "MAR": 4.91,
    "APR": 5.19,
    "MAY": 4.86,
    "JUN": 4.78,
    "JUL": 5.09,
    "AUG": 5.91,
    "SEP": 5.42,
    "OCT": 5.22,
    "NOV": 4.82,
    "DEC": 4.89
  },
  {
    "LONG": -46.14,
    "LAT": -21.25,
    "CIDADE": "Alterosa",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 4.99,
    "FEB": 5.55,
    "MAR": 4.99,
    "APR": 5.2,
    "MAY": 4.84,
    "JUN": 4.76,
    "JUL": 5.05,
    "AUG": 5.9,
    "SEP": 5.45,
    "OCT": 5.28,
    "NOV": 4.8,
    "DEC": 4.92
  },
  {
    "LONG": -45.76,
    "LAT": -21.24,
    "CIDADE": "Campos Gerais",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.07,
    "FEB": 5.54,
    "MAR": 5.08,
    "APR": 5.25,
    "MAY": 4.91,
    "JUN": 4.84,
    "JUL": 5.12,
    "AUG": 5.83,
    "SEP": 5.48,
    "OCT": 5.33,
    "NOV": 4.85,
    "DEC": 5.02
  },
  {
    "LONG": -45.5,
    "LAT": -21.25,
    "CIDADE": "Santana da Vargem",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.05,
    "FEB": 5.56,
    "MAR": 5.08,
    "APR": 5.29,
    "MAY": 4.87,
    "JUN": 4.82,
    "JUL": 5.1,
    "AUG": 5.87,
    "SEP": 5.5,
    "OCT": 5.32,
    "NOV": 4.82,
    "DEC": 5
  },
  {
    "LONG": -4.54,
    "LAT": -21.19,
    "CIDADE": "Coqueiral",
    "UF": "MINAS GERAIS",
    "anual": 5.21,
    "JAN": 5.06,
    "FEB": 5.56,
    "MAR": 5.09,
    "APR": 5.33,
    "MAY": 4.93,
    "JUN": 4.82,
    "JUL": 5.11,
    "AUG": 5.85,
    "SEP": 5.52,
    "OCT": 5.35,
    "NOV": 4.83,
    "DEC": 5.07
  },
  {
    "LONG": -45.24,
    "LAT": -21.23,
    "CIDADE": "Nepomuceno",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.12,
    "FEB": 5.57,
    "MAR": 5.15,
    "APR": 5.36,
    "MAY": 4.91,
    "JUN": 4.81,
    "JUL": 5.08,
    "AUG": 5.87,
    "SEP": 5.51,
    "OCT": 5.34,
    "NOV": 4.89,
    "DEC": 5.07
  },
  {
    "LONG": -45.06,
    "LAT": -21.19,
    "CIDADE": "Ribeirão Vermelho",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.19,
    "APR": 5.34,
    "MAY": 4.89,
    "JUN": 4.81,
    "JUL": 5.07,
    "AUG": 5.83,
    "SEP": 5.54,
    "OCT": 5.31,
    "NOV": 4.83,
    "DEC": 5.08
  },
  {
    "LONG": -45,
    "LAT": -21.25,
    "CIDADE": "Lavras",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.19,
    "APR": 5.34,
    "MAY": 4.89,
    "JUN": 4.81,
    "JUL": 5.07,
    "AUG": 5.83,
    "SEP": 5.54,
    "OCT": 5.31,
    "NOV": 4.83,
    "DEC": 5.08
  },
  {
    "LONG": -44.92,
    "LAT": -21.17,
    "CIDADE": "Ijaci",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.07,
    "FEB": 5.53,
    "MAR": 5.19,
    "APR": 5.29,
    "MAY": 4.83,
    "JUN": 4.76,
    "JUL": 5.01,
    "AUG": 5.78,
    "SEP": 5.51,
    "OCT": 5.28,
    "NOV": 4.84,
    "DEC": 5.05
  },
  {
    "LONG": -44.75,
    "LAT": -21.15,
    "CIDADE": "Ibituruna",
    "UF": "MINAS GERAIS",
    "anual": 5.15,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.17,
    "APR": 5.25,
    "MAY": 4.8,
    "JUN": 4.72,
    "JUL": 4.95,
    "AUG": 5.79,
    "SEP": 5.47,
    "OCT": 5.26,
    "NOV": 4.79,
    "DEC": 5.02
  },
  {
    "LONG": -44.61,
    "LAT": -21.22,
    "CIDADE": "Nazareno",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 5.05,
    "FEB": 5.53,
    "MAR": 5.12,
    "APR": 5.2,
    "MAY": 4.74,
    "JUN": 4.65,
    "JUL": 4.87,
    "AUG": 5.74,
    "SEP": 5.46,
    "OCT": 5.2,
    "NOV": 4.74,
    "DEC": 4.98
  },
  {
    "LONG": -43.97,
    "LAT": -21.19,
    "CIDADE": "Barroso",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5,
    "FEB": 5.55,
    "MAR": 5.03,
    "APR": 4.91,
    "MAY": 4.56,
    "JUN": 4.58,
    "JUL": 4.73,
    "AUG": 5.54,
    "SEP": 5.24,
    "OCT": 5.04,
    "NOV": 4.58,
    "DEC": 4.96
  },
  {
    "LONG": -43.77,
    "LAT": -2.12,
    "CIDADE": "Alfredo Vasconcelos",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 4.94,
    "FEB": 5.51,
    "MAR": 4.96,
    "APR": 4.83,
    "MAY": 4.49,
    "JUN": 4.48,
    "JUL": 4.67,
    "AUG": 5.44,
    "SEP": 5.18,
    "OCT": 4.98,
    "NOV": 4.49,
    "DEC": 4.92
  },
  {
    "LONG": -43.77,
    "LAT": -21.22,
    "CIDADE": "Barbacena",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 4.94,
    "FEB": 5.51,
    "MAR": 4.96,
    "APR": 4.83,
    "MAY": 4.49,
    "JUN": 4.48,
    "JUL": 4.67,
    "AUG": 5.44,
    "SEP": 5.18,
    "OCT": 4.98,
    "NOV": 4.49,
    "DEC": 4.92
  },
  {
    "LONG": -43.56,
    "LAT": -21.24,
    "CIDADE": "Santa Bárbara do Tugúrio",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.81,
    "FEB": 5.39,
    "MAR": 4.82,
    "APR": 4.7,
    "MAY": 4.27,
    "JUN": 4.31,
    "JUL": 4.48,
    "AUG": 5.21,
    "SEP": 4.99,
    "OCT": 4.74,
    "NOV": 4.23,
    "DEC": 4.82
  },
  {
    "LONG": -4.33,
    "LAT": -21.2,
    "CIDADE": "Mercês",
    "UF": "MINAS GERAIS",
    "anual": 4.81,
    "JAN": 4.97,
    "FEB": 5.56,
    "MAR": 4.95,
    "APR": 4.76,
    "MAY": 4.36,
    "JUN": 4.38,
    "JUL": 4.47,
    "AUG": 5.16,
    "SEP": 5,
    "OCT": 4.8,
    "NOV": 4.36,
    "DEC": 4.89
  },
  {
    "LONG": -43.21,
    "LAT": -2.12,
    "CIDADE": "Silveirânia",
    "UF": "MINAS GERAIS",
    "anual": 4.8,
    "JAN": 4.98,
    "FEB": 5.56,
    "MAR": 4.95,
    "APR": 4.75,
    "MAY": 4.34,
    "JUN": 4.36,
    "JUL": 4.46,
    "AUG": 5.14,
    "SEP": 5.02,
    "OCT": 4.8,
    "NOV": 4.35,
    "DEC": 4.88
  },
  {
    "LONG": -43.01,
    "LAT": -21.18,
    "CIDADE": "Tocantins",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.12,
    "FEB": 5.68,
    "MAR": 5.07,
    "APR": 4.86,
    "MAY": 4.4,
    "JUN": 4.39,
    "JUL": 4.56,
    "AUG": 5.21,
    "SEP": 5.1,
    "OCT": 4.91,
    "NOV": 4.47,
    "DEC": 4.95
  },
  {
    "LONG": -4.29,
    "LAT": -21.2,
    "CIDADE": "Rodeiro",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.31,
    "FEB": 5.83,
    "MAR": 5.25,
    "APR": 4.99,
    "MAY": 4.5,
    "JUN": 4.46,
    "JUL": 4.62,
    "AUG": 5.25,
    "SEP": 5.19,
    "OCT": 5.06,
    "NOV": 4.63,
    "DEC": 5.1
  },
  {
    "LONG": -42.79,
    "LAT": -21.16,
    "CIDADE": "Guidoval",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.24,
    "FEB": 5.78,
    "MAR": 5.19,
    "APR": 4.98,
    "MAY": 4.49,
    "JUN": 4.46,
    "JUL": 4.58,
    "AUG": 5.25,
    "SEP": 5.22,
    "OCT": 5.05,
    "NOV": 4.61,
    "DEC": 5.1
  },
  {
    "LONG": -42.61,
    "LAT": -21.2,
    "CIDADE": "Miraí",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.16,
    "FEB": 5.69,
    "MAR": 5.07,
    "APR": 4.88,
    "MAY": 4.44,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.19,
    "SEP": 5.12,
    "OCT": 4.95,
    "NOV": 4.5,
    "DEC": 5.01
  },
  {
    "LONG": -42.21,
    "LAT": -21.15,
    "CIDADE": "Patrocínio do Muriaé",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 5.48,
    "FEB": 6.02,
    "MAR": 5.38,
    "APR": 5.11,
    "MAY": 4.64,
    "JUN": 4.55,
    "JUL": 4.6,
    "AUG": 5.27,
    "SEP": 5.24,
    "OCT": 5.09,
    "NOV": 4.67,
    "DEC": 5.28
  },
  {
    "LONG": -42.24,
    "LAT": -21.24,
    "CIDADE": "Barão de Monte Alto",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 5.48,
    "FEB": 6.02,
    "MAR": 5.38,
    "APR": 5.11,
    "MAY": 4.64,
    "JUN": 4.55,
    "JUL": 4.6,
    "AUG": 5.27,
    "SEP": 5.24,
    "OCT": 5.09,
    "NOV": 4.67,
    "DEC": 5.28
  },
  {
    "LONG": -47.05,
    "LAT": -21.08,
    "CIDADE": "Itamogi",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 4.99,
    "FEB": 5.43,
    "MAR": 5.08,
    "APR": 5.32,
    "MAY": 5.07,
    "JUN": 4.99,
    "JUL": 5.22,
    "AUG": 5.91,
    "SEP": 5.42,
    "OCT": 5.36,
    "NOV": 5.08,
    "DEC": 5.1
  },
  {
    "LONG": -46.61,
    "LAT": -21.13,
    "CIDADE": "São Pedro da União",
    "UF": "MINAS GERAIS",
    "anual": 5.17,
    "JAN": 4.89,
    "FEB": 5.44,
    "MAR": 4.99,
    "APR": 5.21,
    "MAY": 4.93,
    "JUN": 4.9,
    "JUL": 5.14,
    "AUG": 5.92,
    "SEP": 5.45,
    "OCT": 5.3,
    "NOV": 4.89,
    "DEC": 4.92
  },
  {
    "LONG": -46.42,
    "LAT": -21.13,
    "CIDADE": "Nova Resende",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 4.92,
    "FEB": 5.38,
    "MAR": 4.91,
    "APR": 5.15,
    "MAY": 4.9,
    "JUN": 4.87,
    "JUL": 5.13,
    "AUG": 5.95,
    "SEP": 5.48,
    "OCT": 5.28,
    "NOV": 4.83,
    "DEC": 4.92
  },
  {
    "LONG": -46.21,
    "LAT": -21.1,
    "CIDADE": "Conceição da Aparecida",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.93,
    "FEB": 5.48,
    "MAR": 4.91,
    "APR": 5.15,
    "MAY": 4.84,
    "JUN": 4.78,
    "JUL": 5.1,
    "AUG": 5.92,
    "SEP": 5.46,
    "OCT": 5.24,
    "NOV": 4.76,
    "DEC": 4.88
  },
  {
    "LONG": -45.83,
    "LAT": -21.11,
    "CIDADE": "Campo do Meio",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 5.07,
    "FEB": 5.61,
    "MAR": 5.1,
    "APR": 5.34,
    "MAY": 4.92,
    "JUN": 4.77,
    "JUL": 5.09,
    "AUG": 5.71,
    "SEP": 5.45,
    "OCT": 5.36,
    "NOV": 4.92,
    "DEC": 5.1
  },
  {
    "LONG": -45.56,
    "LAT": -21.09,
    "CIDADE": "Boa Esperança",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 4.99,
    "FEB": 5.58,
    "MAR": 5.09,
    "APR": 5.3,
    "MAY": 4.94,
    "JUN": 4.85,
    "JUL": 5.13,
    "AUG": 5.87,
    "SEP": 5.48,
    "OCT": 5.32,
    "NOV": 4.83,
    "DEC": 5
  },
  {
    "LONG": "#VALOR!",
    "LAT": -21.09,
    "CIDADE": "Perdões",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.13,
    "APR": 5.35,
    "MAY": 4.88,
    "JUN": 4.8,
    "JUL": 5.08,
    "AUG": 5.82,
    "SEP": 5.52,
    "OCT": 5.28,
    "NOV": 4.76,
    "DEC": 5.02
  },
  {
    "LONG": -44.47,
    "LAT": -2.11,
    "CIDADE": "Conceição da Barra de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.97,
    "FEB": 5.55,
    "MAR": 5.1,
    "APR": 5.14,
    "MAY": 4.72,
    "JUN": 4.63,
    "JUL": 4.87,
    "AUG": 5.72,
    "SEP": 5.43,
    "OCT": 5.16,
    "NOV": 4.68,
    "DEC": 4.95
  },
  {
    "LONG": -44.22,
    "LAT": -21.12,
    "CIDADE": "Santa Cruz de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.02,
    "FEB": 5.51,
    "MAR": 5.05,
    "APR": 5,
    "MAY": 4.62,
    "JUN": 4.58,
    "JUL": 4.83,
    "AUG": 5.65,
    "SEP": 5.36,
    "OCT": 5.12,
    "NOV": 4.62,
    "DEC": 4.96
  },
  {
    "LONG": -4.43,
    "LAT": -21.13,
    "CIDADE": "São João del Rei",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.02,
    "FEB": 5.51,
    "MAR": 5.05,
    "APR": 5,
    "MAY": 4.62,
    "JUN": 4.58,
    "JUL": 4.83,
    "AUG": 5.65,
    "SEP": 5.36,
    "OCT": 5.12,
    "NOV": 4.62,
    "DEC": 4.96
  },
  {
    "LONG": -44.17,
    "LAT": -21.11,
    "CIDADE": "Tiradentes",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.01,
    "FEB": 5.53,
    "MAR": 5,
    "APR": 4.97,
    "MAY": 4.61,
    "JUN": 4.59,
    "JUL": 4.81,
    "AUG": 5.62,
    "SEP": 5.33,
    "OCT": 5.1,
    "NOV": 4.61,
    "DEC": 4.96
  },
  {
    "LONG": -44.02,
    "LAT": -21.11,
    "CIDADE": "Dores de Campos",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 4.99,
    "APR": 4.95,
    "MAY": 4.59,
    "JUN": 4.56,
    "JUL": 4.79,
    "AUG": 5.6,
    "SEP": 5.28,
    "OCT": 5.05,
    "NOV": 4.59,
    "DEC": 4.91
  },
  {
    "LONG": -44.08,
    "LAT": -21.06,
    "CIDADE": "Prados",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 4.99,
    "APR": 4.95,
    "MAY": 4.59,
    "JUN": 4.56,
    "JUL": 4.79,
    "AUG": 5.6,
    "SEP": 5.28,
    "OCT": 5.05,
    "NOV": 4.59,
    "DEC": 4.91
  },
  {
    "LONG": -43.76,
    "LAT": -21.06,
    "CIDADE": "Ressaquinha",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.02,
    "FEB": 5.57,
    "MAR": 4.97,
    "APR": 4.87,
    "MAY": 4.52,
    "JUN": 4.51,
    "JUL": 4.74,
    "AUG": 5.52,
    "SEP": 5.21,
    "OCT": 5.03,
    "NOV": 4.51,
    "DEC": 4.94
  },
  {
    "LONG": -43.52,
    "LAT": -21.14,
    "CIDADE": "Desterro do Melo",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 4.84,
    "FEB": 5.47,
    "MAR": 4.87,
    "APR": 4.71,
    "MAY": 4.33,
    "JUN": 4.29,
    "JUL": 4.47,
    "AUG": 5.24,
    "SEP": 5.06,
    "OCT": 4.82,
    "NOV": 4.28,
    "DEC": 4.88
  },
  {
    "LONG": -42.94,
    "LAT": -21.12,
    "CIDADE": "Ubá",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.1,
    "FEB": 5.73,
    "MAR": 5.09,
    "APR": 4.86,
    "MAY": 4.45,
    "JUN": 4.44,
    "JUL": 4.57,
    "AUG": 5.26,
    "SEP": 5.12,
    "OCT": 4.95,
    "NOV": 4.47,
    "DEC": 4.98
  },
  {
    "LONG": -4.26,
    "LAT": -21.07,
    "CIDADE": "São Sebastião da Vargem Alegre",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.18,
    "FEB": 5.75,
    "MAR": 5.16,
    "APR": 4.91,
    "MAY": 4.5,
    "JUN": 4.47,
    "JUL": 4.61,
    "AUG": 5.27,
    "SEP": 5.24,
    "OCT": 5.02,
    "NOV": 4.57,
    "DEC": 5.08
  },
  {
    "LONG": -42.37,
    "LAT": -21.13,
    "CIDADE": "Muriaé",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.39,
    "FEB": 5.96,
    "MAR": 5.32,
    "APR": 5.03,
    "MAY": 4.6,
    "JUN": 4.56,
    "JUL": 4.65,
    "AUG": 5.33,
    "SEP": 5.22,
    "OCT": 5.04,
    "NOV": 4.5,
    "DEC": 5.17
  },
  {
    "LONG": -42.19,
    "LAT": -21.1,
    "CIDADE": "Eugenópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.41,
    "FEB": 5.93,
    "MAR": 5.26,
    "APR": 5.08,
    "MAY": 4.61,
    "JUN": 4.53,
    "JUL": 4.62,
    "AUG": 5.28,
    "SEP": 5.21,
    "OCT": 5.02,
    "NOV": 4.58,
    "DEC": 5.18
  },
  {
    "LONG": -46.74,
    "LAT": -21.01,
    "CIDADE": "Jacuí",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5,
    "FEB": 5.46,
    "MAR": 5.12,
    "APR": 5.31,
    "MAY": 5,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 5.91,
    "SEP": 5.47,
    "OCT": 5.35,
    "NOV": 4.96,
    "DEC": 4.99
  },
  {
    "LONG": -46.52,
    "LAT": -21.02,
    "CIDADE": "Bom Jesus da Penha",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 5.08,
    "FEB": 5.51,
    "MAR": 5.09,
    "APR": 5.3,
    "MAY": 4.98,
    "JUN": 4.94,
    "JUL": 5.16,
    "AUG": 5.96,
    "SEP": 5.52,
    "OCT": 5.36,
    "NOV": 4.9,
    "DEC": 5.02
  },
  {
    "LONG": -46.12,
    "LAT": -20.97,
    "CIDADE": "Carmo do Rio Claro",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 5.09,
    "FEB": 5.58,
    "MAR": 5.03,
    "APR": 5.28,
    "MAY": 4.94,
    "JUN": 4.81,
    "JUL": 5.11,
    "AUG": 5.86,
    "SEP": 5.49,
    "OCT": 5.34,
    "NOV": 4.91,
    "DEC": 5.01
  },
  {
    "LONG": -45.18,
    "LAT": -21.02,
    "CIDADE": "Cana Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 5.06,
    "FEB": 5.54,
    "MAR": 5.06,
    "APR": 5.35,
    "MAY": 4.91,
    "JUN": 4.88,
    "JUL": 5.09,
    "AUG": 5.89,
    "SEP": 5.56,
    "OCT": 5.28,
    "NOV": 4.76,
    "DEC": 4.99
  },
  {
    "LONG": -44.75,
    "LAT": -21.03,
    "CIDADE": "Bom Sucesso",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.93,
    "FEB": 5.48,
    "MAR": 5.02,
    "APR": 5.2,
    "MAY": 4.8,
    "JUN": 4.8,
    "JUL": 5.03,
    "AUG": 5.81,
    "SEP": 5.52,
    "OCT": 5.2,
    "NOV": 4.72,
    "DEC": 4.95
  },
  {
    "LONG": -44.32,
    "LAT": -2.1,
    "CIDADE": "Ritápolis",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.01,
    "FEB": 5.5,
    "MAR": 4.98,
    "APR": 5.04,
    "MAY": 4.68,
    "JUN": 4.7,
    "JUL": 4.93,
    "AUG": 5.74,
    "SEP": 5.43,
    "OCT": 5.11,
    "NOV": 4.6,
    "DEC": 4.91
  },
  {
    "LONG": -4.42,
    "LAT": -21.03,
    "CIDADE": "Coronel Xavier Chaves",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.05,
    "FEB": 5.55,
    "MAR": 4.99,
    "APR": 4.98,
    "MAY": 4.63,
    "JUN": 4.61,
    "JUL": 4.86,
    "AUG": 5.71,
    "SEP": 5.39,
    "OCT": 5.08,
    "NOV": 4.58,
    "DEC": 4.92
  },
  {
    "LONG": -43.81,
    "LAT": -20.96,
    "CIDADE": "Carandaí",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.05,
    "FEB": 5.62,
    "MAR": 5.04,
    "APR": 4.95,
    "MAY": 4.55,
    "JUN": 4.58,
    "JUL": 4.82,
    "AUG": 5.61,
    "SEP": 5.32,
    "OCT": 5.11,
    "NOV": 4.59,
    "DEC": 4.97
  },
  {
    "LONG": -43.58,
    "LAT": -21.04,
    "CIDADE": "Senhora dos Remédios",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.07,
    "FEB": 5.59,
    "MAR": 4.98,
    "APR": 4.79,
    "MAY": 4.4,
    "JUN": 4.39,
    "JUL": 4.57,
    "AUG": 5.31,
    "SEP": 5.14,
    "OCT": 4.92,
    "NOV": 4.39,
    "DEC": 4.94
  },
  {
    "LONG": -43.41,
    "LAT": -21.03,
    "CIDADE": "Alto Rio Doce",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5,
    "FEB": 5.59,
    "MAR": 5.03,
    "APR": 4.79,
    "MAY": 4.4,
    "JUN": 4.38,
    "JUL": 4.54,
    "AUG": 5.31,
    "SEP": 5.12,
    "OCT": 4.9,
    "NOV": 4.38,
    "DEC": 4.93
  },
  {
    "LONG": -43.18,
    "LAT": -2.1,
    "CIDADE": "Dores do Turvo",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 5.06,
    "FEB": 5.59,
    "MAR": 4.96,
    "APR": 4.76,
    "MAY": 4.36,
    "JUN": 4.39,
    "JUL": 4.57,
    "AUG": 5.2,
    "SEP": 5.08,
    "OCT": 4.87,
    "NOV": 4.41,
    "DEC": 4.9
  },
  {
    "LONG": -43,
    "LAT": -20.99,
    "CIDADE": "Divinésia",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 5.08,
    "FEB": 5.6,
    "MAR": 5,
    "APR": 4.75,
    "MAY": 4.36,
    "JUN": 4.42,
    "JUL": 4.56,
    "AUG": 5.2,
    "SEP": 5.08,
    "OCT": 4.87,
    "NOV": 4.42,
    "DEC": 4.88
  },
  {
    "LONG": -42.84,
    "LAT": -21.01,
    "CIDADE": "Visconde do Rio Branco",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.25,
    "FEB": 5.77,
    "MAR": 5.2,
    "APR": 4.94,
    "MAY": 4.52,
    "JUN": 4.5,
    "JUL": 4.67,
    "AUG": 5.34,
    "SEP": 5.18,
    "OCT": 5.02,
    "NOV": 4.58,
    "DEC": 5.03
  },
  {
    "LONG": -4.27,
    "LAT": -21.01,
    "CIDADE": "Guiricema",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.3,
    "FEB": 5.84,
    "MAR": 5.25,
    "APR": 5.03,
    "MAY": 4.6,
    "JUN": 4.57,
    "JUL": 4.75,
    "AUG": 5.39,
    "SEP": 5.27,
    "OCT": 5.09,
    "NOV": 4.65,
    "DEC": 5.1
  },
  {
    "LONG": -42.51,
    "LAT": -20.98,
    "CIDADE": "Rosário da Limeira",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 4.97,
    "FEB": 5.5,
    "MAR": 4.91,
    "APR": 4.76,
    "MAY": 4.43,
    "JUN": 4.45,
    "JUL": 4.53,
    "AUG": 5.19,
    "SEP": 5.12,
    "OCT": 4.81,
    "NOV": 4.34,
    "DEC": 4.89
  },
  {
    "LONG": -42.11,
    "LAT": -21.02,
    "CIDADE": "Antônio Prado de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.29,
    "FEB": 5.72,
    "MAR": 5.08,
    "APR": 4.93,
    "MAY": 4.54,
    "JUN": 4.54,
    "JUL": 4.61,
    "AUG": 5.3,
    "SEP": 5.2,
    "OCT": 4.91,
    "NOV": 4.44,
    "DEC": 4.98
  },
  {
    "LONG": -46.98,
    "LAT": -20.92,
    "CIDADE": "São Sebastião do Paraíso",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.07,
    "FEB": 5.62,
    "MAR": 5.18,
    "APR": 5.41,
    "MAY": 5.13,
    "JUN": 5.05,
    "JUL": 5.25,
    "AUG": 5.96,
    "SEP": 5.47,
    "OCT": 5.4,
    "NOV": 5.06,
    "DEC": 5.08
  },
  {
    "LONG": -46.71,
    "LAT": -20.85,
    "CIDADE": "Fortaleza de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.08,
    "FEB": 5.6,
    "MAR": 5.19,
    "APR": 5.43,
    "MAY": 5.11,
    "JUN": 5.01,
    "JUL": 5.22,
    "AUG": 5.95,
    "SEP": 5.49,
    "OCT": 5.42,
    "NOV": 5.04,
    "DEC": 5.07
  },
  {
    "LONG": -46.39,
    "LAT": -20.86,
    "CIDADE": "Alpinópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.15,
    "FEB": 5.55,
    "MAR": 5.11,
    "APR": 5.35,
    "MAY": 5.04,
    "JUN": 5.01,
    "JUL": 5.24,
    "AUG": 5.94,
    "SEP": 5.57,
    "OCT": 5.38,
    "NOV": 4.95,
    "DEC": 5.06
  },
  {
    "LONG": -45.83,
    "LAT": -20.94,
    "CIDADE": "Ilicínea",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 4.98,
    "FEB": 5.59,
    "MAR": 5.1,
    "APR": 5.33,
    "MAY": 5.03,
    "JUN": 4.96,
    "JUL": 5.21,
    "AUG": 5.96,
    "SEP": 5.55,
    "OCT": 5.35,
    "NOV": 4.84,
    "DEC": 5.04
  },
  {
    "LONG": -45.52,
    "LAT": -20.87,
    "CIDADE": "Cristais",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 5.11,
    "FEB": 5.6,
    "MAR": 5.15,
    "APR": 5.41,
    "MAY": 5.01,
    "JUN": 4.87,
    "JUL": 5.15,
    "AUG": 5.86,
    "SEP": 5.52,
    "OCT": 5.33,
    "NOV": 4.81,
    "DEC": 5.06
  },
  {
    "LONG": -45.39,
    "LAT": -20.94,
    "CIDADE": "Aguanil",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.05,
    "FEB": 5.5,
    "MAR": 5.07,
    "APR": 5.36,
    "MAY": 4.97,
    "JUN": 4.91,
    "JUL": 5.22,
    "AUG": 5.89,
    "SEP": 5.56,
    "OCT": 5.3,
    "NOV": 4.77,
    "DEC": 5.03
  },
  {
    "LONG": -45.27,
    "LAT": -20.89,
    "CIDADE": "Campo Belo",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.08,
    "FEB": 5.47,
    "MAR": 5.01,
    "APR": 5.3,
    "MAY": 4.92,
    "JUN": 4.87,
    "JUL": 5.16,
    "AUG": 5.9,
    "SEP": 5.56,
    "OCT": 5.26,
    "NOV": 4.7,
    "DEC": 4.99
  },
  {
    "LONG": -45.13,
    "LAT": -20.9,
    "CIDADE": "Santana do Jacaré",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.06,
    "FEB": 5.5,
    "MAR": 5,
    "APR": 5.34,
    "MAY": 4.91,
    "JUN": 4.85,
    "JUL": 5.12,
    "AUG": 5.93,
    "SEP": 5.56,
    "OCT": 5.23,
    "NOV": 4.7,
    "DEC": 4.95
  },
  {
    "LONG": -4.49,
    "LAT": -20.94,
    "CIDADE": "Santo Antônio do Amparo",
    "UF": "MINAS GERAIS",
    "anual": 5.15,
    "JAN": 5.01,
    "FEB": 5.47,
    "MAR": 4.98,
    "APR": 5.26,
    "MAY": 4.9,
    "JUN": 4.85,
    "JUL": 5.13,
    "AUG": 5.87,
    "SEP": 5.53,
    "OCT": 5.21,
    "NOV": 4.67,
    "DEC": 4.93
  },
  {
    "LONG": -44.51,
    "LAT": -20.91,
    "CIDADE": "São Tiago",
    "UF": "MINAS GERAIS",
    "anual": 5.13,
    "JAN": 5.05,
    "FEB": 5.49,
    "MAR": 5.02,
    "APR": 5.14,
    "MAY": 4.79,
    "JUN": 4.81,
    "JUL": 5.04,
    "AUG": 5.83,
    "SEP": 5.51,
    "OCT": 5.21,
    "NOV": 4.67,
    "DEC": 4.94
  },
  {
    "LONG": -44.24,
    "LAT": -20.92,
    "CIDADE": "Resende Costa",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.05,
    "FEB": 5.56,
    "MAR": 5,
    "APR": 5.02,
    "MAY": 4.64,
    "JUN": 4.68,
    "JUL": 4.91,
    "AUG": 5.75,
    "SEP": 5.42,
    "OCT": 5.14,
    "NOV": 4.61,
    "DEC": 4.93
  },
  {
    "LONG": -44.08,
    "LAT": -20.91,
    "CIDADE": "Lagoa Dourada",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.05,
    "FEB": 5.58,
    "MAR": 5,
    "APR": 4.96,
    "MAY": 4.6,
    "JUN": 4.63,
    "JUL": 4.85,
    "AUG": 5.67,
    "SEP": 5.38,
    "OCT": 5.13,
    "NOV": 4.59,
    "DEC": 4.95
  },
  {
    "LONG": -43.74,
    "LAT": -20.87,
    "CIDADE": "Caranaíba",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 4.97,
    "FEB": 5.6,
    "MAR": 4.98,
    "APR": 4.87,
    "MAY": 4.51,
    "JUN": 4.5,
    "JUL": 4.68,
    "AUG": 5.48,
    "SEP": 5.26,
    "OCT": 5.01,
    "NOV": 4.48,
    "DEC": 4.93
  },
  {
    "LONG": -43.62,
    "LAT": -20.92,
    "CIDADE": "Capela Nova",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.02,
    "FEB": 5.58,
    "MAR": 5.01,
    "APR": 4.83,
    "MAY": 4.45,
    "JUN": 4.45,
    "JUL": 4.62,
    "AUG": 5.43,
    "SEP": 5.21,
    "OCT": 4.97,
    "NOV": 4.41,
    "DEC": 4.92
  },
  {
    "LONG": -43.47,
    "LAT": -20.86,
    "CIDADE": "Rio Espera",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.03,
    "FEB": 5.66,
    "MAR": 5.04,
    "APR": 4.85,
    "MAY": 4.44,
    "JUN": 4.39,
    "JUL": 4.57,
    "AUG": 5.38,
    "SEP": 5.17,
    "OCT": 4.96,
    "NOV": 4.45,
    "DEC": 4.96
  },
  {
    "LONG": -43.36,
    "LAT": -20.9,
    "CIDADE": "Cipotânea",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.03,
    "FEB": 5.63,
    "MAR": 4.99,
    "APR": 4.84,
    "MAY": 4.42,
    "JUN": 4.41,
    "JUL": 4.59,
    "AUG": 5.33,
    "SEP": 5.14,
    "OCT": 4.93,
    "NOV": 4.4,
    "DEC": 4.92
  },
  {
    "LONG": -43.09,
    "LAT": -20.92,
    "CIDADE": "Senador Firmino",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.08,
    "FEB": 5.63,
    "MAR": 5,
    "APR": 4.78,
    "MAY": 4.38,
    "JUN": 4.39,
    "JUL": 4.59,
    "AUG": 5.22,
    "SEP": 5.1,
    "OCT": 4.88,
    "NOV": 4.42,
    "DEC": 4.9
  },
  {
    "LONG": -42.98,
    "LAT": -20.88,
    "CIDADE": "Paula Cândido",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.1,
    "FEB": 5.63,
    "MAR": 5.01,
    "APR": 4.76,
    "MAY": 4.41,
    "JUN": 4.4,
    "JUL": 4.61,
    "AUG": 5.21,
    "SEP": 5.11,
    "OCT": 4.89,
    "NOV": 4.43,
    "DEC": 4.91
  },
  {
    "LONG": -42.8,
    "LAT": -2.09,
    "CIDADE": "Coimbra",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.16,
    "FEB": 5.69,
    "MAR": 5.14,
    "APR": 4.91,
    "MAY": 4.49,
    "JUN": 4.49,
    "JUL": 4.65,
    "AUG": 5.31,
    "SEP": 5.16,
    "OCT": 4.95,
    "NOV": 4.54,
    "DEC": 4.99
  },
  {
    "LONG": -42.84,
    "LAT": -20.93,
    "CIDADE": "São Geraldo",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.16,
    "FEB": 5.69,
    "MAR": 5.14,
    "APR": 4.91,
    "MAY": 4.49,
    "JUN": 4.49,
    "JUL": 4.65,
    "AUG": 5.31,
    "SEP": 5.16,
    "OCT": 4.95,
    "NOV": 4.54,
    "DEC": 4.99
  },
  {
    "LONG": -42.35,
    "LAT": -20.89,
    "CIDADE": "Miradouro",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.14,
    "FEB": 5.72,
    "MAR": 5.05,
    "APR": 4.9,
    "MAY": 4.52,
    "JUN": 4.57,
    "JUL": 4.66,
    "AUG": 5.32,
    "SEP": 5.2,
    "OCT": 4.87,
    "NOV": 4.35,
    "DEC": 4.93
  },
  {
    "LONG": -42.24,
    "LAT": -20.87,
    "CIDADE": "Vieiras",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.27,
    "FEB": 5.71,
    "MAR": 5.12,
    "APR": 4.92,
    "MAY": 4.57,
    "JUN": 4.57,
    "JUL": 4.63,
    "AUG": 5.35,
    "SEP": 5.25,
    "OCT": 4.91,
    "NOV": 4.45,
    "DEC": 4.97
  },
  {
    "LONG": -42.02,
    "LAT": -20.91,
    "CIDADE": "Tombos",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.42,
    "FEB": 5.92,
    "MAR": 5.25,
    "APR": 5.09,
    "MAY": 4.59,
    "JUN": 4.62,
    "JUL": 4.7,
    "AUG": 5.34,
    "SEP": 5.25,
    "OCT": 5,
    "NOV": 4.54,
    "DEC": 5.09
  },
  {
    "LONG": -47.1,
    "LAT": -20.78,
    "CIDADE": "São Tomás de Aquino",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.03,
    "FEB": 5.57,
    "MAR": 5.17,
    "APR": 5.47,
    "MAY": 5.12,
    "JUN": 5.09,
    "JUL": 5.31,
    "AUG": 6.04,
    "SEP": 5.48,
    "OCT": 5.37,
    "NOV": 5.08,
    "DEC": 5.09
  },
  {
    "LONG": -45.92,
    "LAT": -20.76,
    "CIDADE": "Guapé",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.07,
    "FEB": 5.67,
    "MAR": 5.16,
    "APR": 5.5,
    "MAY": 5.13,
    "JUN": 5.04,
    "JUL": 5.26,
    "AUG": 5.94,
    "SEP": 5.52,
    "OCT": 5.42,
    "NOV": 4.95,
    "DEC": 5.1
  },
  {
    "LONG": -45.28,
    "LAT": -20.77,
    "CIDADE": "Candeias",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.06,
    "FEB": 5.44,
    "MAR": 5,
    "APR": 5.28,
    "MAY": 5,
    "JUN": 4.87,
    "JUL": 5.19,
    "AUG": 5.89,
    "SEP": 5.59,
    "OCT": 5.26,
    "NOV": 4.67,
    "DEC": 4.97
  },
  {
    "LONG": -43.93,
    "LAT": -2.08,
    "CIDADE": "Casa Grande",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.11,
    "FEB": 5.63,
    "MAR": 5.08,
    "APR": 5.03,
    "MAY": 4.67,
    "JUN": 4.67,
    "JUL": 4.87,
    "AUG": 5.68,
    "SEP": 5.4,
    "OCT": 5.14,
    "NOV": 4.63,
    "DEC": 4.96
  },
  {
    "LONG": -43.82,
    "LAT": -20.83,
    "CIDADE": "Cristiano Otoni",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.1,
    "FEB": 5.65,
    "MAR": 5.05,
    "APR": 5.01,
    "MAY": 4.64,
    "JUN": 4.62,
    "JUL": 4.8,
    "AUG": 5.62,
    "SEP": 5.36,
    "OCT": 5.11,
    "NOV": 4.6,
    "DEC": 4.98
  },
  {
    "LONG": -43.7,
    "LAT": -20.79,
    "CIDADE": "Santana dos Montes",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5,
    "FEB": 5.64,
    "MAR": 5.01,
    "APR": 4.91,
    "MAY": 4.52,
    "JUN": 4.47,
    "JUL": 4.64,
    "AUG": 5.52,
    "SEP": 5.26,
    "OCT": 4.99,
    "NOV": 4.46,
    "DEC": 4.94
  },
  {
    "LONG": -4.35,
    "LAT": -20.79,
    "CIDADE": "Lamim",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.08,
    "FEB": 5.75,
    "MAR": 5.08,
    "APR": 4.88,
    "MAY": 4.49,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.47,
    "SEP": 5.24,
    "OCT": 5.02,
    "NOV": 4.45,
    "DEC": 4.95
  },
  {
    "LONG": -43.34,
    "LAT": -20.8,
    "CIDADE": "Senhora de Oliveira",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.01,
    "FEB": 5.65,
    "MAR": 4.96,
    "APR": 4.81,
    "MAY": 4.46,
    "JUN": 4.45,
    "JUL": 4.66,
    "AUG": 5.36,
    "SEP": 5.15,
    "OCT": 4.93,
    "NOV": 4.36,
    "DEC": 4.87
  },
  {
    "LONG": -43.24,
    "LAT": -20.84,
    "CIDADE": "Brás Pires",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 4.98,
    "FEB": 5.6,
    "MAR": 4.95,
    "APR": 4.81,
    "MAY": 4.41,
    "JUN": 4.42,
    "JUL": 4.61,
    "AUG": 5.33,
    "SEP": 5.08,
    "OCT": 4.88,
    "NOV": 4.36,
    "DEC": 4.83
  },
  {
    "LONG": -43.19,
    "LAT": -2.08,
    "CIDADE": "Presidente Bernardes",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.06,
    "FEB": 5.66,
    "MAR": 4.99,
    "APR": 4.86,
    "MAY": 4.4,
    "JUN": 4.4,
    "JUL": 4.57,
    "AUG": 5.3,
    "SEP": 5.1,
    "OCT": 4.88,
    "NOV": 4.36,
    "DEC": 4.86
  },
  {
    "LONG": -42.87,
    "LAT": -20.76,
    "CIDADE": "Viçosa",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.08,
    "FEB": 5.64,
    "MAR": 5.08,
    "APR": 4.89,
    "MAY": 4.46,
    "JUN": 4.41,
    "JUL": 4.62,
    "AUG": 5.31,
    "SEP": 5.15,
    "OCT": 4.95,
    "NOV": 4.47,
    "DEC": 4.95
  },
  {
    "LONG": -42.79,
    "LAT": -20.79,
    "CIDADE": "Cajuri",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.12,
    "FEB": 5.71,
    "MAR": 5.11,
    "APR": 4.91,
    "MAY": 4.5,
    "JUN": 4.47,
    "JUL": 4.66,
    "AUG": 5.35,
    "SEP": 5.21,
    "OCT": 5.01,
    "NOV": 4.53,
    "DEC": 4.97
  },
  {
    "LONG": -42.65,
    "LAT": -20.84,
    "CIDADE": "Ervália",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.18,
    "FEB": 5.78,
    "MAR": 5.18,
    "APR": 4.96,
    "MAY": 4.59,
    "JUN": 4.58,
    "JUL": 4.74,
    "AUG": 5.39,
    "SEP": 5.27,
    "OCT": 5.08,
    "NOV": 4.6,
    "DEC": 5.04
  },
  {
    "LONG": -42.27,
    "LAT": -20.79,
    "CIDADE": "São Francisco do Glória",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.11,
    "APR": 4.91,
    "MAY": 4.58,
    "JUN": 4.63,
    "JUL": 4.7,
    "AUG": 5.38,
    "SEP": 5.28,
    "OCT": 4.94,
    "NOV": 4.43,
    "DEC": 4.97
  },
  {
    "LONG": -42.15,
    "LAT": -20.83,
    "CIDADE": "Pedra Dourada",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.04,
    "APR": 4.87,
    "MAY": 4.48,
    "JUN": 4.56,
    "JUL": 4.63,
    "AUG": 5.31,
    "SEP": 5.21,
    "OCT": 4.87,
    "NOV": 4.39,
    "DEC": 4.97
  },
  {
    "LONG": -42.02,
    "LAT": -20.81,
    "CIDADE": "Faria Lemos",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.36,
    "FEB": 5.91,
    "MAR": 5.2,
    "APR": 5.06,
    "MAY": 4.57,
    "JUN": 4.62,
    "JUL": 4.68,
    "AUG": 5.31,
    "SEP": 5.26,
    "OCT": 4.97,
    "NOV": 4.51,
    "DEC": 5.09
  },
  {
    "LONG": -46.86,
    "LAT": -20.74,
    "CIDADE": "Pratápolis",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.16,
    "FEB": 5.68,
    "MAR": 5.22,
    "APR": 5.45,
    "MAY": 5.16,
    "JUN": 5.12,
    "JUL": 5.32,
    "AUG": 6.06,
    "SEP": 5.51,
    "OCT": 5.46,
    "NOV": 5.12,
    "DEC": 5.13
  },
  {
    "LONG": -46.75,
    "LAT": -2.07,
    "CIDADE": "Itaú de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.22,
    "FEB": 5.72,
    "MAR": 5.27,
    "APR": 5.48,
    "MAY": 5.16,
    "JUN": 5.11,
    "JUL": 5.29,
    "AUG": 6,
    "SEP": 5.5,
    "OCT": 5.49,
    "NOV": 5.15,
    "DEC": 5.19
  },
  {
    "LONG": -46.61,
    "LAT": -20.72,
    "CIDADE": "Passos",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.24,
    "FEB": 5.7,
    "MAR": 5.29,
    "APR": 5.47,
    "MAY": 5.15,
    "JUN": 5.09,
    "JUL": 5.27,
    "AUG": 5.9,
    "SEP": 5.54,
    "OCT": 5.49,
    "NOV": 5.16,
    "DEC": 5.2
  },
  {
    "LONG": -46.31,
    "LAT": -20.72,
    "CIDADE": "São José da Barra",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.1,
    "FEB": 5.55,
    "MAR": 5.18,
    "APR": 5.45,
    "MAY": 5.17,
    "JUN": 5.08,
    "JUL": 5.29,
    "AUG": 5.96,
    "SEP": 5.55,
    "OCT": 5.44,
    "NOV": 4.98,
    "DEC": 5.04
  },
  {
    "LONG": -44.98,
    "LAT": -20.7,
    "CIDADE": "São Francisco de Paula",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.11,
    "FEB": 5.58,
    "MAR": 5.03,
    "APR": 5.33,
    "MAY": 4.97,
    "JUN": 4.94,
    "JUL": 5.23,
    "AUG": 5.88,
    "SEP": 5.61,
    "OCT": 5.3,
    "NOV": 4.75,
    "DEC": 4.98
  },
  {
    "LONG": -44.83,
    "LAT": -20.7,
    "CIDADE": "Oliveira",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.1,
    "FEB": 5.58,
    "MAR": 5.14,
    "APR": 5.32,
    "MAY": 4.97,
    "JUN": 4.98,
    "JUL": 5.22,
    "AUG": 5.92,
    "SEP": 5.64,
    "OCT": 5.33,
    "NOV": 4.78,
    "DEC": 5.01
  },
  {
    "LONG": -4.45,
    "LAT": -20.65,
    "CIDADE": "Passa Tempo",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.11,
    "FEB": 5.6,
    "MAR": 5.14,
    "APR": 5.25,
    "MAY": 4.91,
    "JUN": 4.96,
    "JUL": 5.14,
    "AUG": 5.9,
    "SEP": 5.61,
    "OCT": 5.34,
    "NOV": 4.72,
    "DEC": 5.04
  },
  {
    "LONG": -44.33,
    "LAT": -20.67,
    "CIDADE": "Desterro de Entre Rios",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.58,
    "MAR": 5.13,
    "APR": 5.22,
    "MAY": 4.85,
    "JUN": 4.9,
    "JUL": 5.06,
    "AUG": 5.85,
    "SEP": 5.57,
    "OCT": 5.3,
    "NOV": 4.71,
    "DEC": 4.98
  },
  {
    "LONG": -44.07,
    "LAT": -20.67,
    "CIDADE": "Entre Rios de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 5.11,
    "FEB": 5.59,
    "MAR": 5.05,
    "APR": 5.07,
    "MAY": 4.7,
    "JUN": 4.68,
    "JUL": 4.89,
    "AUG": 5.74,
    "SEP": 5.46,
    "OCT": 5.17,
    "NOV": 4.65,
    "DEC": 4.94
  },
  {
    "LONG": -43.89,
    "LAT": -2.07,
    "CIDADE": "Queluzito",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.13,
    "FEB": 5.67,
    "MAR": 5.11,
    "APR": 5.07,
    "MAY": 4.72,
    "JUN": 4.69,
    "JUL": 4.87,
    "AUG": 5.72,
    "SEP": 5.41,
    "OCT": 5.16,
    "NOV": 4.66,
    "DEC": 4.98
  },
  {
    "LONG": -4.38,
    "LAT": -20.66,
    "CIDADE": "Conselheiro Lafaiete",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.99,
    "FEB": 5.64,
    "MAR": 5.09,
    "APR": 5.07,
    "MAY": 4.7,
    "JUN": 4.67,
    "JUL": 4.78,
    "AUG": 5.7,
    "SEP": 5.4,
    "OCT": 5.15,
    "NOV": 4.63,
    "DEC": 4.97
  },
  {
    "LONG": -43.61,
    "LAT": -20.68,
    "CIDADE": "Itaverava",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.03,
    "FEB": 5.68,
    "MAR": 5.01,
    "APR": 4.94,
    "MAY": 4.63,
    "JUN": 4.55,
    "JUL": 4.69,
    "AUG": 5.61,
    "SEP": 5.34,
    "OCT": 5.05,
    "NOV": 4.46,
    "DEC": 4.91
  },
  {
    "LONG": -43.49,
    "LAT": -20.69,
    "CIDADE": "Catas Altas da Noruega",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.01,
    "FEB": 5.67,
    "MAR": 4.97,
    "APR": 4.83,
    "MAY": 4.49,
    "JUN": 4.51,
    "JUL": 4.69,
    "AUG": 5.5,
    "SEP": 5.24,
    "OCT": 4.94,
    "NOV": 4.35,
    "DEC": 4.8
  },
  {
    "LONG": -43.3,
    "LAT": -20.68,
    "CIDADE": "Piranga",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 4.96,
    "FEB": 5.59,
    "MAR": 4.98,
    "APR": 4.84,
    "MAY": 4.46,
    "JUN": 4.42,
    "JUL": 4.64,
    "AUG": 5.37,
    "SEP": 5.13,
    "OCT": 4.88,
    "NOV": 4.36,
    "DEC": 4.79
  },
  {
    "LONG": -43.08,
    "LAT": -20.66,
    "CIDADE": "Porto Firme",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.1,
    "FEB": 5.63,
    "MAR": 5.07,
    "APR": 4.88,
    "MAY": 4.47,
    "JUN": 4.36,
    "JUL": 4.57,
    "AUG": 5.33,
    "SEP": 5.14,
    "OCT": 4.91,
    "NOV": 4.4,
    "DEC": 4.89
  },
  {
    "LONG": -42.86,
    "LAT": -20.66,
    "CIDADE": "Teixeiras",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.08,
    "FEB": 5.62,
    "MAR": 5.05,
    "APR": 4.89,
    "MAY": 4.47,
    "JUN": 4.39,
    "JUL": 4.6,
    "AUG": 5.27,
    "SEP": 5.12,
    "OCT": 4.92,
    "NOV": 4.44,
    "DEC": 4.92
  },
  {
    "LONG": -42.72,
    "LAT": -20.71,
    "CIDADE": "São Miguel do Anta",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.11,
    "FEB": 5.68,
    "MAR": 5.11,
    "APR": 4.92,
    "MAY": 4.52,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.34,
    "SEP": 5.18,
    "OCT": 5,
    "NOV": 4.49,
    "DEC": 4.95
  },
  {
    "LONG": -4.26,
    "LAT": -20.69,
    "CIDADE": "Canaã",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.2,
    "FEB": 5.79,
    "MAR": 5.24,
    "APR": 5.03,
    "MAY": 4.65,
    "JUN": 4.6,
    "JUL": 4.74,
    "AUG": 5.41,
    "SEP": 5.29,
    "OCT": 5.1,
    "NOV": 4.59,
    "DEC": 5.04
  },
  {
    "LONG": -42.52,
    "LAT": -20.67,
    "CIDADE": "Araponga",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.12,
    "FEB": 5.6,
    "MAR": 5.08,
    "APR": 4.97,
    "MAY": 4.62,
    "JUN": 4.61,
    "JUL": 4.75,
    "AUG": 5.39,
    "SEP": 5.3,
    "OCT": 5.04,
    "NOV": 4.52,
    "DEC": 5
  },
  {
    "LONG": -42.28,
    "LAT": -20.73,
    "CIDADE": "Fervedouro",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.24,
    "FEB": 5.78,
    "MAR": 5.06,
    "APR": 4.91,
    "MAY": 4.54,
    "JUN": 4.64,
    "JUL": 4.74,
    "AUG": 5.37,
    "SEP": 5.3,
    "OCT": 4.92,
    "NOV": 4.38,
    "DEC": 4.96
  },
  {
    "LONG": -42.03,
    "LAT": -20.73,
    "CIDADE": "Carangola",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.3,
    "FEB": 5.84,
    "MAR": 5.18,
    "APR": 5.06,
    "MAY": 4.6,
    "JUN": 4.65,
    "JUL": 4.7,
    "AUG": 5.36,
    "SEP": 5.28,
    "OCT": 4.96,
    "NOV": 4.49,
    "DEC": 5.08
  },
  {
    "LONG": -41.91,
    "LAT": -20.65,
    "CIDADE": "Espera Feliz",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.3,
    "FEB": 5.8,
    "MAR": 5.1,
    "APR": 4.96,
    "MAY": 4.57,
    "JUN": 4.67,
    "JUL": 4.73,
    "AUG": 5.34,
    "SEP": 5.27,
    "OCT": 4.97,
    "NOV": 4.44,
    "DEC": 5.01
  },
  {
    "LONG": -41.93,
    "LAT": -20.7,
    "CIDADE": "Caiana",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.3,
    "FEB": 5.8,
    "MAR": 5.1,
    "APR": 4.96,
    "MAY": 4.57,
    "JUN": 4.67,
    "JUL": 4.73,
    "AUG": 5.34,
    "SEP": 5.27,
    "OCT": 4.97,
    "NOV": 4.44,
    "DEC": 5.01
  },
  {
    "LONG": -47.06,
    "LAT": -20.62,
    "CIDADE": "Capetinga",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.03,
    "FEB": 5.56,
    "MAR": 5.09,
    "APR": 5.42,
    "MAY": 5.15,
    "JUN": 5.11,
    "JUL": 5.35,
    "AUG": 6.07,
    "SEP": 5.49,
    "OCT": 5.35,
    "NOV": 5.01,
    "DEC": 5.03
  },
  {
    "LONG": -46.92,
    "LAT": -20.58,
    "CIDADE": "Cássia",
    "UF": "MINAS GERAIS",
    "anual": 5.33,
    "JAN": 5.12,
    "FEB": 5.65,
    "MAR": 5.15,
    "APR": 5.42,
    "MAY": 5.16,
    "JUN": 5.06,
    "JUL": 5.34,
    "AUG": 6.03,
    "SEP": 5.53,
    "OCT": 5.37,
    "NOV": 5.01,
    "DEC": 5.06
  },
  {
    "LONG": -46.51,
    "LAT": -20.64,
    "CIDADE": "São João Batista do Glória",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.14,
    "FEB": 5.64,
    "MAR": 5.19,
    "APR": 5.46,
    "MAY": 5.15,
    "JUN": 5.04,
    "JUL": 5.25,
    "AUG": 5.9,
    "SEP": 5.53,
    "OCT": 5.44,
    "NOV": 5.02,
    "DEC": 5.1
  },
  {
    "LONG": -46.05,
    "LAT": -20.62,
    "CIDADE": "Capitólio",
    "UF": "MINAS GERAIS",
    "anual": 5.27,
    "JAN": 5.06,
    "FEB": 5.61,
    "MAR": 5.02,
    "APR": 5.37,
    "MAY": 5.11,
    "JUN": 5.03,
    "JUL": 5.29,
    "AUG": 5.99,
    "SEP": 5.6,
    "OCT": 5.4,
    "NOV": 4.78,
    "DEC": 4.95
  },
  {
    "LONG": -45.16,
    "LAT": -20.63,
    "CIDADE": "Camacho",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.1,
    "FEB": 5.59,
    "MAR": 5.1,
    "APR": 5.39,
    "MAY": 5.07,
    "JUN": 4.99,
    "JUL": 5.26,
    "AUG": 6,
    "SEP": 5.68,
    "OCT": 5.34,
    "NOV": 4.71,
    "DEC": 4.91
  },
  {
    "LONG": -44.87,
    "LAT": -2.06,
    "CIDADE": "Carmo da Mata",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.14,
    "FEB": 5.67,
    "MAR": 5.17,
    "APR": 5.42,
    "MAY": 5.02,
    "JUN": 5.06,
    "JUL": 5.25,
    "AUG": 5.95,
    "SEP": 5.69,
    "OCT": 5.39,
    "NOV": 4.8,
    "DEC": 5.02
  },
  {
    "LONG": -43.95,
    "LAT": -20.62,
    "CIDADE": "São Brás do Suaçuí",
    "UF": "MINAS GERAIS",
    "anual": 5.15,
    "JAN": 5.2,
    "FEB": 5.63,
    "MAR": 5.14,
    "APR": 5.16,
    "MAY": 4.83,
    "JUN": 4.76,
    "JUL": 4.93,
    "AUG": 5.79,
    "SEP": 5.52,
    "OCT": 5.22,
    "NOV": 4.72,
    "DEC": 4.97
  },
  {
    "LONG": -43.01,
    "LAT": -2.06,
    "CIDADE": "Guaraciaba",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.07,
    "FEB": 5.64,
    "MAR": 5.06,
    "APR": 4.92,
    "MAY": 4.45,
    "JUN": 4.41,
    "JUL": 4.61,
    "AUG": 5.32,
    "SEP": 5.14,
    "OCT": 4.88,
    "NOV": 4.4,
    "DEC": 4.85
  },
  {
    "LONG": -42.71,
    "LAT": -20.6,
    "CIDADE": "Pedra do Anta",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.11,
    "FEB": 5.71,
    "MAR": 5.15,
    "APR": 4.92,
    "MAY": 4.52,
    "JUN": 4.44,
    "JUL": 4.64,
    "AUG": 5.31,
    "SEP": 5.17,
    "OCT": 4.97,
    "NOV": 4.49,
    "DEC": 4.97
  },
  {
    "LONG": -42.14,
    "LAT": -20.61,
    "CIDADE": "Divino",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.23,
    "FEB": 5.76,
    "MAR": 5.12,
    "APR": 5.02,
    "MAY": 4.59,
    "JUN": 4.64,
    "JUL": 4.75,
    "AUG": 5.35,
    "SEP": 5.34,
    "OCT": 5,
    "NOV": 4.44,
    "DEC": 5
  },
  {
    "LONG": -47.12,
    "LAT": -20.46,
    "CIDADE": "Ibiraci",
    "UF": "MINAS GERAIS",
    "anual": 5.33,
    "JAN": 5.02,
    "FEB": 5.53,
    "MAR": 5.12,
    "APR": 5.45,
    "MAY": 5.17,
    "JUN": 5.13,
    "JUL": 5.37,
    "AUG": 6.07,
    "SEP": 5.53,
    "OCT": 5.39,
    "NOV": 5.08,
    "DEC": 5.05
  },
  {
    "LONG": -45.96,
    "LAT": -20.48,
    "CIDADE": "Piumhi",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.09,
    "FEB": 5.64,
    "MAR": 5.11,
    "APR": 5.42,
    "MAY": 5.13,
    "JUN": 5.09,
    "JUL": 5.32,
    "AUG": 6.07,
    "SEP": 5.66,
    "OCT": 5.42,
    "NOV": 4.79,
    "DEC": 4.99
  },
  {
    "LONG": -45.81,
    "LAT": -20.48,
    "CIDADE": "Pimenta",
    "UF": "MINAS GERAIS",
    "anual": 5.27,
    "JAN": 5.07,
    "FEB": 5.56,
    "MAR": 5.02,
    "APR": 5.39,
    "MAY": 5.11,
    "JUN": 5.02,
    "JUL": 5.3,
    "AUG": 6.04,
    "SEP": 5.63,
    "OCT": 5.39,
    "NOV": 4.72,
    "DEC": 5.01
  },
  {
    "LONG": -45.43,
    "LAT": -20.46,
    "CIDADE": "Formiga",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 5.09,
    "FEB": 5.59,
    "MAR": 5.06,
    "APR": 5.4,
    "MAY": 5.1,
    "JUN": 5.05,
    "JUL": 5.28,
    "AUG": 6.04,
    "SEP": 5.67,
    "OCT": 5.35,
    "NOV": 4.76,
    "DEC": 5.02
  },
  {
    "LONG": -45.13,
    "LAT": -20.47,
    "CIDADE": "Itapecerica",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 5.12,
    "FEB": 5.62,
    "MAR": 5.14,
    "APR": 5.43,
    "MAY": 5.09,
    "JUN": 5.02,
    "JUL": 5.29,
    "AUG": 6,
    "SEP": 5.72,
    "OCT": 5.36,
    "NOV": 4.76,
    "DEC": 4.96
  },
  {
    "LONG": -44.63,
    "LAT": "#VALOR!",
    "CIDADE": "Carmópolis de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.15,
    "FEB": 5.63,
    "MAR": 5.22,
    "APR": 5.41,
    "MAY": 5.03,
    "JUN": 5.01,
    "JUL": 5.29,
    "AUG": 5.95,
    "SEP": 5.7,
    "OCT": 5.41,
    "NOV": 4.86,
    "DEC": 5.06
  },
  {
    "LONG": -44.48,
    "LAT": -20.51,
    "CIDADE": "Piracema",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 5.12,
    "FEB": 5.63,
    "MAR": 5.23,
    "APR": 5.38,
    "MAY": 5.06,
    "JUN": 5.05,
    "JUL": 5.27,
    "AUG": 5.95,
    "SEP": 5.68,
    "OCT": 5.36,
    "NOV": 4.8,
    "DEC": 4.99
  },
  {
    "LONG": -44.22,
    "LAT": -2.05,
    "CIDADE": "Piedade dos Gerais",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 5.16,
    "FEB": 5.64,
    "MAR": 5.11,
    "APR": 5.27,
    "MAY": 4.98,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 5.88,
    "SEP": 5.65,
    "OCT": 5.3,
    "NOV": 4.76,
    "DEC": 4.99
  },
  {
    "LONG": -43.99,
    "LAT": -20.53,
    "CIDADE": "Jeceaba",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.12,
    "FEB": 5.58,
    "MAR": 5.11,
    "APR": 5.19,
    "MAY": 4.94,
    "JUN": 4.88,
    "JUL": 5.04,
    "AUG": 5.86,
    "SEP": 5.56,
    "OCT": 5.2,
    "NOV": 4.69,
    "DEC": 4.94
  },
  {
    "LONG": -43.85,
    "LAT": -20.5,
    "CIDADE": "Congonhas",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 4.97,
    "FEB": 5.44,
    "MAR": 4.93,
    "APR": 4.99,
    "MAY": 4.85,
    "JUN": 4.83,
    "JUL": 4.95,
    "AUG": 5.79,
    "SEP": 5.44,
    "OCT": 5.06,
    "NOV": 4.51,
    "DEC": 4.8
  },
  {
    "LONG": -43.7,
    "LAT": -20.53,
    "CIDADE": "Ouro Branco",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 4.97,
    "FEB": 5.48,
    "MAR": 4.92,
    "APR": 4.92,
    "MAY": 4.72,
    "JUN": 4.78,
    "JUL": 4.93,
    "AUG": 5.76,
    "SEP": 5.41,
    "OCT": 5.06,
    "NOV": 4.45,
    "DEC": 4.77
  },
  {
    "LONG": -43.2,
    "LAT": -20.49,
    "CIDADE": "Diogo de Vasconcelos",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.06,
    "FEB": 5.6,
    "MAR": 5.03,
    "APR": 4.91,
    "MAY": 4.47,
    "JUN": 4.42,
    "JUL": 4.62,
    "AUG": 5.33,
    "SEP": 5.12,
    "OCT": 4.83,
    "NOV": 4.36,
    "DEC": 4.79
  },
  {
    "LONG": -42.8,
    "LAT": -20.51,
    "CIDADE": "Amparo do Serra",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.05,
    "FEB": 5.72,
    "MAR": 5.16,
    "APR": 4.95,
    "MAY": 4.52,
    "JUN": 4.42,
    "JUL": 4.63,
    "AUG": 5.3,
    "SEP": 5.16,
    "OCT": 4.95,
    "NOV": 4.5,
    "DEC": 4.94
  },
  {
    "LONG": -42.67,
    "LAT": -20.45,
    "CIDADE": "Jequeri",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 5.28,
    "FEB": 5.83,
    "MAR": 5.3,
    "APR": 5.11,
    "MAY": 4.69,
    "JUN": 4.58,
    "JUL": 4.78,
    "AUG": 5.42,
    "SEP": 5.25,
    "OCT": 5.08,
    "NOV": 4.62,
    "DEC": 5.12
  },
  {
    "LONG": -42.48,
    "LAT": -20.48,
    "CIDADE": "Sericita",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5,
    "FEB": 5.55,
    "MAR": 5.04,
    "APR": 4.85,
    "MAY": 4.51,
    "JUN": 4.47,
    "JUL": 4.68,
    "AUG": 5.27,
    "SEP": 5.22,
    "OCT": 4.9,
    "NOV": 4.38,
    "DEC": 4.89
  },
  {
    "LONG": -42.33,
    "LAT": -20.52,
    "CIDADE": "Pedra Bonita",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 4.95,
    "FEB": 5.54,
    "MAR": 4.96,
    "APR": 4.85,
    "MAY": 4.43,
    "JUN": 4.4,
    "JUL": 4.66,
    "AUG": 5.19,
    "SEP": 5.18,
    "OCT": 4.86,
    "NOV": 4.32,
    "DEC": 4.86
  },
  {
    "LONG": -42.2,
    "LAT": -20.51,
    "CIDADE": "Orizânia",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.05,
    "FEB": 5.61,
    "MAR": 5.02,
    "APR": 4.93,
    "MAY": 4.46,
    "JUN": 4.49,
    "JUL": 4.67,
    "AUG": 5.27,
    "SEP": 5.24,
    "OCT": 4.9,
    "NOV": 4.39,
    "DEC": 4.93
  },
  {
    "LONG": -41.91,
    "LAT": -20.53,
    "CIDADE": "Caparaó",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.19,
    "FEB": 5.69,
    "MAR": 5.04,
    "APR": 4.91,
    "MAY": 4.52,
    "JUN": 4.53,
    "JUL": 4.67,
    "AUG": 5.21,
    "SEP": 5.19,
    "OCT": 4.86,
    "NOV": 4.39,
    "DEC": 4.9
  },
  {
    "LONG": -47.28,
    "LAT": -20.4,
    "CIDADE": "Claraval",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.07,
    "FEB": 5.69,
    "MAR": 5.28,
    "APR": 5.58,
    "MAY": 5.22,
    "JUN": 5.21,
    "JUL": 5.35,
    "AUG": 6.11,
    "SEP": 5.53,
    "OCT": 5.44,
    "NOV": 5.18,
    "DEC": 5.18
  },
  {
    "LONG": -45.66,
    "LAT": -20.37,
    "CIDADE": "Pains",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 5.2,
    "FEB": 5.71,
    "MAR": 5.16,
    "APR": 5.5,
    "MAY": 5.19,
    "JUN": 5.09,
    "JUL": 5.34,
    "AUG": 6.09,
    "SEP": 5.7,
    "OCT": 5.37,
    "NOV": 4.8,
    "DEC": 5.1
  },
  {
    "LONG": -45.56,
    "LAT": -20.45,
    "CIDADE": "Córrego Fundo",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.18,
    "FEB": 5.63,
    "MAR": 5.17,
    "APR": 5.43,
    "MAY": 5.16,
    "JUN": 5.05,
    "JUL": 5.34,
    "AUG": 6.03,
    "SEP": 5.7,
    "OCT": 5.35,
    "NOV": 4.79,
    "DEC": 5.06
  },
  {
    "LONG": -44.77,
    "LAT": -20.44,
    "CIDADE": "Cláudio",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.26,
    "FEB": 5.67,
    "MAR": 5.27,
    "APR": 5.45,
    "MAY": 5.15,
    "JUN": 5.06,
    "JUL": 5.34,
    "AUG": 6.05,
    "SEP": 5.76,
    "OCT": 5.43,
    "NOV": 4.9,
    "DEC": 5.03
  },
  {
    "LONG": -44.49,
    "LAT": -20.4,
    "CIDADE": "Itaguara",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.07,
    "FEB": 5.53,
    "MAR": 5.12,
    "APR": 5.27,
    "MAY": 5.03,
    "JUN": 5.05,
    "JUL": 5.27,
    "AUG": 6,
    "SEP": 5.7,
    "OCT": 5.29,
    "NOV": 4.73,
    "DEC": 4.92
  },
  {
    "LONG": -44.33,
    "LAT": -20.39,
    "CIDADE": "Crucilândia",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.11,
    "FEB": 5.58,
    "MAR": 5.15,
    "APR": 5.26,
    "MAY": 5.01,
    "JUN": 5.02,
    "JUL": 5.29,
    "AUG": 5.95,
    "SEP": 5.69,
    "OCT": 5.32,
    "NOV": 4.74,
    "DEC": 4.92
  },
  {
    "LONG": -44.03,
    "LAT": -20.41,
    "CIDADE": "Belo Vale",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.24,
    "FEB": 5.7,
    "MAR": 5.27,
    "APR": 5.38,
    "MAY": 5,
    "JUN": 5.05,
    "JUL": 5.19,
    "AUG": 5.91,
    "SEP": 5.68,
    "OCT": 5.36,
    "NOV": 4.85,
    "DEC": 5.01
  },
  {
    "LONG": -43.51,
    "LAT": "#VALOR!",
    "CIDADE": "Ouro Preto",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.07,
    "FEB": 5.56,
    "MAR": 5.05,
    "APR": 4.94,
    "MAY": 4.72,
    "JUN": 4.78,
    "JUL": 4.94,
    "AUG": 5.74,
    "SEP": 5.42,
    "OCT": 5.04,
    "NOV": 4.45,
    "DEC": 4.8
  },
  {
    "LONG": -43.41,
    "LAT": -2.04,
    "CIDADE": "Mariana",
    "UF": "MINAS GERAIS",
    "anual": 4.76,
    "JAN": 4.75,
    "FEB": 5.29,
    "MAR": 4.69,
    "APR": 4.69,
    "MAY": 4.46,
    "JUN": 4.47,
    "JUL": 4.64,
    "AUG": 5.39,
    "SEP": 5.14,
    "OCT": 4.75,
    "NOV": 4.22,
    "DEC": 4.59
  },
  {
    "LONG": -43.14,
    "LAT": -20.36,
    "CIDADE": "Acaiaca",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.1,
    "FEB": 5.66,
    "MAR": 5.15,
    "APR": 4.98,
    "MAY": 4.57,
    "JUN": 4.49,
    "JUL": 4.68,
    "AUG": 5.41,
    "SEP": 5.18,
    "OCT": 4.86,
    "NOV": 4.44,
    "DEC": 4.89
  },
  {
    "LONG": -42.9,
    "LAT": -20.41,
    "CIDADE": "Ponte Nova",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.18,
    "FEB": 5.81,
    "MAR": 5.22,
    "APR": 5.05,
    "MAY": 4.59,
    "JUN": 4.48,
    "JUL": 4.72,
    "AUG": 5.36,
    "SEP": 5.22,
    "OCT": 5.02,
    "NOV": 4.52,
    "DEC": 5
  },
  {
    "LONG": -42.74,
    "LAT": -20.35,
    "CIDADE": "Urucânia",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.19,
    "FEB": 5.79,
    "MAR": 5.18,
    "APR": 5.01,
    "MAY": 4.6,
    "JUN": 4.5,
    "JUL": 4.71,
    "AUG": 5.35,
    "SEP": 5.2,
    "OCT": 5.01,
    "NOV": 4.51,
    "DEC": 5.02
  },
  {
    "LONG": -42.8,
    "LAT": -20.43,
    "CIDADE": "Oratórios",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.19,
    "FEB": 5.79,
    "MAR": 5.18,
    "APR": 5.01,
    "MAY": 4.6,
    "JUN": 4.5,
    "JUL": 4.71,
    "AUG": 5.35,
    "SEP": 5.2,
    "OCT": 5.01,
    "NOV": 4.51,
    "DEC": 5.02
  },
  {
    "LONG": -42.25,
    "LAT": -20.38,
    "CIDADE": "Santa Margarida",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.12,
    "FEB": 5.63,
    "MAR": 5.13,
    "APR": 5,
    "MAY": 4.54,
    "JUN": 4.59,
    "JUL": 4.71,
    "AUG": 5.29,
    "SEP": 5.25,
    "OCT": 4.91,
    "NOV": 4.42,
    "DEC": 4.97
  },
  {
    "LONG": -42.15,
    "LAT": -20.39,
    "CIDADE": "São João do Manhuaçu",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 5.03,
    "FEB": 5.52,
    "MAR": 4.97,
    "APR": 4.9,
    "MAY": 4.45,
    "JUN": 4.48,
    "JUL": 4.59,
    "AUG": 5.19,
    "SEP": 5.12,
    "OCT": 4.8,
    "NOV": 4.3,
    "DEC": 4.83
  },
  {
    "LONG": -4.21,
    "LAT": -20.45,
    "CIDADE": "Luisburgo",
    "UF": "MINAS GERAIS",
    "anual": 4.81,
    "JAN": 4.97,
    "FEB": 5.54,
    "MAR": 4.95,
    "APR": 4.89,
    "MAY": 4.47,
    "JUN": 4.43,
    "JUL": 4.53,
    "AUG": 5.13,
    "SEP": 5.04,
    "OCT": 4.76,
    "NOV": 4.24,
    "DEC": 4.73
  },
  {
    "LONG": -41.96,
    "LAT": -20.36,
    "CIDADE": "Manhumirim",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.09,
    "FEB": 5.61,
    "MAR": 5.04,
    "APR": 4.92,
    "MAY": 4.51,
    "JUN": 4.45,
    "JUL": 4.58,
    "AUG": 5.11,
    "SEP": 5.03,
    "OCT": 4.78,
    "NOV": 4.34,
    "DEC": 4.84
  },
  {
    "LONG": -41.97,
    "LAT": -20.42,
    "CIDADE": "Alto Jequitibá",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.09,
    "FEB": 5.61,
    "MAR": 5.04,
    "APR": 4.92,
    "MAY": 4.51,
    "JUN": 4.45,
    "JUL": 4.58,
    "AUG": 5.11,
    "SEP": 5.03,
    "OCT": 4.78,
    "NOV": 4.34,
    "DEC": 4.84
  },
  {
    "LONG": -41.87,
    "LAT": -20.43,
    "CIDADE": "Alto Caparaó",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.06,
    "FEB": 5.54,
    "MAR": 4.93,
    "APR": 4.83,
    "MAY": 4.55,
    "JUN": 4.48,
    "JUL": 4.61,
    "AUG": 5.15,
    "SEP": 5.15,
    "OCT": 4.82,
    "NOV": 4.35,
    "DEC": 4.85
  },
  {
    "LONG": -49.2,
    "LAT": -20.28,
    "CIDADE": "Fronteira",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.23,
    "FEB": 5.72,
    "MAR": 5.36,
    "APR": 5.67,
    "MAY": 5.12,
    "JUN": 4.98,
    "JUL": 5.22,
    "AUG": 5.89,
    "SEP": 5.38,
    "OCT": 5.43,
    "NOV": 5.33,
    "DEC": 5.49
  },
  {
    "LONG": -46.85,
    "LAT": -20.35,
    "CIDADE": "Delfinópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 4.97,
    "FEB": 5.46,
    "MAR": 5.06,
    "APR": 5.45,
    "MAY": 5.2,
    "JUN": 5.11,
    "JUL": 5.34,
    "AUG": 6.03,
    "SEP": 5.56,
    "OCT": 5.41,
    "NOV": 4.97,
    "DEC": 4.97
  },
  {
    "LONG": -46.37,
    "LAT": -20.33,
    "CIDADE": "Vargem Bonita",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 4.92,
    "FEB": 5.52,
    "MAR": 4.82,
    "APR": 5.27,
    "MAY": 5.1,
    "JUN": 5.06,
    "JUL": 5.32,
    "AUG": 6.16,
    "SEP": 5.59,
    "OCT": 5.22,
    "NOV": 4.55,
    "DEC": 4.75
  },
  {
    "LONG": -45.9,
    "LAT": -20.29,
    "CIDADE": "Doresópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.14,
    "FEB": 5.72,
    "MAR": 5.13,
    "APR": 5.48,
    "MAY": 5.27,
    "JUN": 5.15,
    "JUL": 5.4,
    "AUG": 6.19,
    "SEP": 5.72,
    "OCT": 5.38,
    "NOV": 4.77,
    "DEC": 5.02
  },
  {
    "LONG": -45.54,
    "LAT": -20.29,
    "CIDADE": "Arcos",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.26,
    "FEB": 5.68,
    "MAR": 5.24,
    "APR": 5.51,
    "MAY": 5.24,
    "JUN": 5.09,
    "JUL": 5.39,
    "AUG": 6.06,
    "SEP": 5.77,
    "OCT": 5.4,
    "NOV": 4.82,
    "DEC": 5.13
  },
  {
    "LONG": -4.52,
    "LAT": -20.26,
    "CIDADE": "Pedra do Indaiá",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.1,
    "APR": 5.43,
    "MAY": 5.15,
    "JUN": 5.08,
    "JUL": 5.31,
    "AUG": 6.03,
    "SEP": 5.76,
    "OCT": 5.35,
    "NOV": 4.7,
    "DEC": 4.95
  },
  {
    "LONG": -45.01,
    "LAT": -20.28,
    "CIDADE": "São Sebastião do Oeste",
    "UF": "MINAS GERAIS",
    "anual": 5.34,
    "JAN": 5.17,
    "FEB": 5.7,
    "MAR": 5.15,
    "APR": 5.47,
    "MAY": 5.14,
    "JUN": 5.13,
    "JUL": 5.36,
    "AUG": 6.07,
    "SEP": 5.78,
    "OCT": 5.4,
    "NOV": 4.8,
    "DEC": 4.99
  },
  {
    "LONG": -44.31,
    "LAT": -2.03,
    "CIDADE": "Rio Manso",
    "UF": "MINAS GERAIS",
    "anual": 5.27,
    "JAN": 5.14,
    "FEB": 5.61,
    "MAR": 5.14,
    "APR": 5.27,
    "MAY": 5.04,
    "JUN": 5.03,
    "JUL": 5.26,
    "AUG": 6,
    "SEP": 5.72,
    "OCT": 5.33,
    "NOV": 4.77,
    "DEC": 4.92
  },
  {
    "LONG": -44.24,
    "LAT": -20.33,
    "CIDADE": "Bonfim",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.22,
    "FEB": 5.66,
    "MAR": 5.21,
    "APR": 5.34,
    "MAY": 5.05,
    "JUN": 5.02,
    "JUL": 5.23,
    "AUG": 5.97,
    "SEP": 5.71,
    "OCT": 5.36,
    "NOV": 4.83,
    "DEC": 4.99
  },
  {
    "LONG": -44.05,
    "LAT": -20.34,
    "CIDADE": "Moeda",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.25,
    "FEB": 5.66,
    "MAR": 5.27,
    "APR": 5.36,
    "MAY": 5.06,
    "JUN": 5.02,
    "JUL": 5.22,
    "AUG": 5.94,
    "SEP": 5.71,
    "OCT": 5.36,
    "NOV": 4.84,
    "DEC": 5.01
  },
  {
    "LONG": -43.8,
    "LAT": -20.25,
    "CIDADE": "Itabirito",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.93,
    "FEB": 5.4,
    "MAR": 4.96,
    "APR": 5.02,
    "MAY": 4.8,
    "JUN": 4.86,
    "JUL": 5.09,
    "AUG": 5.82,
    "SEP": 5.49,
    "OCT": 5.07,
    "NOV": 4.53,
    "DEC": 4.74
  },
  {
    "LONG": -43.04,
    "LAT": -20.29,
    "CIDADE": "Barra Longa",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.11,
    "FEB": 5.67,
    "MAR": 5.12,
    "APR": 5.02,
    "MAY": 4.53,
    "JUN": 4.49,
    "JUL": 4.65,
    "AUG": 5.39,
    "SEP": 5.23,
    "OCT": 4.89,
    "NOV": 4.42,
    "DEC": 4.9
  },
  {
    "LONG": -42.61,
    "LAT": -20.32,
    "CIDADE": "Santo Antônio do Grama",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.27,
    "FEB": 5.85,
    "MAR": 5.29,
    "APR": 5.08,
    "MAY": 4.67,
    "JUN": 4.61,
    "JUL": 4.77,
    "AUG": 5.38,
    "SEP": 5.26,
    "OCT": 5.02,
    "NOV": 4.56,
    "DEC": 5.07
  },
  {
    "LONG": -42.47,
    "LAT": -20.3,
    "CIDADE": "Abre Campo",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.24,
    "FEB": 5.77,
    "MAR": 5.22,
    "APR": 5.03,
    "MAY": 4.62,
    "JUN": 4.57,
    "JUL": 4.67,
    "AUG": 5.31,
    "SEP": 5.21,
    "OCT": 4.96,
    "NOV": 4.53,
    "DEC": 5.07
  },
  {
    "LONG": -42.34,
    "LAT": -20.29,
    "CIDADE": "Matipó",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.18,
    "FEB": 5.71,
    "MAR": 5.15,
    "APR": 5.01,
    "MAY": 4.57,
    "JUN": 4.55,
    "JUL": 4.7,
    "AUG": 5.27,
    "SEP": 5.21,
    "OCT": 4.91,
    "NOV": 4.44,
    "DEC": 5.02
  },
  {
    "LONG": -42.03,
    "LAT": -20.26,
    "CIDADE": "Manhuaçu",
    "UF": "MINAS GERAIS",
    "anual": 4.81,
    "JAN": 5.02,
    "FEB": 5.53,
    "MAR": 4.93,
    "APR": 4.9,
    "MAY": 4.47,
    "JUN": 4.42,
    "JUL": 4.56,
    "AUG": 5.13,
    "SEP": 5.01,
    "OCT": 4.75,
    "NOV": 4.26,
    "DEC": 4.76
  },
  {
    "LONG": -41.88,
    "LAT": -2.03,
    "CIDADE": "Martins Soares",
    "UF": "MINAS GERAIS",
    "anual": 4.83,
    "JAN": 5.08,
    "FEB": 5.59,
    "MAR": 4.99,
    "APR": 4.92,
    "MAY": 4.49,
    "JUN": 4.41,
    "JUL": 4.53,
    "AUG": 5.12,
    "SEP": 5,
    "OCT": 4.71,
    "NOV": 4.34,
    "DEC": 4.82
  },
  {
    "LONG": -46.36,
    "LAT": -20.25,
    "CIDADE": "São Roque de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 4.93,
    "FEB": 5.54,
    "MAR": 4.9,
    "APR": 5.34,
    "MAY": 5.13,
    "JUN": 5.08,
    "JUL": 5.36,
    "AUG": 6.22,
    "SEP": 5.64,
    "OCT": 5.24,
    "NOV": 4.6,
    "DEC": 4.78
  },
  {
    "LONG": -45.71,
    "LAT": -20.18,
    "CIDADE": "Iguatama",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.25,
    "FEB": 5.74,
    "MAR": 5.2,
    "APR": 5.51,
    "MAY": 5.28,
    "JUN": 5.17,
    "JUL": 5.44,
    "AUG": 6.23,
    "SEP": 5.82,
    "OCT": 5.43,
    "NOV": 4.8,
    "DEC": 5.08
  },
  {
    "LONG": -44.77,
    "LAT": -20.19,
    "CIDADE": "Carmo do Cajuru",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.28,
    "FEB": 5.75,
    "MAR": 5.24,
    "APR": 5.46,
    "MAY": 5.13,
    "JUN": 5.12,
    "JUL": 5.34,
    "AUG": 6.04,
    "SEP": 5.77,
    "OCT": 5.47,
    "NOV": 4.89,
    "DEC": 5.05
  },
  {
    "LONG": -44.42,
    "LAT": -20.2,
    "CIDADE": "Itatiaiuçu",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.18,
    "FEB": 5.61,
    "MAR": 5.14,
    "APR": 5.29,
    "MAY": 5.06,
    "JUN": 5.08,
    "JUL": 5.31,
    "AUG": 6.03,
    "SEP": 5.79,
    "OCT": 5.37,
    "NOV": 4.8,
    "DEC": 4.93
  },
  {
    "LONG": -4.42,
    "LAT": -20.15,
    "CIDADE": "Brumadinho",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.28,
    "FEB": 5.65,
    "MAR": 5.22,
    "APR": 5.33,
    "MAY": 5.04,
    "JUN": 5,
    "JUL": 5.23,
    "AUG": 5.97,
    "SEP": 5.74,
    "OCT": 5.4,
    "NOV": 4.85,
    "DEC": 5
  },
  {
    "LONG": -4.3,
    "LAT": -20.16,
    "CIDADE": "Dom Silvério",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.04,
    "FEB": 5.61,
    "MAR": 5.03,
    "APR": 4.95,
    "MAY": 4.52,
    "JUN": 4.49,
    "JUL": 4.66,
    "AUG": 5.34,
    "SEP": 5.19,
    "OCT": 4.89,
    "NOV": 4.4,
    "DEC": 4.86
  },
  {
    "LONG": -42.9,
    "LAT": -20.24,
    "CIDADE": "Rio Doce",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.04,
    "FEB": 5.61,
    "MAR": 5.03,
    "APR": 4.95,
    "MAY": 4.52,
    "JUN": 4.49,
    "JUL": 4.66,
    "AUG": 5.34,
    "SEP": 5.19,
    "OCT": 4.89,
    "NOV": 4.4,
    "DEC": 4.86
  },
  {
    "LONG": -42.82,
    "LAT": -20.24,
    "CIDADE": "Santa Cruz do Escalvado",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.17,
    "FEB": 5.77,
    "MAR": 5.2,
    "APR": 5.11,
    "MAY": 4.58,
    "JUN": 4.51,
    "JUL": 4.77,
    "AUG": 5.4,
    "SEP": 5.28,
    "OCT": 5,
    "NOV": 4.48,
    "DEC": 4.92
  },
  {
    "LONG": -42.74,
    "LAT": -20.24,
    "CIDADE": "Piedade de Ponte Nova",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.24,
    "FEB": 5.83,
    "MAR": 5.25,
    "APR": 5.14,
    "MAY": 4.65,
    "JUN": 4.58,
    "JUL": 4.8,
    "AUG": 5.41,
    "SEP": 5.28,
    "OCT": 5.02,
    "NOV": 4.56,
    "DEC": 5.02
  },
  {
    "LONG": -42.65,
    "LAT": -2.02,
    "CIDADE": "Rio Casca",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.27,
    "FEB": 5.82,
    "MAR": 5.24,
    "APR": 5.14,
    "MAY": 4.69,
    "JUN": 4.61,
    "JUL": 4.79,
    "AUG": 5.38,
    "SEP": 5.26,
    "OCT": 5.04,
    "NOV": 4.58,
    "DEC": 5.09
  },
  {
    "LONG": -42.53,
    "LAT": -20.17,
    "CIDADE": "São Pedro dos Ferros",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 5.31,
    "FEB": 5.81,
    "MAR": 5.28,
    "APR": 5.18,
    "MAY": 4.73,
    "JUN": 4.61,
    "JUL": 4.76,
    "AUG": 5.36,
    "SEP": 5.24,
    "OCT": 5.03,
    "NOV": 4.63,
    "DEC": 5.11
  },
  {
    "LONG": -42.27,
    "LAT": -20.17,
    "CIDADE": "Caputira",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.14,
    "FEB": 5.61,
    "MAR": 5.11,
    "APR": 5.04,
    "MAY": 4.59,
    "JUN": 4.56,
    "JUL": 4.7,
    "AUG": 5.3,
    "SEP": 5.21,
    "OCT": 4.92,
    "NOV": 4.48,
    "DEC": 4.99
  },
  {
    "LONG": -41.99,
    "LAT": -20.24,
    "CIDADE": "Reduto",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.14,
    "FEB": 5.62,
    "MAR": 5.04,
    "APR": 4.97,
    "MAY": 4.53,
    "JUN": 4.44,
    "JUL": 4.59,
    "AUG": 5.12,
    "SEP": 5.02,
    "OCT": 4.77,
    "NOV": 4.37,
    "DEC": 4.82
  },
  {
    "LONG": -41.8,
    "LAT": -20.21,
    "CIDADE": "Durandé",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.24,
    "FEB": 5.69,
    "MAR": 5.14,
    "APR": 5.06,
    "MAY": 4.53,
    "JUN": 4.48,
    "JUL": 4.59,
    "AUG": 5.1,
    "SEP": 5.03,
    "OCT": 4.75,
    "NOV": 4.45,
    "DEC": 4.9
  },
  {
    "LONG": -41.62,
    "LAT": -20.15,
    "CIDADE": "Lajinha",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.28,
    "FEB": 5.71,
    "MAR": 5.15,
    "APR": 5.07,
    "MAY": 4.58,
    "JUN": 4.48,
    "JUL": 4.56,
    "AUG": 5.09,
    "SEP": 5.02,
    "OCT": 4.75,
    "NOV": 4.45,
    "DEC": 4.95
  },
  {
    "LONG": -4.87,
    "LAT": -2.01,
    "CIDADE": "Planura",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.24,
    "FEB": 5.75,
    "MAR": 5.32,
    "APR": 5.63,
    "MAY": 5.14,
    "JUN": 4.96,
    "JUL": 5.25,
    "AUG": 6,
    "SEP": 5.42,
    "OCT": 5.38,
    "NOV": 5.21,
    "DEC": 5.33
  },
  {
    "LONG": -48.11,
    "LAT": -2.01,
    "CIDADE": "Água Comprida",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.15,
    "FEB": 5.71,
    "MAR": 5.33,
    "APR": 5.67,
    "MAY": 5.2,
    "JUN": 4.93,
    "JUL": 5.24,
    "AUG": 5.92,
    "SEP": 5.36,
    "OCT": 5.38,
    "NOV": 5.25,
    "DEC": 5.32
  },
  {
    "LONG": -45.5,
    "LAT": -20.14,
    "CIDADE": "Japaraíba",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.35,
    "FEB": 5.69,
    "MAR": 5.26,
    "APR": 5.58,
    "MAY": 5.25,
    "JUN": 5.18,
    "JUL": 5.44,
    "AUG": 6.13,
    "SEP": 5.84,
    "OCT": 5.47,
    "NOV": 4.89,
    "DEC": 5.1
  },
  {
    "LONG": -45.3,
    "LAT": -20.09,
    "CIDADE": "Santo Antônio do Monte",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 5.21,
    "FEB": 5.68,
    "MAR": 5.12,
    "APR": 5.46,
    "MAY": 5.16,
    "JUN": 5.1,
    "JUL": 5.33,
    "AUG": 6.04,
    "SEP": 5.8,
    "OCT": 5.41,
    "NOV": 4.86,
    "DEC": 5.04
  },
  {
    "LONG": -44.89,
    "LAT": -20.15,
    "CIDADE": "Divinópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.32,
    "FEB": 5.75,
    "MAR": 5.3,
    "APR": 5.52,
    "MAY": 5.16,
    "JUN": 5.15,
    "JUL": 5.36,
    "AUG": 6.09,
    "SEP": 5.82,
    "OCT": 5.44,
    "NOV": 4.91,
    "DEC": 5.05
  },
  {
    "LONG": -44.58,
    "LAT": -20.08,
    "CIDADE": "Itaúna",
    "UF": "MINAS GERAIS",
    "anual": 5.34,
    "JAN": 5.2,
    "FEB": 5.68,
    "MAR": 5.18,
    "APR": 5.41,
    "MAY": 5.05,
    "JUN": 5.11,
    "JUL": 5.34,
    "AUG": 6.02,
    "SEP": 5.79,
    "OCT": 5.41,
    "NOV": 4.85,
    "DEC": 5
  },
  {
    "LONG": -44.3,
    "LAT": -20.07,
    "CIDADE": "Igarapé",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.31,
    "FEB": 5.71,
    "MAR": 5.25,
    "APR": 5.34,
    "MAY": 5.07,
    "JUN": 5.1,
    "JUL": 5.35,
    "AUG": 6.05,
    "SEP": 5.82,
    "OCT": 5.45,
    "NOV": 4.88,
    "DEC": 4.97
  },
  {
    "LONG": -44.19,
    "LAT": -20.06,
    "CIDADE": "Mário Campos",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 5.31,
    "FEB": 5.65,
    "MAR": 5.28,
    "APR": 5.35,
    "MAY": 5.05,
    "JUN": 5.05,
    "JUL": 5.26,
    "AUG": 6.02,
    "SEP": 5.83,
    "OCT": 5.44,
    "NOV": 4.88,
    "DEC": 5.07
  },
  {
    "LONG": -43.79,
    "LAT": -2.01,
    "CIDADE": "Rio Acima",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.06,
    "FEB": 5.65,
    "MAR": 5.23,
    "APR": 5.2,
    "MAY": 4.94,
    "JUN": 5,
    "JUL": 5.13,
    "AUG": 5.88,
    "SEP": 5.67,
    "OCT": 5.3,
    "NOV": 4.72,
    "DEC": 4.91
  },
  {
    "LONG": -43.41,
    "LAT": -20.07,
    "CIDADE": "Catas Altas",
    "UF": "MINAS GERAIS",
    "anual": 4.81,
    "JAN": 4.77,
    "FEB": 5.38,
    "MAR": 4.77,
    "APR": 4.74,
    "MAY": 4.45,
    "JUN": 4.54,
    "JUL": 4.71,
    "AUG": 5.41,
    "SEP": 5.24,
    "OCT": 4.87,
    "NOV": 4.23,
    "DEC": 4.62
  },
  {
    "LONG": -43.05,
    "LAT": -20.11,
    "CIDADE": "Alvinópolis",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5,
    "FEB": 5.52,
    "MAR": 4.93,
    "APR": 4.9,
    "MAY": 4.47,
    "JUN": 4.48,
    "JUL": 4.66,
    "AUG": 5.3,
    "SEP": 5.17,
    "OCT": 4.81,
    "NOV": 4.34,
    "DEC": 4.8
  },
  {
    "LONG": -42.85,
    "LAT": -20.1,
    "CIDADE": "Sem-Peixe",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.09,
    "FEB": 5.67,
    "MAR": 5.05,
    "APR": 4.98,
    "MAY": 4.49,
    "JUN": 4.43,
    "JUL": 4.66,
    "AUG": 5.27,
    "SEP": 5.17,
    "OCT": 4.88,
    "NOV": 4.37,
    "DEC": 4.81
  },
  {
    "LONG": -42.45,
    "LAT": -20.11,
    "CIDADE": "Raul Soares",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 5.38,
    "FEB": 5.79,
    "MAR": 5.28,
    "APR": 5.24,
    "MAY": 4.76,
    "JUN": 4.66,
    "JUL": 4.74,
    "AUG": 5.36,
    "SEP": 5.25,
    "OCT": 5.03,
    "NOV": 4.66,
    "DEC": 5.17
  },
  {
    "LONG": -42.01,
    "LAT": -20.13,
    "CIDADE": "Simonésia",
    "UF": "MINAS GERAIS",
    "anual": 4.66,
    "JAN": 4.86,
    "FEB": 5.38,
    "MAR": 4.71,
    "APR": 4.65,
    "MAY": 4.28,
    "JUN": 4.28,
    "JUL": 4.45,
    "AUG": 5.06,
    "SEP": 4.92,
    "OCT": 4.55,
    "NOV": 4.14,
    "DEC": 4.64
  },
  {
    "LONG": -41.93,
    "LAT": -20.1,
    "CIDADE": "Santana do Manhuaçu",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.13,
    "FEB": 5.6,
    "MAR": 5,
    "APR": 4.94,
    "MAY": 4.52,
    "JUN": 4.44,
    "JUL": 4.57,
    "AUG": 5.12,
    "SEP": 4.98,
    "OCT": 4.69,
    "NOV": 4.32,
    "DEC": 4.79
  },
  {
    "LONG": -48.94,
    "LAT": -20.03,
    "CIDADE": "Frutal",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.17,
    "FEB": 5.66,
    "MAR": 5.32,
    "APR": 5.61,
    "MAY": 5.19,
    "JUN": 5.04,
    "JUL": 5.28,
    "AUG": 6.01,
    "SEP": 5.43,
    "OCT": 5.41,
    "NOV": 5.22,
    "DEC": 5.35
  },
  {
    "LONG": -47.77,
    "LAT": -19.97,
    "CIDADE": "Delta",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.05,
    "FEB": 5.62,
    "MAR": 5.25,
    "APR": 5.64,
    "MAY": 5.24,
    "JUN": 5.15,
    "JUL": 5.4,
    "AUG": 6.1,
    "SEP": 5.49,
    "OCT": 5.43,
    "NOV": 5.11,
    "DEC": 5.19
  },
  {
    "LONG": -46.23,
    "LAT": -19.99,
    "CIDADE": "Medeiros",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 4.94,
    "FEB": 5.51,
    "MAR": 4.86,
    "APR": 5.33,
    "MAY": 5.17,
    "JUN": 5.14,
    "JUL": 5.38,
    "AUG": 6.26,
    "SEP": 5.67,
    "OCT": 5.22,
    "NOV": 4.57,
    "DEC": 4.81
  },
  {
    "LONG": -45.98,
    "LAT": -20.02,
    "CIDADE": "Bambuí",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.23,
    "FEB": 5.77,
    "MAR": 5.14,
    "APR": 5.55,
    "MAY": 5.32,
    "JUN": 5.25,
    "JUL": 5.49,
    "AUG": 6.34,
    "SEP": 5.83,
    "OCT": 5.4,
    "NOV": 4.79,
    "DEC": 5.03
  },
  {
    "LONG": -45.54,
    "LAT": -20.02,
    "CIDADE": "Lagoa da Prata",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.33,
    "FEB": 5.78,
    "MAR": 5.32,
    "APR": 5.61,
    "MAY": 5.28,
    "JUN": 5.19,
    "JUL": 5.43,
    "AUG": 6.16,
    "SEP": 5.85,
    "OCT": 5.47,
    "NOV": 4.9,
    "DEC": 5.1
  },
  {
    "LONG": -45.09,
    "LAT": -19.95,
    "CIDADE": "Perdigão",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.29,
    "FEB": 5.73,
    "MAR": 5.25,
    "APR": 5.52,
    "MAY": 5.21,
    "JUN": 5.2,
    "JUL": 5.39,
    "AUG": 6.13,
    "SEP": 5.83,
    "OCT": 5.42,
    "NOV": 4.87,
    "DEC": 5.04
  },
  {
    "LONG": -44.86,
    "LAT": -19.98,
    "CIDADE": "São Gonçalo do Pará",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.35,
    "FEB": 5.77,
    "MAR": 5.3,
    "APR": 5.48,
    "MAY": 5.14,
    "JUN": 5.2,
    "JUL": 5.39,
    "AUG": 6.11,
    "SEP": 5.81,
    "OCT": 5.45,
    "NOV": 4.92,
    "DEC": 5.06
  },
  {
    "LONG": -4.44,
    "LAT": -2,
    "CIDADE": "Mateus Leme",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 5.27,
    "FEB": 5.73,
    "MAR": 5.21,
    "APR": 5.4,
    "MAY": 5.06,
    "JUN": 5.11,
    "JUL": 5.37,
    "AUG": 6.05,
    "SEP": 5.78,
    "OCT": 5.41,
    "NOV": 4.84,
    "DEC": 4.96
  },
  {
    "LONG": -44.34,
    "LAT": -19.96,
    "CIDADE": "Juatuba",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.3,
    "FEB": 5.74,
    "MAR": 5.3,
    "APR": 5.44,
    "MAY": 5.07,
    "JUN": 5.1,
    "JUL": 5.34,
    "AUG": 6.01,
    "SEP": 5.78,
    "OCT": 5.43,
    "NOV": 4.88,
    "DEC": 4.97
  },
  {
    "LONG": -44.2,
    "LAT": -19.97,
    "CIDADE": "Betim",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.31,
    "FEB": 5.75,
    "MAR": 5.34,
    "APR": 5.44,
    "MAY": 5.09,
    "JUN": 5.12,
    "JUL": 5.36,
    "AUG": 6.03,
    "SEP": 5.84,
    "OCT": 5.46,
    "NOV": 4.91,
    "DEC": 5.02
  },
  {
    "LONG": -44.28,
    "LAT": -20.05,
    "CIDADE": "São Joaquim de Bicas",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.31,
    "FEB": 5.75,
    "MAR": 5.34,
    "APR": 5.44,
    "MAY": 5.09,
    "JUN": 5.12,
    "JUL": 5.36,
    "AUG": 6.03,
    "SEP": 5.84,
    "OCT": 5.46,
    "NOV": 4.91,
    "DEC": 5.02
  },
  {
    "LONG": -4.41,
    "LAT": -20.04,
    "CIDADE": "Sarzedo",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.25,
    "FEB": 5.72,
    "MAR": 5.31,
    "APR": 5.4,
    "MAY": 5.09,
    "JUN": 5.09,
    "JUL": 5.34,
    "AUG": 6.02,
    "SEP": 5.86,
    "OCT": 5.47,
    "NOV": 4.88,
    "DEC": 5.02
  },
  {
    "LONG": -44.06,
    "LAT": -20.03,
    "CIDADE": "Ibirité",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.13,
    "FEB": 5.64,
    "MAR": 5.23,
    "APR": 5.3,
    "MAY": 5.04,
    "JUN": 5.02,
    "JUL": 5.28,
    "AUG": 5.99,
    "SEP": 5.88,
    "OCT": 5.48,
    "NOV": 4.85,
    "DEC": 4.99
  },
  {
    "LONG": -4.38,
    "LAT": -2,
    "CIDADE": "Raposos",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.12,
    "FEB": 5.64,
    "MAR": 5.2,
    "APR": 5.24,
    "MAY": 4.89,
    "JUN": 4.99,
    "JUL": 5.2,
    "AUG": 5.88,
    "SEP": 5.68,
    "OCT": 5.33,
    "NOV": 4.74,
    "DEC": 4.91
  },
  {
    "LONG": -43.85,
    "LAT": -19.98,
    "CIDADE": "Nova Lima",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.12,
    "FEB": 5.64,
    "MAR": 5.2,
    "APR": 5.24,
    "MAY": 4.89,
    "JUN": 4.99,
    "JUL": 5.2,
    "AUG": 5.88,
    "SEP": 5.68,
    "OCT": 5.33,
    "NOV": 4.74,
    "DEC": 4.91
  },
  {
    "LONG": -43.41,
    "LAT": -19.96,
    "CIDADE": "Santa Bárbara",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 4.92,
    "FEB": 5.54,
    "MAR": 4.97,
    "APR": 4.93,
    "MAY": 4.58,
    "JUN": 4.56,
    "JUL": 4.75,
    "AUG": 5.41,
    "SEP": 5.23,
    "OCT": 4.91,
    "NOV": 4.42,
    "DEC": 4.72
  },
  {
    "LONG": -42.27,
    "LAT": -20.04,
    "CIDADE": "Vermelho Novo",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.22,
    "FEB": 5.68,
    "MAR": 5.07,
    "APR": 5,
    "MAY": 4.55,
    "JUN": 4.55,
    "JUL": 4.61,
    "AUG": 5.21,
    "SEP": 5.19,
    "OCT": 4.9,
    "NOV": 4.48,
    "DEC": 5.03
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2,
    "CIDADE": "Santa Bárbara do Leste",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.12,
    "FEB": 5.54,
    "MAR": 4.92,
    "APR": 4.86,
    "MAY": 4.43,
    "JUN": 4.46,
    "JUL": 4.53,
    "AUG": 5.17,
    "SEP": 5.17,
    "OCT": 4.81,
    "NOV": 4.38,
    "DEC": 4.91
  },
  {
    "LONG": -41.75,
    "LAT": -20.01,
    "CIDADE": "São José do Mantimento",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.25,
    "FEB": 5.73,
    "MAR": 5.16,
    "APR": 5.12,
    "MAY": 4.56,
    "JUN": 4.49,
    "JUL": 4.52,
    "AUG": 5.05,
    "SEP": 5,
    "OCT": 4.76,
    "NOV": 4.45,
    "DEC": 4.97
  },
  {
    "LONG": -41.69,
    "LAT": -20.05,
    "CIDADE": "Chalé",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.27,
    "FEB": 5.79,
    "MAR": 5.23,
    "APR": 5.18,
    "MAY": 4.62,
    "JUN": 4.49,
    "JUL": 4.55,
    "AUG": 5.08,
    "SEP": 5,
    "OCT": 4.8,
    "NOV": 4.5,
    "DEC": 5.01
  },
  {
    "LONG": -49.77,
    "LAT": -19.86,
    "CIDADE": "São Francisco de Sales",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.21,
    "FEB": 5.59,
    "MAR": 5.38,
    "APR": 5.6,
    "MAY": 5.12,
    "JUN": 4.98,
    "JUL": 5.22,
    "AUG": 5.91,
    "SEP": 5.41,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.42
  },
  {
    "LONG": -49.37,
    "LAT": -19.9,
    "CIDADE": "Itapagipe",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.16,
    "FEB": 5.63,
    "MAR": 5.34,
    "APR": 5.62,
    "MAY": 5.18,
    "JUN": 5.01,
    "JUL": 5.28,
    "AUG": 5.99,
    "SEP": 5.42,
    "OCT": 5.42,
    "NOV": 5.32,
    "DEC": 5.43
  },
  {
    "LONG": -48.7,
    "LAT": -19.91,
    "CIDADE": "Pirajuba",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.16,
    "FEB": 5.71,
    "MAR": 5.28,
    "APR": 5.6,
    "MAY": 5.19,
    "JUN": 5.09,
    "JUL": 5.32,
    "AUG": 6.09,
    "SEP": 5.47,
    "OCT": 5.35,
    "NOV": 5.18,
    "DEC": 5.35
  },
  {
    "LONG": -48.38,
    "LAT": -19.92,
    "CIDADE": "Conceição das Alagoas",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.18,
    "FEB": 5.66,
    "MAR": 5.23,
    "APR": 5.57,
    "MAY": 5.27,
    "JUN": 5.16,
    "JUL": 5.39,
    "AUG": 6.12,
    "SEP": 5.48,
    "OCT": 5.38,
    "NOV": 5.18,
    "DEC": 5.3
  },
  {
    "LONG": -47.54,
    "LAT": -19.94,
    "CIDADE": "Conquista",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 4.96,
    "FEB": 5.56,
    "MAR": 5.07,
    "APR": 5.5,
    "MAY": 5.3,
    "JUN": 5.2,
    "JUL": 5.45,
    "AUG": 6.18,
    "SEP": 5.53,
    "OCT": 5.36,
    "NOV": 4.95,
    "DEC": 5.15
  },
  {
    "LONG": -47.44,
    "LAT": "#VALOR!",
    "CIDADE": "Sacramento",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 4.93,
    "FEB": 5.53,
    "MAR": 5.09,
    "APR": 5.48,
    "MAY": 5.31,
    "JUN": 5.21,
    "JUL": 5.51,
    "AUG": 6.15,
    "SEP": 5.56,
    "OCT": 5.39,
    "NOV": 4.99,
    "DEC": 5.14
  },
  {
    "LONG": -46.82,
    "LAT": -19.92,
    "CIDADE": "Tapira",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 4.92,
    "FEB": 5.44,
    "MAR": 4.94,
    "APR": 5.35,
    "MAY": 5.26,
    "JUN": 5.17,
    "JUL": 5.4,
    "AUG": 6.27,
    "SEP": 5.65,
    "OCT": 5.36,
    "NOV": 4.79,
    "DEC": 4.92
  },
  {
    "LONG": -46.02,
    "LAT": -1.99,
    "CIDADE": "Tapiraí",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.18,
    "FEB": 5.72,
    "MAR": 5.07,
    "APR": 5.53,
    "MAY": 5.3,
    "JUN": 5.27,
    "JUL": 5.5,
    "AUG": 6.34,
    "SEP": 5.79,
    "OCT": 5.35,
    "NOV": 4.7,
    "DEC": 4.93
  },
  {
    "LONG": -45.17,
    "LAT": -19.95,
    "CIDADE": "Araújos",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.28,
    "FEB": 5.75,
    "MAR": 5.26,
    "APR": 5.57,
    "MAY": 5.23,
    "JUN": 5.24,
    "JUL": 5.4,
    "AUG": 6.15,
    "SEP": 5.85,
    "OCT": 5.46,
    "NOV": 4.86,
    "DEC": 5.08
  },
  {
    "LONG": -4.5,
    "LAT": -19.87,
    "CIDADE": "Nova Serrana",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.25,
    "FEB": 5.75,
    "MAR": 5.31,
    "APR": 5.48,
    "MAY": 5.17,
    "JUN": 5.12,
    "JUL": 5.34,
    "AUG": 6.06,
    "SEP": 5.81,
    "OCT": 5.46,
    "NOV": 4.92,
    "DEC": 5.05
  },
  {
    "LONG": -44.71,
    "LAT": -19.95,
    "CIDADE": "Igaratinga",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.31,
    "FEB": 5.79,
    "MAR": 5.32,
    "APR": 5.49,
    "MAY": 5.17,
    "JUN": 5.16,
    "JUL": 5.38,
    "AUG": 6.08,
    "SEP": 5.83,
    "OCT": 5.45,
    "NOV": 4.96,
    "DEC": 5.05
  },
  {
    "LONG": -44.61,
    "LAT": -19.85,
    "CIDADE": "Pará de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.23,
    "FEB": 5.77,
    "MAR": 5.23,
    "APR": 5.5,
    "MAY": 5.14,
    "JUN": 5.15,
    "JUL": 5.38,
    "AUG": 6.02,
    "SEP": 5.84,
    "OCT": 5.41,
    "NOV": 4.92,
    "DEC": 5.02
  },
  {
    "LONG": -44.43,
    "LAT": -19.89,
    "CIDADE": "Florestal",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.32,
    "FEB": 5.77,
    "MAR": 5.27,
    "APR": 5.44,
    "MAY": 5.15,
    "JUN": 5.16,
    "JUL": 5.38,
    "AUG": 6.07,
    "SEP": 5.83,
    "OCT": 5.44,
    "NOV": 4.89,
    "DEC": 5.03
  },
  {
    "LONG": -44.05,
    "LAT": -19.93,
    "CIDADE": "Contagem",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.24,
    "FEB": 5.72,
    "MAR": 5.32,
    "APR": 5.39,
    "MAY": 5.03,
    "JUN": 5.04,
    "JUL": 5.28,
    "AUG": 5.97,
    "SEP": 5.88,
    "OCT": 5.51,
    "NOV": 4.89,
    "DEC": 5.03
  },
  {
    "LONG": -43.93,
    "LAT": -19.91,
    "CIDADE": "Belo Horizonte",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 5.25,
    "FEB": 5.71,
    "MAR": 5.3,
    "APR": 5.33,
    "MAY": 4.99,
    "JUN": 5.05,
    "JUL": 5.25,
    "AUG": 5.95,
    "SEP": 5.87,
    "OCT": 5.52,
    "NOV": 4.94,
    "DEC": 5.04
  },
  {
    "LONG": -43.83,
    "LAT": -19.88,
    "CIDADE": "Sabará",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.26,
    "FEB": 5.7,
    "MAR": 5.27,
    "APR": 5.32,
    "MAY": 4.94,
    "JUN": 5.03,
    "JUL": 5.23,
    "AUG": 5.88,
    "SEP": 5.8,
    "OCT": 5.44,
    "NOV": 4.88,
    "DEC": 5.04
  },
  {
    "LONG": -43.67,
    "LAT": -19.88,
    "CIDADE": "Caeté",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.08,
    "FEB": 5.63,
    "MAR": 5.2,
    "APR": 5.18,
    "MAY": 4.89,
    "JUN": 4.9,
    "JUL": 5.08,
    "AUG": 5.78,
    "SEP": 5.62,
    "OCT": 5.29,
    "NOV": 4.72,
    "DEC": 4.96
  },
  {
    "LONG": -43.48,
    "LAT": -19.94,
    "CIDADE": "Barão de Cocais",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 4.95,
    "FEB": 5.46,
    "MAR": 4.88,
    "APR": 4.84,
    "MAY": 4.55,
    "JUN": 4.52,
    "JUL": 4.73,
    "AUG": 5.43,
    "SEP": 5.23,
    "OCT": 4.88,
    "NOV": 4.44,
    "DEC": 4.74
  },
  {
    "LONG": -4.32,
    "LAT": -1.99,
    "CIDADE": "Rio Piracicaba",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 4.95,
    "FEB": 5.52,
    "MAR": 4.92,
    "APR": 4.86,
    "MAY": 4.48,
    "JUN": 4.47,
    "JUL": 4.65,
    "AUG": 5.28,
    "SEP": 5.17,
    "OCT": 4.83,
    "NOV": 4.38,
    "DEC": 4.81
  },
  {
    "LONG": -42.97,
    "LAT": -19.87,
    "CIDADE": "São Domingos do Prata",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.05,
    "FEB": 5.65,
    "MAR": 5.03,
    "APR": 4.93,
    "MAY": 4.5,
    "JUN": 4.4,
    "JUL": 4.65,
    "AUG": 5.29,
    "SEP": 5.19,
    "OCT": 4.89,
    "NOV": 4.38,
    "DEC": 4.91
  },
  {
    "LONG": -42.7,
    "LAT": -19.92,
    "CIDADE": "São José do Goiabal",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.16,
    "FEB": 5.75,
    "MAR": 5.17,
    "APR": 5.12,
    "MAY": 4.6,
    "JUN": 4.54,
    "JUL": 4.75,
    "AUG": 5.27,
    "SEP": 5.19,
    "OCT": 4.95,
    "NOV": 4.44,
    "DEC": 4.92
  },
  {
    "LONG": -42.13,
    "LAT": -19.87,
    "CIDADE": "Santa Rita de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.02,
    "APR": 4.93,
    "MAY": 4.5,
    "JUN": 4.44,
    "JUL": 4.55,
    "AUG": 5.13,
    "SEP": 5.16,
    "OCT": 4.82,
    "NOV": 4.42,
    "DEC": 4.99
  },
  {
    "LONG": -41.69,
    "LAT": -19.93,
    "CIDADE": "Conceição de Ipanema",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.33,
    "FEB": 5.85,
    "MAR": 5.27,
    "APR": 5.21,
    "MAY": 4.65,
    "JUN": 4.5,
    "JUL": 4.58,
    "AUG": 5.09,
    "SEP": 5.01,
    "OCT": 4.81,
    "NOV": 4.54,
    "DEC": 5.05
  },
  {
    "LONG": -4.86,
    "LAT": -19.76,
    "CIDADE": "Campo Florido",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.12,
    "FEB": 5.65,
    "MAR": 5.25,
    "APR": 5.61,
    "MAY": 5.26,
    "JUN": 5.11,
    "JUL": 5.37,
    "AUG": 6.1,
    "SEP": 5.49,
    "OCT": 5.37,
    "NOV": 5.18,
    "DEC": 5.27
  },
  {
    "LONG": -46.38,
    "LAT": -19.75,
    "CIDADE": "Pratinha",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.02,
    "FEB": 5.51,
    "MAR": 4.92,
    "APR": 5.27,
    "MAY": 5.19,
    "JUN": 5.19,
    "JUL": 5.41,
    "AUG": 6.35,
    "SEP": 5.79,
    "OCT": 5.32,
    "NOV": 4.66,
    "DEC": 4.94
  },
  {
    "LONG": -45.9,
    "LAT": -19.82,
    "CIDADE": "Córrego Danta",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.14,
    "FEB": 5.67,
    "MAR": 5.04,
    "APR": 5.48,
    "MAY": 5.29,
    "JUN": 5.26,
    "JUL": 5.51,
    "AUG": 6.37,
    "SEP": 5.8,
    "OCT": 5.28,
    "NOV": 4.67,
    "DEC": 4.86
  },
  {
    "LONG": -45.68,
    "LAT": -19.79,
    "CIDADE": "Luz",
    "UF": "MINAS GERAIS",
    "anual": 5.47,
    "JAN": 5.31,
    "FEB": 5.83,
    "MAR": 5.26,
    "APR": 5.59,
    "MAY": 5.35,
    "JUN": 5.25,
    "JUL": 5.52,
    "AUG": 6.25,
    "SEP": 5.86,
    "OCT": 5.47,
    "NOV": 4.9,
    "DEC": 5.07
  },
  {
    "LONG": -45.41,
    "LAT": -19.84,
    "CIDADE": "Moema",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.33,
    "FEB": 5.87,
    "MAR": 5.28,
    "APR": 5.62,
    "MAY": 5.34,
    "JUN": 5.24,
    "JUL": 5.47,
    "AUG": 6.2,
    "SEP": 5.84,
    "OCT": 5.49,
    "NOV": 4.97,
    "DEC": 5.1
  },
  {
    "LONG": -4.43,
    "LAT": -1.98,
    "CIDADE": "Esmeraldas",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.35,
    "FEB": 5.8,
    "MAR": 5.33,
    "APR": 5.49,
    "MAY": 5.17,
    "JUN": 5.16,
    "JUL": 5.38,
    "AUG": 6.04,
    "SEP": 5.88,
    "OCT": 5.48,
    "NOV": 4.94,
    "DEC": 5.1
  },
  {
    "LONG": -44.08,
    "LAT": -19.76,
    "CIDADE": "Ribeirão das Neves",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.27,
    "FEB": 5.76,
    "MAR": 5.33,
    "APR": 5.41,
    "MAY": 5.08,
    "JUN": 5.03,
    "JUL": 5.29,
    "AUG": 5.97,
    "SEP": 5.87,
    "OCT": 5.49,
    "NOV": 4.94,
    "DEC": 5.01
  },
  {
    "LONG": "#VALOR!",
    "LAT": -19.76,
    "CIDADE": "Santa Luzia",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.32,
    "FEB": 5.82,
    "MAR": 5.46,
    "APR": 5.45,
    "MAY": 5.05,
    "JUN": 5.08,
    "JUL": 5.33,
    "AUG": 5.98,
    "SEP": 5.87,
    "OCT": 5.51,
    "NOV": 4.98,
    "DEC": 5.11
  },
  {
    "LONG": -43.37,
    "LAT": -19.82,
    "CIDADE": "São Gonçalo do Rio Abaixo",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 5.02,
    "APR": 4.97,
    "MAY": 4.56,
    "JUN": 4.55,
    "JUL": 4.79,
    "AUG": 5.44,
    "SEP": 5.29,
    "OCT": 4.98,
    "NOV": 4.48,
    "DEC": 4.86
  },
  {
    "LONG": -43.17,
    "LAT": -19.81,
    "CIDADE": "João Monlevade",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 4.99,
    "FEB": 5.54,
    "MAR": 5,
    "APR": 4.93,
    "MAY": 4.52,
    "JUN": 4.53,
    "JUL": 4.73,
    "AUG": 5.38,
    "SEP": 5.23,
    "OCT": 4.9,
    "NOV": 4.39,
    "DEC": 4.83
  },
  {
    "LONG": -43.03,
    "LAT": -1.98,
    "CIDADE": "Nova Era",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.12,
    "FEB": 5.67,
    "MAR": 5.1,
    "APR": 4.99,
    "MAY": 4.55,
    "JUN": 4.45,
    "JUL": 4.7,
    "AUG": 5.34,
    "SEP": 5.24,
    "OCT": 4.93,
    "NOV": 4.41,
    "DEC": 4.91
  },
  {
    "LONG": -43.09,
    "LAT": -19.83,
    "CIDADE": "Bela Vista de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.12,
    "FEB": 5.67,
    "MAR": 5.1,
    "APR": 4.99,
    "MAY": 4.55,
    "JUN": 4.45,
    "JUL": 4.7,
    "AUG": 5.34,
    "SEP": 5.24,
    "OCT": 4.93,
    "NOV": 4.41,
    "DEC": 4.91
  },
  {
    "LONG": -4.28,
    "LAT": -1.98,
    "CIDADE": "Dionísio",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.05,
    "FEB": 5.64,
    "MAR": 4.99,
    "APR": 5,
    "MAY": 4.5,
    "JUN": 4.44,
    "JUL": 4.64,
    "AUG": 5.17,
    "SEP": 5.06,
    "OCT": 4.85,
    "NOV": 4.35,
    "DEC": 4.86
  },
  {
    "LONG": -42.4,
    "LAT": -19.84,
    "CIDADE": "Córrego Novo",
    "UF": "MINAS GERAIS",
    "anual": 5.15,
    "JAN": 5.37,
    "FEB": 5.93,
    "MAR": 5.34,
    "APR": 5.28,
    "MAY": 4.79,
    "JUN": 4.71,
    "JUL": 4.82,
    "AUG": 5.36,
    "SEP": 5.27,
    "OCT": 5.06,
    "NOV": 4.67,
    "DEC": 5.22
  },
  {
    "LONG": -4.23,
    "LAT": -19.84,
    "CIDADE": "Bom Jesus do Galho",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.25,
    "FEB": 5.74,
    "MAR": 5.16,
    "APR": 5.13,
    "MAY": 4.68,
    "JUN": 4.59,
    "JUL": 4.68,
    "AUG": 5.22,
    "SEP": 5.18,
    "OCT": 4.95,
    "NOV": 4.58,
    "DEC": 5.14
  },
  {
    "LONG": -42.13,
    "LAT": -19.79,
    "CIDADE": "Caratinga",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.13,
    "FEB": 5.62,
    "MAR": 5.01,
    "APR": 4.93,
    "MAY": 4.5,
    "JUN": 4.41,
    "JUL": 4.53,
    "AUG": 5.1,
    "SEP": 5.09,
    "OCT": 4.84,
    "NOV": 4.44,
    "DEC": 4.98
  },
  {
    "LONG": -42.08,
    "LAT": -19.76,
    "CIDADE": "Piedade de Caratinga",
    "UF": "MINAS GERAIS",
    "anual": 4.79,
    "JAN": 4.98,
    "FEB": 5.51,
    "MAR": 4.96,
    "APR": 4.8,
    "MAY": 4.4,
    "JUN": 4.34,
    "JUL": 4.47,
    "AUG": 5.02,
    "SEP": 5.02,
    "OCT": 4.77,
    "NOV": 4.33,
    "DEC": 4.89
  },
  {
    "LONG": -41.72,
    "LAT": -19.8,
    "CIDADE": "Ipanema",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.25,
    "FEB": 5.76,
    "MAR": 5.16,
    "APR": 5.17,
    "MAY": 4.56,
    "JUN": 4.41,
    "JUL": 4.53,
    "AUG": 5.04,
    "SEP": 4.94,
    "OCT": 4.73,
    "NOV": 4.41,
    "DEC": 4.95
  },
  {
    "LONG": -41.61,
    "LAT": -19.76,
    "CIDADE": "Taparuba",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.36,
    "FEB": 5.91,
    "MAR": 5.3,
    "APR": 5.25,
    "MAY": 4.61,
    "JUN": 4.48,
    "JUL": 4.54,
    "AUG": 5.1,
    "SEP": 5.05,
    "OCT": 4.85,
    "NOV": 4.55,
    "DEC": 5.1
  },
  {
    "LONG": -4.14,
    "LAT": -19.81,
    "CIDADE": "Mutum",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.43,
    "FEB": 5.94,
    "MAR": 5.33,
    "APR": 5.27,
    "MAY": 4.67,
    "JUN": 4.53,
    "JUL": 4.55,
    "AUG": 5.05,
    "SEP": 5.02,
    "OCT": 4.83,
    "NOV": 4.59,
    "DEC": 5.08
  },
  {
    "LONG": -50.69,
    "LAT": -19.7,
    "CIDADE": "Carneirinho",
    "UF": "MINAS GERAIS",
    "anual": 5.44,
    "JAN": 5.23,
    "FEB": 5.67,
    "MAR": 5.52,
    "APR": 5.59,
    "MAY": 5.18,
    "JUN": 5.08,
    "JUL": 5.24,
    "AUG": 5.96,
    "SEP": 5.44,
    "OCT": 5.52,
    "NOV": 5.35,
    "DEC": 5.47
  },
  {
    "LONG": -5.02,
    "LAT": -1.97,
    "CIDADE": "Iturama",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.2,
    "FEB": 5.6,
    "MAR": 5.45,
    "APR": 5.6,
    "MAY": 5.19,
    "JUN": 5.02,
    "JUL": 5.31,
    "AUG": 5.94,
    "SEP": 5.48,
    "OCT": 5.5,
    "NOV": 5.34,
    "DEC": 5.41
  },
  {
    "LONG": -49.08,
    "LAT": -1.97,
    "CIDADE": "Comendador Gomes",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.16,
    "FEB": 5.59,
    "MAR": 5.34,
    "APR": 5.65,
    "MAY": 5.24,
    "JUN": 5.11,
    "JUL": 5.37,
    "AUG": 6.08,
    "SEP": 5.47,
    "OCT": 5.42,
    "NOV": 5.28,
    "DEC": 5.35
  },
  {
    "LONG": -4.83,
    "LAT": -1.97,
    "CIDADE": "Veríssimo",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.09,
    "FEB": 5.6,
    "MAR": 5.31,
    "APR": 5.61,
    "MAY": 5.3,
    "JUN": 5.19,
    "JUL": 5.42,
    "AUG": 6.17,
    "SEP": 5.49,
    "OCT": 5.4,
    "NOV": 5.18,
    "DEC": 5.24
  },
  {
    "LONG": -47.94,
    "LAT": -19.75,
    "CIDADE": "Uberaba",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.05,
    "FEB": 5.55,
    "MAR": 5.16,
    "APR": 5.54,
    "MAY": 5.29,
    "JUN": 5.17,
    "JUL": 5.46,
    "AUG": 6.2,
    "SEP": 5.51,
    "OCT": 5.4,
    "NOV": 5.06,
    "DEC": 5.17
  },
  {
    "LONG": -4.62,
    "LAT": -19.69,
    "CIDADE": "Campos Altos",
    "UF": "MINAS GERAIS",
    "anual": 5.27,
    "JAN": 5.01,
    "FEB": 5.49,
    "MAR": 4.87,
    "APR": 5.29,
    "MAY": 5.23,
    "JUN": 5.2,
    "JUL": 5.44,
    "AUG": 6.33,
    "SEP": 5.78,
    "OCT": 5.21,
    "NOV": 4.55,
    "DEC": 4.81
  },
  {
    "LONG": -45.25,
    "LAT": -19.73,
    "CIDADE": "Bom Despacho",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.32,
    "FEB": 5.85,
    "MAR": 5.34,
    "APR": 5.55,
    "MAY": 5.32,
    "JUN": 5.27,
    "JUL": 5.53,
    "AUG": 6.22,
    "SEP": 5.87,
    "OCT": 5.48,
    "NOV": 4.96,
    "DEC": 5.11
  },
  {
    "LONG": -45.02,
    "LAT": -19.72,
    "CIDADE": "Leandro Ferreira",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.31,
    "FEB": 5.81,
    "MAR": 5.35,
    "APR": 5.55,
    "MAY": 5.27,
    "JUN": 5.24,
    "JUL": 5.51,
    "AUG": 6.17,
    "SEP": 5.89,
    "OCT": 5.47,
    "NOV": 4.91,
    "DEC": 5.1
  },
  {
    "LONG": -44.81,
    "LAT": -1.97,
    "CIDADE": "Onça de Pitangui",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.29,
    "APR": 5.47,
    "MAY": 5.21,
    "JUN": 5.2,
    "JUL": 5.47,
    "AUG": 6.14,
    "SEP": 5.88,
    "OCT": 5.45,
    "NOV": 4.9,
    "DEC": 5.07
  },
  {
    "LONG": "#VALOR!",
    "LAT": -1.97,
    "CIDADE": "Pitangui",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.29,
    "APR": 5.47,
    "MAY": 5.21,
    "JUN": 5.2,
    "JUL": 5.47,
    "AUG": 6.14,
    "SEP": 5.88,
    "OCT": 5.45,
    "NOV": 4.9,
    "DEC": 5.07
  },
  {
    "LONG": -44.9,
    "LAT": -19.75,
    "CIDADE": "Conceição do Pará",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.29,
    "APR": 5.47,
    "MAY": 5.21,
    "JUN": 5.2,
    "JUL": 5.47,
    "AUG": 6.14,
    "SEP": 5.88,
    "OCT": 5.45,
    "NOV": 4.9,
    "DEC": 5.07
  },
  {
    "LONG": -44.56,
    "LAT": -1.97,
    "CIDADE": "São José da Varginha",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.41,
    "FEB": 5.78,
    "MAR": 5.32,
    "APR": 5.5,
    "MAY": 5.18,
    "JUN": 5.16,
    "JUL": 5.44,
    "AUG": 6.1,
    "SEP": 5.9,
    "OCT": 5.49,
    "NOV": 4.99,
    "DEC": 5.1
  },
  {
    "LONG": -43.92,
    "LAT": -19.69,
    "CIDADE": "Vespasiano",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.79,
    "MAR": 5.44,
    "APR": 5.46,
    "MAY": 5.14,
    "JUN": 5.07,
    "JUL": 5.35,
    "AUG": 6,
    "SEP": 5.89,
    "OCT": 5.48,
    "NOV": 5.02,
    "DEC": 5.1
  },
  {
    "LONG": -4.4,
    "LAT": -19.7,
    "CIDADE": "São José da Lapa",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.79,
    "MAR": 5.44,
    "APR": 5.46,
    "MAY": 5.14,
    "JUN": 5.07,
    "JUL": 5.35,
    "AUG": 6,
    "SEP": 5.89,
    "OCT": 5.48,
    "NOV": 5.02,
    "DEC": 5.1
  },
  {
    "LONG": -43.69,
    "LAT": -19.67,
    "CIDADE": "Taquaraçu de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.29,
    "FEB": 5.85,
    "MAR": 5.41,
    "APR": 5.38,
    "MAY": 5.1,
    "JUN": 5.1,
    "JUL": 5.32,
    "AUG": 5.95,
    "SEP": 5.85,
    "OCT": 5.54,
    "NOV": 4.96,
    "DEC": 5.1
  },
  {
    "LONG": -43.58,
    "LAT": -19.69,
    "CIDADE": "Nova União",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.18,
    "FEB": 5.72,
    "MAR": 5.23,
    "APR": 5.17,
    "MAY": 4.91,
    "JUN": 4.92,
    "JUL": 5.15,
    "AUG": 5.84,
    "SEP": 5.64,
    "OCT": 5.35,
    "NOV": 4.75,
    "DEC": 4.96
  },
  {
    "LONG": -43.47,
    "LAT": -19.71,
    "CIDADE": "Bom Jesus do Amparo",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.08,
    "FEB": 5.65,
    "MAR": 5.09,
    "APR": 5.06,
    "MAY": 4.64,
    "JUN": 4.66,
    "JUL": 4.95,
    "AUG": 5.59,
    "SEP": 5.42,
    "OCT": 5.09,
    "NOV": 4.55,
    "DEC": 4.89
  },
  {
    "LONG": -42.88,
    "LAT": -19.65,
    "CIDADE": "Antônio Dias",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.15,
    "FEB": 5.71,
    "MAR": 5.04,
    "APR": 5.02,
    "MAY": 4.56,
    "JUN": 4.5,
    "JUL": 4.67,
    "AUG": 5.26,
    "SEP": 5.21,
    "OCT": 4.96,
    "NOV": 4.35,
    "DEC": 4.95
  },
  {
    "LONG": -42.73,
    "LAT": -19.71,
    "CIDADE": "Marliéria",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.15,
    "FEB": 5.7,
    "MAR": 5.07,
    "APR": 5.03,
    "MAY": 4.51,
    "JUN": 4.46,
    "JUL": 4.66,
    "AUG": 5.2,
    "SEP": 5.13,
    "OCT": 4.93,
    "NOV": 4.38,
    "DEC": 4.96
  },
  {
    "LONG": -42.41,
    "LAT": -19.73,
    "CIDADE": "Pingo-d'Água",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.39,
    "FEB": 5.94,
    "MAR": 5.38,
    "APR": 5.26,
    "MAY": 4.76,
    "JUN": 4.68,
    "JUL": 4.83,
    "AUG": 5.33,
    "SEP": 5.25,
    "OCT": 5.04,
    "NOV": 4.63,
    "DEC": 5.19
  },
  {
    "LONG": -50.58,
    "LAT": -19.55,
    "CIDADE": "Limeira do Oeste",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.26,
    "FEB": 5.66,
    "MAR": 5.48,
    "APR": 5.54,
    "MAY": 5.22,
    "JUN": 5.1,
    "JUL": 5.3,
    "AUG": 5.96,
    "SEP": 5.47,
    "OCT": 5.52,
    "NOV": 5.38,
    "DEC": 5.48
  },
  {
    "LONG": -46.94,
    "LAT": -1.96,
    "CIDADE": "Araxá",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5,
    "FEB": 5.64,
    "MAR": 5.05,
    "APR": 5.46,
    "MAY": 5.36,
    "JUN": 5.28,
    "JUL": 5.48,
    "AUG": 6.36,
    "SEP": 5.78,
    "OCT": 5.5,
    "NOV": 4.93,
    "DEC": 5.09
  },
  {
    "LONG": -44.66,
    "LAT": -19.63,
    "CIDADE": "Pequi",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.39,
    "FEB": 5.86,
    "MAR": 5.34,
    "APR": 5.51,
    "MAY": 5.2,
    "JUN": 5.12,
    "JUL": 5.45,
    "AUG": 6.13,
    "SEP": 5.93,
    "OCT": 5.48,
    "NOV": 4.96,
    "DEC": 5.1
  },
  {
    "LONG": -4.44,
    "LAT": -1.96,
    "CIDADE": "Fortuna de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.36,
    "FEB": 5.75,
    "MAR": 5.35,
    "APR": 5.49,
    "MAY": 5.2,
    "JUN": 5.13,
    "JUL": 5.43,
    "AUG": 6.13,
    "SEP": 5.93,
    "OCT": 5.5,
    "NOV": 5,
    "DEC": 5.11
  },
  {
    "LONG": -4.4,
    "LAT": -1.96,
    "CIDADE": "Pedro Leopoldo",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.34,
    "FEB": 5.75,
    "MAR": 5.37,
    "APR": 5.46,
    "MAY": 5.18,
    "JUN": 5.07,
    "JUL": 5.41,
    "AUG": 6.01,
    "SEP": 5.94,
    "OCT": 5.5,
    "NOV": 5,
    "DEC": 5.07
  },
  {
    "LONG": -44.09,
    "LAT": -19.55,
    "CIDADE": "Matozinhos",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.34,
    "FEB": 5.75,
    "MAR": 5.37,
    "APR": 5.46,
    "MAY": 5.18,
    "JUN": 5.07,
    "JUL": 5.41,
    "AUG": 6.01,
    "SEP": 5.94,
    "OCT": 5.5,
    "NOV": 5,
    "DEC": 5.07
  },
  {
    "LONG": -43.99,
    "LAT": -19.63,
    "CIDADE": "Confins",
    "UF": "MINAS GERAIS",
    "anual": 5.44,
    "JAN": 5.34,
    "FEB": 5.81,
    "MAR": 5.44,
    "APR": 5.48,
    "MAY": 5.2,
    "JUN": 5.1,
    "JUL": 5.39,
    "AUG": 5.99,
    "SEP": 5.92,
    "OCT": 5.49,
    "NOV": 5.03,
    "DEC": 5.12
  },
  {
    "LONG": "#VALOR!",
    "LAT": -1.96,
    "CIDADE": "Lagoa Santa",
    "UF": "MINAS GERAIS",
    "anual": 5.47,
    "JAN": 5.35,
    "FEB": 5.85,
    "MAR": 5.48,
    "APR": 5.53,
    "MAY": 5.22,
    "JUN": 5.14,
    "JUL": 5.42,
    "AUG": 6.01,
    "SEP": 5.92,
    "OCT": 5.53,
    "NOV": 5.07,
    "DEC": 5.18
  },
  {
    "LONG": -43.23,
    "LAT": -19.62,
    "CIDADE": "Itabira",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.01,
    "FEB": 5.56,
    "MAR": 4.94,
    "APR": 4.9,
    "MAY": 4.63,
    "JUN": 4.61,
    "JUL": 4.77,
    "AUG": 5.41,
    "SEP": 5.28,
    "OCT": 4.95,
    "NOV": 4.42,
    "DEC": 4.9
  },
  {
    "LONG": -42.75,
    "LAT": -19.65,
    "CIDADE": "Jaguaraçu",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.27,
    "FEB": 5.81,
    "MAR": 5.2,
    "APR": 5.14,
    "MAY": 4.6,
    "JUN": 4.55,
    "JUL": 4.74,
    "AUG": 5.32,
    "SEP": 5.21,
    "OCT": 5,
    "NOV": 4.44,
    "DEC": 4.99
  },
  {
    "LONG": -4.26,
    "LAT": -19.58,
    "CIDADE": "Timóteo",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.24,
    "FEB": 5.77,
    "MAR": 5.15,
    "APR": 5.08,
    "MAY": 4.5,
    "JUN": 4.51,
    "JUL": 4.68,
    "AUG": 5.22,
    "SEP": 5.17,
    "OCT": 4.95,
    "NOV": 4.48,
    "DEC": 4.98
  },
  {
    "LONG": -42.3,
    "LAT": -19.6,
    "CIDADE": "Vargem Alegre",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.25,
    "FEB": 5.65,
    "MAR": 5.14,
    "APR": 4.97,
    "MAY": 4.5,
    "JUN": 4.4,
    "JUL": 4.53,
    "AUG": 5.07,
    "SEP": 5.08,
    "OCT": 4.84,
    "NOV": 4.44,
    "DEC": 5.02
  },
  {
    "LONG": -42.23,
    "LAT": -19.62,
    "CIDADE": "Entre Folhas",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.25,
    "FEB": 5.65,
    "MAR": 5.14,
    "APR": 4.97,
    "MAY": 4.5,
    "JUN": 4.4,
    "JUL": 4.53,
    "AUG": 5.07,
    "SEP": 5.08,
    "OCT": 4.84,
    "NOV": 4.44,
    "DEC": 5.02
  },
  {
    "LONG": -42.11,
    "LAT": -19.64,
    "CIDADE": "Ubaporanga",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.26,
    "FEB": 5.66,
    "MAR": 5.15,
    "APR": 4.97,
    "MAY": 4.44,
    "JUN": 4.38,
    "JUL": 4.52,
    "AUG": 5.06,
    "SEP": 5.07,
    "OCT": 4.83,
    "NOV": 4.44,
    "DEC": 4.99
  },
  {
    "LONG": -41.97,
    "LAT": -19.6,
    "CIDADE": "Imbé de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.83,
    "JAN": 5.18,
    "FEB": 5.61,
    "MAR": 5.03,
    "APR": 4.98,
    "MAY": 4.39,
    "JUN": 4.31,
    "JUL": 4.47,
    "AUG": 4.98,
    "SEP": 4.97,
    "OCT": 4.75,
    "NOV": 4.39,
    "DEC": 4.93
  },
  {
    "LONG": -41.63,
    "LAT": -19.62,
    "CIDADE": "Pocrane",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.34,
    "FEB": 5.88,
    "MAR": 5.33,
    "APR": 5.16,
    "MAY": 4.59,
    "JUN": 4.44,
    "JUL": 4.56,
    "AUG": 5.07,
    "SEP": 5.09,
    "OCT": 4.88,
    "NOV": 4.58,
    "DEC": 5.07
  },
  {
    "LONG": -50.34,
    "LAT": "#VALOR!",
    "CIDADE": "União de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.26,
    "FEB": 5.63,
    "MAR": 5.46,
    "APR": 5.58,
    "MAY": 5.25,
    "JUN": 5.1,
    "JUL": 5.32,
    "AUG": 5.99,
    "SEP": 5.48,
    "OCT": 5.5,
    "NOV": 5.38,
    "DEC": 5.44
  },
  {
    "LONG": -49.49,
    "LAT": -19.53,
    "CIDADE": "Campina Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.2,
    "FEB": 5.52,
    "MAR": 5.33,
    "APR": 5.59,
    "MAY": 5.26,
    "JUN": 5.09,
    "JUL": 5.37,
    "AUG": 6.09,
    "SEP": 5.54,
    "OCT": 5.42,
    "NOV": 5.32,
    "DEC": 5.35
  },
  {
    "LONG": -46.55,
    "LAT": -1.95,
    "CIDADE": "Ibiá",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.03,
    "FEB": 5.6,
    "MAR": 5.04,
    "APR": 5.41,
    "MAY": 5.3,
    "JUN": 5.24,
    "JUL": 5.44,
    "AUG": 6.37,
    "SEP": 5.76,
    "OCT": 5.39,
    "NOV": 4.83,
    "DEC": 5.05
  },
  {
    "LONG": -45.97,
    "LAT": -19.53,
    "CIDADE": "Santa Rosa da Serra",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.13,
    "FEB": 5.71,
    "MAR": 5.03,
    "APR": 5.43,
    "MAY": 5.32,
    "JUN": 5.29,
    "JUL": 5.55,
    "AUG": 6.4,
    "SEP": 5.85,
    "OCT": 5.32,
    "NOV": 4.65,
    "DEC": 4.86
  },
  {
    "LONG": "#VALOR!",
    "LAT": -19.52,
    "CIDADE": "Estrela do Indaiá",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.28,
    "FEB": 5.83,
    "MAR": 5.24,
    "APR": 5.53,
    "MAY": 5.45,
    "JUN": 5.35,
    "JUL": 5.56,
    "AUG": 6.34,
    "SEP": 5.9,
    "OCT": 5.48,
    "NOV": 4.83,
    "DEC": 5.06
  },
  {
    "LONG": -45.6,
    "LAT": -19.46,
    "CIDADE": "Dores do Indaiá",
    "UF": "MINAS GERAIS",
    "anual": 5.52,
    "JAN": 5.31,
    "FEB": 5.87,
    "MAR": 5.27,
    "APR": 5.55,
    "MAY": 5.46,
    "JUN": 5.37,
    "JUL": 5.57,
    "AUG": 6.38,
    "SEP": 5.94,
    "OCT": 5.51,
    "NOV": 4.88,
    "DEC": 5.09
  },
  {
    "LONG": -4.47,
    "LAT": -1.95,
    "CIDADE": "Maravilhas",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.43,
    "FEB": 5.79,
    "MAR": 5.38,
    "APR": 5.55,
    "MAY": 5.29,
    "JUN": 5.2,
    "JUL": 5.49,
    "AUG": 6.17,
    "SEP": 5.94,
    "OCT": 5.5,
    "NOV": 5,
    "DEC": 5.14
  },
  {
    "LONG": -4.45,
    "LAT": -1.95,
    "CIDADE": "Cachoeira da Prata",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.44,
    "FEB": 5.84,
    "MAR": 5.38,
    "APR": 5.5,
    "MAY": 5.25,
    "JUN": 5.15,
    "JUL": 5.48,
    "AUG": 6.14,
    "SEP": 5.95,
    "OCT": 5.49,
    "NOV": 4.98,
    "DEC": 5.13
  },
  {
    "LONG": -44.39,
    "LAT": -19.49,
    "CIDADE": "Inhaúma",
    "UF": "MINAS GERAIS",
    "anual": 5.47,
    "JAN": 5.4,
    "FEB": 5.85,
    "MAR": 5.34,
    "APR": 5.51,
    "MAY": 5.23,
    "JUN": 5.16,
    "JUL": 5.49,
    "AUG": 6.13,
    "SEP": 5.98,
    "OCT": 5.51,
    "NOV": 4.99,
    "DEC": 5.11
  },
  {
    "LONG": -44.24,
    "LAT": -19.46,
    "CIDADE": "Sete Lagoas",
    "UF": "MINAS GERAIS",
    "anual": 5.44,
    "JAN": 5.37,
    "FEB": 5.82,
    "MAR": 5.33,
    "APR": 5.47,
    "MAY": 5.2,
    "JUN": 5.1,
    "JUL": 5.45,
    "AUG": 6.07,
    "SEP": 5.96,
    "OCT": 5.51,
    "NOV": 4.99,
    "DEC": 5.07
  },
  {
    "LONG": -44.16,
    "LAT": -19.47,
    "CIDADE": "Prudente de Morais",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.39,
    "FEB": 5.86,
    "MAR": 5.39,
    "APR": 5.49,
    "MAY": 5.2,
    "JUN": 5.11,
    "JUL": 5.42,
    "AUG": 6.08,
    "SEP": 5.97,
    "OCT": 5.53,
    "NOV": 5.02,
    "DEC": 5.08
  },
  {
    "LONG": -44.13,
    "LAT": -19.55,
    "CIDADE": "Capim Branco",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.39,
    "FEB": 5.86,
    "MAR": 5.39,
    "APR": 5.49,
    "MAY": 5.2,
    "JUN": 5.11,
    "JUL": 5.42,
    "AUG": 6.08,
    "SEP": 5.97,
    "OCT": 5.53,
    "NOV": 5.02,
    "DEC": 5.08
  },
  {
    "LONG": -4.37,
    "LAT": -1.95,
    "CIDADE": "Jaboticatubas",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.37,
    "FEB": 5.81,
    "MAR": 5.45,
    "APR": 5.45,
    "MAY": 5.25,
    "JUN": 5.12,
    "JUL": 5.43,
    "AUG": 6.08,
    "SEP": 6.01,
    "OCT": 5.6,
    "NOV": 5.06,
    "DEC": 5.16
  },
  {
    "LONG": -42.63,
    "LAT": -19.52,
    "CIDADE": "Coronel Fabriciano",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.01,
    "FEB": 5.62,
    "MAR": 4.98,
    "APR": 4.92,
    "MAY": 4.43,
    "JUN": 4.44,
    "JUL": 4.56,
    "AUG": 5.09,
    "SEP": 5.05,
    "OCT": 4.83,
    "NOV": 4.27,
    "DEC": 4.85
  },
  {
    "LONG": -4.25,
    "LAT": -19.47,
    "CIDADE": "Ipatinga",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.25,
    "FEB": 5.87,
    "MAR": 5.22,
    "APR": 5.12,
    "MAY": 4.57,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.2,
    "SEP": 5.21,
    "OCT": 4.99,
    "NOV": 4.47,
    "DEC": 5.03
  },
  {
    "LONG": -42.12,
    "LAT": -19.55,
    "CIDADE": "Inhapim",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.22,
    "FEB": 5.73,
    "MAR": 5.17,
    "APR": 4.94,
    "MAY": 4.49,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 4.99,
    "SEP": 5.02,
    "OCT": 4.83,
    "NOV": 4.43,
    "DEC": 5.09
  },
  {
    "LONG": -42.01,
    "LAT": -19.53,
    "CIDADE": "São Domingos das Dores",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.22,
    "FEB": 5.72,
    "MAR": 5.1,
    "APR": 4.84,
    "MAY": 4.4,
    "JUN": 4.31,
    "JUL": 4.43,
    "AUG": 4.96,
    "SEP": 5.03,
    "OCT": 4.82,
    "NOV": 4.44,
    "DEC": 5.06
  },
  {
    "LONG": -41.98,
    "LAT": -19.5,
    "CIDADE": "São Sebastião do Anta",
    "UF": "MINAS GERAIS",
    "anual": 4.83,
    "JAN": 5.17,
    "FEB": 5.68,
    "MAR": 5.09,
    "APR": 4.89,
    "MAY": 4.35,
    "JUN": 4.27,
    "JUL": 4.4,
    "AUG": 4.91,
    "SEP": 4.99,
    "OCT": 4.77,
    "NOV": 4.41,
    "DEC": 5.02
  },
  {
    "LONG": -4.11,
    "LAT": -19.5,
    "CIDADE": "Aimorés",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.62,
    "FEB": 6.04,
    "MAR": 5.49,
    "APR": 5.25,
    "MAY": 4.75,
    "JUN": 4.5,
    "JUL": 4.59,
    "AUG": 5.09,
    "SEP": 5.15,
    "OCT": 4.96,
    "NOV": 4.71,
    "DEC": 5.28
  },
  {
    "LONG": "#VALOR!",
    "LAT": -19.44,
    "CIDADE": "Serra da Saudade",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.15,
    "FEB": 5.78,
    "MAR": 5.08,
    "APR": 5.49,
    "MAY": 5.4,
    "JUN": 5.26,
    "JUL": 5.56,
    "AUG": 6.41,
    "SEP": 5.89,
    "OCT": 5.39,
    "NOV": 4.74,
    "DEC": 4.93
  },
  {
    "LONG": -44.75,
    "LAT": -19.44,
    "CIDADE": "Papagaios",
    "UF": "MINAS GERAIS",
    "anual": 5.52,
    "JAN": 5.4,
    "FEB": 5.88,
    "MAR": 5.38,
    "APR": 5.61,
    "MAY": 5.35,
    "JUN": 5.2,
    "JUL": 5.55,
    "AUG": 6.18,
    "SEP": 5.98,
    "OCT": 5.51,
    "NOV": 4.98,
    "DEC": 5.2
  },
  {
    "LONG": -44.06,
    "LAT": -19.37,
    "CIDADE": "Funilândia",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.42,
    "FEB": 5.85,
    "MAR": 5.5,
    "APR": 5.52,
    "MAY": 5.25,
    "JUN": 5.12,
    "JUL": 5.47,
    "AUG": 6.08,
    "SEP": 6.02,
    "OCT": 5.57,
    "NOV": 5.06,
    "DEC": 5.23
  },
  {
    "LONG": -43.32,
    "LAT": -19.42,
    "CIDADE": "Itambé do Mato Dentro",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 4.98,
    "FEB": 5.5,
    "MAR": 4.95,
    "APR": 4.83,
    "MAY": 4.5,
    "JUN": 4.51,
    "JUL": 4.68,
    "AUG": 5.36,
    "SEP": 5.24,
    "OCT": 4.88,
    "NOV": 4.33,
    "DEC": 4.81
  },
  {
    "LONG": -43.14,
    "LAT": -19.35,
    "CIDADE": "Passabém",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.21,
    "FEB": 5.72,
    "MAR": 5.16,
    "APR": 4.94,
    "MAY": 4.57,
    "JUN": 4.49,
    "JUL": 4.66,
    "AUG": 5.36,
    "SEP": 5.28,
    "OCT": 5.03,
    "NOV": 4.5,
    "DEC": 5.05
  },
  {
    "LONG": -4.31,
    "LAT": -1.94,
    "CIDADE": "Santa Maria de Itabira",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.21,
    "FEB": 5.72,
    "MAR": 5.16,
    "APR": 4.94,
    "MAY": 4.57,
    "JUN": 4.49,
    "JUL": 4.66,
    "AUG": 5.36,
    "SEP": 5.28,
    "OCT": 5.03,
    "NOV": 4.5,
    "DEC": 5.05
  },
  {
    "LONG": -42.54,
    "LAT": -19.37,
    "CIDADE": "Santana do Paraíso",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.21,
    "FEB": 5.75,
    "MAR": 5.12,
    "APR": 5,
    "MAY": 4.48,
    "JUN": 4.42,
    "JUL": 4.6,
    "AUG": 5.12,
    "SEP": 5.1,
    "OCT": 4.88,
    "NOV": 4.4,
    "DEC": 4.96
  },
  {
    "LONG": -4.24,
    "LAT": -1.94,
    "CIDADE": "Ipaba",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.41,
    "FEB": 5.94,
    "MAR": 5.31,
    "APR": 5.23,
    "MAY": 4.66,
    "JUN": 4.56,
    "JUL": 4.73,
    "AUG": 5.27,
    "SEP": 5.23,
    "OCT": 5.01,
    "NOV": 4.56,
    "DEC": 5.1
  },
  {
    "LONG": -42.22,
    "LAT": -19.44,
    "CIDADE": "Iapu",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.33,
    "FEB": 5.78,
    "MAR": 5.26,
    "APR": 5.09,
    "MAY": 4.59,
    "JUN": 4.44,
    "JUL": 4.55,
    "AUG": 5.07,
    "SEP": 5.1,
    "OCT": 4.93,
    "NOV": 4.51,
    "DEC": 5.17
  },
  {
    "LONG": -42.26,
    "LAT": -19.42,
    "CIDADE": "Bugre",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.33,
    "FEB": 5.78,
    "MAR": 5.26,
    "APR": 5.09,
    "MAY": 4.59,
    "JUN": 4.44,
    "JUL": 4.55,
    "AUG": 5.07,
    "SEP": 5.1,
    "OCT": 4.93,
    "NOV": 4.51,
    "DEC": 5.17
  },
  {
    "LONG": -42.11,
    "LAT": -19.37,
    "CIDADE": "Dom Cavati",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.28,
    "FEB": 5.74,
    "MAR": 5.25,
    "APR": 5.05,
    "MAY": 4.57,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.05,
    "SEP": 5.08,
    "OCT": 4.9,
    "NOV": 4.48,
    "DEC": 5.14
  },
  {
    "LONG": -41.73,
    "LAT": -19.42,
    "CIDADE": "Alvarenga",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5.19,
    "FEB": 5.61,
    "MAR": 5.03,
    "APR": 4.91,
    "MAY": 4.39,
    "JUN": 4.33,
    "JUL": 4.36,
    "AUG": 4.9,
    "SEP": 5.01,
    "OCT": 4.75,
    "NOV": 4.43,
    "DEC": 4.96
  },
  {
    "LONG": -41.38,
    "LAT": -19.36,
    "CIDADE": "Santa Rita do Itueto",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.29,
    "FEB": 5.71,
    "MAR": 5.18,
    "APR": 4.95,
    "MAY": 4.46,
    "JUN": 4.37,
    "JUL": 4.4,
    "AUG": 4.92,
    "SEP": 5.01,
    "OCT": 4.79,
    "NOV": 4.44,
    "DEC": 5.01
  },
  {
    "LONG": -4.12,
    "LAT": -19.4,
    "CIDADE": "Itueta",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.56,
    "FEB": 6.02,
    "MAR": 5.48,
    "APR": 5.19,
    "MAY": 4.71,
    "JUN": 4.48,
    "JUL": 4.53,
    "AUG": 4.95,
    "SEP": 5.11,
    "OCT": 4.89,
    "NOV": 4.65,
    "DEC": 5.21
  },
  {
    "LONG": -4.89,
    "LAT": -19.31,
    "CIDADE": "Prata",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.11,
    "FEB": 5.56,
    "MAR": 5.26,
    "APR": 5.63,
    "MAY": 5.31,
    "JUN": 5.18,
    "JUL": 5.42,
    "AUG": 6.18,
    "SEP": 5.5,
    "OCT": 5.44,
    "NOV": 5.23,
    "DEC": 5.28
  },
  {
    "LONG": -47.53,
    "LAT": -1.93,
    "CIDADE": "Santa Juliana",
    "UF": "MINAS GERAIS",
    "anual": 5.44,
    "JAN": 5.06,
    "FEB": 5.68,
    "MAR": 5.12,
    "APR": 5.51,
    "MAY": 5.35,
    "JUN": 5.32,
    "JUL": 5.52,
    "AUG": 6.33,
    "SEP": 5.75,
    "OCT": 5.48,
    "NOV": 5.03,
    "DEC": 5.16
  },
  {
    "LONG": -47.29,
    "LAT": -19.35,
    "CIDADE": "Perdizes",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.07,
    "FEB": 5.7,
    "MAR": 5.15,
    "APR": 5.48,
    "MAY": 5.41,
    "JUN": 5.35,
    "JUL": 5.55,
    "AUG": 6.36,
    "SEP": 5.78,
    "OCT": 5.46,
    "NOV": 5.02,
    "DEC": 5.13
  },
  {
    "LONG": -46.05,
    "LAT": -19.31,
    "CIDADE": "São Gotardo",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.12,
    "FEB": 5.71,
    "MAR": 4.99,
    "APR": 5.35,
    "MAY": 5.3,
    "JUN": 5.24,
    "JUL": 5.51,
    "AUG": 6.34,
    "SEP": 5.83,
    "OCT": 5.34,
    "NOV": 4.66,
    "DEC": 4.88
  },
  {
    "LONG": -45.56,
    "LAT": -19.27,
    "CIDADE": "Quartel Geral",
    "UF": "MINAS GERAIS",
    "anual": 5.54,
    "JAN": 5.35,
    "FEB": 5.9,
    "MAR": 5.34,
    "APR": 5.6,
    "MAY": 5.46,
    "JUN": 5.34,
    "JUL": 5.59,
    "AUG": 6.4,
    "SEP": 5.98,
    "OCT": 5.56,
    "NOV": 4.92,
    "DEC": 5.1
  },
  {
    "LONG": -45.24,
    "LAT": -19.32,
    "CIDADE": "Martinho Campos",
    "UF": "MINAS GERAIS",
    "anual": 5.54,
    "JAN": 5.34,
    "FEB": 5.85,
    "MAR": 5.36,
    "APR": 5.62,
    "MAY": 5.4,
    "JUN": 5.33,
    "JUL": 5.57,
    "AUG": 6.27,
    "SEP": 5.99,
    "OCT": 5.54,
    "NOV": 5.03,
    "DEC": 5.16
  },
  {
    "LONG": -44.4,
    "LAT": -19.27,
    "CIDADE": "Paraopeba",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.44,
    "FEB": 5.89,
    "MAR": 5.41,
    "APR": 5.6,
    "MAY": 5.28,
    "JUN": 5.15,
    "JUL": 5.52,
    "AUG": 6.13,
    "SEP": 6.01,
    "OCT": 5.54,
    "NOV": 5.03,
    "DEC": 5.17
  },
  {
    "LONG": -44.42,
    "LAT": -19.3,
    "CIDADE": "Caetanópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.44,
    "FEB": 5.89,
    "MAR": 5.41,
    "APR": 5.6,
    "MAY": 5.28,
    "JUN": 5.15,
    "JUL": 5.52,
    "AUG": 6.13,
    "SEP": 6.01,
    "OCT": 5.54,
    "NOV": 5.03,
    "DEC": 5.17
  },
  {
    "LONG": -4.4,
    "LAT": -1.93,
    "CIDADE": "Baldim",
    "UF": "MINAS GERAIS",
    "anual": 5.54,
    "JAN": 5.38,
    "FEB": 5.95,
    "MAR": 5.52,
    "APR": 5.57,
    "MAY": 5.31,
    "JUN": 5.15,
    "JUL": 5.51,
    "AUG": 6.12,
    "SEP": 6.05,
    "OCT": 5.62,
    "NOV": 5.08,
    "DEC": 5.23
  },
  {
    "LONG": -43.17,
    "LAT": -1.93,
    "CIDADE": "São Sebastião do Rio Preto",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.3,
    "FEB": 5.8,
    "MAR": 5.27,
    "APR": 5.09,
    "MAY": 4.58,
    "JUN": 4.49,
    "JUL": 4.71,
    "AUG": 5.4,
    "SEP": 5.32,
    "OCT": 5.08,
    "NOV": 4.56,
    "DEC": 5.09
  },
  {
    "LONG": -4.22,
    "LAT": -1.93,
    "CIDADE": "São João do Oriente",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.33,
    "FEB": 5.78,
    "MAR": 5.28,
    "APR": 5.1,
    "MAY": 4.6,
    "JUN": 4.45,
    "JUL": 4.5,
    "AUG": 5.04,
    "SEP": 5.1,
    "OCT": 4.93,
    "NOV": 4.54,
    "DEC": 5.2
  },
  {
    "LONG": -42.01,
    "LAT": -19.28,
    "CIDADE": "Tarumirim",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.27,
    "FEB": 5.77,
    "MAR": 5.19,
    "APR": 4.99,
    "MAY": 4.49,
    "JUN": 4.37,
    "JUL": 4.42,
    "AUG": 4.97,
    "SEP": 5.07,
    "OCT": 4.88,
    "NOV": 4.47,
    "DEC": 5.14
  },
  {
    "LONG": -41.25,
    "LAT": -19.32,
    "CIDADE": "Resplendor",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.55,
    "FEB": 6,
    "MAR": 5.51,
    "APR": 5.18,
    "MAY": 4.65,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 4.98,
    "SEP": 5.09,
    "OCT": 4.89,
    "NOV": 4.62,
    "DEC": 5.21
  },
  {
    "LONG": -4.98,
    "LAT": -19.21,
    "CIDADE": "Gurinhatã",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.24,
    "FEB": 5.52,
    "MAR": 5.38,
    "APR": 5.6,
    "MAY": 5.33,
    "JUN": 5.18,
    "JUL": 5.41,
    "AUG": 6.11,
    "SEP": 5.54,
    "OCT": 5.45,
    "NOV": 5.39,
    "DEC": 5.34
  },
  {
    "LONG": -4.77,
    "LAT": -19.17,
    "CIDADE": "Nova Ponte",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.01,
    "FEB": 5.62,
    "MAR": 5.11,
    "APR": 5.57,
    "MAY": 5.36,
    "JUN": 5.28,
    "JUL": 5.43,
    "AUG": 6.22,
    "SEP": 5.65,
    "OCT": 5.47,
    "NOV": 5.03,
    "DEC": 5.15
  },
  {
    "LONG": -47.46,
    "LAT": -19.23,
    "CIDADE": "Pedrinópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.47,
    "JAN": 5.1,
    "FEB": 5.76,
    "MAR": 5.21,
    "APR": 5.63,
    "MAY": 5.43,
    "JUN": 5.33,
    "JUL": 5.49,
    "AUG": 6.25,
    "SEP": 5.68,
    "OCT": 5.51,
    "NOV": 5.1,
    "DEC": 5.17
  },
  {
    "LONG": -46.25,
    "LAT": -19.19,
    "CIDADE": "Rio Paranaíba",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.14,
    "FEB": 5.67,
    "MAR": 5.1,
    "APR": 5.53,
    "MAY": 5.41,
    "JUN": 5.28,
    "JUL": 5.53,
    "AUG": 6.36,
    "SEP": 5.84,
    "OCT": 5.42,
    "NOV": 4.82,
    "DEC": 5
  },
  {
    "LONG": -45.97,
    "LAT": -19.22,
    "CIDADE": "Matutina",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.19,
    "FEB": 5.75,
    "MAR": 5.09,
    "APR": 5.46,
    "MAY": 5.37,
    "JUN": 5.3,
    "JUL": 5.58,
    "AUG": 6.41,
    "SEP": 5.92,
    "OCT": 5.38,
    "NOV": 4.68,
    "DEC": 4.94
  },
  {
    "LONG": -45.46,
    "LAT": -19.15,
    "CIDADE": "Abaeté",
    "UF": "MINAS GERAIS",
    "anual": 5.58,
    "JAN": 5.42,
    "FEB": 5.88,
    "MAR": 5.39,
    "APR": 5.64,
    "MAY": 5.47,
    "JUN": 5.42,
    "JUL": 5.61,
    "AUG": 6.34,
    "SEP": 6.03,
    "OCT": 5.58,
    "NOV": 4.98,
    "DEC": 5.18
  },
  {
    "LONG": -45.01,
    "LAT": -19.23,
    "CIDADE": "Pompéu",
    "UF": "MINAS GERAIS",
    "anual": 5.58,
    "JAN": 5.46,
    "FEB": 5.91,
    "MAR": 5.42,
    "APR": 5.67,
    "MAY": 5.42,
    "JUN": 5.31,
    "JUL": 5.64,
    "AUG": 6.22,
    "SEP": 6.03,
    "OCT": 5.57,
    "NOV": 5.04,
    "DEC": 5.22
  },
  {
    "LONG": -44.25,
    "LAT": -1.92,
    "CIDADE": "Araçaí",
    "UF": "MINAS GERAIS",
    "anual": 5.54,
    "JAN": 5.42,
    "FEB": 5.95,
    "MAR": 5.49,
    "APR": 5.59,
    "MAY": 5.3,
    "JUN": 5.19,
    "JUL": 5.53,
    "AUG": 6.12,
    "SEP": 6.03,
    "OCT": 5.59,
    "NOV": 5.05,
    "DEC": 5.23
  },
  {
    "LONG": -4.4,
    "LAT": -1.92,
    "CIDADE": "Jequitibá",
    "UF": "MINAS GERAIS",
    "anual": 5.59,
    "JAN": 5.48,
    "FEB": 5.99,
    "MAR": 5.59,
    "APR": 5.63,
    "MAY": 5.38,
    "JUN": 5.2,
    "JUL": 5.58,
    "AUG": 6.16,
    "SEP": 6.05,
    "OCT": 5.65,
    "NOV": 5.09,
    "DEC": 5.23
  },
  {
    "LONG": -4.37,
    "LAT": -1.92,
    "CIDADE": "Santana do Riacho",
    "UF": "MINAS GERAIS",
    "anual": 5.55,
    "JAN": 5.45,
    "FEB": 5.95,
    "MAR": 5.49,
    "APR": 5.49,
    "MAY": 5.3,
    "JUN": 5.22,
    "JUL": 5.55,
    "AUG": 6.1,
    "SEP": 6.07,
    "OCT": 5.67,
    "NOV": 5.04,
    "DEC": 5.22
  },
  {
    "LONG": -43.38,
    "LAT": -19.22,
    "CIDADE": "Morro do Pilar",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.11,
    "FEB": 5.64,
    "MAR": 5.06,
    "APR": 4.99,
    "MAY": 4.55,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.36,
    "SEP": 5.26,
    "OCT": 4.97,
    "NOV": 4.42,
    "DEC": 4.91
  },
  {
    "LONG": -4.33,
    "LAT": -1.92,
    "CIDADE": "Santo Antônio do Rio Abaixo",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.1,
    "FEB": 5.6,
    "MAR": 5.04,
    "APR": 4.98,
    "MAY": 4.52,
    "JUN": 4.45,
    "JUL": 4.65,
    "AUG": 5.35,
    "SEP": 5.24,
    "OCT": 4.98,
    "NOV": 4.45,
    "DEC": 4.93
  },
  {
    "LONG": -43.02,
    "LAT": -19.23,
    "CIDADE": "Ferros",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.22,
    "FEB": 5.75,
    "MAR": 5.16,
    "APR": 5.01,
    "MAY": 4.54,
    "JUN": 4.41,
    "JUL": 4.66,
    "AUG": 5.28,
    "SEP": 5.24,
    "OCT": 5.03,
    "NOV": 4.47,
    "DEC": 4.94
  },
  {
    "LONG": -42.61,
    "LAT": -19.22,
    "CIDADE": "Mesquita",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.39,
    "FEB": 5.91,
    "MAR": 5.32,
    "APR": 5.17,
    "MAY": 4.65,
    "JUN": 4.53,
    "JUL": 4.67,
    "AUG": 5.23,
    "SEP": 5.2,
    "OCT": 5.04,
    "NOV": 4.6,
    "DEC": 5.16
  },
  {
    "LONG": -42.68,
    "LAT": -19.17,
    "CIDADE": "Joanésia",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.39,
    "FEB": 5.91,
    "MAR": 5.32,
    "APR": 5.17,
    "MAY": 4.65,
    "JUN": 4.53,
    "JUL": 4.67,
    "AUG": 5.23,
    "SEP": 5.2,
    "OCT": 5.04,
    "NOV": 4.6,
    "DEC": 5.16
  },
  {
    "LONG": -42.48,
    "LAT": -19.22,
    "CIDADE": "Belo Oriente",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.34,
    "FEB": 5.91,
    "MAR": 5.34,
    "APR": 5.19,
    "MAY": 4.61,
    "JUN": 4.55,
    "JUL": 4.66,
    "AUG": 5.18,
    "SEP": 5.21,
    "OCT": 4.99,
    "NOV": 4.52,
    "DEC": 5.12
  },
  {
    "LONG": -42.33,
    "LAT": -19.23,
    "CIDADE": "Naque",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.37,
    "FEB": 5.91,
    "MAR": 5.33,
    "APR": 5.17,
    "MAY": 4.62,
    "JUN": 4.57,
    "JUL": 4.66,
    "AUG": 5.17,
    "SEP": 5.21,
    "OCT": 4.99,
    "NOV": 4.56,
    "DEC": 5.11
  },
  {
    "LONG": -4.22,
    "LAT": -19.16,
    "CIDADE": "Periquito",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.42,
    "FEB": 5.92,
    "MAR": 5.38,
    "APR": 5.2,
    "MAY": 4.64,
    "JUN": 4.54,
    "JUL": 4.62,
    "AUG": 5.15,
    "SEP": 5.18,
    "OCT": 5.02,
    "NOV": 4.59,
    "DEC": 5.21
  },
  {
    "LONG": -42.05,
    "LAT": -1.92,
    "CIDADE": "Engenheiro Caldas",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.39,
    "FEB": 5.83,
    "MAR": 5.25,
    "APR": 5.04,
    "MAY": 4.54,
    "JUN": 4.42,
    "JUL": 4.44,
    "AUG": 4.99,
    "SEP": 5.11,
    "OCT": 4.97,
    "NOV": 4.54,
    "DEC": 5.17
  },
  {
    "LONG": -42.08,
    "LAT": -19.15,
    "CIDADE": "Fernandes Tourinho",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.39,
    "FEB": 5.83,
    "MAR": 5.25,
    "APR": 5.04,
    "MAY": 4.54,
    "JUN": 4.42,
    "JUL": 4.44,
    "AUG": 4.99,
    "SEP": 5.11,
    "OCT": 4.97,
    "NOV": 4.54,
    "DEC": 5.17
  },
  {
    "LONG": -4.21,
    "LAT": -1.92,
    "CIDADE": "Sobrália",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.39,
    "FEB": 5.83,
    "MAR": 5.25,
    "APR": 5.04,
    "MAY": 4.54,
    "JUN": 4.42,
    "JUL": 4.44,
    "AUG": 4.99,
    "SEP": 5.11,
    "OCT": 4.97,
    "NOV": 4.54,
    "DEC": 5.17
  },
  {
    "LONG": -41.86,
    "LAT": -19.17,
    "CIDADE": "Itanhomi",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.4,
    "FEB": 5.8,
    "MAR": 5.28,
    "APR": 5.01,
    "MAY": 4.44,
    "JUN": 4.33,
    "JUL": 4.42,
    "AUG": 5.01,
    "SEP": 5.13,
    "OCT": 4.97,
    "NOV": 4.56,
    "DEC": 5.18
  },
  {
    "LONG": -41.47,
    "LAT": -19.18,
    "CIDADE": "Conselheiro Pena",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.64,
    "FEB": 6.03,
    "MAR": 5.54,
    "APR": 5.25,
    "MAY": 4.71,
    "JUN": 4.53,
    "JUL": 4.58,
    "AUG": 5.06,
    "SEP": 5.21,
    "OCT": 5.07,
    "NOV": 4.7,
    "DEC": 5.31
  },
  {
    "LONG": -46.68,
    "LAT": -19.11,
    "CIDADE": "Serra do Salitre",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.1,
    "FEB": 5.67,
    "MAR": 5.07,
    "APR": 5.52,
    "MAY": 5.46,
    "JUN": 5.34,
    "JUL": 5.62,
    "AUG": 6.41,
    "SEP": 5.85,
    "OCT": 5.43,
    "NOV": 4.87,
    "DEC": 5.01
  },
  {
    "LONG": -45.71,
    "LAT": -19.15,
    "CIDADE": "Cedro do Abaeté",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.27,
    "FEB": 5.78,
    "MAR": 5.23,
    "APR": 5.59,
    "MAY": 5.42,
    "JUN": 5.39,
    "JUL": 5.73,
    "AUG": 6.46,
    "SEP": 5.98,
    "OCT": 5.46,
    "NOV": 4.81,
    "DEC": 5
  },
  {
    "LONG": -44.32,
    "LAT": -19.12,
    "CIDADE": "Cordisburgo",
    "UF": "MINAS GERAIS",
    "anual": 5.55,
    "JAN": 5.42,
    "FEB": 5.95,
    "MAR": 5.48,
    "APR": 5.63,
    "MAY": 5.32,
    "JUN": 5.21,
    "JUL": 5.55,
    "AUG": 6.05,
    "SEP": 6.05,
    "OCT": 5.61,
    "NOV": 5.05,
    "DEC": 5.22
  },
  {
    "LONG": -43.14,
    "LAT": -19.09,
    "CIDADE": "Carmésia",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.1,
    "FEB": 5.63,
    "MAR": 5.04,
    "APR": 4.93,
    "MAY": 4.52,
    "JUN": 4.43,
    "JUL": 4.6,
    "AUG": 5.3,
    "SEP": 5.26,
    "OCT": 4.99,
    "NOV": 4.44,
    "DEC": 4.92
  },
  {
    "LONG": -42.93,
    "LAT": -19.05,
    "CIDADE": "Dores de Guanhães",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.23,
    "FEB": 5.71,
    "MAR": 5.08,
    "APR": 4.89,
    "MAY": 4.51,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.2,
    "SEP": 5.24,
    "OCT": 4.98,
    "NOV": 4.46,
    "DEC": 4.93
  },
  {
    "LONG": -42.71,
    "LAT": -19.06,
    "CIDADE": "Braúnas",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.29,
    "FEB": 5.78,
    "MAR": 5.23,
    "APR": 5.12,
    "MAY": 4.61,
    "JUN": 4.53,
    "JUL": 4.67,
    "AUG": 5.2,
    "SEP": 5.19,
    "OCT": 5.01,
    "NOV": 4.5,
    "DEC": 5.02
  },
  {
    "LONG": -42.55,
    "LAT": -19.07,
    "CIDADE": "Açucena",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.17,
    "FEB": 5.65,
    "MAR": 5.07,
    "APR": 4.97,
    "MAY": 4.44,
    "JUN": 4.42,
    "JUL": 4.52,
    "AUG": 5.04,
    "SEP": 5.11,
    "OCT": 4.87,
    "NOV": 4.34,
    "DEC": 4.93
  },
  {
    "LONG": -41.86,
    "LAT": -19.08,
    "CIDADE": "Capitão Andrade",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.44,
    "FEB": 5.84,
    "MAR": 5.36,
    "APR": 5.1,
    "MAY": 4.49,
    "JUN": 4.4,
    "JUL": 4.46,
    "AUG": 5.02,
    "SEP": 5.14,
    "OCT": 4.99,
    "NOV": 4.59,
    "DEC": 5.2
  },
  {
    "LONG": -49.46,
    "LAT": -18.98,
    "CIDADE": "Ituiutaba",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.2,
    "FEB": 5.46,
    "MAR": 5.36,
    "APR": 5.56,
    "MAY": 5.3,
    "JUN": 5.26,
    "JUL": 5.44,
    "AUG": 6.21,
    "SEP": 5.58,
    "OCT": 5.46,
    "NOV": 5.26,
    "DEC": 5.29
  },
  {
    "LONG": -47.92,
    "LAT": -19.04,
    "CIDADE": "Indianópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5,
    "FEB": 5.62,
    "MAR": 5.13,
    "APR": 5.47,
    "MAY": 5.35,
    "JUN": 5.3,
    "JUL": 5.48,
    "AUG": 6.3,
    "SEP": 5.74,
    "OCT": 5.45,
    "NOV": 5.09,
    "DEC": 5.14
  },
  {
    "LONG": -47.46,
    "LAT": -18.99,
    "CIDADE": "Iraí de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 4.99,
    "APR": 5.36,
    "MAY": 5.4,
    "JUN": 5.23,
    "JUL": 5.52,
    "AUG": 6.32,
    "SEP": 5.76,
    "OCT": 5.39,
    "NOV": 4.96,
    "DEC": 5.07
  },
  {
    "LONG": -46.31,
    "LAT": -19,
    "CIDADE": "Carmo do Paranaíba",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.22,
    "FEB": 5.75,
    "MAR": 5.17,
    "APR": 5.56,
    "MAY": 5.43,
    "JUN": 5.29,
    "JUL": 5.66,
    "AUG": 6.42,
    "SEP": 5.92,
    "OCT": 5.43,
    "NOV": 4.89,
    "DEC": 5.07
  },
  {
    "LONG": -46.15,
    "LAT": -1.9,
    "CIDADE": "Arapuá",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.2,
    "FEB": 5.78,
    "MAR": 5.17,
    "APR": 5.57,
    "MAY": 5.43,
    "JUN": 5.34,
    "JUL": 5.68,
    "AUG": 6.47,
    "SEP": 5.96,
    "OCT": 5.41,
    "NOV": 4.79,
    "DEC": 5.04
  },
  {
    "LONG": -4.6,
    "LAT": -19,
    "CIDADE": "Tiros",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.2,
    "FEB": 5.76,
    "MAR": 5.16,
    "APR": 5.54,
    "MAY": 5.46,
    "JUN": 5.37,
    "JUL": 5.67,
    "AUG": 6.45,
    "SEP": 5.99,
    "OCT": 5.44,
    "NOV": 4.77,
    "DEC": 4.97
  },
  {
    "LONG": -44.04,
    "LAT": -19,
    "CIDADE": "Santana de Pirapama",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.5,
    "FEB": 6.12,
    "MAR": 5.64,
    "APR": 5.71,
    "MAY": 5.42,
    "JUN": 5.28,
    "JUL": 5.62,
    "AUG": 6.13,
    "SEP": 6.13,
    "OCT": 5.74,
    "NOV": 5.16,
    "DEC": 5.35
  },
  {
    "LONG": -43.42,
    "LAT": -19.03,
    "CIDADE": "Conceição do Mato Dentro",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.09,
    "FEB": 5.62,
    "MAR": 5.08,
    "APR": 5,
    "MAY": 4.63,
    "JUN": 4.53,
    "JUL": 4.7,
    "AUG": 5.35,
    "SEP": 5.31,
    "OCT": 5.05,
    "NOV": 4.46,
    "DEC": 4.89
  },
  {
    "LONG": -43.25,
    "LAT": -18.96,
    "CIDADE": "Dom Joaquim",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.21,
    "FEB": 5.73,
    "MAR": 5.15,
    "APR": 5,
    "MAY": 4.55,
    "JUN": 4.47,
    "JUL": 4.64,
    "AUG": 5.32,
    "SEP": 5.27,
    "OCT": 5.05,
    "NOV": 4.54,
    "DEC": 4.98
  },
  {
    "LONG": -41.97,
    "LAT": -18.97,
    "CIDADE": "Alpercata",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.45,
    "FEB": 5.88,
    "MAR": 5.45,
    "APR": 5.19,
    "MAY": 4.56,
    "JUN": 4.49,
    "JUL": 4.52,
    "AUG": 5.12,
    "SEP": 5.19,
    "OCT": 5.05,
    "NOV": 4.61,
    "DEC": 5.24
  },
  {
    "LONG": -4.17,
    "LAT": -18.98,
    "CIDADE": "Tumiritinga",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 5.61,
    "FEB": 6,
    "MAR": 5.52,
    "APR": 5.19,
    "MAY": 4.58,
    "JUN": 4.53,
    "JUL": 4.53,
    "AUG": 5.04,
    "SEP": 5.2,
    "OCT": 5.09,
    "NOV": 4.69,
    "DEC": 5.28
  },
  {
    "LONG": -4.15,
    "LAT": -19,
    "CIDADE": "Galiléia",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.56,
    "FEB": 5.95,
    "MAR": 5.48,
    "APR": 5.19,
    "MAY": 4.6,
    "JUN": 4.5,
    "JUL": 4.48,
    "AUG": 5.01,
    "SEP": 5.15,
    "OCT": 5.06,
    "NOV": 4.66,
    "DEC": 5.26
  },
  {
    "LONG": -41.22,
    "LAT": -18.98,
    "CIDADE": "Goiabeira",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.52,
    "FEB": 5.91,
    "MAR": 5.44,
    "APR": 5.1,
    "MAY": 4.58,
    "JUN": 4.39,
    "JUL": 4.45,
    "AUG": 4.94,
    "SEP": 5.09,
    "OCT": 4.96,
    "NOV": 4.65,
    "DEC": 5.2
  },
  {
    "LONG": -4.11,
    "LAT": -18.97,
    "CIDADE": "Cuparaque",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.34,
    "FEB": 5.75,
    "MAR": 5.22,
    "APR": 4.92,
    "MAY": 4.47,
    "JUN": 4.25,
    "JUL": 4.31,
    "AUG": 4.84,
    "SEP": 5.07,
    "OCT": 4.92,
    "NOV": 4.53,
    "DEC": 5.07
  },
  {
    "LONG": -4.89,
    "LAT": -1.89,
    "CIDADE": "Monte Alegre de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.08,
    "FEB": 5.56,
    "MAR": 5.19,
    "APR": 5.56,
    "MAY": 5.32,
    "JUN": 5.23,
    "JUL": 5.49,
    "AUG": 6.31,
    "SEP": 5.6,
    "OCT": 5.47,
    "NOV": 5.2,
    "DEC": 5.19
  },
  {
    "LONG": -48.28,
    "LAT": -18.91,
    "CIDADE": "Uberlândia",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.05,
    "FEB": 5.6,
    "MAR": 5.24,
    "APR": 5.59,
    "MAY": 5.37,
    "JUN": 5.33,
    "JUL": 5.51,
    "AUG": 6.26,
    "SEP": 5.74,
    "OCT": 5.52,
    "NOV": 5.15,
    "DEC": 5.18
  },
  {
    "LONG": -4.76,
    "LAT": -18.88,
    "CIDADE": "Romaria",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.08,
    "FEB": 5.59,
    "MAR": 5.09,
    "APR": 5.42,
    "MAY": 5.4,
    "JUN": 5.32,
    "JUL": 5.59,
    "AUG": 6.39,
    "SEP": 5.82,
    "OCT": 5.43,
    "NOV": 4.98,
    "DEC": 5.1
  },
  {
    "LONG": -46.99,
    "LAT": -18.94,
    "CIDADE": "Patrocínio",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.11,
    "FEB": 5.63,
    "MAR": 5.16,
    "APR": 5.53,
    "MAY": 5.55,
    "JUN": 5.39,
    "JUL": 5.72,
    "AUG": 6.44,
    "SEP": 5.91,
    "OCT": 5.48,
    "NOV": 4.91,
    "DEC": 4.99
  },
  {
    "LONG": -46.67,
    "LAT": -18.95,
    "CIDADE": "Cruzeiro da Fortaleza",
    "UF": "MINAS GERAIS",
    "anual": 5.5,
    "JAN": 5.17,
    "FEB": 5.81,
    "MAR": 5.16,
    "APR": 5.57,
    "MAY": 5.51,
    "JUN": 5.38,
    "JUL": 5.66,
    "AUG": 6.44,
    "SEP": 5.93,
    "OCT": 5.47,
    "NOV": 4.87,
    "DEC": 5.06
  },
  {
    "LONG": -4.55,
    "LAT": -18.9,
    "CIDADE": "Paineiras",
    "UF": "MINAS GERAIS",
    "anual": 5.6,
    "JAN": 5.42,
    "FEB": 5.92,
    "MAR": 5.39,
    "APR": 5.7,
    "MAY": 5.55,
    "JUN": 5.35,
    "JUL": 5.75,
    "AUG": 6.4,
    "SEP": 6.08,
    "OCT": 5.59,
    "NOV": 4.95,
    "DEC": 5.15
  },
  {
    "LONG": -43.08,
    "LAT": -18.89,
    "CIDADE": "Senhora do Porto",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.19,
    "FEB": 5.65,
    "MAR": 5.06,
    "APR": 4.88,
    "MAY": 4.53,
    "JUN": 4.47,
    "JUL": 4.57,
    "AUG": 5.18,
    "SEP": 5.23,
    "OCT": 4.96,
    "NOV": 4.44,
    "DEC": 4.94
  },
  {
    "LONG": -41.96,
    "LAT": -18.85,
    "CIDADE": "Governador Valadares",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.44,
    "FEB": 5.84,
    "MAR": 5.39,
    "APR": 5.07,
    "MAY": 4.54,
    "JUN": 4.41,
    "JUL": 4.47,
    "AUG": 5.11,
    "SEP": 5.21,
    "OCT": 5.06,
    "NOV": 4.58,
    "DEC": 5.19
  },
  {
    "LONG": -41.36,
    "LAT": -18.91,
    "CIDADE": "São Geraldo do Baixio",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.42,
    "FEB": 5.83,
    "MAR": 5.34,
    "APR": 5.04,
    "MAY": 4.56,
    "JUN": 4.35,
    "JUL": 4.38,
    "AUG": 4.9,
    "SEP": 5.09,
    "OCT": 4.98,
    "NOV": 4.6,
    "DEC": 5.16
  },
  {
    "LONG": -50.12,
    "LAT": -18.84,
    "CIDADE": "Santa Vitória",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.33,
    "FEB": 5.6,
    "MAR": 5.52,
    "APR": 5.68,
    "MAY": 5.34,
    "JUN": 5.22,
    "JUL": 5.37,
    "AUG": 6.12,
    "SEP": 5.57,
    "OCT": 5.5,
    "NOV": 5.41,
    "DEC": 5.42
  },
  {
    "LONG": -4.68,
    "LAT": -18.84,
    "CIDADE": "Guimarânia",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.07,
    "FEB": 5.69,
    "MAR": 5.09,
    "APR": 5.53,
    "MAY": 5.54,
    "JUN": 5.39,
    "JUL": 5.68,
    "AUG": 6.45,
    "SEP": 5.92,
    "OCT": 5.46,
    "NOV": 4.88,
    "DEC": 5
  },
  {
    "LONG": -46.4,
    "LAT": -1.88,
    "CIDADE": "Lagoa Formosa",
    "UF": "MINAS GERAIS",
    "anual": 5.54,
    "JAN": 5.24,
    "FEB": 5.75,
    "MAR": 5.24,
    "APR": 5.59,
    "MAY": 5.55,
    "JUN": 5.42,
    "JUL": 5.7,
    "AUG": 6.45,
    "SEP": 5.99,
    "OCT": 5.52,
    "NOV": 4.95,
    "DEC": 5.08
  },
  {
    "LONG": -45.5,
    "LAT": -18.77,
    "CIDADE": "Biquinhas",
    "UF": "MINAS GERAIS",
    "anual": 5.66,
    "JAN": 5.47,
    "FEB": 5.97,
    "MAR": 5.49,
    "APR": 5.77,
    "MAY": 5.58,
    "JUN": 5.4,
    "JUL": 5.78,
    "AUG": 6.39,
    "SEP": 6.14,
    "OCT": 5.7,
    "NOV": 5.05,
    "DEC": 5.2
  },
  {
    "LONG": -44.9,
    "LAT": -18.75,
    "CIDADE": "Felixlândia",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.5,
    "FEB": 6.04,
    "MAR": 5.58,
    "APR": 5.86,
    "MAY": 5.5,
    "JUN": 5.3,
    "JUL": 5.72,
    "AUG": 6.17,
    "SEP": 6.12,
    "OCT": 5.61,
    "NOV": 5.1,
    "DEC": 5.27
  },
  {
    "LONG": -44.43,
    "LAT": -18.75,
    "CIDADE": "Curvelo",
    "UF": "MINAS GERAIS",
    "anual": 5.63,
    "JAN": 5.49,
    "FEB": 6.06,
    "MAR": 5.62,
    "APR": 5.75,
    "MAY": 5.46,
    "JUN": 5.24,
    "JUL": 5.67,
    "AUG": 6.1,
    "SEP": 6.11,
    "OCT": 5.69,
    "NOV": 5.12,
    "DEC": 5.29
  },
  {
    "LONG": -43.68,
    "LAT": -18.8,
    "CIDADE": "Congonhas do Norte",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.2,
    "FEB": 5.64,
    "MAR": 5.11,
    "APR": 5.04,
    "MAY": 4.8,
    "JUN": 4.76,
    "JUL": 4.99,
    "AUG": 5.62,
    "SEP": 5.62,
    "OCT": 5.31,
    "NOV": 4.6,
    "DEC": 4.97
  },
  {
    "LONG": -42.93,
    "LAT": -18.77,
    "CIDADE": "Guanhães",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.18,
    "FEB": 5.67,
    "MAR": 5,
    "APR": 4.79,
    "MAY": 4.48,
    "JUN": 4.44,
    "JUL": 4.56,
    "AUG": 5.13,
    "SEP": 5.23,
    "OCT": 5.03,
    "NOV": 4.48,
    "DEC": 4.96
  },
  {
    "LONG": -42.7,
    "LAT": -18.82,
    "CIDADE": "Virginópolis",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.11,
    "FEB": 5.6,
    "MAR": 4.97,
    "APR": 4.77,
    "MAY": 4.37,
    "JUN": 4.37,
    "JUL": 4.47,
    "AUG": 5.02,
    "SEP": 5.15,
    "OCT": 4.93,
    "NOV": 4.36,
    "DEC": 4.89
  },
  {
    "LONG": -42.62,
    "LAT": -18.81,
    "CIDADE": "Divinolândia de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5.12,
    "FEB": 5.61,
    "MAR": 4.96,
    "APR": 4.77,
    "MAY": 4.34,
    "JUN": 4.34,
    "JUL": 4.45,
    "AUG": 4.99,
    "SEP": 5.15,
    "OCT": 4.92,
    "NOV": 4.34,
    "DEC": 4.89
  },
  {
    "LONG": -42.44,
    "LAT": -18.82,
    "CIDADE": "Santa Efigênia de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 4.97,
    "APR": 4.72,
    "MAY": 4.29,
    "JUN": 4.27,
    "JUL": 4.34,
    "AUG": 4.94,
    "SEP": 5.08,
    "OCT": 4.87,
    "NOV": 4.26,
    "DEC": 4.87
  },
  {
    "LONG": -42.48,
    "LAT": -18.82,
    "CIDADE": "Gonzaga",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 4.97,
    "APR": 4.72,
    "MAY": 4.29,
    "JUN": 4.27,
    "JUL": 4.34,
    "AUG": 4.94,
    "SEP": 5.08,
    "OCT": 4.87,
    "NOV": 4.26,
    "DEC": 4.87
  },
  {
    "LONG": -42.36,
    "LAT": -18.78,
    "CIDADE": "Sardoá",
    "UF": "MINAS GERAIS",
    "anual": 4.81,
    "JAN": 5.14,
    "FEB": 5.59,
    "MAR": 5.05,
    "APR": 4.79,
    "MAY": 4.33,
    "JUN": 4.28,
    "JUL": 4.34,
    "AUG": 4.94,
    "SEP": 5.09,
    "OCT": 4.89,
    "NOV": 4.31,
    "DEC": 4.92
  },
  {
    "LONG": -42.29,
    "LAT": -18.84,
    "CIDADE": "São Geraldo da Piedade",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.26,
    "FEB": 5.71,
    "MAR": 5.16,
    "APR": 4.95,
    "MAY": 4.41,
    "JUN": 4.35,
    "JUL": 4.41,
    "AUG": 4.96,
    "SEP": 5.1,
    "OCT": 4.91,
    "NOV": 4.39,
    "DEC": 4.98
  },
  {
    "LONG": -41.48,
    "LAT": -1.88,
    "CIDADE": "Divino das Laranjeiras",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.48,
    "FEB": 5.89,
    "MAR": 5.38,
    "APR": 5.07,
    "MAY": 4.55,
    "JUN": 4.41,
    "JUL": 4.44,
    "AUG": 4.96,
    "SEP": 5.13,
    "OCT": 5.02,
    "NOV": 4.61,
    "DEC": 5.16
  },
  {
    "LONG": -41.31,
    "LAT": -18.76,
    "CIDADE": "Central de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.49,
    "FEB": 5.92,
    "MAR": 5.39,
    "APR": 5.07,
    "MAY": 4.55,
    "JUN": 4.38,
    "JUL": 4.41,
    "AUG": 4.95,
    "SEP": 5.15,
    "OCT": 5.04,
    "NOV": 4.64,
    "DEC": 5.2
  },
  {
    "LONG": -40.99,
    "LAT": -18.78,
    "CIDADE": "Mantena",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.49,
    "FEB": 5.91,
    "MAR": 5.46,
    "APR": 5.06,
    "MAY": 4.52,
    "JUN": 4.35,
    "JUL": 4.36,
    "AUG": 4.77,
    "SEP": 5.03,
    "OCT": 4.86,
    "NOV": 4.62,
    "DEC": 5.22
  },
  {
    "LONG": -4.99,
    "LAT": -18.69,
    "CIDADE": "Ipiaçu",
    "UF": "MINAS GERAIS",
    "anual": 5.5,
    "JAN": 5.4,
    "FEB": 5.58,
    "MAR": 5.47,
    "APR": 5.71,
    "MAY": 5.4,
    "JUN": 5.19,
    "JUL": 5.29,
    "AUG": 6.02,
    "SEP": 5.55,
    "OCT": 5.47,
    "NOV": 5.43,
    "DEC": 5.43
  },
  {
    "LONG": -4.96,
    "LAT": -18.69,
    "CIDADE": "Capinópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.47,
    "JAN": 5.22,
    "FEB": 5.52,
    "MAR": 5.31,
    "APR": 5.66,
    "MAY": 5.43,
    "JUN": 5.3,
    "JUL": 5.46,
    "AUG": 6.16,
    "SEP": 5.53,
    "OCT": 5.47,
    "NOV": 5.3,
    "DEC": 5.28
  },
  {
    "LONG": -4.92,
    "LAT": -18.72,
    "CIDADE": "Canápolis",
    "UF": "MINAS GERAIS",
    "anual": 5.5,
    "JAN": 5.19,
    "FEB": 5.56,
    "MAR": 5.45,
    "APR": 5.66,
    "MAY": 5.48,
    "JUN": 5.34,
    "JUL": 5.47,
    "AUG": 6.25,
    "SEP": 5.56,
    "OCT": 5.47,
    "NOV": 5.31,
    "DEC": 5.26
  },
  {
    "LONG": -4.77,
    "LAT": -18.74,
    "CIDADE": "Estrela do Sul",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.16,
    "FEB": 5.73,
    "MAR": 5.31,
    "APR": 5.6,
    "MAY": 5.49,
    "JUN": 5.37,
    "JUL": 5.6,
    "AUG": 6.33,
    "SEP": 5.85,
    "OCT": 5.49,
    "NOV": 5.06,
    "DEC": 5.18
  },
  {
    "LONG": -47.49,
    "LAT": -18.73,
    "CIDADE": "Monte Carmelo",
    "UF": "MINAS GERAIS",
    "anual": 5.53,
    "JAN": 5.11,
    "FEB": 5.65,
    "MAR": 5.25,
    "APR": 5.57,
    "MAY": 5.49,
    "JUN": 5.42,
    "JUL": 5.66,
    "AUG": 6.42,
    "SEP": 5.91,
    "OCT": 5.56,
    "NOV": 5.09,
    "DEC": 5.19
  },
  {
    "LONG": -4.44,
    "LAT": -1.87,
    "CIDADE": "Inimutaba",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.56,
    "FEB": 6.08,
    "MAR": 5.65,
    "APR": 5.86,
    "MAY": 5.49,
    "JUN": 5.18,
    "JUL": 5.65,
    "AUG": 6.06,
    "SEP": 6.11,
    "OCT": 5.72,
    "NOV": 5.13,
    "DEC": 5.32
  },
  {
    "LONG": -43.36,
    "LAT": -18.73,
    "CIDADE": "Alvorada de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.18,
    "FEB": 5.7,
    "MAR": 5.07,
    "APR": 4.97,
    "MAY": 4.63,
    "JUN": 4.56,
    "JUL": 4.7,
    "AUG": 5.35,
    "SEP": 5.36,
    "OCT": 5.12,
    "NOV": 4.48,
    "DEC": 4.91
  },
  {
    "LONG": -43.08,
    "LAT": -18.67,
    "CIDADE": "Sabinópolis",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.2,
    "FEB": 5.69,
    "MAR": 5.05,
    "APR": 4.88,
    "MAY": 4.52,
    "JUN": 4.46,
    "JUL": 4.62,
    "AUG": 5.22,
    "SEP": 5.28,
    "OCT": 5.05,
    "NOV": 4.5,
    "DEC": 4.98
  },
  {
    "LONG": -41.41,
    "LAT": -18.66,
    "CIDADE": "Mendes Pimentel",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.38,
    "FEB": 5.78,
    "MAR": 5.28,
    "APR": 5.02,
    "MAY": 4.52,
    "JUN": 4.35,
    "JUL": 4.43,
    "AUG": 4.9,
    "SEP": 5.14,
    "OCT": 5.02,
    "NOV": 4.56,
    "DEC": 5.08
  },
  {
    "LONG": -41.16,
    "LAT": -18.72,
    "CIDADE": "São João do Manteninha",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.59,
    "FEB": 5.96,
    "MAR": 5.49,
    "APR": 5.18,
    "MAY": 4.61,
    "JUN": 4.41,
    "JUL": 4.48,
    "AUG": 4.96,
    "SEP": 5.2,
    "OCT": 5.08,
    "NOV": 4.72,
    "DEC": 5.24
  },
  {
    "LONG": -49.2,
    "LAT": -18.59,
    "CIDADE": "Centralina",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.18,
    "FEB": 5.58,
    "MAR": 5.47,
    "APR": 5.72,
    "MAY": 5.53,
    "JUN": 5.37,
    "JUL": 5.45,
    "AUG": 6.22,
    "SEP": 5.53,
    "OCT": 5.5,
    "NOV": 5.33,
    "DEC": 5.24
  },
  {
    "LONG": -4.87,
    "LAT": -1.86,
    "CIDADE": "Tupaciguara",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.14,
    "FEB": 5.64,
    "MAR": 5.28,
    "APR": 5.6,
    "MAY": 5.43,
    "JUN": 5.33,
    "JUL": 5.48,
    "AUG": 6.21,
    "SEP": 5.64,
    "OCT": 5.5,
    "NOV": 5.23,
    "DEC": 5.28
  },
  {
    "LONG": -48.19,
    "LAT": -1.86,
    "CIDADE": "Araguari",
    "UF": "MINAS GERAIS",
    "anual": 5.52,
    "JAN": 5.07,
    "FEB": 5.66,
    "MAR": 5.3,
    "APR": 5.73,
    "MAY": 5.5,
    "JUN": 5.44,
    "JUL": 5.56,
    "AUG": 6.37,
    "SEP": 5.8,
    "OCT": 5.53,
    "NOV": 5.12,
    "DEC": 5.19
  },
  {
    "LONG": -47.87,
    "LAT": -18.58,
    "CIDADE": "Cascalho Rico",
    "UF": "MINAS GERAIS",
    "anual": 5.58,
    "JAN": 5.18,
    "FEB": 5.83,
    "MAR": 5.42,
    "APR": 5.74,
    "MAY": 5.51,
    "JUN": 5.47,
    "JUL": 5.56,
    "AUG": 6.36,
    "SEP": 5.85,
    "OCT": 5.61,
    "NOV": 5.17,
    "DEC": 5.29
  },
  {
    "LONG": -46.51,
    "LAT": -18.58,
    "CIDADE": "Patos de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.53,
    "JAN": 5.18,
    "FEB": 5.71,
    "MAR": 5.25,
    "APR": 5.58,
    "MAY": 5.52,
    "JUN": 5.41,
    "JUL": 5.71,
    "AUG": 6.49,
    "SEP": 6.02,
    "OCT": 5.53,
    "NOV": 4.91,
    "DEC": 5.05
  },
  {
    "LONG": -45.36,
    "LAT": -18.6,
    "CIDADE": "Morada Nova de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.69,
    "JAN": 5.58,
    "FEB": 6.05,
    "MAR": 5.62,
    "APR": 5.81,
    "MAY": 5.53,
    "JUN": 5.34,
    "JUL": 5.74,
    "AUG": 6.19,
    "SEP": 6.13,
    "OCT": 5.78,
    "NOV": 5.19,
    "DEC": 5.31
  },
  {
    "LONG": -4.41,
    "LAT": -1.86,
    "CIDADE": "Presidente Juscelino",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.53,
    "FEB": 6.05,
    "MAR": 5.58,
    "APR": 5.81,
    "MAY": 5.48,
    "JUN": 5.27,
    "JUL": 5.66,
    "AUG": 6.1,
    "SEP": 6.12,
    "OCT": 5.74,
    "NOV": 5.13,
    "DEC": 5.3
  },
  {
    "LONG": -4.36,
    "LAT": -1.86,
    "CIDADE": "Presidente Kubitschek",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.33,
    "FEB": 5.83,
    "MAR": 5.22,
    "APR": 5.12,
    "MAY": 4.93,
    "JUN": 4.87,
    "JUL": 5.12,
    "AUG": 5.75,
    "SEP": 5.77,
    "OCT": 5.4,
    "NOV": 4.67,
    "DEC": 5.05
  },
  {
    "LONG": -43.37,
    "LAT": -18.6,
    "CIDADE": "Serro",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.23,
    "FEB": 5.75,
    "MAR": 5.1,
    "APR": 4.99,
    "MAY": 4.67,
    "JUN": 4.62,
    "JUL": 4.75,
    "AUG": 5.38,
    "SEP": 5.43,
    "OCT": 5.16,
    "NOV": 4.48,
    "DEC": 4.92
  },
  {
    "LONG": -42.28,
    "LAT": -1.86,
    "CIDADE": "Coroaci",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 5.24,
    "FEB": 5.73,
    "MAR": 5.11,
    "APR": 4.8,
    "MAY": 4.34,
    "JUN": 4.27,
    "JUL": 4.37,
    "AUG": 4.93,
    "SEP": 5.11,
    "OCT": 4.93,
    "NOV": 4.37,
    "DEC": 5.01
  },
  {
    "LONG": -41.91,
    "LAT": -18.56,
    "CIDADE": "Frei Inocêncio",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.53,
    "FEB": 5.93,
    "MAR": 5.48,
    "APR": 5.12,
    "MAY": 4.59,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 4.99,
    "SEP": 5.23,
    "OCT": 5.09,
    "NOV": 4.61,
    "DEC": 5.29
  },
  {
    "LONG": -41.92,
    "LAT": -18.59,
    "CIDADE": "Mathias Lobato",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.53,
    "FEB": 5.93,
    "MAR": 5.48,
    "APR": 5.12,
    "MAY": 4.59,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 4.99,
    "SEP": 5.23,
    "OCT": 5.09,
    "NOV": 4.61,
    "DEC": 5.29
  },
  {
    "LONG": -41.49,
    "LAT": -18.6,
    "CIDADE": "São Félix de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.37,
    "FEB": 5.73,
    "MAR": 5.22,
    "APR": 4.95,
    "MAY": 4.43,
    "JUN": 4.32,
    "JUL": 4.39,
    "AUG": 4.89,
    "SEP": 5.12,
    "OCT": 5.04,
    "NOV": 4.58,
    "DEC": 5.11
  },
  {
    "LONG": -41.23,
    "LAT": -18.57,
    "CIDADE": "Itabirinha",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.39,
    "FEB": 5.79,
    "MAR": 5.31,
    "APR": 5.06,
    "MAY": 4.5,
    "JUN": 4.35,
    "JUL": 4.43,
    "AUG": 4.91,
    "SEP": 5.1,
    "OCT": 4.96,
    "NOV": 4.57,
    "DEC": 5.11
  },
  {
    "LONG": -49.5,
    "LAT": -18.52,
    "CIDADE": "Cachoeira Dourada",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.11,
    "FEB": 5.5,
    "MAR": 5.36,
    "APR": 5.63,
    "MAY": 5.46,
    "JUN": 5.28,
    "JUL": 5.48,
    "AUG": 6.22,
    "SEP": 5.52,
    "OCT": 5.42,
    "NOV": 5.25,
    "DEC": 5.19
  },
  {
    "LONG": -47.72,
    "LAT": -18.5,
    "CIDADE": "Grupiara",
    "UF": "MINAS GERAIS",
    "anual": 5.59,
    "JAN": 5.25,
    "FEB": 5.84,
    "MAR": 5.49,
    "APR": 5.77,
    "MAY": 5.55,
    "JUN": 5.37,
    "JUL": 5.54,
    "AUG": 6.28,
    "SEP": 5.86,
    "OCT": 5.58,
    "NOV": 5.22,
    "DEC": 5.33
  },
  {
    "LONG": -47.39,
    "LAT": -18.48,
    "CIDADE": "Abadia dos Dourados",
    "UF": "MINAS GERAIS",
    "anual": 5.56,
    "JAN": 5.12,
    "FEB": 5.74,
    "MAR": 5.3,
    "APR": 5.63,
    "MAY": 5.56,
    "JUN": 5.41,
    "JUL": 5.72,
    "AUG": 6.46,
    "SEP": 5.95,
    "OCT": 5.58,
    "NOV": 5.07,
    "DEC": 5.22
  },
  {
    "LONG": -47.19,
    "LAT": -18.47,
    "CIDADE": "Coromandel",
    "UF": "MINAS GERAIS",
    "anual": 5.56,
    "JAN": 5.15,
    "FEB": 5.73,
    "MAR": 5.3,
    "APR": 5.59,
    "MAY": 5.58,
    "JUN": 5.39,
    "JUL": 5.75,
    "AUG": 6.42,
    "SEP": 5.97,
    "OCT": 5.61,
    "NOV": 5.04,
    "DEC": 5.16
  },
  {
    "LONG": -4.46,
    "LAT": -1.85,
    "CIDADE": "Morro da Garça",
    "UF": "MINAS GERAIS",
    "anual": 5.66,
    "JAN": 5.54,
    "FEB": 6.05,
    "MAR": 5.56,
    "APR": 5.83,
    "MAY": 5.52,
    "JUN": 5.31,
    "JUL": 5.73,
    "AUG": 6.14,
    "SEP": 6.13,
    "OCT": 5.69,
    "NOV": 5.08,
    "DEC": 5.29
  },
  {
    "LONG": -43.74,
    "LAT": -18.45,
    "CIDADE": "Gouveia",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.29,
    "FEB": 5.82,
    "MAR": 5.22,
    "APR": 5.23,
    "MAY": 5.12,
    "JUN": 5.07,
    "JUL": 5.38,
    "AUG": 5.98,
    "SEP": 5.95,
    "OCT": 5.5,
    "NOV": 4.73,
    "DEC": 5.07
  },
  {
    "LONG": -43.3,
    "LAT": -1.85,
    "CIDADE": "Santo Antônio do Itambé",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.19,
    "FEB": 5.7,
    "MAR": 5.07,
    "APR": 4.92,
    "MAY": 4.7,
    "JUN": 4.71,
    "JUL": 4.88,
    "AUG": 5.52,
    "SEP": 5.55,
    "OCT": 5.21,
    "NOV": 4.48,
    "DEC": 4.91
  },
  {
    "LONG": -43.06,
    "LAT": -18.47,
    "CIDADE": "Materlândia",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.39,
    "FEB": 5.82,
    "MAR": 5.17,
    "APR": 4.92,
    "MAY": 4.61,
    "JUN": 4.52,
    "JUL": 4.62,
    "AUG": 5.3,
    "SEP": 5.37,
    "OCT": 5.19,
    "NOV": 4.51,
    "DEC": 5.06
  },
  {
    "LONG": -42.77,
    "LAT": -18.55,
    "CIDADE": "São João Evangelista",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.33,
    "FEB": 5.82,
    "MAR": 5.2,
    "APR": 4.93,
    "MAY": 4.52,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.1,
    "SEP": 5.26,
    "OCT": 5.11,
    "NOV": 4.49,
    "DEC": 5.11
  },
  {
    "LONG": -42.63,
    "LAT": -18.52,
    "CIDADE": "Cantagalo",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.33,
    "FEB": 5.84,
    "MAR": 5.23,
    "APR": 4.95,
    "MAY": 4.52,
    "JUN": 4.42,
    "JUL": 4.49,
    "AUG": 5.09,
    "SEP": 5.27,
    "OCT": 5.11,
    "NOV": 4.48,
    "DEC": 5.12
  },
  {
    "LONG": -42.56,
    "LAT": -18.54,
    "CIDADE": "Peçanha",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.38,
    "FEB": 5.9,
    "MAR": 5.29,
    "APR": 4.94,
    "MAY": 4.5,
    "JUN": 4.39,
    "JUL": 4.47,
    "AUG": 5.07,
    "SEP": 5.26,
    "OCT": 5.1,
    "NOV": 4.49,
    "DEC": 5.15
  },
  {
    "LONG": -4.23,
    "LAT": -18.47,
    "CIDADE": "Virgolândia",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.5,
    "FEB": 5.89,
    "MAR": 5.28,
    "APR": 4.9,
    "MAY": 4.46,
    "JUN": 4.39,
    "JUL": 4.38,
    "AUG": 5.04,
    "SEP": 5.23,
    "OCT": 5.09,
    "NOV": 4.49,
    "DEC": 5.18
  },
  {
    "LONG": -42.25,
    "LAT": -18.45,
    "CIDADE": "Nacip Raydan",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.47,
    "FEB": 5.84,
    "MAR": 5.34,
    "APR": 4.96,
    "MAY": 4.47,
    "JUN": 4.4,
    "JUL": 4.42,
    "AUG": 5.06,
    "SEP": 5.22,
    "OCT": 5.09,
    "NOV": 4.53,
    "DEC": 5.18
  },
  {
    "LONG": -42.08,
    "LAT": -18.51,
    "CIDADE": "Marilac",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.46,
    "FEB": 5.95,
    "MAR": 5.5,
    "APR": 5.11,
    "MAY": 4.61,
    "JUN": 4.52,
    "JUL": 4.51,
    "AUG": 5.09,
    "SEP": 5.21,
    "OCT": 5.13,
    "NOV": 4.59,
    "DEC": 5.26
  },
  {
    "LONG": -41.81,
    "LAT": -18.46,
    "CIDADE": "Jampruca",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.47,
    "FEB": 5.91,
    "MAR": 5.47,
    "APR": 5.15,
    "MAY": 4.59,
    "JUN": 4.41,
    "JUL": 4.46,
    "AUG": 5,
    "SEP": 5.19,
    "OCT": 5.09,
    "NOV": 4.62,
    "DEC": 5.27
  },
  {
    "LONG": -4.14,
    "LAT": -18.48,
    "CIDADE": "São José do Divino",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.5,
    "FEB": 5.86,
    "MAR": 5.34,
    "APR": 5,
    "MAY": 4.45,
    "JUN": 4.33,
    "JUL": 4.38,
    "AUG": 4.86,
    "SEP": 5.1,
    "OCT": 5.02,
    "NOV": 4.64,
    "DEC": 5.23
  },
  {
    "LONG": -4.11,
    "LAT": -1.85,
    "CIDADE": "Nova Belém",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.31,
    "FEB": 5.66,
    "MAR": 5.15,
    "APR": 4.81,
    "MAY": 4.33,
    "JUN": 4.24,
    "JUL": 4.32,
    "AUG": 4.74,
    "SEP": 5.01,
    "OCT": 4.87,
    "NOV": 4.51,
    "DEC": 5.1
  },
  {
    "LONG": -49.19,
    "LAT": -18.44,
    "CIDADE": "Araporã",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.12,
    "FEB": 5.54,
    "MAR": 5.33,
    "APR": 5.61,
    "MAY": 5.49,
    "JUN": 5.34,
    "JUL": 5.44,
    "AUG": 6.14,
    "SEP": 5.57,
    "OCT": 5.49,
    "NOV": 5.23,
    "DEC": 5.2
  },
  {
    "LONG": -47.61,
    "LAT": -18.43,
    "CIDADE": "Douradoquara",
    "UF": "MINAS GERAIS",
    "anual": 5.59,
    "JAN": 5.21,
    "FEB": 5.78,
    "MAR": 5.42,
    "APR": 5.79,
    "MAY": 5.59,
    "JUN": 5.38,
    "JUL": 5.63,
    "AUG": 6.37,
    "SEP": 5.85,
    "OCT": 5.6,
    "NOV": 5.17,
    "DEC": 5.24
  },
  {
    "LONG": -46.42,
    "LAT": -18.41,
    "CIDADE": "Presidente Olegário",
    "UF": "MINAS GERAIS",
    "anual": 5.57,
    "JAN": 5.25,
    "FEB": 5.77,
    "MAR": 5.21,
    "APR": 5.57,
    "MAY": 5.57,
    "JUN": 5.47,
    "JUL": 5.84,
    "AUG": 6.49,
    "SEP": 6.07,
    "OCT": 5.56,
    "NOV": 4.97,
    "DEC": 5.08
  },
  {
    "LONG": -4.6,
    "LAT": -18.38,
    "CIDADE": "Varjão de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.61,
    "JAN": 5.4,
    "FEB": 5.83,
    "MAR": 5.3,
    "APR": 5.62,
    "MAY": 5.56,
    "JUN": 5.52,
    "JUL": 5.81,
    "AUG": 6.49,
    "SEP": 6.12,
    "OCT": 5.53,
    "NOV": 4.95,
    "DEC": 5.16
  },
  {
    "LONG": -44.45,
    "LAT": -18.37,
    "CIDADE": "Corinto",
    "UF": "MINAS GERAIS",
    "anual": 5.69,
    "JAN": 5.51,
    "FEB": 6.11,
    "MAR": 5.63,
    "APR": 5.85,
    "MAY": 5.53,
    "JUN": 5.37,
    "JUL": 5.74,
    "AUG": 6.16,
    "SEP": 6.16,
    "OCT": 5.78,
    "NOV": 5.13,
    "DEC": 5.33
  },
  {
    "LONG": -43.66,
    "LAT": -18.45,
    "CIDADE": "Datas",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.24,
    "FEB": 5.74,
    "MAR": 5.13,
    "APR": 5.1,
    "MAY": 4.99,
    "JUN": 5.01,
    "JUL": 5.37,
    "AUG": 5.95,
    "SEP": 5.9,
    "OCT": 5.45,
    "NOV": 4.68,
    "DEC": 5.03
  },
  {
    "LONG": -43.17,
    "LAT": -18.36,
    "CIDADE": "Serra Azul de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.32,
    "FEB": 5.82,
    "MAR": 5.18,
    "APR": 4.92,
    "MAY": 4.62,
    "JUN": 4.59,
    "JUL": 4.68,
    "AUG": 5.42,
    "SEP": 5.44,
    "OCT": 5.2,
    "NOV": 4.47,
    "DEC": 5.01
  },
  {
    "LONG": -42.87,
    "LAT": -18.43,
    "CIDADE": "Paulistas",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.41,
    "FEB": 5.9,
    "MAR": 5.24,
    "APR": 5,
    "MAY": 4.59,
    "JUN": 4.51,
    "JUL": 4.57,
    "AUG": 5.22,
    "SEP": 5.37,
    "OCT": 5.19,
    "NOV": 4.53,
    "DEC": 5.12
  },
  {
    "LONG": -4.26,
    "LAT": -1.84,
    "CIDADE": "São Pedro do Suaçuí",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.35,
    "FEB": 5.88,
    "MAR": 5.31,
    "APR": 5.05,
    "MAY": 4.54,
    "JUN": 4.5,
    "JUL": 4.54,
    "AUG": 5.14,
    "SEP": 5.29,
    "OCT": 5.14,
    "NOV": 4.56,
    "DEC": 5.15
  },
  {
    "LONG": -41.6,
    "LAT": -18.36,
    "CIDADE": "Pescador",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.45,
    "FEB": 5.9,
    "MAR": 5.45,
    "APR": 5.1,
    "MAY": 4.52,
    "JUN": 4.32,
    "JUL": 4.43,
    "AUG": 4.99,
    "SEP": 5.22,
    "OCT": 5.1,
    "NOV": 4.63,
    "DEC": 5.23
  },
  {
    "LONG": -41.5,
    "LAT": -18.44,
    "CIDADE": "Nova Módica",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.62,
    "FEB": 5.99,
    "MAR": 5.46,
    "APR": 5.07,
    "MAY": 4.51,
    "JUN": 4.37,
    "JUL": 4.38,
    "AUG": 4.99,
    "SEP": 5.19,
    "OCT": 5.12,
    "NOV": 4.71,
    "DEC": 5.27
  },
  {
    "LONG": -45.83,
    "LAT": -18.34,
    "CIDADE": "São Gonçalo do Abaeté",
    "UF": "MINAS GERAIS",
    "anual": 5.6,
    "JAN": 5.42,
    "FEB": 5.89,
    "MAR": 5.28,
    "APR": 5.61,
    "MAY": 5.54,
    "JUN": 5.4,
    "JUL": 5.82,
    "AUG": 6.45,
    "SEP": 6.16,
    "OCT": 5.54,
    "NOV": 4.92,
    "DEC": 5.13
  },
  {
    "LONG": -44.22,
    "LAT": -18.3,
    "CIDADE": "Santo Hipólito",
    "UF": "MINAS GERAIS",
    "anual": 5.71,
    "JAN": 5.59,
    "FEB": 6.16,
    "MAR": 5.69,
    "APR": 5.88,
    "MAY": 5.57,
    "JUN": 5.36,
    "JUL": 5.68,
    "AUG": 6.06,
    "SEP": 6.16,
    "OCT": 5.82,
    "NOV": 5.12,
    "DEC": 5.37
  },
  {
    "LONG": -44.12,
    "LAT": -18.32,
    "CIDADE": "Monjolos",
    "UF": "MINAS GERAIS",
    "anual": 5.67,
    "JAN": 5.58,
    "FEB": 6.13,
    "MAR": 5.62,
    "APR": 5.78,
    "MAY": 5.53,
    "JUN": 5.33,
    "JUL": 5.63,
    "AUG": 6.08,
    "SEP": 6.12,
    "OCT": 5.77,
    "NOV": 5.07,
    "DEC": 5.36
  },
  {
    "LONG": -4.3,
    "LAT": -1.83,
    "CIDADE": "Rio Vermelho",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.39,
    "FEB": 5.86,
    "MAR": 5.18,
    "APR": 4.94,
    "MAY": 4.62,
    "JUN": 4.57,
    "JUL": 4.68,
    "AUG": 5.4,
    "SEP": 5.47,
    "OCT": 5.21,
    "NOV": 4.5,
    "DEC": 5.03
  },
  {
    "LONG": -42.67,
    "LAT": -18.27,
    "CIDADE": "São José do Jacuri",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.3,
    "FEB": 5.83,
    "MAR": 5.24,
    "APR": 4.97,
    "MAY": 4.5,
    "JUN": 4.48,
    "JUL": 4.49,
    "AUG": 5.14,
    "SEP": 5.26,
    "OCT": 5.14,
    "NOV": 4.49,
    "DEC": 5.05
  },
  {
    "LONG": -42.15,
    "LAT": -18.32,
    "CIDADE": "São José da Safira",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.32,
    "FEB": 5.78,
    "MAR": 5.2,
    "APR": 4.91,
    "MAY": 4.48,
    "JUN": 4.38,
    "JUL": 4.45,
    "AUG": 5.06,
    "SEP": 5.19,
    "OCT": 5.07,
    "NOV": 4.48,
    "DEC": 5.07
  },
  {
    "LONG": -46.81,
    "LAT": -18.18,
    "CIDADE": "Lagamar",
    "UF": "MINAS GERAIS",
    "anual": 5.62,
    "JAN": 5.29,
    "FEB": 5.83,
    "MAR": 5.32,
    "APR": 5.72,
    "MAY": 5.6,
    "JUN": 5.51,
    "JUL": 5.78,
    "AUG": 6.48,
    "SEP": 6.06,
    "OCT": 5.65,
    "NOV": 5.06,
    "DEC": 5.17
  },
  {
    "LONG": -45.23,
    "LAT": -18.2,
    "CIDADE": "Três Marias",
    "UF": "MINAS GERAIS",
    "anual": 5.71,
    "JAN": 5.47,
    "FEB": 6.01,
    "MAR": 5.6,
    "APR": 5.86,
    "MAY": 5.63,
    "JUN": 5.46,
    "JUL": 5.83,
    "AUG": 6.29,
    "SEP": 6.16,
    "OCT": 5.74,
    "NOV": 5.13,
    "DEC": 5.29
  },
  {
    "LONG": -43.6,
    "LAT": -18.24,
    "CIDADE": "Diamantina",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.32,
    "FEB": 5.85,
    "MAR": 5.2,
    "APR": 5.15,
    "MAY": 4.97,
    "JUN": 5,
    "JUL": 5.26,
    "AUG": 5.93,
    "SEP": 5.88,
    "OCT": 5.42,
    "NOV": 4.67,
    "DEC": 5.06
  },
  {
    "LONG": "#VALOR!",
    "LAT": -1.82,
    "CIDADE": "Coluna",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.34,
    "FEB": 5.85,
    "MAR": 5.21,
    "APR": 4.95,
    "MAY": 4.59,
    "JUN": 4.54,
    "JUL": 4.63,
    "AUG": 5.34,
    "SEP": 5.46,
    "OCT": 5.22,
    "NOV": 4.48,
    "DEC": 5.05
  },
  {
    "LONG": -4.28,
    "LAT": -1.82,
    "CIDADE": "Frei Lagonegro",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.3,
    "FEB": 5.84,
    "MAR": 5.22,
    "APR": 4.92,
    "MAY": 4.53,
    "JUN": 4.48,
    "JUL": 4.53,
    "AUG": 5.25,
    "SEP": 5.37,
    "OCT": 5.19,
    "NOV": 4.47,
    "DEC": 5.03
  },
  {
    "LONG": -4.25,
    "LAT": "#VALOR!",
    "CIDADE": "José Raydan",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.43,
    "FEB": 5.97,
    "MAR": 5.37,
    "APR": 5.02,
    "MAY": 4.6,
    "JUN": 4.48,
    "JUL": 4.55,
    "AUG": 5.18,
    "SEP": 5.34,
    "OCT": 5.18,
    "NOV": 4.59,
    "DEC": 5.17
  },
  {
    "LONG": -42.42,
    "LAT": -18.19,
    "CIDADE": "Santa Maria do Suaçuí",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.43,
    "FEB": 5.97,
    "MAR": 5.37,
    "APR": 5.02,
    "MAY": 4.6,
    "JUN": 4.48,
    "JUL": 4.55,
    "AUG": 5.18,
    "SEP": 5.34,
    "OCT": 5.18,
    "NOV": 4.59,
    "DEC": 5.17
  },
  {
    "LONG": -41.74,
    "LAT": -18.24,
    "CIDADE": "Campanário",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.52,
    "FEB": 5.98,
    "MAR": 5.52,
    "APR": 5.11,
    "MAY": 4.55,
    "JUN": 4.42,
    "JUL": 4.44,
    "AUG": 4.97,
    "SEP": 5.2,
    "OCT": 5.11,
    "NOV": 4.63,
    "DEC": 5.24
  },
  {
    "LONG": -44.27,
    "LAT": -18.1,
    "CIDADE": "Augusto de Lima",
    "UF": "MINAS GERAIS",
    "anual": 5.7,
    "JAN": 5.58,
    "FEB": 6.18,
    "MAR": 5.64,
    "APR": 5.81,
    "MAY": 5.6,
    "JUN": 5.37,
    "JUL": 5.82,
    "AUG": 6.17,
    "SEP": 6.22,
    "OCT": 5.81,
    "NOV": 4.98,
    "DEC": 5.23
  },
  {
    "LONG": -43.47,
    "LAT": -18.07,
    "CIDADE": "Couto de Magalhães de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.41,
    "FEB": 5.92,
    "MAR": 5.25,
    "APR": 5.2,
    "MAY": 4.89,
    "JUN": 4.91,
    "JUL": 5.16,
    "AUG": 5.77,
    "SEP": 5.81,
    "OCT": 5.36,
    "NOV": 4.6,
    "DEC": 5.11
  },
  {
    "LONG": -43.24,
    "LAT": -1.81,
    "CIDADE": "Felício dos Santos",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 5.29,
    "FEB": 5.87,
    "MAR": 5.23,
    "APR": 5.09,
    "MAY": 4.8,
    "JUN": 4.8,
    "JUL": 4.98,
    "AUG": 5.61,
    "SEP": 5.69,
    "OCT": 5.29,
    "NOV": 4.6,
    "DEC": 5.09
  },
  {
    "LONG": -42.57,
    "LAT": -18.09,
    "CIDADE": "São Sebastião do Maranhão",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.4,
    "FEB": 5.93,
    "MAR": 5.31,
    "APR": 5.02,
    "MAY": 4.59,
    "JUN": 4.49,
    "JUL": 4.56,
    "AUG": 5.24,
    "SEP": 5.38,
    "OCT": 5.17,
    "NOV": 4.52,
    "DEC": 5.1
  },
  {
    "LONG": -41.43,
    "LAT": -18.07,
    "CIDADE": "Frei Gaspar",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.46,
    "FEB": 5.83,
    "MAR": 5.35,
    "APR": 5.01,
    "MAY": 4.39,
    "JUN": 4.28,
    "JUL": 4.32,
    "AUG": 4.86,
    "SEP": 5.07,
    "OCT": 4.98,
    "NOV": 4.56,
    "DEC": 5.18
  },
  {
    "LONG": -41.27,
    "LAT": -18.07,
    "CIDADE": "Ouro Verde de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.45,
    "FEB": 5.89,
    "MAR": 5.38,
    "APR": 5,
    "MAY": 4.41,
    "JUN": 4.29,
    "JUL": 4.3,
    "AUG": 4.82,
    "SEP": 5.09,
    "OCT": 5.02,
    "NOV": 4.61,
    "DEC": 5.2
  },
  {
    "LONG": -46.9,
    "LAT": -17.99,
    "CIDADE": "Vazante",
    "UF": "MINAS GERAIS",
    "anual": 5.6,
    "JAN": 5.32,
    "FEB": 5.79,
    "MAR": 5.29,
    "APR": 5.7,
    "MAY": 5.6,
    "JUN": 5.46,
    "JUL": 5.83,
    "AUG": 6.44,
    "SEP": 6.03,
    "OCT": 5.66,
    "NOV": 5.01,
    "DEC": 5.14
  },
  {
    "LONG": -43.38,
    "LAT": -18,
    "CIDADE": "São Gonçalo do Rio Preto",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.4,
    "FEB": 5.94,
    "MAR": 5.25,
    "APR": 5.1,
    "MAY": 4.79,
    "JUN": 4.82,
    "JUL": 4.94,
    "AUG": 5.66,
    "SEP": 5.75,
    "OCT": 5.33,
    "NOV": 4.62,
    "DEC": 5.14
  },
  {
    "LONG": -4.24,
    "LAT": -17.99,
    "CIDADE": "Água Boa",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.51,
    "FEB": 6.01,
    "MAR": 5.39,
    "APR": 5.08,
    "MAY": 4.62,
    "JUN": 4.51,
    "JUL": 4.64,
    "AUG": 5.23,
    "SEP": 5.39,
    "OCT": 5.25,
    "NOV": 4.6,
    "DEC": 5.21
  },
  {
    "LONG": -4.2,
    "LAT": "#VALOR!",
    "CIDADE": "Franciscópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.5,
    "FEB": 5.9,
    "MAR": 5.42,
    "APR": 5.03,
    "MAY": 4.56,
    "JUN": 4.42,
    "JUL": 4.51,
    "AUG": 5.08,
    "SEP": 5.31,
    "OCT": 5.26,
    "NOV": 4.66,
    "DEC": 5.26
  },
  {
    "LONG": -41.68,
    "LAT": -18.04,
    "CIDADE": "Itambacuri",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.4,
    "FEB": 5.92,
    "MAR": 5.35,
    "APR": 4.97,
    "MAY": 4.38,
    "JUN": 4.26,
    "JUL": 4.34,
    "AUG": 4.96,
    "SEP": 5.13,
    "OCT": 5.09,
    "NOV": 4.59,
    "DEC": 5.18
  },
  {
    "LONG": -41.12,
    "LAT": -18.04,
    "CIDADE": "Ataléia",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.47,
    "FEB": 5.91,
    "MAR": 5.37,
    "APR": 4.97,
    "MAY": 4.43,
    "JUN": 4.19,
    "JUL": 4.26,
    "AUG": 4.72,
    "SEP": 4.98,
    "OCT": 4.93,
    "NOV": 4.59,
    "DEC": 5.2
  },
  {
    "LONG": -4.46,
    "LAT": -1.79,
    "CIDADE": "Lassance",
    "UF": "MINAS GERAIS",
    "anual": 5.74,
    "JAN": 5.53,
    "FEB": 6.14,
    "MAR": 5.67,
    "APR": 5.92,
    "MAY": 5.69,
    "JUN": 5.47,
    "JUL": 5.85,
    "AUG": 6.21,
    "SEP": 6.28,
    "OCT": 5.75,
    "NOV": 5.06,
    "DEC": 5.34
  },
  {
    "LONG": -44.18,
    "LAT": -17.87,
    "CIDADE": "Buenópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.56,
    "FEB": 6.14,
    "MAR": 5.49,
    "APR": 5.68,
    "MAY": 5.48,
    "JUN": 5.39,
    "JUL": 5.73,
    "AUG": 6.21,
    "SEP": 6.23,
    "OCT": 5.73,
    "NOV": 4.88,
    "DEC": 5.28
  },
  {
    "LONG": -4.32,
    "LAT": -1.79,
    "CIDADE": "Senador Modestino Gonçalves",
    "UF": "MINAS GERAIS",
    "anual": 5.21,
    "JAN": 5.35,
    "FEB": 5.84,
    "MAR": 5.29,
    "APR": 5.05,
    "MAY": 4.76,
    "JUN": 4.81,
    "JUL": 4.94,
    "AUG": 5.66,
    "SEP": 5.74,
    "OCT": 5.32,
    "NOV": 4.65,
    "DEC": 5.17
  },
  {
    "LONG": -42.86,
    "LAT": -17.86,
    "CIDADE": "Itamarandiba",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.32,
    "FEB": 5.84,
    "MAR": 5.17,
    "APR": 4.96,
    "MAY": 4.61,
    "JUN": 4.62,
    "JUL": 4.73,
    "AUG": 5.48,
    "SEP": 5.58,
    "OCT": 5.25,
    "NOV": 4.58,
    "DEC": 5.08
  },
  {
    "LONG": -4.26,
    "LAT": -1.79,
    "CIDADE": "Aricanduva",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.32,
    "FEB": 5.87,
    "MAR": 5.23,
    "APR": 4.91,
    "MAY": 4.5,
    "JUN": 4.49,
    "JUL": 4.64,
    "AUG": 5.31,
    "SEP": 5.47,
    "OCT": 5.2,
    "NOV": 4.51,
    "DEC": 5.09
  },
  {
    "LONG": -41.51,
    "LAT": "#VALOR!",
    "CIDADE": "Teófilo Otoni",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.56,
    "FEB": 5.96,
    "MAR": 5.44,
    "APR": 5.01,
    "MAY": 4.4,
    "JUN": 4.22,
    "JUL": 4.3,
    "AUG": 4.88,
    "SEP": 5.07,
    "OCT": 5.05,
    "NOV": 4.62,
    "DEC": 5.25
  },
  {
    "LONG": -47.1,
    "LAT": -17.77,
    "CIDADE": "Guarda-Mor",
    "UF": "MINAS GERAIS",
    "anual": 5.6,
    "JAN": 5.25,
    "FEB": 5.85,
    "MAR": 5.29,
    "APR": 5.77,
    "MAY": 5.62,
    "JUN": 5.49,
    "JUL": 5.75,
    "AUG": 6.48,
    "SEP": 5.98,
    "OCT": 5.66,
    "NOV": 5.02,
    "DEC": 5.1
  },
  {
    "LONG": -46.52,
    "LAT": -17.84,
    "CIDADE": "Lagoa Grande",
    "UF": "MINAS GERAIS",
    "anual": 5.72,
    "JAN": 5.48,
    "FEB": 6.02,
    "MAR": 5.53,
    "APR": 5.82,
    "MAY": 5.64,
    "JUN": 5.55,
    "JUL": 5.85,
    "AUG": 6.4,
    "SEP": 6.12,
    "OCT": 5.77,
    "NOV": 5.12,
    "DEC": 5.31
  },
  {
    "LONG": -4.42,
    "LAT": -1.78,
    "CIDADE": "Joaquim Felício",
    "UF": "MINAS GERAIS",
    "anual": 5.64,
    "JAN": 5.48,
    "FEB": 6.11,
    "MAR": 5.44,
    "APR": 5.62,
    "MAY": 5.46,
    "JUN": 5.42,
    "JUL": 5.76,
    "AUG": 6.3,
    "SEP": 6.25,
    "OCT": 5.72,
    "NOV": 4.88,
    "DEC": 5.27
  },
  {
    "LONG": -42.08,
    "LAT": -17.85,
    "CIDADE": "Malacacheta",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.28,
    "FEB": 5.78,
    "MAR": 5.23,
    "APR": 4.8,
    "MAY": 4.38,
    "JUN": 4.37,
    "JUL": 4.52,
    "AUG": 5.09,
    "SEP": 5.32,
    "OCT": 5.18,
    "NOV": 4.49,
    "DEC": 5.06
  },
  {
    "LONG": -41.79,
    "LAT": -17.81,
    "CIDADE": "Poté",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.5,
    "FEB": 5.96,
    "MAR": 5.45,
    "APR": 4.97,
    "MAY": 4.44,
    "JUN": 4.26,
    "JUL": 4.34,
    "AUG": 4.97,
    "SEP": 5.16,
    "OCT": 5.17,
    "NOV": 4.65,
    "DEC": 5.28
  },
  {
    "LONG": -40.35,
    "LAT": -17.85,
    "CIDADE": "Nanuque",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.57,
    "FEB": 5.9,
    "MAR": 5.51,
    "APR": 5.07,
    "MAY": 4.43,
    "JUN": 4.19,
    "JUL": 4.25,
    "AUG": 4.8,
    "SEP": 5.06,
    "OCT": 5.05,
    "NOV": 4.81,
    "DEC": 5.46
  },
  {
    "LONG": -40.25,
    "LAT": -17.79,
    "CIDADE": "Serra dos Aimorés",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.6,
    "FEB": 5.88,
    "MAR": 5.48,
    "APR": 5.06,
    "MAY": 4.42,
    "JUN": 4.13,
    "JUL": 4.22,
    "AUG": 4.81,
    "SEP": 5.05,
    "OCT": 5.06,
    "NOV": 4.77,
    "DEC": 5.47
  },
  {
    "LONG": -4.62,
    "LAT": -17.74,
    "CIDADE": "João Pinheiro",
    "UF": "MINAS GERAIS",
    "anual": 5.72,
    "JAN": 5.55,
    "FEB": 6.02,
    "MAR": 5.54,
    "APR": 5.77,
    "MAY": 5.64,
    "JUN": 5.53,
    "JUL": 5.83,
    "AUG": 6.45,
    "SEP": 6.11,
    "OCT": 5.68,
    "NOV": 5.14,
    "DEC": 5.33
  },
  {
    "LONG": -42.52,
    "LAT": -17.69,
    "CIDADE": "Capelinha",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 5.34,
    "FEB": 5.83,
    "MAR": 5.24,
    "APR": 4.9,
    "MAY": 4.6,
    "JUN": 4.55,
    "JUL": 4.68,
    "AUG": 5.37,
    "SEP": 5.56,
    "OCT": 5.27,
    "NOV": 4.57,
    "DEC": 5.19
  },
  {
    "LONG": -4.23,
    "LAT": -1.77,
    "CIDADE": "Angelândia",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.33,
    "FEB": 5.83,
    "MAR": 5.12,
    "APR": 4.82,
    "MAY": 4.46,
    "JUN": 4.42,
    "JUL": 4.62,
    "AUG": 5.22,
    "SEP": 5.45,
    "OCT": 5.22,
    "NOV": 4.54,
    "DEC": 5.08
  },
  {
    "LONG": -40.77,
    "LAT": -17.7,
    "CIDADE": "Carlos Chagas",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.58,
    "FEB": 5.97,
    "MAR": 5.47,
    "APR": 5.08,
    "MAY": 4.42,
    "JUN": 4.18,
    "JUL": 4.21,
    "AUG": 4.77,
    "SEP": 5.09,
    "OCT": 5.05,
    "NOV": 4.8,
    "DEC": 5.43
  },
  {
    "LONG": -4.47,
    "LAT": -1.76,
    "CIDADE": "Várzea da Palma",
    "UF": "MINAS GERAIS",
    "anual": 5.79,
    "JAN": 5.64,
    "FEB": 6.18,
    "MAR": 5.67,
    "APR": 5.93,
    "MAY": 5.63,
    "JUN": 5.46,
    "JUL": 5.8,
    "AUG": 6.32,
    "SEP": 6.34,
    "OCT": 5.87,
    "NOV": 5.22,
    "DEC": 5.39
  },
  {
    "LONG": -4.22,
    "LAT": -17.6,
    "CIDADE": "Setubinha",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.46,
    "FEB": 5.87,
    "MAR": 5.27,
    "APR": 4.88,
    "MAY": 4.45,
    "JUN": 4.37,
    "JUL": 4.55,
    "AUG": 5.17,
    "SEP": 5.36,
    "OCT": 5.23,
    "NOV": 4.58,
    "DEC": 5.19
  },
  {
    "LONG": -41.75,
    "LAT": -17.63,
    "CIDADE": "Ladainha",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.43,
    "FEB": 5.95,
    "MAR": 5.34,
    "APR": 4.99,
    "MAY": 4.48,
    "JUN": 4.26,
    "JUL": 4.4,
    "AUG": 5,
    "SEP": 5.22,
    "OCT": 5.22,
    "NOV": 4.62,
    "DEC": 5.19
  },
  {
    "LONG": -4.3,
    "LAT": -1.75,
    "CIDADE": "Carbonita",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.48,
    "FEB": 6.02,
    "MAR": 5.3,
    "APR": 5.07,
    "MAY": 4.75,
    "JUN": 4.79,
    "JUL": 4.96,
    "AUG": 5.63,
    "SEP": 5.72,
    "OCT": 5.45,
    "NOV": 4.73,
    "DEC": 5.22
  },
  {
    "LONG": -4.19,
    "LAT": -17.47,
    "CIDADE": "Novo Cruzeiro",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.3,
    "FEB": 5.83,
    "MAR": 5.19,
    "APR": 4.92,
    "MAY": 4.43,
    "JUN": 4.28,
    "JUL": 4.45,
    "AUG": 5.06,
    "SEP": 5.29,
    "OCT": 5.2,
    "NOV": 4.59,
    "DEC": 5.16
  },
  {
    "LONG": -4.5,
    "LAT": -1.74,
    "CIDADE": "Buritizeiro",
    "UF": "MINAS GERAIS",
    "anual": 5.82,
    "JAN": 5.72,
    "FEB": 6.25,
    "MAR": 5.69,
    "APR": 6.02,
    "MAY": 5.68,
    "JUN": 5.43,
    "JUL": 5.85,
    "AUG": 6.36,
    "SEP": 6.36,
    "OCT": 5.91,
    "NOV": 5.22,
    "DEC": 5.39
  },
  {
    "LONG": -43.57,
    "LAT": -17.4,
    "CIDADE": "Olhos-d'Água",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.53,
    "FEB": 6.09,
    "MAR": 5.48,
    "APR": 5.34,
    "MAY": 5.15,
    "JUN": 5.1,
    "JUL": 5.25,
    "AUG": 6.02,
    "SEP": 6.06,
    "OCT": 5.62,
    "NOV": 4.88,
    "DEC": 5.35
  },
  {
    "LONG": -42.73,
    "LAT": -17.4,
    "CIDADE": "Veredinha",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.51,
    "FEB": 6,
    "MAR": 5.35,
    "APR": 5.05,
    "MAY": 4.66,
    "JUN": 4.67,
    "JUL": 4.83,
    "AUG": 5.49,
    "SEP": 5.62,
    "OCT": 5.39,
    "NOV": 4.76,
    "DEC": 5.25
  },
  {
    "LONG": "#VALOR!",
    "LAT": -17.4,
    "CIDADE": "Itaipé",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.45,
    "FEB": 5.99,
    "MAR": 5.32,
    "APR": 4.84,
    "MAY": 4.39,
    "JUN": 4.21,
    "JUL": 4.31,
    "AUG": 4.99,
    "SEP": 5.2,
    "OCT": 5.19,
    "NOV": 4.64,
    "DEC": 5.19
  },
  {
    "LONG": -41.22,
    "LAT": -17.41,
    "CIDADE": "Novo Oriente de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 5.69,
    "FEB": 6.19,
    "MAR": 5.57,
    "APR": 5.12,
    "MAY": 4.53,
    "JUN": 4.15,
    "JUL": 4.32,
    "AUG": 4.96,
    "SEP": 5.2,
    "OCT": 5.16,
    "NOV": 4.78,
    "DEC": 5.44
  },
  {
    "LONG": -41,
    "LAT": -17.43,
    "CIDADE": "Pavão",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.6,
    "FEB": 6.05,
    "MAR": 5.5,
    "APR": 5.04,
    "MAY": 4.43,
    "JUN": 4.1,
    "JUL": 4.24,
    "AUG": 4.86,
    "SEP": 5.14,
    "OCT": 5.08,
    "NOV": 4.8,
    "DEC": 5.46
  },
  {
    "LONG": -44.93,
    "LAT": -17.34,
    "CIDADE": "Pirapora",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.75,
    "FEB": 6.26,
    "MAR": 5.68,
    "APR": 6.03,
    "MAY": 5.72,
    "JUN": 5.48,
    "JUL": 5.89,
    "AUG": 6.44,
    "SEP": 6.33,
    "OCT": 5.9,
    "NOV": 5.18,
    "DEC": 5.39
  },
  {
    "LONG": -44.23,
    "LAT": -17.31,
    "CIDADE": "Francisco Dumont",
    "UF": "MINAS GERAIS",
    "anual": 5.81,
    "JAN": 5.75,
    "FEB": 6.32,
    "MAR": 5.81,
    "APR": 5.92,
    "MAY": 5.67,
    "JUN": 5.44,
    "JUL": 5.79,
    "AUG": 6.28,
    "SEP": 6.29,
    "OCT": 5.87,
    "NOV": 5.17,
    "DEC": 5.46
  },
  {
    "LONG": -43.95,
    "LAT": -17.28,
    "CIDADE": "Engenheiro Navarro",
    "UF": "MINAS GERAIS",
    "anual": 5.74,
    "JAN": 5.68,
    "FEB": 6.26,
    "MAR": 5.67,
    "APR": 5.78,
    "MAY": 5.53,
    "JUN": 5.36,
    "JUL": 5.69,
    "AUG": 6.29,
    "SEP": 6.3,
    "OCT": 5.82,
    "NOV": 5.08,
    "DEC": 5.44
  },
  {
    "LONG": -42.73,
    "LAT": -17.28,
    "CIDADE": "Turmalina",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.55,
    "FEB": 6.07,
    "MAR": 5.44,
    "APR": 5.09,
    "MAY": 4.68,
    "JUN": 4.68,
    "JUL": 4.84,
    "AUG": 5.51,
    "SEP": 5.65,
    "OCT": 5.44,
    "NOV": 4.82,
    "DEC": 5.28
  },
  {
    "LONG": -4.15,
    "LAT": -17.3,
    "CIDADE": "Catuji",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.45,
    "FEB": 5.93,
    "MAR": 5.31,
    "APR": 4.83,
    "MAY": 4.36,
    "JUN": 4.14,
    "JUL": 4.25,
    "AUG": 4.95,
    "SEP": 5.16,
    "OCT": 5.15,
    "NOV": 4.63,
    "DEC": 5.2
  },
  {
    "LONG": -40.58,
    "LAT": -17.26,
    "CIDADE": "Umburatiba",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.57,
    "FEB": 6.01,
    "MAR": 5.54,
    "APR": 5.08,
    "MAY": 4.4,
    "JUN": 4.09,
    "JUL": 4.25,
    "AUG": 4.82,
    "SEP": 5.22,
    "OCT": 5.17,
    "NOV": 4.88,
    "DEC": 5.58
  },
  {
    "LONG": -46.87,
    "LAT": -17.23,
    "CIDADE": "Paracatu",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.38,
    "FEB": 5.92,
    "MAR": 5.41,
    "APR": 5.81,
    "MAY": 5.62,
    "JUN": 5.5,
    "JUL": 5.88,
    "AUG": 6.43,
    "SEP": 5.95,
    "OCT": 5.69,
    "NOV": 5.05,
    "DEC": 5.21
  },
  {
    "LONG": -44.44,
    "LAT": -17.23,
    "CIDADE": "Jequitaí",
    "UF": "MINAS GERAIS",
    "anual": 5.82,
    "JAN": 5.75,
    "FEB": 6.3,
    "MAR": 5.79,
    "APR": 6.04,
    "MAY": 5.75,
    "JUN": 5.47,
    "JUL": 5.78,
    "AUG": 6.23,
    "SEP": 6.25,
    "OCT": 5.9,
    "NOV": 5.11,
    "DEC": 5.46
  },
  {
    "LONG": -42.59,
    "LAT": -17.22,
    "CIDADE": "Minas Novas",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.55,
    "FEB": 6.06,
    "MAR": 5.44,
    "APR": 5.06,
    "MAY": 4.64,
    "JUN": 4.6,
    "JUL": 4.78,
    "AUG": 5.47,
    "SEP": 5.61,
    "OCT": 5.41,
    "NOV": 4.81,
    "DEC": 5.31
  },
  {
    "LONG": -41.7,
    "LAT": -17.19,
    "CIDADE": "Caraí",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.43,
    "FEB": 5.98,
    "MAR": 5.35,
    "APR": 4.92,
    "MAY": 4.49,
    "JUN": 4.29,
    "JUL": 4.46,
    "AUG": 5.19,
    "SEP": 5.4,
    "OCT": 5.32,
    "NOV": 4.74,
    "DEC": 5.26
  },
  {
    "LONG": -40.92,
    "LAT": -17.24,
    "CIDADE": "Crisólita",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.64,
    "FEB": 6.12,
    "MAR": 5.54,
    "APR": 5.1,
    "MAY": 4.45,
    "JUN": 4.15,
    "JUL": 4.3,
    "AUG": 4.85,
    "SEP": 5.23,
    "OCT": 5.15,
    "NOV": 4.84,
    "DEC": 5.54
  },
  {
    "LONG": -4.42,
    "LAT": "#VALOR!",
    "CIDADE": "Claro dos Poções",
    "UF": "MINAS GERAIS",
    "anual": 5.76,
    "JAN": 5.7,
    "FEB": 6.2,
    "MAR": 5.71,
    "APR": 5.88,
    "MAY": 5.62,
    "JUN": 5.44,
    "JUL": 5.77,
    "AUG": 6.35,
    "SEP": 6.3,
    "OCT": 5.76,
    "NOV": 5.01,
    "DEC": 5.38
  },
  {
    "LONG": -43.81,
    "LAT": -1.71,
    "CIDADE": "Bocaiúva",
    "UF": "MINAS GERAIS",
    "anual": 5.72,
    "JAN": 5.65,
    "FEB": 6.2,
    "MAR": 5.62,
    "APR": 5.77,
    "MAY": 5.48,
    "JUN": 5.33,
    "JUL": 5.74,
    "AUG": 6.29,
    "SEP": 6.3,
    "OCT": 5.82,
    "NOV": 5.07,
    "DEC": 5.44
  },
  {
    "LONG": -43.31,
    "LAT": -17.06,
    "CIDADE": "Itacambira",
    "UF": "MINAS GERAIS",
    "anual": 5.57,
    "JAN": 5.62,
    "FEB": 6.17,
    "MAR": 5.43,
    "APR": 5.32,
    "MAY": 5.22,
    "JUN": 5.21,
    "JUL": 5.41,
    "AUG": 6.16,
    "SEP": 6.2,
    "OCT": 5.84,
    "NOV": 4.9,
    "DEC": 5.37
  },
  {
    "LONG": -42.69,
    "LAT": -17.08,
    "CIDADE": "Leme do Prado",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.55,
    "FEB": 6.15,
    "MAR": 5.48,
    "APR": 5.12,
    "MAY": 4.72,
    "JUN": 4.66,
    "JUL": 4.85,
    "AUG": 5.55,
    "SEP": 5.67,
    "OCT": 5.47,
    "NOV": 4.85,
    "DEC": 5.3
  },
  {
    "LONG": -42.54,
    "LAT": -17.09,
    "CIDADE": "Chapada do Norte",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.62,
    "FEB": 6.13,
    "MAR": 5.48,
    "APR": 5.08,
    "MAY": 4.67,
    "JUN": 4.61,
    "JUL": 4.78,
    "AUG": 5.48,
    "SEP": 5.65,
    "OCT": 5.47,
    "NOV": 4.86,
    "DEC": 5.36
  },
  {
    "LONG": -42.26,
    "LAT": -17.08,
    "CIDADE": "Jenipapo de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.71,
    "FEB": 6.16,
    "MAR": 5.54,
    "APR": 5.14,
    "MAY": 4.69,
    "JUN": 4.57,
    "JUL": 4.77,
    "AUG": 5.43,
    "SEP": 5.65,
    "OCT": 5.48,
    "NOV": 4.93,
    "DEC": 5.46
  },
  {
    "LONG": -41.48,
    "LAT": -17.08,
    "CIDADE": "Padre Paraíso",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.48,
    "FEB": 5.97,
    "MAR": 5.34,
    "APR": 4.89,
    "MAY": 4.38,
    "JUN": 4.15,
    "JUL": 4.29,
    "AUG": 4.97,
    "SEP": 5.25,
    "OCT": 5.19,
    "NOV": 4.64,
    "DEC": 5.2
  },
  {
    "LONG": -40.94,
    "LAT": -17.08,
    "CIDADE": "Águas Formosas",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.64,
    "FEB": 6.16,
    "MAR": 5.56,
    "APR": 5.13,
    "MAY": 4.48,
    "JUN": 4.15,
    "JUL": 4.32,
    "AUG": 4.9,
    "SEP": 5.28,
    "OCT": 5.18,
    "NOV": 4.84,
    "DEC": 5.54
  },
  {
    "LONG": -40.72,
    "LAT": -17.07,
    "CIDADE": "Machacalis",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.57,
    "FEB": 6.07,
    "MAR": 5.55,
    "APR": 5.09,
    "MAY": 4.42,
    "JUN": 4.11,
    "JUL": 4.29,
    "AUG": 4.87,
    "SEP": 5.3,
    "OCT": 5.19,
    "NOV": 4.89,
    "DEC": 5.58
  },
  {
    "LONG": -40.58,
    "LAT": -17.06,
    "CIDADE": "Bertópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.57,
    "FEB": 5.97,
    "MAR": 5.54,
    "APR": 5.08,
    "MAY": 4.39,
    "JUN": 4.09,
    "JUL": 4.28,
    "AUG": 4.86,
    "SEP": 5.26,
    "OCT": 5.16,
    "NOV": 4.92,
    "DEC": 5.55
  },
  {
    "LONG": -46.01,
    "LAT": -17,
    "CIDADE": "Brasilândia de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.78,
    "JAN": 5.64,
    "FEB": 6.09,
    "MAR": 5.58,
    "APR": 5.97,
    "MAY": 5.77,
    "JUN": 5.59,
    "JUL": 5.94,
    "AUG": 6.49,
    "SEP": 6.08,
    "OCT": 5.78,
    "NOV": 5.09,
    "DEC": 5.36
  },
  {
    "LONG": -44.58,
    "LAT": -16.98,
    "CIDADE": "Lagoa dos Patos",
    "UF": "MINAS GERAIS",
    "anual": 5.75,
    "JAN": 5.65,
    "FEB": 6.17,
    "MAR": 5.61,
    "APR": 5.92,
    "MAY": 5.66,
    "JUN": 5.44,
    "JUL": 5.78,
    "AUG": 6.35,
    "SEP": 6.26,
    "OCT": 5.76,
    "NOV": 5.05,
    "DEC": 5.35
  },
  {
    "LONG": -4.37,
    "LAT": -1.7,
    "CIDADE": "Guaraciama",
    "UF": "MINAS GERAIS",
    "anual": 5.67,
    "JAN": 5.62,
    "FEB": 6.14,
    "MAR": 5.56,
    "APR": 5.61,
    "MAY": 5.47,
    "JUN": 5.3,
    "JUL": 5.6,
    "AUG": 6.19,
    "SEP": 6.26,
    "OCT": 5.8,
    "NOV": 5.05,
    "DEC": 5.47
  },
  {
    "LONG": -42.46,
    "LAT": -16.95,
    "CIDADE": "Berilo",
    "UF": "MINAS GERAIS",
    "anual": 5.33,
    "JAN": 5.74,
    "FEB": 6.2,
    "MAR": 5.61,
    "APR": 5.2,
    "MAY": 4.72,
    "JUN": 4.64,
    "JUL": 4.81,
    "AUG": 5.47,
    "SEP": 5.69,
    "OCT": 5.55,
    "NOV": 4.92,
    "DEC": 5.44
  },
  {
    "LONG": -42.36,
    "LAT": -16.99,
    "CIDADE": "Francisco Badaró",
    "UF": "MINAS GERAIS",
    "anual": 5.34,
    "JAN": 5.75,
    "FEB": 6.17,
    "MAR": 5.63,
    "APR": 5.22,
    "MAY": 4.73,
    "JUN": 4.64,
    "JUL": 4.83,
    "AUG": 5.47,
    "SEP": 5.68,
    "OCT": 5.56,
    "NOV": 4.94,
    "DEC": 5.43
  },
  {
    "LONG": -44.91,
    "LAT": -16.86,
    "CIDADE": "Ibiaí",
    "UF": "MINAS GERAIS",
    "anual": 5.86,
    "JAN": 5.8,
    "FEB": 6.24,
    "MAR": 5.74,
    "APR": 6.08,
    "MAY": 5.73,
    "JUN": 5.57,
    "JUL": 5.82,
    "AUG": 6.45,
    "SEP": 6.3,
    "OCT": 5.95,
    "NOV": 5.19,
    "DEC": 5.43
  },
  {
    "LONG": -44.35,
    "LAT": -16.85,
    "CIDADE": "São João da Lagoa",
    "UF": "MINAS GERAIS",
    "anual": 5.74,
    "JAN": 5.6,
    "FEB": 6.13,
    "MAR": 5.58,
    "APR": 5.87,
    "MAY": 5.66,
    "JUN": 5.47,
    "JUL": 5.81,
    "AUG": 6.38,
    "SEP": 6.25,
    "OCT": 5.78,
    "NOV": 5.02,
    "DEC": 5.38
  },
  {
    "LONG": -43.01,
    "LAT": -16.87,
    "CIDADE": "Botumirim",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 5.42,
    "FEB": 6.01,
    "MAR": 5.29,
    "APR": 5.01,
    "MAY": 4.81,
    "JUN": 4.69,
    "JUL": 4.99,
    "AUG": 5.68,
    "SEP": 5.87,
    "OCT": 5.6,
    "NOV": 4.82,
    "DEC": 5.3
  },
  {
    "LONG": -42.6,
    "LAT": -16.91,
    "CIDADE": "José Gonçalves de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.55,
    "FEB": 6.08,
    "MAR": 5.37,
    "APR": 5.09,
    "MAY": 4.66,
    "JUN": 4.6,
    "JUL": 4.83,
    "AUG": 5.55,
    "SEP": 5.71,
    "OCT": 5.48,
    "NOV": 4.82,
    "DEC": 5.29
  },
  {
    "LONG": -42.06,
    "LAT": -16.85,
    "CIDADE": "Araçuaí",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.8,
    "FEB": 6.21,
    "MAR": 5.67,
    "APR": 5.29,
    "MAY": 4.78,
    "JUN": 4.62,
    "JUL": 4.79,
    "AUG": 5.39,
    "SEP": 5.7,
    "OCT": 5.58,
    "NOV": 5.08,
    "DEC": 5.58
  },
  {
    "LONG": -41.25,
    "LAT": -16.87,
    "CIDADE": "Monte Formoso",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.45,
    "FEB": 6.01,
    "MAR": 5.35,
    "APR": 4.91,
    "MAY": 4.37,
    "JUN": 4.13,
    "JUL": 4.32,
    "AUG": 4.9,
    "SEP": 5.31,
    "OCT": 5.2,
    "NOV": 4.75,
    "DEC": 5.37
  },
  {
    "LONG": -40.92,
    "LAT": -16.89,
    "CIDADE": "Fronteira dos Vales",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.56,
    "FEB": 6.05,
    "MAR": 5.49,
    "APR": 5.08,
    "MAY": 4.46,
    "JUN": 4.19,
    "JUL": 4.29,
    "AUG": 4.92,
    "SEP": 5.34,
    "OCT": 5.18,
    "NOV": 4.76,
    "DEC": 5.49
  },
  {
    "LONG": -40.68,
    "LAT": -16.94,
    "CIDADE": "Santa Helena de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.42,
    "FEB": 5.9,
    "MAR": 5.37,
    "APR": 4.95,
    "MAY": 4.31,
    "JUN": 4.05,
    "JUL": 4.26,
    "AUG": 4.82,
    "SEP": 5.27,
    "OCT": 5.14,
    "NOV": 4.74,
    "DEC": 5.44
  },
  {
    "LONG": -43.69,
    "LAT": -16.85,
    "CIDADE": "Glaucilândia",
    "UF": "MINAS GERAIS",
    "anual": 5.72,
    "JAN": 5.73,
    "FEB": 6.21,
    "MAR": 5.64,
    "APR": 5.74,
    "MAY": 5.51,
    "JUN": 5.33,
    "JUL": 5.6,
    "AUG": 6.22,
    "SEP": 6.28,
    "OCT": 5.81,
    "NOV": 5.05,
    "DEC": 5.52
  },
  {
    "LONG": -43.59,
    "LAT": -16.85,
    "CIDADE": "Juramento",
    "UF": "MINAS GERAIS",
    "anual": 5.71,
    "JAN": 5.74,
    "FEB": 6.19,
    "MAR": 5.62,
    "APR": 5.71,
    "MAY": 5.49,
    "JUN": 5.34,
    "JUL": 5.58,
    "AUG": 6.15,
    "SEP": 6.27,
    "OCT": 5.85,
    "NOV": 5.06,
    "DEC": 5.52
  },
  {
    "LONG": -42.34,
    "LAT": -16.81,
    "CIDADE": "Virgem da Lapa",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.58,
    "FEB": 5.97,
    "MAR": 5.43,
    "APR": 5.17,
    "MAY": 4.65,
    "JUN": 4.57,
    "JUL": 4.77,
    "AUG": 5.39,
    "SEP": 5.63,
    "OCT": 5.45,
    "NOV": 4.84,
    "DEC": 5.35
  },
  {
    "LONG": -46.27,
    "LAT": -16.65,
    "CIDADE": "Dom Bosco",
    "UF": "MINAS GERAIS",
    "anual": 5.72,
    "JAN": 5.55,
    "FEB": 6.01,
    "MAR": 5.53,
    "APR": 5.83,
    "MAY": 5.71,
    "JUN": 5.52,
    "JUL": 5.92,
    "AUG": 6.55,
    "SEP": 6.07,
    "OCT": 5.73,
    "NOV": 4.94,
    "DEC": 5.3
  },
  {
    "LONG": -45.41,
    "LAT": -16.69,
    "CIDADE": "Santa Fé de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.71,
    "FEB": 6.26,
    "MAR": 5.67,
    "APR": 6.04,
    "MAY": 5.79,
    "JUN": 5.59,
    "JUL": 5.89,
    "AUG": 6.55,
    "SEP": 6.2,
    "OCT": 5.94,
    "NOV": 5.1,
    "DEC": 5.38
  },
  {
    "LONG": -44.36,
    "LAT": -16.68,
    "CIDADE": "Coração de Jesus",
    "UF": "MINAS GERAIS",
    "anual": 5.8,
    "JAN": 5.67,
    "FEB": 6.24,
    "MAR": 5.65,
    "APR": 5.93,
    "MAY": 5.69,
    "JUN": 5.55,
    "JUL": 5.83,
    "AUG": 6.49,
    "SEP": 6.26,
    "OCT": 5.78,
    "NOV": 5.04,
    "DEC": 5.43
  },
  {
    "LONG": -43.86,
    "LAT": -16.73,
    "CIDADE": "Montes Claros",
    "UF": "MINAS GERAIS",
    "anual": 5.73,
    "JAN": 5.65,
    "FEB": 6.21,
    "MAR": 5.6,
    "APR": 5.78,
    "MAY": 5.49,
    "JUN": 5.37,
    "JUL": 5.68,
    "AUG": 6.31,
    "SEP": 6.29,
    "OCT": 5.81,
    "NOV": 5.05,
    "DEC": 5.5
  },
  {
    "LONG": -42.86,
    "LAT": -16.72,
    "CIDADE": "Cristália",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.56,
    "FEB": 6.08,
    "MAR": 5.42,
    "APR": 5.12,
    "MAY": 4.75,
    "JUN": 4.59,
    "JUL": 4.83,
    "AUG": 5.59,
    "SEP": 5.76,
    "OCT": 5.54,
    "NOV": 4.81,
    "DEC": 5.35
  },
  {
    "LONG": -41.51,
    "LAT": -16.75,
    "CIDADE": "Ponto dos Volantes",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 5.6,
    "FEB": 6.12,
    "MAR": 5.48,
    "APR": 5.06,
    "MAY": 4.58,
    "JUN": 4.23,
    "JUL": 4.45,
    "AUG": 5.06,
    "SEP": 5.5,
    "OCT": 5.37,
    "NOV": 4.95,
    "DEC": 5.5
  },
  {
    "LONG": -41.02,
    "LAT": -16.65,
    "CIDADE": "Joaíma",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.64,
    "FEB": 6.12,
    "MAR": 5.55,
    "APR": 5.15,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.29,
    "AUG": 4.8,
    "SEP": 5.32,
    "OCT": 5.2,
    "NOV": 4.89,
    "DEC": 5.5
  },
  {
    "LONG": -40.43,
    "LAT": -16.74,
    "CIDADE": "Palmópolis",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.39,
    "FEB": 5.75,
    "MAR": 5.37,
    "APR": 4.94,
    "MAY": 4.35,
    "JUN": 4.04,
    "JUL": 4.13,
    "AUG": 4.71,
    "SEP": 5.19,
    "OCT": 5.01,
    "NOV": 4.72,
    "DEC": 5.4
  },
  {
    "LONG": -45.99,
    "LAT": -1.66,
    "CIDADE": "Bonfinópolis de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.78,
    "JAN": 5.62,
    "FEB": 6.12,
    "MAR": 5.58,
    "APR": 5.91,
    "MAY": 5.66,
    "JUN": 5.6,
    "JUL": 5.92,
    "AUG": 6.61,
    "SEP": 6.08,
    "OCT": 5.84,
    "NOV": 5.04,
    "DEC": 5.33
  },
  {
    "LONG": -45.06,
    "LAT": -16.63,
    "CIDADE": "Ponto Chique",
    "UF": "MINAS GERAIS",
    "anual": 5.89,
    "JAN": 5.76,
    "FEB": 6.28,
    "MAR": 5.84,
    "APR": 6.08,
    "MAY": 5.8,
    "JUN": 5.66,
    "JUL": 5.88,
    "AUG": 6.44,
    "SEP": 6.25,
    "OCT": 5.94,
    "NOV": 5.21,
    "DEC": 5.51
  },
  {
    "LONG": -42.89,
    "LAT": -16.57,
    "CIDADE": "Grão Mogol",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.54,
    "FEB": 6.07,
    "MAR": 5.42,
    "APR": 5.12,
    "MAY": 4.77,
    "JUN": 4.62,
    "JUL": 4.88,
    "AUG": 5.63,
    "SEP": 5.78,
    "OCT": 5.55,
    "NOV": 4.83,
    "DEC": 5.36
  },
  {
    "LONG": -42.18,
    "LAT": -16.62,
    "CIDADE": "Coronel Murta",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.62,
    "FEB": 6.05,
    "MAR": 5.49,
    "APR": 5.18,
    "MAY": 4.71,
    "JUN": 4.48,
    "JUL": 4.71,
    "AUG": 5.31,
    "SEP": 5.68,
    "OCT": 5.49,
    "NOV": 4.9,
    "DEC": 5.45
  },
  {
    "LONG": -41.77,
    "LAT": -16.61,
    "CIDADE": "Itinga",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.83,
    "FEB": 6.33,
    "MAR": 5.72,
    "APR": 5.33,
    "MAY": 4.79,
    "JUN": 4.53,
    "JUL": 4.71,
    "AUG": 5.35,
    "SEP": 5.74,
    "OCT": 5.6,
    "NOV": 5.14,
    "DEC": 5.66
  },
  {
    "LONG": -4.15,
    "LAT": -16.56,
    "CIDADE": "Itaobim",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.75,
    "FEB": 6.31,
    "MAR": 5.67,
    "APR": 5.25,
    "MAY": 4.72,
    "JUN": 4.37,
    "JUL": 4.59,
    "AUG": 5.19,
    "SEP": 5.68,
    "OCT": 5.55,
    "NOV": 5.1,
    "DEC": 5.66
  },
  {
    "LONG": -40.76,
    "LAT": -16.64,
    "CIDADE": "Felisburgo",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.45,
    "FEB": 5.87,
    "MAR": 5.35,
    "APR": 4.97,
    "MAY": 4.39,
    "JUN": 4.07,
    "JUL": 4.17,
    "AUG": 4.69,
    "SEP": 5.24,
    "OCT": 5.07,
    "NOV": 4.75,
    "DEC": 5.41
  },
  {
    "LONG": -40.57,
    "LAT": -16.61,
    "CIDADE": "Rio do Prado",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.53,
    "FEB": 5.97,
    "MAR": 5.5,
    "APR": 5.12,
    "MAY": 4.5,
    "JUN": 4.14,
    "JUL": 4.2,
    "AUG": 4.73,
    "SEP": 5.24,
    "OCT": 5.05,
    "NOV": 4.84,
    "DEC": 5.5
  },
  {
    "LONG": -46.49,
    "LAT": -16.51,
    "CIDADE": "Natalândia",
    "UF": "MINAS GERAIS",
    "anual": 5.67,
    "JAN": 5.46,
    "FEB": 5.97,
    "MAR": 5.45,
    "APR": 5.74,
    "MAY": 5.64,
    "JUN": 5.55,
    "JUL": 5.89,
    "AUG": 6.54,
    "SEP": 6.04,
    "OCT": 5.68,
    "NOV": 4.91,
    "DEC": 5.19
  },
  {
    "LONG": -44.81,
    "LAT": -1.65,
    "CIDADE": "Campo Azul",
    "UF": "MINAS GERAIS",
    "anual": 5.81,
    "JAN": 5.81,
    "FEB": 6.15,
    "MAR": 5.69,
    "APR": 5.96,
    "MAY": 5.65,
    "JUN": 5.5,
    "JUL": 5.8,
    "AUG": 6.47,
    "SEP": 6.24,
    "OCT": 5.87,
    "NOV": 5.1,
    "DEC": 5.47
  },
  {
    "LONG": -44.53,
    "LAT": -16.53,
    "CIDADE": "São João do Pacuí",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.77,
    "FEB": 6.25,
    "MAR": 5.74,
    "APR": 6,
    "MAY": 5.72,
    "JUN": 5.55,
    "JUL": 5.89,
    "AUG": 6.5,
    "SEP": 6.24,
    "OCT": 5.85,
    "NOV": 5.03,
    "DEC": 5.5
  },
  {
    "LONG": "#VALOR!",
    "LAT": -16.48,
    "CIDADE": "Francisco Sá",
    "UF": "MINAS GERAIS",
    "anual": 5.78,
    "JAN": 5.77,
    "FEB": 6.32,
    "MAR": 5.76,
    "APR": 5.84,
    "MAY": 5.52,
    "JUN": 5.36,
    "JUL": 5.63,
    "AUG": 6.15,
    "SEP": 6.27,
    "OCT": 5.95,
    "NOV": 5.19,
    "DEC": 5.58
  },
  {
    "LONG": -42.52,
    "LAT": -16.54,
    "CIDADE": "Josenópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 5.55,
    "FEB": 6.05,
    "MAR": 5.42,
    "APR": 5.06,
    "MAY": 4.59,
    "JUN": 4.49,
    "JUL": 4.78,
    "AUG": 5.45,
    "SEP": 5.71,
    "OCT": 5.52,
    "NOV": 4.86,
    "DEC": 5.34
  },
  {
    "LONG": -40.18,
    "LAT": -16.53,
    "CIDADE": "Santo Antônio do Jacinto",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.33,
    "FEB": 5.74,
    "MAR": 5.34,
    "APR": 5.05,
    "MAY": 4.39,
    "JUN": 4.13,
    "JUL": 4.2,
    "AUG": 4.68,
    "SEP": 5.11,
    "OCT": 4.97,
    "NOV": 4.71,
    "DEC": 5.35
  },
  {
    "LONG": -46.9,
    "LAT": -16.36,
    "CIDADE": "Unaí",
    "UF": "MINAS GERAIS",
    "anual": 5.7,
    "JAN": 5.42,
    "FEB": 5.9,
    "MAR": 5.55,
    "APR": 5.79,
    "MAY": 5.64,
    "JUN": 5.59,
    "JUL": 5.87,
    "AUG": 6.56,
    "SEP": 6.06,
    "OCT": 5.71,
    "NOV": 5.02,
    "DEC": 5.26
  },
  {
    "LONG": -45.07,
    "LAT": -16.37,
    "CIDADE": "São Romão",
    "UF": "MINAS GERAIS",
    "anual": 5.89,
    "JAN": 5.78,
    "FEB": 6.27,
    "MAR": 5.78,
    "APR": 6.07,
    "MAY": 5.79,
    "JUN": 5.63,
    "JUL": 5.9,
    "AUG": 6.54,
    "SEP": 6.25,
    "OCT": 5.97,
    "NOV": 5.18,
    "DEC": 5.49
  },
  {
    "LONG": -42.52,
    "LAT": -16.36,
    "CIDADE": "Padre Carvalho",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.54,
    "FEB": 6.07,
    "MAR": 5.42,
    "APR": 5.09,
    "MAY": 4.62,
    "JUN": 4.49,
    "JUL": 4.76,
    "AUG": 5.46,
    "SEP": 5.77,
    "OCT": 5.55,
    "NOV": 4.84,
    "DEC": 5.38
  },
  {
    "LONG": -42.26,
    "LAT": -16.41,
    "CIDADE": "Rubelita",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.72,
    "FEB": 6.11,
    "MAR": 5.52,
    "APR": 5.17,
    "MAY": 4.69,
    "JUN": 4.48,
    "JUL": 4.74,
    "AUG": 5.4,
    "SEP": 5.77,
    "OCT": 5.53,
    "NOV": 4.97,
    "DEC": 5.55
  },
  {
    "LONG": -41.01,
    "LAT": -16.44,
    "CIDADE": "Jequitinhonha",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.59,
    "FEB": 5.99,
    "MAR": 5.53,
    "APR": 5.16,
    "MAY": 4.56,
    "JUN": 4.27,
    "JUL": 4.41,
    "AUG": 4.94,
    "SEP": 5.44,
    "OCT": 5.25,
    "NOV": 4.83,
    "DEC": 5.46
  },
  {
    "LONG": -40.54,
    "LAT": -1.64,
    "CIDADE": "Rubim",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.54,
    "FEB": 6,
    "MAR": 5.55,
    "APR": 5.21,
    "MAY": 4.55,
    "JUN": 4.16,
    "JUL": 4.27,
    "AUG": 4.72,
    "SEP": 5.19,
    "OCT": 5.05,
    "NOV": 4.87,
    "DEC": 5.51
  },
  {
    "LONG": -44.78,
    "LAT": -1.63,
    "CIDADE": "Ubaí",
    "UF": "MINAS GERAIS",
    "anual": 5.83,
    "JAN": 5.72,
    "FEB": 6.18,
    "MAR": 5.7,
    "APR": 6,
    "MAY": 5.71,
    "JUN": 5.54,
    "JUL": 5.85,
    "AUG": 6.53,
    "SEP": 6.22,
    "OCT": 5.86,
    "NOV": 5.1,
    "DEC": 5.52
  },
  {
    "LONG": -44.16,
    "LAT": -1.63,
    "CIDADE": "Mirabela",
    "UF": "MINAS GERAIS",
    "anual": 5.85,
    "JAN": 5.78,
    "FEB": 6.27,
    "MAR": 5.73,
    "APR": 6.01,
    "MAY": 5.69,
    "JUN": 5.53,
    "JUL": 5.88,
    "AUG": 6.55,
    "SEP": 6.35,
    "OCT": 5.85,
    "NOV": 5.03,
    "DEC": 5.53
  },
  {
    "LONG": -43.71,
    "LAT": -1.63,
    "CIDADE": "Capitão Enéas",
    "UF": "MINAS GERAIS",
    "anual": 5.82,
    "JAN": 5.81,
    "FEB": 6.32,
    "MAR": 5.81,
    "APR": 5.86,
    "MAY": 5.6,
    "JUN": 5.44,
    "JUL": 5.73,
    "AUG": 6.34,
    "SEP": 6.29,
    "OCT": 5.86,
    "NOV": 5.19,
    "DEC": 5.62
  },
  {
    "LONG": -41.79,
    "LAT": -16.3,
    "CIDADE": "Comercinho",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.59,
    "FEB": 6.08,
    "MAR": 5.56,
    "APR": 5.15,
    "MAY": 4.63,
    "JUN": 4.36,
    "JUL": 4.61,
    "AUG": 5.17,
    "SEP": 5.69,
    "OCT": 5.45,
    "NOV": 4.95,
    "DEC": 5.51
  },
  {
    "LONG": -46,
    "LAT": -16.22,
    "CIDADE": "Riachinho",
    "UF": "MINAS GERAIS",
    "anual": 5.85,
    "JAN": 5.71,
    "FEB": 6.16,
    "MAR": 5.67,
    "APR": 6.07,
    "MAY": 5.82,
    "JUN": 5.65,
    "JUL": 5.96,
    "AUG": 6.59,
    "SEP": 6.22,
    "OCT": 5.89,
    "NOV": 5.04,
    "DEC": 5.43
  },
  {
    "LONG": -44.9,
    "LAT": -16.22,
    "CIDADE": "Icaraí de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.87,
    "JAN": 5.78,
    "FEB": 6.22,
    "MAR": 5.79,
    "APR": 6.02,
    "MAY": 5.73,
    "JUN": 5.59,
    "JUL": 5.85,
    "AUG": 6.57,
    "SEP": 6.23,
    "OCT": 5.9,
    "NOV": 5.21,
    "DEC": 5.54
  },
  {
    "LONG": -4.44,
    "LAT": -16.21,
    "CIDADE": "Brasília de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.7,
    "FEB": 6.22,
    "MAR": 5.69,
    "APR": 6.02,
    "MAY": 5.76,
    "JUN": 5.59,
    "JUL": 5.89,
    "AUG": 6.57,
    "SEP": 6.2,
    "OCT": 5.82,
    "NOV": 5.05,
    "DEC": 5.5
  },
  {
    "LONG": -4.23,
    "LAT": -16.17,
    "CIDADE": "Salinas",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.65,
    "FEB": 6.08,
    "MAR": 5.55,
    "APR": 5.17,
    "MAY": 4.7,
    "JUN": 4.46,
    "JUL": 4.71,
    "AUG": 5.38,
    "SEP": 5.81,
    "OCT": 5.51,
    "NOV": 5.03,
    "DEC": 5.56
  },
  {
    "LONG": -41.47,
    "LAT": -1.62,
    "CIDADE": "Medina",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.53,
    "FEB": 5.98,
    "MAR": 5.47,
    "APR": 5.11,
    "MAY": 4.56,
    "JUN": 4.2,
    "JUL": 4.4,
    "AUG": 5.01,
    "SEP": 5.55,
    "OCT": 5.32,
    "NOV": 4.93,
    "DEC": 5.43
  },
  {
    "LONG": -40.69,
    "LAT": -16.18,
    "CIDADE": "Almenara",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.64,
    "FEB": 6.11,
    "MAR": 5.61,
    "APR": 5.24,
    "MAY": 4.57,
    "JUN": 4.16,
    "JUL": 4.28,
    "AUG": 4.69,
    "SEP": 5.26,
    "OCT": 5.08,
    "NOV": 4.97,
    "DEC": 5.57
  },
  {
    "LONG": -40.15,
    "LAT": -16.25,
    "CIDADE": "Santa Maria do Salto",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.51,
    "FEB": 5.91,
    "MAR": 5.55,
    "APR": 5.12,
    "MAY": 4.43,
    "JUN": 4.12,
    "JUL": 4.24,
    "AUG": 4.6,
    "SEP": 5.07,
    "OCT": 4.92,
    "NOV": 4.79,
    "DEC": 5.44
  },
  {
    "LONG": -46.26,
    "LAT": -16.06,
    "CIDADE": "Uruana de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.77,
    "JAN": 5.55,
    "FEB": 6.09,
    "MAR": 5.58,
    "APR": 5.96,
    "MAY": 5.76,
    "JUN": 5.59,
    "JUL": 5.95,
    "AUG": 6.53,
    "SEP": 6.15,
    "OCT": 5.77,
    "NOV": 4.94,
    "DEC": 5.33
  },
  {
    "LONG": -4.57,
    "LAT": -16.12,
    "CIDADE": "Urucuia",
    "UF": "MINAS GERAIS",
    "anual": 5.85,
    "JAN": 5.72,
    "FEB": 6.14,
    "MAR": 5.63,
    "APR": 6,
    "MAY": 5.8,
    "JUN": 5.65,
    "JUL": 6,
    "AUG": 6.67,
    "SEP": 6.22,
    "OCT": 5.86,
    "NOV": 5.07,
    "DEC": 5.41
  },
  {
    "LONG": -45.15,
    "LAT": -16.06,
    "CIDADE": "Pintópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.94,
    "JAN": 5.86,
    "FEB": 6.34,
    "MAR": 5.86,
    "APR": 6.15,
    "MAY": 5.85,
    "JUN": 5.66,
    "JUL": 5.91,
    "AUG": 6.57,
    "SEP": 6.26,
    "OCT": 6.01,
    "NOV": 5.23,
    "DEC": 5.54
  },
  {
    "LONG": -44.59,
    "LAT": -16.12,
    "CIDADE": "Luislândia",
    "UF": "MINAS GERAIS",
    "anual": 5.85,
    "JAN": 5.78,
    "FEB": 6.26,
    "MAR": 5.73,
    "APR": 6.01,
    "MAY": 5.74,
    "JUN": 5.57,
    "JUL": 5.86,
    "AUG": 6.59,
    "SEP": 6.22,
    "OCT": 5.83,
    "NOV": 5.11,
    "DEC": 5.52
  },
  {
    "LONG": -44.08,
    "LAT": -16.08,
    "CIDADE": "Patis",
    "UF": "MINAS GERAIS",
    "anual": 5.83,
    "JAN": 5.76,
    "FEB": 6.28,
    "MAR": 5.78,
    "APR": 5.96,
    "MAY": 5.63,
    "JUN": 5.5,
    "JUL": 5.8,
    "AUG": 6.41,
    "SEP": 6.25,
    "OCT": 5.86,
    "NOV": 5.08,
    "DEC": 5.6
  },
  {
    "LONG": -42.52,
    "LAT": -16.12,
    "CIDADE": "Fruta de Leite",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.65,
    "FEB": 6.14,
    "MAR": 5.54,
    "APR": 5.19,
    "MAY": 4.78,
    "JUN": 4.55,
    "JUL": 4.9,
    "AUG": 5.58,
    "SEP": 5.9,
    "OCT": 5.63,
    "NOV": 4.96,
    "DEC": 5.52
  },
  {
    "LONG": -41.75,
    "LAT": -16.1,
    "CIDADE": "Santa Cruz de Salinas",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.51,
    "FEB": 6.02,
    "MAR": 5.48,
    "APR": 5.09,
    "MAY": 4.62,
    "JUN": 4.35,
    "JUL": 4.61,
    "AUG": 5.18,
    "SEP": 5.79,
    "OCT": 5.51,
    "NOV": 4.95,
    "DEC": 5.51
  },
  {
    "LONG": -40.3,
    "LAT": -16.14,
    "CIDADE": "Jacinto",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.51,
    "FEB": 5.92,
    "MAR": 5.59,
    "APR": 5.18,
    "MAY": 4.49,
    "JUN": 4.18,
    "JUL": 4.28,
    "AUG": 4.68,
    "SEP": 5.09,
    "OCT": 4.96,
    "NOV": 4.84,
    "DEC": 5.47
  },
  {
    "LONG": -47.09,
    "LAT": -1.6,
    "CIDADE": "Cabeceira Grande",
    "UF": "MINAS GERAIS",
    "anual": 5.58,
    "JAN": 5.23,
    "FEB": 5.68,
    "MAR": 5.3,
    "APR": 5.58,
    "MAY": 5.54,
    "JUN": 5.58,
    "JUL": 5.93,
    "AUG": 6.49,
    "SEP": 6.07,
    "OCT": 5.62,
    "NOV": 4.83,
    "DEC": 5.11
  },
  {
    "LONG": -44.86,
    "LAT": -15.95,
    "CIDADE": "São Francisco",
    "UF": "MINAS GERAIS",
    "anual": 5.89,
    "JAN": 5.83,
    "FEB": 6.26,
    "MAR": 5.86,
    "APR": 6.06,
    "MAY": 5.74,
    "JUN": 5.56,
    "JUL": 5.88,
    "AUG": 6.49,
    "SEP": 6.19,
    "OCT": 5.95,
    "NOV": 5.28,
    "DEC": 5.58
  },
  {
    "LONG": -44.28,
    "LAT": -16,
    "CIDADE": "Japonvar",
    "UF": "MINAS GERAIS",
    "anual": 5.88,
    "JAN": 5.77,
    "FEB": 6.27,
    "MAR": 5.8,
    "APR": 6.04,
    "MAY": 5.74,
    "JUN": 5.6,
    "JUL": 5.92,
    "AUG": 6.47,
    "SEP": 6.36,
    "OCT": 5.95,
    "NOV": 5.09,
    "DEC": 5.53
  },
  {
    "LONG": -43.05,
    "LAT": -16.01,
    "CIDADE": "Riacho dos Machados",
    "UF": "MINAS GERAIS",
    "anual": 5.76,
    "JAN": 5.92,
    "FEB": 6.29,
    "MAR": 5.78,
    "APR": 5.73,
    "MAY": 5.42,
    "JUN": 5.24,
    "JUL": 5.55,
    "AUG": 6.14,
    "SEP": 6.28,
    "OCT": 5.94,
    "NOV": 5.2,
    "DEC": 5.63
  },
  {
    "LONG": -42.4,
    "LAT": -16.02,
    "CIDADE": "Novorizonte",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.64,
    "FEB": 6.04,
    "MAR": 5.5,
    "APR": 5.17,
    "MAY": 4.73,
    "JUN": 4.49,
    "JUL": 4.83,
    "AUG": 5.5,
    "SEP": 5.87,
    "OCT": 5.6,
    "NOV": 4.99,
    "DEC": 5.52
  },
  {
    "LONG": -41.5,
    "LAT": -15.97,
    "CIDADE": "Cachoeira de Pajeú",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 5.55,
    "FEB": 6.03,
    "MAR": 5.48,
    "APR": 5.12,
    "MAY": 4.61,
    "JUN": 4.23,
    "JUL": 4.4,
    "AUG": 5.02,
    "SEP": 5.62,
    "OCT": 5.39,
    "NOV": 4.93,
    "DEC": 5.5
  },
  {
    "LONG": -41.29,
    "LAT": -16.01,
    "CIDADE": "Pedra Azul",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.57,
    "FEB": 6.02,
    "MAR": 5.49,
    "APR": 5.08,
    "MAY": 4.58,
    "JUN": 4.21,
    "JUL": 4.35,
    "AUG": 4.96,
    "SEP": 5.56,
    "OCT": 5.4,
    "NOV": 4.94,
    "DEC": 5.53
  },
  {
    "LONG": -39.94,
    "LAT": -16.01,
    "CIDADE": "Salto da Divisa",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.53,
    "FEB": 5.85,
    "MAR": 5.46,
    "APR": 5.06,
    "MAY": 4.38,
    "JUN": 4.1,
    "JUL": 4.25,
    "AUG": 4.64,
    "SEP": 5.11,
    "OCT": 4.98,
    "NOV": 4.82,
    "DEC": 5.49
  },
  {
    "LONG": -46.11,
    "LAT": -15.91,
    "CIDADE": "Arinos",
    "UF": "MINAS GERAIS",
    "anual": 5.82,
    "JAN": 5.6,
    "FEB": 6.1,
    "MAR": 5.62,
    "APR": 5.93,
    "MAY": 5.82,
    "JUN": 5.67,
    "JUL": 6.03,
    "AUG": 6.61,
    "SEP": 6.28,
    "OCT": 5.8,
    "NOV": 5.04,
    "DEC": 5.37
  },
  {
    "LONG": -44.31,
    "LAT": -15.9,
    "CIDADE": "Lontra",
    "UF": "MINAS GERAIS",
    "anual": 5.86,
    "JAN": 5.76,
    "FEB": 6.25,
    "MAR": 5.76,
    "APR": 6.01,
    "MAY": 5.72,
    "JUN": 5.56,
    "JUL": 5.89,
    "AUG": 6.46,
    "SEP": 6.29,
    "OCT": 5.92,
    "NOV": 5.12,
    "DEC": 5.57
  },
  {
    "LONG": -44.01,
    "LAT": -15.93,
    "CIDADE": "São João da Ponte",
    "UF": "MINAS GERAIS",
    "anual": 5.85,
    "JAN": 5.74,
    "FEB": 6.36,
    "MAR": 5.8,
    "APR": 6.01,
    "MAY": 5.66,
    "JUN": 5.52,
    "JUL": 5.82,
    "AUG": 6.43,
    "SEP": 6.28,
    "OCT": 5.9,
    "NOV": 5.13,
    "DEC": 5.59
  },
  {
    "LONG": -4.19,
    "LAT": -15.93,
    "CIDADE": "Curral de Dentro",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.67,
    "FEB": 5.99,
    "MAR": 5.54,
    "APR": 5.12,
    "MAY": 4.67,
    "JUN": 4.39,
    "JUL": 4.62,
    "AUG": 5.22,
    "SEP": 5.85,
    "OCT": 5.56,
    "NOV": 4.99,
    "DEC": 5.51
  },
  {
    "LONG": -40.56,
    "LAT": -15.88,
    "CIDADE": "Bandeira",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.58,
    "FEB": 5.95,
    "MAR": 5.61,
    "APR": 5.2,
    "MAY": 4.51,
    "JUN": 4.17,
    "JUL": 4.29,
    "AUG": 4.75,
    "SEP": 5.22,
    "OCT": 5.03,
    "NOV": 4.84,
    "DEC": 5.46
  },
  {
    "LONG": -4.02,
    "LAT": -15.9,
    "CIDADE": "Jordânia",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.47,
    "FEB": 5.89,
    "MAR": 5.59,
    "APR": 5.18,
    "MAY": 4.47,
    "JUN": 4.11,
    "JUL": 4.28,
    "AUG": 4.67,
    "SEP": 5.14,
    "OCT": 4.99,
    "NOV": 4.86,
    "DEC": 5.53
  },
  {
    "LONG": -43.31,
    "LAT": -15.8,
    "CIDADE": "Janaúba",
    "UF": "MINAS GERAIS",
    "anual": 5.88,
    "JAN": 6,
    "FEB": 6.44,
    "MAR": 5.93,
    "APR": 5.96,
    "MAY": 5.58,
    "JUN": 5.41,
    "JUL": 5.62,
    "AUG": 6.19,
    "SEP": 6.21,
    "OCT": 6.03,
    "NOV": 5.39,
    "DEC": 5.75
  },
  {
    "LONG": -43.3,
    "LAT": -15.8,
    "CIDADE": "Nova Porteirinha",
    "UF": "MINAS GERAIS",
    "anual": 5.86,
    "JAN": 5.98,
    "FEB": 6.4,
    "MAR": 5.93,
    "APR": 5.97,
    "MAY": 5.59,
    "JUN": 5.38,
    "JUL": 5.59,
    "AUG": 6.14,
    "SEP": 6.22,
    "OCT": 6.01,
    "NOV": 5.4,
    "DEC": 5.73
  },
  {
    "LONG": -42.87,
    "LAT": -15.81,
    "CIDADE": "Serranópolis de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 6,
    "FEB": 6.48,
    "MAR": 5.87,
    "APR": 5.88,
    "MAY": 5.5,
    "JUN": 5.34,
    "JUL": 5.53,
    "AUG": 6.13,
    "SEP": 6.3,
    "OCT": 6.02,
    "NOV": 5.29,
    "DEC": 5.69
  },
  {
    "LONG": -42.23,
    "LAT": -15.81,
    "CIDADE": "Taiobeiras",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.6,
    "FEB": 6.02,
    "MAR": 5.52,
    "APR": 5.08,
    "MAY": 4.7,
    "JUN": 4.41,
    "JUL": 4.71,
    "AUG": 5.37,
    "SEP": 5.85,
    "OCT": 5.55,
    "NOV": 5.03,
    "DEC": 5.52
  },
  {
    "LONG": -44.16,
    "LAT": -1.57,
    "CIDADE": "Ibiracatu",
    "UF": "MINAS GERAIS",
    "anual": 5.89,
    "JAN": 5.81,
    "FEB": 6.33,
    "MAR": 5.82,
    "APR": 6.12,
    "MAY": 5.74,
    "JUN": 5.57,
    "JUL": 5.9,
    "AUG": 6.42,
    "SEP": 6.35,
    "OCT": 5.93,
    "NOV": 5.12,
    "DEC": 5.56
  },
  {
    "LONG": -44.03,
    "LAT": -15.7,
    "CIDADE": "Varzelândia",
    "UF": "MINAS GERAIS",
    "anual": 5.89,
    "JAN": 5.83,
    "FEB": 6.39,
    "MAR": 5.83,
    "APR": 6.06,
    "MAY": 5.72,
    "JUN": 5.53,
    "JUL": 5.86,
    "AUG": 6.42,
    "SEP": 6.34,
    "OCT": 5.93,
    "NOV": 5.18,
    "DEC": 5.61
  },
  {
    "LONG": -43.03,
    "LAT": -15.74,
    "CIDADE": "Porteirinha",
    "UF": "MINAS GERAIS",
    "anual": 5.82,
    "JAN": 6.03,
    "FEB": 6.46,
    "MAR": 5.92,
    "APR": 5.9,
    "MAY": 5.5,
    "JUN": 5.32,
    "JUL": 5.54,
    "AUG": 5.98,
    "SEP": 6.1,
    "OCT": 5.97,
    "NOV": 5.36,
    "DEC": 5.79
  },
  {
    "LONG": -41.46,
    "LAT": -15.75,
    "CIDADE": "Águas Vermelhas",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.48,
    "FEB": 5.85,
    "MAR": 5.43,
    "APR": 5.04,
    "MAY": 4.59,
    "JUN": 4.2,
    "JUL": 4.41,
    "AUG": 4.97,
    "SEP": 5.65,
    "OCT": 5.42,
    "NOV": 4.93,
    "DEC": 5.46
  },
  {
    "LONG": -41.35,
    "LAT": -15.72,
    "CIDADE": "Divisa Alegre",
    "UF": "MINAS GERAIS",
    "anual": 5.13,
    "JAN": 5.49,
    "FEB": 5.9,
    "MAR": 5.45,
    "APR": 5.04,
    "MAY": 4.6,
    "JUN": 4.23,
    "JUL": 4.44,
    "AUG": 4.99,
    "SEP": 5.66,
    "OCT": 5.4,
    "NOV": 4.94,
    "DEC": 5.46
  },
  {
    "LONG": 0,
    "LAT": -15.73,
    "CIDADE": "Divisópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.47,
    "FEB": 5.8,
    "MAR": 5.44,
    "APR": 5.01,
    "MAY": 4.53,
    "JUN": 4.15,
    "JUL": 4.4,
    "AUG": 4.9,
    "SEP": 5.51,
    "OCT": 5.28,
    "NOV": 4.88,
    "DEC": 5.46
  },
  {
    "LONG": -40.74,
    "LAT": -15.69,
    "CIDADE": "Mata Verde",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.48,
    "FEB": 5.74,
    "MAR": 5.42,
    "APR": 4.94,
    "MAY": 4.38,
    "JUN": 4.02,
    "JUL": 4.2,
    "AUG": 4.66,
    "SEP": 5.29,
    "OCT": 5.14,
    "NOV": 4.85,
    "DEC": 5.43
  },
  {
    "LONG": -46.42,
    "LAT": -15.62,
    "CIDADE": "Buritis",
    "UF": "MINAS GERAIS",
    "anual": 5.79,
    "JAN": 5.57,
    "FEB": 6.01,
    "MAR": 5.61,
    "APR": 6.01,
    "MAY": 5.76,
    "JUN": 5.68,
    "JUL": 5.97,
    "AUG": 6.48,
    "SEP": 6.28,
    "OCT": 5.79,
    "NOV": 5,
    "DEC": 5.31
  },
  {
    "LONG": -44.39,
    "LAT": -15.6,
    "CIDADE": "Pedras de Maria da Cruz",
    "UF": "MINAS GERAIS",
    "anual": 5.94,
    "JAN": 5.9,
    "FEB": 6.39,
    "MAR": 6,
    "APR": 6.12,
    "MAY": 5.81,
    "JUN": 5.6,
    "JUL": 5.91,
    "AUG": 6.41,
    "SEP": 6.29,
    "OCT": 6,
    "NOV": 5.24,
    "DEC": 5.61
  },
  {
    "LONG": -4.36,
    "LAT": -15.59,
    "CIDADE": "Verdelândia",
    "UF": "MINAS GERAIS",
    "anual": 5.88,
    "JAN": 6.01,
    "FEB": 6.46,
    "MAR": 5.92,
    "APR": 5.98,
    "MAY": 5.61,
    "JUN": 5.42,
    "JUL": 5.75,
    "AUG": 6.26,
    "SEP": 6.14,
    "OCT": 5.94,
    "NOV": 5.34,
    "DEC": 5.73
  },
  {
    "LONG": -42.54,
    "LAT": -15.62,
    "CIDADE": "Rio Pardo de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.59,
    "FEB": 6.04,
    "MAR": 5.55,
    "APR": 5.22,
    "MAY": 4.79,
    "JUN": 4.58,
    "JUL": 4.9,
    "AUG": 5.55,
    "SEP": 5.93,
    "OCT": 5.61,
    "NOV": 5.01,
    "DEC": 5.52
  },
  {
    "LONG": -41.74,
    "LAT": -15.61,
    "CIDADE": "Berizal",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.63,
    "FEB": 6.03,
    "MAR": 5.5,
    "APR": 5.14,
    "MAY": 4.61,
    "JUN": 4.33,
    "JUL": 4.54,
    "AUG": 5.17,
    "SEP": 5.78,
    "OCT": 5.55,
    "NOV": 4.99,
    "DEC": 5.54
  },
  {
    "LONG": -44.36,
    "LAT": -15.48,
    "CIDADE": "Januária",
    "UF": "MINAS GERAIS",
    "anual": 5.97,
    "JAN": 5.93,
    "FEB": 6.42,
    "MAR": 6.03,
    "APR": 6.16,
    "MAY": 5.84,
    "JUN": 5.63,
    "JUL": 5.94,
    "AUG": 6.41,
    "SEP": 6.33,
    "OCT": 6.05,
    "NOV": 5.25,
    "DEC": 5.64
  },
  {
    "LONG": -43.07,
    "LAT": -15.53,
    "CIDADE": "Pai Pedro",
    "UF": "MINAS GERAIS",
    "anual": 5.9,
    "JAN": 6.07,
    "FEB": 6.48,
    "MAR": 6.04,
    "APR": 5.98,
    "MAY": 5.6,
    "JUN": 5.39,
    "JUL": 5.61,
    "AUG": 6.05,
    "SEP": 6.22,
    "OCT": 6.05,
    "NOV": 5.42,
    "DEC": 5.84
  },
  {
    "LONG": -42.2,
    "LAT": -15.49,
    "CIDADE": "Indaiabira",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.56,
    "FEB": 6.05,
    "MAR": 5.52,
    "APR": 5.11,
    "MAY": 4.7,
    "JUN": 4.41,
    "JUL": 4.71,
    "AUG": 5.34,
    "SEP": 5.87,
    "OCT": 5.58,
    "NOV": 5.01,
    "DEC": 5.52
  },
  {
    "LONG": -42.96,
    "LAT": -1.54,
    "CIDADE": "Catuti",
    "UF": "MINAS GERAIS",
    "anual": 5.91,
    "JAN": 6.08,
    "FEB": 6.53,
    "MAR": 6.07,
    "APR": 6.04,
    "MAY": 5.58,
    "JUN": 5.48,
    "JUL": 5.63,
    "AUG": 5.97,
    "SEP": 6.13,
    "OCT": 6.08,
    "NOV": 5.51,
    "DEC": 5.88
  },
  {
    "LONG": -42.86,
    "LAT": -15.39,
    "CIDADE": "Mato Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.93,
    "JAN": 6.08,
    "FEB": 6.51,
    "MAR": 6.12,
    "APR": 6.05,
    "MAY": 5.58,
    "JUN": 5.42,
    "JUL": 5.6,
    "AUG": 6.05,
    "SEP": 6.24,
    "OCT": 6.1,
    "NOV": 5.52,
    "DEC": 5.89
  },
  {
    "LONG": -42.31,
    "LAT": -15.4,
    "CIDADE": "Vargem Grande do Rio Pardo",
    "UF": "MINAS GERAIS",
    "anual": 5.34,
    "JAN": 5.51,
    "FEB": 6.03,
    "MAR": 5.55,
    "APR": 5.16,
    "MAY": 4.78,
    "JUN": 4.54,
    "JUL": 4.87,
    "AUG": 5.48,
    "SEP": 5.98,
    "OCT": 5.63,
    "NOV": 5,
    "DEC": 5.54
  },
  {
    "LONG": -45.62,
    "LAT": -15.3,
    "CIDADE": "Chapada Gaúcha",
    "UF": "MINAS GERAIS",
    "anual": 5.73,
    "JAN": 5.55,
    "FEB": 6,
    "MAR": 5.48,
    "APR": 5.92,
    "MAY": 5.65,
    "JUN": 5.62,
    "JUL": 5.93,
    "AUG": 6.45,
    "SEP": 6.19,
    "OCT": 5.7,
    "NOV": 4.95,
    "DEC": 5.35
  },
  {
    "LONG": -44.76,
    "LAT": -15.32,
    "CIDADE": "Bonito de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.9,
    "JAN": 5.81,
    "FEB": 6.22,
    "MAR": 5.79,
    "APR": 6.1,
    "MAY": 5.77,
    "JUN": 5.72,
    "JUL": 6.07,
    "AUG": 6.58,
    "SEP": 6.36,
    "OCT": 5.91,
    "NOV": 5.08,
    "DEC": 5.43
  },
  {
    "LONG": -44.41,
    "LAT": -15.3,
    "CIDADE": "Cônego Marinho",
    "UF": "MINAS GERAIS",
    "anual": 5.93,
    "JAN": 5.84,
    "FEB": 6.31,
    "MAR": 5.89,
    "APR": 6.11,
    "MAY": 5.84,
    "JUN": 5.68,
    "JUL": 6.07,
    "AUG": 6.48,
    "SEP": 6.36,
    "OCT": 5.93,
    "NOV": 5.12,
    "DEC": 5.53
  },
  {
    "LONG": -43.67,
    "LAT": -15.34,
    "CIDADE": "Jaíba",
    "UF": "MINAS GERAIS",
    "anual": 5.88,
    "JAN": 5.92,
    "FEB": 6.41,
    "MAR": 5.96,
    "APR": 6.02,
    "MAY": 5.63,
    "JUN": 5.49,
    "JUL": 5.72,
    "AUG": 6.16,
    "SEP": 6.14,
    "OCT": 6.01,
    "NOV": 5.31,
    "DEC": 5.75
  },
  {
    "LONG": -42.62,
    "LAT": -15.34,
    "CIDADE": "Santo Antônio do Retiro",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.63,
    "FEB": 6.07,
    "MAR": 5.53,
    "APR": 5.23,
    "MAY": 4.85,
    "JUN": 4.66,
    "JUL": 5.03,
    "AUG": 5.68,
    "SEP": 6.02,
    "OCT": 5.67,
    "NOV": 4.99,
    "DEC": 5.52
  },
  {
    "LONG": -42.02,
    "LAT": -15.32,
    "CIDADE": "São João do Paraíso",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.54,
    "FEB": 6,
    "MAR": 5.42,
    "APR": 5.11,
    "MAY": 4.68,
    "JUN": 4.41,
    "JUL": 4.65,
    "AUG": 5.29,
    "SEP": 5.91,
    "OCT": 5.62,
    "NOV": 5.05,
    "DEC": 5.5
  },
  {
    "LONG": -41.76,
    "LAT": "#VALOR!",
    "CIDADE": "Ninheira",
    "UF": "MINAS GERAIS",
    "anual": 5.21,
    "JAN": 5.57,
    "FEB": 5.87,
    "MAR": 5.5,
    "APR": 5.03,
    "MAY": 4.58,
    "JUN": 4.29,
    "JUL": 4.58,
    "AUG": 5.17,
    "SEP": 5.82,
    "OCT": 5.57,
    "NOV": 5.05,
    "DEC": 5.54
  },
  {
    "LONG": -42.87,
    "LAT": -15.15,
    "CIDADE": "Monte Azul",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.92,
    "FEB": 6.35,
    "MAR": 5.94,
    "APR": 5.85,
    "MAY": 5.5,
    "JUN": 5.42,
    "JUL": 5.6,
    "AUG": 6.15,
    "SEP": 6.27,
    "OCT": 6.03,
    "NOV": 5.33,
    "DEC": 5.76
  },
  {
    "LONG": -42.5,
    "LAT": -15.18,
    "CIDADE": "Montezuma",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.6,
    "FEB": 6.07,
    "MAR": 5.6,
    "APR": 5.23,
    "MAY": 4.89,
    "JUN": 4.66,
    "JUL": 5.04,
    "AUG": 5.65,
    "SEP": 6.03,
    "OCT": 5.7,
    "NOV": 5.06,
    "DEC": 5.57
  },
  {
    "LONG": -44.1,
    "LAT": -15.1,
    "CIDADE": "Itacarambi",
    "UF": "MINAS GERAIS",
    "anual": 5.95,
    "JAN": 5.87,
    "FEB": 6.42,
    "MAR": 6.03,
    "APR": 6.17,
    "MAY": 5.73,
    "JUN": 5.62,
    "JUL": 5.86,
    "AUG": 6.24,
    "SEP": 6.27,
    "OCT": 6.09,
    "NOV": 5.34,
    "DEC": 5.71
  },
  {
    "LONG": -43.12,
    "LAT": -15.08,
    "CIDADE": "Gameleiras",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.88,
    "FEB": 6.3,
    "MAR": 5.94,
    "APR": 5.84,
    "MAY": 5.49,
    "JUN": 5.4,
    "JUL": 5.67,
    "AUG": 6.22,
    "SEP": 6.36,
    "OCT": 5.98,
    "NOV": 5.26,
    "DEC": 5.75
  },
  {
    "LONG": -42.95,
    "LAT": -15.05,
    "CIDADE": "Mamonas",
    "UF": "MINAS GERAIS",
    "anual": 5.81,
    "JAN": 5.98,
    "FEB": 6.31,
    "MAR": 5.89,
    "APR": 5.75,
    "MAY": 5.44,
    "JUN": 5.39,
    "JUL": 5.62,
    "AUG": 6.09,
    "SEP": 6.21,
    "OCT": 5.99,
    "NOV": 5.32,
    "DEC": 5.73
  },
  {
    "LONG": -46.23,
    "LAT": -14.95,
    "CIDADE": "Formoso",
    "UF": "MINAS GERAIS",
    "anual": 5.7,
    "JAN": 5.44,
    "FEB": 5.83,
    "MAR": 5.41,
    "APR": 5.74,
    "MAY": 5.66,
    "JUN": 5.64,
    "JUL": 6.03,
    "AUG": 6.5,
    "SEP": 6.24,
    "OCT": 5.68,
    "NOV": 4.94,
    "DEC": 5.24
  },
  {
    "LONG": -44.08,
    "LAT": -14.88,
    "CIDADE": "São João das Missões",
    "UF": "MINAS GERAIS",
    "anual": 5.95,
    "JAN": 5.9,
    "FEB": 6.37,
    "MAR": 5.99,
    "APR": 6.14,
    "MAY": 5.68,
    "JUN": 5.62,
    "JUL": 5.96,
    "AUG": 6.31,
    "SEP": 6.43,
    "OCT": 6.05,
    "NOV": 5.3,
    "DEC": 5.69
  },
  {
    "LONG": -43.92,
    "LAT": -14.85,
    "CIDADE": "Matias Cardoso",
    "UF": "MINAS GERAIS",
    "anual": 5.96,
    "JAN": 5.95,
    "FEB": 6.35,
    "MAR": 6.02,
    "APR": 6.13,
    "MAY": 5.69,
    "JUN": 5.62,
    "JUL": 5.95,
    "AUG": 6.36,
    "SEP": 6.4,
    "OCT": 6.06,
    "NOV": 5.32,
    "DEC": 5.71
  },
  {
    "LONG": -42.81,
    "LAT": -14.93,
    "CIDADE": "Espinosa",
    "UF": "MINAS GERAIS",
    "anual": 5.86,
    "JAN": 6,
    "FEB": 6.35,
    "MAR": 6,
    "APR": 5.81,
    "MAY": 5.46,
    "JUN": 5.38,
    "JUL": 5.57,
    "AUG": 6.1,
    "SEP": 6.22,
    "OCT": 6.11,
    "NOV": 5.43,
    "DEC": 5.87
  },
  {
    "LONG": -43.94,
    "LAT": -14.75,
    "CIDADE": "Manga",
    "UF": "MINAS GERAIS",
    "anual": 5.98,
    "JAN": 5.98,
    "FEB": 6.39,
    "MAR": 6.04,
    "APR": 6.13,
    "MAY": 5.69,
    "JUN": 5.63,
    "JUL": 5.94,
    "AUG": 6.31,
    "SEP": 6.43,
    "OCT": 6.11,
    "NOV": 5.39,
    "DEC": 5.71
  },
  {
    "LONG": -44.42,
    "LAT": -14.74,
    "CIDADE": "Miravânia",
    "UF": "MINAS GERAIS",
    "anual": 5.96,
    "JAN": 5.8,
    "FEB": 6.31,
    "MAR": 5.82,
    "APR": 6.07,
    "MAY": 5.76,
    "JUN": 5.71,
    "JUL": 6.13,
    "AUG": 6.58,
    "SEP": 6.51,
    "OCT": 6.02,
    "NOV": 5.2,
    "DEC": 5.63
  },
  {
    "LONG": -44.37,
    "LAT": -14.42,
    "CIDADE": "Montalvânia",
    "UF": "MINAS GERAIS",
    "anual": 5.94,
    "JAN": 5.94,
    "FEB": 6.24,
    "MAR": 5.9,
    "APR": 6.06,
    "MAY": 5.72,
    "JUN": 5.64,
    "JUL": 6,
    "AUG": 6.31,
    "SEP": 6.36,
    "OCT": 6.03,
    "NOV": 5.36,
    "DEC": 5.74
  },
  {
    "LONG": "#VALOR!",
    "LAT": -14.27,
    "CIDADE": "Juvenília",
    "UF": "MINAS GERAIS",
    "anual": 5.98,
    "JAN": 6.02,
    "FEB": 6.28,
    "MAR": 6,
    "APR": 6.09,
    "MAY": 5.78,
    "JUN": 5.64,
    "JUL": 6.03,
    "AUG": 6.33,
    "SEP": 6.38,
    "OCT": 6.1,
    "NOV": 5.37,
    "DEC": 5.77
  },
  {
    "LONG": -50.35,
    "LAT": -9.33,
    "CIDADE": "Santana do Araguaia",
    "UF": "PARÁ",
    "anual": 5.11,
    "JAN": 4.66,
    "FEB": 4.82,
    "MAR": 4.82,
    "APR": 4.87,
    "MAY": 5.18,
    "JUN": 5.43,
    "JUL": 5.56,
    "AUG": 6.1,
    "SEP": 5.44,
    "OCT": 4.98,
    "NOV": 4.8,
    "DEC": 4.65
  },
  {
    "LONG": -49.72,
    "LAT": -0.89,
    "CIDADE": "Santa Maria das Barreiras",
    "UF": "PARÁ",
    "anual": 5.17,
    "JAN": 4.65,
    "FEB": 4.86,
    "MAR": 4.81,
    "APR": 4.94,
    "MAY": 5.28,
    "JUN": 5.55,
    "JUL": 5.65,
    "AUG": 6.2,
    "SEP": 5.61,
    "OCT": 5.01,
    "NOV": 4.82,
    "DEC": 4.67
  },
  {
    "LONG": -49.27,
    "LAT": -8.26,
    "CIDADE": "Conceição do Araguaia",
    "UF": "PARÁ",
    "anual": 5.14,
    "JAN": 4.56,
    "FEB": 4.8,
    "MAR": 4.82,
    "APR": 4.88,
    "MAY": 5.24,
    "JUN": 5.55,
    "JUL": 5.65,
    "AUG": 6.18,
    "SEP": 5.73,
    "OCT": 5.04,
    "NOV": 4.68,
    "DEC": 4.52
  },
  {
    "LONG": -50.03,
    "LAT": -8.03,
    "CIDADE": "Redenção",
    "UF": "PARÁ",
    "anual": 5.03,
    "JAN": 4.62,
    "FEB": 4.77,
    "MAR": 4.72,
    "APR": 4.84,
    "MAY": 5.09,
    "JUN": 5.38,
    "JUL": 5.32,
    "AUG": 5.96,
    "SEP": 5.49,
    "OCT": 4.95,
    "NOV": 4.7,
    "DEC": 4.51
  },
  {
    "LONG": -50.77,
    "LAT": -7.81,
    "CIDADE": "Cumaru do Norte",
    "UF": "PARÁ",
    "anual": 4.85,
    "JAN": 4.4,
    "FEB": 4.56,
    "MAR": 4.5,
    "APR": 4.61,
    "MAY": 4.97,
    "JUN": 5.39,
    "JUL": 5.4,
    "AUG": 5.83,
    "SEP": 5.22,
    "OCT": 4.65,
    "NOV": 4.46,
    "DEC": 4.26
  },
  {
    "LONG": -50.04,
    "LAT": -7.83,
    "CIDADE": "Pau D'Arco",
    "UF": "PARÁ",
    "anual": 5.02,
    "JAN": 4.64,
    "FEB": 4.7,
    "MAR": 4.71,
    "APR": 4.84,
    "MAY": 5.11,
    "JUN": 5.4,
    "JUL": 5.45,
    "AUG": 6.04,
    "SEP": 5.45,
    "OCT": 4.84,
    "NOV": 4.64,
    "DEC": 4.48
  },
  {
    "LONG": -49.7,
    "LAT": -7.56,
    "CIDADE": "Floresta do Araguaia",
    "UF": "PARÁ",
    "anual": 5,
    "JAN": 4.53,
    "FEB": 4.67,
    "MAR": 4.69,
    "APR": 4.78,
    "MAY": 5.07,
    "JUN": 5.41,
    "JUL": 5.47,
    "AUG": 5.97,
    "SEP": 5.49,
    "OCT": 4.9,
    "NOV": 4.58,
    "DEC": 4.46
  },
  {
    "LONG": -50.41,
    "LAT": -7.35,
    "CIDADE": "Bannach",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.42,
    "FEB": 4.55,
    "MAR": 4.59,
    "APR": 4.67,
    "MAY": 4.97,
    "JUN": 5.35,
    "JUL": 5.28,
    "AUG": 5.81,
    "SEP": 5.25,
    "OCT": 4.7,
    "NOV": 4.49,
    "DEC": 4.33
  },
  {
    "LONG": -50.04,
    "LAT": -7.32,
    "CIDADE": "Rio Maria",
    "UF": "PARÁ",
    "anual": 4.98,
    "JAN": 4.55,
    "FEB": 4.69,
    "MAR": 4.68,
    "APR": 4.82,
    "MAY": 5.11,
    "JUN": 5.37,
    "JUL": 5.36,
    "AUG": 5.9,
    "SEP": 5.39,
    "OCT": 4.87,
    "NOV": 4.59,
    "DEC": 4.45
  },
  {
    "LONG": -5.54,
    "LAT": -7.14,
    "CIDADE": "Novo Progresso",
    "UF": "PARÁ",
    "anual": 4.63,
    "JAN": 4.16,
    "FEB": 4.23,
    "MAR": 4.3,
    "APR": 4.29,
    "MAY": 4.57,
    "JUN": 4.97,
    "JUL": 4.94,
    "AUG": 5.42,
    "SEP": 4.97,
    "OCT": 4.82,
    "NOV": 4.55,
    "DEC": 4.35
  },
  {
    "LONG": -49.94,
    "LAT": -7.1,
    "CIDADE": "Xinguara",
    "UF": "PARÁ",
    "anual": 4.97,
    "JAN": 4.52,
    "FEB": 4.68,
    "MAR": 4.66,
    "APR": 4.79,
    "MAY": 5.11,
    "JUN": 5.39,
    "JUL": 5.34,
    "AUG": 5.87,
    "SEP": 5.43,
    "OCT": 4.84,
    "NOV": 4.56,
    "DEC": 4.42
  },
  {
    "LONG": -49.7,
    "LAT": -6.94,
    "CIDADE": "Sapucaia",
    "UF": "PARÁ",
    "anual": 4.96,
    "JAN": 4.49,
    "FEB": 4.65,
    "MAR": 4.68,
    "APR": 4.77,
    "MAY": 5.09,
    "JUN": 5.37,
    "JUL": 5.38,
    "AUG": 5.82,
    "SEP": 5.44,
    "OCT": 4.86,
    "NOV": 4.52,
    "DEC": 4.41
  },
  {
    "LONG": -51.09,
    "LAT": -6.75,
    "CIDADE": "Ourilândia do Norte",
    "UF": "PARÁ",
    "anual": 4.78,
    "JAN": 4.28,
    "FEB": 4.47,
    "MAR": 4.48,
    "APR": 4.55,
    "MAY": 4.86,
    "JUN": 5.18,
    "JUL": 5.17,
    "AUG": 5.56,
    "SEP": 5.27,
    "OCT": 4.74,
    "NOV": 4.49,
    "DEC": 4.27
  },
  {
    "LONG": -5.05,
    "LAT": -6.81,
    "CIDADE": "Água Azul do Norte",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.49,
    "FEB": 4.61,
    "MAR": 4.66,
    "APR": 4.67,
    "MAY": 4.97,
    "JUN": 5.22,
    "JUL": 5.24,
    "AUG": 5.68,
    "SEP": 5.29,
    "OCT": 4.79,
    "NOV": 4.55,
    "DEC": 4.34
  },
  {
    "LONG": -5.12,
    "LAT": -6.75,
    "CIDADE": "Tucumã",
    "UF": "PARÁ",
    "anual": 4.79,
    "JAN": 4.32,
    "FEB": 4.5,
    "MAR": 4.47,
    "APR": 4.59,
    "MAY": 4.9,
    "JUN": 5.25,
    "JUL": 5.16,
    "AUG": 5.6,
    "SEP": 5.23,
    "OCT": 4.73,
    "NOV": 4.52,
    "DEC": 4.27
  },
  {
    "LONG": -51.99,
    "LAT": -6.64,
    "CIDADE": "São Félix do Xingu",
    "UF": "PARÁ",
    "anual": 4.74,
    "JAN": 4.17,
    "FEB": 4.48,
    "MAR": 4.52,
    "APR": 4.54,
    "MAY": 4.84,
    "JUN": 5.2,
    "JUL": 5.1,
    "AUG": 5.48,
    "SEP": 5.13,
    "OCT": 4.72,
    "NOV": 4.47,
    "DEC": 4.2
  },
  {
    "LONG": -4.99,
    "LAT": -0.65,
    "CIDADE": "Canaã dos Carajás",
    "UF": "PARÁ",
    "anual": 4.94,
    "JAN": 4.49,
    "FEB": 4.7,
    "MAR": 4.74,
    "APR": 4.8,
    "MAY": 5,
    "JUN": 5.28,
    "JUL": 5.32,
    "AUG": 5.71,
    "SEP": 5.39,
    "OCT": 4.88,
    "NOV": 4.58,
    "DEC": 4.43
  },
  {
    "LONG": -48.86,
    "LAT": -6.44,
    "CIDADE": "Piçarra",
    "UF": "PARÁ",
    "anual": 4.98,
    "JAN": 4.39,
    "FEB": 4.68,
    "MAR": 4.61,
    "APR": 4.83,
    "MAY": 5.04,
    "JUN": 5.43,
    "JUL": 5.55,
    "AUG": 5.96,
    "SEP": 5.49,
    "OCT": 4.89,
    "NOV": 4.53,
    "DEC": 4.37
  },
  {
    "LONG": -48.56,
    "LAT": -6.4,
    "CIDADE": "São Geraldo do Araguaia",
    "UF": "PARÁ",
    "anual": 5.04,
    "JAN": 4.49,
    "FEB": 4.74,
    "MAR": 4.72,
    "APR": 4.89,
    "MAY": 5.11,
    "JUN": 5.47,
    "JUL": 5.53,
    "AUG": 5.94,
    "SEP": 5.66,
    "OCT": 4.97,
    "NOV": 4.56,
    "DEC": 4.39
  },
  {
    "LONG": -57.75,
    "LAT": -6.22,
    "CIDADE": "Jacareacanga",
    "UF": "PARÁ",
    "anual": 4.58,
    "JAN": 4.04,
    "FEB": 4.3,
    "MAR": 4.31,
    "APR": 4.17,
    "MAY": 4.32,
    "JUN": 4.84,
    "JUL": 4.79,
    "AUG": 5.45,
    "SEP": 5.08,
    "OCT": 4.81,
    "NOV": 4.59,
    "DEC": 4.3
  },
  {
    "LONG": -49.9,
    "LAT": -6.07,
    "CIDADE": "Parauapebas",
    "UF": "PARÁ",
    "anual": 4.81,
    "JAN": 4.34,
    "FEB": 4.47,
    "MAR": 4.49,
    "APR": 4.6,
    "MAY": 4.91,
    "JUN": 5.23,
    "JUL": 5.41,
    "AUG": 5.79,
    "SEP": 5.26,
    "OCT": 4.72,
    "NOV": 4.35,
    "DEC": 4.15
  },
  {
    "LONG": -49.6,
    "LAT": -0.61,
    "CIDADE": "Curionópolis",
    "UF": "PARÁ",
    "anual": 4.8,
    "JAN": 4.3,
    "FEB": 4.57,
    "MAR": 4.52,
    "APR": 4.74,
    "MAY": 4.92,
    "JUN": 5.24,
    "JUL": 5.32,
    "AUG": 5.71,
    "SEP": 5.18,
    "OCT": 4.6,
    "NOV": 4.32,
    "DEC": 4.15
  },
  {
    "LONG": -49.36,
    "LAT": -6.1,
    "CIDADE": "Eldorado do Carajás",
    "UF": "PARÁ",
    "anual": 4.94,
    "JAN": 4.44,
    "FEB": 4.68,
    "MAR": 4.7,
    "APR": 4.83,
    "MAY": 5,
    "JUN": 5.34,
    "JUL": 5.43,
    "AUG": 5.79,
    "SEP": 5.26,
    "OCT": 4.81,
    "NOV": 4.56,
    "DEC": 4.38
  },
  {
    "LONG": -48.4,
    "LAT": -5.7,
    "CIDADE": "Brejo Grande do Araguaia",
    "UF": "PARÁ",
    "anual": 4.95,
    "JAN": 4.44,
    "FEB": 4.65,
    "MAR": 4.74,
    "APR": 4.8,
    "MAY": 4.9,
    "JUN": 5.3,
    "JUL": 5.49,
    "AUG": 5.86,
    "SEP": 5.46,
    "OCT": 4.83,
    "NOV": 4.58,
    "DEC": 4.36
  },
  {
    "LONG": -48.32,
    "LAT": -5.74,
    "CIDADE": "Palestina do Pará",
    "UF": "PARÁ",
    "anual": 4.98,
    "JAN": 4.45,
    "FEB": 4.64,
    "MAR": 4.76,
    "APR": 4.87,
    "MAY": 4.96,
    "JUN": 5.37,
    "JUL": 5.48,
    "AUG": 5.89,
    "SEP": 5.47,
    "OCT": 4.87,
    "NOV": 4.62,
    "DEC": 4.41
  },
  {
    "LONG": -48.73,
    "LAT": -0.55,
    "CIDADE": "São Domingos do Araguaia",
    "UF": "PARÁ",
    "anual": 4.94,
    "JAN": 4.44,
    "FEB": 4.66,
    "MAR": 4.74,
    "APR": 4.88,
    "MAY": 4.91,
    "JUN": 5.29,
    "JUL": 5.36,
    "AUG": 5.77,
    "SEP": 5.4,
    "OCT": 4.78,
    "NOV": 4.62,
    "DEC": 4.37
  },
  {
    "LONG": -49.13,
    "LAT": -5.38,
    "CIDADE": "Marabá",
    "UF": "PARÁ",
    "anual": 4.91,
    "JAN": 4.42,
    "FEB": 4.62,
    "MAR": 4.7,
    "APR": 4.88,
    "MAY": 4.87,
    "JUN": 5.25,
    "JUL": 5.37,
    "AUG": 5.66,
    "SEP": 5.36,
    "OCT": 4.82,
    "NOV": 4.6,
    "DEC": 4.32
  },
  {
    "LONG": -48.79,
    "LAT": -5.36,
    "CIDADE": "São João do Araguaia",
    "UF": "PARÁ",
    "anual": 5.03,
    "JAN": 4.58,
    "FEB": 4.72,
    "MAR": 4.85,
    "APR": 5.08,
    "MAY": 5.03,
    "JUN": 5.36,
    "JUL": 5.38,
    "AUG": 5.82,
    "SEP": 5.51,
    "OCT": 4.9,
    "NOV": 4.72,
    "DEC": 4.46
  },
  {
    "LONG": -49.33,
    "LAT": -5.13,
    "CIDADE": "Itupiranga",
    "UF": "PARÁ",
    "anual": 4.82,
    "JAN": 4.27,
    "FEB": 4.54,
    "MAR": 4.56,
    "APR": 4.74,
    "MAY": 4.83,
    "JUN": 5.25,
    "JUL": 5.33,
    "AUG": 5.6,
    "SEP": 5.21,
    "OCT": 4.72,
    "NOV": 4.51,
    "DEC": 4.22
  },
  {
    "LONG": -48.61,
    "LAT": -5.04,
    "CIDADE": "Bom Jesus do Tocantins",
    "UF": "PARÁ",
    "anual": 4.85,
    "JAN": 4.28,
    "FEB": 4.43,
    "MAR": 4.6,
    "APR": 4.69,
    "MAY": 4.84,
    "JUN": 5.25,
    "JUL": 5.32,
    "AUG": 5.65,
    "SEP": 5.34,
    "OCT": 4.77,
    "NOV": 4.65,
    "DEC": 4.33
  },
  {
    "LONG": -48.39,
    "LAT": -4.95,
    "CIDADE": "Abel Figueiredo",
    "UF": "PARÁ",
    "anual": 4.89,
    "JAN": 4.3,
    "FEB": 4.5,
    "MAR": 4.64,
    "APR": 4.72,
    "MAY": 4.84,
    "JUN": 5.27,
    "JUL": 5.41,
    "AUG": 5.7,
    "SEP": 5.36,
    "OCT": 4.87,
    "NOV": 4.63,
    "DEC": 4.43
  },
  {
    "LONG": -49.08,
    "LAT": -4.92,
    "CIDADE": "Nova Ipixuna",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.26,
    "FEB": 4.39,
    "MAR": 4.49,
    "APR": 4.58,
    "MAY": 4.7,
    "JUN": 5.17,
    "JUL": 5.27,
    "AUG": 5.58,
    "SEP": 5.25,
    "OCT": 4.68,
    "NOV": 4.49,
    "DEC": 4.22
  },
  {
    "LONG": -48.07,
    "LAT": -4.78,
    "CIDADE": "Rondon do Pará",
    "UF": "PARÁ",
    "anual": 4.85,
    "JAN": 4.31,
    "FEB": 4.52,
    "MAR": 4.62,
    "APR": 4.8,
    "MAY": 4.87,
    "JUN": 5.15,
    "JUL": 5.25,
    "AUG": 5.59,
    "SEP": 5.28,
    "OCT": 4.81,
    "NOV": 4.57,
    "DEC": 4.4
  },
  {
    "LONG": -55.94,
    "LAT": -4.57,
    "CIDADE": "Trairão",
    "UF": "PARÁ",
    "anual": 4.5,
    "JAN": 3.93,
    "FEB": 4.1,
    "MAR": 4.12,
    "APR": 4.03,
    "MAY": 4.18,
    "JUN": 4.65,
    "JUL": 4.62,
    "AUG": 5.21,
    "SEP": 5.09,
    "OCT": 4.99,
    "NOV": 4.69,
    "DEC": 4.34
  },
  {
    "LONG": -49.12,
    "LAT": -4.45,
    "CIDADE": "Jacundá",
    "UF": "PARÁ",
    "anual": 4.74,
    "JAN": 4.28,
    "FEB": 4.48,
    "MAR": 4.58,
    "APR": 4.71,
    "MAY": 4.69,
    "JUN": 5.01,
    "JUL": 5.14,
    "AUG": 5.37,
    "SEP": 5.19,
    "OCT": 4.66,
    "NOV": 4.51,
    "DEC": 4.27
  },
  {
    "LONG": -55.99,
    "LAT": -4.27,
    "CIDADE": "Itaituba",
    "UF": "PARÁ",
    "anual": 4.68,
    "JAN": 4.2,
    "FEB": 4.34,
    "MAR": 4.39,
    "APR": 4.29,
    "MAY": 4.35,
    "JUN": 4.69,
    "JUL": 4.67,
    "AUG": 5.31,
    "SEP": 5.2,
    "OCT": 5.21,
    "NOV": 4.95,
    "DEC": 4.6
  },
  {
    "LONG": -47.55,
    "LAT": -4.3,
    "CIDADE": "Dom Eliseu",
    "UF": "PARÁ",
    "anual": 4.79,
    "JAN": 4.33,
    "FEB": 4.59,
    "MAR": 4.65,
    "APR": 4.84,
    "MAY": 4.81,
    "JUN": 5.05,
    "JUL": 5.07,
    "AUG": 5.3,
    "SEP": 5.24,
    "OCT": 4.81,
    "NOV": 4.49,
    "DEC": 4.36
  },
  {
    "LONG": -49.95,
    "LAT": -4.25,
    "CIDADE": "Novo Repartimento",
    "UF": "PARÁ",
    "anual": 4.7,
    "JAN": 4.14,
    "FEB": 4.47,
    "MAR": 4.63,
    "APR": 4.68,
    "MAY": 4.82,
    "JUN": 5.09,
    "JUL": 5.17,
    "AUG": 5.31,
    "SEP": 4.97,
    "OCT": 4.52,
    "NOV": 4.4,
    "DEC": 4.15
  },
  {
    "LONG": -54.91,
    "LAT": -4.1,
    "CIDADE": "Rurópolis",
    "UF": "PARÁ",
    "anual": 4.54,
    "JAN": 4,
    "FEB": 4.2,
    "MAR": 4.24,
    "APR": 4.22,
    "MAY": 4.29,
    "JUN": 4.66,
    "JUL": 4.6,
    "AUG": 5.18,
    "SEP": 4.99,
    "OCT": 4.98,
    "NOV": 4.74,
    "DEC": 4.34
  },
  {
    "LONG": -54.21,
    "LAT": -3.87,
    "CIDADE": "Placas",
    "UF": "PARÁ",
    "anual": 4.51,
    "JAN": 4.03,
    "FEB": 4.18,
    "MAR": 4.22,
    "APR": 4.19,
    "MAY": 4.37,
    "JUN": 4.77,
    "JUL": 4.64,
    "AUG": 5.09,
    "SEP": 4.81,
    "OCT": 4.92,
    "NOV": 4.65,
    "DEC": 4.23
  },
  {
    "LONG": -5.06,
    "LAT": -3.84,
    "CIDADE": "Pacajá",
    "UF": "PARÁ",
    "anual": 4.56,
    "JAN": 4.13,
    "FEB": 4.31,
    "MAR": 4.43,
    "APR": 4.47,
    "MAY": 4.59,
    "JUN": 4.89,
    "JUL": 4.95,
    "AUG": 5.15,
    "SEP": 4.83,
    "OCT": 4.51,
    "NOV": 4.39,
    "DEC": 4.11
  },
  {
    "LONG": -49.68,
    "LAT": -3.77,
    "CIDADE": "Tucuruí",
    "UF": "PARÁ",
    "anual": 4.7,
    "JAN": 4.25,
    "FEB": 4.48,
    "MAR": 4.57,
    "APR": 4.72,
    "MAY": 4.72,
    "JUN": 5.02,
    "JUL": 5.11,
    "AUG": 5.35,
    "SEP": 5.02,
    "OCT": 4.61,
    "NOV": 4.42,
    "DEC": 4.14
  },
  {
    "LONG": -49.57,
    "LAT": -3.77,
    "CIDADE": "Breu Branco",
    "UF": "PARÁ",
    "anual": 4.66,
    "JAN": 4.22,
    "FEB": 4.47,
    "MAR": 4.56,
    "APR": 4.69,
    "MAY": 4.69,
    "JUN": 4.96,
    "JUL": 5.04,
    "AUG": 5.3,
    "SEP": 4.96,
    "OCT": 4.54,
    "NOV": 4.39,
    "DEC": 4.1
  },
  {
    "LONG": -49.1,
    "LAT": -3.84,
    "CIDADE": "Goianésia do Pará",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.28,
    "FEB": 4.5,
    "MAR": 4.66,
    "APR": 4.71,
    "MAY": 4.76,
    "JUN": 5.1,
    "JUL": 5.12,
    "AUG": 5.33,
    "SEP": 5.1,
    "OCT": 4.76,
    "NOV": 4.53,
    "DEC": 4.21
  },
  {
    "LONG": -53.74,
    "LAT": -3.72,
    "CIDADE": "Uruará",
    "UF": "PARÁ",
    "anual": 4.46,
    "JAN": 4.11,
    "FEB": 4.17,
    "MAR": 4.24,
    "APR": 4.09,
    "MAY": 4.38,
    "JUN": 4.66,
    "JUL": 4.59,
    "AUG": 5.02,
    "SEP": 4.79,
    "OCT": 4.77,
    "NOV": 4.56,
    "DEC": 4.11
  },
  {
    "LONG": -47.5,
    "LAT": -3.74,
    "CIDADE": "Ulianópolis",
    "UF": "PARÁ",
    "anual": 4.79,
    "JAN": 4.34,
    "FEB": 4.56,
    "MAR": 4.74,
    "APR": 4.81,
    "MAY": 4.81,
    "JUN": 4.99,
    "JUL": 5.02,
    "AUG": 5.2,
    "SEP": 5.17,
    "OCT": 4.83,
    "NOV": 4.59,
    "DEC": 4.39
  },
  {
    "LONG": -55.32,
    "LAT": -3.61,
    "CIDADE": "Aveiro",
    "UF": "PARÁ",
    "anual": 4.68,
    "JAN": 4.12,
    "FEB": 4.35,
    "MAR": 4.34,
    "APR": 4.21,
    "MAY": 4.33,
    "JUN": 4.77,
    "JUL": 4.74,
    "AUG": 5.34,
    "SEP": 5.24,
    "OCT": 5.27,
    "NOV": 5.01,
    "DEC": 4.51
  },
  {
    "LONG": -51.2,
    "LAT": -3.47,
    "CIDADE": "Anapu",
    "UF": "PARÁ",
    "anual": 4.49,
    "JAN": 4.2,
    "FEB": 4.26,
    "MAR": 4.38,
    "APR": 4.43,
    "MAY": 4.47,
    "JUN": 4.77,
    "JUL": 4.83,
    "AUG": 4.94,
    "SEP": 4.7,
    "OCT": 4.43,
    "NOV": 4.31,
    "DEC": 4.11
  },
  {
    "LONG": -52.89,
    "LAT": -3.45,
    "CIDADE": "Medicilândia",
    "UF": "PARÁ",
    "anual": 4.37,
    "JAN": 4.05,
    "FEB": 4.21,
    "MAR": 4.24,
    "APR": 4.22,
    "MAY": 4.27,
    "JUN": 4.56,
    "JUL": 4.55,
    "AUG": 4.79,
    "SEP": 4.56,
    "OCT": 4.54,
    "NOV": 4.4,
    "DEC": 4.01
  },
  {
    "LONG": -52.54,
    "LAT": -3.3,
    "CIDADE": "Brasil Novo",
    "UF": "PARÁ",
    "anual": 4.38,
    "JAN": 4.04,
    "FEB": 4.16,
    "MAR": 4.26,
    "APR": 4.31,
    "MAY": 4.34,
    "JUN": 4.62,
    "JUL": 4.58,
    "AUG": 4.81,
    "SEP": 4.59,
    "OCT": 4.48,
    "NOV": 4.37,
    "DEC": 4.06
  },
  {
    "LONG": -52.21,
    "LAT": -3.2,
    "CIDADE": "Altamira",
    "UF": "PARÁ",
    "anual": 4.44,
    "JAN": 4.06,
    "FEB": 4.27,
    "MAR": 4.32,
    "APR": 4.38,
    "MAY": 4.44,
    "JUN": 4.69,
    "JUL": 4.62,
    "AUG": 4.87,
    "SEP": 4.68,
    "OCT": 4.49,
    "NOV": 4.46,
    "DEC": 4.07
  },
  {
    "LONG": -47.35,
    "LAT": -3,
    "CIDADE": "Paragominas",
    "UF": "PARÁ",
    "anual": 4.89,
    "JAN": 4.51,
    "FEB": 4.63,
    "MAR": 4.7,
    "APR": 4.77,
    "MAY": 4.8,
    "JUN": 4.95,
    "JUL": 5.04,
    "AUG": 5.29,
    "SEP": 5.42,
    "OCT": 5.18,
    "NOV": 4.82,
    "DEC": 4.61
  },
  {
    "LONG": -52.01,
    "LAT": -2.89,
    "CIDADE": "Vitória do Xingu",
    "UF": "PARÁ",
    "anual": 4.61,
    "JAN": 4.26,
    "FEB": 4.37,
    "MAR": 4.4,
    "APR": 4.56,
    "MAY": 4.54,
    "JUN": 4.74,
    "JUL": 4.78,
    "AUG": 4.97,
    "SEP": 4.93,
    "OCT": 4.84,
    "NOV": 4.66,
    "DEC": 4.29
  },
  {
    "LONG": -48.95,
    "LAT": -2.95,
    "CIDADE": "Tailândia",
    "UF": "PARÁ",
    "anual": 4.78,
    "JAN": 4.36,
    "FEB": 4.47,
    "MAR": 4.66,
    "APR": 4.74,
    "MAY": 4.8,
    "JUN": 5,
    "JUL": 5.09,
    "AUG": 5.28,
    "SEP": 5.13,
    "OCT": 4.86,
    "NOV": 4.6,
    "DEC": 4.39
  },
  {
    "LONG": -49.67,
    "LAT": -2.79,
    "CIDADE": "Baião",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.38,
    "FEB": 4.53,
    "MAR": 4.54,
    "APR": 4.69,
    "MAY": 4.76,
    "JUN": 4.97,
    "JUL": 5.05,
    "AUG": 5.33,
    "SEP": 5.09,
    "OCT": 4.85,
    "NOV": 4.58,
    "DEC": 4.3
  },
  {
    "LONG": -54.65,
    "LAT": -2.68,
    "CIDADE": "Mojuí dos Campos",
    "UF": "PARÁ",
    "anual": 4.55,
    "JAN": 4.33,
    "FEB": 4.33,
    "MAR": 4.23,
    "APR": 4.15,
    "MAY": 4.26,
    "JUN": 4.54,
    "JUL": 4.47,
    "AUG": 4.98,
    "SEP": 4.87,
    "OCT": 5.1,
    "NOV": 4.91,
    "DEC": 4.39
  },
  {
    "LONG": -54.94,
    "LAT": -2.64,
    "CIDADE": "Belterra",
    "UF": "PARÁ",
    "anual": 4.63,
    "JAN": 4.36,
    "FEB": 4.4,
    "MAR": 4.34,
    "APR": 4.17,
    "MAY": 4.26,
    "JUN": 4.59,
    "JUL": 4.52,
    "AUG": 5.09,
    "SEP": 5.05,
    "OCT": 5.19,
    "NOV": 5.11,
    "DEC": 4.5
  },
  {
    "LONG": -51.95,
    "LAT": -2.59,
    "CIDADE": "Senador José Porfírio",
    "UF": "PARÁ",
    "anual": 4.56,
    "JAN": 4.19,
    "FEB": 4.29,
    "MAR": 4.34,
    "APR": 4.41,
    "MAY": 4.47,
    "JUN": 4.68,
    "JUL": 4.79,
    "AUG": 5.09,
    "SEP": 4.92,
    "OCT": 4.78,
    "NOV": 4.58,
    "DEC": 4.19
  },
  {
    "LONG": -49.5,
    "LAT": -2.58,
    "CIDADE": "Mocajuba",
    "UF": "PARÁ",
    "anual": 4.9,
    "JAN": 4.47,
    "FEB": 4.61,
    "MAR": 4.69,
    "APR": 4.76,
    "MAY": 4.88,
    "JUN": 5.04,
    "JUL": 5.13,
    "AUG": 5.4,
    "SEP": 5.29,
    "OCT": 5.11,
    "NOV": 4.88,
    "DEC": 4.49
  },
  {
    "LONG": -47.5,
    "LAT": -2.56,
    "CIDADE": "Ipixuna do Pará",
    "UF": "PARÁ",
    "anual": 4.89,
    "JAN": 4.5,
    "FEB": 4.57,
    "MAR": 4.58,
    "APR": 4.69,
    "MAY": 4.8,
    "JUN": 5.05,
    "JUL": 5.1,
    "AUG": 5.41,
    "SEP": 5.38,
    "OCT": 5.19,
    "NOV": 4.83,
    "DEC": 4.56
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.44,
    "CIDADE": "Santarém",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.58,
    "FEB": 4.7,
    "MAR": 4.61,
    "APR": 4.43,
    "MAY": 4.61,
    "JUN": 4.96,
    "JUL": 4.79,
    "AUG": 5.18,
    "SEP": 5.08,
    "OCT": 5.43,
    "NOV": 5.36,
    "DEC": 4.76
  },
  {
    "LONG": -48.15,
    "LAT": -2.42,
    "CIDADE": "Tomé-Açu",
    "UF": "PARÁ",
    "anual": 4.86,
    "JAN": 4.52,
    "FEB": 4.53,
    "MAR": 4.67,
    "APR": 4.71,
    "MAY": 4.76,
    "JUN": 5.01,
    "JUL": 5.1,
    "AUG": 5.34,
    "SEP": 5.28,
    "OCT": 5.04,
    "NOV": 4.8,
    "DEC": 4.58
  },
  {
    "LONG": -46.97,
    "LAT": -0.23,
    "CIDADE": "Nova Esperança do Piriá",
    "UF": "PARÁ",
    "anual": 4.93,
    "JAN": 4.5,
    "FEB": 4.56,
    "MAR": 4.58,
    "APR": 4.63,
    "MAY": 4.79,
    "JUN": 5.02,
    "JUL": 5.1,
    "AUG": 5.47,
    "SEP": 5.52,
    "OCT": 5.26,
    "NOV": 5.02,
    "DEC": 4.77
  },
  {
    "LONG": -56.74,
    "LAT": -2.17,
    "CIDADE": "Faro",
    "UF": "PARÁ",
    "anual": 4.65,
    "JAN": 4.1,
    "FEB": 4.16,
    "MAR": 4.21,
    "APR": 4.12,
    "MAY": 4.23,
    "JUN": 4.83,
    "JUL": 4.83,
    "AUG": 5.38,
    "SEP": 5.23,
    "OCT": 5.26,
    "NOV": 5.01,
    "DEC": 4.46
  },
  {
    "LONG": -56.09,
    "LAT": -2.16,
    "CIDADE": "Juruti",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.28,
    "FEB": 4.37,
    "MAR": 4.38,
    "APR": 4.15,
    "MAY": 4.25,
    "JUN": 4.75,
    "JUL": 4.71,
    "AUG": 5.33,
    "SEP": 5.3,
    "OCT": 5.41,
    "NOV": 5.19,
    "DEC": 4.65
  },
  {
    "LONG": -49.5,
    "LAT": -2.24,
    "CIDADE": "Cametá",
    "UF": "PARÁ",
    "anual": 5.01,
    "JAN": 4.56,
    "FEB": 4.64,
    "MAR": 4.74,
    "APR": 4.85,
    "MAY": 5,
    "JUN": 5.14,
    "JUL": 5.17,
    "AUG": 5.45,
    "SEP": 5.49,
    "OCT": 5.27,
    "NOV": 5.13,
    "DEC": 4.73
  },
  {
    "LONG": -56.49,
    "LAT": -2.1,
    "CIDADE": "Terra Santa",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.27,
    "FEB": 4.36,
    "MAR": 4.35,
    "APR": 4.23,
    "MAY": 4.35,
    "JUN": 4.9,
    "JUL": 4.84,
    "AUG": 5.3,
    "SEP": 5.35,
    "OCT": 5.38,
    "NOV": 5.17,
    "DEC": 4.62
  },
  {
    "LONG": -47.56,
    "LAT": -2.13,
    "CIDADE": "Aurora do Pará",
    "UF": "PARÁ",
    "anual": 4.86,
    "JAN": 4.47,
    "FEB": 4.52,
    "MAR": 4.6,
    "APR": 4.57,
    "MAY": 4.74,
    "JUN": 4.98,
    "JUL": 5.1,
    "AUG": 5.34,
    "SEP": 5.38,
    "OCT": 5.18,
    "NOV": 4.89,
    "DEC": 4.57
  },
  {
    "LONG": -54.07,
    "LAT": -2,
    "CIDADE": "Monte Alegre",
    "UF": "PARÁ",
    "anual": 4.97,
    "JAN": 4.82,
    "FEB": 4.73,
    "MAR": 4.78,
    "APR": 4.66,
    "MAY": 4.76,
    "JUN": 5.02,
    "JUL": 4.97,
    "AUG": 5.26,
    "SEP": 5.13,
    "OCT": 5.37,
    "NOV": 5.31,
    "DEC": 4.88
  },
  {
    "LONG": -49.86,
    "LAT": -0.2,
    "CIDADE": "Oeiras do Pará",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.56,
    "FEB": 4.53,
    "MAR": 4.61,
    "APR": 4.67,
    "MAY": 4.74,
    "JUN": 4.87,
    "JUL": 4.96,
    "AUG": 5.27,
    "SEP": 5.33,
    "OCT": 5.24,
    "NOV": 5.03,
    "DEC": 4.7
  },
  {
    "LONG": -48.96,
    "LAT": -1.98,
    "CIDADE": "Igarapé-Miri",
    "UF": "PARÁ",
    "anual": 4.84,
    "JAN": 4.43,
    "FEB": 4.49,
    "MAR": 4.56,
    "APR": 4.66,
    "MAY": 4.78,
    "JUN": 5.01,
    "JUL": 5.15,
    "AUG": 5.34,
    "SEP": 5.25,
    "OCT": 5.02,
    "NOV": 4.85,
    "DEC": 4.57
  },
  {
    "LONG": -48.2,
    "LAT": -1.96,
    "CIDADE": "Acará",
    "UF": "PARÁ",
    "anual": 4.91,
    "JAN": 4.57,
    "FEB": 4.5,
    "MAR": 4.6,
    "APR": 4.62,
    "MAY": 4.79,
    "JUN": 5,
    "JUL": 5.15,
    "AUG": 5.41,
    "SEP": 5.44,
    "OCT": 5.22,
    "NOV": 4.93,
    "DEC": 4.7
  },
  {
    "LONG": -47.94,
    "LAT": -1.99,
    "CIDADE": "Concórdia do Pará",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.52,
    "FEB": 4.53,
    "MAR": 4.59,
    "APR": 4.62,
    "MAY": 4.76,
    "JUN": 4.99,
    "JUL": 5.11,
    "AUG": 5.39,
    "SEP": 5.38,
    "OCT": 5.17,
    "NOV": 4.89,
    "DEC": 4.59
  },
  {
    "LONG": -47.56,
    "LAT": -2.05,
    "CIDADE": "Mãe do Rio",
    "UF": "PARÁ",
    "anual": 4.92,
    "JAN": 4.55,
    "FEB": 4.47,
    "MAR": 4.6,
    "APR": 4.62,
    "MAY": 4.83,
    "JUN": 5.05,
    "JUL": 5.17,
    "AUG": 5.42,
    "SEP": 5.47,
    "OCT": 5.24,
    "NOV": 4.97,
    "DEC": 4.66
  },
  {
    "LONG": -55.52,
    "LAT": -1.9,
    "CIDADE": "Óbidos",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.27,
    "FEB": 4.42,
    "MAR": 4.39,
    "APR": 4.32,
    "MAY": 4.42,
    "JUN": 4.88,
    "JUL": 4.78,
    "AUG": 5.36,
    "SEP": 5.26,
    "OCT": 5.33,
    "NOV": 5.09,
    "DEC": 4.58
  },
  {
    "LONG": -55.12,
    "LAT": -1.89,
    "CIDADE": "Curuá",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.47,
    "FEB": 4.58,
    "MAR": 4.51,
    "APR": 4.48,
    "MAY": 4.65,
    "JUN": 4.99,
    "JUL": 4.88,
    "AUG": 5.38,
    "SEP": 5.33,
    "OCT": 5.41,
    "NOV": 5.21,
    "DEC": 4.67
  },
  {
    "LONG": -54.74,
    "LAT": -1.95,
    "CIDADE": "Alenquer",
    "UF": "PARÁ",
    "anual": 4.75,
    "JAN": 4.45,
    "FEB": 4.55,
    "MAR": 4.43,
    "APR": 4.34,
    "MAY": 4.5,
    "JUN": 4.74,
    "JUL": 4.74,
    "AUG": 5.25,
    "SEP": 5.21,
    "OCT": 5.2,
    "NOV": 5.08,
    "DEC": 4.52
  },
  {
    "LONG": -50.82,
    "LAT": -1.94,
    "CIDADE": "Portel",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.53,
    "FEB": 4.46,
    "MAR": 4.59,
    "APR": 4.63,
    "MAY": 4.77,
    "JUN": 4.99,
    "JUL": 5.06,
    "AUG": 5.31,
    "SEP": 5.31,
    "OCT": 5.1,
    "NOV": 4.99,
    "DEC": 4.65
  },
  {
    "LONG": -50.2,
    "LAT": -1.9,
    "CIDADE": "Bagre",
    "UF": "PARÁ",
    "anual": 5.08,
    "JAN": 4.74,
    "FEB": 4.69,
    "MAR": 4.8,
    "APR": 4.83,
    "MAY": 4.93,
    "JUN": 5.07,
    "JUL": 5.16,
    "AUG": 5.46,
    "SEP": 5.51,
    "OCT": 5.46,
    "NOV": 5.32,
    "DEC": 4.93
  },
  {
    "LONG": -49.38,
    "LAT": -1.9,
    "CIDADE": "Limoeiro do Ajuru",
    "UF": "PARÁ",
    "anual": 5.15,
    "JAN": 4.72,
    "FEB": 4.74,
    "MAR": 4.87,
    "APR": 4.99,
    "MAY": 5.08,
    "JUN": 5.24,
    "JUL": 5.28,
    "AUG": 5.5,
    "SEP": 5.59,
    "OCT": 5.49,
    "NOV": 5.31,
    "DEC": 4.95
  },
  {
    "LONG": -48.77,
    "LAT": -1.89,
    "CIDADE": "Moju",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.44,
    "FEB": 4.48,
    "MAR": 4.56,
    "APR": 4.63,
    "MAY": 4.79,
    "JUN": 5.01,
    "JUL": 5.14,
    "AUG": 5.36,
    "SEP": 5.31,
    "OCT": 5.17,
    "NOV": 4.95,
    "DEC": 4.66
  },
  {
    "LONG": -47.05,
    "LAT": -1.93,
    "CIDADE": "Garrafão do Norte",
    "UF": "PARÁ",
    "anual": 4.94,
    "JAN": 4.58,
    "FEB": 4.42,
    "MAR": 4.54,
    "APR": 4.57,
    "MAY": 4.73,
    "JUN": 5.02,
    "JUL": 5.16,
    "AUG": 5.49,
    "SEP": 5.58,
    "OCT": 5.32,
    "NOV": 5.1,
    "DEC": 4.7
  },
  {
    "LONG": -55.86,
    "LAT": -1.76,
    "CIDADE": "Oriximiná",
    "UF": "PARÁ",
    "anual": 4.66,
    "JAN": 4.2,
    "FEB": 4.26,
    "MAR": 4.26,
    "APR": 4.12,
    "MAY": 4.3,
    "JUN": 4.76,
    "JUL": 4.75,
    "AUG": 5.28,
    "SEP": 5.21,
    "OCT": 5.23,
    "NOV": 5.05,
    "DEC": 4.5
  },
  {
    "LONG": -53.48,
    "LAT": -1.79,
    "CIDADE": "Prainha",
    "UF": "PARÁ",
    "anual": 4.91,
    "JAN": 4.6,
    "FEB": 4.62,
    "MAR": 4.69,
    "APR": 4.65,
    "MAY": 4.77,
    "JUN": 5.04,
    "JUL": 5,
    "AUG": 5.26,
    "SEP": 5.11,
    "OCT": 5.32,
    "NOV": 5.13,
    "DEC": 4.69
  },
  {
    "LONG": -50.72,
    "LAT": -1.8,
    "CIDADE": "Melgaço",
    "UF": "PARÁ",
    "anual": 4.85,
    "JAN": 4.51,
    "FEB": 4.4,
    "MAR": 4.51,
    "APR": 4.54,
    "MAY": 4.75,
    "JUN": 4.99,
    "JUL": 5.11,
    "AUG": 5.32,
    "SEP": 5.3,
    "OCT": 5.12,
    "NOV": 4.99,
    "DEC": 4.66
  },
  {
    "LONG": -49.8,
    "LAT": -1.81,
    "CIDADE": "Curralinho",
    "UF": "PARÁ",
    "anual": 5.08,
    "JAN": 4.58,
    "FEB": 4.6,
    "MAR": 4.78,
    "APR": 4.78,
    "MAY": 5.06,
    "JUN": 5.23,
    "JUL": 5.19,
    "AUG": 5.43,
    "SEP": 5.6,
    "OCT": 5.52,
    "NOV": 5.31,
    "DEC": 4.86
  },
  {
    "LONG": -47.45,
    "LAT": -1.77,
    "CIDADE": "Irituia",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.45,
    "FEB": 4.47,
    "MAR": 4.44,
    "APR": 4.56,
    "MAY": 4.71,
    "JUN": 5.03,
    "JUL": 5.13,
    "AUG": 5.4,
    "SEP": 5.45,
    "OCT": 5.17,
    "NOV": 5.01,
    "DEC": 4.55
  },
  {
    "LONG": -46.55,
    "LAT": -1.76,
    "CIDADE": "Cachoeira do Piriá",
    "UF": "PARÁ",
    "anual": 4.79,
    "JAN": 4.43,
    "FEB": 4.32,
    "MAR": 4.32,
    "APR": 4.43,
    "MAY": 4.61,
    "JUN": 4.86,
    "JUL": 5.02,
    "AUG": 5.37,
    "SEP": 5.52,
    "OCT": 5.19,
    "NOV": 4.95,
    "DEC": 4.52
  },
  {
    "LONG": -52.24,
    "LAT": -1.75,
    "CIDADE": "Porto de Moz",
    "UF": "PARÁ",
    "anual": 4.72,
    "JAN": 4.42,
    "FEB": 4.48,
    "MAR": 4.48,
    "APR": 4.49,
    "MAY": 4.55,
    "JUN": 4.73,
    "JUL": 4.75,
    "AUG": 5.09,
    "SEP": 5.14,
    "OCT": 5.08,
    "NOV": 4.91,
    "DEC": 4.51
  },
  {
    "LONG": -50.48,
    "LAT": -1.68,
    "CIDADE": "Breves",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.35,
    "FEB": 4.29,
    "MAR": 4.3,
    "APR": 4.44,
    "MAY": 4.65,
    "JUN": 4.9,
    "JUL": 4.98,
    "AUG": 5.27,
    "SEP": 5.15,
    "OCT": 5.03,
    "NOV": 4.92,
    "DEC": 4.46
  },
  {
    "LONG": -49.53,
    "LAT": -1.72,
    "CIDADE": "São Sebastião da Boa Vista",
    "UF": "PARÁ",
    "anual": 5.05,
    "JAN": 4.58,
    "FEB": 4.55,
    "MAR": 4.69,
    "APR": 4.71,
    "MAY": 5.05,
    "JUN": 5.13,
    "JUL": 5.23,
    "AUG": 5.37,
    "SEP": 5.57,
    "OCT": 5.46,
    "NOV": 5.34,
    "DEC": 4.88
  },
  {
    "LONG": -48.88,
    "LAT": -1.72,
    "CIDADE": "Abaetetuba",
    "UF": "PARÁ",
    "anual": 4.9,
    "JAN": 4.5,
    "FEB": 4.41,
    "MAR": 4.53,
    "APR": 4.57,
    "MAY": 4.8,
    "JUN": 5.01,
    "JUL": 5.14,
    "AUG": 5.4,
    "SEP": 5.42,
    "OCT": 5.26,
    "NOV": 5.04,
    "DEC": 4.7
  },
  {
    "LONG": -47.77,
    "LAT": -1.69,
    "CIDADE": "São Domingos do Capim",
    "UF": "PARÁ",
    "anual": 4.9,
    "JAN": 4.53,
    "FEB": 4.49,
    "MAR": 4.47,
    "APR": 4.57,
    "MAY": 4.78,
    "JUN": 5.06,
    "JUL": 5.15,
    "AUG": 5.39,
    "SEP": 5.44,
    "OCT": 5.29,
    "NOV": 5.03,
    "DEC": 4.61
  },
  {
    "LONG": -47.06,
    "LAT": -1.75,
    "CIDADE": "Capitão Poço",
    "UF": "PARÁ",
    "anual": 4.86,
    "JAN": 4.54,
    "FEB": 4.37,
    "MAR": 4.45,
    "APR": 4.47,
    "MAY": 4.62,
    "JUN": 4.97,
    "JUL": 5.11,
    "AUG": 5.39,
    "SEP": 5.51,
    "OCT": 5.26,
    "NOV": 4.99,
    "DEC": 4.59
  },
  {
    "LONG": -47.48,
    "LAT": -1.61,
    "CIDADE": "São Miguel do Guamá",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.51,
    "FEB": 4.38,
    "MAR": 4.38,
    "APR": 4.52,
    "MAY": 4.71,
    "JUN": 5.07,
    "JUL": 5.15,
    "AUG": 5.45,
    "SEP": 5.53,
    "OCT": 5.22,
    "NOV": 5.02,
    "DEC": 4.6
  },
  {
    "LONG": -5.26,
    "LAT": -1.52,
    "CIDADE": "Almeirim",
    "UF": "PARÁ",
    "anual": 4.8,
    "JAN": 4.47,
    "FEB": 4.55,
    "MAR": 4.57,
    "APR": 4.56,
    "MAY": 4.73,
    "JUN": 4.96,
    "JUL": 4.91,
    "AUG": 5.12,
    "SEP": 5.08,
    "OCT": 5.14,
    "NOV": 4.98,
    "DEC": 4.56
  },
  {
    "LONG": -49.22,
    "LAT": -1.54,
    "CIDADE": "Muaná",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.43,
    "FEB": 4.36,
    "MAR": 4.47,
    "APR": 4.53,
    "MAY": 4.79,
    "JUN": 4.93,
    "JUL": 5.09,
    "AUG": 5.34,
    "SEP": 5.38,
    "OCT": 5.35,
    "NOV": 5.19,
    "DEC": 4.75
  },
  {
    "LONG": -48.62,
    "LAT": -1.51,
    "CIDADE": "Barcarena",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.38,
    "FEB": 4.4,
    "MAR": 4.49,
    "APR": 4.62,
    "MAY": 4.78,
    "JUN": 5.01,
    "JUL": 5.11,
    "AUG": 5.33,
    "SEP": 5.39,
    "OCT": 5.22,
    "NOV": 5.11,
    "DEC": 4.65
  },
  {
    "LONG": -48.49,
    "LAT": -1.46,
    "CIDADE": "Belém",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.4,
    "FEB": 4.38,
    "MAR": 4.41,
    "APR": 4.57,
    "MAY": 4.76,
    "JUN": 5.03,
    "JUL": 5.13,
    "AUG": 5.34,
    "SEP": 5.39,
    "OCT": 5.22,
    "NOV": 5.07,
    "DEC": 4.7
  },
  {
    "LONG": -48.04,
    "LAT": -0.15,
    "CIDADE": "Bujaru",
    "UF": "PARÁ",
    "anual": 4.85,
    "JAN": 4.38,
    "FEB": 4.35,
    "MAR": 4.38,
    "APR": 4.41,
    "MAY": 4.74,
    "JUN": 5.06,
    "JUL": 5.17,
    "AUG": 5.46,
    "SEP": 5.44,
    "OCT": 5.27,
    "NOV": 4.95,
    "DEC": 4.58
  },
  {
    "LONG": -47.12,
    "LAT": -1.55,
    "CIDADE": "Ourém",
    "UF": "PARÁ",
    "anual": 4.81,
    "JAN": 4.39,
    "FEB": 4.29,
    "MAR": 4.31,
    "APR": 4.4,
    "MAY": 4.62,
    "JUN": 4.92,
    "JUL": 5.08,
    "AUG": 5.33,
    "SEP": 5.52,
    "OCT": 5.21,
    "NOV": 4.99,
    "DEC": 4.63
  },
  {
    "LONG": -46.9,
    "LAT": -1.52,
    "CIDADE": "Santa Luzia do Pará",
    "UF": "PARÁ",
    "anual": 4.8,
    "JAN": 4.44,
    "FEB": 4.27,
    "MAR": 4.31,
    "APR": 4.37,
    "MAY": 4.57,
    "JUN": 4.87,
    "JUL": 5.07,
    "AUG": 5.32,
    "SEP": 5.49,
    "OCT": 5.24,
    "NOV": 5,
    "DEC": 4.62
  },
  {
    "LONG": -51.63,
    "LAT": -1.41,
    "CIDADE": "Gurupá",
    "UF": "PARÁ",
    "anual": 4.79,
    "JAN": 4.47,
    "FEB": 4.37,
    "MAR": 4.38,
    "APR": 4.45,
    "MAY": 4.67,
    "JUN": 4.89,
    "JUL": 4.89,
    "AUG": 5.2,
    "SEP": 5.3,
    "OCT": 5.17,
    "NOV": 5.13,
    "DEC": 4.61
  },
  {
    "LONG": -48.87,
    "LAT": -1.4,
    "CIDADE": "Ponta de Pedras",
    "UF": "PARÁ",
    "anual": 5.15,
    "JAN": 4.76,
    "FEB": 4.63,
    "MAR": 4.73,
    "APR": 4.83,
    "MAY": 5.04,
    "JUN": 5.23,
    "JUL": 5.28,
    "AUG": 5.54,
    "SEP": 5.68,
    "OCT": 5.55,
    "NOV": 5.44,
    "DEC": 5.09
  },
  {
    "LONG": -4.83,
    "LAT": -1.36,
    "CIDADE": "Marituba",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.25,
    "FEB": 4.26,
    "MAR": 4.27,
    "APR": 4.33,
    "MAY": 4.64,
    "JUN": 4.99,
    "JUL": 5.09,
    "AUG": 5.26,
    "SEP": 5.29,
    "OCT": 5.04,
    "NOV": 4.88,
    "DEC": 4.49
  },
  {
    "LONG": -48.37,
    "LAT": -1.36,
    "CIDADE": "Ananindeua",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.25,
    "FEB": 4.26,
    "MAR": 4.27,
    "APR": 4.33,
    "MAY": 4.64,
    "JUN": 4.99,
    "JUL": 5.09,
    "AUG": 5.26,
    "SEP": 5.29,
    "OCT": 5.04,
    "NOV": 4.88,
    "DEC": 4.49
  },
  {
    "LONG": -48.24,
    "LAT": -1.36,
    "CIDADE": "Benevides",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.28,
    "FEB": 4.23,
    "MAR": 4.29,
    "APR": 4.36,
    "MAY": 4.64,
    "JUN": 4.97,
    "JUL": 5.11,
    "AUG": 5.33,
    "SEP": 5.35,
    "OCT": 5.1,
    "NOV": 4.92,
    "DEC": 4.5
  },
  {
    "LONG": -47.91,
    "LAT": -1.43,
    "CIDADE": "Inhangapi",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.33,
    "FEB": 4.26,
    "MAR": 4.31,
    "APR": 4.33,
    "MAY": 4.64,
    "JUN": 4.94,
    "JUL": 5.05,
    "AUG": 5.33,
    "SEP": 5.4,
    "OCT": 5.18,
    "NOV": 4.92,
    "DEC": 4.48
  },
  {
    "LONG": -47.31,
    "LAT": -1.37,
    "CIDADE": "Bonito",
    "UF": "PARÁ",
    "anual": 4.78,
    "JAN": 4.35,
    "FEB": 4.22,
    "MAR": 4.26,
    "APR": 4.36,
    "MAY": 4.6,
    "JUN": 4.89,
    "JUL": 5.05,
    "AUG": 5.34,
    "SEP": 5.47,
    "OCT": 5.21,
    "NOV": 4.98,
    "DEC": 4.58
  },
  {
    "LONG": -4.82,
    "LAT": -1.3,
    "CIDADE": "Santa Izabel do Pará",
    "UF": "PARÁ",
    "anual": 4.71,
    "JAN": 4.29,
    "FEB": 4.17,
    "MAR": 4.16,
    "APR": 4.31,
    "MAY": 4.58,
    "JUN": 4.91,
    "JUL": 5.03,
    "AUG": 5.27,
    "SEP": 5.37,
    "OCT": 5.1,
    "NOV": 4.86,
    "DEC": 4.51
  },
  {
    "LONG": -47.92,
    "LAT": -1.3,
    "CIDADE": "Castanhal",
    "UF": "PARÁ",
    "anual": 4.71,
    "JAN": 4.33,
    "FEB": 4.18,
    "MAR": 4.26,
    "APR": 4.33,
    "MAY": 4.6,
    "JUN": 4.89,
    "JUL": 5,
    "AUG": 5.23,
    "SEP": 5.28,
    "OCT": 5.09,
    "NOV": 4.87,
    "DEC": 4.47
  },
  {
    "LONG": -47.57,
    "LAT": -1.35,
    "CIDADE": "Santa Maria do Pará",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.31,
    "FEB": 4.19,
    "MAR": 4.24,
    "APR": 4.35,
    "MAY": 4.64,
    "JUN": 4.9,
    "JUL": 5.02,
    "AUG": 5.29,
    "SEP": 5.43,
    "OCT": 5.21,
    "NOV": 4.95,
    "DEC": 4.56
  },
  {
    "LONG": -48.3,
    "LAT": -1.23,
    "CIDADE": "Santa Bárbara do Pará",
    "UF": "PARÁ",
    "anual": 4.68,
    "JAN": 4.26,
    "FEB": 4.15,
    "MAR": 4.12,
    "APR": 4.24,
    "MAY": 4.52,
    "JUN": 4.87,
    "JUL": 4.99,
    "AUG": 5.25,
    "SEP": 5.31,
    "OCT": 5.12,
    "NOV": 4.9,
    "DEC": 4.48
  },
  {
    "LONG": -48.13,
    "LAT": -1.15,
    "CIDADE": "Santo Antônio do Tauá",
    "UF": "PARÁ",
    "anual": 4.69,
    "JAN": 4.28,
    "FEB": 4.14,
    "MAR": 4.08,
    "APR": 4.24,
    "MAY": 4.53,
    "JUN": 4.89,
    "JUL": 5.02,
    "AUG": 5.25,
    "SEP": 5.32,
    "OCT": 5.13,
    "NOV": 4.86,
    "DEC": 4.48
  },
  {
    "LONG": -47.79,
    "LAT": -1.17,
    "CIDADE": "São Francisco do Pará",
    "UF": "PARÁ",
    "anual": 4.74,
    "JAN": 4.31,
    "FEB": 4.2,
    "MAR": 4.2,
    "APR": 4.3,
    "MAY": 4.61,
    "JUN": 4.86,
    "JUL": 5.05,
    "AUG": 5.25,
    "SEP": 5.37,
    "OCT": 5.24,
    "NOV": 5,
    "DEC": 4.53
  },
  {
    "LONG": -47.32,
    "LAT": -1.19,
    "CIDADE": "Peixe-Boi",
    "UF": "PARÁ",
    "anual": 4.78,
    "JAN": 4.35,
    "FEB": 4.2,
    "MAR": 4.21,
    "APR": 4.29,
    "MAY": 4.58,
    "JUN": 4.86,
    "JUL": 4.96,
    "AUG": 5.27,
    "SEP": 5.51,
    "OCT": 5.31,
    "NOV": 5.09,
    "DEC": 4.67
  },
  {
    "LONG": -47.39,
    "LAT": -1.2,
    "CIDADE": "Nova Timboteua",
    "UF": "PARÁ",
    "anual": 4.78,
    "JAN": 4.35,
    "FEB": 4.2,
    "MAR": 4.21,
    "APR": 4.29,
    "MAY": 4.58,
    "JUN": 4.86,
    "JUL": 4.96,
    "AUG": 5.27,
    "SEP": 5.51,
    "OCT": 5.31,
    "NOV": 5.09,
    "DEC": 4.67
  },
  {
    "LONG": -47.18,
    "LAT": -1.21,
    "CIDADE": "Capanema",
    "UF": "PARÁ",
    "anual": 4.75,
    "JAN": 4.37,
    "FEB": 4.19,
    "MAR": 4.16,
    "APR": 4.28,
    "MAY": 4.55,
    "JUN": 4.8,
    "JUL": 4.93,
    "AUG": 5.25,
    "SEP": 5.51,
    "OCT": 5.27,
    "NOV": 5.03,
    "DEC": 4.67
  },
  {
    "LONG": -46.14,
    "LAT": -1.2,
    "CIDADE": "Viseu",
    "UF": "PARÁ",
    "anual": 4.93,
    "JAN": 4.74,
    "FEB": 4.47,
    "MAR": 4.32,
    "APR": 4.29,
    "MAY": 4.57,
    "JUN": 4.83,
    "JUL": 4.96,
    "AUG": 5.33,
    "SEP": 5.68,
    "OCT": 5.5,
    "NOV": 5.4,
    "DEC": 5.06
  },
  {
    "LONG": -47.62,
    "LAT": -1.13,
    "CIDADE": "Igarapé-Açu",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.32,
    "FEB": 4.18,
    "MAR": 4.13,
    "APR": 4.28,
    "MAY": 4.57,
    "JUN": 4.84,
    "JUL": 4.98,
    "AUG": 5.23,
    "SEP": 5.41,
    "OCT": 5.21,
    "NOV": 5.02,
    "DEC": 4.58
  },
  {
    "LONG": -46.9,
    "LAT": -1.08,
    "CIDADE": "Tracuateua",
    "UF": "PARÁ",
    "anual": 4.8,
    "JAN": 4.5,
    "FEB": 4.3,
    "MAR": 4.14,
    "APR": 4.23,
    "MAY": 4.51,
    "JUN": 4.83,
    "JUL": 4.92,
    "AUG": 5.26,
    "SEP": 5.54,
    "OCT": 5.34,
    "NOV": 5.17,
    "DEC": 4.82
  },
  {
    "LONG": -4.68,
    "LAT": -0.11,
    "CIDADE": "Bragança",
    "UF": "PARÁ",
    "anual": 4.84,
    "JAN": 4.62,
    "FEB": 4.33,
    "MAR": 4.18,
    "APR": 4.21,
    "MAY": 4.51,
    "JUN": 4.79,
    "JUL": 4.9,
    "AUG": 5.29,
    "SEP": 5.57,
    "OCT": 5.44,
    "NOV": 5.27,
    "DEC": 4.94
  },
  {
    "LONG": -49.94,
    "LAT": "#VALOR!",
    "CIDADE": "Anajás",
    "UF": "PARÁ",
    "anual": 4.66,
    "JAN": 4.19,
    "FEB": 4.09,
    "MAR": 4.12,
    "APR": 4.19,
    "MAY": 4.59,
    "JUN": 4.76,
    "JUL": 5,
    "AUG": 5.18,
    "SEP": 5.25,
    "OCT": 5.12,
    "NOV": 5.03,
    "DEC": 4.45
  },
  {
    "LONG": -48.96,
    "LAT": -1.01,
    "CIDADE": "Cachoeira do Arari",
    "UF": "PARÁ",
    "anual": 4.83,
    "JAN": 4.41,
    "FEB": 4.28,
    "MAR": 4.36,
    "APR": 4.45,
    "MAY": 4.86,
    "JUN": 4.97,
    "JUL": 5.1,
    "AUG": 5.26,
    "SEP": 5.46,
    "OCT": 5.19,
    "NOV": 5.04,
    "DEC": 4.62
  },
  {
    "LONG": -47.91,
    "LAT": -0.1,
    "CIDADE": "Terra Alta",
    "UF": "PARÁ",
    "anual": 4.69,
    "JAN": 4.31,
    "FEB": 4.04,
    "MAR": 4.01,
    "APR": 4.18,
    "MAY": 4.51,
    "JUN": 4.85,
    "JUL": 4.95,
    "AUG": 5.22,
    "SEP": 5.38,
    "OCT": 5.19,
    "NOV": 5.03,
    "DEC": 4.58
  },
  {
    "LONG": -46.65,
    "LAT": -0.1,
    "CIDADE": "Augusto Corrêa",
    "UF": "PARÁ",
    "anual": 4.96,
    "JAN": 4.79,
    "FEB": 4.5,
    "MAR": 4.29,
    "APR": 4.36,
    "MAY": 4.62,
    "JUN": 4.85,
    "JUL": 4.98,
    "AUG": 5.36,
    "SEP": 5.68,
    "OCT": 5.54,
    "NOV": 5.44,
    "DEC": 5.1
  },
  {
    "LONG": -48.28,
    "LAT": "#VALOR!",
    "CIDADE": "Colares",
    "UF": "PARÁ",
    "anual": 4.7,
    "JAN": 4.32,
    "FEB": 4.12,
    "MAR": 4.09,
    "APR": 4.16,
    "MAY": 4.48,
    "JUN": 4.73,
    "JUL": 4.85,
    "AUG": 5.13,
    "SEP": 5.4,
    "OCT": 5.31,
    "NOV": 5.2,
    "DEC": 4.67
  },
  {
    "LONG": -4.81,
    "LAT": "#VALOR!",
    "CIDADE": "Vigia",
    "UF": "PARÁ",
    "anual": 4.63,
    "JAN": 4.25,
    "FEB": 4.02,
    "MAR": 3.98,
    "APR": 4.07,
    "MAY": 4.43,
    "JUN": 4.7,
    "JUL": 4.84,
    "AUG": 5.16,
    "SEP": 5.34,
    "OCT": 5.17,
    "NOV": 5.04,
    "DEC": 4.49
  },
  {
    "LONG": -4.79,
    "LAT": "#VALOR!",
    "CIDADE": "São João da Ponta",
    "UF": "PARÁ",
    "anual": 4.68,
    "JAN": 4.29,
    "FEB": 4.04,
    "MAR": 3.99,
    "APR": 4.14,
    "MAY": 4.44,
    "JUN": 4.79,
    "JUL": 4.89,
    "AUG": 5.2,
    "SEP": 5.42,
    "OCT": 5.28,
    "NOV": 5.05,
    "DEC": 4.63
  },
  {
    "LONG": -47.4,
    "LAT": "#VALOR!",
    "CIDADE": "Santarém Novo",
    "UF": "PARÁ",
    "anual": 4.8,
    "JAN": 4.56,
    "FEB": 4.25,
    "MAR": 4.2,
    "APR": 4.21,
    "MAY": 4.5,
    "JUN": 4.79,
    "JUL": 4.94,
    "AUG": 5.23,
    "SEP": 5.5,
    "OCT": 5.36,
    "NOV": 5.19,
    "DEC": 4.91
  },
  {
    "LONG": -47.13,
    "LAT": "#VALOR!",
    "CIDADE": "Primavera",
    "UF": "PARÁ",
    "anual": 4.83,
    "JAN": 4.57,
    "FEB": 4.34,
    "MAR": 4.22,
    "APR": 4.24,
    "MAY": 4.51,
    "JUN": 4.84,
    "JUL": 4.91,
    "AUG": 5.22,
    "SEP": 5.52,
    "OCT": 5.39,
    "NOV": 5.24,
    "DEC": 4.94
  },
  {
    "LONG": -4.7,
    "LAT": "#VALOR!",
    "CIDADE": "Quatipuru",
    "UF": "PARÁ",
    "anual": 4.86,
    "JAN": 4.61,
    "FEB": 4.39,
    "MAR": 4.25,
    "APR": 4.28,
    "MAY": 4.51,
    "JUN": 4.81,
    "JUL": 4.93,
    "AUG": 5.27,
    "SEP": 5.54,
    "OCT": 5.45,
    "NOV": 5.29,
    "DEC": 5.01
  },
  {
    "LONG": -48.51,
    "LAT": "#VALOR!",
    "CIDADE": "Salvaterra",
    "UF": "PARÁ",
    "anual": 5.05,
    "JAN": 4.67,
    "FEB": 4.37,
    "MAR": 4.41,
    "APR": 4.49,
    "MAY": 4.87,
    "JUN": 5.08,
    "JUL": 5.13,
    "AUG": 5.48,
    "SEP": 5.71,
    "OCT": 5.65,
    "NOV": 5.58,
    "DEC": 5.12
  },
  {
    "LONG": -4.76,
    "LAT": "#VALOR!",
    "CIDADE": "Magalhães Barata",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.49,
    "FEB": 4.22,
    "MAR": 4.15,
    "APR": 4.18,
    "MAY": 4.5,
    "JUN": 4.77,
    "JUL": 4.86,
    "AUG": 5.22,
    "SEP": 5.46,
    "OCT": 5.32,
    "NOV": 5.16,
    "DEC": 4.81
  },
  {
    "LONG": -47.45,
    "LAT": "#VALOR!",
    "CIDADE": "Maracanã",
    "UF": "PARÁ",
    "anual": 4.84,
    "JAN": 4.59,
    "FEB": 4.3,
    "MAR": 4.2,
    "APR": 4.23,
    "MAY": 4.55,
    "JUN": 4.81,
    "JUL": 4.96,
    "AUG": 5.27,
    "SEP": 5.5,
    "OCT": 5.39,
    "NOV": 5.27,
    "DEC": 4.97
  },
  {
    "LONG": -47.18,
    "LAT": "#VALOR!",
    "CIDADE": "São João de Pirabas",
    "UF": "PARÁ",
    "anual": 4.95,
    "JAN": 4.73,
    "FEB": 4.47,
    "MAR": 4.31,
    "APR": 4.36,
    "MAY": 4.69,
    "JUN": 4.92,
    "JUL": 5.03,
    "AUG": 5.32,
    "SEP": 5.59,
    "OCT": 5.48,
    "NOV": 5.36,
    "DEC": 5.08
  },
  {
    "LONG": -49.18,
    "LAT": "#VALOR!",
    "CIDADE": "Santa Cruz do Arari",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.3,
    "FEB": 4.12,
    "MAR": 4.24,
    "APR": 4.32,
    "MAY": 4.71,
    "JUN": 4.94,
    "JUL": 5.08,
    "AUG": 5.21,
    "SEP": 5.3,
    "OCT": 5.08,
    "NOV": 4.97,
    "DEC": 4.51
  },
  {
    "LONG": -48.52,
    "LAT": "#VALOR!",
    "CIDADE": "Soure",
    "UF": "PARÁ",
    "anual": 5.03,
    "JAN": 4.7,
    "FEB": 4.34,
    "MAR": 4.38,
    "APR": 4.48,
    "MAY": 4.88,
    "JUN": 5.11,
    "JUL": 5.19,
    "AUG": 5.4,
    "SEP": 5.67,
    "OCT": 5.58,
    "NOV": 5.51,
    "DEC": 5.12
  },
  {
    "LONG": -48.02,
    "LAT": "#VALOR!",
    "CIDADE": "São Caetano de Odivelas",
    "UF": "PARÁ",
    "anual": 4.91,
    "JAN": 4.65,
    "FEB": 4.24,
    "MAR": 4.21,
    "APR": 4.29,
    "MAY": 4.52,
    "JUN": 4.84,
    "JUL": 4.97,
    "AUG": 5.39,
    "SEP": 5.65,
    "OCT": 5.59,
    "NOV": 5.52,
    "DEC": 5.11
  },
  {
    "LONG": -47.85,
    "LAT": "#VALOR!",
    "CIDADE": "Curuçá",
    "UF": "PARÁ",
    "anual": 4.82,
    "JAN": 4.57,
    "FEB": 4.24,
    "MAR": 4.13,
    "APR": 4.22,
    "MAY": 4.51,
    "JUN": 4.83,
    "JUL": 4.94,
    "AUG": 5.27,
    "SEP": 5.49,
    "OCT": 5.41,
    "NOV": 5.32,
    "DEC": 4.96
  },
  {
    "LONG": -47.7,
    "LAT": "#VALOR!",
    "CIDADE": "Marapanim",
    "UF": "PARÁ",
    "anual": 4.93,
    "JAN": 4.72,
    "FEB": 4.34,
    "MAR": 4.26,
    "APR": 4.3,
    "MAY": 4.62,
    "JUN": 4.89,
    "JUL": 5.04,
    "AUG": 5.37,
    "SEP": 5.6,
    "OCT": 5.53,
    "NOV": 5.45,
    "DEC": 5.07
  },
  {
    "LONG": -47.35,
    "LAT": "#VALOR!",
    "CIDADE": "Salinópolis",
    "UF": "PARÁ",
    "anual": 5.06,
    "JAN": 4.92,
    "FEB": 4.52,
    "MAR": 4.45,
    "APR": 4.52,
    "MAY": 4.82,
    "JUN": 5.13,
    "JUL": 5.24,
    "AUG": 5.25,
    "SEP": 5.66,
    "OCT": 5.53,
    "NOV": 5.48,
    "DEC": 5.2
  },
  {
    "LONG": -50.39,
    "LAT": "#VALOR!",
    "CIDADE": "Afuá",
    "UF": "PARÁ",
    "anual": 4.7,
    "JAN": 4.38,
    "FEB": 4.06,
    "MAR": 3.96,
    "APR": 3.99,
    "MAY": 4.43,
    "JUN": 4.69,
    "JUL": 4.85,
    "AUG": 5.25,
    "SEP": 5.5,
    "OCT": 5.39,
    "NOV": 5.22,
    "DEC": 4.69
  },
  {
    "LONG": -49.99,
    "LAT": "#VALOR!",
    "CIDADE": "Chaves",
    "UF": "PARÁ",
    "anual": 4.82,
    "JAN": 4.49,
    "FEB": 4.08,
    "MAR": 4,
    "APR": 4.05,
    "MAY": 4.54,
    "JUN": 4.92,
    "JUL": 5.08,
    "AUG": 5.39,
    "SEP": 5.62,
    "OCT": 5.5,
    "NOV": 5.34,
    "DEC": 4.78
  },
  {
    "LONG": -37.01,
    "LAT": -8.15,
    "CIDADE": "São Sebastião do Umbuzeiro",
    "UF": "PARAÍBA",
    "anual": 5.66,
    "JAN": 5.87,
    "FEB": 5.81,
    "MAR": 6.04,
    "APR": 5.7,
    "MAY": 5.02,
    "JUN": 4.58,
    "JUL": 4.66,
    "AUG": 5.44,
    "SEP": 6.2,
    "OCT": 6.25,
    "NOV": 6.36,
    "DEC": 6.01
  },
  {
    "LONG": -37.1,
    "LAT": -0.81,
    "CIDADE": "Zabelê",
    "UF": "PARAÍBA",
    "anual": 5.68,
    "JAN": 5.87,
    "FEB": 5.86,
    "MAR": 5.99,
    "APR": 5.69,
    "MAY": 5.01,
    "JUN": 4.61,
    "JUL": 4.75,
    "AUG": 5.44,
    "SEP": 6.23,
    "OCT": 6.28,
    "NOV": 6.41,
    "DEC": 6.06
  },
  {
    "LONG": -36.86,
    "LAT": -8.08,
    "CIDADE": "São João do Tigre",
    "UF": "PARAÍBA",
    "anual": 5.66,
    "JAN": 5.82,
    "FEB": 5.8,
    "MAR": 5.99,
    "APR": 5.77,
    "MAY": 4.99,
    "JUN": 4.55,
    "JUL": 4.72,
    "AUG": 5.51,
    "SEP": 6.19,
    "OCT": 6.2,
    "NOV": 6.36,
    "DEC": 6.05
  },
  {
    "LONG": -37.12,
    "LAT": -7.89,
    "CIDADE": "Monteiro",
    "UF": "PARAÍBA",
    "anual": 5.79,
    "JAN": 5.98,
    "FEB": 5.96,
    "MAR": 6.15,
    "APR": 5.88,
    "MAY": 5.18,
    "JUN": 4.7,
    "JUL": 4.84,
    "AUG": 5.54,
    "SEP": 6.34,
    "OCT": 6.37,
    "NOV": 6.46,
    "DEC": 6.06
  },
  {
    "LONG": -36.82,
    "LAT": -7.89,
    "CIDADE": "Camalaú",
    "UF": "PARAÍBA",
    "anual": 5.73,
    "JAN": 5.89,
    "FEB": 5.93,
    "MAR": 6.1,
    "APR": 5.85,
    "MAY": 5.12,
    "JUN": 4.7,
    "JUL": 4.78,
    "AUG": 5.49,
    "SEP": 6.22,
    "OCT": 6.29,
    "NOV": 6.35,
    "DEC": 6.02
  },
  {
    "LONG": -36.66,
    "LAT": -7.79,
    "CIDADE": "Congo",
    "UF": "PARAÍBA",
    "anual": 5.66,
    "JAN": 5.83,
    "FEB": 5.84,
    "MAR": 6.05,
    "APR": 5.81,
    "MAY": 5.11,
    "JUN": 4.65,
    "JUL": 4.75,
    "AUG": 5.42,
    "SEP": 6.08,
    "OCT": 6.19,
    "NOV": 6.25,
    "DEC": 5.92
  },
  {
    "LONG": -36.32,
    "LAT": -7.75,
    "CIDADE": "Barra de São Miguel",
    "UF": "PARAÍBA",
    "anual": 5.54,
    "JAN": 5.71,
    "FEB": 5.75,
    "MAR": 5.93,
    "APR": 5.63,
    "MAY": 5.05,
    "JUN": 4.61,
    "JUL": 4.73,
    "AUG": 5.38,
    "SEP": 5.9,
    "OCT": 6,
    "NOV": 6.08,
    "DEC": 5.76
  },
  {
    "LONG": -38.1,
    "LAT": -7.74,
    "CIDADE": "São José de Princesa",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.85,
    "FEB": 5.94,
    "MAR": 6.14,
    "APR": 5.9,
    "MAY": 5.33,
    "JUN": 5.01,
    "JUL": 5.25,
    "AUG": 6.02,
    "SEP": 6.53,
    "OCT": 6.42,
    "NOV": 6.43,
    "DEC": 5.96
  },
  {
    "LONG": -38.15,
    "LAT": -7.7,
    "CIDADE": "Manaíra",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.85,
    "FEB": 5.94,
    "MAR": 6.14,
    "APR": 5.9,
    "MAY": 5.33,
    "JUN": 5.01,
    "JUL": 5.25,
    "AUG": 6.02,
    "SEP": 6.53,
    "OCT": 6.42,
    "NOV": 6.43,
    "DEC": 5.96
  },
  {
    "LONG": -3.8,
    "LAT": -7.74,
    "CIDADE": "Princesa Isabel",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.88,
    "FEB": 5.97,
    "MAR": 6.13,
    "APR": 5.95,
    "MAY": 5.33,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 5.97,
    "SEP": 6.5,
    "OCT": 6.41,
    "NOV": 6.39,
    "DEC": 5.99
  },
  {
    "LONG": -37.08,
    "LAT": -0.77,
    "CIDADE": "Prata",
    "UF": "PARAÍBA",
    "anual": 5.77,
    "JAN": 5.88,
    "FEB": 5.93,
    "MAR": 6.06,
    "APR": 5.91,
    "MAY": 5.22,
    "JUN": 4.75,
    "JUL": 4.89,
    "AUG": 5.6,
    "SEP": 6.29,
    "OCT": 6.34,
    "NOV": 6.37,
    "DEC": 6
  },
  {
    "LONG": -36.88,
    "LAT": -7.67,
    "CIDADE": "Sumé",
    "UF": "PARAÍBA",
    "anual": 5.7,
    "JAN": 5.82,
    "FEB": 5.89,
    "MAR": 6.03,
    "APR": 5.81,
    "MAY": 5.15,
    "JUN": 4.69,
    "JUL": 4.85,
    "AUG": 5.49,
    "SEP": 6.19,
    "OCT": 6.25,
    "NOV": 6.29,
    "DEC": 5.93
  },
  {
    "LONG": -36.49,
    "LAT": -7.73,
    "CIDADE": "Caraúbas",
    "UF": "PARAÍBA",
    "anual": 5.54,
    "JAN": 5.68,
    "FEB": 5.73,
    "MAR": 5.95,
    "APR": 5.64,
    "MAY": 5.02,
    "JUN": 4.53,
    "JUL": 4.7,
    "AUG": 5.37,
    "SEP": 5.92,
    "OCT": 6.04,
    "NOV": 6.1,
    "DEC": 5.79
  },
  {
    "LONG": -36.16,
    "LAT": -0.77,
    "CIDADE": "Riacho de Santo Antônio",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.61,
    "FEB": 5.7,
    "MAR": 5.85,
    "APR": 5.51,
    "MAY": 4.96,
    "JUN": 4.45,
    "JUL": 4.64,
    "AUG": 5.25,
    "SEP": 5.76,
    "OCT": 5.88,
    "NOV": 6,
    "DEC": 5.67
  },
  {
    "LONG": -36.05,
    "LAT": -7.74,
    "CIDADE": "Alcantil",
    "UF": "PARAÍBA",
    "anual": 5.39,
    "JAN": 5.58,
    "FEB": 5.66,
    "MAR": 5.79,
    "APR": 5.45,
    "MAY": 4.93,
    "JUN": 4.43,
    "JUL": 4.63,
    "AUG": 5.19,
    "SEP": 5.68,
    "OCT": 5.81,
    "NOV": 5.93,
    "DEC": 5.59
  },
  {
    "LONG": -35.88,
    "LAT": -0.77,
    "CIDADE": "Santa Cecília",
    "UF": "PARAÍBA",
    "anual": 5.34,
    "JAN": 5.45,
    "FEB": 5.61,
    "MAR": 5.78,
    "APR": 5.39,
    "MAY": 4.84,
    "JUN": 4.42,
    "JUL": 4.58,
    "AUG": 5.2,
    "SEP": 5.66,
    "OCT": 5.75,
    "NOV": 5.85,
    "DEC": 5.59
  },
  {
    "LONG": -35.66,
    "LAT": -7.7,
    "CIDADE": "Umbuzeiro",
    "UF": "PARAÍBA",
    "anual": 5.31,
    "JAN": 5.4,
    "FEB": 5.62,
    "MAR": 5.83,
    "APR": 5.35,
    "MAY": 4.75,
    "JUN": 4.29,
    "JUL": 4.49,
    "AUG": 5.15,
    "SEP": 5.62,
    "OCT": 5.71,
    "NOV": 5.87,
    "DEC": 5.66
  },
  {
    "LONG": -38.51,
    "LAT": -7.56,
    "CIDADE": "Conceição",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.9,
    "FEB": 5.9,
    "MAR": 6.02,
    "APR": 5.86,
    "MAY": 5.35,
    "JUN": 5.02,
    "JUL": 5.24,
    "AUG": 6.02,
    "SEP": 6.59,
    "OCT": 6.42,
    "NOV": 6.48,
    "DEC": 6.07
  },
  {
    "LONG": -38.56,
    "LAT": -7.63,
    "CIDADE": "Santa Inês",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.9,
    "FEB": 5.9,
    "MAR": 6.02,
    "APR": 5.86,
    "MAY": 5.35,
    "JUN": 5.02,
    "JUL": 5.24,
    "AUG": 6.02,
    "SEP": 6.59,
    "OCT": 6.42,
    "NOV": 6.48,
    "DEC": 6.07
  },
  {
    "LONG": -38.34,
    "LAT": -0.76,
    "CIDADE": "Santana de Mangueira",
    "UF": "PARAÍBA",
    "anual": 5.93,
    "JAN": 5.86,
    "FEB": 5.94,
    "MAR": 6.08,
    "APR": 5.96,
    "MAY": 5.39,
    "JUN": 5.07,
    "JUL": 5.33,
    "AUG": 6.03,
    "SEP": 6.55,
    "OCT": 6.44,
    "NOV": 6.47,
    "DEC": 6.03
  },
  {
    "LONG": -37.87,
    "LAT": -7.63,
    "CIDADE": "Tavares",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.83,
    "FEB": 5.97,
    "MAR": 6.12,
    "APR": 5.95,
    "MAY": 5.34,
    "JUN": 5.05,
    "JUL": 5.3,
    "AUG": 6.01,
    "SEP": 6.48,
    "OCT": 6.37,
    "NOV": 6.35,
    "DEC": 5.93
  },
  {
    "LONG": -37.15,
    "LAT": -7.62,
    "CIDADE": "Ouro Velho",
    "UF": "PARAÍBA",
    "anual": 5.81,
    "JAN": 5.84,
    "FEB": 5.95,
    "MAR": 6.1,
    "APR": 5.92,
    "MAY": 5.25,
    "JUN": 4.83,
    "JUL": 5,
    "AUG": 5.69,
    "SEP": 6.37,
    "OCT": 6.39,
    "NOV": 6.38,
    "DEC": 5.99
  },
  {
    "LONG": -3.71,
    "LAT": -7.57,
    "CIDADE": "Amparo",
    "UF": "PARAÍBA",
    "anual": 5.78,
    "JAN": 5.91,
    "FEB": 5.93,
    "MAR": 6.06,
    "APR": 5.88,
    "MAY": 5.23,
    "JUN": 4.79,
    "JUL": 4.98,
    "AUG": 5.69,
    "SEP": 6.3,
    "OCT": 6.34,
    "NOV": 6.32,
    "DEC": 5.97
  },
  {
    "LONG": -36.61,
    "LAT": -7.63,
    "CIDADE": "Coxixola",
    "UF": "PARAÍBA",
    "anual": 5.61,
    "JAN": 5.68,
    "FEB": 5.75,
    "MAR": 5.99,
    "APR": 5.71,
    "MAY": 5.14,
    "JUN": 4.64,
    "JUL": 4.79,
    "AUG": 5.44,
    "SEP": 6.06,
    "OCT": 6.13,
    "NOV": 6.13,
    "DEC": 5.81
  },
  {
    "LONG": -36.44,
    "LAT": -7.63,
    "CIDADE": "São Domingos do Cariri",
    "UF": "PARAÍBA",
    "anual": 5.5,
    "JAN": 5.61,
    "FEB": 5.62,
    "MAR": 5.92,
    "APR": 5.55,
    "MAY": 5.03,
    "JUN": 4.48,
    "JUL": 4.69,
    "AUG": 5.29,
    "SEP": 5.91,
    "OCT": 6.03,
    "NOV": 6.07,
    "DEC": 5.8
  },
  {
    "LONG": -35.79,
    "LAT": -7.58,
    "CIDADE": "Gado Bravo",
    "UF": "PARAÍBA",
    "anual": 5.28,
    "JAN": 5.4,
    "FEB": 5.58,
    "MAR": 5.75,
    "APR": 5.36,
    "MAY": 4.8,
    "JUN": 4.32,
    "JUL": 4.54,
    "AUG": 5.1,
    "SEP": 5.56,
    "OCT": 5.67,
    "NOV": 5.78,
    "DEC": 5.49
  },
  {
    "LONG": -35.55,
    "LAT": -7.64,
    "CIDADE": "Natuba",
    "UF": "PARAÍBA",
    "anual": 5.37,
    "JAN": 5.44,
    "FEB": 5.76,
    "MAR": 5.9,
    "APR": 5.45,
    "MAY": 4.78,
    "JUN": 4.33,
    "JUL": 4.47,
    "AUG": 5.2,
    "SEP": 5.66,
    "OCT": 5.8,
    "NOV": 5.96,
    "DEC": 5.73
  },
  {
    "LONG": -38.41,
    "LAT": "#VALOR!",
    "CIDADE": "Ibiara",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.94,
    "FEB": 5.97,
    "MAR": 6.04,
    "APR": 5.93,
    "MAY": 5.41,
    "JUN": 5.06,
    "JUL": 5.31,
    "AUG": 6.06,
    "SEP": 6.6,
    "OCT": 6.45,
    "NOV": 6.49,
    "DEC": 6.11
  },
  {
    "LONG": -3.82,
    "LAT": -7.54,
    "CIDADE": "Curral Velho",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.92,
    "FEB": 5.96,
    "MAR": 6.22,
    "APR": 6.04,
    "MAY": 5.49,
    "JUN": 5.09,
    "JUL": 5.31,
    "AUG": 6.11,
    "SEP": 6.61,
    "OCT": 6.5,
    "NOV": 6.49,
    "DEC": 6.06
  },
  {
    "LONG": -38.04,
    "LAT": -7.48,
    "CIDADE": "Nova Olinda",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.95,
    "FEB": 6.01,
    "MAR": 6.25,
    "APR": 6.07,
    "MAY": 5.47,
    "JUN": 5.08,
    "JUL": 5.31,
    "AUG": 6.11,
    "SEP": 6.59,
    "OCT": 6.47,
    "NOV": 6.48,
    "DEC": 6.07
  },
  {
    "LONG": -37.82,
    "LAT": -7.54,
    "CIDADE": "Juru",
    "UF": "PARAÍBA",
    "anual": 5.91,
    "JAN": 5.77,
    "FEB": 5.91,
    "MAR": 6.04,
    "APR": 5.95,
    "MAY": 5.4,
    "JUN": 5.1,
    "JUL": 5.33,
    "AUG": 6.07,
    "SEP": 6.59,
    "OCT": 6.44,
    "NOV": 6.4,
    "DEC": 5.92
  },
  {
    "LONG": -3.76,
    "LAT": -7.51,
    "CIDADE": "Água Branca",
    "UF": "PARAÍBA",
    "anual": 5.91,
    "JAN": 5.84,
    "FEB": 5.91,
    "MAR": 6.08,
    "APR": 5.98,
    "MAY": 5.37,
    "JUN": 5.08,
    "JUL": 5.32,
    "AUG": 6.05,
    "SEP": 6.55,
    "OCT": 6.42,
    "NOV": 6.39,
    "DEC": 5.93
  },
  {
    "LONG": -36.67,
    "LAT": -7.48,
    "CIDADE": "Serra Branca",
    "UF": "PARAÍBA",
    "anual": 5.61,
    "JAN": 5.68,
    "FEB": 5.78,
    "MAR": 5.98,
    "APR": 5.69,
    "MAY": 5.13,
    "JUN": 4.62,
    "JUL": 4.87,
    "AUG": 5.47,
    "SEP": 6.06,
    "OCT": 6.12,
    "NOV": 6.14,
    "DEC": 5.81
  },
  {
    "LONG": -36.29,
    "LAT": -7.49,
    "CIDADE": "Cabaceiras",
    "UF": "PARAÍBA",
    "anual": 5.56,
    "JAN": 5.65,
    "FEB": 5.9,
    "MAR": 6.02,
    "APR": 5.66,
    "MAY": 5.08,
    "JUN": 4.5,
    "JUL": 4.73,
    "AUG": 5.34,
    "SEP": 5.91,
    "OCT": 6.01,
    "NOV": 6.1,
    "DEC": 5.86
  },
  {
    "LONG": -36.13,
    "LAT": -7.48,
    "CIDADE": "Boqueirão",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.61,
    "FEB": 5.73,
    "MAR": 5.87,
    "APR": 5.54,
    "MAY": 4.98,
    "JUN": 4.44,
    "JUL": 4.62,
    "AUG": 5.21,
    "SEP": 5.75,
    "OCT": 5.84,
    "NOV": 5.98,
    "DEC": 5.74
  },
  {
    "LONG": -36,
    "LAT": -7.52,
    "CIDADE": "Barra de Santana",
    "UF": "PARAÍBA",
    "anual": 5.34,
    "JAN": 5.53,
    "FEB": 5.61,
    "MAR": 5.79,
    "APR": 5.42,
    "MAY": 4.85,
    "JUN": 4.3,
    "JUL": 4.5,
    "AUG": 5.14,
    "SEP": 5.63,
    "OCT": 5.75,
    "NOV": 5.91,
    "DEC": 5.6
  },
  {
    "LONG": -35.71,
    "LAT": -7.55,
    "CIDADE": "Aroeiras",
    "UF": "PARAÍBA",
    "anual": 5.3,
    "JAN": 5.46,
    "FEB": 5.59,
    "MAR": 5.79,
    "APR": 5.4,
    "MAY": 4.78,
    "JUN": 4.29,
    "JUL": 4.48,
    "AUG": 5.14,
    "SEP": 5.59,
    "OCT": 5.71,
    "NOV": 5.81,
    "DEC": 5.6
  },
  {
    "LONG": -34.91,
    "LAT": -7.51,
    "CIDADE": "Caaporã",
    "UF": "PARAÍBA",
    "anual": 5.41,
    "JAN": 5.47,
    "FEB": 5.85,
    "MAR": 6,
    "APR": 5.46,
    "MAY": 4.9,
    "JUN": 4.54,
    "JUL": 4.59,
    "AUG": 5.31,
    "SEP": 5.59,
    "OCT": 5.75,
    "NOV": 5.78,
    "DEC": 5.72
  },
  {
    "LONG": -34.82,
    "LAT": -7.47,
    "CIDADE": "Pitimbu",
    "UF": "PARAÍBA",
    "anual": 5.51,
    "JAN": 5.53,
    "FEB": 5.91,
    "MAR": 6.05,
    "APR": 5.51,
    "MAY": 5.04,
    "JUN": 4.65,
    "JUL": 4.68,
    "AUG": 5.45,
    "SEP": 5.71,
    "OCT": 5.91,
    "NOV": 5.82,
    "DEC": 5.83
  },
  {
    "LONG": -38.22,
    "LAT": -7.42,
    "CIDADE": "Boa Ventura",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.9,
    "FEB": 6.01,
    "MAR": 6.21,
    "APR": 6.06,
    "MAY": 5.49,
    "JUN": 5.11,
    "JUL": 5.37,
    "AUG": 6.1,
    "SEP": 6.6,
    "OCT": 6.46,
    "NOV": 6.5,
    "DEC": 6.13
  },
  {
    "LONG": -38.27,
    "LAT": -7.43,
    "CIDADE": "Diamante",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.9,
    "FEB": 6.01,
    "MAR": 6.21,
    "APR": 6.06,
    "MAY": 5.49,
    "JUN": 5.11,
    "JUL": 5.37,
    "AUG": 6.1,
    "SEP": 6.6,
    "OCT": 6.46,
    "NOV": 6.5,
    "DEC": 6.13
  },
  {
    "LONG": -38.07,
    "LAT": -7.43,
    "CIDADE": "Pedra Branca",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.94,
    "FEB": 6.05,
    "MAR": 6.28,
    "APR": 6.1,
    "MAY": 5.5,
    "JUN": 5.13,
    "JUL": 5.33,
    "AUG": 6.13,
    "SEP": 6.54,
    "OCT": 6.44,
    "NOV": 6.48,
    "DEC": 6.09
  },
  {
    "LONG": -37.99,
    "LAT": -7.39,
    "CIDADE": "Santana dos Garrotes",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.82,
    "FEB": 6,
    "MAR": 6.16,
    "APR": 6.03,
    "MAY": 5.45,
    "JUN": 5.08,
    "JUL": 5.37,
    "AUG": 6.1,
    "SEP": 6.54,
    "OCT": 6.42,
    "NOV": 6.44,
    "DEC": 6.04
  },
  {
    "LONG": -37.51,
    "LAT": -7.39,
    "CIDADE": "Imaculada",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.81,
    "FEB": 5.83,
    "MAR": 6.03,
    "APR": 5.93,
    "MAY": 5.37,
    "JUN": 5.04,
    "JUL": 5.36,
    "AUG": 6.05,
    "SEP": 6.56,
    "OCT": 6.43,
    "NOV": 6.38,
    "DEC": 5.95
  },
  {
    "LONG": -36.95,
    "LAT": -7.38,
    "CIDADE": "Livramento",
    "UF": "PARAÍBA",
    "anual": 5.72,
    "JAN": 5.77,
    "FEB": 5.86,
    "MAR": 6.04,
    "APR": 5.79,
    "MAY": 5.17,
    "JUN": 4.76,
    "JUL": 4.97,
    "AUG": 5.63,
    "SEP": 6.23,
    "OCT": 6.27,
    "NOV": 6.24,
    "DEC": 5.89
  },
  {
    "LONG": -36.81,
    "LAT": -7.39,
    "CIDADE": "São José dos Cordeiros",
    "UF": "PARAÍBA",
    "anual": 5.69,
    "JAN": 5.74,
    "FEB": 5.89,
    "MAR": 6.01,
    "APR": 5.76,
    "MAY": 5.15,
    "JUN": 4.74,
    "JUL": 4.94,
    "AUG": 5.6,
    "SEP": 6.16,
    "OCT": 6.21,
    "NOV": 6.2,
    "DEC": 5.86
  },
  {
    "LONG": -36.53,
    "LAT": -7.39,
    "CIDADE": "São João do Cariri",
    "UF": "PARAÍBA",
    "anual": 5.61,
    "JAN": 5.7,
    "FEB": 5.77,
    "MAR": 6.02,
    "APR": 5.64,
    "MAY": 5.12,
    "JUN": 4.59,
    "JUL": 4.84,
    "AUG": 5.45,
    "SEP": 6.03,
    "OCT": 6.11,
    "NOV": 6.16,
    "DEC": 5.86
  },
  {
    "LONG": -36.02,
    "LAT": "#VALOR!",
    "CIDADE": "Caturité",
    "UF": "PARAÍBA",
    "anual": 5.42,
    "JAN": 5.59,
    "FEB": 5.66,
    "MAR": 5.85,
    "APR": 5.49,
    "MAY": 4.93,
    "JUN": 4.42,
    "JUL": 4.62,
    "AUG": 5.22,
    "SEP": 5.72,
    "OCT": 5.86,
    "NOV": 5.97,
    "DEC": 5.69
  },
  {
    "LONG": -35.9,
    "LAT": -0.74,
    "CIDADE": "Queimadas",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.56,
    "FEB": 5.67,
    "MAR": 5.84,
    "APR": 5.48,
    "MAY": 4.9,
    "JUN": 4.34,
    "JUL": 4.55,
    "AUG": 5.18,
    "SEP": 5.64,
    "OCT": 5.8,
    "NOV": 5.95,
    "DEC": 5.67
  },
  {
    "LONG": -35.79,
    "LAT": -7.36,
    "CIDADE": "Fagundes",
    "UF": "PARAÍBA",
    "anual": 5.27,
    "JAN": 5.45,
    "FEB": 5.6,
    "MAR": 5.8,
    "APR": 5.4,
    "MAY": 4.78,
    "JUN": 4.31,
    "JUL": 4.41,
    "AUG": 5.07,
    "SEP": 5.46,
    "OCT": 5.62,
    "NOV": 5.75,
    "DEC": 5.6
  },
  {
    "LONG": -35.63,
    "LAT": -7.38,
    "CIDADE": "Itatuba",
    "UF": "PARAÍBA",
    "anual": 5.28,
    "JAN": 5.36,
    "FEB": 5.6,
    "MAR": 5.81,
    "APR": 5.39,
    "MAY": 4.81,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 5.09,
    "SEP": 5.47,
    "OCT": 5.62,
    "NOV": 5.81,
    "DEC": 5.56
  },
  {
    "LONG": -35.44,
    "LAT": -7.36,
    "CIDADE": "Salgado de São Félix",
    "UF": "PARAÍBA",
    "anual": 5.36,
    "JAN": 5.43,
    "FEB": 5.72,
    "MAR": 5.89,
    "APR": 5.43,
    "MAY": 4.84,
    "JUN": 4.42,
    "JUL": 4.52,
    "AUG": 5.3,
    "SEP": 5.63,
    "OCT": 5.7,
    "NOV": 5.87,
    "DEC": 5.63
  },
  {
    "LONG": -35.24,
    "LAT": -7.36,
    "CIDADE": "Juripiranga",
    "UF": "PARAÍBA",
    "anual": 5.39,
    "JAN": 5.43,
    "FEB": 5.72,
    "MAR": 5.87,
    "APR": 5.48,
    "MAY": 4.88,
    "JUN": 4.51,
    "JUL": 4.63,
    "AUG": 5.29,
    "SEP": 5.64,
    "OCT": 5.7,
    "NOV": 5.85,
    "DEC": 5.66
  },
  {
    "LONG": -35.11,
    "LAT": -7.4,
    "CIDADE": "Pedras de Fogo",
    "UF": "PARAÍBA",
    "anual": 5.4,
    "JAN": 5.47,
    "FEB": 5.78,
    "MAR": 5.92,
    "APR": 5.49,
    "MAY": 4.87,
    "JUN": 4.51,
    "JUL": 4.61,
    "AUG": 5.25,
    "SEP": 5.63,
    "OCT": 5.73,
    "NOV": 5.88,
    "DEC": 5.71
  },
  {
    "LONG": -3.49,
    "LAT": -7.43,
    "CIDADE": "Alhandra",
    "UF": "PARAÍBA",
    "anual": 5.42,
    "JAN": 5.48,
    "FEB": 5.82,
    "MAR": 6,
    "APR": 5.45,
    "MAY": 4.95,
    "JUN": 4.56,
    "JUL": 4.59,
    "AUG": 5.34,
    "SEP": 5.59,
    "OCT": 5.76,
    "NOV": 5.77,
    "DEC": 5.73
  },
  {
    "LONG": -38.51,
    "LAT": -7.31,
    "CIDADE": "Bonito de Santa Fé",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.71,
    "FEB": 5.87,
    "MAR": 6,
    "APR": 5.8,
    "MAY": 5.35,
    "JUN": 5.22,
    "JUL": 5.5,
    "AUG": 6.18,
    "SEP": 6.68,
    "OCT": 6.53,
    "NOV": 6.5,
    "DEC": 6.05
  },
  {
    "LONG": -38.3,
    "LAT": -7.25,
    "CIDADE": "São José de Caiana",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.85,
    "FEB": 5.91,
    "MAR": 6.09,
    "APR": 5.98,
    "MAY": 5.53,
    "JUN": 5.26,
    "JUL": 5.48,
    "AUG": 6.22,
    "SEP": 6.65,
    "OCT": 6.51,
    "NOV": 6.54,
    "DEC": 6.11
  },
  {
    "LONG": -38.15,
    "LAT": -7.3,
    "CIDADE": "Itaporanga",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.92,
    "FEB": 6.01,
    "MAR": 6.25,
    "APR": 6.04,
    "MAY": 5.48,
    "JUN": 5.16,
    "JUL": 5.39,
    "AUG": 6.15,
    "SEP": 6.6,
    "OCT": 6.47,
    "NOV": 6.49,
    "DEC": 6.1
  },
  {
    "LONG": -37.43,
    "LAT": -7.26,
    "CIDADE": "Mãe d'Água",
    "UF": "PARAÍBA",
    "anual": 5.91,
    "JAN": 5.75,
    "FEB": 5.88,
    "MAR": 6.05,
    "APR": 5.96,
    "MAY": 5.38,
    "JUN": 5.01,
    "JUL": 5.37,
    "AUG": 6.09,
    "SEP": 6.53,
    "OCT": 6.45,
    "NOV": 6.39,
    "DEC": 6.02
  },
  {
    "LONG": -37.35,
    "LAT": -7.26,
    "CIDADE": "Maturéia",
    "UF": "PARAÍBA",
    "anual": 5.88,
    "JAN": 5.79,
    "FEB": 5.85,
    "MAR": 6.04,
    "APR": 5.87,
    "MAY": 5.32,
    "JUN": 4.99,
    "JUL": 5.36,
    "AUG": 6.03,
    "SEP": 6.51,
    "OCT": 6.42,
    "NOV": 6.41,
    "DEC": 5.91
  },
  {
    "LONG": -37.09,
    "LAT": -7.29,
    "CIDADE": "Desterro",
    "UF": "PARAÍBA",
    "anual": 5.76,
    "JAN": 5.79,
    "FEB": 5.84,
    "MAR": 6.09,
    "APR": 5.85,
    "MAY": 5.23,
    "JUN": 4.79,
    "JUL": 5.04,
    "AUG": 5.69,
    "SEP": 6.32,
    "OCT": 6.31,
    "NOV": 6.27,
    "DEC": 5.91
  },
  {
    "LONG": -36.66,
    "LAT": -7.32,
    "CIDADE": "Parari",
    "UF": "PARAÍBA",
    "anual": 5.63,
    "JAN": 5.65,
    "FEB": 5.79,
    "MAR": 6.02,
    "APR": 5.71,
    "MAY": 5.17,
    "JUN": 4.66,
    "JUL": 4.86,
    "AUG": 5.56,
    "SEP": 6.08,
    "OCT": 6.12,
    "NOV": 6.16,
    "DEC": 5.82
  },
  {
    "LONG": -36.24,
    "LAT": -7.26,
    "CIDADE": "Boa Vista",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.55,
    "FEB": 5.63,
    "MAR": 5.88,
    "APR": 5.5,
    "MAY": 4.94,
    "JUN": 4.44,
    "JUL": 4.67,
    "AUG": 5.27,
    "SEP": 5.79,
    "OCT": 5.94,
    "NOV": 6.01,
    "DEC": 5.7
  },
  {
    "LONG": -35.67,
    "LAT": -7.25,
    "CIDADE": "Riachão do Bacamarte",
    "UF": "PARAÍBA",
    "anual": 5.27,
    "JAN": 5.35,
    "FEB": 5.53,
    "MAR": 5.75,
    "APR": 5.39,
    "MAY": 4.88,
    "JUN": 4.39,
    "JUL": 4.46,
    "AUG": 5.06,
    "SEP": 5.47,
    "OCT": 5.61,
    "NOV": 5.81,
    "DEC": 5.53
  },
  {
    "LONG": -35.61,
    "LAT": -7.29,
    "CIDADE": "Ingá",
    "UF": "PARAÍBA",
    "anual": 5.27,
    "JAN": 5.35,
    "FEB": 5.53,
    "MAR": 5.75,
    "APR": 5.39,
    "MAY": 4.88,
    "JUN": 4.39,
    "JUL": 4.46,
    "AUG": 5.06,
    "SEP": 5.47,
    "OCT": 5.61,
    "NOV": 5.81,
    "DEC": 5.53
  },
  {
    "LONG": -35.48,
    "LAT": -7.3,
    "CIDADE": "Mogeiro",
    "UF": "PARAÍBA",
    "anual": 5.28,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.8,
    "APR": 5.43,
    "MAY": 4.82,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 5.18,
    "SEP": 5.46,
    "OCT": 5.53,
    "NOV": 5.74,
    "DEC": 5.54
  },
  {
    "LONG": -35.34,
    "LAT": -7.34,
    "CIDADE": "Itabaiana",
    "UF": "PARAÍBA",
    "anual": 5.36,
    "JAN": 5.4,
    "FEB": 5.72,
    "MAR": 5.84,
    "APR": 5.5,
    "MAY": 4.92,
    "JUN": 4.49,
    "JUL": 4.6,
    "AUG": 5.3,
    "SEP": 5.56,
    "OCT": 5.65,
    "NOV": 5.75,
    "DEC": 5.63
  },
  {
    "LONG": -3.53,
    "LAT": -7.27,
    "CIDADE": "Pilar",
    "UF": "PARAÍBA",
    "anual": 5.39,
    "JAN": 5.44,
    "FEB": 5.72,
    "MAR": 5.84,
    "APR": 5.49,
    "MAY": 4.91,
    "JUN": 4.53,
    "JUL": 4.64,
    "AUG": 5.35,
    "SEP": 5.63,
    "OCT": 5.69,
    "NOV": 5.81,
    "DEC": 5.64
  },
  {
    "LONG": -34.9,
    "LAT": -7.26,
    "CIDADE": "Conde",
    "UF": "PARAÍBA",
    "anual": 5.45,
    "JAN": 5.54,
    "FEB": 5.83,
    "MAR": 6.01,
    "APR": 5.46,
    "MAY": 4.98,
    "JUN": 4.59,
    "JUL": 4.63,
    "AUG": 5.39,
    "SEP": 5.63,
    "OCT": 5.8,
    "NOV": 5.81,
    "DEC": 5.73
  },
  {
    "LONG": -3.86,
    "LAT": -7.22,
    "CIDADE": "Monte Horebe",
    "UF": "PARAÍBA",
    "anual": 5.96,
    "JAN": 5.73,
    "FEB": 5.85,
    "MAR": 5.97,
    "APR": 5.79,
    "MAY": 5.43,
    "JUN": 5.29,
    "JUL": 5.53,
    "AUG": 6.23,
    "SEP": 6.66,
    "OCT": 6.5,
    "NOV": 6.48,
    "DEC": 6.07
  },
  {
    "LONG": -38.37,
    "LAT": -7.21,
    "CIDADE": "Serra Grande",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.87,
    "FEB": 5.94,
    "MAR": 6.06,
    "APR": 5.95,
    "MAY": 5.51,
    "JUN": 5.3,
    "JUL": 5.51,
    "AUG": 6.27,
    "SEP": 6.64,
    "OCT": 6.5,
    "NOV": 6.52,
    "DEC": 6.09
  },
  {
    "LONG": -38.15,
    "LAT": -7.18,
    "CIDADE": "Igaracy",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.85,
    "FEB": 5.96,
    "MAR": 6.22,
    "APR": 6.04,
    "MAY": 5.51,
    "JUN": 5.18,
    "JUL": 5.45,
    "AUG": 6.18,
    "SEP": 6.6,
    "OCT": 6.49,
    "NOV": 6.49,
    "DEC": 6.11
  },
  {
    "LONG": -37.93,
    "LAT": -7.19,
    "CIDADE": "Piancó",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.91,
    "FEB": 6.04,
    "MAR": 6.32,
    "APR": 6.08,
    "MAY": 5.5,
    "JUN": 5.14,
    "JUL": 5.36,
    "AUG": 6.09,
    "SEP": 6.55,
    "OCT": 6.52,
    "NOV": 6.46,
    "DEC": 6.14
  },
  {
    "LONG": -37.75,
    "LAT": -7.23,
    "CIDADE": "Olho d'Água",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.96,
    "FEB": 6,
    "MAR": 6.27,
    "APR": 6.1,
    "MAY": 5.46,
    "JUN": 5.13,
    "JUL": 5.35,
    "AUG": 6.08,
    "SEP": 6.55,
    "OCT": 6.5,
    "NOV": 6.48,
    "DEC": 6.1
  },
  {
    "LONG": -37.31,
    "LAT": -7.16,
    "CIDADE": "São José do Bonfim",
    "UF": "PARAÍBA",
    "anual": 5.96,
    "JAN": 5.94,
    "FEB": 6.04,
    "MAR": 6.25,
    "APR": 6.01,
    "MAY": 5.41,
    "JUN": 4.97,
    "JUL": 5.3,
    "AUG": 6.11,
    "SEP": 6.53,
    "OCT": 6.48,
    "NOV": 6.45,
    "DEC": 6
  },
  {
    "LONG": -37.25,
    "LAT": -7.22,
    "CIDADE": "Teixeira",
    "UF": "PARAÍBA",
    "anual": 5.92,
    "JAN": 5.88,
    "FEB": 6,
    "MAR": 6.22,
    "APR": 5.99,
    "MAY": 5.36,
    "JUN": 4.96,
    "JUL": 5.29,
    "AUG": 6.08,
    "SEP": 6.5,
    "OCT": 6.43,
    "NOV": 6.39,
    "DEC": 5.96
  },
  {
    "LONG": -37.06,
    "LAT": -7.21,
    "CIDADE": "Cacimbas",
    "UF": "PARAÍBA",
    "anual": 5.82,
    "JAN": 5.78,
    "FEB": 5.86,
    "MAR": 6.04,
    "APR": 5.84,
    "MAY": 5.31,
    "JUN": 4.87,
    "JUL": 5.18,
    "AUG": 5.93,
    "SEP": 6.39,
    "OCT": 6.39,
    "NOV": 6.32,
    "DEC": 5.91
  },
  {
    "LONG": -36.82,
    "LAT": -7.21,
    "CIDADE": "Taperoá",
    "UF": "PARAÍBA",
    "anual": 5.71,
    "JAN": 5.7,
    "FEB": 5.82,
    "MAR": 5.94,
    "APR": 5.81,
    "MAY": 5.2,
    "JUN": 4.71,
    "JUL": 5.04,
    "AUG": 5.73,
    "SEP": 6.21,
    "OCT": 6.26,
    "NOV": 6.24,
    "DEC": 5.87
  },
  {
    "LONG": -36.63,
    "LAT": -7.22,
    "CIDADE": "Santo André",
    "UF": "PARAÍBA",
    "anual": 5.63,
    "JAN": 5.69,
    "FEB": 5.8,
    "MAR": 5.96,
    "APR": 5.7,
    "MAY": 5.15,
    "JUN": 4.62,
    "JUL": 4.91,
    "AUG": 5.54,
    "SEP": 6.03,
    "OCT": 6.16,
    "NOV": 6.15,
    "DEC": 5.82
  },
  {
    "LONG": -3.65,
    "LAT": -7.25,
    "CIDADE": "Gurjão",
    "UF": "PARAÍBA",
    "anual": 5.54,
    "JAN": 5.61,
    "FEB": 5.77,
    "MAR": 5.9,
    "APR": 5.62,
    "MAY": 5.07,
    "JUN": 4.56,
    "JUL": 4.74,
    "AUG": 5.41,
    "SEP": 5.93,
    "OCT": 6.05,
    "NOV": 6.09,
    "DEC": 5.78
  },
  {
    "LONG": -35.85,
    "LAT": -7.16,
    "CIDADE": "Lagoa Seca",
    "UF": "PARAÍBA",
    "anual": 5.25,
    "JAN": 5.31,
    "FEB": 5.53,
    "MAR": 5.76,
    "APR": 5.37,
    "MAY": 4.85,
    "JUN": 4.3,
    "JUL": 4.38,
    "AUG": 5.07,
    "SEP": 5.53,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.49
  },
  {
    "LONG": -35.87,
    "LAT": -7.22,
    "CIDADE": "Campina Grande",
    "UF": "PARAÍBA",
    "anual": 5.25,
    "JAN": 5.31,
    "FEB": 5.53,
    "MAR": 5.76,
    "APR": 5.37,
    "MAY": 4.85,
    "JUN": 4.3,
    "JUL": 4.38,
    "AUG": 5.07,
    "SEP": 5.53,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.49
  },
  {
    "LONG": -35.79,
    "LAT": -7.19,
    "CIDADE": "Massaranduba",
    "UF": "PARAÍBA",
    "anual": 5.22,
    "JAN": 5.19,
    "FEB": 5.46,
    "MAR": 5.71,
    "APR": 5.35,
    "MAY": 4.87,
    "JUN": 4.32,
    "JUL": 4.41,
    "AUG": 5.08,
    "SEP": 5.48,
    "OCT": 5.57,
    "NOV": 5.7,
    "DEC": 5.48
  },
  {
    "LONG": -35.68,
    "LAT": -7.19,
    "CIDADE": "Serra Redonda",
    "UF": "PARAÍBA",
    "anual": 5.19,
    "JAN": 5.27,
    "FEB": 5.49,
    "MAR": 5.63,
    "APR": 5.28,
    "MAY": 4.81,
    "JUN": 4.35,
    "JUL": 4.47,
    "AUG": 5.05,
    "SEP": 5.44,
    "OCT": 5.47,
    "NOV": 5.6,
    "DEC": 5.42
  },
  {
    "LONG": -35.57,
    "LAT": -7.17,
    "CIDADE": "Juarez Távora",
    "UF": "PARAÍBA",
    "anual": 5.26,
    "JAN": 5.38,
    "FEB": 5.57,
    "MAR": 5.71,
    "APR": 5.33,
    "MAY": 4.84,
    "JUN": 4.33,
    "JUL": 4.51,
    "AUG": 5.16,
    "SEP": 5.51,
    "OCT": 5.53,
    "NOV": 5.67,
    "DEC": 5.54
  },
  {
    "LONG": -35.38,
    "LAT": -7.25,
    "CIDADE": "São José dos Ramos",
    "UF": "PARAÍBA",
    "anual": 5.33,
    "JAN": 5.39,
    "FEB": 5.65,
    "MAR": 5.85,
    "APR": 5.48,
    "MAY": 4.87,
    "JUN": 4.47,
    "JUL": 4.59,
    "AUG": 5.27,
    "SEP": 5.55,
    "OCT": 5.6,
    "NOV": 5.72,
    "DEC": 5.53
  },
  {
    "LONG": -35.28,
    "LAT": -7.18,
    "CIDADE": "Riachão do Poço",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.39,
    "FEB": 5.63,
    "MAR": 5.89,
    "APR": 5.45,
    "MAY": 4.87,
    "JUN": 4.5,
    "JUL": 4.58,
    "AUG": 5.31,
    "SEP": 5.58,
    "OCT": 5.65,
    "NOV": 5.74,
    "DEC": 5.58
  },
  {
    "LONG": -35.2,
    "LAT": -7.25,
    "CIDADE": "São Miguel de Taipu",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.39,
    "FEB": 5.63,
    "MAR": 5.89,
    "APR": 5.45,
    "MAY": 4.87,
    "JUN": 4.5,
    "JUL": 4.58,
    "AUG": 5.31,
    "SEP": 5.58,
    "OCT": 5.65,
    "NOV": 5.74,
    "DEC": 5.58
  },
  {
    "LONG": -34.97,
    "LAT": -7.15,
    "CIDADE": "Santa Rita",
    "UF": "PARAÍBA",
    "anual": 5.47,
    "JAN": 5.57,
    "FEB": 5.8,
    "MAR": 5.99,
    "APR": 5.48,
    "MAY": 5.02,
    "JUN": 4.6,
    "JUL": 4.69,
    "AUG": 5.41,
    "SEP": 5.65,
    "OCT": 5.78,
    "NOV": 5.87,
    "DEC": 5.71
  },
  {
    "LONG": -38.5,
    "LAT": -7.12,
    "CIDADE": "São José de Piranhas",
    "UF": "PARAÍBA",
    "anual": 6.03,
    "JAN": 5.9,
    "FEB": 5.99,
    "MAR": 6.11,
    "APR": 5.99,
    "MAY": 5.53,
    "JUN": 5.29,
    "JUL": 5.54,
    "AUG": 6.25,
    "SEP": 6.65,
    "OCT": 6.53,
    "NOV": 6.48,
    "DEC": 6.13
  },
  {
    "LONG": -38.17,
    "LAT": -7.09,
    "CIDADE": "Aguiar",
    "UF": "PARAÍBA",
    "anual": 6.05,
    "JAN": 6.03,
    "FEB": 6,
    "MAR": 6.23,
    "APR": 6.06,
    "MAY": 5.55,
    "JUN": 5.22,
    "JUL": 5.5,
    "AUG": 6.16,
    "SEP": 6.61,
    "OCT": 6.53,
    "NOV": 6.55,
    "DEC": 6.15
  },
  {
    "LONG": -37.72,
    "LAT": -7.1,
    "CIDADE": "Emas",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.95,
    "FEB": 5.96,
    "MAR": 6.28,
    "APR": 6.01,
    "MAY": 5.46,
    "JUN": 5.08,
    "JUL": 5.31,
    "AUG": 6.07,
    "SEP": 6.53,
    "OCT": 6.52,
    "NOV": 6.5,
    "DEC": 6.07
  },
  {
    "LONG": -37.61,
    "LAT": -7.13,
    "CIDADE": "Catingueira",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.93,
    "FEB": 6.02,
    "MAR": 6.33,
    "APR": 6.05,
    "MAY": 5.46,
    "JUN": 5.1,
    "JUL": 5.33,
    "AUG": 6.03,
    "SEP": 6.54,
    "OCT": 6.5,
    "NOV": 6.45,
    "DEC": 6.14
  },
  {
    "LONG": -3.74,
    "LAT": -7.08,
    "CIDADE": "Santa Teresinha",
    "UF": "PARAÍBA",
    "anual": 6.04,
    "JAN": 5.96,
    "FEB": 6.17,
    "MAR": 6.42,
    "APR": 6.13,
    "MAY": 5.51,
    "JUN": 5.09,
    "JUL": 5.3,
    "AUG": 6.09,
    "SEP": 6.55,
    "OCT": 6.54,
    "NOV": 6.51,
    "DEC": 6.18
  },
  {
    "LONG": -37.16,
    "LAT": -7.13,
    "CIDADE": "Cacimba de Areia",
    "UF": "PARAÍBA",
    "anual": 5.96,
    "JAN": 5.97,
    "FEB": 6.1,
    "MAR": 6.25,
    "APR": 6.06,
    "MAY": 5.44,
    "JUN": 4.98,
    "JUL": 5.31,
    "AUG": 6.03,
    "SEP": 6.5,
    "OCT": 6.46,
    "NOV": 6.38,
    "DEC": 5.99
  },
  {
    "LONG": -37.05,
    "LAT": -7.14,
    "CIDADE": "Passagem",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.83,
    "FEB": 6.02,
    "MAR": 6.16,
    "APR": 5.97,
    "MAY": 5.41,
    "JUN": 4.91,
    "JUL": 5.28,
    "AUG": 6.01,
    "SEP": 6.46,
    "OCT": 6.37,
    "NOV": 6.32,
    "DEC": 5.94
  },
  {
    "LONG": -3.69,
    "LAT": -7.12,
    "CIDADE": "Areia de Baraúnas",
    "UF": "PARAÍBA",
    "anual": 5.85,
    "JAN": 5.8,
    "FEB": 5.97,
    "MAR": 6.1,
    "APR": 5.92,
    "MAY": 5.37,
    "JUN": 4.87,
    "JUL": 5.21,
    "AUG": 5.94,
    "SEP": 6.4,
    "OCT": 6.36,
    "NOV": 6.31,
    "DEC": 5.92
  },
  {
    "LONG": -3.68,
    "LAT": -7.1,
    "CIDADE": "Salgadinho",
    "UF": "PARAÍBA",
    "anual": 5.75,
    "JAN": 5.77,
    "FEB": 5.88,
    "MAR": 5.98,
    "APR": 5.81,
    "MAY": 5.24,
    "JUN": 4.77,
    "JUL": 5.05,
    "AUG": 5.78,
    "SEP": 6.26,
    "OCT": 6.3,
    "NOV": 6.28,
    "DEC": 5.89
  },
  {
    "LONG": -36.73,
    "LAT": -0.71,
    "CIDADE": "Assunção",
    "UF": "PARAÍBA",
    "anual": 5.65,
    "JAN": 5.7,
    "FEB": 5.81,
    "MAR": 5.92,
    "APR": 5.67,
    "MAY": 5.15,
    "JUN": 4.67,
    "JUL": 4.91,
    "AUG": 5.64,
    "SEP": 6.13,
    "OCT": 6.19,
    "NOV": 6.17,
    "DEC": 5.79
  },
  {
    "LONG": -36.58,
    "LAT": -7.07,
    "CIDADE": "Juazeirinho",
    "UF": "PARAÍBA",
    "anual": 5.56,
    "JAN": 5.55,
    "FEB": 5.7,
    "MAR": 5.9,
    "APR": 5.65,
    "MAY": 5.06,
    "JUN": 4.61,
    "JUL": 4.84,
    "AUG": 5.51,
    "SEP": 6.01,
    "OCT": 6.07,
    "NOV": 6.12,
    "DEC": 5.76
  },
  {
    "LONG": -36.36,
    "LAT": -7.06,
    "CIDADE": "Soledade",
    "UF": "PARAÍBA",
    "anual": 5.48,
    "JAN": 5.52,
    "FEB": 5.68,
    "MAR": 5.86,
    "APR": 5.53,
    "MAY": 4.99,
    "JUN": 4.5,
    "JUL": 4.67,
    "AUG": 5.33,
    "SEP": 5.87,
    "OCT": 5.99,
    "NOV": 6.06,
    "DEC": 5.74
  },
  {
    "LONG": -36.06,
    "LAT": -7.07,
    "CIDADE": "Pocinhos",
    "UF": "PARAÍBA",
    "anual": 5.42,
    "JAN": 5.51,
    "FEB": 5.67,
    "MAR": 5.87,
    "APR": 5.45,
    "MAY": 4.94,
    "JUN": 4.42,
    "JUL": 4.64,
    "AUG": 5.25,
    "SEP": 5.74,
    "OCT": 5.9,
    "NOV": 5.93,
    "DEC": 5.69
  },
  {
    "LONG": -35.96,
    "LAT": -7.09,
    "CIDADE": "Montadas",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.56,
    "FEB": 5.78,
    "MAR": 5.87,
    "APR": 5.52,
    "MAY": 5.01,
    "JUN": 4.47,
    "JUL": 4.61,
    "AUG": 5.32,
    "SEP": 5.72,
    "OCT": 5.84,
    "NOV": 5.9,
    "DEC": 5.67
  },
  {
    "LONG": -35.97,
    "LAT": -7.15,
    "CIDADE": "Puxinanã",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.56,
    "FEB": 5.78,
    "MAR": 5.87,
    "APR": 5.52,
    "MAY": 5.01,
    "JUN": 4.47,
    "JUL": 4.61,
    "AUG": 5.32,
    "SEP": 5.72,
    "OCT": 5.84,
    "NOV": 5.9,
    "DEC": 5.67
  },
  {
    "LONG": -35.87,
    "LAT": -7.11,
    "CIDADE": "São Sebastião de Lagoa de Roça",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.45,
    "FEB": 5.67,
    "MAR": 5.79,
    "APR": 5.47,
    "MAY": 4.91,
    "JUN": 4.39,
    "JUL": 4.49,
    "AUG": 5.22,
    "SEP": 5.6,
    "OCT": 5.76,
    "NOV": 5.84,
    "DEC": 5.62
  },
  {
    "LONG": -35.76,
    "LAT": -7.06,
    "CIDADE": "Alagoa Nova",
    "UF": "PARAÍBA",
    "anual": 5.26,
    "JAN": 5.36,
    "FEB": 5.59,
    "MAR": 5.71,
    "APR": 5.42,
    "MAY": 4.84,
    "JUN": 4.33,
    "JUL": 4.38,
    "AUG": 5.12,
    "SEP": 5.47,
    "OCT": 5.63,
    "NOV": 5.78,
    "DEC": 5.53
  },
  {
    "LONG": -35.77,
    "LAT": -7.12,
    "CIDADE": "Matinhas",
    "UF": "PARAÍBA",
    "anual": 5.26,
    "JAN": 5.36,
    "FEB": 5.59,
    "MAR": 5.71,
    "APR": 5.42,
    "MAY": 4.84,
    "JUN": 4.33,
    "JUL": 4.38,
    "AUG": 5.12,
    "SEP": 5.47,
    "OCT": 5.63,
    "NOV": 5.78,
    "DEC": 5.53
  },
  {
    "LONG": -35.43,
    "LAT": -0.71,
    "CIDADE": "Gurinhém",
    "UF": "PARAÍBA",
    "anual": 5.32,
    "JAN": 5.33,
    "FEB": 5.62,
    "MAR": 5.8,
    "APR": 5.41,
    "MAY": 4.9,
    "JUN": 4.44,
    "JUL": 4.61,
    "AUG": 5.27,
    "SEP": 5.54,
    "OCT": 5.63,
    "NOV": 5.77,
    "DEC": 5.55
  },
  {
    "LONG": -35.32,
    "LAT": -7.06,
    "CIDADE": "Mari",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.44,
    "FEB": 5.6,
    "MAR": 5.88,
    "APR": 5.46,
    "MAY": 4.89,
    "JUN": 4.53,
    "JUL": 4.6,
    "AUG": 5.34,
    "SEP": 5.59,
    "OCT": 5.72,
    "NOV": 5.85,
    "DEC": 5.64
  },
  {
    "LONG": -3.53,
    "LAT": -7.1,
    "CIDADE": "Caldas Brandão",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.44,
    "FEB": 5.6,
    "MAR": 5.88,
    "APR": 5.46,
    "MAY": 4.89,
    "JUN": 4.53,
    "JUL": 4.6,
    "AUG": 5.34,
    "SEP": 5.59,
    "OCT": 5.72,
    "NOV": 5.85,
    "DEC": 5.64
  },
  {
    "LONG": -35.23,
    "LAT": -7.09,
    "CIDADE": "Sapé",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.44,
    "FEB": 5.7,
    "MAR": 5.92,
    "APR": 5.45,
    "MAY": 4.91,
    "JUN": 4.49,
    "JUL": 4.56,
    "AUG": 5.29,
    "SEP": 5.59,
    "OCT": 5.72,
    "NOV": 5.85,
    "DEC": 5.65
  },
  {
    "LONG": -3.52,
    "LAT": -7.14,
    "CIDADE": "Sobrado",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.44,
    "FEB": 5.7,
    "MAR": 5.92,
    "APR": 5.45,
    "MAY": 4.91,
    "JUN": 4.49,
    "JUL": 4.56,
    "AUG": 5.29,
    "SEP": 5.59,
    "OCT": 5.72,
    "NOV": 5.85,
    "DEC": 5.65
  },
  {
    "LONG": -3.51,
    "LAT": -7.14,
    "CIDADE": "Cruz do Espírito Santo",
    "UF": "PARAÍBA",
    "anual": 5.39,
    "JAN": 5.49,
    "FEB": 5.75,
    "MAR": 5.96,
    "APR": 5.45,
    "MAY": 4.92,
    "JUN": 4.46,
    "JUL": 4.54,
    "AUG": 5.33,
    "SEP": 5.6,
    "OCT": 5.7,
    "NOV": 5.84,
    "DEC": 5.69
  },
  {
    "LONG": -34.93,
    "LAT": -7.13,
    "CIDADE": "Bayeux",
    "UF": "PARAÍBA",
    "anual": 5.47,
    "JAN": 5.56,
    "FEB": 5.82,
    "MAR": 6.03,
    "APR": 5.5,
    "MAY": 5,
    "JUN": 4.55,
    "JUL": 4.65,
    "AUG": 5.39,
    "SEP": 5.67,
    "OCT": 5.8,
    "NOV": 5.95,
    "DEC": 5.78
  },
  {
    "LONG": -34.86,
    "LAT": -7.12,
    "CIDADE": "João Pessoa",
    "UF": "PARAÍBA",
    "anual": 5.53,
    "JAN": 5.59,
    "FEB": 5.85,
    "MAR": 6,
    "APR": 5.51,
    "MAY": 5.07,
    "JUN": 4.63,
    "JUL": 4.72,
    "AUG": 5.45,
    "SEP": 5.8,
    "OCT": 5.93,
    "NOV": 5.99,
    "DEC": 5.83
  },
  {
    "LONG": -3.83,
    "LAT": -7.04,
    "CIDADE": "Carrapateira",
    "UF": "PARAÍBA",
    "anual": 6.05,
    "JAN": 5.86,
    "FEB": 6.01,
    "MAR": 6.1,
    "APR": 6,
    "MAY": 5.59,
    "JUN": 5.35,
    "JUL": 5.65,
    "AUG": 6.29,
    "SEP": 6.63,
    "OCT": 6.52,
    "NOV": 6.54,
    "DEC": 6.05
  },
  {
    "LONG": -37.93,
    "LAT": -7.01,
    "CIDADE": "Coremas",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.95,
    "FEB": 6.06,
    "MAR": 6.22,
    "APR": 6.08,
    "MAY": 5.6,
    "JUN": 5.22,
    "JUL": 5.48,
    "AUG": 6.22,
    "SEP": 6.49,
    "OCT": 6.44,
    "NOV": 6.45,
    "DEC": 6.04
  },
  {
    "LONG": -37.8,
    "LAT": -6.96,
    "CIDADE": "Cajazeirinhas",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.91,
    "FEB": 6.02,
    "MAR": 6.18,
    "APR": 6.06,
    "MAY": 5.51,
    "JUN": 5.17,
    "JUL": 5.4,
    "AUG": 6.13,
    "SEP": 6.52,
    "OCT": 6.47,
    "NOV": 6.48,
    "DEC": 6
  },
  {
    "LONG": -3.73,
    "LAT": -7.02,
    "CIDADE": "Patos",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.95,
    "FEB": 6.03,
    "MAR": 6.28,
    "APR": 6.06,
    "MAY": 5.42,
    "JUN": 5.03,
    "JUL": 5.34,
    "AUG": 6.06,
    "SEP": 6.44,
    "OCT": 6.42,
    "NOV": 6.31,
    "DEC": 6.02
  },
  {
    "LONG": -37.15,
    "LAT": -7.03,
    "CIDADE": "Quixaba",
    "UF": "PARAÍBA",
    "anual": 5.92,
    "JAN": 5.94,
    "FEB": 6.04,
    "MAR": 6.23,
    "APR": 6.02,
    "MAY": 5.41,
    "JUN": 4.99,
    "JUL": 5.31,
    "AUG": 5.99,
    "SEP": 6.44,
    "OCT": 6.41,
    "NOV": 6.3,
    "DEC": 5.96
  },
  {
    "LONG": -36.72,
    "LAT": -6.99,
    "CIDADE": "Junco do Seridó",
    "UF": "PARAÍBA",
    "anual": 5.74,
    "JAN": 5.75,
    "FEB": 5.82,
    "MAR": 5.95,
    "APR": 5.72,
    "MAY": 5.24,
    "JUN": 4.83,
    "JUL": 5.11,
    "AUG": 5.83,
    "SEP": 6.27,
    "OCT": 6.29,
    "NOV": 6.24,
    "DEC": 5.84
  },
  {
    "LONG": -3.62,
    "LAT": -6.99,
    "CIDADE": "Olivedos",
    "UF": "PARAÍBA",
    "anual": 5.45,
    "JAN": 5.55,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.45,
    "MAY": 5,
    "JUN": 4.43,
    "JUL": 4.69,
    "AUG": 5.34,
    "SEP": 5.82,
    "OCT": 5.98,
    "NOV": 6.03,
    "DEC": 5.68
  },
  {
    "LONG": -35.93,
    "LAT": -7.05,
    "CIDADE": "Areial",
    "UF": "PARAÍBA",
    "anual": 5.46,
    "JAN": 5.58,
    "FEB": 5.7,
    "MAR": 5.9,
    "APR": 5.53,
    "MAY": 5.02,
    "JUN": 4.5,
    "JUL": 4.68,
    "AUG": 5.34,
    "SEP": 5.72,
    "OCT": 5.86,
    "NOV": 5.96,
    "DEC": 5.67
  },
  {
    "LONG": -35.86,
    "LAT": -7.02,
    "CIDADE": "Esperança",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.53,
    "FEB": 5.74,
    "MAR": 5.87,
    "APR": 5.54,
    "MAY": 5,
    "JUN": 4.48,
    "JUL": 4.64,
    "AUG": 5.32,
    "SEP": 5.7,
    "OCT": 5.83,
    "NOV": 5.96,
    "DEC": 5.7
  },
  {
    "LONG": -35.8,
    "LAT": -6.96,
    "CIDADE": "Remígio",
    "UF": "PARAÍBA",
    "anual": 5.37,
    "JAN": 5.47,
    "FEB": 5.71,
    "MAR": 5.79,
    "APR": 5.48,
    "MAY": 4.92,
    "JUN": 4.39,
    "JUL": 4.52,
    "AUG": 5.2,
    "SEP": 5.63,
    "OCT": 5.76,
    "NOV": 5.92,
    "DEC": 5.67
  },
  {
    "LONG": -35.7,
    "LAT": -6.97,
    "CIDADE": "Areia",
    "UF": "PARAÍBA",
    "anual": 5.37,
    "JAN": 5.47,
    "FEB": 5.71,
    "MAR": 5.79,
    "APR": 5.48,
    "MAY": 4.92,
    "JUN": 4.39,
    "JUL": 4.52,
    "AUG": 5.2,
    "SEP": 5.63,
    "OCT": 5.76,
    "NOV": 5.92,
    "DEC": 5.67
  },
  {
    "LONG": -35.63,
    "LAT": -7.04,
    "CIDADE": "Alagoa Grande",
    "UF": "PARAÍBA",
    "anual": 5.21,
    "JAN": 5.34,
    "FEB": 5.53,
    "MAR": 5.66,
    "APR": 5.3,
    "MAY": 4.8,
    "JUN": 4.27,
    "JUL": 4.35,
    "AUG": 5.03,
    "SEP": 5.42,
    "OCT": 5.59,
    "NOV": 5.72,
    "DEC": 5.48
  },
  {
    "LONG": -35.46,
    "LAT": -7.03,
    "CIDADE": "Mulungu",
    "UF": "PARAÍBA",
    "anual": 5.3,
    "JAN": 5.34,
    "FEB": 5.58,
    "MAR": 5.74,
    "APR": 5.37,
    "MAY": 4.84,
    "JUN": 4.46,
    "JUL": 4.55,
    "AUG": 5.26,
    "SEP": 5.56,
    "OCT": 5.63,
    "NOV": 5.74,
    "DEC": 5.5
  },
  {
    "LONG": -34.83,
    "LAT": -6.99,
    "CIDADE": "Cabedelo",
    "UF": "PARAÍBA",
    "anual": 5.62,
    "JAN": 5.66,
    "FEB": 5.91,
    "MAR": 6.09,
    "APR": 5.63,
    "MAY": 5.17,
    "JUN": 4.72,
    "JUL": 4.82,
    "AUG": 5.59,
    "SEP": 5.93,
    "OCT": 6.05,
    "NOV": 6.05,
    "DEC": 5.85
  },
  {
    "LONG": -38.68,
    "LAT": -6.92,
    "CIDADE": "Cachoeira dos Índios",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.84,
    "FEB": 5.95,
    "MAR": 6.05,
    "APR": 5.93,
    "MAY": 5.58,
    "JUN": 5.38,
    "JUL": 5.63,
    "AUG": 6.29,
    "SEP": 6.55,
    "OCT": 6.45,
    "NOV": 6.48,
    "DEC": 6.05
  },
  {
    "LONG": -3.86,
    "LAT": -6.88,
    "CIDADE": "Cajazeiras",
    "UF": "PARAÍBA",
    "anual": 6.03,
    "JAN": 5.89,
    "FEB": 5.98,
    "MAR": 6.08,
    "APR": 5.98,
    "MAY": 5.57,
    "JUN": 5.37,
    "JUL": 5.6,
    "AUG": 6.29,
    "SEP": 6.56,
    "OCT": 6.49,
    "NOV": 6.49,
    "DEC": 6.08
  },
  {
    "LONG": -38.32,
    "LAT": -6.91,
    "CIDADE": "Nazarezinho",
    "UF": "PARAÍBA",
    "anual": 6.07,
    "JAN": 5.91,
    "FEB": 6.03,
    "MAR": 6.2,
    "APR": 6.02,
    "MAY": 5.59,
    "JUN": 5.38,
    "JUL": 5.63,
    "AUG": 6.27,
    "SEP": 6.59,
    "OCT": 6.55,
    "NOV": 6.53,
    "DEC": 6.14
  },
  {
    "LONG": -38.16,
    "LAT": -6.94,
    "CIDADE": "São José da Lagoa Tapada",
    "UF": "PARAÍBA",
    "anual": 6.05,
    "JAN": 5.84,
    "FEB": 5.98,
    "MAR": 6.19,
    "APR": 6.05,
    "MAY": 5.62,
    "JUN": 5.37,
    "JUL": 5.58,
    "AUG": 6.29,
    "SEP": 6.6,
    "OCT": 6.54,
    "NOV": 6.51,
    "DEC": 6.07
  },
  {
    "LONG": -37.73,
    "LAT": -6.89,
    "CIDADE": "São Bentinho",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.91,
    "FEB": 6.05,
    "MAR": 6.28,
    "APR": 6.04,
    "MAY": 5.54,
    "JUN": 5.18,
    "JUL": 5.35,
    "AUG": 6.12,
    "SEP": 6.56,
    "OCT": 6.55,
    "NOV": 6.5,
    "DEC": 6.06
  },
  {
    "LONG": -37.61,
    "LAT": -6.9,
    "CIDADE": "Condado",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.92,
    "FEB": 6.11,
    "MAR": 6.33,
    "APR": 6.08,
    "MAY": 5.54,
    "JUN": 5.18,
    "JUL": 5.34,
    "AUG": 6.11,
    "SEP": 6.56,
    "OCT": 6.53,
    "NOV": 6.45,
    "DEC": 6.12
  },
  {
    "LONG": -37.52,
    "LAT": -6.9,
    "CIDADE": "Malta",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.94,
    "FEB": 6.1,
    "MAR": 6.33,
    "APR": 6.09,
    "MAY": 5.54,
    "JUN": 5.14,
    "JUL": 5.38,
    "AUG": 6.14,
    "SEP": 6.56,
    "OCT": 6.51,
    "NOV": 6.39,
    "DEC": 6.11
  },
  {
    "LONG": -37.1,
    "LAT": -6.92,
    "CIDADE": "São Mamede",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.85,
    "FEB": 6,
    "MAR": 6.18,
    "APR": 5.96,
    "MAY": 5.43,
    "JUN": 4.93,
    "JUL": 5.29,
    "AUG": 6.01,
    "SEP": 6.41,
    "OCT": 6.43,
    "NOV": 6.38,
    "DEC": 5.93
  },
  {
    "LONG": -36.92,
    "LAT": -6.87,
    "CIDADE": "Santa Luzia",
    "UF": "PARAÍBA",
    "anual": 5.87,
    "JAN": 5.81,
    "FEB": 5.98,
    "MAR": 6.14,
    "APR": 5.91,
    "MAY": 5.36,
    "JUN": 4.92,
    "JUL": 5.26,
    "AUG": 5.98,
    "SEP": 6.36,
    "OCT": 6.41,
    "NOV": 6.34,
    "DEC": 5.91
  },
  {
    "LONG": -36.63,
    "LAT": -0.69,
    "CIDADE": "Tenório",
    "UF": "PARAÍBA",
    "anual": 5.69,
    "JAN": 5.68,
    "FEB": 5.77,
    "MAR": 5.95,
    "APR": 5.68,
    "MAY": 5.17,
    "JUN": 4.8,
    "JUL": 5.08,
    "AUG": 5.76,
    "SEP": 6.17,
    "OCT": 6.27,
    "NOV": 6.17,
    "DEC": 5.82
  },
  {
    "LONG": -36.4,
    "LAT": -6.93,
    "CIDADE": "São Vicente do Seridó",
    "UF": "PARAÍBA",
    "anual": 5.66,
    "JAN": 5.64,
    "FEB": 5.78,
    "MAR": 5.97,
    "APR": 5.67,
    "MAY": 5.17,
    "JUN": 4.77,
    "JUL": 5.03,
    "AUG": 5.67,
    "SEP": 6.09,
    "OCT": 6.21,
    "NOV": 6.19,
    "DEC": 5.76
  },
  {
    "LONG": -36.35,
    "LAT": -6.86,
    "CIDADE": "Cubati",
    "UF": "PARAÍBA",
    "anual": 5.6,
    "JAN": 5.6,
    "FEB": 5.74,
    "MAR": 5.93,
    "APR": 5.59,
    "MAY": 5.13,
    "JUN": 4.69,
    "JUL": 4.94,
    "AUG": 5.58,
    "SEP": 5.98,
    "OCT": 6.13,
    "NOV": 6.15,
    "DEC": 5.73
  },
  {
    "LONG": -36.01,
    "LAT": -6.9,
    "CIDADE": "Algodão de Jandaíra",
    "UF": "PARAÍBA",
    "anual": 5.4,
    "JAN": 5.46,
    "FEB": 5.61,
    "MAR": 5.77,
    "APR": 5.46,
    "MAY": 4.98,
    "JUN": 4.47,
    "JUL": 4.61,
    "AUG": 5.32,
    "SEP": 5.69,
    "OCT": 5.89,
    "NOV": 5.95,
    "DEC": 5.64
  },
  {
    "LONG": -35.61,
    "LAT": -6.87,
    "CIDADE": "Pilões",
    "UF": "PARAÍBA",
    "anual": 5.29,
    "JAN": 5.39,
    "FEB": 5.61,
    "MAR": 5.72,
    "APR": 5.36,
    "MAY": 4.85,
    "JUN": 4.37,
    "JUL": 4.47,
    "AUG": 5.22,
    "SEP": 5.55,
    "OCT": 5.69,
    "NOV": 5.76,
    "DEC": 5.53
  },
  {
    "LONG": -35.52,
    "LAT": -6.89,
    "CIDADE": "Cuitegi",
    "UF": "PARAÍBA",
    "anual": 5.19,
    "JAN": 5.26,
    "FEB": 5.47,
    "MAR": 5.63,
    "APR": 5.3,
    "MAY": 4.77,
    "JUN": 4.35,
    "JUL": 4.41,
    "AUG": 5.12,
    "SEP": 5.41,
    "OCT": 5.51,
    "NOV": 5.64,
    "DEC": 5.39
  },
  {
    "LONG": -35.53,
    "LAT": -6.95,
    "CIDADE": "Alagoinha",
    "UF": "PARAÍBA",
    "anual": 5.19,
    "JAN": 5.26,
    "FEB": 5.47,
    "MAR": 5.63,
    "APR": 5.3,
    "MAY": 4.77,
    "JUN": 4.35,
    "JUL": 4.41,
    "AUG": 5.12,
    "SEP": 5.41,
    "OCT": 5.51,
    "NOV": 5.64,
    "DEC": 5.39
  },
  {
    "LONG": -35.49,
    "LAT": -6.85,
    "CIDADE": "Guarabira",
    "UF": "PARAÍBA",
    "anual": 5.24,
    "JAN": 5.27,
    "FEB": 5.51,
    "MAR": 5.69,
    "APR": 5.35,
    "MAY": 4.81,
    "JUN": 4.42,
    "JUL": 4.48,
    "AUG": 5.19,
    "SEP": 5.46,
    "OCT": 5.6,
    "NOV": 5.68,
    "DEC": 5.47
  },
  {
    "LONG": -35.38,
    "LAT": -6.85,
    "CIDADE": "Araçagi",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.4,
    "FEB": 5.55,
    "MAR": 5.81,
    "APR": 5.46,
    "MAY": 4.93,
    "JUN": 4.46,
    "JUL": 4.59,
    "AUG": 5.26,
    "SEP": 5.56,
    "OCT": 5.7,
    "NOV": 5.82,
    "DEC": 5.61
  },
  {
    "LONG": -35.25,
    "LAT": -6.91,
    "CIDADE": "Cuité de Mamanguape",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.46,
    "FEB": 5.59,
    "MAR": 5.84,
    "APR": 5.43,
    "MAY": 4.91,
    "JUN": 4.52,
    "JUL": 4.6,
    "AUG": 5.32,
    "SEP": 5.58,
    "OCT": 5.69,
    "NOV": 5.89,
    "DEC": 5.67
  },
  {
    "LONG": -35.17,
    "LAT": -6.92,
    "CIDADE": "Capim",
    "UF": "PARAÍBA",
    "anual": 5.41,
    "JAN": 5.52,
    "FEB": 5.65,
    "MAR": 5.91,
    "APR": 5.44,
    "MAY": 4.91,
    "JUN": 4.55,
    "JUL": 4.59,
    "AUG": 5.32,
    "SEP": 5.61,
    "OCT": 5.75,
    "NOV": 5.97,
    "DEC": 5.75
  },
  {
    "LONG": -34.88,
    "LAT": -6.9,
    "CIDADE": "Lucena",
    "UF": "PARAÍBA",
    "anual": 5.73,
    "JAN": 5.76,
    "FEB": 6.02,
    "MAR": 6.19,
    "APR": 5.71,
    "MAY": 5.26,
    "JUN": 4.8,
    "JUL": 4.9,
    "AUG": 5.74,
    "SEP": 6.03,
    "OCT": 6.17,
    "NOV": 6.21,
    "DEC": 6.01
  },
  {
    "LONG": -38.65,
    "LAT": -6.82,
    "CIDADE": "Bom Jesus",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.81,
    "FEB": 5.93,
    "MAR": 6.05,
    "APR": 5.92,
    "MAY": 5.55,
    "JUN": 5.39,
    "JUL": 5.66,
    "AUG": 6.29,
    "SEP": 6.54,
    "OCT": 6.45,
    "NOV": 6.47,
    "DEC": 6.04
  },
  {
    "LONG": -38.35,
    "LAT": -6.85,
    "CIDADE": "Marizópolis",
    "UF": "PARAÍBA",
    "anual": 6.05,
    "JAN": 5.91,
    "FEB": 5.99,
    "MAR": 6.19,
    "APR": 5.98,
    "MAY": 5.57,
    "JUN": 5.36,
    "JUL": 5.61,
    "AUG": 6.28,
    "SEP": 6.56,
    "OCT": 6.54,
    "NOV": 6.54,
    "DEC": 6.13
  },
  {
    "LONG": -3.82,
    "LAT": -6.76,
    "CIDADE": "Sousa",
    "UF": "PARAÍBA",
    "anual": 6.06,
    "JAN": 5.91,
    "FEB": 6.03,
    "MAR": 6.2,
    "APR": 6.03,
    "MAY": 5.6,
    "JUN": 5.36,
    "JUL": 5.59,
    "AUG": 6.26,
    "SEP": 6.54,
    "OCT": 6.56,
    "NOV": 6.55,
    "DEC": 6.1
  },
  {
    "LONG": -38.08,
    "LAT": -6.79,
    "CIDADE": "Aparecida",
    "UF": "PARAÍBA",
    "anual": 6.03,
    "JAN": 5.82,
    "FEB": 5.97,
    "MAR": 6.22,
    "APR": 6.04,
    "MAY": 5.57,
    "JUN": 5.3,
    "JUL": 5.5,
    "AUG": 6.25,
    "SEP": 6.56,
    "OCT": 6.53,
    "NOV": 6.53,
    "DEC": 6.07
  },
  {
    "LONG": -37.94,
    "LAT": -6.82,
    "CIDADE": "São Domingos",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.85,
    "FEB": 5.95,
    "MAR": 6.18,
    "APR": 5.99,
    "MAY": 5.57,
    "JUN": 5.22,
    "JUL": 5.48,
    "AUG": 6.15,
    "SEP": 6.56,
    "OCT": 6.55,
    "NOV": 6.56,
    "DEC": 6.04
  },
  {
    "LONG": -37.8,
    "LAT": -6.77,
    "CIDADE": "Pombal",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.89,
    "FEB": 6,
    "MAR": 6.24,
    "APR": 6.01,
    "MAY": 5.56,
    "JUN": 5.21,
    "JUL": 5.44,
    "AUG": 6.14,
    "SEP": 6.58,
    "OCT": 6.56,
    "NOV": 6.51,
    "DEC": 6.04
  },
  {
    "LONG": -37.32,
    "LAT": -6.84,
    "CIDADE": "São José de Espinharas",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.83,
    "FEB": 6.04,
    "MAR": 6.23,
    "APR": 6.04,
    "MAY": 5.49,
    "JUN": 5.07,
    "JUL": 5.37,
    "AUG": 6.08,
    "SEP": 6.44,
    "OCT": 6.45,
    "NOV": 6.36,
    "DEC": 6.02
  },
  {
    "LONG": -36.99,
    "LAT": -6.77,
    "CIDADE": "Várzea",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.82,
    "FEB": 5.99,
    "MAR": 6.23,
    "APR": 5.95,
    "MAY": 5.41,
    "JUN": 4.94,
    "JUL": 5.28,
    "AUG": 6,
    "SEP": 6.36,
    "OCT": 6.4,
    "NOV": 6.33,
    "DEC": 5.93
  },
  {
    "LONG": -36.8,
    "LAT": -6.77,
    "CIDADE": "São José do Sabugi",
    "UF": "PARAÍBA",
    "anual": 5.81,
    "JAN": 5.77,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.83,
    "MAY": 5.31,
    "JUN": 4.92,
    "JUL": 5.21,
    "AUG": 5.94,
    "SEP": 6.31,
    "OCT": 6.32,
    "NOV": 6.22,
    "DEC": 5.89
  },
  {
    "LONG": -36.47,
    "LAT": -0.68,
    "CIDADE": "Pedra Lavrada",
    "UF": "PARAÍBA",
    "anual": 5.66,
    "JAN": 5.65,
    "FEB": 5.74,
    "MAR": 5.93,
    "APR": 5.66,
    "MAY": 5.15,
    "JUN": 4.75,
    "JUL": 5.01,
    "AUG": 5.66,
    "SEP": 6.09,
    "OCT": 6.24,
    "NOV": 6.22,
    "DEC": 5.79
  },
  {
    "LONG": -36.25,
    "LAT": -6.77,
    "CIDADE": "Sossêgo",
    "UF": "PARAÍBA",
    "anual": 5.5,
    "JAN": 5.59,
    "FEB": 5.61,
    "MAR": 5.79,
    "APR": 5.5,
    "MAY": 5.02,
    "JUN": 4.58,
    "JUL": 4.78,
    "AUG": 5.45,
    "SEP": 5.86,
    "OCT": 6.03,
    "NOV": 6.06,
    "DEC": 5.7
  },
  {
    "LONG": -35.82,
    "LAT": "#VALOR!",
    "CIDADE": "Casserengue",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.54,
    "FEB": 5.64,
    "MAR": 5.86,
    "APR": 5.48,
    "MAY": 4.99,
    "JUN": 4.56,
    "JUL": 4.71,
    "AUG": 5.4,
    "SEP": 5.72,
    "OCT": 5.83,
    "NOV": 5.93,
    "DEC": 5.63
  },
  {
    "LONG": -35.76,
    "LAT": -6.83,
    "CIDADE": "Arara",
    "UF": "PARAÍBA",
    "anual": 5.43,
    "JAN": 5.51,
    "FEB": 5.66,
    "MAR": 5.89,
    "APR": 5.47,
    "MAY": 5,
    "JUN": 4.53,
    "JUL": 4.7,
    "AUG": 5.42,
    "SEP": 5.72,
    "OCT": 5.77,
    "NOV": 5.92,
    "DEC": 5.61
  },
  {
    "LONG": -35.66,
    "LAT": -6.76,
    "CIDADE": "Solânea",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.43,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.42,
    "MAY": 4.91,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.29,
    "SEP": 5.63,
    "OCT": 5.73,
    "NOV": 5.82,
    "DEC": 5.58
  },
  {
    "LONG": -3.56,
    "LAT": -6.8,
    "CIDADE": "Borborema",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.43,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.42,
    "MAY": 4.91,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.29,
    "SEP": 5.63,
    "OCT": 5.73,
    "NOV": 5.82,
    "DEC": 5.58
  },
  {
    "LONG": -35.63,
    "LAT": -6.82,
    "CIDADE": "Serraria",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.43,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.42,
    "MAY": 4.91,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.29,
    "SEP": 5.63,
    "OCT": 5.73,
    "NOV": 5.82,
    "DEC": 5.58
  },
  {
    "LONG": -35.53,
    "LAT": -6.84,
    "CIDADE": "Pilõezinhos",
    "UF": "PARAÍBA",
    "anual": 5.21,
    "JAN": 5.28,
    "FEB": 5.47,
    "MAR": 5.65,
    "APR": 5.3,
    "MAY": 4.82,
    "JUN": 4.38,
    "JUL": 4.44,
    "AUG": 5.16,
    "SEP": 5.47,
    "OCT": 5.57,
    "NOV": 5.66,
    "DEC": 5.38
  },
  {
    "LONG": -35.44,
    "LAT": -6.75,
    "CIDADE": "Sertãozinho",
    "UF": "PARAÍBA",
    "anual": 5.27,
    "JAN": 5.31,
    "FEB": 5.47,
    "MAR": 5.68,
    "APR": 5.35,
    "MAY": 4.85,
    "JUN": 4.43,
    "JUL": 4.5,
    "AUG": 5.2,
    "SEP": 5.52,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.47
  },
  {
    "LONG": -35.49,
    "LAT": "#VALOR!",
    "CIDADE": "Pirpirituba",
    "UF": "PARAÍBA",
    "anual": 5.27,
    "JAN": 5.31,
    "FEB": 5.47,
    "MAR": 5.68,
    "APR": 5.35,
    "MAY": 4.85,
    "JUN": 4.43,
    "JUL": 4.5,
    "AUG": 5.2,
    "SEP": 5.52,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.47
  },
  {
    "LONG": -35.25,
    "LAT": -6.83,
    "CIDADE": "Itapororoca",
    "UF": "PARAÍBA",
    "anual": 5.41,
    "JAN": 5.52,
    "FEB": 5.67,
    "MAR": 5.94,
    "APR": 5.47,
    "MAY": 4.91,
    "JUN": 4.49,
    "JUL": 4.56,
    "AUG": 5.28,
    "SEP": 5.61,
    "OCT": 5.79,
    "NOV": 5.97,
    "DEC": 5.77
  },
  {
    "LONG": -35.12,
    "LAT": -6.83,
    "CIDADE": "Mamanguape",
    "UF": "PARAÍBA",
    "anual": 5.43,
    "JAN": 5.56,
    "FEB": 5.7,
    "MAR": 5.98,
    "APR": 5.48,
    "MAY": 4.91,
    "JUN": 4.49,
    "JUL": 4.54,
    "AUG": 5.29,
    "SEP": 5.62,
    "OCT": 5.79,
    "NOV": 5.98,
    "DEC": 5.78
  },
  {
    "LONG": -35.01,
    "LAT": -6.77,
    "CIDADE": "Marcação",
    "UF": "PARAÍBA",
    "anual": 5.47,
    "JAN": 5.6,
    "FEB": 5.81,
    "MAR": 6.06,
    "APR": 5.51,
    "MAY": 4.99,
    "JUN": 4.54,
    "JUL": 4.57,
    "AUG": 5.36,
    "SEP": 5.65,
    "OCT": 5.82,
    "NOV": 5.96,
    "DEC": 5.78
  },
  {
    "LONG": -35.08,
    "LAT": -6.8,
    "CIDADE": "Rio Tinto",
    "UF": "PARAÍBA",
    "anual": 5.47,
    "JAN": 5.6,
    "FEB": 5.81,
    "MAR": 6.06,
    "APR": 5.51,
    "MAY": 4.99,
    "JUN": 4.54,
    "JUL": 4.57,
    "AUG": 5.36,
    "SEP": 5.65,
    "OCT": 5.82,
    "NOV": 5.96,
    "DEC": 5.78
  },
  {
    "LONG": -3.86,
    "LAT": -0.67,
    "CIDADE": "Santa Helena",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.84,
    "FEB": 5.92,
    "MAR": 6.08,
    "APR": 5.89,
    "MAY": 5.5,
    "JUN": 5.36,
    "JUL": 5.68,
    "AUG": 6.27,
    "SEP": 6.49,
    "OCT": 6.47,
    "NOV": 6.47,
    "DEC": 6.01
  },
  {
    "LONG": -38.46,
    "LAT": -6.72,
    "CIDADE": "São João do Rio do Peixe",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.9,
    "FEB": 5.93,
    "MAR": 6.06,
    "APR": 5.95,
    "MAY": 5.49,
    "JUN": 5.31,
    "JUL": 5.61,
    "AUG": 6.27,
    "SEP": 6.47,
    "OCT": 6.5,
    "NOV": 6.48,
    "DEC": 6.04
  },
  {
    "LONG": -37.57,
    "LAT": -6.74,
    "CIDADE": "Vista Serrana",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.8,
    "FEB": 6.06,
    "MAR": 6.28,
    "APR": 6.04,
    "MAY": 5.57,
    "JUN": 5.12,
    "JUL": 5.43,
    "AUG": 6.12,
    "SEP": 6.49,
    "OCT": 6.47,
    "NOV": 6.39,
    "DEC": 5.99
  },
  {
    "LONG": -36.42,
    "LAT": -6.68,
    "CIDADE": "Nova Palmeira",
    "UF": "PARAÍBA",
    "anual": 5.68,
    "JAN": 5.73,
    "FEB": 5.77,
    "MAR": 5.89,
    "APR": 5.67,
    "MAY": 5.17,
    "JUN": 4.75,
    "JUL": 5.02,
    "AUG": 5.7,
    "SEP": 6.11,
    "OCT": 6.31,
    "NOV": 6.24,
    "DEC": 5.83
  },
  {
    "LONG": -36.07,
    "LAT": -6.72,
    "CIDADE": "Barra de Santa Rosa",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.48,
    "FEB": 5.63,
    "MAR": 5.82,
    "APR": 5.48,
    "MAY": 5.01,
    "JUN": 4.58,
    "JUL": 4.69,
    "AUG": 5.35,
    "SEP": 5.75,
    "OCT": 5.89,
    "NOV": 5.94,
    "DEC": 5.64
  },
  {
    "LONG": -35.63,
    "LAT": -6.74,
    "CIDADE": "Bananeiras",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.44,
    "FEB": 5.69,
    "MAR": 5.88,
    "APR": 5.48,
    "MAY": 5.04,
    "JUN": 4.62,
    "JUL": 4.7,
    "AUG": 5.42,
    "SEP": 5.76,
    "OCT": 5.79,
    "NOV": 5.9,
    "DEC": 5.61
  },
  {
    "LONG": -35.52,
    "LAT": -6.74,
    "CIDADE": "Belém",
    "UF": "PARAÍBA",
    "anual": 5.33,
    "JAN": 5.38,
    "FEB": 5.54,
    "MAR": 5.73,
    "APR": 5.37,
    "MAY": 4.99,
    "JUN": 4.54,
    "JUL": 4.59,
    "AUG": 5.3,
    "SEP": 5.63,
    "OCT": 5.68,
    "NOV": 5.75,
    "DEC": 5.44
  },
  {
    "LONG": -35.42,
    "LAT": -6.69,
    "CIDADE": "Duas Estradas",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.44,
    "FEB": 5.55,
    "MAR": 5.73,
    "APR": 5.4,
    "MAY": 4.96,
    "JUN": 4.52,
    "JUL": 4.58,
    "AUG": 5.31,
    "SEP": 5.63,
    "OCT": 5.76,
    "NOV": 5.8,
    "DEC": 5.51
  },
  {
    "LONG": -35.44,
    "LAT": -6.69,
    "CIDADE": "Serra da Raiz",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.44,
    "FEB": 5.55,
    "MAR": 5.73,
    "APR": 5.4,
    "MAY": 4.96,
    "JUN": 4.52,
    "JUL": 4.58,
    "AUG": 5.31,
    "SEP": 5.63,
    "OCT": 5.76,
    "NOV": 5.8,
    "DEC": 5.51
  },
  {
    "LONG": -35.37,
    "LAT": -6.67,
    "CIDADE": "Lagoa de Dentro",
    "UF": "PARAÍBA",
    "anual": 5.39,
    "JAN": 5.52,
    "FEB": 5.64,
    "MAR": 5.81,
    "APR": 5.45,
    "MAY": 4.94,
    "JUN": 4.49,
    "JUL": 4.64,
    "AUG": 5.31,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.9,
    "DEC": 5.63
  },
  {
    "LONG": -35.27,
    "LAT": -6.72,
    "CIDADE": "Curral de Cima",
    "UF": "PARAÍBA",
    "anual": 5.43,
    "JAN": 5.59,
    "FEB": 5.74,
    "MAR": 5.92,
    "APR": 5.46,
    "MAY": 4.94,
    "JUN": 4.48,
    "JUL": 4.59,
    "AUG": 5.3,
    "SEP": 5.65,
    "OCT": 5.81,
    "NOV": 5.96,
    "DEC": 5.71
  },
  {
    "LONG": -34.93,
    "LAT": -6.69,
    "CIDADE": "Baía da Traição",
    "UF": "PARAÍBA",
    "anual": 5.76,
    "JAN": 5.88,
    "FEB": 6,
    "MAR": 6.22,
    "APR": 5.75,
    "MAY": 5.3,
    "JUN": 4.8,
    "JUL": 4.94,
    "AUG": 5.76,
    "SEP": 6.04,
    "OCT": 6.17,
    "NOV": 6.24,
    "DEC": 5.99
  },
  {
    "LONG": -38.51,
    "LAT": -6.56,
    "CIDADE": "Poço de José de Moura",
    "UF": "PARAÍBA",
    "anual": 5.94,
    "JAN": 5.81,
    "FEB": 5.89,
    "MAR": 5.91,
    "APR": 5.82,
    "MAY": 5.42,
    "JUN": 5.26,
    "JUL": 5.56,
    "AUG": 6.25,
    "SEP": 6.51,
    "OCT": 6.45,
    "NOV": 6.47,
    "DEC": 5.94
  },
  {
    "LONG": -38.6,
    "LAT": -6.57,
    "CIDADE": "Triunfo",
    "UF": "PARAÍBA",
    "anual": 5.94,
    "JAN": 5.81,
    "FEB": 5.89,
    "MAR": 5.91,
    "APR": 5.82,
    "MAY": 5.42,
    "JUN": 5.26,
    "JUL": 5.56,
    "AUG": 6.25,
    "SEP": 6.51,
    "OCT": 6.45,
    "NOV": 6.47,
    "DEC": 5.94
  },
  {
    "LONG": -38.1,
    "LAT": -6.62,
    "CIDADE": "São Francisco",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.76,
    "FEB": 5.85,
    "MAR": 6.1,
    "APR": 5.94,
    "MAY": 5.54,
    "JUN": 5.27,
    "JUL": 5.53,
    "AUG": 6.21,
    "SEP": 6.6,
    "OCT": 6.52,
    "NOV": 6.52,
    "DEC": 5.96
  },
  {
    "LONG": -37.92,
    "LAT": -6.59,
    "CIDADE": "Lagoa",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.83,
    "FEB": 5.93,
    "MAR": 6.12,
    "APR": 5.96,
    "MAY": 5.56,
    "JUN": 5.28,
    "JUL": 5.55,
    "AUG": 6.24,
    "SEP": 6.62,
    "OCT": 6.55,
    "NOV": 6.48,
    "DEC": 5.97
  },
  {
    "LONG": -3.78,
    "LAT": -6.55,
    "CIDADE": "Jericó",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.82,
    "FEB": 5.94,
    "MAR": 6.13,
    "APR": 5.97,
    "MAY": 5.56,
    "JUN": 5.24,
    "JUL": 5.52,
    "AUG": 6.2,
    "SEP": 6.57,
    "OCT": 6.52,
    "NOV": 6.42,
    "DEC": 5.95
  },
  {
    "LONG": -37.62,
    "LAT": -6.59,
    "CIDADE": "Paulista",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.97,
    "FEB": 6.07,
    "MAR": 6.24,
    "APR": 6.01,
    "MAY": 5.58,
    "JUN": 5.19,
    "JUL": 5.45,
    "AUG": 6.17,
    "SEP": 6.54,
    "OCT": 6.52,
    "NOV": 6.4,
    "DEC": 6.03
  },
  {
    "LONG": -3.63,
    "LAT": -6.64,
    "CIDADE": "Baraúna",
    "UF": "PARAÍBA",
    "anual": 5.58,
    "JAN": 5.63,
    "FEB": 5.71,
    "MAR": 5.9,
    "APR": 5.64,
    "MAY": 5.09,
    "JUN": 4.74,
    "JUL": 4.94,
    "AUG": 5.57,
    "SEP": 5.98,
    "OCT": 6.1,
    "NOV": 6.02,
    "DEC": 5.66
  },
  {
    "LONG": -35.91,
    "LAT": -0.66,
    "CIDADE": "Damião",
    "UF": "PARAÍBA",
    "anual": 5.45,
    "JAN": 5.52,
    "FEB": 5.59,
    "MAR": 5.85,
    "APR": 5.51,
    "MAY": 4.99,
    "JUN": 4.62,
    "JUL": 4.78,
    "AUG": 5.46,
    "SEP": 5.74,
    "OCT": 5.87,
    "NOV": 5.91,
    "DEC": 5.57
  },
  {
    "LONG": -35.79,
    "LAT": -6.64,
    "CIDADE": "Cacimba de Dentro",
    "UF": "PARAÍBA",
    "anual": 5.33,
    "JAN": 5.45,
    "FEB": 5.59,
    "MAR": 5.81,
    "APR": 5.4,
    "MAY": 4.95,
    "JUN": 4.43,
    "JUL": 4.57,
    "AUG": 5.23,
    "SEP": 5.58,
    "OCT": 5.7,
    "NOV": 5.77,
    "DEC": 5.48
  },
  {
    "LONG": -35.63,
    "LAT": -6.6,
    "CIDADE": "Dona Inês",
    "UF": "PARAÍBA",
    "anual": 5.37,
    "JAN": 5.39,
    "FEB": 5.62,
    "MAR": 5.82,
    "APR": 5.48,
    "MAY": 5.04,
    "JUN": 4.5,
    "JUL": 4.68,
    "AUG": 5.37,
    "SEP": 5.61,
    "OCT": 5.68,
    "NOV": 5.77,
    "DEC": 5.47
  },
  {
    "LONG": -35.44,
    "LAT": -6.62,
    "CIDADE": "Logradouro",
    "UF": "PARAÍBA",
    "anual": 5.46,
    "JAN": 5.51,
    "FEB": 5.64,
    "MAR": 5.87,
    "APR": 5.48,
    "MAY": 5.08,
    "JUN": 4.66,
    "JUL": 4.82,
    "AUG": 5.45,
    "SEP": 5.75,
    "OCT": 5.82,
    "NOV": 5.89,
    "DEC": 5.54
  },
  {
    "LONG": -35.46,
    "LAT": -6.62,
    "CIDADE": "Caiçara",
    "UF": "PARAÍBA",
    "anual": 5.46,
    "JAN": 5.51,
    "FEB": 5.64,
    "MAR": 5.87,
    "APR": 5.48,
    "MAY": 5.08,
    "JUN": 4.66,
    "JUL": 4.82,
    "AUG": 5.45,
    "SEP": 5.75,
    "OCT": 5.82,
    "NOV": 5.89,
    "DEC": 5.54
  },
  {
    "LONG": -35.29,
    "LAT": -6.61,
    "CIDADE": "Jacaraú",
    "UF": "PARAÍBA",
    "anual": 5.51,
    "JAN": 5.67,
    "FEB": 5.83,
    "MAR": 5.98,
    "APR": 5.53,
    "MAY": 5.06,
    "JUN": 4.63,
    "JUL": 4.71,
    "AUG": 5.41,
    "SEP": 5.75,
    "OCT": 5.84,
    "NOV": 6.02,
    "DEC": 5.75
  },
  {
    "LONG": -35.27,
    "LAT": -6.64,
    "CIDADE": "Pedro Régis",
    "UF": "PARAÍBA",
    "anual": 5.51,
    "JAN": 5.67,
    "FEB": 5.83,
    "MAR": 5.98,
    "APR": 5.53,
    "MAY": 5.06,
    "JUN": 4.63,
    "JUL": 4.71,
    "AUG": 5.41,
    "SEP": 5.75,
    "OCT": 5.84,
    "NOV": 6.02,
    "DEC": 5.75
  },
  {
    "LONG": -35.05,
    "LAT": -6.6,
    "CIDADE": "Mataraca",
    "UF": "PARAÍBA",
    "anual": 5.6,
    "JAN": 5.75,
    "FEB": 5.88,
    "MAR": 6.1,
    "APR": 5.64,
    "MAY": 5.15,
    "JUN": 4.71,
    "JUL": 4.83,
    "AUG": 5.57,
    "SEP": 5.85,
    "OCT": 5.93,
    "NOV": 6.02,
    "DEC": 5.75
  },
  {
    "LONG": -38.55,
    "LAT": -6.45,
    "CIDADE": "Bernardino Batista",
    "UF": "PARAÍBA",
    "anual": 5.88,
    "JAN": 5.72,
    "FEB": 5.75,
    "MAR": 5.81,
    "APR": 5.64,
    "MAY": 5.32,
    "JUN": 5.21,
    "JUL": 5.57,
    "AUG": 6.26,
    "SEP": 6.54,
    "OCT": 6.48,
    "NOV": 6.44,
    "DEC": 5.85
  },
  {
    "LONG": -38.41,
    "LAT": -6.52,
    "CIDADE": "Uiraúna",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.73,
    "FEB": 5.8,
    "MAR": 5.92,
    "APR": 5.7,
    "MAY": 5.37,
    "JUN": 5.22,
    "JUL": 5.54,
    "AUG": 6.23,
    "SEP": 6.51,
    "OCT": 6.44,
    "NOV": 6.41,
    "DEC": 5.86
  },
  {
    "LONG": -38.48,
    "LAT": -0.65,
    "CIDADE": "Joca Claudino",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.73,
    "FEB": 5.8,
    "MAR": 5.92,
    "APR": 5.7,
    "MAY": 5.37,
    "JUN": 5.22,
    "JUL": 5.54,
    "AUG": 6.23,
    "SEP": 6.51,
    "OCT": 6.44,
    "NOV": 6.41,
    "DEC": 5.86
  },
  {
    "LONG": -38.28,
    "LAT": -6.55,
    "CIDADE": "Vieirópolis",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.77,
    "FEB": 5.87,
    "MAR": 6,
    "APR": 5.81,
    "MAY": 5.48,
    "JUN": 5.29,
    "JUL": 5.59,
    "AUG": 6.28,
    "SEP": 6.49,
    "OCT": 6.48,
    "NOV": 6.46,
    "DEC": 5.89
  },
  {
    "LONG": -38.17,
    "LAT": -6.51,
    "CIDADE": "Lastro",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.76,
    "FEB": 5.82,
    "MAR": 6.05,
    "APR": 5.86,
    "MAY": 5.51,
    "JUN": 5.27,
    "JUL": 5.55,
    "AUG": 6.25,
    "SEP": 6.51,
    "OCT": 6.47,
    "NOV": 6.48,
    "DEC": 5.9
  },
  {
    "LONG": -38.06,
    "LAT": -6.53,
    "CIDADE": "Santa Cruz",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.82,
    "FEB": 5.9,
    "MAR": 6.13,
    "APR": 5.91,
    "MAY": 5.55,
    "JUN": 5.28,
    "JUL": 5.55,
    "AUG": 6.21,
    "SEP": 6.55,
    "OCT": 6.54,
    "NOV": 6.49,
    "DEC": 5.98
  },
  {
    "LONG": -37.71,
    "LAT": -6.54,
    "CIDADE": "Mato Grosso",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.9,
    "FEB": 6.03,
    "MAR": 6.2,
    "APR": 6.02,
    "MAY": 5.59,
    "JUN": 5.22,
    "JUL": 5.53,
    "AUG": 6.21,
    "SEP": 6.57,
    "OCT": 6.52,
    "NOV": 6.44,
    "DEC": 6.01
  },
  {
    "LONG": -37.45,
    "LAT": -6.49,
    "CIDADE": "São Bento",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.94,
    "FEB": 6.08,
    "MAR": 6.23,
    "APR": 6.06,
    "MAY": 5.55,
    "JUN": 5.13,
    "JUL": 5.4,
    "AUG": 6.08,
    "SEP": 6.48,
    "OCT": 6.49,
    "NOV": 6.35,
    "DEC": 6
  },
  {
    "LONG": "#VALOR!",
    "LAT": -6.51,
    "CIDADE": "Picuí",
    "UF": "PARAÍBA",
    "anual": 5.68,
    "JAN": 5.72,
    "FEB": 5.76,
    "MAR": 5.9,
    "APR": 5.67,
    "MAY": 5.19,
    "JUN": 4.84,
    "JUL": 5.08,
    "AUG": 5.75,
    "SEP": 6.13,
    "OCT": 6.21,
    "NOV": 6.15,
    "DEC": 5.71
  },
  {
    "LONG": -36.2,
    "LAT": -6.46,
    "CIDADE": "Nova Floresta",
    "UF": "PARAÍBA",
    "anual": 5.6,
    "JAN": 5.63,
    "FEB": 5.7,
    "MAR": 5.87,
    "APR": 5.63,
    "MAY": 5.14,
    "JUN": 4.75,
    "JUL": 4.96,
    "AUG": 5.65,
    "SEP": 6.04,
    "OCT": 6.13,
    "NOV": 6.07,
    "DEC": 5.66
  },
  {
    "LONG": -36.15,
    "LAT": -6.49,
    "CIDADE": "Cuité",
    "UF": "PARAÍBA",
    "anual": 5.43,
    "JAN": 5.47,
    "FEB": 5.59,
    "MAR": 5.68,
    "APR": 5.48,
    "MAY": 4.97,
    "JUN": 4.55,
    "JUL": 4.74,
    "AUG": 5.44,
    "SEP": 5.81,
    "OCT": 5.94,
    "NOV": 5.96,
    "DEC": 5.54
  },
  {
    "LONG": -35.74,
    "LAT": -6.53,
    "CIDADE": "Araruna",
    "UF": "PARAÍBA",
    "anual": 5.49,
    "JAN": 5.54,
    "FEB": 5.72,
    "MAR": 5.93,
    "APR": 5.54,
    "MAY": 5.1,
    "JUN": 4.65,
    "JUL": 4.82,
    "AUG": 5.47,
    "SEP": 5.81,
    "OCT": 5.83,
    "NOV": 5.9,
    "DEC": 5.56
  },
  {
    "LONG": -35.66,
    "LAT": -6.54,
    "CIDADE": "Riachão",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.38,
    "FEB": 5.6,
    "MAR": 5.78,
    "APR": 5.5,
    "MAY": 5.06,
    "JUN": 4.62,
    "JUL": 4.76,
    "AUG": 5.39,
    "SEP": 5.62,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.43
  },
  {
    "LONG": -3.56,
    "LAT": -0.65,
    "CIDADE": "Tacima",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.38,
    "FEB": 5.6,
    "MAR": 5.78,
    "APR": 5.5,
    "MAY": 5.06,
    "JUN": 4.62,
    "JUL": 4.76,
    "AUG": 5.39,
    "SEP": 5.62,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.43
  },
  {
    "LONG": -38.49,
    "LAT": -6.4,
    "CIDADE": "Poço Dantas",
    "UF": "PARAÍBA",
    "anual": 5.88,
    "JAN": 5.67,
    "FEB": 5.7,
    "MAR": 5.86,
    "APR": 5.64,
    "MAY": 5.36,
    "JUN": 5.22,
    "JUL": 5.58,
    "AUG": 6.28,
    "SEP": 6.52,
    "OCT": 6.45,
    "NOV": 6.4,
    "DEC": 5.83
  },
  {
    "LONG": -37.92,
    "LAT": -6.44,
    "CIDADE": "Bom Sucesso",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.88,
    "FEB": 5.96,
    "MAR": 6.13,
    "APR": 5.97,
    "MAY": 5.57,
    "JUN": 5.33,
    "JUL": 5.61,
    "AUG": 6.25,
    "SEP": 6.52,
    "OCT": 6.5,
    "NOV": 6.46,
    "DEC": 5.98
  },
  {
    "LONG": -37.83,
    "LAT": -6.37,
    "CIDADE": "Brejo dos Santos",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.87,
    "FEB": 5.98,
    "MAR": 6.1,
    "APR": 5.95,
    "MAY": 5.56,
    "JUN": 5.28,
    "JUL": 5.58,
    "AUG": 6.23,
    "SEP": 6.59,
    "OCT": 6.51,
    "NOV": 6.43,
    "DEC": 5.98
  },
  {
    "LONG": -37.65,
    "LAT": -6.44,
    "CIDADE": "Riacho dos Cavalos",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.87,
    "FEB": 6.03,
    "MAR": 6.17,
    "APR": 6.02,
    "MAY": 5.57,
    "JUN": 5.18,
    "JUL": 5.54,
    "AUG": 6.17,
    "SEP": 6.54,
    "OCT": 6.49,
    "NOV": 6.36,
    "DEC": 5.96
  },
  {
    "LONG": -36.45,
    "LAT": -0.64,
    "CIDADE": "Frei Martinho",
    "UF": "PARAÍBA",
    "anual": 5.68,
    "JAN": 5.71,
    "FEB": 5.75,
    "MAR": 5.89,
    "APR": 5.7,
    "MAY": 5.21,
    "JUN": 4.8,
    "JUL": 5.04,
    "AUG": 5.81,
    "SEP": 6.18,
    "OCT": 6.2,
    "NOV": 6.19,
    "DEC": 5.7
  },
  {
    "LONG": -37.75,
    "LAT": -0.63,
    "CIDADE": "Catolé do Rocha",
    "UF": "PARAÍBA",
    "anual": 5.96,
    "JAN": 5.79,
    "FEB": 5.94,
    "MAR": 6.06,
    "APR": 5.87,
    "MAY": 5.54,
    "JUN": 5.21,
    "JUL": 5.57,
    "AUG": 6.24,
    "SEP": 6.58,
    "OCT": 6.47,
    "NOV": 6.32,
    "DEC": 5.91
  },
  {
    "LONG": -37.49,
    "LAT": -6.34,
    "CIDADE": "Brejo do Cruz",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.92,
    "FEB": 6.08,
    "MAR": 6.15,
    "APR": 6,
    "MAY": 5.6,
    "JUN": 5.23,
    "JUL": 5.49,
    "AUG": 6.12,
    "SEP": 6.52,
    "OCT": 6.51,
    "NOV": 6.4,
    "DEC": 5.98
  },
  {
    "LONG": -37.54,
    "LAT": -6.19,
    "CIDADE": "Belém do Brejo do Cruz",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.84,
    "FEB": 5.98,
    "MAR": 6.1,
    "APR": 6.01,
    "MAY": 5.59,
    "JUN": 5.25,
    "JUL": 5.53,
    "AUG": 6.15,
    "SEP": 6.54,
    "OCT": 6.48,
    "NOV": 6.36,
    "DEC": 5.91
  },
  {
    "LONG": -37.35,
    "LAT": -6.21,
    "CIDADE": "São José do Brejo do Cruz",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.85,
    "FEB": 5.98,
    "MAR": 6.02,
    "APR": 5.99,
    "MAY": 5.55,
    "JUN": 5.15,
    "JUL": 5.48,
    "AUG": 6.15,
    "SEP": 6.52,
    "OCT": 6.47,
    "NOV": 6.35,
    "DEC": 5.9
  },
  {
    "LONG": -51.99,
    "LAT": -26.48,
    "CIDADE": "Palmas",
    "UF": "PARANÁ",
    "anual": 4.82,
    "JAN": 5.26,
    "FEB": 5.24,
    "MAR": 5.3,
    "APR": 4.88,
    "MAY": 4.09,
    "JUN": 3.75,
    "JUL": 4.05,
    "AUG": 4.94,
    "SEP": 4.55,
    "OCT": 4.95,
    "NOV": 5.47,
    "DEC": 5.37
  },
  {
    "LONG": -52.55,
    "LAT": -26.35,
    "CIDADE": "Mariópolis",
    "UF": "PARANÁ",
    "anual": 4.91,
    "JAN": 5.47,
    "FEB": 5.37,
    "MAR": 5.42,
    "APR": 4.97,
    "MAY": 4.22,
    "JUN": 3.79,
    "JUL": 4.11,
    "AUG": 5,
    "SEP": 4.59,
    "OCT": 5.05,
    "NOV": 5.51,
    "DEC": 5.45
  },
  {
    "LONG": -52.35,
    "LAT": -26.4,
    "CIDADE": "Clevelândia",
    "UF": "PARANÁ",
    "anual": 4.89,
    "JAN": 5.41,
    "FEB": 5.33,
    "MAR": 5.39,
    "APR": 4.95,
    "MAY": 4.22,
    "JUN": 3.79,
    "JUL": 4.1,
    "AUG": 5.01,
    "SEP": 4.59,
    "OCT": 5,
    "NOV": 5.5,
    "DEC": 5.42
  },
  {
    "LONG": -51.32,
    "LAT": -26.43,
    "CIDADE": "General Carneiro",
    "UF": "PARANÁ",
    "anual": 4.4,
    "JAN": 4.86,
    "FEB": 4.88,
    "MAR": 4.79,
    "APR": 4.28,
    "MAY": 3.72,
    "JUN": 3.4,
    "JUL": 3.69,
    "AUG": 4.64,
    "SEP": 4.2,
    "OCT": 4.41,
    "NOV": 4.99,
    "DEC": 4.98
  },
  {
    "LONG": -53.63,
    "LAT": -26.25,
    "CIDADE": "Barracão",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.6,
    "FEB": 5.54,
    "MAR": 5.48,
    "APR": 4.91,
    "MAY": 4.16,
    "JUN": 3.74,
    "JUL": 4.06,
    "AUG": 4.91,
    "SEP": 4.64,
    "OCT": 5.12,
    "NOV": 5.46,
    "DEC": 5.49
  },
  {
    "LONG": -53.31,
    "LAT": -26.25,
    "CIDADE": "Flor da Serra do Sul",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.58,
    "FEB": 5.43,
    "MAR": 5.43,
    "APR": 4.96,
    "MAY": 4.18,
    "JUN": 3.73,
    "JUL": 4.08,
    "AUG": 4.94,
    "SEP": 4.66,
    "OCT": 5.09,
    "NOV": 5.47,
    "DEC": 5.45
  },
  {
    "LONG": -52.78,
    "LAT": -26.27,
    "CIDADE": "Vitorino",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.51,
    "FEB": 5.39,
    "MAR": 5.43,
    "APR": 4.98,
    "MAY": 4.22,
    "JUN": 3.84,
    "JUL": 4.12,
    "AUG": 5.02,
    "SEP": 4.63,
    "OCT": 5.06,
    "NOV": 5.52,
    "DEC": 5.48
  },
  {
    "LONG": -53.6,
    "LAT": -26.2,
    "CIDADE": "Bom Jesus do Sul",
    "UF": "PARANÁ",
    "anual": 4.97,
    "JAN": 5.7,
    "FEB": 5.49,
    "MAR": 5.53,
    "APR": 4.99,
    "MAY": 4.2,
    "JUN": 3.77,
    "JUL": 4.11,
    "AUG": 5,
    "SEP": 4.66,
    "OCT": 5.15,
    "NOV": 5.52,
    "DEC": 5.55
  },
  {
    "LONG": -53.36,
    "LAT": -26.18,
    "CIDADE": "Salgado Filho",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.6,
    "FEB": 5.45,
    "MAR": 5.47,
    "APR": 4.99,
    "MAY": 4.21,
    "JUN": 3.77,
    "JUL": 4.13,
    "AUG": 4.99,
    "SEP": 4.68,
    "OCT": 5.13,
    "NOV": 5.53,
    "DEC": 5.48
  },
  {
    "LONG": -52.97,
    "LAT": -26.16,
    "CIDADE": "Renascença",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.47,
    "FEB": 5.42,
    "MAR": 5.48,
    "APR": 5,
    "MAY": 4.24,
    "JUN": 3.83,
    "JUL": 4.11,
    "AUG": 5.02,
    "SEP": 4.66,
    "OCT": 5.1,
    "NOV": 5.51,
    "DEC": 5.47
  },
  {
    "LONG": -52.67,
    "LAT": -26.23,
    "CIDADE": "Pato Branco",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.55,
    "FEB": 5.38,
    "MAR": 5.48,
    "APR": 4.98,
    "MAY": 4.21,
    "JUN": 3.84,
    "JUL": 4.11,
    "AUG": 5.08,
    "SEP": 4.62,
    "OCT": 5.07,
    "NOV": 5.48,
    "DEC": 5.49
  },
  {
    "LONG": -52.04,
    "LAT": -26.23,
    "CIDADE": "Coronel Domingos Soares",
    "UF": "PARANÁ",
    "anual": 4.85,
    "JAN": 5.33,
    "FEB": 5.22,
    "MAR": 5.31,
    "APR": 4.89,
    "MAY": 4.14,
    "JUN": 3.81,
    "JUL": 4.09,
    "AUG": 5.03,
    "SEP": 4.63,
    "OCT": 4.99,
    "NOV": 5.46,
    "DEC": 5.36
  },
  {
    "LONG": -51.55,
    "LAT": -26.16,
    "CIDADE": "Bituruna",
    "UF": "PARANÁ",
    "anual": 4.5,
    "JAN": 4.96,
    "FEB": 5.03,
    "MAR": 4.95,
    "APR": 4.38,
    "MAY": 3.73,
    "JUN": 3.39,
    "JUL": 3.71,
    "AUG": 4.71,
    "SEP": 4.29,
    "OCT": 4.6,
    "NOV": 5.14,
    "DEC": 5.14
  },
  {
    "LONG": -51.23,
    "LAT": -26.17,
    "CIDADE": "Porto Vitória",
    "UF": "PARANÁ",
    "anual": 4.42,
    "JAN": 4.98,
    "FEB": 5,
    "MAR": 4.92,
    "APR": 4.29,
    "MAY": 3.66,
    "JUN": 3.22,
    "JUL": 3.48,
    "AUG": 4.59,
    "SEP": 4.24,
    "OCT": 4.5,
    "NOV": 5.1,
    "DEC": 5.11
  },
  {
    "LONG": -51.09,
    "LAT": -26.23,
    "CIDADE": "União da Vitória",
    "UF": "PARANÁ",
    "anual": 4.33,
    "JAN": 4.9,
    "FEB": 4.87,
    "MAR": 4.82,
    "APR": 4.16,
    "MAY": 3.63,
    "JUN": 3.19,
    "JUL": 3.43,
    "AUG": 4.5,
    "SEP": 4.16,
    "OCT": 4.4,
    "NOV": 4.98,
    "DEC": 4.96
  },
  {
    "LONG": -50.93,
    "LAT": -2.62,
    "CIDADE": "Paula Freitas",
    "UF": "PARANÁ",
    "anual": 4.28,
    "JAN": 4.77,
    "FEB": 4.84,
    "MAR": 4.8,
    "APR": 4.1,
    "MAY": 3.6,
    "JUN": 3.15,
    "JUL": 3.4,
    "AUG": 4.45,
    "SEP": 4.1,
    "OCT": 4.32,
    "NOV": 4.9,
    "DEC": 4.9
  },
  {
    "LONG": -53.73,
    "LAT": -26.07,
    "CIDADE": "Santo Antônio do Sudoeste",
    "UF": "PARANÁ",
    "anual": 4.97,
    "JAN": 5.65,
    "FEB": 5.56,
    "MAR": 5.5,
    "APR": 5.01,
    "MAY": 4.2,
    "JUN": 3.79,
    "JUL": 4.11,
    "AUG": 4.96,
    "SEP": 4.64,
    "OCT": 5.14,
    "NOV": 5.51,
    "DEC": 5.59
  },
  {
    "LONG": -53.32,
    "LAT": -26.15,
    "CIDADE": "Manfrinópolis",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.53,
    "FEB": 5.5,
    "MAR": 5.53,
    "APR": 5,
    "MAY": 4.18,
    "JUN": 3.81,
    "JUL": 4.12,
    "AUG": 5.03,
    "SEP": 4.68,
    "OCT": 5.12,
    "NOV": 5.53,
    "DEC": 5.52
  },
  {
    "LONG": -53.05,
    "LAT": -26.08,
    "CIDADE": "Francisco Beltrão",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.5,
    "FEB": 5.39,
    "MAR": 5.49,
    "APR": 4.98,
    "MAY": 4.23,
    "JUN": 3.81,
    "JUL": 4.13,
    "AUG": 5.04,
    "SEP": 4.68,
    "OCT": 5.12,
    "NOV": 5.51,
    "DEC": 5.51
  },
  {
    "LONG": -53.03,
    "LAT": -26.15,
    "CIDADE": "Marmeleiro",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.5,
    "FEB": 5.39,
    "MAR": 5.49,
    "APR": 4.98,
    "MAY": 4.23,
    "JUN": 3.81,
    "JUL": 4.13,
    "AUG": 5.04,
    "SEP": 4.68,
    "OCT": 5.12,
    "NOV": 5.51,
    "DEC": 5.51
  },
  {
    "LONG": -52.84,
    "LAT": -26.07,
    "CIDADE": "Bom Sucesso do Sul",
    "UF": "PARANÁ",
    "anual": 4.97,
    "JAN": 5.5,
    "FEB": 5.43,
    "MAR": 5.53,
    "APR": 5.04,
    "MAY": 4.25,
    "JUN": 3.81,
    "JUL": 4.12,
    "AUG": 5.07,
    "SEP": 4.66,
    "OCT": 5.12,
    "NOV": 5.57,
    "DEC": 5.55
  },
  {
    "LONG": -52.39,
    "LAT": -26.14,
    "CIDADE": "Honório Serpa",
    "UF": "PARANÁ",
    "anual": 4.91,
    "JAN": 5.48,
    "FEB": 5.35,
    "MAR": 5.41,
    "APR": 4.91,
    "MAY": 4.21,
    "JUN": 3.84,
    "JUL": 4.15,
    "AUG": 5.04,
    "SEP": 4.64,
    "OCT": 5.04,
    "NOV": 5.48,
    "DEC": 5.4
  },
  {
    "LONG": -49.8,
    "LAT": -26.1,
    "CIDADE": "Rio Negro",
    "UF": "PARANÁ",
    "anual": 4.34,
    "JAN": 4.88,
    "FEB": 4.9,
    "MAR": 4.77,
    "APR": 4.24,
    "MAY": 3.67,
    "JUN": 3.31,
    "JUL": 3.61,
    "AUG": 4.56,
    "SEP": 4.08,
    "OCT": 4.31,
    "NOV": 4.82,
    "DEC": 4.93
  },
  {
    "LONG": -4.94,
    "LAT": -26.1,
    "CIDADE": "Piên",
    "UF": "PARANÁ",
    "anual": 4.34,
    "JAN": 4.81,
    "FEB": 4.87,
    "MAR": 4.73,
    "APR": 4.3,
    "MAY": 3.75,
    "JUN": 3.47,
    "JUL": 3.68,
    "AUG": 4.55,
    "SEP": 4.06,
    "OCT": 4.22,
    "NOV": 4.76,
    "DEC": 4.87
  },
  {
    "LONG": -53.74,
    "LAT": -26.02,
    "CIDADE": "Pranchita",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.67,
    "FEB": 5.6,
    "MAR": 5.53,
    "APR": 4.98,
    "MAY": 4.22,
    "JUN": 3.79,
    "JUL": 4.11,
    "AUG": 4.98,
    "SEP": 4.64,
    "OCT": 5.14,
    "NOV": 5.53,
    "DEC": 5.63
  },
  {
    "LONG": -53.48,
    "LAT": -26.03,
    "CIDADE": "Pinhal de São Bento",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.63,
    "FEB": 5.56,
    "MAR": 5.55,
    "APR": 5.01,
    "MAY": 4.23,
    "JUN": 3.85,
    "JUL": 4.14,
    "AUG": 5.03,
    "SEP": 4.68,
    "OCT": 5.15,
    "NOV": 5.54,
    "DEC": 5.59
  },
  {
    "LONG": -52.81,
    "LAT": -25.96,
    "CIDADE": "Itapejara d'Oeste",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.53,
    "FEB": 5.45,
    "MAR": 5.57,
    "APR": 5.07,
    "MAY": 4.26,
    "JUN": 3.81,
    "JUL": 4.12,
    "AUG": 5.08,
    "SEP": 4.69,
    "OCT": 5.14,
    "NOV": 5.61,
    "DEC": 5.57
  },
  {
    "LONG": -52.56,
    "LAT": -25.98,
    "CIDADE": "Coronel Vivida",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.53,
    "FEB": 5.38,
    "MAR": 5.52,
    "APR": 5.01,
    "MAY": 4.24,
    "JUN": 3.87,
    "JUL": 4.12,
    "AUG": 5.05,
    "SEP": 4.65,
    "OCT": 5.11,
    "NOV": 5.53,
    "DEC": 5.46
  },
  {
    "LONG": -51.34,
    "LAT": -26.02,
    "CIDADE": "Cruz Machado",
    "UF": "PARANÁ",
    "anual": 4.5,
    "JAN": 5,
    "FEB": 4.97,
    "MAR": 5,
    "APR": 4.39,
    "MAY": 3.77,
    "JUN": 3.37,
    "JUL": 3.63,
    "AUG": 4.68,
    "SEP": 4.34,
    "OCT": 4.62,
    "NOV": 5.16,
    "DEC": 5.12
  },
  {
    "LONG": -50.83,
    "LAT": -2.6,
    "CIDADE": "Paulo Frontin",
    "UF": "PARANÁ",
    "anual": 4.25,
    "JAN": 4.67,
    "FEB": 4.72,
    "MAR": 4.7,
    "APR": 4.08,
    "MAY": 3.58,
    "JUN": 3.24,
    "JUL": 3.48,
    "AUG": 4.5,
    "SEP": 4.12,
    "OCT": 4.23,
    "NOV": 4.86,
    "DEC": 4.83
  },
  {
    "LONG": -50.2,
    "LAT": -25.98,
    "CIDADE": "Antônio Olinto",
    "UF": "PARANÁ",
    "anual": 4.37,
    "JAN": 4.85,
    "FEB": 4.86,
    "MAR": 4.84,
    "APR": 4.21,
    "MAY": 3.68,
    "JUN": 3.35,
    "JUL": 3.61,
    "AUG": 4.6,
    "SEP": 4.17,
    "OCT": 4.38,
    "NOV": 4.9,
    "DEC": 4.96
  },
  {
    "LONG": -49.68,
    "LAT": -25.98,
    "CIDADE": "Campo do Tenente",
    "UF": "PARANÁ",
    "anual": 4.37,
    "JAN": 4.86,
    "FEB": 4.91,
    "MAR": 4.76,
    "APR": 4.31,
    "MAY": 3.73,
    "JUN": 3.4,
    "JUL": 3.64,
    "AUG": 4.61,
    "SEP": 4.13,
    "OCT": 4.32,
    "NOV": 4.81,
    "DEC": 4.95
  },
  {
    "LONG": -49.33,
    "LAT": -25.99,
    "CIDADE": "Agudos do Sul",
    "UF": "PARANÁ",
    "anual": 4.35,
    "JAN": 4.82,
    "FEB": 4.87,
    "MAR": 4.73,
    "APR": 4.3,
    "MAY": 3.77,
    "JUN": 3.48,
    "JUL": 3.69,
    "AUG": 4.58,
    "SEP": 4.07,
    "OCT": 4.25,
    "NOV": 4.74,
    "DEC": 4.9
  },
  {
    "LONG": -5.37,
    "LAT": -25.88,
    "CIDADE": "Bela Vista da Caroba",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.65,
    "FEB": 5.62,
    "MAR": 5.59,
    "APR": 5.02,
    "MAY": 4.25,
    "JUN": 3.82,
    "JUL": 4.12,
    "AUG": 5.01,
    "SEP": 4.67,
    "OCT": 5.18,
    "NOV": 5.58,
    "DEC": 5.6
  },
  {
    "LONG": -53.47,
    "LAT": -25.92,
    "CIDADE": "Ampére",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.66,
    "FEB": 5.55,
    "MAR": 5.53,
    "APR": 5.01,
    "MAY": 4.25,
    "JUN": 3.87,
    "JUL": 4.13,
    "AUG": 5.04,
    "SEP": 4.67,
    "OCT": 5.15,
    "NOV": 5.53,
    "DEC": 5.6
  },
  {
    "LONG": -53.27,
    "LAT": -25.91,
    "CIDADE": "Nova Esperança do Sudoeste",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.56,
    "FEB": 5.49,
    "MAR": 5.55,
    "APR": 5.04,
    "MAY": 4.24,
    "JUN": 3.84,
    "JUL": 4.16,
    "AUG": 5.08,
    "SEP": 4.7,
    "OCT": 5.17,
    "NOV": 5.56,
    "DEC": 5.6
  },
  {
    "LONG": -53.17,
    "LAT": -2.59,
    "CIDADE": "Enéas Marques",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.55,
    "FEB": 5.48,
    "MAR": 5.54,
    "APR": 5.04,
    "MAY": 4.24,
    "JUN": 3.83,
    "JUL": 4.16,
    "AUG": 5.07,
    "SEP": 4.69,
    "OCT": 5.15,
    "NOV": 5.56,
    "DEC": 5.58
  },
  {
    "LONG": -52.91,
    "LAT": -25.88,
    "CIDADE": "Verê",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.57,
    "FEB": 5.51,
    "MAR": 5.58,
    "APR": 5.08,
    "MAY": 4.24,
    "JUN": 3.81,
    "JUL": 4.14,
    "AUG": 5.08,
    "SEP": 4.7,
    "OCT": 5.15,
    "NOV": 5.63,
    "DEC": 5.6
  },
  {
    "LONG": -52.52,
    "LAT": -2.59,
    "CIDADE": "Chopinzinho",
    "UF": "PARANÁ",
    "anual": 4.97,
    "JAN": 5.53,
    "FEB": 5.42,
    "MAR": 5.5,
    "APR": 5.03,
    "MAY": 4.24,
    "JUN": 3.86,
    "JUL": 4.14,
    "AUG": 5.07,
    "SEP": 4.66,
    "OCT": 5.14,
    "NOV": 5.54,
    "DEC": 5.47
  },
  {
    "LONG": -52.17,
    "LAT": -25.94,
    "CIDADE": "Mangueirinha",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.49,
    "FEB": 5.43,
    "MAR": 5.53,
    "APR": 5.02,
    "MAY": 4.2,
    "JUN": 3.84,
    "JUL": 4.09,
    "AUG": 5.07,
    "SEP": 4.65,
    "OCT": 5.12,
    "NOV": 5.55,
    "DEC": 5.51
  },
  {
    "LONG": -50.82,
    "LAT": -25.88,
    "CIDADE": "Mallet",
    "UF": "PARANÁ",
    "anual": 4.29,
    "JAN": 4.71,
    "FEB": 4.76,
    "MAR": 4.68,
    "APR": 4.14,
    "MAY": 3.62,
    "JUN": 3.31,
    "JUL": 3.55,
    "AUG": 4.57,
    "SEP": 4.15,
    "OCT": 4.24,
    "NOV": 4.87,
    "DEC": 4.84
  },
  {
    "LONG": -50.38,
    "LAT": -25.87,
    "CIDADE": "São Mateus do Sul",
    "UF": "PARANÁ",
    "anual": 4.34,
    "JAN": 4.84,
    "FEB": 4.86,
    "MAR": 4.75,
    "APR": 4.15,
    "MAY": 3.66,
    "JUN": 3.31,
    "JUL": 3.57,
    "AUG": 4.58,
    "SEP": 4.2,
    "OCT": 4.39,
    "NOV": 4.92,
    "DEC": 4.9
  },
  {
    "LONG": -49.5,
    "LAT": -25.87,
    "CIDADE": "Quitandinha",
    "UF": "PARANÁ",
    "anual": 4.38,
    "JAN": 4.86,
    "FEB": 4.9,
    "MAR": 4.72,
    "APR": 4.31,
    "MAY": 3.77,
    "JUN": 3.48,
    "JUL": 3.7,
    "AUG": 4.62,
    "SEP": 4.12,
    "OCT": 4.29,
    "NOV": 4.82,
    "DEC": 4.92
  },
  {
    "LONG": -49.2,
    "LAT": -25.93,
    "CIDADE": "Tijucas do Sul",
    "UF": "PARANÁ",
    "anual": 4.32,
    "JAN": 4.77,
    "FEB": 4.85,
    "MAR": 4.65,
    "APR": 4.27,
    "MAY": 3.8,
    "JUN": 3.52,
    "JUL": 3.65,
    "AUG": 4.57,
    "SEP": 4.04,
    "OCT": 4.19,
    "NOV": 4.69,
    "DEC": 4.81
  },
  {
    "LONG": -48.58,
    "LAT": -25.88,
    "CIDADE": "Guaratuba",
    "UF": "PARANÁ",
    "anual": 4.15,
    "JAN": 4.87,
    "FEB": 5.02,
    "MAR": 4.71,
    "APR": 4.28,
    "MAY": 3.8,
    "JUN": 3.28,
    "JUL": 3.25,
    "AUG": 3.86,
    "SEP": 3.57,
    "OCT": 3.86,
    "NOV": 4.55,
    "DEC": 4.75
  },
  {
    "LONG": -53.75,
    "LAT": -25.82,
    "CIDADE": "Pérola d'Oeste",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.64,
    "FEB": 5.63,
    "MAR": 5.6,
    "APR": 5.05,
    "MAY": 4.25,
    "JUN": 3.82,
    "JUL": 4.11,
    "AUG": 5.01,
    "SEP": 4.66,
    "OCT": 5.18,
    "NOV": 5.58,
    "DEC": 5.62
  },
  {
    "LONG": -53.53,
    "LAT": -25.77,
    "CIDADE": "Realeza",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.67,
    "FEB": 5.61,
    "MAR": 5.62,
    "APR": 5.03,
    "MAY": 4.28,
    "JUN": 3.84,
    "JUL": 4.11,
    "AUG": 5.02,
    "SEP": 4.66,
    "OCT": 5.2,
    "NOV": 5.58,
    "DEC": 5.63
  },
  {
    "LONG": -53.48,
    "LAT": -25.82,
    "CIDADE": "Santa Izabel do Oeste",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.66,
    "FEB": 5.59,
    "MAR": 5.56,
    "APR": 5.03,
    "MAY": 4.26,
    "JUN": 3.86,
    "JUL": 4.13,
    "AUG": 5.05,
    "SEP": 4.68,
    "OCT": 5.19,
    "NOV": 5.56,
    "DEC": 5.63
  },
  {
    "LONG": -5.33,
    "LAT": -25.78,
    "CIDADE": "Salto do Lontra",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.64,
    "FEB": 5.58,
    "MAR": 5.56,
    "APR": 5.07,
    "MAY": 4.26,
    "JUN": 3.86,
    "JUL": 4.12,
    "AUG": 5.07,
    "SEP": 4.7,
    "OCT": 5.21,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -52.73,
    "LAT": -25.82,
    "CIDADE": "São João",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.61,
    "FEB": 5.51,
    "MAR": 5.56,
    "APR": 5.04,
    "MAY": 4.25,
    "JUN": 3.87,
    "JUL": 4.16,
    "AUG": 5.07,
    "SEP": 4.71,
    "OCT": 5.16,
    "NOV": 5.58,
    "DEC": 5.55
  },
  {
    "LONG": -52.03,
    "LAT": -25.83,
    "CIDADE": "Reserva do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.46,
    "FEB": 5.33,
    "MAR": 5.5,
    "APR": 5.01,
    "MAY": 4.22,
    "JUN": 3.87,
    "JUL": 4.1,
    "AUG": 5.08,
    "SEP": 4.66,
    "OCT": 5.1,
    "NOV": 5.45,
    "DEC": 5.44
  },
  {
    "LONG": -49.72,
    "LAT": -25.77,
    "CIDADE": "Lapa",
    "UF": "PARANÁ",
    "anual": 4.42,
    "JAN": 4.8,
    "FEB": 4.91,
    "MAR": 4.76,
    "APR": 4.35,
    "MAY": 3.8,
    "JUN": 3.54,
    "JUL": 3.73,
    "AUG": 4.74,
    "SEP": 4.22,
    "OCT": 4.38,
    "NOV": 4.89,
    "DEC": 4.96
  },
  {
    "LONG": -49.33,
    "LAT": -25.78,
    "CIDADE": "Mandirituba",
    "UF": "PARANÁ",
    "anual": 4.38,
    "JAN": 4.83,
    "FEB": 4.88,
    "MAR": 4.71,
    "APR": 4.33,
    "MAY": 3.79,
    "JUN": 3.52,
    "JUL": 3.7,
    "AUG": 4.64,
    "SEP": 4.12,
    "OCT": 4.28,
    "NOV": 4.79,
    "DEC": 4.93
  },
  {
    "LONG": -48.55,
    "LAT": -25.82,
    "CIDADE": "Matinhos",
    "UF": "PARANÁ",
    "anual": 4.02,
    "JAN": 4.74,
    "FEB": 4.85,
    "MAR": 4.55,
    "APR": 4.16,
    "MAY": 3.71,
    "JUN": 3.23,
    "JUL": 3.22,
    "AUG": 3.8,
    "SEP": 3.41,
    "OCT": 3.64,
    "NOV": 4.36,
    "DEC": 4.57
  },
  {
    "LONG": -5.38,
    "LAT": -25.67,
    "CIDADE": "Capanema",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.62,
    "FEB": 5.58,
    "MAR": 5.63,
    "APR": 5.03,
    "MAY": 4.21,
    "JUN": 3.77,
    "JUL": 4.04,
    "AUG": 4.98,
    "SEP": 4.65,
    "OCT": 5.18,
    "NOV": 5.55,
    "DEC": 5.62
  },
  {
    "LONG": -53.76,
    "LAT": -25.72,
    "CIDADE": "Planalto",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.62,
    "FEB": 5.6,
    "MAR": 5.61,
    "APR": 5.03,
    "MAY": 4.22,
    "JUN": 3.79,
    "JUL": 4.07,
    "AUG": 5,
    "SEP": 4.65,
    "OCT": 5.18,
    "NOV": 5.55,
    "DEC": 5.62
  },
  {
    "LONG": -53.06,
    "LAT": -25.74,
    "CIDADE": "Dois Vizinhos",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.64,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.06,
    "MAY": 4.22,
    "JUN": 3.81,
    "JUL": 4.1,
    "AUG": 5.05,
    "SEP": 4.69,
    "OCT": 5.17,
    "NOV": 5.6,
    "DEC": 5.6
  },
  {
    "LONG": -52.91,
    "LAT": -25.7,
    "CIDADE": "São Jorge d'Oeste",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.57,
    "FEB": 5.47,
    "MAR": 5.55,
    "APR": 5.07,
    "MAY": 4.22,
    "JUN": 3.81,
    "JUL": 4.06,
    "AUG": 5.03,
    "SEP": 4.7,
    "OCT": 5.16,
    "NOV": 5.59,
    "DEC": 5.56
  },
  {
    "LONG": -52.73,
    "LAT": -2.57,
    "CIDADE": "Sulina",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.51,
    "FEB": 5.49,
    "MAR": 5.54,
    "APR": 5.07,
    "MAY": 4.26,
    "JUN": 3.88,
    "JUL": 4.1,
    "AUG": 5.05,
    "SEP": 4.71,
    "OCT": 5.18,
    "NOV": 5.6,
    "DEC": 5.53
  },
  {
    "LONG": -52.62,
    "LAT": -25.69,
    "CIDADE": "Saudade do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.49,
    "FEB": 5.45,
    "MAR": 5.58,
    "APR": 5.07,
    "MAY": 4.25,
    "JUN": 3.88,
    "JUL": 4.09,
    "AUG": 5.06,
    "SEP": 4.7,
    "OCT": 5.17,
    "NOV": 5.57,
    "DEC": 5.49
  },
  {
    "LONG": -52.12,
    "LAT": -25.74,
    "CIDADE": "Foz do Jordão",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.45,
    "FEB": 5.34,
    "MAR": 5.5,
    "APR": 5.05,
    "MAY": 4.24,
    "JUN": 3.85,
    "JUL": 4.1,
    "AUG": 5.08,
    "SEP": 4.68,
    "OCT": 5.12,
    "NOV": 5.51,
    "DEC": 5.5
  },
  {
    "LONG": -5.17,
    "LAT": -25.69,
    "CIDADE": "Pinhão",
    "UF": "PARANÁ",
    "anual": 4.84,
    "JAN": 5.26,
    "FEB": 5.17,
    "MAR": 5.31,
    "APR": 4.81,
    "MAY": 4.12,
    "JUN": 3.86,
    "JUL": 4.1,
    "AUG": 5.07,
    "SEP": 4.69,
    "OCT": 4.99,
    "NOV": 5.32,
    "DEC": 5.34
  },
  {
    "LONG": -5.08,
    "LAT": -25.73,
    "CIDADE": "Rio Azul",
    "UF": "PARANÁ",
    "anual": 4.4,
    "JAN": 4.88,
    "FEB": 4.94,
    "MAR": 4.74,
    "APR": 4.27,
    "MAY": 3.72,
    "JUN": 3.38,
    "JUL": 3.71,
    "AUG": 4.68,
    "SEP": 4.25,
    "OCT": 4.39,
    "NOV": 4.97,
    "DEC": 4.92
  },
  {
    "LONG": -50.3,
    "LAT": -25.68,
    "CIDADE": "São João do Triunfo",
    "UF": "PARANÁ",
    "anual": 4.43,
    "JAN": 4.85,
    "FEB": 4.88,
    "MAR": 4.78,
    "APR": 4.31,
    "MAY": 3.76,
    "JUN": 3.52,
    "JUL": 3.7,
    "AUG": 4.72,
    "SEP": 4.27,
    "OCT": 4.43,
    "NOV": 4.96,
    "DEC": 4.94
  },
  {
    "LONG": -49.54,
    "LAT": -25.68,
    "CIDADE": "Contenda",
    "UF": "PARANÁ",
    "anual": 4.42,
    "JAN": 4.83,
    "FEB": 4.93,
    "MAR": 4.73,
    "APR": 4.35,
    "MAY": 3.81,
    "JUN": 3.59,
    "JUL": 3.74,
    "AUG": 4.7,
    "SEP": 4.2,
    "OCT": 4.35,
    "NOV": 4.86,
    "DEC": 4.94
  },
  {
    "LONG": -49.31,
    "LAT": -25.66,
    "CIDADE": "Fazenda Rio Grande",
    "UF": "PARANÁ",
    "anual": 4.39,
    "JAN": 4.82,
    "FEB": 4.95,
    "MAR": 4.72,
    "APR": 4.33,
    "MAY": 3.77,
    "JUN": 3.55,
    "JUL": 3.69,
    "AUG": 4.65,
    "SEP": 4.13,
    "OCT": 4.28,
    "NOV": 4.8,
    "DEC": 4.94
  },
  {
    "LONG": -48.51,
    "LAT": -2.57,
    "CIDADE": "Pontal do Paraná",
    "UF": "PARANÁ",
    "anual": 3.97,
    "JAN": 4.6,
    "FEB": 4.82,
    "MAR": 4.46,
    "APR": 4.08,
    "MAY": 3.7,
    "JUN": 3.24,
    "JUL": 3.22,
    "AUG": 3.83,
    "SEP": 3.37,
    "OCT": 3.59,
    "NOV": 4.26,
    "DEC": 4.53
  },
  {
    "LONG": -53.35,
    "LAT": -25.63,
    "CIDADE": "Nova Prata do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.62,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.02,
    "MAY": 4.2,
    "JUN": 3.81,
    "JUL": 4.05,
    "AUG": 5.02,
    "SEP": 4.62,
    "OCT": 5.18,
    "NOV": 5.56,
    "DEC": 5.61
  },
  {
    "LONG": -53.21,
    "LAT": -25.63,
    "CIDADE": "Boa Esperança do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.63,
    "FEB": 5.53,
    "MAR": 5.59,
    "APR": 5.04,
    "MAY": 4.19,
    "JUN": 3.81,
    "JUL": 4.06,
    "AUG": 5.01,
    "SEP": 4.63,
    "OCT": 5.17,
    "NOV": 5.55,
    "DEC": 5.6
  },
  {
    "LONG": -53.13,
    "LAT": -25.62,
    "CIDADE": "Cruzeiro do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.59,
    "FEB": 5.52,
    "MAR": 5.59,
    "APR": 5.06,
    "MAY": 4.2,
    "JUN": 3.8,
    "JUL": 4.06,
    "AUG": 5.01,
    "SEP": 4.64,
    "OCT": 5.17,
    "NOV": 5.56,
    "DEC": 5.6
  },
  {
    "LONG": -52.04,
    "LAT": -25.58,
    "CIDADE": "Candói",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.38,
    "FEB": 5.31,
    "MAR": 5.44,
    "APR": 5.01,
    "MAY": 4.22,
    "JUN": 3.88,
    "JUL": 4.11,
    "AUG": 5.1,
    "SEP": 4.7,
    "OCT": 5.09,
    "NOV": 5.45,
    "DEC": 5.44
  },
  {
    "LONG": -51.08,
    "LAT": -25.57,
    "CIDADE": "Inácio Martins",
    "UF": "PARANÁ",
    "anual": 4.46,
    "JAN": 4.8,
    "FEB": 4.84,
    "MAR": 4.75,
    "APR": 4.37,
    "MAY": 3.8,
    "JUN": 3.56,
    "JUL": 3.82,
    "AUG": 4.85,
    "SEP": 4.38,
    "OCT": 4.48,
    "NOV": 4.99,
    "DEC": 4.92
  },
  {
    "LONG": -50.69,
    "LAT": -25.62,
    "CIDADE": "Rebouças",
    "UF": "PARANÁ",
    "anual": 4.46,
    "JAN": 4.93,
    "FEB": 4.94,
    "MAR": 4.77,
    "APR": 4.35,
    "MAY": 3.81,
    "JUN": 3.43,
    "JUL": 3.76,
    "AUG": 4.76,
    "SEP": 4.31,
    "OCT": 4.48,
    "NOV": 5.04,
    "DEC": 4.96
  },
  {
    "LONG": -49.63,
    "LAT": -25.58,
    "CIDADE": "Balsa Nova",
    "UF": "PARANÁ",
    "anual": 4.47,
    "JAN": 4.85,
    "FEB": 4.96,
    "MAR": 4.81,
    "APR": 4.42,
    "MAY": 3.85,
    "JUN": 3.64,
    "JUL": 3.81,
    "AUG": 4.79,
    "SEP": 4.26,
    "OCT": 4.41,
    "NOV": 4.9,
    "DEC": 4.97
  },
  {
    "LONG": -49.41,
    "LAT": -25.59,
    "CIDADE": "Araucária",
    "UF": "PARANÁ",
    "anual": 4.41,
    "JAN": 4.84,
    "FEB": 4.92,
    "MAR": 4.71,
    "APR": 4.37,
    "MAY": 3.8,
    "JUN": 3.57,
    "JUL": 3.73,
    "AUG": 4.69,
    "SEP": 4.18,
    "OCT": 4.34,
    "NOV": 4.85,
    "DEC": 4.95
  },
  {
    "LONG": -54.58,
    "LAT": -25.54,
    "CIDADE": "Foz do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.69,
    "FEB": 5.65,
    "MAR": 5.67,
    "APR": 4.95,
    "MAY": 4.19,
    "JUN": 3.74,
    "JUL": 3.97,
    "AUG": 4.87,
    "SEP": 4.67,
    "OCT": 5.2,
    "NOV": 5.58,
    "DEC": 5.73
  },
  {
    "LONG": -53.61,
    "LAT": -25.48,
    "CIDADE": "Capitão Leônidas Marques",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.48,
    "FEB": 5.46,
    "MAR": 5.54,
    "APR": 4.98,
    "MAY": 4.21,
    "JUN": 3.8,
    "JUL": 4.01,
    "AUG": 4.99,
    "SEP": 4.63,
    "OCT": 5.15,
    "NOV": 5.5,
    "DEC": 5.49
  },
  {
    "LONG": -52.53,
    "LAT": -25.49,
    "CIDADE": "Rio Bonito do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.4,
    "FEB": 5.34,
    "MAR": 5.47,
    "APR": 5.04,
    "MAY": 4.23,
    "JUN": 3.9,
    "JUL": 4.14,
    "AUG": 5.06,
    "SEP": 4.7,
    "OCT": 5.09,
    "NOV": 5.49,
    "DEC": 5.4
  },
  {
    "LONG": -52.41,
    "LAT": -25.55,
    "CIDADE": "Porto Barreiro",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.4,
    "FEB": 5.34,
    "MAR": 5.45,
    "APR": 5.02,
    "MAY": 4.23,
    "JUN": 3.91,
    "JUL": 4.17,
    "AUG": 5.09,
    "SEP": 4.73,
    "OCT": 5.08,
    "NOV": 5.48,
    "DEC": 5.4
  },
  {
    "LONG": -50.65,
    "LAT": -25.47,
    "CIDADE": "Irati",
    "UF": "PARANÁ",
    "anual": 4.52,
    "JAN": 4.97,
    "FEB": 5.02,
    "MAR": 4.81,
    "APR": 4.41,
    "MAY": 3.86,
    "JUN": 3.53,
    "JUL": 3.82,
    "AUG": 4.82,
    "SEP": 4.4,
    "OCT": 4.54,
    "NOV": 5.09,
    "DEC": 5.02
  },
  {
    "LONG": -4.99,
    "LAT": -25.54,
    "CIDADE": "Porto Amazonas",
    "UF": "PARANÁ",
    "anual": 4.53,
    "JAN": 4.89,
    "FEB": 5.02,
    "MAR": 4.9,
    "APR": 4.5,
    "MAY": 3.86,
    "JUN": 3.71,
    "JUL": 3.87,
    "AUG": 4.86,
    "SEP": 4.36,
    "OCT": 4.5,
    "NOV": 4.92,
    "DEC": 5.01
  },
  {
    "LONG": -49.53,
    "LAT": -2.55,
    "CIDADE": "Campo Largo",
    "UF": "PARANÁ",
    "anual": 4.4,
    "JAN": 4.77,
    "FEB": 4.94,
    "MAR": 4.75,
    "APR": 4.37,
    "MAY": 3.81,
    "JUN": 3.52,
    "JUL": 3.74,
    "AUG": 4.71,
    "SEP": 4.19,
    "OCT": 4.31,
    "NOV": 4.84,
    "DEC": 4.89
  },
  {
    "LONG": -49.2,
    "LAT": -25.53,
    "CIDADE": "São José dos Pinhais",
    "UF": "PARANÁ",
    "anual": 4.42,
    "JAN": 4.79,
    "FEB": 4.94,
    "MAR": 4.71,
    "APR": 4.4,
    "MAY": 3.87,
    "JUN": 3.64,
    "JUL": 3.76,
    "AUG": 4.71,
    "SEP": 4.17,
    "OCT": 4.33,
    "NOV": 4.82,
    "DEC": 4.91
  },
  {
    "LONG": -4.88,
    "LAT": -25.47,
    "CIDADE": "Morretes",
    "UF": "PARANÁ",
    "anual": 3.97,
    "JAN": 4.5,
    "FEB": 4.65,
    "MAR": 4.37,
    "APR": 4.08,
    "MAY": 3.68,
    "JUN": 3.36,
    "JUL": 3.37,
    "AUG": 4.03,
    "SEP": 3.41,
    "OCT": 3.58,
    "NOV": 4.21,
    "DEC": 4.45
  },
  {
    "LONG": -48.52,
    "LAT": -25.52,
    "CIDADE": "Paranaguá",
    "UF": "PARANÁ",
    "anual": 4.08,
    "JAN": 4.76,
    "FEB": 4.97,
    "MAR": 4.58,
    "APR": 4.21,
    "MAY": 3.76,
    "JUN": 3.33,
    "JUL": 3.26,
    "AUG": 3.89,
    "SEP": 3.45,
    "OCT": 3.67,
    "NOV": 4.4,
    "DEC": 4.69
  },
  {
    "LONG": -54.4,
    "LAT": -25.43,
    "CIDADE": "Santa Terezinha de Itaipu",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.77,
    "FEB": 5.68,
    "MAR": 5.68,
    "APR": 4.98,
    "MAY": 4.21,
    "JUN": 3.77,
    "JUL": 4,
    "AUG": 4.89,
    "SEP": 4.65,
    "OCT": 5.18,
    "NOV": 5.57,
    "DEC": 5.8
  },
  {
    "LONG": -54.05,
    "LAT": -25.38,
    "CIDADE": "Serranópolis do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.53,
    "FEB": 5.45,
    "MAR": 5.48,
    "APR": 4.98,
    "MAY": 4.17,
    "JUN": 3.78,
    "JUL": 4.03,
    "AUG": 4.96,
    "SEP": 4.69,
    "OCT": 5.13,
    "NOV": 5.46,
    "DEC": 5.6
  },
  {
    "LONG": -53.56,
    "LAT": -25.41,
    "CIDADE": "Santa Lúcia",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.43,
    "FEB": 5.39,
    "MAR": 5.45,
    "APR": 4.99,
    "MAY": 4.21,
    "JUN": 3.82,
    "JUL": 4.04,
    "AUG": 5.07,
    "SEP": 4.66,
    "OCT": 5.14,
    "NOV": 5.47,
    "DEC": 5.48
  },
  {
    "LONG": -53.41,
    "LAT": -25.43,
    "CIDADE": "Boa Vista da Aparecida",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.45,
    "FEB": 5.41,
    "MAR": 5.48,
    "APR": 4.99,
    "MAY": 4.21,
    "JUN": 3.84,
    "JUL": 4.06,
    "AUG": 5.06,
    "SEP": 4.67,
    "OCT": 5.13,
    "NOV": 5.49,
    "DEC": 5.49
  },
  {
    "LONG": -53.18,
    "LAT": -2.54,
    "CIDADE": "Três Barras do Paraná",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.52,
    "FEB": 5.37,
    "MAR": 5.49,
    "APR": 5.02,
    "MAY": 4.23,
    "JUN": 3.85,
    "JUL": 4.07,
    "AUG": 5.07,
    "SEP": 4.67,
    "OCT": 5.09,
    "NOV": 5.47,
    "DEC": 5.47
  },
  {
    "LONG": -52.91,
    "LAT": -25.45,
    "CIDADE": "Quedas do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.97,
    "JAN": 5.55,
    "FEB": 5.4,
    "MAR": 5.5,
    "APR": 5.07,
    "MAY": 4.23,
    "JUN": 3.88,
    "JUL": 4.11,
    "AUG": 5.09,
    "SEP": 4.71,
    "OCT": 5.12,
    "NOV": 5.51,
    "DEC": 5.49
  },
  {
    "LONG": -52.84,
    "LAT": -25.42,
    "CIDADE": "Espigão Alto do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.52,
    "FEB": 5.37,
    "MAR": 5.46,
    "APR": 5.05,
    "MAY": 4.24,
    "JUN": 3.9,
    "JUL": 4.13,
    "AUG": 5.07,
    "SEP": 4.71,
    "OCT": 5.1,
    "NOV": 5.49,
    "DEC": 5.47
  },
  {
    "LONG": -52.41,
    "LAT": -25.41,
    "CIDADE": "Laranjeiras do Sul",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.42,
    "FEB": 5.34,
    "MAR": 5.47,
    "APR": 5.03,
    "MAY": 4.25,
    "JUN": 3.97,
    "JUL": 4.21,
    "AUG": 5.14,
    "SEP": 4.75,
    "OCT": 5.1,
    "NOV": 5.48,
    "DEC": 5.4
  },
  {
    "LONG": -52.2,
    "LAT": -25.38,
    "CIDADE": "Virmond",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.34,
    "FEB": 5.28,
    "MAR": 5.41,
    "APR": 5.04,
    "MAY": 4.25,
    "JUN": 3.92,
    "JUL": 4.18,
    "AUG": 5.11,
    "SEP": 4.75,
    "OCT": 5.06,
    "NOV": 5.45,
    "DEC": 5.39
  },
  {
    "LONG": -52.12,
    "LAT": -25.37,
    "CIDADE": "Cantagalo",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.34,
    "FEB": 5.3,
    "MAR": 5.42,
    "APR": 5.06,
    "MAY": 4.27,
    "JUN": 3.91,
    "JUL": 4.17,
    "AUG": 5.12,
    "SEP": 4.75,
    "OCT": 5.06,
    "NOV": 5.47,
    "DEC": 5.38
  },
  {
    "LONG": -51.46,
    "LAT": -25.39,
    "CIDADE": "Guarapuava",
    "UF": "PARANÁ",
    "anual": 4.79,
    "JAN": 5.15,
    "FEB": 5.1,
    "MAR": 5.12,
    "APR": 4.83,
    "MAY": 4.11,
    "JUN": 3.82,
    "JUL": 4.12,
    "AUG": 5.05,
    "SEP": 4.68,
    "OCT": 4.9,
    "NOV": 5.31,
    "DEC": 5.25
  },
  {
    "LONG": -5.05,
    "LAT": -25.41,
    "CIDADE": "Fernandes Pinheiro",
    "UF": "PARANÁ",
    "anual": 4.55,
    "JAN": 4.95,
    "FEB": 5.02,
    "MAR": 4.86,
    "APR": 4.51,
    "MAY": 3.9,
    "JUN": 3.56,
    "JUL": 3.86,
    "AUG": 4.85,
    "SEP": 4.42,
    "OCT": 4.57,
    "NOV": 5.08,
    "DEC": 5.02
  },
  {
    "LONG": -50.46,
    "LAT": -25.37,
    "CIDADE": "Teixeira Soares",
    "UF": "PARANÁ",
    "anual": 4.57,
    "JAN": 4.99,
    "FEB": 5.03,
    "MAR": 4.91,
    "APR": 4.58,
    "MAY": 3.94,
    "JUN": 3.59,
    "JUL": 3.88,
    "AUG": 4.86,
    "SEP": 4.42,
    "OCT": 4.59,
    "NOV": 5.09,
    "DEC": 5.02
  },
  {
    "LONG": -50.01,
    "LAT": -25.43,
    "CIDADE": "Palmeira",
    "UF": "PARANÁ",
    "anual": 4.6,
    "JAN": 4.98,
    "FEB": 5.1,
    "MAR": 4.92,
    "APR": 4.57,
    "MAY": 3.95,
    "JUN": 3.71,
    "JUL": 3.91,
    "AUG": 4.9,
    "SEP": 4.43,
    "OCT": 4.58,
    "NOV": 5.04,
    "DEC": 5.08
  },
  {
    "LONG": -49.45,
    "LAT": -25.37,
    "CIDADE": "Campo Magro",
    "UF": "PARANÁ",
    "anual": 4.42,
    "JAN": 4.76,
    "FEB": 4.98,
    "MAR": 4.8,
    "APR": 4.4,
    "MAY": 3.8,
    "JUN": 3.54,
    "JUL": 3.74,
    "AUG": 4.74,
    "SEP": 4.21,
    "OCT": 4.34,
    "NOV": 4.86,
    "DEC": 4.94
  },
  {
    "LONG": -49.27,
    "LAT": -25.42,
    "CIDADE": "Curitiba",
    "UF": "PARANÁ",
    "anual": 4.41,
    "JAN": 4.78,
    "FEB": 4.94,
    "MAR": 4.75,
    "APR": 4.38,
    "MAY": 3.83,
    "JUN": 3.65,
    "JUL": 3.75,
    "AUG": 4.72,
    "SEP": 4.15,
    "OCT": 4.27,
    "NOV": 4.79,
    "DEC": 4.91
  },
  {
    "LONG": -49.19,
    "LAT": -25.44,
    "CIDADE": "Pinhais",
    "UF": "PARANÁ",
    "anual": 4.39,
    "JAN": 4.76,
    "FEB": 4.93,
    "MAR": 4.71,
    "APR": 4.37,
    "MAY": 3.82,
    "JUN": 3.6,
    "JUL": 3.75,
    "AUG": 4.67,
    "SEP": 4.11,
    "OCT": 4.25,
    "NOV": 4.77,
    "DEC": 4.91
  },
  {
    "LONG": -49.08,
    "LAT": -25.37,
    "CIDADE": "Quatro Barras",
    "UF": "PARANÁ",
    "anual": 4.37,
    "JAN": 4.78,
    "FEB": 4.93,
    "MAR": 4.7,
    "APR": 4.32,
    "MAY": 3.82,
    "JUN": 3.57,
    "JUL": 3.73,
    "AUG": 4.62,
    "SEP": 4.1,
    "OCT": 4.27,
    "NOV": 4.76,
    "DEC": 4.9
  },
  {
    "LONG": -49.06,
    "LAT": -25.44,
    "CIDADE": "Piraquara",
    "UF": "PARANÁ",
    "anual": 4.37,
    "JAN": 4.78,
    "FEB": 4.93,
    "MAR": 4.7,
    "APR": 4.32,
    "MAY": 3.82,
    "JUN": 3.57,
    "JUL": 3.73,
    "AUG": 4.62,
    "SEP": 4.1,
    "OCT": 4.27,
    "NOV": 4.76,
    "DEC": 4.9
  },
  {
    "LONG": -48.72,
    "LAT": -25.44,
    "CIDADE": "Antonina",
    "UF": "PARANÁ",
    "anual": 4.01,
    "JAN": 4.54,
    "FEB": 4.72,
    "MAR": 4.48,
    "APR": 4.17,
    "MAY": 3.72,
    "JUN": 3.38,
    "JUL": 3.29,
    "AUG": 3.98,
    "SEP": 3.43,
    "OCT": 3.59,
    "NOV": 4.27,
    "DEC": 4.53
  },
  {
    "LONG": -5.42,
    "LAT": -25.35,
    "CIDADE": "São Miguel do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.63,
    "FEB": 5.53,
    "MAR": 5.6,
    "APR": 5,
    "MAY": 4.19,
    "JUN": 3.79,
    "JUL": 4,
    "AUG": 4.92,
    "SEP": 4.69,
    "OCT": 5.14,
    "NOV": 5.54,
    "DEC": 5.73
  },
  {
    "LONG": -54.09,
    "LAT": -25.3,
    "CIDADE": "Medianeira",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.53,
    "FEB": 5.48,
    "MAR": 5.46,
    "APR": 4.95,
    "MAY": 4.16,
    "JUN": 3.82,
    "JUL": 4.05,
    "AUG": 4.98,
    "SEP": 4.71,
    "OCT": 5.14,
    "NOV": 5.45,
    "DEC": 5.58
  },
  {
    "LONG": -53.57,
    "LAT": -25.26,
    "CIDADE": "Lindoeste",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.42,
    "FEB": 5.36,
    "MAR": 5.4,
    "APR": 4.97,
    "MAY": 4.2,
    "JUN": 3.84,
    "JUL": 4.09,
    "AUG": 5.09,
    "SEP": 4.7,
    "OCT": 5.1,
    "NOV": 5.42,
    "DEC": 5.45
  },
  {
    "LONG": -52.55,
    "LAT": -25.31,
    "CIDADE": "Nova Laranjeiras",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.44,
    "FEB": 5.28,
    "MAR": 5.47,
    "APR": 5.07,
    "MAY": 4.23,
    "JUN": 3.94,
    "JUL": 4.18,
    "AUG": 5.15,
    "SEP": 4.74,
    "OCT": 5.12,
    "NOV": 5.46,
    "DEC": 5.42
  },
  {
    "LONG": -49.3,
    "LAT": -25.32,
    "CIDADE": "Almirante Tamandaré",
    "UF": "PARANÁ",
    "anual": 4.45,
    "JAN": 4.77,
    "FEB": 4.99,
    "MAR": 4.84,
    "APR": 4.41,
    "MAY": 3.81,
    "JUN": 3.61,
    "JUL": 3.79,
    "AUG": 4.78,
    "SEP": 4.24,
    "OCT": 4.35,
    "NOV": 4.85,
    "DEC": 4.97
  },
  {
    "LONG": -49.23,
    "LAT": -2.53,
    "CIDADE": "Colombo",
    "UF": "PARANÁ",
    "anual": 4.41,
    "JAN": 4.72,
    "FEB": 4.97,
    "MAR": 4.77,
    "APR": 4.37,
    "MAY": 3.8,
    "JUN": 3.64,
    "JUL": 3.76,
    "AUG": 4.73,
    "SEP": 4.17,
    "OCT": 4.26,
    "NOV": 4.8,
    "DEC": 4.91
  },
  {
    "LONG": -49.06,
    "LAT": -25.3,
    "CIDADE": "Campina Grande do Sul",
    "UF": "PARANÁ",
    "anual": 4.39,
    "JAN": 4.77,
    "FEB": 4.95,
    "MAR": 4.75,
    "APR": 4.32,
    "MAY": 3.83,
    "JUN": 3.59,
    "JUL": 3.75,
    "AUG": 4.66,
    "SEP": 4.09,
    "OCT": 4.24,
    "NOV": 4.76,
    "DEC": 4.92
  },
  {
    "LONG": -48.32,
    "LAT": -25.31,
    "CIDADE": "Guaraqueçaba",
    "UF": "PARANÁ",
    "anual": 4.08,
    "JAN": 4.77,
    "FEB": 4.99,
    "MAR": 4.57,
    "APR": 4.23,
    "MAY": 3.75,
    "JUN": 3.36,
    "JUL": 3.23,
    "AUG": 3.88,
    "SEP": 3.51,
    "OCT": 3.6,
    "NOV": 4.37,
    "DEC": 4.72
  },
  {
    "LONG": -53.99,
    "LAT": "#VALOR!",
    "CIDADE": "Matelândia",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.5,
    "FEB": 5.49,
    "MAR": 5.42,
    "APR": 4.92,
    "MAY": 4.22,
    "JUN": 3.86,
    "JUL": 4.08,
    "AUG": 5.06,
    "SEP": 4.73,
    "OCT": 5.14,
    "NOV": 5.43,
    "DEC": 5.57
  },
  {
    "LONG": -53.16,
    "LAT": -25.2,
    "CIDADE": "Catanduvas",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.5,
    "FEB": 5.31,
    "MAR": 5.36,
    "APR": 4.99,
    "MAY": 4.24,
    "JUN": 3.93,
    "JUL": 4.17,
    "AUG": 5.15,
    "SEP": 4.74,
    "OCT": 5.05,
    "NOV": 5.36,
    "DEC": 5.44
  },
  {
    "LONG": -51.99,
    "LAT": -25.19,
    "CIDADE": "Goioxim",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.31,
    "FEB": 5.3,
    "MAR": 5.44,
    "APR": 5.06,
    "MAY": 4.27,
    "JUN": 3.97,
    "JUL": 4.26,
    "AUG": 5.16,
    "SEP": 4.78,
    "OCT": 5.11,
    "NOV": 5.41,
    "DEC": 5.36
  },
  {
    "LONG": -50.98,
    "LAT": -25.21,
    "CIDADE": "Prudentópolis",
    "UF": "PARANÁ",
    "anual": 4.68,
    "JAN": 5.12,
    "FEB": 5.16,
    "MAR": 5.02,
    "APR": 4.67,
    "MAY": 4.01,
    "JUN": 3.65,
    "JUL": 3.95,
    "AUG": 4.9,
    "SEP": 4.56,
    "OCT": 4.72,
    "NOV": 5.18,
    "DEC": 5.19
  },
  {
    "LONG": -50.8,
    "LAT": -25.19,
    "CIDADE": "Guamiranga",
    "UF": "PARANÁ",
    "anual": 4.72,
    "JAN": 5.1,
    "FEB": 5.22,
    "MAR": 5.08,
    "APR": 4.7,
    "MAY": 4.04,
    "JUN": 3.71,
    "JUL": 3.99,
    "AUG": 4.95,
    "SEP": 4.61,
    "OCT": 4.77,
    "NOV": 5.24,
    "DEC": 5.22
  },
  {
    "LONG": -50.6,
    "LAT": -2.52,
    "CIDADE": "Imbituva",
    "UF": "PARANÁ",
    "anual": 4.67,
    "JAN": 5.03,
    "FEB": 5.17,
    "MAR": 5.03,
    "APR": 4.66,
    "MAY": 3.99,
    "JUN": 3.69,
    "JUL": 3.98,
    "AUG": 4.95,
    "SEP": 4.55,
    "OCT": 4.7,
    "NOV": 5.15,
    "DEC": 5.16
  },
  {
    "LONG": -4.93,
    "LAT": -25.19,
    "CIDADE": "Rio Branco do Sul",
    "UF": "PARANÁ",
    "anual": 4.47,
    "JAN": 4.82,
    "FEB": 5.02,
    "MAR": 4.87,
    "APR": 4.46,
    "MAY": 3.85,
    "JUN": 3.58,
    "JUL": 3.8,
    "AUG": 4.77,
    "SEP": 4.26,
    "OCT": 4.38,
    "NOV": 4.88,
    "DEC": 4.94
  },
  {
    "LONG": -49.35,
    "LAT": -25.22,
    "CIDADE": "Itaperuçu",
    "UF": "PARANÁ",
    "anual": 4.47,
    "JAN": 4.82,
    "FEB": 5.02,
    "MAR": 4.87,
    "APR": 4.46,
    "MAY": 3.85,
    "JUN": 3.58,
    "JUL": 3.8,
    "AUG": 4.77,
    "SEP": 4.26,
    "OCT": 4.38,
    "NOV": 4.88,
    "DEC": 4.94
  },
  {
    "LONG": -49.11,
    "LAT": -25.21,
    "CIDADE": "Bocaiúva do Sul",
    "UF": "PARANÁ",
    "anual": 4.43,
    "JAN": 4.77,
    "FEB": 4.98,
    "MAR": 4.79,
    "APR": 4.43,
    "MAY": 3.85,
    "JUN": 3.62,
    "JUL": 3.81,
    "AUG": 4.75,
    "SEP": 4.17,
    "OCT": 4.31,
    "NOV": 4.8,
    "DEC": 4.95
  },
  {
    "LONG": -54.3,
    "LAT": -25.14,
    "CIDADE": "Itaipulândia",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.68,
    "FEB": 5.66,
    "MAR": 5.69,
    "APR": 5,
    "MAY": 4.21,
    "JUN": 3.83,
    "JUL": 4.03,
    "AUG": 4.95,
    "SEP": 4.7,
    "OCT": 5.19,
    "NOV": 5.59,
    "DEC": 5.81
  },
  {
    "LONG": -54.25,
    "LAT": -25.09,
    "CIDADE": "Missal",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.58,
    "FEB": 5.53,
    "MAR": 5.61,
    "APR": 5.01,
    "MAY": 4.21,
    "JUN": 3.86,
    "JUL": 4.04,
    "AUG": 4.98,
    "SEP": 4.75,
    "OCT": 5.16,
    "NOV": 5.56,
    "DEC": 5.73
  },
  {
    "LONG": -54.02,
    "LAT": -25.12,
    "CIDADE": "Ramilândia",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.51,
    "FEB": 5.53,
    "MAR": 5.54,
    "APR": 4.99,
    "MAY": 4.2,
    "JUN": 3.89,
    "JUL": 4.11,
    "AUG": 5.07,
    "SEP": 4.77,
    "OCT": 5.17,
    "NOV": 5.51,
    "DEC": 5.6
  },
  {
    "LONG": -53.84,
    "LAT": -25.15,
    "CIDADE": "Céu Azul",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.55,
    "FEB": 5.43,
    "MAR": 5.33,
    "APR": 4.97,
    "MAY": 4.21,
    "JUN": 3.88,
    "JUL": 4.14,
    "AUG": 5.09,
    "SEP": 4.74,
    "OCT": 5.1,
    "NOV": 5.39,
    "DEC": 5.56
  },
  {
    "LONG": -53.88,
    "LAT": -25.06,
    "CIDADE": "Vera Cruz do Oeste",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.55,
    "FEB": 5.43,
    "MAR": 5.33,
    "APR": 4.97,
    "MAY": 4.21,
    "JUN": 3.88,
    "JUL": 4.14,
    "AUG": 5.09,
    "SEP": 4.74,
    "OCT": 5.1,
    "NOV": 5.39,
    "DEC": 5.56
  },
  {
    "LONG": -53.63,
    "LAT": -25.05,
    "CIDADE": "Santa Tereza do Oeste",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.42,
    "FEB": 5.32,
    "MAR": 5.34,
    "APR": 4.95,
    "MAY": 4.19,
    "JUN": 3.93,
    "JUL": 4.14,
    "AUG": 5.12,
    "SEP": 4.73,
    "OCT": 5.07,
    "NOV": 5.37,
    "DEC": 5.49
  },
  {
    "LONG": -53.02,
    "LAT": -25.11,
    "CIDADE": "Ibema",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.5,
    "FEB": 5.28,
    "MAR": 5.39,
    "APR": 5.05,
    "MAY": 4.25,
    "JUN": 3.99,
    "JUL": 4.22,
    "AUG": 5.19,
    "SEP": 4.78,
    "OCT": 5.06,
    "NOV": 5.37,
    "DEC": 5.43
  },
  {
    "LONG": -5.29,
    "LAT": -25.1,
    "CIDADE": "Guaraniaçu",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.52,
    "FEB": 5.31,
    "MAR": 5.53,
    "APR": 5.09,
    "MAY": 4.29,
    "JUN": 4,
    "JUL": 4.25,
    "AUG": 5.19,
    "SEP": 4.81,
    "OCT": 5.13,
    "NOV": 5.49,
    "DEC": 5.45
  },
  {
    "LONG": -52.25,
    "LAT": -25.11,
    "CIDADE": "Marquinho",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.42,
    "FEB": 5.36,
    "MAR": 5.52,
    "APR": 5.16,
    "MAY": 4.32,
    "JUN": 4,
    "JUL": 4.26,
    "AUG": 5.21,
    "SEP": 4.82,
    "OCT": 5.14,
    "NOV": 5.5,
    "DEC": 5.46
  },
  {
    "LONG": -51.82,
    "LAT": -25.08,
    "CIDADE": "Campina do Simão",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.18,
    "FEB": 5.24,
    "MAR": 5.35,
    "APR": 5.05,
    "MAY": 4.25,
    "JUN": 3.95,
    "JUL": 4.23,
    "AUG": 5.18,
    "SEP": 4.75,
    "OCT": 5.07,
    "NOV": 5.4,
    "DEC": 5.36
  },
  {
    "LONG": -50.17,
    "LAT": -25.09,
    "CIDADE": "Ponta Grossa",
    "UF": "PARANÁ",
    "anual": 4.73,
    "JAN": 5.01,
    "FEB": 5.14,
    "MAR": 5,
    "APR": 4.78,
    "MAY": 4.14,
    "JUN": 3.86,
    "JUL": 4.04,
    "AUG": 5.01,
    "SEP": 4.63,
    "OCT": 4.77,
    "NOV": 5.16,
    "DEC": 5.17
  },
  {
    "LONG": -53.46,
    "LAT": -24.96,
    "CIDADE": "Cascavel",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.51,
    "FEB": 5.27,
    "MAR": 5.36,
    "APR": 5.01,
    "MAY": 4.26,
    "JUN": 3.97,
    "JUL": 4.18,
    "AUG": 5.16,
    "SEP": 4.77,
    "OCT": 5.09,
    "NOV": 5.4,
    "DEC": 5.5
  },
  {
    "LONG": -52.99,
    "LAT": -25.03,
    "CIDADE": "Campo Bonito",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.53,
    "FEB": 5.34,
    "MAR": 5.57,
    "APR": 5.11,
    "MAY": 4.34,
    "JUN": 4.01,
    "JUL": 4.26,
    "AUG": 5.21,
    "SEP": 4.82,
    "OCT": 5.12,
    "NOV": 5.49,
    "DEC": 5.52
  },
  {
    "LONG": -52.68,
    "LAT": -25.04,
    "CIDADE": "Diamante do Sul",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.58,
    "FEB": 5.42,
    "MAR": 5.62,
    "APR": 5.16,
    "MAY": 4.33,
    "JUN": 4.01,
    "JUL": 4.24,
    "AUG": 5.22,
    "SEP": 4.83,
    "OCT": 5.19,
    "NOV": 5.58,
    "DEC": 5.56
  },
  {
    "LONG": -51.53,
    "LAT": -25.04,
    "CIDADE": "Turvo",
    "UF": "PARANÁ",
    "anual": 4.86,
    "JAN": 5.07,
    "FEB": 5.22,
    "MAR": 5.16,
    "APR": 4.97,
    "MAY": 4.21,
    "JUN": 3.91,
    "JUL": 4.23,
    "AUG": 5.17,
    "SEP": 4.77,
    "OCT": 4.98,
    "NOV": 5.3,
    "DEC": 5.29
  },
  {
    "LONG": -50.86,
    "LAT": -25.01,
    "CIDADE": "Ivaí",
    "UF": "PARANÁ",
    "anual": 4.79,
    "JAN": 5.16,
    "FEB": 5.22,
    "MAR": 5.19,
    "APR": 4.82,
    "MAY": 4.11,
    "JUN": 3.78,
    "JUL": 4.08,
    "AUG": 5.05,
    "SEP": 4.68,
    "OCT": 4.86,
    "NOV": 5.29,
    "DEC": 5.25
  },
  {
    "LONG": -50.58,
    "LAT": -25.02,
    "CIDADE": "Ipiranga",
    "UF": "PARANÁ",
    "anual": 4.75,
    "JAN": 5.08,
    "FEB": 5.18,
    "MAR": 5.11,
    "APR": 4.8,
    "MAY": 4.14,
    "JUN": 3.81,
    "JUL": 4.07,
    "AUG": 5.02,
    "SEP": 4.66,
    "OCT": 4.81,
    "NOV": 5.21,
    "DEC": 5.17
  },
  {
    "LONG": -49.09,
    "LAT": -24.97,
    "CIDADE": "Tunas do Paraná",
    "UF": "PARANÁ",
    "anual": 4.45,
    "JAN": 4.85,
    "FEB": 5.04,
    "MAR": 4.78,
    "APR": 4.41,
    "MAY": 3.89,
    "JUN": 3.66,
    "JUL": 3.81,
    "AUG": 4.73,
    "SEP": 4.21,
    "OCT": 4.32,
    "NOV": 4.8,
    "DEC": 4.96
  },
  {
    "LONG": -54.34,
    "LAT": -2.49,
    "CIDADE": "Santa Helena",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.69,
    "FEB": 5.68,
    "MAR": 5.7,
    "APR": 5.03,
    "MAY": 4.2,
    "JUN": 3.86,
    "JUL": 4.06,
    "AUG": 4.97,
    "SEP": 4.73,
    "OCT": 5.24,
    "NOV": 5.6,
    "DEC": 5.77
  },
  {
    "LONG": -54.11,
    "LAT": -24.94,
    "CIDADE": "Diamante D'Oeste",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.56,
    "FEB": 5.5,
    "MAR": 5.61,
    "APR": 5.01,
    "MAY": 4.2,
    "JUN": 3.86,
    "JUL": 4.09,
    "AUG": 5.06,
    "SEP": 4.76,
    "OCT": 5.19,
    "NOV": 5.55,
    "DEC": 5.68
  },
  {
    "LONG": -53.85,
    "LAT": -24.94,
    "CIDADE": "São Pedro do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.57,
    "FEB": 5.42,
    "MAR": 5.46,
    "APR": 4.99,
    "MAY": 4.24,
    "JUN": 3.92,
    "JUL": 4.16,
    "AUG": 5.11,
    "SEP": 4.78,
    "OCT": 5.11,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -52.47,
    "LAT": -24.89,
    "CIDADE": "Laranjal",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.52,
    "FEB": 5.37,
    "MAR": 5.56,
    "APR": 5.19,
    "MAY": 4.36,
    "JUN": 4.04,
    "JUL": 4.26,
    "AUG": 5.22,
    "SEP": 4.84,
    "OCT": 5.15,
    "NOV": 5.52,
    "DEC": 5.5
  },
  {
    "LONG": -52.2,
    "LAT": -24.89,
    "CIDADE": "Palmital",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.42,
    "FEB": 5.36,
    "MAR": 5.49,
    "APR": 5.17,
    "MAY": 4.33,
    "JUN": 4.05,
    "JUL": 4.27,
    "AUG": 5.18,
    "SEP": 4.84,
    "OCT": 5.13,
    "NOV": 5.52,
    "DEC": 5.47
  },
  {
    "LONG": "#VALOR!",
    "LAT": -24.94,
    "CIDADE": "Santa Maria do Oeste",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.17,
    "FEB": 5.26,
    "MAR": 5.33,
    "APR": 5.02,
    "MAY": 4.28,
    "JUN": 3.98,
    "JUL": 4.27,
    "AUG": 5.2,
    "SEP": 4.81,
    "OCT": 5.06,
    "NOV": 5.42,
    "DEC": 5.36
  },
  {
    "LONG": -5.15,
    "LAT": -24.88,
    "CIDADE": "Boa Ventura de São Roque",
    "UF": "PARANÁ",
    "anual": 4.91,
    "JAN": 5.2,
    "FEB": 5.27,
    "MAR": 5.24,
    "APR": 5.02,
    "MAY": 4.26,
    "JUN": 3.91,
    "JUL": 4.28,
    "AUG": 5.19,
    "SEP": 4.81,
    "OCT": 5.01,
    "NOV": 5.36,
    "DEC": 5.35
  },
  {
    "LONG": -50.11,
    "LAT": -24.95,
    "CIDADE": "Carambeí",
    "UF": "PARANÁ",
    "anual": 4.8,
    "JAN": 5.08,
    "FEB": 5.2,
    "MAR": 5.04,
    "APR": 4.9,
    "MAY": 4.17,
    "JUN": 3.94,
    "JUL": 4.19,
    "AUG": 5.11,
    "SEP": 4.71,
    "OCT": 4.88,
    "NOV": 5.2,
    "DEC": 5.22
  },
  {
    "LONG": -54.06,
    "LAT": -24.84,
    "CIDADE": "São José das Palmeiras",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.54,
    "FEB": 5.44,
    "MAR": 5.61,
    "APR": 4.99,
    "MAY": 4.21,
    "JUN": 3.9,
    "JUL": 4.12,
    "AUG": 5.07,
    "SEP": 4.79,
    "OCT": 5.17,
    "NOV": 5.55,
    "DEC": 5.64
  },
  {
    "LONG": -53.9,
    "LAT": -24.79,
    "CIDADE": "Ouro Verde do Oeste",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.56,
    "FEB": 5.44,
    "MAR": 5.57,
    "APR": 4.99,
    "MAY": 4.24,
    "JUN": 3.93,
    "JUL": 4.13,
    "AUG": 5.1,
    "SEP": 4.78,
    "OCT": 5.16,
    "NOV": 5.53,
    "DEC": 5.63
  },
  {
    "LONG": -5.33,
    "LAT": -24.8,
    "CIDADE": "Corbélia",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.61,
    "FEB": 5.3,
    "MAR": 5.46,
    "APR": 5.05,
    "MAY": 4.32,
    "JUN": 4.04,
    "JUL": 4.22,
    "AUG": 5.16,
    "SEP": 4.84,
    "OCT": 5.09,
    "NOV": 5.47,
    "DEC": 5.59
  },
  {
    "LONG": -53.12,
    "LAT": -24.82,
    "CIDADE": "Braganey",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.61,
    "FEB": 5.35,
    "MAR": 5.51,
    "APR": 5.11,
    "MAY": 4.37,
    "JUN": 4.02,
    "JUL": 4.24,
    "AUG": 5.19,
    "SEP": 4.86,
    "OCT": 5.12,
    "NOV": 5.48,
    "DEC": 5.58
  },
  {
    "LONG": -52.71,
    "LAT": -24.8,
    "CIDADE": "Altamira do Paraná",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.56,
    "FEB": 5.32,
    "MAR": 5.59,
    "APR": 5.19,
    "MAY": 4.38,
    "JUN": 4.05,
    "JUL": 4.22,
    "AUG": 5.21,
    "SEP": 4.85,
    "OCT": 5.19,
    "NOV": 5.53,
    "DEC": 5.57
  },
  {
    "LONG": -51.76,
    "LAT": -24.76,
    "CIDADE": "Pitanga",
    "UF": "PARANÁ",
    "anual": 4.91,
    "JAN": 5.14,
    "FEB": 5.27,
    "MAR": 5.29,
    "APR": 5.02,
    "MAY": 4.28,
    "JUN": 3.93,
    "JUL": 4.3,
    "AUG": 5.18,
    "SEP": 4.84,
    "OCT": 5.02,
    "NOV": 5.39,
    "DEC": 5.32
  },
  {
    "LONG": -50.01,
    "LAT": -24.79,
    "CIDADE": "Castro",
    "UF": "PARANÁ",
    "anual": 4.62,
    "JAN": 4.86,
    "FEB": 5.06,
    "MAR": 4.88,
    "APR": 4.68,
    "MAY": 4.03,
    "JUN": 3.76,
    "JUL": 3.99,
    "AUG": 4.95,
    "SEP": 4.54,
    "OCT": 4.63,
    "NOV": 5.02,
    "DEC": 5.05
  },
  {
    "LONG": -49.26,
    "LAT": -2.48,
    "CIDADE": "Cerro Azul",
    "UF": "PARANÁ",
    "anual": 4.4,
    "JAN": 4.84,
    "FEB": 5.08,
    "MAR": 4.88,
    "APR": 4.46,
    "MAY": 3.79,
    "JUN": 3.3,
    "JUL": 3.44,
    "AUG": 4.44,
    "SEP": 4.21,
    "OCT": 4.4,
    "NOV": 4.9,
    "DEC": 5.05
  },
  {
    "LONG": -5.42,
    "LAT": -24.7,
    "CIDADE": "Entre Rios do Oeste",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.65,
    "FEB": 5.57,
    "MAR": 5.61,
    "APR": 5.04,
    "MAY": 4.22,
    "JUN": 3.86,
    "JUL": 4.07,
    "AUG": 4.98,
    "SEP": 4.75,
    "OCT": 5.19,
    "NOV": 5.55,
    "DEC": 5.7
  },
  {
    "LONG": -53.74,
    "LAT": -24.73,
    "CIDADE": "Toledo",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.58,
    "FEB": 5.37,
    "MAR": 5.47,
    "APR": 5.07,
    "MAY": 4.3,
    "JUN": 4,
    "JUL": 4.17,
    "AUG": 5.13,
    "SEP": 4.84,
    "OCT": 5.14,
    "NOV": 5.53,
    "DEC": 5.65
  },
  {
    "LONG": -53.08,
    "LAT": -24.72,
    "CIDADE": "Iguatu",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.57,
    "FEB": 5.35,
    "MAR": 5.58,
    "APR": 5.17,
    "MAY": 4.43,
    "JUN": 4.04,
    "JUL": 4.23,
    "AUG": 5.18,
    "SEP": 4.88,
    "OCT": 5.17,
    "NOV": 5.53,
    "DEC": 5.61
  },
  {
    "LONG": -52.57,
    "LAT": -24.67,
    "CIDADE": "Nova Cantu",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.58,
    "FEB": 5.33,
    "MAR": 5.46,
    "APR": 5.18,
    "MAY": 4.4,
    "JUN": 4.04,
    "JUL": 4.26,
    "AUG": 5.2,
    "SEP": 4.83,
    "OCT": 5.08,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -52.15,
    "LAT": "#VALOR!",
    "CIDADE": "Mato Rico",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.45,
    "FEB": 5.41,
    "MAR": 5.43,
    "APR": 5.21,
    "MAY": 4.39,
    "JUN": 4.08,
    "JUL": 4.31,
    "AUG": 5.23,
    "SEP": 4.85,
    "OCT": 5.13,
    "NOV": 5.49,
    "DEC": 5.47
  },
  {
    "LONG": -48.99,
    "LAT": -24.66,
    "CIDADE": "Adrianópolis",
    "UF": "PARANÁ",
    "anual": 4.41,
    "JAN": 4.89,
    "FEB": 5.12,
    "MAR": 4.9,
    "APR": 4.5,
    "MAY": 3.85,
    "JUN": 3.45,
    "JUL": 3.5,
    "AUG": 4.43,
    "SEP": 4.15,
    "OCT": 4.33,
    "NOV": 4.81,
    "DEC": 5.03
  },
  {
    "LONG": -54.23,
    "LAT": -24.63,
    "CIDADE": "Pato Bragado",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.58,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.06,
    "MAY": 4.22,
    "JUN": 3.88,
    "JUL": 4.07,
    "AUG": 5,
    "SEP": 4.77,
    "OCT": 5.19,
    "NOV": 5.54,
    "DEC": 5.69
  },
  {
    "LONG": -54.06,
    "LAT": -24.56,
    "CIDADE": "Marechal Cândido Rondon",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.52,
    "FEB": 5.44,
    "MAR": 5.54,
    "APR": 5.04,
    "MAY": 4.28,
    "JUN": 3.95,
    "JUL": 4.15,
    "AUG": 5.08,
    "SEP": 4.83,
    "OCT": 5.16,
    "NOV": 5.53,
    "DEC": 5.66
  },
  {
    "LONG": -53.98,
    "LAT": -24.58,
    "CIDADE": "Quatro Pontes",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.49,
    "FEB": 5.37,
    "MAR": 5.55,
    "APR": 5.05,
    "MAY": 4.31,
    "JUN": 3.95,
    "JUL": 4.18,
    "AUG": 5.1,
    "SEP": 4.83,
    "OCT": 5.18,
    "NOV": 5.55,
    "DEC": 5.66
  },
  {
    "LONG": -5.35,
    "LAT": -24.59,
    "CIDADE": "Tupãssi",
    "UF": "PARANÁ",
    "anual": 5.05,
    "JAN": 5.66,
    "FEB": 5.32,
    "MAR": 5.53,
    "APR": 5.14,
    "MAY": 4.38,
    "JUN": 4.05,
    "JUL": 4.21,
    "AUG": 5.1,
    "SEP": 4.9,
    "OCT": 5.15,
    "NOV": 5.52,
    "DEC": 5.64
  },
  {
    "LONG": -53.32,
    "LAT": -24.62,
    "CIDADE": "Cafelândia",
    "UF": "PARANÁ",
    "anual": 5.05,
    "JAN": 5.61,
    "FEB": 5.35,
    "MAR": 5.51,
    "APR": 5.12,
    "MAY": 4.37,
    "JUN": 4.07,
    "JUL": 4.24,
    "AUG": 5.13,
    "SEP": 4.89,
    "OCT": 5.17,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -53.13,
    "LAT": -24.65,
    "CIDADE": "Anahy",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.63,
    "FEB": 5.38,
    "MAR": 5.58,
    "APR": 5.17,
    "MAY": 4.41,
    "JUN": 4.07,
    "JUL": 4.23,
    "AUG": 5.2,
    "SEP": 4.89,
    "OCT": 5.16,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -5.28,
    "LAT": -24.59,
    "CIDADE": "Campina da Lagoa",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.57,
    "FEB": 5.24,
    "MAR": 5.43,
    "APR": 5.16,
    "MAY": 4.42,
    "JUN": 4.04,
    "JUL": 4.25,
    "AUG": 5.17,
    "SEP": 4.86,
    "OCT": 5.1,
    "NOV": 5.46,
    "DEC": 5.61
  },
  {
    "LONG": -5.23,
    "LAT": -24.6,
    "CIDADE": "Roncador",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.49,
    "FEB": 5.39,
    "MAR": 5.45,
    "APR": 5.16,
    "MAY": 4.41,
    "JUN": 4.09,
    "JUL": 4.32,
    "AUG": 5.24,
    "SEP": 4.85,
    "OCT": 5.1,
    "NOV": 5.43,
    "DEC": 5.46
  },
  {
    "LONG": -51.34,
    "LAT": -24.57,
    "CIDADE": "Cândido de Abreu",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.44,
    "FEB": 5.5,
    "MAR": 5.45,
    "APR": 5.18,
    "MAY": 4.39,
    "JUN": 4.05,
    "JUL": 4.29,
    "AUG": 5.25,
    "SEP": 4.9,
    "OCT": 5.16,
    "NOV": 5.52,
    "DEC": 5.52
  },
  {
    "LONG": -5.08,
    "LAT": -24.65,
    "CIDADE": "Reserva",
    "UF": "PARANÁ",
    "anual": 4.91,
    "JAN": 5.12,
    "FEB": 5.29,
    "MAR": 5.3,
    "APR": 5.03,
    "MAY": 4.27,
    "JUN": 3.98,
    "JUL": 4.26,
    "AUG": 5.21,
    "SEP": 4.83,
    "OCT": 4.98,
    "NOV": 5.34,
    "DEC": 5.31
  },
  {
    "LONG": -49.42,
    "LAT": -2.46,
    "CIDADE": "Doutor Ulysses",
    "UF": "PARANÁ",
    "anual": 4.39,
    "JAN": 4.56,
    "FEB": 4.9,
    "MAR": 4.68,
    "APR": 4.42,
    "MAY": 3.87,
    "JUN": 3.67,
    "JUL": 3.77,
    "AUG": 4.76,
    "SEP": 4.24,
    "OCT": 4.28,
    "NOV": 4.71,
    "DEC": 4.88
  },
  {
    "LONG": -54.16,
    "LAT": -24.45,
    "CIDADE": "Mercedes",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.54,
    "FEB": 5.49,
    "MAR": 5.6,
    "APR": 5.05,
    "MAY": 4.28,
    "JUN": 3.95,
    "JUL": 4.12,
    "AUG": 5.07,
    "SEP": 4.86,
    "OCT": 5.18,
    "NOV": 5.53,
    "DEC": 5.66
  },
  {
    "LONG": -53.96,
    "LAT": -24.47,
    "CIDADE": "Nova Santa Rosa",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.5,
    "FEB": 5.4,
    "MAR": 5.55,
    "APR": 5.09,
    "MAY": 4.31,
    "JUN": 3.96,
    "JUL": 4.16,
    "AUG": 5.11,
    "SEP": 4.86,
    "OCT": 5.17,
    "NOV": 5.55,
    "DEC": 5.68
  },
  {
    "LONG": -53.26,
    "LAT": -24.53,
    "CIDADE": "Nova Aurora",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.64,
    "FEB": 5.38,
    "MAR": 5.53,
    "APR": 5.13,
    "MAY": 4.41,
    "JUN": 4.05,
    "JUL": 4.23,
    "AUG": 5.17,
    "SEP": 4.9,
    "OCT": 5.18,
    "NOV": 5.49,
    "DEC": 5.61
  },
  {
    "LONG": -52.99,
    "LAT": -24.54,
    "CIDADE": "Ubiratã",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.58,
    "FEB": 5.3,
    "MAR": 5.48,
    "APR": 5.19,
    "MAY": 4.41,
    "JUN": 4.07,
    "JUL": 4.25,
    "AUG": 5.13,
    "SEP": 4.86,
    "OCT": 5.12,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -51.67,
    "LAT": -24.51,
    "CIDADE": "Manoel Ribas",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.36,
    "FEB": 5.44,
    "MAR": 5.38,
    "APR": 5.2,
    "MAY": 4.41,
    "JUN": 4.1,
    "JUL": 4.34,
    "AUG": 5.28,
    "SEP": 4.92,
    "OCT": 5.09,
    "NOV": 5.47,
    "DEC": 5.44
  },
  {
    "LONG": -50.42,
    "LAT": -24.51,
    "CIDADE": "Tibagi",
    "UF": "PARANÁ",
    "anual": 4.9,
    "JAN": 5.12,
    "FEB": 5.23,
    "MAR": 5.22,
    "APR": 5.07,
    "MAY": 4.35,
    "JUN": 3.97,
    "JUL": 4.27,
    "AUG": 5.2,
    "SEP": 4.84,
    "OCT": 4.96,
    "NOV": 5.33,
    "DEC": 5.28
  },
  {
    "LONG": -49.94,
    "LAT": -24.53,
    "CIDADE": "Piraí do Sul",
    "UF": "PARANÁ",
    "anual": 4.77,
    "JAN": 4.87,
    "FEB": 5.14,
    "MAR": 4.97,
    "APR": 4.85,
    "MAY": 4.22,
    "JUN": 3.95,
    "JUL": 4.18,
    "AUG": 5.16,
    "SEP": 4.75,
    "OCT": 4.81,
    "NOV": 5.15,
    "DEC": 5.17
  },
  {
    "LONG": -53.83,
    "LAT": -24.42,
    "CIDADE": "Maripá",
    "UF": "PARANÁ",
    "anual": 5.05,
    "JAN": 5.48,
    "FEB": 5.4,
    "MAR": 5.54,
    "APR": 5.14,
    "MAY": 4.35,
    "JUN": 4.02,
    "JUL": 4.19,
    "AUG": 5.13,
    "SEP": 4.91,
    "OCT": 5.2,
    "NOV": 5.56,
    "DEC": 5.7
  },
  {
    "LONG": -53.52,
    "LAT": -24.42,
    "CIDADE": "Assis Chateaubriand",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.6,
    "FEB": 5.43,
    "MAR": 5.51,
    "APR": 5.14,
    "MAY": 4.37,
    "JUN": 4.04,
    "JUL": 4.19,
    "AUG": 5.16,
    "SEP": 4.91,
    "OCT": 5.19,
    "NOV": 5.55,
    "DEC": 5.67
  },
  {
    "LONG": -53.35,
    "LAT": -24.43,
    "CIDADE": "Iracema do Oeste",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.56,
    "FEB": 5.46,
    "MAR": 5.55,
    "APR": 5.13,
    "MAY": 4.4,
    "JUN": 4.04,
    "JUL": 4.23,
    "AUG": 5.2,
    "SEP": 4.93,
    "OCT": 5.21,
    "NOV": 5.52,
    "DEC": 5.63
  },
  {
    "LONG": -53.39,
    "LAT": -24.38,
    "CIDADE": "Jesuítas",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.56,
    "FEB": 5.46,
    "MAR": 5.55,
    "APR": 5.13,
    "MAY": 4.4,
    "JUN": 4.04,
    "JUL": 4.23,
    "AUG": 5.2,
    "SEP": 4.93,
    "OCT": 5.21,
    "NOV": 5.52,
    "DEC": 5.63
  },
  {
    "LONG": -52.84,
    "LAT": -24.42,
    "CIDADE": "Juranda",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.54,
    "FEB": 5.37,
    "MAR": 5.41,
    "APR": 5.2,
    "MAY": 4.43,
    "JUN": 4.08,
    "JUL": 4.27,
    "AUG": 5.12,
    "SEP": 4.87,
    "OCT": 5.14,
    "NOV": 5.45,
    "DEC": 5.64
  },
  {
    "LONG": -52.1,
    "LAT": -24.43,
    "CIDADE": "Iretama",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.45,
    "FEB": 5.46,
    "MAR": 5.51,
    "APR": 5.26,
    "MAY": 4.46,
    "JUN": 4.13,
    "JUL": 4.34,
    "AUG": 5.27,
    "SEP": 4.94,
    "OCT": 5.15,
    "NOV": 5.57,
    "DEC": 5.53
  },
  {
    "LONG": -51.95,
    "LAT": -24.44,
    "CIDADE": "Nova Tebas",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.45,
    "FEB": 5.49,
    "MAR": 5.57,
    "APR": 5.32,
    "MAY": 4.46,
    "JUN": 4.16,
    "JUL": 4.35,
    "AUG": 5.27,
    "SEP": 4.96,
    "OCT": 5.18,
    "NOV": 5.6,
    "DEC": 5.57
  },
  {
    "LONG": -51.58,
    "LAT": -24.39,
    "CIDADE": "Ariranha do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.41,
    "FEB": 5.45,
    "MAR": 5.47,
    "APR": 5.29,
    "MAY": 4.43,
    "JUN": 4.06,
    "JUL": 4.35,
    "AUG": 5.3,
    "SEP": 4.93,
    "OCT": 5.12,
    "NOV": 5.55,
    "DEC": 5.5
  },
  {
    "LONG": -50.75,
    "LAT": -24.45,
    "CIDADE": "Imbaú",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.1,
    "FEB": 5.27,
    "MAR": 5.28,
    "APR": 5.09,
    "MAY": 4.31,
    "JUN": 3.96,
    "JUL": 4.31,
    "AUG": 5.25,
    "SEP": 4.84,
    "OCT": 4.95,
    "NOV": 5.33,
    "DEC": 5.33
  },
  {
    "LONG": -53.84,
    "LAT": -24.29,
    "CIDADE": "Palotina",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.49,
    "FEB": 5.47,
    "MAR": 5.58,
    "APR": 5.13,
    "MAY": 4.36,
    "JUN": 4.07,
    "JUL": 4.19,
    "AUG": 5.14,
    "SEP": 4.93,
    "OCT": 5.19,
    "NOV": 5.56,
    "DEC": 5.69
  },
  {
    "LONG": -53.31,
    "LAT": -24.3,
    "CIDADE": "Formosa do Oeste",
    "UF": "PARANÁ",
    "anual": 5.08,
    "JAN": 5.56,
    "FEB": 5.53,
    "MAR": 5.57,
    "APR": 5.17,
    "MAY": 4.42,
    "JUN": 4.03,
    "JUL": 4.21,
    "AUG": 5.19,
    "SEP": 4.92,
    "OCT": 5.22,
    "NOV": 5.52,
    "DEC": 5.66
  },
  {
    "LONG": -53.08,
    "LAT": -2.43,
    "CIDADE": "Quarto Centenário",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.55,
    "FEB": 5.46,
    "MAR": 5.49,
    "APR": 5.2,
    "MAY": 4.43,
    "JUN": 4.06,
    "JUL": 4.25,
    "AUG": 5.15,
    "SEP": 4.9,
    "OCT": 5.2,
    "NOV": 5.51,
    "DEC": 5.66
  },
  {
    "LONG": -52.95,
    "LAT": -2.43,
    "CIDADE": "Rancho Alegre D'Oeste",
    "UF": "PARANÁ",
    "anual": 5.08,
    "JAN": 5.52,
    "FEB": 5.48,
    "MAR": 5.49,
    "APR": 5.22,
    "MAY": 4.43,
    "JUN": 4.1,
    "JUL": 4.29,
    "AUG": 5.16,
    "SEP": 4.9,
    "OCT": 5.21,
    "NOV": 5.53,
    "DEC": 5.63
  },
  {
    "LONG": -52.53,
    "LAT": -24.32,
    "CIDADE": "Mamborê",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.53,
    "FEB": 5.4,
    "MAR": 5.42,
    "APR": 5.14,
    "MAY": 4.45,
    "JUN": 4.18,
    "JUL": 4.37,
    "AUG": 5.17,
    "SEP": 4.89,
    "OCT": 5.16,
    "NOV": 5.45,
    "DEC": 5.63
  },
  {
    "LONG": -52.27,
    "LAT": -24.29,
    "CIDADE": "Luiziana",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.41,
    "FEB": 5.41,
    "MAR": 5.41,
    "APR": 5.2,
    "MAY": 4.43,
    "JUN": 4.1,
    "JUL": 4.36,
    "AUG": 5.24,
    "SEP": 4.9,
    "OCT": 5.1,
    "NOV": 5.52,
    "DEC": 5.58
  },
  {
    "LONG": -51.79,
    "LAT": -24.31,
    "CIDADE": "Arapuã",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.4,
    "FEB": 5.51,
    "MAR": 5.5,
    "APR": 5.28,
    "MAY": 4.45,
    "JUN": 4.16,
    "JUL": 4.37,
    "AUG": 5.3,
    "SEP": 4.92,
    "OCT": 5.15,
    "NOV": 5.53,
    "DEC": 5.53
  },
  {
    "LONG": -51.32,
    "LAT": -24.32,
    "CIDADE": "Rio Branco do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.32,
    "FEB": 5.45,
    "MAR": 5.49,
    "APR": 5.27,
    "MAY": 4.44,
    "JUN": 4.1,
    "JUL": 4.36,
    "AUG": 5.3,
    "SEP": 4.94,
    "OCT": 5.15,
    "NOV": 5.52,
    "DEC": 5.52
  },
  {
    "LONG": -51.24,
    "LAT": -24.25,
    "CIDADE": "Rosário do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.34,
    "FEB": 5.43,
    "MAR": 5.55,
    "APR": 5.3,
    "MAY": 4.43,
    "JUN": 4.14,
    "JUL": 4.4,
    "AUG": 5.32,
    "SEP": 4.97,
    "OCT": 5.17,
    "NOV": 5.58,
    "DEC": 5.55
  },
  {
    "LONG": -5.06,
    "LAT": -2.43,
    "CIDADE": "Telêmaco Borba",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.12,
    "FEB": 5.3,
    "MAR": 5.31,
    "APR": 5.13,
    "MAY": 4.32,
    "JUN": 3.97,
    "JUL": 4.29,
    "AUG": 5.24,
    "SEP": 4.85,
    "OCT": 4.93,
    "NOV": 5.32,
    "DEC": 5.34
  },
  {
    "LONG": -49.71,
    "LAT": -2.43,
    "CIDADE": "Jaguariaíva",
    "UF": "PARANÁ",
    "anual": 4.85,
    "JAN": 4.93,
    "FEB": 5.25,
    "MAR": 5.04,
    "APR": 4.94,
    "MAY": 4.31,
    "JUN": 4.06,
    "JUL": 4.31,
    "AUG": 5.23,
    "SEP": 4.84,
    "OCT": 4.89,
    "NOV": 5.18,
    "DEC": 5.25
  },
  {
    "LONG": -54.1,
    "LAT": -24.16,
    "CIDADE": "Terra Roxa",
    "UF": "PARANÁ",
    "anual": 5.08,
    "JAN": 5.58,
    "FEB": 5.63,
    "MAR": 5.62,
    "APR": 5.12,
    "MAY": 4.3,
    "JUN": 3.96,
    "JUL": 4.2,
    "AUG": 5.11,
    "SEP": 4.89,
    "OCT": 5.23,
    "NOV": 5.54,
    "DEC": 5.71
  },
  {
    "LONG": -5.35,
    "LAT": -24.2,
    "CIDADE": "Brasilândia do Sul",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.56,
    "FEB": 5.55,
    "MAR": 5.55,
    "APR": 5.13,
    "MAY": 4.41,
    "JUN": 4.05,
    "JUL": 4.23,
    "AUG": 5.18,
    "SEP": 4.93,
    "OCT": 5.24,
    "NOV": 5.56,
    "DEC": 5.68
  },
  {
    "LONG": -53.03,
    "LAT": -24.18,
    "CIDADE": "Goioerê",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.54,
    "FEB": 5.51,
    "MAR": 5.57,
    "APR": 5.21,
    "MAY": 4.43,
    "JUN": 4.08,
    "JUL": 4.28,
    "AUG": 5.18,
    "SEP": 4.91,
    "OCT": 5.25,
    "NOV": 5.54,
    "DEC": 5.66
  },
  {
    "LONG": -5.28,
    "LAT": -24.25,
    "CIDADE": "Boa Esperança",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.5,
    "FEB": 5.48,
    "MAR": 5.51,
    "APR": 5.2,
    "MAY": 4.44,
    "JUN": 4.16,
    "JUL": 4.37,
    "AUG": 5.2,
    "SEP": 4.93,
    "OCT": 5.23,
    "NOV": 5.51,
    "DEC": 5.65
  },
  {
    "LONG": -51.93,
    "LAT": -24.17,
    "CIDADE": "Godoy Moreira",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.44,
    "FEB": 5.57,
    "MAR": 5.57,
    "APR": 5.38,
    "MAY": 4.49,
    "JUN": 4.15,
    "JUL": 4.4,
    "AUG": 5.29,
    "SEP": 4.98,
    "OCT": 5.21,
    "NOV": 5.6,
    "DEC": 5.57
  },
  {
    "LONG": -51.69,
    "LAT": -24.18,
    "CIDADE": "Jardim Alegre",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.39,
    "FEB": 5.51,
    "MAR": 5.54,
    "APR": 5.35,
    "MAY": 4.46,
    "JUN": 4.19,
    "JUL": 4.39,
    "AUG": 5.34,
    "SEP": 4.96,
    "OCT": 5.16,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -51.68,
    "LAT": -2.42,
    "CIDADE": "Ivaiporã",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.39,
    "FEB": 5.51,
    "MAR": 5.54,
    "APR": 5.35,
    "MAY": 4.46,
    "JUN": 4.19,
    "JUL": 4.39,
    "AUG": 5.34,
    "SEP": 4.96,
    "OCT": 5.16,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -50.93,
    "LAT": -2.42,
    "CIDADE": "Ortigueira",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.06,
    "FEB": 5.22,
    "MAR": 5.25,
    "APR": 5.14,
    "MAY": 4.36,
    "JUN": 4.03,
    "JUL": 4.31,
    "AUG": 5.26,
    "SEP": 4.85,
    "OCT": 4.97,
    "NOV": 5.3,
    "DEC": 5.26
  },
  {
    "LONG": -50.24,
    "LAT": -2.42,
    "CIDADE": "Ventania",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.04,
    "FEB": 5.25,
    "MAR": 5.2,
    "APR": 5.08,
    "MAY": 4.37,
    "JUN": 4.07,
    "JUL": 4.33,
    "AUG": 5.3,
    "SEP": 4.92,
    "OCT": 4.95,
    "NOV": 5.26,
    "DEC": 5.27
  },
  {
    "LONG": -54.26,
    "LAT": -24.09,
    "CIDADE": "Guaíra",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.59,
    "FEB": 5.61,
    "MAR": 5.62,
    "APR": 5.1,
    "MAY": 4.32,
    "JUN": 3.92,
    "JUL": 4.15,
    "AUG": 5.08,
    "SEP": 4.84,
    "OCT": 5.21,
    "NOV": 5.55,
    "DEC": 5.68
  },
  {
    "LONG": -53.85,
    "LAT": -24.07,
    "CIDADE": "Francisco Alves",
    "UF": "PARANÁ",
    "anual": 5.08,
    "JAN": 5.47,
    "FEB": 5.56,
    "MAR": 5.56,
    "APR": 5.12,
    "MAY": 4.41,
    "JUN": 4.02,
    "JUL": 4.21,
    "AUG": 5.19,
    "SEP": 4.92,
    "OCT": 5.23,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -53.01,
    "LAT": -24.05,
    "CIDADE": "Moreira Sales",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.51,
    "FEB": 5.56,
    "MAR": 5.59,
    "APR": 5.22,
    "MAY": 4.43,
    "JUN": 4.11,
    "JUL": 4.31,
    "AUG": 5.21,
    "SEP": 4.94,
    "OCT": 5.27,
    "NOV": 5.57,
    "DEC": 5.64
  },
  {
    "LONG": -52.78,
    "LAT": -24.14,
    "CIDADE": "Janiópolis",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.51,
    "FEB": 5.44,
    "MAR": 5.53,
    "APR": 5.21,
    "MAY": 4.47,
    "JUN": 4.13,
    "JUL": 4.37,
    "AUG": 5.23,
    "SEP": 4.95,
    "OCT": 5.24,
    "NOV": 5.53,
    "DEC": 5.66
  },
  {
    "LONG": -52.62,
    "LAT": -24.1,
    "CIDADE": "Farol",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.53,
    "FEB": 5.43,
    "MAR": 5.49,
    "APR": 5.21,
    "MAY": 4.47,
    "JUN": 4.14,
    "JUL": 4.38,
    "AUG": 5.22,
    "SEP": 4.94,
    "OCT": 5.21,
    "NOV": 5.51,
    "DEC": 5.66
  },
  {
    "LONG": -52.12,
    "LAT": -24.1,
    "CIDADE": "Corumbataí do Sul",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.41,
    "FEB": 5.48,
    "MAR": 5.53,
    "APR": 5.3,
    "MAY": 4.51,
    "JUN": 4.21,
    "JUL": 4.4,
    "AUG": 5.3,
    "SEP": 4.97,
    "OCT": 5.19,
    "NOV": 5.58,
    "DEC": 5.61
  },
  {
    "LONG": -51.74,
    "LAT": -24.08,
    "CIDADE": "Lunardelli",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.43,
    "FEB": 5.53,
    "MAR": 5.57,
    "APR": 5.35,
    "MAY": 4.5,
    "JUN": 4.22,
    "JUL": 4.43,
    "AUG": 5.33,
    "SEP": 4.97,
    "OCT": 5.16,
    "NOV": 5.53,
    "DEC": 5.59
  },
  {
    "LONG": -51.65,
    "LAT": -24.11,
    "CIDADE": "Lidianópolis",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.4,
    "FEB": 5.51,
    "MAR": 5.54,
    "APR": 5.34,
    "MAY": 4.49,
    "JUN": 4.25,
    "JUL": 4.42,
    "AUG": 5.33,
    "SEP": 4.97,
    "OCT": 5.19,
    "NOV": 5.56,
    "DEC": 5.61
  },
  {
    "LONG": -51.51,
    "LAT": -24.15,
    "CIDADE": "Grandes Rios",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.39,
    "FEB": 5.53,
    "MAR": 5.57,
    "APR": 5.37,
    "MAY": 4.47,
    "JUN": 4.19,
    "JUL": 4.4,
    "AUG": 5.33,
    "SEP": 4.96,
    "OCT": 5.19,
    "NOV": 5.57,
    "DEC": 5.61
  },
  {
    "LONG": -4.98,
    "LAT": -24.14,
    "CIDADE": "Arapoti",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.05,
    "FEB": 5.38,
    "MAR": 5.27,
    "APR": 5.12,
    "MAY": 4.45,
    "JUN": 4.12,
    "JUL": 4.41,
    "AUG": 5.27,
    "SEP": 4.95,
    "OCT": 5,
    "NOV": 5.37,
    "DEC": 5.39
  },
  {
    "LONG": -4.95,
    "LAT": -24.11,
    "CIDADE": "Sengés",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 4.98,
    "FEB": 5.44,
    "MAR": 5.25,
    "APR": 5.05,
    "MAY": 4.38,
    "JUN": 4.09,
    "JUL": 4.33,
    "AUG": 5.26,
    "SEP": 4.89,
    "OCT": 4.98,
    "NOV": 5.26,
    "DEC": 5.43
  },
  {
    "LONG": -53.71,
    "LAT": -24.01,
    "CIDADE": "Iporã",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.53,
    "FEB": 5.56,
    "MAR": 5.59,
    "APR": 5.13,
    "MAY": 4.38,
    "JUN": 4.07,
    "JUL": 4.22,
    "AUG": 5.19,
    "SEP": 4.92,
    "OCT": 5.24,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -53.44,
    "LAT": -24.02,
    "CIDADE": "Alto Piquiri",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.49,
    "FEB": 5.58,
    "MAR": 5.62,
    "APR": 5.19,
    "MAY": 4.46,
    "JUN": 4.09,
    "JUL": 4.27,
    "AUG": 5.23,
    "SEP": 4.94,
    "OCT": 5.26,
    "NOV": 5.58,
    "DEC": 5.63
  },
  {
    "LONG": -53.14,
    "LAT": -24.01,
    "CIDADE": "Mariluz",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.44,
    "FEB": 5.55,
    "MAR": 5.64,
    "APR": 5.23,
    "MAY": 4.46,
    "JUN": 4.12,
    "JUL": 4.29,
    "AUG": 5.22,
    "SEP": 4.95,
    "OCT": 5.27,
    "NOV": 5.58,
    "DEC": 5.66
  },
  {
    "LONG": -52.38,
    "LAT": -24.05,
    "CIDADE": "Campo Mourão",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.43,
    "FEB": 5.47,
    "MAR": 5.51,
    "APR": 5.29,
    "MAY": 4.53,
    "JUN": 4.18,
    "JUL": 4.39,
    "AUG": 5.25,
    "SEP": 4.95,
    "OCT": 5.17,
    "NOV": 5.54,
    "DEC": 5.65
  },
  {
    "LONG": -52,
    "LAT": -24.03,
    "CIDADE": "Barbosa Ferraz",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.48,
    "FEB": 5.55,
    "MAR": 5.56,
    "APR": 5.34,
    "MAY": 4.52,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 5.28,
    "SEP": 4.99,
    "OCT": 5.17,
    "NOV": 5.55,
    "DEC": 5.62
  },
  {
    "LONG": -5.18,
    "LAT": -23.98,
    "CIDADE": "São João do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.51,
    "FEB": 5.54,
    "MAR": 5.6,
    "APR": 5.37,
    "MAY": 4.5,
    "JUN": 4.24,
    "JUL": 4.44,
    "AUG": 5.33,
    "SEP": 4.99,
    "OCT": 5.19,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -5.14,
    "LAT": -23.99,
    "CIDADE": "Cruzmaltina",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.22,
    "FEB": 5.4,
    "MAR": 5.41,
    "APR": 5.24,
    "MAY": 4.45,
    "JUN": 4.24,
    "JUL": 4.45,
    "AUG": 5.32,
    "SEP": 4.94,
    "OCT": 5.12,
    "NOV": 5.41,
    "DEC": 5.48
  },
  {
    "LONG": -51.32,
    "LAT": -24.01,
    "CIDADE": "Faxinal",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.15,
    "FEB": 5.36,
    "MAR": 5.33,
    "APR": 5.19,
    "MAY": 4.42,
    "JUN": 4.23,
    "JUL": 4.44,
    "AUG": 5.3,
    "SEP": 4.91,
    "OCT": 5.06,
    "NOV": 5.35,
    "DEC": 5.39
  },
  {
    "LONG": -50.46,
    "LAT": -24.04,
    "CIDADE": "Curiúva",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.08,
    "FEB": 5.29,
    "MAR": 5.31,
    "APR": 5.16,
    "MAY": 4.38,
    "JUN": 4.06,
    "JUL": 4.39,
    "AUG": 5.3,
    "SEP": 4.93,
    "OCT": 5,
    "NOV": 5.27,
    "DEC": 5.33
  },
  {
    "LONG": -53.9,
    "LAT": -23.88,
    "CIDADE": "Altônia",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.52,
    "FEB": 5.6,
    "MAR": 5.59,
    "APR": 5.14,
    "MAY": 4.38,
    "JUN": 4.05,
    "JUL": 4.22,
    "AUG": 5.2,
    "SEP": 4.93,
    "OCT": 5.25,
    "NOV": 5.54,
    "DEC": 5.64
  },
  {
    "LONG": -53.51,
    "LAT": -2.39,
    "CIDADE": "Cafezal do Sul",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.48,
    "FEB": 5.57,
    "MAR": 5.59,
    "APR": 5.18,
    "MAY": 4.44,
    "JUN": 4.08,
    "JUL": 4.29,
    "AUG": 5.23,
    "SEP": 4.94,
    "OCT": 5.24,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -53.41,
    "LAT": -23.9,
    "CIDADE": "Perobal",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.47,
    "FEB": 5.58,
    "MAR": 5.6,
    "APR": 5.2,
    "MAY": 4.45,
    "JUN": 4.09,
    "JUL": 4.3,
    "AUG": 5.23,
    "SEP": 4.95,
    "OCT": 5.24,
    "NOV": 5.6,
    "DEC": 5.65
  },
  {
    "LONG": -52.88,
    "LAT": -23.87,
    "CIDADE": "Tuneiras do Oeste",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.45,
    "FEB": 5.52,
    "MAR": 5.63,
    "APR": 5.26,
    "MAY": 4.49,
    "JUN": 4.18,
    "JUL": 4.37,
    "AUG": 5.26,
    "SEP": 4.96,
    "OCT": 5.25,
    "NOV": 5.56,
    "DEC": 5.63
  },
  {
    "LONG": -52.5,
    "LAT": -2.39,
    "CIDADE": "Araruna",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.43,
    "FEB": 5.49,
    "MAR": 5.62,
    "APR": 5.29,
    "MAY": 4.52,
    "JUN": 4.2,
    "JUL": 4.4,
    "AUG": 5.28,
    "SEP": 5,
    "OCT": 5.24,
    "NOV": 5.56,
    "DEC": 5.63
  },
  {
    "LONG": -52.34,
    "LAT": -23.91,
    "CIDADE": "Peabiru",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.43,
    "FEB": 5.49,
    "MAR": 5.52,
    "APR": 5.32,
    "MAY": 4.55,
    "JUN": 4.2,
    "JUL": 4.39,
    "AUG": 5.24,
    "SEP": 4.98,
    "OCT": 5.16,
    "NOV": 5.54,
    "DEC": 5.65
  },
  {
    "LONG": -52.13,
    "LAT": -23.85,
    "CIDADE": "Quinta do Sol",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.44,
    "FEB": 5.52,
    "MAR": 5.55,
    "APR": 5.33,
    "MAY": 4.55,
    "JUN": 4.25,
    "JUL": 4.43,
    "AUG": 5.26,
    "SEP": 4.99,
    "OCT": 5.16,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -5.2,
    "LAT": -2.39,
    "CIDADE": "Fênix",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.45,
    "FEB": 5.55,
    "MAR": 5.56,
    "APR": 5.36,
    "MAY": 4.53,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 5.29,
    "SEP": 4.97,
    "OCT": 5.15,
    "NOV": 5.55,
    "DEC": 5.6
  },
  {
    "LONG": -51.86,
    "LAT": -23.86,
    "CIDADE": "São Pedro do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.42,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.34,
    "MAY": 4.51,
    "JUN": 4.23,
    "JUL": 4.42,
    "AUG": 5.33,
    "SEP": 4.97,
    "OCT": 5.15,
    "NOV": 5.54,
    "DEC": 5.58
  },
  {
    "LONG": -51.59,
    "LAT": -23.94,
    "CIDADE": "Borrazópolis",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.27,
    "FEB": 5.45,
    "MAR": 5.49,
    "APR": 5.28,
    "MAY": 4.47,
    "JUN": 4.27,
    "JUL": 4.46,
    "AUG": 5.38,
    "SEP": 4.98,
    "OCT": 5.13,
    "NOV": 5.48,
    "DEC": 5.49
  },
  {
    "LONG": -51.23,
    "LAT": -23.9,
    "CIDADE": "Mauá da Serra",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.15,
    "FEB": 5.32,
    "MAR": 5.34,
    "APR": 5.18,
    "MAY": 4.44,
    "JUN": 4.28,
    "JUL": 4.49,
    "AUG": 5.37,
    "SEP": 4.92,
    "OCT": 5.09,
    "NOV": 5.31,
    "DEC": 5.38
  },
  {
    "LONG": -50.58,
    "LAT": -2.39,
    "CIDADE": "Sapopema",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.02,
    "FEB": 5.22,
    "MAR": 5.23,
    "APR": 5.12,
    "MAY": 4.4,
    "JUN": 4.09,
    "JUL": 4.37,
    "AUG": 5.32,
    "SEP": 4.92,
    "OCT": 4.97,
    "NOV": 5.24,
    "DEC": 5.28
  },
  {
    "LONG": -49.8,
    "LAT": -23.87,
    "CIDADE": "Wenceslau Braz",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.11,
    "FEB": 5.47,
    "MAR": 5.33,
    "APR": 5.19,
    "MAY": 4.46,
    "JUN": 4.19,
    "JUL": 4.43,
    "AUG": 5.33,
    "SEP": 5.01,
    "OCT": 5.1,
    "NOV": 5.44,
    "DEC": 5.44
  },
  {
    "LONG": -49.65,
    "LAT": -2.39,
    "CIDADE": "São José da Boa Vista",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.12,
    "FEB": 5.45,
    "MAR": 5.29,
    "APR": 5.13,
    "MAY": 4.42,
    "JUN": 4.12,
    "JUL": 4.34,
    "AUG": 5.29,
    "SEP": 4.94,
    "OCT": 5.04,
    "NOV": 5.34,
    "DEC": 5.44
  },
  {
    "LONG": -53.88,
    "LAT": -23.77,
    "CIDADE": "São Jorge do Patrocínio",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.51,
    "FEB": 5.61,
    "MAR": 5.6,
    "APR": 5.17,
    "MAY": 4.4,
    "JUN": 4.09,
    "JUL": 4.26,
    "AUG": 5.21,
    "SEP": 4.96,
    "OCT": 5.25,
    "NOV": 5.56,
    "DEC": 5.67
  },
  {
    "LONG": -53.68,
    "LAT": -23.8,
    "CIDADE": "Pérola",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.43,
    "FEB": 5.61,
    "MAR": 5.59,
    "APR": 5.2,
    "MAY": 4.4,
    "JUN": 4.11,
    "JUL": 4.3,
    "AUG": 5.22,
    "SEP": 4.96,
    "OCT": 5.25,
    "NOV": 5.57,
    "DEC": 5.64
  },
  {
    "LONG": -53.32,
    "LAT": -23.77,
    "CIDADE": "Umuarama",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.44,
    "FEB": 5.57,
    "MAR": 5.59,
    "APR": 5.23,
    "MAY": 4.43,
    "JUN": 4.11,
    "JUL": 4.36,
    "AUG": 5.26,
    "SEP": 4.97,
    "OCT": 5.27,
    "NOV": 5.57,
    "DEC": 5.67
  },
  {
    "LONG": -53.08,
    "LAT": -23.78,
    "CIDADE": "Cruzeiro do Oeste",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.46,
    "FEB": 5.57,
    "MAR": 5.66,
    "APR": 5.25,
    "MAY": 4.47,
    "JUN": 4.15,
    "JUL": 4.35,
    "AUG": 5.27,
    "SEP": 4.98,
    "OCT": 5.27,
    "NOV": 5.6,
    "DEC": 5.64
  },
  {
    "LONG": -52.45,
    "LAT": -23.77,
    "CIDADE": "Terra Boa",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.45,
    "FEB": 5.51,
    "MAR": 5.53,
    "APR": 5.32,
    "MAY": 4.55,
    "JUN": 4.22,
    "JUL": 4.43,
    "AUG": 5.29,
    "SEP": 5.02,
    "OCT": 5.22,
    "NOV": 5.55,
    "DEC": 5.63
  },
  {
    "LONG": -52.27,
    "LAT": -23.8,
    "CIDADE": "Engenheiro Beltrão",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.41,
    "FEB": 5.51,
    "MAR": 5.58,
    "APR": 5.33,
    "MAY": 4.56,
    "JUN": 4.25,
    "JUL": 4.41,
    "AUG": 5.28,
    "SEP": 4.99,
    "OCT": 5.18,
    "NOV": 5.57,
    "DEC": 5.61
  },
  {
    "LONG": -51.67,
    "LAT": -23.82,
    "CIDADE": "Kaloré",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.23,
    "FEB": 5.45,
    "MAR": 5.47,
    "APR": 5.26,
    "MAY": 4.48,
    "JUN": 4.25,
    "JUL": 4.45,
    "AUG": 5.37,
    "SEP": 4.99,
    "OCT": 5.15,
    "NOV": 5.44,
    "DEC": 5.48
  },
  {
    "LONG": -51.51,
    "LAT": -23.76,
    "CIDADE": "Novo Itacolomi",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.21,
    "FEB": 5.47,
    "MAR": 5.52,
    "APR": 5.31,
    "MAY": 4.49,
    "JUN": 4.28,
    "JUL": 4.47,
    "AUG": 5.41,
    "SEP": 4.99,
    "OCT": 5.15,
    "NOV": 5.44,
    "DEC": 5.45
  },
  {
    "LONG": -51.41,
    "LAT": -23.76,
    "CIDADE": "Rio Bom",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.22,
    "FEB": 5.45,
    "MAR": 5.53,
    "APR": 5.31,
    "MAY": 4.48,
    "JUN": 4.28,
    "JUL": 4.46,
    "AUG": 5.38,
    "SEP": 5,
    "OCT": 5.15,
    "NOV": 5.44,
    "DEC": 5.48
  },
  {
    "LONG": -50.4,
    "LAT": -2.38,
    "CIDADE": "Figueira",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.04,
    "FEB": 5.31,
    "MAR": 5.18,
    "APR": 5.12,
    "MAY": 4.42,
    "JUN": 4.12,
    "JUL": 4.39,
    "AUG": 5.34,
    "SEP": 4.94,
    "OCT": 4.97,
    "NOV": 5.25,
    "DEC": 5.34
  },
  {
    "LONG": -50.14,
    "LAT": -23.81,
    "CIDADE": "Japira",
    "UF": "PARANÁ",
    "anual": 5.05,
    "JAN": 5.13,
    "FEB": 5.44,
    "MAR": 5.35,
    "APR": 5.2,
    "MAY": 4.5,
    "JUN": 4.19,
    "JUL": 4.45,
    "AUG": 5.37,
    "SEP": 5.01,
    "OCT": 5.1,
    "NOV": 5.39,
    "DEC": 5.47
  },
  {
    "LONG": -50.19,
    "LAT": -23.85,
    "CIDADE": "Ibaiti",
    "UF": "PARANÁ",
    "anual": 5.05,
    "JAN": 5.13,
    "FEB": 5.44,
    "MAR": 5.35,
    "APR": 5.2,
    "MAY": 4.5,
    "JUN": 4.19,
    "JUL": 4.45,
    "AUG": 5.37,
    "SEP": 5.01,
    "OCT": 5.1,
    "NOV": 5.39,
    "DEC": 5.47
  },
  {
    "LONG": -50.06,
    "LAT": -2.38,
    "CIDADE": "Pinhalão",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.13,
    "FEB": 5.49,
    "MAR": 5.36,
    "APR": 5.22,
    "MAY": 4.5,
    "JUN": 4.19,
    "JUL": 4.45,
    "AUG": 5.36,
    "SEP": 5.02,
    "OCT": 5.14,
    "NOV": 5.43,
    "DEC": 5.51
  },
  {
    "LONG": -49.95,
    "LAT": -23.78,
    "CIDADE": "Tomazina",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.12,
    "FEB": 5.5,
    "MAR": 5.37,
    "APR": 5.22,
    "MAY": 4.49,
    "JUN": 4.18,
    "JUL": 4.44,
    "AUG": 5.36,
    "SEP": 5.03,
    "OCT": 5.12,
    "NOV": 5.44,
    "DEC": 5.52
  },
  {
    "LONG": -49.63,
    "LAT": -23.76,
    "CIDADE": "Santana do Itararé",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.12,
    "FEB": 5.46,
    "MAR": 5.34,
    "APR": 5.17,
    "MAY": 4.41,
    "JUN": 4.11,
    "JUL": 4.33,
    "AUG": 5.3,
    "SEP": 4.96,
    "OCT": 5.07,
    "NOV": 5.32,
    "DEC": 5.47
  },
  {
    "LONG": -53.81,
    "LAT": -23.72,
    "CIDADE": "Esperança Nova",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.52,
    "FEB": 5.63,
    "MAR": 5.61,
    "APR": 5.17,
    "MAY": 4.41,
    "JUN": 4.1,
    "JUL": 4.27,
    "AUG": 5.21,
    "SEP": 4.95,
    "OCT": 5.26,
    "NOV": 5.56,
    "DEC": 5.71
  },
  {
    "LONG": -53.49,
    "LAT": -23.74,
    "CIDADE": "Xambrê",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.46,
    "FEB": 5.59,
    "MAR": 5.58,
    "APR": 5.21,
    "MAY": 4.44,
    "JUN": 4.13,
    "JUL": 4.35,
    "AUG": 5.23,
    "SEP": 4.96,
    "OCT": 5.28,
    "NOV": 5.56,
    "DEC": 5.68
  },
  {
    "LONG": -52.87,
    "LAT": -23.73,
    "CIDADE": "Tapejara",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.48,
    "FEB": 5.6,
    "MAR": 5.64,
    "APR": 5.27,
    "MAY": 4.47,
    "JUN": 4.22,
    "JUL": 4.41,
    "AUG": 5.28,
    "SEP": 4.99,
    "OCT": 5.29,
    "NOV": 5.56,
    "DEC": 5.61
  },
  {
    "LONG": -52.61,
    "LAT": -23.66,
    "CIDADE": "Cianorte",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.42,
    "FEB": 5.53,
    "MAR": 5.61,
    "APR": 5.28,
    "MAY": 4.52,
    "JUN": 4.24,
    "JUL": 4.44,
    "AUG": 5.33,
    "SEP": 5.02,
    "OCT": 5.27,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -51.99,
    "LAT": -23.66,
    "CIDADE": "Itambé",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.4,
    "FEB": 5.44,
    "MAR": 5.46,
    "APR": 5.3,
    "MAY": 4.54,
    "JUN": 4.3,
    "JUL": 4.45,
    "AUG": 5.26,
    "SEP": 4.99,
    "OCT": 5.16,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -51.77,
    "LAT": -23.71,
    "CIDADE": "Bom Sucesso",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.24,
    "FEB": 5.49,
    "MAR": 5.49,
    "APR": 5.27,
    "MAY": 4.48,
    "JUN": 4.29,
    "JUL": 4.47,
    "AUG": 5.37,
    "SEP": 5.02,
    "OCT": 5.14,
    "NOV": 5.43,
    "DEC": 5.47
  },
  {
    "LONG": -51.64,
    "LAT": -23.71,
    "CIDADE": "Marumbi",
    "UF": "PARANÁ",
    "anual": 5.08,
    "JAN": 5.2,
    "FEB": 5.44,
    "MAR": 5.45,
    "APR": 5.26,
    "MAY": 4.48,
    "JUN": 4.27,
    "JUL": 4.48,
    "AUG": 5.39,
    "SEP": 5.02,
    "OCT": 5.17,
    "NOV": 5.4,
    "DEC": 5.45
  },
  {
    "LONG": -51.36,
    "LAT": -23.66,
    "CIDADE": "Califórnia",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.26,
    "FEB": 5.37,
    "MAR": 5.5,
    "APR": 5.3,
    "MAY": 4.49,
    "JUN": 4.28,
    "JUL": 4.47,
    "AUG": 5.38,
    "SEP": 5,
    "OCT": 5.13,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -51.31,
    "LAT": -2.37,
    "CIDADE": "Marilândia do Sul",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.26,
    "FEB": 5.37,
    "MAR": 5.5,
    "APR": 5.3,
    "MAY": 4.49,
    "JUN": 4.28,
    "JUL": 4.47,
    "AUG": 5.38,
    "SEP": 5,
    "OCT": 5.13,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -51.1,
    "LAT": -23.72,
    "CIDADE": "Tamarana",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.14,
    "FEB": 5.38,
    "MAR": 5.41,
    "APR": 5.28,
    "MAY": 4.53,
    "JUN": 4.3,
    "JUL": 4.49,
    "AUG": 5.39,
    "SEP": 5.01,
    "OCT": 5.12,
    "NOV": 5.39,
    "DEC": 5.43
  },
  {
    "LONG": -50.74,
    "LAT": -23.72,
    "CIDADE": "São Jerônimo da Serra",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.17,
    "FEB": 5.39,
    "MAR": 5.43,
    "APR": 5.24,
    "MAY": 4.52,
    "JUN": 4.3,
    "JUL": 4.53,
    "AUG": 5.42,
    "SEP": 5.04,
    "OCT": 5.12,
    "NOV": 5.33,
    "DEC": 5.41
  },
  {
    "LONG": -50.07,
    "LAT": -2.37,
    "CIDADE": "Jaboti",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.17,
    "FEB": 5.54,
    "MAR": 5.4,
    "APR": 5.23,
    "MAY": 4.54,
    "JUN": 4.22,
    "JUL": 4.49,
    "AUG": 5.39,
    "SEP": 5.04,
    "OCT": 5.16,
    "NOV": 5.44,
    "DEC": 5.54
  },
  {
    "LONG": -49.83,
    "LAT": -2.37,
    "CIDADE": "Siqueira Campos",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.11,
    "FEB": 5.49,
    "MAR": 5.39,
    "APR": 5.24,
    "MAY": 4.49,
    "JUN": 4.16,
    "JUL": 4.43,
    "AUG": 5.39,
    "SEP": 5.01,
    "OCT": 5.16,
    "NOV": 5.42,
    "DEC": 5.49
  },
  {
    "LONG": -53.21,
    "LAT": -23.62,
    "CIDADE": "Maria Helena",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.43,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.26,
    "MAY": 4.48,
    "JUN": 4.17,
    "JUL": 4.37,
    "AUG": 5.27,
    "SEP": 4.98,
    "OCT": 5.3,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -52.47,
    "LAT": -23.62,
    "CIDADE": "Jussara",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.41,
    "FEB": 5.56,
    "MAR": 5.59,
    "APR": 5.29,
    "MAY": 4.57,
    "JUN": 4.26,
    "JUL": 4.46,
    "AUG": 5.26,
    "SEP": 5,
    "OCT": 5.23,
    "NOV": 5.54,
    "DEC": 5.62
  },
  {
    "LONG": -52.22,
    "LAT": -23.56,
    "CIDADE": "Doutor Camargo",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.43,
    "FEB": 5.42,
    "MAR": 5.46,
    "APR": 5.34,
    "MAY": 4.58,
    "JUN": 4.26,
    "JUL": 4.44,
    "AUG": 5.23,
    "SEP": 5.01,
    "OCT": 5.17,
    "NOV": 5.49,
    "DEC": 5.66
  },
  {
    "LONG": -52.22,
    "LAT": -23.62,
    "CIDADE": "Ivatuba",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.43,
    "FEB": 5.42,
    "MAR": 5.46,
    "APR": 5.34,
    "MAY": 4.58,
    "JUN": 4.26,
    "JUL": 4.44,
    "AUG": 5.23,
    "SEP": 5.01,
    "OCT": 5.17,
    "NOV": 5.49,
    "DEC": 5.66
  },
  {
    "LONG": -52.08,
    "LAT": -23.6,
    "CIDADE": "Floresta",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.34,
    "FEB": 5.47,
    "MAR": 5.43,
    "APR": 5.31,
    "MAY": 4.55,
    "JUN": 4.29,
    "JUL": 4.45,
    "AUG": 5.26,
    "SEP": 5.02,
    "OCT": 5.15,
    "NOV": 5.48,
    "DEC": 5.58
  },
  {
    "LONG": -51.65,
    "LAT": -23.6,
    "CIDADE": "Jandaia do Sul",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.16,
    "FEB": 5.46,
    "MAR": 5.48,
    "APR": 5.28,
    "MAY": 4.5,
    "JUN": 4.33,
    "JUL": 4.51,
    "AUG": 5.41,
    "SEP": 5.03,
    "OCT": 5.19,
    "NOV": 5.44,
    "DEC": 5.44
  },
  {
    "LONG": -51.58,
    "LAT": -23.59,
    "CIDADE": "Cambira",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.13,
    "FEB": 5.41,
    "MAR": 5.46,
    "APR": 5.29,
    "MAY": 4.51,
    "JUN": 4.34,
    "JUL": 4.53,
    "AUG": 5.41,
    "SEP": 5.04,
    "OCT": 5.18,
    "NOV": 5.4,
    "DEC": 5.41
  },
  {
    "LONG": -50.76,
    "LAT": -23.59,
    "CIDADE": "Nova Santa Bárbara",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.23,
    "FEB": 5.48,
    "MAR": 5.42,
    "APR": 5.29,
    "MAY": 4.57,
    "JUN": 4.37,
    "JUL": 4.55,
    "AUG": 5.43,
    "SEP": 5.05,
    "OCT": 5.14,
    "NOV": 5.39,
    "DEC": 5.47
  },
  {
    "LONG": -50.55,
    "LAT": -2.36,
    "CIDADE": "Congonhinhas",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.18,
    "FEB": 5.45,
    "MAR": 5.37,
    "APR": 5.26,
    "MAY": 4.54,
    "JUN": 4.3,
    "JUL": 4.5,
    "AUG": 5.46,
    "SEP": 5.02,
    "OCT": 5.14,
    "NOV": 5.41,
    "DEC": 5.45
  },
  {
    "LONG": -50.17,
    "LAT": -23.63,
    "CIDADE": "Conselheiro Mairinck",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.22,
    "FEB": 5.48,
    "MAR": 5.43,
    "APR": 5.27,
    "MAY": 4.51,
    "JUN": 4.22,
    "JUL": 4.47,
    "AUG": 5.41,
    "SEP": 5.03,
    "OCT": 5.15,
    "NOV": 5.4,
    "DEC": 5.53
  },
  {
    "LONG": -49.92,
    "LAT": -23.57,
    "CIDADE": "Quatiguá",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.17,
    "FEB": 5.54,
    "MAR": 5.44,
    "APR": 5.25,
    "MAY": 4.53,
    "JUN": 4.26,
    "JUL": 4.49,
    "AUG": 5.44,
    "SEP": 5.06,
    "OCT": 5.2,
    "NOV": 5.43,
    "DEC": 5.55
  },
  {
    "LONG": -49.64,
    "LAT": -23.61,
    "CIDADE": "Salto do Itararé",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.16,
    "FEB": 5.59,
    "MAR": 5.44,
    "APR": 5.23,
    "MAY": 4.42,
    "JUN": 4.18,
    "JUL": 4.38,
    "AUG": 5.34,
    "SEP": 5,
    "OCT": 5.16,
    "NOV": 5.39,
    "DEC": 5.58
  },
  {
    "LONG": -5.37,
    "LAT": -23.51,
    "CIDADE": "Alto Paraíso",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.51,
    "FEB": 5.68,
    "MAR": 5.67,
    "APR": 5.17,
    "MAY": 4.44,
    "JUN": 4.16,
    "JUL": 4.32,
    "AUG": 5.19,
    "SEP": 4.97,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.74
  },
  {
    "LONG": -53.09,
    "LAT": -23.47,
    "CIDADE": "Nova Olímpia",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.41,
    "FEB": 5.58,
    "MAR": 5.62,
    "APR": 5.3,
    "MAY": 4.54,
    "JUN": 4.22,
    "JUL": 4.42,
    "AUG": 5.32,
    "SEP": 5.01,
    "OCT": 5.3,
    "NOV": 5.56,
    "DEC": 5.68
  },
  {
    "LONG": -52.7,
    "LAT": -23.48,
    "CIDADE": "Indianópolis",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.37,
    "FEB": 5.61,
    "MAR": 5.69,
    "APR": 5.26,
    "MAY": 4.53,
    "JUN": 4.26,
    "JUL": 4.42,
    "AUG": 5.33,
    "SEP": 5.01,
    "OCT": 5.28,
    "NOV": 5.57,
    "DEC": 5.65
  },
  {
    "LONG": -52.56,
    "LAT": -23.47,
    "CIDADE": "Japurá",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.41,
    "FEB": 5.57,
    "MAR": 5.57,
    "APR": 5.28,
    "MAY": 4.54,
    "JUN": 4.27,
    "JUL": 4.47,
    "AUG": 5.3,
    "SEP": 5.03,
    "OCT": 5.26,
    "NOV": 5.55,
    "DEC": 5.66
  },
  {
    "LONG": -52.59,
    "LAT": -23.54,
    "CIDADE": "São Tomé",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.41,
    "FEB": 5.57,
    "MAR": 5.57,
    "APR": 5.28,
    "MAY": 4.54,
    "JUN": 4.27,
    "JUL": 4.47,
    "AUG": 5.3,
    "SEP": 5.03,
    "OCT": 5.26,
    "NOV": 5.55,
    "DEC": 5.66
  },
  {
    "LONG": -52.05,
    "LAT": -2.35,
    "CIDADE": "Paiçandu",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.28,
    "FEB": 5.51,
    "MAR": 5.47,
    "APR": 5.26,
    "MAY": 4.55,
    "JUN": 4.29,
    "JUL": 4.5,
    "AUG": 5.3,
    "SEP": 5.03,
    "OCT": 5.2,
    "NOV": 5.46,
    "DEC": 5.55
  },
  {
    "LONG": -51.79,
    "LAT": -23.48,
    "CIDADE": "Marialva",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.22,
    "FEB": 5.54,
    "MAR": 5.51,
    "APR": 5.3,
    "MAY": 4.55,
    "JUN": 4.34,
    "JUL": 4.53,
    "AUG": 5.38,
    "SEP": 5.05,
    "OCT": 5.22,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -51.67,
    "LAT": -23.55,
    "CIDADE": "Mandaguari",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.22,
    "FEB": 5.49,
    "MAR": 5.54,
    "APR": 5.33,
    "MAY": 4.55,
    "JUN": 4.33,
    "JUL": 4.53,
    "AUG": 5.42,
    "SEP": 5.05,
    "OCT": 5.24,
    "NOV": 5.45,
    "DEC": 5.49
  },
  {
    "LONG": -51.46,
    "LAT": -23.55,
    "CIDADE": "Apucarana",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.25,
    "FEB": 5.44,
    "MAR": 5.43,
    "APR": 5.27,
    "MAY": 4.55,
    "JUN": 4.39,
    "JUL": 4.53,
    "AUG": 5.38,
    "SEP": 5.05,
    "OCT": 5.21,
    "NOV": 5.39,
    "DEC": 5.45
  },
  {
    "LONG": -5.08,
    "LAT": -23.47,
    "CIDADE": "São Sebastião da Amoreira",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.28,
    "FEB": 5.47,
    "MAR": 5.39,
    "APR": 5.31,
    "MAY": 4.57,
    "JUN": 4.4,
    "JUL": 4.56,
    "AUG": 5.42,
    "SEP": 5.04,
    "OCT": 5.16,
    "NOV": 5.44,
    "DEC": 5.5
  },
  {
    "LONG": -50.78,
    "LAT": -2.35,
    "CIDADE": "Santa Cecília do Pavão",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.28,
    "FEB": 5.47,
    "MAR": 5.39,
    "APR": 5.31,
    "MAY": 4.57,
    "JUN": 4.4,
    "JUL": 4.56,
    "AUG": 5.42,
    "SEP": 5.04,
    "OCT": 5.16,
    "NOV": 5.44,
    "DEC": 5.5
  },
  {
    "LONG": -50.65,
    "LAT": -2.35,
    "CIDADE": "Santo Antônio do Paraíso",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.26,
    "FEB": 5.48,
    "MAR": 5.42,
    "APR": 5.27,
    "MAY": 4.57,
    "JUN": 4.38,
    "JUL": 4.54,
    "AUG": 5.44,
    "SEP": 5.03,
    "OCT": 5.17,
    "NOV": 5.46,
    "DEC": 5.51
  },
  {
    "LONG": -50.04,
    "LAT": -23.52,
    "CIDADE": "Guapirama",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.21,
    "FEB": 5.53,
    "MAR": 5.42,
    "APR": 5.32,
    "MAY": 4.55,
    "JUN": 4.26,
    "JUL": 4.5,
    "AUG": 5.44,
    "SEP": 5.03,
    "OCT": 5.2,
    "NOV": 5.45,
    "DEC": 5.56
  },
  {
    "LONG": -49.92,
    "LAT": -2.35,
    "CIDADE": "Joaquim Távora",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.18,
    "FEB": 5.53,
    "MAR": 5.46,
    "APR": 5.32,
    "MAY": 4.54,
    "JUN": 4.29,
    "JUL": 4.51,
    "AUG": 5.46,
    "SEP": 5.04,
    "OCT": 5.21,
    "NOV": 5.45,
    "DEC": 5.6
  },
  {
    "LONG": -53.62,
    "LAT": -23.39,
    "CIDADE": "Icaraíma",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.5,
    "FEB": 5.7,
    "MAR": 5.69,
    "APR": 5.24,
    "MAY": 4.49,
    "JUN": 4.19,
    "JUL": 4.37,
    "AUG": 5.22,
    "SEP": 4.98,
    "OCT": 5.3,
    "NOV": 5.58,
    "DEC": 5.72
  },
  {
    "LONG": -53.37,
    "LAT": -23.41,
    "CIDADE": "Ivaté",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.39,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.23,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.4,
    "AUG": 5.27,
    "SEP": 5,
    "OCT": 5.31,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -53.29,
    "LAT": -23.38,
    "CIDADE": "Douradina",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.39,
    "FEB": 5.62,
    "MAR": 5.62,
    "APR": 5.26,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 5.3,
    "SEP": 5,
    "OCT": 5.33,
    "NOV": 5.58,
    "DEC": 5.66
  },
  {
    "LONG": -5.29,
    "LAT": -23.38,
    "CIDADE": "Cidade Gaúcha",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.42,
    "FEB": 5.65,
    "MAR": 5.66,
    "APR": 5.31,
    "MAY": 4.54,
    "JUN": 4.29,
    "JUL": 4.42,
    "AUG": 5.31,
    "SEP": 5.03,
    "OCT": 5.31,
    "NOV": 5.57,
    "DEC": 5.66
  },
  {
    "LONG": -52.77,
    "LAT": -23.41,
    "CIDADE": "Rondon",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.39,
    "FEB": 5.62,
    "MAR": 5.69,
    "APR": 5.29,
    "MAY": 4.55,
    "JUN": 4.26,
    "JUL": 4.44,
    "AUG": 5.34,
    "SEP": 5.03,
    "OCT": 5.29,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -52.65,
    "LAT": -23.39,
    "CIDADE": "São Manoel do Paraná",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.39,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.29,
    "MAY": 4.52,
    "JUN": 4.26,
    "JUL": 4.45,
    "AUG": 5.31,
    "SEP": 5.03,
    "OCT": 5.29,
    "NOV": 5.55,
    "DEC": 5.65
  },
  {
    "LONG": -52.29,
    "LAT": -23.43,
    "CIDADE": "São Jorge do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.33,
    "FEB": 5.57,
    "MAR": 5.52,
    "APR": 5.3,
    "MAY": 4.56,
    "JUN": 4.3,
    "JUL": 4.48,
    "AUG": 5.27,
    "SEP": 5.04,
    "OCT": 5.26,
    "NOV": 5.49,
    "DEC": 5.57
  },
  {
    "LONG": -52.2,
    "LAT": -23.41,
    "CIDADE": "Ourizona",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.25,
    "FEB": 5.61,
    "MAR": 5.55,
    "APR": 5.3,
    "MAY": 4.55,
    "JUN": 4.32,
    "JUL": 4.49,
    "AUG": 5.37,
    "SEP": 5.04,
    "OCT": 5.26,
    "NOV": 5.51,
    "DEC": 5.55
  },
  {
    "LONG": -51.93,
    "LAT": -2.34,
    "CIDADE": "Maringá",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.26,
    "FEB": 5.53,
    "MAR": 5.45,
    "APR": 5.26,
    "MAY": 4.56,
    "JUN": 4.37,
    "JUL": 4.53,
    "AUG": 5.36,
    "SEP": 5.07,
    "OCT": 5.2,
    "NOV": 5.43,
    "DEC": 5.54
  },
  {
    "LONG": -51.88,
    "LAT": -23.44,
    "CIDADE": "Sarandi",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.28,
    "FEB": 5.53,
    "MAR": 5.51,
    "APR": 5.31,
    "MAY": 4.57,
    "JUN": 4.39,
    "JUL": 4.54,
    "AUG": 5.37,
    "SEP": 5.07,
    "OCT": 5.21,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -51.43,
    "LAT": -23.42,
    "CIDADE": "Arapongas",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.26,
    "FEB": 5.48,
    "MAR": 5.43,
    "APR": 5.31,
    "MAY": 4.55,
    "JUN": 4.43,
    "JUL": 4.58,
    "AUG": 5.39,
    "SEP": 5.06,
    "OCT": 5.22,
    "NOV": 5.41,
    "DEC": 5.44
  },
  {
    "LONG": -50.85,
    "LAT": -23.37,
    "CIDADE": "Assaí",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.28,
    "FEB": 5.5,
    "MAR": 5.49,
    "APR": 5.33,
    "MAY": 4.61,
    "JUN": 4.42,
    "JUL": 4.59,
    "AUG": 5.45,
    "SEP": 5.07,
    "OCT": 5.17,
    "NOV": 5.43,
    "DEC": 5.52
  },
  {
    "LONG": -50.56,
    "LAT": -2.34,
    "CIDADE": "Nova Fátima",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.26,
    "FEB": 5.54,
    "MAR": 5.44,
    "APR": 5.29,
    "MAY": 4.58,
    "JUN": 4.38,
    "JUL": 4.57,
    "AUG": 5.46,
    "SEP": 5.07,
    "OCT": 5.18,
    "NOV": 5.45,
    "DEC": 5.57
  },
  {
    "LONG": -50.36,
    "LAT": -2.34,
    "CIDADE": "Ribeirão do Pinhal",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.22,
    "FEB": 5.52,
    "MAR": 5.4,
    "APR": 5.33,
    "MAY": 4.62,
    "JUN": 4.36,
    "JUL": 4.56,
    "AUG": 5.48,
    "SEP": 5.08,
    "OCT": 5.23,
    "NOV": 5.42,
    "DEC": 5.55
  },
  {
    "LONG": "#VALOR!",
    "LAT": -23.44,
    "CIDADE": "Jundiaí do Sul",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.16,
    "FEB": 5.53,
    "MAR": 5.36,
    "APR": 5.31,
    "MAY": 4.6,
    "JUN": 4.31,
    "JUL": 4.55,
    "AUG": 5.46,
    "SEP": 5.07,
    "OCT": 5.21,
    "NOV": 5.42,
    "DEC": 5.53
  },
  {
    "LONG": -49.72,
    "LAT": -2.34,
    "CIDADE": "Carlópolis",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.17,
    "FEB": 5.56,
    "MAR": 5.41,
    "APR": 5.26,
    "MAY": 4.47,
    "JUN": 4.2,
    "JUL": 4.46,
    "AUG": 5.37,
    "SEP": 5,
    "OCT": 5.18,
    "NOV": 5.43,
    "DEC": 5.6
  },
  {
    "LONG": -53.07,
    "LAT": -23.32,
    "CIDADE": "Tapira",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.37,
    "FEB": 5.68,
    "MAR": 5.67,
    "APR": 5.31,
    "MAY": 4.56,
    "JUN": 4.24,
    "JUL": 4.43,
    "AUG": 5.31,
    "SEP": 5.03,
    "OCT": 5.31,
    "NOV": 5.59,
    "DEC": 5.69
  },
  {
    "LONG": -52.78,
    "LAT": -23.26,
    "CIDADE": "Mirador",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.38,
    "FEB": 5.6,
    "MAR": 5.65,
    "APR": 5.29,
    "MAY": 4.55,
    "JUN": 4.27,
    "JUL": 4.45,
    "AUG": 5.36,
    "SEP": 5.04,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.62
  },
  {
    "LONG": -5.28,
    "LAT": -23.34,
    "CIDADE": "Guaporema",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.38,
    "FEB": 5.6,
    "MAR": 5.65,
    "APR": 5.29,
    "MAY": 4.55,
    "JUN": 4.27,
    "JUL": 4.45,
    "AUG": 5.36,
    "SEP": 5.04,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.62
  },
  {
    "LONG": -52.61,
    "LAT": -23.28,
    "CIDADE": "Paraíso do Norte",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.38,
    "FEB": 5.58,
    "MAR": 5.62,
    "APR": 5.33,
    "MAY": 4.52,
    "JUN": 4.27,
    "JUL": 4.47,
    "AUG": 5.35,
    "SEP": 5.04,
    "OCT": 5.32,
    "NOV": 5.57,
    "DEC": 5.62
  },
  {
    "LONG": -52.48,
    "LAT": -23.32,
    "CIDADE": "São Carlos do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.33,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.32,
    "MAY": 4.54,
    "JUN": 4.28,
    "JUL": 4.48,
    "AUG": 5.32,
    "SEP": 5.06,
    "OCT": 5.31,
    "NOV": 5.57,
    "DEC": 5.62
  },
  {
    "LONG": -52.3,
    "LAT": -23.32,
    "CIDADE": "Floraí",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.32,
    "FEB": 5.62,
    "MAR": 5.63,
    "APR": 5.32,
    "MAY": 4.55,
    "JUN": 4.31,
    "JUL": 4.5,
    "AUG": 5.31,
    "SEP": 5.05,
    "OCT": 5.3,
    "NOV": 5.55,
    "DEC": 5.6
  },
  {
    "LONG": -5.22,
    "LAT": -23.28,
    "CIDADE": "Presidente Castelo Branco",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.27,
    "FEB": 5.59,
    "MAR": 5.61,
    "APR": 5.34,
    "MAY": 4.56,
    "JUN": 4.35,
    "JUL": 4.54,
    "AUG": 5.41,
    "SEP": 5.06,
    "OCT": 5.29,
    "NOV": 5.54,
    "DEC": 5.56
  },
  {
    "LONG": -52.09,
    "LAT": -23.35,
    "CIDADE": "Mandaguaçu",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.27,
    "FEB": 5.56,
    "MAR": 5.53,
    "APR": 5.32,
    "MAY": 4.56,
    "JUN": 4.38,
    "JUL": 4.54,
    "AUG": 5.4,
    "SEP": 5.06,
    "OCT": 5.26,
    "NOV": 5.5,
    "DEC": 5.56
  },
  {
    "LONG": -51.56,
    "LAT": -2.33,
    "CIDADE": "Sabáudia",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.31,
    "FEB": 5.52,
    "MAR": 5.54,
    "APR": 5.36,
    "MAY": 4.57,
    "JUN": 4.44,
    "JUL": 4.58,
    "AUG": 5.4,
    "SEP": 5.06,
    "OCT": 5.24,
    "NOV": 5.49,
    "DEC": 5.52
  },
  {
    "LONG": -51.37,
    "LAT": -23.31,
    "CIDADE": "Rolândia",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.25,
    "FEB": 5.5,
    "MAR": 5.43,
    "APR": 5.33,
    "MAY": 4.56,
    "JUN": 4.43,
    "JUL": 4.62,
    "AUG": 5.44,
    "SEP": 5.08,
    "OCT": 5.19,
    "NOV": 5.43,
    "DEC": 5.48
  },
  {
    "LONG": -51.28,
    "LAT": -2.33,
    "CIDADE": "Cambé",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.24,
    "FEB": 5.43,
    "MAR": 5.48,
    "APR": 5.32,
    "MAY": 4.54,
    "JUN": 4.44,
    "JUL": 4.59,
    "AUG": 5.43,
    "SEP": 5.08,
    "OCT": 5.16,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -51.17,
    "LAT": -23.3,
    "CIDADE": "Londrina",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.23,
    "FEB": 5.45,
    "MAR": 5.49,
    "APR": 5.35,
    "MAY": 4.57,
    "JUN": 4.42,
    "JUL": 4.6,
    "AUG": 5.46,
    "SEP": 5.08,
    "OCT": 5.15,
    "NOV": 5.46,
    "DEC": 5.49
  },
  {
    "LONG": -51.05,
    "LAT": -23.27,
    "CIDADE": "Ibiporã",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.25,
    "FEB": 5.47,
    "MAR": 5.49,
    "APR": 5.38,
    "MAY": 4.58,
    "JUN": 4.42,
    "JUL": 4.6,
    "AUG": 5.46,
    "SEP": 5.1,
    "OCT": 5.17,
    "NOV": 5.45,
    "DEC": 5.48
  },
  {
    "LONG": -50.98,
    "LAT": -23.26,
    "CIDADE": "Jataizinho",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.31,
    "FEB": 5.51,
    "MAR": 5.52,
    "APR": 5.38,
    "MAY": 4.63,
    "JUN": 4.41,
    "JUL": 4.59,
    "AUG": 5.47,
    "SEP": 5.08,
    "OCT": 5.19,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -50.72,
    "LAT": -23.33,
    "CIDADE": "Nova América da Colina",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.28,
    "FEB": 5.55,
    "MAR": 5.5,
    "APR": 5.33,
    "MAY": 4.63,
    "JUN": 4.43,
    "JUL": 4.6,
    "AUG": 5.45,
    "SEP": 5.1,
    "OCT": 5.23,
    "NOV": 5.46,
    "DEC": 5.54
  },
  {
    "LONG": -50.43,
    "LAT": -23.27,
    "CIDADE": "Santa Amélia",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.3,
    "FEB": 5.56,
    "MAR": 5.41,
    "APR": 5.32,
    "MAY": 4.6,
    "JUN": 4.39,
    "JUL": 4.58,
    "AUG": 5.48,
    "SEP": 5.1,
    "OCT": 5.23,
    "NOV": 5.42,
    "DEC": 5.56
  },
  {
    "LONG": -50.31,
    "LAT": -2.33,
    "CIDADE": "Abatiá",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.25,
    "FEB": 5.58,
    "MAR": 5.45,
    "APR": 5.35,
    "MAY": 4.63,
    "JUN": 4.42,
    "JUL": 4.6,
    "AUG": 5.51,
    "SEP": 5.11,
    "OCT": 5.25,
    "NOV": 5.44,
    "DEC": 5.57
  },
  {
    "LONG": -50.08,
    "LAT": -23.3,
    "CIDADE": "Santo Antônio da Platina",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.21,
    "FEB": 5.58,
    "MAR": 5.47,
    "APR": 5.37,
    "MAY": 4.59,
    "JUN": 4.38,
    "JUL": 4.61,
    "AUG": 5.5,
    "SEP": 5.1,
    "OCT": 5.27,
    "NOV": 5.46,
    "DEC": 5.58
  },
  {
    "LONG": -52.6,
    "LAT": -23.18,
    "CIDADE": "Nova Aliança do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.35,
    "FEB": 5.58,
    "MAR": 5.63,
    "APR": 5.35,
    "MAY": 4.53,
    "JUN": 4.31,
    "JUL": 4.49,
    "AUG": 5.36,
    "SEP": 5.07,
    "OCT": 5.32,
    "NOV": 5.57,
    "DEC": 5.6
  },
  {
    "LONG": -52.47,
    "LAT": -2.32,
    "CIDADE": "Tamboara",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.32,
    "FEB": 5.63,
    "MAR": 5.62,
    "APR": 5.35,
    "MAY": 4.53,
    "JUN": 4.32,
    "JUL": 4.49,
    "AUG": 5.35,
    "SEP": 5.09,
    "OCT": 5.32,
    "NOV": 5.58,
    "DEC": 5.6
  },
  {
    "LONG": -52.2,
    "LAT": -23.18,
    "CIDADE": "Nova Esperança",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.31,
    "FEB": 5.62,
    "MAR": 5.66,
    "APR": 5.35,
    "MAY": 4.55,
    "JUN": 4.35,
    "JUL": 4.53,
    "AUG": 5.37,
    "SEP": 5.08,
    "OCT": 5.32,
    "NOV": 5.58,
    "DEC": 5.58
  },
  {
    "LONG": -52.06,
    "LAT": -23.15,
    "CIDADE": "Atalaia",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.29,
    "FEB": 5.56,
    "MAR": 5.56,
    "APR": 5.38,
    "MAY": 4.59,
    "JUN": 4.41,
    "JUL": 4.57,
    "AUG": 5.39,
    "SEP": 5.06,
    "OCT": 5.28,
    "NOV": 5.53,
    "DEC": 5.58
  },
  {
    "LONG": -51.92,
    "LAT": -23.2,
    "CIDADE": "Ângulo",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.31,
    "FEB": 5.58,
    "MAR": 5.55,
    "APR": 5.38,
    "MAY": 4.6,
    "JUN": 4.43,
    "JUL": 4.55,
    "AUG": 5.39,
    "SEP": 5.07,
    "OCT": 5.27,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -5.18,
    "LAT": -23.2,
    "CIDADE": "Iguaraçu",
    "UF": "PARANÁ",
    "anual": 5.21,
    "JAN": 5.36,
    "FEB": 5.65,
    "MAR": 5.59,
    "APR": 5.35,
    "MAY": 4.6,
    "JUN": 4.4,
    "JUL": 4.56,
    "AUG": 5.42,
    "SEP": 5.08,
    "OCT": 5.31,
    "NOV": 5.54,
    "DEC": 5.6
  },
  {
    "LONG": -51.67,
    "LAT": -23.23,
    "CIDADE": "Astorga",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.3,
    "FEB": 5.52,
    "MAR": 5.55,
    "APR": 5.43,
    "MAY": 4.61,
    "JUN": 4.44,
    "JUL": 4.61,
    "AUG": 5.45,
    "SEP": 5.1,
    "OCT": 5.29,
    "NOV": 5.52,
    "DEC": 5.6
  },
  {
    "LONG": -51.59,
    "LAT": -23.23,
    "CIDADE": "Pitangueiras",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.32,
    "FEB": 5.57,
    "MAR": 5.56,
    "APR": 5.41,
    "MAY": 4.59,
    "JUN": 4.45,
    "JUL": 4.59,
    "AUG": 5.43,
    "SEP": 5.08,
    "OCT": 5.28,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -50.79,
    "LAT": -23.2,
    "CIDADE": "Uraí",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.31,
    "FEB": 5.61,
    "MAR": 5.54,
    "APR": 5.36,
    "MAY": 4.64,
    "JUN": 4.45,
    "JUL": 4.61,
    "AUG": 5.49,
    "SEP": 5.12,
    "OCT": 5.28,
    "NOV": 5.48,
    "DEC": 5.53
  },
  {
    "LONG": -50.65,
    "LAT": -23.18,
    "CIDADE": "Cornélio Procópio",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.32,
    "FEB": 5.61,
    "MAR": 5.52,
    "APR": 5.34,
    "MAY": 4.6,
    "JUN": 4.45,
    "JUL": 4.61,
    "AUG": 5.5,
    "SEP": 5.14,
    "OCT": 5.28,
    "NOV": 5.51,
    "DEC": 5.56
  },
  {
    "LONG": -49.97,
    "LAT": -23.16,
    "CIDADE": "Jacarezinho",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.18,
    "FEB": 5.55,
    "MAR": 5.5,
    "APR": 5.4,
    "MAY": 4.61,
    "JUN": 4.42,
    "JUL": 4.64,
    "AUG": 5.52,
    "SEP": 5.12,
    "OCT": 5.3,
    "NOV": 5.43,
    "DEC": 5.59
  },
  {
    "LONG": -49.76,
    "LAT": -2.32,
    "CIDADE": "Ribeirão Claro",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.13,
    "FEB": 5.57,
    "MAR": 5.52,
    "APR": 5.39,
    "MAY": 4.6,
    "JUN": 4.38,
    "JUL": 4.58,
    "AUG": 5.49,
    "SEP": 5.08,
    "OCT": 5.29,
    "NOV": 5.46,
    "DEC": 5.56
  },
  {
    "LONG": -53.48,
    "LAT": -23.08,
    "CIDADE": "Querência do Norte",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.37,
    "FEB": 5.6,
    "MAR": 5.7,
    "APR": 5.29,
    "MAY": 4.57,
    "JUN": 4.25,
    "JUL": 4.43,
    "AUG": 5.26,
    "SEP": 5.01,
    "OCT": 5.32,
    "NOV": 5.56,
    "DEC": 5.65
  },
  {
    "LONG": -53.11,
    "LAT": -23.11,
    "CIDADE": "Santa Mônica",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.26,
    "FEB": 5.58,
    "MAR": 5.65,
    "APR": 5.32,
    "MAY": 4.57,
    "JUN": 4.25,
    "JUL": 4.47,
    "AUG": 5.34,
    "SEP": 5.05,
    "OCT": 5.3,
    "NOV": 5.56,
    "DEC": 5.6
  },
  {
    "LONG": -5.28,
    "LAT": -23.09,
    "CIDADE": "Amaporã",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.34,
    "FEB": 5.6,
    "MAR": 5.63,
    "APR": 5.25,
    "MAY": 4.54,
    "JUN": 4.33,
    "JUL": 4.49,
    "AUG": 5.35,
    "SEP": 5.07,
    "OCT": 5.32,
    "NOV": 5.57,
    "DEC": 5.62
  },
  {
    "LONG": -52.46,
    "LAT": -23.08,
    "CIDADE": "Paranavaí",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.37,
    "FEB": 5.6,
    "MAR": 5.63,
    "APR": 5.33,
    "MAY": 4.56,
    "JUN": 4.37,
    "JUL": 4.54,
    "AUG": 5.38,
    "SEP": 5.11,
    "OCT": 5.33,
    "NOV": 5.6,
    "DEC": 5.62
  },
  {
    "LONG": -52.32,
    "LAT": -23.13,
    "CIDADE": "Alto Paraná",
    "UF": "PARANÁ",
    "anual": 5.21,
    "JAN": 5.36,
    "FEB": 5.62,
    "MAR": 5.67,
    "APR": 5.35,
    "MAY": 4.57,
    "JUN": 4.35,
    "JUL": 4.56,
    "AUG": 5.42,
    "SEP": 5.11,
    "OCT": 5.32,
    "NOV": 5.6,
    "DEC": 5.62
  },
  {
    "LONG": -52.16,
    "LAT": -23.09,
    "CIDADE": "Uniflor",
    "UF": "PARANÁ",
    "anual": 5.22,
    "JAN": 5.34,
    "FEB": 5.61,
    "MAR": 5.66,
    "APR": 5.4,
    "MAY": 4.6,
    "JUN": 4.41,
    "JUL": 4.57,
    "AUG": 5.41,
    "SEP": 5.1,
    "OCT": 5.32,
    "NOV": 5.62,
    "DEC": 5.61
  },
  {
    "LONG": -5.2,
    "LAT": -23.09,
    "CIDADE": "Flórida",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.42,
    "FEB": 5.64,
    "MAR": 5.61,
    "APR": 5.41,
    "MAY": 4.62,
    "JUN": 4.42,
    "JUL": 4.56,
    "AUG": 5.45,
    "SEP": 5.11,
    "OCT": 5.32,
    "NOV": 5.57,
    "DEC": 5.62
  },
  {
    "LONG": -51.77,
    "LAT": -23.15,
    "CIDADE": "Munhoz de Melo",
    "UF": "PARANÁ",
    "anual": 5.22,
    "JAN": 5.35,
    "FEB": 5.64,
    "MAR": 5.63,
    "APR": 5.38,
    "MAY": 4.6,
    "JUN": 4.46,
    "JUL": 4.59,
    "AUG": 5.43,
    "SEP": 5.11,
    "OCT": 5.32,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -51.53,
    "LAT": -23.11,
    "CIDADE": "Jaguapitã",
    "UF": "PARANÁ",
    "anual": 5.24,
    "JAN": 5.3,
    "FEB": 5.65,
    "MAR": 5.66,
    "APR": 5.48,
    "MAY": 4.59,
    "JUN": 4.5,
    "JUL": 4.62,
    "AUG": 5.45,
    "SEP": 5.15,
    "OCT": 5.35,
    "NOV": 5.6,
    "DEC": 5.59
  },
  {
    "LONG": -51.04,
    "LAT": -23.06,
    "CIDADE": "Sertanópolis",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.3,
    "FEB": 5.57,
    "MAR": 5.49,
    "APR": 5.41,
    "MAY": 4.61,
    "JUN": 4.45,
    "JUL": 4.61,
    "AUG": 5.47,
    "SEP": 5.09,
    "OCT": 5.25,
    "NOV": 5.48,
    "DEC": 5.58
  },
  {
    "LONG": -50.91,
    "LAT": -23.07,
    "CIDADE": "Rancho Alegre",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.36,
    "FEB": 5.62,
    "MAR": 5.55,
    "APR": 5.36,
    "MAY": 4.66,
    "JUN": 4.43,
    "JUL": 4.6,
    "AUG": 5.44,
    "SEP": 5.05,
    "OCT": 5.28,
    "NOV": 5.47,
    "DEC": 5.61
  },
  {
    "LONG": -50.75,
    "LAT": "#VALOR!",
    "CIDADE": "Leópolis",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.38,
    "FEB": 5.69,
    "MAR": 5.54,
    "APR": 5.34,
    "MAY": 4.68,
    "JUN": 4.48,
    "JUL": 4.64,
    "AUG": 5.51,
    "SEP": 5.11,
    "OCT": 5.31,
    "NOV": 5.5,
    "DEC": 5.6
  },
  {
    "LONG": -50.52,
    "LAT": -2.31,
    "CIDADE": "Santa Mariana",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.3,
    "FEB": 5.63,
    "MAR": 5.46,
    "APR": 5.36,
    "MAY": 4.65,
    "JUN": 4.45,
    "JUL": 4.62,
    "AUG": 5.48,
    "SEP": 5.09,
    "OCT": 5.26,
    "NOV": 5.46,
    "DEC": 5.61
  },
  {
    "LONG": -50.37,
    "LAT": -23.11,
    "CIDADE": "Bandeirantes",
    "UF": "PARANÁ",
    "anual": 5.21,
    "JAN": 5.26,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 5.4,
    "MAY": 4.63,
    "JUN": 4.44,
    "JUL": 4.63,
    "AUG": 5.52,
    "SEP": 5.1,
    "OCT": 5.3,
    "NOV": 5.43,
    "DEC": 5.6
  },
  {
    "LONG": -50.23,
    "LAT": -23.05,
    "CIDADE": "Andirá",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.24,
    "FEB": 5.65,
    "MAR": 5.46,
    "APR": 5.41,
    "MAY": 4.63,
    "JUN": 4.46,
    "JUL": 4.65,
    "AUG": 5.51,
    "SEP": 5.1,
    "OCT": 5.27,
    "NOV": 5.37,
    "DEC": 5.61
  },
  {
    "LONG": -50.18,
    "LAT": -23.12,
    "CIDADE": "Barra do Jacaré",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.23,
    "FEB": 5.66,
    "MAR": 5.43,
    "APR": 5.38,
    "MAY": 4.64,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.49,
    "SEP": 5.1,
    "OCT": 5.27,
    "NOV": 5.38,
    "DEC": 5.61
  },
  {
    "LONG": -53.29,
    "LAT": -22.96,
    "CIDADE": "Santa Cruz de Monte Castelo",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.31,
    "FEB": 5.65,
    "MAR": 5.66,
    "APR": 5.34,
    "MAY": 4.59,
    "JUN": 4.32,
    "JUL": 4.48,
    "AUG": 5.34,
    "SEP": 5.09,
    "OCT": 5.32,
    "NOV": 5.59,
    "DEC": 5.66
  },
  {
    "LONG": -53.2,
    "LAT": -23,
    "CIDADE": "Santa Isabel do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.27,
    "FEB": 5.62,
    "MAR": 5.63,
    "APR": 5.33,
    "MAY": 4.6,
    "JUN": 4.32,
    "JUL": 4.49,
    "AUG": 5.36,
    "SEP": 5.09,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -52.92,
    "LAT": -23.01,
    "CIDADE": "Planaltina do Paraná",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.33,
    "FEB": 5.64,
    "MAR": 5.65,
    "APR": 5.33,
    "MAY": 4.56,
    "JUN": 4.33,
    "JUL": 4.49,
    "AUG": 5.38,
    "SEP": 5.09,
    "OCT": 5.3,
    "NOV": 5.6,
    "DEC": 5.64
  },
  {
    "LONG": -52.16,
    "LAT": -22.96,
    "CIDADE": "Cruzeiro do Sul",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.33,
    "FEB": 5.61,
    "MAR": 5.61,
    "APR": 5.4,
    "MAY": 4.63,
    "JUN": 4.46,
    "JUL": 4.59,
    "AUG": 5.44,
    "SEP": 5.12,
    "OCT": 5.34,
    "NOV": 5.61,
    "DEC": 5.59
  },
  {
    "LONG": -51.95,
    "LAT": -23.01,
    "CIDADE": "Lobato",
    "UF": "PARANÁ",
    "anual": 5.24,
    "JAN": 5.35,
    "FEB": 5.67,
    "MAR": 5.64,
    "APR": 5.39,
    "MAY": 4.65,
    "JUN": 4.44,
    "JUL": 4.59,
    "AUG": 5.45,
    "SEP": 5.12,
    "OCT": 5.35,
    "NOV": 5.61,
    "DEC": 5.61
  },
  {
    "LONG": -51.81,
    "LAT": -23.04,
    "CIDADE": "Santa Fé",
    "UF": "PARANÁ",
    "anual": 5.24,
    "JAN": 5.3,
    "FEB": 5.67,
    "MAR": 5.66,
    "APR": 5.39,
    "MAY": 4.61,
    "JUN": 4.47,
    "JUL": 4.6,
    "AUG": 5.48,
    "SEP": 5.13,
    "OCT": 5.35,
    "NOV": 5.59,
    "DEC": 5.62
  },
  {
    "LONG": -51.65,
    "LAT": -22.97,
    "CIDADE": "Guaraci",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.36,
    "FEB": 5.66,
    "MAR": 5.69,
    "APR": 5.46,
    "MAY": 4.64,
    "JUN": 4.49,
    "JUL": 4.63,
    "AUG": 5.47,
    "SEP": 5.16,
    "OCT": 5.38,
    "NOV": 5.63,
    "DEC": 5.64
  },
  {
    "LONG": -51.44,
    "LAT": -23.04,
    "CIDADE": "Prado Ferreira",
    "UF": "PARANÁ",
    "anual": 5.25,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.64,
    "APR": 5.46,
    "MAY": 4.63,
    "JUN": 4.47,
    "JUL": 4.68,
    "AUG": 5.5,
    "SEP": 5.15,
    "OCT": 5.34,
    "NOV": 5.62,
    "DEC": 5.6
  },
  {
    "LONG": -5.15,
    "LAT": -22.97,
    "CIDADE": "Miraselva",
    "UF": "PARANÁ",
    "anual": 5.25,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.64,
    "APR": 5.46,
    "MAY": 4.63,
    "JUN": 4.47,
    "JUL": 4.68,
    "AUG": 5.5,
    "SEP": 5.15,
    "OCT": 5.34,
    "NOV": 5.62,
    "DEC": 5.6
  },
  {
    "LONG": -51.19,
    "LAT": -22.99,
    "CIDADE": "Bela Vista do Paraíso",
    "UF": "PARANÁ",
    "anual": 5.24,
    "JAN": 5.35,
    "FEB": 5.62,
    "MAR": 5.58,
    "APR": 5.44,
    "MAY": 4.67,
    "JUN": 4.49,
    "JUL": 4.67,
    "AUG": 5.5,
    "SEP": 5.1,
    "OCT": 5.31,
    "NOV": 5.55,
    "DEC": 5.63
  },
  {
    "LONG": -50.82,
    "LAT": -23.04,
    "CIDADE": "Sertaneja",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.4,
    "FEB": 5.7,
    "MAR": 5.51,
    "APR": 5.43,
    "MAY": 4.68,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.46,
    "SEP": 5.02,
    "OCT": 5.31,
    "NOV": 5.49,
    "DEC": 5.67
  },
  {
    "LONG": -50.41,
    "LAT": -2.3,
    "CIDADE": "Itambaracá",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.3,
    "FEB": 5.61,
    "MAR": 5.5,
    "APR": 5.37,
    "MAY": 4.65,
    "JUN": 4.42,
    "JUL": 4.6,
    "AUG": 5.48,
    "SEP": 5.08,
    "OCT": 5.28,
    "NOV": 5.46,
    "DEC": 5.61
  },
  {
    "LONG": -50.08,
    "LAT": -23.04,
    "CIDADE": "Cambará",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.19,
    "FEB": 5.64,
    "MAR": 5.49,
    "APR": 5.39,
    "MAY": 4.65,
    "JUN": 4.48,
    "JUL": 4.65,
    "AUG": 5.52,
    "SEP": 5.12,
    "OCT": 5.31,
    "NOV": 5.38,
    "DEC": 5.61
  },
  {
    "LONG": -53.14,
    "LAT": -22.92,
    "CIDADE": "Loanda",
    "UF": "PARANÁ",
    "anual": 5.22,
    "JAN": 5.33,
    "FEB": 5.68,
    "MAR": 5.65,
    "APR": 5.36,
    "MAY": 4.61,
    "JUN": 4.37,
    "JUL": 4.52,
    "AUG": 5.36,
    "SEP": 5.12,
    "OCT": 5.33,
    "NOV": 5.6,
    "DEC": 5.68
  },
  {
    "LONG": -5.27,
    "LAT": -2.29,
    "CIDADE": "Guairaçá",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.4,
    "FEB": 5.7,
    "MAR": 5.63,
    "APR": 5.35,
    "MAY": 4.59,
    "JUN": 4.37,
    "JUL": 4.57,
    "AUG": 5.43,
    "SEP": 5.14,
    "OCT": 5.33,
    "NOV": 5.61,
    "DEC": 5.65
  },
  {
    "LONG": -5.23,
    "LAT": -2.29,
    "CIDADE": "São João do Caiuá",
    "UF": "PARANÁ",
    "anual": 5.25,
    "JAN": 5.38,
    "FEB": 5.67,
    "MAR": 5.66,
    "APR": 5.38,
    "MAY": 4.63,
    "JUN": 4.43,
    "JUL": 4.58,
    "AUG": 5.44,
    "SEP": 5.14,
    "OCT": 5.36,
    "NOV": 5.64,
    "DEC": 5.67
  },
  {
    "LONG": -52.16,
    "LAT": -22.93,
    "CIDADE": "Paranacity",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.32,
    "FEB": 5.61,
    "MAR": 5.63,
    "APR": 5.4,
    "MAY": 4.63,
    "JUN": 4.47,
    "JUL": 4.6,
    "AUG": 5.46,
    "SEP": 5.14,
    "OCT": 5.34,
    "NOV": 5.61,
    "DEC": 5.62
  },
  {
    "LONG": -51.8,
    "LAT": -22.91,
    "CIDADE": "Nossa Senhora das Graças",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.36,
    "FEB": 5.69,
    "MAR": 5.69,
    "APR": 5.43,
    "MAY": 4.66,
    "JUN": 4.49,
    "JUL": 4.65,
    "AUG": 5.5,
    "SEP": 5.16,
    "OCT": 5.39,
    "NOV": 5.62,
    "DEC": 5.65
  },
  {
    "LONG": -51.39,
    "LAT": -22.86,
    "CIDADE": "Florestópolis",
    "UF": "PARANÁ",
    "anual": 5.25,
    "JAN": 5.35,
    "FEB": 5.66,
    "MAR": 5.64,
    "APR": 5.45,
    "MAY": 4.65,
    "JUN": 4.46,
    "JUL": 4.67,
    "AUG": 5.47,
    "SEP": 5.13,
    "OCT": 5.34,
    "NOV": 5.59,
    "DEC": 5.64
  },
  {
    "LONG": -51.03,
    "LAT": -2.29,
    "CIDADE": "Primeiro de Maio",
    "UF": "PARANÁ",
    "anual": 5.28,
    "JAN": 5.43,
    "FEB": 5.71,
    "MAR": 5.63,
    "APR": 5.5,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.68,
    "AUG": 5.46,
    "SEP": 5.08,
    "OCT": 5.35,
    "NOV": 5.59,
    "DEC": 5.73
  },
  {
    "LONG": -53.27,
    "LAT": -22.78,
    "CIDADE": "Porto Rico",
    "UF": "PARANÁ",
    "anual": 5.21,
    "JAN": 5.39,
    "FEB": 5.71,
    "MAR": 5.71,
    "APR": 5.37,
    "MAY": 4.59,
    "JUN": 4.35,
    "JUL": 4.47,
    "AUG": 5.28,
    "SEP": 5.06,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.7
  },
  {
    "LONG": -53.22,
    "LAT": -22.82,
    "CIDADE": "São Pedro do Paraná",
    "UF": "PARANÁ",
    "anual": 5.21,
    "JAN": 5.39,
    "FEB": 5.71,
    "MAR": 5.71,
    "APR": 5.37,
    "MAY": 4.59,
    "JUN": 4.35,
    "JUL": 4.47,
    "AUG": 5.28,
    "SEP": 5.06,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.7
  },
  {
    "LONG": -52.99,
    "LAT": -22.76,
    "CIDADE": "Nova Londrina",
    "UF": "PARANÁ",
    "anual": 5.24,
    "JAN": 5.42,
    "FEB": 5.71,
    "MAR": 5.67,
    "APR": 5.34,
    "MAY": 4.61,
    "JUN": 4.4,
    "JUL": 4.54,
    "AUG": 5.38,
    "SEP": 5.14,
    "OCT": 5.32,
    "NOV": 5.63,
    "DEC": 5.7
  },
  {
    "LONG": -52.2,
    "LAT": -22.75,
    "CIDADE": "Inajá",
    "UF": "PARANÁ",
    "anual": 5.26,
    "JAN": 5.36,
    "FEB": 5.7,
    "MAR": 5.69,
    "APR": 5.4,
    "MAY": 4.66,
    "JUN": 4.46,
    "JUL": 4.6,
    "AUG": 5.46,
    "SEP": 5.14,
    "OCT": 5.36,
    "NOV": 5.64,
    "DEC": 5.66
  },
  {
    "LONG": -51.97,
    "LAT": -22.84,
    "CIDADE": "Colorado",
    "UF": "PARANÁ",
    "anual": 5.28,
    "JAN": 5.34,
    "FEB": 5.72,
    "MAR": 5.7,
    "APR": 5.44,
    "MAY": 4.67,
    "JUN": 4.49,
    "JUL": 4.63,
    "AUG": 5.53,
    "SEP": 5.16,
    "OCT": 5.39,
    "NOV": 5.62,
    "DEC": 5.64
  },
  {
    "LONG": -51.71,
    "LAT": -22.79,
    "CIDADE": "Cafeara",
    "UF": "PARANÁ",
    "anual": 5.3,
    "JAN": 5.37,
    "FEB": 5.75,
    "MAR": 5.74,
    "APR": 5.46,
    "MAY": 4.68,
    "JUN": 4.5,
    "JUL": 4.68,
    "AUG": 5.51,
    "SEP": 5.19,
    "OCT": 5.41,
    "NOV": 5.63,
    "DEC": 5.67
  },
  {
    "LONG": -51.66,
    "LAT": -22.76,
    "CIDADE": "Lupionópolis",
    "UF": "PARANÁ",
    "anual": 5.29,
    "JAN": 5.36,
    "FEB": 5.74,
    "MAR": 5.74,
    "APR": 5.47,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.69,
    "AUG": 5.5,
    "SEP": 5.17,
    "OCT": 5.4,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -51.6,
    "LAT": -22.82,
    "CIDADE": "Centenário do Sul",
    "UF": "PARANÁ",
    "anual": 5.29,
    "JAN": 5.38,
    "FEB": 5.69,
    "MAR": 5.69,
    "APR": 5.5,
    "MAY": 4.68,
    "JUN": 4.48,
    "JUL": 4.69,
    "AUG": 5.51,
    "SEP": 5.17,
    "OCT": 5.39,
    "NOV": 5.57,
    "DEC": 5.66
  },
  {
    "LONG": -51.38,
    "LAT": -22.75,
    "CIDADE": "Porecatu",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.35,
    "FEB": 5.68,
    "MAR": 5.71,
    "APR": 5.5,
    "MAY": 4.67,
    "JUN": 4.45,
    "JUL": 4.65,
    "AUG": 5.49,
    "SEP": 5.09,
    "OCT": 5.36,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -51.23,
    "LAT": -22.78,
    "CIDADE": "Alvorada do Sul",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.43,
    "FEB": 5.73,
    "MAR": 5.7,
    "APR": 5.5,
    "MAY": 4.7,
    "JUN": 4.43,
    "JUL": 4.65,
    "AUG": 5.46,
    "SEP": 5.03,
    "OCT": 5.36,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -53.04,
    "LAT": -2.27,
    "CIDADE": "Marilena",
    "UF": "PARANÁ",
    "anual": 5.26,
    "JAN": 5.46,
    "FEB": 5.77,
    "MAR": 5.75,
    "APR": 5.4,
    "MAY": 4.63,
    "JUN": 4.38,
    "JUL": 4.54,
    "AUG": 5.32,
    "SEP": 5.12,
    "OCT": 5.34,
    "NOV": 5.63,
    "DEC": 5.74
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.27,
    "CIDADE": "Diamante do Norte",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.46,
    "FEB": 5.78,
    "MAR": 5.7,
    "APR": 5.36,
    "MAY": 4.66,
    "JUN": 4.44,
    "JUL": 4.57,
    "AUG": 5.39,
    "SEP": 5.16,
    "OCT": 5.33,
    "NOV": 5.63,
    "DEC": 5.75
  },
  {
    "LONG": -52.89,
    "LAT": -22.73,
    "CIDADE": "Itaúna do Sul",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.46,
    "FEB": 5.78,
    "MAR": 5.7,
    "APR": 5.36,
    "MAY": 4.66,
    "JUN": 4.44,
    "JUL": 4.57,
    "AUG": 5.39,
    "SEP": 5.16,
    "OCT": 5.33,
    "NOV": 5.63,
    "DEC": 5.75
  },
  {
    "LONG": -52.62,
    "LAT": -22.73,
    "CIDADE": "Terra Rica",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.44,
    "FEB": 5.78,
    "MAR": 5.7,
    "APR": 5.41,
    "MAY": 4.64,
    "JUN": 4.4,
    "JUL": 4.58,
    "AUG": 5.42,
    "SEP": 5.14,
    "OCT": 5.34,
    "NOV": 5.64,
    "DEC": 5.74
  },
  {
    "LONG": -52.34,
    "LAT": -22.74,
    "CIDADE": "Santo Antônio do Caiuá",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.43,
    "FEB": 5.73,
    "MAR": 5.73,
    "APR": 5.4,
    "MAY": 4.67,
    "JUN": 4.44,
    "JUL": 4.6,
    "AUG": 5.45,
    "SEP": 5.13,
    "OCT": 5.35,
    "NOV": 5.65,
    "DEC": 5.67
  },
  {
    "LONG": -52.08,
    "LAT": -22.66,
    "CIDADE": "Paranapoema",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.36,
    "FEB": 5.69,
    "MAR": 5.7,
    "APR": 5.42,
    "MAY": 4.7,
    "JUN": 4.48,
    "JUL": 4.64,
    "AUG": 5.52,
    "SEP": 5.16,
    "OCT": 5.35,
    "NOV": 5.61,
    "DEC": 5.65
  },
  {
    "LONG": -5.18,
    "LAT": -2.27,
    "CIDADE": "Santo Inácio",
    "UF": "PARANÁ",
    "anual": 5.28,
    "JAN": 5.36,
    "FEB": 5.72,
    "MAR": 5.74,
    "APR": 5.47,
    "MAY": 4.69,
    "JUN": 4.48,
    "JUL": 4.67,
    "AUG": 5.48,
    "SEP": 5.16,
    "OCT": 5.38,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -52.04,
    "LAT": -22.55,
    "CIDADE": "Jardim Olinda",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.38,
    "FEB": 5.69,
    "MAR": 5.72,
    "APR": 5.42,
    "MAY": 4.68,
    "JUN": 4.5,
    "JUL": 4.64,
    "AUG": 5.49,
    "SEP": 5.13,
    "OCT": 5.37,
    "NOV": 5.59,
    "DEC": 5.66
  },
  {
    "LONG": -51.9,
    "LAT": -22.64,
    "CIDADE": "Santa Inês",
    "UF": "PARANÁ",
    "anual": 5.28,
    "JAN": 5.39,
    "FEB": 5.72,
    "MAR": 5.73,
    "APR": 5.45,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.63,
    "AUG": 5.49,
    "SEP": 5.12,
    "OCT": 5.36,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -51.97,
    "LAT": -22.62,
    "CIDADE": "Itaguajé",
    "UF": "PARANÁ",
    "anual": 5.28,
    "JAN": 5.39,
    "FEB": 5.72,
    "MAR": 5.73,
    "APR": 5.45,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.63,
    "AUG": 5.49,
    "SEP": 5.12,
    "OCT": 5.36,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -40.5,
    "LAT": -9.39,
    "CIDADE": "Petrolina",
    "UF": "PERNAMBUCO",
    "anual": 5.78,
    "JAN": 6.01,
    "FEB": 5.97,
    "MAR": 6.04,
    "APR": 5.45,
    "MAY": 5.16,
    "JUN": 4.97,
    "JUL": 5.2,
    "AUG": 5.85,
    "SEP": 6.41,
    "OCT": 6.25,
    "NOV": 6.16,
    "DEC": 5.95
  },
  {
    "LONG": -38.27,
    "LAT": -9.19,
    "CIDADE": "Jatobá",
    "UF": "PERNAMBUCO",
    "anual": 5.58,
    "JAN": 5.98,
    "FEB": 5.95,
    "MAR": 6.09,
    "APR": 5.63,
    "MAY": 4.93,
    "JUN": 4.41,
    "JUL": 4.6,
    "AUG": 5.18,
    "SEP": 5.96,
    "OCT": 5.96,
    "NOV": 6.24,
    "DEC": 6.01
  },
  {
    "LONG": -3.67,
    "LAT": -9.17,
    "CIDADE": "Bom Conselho",
    "UF": "PERNAMBUCO",
    "anual": 5.35,
    "JAN": 5.72,
    "FEB": 5.72,
    "MAR": 5.9,
    "APR": 5.4,
    "MAY": 4.52,
    "JUN": 4.1,
    "JUL": 4.26,
    "AUG": 4.83,
    "SEP": 5.63,
    "OCT": 5.85,
    "NOV": 6.22,
    "DEC": 6.01
  },
  {
    "LONG": "#VALOR!",
    "LAT": -9.08,
    "CIDADE": "Petrolândia",
    "UF": "PERNAMBUCO",
    "anual": 5.64,
    "JAN": 5.97,
    "FEB": 5.93,
    "MAR": 6.1,
    "APR": 5.66,
    "MAY": 4.98,
    "JUN": 4.49,
    "JUL": 4.7,
    "AUG": 5.38,
    "SEP": 6.08,
    "OCT": 6.03,
    "NOV": 6.3,
    "DEC": 6.04
  },
  {
    "LONG": -38.15,
    "LAT": -9.1,
    "CIDADE": "Tacaratu",
    "UF": "PERNAMBUCO",
    "anual": 5.64,
    "JAN": 6.02,
    "FEB": 5.92,
    "MAR": 6.06,
    "APR": 5.6,
    "MAY": 4.92,
    "JUN": 4.46,
    "JUL": 4.63,
    "AUG": 5.33,
    "SEP": 6.14,
    "OCT": 6.14,
    "NOV": 6.36,
    "DEC": 6.05
  },
  {
    "LONG": -37.12,
    "LAT": -9.11,
    "CIDADE": "Águas Belas",
    "UF": "PERNAMBUCO",
    "anual": 5.47,
    "JAN": 5.87,
    "FEB": 5.82,
    "MAR": 6.02,
    "APR": 5.5,
    "MAY": 4.68,
    "JUN": 4.26,
    "JUL": 4.47,
    "AUG": 5.02,
    "SEP": 5.79,
    "OCT": 5.96,
    "NOV": 6.22,
    "DEC": 5.98
  },
  {
    "LONG": -36.62,
    "LAT": -0.91,
    "CIDADE": "Terezinha",
    "UF": "PERNAMBUCO",
    "anual": 5.39,
    "JAN": 5.72,
    "FEB": 5.73,
    "MAR": 5.94,
    "APR": 5.45,
    "MAY": 4.55,
    "JUN": 4.22,
    "JUL": 4.33,
    "AUG": 4.91,
    "SEP": 5.69,
    "OCT": 5.86,
    "NOV": 6.24,
    "DEC": 6.02
  },
  {
    "LONG": -36.46,
    "LAT": -9.13,
    "CIDADE": "Lagoa do Ouro",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.68,
    "FEB": 5.66,
    "MAR": 5.84,
    "APR": 5.38,
    "MAY": 4.51,
    "JUN": 4.06,
    "JUL": 4.13,
    "AUG": 4.72,
    "SEP": 5.53,
    "OCT": 5.71,
    "NOV": 6.07,
    "DEC": 5.91
  },
  {
    "LONG": -36.32,
    "LAT": -9.12,
    "CIDADE": "Correntes",
    "UF": "PERNAMBUCO",
    "anual": 5.24,
    "JAN": 5.67,
    "FEB": 5.66,
    "MAR": 5.87,
    "APR": 5.38,
    "MAY": 4.51,
    "JUN": 4.03,
    "JUL": 4.08,
    "AUG": 4.71,
    "SEP": 5.46,
    "OCT": 5.62,
    "NOV": 6.01,
    "DEC": 5.85
  },
  {
    "LONG": -40.27,
    "LAT": -8.99,
    "CIDADE": "Lagoa Grande",
    "UF": "PERNAMBUCO",
    "anual": 5.71,
    "JAN": 5.86,
    "FEB": 5.74,
    "MAR": 5.93,
    "APR": 5.47,
    "MAY": 5.07,
    "JUN": 4.88,
    "JUL": 5.11,
    "AUG": 5.85,
    "SEP": 6.43,
    "OCT": 6.22,
    "NOV": 6.14,
    "DEC": 5.82
  },
  {
    "LONG": -37.62,
    "LAT": -0.9,
    "CIDADE": "Manari",
    "UF": "PERNAMBUCO",
    "anual": 5.57,
    "JAN": 6.02,
    "FEB": 5.92,
    "MAR": 6.07,
    "APR": 5.66,
    "MAY": 4.79,
    "JUN": 4.27,
    "JUL": 4.46,
    "AUG": 5.12,
    "SEP": 5.95,
    "OCT": 6.12,
    "NOV": 6.37,
    "DEC": 6.08
  },
  {
    "LONG": -36.85,
    "LAT": -9.04,
    "CIDADE": "Iati",
    "UF": "PERNAMBUCO",
    "anual": 5.43,
    "JAN": 5.81,
    "FEB": 5.72,
    "MAR": 5.89,
    "APR": 5.51,
    "MAY": 4.68,
    "JUN": 4.25,
    "JUL": 4.39,
    "AUG": 5.01,
    "SEP": 5.77,
    "OCT": 5.96,
    "NOV": 6.22,
    "DEC": 5.98
  },
  {
    "LONG": -36.69,
    "LAT": -8.97,
    "CIDADE": "Saloá",
    "UF": "PERNAMBUCO",
    "anual": 5.34,
    "JAN": 5.74,
    "FEB": 5.67,
    "MAR": 5.9,
    "APR": 5.42,
    "MAY": 4.51,
    "JUN": 4.08,
    "JUL": 4.21,
    "AUG": 4.81,
    "SEP": 5.63,
    "OCT": 5.85,
    "NOV": 6.23,
    "DEC": 5.99
  },
  {
    "LONG": -36.57,
    "LAT": -9.03,
    "CIDADE": "Brejão",
    "UF": "PERNAMBUCO",
    "anual": 5.29,
    "JAN": 5.77,
    "FEB": 5.68,
    "MAR": 5.86,
    "APR": 5.38,
    "MAY": 4.5,
    "JUN": 4,
    "JUL": 4.12,
    "AUG": 4.73,
    "SEP": 5.57,
    "OCT": 5.77,
    "NOV": 6.19,
    "DEC": 5.94
  },
  {
    "LONG": -36.32,
    "LAT": -9.01,
    "CIDADE": "Palmeirina",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.65,
    "FEB": 5.55,
    "MAR": 5.81,
    "APR": 5.33,
    "MAY": 4.47,
    "JUN": 3.96,
    "JUL": 4.04,
    "AUG": 4.64,
    "SEP": 5.42,
    "OCT": 5.57,
    "NOV": 6.04,
    "DEC": 5.88
  },
  {
    "LONG": -37.83,
    "LAT": "#VALOR!",
    "CIDADE": "Inajá",
    "UF": "PERNAMBUCO",
    "anual": 5.61,
    "JAN": 6.06,
    "FEB": 5.9,
    "MAR": 6.13,
    "APR": 5.69,
    "MAY": 4.83,
    "JUN": 4.34,
    "JUL": 4.53,
    "AUG": 5.2,
    "SEP": 6.01,
    "OCT": 6.17,
    "NOV": 6.34,
    "DEC": 6.14
  },
  {
    "LONG": -37.42,
    "LAT": -0.89,
    "CIDADE": "Itaíba",
    "UF": "PERNAMBUCO",
    "anual": 5.62,
    "JAN": 6.02,
    "FEB": 5.95,
    "MAR": 6.19,
    "APR": 5.71,
    "MAY": 4.81,
    "JUN": 4.35,
    "JUL": 4.52,
    "AUG": 5.22,
    "SEP": 6.03,
    "OCT": 6.18,
    "NOV": 6.38,
    "DEC": 6.08
  },
  {
    "LONG": -36.66,
    "LAT": -8.92,
    "CIDADE": "Paranatama",
    "UF": "PERNAMBUCO",
    "anual": 5.4,
    "JAN": 5.81,
    "FEB": 5.71,
    "MAR": 5.94,
    "APR": 5.45,
    "MAY": 4.61,
    "JUN": 4.19,
    "JUL": 4.34,
    "AUG": 4.97,
    "SEP": 5.76,
    "OCT": 5.9,
    "NOV": 6.18,
    "DEC": 5.99
  },
  {
    "LONG": -36.5,
    "LAT": -8.88,
    "CIDADE": "Garanhuns",
    "UF": "PERNAMBUCO",
    "anual": 5.25,
    "JAN": 5.68,
    "FEB": 5.54,
    "MAR": 5.82,
    "APR": 5.31,
    "MAY": 4.53,
    "JUN": 4.08,
    "JUL": 4.2,
    "AUG": 4.79,
    "SEP": 5.5,
    "OCT": 5.64,
    "NOV": 6.02,
    "DEC": 5.85
  },
  {
    "LONG": -36.37,
    "LAT": -8.88,
    "CIDADE": "São João",
    "UF": "PERNAMBUCO",
    "anual": 5.21,
    "JAN": 5.62,
    "FEB": 5.51,
    "MAR": 5.79,
    "APR": 5.29,
    "MAY": 4.54,
    "JUN": 4.08,
    "JUL": 4.17,
    "AUG": 4.75,
    "SEP": 5.45,
    "OCT": 5.58,
    "NOV": 5.97,
    "DEC": 5.8
  },
  {
    "LONG": -3.63,
    "LAT": -8.89,
    "CIDADE": "Angelim",
    "UF": "PERNAMBUCO",
    "anual": 5.19,
    "JAN": 5.52,
    "FEB": 5.53,
    "MAR": 5.76,
    "APR": 5.31,
    "MAY": 4.52,
    "JUN": 4.08,
    "JUL": 4.18,
    "AUG": 4.74,
    "SEP": 5.44,
    "OCT": 5.55,
    "NOV": 5.92,
    "DEC": 5.78
  },
  {
    "LONG": -36.2,
    "LAT": -8.88,
    "CIDADE": "Canhotinho",
    "UF": "PERNAMBUCO",
    "anual": 5.15,
    "JAN": 5.5,
    "FEB": 5.51,
    "MAR": 5.69,
    "APR": 5.27,
    "MAY": 4.49,
    "JUN": 4.05,
    "JUL": 4.1,
    "AUG": 4.7,
    "SEP": 5.4,
    "OCT": 5.52,
    "NOV": 5.86,
    "DEC": 5.72
  },
  {
    "LONG": -35.15,
    "LAT": -8.89,
    "CIDADE": "São José da Coroa Grande",
    "UF": "PERNAMBUCO",
    "anual": 5.4,
    "JAN": 5.62,
    "FEB": 5.79,
    "MAR": 6.05,
    "APR": 5.3,
    "MAY": 4.7,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 5.14,
    "SEP": 5.67,
    "OCT": 5.83,
    "NOV": 5.89,
    "DEC": 5.85
  },
  {
    "LONG": -39.82,
    "LAT": -8.8,
    "CIDADE": "Santa Maria da Boa Vista",
    "UF": "PERNAMBUCO",
    "anual": 5.68,
    "JAN": 5.85,
    "FEB": 5.75,
    "MAR": 5.97,
    "APR": 5.51,
    "MAY": 5,
    "JUN": 4.75,
    "JUL": 4.93,
    "AUG": 5.65,
    "SEP": 6.34,
    "OCT": 6.25,
    "NOV": 6.21,
    "DEC": 5.91
  },
  {
    "LONG": -38.96,
    "LAT": -8.75,
    "CIDADE": "Belém de São Francisco",
    "UF": "PERNAMBUCO",
    "anual": 5.75,
    "JAN": 6.02,
    "FEB": 6.08,
    "MAR": 6.23,
    "APR": 5.76,
    "MAY": 5.07,
    "JUN": 4.63,
    "JUL": 4.85,
    "AUG": 5.49,
    "SEP": 6.17,
    "OCT": 6.15,
    "NOV": 6.27,
    "DEC": 6.21
  },
  {
    "LONG": -38.7,
    "LAT": -8.82,
    "CIDADE": "Itacuruba",
    "UF": "PERNAMBUCO",
    "anual": 5.82,
    "JAN": 6.16,
    "FEB": 6.13,
    "MAR": 6.33,
    "APR": 5.87,
    "MAY": 5.19,
    "JUN": 4.87,
    "JUL": 5.11,
    "AUG": 5.62,
    "SEP": 6.16,
    "OCT": 6.09,
    "NOV": 6.19,
    "DEC": 6.15
  },
  {
    "LONG": -37.34,
    "LAT": -8.75,
    "CIDADE": "Tupanatinga",
    "UF": "PERNAMBUCO",
    "anual": 5.62,
    "JAN": 5.98,
    "FEB": 5.95,
    "MAR": 6.2,
    "APR": 5.75,
    "MAY": 4.8,
    "JUN": 4.31,
    "JUL": 4.46,
    "AUG": 5.18,
    "SEP": 6.05,
    "OCT": 6.21,
    "NOV": 6.43,
    "DEC": 6.15
  },
  {
    "LONG": -36.63,
    "LAT": -8.77,
    "CIDADE": "Caetés",
    "UF": "PERNAMBUCO",
    "anual": 5.44,
    "JAN": 5.83,
    "FEB": 5.77,
    "MAR": 5.99,
    "APR": 5.52,
    "MAY": 4.64,
    "JUN": 4.2,
    "JUL": 4.39,
    "AUG": 5.02,
    "SEP": 5.75,
    "OCT": 5.91,
    "NOV": 6.19,
    "DEC": 6.01
  },
  {
    "LONG": -3.6,
    "LAT": -8.81,
    "CIDADE": "Quipapá",
    "UF": "PERNAMBUCO",
    "anual": 5.06,
    "JAN": 5.42,
    "FEB": 5.46,
    "MAR": 5.66,
    "APR": 5.15,
    "MAY": 4.37,
    "JUN": 3.97,
    "JUL": 3.96,
    "AUG": 4.52,
    "SEP": 5.27,
    "OCT": 5.46,
    "NOV": 5.76,
    "DEC": 5.7
  },
  {
    "LONG": -35.95,
    "LAT": -0.88,
    "CIDADE": "São Benedito do Sul",
    "UF": "PERNAMBUCO",
    "anual": 5.09,
    "JAN": 5.47,
    "FEB": 5.49,
    "MAR": 5.71,
    "APR": 5.22,
    "MAY": 4.37,
    "JUN": 3.98,
    "JUL": 4.06,
    "AUG": 4.61,
    "SEP": 5.29,
    "OCT": 5.42,
    "NOV": 5.74,
    "DEC": 5.7
  },
  {
    "LONG": -35.83,
    "LAT": -8.79,
    "CIDADE": "Maraial",
    "UF": "PERNAMBUCO",
    "anual": 5.06,
    "JAN": 5.36,
    "FEB": 5.46,
    "MAR": 5.66,
    "APR": 5.2,
    "MAY": 4.43,
    "JUN": 4,
    "JUL": 4.08,
    "AUG": 4.61,
    "SEP": 5.29,
    "OCT": 5.37,
    "NOV": 5.69,
    "DEC": 5.59
  },
  {
    "LONG": -35.62,
    "LAT": -0.88,
    "CIDADE": "Xexéu",
    "UF": "PERNAMBUCO",
    "anual": 5.09,
    "JAN": 5.34,
    "FEB": 5.42,
    "MAR": 5.65,
    "APR": 5.18,
    "MAY": 4.48,
    "JUN": 4.17,
    "JUL": 4.17,
    "AUG": 4.75,
    "SEP": 5.34,
    "OCT": 5.38,
    "NOV": 5.65,
    "DEC": 5.54
  },
  {
    "LONG": -35.1,
    "LAT": -8.76,
    "CIDADE": "Tamandaré",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.51,
    "FEB": 5.73,
    "MAR": 6,
    "APR": 5.25,
    "MAY": 4.6,
    "JUN": 4.41,
    "JUL": 4.46,
    "AUG": 5.07,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.83,
    "DEC": 5.78
  },
  {
    "LONG": -35.18,
    "LAT": -8.82,
    "CIDADE": "Barreiros",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.51,
    "FEB": 5.73,
    "MAR": 6,
    "APR": 5.25,
    "MAY": 4.6,
    "JUN": 4.41,
    "JUL": 4.46,
    "AUG": 5.07,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.83,
    "DEC": 5.78
  },
  {
    "LONG": -3.66,
    "LAT": -8.74,
    "CIDADE": "Capoeiras",
    "UF": "PERNAMBUCO",
    "anual": 5.47,
    "JAN": 5.88,
    "FEB": 5.75,
    "MAR": 5.95,
    "APR": 5.55,
    "MAY": 4.66,
    "JUN": 4.29,
    "JUL": 4.38,
    "AUG": 5.12,
    "SEP": 5.81,
    "OCT": 6,
    "NOV": 6.26,
    "DEC": 6.02
  },
  {
    "LONG": -36.42,
    "LAT": -0.87,
    "CIDADE": "Jupi",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.85,
    "APR": 5.39,
    "MAY": 4.59,
    "JUN": 4.16,
    "JUL": 4.24,
    "AUG": 4.92,
    "SEP": 5.57,
    "OCT": 5.68,
    "NOV": 6,
    "DEC": 5.84
  },
  {
    "LONG": -36.49,
    "LAT": -8.71,
    "CIDADE": "Jucati",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.85,
    "APR": 5.39,
    "MAY": 4.59,
    "JUN": 4.16,
    "JUL": 4.24,
    "AUG": 4.92,
    "SEP": 5.57,
    "OCT": 5.68,
    "NOV": 6,
    "DEC": 5.84
  },
  {
    "LONG": -3.63,
    "LAT": -8.66,
    "CIDADE": "Lajedo",
    "UF": "PERNAMBUCO",
    "anual": 5.29,
    "JAN": 5.67,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.36,
    "MAY": 4.61,
    "JUN": 4.19,
    "JUL": 4.27,
    "AUG": 4.93,
    "SEP": 5.58,
    "OCT": 5.63,
    "NOV": 5.96,
    "DEC": 5.82
  },
  {
    "LONG": -36.34,
    "LAT": -0.87,
    "CIDADE": "Calçado",
    "UF": "PERNAMBUCO",
    "anual": 5.29,
    "JAN": 5.67,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.36,
    "MAY": 4.61,
    "JUN": 4.19,
    "JUL": 4.27,
    "AUG": 4.93,
    "SEP": 5.58,
    "OCT": 5.63,
    "NOV": 5.96,
    "DEC": 5.82
  },
  {
    "LONG": -3.61,
    "LAT": -8.71,
    "CIDADE": "Jurema",
    "UF": "PERNAMBUCO",
    "anual": 5.28,
    "JAN": 5.6,
    "FEB": 5.6,
    "MAR": 5.76,
    "APR": 5.3,
    "MAY": 4.64,
    "JUN": 4.24,
    "JUL": 4.3,
    "AUG": 4.93,
    "SEP": 5.55,
    "OCT": 5.68,
    "NOV": 5.94,
    "DEC": 5.77
  },
  {
    "LONG": -36.01,
    "LAT": -8.66,
    "CIDADE": "Panelas",
    "UF": "PERNAMBUCO",
    "anual": 5.22,
    "JAN": 5.58,
    "FEB": 5.55,
    "MAR": 5.72,
    "APR": 5.25,
    "MAY": 4.59,
    "JUN": 4.21,
    "JUL": 4.24,
    "AUG": 4.85,
    "SEP": 5.45,
    "OCT": 5.61,
    "NOV": 5.9,
    "DEC": 5.74
  },
  {
    "LONG": -35.9,
    "LAT": -8.66,
    "CIDADE": "Lagoa dos Gatos",
    "UF": "PERNAMBUCO",
    "anual": 5.16,
    "JAN": 5.53,
    "FEB": 5.52,
    "MAR": 5.71,
    "APR": 5.22,
    "MAY": 4.49,
    "JUN": 4.15,
    "JUL": 4.17,
    "AUG": 4.75,
    "SEP": 5.36,
    "OCT": 5.52,
    "NOV": 5.83,
    "DEC": 5.67
  },
  {
    "LONG": -35.7,
    "LAT": -8.68,
    "CIDADE": "Catende",
    "UF": "PERNAMBUCO",
    "anual": 5.09,
    "JAN": 5.34,
    "FEB": 5.5,
    "MAR": 5.67,
    "APR": 5.21,
    "MAY": 4.51,
    "JUN": 4.09,
    "JUL": 4.1,
    "AUG": 4.68,
    "SEP": 5.31,
    "OCT": 5.43,
    "NOV": 5.71,
    "DEC": 5.56
  },
  {
    "LONG": -35.79,
    "LAT": -8.73,
    "CIDADE": "Jaqueira",
    "UF": "PERNAMBUCO",
    "anual": 5.09,
    "JAN": 5.34,
    "FEB": 5.5,
    "MAR": 5.67,
    "APR": 5.21,
    "MAY": 4.51,
    "JUN": 4.09,
    "JUL": 4.1,
    "AUG": 4.68,
    "SEP": 5.31,
    "OCT": 5.43,
    "NOV": 5.71,
    "DEC": 5.56
  },
  {
    "LONG": -35.53,
    "LAT": -8.71,
    "CIDADE": "Água Preta",
    "UF": "PERNAMBUCO",
    "anual": 5.14,
    "JAN": 5.41,
    "FEB": 5.47,
    "MAR": 5.74,
    "APR": 5.17,
    "MAY": 4.55,
    "JUN": 4.22,
    "JUL": 4.23,
    "AUG": 4.76,
    "SEP": 5.41,
    "OCT": 5.49,
    "NOV": 5.7,
    "DEC": 5.6
  },
  {
    "LONG": -35.59,
    "LAT": -8.68,
    "CIDADE": "Palmares",
    "UF": "PERNAMBUCO",
    "anual": 5.14,
    "JAN": 5.41,
    "FEB": 5.47,
    "MAR": 5.74,
    "APR": 5.17,
    "MAY": 4.55,
    "JUN": 4.22,
    "JUL": 4.23,
    "AUG": 4.76,
    "SEP": 5.41,
    "OCT": 5.49,
    "NOV": 5.7,
    "DEC": 5.6
  },
  {
    "LONG": -35.15,
    "LAT": -8.66,
    "CIDADE": "Rio Formoso",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.52,
    "FEB": 5.72,
    "MAR": 5.96,
    "APR": 5.27,
    "MAY": 4.66,
    "JUN": 4.36,
    "JUL": 4.43,
    "AUG": 5.04,
    "SEP": 5.5,
    "OCT": 5.63,
    "NOV": 5.81,
    "DEC": 5.76
  },
  {
    "LONG": -3.96,
    "LAT": -8.61,
    "CIDADE": "Orocó",
    "UF": "PERNAMBUCO",
    "anual": 5.76,
    "JAN": 5.91,
    "FEB": 5.83,
    "MAR": 6.04,
    "APR": 5.65,
    "MAY": 5.1,
    "JUN": 4.76,
    "JUL": 4.99,
    "AUG": 5.69,
    "SEP": 6.39,
    "OCT": 6.35,
    "NOV": 6.35,
    "DEC": 6.07
  },
  {
    "LONG": -3.86,
    "LAT": -8.6,
    "CIDADE": "Floresta",
    "UF": "PERNAMBUCO",
    "anual": 5.73,
    "JAN": 6.01,
    "FEB": 5.97,
    "MAR": 6.14,
    "APR": 5.81,
    "MAY": 5.03,
    "JUN": 4.49,
    "JUL": 4.76,
    "AUG": 5.45,
    "SEP": 6.28,
    "OCT": 6.32,
    "NOV": 6.4,
    "DEC": 6.1
  },
  {
    "LONG": -37.16,
    "LAT": -0.86,
    "CIDADE": "Buíque",
    "UF": "PERNAMBUCO",
    "anual": 5.64,
    "JAN": 5.96,
    "FEB": 5.92,
    "MAR": 6.18,
    "APR": 5.74,
    "MAY": 4.89,
    "JUN": 4.4,
    "JUL": 4.54,
    "AUG": 5.27,
    "SEP": 6.08,
    "OCT": 6.21,
    "NOV": 6.41,
    "DEC": 6.12
  },
  {
    "LONG": -36.88,
    "LAT": -8.58,
    "CIDADE": "Venturosa",
    "UF": "PERNAMBUCO",
    "anual": 5.5,
    "JAN": 5.91,
    "FEB": 5.83,
    "MAR": 6.04,
    "APR": 5.62,
    "MAY": 4.74,
    "JUN": 4.28,
    "JUL": 4.28,
    "AUG": 5.05,
    "SEP": 5.87,
    "OCT": 6.04,
    "NOV": 6.3,
    "DEC": 6.03
  },
  {
    "LONG": -36.18,
    "LAT": -8.58,
    "CIDADE": "Ibirajuba",
    "UF": "PERNAMBUCO",
    "anual": 5.23,
    "JAN": 5.58,
    "FEB": 5.53,
    "MAR": 5.75,
    "APR": 5.32,
    "MAY": 4.58,
    "JUN": 4.14,
    "JUL": 4.27,
    "AUG": 4.82,
    "SEP": 5.45,
    "OCT": 5.62,
    "NOV": 5.99,
    "DEC": 5.74
  },
  {
    "LONG": -35.95,
    "LAT": -8.62,
    "CIDADE": "Cupira",
    "UF": "PERNAMBUCO",
    "anual": 5.28,
    "JAN": 5.69,
    "FEB": 5.65,
    "MAR": 5.94,
    "APR": 5.35,
    "MAY": 4.61,
    "JUN": 4.22,
    "JUL": 4.24,
    "AUG": 4.84,
    "SEP": 5.48,
    "OCT": 5.64,
    "NOV": 5.93,
    "DEC": 5.76
  },
  {
    "LONG": -35.83,
    "LAT": -8.63,
    "CIDADE": "Belém de Maria",
    "UF": "PERNAMBUCO",
    "anual": 5.25,
    "JAN": 5.6,
    "FEB": 5.67,
    "MAR": 5.89,
    "APR": 5.35,
    "MAY": 4.61,
    "JUN": 4.18,
    "JUL": 4.2,
    "AUG": 4.82,
    "SEP": 5.44,
    "OCT": 5.61,
    "NOV": 5.9,
    "DEC": 5.75
  },
  {
    "LONG": -35.53,
    "LAT": -8.62,
    "CIDADE": "Joaquim Nabuco",
    "UF": "PERNAMBUCO",
    "anual": 5.04,
    "JAN": 5.36,
    "FEB": 5.41,
    "MAR": 5.62,
    "APR": 5.1,
    "MAY": 4.43,
    "JUN": 4.09,
    "JUL": 4.06,
    "AUG": 4.61,
    "SEP": 5.25,
    "OCT": 5.36,
    "NOV": 5.66,
    "DEC": 5.55
  },
  {
    "LONG": -35.38,
    "LAT": -8.58,
    "CIDADE": "Gameleira",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.39,
    "FEB": 5.58,
    "MAR": 5.73,
    "APR": 5.2,
    "MAY": 4.61,
    "JUN": 4.24,
    "JUL": 4.3,
    "AUG": 4.87,
    "SEP": 5.43,
    "OCT": 5.56,
    "NOV": 5.76,
    "DEC": 5.72
  },
  {
    "LONG": -35.11,
    "LAT": -8.59,
    "CIDADE": "Sirinhaém",
    "UF": "PERNAMBUCO",
    "anual": 5.28,
    "JAN": 5.43,
    "FEB": 5.73,
    "MAR": 5.96,
    "APR": 5.23,
    "MAY": 4.67,
    "JUN": 4.33,
    "JUL": 4.42,
    "AUG": 5.01,
    "SEP": 5.5,
    "OCT": 5.65,
    "NOV": 5.76,
    "DEC": 5.67
  },
  {
    "LONG": -41.01,
    "LAT": -8.51,
    "CIDADE": "Afrânio",
    "UF": "PERNAMBUCO",
    "anual": 5.87,
    "JAN": 5.8,
    "FEB": 5.65,
    "MAR": 5.77,
    "APR": 5.45,
    "MAY": 5.34,
    "JUN": 5.28,
    "JUL": 5.63,
    "AUG": 6.41,
    "SEP": 6.71,
    "OCT": 6.45,
    "NOV": 6.14,
    "DEC": 5.79
  },
  {
    "LONG": -39.31,
    "LAT": -8.51,
    "CIDADE": "Cabrobó",
    "UF": "PERNAMBUCO",
    "anual": 5.78,
    "JAN": 5.92,
    "FEB": 5.9,
    "MAR": 6.14,
    "APR": 5.7,
    "MAY": 5.13,
    "JUN": 4.79,
    "JUL": 5.04,
    "AUG": 5.71,
    "SEP": 6.33,
    "OCT": 6.31,
    "NOV": 6.36,
    "DEC": 6.06
  },
  {
    "LONG": -3.77,
    "LAT": -0.85,
    "CIDADE": "Ibimirim",
    "UF": "PERNAMBUCO",
    "anual": 5.73,
    "JAN": 6.03,
    "FEB": 6.03,
    "MAR": 6.25,
    "APR": 5.9,
    "MAY": 5.08,
    "JUN": 4.52,
    "JUL": 4.72,
    "AUG": 5.44,
    "SEP": 6.19,
    "OCT": 6.19,
    "NOV": 6.33,
    "DEC": 6.12
  },
  {
    "LONG": -36.95,
    "LAT": "#VALOR!",
    "CIDADE": "Pedra",
    "UF": "PERNAMBUCO",
    "anual": 5.56,
    "JAN": 5.86,
    "FEB": 5.9,
    "MAR": 6.11,
    "APR": 5.69,
    "MAY": 4.8,
    "JUN": 4.26,
    "JUL": 4.4,
    "AUG": 5.14,
    "SEP": 6,
    "OCT": 6.16,
    "NOV": 6.34,
    "DEC": 6.07
  },
  {
    "LONG": -36.78,
    "LAT": -8.47,
    "CIDADE": "Alagoinha",
    "UF": "PERNAMBUCO",
    "anual": 5.45,
    "JAN": 5.91,
    "FEB": 5.74,
    "MAR": 5.95,
    "APR": 5.55,
    "MAY": 4.64,
    "JUN": 4.17,
    "JUL": 4.29,
    "AUG": 5.03,
    "SEP": 5.82,
    "OCT": 6,
    "NOV": 6.3,
    "DEC": 6.04
  },
  {
    "LONG": -36.45,
    "LAT": -8.53,
    "CIDADE": "São Bento do Una",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.67,
    "FEB": 5.63,
    "MAR": 5.86,
    "APR": 5.46,
    "MAY": 4.65,
    "JUN": 4.15,
    "JUL": 4.28,
    "AUG": 4.9,
    "SEP": 5.54,
    "OCT": 5.72,
    "NOV": 6.02,
    "DEC": 5.82
  },
  {
    "LONG": -3.62,
    "LAT": -8.49,
    "CIDADE": "Cachoeirinha",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.62,
    "FEB": 5.63,
    "MAR": 5.86,
    "APR": 5.45,
    "MAY": 4.65,
    "JUN": 4.19,
    "JUL": 4.25,
    "AUG": 4.87,
    "SEP": 5.48,
    "OCT": 5.72,
    "NOV": 6.07,
    "DEC": 5.8
  },
  {
    "LONG": -36.06,
    "LAT": -8.49,
    "CIDADE": "Altinho",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.61,
    "FEB": 5.65,
    "MAR": 5.88,
    "APR": 5.38,
    "MAY": 4.64,
    "JUN": 4.21,
    "JUL": 4.27,
    "AUG": 4.87,
    "SEP": 5.49,
    "OCT": 5.63,
    "NOV": 5.92,
    "DEC": 5.7
  },
  {
    "LONG": -3.59,
    "LAT": -8.46,
    "CIDADE": "Agrestina",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.64,
    "FEB": 5.68,
    "MAR": 5.96,
    "APR": 5.41,
    "MAY": 4.62,
    "JUN": 4.25,
    "JUL": 4.34,
    "AUG": 4.93,
    "SEP": 5.55,
    "OCT": 5.67,
    "NOV": 5.9,
    "DEC": 5.71
  },
  {
    "LONG": -35.73,
    "LAT": -8.47,
    "CIDADE": "Bonito",
    "UF": "PERNAMBUCO",
    "anual": 5.17,
    "JAN": 5.5,
    "FEB": 5.57,
    "MAR": 5.79,
    "APR": 5.25,
    "MAY": 4.54,
    "JUN": 4.07,
    "JUL": 4.14,
    "AUG": 4.76,
    "SEP": 5.34,
    "OCT": 5.54,
    "NOV": 5.86,
    "DEC": 5.65
  },
  {
    "LONG": -35.55,
    "LAT": -8.47,
    "CIDADE": "Cortês",
    "UF": "PERNAMBUCO",
    "anual": 4.98,
    "JAN": 5.29,
    "FEB": 5.34,
    "MAR": 5.61,
    "APR": 5.06,
    "MAY": 4.4,
    "JUN": 4.03,
    "JUL": 4.03,
    "AUG": 4.57,
    "SEP": 5.13,
    "OCT": 5.26,
    "NOV": 5.6,
    "DEC": 5.51
  },
  {
    "LONG": -35.37,
    "LAT": "#VALOR!",
    "CIDADE": "Ribeirão",
    "UF": "PERNAMBUCO",
    "anual": 5.16,
    "JAN": 5.37,
    "FEB": 5.51,
    "MAR": 5.7,
    "APR": 5.17,
    "MAY": 4.56,
    "JUN": 4.22,
    "JUL": 4.28,
    "AUG": 4.82,
    "SEP": 5.39,
    "OCT": 5.54,
    "NOV": 5.7,
    "DEC": 5.65
  },
  {
    "LONG": -40.77,
    "LAT": -8.44,
    "CIDADE": "Dormentes",
    "UF": "PERNAMBUCO",
    "anual": 5.74,
    "JAN": 5.68,
    "FEB": 5.58,
    "MAR": 5.72,
    "APR": 5.36,
    "MAY": 5.09,
    "JUN": 4.99,
    "JUL": 5.34,
    "AUG": 6.21,
    "SEP": 6.62,
    "OCT": 6.36,
    "NOV": 6.11,
    "DEC": 5.76
  },
  {
    "LONG": -37.06,
    "LAT": -8.42,
    "CIDADE": "Arcoverde",
    "UF": "PERNAMBUCO",
    "anual": 5.65,
    "JAN": 5.81,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.76,
    "MAY": 4.98,
    "JUN": 4.5,
    "JUL": 4.66,
    "AUG": 5.41,
    "SEP": 6.15,
    "OCT": 6.21,
    "NOV": 6.32,
    "DEC": 6.03
  },
  {
    "LONG": -36.7,
    "LAT": -8.36,
    "CIDADE": "Pesqueira",
    "UF": "PERNAMBUCO",
    "anual": 5.39,
    "JAN": 5.79,
    "FEB": 5.75,
    "MAR": 5.93,
    "APR": 5.55,
    "MAY": 4.67,
    "JUN": 4.13,
    "JUL": 4.22,
    "AUG": 4.96,
    "SEP": 5.71,
    "OCT": 5.91,
    "NOV": 6.16,
    "DEC": 5.95
  },
  {
    "LONG": -36.56,
    "LAT": -8.36,
    "CIDADE": "Sanharó",
    "UF": "PERNAMBUCO",
    "anual": 5.37,
    "JAN": 5.7,
    "FEB": 5.7,
    "MAR": 5.92,
    "APR": 5.51,
    "MAY": 4.65,
    "JUN": 4.17,
    "JUL": 4.25,
    "AUG": 4.95,
    "SEP": 5.69,
    "OCT": 5.89,
    "NOV": 6.11,
    "DEC": 5.9
  },
  {
    "LONG": -35.8,
    "LAT": -8.43,
    "CIDADE": "São Joaquim do Monte",
    "UF": "PERNAMBUCO",
    "anual": 5.18,
    "JAN": 5.45,
    "FEB": 5.54,
    "MAR": 5.73,
    "APR": 5.24,
    "MAY": 4.55,
    "JUN": 4.12,
    "JUL": 4.22,
    "AUG": 4.85,
    "SEP": 5.44,
    "OCT": 5.58,
    "NOV": 5.85,
    "DEC": 5.6
  },
  {
    "LONG": -35.77,
    "LAT": -8.36,
    "CIDADE": "Camocim de São Félix",
    "UF": "PERNAMBUCO",
    "anual": 5.19,
    "JAN": 5.49,
    "FEB": 5.55,
    "MAR": 5.8,
    "APR": 5.24,
    "MAY": 4.53,
    "JUN": 4.09,
    "JUL": 4.18,
    "AUG": 4.82,
    "SEP": 5.41,
    "OCT": 5.62,
    "NOV": 5.86,
    "DEC": 5.66
  },
  {
    "LONG": -35.66,
    "LAT": -8.42,
    "CIDADE": "Barra de Guabiraba",
    "UF": "PERNAMBUCO",
    "anual": 5.12,
    "JAN": 5.41,
    "FEB": 5.5,
    "MAR": 5.74,
    "APR": 5.2,
    "MAY": 4.47,
    "JUN": 4.08,
    "JUL": 4.12,
    "AUG": 4.68,
    "SEP": 5.27,
    "OCT": 5.47,
    "NOV": 5.81,
    "DEC": 5.67
  },
  {
    "LONG": -35.45,
    "LAT": -8.38,
    "CIDADE": "Amaraji",
    "UF": "PERNAMBUCO",
    "anual": 4.98,
    "JAN": 5.27,
    "FEB": 5.39,
    "MAR": 5.63,
    "APR": 5.03,
    "MAY": 4.4,
    "JUN": 4.02,
    "JUL": 4,
    "AUG": 4.52,
    "SEP": 5.15,
    "OCT": 5.3,
    "NOV": 5.57,
    "DEC": 5.5
  },
  {
    "LONG": -35.22,
    "LAT": -8.36,
    "CIDADE": "Escada",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.36,
    "FEB": 5.6,
    "MAR": 5.79,
    "APR": 5.18,
    "MAY": 4.56,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 4.94,
    "SEP": 5.45,
    "OCT": 5.58,
    "NOV": 5.71,
    "DEC": 5.64
  },
  {
    "LONG": -35.06,
    "LAT": -8.39,
    "CIDADE": "Ipojuca",
    "UF": "PERNAMBUCO",
    "anual": 5.28,
    "JAN": 5.43,
    "FEB": 5.67,
    "MAR": 5.91,
    "APR": 5.31,
    "MAY": 4.66,
    "JUN": 4.41,
    "JUL": 4.47,
    "AUG": 5.08,
    "SEP": 5.45,
    "OCT": 5.61,
    "NOV": 5.7,
    "DEC": 5.66
  },
  {
    "LONG": -38.74,
    "LAT": -8.32,
    "CIDADE": "Carnaubeira da Penha",
    "UF": "PERNAMBUCO",
    "anual": 5.74,
    "JAN": 5.86,
    "FEB": 5.9,
    "MAR": 6.06,
    "APR": 5.73,
    "MAY": 5.08,
    "JUN": 4.62,
    "JUL": 4.88,
    "AUG": 5.62,
    "SEP": 6.44,
    "OCT": 6.35,
    "NOV": 6.37,
    "DEC": 6.03
  },
  {
    "LONG": -38.04,
    "LAT": -8.27,
    "CIDADE": "Betânia",
    "UF": "PERNAMBUCO",
    "anual": 5.75,
    "JAN": 6.01,
    "FEB": 5.95,
    "MAR": 6.13,
    "APR": 5.83,
    "MAY": 5.01,
    "JUN": 4.53,
    "JUL": 4.81,
    "AUG": 5.51,
    "SEP": 6.3,
    "OCT": 6.35,
    "NOV": 6.44,
    "DEC": 6.12
  },
  {
    "LONG": -36.42,
    "LAT": -8.34,
    "CIDADE": "Belo Jardim",
    "UF": "PERNAMBUCO",
    "anual": 5.32,
    "JAN": 5.74,
    "FEB": 5.67,
    "MAR": 5.81,
    "APR": 5.43,
    "MAY": 4.58,
    "JUN": 4.08,
    "JUL": 4.21,
    "AUG": 4.85,
    "SEP": 5.66,
    "OCT": 5.85,
    "NOV": 6.1,
    "DEC": 5.85
  },
  {
    "LONG": -36.29,
    "LAT": -8.32,
    "CIDADE": "Tacaimbó",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.54,
    "FEB": 5.51,
    "MAR": 5.73,
    "APR": 5.39,
    "MAY": 4.7,
    "JUN": 4.26,
    "JUL": 4.31,
    "AUG": 4.99,
    "SEP": 5.57,
    "OCT": 5.68,
    "NOV": 5.9,
    "DEC": 5.65
  },
  {
    "LONG": -36.14,
    "LAT": -8.33,
    "CIDADE": "São Caitano",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.54,
    "FEB": 5.53,
    "MAR": 5.74,
    "APR": 5.36,
    "MAY": 4.66,
    "JUN": 4.27,
    "JUL": 4.32,
    "AUG": 5.01,
    "SEP": 5.55,
    "OCT": 5.68,
    "NOV": 5.89,
    "DEC": 5.62
  },
  {
    "LONG": -35.97,
    "LAT": -0.83,
    "CIDADE": "Caruaru",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.44,
    "FEB": 5.61,
    "MAR": 5.71,
    "APR": 5.34,
    "MAY": 4.69,
    "JUN": 4.28,
    "JUL": 4.38,
    "AUG": 5.01,
    "SEP": 5.55,
    "OCT": 5.65,
    "NOV": 5.83,
    "DEC": 5.6
  },
  {
    "LONG": -35.71,
    "LAT": -8.33,
    "CIDADE": "Sairé",
    "UF": "PERNAMBUCO",
    "anual": 5.21,
    "JAN": 5.46,
    "FEB": 5.56,
    "MAR": 5.77,
    "APR": 5.24,
    "MAY": 4.58,
    "JUN": 4.19,
    "JUL": 4.27,
    "AUG": 4.9,
    "SEP": 5.48,
    "OCT": 5.64,
    "NOV": 5.83,
    "DEC": 5.6
  },
  {
    "LONG": -35.35,
    "LAT": -8.33,
    "CIDADE": "Primavera",
    "UF": "PERNAMBUCO",
    "anual": 5,
    "JAN": 5.22,
    "FEB": 5.4,
    "MAR": 5.57,
    "APR": 5.04,
    "MAY": 4.42,
    "JUN": 4.09,
    "JUL": 4.12,
    "AUG": 4.65,
    "SEP": 5.23,
    "OCT": 5.32,
    "NOV": 5.5,
    "DEC": 5.47
  },
  {
    "LONG": -35.03,
    "LAT": -8.28,
    "CIDADE": "Cabo de Santo Agostinho",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.4,
    "FEB": 5.64,
    "MAR": 5.81,
    "APR": 5.26,
    "MAY": 4.64,
    "JUN": 4.38,
    "JUL": 4.44,
    "AUG": 5.05,
    "SEP": 5.47,
    "OCT": 5.61,
    "NOV": 5.7,
    "DEC": 5.67
  },
  {
    "LONG": -40.61,
    "LAT": -8.16,
    "CIDADE": "Santa Filomena",
    "UF": "PERNAMBUCO",
    "anual": 5.79,
    "JAN": 5.62,
    "FEB": 5.55,
    "MAR": 5.73,
    "APR": 5.43,
    "MAY": 5.19,
    "JUN": 5.1,
    "JUL": 5.43,
    "AUG": 6.32,
    "SEP": 6.71,
    "OCT": 6.43,
    "NOV": 6.16,
    "DEC": 5.81
  },
  {
    "LONG": -40.34,
    "LAT": -0.82,
    "CIDADE": "Santa Cruz",
    "UF": "PERNAMBUCO",
    "anual": 5.6,
    "JAN": 5.57,
    "FEB": 5.44,
    "MAR": 5.67,
    "APR": 5.26,
    "MAY": 4.93,
    "JUN": 4.77,
    "JUL": 5.14,
    "AUG": 5.95,
    "SEP": 6.44,
    "OCT": 6.24,
    "NOV": 6.07,
    "DEC": 5.74
  },
  {
    "LONG": -39.38,
    "LAT": -8.23,
    "CIDADE": "Terra Nova",
    "UF": "PERNAMBUCO",
    "anual": 5.73,
    "JAN": 5.85,
    "FEB": 5.75,
    "MAR": 5.87,
    "APR": 5.62,
    "MAY": 5.08,
    "JUN": 4.72,
    "JUL": 5.02,
    "AUG": 5.71,
    "SEP": 6.47,
    "OCT": 6.35,
    "NOV": 6.31,
    "DEC": 5.96
  },
  {
    "LONG": -3.67,
    "LAT": -8.19,
    "CIDADE": "Poção",
    "UF": "PERNAMBUCO",
    "anual": 5.67,
    "JAN": 5.92,
    "FEB": 5.89,
    "MAR": 6.05,
    "APR": 5.71,
    "MAY": 4.98,
    "JUN": 4.51,
    "JUL": 4.68,
    "AUG": 5.47,
    "SEP": 6.22,
    "OCT": 6.25,
    "NOV": 6.33,
    "DEC": 6.01
  },
  {
    "LONG": -35.8,
    "LAT": -8.23,
    "CIDADE": "Bezerros",
    "UF": "PERNAMBUCO",
    "anual": 5.16,
    "JAN": 5.36,
    "FEB": 5.49,
    "MAR": 5.64,
    "APR": 5.18,
    "MAY": 4.57,
    "JUN": 4.2,
    "JUL": 4.29,
    "AUG": 4.89,
    "SEP": 5.45,
    "OCT": 5.59,
    "NOV": 5.75,
    "DEC": 5.54
  },
  {
    "LONG": -35.57,
    "LAT": -8.21,
    "CIDADE": "Gravatá",
    "UF": "PERNAMBUCO",
    "anual": 5.25,
    "JAN": 5.47,
    "FEB": 5.55,
    "MAR": 5.71,
    "APR": 5.25,
    "MAY": 4.69,
    "JUN": 4.41,
    "JUL": 4.44,
    "AUG": 5.14,
    "SEP": 5.55,
    "OCT": 5.59,
    "NOV": 5.67,
    "DEC": 5.51
  },
  {
    "LONG": -35.46,
    "LAT": -8.24,
    "CIDADE": "Chã Grande",
    "UF": "PERNAMBUCO",
    "anual": 5.25,
    "JAN": 5.49,
    "FEB": 5.56,
    "MAR": 5.72,
    "APR": 5.29,
    "MAY": 4.68,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 5.12,
    "SEP": 5.54,
    "OCT": 5.56,
    "NOV": 5.68,
    "DEC": 5.57
  },
  {
    "LONG": -34.92,
    "LAT": -8.17,
    "CIDADE": "Jaboatão dos Guararapes",
    "UF": "PERNAMBUCO",
    "anual": 5.45,
    "JAN": 5.62,
    "FEB": 5.83,
    "MAR": 5.98,
    "APR": 5.4,
    "MAY": 4.83,
    "JUN": 4.56,
    "JUL": 4.61,
    "AUG": 5.28,
    "SEP": 5.67,
    "OCT": 5.83,
    "NOV": 5.92,
    "DEC": 5.89
  },
  {
    "LONG": -39.58,
    "LAT": -8.09,
    "CIDADE": "Parnamirim",
    "UF": "PERNAMBUCO",
    "anual": 5.71,
    "JAN": 5.74,
    "FEB": 5.7,
    "MAR": 5.89,
    "APR": 5.57,
    "MAY": 5.02,
    "JUN": 4.76,
    "JUL": 4.99,
    "AUG": 5.77,
    "SEP": 6.45,
    "OCT": 6.35,
    "NOV": 6.28,
    "DEC": 5.96
  },
  {
    "LONG": -3.91,
    "LAT": -8.07,
    "CIDADE": "Salgueiro",
    "UF": "PERNAMBUCO",
    "anual": 5.71,
    "JAN": 5.75,
    "FEB": 5.74,
    "MAR": 5.89,
    "APR": 5.63,
    "MAY": 5.04,
    "JUN": 4.75,
    "JUL": 5.04,
    "AUG": 5.77,
    "SEP": 6.43,
    "OCT": 6.29,
    "NOV": 6.29,
    "DEC": 5.89
  },
  {
    "LONG": -38.74,
    "LAT": -8.12,
    "CIDADE": "Mirandiba",
    "UF": "PERNAMBUCO",
    "anual": 5.78,
    "JAN": 5.9,
    "FEB": 5.83,
    "MAR": 6.02,
    "APR": 5.72,
    "MAY": 5.1,
    "JUN": 4.71,
    "JUL": 4.97,
    "AUG": 5.72,
    "SEP": 6.49,
    "OCT": 6.38,
    "NOV": 6.43,
    "DEC": 6.04
  },
  {
    "LONG": -37.64,
    "LAT": -8.09,
    "CIDADE": "Custódia",
    "UF": "PERNAMBUCO",
    "anual": 5.79,
    "JAN": 5.99,
    "FEB": 6.07,
    "MAR": 6.13,
    "APR": 5.92,
    "MAY": 5.14,
    "JUN": 4.57,
    "JUL": 4.83,
    "AUG": 5.56,
    "SEP": 6.32,
    "OCT": 6.38,
    "NOV": 6.47,
    "DEC": 6.1
  },
  {
    "LONG": -37.27,
    "LAT": -8.07,
    "CIDADE": "Sertânia",
    "UF": "PERNAMBUCO",
    "anual": 5.73,
    "JAN": 5.95,
    "FEB": 5.95,
    "MAR": 6.04,
    "APR": 5.76,
    "MAY": 5.08,
    "JUN": 4.6,
    "JUL": 4.76,
    "AUG": 5.49,
    "SEP": 6.24,
    "OCT": 6.29,
    "NOV": 6.44,
    "DEC": 6.13
  },
  {
    "LONG": -3.64,
    "LAT": -8.14,
    "CIDADE": "Brejo da Madre de Deus",
    "UF": "PERNAMBUCO",
    "anual": 5.46,
    "JAN": 5.66,
    "FEB": 5.73,
    "MAR": 5.86,
    "APR": 5.57,
    "MAY": 4.86,
    "JUN": 4.48,
    "JUL": 4.58,
    "AUG": 5.26,
    "SEP": 5.81,
    "OCT": 5.87,
    "NOV": 6.01,
    "DEC": 5.79
  },
  {
    "LONG": -35.87,
    "LAT": -8.14,
    "CIDADE": "Riacho das Almas",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.47,
    "FEB": 5.6,
    "MAR": 5.74,
    "APR": 5.28,
    "MAY": 4.72,
    "JUN": 4.3,
    "JUL": 4.44,
    "AUG": 4.96,
    "SEP": 5.5,
    "OCT": 5.64,
    "NOV": 5.81,
    "DEC": 5.61
  },
  {
    "LONG": -3.54,
    "LAT": -8.14,
    "CIDADE": "Pombos",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.39,
    "FEB": 5.56,
    "MAR": 5.75,
    "APR": 5.24,
    "MAY": 4.69,
    "JUN": 4.37,
    "JUL": 4.48,
    "AUG": 5.06,
    "SEP": 5.56,
    "OCT": 5.63,
    "NOV": 5.68,
    "DEC": 5.67
  },
  {
    "LONG": -35.3,
    "LAT": -8.13,
    "CIDADE": "Vitória de Santo Antão",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.44,
    "FEB": 5.61,
    "MAR": 5.78,
    "APR": 5.22,
    "MAY": 4.67,
    "JUN": 4.38,
    "JUL": 4.46,
    "AUG": 5.08,
    "SEP": 5.58,
    "OCT": 5.61,
    "NOV": 5.68,
    "DEC": 5.67
  },
  {
    "LONG": -35.08,
    "LAT": -8.11,
    "CIDADE": "Moreno",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.52,
    "FEB": 5.72,
    "MAR": 5.89,
    "APR": 5.3,
    "MAY": 4.64,
    "JUN": 4.41,
    "JUL": 4.5,
    "AUG": 5.1,
    "SEP": 5.52,
    "OCT": 5.6,
    "NOV": 5.73,
    "DEC": 5.66
  },
  {
    "LONG": -38.29,
    "LAT": -7.98,
    "CIDADE": "Serra Talhada",
    "UF": "PERNAMBUCO",
    "anual": 5.83,
    "JAN": 5.95,
    "FEB": 6.02,
    "MAR": 6.12,
    "APR": 5.89,
    "MAY": 5.13,
    "JUN": 4.71,
    "JUL": 4.95,
    "AUG": 5.75,
    "SEP": 6.48,
    "OCT": 6.45,
    "NOV": 6.45,
    "DEC": 6.07
  },
  {
    "LONG": -36.5,
    "LAT": -7.98,
    "CIDADE": "Jataúba",
    "UF": "PERNAMBUCO",
    "anual": 5.52,
    "JAN": 5.72,
    "FEB": 5.74,
    "MAR": 5.91,
    "APR": 5.66,
    "MAY": 4.93,
    "JUN": 4.54,
    "JUL": 4.65,
    "AUG": 5.29,
    "SEP": 5.89,
    "OCT": 6,
    "NOV": 6.09,
    "DEC": 5.82
  },
  {
    "LONG": -36.06,
    "LAT": -8,
    "CIDADE": "Toritama",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.56,
    "FEB": 5.65,
    "MAR": 5.87,
    "APR": 5.49,
    "MAY": 4.8,
    "JUN": 4.29,
    "JUL": 4.37,
    "AUG": 4.99,
    "SEP": 5.56,
    "OCT": 5.75,
    "NOV": 5.95,
    "DEC": 5.73
  },
  {
    "LONG": -3.57,
    "LAT": -8.01,
    "CIDADE": "Cumaru",
    "UF": "PERNAMBUCO",
    "anual": 5.21,
    "JAN": 5.43,
    "FEB": 5.51,
    "MAR": 5.71,
    "APR": 5.28,
    "MAY": 4.67,
    "JUN": 4.3,
    "JUL": 4.38,
    "AUG": 4.96,
    "SEP": 5.46,
    "OCT": 5.54,
    "NOV": 5.68,
    "DEC": 5.59
  },
  {
    "LONG": -35.58,
    "LAT": -7.98,
    "CIDADE": "Passira",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.4,
    "FEB": 5.48,
    "MAR": 5.67,
    "APR": 5.25,
    "MAY": 4.64,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 4.99,
    "SEP": 5.45,
    "OCT": 5.5,
    "NOV": 5.64,
    "DEC": 5.53
  },
  {
    "LONG": -35.2,
    "LAT": -8.01,
    "CIDADE": "Chã de Alegria",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.49,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.34,
    "MAY": 4.75,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.16,
    "SEP": 5.62,
    "OCT": 5.67,
    "NOV": 5.74,
    "DEC": 5.71
  },
  {
    "LONG": -35.29,
    "LAT": -8.01,
    "CIDADE": "Glória do Goitá",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.49,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.34,
    "MAY": 4.75,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.16,
    "SEP": 5.62,
    "OCT": 5.67,
    "NOV": 5.74,
    "DEC": 5.71
  },
  {
    "LONG": -35.01,
    "LAT": -8.01,
    "CIDADE": "São Lourenço da Mata",
    "UF": "PERNAMBUCO",
    "anual": 5.32,
    "JAN": 5.51,
    "FEB": 5.74,
    "MAR": 5.88,
    "APR": 5.32,
    "MAY": 4.68,
    "JUN": 4.42,
    "JUL": 4.52,
    "AUG": 5.15,
    "SEP": 5.53,
    "OCT": 5.63,
    "NOV": 5.76,
    "DEC": 5.7
  },
  {
    "LONG": -34.98,
    "LAT": -8.02,
    "CIDADE": "Camaragibe",
    "UF": "PERNAMBUCO",
    "anual": 5.29,
    "JAN": 5.45,
    "FEB": 5.71,
    "MAR": 5.81,
    "APR": 5.31,
    "MAY": 4.69,
    "JUN": 4.42,
    "JUL": 4.53,
    "AUG": 5.18,
    "SEP": 5.42,
    "OCT": 5.6,
    "NOV": 5.7,
    "DEC": 5.64
  },
  {
    "LONG": -34.88,
    "LAT": -8.05,
    "CIDADE": "Recife",
    "UF": "PERNAMBUCO",
    "anual": 5.45,
    "JAN": 5.58,
    "FEB": 5.82,
    "MAR": 5.91,
    "APR": 5.4,
    "MAY": 4.79,
    "JUN": 4.53,
    "JUL": 4.66,
    "AUG": 5.37,
    "SEP": 5.69,
    "OCT": 5.89,
    "NOV": 5.9,
    "DEC": 5.83
  },
  {
    "LONG": -34.85,
    "LAT": -8.01,
    "CIDADE": "Olinda",
    "UF": "PERNAMBUCO",
    "anual": 5.45,
    "JAN": 5.58,
    "FEB": 5.82,
    "MAR": 5.91,
    "APR": 5.4,
    "MAY": 4.79,
    "JUN": 4.53,
    "JUL": 4.66,
    "AUG": 5.37,
    "SEP": 5.69,
    "OCT": 5.89,
    "NOV": 5.9,
    "DEC": 5.83
  },
  {
    "LONG": -40.08,
    "LAT": -7.88,
    "CIDADE": "Ouricuri",
    "UF": "PERNAMBUCO",
    "anual": 5.59,
    "JAN": 5.63,
    "FEB": 5.47,
    "MAR": 5.76,
    "APR": 5.36,
    "MAY": 4.93,
    "JUN": 4.65,
    "JUL": 4.9,
    "AUG": 5.79,
    "SEP": 6.33,
    "OCT": 6.28,
    "NOV": 6.16,
    "DEC": 5.88
  },
  {
    "LONG": -39.3,
    "LAT": -7.94,
    "CIDADE": "Serrita",
    "UF": "PERNAMBUCO",
    "anual": 5.7,
    "JAN": 5.73,
    "FEB": 5.73,
    "MAR": 5.78,
    "APR": 5.59,
    "MAY": 4.98,
    "JUN": 4.74,
    "JUL": 4.99,
    "AUG": 5.75,
    "SEP": 6.5,
    "OCT": 6.33,
    "NOV": 6.32,
    "DEC": 5.92
  },
  {
    "LONG": -38.97,
    "LAT": -7.93,
    "CIDADE": "Verdejante",
    "UF": "PERNAMBUCO",
    "anual": 5.74,
    "JAN": 5.69,
    "FEB": 5.76,
    "MAR": 5.92,
    "APR": 5.63,
    "MAY": 5.13,
    "JUN": 4.82,
    "JUL": 5.1,
    "AUG": 5.8,
    "SEP": 6.52,
    "OCT": 6.3,
    "NOV": 6.29,
    "DEC": 5.89
  },
  {
    "LONG": -38.76,
    "LAT": -7.87,
    "CIDADE": "São José do Belmonte",
    "UF": "PERNAMBUCO",
    "anual": 5.79,
    "JAN": 5.85,
    "FEB": 5.87,
    "MAR": 5.95,
    "APR": 5.74,
    "MAY": 5.14,
    "JUN": 4.83,
    "JUL": 5.1,
    "AUG": 5.83,
    "SEP": 6.56,
    "OCT": 6.36,
    "NOV": 6.32,
    "DEC": 5.99
  },
  {
    "LONG": -38.15,
    "LAT": -7.94,
    "CIDADE": "Calumbi",
    "UF": "PERNAMBUCO",
    "anual": 5.82,
    "JAN": 5.9,
    "FEB": 5.94,
    "MAR": 6.14,
    "APR": 5.9,
    "MAY": 5.11,
    "JUN": 4.73,
    "JUL": 4.97,
    "AUG": 5.79,
    "SEP": 6.5,
    "OCT": 6.44,
    "NOV": 6.42,
    "DEC": 6.04
  },
  {
    "LONG": -37.97,
    "LAT": -7.86,
    "CIDADE": "Flores",
    "UF": "PERNAMBUCO",
    "anual": 5.78,
    "JAN": 5.9,
    "FEB": 6.01,
    "MAR": 6.14,
    "APR": 5.92,
    "MAY": 5.1,
    "JUN": 4.64,
    "JUL": 4.86,
    "AUG": 5.64,
    "SEP": 6.39,
    "OCT": 6.37,
    "NOV": 6.4,
    "DEC": 6.03
  },
  {
    "LONG": -36.21,
    "LAT": -7.95,
    "CIDADE": "Santa Cruz do Capibaribe",
    "UF": "PERNAMBUCO",
    "anual": 5.43,
    "JAN": 5.6,
    "FEB": 5.72,
    "MAR": 5.86,
    "APR": 5.53,
    "MAY": 4.89,
    "JUN": 4.48,
    "JUL": 4.6,
    "AUG": 5.23,
    "SEP": 5.76,
    "OCT": 5.89,
    "NOV": 5.98,
    "DEC": 5.66
  },
  {
    "LONG": -36.05,
    "LAT": -0.79,
    "CIDADE": "Taquaritinga do Norte",
    "UF": "PERNAMBUCO",
    "anual": 5.4,
    "JAN": 5.61,
    "FEB": 5.73,
    "MAR": 5.91,
    "APR": 5.46,
    "MAY": 4.87,
    "JUN": 4.37,
    "JUL": 4.47,
    "AUG": 5.11,
    "SEP": 5.68,
    "OCT": 5.87,
    "NOV": 6,
    "DEC": 5.74
  },
  {
    "LONG": -35.92,
    "LAT": -7.94,
    "CIDADE": "Frei Miguelinho",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.45,
    "FEB": 5.62,
    "MAR": 5.84,
    "APR": 5.35,
    "MAY": 4.81,
    "JUN": 4.31,
    "JUL": 4.52,
    "AUG": 5.07,
    "SEP": 5.52,
    "OCT": 5.68,
    "NOV": 5.85,
    "DEC": 5.66
  },
  {
    "LONG": -35.99,
    "LAT": -7.9,
    "CIDADE": "Vertentes",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.45,
    "FEB": 5.62,
    "MAR": 5.84,
    "APR": 5.35,
    "MAY": 4.81,
    "JUN": 4.31,
    "JUL": 4.52,
    "AUG": 5.07,
    "SEP": 5.52,
    "OCT": 5.68,
    "NOV": 5.85,
    "DEC": 5.66
  },
  {
    "LONG": -35.63,
    "LAT": -7.94,
    "CIDADE": "Salgadinho",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.43,
    "FEB": 5.56,
    "MAR": 5.81,
    "APR": 5.34,
    "MAY": 4.71,
    "JUN": 4.37,
    "JUL": 4.5,
    "AUG": 5.05,
    "SEP": 5.56,
    "OCT": 5.59,
    "NOV": 5.75,
    "DEC": 5.61
  },
  {
    "LONG": -35.59,
    "LAT": -7.86,
    "CIDADE": "João Alfredo",
    "UF": "PERNAMBUCO",
    "anual": 5.17,
    "JAN": 5.35,
    "FEB": 5.39,
    "MAR": 5.66,
    "APR": 5.25,
    "MAY": 4.63,
    "JUN": 4.32,
    "JUL": 4.41,
    "AUG": 4.99,
    "SEP": 5.44,
    "OCT": 5.45,
    "NOV": 5.64,
    "DEC": 5.49
  },
  {
    "LONG": -35.45,
    "LAT": -7.88,
    "CIDADE": "Limoeiro",
    "UF": "PERNAMBUCO",
    "anual": 5.19,
    "JAN": 5.4,
    "FEB": 5.44,
    "MAR": 5.65,
    "APR": 5.27,
    "MAY": 4.62,
    "JUN": 4.34,
    "JUL": 4.42,
    "AUG": 5,
    "SEP": 5.47,
    "OCT": 5.54,
    "NOV": 5.68,
    "DEC": 5.52
  },
  {
    "LONG": -35.39,
    "LAT": -0.79,
    "CIDADE": "Feira Nova",
    "UF": "PERNAMBUCO",
    "anual": 5.29,
    "JAN": 5.47,
    "FEB": 5.64,
    "MAR": 5.75,
    "APR": 5.36,
    "MAY": 4.7,
    "JUN": 4.4,
    "JUL": 4.51,
    "AUG": 5.09,
    "SEP": 5.59,
    "OCT": 5.63,
    "NOV": 5.72,
    "DEC": 5.62
  },
  {
    "LONG": -35.29,
    "LAT": -7.93,
    "CIDADE": "Lagoa do Itaenga",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.47,
    "FEB": 5.68,
    "MAR": 5.8,
    "APR": 5.39,
    "MAY": 4.74,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.2,
    "SEP": 5.58,
    "OCT": 5.66,
    "NOV": 5.74,
    "DEC": 5.68
  },
  {
    "LONG": -35.17,
    "LAT": -7.9,
    "CIDADE": "Paudalho",
    "UF": "PERNAMBUCO",
    "anual": 5.34,
    "JAN": 5.46,
    "FEB": 5.72,
    "MAR": 5.84,
    "APR": 5.38,
    "MAY": 4.76,
    "JUN": 4.46,
    "JUL": 4.55,
    "AUG": 5.24,
    "SEP": 5.6,
    "OCT": 5.66,
    "NOV": 5.77,
    "DEC": 5.7
  },
  {
    "LONG": -34.91,
    "LAT": -7.9,
    "CIDADE": "Abreu e Lima",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.44,
    "FEB": 5.71,
    "MAR": 5.83,
    "APR": 5.3,
    "MAY": 4.72,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.2,
    "SEP": 5.45,
    "OCT": 5.57,
    "NOV": 5.73,
    "DEC": 5.64
  },
  {
    "LONG": -34.87,
    "LAT": -7.94,
    "CIDADE": "Paulista",
    "UF": "PERNAMBUCO",
    "anual": 5.39,
    "JAN": 5.52,
    "FEB": 5.78,
    "MAR": 5.9,
    "APR": 5.37,
    "MAY": 4.78,
    "JUN": 4.51,
    "JUL": 4.6,
    "AUG": 5.31,
    "SEP": 5.57,
    "OCT": 5.77,
    "NOV": 5.86,
    "DEC": 5.73
  },
  {
    "LONG": -4.03,
    "LAT": -7.76,
    "CIDADE": "Trindade",
    "UF": "PERNAMBUCO",
    "anual": 5.64,
    "JAN": 5.58,
    "FEB": 5.43,
    "MAR": 5.76,
    "APR": 5.36,
    "MAY": 4.99,
    "JUN": 4.78,
    "JUL": 5.03,
    "AUG": 5.97,
    "SEP": 6.4,
    "OCT": 6.28,
    "NOV": 6.2,
    "DEC": 5.91
  },
  {
    "LONG": -39.93,
    "LAT": -0.78,
    "CIDADE": "Bodocó",
    "UF": "PERNAMBUCO",
    "anual": 5.65,
    "JAN": 5.64,
    "FEB": 5.57,
    "MAR": 5.82,
    "APR": 5.43,
    "MAY": 5,
    "JUN": 4.67,
    "JUL": 5,
    "AUG": 5.84,
    "SEP": 6.36,
    "OCT": 6.32,
    "NOV": 6.24,
    "DEC": 5.92
  },
  {
    "LONG": -38.11,
    "LAT": -7.83,
    "CIDADE": "Triunfo",
    "UF": "PERNAMBUCO",
    "anual": 5.87,
    "JAN": 5.83,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.89,
    "MAY": 5.23,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 6,
    "SEP": 6.59,
    "OCT": 6.44,
    "NOV": 6.41,
    "DEC": 5.97
  },
  {
    "LONG": -38.15,
    "LAT": -7.82,
    "CIDADE": "Santa Cruz da Baixa Verde",
    "UF": "PERNAMBUCO",
    "anual": 5.87,
    "JAN": 5.83,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.89,
    "MAY": 5.23,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 6,
    "SEP": 6.59,
    "OCT": 6.44,
    "NOV": 6.41,
    "DEC": 5.97
  },
  {
    "LONG": -37.8,
    "LAT": -7.8,
    "CIDADE": "Carnaíba",
    "UF": "PERNAMBUCO",
    "anual": 5.85,
    "JAN": 5.95,
    "FEB": 5.99,
    "MAR": 6.13,
    "APR": 5.92,
    "MAY": 5.25,
    "JUN": 4.85,
    "JUL": 5,
    "AUG": 5.76,
    "SEP": 6.44,
    "OCT": 6.39,
    "NOV": 6.44,
    "DEC": 6.05
  },
  {
    "LONG": -37.64,
    "LAT": -7.75,
    "CIDADE": "Afogados da Ingazeira",
    "UF": "PERNAMBUCO",
    "anual": 5.82,
    "JAN": 5.89,
    "FEB": 5.98,
    "MAR": 6.08,
    "APR": 5.88,
    "MAY": 5.24,
    "JUN": 4.85,
    "JUL": 4.97,
    "AUG": 5.74,
    "SEP": 6.42,
    "OCT": 6.36,
    "NOV": 6.41,
    "DEC": 6.02
  },
  {
    "LONG": -37.51,
    "LAT": -7.84,
    "CIDADE": "Iguaracy",
    "UF": "PERNAMBUCO",
    "anual": 5.84,
    "JAN": 5.91,
    "FEB": 5.98,
    "MAR": 6.09,
    "APR": 5.89,
    "MAY": 5.27,
    "JUN": 4.88,
    "JUL": 5.03,
    "AUG": 5.79,
    "SEP": 6.43,
    "OCT": 6.38,
    "NOV": 6.43,
    "DEC": 6.05
  },
  {
    "LONG": -35.85,
    "LAT": -7.77,
    "CIDADE": "Vertente do Lério",
    "UF": "PERNAMBUCO",
    "anual": 5.34,
    "JAN": 5.52,
    "FEB": 5.66,
    "MAR": 5.87,
    "APR": 5.42,
    "MAY": 4.82,
    "JUN": 4.36,
    "JUL": 4.54,
    "AUG": 5.16,
    "SEP": 5.59,
    "OCT": 5.71,
    "NOV": 5.87,
    "DEC": 5.63
  },
  {
    "LONG": -35.88,
    "LAT": -0.78,
    "CIDADE": "Santa Maria do Cambucá",
    "UF": "PERNAMBUCO",
    "anual": 5.34,
    "JAN": 5.52,
    "FEB": 5.66,
    "MAR": 5.87,
    "APR": 5.42,
    "MAY": 4.82,
    "JUN": 4.36,
    "JUL": 4.54,
    "AUG": 5.16,
    "SEP": 5.59,
    "OCT": 5.71,
    "NOV": 5.87,
    "DEC": 5.63
  },
  {
    "LONG": -35.76,
    "LAT": -7.85,
    "CIDADE": "Surubim",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.52,
    "FEB": 5.58,
    "MAR": 5.81,
    "APR": 5.4,
    "MAY": 4.76,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.13,
    "SEP": 5.58,
    "OCT": 5.64,
    "NOV": 5.81,
    "DEC": 5.59
  },
  {
    "LONG": -3.56,
    "LAT": -0.78,
    "CIDADE": "Bom Jardim",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.37,
    "FEB": 5.44,
    "MAR": 5.66,
    "APR": 5.3,
    "MAY": 4.64,
    "JUN": 4.3,
    "JUL": 4.39,
    "AUG": 5,
    "SEP": 5.49,
    "OCT": 5.55,
    "NOV": 5.71,
    "DEC": 5.52
  },
  {
    "LONG": -35.31,
    "LAT": -7.84,
    "CIDADE": "Lagoa do Carro",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.36,
    "FEB": 5.52,
    "MAR": 5.69,
    "APR": 5.31,
    "MAY": 4.64,
    "JUN": 4.34,
    "JUL": 4.44,
    "AUG": 5.03,
    "SEP": 5.48,
    "OCT": 5.53,
    "NOV": 5.64,
    "DEC": 5.46
  },
  {
    "LONG": -35.24,
    "LAT": -7.8,
    "CIDADE": "Tracunhaém",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.38,
    "FEB": 5.62,
    "MAR": 5.77,
    "APR": 5.33,
    "MAY": 4.72,
    "JUN": 4.4,
    "JUL": 4.48,
    "AUG": 5.14,
    "SEP": 5.53,
    "OCT": 5.58,
    "NOV": 5.71,
    "DEC": 5.56
  },
  {
    "LONG": -35.26,
    "LAT": -7.85,
    "CIDADE": "Carpina",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.38,
    "FEB": 5.62,
    "MAR": 5.77,
    "APR": 5.33,
    "MAY": 4.72,
    "JUN": 4.4,
    "JUL": 4.48,
    "AUG": 5.14,
    "SEP": 5.53,
    "OCT": 5.58,
    "NOV": 5.71,
    "DEC": 5.56
  },
  {
    "LONG": -35.08,
    "LAT": -7.78,
    "CIDADE": "Araçoiaba",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.45,
    "FEB": 5.71,
    "MAR": 5.9,
    "APR": 5.31,
    "MAY": 4.7,
    "JUN": 4.39,
    "JUL": 4.55,
    "AUG": 5.22,
    "SEP": 5.49,
    "OCT": 5.61,
    "NOV": 5.73,
    "DEC": 5.7
  },
  {
    "LONG": -34.9,
    "LAT": -7.84,
    "CIDADE": "Igarassu",
    "UF": "PERNAMBUCO",
    "anual": 5.34,
    "JAN": 5.49,
    "FEB": 5.76,
    "MAR": 5.97,
    "APR": 5.32,
    "MAY": 4.73,
    "JUN": 4.45,
    "JUL": 4.55,
    "AUG": 5.22,
    "SEP": 5.45,
    "OCT": 5.61,
    "NOV": 5.78,
    "DEC": 5.7
  },
  {
    "LONG": -34.9,
    "LAT": -7.77,
    "CIDADE": "Itapissuma",
    "UF": "PERNAMBUCO",
    "anual": 5.52,
    "JAN": 5.65,
    "FEB": 5.9,
    "MAR": 6.07,
    "APR": 5.49,
    "MAY": 4.89,
    "JUN": 4.59,
    "JUL": 4.67,
    "AUG": 5.43,
    "SEP": 5.72,
    "OCT": 5.92,
    "NOV": 5.96,
    "DEC": 5.9
  },
  {
    "LONG": -39.62,
    "LAT": -7.71,
    "CIDADE": "Granito",
    "UF": "PERNAMBUCO",
    "anual": 5.68,
    "JAN": 5.68,
    "FEB": 5.64,
    "MAR": 5.81,
    "APR": 5.5,
    "MAY": 5.02,
    "JUN": 4.68,
    "JUL": 4.98,
    "AUG": 5.82,
    "SEP": 6.44,
    "OCT": 6.35,
    "NOV": 6.25,
    "DEC": 5.94
  },
  {
    "LONG": -39.24,
    "LAT": -7.71,
    "CIDADE": "Cedro",
    "UF": "PERNAMBUCO",
    "anual": 5.7,
    "JAN": 5.75,
    "FEB": 5.68,
    "MAR": 5.9,
    "APR": 5.58,
    "MAY": 4.97,
    "JUN": 4.68,
    "JUL": 4.93,
    "AUG": 5.73,
    "SEP": 6.5,
    "OCT": 6.33,
    "NOV": 6.34,
    "DEC": 5.99
  },
  {
    "LONG": -37.85,
    "LAT": -7.72,
    "CIDADE": "Quixaba",
    "UF": "PERNAMBUCO",
    "anual": 5.88,
    "JAN": 5.88,
    "FEB": 5.97,
    "MAR": 6.16,
    "APR": 5.96,
    "MAY": 5.31,
    "JUN": 4.9,
    "JUL": 5.15,
    "AUG": 5.93,
    "SEP": 6.49,
    "OCT": 6.4,
    "NOV": 6.41,
    "DEC": 6.01
  },
  {
    "LONG": -37.46,
    "LAT": -7.68,
    "CIDADE": "Ingazeira",
    "UF": "PERNAMBUCO",
    "anual": 5.82,
    "JAN": 5.87,
    "FEB": 5.97,
    "MAR": 6.12,
    "APR": 5.91,
    "MAY": 5.22,
    "JUN": 4.81,
    "JUL": 4.96,
    "AUG": 5.74,
    "SEP": 6.4,
    "OCT": 6.37,
    "NOV": 6.43,
    "DEC": 6.01
  },
  {
    "LONG": -35.72,
    "LAT": -7.75,
    "CIDADE": "Casinhas",
    "UF": "PERNAMBUCO",
    "anual": 5.32,
    "JAN": 5.43,
    "FEB": 5.62,
    "MAR": 5.78,
    "APR": 5.36,
    "MAY": 4.8,
    "JUN": 4.38,
    "JUL": 4.58,
    "AUG": 5.18,
    "SEP": 5.63,
    "OCT": 5.7,
    "NOV": 5.82,
    "DEC": 5.6
  },
  {
    "LONG": -35.6,
    "LAT": -7.75,
    "CIDADE": "Orobó",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.4,
    "FEB": 5.62,
    "MAR": 5.83,
    "APR": 5.35,
    "MAY": 4.75,
    "JUN": 4.29,
    "JUL": 4.49,
    "AUG": 5.15,
    "SEP": 5.62,
    "OCT": 5.71,
    "NOV": 5.87,
    "DEC": 5.66
  },
  {
    "LONG": -35.52,
    "LAT": -7.69,
    "CIDADE": "Machados",
    "UF": "PERNAMBUCO",
    "anual": 5.24,
    "JAN": 5.37,
    "FEB": 5.58,
    "MAR": 5.78,
    "APR": 5.33,
    "MAY": 4.69,
    "JUN": 4.23,
    "JUL": 4.35,
    "AUG": 5.04,
    "SEP": 5.48,
    "OCT": 5.61,
    "NOV": 5.78,
    "DEC": 5.6
  },
  {
    "LONG": -35.32,
    "LAT": -7.66,
    "CIDADE": "Vicência",
    "UF": "PERNAMBUCO",
    "anual": 5.14,
    "JAN": 5.22,
    "FEB": 5.47,
    "MAR": 5.69,
    "APR": 5.23,
    "MAY": 4.61,
    "JUN": 4.27,
    "JUL": 4.33,
    "AUG": 5.01,
    "SEP": 5.4,
    "OCT": 5.44,
    "NOV": 5.64,
    "DEC": 5.43
  },
  {
    "LONG": -35.33,
    "LAT": -7.72,
    "CIDADE": "Buenos Aires",
    "UF": "PERNAMBUCO",
    "anual": 5.14,
    "JAN": 5.22,
    "FEB": 5.47,
    "MAR": 5.69,
    "APR": 5.23,
    "MAY": 4.61,
    "JUN": 4.27,
    "JUL": 4.33,
    "AUG": 5.01,
    "SEP": 5.4,
    "OCT": 5.44,
    "NOV": 5.64,
    "DEC": 5.43
  },
  {
    "LONG": -35.22,
    "LAT": -7.74,
    "CIDADE": "Nazaré da Mata",
    "UF": "PERNAMBUCO",
    "anual": 5.22,
    "JAN": 5.27,
    "FEB": 5.53,
    "MAR": 5.76,
    "APR": 5.3,
    "MAY": 4.67,
    "JUN": 4.33,
    "JUL": 4.42,
    "AUG": 5.09,
    "SEP": 5.47,
    "OCT": 5.53,
    "NOV": 5.72,
    "DEC": 5.54
  },
  {
    "LONG": -35.1,
    "LAT": -7.66,
    "CIDADE": "Itaquitinga",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.37,
    "FEB": 5.64,
    "MAR": 5.85,
    "APR": 5.35,
    "MAY": 4.75,
    "JUN": 4.39,
    "JUL": 4.49,
    "AUG": 5.18,
    "SEP": 5.56,
    "OCT": 5.64,
    "NOV": 5.83,
    "DEC": 5.68
  },
  {
    "LONG": -34.83,
    "LAT": -7.75,
    "CIDADE": "Ilha de Itamaracá",
    "UF": "PERNAMBUCO",
    "anual": 5.54,
    "JAN": 5.59,
    "FEB": 5.95,
    "MAR": 6.09,
    "APR": 5.5,
    "MAY": 4.96,
    "JUN": 4.64,
    "JUL": 4.76,
    "AUG": 5.46,
    "SEP": 5.74,
    "OCT": 5.94,
    "NOV": 5.94,
    "DEC": 5.86
  },
  {
    "LONG": -4.05,
    "LAT": -7.57,
    "CIDADE": "Araripina",
    "UF": "PERNAMBUCO",
    "anual": 5.65,
    "JAN": 5.43,
    "FEB": 5.3,
    "MAR": 5.54,
    "APR": 5.29,
    "MAY": 5.09,
    "JUN": 4.96,
    "JUL": 5.35,
    "AUG": 6.16,
    "SEP": 6.49,
    "OCT": 6.31,
    "NOV": 6.12,
    "DEC": 5.73
  },
  {
    "LONG": -40.15,
    "LAT": -7.65,
    "CIDADE": "Ipubi",
    "UF": "PERNAMBUCO",
    "anual": 5.6,
    "JAN": 5.47,
    "FEB": 5.44,
    "MAR": 5.62,
    "APR": 5.3,
    "MAY": 4.97,
    "JUN": 4.73,
    "JUL": 5.06,
    "AUG": 5.93,
    "SEP": 6.44,
    "OCT": 6.25,
    "NOV": 6.16,
    "DEC": 5.79
  },
  {
    "LONG": -39.55,
    "LAT": -0.76,
    "CIDADE": "Moreilândia",
    "UF": "PERNAMBUCO",
    "anual": 5.68,
    "JAN": 5.54,
    "FEB": 5.58,
    "MAR": 5.77,
    "APR": 5.53,
    "MAY": 5.04,
    "JUN": 4.76,
    "JUL": 5.04,
    "AUG": 5.87,
    "SEP": 6.5,
    "OCT": 6.39,
    "NOV": 6.25,
    "DEC": 5.88
  },
  {
    "LONG": -37.65,
    "LAT": -0.76,
    "CIDADE": "Solidão",
    "UF": "PERNAMBUCO",
    "anual": 5.89,
    "JAN": 5.85,
    "FEB": 6.01,
    "MAR": 6.14,
    "APR": 5.97,
    "MAY": 5.29,
    "JUN": 4.97,
    "JUL": 5.12,
    "AUG": 5.94,
    "SEP": 6.51,
    "OCT": 6.44,
    "NOV": 6.43,
    "DEC": 5.98
  },
  {
    "LONG": -3.75,
    "LAT": -7.58,
    "CIDADE": "Tabira",
    "UF": "PERNAMBUCO",
    "anual": 5.86,
    "JAN": 5.87,
    "FEB": 6.05,
    "MAR": 6.14,
    "APR": 5.97,
    "MAY": 5.27,
    "JUN": 4.91,
    "JUL": 5.05,
    "AUG": 5.85,
    "SEP": 6.45,
    "OCT": 6.38,
    "NOV": 6.43,
    "DEC": 6
  },
  {
    "LONG": "#VALOR!",
    "LAT": -7.6,
    "CIDADE": "Tuparetama",
    "UF": "PERNAMBUCO",
    "anual": 5.81,
    "JAN": 5.93,
    "FEB": 5.89,
    "MAR": 6.08,
    "APR": 5.84,
    "MAY": 5.25,
    "JUN": 4.8,
    "JUL": 4.98,
    "AUG": 5.73,
    "SEP": 6.4,
    "OCT": 6.39,
    "NOV": 6.42,
    "DEC": 5.99
  },
  {
    "LONG": "#VALOR!",
    "LAT": -7.55,
    "CIDADE": "Macaparana",
    "UF": "PERNAMBUCO",
    "anual": 5.17,
    "JAN": 5.26,
    "FEB": 5.54,
    "MAR": 5.69,
    "APR": 5.3,
    "MAY": 4.63,
    "JUN": 4.19,
    "JUL": 4.29,
    "AUG": 4.97,
    "SEP": 5.38,
    "OCT": 5.48,
    "NOV": 5.76,
    "DEC": 5.53
  },
  {
    "LONG": -35.49,
    "LAT": -7.59,
    "CIDADE": "São Vicente Ferrer",
    "UF": "PERNAMBUCO",
    "anual": 5.17,
    "JAN": 5.26,
    "FEB": 5.54,
    "MAR": 5.69,
    "APR": 5.3,
    "MAY": 4.63,
    "JUN": 4.19,
    "JUL": 4.29,
    "AUG": 4.97,
    "SEP": 5.38,
    "OCT": 5.48,
    "NOV": 5.76,
    "DEC": 5.53
  },
  {
    "LONG": -35.23,
    "LAT": -7.6,
    "CIDADE": "Aliança",
    "UF": "PERNAMBUCO",
    "anual": 5.21,
    "JAN": 5.23,
    "FEB": 5.49,
    "MAR": 5.74,
    "APR": 5.28,
    "MAY": 4.72,
    "JUN": 4.34,
    "JUL": 4.43,
    "AUG": 5.1,
    "SEP": 5.44,
    "OCT": 5.51,
    "NOV": 5.69,
    "DEC": 5.52
  },
  {
    "LONG": -35.1,
    "LAT": -7.59,
    "CIDADE": "Condado",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.38,
    "FEB": 5.63,
    "MAR": 5.86,
    "APR": 5.35,
    "MAY": 4.79,
    "JUN": 4.4,
    "JUL": 4.52,
    "AUG": 5.23,
    "SEP": 5.57,
    "OCT": 5.68,
    "NOV": 5.83,
    "DEC": 5.71
  },
  {
    "LONG": -35,
    "LAT": -0.76,
    "CIDADE": "Goiana",
    "UF": "PERNAMBUCO",
    "anual": 5.45,
    "JAN": 5.52,
    "FEB": 5.95,
    "MAR": 6.04,
    "APR": 5.46,
    "MAY": 4.91,
    "JUN": 4.56,
    "JUL": 4.63,
    "AUG": 5.34,
    "SEP": 5.59,
    "OCT": 5.81,
    "NOV": 5.84,
    "DEC": 5.79
  },
  {
    "LONG": -39.72,
    "LAT": -0.75,
    "CIDADE": "Exu",
    "UF": "PERNAMBUCO",
    "anual": 5.66,
    "JAN": 5.57,
    "FEB": 5.55,
    "MAR": 5.72,
    "APR": 5.42,
    "MAY": 5,
    "JUN": 4.77,
    "JUL": 5.06,
    "AUG": 5.94,
    "SEP": 6.52,
    "OCT": 6.37,
    "NOV": 6.19,
    "DEC": 5.77
  },
  {
    "LONG": -37.27,
    "LAT": -7.47,
    "CIDADE": "São José do Egito",
    "UF": "PERNAMBUCO",
    "anual": 5.77,
    "JAN": 5.85,
    "FEB": 5.88,
    "MAR": 6.03,
    "APR": 5.83,
    "MAY": 5.22,
    "JUN": 4.77,
    "JUL": 4.98,
    "AUG": 5.73,
    "SEP": 6.34,
    "OCT": 6.36,
    "NOV": 6.37,
    "DEC": 5.95
  },
  {
    "LONG": -35.32,
    "LAT": -7.51,
    "CIDADE": "Timbaúba",
    "UF": "PERNAMBUCO",
    "anual": 5.23,
    "JAN": 5.28,
    "FEB": 5.56,
    "MAR": 5.72,
    "APR": 5.3,
    "MAY": 4.72,
    "JUN": 4.4,
    "JUL": 4.48,
    "AUG": 5.17,
    "SEP": 5.47,
    "OCT": 5.51,
    "NOV": 5.65,
    "DEC": 5.48
  },
  {
    "LONG": -37.48,
    "LAT": -7.38,
    "CIDADE": "Santa Terezinha",
    "UF": "PERNAMBUCO",
    "anual": 5.87,
    "JAN": 5.81,
    "FEB": 5.85,
    "MAR": 6.01,
    "APR": 5.91,
    "MAY": 5.32,
    "JUN": 4.96,
    "JUL": 5.3,
    "AUG": 6.03,
    "SEP": 6.53,
    "OCT": 6.43,
    "NOV": 6.39,
    "DEC": 5.96
  },
  {
    "LONG": -37.19,
    "LAT": -7.38,
    "CIDADE": "Itapetim",
    "UF": "PERNAMBUCO",
    "anual": 5.76,
    "JAN": 5.79,
    "FEB": 5.83,
    "MAR": 6.02,
    "APR": 5.81,
    "MAY": 5.19,
    "JUN": 4.81,
    "JUL": 5.05,
    "AUG": 5.74,
    "SEP": 6.33,
    "OCT": 6.33,
    "NOV": 6.31,
    "DEC": 5.91
  },
  {
    "LONG": -35.24,
    "LAT": -7.45,
    "CIDADE": "Ferreiros",
    "UF": "PERNAMBUCO",
    "anual": 5.39,
    "JAN": 5.43,
    "FEB": 5.72,
    "MAR": 5.87,
    "APR": 5.48,
    "MAY": 4.88,
    "JUN": 4.51,
    "JUL": 4.63,
    "AUG": 5.29,
    "SEP": 5.64,
    "OCT": 5.7,
    "NOV": 5.85,
    "DEC": 5.66
  },
  {
    "LONG": -35.27,
    "LAT": -7.41,
    "CIDADE": "Camutanga",
    "UF": "PERNAMBUCO",
    "anual": 5.39,
    "JAN": 5.43,
    "FEB": 5.72,
    "MAR": 5.87,
    "APR": 5.48,
    "MAY": 4.88,
    "JUN": 4.51,
    "JUL": 4.63,
    "AUG": 5.29,
    "SEP": 5.64,
    "OCT": 5.7,
    "NOV": 5.85,
    "DEC": 5.66
  },
  {
    "LONG": -35.11,
    "LAT": -7.41,
    "CIDADE": "Itambé",
    "UF": "PERNAMBUCO",
    "anual": 5.4,
    "JAN": 5.47,
    "FEB": 5.78,
    "MAR": 5.92,
    "APR": 5.49,
    "MAY": 4.87,
    "JUN": 4.51,
    "JUL": 4.61,
    "AUG": 5.25,
    "SEP": 5.63,
    "OCT": 5.73,
    "NOV": 5.88,
    "DEC": 5.71
  },
  {
    "LONG": -37.29,
    "LAT": -7.35,
    "CIDADE": "Brejinho",
    "UF": "PERNAMBUCO",
    "anual": 5.84,
    "JAN": 5.79,
    "FEB": 5.84,
    "MAR": 6.04,
    "APR": 5.87,
    "MAY": 5.29,
    "JUN": 4.94,
    "JUL": 5.28,
    "AUG": 5.94,
    "SEP": 6.44,
    "OCT": 6.41,
    "NOV": 6.37,
    "DEC": 5.89
  },
  {
    "LONG": -44.83,
    "LAT": -10.82,
    "CIDADE": "Sebastião Barros",
    "UF": "PIAUÍ",
    "anual": 5.86,
    "JAN": 5.53,
    "FEB": 5.55,
    "MAR": 5.57,
    "APR": 5.69,
    "MAY": 5.95,
    "JUN": 5.91,
    "JUL": 6.1,
    "AUG": 6.57,
    "SEP": 6.58,
    "OCT": 5.99,
    "NOV": 5.43,
    "DEC": 5.46
  },
  {
    "LONG": -45.19,
    "LAT": -10.64,
    "CIDADE": "Cristalândia do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.87,
    "JAN": 5.57,
    "FEB": 5.55,
    "MAR": 5.57,
    "APR": 5.68,
    "MAY": 5.85,
    "JUN": 5.9,
    "JUL": 6.1,
    "AUG": 6.67,
    "SEP": 6.51,
    "OCT": 5.95,
    "NOV": 5.49,
    "DEC": 5.58
  },
  {
    "LONG": -45.16,
    "LAT": -10.43,
    "CIDADE": "Corrente",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.47,
    "FEB": 5.51,
    "MAR": 5.48,
    "APR": 5.64,
    "MAY": 5.87,
    "JUN": 5.89,
    "JUL": 6.1,
    "AUG": 6.61,
    "SEP": 6.46,
    "OCT": 5.98,
    "NOV": 5.54,
    "DEC": 5.53
  },
  {
    "LONG": -44.24,
    "LAT": -10.33,
    "CIDADE": "Júlio Borges",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.6,
    "FEB": 5.64,
    "MAR": 5.7,
    "APR": 5.71,
    "MAY": 5.81,
    "JUN": 5.84,
    "JUL": 6.11,
    "AUG": 6.66,
    "SEP": 6.72,
    "OCT": 6.06,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -44.64,
    "LAT": -1.02,
    "CIDADE": "Parnaguá",
    "UF": "PIAUÍ",
    "anual": 5.9,
    "JAN": 5.62,
    "FEB": 5.61,
    "MAR": 5.64,
    "APR": 5.75,
    "MAY": 5.94,
    "JUN": 5.85,
    "JUL": 6.09,
    "AUG": 6.58,
    "SEP": 6.56,
    "OCT": 6.08,
    "NOV": 5.57,
    "DEC": 5.57
  },
  {
    "LONG": -44.95,
    "LAT": -10.13,
    "CIDADE": "Riacho Frio",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.53,
    "FEB": 5.58,
    "MAR": 5.55,
    "APR": 5.69,
    "MAY": 5.9,
    "JUN": 5.86,
    "JUL": 6,
    "AUG": 6.53,
    "SEP": 6.52,
    "OCT": 6.05,
    "NOV": 5.53,
    "DEC": 5.48
  },
  {
    "LONG": -43.95,
    "LAT": -10.13,
    "CIDADE": "Avelino Lopes",
    "UF": "PIAUÍ",
    "anual": 5.93,
    "JAN": 5.7,
    "FEB": 5.6,
    "MAR": 5.7,
    "APR": 5.78,
    "MAY": 5.83,
    "JUN": 5.8,
    "JUL": 6.05,
    "AUG": 6.61,
    "SEP": 6.59,
    "OCT": 6.17,
    "NOV": 5.68,
    "DEC": 5.62
  },
  {
    "LONG": -45.3,
    "LAT": -10.03,
    "CIDADE": "São Gonçalo do Gurguéia",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.49,
    "FEB": 5.5,
    "MAR": 5.44,
    "APR": 5.53,
    "MAY": 5.88,
    "JUN": 5.86,
    "JUL": 5.98,
    "AUG": 6.48,
    "SEP": 6.53,
    "OCT": 6,
    "NOV": 5.42,
    "DEC": 5.44
  },
  {
    "LONG": -44.3,
    "LAT": -1,
    "CIDADE": "Curimatá",
    "UF": "PIAUÍ",
    "anual": 5.92,
    "JAN": 5.65,
    "FEB": 5.62,
    "MAR": 5.71,
    "APR": 5.79,
    "MAY": 5.87,
    "JUN": 5.81,
    "JUL": 6.05,
    "AUG": 6.56,
    "SEP": 6.58,
    "OCT": 6.11,
    "NOV": 5.64,
    "DEC": 5.59
  },
  {
    "LONG": -45.47,
    "LAT": -9.92,
    "CIDADE": "Barreiras do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5.49,
    "FEB": 5.55,
    "MAR": 5.5,
    "APR": 5.57,
    "MAY": 5.87,
    "JUN": 5.7,
    "JUL": 5.7,
    "AUG": 6.22,
    "SEP": 6.38,
    "OCT": 5.99,
    "NOV": 5.39,
    "DEC": 5.44
  },
  {
    "LONG": -45.3,
    "LAT": -9.75,
    "CIDADE": "Monte Alegre do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.47,
    "APR": 5.62,
    "MAY": 5.92,
    "JUN": 5.73,
    "JUL": 5.81,
    "AUG": 6.41,
    "SEP": 6.48,
    "OCT": 5.99,
    "NOV": 5.41,
    "DEC": 5.41
  },
  {
    "LONG": -4.53,
    "LAT": -9.83,
    "CIDADE": "Gilbués",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.47,
    "APR": 5.62,
    "MAY": 5.92,
    "JUN": 5.73,
    "JUL": 5.81,
    "AUG": 6.41,
    "SEP": 6.48,
    "OCT": 5.99,
    "NOV": 5.41,
    "DEC": 5.41
  },
  {
    "LONG": -43.91,
    "LAT": -9.73,
    "CIDADE": "Morro Cabeça no Tempo",
    "UF": "PIAUÍ",
    "anual": 5.94,
    "JAN": 5.57,
    "FEB": 5.47,
    "MAR": 5.6,
    "APR": 5.66,
    "MAY": 5.9,
    "JUN": 5.97,
    "JUL": 6.29,
    "AUG": 6.81,
    "SEP": 6.8,
    "OCT": 6.15,
    "NOV": 5.58,
    "DEC": 5.47
  },
  {
    "LONG": -44.58,
    "LAT": -9.49,
    "CIDADE": "Redenção do Gurguéia",
    "UF": "PIAUÍ",
    "anual": 5.8,
    "JAN": 5.46,
    "FEB": 5.39,
    "MAR": 5.48,
    "APR": 5.62,
    "MAY": 5.83,
    "JUN": 5.82,
    "JUL": 6.08,
    "AUG": 6.56,
    "SEP": 6.57,
    "OCT": 6.01,
    "NOV": 5.46,
    "DEC": 5.37
  },
  {
    "LONG": -42.79,
    "LAT": -9.48,
    "CIDADE": "Fartura do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.95,
    "JAN": 5.69,
    "FEB": 5.63,
    "MAR": 5.7,
    "APR": 5.63,
    "MAY": 5.64,
    "JUN": 5.72,
    "JUL": 6.12,
    "AUG": 6.72,
    "SEP": 6.71,
    "OCT": 6.31,
    "NOV": 5.86,
    "DEC": 5.67
  },
  {
    "LONG": -43.69,
    "LAT": -9.39,
    "CIDADE": "Guaribas",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.51,
    "FEB": 5.39,
    "MAR": 5.46,
    "APR": 5.56,
    "MAY": 5.82,
    "JUN": 5.89,
    "JUL": 6.2,
    "AUG": 6.82,
    "SEP": 6.77,
    "OCT": 6.16,
    "NOV": 5.6,
    "DEC": 5.43
  },
  {
    "LONG": -43.33,
    "LAT": -9.28,
    "CIDADE": "Caracol",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.62,
    "FEB": 5.47,
    "MAR": 5.61,
    "APR": 5.55,
    "MAY": 5.67,
    "JUN": 5.77,
    "JUL": 6.15,
    "AUG": 6.81,
    "SEP": 6.66,
    "OCT": 6.27,
    "NOV": 5.78,
    "DEC": 5.56
  },
  {
    "LONG": -42.44,
    "LAT": -9.34,
    "CIDADE": "Dirceu Arcoverde",
    "UF": "PIAUÍ",
    "anual": 5.95,
    "JAN": 5.71,
    "FEB": 5.62,
    "MAR": 5.66,
    "APR": 5.66,
    "MAY": 5.64,
    "JUN": 5.69,
    "JUL": 6.05,
    "AUG": 6.69,
    "SEP": 6.66,
    "OCT": 6.35,
    "NOV": 5.98,
    "DEC": 5.69
  },
  {
    "LONG": -43.13,
    "LAT": -9.23,
    "CIDADE": "Jurema",
    "UF": "PIAUÍ",
    "anual": 5.93,
    "JAN": 5.68,
    "FEB": 5.52,
    "MAR": 5.58,
    "APR": 5.58,
    "MAY": 5.65,
    "JUN": 5.74,
    "JUL": 6.14,
    "AUG": 6.73,
    "SEP": 6.77,
    "OCT": 6.33,
    "NOV": 5.81,
    "DEC": 5.58
  },
  {
    "LONG": -43.05,
    "LAT": -9.19,
    "CIDADE": "Anísio de Abreu",
    "UF": "PIAUÍ",
    "anual": 5.95,
    "JAN": 5.69,
    "FEB": 5.56,
    "MAR": 5.65,
    "APR": 5.65,
    "MAY": 5.63,
    "JUN": 5.74,
    "JUL": 6.12,
    "AUG": 6.74,
    "SEP": 6.76,
    "OCT": 6.32,
    "NOV": 5.88,
    "DEC": 5.66
  },
  {
    "LONG": -42.96,
    "LAT": -9.24,
    "CIDADE": "Várzea Branca",
    "UF": "PIAUÍ",
    "anual": 5.96,
    "JAN": 5.67,
    "FEB": 5.55,
    "MAR": 5.65,
    "APR": 5.69,
    "MAY": 5.64,
    "JUN": 5.73,
    "JUL": 6.11,
    "AUG": 6.72,
    "SEP": 6.74,
    "OCT": 6.34,
    "NOV": 5.92,
    "DEC": 5.69
  },
  {
    "LONG": -42.88,
    "LAT": -9.16,
    "CIDADE": "Bonfim do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.94,
    "JAN": 5.62,
    "FEB": 5.55,
    "MAR": 5.65,
    "APR": 5.66,
    "MAY": 5.65,
    "JUN": 5.71,
    "JUL": 6.1,
    "AUG": 6.73,
    "SEP": 6.69,
    "OCT": 6.34,
    "NOV": 5.91,
    "DEC": 5.69
  },
  {
    "LONG": -42.55,
    "LAT": -9.17,
    "CIDADE": "São Lourenço do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.95,
    "JAN": 5.66,
    "FEB": 5.59,
    "MAR": 5.71,
    "APR": 5.64,
    "MAY": 5.6,
    "JUN": 5.68,
    "JUL": 6.02,
    "AUG": 6.71,
    "SEP": 6.72,
    "OCT": 6.37,
    "NOV": 5.97,
    "DEC": 5.7
  },
  {
    "LONG": -45.92,
    "LAT": -9.12,
    "CIDADE": "Santa Filomena",
    "UF": "PIAUÍ",
    "anual": 5.57,
    "JAN": 5.05,
    "FEB": 5.09,
    "MAR": 5.12,
    "APR": 5.37,
    "MAY": 5.67,
    "JUN": 5.78,
    "JUL": 5.9,
    "AUG": 6.5,
    "SEP": 6.49,
    "OCT": 5.68,
    "NOV": 5.12,
    "DEC": 5.07
  },
  {
    "LONG": -44.36,
    "LAT": -9.07,
    "CIDADE": "Bom Jesus",
    "UF": "PIAUÍ",
    "anual": 5.77,
    "JAN": 5.37,
    "FEB": 5.33,
    "MAR": 5.49,
    "APR": 5.64,
    "MAY": 5.75,
    "JUN": 5.74,
    "JUL": 6.01,
    "AUG": 6.51,
    "SEP": 6.6,
    "OCT": 6,
    "NOV": 5.48,
    "DEC": 5.33
  },
  {
    "LONG": -43,
    "LAT": -9.06,
    "CIDADE": "São Braz do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.94,
    "JAN": 5.61,
    "FEB": 5.51,
    "MAR": 5.58,
    "APR": 5.64,
    "MAY": 5.67,
    "JUN": 5.78,
    "JUL": 6.14,
    "AUG": 6.75,
    "SEP": 6.82,
    "OCT": 6.34,
    "NOV": 5.82,
    "DEC": 5.58
  },
  {
    "LONG": -44.4,
    "LAT": -9.01,
    "CIDADE": "Currais",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.32,
    "FEB": 5.33,
    "MAR": 5.39,
    "APR": 5.61,
    "MAY": 5.76,
    "JUN": 5.79,
    "JUL": 6.11,
    "AUG": 6.68,
    "SEP": 6.73,
    "OCT": 6.01,
    "NOV": 5.45,
    "DEC": 5.31
  },
  {
    "LONG": -4.27,
    "LAT": -9.01,
    "CIDADE": "São Raimundo Nonato",
    "UF": "PIAUÍ",
    "anual": 5.94,
    "JAN": 5.63,
    "FEB": 5.54,
    "MAR": 5.65,
    "APR": 5.61,
    "MAY": 5.62,
    "JUN": 5.69,
    "JUL": 6.05,
    "AUG": 6.7,
    "SEP": 6.77,
    "OCT": 6.39,
    "NOV": 5.92,
    "DEC": 5.69
  },
  {
    "LONG": -41.97,
    "LAT": -9.01,
    "CIDADE": "Dom Inocêncio",
    "UF": "PIAUÍ",
    "anual": 5.99,
    "JAN": 5.79,
    "FEB": 5.76,
    "MAR": 5.8,
    "APR": 5.59,
    "MAY": 5.61,
    "JUN": 5.67,
    "JUL": 6.06,
    "AUG": 6.69,
    "SEP": 6.62,
    "OCT": 6.46,
    "NOV": 6.02,
    "DEC": 5.77
  },
  {
    "LONG": -44.13,
    "LAT": -8.95,
    "CIDADE": "Santa Luz",
    "UF": "PIAUÍ",
    "anual": 5.74,
    "JAN": 5.28,
    "FEB": 5.28,
    "MAR": 5.34,
    "APR": 5.6,
    "MAY": 5.73,
    "JUN": 5.73,
    "JUL": 6.02,
    "AUG": 6.57,
    "SEP": 6.65,
    "OCT": 5.96,
    "NOV": 5.42,
    "DEC": 5.27
  },
  {
    "LONG": -44.22,
    "LAT": -8.82,
    "CIDADE": "Cristino Castro",
    "UF": "PIAUÍ",
    "anual": 5.78,
    "JAN": 5.32,
    "FEB": 5.26,
    "MAR": 5.37,
    "APR": 5.6,
    "MAY": 5.76,
    "JUN": 5.74,
    "JUL": 6.11,
    "AUG": 6.7,
    "SEP": 6.75,
    "OCT": 6.01,
    "NOV": 5.48,
    "DEC": 5.3
  },
  {
    "LONG": -4.25,
    "LAT": -8.83,
    "CIDADE": "Coronel José Dias",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.55,
    "FEB": 5.53,
    "MAR": 5.57,
    "APR": 5.54,
    "MAY": 5.58,
    "JUN": 5.66,
    "JUL": 6.1,
    "AUG": 6.76,
    "SEP": 6.74,
    "OCT": 6.4,
    "NOV": 5.89,
    "DEC": 5.59
  },
  {
    "LONG": -44.25,
    "LAT": -0.87,
    "CIDADE": "Palmeira do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.33,
    "FEB": 5.23,
    "MAR": 5.38,
    "APR": 5.67,
    "MAY": 5.76,
    "JUN": 5.83,
    "JUL": 6.09,
    "AUG": 6.71,
    "SEP": 6.76,
    "OCT": 6.02,
    "NOV": 5.45,
    "DEC": 5.27
  },
  {
    "LONG": -41.41,
    "LAT": -8.57,
    "CIDADE": "Queimada Nova",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.73,
    "FEB": 5.66,
    "MAR": 5.73,
    "APR": 5.52,
    "MAY": 5.45,
    "JUN": 5.48,
    "JUL": 5.81,
    "AUG": 6.52,
    "SEP": 6.55,
    "OCT": 6.44,
    "NOV": 6.15,
    "DEC": 5.8
  },
  {
    "LONG": -42.42,
    "LAT": -8.51,
    "CIDADE": "João Costa",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.49,
    "FEB": 5.57,
    "MAR": 5.56,
    "APR": 5.58,
    "MAY": 5.64,
    "JUN": 5.71,
    "JUL": 6.02,
    "AUG": 6.75,
    "SEP": 6.7,
    "OCT": 6.39,
    "NOV": 5.9,
    "DEC": 5.61
  },
  {
    "LONG": -41.82,
    "LAT": -8.5,
    "CIDADE": "Capitão Gervásio Oliveira",
    "UF": "PIAUÍ",
    "anual": 5.89,
    "JAN": 5.58,
    "FEB": 5.57,
    "MAR": 5.64,
    "APR": 5.5,
    "MAY": 5.49,
    "JUN": 5.53,
    "JUL": 5.95,
    "AUG": 6.66,
    "SEP": 6.65,
    "OCT": 6.41,
    "NOV": 5.98,
    "DEC": 5.7
  },
  {
    "LONG": -41.54,
    "LAT": -8.48,
    "CIDADE": "Lagoa do Barro do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.96,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.77,
    "APR": 5.6,
    "MAY": 5.58,
    "JUN": 5.58,
    "JUL": 5.95,
    "AUG": 6.63,
    "SEP": 6.58,
    "OCT": 6.49,
    "NOV": 6.14,
    "DEC": 5.79
  },
  {
    "LONG": -43.87,
    "LAT": -8.44,
    "CIDADE": "Alvorada do Gurguéia",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.25,
    "FEB": 5.16,
    "MAR": 5.31,
    "APR": 5.56,
    "MAY": 5.68,
    "JUN": 5.71,
    "JUL": 5.98,
    "AUG": 6.48,
    "SEP": 6.63,
    "OCT": 6.06,
    "NOV": 5.54,
    "DEC": 5.24
  },
  {
    "LONG": -42.91,
    "LAT": -8.4,
    "CIDADE": "Tamboril do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.82,
    "JAN": 5.3,
    "FEB": 5.43,
    "MAR": 5.44,
    "APR": 5.52,
    "MAY": 5.59,
    "JUN": 5.62,
    "JUL": 6,
    "AUG": 6.76,
    "SEP": 6.68,
    "OCT": 6.26,
    "NOV": 5.72,
    "DEC": 5.47
  },
  {
    "LONG": -42.26,
    "LAT": -8.36,
    "CIDADE": "São João do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.89,
    "JAN": 5.42,
    "FEB": 5.52,
    "MAR": 5.65,
    "APR": 5.63,
    "MAY": 5.59,
    "JUN": 5.68,
    "JUL": 5.98,
    "AUG": 6.65,
    "SEP": 6.64,
    "OCT": 6.35,
    "NOV": 5.94,
    "DEC": 5.63
  },
  {
    "LONG": -41.84,
    "LAT": -8.38,
    "CIDADE": "Campo Alegre do Fidalgo",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.5,
    "FEB": 5.58,
    "MAR": 5.65,
    "APR": 5.53,
    "MAY": 5.61,
    "JUN": 5.63,
    "JUL": 5.99,
    "AUG": 6.66,
    "SEP": 6.64,
    "OCT": 6.46,
    "NOV": 6,
    "DEC": 5.67
  },
  {
    "LONG": -43.79,
    "LAT": -8.18,
    "CIDADE": "Colônia do Gurguéia",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 5.14,
    "FEB": 5.21,
    "MAR": 5.27,
    "APR": 5.43,
    "MAY": 5.65,
    "JUN": 5.73,
    "JUL": 5.96,
    "AUG": 6.47,
    "SEP": 6.56,
    "OCT": 6.07,
    "NOV": 5.53,
    "DEC": 5.26
  },
  {
    "LONG": -42.84,
    "LAT": -8.21,
    "CIDADE": "Brejo do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.34,
    "FEB": 5.46,
    "MAR": 5.52,
    "APR": 5.55,
    "MAY": 5.64,
    "JUN": 5.67,
    "JUL": 5.94,
    "AUG": 6.66,
    "SEP": 6.58,
    "OCT": 6.31,
    "NOV": 5.83,
    "DEC": 5.57
  },
  {
    "LONG": -41.69,
    "LAT": -8.24,
    "CIDADE": "São Francisco de Assis do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.92,
    "JAN": 5.46,
    "FEB": 5.52,
    "MAR": 5.67,
    "APR": 5.54,
    "MAY": 5.56,
    "JUN": 5.63,
    "JUL": 5.97,
    "AUG": 6.63,
    "SEP": 6.74,
    "OCT": 6.5,
    "NOV": 6.09,
    "DEC": 5.7
  },
  {
    "LONG": -41.08,
    "LAT": -8.22,
    "CIDADE": "Acauã",
    "UF": "PIAUÍ",
    "anual": 5.89,
    "JAN": 5.7,
    "FEB": 5.6,
    "MAR": 5.77,
    "APR": 5.57,
    "MAY": 5.39,
    "JUN": 5.38,
    "JUL": 5.73,
    "AUG": 6.45,
    "SEP": 6.67,
    "OCT": 6.48,
    "NOV": 6.16,
    "DEC": 5.82
  },
  {
    "LONG": -43.66,
    "LAT": -8.1,
    "CIDADE": "Eliseu Martins",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 5.16,
    "FEB": 5.23,
    "MAR": 5.25,
    "APR": 5.46,
    "MAY": 5.6,
    "JUN": 5.67,
    "JUL": 6.03,
    "AUG": 6.42,
    "SEP": 6.58,
    "OCT": 6.15,
    "NOV": 5.52,
    "DEC": 5.28
  },
  {
    "LONG": -42.95,
    "LAT": -8.11,
    "CIDADE": "Canto do Buriti",
    "UF": "PIAUÍ",
    "anual": 5.81,
    "JAN": 5.27,
    "FEB": 5.41,
    "MAR": 5.48,
    "APR": 5.57,
    "MAY": 5.63,
    "JUN": 5.7,
    "JUL": 5.93,
    "AUG": 6.6,
    "SEP": 6.58,
    "OCT": 6.28,
    "NOV": 5.76,
    "DEC": 5.51
  },
  {
    "LONG": -42.29,
    "LAT": -8.07,
    "CIDADE": "Pedro Laurentino",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.35,
    "FEB": 5.44,
    "MAR": 5.56,
    "APR": 5.51,
    "MAY": 5.57,
    "JUN": 5.58,
    "JUL": 5.92,
    "AUG": 6.63,
    "SEP": 6.63,
    "OCT": 6.34,
    "NOV": 5.92,
    "DEC": 5.51
  },
  {
    "LONG": -42.05,
    "LAT": -8.1,
    "CIDADE": "Nova Santa Rita",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.37,
    "FEB": 5.42,
    "MAR": 5.57,
    "APR": 5.53,
    "MAY": 5.59,
    "JUN": 5.66,
    "JUL": 5.94,
    "AUG": 6.6,
    "SEP": 6.66,
    "OCT": 6.36,
    "NOV": 5.95,
    "DEC": 5.58
  },
  {
    "LONG": -41.14,
    "LAT": -8.13,
    "CIDADE": "Paulistana",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.59,
    "FEB": 5.51,
    "MAR": 5.74,
    "APR": 5.57,
    "MAY": 5.45,
    "JUN": 5.42,
    "JUL": 5.8,
    "AUG": 6.51,
    "SEP": 6.63,
    "OCT": 6.48,
    "NOV": 6.14,
    "DEC": 5.76
  },
  {
    "LONG": -40.8,
    "LAT": -8.14,
    "CIDADE": "Betânia do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.64,
    "FEB": 5.54,
    "MAR": 5.81,
    "APR": 5.5,
    "MAY": 5.34,
    "JUN": 5.29,
    "JUL": 5.68,
    "AUG": 6.46,
    "SEP": 6.72,
    "OCT": 6.52,
    "NOV": 6.21,
    "DEC": 5.83
  },
  {
    "LONG": -43.87,
    "LAT": -8,
    "CIDADE": "Manoel Emídio",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.13,
    "FEB": 5.17,
    "MAR": 5.23,
    "APR": 5.51,
    "MAY": 5.67,
    "JUN": 5.78,
    "JUL": 6.03,
    "AUG": 6.55,
    "SEP": 6.63,
    "OCT": 6.12,
    "NOV": 5.57,
    "DEC": 5.24
  },
  {
    "LONG": -43.23,
    "LAT": -0.8,
    "CIDADE": "Pavussu",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.21,
    "FEB": 5.3,
    "MAR": 5.28,
    "APR": 5.41,
    "MAY": 5.71,
    "JUN": 5.7,
    "JUL": 6.05,
    "AUG": 6.58,
    "SEP": 6.67,
    "OCT": 6.23,
    "NOV": 5.63,
    "DEC": 5.39
  },
  {
    "LONG": -41.87,
    "LAT": -7.99,
    "CIDADE": "Bela Vista do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.45,
    "FEB": 5.48,
    "MAR": 5.62,
    "APR": 5.49,
    "MAY": 5.58,
    "JUN": 5.63,
    "JUL": 5.94,
    "AUG": 6.56,
    "SEP": 6.66,
    "OCT": 6.42,
    "NOV": 6.02,
    "DEC": 5.65
  },
  {
    "LONG": -45.21,
    "LAT": -7.86,
    "CIDADE": "Baixa Grande do Ribeiro",
    "UF": "PIAUÍ",
    "anual": 5.49,
    "JAN": 4.78,
    "FEB": 4.95,
    "MAR": 4.92,
    "APR": 5.35,
    "MAY": 5.63,
    "JUN": 5.74,
    "JUL": 5.91,
    "AUG": 6.3,
    "SEP": 6.55,
    "OCT": 5.79,
    "NOV": 5.09,
    "DEC": 4.87
  },
  {
    "LONG": -42.82,
    "LAT": -7.86,
    "CIDADE": "Pajeú do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.81,
    "JAN": 5.22,
    "FEB": 5.25,
    "MAR": 5.41,
    "APR": 5.49,
    "MAY": 5.59,
    "JUN": 5.76,
    "JUL": 6,
    "AUG": 6.65,
    "SEP": 6.76,
    "OCT": 6.33,
    "NOV": 5.77,
    "DEC": 5.44
  },
  {
    "LONG": -42.5,
    "LAT": -7.86,
    "CIDADE": "Socorro do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.33,
    "FEB": 5.37,
    "MAR": 5.49,
    "APR": 5.48,
    "MAY": 5.6,
    "JUN": 5.69,
    "JUL": 6,
    "AUG": 6.62,
    "SEP": 6.69,
    "OCT": 6.36,
    "NOV": 5.82,
    "DEC": 5.49
  },
  {
    "LONG": -41.91,
    "LAT": -7.86,
    "CIDADE": "Simplício Mendes",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.49,
    "FEB": 5.42,
    "MAR": 5.6,
    "APR": 5.53,
    "MAY": 5.57,
    "JUN": 5.66,
    "JUL": 5.95,
    "AUG": 6.61,
    "SEP": 6.72,
    "OCT": 6.38,
    "NOV": 6.02,
    "DEC": 5.64
  },
  {
    "LONG": -41.59,
    "LAT": -7.88,
    "CIDADE": "Conceição do Canindé",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.48,
    "FEB": 5.47,
    "MAR": 5.65,
    "APR": 5.57,
    "MAY": 5.54,
    "JUN": 5.64,
    "JUL": 5.9,
    "AUG": 6.53,
    "SEP": 6.72,
    "OCT": 6.39,
    "NOV": 6.04,
    "DEC": 5.65
  },
  {
    "LONG": -41.21,
    "LAT": -7.93,
    "CIDADE": "Jacobina do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.89,
    "JAN": 5.57,
    "FEB": 5.51,
    "MAR": 5.67,
    "APR": 5.52,
    "MAY": 5.45,
    "JUN": 5.53,
    "JUL": 5.87,
    "AUG": 6.6,
    "SEP": 6.72,
    "OCT": 6.44,
    "NOV": 6.11,
    "DEC": 5.74
  },
  {
    "LONG": -43.14,
    "LAT": -7.77,
    "CIDADE": "Rio Grande do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5.1,
    "FEB": 5.29,
    "MAR": 5.34,
    "APR": 5.42,
    "MAY": 5.58,
    "JUN": 5.64,
    "JUL": 5.99,
    "AUG": 6.46,
    "SEP": 6.63,
    "OCT": 6.24,
    "NOV": 5.67,
    "DEC": 5.39
  },
  {
    "LONG": -42.92,
    "LAT": -7.79,
    "CIDADE": "Flores do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.77,
    "JAN": 5.17,
    "FEB": 5.22,
    "MAR": 5.3,
    "APR": 5.47,
    "MAY": 5.6,
    "JUN": 5.72,
    "JUL": 5.98,
    "AUG": 6.55,
    "SEP": 6.78,
    "OCT": 6.27,
    "NOV": 5.7,
    "DEC": 5.44
  },
  {
    "LONG": -42.25,
    "LAT": -7.78,
    "CIDADE": "Paes Landim",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.35,
    "FEB": 5.39,
    "MAR": 5.6,
    "APR": 5.52,
    "MAY": 5.62,
    "JUN": 5.66,
    "JUL": 5.95,
    "AUG": 6.57,
    "SEP": 6.64,
    "OCT": 6.35,
    "NOV": 5.9,
    "DEC": 5.55
  },
  {
    "LONG": -40.9,
    "LAT": -7.83,
    "CIDADE": "Curral Novo do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.9,
    "JAN": 5.51,
    "FEB": 5.46,
    "MAR": 5.74,
    "APR": 5.53,
    "MAY": 5.45,
    "JUN": 5.49,
    "JUL": 5.9,
    "AUG": 6.56,
    "SEP": 6.73,
    "OCT": 6.46,
    "NOV": 6.18,
    "DEC": 5.77
  },
  {
    "LONG": -43.72,
    "LAT": -7.69,
    "CIDADE": "Canavieira",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 5.08,
    "FEB": 5.13,
    "MAR": 5.23,
    "APR": 5.36,
    "MAY": 5.65,
    "JUN": 5.76,
    "JUL": 6.04,
    "AUG": 6.44,
    "SEP": 6.58,
    "OCT": 6.15,
    "NOV": 5.6,
    "DEC": 5.25
  },
  {
    "LONG": -42.71,
    "LAT": -7.69,
    "CIDADE": "Ribeira do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.8,
    "JAN": 5.23,
    "FEB": 5.29,
    "MAR": 5.41,
    "APR": 5.43,
    "MAY": 5.64,
    "JUN": 5.72,
    "JUL": 5.95,
    "AUG": 6.6,
    "SEP": 6.75,
    "OCT": 6.33,
    "NOV": 5.77,
    "DEC": 5.47
  },
  {
    "LONG": -41.88,
    "LAT": -7.66,
    "CIDADE": "Campinas do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.86,
    "JAN": 5.48,
    "FEB": 5.46,
    "MAR": 5.65,
    "APR": 5.52,
    "MAY": 5.55,
    "JUN": 5.63,
    "JUL": 5.87,
    "AUG": 6.45,
    "SEP": 6.61,
    "OCT": 6.4,
    "NOV": 6.08,
    "DEC": 5.67
  },
  {
    "LONG": -41.68,
    "LAT": -7.74,
    "CIDADE": "Isaías Coelho",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.43,
    "FEB": 5.45,
    "MAR": 5.59,
    "APR": 5.49,
    "MAY": 5.55,
    "JUN": 5.6,
    "JUL": 5.92,
    "AUG": 6.56,
    "SEP": 6.76,
    "OCT": 6.43,
    "NOV": 6.08,
    "DEC": 5.67
  },
  {
    "LONG": -41.24,
    "LAT": -7.68,
    "CIDADE": "Patos do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.87,
    "JAN": 5.5,
    "FEB": 5.5,
    "MAR": 5.7,
    "APR": 5.51,
    "MAY": 5.44,
    "JUN": 5.51,
    "JUL": 5.88,
    "AUG": 6.48,
    "SEP": 6.69,
    "OCT": 6.37,
    "NOV": 6.14,
    "DEC": 5.76
  },
  {
    "LONG": -40.99,
    "LAT": -7.73,
    "CIDADE": "Caridade do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.42,
    "FEB": 5.41,
    "MAR": 5.66,
    "APR": 5.5,
    "MAY": 5.45,
    "JUN": 5.49,
    "JUL": 5.91,
    "AUG": 6.59,
    "SEP": 6.75,
    "OCT": 6.45,
    "NOV": 6.15,
    "DEC": 5.76
  },
  {
    "LONG": -45.25,
    "LAT": -7.56,
    "CIDADE": "Ribeiro Gonçalves",
    "UF": "PIAUÍ",
    "anual": 5.52,
    "JAN": 4.74,
    "FEB": 5.06,
    "MAR": 5,
    "APR": 5.35,
    "MAY": 5.59,
    "JUN": 5.77,
    "JUL": 5.89,
    "AUG": 6.33,
    "SEP": 6.6,
    "OCT": 5.81,
    "NOV": 5.1,
    "DEC": 4.94
  },
  {
    "LONG": -44.06,
    "LAT": -7.57,
    "CIDADE": "Sebastião Leal",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 4.99,
    "FEB": 5.07,
    "MAR": 5.11,
    "APR": 5.39,
    "MAY": 5.65,
    "JUN": 5.8,
    "JUL": 5.92,
    "AUG": 6.4,
    "SEP": 6.63,
    "OCT": 6.13,
    "NOV": 5.46,
    "DEC": 5.19
  },
  {
    "LONG": -43.95,
    "LAT": -7.63,
    "CIDADE": "Bertolínia",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 5,
    "FEB": 5.08,
    "MAR": 5.12,
    "APR": 5.33,
    "MAY": 5.61,
    "JUN": 5.77,
    "JUL": 5.97,
    "AUG": 6.49,
    "SEP": 6.62,
    "OCT": 6.12,
    "NOV": 5.5,
    "DEC": 5.2
  },
  {
    "LONG": -43.03,
    "LAT": -7.6,
    "CIDADE": "Itaueira",
    "UF": "PIAUÍ",
    "anual": 5.74,
    "JAN": 5.15,
    "FEB": 5.22,
    "MAR": 5.33,
    "APR": 5.45,
    "MAY": 5.59,
    "JUN": 5.67,
    "JUL": 5.98,
    "AUG": 6.42,
    "SEP": 6.64,
    "OCT": 6.24,
    "NOV": 5.73,
    "DEC": 5.47
  },
  {
    "LONG": -42.37,
    "LAT": -7.6,
    "CIDADE": "São Miguel do Fidalgo",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.38,
    "FEB": 5.37,
    "MAR": 5.51,
    "APR": 5.6,
    "MAY": 5.6,
    "JUN": 5.65,
    "JUL": 5.9,
    "AUG": 6.53,
    "SEP": 6.64,
    "OCT": 6.36,
    "NOV": 5.86,
    "DEC": 5.53
  },
  {
    "LONG": -41.47,
    "LAT": -7.6,
    "CIDADE": "Vera Mendes",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.43,
    "FEB": 5.39,
    "MAR": 5.56,
    "APR": 5.48,
    "MAY": 5.52,
    "JUN": 5.64,
    "JUL": 5.96,
    "AUG": 6.56,
    "SEP": 6.79,
    "OCT": 6.43,
    "NOV": 6.12,
    "DEC": 5.67
  },
  {
    "LONG": -40.82,
    "LAT": -0.76,
    "CIDADE": "Simões",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.46,
    "FEB": 5.37,
    "MAR": 5.6,
    "APR": 5.51,
    "MAY": 5.52,
    "JUN": 5.56,
    "JUL": 5.95,
    "AUG": 6.54,
    "SEP": 6.77,
    "OCT": 6.47,
    "NOV": 6.1,
    "DEC": 5.71
  },
  {
    "LONG": -42.57,
    "LAT": -0.75,
    "CIDADE": "São José do Peixe",
    "UF": "PIAUÍ",
    "anual": 5.78,
    "JAN": 5.23,
    "FEB": 5.31,
    "MAR": 5.44,
    "APR": 5.48,
    "MAY": 5.59,
    "JUN": 5.67,
    "JUL": 6,
    "AUG": 6.43,
    "SEP": 6.67,
    "OCT": 6.32,
    "NOV": 5.8,
    "DEC": 5.47
  },
  {
    "LONG": -41.8,
    "LAT": -7.47,
    "CIDADE": "Floresta do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.39,
    "FEB": 5.37,
    "MAR": 5.57,
    "APR": 5.47,
    "MAY": 5.55,
    "JUN": 5.63,
    "JUL": 5.91,
    "AUG": 6.41,
    "SEP": 6.67,
    "OCT": 6.35,
    "NOV": 6.01,
    "DEC": 5.58
  },
  {
    "LONG": -41.11,
    "LAT": -7.48,
    "CIDADE": "Massapê do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.4,
    "FEB": 5.47,
    "MAR": 5.67,
    "APR": 5.48,
    "MAY": 5.47,
    "JUN": 5.51,
    "JUL": 5.84,
    "AUG": 6.47,
    "SEP": 6.71,
    "OCT": 6.41,
    "NOV": 6.09,
    "DEC": 5.69
  },
  {
    "LONG": -41.91,
    "LAT": -0.74,
    "CIDADE": "Santo Inácio do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.33,
    "FEB": 5.33,
    "MAR": 5.5,
    "APR": 5.45,
    "MAY": 5.57,
    "JUN": 5.65,
    "JUL": 5.95,
    "AUG": 6.55,
    "SEP": 6.73,
    "OCT": 6.4,
    "NOV": 6,
    "DEC": 5.56
  },
  {
    "LONG": -41.48,
    "LAT": -7.45,
    "CIDADE": "Itainópolis",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.29,
    "FEB": 5.37,
    "MAR": 5.59,
    "APR": 5.49,
    "MAY": 5.55,
    "JUN": 5.58,
    "JUL": 5.94,
    "AUG": 6.47,
    "SEP": 6.75,
    "OCT": 6.38,
    "NOV": 6.05,
    "DEC": 5.59
  },
  {
    "LONG": -41.14,
    "LAT": -7.36,
    "CIDADE": "Jaicós",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.34,
    "FEB": 5.4,
    "MAR": 5.6,
    "APR": 5.48,
    "MAY": 5.49,
    "JUN": 5.55,
    "JUL": 5.85,
    "AUG": 6.47,
    "SEP": 6.74,
    "OCT": 6.38,
    "NOV": 6.04,
    "DEC": 5.64
  },
  {
    "LONG": -40.97,
    "LAT": -7.37,
    "CIDADE": "Belém do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.34,
    "FEB": 5.39,
    "MAR": 5.56,
    "APR": 5.44,
    "MAY": 5.47,
    "JUN": 5.51,
    "JUL": 5.86,
    "AUG": 6.46,
    "SEP": 6.73,
    "OCT": 6.36,
    "NOV": 6.11,
    "DEC": 5.7
  },
  {
    "LONG": -40.66,
    "LAT": -7.44,
    "CIDADE": "Marcolândia",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.28,
    "FEB": 5.35,
    "MAR": 5.45,
    "APR": 5.5,
    "MAY": 5.51,
    "JUN": 5.54,
    "JUL": 5.86,
    "AUG": 6.31,
    "SEP": 6.58,
    "OCT": 6.38,
    "NOV": 6.1,
    "DEC": 5.66
  },
  {
    "LONG": -43.93,
    "LAT": -7.26,
    "CIDADE": "Landri Sales",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 4.91,
    "FEB": 5.01,
    "MAR": 5.04,
    "APR": 5.28,
    "MAY": 5.6,
    "JUN": 5.78,
    "JUL": 6,
    "AUG": 6.41,
    "SEP": 6.68,
    "OCT": 6.12,
    "NOV": 5.51,
    "DEC": 5.14
  },
  {
    "LONG": -41.57,
    "LAT": -7.28,
    "CIDADE": "Aroeiras do Itaim",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.25,
    "FEB": 5.34,
    "MAR": 5.61,
    "APR": 5.48,
    "MAY": 5.54,
    "JUN": 5.6,
    "JUL": 5.94,
    "AUG": 6.46,
    "SEP": 6.74,
    "OCT": 6.37,
    "NOV": 6.03,
    "DEC": 5.62
  },
  {
    "LONG": -40.91,
    "LAT": -0.73,
    "CIDADE": "Padre Marcos",
    "UF": "PIAUÍ",
    "anual": 5.8,
    "JAN": 5.29,
    "FEB": 5.4,
    "MAR": 5.57,
    "APR": 5.42,
    "MAY": 5.47,
    "JUN": 5.45,
    "JUL": 5.81,
    "AUG": 6.39,
    "SEP": 6.69,
    "OCT": 6.32,
    "NOV": 6.08,
    "DEC": 5.7
  },
  {
    "LONG": -40.79,
    "LAT": -7.33,
    "CIDADE": "Francisco Macedo",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.25,
    "FEB": 5.35,
    "MAR": 5.5,
    "APR": 5.49,
    "MAY": 5.5,
    "JUN": 5.54,
    "JUL": 5.97,
    "AUG": 6.58,
    "SEP": 6.78,
    "OCT": 6.39,
    "NOV": 6.15,
    "DEC": 5.67
  },
  {
    "LONG": -40.64,
    "LAT": -7.33,
    "CIDADE": "Caldeirão Grande do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.86,
    "JAN": 5.19,
    "FEB": 5.35,
    "MAR": 5.45,
    "APR": 5.5,
    "MAY": 5.52,
    "JUN": 5.59,
    "JUL": 6.02,
    "AUG": 6.57,
    "SEP": 6.8,
    "OCT": 6.44,
    "NOV": 6.15,
    "DEC": 5.68
  },
  {
    "LONG": -4.46,
    "LAT": -7.24,
    "CIDADE": "Uruçuí",
    "UF": "PIAUÍ",
    "anual": 5.54,
    "JAN": 4.8,
    "FEB": 4.97,
    "MAR": 5.02,
    "APR": 5.26,
    "MAY": 5.57,
    "JUN": 5.76,
    "JUL": 5.96,
    "AUG": 6.27,
    "SEP": 6.62,
    "OCT": 5.92,
    "NOV": 5.34,
    "DEC": 5.04
  },
  {
    "LONG": -44.19,
    "LAT": -7.22,
    "CIDADE": "Antônio Almeida",
    "UF": "PIAUÍ",
    "anual": 5.57,
    "JAN": 4.85,
    "FEB": 4.93,
    "MAR": 4.97,
    "APR": 5.28,
    "MAY": 5.6,
    "JUN": 5.79,
    "JUL": 5.97,
    "AUG": 6.38,
    "SEP": 6.67,
    "OCT": 6.01,
    "NOV": 5.41,
    "DEC": 5.01
  },
  {
    "LONG": -42.54,
    "LAT": -7.25,
    "CIDADE": "São Francisco do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.74,
    "JAN": 5.15,
    "FEB": 5.21,
    "MAR": 5.32,
    "APR": 5.39,
    "MAY": 5.57,
    "JUN": 5.59,
    "JUL": 5.92,
    "AUG": 6.43,
    "SEP": 6.72,
    "OCT": 6.27,
    "NOV": 5.83,
    "DEC": 5.45
  },
  {
    "LONG": -42.18,
    "LAT": -7.23,
    "CIDADE": "Colônia do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.81,
    "JAN": 5.27,
    "FEB": 5.27,
    "MAR": 5.42,
    "APR": 5.45,
    "MAY": 5.65,
    "JUN": 5.63,
    "JUL": 5.96,
    "AUG": 6.45,
    "SEP": 6.77,
    "OCT": 6.34,
    "NOV": 6.03,
    "DEC": 5.55
  },
  {
    "LONG": -41.91,
    "LAT": -7.23,
    "CIDADE": "Wall Ferraz",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.35,
    "FEB": 5.33,
    "MAR": 5.54,
    "APR": 5.5,
    "MAY": 5.61,
    "JUN": 5.65,
    "JUL": 5.97,
    "AUG": 6.44,
    "SEP": 6.75,
    "OCT": 6.38,
    "NOV": 6.08,
    "DEC": 5.61
  },
  {
    "LONG": -41.76,
    "LAT": -7.18,
    "CIDADE": "Santa Cruz do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.31,
    "FEB": 5.32,
    "MAR": 5.61,
    "APR": 5.5,
    "MAY": 5.58,
    "JUN": 5.6,
    "JUL": 5.95,
    "AUG": 6.44,
    "SEP": 6.7,
    "OCT": 6.39,
    "NOV": 6.17,
    "DEC": 5.66
  },
  {
    "LONG": -41.36,
    "LAT": -7.16,
    "CIDADE": "Geminiano",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.25,
    "FEB": 5.27,
    "MAR": 5.49,
    "APR": 5.44,
    "MAY": 5.51,
    "JUN": 5.49,
    "JUL": 5.87,
    "AUG": 6.5,
    "SEP": 6.72,
    "OCT": 6.3,
    "NOV": 6.02,
    "DEC": 5.59
  },
  {
    "LONG": -40.86,
    "LAT": -7.24,
    "CIDADE": "Alegrete do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.35,
    "FEB": 5.35,
    "MAR": 5.61,
    "APR": 5.42,
    "MAY": 5.44,
    "JUN": 5.42,
    "JUL": 5.84,
    "AUG": 6.32,
    "SEP": 6.66,
    "OCT": 6.3,
    "NOV": 6.13,
    "DEC": 5.7
  },
  {
    "LONG": -4.39,
    "LAT": -7.12,
    "CIDADE": "Marcos Parente",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 4.89,
    "FEB": 4.97,
    "MAR": 5.07,
    "APR": 5.2,
    "MAY": 5.53,
    "JUN": 5.77,
    "JUL": 6.04,
    "AUG": 6.39,
    "SEP": 6.7,
    "OCT": 6.13,
    "NOV": 5.53,
    "DEC": 5.15
  },
  {
    "LONG": -43.5,
    "LAT": -7.09,
    "CIDADE": "Jerumenha",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.95,
    "FEB": 5.05,
    "MAR": 5.16,
    "APR": 5.3,
    "MAY": 5.53,
    "JUN": 5.77,
    "JUL": 5.96,
    "AUG": 6.44,
    "SEP": 6.66,
    "OCT": 6.21,
    "NOV": 5.67,
    "DEC": 5.29
  },
  {
    "LONG": -41.7,
    "LAT": -7.1,
    "CIDADE": "Paquetá",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.19,
    "FEB": 5.31,
    "MAR": 5.48,
    "APR": 5.53,
    "MAY": 5.53,
    "JUN": 5.57,
    "JUL": 5.99,
    "AUG": 6.54,
    "SEP": 6.78,
    "OCT": 6.41,
    "NOV": 6.08,
    "DEC": 5.56
  },
  {
    "LONG": -41.47,
    "LAT": -7.08,
    "CIDADE": "Picos",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.35,
    "FEB": 5.37,
    "MAR": 5.62,
    "APR": 5.5,
    "MAY": 5.44,
    "JUN": 5.44,
    "JUL": 5.83,
    "AUG": 6.52,
    "SEP": 6.73,
    "OCT": 6.31,
    "NOV": 6.1,
    "DEC": 5.7
  },
  {
    "LONG": -41.03,
    "LAT": -7.13,
    "CIDADE": "Campo Grande do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.33,
    "FEB": 5.33,
    "MAR": 5.61,
    "APR": 5.44,
    "MAY": 5.38,
    "JUN": 5.4,
    "JUL": 5.84,
    "AUG": 6.41,
    "SEP": 6.66,
    "OCT": 6.31,
    "NOV": 6.1,
    "DEC": 5.67
  },
  {
    "LONG": -40.94,
    "LAT": -7.14,
    "CIDADE": "Vila Nova do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.78,
    "JAN": 5.31,
    "FEB": 5.31,
    "MAR": 5.61,
    "APR": 5.44,
    "MAY": 5.33,
    "JUN": 5.38,
    "JUL": 5.83,
    "AUG": 6.43,
    "SEP": 6.71,
    "OCT": 6.3,
    "NOV": 6.1,
    "DEC": 5.68
  },
  {
    "LONG": -40.84,
    "LAT": -7.08,
    "CIDADE": "São Julião",
    "UF": "PIAUÍ",
    "anual": 5.82,
    "JAN": 5.33,
    "FEB": 5.38,
    "MAR": 5.58,
    "APR": 5.44,
    "MAY": 5.36,
    "JUN": 5.37,
    "JUL": 5.83,
    "AUG": 6.5,
    "SEP": 6.75,
    "OCT": 6.36,
    "NOV": 6.25,
    "DEC": 5.75
  },
  {
    "LONG": -40.61,
    "LAT": -7.09,
    "CIDADE": "Fronteiras",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.3,
    "FEB": 5.4,
    "MAR": 5.58,
    "APR": 5.43,
    "MAY": 5.42,
    "JUN": 5.45,
    "JUL": 5.81,
    "AUG": 6.51,
    "SEP": 6.74,
    "OCT": 6.39,
    "NOV": 6.19,
    "DEC": 5.71
  },
  {
    "LONG": -44.2,
    "LAT": -0.7,
    "CIDADE": "Porto Alegre do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.55,
    "JAN": 4.79,
    "FEB": 4.94,
    "MAR": 5.02,
    "APR": 5.26,
    "MAY": 5.51,
    "JUN": 5.75,
    "JUL": 5.94,
    "AUG": 6.29,
    "SEP": 6.6,
    "OCT": 6.02,
    "NOV": 5.47,
    "DEC": 5.06
  },
  {
    "LONG": -42.67,
    "LAT": -6.97,
    "CIDADE": "Nazaré do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5.07,
    "FEB": 5.23,
    "MAR": 5.31,
    "APR": 5.46,
    "MAY": 5.61,
    "JUN": 5.67,
    "JUL": 5.93,
    "AUG": 6.39,
    "SEP": 6.57,
    "OCT": 6.25,
    "NOV": 5.83,
    "DEC": 5.39
  },
  {
    "LONG": -42.13,
    "LAT": -7.02,
    "CIDADE": "Oeiras",
    "UF": "PIAUÍ",
    "anual": 5.81,
    "JAN": 5.21,
    "FEB": 5.26,
    "MAR": 5.4,
    "APR": 5.52,
    "MAY": 5.63,
    "JUN": 5.63,
    "JUL": 5.98,
    "AUG": 6.38,
    "SEP": 6.69,
    "OCT": 6.37,
    "NOV": 6.02,
    "DEC": 5.58
  },
  {
    "LONG": -41.65,
    "LAT": -6.96,
    "CIDADE": "Dom Expedito Lopes",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.22,
    "FEB": 5.22,
    "MAR": 5.45,
    "APR": 5.5,
    "MAY": 5.52,
    "JUN": 5.54,
    "JUL": 6,
    "AUG": 6.6,
    "SEP": 6.81,
    "OCT": 6.41,
    "NOV": 6.06,
    "DEC": 5.59
  },
  {
    "LONG": -41.41,
    "LAT": -7.03,
    "CIDADE": "Sussuapara",
    "UF": "PIAUÍ",
    "anual": 5.82,
    "JAN": 5.3,
    "FEB": 5.32,
    "MAR": 5.55,
    "APR": 5.48,
    "MAY": 5.4,
    "JUN": 5.43,
    "JUL": 5.85,
    "AUG": 6.6,
    "SEP": 6.79,
    "OCT": 6.36,
    "NOV": 6.08,
    "DEC": 5.66
  },
  {
    "LONG": -41.23,
    "LAT": -6.98,
    "CIDADE": "Santo Antônio de Lisboa",
    "UF": "PIAUÍ",
    "anual": 5.82,
    "JAN": 5.4,
    "FEB": 5.33,
    "MAR": 5.55,
    "APR": 5.43,
    "MAY": 5.39,
    "JUN": 5.42,
    "JUL": 5.84,
    "AUG": 6.47,
    "SEP": 6.7,
    "OCT": 6.4,
    "NOV": 6.19,
    "DEC": 5.71
  },
  {
    "LONG": -41.13,
    "LAT": -6.99,
    "CIDADE": "Francisco Santos",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.43,
    "FEB": 5.35,
    "MAR": 5.63,
    "APR": 5.46,
    "MAY": 5.45,
    "JUN": 5.4,
    "JUL": 5.89,
    "AUG": 6.46,
    "SEP": 6.73,
    "OCT": 6.39,
    "NOV": 6.21,
    "DEC": 5.74
  },
  {
    "LONG": "#VALOR!",
    "LAT": -7,
    "CIDADE": "Monsenhor Hipólito",
    "UF": "PIAUÍ",
    "anual": 5.82,
    "JAN": 5.35,
    "FEB": 5.34,
    "MAR": 5.67,
    "APR": 5.46,
    "MAY": 5.39,
    "JUN": 5.39,
    "JUL": 5.81,
    "AUG": 6.47,
    "SEP": 6.74,
    "OCT": 6.35,
    "NOV": 6.16,
    "DEC": 5.74
  },
  {
    "LONG": -4.09,
    "LAT": -7.01,
    "CIDADE": "Alagoinha do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.3,
    "FEB": 5.29,
    "MAR": 5.63,
    "APR": 5.44,
    "MAY": 5.34,
    "JUN": 5.38,
    "JUL": 5.8,
    "AUG": 6.47,
    "SEP": 6.7,
    "OCT": 6.33,
    "NOV": 6.13,
    "DEC": 5.7
  },
  {
    "LONG": -41.86,
    "LAT": -6.92,
    "CIDADE": "São João da Varjota",
    "UF": "PIAUÍ",
    "anual": 5.74,
    "JAN": 5.01,
    "FEB": 5.08,
    "MAR": 5.21,
    "APR": 5.38,
    "MAY": 5.56,
    "JUN": 5.59,
    "JUL": 5.98,
    "AUG": 6.5,
    "SEP": 6.79,
    "OCT": 6.34,
    "NOV": 5.95,
    "DEC": 5.46
  },
  {
    "LONG": -41.52,
    "LAT": -6.95,
    "CIDADE": "Santana do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.8,
    "JAN": 5.22,
    "FEB": 5.24,
    "MAR": 5.42,
    "APR": 5.5,
    "MAY": 5.48,
    "JUN": 5.49,
    "JUL": 5.94,
    "AUG": 6.57,
    "SEP": 6.78,
    "OCT": 6.37,
    "NOV": 6.05,
    "DEC": 5.6
  },
  {
    "LONG": -41.48,
    "LAT": -6.87,
    "CIDADE": "São José do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.8,
    "JAN": 5.26,
    "FEB": 5.23,
    "MAR": 5.45,
    "APR": 5.49,
    "MAY": 5.41,
    "JUN": 5.46,
    "JUL": 5.85,
    "AUG": 6.57,
    "SEP": 6.79,
    "OCT": 6.37,
    "NOV": 6.07,
    "DEC": 5.59
  },
  {
    "LONG": -41.32,
    "LAT": -6.95,
    "CIDADE": "Bocaina",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.28,
    "FEB": 5.29,
    "MAR": 5.47,
    "APR": 5.45,
    "MAY": 5.39,
    "JUN": 5.43,
    "JUL": 5.86,
    "AUG": 6.5,
    "SEP": 6.75,
    "OCT": 6.32,
    "NOV": 6.1,
    "DEC": 5.62
  },
  {
    "LONG": -43.56,
    "LAT": -6.78,
    "CIDADE": "Guadalupe",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 4.86,
    "FEB": 5.06,
    "MAR": 5.15,
    "APR": 5.33,
    "MAY": 5.43,
    "JUN": 5.72,
    "JUL": 5.98,
    "AUG": 6.36,
    "SEP": 6.61,
    "OCT": 6.15,
    "NOV": 5.64,
    "DEC": 5.17
  },
  {
    "LONG": -43.02,
    "LAT": -6.77,
    "CIDADE": "Floriano",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 5.02,
    "FEB": 5.18,
    "MAR": 5.26,
    "APR": 5.32,
    "MAY": 5.48,
    "JUN": 5.69,
    "JUL": 5.89,
    "AUG": 6.32,
    "SEP": 6.63,
    "OCT": 6.23,
    "NOV": 5.75,
    "DEC": 5.29
  },
  {
    "LONG": -42.39,
    "LAT": -6.8,
    "CIDADE": "Cajazeiras do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.74,
    "JAN": 5.08,
    "FEB": 5.28,
    "MAR": 5.29,
    "APR": 5.41,
    "MAY": 5.62,
    "JUN": 5.6,
    "JUL": 5.94,
    "AUG": 6.37,
    "SEP": 6.57,
    "OCT": 6.31,
    "NOV": 5.99,
    "DEC": 5.46
  },
  {
    "LONG": -42.28,
    "LAT": -6.8,
    "CIDADE": "Santa Rosa do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5.07,
    "FEB": 5.24,
    "MAR": 5.26,
    "APR": 5.4,
    "MAY": 5.6,
    "JUN": 5.64,
    "JUL": 5.97,
    "AUG": 6.33,
    "SEP": 6.57,
    "OCT": 6.27,
    "NOV": 5.97,
    "DEC": 5.42
  },
  {
    "LONG": -41.74,
    "LAT": -6.82,
    "CIDADE": "Ipiranga do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 4.97,
    "FEB": 5.1,
    "MAR": 5.21,
    "APR": 5.33,
    "MAY": 5.5,
    "JUN": 5.47,
    "JUL": 5.95,
    "AUG": 6.47,
    "SEP": 6.74,
    "OCT": 6.28,
    "NOV": 5.91,
    "DEC": 5.43
  },
  {
    "LONG": -41.34,
    "LAT": -6.82,
    "CIDADE": "São João da Canabrava",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.19,
    "FEB": 5.2,
    "MAR": 5.37,
    "APR": 5.39,
    "MAY": 5.44,
    "JUN": 5.48,
    "JUL": 5.88,
    "AUG": 6.53,
    "SEP": 6.79,
    "OCT": 6.34,
    "NOV": 5.98,
    "DEC": 5.5
  },
  {
    "LONG": -41.32,
    "LAT": -6.83,
    "CIDADE": "São Luis do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.19,
    "FEB": 5.2,
    "MAR": 5.37,
    "APR": 5.39,
    "MAY": 5.44,
    "JUN": 5.48,
    "JUL": 5.88,
    "AUG": 6.53,
    "SEP": 6.79,
    "OCT": 6.34,
    "NOV": 5.98,
    "DEC": 5.5
  },
  {
    "LONG": -4.06,
    "LAT": -6.83,
    "CIDADE": "Pio IX",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.18,
    "FEB": 5.22,
    "MAR": 5.38,
    "APR": 5.26,
    "MAY": 5.22,
    "JUN": 5.27,
    "JUL": 5.72,
    "AUG": 6.52,
    "SEP": 6.73,
    "OCT": 6.42,
    "NOV": 6.09,
    "DEC": 5.65
  },
  {
    "LONG": -42.53,
    "LAT": -6.65,
    "CIDADE": "Arraial",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.02,
    "FEB": 5.21,
    "MAR": 5.26,
    "APR": 5.42,
    "MAY": 5.56,
    "JUN": 5.63,
    "JUL": 5.97,
    "AUG": 6.38,
    "SEP": 6.54,
    "OCT": 6.3,
    "NOV": 5.87,
    "DEC": 5.42
  },
  {
    "LONG": -41.71,
    "LAT": -0.67,
    "CIDADE": "Inhuma",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 5,
    "FEB": 5.15,
    "MAR": 5.18,
    "APR": 5.28,
    "MAY": 5.52,
    "JUN": 5.49,
    "JUL": 5.93,
    "AUG": 6.45,
    "SEP": 6.71,
    "OCT": 6.31,
    "NOV": 5.92,
    "DEC": 5.47
  },
  {
    "LONG": -42.69,
    "LAT": -6.63,
    "CIDADE": "Francisco Ayres",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 4.98,
    "FEB": 5.16,
    "MAR": 5.24,
    "APR": 5.34,
    "MAY": 5.55,
    "JUN": 5.7,
    "JUL": 5.95,
    "AUG": 6.38,
    "SEP": 6.56,
    "OCT": 6.27,
    "NOV": 5.84,
    "DEC": 5.39
  },
  {
    "LONG": -42.28,
    "LAT": -6.6,
    "CIDADE": "Tanque do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.93,
    "FEB": 5.04,
    "MAR": 5.11,
    "APR": 5.24,
    "MAY": 5.51,
    "JUN": 5.65,
    "JUL": 6.03,
    "AUG": 6.41,
    "SEP": 6.69,
    "OCT": 6.22,
    "NOV": 5.84,
    "DEC": 5.38
  },
  {
    "LONG": -42.25,
    "LAT": -6.55,
    "CIDADE": "Várzea Grande",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.95,
    "FEB": 5.09,
    "MAR": 5.15,
    "APR": 5.27,
    "MAY": 5.49,
    "JUN": 5.61,
    "JUL": 5.95,
    "AUG": 6.41,
    "SEP": 6.63,
    "OCT": 6.19,
    "NOV": 5.86,
    "DEC": 5.4
  },
  {
    "LONG": -42.11,
    "LAT": -6.52,
    "CIDADE": "Barra D'Alcântara",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.97,
    "FEB": 5.1,
    "MAR": 5.17,
    "APR": 5.27,
    "MAY": 5.46,
    "JUN": 5.57,
    "JUL": 5.93,
    "AUG": 6.4,
    "SEP": 6.64,
    "OCT": 6.18,
    "NOV": 5.87,
    "DEC": 5.41
  },
  {
    "LONG": -41.57,
    "LAT": -0.65,
    "CIDADE": "Lagoa do Sítio",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 4.99,
    "FEB": 5.16,
    "MAR": 5.22,
    "APR": 5.3,
    "MAY": 5.43,
    "JUN": 5.49,
    "JUL": 5.92,
    "AUG": 6.47,
    "SEP": 6.63,
    "OCT": 6.24,
    "NOV": 5.91,
    "DEC": 5.39
  },
  {
    "LONG": -42.27,
    "LAT": -6.4,
    "CIDADE": "Francinópolis",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 4.98,
    "FEB": 5.17,
    "MAR": 5.21,
    "APR": 5.27,
    "MAY": 5.49,
    "JUN": 5.58,
    "JUL": 5.89,
    "AUG": 6.44,
    "SEP": 6.61,
    "OCT": 6.17,
    "NOV": 5.88,
    "DEC": 5.43
  },
  {
    "LONG": -41.93,
    "LAT": -6.45,
    "CIDADE": "Novo Oriente do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.05,
    "FEB": 5.19,
    "MAR": 5.3,
    "APR": 5.36,
    "MAY": 5.5,
    "JUN": 5.59,
    "JUL": 5.9,
    "AUG": 6.4,
    "SEP": 6.61,
    "OCT": 6.23,
    "NOV": 5.96,
    "DEC": 5.49
  },
  {
    "LONG": -41.74,
    "LAT": -6.4,
    "CIDADE": "Valença do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.02,
    "FEB": 5.12,
    "MAR": 5.25,
    "APR": 5.36,
    "MAY": 5.45,
    "JUN": 5.54,
    "JUL": 5.95,
    "AUG": 6.43,
    "SEP": 6.64,
    "OCT": 6.29,
    "NOV": 5.98,
    "DEC": 5.46
  },
  {
    "LONG": -42.85,
    "LAT": -6.24,
    "CIDADE": "Amarante",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 4.86,
    "FEB": 5.1,
    "MAR": 5.2,
    "APR": 5.26,
    "MAY": 5.5,
    "JUN": 5.72,
    "JUL": 5.96,
    "AUG": 6.36,
    "SEP": 6.6,
    "OCT": 6.16,
    "NOV": 5.78,
    "DEC": 5.26
  },
  {
    "LONG": -42.68,
    "LAT": -6.23,
    "CIDADE": "Regeneração",
    "UF": "PIAUÍ",
    "anual": 5.61,
    "JAN": 4.82,
    "FEB": 5.07,
    "MAR": 5.18,
    "APR": 5.19,
    "MAY": 5.43,
    "JUN": 5.6,
    "JUL": 5.93,
    "AUG": 6.48,
    "SEP": 6.59,
    "OCT": 6.16,
    "NOV": 5.7,
    "DEC": 5.22
  },
  {
    "LONG": -42.14,
    "LAT": -6.2,
    "CIDADE": "Elesbão Veloso",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.08,
    "FEB": 5.25,
    "MAR": 5.3,
    "APR": 5.44,
    "MAY": 5.44,
    "JUN": 5.49,
    "JUL": 5.81,
    "AUG": 6.37,
    "SEP": 6.62,
    "OCT": 6.24,
    "NOV": 6,
    "DEC": 5.54
  },
  {
    "LONG": -41.41,
    "LAT": -6.24,
    "CIDADE": "Pimenteiras",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 4.97,
    "FEB": 5.12,
    "MAR": 5.28,
    "APR": 5.39,
    "MAY": 5.39,
    "JUN": 5.48,
    "JUL": 5.78,
    "AUG": 6.39,
    "SEP": 6.67,
    "OCT": 6.32,
    "NOV": 6,
    "DEC": 5.43
  },
  {
    "LONG": -42.74,
    "LAT": -6.09,
    "CIDADE": "Angical do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.86,
    "FEB": 5.09,
    "MAR": 5.2,
    "APR": 5.23,
    "MAY": 5.43,
    "JUN": 5.67,
    "JUL": 5.96,
    "AUG": 6.45,
    "SEP": 6.59,
    "OCT": 6.14,
    "NOV": 5.76,
    "DEC": 5.22
  },
  {
    "LONG": -42.63,
    "LAT": -6.1,
    "CIDADE": "Jardim do Mulato",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 4.82,
    "FEB": 5.13,
    "MAR": 5.23,
    "APR": 5.24,
    "MAY": 5.46,
    "JUN": 5.64,
    "JUL": 5.97,
    "AUG": 6.51,
    "SEP": 6.61,
    "OCT": 6.18,
    "NOV": 5.77,
    "DEC": 5.23
  },
  {
    "LONG": -41.78,
    "LAT": -6.11,
    "CIDADE": "Aroazes",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.98,
    "FEB": 5.08,
    "MAR": 5.14,
    "APR": 5.32,
    "MAY": 5.44,
    "JUN": 5.55,
    "JUL": 5.88,
    "AUG": 6.4,
    "SEP": 6.65,
    "OCT": 6.28,
    "NOV": 5.94,
    "DEC": 5.43
  },
  {
    "LONG": -43.06,
    "LAT": -5.97,
    "CIDADE": "Palmeirais",
    "UF": "PIAUÍ",
    "anual": 5.59,
    "JAN": 4.78,
    "FEB": 5,
    "MAR": 5.05,
    "APR": 5.14,
    "MAY": 5.33,
    "JUN": 5.68,
    "JUL": 5.97,
    "AUG": 6.43,
    "SEP": 6.62,
    "OCT": 6.13,
    "NOV": 5.69,
    "DEC": 5.25
  },
  {
    "LONG": -42.7,
    "LAT": -0.6,
    "CIDADE": "São Gonçalo do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.82,
    "FEB": 5.06,
    "MAR": 5.16,
    "APR": 5.18,
    "MAY": 5.42,
    "JUN": 5.67,
    "JUL": 5.96,
    "AUG": 6.47,
    "SEP": 6.62,
    "OCT": 6.18,
    "NOV": 5.72,
    "DEC": 5.29
  },
  {
    "LONG": -42.71,
    "LAT": -6.05,
    "CIDADE": "Santo Antônio dos Milagres",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.82,
    "FEB": 5.06,
    "MAR": 5.16,
    "APR": 5.18,
    "MAY": 5.42,
    "JUN": 5.67,
    "JUL": 5.96,
    "AUG": 6.47,
    "SEP": 6.62,
    "OCT": 6.18,
    "NOV": 5.72,
    "DEC": 5.29
  },
  {
    "LONG": -42.56,
    "LAT": -0.6,
    "CIDADE": "Hugo Napoleão",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.87,
    "FEB": 5.11,
    "MAR": 5.29,
    "APR": 5.28,
    "MAY": 5.45,
    "JUN": 5.7,
    "JUL": 5.9,
    "AUG": 6.41,
    "SEP": 6.62,
    "OCT": 6.18,
    "NOV": 5.77,
    "DEC": 5.35
  },
  {
    "LONG": -42.72,
    "LAT": -5.92,
    "CIDADE": "São Pedro do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.64,
    "JAN": 4.76,
    "FEB": 5.07,
    "MAR": 5.17,
    "APR": 5.19,
    "MAY": 5.44,
    "JUN": 5.7,
    "JUL": 5.96,
    "AUG": 6.46,
    "SEP": 6.63,
    "OCT": 6.22,
    "NOV": 5.72,
    "DEC": 5.3
  },
  {
    "LONG": -42.63,
    "LAT": -5.89,
    "CIDADE": "Água Branca",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.85,
    "FEB": 5.15,
    "MAR": 5.25,
    "APR": 5.23,
    "MAY": 5.45,
    "JUN": 5.72,
    "JUL": 5.96,
    "AUG": 6.4,
    "SEP": 6.61,
    "OCT": 6.25,
    "NOV": 5.78,
    "DEC": 5.37
  },
  {
    "LONG": -42.44,
    "LAT": -5.86,
    "CIDADE": "Passagem Franca do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 4.89,
    "FEB": 5.15,
    "MAR": 5.3,
    "APR": 5.3,
    "MAY": 5.47,
    "JUN": 5.69,
    "JUL": 5.92,
    "AUG": 6.45,
    "SEP": 6.68,
    "OCT": 6.29,
    "NOV": 5.91,
    "DEC": 5.45
  },
  {
    "LONG": -42.19,
    "LAT": -5.86,
    "CIDADE": "São Miguel da Baixa Grande",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.04,
    "FEB": 5.22,
    "MAR": 5.35,
    "APR": 5.37,
    "MAY": 5.5,
    "JUN": 5.57,
    "JUL": 5.84,
    "AUG": 6.38,
    "SEP": 6.59,
    "OCT": 6.26,
    "NOV": 6,
    "DEC": 5.48
  },
  {
    "LONG": -42.11,
    "LAT": -5.94,
    "CIDADE": "São Félix do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.04,
    "FEB": 5.22,
    "MAR": 5.35,
    "APR": 5.37,
    "MAY": 5.5,
    "JUN": 5.57,
    "JUL": 5.84,
    "AUG": 6.38,
    "SEP": 6.59,
    "OCT": 6.26,
    "NOV": 6,
    "DEC": 5.48
  },
  {
    "LONG": -41.04,
    "LAT": -5.87,
    "CIDADE": "Assunção do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.15,
    "FEB": 5.16,
    "MAR": 5.39,
    "APR": 5.22,
    "MAY": 5.32,
    "JUN": 5.44,
    "JUL": 5.8,
    "AUG": 6.49,
    "SEP": 6.75,
    "OCT": 6.51,
    "NOV": 6.28,
    "DEC": 5.65
  },
  {
    "LONG": -42.63,
    "LAT": -5.83,
    "CIDADE": "Lagoinha do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.78,
    "FEB": 5.13,
    "MAR": 5.21,
    "APR": 5.22,
    "MAY": 5.44,
    "JUN": 5.73,
    "JUL": 5.97,
    "AUG": 6.44,
    "SEP": 6.64,
    "OCT": 6.25,
    "NOV": 5.78,
    "DEC": 5.35
  },
  {
    "LONG": -42.66,
    "LAT": -0.58,
    "CIDADE": "Agricolândia",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.78,
    "FEB": 5.13,
    "MAR": 5.21,
    "APR": 5.22,
    "MAY": 5.44,
    "JUN": 5.73,
    "JUL": 5.97,
    "AUG": 6.44,
    "SEP": 6.64,
    "OCT": 6.25,
    "NOV": 5.78,
    "DEC": 5.35
  },
  {
    "LONG": -4.25,
    "LAT": -5.82,
    "CIDADE": "Barro Duro",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.79,
    "FEB": 5.1,
    "MAR": 5.19,
    "APR": 5.22,
    "MAY": 5.44,
    "JUN": 5.7,
    "JUL": 5.95,
    "AUG": 6.44,
    "SEP": 6.67,
    "OCT": 6.28,
    "NOV": 5.86,
    "DEC": 5.35
  },
  {
    "LONG": -42.57,
    "LAT": -0.58,
    "CIDADE": "Olho d'Água do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.79,
    "FEB": 5.1,
    "MAR": 5.19,
    "APR": 5.22,
    "MAY": 5.44,
    "JUN": 5.7,
    "JUL": 5.95,
    "AUG": 6.44,
    "SEP": 6.67,
    "OCT": 6.28,
    "NOV": 5.86,
    "DEC": 5.35
  },
  {
    "LONG": -4.2,
    "LAT": -5.81,
    "CIDADE": "Santa Cruz dos Milagres",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5,
    "FEB": 5.28,
    "MAR": 5.36,
    "APR": 5.41,
    "MAY": 5.5,
    "JUN": 5.56,
    "JUL": 5.82,
    "AUG": 6.44,
    "SEP": 6.62,
    "OCT": 6.29,
    "NOV": 6.01,
    "DEC": 5.48
  },
  {
    "LONG": -42.74,
    "LAT": -5.68,
    "CIDADE": "Miguel Leão",
    "UF": "PIAUÍ",
    "anual": 5.59,
    "JAN": 4.71,
    "FEB": 5.02,
    "MAR": 5.14,
    "APR": 5.11,
    "MAY": 5.37,
    "JUN": 5.66,
    "JUL": 5.93,
    "AUG": 6.39,
    "SEP": 6.57,
    "OCT": 6.19,
    "NOV": 5.71,
    "DEC": 5.31
  },
  {
    "LONG": -4.22,
    "LAT": -5.67,
    "CIDADE": "Prata do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5.1,
    "FEB": 5.22,
    "MAR": 5.33,
    "APR": 5.38,
    "MAY": 5.48,
    "JUN": 5.59,
    "JUL": 5.86,
    "AUG": 6.33,
    "SEP": 6.58,
    "OCT": 6.32,
    "NOV": 6.05,
    "DEC": 5.55
  },
  {
    "LONG": -42.83,
    "LAT": -5.62,
    "CIDADE": "Curralinhos",
    "UF": "PIAUÍ",
    "anual": 5.59,
    "JAN": 4.75,
    "FEB": 5,
    "MAR": 5.14,
    "APR": 5.15,
    "MAY": 5.33,
    "JUN": 5.68,
    "JUL": 5.85,
    "AUG": 6.43,
    "SEP": 6.58,
    "OCT": 6.19,
    "NOV": 5.76,
    "DEC": 5.29
  },
  {
    "LONG": -42.61,
    "LAT": -5.56,
    "CIDADE": "Monsenhor Gil",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.85,
    "FEB": 5.08,
    "MAR": 5.2,
    "APR": 5.16,
    "MAY": 5.38,
    "JUN": 5.67,
    "JUL": 5.8,
    "AUG": 6.45,
    "SEP": 6.62,
    "OCT": 6.3,
    "NOV": 5.92,
    "DEC": 5.47
  },
  {
    "LONG": -42.36,
    "LAT": -5.46,
    "CIDADE": "Beneditinos",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.04,
    "FEB": 5.22,
    "MAR": 5.29,
    "APR": 5.31,
    "MAY": 5.44,
    "JUN": 5.65,
    "JUL": 5.89,
    "AUG": 6.32,
    "SEP": 6.59,
    "OCT": 6.34,
    "NOV": 6.04,
    "DEC": 5.57
  },
  {
    "LONG": -41.89,
    "LAT": -5.51,
    "CIDADE": "São João da Serra",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 4.99,
    "FEB": 5.23,
    "MAR": 5.32,
    "APR": 5.3,
    "MAY": 5.41,
    "JUN": 5.49,
    "JUL": 5.77,
    "AUG": 6.37,
    "SEP": 6.6,
    "OCT": 6.27,
    "NOV": 6.06,
    "DEC": 5.49
  },
  {
    "LONG": -41.32,
    "LAT": -5.5,
    "CIDADE": "São Miguel do Tapuio",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 5,
    "FEB": 5.21,
    "MAR": 5.38,
    "APR": 5.22,
    "MAY": 5.28,
    "JUN": 5.36,
    "JUL": 5.67,
    "AUG": 6.39,
    "SEP": 6.7,
    "OCT": 6.44,
    "NOV": 6.16,
    "DEC": 5.54
  },
  {
    "LONG": -42.68,
    "LAT": -5.36,
    "CIDADE": "Demerval Lobão",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.91,
    "FEB": 5.05,
    "MAR": 5.25,
    "APR": 5.21,
    "MAY": 5.33,
    "JUN": 5.6,
    "JUL": 5.86,
    "AUG": 6.37,
    "SEP": 6.64,
    "OCT": 6.3,
    "NOV": 5.93,
    "DEC": 5.49
  },
  {
    "LONG": -4.26,
    "LAT": -5.41,
    "CIDADE": "Lagoa do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.91,
    "FEB": 5.05,
    "MAR": 5.25,
    "APR": 5.21,
    "MAY": 5.33,
    "JUN": 5.6,
    "JUL": 5.86,
    "AUG": 6.37,
    "SEP": 6.64,
    "OCT": 6.3,
    "NOV": 5.93,
    "DEC": 5.49
  },
  {
    "LONG": -42.81,
    "LAT": -5.35,
    "CIDADE": "Nazária",
    "UF": "PIAUÍ",
    "anual": 5.64,
    "JAN": 4.89,
    "FEB": 5.15,
    "MAR": 5.22,
    "APR": 5.18,
    "MAY": 5.27,
    "JUN": 5.55,
    "JUL": 5.86,
    "AUG": 6.36,
    "SEP": 6.6,
    "OCT": 6.26,
    "NOV": 5.9,
    "DEC": 5.48
  },
  {
    "LONG": -4.24,
    "LAT": -5.25,
    "CIDADE": "Pau D'Arco do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.04,
    "FEB": 5.18,
    "MAR": 5.33,
    "APR": 5.22,
    "MAY": 5.34,
    "JUN": 5.56,
    "JUL": 5.83,
    "AUG": 6.44,
    "SEP": 6.61,
    "OCT": 6.35,
    "NOV": 6.06,
    "DEC": 5.59
  },
  {
    "LONG": -42.21,
    "LAT": -5.26,
    "CIDADE": "Alto Longá",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.03,
    "FEB": 5.2,
    "MAR": 5.35,
    "APR": 5.24,
    "MAY": 5.35,
    "JUN": 5.56,
    "JUL": 5.85,
    "AUG": 6.38,
    "SEP": 6.6,
    "OCT": 6.36,
    "NOV": 6.04,
    "DEC": 5.57
  },
  {
    "LONG": -41.93,
    "LAT": -5.29,
    "CIDADE": "Novo Santo Antônio",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 4.99,
    "FEB": 5.25,
    "MAR": 5.44,
    "APR": 5.31,
    "MAY": 5.36,
    "JUN": 5.47,
    "JUL": 5.78,
    "AUG": 6.32,
    "SEP": 6.58,
    "OCT": 6.31,
    "NOV": 6.07,
    "DEC": 5.55
  },
  {
    "LONG": -41.55,
    "LAT": -5.32,
    "CIDADE": "Castelo do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.96,
    "FEB": 5.16,
    "MAR": 5.32,
    "APR": 5.2,
    "MAY": 5.32,
    "JUN": 5.39,
    "JUL": 5.7,
    "AUG": 6.38,
    "SEP": 6.65,
    "OCT": 6.37,
    "NOV": 6.11,
    "DEC": 5.51
  },
  {
    "LONG": -41.09,
    "LAT": -5.31,
    "CIDADE": "Buriti dos Montes",
    "UF": "PIAUÍ",
    "anual": 5.77,
    "JAN": 5.07,
    "FEB": 5.19,
    "MAR": 5.43,
    "APR": 5.11,
    "MAY": 5.24,
    "JUN": 5.47,
    "JUL": 5.87,
    "AUG": 6.52,
    "SEP": 6.78,
    "OCT": 6.58,
    "NOV": 6.33,
    "DEC": 5.68
  },
  {
    "LONG": -41.7,
    "LAT": -0.52,
    "CIDADE": "Juazeiro do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 5.01,
    "FEB": 5.24,
    "MAR": 5.39,
    "APR": 5.25,
    "MAY": 5.36,
    "JUN": 5.4,
    "JUL": 5.66,
    "AUG": 6.32,
    "SEP": 6.59,
    "OCT": 6.33,
    "NOV": 6.09,
    "DEC": 5.58
  },
  {
    "LONG": -4.28,
    "LAT": -5.09,
    "CIDADE": "Teresina",
    "UF": "PIAUÍ",
    "anual": 5.58,
    "JAN": 4.94,
    "FEB": 5.12,
    "MAR": 5.14,
    "APR": 5.13,
    "MAY": 5.23,
    "JUN": 5.41,
    "JUL": 5.69,
    "AUG": 6.19,
    "SEP": 6.49,
    "OCT": 6.25,
    "NOV": 5.97,
    "DEC": 5.43
  },
  {
    "LONG": -42.21,
    "LAT": -5.09,
    "CIDADE": "Coivaras",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 4.98,
    "FEB": 5.18,
    "MAR": 5.4,
    "APR": 5.23,
    "MAY": 5.31,
    "JUN": 5.54,
    "JUL": 5.79,
    "AUG": 6.39,
    "SEP": 6.54,
    "OCT": 6.33,
    "NOV": 6.06,
    "DEC": 5.51
  },
  {
    "LONG": -42.46,
    "LAT": -5.04,
    "CIDADE": "Altos",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.98,
    "FEB": 5.15,
    "MAR": 5.32,
    "APR": 5.17,
    "MAY": 5.28,
    "JUN": 5.51,
    "JUL": 5.8,
    "AUG": 6.35,
    "SEP": 6.57,
    "OCT": 6.33,
    "NOV": 5.99,
    "DEC": 5.46
  },
  {
    "LONG": -41.73,
    "LAT": -0.49,
    "CIDADE": "Sigefredo Pacheco",
    "UF": "PIAUÍ",
    "anual": 5.75,
    "JAN": 5.1,
    "FEB": 5.3,
    "MAR": 5.53,
    "APR": 5.32,
    "MAY": 5.45,
    "JUN": 5.47,
    "JUL": 5.75,
    "AUG": 6.33,
    "SEP": 6.64,
    "OCT": 6.42,
    "NOV": 6.18,
    "DEC": 5.56
  },
  {
    "LONG": -42.57,
    "LAT": -4.75,
    "CIDADE": "José de Freitas",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 5.01,
    "FEB": 5.19,
    "MAR": 5.3,
    "APR": 5.22,
    "MAY": 5.25,
    "JUN": 5.49,
    "JUL": 5.76,
    "AUG": 6.29,
    "SEP": 6.52,
    "OCT": 6.3,
    "NOV": 5.99,
    "DEC": 5.47
  },
  {
    "LONG": -42.16,
    "LAT": -4.83,
    "CIDADE": "Campo Maior",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 5.15,
    "FEB": 5.33,
    "MAR": 5.5,
    "APR": 5.38,
    "MAY": 5.39,
    "JUN": 5.49,
    "JUL": 5.66,
    "AUG": 6.19,
    "SEP": 6.48,
    "OCT": 6.34,
    "NOV": 6.04,
    "DEC": 5.5
  },
  {
    "LONG": -41.82,
    "LAT": -4.77,
    "CIDADE": "Jatobá do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 5.07,
    "FEB": 5.25,
    "MAR": 5.41,
    "APR": 5.2,
    "MAY": 5.33,
    "JUN": 5.4,
    "JUL": 5.66,
    "AUG": 6.24,
    "SEP": 6.56,
    "OCT": 6.4,
    "NOV": 6.11,
    "DEC": 5.49
  },
  {
    "LONG": -41.42,
    "LAT": -4.68,
    "CIDADE": "Milton Brandão",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.03,
    "FEB": 5.25,
    "MAR": 5.49,
    "APR": 5.21,
    "MAY": 5.3,
    "JUN": 5.37,
    "JUL": 5.66,
    "AUG": 6.34,
    "SEP": 6.72,
    "OCT": 6.48,
    "NOV": 6.2,
    "DEC": 5.53
  },
  {
    "LONG": -42.86,
    "LAT": -4.59,
    "CIDADE": "União",
    "UF": "PIAUÍ",
    "anual": 5.6,
    "JAN": 5.02,
    "FEB": 5.15,
    "MAR": 5.24,
    "APR": 5.22,
    "MAY": 5.21,
    "JUN": 5.4,
    "JUL": 5.67,
    "AUG": 6.25,
    "SEP": 6.46,
    "OCT": 6.24,
    "NOV": 5.91,
    "DEC": 5.46
  },
  {
    "LONG": -42.17,
    "LAT": -4.63,
    "CIDADE": "Nossa Senhora de Nazaré",
    "UF": "PIAUÍ",
    "anual": 5.64,
    "JAN": 5.07,
    "FEB": 5.18,
    "MAR": 5.29,
    "APR": 5.18,
    "MAY": 5.28,
    "JUN": 5.55,
    "JUL": 5.74,
    "AUG": 6.19,
    "SEP": 6.45,
    "OCT": 6.28,
    "NOV": 6.03,
    "DEC": 5.51
  },
  {
    "LONG": -41.97,
    "LAT": -4.55,
    "CIDADE": "Cocal de Telha",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 5.01,
    "FEB": 5.23,
    "MAR": 5.28,
    "APR": 5.13,
    "MAY": 5.28,
    "JUN": 5.51,
    "JUL": 5.78,
    "AUG": 6.34,
    "SEP": 6.59,
    "OCT": 6.38,
    "NOV": 6.1,
    "DEC": 5.5
  },
  {
    "LONG": -42.62,
    "LAT": -4.51,
    "CIDADE": "Lagoa Alegre",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.99,
    "FEB": 5.11,
    "MAR": 5.28,
    "APR": 5.19,
    "MAY": 5.24,
    "JUN": 5.49,
    "JUL": 5.76,
    "AUG": 6.22,
    "SEP": 6.54,
    "OCT": 6.35,
    "NOV": 5.97,
    "DEC": 5.45
  },
  {
    "LONG": -42.31,
    "LAT": -4.47,
    "CIDADE": "Cabeceiras do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.98,
    "FEB": 5.25,
    "MAR": 5.34,
    "APR": 5.19,
    "MAY": 5.3,
    "JUN": 5.5,
    "JUL": 5.74,
    "AUG": 6.28,
    "SEP": 6.48,
    "OCT": 6.29,
    "NOV": 6.03,
    "DEC": 5.48
  },
  {
    "LONG": -42.1,
    "LAT": -4.49,
    "CIDADE": "Boqueirão do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 5.07,
    "FEB": 5.22,
    "MAR": 5.3,
    "APR": 5.13,
    "MAY": 5.32,
    "JUN": 5.53,
    "JUL": 5.76,
    "AUG": 6.28,
    "SEP": 6.51,
    "OCT": 6.32,
    "NOV": 6.09,
    "DEC": 5.52
  },
  {
    "LONG": -41.94,
    "LAT": -4.46,
    "CIDADE": "Capitão de Campos",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 5.04,
    "FEB": 5.24,
    "MAR": 5.32,
    "APR": 5.12,
    "MAY": 5.32,
    "JUN": 5.51,
    "JUL": 5.79,
    "AUG": 6.32,
    "SEP": 6.6,
    "OCT": 6.35,
    "NOV": 6.11,
    "DEC": 5.52
  },
  {
    "LONG": -42.12,
    "LAT": -4.41,
    "CIDADE": "Boa Hora",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 5.11,
    "FEB": 5.24,
    "MAR": 5.28,
    "APR": 5.11,
    "MAY": 5.28,
    "JUN": 5.5,
    "JUL": 5.76,
    "AUG": 6.25,
    "SEP": 6.5,
    "OCT": 6.28,
    "NOV": 6.1,
    "DEC": 5.55
  },
  {
    "LONG": -41.6,
    "LAT": -4.39,
    "CIDADE": "Lagoa de São Francisco",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.97,
    "FEB": 5.19,
    "MAR": 5.24,
    "APR": 5.02,
    "MAY": 5.26,
    "JUN": 5.43,
    "JUL": 5.65,
    "AUG": 6.27,
    "SEP": 6.59,
    "OCT": 6.37,
    "NOV": 6.08,
    "DEC": 5.45
  },
  {
    "LONG": -4.15,
    "LAT": -4.43,
    "CIDADE": "Pedro II",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 5.07,
    "FEB": 5.21,
    "MAR": 5.32,
    "APR": 5.07,
    "MAY": 5.28,
    "JUN": 5.4,
    "JUL": 5.71,
    "AUG": 6.32,
    "SEP": 6.68,
    "OCT": 6.45,
    "NOV": 6.24,
    "DEC": 5.57
  },
  {
    "LONG": -41.78,
    "LAT": -4.27,
    "CIDADE": "Piripiri",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 5.06,
    "FEB": 5.26,
    "MAR": 5.36,
    "APR": 5.14,
    "MAY": 5.38,
    "JUN": 5.45,
    "JUL": 5.68,
    "AUG": 6.26,
    "SEP": 6.54,
    "OCT": 6.34,
    "NOV": 6.09,
    "DEC": 5.51
  },
  {
    "LONG": -41.27,
    "LAT": -4.25,
    "CIDADE": "Domingos Mourão",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.97,
    "FEB": 5.18,
    "MAR": 5.31,
    "APR": 5.11,
    "MAY": 5.23,
    "JUN": 5.31,
    "JUL": 5.58,
    "AUG": 6.18,
    "SEP": 6.57,
    "OCT": 6.45,
    "NOV": 6.23,
    "DEC": 5.48
  },
  {
    "LONG": -42.9,
    "LAT": -0.42,
    "CIDADE": "Miguel Alves",
    "UF": "PIAUÍ",
    "anual": 5.58,
    "JAN": 4.93,
    "FEB": 5.19,
    "MAR": 5.23,
    "APR": 5.15,
    "MAY": 5.22,
    "JUN": 5.45,
    "JUL": 5.64,
    "AUG": 6.12,
    "SEP": 6.42,
    "OCT": 6.27,
    "NOV": 5.89,
    "DEC": 5.4
  },
  {
    "LONG": -42.29,
    "LAT": -4.25,
    "CIDADE": "Barras",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 5.17,
    "FEB": 5.28,
    "MAR": 5.23,
    "APR": 5.05,
    "MAY": 5.21,
    "JUN": 5.45,
    "JUL": 5.77,
    "AUG": 6.35,
    "SEP": 6.5,
    "OCT": 6.35,
    "NOV": 6.15,
    "DEC": 5.59
  },
  {
    "LONG": -41.79,
    "LAT": -4.13,
    "CIDADE": "Brasileira",
    "UF": "PIAUÍ",
    "anual": 5.75,
    "JAN": 5.2,
    "FEB": 5.37,
    "MAR": 5.39,
    "APR": 5.23,
    "MAY": 5.39,
    "JUN": 5.53,
    "JUL": 5.8,
    "AUG": 6.28,
    "SEP": 6.56,
    "OCT": 6.45,
    "NOV": 6.21,
    "DEC": 5.56
  },
  {
    "LONG": -42.62,
    "LAT": -3.98,
    "CIDADE": "Nossa Senhora dos Remédios",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 5.14,
    "FEB": 5.24,
    "MAR": 5.31,
    "APR": 5.1,
    "MAY": 5.2,
    "JUN": 5.46,
    "JUL": 5.78,
    "AUG": 6.2,
    "SEP": 6.43,
    "OCT": 6.37,
    "NOV": 6.01,
    "DEC": 5.52
  },
  {
    "LONG": -4.21,
    "LAT": -4.02,
    "CIDADE": "Batalha",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.17,
    "FEB": 5.38,
    "MAR": 5.38,
    "APR": 5.26,
    "MAY": 5.36,
    "JUN": 5.5,
    "JUL": 5.76,
    "AUG": 6.21,
    "SEP": 6.47,
    "OCT": 6.4,
    "NOV": 6.13,
    "DEC": 5.53
  },
  {
    "LONG": -42.71,
    "LAT": -3.89,
    "CIDADE": "Porto",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 5.13,
    "FEB": 5.32,
    "MAR": 5.36,
    "APR": 5.26,
    "MAY": 5.3,
    "JUN": 5.49,
    "JUL": 5.72,
    "AUG": 6.19,
    "SEP": 6.4,
    "OCT": 6.25,
    "NOV": 5.99,
    "DEC": 5.49
  },
  {
    "LONG": -42.23,
    "LAT": -0.39,
    "CIDADE": "Esperantina",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.16,
    "FEB": 5.41,
    "MAR": 5.39,
    "APR": 5.21,
    "MAY": 5.33,
    "JUN": 5.51,
    "JUL": 5.7,
    "AUG": 6.27,
    "SEP": 6.52,
    "OCT": 6.4,
    "NOV": 6.16,
    "DEC": 5.54
  },
  {
    "LONG": -41.71,
    "LAT": -3.93,
    "CIDADE": "Piracuruca",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.15,
    "FEB": 5.32,
    "MAR": 5.3,
    "APR": 5.1,
    "MAY": 5.38,
    "JUN": 5.6,
    "JUL": 5.8,
    "AUG": 6.18,
    "SEP": 6.46,
    "OCT": 6.48,
    "NOV": 6.22,
    "DEC": 5.54
  },
  {
    "LONG": -41.26,
    "LAT": -3.94,
    "CIDADE": "São João da Fronteira",
    "UF": "PIAUÍ",
    "anual": 5.61,
    "JAN": 5.12,
    "FEB": 5.19,
    "MAR": 5.15,
    "APR": 4.89,
    "MAY": 5.16,
    "JUN": 5.27,
    "JUL": 5.51,
    "AUG": 6.12,
    "SEP": 6.62,
    "OCT": 6.5,
    "NOV": 6.28,
    "DEC": 5.56
  },
  {
    "LONG": -42.63,
    "LAT": -0.38,
    "CIDADE": "Campo Largo do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 5.1,
    "FEB": 5.27,
    "MAR": 5.31,
    "APR": 5.19,
    "MAY": 5.28,
    "JUN": 5.46,
    "JUL": 5.67,
    "AUG": 6.13,
    "SEP": 6.39,
    "OCT": 6.24,
    "NOV": 5.99,
    "DEC": 5.43
  },
  {
    "LONG": -42.45,
    "LAT": -3.82,
    "CIDADE": "São João do Arraial",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 5.16,
    "FEB": 5.38,
    "MAR": 5.35,
    "APR": 5.18,
    "MAY": 5.31,
    "JUN": 5.53,
    "JUL": 5.74,
    "AUG": 6.29,
    "SEP": 6.55,
    "OCT": 6.39,
    "NOV": 6.07,
    "DEC": 5.47
  },
  {
    "LONG": -41.83,
    "LAT": -0.38,
    "CIDADE": "São José do Divino",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 5.08,
    "FEB": 5.29,
    "MAR": 5.25,
    "APR": 5.12,
    "MAY": 5.37,
    "JUN": 5.56,
    "JUL": 5.73,
    "AUG": 6.17,
    "SEP": 6.46,
    "OCT": 6.43,
    "NOV": 6.16,
    "DEC": 5.46
  },
  {
    "LONG": -4.26,
    "LAT": -3.71,
    "CIDADE": "Matias Olímpio",
    "UF": "PIAUÍ",
    "anual": 5.64,
    "JAN": 5.07,
    "FEB": 5.29,
    "MAR": 5.37,
    "APR": 5.21,
    "MAY": 5.24,
    "JUN": 5.49,
    "JUL": 5.66,
    "AUG": 6.23,
    "SEP": 6.46,
    "OCT": 6.28,
    "NOV": 5.95,
    "DEC": 5.37
  },
  {
    "LONG": -42.31,
    "LAT": -3.74,
    "CIDADE": "Morro do Chapéu do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 5.13,
    "FEB": 5.34,
    "MAR": 5.36,
    "APR": 5.21,
    "MAY": 5.27,
    "JUN": 5.54,
    "JUL": 5.68,
    "AUG": 6.22,
    "SEP": 6.47,
    "OCT": 6.34,
    "NOV": 5.98,
    "DEC": 5.4
  },
  {
    "LONG": -41.44,
    "LAT": -3.62,
    "CIDADE": "Cocal dos Alves",
    "UF": "PIAUÍ",
    "anual": 5.54,
    "JAN": 5.02,
    "FEB": 5.09,
    "MAR": 5,
    "APR": 4.82,
    "MAY": 5.15,
    "JUN": 5.31,
    "JUL": 5.51,
    "AUG": 6.04,
    "SEP": 6.47,
    "OCT": 6.43,
    "NOV": 6.24,
    "DEC": 5.45
  },
  {
    "LONG": -42.5,
    "LAT": -3.48,
    "CIDADE": "Madeiro",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 5.15,
    "FEB": 5.23,
    "MAR": 5.39,
    "APR": 5.21,
    "MAY": 5.27,
    "JUN": 5.46,
    "JUL": 5.68,
    "AUG": 6.1,
    "SEP": 6.33,
    "OCT": 6.26,
    "NOV": 5.95,
    "DEC": 5.42
  },
  {
    "LONG": -4.24,
    "LAT": -3.5,
    "CIDADE": "Joca Marques",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 5.16,
    "FEB": 5.27,
    "MAR": 5.37,
    "APR": 5.17,
    "MAY": 5.3,
    "JUN": 5.46,
    "JUL": 5.68,
    "AUG": 6.13,
    "SEP": 6.38,
    "OCT": 6.28,
    "NOV": 5.96,
    "DEC": 5.39
  },
  {
    "LONG": -42.37,
    "LAT": -3.46,
    "CIDADE": "Luzilândia",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 5.12,
    "FEB": 5.33,
    "MAR": 5.3,
    "APR": 5.16,
    "MAY": 5.33,
    "JUN": 5.44,
    "JUL": 5.67,
    "AUG": 6.09,
    "SEP": 6.32,
    "OCT": 6.26,
    "NOV": 5.96,
    "DEC": 5.4
  },
  {
    "LONG": -42.2,
    "LAT": -3.51,
    "CIDADE": "Joaquim Pires",
    "UF": "PIAUÍ",
    "anual": 5.59,
    "JAN": 5.07,
    "FEB": 5.25,
    "MAR": 5.17,
    "APR": 5.05,
    "MAY": 5.3,
    "JUN": 5.51,
    "JUL": 5.65,
    "AUG": 6.05,
    "SEP": 6.36,
    "OCT": 6.31,
    "NOV": 5.95,
    "DEC": 5.35
  },
  {
    "LONG": -41.84,
    "LAT": -3.48,
    "CIDADE": "Caraúbas do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.58,
    "JAN": 5,
    "FEB": 5.18,
    "MAR": 5.16,
    "APR": 4.96,
    "MAY": 5.28,
    "JUN": 5.51,
    "JUL": 5.65,
    "AUG": 6.03,
    "SEP": 6.42,
    "OCT": 6.36,
    "NOV": 6.06,
    "DEC": 5.35
  },
  {
    "LONG": -4.16,
    "LAT": -3.47,
    "CIDADE": "Cocal",
    "UF": "PIAUÍ",
    "anual": 5.57,
    "JAN": 5.02,
    "FEB": 5.16,
    "MAR": 5.1,
    "APR": 4.87,
    "MAY": 5.16,
    "JUN": 5.37,
    "JUL": 5.59,
    "AUG": 6.14,
    "SEP": 6.47,
    "OCT": 6.42,
    "NOV": 6.15,
    "DEC": 5.43
  },
  {
    "LONG": -41.89,
    "LAT": -3.42,
    "CIDADE": "Caxingó",
    "UF": "PIAUÍ",
    "anual": 5.57,
    "JAN": 5.02,
    "FEB": 5.16,
    "MAR": 5.1,
    "APR": 4.88,
    "MAY": 5.21,
    "JUN": 5.49,
    "JUL": 5.69,
    "AUG": 6.1,
    "SEP": 6.44,
    "OCT": 6.38,
    "NOV": 6.08,
    "DEC": 5.33
  },
  {
    "LONG": -42.09,
    "LAT": -3.32,
    "CIDADE": "Murici dos Portelas",
    "UF": "PIAUÍ",
    "anual": 5.61,
    "JAN": 5.09,
    "FEB": 5.15,
    "MAR": 5.2,
    "APR": 5.06,
    "MAY": 5.22,
    "JUN": 5.53,
    "JUL": 5.71,
    "AUG": 6.13,
    "SEP": 6.35,
    "OCT": 6.32,
    "NOV": 6.09,
    "DEC": 5.49
  },
  {
    "LONG": -41.87,
    "LAT": -3.18,
    "CIDADE": "Buriti dos Lopes",
    "UF": "PIAUÍ",
    "anual": 5.55,
    "JAN": 5.05,
    "FEB": 5.09,
    "MAR": 5.05,
    "APR": 4.93,
    "MAY": 5.11,
    "JUN": 5.41,
    "JUL": 5.53,
    "AUG": 6.06,
    "SEP": 6.38,
    "OCT": 6.38,
    "NOV": 6.14,
    "DEC": 5.46
  },
  {
    "LONG": -41.64,
    "LAT": -3.19,
    "CIDADE": "Bom Princípio do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 5.16,
    "FEB": 5.17,
    "MAR": 5.15,
    "APR": 4.96,
    "MAY": 5.26,
    "JUN": 5.46,
    "JUL": 5.58,
    "AUG": 6.14,
    "SEP": 6.51,
    "OCT": 6.53,
    "NOV": 6.28,
    "DEC": 5.55
  },
  {
    "LONG": -41.82,
    "LAT": -2.86,
    "CIDADE": "Ilha Grande",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 5.49,
    "FEB": 5.39,
    "MAR": 5.32,
    "APR": 5.05,
    "MAY": 5.3,
    "JUN": 5.47,
    "JUL": 5.63,
    "AUG": 6.18,
    "SEP": 6.1,
    "OCT": 5.99,
    "NOV": 6.25,
    "DEC": 5.93
  },
  {
    "LONG": -41.78,
    "LAT": -2.91,
    "CIDADE": "Parnaíba",
    "UF": "PIAUÍ",
    "anual": 5.58,
    "JAN": 5.47,
    "FEB": 5.46,
    "MAR": 5.36,
    "APR": 5.09,
    "MAY": 5.26,
    "JUN": 5.42,
    "JUL": 5.58,
    "AUG": 5.92,
    "SEP": 5.73,
    "OCT": 5.71,
    "NOV": 6.07,
    "DEC": 5.85
  },
  {
    "LONG": -41.66,
    "LAT": -2.88,
    "CIDADE": "Luís Correia",
    "UF": "PIAUÍ",
    "anual": 5.48,
    "JAN": 5.48,
    "FEB": 5.44,
    "MAR": 5.32,
    "APR": 5.05,
    "MAY": 5.26,
    "JUN": 5.41,
    "JUL": 5.5,
    "AUG": 5.73,
    "SEP": 5.44,
    "OCT": 5.54,
    "NOV": 5.87,
    "DEC": 5.76
  },
  {
    "LONG": -41.34,
    "LAT": -2.93,
    "CIDADE": "Cajueiro da Praia",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 5.54,
    "FEB": 5.4,
    "MAR": 5.22,
    "APR": 5.01,
    "MAY": 5.3,
    "JUN": 5.48,
    "JUL": 5.69,
    "AUG": 6.02,
    "SEP": 5.89,
    "OCT": 5.88,
    "NOV": 6.21,
    "DEC": 5.98
  },
  {
    "LONG": -4.47,
    "LAT": -23.22,
    "CIDADE": "Paraty",
    "UF": "RIO DE JANEIRO",
    "anual": 4.33,
    "JAN": 4.53,
    "FEB": 5.08,
    "MAR": 4.47,
    "APR": 4.47,
    "MAY": 4.1,
    "JUN": 4.04,
    "JUL": 3.95,
    "AUG": 4.61,
    "SEP": 4.18,
    "OCT": 4.09,
    "NOV": 4,
    "DEC": 4.45
  },
  {
    "LONG": -44.32,
    "LAT": -23,
    "CIDADE": "Angra dos Reis",
    "UF": "RIO DE JANEIRO",
    "anual": 4.56,
    "JAN": 4.84,
    "FEB": 5.39,
    "MAR": 4.81,
    "APR": 4.86,
    "MAY": 4.3,
    "JUN": 4.18,
    "JUL": 4.12,
    "AUG": 4.77,
    "SEP": 4.31,
    "OCT": 4.3,
    "NOV": 4.21,
    "DEC": 4.6
  },
  {
    "LONG": -44.04,
    "LAT": -22.96,
    "CIDADE": "Mangaratiba",
    "UF": "RIO DE JANEIRO",
    "anual": 4.61,
    "JAN": 4.92,
    "FEB": 5.56,
    "MAR": 4.86,
    "APR": 4.86,
    "MAY": 4.28,
    "JUN": 4.16,
    "JUL": 4.09,
    "AUG": 4.81,
    "SEP": 4.38,
    "OCT": 4.43,
    "NOV": 4.26,
    "DEC": 4.72
  },
  {
    "LONG": -42.03,
    "LAT": -22.98,
    "CIDADE": "Arraial do Cabo",
    "UF": "RIO DE JANEIRO",
    "anual": 5.24,
    "JAN": 5.76,
    "FEB": 6.4,
    "MAR": 5.6,
    "APR": 5.34,
    "MAY": 4.63,
    "JUN": 4.43,
    "JUL": 4.44,
    "AUG": 5.21,
    "SEP": 5.03,
    "OCT": 5.24,
    "NOV": 5.16,
    "DEC": 5.62
  },
  {
    "LONG": -43.78,
    "LAT": -22.86,
    "CIDADE": "Itaguaí",
    "UF": "RIO DE JANEIRO",
    "anual": 4.9,
    "JAN": 5.3,
    "FEB": 5.9,
    "MAR": 5.2,
    "APR": 5.13,
    "MAY": 4.46,
    "JUN": 4.29,
    "JUL": 4.26,
    "AUG": 5,
    "SEP": 4.64,
    "OCT": 4.81,
    "NOV": 4.67,
    "DEC": 5.15
  },
  {
    "LONG": -4.32,
    "LAT": -22.91,
    "CIDADE": "Rio de Janeiro",
    "UF": "RIO DE JANEIRO",
    "anual": 4.93,
    "JAN": 5.41,
    "FEB": 5.92,
    "MAR": 5.25,
    "APR": 4.98,
    "MAY": 4.43,
    "JUN": 4.33,
    "JUL": 4.22,
    "AUG": 4.99,
    "SEP": 4.75,
    "OCT": 4.98,
    "NOV": 4.71,
    "DEC": 5.23
  },
  {
    "LONG": -43.1,
    "LAT": -22.88,
    "CIDADE": "Niterói",
    "UF": "RIO DE JANEIRO",
    "anual": 4.99,
    "JAN": 5.54,
    "FEB": 6.04,
    "MAR": 5.36,
    "APR": 5.1,
    "MAY": 4.44,
    "JUN": 4.32,
    "JUL": 4.22,
    "AUG": 5.01,
    "SEP": 4.72,
    "OCT": 4.97,
    "NOV": 4.78,
    "DEC": 5.34
  },
  {
    "LONG": -4.28,
    "LAT": -22.94,
    "CIDADE": "Maricá",
    "UF": "RIO DE JANEIRO",
    "anual": 4.98,
    "JAN": 5.55,
    "FEB": 6.07,
    "MAR": 5.32,
    "APR": 5.05,
    "MAY": 4.44,
    "JUN": 4.33,
    "JUL": 4.27,
    "AUG": 5,
    "SEP": 4.76,
    "OCT": 4.94,
    "NOV": 4.78,
    "DEC": 5.32
  },
  {
    "LONG": -42.51,
    "LAT": -22.93,
    "CIDADE": "Saquarema",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.54,
    "FEB": 6.13,
    "MAR": 5.31,
    "APR": 5.07,
    "MAY": 4.43,
    "JUN": 4.37,
    "JUL": 4.27,
    "AUG": 4.95,
    "SEP": 4.7,
    "OCT": 4.92,
    "NOV": 4.69,
    "DEC": 5.22
  },
  {
    "LONG": -4.23,
    "LAT": -22.87,
    "CIDADE": "Araruama",
    "UF": "RIO DE JANEIRO",
    "anual": 5.07,
    "JAN": 5.61,
    "FEB": 6.16,
    "MAR": 5.45,
    "APR": 5.17,
    "MAY": 4.51,
    "JUN": 4.4,
    "JUL": 4.34,
    "AUG": 5.05,
    "SEP": 4.83,
    "OCT": 5.03,
    "NOV": 4.85,
    "DEC": 5.38
  },
  {
    "LONG": -4.2,
    "LAT": -22.89,
    "CIDADE": "Cabo Frio",
    "UF": "RIO DE JANEIRO",
    "anual": 5.12,
    "JAN": 5.7,
    "FEB": 6.18,
    "MAR": 5.46,
    "APR": 5.18,
    "MAY": 4.57,
    "JUN": 4.35,
    "JUL": 4.33,
    "AUG": 5.1,
    "SEP": 4.93,
    "OCT": 5.16,
    "NOV": 5.01,
    "DEC": 5.5
  },
  {
    "LONG": -43.72,
    "LAT": -22.75,
    "CIDADE": "Seropédica",
    "UF": "RIO DE JANEIRO",
    "anual": 4.89,
    "JAN": 5.31,
    "FEB": 5.93,
    "MAR": 5.18,
    "APR": 5.07,
    "MAY": 4.43,
    "JUN": 4.28,
    "JUL": 4.27,
    "AUG": 5.01,
    "SEP": 4.66,
    "OCT": 4.79,
    "NOV": 4.6,
    "DEC": 5.13
  },
  {
    "LONG": -43.4,
    "LAT": -22.76,
    "CIDADE": "Belford Roxo",
    "UF": "RIO DE JANEIRO",
    "anual": 4.82,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.08,
    "APR": 4.93,
    "MAY": 4.42,
    "JUN": 4.31,
    "JUL": 4.2,
    "AUG": 4.94,
    "SEP": 4.65,
    "OCT": 4.79,
    "NOV": 4.53,
    "DEC": 5.01
  },
  {
    "LONG": -43.42,
    "LAT": -22.81,
    "CIDADE": "Nilópolis",
    "UF": "RIO DE JANEIRO",
    "anual": 4.82,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.08,
    "APR": 4.93,
    "MAY": 4.42,
    "JUN": 4.31,
    "JUL": 4.2,
    "AUG": 4.94,
    "SEP": 4.65,
    "OCT": 4.79,
    "NOV": 4.53,
    "DEC": 5.01
  },
  {
    "LONG": -43.46,
    "LAT": -22.76,
    "CIDADE": "Nova Iguaçu",
    "UF": "RIO DE JANEIRO",
    "anual": 4.82,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.08,
    "APR": 4.93,
    "MAY": 4.42,
    "JUN": 4.31,
    "JUL": 4.2,
    "AUG": 4.94,
    "SEP": 4.65,
    "OCT": 4.79,
    "NOV": 4.53,
    "DEC": 5.01
  },
  {
    "LONG": -43.46,
    "LAT": -22.8,
    "CIDADE": "Mesquita",
    "UF": "RIO DE JANEIRO",
    "anual": 4.82,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.08,
    "APR": 4.93,
    "MAY": 4.42,
    "JUN": 4.31,
    "JUL": 4.2,
    "AUG": 4.94,
    "SEP": 4.65,
    "OCT": 4.79,
    "NOV": 4.53,
    "DEC": 5.01
  },
  {
    "LONG": -43.31,
    "LAT": -22.79,
    "CIDADE": "Duque de Caxias",
    "UF": "RIO DE JANEIRO",
    "anual": 4.91,
    "JAN": 5.35,
    "FEB": 5.85,
    "MAR": 5.21,
    "APR": 4.98,
    "MAY": 4.47,
    "JUN": 4.38,
    "JUL": 4.26,
    "AUG": 5.01,
    "SEP": 4.74,
    "OCT": 4.9,
    "NOV": 4.64,
    "DEC": 5.08
  },
  {
    "LONG": -43.37,
    "LAT": -22.81,
    "CIDADE": "São João de Meriti",
    "UF": "RIO DE JANEIRO",
    "anual": 4.91,
    "JAN": 5.35,
    "FEB": 5.85,
    "MAR": 5.21,
    "APR": 4.98,
    "MAY": 4.47,
    "JUN": 4.38,
    "JUL": 4.26,
    "AUG": 5.01,
    "SEP": 4.74,
    "OCT": 4.9,
    "NOV": 4.64,
    "DEC": 5.08
  },
  {
    "LONG": -43.06,
    "LAT": -22.83,
    "CIDADE": "São Gonçalo",
    "UF": "RIO DE JANEIRO",
    "anual": 4.96,
    "JAN": 5.5,
    "FEB": 6.02,
    "MAR": 5.3,
    "APR": 5.09,
    "MAY": 4.44,
    "JUN": 4.35,
    "JUL": 4.27,
    "AUG": 5,
    "SEP": 4.7,
    "OCT": 4.91,
    "NOV": 4.68,
    "DEC": 5.23
  },
  {
    "LONG": -42.86,
    "LAT": -2.28,
    "CIDADE": "Itaboraí",
    "UF": "RIO DE JANEIRO",
    "anual": 4.99,
    "JAN": 5.54,
    "FEB": 6.04,
    "MAR": 5.29,
    "APR": 5.11,
    "MAY": 4.47,
    "JUN": 4.4,
    "JUL": 4.32,
    "AUG": 5.03,
    "SEP": 4.78,
    "OCT": 4.95,
    "NOV": 4.72,
    "DEC": 5.25
  },
  {
    "LONG": -42.23,
    "LAT": -22.85,
    "CIDADE": "Iguaba Grande",
    "UF": "RIO DE JANEIRO",
    "anual": 5.07,
    "JAN": 5.62,
    "FEB": 6.18,
    "MAR": 5.4,
    "APR": 5.16,
    "MAY": 4.52,
    "JUN": 4.47,
    "JUL": 4.38,
    "AUG": 5.09,
    "SEP": 4.87,
    "OCT": 5.05,
    "NOV": 4.78,
    "DEC": 5.35
  },
  {
    "LONG": -4.21,
    "LAT": -22.84,
    "CIDADE": "São Pedro da Aldeia",
    "UF": "RIO DE JANEIRO",
    "anual": 5.1,
    "JAN": 5.65,
    "FEB": 6.23,
    "MAR": 5.42,
    "APR": 5.18,
    "MAY": 4.56,
    "JUN": 4.45,
    "JUL": 4.39,
    "AUG": 5.12,
    "SEP": 4.91,
    "OCT": 5.08,
    "NOV": 4.8,
    "DEC": 5.39
  },
  {
    "LONG": -41.88,
    "LAT": -22.75,
    "CIDADE": "Armação dos Búzios",
    "UF": "RIO DE JANEIRO",
    "anual": 5.28,
    "JAN": 5.82,
    "FEB": 6.45,
    "MAR": 5.6,
    "APR": 5.44,
    "MAY": 4.7,
    "JUN": 4.47,
    "JUL": 4.48,
    "AUG": 5.31,
    "SEP": 5.08,
    "OCT": 5.31,
    "NOV": 5.09,
    "DEC": 5.62
  },
  {
    "LONG": -44.14,
    "LAT": -22.72,
    "CIDADE": "Rio Claro",
    "UF": "RIO DE JANEIRO",
    "anual": 4.82,
    "JAN": 5.04,
    "FEB": 5.73,
    "MAR": 5.02,
    "APR": 4.87,
    "MAY": 4.36,
    "JUN": 4.29,
    "JUL": 4.28,
    "AUG": 5.04,
    "SEP": 4.78,
    "OCT": 4.8,
    "NOV": 4.57,
    "DEC": 5.07
  },
  {
    "LONG": -43.55,
    "LAT": -22.71,
    "CIDADE": "Queimados",
    "UF": "RIO DE JANEIRO",
    "anual": 4.89,
    "JAN": 5.27,
    "FEB": 5.84,
    "MAR": 5.2,
    "APR": 5.06,
    "MAY": 4.47,
    "JUN": 4.37,
    "JUL": 4.28,
    "AUG": 5.02,
    "SEP": 4.7,
    "OCT": 4.8,
    "NOV": 4.57,
    "DEC": 5.05
  },
  {
    "LONG": -43.03,
    "LAT": -22.66,
    "CIDADE": "Magé",
    "UF": "RIO DE JANEIRO",
    "anual": 4.88,
    "JAN": 5.35,
    "FEB": 5.88,
    "MAR": 5.18,
    "APR": 5.06,
    "MAY": 4.42,
    "JUN": 4.37,
    "JUL": 4.28,
    "AUG": 4.98,
    "SEP": 4.66,
    "OCT": 4.78,
    "NOV": 4.51,
    "DEC": 5.03
  },
  {
    "LONG": -42.72,
    "LAT": -22.74,
    "CIDADE": "Tanguá",
    "UF": "RIO DE JANEIRO",
    "anual": 4.96,
    "JAN": 5.44,
    "FEB": 6.02,
    "MAR": 5.23,
    "APR": 5.08,
    "MAY": 4.48,
    "JUN": 4.46,
    "JUL": 4.35,
    "AUG": 5.04,
    "SEP": 4.77,
    "OCT": 4.86,
    "NOV": 4.62,
    "DEC": 5.12
  },
  {
    "LONG": -42.63,
    "LAT": -22.72,
    "CIDADE": "Rio Bonito",
    "UF": "RIO DE JANEIRO",
    "anual": 4.94,
    "JAN": 5.41,
    "FEB": 6,
    "MAR": 5.19,
    "APR": 5.05,
    "MAY": 4.49,
    "JUN": 4.46,
    "JUL": 4.36,
    "AUG": 5.04,
    "SEP": 4.78,
    "OCT": 4.85,
    "NOV": 4.59,
    "DEC": 5.09
  },
  {
    "LONG": -42.4,
    "LAT": -22.66,
    "CIDADE": "Silva Jardim",
    "UF": "RIO DE JANEIRO",
    "anual": 4.99,
    "JAN": 5.5,
    "FEB": 6.09,
    "MAR": 5.28,
    "APR": 5.1,
    "MAY": 4.52,
    "JUN": 4.44,
    "JUL": 4.35,
    "AUG": 5.09,
    "SEP": 4.81,
    "OCT": 4.93,
    "NOV": 4.61,
    "DEC": 5.13
  },
  {
    "LONG": -43.91,
    "LAT": -2.26,
    "CIDADE": "Piraí",
    "UF": "RIO DE JANEIRO",
    "anual": 4.86,
    "JAN": 5.17,
    "FEB": 5.81,
    "MAR": 5.12,
    "APR": 4.95,
    "MAY": 4.37,
    "JUN": 4.29,
    "JUL": 4.28,
    "AUG": 5.05,
    "SEP": 4.78,
    "OCT": 4.8,
    "NOV": 4.64,
    "DEC": 5.11
  },
  {
    "LONG": -43.71,
    "LAT": -22.61,
    "CIDADE": "Paracambi",
    "UF": "RIO DE JANEIRO",
    "anual": 4.86,
    "JAN": 5.21,
    "FEB": 5.85,
    "MAR": 5.16,
    "APR": 4.99,
    "MAY": 4.41,
    "JUN": 4.32,
    "JUL": 4.27,
    "AUG": 5.03,
    "SEP": 4.74,
    "OCT": 4.78,
    "NOV": 4.55,
    "DEC": 5.02
  },
  {
    "LONG": -43.66,
    "LAT": -2.26,
    "CIDADE": "Japeri",
    "UF": "RIO DE JANEIRO",
    "anual": 4.84,
    "JAN": 5.16,
    "FEB": 5.83,
    "MAR": 5.12,
    "APR": 5,
    "MAY": 4.42,
    "JUN": 4.36,
    "JUL": 4.28,
    "AUG": 4.99,
    "SEP": 4.69,
    "OCT": 4.72,
    "NOV": 4.51,
    "DEC": 4.96
  },
  {
    "LONG": -44.57,
    "LAT": -22.49,
    "CIDADE": "Itatiaia",
    "UF": "RIO DE JANEIRO",
    "anual": 4.67,
    "JAN": 4.72,
    "FEB": 5.16,
    "MAR": 4.76,
    "APR": 4.83,
    "MAY": 4.34,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 5.13,
    "SEP": 4.75,
    "OCT": 4.6,
    "NOV": 4.35,
    "DEC": 4.78
  },
  {
    "LONG": -44.45,
    "LAT": -2.25,
    "CIDADE": "Resende",
    "UF": "RIO DE JANEIRO",
    "anual": 4.78,
    "JAN": 4.88,
    "FEB": 5.43,
    "MAR": 4.96,
    "APR": 4.92,
    "MAY": 4.4,
    "JUN": 4.32,
    "JUL": 4.31,
    "AUG": 5.18,
    "SEP": 4.81,
    "OCT": 4.72,
    "NOV": 4.5,
    "DEC": 4.93
  },
  {
    "LONG": -44.18,
    "LAT": -22.55,
    "CIDADE": "Barra Mansa",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.08,
    "FEB": 5.7,
    "MAR": 5.09,
    "APR": 4.95,
    "MAY": 4.41,
    "JUN": 4.35,
    "JUL": 4.31,
    "AUG": 5.15,
    "SEP": 4.89,
    "OCT": 4.85,
    "NOV": 4.62,
    "DEC": 5.08
  },
  {
    "LONG": -44.1,
    "LAT": -22.52,
    "CIDADE": "Volta Redonda",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.08,
    "FEB": 5.7,
    "MAR": 5.09,
    "APR": 4.95,
    "MAY": 4.41,
    "JUN": 4.35,
    "JUL": 4.31,
    "AUG": 5.15,
    "SEP": 4.89,
    "OCT": 4.85,
    "NOV": 4.62,
    "DEC": 5.08
  },
  {
    "LONG": -44,
    "LAT": -22.52,
    "CIDADE": "Pinheiral",
    "UF": "RIO DE JANEIRO",
    "anual": 4.89,
    "JAN": 5.16,
    "FEB": 5.74,
    "MAR": 5.12,
    "APR": 4.98,
    "MAY": 4.41,
    "JUN": 4.36,
    "JUL": 4.32,
    "AUG": 5.15,
    "SEP": 4.86,
    "OCT": 4.84,
    "NOV": 4.65,
    "DEC": 5.1
  },
  {
    "LONG": -43.83,
    "LAT": -2.25,
    "CIDADE": "Barra do Piraí",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.21,
    "FEB": 5.78,
    "MAR": 5.13,
    "APR": 4.96,
    "MAY": 4.4,
    "JUN": 4.29,
    "JUL": 4.3,
    "AUG": 5.02,
    "SEP": 4.81,
    "OCT": 4.79,
    "NOV": 4.63,
    "DEC": 5.09
  },
  {
    "LONG": -43.73,
    "LAT": -2.25,
    "CIDADE": "Mendes",
    "UF": "RIO DE JANEIRO",
    "anual": 4.92,
    "JAN": 5.27,
    "FEB": 5.84,
    "MAR": 5.17,
    "APR": 5,
    "MAY": 4.43,
    "JUN": 4.38,
    "JUL": 4.37,
    "AUG": 5.08,
    "SEP": 4.86,
    "OCT": 4.84,
    "NOV": 4.69,
    "DEC": 5.1
  },
  {
    "LONG": -43.68,
    "LAT": -22.55,
    "CIDADE": "Engenheiro Paulo de Frontin",
    "UF": "RIO DE JANEIRO",
    "anual": 4.9,
    "JAN": 5.26,
    "FEB": 5.85,
    "MAR": 5.15,
    "APR": 4.98,
    "MAY": 4.4,
    "JUN": 4.37,
    "JUL": 4.36,
    "AUG": 5.04,
    "SEP": 4.82,
    "OCT": 4.82,
    "NOV": 4.64,
    "DEC": 5.08
  },
  {
    "LONG": -43.48,
    "LAT": -22.46,
    "CIDADE": "Miguel Pereira",
    "UF": "RIO DE JANEIRO",
    "anual": 4.91,
    "JAN": 5.2,
    "FEB": 5.87,
    "MAR": 5.14,
    "APR": 4.94,
    "MAY": 4.45,
    "JUN": 4.38,
    "JUL": 4.42,
    "AUG": 5.07,
    "SEP": 4.87,
    "OCT": 4.88,
    "NOV": 4.62,
    "DEC": 5.1
  },
  {
    "LONG": -4.32,
    "LAT": -22.52,
    "CIDADE": "Petrópolis",
    "UF": "RIO DE JANEIRO",
    "anual": 4.72,
    "JAN": 4.99,
    "FEB": 5.54,
    "MAR": 4.85,
    "APR": 4.72,
    "MAY": 4.25,
    "JUN": 4.3,
    "JUL": 4.34,
    "AUG": 5.02,
    "SEP": 4.8,
    "OCT": 4.71,
    "NOV": 4.38,
    "DEC": 4.79
  },
  {
    "LONG": -42.99,
    "LAT": -22.54,
    "CIDADE": "Guapimirim",
    "UF": "RIO DE JANEIRO",
    "anual": 4.53,
    "JAN": 4.75,
    "FEB": 5.44,
    "MAR": 4.67,
    "APR": 4.57,
    "MAY": 4.21,
    "JUN": 4.19,
    "JUL": 4.16,
    "AUG": 4.81,
    "SEP": 4.6,
    "OCT": 4.41,
    "NOV": 4.04,
    "DEC": 4.5
  },
  {
    "LONG": -42.65,
    "LAT": -22.47,
    "CIDADE": "Cachoeiras de Macacu",
    "UF": "RIO DE JANEIRO",
    "anual": 4.8,
    "JAN": 5.12,
    "FEB": 5.74,
    "MAR": 5,
    "APR": 4.93,
    "MAY": 4.39,
    "JUN": 4.47,
    "JUL": 4.35,
    "AUG": 5.05,
    "SEP": 4.76,
    "OCT": 4.67,
    "NOV": 4.29,
    "DEC": 4.8
  },
  {
    "LONG": -4.22,
    "LAT": -22.48,
    "CIDADE": "Casimiro de Abreu",
    "UF": "RIO DE JANEIRO",
    "anual": 4.8,
    "JAN": 5.19,
    "FEB": 5.8,
    "MAR": 4.98,
    "APR": 4.93,
    "MAY": 4.43,
    "JUN": 4.39,
    "JUL": 4.29,
    "AUG": 5.02,
    "SEP": 4.73,
    "OCT": 4.73,
    "NOV": 4.27,
    "DEC": 4.79
  },
  {
    "LONG": -41.95,
    "LAT": -22.52,
    "CIDADE": "Rio das Ostras",
    "UF": "RIO DE JANEIRO",
    "anual": 5,
    "JAN": 5.57,
    "FEB": 6.12,
    "MAR": 5.3,
    "APR": 5.1,
    "MAY": 4.54,
    "JUN": 4.41,
    "JUL": 4.39,
    "AUG": 5.08,
    "SEP": 4.83,
    "OCT": 4.93,
    "NOV": 4.59,
    "DEC": 5.18
  },
  {
    "LONG": -44.26,
    "LAT": -2.24,
    "CIDADE": "Quatis",
    "UF": "RIO DE JANEIRO",
    "anual": 4.83,
    "JAN": 4.97,
    "FEB": 5.55,
    "MAR": 5,
    "APR": 4.93,
    "MAY": 4.43,
    "JUN": 4.39,
    "JUL": 4.34,
    "AUG": 5.2,
    "SEP": 4.88,
    "OCT": 4.76,
    "NOV": 4.52,
    "DEC": 4.95
  },
  {
    "LONG": -44.3,
    "LAT": -2.24,
    "CIDADE": "Porto Real",
    "UF": "RIO DE JANEIRO",
    "anual": 4.83,
    "JAN": 4.97,
    "FEB": 5.55,
    "MAR": 5,
    "APR": 4.93,
    "MAY": 4.43,
    "JUN": 4.39,
    "JUL": 4.34,
    "AUG": 5.2,
    "SEP": 4.88,
    "OCT": 4.76,
    "NOV": 4.52,
    "DEC": 4.95
  },
  {
    "LONG": -43.67,
    "LAT": -22.41,
    "CIDADE": "Vassouras",
    "UF": "RIO DE JANEIRO",
    "anual": 4.91,
    "JAN": 5.23,
    "FEB": 5.82,
    "MAR": 5.17,
    "APR": 4.99,
    "MAY": 4.4,
    "JUN": 4.34,
    "JUL": 4.38,
    "AUG": 5.06,
    "SEP": 4.86,
    "OCT": 4.85,
    "NOV": 4.68,
    "DEC": 5.12
  },
  {
    "LONG": -43.43,
    "LAT": -22.43,
    "CIDADE": "Paty do Alferes",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.27,
    "FEB": 5.91,
    "MAR": 5.27,
    "APR": 5.02,
    "MAY": 4.45,
    "JUN": 4.34,
    "JUL": 4.43,
    "AUG": 5.09,
    "SEP": 4.96,
    "OCT": 4.99,
    "NOV": 4.74,
    "DEC": 5.2
  },
  {
    "LONG": -42.98,
    "LAT": -22.42,
    "CIDADE": "Teresópolis",
    "UF": "RIO DE JANEIRO",
    "anual": 4.66,
    "JAN": 4.91,
    "FEB": 5.53,
    "MAR": 4.83,
    "APR": 4.65,
    "MAY": 4.23,
    "JUN": 4.22,
    "JUL": 4.26,
    "AUG": 4.9,
    "SEP": 4.77,
    "OCT": 4.64,
    "NOV": 4.25,
    "DEC": 4.74
  },
  {
    "LONG": -41.79,
    "LAT": -22.38,
    "CIDADE": "Macaé",
    "UF": "RIO DE JANEIRO",
    "anual": 5.14,
    "JAN": 5.69,
    "FEB": 6.28,
    "MAR": 5.46,
    "APR": 5.31,
    "MAY": 4.69,
    "JUN": 4.51,
    "JUL": 4.47,
    "AUG": 5.2,
    "SEP": 4.93,
    "OCT": 5.02,
    "NOV": 4.76,
    "DEC": 5.37
  },
  {
    "LONG": -42.54,
    "LAT": -22.29,
    "CIDADE": "Nova Friburgo",
    "UF": "RIO DE JANEIRO",
    "anual": 4.67,
    "JAN": 4.98,
    "FEB": 5.57,
    "MAR": 4.85,
    "APR": 4.68,
    "MAY": 4.22,
    "JUN": 4.17,
    "JUL": 4.31,
    "AUG": 4.91,
    "SEP": 4.76,
    "OCT": 4.65,
    "NOV": 4.25,
    "DEC": 4.77
  },
  {
    "LONG": -43.71,
    "LAT": -2.22,
    "CIDADE": "Valença",
    "UF": "RIO DE JANEIRO",
    "anual": 4.89,
    "JAN": 5.07,
    "FEB": 5.73,
    "MAR": 5.13,
    "APR": 4.96,
    "MAY": 4.45,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 5.16,
    "SEP": 4.93,
    "OCT": 4.81,
    "NOV": 4.58,
    "DEC": 5.05
  },
  {
    "LONG": -4.36,
    "LAT": -22.17,
    "CIDADE": "Rio das Flores",
    "UF": "RIO DE JANEIRO",
    "anual": 4.84,
    "JAN": 5.18,
    "FEB": 5.73,
    "MAR": 5.11,
    "APR": 4.89,
    "MAY": 4.38,
    "JUN": 4.3,
    "JUL": 4.36,
    "AUG": 5.02,
    "SEP": 4.87,
    "OCT": 4.81,
    "NOV": 4.53,
    "DEC": 4.96
  },
  {
    "LONG": -43.3,
    "LAT": -22.16,
    "CIDADE": "Paraíba do Sul",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.3,
    "FEB": 5.84,
    "MAR": 5.29,
    "APR": 5.05,
    "MAY": 4.44,
    "JUN": 4.31,
    "JUL": 4.45,
    "AUG": 5.14,
    "SEP": 5,
    "OCT": 4.94,
    "NOV": 4.67,
    "DEC": 5.16
  },
  {
    "LONG": -4.31,
    "LAT": -22.24,
    "CIDADE": "Areal",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.29,
    "FEB": 5.85,
    "MAR": 5.32,
    "APR": 5.1,
    "MAY": 4.45,
    "JUN": 4.34,
    "JUL": 4.44,
    "AUG": 5.13,
    "SEP": 4.99,
    "OCT": 4.94,
    "NOV": 4.66,
    "DEC": 5.16
  },
  {
    "LONG": -42.93,
    "LAT": -2.22,
    "CIDADE": "São José do Vale do Rio Preto",
    "UF": "RIO DE JANEIRO",
    "anual": 4.85,
    "JAN": 5.19,
    "FEB": 5.75,
    "MAR": 5.17,
    "APR": 4.87,
    "MAY": 4.36,
    "JUN": 4.26,
    "JUL": 4.38,
    "AUG": 5.03,
    "SEP": 4.88,
    "OCT": 4.85,
    "NOV": 4.5,
    "DEC": 5.04
  },
  {
    "LONG": -42.43,
    "LAT": -2.22,
    "CIDADE": "Bom Jardim",
    "UF": "RIO DE JANEIRO",
    "anual": 4.75,
    "JAN": 5.16,
    "FEB": 5.69,
    "MAR": 5.01,
    "APR": 4.79,
    "MAY": 4.27,
    "JUN": 4.18,
    "JUL": 4.33,
    "AUG": 4.89,
    "SEP": 4.77,
    "OCT": 4.65,
    "NOV": 4.33,
    "DEC": 4.88
  },
  {
    "LONG": -41.66,
    "LAT": -22.18,
    "CIDADE": "Carapebus",
    "UF": "RIO DE JANEIRO",
    "anual": 5.08,
    "JAN": 5.66,
    "FEB": 6.19,
    "MAR": 5.34,
    "APR": 5.19,
    "MAY": 4.64,
    "JUN": 4.5,
    "JUL": 4.47,
    "AUG": 5.15,
    "SEP": 4.95,
    "OCT": 4.95,
    "NOV": 4.67,
    "DEC": 5.26
  },
  {
    "LONG": -43.22,
    "LAT": -2.21,
    "CIDADE": "Três Rios",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.16,
    "FEB": 5.72,
    "MAR": 5.17,
    "APR": 4.94,
    "MAY": 4.36,
    "JUN": 4.3,
    "JUL": 4.42,
    "AUG": 5.04,
    "SEP": 4.9,
    "OCT": 4.84,
    "NOV": 4.55,
    "DEC": 5.04
  },
  {
    "LONG": -42.52,
    "LAT": -22.05,
    "CIDADE": "Duas Barras",
    "UF": "RIO DE JANEIRO",
    "anual": 4.8,
    "JAN": 5.15,
    "FEB": 5.77,
    "MAR": 5.15,
    "APR": 4.87,
    "MAY": 4.33,
    "JUN": 4.23,
    "JUL": 4.33,
    "AUG": 4.92,
    "SEP": 4.82,
    "OCT": 4.72,
    "NOV": 4.31,
    "DEC": 4.97
  },
  {
    "LONG": -4.21,
    "LAT": -22.06,
    "CIDADE": "Trajano de Moraes",
    "UF": "RIO DE JANEIRO",
    "anual": 4.79,
    "JAN": 5.27,
    "FEB": 5.86,
    "MAR": 5.06,
    "APR": 4.78,
    "MAY": 4.31,
    "JUN": 4.22,
    "JUL": 4.27,
    "AUG": 4.88,
    "SEP": 4.8,
    "OCT": 4.65,
    "NOV": 4.35,
    "DEC": 4.98
  },
  {
    "LONG": -41.87,
    "LAT": -22.08,
    "CIDADE": "Conceição de Macabu",
    "UF": "RIO DE JANEIRO",
    "anual": 4.86,
    "JAN": 5.28,
    "FEB": 5.91,
    "MAR": 5.17,
    "APR": 4.98,
    "MAY": 4.52,
    "JUN": 4.4,
    "JUL": 4.36,
    "AUG": 4.96,
    "SEP": 4.77,
    "OCT": 4.63,
    "NOV": 4.36,
    "DEC": 4.98
  },
  {
    "LONG": -41.47,
    "LAT": -22.1,
    "CIDADE": "Quissamã",
    "UF": "RIO DE JANEIRO",
    "anual": 5.15,
    "JAN": 5.74,
    "FEB": 6.3,
    "MAR": 5.45,
    "APR": 5.23,
    "MAY": 4.65,
    "JUN": 4.48,
    "JUL": 4.51,
    "AUG": 5.23,
    "SEP": 5,
    "OCT": 5.05,
    "NOV": 4.77,
    "DEC": 5.4
  },
  {
    "LONG": -43.21,
    "LAT": -22.04,
    "CIDADE": "Comendador Levy Gasparian",
    "UF": "RIO DE JANEIRO",
    "anual": 4.8,
    "JAN": 5.12,
    "FEB": 5.62,
    "MAR": 5.07,
    "APR": 4.86,
    "MAY": 4.29,
    "JUN": 4.28,
    "JUL": 4.4,
    "AUG": 4.96,
    "SEP": 4.86,
    "OCT": 4.77,
    "NOV": 4.45,
    "DEC": 4.95
  },
  {
    "LONG": -42.91,
    "LAT": -22,
    "CIDADE": "Sapucaia",
    "UF": "RIO DE JANEIRO",
    "anual": 4.92,
    "JAN": 5.23,
    "FEB": 5.75,
    "MAR": 5.27,
    "APR": 4.97,
    "MAY": 4.38,
    "JUN": 4.31,
    "JUL": 4.44,
    "AUG": 5.07,
    "SEP": 4.95,
    "OCT": 4.9,
    "NOV": 4.62,
    "DEC": 5.1
  },
  {
    "LONG": -42.68,
    "LAT": -22.05,
    "CIDADE": "Sumidouro",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.34,
    "FEB": 5.92,
    "MAR": 5.39,
    "APR": 5.15,
    "MAY": 4.47,
    "JUN": 4.3,
    "JUL": 4.45,
    "AUG": 5.09,
    "SEP": 4.95,
    "OCT": 4.85,
    "NOV": 4.54,
    "DEC": 5.16
  },
  {
    "LONG": -42.37,
    "LAT": -22.03,
    "CIDADE": "Cordeiro",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.3,
    "FEB": 5.86,
    "MAR": 5.19,
    "APR": 4.9,
    "MAY": 4.4,
    "JUN": 4.28,
    "JUL": 4.42,
    "AUG": 4.99,
    "SEP": 4.86,
    "OCT": 4.74,
    "NOV": 4.46,
    "DEC": 5.05
  },
  {
    "LONG": -42.37,
    "LAT": -21.98,
    "CIDADE": "Cantagalo",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.3,
    "FEB": 5.86,
    "MAR": 5.19,
    "APR": 4.9,
    "MAY": 4.4,
    "JUN": 4.28,
    "JUL": 4.42,
    "AUG": 4.99,
    "SEP": 4.86,
    "OCT": 4.74,
    "NOV": 4.46,
    "DEC": 5.05
  },
  {
    "LONG": -42.25,
    "LAT": -21.98,
    "CIDADE": "Macuco",
    "UF": "RIO DE JANEIRO",
    "anual": 4.94,
    "JAN": 5.4,
    "FEB": 5.92,
    "MAR": 5.3,
    "APR": 4.99,
    "MAY": 4.45,
    "JUN": 4.33,
    "JUL": 4.45,
    "AUG": 5.04,
    "SEP": 4.91,
    "OCT": 4.82,
    "NOV": 4.55,
    "DEC": 5.14
  },
  {
    "LONG": -42.13,
    "LAT": -21.96,
    "CIDADE": "São Sebastião do Alto",
    "UF": "RIO DE JANEIRO",
    "anual": 4.98,
    "JAN": 5.43,
    "FEB": 5.99,
    "MAR": 5.35,
    "APR": 5.05,
    "MAY": 4.5,
    "JUN": 4.36,
    "JUL": 4.45,
    "AUG": 5.07,
    "SEP": 4.96,
    "OCT": 4.85,
    "NOV": 4.62,
    "DEC": 5.19
  },
  {
    "LONG": -42.01,
    "LAT": -21.96,
    "CIDADE": "Santa Maria Madalena",
    "UF": "RIO DE JANEIRO",
    "anual": 4.94,
    "JAN": 5.37,
    "FEB": 6,
    "MAR": 5.29,
    "APR": 4.93,
    "MAY": 4.46,
    "JUN": 4.32,
    "JUL": 4.38,
    "AUG": 5.02,
    "SEP": 4.93,
    "OCT": 4.83,
    "NOV": 4.56,
    "DEC": 5.14
  },
  {
    "LONG": -4.26,
    "LAT": -21.93,
    "CIDADE": "Carmo",
    "UF": "RIO DE JANEIRO",
    "anual": 5,
    "JAN": 5.35,
    "FEB": 5.94,
    "MAR": 5.42,
    "APR": 5.14,
    "MAY": 4.5,
    "JUN": 4.35,
    "JUL": 4.48,
    "AUG": 5.12,
    "SEP": 5.01,
    "OCT": 4.88,
    "NOV": 4.57,
    "DEC": 5.18
  },
  {
    "LONG": -41.32,
    "LAT": -21.76,
    "CIDADE": "Campos dos Goytacazes",
    "UF": "RIO DE JANEIRO",
    "anual": 5.11,
    "JAN": 5.67,
    "FEB": 6.24,
    "MAR": 5.44,
    "APR": 5.13,
    "MAY": 4.63,
    "JUN": 4.51,
    "JUL": 4.48,
    "AUG": 5.14,
    "SEP": 5.01,
    "OCT": 4.98,
    "NOV": 4.75,
    "DEC": 5.35
  },
  {
    "LONG": -42.08,
    "LAT": -21.68,
    "CIDADE": "Itaocara",
    "UF": "RIO DE JANEIRO",
    "anual": 5.14,
    "JAN": 5.6,
    "FEB": 6.11,
    "MAR": 5.5,
    "APR": 5.16,
    "MAY": 4.66,
    "JUN": 4.5,
    "JUL": 4.54,
    "AUG": 5.2,
    "SEP": 5.16,
    "OCT": 5.1,
    "NOV": 4.82,
    "DEC": 5.35
  },
  {
    "LONG": -4.21,
    "LAT": -21.63,
    "CIDADE": "Aperibé",
    "UF": "RIO DE JANEIRO",
    "anual": 5.06,
    "JAN": 5.54,
    "FEB": 6.02,
    "MAR": 5.39,
    "APR": 5.07,
    "MAY": 4.54,
    "JUN": 4.39,
    "JUL": 4.49,
    "AUG": 5.09,
    "SEP": 5.13,
    "OCT": 5.04,
    "NOV": 4.7,
    "DEC": 5.34
  },
  {
    "LONG": -41.92,
    "LAT": -21.57,
    "CIDADE": "Cambuci",
    "UF": "RIO DE JANEIRO",
    "anual": 5.04,
    "JAN": 5.57,
    "FEB": 5.97,
    "MAR": 5.32,
    "APR": 5.07,
    "MAY": 4.6,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 5.09,
    "SEP": 5.08,
    "OCT": 4.94,
    "NOV": 4.62,
    "DEC": 5.21
  },
  {
    "LONG": -41.75,
    "LAT": -21.65,
    "CIDADE": "São Fidélis",
    "UF": "RIO DE JANEIRO",
    "anual": 5.1,
    "JAN": 5.62,
    "FEB": 6.08,
    "MAR": 5.4,
    "APR": 5.08,
    "MAY": 4.7,
    "JUN": 4.55,
    "JUL": 4.54,
    "AUG": 5.14,
    "SEP": 5.09,
    "OCT": 4.99,
    "NOV": 4.69,
    "DEC": 5.3
  },
  {
    "LONG": -41.04,
    "LAT": -21.64,
    "CIDADE": "São João da Barra",
    "UF": "RIO DE JANEIRO",
    "anual": 5.26,
    "JAN": 5.82,
    "FEB": 6.38,
    "MAR": 5.58,
    "APR": 5.25,
    "MAY": 4.7,
    "JUN": 4.56,
    "JUL": 4.57,
    "AUG": 5.34,
    "SEP": 5.25,
    "OCT": 5.26,
    "NOV": 4.94,
    "DEC": 5.52
  },
  {
    "LONG": -42.18,
    "LAT": -21.54,
    "CIDADE": "Santo Antônio de Pádua",
    "UF": "RIO DE JANEIRO",
    "anual": 5.05,
    "JAN": 5.46,
    "FEB": 5.99,
    "MAR": 5.4,
    "APR": 5.07,
    "MAY": 4.55,
    "JUN": 4.39,
    "JUL": 4.47,
    "AUG": 5.15,
    "SEP": 5.13,
    "OCT": 5.03,
    "NOV": 4.66,
    "DEC": 5.28
  },
  {
    "LONG": -41.62,
    "LAT": -21.49,
    "CIDADE": "Cardoso Moreira",
    "UF": "RIO DE JANEIRO",
    "anual": 5.12,
    "JAN": 5.68,
    "FEB": 6.13,
    "MAR": 5.42,
    "APR": 5.11,
    "MAY": 4.65,
    "JUN": 4.49,
    "JUL": 4.53,
    "AUG": 5.13,
    "SEP": 5.15,
    "OCT": 5.05,
    "NOV": 4.74,
    "DEC": 5.32
  },
  {
    "LONG": -41.11,
    "LAT": -21.48,
    "CIDADE": "São Francisco de Itabapoana",
    "UF": "RIO DE JANEIRO",
    "anual": 5.19,
    "JAN": 5.82,
    "FEB": 6.37,
    "MAR": 5.49,
    "APR": 5.11,
    "MAY": 4.67,
    "JUN": 4.52,
    "JUL": 4.52,
    "AUG": 5.28,
    "SEP": 5.16,
    "OCT": 5.14,
    "NOV": 4.82,
    "DEC": 5.39
  },
  {
    "LONG": -42.19,
    "LAT": -21.42,
    "CIDADE": "Miracema",
    "UF": "RIO DE JANEIRO",
    "anual": 5,
    "JAN": 5.36,
    "FEB": 5.9,
    "MAR": 5.3,
    "APR": 4.99,
    "MAY": 4.54,
    "JUN": 4.39,
    "JUL": 4.47,
    "AUG": 5.13,
    "SEP": 5.09,
    "OCT": 5,
    "NOV": 4.58,
    "DEC": 5.2
  },
  {
    "LONG": -41.94,
    "LAT": -21.36,
    "CIDADE": "São José de Ubá",
    "UF": "RIO DE JANEIRO",
    "anual": 5.08,
    "JAN": 5.53,
    "FEB": 6.07,
    "MAR": 5.36,
    "APR": 5.04,
    "MAY": 4.6,
    "JUN": 4.45,
    "JUL": 4.52,
    "AUG": 5.17,
    "SEP": 5.16,
    "OCT": 5.08,
    "NOV": 4.7,
    "DEC": 5.32
  },
  {
    "LONG": -41.7,
    "LAT": -21.43,
    "CIDADE": "Italva",
    "UF": "RIO DE JANEIRO",
    "anual": 5.13,
    "JAN": 5.64,
    "FEB": 6.13,
    "MAR": 5.44,
    "APR": 5.09,
    "MAY": 4.71,
    "JUN": 4.47,
    "JUL": 4.52,
    "AUG": 5.18,
    "SEP": 5.18,
    "OCT": 5.08,
    "NOV": 4.77,
    "DEC": 5.36
  },
  {
    "LONG": -42.12,
    "LAT": -21.21,
    "CIDADE": "Laje do Muriaé",
    "UF": "RIO DE JANEIRO",
    "anual": 5.11,
    "JAN": 5.52,
    "FEB": 6.03,
    "MAR": 5.37,
    "APR": 5.11,
    "MAY": 4.62,
    "JUN": 4.54,
    "JUL": 4.58,
    "AUG": 5.25,
    "SEP": 5.22,
    "OCT": 5.1,
    "NOV": 4.69,
    "DEC": 5.3
  },
  {
    "LONG": -4.19,
    "LAT": -21.2,
    "CIDADE": "Itaperuna",
    "UF": "RIO DE JANEIRO",
    "anual": 5.15,
    "JAN": 5.57,
    "FEB": 6.09,
    "MAR": 5.41,
    "APR": 5.17,
    "MAY": 4.66,
    "JUN": 4.54,
    "JUL": 4.6,
    "AUG": 5.29,
    "SEP": 5.25,
    "OCT": 5.11,
    "NOV": 4.74,
    "DEC": 5.37
  },
  {
    "LONG": -41.68,
    "LAT": -21.15,
    "CIDADE": "Bom Jesus do Itabapoana",
    "UF": "RIO DE JANEIRO",
    "anual": 5.07,
    "JAN": 5.5,
    "FEB": 5.97,
    "MAR": 5.3,
    "APR": 5.06,
    "MAY": 4.67,
    "JUN": 4.57,
    "JUL": 4.61,
    "AUG": 5.24,
    "SEP": 5.22,
    "OCT": 4.98,
    "NOV": 4.6,
    "DEC": 5.17
  },
  {
    "LONG": -4.2,
    "LAT": -20.97,
    "CIDADE": "Porciúncula",
    "UF": "RIO DE JANEIRO",
    "anual": 5.1,
    "JAN": 5.47,
    "FEB": 5.99,
    "MAR": 5.3,
    "APR": 5.08,
    "MAY": 4.64,
    "JUN": 4.63,
    "JUL": 4.69,
    "AUG": 5.34,
    "SEP": 5.28,
    "OCT": 5.05,
    "NOV": 4.6,
    "DEC": 5.18
  },
  {
    "LONG": -41.97,
    "LAT": -21.04,
    "CIDADE": "Natividade",
    "UF": "RIO DE JANEIRO",
    "anual": 5.13,
    "JAN": 5.48,
    "FEB": 6,
    "MAR": 5.33,
    "APR": 5.16,
    "MAY": 4.72,
    "JUN": 4.65,
    "JUL": 4.69,
    "AUG": 5.32,
    "SEP": 5.25,
    "OCT": 5.05,
    "NOV": 4.64,
    "DEC": 5.22
  },
  {
    "LONG": -41.87,
    "LAT": -2.09,
    "CIDADE": "Varre-Sai",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.3,
    "FEB": 5.76,
    "MAR": 5.09,
    "APR": 4.93,
    "MAY": 4.56,
    "JUN": 4.57,
    "JUL": 4.62,
    "AUG": 5.24,
    "SEP": 5.2,
    "OCT": 4.89,
    "NOV": 4.45,
    "DEC": 5.03
  },
  {
    "LONG": -51.21,
    "LAT": -30.03,
    "CIDADE": "Porto Alegre",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 4.67,
    "JAN": 5.68,
    "FEB": 5.63,
    "MAR": 5.24,
    "APR": 4.71,
    "MAY": 3.76,
    "JUN": 3.27,
    "JUL": 3.53,
    "AUG": 4.08,
    "SEP": 4.02,
    "OCT": 4.78,
    "NOV": 5.64,
    "DEC": 5.75
  },
  {
    "LONG": -36.72,
    "LAT": -6.95,
    "CIDADE": "Equador",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.79,
    "JAN": 5.75,
    "FEB": 5.82,
    "MAR": 6.02,
    "APR": 5.76,
    "MAY": 5.27,
    "JUN": 4.92,
    "JUL": 5.22,
    "AUG": 5.94,
    "SEP": 6.32,
    "OCT": 6.35,
    "NOV": 6.25,
    "DEC": 5.86
  },
  {
    "LONG": -37.2,
    "LAT": -0.68,
    "CIDADE": "Ipueira",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.93,
    "JAN": 5.9,
    "FEB": 6.02,
    "MAR": 6.2,
    "APR": 6.01,
    "MAY": 5.46,
    "JUN": 5.05,
    "JUL": 5.31,
    "AUG": 6.01,
    "SEP": 6.42,
    "OCT": 6.45,
    "NOV": 6.34,
    "DEC": 5.97
  },
  {
    "LONG": -36.73,
    "LAT": "#VALOR!",
    "CIDADE": "Santana do Seridó",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.77,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.83,
    "MAY": 5.31,
    "JUN": 4.92,
    "JUL": 5.21,
    "AUG": 5.94,
    "SEP": 6.31,
    "OCT": 6.32,
    "NOV": 6.22,
    "DEC": 5.89
  },
  {
    "LONG": -37.4,
    "LAT": -6.67,
    "CIDADE": "Serra Negra do Norte",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.83,
    "FEB": 6.07,
    "MAR": 6.17,
    "APR": 5.99,
    "MAY": 5.47,
    "JUN": 5.07,
    "JUL": 5.37,
    "AUG": 6.05,
    "SEP": 6.42,
    "OCT": 6.47,
    "NOV": 6.41,
    "DEC": 5.96
  },
  {
    "LONG": -3.72,
    "LAT": -6.71,
    "CIDADE": "São João do Sabugi",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.88,
    "FEB": 6.08,
    "MAR": 6.2,
    "APR": 6,
    "MAY": 5.48,
    "JUN": 5.06,
    "JUL": 5.36,
    "AUG": 6.05,
    "SEP": 6.44,
    "OCT": 6.49,
    "NOV": 6.4,
    "DEC": 6.02
  },
  {
    "LONG": -36.95,
    "LAT": -6.7,
    "CIDADE": "Ouro Branco",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.91,
    "JAN": 5.85,
    "FEB": 6.03,
    "MAR": 6.22,
    "APR": 6,
    "MAY": 5.43,
    "JUN": 5.01,
    "JUL": 5.3,
    "AUG": 6.01,
    "SEP": 6.37,
    "OCT": 6.43,
    "NOV": 6.34,
    "DEC": 5.99
  },
  {
    "LONG": -36.66,
    "LAT": -6.69,
    "CIDADE": "Parelhas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.79,
    "JAN": 5.78,
    "FEB": 5.91,
    "MAR": 6.03,
    "APR": 5.85,
    "MAY": 5.32,
    "JUN": 4.91,
    "JUL": 5.19,
    "AUG": 5.88,
    "SEP": 6.26,
    "OCT": 6.28,
    "NOV": 6.22,
    "DEC": 5.88
  },
  {
    "LONG": -36.78,
    "LAT": -6.59,
    "CIDADE": "Jardim do Seridó",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.83,
    "JAN": 5.78,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.86,
    "MAY": 5.4,
    "JUN": 4.88,
    "JUL": 5.25,
    "AUG": 5.96,
    "SEP": 6.36,
    "OCT": 6.4,
    "NOV": 6.25,
    "DEC": 5.85
  },
  {
    "LONG": -36.59,
    "LAT": -6.56,
    "CIDADE": "Carnaúba dos Dantas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.7,
    "JAN": 5.68,
    "FEB": 5.72,
    "MAR": 5.94,
    "APR": 5.65,
    "MAY": 5.18,
    "JUN": 4.79,
    "JUL": 5.09,
    "AUG": 5.8,
    "SEP": 6.19,
    "OCT": 6.29,
    "NOV": 6.23,
    "DEC": 5.78
  },
  {
    "LONG": -38.31,
    "LAT": -6.48,
    "CIDADE": "Paraná",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.78,
    "FEB": 5.87,
    "MAR": 6.01,
    "APR": 5.8,
    "MAY": 5.45,
    "JUN": 5.25,
    "JUL": 5.56,
    "AUG": 6.26,
    "SEP": 6.5,
    "OCT": 6.48,
    "NOV": 6.45,
    "DEC": 5.9
  },
  {
    "LONG": -38.18,
    "LAT": -6.46,
    "CIDADE": "Tenente Ananias",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.76,
    "FEB": 5.82,
    "MAR": 6.05,
    "APR": 5.86,
    "MAY": 5.51,
    "JUN": 5.27,
    "JUL": 5.55,
    "AUG": 6.25,
    "SEP": 6.51,
    "OCT": 6.47,
    "NOV": 6.48,
    "DEC": 5.9
  },
  {
    "LONG": -37.27,
    "LAT": -6.46,
    "CIDADE": "Timbaúba dos Batistas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.87,
    "FEB": 6.11,
    "MAR": 6.17,
    "APR": 6.01,
    "MAY": 5.55,
    "JUN": 5.11,
    "JUL": 5.39,
    "AUG": 6.06,
    "SEP": 6.43,
    "OCT": 6.48,
    "NOV": 6.32,
    "DEC": 5.95
  },
  {
    "LONG": -37.1,
    "LAT": -6.46,
    "CIDADE": "Caicó",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.91,
    "JAN": 5.9,
    "FEB": 6.04,
    "MAR": 6.14,
    "APR": 5.95,
    "MAY": 5.44,
    "JUN": 5.04,
    "JUL": 5.32,
    "AUG": 6.01,
    "SEP": 6.42,
    "OCT": 6.44,
    "NOV": 6.29,
    "DEC": 5.99
  },
  {
    "LONG": -35.95,
    "LAT": -6.47,
    "CIDADE": "Japi",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.44,
    "JAN": 5.49,
    "FEB": 5.63,
    "MAR": 5.78,
    "APR": 5.46,
    "MAY": 5.02,
    "JUN": 4.62,
    "JUL": 4.77,
    "AUG": 5.48,
    "SEP": 5.77,
    "OCT": 5.85,
    "NOV": 5.92,
    "DEC": 5.53
  },
  {
    "LONG": -35.43,
    "LAT": -6.48,
    "CIDADE": "Nova Cruz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.46,
    "JAN": 5.53,
    "FEB": 5.67,
    "MAR": 5.87,
    "APR": 5.51,
    "MAY": 5.07,
    "JUN": 4.65,
    "JUL": 4.81,
    "AUG": 5.44,
    "SEP": 5.73,
    "OCT": 5.8,
    "NOV": 5.9,
    "DEC": 5.58
  },
  {
    "LONG": -35.28,
    "LAT": -6.49,
    "CIDADE": "Montanhas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.53,
    "JAN": 5.67,
    "FEB": 5.87,
    "MAR": 6.02,
    "APR": 5.55,
    "MAY": 5.07,
    "JUN": 4.64,
    "JUL": 4.75,
    "AUG": 5.44,
    "SEP": 5.79,
    "OCT": 5.87,
    "NOV": 5.97,
    "DEC": 5.73
  },
  {
    "LONG": -38.32,
    "LAT": -6.4,
    "CIDADE": "Major Sales",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.75,
    "FEB": 5.84,
    "MAR": 6.01,
    "APR": 5.78,
    "MAY": 5.45,
    "JUN": 5.23,
    "JUL": 5.6,
    "AUG": 6.28,
    "SEP": 6.52,
    "OCT": 6.46,
    "NOV": 6.44,
    "DEC": 5.9
  },
  {
    "LONG": -38.39,
    "LAT": -6.41,
    "CIDADE": "Luís Gomes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.75,
    "FEB": 5.84,
    "MAR": 6.01,
    "APR": 5.78,
    "MAY": 5.45,
    "JUN": 5.23,
    "JUL": 5.6,
    "AUG": 6.28,
    "SEP": 6.52,
    "OCT": 6.46,
    "NOV": 6.44,
    "DEC": 5.9
  },
  {
    "LONG": -38.01,
    "LAT": -6.41,
    "CIDADE": "Alexandria",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.99,
    "JAN": 5.84,
    "FEB": 5.92,
    "MAR": 6.12,
    "APR": 5.94,
    "MAY": 5.52,
    "JUN": 5.25,
    "JUL": 5.57,
    "AUG": 6.27,
    "SEP": 6.53,
    "OCT": 6.51,
    "NOV": 6.43,
    "DEC": 5.93
  },
  {
    "LONG": -37.35,
    "LAT": -6.38,
    "CIDADE": "Jardim de Piranhas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.93,
    "FEB": 6.09,
    "MAR": 6.21,
    "APR": 6.01,
    "MAY": 5.54,
    "JUN": 5.12,
    "JUL": 5.43,
    "AUG": 6.13,
    "SEP": 6.46,
    "OCT": 6.48,
    "NOV": 6.35,
    "DEC": 5.91
  },
  {
    "LONG": -37.19,
    "LAT": -6.38,
    "CIDADE": "São Fernando",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.93,
    "JAN": 5.88,
    "FEB": 5.98,
    "MAR": 6.1,
    "APR": 5.98,
    "MAY": 5.48,
    "JUN": 5.1,
    "JUL": 5.4,
    "AUG": 6.08,
    "SEP": 6.47,
    "OCT": 6.46,
    "NOV": 6.34,
    "DEC": 5.92
  },
  {
    "LONG": -36.88,
    "LAT": -0.64,
    "CIDADE": "São José do Seridó",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.77,
    "FEB": 5.89,
    "MAR": 6.07,
    "APR": 5.84,
    "MAY": 5.33,
    "JUN": 4.95,
    "JUL": 5.2,
    "AUG": 5.91,
    "SEP": 6.33,
    "OCT": 6.35,
    "NOV": 6.22,
    "DEC": 5.86
  },
  {
    "LONG": -3.68,
    "LAT": -6.41,
    "CIDADE": "Cruzeta",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.78,
    "JAN": 5.74,
    "FEB": 5.87,
    "MAR": 6.03,
    "APR": 5.77,
    "MAY": 5.31,
    "JUN": 4.94,
    "JUL": 5.17,
    "AUG": 5.85,
    "SEP": 6.31,
    "OCT": 6.36,
    "NOV": 6.25,
    "DEC": 5.79
  },
  {
    "LONG": -36.64,
    "LAT": -6.44,
    "CIDADE": "Acari",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.76,
    "JAN": 5.76,
    "FEB": 5.83,
    "MAR": 5.98,
    "APR": 5.74,
    "MAY": 5.26,
    "JUN": 4.9,
    "JUL": 5.13,
    "AUG": 5.81,
    "SEP": 6.28,
    "OCT": 6.38,
    "NOV": 6.27,
    "DEC": 5.83
  },
  {
    "LONG": -36.22,
    "LAT": -6.39,
    "CIDADE": "Coronel Ezequiel",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.65,
    "JAN": 5.69,
    "FEB": 5.8,
    "MAR": 5.91,
    "APR": 5.65,
    "MAY": 5.18,
    "JUN": 4.8,
    "JUL": 4.98,
    "AUG": 5.69,
    "SEP": 6.06,
    "OCT": 6.18,
    "NOV": 6.15,
    "DEC": 5.73
  },
  {
    "LONG": -36.2,
    "LAT": -6.42,
    "CIDADE": "Jaçanã",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.65,
    "JAN": 5.69,
    "FEB": 5.8,
    "MAR": 5.91,
    "APR": 5.65,
    "MAY": 5.18,
    "JUN": 4.8,
    "JUL": 4.98,
    "AUG": 5.69,
    "SEP": 6.06,
    "OCT": 6.18,
    "NOV": 6.15,
    "DEC": 5.73
  },
  {
    "LONG": -35.7,
    "LAT": -6.42,
    "CIDADE": "Serra de São Bento",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.58,
    "FEB": 5.79,
    "MAR": 5.98,
    "APR": 5.61,
    "MAY": 5.13,
    "JUN": 4.75,
    "JUL": 4.86,
    "AUG": 5.55,
    "SEP": 5.82,
    "OCT": 5.84,
    "NOV": 5.94,
    "DEC": 5.63
  },
  {
    "LONG": -35.78,
    "LAT": -6.44,
    "CIDADE": "Monte das Gameleiras",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.58,
    "FEB": 5.79,
    "MAR": 5.98,
    "APR": 5.61,
    "MAY": 5.13,
    "JUN": 4.75,
    "JUL": 4.86,
    "AUG": 5.55,
    "SEP": 5.82,
    "OCT": 5.84,
    "NOV": 5.94,
    "DEC": 5.63
  },
  {
    "LONG": -35.64,
    "LAT": -6.43,
    "CIDADE": "Passa e Fica",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.49,
    "JAN": 5.52,
    "FEB": 5.67,
    "MAR": 5.86,
    "APR": 5.57,
    "MAY": 5.14,
    "JUN": 4.77,
    "JUL": 4.92,
    "AUG": 5.52,
    "SEP": 5.76,
    "OCT": 5.79,
    "NOV": 5.84,
    "DEC": 5.47
  },
  {
    "LONG": -35.6,
    "LAT": -6.39,
    "CIDADE": "Lagoa d'Anta",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.48,
    "JAN": 5.51,
    "FEB": 5.69,
    "MAR": 5.87,
    "APR": 5.55,
    "MAY": 5.1,
    "JUN": 4.72,
    "JUL": 4.87,
    "AUG": 5.49,
    "SEP": 5.75,
    "OCT": 5.79,
    "NOV": 5.87,
    "DEC": 5.54
  },
  {
    "LONG": -35.22,
    "LAT": -0.64,
    "CIDADE": "Pedro Velho",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.5,
    "JAN": 5.6,
    "FEB": 5.82,
    "MAR": 6.01,
    "APR": 5.54,
    "MAY": 5.06,
    "JUN": 4.65,
    "JUL": 4.74,
    "AUG": 5.44,
    "SEP": 5.75,
    "OCT": 5.83,
    "NOV": 5.93,
    "DEC": 5.68
  },
  {
    "LONG": -35.13,
    "LAT": -6.38,
    "CIDADE": "Canguaretama",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.55,
    "JAN": 5.62,
    "FEB": 5.87,
    "MAR": 6.05,
    "APR": 5.59,
    "MAY": 5.08,
    "JUN": 4.67,
    "JUL": 4.77,
    "AUG": 5.47,
    "SEP": 5.85,
    "OCT": 5.91,
    "NOV": 5.94,
    "DEC": 5.74
  },
  {
    "LONG": -35.01,
    "LAT": -6.37,
    "CIDADE": "Baía Formosa",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.63,
    "JAN": 5.68,
    "FEB": 5.92,
    "MAR": 6.1,
    "APR": 5.68,
    "MAY": 5.21,
    "JUN": 4.79,
    "JUL": 4.94,
    "AUG": 5.66,
    "SEP": 5.91,
    "OCT": 5.97,
    "NOV": 5.98,
    "DEC": 5.75
  },
  {
    "LONG": -38.49,
    "LAT": -6.32,
    "CIDADE": "Venha-Ver",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.92,
    "JAN": 5.71,
    "FEB": 5.76,
    "MAR": 5.9,
    "APR": 5.7,
    "MAY": 5.44,
    "JUN": 5.25,
    "JUL": 5.64,
    "AUG": 6.31,
    "SEP": 6.53,
    "OCT": 6.46,
    "NOV": 6.46,
    "DEC": 5.87
  },
  {
    "LONG": -38.31,
    "LAT": -6.25,
    "CIDADE": "Riacho de Santana",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.91,
    "JAN": 5.71,
    "FEB": 5.79,
    "MAR": 5.95,
    "APR": 5.75,
    "MAY": 5.44,
    "JUN": 5.23,
    "JUL": 5.62,
    "AUG": 6.26,
    "SEP": 6.51,
    "OCT": 6.43,
    "NOV": 6.39,
    "DEC": 5.87
  },
  {
    "LONG": -38.28,
    "LAT": -6.31,
    "CIDADE": "José da Penha",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.75,
    "FEB": 5.9,
    "MAR": 6.01,
    "APR": 5.86,
    "MAY": 5.52,
    "JUN": 5.27,
    "JUL": 5.59,
    "AUG": 6.21,
    "SEP": 6.48,
    "OCT": 6.43,
    "NOV": 6.37,
    "DEC": 5.89
  },
  {
    "LONG": -38.16,
    "LAT": -0.63,
    "CIDADE": "Marcelino Vieira",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.79,
    "FEB": 5.88,
    "MAR": 6.06,
    "APR": 5.92,
    "MAY": 5.49,
    "JUN": 5.21,
    "JUL": 5.57,
    "AUG": 6.24,
    "SEP": 6.5,
    "OCT": 6.45,
    "NOV": 6.37,
    "DEC": 5.87
  },
  {
    "LONG": -38.05,
    "LAT": -0.63,
    "CIDADE": "Pilões",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.98,
    "JAN": 5.83,
    "FEB": 5.87,
    "MAR": 6.12,
    "APR": 5.97,
    "MAY": 5.51,
    "JUN": 5.24,
    "JUL": 5.59,
    "AUG": 6.3,
    "SEP": 6.54,
    "OCT": 6.51,
    "NOV": 6.41,
    "DEC": 5.9
  },
  {
    "LONG": -37.79,
    "LAT": -6.27,
    "CIDADE": "João Dias",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.96,
    "JAN": 5.79,
    "FEB": 5.94,
    "MAR": 6.06,
    "APR": 5.87,
    "MAY": 5.54,
    "JUN": 5.21,
    "JUL": 5.57,
    "AUG": 6.24,
    "SEP": 6.58,
    "OCT": 6.47,
    "NOV": 6.32,
    "DEC": 5.91
  },
  {
    "LONG": -36.51,
    "LAT": -6.26,
    "CIDADE": "Currais Novos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.69,
    "JAN": 5.68,
    "FEB": 5.8,
    "MAR": 5.86,
    "APR": 5.69,
    "MAY": 5.17,
    "JUN": 4.85,
    "JUL": 5.07,
    "AUG": 5.76,
    "SEP": 6.22,
    "OCT": 6.25,
    "NOV": 6.2,
    "DEC": 5.8
  },
  {
    "LONG": -36.09,
    "LAT": -6.34,
    "CIDADE": "São Bento do Trairí",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.47,
    "JAN": 5.48,
    "FEB": 5.68,
    "MAR": 5.84,
    "APR": 5.56,
    "MAY": 5.1,
    "JUN": 4.58,
    "JUL": 4.75,
    "AUG": 5.47,
    "SEP": 5.81,
    "OCT": 5.9,
    "NOV": 5.95,
    "DEC": 5.52
  },
  {
    "LONG": -35.71,
    "LAT": -6.32,
    "CIDADE": "São José do Campestre",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.53,
    "JAN": 5.57,
    "FEB": 5.77,
    "MAR": 5.93,
    "APR": 5.62,
    "MAY": 5.17,
    "JUN": 4.74,
    "JUL": 4.84,
    "AUG": 5.52,
    "SEP": 5.78,
    "OCT": 5.85,
    "NOV": 5.93,
    "DEC": 5.6
  },
  {
    "LONG": -35.48,
    "LAT": -6.31,
    "CIDADE": "Santo Antônio",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.52,
    "JAN": 5.51,
    "FEB": 5.76,
    "MAR": 6.02,
    "APR": 5.55,
    "MAY": 5.12,
    "JUN": 4.72,
    "JUL": 4.88,
    "AUG": 5.47,
    "SEP": 5.78,
    "OCT": 5.88,
    "NOV": 6,
    "DEC": 5.59
  },
  {
    "LONG": -35.5,
    "LAT": -6.28,
    "CIDADE": "Serrinha",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.52,
    "JAN": 5.51,
    "FEB": 5.76,
    "MAR": 6.02,
    "APR": 5.55,
    "MAY": 5.12,
    "JUN": 4.72,
    "JUL": 4.88,
    "AUG": 5.47,
    "SEP": 5.78,
    "OCT": 5.88,
    "NOV": 6,
    "DEC": 5.59
  },
  {
    "LONG": -35.31,
    "LAT": -6.33,
    "CIDADE": "Espírito Santo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.55,
    "JAN": 5.61,
    "FEB": 5.86,
    "MAR": 6.08,
    "APR": 5.56,
    "MAY": 5.16,
    "JUN": 4.68,
    "JUL": 4.77,
    "AUG": 5.53,
    "SEP": 5.78,
    "OCT": 5.92,
    "NOV": 6.04,
    "DEC": 5.64
  },
  {
    "LONG": -35.37,
    "LAT": -6.35,
    "CIDADE": "Várzea",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.55,
    "JAN": 5.61,
    "FEB": 5.86,
    "MAR": 6.08,
    "APR": 5.56,
    "MAY": 5.16,
    "JUN": 4.68,
    "JUL": 4.77,
    "AUG": 5.53,
    "SEP": 5.78,
    "OCT": 5.92,
    "NOV": 6.04,
    "DEC": 5.64
  },
  {
    "LONG": -35.33,
    "LAT": -6.27,
    "CIDADE": "Jundiá",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.55,
    "JAN": 5.61,
    "FEB": 5.86,
    "MAR": 6.08,
    "APR": 5.56,
    "MAY": 5.16,
    "JUN": 4.68,
    "JUL": 4.77,
    "AUG": 5.53,
    "SEP": 5.78,
    "OCT": 5.92,
    "NOV": 6.04,
    "DEC": 5.64
  },
  {
    "LONG": -35.37,
    "LAT": -6.27,
    "CIDADE": "Passagem",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.55,
    "JAN": 5.61,
    "FEB": 5.86,
    "MAR": 6.08,
    "APR": 5.56,
    "MAY": 5.16,
    "JUN": 4.68,
    "JUL": 4.77,
    "AUG": 5.53,
    "SEP": 5.78,
    "OCT": 5.92,
    "NOV": 6.04,
    "DEC": 5.64
  },
  {
    "LONG": -35.21,
    "LAT": -6.27,
    "CIDADE": "Goianinha",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.61,
    "FEB": 5.85,
    "MAR": 6.06,
    "APR": 5.57,
    "MAY": 5.1,
    "JUN": 4.66,
    "JUL": 4.74,
    "AUG": 5.49,
    "SEP": 5.82,
    "OCT": 5.9,
    "NOV": 6,
    "DEC": 5.71
  },
  {
    "LONG": -35.08,
    "LAT": -6.31,
    "CIDADE": "Vila Flor",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.72,
    "JAN": 5.76,
    "FEB": 6,
    "MAR": 6.19,
    "APR": 5.72,
    "MAY": 5.27,
    "JUN": 4.83,
    "JUL": 4.98,
    "AUG": 5.73,
    "SEP": 6.06,
    "OCT": 6.13,
    "NOV": 6.12,
    "DEC": 5.88
  },
  {
    "LONG": -38.44,
    "LAT": -6.25,
    "CIDADE": "Coronel João Pessoa",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.89,
    "JAN": 5.63,
    "FEB": 5.74,
    "MAR": 5.83,
    "APR": 5.68,
    "MAY": 5.39,
    "JUN": 5.31,
    "JUL": 5.64,
    "AUG": 6.31,
    "SEP": 6.51,
    "OCT": 6.45,
    "NOV": 6.42,
    "DEC": 5.82
  },
  {
    "LONG": -3.85,
    "LAT": -6.2,
    "CIDADE": "São Miguel",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.89,
    "JAN": 5.63,
    "FEB": 5.74,
    "MAR": 5.83,
    "APR": 5.68,
    "MAY": 5.39,
    "JUN": 5.31,
    "JUL": 5.64,
    "AUG": 6.31,
    "SEP": 6.51,
    "OCT": 6.45,
    "NOV": 6.42,
    "DEC": 5.82
  },
  {
    "LONG": -38.29,
    "LAT": -6.2,
    "CIDADE": "Água Nova",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.69,
    "FEB": 5.89,
    "MAR": 6.01,
    "APR": 5.83,
    "MAY": 5.52,
    "JUN": 5.27,
    "JUL": 5.59,
    "AUG": 6.25,
    "SEP": 6.48,
    "OCT": 6.45,
    "NOV": 6.37,
    "DEC": 5.87
  },
  {
    "LONG": -38.22,
    "LAT": -6.19,
    "CIDADE": "Rafael Fernandes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.69,
    "FEB": 5.89,
    "MAR": 6.01,
    "APR": 5.83,
    "MAY": 5.52,
    "JUN": 5.27,
    "JUL": 5.59,
    "AUG": 6.25,
    "SEP": 6.48,
    "OCT": 6.45,
    "NOV": 6.37,
    "DEC": 5.87
  },
  {
    "LONG": -37.84,
    "LAT": -6.16,
    "CIDADE": "Frutuoso Gomes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.81,
    "FEB": 5.9,
    "MAR": 6.03,
    "APR": 5.93,
    "MAY": 5.57,
    "JUN": 5.26,
    "JUL": 5.6,
    "AUG": 6.24,
    "SEP": 6.54,
    "OCT": 6.51,
    "NOV": 6.39,
    "DEC": 5.9
  },
  {
    "LONG": -37.88,
    "LAT": -6.21,
    "CIDADE": "Antônio Martins",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.81,
    "FEB": 5.9,
    "MAR": 6.03,
    "APR": 5.93,
    "MAY": 5.57,
    "JUN": 5.26,
    "JUL": 5.6,
    "AUG": 6.24,
    "SEP": 6.54,
    "OCT": 6.51,
    "NOV": 6.39,
    "DEC": 5.9
  },
  {
    "LONG": -3.67,
    "LAT": -6.22,
    "CIDADE": "São Vicente",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.75,
    "JAN": 5.71,
    "FEB": 5.78,
    "MAR": 5.9,
    "APR": 5.67,
    "MAY": 5.28,
    "JUN": 4.89,
    "JUL": 5.16,
    "AUG": 5.9,
    "SEP": 6.28,
    "OCT": 6.34,
    "NOV": 6.25,
    "DEC": 5.79
  },
  {
    "LONG": -36.18,
    "LAT": -6.24,
    "CIDADE": "Campo Redondo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.53,
    "JAN": 5.51,
    "FEB": 5.7,
    "MAR": 5.76,
    "APR": 5.57,
    "MAY": 5.16,
    "JUN": 4.72,
    "JUL": 4.92,
    "AUG": 5.55,
    "SEP": 5.94,
    "OCT": 5.99,
    "NOV": 5.98,
    "DEC": 5.5
  },
  {
    "LONG": -36.02,
    "LAT": -6.23,
    "CIDADE": "Santa Cruz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.47,
    "JAN": 5.46,
    "FEB": 5.7,
    "MAR": 5.76,
    "APR": 5.52,
    "MAY": 5.13,
    "JUN": 4.67,
    "JUL": 4.85,
    "AUG": 5.45,
    "SEP": 5.83,
    "OCT": 5.89,
    "NOV": 5.9,
    "DEC": 5.5
  },
  {
    "LONG": -35.8,
    "LAT": -6.2,
    "CIDADE": "Tangará",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.48,
    "JAN": 5.53,
    "FEB": 5.71,
    "MAR": 5.83,
    "APR": 5.61,
    "MAY": 5.17,
    "JUN": 4.7,
    "JUL": 4.83,
    "AUG": 5.47,
    "SEP": 5.76,
    "OCT": 5.8,
    "NOV": 5.85,
    "DEC": 5.49
  },
  {
    "LONG": -35.6,
    "LAT": -6.16,
    "CIDADE": "Januário Cicco",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.49,
    "JAN": 5.46,
    "FEB": 5.68,
    "MAR": 5.86,
    "APR": 5.61,
    "MAY": 5.16,
    "JUN": 4.76,
    "JUL": 4.87,
    "AUG": 5.51,
    "SEP": 5.77,
    "OCT": 5.82,
    "NOV": 5.9,
    "DEC": 5.51
  },
  {
    "LONG": -35.44,
    "LAT": -6.16,
    "CIDADE": "Lagoa de Pedras",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.62,
    "FEB": 5.79,
    "MAR": 5.97,
    "APR": 5.6,
    "MAY": 5.12,
    "JUN": 4.77,
    "JUL": 4.89,
    "AUG": 5.54,
    "SEP": 5.8,
    "OCT": 5.87,
    "NOV": 5.95,
    "DEC": 5.58
  },
  {
    "LONG": -35.36,
    "LAT": -6.2,
    "CIDADE": "Brejinho",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.56,
    "JAN": 5.65,
    "FEB": 5.82,
    "MAR": 6.07,
    "APR": 5.6,
    "MAY": 5.14,
    "JUN": 4.72,
    "JUL": 4.8,
    "AUG": 5.5,
    "SEP": 5.82,
    "OCT": 5.9,
    "NOV": 6.02,
    "DEC": 5.7
  },
  {
    "LONG": -35.16,
    "LAT": -0.62,
    "CIDADE": "Arês",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.66,
    "JAN": 5.69,
    "FEB": 5.99,
    "MAR": 6.14,
    "APR": 5.67,
    "MAY": 5.27,
    "JUN": 4.74,
    "JUL": 4.89,
    "AUG": 5.64,
    "SEP": 5.95,
    "OCT": 6.07,
    "NOV": 6.07,
    "DEC": 5.82
  },
  {
    "LONG": -35.13,
    "LAT": -6.16,
    "CIDADE": "Senador Georgino Avelino",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.66,
    "JAN": 5.69,
    "FEB": 5.99,
    "MAR": 6.14,
    "APR": 5.67,
    "MAY": 5.27,
    "JUN": 4.74,
    "JUL": 4.89,
    "AUG": 5.64,
    "SEP": 5.95,
    "OCT": 6.07,
    "NOV": 6.07,
    "DEC": 5.82
  },
  {
    "LONG": -35.09,
    "LAT": -6.19,
    "CIDADE": "Tibau do Sul",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.84,
    "JAN": 5.91,
    "FEB": 6.13,
    "MAR": 6.24,
    "APR": 5.8,
    "MAY": 5.43,
    "JUN": 4.9,
    "JUL": 5.08,
    "AUG": 5.91,
    "SEP": 6.17,
    "OCT": 6.22,
    "NOV": 6.3,
    "DEC": 6.03
  },
  {
    "LONG": -38.3,
    "LAT": -6.11,
    "CIDADE": "Encanto",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.88,
    "JAN": 5.58,
    "FEB": 5.72,
    "MAR": 5.86,
    "APR": 5.72,
    "MAY": 5.44,
    "JUN": 5.3,
    "JUL": 5.62,
    "AUG": 6.24,
    "SEP": 6.52,
    "OCT": 6.44,
    "NOV": 6.37,
    "DEC": 5.77
  },
  {
    "LONG": -38.38,
    "LAT": -6.08,
    "CIDADE": "Doutor Severiano",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.88,
    "JAN": 5.58,
    "FEB": 5.72,
    "MAR": 5.86,
    "APR": 5.72,
    "MAY": 5.44,
    "JUN": 5.3,
    "JUL": 5.62,
    "AUG": 6.24,
    "SEP": 6.52,
    "OCT": 6.44,
    "NOV": 6.37,
    "DEC": 5.77
  },
  {
    "LONG": -3.82,
    "LAT": -6.11,
    "CIDADE": "Pau dos Ferros",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.93,
    "JAN": 5.63,
    "FEB": 5.82,
    "MAR": 6.01,
    "APR": 5.83,
    "MAY": 5.54,
    "JUN": 5.29,
    "JUL": 5.63,
    "AUG": 6.25,
    "SEP": 6.47,
    "OCT": 6.46,
    "NOV": 6.38,
    "DEC": 5.82
  },
  {
    "LONG": -38.12,
    "LAT": -6.07,
    "CIDADE": "Francisco Dantas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.93,
    "JAN": 5.69,
    "FEB": 5.83,
    "MAR": 6,
    "APR": 5.79,
    "MAY": 5.55,
    "JUN": 5.29,
    "JUL": 5.64,
    "AUG": 6.24,
    "SEP": 6.45,
    "OCT": 6.48,
    "NOV": 6.37,
    "DEC": 5.82
  },
  {
    "LONG": -37.91,
    "LAT": -6.08,
    "CIDADE": "Martins",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6,
    "JAN": 5.85,
    "FEB": 5.87,
    "MAR": 6.02,
    "APR": 5.9,
    "MAY": 5.55,
    "JUN": 5.33,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 6.56,
    "OCT": 6.56,
    "NOV": 6.46,
    "DEC": 5.91
  },
  {
    "LONG": -37.96,
    "LAT": -6.11,
    "CIDADE": "Serrinha dos Pintos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6,
    "JAN": 5.85,
    "FEB": 5.87,
    "MAR": 6.02,
    "APR": 5.9,
    "MAY": 5.55,
    "JUN": 5.33,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 6.56,
    "OCT": 6.56,
    "NOV": 6.46,
    "DEC": 5.91
  },
  {
    "LONG": -37.81,
    "LAT": -6.11,
    "CIDADE": "Lucrécia",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.99,
    "JAN": 5.86,
    "FEB": 5.87,
    "MAR": 6.04,
    "APR": 5.95,
    "MAY": 5.57,
    "JUN": 5.3,
    "JUL": 5.65,
    "AUG": 6.28,
    "SEP": 6.55,
    "OCT": 6.53,
    "NOV": 6.42,
    "DEC": 5.87
  },
  {
    "LONG": -37.72,
    "LAT": -6.07,
    "CIDADE": "Rafael Godeiro",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.01,
    "JAN": 5.9,
    "FEB": 5.92,
    "MAR": 6.12,
    "APR": 6.02,
    "MAY": 5.65,
    "JUN": 5.35,
    "JUL": 5.64,
    "AUG": 6.26,
    "SEP": 6.54,
    "OCT": 6.5,
    "NOV": 6.4,
    "DEC": 5.88
  },
  {
    "LONG": -37.76,
    "LAT": -6.15,
    "CIDADE": "Almino Afonso",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.01,
    "JAN": 5.9,
    "FEB": 5.92,
    "MAR": 6.12,
    "APR": 6.02,
    "MAY": 5.65,
    "JUN": 5.35,
    "JUL": 5.64,
    "AUG": 6.26,
    "SEP": 6.54,
    "OCT": 6.5,
    "NOV": 6.4,
    "DEC": 5.88
  },
  {
    "LONG": -37.64,
    "LAT": -0.61,
    "CIDADE": "Patu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.02,
    "JAN": 5.91,
    "FEB": 6.02,
    "MAR": 6.15,
    "APR": 6.01,
    "MAY": 5.67,
    "JUN": 5.37,
    "JUL": 5.6,
    "AUG": 6.18,
    "SEP": 6.59,
    "OCT": 6.49,
    "NOV": 6.38,
    "DEC": 5.91
  },
  {
    "LONG": -37.52,
    "LAT": -6.07,
    "CIDADE": "Messias Targino",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.99,
    "JAN": 5.86,
    "FEB": 5.98,
    "MAR": 6.1,
    "APR": 6,
    "MAY": 5.62,
    "JUN": 5.29,
    "JUL": 5.55,
    "AUG": 6.17,
    "SEP": 6.56,
    "OCT": 6.48,
    "NOV": 6.39,
    "DEC": 5.89
  },
  {
    "LONG": -36.81,
    "LAT": -6.12,
    "CIDADE": "Florânia",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.77,
    "JAN": 5.63,
    "FEB": 5.78,
    "MAR": 5.91,
    "APR": 5.76,
    "MAY": 5.3,
    "JUN": 5.02,
    "JUL": 5.33,
    "AUG": 6.09,
    "SEP": 6.42,
    "OCT": 6.32,
    "NOV": 6.15,
    "DEC": 5.59
  },
  {
    "LONG": -36.72,
    "LAT": -6.15,
    "CIDADE": "Tenente Laurentino Cruz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.73,
    "JAN": 5.6,
    "FEB": 5.6,
    "MAR": 5.8,
    "APR": 5.61,
    "MAY": 5.26,
    "JUN": 4.96,
    "JUL": 5.32,
    "AUG": 6.06,
    "SEP": 6.38,
    "OCT": 6.33,
    "NOV": 6.2,
    "DEC": 5.59
  },
  {
    "LONG": -36.47,
    "LAT": -6.09,
    "CIDADE": "Lagoa Nova",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.6,
    "JAN": 5.52,
    "FEB": 5.69,
    "MAR": 5.81,
    "APR": 5.53,
    "MAY": 5.15,
    "JUN": 4.82,
    "JUL": 5.02,
    "AUG": 5.76,
    "SEP": 6.14,
    "OCT": 6.12,
    "NOV": 6.1,
    "DEC": 5.61
  },
  {
    "LONG": -36.12,
    "LAT": -6.15,
    "CIDADE": "Lajes Pintadas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.53,
    "JAN": 5.46,
    "FEB": 5.67,
    "MAR": 5.71,
    "APR": 5.58,
    "MAY": 5.15,
    "JUN": 4.76,
    "JUL": 5,
    "AUG": 5.64,
    "SEP": 5.95,
    "OCT": 6.01,
    "NOV": 5.97,
    "DEC": 5.49
  },
  {
    "LONG": -3.59,
    "LAT": -0.61,
    "CIDADE": "Sítio Novo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.5,
    "JAN": 5.51,
    "FEB": 5.75,
    "MAR": 5.79,
    "APR": 5.61,
    "MAY": 5.18,
    "JUN": 4.73,
    "JUL": 4.92,
    "AUG": 5.52,
    "SEP": 5.84,
    "OCT": 5.86,
    "NOV": 5.9,
    "DEC": 5.45
  },
  {
    "LONG": -35.71,
    "LAT": -6.11,
    "CIDADE": "Serra Caiada",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.5,
    "JAN": 5.48,
    "FEB": 5.67,
    "MAR": 5.78,
    "APR": 5.6,
    "MAY": 5.19,
    "JUN": 4.73,
    "JUL": 4.9,
    "AUG": 5.57,
    "SEP": 5.78,
    "OCT": 5.82,
    "NOV": 5.91,
    "DEC": 5.55
  },
  {
    "LONG": -35.48,
    "LAT": -6.12,
    "CIDADE": "Lagoa Salgada",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.52,
    "JAN": 5.55,
    "FEB": 5.71,
    "MAR": 5.97,
    "APR": 5.58,
    "MAY": 5.13,
    "JUN": 4.72,
    "JUL": 4.84,
    "AUG": 5.49,
    "SEP": 5.77,
    "OCT": 5.84,
    "NOV": 5.96,
    "DEC": 5.66
  },
  {
    "LONG": -35.33,
    "LAT": -6.07,
    "CIDADE": "Monte Alegre",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.6,
    "JAN": 5.73,
    "FEB": 5.89,
    "MAR": 6.07,
    "APR": 5.63,
    "MAY": 5.16,
    "JUN": 4.73,
    "JUL": 4.79,
    "AUG": 5.49,
    "SEP": 5.87,
    "OCT": 5.96,
    "NOV": 6.1,
    "DEC": 5.73
  },
  {
    "LONG": -3.52,
    "LAT": -6.09,
    "CIDADE": "Nísia Floresta",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.68,
    "JAN": 5.77,
    "FEB": 6.03,
    "MAR": 6.12,
    "APR": 5.69,
    "MAY": 5.26,
    "JUN": 4.77,
    "JUL": 4.88,
    "AUG": 5.61,
    "SEP": 5.97,
    "OCT": 6.08,
    "NOV": 6.13,
    "DEC": 5.82
  },
  {
    "LONG": -35.24,
    "LAT": -0.61,
    "CIDADE": "São José de Mipibu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.68,
    "JAN": 5.77,
    "FEB": 6.03,
    "MAR": 6.12,
    "APR": 5.69,
    "MAY": 5.26,
    "JUN": 4.77,
    "JUL": 4.88,
    "AUG": 5.61,
    "SEP": 5.97,
    "OCT": 6.08,
    "NOV": 6.13,
    "DEC": 5.82
  },
  {
    "LONG": -38.15,
    "LAT": -5.98,
    "CIDADE": "São Francisco do Oeste",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.93,
    "JAN": 5.61,
    "FEB": 5.82,
    "MAR": 5.99,
    "APR": 5.77,
    "MAY": 5.52,
    "JUN": 5.33,
    "JUL": 5.66,
    "AUG": 6.28,
    "SEP": 6.48,
    "OCT": 6.48,
    "NOV": 6.37,
    "DEC": 5.81
  },
  {
    "LONG": -37.95,
    "LAT": -5.98,
    "CIDADE": "Viçosa",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.03,
    "JAN": 5.81,
    "FEB": 5.98,
    "MAR": 6.13,
    "APR": 5.98,
    "MAY": 5.63,
    "JUN": 5.38,
    "JUL": 5.7,
    "AUG": 6.3,
    "SEP": 6.54,
    "OCT": 6.55,
    "NOV": 6.45,
    "DEC": 5.87
  },
  {
    "LONG": -37.99,
    "LAT": -6.02,
    "CIDADE": "Portalegre",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.03,
    "JAN": 5.81,
    "FEB": 5.98,
    "MAR": 6.13,
    "APR": 5.98,
    "MAY": 5.63,
    "JUN": 5.38,
    "JUL": 5.7,
    "AUG": 6.3,
    "SEP": 6.54,
    "OCT": 6.55,
    "NOV": 6.45,
    "DEC": 5.87
  },
  {
    "LONG": -37.82,
    "LAT": -5.98,
    "CIDADE": "Umarizal",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.03,
    "JAN": 5.85,
    "FEB": 5.98,
    "MAR": 6.15,
    "APR": 6.01,
    "MAY": 5.69,
    "JUN": 5.37,
    "JUL": 5.71,
    "AUG": 6.3,
    "SEP": 6.52,
    "OCT": 6.54,
    "NOV": 6.43,
    "DEC": 5.86
  },
  {
    "LONG": -37.41,
    "LAT": -6.02,
    "CIDADE": "Janduís",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.77,
    "FEB": 5.94,
    "MAR": 6.07,
    "APR": 5.98,
    "MAY": 5.61,
    "JUN": 5.23,
    "JUL": 5.58,
    "AUG": 6.22,
    "SEP": 6.53,
    "OCT": 6.48,
    "NOV": 6.36,
    "DEC": 5.84
  },
  {
    "LONG": -37.02,
    "LAT": -6.04,
    "CIDADE": "Jucurutu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.89,
    "JAN": 5.77,
    "FEB": 5.96,
    "MAR": 6.16,
    "APR": 5.93,
    "MAY": 5.53,
    "JUN": 5.14,
    "JUL": 5.38,
    "AUG": 6.03,
    "SEP": 6.41,
    "OCT": 6.36,
    "NOV": 6.28,
    "DEC": 5.79
  },
  {
    "LONG": -36.41,
    "LAT": -0.6,
    "CIDADE": "Bodó",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.84,
    "JAN": 5.76,
    "FEB": 5.88,
    "MAR": 6,
    "APR": 5.7,
    "MAY": 5.4,
    "JUN": 5.05,
    "JUL": 5.33,
    "AUG": 6.04,
    "SEP": 6.37,
    "OCT": 6.42,
    "NOV": 6.3,
    "DEC": 5.79
  },
  {
    "LONG": -36.35,
    "LAT": -6.04,
    "CIDADE": "Cerro Corá",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.78,
    "JAN": 5.72,
    "FEB": 5.82,
    "MAR": 5.93,
    "APR": 5.66,
    "MAY": 5.39,
    "JUN": 5.02,
    "JUL": 5.27,
    "AUG": 5.97,
    "SEP": 6.3,
    "OCT": 6.35,
    "NOV": 6.25,
    "DEC": 5.71
  },
  {
    "LONG": -3.61,
    "LAT": -5.97,
    "CIDADE": "São Tomé",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.56,
    "FEB": 5.7,
    "MAR": 5.8,
    "APR": 5.6,
    "MAY": 5.2,
    "JUN": 4.73,
    "JUL": 4.96,
    "AUG": 5.55,
    "SEP": 5.92,
    "OCT": 5.95,
    "NOV": 5.95,
    "DEC": 5.57
  },
  {
    "LONG": -3.59,
    "LAT": -5.95,
    "CIDADE": "Barcelona",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.52,
    "JAN": 5.53,
    "FEB": 5.66,
    "MAR": 5.79,
    "APR": 5.61,
    "MAY": 5.18,
    "JUN": 4.77,
    "JUL": 4.95,
    "AUG": 5.53,
    "SEP": 5.83,
    "OCT": 5.89,
    "NOV": 5.92,
    "DEC": 5.53
  },
  {
    "LONG": -35.87,
    "LAT": -6,
    "CIDADE": "Lagoa de Velhos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.49,
    "JAN": 5.53,
    "FEB": 5.64,
    "MAR": 5.76,
    "APR": 5.58,
    "MAY": 5.16,
    "JUN": 4.73,
    "JUL": 4.94,
    "AUG": 5.55,
    "SEP": 5.78,
    "OCT": 5.86,
    "NOV": 5.9,
    "DEC": 5.51
  },
  {
    "LONG": -35.69,
    "LAT": -0.6,
    "CIDADE": "Senador Elói de Souza",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.56,
    "JAN": 5.66,
    "FEB": 5.74,
    "MAR": 5.9,
    "APR": 5.64,
    "MAY": 5.18,
    "JUN": 4.77,
    "JUL": 4.92,
    "AUG": 5.59,
    "SEP": 5.84,
    "OCT": 5.9,
    "NOV": 5.98,
    "DEC": 5.62
  },
  {
    "LONG": -35.58,
    "LAT": -5.99,
    "CIDADE": "Bom Jesus",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.58,
    "JAN": 5.68,
    "FEB": 5.78,
    "MAR": 5.97,
    "APR": 5.64,
    "MAY": 5.18,
    "JUN": 4.75,
    "JUL": 4.91,
    "AUG": 5.56,
    "SEP": 5.84,
    "OCT": 5.94,
    "NOV": 6.02,
    "DEC": 5.68
  },
  {
    "LONG": -35.43,
    "LAT": -6.04,
    "CIDADE": "Vera Cruz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.59,
    "JAN": 5.71,
    "FEB": 5.81,
    "MAR": 6.01,
    "APR": 5.64,
    "MAY": 5.18,
    "JUN": 4.73,
    "JUL": 4.88,
    "AUG": 5.53,
    "SEP": 5.85,
    "OCT": 5.96,
    "NOV": 6.04,
    "DEC": 5.7
  },
  {
    "LONG": -3.8,
    "LAT": -5.92,
    "CIDADE": "Taboleiro Grande",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.72,
    "FEB": 5.94,
    "MAR": 6.06,
    "APR": 5.86,
    "MAY": 5.58,
    "JUN": 5.28,
    "JUL": 5.62,
    "AUG": 6.21,
    "SEP": 6.49,
    "OCT": 6.53,
    "NOV": 6.42,
    "DEC": 5.89
  },
  {
    "LONG": -37.95,
    "LAT": -0.59,
    "CIDADE": "Riacho da Cruz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6,
    "JAN": 5.78,
    "FEB": 5.96,
    "MAR": 6.1,
    "APR": 5.94,
    "MAY": 5.64,
    "JUN": 5.34,
    "JUL": 5.67,
    "AUG": 6.28,
    "SEP": 6.49,
    "OCT": 6.54,
    "NOV": 6.43,
    "DEC": 5.87
  },
  {
    "LONG": -3.77,
    "LAT": -0.59,
    "CIDADE": "Olho-d'Água do Borges",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.74,
    "FEB": 5.9,
    "MAR": 6.07,
    "APR": 5.89,
    "MAY": 5.6,
    "JUN": 5.29,
    "JUL": 5.64,
    "AUG": 6.24,
    "SEP": 6.47,
    "OCT": 6.5,
    "NOV": 6.37,
    "DEC": 5.86
  },
  {
    "LONG": -37.31,
    "LAT": -0.59,
    "CIDADE": "Augusto Severo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.8,
    "FEB": 5.95,
    "MAR": 6.1,
    "APR": 5.96,
    "MAY": 5.61,
    "JUN": 5.23,
    "JUL": 5.53,
    "AUG": 6.16,
    "SEP": 6.47,
    "OCT": 6.43,
    "NOV": 6.35,
    "DEC": 5.82
  },
  {
    "LONG": -37.19,
    "LAT": -5.87,
    "CIDADE": "Triunfo Potiguar",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.96,
    "JAN": 5.82,
    "FEB": 6,
    "MAR": 6.16,
    "APR": 5.93,
    "MAY": 5.61,
    "JUN": 5.2,
    "JUL": 5.51,
    "AUG": 6.11,
    "SEP": 6.45,
    "OCT": 6.45,
    "NOV": 6.33,
    "DEC": 5.89
  },
  {
    "LONG": -36.65,
    "LAT": -5.95,
    "CIDADE": "Santana do Matos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.75,
    "FEB": 5.83,
    "MAR": 6.01,
    "APR": 5.77,
    "MAY": 5.43,
    "JUN": 4.98,
    "JUL": 5.31,
    "AUG": 5.95,
    "SEP": 6.31,
    "OCT": 6.37,
    "NOV": 6.25,
    "DEC": 5.8
  },
  {
    "LONG": -35.94,
    "LAT": -5.88,
    "CIDADE": "Ruy Barbosa",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.49,
    "JAN": 5.51,
    "FEB": 5.58,
    "MAR": 5.74,
    "APR": 5.57,
    "MAY": 5.14,
    "JUN": 4.75,
    "JUL": 4.96,
    "AUG": 5.53,
    "SEP": 5.82,
    "OCT": 5.88,
    "NOV": 5.9,
    "DEC": 5.49
  },
  {
    "LONG": -35.76,
    "LAT": -5.9,
    "CIDADE": "São Paulo do Potengi",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.52,
    "JAN": 5.58,
    "FEB": 5.69,
    "MAR": 5.82,
    "APR": 5.57,
    "MAY": 5.18,
    "JUN": 4.75,
    "JUL": 4.98,
    "AUG": 5.54,
    "SEP": 5.81,
    "OCT": 5.86,
    "NOV": 5.93,
    "DEC": 5.57
  },
  {
    "LONG": -35.63,
    "LAT": -5.9,
    "CIDADE": "São Pedro",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.61,
    "FEB": 5.73,
    "MAR": 5.86,
    "APR": 5.6,
    "MAY": 5.17,
    "JUN": 4.76,
    "JUL": 4.91,
    "AUG": 5.55,
    "SEP": 5.82,
    "OCT": 5.88,
    "NOV": 5.96,
    "DEC": 5.59
  },
  {
    "LONG": -35.35,
    "LAT": -5.86,
    "CIDADE": "Macaíba",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.64,
    "JAN": 5.69,
    "FEB": 5.9,
    "MAR": 6.07,
    "APR": 5.65,
    "MAY": 5.28,
    "JUN": 4.83,
    "JUL": 4.91,
    "AUG": 5.65,
    "SEP": 5.96,
    "OCT": 6,
    "NOV": 6.04,
    "DEC": 5.7
  },
  {
    "LONG": -35.27,
    "LAT": -5.91,
    "CIDADE": "Parnamirim",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.65,
    "JAN": 5.72,
    "FEB": 5.91,
    "MAR": 6.05,
    "APR": 5.66,
    "MAY": 5.29,
    "JUN": 4.9,
    "JUL": 4.97,
    "AUG": 5.71,
    "SEP": 5.93,
    "OCT": 5.98,
    "NOV": 6.01,
    "DEC": 5.7
  },
  {
    "LONG": -38.06,
    "LAT": -5.78,
    "CIDADE": "Rodolfo Fernandes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.98,
    "JAN": 5.75,
    "FEB": 5.96,
    "MAR": 6.04,
    "APR": 5.85,
    "MAY": 5.59,
    "JUN": 5.31,
    "JUL": 5.65,
    "AUG": 6.27,
    "SEP": 6.46,
    "OCT": 6.55,
    "NOV": 6.44,
    "DEC": 5.87
  },
  {
    "LONG": -37.96,
    "LAT": -5.78,
    "CIDADE": "Severiano Melo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.98,
    "JAN": 5.77,
    "FEB": 5.94,
    "MAR": 6.07,
    "APR": 5.85,
    "MAY": 5.59,
    "JUN": 5.31,
    "JUL": 5.66,
    "AUG": 6.26,
    "SEP": 6.47,
    "OCT": 6.55,
    "NOV": 6.41,
    "DEC": 5.87
  },
  {
    "LONG": -37.99,
    "LAT": -5.84,
    "CIDADE": "Itaú",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.98,
    "JAN": 5.77,
    "FEB": 5.94,
    "MAR": 6.07,
    "APR": 5.85,
    "MAY": 5.59,
    "JUN": 5.31,
    "JUL": 5.66,
    "AUG": 6.26,
    "SEP": 6.47,
    "OCT": 6.55,
    "NOV": 6.41,
    "DEC": 5.87
  },
  {
    "LONG": -37.56,
    "LAT": -5.78,
    "CIDADE": "Caraúbas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.74,
    "FEB": 6,
    "MAR": 6.04,
    "APR": 5.9,
    "MAY": 5.6,
    "JUN": 5.29,
    "JUL": 5.64,
    "AUG": 6.15,
    "SEP": 6.39,
    "OCT": 6.41,
    "NOV": 6.33,
    "DEC": 5.78
  },
  {
    "LONG": -3.71,
    "LAT": -5.78,
    "CIDADE": "Paraú",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.84,
    "FEB": 6.03,
    "MAR": 6.2,
    "APR": 5.95,
    "MAY": 5.62,
    "JUN": 5.22,
    "JUL": 5.49,
    "AUG": 6.1,
    "SEP": 6.44,
    "OCT": 6.48,
    "NOV": 6.32,
    "DEC": 5.9
  },
  {
    "LONG": -36.89,
    "LAT": -5.8,
    "CIDADE": "São Rafael",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.83,
    "JAN": 5.75,
    "FEB": 5.83,
    "MAR": 6.11,
    "APR": 5.95,
    "MAY": 5.49,
    "JUN": 5.06,
    "JUL": 5.31,
    "AUG": 5.93,
    "SEP": 6.27,
    "OCT": 6.23,
    "NOV": 6.18,
    "DEC": 5.83
  },
  {
    "LONG": -36,
    "LAT": -5.76,
    "CIDADE": "Caiçara do Rio do Vento",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.58,
    "JAN": 5.48,
    "FEB": 5.66,
    "MAR": 5.78,
    "APR": 5.53,
    "MAY": 5.26,
    "JUN": 4.91,
    "JUL": 5.12,
    "AUG": 5.72,
    "SEP": 5.98,
    "OCT": 6.05,
    "NOV": 5.97,
    "DEC": 5.51
  },
  {
    "LONG": -3.58,
    "LAT": -5.81,
    "CIDADE": "Riachuelo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.48,
    "JAN": 5.5,
    "FEB": 5.59,
    "MAR": 5.75,
    "APR": 5.51,
    "MAY": 5.17,
    "JUN": 4.75,
    "JUL": 5,
    "AUG": 5.51,
    "SEP": 5.79,
    "OCT": 5.86,
    "NOV": 5.87,
    "DEC": 5.5
  },
  {
    "LONG": -35.69,
    "LAT": -5.84,
    "CIDADE": "Santa Maria",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.58,
    "FEB": 5.73,
    "MAR": 5.85,
    "APR": 5.6,
    "MAY": 5.19,
    "JUN": 4.77,
    "JUL": 4.92,
    "AUG": 5.53,
    "SEP": 5.84,
    "OCT": 5.9,
    "NOV": 5.97,
    "DEC": 5.56
  },
  {
    "LONG": -35.55,
    "LAT": -5.82,
    "CIDADE": "Ielmo Marinho",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.58,
    "JAN": 5.66,
    "FEB": 5.79,
    "MAR": 5.94,
    "APR": 5.63,
    "MAY": 5.2,
    "JUN": 4.8,
    "JUL": 4.96,
    "AUG": 5.56,
    "SEP": 5.86,
    "OCT": 5.94,
    "NOV": 6.03,
    "DEC": 5.61
  },
  {
    "LONG": -35.33,
    "LAT": -5.79,
    "CIDADE": "São Gonçalo do Amarante",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.66,
    "JAN": 5.75,
    "FEB": 5.92,
    "MAR": 6.1,
    "APR": 5.68,
    "MAY": 5.3,
    "JUN": 4.84,
    "JUL": 4.93,
    "AUG": 5.65,
    "SEP": 5.97,
    "OCT": 6.03,
    "NOV": 6.07,
    "DEC": 5.71
  },
  {
    "LONG": -35.2,
    "LAT": -5.79,
    "CIDADE": "Natal",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.68,
    "JAN": 5.79,
    "FEB": 5.9,
    "MAR": 6.07,
    "APR": 5.68,
    "MAY": 5.28,
    "JUN": 4.89,
    "JUL": 4.99,
    "AUG": 5.74,
    "SEP": 6,
    "OCT": 6.01,
    "NOV": 6.06,
    "DEC": 5.71
  },
  {
    "LONG": -37.79,
    "LAT": -5.65,
    "CIDADE": "Apodi",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.74,
    "FEB": 6.02,
    "MAR": 6.06,
    "APR": 5.89,
    "MAY": 5.57,
    "JUN": 5.29,
    "JUL": 5.58,
    "AUG": 6.15,
    "SEP": 6.4,
    "OCT": 6.47,
    "NOV": 6.31,
    "DEC": 5.9
  },
  {
    "LONG": -36.61,
    "LAT": -5.66,
    "CIDADE": "Angicos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.69,
    "FEB": 5.78,
    "MAR": 5.97,
    "APR": 5.77,
    "MAY": 5.48,
    "JUN": 5.05,
    "JUL": 5.31,
    "AUG": 5.99,
    "SEP": 6.3,
    "OCT": 6.37,
    "NOV": 6.22,
    "DEC": 5.76
  },
  {
    "LONG": -36.53,
    "LAT": -5.69,
    "CIDADE": "Fernando Pedroza",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.78,
    "JAN": 5.69,
    "FEB": 5.79,
    "MAR": 5.98,
    "APR": 5.67,
    "MAY": 5.43,
    "JUN": 5.04,
    "JUL": 5.31,
    "AUG": 5.96,
    "SEP": 6.27,
    "OCT": 6.32,
    "NOV": 6.2,
    "DEC": 5.74
  },
  {
    "LONG": -36.25,
    "LAT": -5.7,
    "CIDADE": "Lajes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.77,
    "JAN": 5.72,
    "FEB": 5.84,
    "MAR": 5.88,
    "APR": 5.73,
    "MAY": 5.45,
    "JUN": 5,
    "JUL": 5.25,
    "AUG": 5.93,
    "SEP": 6.21,
    "OCT": 6.32,
    "NOV": 6.19,
    "DEC": 5.75
  },
  {
    "LONG": -35.82,
    "LAT": -5.69,
    "CIDADE": "Bento Fernandes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.56,
    "JAN": 5.55,
    "FEB": 5.63,
    "MAR": 5.76,
    "APR": 5.61,
    "MAY": 5.23,
    "JUN": 4.86,
    "JUL": 5.06,
    "AUG": 5.68,
    "SEP": 5.93,
    "OCT": 5.97,
    "NOV": 5.97,
    "DEC": 5.53
  },
  {
    "LONG": -35.29,
    "LAT": -5.7,
    "CIDADE": "Extremoz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.69,
    "JAN": 5.77,
    "FEB": 5.92,
    "MAR": 6.02,
    "APR": 5.7,
    "MAY": 5.32,
    "JUN": 4.93,
    "JUL": 5.09,
    "AUG": 5.79,
    "SEP": 6.04,
    "OCT": 5.98,
    "NOV": 6.05,
    "DEC": 5.72
  },
  {
    "LONG": -37.7,
    "LAT": -0.56,
    "CIDADE": "Felipe Guerra",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.91,
    "JAN": 5.75,
    "FEB": 5.92,
    "MAR": 5.95,
    "APR": 5.83,
    "MAY": 5.55,
    "JUN": 5.23,
    "JUL": 5.63,
    "AUG": 6.2,
    "SEP": 6.38,
    "OCT": 6.45,
    "NOV": 6.3,
    "DEC": 5.78
  },
  {
    "LONG": -37.26,
    "LAT": -5.64,
    "CIDADE": "Upanema",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.92,
    "JAN": 5.75,
    "FEB": 5.86,
    "MAR": 6.02,
    "APR": 5.91,
    "MAY": 5.59,
    "JUN": 5.3,
    "JUL": 5.53,
    "AUG": 6.16,
    "SEP": 6.41,
    "OCT": 6.46,
    "NOV": 6.31,
    "DEC": 5.78
  },
  {
    "LONG": -36.91,
    "LAT": -5.58,
    "CIDADE": "Açu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.9,
    "JAN": 5.71,
    "FEB": 5.92,
    "MAR": 5.99,
    "APR": 5.88,
    "MAY": 5.59,
    "JUN": 5.22,
    "JUL": 5.51,
    "AUG": 6.11,
    "SEP": 6.37,
    "OCT": 6.39,
    "NOV": 6.29,
    "DEC": 5.77
  },
  {
    "LONG": -36.87,
    "LAT": -5.64,
    "CIDADE": "Itajá",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.63,
    "FEB": 5.88,
    "MAR": 5.93,
    "APR": 5.76,
    "MAY": 5.48,
    "JUN": 5.08,
    "JUL": 5.35,
    "AUG": 6.01,
    "SEP": 6.3,
    "OCT": 6.3,
    "NOV": 6.21,
    "DEC": 5.75
  },
  {
    "LONG": -36.1,
    "LAT": -5.58,
    "CIDADE": "Pedra Preta",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.69,
    "JAN": 5.63,
    "FEB": 5.71,
    "MAR": 5.77,
    "APR": 5.62,
    "MAY": 5.37,
    "JUN": 5.03,
    "JUL": 5.24,
    "AUG": 5.89,
    "SEP": 6.11,
    "OCT": 6.19,
    "NOV": 6.1,
    "DEC": 5.66
  },
  {
    "LONG": -35.97,
    "LAT": -5.65,
    "CIDADE": "Jardim de Angicos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.59,
    "JAN": 5.54,
    "FEB": 5.65,
    "MAR": 5.76,
    "APR": 5.61,
    "MAY": 5.26,
    "JUN": 4.91,
    "JUL": 5.11,
    "AUG": 5.69,
    "SEP": 5.93,
    "OCT": 6.02,
    "NOV": 6,
    "DEC": 5.54
  },
  {
    "LONG": -35.66,
    "LAT": -5.62,
    "CIDADE": "Poço Branco",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.58,
    "JAN": 5.64,
    "FEB": 5.82,
    "MAR": 5.87,
    "APR": 5.65,
    "MAY": 5.22,
    "JUN": 4.85,
    "JUL": 4.96,
    "AUG": 5.6,
    "SEP": 5.93,
    "OCT": 5.94,
    "NOV": 5.99,
    "DEC": 5.56
  },
  {
    "LONG": -35.6,
    "LAT": -5.63,
    "CIDADE": "Taipu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.62,
    "JAN": 5.68,
    "FEB": 5.9,
    "MAR": 5.98,
    "APR": 5.65,
    "MAY": 5.21,
    "JUN": 4.86,
    "JUL": 4.95,
    "AUG": 5.59,
    "SEP": 5.96,
    "OCT": 5.98,
    "NOV": 6.03,
    "DEC": 5.62
  },
  {
    "LONG": "#VALOR!",
    "LAT": -5.64,
    "CIDADE": "Ceará-Mirim",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.64,
    "JAN": 5.69,
    "FEB": 5.94,
    "MAR": 6.05,
    "APR": 5.67,
    "MAY": 5.2,
    "JUN": 4.88,
    "JUL": 4.95,
    "AUG": 5.6,
    "SEP": 5.98,
    "OCT": 6.02,
    "NOV": 6.04,
    "DEC": 5.68
  },
  {
    "LONG": -36.86,
    "LAT": -5.51,
    "CIDADE": "Ipanguaçu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.69,
    "FEB": 5.84,
    "MAR": 5.94,
    "APR": 5.72,
    "MAY": 5.53,
    "JUN": 5.07,
    "JUL": 5.33,
    "AUG": 6.02,
    "SEP": 6.3,
    "OCT": 6.3,
    "NOV": 6.19,
    "DEC": 5.78
  },
  {
    "LONG": -36.51,
    "LAT": -5.49,
    "CIDADE": "Afonso Bezerra",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.7,
    "JAN": 5.54,
    "FEB": 5.75,
    "MAR": 5.76,
    "APR": 5.62,
    "MAY": 5.36,
    "JUN": 5,
    "JUL": 5.26,
    "AUG": 5.91,
    "SEP": 6.16,
    "OCT": 6.24,
    "NOV": 6.17,
    "DEC": 5.63
  },
  {
    "LONG": -3.64,
    "LAT": -5.52,
    "CIDADE": "Pedro Avelino",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.69,
    "JAN": 5.55,
    "FEB": 5.74,
    "MAR": 5.73,
    "APR": 5.56,
    "MAY": 5.38,
    "JUN": 5.02,
    "JUL": 5.23,
    "AUG": 5.91,
    "SEP": 6.12,
    "OCT": 6.2,
    "NOV": 6.12,
    "DEC": 5.67
  },
  {
    "LONG": -35.82,
    "LAT": -5.54,
    "CIDADE": "João Câmara",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.59,
    "JAN": 5.59,
    "FEB": 5.67,
    "MAR": 5.78,
    "APR": 5.58,
    "MAY": 5.23,
    "JUN": 4.9,
    "JUL": 5.07,
    "AUG": 5.68,
    "SEP": 5.98,
    "OCT": 6.01,
    "NOV": 6.02,
    "DEC": 5.6
  },
  {
    "LONG": -35.56,
    "LAT": -5.46,
    "CIDADE": "Pureza",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.61,
    "JAN": 5.61,
    "FEB": 5.87,
    "MAR": 5.97,
    "APR": 5.6,
    "MAY": 5.23,
    "JUN": 4.87,
    "JUL": 4.94,
    "AUG": 5.61,
    "SEP": 5.96,
    "OCT": 5.95,
    "NOV": 6.01,
    "DEC": 5.65
  },
  {
    "LONG": -3.53,
    "LAT": -5.52,
    "CIDADE": "Maxaranguape",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.85,
    "JAN": 5.93,
    "FEB": 6.09,
    "MAR": 6.11,
    "APR": 5.81,
    "MAY": 5.44,
    "JUN": 5.05,
    "JUL": 5.2,
    "AUG": 5.94,
    "SEP": 6.26,
    "OCT": 6.22,
    "NOV": 6.26,
    "DEC": 5.94
  },
  {
    "LONG": -37.52,
    "LAT": -5.45,
    "CIDADE": "Governador Dix-Sept Rosado",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.86,
    "JAN": 5.58,
    "FEB": 5.71,
    "MAR": 5.89,
    "APR": 5.76,
    "MAY": 5.55,
    "JUN": 5.28,
    "JUL": 5.56,
    "AUG": 6.17,
    "SEP": 6.39,
    "OCT": 6.41,
    "NOV": 6.29,
    "DEC": 5.73
  },
  {
    "LONG": -36.13,
    "LAT": -5.35,
    "CIDADE": "Jandaíra",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.68,
    "JAN": 5.58,
    "FEB": 5.68,
    "MAR": 5.72,
    "APR": 5.58,
    "MAY": 5.4,
    "JUN": 5.06,
    "JUL": 5.3,
    "AUG": 5.92,
    "SEP": 6.07,
    "OCT": 6.16,
    "NOV": 6.06,
    "DEC": 5.62
  },
  {
    "LONG": -36.83,
    "LAT": -5.34,
    "CIDADE": "Carnaubais",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.72,
    "FEB": 5.8,
    "MAR": 5.91,
    "APR": 5.62,
    "MAY": 5.5,
    "JUN": 5.1,
    "JUL": 5.31,
    "AUG": 5.98,
    "SEP": 6.26,
    "OCT": 6.33,
    "NOV": 6.27,
    "DEC": 5.87
  },
  {
    "LONG": -36.72,
    "LAT": -5.26,
    "CIDADE": "Pendências",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.74,
    "JAN": 5.63,
    "FEB": 5.78,
    "MAR": 5.86,
    "APR": 5.63,
    "MAY": 5.41,
    "JUN": 5.02,
    "JUL": 5.27,
    "AUG": 5.85,
    "SEP": 6.18,
    "OCT": 6.26,
    "NOV": 6.19,
    "DEC": 5.8
  },
  {
    "LONG": -36.76,
    "LAT": -5.29,
    "CIDADE": "Alto do Rodrigues",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.74,
    "JAN": 5.63,
    "FEB": 5.78,
    "MAR": 5.86,
    "APR": 5.63,
    "MAY": 5.41,
    "JUN": 5.02,
    "JUL": 5.27,
    "AUG": 5.85,
    "SEP": 6.18,
    "OCT": 6.26,
    "NOV": 6.19,
    "DEC": 5.8
  },
  {
    "LONG": -35.38,
    "LAT": -5.27,
    "CIDADE": "Rio do Fogo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.86,
    "JAN": 5.94,
    "FEB": 6.07,
    "MAR": 6.11,
    "APR": 5.82,
    "MAY": 5.43,
    "JUN": 5.07,
    "JUL": 5.22,
    "AUG": 5.98,
    "SEP": 6.26,
    "OCT": 6.17,
    "NOV": 6.3,
    "DEC": 5.98
  },
  {
    "LONG": -37.35,
    "LAT": -5.18,
    "CIDADE": "Mossoró",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.8,
    "JAN": 5.64,
    "FEB": 5.74,
    "MAR": 5.92,
    "APR": 5.66,
    "MAY": 5.46,
    "JUN": 5.15,
    "JUL": 5.43,
    "AUG": 6.02,
    "SEP": 6.27,
    "OCT": 6.29,
    "NOV": 6.25,
    "DEC": 5.84
  },
  {
    "LONG": "#VALOR!",
    "LAT": -5.17,
    "CIDADE": "Serra do Mel",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.84,
    "JAN": 5.71,
    "FEB": 5.76,
    "MAR": 5.8,
    "APR": 5.56,
    "MAY": 5.5,
    "JUN": 5.23,
    "JUL": 5.44,
    "AUG": 6.08,
    "SEP": 6.42,
    "OCT": 6.38,
    "NOV": 6.31,
    "DEC": 5.87
  },
  {
    "LONG": -35.84,
    "LAT": -5.22,
    "CIDADE": "Parazinho",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.65,
    "JAN": 5.64,
    "FEB": 5.73,
    "MAR": 5.85,
    "APR": 5.54,
    "MAY": 5.34,
    "JUN": 5.04,
    "JUL": 5.22,
    "AUG": 5.83,
    "SEP": 5.96,
    "OCT": 6.02,
    "NOV": 6.02,
    "DEC": 5.59
  },
  {
    "LONG": -35.46,
    "LAT": -5.2,
    "CIDADE": "Touros",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.83,
    "JAN": 5.96,
    "FEB": 6.09,
    "MAR": 6.09,
    "APR": 5.76,
    "MAY": 5.41,
    "JUN": 5.1,
    "JUL": 5.25,
    "AUG": 5.88,
    "SEP": 6.23,
    "OCT": 6.08,
    "NOV": 6.17,
    "DEC": 5.87
  },
  {
    "LONG": -37.61,
    "LAT": -5.07,
    "CIDADE": "Baraúna",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.83,
    "JAN": 5.56,
    "FEB": 5.69,
    "MAR": 5.86,
    "APR": 5.63,
    "MAY": 5.5,
    "JUN": 5.33,
    "JUL": 5.63,
    "AUG": 6.11,
    "SEP": 6.39,
    "OCT": 6.32,
    "NOV": 6.18,
    "DEC": 5.78
  },
  {
    "LONG": "#VALOR!",
    "LAT": -5.07,
    "CIDADE": "Porto do Mangue",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.82,
    "JAN": 6.02,
    "FEB": 5.94,
    "MAR": 5.97,
    "APR": 5.77,
    "MAY": 5.42,
    "JUN": 5.13,
    "JUL": 5.3,
    "AUG": 5.89,
    "SEP": 6.2,
    "OCT": 6.02,
    "NOV": 6.1,
    "DEC": 6.03
  },
  {
    "LONG": -36.63,
    "LAT": -0.51,
    "CIDADE": "Macau",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.85,
    "JAN": 6.02,
    "FEB": 5.96,
    "MAR": 5.97,
    "APR": 5.74,
    "MAY": 5.51,
    "JUN": 5.11,
    "JUL": 5.31,
    "AUG": 5.92,
    "SEP": 6.29,
    "OCT": 6.16,
    "NOV": 6.17,
    "DEC": 6.07
  },
  {
    "LONG": -36.32,
    "LAT": -5.11,
    "CIDADE": "Guamaré",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.62,
    "JAN": 5.73,
    "FEB": 5.72,
    "MAR": 5.8,
    "APR": 5.52,
    "MAY": 5.31,
    "JUN": 4.87,
    "JUL": 5.03,
    "AUG": 5.62,
    "SEP": 6.09,
    "OCT": 5.96,
    "NOV": 6,
    "DEC": 5.77
  },
  {
    "LONG": -36.28,
    "LAT": -5.09,
    "CIDADE": "Galinhos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.75,
    "JAN": 5.84,
    "FEB": 5.81,
    "MAR": 5.88,
    "APR": 5.63,
    "MAY": 5.47,
    "JUN": 5.03,
    "JUL": 5.18,
    "AUG": 5.82,
    "SEP": 6.1,
    "OCT": 6.06,
    "NOV": 6.2,
    "DEC": 5.97
  },
  {
    "LONG": -36.05,
    "LAT": -5.06,
    "CIDADE": "Caiçara do Norte",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.59,
    "JAN": 5.65,
    "FEB": 5.71,
    "MAR": 5.85,
    "APR": 5.43,
    "MAY": 5.25,
    "JUN": 4.9,
    "JUL": 5.12,
    "AUG": 5.62,
    "SEP": 5.85,
    "OCT": 5.93,
    "NOV": 6.04,
    "DEC": 5.72
  },
  {
    "LONG": -35.98,
    "LAT": -5.11,
    "CIDADE": "São Bento do Norte",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.63,
    "JAN": 5.68,
    "FEB": 5.79,
    "MAR": 5.92,
    "APR": 5.51,
    "MAY": 5.26,
    "JUN": 4.96,
    "JUL": 5.08,
    "AUG": 5.71,
    "SEP": 5.85,
    "OCT": 5.95,
    "NOV": 6.09,
    "DEC": 5.73
  },
  {
    "LONG": -35.88,
    "LAT": -5.15,
    "CIDADE": "Pedra Grande",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.58,
    "JAN": 5.67,
    "FEB": 5.82,
    "MAR": 5.92,
    "APR": 5.5,
    "MAY": 5.2,
    "JUN": 4.9,
    "JUL": 5.01,
    "AUG": 5.63,
    "SEP": 5.83,
    "OCT": 5.84,
    "NOV": 5.96,
    "DEC": 5.63
  },
  {
    "LONG": -35.64,
    "LAT": -5.13,
    "CIDADE": "São Miguel do Gostoso",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.86,
    "JAN": 6.02,
    "FEB": 6.04,
    "MAR": 6.17,
    "APR": 5.72,
    "MAY": 5.49,
    "JUN": 5.04,
    "JUL": 5.2,
    "AUG": 5.91,
    "SEP": 6.21,
    "OCT": 6.21,
    "NOV": 6.32,
    "DEC": 5.98
  },
  {
    "LONG": -37.13,
    "LAT": -4.95,
    "CIDADE": "Areia Branca",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.75,
    "JAN": 5.78,
    "FEB": 5.76,
    "MAR": 5.82,
    "APR": 5.58,
    "MAY": 5.45,
    "JUN": 5.12,
    "JUL": 5.36,
    "AUG": 5.86,
    "SEP": 6.21,
    "OCT": 6,
    "NOV": 6.07,
    "DEC": 5.94
  },
  {
    "LONG": -37.16,
    "LAT": -4.98,
    "CIDADE": "Grossos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.75,
    "JAN": 5.78,
    "FEB": 5.76,
    "MAR": 5.82,
    "APR": 5.58,
    "MAY": 5.45,
    "JUN": 5.12,
    "JUL": 5.36,
    "AUG": 5.86,
    "SEP": 6.21,
    "OCT": 6,
    "NOV": 6.07,
    "DEC": 5.94
  },
  {
    "LONG": -37.26,
    "LAT": -4.84,
    "CIDADE": "Tibau",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.96,
    "JAN": 6.11,
    "FEB": 6.14,
    "MAR": 6.12,
    "APR": 5.87,
    "MAY": 5.71,
    "JUN": 5.47,
    "JUL": 5.67,
    "AUG": 6.19,
    "SEP": 6.1,
    "OCT": 5.97,
    "NOV": 6.06,
    "DEC": 6.16
  },
  {
    "LONG": -53.46,
    "LAT": -33.69,
    "CIDADE": "Chuí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.78,
    "JAN": 5.79,
    "FEB": 5.55,
    "MAR": 5.55,
    "APR": 4.65,
    "MAY": 3.69,
    "JUN": 3.34,
    "JUL": 3.39,
    "AUG": 3.93,
    "SEP": 4.39,
    "OCT": 5.24,
    "NOV": 5.84,
    "DEC": 5.96
  },
  {
    "LONG": -53.37,
    "LAT": -33.53,
    "CIDADE": "Santa Vitória do Palmar",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.75,
    "FEB": 5.51,
    "MAR": 5.5,
    "APR": 4.66,
    "MAY": 3.71,
    "JUN": 3.33,
    "JUL": 3.37,
    "AUG": 3.91,
    "SEP": 4.36,
    "OCT": 5.19,
    "NOV": 5.78,
    "DEC": 5.88
  },
  {
    "LONG": -53.38,
    "LAT": -32.56,
    "CIDADE": "Jaguarão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.72,
    "FEB": 5.58,
    "MAR": 5.39,
    "APR": 4.66,
    "MAY": 3.73,
    "JUN": 3.28,
    "JUL": 3.4,
    "AUG": 3.92,
    "SEP": 4.23,
    "OCT": 5.04,
    "NOV": 5.75,
    "DEC": 5.87
  },
  {
    "LONG": -53.09,
    "LAT": -32.23,
    "CIDADE": "Arroio Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.58,
    "FEB": 5.39,
    "MAR": 5.24,
    "APR": 4.6,
    "MAY": 3.69,
    "JUN": 3.3,
    "JUL": 3.44,
    "AUG": 3.93,
    "SEP": 4.09,
    "OCT": 4.9,
    "NOV": 5.59,
    "DEC": 5.72
  },
  {
    "LONG": -53.39,
    "LAT": -32.02,
    "CIDADE": "Herval",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.5,
    "FEB": 5.44,
    "MAR": 5.28,
    "APR": 4.66,
    "MAY": 3.68,
    "JUN": 3.32,
    "JUL": 3.54,
    "AUG": 3.97,
    "SEP": 4.12,
    "OCT": 4.97,
    "NOV": 5.66,
    "DEC": 5.78
  },
  {
    "LONG": -52.11,
    "LAT": -32.04,
    "CIDADE": "Rio Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.58,
    "FEB": 5.5,
    "MAR": 5.3,
    "APR": 4.57,
    "MAY": 3.69,
    "JUN": 3.25,
    "JUL": 3.41,
    "AUG": 3.91,
    "SEP": 4.09,
    "OCT": 4.91,
    "NOV": 5.6,
    "DEC": 5.76
  },
  {
    "LONG": -52.03,
    "LAT": -32.01,
    "CIDADE": "São José do Norte",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.6,
    "FEB": 5.52,
    "MAR": 5.3,
    "APR": 4.59,
    "MAY": 3.69,
    "JUN": 3.26,
    "JUL": 3.4,
    "AUG": 3.9,
    "SEP": 4.09,
    "OCT": 4.89,
    "NOV": 5.59,
    "DEC": 5.74
  },
  {
    "LONG": -54.16,
    "LAT": -3.19,
    "CIDADE": "Aceguá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.77,
    "FEB": 5.69,
    "MAR": 5.53,
    "APR": 4.77,
    "MAY": 3.76,
    "JUN": 3.36,
    "JUL": 3.6,
    "AUG": 4.09,
    "SEP": 4.36,
    "OCT": 5.19,
    "NOV": 5.83,
    "DEC": 5.96
  },
  {
    "LONG": -52.82,
    "LAT": -31.86,
    "CIDADE": "Pedro Osório",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.54,
    "FEB": 5.43,
    "MAR": 5.23,
    "APR": 4.61,
    "MAY": 3.68,
    "JUN": 3.31,
    "JUL": 3.47,
    "AUG": 3.93,
    "SEP": 4.04,
    "OCT": 4.87,
    "NOV": 5.58,
    "DEC": 5.72
  },
  {
    "LONG": -52.8,
    "LAT": -31.84,
    "CIDADE": "Cerrito",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.48,
    "FEB": 5.4,
    "MAR": 5.17,
    "APR": 4.56,
    "MAY": 3.65,
    "JUN": 3.26,
    "JUL": 3.47,
    "AUG": 3.92,
    "SEP": 4,
    "OCT": 4.85,
    "NOV": 5.56,
    "DEC": 5.68
  },
  {
    "LONG": -52.49,
    "LAT": -31.76,
    "CIDADE": "Capão do Leão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.45,
    "FEB": 5.35,
    "MAR": 5.17,
    "APR": 4.53,
    "MAY": 3.67,
    "JUN": 3.29,
    "JUL": 3.45,
    "AUG": 3.9,
    "SEP": 3.98,
    "OCT": 4.76,
    "NOV": 5.5,
    "DEC": 5.64
  },
  {
    "LONG": -52.34,
    "LAT": -31.77,
    "CIDADE": "Pelotas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.49,
    "FEB": 5.41,
    "MAR": 5.23,
    "APR": 4.57,
    "MAY": 3.68,
    "JUN": 3.27,
    "JUL": 3.4,
    "AUG": 3.88,
    "SEP": 3.98,
    "OCT": 4.79,
    "NOV": 5.54,
    "DEC": 5.68
  },
  {
    "LONG": -53.58,
    "LAT": -31.74,
    "CIDADE": "Pedras Altas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.74,
    "JAN": 5.63,
    "FEB": 5.58,
    "MAR": 5.37,
    "APR": 4.68,
    "MAY": 3.71,
    "JUN": 3.35,
    "JUL": 3.62,
    "AUG": 4.08,
    "SEP": 4.25,
    "OCT": 5.06,
    "NOV": 5.74,
    "DEC": 5.86
  },
  {
    "LONG": -53.67,
    "LAT": -31.56,
    "CIDADE": "Candiota",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.61,
    "FEB": 5.59,
    "MAR": 5.39,
    "APR": 4.69,
    "MAY": 3.72,
    "JUN": 3.35,
    "JUL": 3.6,
    "AUG": 4.11,
    "SEP": 4.24,
    "OCT": 5.05,
    "NOV": 5.75,
    "DEC": 5.88
  },
  {
    "LONG": -53.38,
    "LAT": -31.58,
    "CIDADE": "Pinheiro Machado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.51,
    "FEB": 5.55,
    "MAR": 5.31,
    "APR": 4.65,
    "MAY": 3.68,
    "JUN": 3.34,
    "JUL": 3.56,
    "AUG": 4.07,
    "SEP": 4.16,
    "OCT": 4.97,
    "NOV": 5.68,
    "DEC": 5.83
  },
  {
    "LONG": -52.63,
    "LAT": -31.59,
    "CIDADE": "Morro Redondo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.43,
    "FEB": 5.31,
    "MAR": 5.14,
    "APR": 4.53,
    "MAY": 3.66,
    "JUN": 3.24,
    "JUL": 3.48,
    "AUG": 3.95,
    "SEP": 3.96,
    "OCT": 4.75,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -53.87,
    "LAT": -31.41,
    "CIDADE": "Hulha Negra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.66,
    "FEB": 5.67,
    "MAR": 5.49,
    "APR": 4.75,
    "MAY": 3.76,
    "JUN": 3.36,
    "JUL": 3.64,
    "AUG": 4.15,
    "SEP": 4.28,
    "OCT": 5.08,
    "NOV": 5.77,
    "DEC": 5.87
  },
  {
    "LONG": -53.1,
    "LAT": -31.45,
    "CIDADE": "Piratini",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.5,
    "FEB": 5.49,
    "MAR": 5.26,
    "APR": 4.63,
    "MAY": 3.72,
    "JUN": 3.3,
    "JUL": 3.57,
    "AUG": 4.08,
    "SEP": 4.15,
    "OCT": 4.92,
    "NOV": 5.64,
    "DEC": 5.77
  },
  {
    "LONG": -52.68,
    "LAT": -31.4,
    "CIDADE": "Canguçu",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.4,
    "FEB": 5.38,
    "MAR": 5.13,
    "APR": 4.58,
    "MAY": 3.69,
    "JUN": 3.29,
    "JUL": 3.53,
    "AUG": 4.01,
    "SEP": 4.04,
    "OCT": 4.79,
    "NOV": 5.47,
    "DEC": 5.66
  },
  {
    "LONG": -52.42,
    "LAT": -31.44,
    "CIDADE": "Arroio do Padre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.37,
    "FEB": 5.31,
    "MAR": 5.08,
    "APR": 4.56,
    "MAY": 3.67,
    "JUN": 3.27,
    "JUL": 3.52,
    "AUG": 3.96,
    "SEP": 3.95,
    "OCT": 4.72,
    "NOV": 5.41,
    "DEC": 5.54
  },
  {
    "LONG": -52.18,
    "LAT": -3.14,
    "CIDADE": "Turuçu",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.45,
    "FEB": 5.35,
    "MAR": 5.12,
    "APR": 4.57,
    "MAY": 3.72,
    "JUN": 3.31,
    "JUL": 3.49,
    "AUG": 3.97,
    "SEP": 3.96,
    "OCT": 4.68,
    "NOV": 5.47,
    "DEC": 5.61
  },
  {
    "LONG": -51.97,
    "LAT": -3.14,
    "CIDADE": "São Lourenço do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.68,
    "FEB": 5.58,
    "MAR": 5.32,
    "APR": 4.63,
    "MAY": 3.74,
    "JUN": 3.3,
    "JUL": 3.44,
    "AUG": 3.94,
    "SEP": 4.09,
    "OCT": 4.84,
    "NOV": 5.65,
    "DEC": 5.78
  },
  {
    "LONG": -54.11,
    "LAT": -3.13,
    "CIDADE": "Bagé",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.67,
    "FEB": 5.72,
    "MAR": 5.48,
    "APR": 4.75,
    "MAY": 3.79,
    "JUN": 3.38,
    "JUL": 3.68,
    "AUG": 4.2,
    "SEP": 4.34,
    "OCT": 5.14,
    "NOV": 5.8,
    "DEC": 5.9
  },
  {
    "LONG": -51.09,
    "LAT": -31.29,
    "CIDADE": "Tavares",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.79,
    "FEB": 5.59,
    "MAR": 5.31,
    "APR": 4.72,
    "MAY": 3.76,
    "JUN": 3.31,
    "JUL": 3.49,
    "AUG": 4.01,
    "SEP": 4.07,
    "OCT": 4.82,
    "NOV": 5.65,
    "DEC": 5.77
  },
  {
    "LONG": -50.92,
    "LAT": -31.11,
    "CIDADE": "Mostardas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.66,
    "FEB": 5.58,
    "MAR": 5.25,
    "APR": 4.69,
    "MAY": 3.74,
    "JUN": 3.26,
    "JUL": 3.45,
    "AUG": 4,
    "SEP": 4.02,
    "OCT": 4.77,
    "NOV": 5.58,
    "DEC": 5.74
  },
  {
    "LONG": -54.67,
    "LAT": -30.98,
    "CIDADE": "Dom Pedrito",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.86,
    "FEB": 5.78,
    "MAR": 5.58,
    "APR": 4.88,
    "MAY": 3.86,
    "JUN": 3.42,
    "JUL": 3.68,
    "AUG": 4.29,
    "SEP": 4.37,
    "OCT": 5.23,
    "NOV": 5.81,
    "DEC": 5.95
  },
  {
    "LONG": -52.05,
    "LAT": -31,
    "CIDADE": "Cristal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.52,
    "FEB": 5.44,
    "MAR": 5.16,
    "APR": 4.61,
    "MAY": 3.72,
    "JUN": 3.27,
    "JUL": 3.46,
    "AUG": 3.98,
    "SEP": 3.99,
    "OCT": 4.69,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -5.55,
    "LAT": -30.89,
    "CIDADE": "Sant'Ana do Livramento",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.84,
    "FEB": 5.75,
    "MAR": 5.56,
    "APR": 4.95,
    "MAY": 3.84,
    "JUN": 3.37,
    "JUL": 3.75,
    "AUG": 4.34,
    "SEP": 4.45,
    "OCT": 5.26,
    "NOV": 5.79,
    "DEC": 5.97
  },
  {
    "LONG": -53.11,
    "LAT": -3.09,
    "CIDADE": "Santana da Boa Vista",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.56,
    "FEB": 5.62,
    "MAR": 5.35,
    "APR": 4.68,
    "MAY": 3.72,
    "JUN": 3.28,
    "JUL": 3.54,
    "AUG": 4.11,
    "SEP": 4.17,
    "OCT": 4.95,
    "NOV": 5.64,
    "DEC": 5.84
  },
  {
    "LONG": -52.25,
    "LAT": -30.88,
    "CIDADE": "Amaral Ferrador",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.53,
    "FEB": 5.44,
    "MAR": 5.15,
    "APR": 4.59,
    "MAY": 3.72,
    "JUN": 3.28,
    "JUL": 3.47,
    "AUG": 3.99,
    "SEP": 4,
    "OCT": 4.7,
    "NOV": 5.51,
    "DEC": 5.68
  },
  {
    "LONG": -5.15,
    "LAT": -30.91,
    "CIDADE": "Arambaré",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.69,
    "FEB": 5.59,
    "MAR": 5.31,
    "APR": 4.66,
    "MAY": 3.81,
    "JUN": 3.26,
    "JUL": 3.5,
    "AUG": 3.99,
    "SEP": 4.04,
    "OCT": 4.71,
    "NOV": 5.57,
    "DEC": 5.7
  },
  {
    "LONG": -53.9,
    "LAT": -3.08,
    "CIDADE": "Lavras do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.65,
    "FEB": 5.66,
    "MAR": 5.46,
    "APR": 4.79,
    "MAY": 3.8,
    "JUN": 3.34,
    "JUL": 3.7,
    "AUG": 4.22,
    "SEP": 4.32,
    "OCT": 5.04,
    "NOV": 5.7,
    "DEC": 5.87
  },
  {
    "LONG": -51.97,
    "LAT": -30.75,
    "CIDADE": "Chuvisca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.5,
    "FEB": 5.38,
    "MAR": 5.09,
    "APR": 4.61,
    "MAY": 3.7,
    "JUN": 3.26,
    "JUL": 3.51,
    "AUG": 4.02,
    "SEP": 3.97,
    "OCT": 4.62,
    "NOV": 5.45,
    "DEC": 5.57
  },
  {
    "LONG": -51.8,
    "LAT": -30.85,
    "CIDADE": "Camaquã",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.49,
    "FEB": 5.31,
    "MAR": 5.08,
    "APR": 4.58,
    "MAY": 3.72,
    "JUN": 3.25,
    "JUL": 3.49,
    "AUG": 3.99,
    "SEP": 3.94,
    "OCT": 4.6,
    "NOV": 5.43,
    "DEC": 5.53
  },
  {
    "LONG": -52.1,
    "LAT": -30.7,
    "CIDADE": "Dom Feliciano",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.46,
    "FEB": 5.41,
    "MAR": 5.14,
    "APR": 4.63,
    "MAY": 3.72,
    "JUN": 3.28,
    "JUL": 3.55,
    "AUG": 4.03,
    "SEP": 3.98,
    "OCT": 4.66,
    "NOV": 5.46,
    "DEC": 5.61
  },
  {
    "LONG": -51.4,
    "LAT": -30.67,
    "CIDADE": "Tapes",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.71,
    "FEB": 5.49,
    "MAR": 5.26,
    "APR": 4.69,
    "MAY": 3.82,
    "JUN": 3.3,
    "JUL": 3.53,
    "AUG": 3.98,
    "SEP": 4.07,
    "OCT": 4.72,
    "NOV": 5.59,
    "DEC": 5.64
  },
  {
    "LONG": -51.75,
    "LAT": -30.6,
    "CIDADE": "Cerro Grande do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.51,
    "JAN": 5.4,
    "FEB": 5.24,
    "MAR": 5.04,
    "APR": 4.59,
    "MAY": 3.71,
    "JUN": 3.29,
    "JUL": 3.49,
    "AUG": 4,
    "SEP": 3.92,
    "OCT": 4.56,
    "NOV": 5.41,
    "DEC": 5.48
  },
  {
    "LONG": -51.59,
    "LAT": -30.61,
    "CIDADE": "Sentinela do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.5,
    "FEB": 5.34,
    "MAR": 5.11,
    "APR": 4.64,
    "MAY": 3.78,
    "JUN": 3.28,
    "JUL": 3.53,
    "AUG": 4,
    "SEP": 3.98,
    "OCT": 4.61,
    "NOV": 5.5,
    "DEC": 5.5
  },
  {
    "LONG": -53.48,
    "LAT": -30.51,
    "CIDADE": "Caçapava do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.66,
    "FEB": 5.66,
    "MAR": 5.44,
    "APR": 4.81,
    "MAY": 3.83,
    "JUN": 3.35,
    "JUL": 3.62,
    "AUG": 4.25,
    "SEP": 4.28,
    "OCT": 5,
    "NOV": 5.69,
    "DEC": 5.85
  },
  {
    "LONG": -52.52,
    "LAT": -30.54,
    "CIDADE": "Encruzilhada do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.64,
    "FEB": 5.57,
    "MAR": 5.29,
    "APR": 4.75,
    "MAY": 3.81,
    "JUN": 3.36,
    "JUL": 3.62,
    "AUG": 4.17,
    "SEP": 4.18,
    "OCT": 4.89,
    "NOV": 5.59,
    "DEC": 5.74
  },
  {
    "LONG": -51.6,
    "LAT": -30.46,
    "CIDADE": "Sertão Santana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.54,
    "JAN": 5.4,
    "FEB": 5.3,
    "MAR": 5.05,
    "APR": 4.62,
    "MAY": 3.74,
    "JUN": 3.33,
    "JUL": 3.53,
    "AUG": 4.03,
    "SEP": 3.92,
    "OCT": 4.58,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -56.45,
    "LAT": -30.38,
    "CIDADE": "Quaraí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.1,
    "JAN": 6,
    "FEB": 5.96,
    "MAR": 5.79,
    "APR": 5.13,
    "MAY": 4.07,
    "JUN": 3.51,
    "JUL": 3.92,
    "AUG": 4.55,
    "SEP": 4.79,
    "OCT": 5.42,
    "NOV": 5.93,
    "DEC": 6.11
  },
  {
    "LONG": -51.74,
    "LAT": -30.39,
    "CIDADE": "Barão do Triunfo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.42,
    "FEB": 5.38,
    "MAR": 5.11,
    "APR": 4.66,
    "MAY": 3.76,
    "JUN": 3.38,
    "JUL": 3.59,
    "AUG": 4.11,
    "SEP": 3.99,
    "OCT": 4.68,
    "NOV": 5.51,
    "DEC": 5.59
  },
  {
    "LONG": -51.58,
    "LAT": -3.04,
    "CIDADE": "Mariana Pimentel",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.48,
    "FEB": 5.36,
    "MAR": 5.09,
    "APR": 4.6,
    "MAY": 3.75,
    "JUN": 3.32,
    "JUL": 3.54,
    "AUG": 4.02,
    "SEP": 3.95,
    "OCT": 4.64,
    "NOV": 5.48,
    "DEC": 5.55
  },
  {
    "LONG": -54.91,
    "LAT": -3.03,
    "CIDADE": "Rosário do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.9,
    "FEB": 5.8,
    "MAR": 5.6,
    "APR": 4.92,
    "MAY": 3.93,
    "JUN": 3.42,
    "JUL": 3.72,
    "AUG": 4.37,
    "SEP": 4.45,
    "OCT": 5.19,
    "NOV": 5.75,
    "DEC": 5.99
  },
  {
    "LONG": -54.32,
    "LAT": -3.03,
    "CIDADE": "São Gabriel",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.8,
    "FEB": 5.84,
    "MAR": 5.62,
    "APR": 4.94,
    "MAY": 3.92,
    "JUN": 3.4,
    "JUL": 3.66,
    "AUG": 4.31,
    "SEP": 4.38,
    "OCT": 5.12,
    "NOV": 5.74,
    "DEC": 5.94
  },
  {
    "LONG": -54.08,
    "LAT": -30.34,
    "CIDADE": "Santa Margarida do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.77,
    "FEB": 5.82,
    "MAR": 5.55,
    "APR": 4.9,
    "MAY": 3.92,
    "JUN": 3.43,
    "JUL": 3.69,
    "AUG": 4.31,
    "SEP": 4.37,
    "OCT": 5.08,
    "NOV": 5.75,
    "DEC": 5.95
  },
  {
    "LONG": -53.88,
    "LAT": -30.35,
    "CIDADE": "Vila Nova do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.74,
    "FEB": 5.83,
    "MAR": 5.55,
    "APR": 4.91,
    "MAY": 3.9,
    "JUN": 3.42,
    "JUL": 3.66,
    "AUG": 4.32,
    "SEP": 4.34,
    "OCT": 5.07,
    "NOV": 5.77,
    "DEC": 5.92
  },
  {
    "LONG": -51.3,
    "LAT": -30.29,
    "CIDADE": "Barra do Ribeiro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.74,
    "FEB": 5.57,
    "MAR": 5.24,
    "APR": 4.71,
    "MAY": 3.79,
    "JUN": 3.33,
    "JUL": 3.58,
    "AUG": 4.07,
    "SEP": 4.05,
    "OCT": 4.77,
    "NOV": 5.58,
    "DEC": 5.7
  },
  {
    "LONG": -50.51,
    "LAT": -3.03,
    "CIDADE": "Palmares do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.75,
    "FEB": 5.7,
    "MAR": 5.27,
    "APR": 4.78,
    "MAY": 3.81,
    "JUN": 3.31,
    "JUL": 3.54,
    "AUG": 4.06,
    "SEP": 4.02,
    "OCT": 4.8,
    "NOV": 5.59,
    "DEC": 5.76
  },
  {
    "LONG": -57.55,
    "LAT": -30.2,
    "CIDADE": "Barra do Quaraí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.17,
    "JAN": 6.18,
    "FEB": 6.08,
    "MAR": 5.82,
    "APR": 5.2,
    "MAY": 4.13,
    "JUN": 3.51,
    "JUL": 4,
    "AUG": 4.63,
    "SEP": 4.88,
    "OCT": 5.55,
    "NOV": 5.96,
    "DEC": 6.17
  },
  {
    "LONG": -53.56,
    "LAT": -3.02,
    "CIDADE": "São Sepé",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.69,
    "FEB": 5.79,
    "MAR": 5.5,
    "APR": 4.81,
    "MAY": 3.85,
    "JUN": 3.33,
    "JUL": 3.55,
    "AUG": 4.25,
    "SEP": 4.27,
    "OCT": 4.99,
    "NOV": 5.71,
    "DEC": 5.88
  },
  {
    "LONG": -52.37,
    "LAT": -30.19,
    "CIDADE": "Pantano Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.69,
    "FEB": 5.72,
    "MAR": 5.36,
    "APR": 4.71,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.14,
    "SEP": 4.17,
    "OCT": 4.87,
    "NOV": 5.61,
    "DEC": 5.76
  },
  {
    "LONG": -50.22,
    "LAT": -30.17,
    "CIDADE": "Cidreira",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.67,
    "FEB": 5.55,
    "MAR": 5.17,
    "APR": 4.75,
    "MAY": 3.78,
    "JUN": 3.34,
    "JUL": 3.52,
    "AUG": 4.01,
    "SEP": 3.97,
    "OCT": 4.68,
    "NOV": 5.56,
    "DEC": 5.71
  },
  {
    "LONG": -50.23,
    "LAT": -30.24,
    "CIDADE": "Balneário Pinhal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.67,
    "FEB": 5.55,
    "MAR": 5.17,
    "APR": 4.75,
    "MAY": 3.78,
    "JUN": 3.34,
    "JUL": 3.52,
    "AUG": 4.01,
    "SEP": 3.97,
    "OCT": 4.68,
    "NOV": 5.56,
    "DEC": 5.71
  },
  {
    "LONG": -52.05,
    "LAT": -3.01,
    "CIDADE": "Minas do Leão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.67,
    "FEB": 5.63,
    "MAR": 5.3,
    "APR": 4.7,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.53,
    "AUG": 4.12,
    "SEP": 4.11,
    "OCT": 4.83,
    "NOV": 5.64,
    "DEC": 5.75
  },
  {
    "LONG": -51.96,
    "LAT": -30.12,
    "CIDADE": "Butiá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.63,
    "FEB": 5.62,
    "MAR": 5.26,
    "APR": 4.69,
    "MAY": 3.75,
    "JUN": 3.29,
    "JUL": 3.54,
    "AUG": 4.11,
    "SEP": 4.09,
    "OCT": 4.8,
    "NOV": 5.63,
    "DEC": 5.73
  },
  {
    "LONG": -51.73,
    "LAT": -3.01,
    "CIDADE": "Arroio dos Ratos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.63,
    "FEB": 5.59,
    "MAR": 5.27,
    "APR": 4.69,
    "MAY": 3.73,
    "JUN": 3.29,
    "JUL": 3.54,
    "AUG": 4.1,
    "SEP": 4.05,
    "OCT": 4.8,
    "NOV": 5.6,
    "DEC": 5.72
  },
  {
    "LONG": -51.32,
    "LAT": -30.11,
    "CIDADE": "Guaíba",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.73,
    "FEB": 5.63,
    "MAR": 5.29,
    "APR": 4.74,
    "MAY": 3.78,
    "JUN": 3.29,
    "JUL": 3.56,
    "AUG": 4.12,
    "SEP": 4.06,
    "OCT": 4.83,
    "NOV": 5.66,
    "DEC": 5.78
  },
  {
    "LONG": -51.02,
    "LAT": -30.08,
    "CIDADE": "Viamão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.58,
    "FEB": 5.5,
    "MAR": 5.15,
    "APR": 4.66,
    "MAY": 3.73,
    "JUN": 3.29,
    "JUL": 3.52,
    "AUG": 4.06,
    "SEP": 3.97,
    "OCT": 4.7,
    "NOV": 5.57,
    "DEC": 5.63
  },
  {
    "LONG": -50.51,
    "LAT": -3.01,
    "CIDADE": "Capivari do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.73,
    "FEB": 5.65,
    "MAR": 5.24,
    "APR": 4.78,
    "MAY": 3.8,
    "JUN": 3.36,
    "JUL": 3.57,
    "AUG": 4.1,
    "SEP": 3.98,
    "OCT": 4.75,
    "NOV": 5.6,
    "DEC": 5.72
  },
  {
    "LONG": -53.5,
    "LAT": -30,
    "CIDADE": "Formigueiro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.74,
    "FEB": 5.8,
    "MAR": 5.49,
    "APR": 4.81,
    "MAY": 3.82,
    "JUN": 3.32,
    "JUL": 3.54,
    "AUG": 4.25,
    "SEP": 4.27,
    "OCT": 4.94,
    "NOV": 5.69,
    "DEC": 5.9
  },
  {
    "LONG": -52.89,
    "LAT": -3,
    "CIDADE": "Cachoeira do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.4,
    "APR": 4.72,
    "MAY": 3.77,
    "JUN": 3.26,
    "JUL": 3.48,
    "AUG": 4.16,
    "SEP": 4.15,
    "OCT": 4.83,
    "NOV": 5.62,
    "DEC": 5.78
  },
  {
    "LONG": -5.24,
    "LAT": -29.99,
    "CIDADE": "Rio Pardo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.65,
    "FEB": 5.65,
    "MAR": 5.3,
    "APR": 4.68,
    "MAY": 3.79,
    "JUN": 3.29,
    "JUL": 3.47,
    "AUG": 4.12,
    "SEP": 4.07,
    "OCT": 4.79,
    "NOV": 5.61,
    "DEC": 5.74
  },
  {
    "LONG": -51.72,
    "LAT": -29.96,
    "CIDADE": "São Jerônimo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.65,
    "FEB": 5.63,
    "MAR": 5.26,
    "APR": 4.67,
    "MAY": 3.74,
    "JUN": 3.24,
    "JUL": 3.51,
    "AUG": 4.11,
    "SEP": 4.04,
    "OCT": 4.79,
    "NOV": 5.6,
    "DEC": 5.73
  },
  {
    "LONG": -51.63,
    "LAT": -3,
    "CIDADE": "Charqueadas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.67,
    "FEB": 5.63,
    "MAR": 5.25,
    "APR": 4.68,
    "MAY": 3.74,
    "JUN": 3.24,
    "JUL": 3.52,
    "AUG": 4.12,
    "SEP": 4.04,
    "OCT": 4.79,
    "NOV": 5.6,
    "DEC": 5.72
  },
  {
    "LONG": -51.31,
    "LAT": -30,
    "CIDADE": "Eldorado do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.73,
    "FEB": 5.67,
    "MAR": 5.28,
    "APR": 4.71,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.53,
    "AUG": 4.11,
    "SEP": 4.03,
    "OCT": 4.81,
    "NOV": 5.66,
    "DEC": 5.78
  },
  {
    "LONG": -51.08,
    "LAT": -29.99,
    "CIDADE": "Alvorada",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.59,
    "FEB": 5.52,
    "MAR": 5.19,
    "APR": 4.65,
    "MAY": 3.73,
    "JUN": 3.26,
    "JUL": 3.5,
    "AUG": 4.05,
    "SEP": 3.97,
    "OCT": 4.68,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -50.13,
    "LAT": -29.98,
    "CIDADE": "Imbé",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.61,
    "FEB": 5.5,
    "MAR": 5.14,
    "APR": 4.74,
    "MAY": 3.88,
    "JUN": 3.37,
    "JUL": 3.54,
    "AUG": 4.09,
    "SEP": 4.01,
    "OCT": 4.65,
    "NOV": 5.54,
    "DEC": 5.65
  },
  {
    "LONG": -50.13,
    "LAT": -29.98,
    "CIDADE": "Tramandaí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.61,
    "FEB": 5.5,
    "MAR": 5.14,
    "APR": 4.74,
    "MAY": 3.88,
    "JUN": 3.37,
    "JUL": 3.54,
    "AUG": 4.09,
    "SEP": 4.01,
    "OCT": 4.65,
    "NOV": 5.54,
    "DEC": 5.65
  },
  {
    "LONG": -54.82,
    "LAT": -29.88,
    "CIDADE": "Cacequi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.83,
    "FEB": 5.83,
    "MAR": 5.58,
    "APR": 4.88,
    "MAY": 3.95,
    "JUN": 3.41,
    "JUL": 3.69,
    "AUG": 4.32,
    "SEP": 4.41,
    "OCT": 5.11,
    "NOV": 5.73,
    "DEC": 5.95
  },
  {
    "LONG": -51.71,
    "LAT": -29.93,
    "CIDADE": "Triunfo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.63,
    "FEB": 5.61,
    "MAR": 5.26,
    "APR": 4.67,
    "MAY": 3.73,
    "JUN": 3.23,
    "JUL": 3.48,
    "AUG": 4.13,
    "SEP": 4,
    "OCT": 4.73,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -51.76,
    "LAT": -29.9,
    "CIDADE": "General Câmara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.63,
    "FEB": 5.61,
    "MAR": 5.26,
    "APR": 4.67,
    "MAY": 3.73,
    "JUN": 3.23,
    "JUL": 3.48,
    "AUG": 4.13,
    "SEP": 4,
    "OCT": 4.73,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -51.18,
    "LAT": -29.85,
    "CIDADE": "Esteio",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.58,
    "FEB": 5.55,
    "MAR": 5.2,
    "APR": 4.66,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.99,
    "OCT": 4.69,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -51.1,
    "LAT": -29.95,
    "CIDADE": "Cachoeirinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.58,
    "FEB": 5.55,
    "MAR": 5.2,
    "APR": 4.66,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.99,
    "OCT": 4.69,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -51.19,
    "LAT": -29.91,
    "CIDADE": "Canoas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.58,
    "FEB": 5.55,
    "MAR": 5.2,
    "APR": 4.66,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.99,
    "OCT": 4.69,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -51.02,
    "LAT": -29.94,
    "CIDADE": "Gravataí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.57,
    "FEB": 5.52,
    "MAR": 5.17,
    "APR": 4.66,
    "MAY": 3.76,
    "JUN": 3.29,
    "JUL": 3.49,
    "AUG": 4.08,
    "SEP": 3.98,
    "OCT": 4.66,
    "NOV": 5.58,
    "DEC": 5.66
  },
  {
    "LONG": -50.77,
    "LAT": -29.88,
    "CIDADE": "Glorinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.62,
    "FEB": 5.59,
    "MAR": 5.21,
    "APR": 4.72,
    "MAY": 3.82,
    "JUN": 3.34,
    "JUL": 3.57,
    "AUG": 4.08,
    "SEP": 4.02,
    "OCT": 4.7,
    "NOV": 5.63,
    "DEC": 5.7
  },
  {
    "LONG": -50.27,
    "LAT": -29.89,
    "CIDADE": "Osório",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.49,
    "FEB": 5.33,
    "MAR": 5.02,
    "APR": 4.68,
    "MAY": 3.81,
    "JUN": 3.38,
    "JUL": 3.59,
    "AUG": 4.13,
    "SEP": 3.9,
    "OCT": 4.49,
    "NOV": 5.39,
    "DEC": 5.48
  },
  {
    "LONG": -57.09,
    "LAT": -29.76,
    "CIDADE": "Uruguaiana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.11,
    "JAN": 6.1,
    "FEB": 5.96,
    "MAR": 5.74,
    "APR": 5.11,
    "MAY": 4.14,
    "JUN": 3.5,
    "JUL": 3.94,
    "AUG": 4.57,
    "SEP": 4.79,
    "OCT": 5.46,
    "NOV": 5.88,
    "DEC": 6.06
  },
  {
    "LONG": -55.8,
    "LAT": -29.79,
    "CIDADE": "Alegrete",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.05,
    "JAN": 6,
    "FEB": 5.89,
    "MAR": 5.74,
    "APR": 5.09,
    "MAY": 4.05,
    "JUN": 3.51,
    "JUL": 3.86,
    "AUG": 4.51,
    "SEP": 4.67,
    "OCT": 5.38,
    "NOV": 5.86,
    "DEC": 6.02
  },
  {
    "LONG": -53.37,
    "LAT": -2.98,
    "CIDADE": "Restinga Sêca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.7,
    "FEB": 5.72,
    "MAR": 5.43,
    "APR": 4.75,
    "MAY": 3.82,
    "JUN": 3.29,
    "JUL": 3.56,
    "AUG": 4.19,
    "SEP": 4.2,
    "OCT": 4.88,
    "NOV": 5.68,
    "DEC": 5.83
  },
  {
    "LONG": -52.18,
    "LAT": -2.98,
    "CIDADE": "Vale Verde",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.5,
    "FEB": 5.6,
    "MAR": 5.25,
    "APR": 4.68,
    "MAY": 3.8,
    "JUN": 3.29,
    "JUL": 3.55,
    "AUG": 4.12,
    "SEP": 4.05,
    "OCT": 4.71,
    "NOV": 5.57,
    "DEC": 5.68
  },
  {
    "LONG": -51.87,
    "LAT": -29.79,
    "CIDADE": "Taquari",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.63,
    "FEB": 5.61,
    "MAR": 5.26,
    "APR": 4.69,
    "MAY": 3.74,
    "JUN": 3.24,
    "JUL": 3.5,
    "AUG": 4.17,
    "SEP": 4.01,
    "OCT": 4.69,
    "NOV": 5.6,
    "DEC": 5.71
  },
  {
    "LONG": "#VALOR!",
    "LAT": -29.85,
    "CIDADE": "Nova Santa Rita",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.56,
    "FEB": 5.6,
    "MAR": 5.27,
    "APR": 4.68,
    "MAY": 3.77,
    "JUN": 3.31,
    "JUL": 3.54,
    "AUG": 4.08,
    "SEP": 3.96,
    "OCT": 4.64,
    "NOV": 5.57,
    "DEC": 5.68
  },
  {
    "LONG": -51.15,
    "LAT": -2.98,
    "CIDADE": "São Leopoldo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.56,
    "FEB": 5.55,
    "MAR": 5.18,
    "APR": 4.67,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.5,
    "AUG": 4.1,
    "SEP": 3.96,
    "OCT": 4.63,
    "NOV": 5.54,
    "DEC": 5.66
  },
  {
    "LONG": -51.15,
    "LAT": -29.83,
    "CIDADE": "Sapucaia do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.56,
    "FEB": 5.55,
    "MAR": 5.18,
    "APR": 4.67,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.5,
    "AUG": 4.1,
    "SEP": 3.96,
    "OCT": 4.63,
    "NOV": 5.54,
    "DEC": 5.66
  },
  {
    "LONG": -50.52,
    "LAT": -29.83,
    "CIDADE": "Santo Antônio da Patrulha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.64,
    "FEB": 5.59,
    "MAR": 5.19,
    "APR": 4.71,
    "MAY": 3.81,
    "JUN": 3.32,
    "JUL": 3.59,
    "AUG": 4.11,
    "SEP": 4.01,
    "OCT": 4.7,
    "NOV": 5.61,
    "DEC": 5.71
  },
  {
    "LONG": -5.04,
    "LAT": -29.79,
    "CIDADE": "Caraá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.61,
    "FEB": 5.55,
    "MAR": 5.16,
    "APR": 4.7,
    "MAY": 3.78,
    "JUN": 3.32,
    "JUL": 3.57,
    "AUG": 4.09,
    "SEP": 3.96,
    "OCT": 4.66,
    "NOV": 5.57,
    "DEC": 5.67
  },
  {
    "LONG": -50.03,
    "LAT": -29.76,
    "CIDADE": "Capão da Canoa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.53,
    "FEB": 5.37,
    "MAR": 5.1,
    "APR": 4.77,
    "MAY": 3.95,
    "JUN": 3.44,
    "JUL": 3.66,
    "AUG": 4.13,
    "SEP": 3.98,
    "OCT": 4.56,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -50.04,
    "LAT": -2.98,
    "CIDADE": "Xangri-lá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.53,
    "FEB": 5.37,
    "MAR": 5.1,
    "APR": 4.77,
    "MAY": 3.95,
    "JUN": 3.44,
    "JUL": 3.66,
    "AUG": 4.13,
    "SEP": 3.98,
    "OCT": 4.56,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -5.47,
    "LAT": -29.69,
    "CIDADE": "São Vicente do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.79,
    "FEB": 5.78,
    "MAR": 5.54,
    "APR": 4.88,
    "MAY": 3.95,
    "JUN": 3.42,
    "JUL": 3.73,
    "AUG": 4.37,
    "SEP": 4.36,
    "OCT": 5.03,
    "NOV": 5.73,
    "DEC": 5.94
  },
  {
    "LONG": -54.21,
    "LAT": -29.71,
    "CIDADE": "Dilermando de Aguiar",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.77,
    "FEB": 5.83,
    "MAR": 5.48,
    "APR": 4.83,
    "MAY": 3.87,
    "JUN": 3.36,
    "JUL": 3.63,
    "AUG": 4.31,
    "SEP": 4.32,
    "OCT": 4.95,
    "NOV": 5.69,
    "DEC": 5.91
  },
  {
    "LONG": -53.82,
    "LAT": -29.69,
    "CIDADE": "Santa Maria",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.67,
    "FEB": 5.73,
    "MAR": 5.36,
    "APR": 4.8,
    "MAY": 3.79,
    "JUN": 3.29,
    "JUL": 3.56,
    "AUG": 4.25,
    "SEP": 4.21,
    "OCT": 4.88,
    "NOV": 5.65,
    "DEC": 5.81
  },
  {
    "LONG": -53.14,
    "LAT": -2.97,
    "CIDADE": "Paraíso do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.58,
    "FEB": 5.63,
    "MAR": 5.36,
    "APR": 4.68,
    "MAY": 3.74,
    "JUN": 3.27,
    "JUL": 3.54,
    "AUG": 4.21,
    "SEP": 4.1,
    "OCT": 4.83,
    "NOV": 5.61,
    "DEC": 5.76
  },
  {
    "LONG": -52.94,
    "LAT": -29.66,
    "CIDADE": "Cerro Branco",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.44,
    "FEB": 5.55,
    "MAR": 5.29,
    "APR": 4.69,
    "MAY": 3.76,
    "JUN": 3.29,
    "JUL": 3.53,
    "AUG": 4.17,
    "SEP": 4.04,
    "OCT": 4.72,
    "NOV": 5.5,
    "DEC": 5.64
  },
  {
    "LONG": -52.95,
    "LAT": -29.73,
    "CIDADE": "Novo Cabrais",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.44,
    "FEB": 5.55,
    "MAR": 5.29,
    "APR": 4.69,
    "MAY": 3.76,
    "JUN": 3.29,
    "JUL": 3.53,
    "AUG": 4.17,
    "SEP": 4.04,
    "OCT": 4.72,
    "NOV": 5.5,
    "DEC": 5.64
  },
  {
    "LONG": "#VALOR!",
    "LAT": -29.67,
    "CIDADE": "Candelária",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.49,
    "FEB": 5.59,
    "MAR": 5.29,
    "APR": 4.65,
    "MAY": 3.78,
    "JUN": 3.28,
    "JUL": 3.54,
    "AUG": 4.14,
    "SEP": 4.02,
    "OCT": 4.69,
    "NOV": 5.49,
    "DEC": 5.63
  },
  {
    "LONG": -52.52,
    "LAT": -29.72,
    "CIDADE": "Vera Cruz",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.42,
    "FEB": 5.53,
    "MAR": 5.25,
    "APR": 4.65,
    "MAY": 3.76,
    "JUN": 3.28,
    "JUL": 3.52,
    "AUG": 4.12,
    "SEP": 4,
    "OCT": 4.66,
    "NOV": 5.5,
    "DEC": 5.61
  },
  {
    "LONG": -52.43,
    "LAT": -29.72,
    "CIDADE": "Santa Cruz do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.4,
    "FEB": 5.49,
    "MAR": 5.21,
    "APR": 4.65,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.53,
    "AUG": 4.13,
    "SEP": 4,
    "OCT": 4.63,
    "NOV": 5.51,
    "DEC": 5.61
  },
  {
    "LONG": -52.28,
    "LAT": -29.75,
    "CIDADE": "Passo do Sobrado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.41,
    "FEB": 5.56,
    "MAR": 5.21,
    "APR": 4.65,
    "MAY": 3.74,
    "JUN": 3.25,
    "JUL": 3.5,
    "AUG": 4.1,
    "SEP": 3.99,
    "OCT": 4.64,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -51.46,
    "LAT": -29.69,
    "CIDADE": "Montenegro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.48,
    "FEB": 5.54,
    "MAR": 5.24,
    "APR": 4.65,
    "MAY": 3.77,
    "JUN": 3.27,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.94,
    "OCT": 4.58,
    "NOV": 5.54,
    "DEC": 5.65
  },
  {
    "LONG": -51.32,
    "LAT": -2.97,
    "CIDADE": "Capela de Santana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.5,
    "FEB": 5.55,
    "MAR": 5.26,
    "APR": 4.65,
    "MAY": 3.79,
    "JUN": 3.29,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.95,
    "OCT": 4.61,
    "NOV": 5.55,
    "DEC": 5.66
  },
  {
    "LONG": -51.24,
    "LAT": -2.97,
    "CIDADE": "Portão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.51,
    "FEB": 5.56,
    "MAR": 5.24,
    "APR": 4.64,
    "MAY": 3.8,
    "JUN": 3.3,
    "JUL": 3.53,
    "AUG": 4.11,
    "SEP": 3.98,
    "OCT": 4.61,
    "NOV": 5.51,
    "DEC": 5.67
  },
  {
    "LONG": -51.13,
    "LAT": -2.97,
    "CIDADE": "Novo Hamburgo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.49,
    "FEB": 5.51,
    "MAR": 5.16,
    "APR": 4.63,
    "MAY": 3.81,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.96,
    "OCT": 4.58,
    "NOV": 5.48,
    "DEC": 5.62
  },
  {
    "LONG": -51.18,
    "LAT": -2.97,
    "CIDADE": "Estância Velha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.49,
    "FEB": 5.51,
    "MAR": 5.16,
    "APR": 4.63,
    "MAY": 3.81,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.96,
    "OCT": 4.58,
    "NOV": 5.48,
    "DEC": 5.62
  },
  {
    "LONG": -51.06,
    "LAT": -29.68,
    "CIDADE": "Campo Bom",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.49,
    "FEB": 5.47,
    "MAR": 5.12,
    "APR": 4.63,
    "MAY": 3.8,
    "JUN": 3.29,
    "JUL": 3.5,
    "AUG": 4.06,
    "SEP": 3.96,
    "OCT": 4.57,
    "NOV": 5.48,
    "DEC": 5.59
  },
  {
    "LONG": -50.78,
    "LAT": -2.97,
    "CIDADE": "Taquara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.51,
    "FEB": 5.49,
    "MAR": 5.16,
    "APR": 4.68,
    "MAY": 3.82,
    "JUN": 3.31,
    "JUL": 3.54,
    "AUG": 4.12,
    "SEP": 4.01,
    "OCT": 4.64,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -50.58,
    "LAT": -2.97,
    "CIDADE": "Rolante",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.56,
    "FEB": 5.49,
    "MAR": 5.12,
    "APR": 4.67,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.59,
    "AUG": 4.12,
    "SEP": 3.97,
    "OCT": 4.64,
    "NOV": 5.57,
    "DEC": 5.6
  },
  {
    "LONG": -50.21,
    "LAT": -2.97,
    "CIDADE": "Maquiné",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.35,
    "JAN": 5.08,
    "FEB": 4.96,
    "MAR": 4.7,
    "APR": 4.51,
    "MAY": 3.66,
    "JUN": 3.34,
    "JUL": 3.6,
    "AUG": 4.13,
    "SEP": 3.79,
    "OCT": 4.23,
    "NOV": 5.08,
    "DEC": 5.16
  },
  {
    "LONG": -55.48,
    "LAT": -29.59,
    "CIDADE": "Manoel Viana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.99,
    "JAN": 5.96,
    "FEB": 5.87,
    "MAR": 5.67,
    "APR": 4.98,
    "MAY": 4.04,
    "JUN": 3.52,
    "JUL": 3.78,
    "AUG": 4.49,
    "SEP": 4.56,
    "OCT": 5.22,
    "NOV": 5.77,
    "DEC": 5.98
  },
  {
    "LONG": -55.13,
    "LAT": -29.56,
    "CIDADE": "São Francisco de Assis",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.85,
    "FEB": 5.83,
    "MAR": 5.6,
    "APR": 4.93,
    "MAY": 4,
    "JUN": 3.5,
    "JUL": 3.78,
    "AUG": 4.45,
    "SEP": 4.48,
    "OCT": 5.15,
    "NOV": 5.72,
    "DEC": 5.94
  },
  {
    "LONG": -54.46,
    "LAT": -29.57,
    "CIDADE": "Mata",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.72,
    "FEB": 5.78,
    "MAR": 5.48,
    "APR": 4.85,
    "MAY": 3.89,
    "JUN": 3.4,
    "JUL": 3.7,
    "AUG": 4.34,
    "SEP": 4.33,
    "OCT": 4.97,
    "NOV": 5.67,
    "DEC": 5.88
  },
  {
    "LONG": -5.42,
    "LAT": -29.62,
    "CIDADE": "São Pedro do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.7,
    "FEB": 5.74,
    "MAR": 5.45,
    "APR": 4.83,
    "MAY": 3.87,
    "JUN": 3.36,
    "JUL": 3.67,
    "AUG": 4.32,
    "SEP": 4.29,
    "OCT": 4.94,
    "NOV": 5.68,
    "DEC": 5.85
  },
  {
    "LONG": -53.76,
    "LAT": "#VALOR!",
    "CIDADE": "Itaara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.6,
    "FEB": 5.68,
    "MAR": 5.35,
    "APR": 4.79,
    "MAY": 3.86,
    "JUN": 3.36,
    "JUL": 3.65,
    "AUG": 4.3,
    "SEP": 4.23,
    "OCT": 4.87,
    "NOV": 5.6,
    "DEC": 5.75
  },
  {
    "LONG": -53.59,
    "LAT": -2.96,
    "CIDADE": "Silveira Martins",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.51,
    "FEB": 5.67,
    "MAR": 5.35,
    "APR": 4.75,
    "MAY": 3.84,
    "JUN": 3.33,
    "JUL": 3.61,
    "AUG": 4.29,
    "SEP": 4.19,
    "OCT": 4.83,
    "NOV": 5.63,
    "DEC": 5.72
  },
  {
    "LONG": -53.45,
    "LAT": -2.96,
    "CIDADE": "São João do Polêsine",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.56,
    "FEB": 5.68,
    "MAR": 5.38,
    "APR": 4.75,
    "MAY": 3.82,
    "JUN": 3.32,
    "JUL": 3.6,
    "AUG": 4.27,
    "SEP": 4.15,
    "OCT": 4.85,
    "NOV": 5.63,
    "DEC": 5.75
  },
  {
    "LONG": -53.44,
    "LAT": -2.96,
    "CIDADE": "Faxinal do Soturno",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.56,
    "FEB": 5.68,
    "MAR": 5.38,
    "APR": 4.75,
    "MAY": 3.82,
    "JUN": 3.32,
    "JUL": 3.6,
    "AUG": 4.27,
    "SEP": 4.15,
    "OCT": 4.85,
    "NOV": 5.63,
    "DEC": 5.75
  },
  {
    "LONG": -53.36,
    "LAT": -2.96,
    "CIDADE": "Dona Francisca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.58,
    "FEB": 5.66,
    "MAR": 5.39,
    "APR": 4.73,
    "MAY": 3.81,
    "JUN": 3.31,
    "JUL": 3.58,
    "AUG": 4.26,
    "SEP": 4.12,
    "OCT": 4.84,
    "NOV": 5.61,
    "DEC": 5.75
  },
  {
    "LONG": -53.25,
    "LAT": -29.65,
    "CIDADE": "Agudo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.56,
    "FEB": 5.64,
    "MAR": 5.38,
    "APR": 4.72,
    "MAY": 3.78,
    "JUN": 3.32,
    "JUL": 3.58,
    "AUG": 4.28,
    "SEP": 4.11,
    "OCT": 4.83,
    "NOV": 5.61,
    "DEC": 5.75
  },
  {
    "LONG": -52.69,
    "LAT": -29.6,
    "CIDADE": "Vale do Sol",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.36,
    "FEB": 5.46,
    "MAR": 5.22,
    "APR": 4.63,
    "MAY": 3.75,
    "JUN": 3.29,
    "JUL": 3.56,
    "AUG": 4.17,
    "SEP": 3.99,
    "OCT": 4.63,
    "NOV": 5.45,
    "DEC": 5.55
  },
  {
    "LONG": -52.19,
    "LAT": -29.61,
    "CIDADE": "Venâncio Aires",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.32,
    "FEB": 5.48,
    "MAR": 5.15,
    "APR": 4.66,
    "MAY": 3.75,
    "JUN": 3.26,
    "JUL": 3.52,
    "AUG": 4.15,
    "SEP": 3.99,
    "OCT": 4.57,
    "NOV": 5.49,
    "DEC": 5.54
  },
  {
    "LONG": -51.94,
    "LAT": -29.6,
    "CIDADE": "Bom Retiro do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.5,
    "FEB": 5.58,
    "MAR": 5.22,
    "APR": 4.65,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.54,
    "AUG": 4.17,
    "SEP": 4.03,
    "OCT": 4.62,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -51.82,
    "LAT": -2.96,
    "CIDADE": "Fazenda Vilanova",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.56,
    "FEB": 5.56,
    "MAR": 5.27,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.52,
    "AUG": 4.17,
    "SEP": 4.02,
    "OCT": 4.62,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -51.68,
    "LAT": -29.64,
    "CIDADE": "Tabaí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.44,
    "FEB": 5.51,
    "MAR": 5.2,
    "APR": 4.63,
    "MAY": 3.74,
    "JUN": 3.26,
    "JUL": 3.52,
    "AUG": 4.13,
    "SEP": 3.95,
    "OCT": 4.56,
    "NOV": 5.52,
    "DEC": 5.59
  },
  {
    "LONG": -5.16,
    "LAT": -29.55,
    "CIDADE": "Brochier",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.44,
    "FEB": 5.51,
    "MAR": 5.19,
    "APR": 4.64,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.09,
    "SEP": 3.93,
    "OCT": 4.51,
    "NOV": 5.5,
    "DEC": 5.56
  },
  {
    "LONG": -51.42,
    "LAT": -29.55,
    "CIDADE": "Harmonia",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.41,
    "FEB": 5.49,
    "MAR": 5.18,
    "APR": 4.62,
    "MAY": 3.76,
    "JUN": 3.29,
    "JUL": 3.51,
    "AUG": 4.09,
    "SEP": 3.94,
    "OCT": 4.5,
    "NOV": 5.5,
    "DEC": 5.55
  },
  {
    "LONG": -51.38,
    "LAT": -2.96,
    "CIDADE": "São Sebastião do Caí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.43,
    "FEB": 5.53,
    "MAR": 5.2,
    "APR": 4.63,
    "MAY": 3.79,
    "JUN": 3.31,
    "JUL": 3.52,
    "AUG": 4.11,
    "SEP": 3.94,
    "OCT": 4.55,
    "NOV": 5.51,
    "DEC": 5.6
  },
  {
    "LONG": -51.4,
    "LAT": -2.96,
    "CIDADE": "Pareci Novo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.43,
    "FEB": 5.53,
    "MAR": 5.2,
    "APR": 4.63,
    "MAY": 3.79,
    "JUN": 3.31,
    "JUL": 3.52,
    "AUG": 4.11,
    "SEP": 3.94,
    "OCT": 4.55,
    "NOV": 5.51,
    "DEC": 5.6
  },
  {
    "LONG": -51.21,
    "LAT": -2.96,
    "CIDADE": "Lindolfo Collor",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.46,
    "FEB": 5.53,
    "MAR": 5.22,
    "APR": 4.64,
    "MAY": 3.8,
    "JUN": 3.32,
    "JUL": 3.52,
    "AUG": 4.11,
    "SEP": 3.95,
    "OCT": 4.57,
    "NOV": 5.51,
    "DEC": 5.63
  },
  {
    "LONG": -51.15,
    "LAT": -29.6,
    "CIDADE": "Ivoti",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.47,
    "FEB": 5.52,
    "MAR": 5.16,
    "APR": 4.63,
    "MAY": 3.79,
    "JUN": 3.31,
    "JUL": 3.52,
    "AUG": 4.11,
    "SEP": 3.93,
    "OCT": 4.58,
    "NOV": 5.49,
    "DEC": 5.61
  },
  {
    "LONG": -51.09,
    "LAT": -2.96,
    "CIDADE": "Dois Irmãos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.45,
    "FEB": 5.45,
    "MAR": 5.08,
    "APR": 4.59,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.52,
    "AUG": 4.1,
    "SEP": 3.9,
    "OCT": 4.55,
    "NOV": 5.44,
    "DEC": 5.56
  },
  {
    "LONG": -51.01,
    "LAT": -29.64,
    "CIDADE": "Sapiranga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.45,
    "FEB": 5.45,
    "MAR": 5.08,
    "APR": 4.59,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.52,
    "AUG": 4.1,
    "SEP": 3.9,
    "OCT": 4.55,
    "NOV": 5.44,
    "DEC": 5.56
  },
  {
    "LONG": -50.91,
    "LAT": -29.58,
    "CIDADE": "Nova Hartz",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.52,
    "JAN": 5.36,
    "FEB": 5.36,
    "MAR": 5,
    "APR": 4.56,
    "MAY": 3.74,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.11,
    "SEP": 3.88,
    "OCT": 4.5,
    "NOV": 5.4,
    "DEC": 5.47
  },
  {
    "LONG": -50.93,
    "LAT": -29.62,
    "CIDADE": "Araricá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.52,
    "JAN": 5.36,
    "FEB": 5.36,
    "MAR": 5,
    "APR": 4.56,
    "MAY": 3.74,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.11,
    "SEP": 3.88,
    "OCT": 4.5,
    "NOV": 5.4,
    "DEC": 5.47
  },
  {
    "LONG": -50.83,
    "LAT": -2.96,
    "CIDADE": "Parobé",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.34,
    "FEB": 5.33,
    "MAR": 5,
    "APR": 4.59,
    "MAY": 3.76,
    "JUN": 3.32,
    "JUL": 3.55,
    "AUG": 4.14,
    "SEP": 3.92,
    "OCT": 4.49,
    "NOV": 5.41,
    "DEC": 5.46
  },
  {
    "LONG": -50.79,
    "LAT": -29.57,
    "CIDADE": "Igrejinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.41,
    "FEB": 5.39,
    "MAR": 5.06,
    "APR": 4.63,
    "MAY": 3.78,
    "JUN": 3.34,
    "JUL": 3.56,
    "AUG": 4.15,
    "SEP": 3.95,
    "OCT": 4.55,
    "NOV": 5.48,
    "DEC": 5.51
  },
  {
    "LONG": -50.45,
    "LAT": -29.64,
    "CIDADE": "Riozinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.51,
    "JAN": 5.32,
    "FEB": 5.24,
    "MAR": 4.93,
    "APR": 4.59,
    "MAY": 3.69,
    "JUN": 3.3,
    "JUL": 3.6,
    "AUG": 4.16,
    "SEP": 3.9,
    "OCT": 4.52,
    "NOV": 5.41,
    "DEC": 5.43
  },
  {
    "LONG": -50.06,
    "LAT": -29.58,
    "CIDADE": "Terra de Areia",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.44,
    "JAN": 5.11,
    "FEB": 5.03,
    "MAR": 4.81,
    "APR": 4.63,
    "MAY": 3.86,
    "JUN": 3.45,
    "JUL": 3.68,
    "AUG": 4.2,
    "SEP": 3.86,
    "OCT": 4.26,
    "NOV": 5.15,
    "DEC": 5.2
  },
  {
    "LONG": -54.69,
    "LAT": -2.95,
    "CIDADE": "Jaguari",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.75,
    "FEB": 5.73,
    "MAR": 5.49,
    "APR": 4.85,
    "MAY": 3.96,
    "JUN": 3.45,
    "JUL": 3.73,
    "AUG": 4.38,
    "SEP": 4.37,
    "OCT": 4.99,
    "NOV": 5.71,
    "DEC": 5.87
  },
  {
    "LONG": -54.22,
    "LAT": -29.48,
    "CIDADE": "Toropi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.68,
    "FEB": 5.7,
    "MAR": 5.45,
    "APR": 4.84,
    "MAY": 3.92,
    "JUN": 3.41,
    "JUL": 3.72,
    "AUG": 4.36,
    "SEP": 4.31,
    "OCT": 4.95,
    "NOV": 5.67,
    "DEC": 5.81
  },
  {
    "LONG": -53.85,
    "LAT": -2.95,
    "CIDADE": "São Martinho da Serra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.65,
    "FEB": 5.71,
    "MAR": 5.41,
    "APR": 4.83,
    "MAY": 3.95,
    "JUN": 3.43,
    "JUL": 3.74,
    "AUG": 4.36,
    "SEP": 4.32,
    "OCT": 4.95,
    "NOV": 5.65,
    "DEC": 5.76
  },
  {
    "LONG": -53.58,
    "LAT": "#VALOR!",
    "CIDADE": "Ivorá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.51,
    "FEB": 5.68,
    "MAR": 5.4,
    "APR": 4.76,
    "MAY": 3.88,
    "JUN": 3.34,
    "JUL": 3.64,
    "AUG": 4.31,
    "SEP": 4.22,
    "OCT": 4.86,
    "NOV": 5.63,
    "DEC": 5.74
  },
  {
    "LONG": -53.47,
    "LAT": -29.47,
    "CIDADE": "Nova Palma",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.74,
    "JAN": 5.51,
    "FEB": 5.67,
    "MAR": 5.39,
    "APR": 4.76,
    "MAY": 3.87,
    "JUN": 3.34,
    "JUL": 3.64,
    "AUG": 4.31,
    "SEP": 4.19,
    "OCT": 4.87,
    "NOV": 5.62,
    "DEC": 5.74
  },
  {
    "LONG": -53.01,
    "LAT": -29.49,
    "CIDADE": "Lagoa Bonita do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.46,
    "FEB": 5.63,
    "MAR": 5.34,
    "APR": 4.76,
    "MAY": 3.86,
    "JUN": 3.39,
    "JUL": 3.67,
    "AUG": 4.32,
    "SEP": 4.13,
    "OCT": 4.89,
    "NOV": 5.6,
    "DEC": 5.71
  },
  {
    "LONG": -52.65,
    "LAT": -29.45,
    "CIDADE": "Herveiras",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.37,
    "FEB": 5.45,
    "MAR": 5.16,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.33,
    "JUL": 3.62,
    "AUG": 4.25,
    "SEP": 4.01,
    "OCT": 4.68,
    "NOV": 5.47,
    "DEC": 5.55
  },
  {
    "LONG": -52.52,
    "LAT": -2.95,
    "CIDADE": "Sinimbu",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.36,
    "FEB": 5.44,
    "MAR": 5.14,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.34,
    "JUL": 3.61,
    "AUG": 4.24,
    "SEP": 3.99,
    "OCT": 4.64,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -52.13,
    "LAT": -2.95,
    "CIDADE": "Mato Leitão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.26,
    "FEB": 5.4,
    "MAR": 5.1,
    "APR": 4.63,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.19,
    "SEP": 3.96,
    "OCT": 4.53,
    "NOV": 5.42,
    "DEC": 5.47
  },
  {
    "LONG": -52.09,
    "LAT": -2.95,
    "CIDADE": "Santa Clara do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.35,
    "FEB": 5.48,
    "MAR": 5.15,
    "APR": 4.64,
    "MAY": 3.78,
    "JUN": 3.29,
    "JUL": 3.54,
    "AUG": 4.18,
    "SEP": 3.99,
    "OCT": 4.56,
    "NOV": 5.47,
    "DEC": 5.53
  },
  {
    "LONG": -51.96,
    "LAT": -29.46,
    "CIDADE": "Lajeado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.41,
    "FEB": 5.53,
    "MAR": 5.19,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.56,
    "AUG": 4.17,
    "SEP": 3.99,
    "OCT": 4.59,
    "NOV": 5.53,
    "DEC": 5.57
  },
  {
    "LONG": -51.95,
    "LAT": -29.49,
    "CIDADE": "Estrela",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.41,
    "FEB": 5.53,
    "MAR": 5.19,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.56,
    "AUG": 4.17,
    "SEP": 3.99,
    "OCT": 4.59,
    "NOV": 5.53,
    "DEC": 5.57
  },
  {
    "LONG": -51.98,
    "LAT": -2.95,
    "CIDADE": "Cruzeiro do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.41,
    "FEB": 5.53,
    "MAR": 5.19,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.56,
    "AUG": 4.17,
    "SEP": 3.99,
    "OCT": 4.59,
    "NOV": 5.53,
    "DEC": 5.57
  },
  {
    "LONG": -51.73,
    "LAT": -2.95,
    "CIDADE": "Paverama",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.49,
    "FEB": 5.49,
    "MAR": 5.21,
    "APR": 4.63,
    "MAY": 3.75,
    "JUN": 3.27,
    "JUL": 3.56,
    "AUG": 4.16,
    "SEP": 3.97,
    "OCT": 4.57,
    "NOV": 5.51,
    "DEC": 5.57
  },
  {
    "LONG": -51.56,
    "LAT": -29.55,
    "CIDADE": "Maratá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.42,
    "FEB": 5.43,
    "MAR": 5.11,
    "APR": 4.62,
    "MAY": 3.73,
    "JUN": 3.3,
    "JUL": 3.56,
    "AUG": 4.11,
    "SEP": 3.92,
    "OCT": 4.5,
    "NOV": 5.45,
    "DEC": 5.49
  },
  {
    "LONG": -51.49,
    "LAT": -29.54,
    "CIDADE": "São José do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.33,
    "FEB": 5.39,
    "MAR": 5.09,
    "APR": 4.59,
    "MAY": 3.75,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.12,
    "SEP": 3.91,
    "OCT": 4.46,
    "NOV": 5.42,
    "DEC": 5.46
  },
  {
    "LONG": -51.42,
    "LAT": -29.48,
    "CIDADE": "Tupandi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.33,
    "FEB": 5.39,
    "MAR": 5.09,
    "APR": 4.59,
    "MAY": 3.75,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.12,
    "SEP": 3.91,
    "OCT": 4.46,
    "NOV": 5.42,
    "DEC": 5.46
  },
  {
    "LONG": -51.3,
    "LAT": -29.45,
    "CIDADE": "Feliz",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.36,
    "FEB": 5.41,
    "MAR": 5.11,
    "APR": 4.62,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.55,
    "AUG": 4.12,
    "SEP": 3.92,
    "OCT": 4.51,
    "NOV": 5.46,
    "DEC": 5.5
  },
  {
    "LONG": -51.36,
    "LAT": -29.49,
    "CIDADE": "Bom Princípio",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.36,
    "FEB": 5.41,
    "MAR": 5.11,
    "APR": 4.62,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.55,
    "AUG": 4.12,
    "SEP": 3.92,
    "OCT": 4.51,
    "NOV": 5.46,
    "DEC": 5.5
  },
  {
    "LONG": -51.2,
    "LAT": -29.47,
    "CIDADE": "Linha Nova",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.15,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.31,
    "JUL": 3.56,
    "AUG": 4.13,
    "SEP": 3.94,
    "OCT": 4.54,
    "NOV": 5.5,
    "DEC": 5.57
  },
  {
    "LONG": -51.25,
    "LAT": -29.52,
    "CIDADE": "São José do Hortêncio",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.15,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.31,
    "JUL": 3.56,
    "AUG": 4.13,
    "SEP": 3.94,
    "OCT": 4.54,
    "NOV": 5.5,
    "DEC": 5.57
  },
  {
    "LONG": -51.13,
    "LAT": -29.45,
    "CIDADE": "Picada Café",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.47,
    "FEB": 5.5,
    "MAR": 5.14,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.33,
    "JUL": 3.58,
    "AUG": 4.16,
    "SEP": 3.96,
    "OCT": 4.6,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -51.18,
    "LAT": -29.52,
    "CIDADE": "Presidente Lucena",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.47,
    "FEB": 5.5,
    "MAR": 5.14,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.33,
    "JUL": 3.58,
    "AUG": 4.16,
    "SEP": 3.96,
    "OCT": 4.6,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -51.08,
    "LAT": -29.54,
    "CIDADE": "Morro Reuter",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.46,
    "FEB": 5.45,
    "MAR": 5.07,
    "APR": 4.6,
    "MAY": 3.76,
    "JUN": 3.33,
    "JUL": 3.59,
    "AUG": 4.17,
    "SEP": 3.95,
    "OCT": 4.58,
    "NOV": 5.47,
    "DEC": 5.56
  },
  {
    "LONG": -50.99,
    "LAT": -29.49,
    "CIDADE": "Santa Maria do Herval",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.3,
    "FEB": 5.32,
    "MAR": 4.93,
    "APR": 4.56,
    "MAY": 3.73,
    "JUN": 3.34,
    "JUL": 3.6,
    "AUG": 4.18,
    "SEP": 3.91,
    "OCT": 4.55,
    "NOV": 5.42,
    "DEC": 5.48
  },
  {
    "LONG": -50.77,
    "LAT": -29.51,
    "CIDADE": "Três Coroas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.54,
    "JAN": 5.32,
    "FEB": 5.35,
    "MAR": 4.99,
    "APR": 4.61,
    "MAY": 3.76,
    "JUN": 3.36,
    "JUL": 3.6,
    "AUG": 4.2,
    "SEP": 3.92,
    "OCT": 4.52,
    "NOV": 5.42,
    "DEC": 5.45
  },
  {
    "LONG": -5.01,
    "LAT": -29.49,
    "CIDADE": "Itati",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.32,
    "JAN": 4.85,
    "FEB": 4.79,
    "MAR": 4.58,
    "APR": 4.51,
    "MAY": 3.74,
    "JUN": 3.4,
    "JUL": 3.69,
    "AUG": 4.24,
    "SEP": 3.8,
    "OCT": 4.2,
    "NOV": 5,
    "DEC": 5.05
  },
  {
    "LONG": -50.06,
    "LAT": -2.95,
    "CIDADE": "Três Forquilhas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.33,
    "JAN": 4.9,
    "FEB": 4.85,
    "MAR": 4.61,
    "APR": 4.54,
    "MAY": 3.79,
    "JUN": 3.4,
    "JUL": 3.66,
    "AUG": 4.22,
    "SEP": 3.77,
    "OCT": 4.14,
    "NOV": 4.99,
    "DEC": 5.04
  },
  {
    "LONG": -49.92,
    "LAT": -2.95,
    "CIDADE": "Três Cachoeiras",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.33,
    "JAN": 4.91,
    "FEB": 4.86,
    "MAR": 4.63,
    "APR": 4.57,
    "MAY": 3.89,
    "JUN": 3.44,
    "JUL": 3.65,
    "AUG": 4.23,
    "SEP": 3.78,
    "OCT": 4.07,
    "NOV": 4.97,
    "DEC": 4.94
  },
  {
    "LONG": "#VALOR!",
    "LAT": -29.54,
    "CIDADE": "Arroio do Sal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.49,
    "JAN": 5.17,
    "FEB": 5.16,
    "MAR": 4.88,
    "APR": 4.7,
    "MAY": 3.99,
    "JUN": 3.43,
    "JUL": 3.66,
    "AUG": 4.21,
    "SEP": 3.9,
    "OCT": 4.31,
    "NOV": 5.21,
    "DEC": 5.26
  },
  {
    "LONG": -54.83,
    "LAT": -29.41,
    "CIDADE": "Nova Esperança do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.73,
    "FEB": 5.76,
    "MAR": 5.52,
    "APR": 4.9,
    "MAY": 3.96,
    "JUN": 3.49,
    "JUL": 3.8,
    "AUG": 4.42,
    "SEP": 4.45,
    "OCT": 5.03,
    "NOV": 5.73,
    "DEC": 5.84
  },
  {
    "LONG": -54.07,
    "LAT": -2.94,
    "CIDADE": "Quevedos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.63,
    "FEB": 5.69,
    "MAR": 5.44,
    "APR": 4.84,
    "MAY": 3.94,
    "JUN": 3.44,
    "JUL": 3.77,
    "AUG": 4.41,
    "SEP": 4.35,
    "OCT": 4.93,
    "NOV": 5.64,
    "DEC": 5.76
  },
  {
    "LONG": "#VALOR!",
    "LAT": -29.42,
    "CIDADE": "Ibarama",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.76,
    "JAN": 5.49,
    "FEB": 5.67,
    "MAR": 5.44,
    "APR": 4.79,
    "MAY": 3.87,
    "JUN": 3.36,
    "JUL": 3.68,
    "AUG": 4.34,
    "SEP": 4.17,
    "OCT": 4.9,
    "NOV": 5.64,
    "DEC": 5.75
  },
  {
    "LONG": -53.03,
    "LAT": -29.42,
    "CIDADE": "Sobradinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.77,
    "JAN": 5.52,
    "FEB": 5.68,
    "MAR": 5.41,
    "APR": 4.8,
    "MAY": 3.89,
    "JUN": 3.4,
    "JUL": 3.69,
    "AUG": 4.36,
    "SEP": 4.17,
    "OCT": 4.92,
    "NOV": 5.66,
    "DEC": 5.73
  },
  {
    "LONG": -52.98,
    "LAT": -29.35,
    "CIDADE": "Segredo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.45,
    "FEB": 5.66,
    "MAR": 5.41,
    "APR": 4.77,
    "MAY": 3.87,
    "JUN": 3.41,
    "JUL": 3.7,
    "AUG": 4.36,
    "SEP": 4.15,
    "OCT": 4.9,
    "NOV": 5.64,
    "DEC": 5.72
  },
  {
    "LONG": -52.97,
    "LAT": -29.45,
    "CIDADE": "Passa Sete",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.45,
    "FEB": 5.66,
    "MAR": 5.41,
    "APR": 4.77,
    "MAY": 3.87,
    "JUN": 3.41,
    "JUL": 3.7,
    "AUG": 4.36,
    "SEP": 4.15,
    "OCT": 4.9,
    "NOV": 5.64,
    "DEC": 5.72
  },
  {
    "LONG": -52.27,
    "LAT": -2.94,
    "CIDADE": "Sério",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.25,
    "FEB": 5.36,
    "MAR": 5.07,
    "APR": 4.57,
    "MAY": 3.75,
    "JUN": 3.32,
    "JUL": 3.59,
    "AUG": 4.22,
    "SEP": 3.98,
    "OCT": 4.54,
    "NOV": 5.43,
    "DEC": 5.45
  },
  {
    "LONG": -52.1,
    "LAT": -29.38,
    "CIDADE": "Forquetinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.34,
    "FEB": 5.47,
    "MAR": 5.16,
    "APR": 4.62,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.57,
    "AUG": 4.2,
    "SEP": 3.98,
    "OCT": 4.6,
    "NOV": 5.46,
    "DEC": 5.53
  },
  {
    "LONG": -51.94,
    "LAT": -2.94,
    "CIDADE": "Arroio do Meio",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.36,
    "FEB": 5.49,
    "MAR": 5.16,
    "APR": 4.61,
    "MAY": 3.76,
    "JUN": 3.27,
    "JUL": 3.58,
    "AUG": 4.19,
    "SEP": 3.96,
    "OCT": 4.58,
    "NOV": 5.47,
    "DEC": 5.52
  },
  {
    "LONG": -51.8,
    "LAT": -29.45,
    "CIDADE": "Teutônia",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.47,
    "FEB": 5.53,
    "MAR": 5.23,
    "APR": 4.6,
    "MAY": 3.78,
    "JUN": 3.27,
    "JUL": 3.55,
    "AUG": 4.19,
    "SEP": 3.97,
    "OCT": 4.61,
    "NOV": 5.51,
    "DEC": 5.54
  },
  {
    "LONG": -51.85,
    "LAT": -29.4,
    "CIDADE": "Colinas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.47,
    "FEB": 5.53,
    "MAR": 5.23,
    "APR": 4.6,
    "MAY": 3.78,
    "JUN": 3.27,
    "JUL": 3.55,
    "AUG": 4.19,
    "SEP": 3.97,
    "OCT": 4.61,
    "NOV": 5.51,
    "DEC": 5.54
  },
  {
    "LONG": -51.76,
    "LAT": -29.43,
    "CIDADE": "Westfália",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.47,
    "FEB": 5.47,
    "MAR": 5.17,
    "APR": 4.61,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.59,
    "AUG": 4.2,
    "SEP": 3.96,
    "OCT": 4.59,
    "NOV": 5.5,
    "DEC": 5.53
  },
  {
    "LONG": -51.78,
    "LAT": -29.35,
    "CIDADE": "Imigrante",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.47,
    "FEB": 5.47,
    "MAR": 5.17,
    "APR": 4.61,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.59,
    "AUG": 4.2,
    "SEP": 3.96,
    "OCT": 4.59,
    "NOV": 5.5,
    "DEC": 5.53
  },
  {
    "LONG": -51.67,
    "LAT": -29.36,
    "CIDADE": "Boa Vista do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.46,
    "FEB": 5.42,
    "MAR": 5.12,
    "APR": 4.62,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.61,
    "AUG": 4.21,
    "SEP": 3.95,
    "OCT": 4.59,
    "NOV": 5.48,
    "DEC": 5.5
  },
  {
    "LONG": -51.67,
    "LAT": -29.45,
    "CIDADE": "Poço das Antas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.46,
    "FEB": 5.42,
    "MAR": 5.12,
    "APR": 4.62,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.61,
    "AUG": 4.21,
    "SEP": 3.95,
    "OCT": 4.59,
    "NOV": 5.48,
    "DEC": 5.5
  },
  {
    "LONG": -51.5,
    "LAT": -29.38,
    "CIDADE": "Barão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.41,
    "FEB": 5.4,
    "MAR": 5.07,
    "APR": 4.62,
    "MAY": 3.74,
    "JUN": 3.32,
    "JUL": 3.63,
    "AUG": 4.19,
    "SEP": 3.95,
    "OCT": 4.58,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -51.51,
    "LAT": -29.44,
    "CIDADE": "Salvador do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.41,
    "FEB": 5.4,
    "MAR": 5.07,
    "APR": 4.62,
    "MAY": 3.74,
    "JUN": 3.32,
    "JUL": 3.63,
    "AUG": 4.19,
    "SEP": 3.95,
    "OCT": 4.58,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -51.51,
    "LAT": -29.42,
    "CIDADE": "São Pedro da Serra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.41,
    "FEB": 5.4,
    "MAR": 5.07,
    "APR": 4.62,
    "MAY": 3.74,
    "JUN": 3.32,
    "JUL": 3.63,
    "AUG": 4.19,
    "SEP": 3.95,
    "OCT": 4.58,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -51.31,
    "LAT": -29.38,
    "CIDADE": "Alto Feliz",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.35,
    "FEB": 5.35,
    "MAR": 5.07,
    "APR": 4.62,
    "MAY": 3.76,
    "JUN": 3.32,
    "JUL": 3.6,
    "AUG": 4.18,
    "SEP": 3.94,
    "OCT": 4.52,
    "NOV": 5.41,
    "DEC": 5.46
  },
  {
    "LONG": -51.37,
    "LAT": -29.37,
    "CIDADE": "São Vendelino",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.35,
    "FEB": 5.35,
    "MAR": 5.07,
    "APR": 4.62,
    "MAY": 3.76,
    "JUN": 3.32,
    "JUL": 3.6,
    "AUG": 4.18,
    "SEP": 3.94,
    "OCT": 4.52,
    "NOV": 5.41,
    "DEC": 5.46
  },
  {
    "LONG": -51.26,
    "LAT": -29.39,
    "CIDADE": "Vale Real",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.4,
    "FEB": 5.43,
    "MAR": 5.1,
    "APR": 4.63,
    "MAY": 3.75,
    "JUN": 3.32,
    "JUL": 3.61,
    "AUG": 4.17,
    "SEP": 3.94,
    "OCT": 4.54,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -5.11,
    "LAT": -29.37,
    "CIDADE": "Nova Petrópolis",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.44,
    "FEB": 5.44,
    "MAR": 5.08,
    "APR": 4.61,
    "MAY": 3.75,
    "JUN": 3.31,
    "JUL": 3.61,
    "AUG": 4.17,
    "SEP": 3.97,
    "OCT": 4.57,
    "NOV": 5.45,
    "DEC": 5.51
  },
  {
    "LONG": -50.81,
    "LAT": -29.36,
    "CIDADE": "Canela",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.26,
    "FEB": 5.31,
    "MAR": 4.94,
    "APR": 4.58,
    "MAY": 3.74,
    "JUN": 3.35,
    "JUL": 3.62,
    "AUG": 4.23,
    "SEP": 3.93,
    "OCT": 4.55,
    "NOV": 5.39,
    "DEC": 5.46
  },
  {
    "LONG": -50.88,
    "LAT": -29.37,
    "CIDADE": "Gramado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.26,
    "FEB": 5.31,
    "MAR": 4.94,
    "APR": 4.58,
    "MAY": 3.74,
    "JUN": 3.35,
    "JUL": 3.62,
    "AUG": 4.23,
    "SEP": 3.93,
    "OCT": 4.55,
    "NOV": 5.39,
    "DEC": 5.46
  },
  {
    "LONG": -50.58,
    "LAT": -29.45,
    "CIDADE": "São Francisco de Paula",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.33,
    "FEB": 5.3,
    "MAR": 4.92,
    "APR": 4.66,
    "MAY": 3.75,
    "JUN": 3.41,
    "JUL": 3.71,
    "AUG": 4.28,
    "SEP": 3.99,
    "OCT": 4.64,
    "NOV": 5.43,
    "DEC": 5.45
  },
  {
    "LONG": -49.93,
    "LAT": -2.94,
    "CIDADE": "Morrinhos do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.22,
    "JAN": 4.71,
    "FEB": 4.75,
    "MAR": 4.49,
    "APR": 4.45,
    "MAY": 3.82,
    "JUN": 3.37,
    "JUL": 3.63,
    "AUG": 4.22,
    "SEP": 3.72,
    "OCT": 3.89,
    "NOV": 4.75,
    "DEC": 4.79
  },
  {
    "LONG": -49.85,
    "LAT": -29.37,
    "CIDADE": "Dom Pedro de Alcântara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.42,
    "JAN": 5.02,
    "FEB": 5.06,
    "MAR": 4.77,
    "APR": 4.64,
    "MAY": 3.95,
    "JUN": 3.43,
    "JUL": 3.68,
    "AUG": 4.22,
    "SEP": 3.85,
    "OCT": 4.15,
    "NOV": 5.09,
    "DEC": 5.13
  },
  {
    "LONG": -54.22,
    "LAT": -29.29,
    "CIDADE": "Jari",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.69,
    "FEB": 5.77,
    "MAR": 5.51,
    "APR": 4.88,
    "MAY": 4.02,
    "JUN": 3.48,
    "JUL": 3.84,
    "AUG": 4.47,
    "SEP": 4.41,
    "OCT": 5,
    "NOV": 5.69,
    "DEC": 5.8
  },
  {
    "LONG": -53.34,
    "LAT": -29.34,
    "CIDADE": "Pinhal Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.56,
    "FEB": 5.72,
    "MAR": 5.44,
    "APR": 4.78,
    "MAY": 3.96,
    "JUN": 3.39,
    "JUL": 3.74,
    "AUG": 4.42,
    "SEP": 4.28,
    "OCT": 4.93,
    "NOV": 5.66,
    "DEC": 5.72
  },
  {
    "LONG": -53.09,
    "LAT": -29.33,
    "CIDADE": "Arroio do Tigre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.54,
    "FEB": 5.72,
    "MAR": 5.44,
    "APR": 4.8,
    "MAY": 3.92,
    "JUN": 3.41,
    "JUL": 3.72,
    "AUG": 4.41,
    "SEP": 4.19,
    "OCT": 4.93,
    "NOV": 5.65,
    "DEC": 5.76
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.93,
    "CIDADE": "Gramado Xavier",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.42,
    "FEB": 5.46,
    "MAR": 5.23,
    "APR": 4.72,
    "MAY": 3.81,
    "JUN": 3.37,
    "JUL": 3.7,
    "AUG": 4.34,
    "SEP": 4.04,
    "OCT": 4.74,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -52.43,
    "LAT": -29.31,
    "CIDADE": "Boqueirão do Leão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.38,
    "FEB": 5.45,
    "MAR": 5.2,
    "APR": 4.69,
    "MAY": 3.82,
    "JUN": 3.37,
    "JUL": 3.67,
    "AUG": 4.32,
    "SEP": 4.05,
    "OCT": 4.69,
    "NOV": 5.51,
    "DEC": 5.55
  },
  {
    "LONG": -52.24,
    "LAT": -29.33,
    "CIDADE": "Canudos do Vale",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.39,
    "FEB": 5.46,
    "MAR": 5.16,
    "APR": 4.63,
    "MAY": 3.77,
    "JUN": 3.33,
    "JUL": 3.64,
    "AUG": 4.28,
    "SEP": 4.03,
    "OCT": 4.62,
    "NOV": 5.47,
    "DEC": 5.54
  },
  {
    "LONG": -52.05,
    "LAT": -29.3,
    "CIDADE": "Travesseiro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.36,
    "FEB": 5.47,
    "MAR": 5.14,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.6,
    "AUG": 4.23,
    "SEP": 3.99,
    "OCT": 4.6,
    "NOV": 5.45,
    "DEC": 5.52
  },
  {
    "LONG": -52.1,
    "LAT": -29.33,
    "CIDADE": "Marques de Souza",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.36,
    "FEB": 5.47,
    "MAR": 5.14,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.6,
    "AUG": 4.23,
    "SEP": 3.99,
    "OCT": 4.6,
    "NOV": 5.45,
    "DEC": 5.52
  },
  {
    "LONG": -51.99,
    "LAT": -29.27,
    "CIDADE": "Capitão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.35,
    "FEB": 5.45,
    "MAR": 5.12,
    "APR": 4.61,
    "MAY": 3.76,
    "JUN": 3.27,
    "JUL": 3.58,
    "AUG": 4.24,
    "SEP": 4,
    "OCT": 4.59,
    "NOV": 5.46,
    "DEC": 5.51
  },
  {
    "LONG": -51.87,
    "LAT": -29.29,
    "CIDADE": "Roca Sales",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.47,
    "FEB": 5.51,
    "MAR": 5.19,
    "APR": 4.6,
    "MAY": 3.76,
    "JUN": 3.24,
    "JUL": 3.59,
    "AUG": 4.23,
    "SEP": 4.01,
    "OCT": 4.65,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -51.69,
    "LAT": "#VALOR!",
    "CIDADE": "Coronel Pilar",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.17,
    "APR": 4.65,
    "MAY": 3.75,
    "JUN": 3.3,
    "JUL": 3.68,
    "AUG": 4.29,
    "SEP": 4.02,
    "OCT": 4.68,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -51.5,
    "LAT": -29.3,
    "CIDADE": "Carlos Barbosa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.43,
    "FEB": 5.44,
    "MAR": 5.09,
    "APR": 4.67,
    "MAY": 3.77,
    "JUN": 3.35,
    "JUL": 3.67,
    "AUG": 4.3,
    "SEP": 4.02,
    "OCT": 4.67,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -51.54,
    "LAT": -29.26,
    "CIDADE": "Garibaldi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.43,
    "FEB": 5.44,
    "MAR": 5.09,
    "APR": 4.67,
    "MAY": 3.77,
    "JUN": 3.35,
    "JUL": 3.67,
    "AUG": 4.3,
    "SEP": 4.02,
    "OCT": 4.67,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -49.73,
    "LAT": -29.33,
    "CIDADE": "Torres",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.5,
    "JAN": 5.19,
    "FEB": 5.2,
    "MAR": 4.91,
    "APR": 4.69,
    "MAY": 4,
    "JUN": 3.45,
    "JUL": 3.68,
    "AUG": 4.25,
    "SEP": 3.91,
    "OCT": 4.28,
    "NOV": 5.21,
    "DEC": 5.26
  },
  {
    "LONG": -54.87,
    "LAT": -29.19,
    "CIDADE": "Santiago",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.72,
    "FEB": 5.8,
    "MAR": 5.6,
    "APR": 4.96,
    "MAY": 4.05,
    "JUN": 3.49,
    "JUL": 3.89,
    "AUG": 4.48,
    "SEP": 4.52,
    "OCT": 5.11,
    "NOV": 5.8,
    "DEC": 5.87
  },
  {
    "LONG": -53.68,
    "LAT": -29.23,
    "CIDADE": "Júlio de Castilhos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.6,
    "FEB": 5.69,
    "MAR": 5.45,
    "APR": 4.84,
    "MAY": 3.97,
    "JUN": 3.43,
    "JUL": 3.8,
    "AUG": 4.49,
    "SEP": 4.36,
    "OCT": 4.96,
    "NOV": 5.68,
    "DEC": 5.78
  },
  {
    "LONG": -53.16,
    "LAT": -2.92,
    "CIDADE": "Estrela Velha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.6,
    "FEB": 5.71,
    "MAR": 5.44,
    "APR": 4.78,
    "MAY": 3.94,
    "JUN": 3.4,
    "JUL": 3.73,
    "AUG": 4.45,
    "SEP": 4.24,
    "OCT": 4.92,
    "NOV": 5.65,
    "DEC": 5.75
  },
  {
    "LONG": -5.28,
    "LAT": -29.22,
    "CIDADE": "Lagoão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.48,
    "FEB": 5.62,
    "MAR": 5.33,
    "APR": 4.8,
    "MAY": 3.88,
    "JUN": 3.41,
    "JUL": 3.74,
    "AUG": 4.4,
    "SEP": 4.15,
    "OCT": 4.86,
    "NOV": 5.63,
    "DEC": 5.68
  },
  {
    "LONG": -52.31,
    "LAT": -2.92,
    "CIDADE": "Progresso",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.4,
    "FEB": 5.49,
    "MAR": 5.21,
    "APR": 4.68,
    "MAY": 3.8,
    "JUN": 3.31,
    "JUL": 3.64,
    "AUG": 4.34,
    "SEP": 4.05,
    "OCT": 4.68,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -52.21,
    "LAT": -2.92,
    "CIDADE": "Pouso Novo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.4,
    "FEB": 5.46,
    "MAR": 5.17,
    "APR": 4.63,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.61,
    "AUG": 4.29,
    "SEP": 4.03,
    "OCT": 4.63,
    "NOV": 5.46,
    "DEC": 5.55
  },
  {
    "LONG": -52.1,
    "LAT": -29.18,
    "CIDADE": "Coqueiro Baixo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.39,
    "FEB": 5.47,
    "MAR": 5.13,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.29,
    "JUL": 3.62,
    "AUG": 4.27,
    "SEP": 4.02,
    "OCT": 4.6,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -52.03,
    "LAT": -2.92,
    "CIDADE": "Nova Bréscia",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.39,
    "FEB": 5.47,
    "MAR": 5.13,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.29,
    "JUL": 3.62,
    "AUG": 4.27,
    "SEP": 4.02,
    "OCT": 4.6,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -51.87,
    "LAT": -29.17,
    "CIDADE": "Muçum",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.48,
    "FEB": 5.52,
    "MAR": 5.19,
    "APR": 4.63,
    "MAY": 3.76,
    "JUN": 3.28,
    "JUL": 3.59,
    "AUG": 4.24,
    "SEP": 4.04,
    "OCT": 4.67,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -51.87,
    "LAT": -29.24,
    "CIDADE": "Encantado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.48,
    "FEB": 5.52,
    "MAR": 5.19,
    "APR": 4.63,
    "MAY": 3.76,
    "JUN": 3.28,
    "JUL": 3.59,
    "AUG": 4.24,
    "SEP": 4.04,
    "OCT": 4.67,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -51.74,
    "LAT": -29.17,
    "CIDADE": "Santa Tereza",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.46,
    "FEB": 5.51,
    "MAR": 5.19,
    "APR": 4.66,
    "MAY": 3.76,
    "JUN": 3.28,
    "JUL": 3.64,
    "AUG": 4.27,
    "SEP": 4.04,
    "OCT": 4.68,
    "NOV": 5.52,
    "DEC": 5.58
  },
  {
    "LONG": -51.63,
    "LAT": -29.16,
    "CIDADE": "Monte Belo do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.47,
    "FEB": 5.51,
    "MAR": 5.19,
    "APR": 4.68,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.68,
    "AUG": 4.3,
    "SEP": 4.05,
    "OCT": 4.71,
    "NOV": 5.54,
    "DEC": 5.6
  },
  {
    "LONG": -51.52,
    "LAT": -29.17,
    "CIDADE": "Bento Gonçalves",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.45,
    "FEB": 5.53,
    "MAR": 5.13,
    "APR": 4.68,
    "MAY": 3.78,
    "JUN": 3.35,
    "JUL": 3.68,
    "AUG": 4.33,
    "SEP": 4.06,
    "OCT": 4.73,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -51.34,
    "LAT": -29.22,
    "CIDADE": "Farroupilha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.45,
    "FEB": 5.45,
    "MAR": 5.09,
    "APR": 4.67,
    "MAY": 3.76,
    "JUN": 3.35,
    "JUL": 3.69,
    "AUG": 4.33,
    "SEP": 4.06,
    "OCT": 4.7,
    "NOV": 5.56,
    "DEC": 5.56
  },
  {
    "LONG": -51.18,
    "LAT": -29.16,
    "CIDADE": "Caxias do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.38,
    "FEB": 5.4,
    "MAR": 4.99,
    "APR": 4.63,
    "MAY": 3.75,
    "JUN": 3.35,
    "JUL": 3.74,
    "AUG": 4.32,
    "SEP": 4.04,
    "OCT": 4.67,
    "NOV": 5.48,
    "DEC": 5.5
  },
  {
    "LONG": -4.99,
    "LAT": -29.21,
    "CIDADE": "Mampituba",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.21,
    "JAN": 4.6,
    "FEB": 4.66,
    "MAR": 4.47,
    "APR": 4.45,
    "MAY": 3.82,
    "JUN": 3.41,
    "JUL": 3.68,
    "AUG": 4.26,
    "SEP": 3.73,
    "OCT": 3.94,
    "NOV": 4.78,
    "DEC": 4.77
  },
  {
    "LONG": -5.66,
    "LAT": -29.13,
    "CIDADE": "Itaqui",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.08,
    "JAN": 6.04,
    "FEB": 6,
    "MAR": 5.74,
    "APR": 5.07,
    "MAY": 4.09,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.59,
    "SEP": 4.68,
    "OCT": 5.37,
    "NOV": 5.85,
    "DEC": 6.03
  },
  {
    "LONG": -56.07,
    "LAT": -29.14,
    "CIDADE": "Maçambará",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.05,
    "JAN": 6,
    "FEB": 5.99,
    "MAR": 5.72,
    "APR": 4.98,
    "MAY": 4.12,
    "JUN": 3.53,
    "JUL": 3.89,
    "AUG": 4.57,
    "SEP": 4.64,
    "OCT": 5.34,
    "NOV": 5.83,
    "DEC": 5.99
  },
  {
    "LONG": -53.84,
    "LAT": -2.91,
    "CIDADE": "Tupanciretã",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.67,
    "FEB": 5.74,
    "MAR": 5.5,
    "APR": 4.89,
    "MAY": 4.02,
    "JUN": 3.48,
    "JUL": 3.81,
    "AUG": 4.49,
    "SEP": 4.39,
    "OCT": 4.98,
    "NOV": 5.7,
    "DEC": 5.76
  },
  {
    "LONG": -53.21,
    "LAT": -29.1,
    "CIDADE": "Salto do Jacuí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.6,
    "FEB": 5.66,
    "MAR": 5.43,
    "APR": 4.79,
    "MAY": 3.97,
    "JUN": 3.4,
    "JUL": 3.74,
    "AUG": 4.44,
    "SEP": 4.25,
    "OCT": 4.93,
    "NOV": 5.63,
    "DEC": 5.73
  },
  {
    "LONG": -52.95,
    "LAT": -29.1,
    "CIDADE": "Tunas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.59,
    "FEB": 5.67,
    "MAR": 5.46,
    "APR": 4.79,
    "MAY": 3.97,
    "JUN": 3.41,
    "JUL": 3.73,
    "AUG": 4.45,
    "SEP": 4.22,
    "OCT": 4.93,
    "NOV": 5.66,
    "DEC": 5.72
  },
  {
    "LONG": -52.58,
    "LAT": -2.91,
    "CIDADE": "Barros Cassal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.49,
    "FEB": 5.53,
    "MAR": 5.3,
    "APR": 4.76,
    "MAY": 3.89,
    "JUN": 3.41,
    "JUL": 3.73,
    "AUG": 4.39,
    "SEP": 4.14,
    "OCT": 4.78,
    "NOV": 5.57,
    "DEC": 5.6
  },
  {
    "LONG": -52.08,
    "LAT": -29.12,
    "CIDADE": "Relvado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.35,
    "FEB": 5.43,
    "MAR": 5.1,
    "APR": 4.6,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.67,
    "AUG": 4.3,
    "SEP": 4.05,
    "OCT": 4.6,
    "NOV": 5.44,
    "DEC": 5.48
  },
  {
    "LONG": -51.99,
    "LAT": -2.91,
    "CIDADE": "Doutor Ricardo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.38,
    "FEB": 5.47,
    "MAR": 5.12,
    "APR": 4.61,
    "MAY": 3.75,
    "JUN": 3.26,
    "JUL": 3.63,
    "AUG": 4.29,
    "SEP": 4.06,
    "OCT": 4.63,
    "NOV": 5.47,
    "DEC": 5.49
  },
  {
    "LONG": -51.86,
    "LAT": -2.91,
    "CIDADE": "Vespasiano Corrêa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.45,
    "FEB": 5.51,
    "MAR": 5.2,
    "APR": 4.63,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.64,
    "AUG": 4.29,
    "SEP": 4.07,
    "OCT": 4.66,
    "NOV": 5.49,
    "DEC": 5.55
  },
  {
    "LONG": -5.15,
    "LAT": -29.1,
    "CIDADE": "Pinto Bandeira",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.49,
    "FEB": 5.51,
    "MAR": 5.18,
    "APR": 4.67,
    "MAY": 3.81,
    "JUN": 3.33,
    "JUL": 3.67,
    "AUG": 4.36,
    "SEP": 4.09,
    "OCT": 4.73,
    "NOV": 5.56,
    "DEC": 5.59
  },
  {
    "LONG": -55.15,
    "LAT": -29.05,
    "CIDADE": "Unistalda",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5,
    "JAN": 5.78,
    "FEB": 5.88,
    "MAR": 5.64,
    "APR": 4.98,
    "MAY": 4.11,
    "JUN": 3.56,
    "JUL": 3.95,
    "AUG": 4.55,
    "SEP": 4.56,
    "OCT": 5.22,
    "NOV": 5.84,
    "DEC": 5.92
  },
  {
    "LONG": -53.06,
    "LAT": -29.03,
    "CIDADE": "Jacuizinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.63,
    "FEB": 5.69,
    "MAR": 5.46,
    "APR": 4.82,
    "MAY": 3.98,
    "JUN": 3.42,
    "JUL": 3.76,
    "AUG": 4.47,
    "SEP": 4.28,
    "OCT": 4.95,
    "NOV": 5.68,
    "DEC": 5.77
  },
  {
    "LONG": -52.34,
    "LAT": -28.98,
    "CIDADE": "Fontoura Xavier",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.38,
    "FEB": 5.49,
    "MAR": 5.21,
    "APR": 4.71,
    "MAY": 3.86,
    "JUN": 3.4,
    "JUL": 3.77,
    "AUG": 4.4,
    "SEP": 4.14,
    "OCT": 4.77,
    "NOV": 5.53,
    "DEC": 5.56
  },
  {
    "LONG": -52.3,
    "LAT": -2.9,
    "CIDADE": "São José do Herval",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.36,
    "FEB": 5.45,
    "MAR": 5.16,
    "APR": 4.67,
    "MAY": 3.82,
    "JUN": 3.37,
    "JUL": 3.75,
    "AUG": 4.36,
    "SEP": 4.08,
    "OCT": 4.69,
    "NOV": 5.5,
    "DEC": 5.53
  },
  {
    "LONG": -52.16,
    "LAT": -2.9,
    "CIDADE": "Putinga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.37,
    "FEB": 5.4,
    "MAR": 5.13,
    "APR": 4.67,
    "MAY": 3.81,
    "JUN": 3.37,
    "JUL": 3.75,
    "AUG": 4.36,
    "SEP": 4.08,
    "OCT": 4.66,
    "NOV": 5.47,
    "DEC": 5.52
  },
  {
    "LONG": -52.01,
    "LAT": -28.97,
    "CIDADE": "Anta Gorda",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.34,
    "FEB": 5.43,
    "MAR": 5.13,
    "APR": 4.64,
    "MAY": 3.78,
    "JUN": 3.34,
    "JUL": 3.72,
    "AUG": 4.34,
    "SEP": 4.1,
    "OCT": 4.65,
    "NOV": 5.47,
    "DEC": 5.49
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.98,
    "CIDADE": "Dois Lajeados",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.44,
    "FEB": 5.53,
    "MAR": 5.21,
    "APR": 4.67,
    "MAY": 3.79,
    "JUN": 3.3,
    "JUL": 3.71,
    "AUG": 4.31,
    "SEP": 4.09,
    "OCT": 4.68,
    "NOV": 5.5,
    "DEC": 5.55
  },
  {
    "LONG": -51.76,
    "LAT": -2.9,
    "CIDADE": "São Valentim do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.47,
    "FEB": 5.51,
    "MAR": 5.23,
    "APR": 4.67,
    "MAY": 3.79,
    "JUN": 3.3,
    "JUL": 3.71,
    "AUG": 4.31,
    "SEP": 4.08,
    "OCT": 4.69,
    "NOV": 5.51,
    "DEC": 5.57
  },
  {
    "LONG": -51.7,
    "LAT": -28.99,
    "CIDADE": "Cotiporã",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.47,
    "FEB": 5.5,
    "MAR": 5.23,
    "APR": 4.68,
    "MAY": 3.8,
    "JUN": 3.32,
    "JUL": 3.73,
    "AUG": 4.34,
    "SEP": 4.08,
    "OCT": 4.69,
    "NOV": 5.5,
    "DEC": 5.57
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.99,
    "CIDADE": "Nova Roma do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.46,
    "FEB": 5.47,
    "MAR": 5.22,
    "APR": 4.67,
    "MAY": 3.79,
    "JUN": 3.34,
    "JUL": 3.7,
    "AUG": 4.36,
    "SEP": 4.09,
    "OCT": 4.73,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -51.31,
    "LAT": -2.9,
    "CIDADE": "Nova Pádua",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.47,
    "FEB": 5.49,
    "MAR": 5.21,
    "APR": 4.67,
    "MAY": 3.79,
    "JUN": 3.35,
    "JUL": 3.7,
    "AUG": 4.37,
    "SEP": 4.09,
    "OCT": 4.75,
    "NOV": 5.55,
    "DEC": 5.56
  },
  {
    "LONG": -51.19,
    "LAT": -29.03,
    "CIDADE": "Flores da Cunha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.43,
    "FEB": 5.48,
    "MAR": 5.14,
    "APR": 4.68,
    "MAY": 3.81,
    "JUN": 3.35,
    "JUL": 3.75,
    "AUG": 4.37,
    "SEP": 4.11,
    "OCT": 4.77,
    "NOV": 5.58,
    "DEC": 5.56
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.97,
    "CIDADE": "São Marcos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.44,
    "FEB": 5.47,
    "MAR": 5.14,
    "APR": 4.69,
    "MAY": 3.81,
    "JUN": 3.37,
    "JUL": 3.79,
    "AUG": 4.38,
    "SEP": 4.11,
    "OCT": 4.78,
    "NOV": 5.58,
    "DEC": 5.55
  },
  {
    "LONG": -50.15,
    "LAT": -29.05,
    "CIDADE": "Cambará do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.27,
    "FEB": 5.23,
    "MAR": 5.01,
    "APR": 4.7,
    "MAY": 3.86,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.51,
    "SEP": 4.09,
    "OCT": 4.74,
    "NOV": 5.48,
    "DEC": 5.44
  },
  {
    "LONG": -54.56,
    "LAT": -28.93,
    "CIDADE": "Capão do Cipó",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.73,
    "FEB": 5.83,
    "MAR": 5.59,
    "APR": 4.9,
    "MAY": 4.11,
    "JUN": 3.5,
    "JUL": 3.91,
    "AUG": 4.54,
    "SEP": 4.48,
    "OCT": 5.1,
    "NOV": 5.75,
    "DEC": 5.85
  },
  {
    "LONG": -53,
    "LAT": -28.89,
    "CIDADE": "Campos Borges",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.6,
    "FEB": 5.68,
    "MAR": 5.47,
    "APR": 4.84,
    "MAY": 4,
    "JUN": 3.39,
    "JUL": 3.77,
    "AUG": 4.47,
    "SEP": 4.3,
    "OCT": 4.96,
    "NOV": 5.68,
    "DEC": 5.76
  },
  {
    "LONG": -52.13,
    "LAT": -28.93,
    "CIDADE": "Ilópolis",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.36,
    "FEB": 5.44,
    "MAR": 5.13,
    "APR": 4.68,
    "MAY": 3.82,
    "JUN": 3.38,
    "JUL": 3.76,
    "AUG": 4.41,
    "SEP": 4.12,
    "OCT": 4.72,
    "NOV": 5.5,
    "DEC": 5.54
  },
  {
    "LONG": -52.18,
    "LAT": -28.87,
    "CIDADE": "Arvorezinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.36,
    "FEB": 5.44,
    "MAR": 5.13,
    "APR": 4.68,
    "MAY": 3.82,
    "JUN": 3.38,
    "JUL": 3.76,
    "AUG": 4.41,
    "SEP": 4.12,
    "OCT": 4.72,
    "NOV": 5.5,
    "DEC": 5.54
  },
  {
    "LONG": -51.7,
    "LAT": -28.88,
    "CIDADE": "Fagundes Varela",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.49,
    "FEB": 5.5,
    "MAR": 5.2,
    "APR": 4.71,
    "MAY": 3.82,
    "JUN": 3.32,
    "JUL": 3.74,
    "AUG": 4.35,
    "SEP": 4.11,
    "OCT": 4.71,
    "NOV": 5.51,
    "DEC": 5.57
  },
  {
    "LONG": -51.55,
    "LAT": -28.86,
    "CIDADE": "Vila Flores",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.39,
    "FEB": 5.43,
    "MAR": 5.17,
    "APR": 4.68,
    "MAY": 3.84,
    "JUN": 3.39,
    "JUL": 3.76,
    "AUG": 4.38,
    "SEP": 4.13,
    "OCT": 4.72,
    "NOV": 5.51,
    "DEC": 5.53
  },
  {
    "LONG": -51.55,
    "LAT": -28.93,
    "CIDADE": "Veranópolis",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.39,
    "FEB": 5.43,
    "MAR": 5.17,
    "APR": 4.68,
    "MAY": 3.84,
    "JUN": 3.39,
    "JUL": 3.76,
    "AUG": 4.38,
    "SEP": 4.13,
    "OCT": 4.72,
    "NOV": 5.51,
    "DEC": 5.53
  },
  {
    "LONG": -51.29,
    "LAT": -2.89,
    "CIDADE": "Antônio Prado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.37,
    "FEB": 5.45,
    "MAR": 5.19,
    "APR": 4.68,
    "MAY": 3.81,
    "JUN": 3.38,
    "JUL": 3.78,
    "AUG": 4.39,
    "SEP": 4.11,
    "OCT": 4.74,
    "NOV": 5.57,
    "DEC": 5.54
  },
  {
    "LONG": -50.36,
    "LAT": -28.88,
    "CIDADE": "Jaquirana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.36,
    "FEB": 5.37,
    "MAR": 5.11,
    "APR": 4.73,
    "MAY": 3.87,
    "JUN": 3.48,
    "JUL": 3.86,
    "AUG": 4.5,
    "SEP": 4.12,
    "OCT": 4.8,
    "NOV": 5.53,
    "DEC": 5.51
  },
  {
    "LONG": -55.25,
    "LAT": -28.79,
    "CIDADE": "Itacurubi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.99,
    "JAN": 5.82,
    "FEB": 5.86,
    "MAR": 5.66,
    "APR": 4.92,
    "MAY": 4.14,
    "JUN": 3.53,
    "JUL": 3.89,
    "AUG": 4.58,
    "SEP": 4.55,
    "OCT": 5.19,
    "NOV": 5.81,
    "DEC": 5.88
  },
  {
    "LONG": -53.39,
    "LAT": -28.82,
    "CIDADE": "Boa Vista do Incra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.57,
    "FEB": 5.71,
    "MAR": 5.46,
    "APR": 4.82,
    "MAY": 4.04,
    "JUN": 3.41,
    "JUL": 3.82,
    "AUG": 4.47,
    "SEP": 4.33,
    "OCT": 4.95,
    "NOV": 5.62,
    "DEC": 5.7
  },
  {
    "LONG": -53.23,
    "LAT": -28.8,
    "CIDADE": "Fortaleza dos Valos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.62,
    "FEB": 5.66,
    "MAR": 5.44,
    "APR": 4.85,
    "MAY": 4.01,
    "JUN": 3.37,
    "JUL": 3.78,
    "AUG": 4.49,
    "SEP": 4.34,
    "OCT": 4.94,
    "NOV": 5.63,
    "DEC": 5.7
  },
  {
    "LONG": -52.99,
    "LAT": -28.78,
    "CIDADE": "Alto Alegre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.58,
    "FEB": 5.67,
    "MAR": 5.45,
    "APR": 4.83,
    "MAY": 4.04,
    "JUN": 3.42,
    "JUL": 3.77,
    "AUG": 4.48,
    "SEP": 4.29,
    "OCT": 4.98,
    "NOV": 5.67,
    "DEC": 5.72
  },
  {
    "LONG": -52.51,
    "LAT": -28.83,
    "CIDADE": "Soledade",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.52,
    "FEB": 5.6,
    "MAR": 5.39,
    "APR": 4.83,
    "MAY": 4,
    "JUN": 3.45,
    "JUL": 3.82,
    "AUG": 4.48,
    "SEP": 4.26,
    "OCT": 4.98,
    "NOV": 5.66,
    "DEC": 5.73
  },
  {
    "LONG": -52.17,
    "LAT": -28.78,
    "CIDADE": "Itapuca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.36,
    "FEB": 5.52,
    "MAR": 5.22,
    "APR": 4.74,
    "MAY": 3.85,
    "JUN": 3.39,
    "JUL": 3.78,
    "AUG": 4.43,
    "SEP": 4.17,
    "OCT": 4.79,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -52.02,
    "LAT": -28.78,
    "CIDADE": "União da Serra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.39,
    "FEB": 5.56,
    "MAR": 5.22,
    "APR": 4.7,
    "MAY": 3.84,
    "JUN": 3.36,
    "JUL": 3.74,
    "AUG": 4.4,
    "SEP": 4.15,
    "OCT": 4.78,
    "NOV": 5.55,
    "DEC": 5.62
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.84,
    "CIDADE": "Guaporé",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.36,
    "FEB": 5.5,
    "MAR": 5.17,
    "APR": 4.66,
    "MAY": 3.81,
    "JUN": 3.38,
    "JUL": 3.74,
    "AUG": 4.36,
    "SEP": 4.14,
    "OCT": 4.73,
    "NOV": 5.52,
    "DEC": 5.58
  },
  {
    "LONG": -51.8,
    "LAT": -28.81,
    "CIDADE": "Vista Alegre do Prata",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.42,
    "FEB": 5.52,
    "MAR": 5.17,
    "APR": 4.71,
    "MAY": 3.85,
    "JUN": 3.39,
    "JUL": 3.78,
    "AUG": 4.39,
    "SEP": 4.13,
    "OCT": 4.73,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -51.61,
    "LAT": -28.78,
    "CIDADE": "Nova Prata",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.43,
    "FEB": 5.51,
    "MAR": 5.16,
    "APR": 4.72,
    "MAY": 3.86,
    "JUN": 3.38,
    "JUL": 3.78,
    "AUG": 4.39,
    "SEP": 4.15,
    "OCT": 4.72,
    "NOV": 5.51,
    "DEC": 5.54
  },
  {
    "LONG": -51.48,
    "LAT": -28.76,
    "CIDADE": "Protásio Alves",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.39,
    "FEB": 5.41,
    "MAR": 5.22,
    "APR": 4.71,
    "MAY": 3.84,
    "JUN": 3.38,
    "JUL": 3.76,
    "AUG": 4.39,
    "SEP": 4.11,
    "OCT": 4.75,
    "NOV": 5.55,
    "DEC": 5.53
  },
  {
    "LONG": -51.29,
    "LAT": -28.82,
    "CIDADE": "Ipê",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.34,
    "FEB": 5.44,
    "MAR": 5.19,
    "APR": 4.7,
    "MAY": 3.83,
    "JUN": 3.43,
    "JUL": 3.81,
    "AUG": 4.44,
    "SEP": 4.14,
    "OCT": 4.78,
    "NOV": 5.57,
    "DEC": 5.55
  },
  {
    "LONG": -51.09,
    "LAT": -28.79,
    "CIDADE": "Campestre da Serra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.32,
    "FEB": 5.41,
    "MAR": 5.19,
    "APR": 4.64,
    "MAY": 3.84,
    "JUN": 3.4,
    "JUL": 3.8,
    "AUG": 4.42,
    "SEP": 4.14,
    "OCT": 4.77,
    "NOV": 5.53,
    "DEC": 5.51
  },
  {
    "LONG": -56,
    "LAT": -28.66,
    "CIDADE": "São Borja",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.02,
    "JAN": 5.98,
    "FEB": 5.92,
    "MAR": 5.68,
    "APR": 4.93,
    "MAY": 4.1,
    "JUN": 3.51,
    "JUL": 3.9,
    "AUG": 4.54,
    "SEP": 4.6,
    "OCT": 5.28,
    "NOV": 5.8,
    "DEC": 5.97
  },
  {
    "LONG": -5.49,
    "LAT": -28.73,
    "CIDADE": "Bossoroca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.97,
    "JAN": 5.79,
    "FEB": 5.85,
    "MAR": 5.66,
    "APR": 4.92,
    "MAY": 4.13,
    "JUN": 3.53,
    "JUL": 3.93,
    "AUG": 4.57,
    "SEP": 4.52,
    "OCT": 5.14,
    "NOV": 5.77,
    "DEC": 5.85
  },
  {
    "LONG": -53.09,
    "LAT": -2.87,
    "CIDADE": "Quinze de Novembro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.64,
    "FEB": 5.7,
    "MAR": 5.43,
    "APR": 4.86,
    "MAY": 4.05,
    "JUN": 3.45,
    "JUL": 3.8,
    "AUG": 4.48,
    "SEP": 4.31,
    "OCT": 4.98,
    "NOV": 5.64,
    "DEC": 5.7
  },
  {
    "LONG": -52.85,
    "LAT": -28.73,
    "CIDADE": "Espumoso",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.57,
    "FEB": 5.68,
    "MAR": 5.4,
    "APR": 4.84,
    "MAY": 4.02,
    "JUN": 3.44,
    "JUL": 3.79,
    "AUG": 4.5,
    "SEP": 4.28,
    "OCT": 4.98,
    "NOV": 5.64,
    "DEC": 5.71
  },
  {
    "LONG": -52.69,
    "LAT": -2.87,
    "CIDADE": "Mormaço",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.53,
    "FEB": 5.61,
    "MAR": 5.4,
    "APR": 4.83,
    "MAY": 4.03,
    "JUN": 3.44,
    "JUL": 3.81,
    "AUG": 4.49,
    "SEP": 4.27,
    "OCT": 4.98,
    "NOV": 5.65,
    "DEC": 5.69
  },
  {
    "LONG": -52.16,
    "LAT": -28.68,
    "CIDADE": "Nova Alvorada",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.74,
    "JAN": 5.44,
    "FEB": 5.59,
    "MAR": 5.26,
    "APR": 4.79,
    "MAY": 3.88,
    "JUN": 3.4,
    "JUL": 3.79,
    "AUG": 4.47,
    "SEP": 4.2,
    "OCT": 4.86,
    "NOV": 5.58,
    "DEC": 5.68
  },
  {
    "LONG": -51.94,
    "LAT": -28.71,
    "CIDADE": "Serafina Corrêa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.39,
    "FEB": 5.57,
    "MAR": 5.2,
    "APR": 4.74,
    "MAY": 3.85,
    "JUN": 3.43,
    "JUL": 3.78,
    "AUG": 4.43,
    "SEP": 4.17,
    "OCT": 4.79,
    "NOV": 5.53,
    "DEC": 5.61
  },
  {
    "LONG": -51.75,
    "LAT": -28.65,
    "CIDADE": "Nova Araçá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.39,
    "FEB": 5.48,
    "MAR": 5.21,
    "APR": 4.76,
    "MAY": 3.86,
    "JUN": 3.45,
    "JUL": 3.82,
    "AUG": 4.42,
    "SEP": 4.15,
    "OCT": 4.76,
    "NOV": 5.52,
    "DEC": 5.54
  },
  {
    "LONG": -51.71,
    "LAT": -28.73,
    "CIDADE": "Nova Bassano",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.39,
    "FEB": 5.48,
    "MAR": 5.21,
    "APR": 4.76,
    "MAY": 3.86,
    "JUN": 3.45,
    "JUL": 3.82,
    "AUG": 4.42,
    "SEP": 4.15,
    "OCT": 4.76,
    "NOV": 5.52,
    "DEC": 5.54
  },
  {
    "LONG": -50.78,
    "LAT": -2.87,
    "CIDADE": "Monte Alegre dos Campos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.3,
    "FEB": 5.38,
    "MAR": 5.12,
    "APR": 4.74,
    "MAY": 3.87,
    "JUN": 3.44,
    "JUL": 3.85,
    "AUG": 4.52,
    "SEP": 4.17,
    "OCT": 4.8,
    "NOV": 5.52,
    "DEC": 5.45
  },
  {
    "LONG": -50.43,
    "LAT": -28.67,
    "CIDADE": "Bom Jesus",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.33,
    "FEB": 5.38,
    "MAR": 5.11,
    "APR": 4.72,
    "MAY": 3.87,
    "JUN": 3.45,
    "JUL": 3.86,
    "AUG": 4.5,
    "SEP": 4.2,
    "OCT": 4.82,
    "NOV": 5.51,
    "DEC": 5.48
  },
  {
    "LONG": -50.07,
    "LAT": -28.75,
    "CIDADE": "São José dos Ausentes",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.3,
    "FEB": 5.3,
    "MAR": 5.12,
    "APR": 4.8,
    "MAY": 3.93,
    "JUN": 3.57,
    "JUL": 3.96,
    "AUG": 4.61,
    "SEP": 4.21,
    "OCT": 4.83,
    "NOV": 5.53,
    "DEC": 5.52
  },
  {
    "LONG": -54.56,
    "LAT": -28.56,
    "CIDADE": "São Miguel das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.74,
    "FEB": 5.79,
    "MAR": 5.61,
    "APR": 4.92,
    "MAY": 4.09,
    "JUN": 3.5,
    "JUL": 3.85,
    "AUG": 4.54,
    "SEP": 4.46,
    "OCT": 5.08,
    "NOV": 5.73,
    "DEC": 5.78
  },
  {
    "LONG": -54.11,
    "LAT": -2.86,
    "CIDADE": "Jóia",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.72,
    "FEB": 5.78,
    "MAR": 5.61,
    "APR": 4.93,
    "MAY": 4.09,
    "JUN": 3.51,
    "JUL": 3.86,
    "AUG": 4.51,
    "SEP": 4.42,
    "OCT": 5.04,
    "NOV": 5.7,
    "DEC": 5.77
  },
  {
    "LONG": -53.81,
    "LAT": -28.58,
    "CIDADE": "Boa Vista do Cadeado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.67,
    "FEB": 5.79,
    "MAR": 5.51,
    "APR": 4.92,
    "MAY": 4.1,
    "JUN": 3.48,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.39,
    "OCT": 5.01,
    "NOV": 5.67,
    "DEC": 5.74
  },
  {
    "LONG": -53.61,
    "LAT": -28.65,
    "CIDADE": "Cruz Alta",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.62,
    "FEB": 5.76,
    "MAR": 5.48,
    "APR": 4.86,
    "MAY": 4.07,
    "JUN": 3.47,
    "JUL": 3.85,
    "AUG": 4.51,
    "SEP": 4.36,
    "OCT": 4.97,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -53.1,
    "LAT": -28.63,
    "CIDADE": "Ibirubá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.61,
    "FEB": 5.68,
    "MAR": 5.43,
    "APR": 4.82,
    "MAY": 4.03,
    "JUN": 3.46,
    "JUL": 3.79,
    "AUG": 4.5,
    "SEP": 4.31,
    "OCT": 4.97,
    "NOV": 5.64,
    "DEC": 5.67
  },
  {
    "LONG": -52.95,
    "LAT": -28.63,
    "CIDADE": "Selbach",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.58,
    "FEB": 5.64,
    "MAR": 5.39,
    "APR": 4.86,
    "MAY": 4.06,
    "JUN": 3.45,
    "JUL": 3.85,
    "AUG": 4.52,
    "SEP": 4.31,
    "OCT": 4.97,
    "NOV": 5.63,
    "DEC": 5.71
  },
  {
    "LONG": -52.86,
    "LAT": -28.57,
    "CIDADE": "Lagoa dos Três Cantos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.57,
    "FEB": 5.64,
    "MAR": 5.38,
    "APR": 4.84,
    "MAY": 4.05,
    "JUN": 3.45,
    "JUL": 3.83,
    "AUG": 4.52,
    "SEP": 4.29,
    "OCT": 4.99,
    "NOV": 5.65,
    "DEC": 5.7
  },
  {
    "LONG": -52.87,
    "LAT": -2.86,
    "CIDADE": "Tapera",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.57,
    "FEB": 5.64,
    "MAR": 5.38,
    "APR": 4.84,
    "MAY": 4.05,
    "JUN": 3.45,
    "JUL": 3.83,
    "AUG": 4.52,
    "SEP": 4.29,
    "OCT": 4.99,
    "NOV": 5.65,
    "DEC": 5.7
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.56,
    "CIDADE": "Victor Graeff",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.59,
    "FEB": 5.66,
    "MAR": 5.38,
    "APR": 4.82,
    "MAY": 4.02,
    "JUN": 3.46,
    "JUL": 3.82,
    "AUG": 4.5,
    "SEP": 4.27,
    "OCT": 4.97,
    "NOV": 5.65,
    "DEC": 5.69
  },
  {
    "LONG": -5.26,
    "LAT": -28.57,
    "CIDADE": "Tio Hugo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.54,
    "FEB": 5.62,
    "MAR": 5.38,
    "APR": 4.85,
    "MAY": 4.02,
    "JUN": 3.44,
    "JUL": 3.81,
    "AUG": 4.51,
    "SEP": 4.25,
    "OCT": 4.97,
    "NOV": 5.64,
    "DEC": 5.69
  },
  {
    "LONG": -52.51,
    "LAT": -2.86,
    "CIDADE": "Ibirapuitã",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.54,
    "FEB": 5.62,
    "MAR": 5.38,
    "APR": 4.85,
    "MAY": 4.02,
    "JUN": 3.44,
    "JUL": 3.81,
    "AUG": 4.51,
    "SEP": 4.25,
    "OCT": 4.97,
    "NOV": 5.64,
    "DEC": 5.69
  },
  {
    "LONG": -52.2,
    "LAT": -28.59,
    "CIDADE": "Camargo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.77,
    "JAN": 5.49,
    "FEB": 5.59,
    "MAR": 5.3,
    "APR": 4.8,
    "MAY": 3.94,
    "JUN": 3.43,
    "JUL": 3.8,
    "AUG": 4.48,
    "SEP": 4.24,
    "OCT": 4.93,
    "NOV": 5.6,
    "DEC": 5.68
  },
  {
    "LONG": -52.08,
    "LAT": -28.65,
    "CIDADE": "Montauri",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.42,
    "FEB": 5.53,
    "MAR": 5.25,
    "APR": 4.78,
    "MAY": 3.88,
    "JUN": 3.45,
    "JUL": 3.81,
    "AUG": 4.44,
    "SEP": 4.18,
    "OCT": 4.85,
    "NOV": 5.52,
    "DEC": 5.64
  },
  {
    "LONG": -51.98,
    "LAT": -2.86,
    "CIDADE": "Casca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.38,
    "FEB": 5.52,
    "MAR": 5.21,
    "APR": 4.75,
    "MAY": 3.87,
    "JUN": 3.46,
    "JUL": 3.83,
    "AUG": 4.42,
    "SEP": 4.17,
    "OCT": 4.81,
    "NOV": 5.48,
    "DEC": 5.59
  },
  {
    "LONG": -51.79,
    "LAT": -28.6,
    "CIDADE": "Paraí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.37,
    "FEB": 5.45,
    "MAR": 5.22,
    "APR": 4.78,
    "MAY": 3.84,
    "JUN": 3.44,
    "JUL": 3.82,
    "AUG": 4.42,
    "SEP": 4.16,
    "OCT": 4.81,
    "NOV": 5.52,
    "DEC": 5.55
  },
  {
    "LONG": -51.58,
    "LAT": -28.63,
    "CIDADE": "André da Rocha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.37,
    "FEB": 5.4,
    "MAR": 5.17,
    "APR": 4.7,
    "MAY": 3.88,
    "JUN": 3.43,
    "JUL": 3.86,
    "AUG": 4.43,
    "SEP": 4.17,
    "OCT": 4.8,
    "NOV": 5.53,
    "DEC": 5.5
  },
  {
    "LONG": -55.23,
    "LAT": -2.85,
    "CIDADE": "Santo Antônio das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.99,
    "JAN": 5.83,
    "FEB": 5.91,
    "MAR": 5.67,
    "APR": 4.93,
    "MAY": 4.15,
    "JUN": 3.52,
    "JUL": 3.91,
    "AUG": 4.55,
    "SEP": 4.57,
    "OCT": 5.16,
    "NOV": 5.78,
    "DEC": 5.85
  },
  {
    "LONG": -54.15,
    "LAT": -28.53,
    "CIDADE": "Eugênio de Castro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.71,
    "FEB": 5.81,
    "MAR": 5.58,
    "APR": 4.92,
    "MAY": 4.1,
    "JUN": 3.52,
    "JUL": 3.86,
    "AUG": 4.52,
    "SEP": 4.44,
    "OCT": 5.05,
    "NOV": 5.67,
    "DEC": 5.76
  },
  {
    "LONG": -53.99,
    "LAT": -28.52,
    "CIDADE": "Augusto Pestana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.69,
    "FEB": 5.79,
    "MAR": 5.56,
    "APR": 4.91,
    "MAY": 4.1,
    "JUN": 3.49,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.4,
    "OCT": 5.02,
    "NOV": 5.67,
    "DEC": 5.74
  },
  {
    "LONG": -52.99,
    "LAT": -28.53,
    "CIDADE": "Colorado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.58,
    "FEB": 5.62,
    "MAR": 5.37,
    "APR": 4.86,
    "MAY": 4.05,
    "JUN": 3.48,
    "JUL": 3.87,
    "AUG": 4.52,
    "SEP": 4.3,
    "OCT": 4.98,
    "NOV": 5.62,
    "DEC": 5.67
  },
  {
    "LONG": -52.82,
    "LAT": -28.46,
    "CIDADE": "Não-Me-Toque",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.58,
    "FEB": 5.62,
    "MAR": 5.36,
    "APR": 4.86,
    "MAY": 4.05,
    "JUN": 3.47,
    "JUL": 3.87,
    "AUG": 4.5,
    "SEP": 4.29,
    "OCT": 4.97,
    "NOV": 5.63,
    "DEC": 5.67
  },
  {
    "LONG": -52.58,
    "LAT": -28.5,
    "CIDADE": "Ernestina",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.54,
    "FEB": 5.6,
    "MAR": 5.35,
    "APR": 4.83,
    "MAY": 4.02,
    "JUN": 3.43,
    "JUL": 3.83,
    "AUG": 4.5,
    "SEP": 4.25,
    "OCT": 4.95,
    "NOV": 5.61,
    "DEC": 5.67
  },
  {
    "LONG": -52.47,
    "LAT": -28.53,
    "CIDADE": "Nicolau Vergueiro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.53,
    "FEB": 5.62,
    "MAR": 5.36,
    "APR": 4.86,
    "MAY": 3.98,
    "JUN": 3.43,
    "JUL": 3.82,
    "AUG": 4.51,
    "SEP": 4.25,
    "OCT": 4.95,
    "NOV": 5.61,
    "DEC": 5.68
  },
  {
    "LONG": -52.15,
    "LAT": -28.54,
    "CIDADE": "Vila Maria",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.43,
    "FEB": 5.52,
    "MAR": 5.29,
    "APR": 4.81,
    "MAY": 3.95,
    "JUN": 3.46,
    "JUL": 3.83,
    "AUG": 4.45,
    "SEP": 4.21,
    "OCT": 4.89,
    "NOV": 5.56,
    "DEC": 5.63
  },
  {
    "LONG": -52.03,
    "LAT": -2.85,
    "CIDADE": "Santo Antônio do Palma",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.4,
    "FEB": 5.49,
    "MAR": 5.25,
    "APR": 4.8,
    "MAY": 3.93,
    "JUN": 3.47,
    "JUL": 3.84,
    "AUG": 4.44,
    "SEP": 4.19,
    "OCT": 4.87,
    "NOV": 5.53,
    "DEC": 5.58
  },
  {
    "LONG": -51.85,
    "LAT": -28.48,
    "CIDADE": "Vanini",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.4,
    "FEB": 5.45,
    "MAR": 5.24,
    "APR": 4.79,
    "MAY": 3.88,
    "JUN": 3.45,
    "JUL": 3.85,
    "AUG": 4.43,
    "SEP": 4.18,
    "OCT": 4.82,
    "NOV": 5.5,
    "DEC": 5.55
  },
  {
    "LONG": -51.89,
    "LAT": -28.53,
    "CIDADE": "São Domingos do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.4,
    "FEB": 5.45,
    "MAR": 5.24,
    "APR": 4.79,
    "MAY": 3.88,
    "JUN": 3.45,
    "JUL": 3.85,
    "AUG": 4.43,
    "SEP": 4.18,
    "OCT": 4.82,
    "NOV": 5.5,
    "DEC": 5.55
  },
  {
    "LONG": -51.71,
    "LAT": -28.5,
    "CIDADE": "São Jorge",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.37,
    "FEB": 5.43,
    "MAR": 5.2,
    "APR": 4.78,
    "MAY": 3.88,
    "JUN": 3.48,
    "JUL": 3.85,
    "AUG": 4.45,
    "SEP": 4.18,
    "OCT": 4.83,
    "NOV": 5.52,
    "DEC": 5.54
  },
  {
    "LONG": -51.7,
    "LAT": -28.54,
    "CIDADE": "Guabiju",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.34,
    "FEB": 5.42,
    "MAR": 5.18,
    "APR": 4.78,
    "MAY": 3.89,
    "JUN": 3.51,
    "JUL": 3.86,
    "AUG": 4.44,
    "SEP": 4.17,
    "OCT": 4.83,
    "NOV": 5.53,
    "DEC": 5.52
  },
  {
    "LONG": -50.94,
    "LAT": -28.51,
    "CIDADE": "Vacaria",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.33,
    "FEB": 5.42,
    "MAR": 5.11,
    "APR": 4.68,
    "MAY": 3.88,
    "JUN": 3.48,
    "JUL": 3.85,
    "AUG": 4.55,
    "SEP": 4.22,
    "OCT": 4.81,
    "NOV": 5.51,
    "DEC": 5.45
  },
  {
    "LONG": -54.96,
    "LAT": -2.84,
    "CIDADE": "São Luiz Gonzaga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.95,
    "JAN": 5.77,
    "FEB": 5.86,
    "MAR": 5.63,
    "APR": 4.93,
    "MAY": 4.14,
    "JUN": 3.51,
    "JUL": 3.9,
    "AUG": 4.54,
    "SEP": 4.51,
    "OCT": 5.09,
    "NOV": 5.74,
    "DEC": 5.82
  },
  {
    "LONG": -54.5,
    "LAT": -2.84,
    "CIDADE": "Vitória das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.76,
    "FEB": 5.85,
    "MAR": 5.58,
    "APR": 4.93,
    "MAY": 4.1,
    "JUN": 3.5,
    "JUL": 3.87,
    "AUG": 4.54,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.72,
    "DEC": 5.78
  },
  {
    "LONG": -54.27,
    "LAT": "#VALOR!",
    "CIDADE": "Entre-Ijuís",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.72,
    "FEB": 5.8,
    "MAR": 5.57,
    "APR": 4.92,
    "MAY": 4.1,
    "JUN": 3.51,
    "JUL": 3.85,
    "AUG": 4.49,
    "SEP": 4.43,
    "OCT": 5.07,
    "NOV": 5.67,
    "DEC": 5.76
  },
  {
    "LONG": -54.07,
    "LAT": -2.84,
    "CIDADE": "Coronel Barros",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.72,
    "FEB": 5.78,
    "MAR": 5.57,
    "APR": 4.89,
    "MAY": 4.08,
    "JUN": 3.5,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.41,
    "OCT": 5.04,
    "NOV": 5.66,
    "DEC": 5.73
  },
  {
    "LONG": -53.92,
    "LAT": -28.39,
    "CIDADE": "Ijuí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.7,
    "FEB": 5.76,
    "MAR": 5.56,
    "APR": 4.88,
    "MAY": 4.07,
    "JUN": 3.48,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.39,
    "OCT": 5.04,
    "NOV": 5.66,
    "DEC": 5.72
  },
  {
    "LONG": -53.77,
    "LAT": -28.37,
    "CIDADE": "Bozano",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.67,
    "FEB": 5.75,
    "MAR": 5.52,
    "APR": 4.85,
    "MAY": 4.07,
    "JUN": 3.48,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.38,
    "OCT": 5.01,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -53.66,
    "LAT": -2.84,
    "CIDADE": "Pejuçara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.65,
    "FEB": 5.7,
    "MAR": 5.49,
    "APR": 4.85,
    "MAY": 4.08,
    "JUN": 3.48,
    "JUL": 3.86,
    "AUG": 4.49,
    "SEP": 4.36,
    "OCT": 5.01,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -53.25,
    "LAT": -28.37,
    "CIDADE": "Santa Bárbara do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.62,
    "FEB": 5.67,
    "MAR": 5.39,
    "APR": 4.87,
    "MAY": 4.05,
    "JUN": 3.49,
    "JUL": 3.88,
    "AUG": 4.52,
    "SEP": 4.31,
    "OCT": 5,
    "NOV": 5.59,
    "DEC": 5.68
  },
  {
    "LONG": -53.1,
    "LAT": -28.39,
    "CIDADE": "Saldanha Marinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.58,
    "FEB": 5.66,
    "MAR": 5.4,
    "APR": 4.85,
    "MAY": 4.05,
    "JUN": 3.48,
    "JUL": 3.87,
    "AUG": 4.52,
    "SEP": 4.28,
    "OCT": 4.98,
    "NOV": 5.6,
    "DEC": 5.68
  },
  {
    "LONG": -52.69,
    "LAT": -2.84,
    "CIDADE": "Santo Antônio do Planalto",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.52,
    "FEB": 5.61,
    "MAR": 5.35,
    "APR": 4.85,
    "MAY": 4.03,
    "JUN": 3.47,
    "JUL": 3.88,
    "AUG": 4.52,
    "SEP": 4.27,
    "OCT": 4.95,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -52.2,
    "LAT": -28.45,
    "CIDADE": "Marau",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.77,
    "JAN": 5.45,
    "FEB": 5.5,
    "MAR": 5.27,
    "APR": 4.84,
    "MAY": 3.98,
    "JUN": 3.46,
    "JUL": 3.87,
    "AUG": 4.5,
    "SEP": 4.23,
    "OCT": 4.93,
    "NOV": 5.6,
    "DEC": 5.63
  },
  {
    "LONG": -52.03,
    "LAT": -28.43,
    "CIDADE": "Gentil",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.4,
    "FEB": 5.5,
    "MAR": 5.27,
    "APR": 4.82,
    "MAY": 3.94,
    "JUN": 3.5,
    "JUL": 3.86,
    "AUG": 4.49,
    "SEP": 4.22,
    "OCT": 4.9,
    "NOV": 5.55,
    "DEC": 5.58
  },
  {
    "LONG": -51.85,
    "LAT": -28.39,
    "CIDADE": "David Canabarro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.4,
    "FEB": 5.41,
    "MAR": 5.23,
    "APR": 4.8,
    "MAY": 3.92,
    "JUN": 3.47,
    "JUL": 3.88,
    "AUG": 4.5,
    "SEP": 4.2,
    "OCT": 4.85,
    "NOV": 5.52,
    "DEC": 5.54
  },
  {
    "LONG": -51.64,
    "LAT": -28.37,
    "CIDADE": "Ibiraiaras",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.36,
    "FEB": 5.45,
    "MAR": 5.19,
    "APR": 4.8,
    "MAY": 3.93,
    "JUN": 3.53,
    "JUL": 3.89,
    "AUG": 4.49,
    "SEP": 4.19,
    "OCT": 4.83,
    "NOV": 5.54,
    "DEC": 5.54
  },
  {
    "LONG": -54.82,
    "LAT": -2.83,
    "CIDADE": "Rolador",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.95,
    "JAN": 5.81,
    "FEB": 5.88,
    "MAR": 5.61,
    "APR": 4.95,
    "MAY": 4.13,
    "JUN": 3.52,
    "JUL": 3.89,
    "AUG": 4.53,
    "SEP": 4.5,
    "OCT": 5.09,
    "NOV": 5.73,
    "DEC": 5.8
  },
  {
    "LONG": -54.62,
    "LAT": -28.26,
    "CIDADE": "Mato Queimado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.77,
    "FEB": 5.87,
    "MAR": 5.61,
    "APR": 4.96,
    "MAY": 4.12,
    "JUN": 3.51,
    "JUL": 3.85,
    "AUG": 4.53,
    "SEP": 4.48,
    "OCT": 5.09,
    "NOV": 5.71,
    "DEC": 5.78
  },
  {
    "LONG": -54.64,
    "LAT": -2.83,
    "CIDADE": "Caibaté",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.77,
    "FEB": 5.87,
    "MAR": 5.61,
    "APR": 4.96,
    "MAY": 4.12,
    "JUN": 3.51,
    "JUL": 3.85,
    "AUG": 4.53,
    "SEP": 4.48,
    "OCT": 5.09,
    "NOV": 5.71,
    "DEC": 5.78
  },
  {
    "LONG": -54.27,
    "LAT": -28.3,
    "CIDADE": "Santo Ângelo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.68,
    "FEB": 5.73,
    "MAR": 5.56,
    "APR": 4.88,
    "MAY": 4.1,
    "JUN": 3.51,
    "JUL": 3.86,
    "AUG": 4.49,
    "SEP": 4.42,
    "OCT": 5.08,
    "NOV": 5.65,
    "DEC": 5.74
  },
  {
    "LONG": -54.01,
    "LAT": -28.26,
    "CIDADE": "Catuípe",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.71,
    "FEB": 5.78,
    "MAR": 5.52,
    "APR": 4.87,
    "MAY": 4.07,
    "JUN": 3.51,
    "JUL": 3.84,
    "AUG": 4.51,
    "SEP": 4.41,
    "OCT": 5.04,
    "NOV": 5.64,
    "DEC": 5.7
  },
  {
    "LONG": -53.5,
    "LAT": -28.28,
    "CIDADE": "Panambi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.61,
    "FEB": 5.67,
    "MAR": 5.48,
    "APR": 4.9,
    "MAY": 4.08,
    "JUN": 3.49,
    "JUL": 3.84,
    "AUG": 4.54,
    "SEP": 4.36,
    "OCT": 5.05,
    "NOV": 5.61,
    "DEC": 5.68
  },
  {
    "LONG": -52.79,
    "LAT": -28.3,
    "CIDADE": "Carazinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.47,
    "FEB": 5.62,
    "MAR": 5.36,
    "APR": 4.89,
    "MAY": 4.04,
    "JUN": 3.49,
    "JUL": 3.85,
    "AUG": 4.53,
    "SEP": 4.28,
    "OCT": 4.98,
    "NOV": 5.62,
    "DEC": 5.63
  },
  {
    "LONG": -52.41,
    "LAT": -2.83,
    "CIDADE": "Passo Fundo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.52,
    "FEB": 5.55,
    "MAR": 5.29,
    "APR": 4.86,
    "MAY": 3.99,
    "JUN": 3.49,
    "JUL": 3.88,
    "AUG": 4.53,
    "SEP": 4.25,
    "OCT": 4.98,
    "NOV": 5.61,
    "DEC": 5.65
  },
  {
    "LONG": -52.19,
    "LAT": -28.28,
    "CIDADE": "Mato Castelhano",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.76,
    "JAN": 5.42,
    "FEB": 5.49,
    "MAR": 5.25,
    "APR": 4.83,
    "MAY": 3.97,
    "JUN": 3.47,
    "JUL": 3.88,
    "AUG": 4.53,
    "SEP": 4.25,
    "OCT": 4.91,
    "NOV": 5.55,
    "DEC": 5.59
  },
  {
    "LONG": -51.87,
    "LAT": -28.34,
    "CIDADE": "Ciríaco",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.76,
    "JAN": 5.44,
    "FEB": 5.42,
    "MAR": 5.24,
    "APR": 4.83,
    "MAY": 3.98,
    "JUN": 3.5,
    "JUL": 3.89,
    "AUG": 4.54,
    "SEP": 4.22,
    "OCT": 4.88,
    "NOV": 5.58,
    "DEC": 5.57
  },
  {
    "LONG": -51.77,
    "LAT": -28.33,
    "CIDADE": "Muliterno",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.76,
    "JAN": 5.4,
    "FEB": 5.44,
    "MAR": 5.24,
    "APR": 4.84,
    "MAY": 3.96,
    "JUN": 3.53,
    "JUL": 3.91,
    "AUG": 4.54,
    "SEP": 4.21,
    "OCT": 4.86,
    "NOV": 5.56,
    "DEC": 5.57
  },
  {
    "LONG": -51.69,
    "LAT": -28.26,
    "CIDADE": "Caseiros",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.74,
    "JAN": 5.35,
    "FEB": 5.45,
    "MAR": 5.23,
    "APR": 4.84,
    "MAY": 3.94,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.53,
    "SEP": 4.23,
    "OCT": 4.85,
    "NOV": 5.54,
    "DEC": 5.56
  },
  {
    "LONG": -5.12,
    "LAT": -28.31,
    "CIDADE": "Muitos Capões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.35,
    "FEB": 5.44,
    "MAR": 5.13,
    "APR": 4.73,
    "MAY": 3.89,
    "JUN": 3.52,
    "JUL": 3.92,
    "AUG": 4.58,
    "SEP": 4.29,
    "OCT": 4.85,
    "NOV": 5.52,
    "DEC": 5.52
  },
  {
    "LONG": -55.64,
    "LAT": -28.19,
    "CIDADE": "Garruchos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.98,
    "JAN": 5.88,
    "FEB": 5.87,
    "MAR": 5.66,
    "APR": 4.89,
    "MAY": 4.13,
    "JUN": 3.51,
    "JUL": 3.89,
    "AUG": 4.54,
    "SEP": 4.58,
    "OCT": 5.15,
    "NOV": 5.77,
    "DEC": 5.86
  },
  {
    "LONG": -55.27,
    "LAT": -28.18,
    "CIDADE": "São Nicolau",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.97,
    "JAN": 5.8,
    "FEB": 5.85,
    "MAR": 5.66,
    "APR": 4.93,
    "MAY": 4.13,
    "JUN": 3.53,
    "JUL": 3.91,
    "AUG": 4.55,
    "SEP": 4.54,
    "OCT": 5.12,
    "NOV": 5.75,
    "DEC": 5.82
  },
  {
    "LONG": -55.05,
    "LAT": -28.23,
    "CIDADE": "Dezesseis de Novembro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.95,
    "JAN": 5.75,
    "FEB": 5.81,
    "MAR": 5.67,
    "APR": 4.93,
    "MAY": 4.13,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.53,
    "SEP": 4.51,
    "OCT": 5.11,
    "NOV": 5.76,
    "DEC": 5.82
  },
  {
    "LONG": -53.77,
    "LAT": -2.82,
    "CIDADE": "Ajuricaba",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.64,
    "FEB": 5.73,
    "MAR": 5.47,
    "APR": 4.87,
    "MAY": 4.11,
    "JUN": 3.52,
    "JUL": 3.86,
    "AUG": 4.54,
    "SEP": 4.37,
    "OCT": 5.03,
    "NOV": 5.56,
    "DEC": 5.66
  },
  {
    "LONG": -5.35,
    "LAT": -2.82,
    "CIDADE": "Condor",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.56,
    "FEB": 5.66,
    "MAR": 5.45,
    "APR": 4.87,
    "MAY": 4.07,
    "JUN": 3.53,
    "JUL": 3.87,
    "AUG": 4.52,
    "SEP": 4.34,
    "OCT": 5.04,
    "NOV": 5.56,
    "DEC": 5.65
  },
  {
    "LONG": -52.03,
    "LAT": -28.17,
    "CIDADE": "Água Santa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.48,
    "FEB": 5.48,
    "MAR": 5.28,
    "APR": 4.86,
    "MAY": 3.99,
    "JUN": 3.48,
    "JUL": 3.91,
    "AUG": 4.61,
    "SEP": 4.28,
    "OCT": 4.95,
    "NOV": 5.59,
    "DEC": 5.59
  },
  {
    "LONG": -51.93,
    "LAT": -28.16,
    "CIDADE": "Santa Cecília do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.78,
    "JAN": 5.46,
    "FEB": 5.47,
    "MAR": 5.27,
    "APR": 4.84,
    "MAY": 3.96,
    "JUN": 3.48,
    "JUL": 3.93,
    "AUG": 4.61,
    "SEP": 4.26,
    "OCT": 4.91,
    "NOV": 5.58,
    "DEC": 5.58
  },
  {
    "LONG": -51.53,
    "LAT": -28.21,
    "CIDADE": "Lagoa Vermelha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.76,
    "JAN": 5.38,
    "FEB": 5.47,
    "MAR": 5.24,
    "APR": 4.8,
    "MAY": 3.96,
    "JUN": 3.49,
    "JUL": 3.94,
    "AUG": 4.58,
    "SEP": 4.24,
    "OCT": 4.86,
    "NOV": 5.57,
    "DEC": 5.55
  },
  {
    "LONG": -55.03,
    "LAT": -28.13,
    "CIDADE": "Roque Gonzales",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.95,
    "JAN": 5.8,
    "FEB": 5.85,
    "MAR": 5.6,
    "APR": 4.94,
    "MAY": 4.1,
    "JUN": 3.52,
    "JUL": 3.87,
    "AUG": 4.57,
    "SEP": 4.5,
    "OCT": 5.07,
    "NOV": 5.73,
    "DEC": 5.8
  },
  {
    "LONG": -54.84,
    "LAT": -28.12,
    "CIDADE": "Salvador das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.81,
    "FEB": 5.83,
    "MAR": 5.59,
    "APR": 4.95,
    "MAY": 4.12,
    "JUN": 3.51,
    "JUL": 3.89,
    "AUG": 4.54,
    "SEP": 4.47,
    "OCT": 5.07,
    "NOV": 5.72,
    "DEC": 5.78
  },
  {
    "LONG": -54.89,
    "LAT": -2.81,
    "CIDADE": "São Pedro do Butiá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.81,
    "FEB": 5.83,
    "MAR": 5.59,
    "APR": 4.95,
    "MAY": 4.12,
    "JUN": 3.51,
    "JUL": 3.89,
    "AUG": 4.54,
    "SEP": 4.47,
    "OCT": 5.07,
    "NOV": 5.72,
    "DEC": 5.78
  },
  {
    "LONG": -54.74,
    "LAT": -28.15,
    "CIDADE": "Cerro Largo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.75,
    "FEB": 5.81,
    "MAR": 5.57,
    "APR": 4.96,
    "MAY": 4.13,
    "JUN": 3.51,
    "JUL": 3.9,
    "AUG": 4.54,
    "SEP": 4.47,
    "OCT": 5.07,
    "NOV": 5.71,
    "DEC": 5.76
  },
  {
    "LONG": -54.56,
    "LAT": -2.81,
    "CIDADE": "Guarani das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.74,
    "FEB": 5.83,
    "MAR": 5.53,
    "APR": 4.93,
    "MAY": 4.12,
    "JUN": 3.52,
    "JUL": 3.89,
    "AUG": 4.57,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.67,
    "DEC": 5.75
  },
  {
    "LONG": -54.46,
    "LAT": -2.81,
    "CIDADE": "Sete de Setembro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.74,
    "FEB": 5.8,
    "MAR": 5.52,
    "APR": 4.93,
    "MAY": 4.13,
    "JUN": 3.54,
    "JUL": 3.92,
    "AUG": 4.58,
    "SEP": 4.46,
    "OCT": 5.08,
    "NOV": 5.67,
    "DEC": 5.74
  },
  {
    "LONG": -53.7,
    "LAT": -28.07,
    "CIDADE": "Nova Ramada",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.61,
    "FEB": 5.75,
    "MAR": 5.45,
    "APR": 4.89,
    "MAY": 4.11,
    "JUN": 3.6,
    "JUL": 3.89,
    "AUG": 4.57,
    "SEP": 4.38,
    "OCT": 5.04,
    "NOV": 5.55,
    "DEC": 5.67
  },
  {
    "LONG": -5.31,
    "LAT": -28.06,
    "CIDADE": "Chapada",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.55,
    "FEB": 5.65,
    "MAR": 5.4,
    "APR": 4.9,
    "MAY": 4.07,
    "JUN": 3.51,
    "JUL": 3.91,
    "AUG": 4.6,
    "SEP": 4.33,
    "OCT": 5.04,
    "NOV": 5.64,
    "DEC": 5.66
  },
  {
    "LONG": -52.91,
    "LAT": -28.12,
    "CIDADE": "Almirante Tamandaré do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.37,
    "APR": 4.94,
    "MAY": 4.08,
    "JUN": 3.54,
    "JUL": 3.92,
    "AUG": 4.59,
    "SEP": 4.3,
    "OCT": 5.03,
    "NOV": 5.64,
    "DEC": 5.69
  },
  {
    "LONG": -52.78,
    "LAT": -28.12,
    "CIDADE": "Coqueiros do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.59,
    "FEB": 5.59,
    "MAR": 5.36,
    "APR": 4.89,
    "MAY": 4.05,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.57,
    "SEP": 4.29,
    "OCT": 5.01,
    "NOV": 5.6,
    "DEC": 5.65
  },
  {
    "LONG": -52.68,
    "LAT": -2.81,
    "CIDADE": "Pontão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.57,
    "FEB": 5.58,
    "MAR": 5.3,
    "APR": 4.92,
    "MAY": 4.05,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.57,
    "SEP": 4.29,
    "OCT": 5,
    "NOV": 5.58,
    "DEC": 5.63
  },
  {
    "LONG": -52.3,
    "LAT": -28.13,
    "CIDADE": "Coxilha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.5,
    "FEB": 5.57,
    "MAR": 5.34,
    "APR": 4.88,
    "MAY": 4.02,
    "JUN": 3.48,
    "JUL": 3.95,
    "AUG": 4.59,
    "SEP": 4.29,
    "OCT": 4.97,
    "NOV": 5.59,
    "DEC": 5.58
  },
  {
    "LONG": -52.14,
    "LAT": -28.11,
    "CIDADE": "Vila Lângaro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.48,
    "FEB": 5.57,
    "MAR": 5.27,
    "APR": 4.87,
    "MAY": 4.01,
    "JUN": 3.47,
    "JUL": 3.89,
    "AUG": 4.59,
    "SEP": 4.3,
    "OCT": 4.95,
    "NOV": 5.56,
    "DEC": 5.59
  },
  {
    "LONG": -52.01,
    "LAT": -28.07,
    "CIDADE": "Tapejara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.48,
    "FEB": 5.55,
    "MAR": 5.31,
    "APR": 4.87,
    "MAY": 3.99,
    "JUN": 3.5,
    "JUL": 3.91,
    "AUG": 4.6,
    "SEP": 4.3,
    "OCT": 4.94,
    "NOV": 5.58,
    "DEC": 5.6
  },
  {
    "LONG": -51.86,
    "LAT": -2.81,
    "CIDADE": "Ibiaçá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.47,
    "FEB": 5.48,
    "MAR": 5.32,
    "APR": 4.86,
    "MAY": 3.97,
    "JUN": 3.48,
    "JUL": 3.92,
    "AUG": 4.59,
    "SEP": 4.27,
    "OCT": 4.93,
    "NOV": 5.61,
    "DEC": 5.62
  },
  {
    "LONG": -51.4,
    "LAT": -28.13,
    "CIDADE": "Capão Bonito do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.78,
    "JAN": 5.46,
    "FEB": 5.54,
    "MAR": 5.25,
    "APR": 4.75,
    "MAY": 3.94,
    "JUN": 3.59,
    "JUL": 3.93,
    "AUG": 4.6,
    "SEP": 4.3,
    "OCT": 4.87,
    "NOV": 5.55,
    "DEC": 5.53
  },
  {
    "LONG": -51.19,
    "LAT": -28.05,
    "CIDADE": "Esmeralda",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.46,
    "FEB": 5.45,
    "MAR": 5.21,
    "APR": 4.74,
    "MAY": 3.92,
    "JUN": 3.55,
    "JUL": 3.94,
    "AUG": 4.59,
    "SEP": 4.28,
    "OCT": 4.85,
    "NOV": 5.54,
    "DEC": 5.5
  },
  {
    "LONG": -55.2,
    "LAT": -28.04,
    "CIDADE": "Pirapó",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.8,
    "FEB": 5.83,
    "MAR": 5.62,
    "APR": 4.91,
    "MAY": 4.12,
    "JUN": 3.46,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.51,
    "OCT": 5.11,
    "NOV": 5.73,
    "DEC": 5.78
  },
  {
    "LONG": -54.94,
    "LAT": -2.8,
    "CIDADE": "São Paulo das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.77,
    "FEB": 5.81,
    "MAR": 5.59,
    "APR": 4.93,
    "MAY": 4.11,
    "JUN": 3.51,
    "JUL": 3.89,
    "AUG": 4.56,
    "SEP": 4.48,
    "OCT": 5.08,
    "NOV": 5.72,
    "DEC": 5.79
  },
  {
    "LONG": -54.84,
    "LAT": -27.99,
    "CIDADE": "Campina das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.76,
    "FEB": 5.78,
    "MAR": 5.56,
    "APR": 4.93,
    "MAY": 4.12,
    "JUN": 3.5,
    "JUL": 3.9,
    "AUG": 4.56,
    "SEP": 4.47,
    "OCT": 5.08,
    "NOV": 5.69,
    "DEC": 5.77
  },
  {
    "LONG": -54.75,
    "LAT": -2.8,
    "CIDADE": "Cândido Godói",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.72,
    "FEB": 5.77,
    "MAR": 5.55,
    "APR": 4.94,
    "MAY": 4.14,
    "JUN": 3.52,
    "JUL": 3.91,
    "AUG": 4.55,
    "SEP": 4.47,
    "OCT": 5.08,
    "NOV": 5.68,
    "DEC": 5.76
  },
  {
    "LONG": -54.68,
    "LAT": -2.8,
    "CIDADE": "Ubiretama",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.71,
    "FEB": 5.78,
    "MAR": 5.54,
    "APR": 4.95,
    "MAY": 4.13,
    "JUN": 3.52,
    "JUL": 3.89,
    "AUG": 4.56,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.67,
    "DEC": 5.76
  },
  {
    "LONG": -54.55,
    "LAT": -28.03,
    "CIDADE": "Senador Salgado Filho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.72,
    "FEB": 5.8,
    "MAR": 5.53,
    "APR": 4.95,
    "MAY": 4.14,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.58,
    "SEP": 4.48,
    "OCT": 5.09,
    "NOV": 5.67,
    "DEC": 5.75
  },
  {
    "LONG": -54.35,
    "LAT": -28.03,
    "CIDADE": "Giruá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.73,
    "FEB": 5.8,
    "MAR": 5.52,
    "APR": 4.94,
    "MAY": 4.14,
    "JUN": 3.56,
    "JUL": 3.91,
    "AUG": 4.6,
    "SEP": 4.46,
    "OCT": 5.1,
    "NOV": 5.66,
    "DEC": 5.74
  },
  {
    "LONG": -52.98,
    "LAT": -27.99,
    "CIDADE": "Nova Boa Vista",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.55,
    "FEB": 5.66,
    "MAR": 5.4,
    "APR": 4.97,
    "MAY": 4.07,
    "JUN": 3.57,
    "JUL": 3.93,
    "AUG": 4.59,
    "SEP": 4.32,
    "OCT": 5.07,
    "NOV": 5.64,
    "DEC": 5.71
  },
  {
    "LONG": -52.26,
    "LAT": -27.98,
    "CIDADE": "Sertão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.5,
    "FEB": 5.56,
    "MAR": 5.3,
    "APR": 4.86,
    "MAY": 4.02,
    "JUN": 3.49,
    "JUL": 3.95,
    "AUG": 4.61,
    "SEP": 4.33,
    "OCT": 4.98,
    "NOV": 5.56,
    "DEC": 5.59
  },
  {
    "LONG": -55.14,
    "LAT": -27.91,
    "CIDADE": "Porto Xavier",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.79,
    "FEB": 5.78,
    "MAR": 5.59,
    "APR": 4.89,
    "MAY": 4.1,
    "JUN": 3.46,
    "JUL": 3.83,
    "AUG": 4.51,
    "SEP": 4.47,
    "OCT": 5.06,
    "NOV": 5.68,
    "DEC": 5.76
  },
  {
    "LONG": -55.01,
    "LAT": -27.86,
    "CIDADE": "Porto Lucena",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.74,
    "FEB": 5.78,
    "MAR": 5.57,
    "APR": 4.89,
    "MAY": 4.11,
    "JUN": 3.48,
    "JUL": 3.85,
    "AUG": 4.5,
    "SEP": 4.48,
    "OCT": 5.05,
    "NOV": 5.69,
    "DEC": 5.76
  },
  {
    "LONG": -54.48,
    "LAT": -27.87,
    "CIDADE": "Santa Rosa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.7,
    "FEB": 5.77,
    "MAR": 5.53,
    "APR": 4.95,
    "MAY": 4.12,
    "JUN": 3.52,
    "JUL": 3.88,
    "AUG": 4.57,
    "SEP": 4.46,
    "OCT": 5.09,
    "NOV": 5.66,
    "DEC": 5.75
  },
  {
    "LONG": -54.02,
    "LAT": -27.88,
    "CIDADE": "Inhacorá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.7,
    "FEB": 5.75,
    "MAR": 5.53,
    "APR": 4.94,
    "MAY": 4.11,
    "JUN": 3.55,
    "JUL": 3.91,
    "AUG": 4.62,
    "SEP": 4.42,
    "OCT": 5.1,
    "NOV": 5.63,
    "DEC": 5.76
  },
  {
    "LONG": -53.94,
    "LAT": -27.92,
    "CIDADE": "Chiapetta",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.67,
    "FEB": 5.74,
    "MAR": 5.47,
    "APR": 4.92,
    "MAY": 4.11,
    "JUN": 3.56,
    "JUL": 3.93,
    "AUG": 4.63,
    "SEP": 4.41,
    "OCT": 5.07,
    "NOV": 5.59,
    "DEC": 5.74
  },
  {
    "LONG": -53.78,
    "LAT": -2.79,
    "CIDADE": "Santo Augusto",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.62,
    "FEB": 5.72,
    "MAR": 5.43,
    "APR": 4.9,
    "MAY": 4.13,
    "JUN": 3.57,
    "JUL": 3.96,
    "AUG": 4.63,
    "SEP": 4.41,
    "OCT": 5.07,
    "NOV": 5.58,
    "DEC": 5.72
  },
  {
    "LONG": -53.31,
    "LAT": -27.9,
    "CIDADE": "Palmeira das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.64,
    "FEB": 5.7,
    "MAR": 5.35,
    "APR": 4.88,
    "MAY": 4.12,
    "JUN": 3.59,
    "JUL": 3.97,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.06,
    "NOV": 5.59,
    "DEC": 5.7
  },
  {
    "LONG": -53.11,
    "LAT": -27.91,
    "CIDADE": "Novo Barreiro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.61,
    "FEB": 5.67,
    "MAR": 5.4,
    "APR": 4.94,
    "MAY": 4.1,
    "JUN": 3.55,
    "JUL": 3.95,
    "AUG": 4.59,
    "SEP": 4.39,
    "OCT": 5.06,
    "NOV": 5.63,
    "DEC": 5.7
  },
  {
    "LONG": -53.04,
    "LAT": -27.92,
    "CIDADE": "Barra Funda",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.63,
    "FEB": 5.7,
    "MAR": 5.45,
    "APR": 4.96,
    "MAY": 4.08,
    "JUN": 3.53,
    "JUL": 3.95,
    "AUG": 4.59,
    "SEP": 4.35,
    "OCT": 5.07,
    "NOV": 5.66,
    "DEC": 5.73
  },
  {
    "LONG": -52.92,
    "LAT": -27.94,
    "CIDADE": "Sarandi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.6,
    "FEB": 5.7,
    "MAR": 5.4,
    "APR": 4.96,
    "MAY": 4.09,
    "JUN": 3.56,
    "JUL": 3.96,
    "AUG": 4.61,
    "SEP": 4.33,
    "OCT": 5.06,
    "NOV": 5.62,
    "DEC": 5.71
  },
  {
    "LONG": -52.43,
    "LAT": -27.94,
    "CIDADE": "Ipiranga do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.53,
    "FEB": 5.58,
    "MAR": 5.3,
    "APR": 4.9,
    "MAY": 4.07,
    "JUN": 3.57,
    "JUL": 3.99,
    "AUG": 4.64,
    "SEP": 4.32,
    "OCT": 4.98,
    "NOV": 5.53,
    "DEC": 5.62
  },
  {
    "LONG": -52.3,
    "LAT": -27.85,
    "CIDADE": "Erebango",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.54,
    "FEB": 5.56,
    "MAR": 5.28,
    "APR": 4.87,
    "MAY": 4.04,
    "JUN": 3.57,
    "JUL": 3.97,
    "AUG": 4.63,
    "SEP": 4.34,
    "OCT": 4.97,
    "NOV": 5.55,
    "DEC": 5.61
  },
  {
    "LONG": -52.23,
    "LAT": -27.89,
    "CIDADE": "Getúlio Vargas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.52,
    "FEB": 5.54,
    "MAR": 5.28,
    "APR": 4.85,
    "MAY": 4.01,
    "JUN": 3.53,
    "JUL": 3.96,
    "AUG": 4.62,
    "SEP": 4.33,
    "OCT": 4.98,
    "NOV": 5.56,
    "DEC": 5.59
  },
  {
    "LONG": -5.23,
    "LAT": -27.91,
    "CIDADE": "Estação",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.52,
    "FEB": 5.54,
    "MAR": 5.28,
    "APR": 4.85,
    "MAY": 4.01,
    "JUN": 3.53,
    "JUL": 3.96,
    "AUG": 4.62,
    "SEP": 4.33,
    "OCT": 4.98,
    "NOV": 5.56,
    "DEC": 5.59
  },
  {
    "LONG": -52.02,
    "LAT": -27.95,
    "CIDADE": "Charrua",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.48,
    "FEB": 5.51,
    "MAR": 5.33,
    "APR": 4.88,
    "MAY": 4.01,
    "JUN": 3.48,
    "JUL": 3.9,
    "AUG": 4.59,
    "SEP": 4.31,
    "OCT": 4.95,
    "NOV": 5.58,
    "DEC": 5.59
  },
  {
    "LONG": -52.08,
    "LAT": -27.86,
    "CIDADE": "Floriano Peixoto",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.48,
    "FEB": 5.51,
    "MAR": 5.33,
    "APR": 4.88,
    "MAY": 4.01,
    "JUN": 3.48,
    "JUL": 3.9,
    "AUG": 4.59,
    "SEP": 4.31,
    "OCT": 4.95,
    "NOV": 5.58,
    "DEC": 5.59
  },
  {
    "LONG": -51.81,
    "LAT": -27.95,
    "CIDADE": "Sananduva",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.51,
    "FEB": 5.53,
    "MAR": 5.35,
    "APR": 4.92,
    "MAY": 3.99,
    "JUN": 3.54,
    "JUL": 3.93,
    "AUG": 4.6,
    "SEP": 4.3,
    "OCT": 4.95,
    "NOV": 5.64,
    "DEC": 5.62
  },
  {
    "LONG": -51.64,
    "LAT": -27.91,
    "CIDADE": "Santo Expedito do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.55,
    "FEB": 5.52,
    "MAR": 5.31,
    "APR": 4.87,
    "MAY": 3.98,
    "JUN": 3.6,
    "JUL": 3.97,
    "AUG": 4.65,
    "SEP": 4.32,
    "OCT": 4.93,
    "NOV": 5.62,
    "DEC": 5.61
  },
  {
    "LONG": -51.54,
    "LAT": -27.92,
    "CIDADE": "Tupanci do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.53,
    "FEB": 5.5,
    "MAR": 5.3,
    "APR": 4.85,
    "MAY": 3.98,
    "JUN": 3.6,
    "JUL": 3.96,
    "AUG": 4.65,
    "SEP": 4.32,
    "OCT": 4.91,
    "NOV": 5.6,
    "DEC": 5.58
  },
  {
    "LONG": -51.17,
    "LAT": -27.88,
    "CIDADE": "Pinhal da Serra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.41,
    "FEB": 5.47,
    "MAR": 5.24,
    "APR": 4.78,
    "MAY": 3.9,
    "JUN": 3.56,
    "JUL": 3.9,
    "AUG": 4.58,
    "SEP": 4.28,
    "OCT": 4.83,
    "NOV": 5.55,
    "DEC": 5.53
  },
  {
    "LONG": -54.66,
    "LAT": -27.83,
    "CIDADE": "Santo Cristo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.7,
    "FEB": 5.73,
    "MAR": 5.53,
    "APR": 4.94,
    "MAY": 4.09,
    "JUN": 3.46,
    "JUL": 3.83,
    "AUG": 4.58,
    "SEP": 4.46,
    "OCT": 5.09,
    "NOV": 5.63,
    "DEC": 5.76
  },
  {
    "LONG": -54.48,
    "LAT": -27.76,
    "CIDADE": "Tuparendi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.71,
    "FEB": 5.76,
    "MAR": 5.52,
    "APR": 4.94,
    "MAY": 4.1,
    "JUN": 3.52,
    "JUL": 3.86,
    "AUG": 4.59,
    "SEP": 4.46,
    "OCT": 5.11,
    "NOV": 5.64,
    "DEC": 5.74
  },
  {
    "LONG": -54.24,
    "LAT": -27.78,
    "CIDADE": "Três de Maio",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.73,
    "FEB": 5.78,
    "MAR": 5.48,
    "APR": 4.94,
    "MAY": 4.12,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.61,
    "SEP": 4.44,
    "OCT": 5.11,
    "NOV": 5.6,
    "DEC": 5.74
  },
  {
    "LONG": -54.19,
    "LAT": -27.84,
    "CIDADE": "Independência",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.74,
    "FEB": 5.78,
    "MAR": 5.51,
    "APR": 4.94,
    "MAY": 4.12,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.6,
    "SEP": 4.42,
    "OCT": 5.14,
    "NOV": 5.65,
    "DEC": 5.75
  },
  {
    "LONG": -54.06,
    "LAT": -27.83,
    "CIDADE": "Alegria",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.7,
    "FEB": 5.73,
    "MAR": 5.5,
    "APR": 4.94,
    "MAY": 4.11,
    "JUN": 3.57,
    "JUL": 3.92,
    "AUG": 4.63,
    "SEP": 4.41,
    "OCT": 5.12,
    "NOV": 5.63,
    "DEC": 5.76
  },
  {
    "LONG": -53.94,
    "LAT": -27.79,
    "CIDADE": "São Valério do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.67,
    "FEB": 5.73,
    "MAR": 5.44,
    "APR": 4.91,
    "MAY": 4.1,
    "JUN": 3.58,
    "JUL": 3.93,
    "AUG": 4.64,
    "SEP": 4.4,
    "OCT": 5.08,
    "NOV": 5.59,
    "DEC": 5.73
  },
  {
    "LONG": -53.25,
    "LAT": -2.78,
    "CIDADE": "São Pedro das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.62,
    "FEB": 5.63,
    "MAR": 5.37,
    "APR": 4.95,
    "MAY": 4.11,
    "JUN": 3.57,
    "JUL": 3.97,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.06,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -53.12,
    "LAT": -27.78,
    "CIDADE": "São José das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.62,
    "FEB": 5.66,
    "MAR": 5.41,
    "APR": 4.97,
    "MAY": 4.1,
    "JUN": 3.55,
    "JUL": 3.96,
    "AUG": 4.6,
    "SEP": 4.38,
    "OCT": 5.07,
    "NOV": 5.64,
    "DEC": 5.73
  },
  {
    "LONG": -52.91,
    "LAT": -27.83,
    "CIDADE": "Rondinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.59,
    "FEB": 5.68,
    "MAR": 5.42,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.56,
    "JUL": 3.99,
    "AUG": 4.62,
    "SEP": 4.35,
    "OCT": 5.07,
    "NOV": 5.61,
    "DEC": 5.72
  },
  {
    "LONG": -5.28,
    "LAT": -27.78,
    "CIDADE": "Ronda Alta",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.57,
    "FEB": 5.65,
    "MAR": 5.4,
    "APR": 4.94,
    "MAY": 4.09,
    "JUN": 3.56,
    "JUL": 3.98,
    "AUG": 4.62,
    "SEP": 4.34,
    "OCT": 5.06,
    "NOV": 5.58,
    "DEC": 5.69
  },
  {
    "LONG": -52.44,
    "LAT": -27.83,
    "CIDADE": "Quatro Irmãos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.51,
    "FEB": 5.56,
    "MAR": 5.33,
    "APR": 4.9,
    "MAY": 4.06,
    "JUN": 3.58,
    "JUL": 3.97,
    "AUG": 4.67,
    "SEP": 4.34,
    "OCT": 5.01,
    "NOV": 5.54,
    "DEC": 5.62
  },
  {
    "LONG": -52,
    "LAT": -2.78,
    "CIDADE": "Centenário",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.47,
    "FEB": 5.49,
    "MAR": 5.34,
    "APR": 4.9,
    "MAY": 4.01,
    "JUN": 3.48,
    "JUL": 3.92,
    "AUG": 4.61,
    "SEP": 4.33,
    "OCT": 4.98,
    "NOV": 5.6,
    "DEC": 5.6
  },
  {
    "LONG": -51.83,
    "LAT": "#VALOR!",
    "CIDADE": "São João da Urtiga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.52,
    "FEB": 5.52,
    "MAR": 5.36,
    "APR": 4.92,
    "MAY": 3.98,
    "JUN": 3.54,
    "JUL": 3.92,
    "AUG": 4.62,
    "SEP": 4.32,
    "OCT": 4.97,
    "NOV": 5.64,
    "DEC": 5.62
  },
  {
    "LONG": -51.66,
    "LAT": -27.77,
    "CIDADE": "Cacique Doble",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.55,
    "FEB": 5.52,
    "MAR": 5.32,
    "APR": 4.89,
    "MAY": 4,
    "JUN": 3.62,
    "JUL": 3.98,
    "AUG": 4.67,
    "SEP": 4.33,
    "OCT": 4.97,
    "NOV": 5.62,
    "DEC": 5.62
  },
  {
    "LONG": -51.6,
    "LAT": -27.77,
    "CIDADE": "São José do Ouro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.55,
    "FEB": 5.52,
    "MAR": 5.29,
    "APR": 4.84,
    "MAY": 3.97,
    "JUN": 3.58,
    "JUL": 3.97,
    "AUG": 4.67,
    "SEP": 4.33,
    "OCT": 4.93,
    "NOV": 5.58,
    "DEC": 5.58
  },
  {
    "LONG": -54.9,
    "LAT": -2.77,
    "CIDADE": "Porto Vera Cruz",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.69,
    "FEB": 5.68,
    "MAR": 5.54,
    "APR": 4.91,
    "MAY": 4.09,
    "JUN": 3.45,
    "JUL": 3.81,
    "AUG": 4.55,
    "SEP": 4.44,
    "OCT": 5.07,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -54.77,
    "LAT": -27.66,
    "CIDADE": "Alecrim",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.71,
    "FEB": 5.71,
    "MAR": 5.52,
    "APR": 4.91,
    "MAY": 4.07,
    "JUN": 3.46,
    "JUL": 3.84,
    "AUG": 4.56,
    "SEP": 4.45,
    "OCT": 5.11,
    "NOV": 5.61,
    "DEC": 5.76
  },
  {
    "LONG": -54.44,
    "LAT": -27.66,
    "CIDADE": "Tucunduva",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.73,
    "FEB": 5.72,
    "MAR": 5.5,
    "APR": 4.91,
    "MAY": 4.08,
    "JUN": 3.49,
    "JUL": 3.87,
    "AUG": 4.57,
    "SEP": 4.45,
    "OCT": 5.12,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -54.11,
    "LAT": -27.67,
    "CIDADE": "Boa Vista do Buricá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.76,
    "FEB": 5.73,
    "MAR": 5.52,
    "APR": 4.94,
    "MAY": 4.13,
    "JUN": 3.52,
    "JUL": 3.89,
    "AUG": 4.64,
    "SEP": 4.41,
    "OCT": 5.15,
    "NOV": 5.64,
    "DEC": 5.75
  },
  {
    "LONG": -54.13,
    "LAT": -27.73,
    "CIDADE": "São José do Inhacorá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.76,
    "FEB": 5.73,
    "MAR": 5.52,
    "APR": 4.94,
    "MAY": 4.13,
    "JUN": 3.52,
    "JUL": 3.89,
    "AUG": 4.64,
    "SEP": 4.41,
    "OCT": 5.15,
    "NOV": 5.64,
    "DEC": 5.75
  },
  {
    "LONG": -53.97,
    "LAT": -27.71,
    "CIDADE": "São Martinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.69,
    "FEB": 5.71,
    "MAR": 5.47,
    "APR": 4.92,
    "MAY": 4.12,
    "JUN": 3.58,
    "JUL": 3.94,
    "AUG": 4.62,
    "SEP": 4.39,
    "OCT": 5.11,
    "NOV": 5.58,
    "DEC": 5.72
  },
  {
    "LONG": -53.81,
    "LAT": -27.68,
    "CIDADE": "Campo Novo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.69,
    "FEB": 5.72,
    "MAR": 5.45,
    "APR": 4.9,
    "MAY": 4.12,
    "JUN": 3.57,
    "JUL": 3.97,
    "AUG": 4.65,
    "SEP": 4.4,
    "OCT": 5.09,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -53.7,
    "LAT": -27.72,
    "CIDADE": "Coronel Bicaco",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.69,
    "FEB": 5.7,
    "MAR": 5.45,
    "APR": 4.9,
    "MAY": 4.13,
    "JUN": 3.56,
    "JUL": 3.96,
    "AUG": 4.64,
    "SEP": 4.41,
    "OCT": 5.1,
    "NOV": 5.6,
    "DEC": 5.69
  },
  {
    "LONG": -53.64,
    "LAT": -27.66,
    "CIDADE": "Redentora",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.66,
    "FEB": 5.68,
    "MAR": 5.41,
    "APR": 4.89,
    "MAY": 4.11,
    "JUN": 3.58,
    "JUL": 3.97,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.08,
    "NOV": 5.58,
    "DEC": 5.71
  },
  {
    "LONG": -53.53,
    "LAT": -27.66,
    "CIDADE": "Dois Irmãos das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.67,
    "FEB": 5.66,
    "MAR": 5.41,
    "APR": 4.92,
    "MAY": 4.12,
    "JUN": 3.56,
    "JUL": 3.99,
    "AUG": 4.65,
    "SEP": 4.4,
    "OCT": 5.1,
    "NOV": 5.58,
    "DEC": 5.7
  },
  {
    "LONG": -53.31,
    "LAT": -27.67,
    "CIDADE": "Boa Vista das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.62,
    "FEB": 5.64,
    "MAR": 5.39,
    "APR": 4.92,
    "MAY": 4.12,
    "JUN": 3.57,
    "JUL": 4,
    "AUG": 4.65,
    "SEP": 4.4,
    "OCT": 5.07,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -53.14,
    "LAT": -2.77,
    "CIDADE": "Sagrada Família",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.62,
    "FEB": 5.63,
    "MAR": 5.44,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.53,
    "JUL": 3.96,
    "AUG": 4.63,
    "SEP": 4.39,
    "OCT": 5.08,
    "NOV": 5.66,
    "DEC": 5.77
  },
  {
    "LONG": -53.18,
    "LAT": -27.69,
    "CIDADE": "Lajeado do Bugre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.62,
    "FEB": 5.63,
    "MAR": 5.44,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.53,
    "JUL": 3.96,
    "AUG": 4.63,
    "SEP": 4.39,
    "OCT": 5.08,
    "NOV": 5.66,
    "DEC": 5.77
  },
  {
    "LONG": -53.06,
    "LAT": -27.75,
    "CIDADE": "Novo Xingu",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.59,
    "FEB": 5.67,
    "MAR": 5.49,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.54,
    "JUL": 3.93,
    "AUG": 4.6,
    "SEP": 4.37,
    "OCT": 5.09,
    "NOV": 5.65,
    "DEC": 5.76
  },
  {
    "LONG": -5.29,
    "LAT": -27.71,
    "CIDADE": "Engenho Velho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.53,
    "FEB": 5.64,
    "MAR": 5.43,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.57,
    "JUL": 3.99,
    "AUG": 4.64,
    "SEP": 4.37,
    "OCT": 5.07,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -52.99,
    "LAT": -27.73,
    "CIDADE": "Constantina",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.53,
    "FEB": 5.64,
    "MAR": 5.43,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.57,
    "JUL": 3.99,
    "AUG": 4.64,
    "SEP": 4.37,
    "OCT": 5.07,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -52.65,
    "LAT": -27.67,
    "CIDADE": "Cruzaltense",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.61,
    "FEB": 5.67,
    "MAR": 5.38,
    "APR": 4.93,
    "MAY": 4.07,
    "JUN": 3.58,
    "JUL": 3.96,
    "AUG": 4.69,
    "SEP": 4.37,
    "OCT": 5.05,
    "NOV": 5.56,
    "DEC": 5.67
  },
  {
    "LONG": -52.63,
    "LAT": -27.72,
    "CIDADE": "Campinas do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.61,
    "FEB": 5.67,
    "MAR": 5.38,
    "APR": 4.93,
    "MAY": 4.07,
    "JUN": 3.58,
    "JUL": 3.96,
    "AUG": 4.69,
    "SEP": 4.37,
    "OCT": 5.05,
    "NOV": 5.56,
    "DEC": 5.67
  },
  {
    "LONG": -52.54,
    "LAT": -27.73,
    "CIDADE": "Jacutinga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.53,
    "FEB": 5.59,
    "MAR": 5.37,
    "APR": 4.92,
    "MAY": 4.06,
    "JUN": 3.58,
    "JUL": 3.97,
    "AUG": 4.68,
    "SEP": 4.35,
    "OCT": 5.02,
    "NOV": 5.55,
    "DEC": 5.64
  },
  {
    "LONG": -52.42,
    "LAT": -27.71,
    "CIDADE": "Paulo Bento",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.48,
    "FEB": 5.52,
    "MAR": 5.35,
    "APR": 4.89,
    "MAY": 4.02,
    "JUN": 3.57,
    "JUL": 3.99,
    "AUG": 4.69,
    "SEP": 4.34,
    "OCT": 5.01,
    "NOV": 5.54,
    "DEC": 5.61
  },
  {
    "LONG": -52.49,
    "LAT": -27.66,
    "CIDADE": "Ponte Preta",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.48,
    "FEB": 5.52,
    "MAR": 5.35,
    "APR": 4.89,
    "MAY": 4.02,
    "JUN": 3.57,
    "JUL": 3.99,
    "AUG": 4.69,
    "SEP": 4.34,
    "OCT": 5.01,
    "NOV": 5.54,
    "DEC": 5.61
  },
  {
    "LONG": -5.21,
    "LAT": -27.69,
    "CIDADE": "Áurea",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.49,
    "FEB": 5.53,
    "MAR": 5.34,
    "APR": 4.86,
    "MAY": 4.02,
    "JUN": 3.5,
    "JUL": 3.93,
    "AUG": 4.64,
    "SEP": 4.36,
    "OCT": 4.97,
    "NOV": 5.58,
    "DEC": 5.58
  },
  {
    "LONG": -51.91,
    "LAT": -27.72,
    "CIDADE": "Carlos Gomes",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.5,
    "FEB": 5.54,
    "MAR": 5.38,
    "APR": 4.87,
    "MAY": 4.01,
    "JUN": 3.47,
    "JUL": 3.91,
    "AUG": 4.63,
    "SEP": 4.35,
    "OCT": 5,
    "NOV": 5.63,
    "DEC": 5.63
  },
  {
    "LONG": -51.76,
    "LAT": -2.77,
    "CIDADE": "Paim Filho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.53,
    "FEB": 5.52,
    "MAR": 5.36,
    "APR": 4.92,
    "MAY": 4.02,
    "JUN": 3.6,
    "JUL": 3.94,
    "AUG": 4.65,
    "SEP": 4.34,
    "OCT": 4.97,
    "NOV": 5.64,
    "DEC": 5.63
  },
  {
    "LONG": -5.15,
    "LAT": -27.67,
    "CIDADE": "Barracão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.49,
    "FEB": 5.49,
    "MAR": 5.29,
    "APR": 4.83,
    "MAY": 3.96,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.65,
    "SEP": 4.34,
    "OCT": 4.91,
    "NOV": 5.59,
    "DEC": 5.59
  },
  {
    "LONG": -54.67,
    "LAT": "#VALOR!",
    "CIDADE": "Porto Mauá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.5,
    "APR": 4.86,
    "MAY": 4.01,
    "JUN": 3.39,
    "JUL": 3.76,
    "AUG": 4.52,
    "SEP": 4.43,
    "OCT": 5.11,
    "NOV": 5.6,
    "DEC": 5.72
  },
  {
    "LONG": -54.5,
    "LAT": -27.58,
    "CIDADE": "Novo Machado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.73,
    "FEB": 5.72,
    "MAR": 5.52,
    "APR": 4.86,
    "MAY": 4.04,
    "JUN": 3.44,
    "JUL": 3.79,
    "AUG": 4.54,
    "SEP": 4.43,
    "OCT": 5.1,
    "NOV": 5.59,
    "DEC": 5.73
  },
  {
    "LONG": -54.31,
    "LAT": -27.63,
    "CIDADE": "Horizontina",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.69,
    "FEB": 5.76,
    "MAR": 5.52,
    "APR": 4.87,
    "MAY": 4.07,
    "JUN": 3.49,
    "JUL": 3.86,
    "AUG": 4.6,
    "SEP": 4.44,
    "OCT": 5.12,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -54.11,
    "LAT": -27.61,
    "CIDADE": "Nova Candelária",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.72,
    "FEB": 5.72,
    "MAR": 5.5,
    "APR": 4.89,
    "MAY": 4.11,
    "JUN": 3.5,
    "JUL": 3.9,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.16,
    "NOV": 5.61,
    "DEC": 5.72
  },
  {
    "LONG": "#VALOR!",
    "LAT": -27.57,
    "CIDADE": "Humaitá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.66,
    "FEB": 5.68,
    "MAR": 5.51,
    "APR": 4.89,
    "MAY": 4.12,
    "JUN": 3.55,
    "JUL": 3.97,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.13,
    "NOV": 5.6,
    "DEC": 5.71
  },
  {
    "LONG": -53.95,
    "LAT": -27.64,
    "CIDADE": "Sede Nova",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.66,
    "FEB": 5.68,
    "MAR": 5.51,
    "APR": 4.89,
    "MAY": 4.12,
    "JUN": 3.55,
    "JUL": 3.97,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.13,
    "NOV": 5.6,
    "DEC": 5.71
  },
  {
    "LONG": -5.37,
    "LAT": -27.62,
    "CIDADE": "Braga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.71,
    "FEB": 5.68,
    "MAR": 5.5,
    "APR": 4.88,
    "MAY": 4.13,
    "JUN": 3.55,
    "JUL": 3.99,
    "AUG": 4.62,
    "SEP": 4.42,
    "OCT": 5.11,
    "NOV": 5.62,
    "DEC": 5.7
  },
  {
    "LONG": -53.28,
    "LAT": -27.64,
    "CIDADE": "Jaboticaba",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.56,
    "FEB": 5.65,
    "MAR": 5.43,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.5,
    "JUL": 3.96,
    "AUG": 4.65,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.66,
    "DEC": 5.72
  },
  {
    "LONG": -53.18,
    "LAT": -27.57,
    "CIDADE": "Novo Tiradentes",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.57,
    "FEB": 5.66,
    "MAR": 5.47,
    "APR": 4.97,
    "MAY": 4.09,
    "JUN": 3.49,
    "JUL": 3.92,
    "AUG": 4.64,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.69,
    "DEC": 5.73
  },
  {
    "LONG": -53.17,
    "LAT": -27.61,
    "CIDADE": "Cerro Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.57,
    "FEB": 5.66,
    "MAR": 5.47,
    "APR": 4.97,
    "MAY": 4.09,
    "JUN": 3.49,
    "JUL": 3.92,
    "AUG": 4.64,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.69,
    "DEC": 5.73
  },
  {
    "LONG": -53.08,
    "LAT": -27.6,
    "CIDADE": "Liberato Salzano",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.59,
    "FEB": 5.64,
    "MAR": 5.48,
    "APR": 4.99,
    "MAY": 4.1,
    "JUN": 3.52,
    "JUL": 3.92,
    "AUG": 4.63,
    "SEP": 4.38,
    "OCT": 5.1,
    "NOV": 5.66,
    "DEC": 5.73
  },
  {
    "LONG": -52.84,
    "LAT": -27.61,
    "CIDADE": "Três Palmeiras",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.48,
    "FEB": 5.54,
    "MAR": 5.37,
    "APR": 4.92,
    "MAY": 4.07,
    "JUN": 3.58,
    "JUL": 3.98,
    "AUG": 4.66,
    "SEP": 4.38,
    "OCT": 5.05,
    "NOV": 5.56,
    "DEC": 5.64
  },
  {
    "LONG": -52.52,
    "LAT": -27.56,
    "CIDADE": "São Valentim",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.46,
    "FEB": 5.48,
    "MAR": 5.34,
    "APR": 4.94,
    "MAY": 4.06,
    "JUN": 3.59,
    "JUL": 3.98,
    "AUG": 4.7,
    "SEP": 4.37,
    "OCT": 5.02,
    "NOV": 5.55,
    "DEC": 5.59
  },
  {
    "LONG": -52.38,
    "LAT": -27.62,
    "CIDADE": "Barão de Cotegipe",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.47,
    "FEB": 5.5,
    "MAR": 5.33,
    "APR": 4.88,
    "MAY": 4.04,
    "JUN": 3.57,
    "JUL": 4,
    "AUG": 4.68,
    "SEP": 4.37,
    "OCT": 4.99,
    "NOV": 5.6,
    "DEC": 5.57
  },
  {
    "LONG": -52.27,
    "LAT": -27.64,
    "CIDADE": "Erechim",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.5,
    "FEB": 5.53,
    "MAR": 5.33,
    "APR": 4.87,
    "MAY": 4.03,
    "JUN": 3.56,
    "JUL": 3.99,
    "AUG": 4.66,
    "SEP": 4.37,
    "OCT": 4.97,
    "NOV": 5.59,
    "DEC": 5.59
  },
  {
    "LONG": -5.21,
    "LAT": -27.59,
    "CIDADE": "Gaurama",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.54,
    "FEB": 5.51,
    "MAR": 5.34,
    "APR": 4.86,
    "MAY": 4.02,
    "JUN": 3.53,
    "JUL": 3.94,
    "AUG": 4.66,
    "SEP": 4.38,
    "OCT": 4.98,
    "NOV": 5.61,
    "DEC": 5.61
  },
  {
    "LONG": -52.02,
    "LAT": -2.76,
    "CIDADE": "Viadutos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.54,
    "FEB": 5.51,
    "MAR": 5.34,
    "APR": 4.86,
    "MAY": 4.02,
    "JUN": 3.53,
    "JUL": 3.94,
    "AUG": 4.66,
    "SEP": 4.38,
    "OCT": 4.98,
    "NOV": 5.61,
    "DEC": 5.61
  },
  {
    "LONG": -51.8,
    "LAT": -2.76,
    "CIDADE": "Maximiliano de Almeida",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.59,
    "FEB": 5.58,
    "MAR": 5.43,
    "APR": 4.92,
    "MAY": 4.03,
    "JUN": 3.52,
    "JUL": 3.91,
    "AUG": 4.64,
    "SEP": 4.37,
    "OCT": 5.01,
    "NOV": 5.67,
    "DEC": 5.69
  },
  {
    "LONG": -51.67,
    "LAT": -27.57,
    "CIDADE": "Machadinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.59,
    "FEB": 5.54,
    "MAR": 5.38,
    "APR": 4.95,
    "MAY": 4.05,
    "JUN": 3.65,
    "JUL": 3.94,
    "AUG": 4.68,
    "SEP": 4.37,
    "OCT": 4.99,
    "NOV": 5.63,
    "DEC": 5.66
  },
  {
    "LONG": -54.36,
    "LAT": -27.51,
    "CIDADE": "Doutor Maurício Cardoso",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.68,
    "FEB": 5.72,
    "MAR": 5.53,
    "APR": 4.82,
    "MAY": 4.05,
    "JUN": 3.44,
    "JUL": 3.81,
    "AUG": 4.6,
    "SEP": 4.41,
    "OCT": 5.12,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -54.1,
    "LAT": -27.5,
    "CIDADE": "Crissiumal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.68,
    "FEB": 5.7,
    "MAR": 5.48,
    "APR": 4.85,
    "MAY": 4.09,
    "JUN": 3.48,
    "JUL": 3.9,
    "AUG": 4.61,
    "SEP": 4.41,
    "OCT": 5.16,
    "NOV": 5.61,
    "DEC": 5.71
  },
  {
    "LONG": -53.93,
    "LAT": -27.46,
    "CIDADE": "Três Passos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.64,
    "FEB": 5.66,
    "MAR": 5.49,
    "APR": 4.88,
    "MAY": 4.11,
    "JUN": 3.54,
    "JUL": 3.94,
    "AUG": 4.62,
    "SEP": 4.42,
    "OCT": 5.14,
    "NOV": 5.6,
    "DEC": 5.71
  },
  {
    "LONG": -53.87,
    "LAT": -27.54,
    "CIDADE": "Bom Progresso",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.67,
    "FEB": 5.66,
    "MAR": 5.5,
    "APR": 4.86,
    "MAY": 4.09,
    "JUN": 3.51,
    "JUL": 3.94,
    "AUG": 4.63,
    "SEP": 4.42,
    "OCT": 5.13,
    "NOV": 5.62,
    "DEC": 5.7
  },
  {
    "LONG": -53.69,
    "LAT": -27.5,
    "CIDADE": "Miraguaí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.68,
    "FEB": 5.63,
    "MAR": 5.49,
    "APR": 4.89,
    "MAY": 4.1,
    "JUN": 3.5,
    "JUL": 3.96,
    "AUG": 4.63,
    "SEP": 4.41,
    "OCT": 5.1,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -5.35,
    "LAT": -27.54,
    "CIDADE": "Erval Seco",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.64,
    "FEB": 5.6,
    "MAR": 5.48,
    "APR": 4.92,
    "MAY": 4.09,
    "JUN": 3.51,
    "JUL": 3.93,
    "AUG": 4.63,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.64,
    "DEC": 5.69
  },
  {
    "LONG": -53.4,
    "LAT": -27.48,
    "CIDADE": "Seberi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.64,
    "FEB": 5.59,
    "MAR": 5.46,
    "APR": 4.91,
    "MAY": 4.1,
    "JUN": 3.52,
    "JUL": 3.94,
    "AUG": 4.65,
    "SEP": 4.41,
    "OCT": 5.11,
    "NOV": 5.63,
    "DEC": 5.7
  },
  {
    "LONG": -53.21,
    "LAT": -27.51,
    "CIDADE": "Pinhal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 4.94,
    "MAY": 4.09,
    "JUN": 3.48,
    "JUL": 3.93,
    "AUG": 4.66,
    "SEP": 4.39,
    "OCT": 5.11,
    "NOV": 5.66,
    "DEC": 5.71
  },
  {
    "LONG": -53.24,
    "LAT": -27.45,
    "CIDADE": "Cristal do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 4.94,
    "MAY": 4.09,
    "JUN": 3.48,
    "JUL": 3.93,
    "AUG": 4.66,
    "SEP": 4.39,
    "OCT": 5.11,
    "NOV": 5.66,
    "DEC": 5.71
  },
  {
    "LONG": -5.32,
    "LAT": -27.47,
    "CIDADE": "Rodeio Bonito",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.48,
    "APR": 4.94,
    "MAY": 4.08,
    "JUN": 3.49,
    "JUL": 3.92,
    "AUG": 4.65,
    "SEP": 4.39,
    "OCT": 5.1,
    "NOV": 5.65,
    "DEC": 5.72
  },
  {
    "LONG": -5.29,
    "LAT": -27.52,
    "CIDADE": "Trindade do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.51,
    "FEB": 5.54,
    "MAR": 5.39,
    "APR": 4.93,
    "MAY": 4.07,
    "JUN": 3.6,
    "JUL": 3.96,
    "AUG": 4.68,
    "SEP": 4.4,
    "OCT": 5.04,
    "NOV": 5.54,
    "DEC": 5.63
  },
  {
    "LONG": -52.74,
    "LAT": -27.53,
    "CIDADE": "Entre Rios do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.51,
    "FEB": 5.55,
    "MAR": 5.38,
    "APR": 4.9,
    "MAY": 4.08,
    "JUN": 3.59,
    "JUL": 3.96,
    "AUG": 4.68,
    "SEP": 4.39,
    "OCT": 5.02,
    "NOV": 5.52,
    "DEC": 5.6
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.75,
    "CIDADE": "Benjamin Constant do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.52,
    "FEB": 5.52,
    "MAR": 5.42,
    "APR": 4.93,
    "MAY": 4.08,
    "JUN": 3.61,
    "JUL": 3.97,
    "AUG": 4.71,
    "SEP": 4.4,
    "OCT": 5.03,
    "NOV": 5.57,
    "DEC": 5.61
  },
  {
    "LONG": -52.15,
    "LAT": -27.5,
    "CIDADE": "Três Arroios",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.58,
    "FEB": 5.59,
    "MAR": 5.42,
    "APR": 4.89,
    "MAY": 4.04,
    "JUN": 3.57,
    "JUL": 3.98,
    "AUG": 4.68,
    "SEP": 4.39,
    "OCT": 5.02,
    "NOV": 5.63,
    "DEC": 5.65
  },
  {
    "LONG": "#VALOR!",
    "LAT": -27.47,
    "CIDADE": "Marcelino Ramos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.59,
    "FEB": 5.56,
    "MAR": 5.43,
    "APR": 4.87,
    "MAY": 4.01,
    "JUN": 3.48,
    "JUL": 3.89,
    "AUG": 4.66,
    "SEP": 4.39,
    "OCT": 5.02,
    "NOV": 5.68,
    "DEC": 5.68
  },
  {
    "LONG": -54.08,
    "LAT": -27.4,
    "CIDADE": "Tiradentes do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.66,
    "FEB": 5.66,
    "MAR": 5.48,
    "APR": 4.86,
    "MAY": 4.08,
    "JUN": 3.49,
    "JUL": 3.87,
    "AUG": 4.59,
    "SEP": 4.41,
    "OCT": 5.14,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -53.99,
    "LAT": -27.36,
    "CIDADE": "Esperança do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.64,
    "FEB": 5.63,
    "MAR": 5.47,
    "APR": 4.85,
    "MAY": 4.09,
    "JUN": 3.51,
    "JUL": 3.89,
    "AUG": 4.61,
    "SEP": 4.42,
    "OCT": 5.14,
    "NOV": 5.59,
    "DEC": 5.68
  },
  {
    "LONG": -53.76,
    "LAT": -27.37,
    "CIDADE": "Tenente Portela",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.65,
    "FEB": 5.63,
    "MAR": 5.47,
    "APR": 4.85,
    "MAY": 4.08,
    "JUN": 3.51,
    "JUL": 3.92,
    "AUG": 4.63,
    "SEP": 4.41,
    "OCT": 5.1,
    "NOV": 5.57,
    "DEC": 5.66
  },
  {
    "LONG": -53.56,
    "LAT": "#VALOR!",
    "CIDADE": "Palmitinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.7,
    "FEB": 5.59,
    "MAR": 5.52,
    "APR": 4.9,
    "MAY": 4.08,
    "JUN": 3.52,
    "JUL": 3.92,
    "AUG": 4.65,
    "SEP": 4.39,
    "OCT": 5.12,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -53.49,
    "LAT": -27.37,
    "CIDADE": "Vista Alegre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.67,
    "FEB": 5.58,
    "MAR": 5.49,
    "APR": 4.89,
    "MAY": 4.09,
    "JUN": 3.54,
    "JUL": 3.93,
    "AUG": 4.66,
    "SEP": 4.39,
    "OCT": 5.11,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -53.47,
    "LAT": -27.4,
    "CIDADE": "Taquaruçu do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.67,
    "FEB": 5.58,
    "MAR": 5.49,
    "APR": 4.89,
    "MAY": 4.09,
    "JUN": 3.54,
    "JUL": 3.93,
    "AUG": 4.66,
    "SEP": 4.39,
    "OCT": 5.11,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -53.4,
    "LAT": -27.36,
    "CIDADE": "Frederico Westphalen",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.61,
    "FEB": 5.6,
    "MAR": 5.46,
    "APR": 4.88,
    "MAY": 4.07,
    "JUN": 3.48,
    "JUL": 3.9,
    "AUG": 4.67,
    "SEP": 4.39,
    "OCT": 5.1,
    "NOV": 5.62,
    "DEC": 5.67
  },
  {
    "LONG": -53.18,
    "LAT": -27.36,
    "CIDADE": "Ametista do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.59,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 4.91,
    "MAY": 4.08,
    "JUN": 3.51,
    "JUL": 3.9,
    "AUG": 4.67,
    "SEP": 4.38,
    "OCT": 5.09,
    "NOV": 5.6,
    "DEC": 5.69
  },
  {
    "LONG": -52.92,
    "LAT": -27.44,
    "CIDADE": "Gramado dos Loureiros",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.51,
    "FEB": 5.56,
    "MAR": 5.46,
    "APR": 4.97,
    "MAY": 4.09,
    "JUN": 3.61,
    "JUL": 3.95,
    "AUG": 4.68,
    "SEP": 4.41,
    "OCT": 5.07,
    "NOV": 5.57,
    "DEC": 5.65
  },
  {
    "LONG": -5.28,
    "LAT": -27.37,
    "CIDADE": "Nonoai",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.55,
    "FEB": 5.56,
    "MAR": 5.45,
    "APR": 4.91,
    "MAY": 4.09,
    "JUN": 3.62,
    "JUL": 3.93,
    "AUG": 4.71,
    "SEP": 4.42,
    "OCT": 5.05,
    "NOV": 5.53,
    "DEC": 5.62
  },
  {
    "LONG": -52.68,
    "LAT": -27.42,
    "CIDADE": "Faxinalzinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.53,
    "FEB": 5.53,
    "MAR": 5.43,
    "APR": 4.91,
    "MAY": 4.08,
    "JUN": 3.62,
    "JUL": 3.94,
    "AUG": 4.72,
    "SEP": 4.42,
    "OCT": 5.07,
    "NOV": 5.55,
    "DEC": 5.63
  },
  {
    "LONG": -52.57,
    "LAT": -27.39,
    "CIDADE": "Erval Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.48,
    "FEB": 5.5,
    "MAR": 5.45,
    "APR": 4.9,
    "MAY": 4.05,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.7,
    "SEP": 4.41,
    "OCT": 5.05,
    "NOV": 5.57,
    "DEC": 5.64
  },
  {
    "LONG": -52.41,
    "LAT": -27.41,
    "CIDADE": "Barra do Rio Azul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.5,
    "FEB": 5.53,
    "MAR": 5.45,
    "APR": 4.91,
    "MAY": 4,
    "JUN": 3.44,
    "JUL": 3.89,
    "AUG": 4.68,
    "SEP": 4.41,
    "OCT": 5.04,
    "NOV": 5.63,
    "DEC": 5.65
  },
  {
    "LONG": -52.45,
    "LAT": -27.39,
    "CIDADE": "Itatiba do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.5,
    "FEB": 5.53,
    "MAR": 5.45,
    "APR": 4.91,
    "MAY": 4,
    "JUN": 3.44,
    "JUL": 3.89,
    "AUG": 4.68,
    "SEP": 4.41,
    "OCT": 5.04,
    "NOV": 5.63,
    "DEC": 5.65
  },
  {
    "LONG": -52.3,
    "LAT": -27.4,
    "CIDADE": "Aratiba",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.59,
    "FEB": 5.61,
    "MAR": 5.49,
    "APR": 4.85,
    "MAY": 3.99,
    "JUN": 3.46,
    "JUL": 3.86,
    "AUG": 4.65,
    "SEP": 4.39,
    "OCT": 5.03,
    "NOV": 5.65,
    "DEC": 5.69
  },
  {
    "LONG": -52.15,
    "LAT": -27.36,
    "CIDADE": "Mariano Moro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.64,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 4.86,
    "MAY": 4,
    "JUN": 3.48,
    "JUL": 3.87,
    "AUG": 4.68,
    "SEP": 4.4,
    "OCT": 5.03,
    "NOV": 5.67,
    "DEC": 5.71
  },
  {
    "LONG": -52.12,
    "LAT": -27.44,
    "CIDADE": "Severiano de Almeida",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.64,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 4.86,
    "MAY": 4,
    "JUN": 3.48,
    "JUL": 3.87,
    "AUG": 4.68,
    "SEP": 4.4,
    "OCT": 5.03,
    "NOV": 5.67,
    "DEC": 5.71
  },
  {
    "LONG": -5.39,
    "LAT": -27.26,
    "CIDADE": "Derrubadas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.62,
    "FEB": 5.62,
    "MAR": 5.48,
    "APR": 4.81,
    "MAY": 4.06,
    "JUN": 3.45,
    "JUL": 3.85,
    "AUG": 4.64,
    "SEP": 4.39,
    "OCT": 5.09,
    "NOV": 5.53,
    "DEC": 5.65
  },
  {
    "LONG": -53.7,
    "LAT": -27.29,
    "CIDADE": "Vista Gaúcha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.7,
    "FEB": 5.6,
    "MAR": 5.52,
    "APR": 4.84,
    "MAY": 4.04,
    "JUN": 3.48,
    "JUL": 3.89,
    "AUG": 4.63,
    "SEP": 4.4,
    "OCT": 5.12,
    "NOV": 5.58,
    "DEC": 5.68
  },
  {
    "LONG": -53.43,
    "LAT": -27.28,
    "CIDADE": "Caiçara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.69,
    "FEB": 5.6,
    "MAR": 5.52,
    "APR": 4.86,
    "MAY": 4.08,
    "JUN": 3.52,
    "JUL": 3.92,
    "AUG": 4.66,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.59,
    "DEC": 5.66
  },
  {
    "LONG": -53.03,
    "LAT": -27.25,
    "CIDADE": "Alpestre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.55,
    "FEB": 5.58,
    "MAR": 5.52,
    "APR": 4.89,
    "MAY": 4.07,
    "JUN": 3.57,
    "JUL": 3.9,
    "AUG": 4.68,
    "SEP": 4.42,
    "OCT": 5.09,
    "NOV": 5.59,
    "DEC": 5.69
  },
  {
    "LONG": -5.31,
    "LAT": -27.33,
    "CIDADE": "Planalto",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.55,
    "FEB": 5.58,
    "MAR": 5.52,
    "APR": 4.89,
    "MAY": 4.07,
    "JUN": 3.57,
    "JUL": 3.9,
    "AUG": 4.68,
    "SEP": 4.42,
    "OCT": 5.09,
    "NOV": 5.59,
    "DEC": 5.69
  },
  {
    "LONG": -52.84,
    "LAT": -27.3,
    "CIDADE": "Rio dos Índios",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.55,
    "FEB": 5.61,
    "MAR": 5.52,
    "APR": 4.95,
    "MAY": 4.11,
    "JUN": 3.61,
    "JUL": 3.95,
    "AUG": 4.72,
    "SEP": 4.43,
    "OCT": 5.09,
    "NOV": 5.57,
    "DEC": 5.66
  },
  {
    "LONG": -53.71,
    "LAT": -27.19,
    "CIDADE": "Barra do Guarita",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.65,
    "FEB": 5.6,
    "MAR": 5.5,
    "APR": 4.8,
    "MAY": 4.02,
    "JUN": 3.4,
    "JUL": 3.82,
    "AUG": 4.61,
    "SEP": 4.39,
    "OCT": 5.09,
    "NOV": 5.56,
    "DEC": 5.64
  },
  {
    "LONG": -53.61,
    "LAT": -27.21,
    "CIDADE": "Pinheirinho do Vale",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.69,
    "FEB": 5.6,
    "MAR": 5.5,
    "APR": 4.82,
    "MAY": 4.03,
    "JUN": 3.42,
    "JUL": 3.83,
    "AUG": 4.64,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -53.4,
    "LAT": -27.16,
    "CIDADE": "Vicente Dutra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.67,
    "FEB": 5.6,
    "MAR": 5.5,
    "APR": 4.83,
    "MAY": 4.03,
    "JUN": 3.44,
    "JUL": 3.83,
    "AUG": 4.64,
    "SEP": 4.41,
    "OCT": 5.09,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -53.25,
    "LAT": -27.2,
    "CIDADE": "Iraí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.61,
    "FEB": 5.62,
    "MAR": 5.53,
    "APR": 4.85,
    "MAY": 4.03,
    "JUN": 3.49,
    "JUL": 3.85,
    "AUG": 4.64,
    "SEP": 4.4,
    "OCT": 5.09,
    "NOV": 5.59,
    "DEC": 5.69
  },
  {
    "LONG": -61.05,
    "LAT": -13.48,
    "CIDADE": "Pimenteiras do Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.93,
    "JAN": 4.51,
    "FEB": 4.53,
    "MAR": 4.74,
    "APR": 5.04,
    "MAY": 4.74,
    "JUN": 5,
    "JUL": 5.21,
    "AUG": 5.55,
    "SEP": 5.19,
    "OCT": 5.12,
    "NOV": 4.91,
    "DEC": 4.69
  },
  {
    "LONG": -60.54,
    "LAT": -13.5,
    "CIDADE": "Cabixi",
    "UF": "RONDÔNIA",
    "anual": 4.89,
    "JAN": 4.51,
    "FEB": 4.47,
    "MAR": 4.73,
    "APR": 5.08,
    "MAY": 4.72,
    "JUN": 4.95,
    "JUL": 5.11,
    "AUG": 5.42,
    "SEP": 5.07,
    "OCT": 5.1,
    "NOV": 4.87,
    "DEC": 4.69
  },
  {
    "LONG": -60.82,
    "LAT": -13.19,
    "CIDADE": "Cerejeiras",
    "UF": "RONDÔNIA",
    "anual": 4.86,
    "JAN": 4.43,
    "FEB": 4.5,
    "MAR": 4.74,
    "APR": 4.92,
    "MAY": 4.7,
    "JUN": 4.96,
    "JUL": 5.06,
    "AUG": 5.38,
    "SEP": 5.07,
    "OCT": 5.1,
    "NOV": 4.84,
    "DEC": 4.64
  },
  {
    "LONG": -60.55,
    "LAT": -13.12,
    "CIDADE": "Colorado do Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.78,
    "JAN": 4.33,
    "FEB": 4.31,
    "MAR": 4.56,
    "APR": 4.83,
    "MAY": 4.62,
    "JUN": 4.95,
    "JUL": 5.11,
    "AUG": 5.43,
    "SEP": 5.03,
    "OCT": 4.95,
    "NOV": 4.72,
    "DEC": 4.53
  },
  {
    "LONG": -60.94,
    "LAT": -13,
    "CIDADE": "Corumbiara",
    "UF": "RONDÔNIA",
    "anual": 4.82,
    "JAN": 4.36,
    "FEB": 4.43,
    "MAR": 4.6,
    "APR": 4.86,
    "MAY": 4.65,
    "JUN": 4.97,
    "JUL": 5.08,
    "AUG": 5.44,
    "SEP": 5.05,
    "OCT": 5.05,
    "NOV": 4.8,
    "DEC": 4.56
  },
  {
    "LONG": -60.15,
    "LAT": -12.75,
    "CIDADE": "Vilhena",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.29,
    "FEB": 4.3,
    "MAR": 4.46,
    "APR": 4.78,
    "MAY": 4.68,
    "JUN": 4.94,
    "JUL": 5.09,
    "AUG": 5.45,
    "SEP": 4.9,
    "OCT": 4.82,
    "NOV": 4.58,
    "DEC": 4.46
  },
  {
    "LONG": -60.9,
    "LAT": -12.55,
    "CIDADE": "Chupinguaia",
    "UF": "RONDÔNIA",
    "anual": 4.81,
    "JAN": 4.33,
    "FEB": 4.42,
    "MAR": 4.6,
    "APR": 4.85,
    "MAY": 4.69,
    "JUN": 4.95,
    "JUL": 5.04,
    "AUG": 5.34,
    "SEP": 5.07,
    "OCT": 5.09,
    "NOV": 4.81,
    "DEC": 4.57
  },
  {
    "LONG": -64.23,
    "LAT": -12.44,
    "CIDADE": "Costa Marques",
    "UF": "RONDÔNIA",
    "anual": 4.71,
    "JAN": 4.36,
    "FEB": 4.38,
    "MAR": 4.51,
    "APR": 4.81,
    "MAY": 4.3,
    "JUN": 4.69,
    "JUL": 4.89,
    "AUG": 5.26,
    "SEP": 5.04,
    "OCT": 4.99,
    "NOV": 4.78,
    "DEC": 4.55
  },
  {
    "LONG": -61.6,
    "LAT": -12.18,
    "CIDADE": "Parecis",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.15,
    "FEB": 4.26,
    "MAR": 4.52,
    "APR": 4.82,
    "MAY": 4.56,
    "JUN": 4.87,
    "JUL": 5,
    "AUG": 5.36,
    "SEP": 5.05,
    "OCT": 5,
    "NOV": 4.71,
    "DEC": 4.46
  },
  {
    "LONG": -63.57,
    "LAT": -12.05,
    "CIDADE": "São Francisco do Guaporé",
    "UF": "RONDÔNIA",
    "anual": 4.66,
    "JAN": 4.25,
    "FEB": 4.27,
    "MAR": 4.43,
    "APR": 4.71,
    "MAY": 4.26,
    "JUN": 4.7,
    "JUL": 4.9,
    "AUG": 5.22,
    "SEP": 5.01,
    "OCT": 4.99,
    "NOV": 4.69,
    "DEC": 4.45
  },
  {
    "LONG": -61.84,
    "LAT": -12.14,
    "CIDADE": "Alto Alegre dos Parecis",
    "UF": "RONDÔNIA",
    "anual": 4.71,
    "JAN": 4.18,
    "FEB": 4.25,
    "MAR": 4.45,
    "APR": 4.73,
    "MAY": 4.48,
    "JUN": 4.84,
    "JUL": 4.96,
    "AUG": 5.37,
    "SEP": 5.12,
    "OCT": 5,
    "NOV": 4.68,
    "DEC": 4.42
  },
  {
    "LONG": -62,
    "LAT": -11.93,
    "CIDADE": "Alta Floresta D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.71,
    "JAN": 4.14,
    "FEB": 4.24,
    "MAR": 4.52,
    "APR": 4.78,
    "MAY": 4.49,
    "JUN": 4.84,
    "JUL": 4.95,
    "AUG": 5.34,
    "SEP": 5.08,
    "OCT": 5.03,
    "NOV": 4.72,
    "DEC": 4.42
  },
  {
    "LONG": -61.78,
    "LAT": -11.91,
    "CIDADE": "Santa Luzia D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.75,
    "JAN": 4.23,
    "FEB": 4.27,
    "MAR": 4.58,
    "APR": 4.8,
    "MAY": 4.57,
    "JUN": 4.9,
    "JUL": 5,
    "AUG": 5.32,
    "SEP": 5.09,
    "OCT": 5.01,
    "NOV": 4.71,
    "DEC": 4.46
  },
  {
    "LONG": -61.5,
    "LAT": -11.9,
    "CIDADE": "São Felipe D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.76,
    "JAN": 4.25,
    "FEB": 4.31,
    "MAR": 4.6,
    "APR": 4.84,
    "MAY": 4.58,
    "JUN": 4.9,
    "JUL": 5,
    "AUG": 5.31,
    "SEP": 5.06,
    "OCT": 5.05,
    "NOV": 4.72,
    "DEC": 4.49
  },
  {
    "LONG": -63.03,
    "LAT": -11.77,
    "CIDADE": "Seringueiras",
    "UF": "RONDÔNIA",
    "anual": 4.65,
    "JAN": 4.2,
    "FEB": 4.28,
    "MAR": 4.43,
    "APR": 4.66,
    "MAY": 4.3,
    "JUN": 4.75,
    "JUL": 4.93,
    "AUG": 5.27,
    "SEP": 5.05,
    "OCT": 4.91,
    "NOV": 4.67,
    "DEC": 4.35
  },
  {
    "LONG": -61.32,
    "LAT": -11.83,
    "CIDADE": "Primavera de Rondônia",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.18,
    "FEB": 4.28,
    "MAR": 4.55,
    "APR": 4.76,
    "MAY": 4.61,
    "JUN": 4.88,
    "JUL": 5.05,
    "AUG": 5.29,
    "SEP": 5,
    "OCT": 4.95,
    "NOV": 4.69,
    "DEC": 4.45
  },
  {
    "LONG": -62.72,
    "LAT": -11.7,
    "CIDADE": "São Miguel do Guaporé",
    "UF": "RONDÔNIA",
    "anual": 4.68,
    "JAN": 4.18,
    "FEB": 4.31,
    "MAR": 4.47,
    "APR": 4.7,
    "MAY": 4.32,
    "JUN": 4.78,
    "JUL": 4.97,
    "AUG": 5.27,
    "SEP": 5.12,
    "OCT": 4.98,
    "NOV": 4.7,
    "DEC": 4.4
  },
  {
    "LONG": -62.31,
    "LAT": -11.73,
    "CIDADE": "Nova Brasilândia D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.67,
    "JAN": 4.18,
    "FEB": 4.26,
    "MAR": 4.45,
    "APR": 4.69,
    "MAY": 4.34,
    "JUN": 4.86,
    "JUL": 4.93,
    "AUG": 5.3,
    "SEP": 5.03,
    "OCT": 4.98,
    "NOV": 4.63,
    "DEC": 4.4
  },
  {
    "LONG": -62,
    "LAT": -11.71,
    "CIDADE": "Novo Horizonte do Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.23,
    "FEB": 4.27,
    "MAR": 4.57,
    "APR": 4.79,
    "MAY": 4.49,
    "JUN": 4.9,
    "JUL": 4.94,
    "AUG": 5.32,
    "SEP": 5.07,
    "OCT": 5.01,
    "NOV": 4.73,
    "DEC": 4.4
  },
  {
    "LONG": -61.77,
    "LAT": -11.73,
    "CIDADE": "Rolim de Moura",
    "UF": "RONDÔNIA",
    "anual": 4.71,
    "JAN": 4.18,
    "FEB": 4.27,
    "MAR": 4.59,
    "APR": 4.76,
    "MAY": 4.49,
    "JUN": 4.87,
    "JUL": 4.99,
    "AUG": 5.22,
    "SEP": 5.04,
    "OCT": 5,
    "NOV": 4.66,
    "DEC": 4.43
  },
  {
    "LONG": -61.2,
    "LAT": -11.67,
    "CIDADE": "Pimenta Bueno",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.16,
    "FEB": 4.27,
    "MAR": 4.57,
    "APR": 4.74,
    "MAY": 4.58,
    "JUN": 4.92,
    "JUL": 5.05,
    "AUG": 5.35,
    "SEP": 4.98,
    "OCT": 4.96,
    "NOV": 4.7,
    "DEC": 4.48
  },
  {
    "LONG": -61.03,
    "LAT": -1.15,
    "CIDADE": "Espigão D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.22,
    "FEB": 4.31,
    "MAR": 4.52,
    "APR": 4.71,
    "MAY": 4.54,
    "JUN": 4.94,
    "JUL": 5.06,
    "AUG": 5.3,
    "SEP": 5.02,
    "OCT": 5.02,
    "NOV": 4.74,
    "DEC": 4.43
  },
  {
    "LONG": -61.95,
    "LAT": -11.43,
    "CIDADE": "Castanheiras",
    "UF": "RONDÔNIA",
    "anual": 4.7,
    "JAN": 4.17,
    "FEB": 4.24,
    "MAR": 4.53,
    "APR": 4.8,
    "MAY": 4.51,
    "JUN": 4.84,
    "JUL": 4.95,
    "AUG": 5.21,
    "SEP": 4.97,
    "OCT": 5.02,
    "NOV": 4.76,
    "DEC": 4.42
  },
  {
    "LONG": -61.46,
    "LAT": -11.43,
    "CIDADE": "Cacoal",
    "UF": "RONDÔNIA",
    "anual": 4.7,
    "JAN": 4.08,
    "FEB": 4.23,
    "MAR": 4.5,
    "APR": 4.74,
    "MAY": 4.51,
    "JUN": 4.89,
    "JUL": 5.02,
    "AUG": 5.3,
    "SEP": 5.01,
    "OCT": 4.98,
    "NOV": 4.69,
    "DEC": 4.41
  },
  {
    "LONG": -62.29,
    "LAT": -11.35,
    "CIDADE": "Alvorada D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.69,
    "JAN": 4.2,
    "FEB": 4.33,
    "MAR": 4.5,
    "APR": 4.77,
    "MAY": 4.42,
    "JUN": 4.83,
    "JUL": 4.95,
    "AUG": 5.26,
    "SEP": 4.99,
    "OCT": 4.95,
    "NOV": 4.72,
    "DEC": 4.41
  },
  {
    "LONG": -61.9,
    "LAT": -11.17,
    "CIDADE": "Presidente Médici",
    "UF": "RONDÔNIA",
    "anual": 4.71,
    "JAN": 4.22,
    "FEB": 4.28,
    "MAR": 4.51,
    "APR": 4.74,
    "MAY": 4.48,
    "JUN": 4.89,
    "JUL": 4.93,
    "AUG": 5.22,
    "SEP": 5.01,
    "OCT": 5.04,
    "NOV": 4.69,
    "DEC": 4.43
  },
  {
    "LONG": -61.52,
    "LAT": -11.2,
    "CIDADE": "Ministro Andreazza",
    "UF": "RONDÔNIA",
    "anual": 4.66,
    "JAN": 4.09,
    "FEB": 4.24,
    "MAR": 4.44,
    "APR": 4.65,
    "MAY": 4.42,
    "JUN": 4.86,
    "JUL": 5.02,
    "AUG": 5.25,
    "SEP": 4.96,
    "OCT": 4.89,
    "NOV": 4.67,
    "DEC": 4.44
  },
  {
    "LONG": -62.36,
    "LAT": -11.13,
    "CIDADE": "Urupá",
    "UF": "RONDÔNIA",
    "anual": 4.68,
    "JAN": 4.16,
    "FEB": 4.25,
    "MAR": 4.43,
    "APR": 4.74,
    "MAY": 4.41,
    "JUN": 4.84,
    "JUL": 4.96,
    "AUG": 5.21,
    "SEP": 4.99,
    "OCT": 5.06,
    "NOV": 4.68,
    "DEC": 4.39
  },
  {
    "LONG": -62.67,
    "LAT": -11.03,
    "CIDADE": "Mirante da Serra",
    "UF": "RONDÔNIA",
    "anual": 4.64,
    "JAN": 4.15,
    "FEB": 4.19,
    "MAR": 4.4,
    "APR": 4.66,
    "MAY": 4.33,
    "JUN": 4.79,
    "JUL": 4.91,
    "AUG": 5.22,
    "SEP": 5,
    "OCT": 4.99,
    "NOV": 4.68,
    "DEC": 4.35
  },
  {
    "LONG": -62.56,
    "LAT": -10.91,
    "CIDADE": "Nova União",
    "UF": "RONDÔNIA",
    "anual": 4.64,
    "JAN": 4.12,
    "FEB": 4.22,
    "MAR": 4.47,
    "APR": 4.67,
    "MAY": 4.34,
    "JUN": 4.79,
    "JUL": 4.89,
    "AUG": 5.2,
    "SEP": 5.02,
    "OCT": 5,
    "NOV": 4.69,
    "DEC": 4.33
  },
  {
    "LONG": -62.26,
    "LAT": -10.93,
    "CIDADE": "Teixeirópolis",
    "UF": "RONDÔNIA",
    "anual": 4.69,
    "JAN": 4.26,
    "FEB": 4.25,
    "MAR": 4.45,
    "APR": 4.7,
    "MAY": 4.4,
    "JUN": 4.86,
    "JUL": 4.97,
    "AUG": 5.27,
    "SEP": 4.95,
    "OCT": 5.05,
    "NOV": 4.72,
    "DEC": 4.39
  },
  {
    "LONG": -61.93,
    "LAT": -10.88,
    "CIDADE": "Ji-Paraná",
    "UF": "RONDÔNIA",
    "anual": 4.67,
    "JAN": 4.15,
    "FEB": 4.29,
    "MAR": 4.47,
    "APR": 4.72,
    "MAY": 4.42,
    "JUN": 4.83,
    "JUL": 4.87,
    "AUG": 5.18,
    "SEP": 4.96,
    "OCT": 5.02,
    "NOV": 4.7,
    "DEC": 4.42
  },
  {
    "LONG": "#VALOR!",
    "LAT": -10.79,
    "CIDADE": "Guajará-Mirim",
    "UF": "RONDÔNIA",
    "anual": 4.64,
    "JAN": 4.25,
    "FEB": 4.33,
    "MAR": 4.37,
    "APR": 4.64,
    "MAY": 4.29,
    "JUN": 4.58,
    "JUL": 4.81,
    "AUG": 5.23,
    "SEP": 5.05,
    "OCT": 5.01,
    "NOV": 4.65,
    "DEC": 4.5
  },
  {
    "LONG": -6.23,
    "LAT": -10.72,
    "CIDADE": "Ouro Preto do Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.64,
    "JAN": 4.16,
    "FEB": 4.14,
    "MAR": 4.42,
    "APR": 4.7,
    "MAY": 4.41,
    "JUN": 4.84,
    "JUL": 4.89,
    "AUG": 5.19,
    "SEP": 4.92,
    "OCT": 4.97,
    "NOV": 4.65,
    "DEC": 4.33
  },
  {
    "LONG": -63.62,
    "LAT": -10.57,
    "CIDADE": "Campo Novo de Rondônia",
    "UF": "RONDÔNIA",
    "anual": 4.59,
    "JAN": 4,
    "FEB": 4.22,
    "MAR": 4.34,
    "APR": 4.66,
    "MAY": 4.42,
    "JUN": 4.82,
    "JUL": 4.8,
    "AUG": 5.16,
    "SEP": 4.97,
    "OCT": 4.89,
    "NOV": 4.51,
    "DEC": 4.25
  },
  {
    "LONG": -62.74,
    "LAT": -10.61,
    "CIDADE": "Governador Jorge Teixeira",
    "UF": "RONDÔNIA",
    "anual": 4.63,
    "JAN": 4.12,
    "FEB": 4.17,
    "MAR": 4.41,
    "APR": 4.72,
    "MAY": 4.33,
    "JUN": 4.8,
    "JUL": 4.89,
    "AUG": 5.19,
    "SEP": 5.01,
    "OCT": 4.96,
    "NOV": 4.59,
    "DEC": 4.37
  },
  {
    "LONG": -65.34,
    "LAT": -10.41,
    "CIDADE": "Nova Mamoré",
    "UF": "RONDÔNIA",
    "anual": 4.62,
    "JAN": 4.24,
    "FEB": 4.38,
    "MAR": 4.37,
    "APR": 4.61,
    "MAY": 4.25,
    "JUN": 4.6,
    "JUL": 4.81,
    "AUG": 5.16,
    "SEP": 5.08,
    "OCT": 4.99,
    "NOV": 4.55,
    "DEC": 4.43
  },
  {
    "LONG": -62.48,
    "LAT": -10.43,
    "CIDADE": "Jaru",
    "UF": "RONDÔNIA",
    "anual": 4.62,
    "JAN": 4.1,
    "FEB": 4.23,
    "MAR": 4.39,
    "APR": 4.69,
    "MAY": 4.35,
    "JUN": 4.88,
    "JUL": 4.88,
    "AUG": 5.12,
    "SEP": 4.98,
    "OCT": 4.94,
    "NOV": 4.6,
    "DEC": 4.3
  },
  {
    "LONG": -62.13,
    "LAT": -10.43,
    "CIDADE": "Vale do Paraíso",
    "UF": "RONDÔNIA",
    "anual": 4.64,
    "JAN": 4.12,
    "FEB": 4.2,
    "MAR": 4.39,
    "APR": 4.71,
    "MAY": 4.38,
    "JUN": 4.88,
    "JUL": 4.94,
    "AUG": 5.17,
    "SEP": 4.94,
    "OCT": 4.94,
    "NOV": 4.61,
    "DEC": 4.36
  },
  {
    "LONG": -63.3,
    "LAT": -10.26,
    "CIDADE": "Monte Negro",
    "UF": "RONDÔNIA",
    "anual": 4.59,
    "JAN": 3.98,
    "FEB": 4.28,
    "MAR": 4.4,
    "APR": 4.65,
    "MAY": 4.31,
    "JUN": 4.82,
    "JUL": 4.84,
    "AUG": 5.2,
    "SEP": 4.99,
    "OCT": 4.89,
    "NOV": 4.51,
    "DEC": 4.22
  },
  {
    "LONG": -62.9,
    "LAT": -10.34,
    "CIDADE": "Cacaulândia",
    "UF": "RONDÔNIA",
    "anual": 4.6,
    "JAN": 4.03,
    "FEB": 4.14,
    "MAR": 4.4,
    "APR": 4.69,
    "MAY": 4.35,
    "JUN": 4.8,
    "JUL": 4.86,
    "AUG": 5.18,
    "SEP": 4.96,
    "OCT": 4.92,
    "NOV": 4.55,
    "DEC": 4.26
  },
  {
    "LONG": -63.83,
    "LAT": -1.02,
    "CIDADE": "Buritis",
    "UF": "RONDÔNIA",
    "anual": 4.56,
    "JAN": 4.06,
    "FEB": 4.26,
    "MAR": 4.34,
    "APR": 4.56,
    "MAY": 4.28,
    "JUN": 4.73,
    "JUL": 4.78,
    "AUG": 5.12,
    "SEP": 4.98,
    "OCT": 4.85,
    "NOV": 4.5,
    "DEC": 4.22
  },
  {
    "LONG": -62.35,
    "LAT": -10.25,
    "CIDADE": "Theobroma",
    "UF": "RONDÔNIA",
    "anual": 4.6,
    "JAN": 4.14,
    "FEB": 4.16,
    "MAR": 4.31,
    "APR": 4.67,
    "MAY": 4.35,
    "JUN": 4.87,
    "JUL": 4.91,
    "AUG": 5.12,
    "SEP": 4.91,
    "OCT": 4.87,
    "NOV": 4.54,
    "DEC": 4.32
  },
  {
    "LONG": -6.3,
    "LAT": -9.91,
    "CIDADE": "Ariquemes",
    "UF": "RONDÔNIA",
    "anual": 4.54,
    "JAN": 4.07,
    "FEB": 4.19,
    "MAR": 4.3,
    "APR": 4.6,
    "MAY": 4.27,
    "JUN": 4.75,
    "JUL": 4.78,
    "AUG": 5,
    "SEP": 4.92,
    "OCT": 4.85,
    "NOV": 4.49,
    "DEC": 4.33
  },
  {
    "LONG": -62.17,
    "LAT": -9.86,
    "CIDADE": "Vale do Anari",
    "UF": "RONDÔNIA",
    "anual": 4.59,
    "JAN": 4.1,
    "FEB": 4.24,
    "MAR": 4.35,
    "APR": 4.64,
    "MAY": 4.36,
    "JUN": 4.83,
    "JUL": 4.94,
    "AUG": 5.12,
    "SEP": 4.87,
    "OCT": 4.85,
    "NOV": 4.48,
    "DEC": 4.3
  },
  {
    "LONG": -63.32,
    "LAT": -9.71,
    "CIDADE": "Alto Paraíso",
    "UF": "RONDÔNIA",
    "anual": 4.56,
    "JAN": 4.07,
    "FEB": 4.25,
    "MAR": 4.33,
    "APR": 4.55,
    "MAY": 4.3,
    "JUN": 4.73,
    "JUL": 4.8,
    "AUG": 5.12,
    "SEP": 4.92,
    "OCT": 4.91,
    "NOV": 4.53,
    "DEC": 4.24
  },
  {
    "LONG": -62.9,
    "LAT": -9.7,
    "CIDADE": "Rio Crespo",
    "UF": "RONDÔNIA",
    "anual": 4.56,
    "JAN": 3.99,
    "FEB": 4.23,
    "MAR": 4.29,
    "APR": 4.54,
    "MAY": 4.34,
    "JUN": 4.77,
    "JUL": 4.84,
    "AUG": 5.1,
    "SEP": 4.91,
    "OCT": 4.87,
    "NOV": 4.57,
    "DEC": 4.31
  },
  {
    "LONG": -6.26,
    "LAT": -9.36,
    "CIDADE": "Cujubim",
    "UF": "RONDÔNIA",
    "anual": 4.54,
    "JAN": 3.95,
    "FEB": 4.17,
    "MAR": 4.35,
    "APR": 4.45,
    "MAY": 4.28,
    "JUN": 4.82,
    "JUL": 4.87,
    "AUG": 5.13,
    "SEP": 4.86,
    "OCT": 4.85,
    "NOV": 4.5,
    "DEC": 4.28
  },
  {
    "LONG": -62,
    "LAT": -9.43,
    "CIDADE": "Machadinho D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.57,
    "JAN": 3.98,
    "FEB": 4.19,
    "MAR": 4.34,
    "APR": 4.51,
    "MAY": 4.33,
    "JUN": 4.89,
    "JUL": 4.97,
    "AUG": 5.11,
    "SEP": 4.9,
    "OCT": 4.87,
    "NOV": 4.46,
    "DEC": 4.25
  },
  {
    "LONG": -63.18,
    "LAT": -9.2,
    "CIDADE": "Itapuã do Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.57,
    "JAN": 3.98,
    "FEB": 4.27,
    "MAR": 4.3,
    "APR": 4.49,
    "MAY": 4.26,
    "JUN": 4.8,
    "JUL": 4.92,
    "AUG": 5.1,
    "SEP": 5.02,
    "OCT": 4.92,
    "NOV": 4.56,
    "DEC": 4.23
  },
  {
    "LONG": -63.9,
    "LAT": -8.76,
    "CIDADE": "Porto Velho",
    "UF": "RONDÔNIA",
    "anual": 4.52,
    "JAN": 4,
    "FEB": 4.32,
    "MAR": 4.32,
    "APR": 4.5,
    "MAY": 4.22,
    "JUN": 4.63,
    "JUL": 4.81,
    "AUG": 5.08,
    "SEP": 4.91,
    "OCT": 4.84,
    "NOV": 4.44,
    "DEC": 4.17
  },
  {
    "LONG": -6.37,
    "LAT": -8.79,
    "CIDADE": "Candeias do Jamari",
    "UF": "RONDÔNIA",
    "anual": 4.51,
    "JAN": 4.03,
    "FEB": 4.27,
    "MAR": 4.35,
    "APR": 4.47,
    "MAY": 4.26,
    "JUN": 4.73,
    "JUL": 4.83,
    "AUG": 4.97,
    "SEP": 4.82,
    "OCT": 4.76,
    "NOV": 4.45,
    "DEC": 4.15
  },
  {
    "LONG": -60.44,
    "LAT": "#VALOR!",
    "CIDADE": "Rorainópolis",
    "UF": "RORAIMA",
    "anual": 4.71,
    "JAN": 4.54,
    "FEB": 4.62,
    "MAR": 4.66,
    "APR": 4.42,
    "MAY": 4.09,
    "JUN": 4.35,
    "JUL": 4.43,
    "AUG": 4.99,
    "SEP": 5.35,
    "OCT": 5.35,
    "NOV": 5.03,
    "DEC": 4.74
  },
  {
    "LONG": -59.7,
    "LAT": "#VALOR!",
    "CIDADE": "Caroebe",
    "UF": "RORAIMA",
    "anual": 4.68,
    "JAN": 4.44,
    "FEB": 4.5,
    "MAR": 4.49,
    "APR": 4.32,
    "MAY": 4.12,
    "JUN": 4.42,
    "JUL": 4.48,
    "AUG": 5.08,
    "SEP": 5.29,
    "OCT": 5.27,
    "NOV": 5.08,
    "DEC": 4.65
  },
  {
    "LONG": -60.04,
    "LAT": 1.02,
    "CIDADE": "São Luiz",
    "UF": "RORAIMA",
    "anual": 4.72,
    "JAN": 4.49,
    "FEB": 4.54,
    "MAR": 4.62,
    "APR": 4.4,
    "MAY": 4.13,
    "JUN": 4.39,
    "JUL": 4.44,
    "AUG": 5.03,
    "SEP": 5.35,
    "OCT": 5.36,
    "NOV": 5.13,
    "DEC": 4.71
  },
  {
    "LONG": -59.91,
    "LAT": "#VALOR!",
    "CIDADE": "São João da Baliza",
    "UF": "RORAIMA",
    "anual": 4.7,
    "JAN": 4.49,
    "FEB": 4.55,
    "MAR": 4.6,
    "APR": 4.37,
    "MAY": 4.1,
    "JUN": 4.42,
    "JUL": 4.41,
    "AUG": 4.98,
    "SEP": 5.31,
    "OCT": 5.33,
    "NOV": 5.13,
    "DEC": 4.73
  },
  {
    "LONG": -61.13,
    "LAT": 1.82,
    "CIDADE": "Caracaraí",
    "UF": "RORAIMA",
    "anual": 4.83,
    "JAN": 4.69,
    "FEB": 4.8,
    "MAR": 4.93,
    "APR": 4.57,
    "MAY": 4.15,
    "JUN": 4.44,
    "JUL": 4.44,
    "AUG": 4.96,
    "SEP": 5.46,
    "OCT": 5.43,
    "NOV": 5.2,
    "DEC": 4.88
  },
  {
    "LONG": -61.05,
    "LAT": 2.17,
    "CIDADE": "Iracema",
    "UF": "RORAIMA",
    "anual": 4.87,
    "JAN": 4.73,
    "FEB": 4.8,
    "MAR": 5.08,
    "APR": 4.65,
    "MAY": 4.28,
    "JUN": 4.44,
    "JUL": 4.49,
    "AUG": 4.94,
    "SEP": 5.48,
    "OCT": 5.45,
    "NOV": 5.23,
    "DEC": 4.89
  },
  {
    "LONG": -60.92,
    "LAT": 2.44,
    "CIDADE": "Mucajaí",
    "UF": "RORAIMA",
    "anual": 4.87,
    "JAN": 4.69,
    "FEB": 4.73,
    "MAR": 4.95,
    "APR": 4.63,
    "MAY": 4.22,
    "JUN": 4.46,
    "JUL": 4.51,
    "AUG": 5.02,
    "SEP": 5.55,
    "OCT": 5.48,
    "NOV": 5.3,
    "DEC": 4.86
  },
  {
    "LONG": -60.6,
    "LAT": 2.61,
    "CIDADE": "Cantá",
    "UF": "RORAIMA",
    "anual": 4.88,
    "JAN": 4.67,
    "FEB": 4.72,
    "MAR": 4.96,
    "APR": 4.65,
    "MAY": 4.27,
    "JUN": 4.48,
    "JUL": 4.51,
    "AUG": 5.04,
    "SEP": 5.62,
    "OCT": 5.53,
    "NOV": 5.29,
    "DEC": 4.81
  },
  {
    "LONG": -60.68,
    "LAT": 2.82,
    "CIDADE": "Boa Vista",
    "UF": "RORAIMA",
    "anual": 4.9,
    "JAN": 4.7,
    "FEB": 4.77,
    "MAR": 4.96,
    "APR": 4.76,
    "MAY": 4.36,
    "JUN": 4.51,
    "JUL": 4.61,
    "AUG": 5.09,
    "SEP": 5.53,
    "OCT": 5.49,
    "NOV": 5.22,
    "DEC": 4.78
  },
  {
    "LONG": -61.31,
    "LAT": 2.99,
    "CIDADE": "Alto Alegre",
    "UF": "RORAIMA",
    "anual": 4.86,
    "JAN": 4.64,
    "FEB": 4.79,
    "MAR": 4.96,
    "APR": 4.73,
    "MAY": 4.32,
    "JUN": 4.5,
    "JUL": 4.66,
    "AUG": 5.09,
    "SEP": 5.45,
    "OCT": 5.37,
    "NOV": 5.09,
    "DEC": 4.75
  },
  {
    "LONG": -59.83,
    "LAT": 3.36,
    "CIDADE": "Bonfim",
    "UF": "RORAIMA",
    "anual": 5.1,
    "JAN": 4.88,
    "FEB": 4.92,
    "MAR": 5.16,
    "APR": 4.99,
    "MAY": 4.56,
    "JUN": 4.74,
    "JUL": 4.79,
    "AUG": 5.26,
    "SEP": 5.75,
    "OCT": 5.74,
    "NOV": 5.45,
    "DEC": 4.96
  },
  {
    "LONG": -61.42,
    "LAT": 3.65,
    "CIDADE": "Amajari",
    "UF": "RORAIMA",
    "anual": 5.15,
    "JAN": 5.01,
    "FEB": 5.09,
    "MAR": 5.23,
    "APR": 5.08,
    "MAY": 4.65,
    "JUN": 4.71,
    "JUL": 4.86,
    "AUG": 5.36,
    "SEP": 5.76,
    "OCT": 5.62,
    "NOV": 5.38,
    "DEC": 5.01
  },
  {
    "LONG": -59.63,
    "LAT": 3.88,
    "CIDADE": "Normandia",
    "UF": "RORAIMA",
    "anual": 5.11,
    "JAN": 4.86,
    "FEB": 4.97,
    "MAR": 5.17,
    "APR": 5.03,
    "MAY": 4.7,
    "JUN": 4.83,
    "JUL": 4.93,
    "AUG": 5.4,
    "SEP": 5.78,
    "OCT": 5.49,
    "NOV": 5.28,
    "DEC": 4.89
  },
  {
    "LONG": -61.15,
    "LAT": 4.48,
    "CIDADE": "Pacaraima",
    "UF": "RORAIMA",
    "anual": 5.11,
    "JAN": 4.99,
    "FEB": 5.15,
    "MAR": 5.23,
    "APR": 5.22,
    "MAY": 4.75,
    "JUN": 4.59,
    "JUL": 4.73,
    "AUG": 5.13,
    "SEP": 5.63,
    "OCT": 5.57,
    "NOV": 5.33,
    "DEC": 4.98
  },
  {
    "LONG": -60.16,
    "LAT": 4.59,
    "CIDADE": "Uiramutã",
    "UF": "RORAIMA",
    "anual": 5.16,
    "JAN": 5.01,
    "FEB": 5.19,
    "MAR": 5.34,
    "APR": 5.19,
    "MAY": 4.75,
    "JUN": 4.7,
    "JUL": 4.94,
    "AUG": 5.32,
    "SEP": 5.72,
    "OCT": 5.63,
    "NOV": 5.34,
    "DEC": 4.84
  },
  {
    "LONG": -49.72,
    "LAT": -29.31,
    "CIDADE": "Passo de Torres",
    "UF": "SANTA CATARINA",
    "anual": 4.5,
    "JAN": 5.19,
    "FEB": 5.2,
    "MAR": 4.91,
    "APR": 4.69,
    "MAY": 4,
    "JUN": 3.45,
    "JUL": 3.68,
    "AUG": 4.25,
    "SEP": 3.91,
    "OCT": 4.28,
    "NOV": 5.21,
    "DEC": 5.26
  },
  {
    "LONG": -49.95,
    "LAT": -29.2,
    "CIDADE": "Praia Grande",
    "UF": "SANTA CATARINA",
    "anual": 4.21,
    "JAN": 4.6,
    "FEB": 4.66,
    "MAR": 4.47,
    "APR": 4.45,
    "MAY": 3.82,
    "JUN": 3.41,
    "JUL": 3.68,
    "AUG": 4.26,
    "SEP": 3.73,
    "OCT": 3.94,
    "NOV": 4.78,
    "DEC": 4.77
  },
  {
    "LONG": -49.8,
    "LAT": -29.22,
    "CIDADE": "São João do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.31,
    "JAN": 4.73,
    "FEB": 4.82,
    "MAR": 4.61,
    "APR": 4.55,
    "MAY": 3.88,
    "JUN": 3.44,
    "JUL": 3.72,
    "AUG": 4.27,
    "SEP": 3.83,
    "OCT": 4.05,
    "NOV": 4.9,
    "DEC": 4.91
  },
  {
    "LONG": -49.58,
    "LAT": -29.15,
    "CIDADE": "Balneário Gaivota",
    "UF": "SANTA CATARINA",
    "anual": 4.63,
    "JAN": 5.42,
    "FEB": 5.38,
    "MAR": 5.11,
    "APR": 4.82,
    "MAY": 4.02,
    "JUN": 3.49,
    "JUL": 3.7,
    "AUG": 4.27,
    "SEP": 3.98,
    "OCT": 4.51,
    "NOV": 5.42,
    "DEC": 5.49
  },
  {
    "LONG": -49.71,
    "LAT": -29.14,
    "CIDADE": "Santa Rosa do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.37,
    "JAN": 4.9,
    "FEB": 4.95,
    "MAR": 4.73,
    "APR": 4.6,
    "MAY": 3.93,
    "JUN": 3.43,
    "JUL": 3.7,
    "AUG": 4.27,
    "SEP": 3.83,
    "OCT": 4.11,
    "NOV": 5,
    "DEC": 5.01
  },
  {
    "LONG": -49.63,
    "LAT": -29.11,
    "CIDADE": "Sombrio",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.1,
    "FEB": 5.15,
    "MAR": 4.91,
    "APR": 4.68,
    "MAY": 4.01,
    "JUN": 3.44,
    "JUL": 3.72,
    "AUG": 4.29,
    "SEP": 3.9,
    "OCT": 4.27,
    "NOV": 5.19,
    "DEC": 5.23
  },
  {
    "LONG": -49.76,
    "LAT": -29,
    "CIDADE": "Jacinto Machado",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 4.85,
    "FEB": 4.93,
    "MAR": 4.72,
    "APR": 4.58,
    "MAY": 3.92,
    "JUN": 3.42,
    "JUL": 3.7,
    "AUG": 4.3,
    "SEP": 3.84,
    "OCT": 4.12,
    "NOV": 4.99,
    "DEC": 4.99
  },
  {
    "LONG": -49.64,
    "LAT": -28.99,
    "CIDADE": "Ermo",
    "UF": "SANTA CATARINA",
    "anual": 4.46,
    "JAN": 5,
    "FEB": 5.09,
    "MAR": 4.86,
    "APR": 4.66,
    "MAY": 3.99,
    "JUN": 3.43,
    "JUL": 3.71,
    "AUG": 4.31,
    "SEP": 3.87,
    "OCT": 4.24,
    "NOV": 5.13,
    "DEC": 5.17
  },
  {
    "LONG": -49.42,
    "LAT": -28.98,
    "CIDADE": "Balneário Arroio do Silva",
    "UF": "SANTA CATARINA",
    "anual": 4.6,
    "JAN": 5.35,
    "FEB": 5.33,
    "MAR": 5.02,
    "APR": 4.76,
    "MAY": 4.07,
    "JUN": 3.45,
    "JUL": 3.71,
    "AUG": 4.31,
    "SEP": 3.97,
    "OCT": 4.43,
    "NOV": 5.33,
    "DEC": 5.45
  },
  {
    "LONG": -49.68,
    "LAT": -28.93,
    "CIDADE": "Turvo",
    "UF": "SANTA CATARINA",
    "anual": 4.42,
    "JAN": 4.95,
    "FEB": 5.03,
    "MAR": 4.78,
    "APR": 4.65,
    "MAY": 3.96,
    "JUN": 3.43,
    "JUL": 3.72,
    "AUG": 4.31,
    "SEP": 3.86,
    "OCT": 4.18,
    "NOV": 5.07,
    "DEC": 5.09
  },
  {
    "LONG": -49.49,
    "LAT": -28.94,
    "CIDADE": "Araranguá",
    "UF": "SANTA CATARINA",
    "anual": 4.56,
    "JAN": 5.24,
    "FEB": 5.25,
    "MAR": 5,
    "APR": 4.7,
    "MAY": 4.06,
    "JUN": 3.45,
    "JUL": 3.72,
    "AUG": 4.32,
    "SEP": 3.94,
    "OCT": 4.35,
    "NOV": 5.27,
    "DEC": 5.37
  },
  {
    "LONG": -49.84,
    "LAT": -28.83,
    "CIDADE": "Timbé do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.19,
    "JAN": 4.51,
    "FEB": 4.63,
    "MAR": 4.49,
    "APR": 4.41,
    "MAY": 3.75,
    "JUN": 3.38,
    "JUL": 3.72,
    "AUG": 4.25,
    "SEP": 3.66,
    "OCT": 3.93,
    "NOV": 4.74,
    "DEC": 4.77
  },
  {
    "LONG": -49.72,
    "LAT": -28.8,
    "CIDADE": "Morro Grande",
    "UF": "SANTA CATARINA",
    "anual": 4.21,
    "JAN": 4.63,
    "FEB": 4.71,
    "MAR": 4.52,
    "APR": 4.42,
    "MAY": 3.82,
    "JUN": 3.4,
    "JUL": 3.67,
    "AUG": 4.25,
    "SEP": 3.71,
    "OCT": 3.92,
    "NOV": 4.78,
    "DEC": 4.76
  },
  {
    "LONG": -49.64,
    "LAT": -28.82,
    "CIDADE": "Meleiro",
    "UF": "SANTA CATARINA",
    "anual": 4.31,
    "JAN": 4.82,
    "FEB": 4.86,
    "MAR": 4.62,
    "APR": 4.51,
    "MAY": 3.89,
    "JUN": 3.41,
    "JUL": 3.7,
    "AUG": 4.27,
    "SEP": 3.79,
    "OCT": 4.05,
    "NOV": 4.93,
    "DEC": 4.92
  },
  {
    "LONG": -4.95,
    "LAT": -28.85,
    "CIDADE": "Maracajá",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.15,
    "FEB": 5.11,
    "MAR": 4.87,
    "APR": 4.64,
    "MAY": 4.02,
    "JUN": 3.45,
    "JUL": 3.72,
    "AUG": 4.33,
    "SEP": 3.91,
    "OCT": 4.28,
    "NOV": 5.19,
    "DEC": 5.19
  },
  {
    "LONG": -49.24,
    "LAT": -28.8,
    "CIDADE": "Balneário Rincão",
    "UF": "SANTA CATARINA",
    "anual": 4.57,
    "JAN": 5.34,
    "FEB": 5.29,
    "MAR": 4.95,
    "APR": 4.69,
    "MAY": 4.02,
    "JUN": 3.48,
    "JUL": 3.68,
    "AUG": 4.31,
    "SEP": 3.98,
    "OCT": 4.44,
    "NOV": 5.32,
    "DEC": 5.39
  },
  {
    "LONG": -49.48,
    "LAT": -28.75,
    "CIDADE": "Forquilhinha",
    "UF": "SANTA CATARINA",
    "anual": 4.37,
    "JAN": 4.86,
    "FEB": 4.89,
    "MAR": 4.72,
    "APR": 4.53,
    "MAY": 3.94,
    "JUN": 3.47,
    "JUL": 3.71,
    "AUG": 4.31,
    "SEP": 3.84,
    "OCT": 4.15,
    "NOV": 5.03,
    "DEC": 5.01
  },
  {
    "LONG": -49.37,
    "LAT": -28.67,
    "CIDADE": "Criciúma",
    "UF": "SANTA CATARINA",
    "anual": 4.45,
    "JAN": 5.02,
    "FEB": 5.02,
    "MAR": 4.8,
    "APR": 4.59,
    "MAY": 3.98,
    "JUN": 3.49,
    "JUL": 3.71,
    "AUG": 4.33,
    "SEP": 3.9,
    "OCT": 4.26,
    "NOV": 5.13,
    "DEC": 5.14
  },
  {
    "LONG": -49.31,
    "LAT": -28.71,
    "CIDADE": "Içara",
    "UF": "SANTA CATARINA",
    "anual": 4.45,
    "JAN": 5.02,
    "FEB": 5.02,
    "MAR": 4.8,
    "APR": 4.59,
    "MAY": 3.98,
    "JUN": 3.49,
    "JUL": 3.71,
    "AUG": 4.33,
    "SEP": 3.9,
    "OCT": 4.26,
    "NOV": 5.13,
    "DEC": 5.14
  },
  {
    "LONG": -49.22,
    "LAT": -28.65,
    "CIDADE": "Morro da Fumaça",
    "UF": "SANTA CATARINA",
    "anual": 4.52,
    "JAN": 5.2,
    "FEB": 5.16,
    "MAR": 4.87,
    "APR": 4.64,
    "MAY": 4.01,
    "JUN": 3.5,
    "JUL": 3.72,
    "AUG": 4.35,
    "SEP": 3.94,
    "OCT": 4.35,
    "NOV": 5.24,
    "DEC": 5.28
  },
  {
    "LONG": -4.95,
    "LAT": -28.63,
    "CIDADE": "Nova Veneza",
    "UF": "SANTA CATARINA",
    "anual": 4.21,
    "JAN": 4.52,
    "FEB": 4.64,
    "MAR": 4.46,
    "APR": 4.32,
    "MAY": 3.82,
    "JUN": 3.45,
    "JUL": 3.67,
    "AUG": 4.27,
    "SEP": 3.71,
    "OCT": 3.97,
    "NOV": 4.8,
    "DEC": 4.83
  },
  {
    "LONG": -49.43,
    "LAT": -2.86,
    "CIDADE": "Siderópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.27,
    "JAN": 4.66,
    "FEB": 4.72,
    "MAR": 4.57,
    "APR": 4.46,
    "MAY": 3.85,
    "JUN": 3.44,
    "JUL": 3.7,
    "AUG": 4.29,
    "SEP": 3.79,
    "OCT": 4.04,
    "NOV": 4.89,
    "DEC": 4.86
  },
  {
    "LONG": -4.93,
    "LAT": -28.6,
    "CIDADE": "Cocal do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.37,
    "JAN": 4.87,
    "FEB": 4.89,
    "MAR": 4.71,
    "APR": 4.53,
    "MAY": 3.92,
    "JUN": 3.48,
    "JUL": 3.71,
    "AUG": 4.33,
    "SEP": 3.84,
    "OCT": 4.16,
    "NOV": 5.02,
    "DEC": 5.02
  },
  {
    "LONG": -4.92,
    "LAT": -28.55,
    "CIDADE": "Treze de Maio",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.13,
    "FEB": 5.1,
    "MAR": 4.84,
    "APR": 4.58,
    "MAY": 3.98,
    "JUN": 3.5,
    "JUL": 3.72,
    "AUG": 4.35,
    "SEP": 3.9,
    "OCT": 4.31,
    "NOV": 5.2,
    "DEC": 5.26
  },
  {
    "LONG": -49.13,
    "LAT": -28.63,
    "CIDADE": "Sangão",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.13,
    "FEB": 5.1,
    "MAR": 4.84,
    "APR": 4.58,
    "MAY": 3.98,
    "JUN": 3.5,
    "JUL": 3.72,
    "AUG": 4.35,
    "SEP": 3.9,
    "OCT": 4.31,
    "NOV": 5.2,
    "DEC": 5.26
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.62,
    "CIDADE": "Jaguaruna",
    "UF": "SANTA CATARINA",
    "anual": 4.53,
    "JAN": 5.25,
    "FEB": 5.18,
    "MAR": 4.88,
    "APR": 4.59,
    "MAY": 4.01,
    "JUN": 3.49,
    "JUL": 3.73,
    "AUG": 4.35,
    "SEP": 3.94,
    "OCT": 4.35,
    "NOV": 5.24,
    "DEC": 5.34
  },
  {
    "LONG": -49.46,
    "LAT": -28.51,
    "CIDADE": "Treviso",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.59,
    "FEB": 4.62,
    "MAR": 4.5,
    "APR": 4.39,
    "MAY": 3.81,
    "JUN": 3.47,
    "JUL": 3.67,
    "AUG": 4.3,
    "SEP": 3.74,
    "OCT": 3.96,
    "NOV": 4.79,
    "DEC": 4.81
  },
  {
    "LONG": -49.32,
    "LAT": -28.52,
    "CIDADE": "Urussanga",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 4.84,
    "FEB": 4.88,
    "MAR": 4.7,
    "APR": 4.53,
    "MAY": 3.9,
    "JUN": 3.48,
    "JUL": 3.68,
    "AUG": 4.33,
    "SEP": 3.84,
    "OCT": 4.14,
    "NOV": 4.99,
    "DEC": 5.03
  },
  {
    "LONG": -49.01,
    "LAT": -28.47,
    "CIDADE": "Tubarão",
    "UF": "SANTA CATARINA",
    "anual": 4.5,
    "JAN": 5.12,
    "FEB": 5.13,
    "MAR": 4.85,
    "APR": 4.56,
    "MAY": 3.99,
    "JUN": 3.52,
    "JUL": 3.73,
    "AUG": 4.38,
    "SEP": 3.95,
    "OCT": 4.33,
    "NOV": 5.18,
    "DEC": 5.28
  },
  {
    "LONG": -48.78,
    "LAT": -28.48,
    "CIDADE": "Laguna",
    "UF": "SANTA CATARINA",
    "anual": 4.69,
    "JAN": 5.51,
    "FEB": 5.41,
    "MAR": 5.16,
    "APR": 4.7,
    "MAY": 4.06,
    "JUN": 3.53,
    "JUL": 3.75,
    "AUG": 4.33,
    "SEP": 4.08,
    "OCT": 4.59,
    "NOV": 5.49,
    "DEC": 5.6
  },
  {
    "LONG": -49.4,
    "LAT": -28.39,
    "CIDADE": "Lauro Muller",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.59,
    "FEB": 4.6,
    "MAR": 4.46,
    "APR": 4.36,
    "MAY": 3.78,
    "JUN": 3.49,
    "JUL": 3.67,
    "AUG": 4.31,
    "SEP": 3.79,
    "OCT": 3.97,
    "NOV": 4.78,
    "DEC": 4.79
  },
  {
    "LONG": -49.2,
    "LAT": -28.43,
    "CIDADE": "Pedras Grandes",
    "UF": "SANTA CATARINA",
    "anual": 4.48,
    "JAN": 5.06,
    "FEB": 5.07,
    "MAR": 4.85,
    "APR": 4.56,
    "MAY": 3.93,
    "JUN": 3.52,
    "JUL": 3.72,
    "AUG": 4.39,
    "SEP": 3.96,
    "OCT": 4.32,
    "NOV": 5.16,
    "DEC": 5.21
  },
  {
    "LONG": -48.96,
    "LAT": -28.45,
    "CIDADE": "Capivari de Baixo",
    "UF": "SANTA CATARINA",
    "anual": 4.54,
    "JAN": 5.2,
    "FEB": 5.21,
    "MAR": 4.91,
    "APR": 4.6,
    "MAY": 3.97,
    "JUN": 3.54,
    "JUL": 3.77,
    "AUG": 4.39,
    "SEP": 3.98,
    "OCT": 4.39,
    "NOV": 5.22,
    "DEC": 5.33
  },
  {
    "LONG": -48.88,
    "LAT": -2.84,
    "CIDADE": "Pescaria Brava",
    "UF": "SANTA CATARINA",
    "anual": 4.55,
    "JAN": 5.25,
    "FEB": 5.21,
    "MAR": 4.95,
    "APR": 4.59,
    "MAY": 3.99,
    "JUN": 3.5,
    "JUL": 3.74,
    "AUG": 4.35,
    "SEP": 3.98,
    "OCT": 4.38,
    "NOV": 5.27,
    "DEC": 5.37
  },
  {
    "LONG": -49.95,
    "LAT": -28.29,
    "CIDADE": "São Joaquim",
    "UF": "SANTA CATARINA",
    "anual": 4.73,
    "JAN": 5.18,
    "FEB": 5.25,
    "MAR": 5.11,
    "APR": 4.74,
    "MAY": 3.98,
    "JUN": 3.65,
    "JUL": 3.96,
    "AUG": 4.64,
    "SEP": 4.32,
    "OCT": 4.81,
    "NOV": 5.52,
    "DEC": 5.54
  },
  {
    "LONG": -49.64,
    "LAT": -28.34,
    "CIDADE": "Bom Jardim da Serra",
    "UF": "SANTA CATARINA",
    "anual": 4.63,
    "JAN": 4.93,
    "FEB": 5.08,
    "MAR": 4.94,
    "APR": 4.65,
    "MAY": 3.98,
    "JUN": 3.72,
    "JUL": 3.96,
    "AUG": 4.65,
    "SEP": 4.28,
    "OCT": 4.68,
    "NOV": 5.34,
    "DEC": 5.33
  },
  {
    "LONG": -49.3,
    "LAT": -28.35,
    "CIDADE": "Orleans",
    "UF": "SANTA CATARINA",
    "anual": 4.3,
    "JAN": 4.69,
    "FEB": 4.72,
    "MAR": 4.6,
    "APR": 4.45,
    "MAY": 3.85,
    "JUN": 3.49,
    "JUL": 3.69,
    "AUG": 4.35,
    "SEP": 3.89,
    "OCT": 4.1,
    "NOV": 4.92,
    "DEC": 4.89
  },
  {
    "LONG": -49.17,
    "LAT": -28.27,
    "CIDADE": "Braço do Norte",
    "UF": "SANTA CATARINA",
    "anual": 4.47,
    "JAN": 5.06,
    "FEB": 5.03,
    "MAR": 4.82,
    "APR": 4.57,
    "MAY": 3.93,
    "JUN": 3.52,
    "JUL": 3.73,
    "AUG": 4.41,
    "SEP": 3.97,
    "OCT": 4.32,
    "NOV": 5.14,
    "DEC": 5.2
  },
  {
    "LONG": -49.18,
    "LAT": -28.31,
    "CIDADE": "São Ludgero",
    "UF": "SANTA CATARINA",
    "anual": 4.47,
    "JAN": 5.06,
    "FEB": 5.03,
    "MAR": 4.82,
    "APR": 4.57,
    "MAY": 3.93,
    "JUN": 3.52,
    "JUL": 3.73,
    "AUG": 4.41,
    "SEP": 3.97,
    "OCT": 4.32,
    "NOV": 5.14,
    "DEC": 5.2
  },
  {
    "LONG": -49.04,
    "LAT": -28.32,
    "CIDADE": "Gravatal",
    "UF": "SANTA CATARINA",
    "anual": 4.51,
    "JAN": 5.12,
    "FEB": 5.09,
    "MAR": 4.83,
    "APR": 4.59,
    "MAY": 3.97,
    "JUN": 3.54,
    "JUL": 3.76,
    "AUG": 4.41,
    "SEP": 3.99,
    "OCT": 4.36,
    "NOV": 5.2,
    "DEC": 5.24
  },
  {
    "LONG": -48.82,
    "LAT": -28.33,
    "CIDADE": "Imaruí",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.13,
    "FEB": 5.12,
    "MAR": 4.85,
    "APR": 4.52,
    "MAY": 3.95,
    "JUN": 3.49,
    "JUL": 3.73,
    "AUG": 4.36,
    "SEP": 3.95,
    "OCT": 4.28,
    "NOV": 5.17,
    "DEC": 5.28
  },
  {
    "LONG": -49.23,
    "LAT": -28.18,
    "CIDADE": "Grão Pará",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 4.87,
    "FEB": 4.84,
    "MAR": 4.7,
    "APR": 4.49,
    "MAY": 3.87,
    "JUN": 3.54,
    "JUL": 3.72,
    "AUG": 4.36,
    "SEP": 3.9,
    "OCT": 4.14,
    "NOV": 4.97,
    "DEC": 4.95
  },
  {
    "LONG": -49.02,
    "LAT": -28.25,
    "CIDADE": "Armazém",
    "UF": "SANTA CATARINA",
    "anual": 4.51,
    "JAN": 5.1,
    "FEB": 5.09,
    "MAR": 4.87,
    "APR": 4.59,
    "MAY": 3.96,
    "JUN": 3.59,
    "JUL": 3.77,
    "AUG": 4.42,
    "SEP": 3.99,
    "OCT": 4.36,
    "NOV": 5.16,
    "DEC": 5.21
  },
  {
    "LONG": -48.99,
    "LAT": -28.16,
    "CIDADE": "São Martinho",
    "UF": "SANTA CATARINA",
    "anual": 4.53,
    "JAN": 5.17,
    "FEB": 5.14,
    "MAR": 4.88,
    "APR": 4.6,
    "MAY": 3.97,
    "JUN": 3.58,
    "JUL": 3.78,
    "AUG": 4.41,
    "SEP": 4,
    "OCT": 4.37,
    "NOV": 5.2,
    "DEC": 5.26
  },
  {
    "LONG": -48.67,
    "LAT": -28.23,
    "CIDADE": "Imbituba",
    "UF": "SANTA CATARINA",
    "anual": 4.56,
    "JAN": 5.28,
    "FEB": 5.24,
    "MAR": 5.02,
    "APR": 4.56,
    "MAY": 4.03,
    "JUN": 3.52,
    "JUL": 3.74,
    "AUG": 4.31,
    "SEP": 4.02,
    "OCT": 4.39,
    "NOV": 5.26,
    "DEC": 5.38
  },
  {
    "LONG": -49.11,
    "LAT": -28.12,
    "CIDADE": "Rio Fortuna",
    "UF": "SANTA CATARINA",
    "anual": 4.43,
    "JAN": 4.93,
    "FEB": 4.99,
    "MAR": 4.81,
    "APR": 4.55,
    "MAY": 3.88,
    "JUN": 3.54,
    "JUL": 3.75,
    "AUG": 4.4,
    "SEP": 3.93,
    "OCT": 4.24,
    "NOV": 5.06,
    "DEC": 5.07
  },
  {
    "LONG": -49.87,
    "LAT": -27.96,
    "CIDADE": "Urupema",
    "UF": "SANTA CATARINA",
    "anual": 4.59,
    "JAN": 5.15,
    "FEB": 5.13,
    "MAR": 4.94,
    "APR": 4.57,
    "MAY": 3.91,
    "JUN": 3.6,
    "JUL": 3.85,
    "AUG": 4.59,
    "SEP": 4.26,
    "OCT": 4.49,
    "NOV": 5.31,
    "DEC": 5.29
  },
  {
    "LONG": -4.96,
    "LAT": -28.02,
    "CIDADE": "Urubici",
    "UF": "SANTA CATARINA",
    "anual": 4.48,
    "JAN": 5.04,
    "FEB": 5.11,
    "MAR": 4.88,
    "APR": 4.55,
    "MAY": 3.85,
    "JUN": 3.48,
    "JUL": 3.69,
    "AUG": 4.41,
    "SEP": 4.11,
    "OCT": 4.3,
    "NOV": 5.15,
    "DEC": 5.16
  },
  {
    "LONG": -49.13,
    "LAT": -28.03,
    "CIDADE": "Santa Rosa de Lima",
    "UF": "SANTA CATARINA",
    "anual": 4.38,
    "JAN": 4.83,
    "FEB": 4.89,
    "MAR": 4.72,
    "APR": 4.48,
    "MAY": 3.85,
    "JUN": 3.54,
    "JUL": 3.73,
    "AUG": 4.39,
    "SEP": 3.93,
    "OCT": 4.18,
    "NOV": 5.01,
    "DEC": 5.01
  },
  {
    "LONG": -48.67,
    "LAT": -27.96,
    "CIDADE": "Paulo Lopes",
    "UF": "SANTA CATARINA",
    "anual": 4.39,
    "JAN": 5.04,
    "FEB": 4.96,
    "MAR": 4.75,
    "APR": 4.39,
    "MAY": 3.97,
    "JUN": 3.51,
    "JUL": 3.67,
    "AUG": 4.31,
    "SEP": 3.9,
    "OCT": 4.13,
    "NOV": 4.97,
    "DEC": 5.09
  },
  {
    "LONG": -48.62,
    "LAT": -28.03,
    "CIDADE": "Garopaba",
    "UF": "SANTA CATARINA",
    "anual": 4.39,
    "JAN": 5.04,
    "FEB": 4.96,
    "MAR": 4.75,
    "APR": 4.39,
    "MAY": 3.97,
    "JUN": 3.51,
    "JUL": 3.67,
    "AUG": 4.31,
    "SEP": 3.9,
    "OCT": 4.13,
    "NOV": 4.97,
    "DEC": 5.09
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.79,
    "CIDADE": "Campo Belo do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.6,
    "JAN": 5.29,
    "FEB": 5.29,
    "MAR": 5.03,
    "APR": 4.6,
    "MAY": 3.82,
    "JUN": 3.44,
    "JUL": 3.73,
    "AUG": 4.5,
    "SEP": 4.15,
    "OCT": 4.61,
    "NOV": 5.34,
    "DEC": 5.38
  },
  {
    "LONG": -50.51,
    "LAT": -27.94,
    "CIDADE": "Capão Alto",
    "UF": "SANTA CATARINA",
    "anual": 4.53,
    "JAN": 5.19,
    "FEB": 5.27,
    "MAR": 4.93,
    "APR": 4.52,
    "MAY": 3.74,
    "JUN": 3.37,
    "JUL": 3.7,
    "AUG": 4.48,
    "SEP": 4.1,
    "OCT": 4.5,
    "NOV": 5.27,
    "DEC": 5.32
  },
  {
    "LONG": -50.1,
    "LAT": -27.92,
    "CIDADE": "Painel",
    "UF": "SANTA CATARINA",
    "anual": 4.53,
    "JAN": 5.14,
    "FEB": 5.17,
    "MAR": 4.97,
    "APR": 4.52,
    "MAY": 3.78,
    "JUN": 3.43,
    "JUL": 3.72,
    "AUG": 4.42,
    "SEP": 4.14,
    "OCT": 4.46,
    "NOV": 5.27,
    "DEC": 5.27
  },
  {
    "LONG": -49.78,
    "LAT": -27.86,
    "CIDADE": "Rio Rufino",
    "UF": "SANTA CATARINA",
    "anual": 4.42,
    "JAN": 5.04,
    "FEB": 5.12,
    "MAR": 4.83,
    "APR": 4.49,
    "MAY": 3.81,
    "JUN": 3.42,
    "JUL": 3.63,
    "AUG": 4.34,
    "SEP": 4,
    "OCT": 4.2,
    "NOV": 5.06,
    "DEC": 5.12
  },
  {
    "LONG": -49.13,
    "LAT": -27.9,
    "CIDADE": "Anitápolis",
    "UF": "SANTA CATARINA",
    "anual": 4.33,
    "JAN": 4.81,
    "FEB": 4.9,
    "MAR": 4.65,
    "APR": 4.4,
    "MAY": 3.79,
    "JUN": 3.49,
    "JUL": 3.68,
    "AUG": 4.34,
    "SEP": 3.85,
    "OCT": 4.08,
    "NOV": 4.93,
    "DEC": 5.01
  },
  {
    "LONG": -4.89,
    "LAT": -27.9,
    "CIDADE": "São Bonifácio",
    "UF": "SANTA CATARINA",
    "anual": 4.27,
    "JAN": 4.8,
    "FEB": 4.8,
    "MAR": 4.6,
    "APR": 4.32,
    "MAY": 3.83,
    "JUN": 3.51,
    "JUL": 3.66,
    "AUG": 4.24,
    "SEP": 3.76,
    "OCT": 3.99,
    "NOV": 4.81,
    "DEC": 4.89
  },
  {
    "LONG": -50.87,
    "LAT": -27.79,
    "CIDADE": "Cerro Negro",
    "UF": "SANTA CATARINA",
    "anual": 4.63,
    "JAN": 5.27,
    "FEB": 5.31,
    "MAR": 5.09,
    "APR": 4.65,
    "MAY": 3.8,
    "JUN": 3.44,
    "JUL": 3.76,
    "AUG": 4.52,
    "SEP": 4.21,
    "OCT": 4.65,
    "NOV": 5.38,
    "DEC": 5.43
  },
  {
    "LONG": -50.33,
    "LAT": -27.82,
    "CIDADE": "Lages",
    "UF": "SANTA CATARINA",
    "anual": 4.45,
    "JAN": 5.12,
    "FEB": 5.22,
    "MAR": 4.88,
    "APR": 4.44,
    "MAY": 3.7,
    "JUN": 3.33,
    "JUL": 3.6,
    "AUG": 4.35,
    "SEP": 4.03,
    "OCT": 4.34,
    "NOV": 5.14,
    "DEC": 5.21
  },
  {
    "LONG": -49.49,
    "LAT": -27.8,
    "CIDADE": "Bom Retiro",
    "UF": "SANTA CATARINA",
    "anual": 4.41,
    "JAN": 5.07,
    "FEB": 5.09,
    "MAR": 4.87,
    "APR": 4.46,
    "MAY": 3.8,
    "JUN": 3.39,
    "JUL": 3.62,
    "AUG": 4.33,
    "SEP": 3.96,
    "OCT": 4.14,
    "NOV": 5.05,
    "DEC": 5.11
  },
  {
    "LONG": -51.13,
    "LAT": -27.69,
    "CIDADE": "Anita Garibaldi",
    "UF": "SANTA CATARINA",
    "anual": 4.75,
    "JAN": 5.41,
    "FEB": 5.37,
    "MAR": 5.24,
    "APR": 4.8,
    "MAY": 3.89,
    "JUN": 3.54,
    "JUL": 3.89,
    "AUG": 4.61,
    "SEP": 4.31,
    "OCT": 4.82,
    "NOV": 5.55,
    "DEC": 5.52
  },
  {
    "LONG": -50.57,
    "LAT": -27.66,
    "CIDADE": "São José do Cerrito",
    "UF": "SANTA CATARINA",
    "anual": 4.53,
    "JAN": 5.12,
    "FEB": 5.19,
    "MAR": 4.93,
    "APR": 4.54,
    "MAY": 3.75,
    "JUN": 3.44,
    "JUL": 3.7,
    "AUG": 4.47,
    "SEP": 4.15,
    "OCT": 4.48,
    "NOV": 5.24,
    "DEC": 5.3
  },
  {
    "LONG": -49.94,
    "LAT": -2.77,
    "CIDADE": "Bocaina do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.37,
    "JAN": 5.05,
    "FEB": 5.12,
    "MAR": 4.87,
    "APR": 4.38,
    "MAY": 3.74,
    "JUN": 3.31,
    "JUL": 3.55,
    "AUG": 4.25,
    "SEP": 3.93,
    "OCT": 4.12,
    "NOV": 5.04,
    "DEC": 5.1
  },
  {
    "LONG": -49.33,
    "LAT": -27.7,
    "CIDADE": "Alfredo Wagner",
    "UF": "SANTA CATARINA",
    "anual": 4.42,
    "JAN": 5.13,
    "FEB": 5.12,
    "MAR": 5,
    "APR": 4.53,
    "MAY": 3.84,
    "JUN": 3.37,
    "JUL": 3.58,
    "AUG": 4.29,
    "SEP": 3.91,
    "OCT": 4.1,
    "NOV": 5.06,
    "DEC": 5.16
  },
  {
    "LONG": -49.02,
    "LAT": -27.67,
    "CIDADE": "Rancho Queimado",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.77,
    "FEB": 4.83,
    "MAR": 4.62,
    "APR": 4.33,
    "MAY": 3.82,
    "JUN": 3.49,
    "JUL": 3.57,
    "AUG": 4.22,
    "SEP": 3.75,
    "OCT": 3.92,
    "NOV": 4.76,
    "DEC": 4.87
  },
  {
    "LONG": -48.82,
    "LAT": -27.7,
    "CIDADE": "Águas Mornas",
    "UF": "SANTA CATARINA",
    "anual": 4.26,
    "JAN": 4.83,
    "FEB": 4.87,
    "MAR": 4.66,
    "APR": 4.33,
    "MAY": 3.9,
    "JUN": 3.53,
    "JUL": 3.59,
    "AUG": 4.21,
    "SEP": 3.74,
    "OCT": 3.88,
    "NOV": 4.74,
    "DEC": 4.88
  },
  {
    "LONG": -48.78,
    "LAT": -27.69,
    "CIDADE": "Santo Amaro da Imperatriz",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 4.91,
    "FEB": 5.02,
    "MAR": 4.75,
    "APR": 4.36,
    "MAY": 3.95,
    "JUN": 3.54,
    "JUL": 3.62,
    "AUG": 4.23,
    "SEP": 3.79,
    "OCT": 4.01,
    "NOV": 4.83,
    "DEC": 5
  },
  {
    "LONG": -51.34,
    "LAT": -27.63,
    "CIDADE": "Celso Ramos",
    "UF": "SANTA CATARINA",
    "anual": 4.77,
    "JAN": 5.42,
    "FEB": 5.4,
    "MAR": 5.22,
    "APR": 4.84,
    "MAY": 3.94,
    "JUN": 3.58,
    "JUL": 3.92,
    "AUG": 4.65,
    "SEP": 4.32,
    "OCT": 4.84,
    "NOV": 5.54,
    "DEC": 5.53
  },
  {
    "LONG": -51.02,
    "LAT": -27.61,
    "CIDADE": "Abdon Batista",
    "UF": "SANTA CATARINA",
    "anual": 4.67,
    "JAN": 5.33,
    "FEB": 5.29,
    "MAR": 5.17,
    "APR": 4.72,
    "MAY": 3.83,
    "JUN": 3.49,
    "JUL": 3.81,
    "AUG": 4.58,
    "SEP": 4.26,
    "OCT": 4.72,
    "NOV": 5.43,
    "DEC": 5.38
  },
  {
    "LONG": -50.36,
    "LAT": -27.59,
    "CIDADE": "Correia Pinto",
    "UF": "SANTA CATARINA",
    "anual": 4.42,
    "JAN": 5.05,
    "FEB": 5.16,
    "MAR": 4.91,
    "APR": 4.41,
    "MAY": 3.71,
    "JUN": 3.32,
    "JUL": 3.55,
    "AUG": 4.31,
    "SEP": 4.01,
    "OCT": 4.32,
    "NOV": 5.13,
    "DEC": 5.21
  },
  {
    "LONG": -50.16,
    "LAT": -27.58,
    "CIDADE": "Palmeira",
    "UF": "SANTA CATARINA",
    "anual": 4.33,
    "JAN": 5.02,
    "FEB": 5.08,
    "MAR": 4.75,
    "APR": 4.32,
    "MAY": 3.68,
    "JUN": 3.22,
    "JUL": 3.52,
    "AUG": 4.21,
    "SEP": 3.9,
    "OCT": 4.11,
    "NOV": 5.01,
    "DEC": 5.1
  },
  {
    "LONG": -49.55,
    "LAT": -2.76,
    "CIDADE": "Chapadão do Lageado",
    "UF": "SANTA CATARINA",
    "anual": 4.39,
    "JAN": 5.15,
    "FEB": 5.21,
    "MAR": 4.93,
    "APR": 4.46,
    "MAY": 3.8,
    "JUN": 3.26,
    "JUL": 3.48,
    "AUG": 4.2,
    "SEP": 3.87,
    "OCT": 4.12,
    "NOV": 5,
    "DEC": 5.18
  },
  {
    "LONG": -48.99,
    "LAT": -27.57,
    "CIDADE": "Angelina",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.84,
    "FEB": 4.93,
    "MAR": 4.7,
    "APR": 4.31,
    "MAY": 3.86,
    "JUN": 3.44,
    "JUL": 3.57,
    "AUG": 4.18,
    "SEP": 3.71,
    "OCT": 3.89,
    "NOV": 4.66,
    "DEC": 4.91
  },
  {
    "LONG": -48.81,
    "LAT": -2.76,
    "CIDADE": "São Pedro de Alcântara",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.79,
    "FEB": 4.83,
    "MAR": 4.68,
    "APR": 4.3,
    "MAY": 3.87,
    "JUN": 3.48,
    "JUL": 3.54,
    "AUG": 4.16,
    "SEP": 3.67,
    "OCT": 3.81,
    "NOV": 4.63,
    "DEC": 4.85
  },
  {
    "LONG": -48.67,
    "LAT": -2.76,
    "CIDADE": "Palhoça",
    "UF": "SANTA CATARINA",
    "anual": 4.39,
    "JAN": 5.12,
    "FEB": 5.14,
    "MAR": 4.85,
    "APR": 4.45,
    "MAY": 3.97,
    "JUN": 3.49,
    "JUL": 3.58,
    "AUG": 4.2,
    "SEP": 3.78,
    "OCT": 4.07,
    "NOV": 4.92,
    "DEC": 5.11
  },
  {
    "LONG": -4.86,
    "LAT": -27.59,
    "CIDADE": "São José",
    "UF": "SANTA CATARINA",
    "anual": 4.39,
    "JAN": 5.12,
    "FEB": 5.14,
    "MAR": 4.85,
    "APR": 4.45,
    "MAY": 3.97,
    "JUN": 3.49,
    "JUL": 3.58,
    "AUG": 4.2,
    "SEP": 3.78,
    "OCT": 4.07,
    "NOV": 4.92,
    "DEC": 5.11
  },
  {
    "LONG": -48.55,
    "LAT": -27.6,
    "CIDADE": "Florianópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.23,
    "FEB": 5.23,
    "MAR": 5.01,
    "APR": 4.51,
    "MAY": 4,
    "JUN": 3.53,
    "JUL": 3.61,
    "AUG": 4.22,
    "SEP": 3.89,
    "OCT": 4.29,
    "NOV": 5.06,
    "DEC": 5.25
  },
  {
    "LONG": -51.55,
    "LAT": -27.45,
    "CIDADE": "Zortéa",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.47,
    "FEB": 5.44,
    "MAR": 5.35,
    "APR": 4.88,
    "MAY": 4.02,
    "JUN": 3.64,
    "JUL": 3.92,
    "AUG": 4.66,
    "SEP": 4.37,
    "OCT": 4.94,
    "NOV": 5.59,
    "DEC": 5.57
  },
  {
    "LONG": -50.97,
    "LAT": -27.49,
    "CIDADE": "Vargem",
    "UF": "SANTA CATARINA",
    "anual": 4.6,
    "JAN": 5.15,
    "FEB": 5.22,
    "MAR": 5.08,
    "APR": 4.64,
    "MAY": 3.79,
    "JUN": 3.46,
    "JUL": 3.76,
    "AUG": 4.54,
    "SEP": 4.25,
    "OCT": 4.63,
    "NOV": 5.34,
    "DEC": 5.34
  },
  {
    "LONG": -50.38,
    "LAT": -2.75,
    "CIDADE": "Ponte Alta",
    "UF": "SANTA CATARINA",
    "anual": 4.44,
    "JAN": 5.03,
    "FEB": 5.06,
    "MAR": 4.92,
    "APR": 4.44,
    "MAY": 3.74,
    "JUN": 3.4,
    "JUL": 3.6,
    "AUG": 4.41,
    "SEP": 4.06,
    "OCT": 4.32,
    "NOV": 5.15,
    "DEC": 5.19
  },
  {
    "LONG": -50.12,
    "LAT": -27.48,
    "CIDADE": "Otacílio Costa",
    "UF": "SANTA CATARINA",
    "anual": 4.31,
    "JAN": 4.95,
    "FEB": 5.03,
    "MAR": 4.79,
    "APR": 4.34,
    "MAY": 3.68,
    "JUN": 3.24,
    "JUL": 3.5,
    "AUG": 4.21,
    "SEP": 3.88,
    "OCT": 4.06,
    "NOV": 5,
    "DEC": 5.05
  },
  {
    "LONG": -49.69,
    "LAT": -27.54,
    "CIDADE": "Petrolândia",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 5.09,
    "FEB": 5.14,
    "MAR": 4.95,
    "APR": 4.39,
    "MAY": 3.76,
    "JUN": 3.21,
    "JUL": 3.39,
    "AUG": 4.15,
    "SEP": 3.85,
    "OCT": 4.08,
    "NOV": 4.98,
    "DEC": 5.08
  },
  {
    "LONG": -49.42,
    "LAT": -27.49,
    "CIDADE": "Imbuia",
    "UF": "SANTA CATARINA",
    "anual": 4.31,
    "JAN": 5.06,
    "FEB": 5.06,
    "MAR": 4.83,
    "APR": 4.37,
    "MAY": 3.76,
    "JUN": 3.23,
    "JUL": 3.47,
    "AUG": 4.18,
    "SEP": 3.84,
    "OCT": 4,
    "NOV": 4.92,
    "DEC": 5.02
  },
  {
    "LONG": -49.28,
    "LAT": -27.51,
    "CIDADE": "Leoberto Leal",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.93,
    "FEB": 4.99,
    "MAR": 4.74,
    "APR": 4.37,
    "MAY": 3.82,
    "JUN": 3.34,
    "JUL": 3.52,
    "AUG": 4.2,
    "SEP": 3.8,
    "OCT": 3.94,
    "NOV": 4.83,
    "DEC": 4.95
  },
  {
    "LONG": -48.77,
    "LAT": -27.52,
    "CIDADE": "Antônio Carlos",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.84,
    "FEB": 4.92,
    "MAR": 4.7,
    "APR": 4.31,
    "MAY": 3.9,
    "JUN": 3.49,
    "JUL": 3.54,
    "AUG": 4.15,
    "SEP": 3.7,
    "OCT": 3.86,
    "NOV": 4.69,
    "DEC": 4.88
  },
  {
    "LONG": -48.66,
    "LAT": -27.5,
    "CIDADE": "Biguaçu",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 5.06,
    "FEB": 5.1,
    "MAR": 4.83,
    "APR": 4.43,
    "MAY": 3.95,
    "JUN": 3.49,
    "JUL": 3.57,
    "AUG": 4.18,
    "SEP": 3.77,
    "OCT": 4.03,
    "NOV": 4.87,
    "DEC": 5.03
  },
  {
    "LONG": -51.9,
    "LAT": -27.38,
    "CIDADE": "Peritiba",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.58,
    "FEB": 5.48,
    "MAR": 5.45,
    "APR": 4.84,
    "MAY": 4.01,
    "JUN": 3.43,
    "JUL": 3.84,
    "AUG": 4.64,
    "SEP": 4.38,
    "OCT": 4.99,
    "NOV": 5.65,
    "DEC": 5.68
  },
  {
    "LONG": -51.9,
    "LAT": -27.43,
    "CIDADE": "Alto Bela Vista",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.58,
    "FEB": 5.48,
    "MAR": 5.45,
    "APR": 4.84,
    "MAY": 4.01,
    "JUN": 3.43,
    "JUL": 3.84,
    "AUG": 4.64,
    "SEP": 4.38,
    "OCT": 4.99,
    "NOV": 5.65,
    "DEC": 5.68
  },
  {
    "LONG": -51.77,
    "LAT": -27.42,
    "CIDADE": "Piratuba",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.44,
    "FEB": 5.42,
    "MAR": 5.43,
    "APR": 4.9,
    "MAY": 4.03,
    "JUN": 3.59,
    "JUL": 3.94,
    "AUG": 4.68,
    "SEP": 4.39,
    "OCT": 4.96,
    "NOV": 5.63,
    "DEC": 5.6
  },
  {
    "LONG": -51.78,
    "LAT": -27.4,
    "CIDADE": "Ipira",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.44,
    "FEB": 5.42,
    "MAR": 5.43,
    "APR": 4.9,
    "MAY": 4.03,
    "JUN": 3.59,
    "JUL": 3.94,
    "AUG": 4.68,
    "SEP": 4.39,
    "OCT": 4.96,
    "NOV": 5.63,
    "DEC": 5.6
  },
  {
    "LONG": -5.12,
    "LAT": -27.4,
    "CIDADE": "Campos Novos",
    "UF": "SANTA CATARINA",
    "anual": 4.77,
    "JAN": 5.31,
    "FEB": 5.34,
    "MAR": 5.22,
    "APR": 4.81,
    "MAY": 3.95,
    "JUN": 3.68,
    "JUL": 3.95,
    "AUG": 4.7,
    "SEP": 4.41,
    "OCT": 4.85,
    "NOV": 5.49,
    "DEC": 5.47
  },
  {
    "LONG": -49.88,
    "LAT": -27.36,
    "CIDADE": "Braço do Trombudo",
    "UF": "SANTA CATARINA",
    "anual": 4.3,
    "JAN": 5.04,
    "FEB": 5.13,
    "MAR": 4.88,
    "APR": 4.34,
    "MAY": 3.73,
    "JUN": 3.15,
    "JUL": 3.32,
    "AUG": 4.08,
    "SEP": 3.83,
    "OCT": 4.03,
    "NOV": 4.94,
    "DEC": 5.06
  },
  {
    "LONG": -49.82,
    "LAT": -27.41,
    "CIDADE": "Agrolândia",
    "UF": "SANTA CATARINA",
    "anual": 4.3,
    "JAN": 5.04,
    "FEB": 5.13,
    "MAR": 4.88,
    "APR": 4.34,
    "MAY": 3.73,
    "JUN": 3.15,
    "JUL": 3.32,
    "AUG": 4.08,
    "SEP": 3.83,
    "OCT": 4.03,
    "NOV": 4.94,
    "DEC": 5.06
  },
  {
    "LONG": -49.78,
    "LAT": -27.42,
    "CIDADE": "Atalanta",
    "UF": "SANTA CATARINA",
    "anual": 4.32,
    "JAN": 5.07,
    "FEB": 5.16,
    "MAR": 4.92,
    "APR": 4.35,
    "MAY": 3.74,
    "JUN": 3.14,
    "JUL": 3.33,
    "AUG": 4.1,
    "SEP": 3.85,
    "OCT": 4.07,
    "NOV": 4.97,
    "DEC": 5.08
  },
  {
    "LONG": -49.6,
    "LAT": -27.42,
    "CIDADE": "Ituporanga",
    "UF": "SANTA CATARINA",
    "anual": 4.3,
    "JAN": 5.06,
    "FEB": 5.13,
    "MAR": 4.91,
    "APR": 4.32,
    "MAY": 3.7,
    "JUN": 3.13,
    "JUL": 3.33,
    "AUG": 4.1,
    "SEP": 3.83,
    "OCT": 4.05,
    "NOV": 4.95,
    "DEC": 5.07
  },
  {
    "LONG": -49.36,
    "LAT": -27.39,
    "CIDADE": "Vidal Ramos",
    "UF": "SANTA CATARINA",
    "anual": 4.26,
    "JAN": 4.95,
    "FEB": 4.96,
    "MAR": 4.74,
    "APR": 4.34,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.47,
    "AUG": 4.16,
    "SEP": 3.78,
    "OCT": 3.93,
    "NOV": 4.83,
    "DEC": 4.9
  },
  {
    "LONG": -48.95,
    "LAT": -27.42,
    "CIDADE": "Major Gercino",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.87,
    "FEB": 5.05,
    "MAR": 4.8,
    "APR": 4.33,
    "MAY": 3.9,
    "JUN": 3.41,
    "JUL": 3.52,
    "AUG": 4.12,
    "SEP": 3.7,
    "OCT": 3.8,
    "NOV": 4.68,
    "DEC": 4.89
  },
  {
    "LONG": -5.25,
    "LAT": -27.25,
    "CIDADE": "Paial",
    "UF": "SANTA CATARINA",
    "anual": 4.81,
    "JAN": 5.51,
    "FEB": 5.53,
    "MAR": 5.48,
    "APR": 4.87,
    "MAY": 3.94,
    "JUN": 3.33,
    "JUL": 3.75,
    "AUG": 4.63,
    "SEP": 4.39,
    "OCT": 5.04,
    "NOV": 5.64,
    "DEC": 5.67
  },
  {
    "LONG": -52.33,
    "LAT": -27.28,
    "CIDADE": "Itá",
    "UF": "SANTA CATARINA",
    "anual": 4.81,
    "JAN": 5.57,
    "FEB": 5.59,
    "MAR": 5.48,
    "APR": 4.78,
    "MAY": 3.95,
    "JUN": 3.34,
    "JUL": 3.75,
    "AUG": 4.6,
    "SEP": 4.38,
    "OCT": 5.01,
    "NOV": 5.63,
    "DEC": 5.68
  },
  {
    "LONG": -51.61,
    "LAT": -27.35,
    "CIDADE": "Capinzal",
    "UF": "SANTA CATARINA",
    "anual": 4.8,
    "JAN": 5.34,
    "FEB": 5.32,
    "MAR": 5.38,
    "APR": 4.87,
    "MAY": 4.02,
    "JUN": 3.61,
    "JUL": 3.96,
    "AUG": 4.69,
    "SEP": 4.43,
    "OCT": 4.9,
    "NOV": 5.57,
    "DEC": 5.52
  },
  {
    "LONG": -51.62,
    "LAT": -27.34,
    "CIDADE": "Ouro",
    "UF": "SANTA CATARINA",
    "anual": 4.8,
    "JAN": 5.34,
    "FEB": 5.32,
    "MAR": 5.38,
    "APR": 4.87,
    "MAY": 4.02,
    "JUN": 3.61,
    "JUL": 3.96,
    "AUG": 4.69,
    "SEP": 4.43,
    "OCT": 4.9,
    "NOV": 5.57,
    "DEC": 5.52
  },
  {
    "LONG": -51.56,
    "LAT": -27.26,
    "CIDADE": "Lacerdópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.37,
    "FEB": 5.36,
    "MAR": 5.41,
    "APR": 4.91,
    "MAY": 4.01,
    "JUN": 3.65,
    "JUL": 3.96,
    "AUG": 4.68,
    "SEP": 4.45,
    "OCT": 4.93,
    "NOV": 5.6,
    "DEC": 5.53
  },
  {
    "LONG": -51.44,
    "LAT": -27.27,
    "CIDADE": "Erval Velho",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.39,
    "FEB": 5.33,
    "MAR": 5.38,
    "APR": 4.95,
    "MAY": 4.01,
    "JUN": 3.67,
    "JUL": 3.93,
    "AUG": 4.71,
    "SEP": 4.43,
    "OCT": 4.91,
    "NOV": 5.58,
    "DEC": 5.53
  },
  {
    "LONG": -50.84,
    "LAT": -27.31,
    "CIDADE": "Brunópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.59,
    "JAN": 5.09,
    "FEB": 5.17,
    "MAR": 5.03,
    "APR": 4.6,
    "MAY": 3.83,
    "JUN": 3.5,
    "JUL": 3.8,
    "AUG": 4.62,
    "SEP": 4.26,
    "OCT": 4.58,
    "NOV": 5.28,
    "DEC": 5.31
  },
  {
    "LONG": -50.58,
    "LAT": -27.28,
    "CIDADE": "Curitibanos",
    "UF": "SANTA CATARINA",
    "anual": 4.55,
    "JAN": 5.05,
    "FEB": 5.12,
    "MAR": 4.95,
    "APR": 4.55,
    "MAY": 3.78,
    "JUN": 3.55,
    "JUL": 3.78,
    "AUG": 4.6,
    "SEP": 4.19,
    "OCT": 4.49,
    "NOV": 5.25,
    "DEC": 5.25
  },
  {
    "LONG": -50.44,
    "LAT": -27.27,
    "CIDADE": "São Cristovão do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.48,
    "JAN": 4.95,
    "FEB": 5.07,
    "MAR": 4.84,
    "APR": 4.48,
    "MAY": 3.76,
    "JUN": 3.54,
    "JUL": 3.69,
    "AUG": 4.57,
    "SEP": 4.13,
    "OCT": 4.4,
    "NOV": 5.14,
    "DEC": 5.16
  },
  {
    "LONG": -49.93,
    "LAT": -27.26,
    "CIDADE": "Pouso Redondo",
    "UF": "SANTA CATARINA",
    "anual": 4.23,
    "JAN": 4.94,
    "FEB": 5.06,
    "MAR": 4.82,
    "APR": 4.26,
    "MAY": 3.67,
    "JUN": 3.08,
    "JUL": 3.29,
    "AUG": 4.02,
    "SEP": 3.78,
    "OCT": 4.01,
    "NOV": 4.9,
    "DEC": 4.97
  },
  {
    "LONG": -49.71,
    "LAT": -27.27,
    "CIDADE": "Agronômica",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 5.06,
    "FEB": 5.14,
    "MAR": 4.92,
    "APR": 4.31,
    "MAY": 3.69,
    "JUN": 3.09,
    "JUL": 3.25,
    "AUG": 4.07,
    "SEP": 3.84,
    "OCT": 4.08,
    "NOV": 4.95,
    "DEC": 5.09
  },
  {
    "LONG": -49.79,
    "LAT": -27.3,
    "CIDADE": "Trombudo Central",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 5.06,
    "FEB": 5.14,
    "MAR": 4.92,
    "APR": 4.31,
    "MAY": 3.69,
    "JUN": 3.09,
    "JUL": 3.25,
    "AUG": 4.07,
    "SEP": 3.84,
    "OCT": 4.08,
    "NOV": 4.95,
    "DEC": 5.09
  },
  {
    "LONG": "#VALOR!",
    "LAT": -27.31,
    "CIDADE": "Aurora",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 5.04,
    "FEB": 5.1,
    "MAR": 4.89,
    "APR": 4.3,
    "MAY": 3.69,
    "JUN": 3.12,
    "JUL": 3.3,
    "AUG": 4.1,
    "SEP": 3.85,
    "OCT": 4.08,
    "NOV": 4.95,
    "DEC": 5.05
  },
  {
    "LONG": -49.39,
    "LAT": -27.28,
    "CIDADE": "Presidente Nereu",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.91,
    "FEB": 4.93,
    "MAR": 4.75,
    "APR": 4.33,
    "MAY": 3.76,
    "JUN": 3.26,
    "JUL": 3.45,
    "AUG": 4.14,
    "SEP": 3.74,
    "OCT": 3.9,
    "NOV": 4.78,
    "DEC": 4.88
  },
  {
    "LONG": -48.93,
    "LAT": -27.28,
    "CIDADE": "Nova Trento",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.88,
    "FEB": 5.04,
    "MAR": 4.83,
    "APR": 4.32,
    "MAY": 3.92,
    "JUN": 3.39,
    "JUL": 3.5,
    "AUG": 4.08,
    "SEP": 3.66,
    "OCT": 3.77,
    "NOV": 4.64,
    "DEC": 4.86
  },
  {
    "LONG": -48.85,
    "LAT": -27.28,
    "CIDADE": "São João Batista",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 4.94,
    "FEB": 5.08,
    "MAR": 4.82,
    "APR": 4.37,
    "MAY": 3.96,
    "JUN": 3.41,
    "JUL": 3.53,
    "AUG": 4.1,
    "SEP": 3.75,
    "OCT": 3.86,
    "NOV": 4.71,
    "DEC": 4.93
  },
  {
    "LONG": -48.77,
    "LAT": -27.26,
    "CIDADE": "Canelinha",
    "UF": "SANTA CATARINA",
    "anual": 4.27,
    "JAN": 4.94,
    "FEB": 5.04,
    "MAR": 4.74,
    "APR": 4.32,
    "MAY": 3.93,
    "JUN": 3.38,
    "JUL": 3.53,
    "AUG": 4.07,
    "SEP": 3.75,
    "OCT": 3.9,
    "NOV": 4.72,
    "DEC": 4.91
  },
  {
    "LONG": -4.86,
    "LAT": -27.32,
    "CIDADE": "Governador Celso Ramos",
    "UF": "SANTA CATARINA",
    "anual": 4.48,
    "JAN": 5.24,
    "FEB": 5.31,
    "MAR": 5.04,
    "APR": 4.51,
    "MAY": 4.01,
    "JUN": 3.49,
    "JUL": 3.61,
    "AUG": 4.16,
    "SEP": 3.92,
    "OCT": 4.23,
    "NOV": 5.06,
    "DEC": 5.16
  },
  {
    "LONG": -53.72,
    "LAT": -27.17,
    "CIDADE": "Itapiranga",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.65,
    "FEB": 5.6,
    "MAR": 5.5,
    "APR": 4.8,
    "MAY": 4.02,
    "JUN": 3.4,
    "JUL": 3.82,
    "AUG": 4.61,
    "SEP": 4.39,
    "OCT": 5.09,
    "NOV": 5.56,
    "DEC": 5.64
  },
  {
    "LONG": -52.88,
    "LAT": -27.16,
    "CIDADE": "Caxambu do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.54,
    "FEB": 5.57,
    "MAR": 5.56,
    "APR": 4.91,
    "MAY": 4.09,
    "JUN": 3.56,
    "JUL": 3.92,
    "AUG": 4.69,
    "SEP": 4.41,
    "OCT": 5.09,
    "NOV": 5.58,
    "DEC": 5.68
  },
  {
    "LONG": -52.3,
    "LAT": -27.16,
    "CIDADE": "Seara",
    "UF": "SANTA CATARINA",
    "anual": 4.81,
    "JAN": 5.47,
    "FEB": 5.48,
    "MAR": 5.44,
    "APR": 4.82,
    "MAY": 4.01,
    "JUN": 3.45,
    "JUL": 3.83,
    "AUG": 4.68,
    "SEP": 4.4,
    "OCT": 4.99,
    "NOV": 5.57,
    "DEC": 5.56
  },
  {
    "LONG": -52.14,
    "LAT": -27.16,
    "CIDADE": "Arabutã",
    "UF": "SANTA CATARINA",
    "anual": 4.8,
    "JAN": 5.44,
    "FEB": 5.39,
    "MAR": 5.44,
    "APR": 4.82,
    "MAY": 4.04,
    "JUN": 3.54,
    "JUL": 3.86,
    "AUG": 4.71,
    "SEP": 4.4,
    "OCT": 4.96,
    "NOV": 5.55,
    "DEC": 5.51
  },
  {
    "LONG": -52.03,
    "LAT": -27.23,
    "CIDADE": "Concórdia",
    "UF": "SANTA CATARINA",
    "anual": 4.81,
    "JAN": 5.4,
    "FEB": 5.35,
    "MAR": 5.39,
    "APR": 4.86,
    "MAY": 4.06,
    "JUN": 3.62,
    "JUL": 3.93,
    "AUG": 4.73,
    "SEP": 4.42,
    "OCT": 4.93,
    "NOV": 5.53,
    "DEC": 5.49
  },
  {
    "LONG": -51.8,
    "LAT": -27.23,
    "CIDADE": "Presidente Castello Branco",
    "UF": "SANTA CATARINA",
    "anual": 4.78,
    "JAN": 5.24,
    "FEB": 5.27,
    "MAR": 5.3,
    "APR": 4.83,
    "MAY": 4.04,
    "JUN": 3.66,
    "JUL": 3.97,
    "AUG": 4.72,
    "SEP": 4.43,
    "OCT": 4.88,
    "NOV": 5.51,
    "DEC": 5.43
  },
  {
    "LONG": -51.74,
    "LAT": -27.17,
    "CIDADE": "Jaborá",
    "UF": "SANTA CATARINA",
    "anual": 4.77,
    "JAN": 5.26,
    "FEB": 5.25,
    "MAR": 5.32,
    "APR": 4.82,
    "MAY": 4.05,
    "JUN": 3.66,
    "JUL": 3.98,
    "AUG": 4.71,
    "SEP": 4.44,
    "OCT": 4.88,
    "NOV": 5.51,
    "DEC": 5.41
  },
  {
    "LONG": -51.51,
    "LAT": -27.17,
    "CIDADE": "Joaçaba",
    "UF": "SANTA CATARINA",
    "anual": 4.75,
    "JAN": 5.23,
    "FEB": 5.2,
    "MAR": 5.31,
    "APR": 4.85,
    "MAY": 4,
    "JUN": 3.63,
    "JUL": 3.92,
    "AUG": 4.72,
    "SEP": 4.43,
    "OCT": 4.85,
    "NOV": 5.52,
    "DEC": 5.39
  },
  {
    "LONG": -51.49,
    "LAT": -27.19,
    "CIDADE": "Herval d'Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.78,
    "JAN": 5.24,
    "FEB": 5.28,
    "MAR": 5.33,
    "APR": 4.89,
    "MAY": 3.99,
    "JUN": 3.65,
    "JUL": 3.9,
    "AUG": 4.72,
    "SEP": 4.42,
    "OCT": 4.88,
    "NOV": 5.56,
    "DEC": 5.43
  },
  {
    "LONG": -51.24,
    "LAT": -27.19,
    "CIDADE": "Ibiam",
    "UF": "SANTA CATARINA",
    "anual": 4.73,
    "JAN": 5.27,
    "FEB": 5.28,
    "MAR": 5.27,
    "APR": 4.8,
    "MAY": 3.94,
    "JUN": 3.64,
    "JUL": 3.86,
    "AUG": 4.71,
    "SEP": 4.36,
    "OCT": 4.79,
    "NOV": 5.47,
    "DEC": 5.42
  },
  {
    "LONG": -50.98,
    "LAT": -27.22,
    "CIDADE": "Monte Carlo",
    "UF": "SANTA CATARINA",
    "anual": 4.62,
    "JAN": 5.11,
    "FEB": 5.15,
    "MAR": 5.06,
    "APR": 4.61,
    "MAY": 3.87,
    "JUN": 3.58,
    "JUL": 3.87,
    "AUG": 4.65,
    "SEP": 4.26,
    "OCT": 4.63,
    "NOV": 5.31,
    "DEC": 5.29
  },
  {
    "LONG": -50.81,
    "LAT": -27.18,
    "CIDADE": "Frei Rogério",
    "UF": "SANTA CATARINA",
    "anual": 4.59,
    "JAN": 5.05,
    "FEB": 5.12,
    "MAR": 5.02,
    "APR": 4.57,
    "MAY": 3.85,
    "JUN": 3.57,
    "JUL": 3.86,
    "AUG": 4.66,
    "SEP": 4.25,
    "OCT": 4.58,
    "NOV": 5.28,
    "DEC": 5.28
  },
  {
    "LONG": -50.47,
    "LAT": -27.16,
    "CIDADE": "Ponte Alta do Norte",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 4.95,
    "FEB": 5.06,
    "MAR": 4.88,
    "APR": 4.47,
    "MAY": 3.78,
    "JUN": 3.53,
    "JUL": 3.75,
    "AUG": 4.57,
    "SEP": 4.14,
    "OCT": 4.41,
    "NOV": 5.18,
    "DEC": 5.16
  },
  {
    "LONG": -50.07,
    "LAT": -27.2,
    "CIDADE": "Mirim Doce",
    "UF": "SANTA CATARINA",
    "anual": 4.2,
    "JAN": 4.87,
    "FEB": 4.96,
    "MAR": 4.79,
    "APR": 4.26,
    "MAY": 3.66,
    "JUN": 3.08,
    "JUL": 3.25,
    "AUG": 4.06,
    "SEP": 3.74,
    "OCT": 3.93,
    "NOV": 4.88,
    "DEC": 4.92
  },
  {
    "LONG": -49.8,
    "LAT": -27.2,
    "CIDADE": "Rio do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.98,
    "FEB": 5.02,
    "MAR": 4.91,
    "APR": 4.31,
    "MAY": 3.67,
    "JUN": 3.06,
    "JUL": 3.18,
    "AUG": 4.05,
    "SEP": 3.82,
    "OCT": 4.06,
    "NOV": 4.95,
    "DEC": 5.03
  },
  {
    "LONG": -49.73,
    "LAT": -27.22,
    "CIDADE": "Laurentino",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.97,
    "FEB": 5.03,
    "MAR": 4.88,
    "APR": 4.29,
    "MAY": 3.66,
    "JUN": 3.04,
    "JUL": 3.23,
    "AUG": 4.06,
    "SEP": 3.81,
    "OCT": 4.06,
    "NOV": 4.95,
    "DEC": 5.02
  },
  {
    "LONG": -49.64,
    "LAT": -27.22,
    "CIDADE": "Rio do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.26,
    "JAN": 4.98,
    "FEB": 5.03,
    "MAR": 4.86,
    "APR": 4.3,
    "MAY": 3.68,
    "JUN": 3.1,
    "JUL": 3.29,
    "AUG": 4.1,
    "SEP": 3.81,
    "OCT": 4.03,
    "NOV": 4.92,
    "DEC": 5.02
  },
  {
    "LONG": -49.54,
    "LAT": -27.17,
    "CIDADE": "Lontras",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 5.01,
    "FEB": 5.05,
    "MAR": 4.9,
    "APR": 4.34,
    "MAY": 3.73,
    "JUN": 3.16,
    "JUL": 3.35,
    "AUG": 4.1,
    "SEP": 3.8,
    "OCT": 4.02,
    "NOV": 4.91,
    "DEC": 4.99
  },
  {
    "LONG": -49.07,
    "LAT": -27.2,
    "CIDADE": "Botuverá",
    "UF": "SANTA CATARINA",
    "anual": 4.17,
    "JAN": 4.79,
    "FEB": 4.92,
    "MAR": 4.67,
    "APR": 4.26,
    "MAY": 3.88,
    "JUN": 3.33,
    "JUL": 3.49,
    "AUG": 4.07,
    "SEP": 3.64,
    "OCT": 3.7,
    "NOV": 4.57,
    "DEC": 4.77
  },
  {
    "LONG": -48.63,
    "LAT": -27.24,
    "CIDADE": "Tijucas",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 5.01,
    "FEB": 5.06,
    "MAR": 4.77,
    "APR": 4.29,
    "MAY": 3.9,
    "JUN": 3.4,
    "JUL": 3.56,
    "AUG": 4.05,
    "SEP": 3.73,
    "OCT": 3.9,
    "NOV": 4.73,
    "DEC": 4.93
  },
  {
    "LONG": -48.55,
    "LAT": -27.16,
    "CIDADE": "Porto Belo",
    "UF": "SANTA CATARINA",
    "anual": 4.43,
    "JAN": 5.2,
    "FEB": 5.25,
    "MAR": 5,
    "APR": 4.46,
    "MAY": 3.97,
    "JUN": 3.46,
    "JUL": 3.58,
    "AUG": 4.11,
    "SEP": 3.87,
    "OCT": 4.19,
    "NOV": 5,
    "DEC": 5.13
  },
  {
    "LONG": -53.6,
    "LAT": -27.1,
    "CIDADE": "São João do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.56,
    "FEB": 5.51,
    "MAR": 5.43,
    "APR": 4.81,
    "MAY": 4.04,
    "JUN": 3.51,
    "JUL": 3.87,
    "AUG": 4.67,
    "SEP": 4.41,
    "OCT": 5.05,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -53.4,
    "LAT": -27.1,
    "CIDADE": "Mondaí",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.57,
    "FEB": 5.52,
    "MAR": 5.44,
    "APR": 4.79,
    "MAY": 3.99,
    "JUN": 3.47,
    "JUL": 3.83,
    "AUG": 4.65,
    "SEP": 4.42,
    "OCT": 5.06,
    "NOV": 5.56,
    "DEC": 5.58
  },
  {
    "LONG": -53.33,
    "LAT": -27.07,
    "CIDADE": "Riqueza",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.63,
    "FEB": 5.58,
    "MAR": 5.5,
    "APR": 4.79,
    "MAY": 3.96,
    "JUN": 3.45,
    "JUL": 3.82,
    "AUG": 4.63,
    "SEP": 4.39,
    "OCT": 5.06,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -53.25,
    "LAT": -27.07,
    "CIDADE": "Caibi",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.55,
    "FEB": 5.59,
    "MAR": 5.47,
    "APR": 4.84,
    "MAY": 4.02,
    "JUN": 3.49,
    "JUL": 3.9,
    "AUG": 4.69,
    "SEP": 4.4,
    "OCT": 5.05,
    "NOV": 5.53,
    "DEC": 5.62
  },
  {
    "LONG": -53.16,
    "LAT": -27.07,
    "CIDADE": "Palmitos",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.5,
    "FEB": 5.55,
    "MAR": 5.44,
    "APR": 4.85,
    "MAY": 4.04,
    "JUN": 3.52,
    "JUL": 3.91,
    "AUG": 4.7,
    "SEP": 4.4,
    "OCT": 5.04,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -53,
    "LAT": -27.08,
    "CIDADE": "São Carlos",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.51,
    "FEB": 5.53,
    "MAR": 5.44,
    "APR": 4.87,
    "MAY": 4.03,
    "JUN": 3.53,
    "JUL": 3.88,
    "AUG": 4.67,
    "SEP": 4.41,
    "OCT": 5.06,
    "NOV": 5.56,
    "DEC": 5.61
  },
  {
    "LONG": -52.98,
    "LAT": -27.08,
    "CIDADE": "Águas de Chapecó",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.51,
    "FEB": 5.57,
    "MAR": 5.56,
    "APR": 4.91,
    "MAY": 4.06,
    "JUN": 3.54,
    "JUL": 3.87,
    "AUG": 4.7,
    "SEP": 4.41,
    "OCT": 5.09,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -52.87,
    "LAT": -27.07,
    "CIDADE": "Planalto Alegre",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.5,
    "FEB": 5.55,
    "MAR": 5.56,
    "APR": 4.92,
    "MAY": 4.08,
    "JUN": 3.59,
    "JUL": 3.92,
    "AUG": 4.71,
    "SEP": 4.41,
    "OCT": 5.07,
    "NOV": 5.59,
    "DEC": 5.65
  },
  {
    "LONG": -52.79,
    "LAT": -27.13,
    "CIDADE": "Guatambú",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.48,
    "FEB": 5.48,
    "MAR": 5.49,
    "APR": 4.91,
    "MAY": 4.12,
    "JUN": 3.64,
    "JUL": 3.97,
    "AUG": 4.72,
    "SEP": 4.42,
    "OCT": 5.05,
    "NOV": 5.55,
    "DEC": 5.6
  },
  {
    "LONG": -52.62,
    "LAT": -27.1,
    "CIDADE": "Chapecó",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.46,
    "FEB": 5.45,
    "MAR": 5.42,
    "APR": 4.88,
    "MAY": 4.12,
    "JUN": 3.64,
    "JUL": 3.97,
    "AUG": 4.74,
    "SEP": 4.44,
    "OCT": 5.07,
    "NOV": 5.57,
    "DEC": 5.58
  },
  {
    "LONG": -52.45,
    "LAT": -27.08,
    "CIDADE": "Arvoredo",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.51,
    "FEB": 5.48,
    "MAR": 5.46,
    "APR": 4.85,
    "MAY": 4.06,
    "JUN": 3.56,
    "JUL": 3.91,
    "AUG": 4.71,
    "SEP": 4.42,
    "OCT": 5.03,
    "NOV": 5.57,
    "DEC": 5.55
  },
  {
    "LONG": -52.34,
    "LAT": -27.07,
    "CIDADE": "Xavantina",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.38,
    "FEB": 5.37,
    "MAR": 5.41,
    "APR": 4.87,
    "MAY": 4.09,
    "JUN": 3.62,
    "JUL": 3.96,
    "AUG": 4.74,
    "SEP": 4.45,
    "OCT": 5,
    "NOV": 5.54,
    "DEC": 5.51
  },
  {
    "LONG": -52.13,
    "LAT": -27.08,
    "CIDADE": "Ipumirim",
    "UF": "SANTA CATARINA",
    "anual": 4.78,
    "JAN": 5.35,
    "FEB": 5.23,
    "MAR": 5.34,
    "APR": 4.83,
    "MAY": 4.05,
    "JUN": 3.64,
    "JUL": 3.94,
    "AUG": 4.77,
    "SEP": 4.42,
    "OCT": 4.91,
    "NOV": 5.48,
    "DEC": 5.44
  },
  {
    "LONG": -52.08,
    "LAT": -27.05,
    "CIDADE": "Lindóia do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.77,
    "JAN": 5.26,
    "FEB": 5.26,
    "MAR": 5.3,
    "APR": 4.85,
    "MAY": 4.04,
    "JUN": 3.65,
    "JUL": 3.97,
    "AUG": 4.75,
    "SEP": 4.42,
    "OCT": 4.9,
    "NOV": 5.47,
    "DEC": 5.4
  },
  {
    "LONG": -51.66,
    "LAT": -27.07,
    "CIDADE": "Catanduvas",
    "UF": "SANTA CATARINA",
    "anual": 4.74,
    "JAN": 5.15,
    "FEB": 5.18,
    "MAR": 5.25,
    "APR": 4.8,
    "MAY": 4.04,
    "JUN": 3.69,
    "JUL": 3.97,
    "AUG": 4.74,
    "SEP": 4.45,
    "OCT": 4.81,
    "NOV": 5.45,
    "DEC": 5.29
  },
  {
    "LONG": -51.47,
    "LAT": -27.13,
    "CIDADE": "Luzerna",
    "UF": "SANTA CATARINA",
    "anual": 4.73,
    "JAN": 5.16,
    "FEB": 5.21,
    "MAR": 5.28,
    "APR": 4.82,
    "MAY": 3.99,
    "JUN": 3.67,
    "JUL": 3.89,
    "AUG": 4.71,
    "SEP": 4.43,
    "OCT": 4.82,
    "NOV": 5.46,
    "DEC": 5.36
  },
  {
    "LONG": -51.37,
    "LAT": -27.09,
    "CIDADE": "Ibicaré",
    "UF": "SANTA CATARINA",
    "anual": 4.74,
    "JAN": 5.22,
    "FEB": 5.23,
    "MAR": 5.29,
    "APR": 4.8,
    "MAY": 3.98,
    "JUN": 3.66,
    "JUL": 3.85,
    "AUG": 4.71,
    "SEP": 4.41,
    "OCT": 4.83,
    "NOV": 5.49,
    "DEC": 5.4
  },
  {
    "LONG": -51.25,
    "LAT": -27.1,
    "CIDADE": "Tangará",
    "UF": "SANTA CATARINA",
    "anual": 4.7,
    "JAN": 5.18,
    "FEB": 5.24,
    "MAR": 5.23,
    "APR": 4.79,
    "MAY": 3.9,
    "JUN": 3.61,
    "JUL": 3.84,
    "AUG": 4.71,
    "SEP": 4.35,
    "OCT": 4.77,
    "NOV": 5.42,
    "DEC": 5.41
  },
  {
    "LONG": -50,
    "LAT": -27.12,
    "CIDADE": "Taió",
    "UF": "SANTA CATARINA",
    "anual": 4.19,
    "JAN": 4.81,
    "FEB": 4.88,
    "MAR": 4.81,
    "APR": 4.25,
    "MAY": 3.63,
    "JUN": 3.11,
    "JUL": 3.29,
    "AUG": 4.08,
    "SEP": 3.78,
    "OCT": 3.94,
    "NOV": 4.87,
    "DEC": 4.88
  },
  {
    "LONG": -49.52,
    "LAT": -27.06,
    "CIDADE": "Ibirama",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.99,
    "FEB": 5.08,
    "MAR": 4.92,
    "APR": 4.34,
    "MAY": 3.74,
    "JUN": 3.13,
    "JUL": 3.35,
    "AUG": 4.09,
    "SEP": 3.8,
    "OCT": 4.03,
    "NOV": 4.92,
    "DEC": 4.99
  },
  {
    "LONG": -48.98,
    "LAT": -27.08,
    "CIDADE": "Guabiruba",
    "UF": "SANTA CATARINA",
    "anual": 4.2,
    "JAN": 4.88,
    "FEB": 5.01,
    "MAR": 4.8,
    "APR": 4.26,
    "MAY": 3.88,
    "JUN": 3.32,
    "JUL": 3.46,
    "AUG": 3.97,
    "SEP": 3.64,
    "OCT": 3.76,
    "NOV": 4.58,
    "DEC": 4.83
  },
  {
    "LONG": -48.91,
    "LAT": -27.1,
    "CIDADE": "Brusque",
    "UF": "SANTA CATARINA",
    "anual": 4.2,
    "JAN": 4.88,
    "FEB": 5.01,
    "MAR": 4.8,
    "APR": 4.26,
    "MAY": 3.88,
    "JUN": 3.32,
    "JUL": 3.46,
    "AUG": 3.97,
    "SEP": 3.64,
    "OCT": 3.76,
    "NOV": 4.58,
    "DEC": 4.83
  },
  {
    "LONG": -48.62,
    "LAT": -27.09,
    "CIDADE": "Itapema",
    "UF": "SANTA CATARINA",
    "anual": 4.26,
    "JAN": 4.94,
    "FEB": 5.05,
    "MAR": 4.78,
    "APR": 4.31,
    "MAY": 3.88,
    "JUN": 3.37,
    "JUL": 3.5,
    "AUG": 3.99,
    "SEP": 3.73,
    "OCT": 3.9,
    "NOV": 4.72,
    "DEC": 4.9
  },
  {
    "LONG": -4.85,
    "LAT": -27.14,
    "CIDADE": "Bombinhas",
    "UF": "SANTA CATARINA",
    "anual": 4.46,
    "JAN": 5.23,
    "FEB": 5.3,
    "MAR": 5.06,
    "APR": 4.51,
    "MAY": 3.98,
    "JUN": 3.47,
    "JUL": 3.55,
    "AUG": 4.08,
    "SEP": 3.86,
    "OCT": 4.26,
    "NOV": 5.05,
    "DEC": 5.18
  },
  {
    "LONG": -53.64,
    "LAT": -26.97,
    "CIDADE": "Tunápolis",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.58,
    "FEB": 5.53,
    "MAR": 5.43,
    "APR": 4.84,
    "MAY": 4.05,
    "JUN": 3.55,
    "JUL": 3.88,
    "AUG": 4.68,
    "SEP": 4.41,
    "OCT": 5.05,
    "NOV": 5.49,
    "DEC": 5.54
  },
  {
    "LONG": -53.54,
    "LAT": -26.99,
    "CIDADE": "Iporã do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.58,
    "FEB": 5.52,
    "MAR": 5.41,
    "APR": 4.87,
    "MAY": 4.06,
    "JUN": 3.57,
    "JUL": 3.92,
    "AUG": 4.69,
    "SEP": 4.44,
    "OCT": 5.05,
    "NOV": 5.5,
    "DEC": 5.54
  },
  {
    "LONG": -53.09,
    "LAT": -26.97,
    "CIDADE": "Cunhataí",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.47,
    "FEB": 5.46,
    "MAR": 5.41,
    "APR": 4.88,
    "MAY": 4.07,
    "JUN": 3.58,
    "JUL": 3.92,
    "AUG": 4.72,
    "SEP": 4.44,
    "OCT": 5.06,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -5.26,
    "LAT": -26.98,
    "CIDADE": "Cordilheira Alta",
    "UF": "SANTA CATARINA",
    "anual": 4.89,
    "JAN": 5.51,
    "FEB": 5.48,
    "MAR": 5.48,
    "APR": 4.92,
    "MAY": 4.12,
    "JUN": 3.69,
    "JUL": 3.98,
    "AUG": 4.76,
    "SEP": 4.46,
    "OCT": 5.08,
    "NOV": 5.59,
    "DEC": 5.6
  },
  {
    "LONG": -52.54,
    "LAT": -26.96,
    "CIDADE": "Xaxim",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.48,
    "FEB": 5.46,
    "MAR": 5.45,
    "APR": 4.89,
    "MAY": 4.09,
    "JUN": 3.65,
    "JUL": 3.96,
    "AUG": 4.75,
    "SEP": 4.45,
    "OCT": 5.06,
    "NOV": 5.57,
    "DEC": 5.57
  },
  {
    "LONG": -51.9,
    "LAT": -27.03,
    "CIDADE": "Irani",
    "UF": "SANTA CATARINA",
    "anual": 4.76,
    "JAN": 5.21,
    "FEB": 5.19,
    "MAR": 5.25,
    "APR": 4.86,
    "MAY": 4.05,
    "JUN": 3.71,
    "JUL": 3.99,
    "AUG": 4.78,
    "SEP": 4.45,
    "OCT": 4.88,
    "NOV": 5.42,
    "DEC": 5.35
  },
  {
    "LONG": -51.74,
    "LAT": -27.01,
    "CIDADE": "Vargem Bonita",
    "UF": "SANTA CATARINA",
    "anual": 4.75,
    "JAN": 5.13,
    "FEB": 5.16,
    "MAR": 5.26,
    "APR": 4.82,
    "MAY": 4.03,
    "JUN": 3.75,
    "JUL": 4.01,
    "AUG": 4.79,
    "SEP": 4.47,
    "OCT": 4.85,
    "NOV": 5.41,
    "DEC": 5.3
  },
  {
    "LONG": -51.55,
    "LAT": -27,
    "CIDADE": "Água Doce",
    "UF": "SANTA CATARINA",
    "anual": 4.71,
    "JAN": 5.06,
    "FEB": 5.06,
    "MAR": 5.2,
    "APR": 4.8,
    "MAY": 4,
    "JUN": 3.74,
    "JUL": 3.96,
    "AUG": 4.79,
    "SEP": 4.45,
    "OCT": 4.79,
    "NOV": 5.39,
    "DEC": 5.28
  },
  {
    "LONG": -51.41,
    "LAT": -27,
    "CIDADE": "Treze Tílias",
    "UF": "SANTA CATARINA",
    "anual": 4.7,
    "JAN": 5.08,
    "FEB": 5.08,
    "MAR": 5.2,
    "APR": 4.77,
    "MAY": 3.99,
    "JUN": 3.73,
    "JUL": 3.92,
    "AUG": 4.77,
    "SEP": 4.43,
    "OCT": 4.79,
    "NOV": 5.39,
    "DEC": 5.3
  },
  {
    "LONG": -51.24,
    "LAT": -27,
    "CIDADE": "Iomerê",
    "UF": "SANTA CATARINA",
    "anual": 4.65,
    "JAN": 5.12,
    "FEB": 5.12,
    "MAR": 5.14,
    "APR": 4.69,
    "MAY": 3.9,
    "JUN": 3.61,
    "JUL": 3.87,
    "AUG": 4.71,
    "SEP": 4.35,
    "OCT": 4.69,
    "NOV": 5.35,
    "DEC": 5.29
  },
  {
    "LONG": -51.23,
    "LAT": -27.04,
    "CIDADE": "Pinheiro Preto",
    "UF": "SANTA CATARINA",
    "anual": 4.65,
    "JAN": 5.12,
    "FEB": 5.12,
    "MAR": 5.14,
    "APR": 4.69,
    "MAY": 3.9,
    "JUN": 3.61,
    "JUL": 3.87,
    "AUG": 4.71,
    "SEP": 4.35,
    "OCT": 4.69,
    "NOV": 5.35,
    "DEC": 5.29
  },
  {
    "LONG": -51.15,
    "LAT": -27.01,
    "CIDADE": "Videira",
    "UF": "SANTA CATARINA",
    "anual": 4.64,
    "JAN": 5.12,
    "FEB": 5.1,
    "MAR": 5.15,
    "APR": 4.67,
    "MAY": 3.9,
    "JUN": 3.57,
    "JUL": 3.86,
    "AUG": 4.7,
    "SEP": 4.33,
    "OCT": 4.69,
    "NOV": 5.32,
    "DEC": 5.27
  },
  {
    "LONG": -50.92,
    "LAT": -27.02,
    "CIDADE": "Fraiburgo",
    "UF": "SANTA CATARINA",
    "anual": 4.6,
    "JAN": 5.07,
    "FEB": 5.05,
    "MAR": 5.06,
    "APR": 4.58,
    "MAY": 3.86,
    "JUN": 3.6,
    "JUL": 3.87,
    "AUG": 4.68,
    "SEP": 4.28,
    "OCT": 4.6,
    "NOV": 5.26,
    "DEC": 5.23
  },
  {
    "LONG": -50.43,
    "LAT": -26.96,
    "CIDADE": "Santa Cecília",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 4.91,
    "FEB": 4.97,
    "MAR": 4.85,
    "APR": 4.49,
    "MAY": 3.79,
    "JUN": 3.58,
    "JUL": 3.8,
    "AUG": 4.66,
    "SEP": 4.18,
    "OCT": 4.42,
    "NOV": 5.11,
    "DEC": 5.1
  },
  {
    "LONG": -50,
    "LAT": -26.98,
    "CIDADE": "Salete",
    "UF": "SANTA CATARINA",
    "anual": 4.17,
    "JAN": 4.72,
    "FEB": 4.82,
    "MAR": 4.72,
    "APR": 4.19,
    "MAY": 3.62,
    "JUN": 3.16,
    "JUL": 3.37,
    "AUG": 4.16,
    "SEP": 3.8,
    "OCT": 3.92,
    "NOV": 4.8,
    "DEC": 4.81
  },
  {
    "LONG": -49.73,
    "LAT": -26.98,
    "CIDADE": "Dona Emma",
    "UF": "SANTA CATARINA",
    "anual": 4.23,
    "JAN": 4.88,
    "FEB": 4.98,
    "MAR": 4.82,
    "APR": 4.24,
    "MAY": 3.67,
    "JUN": 3.09,
    "JUL": 3.32,
    "AUG": 4.12,
    "SEP": 3.8,
    "OCT": 4.03,
    "NOV": 4.87,
    "DEC": 4.92
  },
  {
    "LONG": -49.63,
    "LAT": -2.7,
    "CIDADE": "José Boiteux",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.92,
    "FEB": 5.01,
    "MAR": 4.85,
    "APR": 4.26,
    "MAY": 3.67,
    "JUN": 3.1,
    "JUL": 3.32,
    "AUG": 4.11,
    "SEP": 3.8,
    "OCT": 4.05,
    "NOV": 4.88,
    "DEC": 4.95
  },
  {
    "LONG": -4.96,
    "LAT": -27.04,
    "CIDADE": "Presidente Getúlio",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.92,
    "FEB": 5.01,
    "MAR": 4.85,
    "APR": 4.26,
    "MAY": 3.67,
    "JUN": 3.1,
    "JUL": 3.32,
    "AUG": 4.11,
    "SEP": 3.8,
    "OCT": 4.05,
    "NOV": 4.88,
    "DEC": 4.95
  },
  {
    "LONG": -49.39,
    "LAT": -2.7,
    "CIDADE": "Apiúna",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.88,
    "FEB": 4.97,
    "MAR": 4.83,
    "APR": 4.33,
    "MAY": 3.83,
    "JUN": 3.26,
    "JUL": 3.38,
    "AUG": 4.06,
    "SEP": 3.69,
    "OCT": 3.84,
    "NOV": 4.72,
    "DEC": 4.83
  },
  {
    "LONG": -49.38,
    "LAT": -26.96,
    "CIDADE": "Ascurra",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.88,
    "FEB": 4.97,
    "MAR": 4.83,
    "APR": 4.33,
    "MAY": 3.83,
    "JUN": 3.26,
    "JUL": 3.38,
    "AUG": 4.06,
    "SEP": 3.69,
    "OCT": 3.84,
    "NOV": 4.72,
    "DEC": 4.83
  },
  {
    "LONG": -48.65,
    "LAT": -27.02,
    "CIDADE": "Camboriú",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 5.09,
    "FEB": 5.2,
    "MAR": 4.89,
    "APR": 4.38,
    "MAY": 3.91,
    "JUN": 3.39,
    "JUL": 3.49,
    "AUG": 3.96,
    "SEP": 3.82,
    "OCT": 4.08,
    "NOV": 4.87,
    "DEC": 5.04
  },
  {
    "LONG": -48.64,
    "LAT": -26.99,
    "CIDADE": "Balneário Camboriú",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 5.09,
    "FEB": 5.2,
    "MAR": 4.89,
    "APR": 4.38,
    "MAY": 3.91,
    "JUN": 3.39,
    "JUL": 3.49,
    "AUG": 3.96,
    "SEP": 3.82,
    "OCT": 4.08,
    "NOV": 4.87,
    "DEC": 5.04
  },
  {
    "LONG": -53.62,
    "LAT": -26.94,
    "CIDADE": "Santa Helena",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.53,
    "FEB": 5.51,
    "MAR": 5.41,
    "APR": 4.83,
    "MAY": 4.07,
    "JUN": 3.56,
    "JUL": 3.9,
    "AUG": 4.68,
    "SEP": 4.42,
    "OCT": 5.03,
    "NOV": 5.46,
    "DEC": 5.51
  },
  {
    "LONG": -53.17,
    "LAT": -26.9,
    "CIDADE": "Cunha Porã",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.47,
    "FEB": 5.48,
    "MAR": 5.38,
    "APR": 4.92,
    "MAY": 4.12,
    "JUN": 3.61,
    "JUL": 3.96,
    "AUG": 4.74,
    "SEP": 4.46,
    "OCT": 5.02,
    "NOV": 5.46,
    "DEC": 5.52
  },
  {
    "LONG": -53,
    "LAT": -26.93,
    "CIDADE": "Saudades",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.48,
    "FEB": 5.44,
    "MAR": 5.38,
    "APR": 4.87,
    "MAY": 4.09,
    "JUN": 3.58,
    "JUL": 3.95,
    "AUG": 4.73,
    "SEP": 4.44,
    "OCT": 5.02,
    "NOV": 5.46,
    "DEC": 5.54
  },
  {
    "LONG": -5.29,
    "LAT": -26.9,
    "CIDADE": "Nova Erechim",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.44,
    "FEB": 5.43,
    "MAR": 5.45,
    "APR": 4.91,
    "MAY": 4.08,
    "JUN": 3.59,
    "JUL": 3.92,
    "AUG": 4.74,
    "SEP": 4.44,
    "OCT": 5.05,
    "NOV": 5.52,
    "DEC": 5.53
  },
  {
    "LONG": -52.81,
    "LAT": -26.94,
    "CIDADE": "Nova Itaberaba",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.49,
    "FEB": 5.49,
    "MAR": 5.5,
    "APR": 4.94,
    "MAY": 4.11,
    "JUN": 3.61,
    "JUL": 3.94,
    "AUG": 4.75,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.56,
    "DEC": 5.57
  },
  {
    "LONG": -52.86,
    "LAT": -26.88,
    "CIDADE": "Águas Frias",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.49,
    "FEB": 5.49,
    "MAR": 5.5,
    "APR": 4.94,
    "MAY": 4.11,
    "JUN": 3.61,
    "JUL": 3.94,
    "AUG": 4.75,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.56,
    "DEC": 5.57
  },
  {
    "LONG": -52.7,
    "LAT": -26.91,
    "CIDADE": "Coronel Freitas",
    "UF": "SANTA CATARINA",
    "anual": 4.9,
    "JAN": 5.51,
    "FEB": 5.51,
    "MAR": 5.54,
    "APR": 4.93,
    "MAY": 4.15,
    "JUN": 3.65,
    "JUL": 3.95,
    "AUG": 4.79,
    "SEP": 4.48,
    "OCT": 5.1,
    "NOV": 5.59,
    "DEC": 5.57
  },
  {
    "LONG": -52.57,
    "LAT": -26.86,
    "CIDADE": "Lajeado Grande",
    "UF": "SANTA CATARINA",
    "anual": 4.9,
    "JAN": 5.54,
    "FEB": 5.47,
    "MAR": 5.51,
    "APR": 4.96,
    "MAY": 4.13,
    "JUN": 3.69,
    "JUL": 4,
    "AUG": 4.8,
    "SEP": 4.48,
    "OCT": 5.06,
    "NOV": 5.58,
    "DEC": 5.55
  },
  {
    "LONG": -5.24,
    "LAT": -26.88,
    "CIDADE": "Xanxerê",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.5,
    "FEB": 5.4,
    "MAR": 5.41,
    "APR": 4.91,
    "MAY": 4.12,
    "JUN": 3.7,
    "JUL": 4.02,
    "AUG": 4.81,
    "SEP": 4.48,
    "OCT": 5.01,
    "NOV": 5.5,
    "DEC": 5.5
  },
  {
    "LONG": -52.16,
    "LAT": -26.86,
    "CIDADE": "Vargeão",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.38,
    "FEB": 5.29,
    "MAR": 5.36,
    "APR": 4.87,
    "MAY": 4.07,
    "JUN": 3.73,
    "JUL": 4.02,
    "AUG": 4.8,
    "SEP": 4.48,
    "OCT": 4.96,
    "NOV": 5.45,
    "DEC": 5.41
  },
  {
    "LONG": -52.01,
    "LAT": -26.87,
    "CIDADE": "Ponte Serrada",
    "UF": "SANTA CATARINA",
    "anual": 4.79,
    "JAN": 5.27,
    "FEB": 5.21,
    "MAR": 5.32,
    "APR": 4.88,
    "MAY": 4.08,
    "JUN": 3.73,
    "JUL": 4.02,
    "AUG": 4.81,
    "SEP": 4.48,
    "OCT": 4.93,
    "NOV": 5.43,
    "DEC": 5.38
  },
  {
    "LONG": -51.4,
    "LAT": -26.9,
    "CIDADE": "Salto Veloso",
    "UF": "SANTA CATARINA",
    "anual": 4.67,
    "JAN": 5.07,
    "FEB": 5.02,
    "MAR": 5.1,
    "APR": 4.71,
    "MAY": 3.93,
    "JUN": 3.71,
    "JUL": 3.94,
    "AUG": 4.79,
    "SEP": 4.43,
    "OCT": 4.75,
    "NOV": 5.34,
    "DEC": 5.25
  },
  {
    "LONG": -5.14,
    "LAT": -26.86,
    "CIDADE": "Macieira",
    "UF": "SANTA CATARINA",
    "anual": 4.65,
    "JAN": 5.06,
    "FEB": 5.01,
    "MAR": 5.08,
    "APR": 4.67,
    "MAY": 3.9,
    "JUN": 3.68,
    "JUL": 3.92,
    "AUG": 4.77,
    "SEP": 4.39,
    "OCT": 4.71,
    "NOV": 5.31,
    "DEC": 5.24
  },
  {
    "LONG": -51.34,
    "LAT": -26.93,
    "CIDADE": "Arroio Trinta",
    "UF": "SANTA CATARINA",
    "anual": 4.65,
    "JAN": 5.06,
    "FEB": 5.01,
    "MAR": 5.08,
    "APR": 4.67,
    "MAY": 3.9,
    "JUN": 3.68,
    "JUL": 3.92,
    "AUG": 4.77,
    "SEP": 4.39,
    "OCT": 4.71,
    "NOV": 5.31,
    "DEC": 5.24
  },
  {
    "LONG": -51.07,
    "LAT": -26.9,
    "CIDADE": "Rio das Antas",
    "UF": "SANTA CATARINA",
    "anual": 4.58,
    "JAN": 5.03,
    "FEB": 5.01,
    "MAR": 5.05,
    "APR": 4.58,
    "MAY": 3.85,
    "JUN": 3.57,
    "JUL": 3.85,
    "AUG": 4.7,
    "SEP": 4.28,
    "OCT": 4.6,
    "NOV": 5.24,
    "DEC": 5.2
  },
  {
    "LONG": -50.69,
    "LAT": -26.93,
    "CIDADE": "Lebon Régis",
    "UF": "SANTA CATARINA",
    "anual": 4.52,
    "JAN": 4.92,
    "FEB": 4.98,
    "MAR": 4.89,
    "APR": 4.5,
    "MAY": 3.8,
    "JUN": 3.6,
    "JUL": 3.86,
    "AUG": 4.69,
    "SEP": 4.27,
    "OCT": 4.49,
    "NOV": 5.14,
    "DEC": 5.13
  },
  {
    "LONG": -50.14,
    "LAT": -26.95,
    "CIDADE": "Rio do Campo",
    "UF": "SANTA CATARINA",
    "anual": 4.18,
    "JAN": 4.67,
    "FEB": 4.72,
    "MAR": 4.65,
    "APR": 4.17,
    "MAY": 3.66,
    "JUN": 3.21,
    "JUL": 3.47,
    "AUG": 4.28,
    "SEP": 3.86,
    "OCT": 3.94,
    "NOV": 4.77,
    "DEC": 4.75
  },
  {
    "LONG": -49.83,
    "LAT": -26.88,
    "CIDADE": "Vitor Meireles",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.79,
    "FEB": 4.9,
    "MAR": 4.83,
    "APR": 4.2,
    "MAY": 3.63,
    "JUN": 3.16,
    "JUL": 3.36,
    "AUG": 4.18,
    "SEP": 3.85,
    "OCT": 4.07,
    "NOV": 4.85,
    "DEC": 4.85
  },
  {
    "LONG": -49.8,
    "LAT": -2.69,
    "CIDADE": "Witmarsum",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.85,
    "FEB": 4.97,
    "MAR": 4.82,
    "APR": 4.23,
    "MAY": 3.64,
    "JUN": 3.13,
    "JUL": 3.34,
    "AUG": 4.2,
    "SEP": 3.84,
    "OCT": 4.1,
    "NOV": 4.87,
    "DEC": 4.91
  },
  {
    "LONG": -49.37,
    "LAT": -26.92,
    "CIDADE": "Rodeio",
    "UF": "SANTA CATARINA",
    "anual": 4.16,
    "JAN": 4.79,
    "FEB": 4.88,
    "MAR": 4.74,
    "APR": 4.27,
    "MAY": 3.83,
    "JUN": 3.28,
    "JUL": 3.39,
    "AUG": 4.05,
    "SEP": 3.62,
    "OCT": 3.75,
    "NOV": 4.62,
    "DEC": 4.75
  },
  {
    "LONG": -49.24,
    "LAT": -26.9,
    "CIDADE": "Indaial",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.95,
    "FEB": 5,
    "MAR": 4.85,
    "APR": 4.39,
    "MAY": 3.9,
    "JUN": 3.32,
    "JUL": 3.44,
    "AUG": 4.05,
    "SEP": 3.68,
    "OCT": 3.81,
    "NOV": 4.68,
    "DEC": 4.84
  },
  {
    "LONG": -49.07,
    "LAT": -2.69,
    "CIDADE": "Blumenau",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.9,
    "FEB": 5.02,
    "MAR": 4.8,
    "APR": 4.33,
    "MAY": 3.9,
    "JUN": 3.34,
    "JUL": 3.46,
    "AUG": 3.99,
    "SEP": 3.66,
    "OCT": 3.75,
    "NOV": 4.62,
    "DEC": 4.84
  },
  {
    "LONG": -48.95,
    "LAT": -26.93,
    "CIDADE": "Gaspar",
    "UF": "SANTA CATARINA",
    "anual": 4.17,
    "JAN": 4.85,
    "FEB": 4.96,
    "MAR": 4.74,
    "APR": 4.28,
    "MAY": 3.87,
    "JUN": 3.31,
    "JUL": 3.43,
    "AUG": 3.94,
    "SEP": 3.63,
    "OCT": 3.7,
    "NOV": 4.57,
    "DEC": 4.8
  },
  {
    "LONG": -48.83,
    "LAT": -26.9,
    "CIDADE": "Ilhota",
    "UF": "SANTA CATARINA",
    "anual": 4.19,
    "JAN": 4.88,
    "FEB": 4.97,
    "MAR": 4.75,
    "APR": 4.27,
    "MAY": 3.86,
    "JUN": 3.33,
    "JUL": 3.45,
    "AUG": 3.94,
    "SEP": 3.63,
    "OCT": 3.76,
    "NOV": 4.59,
    "DEC": 4.79
  },
  {
    "LONG": -48.67,
    "LAT": -26.91,
    "CIDADE": "Itajaí",
    "UF": "SANTA CATARINA",
    "anual": 4.35,
    "JAN": 5.13,
    "FEB": 5.21,
    "MAR": 4.92,
    "APR": 4.36,
    "MAY": 3.9,
    "JUN": 3.38,
    "JUL": 3.46,
    "AUG": 3.97,
    "SEP": 3.8,
    "OCT": 4.13,
    "NOV": 4.91,
    "DEC": 5.07
  },
  {
    "LONG": -4.87,
    "LAT": -26.89,
    "CIDADE": "Navegantes",
    "UF": "SANTA CATARINA",
    "anual": 4.35,
    "JAN": 5.13,
    "FEB": 5.21,
    "MAR": 4.92,
    "APR": 4.36,
    "MAY": 3.9,
    "JUN": 3.38,
    "JUL": 3.46,
    "AUG": 3.97,
    "SEP": 3.8,
    "OCT": 4.13,
    "NOV": 4.91,
    "DEC": 5.07
  },
  {
    "LONG": -53.64,
    "LAT": -2.68,
    "CIDADE": "Bandeirante",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.52,
    "FEB": 5.51,
    "MAR": 5.41,
    "APR": 4.84,
    "MAY": 4.09,
    "JUN": 3.6,
    "JUL": 3.92,
    "AUG": 4.7,
    "SEP": 4.46,
    "OCT": 5.04,
    "NOV": 5.45,
    "DEC": 5.49
  },
  {
    "LONG": -53.5,
    "LAT": -26.83,
    "CIDADE": "Descanso",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.57,
    "FEB": 5.49,
    "MAR": 5.38,
    "APR": 4.88,
    "MAY": 4.11,
    "JUN": 3.65,
    "JUL": 3.99,
    "AUG": 4.75,
    "SEP": 4.49,
    "OCT": 5.06,
    "NOV": 5.43,
    "DEC": 5.54
  },
  {
    "LONG": -53.58,
    "LAT": -26.84,
    "CIDADE": "Belmonte",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.57,
    "FEB": 5.49,
    "MAR": 5.38,
    "APR": 4.88,
    "MAY": 4.11,
    "JUN": 3.65,
    "JUL": 3.99,
    "AUG": 4.75,
    "SEP": 4.49,
    "OCT": 5.06,
    "NOV": 5.43,
    "DEC": 5.54
  },
  {
    "LONG": -53.35,
    "LAT": -26.78,
    "CIDADE": "Flor do Sertão",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.55,
    "FEB": 5.54,
    "MAR": 5.47,
    "APR": 4.86,
    "MAY": 4.1,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.71,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.5,
    "DEC": 5.54
  },
  {
    "LONG": -53.28,
    "LAT": -2.68,
    "CIDADE": "Iraceminha",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.5,
    "FEB": 5.55,
    "MAR": 5.44,
    "APR": 4.98,
    "MAY": 4.12,
    "JUN": 3.63,
    "JUL": 3.96,
    "AUG": 4.73,
    "SEP": 4.49,
    "OCT": 5.05,
    "NOV": 5.48,
    "DEC": 5.52
  },
  {
    "LONG": -53.17,
    "LAT": -2.68,
    "CIDADE": "Maravilha",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.48,
    "FEB": 5.48,
    "MAR": 5.38,
    "APR": 4.93,
    "MAY": 4.13,
    "JUN": 3.63,
    "JUL": 3.98,
    "AUG": 4.76,
    "SEP": 4.49,
    "OCT": 5.02,
    "NOV": 5.46,
    "DEC": 5.5
  },
  {
    "LONG": -53.04,
    "LAT": -26.77,
    "CIDADE": "Modelo",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.46,
    "FEB": 5.41,
    "MAR": 5.36,
    "APR": 4.85,
    "MAY": 4.1,
    "JUN": 3.58,
    "JUL": 3.99,
    "AUG": 4.76,
    "SEP": 4.47,
    "OCT": 5.01,
    "NOV": 5.42,
    "DEC": 5.49
  },
  {
    "LONG": -52.99,
    "LAT": "#VALOR!",
    "CIDADE": "Pinhalzinho",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.42,
    "FEB": 5.38,
    "MAR": 5.43,
    "APR": 4.89,
    "MAY": 4.09,
    "JUN": 3.57,
    "JUL": 3.95,
    "AUG": 4.77,
    "SEP": 4.47,
    "OCT": 5.02,
    "NOV": 5.49,
    "DEC": 5.5
  },
  {
    "LONG": -52.85,
    "LAT": -26.76,
    "CIDADE": "União do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.43,
    "FEB": 5.43,
    "MAR": 5.46,
    "APR": 4.91,
    "MAY": 4.12,
    "JUN": 3.6,
    "JUL": 3.97,
    "AUG": 4.78,
    "SEP": 4.47,
    "OCT": 5.03,
    "NOV": 5.53,
    "DEC": 5.52
  },
  {
    "LONG": -52.63,
    "LAT": -26.8,
    "CIDADE": "Marema",
    "UF": "SANTA CATARINA",
    "anual": 4.91,
    "JAN": 5.59,
    "FEB": 5.49,
    "MAR": 5.55,
    "APR": 5,
    "MAY": 4.12,
    "JUN": 3.67,
    "JUL": 3.97,
    "AUG": 4.8,
    "SEP": 4.5,
    "OCT": 5.06,
    "NOV": 5.6,
    "DEC": 5.54
  },
  {
    "LONG": -52.26,
    "LAT": -26.85,
    "CIDADE": "Faxinal dos Guedes",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.49,
    "FEB": 5.28,
    "MAR": 5.34,
    "APR": 4.85,
    "MAY": 4.14,
    "JUN": 3.75,
    "JUL": 4.02,
    "AUG": 4.82,
    "SEP": 4.5,
    "OCT": 5.01,
    "NOV": 5.45,
    "DEC": 5.44
  },
  {
    "LONG": -52.06,
    "LAT": -26.78,
    "CIDADE": "Passos Maia",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.29,
    "FEB": 5.25,
    "MAR": 5.36,
    "APR": 4.88,
    "MAY": 4.09,
    "JUN": 3.75,
    "JUL": 4.04,
    "AUG": 4.84,
    "SEP": 4.52,
    "OCT": 4.98,
    "NOV": 5.46,
    "DEC": 5.42
  },
  {
    "LONG": -51.01,
    "LAT": -26.78,
    "CIDADE": "Caçador",
    "UF": "SANTA CATARINA",
    "anual": 4.52,
    "JAN": 4.88,
    "FEB": 4.93,
    "MAR": 4.96,
    "APR": 4.5,
    "MAY": 3.81,
    "JUN": 3.57,
    "JUL": 3.82,
    "AUG": 4.69,
    "SEP": 4.27,
    "OCT": 4.54,
    "NOV": 5.16,
    "DEC": 5.13
  },
  {
    "LONG": -50.01,
    "LAT": -26.78,
    "CIDADE": "Santa Terezinha",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.76,
    "FEB": 4.9,
    "MAR": 4.82,
    "APR": 4.19,
    "MAY": 3.65,
    "JUN": 3.18,
    "JUL": 3.48,
    "AUG": 4.3,
    "SEP": 3.89,
    "OCT": 4.08,
    "NOV": 4.87,
    "DEC": 4.87
  },
  {
    "LONG": -49.36,
    "LAT": -26.78,
    "CIDADE": "Benedito Novo",
    "UF": "SANTA CATARINA",
    "anual": 4.16,
    "JAN": 4.73,
    "FEB": 4.79,
    "MAR": 4.7,
    "APR": 4.27,
    "MAY": 3.86,
    "JUN": 3.34,
    "JUL": 3.45,
    "AUG": 4.11,
    "SEP": 3.62,
    "OCT": 3.77,
    "NOV": 4.6,
    "DEC": 4.69
  },
  {
    "LONG": -49.27,
    "LAT": -26.83,
    "CIDADE": "Timbó",
    "UF": "SANTA CATARINA",
    "anual": 4.18,
    "JAN": 4.86,
    "FEB": 4.88,
    "MAR": 4.73,
    "APR": 4.31,
    "MAY": 3.88,
    "JUN": 3.28,
    "JUL": 3.43,
    "AUG": 4.03,
    "SEP": 3.61,
    "OCT": 3.76,
    "NOV": 4.61,
    "DEC": 4.74
  },
  {
    "LONG": -48.65,
    "LAT": -26.78,
    "CIDADE": "Penha",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 5.13,
    "FEB": 5.21,
    "MAR": 4.95,
    "APR": 4.35,
    "MAY": 3.91,
    "JUN": 3.39,
    "JUL": 3.44,
    "AUG": 3.98,
    "SEP": 3.79,
    "OCT": 4.17,
    "NOV": 4.95,
    "DEC": 5.08
  },
  {
    "LONG": -48.68,
    "LAT": -26.77,
    "CIDADE": "Balneário Piçarras",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 5.13,
    "FEB": 5.21,
    "MAR": 4.95,
    "APR": 4.35,
    "MAY": 3.91,
    "JUN": 3.39,
    "JUL": 3.44,
    "AUG": 3.98,
    "SEP": 3.79,
    "OCT": 4.17,
    "NOV": 4.95,
    "DEC": 5.08
  },
  {
    "LONG": -53.52,
    "LAT": -26.72,
    "CIDADE": "São Miguel do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.59,
    "FEB": 5.49,
    "MAR": 5.4,
    "APR": 4.89,
    "MAY": 4.12,
    "JUN": 3.7,
    "JUL": 4.01,
    "AUG": 4.78,
    "SEP": 4.53,
    "OCT": 5.06,
    "NOV": 5.45,
    "DEC": 5.55
  },
  {
    "LONG": -53.44,
    "LAT": -26.65,
    "CIDADE": "Barra Bonita",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.55,
    "FEB": 5.49,
    "MAR": 5.39,
    "APR": 4.87,
    "MAY": 4.1,
    "JUN": 3.63,
    "JUL": 3.96,
    "AUG": 4.75,
    "SEP": 4.5,
    "OCT": 5.05,
    "NOV": 5.47,
    "DEC": 5.51
  },
  {
    "LONG": -53.32,
    "LAT": -26.68,
    "CIDADE": "Romelândia",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.57,
    "FEB": 5.54,
    "MAR": 5.46,
    "APR": 4.89,
    "MAY": 4.12,
    "JUN": 3.58,
    "JUL": 3.91,
    "AUG": 4.73,
    "SEP": 4.49,
    "OCT": 5.05,
    "NOV": 5.5,
    "DEC": 5.53
  },
  {
    "LONG": -53.25,
    "LAT": -26.69,
    "CIDADE": "São Miguel da Boa Vista",
    "UF": "SANTA CATARINA",
    "anual": 4.89,
    "JAN": 5.55,
    "FEB": 5.52,
    "MAR": 5.46,
    "APR": 4.94,
    "MAY": 4.13,
    "JUN": 3.68,
    "JUL": 3.97,
    "AUG": 4.79,
    "SEP": 4.52,
    "OCT": 5.04,
    "NOV": 5.5,
    "DEC": 5.54
  },
  {
    "LONG": -5.32,
    "LAT": -26.69,
    "CIDADE": "Tigrinhos",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.5,
    "FEB": 5.48,
    "MAR": 5.44,
    "APR": 4.93,
    "MAY": 4.13,
    "JUN": 3.69,
    "JUL": 3.99,
    "AUG": 4.79,
    "SEP": 4.51,
    "OCT": 5.02,
    "NOV": 5.48,
    "DEC": 5.51
  },
  {
    "LONG": -53.04,
    "LAT": -26.72,
    "CIDADE": "Serra Alta",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.42,
    "FEB": 5.38,
    "MAR": 5.38,
    "APR": 4.88,
    "MAY": 4.13,
    "JUN": 3.65,
    "JUL": 4.02,
    "AUG": 4.79,
    "SEP": 4.52,
    "OCT": 5.01,
    "NOV": 5.45,
    "DEC": 5.47
  },
  {
    "LONG": -53.1,
    "LAT": -26.69,
    "CIDADE": "Bom Jesus do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.42,
    "FEB": 5.38,
    "MAR": 5.38,
    "APR": 4.88,
    "MAY": 4.13,
    "JUN": 3.65,
    "JUL": 4.02,
    "AUG": 4.79,
    "SEP": 4.52,
    "OCT": 5.01,
    "NOV": 5.45,
    "DEC": 5.47
  },
  {
    "LONG": -52.96,
    "LAT": -26.74,
    "CIDADE": "Sul Brasil",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.42,
    "FEB": 5.4,
    "MAR": 5.41,
    "APR": 4.89,
    "MAY": 4.08,
    "JUN": 3.6,
    "JUL": 4.04,
    "AUG": 4.8,
    "SEP": 4.52,
    "OCT": 5.03,
    "NOV": 5.49,
    "DEC": 5.47
  },
  {
    "LONG": -5.29,
    "LAT": -26.72,
    "CIDADE": "Jardinópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.46,
    "FEB": 5.38,
    "MAR": 5.43,
    "APR": 4.91,
    "MAY": 4.1,
    "JUN": 3.63,
    "JUL": 4.04,
    "AUG": 4.82,
    "SEP": 4.52,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.49
  },
  {
    "LONG": -5.29,
    "LAT": -26.65,
    "CIDADE": "Irati",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.46,
    "FEB": 5.38,
    "MAR": 5.43,
    "APR": 4.91,
    "MAY": 4.1,
    "JUN": 3.63,
    "JUL": 4.04,
    "AUG": 4.82,
    "SEP": 4.52,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.49
  },
  {
    "LONG": -52.72,
    "LAT": -26.73,
    "CIDADE": "Quilombo",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.42,
    "FEB": 5.35,
    "MAR": 5.42,
    "APR": 4.89,
    "MAY": 4.12,
    "JUN": 3.66,
    "JUL": 3.99,
    "AUG": 4.83,
    "SEP": 4.51,
    "OCT": 5.01,
    "NOV": 5.5,
    "DEC": 5.47
  },
  {
    "LONG": -5.26,
    "LAT": -26.72,
    "CIDADE": "Entre Rios",
    "UF": "SANTA CATARINA",
    "anual": 4.89,
    "JAN": 5.53,
    "FEB": 5.41,
    "MAR": 5.47,
    "APR": 4.95,
    "MAY": 4.17,
    "JUN": 3.69,
    "JUL": 3.99,
    "AUG": 4.85,
    "SEP": 4.53,
    "OCT": 5.04,
    "NOV": 5.57,
    "DEC": 5.49
  },
  {
    "LONG": -52.31,
    "LAT": -26.69,
    "CIDADE": "Ouro Verde",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.47,
    "FEB": 5.29,
    "MAR": 5.32,
    "APR": 4.91,
    "MAY": 4.18,
    "JUN": 3.77,
    "JUL": 4.05,
    "AUG": 4.86,
    "SEP": 4.53,
    "OCT": 5.01,
    "NOV": 5.49,
    "DEC": 5.42
  },
  {
    "LONG": -52.39,
    "LAT": -26.73,
    "CIDADE": "Bom Jesus",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.47,
    "FEB": 5.29,
    "MAR": 5.32,
    "APR": 4.91,
    "MAY": 4.18,
    "JUN": 3.77,
    "JUL": 4.05,
    "AUG": 4.86,
    "SEP": 4.53,
    "OCT": 5.01,
    "NOV": 5.49,
    "DEC": 5.42
  },
  {
    "LONG": -49.48,
    "LAT": -26.72,
    "CIDADE": "Doutor Pedrinho",
    "UF": "SANTA CATARINA",
    "anual": 4.13,
    "JAN": 4.62,
    "FEB": 4.62,
    "MAR": 4.59,
    "APR": 4.15,
    "MAY": 3.74,
    "JUN": 3.36,
    "JUL": 3.51,
    "AUG": 4.26,
    "SEP": 3.71,
    "OCT": 3.87,
    "NOV": 4.57,
    "DEC": 4.63
  },
  {
    "LONG": -49.27,
    "LAT": -26.74,
    "CIDADE": "Rio dos Cedros",
    "UF": "SANTA CATARINA",
    "anual": 4.08,
    "JAN": 4.67,
    "FEB": 4.73,
    "MAR": 4.58,
    "APR": 4.18,
    "MAY": 3.77,
    "JUN": 3.26,
    "JUL": 3.4,
    "AUG": 4.05,
    "SEP": 3.55,
    "OCT": 3.68,
    "NOV": 4.47,
    "DEC": 4.63
  },
  {
    "LONG": -49.18,
    "LAT": -26.74,
    "CIDADE": "Pomerode",
    "UF": "SANTA CATARINA",
    "anual": 4.08,
    "JAN": 4.71,
    "FEB": 4.81,
    "MAR": 4.64,
    "APR": 4.19,
    "MAY": 3.78,
    "JUN": 3.24,
    "JUL": 3.37,
    "AUG": 3.95,
    "SEP": 3.53,
    "OCT": 3.64,
    "NOV": 4.47,
    "DEC": 4.66
  },
  {
    "LONG": -48.93,
    "LAT": -26.72,
    "CIDADE": "Luiz Alves",
    "UF": "SANTA CATARINA",
    "anual": 4.13,
    "JAN": 4.77,
    "FEB": 4.91,
    "MAR": 4.71,
    "APR": 4.26,
    "MAY": 3.86,
    "JUN": 3.32,
    "JUL": 3.4,
    "AUG": 3.91,
    "SEP": 3.56,
    "OCT": 3.68,
    "NOV": 4.5,
    "DEC": 4.7
  },
  {
    "LONG": -53.67,
    "LAT": -26.62,
    "CIDADE": "Paraíso",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.57,
    "FEB": 5.52,
    "MAR": 5.43,
    "APR": 4.88,
    "MAY": 4.11,
    "JUN": 3.69,
    "JUL": 3.98,
    "AUG": 4.78,
    "SEP": 4.54,
    "OCT": 5.06,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -53.52,
    "LAT": -26.6,
    "CIDADE": "Guaraciaba",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.57,
    "FEB": 5.46,
    "MAR": 5.42,
    "APR": 4.9,
    "MAY": 4.14,
    "JUN": 3.68,
    "JUL": 3.98,
    "AUG": 4.8,
    "SEP": 4.57,
    "OCT": 5.09,
    "NOV": 5.45,
    "DEC": 5.52
  },
  {
    "LONG": -53.2,
    "LAT": -26.62,
    "CIDADE": "Santa Terezinha do Progresso",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.47,
    "FEB": 5.49,
    "MAR": 5.41,
    "APR": 4.91,
    "MAY": 4.15,
    "JUN": 3.68,
    "JUL": 4,
    "AUG": 4.81,
    "SEP": 4.54,
    "OCT": 5.02,
    "NOV": 5.47,
    "DEC": 5.47
  },
  {
    "LONG": -53.06,
    "LAT": -26.61,
    "CIDADE": "Saltinho",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.43,
    "FEB": 5.39,
    "MAR": 5.35,
    "APR": 4.87,
    "MAY": 4.12,
    "JUN": 3.67,
    "JUL": 4.03,
    "AUG": 4.83,
    "SEP": 4.53,
    "OCT": 4.99,
    "NOV": 5.38,
    "DEC": 5.42
  },
  {
    "LONG": -52.8,
    "LAT": -26.65,
    "CIDADE": "Formosa do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.44,
    "FEB": 5.36,
    "MAR": 5.41,
    "APR": 4.92,
    "MAY": 4.13,
    "JUN": 3.7,
    "JUL": 4.03,
    "AUG": 4.86,
    "SEP": 4.56,
    "OCT": 5.03,
    "NOV": 5.48,
    "DEC": 5.43
  },
  {
    "LONG": -52.68,
    "LAT": -26.64,
    "CIDADE": "Santiago do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.4,
    "FEB": 5.41,
    "MAR": 5.46,
    "APR": 4.93,
    "MAY": 4.14,
    "JUN": 3.7,
    "JUL": 4.04,
    "AUG": 4.89,
    "SEP": 4.55,
    "OCT": 5.04,
    "NOV": 5.51,
    "DEC": 5.5
  },
  {
    "LONG": -52.53,
    "LAT": -26.56,
    "CIDADE": "São Domingos",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.4,
    "FEB": 5.37,
    "MAR": 5.41,
    "APR": 4.91,
    "MAY": 4.15,
    "JUN": 3.72,
    "JUL": 4.04,
    "AUG": 4.89,
    "SEP": 4.56,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.47
  },
  {
    "LONG": -52.46,
    "LAT": -26.63,
    "CIDADE": "Ipuaçu",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.45,
    "FEB": 5.35,
    "MAR": 5.34,
    "APR": 4.88,
    "MAY": 4.16,
    "JUN": 3.76,
    "JUL": 4.03,
    "AUG": 4.87,
    "SEP": 4.54,
    "OCT": 5,
    "NOV": 5.51,
    "DEC": 5.48
  },
  {
    "LONG": -52.32,
    "LAT": -26.57,
    "CIDADE": "Abelardo Luz",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.43,
    "FEB": 5.35,
    "MAR": 5.3,
    "APR": 4.91,
    "MAY": 4.19,
    "JUN": 3.77,
    "JUL": 4.06,
    "AUG": 4.9,
    "SEP": 4.54,
    "OCT": 5.01,
    "NOV": 5.46,
    "DEC": 5.43
  },
  {
    "LONG": -51.1,
    "LAT": -26.59,
    "CIDADE": "Calmon",
    "UF": "SANTA CATARINA",
    "anual": 4.43,
    "JAN": 4.81,
    "FEB": 4.89,
    "MAR": 4.83,
    "APR": 4.32,
    "MAY": 3.77,
    "JUN": 3.48,
    "JUL": 3.74,
    "AUG": 4.67,
    "SEP": 4.23,
    "OCT": 4.43,
    "NOV": 5.01,
    "DEC": 5.02
  },
  {
    "LONG": -50.66,
    "LAT": -26.61,
    "CIDADE": "Timbó Grande",
    "UF": "SANTA CATARINA",
    "anual": 4.32,
    "JAN": 4.8,
    "FEB": 4.87,
    "MAR": 4.75,
    "APR": 4.21,
    "MAY": 3.65,
    "JUN": 3.3,
    "JUL": 3.61,
    "AUG": 4.53,
    "SEP": 4.1,
    "OCT": 4.26,
    "NOV": 4.93,
    "DEC": 4.85
  },
  {
    "LONG": -49.01,
    "LAT": -26.61,
    "CIDADE": "Massaranduba",
    "UF": "SANTA CATARINA",
    "anual": 4.13,
    "JAN": 4.72,
    "FEB": 4.89,
    "MAR": 4.69,
    "APR": 4.23,
    "MAY": 3.82,
    "JUN": 3.31,
    "JUL": 3.39,
    "AUG": 3.97,
    "SEP": 3.57,
    "OCT": 3.75,
    "NOV": 4.52,
    "DEC": 4.71
  },
  {
    "LONG": -48.77,
    "LAT": -26.62,
    "CIDADE": "São João do Itaperiú",
    "UF": "SANTA CATARINA",
    "anual": 4.2,
    "JAN": 4.89,
    "FEB": 5.04,
    "MAR": 4.8,
    "APR": 4.35,
    "MAY": 3.86,
    "JUN": 3.28,
    "JUL": 3.36,
    "AUG": 3.86,
    "SEP": 3.64,
    "OCT": 3.91,
    "NOV": 4.65,
    "DEC": 4.83
  },
  {
    "LONG": -48.69,
    "LAT": -26.64,
    "CIDADE": "Barra Velha",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 5.1,
    "FEB": 5.25,
    "MAR": 4.98,
    "APR": 4.46,
    "MAY": 3.92,
    "JUN": 3.36,
    "JUL": 3.4,
    "AUG": 3.91,
    "SEP": 3.73,
    "OCT": 4.1,
    "NOV": 4.84,
    "DEC": 5.03
  },
  {
    "LONG": -53.5,
    "LAT": -26.46,
    "CIDADE": "São José do Cedro",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.54,
    "FEB": 5.5,
    "MAR": 5.39,
    "APR": 4.89,
    "MAY": 4.16,
    "JUN": 3.7,
    "JUL": 4.01,
    "AUG": 4.84,
    "SEP": 4.58,
    "OCT": 5.06,
    "NOV": 5.44,
    "DEC": 5.48
  },
  {
    "LONG": -53.33,
    "LAT": -26.54,
    "CIDADE": "Anchieta",
    "UF": "SANTA CATARINA",
    "anual": 4.9,
    "JAN": 5.53,
    "FEB": 5.51,
    "MAR": 5.43,
    "APR": 4.94,
    "MAY": 4.18,
    "JUN": 3.72,
    "JUL": 4.04,
    "AUG": 4.86,
    "SEP": 4.59,
    "OCT": 5.06,
    "NOV": 5.49,
    "DEC": 5.5
  },
  {
    "LONG": -52.97,
    "LAT": -26.47,
    "CIDADE": "São Bernardino",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.45,
    "FEB": 5.4,
    "MAR": 5.38,
    "APR": 4.9,
    "MAY": 4.14,
    "JUN": 3.71,
    "JUL": 4.04,
    "AUG": 4.9,
    "SEP": 4.56,
    "OCT": 5.01,
    "NOV": 5.45,
    "DEC": 5.43
  },
  {
    "LONG": -5.27,
    "LAT": -26.46,
    "CIDADE": "Galvão",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.43,
    "FEB": 5.4,
    "MAR": 5.39,
    "APR": 4.94,
    "MAY": 4.19,
    "JUN": 3.73,
    "JUL": 4.07,
    "AUG": 4.91,
    "SEP": 4.57,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.44
  },
  {
    "LONG": -52.67,
    "LAT": -26.51,
    "CIDADE": "Coronel Martins",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.43,
    "FEB": 5.4,
    "MAR": 5.39,
    "APR": 4.94,
    "MAY": 4.19,
    "JUN": 3.73,
    "JUL": 4.07,
    "AUG": 4.91,
    "SEP": 4.57,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.44
  },
  {
    "LONG": -51.15,
    "LAT": -26.47,
    "CIDADE": "Matos Costa",
    "UF": "SANTA CATARINA",
    "anual": 4.42,
    "JAN": 4.85,
    "FEB": 4.91,
    "MAR": 4.84,
    "APR": 4.3,
    "MAY": 3.74,
    "JUN": 3.43,
    "JUL": 3.71,
    "AUG": 4.66,
    "SEP": 4.22,
    "OCT": 4.43,
    "NOV": 5,
    "DEC": 5.01
  },
  {
    "LONG": -50.23,
    "LAT": -26.46,
    "CIDADE": "Monte Castelo",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 4.86,
    "FEB": 4.85,
    "MAR": 4.79,
    "APR": 4.16,
    "MAY": 3.63,
    "JUN": 3.24,
    "JUL": 3.52,
    "AUG": 4.41,
    "SEP": 4.03,
    "OCT": 4.18,
    "NOV": 4.89,
    "DEC": 4.87
  },
  {
    "LONG": -49,
    "LAT": -26.47,
    "CIDADE": "Guaramirim",
    "UF": "SANTA CATARINA",
    "anual": 4.13,
    "JAN": 4.72,
    "FEB": 4.85,
    "MAR": 4.67,
    "APR": 4.25,
    "MAY": 3.84,
    "JUN": 3.31,
    "JUL": 3.38,
    "AUG": 3.99,
    "SEP": 3.58,
    "OCT": 3.77,
    "NOV": 4.53,
    "DEC": 4.68
  },
  {
    "LONG": -49.07,
    "LAT": -26.49,
    "CIDADE": "Jaraguá do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.13,
    "JAN": 4.72,
    "FEB": 4.85,
    "MAR": 4.67,
    "APR": 4.25,
    "MAY": 3.84,
    "JUN": 3.31,
    "JUL": 3.38,
    "AUG": 3.99,
    "SEP": 3.58,
    "OCT": 3.77,
    "NOV": 4.53,
    "DEC": 4.68
  },
  {
    "LONG": -48.61,
    "LAT": -26.46,
    "CIDADE": "Balneário Barra do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.95,
    "FEB": 5.13,
    "MAR": 4.88,
    "APR": 4.38,
    "MAY": 3.87,
    "JUN": 3.32,
    "JUL": 3.34,
    "AUG": 3.89,
    "SEP": 3.66,
    "OCT": 3.99,
    "NOV": 4.71,
    "DEC": 4.92
  },
  {
    "LONG": -53.6,
    "LAT": -26.44,
    "CIDADE": "Princesa",
    "UF": "SANTA CATARINA",
    "anual": 4.92,
    "JAN": 5.58,
    "FEB": 5.56,
    "MAR": 5.46,
    "APR": 4.9,
    "MAY": 4.16,
    "JUN": 3.74,
    "JUL": 4.04,
    "AUG": 4.85,
    "SEP": 4.62,
    "OCT": 5.11,
    "NOV": 5.47,
    "DEC": 5.5
  },
  {
    "LONG": "#VALOR!",
    "LAT": -26.39,
    "CIDADE": "Guarujá do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.91,
    "JAN": 5.55,
    "FEB": 5.49,
    "MAR": 5.42,
    "APR": 4.91,
    "MAY": 4.19,
    "JUN": 3.75,
    "JUL": 4.07,
    "AUG": 4.9,
    "SEP": 4.62,
    "OCT": 5.09,
    "NOV": 5.43,
    "DEC": 5.5
  },
  {
    "LONG": -53.09,
    "LAT": -26.39,
    "CIDADE": "Campo Erê",
    "UF": "SANTA CATARINA",
    "anual": 4.89,
    "JAN": 5.48,
    "FEB": 5.38,
    "MAR": 5.36,
    "APR": 4.91,
    "MAY": 4.2,
    "JUN": 3.75,
    "JUL": 4.07,
    "AUG": 4.95,
    "SEP": 4.62,
    "OCT": 5.05,
    "NOV": 5.45,
    "DEC": 5.43
  },
  {
    "LONG": -52.85,
    "LAT": -26.36,
    "CIDADE": "São Lourenço do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.42,
    "FEB": 5.4,
    "MAR": 5.37,
    "APR": 4.92,
    "MAY": 4.18,
    "JUN": 3.76,
    "JUL": 4.1,
    "AUG": 4.95,
    "SEP": 4.61,
    "OCT": 5.02,
    "NOV": 5.46,
    "DEC": 5.42
  },
  {
    "LONG": -52.83,
    "LAT": -26.44,
    "CIDADE": "Novo Horizonte",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.42,
    "FEB": 5.4,
    "MAR": 5.37,
    "APR": 4.92,
    "MAY": 4.18,
    "JUN": 3.76,
    "JUL": 4.1,
    "AUG": 4.95,
    "SEP": 4.61,
    "OCT": 5.02,
    "NOV": 5.46,
    "DEC": 5.42
  },
  {
    "LONG": -52.73,
    "LAT": -26.4,
    "CIDADE": "Jupiá",
    "UF": "SANTA CATARINA",
    "anual": 4.9,
    "JAN": 5.45,
    "FEB": 5.38,
    "MAR": 5.38,
    "APR": 4.94,
    "MAY": 4.19,
    "JUN": 3.79,
    "JUL": 4.1,
    "AUG": 4.97,
    "SEP": 4.6,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.43
  },
  {
    "LONG": -5.03,
    "LAT": -26.37,
    "CIDADE": "Major Vieira",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.86,
    "FEB": 4.83,
    "MAR": 4.78,
    "APR": 4.12,
    "MAY": 3.61,
    "JUN": 3.22,
    "JUL": 3.49,
    "AUG": 4.44,
    "SEP": 4.04,
    "OCT": 4.2,
    "NOV": 4.88,
    "DEC": 4.9
  },
  {
    "LONG": -50.14,
    "LAT": -26.41,
    "CIDADE": "Papanduva",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.78,
    "FEB": 4.83,
    "MAR": 4.74,
    "APR": 4.11,
    "MAY": 3.63,
    "JUN": 3.25,
    "JUL": 3.5,
    "AUG": 4.46,
    "SEP": 4.06,
    "OCT": 4.22,
    "NOV": 4.85,
    "DEC": 4.87
  },
  {
    "LONG": -49.25,
    "LAT": -26.43,
    "CIDADE": "Corupá",
    "UF": "SANTA CATARINA",
    "anual": 4.16,
    "JAN": 4.64,
    "FEB": 4.74,
    "MAR": 4.65,
    "APR": 4.22,
    "MAY": 3.79,
    "JUN": 3.35,
    "JUL": 3.51,
    "AUG": 4.24,
    "SEP": 3.68,
    "OCT": 3.88,
    "NOV": 4.56,
    "DEC": 4.69
  },
  {
    "LONG": -49.07,
    "LAT": -26.41,
    "CIDADE": "Schroeder",
    "UF": "SANTA CATARINA",
    "anual": 3.99,
    "JAN": 4.5,
    "FEB": 4.59,
    "MAR": 4.46,
    "APR": 4.12,
    "MAY": 3.74,
    "JUN": 3.26,
    "JUL": 3.34,
    "AUG": 3.98,
    "SEP": 3.44,
    "OCT": 3.63,
    "NOV": 4.35,
    "DEC": 4.45
  },
  {
    "LONG": -48.72,
    "LAT": -26.38,
    "CIDADE": "Araquari",
    "UF": "SANTA CATARINA",
    "anual": 4.12,
    "JAN": 4.74,
    "FEB": 4.92,
    "MAR": 4.68,
    "APR": 4.26,
    "MAY": 3.82,
    "JUN": 3.27,
    "JUL": 3.31,
    "AUG": 3.86,
    "SEP": 3.55,
    "OCT": 3.85,
    "NOV": 4.54,
    "DEC": 4.71
  },
  {
    "LONG": -53.64,
    "LAT": -26.27,
    "CIDADE": "Dionísio Cerqueira",
    "UF": "SANTA CATARINA",
    "anual": 4.93,
    "JAN": 5.6,
    "FEB": 5.54,
    "MAR": 5.48,
    "APR": 4.91,
    "MAY": 4.16,
    "JUN": 3.74,
    "JUL": 4.06,
    "AUG": 4.91,
    "SEP": 4.64,
    "OCT": 5.12,
    "NOV": 5.46,
    "DEC": 5.49
  },
  {
    "LONG": -53.28,
    "LAT": -26.35,
    "CIDADE": "Palma Sola",
    "UF": "SANTA CATARINA",
    "anual": 4.9,
    "JAN": 5.5,
    "FEB": 5.4,
    "MAR": 5.41,
    "APR": 4.92,
    "MAY": 4.19,
    "JUN": 3.74,
    "JUL": 4.1,
    "AUG": 4.97,
    "SEP": 4.66,
    "OCT": 5.08,
    "NOV": 5.44,
    "DEC": 5.44
  },
  {
    "LONG": -50.47,
    "LAT": -26.28,
    "CIDADE": "Bela Vista do Toldo",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.83,
    "FEB": 4.84,
    "MAR": 4.74,
    "APR": 4.09,
    "MAY": 3.62,
    "JUN": 3.16,
    "JUL": 3.45,
    "AUG": 4.45,
    "SEP": 4.09,
    "OCT": 4.25,
    "NOV": 4.9,
    "DEC": 4.91
  },
  {
    "LONG": -49.91,
    "LAT": -26.34,
    "CIDADE": "Itaiópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 4.86,
    "FEB": 4.86,
    "MAR": 4.81,
    "APR": 4.18,
    "MAY": 3.71,
    "JUN": 3.31,
    "JUL": 3.63,
    "AUG": 4.54,
    "SEP": 4.09,
    "OCT": 4.3,
    "NOV": 4.87,
    "DEC": 4.95
  },
  {
    "LONG": -49.52,
    "LAT": -26.26,
    "CIDADE": "Rio Negrinho",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 4.78,
    "FEB": 4.86,
    "MAR": 4.71,
    "APR": 4.2,
    "MAY": 3.68,
    "JUN": 3.33,
    "JUL": 3.62,
    "AUG": 4.5,
    "SEP": 4.02,
    "OCT": 4.21,
    "NOV": 4.74,
    "DEC": 4.88
  },
  {
    "LONG": -48.85,
    "LAT": -2.63,
    "CIDADE": "Joinville",
    "UF": "SANTA CATARINA",
    "anual": 3.98,
    "JAN": 4.53,
    "FEB": 4.64,
    "MAR": 4.46,
    "APR": 4.11,
    "MAY": 3.74,
    "JUN": 3.24,
    "JUL": 3.26,
    "AUG": 3.85,
    "SEP": 3.43,
    "OCT": 3.64,
    "NOV": 4.36,
    "DEC": 4.49
  },
  {
    "LONG": -48.63,
    "LAT": -26.26,
    "CIDADE": "São Francisco do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.11,
    "JAN": 4.73,
    "FEB": 4.93,
    "MAR": 4.67,
    "APR": 4.27,
    "MAY": 3.83,
    "JUN": 3.25,
    "JUL": 3.25,
    "AUG": 3.81,
    "SEP": 3.51,
    "OCT": 3.83,
    "NOV": 4.5,
    "DEC": 4.73
  },
  {
    "LONG": -51.08,
    "LAT": -26.25,
    "CIDADE": "Porto União",
    "UF": "SANTA CATARINA",
    "anual": 4.33,
    "JAN": 4.9,
    "FEB": 4.87,
    "MAR": 4.82,
    "APR": 4.16,
    "MAY": 3.63,
    "JUN": 3.19,
    "JUL": 3.43,
    "AUG": 4.5,
    "SEP": 4.16,
    "OCT": 4.4,
    "NOV": 4.98,
    "DEC": 4.96
  },
  {
    "LONG": -50.8,
    "LAT": -26.24,
    "CIDADE": "Irineópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.75,
    "FEB": 4.79,
    "MAR": 4.73,
    "APR": 4.09,
    "MAY": 3.52,
    "JUN": 3.12,
    "JUL": 3.39,
    "AUG": 4.4,
    "SEP": 4.1,
    "OCT": 4.25,
    "NOV": 4.88,
    "DEC": 4.86
  },
  {
    "LONG": -50.4,
    "LAT": -26.18,
    "CIDADE": "Canoinhas",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.85,
    "FEB": 4.88,
    "MAR": 4.73,
    "APR": 4.08,
    "MAY": 3.56,
    "JUN": 3.19,
    "JUL": 3.45,
    "AUG": 4.47,
    "SEP": 4.09,
    "OCT": 4.27,
    "NOV": 4.87,
    "DEC": 4.91
  },
  {
    "LONG": -49.38,
    "LAT": "#VALOR!",
    "CIDADE": "São Bento do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.73,
    "FEB": 4.78,
    "MAR": 4.62,
    "APR": 4.21,
    "MAY": 3.73,
    "JUN": 3.43,
    "JUL": 3.65,
    "AUG": 4.51,
    "SEP": 4.02,
    "OCT": 4.16,
    "NOV": 4.68,
    "DEC": 4.8
  },
  {
    "LONG": -4.93,
    "LAT": -26.2,
    "CIDADE": "Campo Alegre",
    "UF": "SANTA CATARINA",
    "anual": 4.27,
    "JAN": 4.73,
    "FEB": 4.76,
    "MAR": 4.59,
    "APR": 4.22,
    "MAY": 3.75,
    "JUN": 3.45,
    "JUL": 3.68,
    "AUG": 4.51,
    "SEP": 3.98,
    "OCT": 4.13,
    "NOV": 4.65,
    "DEC": 4.79
  },
  {
    "LONG": -50.32,
    "LAT": -26.11,
    "CIDADE": "Três Barras",
    "UF": "SANTA CATARINA",
    "anual": 4.31,
    "JAN": 4.86,
    "FEB": 4.91,
    "MAR": 4.76,
    "APR": 4.12,
    "MAY": 3.59,
    "JUN": 3.21,
    "JUL": 3.49,
    "AUG": 4.52,
    "SEP": 4.15,
    "OCT": 4.31,
    "NOV": 4.91,
    "DEC": 4.93
  },
  {
    "LONG": -49.81,
    "LAT": -26.12,
    "CIDADE": "Mafra",
    "UF": "SANTA CATARINA",
    "anual": 4.35,
    "JAN": 4.92,
    "FEB": 4.94,
    "MAR": 4.79,
    "APR": 4.23,
    "MAY": 3.66,
    "JUN": 3.29,
    "JUL": 3.58,
    "AUG": 4.58,
    "SEP": 4.11,
    "OCT": 4.34,
    "NOV": 4.85,
    "DEC": 4.96
  },
  {
    "LONG": -48.62,
    "LAT": -26.12,
    "CIDADE": "Itapoá",
    "UF": "SANTA CATARINA",
    "anual": 4.08,
    "JAN": 4.75,
    "FEB": 4.89,
    "MAR": 4.62,
    "APR": 4.3,
    "MAY": 3.8,
    "JUN": 3.26,
    "JUL": 3.23,
    "AUG": 3.82,
    "SEP": 3.47,
    "OCT": 3.74,
    "NOV": 4.42,
    "DEC": 4.66
  },
  {
    "LONG": -48.85,
    "LAT": -26.03,
    "CIDADE": "Garuva",
    "UF": "SANTA CATARINA",
    "anual": 3.78,
    "JAN": 4.24,
    "FEB": 4.34,
    "MAR": 4.13,
    "APR": 3.84,
    "MAY": 3.59,
    "JUN": 3.2,
    "JUL": 3.27,
    "AUG": 3.91,
    "SEP": 3.28,
    "OCT": 3.38,
    "NOV": 4.02,
    "DEC": 4.18
  },
  {
    "LONG": -47.93,
    "LAT": -25.01,
    "CIDADE": "Cananéia",
    "UF": "SÃO PAULO",
    "anual": 4.11,
    "JAN": 4.72,
    "FEB": 4.99,
    "MAR": 4.57,
    "APR": 4.34,
    "MAY": 3.76,
    "JUN": 3.37,
    "JUL": 3.33,
    "AUG": 3.89,
    "SEP": 3.58,
    "OCT": 3.68,
    "NOV": 4.39,
    "DEC": 4.72
  },
  {
    "LONG": -48.5,
    "LAT": -2.48,
    "CIDADE": "Barra do Turvo",
    "UF": "SÃO PAULO",
    "anual": 4.23,
    "JAN": 4.74,
    "FEB": 4.99,
    "MAR": 4.66,
    "APR": 4.33,
    "MAY": 3.77,
    "JUN": 3.38,
    "JUL": 3.41,
    "AUG": 4.24,
    "SEP": 3.85,
    "OCT": 4.06,
    "NOV": 4.51,
    "DEC": 4.85
  },
  {
    "LONG": -4.9,
    "LAT": -24.65,
    "CIDADE": "Ribeira",
    "UF": "SÃO PAULO",
    "anual": 4.43,
    "JAN": 4.89,
    "FEB": 5.14,
    "MAR": 4.89,
    "APR": 4.49,
    "MAY": 3.85,
    "JUN": 3.46,
    "JUL": 3.5,
    "AUG": 4.47,
    "SEP": 4.17,
    "OCT": 4.35,
    "NOV": 4.84,
    "DEC": 5.05
  },
  {
    "LONG": -48.11,
    "LAT": -24.73,
    "CIDADE": "Cajati",
    "UF": "SÃO PAULO",
    "anual": 4.28,
    "JAN": 4.87,
    "FEB": 5.19,
    "MAR": 4.72,
    "APR": 4.53,
    "MAY": 3.89,
    "JUN": 3.58,
    "JUL": 3.49,
    "AUG": 4.07,
    "SEP": 3.78,
    "OCT": 3.89,
    "NOV": 4.46,
    "DEC": 4.86
  },
  {
    "LONG": -48.01,
    "LAT": -24.7,
    "CIDADE": "Jacupiranga",
    "UF": "SÃO PAULO",
    "anual": 4.25,
    "JAN": 4.87,
    "FEB": 5.16,
    "MAR": 4.73,
    "APR": 4.47,
    "MAY": 3.91,
    "JUN": 3.51,
    "JUL": 3.42,
    "AUG": 4,
    "SEP": 3.74,
    "OCT": 3.91,
    "NOV": 4.46,
    "DEC": 4.82
  },
  {
    "LONG": -47.88,
    "LAT": -2.47,
    "CIDADE": "Pariquera-Açu",
    "UF": "SÃO PAULO",
    "anual": 4.23,
    "JAN": 4.85,
    "FEB": 5.18,
    "MAR": 4.7,
    "APR": 4.47,
    "MAY": 3.84,
    "JUN": 3.46,
    "JUL": 3.37,
    "AUG": 3.95,
    "SEP": 3.72,
    "OCT": 3.87,
    "NOV": 4.48,
    "DEC": 4.89
  },
  {
    "LONG": -4.76,
    "LAT": -2.47,
    "CIDADE": "Iguape",
    "UF": "SÃO PAULO",
    "anual": 4.27,
    "JAN": 4.99,
    "FEB": 5.32,
    "MAR": 4.8,
    "APR": 4.54,
    "MAY": 3.81,
    "JUN": 3.39,
    "JUL": 3.28,
    "AUG": 3.87,
    "SEP": 3.74,
    "OCT": 3.89,
    "NOV": 4.6,
    "DEC": 5
  },
  {
    "LONG": -47.54,
    "LAT": -24.74,
    "CIDADE": "Ilha Comprida",
    "UF": "SÃO PAULO",
    "anual": 4.27,
    "JAN": 4.99,
    "FEB": 5.32,
    "MAR": 4.8,
    "APR": 4.54,
    "MAY": 3.81,
    "JUN": 3.39,
    "JUL": 3.28,
    "AUG": 3.87,
    "SEP": 3.74,
    "OCT": 3.89,
    "NOV": 4.6,
    "DEC": 5
  },
  {
    "LONG": -49.17,
    "LAT": -24.57,
    "CIDADE": "Itapirapuã Paulista",
    "UF": "SÃO PAULO",
    "anual": 4.37,
    "JAN": 4.71,
    "FEB": 4.96,
    "MAR": 4.78,
    "APR": 4.46,
    "MAY": 3.85,
    "JUN": 3.52,
    "JUL": 3.53,
    "AUG": 4.54,
    "SEP": 4.13,
    "OCT": 4.3,
    "NOV": 4.76,
    "DEC": 4.92
  },
  {
    "LONG": -48.84,
    "LAT": -24.64,
    "CIDADE": "Itaóca",
    "UF": "SÃO PAULO",
    "anual": 4.32,
    "JAN": 4.7,
    "FEB": 5,
    "MAR": 4.69,
    "APR": 4.42,
    "MAY": 3.8,
    "JUN": 3.48,
    "JUL": 3.53,
    "AUG": 4.44,
    "SEP": 4.05,
    "OCT": 4.19,
    "NOV": 4.61,
    "DEC": 4.87
  },
  {
    "LONG": -48.59,
    "LAT": -2.46,
    "CIDADE": "Iporanga",
    "UF": "SÃO PAULO",
    "anual": 4.25,
    "JAN": 4.76,
    "FEB": 5.02,
    "MAR": 4.69,
    "APR": 4.44,
    "MAY": 3.83,
    "JUN": 3.44,
    "JUL": 3.42,
    "AUG": 4.22,
    "SEP": 3.86,
    "OCT": 4.01,
    "NOV": 4.46,
    "DEC": 4.82
  },
  {
    "LONG": -49.02,
    "LAT": -24.47,
    "CIDADE": "Barra do Chapéu",
    "UF": "SÃO PAULO",
    "anual": 4.42,
    "JAN": 4.7,
    "FEB": 4.95,
    "MAR": 4.75,
    "APR": 4.5,
    "MAY": 3.91,
    "JUN": 3.67,
    "JUL": 3.7,
    "AUG": 4.68,
    "SEP": 4.25,
    "OCT": 4.38,
    "NOV": 4.69,
    "DEC": 4.88
  },
  {
    "LONG": -48.84,
    "LAT": -24.51,
    "CIDADE": "Apiaí",
    "UF": "SÃO PAULO",
    "anual": 4.39,
    "JAN": 4.69,
    "FEB": 4.98,
    "MAR": 4.66,
    "APR": 4.46,
    "MAY": 3.89,
    "JUN": 3.69,
    "JUL": 3.73,
    "AUG": 4.65,
    "SEP": 4.2,
    "OCT": 4.29,
    "NOV": 4.61,
    "DEC": 4.87
  },
  {
    "LONG": -48.11,
    "LAT": "#VALOR!",
    "CIDADE": "Eldorado",
    "UF": "SÃO PAULO",
    "anual": 4.11,
    "JAN": 4.69,
    "FEB": 4.95,
    "MAR": 4.53,
    "APR": 4.38,
    "MAY": 3.77,
    "JUN": 3.45,
    "JUL": 3.37,
    "AUG": 3.99,
    "SEP": 3.63,
    "OCT": 3.67,
    "NOV": 4.24,
    "DEC": 4.63
  },
  {
    "LONG": -47.85,
    "LAT": -24.5,
    "CIDADE": "Registro",
    "UF": "SÃO PAULO",
    "anual": 4.18,
    "JAN": 4.84,
    "FEB": 5.14,
    "MAR": 4.64,
    "APR": 4.44,
    "MAY": 3.78,
    "JUN": 3.38,
    "JUL": 3.3,
    "AUG": 3.88,
    "SEP": 3.68,
    "OCT": 3.8,
    "NOV": 4.41,
    "DEC": 4.84
  },
  {
    "LONG": -47.93,
    "LAT": -24.38,
    "CIDADE": "Sete Barras",
    "UF": "SÃO PAULO",
    "anual": 4.11,
    "JAN": 4.72,
    "FEB": 5.02,
    "MAR": 4.54,
    "APR": 4.4,
    "MAY": 3.72,
    "JUN": 3.36,
    "JUL": 3.29,
    "AUG": 3.86,
    "SEP": 3.64,
    "OCT": 3.68,
    "NOV": 4.3,
    "DEC": 4.74
  },
  {
    "LONG": -49.14,
    "LAT": -2.43,
    "CIDADE": "Bom Sucesso de Itararé",
    "UF": "SÃO PAULO",
    "anual": 4.68,
    "JAN": 4.78,
    "FEB": 5.11,
    "MAR": 4.89,
    "APR": 4.74,
    "MAY": 4.15,
    "JUN": 3.97,
    "JUL": 4.11,
    "AUG": 5.09,
    "SEP": 4.63,
    "OCT": 4.63,
    "NOV": 4.92,
    "DEC": 5.12
  },
  {
    "LONG": -4.76,
    "LAT": -2.43,
    "CIDADE": "Juquiá",
    "UF": "SÃO PAULO",
    "anual": 4.15,
    "JAN": 4.67,
    "FEB": 5.08,
    "MAR": 4.58,
    "APR": 4.4,
    "MAY": 3.75,
    "JUN": 3.45,
    "JUL": 3.37,
    "AUG": 3.96,
    "SEP": 3.68,
    "OCT": 3.74,
    "NOV": 4.31,
    "DEC": 4.75
  },
  {
    "LONG": -47.46,
    "LAT": -24.28,
    "CIDADE": "Miracatu",
    "UF": "SÃO PAULO",
    "anual": 4.23,
    "JAN": 4.7,
    "FEB": 5.14,
    "MAR": 4.69,
    "APR": 4.46,
    "MAY": 3.84,
    "JUN": 3.52,
    "JUL": 3.48,
    "AUG": 4.09,
    "SEP": 3.8,
    "OCT": 3.8,
    "NOV": 4.38,
    "DEC": 4.84
  },
  {
    "LONG": -4.72,
    "LAT": -2.43,
    "CIDADE": "Pedro de Toledo",
    "UF": "SÃO PAULO",
    "anual": 4.37,
    "JAN": 4.82,
    "FEB": 5.24,
    "MAR": 4.8,
    "APR": 4.61,
    "MAY": 3.99,
    "JUN": 3.68,
    "JUL": 3.66,
    "AUG": 4.3,
    "SEP": 3.92,
    "OCT": 3.99,
    "NOV": 4.48,
    "DEC": 4.94
  },
  {
    "LONG": -4.72,
    "LAT": -2.43,
    "CIDADE": "Itariri",
    "UF": "SÃO PAULO",
    "anual": 4.32,
    "JAN": 4.73,
    "FEB": 5.2,
    "MAR": 4.76,
    "APR": 4.55,
    "MAY": 3.97,
    "JUN": 3.66,
    "JUL": 3.63,
    "AUG": 4.26,
    "SEP": 3.87,
    "OCT": 3.96,
    "NOV": 4.42,
    "DEC": 4.87
  },
  {
    "LONG": -47,
    "LAT": -24.31,
    "CIDADE": "Peruíbe",
    "UF": "SÃO PAULO",
    "anual": 4.24,
    "JAN": 4.67,
    "FEB": 5.09,
    "MAR": 4.64,
    "APR": 4.52,
    "MAY": 3.95,
    "JUN": 3.63,
    "JUL": 3.59,
    "AUG": 4.15,
    "SEP": 3.77,
    "OCT": 3.79,
    "NOV": 4.32,
    "DEC": 4.74
  },
  {
    "LONG": -4.88,
    "LAT": -24.22,
    "CIDADE": "Ribeirão Branco",
    "UF": "SÃO PAULO",
    "anual": 4.69,
    "JAN": 4.93,
    "FEB": 5.22,
    "MAR": 4.93,
    "APR": 4.76,
    "MAY": 4.15,
    "JUN": 3.96,
    "JUL": 4.1,
    "AUG": 5.01,
    "SEP": 4.54,
    "OCT": 4.59,
    "NOV": 4.92,
    "DEC": 5.19
  },
  {
    "LONG": "#VALOR!",
    "LAT": -24.19,
    "CIDADE": "Guapiara",
    "UF": "SÃO PAULO",
    "anual": 4.59,
    "JAN": 4.81,
    "FEB": 5.16,
    "MAR": 4.85,
    "APR": 4.69,
    "MAY": 4.1,
    "JUN": 3.9,
    "JUL": 3.97,
    "AUG": 4.89,
    "SEP": 4.4,
    "OCT": 4.45,
    "NOV": 4.78,
    "DEC": 5.1
  },
  {
    "LONG": -4.68,
    "LAT": -24.18,
    "CIDADE": "Itanhaém",
    "UF": "SÃO PAULO",
    "anual": 4.33,
    "JAN": 4.85,
    "FEB": 5.22,
    "MAR": 4.76,
    "APR": 4.67,
    "MAY": 4,
    "JUN": 3.67,
    "JUL": 3.62,
    "AUG": 4.17,
    "SEP": 3.81,
    "OCT": 3.88,
    "NOV": 4.48,
    "DEC": 4.8
  },
  {
    "LONG": -49.34,
    "LAT": -24.11,
    "CIDADE": "Itararé",
    "UF": "SÃO PAULO",
    "anual": 4.94,
    "JAN": 4.99,
    "FEB": 5.42,
    "MAR": 5.21,
    "APR": 5.02,
    "MAY": 4.36,
    "JUN": 4.1,
    "JUL": 4.34,
    "AUG": 5.26,
    "SEP": 4.91,
    "OCT": 4.96,
    "NOV": 5.24,
    "DEC": 5.42
  },
  {
    "LONG": -48.9,
    "LAT": -24.12,
    "CIDADE": "Nova Campina",
    "UF": "SÃO PAULO",
    "anual": 4.85,
    "JAN": 4.99,
    "FEB": 5.35,
    "MAR": 5.15,
    "APR": 4.94,
    "MAY": 4.3,
    "JUN": 4.06,
    "JUL": 4.22,
    "AUG": 5.16,
    "SEP": 4.78,
    "OCT": 4.82,
    "NOV": 5.11,
    "DEC": 5.31
  },
  {
    "LONG": -48.36,
    "LAT": -2.41,
    "CIDADE": "Ribeirão Grande",
    "UF": "SÃO PAULO",
    "anual": 4.67,
    "JAN": 4.95,
    "FEB": 5.22,
    "MAR": 4.93,
    "APR": 4.76,
    "MAY": 4.13,
    "JUN": 3.96,
    "JUL": 4.05,
    "AUG": 4.92,
    "SEP": 4.48,
    "OCT": 4.57,
    "NOV": 4.89,
    "DEC": 5.17
  },
  {
    "LONG": -4.66,
    "LAT": -2.41,
    "CIDADE": "Mongaguá",
    "UF": "SÃO PAULO",
    "anual": 4.11,
    "JAN": 4.59,
    "FEB": 4.94,
    "MAR": 4.46,
    "APR": 4.45,
    "MAY": 3.89,
    "JUN": 3.55,
    "JUL": 3.53,
    "AUG": 3.99,
    "SEP": 3.58,
    "OCT": 3.62,
    "NOV": 4.17,
    "DEC": 4.53
  },
  {
    "LONG": -48.88,
    "LAT": -23.98,
    "CIDADE": "Itapeva",
    "UF": "SÃO PAULO",
    "anual": 4.86,
    "JAN": 5.02,
    "FEB": 5.35,
    "MAR": 5.14,
    "APR": 4.97,
    "MAY": 4.28,
    "JUN": 4.08,
    "JUL": 4.23,
    "AUG": 5.15,
    "SEP": 4.76,
    "OCT": 4.86,
    "NOV": 5.11,
    "DEC": 5.36
  },
  {
    "LONG": -48.35,
    "LAT": -2.4,
    "CIDADE": "Capão Bonito",
    "UF": "SÃO PAULO",
    "anual": 4.7,
    "JAN": 4.88,
    "FEB": 5.24,
    "MAR": 4.98,
    "APR": 4.84,
    "MAY": 4.18,
    "JUN": 3.98,
    "JUL": 4.1,
    "AUG": 4.96,
    "SEP": 4.52,
    "OCT": 4.63,
    "NOV": 4.91,
    "DEC": 5.18
  },
  {
    "LONG": -47.51,
    "LAT": -23.96,
    "CIDADE": "Tapiraí",
    "UF": "SÃO PAULO",
    "anual": 4.32,
    "JAN": 4.41,
    "FEB": 5,
    "MAR": 4.6,
    "APR": 4.44,
    "MAY": 3.88,
    "JUN": 3.82,
    "JUL": 3.81,
    "AUG": 4.53,
    "SEP": 4.02,
    "OCT": 4.06,
    "NOV": 4.42,
    "DEC": 4.8
  },
  {
    "LONG": -46.41,
    "LAT": -24.01,
    "CIDADE": "Praia Grande",
    "UF": "SÃO PAULO",
    "anual": 4.09,
    "JAN": 4.44,
    "FEB": 4.87,
    "MAR": 4.44,
    "APR": 4.38,
    "MAY": 3.9,
    "JUN": 3.6,
    "JUL": 3.53,
    "AUG": 4.06,
    "SEP": 3.61,
    "OCT": 3.68,
    "NOV": 4.09,
    "DEC": 4.45
  },
  {
    "LONG": -46.34,
    "LAT": -2.4,
    "CIDADE": "Santos",
    "UF": "SÃO PAULO",
    "anual": 4.33,
    "JAN": 4.78,
    "FEB": 5.21,
    "MAR": 4.76,
    "APR": 4.63,
    "MAY": 4.05,
    "JUN": 3.74,
    "JUL": 3.69,
    "AUG": 4.25,
    "SEP": 3.8,
    "OCT": 3.9,
    "NOV": 4.44,
    "DEC": 4.74
  },
  {
    "LONG": -46.39,
    "LAT": -23.96,
    "CIDADE": "São Vicente",
    "UF": "SÃO PAULO",
    "anual": 4.33,
    "JAN": 4.78,
    "FEB": 5.21,
    "MAR": 4.76,
    "APR": 4.63,
    "MAY": 4.05,
    "JUN": 3.74,
    "JUL": 3.69,
    "AUG": 4.25,
    "SEP": 3.8,
    "OCT": 3.9,
    "NOV": 4.44,
    "DEC": 4.74
  },
  {
    "LONG": -46.26,
    "LAT": -23.99,
    "CIDADE": "Guarujá",
    "UF": "SÃO PAULO",
    "anual": 4.37,
    "JAN": 4.91,
    "FEB": 5.25,
    "MAR": 4.8,
    "APR": 4.64,
    "MAY": 4.07,
    "JUN": 3.73,
    "JUL": 3.7,
    "AUG": 4.27,
    "SEP": 3.83,
    "OCT": 3.94,
    "NOV": 4.47,
    "DEC": 4.83
  },
  {
    "LONG": -49.14,
    "LAT": -23.86,
    "CIDADE": "Itaberá",
    "UF": "SÃO PAULO",
    "anual": 4.96,
    "JAN": 5.11,
    "FEB": 5.46,
    "MAR": 5.25,
    "APR": 5.09,
    "MAY": 4.36,
    "JUN": 4.14,
    "JUL": 4.32,
    "AUG": 5.25,
    "SEP": 4.9,
    "OCT": 5,
    "NOV": 5.23,
    "DEC": 5.45
  },
  {
    "LONG": -48.7,
    "LAT": -23.92,
    "CIDADE": "Taquarivaí",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.94,
    "FEB": 5.27,
    "MAR": 5.06,
    "APR": 4.93,
    "MAY": 4.25,
    "JUN": 4.03,
    "JUL": 4.17,
    "AUG": 5.09,
    "SEP": 4.69,
    "OCT": 4.76,
    "NOV": 5,
    "DEC": 5.27
  },
  {
    "LONG": -4.8,
    "LAT": -23.88,
    "CIDADE": "São Miguel Arcanjo",
    "UF": "SÃO PAULO",
    "anual": 4.74,
    "JAN": 4.88,
    "FEB": 5.29,
    "MAR": 4.97,
    "APR": 4.89,
    "MAY": 4.23,
    "JUN": 4.12,
    "JUL": 4.13,
    "AUG": 4.99,
    "SEP": 4.56,
    "OCT": 4.65,
    "NOV": 4.96,
    "DEC": 5.21
  },
  {
    "LONG": -47.07,
    "LAT": -23.92,
    "CIDADE": "Juquitiba",
    "UF": "SÃO PAULO",
    "anual": 4.42,
    "JAN": 4.5,
    "FEB": 5.05,
    "MAR": 4.65,
    "APR": 4.56,
    "MAY": 4.08,
    "JUN": 3.96,
    "JUL": 3.94,
    "AUG": 4.73,
    "SEP": 4.21,
    "OCT": 4.16,
    "NOV": 4.45,
    "DEC": 4.79
  },
  {
    "LONG": -46.42,
    "LAT": -23.89,
    "CIDADE": "Cubatão",
    "UF": "SÃO PAULO",
    "anual": 4.05,
    "JAN": 4.35,
    "FEB": 4.81,
    "MAR": 4.34,
    "APR": 4.31,
    "MAY": 3.88,
    "JUN": 3.64,
    "JUL": 3.61,
    "AUG": 4.15,
    "SEP": 3.62,
    "OCT": 3.63,
    "NOV": 3.9,
    "DEC": 4.38
  },
  {
    "LONG": -49.43,
    "LAT": -23.83,
    "CIDADE": "Riversul",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 5.14,
    "FEB": 5.52,
    "MAR": 5.37,
    "APR": 5.17,
    "MAY": 4.44,
    "JUN": 4.11,
    "JUL": 4.32,
    "AUG": 5.29,
    "SEP": 4.97,
    "OCT": 5.06,
    "NOV": 5.36,
    "DEC": 5.51
  },
  {
    "LONG": -48.6,
    "LAT": -23.8,
    "CIDADE": "Buri",
    "UF": "SÃO PAULO",
    "anual": 4.82,
    "JAN": 4.96,
    "FEB": 5.33,
    "MAR": 5.09,
    "APR": 4.96,
    "MAY": 4.25,
    "JUN": 4.07,
    "JUL": 4.22,
    "AUG": 5.09,
    "SEP": 4.73,
    "OCT": 4.81,
    "NOV": 5.04,
    "DEC": 5.28
  },
  {
    "LONG": -47.72,
    "LAT": -23.81,
    "CIDADE": "Pilar do Sul",
    "UF": "SÃO PAULO",
    "anual": 4.91,
    "JAN": 4.87,
    "FEB": 5.37,
    "MAR": 5.13,
    "APR": 5.07,
    "MAY": 4.38,
    "JUN": 4.33,
    "JUL": 4.33,
    "AUG": 5.19,
    "SEP": 4.84,
    "OCT": 4.92,
    "NOV": 5.12,
    "DEC": 5.36
  },
  {
    "LONG": -46.94,
    "LAT": -23.85,
    "CIDADE": "São Lourenço da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.48,
    "JAN": 4.55,
    "FEB": 5.07,
    "MAR": 4.67,
    "APR": 4.64,
    "MAY": 4.08,
    "JUN": 4.02,
    "JUL": 4,
    "AUG": 4.87,
    "SEP": 4.3,
    "OCT": 4.27,
    "NOV": 4.49,
    "DEC": 4.81
  },
  {
    "LONG": -4.68,
    "LAT": -23.83,
    "CIDADE": "Embu-Guaçu",
    "UF": "SÃO PAULO",
    "anual": 4.49,
    "JAN": 4.65,
    "FEB": 5.12,
    "MAR": 4.72,
    "APR": 4.65,
    "MAY": 4.07,
    "JUN": 3.97,
    "JUL": 4,
    "AUG": 4.83,
    "SEP": 4.28,
    "OCT": 4.24,
    "NOV": 4.49,
    "DEC": 4.84
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.38,
    "CIDADE": "Bertioga",
    "UF": "SÃO PAULO",
    "anual": 4.16,
    "JAN": 4.4,
    "FEB": 4.91,
    "MAR": 4.37,
    "APR": 4.34,
    "MAY": 3.92,
    "JUN": 3.72,
    "JUL": 3.75,
    "AUG": 4.37,
    "SEP": 3.83,
    "OCT": 3.86,
    "NOV": 4.02,
    "DEC": 4.46
  },
  {
    "LONG": -45.41,
    "LAT": -23.8,
    "CIDADE": "São Sebastião",
    "UF": "SÃO PAULO",
    "anual": 4.41,
    "JAN": 4.96,
    "FEB": 5.37,
    "MAR": 4.74,
    "APR": 4.7,
    "MAY": 4.09,
    "JUN": 3.82,
    "JUL": 3.87,
    "AUG": 4.46,
    "SEP": 3.87,
    "OCT": 4.03,
    "NOV": 4.28,
    "DEC": 4.72
  },
  {
    "LONG": -45.36,
    "LAT": -2.38,
    "CIDADE": "Ilhabela",
    "UF": "SÃO PAULO",
    "anual": 4.59,
    "JAN": 5.11,
    "FEB": 5.57,
    "MAR": 4.92,
    "APR": 4.86,
    "MAY": 4.19,
    "JUN": 3.93,
    "JUL": 3.96,
    "AUG": 4.64,
    "SEP": 4.12,
    "OCT": 4.33,
    "NOV": 4.54,
    "DEC": 4.92
  },
  {
    "LONG": -49.48,
    "LAT": -23.7,
    "CIDADE": "Itaporanga",
    "UF": "SÃO PAULO",
    "anual": 5.03,
    "JAN": 5.14,
    "FEB": 5.56,
    "MAR": 5.36,
    "APR": 5.19,
    "MAY": 4.43,
    "JUN": 4.14,
    "JUL": 4.33,
    "AUG": 5.3,
    "SEP": 4.97,
    "OCT": 5.1,
    "NOV": 5.33,
    "DEC": 5.53
  },
  {
    "LONG": -4.74,
    "LAT": -23.71,
    "CIDADE": "Piedade",
    "UF": "SÃO PAULO",
    "anual": 4.96,
    "JAN": 4.89,
    "FEB": 5.4,
    "MAR": 5.21,
    "APR": 5.07,
    "MAY": 4.47,
    "JUN": 4.39,
    "JUL": 4.39,
    "AUG": 5.31,
    "SEP": 4.98,
    "OCT": 5,
    "NOV": 5.12,
    "DEC": 5.34
  },
  {
    "LONG": -47.22,
    "LAT": -23.66,
    "CIDADE": "Ibiúna",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.75,
    "FEB": 5.3,
    "MAR": 5.01,
    "APR": 4.93,
    "MAY": 4.34,
    "JUN": 4.21,
    "JUL": 4.23,
    "AUG": 5.15,
    "SEP": 4.7,
    "OCT": 4.77,
    "NOV": 4.9,
    "DEC": 5.16
  },
  {
    "LONG": -46.86,
    "LAT": -23.72,
    "CIDADE": "Itapecerica da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.62,
    "JAN": 4.73,
    "FEB": 5.25,
    "MAR": 4.86,
    "APR": 4.78,
    "MAY": 4.15,
    "JUN": 4.04,
    "JUL": 4.06,
    "AUG": 4.96,
    "SEP": 4.43,
    "OCT": 4.48,
    "NOV": 4.67,
    "DEC": 5.01
  },
  {
    "LONG": -46.62,
    "LAT": -23.68,
    "CIDADE": "Diadema",
    "UF": "SÃO PAULO",
    "anual": 4.6,
    "JAN": 4.74,
    "FEB": 5.19,
    "MAR": 4.82,
    "APR": 4.75,
    "MAY": 4.16,
    "JUN": 4.01,
    "JUL": 4.07,
    "AUG": 4.87,
    "SEP": 4.4,
    "OCT": 4.45,
    "NOV": 4.68,
    "DEC": 5.01
  },
  {
    "LONG": -46.54,
    "LAT": -23.67,
    "CIDADE": "Santo André",
    "UF": "SÃO PAULO",
    "anual": 4.55,
    "JAN": 4.6,
    "FEB": 5.17,
    "MAR": 4.78,
    "APR": 4.67,
    "MAY": 4.13,
    "JUN": 3.99,
    "JUL": 4.05,
    "AUG": 4.83,
    "SEP": 4.39,
    "OCT": 4.42,
    "NOV": 4.61,
    "DEC": 4.95
  },
  {
    "LONG": -4.66,
    "LAT": -23.69,
    "CIDADE": "São Bernardo do Campo",
    "UF": "SÃO PAULO",
    "anual": 4.55,
    "JAN": 4.6,
    "FEB": 5.17,
    "MAR": 4.78,
    "APR": 4.67,
    "MAY": 4.13,
    "JUN": 3.99,
    "JUL": 4.05,
    "AUG": 4.83,
    "SEP": 4.39,
    "OCT": 4.42,
    "NOV": 4.61,
    "DEC": 4.95
  },
  {
    "LONG": -46.41,
    "LAT": -23.71,
    "CIDADE": "Ribeirão Pires",
    "UF": "SÃO PAULO",
    "anual": 4.49,
    "JAN": 4.54,
    "FEB": 5.16,
    "MAR": 4.72,
    "APR": 4.61,
    "MAY": 4.08,
    "JUN": 3.92,
    "JUL": 3.99,
    "AUG": 4.74,
    "SEP": 4.31,
    "OCT": 4.36,
    "NOV": 4.54,
    "DEC": 4.9
  },
  {
    "LONG": -46.46,
    "LAT": -23.67,
    "CIDADE": "Mauá",
    "UF": "SÃO PAULO",
    "anual": 4.49,
    "JAN": 4.54,
    "FEB": 5.16,
    "MAR": 4.72,
    "APR": 4.61,
    "MAY": 4.08,
    "JUN": 3.92,
    "JUL": 3.99,
    "AUG": 4.74,
    "SEP": 4.31,
    "OCT": 4.36,
    "NOV": 4.54,
    "DEC": 4.9
  },
  {
    "LONG": -46.4,
    "LAT": -23.74,
    "CIDADE": "Rio Grande da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.45,
    "JAN": 4.57,
    "FEB": 5.16,
    "MAR": 4.71,
    "APR": 4.55,
    "MAY": 4.03,
    "JUN": 3.86,
    "JUL": 3.94,
    "AUG": 4.72,
    "SEP": 4.26,
    "OCT": 4.33,
    "NOV": 4.46,
    "DEC": 4.87
  },
  {
    "LONG": -49.56,
    "LAT": -23.63,
    "CIDADE": "Barão de Antonina",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 5.23,
    "FEB": 5.63,
    "MAR": 5.47,
    "APR": 5.26,
    "MAY": 4.42,
    "JUN": 4.16,
    "JUL": 4.36,
    "AUG": 5.31,
    "SEP": 4.97,
    "OCT": 5.15,
    "NOV": 5.38,
    "DEC": 5.59
  },
  {
    "LONG": -49.31,
    "LAT": -2.36,
    "CIDADE": "Coronel Macedo",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 5.13,
    "FEB": 5.5,
    "MAR": 5.35,
    "APR": 5.18,
    "MAY": 4.44,
    "JUN": 4.18,
    "JUL": 4.36,
    "AUG": 5.3,
    "SEP": 4.95,
    "OCT": 5.09,
    "NOV": 5.3,
    "DEC": 5.46
  },
  {
    "LONG": -48.48,
    "LAT": -2.36,
    "CIDADE": "Campina do Monte Alegre",
    "UF": "SÃO PAULO",
    "anual": 4.91,
    "JAN": 4.95,
    "FEB": 5.43,
    "MAR": 5.19,
    "APR": 5.03,
    "MAY": 4.31,
    "JUN": 4.2,
    "JUL": 4.25,
    "AUG": 5.2,
    "SEP": 4.88,
    "OCT": 4.95,
    "NOV": 5.13,
    "DEC": 5.35
  },
  {
    "LONG": -48.05,
    "LAT": -23.59,
    "CIDADE": "Itapetininga",
    "UF": "SÃO PAULO",
    "anual": 4.91,
    "JAN": 4.87,
    "FEB": 5.39,
    "MAR": 5.13,
    "APR": 5.06,
    "MAY": 4.39,
    "JUN": 4.28,
    "JUL": 4.36,
    "AUG": 5.26,
    "SEP": 4.9,
    "OCT": 4.94,
    "NOV": 5.06,
    "DEC": 5.3
  },
  {
    "LONG": -47.9,
    "LAT": -23.55,
    "CIDADE": "Alambari",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.93,
    "FEB": 5.44,
    "MAR": 5.22,
    "APR": 5.15,
    "MAY": 4.47,
    "JUN": 4.36,
    "JUL": 4.4,
    "AUG": 5.33,
    "SEP": 4.94,
    "OCT": 5.02,
    "NOV": 5.15,
    "DEC": 5.31
  },
  {
    "LONG": -47.83,
    "LAT": -23.64,
    "CIDADE": "Sarapuí",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.93,
    "FEB": 5.44,
    "MAR": 5.22,
    "APR": 5.15,
    "MAY": 4.47,
    "JUN": 4.36,
    "JUL": 4.4,
    "AUG": 5.33,
    "SEP": 4.94,
    "OCT": 5.02,
    "NOV": 5.15,
    "DEC": 5.31
  },
  {
    "LONG": -47.57,
    "LAT": -23.65,
    "CIDADE": "Salto de Pirapora",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.94,
    "FEB": 5.44,
    "MAR": 5.22,
    "APR": 5.12,
    "MAY": 4.46,
    "JUN": 4.37,
    "JUL": 4.42,
    "AUG": 5.36,
    "SEP": 4.97,
    "OCT": 5.02,
    "NOV": 5.11,
    "DEC": 5.32
  },
  {
    "LONG": -47.02,
    "LAT": -23.6,
    "CIDADE": "Vargem Grande Paulista",
    "UF": "SÃO PAULO",
    "anual": 4.78,
    "JAN": 4.77,
    "FEB": 5.31,
    "MAR": 5.04,
    "APR": 4.91,
    "MAY": 4.32,
    "JUN": 4.24,
    "JUL": 4.24,
    "AUG": 5.18,
    "SEP": 4.7,
    "OCT": 4.72,
    "NOV": 4.85,
    "DEC": 5.12
  },
  {
    "LONG": -46.92,
    "LAT": -23.6,
    "CIDADE": "Cotia",
    "UF": "SÃO PAULO",
    "anual": 4.73,
    "JAN": 4.82,
    "FEB": 5.3,
    "MAR": 5,
    "APR": 4.86,
    "MAY": 4.27,
    "JUN": 4.15,
    "JUL": 4.17,
    "AUG": 5.09,
    "SEP": 4.58,
    "OCT": 4.67,
    "NOV": 4.8,
    "DEC": 5.11
  },
  {
    "LONG": -46.86,
    "LAT": -23.64,
    "CIDADE": "Embu das Artes",
    "UF": "SÃO PAULO",
    "anual": 4.72,
    "JAN": 4.82,
    "FEB": 5.32,
    "MAR": 4.96,
    "APR": 4.85,
    "MAY": 4.26,
    "JUN": 4.11,
    "JUL": 4.15,
    "AUG": 5.06,
    "SEP": 4.58,
    "OCT": 4.67,
    "NOV": 4.81,
    "DEC": 5.12
  },
  {
    "LONG": -46.75,
    "LAT": -23.6,
    "CIDADE": "Taboão da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.7,
    "JAN": 4.77,
    "FEB": 5.28,
    "MAR": 4.92,
    "APR": 4.81,
    "MAY": 4.26,
    "JUN": 4.11,
    "JUL": 4.14,
    "AUG": 5.03,
    "SEP": 4.55,
    "OCT": 4.65,
    "NOV": 4.78,
    "DEC": 5.1
  },
  {
    "LONG": -46.56,
    "LAT": -23.62,
    "CIDADE": "São Caetano do Sul",
    "UF": "SÃO PAULO",
    "anual": 4.64,
    "JAN": 4.68,
    "FEB": 5.2,
    "MAR": 4.86,
    "APR": 4.74,
    "MAY": 4.2,
    "JUN": 4.09,
    "JUL": 4.14,
    "AUG": 4.98,
    "SEP": 4.5,
    "OCT": 4.59,
    "NOV": 4.69,
    "DEC": 5.02
  },
  {
    "LONG": -46.04,
    "LAT": -23.57,
    "CIDADE": "Biritiba-Mirim",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.82,
    "FEB": 5.4,
    "MAR": 4.87,
    "APR": 4.76,
    "MAY": 4.14,
    "JUN": 3.97,
    "JUL": 4.08,
    "AUG": 4.89,
    "SEP": 4.43,
    "OCT": 4.58,
    "NOV": 4.65,
    "DEC": 5.02
  },
  {
    "LONG": -45.41,
    "LAT": -2.36,
    "CIDADE": "Caraguatatuba",
    "UF": "SÃO PAULO",
    "anual": 4.43,
    "JAN": 4.79,
    "FEB": 5.34,
    "MAR": 4.78,
    "APR": 4.65,
    "MAY": 4.1,
    "JUN": 3.89,
    "JUL": 3.92,
    "AUG": 4.55,
    "SEP": 4.02,
    "OCT": 4.15,
    "NOV": 4.24,
    "DEC": 4.74
  },
  {
    "LONG": -49.41,
    "LAT": -2.35,
    "CIDADE": "Taguaí",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 5.17,
    "FEB": 5.58,
    "MAR": 5.43,
    "APR": 5.22,
    "MAY": 4.48,
    "JUN": 4.25,
    "JUL": 4.41,
    "AUG": 5.33,
    "SEP": 4.98,
    "OCT": 5.16,
    "NOV": 5.38,
    "DEC": 5.53
  },
  {
    "LONG": -49.24,
    "LAT": -2.35,
    "CIDADE": "Taquarituba",
    "UF": "SÃO PAULO",
    "anual": 4.97,
    "JAN": 5.05,
    "FEB": 5.43,
    "MAR": 5.25,
    "APR": 5.11,
    "MAY": 4.41,
    "JUN": 4.18,
    "JUL": 4.35,
    "AUG": 5.28,
    "SEP": 4.93,
    "OCT": 5.08,
    "NOV": 5.26,
    "DEC": 5.36
  },
  {
    "LONG": -48.41,
    "LAT": -23.49,
    "CIDADE": "Angatuba",
    "UF": "SÃO PAULO",
    "anual": 4.93,
    "JAN": 4.91,
    "FEB": 5.38,
    "MAR": 5.21,
    "APR": 5.1,
    "MAY": 4.37,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 5.27,
    "SEP": 4.91,
    "OCT": 4.95,
    "NOV": 5.15,
    "DEC": 5.33
  },
  {
    "LONG": -47.74,
    "LAT": -23.47,
    "CIDADE": "Capela do Alto",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 4.96,
    "FEB": 5.48,
    "MAR": 5.28,
    "APR": 5.15,
    "MAY": 4.48,
    "JUN": 4.4,
    "JUL": 4.45,
    "AUG": 5.36,
    "SEP": 5,
    "OCT": 5.11,
    "NOV": 5.19,
    "DEC": 5.41
  },
  {
    "LONG": -4.76,
    "LAT": -23.5,
    "CIDADE": "Araçoiaba da Serra",
    "UF": "SÃO PAULO",
    "anual": 5,
    "JAN": 4.95,
    "FEB": 5.47,
    "MAR": 5.26,
    "APR": 5.13,
    "MAY": 4.51,
    "JUN": 4.41,
    "JUL": 4.43,
    "AUG": 5.37,
    "SEP": 4.99,
    "OCT": 5.06,
    "NOV": 5.13,
    "DEC": 5.34
  },
  {
    "LONG": -47.45,
    "LAT": -23.5,
    "CIDADE": "Sorocaba",
    "UF": "SÃO PAULO",
    "anual": 5.01,
    "JAN": 4.98,
    "FEB": 5.48,
    "MAR": 5.19,
    "APR": 5.12,
    "MAY": 4.53,
    "JUN": 4.41,
    "JUL": 4.44,
    "AUG": 5.35,
    "SEP": 5.01,
    "OCT": 5.1,
    "NOV": 5.11,
    "DEC": 5.37
  },
  {
    "LONG": -47.44,
    "LAT": -23.55,
    "CIDADE": "Votorantim",
    "UF": "SÃO PAULO",
    "anual": 5.01,
    "JAN": 4.98,
    "FEB": 5.48,
    "MAR": 5.19,
    "APR": 5.12,
    "MAY": 4.53,
    "JUN": 4.41,
    "JUL": 4.44,
    "AUG": 5.35,
    "SEP": 5.01,
    "OCT": 5.1,
    "NOV": 5.11,
    "DEC": 5.37
  },
  {
    "LONG": -4.73,
    "LAT": -2.35,
    "CIDADE": "Alumínio",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 4.96,
    "FEB": 5.49,
    "MAR": 5.25,
    "APR": 5.13,
    "MAY": 4.55,
    "JUN": 4.42,
    "JUL": 4.47,
    "AUG": 5.4,
    "SEP": 4.98,
    "OCT": 5.06,
    "NOV": 5.15,
    "DEC": 5.36
  },
  {
    "LONG": -47.14,
    "LAT": -23.52,
    "CIDADE": "São Roque",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.9,
    "FEB": 5.44,
    "MAR": 5.21,
    "APR": 5.09,
    "MAY": 4.49,
    "JUN": 4.37,
    "JUL": 4.39,
    "AUG": 5.35,
    "SEP": 4.91,
    "OCT": 4.96,
    "NOV": 5.04,
    "DEC": 5.29
  },
  {
    "LONG": -47.19,
    "LAT": -23.54,
    "CIDADE": "Mairinque",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.9,
    "FEB": 5.44,
    "MAR": 5.21,
    "APR": 5.09,
    "MAY": 4.49,
    "JUN": 4.37,
    "JUL": 4.39,
    "AUG": 5.35,
    "SEP": 4.91,
    "OCT": 4.96,
    "NOV": 5.04,
    "DEC": 5.29
  },
  {
    "LONG": -46.9,
    "LAT": -2.35,
    "CIDADE": "Jandira",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.8,
    "FEB": 5.31,
    "MAR": 5,
    "APR": 4.94,
    "MAY": 4.37,
    "JUN": 4.22,
    "JUL": 4.25,
    "AUG": 5.17,
    "SEP": 4.68,
    "OCT": 4.78,
    "NOV": 4.86,
    "DEC": 5.13
  },
  {
    "LONG": -46.93,
    "LAT": -23.55,
    "CIDADE": "Itapevi",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.8,
    "FEB": 5.31,
    "MAR": 5,
    "APR": 4.94,
    "MAY": 4.37,
    "JUN": 4.22,
    "JUL": 4.25,
    "AUG": 5.17,
    "SEP": 4.68,
    "OCT": 4.78,
    "NOV": 4.86,
    "DEC": 5.13
  },
  {
    "LONG": -46.84,
    "LAT": -23.52,
    "CIDADE": "Carapicuíba",
    "UF": "SÃO PAULO",
    "anual": 4.75,
    "JAN": 4.76,
    "FEB": 5.32,
    "MAR": 4.96,
    "APR": 4.88,
    "MAY": 4.32,
    "JUN": 4.16,
    "JUL": 4.22,
    "AUG": 5.13,
    "SEP": 4.65,
    "OCT": 4.71,
    "NOV": 4.81,
    "DEC": 5.13
  },
  {
    "LONG": -46.88,
    "LAT": -23.51,
    "CIDADE": "Barueri",
    "UF": "SÃO PAULO",
    "anual": 4.75,
    "JAN": 4.76,
    "FEB": 5.32,
    "MAR": 4.96,
    "APR": 4.88,
    "MAY": 4.32,
    "JUN": 4.16,
    "JUL": 4.22,
    "AUG": 5.13,
    "SEP": 4.65,
    "OCT": 4.71,
    "NOV": 4.81,
    "DEC": 5.13
  },
  {
    "LONG": -4.68,
    "LAT": -23.53,
    "CIDADE": "Osasco",
    "UF": "SÃO PAULO",
    "anual": 4.72,
    "JAN": 4.74,
    "FEB": 5.29,
    "MAR": 4.92,
    "APR": 4.83,
    "MAY": 4.29,
    "JUN": 4.15,
    "JUL": 4.19,
    "AUG": 5.1,
    "SEP": 4.59,
    "OCT": 4.67,
    "NOV": 4.76,
    "DEC": 5.11
  },
  {
    "LONG": -46.64,
    "LAT": -23.53,
    "CIDADE": "São Paulo",
    "UF": "SÃO PAULO",
    "anual": 4.67,
    "JAN": 4.71,
    "FEB": 5.24,
    "MAR": 4.89,
    "APR": 4.75,
    "MAY": 4.24,
    "JUN": 4.11,
    "JUL": 4.12,
    "AUG": 5.02,
    "SEP": 4.56,
    "OCT": 4.66,
    "NOV": 4.71,
    "DEC": 5.05
  },
  {
    "LONG": -46.53,
    "LAT": -23.45,
    "CIDADE": "Guarulhos",
    "UF": "SÃO PAULO",
    "anual": 4.66,
    "JAN": 4.69,
    "FEB": 5.27,
    "MAR": 4.88,
    "APR": 4.75,
    "MAY": 4.21,
    "JUN": 4.11,
    "JUL": 4.15,
    "AUG": 5.02,
    "SEP": 4.52,
    "OCT": 4.63,
    "NOV": 4.67,
    "DEC": 5.02
  },
  {
    "LONG": -46.31,
    "LAT": -23.55,
    "CIDADE": "Suzano",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.79,
    "FEB": 5.27,
    "MAR": 4.83,
    "APR": 4.71,
    "MAY": 4.12,
    "JUN": 4.01,
    "JUL": 4.09,
    "AUG": 4.89,
    "SEP": 4.52,
    "OCT": 4.62,
    "NOV": 4.7,
    "DEC": 5.03
  },
  {
    "LONG": -46.35,
    "LAT": -2.35,
    "CIDADE": "Itaquaquecetuba",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.79,
    "FEB": 5.27,
    "MAR": 4.83,
    "APR": 4.71,
    "MAY": 4.12,
    "JUN": 4.01,
    "JUL": 4.09,
    "AUG": 4.89,
    "SEP": 4.52,
    "OCT": 4.62,
    "NOV": 4.7,
    "DEC": 5.03
  },
  {
    "LONG": -46.35,
    "LAT": -23.53,
    "CIDADE": "Poá",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.79,
    "FEB": 5.27,
    "MAR": 4.83,
    "APR": 4.71,
    "MAY": 4.12,
    "JUN": 4.01,
    "JUL": 4.09,
    "AUG": 4.89,
    "SEP": 4.52,
    "OCT": 4.62,
    "NOV": 4.7,
    "DEC": 5.03
  },
  {
    "LONG": -46.37,
    "LAT": -23.54,
    "CIDADE": "Ferraz de Vasconcelos",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.79,
    "FEB": 5.27,
    "MAR": 4.83,
    "APR": 4.71,
    "MAY": 4.12,
    "JUN": 4.01,
    "JUL": 4.09,
    "AUG": 4.89,
    "SEP": 4.52,
    "OCT": 4.62,
    "NOV": 4.7,
    "DEC": 5.03
  },
  {
    "LONG": -46.19,
    "LAT": -23.52,
    "CIDADE": "Mogi das Cruzes",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.79,
    "FEB": 5.34,
    "MAR": 4.91,
    "APR": 4.7,
    "MAY": 4.1,
    "JUN": 3.95,
    "JUL": 4.04,
    "AUG": 4.91,
    "SEP": 4.48,
    "OCT": 4.65,
    "NOV": 4.69,
    "DEC": 5.03
  },
  {
    "LONG": -45.85,
    "LAT": -23.53,
    "CIDADE": "Salesópolis",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.95,
    "FEB": 5.49,
    "MAR": 5.02,
    "APR": 4.92,
    "MAY": 4.24,
    "JUN": 4.17,
    "JUL": 4.2,
    "AUG": 5.06,
    "SEP": 4.63,
    "OCT": 4.85,
    "NOV": 4.85,
    "DEC": 5.17
  },
  {
    "LONG": -49.51,
    "LAT": -23.39,
    "CIDADE": "Fartura",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 5.24,
    "FEB": 5.65,
    "MAR": 5.5,
    "APR": 5.26,
    "MAY": 4.51,
    "JUN": 4.24,
    "JUL": 4.45,
    "AUG": 5.35,
    "SEP": 4.99,
    "OCT": 5.22,
    "NOV": 5.43,
    "DEC": 5.65
  },
  {
    "LONG": -49.09,
    "LAT": -23.42,
    "CIDADE": "Itaí",
    "UF": "SÃO PAULO",
    "anual": 5.04,
    "JAN": 5.06,
    "FEB": 5.53,
    "MAR": 5.35,
    "APR": 5.21,
    "MAY": 4.47,
    "JUN": 4.26,
    "JUL": 4.4,
    "AUG": 5.33,
    "SEP": 4.96,
    "OCT": 5.16,
    "NOV": 5.33,
    "DEC": 5.43
  },
  {
    "LONG": -48.72,
    "LAT": -23.39,
    "CIDADE": "Paranapanema",
    "UF": "SÃO PAULO",
    "anual": 5.01,
    "JAN": 5.04,
    "FEB": 5.53,
    "MAR": 5.34,
    "APR": 5.15,
    "MAY": 4.41,
    "JUN": 4.22,
    "JUL": 4.37,
    "AUG": 5.28,
    "SEP": 4.91,
    "OCT": 5.12,
    "NOV": 5.32,
    "DEC": 5.46
  },
  {
    "LONG": -48.18,
    "LAT": -23.37,
    "CIDADE": "Guareí",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.89,
    "FEB": 5.43,
    "MAR": 5.22,
    "APR": 5.14,
    "MAY": 4.49,
    "JUN": 4.38,
    "JUL": 4.4,
    "AUG": 5.35,
    "SEP": 4.97,
    "OCT": 5.05,
    "NOV": 5.12,
    "DEC": 5.28
  },
  {
    "LONG": -47.69,
    "LAT": -23.35,
    "CIDADE": "Iperó",
    "UF": "SÃO PAULO",
    "anual": 5.06,
    "JAN": 5,
    "FEB": 5.53,
    "MAR": 5.31,
    "APR": 5.22,
    "MAY": 4.57,
    "JUN": 4.45,
    "JUL": 4.47,
    "AUG": 5.42,
    "SEP": 5.05,
    "OCT": 5.13,
    "NOV": 5.15,
    "DEC": 5.38
  },
  {
    "LONG": -47,
    "LAT": -2.34,
    "CIDADE": "Pirapora do Bom Jesus",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.88,
    "FEB": 5.44,
    "MAR": 5.2,
    "APR": 5.11,
    "MAY": 4.51,
    "JUN": 4.35,
    "JUL": 4.41,
    "AUG": 5.35,
    "SEP": 4.89,
    "OCT": 4.96,
    "NOV": 5.03,
    "DEC": 5.29
  },
  {
    "LONG": -47.06,
    "LAT": -23.44,
    "CIDADE": "Araçariguama",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.88,
    "FEB": 5.44,
    "MAR": 5.2,
    "APR": 5.11,
    "MAY": 4.51,
    "JUN": 4.35,
    "JUL": 4.41,
    "AUG": 5.35,
    "SEP": 4.89,
    "OCT": 4.96,
    "NOV": 5.03,
    "DEC": 5.29
  },
  {
    "LONG": -46.92,
    "LAT": -2.34,
    "CIDADE": "Santana de Parnaíba",
    "UF": "SÃO PAULO",
    "anual": 4.82,
    "JAN": 4.77,
    "FEB": 5.32,
    "MAR": 5,
    "APR": 4.96,
    "MAY": 4.4,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 5.25,
    "SEP": 4.73,
    "OCT": 4.8,
    "NOV": 4.85,
    "DEC": 5.15
  },
  {
    "LONG": -46.88,
    "LAT": -23.36,
    "CIDADE": "Cajamar",
    "UF": "SÃO PAULO",
    "anual": 4.78,
    "JAN": 4.76,
    "FEB": 5.31,
    "MAR": 4.97,
    "APR": 4.93,
    "MAY": 4.36,
    "JUN": 4.25,
    "JUL": 4.28,
    "AUG": 5.2,
    "SEP": 4.68,
    "OCT": 4.75,
    "NOV": 4.78,
    "DEC": 5.1
  },
  {
    "LONG": -46.74,
    "LAT": -23.36,
    "CIDADE": "Caieiras",
    "UF": "SÃO PAULO",
    "anual": 4.8,
    "JAN": 4.79,
    "FEB": 5.36,
    "MAR": 4.98,
    "APR": 4.95,
    "MAY": 4.37,
    "JUN": 4.26,
    "JUL": 4.28,
    "AUG": 5.2,
    "SEP": 4.72,
    "OCT": 4.78,
    "NOV": 4.81,
    "DEC": 5.14
  },
  {
    "LONG": -46.32,
    "LAT": -2.34,
    "CIDADE": "Arujá",
    "UF": "SÃO PAULO",
    "anual": 4.61,
    "JAN": 4.67,
    "FEB": 5.23,
    "MAR": 4.84,
    "APR": 4.72,
    "MAY": 4.14,
    "JUN": 4.04,
    "JUL": 4.07,
    "AUG": 4.97,
    "SEP": 4.51,
    "OCT": 4.59,
    "NOV": 4.59,
    "DEC": 4.97
  },
  {
    "LONG": -46.04,
    "LAT": -23.41,
    "CIDADE": "Guararema",
    "UF": "SÃO PAULO",
    "anual": 4.73,
    "JAN": 4.83,
    "FEB": 5.44,
    "MAR": 5,
    "APR": 4.84,
    "MAY": 4.17,
    "JUN": 3.97,
    "JUL": 4.09,
    "AUG": 5,
    "SEP": 4.63,
    "OCT": 4.81,
    "NOV": 4.79,
    "DEC": 5.16
  },
  {
    "LONG": -45.89,
    "LAT": -23.39,
    "CIDADE": "Santa Branca",
    "UF": "SÃO PAULO",
    "anual": 4.83,
    "JAN": 4.94,
    "FEB": 5.51,
    "MAR": 5.06,
    "APR": 4.98,
    "MAY": 4.27,
    "JUN": 4.17,
    "JUL": 4.22,
    "AUG": 5.08,
    "SEP": 4.69,
    "OCT": 4.95,
    "NOV": 4.9,
    "DEC": 5.2
  },
  {
    "LONG": -45.66,
    "LAT": -23.39,
    "CIDADE": "Paraibuna",
    "UF": "SÃO PAULO",
    "anual": 4.88,
    "JAN": 5.12,
    "FEB": 5.63,
    "MAR": 5.1,
    "APR": 5.04,
    "MAY": 4.33,
    "JUN": 4.22,
    "JUL": 4.25,
    "AUG": 5.11,
    "SEP": 4.69,
    "OCT": 4.93,
    "NOV": 4.91,
    "DEC": 5.26
  },
  {
    "LONG": -45.45,
    "LAT": -23.37,
    "CIDADE": "Natividade da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.83,
    "JAN": 5.1,
    "FEB": 5.56,
    "MAR": 5.05,
    "APR": 4.99,
    "MAY": 4.37,
    "JUN": 4.24,
    "JUL": 4.28,
    "AUG": 5.09,
    "SEP": 4.64,
    "OCT": 4.81,
    "NOV": 4.73,
    "DEC": 5.16
  },
  {
    "LONG": -45.08,
    "LAT": -23.43,
    "CIDADE": "Ubatuba",
    "UF": "SÃO PAULO",
    "anual": 4.23,
    "JAN": 4.42,
    "FEB": 5.02,
    "MAR": 4.42,
    "APR": 4.49,
    "MAY": 4.02,
    "JUN": 3.93,
    "JUL": 3.89,
    "AUG": 4.49,
    "SEP": 3.92,
    "OCT": 3.91,
    "NOV": 3.89,
    "DEC": 4.39
  },
  {
    "LONG": -49.48,
    "LAT": -2.33,
    "CIDADE": "Sarutaiá",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 5.07,
    "FEB": 5.48,
    "MAR": 5.35,
    "APR": 5.24,
    "MAY": 4.52,
    "JUN": 4.34,
    "JUL": 4.54,
    "AUG": 5.44,
    "SEP": 5.03,
    "OCT": 5.19,
    "NOV": 5.34,
    "DEC": 5.46
  },
  {
    "LONG": -49.38,
    "LAT": -2.33,
    "CIDADE": "Tejupá",
    "UF": "SÃO PAULO",
    "anual": 5.05,
    "JAN": 5.03,
    "FEB": 5.49,
    "MAR": 5.32,
    "APR": 5.19,
    "MAY": 4.46,
    "JUN": 4.29,
    "JUL": 4.47,
    "AUG": 5.39,
    "SEP": 5,
    "OCT": 5.17,
    "NOV": 5.33,
    "DEC": 5.45
  },
  {
    "LONG": -48.06,
    "LAT": -23.3,
    "CIDADE": "Quadra",
    "UF": "SÃO PAULO",
    "anual": 5.06,
    "JAN": 4.98,
    "FEB": 5.53,
    "MAR": 5.27,
    "APR": 5.23,
    "MAY": 4.54,
    "JUN": 4.45,
    "JUL": 4.48,
    "AUG": 5.42,
    "SEP": 5.06,
    "OCT": 5.15,
    "NOV": 5.22,
    "DEC": 5.35
  },
  {
    "LONG": -47.85,
    "LAT": -23.35,
    "CIDADE": "Tatuí",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.99,
    "FEB": 5.52,
    "MAR": 5.31,
    "APR": 5.22,
    "MAY": 4.57,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 5.44,
    "SEP": 5.07,
    "OCT": 5.15,
    "NOV": 5.2,
    "DEC": 5.36
  },
  {
    "LONG": -4.77,
    "LAT": -2.33,
    "CIDADE": "Boituva",
    "UF": "SÃO PAULO",
    "anual": 5.09,
    "JAN": 5.03,
    "FEB": 5.56,
    "MAR": 5.33,
    "APR": 5.24,
    "MAY": 4.62,
    "JUN": 4.48,
    "JUL": 4.54,
    "AUG": 5.45,
    "SEP": 5.09,
    "OCT": 5.17,
    "NOV": 5.21,
    "DEC": 5.39
  },
  {
    "LONG": -47.29,
    "LAT": -23.25,
    "CIDADE": "Itu",
    "UF": "SÃO PAULO",
    "anual": 5.09,
    "JAN": 5,
    "FEB": 5.55,
    "MAR": 5.31,
    "APR": 5.27,
    "MAY": 4.64,
    "JUN": 4.46,
    "JUL": 4.56,
    "AUG": 5.44,
    "SEP": 5.09,
    "OCT": 5.19,
    "NOV": 5.22,
    "DEC": 5.4
  },
  {
    "LONG": -47.14,
    "LAT": -23.31,
    "CIDADE": "Cabreúva",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.96,
    "FEB": 5.5,
    "MAR": 5.28,
    "APR": 5.26,
    "MAY": 4.64,
    "JUN": 4.44,
    "JUL": 4.54,
    "AUG": 5.46,
    "SEP": 5.08,
    "OCT": 5.18,
    "NOV": 5.18,
    "DEC": 5.37
  },
  {
    "LONG": -46.75,
    "LAT": -23.28,
    "CIDADE": "Francisco Morato",
    "UF": "SÃO PAULO",
    "anual": 4.9,
    "JAN": 4.85,
    "FEB": 5.42,
    "MAR": 5.09,
    "APR": 5.02,
    "MAY": 4.47,
    "JUN": 4.36,
    "JUL": 4.41,
    "AUG": 5.33,
    "SEP": 4.85,
    "OCT": 4.94,
    "NOV": 4.92,
    "DEC": 5.19
  },
  {
    "LONG": -46.73,
    "LAT": -23.32,
    "CIDADE": "Franco da Rocha",
    "UF": "SÃO PAULO",
    "anual": 4.9,
    "JAN": 4.85,
    "FEB": 5.42,
    "MAR": 5.09,
    "APR": 5.02,
    "MAY": 4.47,
    "JUN": 4.36,
    "JUL": 4.41,
    "AUG": 5.33,
    "SEP": 4.85,
    "OCT": 4.94,
    "NOV": 4.92,
    "DEC": 5.19
  },
  {
    "LONG": -46.59,
    "LAT": -23.32,
    "CIDADE": "Mairiporã",
    "UF": "SÃO PAULO",
    "anual": 4.87,
    "JAN": 4.79,
    "FEB": 5.38,
    "MAR": 5.07,
    "APR": 5,
    "MAY": 4.42,
    "JUN": 4.37,
    "JUL": 4.4,
    "AUG": 5.32,
    "SEP": 4.83,
    "OCT": 4.89,
    "NOV": 4.82,
    "DEC": 5.15
  },
  {
    "LONG": -46.22,
    "LAT": -23.32,
    "CIDADE": "Santa Isabel",
    "UF": "SÃO PAULO",
    "anual": 4.68,
    "JAN": 4.75,
    "FEB": 5.35,
    "MAR": 4.93,
    "APR": 4.79,
    "MAY": 4.16,
    "JUN": 4.07,
    "JUL": 4.07,
    "AUG": 5.03,
    "SEP": 4.56,
    "OCT": 4.69,
    "NOV": 4.66,
    "DEC": 5.06
  },
  {
    "LONG": -45.97,
    "LAT": -23.3,
    "CIDADE": "Jacareí",
    "UF": "SÃO PAULO",
    "anual": 4.76,
    "JAN": 4.86,
    "FEB": 5.43,
    "MAR": 5.01,
    "APR": 4.88,
    "MAY": 4.19,
    "JUN": 4.04,
    "JUL": 4.13,
    "AUG": 5.04,
    "SEP": 4.66,
    "OCT": 4.88,
    "NOV": 4.82,
    "DEC": 5.16
  },
  {
    "LONG": -45.69,
    "LAT": -23.25,
    "CIDADE": "Jambeiro",
    "UF": "SÃO PAULO",
    "anual": 4.88,
    "JAN": 5.06,
    "FEB": 5.57,
    "MAR": 5.09,
    "APR": 5.02,
    "MAY": 4.36,
    "JUN": 4.26,
    "JUL": 4.31,
    "AUG": 5.16,
    "SEP": 4.73,
    "OCT": 4.95,
    "NOV": 4.87,
    "DEC": 5.21
  },
  {
    "LONG": -45.54,
    "LAT": -23.26,
    "CIDADE": "Redenção da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.87,
    "JAN": 5.06,
    "FEB": 5.59,
    "MAR": 5.06,
    "APR": 5.01,
    "MAY": 4.38,
    "JUN": 4.26,
    "JUL": 4.33,
    "AUG": 5.17,
    "SEP": 4.72,
    "OCT": 4.9,
    "NOV": 4.8,
    "DEC": 5.18
  },
  {
    "LONG": -49.61,
    "LAT": -2.32,
    "CIDADE": "Timburi",
    "UF": "SÃO PAULO",
    "anual": 5.13,
    "JAN": 5.12,
    "FEB": 5.56,
    "MAR": 5.44,
    "APR": 5.33,
    "MAY": 4.56,
    "JUN": 4.35,
    "JUL": 4.54,
    "AUG": 5.45,
    "SEP": 5.04,
    "OCT": 5.24,
    "NOV": 5.4,
    "DEC": 5.55
  },
  {
    "LONG": -49.38,
    "LAT": -23.2,
    "CIDADE": "Piraju",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 5.08,
    "FEB": 5.48,
    "MAR": 5.36,
    "APR": 5.22,
    "MAY": 4.48,
    "JUN": 4.32,
    "JUL": 4.48,
    "AUG": 5.39,
    "SEP": 5.02,
    "OCT": 5.19,
    "NOV": 5.38,
    "DEC": 5.51
  },
  {
    "LONG": -48.12,
    "LAT": -23.18,
    "CIDADE": "Porangaba",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 5.05,
    "FEB": 5.52,
    "MAR": 5.27,
    "APR": 5.22,
    "MAY": 4.55,
    "JUN": 4.43,
    "JUL": 4.53,
    "AUG": 5.43,
    "SEP": 5.07,
    "OCT": 5.14,
    "NOV": 5.23,
    "DEC": 5.36
  },
  {
    "LONG": -48.2,
    "LAT": -23.25,
    "CIDADE": "Torre de Pedra",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 5.05,
    "FEB": 5.52,
    "MAR": 5.27,
    "APR": 5.22,
    "MAY": 4.55,
    "JUN": 4.43,
    "JUL": 4.53,
    "AUG": 5.43,
    "SEP": 5.07,
    "OCT": 5.14,
    "NOV": 5.23,
    "DEC": 5.36
  },
  {
    "LONG": -47.95,
    "LAT": -23.23,
    "CIDADE": "Cesário Lange",
    "UF": "SÃO PAULO",
    "anual": 5.11,
    "JAN": 5.03,
    "FEB": 5.54,
    "MAR": 5.35,
    "APR": 5.28,
    "MAY": 4.6,
    "JUN": 4.5,
    "JUL": 4.53,
    "AUG": 5.48,
    "SEP": 5.12,
    "OCT": 5.22,
    "NOV": 5.24,
    "DEC": 5.39
  },
  {
    "LONG": -47.75,
    "LAT": -2.32,
    "CIDADE": "Cerquilho",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 5.07,
    "FEB": 5.57,
    "MAR": 5.35,
    "APR": 5.28,
    "MAY": 4.62,
    "JUN": 4.51,
    "JUL": 4.56,
    "AUG": 5.44,
    "SEP": 5.11,
    "OCT": 5.23,
    "NOV": 5.24,
    "DEC": 5.42
  },
  {
    "LONG": -47.53,
    "LAT": -23.21,
    "CIDADE": "Porto Feliz",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 5.04,
    "FEB": 5.61,
    "MAR": 5.36,
    "APR": 5.23,
    "MAY": 4.67,
    "JUN": 4.49,
    "JUL": 4.55,
    "AUG": 5.47,
    "SEP": 5.09,
    "OCT": 5.24,
    "NOV": 5.23,
    "DEC": 5.42
  },
  {
    "LONG": -47.29,
    "LAT": -23.2,
    "CIDADE": "Salto",
    "UF": "SÃO PAULO",
    "anual": 5.11,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 5.31,
    "APR": 5.27,
    "MAY": 4.66,
    "JUN": 4.48,
    "JUL": 4.59,
    "AUG": 5.46,
    "SEP": 5.1,
    "OCT": 5.21,
    "NOV": 5.21,
    "DEC": 5.41
  },
  {
    "LONG": -47.06,
    "LAT": -23.15,
    "CIDADE": "Itupeva",
    "UF": "SÃO PAULO",
    "anual": 5.1,
    "JAN": 4.93,
    "FEB": 5.51,
    "MAR": 5.27,
    "APR": 5.25,
    "MAY": 4.65,
    "JUN": 4.51,
    "JUL": 4.61,
    "AUG": 5.5,
    "SEP": 5.11,
    "OCT": 5.24,
    "NOV": 5.18,
    "DEC": 5.38
  },
  {
    "LONG": -46.9,
    "LAT": -23.19,
    "CIDADE": "Jundiaí",
    "UF": "SÃO PAULO",
    "anual": 5.03,
    "JAN": 4.93,
    "FEB": 5.46,
    "MAR": 5.23,
    "APR": 5.13,
    "MAY": 4.57,
    "JUN": 4.46,
    "JUL": 4.54,
    "AUG": 5.42,
    "SEP": 5.04,
    "OCT": 5.17,
    "NOV": 5.11,
    "DEC": 5.3
  },
  {
    "LONG": -46.82,
    "LAT": -23.21,
    "CIDADE": "Várzea Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.03,
    "JAN": 4.93,
    "FEB": 5.46,
    "MAR": 5.23,
    "APR": 5.13,
    "MAY": 4.57,
    "JUN": 4.46,
    "JUL": 4.54,
    "AUG": 5.42,
    "SEP": 5.04,
    "OCT": 5.17,
    "NOV": 5.11,
    "DEC": 5.3
  },
  {
    "LONG": -46.79,
    "LAT": -23.21,
    "CIDADE": "Campo Limpo Paulista",
    "UF": "SÃO PAULO",
    "anual": 5,
    "JAN": 4.9,
    "FEB": 5.48,
    "MAR": 5.17,
    "APR": 5.1,
    "MAY": 4.56,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.45,
    "SEP": 4.99,
    "OCT": 5.1,
    "NOV": 5.02,
    "DEC": 5.24
  },
  {
    "LONG": -46.4,
    "LAT": -23.18,
    "CIDADE": "Nazaré Paulista",
    "UF": "SÃO PAULO",
    "anual": 4.86,
    "JAN": 4.87,
    "FEB": 5.46,
    "MAR": 5.04,
    "APR": 4.96,
    "MAY": 4.4,
    "JUN": 4.28,
    "JUL": 4.31,
    "AUG": 5.27,
    "SEP": 4.78,
    "OCT": 4.9,
    "NOV": 4.83,
    "DEC": 5.21
  },
  {
    "LONG": -46.16,
    "LAT": -23.2,
    "CIDADE": "Igaratá",
    "UF": "SÃO PAULO",
    "anual": 4.69,
    "JAN": 4.75,
    "FEB": 5.29,
    "MAR": 4.93,
    "APR": 4.85,
    "MAY": 4.16,
    "JUN": 4.07,
    "JUL": 4.09,
    "AUG": 5.06,
    "SEP": 4.6,
    "OCT": 4.73,
    "NOV": 4.69,
    "DEC": 5.04
  },
  {
    "LONG": -45.88,
    "LAT": "#VALOR!",
    "CIDADE": "São José dos Campos",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.88,
    "FEB": 5.43,
    "MAR": 5.02,
    "APR": 4.9,
    "MAY": 4.23,
    "JUN": 4.14,
    "JUL": 4.23,
    "AUG": 5.13,
    "SEP": 4.72,
    "OCT": 4.89,
    "NOV": 4.81,
    "DEC": 5.15
  },
  {
    "LONG": -45.31,
    "LAT": -2.32,
    "CIDADE": "São Luíz do Paraitinga",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 5.15,
    "FEB": 5.59,
    "MAR": 5.12,
    "APR": 5.08,
    "MAY": 4.44,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 5.26,
    "SEP": 4.84,
    "OCT": 5,
    "NOV": 4.87,
    "DEC": 5.25
  },
  {
    "LONG": -49.63,
    "LAT": -2.31,
    "CIDADE": "Ipaussu",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5.06,
    "FEB": 5.54,
    "MAR": 5.46,
    "APR": 5.38,
    "MAY": 4.59,
    "JUN": 4.41,
    "JUL": 4.57,
    "AUG": 5.48,
    "SEP": 5.07,
    "OCT": 5.26,
    "NOV": 5.37,
    "DEC": 5.51
  },
  {
    "LONG": -49.05,
    "LAT": -2.31,
    "CIDADE": "Arandu",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.99,
    "FEB": 5.42,
    "MAR": 5.25,
    "APR": 5.25,
    "MAY": 4.57,
    "JUN": 4.41,
    "JUL": 4.53,
    "AUG": 5.47,
    "SEP": 5.09,
    "OCT": 5.18,
    "NOV": 5.28,
    "DEC": 5.39
  },
  {
    "LONG": -48.93,
    "LAT": -23.11,
    "CIDADE": "Avaré",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.98,
    "FEB": 5.42,
    "MAR": 5.25,
    "APR": 5.23,
    "MAY": 4.55,
    "JUN": 4.46,
    "JUL": 4.51,
    "AUG": 5.48,
    "SEP": 5.11,
    "OCT": 5.18,
    "NOV": 5.27,
    "DEC": 5.35
  },
  {
    "LONG": -48.62,
    "LAT": -23.11,
    "CIDADE": "Itatinga",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 4.89,
    "FEB": 5.36,
    "MAR": 5.22,
    "APR": 5.23,
    "MAY": 4.53,
    "JUN": 4.42,
    "JUL": 4.5,
    "AUG": 5.47,
    "SEP": 5.06,
    "OCT": 5.11,
    "NOV": 5.19,
    "DEC": 5.25
  },
  {
    "LONG": -48.37,
    "LAT": -2.31,
    "CIDADE": "Pardinho",
    "UF": "SÃO PAULO",
    "anual": 5.01,
    "JAN": 4.87,
    "FEB": 5.35,
    "MAR": 5.13,
    "APR": 5.19,
    "MAY": 4.54,
    "JUN": 4.47,
    "JUL": 4.52,
    "AUG": 5.42,
    "SEP": 5.06,
    "OCT": 5.13,
    "NOV": 5.13,
    "DEC": 5.25
  },
  {
    "LONG": -4.83,
    "LAT": -2.31,
    "CIDADE": "Bofete",
    "UF": "SÃO PAULO",
    "anual": 5.05,
    "JAN": 4.95,
    "FEB": 5.45,
    "MAR": 5.23,
    "APR": 5.26,
    "MAY": 4.56,
    "JUN": 4.5,
    "JUL": 4.54,
    "AUG": 5.43,
    "SEP": 5.05,
    "OCT": 5.12,
    "NOV": 5.18,
    "DEC": 5.3
  },
  {
    "LONG": -47.97,
    "LAT": -23.08,
    "CIDADE": "Pereiras",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5.07,
    "FEB": 5.59,
    "MAR": 5.38,
    "APR": 5.33,
    "MAY": 4.62,
    "JUN": 4.53,
    "JUL": 4.59,
    "AUG": 5.5,
    "SEP": 5.14,
    "OCT": 5.23,
    "NOV": 5.27,
    "DEC": 5.42
  },
  {
    "LONG": -4.78,
    "LAT": -2.31,
    "CIDADE": "Laranjal Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.13,
    "JAN": 5.06,
    "FEB": 5.58,
    "MAR": 5.33,
    "APR": 5.33,
    "MAY": 4.61,
    "JUN": 4.51,
    "JUL": 4.58,
    "AUG": 5.49,
    "SEP": 5.12,
    "OCT": 5.27,
    "NOV": 5.25,
    "DEC": 5.42
  },
  {
    "LONG": -47.72,
    "LAT": -23.11,
    "CIDADE": "Tietê",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.06,
    "FEB": 5.63,
    "MAR": 5.4,
    "APR": 5.33,
    "MAY": 4.67,
    "JUN": 4.54,
    "JUL": 4.58,
    "AUG": 5.48,
    "SEP": 5.14,
    "OCT": 5.27,
    "NOV": 5.29,
    "DEC": 5.43
  },
  {
    "LONG": -47.79,
    "LAT": -23.09,
    "CIDADE": "Jumirim",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.06,
    "FEB": 5.63,
    "MAR": 5.4,
    "APR": 5.33,
    "MAY": 4.67,
    "JUN": 4.54,
    "JUL": 4.58,
    "AUG": 5.48,
    "SEP": 5.14,
    "OCT": 5.27,
    "NOV": 5.29,
    "DEC": 5.43
  },
  {
    "LONG": -47.21,
    "LAT": -23.08,
    "CIDADE": "Indaiatuba",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5.12,
    "FEB": 5.57,
    "MAR": 5.31,
    "APR": 5.31,
    "MAY": 4.63,
    "JUN": 4.51,
    "JUL": 4.64,
    "AUG": 5.47,
    "SEP": 5.14,
    "OCT": 5.28,
    "NOV": 5.2,
    "DEC": 5.46
  },
  {
    "LONG": -46.95,
    "LAT": -2.31,
    "CIDADE": "Louveira",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 4.98,
    "FEB": 5.53,
    "MAR": 5.27,
    "APR": 5.26,
    "MAY": 4.69,
    "JUN": 4.57,
    "JUL": 4.68,
    "AUG": 5.52,
    "SEP": 5.14,
    "OCT": 5.3,
    "NOV": 5.2,
    "DEC": 5.36
  },
  {
    "LONG": -46.73,
    "LAT": -23.1,
    "CIDADE": "Jarinu",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.97,
    "FEB": 5.51,
    "MAR": 5.27,
    "APR": 5.2,
    "MAY": 4.64,
    "JUN": 4.5,
    "JUL": 4.61,
    "AUG": 5.48,
    "SEP": 5.07,
    "OCT": 5.2,
    "NOV": 5.09,
    "DEC": 5.33
  },
  {
    "LONG": -46.56,
    "LAT": -23.12,
    "CIDADE": "Atibaia",
    "UF": "SÃO PAULO",
    "anual": 5.03,
    "JAN": 4.91,
    "FEB": 5.46,
    "MAR": 5.19,
    "APR": 5.17,
    "MAY": 4.59,
    "JUN": 4.5,
    "JUL": 4.55,
    "AUG": 5.47,
    "SEP": 5.04,
    "OCT": 5.14,
    "NOV": 5.03,
    "DEC": 5.32
  },
  {
    "LONG": -46.47,
    "LAT": -23.14,
    "CIDADE": "Bom Jesus dos Perdões",
    "UF": "SÃO PAULO",
    "anual": 5,
    "JAN": 4.87,
    "FEB": 5.48,
    "MAR": 5.14,
    "APR": 5.17,
    "MAY": 4.57,
    "JUN": 4.43,
    "JUL": 4.49,
    "AUG": 5.43,
    "SEP": 4.96,
    "OCT": 5.1,
    "NOV": 5,
    "DEC": 5.32
  },
  {
    "LONG": -46.36,
    "LAT": -2.31,
    "CIDADE": "Piracaia",
    "UF": "SÃO PAULO",
    "anual": 4.96,
    "JAN": 4.82,
    "FEB": 5.47,
    "MAR": 5.09,
    "APR": 5.08,
    "MAY": 4.55,
    "JUN": 4.39,
    "JUL": 4.43,
    "AUG": 5.41,
    "SEP": 4.92,
    "OCT": 5.04,
    "NOV": 4.97,
    "DEC": 5.28
  },
  {
    "LONG": -4.57,
    "LAT": -23.1,
    "CIDADE": "Caçapava",
    "UF": "SÃO PAULO",
    "anual": 4.86,
    "JAN": 4.86,
    "FEB": 5.41,
    "MAR": 5.1,
    "APR": 5.05,
    "MAY": 4.38,
    "JUN": 4.29,
    "JUL": 4.35,
    "AUG": 5.23,
    "SEP": 4.81,
    "OCT": 4.9,
    "NOV": 4.8,
    "DEC": 5.09
  },
  {
    "LONG": -45.19,
    "LAT": -23.09,
    "CIDADE": "Lagoinha",
    "UF": "SÃO PAULO",
    "anual": 4.93,
    "JAN": 4.97,
    "FEB": 5.49,
    "MAR": 5.02,
    "APR": 5.06,
    "MAY": 4.51,
    "JUN": 4.47,
    "JUL": 4.51,
    "AUG": 5.33,
    "SEP": 4.87,
    "OCT": 4.98,
    "NOV": 4.78,
    "DEC": 5.12
  },
  {
    "LONG": -44.96,
    "LAT": -23.07,
    "CIDADE": "Cunha",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 5.19,
    "FEB": 5.59,
    "MAR": 5.16,
    "APR": 5.18,
    "MAY": 4.55,
    "JUN": 4.51,
    "JUL": 4.56,
    "AUG": 5.34,
    "SEP": 4.96,
    "OCT": 5.09,
    "NOV": 4.88,
    "DEC": 5.25
  },
  {
    "LONG": -49.87,
    "LAT": -22.98,
    "CIDADE": "Ourinhos",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.09,
    "FEB": 5.53,
    "MAR": 5.42,
    "APR": 5.38,
    "MAY": 4.63,
    "JUN": 4.46,
    "JUL": 4.62,
    "AUG": 5.54,
    "SEP": 5.09,
    "OCT": 5.28,
    "NOV": 5.38,
    "DEC": 5.56
  },
  {
    "LONG": "#VALOR!",
    "LAT": -23.04,
    "CIDADE": "Chavantes",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.42,
    "APR": 5.43,
    "MAY": 4.65,
    "JUN": 4.47,
    "JUL": 4.64,
    "AUG": 5.57,
    "SEP": 5.11,
    "OCT": 5.28,
    "NOV": 5.38,
    "DEC": 5.52
  },
  {
    "LONG": -49.78,
    "LAT": -2.3,
    "CIDADE": "Canitar",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.42,
    "APR": 5.43,
    "MAY": 4.65,
    "JUN": 4.47,
    "JUL": 4.64,
    "AUG": 5.57,
    "SEP": 5.11,
    "OCT": 5.28,
    "NOV": 5.38,
    "DEC": 5.52
  },
  {
    "LONG": -49.47,
    "LAT": -2.3,
    "CIDADE": "Bernardino de Campos",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.07,
    "FEB": 5.51,
    "MAR": 5.38,
    "APR": 5.37,
    "MAY": 4.66,
    "JUN": 4.46,
    "JUL": 4.65,
    "AUG": 5.56,
    "SEP": 5.1,
    "OCT": 5.27,
    "NOV": 5.36,
    "DEC": 5.53
  },
  {
    "LONG": -49.32,
    "LAT": -2.3,
    "CIDADE": "Manduri",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 5,
    "FEB": 5.48,
    "MAR": 5.35,
    "APR": 5.34,
    "MAY": 4.61,
    "JUN": 4.44,
    "JUL": 4.62,
    "AUG": 5.52,
    "SEP": 5.06,
    "OCT": 5.24,
    "NOV": 5.33,
    "DEC": 5.45
  },
  {
    "LONG": -49.17,
    "LAT": -2.3,
    "CIDADE": "Cerqueira César",
    "UF": "SÃO PAULO",
    "anual": 5.11,
    "JAN": 4.99,
    "FEB": 5.46,
    "MAR": 5.3,
    "APR": 5.31,
    "MAY": 4.58,
    "JUN": 4.48,
    "JUL": 4.62,
    "AUG": 5.52,
    "SEP": 5.11,
    "OCT": 5.24,
    "NOV": 5.31,
    "DEC": 5.41
  },
  {
    "LONG": -48.01,
    "LAT": -23.02,
    "CIDADE": "Conchas",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.07,
    "FEB": 5.57,
    "MAR": 5.4,
    "APR": 5.34,
    "MAY": 4.64,
    "JUN": 4.5,
    "JUL": 4.6,
    "AUG": 5.51,
    "SEP": 5.14,
    "OCT": 5.23,
    "NOV": 5.29,
    "DEC": 5.44
  },
  {
    "LONG": -47.51,
    "LAT": -23,
    "CIDADE": "Capivari",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5.07,
    "FEB": 5.61,
    "MAR": 5.33,
    "APR": 5.3,
    "MAY": 4.71,
    "JUN": 4.54,
    "JUL": 4.62,
    "AUG": 5.49,
    "SEP": 5.14,
    "OCT": 5.3,
    "NOV": 5.21,
    "DEC": 5.4
  },
  {
    "LONG": -47.53,
    "LAT": -2.3,
    "CIDADE": "Rafard",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5.07,
    "FEB": 5.61,
    "MAR": 5.33,
    "APR": 5.3,
    "MAY": 4.71,
    "JUN": 4.54,
    "JUL": 4.62,
    "AUG": 5.49,
    "SEP": 5.14,
    "OCT": 5.3,
    "NOV": 5.21,
    "DEC": 5.4
  },
  {
    "LONG": -47.37,
    "LAT": -23.04,
    "CIDADE": "Elias Fausto",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.15,
    "FEB": 5.57,
    "MAR": 5.33,
    "APR": 5.37,
    "MAY": 4.71,
    "JUN": 4.57,
    "JUL": 4.67,
    "AUG": 5.53,
    "SEP": 5.17,
    "OCT": 5.31,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -46.98,
    "LAT": -23.03,
    "CIDADE": "Vinhedo",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.97,
    "FEB": 5.5,
    "MAR": 5.28,
    "APR": 5.29,
    "MAY": 4.69,
    "JUN": 4.6,
    "JUL": 4.71,
    "AUG": 5.53,
    "SEP": 5.19,
    "OCT": 5.33,
    "NOV": 5.21,
    "DEC": 5.36
  },
  {
    "LONG": -47,
    "LAT": -22.97,
    "CIDADE": "Valinhos",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.97,
    "FEB": 5.5,
    "MAR": 5.28,
    "APR": 5.29,
    "MAY": 4.69,
    "JUN": 4.6,
    "JUL": 4.71,
    "AUG": 5.53,
    "SEP": 5.19,
    "OCT": 5.33,
    "NOV": 5.21,
    "DEC": 5.36
  },
  {
    "LONG": -46.85,
    "LAT": -2.3,
    "CIDADE": "Itatiba",
    "UF": "SÃO PAULO",
    "anual": 5.13,
    "JAN": 4.97,
    "FEB": 5.55,
    "MAR": 5.28,
    "APR": 5.31,
    "MAY": 4.7,
    "JUN": 4.58,
    "JUL": 4.69,
    "AUG": 5.54,
    "SEP": 5.16,
    "OCT": 5.27,
    "NOV": 5.18,
    "DEC": 5.37
  },
  {
    "LONG": -46.54,
    "LAT": -22.95,
    "CIDADE": "Bragança Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.06,
    "JAN": 4.87,
    "FEB": 5.46,
    "MAR": 5.18,
    "APR": 5.21,
    "MAY": 4.63,
    "JUN": 4.49,
    "JUL": 4.62,
    "AUG": 5.53,
    "SEP": 5.11,
    "OCT": 5.18,
    "NOV": 5.1,
    "DEC": 5.32
  },
  {
    "LONG": -45.84,
    "LAT": -22.95,
    "CIDADE": "Monteiro Lobato",
    "UF": "SÃO PAULO",
    "anual": 4.68,
    "JAN": 4.52,
    "FEB": 5.09,
    "MAR": 4.74,
    "APR": 4.83,
    "MAY": 4.38,
    "JUN": 4.34,
    "JUL": 4.35,
    "AUG": 5.26,
    "SEP": 4.71,
    "OCT": 4.67,
    "NOV": 4.51,
    "DEC": 4.78
  },
  {
    "LONG": -45.55,
    "LAT": -22.96,
    "CIDADE": "Tremembé",
    "UF": "SÃO PAULO",
    "anual": 4.88,
    "JAN": 4.88,
    "FEB": 5.44,
    "MAR": 5.03,
    "APR": 5.06,
    "MAY": 4.43,
    "JUN": 4.3,
    "JUL": 4.37,
    "AUG": 5.26,
    "SEP": 4.87,
    "OCT": 4.97,
    "NOV": 4.83,
    "DEC": 5.15
  },
  {
    "LONG": -45.56,
    "LAT": -23.01,
    "CIDADE": "Taubaté",
    "UF": "SÃO PAULO",
    "anual": 4.88,
    "JAN": 4.88,
    "FEB": 5.44,
    "MAR": 5.03,
    "APR": 5.06,
    "MAY": 4.43,
    "JUN": 4.3,
    "JUL": 4.37,
    "AUG": 5.26,
    "SEP": 4.87,
    "OCT": 4.97,
    "NOV": 4.83,
    "DEC": 5.15
  },
  {
    "LONG": -50.73,
    "LAT": -22.9,
    "CIDADE": "Florínia",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.39,
    "FEB": 5.63,
    "MAR": 5.51,
    "APR": 5.43,
    "MAY": 4.69,
    "JUN": 4.46,
    "JUL": 4.61,
    "AUG": 5.46,
    "SEP": 5.05,
    "OCT": 5.27,
    "NOV": 5.49,
    "DEC": 5.66
  },
  {
    "LONG": -49.98,
    "LAT": -22.89,
    "CIDADE": "Salto Grande",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.12,
    "FEB": 5.54,
    "MAR": 5.36,
    "APR": 5.39,
    "MAY": 4.65,
    "JUN": 4.48,
    "JUL": 4.65,
    "AUG": 5.5,
    "SEP": 5.08,
    "OCT": 5.27,
    "NOV": 5.33,
    "DEC": 5.58
  },
  {
    "LONG": -49.64,
    "LAT": -22.9,
    "CIDADE": "Santa Cruz do Rio Pardo",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.09,
    "FEB": 5.53,
    "MAR": 5.38,
    "APR": 5.39,
    "MAY": 4.64,
    "JUN": 4.5,
    "JUL": 4.66,
    "AUG": 5.56,
    "SEP": 5.1,
    "OCT": 5.31,
    "NOV": 5.34,
    "DEC": 5.52
  },
  {
    "LONG": -49.34,
    "LAT": -22.94,
    "CIDADE": "Óleo",
    "UF": "SÃO PAULO",
    "anual": 5.13,
    "JAN": 5,
    "FEB": 5.49,
    "MAR": 5.36,
    "APR": 5.35,
    "MAY": 4.64,
    "JUN": 4.48,
    "JUL": 4.64,
    "AUG": 5.54,
    "SEP": 5.06,
    "OCT": 5.26,
    "NOV": 5.33,
    "DEC": 5.44
  },
  {
    "LONG": -49.24,
    "LAT": -22.88,
    "CIDADE": "Águas de Santa Bárbara",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5,
    "FEB": 5.49,
    "MAR": 5.34,
    "APR": 5.35,
    "MAY": 4.62,
    "JUN": 4.5,
    "JUL": 4.66,
    "AUG": 5.55,
    "SEP": 5.08,
    "OCT": 5.26,
    "NOV": 5.35,
    "DEC": 5.42
  },
  {
    "LONG": -49.16,
    "LAT": -2.29,
    "CIDADE": "Iaras",
    "UF": "SÃO PAULO",
    "anual": 5.13,
    "JAN": 4.96,
    "FEB": 5.47,
    "MAR": 5.31,
    "APR": 5.35,
    "MAY": 4.61,
    "JUN": 4.5,
    "JUL": 4.67,
    "AUG": 5.58,
    "SEP": 5.11,
    "OCT": 5.24,
    "NOV": 5.31,
    "DEC": 5.4
  },
  {
    "LONG": -48.44,
    "LAT": -22.88,
    "CIDADE": "Botucatu",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 4.95,
    "FEB": 5.56,
    "MAR": 5.34,
    "APR": 5.33,
    "MAY": 4.67,
    "JUN": 4.54,
    "JUL": 4.66,
    "AUG": 5.55,
    "SEP": 5.18,
    "OCT": 5.3,
    "NOV": 5.3,
    "DEC": 5.36
  },
  {
    "LONG": -47.56,
    "LAT": -2.29,
    "CIDADE": "Mombuca",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.12,
    "FEB": 5.59,
    "MAR": 5.34,
    "APR": 5.28,
    "MAY": 4.72,
    "JUN": 4.53,
    "JUL": 4.69,
    "AUG": 5.53,
    "SEP": 5.16,
    "OCT": 5.31,
    "NOV": 5.19,
    "DEC": 5.38
  },
  {
    "LONG": -47.31,
    "LAT": -22.95,
    "CIDADE": "Monte Mor",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.14,
    "FEB": 5.57,
    "MAR": 5.36,
    "APR": 5.38,
    "MAY": 4.75,
    "JUN": 4.59,
    "JUL": 4.7,
    "AUG": 5.55,
    "SEP": 5.2,
    "OCT": 5.34,
    "NOV": 5.24,
    "DEC": 5.41
  },
  {
    "LONG": -47.21,
    "LAT": -22.85,
    "CIDADE": "Hortolândia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.1,
    "FEB": 5.52,
    "MAR": 5.34,
    "APR": 5.38,
    "MAY": 4.73,
    "JUN": 4.63,
    "JUL": 4.73,
    "AUG": 5.58,
    "SEP": 5.22,
    "OCT": 5.37,
    "NOV": 5.26,
    "DEC": 5.45
  },
  {
    "LONG": -47.07,
    "LAT": -22.91,
    "CIDADE": "Campinas",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.05,
    "FEB": 5.5,
    "MAR": 5.3,
    "APR": 5.32,
    "MAY": 4.73,
    "JUN": 4.61,
    "JUL": 4.74,
    "AUG": 5.51,
    "SEP": 5.22,
    "OCT": 5.36,
    "NOV": 5.18,
    "DEC": 5.39
  },
  {
    "LONG": "#VALOR!",
    "LAT": -22.88,
    "CIDADE": "Morungaba",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 4.92,
    "FEB": 5.53,
    "MAR": 5.24,
    "APR": 5.3,
    "MAY": 4.73,
    "JUN": 4.55,
    "JUL": 4.68,
    "AUG": 5.57,
    "SEP": 5.18,
    "OCT": 5.27,
    "NOV": 5.14,
    "DEC": 5.34
  },
  {
    "LONG": -46.41,
    "LAT": -22.89,
    "CIDADE": "Vargem",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 4.82,
    "FEB": 5.42,
    "MAR": 5.15,
    "APR": 5.3,
    "MAY": 4.69,
    "JUN": 4.57,
    "JUL": 4.68,
    "AUG": 5.55,
    "SEP": 5.16,
    "OCT": 5.23,
    "NOV": 5.1,
    "DEC": 5.27
  },
  {
    "LONG": -46.27,
    "LAT": -22.93,
    "CIDADE": "Joanópolis",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.7,
    "FEB": 5.24,
    "MAR": 4.96,
    "APR": 5.15,
    "MAY": 4.65,
    "JUN": 4.55,
    "JUL": 4.64,
    "AUG": 5.54,
    "SEP": 5.13,
    "OCT": 5.16,
    "NOV": 4.92,
    "DEC": 5.08
  },
  {
    "LONG": -45.46,
    "LAT": -22.93,
    "CIDADE": "Pindamonhangaba",
    "UF": "SÃO PAULO",
    "anual": 4.83,
    "JAN": 4.88,
    "FEB": 5.29,
    "MAR": 4.93,
    "APR": 4.96,
    "MAY": 4.42,
    "JUN": 4.38,
    "JUL": 4.41,
    "AUG": 5.29,
    "SEP": 4.86,
    "OCT": 4.89,
    "NOV": 4.7,
    "DEC": 5.02
  },
  {
    "LONG": -45.31,
    "LAT": -22.89,
    "CIDADE": "Roseira",
    "UF": "SÃO PAULO",
    "anual": 4.93,
    "JAN": 4.99,
    "FEB": 5.46,
    "MAR": 5.08,
    "APR": 5.07,
    "MAY": 4.47,
    "JUN": 4.4,
    "JUL": 4.42,
    "AUG": 5.33,
    "SEP": 4.95,
    "OCT": 5.01,
    "NOV": 4.8,
    "DEC": 5.15
  },
  {
    "LONG": -50.79,
    "LAT": -2.28,
    "CIDADE": "Pedrinhas Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.34,
    "FEB": 5.54,
    "MAR": 5.44,
    "APR": 5.4,
    "MAY": 4.71,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.47,
    "SEP": 5.07,
    "OCT": 5.26,
    "NOV": 5.44,
    "DEC": 5.63
  },
  {
    "LONG": -50.22,
    "LAT": -22.79,
    "CIDADE": "Palmital",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.15,
    "FEB": 5.53,
    "MAR": 5.37,
    "APR": 5.37,
    "MAY": 4.67,
    "JUN": 4.51,
    "JUL": 4.69,
    "AUG": 5.5,
    "SEP": 5.14,
    "OCT": 5.25,
    "NOV": 5.38,
    "DEC": 5.54
  },
  {
    "LONG": -50.07,
    "LAT": -2.28,
    "CIDADE": "Ibirarema",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.08,
    "FEB": 5.52,
    "MAR": 5.35,
    "APR": 5.39,
    "MAY": 4.67,
    "JUN": 4.49,
    "JUL": 4.68,
    "AUG": 5.52,
    "SEP": 5.12,
    "OCT": 5.29,
    "NOV": 5.35,
    "DEC": 5.53
  },
  {
    "LONG": -49.93,
    "LAT": -2.28,
    "CIDADE": "Ribeirão do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.09,
    "FEB": 5.52,
    "MAR": 5.32,
    "APR": 5.38,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.69,
    "AUG": 5.51,
    "SEP": 5.09,
    "OCT": 5.28,
    "NOV": 5.35,
    "DEC": 5.54
  },
  {
    "LONG": -4.87,
    "LAT": -22.81,
    "CIDADE": "Pratânia",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.03,
    "FEB": 5.5,
    "MAR": 5.34,
    "APR": 5.31,
    "MAY": 4.71,
    "JUN": 4.58,
    "JUL": 4.72,
    "AUG": 5.61,
    "SEP": 5.17,
    "OCT": 5.33,
    "NOV": 5.32,
    "DEC": 5.42
  },
  {
    "LONG": -4.81,
    "LAT": -2.28,
    "CIDADE": "Anhembi",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 5.11,
    "FEB": 5.66,
    "MAR": 5.45,
    "APR": 5.31,
    "MAY": 4.67,
    "JUN": 4.54,
    "JUL": 4.6,
    "AUG": 5.48,
    "SEP": 5.16,
    "OCT": 5.34,
    "NOV": 5.39,
    "DEC": 5.51
  },
  {
    "LONG": -47.61,
    "LAT": -22.84,
    "CIDADE": "Rio das Pedras",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.08,
    "FEB": 5.56,
    "MAR": 5.34,
    "APR": 5.31,
    "MAY": 4.74,
    "JUN": 4.59,
    "JUL": 4.71,
    "AUG": 5.54,
    "SEP": 5.19,
    "OCT": 5.32,
    "NOV": 5.23,
    "DEC": 5.38
  },
  {
    "LONG": -47.68,
    "LAT": -22.84,
    "CIDADE": "Saltinho",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.08,
    "FEB": 5.56,
    "MAR": 5.34,
    "APR": 5.31,
    "MAY": 4.74,
    "JUN": 4.59,
    "JUL": 4.71,
    "AUG": 5.54,
    "SEP": 5.19,
    "OCT": 5.32,
    "NOV": 5.23,
    "DEC": 5.38
  },
  {
    "LONG": -47.41,
    "LAT": -22.76,
    "CIDADE": "Santa Bárbara d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 5.11,
    "FEB": 5.58,
    "MAR": 5.36,
    "APR": 5.32,
    "MAY": 4.73,
    "JUN": 4.6,
    "JUL": 4.7,
    "AUG": 5.52,
    "SEP": 5.21,
    "OCT": 5.34,
    "NOV": 5.2,
    "DEC": 5.43
  },
  {
    "LONG": -47.27,
    "LAT": -22.82,
    "CIDADE": "Sumaré",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.53,
    "MAR": 5.38,
    "APR": 5.34,
    "MAY": 4.73,
    "JUN": 4.63,
    "JUL": 4.73,
    "AUG": 5.57,
    "SEP": 5.23,
    "OCT": 5.35,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -47.29,
    "LAT": -22.78,
    "CIDADE": "Nova Odessa",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.53,
    "MAR": 5.38,
    "APR": 5.34,
    "MAY": 4.73,
    "JUN": 4.63,
    "JUL": 4.73,
    "AUG": 5.57,
    "SEP": 5.23,
    "OCT": 5.35,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -47.15,
    "LAT": -22.75,
    "CIDADE": "Paulínia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.07,
    "FEB": 5.56,
    "MAR": 5.37,
    "APR": 5.37,
    "MAY": 4.74,
    "JUN": 4.65,
    "JUL": 4.76,
    "AUG": 5.55,
    "SEP": 5.24,
    "OCT": 5.36,
    "NOV": 5.23,
    "DEC": 5.44
  },
  {
    "LONG": -46.69,
    "LAT": -22.82,
    "CIDADE": "Tuiuti",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.88,
    "FEB": 5.5,
    "MAR": 5.22,
    "APR": 5.26,
    "MAY": 4.77,
    "JUN": 4.62,
    "JUL": 4.76,
    "AUG": 5.61,
    "SEP": 5.27,
    "OCT": 5.31,
    "NOV": 5.13,
    "DEC": 5.32
  },
  {
    "LONG": -46.59,
    "LAT": -22.78,
    "CIDADE": "Pinhalzinho",
    "UF": "SÃO PAULO",
    "anual": 5.11,
    "JAN": 4.86,
    "FEB": 5.43,
    "MAR": 5.19,
    "APR": 5.27,
    "MAY": 4.75,
    "JUN": 4.6,
    "JUL": 4.75,
    "AUG": 5.58,
    "SEP": 5.24,
    "OCT": 5.3,
    "NOV": 5.13,
    "DEC": 5.29
  },
  {
    "LONG": -46.45,
    "LAT": -22.79,
    "CIDADE": "Pedra Bela",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 4.8,
    "FEB": 5.36,
    "MAR": 5.1,
    "APR": 5.23,
    "MAY": 4.72,
    "JUN": 4.6,
    "JUL": 4.74,
    "AUG": 5.59,
    "SEP": 5.23,
    "OCT": 5.27,
    "NOV": 5.06,
    "DEC": 5.19
  },
  {
    "LONG": -45.66,
    "LAT": -22.83,
    "CIDADE": "Santo Antônio do Pinhal",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.74,
    "FEB": 5.33,
    "MAR": 4.95,
    "APR": 5.07,
    "MAY": 4.61,
    "JUN": 4.54,
    "JUL": 4.66,
    "AUG": 5.52,
    "SEP": 5.06,
    "OCT": 5.08,
    "NOV": 4.78,
    "DEC": 5.09
  },
  {
    "LONG": -45.23,
    "LAT": "#VALOR!",
    "CIDADE": "Aparecida",
    "UF": "SÃO PAULO",
    "anual": 4.87,
    "JAN": 4.86,
    "FEB": 5.39,
    "MAR": 5,
    "APR": 5.02,
    "MAY": 4.46,
    "JUN": 4.37,
    "JUL": 4.45,
    "AUG": 5.34,
    "SEP": 4.94,
    "OCT": 4.93,
    "NOV": 4.68,
    "DEC": 5.05
  },
  {
    "LONG": -45.26,
    "LAT": -22.83,
    "CIDADE": "Potim",
    "UF": "SÃO PAULO",
    "anual": 4.87,
    "JAN": 4.86,
    "FEB": 5.39,
    "MAR": 5,
    "APR": 5.02,
    "MAY": 4.46,
    "JUN": 4.37,
    "JUL": 4.45,
    "AUG": 5.34,
    "SEP": 4.94,
    "OCT": 4.93,
    "NOV": 4.68,
    "DEC": 5.05
  },
  {
    "LONG": -45.19,
    "LAT": -2.28,
    "CIDADE": "Guaratinguetá",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 5.11,
    "APR": 5.14,
    "MAY": 4.55,
    "JUN": 4.41,
    "JUL": 4.5,
    "AUG": 5.38,
    "SEP": 5.03,
    "OCT": 5.04,
    "NOV": 4.83,
    "DEC": 5.22
  },
  {
    "LONG": -51.08,
    "LAT": -22.66,
    "CIDADE": "Iepê",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.29,
    "FEB": 5.58,
    "MAR": 5.59,
    "APR": 5.44,
    "MAY": 4.69,
    "JUN": 4.44,
    "JUL": 4.66,
    "AUG": 5.54,
    "SEP": 5.09,
    "OCT": 5.32,
    "NOV": 5.51,
    "DEC": 5.61
  },
  {
    "LONG": -50.79,
    "LAT": -22.74,
    "CIDADE": "Cruzália",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.31,
    "FEB": 5.52,
    "MAR": 5.43,
    "APR": 5.38,
    "MAY": 4.72,
    "JUN": 4.48,
    "JUL": 4.69,
    "AUG": 5.52,
    "SEP": 5.12,
    "OCT": 5.26,
    "NOV": 5.42,
    "DEC": 5.56
  },
  {
    "LONG": -5.06,
    "LAT": -22.74,
    "CIDADE": "Tarumã",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.17,
    "FEB": 5.54,
    "MAR": 5.48,
    "APR": 5.42,
    "MAY": 4.72,
    "JUN": 4.5,
    "JUL": 4.72,
    "AUG": 5.59,
    "SEP": 5.15,
    "OCT": 5.3,
    "NOV": 5.44,
    "DEC": 5.5
  },
  {
    "LONG": -50.42,
    "LAT": -22.66,
    "CIDADE": "Assis",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.14,
    "FEB": 5.54,
    "MAR": 5.45,
    "APR": 5.37,
    "MAY": 4.69,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.57,
    "SEP": 5.12,
    "OCT": 5.31,
    "NOV": 5.45,
    "DEC": 5.52
  },
  {
    "LONG": -50.39,
    "LAT": -22.75,
    "CIDADE": "Cândido Mota",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 5.12,
    "FEB": 5.54,
    "MAR": 5.42,
    "APR": 5.35,
    "MAY": 4.67,
    "JUN": 4.51,
    "JUL": 4.71,
    "AUG": 5.53,
    "SEP": 5.12,
    "OCT": 5.28,
    "NOV": 5.42,
    "DEC": 5.5
  },
  {
    "LONG": -49.74,
    "LAT": -22.75,
    "CIDADE": "São Pedro do Turvo",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 5.08,
    "FEB": 5.52,
    "MAR": 5.39,
    "APR": 5.41,
    "MAY": 4.66,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.6,
    "SEP": 5.12,
    "OCT": 5.32,
    "NOV": 5.38,
    "DEC": 5.47
  },
  {
    "LONG": -49.43,
    "LAT": -2.27,
    "CIDADE": "Espírito Santo do Turvo",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 5.04,
    "FEB": 5.51,
    "MAR": 5.41,
    "APR": 5.38,
    "MAY": 4.67,
    "JUN": 4.52,
    "JUL": 4.73,
    "AUG": 5.61,
    "SEP": 5.11,
    "OCT": 5.31,
    "NOV": 5.37,
    "DEC": 5.46
  },
  {
    "LONG": -48.66,
    "LAT": -2.27,
    "CIDADE": "Areiópolis",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.1,
    "FEB": 5.58,
    "MAR": 5.45,
    "APR": 5.36,
    "MAY": 4.74,
    "JUN": 4.61,
    "JUL": 4.75,
    "AUG": 5.63,
    "SEP": 5.2,
    "OCT": 5.37,
    "NOV": 5.37,
    "DEC": 5.47
  },
  {
    "LONG": -48.57,
    "LAT": -22.73,
    "CIDADE": "São Manuel",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.06,
    "FEB": 5.66,
    "MAR": 5.48,
    "APR": 5.4,
    "MAY": 4.76,
    "JUN": 4.61,
    "JUL": 4.74,
    "AUG": 5.62,
    "SEP": 5.21,
    "OCT": 5.38,
    "NOV": 5.4,
    "DEC": 5.5
  },
  {
    "LONG": -4.76,
    "LAT": -22.73,
    "CIDADE": "Piracicaba",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.06,
    "FEB": 5.53,
    "MAR": 5.32,
    "APR": 5.3,
    "MAY": 4.72,
    "JUN": 4.57,
    "JUL": 4.71,
    "AUG": 5.56,
    "SEP": 5.2,
    "OCT": 5.3,
    "NOV": 5.19,
    "DEC": 5.35
  },
  {
    "LONG": -47.33,
    "LAT": -22.74,
    "CIDADE": "Americana",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.07,
    "FEB": 5.51,
    "MAR": 5.33,
    "APR": 5.3,
    "MAY": 4.73,
    "JUN": 4.62,
    "JUL": 4.74,
    "AUG": 5.58,
    "SEP": 5.24,
    "OCT": 5.32,
    "NOV": 5.18,
    "DEC": 5.38
  },
  {
    "LONG": -46.99,
    "LAT": -22.7,
    "CIDADE": "Jaguariúna",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.03,
    "FEB": 5.56,
    "MAR": 5.39,
    "APR": 5.38,
    "MAY": 4.83,
    "JUN": 4.65,
    "JUL": 4.83,
    "AUG": 5.6,
    "SEP": 5.26,
    "OCT": 5.4,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -46.9,
    "LAT": -22.74,
    "CIDADE": "Pedreira",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.01,
    "FEB": 5.56,
    "MAR": 5.35,
    "APR": 5.4,
    "MAY": 4.85,
    "JUN": 4.64,
    "JUL": 4.83,
    "AUG": 5.58,
    "SEP": 5.28,
    "OCT": 5.4,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -46.77,
    "LAT": -22.71,
    "CIDADE": "Amparo",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 4.96,
    "FEB": 5.51,
    "MAR": 5.29,
    "APR": 5.36,
    "MAY": 4.83,
    "JUN": 4.63,
    "JUL": 4.83,
    "AUG": 5.58,
    "SEP": 5.29,
    "OCT": 5.38,
    "NOV": 5.19,
    "DEC": 5.36
  },
  {
    "LONG": -46.68,
    "LAT": -22.68,
    "CIDADE": "Monte Alegre do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.85,
    "FEB": 5.47,
    "MAR": 5.22,
    "APR": 5.28,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.8,
    "AUG": 5.62,
    "SEP": 5.27,
    "OCT": 5.33,
    "NOV": 5.12,
    "DEC": 5.26
  },
  {
    "LONG": -45.73,
    "LAT": -22.68,
    "CIDADE": "São Bento do Sapucaí",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.74,
    "FEB": 5.36,
    "MAR": 5,
    "APR": 5.09,
    "MAY": 4.63,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.55,
    "SEP": 5.15,
    "OCT": 5.14,
    "NOV": 4.85,
    "DEC": 5.07
  },
  {
    "LONG": -45.58,
    "LAT": -22.73,
    "CIDADE": "Campos do Jordão",
    "UF": "SÃO PAULO",
    "anual": 4.82,
    "JAN": 4.55,
    "FEB": 5.05,
    "MAR": 4.7,
    "APR": 4.86,
    "MAY": 4.52,
    "JUN": 4.5,
    "JUL": 4.63,
    "AUG": 5.53,
    "SEP": 5.11,
    "OCT": 4.98,
    "NOV": 4.56,
    "DEC": 4.82
  },
  {
    "LONG": -45.12,
    "LAT": -22.73,
    "CIDADE": "Lorena",
    "UF": "SÃO PAULO",
    "anual": 4.85,
    "JAN": 4.83,
    "FEB": 5.37,
    "MAR": 4.95,
    "APR": 4.96,
    "MAY": 4.47,
    "JUN": 4.4,
    "JUL": 4.48,
    "AUG": 5.35,
    "SEP": 4.91,
    "OCT": 4.88,
    "NOV": 4.6,
    "DEC": 4.98
  },
  {
    "LONG": -45.02,
    "LAT": -2.27,
    "CIDADE": "Cachoeira Paulista",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.96,
    "FEB": 5.49,
    "MAR": 5.05,
    "APR": 5.07,
    "MAY": 4.53,
    "JUN": 4.47,
    "JUL": 4.52,
    "AUG": 5.41,
    "SEP": 5,
    "OCT": 5.01,
    "NOV": 4.74,
    "DEC": 5.11
  },
  {
    "LONG": -45.05,
    "LAT": -22.7,
    "CIDADE": "Canas",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.96,
    "FEB": 5.49,
    "MAR": 5.05,
    "APR": 5.07,
    "MAY": 4.53,
    "JUN": 4.47,
    "JUL": 4.52,
    "AUG": 5.41,
    "SEP": 5,
    "OCT": 5.01,
    "NOV": 4.74,
    "DEC": 5.11
  },
  {
    "LONG": -44.85,
    "LAT": -22.66,
    "CIDADE": "Silveiras",
    "UF": "SÃO PAULO",
    "anual": 5.09,
    "JAN": 5.18,
    "FEB": 5.67,
    "MAR": 5.22,
    "APR": 5.18,
    "MAY": 4.65,
    "JUN": 4.59,
    "JUL": 4.62,
    "AUG": 5.44,
    "SEP": 5.11,
    "OCT": 5.15,
    "NOV": 4.94,
    "DEC": 5.33
  },
  {
    "LONG": -44.44,
    "LAT": -22.67,
    "CIDADE": "Arapeí",
    "UF": "SÃO PAULO",
    "anual": 4.82,
    "JAN": 4.97,
    "FEB": 5.59,
    "MAR": 4.91,
    "APR": 4.82,
    "MAY": 4.41,
    "JUN": 4.34,
    "JUL": 4.37,
    "AUG": 5.11,
    "SEP": 4.84,
    "OCT": 4.8,
    "NOV": 4.54,
    "DEC": 5.09
  },
  {
    "LONG": -44.33,
    "LAT": -22.68,
    "CIDADE": "Bananal",
    "UF": "SÃO PAULO",
    "anual": 4.85,
    "JAN": 5.05,
    "FEB": 5.69,
    "MAR": 5.01,
    "APR": 4.87,
    "MAY": 4.41,
    "JUN": 4.37,
    "JUL": 4.34,
    "AUG": 5.09,
    "SEP": 4.84,
    "OCT": 4.81,
    "NOV": 4.58,
    "DEC": 5.09
  },
  {
    "LONG": -53.06,
    "LAT": "#VALOR!",
    "CIDADE": "Rosana",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.41,
    "FEB": 5.78,
    "MAR": 5.78,
    "APR": 5.4,
    "MAY": 4.62,
    "JUN": 4.37,
    "JUL": 4.52,
    "AUG": 5.32,
    "SEP": 5.08,
    "OCT": 5.32,
    "NOV": 5.58,
    "DEC": 5.73
  },
  {
    "LONG": -52.59,
    "LAT": -2.26,
    "CIDADE": "Euclides da Cunha Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.44,
    "FEB": 5.72,
    "MAR": 5.69,
    "APR": 5.39,
    "MAY": 4.62,
    "JUN": 4.37,
    "JUL": 4.55,
    "AUG": 5.36,
    "SEP": 5.09,
    "OCT": 5.3,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -51.24,
    "LAT": -22.62,
    "CIDADE": "Nantes",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.25,
    "FEB": 5.56,
    "MAR": 5.58,
    "APR": 5.43,
    "MAY": 4.71,
    "JUN": 4.43,
    "JUL": 4.7,
    "AUG": 5.53,
    "SEP": 5.1,
    "OCT": 5.33,
    "NOV": 5.51,
    "DEC": 5.57
  },
  {
    "LONG": -50.67,
    "LAT": -22.62,
    "CIDADE": "Maracaí",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.2,
    "FEB": 5.5,
    "MAR": 5.5,
    "APR": 5.44,
    "MAY": 4.72,
    "JUN": 4.55,
    "JUL": 4.71,
    "AUG": 5.59,
    "SEP": 5.18,
    "OCT": 5.31,
    "NOV": 5.45,
    "DEC": 5.48
  },
  {
    "LONG": -50.2,
    "LAT": -22.64,
    "CIDADE": "Platina",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.16,
    "FEB": 5.52,
    "MAR": 5.47,
    "APR": 5.41,
    "MAY": 4.71,
    "JUN": 4.55,
    "JUL": 4.71,
    "AUG": 5.57,
    "SEP": 5.13,
    "OCT": 5.33,
    "NOV": 5.41,
    "DEC": 5.53
  },
  {
    "LONG": -50,
    "LAT": -22.6,
    "CIDADE": "Campos Novos Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.11,
    "FEB": 5.56,
    "MAR": 5.43,
    "APR": 5.44,
    "MAY": 4.69,
    "JUN": 4.54,
    "JUL": 4.71,
    "AUG": 5.56,
    "SEP": 5.14,
    "OCT": 5.33,
    "NOV": 5.38,
    "DEC": 5.53
  },
  {
    "LONG": -49.4,
    "LAT": -22.58,
    "CIDADE": "Paulistânia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.02,
    "FEB": 5.52,
    "MAR": 5.42,
    "APR": 5.39,
    "MAY": 4.71,
    "JUN": 4.54,
    "JUL": 4.74,
    "AUG": 5.64,
    "SEP": 5.14,
    "OCT": 5.33,
    "NOV": 5.34,
    "DEC": 5.43
  },
  {
    "LONG": -48.97,
    "LAT": -2.26,
    "CIDADE": "Borebi",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 4.98,
    "FEB": 5.5,
    "MAR": 5.39,
    "APR": 5.39,
    "MAY": 4.75,
    "JUN": 4.61,
    "JUL": 4.72,
    "AUG": 5.58,
    "SEP": 5.16,
    "OCT": 5.32,
    "NOV": 5.37,
    "DEC": 5.42
  },
  {
    "LONG": -48.8,
    "LAT": -22.6,
    "CIDADE": "Lençóis Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.01,
    "FEB": 5.54,
    "MAR": 5.39,
    "APR": 5.37,
    "MAY": 4.74,
    "JUN": 4.62,
    "JUL": 4.72,
    "AUG": 5.6,
    "SEP": 5.17,
    "OCT": 5.35,
    "NOV": 5.35,
    "DEC": 5.44
  },
  {
    "LONG": -48.16,
    "LAT": -22.57,
    "CIDADE": "Santa Maria da Serra",
    "UF": "SÃO PAULO",
    "anual": 5.11,
    "JAN": 4.99,
    "FEB": 5.51,
    "MAR": 5.34,
    "APR": 5.3,
    "MAY": 4.64,
    "JUN": 4.47,
    "JUL": 4.57,
    "AUG": 5.48,
    "SEP": 5.12,
    "OCT": 5.27,
    "NOV": 5.29,
    "DEC": 5.39
  },
  {
    "LONG": -47.87,
    "LAT": -22.6,
    "CIDADE": "Águas de São Pedro",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.86,
    "FEB": 5.38,
    "MAR": 5.2,
    "APR": 5.31,
    "MAY": 4.64,
    "JUN": 4.53,
    "JUL": 4.62,
    "AUG": 5.59,
    "SEP": 5.14,
    "OCT": 5.23,
    "NOV": 5.09,
    "DEC": 5.27
  },
  {
    "LONG": -47.52,
    "LAT": -22.58,
    "CIDADE": "Iracemápolis",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.07,
    "FEB": 5.57,
    "MAR": 5.34,
    "APR": 5.31,
    "MAY": 4.73,
    "JUN": 4.56,
    "JUL": 4.74,
    "AUG": 5.62,
    "SEP": 5.2,
    "OCT": 5.33,
    "NOV": 5.11,
    "DEC": 5.29
  },
  {
    "LONG": -47.4,
    "LAT": -22.57,
    "CIDADE": "Limeira",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.02,
    "FEB": 5.49,
    "MAR": 5.32,
    "APR": 5.28,
    "MAY": 4.75,
    "JUN": 4.64,
    "JUL": 4.74,
    "AUG": 5.6,
    "SEP": 5.22,
    "OCT": 5.33,
    "NOV": 5.16,
    "DEC": 5.32
  },
  {
    "LONG": -47.17,
    "LAT": -22.57,
    "CIDADE": "Artur Nogueira",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.03,
    "FEB": 5.57,
    "MAR": 5.36,
    "APR": 5.35,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.78,
    "AUG": 5.63,
    "SEP": 5.27,
    "OCT": 5.38,
    "NOV": 5.21,
    "DEC": 5.42
  },
  {
    "LONG": -4.72,
    "LAT": -22.64,
    "CIDADE": "Cosmópolis",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.03,
    "FEB": 5.57,
    "MAR": 5.36,
    "APR": 5.35,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.78,
    "AUG": 5.63,
    "SEP": 5.27,
    "OCT": 5.38,
    "NOV": 5.21,
    "DEC": 5.42
  },
  {
    "LONG": -47.05,
    "LAT": -2.26,
    "CIDADE": "Holambra",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.02,
    "FEB": 5.57,
    "MAR": 5.35,
    "APR": 5.37,
    "MAY": 4.82,
    "JUN": 4.64,
    "JUL": 4.81,
    "AUG": 5.63,
    "SEP": 5.26,
    "OCT": 5.4,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -46.92,
    "LAT": -22.6,
    "CIDADE": "Santo Antônio de Posse",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.01,
    "FEB": 5.57,
    "MAR": 5.38,
    "APR": 5.37,
    "MAY": 4.82,
    "JUN": 4.66,
    "JUL": 4.83,
    "AUG": 5.63,
    "SEP": 5.29,
    "OCT": 5.41,
    "NOV": 5.25,
    "DEC": 5.42
  },
  {
    "LONG": -46.7,
    "LAT": -22.61,
    "CIDADE": "Serra Negra",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 4.99,
    "FEB": 5.55,
    "MAR": 5.36,
    "APR": 5.38,
    "MAY": 4.86,
    "JUN": 4.66,
    "JUL": 4.87,
    "AUG": 5.62,
    "SEP": 5.31,
    "OCT": 5.41,
    "NOV": 5.24,
    "DEC": 5.36
  },
  {
    "LONG": -46.53,
    "LAT": -22.59,
    "CIDADE": "Socorro",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 4.96,
    "FEB": 5.49,
    "MAR": 5.3,
    "APR": 5.33,
    "MAY": 4.83,
    "JUN": 4.64,
    "JUL": 4.84,
    "AUG": 5.64,
    "SEP": 5.32,
    "OCT": 5.4,
    "NOV": 5.18,
    "DEC": 5.25
  },
  {
    "LONG": -45.19,
    "LAT": -22.61,
    "CIDADE": "Piquete",
    "UF": "SÃO PAULO",
    "anual": 4.71,
    "JAN": 4.57,
    "FEB": 5.12,
    "MAR": 4.75,
    "APR": 4.75,
    "MAY": 4.35,
    "JUN": 4.42,
    "JUL": 4.48,
    "AUG": 5.34,
    "SEP": 4.84,
    "OCT": 4.74,
    "NOV": 4.36,
    "DEC": 4.75
  },
  {
    "LONG": -44.9,
    "LAT": -22.57,
    "CIDADE": "Lavrinhas",
    "UF": "SÃO PAULO",
    "anual": 4.93,
    "JAN": 4.91,
    "FEB": 5.41,
    "MAR": 4.98,
    "APR": 5.06,
    "MAY": 4.55,
    "JUN": 4.51,
    "JUL": 4.57,
    "AUG": 5.4,
    "SEP": 5.01,
    "OCT": 4.98,
    "NOV": 4.69,
    "DEC": 5.1
  },
  {
    "LONG": -44.97,
    "LAT": -22.57,
    "CIDADE": "Cruzeiro",
    "UF": "SÃO PAULO",
    "anual": 4.93,
    "JAN": 4.91,
    "FEB": 5.41,
    "MAR": 4.98,
    "APR": 5.06,
    "MAY": 4.55,
    "JUN": 4.51,
    "JUL": 4.57,
    "AUG": 5.4,
    "SEP": 5.01,
    "OCT": 4.98,
    "NOV": 4.69,
    "DEC": 5.1
  },
  {
    "LONG": -44.7,
    "LAT": -22.58,
    "CIDADE": "Areias",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 5.03,
    "FEB": 5.48,
    "MAR": 5.07,
    "APR": 5.05,
    "MAY": 4.6,
    "JUN": 4.48,
    "JUL": 4.53,
    "AUG": 5.3,
    "SEP": 4.96,
    "OCT": 4.99,
    "NOV": 4.74,
    "DEC": 5.16
  },
  {
    "LONG": -44.58,
    "LAT": -22.64,
    "CIDADE": "São José do Barreiro",
    "UF": "SÃO PAULO",
    "anual": 4.9,
    "JAN": 5.06,
    "FEB": 5.53,
    "MAR": 5.12,
    "APR": 5.01,
    "MAY": 4.52,
    "JUN": 4.37,
    "JUL": 4.39,
    "AUG": 5.23,
    "SEP": 4.9,
    "OCT": 4.9,
    "NOV": 4.67,
    "DEC": 5.14
  },
  {
    "LONG": -52.17,
    "LAT": -22.53,
    "CIDADE": "Teodoro Sampaio",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.39,
    "FEB": 5.67,
    "MAR": 5.62,
    "APR": 5.42,
    "MAY": 4.68,
    "JUN": 4.48,
    "JUL": 4.66,
    "AUG": 5.47,
    "SEP": 5.14,
    "OCT": 5.33,
    "NOV": 5.55,
    "DEC": 5.59
  },
  {
    "LONG": -5.18,
    "LAT": -2.25,
    "CIDADE": "Sandovalina",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.31,
    "FEB": 5.66,
    "MAR": 5.67,
    "APR": 5.47,
    "MAY": 4.74,
    "JUN": 4.54,
    "JUL": 4.71,
    "AUG": 5.55,
    "SEP": 5.15,
    "OCT": 5.38,
    "NOV": 5.53,
    "DEC": 5.67
  },
  {
    "LONG": "#VALOR!",
    "LAT": -22.49,
    "CIDADE": "Estrela do Norte",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.29,
    "FEB": 5.64,
    "MAR": 5.65,
    "APR": 5.47,
    "MAY": 4.72,
    "JUN": 4.55,
    "JUL": 4.72,
    "AUG": 5.58,
    "SEP": 5.19,
    "OCT": 5.38,
    "NOV": 5.53,
    "DEC": 5.63
  },
  {
    "LONG": -49.66,
    "LAT": -22.53,
    "CIDADE": "Ubirajara",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.09,
    "FEB": 5.51,
    "MAR": 5.38,
    "APR": 5.42,
    "MAY": 4.71,
    "JUN": 4.54,
    "JUL": 4.76,
    "AUG": 5.64,
    "SEP": 5.18,
    "OCT": 5.34,
    "NOV": 5.35,
    "DEC": 5.44
  },
  {
    "LONG": -49.34,
    "LAT": -2.25,
    "CIDADE": "Cabrália Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.56,
    "MAR": 5.4,
    "APR": 5.39,
    "MAY": 4.71,
    "JUN": 4.56,
    "JUL": 4.76,
    "AUG": 5.59,
    "SEP": 5.14,
    "OCT": 5.37,
    "NOV": 5.33,
    "DEC": 5.41
  },
  {
    "LONG": -48.99,
    "LAT": -22.47,
    "CIDADE": "Agudos",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 4.97,
    "FEB": 5.54,
    "MAR": 5.41,
    "APR": 5.43,
    "MAY": 4.74,
    "JUN": 4.53,
    "JUL": 4.73,
    "AUG": 5.58,
    "SEP": 5.14,
    "OCT": 5.34,
    "NOV": 5.31,
    "DEC": 5.4
  },
  {
    "LONG": -48.71,
    "LAT": -22.5,
    "CIDADE": "Macatuba",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.11,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 5.38,
    "MAY": 4.74,
    "JUN": 4.63,
    "JUL": 4.77,
    "AUG": 5.63,
    "SEP": 5.24,
    "OCT": 5.43,
    "NOV": 5.42,
    "DEC": 5.52
  },
  {
    "LONG": -48.56,
    "LAT": -22.51,
    "CIDADE": "Igaraçu do Tietê",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.11,
    "FEB": 5.7,
    "MAR": 5.52,
    "APR": 5.41,
    "MAY": 4.73,
    "JUN": 4.57,
    "JUL": 4.73,
    "AUG": 5.57,
    "SEP": 5.22,
    "OCT": 5.43,
    "NOV": 5.46,
    "DEC": 5.55
  },
  {
    "LONG": -48.56,
    "LAT": -22.49,
    "CIDADE": "Barra Bonita",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.11,
    "FEB": 5.7,
    "MAR": 5.52,
    "APR": 5.41,
    "MAY": 4.73,
    "JUN": 4.57,
    "JUL": 4.73,
    "AUG": 5.57,
    "SEP": 5.22,
    "OCT": 5.43,
    "NOV": 5.46,
    "DEC": 5.55
  },
  {
    "LONG": "#VALOR!",
    "LAT": -22.55,
    "CIDADE": "São Pedro",
    "UF": "SÃO PAULO",
    "anual": 5.09,
    "JAN": 4.82,
    "FEB": 5.34,
    "MAR": 5.15,
    "APR": 5.29,
    "MAY": 4.72,
    "JUN": 4.57,
    "JUL": 4.74,
    "AUG": 5.64,
    "SEP": 5.2,
    "OCT": 5.24,
    "NOV": 5.14,
    "DEC": 5.19
  },
  {
    "LONG": -47.78,
    "LAT": -22.51,
    "CIDADE": "Charqueada",
    "UF": "SÃO PAULO",
    "anual": 5.09,
    "JAN": 4.9,
    "FEB": 5.42,
    "MAR": 5.19,
    "APR": 5.28,
    "MAY": 4.7,
    "JUN": 4.55,
    "JUL": 4.72,
    "AUG": 5.61,
    "SEP": 5.16,
    "OCT": 5.24,
    "NOV": 5.12,
    "DEC": 5.23
  },
  {
    "LONG": -47.53,
    "LAT": -22.46,
    "CIDADE": "Santa Gertrudes",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.05,
    "FEB": 5.45,
    "MAR": 5.28,
    "APR": 5.32,
    "MAY": 4.73,
    "JUN": 4.59,
    "JUL": 4.76,
    "AUG": 5.63,
    "SEP": 5.2,
    "OCT": 5.33,
    "NOV": 5.13,
    "DEC": 5.32
  },
  {
    "LONG": -47.45,
    "LAT": -22.48,
    "CIDADE": "Cordeirópolis",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.02,
    "FEB": 5.48,
    "MAR": 5.27,
    "APR": 5.3,
    "MAY": 4.74,
    "JUN": 4.64,
    "JUL": 4.76,
    "AUG": 5.65,
    "SEP": 5.2,
    "OCT": 5.31,
    "NOV": 5.14,
    "DEC": 5.29
  },
  {
    "LONG": -47.21,
    "LAT": -2.25,
    "CIDADE": "Engenheiro Coelho",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.06,
    "FEB": 5.61,
    "MAR": 5.37,
    "APR": 5.31,
    "MAY": 4.83,
    "JUN": 4.7,
    "JUL": 4.78,
    "AUG": 5.63,
    "SEP": 5.27,
    "OCT": 5.38,
    "NOV": 5.18,
    "DEC": 5.39
  },
  {
    "LONG": -46.63,
    "LAT": -22.47,
    "CIDADE": "Águas de Lindóia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 4.99,
    "FEB": 5.46,
    "MAR": 5.31,
    "APR": 5.4,
    "MAY": 4.88,
    "JUN": 4.71,
    "JUL": 4.89,
    "AUG": 5.59,
    "SEP": 5.31,
    "OCT": 5.36,
    "NOV": 5.17,
    "DEC": 5.25
  },
  {
    "LONG": -46.65,
    "LAT": -2.25,
    "CIDADE": "Lindóia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 4.99,
    "FEB": 5.46,
    "MAR": 5.31,
    "APR": 5.4,
    "MAY": 4.88,
    "JUN": 4.71,
    "JUL": 4.89,
    "AUG": 5.59,
    "SEP": 5.31,
    "OCT": 5.36,
    "NOV": 5.17,
    "DEC": 5.25
  },
  {
    "LONG": -44.78,
    "LAT": -22.53,
    "CIDADE": "Queluz",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.56,
    "FEB": 4.98,
    "MAR": 4.64,
    "APR": 4.72,
    "MAY": 4.4,
    "JUN": 4.38,
    "JUL": 4.39,
    "AUG": 5.17,
    "SEP": 4.78,
    "OCT": 4.6,
    "NOV": 4.29,
    "DEC": 4.69
  },
  {
    "LONG": -51.52,
    "LAT": -22.41,
    "CIDADE": "Narandiba",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.34,
    "FEB": 5.66,
    "MAR": 5.65,
    "APR": 5.47,
    "MAY": 4.75,
    "JUN": 4.51,
    "JUL": 4.74,
    "AUG": 5.57,
    "SEP": 5.19,
    "OCT": 5.4,
    "NOV": 5.55,
    "DEC": 5.69
  },
  {
    "LONG": -5.13,
    "LAT": "#VALOR!",
    "CIDADE": "Taciba",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.29,
    "FEB": 5.62,
    "MAR": 5.6,
    "APR": 5.44,
    "MAY": 4.75,
    "JUN": 4.58,
    "JUL": 4.74,
    "AUG": 5.61,
    "SEP": 5.18,
    "OCT": 5.38,
    "NOV": 5.54,
    "DEC": 5.61
  },
  {
    "LONG": -50.58,
    "LAT": -2.24,
    "CIDADE": "Paraguaçu Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.19,
    "FEB": 5.55,
    "MAR": 5.51,
    "APR": 5.41,
    "MAY": 4.77,
    "JUN": 4.63,
    "JUL": 4.78,
    "AUG": 5.64,
    "SEP": 5.19,
    "OCT": 5.34,
    "NOV": 5.44,
    "DEC": 5.52
  },
  {
    "LONG": -50.2,
    "LAT": -2.24,
    "CIDADE": "Echaporã",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.17,
    "FEB": 5.57,
    "MAR": 5.54,
    "APR": 5.46,
    "MAY": 4.76,
    "JUN": 4.63,
    "JUL": 4.76,
    "AUG": 5.64,
    "SEP": 5.19,
    "OCT": 5.38,
    "NOV": 5.43,
    "DEC": 5.55
  },
  {
    "LONG": -49.92,
    "LAT": -22.44,
    "CIDADE": "Ocauçu",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.1,
    "FEB": 5.58,
    "MAR": 5.51,
    "APR": 5.46,
    "MAY": 4.77,
    "JUN": 4.58,
    "JUL": 4.78,
    "AUG": 5.66,
    "SEP": 5.19,
    "OCT": 5.37,
    "NOV": 5.39,
    "DEC": 5.49
  },
  {
    "LONG": -49.82,
    "LAT": -22.42,
    "CIDADE": "Lupércio",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.09,
    "FEB": 5.55,
    "MAR": 5.44,
    "APR": 5.45,
    "MAY": 4.77,
    "JUN": 4.57,
    "JUL": 4.79,
    "AUG": 5.68,
    "SEP": 5.19,
    "OCT": 5.36,
    "NOV": 5.38,
    "DEC": 5.5
  },
  {
    "LONG": -49.76,
    "LAT": -22.44,
    "CIDADE": "Alvinlândia",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.07,
    "FEB": 5.54,
    "MAR": 5.4,
    "APR": 5.42,
    "MAY": 4.74,
    "JUN": 4.55,
    "JUL": 4.78,
    "AUG": 5.67,
    "SEP": 5.18,
    "OCT": 5.36,
    "NOV": 5.33,
    "DEC": 5.44
  },
  {
    "LONG": -49.52,
    "LAT": -22.36,
    "CIDADE": "Fernão",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.09,
    "FEB": 5.51,
    "MAR": 5.32,
    "APR": 5.37,
    "MAY": 4.73,
    "JUN": 4.59,
    "JUL": 4.78,
    "AUG": 5.66,
    "SEP": 5.19,
    "OCT": 5.39,
    "NOV": 5.34,
    "DEC": 5.44
  },
  {
    "LONG": -49.52,
    "LAT": -22.43,
    "CIDADE": "Lucianópolis",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.09,
    "FEB": 5.51,
    "MAR": 5.32,
    "APR": 5.37,
    "MAY": 4.73,
    "JUN": 4.59,
    "JUL": 4.78,
    "AUG": 5.66,
    "SEP": 5.19,
    "OCT": 5.39,
    "NOV": 5.34,
    "DEC": 5.44
  },
  {
    "LONG": -49.41,
    "LAT": -2.24,
    "CIDADE": "Duartina",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.09,
    "FEB": 5.56,
    "MAR": 5.37,
    "APR": 5.37,
    "MAY": 4.74,
    "JUN": 4.6,
    "JUL": 4.78,
    "AUG": 5.61,
    "SEP": 5.17,
    "OCT": 5.4,
    "NOV": 5.34,
    "DEC": 5.44
  },
  {
    "LONG": -49.13,
    "LAT": -22.41,
    "CIDADE": "Piratininga",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.06,
    "FEB": 5.56,
    "MAR": 5.45,
    "APR": 5.42,
    "MAY": 4.77,
    "JUN": 4.59,
    "JUL": 4.78,
    "AUG": 5.61,
    "SEP": 5.17,
    "OCT": 5.41,
    "NOV": 5.39,
    "DEC": 5.48
  },
  {
    "LONG": -48.78,
    "LAT": -22.35,
    "CIDADE": "Pederneiras",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.13,
    "FEB": 5.67,
    "MAR": 5.49,
    "APR": 5.37,
    "MAY": 4.73,
    "JUN": 4.63,
    "JUL": 4.77,
    "AUG": 5.56,
    "SEP": 5.21,
    "OCT": 5.45,
    "NOV": 5.41,
    "DEC": 5.55
  },
  {
    "LONG": -48.45,
    "LAT": -22.41,
    "CIDADE": "Mineiros do Tietê",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.03,
    "FEB": 5.59,
    "MAR": 5.41,
    "APR": 5.38,
    "MAY": 4.79,
    "JUN": 4.65,
    "JUL": 4.8,
    "AUG": 5.61,
    "SEP": 5.26,
    "OCT": 5.43,
    "NOV": 5.36,
    "DEC": 5.44
  },
  {
    "LONG": -48.38,
    "LAT": -22.37,
    "CIDADE": "Dois Córregos",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.01,
    "FEB": 5.55,
    "MAR": 5.33,
    "APR": 5.37,
    "MAY": 4.76,
    "JUN": 4.63,
    "JUL": 4.71,
    "AUG": 5.61,
    "SEP": 5.25,
    "OCT": 5.39,
    "NOV": 5.34,
    "DEC": 5.38
  },
  {
    "LONG": -4.82,
    "LAT": -2.24,
    "CIDADE": "Torrinha",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 4.96,
    "FEB": 5.49,
    "MAR": 5.29,
    "APR": 5.3,
    "MAY": 4.78,
    "JUN": 4.65,
    "JUL": 4.79,
    "AUG": 5.64,
    "SEP": 5.26,
    "OCT": 5.33,
    "NOV": 5.26,
    "DEC": 5.31
  },
  {
    "LONG": -47.72,
    "LAT": -2.24,
    "CIDADE": "Ipeúna",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.93,
    "FEB": 5.43,
    "MAR": 5.24,
    "APR": 5.32,
    "MAY": 4.75,
    "JUN": 4.64,
    "JUL": 4.77,
    "AUG": 5.65,
    "SEP": 5.19,
    "OCT": 5.29,
    "NOV": 5.18,
    "DEC": 5.24
  },
  {
    "LONG": -4.76,
    "LAT": -22.4,
    "CIDADE": "Rio Claro",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.01,
    "FEB": 5.46,
    "MAR": 5.29,
    "APR": 5.33,
    "MAY": 4.75,
    "JUN": 4.64,
    "JUL": 4.76,
    "AUG": 5.6,
    "SEP": 5.21,
    "OCT": 5.34,
    "NOV": 5.14,
    "DEC": 5.28
  },
  {
    "LONG": -47.38,
    "LAT": -22.36,
    "CIDADE": "Araras",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.02,
    "FEB": 5.56,
    "MAR": 5.33,
    "APR": 5.33,
    "MAY": 4.79,
    "JUN": 4.69,
    "JUL": 4.81,
    "AUG": 5.66,
    "SEP": 5.27,
    "OCT": 5.37,
    "NOV": 5.19,
    "DEC": 5.33
  },
  {
    "LONG": -46.94,
    "LAT": -22.37,
    "CIDADE": "Mogi Guaçu",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.03,
    "FEB": 5.59,
    "MAR": 5.35,
    "APR": 5.38,
    "MAY": 4.84,
    "JUN": 4.7,
    "JUL": 4.86,
    "AUG": 5.59,
    "SEP": 5.3,
    "OCT": 5.41,
    "NOV": 5.24,
    "DEC": 5.38
  },
  {
    "LONG": -46.95,
    "LAT": -22.43,
    "CIDADE": "Mogi Mirim",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.03,
    "FEB": 5.59,
    "MAR": 5.35,
    "APR": 5.38,
    "MAY": 4.84,
    "JUN": 4.7,
    "JUL": 4.86,
    "AUG": 5.59,
    "SEP": 5.3,
    "OCT": 5.41,
    "NOV": 5.24,
    "DEC": 5.38
  },
  {
    "LONG": -46.82,
    "LAT": -22.44,
    "CIDADE": "Itapira",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.01,
    "FEB": 5.59,
    "MAR": 5.34,
    "APR": 5.41,
    "MAY": 4.85,
    "JUN": 4.7,
    "JUL": 4.87,
    "AUG": 5.6,
    "SEP": 5.32,
    "OCT": 5.44,
    "NOV": 5.24,
    "DEC": 5.35
  },
  {
    "LONG": -51.91,
    "LAT": -22.29,
    "CIDADE": "Mirante do Paranapanema",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.41,
    "FEB": 5.7,
    "MAR": 5.65,
    "APR": 5.47,
    "MAY": 4.76,
    "JUN": 4.56,
    "JUL": 4.73,
    "AUG": 5.58,
    "SEP": 5.17,
    "OCT": 5.43,
    "NOV": 5.59,
    "DEC": 5.68
  },
  {
    "LONG": -51.56,
    "LAT": -22.3,
    "CIDADE": "Tarabai",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.36,
    "FEB": 5.72,
    "MAR": 5.7,
    "APR": 5.49,
    "MAY": 4.75,
    "JUN": 4.57,
    "JUL": 4.77,
    "AUG": 5.62,
    "SEP": 5.21,
    "OCT": 5.43,
    "NOV": 5.58,
    "DEC": 5.71
  },
  {
    "LONG": -51.5,
    "LAT": -22.27,
    "CIDADE": "Pirapozinho",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.36,
    "FEB": 5.73,
    "MAR": 5.68,
    "APR": 5.47,
    "MAY": 4.78,
    "JUN": 4.59,
    "JUL": 4.78,
    "AUG": 5.64,
    "SEP": 5.23,
    "OCT": 5.42,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -51.39,
    "LAT": -2.23,
    "CIDADE": "Anhumas",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.35,
    "FEB": 5.7,
    "MAR": 5.64,
    "APR": 5.47,
    "MAY": 4.79,
    "JUN": 4.61,
    "JUL": 4.79,
    "AUG": 5.64,
    "SEP": 5.22,
    "OCT": 5.4,
    "NOV": 5.6,
    "DEC": 5.68
  },
  {
    "LONG": -50.54,
    "LAT": -22.27,
    "CIDADE": "Borá",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.24,
    "FEB": 5.57,
    "MAR": 5.52,
    "APR": 5.44,
    "MAY": 4.8,
    "JUN": 4.61,
    "JUL": 4.81,
    "AUG": 5.67,
    "SEP": 5.18,
    "OCT": 5.34,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -50.39,
    "LAT": -22.34,
    "CIDADE": "Lutécia",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.23,
    "FEB": 5.6,
    "MAR": 5.52,
    "APR": 5.46,
    "MAY": 4.78,
    "JUN": 4.62,
    "JUL": 4.81,
    "AUG": 5.65,
    "SEP": 5.16,
    "OCT": 5.36,
    "NOV": 5.47,
    "DEC": 5.54
  },
  {
    "LONG": -50.28,
    "LAT": -22.32,
    "CIDADE": "Oscar Bressane",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.21,
    "FEB": 5.6,
    "MAR": 5.54,
    "APR": 5.49,
    "MAY": 4.77,
    "JUN": 4.62,
    "JUL": 4.8,
    "AUG": 5.65,
    "SEP": 5.17,
    "OCT": 5.39,
    "NOV": 5.46,
    "DEC": 5.54
  },
  {
    "LONG": -49.55,
    "LAT": -22.29,
    "CIDADE": "Gália",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.08,
    "FEB": 5.56,
    "MAR": 5.39,
    "APR": 5.36,
    "MAY": 4.78,
    "JUN": 4.62,
    "JUL": 4.81,
    "AUG": 5.68,
    "SEP": 5.2,
    "OCT": 5.41,
    "NOV": 5.36,
    "DEC": 5.45
  },
  {
    "LONG": -49.09,
    "LAT": -2.23,
    "CIDADE": "Bauru",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.09,
    "FEB": 5.59,
    "MAR": 5.44,
    "APR": 5.42,
    "MAY": 4.79,
    "JUN": 4.6,
    "JUL": 4.81,
    "AUG": 5.57,
    "SEP": 5.17,
    "OCT": 5.44,
    "NOV": 5.4,
    "DEC": 5.53
  },
  {
    "LONG": -48.56,
    "LAT": -22.29,
    "CIDADE": "Jaú",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.12,
    "FEB": 5.65,
    "MAR": 5.45,
    "APR": 5.41,
    "MAY": 4.8,
    "JUN": 4.69,
    "JUL": 4.86,
    "AUG": 5.64,
    "SEP": 5.26,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.44
  },
  {
    "LONG": -48.13,
    "LAT": -22.28,
    "CIDADE": "Brotas",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.02,
    "FEB": 5.56,
    "MAR": 5.35,
    "APR": 5.37,
    "MAY": 4.81,
    "JUN": 4.67,
    "JUL": 4.82,
    "AUG": 5.66,
    "SEP": 5.24,
    "OCT": 5.37,
    "NOV": 5.25,
    "DEC": 5.32
  },
  {
    "LONG": -4.78,
    "LAT": -22.26,
    "CIDADE": "Itirapina",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.89,
    "FEB": 5.39,
    "MAR": 5.22,
    "APR": 5.3,
    "MAY": 4.78,
    "JUN": 4.69,
    "JUL": 4.84,
    "AUG": 5.66,
    "SEP": 5.21,
    "OCT": 5.32,
    "NOV": 5.16,
    "DEC": 5.2
  },
  {
    "LONG": -47.17,
    "LAT": -2.23,
    "CIDADE": "Conchal",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.04,
    "FEB": 5.59,
    "MAR": 5.38,
    "APR": 5.37,
    "MAY": 4.84,
    "JUN": 4.77,
    "JUL": 4.84,
    "AUG": 5.66,
    "SEP": 5.3,
    "OCT": 5.44,
    "NOV": 5.2,
    "DEC": 5.34
  },
  {
    "LONG": -46.95,
    "LAT": -22.27,
    "CIDADE": "Estiva Gerbi",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.04,
    "FEB": 5.55,
    "MAR": 5.36,
    "APR": 5.37,
    "MAY": 4.87,
    "JUN": 4.71,
    "JUL": 4.88,
    "AUG": 5.64,
    "SEP": 5.32,
    "OCT": 5.42,
    "NOV": 5.27,
    "DEC": 5.39
  },
  {
    "LONG": -51.31,
    "LAT": -22.22,
    "CIDADE": "Regente Feijó",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.36,
    "FEB": 5.7,
    "MAR": 5.63,
    "APR": 5.47,
    "MAY": 4.81,
    "JUN": 4.63,
    "JUL": 4.8,
    "AUG": 5.67,
    "SEP": 5.25,
    "OCT": 5.39,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -51.26,
    "LAT": -22.17,
    "CIDADE": "Indiana",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.37,
    "FEB": 5.68,
    "MAR": 5.65,
    "APR": 5.48,
    "MAY": 4.82,
    "JUN": 4.66,
    "JUL": 4.78,
    "AUG": 5.68,
    "SEP": 5.22,
    "OCT": 5.4,
    "NOV": 5.56,
    "DEC": 5.66
  },
  {
    "LONG": -50.89,
    "LAT": -22.23,
    "CIDADE": "Rancharia",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.32,
    "FEB": 5.63,
    "MAR": 5.57,
    "APR": 5.44,
    "MAY": 4.81,
    "JUN": 4.64,
    "JUL": 4.8,
    "AUG": 5.62,
    "SEP": 5.2,
    "OCT": 5.38,
    "NOV": 5.47,
    "DEC": 5.59
  },
  {
    "LONG": -50.77,
    "LAT": "#VALOR!",
    "CIDADE": "João Ramalho",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.3,
    "FEB": 5.61,
    "MAR": 5.55,
    "APR": 5.41,
    "MAY": 4.8,
    "JUN": 4.68,
    "JUL": 4.79,
    "AUG": 5.63,
    "SEP": 5.2,
    "OCT": 5.36,
    "NOV": 5.45,
    "DEC": 5.59
  },
  {
    "LONG": -50.7,
    "LAT": -2.22,
    "CIDADE": "Quatá",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.25,
    "FEB": 5.61,
    "MAR": 5.53,
    "APR": 5.4,
    "MAY": 4.82,
    "JUN": 4.58,
    "JUL": 4.84,
    "AUG": 5.67,
    "SEP": 5.18,
    "OCT": 5.37,
    "NOV": 5.45,
    "DEC": 5.56
  },
  {
    "LONG": -50.09,
    "LAT": -2.22,
    "CIDADE": "Oriente",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.17,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 5.48,
    "MAY": 4.78,
    "JUN": 4.6,
    "JUL": 4.81,
    "AUG": 5.7,
    "SEP": 5.18,
    "OCT": 5.39,
    "NOV": 5.43,
    "DEC": 5.44
  },
  {
    "LONG": -49.95,
    "LAT": -22.22,
    "CIDADE": "Marília",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.47,
    "APR": 5.46,
    "MAY": 4.75,
    "JUN": 4.59,
    "JUL": 4.83,
    "AUG": 5.68,
    "SEP": 5.2,
    "OCT": 5.4,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -49.82,
    "LAT": -22.22,
    "CIDADE": "Vera Cruz",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.13,
    "FEB": 5.62,
    "MAR": 5.51,
    "APR": 5.46,
    "MAY": 4.76,
    "JUN": 4.64,
    "JUL": 4.88,
    "AUG": 5.68,
    "SEP": 5.22,
    "OCT": 5.43,
    "NOV": 5.42,
    "DEC": 5.44
  },
  {
    "LONG": -4.97,
    "LAT": -2.22,
    "CIDADE": "Garça",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.1,
    "FEB": 5.56,
    "MAR": 5.46,
    "APR": 5.41,
    "MAY": 4.81,
    "JUN": 4.63,
    "JUL": 4.85,
    "AUG": 5.68,
    "SEP": 5.21,
    "OCT": 5.44,
    "NOV": 5.39,
    "DEC": 5.49
  },
  {
    "LONG": -4.93,
    "LAT": -22.16,
    "CIDADE": "Avaí",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.09,
    "FEB": 5.6,
    "MAR": 5.42,
    "APR": 5.42,
    "MAY": 4.78,
    "JUN": 4.61,
    "JUL": 4.79,
    "AUG": 5.56,
    "SEP": 5.18,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.46
  },
  {
    "LONG": -48.78,
    "LAT": -22.19,
    "CIDADE": "Boracéia",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.2,
    "FEB": 5.7,
    "MAR": 5.49,
    "APR": 5.41,
    "MAY": 4.8,
    "JUN": 4.63,
    "JUL": 4.77,
    "AUG": 5.49,
    "SEP": 5.17,
    "OCT": 5.44,
    "NOV": 5.41,
    "DEC": 5.55
  },
  {
    "LONG": -48.72,
    "LAT": -22.23,
    "CIDADE": "Itapuí",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.2,
    "FEB": 5.7,
    "MAR": 5.49,
    "APR": 5.41,
    "MAY": 4.8,
    "JUN": 4.63,
    "JUL": 4.77,
    "AUG": 5.49,
    "SEP": 5.17,
    "OCT": 5.44,
    "NOV": 5.41,
    "DEC": 5.55
  },
  {
    "LONG": -47.62,
    "LAT": -22.22,
    "CIDADE": "Corumbataí",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.92,
    "FEB": 5.45,
    "MAR": 5.23,
    "APR": 5.33,
    "MAY": 4.77,
    "JUN": 4.67,
    "JUL": 4.77,
    "AUG": 5.69,
    "SEP": 5.23,
    "OCT": 5.31,
    "NOV": 5.12,
    "DEC": 5.18
  },
  {
    "LONG": -47.38,
    "LAT": -22.18,
    "CIDADE": "Leme",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.06,
    "FEB": 5.59,
    "MAR": 5.33,
    "APR": 5.36,
    "MAY": 4.85,
    "JUN": 4.76,
    "JUL": 4.84,
    "AUG": 5.69,
    "SEP": 5.32,
    "OCT": 5.38,
    "NOV": 5.21,
    "DEC": 5.31
  },
  {
    "LONG": -46.75,
    "LAT": -22.19,
    "CIDADE": "Espírito Santo do Pinhal",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 4.94,
    "FEB": 5.39,
    "MAR": 5.18,
    "APR": 5.33,
    "MAY": 4.87,
    "JUN": 4.73,
    "JUL": 4.92,
    "AUG": 5.65,
    "SEP": 5.31,
    "OCT": 5.33,
    "NOV": 5.13,
    "DEC": 5.17
  },
  {
    "LONG": -51.96,
    "LAT": -22.11,
    "CIDADE": "Marabá Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.39,
    "FEB": 5.69,
    "MAR": 5.64,
    "APR": 5.45,
    "MAY": 4.83,
    "JUN": 4.65,
    "JUL": 4.77,
    "AUG": 5.6,
    "SEP": 5.19,
    "OCT": 5.43,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -51.47,
    "LAT": -22.08,
    "CIDADE": "Álvares Machado",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.38,
    "FEB": 5.73,
    "MAR": 5.69,
    "APR": 5.52,
    "MAY": 4.85,
    "JUN": 4.63,
    "JUL": 4.84,
    "AUG": 5.67,
    "SEP": 5.24,
    "OCT": 5.44,
    "NOV": 5.56,
    "DEC": 5.69
  },
  {
    "LONG": -5.14,
    "LAT": -22.12,
    "CIDADE": "Presidente Prudente",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.39,
    "FEB": 5.71,
    "MAR": 5.68,
    "APR": 5.51,
    "MAY": 4.85,
    "JUN": 4.62,
    "JUL": 4.85,
    "AUG": 5.68,
    "SEP": 5.22,
    "OCT": 5.42,
    "NOV": 5.56,
    "DEC": 5.67
  },
  {
    "LONG": -51.17,
    "LAT": -22.15,
    "CIDADE": "Martinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.38,
    "FEB": 5.67,
    "MAR": 5.68,
    "APR": 5.52,
    "MAY": 4.87,
    "JUN": 4.68,
    "JUL": 4.83,
    "AUG": 5.7,
    "SEP": 5.2,
    "OCT": 5.43,
    "NOV": 5.55,
    "DEC": 5.64
  },
  {
    "LONG": -50.31,
    "LAT": -22.07,
    "CIDADE": "Quintana",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.26,
    "FEB": 5.62,
    "MAR": 5.53,
    "APR": 5.44,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.83,
    "AUG": 5.69,
    "SEP": 5.14,
    "OCT": 5.38,
    "NOV": 5.45,
    "DEC": 5.51
  },
  {
    "LONG": -50.18,
    "LAT": -22.11,
    "CIDADE": "Pompéia",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.21,
    "FEB": 5.62,
    "MAR": 5.47,
    "APR": 5.46,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.84,
    "AUG": 5.69,
    "SEP": 5.18,
    "OCT": 5.41,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -49.72,
    "LAT": -2.21,
    "CIDADE": "Álvaro de Carvalho",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.14,
    "FEB": 5.6,
    "MAR": 5.48,
    "APR": 5.48,
    "MAY": 4.82,
    "JUN": 4.68,
    "JUL": 4.84,
    "AUG": 5.68,
    "SEP": 5.22,
    "OCT": 5.46,
    "NOV": 5.44,
    "DEC": 5.5
  },
  {
    "LONG": -49.44,
    "LAT": -22.1,
    "CIDADE": "Presidente Alves",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.09,
    "FEB": 5.61,
    "MAR": 5.43,
    "APR": 5.44,
    "MAY": 4.82,
    "JUN": 4.64,
    "JUL": 4.87,
    "AUG": 5.65,
    "SEP": 5.22,
    "OCT": 5.45,
    "NOV": 5.39,
    "DEC": 5.48
  },
  {
    "LONG": -48.74,
    "LAT": -22.07,
    "CIDADE": "Bariri",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.23,
    "FEB": 5.72,
    "MAR": 5.47,
    "APR": 5.42,
    "MAY": 4.81,
    "JUN": 4.7,
    "JUL": 4.87,
    "AUG": 5.6,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.41,
    "DEC": 5.53
  },
  {
    "LONG": -48.52,
    "LAT": -2.21,
    "CIDADE": "Bocaina",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.07,
    "FEB": 5.62,
    "MAR": 5.4,
    "APR": 5.36,
    "MAY": 4.81,
    "JUN": 4.73,
    "JUL": 4.87,
    "AUG": 5.61,
    "SEP": 5.24,
    "OCT": 5.45,
    "NOV": 5.33,
    "DEC": 5.43
  },
  {
    "LONG": -48.31,
    "LAT": -22.11,
    "CIDADE": "Dourado",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.1,
    "FEB": 5.62,
    "MAR": 5.4,
    "APR": 5.38,
    "MAY": 4.84,
    "JUN": 4.76,
    "JUL": 4.89,
    "AUG": 5.68,
    "SEP": 5.23,
    "OCT": 5.43,
    "NOV": 5.3,
    "DEC": 5.42
  },
  {
    "LONG": -48.18,
    "LAT": -22.07,
    "CIDADE": "Ribeirão Bonito",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.01,
    "FEB": 5.61,
    "MAR": 5.37,
    "APR": 5.39,
    "MAY": 4.86,
    "JUN": 4.73,
    "JUL": 4.89,
    "AUG": 5.68,
    "SEP": 5.26,
    "OCT": 5.4,
    "NOV": 5.22,
    "DEC": 5.35
  },
  {
    "LONG": -47.66,
    "LAT": -22.13,
    "CIDADE": "Analândia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 4.97,
    "FEB": 5.52,
    "MAR": 5.26,
    "APR": 5.33,
    "MAY": 4.83,
    "JUN": 4.77,
    "JUL": 4.86,
    "AUG": 5.72,
    "SEP": 5.27,
    "OCT": 5.36,
    "NOV": 5.15,
    "DEC": 5.21
  },
  {
    "LONG": -47.45,
    "LAT": -2.21,
    "CIDADE": "Santa Cruz da Conceição",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.04,
    "FEB": 5.6,
    "MAR": 5.34,
    "APR": 5.4,
    "MAY": 4.87,
    "JUN": 4.77,
    "JUL": 4.89,
    "AUG": 5.7,
    "SEP": 5.32,
    "OCT": 5.4,
    "NOV": 5.22,
    "DEC": 5.3
  },
  {
    "LONG": -46.97,
    "LAT": -22.06,
    "CIDADE": "Aguaí",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.07,
    "FEB": 5.58,
    "MAR": 5.32,
    "APR": 5.43,
    "MAY": 4.9,
    "JUN": 4.76,
    "JUL": 4.92,
    "AUG": 5.67,
    "SEP": 5.34,
    "OCT": 5.43,
    "NOV": 5.29,
    "DEC": 5.33
  },
  {
    "LONG": -46.68,
    "LAT": -22.11,
    "CIDADE": "Santo Antônio do Jardim",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 4.86,
    "FEB": 5.33,
    "MAR": 5.04,
    "APR": 5.25,
    "MAY": 4.88,
    "JUN": 4.79,
    "JUL": 4.92,
    "AUG": 5.69,
    "SEP": 5.32,
    "OCT": 5.24,
    "NOV": 5.01,
    "DEC": 5.06
  },
  {
    "LONG": -51.65,
    "LAT": -21.98,
    "CIDADE": "Santo Anastácio",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.37,
    "FEB": 5.71,
    "MAR": 5.65,
    "APR": 5.48,
    "MAY": 4.87,
    "JUN": 4.67,
    "JUL": 4.83,
    "AUG": 5.6,
    "SEP": 5.24,
    "OCT": 5.45,
    "NOV": 5.59,
    "DEC": 5.65
  },
  {
    "LONG": -51.56,
    "LAT": -22,
    "CIDADE": "Presidente Bernardes",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.38,
    "FEB": 5.76,
    "MAR": 5.73,
    "APR": 5.56,
    "MAY": 4.87,
    "JUN": 4.64,
    "JUL": 4.85,
    "AUG": 5.66,
    "SEP": 5.21,
    "OCT": 5.44,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -51.41,
    "LAT": -21.95,
    "CIDADE": "Alfredo Marcondes",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.41,
    "FEB": 5.77,
    "MAR": 5.74,
    "APR": 5.59,
    "MAY": 4.89,
    "JUN": 4.65,
    "JUL": 4.86,
    "AUG": 5.71,
    "SEP": 5.24,
    "OCT": 5.45,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -5.12,
    "LAT": -2.2,
    "CIDADE": "Caiabu",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.42,
    "FEB": 5.74,
    "MAR": 5.71,
    "APR": 5.55,
    "MAY": 4.89,
    "JUN": 4.69,
    "JUL": 4.84,
    "AUG": 5.71,
    "SEP": 5.21,
    "OCT": 5.42,
    "NOV": 5.56,
    "DEC": 5.67
  },
  {
    "LONG": -50.39,
    "LAT": -22,
    "CIDADE": "Herculândia",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.27,
    "FEB": 5.65,
    "MAR": 5.51,
    "APR": 5.42,
    "MAY": 4.8,
    "JUN": 4.67,
    "JUL": 4.83,
    "AUG": 5.7,
    "SEP": 5.17,
    "OCT": 5.41,
    "NOV": 5.48,
    "DEC": 5.56
  },
  {
    "LONG": -49.79,
    "LAT": -2.2,
    "CIDADE": "Júlio Mesquita",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.15,
    "FEB": 5.61,
    "MAR": 5.42,
    "APR": 5.46,
    "MAY": 4.82,
    "JUN": 4.64,
    "JUL": 4.85,
    "AUG": 5.63,
    "SEP": 5.2,
    "OCT": 5.47,
    "NOV": 5.42,
    "DEC": 5.48
  },
  {
    "LONG": -49.46,
    "LAT": -2.2,
    "CIDADE": "Pirajuí",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.14,
    "FEB": 5.6,
    "MAR": 5.4,
    "APR": 5.47,
    "MAY": 4.81,
    "JUN": 4.62,
    "JUL": 4.89,
    "AUG": 5.64,
    "SEP": 5.21,
    "OCT": 5.46,
    "NOV": 5.39,
    "DEC": 5.49
  },
  {
    "LONG": -48.91,
    "LAT": -22.03,
    "CIDADE": "Arealva",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.21,
    "FEB": 5.71,
    "MAR": 5.49,
    "APR": 5.53,
    "MAY": 4.84,
    "JUN": 4.75,
    "JUL": 4.9,
    "AUG": 5.65,
    "SEP": 5.24,
    "OCT": 5.52,
    "NOV": 5.47,
    "DEC": 5.55
  },
  {
    "LONG": -48.81,
    "LAT": -21.99,
    "CIDADE": "Itaju",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.26,
    "FEB": 5.69,
    "MAR": 5.51,
    "APR": 5.47,
    "MAY": 4.8,
    "JUN": 4.72,
    "JUL": 4.87,
    "AUG": 5.59,
    "SEP": 5.23,
    "OCT": 5.49,
    "NOV": 5.46,
    "DEC": 5.55
  },
  {
    "LONG": -4.84,
    "LAT": -21.99,
    "CIDADE": "Boa Esperança do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.1,
    "FEB": 5.56,
    "MAR": 5.33,
    "APR": 5.39,
    "MAY": 4.86,
    "JUN": 4.74,
    "JUL": 4.92,
    "AUG": 5.72,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.27,
    "DEC": 5.4
  },
  {
    "LONG": -48.33,
    "LAT": -22.04,
    "CIDADE": "Trabiju",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.1,
    "FEB": 5.56,
    "MAR": 5.33,
    "APR": 5.39,
    "MAY": 4.86,
    "JUN": 4.74,
    "JUL": 4.92,
    "AUG": 5.72,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.27,
    "DEC": 5.4
  },
  {
    "LONG": -4.8,
    "LAT": -21.96,
    "CIDADE": "Ibaté",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 4.99,
    "FEB": 5.59,
    "MAR": 5.35,
    "APR": 5.34,
    "MAY": 4.88,
    "JUN": 4.78,
    "JUL": 4.85,
    "AUG": 5.66,
    "SEP": 5.27,
    "OCT": 5.37,
    "NOV": 5.19,
    "DEC": 5.32
  },
  {
    "LONG": -47.89,
    "LAT": -22.02,
    "CIDADE": "São Carlos",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5,
    "FEB": 5.55,
    "MAR": 5.25,
    "APR": 5.34,
    "MAY": 4.88,
    "JUN": 4.77,
    "JUL": 4.94,
    "AUG": 5.72,
    "SEP": 5.31,
    "OCT": 5.4,
    "NOV": 5.2,
    "DEC": 5.3
  },
  {
    "LONG": -47.43,
    "LAT": -22,
    "CIDADE": "Pirassununga",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.1,
    "FEB": 5.66,
    "MAR": 5.36,
    "APR": 5.41,
    "MAY": 4.89,
    "JUN": 4.76,
    "JUL": 4.91,
    "AUG": 5.68,
    "SEP": 5.32,
    "OCT": 5.43,
    "NOV": 5.24,
    "DEC": 5.34
  },
  {
    "LONG": -46.79,
    "LAT": -21.97,
    "CIDADE": "São João da Boa Vista",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 4.98,
    "FEB": 5.43,
    "MAR": 5.18,
    "APR": 5.36,
    "MAY": 4.91,
    "JUN": 4.78,
    "JUL": 4.93,
    "AUG": 5.68,
    "SEP": 5.32,
    "OCT": 5.34,
    "NOV": 5.15,
    "DEC": 5.19
  },
  {
    "LONG": -51.85,
    "LAT": -21.87,
    "CIDADE": "Presidente Venceslau",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.42,
    "FEB": 5.78,
    "MAR": 5.69,
    "APR": 5.45,
    "MAY": 4.88,
    "JUN": 4.65,
    "JUL": 4.82,
    "AUG": 5.64,
    "SEP": 5.23,
    "OCT": 5.42,
    "NOV": 5.56,
    "DEC": 5.69
  },
  {
    "LONG": -51.73,
    "LAT": -21.89,
    "CIDADE": "Piquerobi",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.36,
    "FEB": 5.73,
    "MAR": 5.67,
    "APR": 5.49,
    "MAY": 4.9,
    "JUN": 4.65,
    "JUL": 4.83,
    "AUG": 5.61,
    "SEP": 5.23,
    "OCT": 5.43,
    "NOV": 5.57,
    "DEC": 5.68
  },
  {
    "LONG": -51.09,
    "LAT": -21.85,
    "CIDADE": "Pracinha",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.35,
    "FEB": 5.7,
    "MAR": 5.67,
    "APR": 5.51,
    "MAY": 4.9,
    "JUN": 4.71,
    "JUL": 4.83,
    "AUG": 5.74,
    "SEP": 5.16,
    "OCT": 5.43,
    "NOV": 5.55,
    "DEC": 5.63
  },
  {
    "LONG": -50.96,
    "LAT": -21.88,
    "CIDADE": "Sagres",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.32,
    "FEB": 5.68,
    "MAR": 5.64,
    "APR": 5.49,
    "MAY": 4.89,
    "JUN": 4.72,
    "JUL": 4.85,
    "AUG": 5.71,
    "SEP": 5.19,
    "OCT": 5.45,
    "NOV": 5.54,
    "DEC": 5.59
  },
  {
    "LONG": -50.74,
    "LAT": -21.92,
    "CIDADE": "Bastos",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.32,
    "FEB": 5.63,
    "MAR": 5.59,
    "APR": 5.42,
    "MAY": 4.88,
    "JUN": 4.69,
    "JUL": 4.87,
    "AUG": 5.74,
    "SEP": 5.19,
    "OCT": 5.41,
    "NOV": 5.48,
    "DEC": 5.56
  },
  {
    "LONG": -50.69,
    "LAT": -2.19,
    "CIDADE": "Iacri",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.27,
    "FEB": 5.64,
    "MAR": 5.54,
    "APR": 5.44,
    "MAY": 4.87,
    "JUN": 4.67,
    "JUL": 4.87,
    "AUG": 5.75,
    "SEP": 5.18,
    "OCT": 5.42,
    "NOV": 5.49,
    "DEC": 5.58
  },
  {
    "LONG": -50.52,
    "LAT": -2.19,
    "CIDADE": "Tupã",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.29,
    "FEB": 5.65,
    "MAR": 5.54,
    "APR": 5.43,
    "MAY": 4.87,
    "JUN": 4.69,
    "JUL": 4.88,
    "AUG": 5.75,
    "SEP": 5.18,
    "OCT": 5.43,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -4.99,
    "LAT": -21.91,
    "CIDADE": "Guaimbê",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.21,
    "FEB": 5.62,
    "MAR": 5.48,
    "APR": 5.48,
    "MAY": 4.82,
    "JUN": 4.66,
    "JUL": 4.88,
    "AUG": 5.66,
    "SEP": 5.24,
    "OCT": 5.47,
    "NOV": 5.42,
    "DEC": 5.5
  },
  {
    "LONG": -49.59,
    "LAT": -2.19,
    "CIDADE": "Guarantã",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.19,
    "FEB": 5.6,
    "MAR": 5.46,
    "APR": 5.43,
    "MAY": 4.81,
    "JUN": 4.71,
    "JUL": 4.89,
    "AUG": 5.65,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.39,
    "DEC": 5.47
  },
  {
    "LONG": -49.36,
    "LAT": "#VALOR!",
    "CIDADE": "Balbinos",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.23,
    "FEB": 5.68,
    "MAR": 5.5,
    "APR": 5.46,
    "MAY": 4.85,
    "JUN": 4.68,
    "JUL": 4.93,
    "AUG": 5.68,
    "SEP": 5.29,
    "OCT": 5.49,
    "NOV": 5.43,
    "DEC": 5.5
  },
  {
    "LONG": -49.22,
    "LAT": -2.19,
    "CIDADE": "Reginópolis",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.25,
    "FEB": 5.68,
    "MAR": 5.53,
    "APR": 5.43,
    "MAY": 4.86,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.68,
    "SEP": 5.25,
    "OCT": 5.51,
    "NOV": 5.43,
    "DEC": 5.53
  },
  {
    "LONG": -49.02,
    "LAT": "#VALOR!",
    "CIDADE": "Iacanga",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.26,
    "FEB": 5.75,
    "MAR": 5.49,
    "APR": 5.48,
    "MAY": 4.83,
    "JUN": 4.75,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.27,
    "OCT": 5.54,
    "NOV": 5.48,
    "DEC": 5.56
  },
  {
    "LONG": -47.62,
    "LAT": -21.9,
    "CIDADE": "Descalvado",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.08,
    "FEB": 5.62,
    "MAR": 5.32,
    "APR": 5.44,
    "MAY": 4.95,
    "JUN": 4.79,
    "JUL": 4.95,
    "AUG": 5.69,
    "SEP": 5.31,
    "OCT": 5.41,
    "NOV": 5.21,
    "DEC": 5.31
  },
  {
    "LONG": -4.67,
    "LAT": -21.93,
    "CIDADE": "Águas da Prata",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 4.9,
    "FEB": 5.37,
    "MAR": 5.11,
    "APR": 5.32,
    "MAY": 4.9,
    "JUN": 4.79,
    "JUL": 4.95,
    "AUG": 5.71,
    "SEP": 5.33,
    "OCT": 5.26,
    "NOV": 5.05,
    "DEC": 5.09
  },
  {
    "LONG": -52.11,
    "LAT": -21.77,
    "CIDADE": "Presidente Epitácio",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.45,
    "FEB": 5.88,
    "MAR": 5.86,
    "APR": 5.53,
    "MAY": 4.88,
    "JUN": 4.62,
    "JUL": 4.75,
    "AUG": 5.58,
    "SEP": 5.09,
    "OCT": 5.38,
    "NOV": 5.6,
    "DEC": 5.78
  },
  {
    "LONG": -51.99,
    "LAT": -21.83,
    "CIDADE": "Caiuá",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.46,
    "FEB": 5.78,
    "MAR": 5.7,
    "APR": 5.49,
    "MAY": 4.94,
    "JUN": 4.63,
    "JUL": 4.8,
    "AUG": 5.57,
    "SEP": 5.18,
    "OCT": 5.43,
    "NOV": 5.6,
    "DEC": 5.72
  },
  {
    "LONG": -5.16,
    "LAT": -21.84,
    "CIDADE": "Ribeirão dos Índios",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.37,
    "FEB": 5.73,
    "MAR": 5.65,
    "APR": 5.5,
    "MAY": 4.9,
    "JUN": 4.67,
    "JUL": 4.83,
    "AUG": 5.62,
    "SEP": 5.2,
    "OCT": 5.45,
    "NOV": 5.57,
    "DEC": 5.68
  },
  {
    "LONG": -51.48,
    "LAT": -21.83,
    "CIDADE": "Emilianópolis",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.34,
    "FEB": 5.69,
    "MAR": 5.65,
    "APR": 5.51,
    "MAY": 4.89,
    "JUN": 4.71,
    "JUL": 4.84,
    "AUG": 5.66,
    "SEP": 5.16,
    "OCT": 5.42,
    "NOV": 5.53,
    "DEC": 5.6
  },
  {
    "LONG": -51.39,
    "LAT": -21.85,
    "CIDADE": "Santo Expedito",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.33,
    "FEB": 5.68,
    "MAR": 5.65,
    "APR": 5.52,
    "MAY": 4.9,
    "JUN": 4.73,
    "JUL": 4.84,
    "AUG": 5.68,
    "SEP": 5.17,
    "OCT": 5.4,
    "NOV": 5.52,
    "DEC": 5.6
  },
  {
    "LONG": -51.18,
    "LAT": -21.8,
    "CIDADE": "Mariápolis",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.35,
    "FEB": 5.7,
    "MAR": 5.69,
    "APR": 5.52,
    "MAY": 4.92,
    "JUN": 4.72,
    "JUL": 4.86,
    "AUG": 5.72,
    "SEP": 5.21,
    "OCT": 5.43,
    "NOV": 5.53,
    "DEC": 5.61
  },
  {
    "LONG": -50.96,
    "LAT": "#VALOR!",
    "CIDADE": "Inúbia Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.35,
    "FEB": 5.67,
    "MAR": 5.62,
    "APR": 5.5,
    "MAY": 4.91,
    "JUN": 4.73,
    "JUL": 4.85,
    "AUG": 5.73,
    "SEP": 5.23,
    "OCT": 5.43,
    "NOV": 5.53,
    "DEC": 5.56
  },
  {
    "LONG": -50.88,
    "LAT": -21.8,
    "CIDADE": "Osvaldo Cruz",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.33,
    "FEB": 5.64,
    "MAR": 5.59,
    "APR": 5.47,
    "MAY": 4.92,
    "JUN": 4.72,
    "JUL": 4.88,
    "AUG": 5.74,
    "SEP": 5.21,
    "OCT": 5.41,
    "NOV": 5.52,
    "DEC": 5.56
  },
  {
    "LONG": -50.8,
    "LAT": -21.78,
    "CIDADE": "Parapuã",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.31,
    "FEB": 5.62,
    "MAR": 5.57,
    "APR": 5.46,
    "MAY": 4.91,
    "JUN": 4.73,
    "JUL": 4.89,
    "AUG": 5.74,
    "SEP": 5.2,
    "OCT": 5.42,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -50.47,
    "LAT": -21.77,
    "CIDADE": "Arco-Íris",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.33,
    "FEB": 5.66,
    "MAR": 5.55,
    "APR": 5.43,
    "MAY": 4.87,
    "JUN": 4.68,
    "JUL": 4.89,
    "AUG": 5.7,
    "SEP": 5.19,
    "OCT": 5.44,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -50.24,
    "LAT": -2.18,
    "CIDADE": "Queiroz",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.31,
    "FEB": 5.65,
    "MAR": 5.53,
    "APR": 5.49,
    "MAY": 4.82,
    "JUN": 4.68,
    "JUL": 4.89,
    "AUG": 5.66,
    "SEP": 5.19,
    "OCT": 5.46,
    "NOV": 5.47,
    "DEC": 5.61
  },
  {
    "LONG": -49.93,
    "LAT": -2.18,
    "CIDADE": "Getulina",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.28,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 5.51,
    "MAY": 4.83,
    "JUN": 4.65,
    "JUL": 4.88,
    "AUG": 5.62,
    "SEP": 5.23,
    "OCT": 5.45,
    "NOV": 5.42,
    "DEC": 5.52
  },
  {
    "LONG": -49.61,
    "LAT": -21.8,
    "CIDADE": "Cafelândia",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.21,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 5.48,
    "MAY": 4.82,
    "JUN": 4.68,
    "JUL": 4.88,
    "AUG": 5.67,
    "SEP": 5.29,
    "OCT": 5.47,
    "NOV": 5.41,
    "DEC": 5.46
  },
  {
    "LONG": -49.28,
    "LAT": -2.18,
    "CIDADE": "Uru",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.34,
    "FEB": 5.76,
    "MAR": 5.59,
    "APR": 5.48,
    "MAY": 4.85,
    "JUN": 4.74,
    "JUL": 4.95,
    "AUG": 5.67,
    "SEP": 5.28,
    "OCT": 5.53,
    "NOV": 5.46,
    "DEC": 5.57
  },
  {
    "LONG": -48.83,
    "LAT": -21.76,
    "CIDADE": "Ibitinga",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.23,
    "FEB": 5.69,
    "MAR": 5.42,
    "APR": 5.42,
    "MAY": 4.84,
    "JUN": 4.69,
    "JUL": 4.92,
    "AUG": 5.63,
    "SEP": 5.3,
    "OCT": 5.47,
    "NOV": 5.37,
    "DEC": 5.48
  },
  {
    "LONG": -4.86,
    "LAT": -2.18,
    "CIDADE": "Nova Europa",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.15,
    "FEB": 5.61,
    "MAR": 5.33,
    "APR": 5.4,
    "MAY": 4.87,
    "JUN": 4.76,
    "JUL": 4.93,
    "AUG": 5.68,
    "SEP": 5.28,
    "OCT": 5.42,
    "NOV": 5.32,
    "DEC": 5.42
  },
  {
    "LONG": -48.5,
    "LAT": -21.84,
    "CIDADE": "Gavião Peixoto",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.12,
    "FEB": 5.6,
    "MAR": 5.32,
    "APR": 5.39,
    "MAY": 4.9,
    "JUN": 4.73,
    "JUL": 4.94,
    "AUG": 5.7,
    "SEP": 5.31,
    "OCT": 5.42,
    "NOV": 5.28,
    "DEC": 5.35
  },
  {
    "LONG": -48.18,
    "LAT": -2.18,
    "CIDADE": "Araraquara",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.08,
    "FEB": 5.6,
    "MAR": 5.31,
    "APR": 5.38,
    "MAY": 4.91,
    "JUN": 4.76,
    "JUL": 4.97,
    "AUG": 5.72,
    "SEP": 5.31,
    "OCT": 5.4,
    "NOV": 5.25,
    "DEC": 5.3
  },
  {
    "LONG": -47.49,
    "LAT": -21.85,
    "CIDADE": "Porto Ferreira",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.11,
    "FEB": 5.59,
    "MAR": 5.32,
    "APR": 5.45,
    "MAY": 4.95,
    "JUN": 4.82,
    "JUL": 4.96,
    "AUG": 5.76,
    "SEP": 5.31,
    "OCT": 5.41,
    "NOV": 5.25,
    "DEC": 5.35
  },
  {
    "LONG": -47.25,
    "LAT": -21.82,
    "CIDADE": "Santa Cruz das Palmeiras",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.06,
    "FEB": 5.54,
    "MAR": 5.29,
    "APR": 5.44,
    "MAY": 4.94,
    "JUN": 4.8,
    "JUL": 4.95,
    "AUG": 5.76,
    "SEP": 5.32,
    "OCT": 5.39,
    "NOV": 5.22,
    "DEC": 5.27
  },
  {
    "LONG": -47.09,
    "LAT": -21.77,
    "CIDADE": "Casa Branca",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.08,
    "FEB": 5.6,
    "MAR": 5.31,
    "APR": 5.43,
    "MAY": 4.95,
    "JUN": 4.81,
    "JUL": 4.97,
    "AUG": 5.74,
    "SEP": 5.35,
    "OCT": 5.42,
    "NOV": 5.3,
    "DEC": 5.32
  },
  {
    "LONG": -46.89,
    "LAT": -21.83,
    "CIDADE": "Vargem Grande do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.06,
    "FEB": 5.53,
    "MAR": 5.25,
    "APR": 5.47,
    "MAY": 4.95,
    "JUN": 4.85,
    "JUL": 5.01,
    "AUG": 5.75,
    "SEP": 5.39,
    "OCT": 5.4,
    "NOV": 5.22,
    "DEC": 5.25
  },
  {
    "LONG": -51.38,
    "LAT": -2.17,
    "CIDADE": "Flora Rica",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.31,
    "FEB": 5.67,
    "MAR": 5.63,
    "APR": 5.52,
    "MAY": 4.92,
    "JUN": 4.74,
    "JUL": 4.83,
    "AUG": 5.66,
    "SEP": 5.21,
    "OCT": 5.43,
    "NOV": 5.52,
    "DEC": 5.59
  },
  {
    "LONG": -51.02,
    "LAT": -21.72,
    "CIDADE": "Lucélia",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.36,
    "FEB": 5.67,
    "MAR": 5.69,
    "APR": 5.56,
    "MAY": 4.93,
    "JUN": 4.72,
    "JUL": 4.89,
    "AUG": 5.71,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.53,
    "DEC": 5.6
  },
  {
    "LONG": -51.07,
    "LAT": -21.68,
    "CIDADE": "Adamantina",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.36,
    "FEB": 5.67,
    "MAR": 5.69,
    "APR": 5.56,
    "MAY": 4.93,
    "JUN": 4.72,
    "JUL": 4.89,
    "AUG": 5.71,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.53,
    "DEC": 5.6
  },
  {
    "LONG": -50.72,
    "LAT": -21.73,
    "CIDADE": "Rinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.29,
    "FEB": 5.62,
    "MAR": 5.58,
    "APR": 5.49,
    "MAY": 4.89,
    "JUN": 4.72,
    "JUL": 4.9,
    "AUG": 5.7,
    "SEP": 5.16,
    "OCT": 5.41,
    "NOV": 5.52,
    "DEC": 5.55
  },
  {
    "LONG": -50.33,
    "LAT": -2.17,
    "CIDADE": "Luiziânia",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.35,
    "FEB": 5.67,
    "MAR": 5.54,
    "APR": 5.5,
    "MAY": 4.88,
    "JUN": 4.69,
    "JUL": 4.89,
    "AUG": 5.66,
    "SEP": 5.21,
    "OCT": 5.44,
    "NOV": 5.49,
    "DEC": 5.59
  },
  {
    "LONG": -4.98,
    "LAT": -21.67,
    "CIDADE": "Lins",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.27,
    "FEB": 5.69,
    "MAR": 5.51,
    "APR": 5.49,
    "MAY": 4.84,
    "JUN": 4.67,
    "JUL": 4.92,
    "AUG": 5.67,
    "SEP": 5.29,
    "OCT": 5.49,
    "NOV": 5.42,
    "DEC": 5.48
  },
  {
    "LONG": -49.36,
    "LAT": -21.74,
    "CIDADE": "Pongaí",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.38,
    "FEB": 5.83,
    "MAR": 5.63,
    "APR": 5.51,
    "MAY": 4.85,
    "JUN": 4.72,
    "JUL": 4.96,
    "AUG": 5.65,
    "SEP": 5.32,
    "OCT": 5.55,
    "NOV": 5.49,
    "DEC": 5.58
  },
  {
    "LONG": -48.69,
    "LAT": -21.73,
    "CIDADE": "Tabatinga",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.2,
    "FEB": 5.63,
    "MAR": 5.32,
    "APR": 5.4,
    "MAY": 4.91,
    "JUN": 4.83,
    "JUL": 4.97,
    "AUG": 5.74,
    "SEP": 5.32,
    "OCT": 5.46,
    "NOV": 5.34,
    "DEC": 5.45
  },
  {
    "LONG": -48.12,
    "LAT": -21.73,
    "CIDADE": "Américo Brasiliense",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.1,
    "FEB": 5.66,
    "MAR": 5.33,
    "APR": 5.38,
    "MAY": 4.94,
    "JUN": 4.82,
    "JUL": 5.01,
    "AUG": 5.69,
    "SEP": 5.3,
    "OCT": 5.4,
    "NOV": 5.27,
    "DEC": 5.3
  },
  {
    "LONG": -4.81,
    "LAT": -2.17,
    "CIDADE": "Santa Lúcia",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.09,
    "FEB": 5.63,
    "MAR": 5.31,
    "APR": 5.38,
    "MAY": 4.94,
    "JUN": 4.81,
    "JUL": 5,
    "AUG": 5.67,
    "SEP": 5.31,
    "OCT": 5.39,
    "NOV": 5.27,
    "DEC": 5.27
  },
  {
    "LONG": -47.48,
    "LAT": -21.71,
    "CIDADE": "Santa Rita do Passa Quatro",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.08,
    "FEB": 5.58,
    "MAR": 5.33,
    "APR": 5.45,
    "MAY": 4.95,
    "JUN": 4.87,
    "JUL": 4.97,
    "AUG": 5.76,
    "SEP": 5.26,
    "OCT": 5.35,
    "NOV": 5.24,
    "DEC": 5.32
  },
  {
    "LONG": -47.27,
    "LAT": -21.7,
    "CIDADE": "Tambaú",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.31,
    "APR": 5.44,
    "MAY": 4.95,
    "JUN": 4.8,
    "JUL": 4.99,
    "AUG": 5.75,
    "SEP": 5.32,
    "OCT": 5.38,
    "NOV": 5.23,
    "DEC": 5.26
  },
  {
    "LONG": -46.97,
    "LAT": -21.73,
    "CIDADE": "Itobi",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.15,
    "FEB": 5.62,
    "MAR": 5.41,
    "APR": 5.55,
    "MAY": 4.99,
    "JUN": 4.88,
    "JUL": 5.02,
    "AUG": 5.74,
    "SEP": 5.39,
    "OCT": 5.46,
    "NOV": 5.33,
    "DEC": 5.32
  },
  {
    "LONG": -46.82,
    "LAT": -21.7,
    "CIDADE": "São Sebastião da Grama",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.11,
    "FEB": 5.57,
    "MAR": 5.32,
    "APR": 5.53,
    "MAY": 4.99,
    "JUN": 4.91,
    "JUL": 5.08,
    "AUG": 5.78,
    "SEP": 5.42,
    "OCT": 5.44,
    "NOV": 5.27,
    "DEC": 5.28
  },
  {
    "LONG": -46.74,
    "LAT": -21.66,
    "CIDADE": "Divinolândia",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.05,
    "FEB": 5.5,
    "MAR": 5.21,
    "APR": 5.45,
    "MAY": 4.97,
    "JUN": 4.93,
    "JUL": 5.09,
    "AUG": 5.8,
    "SEP": 5.41,
    "OCT": 5.37,
    "NOV": 5.17,
    "DEC": 5.19
  },
  {
    "LONG": -51.35,
    "LAT": -21.57,
    "CIDADE": "Irapuru",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.32,
    "FEB": 5.73,
    "MAR": 5.58,
    "APR": 5.53,
    "MAY": 4.91,
    "JUN": 4.71,
    "JUL": 4.88,
    "AUG": 5.68,
    "SEP": 5.25,
    "OCT": 5.43,
    "NOV": 5.51,
    "DEC": 5.59
  },
  {
    "LONG": -51.27,
    "LAT": -21.56,
    "CIDADE": "Pacaembu",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.34,
    "FEB": 5.68,
    "MAR": 5.65,
    "APR": 5.56,
    "MAY": 4.94,
    "JUN": 4.76,
    "JUL": 4.88,
    "AUG": 5.68,
    "SEP": 5.23,
    "OCT": 5.43,
    "NOV": 5.55,
    "DEC": 5.57
  },
  {
    "LONG": -51.17,
    "LAT": -21.61,
    "CIDADE": "Flórida Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.32,
    "FEB": 5.72,
    "MAR": 5.66,
    "APR": 5.58,
    "MAY": 4.95,
    "JUN": 4.77,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.21,
    "OCT": 5.43,
    "NOV": 5.53,
    "DEC": 5.57
  },
  {
    "LONG": -50.86,
    "LAT": -2.16,
    "CIDADE": "Salmourão",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.32,
    "FEB": 5.65,
    "MAR": 5.58,
    "APR": 5.47,
    "MAY": 4.89,
    "JUN": 4.71,
    "JUL": 4.89,
    "AUG": 5.68,
    "SEP": 5.19,
    "OCT": 5.37,
    "NOV": 5.47,
    "DEC": 5.52
  },
  {
    "LONG": -50.5,
    "LAT": -2.16,
    "CIDADE": "Santópolis do Aguapeí",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.32,
    "FEB": 5.62,
    "MAR": 5.54,
    "APR": 5.5,
    "MAY": 4.89,
    "JUN": 4.73,
    "JUL": 4.94,
    "AUG": 5.71,
    "SEP": 5.21,
    "OCT": 5.45,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -50.6,
    "LAT": -2.16,
    "CIDADE": "Piacatu",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.32,
    "FEB": 5.62,
    "MAR": 5.54,
    "APR": 5.5,
    "MAY": 4.89,
    "JUN": 4.73,
    "JUL": 4.94,
    "AUG": 5.71,
    "SEP": 5.21,
    "OCT": 5.45,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -50.45,
    "LAT": "#VALOR!",
    "CIDADE": "Clementina",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.31,
    "FEB": 5.63,
    "MAR": 5.55,
    "APR": 5.51,
    "MAY": 4.89,
    "JUN": 4.73,
    "JUL": 4.94,
    "AUG": 5.71,
    "SEP": 5.24,
    "OCT": 5.44,
    "NOV": 5.5,
    "DEC": 5.56
  },
  {
    "LONG": -50.16,
    "LAT": -2.16,
    "CIDADE": "Alto Alegre",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.31,
    "FEB": 5.68,
    "MAR": 5.55,
    "APR": 5.5,
    "MAY": 4.88,
    "JUN": 4.69,
    "JUL": 4.86,
    "AUG": 5.67,
    "SEP": 5.27,
    "OCT": 5.48,
    "NOV": 5.49,
    "DEC": 5.55
  },
  {
    "LONG": -49.8,
    "LAT": -2.16,
    "CIDADE": "Guaiçara",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.31,
    "FEB": 5.74,
    "MAR": 5.59,
    "APR": 5.55,
    "MAY": 4.87,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.64,
    "SEP": 5.32,
    "OCT": 5.51,
    "NOV": 5.48,
    "DEC": 5.52
  },
  {
    "LONG": -49.07,
    "LAT": -21.62,
    "CIDADE": "Borborema",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.26,
    "FEB": 5.68,
    "MAR": 5.43,
    "APR": 5.45,
    "MAY": 4.9,
    "JUN": 4.79,
    "JUL": 4.96,
    "AUG": 5.68,
    "SEP": 5.32,
    "OCT": 5.44,
    "NOV": 5.37,
    "DEC": 5.46
  },
  {
    "LONG": -48.82,
    "LAT": -21.59,
    "CIDADE": "Itápolis",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.2,
    "FEB": 5.68,
    "MAR": 5.39,
    "APR": 5.41,
    "MAY": 4.9,
    "JUN": 4.79,
    "JUL": 4.99,
    "AUG": 5.67,
    "SEP": 5.34,
    "OCT": 5.45,
    "NOV": 5.33,
    "DEC": 5.4
  },
  {
    "LONG": -48.36,
    "LAT": -2.16,
    "CIDADE": "Matão",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.18,
    "FEB": 5.64,
    "MAR": 5.35,
    "APR": 5.37,
    "MAY": 4.95,
    "JUN": 4.83,
    "JUL": 4.97,
    "AUG": 5.72,
    "SEP": 5.32,
    "OCT": 5.44,
    "NOV": 5.3,
    "DEC": 5.38
  },
  {
    "LONG": -48.07,
    "LAT": -21.59,
    "CIDADE": "Rincão",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.08,
    "FEB": 5.68,
    "MAR": 5.36,
    "APR": 5.45,
    "MAY": 5,
    "JUN": 4.84,
    "JUL": 5.02,
    "AUG": 5.73,
    "SEP": 5.29,
    "OCT": 5.39,
    "NOV": 5.25,
    "DEC": 5.4
  },
  {
    "LONG": -47.7,
    "LAT": -21.55,
    "CIDADE": "Luís Antônio",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.07,
    "FEB": 5.63,
    "MAR": 5.28,
    "APR": 5.46,
    "MAY": 4.96,
    "JUN": 4.85,
    "JUL": 5,
    "AUG": 5.74,
    "SEP": 5.27,
    "OCT": 5.35,
    "NOV": 5.16,
    "DEC": 5.31
  },
  {
    "LONG": -46.89,
    "LAT": -21.6,
    "CIDADE": "São José do Rio Pardo",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.15,
    "FEB": 5.63,
    "MAR": 5.31,
    "APR": 5.51,
    "MAY": 5.01,
    "JUN": 4.9,
    "JUL": 5.09,
    "AUG": 5.79,
    "SEP": 5.42,
    "OCT": 5.42,
    "NOV": 5.29,
    "DEC": 5.27
  },
  {
    "LONG": -51.7,
    "LAT": -21.49,
    "CIDADE": "Ouro Verde",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.32,
    "FEB": 5.71,
    "MAR": 5.62,
    "APR": 5.52,
    "MAY": 4.94,
    "JUN": 4.7,
    "JUL": 4.86,
    "AUG": 5.63,
    "SEP": 5.23,
    "OCT": 5.45,
    "NOV": 5.49,
    "DEC": 5.6
  },
  {
    "LONG": -51.54,
    "LAT": -21.48,
    "CIDADE": "Dracena",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.32,
    "FEB": 5.71,
    "MAR": 5.64,
    "APR": 5.48,
    "MAY": 4.95,
    "JUN": 4.75,
    "JUL": 4.88,
    "AUG": 5.64,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -51.43,
    "LAT": -21.51,
    "CIDADE": "Junqueirópolis",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.33,
    "FEB": 5.71,
    "MAR": 5.62,
    "APR": 5.48,
    "MAY": 4.95,
    "JUN": 4.75,
    "JUL": 4.89,
    "AUG": 5.67,
    "SEP": 5.23,
    "OCT": 5.45,
    "NOV": 5.52,
    "DEC": 5.59
  },
  {
    "LONG": -50.55,
    "LAT": -2.15,
    "CIDADE": "Gabriel Monteiro",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.36,
    "FEB": 5.66,
    "MAR": 5.58,
    "APR": 5.56,
    "MAY": 4.89,
    "JUN": 4.75,
    "JUL": 4.93,
    "AUG": 5.71,
    "SEP": 5.24,
    "OCT": 5.46,
    "NOV": 5.53,
    "DEC": 5.55
  },
  {
    "LONG": -50.32,
    "LAT": -21.5,
    "CIDADE": "Braúna",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.37,
    "FEB": 5.7,
    "MAR": 5.54,
    "APR": 5.52,
    "MAY": 4.89,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.7,
    "SEP": 5.25,
    "OCT": 5.45,
    "NOV": 5.52,
    "DEC": 5.56
  },
  {
    "LONG": -49.95,
    "LAT": -21.46,
    "CIDADE": "Avanhandava",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.3,
    "FEB": 5.67,
    "MAR": 5.55,
    "APR": 5.53,
    "MAY": 4.88,
    "JUN": 4.74,
    "JUL": 4.96,
    "AUG": 5.66,
    "SEP": 5.29,
    "OCT": 5.5,
    "NOV": 5.5,
    "DEC": 5.57
  },
  {
    "LONG": -49.86,
    "LAT": -2.15,
    "CIDADE": "Promissão",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.32,
    "FEB": 5.75,
    "MAR": 5.57,
    "APR": 5.56,
    "MAY": 4.85,
    "JUN": 4.75,
    "JUL": 4.95,
    "AUG": 5.66,
    "SEP": 5.31,
    "OCT": 5.49,
    "NOV": 5.49,
    "DEC": 5.59
  },
  {
    "LONG": -4.96,
    "LAT": -21.46,
    "CIDADE": "Sabino",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.42,
    "FEB": 5.85,
    "MAR": 5.65,
    "APR": 5.48,
    "MAY": 4.87,
    "JUN": 4.69,
    "JUL": 4.96,
    "AUG": 5.63,
    "SEP": 5.3,
    "OCT": 5.52,
    "NOV": 5.52,
    "DEC": 5.63
  },
  {
    "LONG": -49.22,
    "LAT": -21.47,
    "CIDADE": "Novo Horizonte",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.31,
    "FEB": 5.69,
    "MAR": 5.49,
    "APR": 5.47,
    "MAY": 4.92,
    "JUN": 4.76,
    "JUL": 5.01,
    "AUG": 5.74,
    "SEP": 5.32,
    "OCT": 5.49,
    "NOV": 5.42,
    "DEC": 5.47
  },
  {
    "LONG": -4.84,
    "LAT": -2.15,
    "CIDADE": "Santa Ernestina",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.17,
    "FEB": 5.62,
    "MAR": 5.32,
    "APR": 5.43,
    "MAY": 4.98,
    "JUN": 4.86,
    "JUL": 5.02,
    "AUG": 5.72,
    "SEP": 5.33,
    "OCT": 5.44,
    "NOV": 5.31,
    "DEC": 5.38
  },
  {
    "LONG": -48.39,
    "LAT": -2.15,
    "CIDADE": "Dobrada",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.17,
    "FEB": 5.62,
    "MAR": 5.32,
    "APR": 5.43,
    "MAY": 4.98,
    "JUN": 4.86,
    "JUL": 5.02,
    "AUG": 5.72,
    "SEP": 5.33,
    "OCT": 5.44,
    "NOV": 5.31,
    "DEC": 5.38
  },
  {
    "LONG": -4.82,
    "LAT": -2.15,
    "CIDADE": "Motuca",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.13,
    "FEB": 5.64,
    "MAR": 5.33,
    "APR": 5.47,
    "MAY": 4.99,
    "JUN": 4.88,
    "JUL": 5.03,
    "AUG": 5.67,
    "SEP": 5.31,
    "OCT": 5.36,
    "NOV": 5.26,
    "DEC": 5.37
  },
  {
    "LONG": -4.8,
    "LAT": -21.49,
    "CIDADE": "Guatapará",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.18,
    "FEB": 5.7,
    "MAR": 5.37,
    "APR": 5.42,
    "MAY": 4.97,
    "JUN": 4.87,
    "JUL": 5.02,
    "AUG": 5.71,
    "SEP": 5.28,
    "OCT": 5.38,
    "NOV": 5.26,
    "DEC": 5.4
  },
  {
    "LONG": -47.55,
    "LAT": -21.47,
    "CIDADE": "São Simão",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.09,
    "FEB": 5.6,
    "MAR": 5.27,
    "APR": 5.44,
    "MAY": 4.97,
    "JUN": 4.87,
    "JUL": 5.03,
    "AUG": 5.76,
    "SEP": 5.3,
    "OCT": 5.38,
    "NOV": 5.15,
    "DEC": 5.3
  },
  {
    "LONG": -47.36,
    "LAT": -2.15,
    "CIDADE": "Santa Rosa de Viterbo",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.12,
    "FEB": 5.63,
    "MAR": 5.31,
    "APR": 5.49,
    "MAY": 4.98,
    "JUN": 4.87,
    "JUL": 5.05,
    "AUG": 5.77,
    "SEP": 5.32,
    "OCT": 5.43,
    "NOV": 5.26,
    "DEC": 5.3
  },
  {
    "LONG": -47,
    "LAT": -21.47,
    "CIDADE": "Mococa",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.1,
    "FEB": 5.57,
    "MAR": 5.31,
    "APR": 5.47,
    "MAY": 5,
    "JUN": 4.88,
    "JUL": 5.07,
    "AUG": 5.76,
    "SEP": 5.33,
    "OCT": 5.41,
    "NOV": 5.25,
    "DEC": 5.25
  },
  {
    "LONG": -46.75,
    "LAT": -21.47,
    "CIDADE": "Tapiratiba",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.06,
    "FEB": 5.52,
    "MAR": 5.14,
    "APR": 5.41,
    "MAY": 4.98,
    "JUN": 4.9,
    "JUL": 5.08,
    "AUG": 5.81,
    "SEP": 5.4,
    "OCT": 5.36,
    "NOV": 5.11,
    "DEC": 5.15
  },
  {
    "LONG": -46.64,
    "LAT": -21.53,
    "CIDADE": "Caconde",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 4.99,
    "FEB": 5.41,
    "MAR": 5.05,
    "APR": 5.39,
    "MAY": 4.94,
    "JUN": 4.78,
    "JUL": 5.08,
    "AUG": 5.77,
    "SEP": 5.39,
    "OCT": 5.31,
    "NOV": 5.04,
    "DEC": 5.03
  },
  {
    "LONG": -51.86,
    "LAT": -21.35,
    "CIDADE": "Panorama",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.35,
    "FEB": 5.81,
    "MAR": 5.69,
    "APR": 5.5,
    "MAY": 4.9,
    "JUN": 4.62,
    "JUL": 4.8,
    "AUG": 5.52,
    "SEP": 5.16,
    "OCT": 5.44,
    "NOV": 5.54,
    "DEC": 5.6
  },
  {
    "LONG": -51.75,
    "LAT": -21.35,
    "CIDADE": "Santa Mercedes",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.35,
    "FEB": 5.72,
    "MAR": 5.66,
    "APR": 5.51,
    "MAY": 4.94,
    "JUN": 4.71,
    "JUL": 4.87,
    "AUG": 5.64,
    "SEP": 5.25,
    "OCT": 5.46,
    "NOV": 5.52,
    "DEC": 5.6
  },
  {
    "LONG": -51.57,
    "LAT": -21.39,
    "CIDADE": "Tupi Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.32,
    "FEB": 5.7,
    "MAR": 5.64,
    "APR": 5.54,
    "MAY": 4.96,
    "JUN": 4.76,
    "JUL": 4.88,
    "AUG": 5.64,
    "SEP": 5.27,
    "OCT": 5.44,
    "NOV": 5.53,
    "DEC": 5.6
  },
  {
    "LONG": -50.48,
    "LAT": -21.4,
    "CIDADE": "Bilac",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.39,
    "FEB": 5.69,
    "MAR": 5.57,
    "APR": 5.53,
    "MAY": 4.91,
    "JUN": 4.72,
    "JUL": 4.96,
    "AUG": 5.73,
    "SEP": 5.24,
    "OCT": 5.46,
    "NOV": 5.52,
    "DEC": 5.56
  },
  {
    "LONG": -50.29,
    "LAT": -21.35,
    "CIDADE": "Coroados",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.38,
    "FEB": 5.77,
    "MAR": 5.55,
    "APR": 5.5,
    "MAY": 4.92,
    "JUN": 4.73,
    "JUL": 4.96,
    "AUG": 5.71,
    "SEP": 5.27,
    "OCT": 5.48,
    "NOV": 5.52,
    "DEC": 5.59
  },
  {
    "LONG": -50.21,
    "LAT": -2.14,
    "CIDADE": "Glicério",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.38,
    "FEB": 5.77,
    "MAR": 5.55,
    "APR": 5.5,
    "MAY": 4.92,
    "JUN": 4.73,
    "JUL": 4.96,
    "AUG": 5.71,
    "SEP": 5.27,
    "OCT": 5.48,
    "NOV": 5.52,
    "DEC": 5.59
  },
  {
    "LONG": -50.08,
    "LAT": -21.42,
    "CIDADE": "Penápolis",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.34,
    "FEB": 5.73,
    "MAR": 5.6,
    "APR": 5.53,
    "MAY": 4.91,
    "JUN": 4.75,
    "JUL": 5,
    "AUG": 5.72,
    "SEP": 5.28,
    "OCT": 5.52,
    "NOV": 5.57,
    "DEC": 5.6
  },
  {
    "LONG": -48.51,
    "LAT": -21.41,
    "CIDADE": "Taquaritinga",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.18,
    "FEB": 5.66,
    "MAR": 5.34,
    "APR": 5.45,
    "MAY": 5.02,
    "JUN": 4.87,
    "JUL": 5.04,
    "AUG": 5.8,
    "SEP": 5.36,
    "OCT": 5.47,
    "NOV": 5.39,
    "DEC": 5.42
  },
  {
    "LONG": -4.82,
    "LAT": -21.36,
    "CIDADE": "Guariba",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.18,
    "FEB": 5.69,
    "MAR": 5.35,
    "APR": 5.46,
    "MAY": 5.01,
    "JUN": 4.87,
    "JUL": 5.06,
    "AUG": 5.71,
    "SEP": 5.33,
    "OCT": 5.4,
    "NOV": 5.31,
    "DEC": 5.39
  },
  {
    "LONG": -48.07,
    "LAT": -2.14,
    "CIDADE": "Pradópolis",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.15,
    "FEB": 5.67,
    "MAR": 5.4,
    "APR": 5.4,
    "MAY": 5.01,
    "JUN": 4.88,
    "JUL": 5.03,
    "AUG": 5.69,
    "SEP": 5.26,
    "OCT": 5.37,
    "NOV": 5.26,
    "DEC": 5.41
  },
  {
    "LONG": -51.83,
    "LAT": -21.32,
    "CIDADE": "Paulicéia",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.31,
    "FEB": 5.78,
    "MAR": 5.68,
    "APR": 5.51,
    "MAY": 4.92,
    "JUN": 4.67,
    "JUL": 4.79,
    "AUG": 5.52,
    "SEP": 5.17,
    "OCT": 5.42,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -51.64,
    "LAT": -2.13,
    "CIDADE": "Nova Guataporanga",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.37,
    "FEB": 5.7,
    "MAR": 5.68,
    "APR": 5.5,
    "MAY": 4.95,
    "JUN": 4.73,
    "JUL": 4.88,
    "AUG": 5.7,
    "SEP": 5.24,
    "OCT": 5.44,
    "NOV": 5.55,
    "DEC": 5.61
  },
  {
    "LONG": -5.17,
    "LAT": -2.13,
    "CIDADE": "São João do Pau d'Alho",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.37,
    "FEB": 5.7,
    "MAR": 5.68,
    "APR": 5.5,
    "MAY": 4.95,
    "JUN": 4.73,
    "JUL": 4.88,
    "AUG": 5.7,
    "SEP": 5.24,
    "OCT": 5.44,
    "NOV": 5.55,
    "DEC": 5.61
  },
  {
    "LONG": -51.57,
    "LAT": -21.3,
    "CIDADE": "Monte Castelo",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.29,
    "FEB": 5.69,
    "MAR": 5.61,
    "APR": 5.52,
    "MAY": 4.97,
    "JUN": 4.74,
    "JUL": 4.89,
    "AUG": 5.67,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.55,
    "DEC": 5.63
  },
  {
    "LONG": -50.81,
    "LAT": -2.13,
    "CIDADE": "Bento de Abreu",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.33,
    "FEB": 5.66,
    "MAR": 5.62,
    "APR": 5.54,
    "MAY": 4.91,
    "JUN": 4.77,
    "JUL": 4.93,
    "AUG": 5.72,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.51,
    "DEC": 5.56
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.13,
    "CIDADE": "Rubiácea",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.33,
    "FEB": 5.66,
    "MAR": 5.6,
    "APR": 5.53,
    "MAY": 4.9,
    "JUN": 4.75,
    "JUL": 4.94,
    "AUG": 5.7,
    "SEP": 5.27,
    "OCT": 5.46,
    "NOV": 5.5,
    "DEC": 5.56
  },
  {
    "LONG": -50.65,
    "LAT": -21.25,
    "CIDADE": "Guararapes",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.35,
    "FEB": 5.7,
    "MAR": 5.58,
    "APR": 5.56,
    "MAY": 4.9,
    "JUN": 4.74,
    "JUL": 4.97,
    "AUG": 5.73,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.56,
    "DEC": 5.57
  },
  {
    "LONG": -50.34,
    "LAT": -21.29,
    "CIDADE": "Birigui",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.38,
    "FEB": 5.7,
    "MAR": 5.55,
    "APR": 5.52,
    "MAY": 4.94,
    "JUN": 4.76,
    "JUL": 4.98,
    "AUG": 5.7,
    "SEP": 5.28,
    "OCT": 5.5,
    "NOV": 5.55,
    "DEC": 5.6
  },
  {
    "LONG": -49.95,
    "LAT": -21.27,
    "CIDADE": "Barbosa",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.35,
    "FEB": 5.74,
    "MAR": 5.59,
    "APR": 5.49,
    "MAY": 4.88,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.64,
    "SEP": 5.27,
    "OCT": 5.51,
    "NOV": 5.54,
    "DEC": 5.64
  },
  {
    "LONG": -49.41,
    "LAT": -21.28,
    "CIDADE": "Irapuã",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.34,
    "FEB": 5.69,
    "MAR": 5.49,
    "APR": 5.51,
    "MAY": 4.93,
    "JUN": 4.79,
    "JUL": 4.99,
    "AUG": 5.7,
    "SEP": 5.33,
    "OCT": 5.49,
    "NOV": 5.43,
    "DEC": 5.52
  },
  {
    "LONG": -49.5,
    "LAT": -21.34,
    "CIDADE": "Sales",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.34,
    "FEB": 5.69,
    "MAR": 5.49,
    "APR": 5.51,
    "MAY": 4.93,
    "JUN": 4.79,
    "JUL": 4.99,
    "AUG": 5.7,
    "SEP": 5.33,
    "OCT": 5.49,
    "NOV": 5.43,
    "DEC": 5.52
  },
  {
    "LONG": -49.13,
    "LAT": -21.26,
    "CIDADE": "Marapoama",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.27,
    "FEB": 5.63,
    "MAR": 5.43,
    "APR": 5.45,
    "MAY": 4.95,
    "JUN": 4.85,
    "JUL": 5.04,
    "AUG": 5.8,
    "SEP": 5.39,
    "OCT": 5.48,
    "NOV": 5.36,
    "DEC": 5.43
  },
  {
    "LONG": -49.05,
    "LAT": -2.13,
    "CIDADE": "Itajobi",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.27,
    "FEB": 5.61,
    "MAR": 5.39,
    "APR": 5.44,
    "MAY": 4.97,
    "JUN": 4.84,
    "JUL": 5.03,
    "AUG": 5.82,
    "SEP": 5.39,
    "OCT": 5.47,
    "NOV": 5.37,
    "DEC": 5.41
  },
  {
    "LONG": -48.69,
    "LAT": -21.27,
    "CIDADE": "Fernando Prestes",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.18,
    "FEB": 5.68,
    "MAR": 5.42,
    "APR": 5.49,
    "MAY": 5.04,
    "JUN": 4.9,
    "JUL": 5.1,
    "AUG": 5.82,
    "SEP": 5.41,
    "OCT": 5.48,
    "NOV": 5.39,
    "DEC": 5.43
  },
  {
    "LONG": -4.86,
    "LAT": -2.13,
    "CIDADE": "Cândido Rodrigues",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.18,
    "FEB": 5.68,
    "MAR": 5.42,
    "APR": 5.49,
    "MAY": 5.04,
    "JUN": 4.9,
    "JUL": 5.1,
    "AUG": 5.82,
    "SEP": 5.41,
    "OCT": 5.48,
    "NOV": 5.39,
    "DEC": 5.43
  },
  {
    "LONG": -48.5,
    "LAT": -2.13,
    "CIDADE": "Monte Alto",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.15,
    "FEB": 5.65,
    "MAR": 5.31,
    "APR": 5.44,
    "MAY": 5.04,
    "JUN": 4.9,
    "JUL": 5.11,
    "AUG": 5.78,
    "SEP": 5.38,
    "OCT": 5.48,
    "NOV": 5.38,
    "DEC": 5.42
  },
  {
    "LONG": -48.33,
    "LAT": -21.25,
    "CIDADE": "Jaboticabal",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.21,
    "FEB": 5.67,
    "MAR": 5.35,
    "APR": 5.46,
    "MAY": 5.04,
    "JUN": 4.92,
    "JUL": 5.11,
    "AUG": 5.72,
    "SEP": 5.35,
    "OCT": 5.45,
    "NOV": 5.33,
    "DEC": 5.41
  },
  {
    "LONG": -47.73,
    "LAT": -21.34,
    "CIDADE": "Cravinhos",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.05,
    "FEB": 5.63,
    "MAR": 5.26,
    "APR": 5.42,
    "MAY": 4.97,
    "JUN": 4.97,
    "JUL": 5.1,
    "AUG": 5.75,
    "SEP": 5.33,
    "OCT": 5.36,
    "NOV": 5.22,
    "DEC": 5.33
  },
  {
    "LONG": -47.56,
    "LAT": -21.31,
    "CIDADE": "Serra Azul",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.07,
    "FEB": 5.6,
    "MAR": 5.32,
    "APR": 5.46,
    "MAY": 5.01,
    "JUN": 4.95,
    "JUL": 5.11,
    "AUG": 5.82,
    "SEP": 5.31,
    "OCT": 5.39,
    "NOV": 5.21,
    "DEC": 5.37
  },
  {
    "LONG": -47.43,
    "LAT": -21.3,
    "CIDADE": "Santa Cruz da Esperança",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.1,
    "FEB": 5.59,
    "MAR": 5.31,
    "APR": 5.47,
    "MAY": 5,
    "JUN": 4.91,
    "JUL": 5.12,
    "AUG": 5.81,
    "SEP": 5.32,
    "OCT": 5.42,
    "NOV": 5.22,
    "DEC": 5.35
  },
  {
    "LONG": -47.3,
    "LAT": -21.28,
    "CIDADE": "Cajuru",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.05,
    "FEB": 5.52,
    "MAR": 5.24,
    "APR": 5.42,
    "MAY": 5.01,
    "JUN": 4.91,
    "JUL": 5.12,
    "AUG": 5.85,
    "SEP": 5.35,
    "OCT": 5.4,
    "NOV": 5.17,
    "DEC": 5.27
  },
  {
    "LONG": -47.16,
    "LAT": -21.28,
    "CIDADE": "Cássia dos Coqueiros",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 4.92,
    "FEB": 5.39,
    "MAR": 5.05,
    "APR": 5.35,
    "MAY": 5.01,
    "JUN": 4.96,
    "JUL": 5.13,
    "AUG": 5.88,
    "SEP": 5.34,
    "OCT": 5.33,
    "NOV": 5.03,
    "DEC": 5.09
  },
  {
    "LONG": -51.04,
    "LAT": -21.17,
    "CIDADE": "Lavínia",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.33,
    "FEB": 5.69,
    "MAR": 5.62,
    "APR": 5.56,
    "MAY": 4.95,
    "JUN": 4.8,
    "JUL": 4.93,
    "AUG": 5.74,
    "SEP": 5.25,
    "OCT": 5.43,
    "NOV": 5.51,
    "DEC": 5.54
  },
  {
    "LONG": -50.87,
    "LAT": -21.22,
    "CIDADE": "Valparaíso",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.35,
    "FEB": 5.68,
    "MAR": 5.61,
    "APR": 5.53,
    "MAY": 4.93,
    "JUN": 4.78,
    "JUL": 4.96,
    "AUG": 5.74,
    "SEP": 5.29,
    "OCT": 5.45,
    "NOV": 5.51,
    "DEC": 5.57
  },
  {
    "LONG": -50.44,
    "LAT": -21.21,
    "CIDADE": "Araçatuba",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.39,
    "FEB": 5.72,
    "MAR": 5.6,
    "APR": 5.55,
    "MAY": 4.94,
    "JUN": 4.76,
    "JUL": 4.97,
    "AUG": 5.75,
    "SEP": 5.27,
    "OCT": 5.5,
    "NOV": 5.58,
    "DEC": 5.59
  },
  {
    "LONG": -50.18,
    "LAT": -2.12,
    "CIDADE": "Brejo Alegre",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.33,
    "FEB": 5.71,
    "MAR": 5.56,
    "APR": 5.49,
    "MAY": 4.86,
    "JUN": 4.69,
    "JUL": 4.91,
    "AUG": 5.62,
    "SEP": 5.21,
    "OCT": 5.48,
    "NOV": 5.53,
    "DEC": 5.61
  },
  {
    "LONG": -49.72,
    "LAT": -21.17,
    "CIDADE": "Ubarana",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.31,
    "FEB": 5.66,
    "MAR": 5.46,
    "APR": 5.51,
    "MAY": 4.9,
    "JUN": 4.79,
    "JUL": 5,
    "AUG": 5.7,
    "SEP": 5.33,
    "OCT": 5.49,
    "NOV": 5.48,
    "DEC": 5.52
  },
  {
    "LONG": -49.65,
    "LAT": -2.12,
    "CIDADE": "Adolfo",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.33,
    "FEB": 5.66,
    "MAR": 5.48,
    "APR": 5.49,
    "MAY": 4.88,
    "JUN": 4.78,
    "JUL": 4.99,
    "AUG": 5.7,
    "SEP": 5.35,
    "OCT": 5.5,
    "NOV": 5.45,
    "DEC": 5.48
  },
  {
    "LONG": -49.58,
    "LAT": -21.18,
    "CIDADE": "Mendonça",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.33,
    "FEB": 5.65,
    "MAR": 5.48,
    "APR": 5.49,
    "MAY": 4.92,
    "JUN": 4.78,
    "JUL": 4.99,
    "AUG": 5.68,
    "SEP": 5.34,
    "OCT": 5.49,
    "NOV": 5.43,
    "DEC": 5.47
  },
  {
    "LONG": -49.29,
    "LAT": -21.2,
    "CIDADE": "Urupês",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.27,
    "FEB": 5.66,
    "MAR": 5.43,
    "APR": 5.48,
    "MAY": 4.96,
    "JUN": 4.86,
    "JUL": 5.05,
    "AUG": 5.81,
    "SEP": 5.4,
    "OCT": 5.48,
    "NOV": 5.41,
    "DEC": 5.44
  },
  {
    "LONG": -49.11,
    "LAT": -2.12,
    "CIDADE": "Elisiário",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.23,
    "FEB": 5.66,
    "MAR": 5.41,
    "APR": 5.48,
    "MAY": 4.97,
    "JUN": 4.85,
    "JUL": 5.05,
    "AUG": 5.8,
    "SEP": 5.41,
    "OCT": 5.47,
    "NOV": 5.37,
    "DEC": 5.44
  },
  {
    "LONG": -4.89,
    "LAT": -21.19,
    "CIDADE": "Pindorama",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.19,
    "FEB": 5.54,
    "MAR": 5.34,
    "APR": 5.45,
    "MAY": 4.98,
    "JUN": 4.88,
    "JUL": 5.07,
    "AUG": 5.85,
    "SEP": 5.41,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.4
  },
  {
    "LONG": -48.81,
    "LAT": -21.24,
    "CIDADE": "Santa Adélia",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.22,
    "FEB": 5.6,
    "MAR": 5.36,
    "APR": 5.48,
    "MAY": 5.01,
    "JUN": 4.89,
    "JUL": 5.08,
    "AUG": 5.83,
    "SEP": 5.42,
    "OCT": 5.47,
    "NOV": 5.36,
    "DEC": 5.42
  },
  {
    "LONG": -48.79,
    "LAT": -21.19,
    "CIDADE": "Ariranha",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.25,
    "FEB": 5.68,
    "MAR": 5.37,
    "APR": 5.48,
    "MAY": 5.02,
    "JUN": 4.9,
    "JUL": 5.09,
    "AUG": 5.81,
    "SEP": 5.42,
    "OCT": 5.46,
    "NOV": 5.36,
    "DEC": 5.43
  },
  {
    "LONG": -4.86,
    "LAT": -2.12,
    "CIDADE": "Vista Alegre do Alto",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.23,
    "FEB": 5.69,
    "MAR": 5.39,
    "APR": 5.46,
    "MAY": 5.04,
    "JUN": 4.94,
    "JUL": 5.13,
    "AUG": 5.81,
    "SEP": 5.44,
    "OCT": 5.48,
    "NOV": 5.39,
    "DEC": 5.42
  },
  {
    "LONG": -4.82,
    "LAT": -2.12,
    "CIDADE": "Barrinha",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.19,
    "FEB": 5.75,
    "MAR": 5.36,
    "APR": 5.5,
    "MAY": 5.07,
    "JUN": 4.88,
    "JUL": 5.12,
    "AUG": 5.78,
    "SEP": 5.32,
    "OCT": 5.42,
    "NOV": 5.33,
    "DEC": 5.38
  },
  {
    "LONG": -4.8,
    "LAT": -21.23,
    "CIDADE": "Dumont",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.17,
    "FEB": 5.7,
    "MAR": 5.35,
    "APR": 5.48,
    "MAY": 5.03,
    "JUN": 4.95,
    "JUL": 5.15,
    "AUG": 5.79,
    "SEP": 5.33,
    "OCT": 5.39,
    "NOV": 5.28,
    "DEC": 5.43
  },
  {
    "LONG": -47.81,
    "LAT": -21.17,
    "CIDADE": "Ribeirão Preto",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.09,
    "FEB": 5.66,
    "MAR": 5.34,
    "APR": 5.45,
    "MAY": 5.02,
    "JUN": 4.98,
    "JUL": 5.15,
    "AUG": 5.77,
    "SEP": 5.34,
    "OCT": 5.38,
    "NOV": 5.24,
    "DEC": 5.39
  },
  {
    "LONG": -47.6,
    "LAT": -21.2,
    "CIDADE": "Serrana",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.06,
    "FEB": 5.55,
    "MAR": 5.29,
    "APR": 5.46,
    "MAY": 5.03,
    "JUN": 4.96,
    "JUL": 5.12,
    "AUG": 5.86,
    "SEP": 5.34,
    "OCT": 5.4,
    "NOV": 5.18,
    "DEC": 5.31
  },
  {
    "LONG": -5.15,
    "LAT": -21.1,
    "CIDADE": "Nova Independência",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.3,
    "FEB": 5.66,
    "MAR": 5.61,
    "APR": 5.54,
    "MAY": 4.99,
    "JUN": 4.78,
    "JUL": 4.95,
    "AUG": 5.74,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.5,
    "DEC": 5.61
  },
  {
    "LONG": -51.1,
    "LAT": -21.13,
    "CIDADE": "Mirandópolis",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.31,
    "FEB": 5.68,
    "MAR": 5.62,
    "APR": 5.54,
    "MAY": 5,
    "JUN": 4.82,
    "JUL": 4.94,
    "AUG": 5.8,
    "SEP": 5.31,
    "OCT": 5.46,
    "NOV": 5.55,
    "DEC": 5.56
  },
  {
    "LONG": -50.15,
    "LAT": -21.07,
    "CIDADE": "Buritama",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.23,
    "FEB": 5.62,
    "MAR": 5.52,
    "APR": 5.55,
    "MAY": 4.9,
    "JUN": 4.7,
    "JUL": 4.94,
    "AUG": 5.66,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -50.05,
    "LAT": -2.11,
    "CIDADE": "Zacarias",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.32,
    "FEB": 5.66,
    "MAR": 5.54,
    "APR": 5.55,
    "MAY": 4.94,
    "JUN": 4.72,
    "JUL": 4.98,
    "AUG": 5.69,
    "SEP": 5.3,
    "OCT": 5.46,
    "NOV": 5.51,
    "DEC": 5.54
  },
  {
    "LONG": -49.69,
    "LAT": -21.06,
    "CIDADE": "José Bonifácio",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.32,
    "FEB": 5.61,
    "MAR": 5.44,
    "APR": 5.5,
    "MAY": 4.95,
    "JUN": 4.83,
    "JUL": 5.07,
    "AUG": 5.78,
    "SEP": 5.38,
    "OCT": 5.47,
    "NOV": 5.43,
    "DEC": 5.46
  },
  {
    "LONG": -49.25,
    "LAT": -21.08,
    "CIDADE": "Ibirá",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.26,
    "FEB": 5.67,
    "MAR": 5.41,
    "APR": 5.49,
    "MAY": 5,
    "JUN": 4.84,
    "JUL": 5.07,
    "AUG": 5.81,
    "SEP": 5.43,
    "OCT": 5.48,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -4.91,
    "LAT": -21.05,
    "CIDADE": "Catiguá",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.18,
    "FEB": 5.63,
    "MAR": 5.38,
    "APR": 5.44,
    "MAY": 5,
    "JUN": 4.88,
    "JUL": 5.08,
    "AUG": 5.85,
    "SEP": 5.4,
    "OCT": 5.46,
    "NOV": 5.37,
    "DEC": 5.42
  },
  {
    "LONG": -48.98,
    "LAT": -21.13,
    "CIDADE": "Catanduva",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.21,
    "FEB": 5.59,
    "MAR": 5.34,
    "APR": 5.43,
    "MAY": 4.99,
    "JUN": 4.91,
    "JUL": 5.09,
    "AUG": 5.88,
    "SEP": 5.43,
    "OCT": 5.45,
    "NOV": 5.37,
    "DEC": 5.44
  },
  {
    "LONG": -48.8,
    "LAT": -21.09,
    "CIDADE": "Palmares Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.26,
    "FEB": 5.64,
    "MAR": 5.37,
    "APR": 5.46,
    "MAY": 5.01,
    "JUN": 4.93,
    "JUL": 5.11,
    "AUG": 5.86,
    "SEP": 5.45,
    "OCT": 5.48,
    "NOV": 5.37,
    "DEC": 5.43
  },
  {
    "LONG": -48.66,
    "LAT": -21.09,
    "CIDADE": "Pirangi",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.29,
    "FEB": 5.7,
    "MAR": 5.35,
    "APR": 5.42,
    "MAY": 5.03,
    "JUN": 4.96,
    "JUL": 5.14,
    "AUG": 5.81,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.37,
    "DEC": 5.41
  },
  {
    "LONG": -48.51,
    "LAT": -21.14,
    "CIDADE": "Taiaçu",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.25,
    "FEB": 5.73,
    "MAR": 5.38,
    "APR": 5.45,
    "MAY": 5.04,
    "JUN": 4.95,
    "JUL": 5.15,
    "AUG": 5.8,
    "SEP": 5.42,
    "OCT": 5.46,
    "NOV": 5.38,
    "DEC": 5.43
  },
  {
    "LONG": -4.84,
    "LAT": -21.07,
    "CIDADE": "Taquaral",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.2,
    "FEB": 5.71,
    "MAR": 5.39,
    "APR": 5.47,
    "MAY": 5.04,
    "JUN": 4.94,
    "JUL": 5.15,
    "AUG": 5.83,
    "SEP": 5.41,
    "OCT": 5.45,
    "NOV": 5.39,
    "DEC": 5.44
  },
  {
    "LONG": -4.85,
    "LAT": -2.11,
    "CIDADE": "Taiúva",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.2,
    "FEB": 5.71,
    "MAR": 5.39,
    "APR": 5.47,
    "MAY": 5.04,
    "JUN": 4.94,
    "JUL": 5.15,
    "AUG": 5.83,
    "SEP": 5.41,
    "OCT": 5.45,
    "NOV": 5.39,
    "DEC": 5.44
  },
  {
    "LONG": -47.99,
    "LAT": -21.13,
    "CIDADE": "Sertãozinho",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.19,
    "FEB": 5.7,
    "MAR": 5.38,
    "APR": 5.51,
    "MAY": 5.08,
    "JUN": 4.97,
    "JUL": 5.15,
    "AUG": 5.85,
    "SEP": 5.33,
    "OCT": 5.41,
    "NOV": 5.32,
    "DEC": 5.43
  },
  {
    "LONG": -47.15,
    "LAT": -21.09,
    "CIDADE": "Santo Antônio da Alegria",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5,
    "FEB": 5.46,
    "MAR": 5.15,
    "APR": 5.37,
    "MAY": 5.07,
    "JUN": 5,
    "JUL": 5.19,
    "AUG": 5.89,
    "SEP": 5.39,
    "OCT": 5.39,
    "NOV": 5.13,
    "DEC": 5.14
  },
  {
    "LONG": -51.21,
    "LAT": -21.03,
    "CIDADE": "Guaraçaí",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.31,
    "FEB": 5.65,
    "MAR": 5.61,
    "APR": 5.59,
    "MAY": 5,
    "JUN": 4.83,
    "JUL": 4.95,
    "AUG": 5.77,
    "SEP": 5.32,
    "OCT": 5.5,
    "NOV": 5.54,
    "DEC": 5.55
  },
  {
    "LONG": -51.28,
    "LAT": -20.99,
    "CIDADE": "Murutinga do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.31,
    "FEB": 5.65,
    "MAR": 5.61,
    "APR": 5.59,
    "MAY": 5,
    "JUN": 4.83,
    "JUL": 4.95,
    "AUG": 5.77,
    "SEP": 5.32,
    "OCT": 5.5,
    "NOV": 5.54,
    "DEC": 5.55
  },
  {
    "LONG": -50.23,
    "LAT": -20.97,
    "CIDADE": "Lourdes",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.28,
    "FEB": 5.63,
    "MAR": 5.49,
    "APR": 5.57,
    "MAY": 4.98,
    "JUN": 4.76,
    "JUL": 5.01,
    "AUG": 5.75,
    "SEP": 5.27,
    "OCT": 5.49,
    "NOV": 5.49,
    "DEC": 5.56
  },
  {
    "LONG": -49.93,
    "LAT": -2.1,
    "CIDADE": "Planalto",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.28,
    "FEB": 5.65,
    "MAR": 5.46,
    "APR": 5.57,
    "MAY": 4.99,
    "JUN": 4.85,
    "JUL": 5.02,
    "AUG": 5.77,
    "SEP": 5.35,
    "OCT": 5.47,
    "NOV": 5.48,
    "DEC": 5.51
  },
  {
    "LONG": -49.5,
    "LAT": -21.02,
    "CIDADE": "Nova Aliança",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.2,
    "FEB": 5.64,
    "MAR": 5.4,
    "APR": 5.49,
    "MAY": 4.98,
    "JUN": 4.88,
    "JUL": 5.12,
    "AUG": 5.83,
    "SEP": 5.41,
    "OCT": 5.46,
    "NOV": 5.42,
    "DEC": 5.46
  },
  {
    "LONG": -49.38,
    "LAT": -2.1,
    "CIDADE": "Potirendaba",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.23,
    "FEB": 5.59,
    "MAR": 5.37,
    "APR": 5.48,
    "MAY": 5.01,
    "JUN": 4.9,
    "JUL": 5.09,
    "AUG": 5.84,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.39,
    "DEC": 5.41
  },
  {
    "LONG": -49.17,
    "LAT": -2.1,
    "CIDADE": "Uchoa",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.23,
    "FEB": 5.62,
    "MAR": 5.38,
    "APR": 5.48,
    "MAY": 5.01,
    "JUN": 4.92,
    "JUL": 5.11,
    "AUG": 5.83,
    "SEP": 5.43,
    "OCT": 5.45,
    "NOV": 5.38,
    "DEC": 5.43
  },
  {
    "LONG": -49.03,
    "LAT": -20.96,
    "CIDADE": "Tabapuã",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.24,
    "FEB": 5.63,
    "MAR": 5.39,
    "APR": 5.47,
    "MAY": 5.02,
    "JUN": 4.93,
    "JUL": 5.12,
    "AUG": 5.86,
    "SEP": 5.44,
    "OCT": 5.46,
    "NOV": 5.38,
    "DEC": 5.43
  },
  {
    "LONG": -48.92,
    "LAT": -2.1,
    "CIDADE": "Novais",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.27,
    "FEB": 5.61,
    "MAR": 5.37,
    "APR": 5.44,
    "MAY": 5.01,
    "JUN": 4.95,
    "JUL": 5.15,
    "AUG": 5.87,
    "SEP": 5.45,
    "OCT": 5.45,
    "NOV": 5.4,
    "DEC": 5.42
  },
  {
    "LONG": -48.83,
    "LAT": -20.98,
    "CIDADE": "Embaúba",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.25,
    "FEB": 5.65,
    "MAR": 5.37,
    "APR": 5.42,
    "MAY": 5,
    "JUN": 4.95,
    "JUL": 5.18,
    "AUG": 5.87,
    "SEP": 5.44,
    "OCT": 5.46,
    "NOV": 5.39,
    "DEC": 5.42
  },
  {
    "LONG": -48.78,
    "LAT": -21.02,
    "CIDADE": "Paraíso",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.23,
    "FEB": 5.67,
    "MAR": 5.38,
    "APR": 5.42,
    "MAY": 5.02,
    "JUN": 4.95,
    "JUL": 5.19,
    "AUG": 5.87,
    "SEP": 5.42,
    "OCT": 5.45,
    "NOV": 5.37,
    "DEC": 5.43
  },
  {
    "LONG": -48.22,
    "LAT": -21.01,
    "CIDADE": "Pitangueiras",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.22,
    "FEB": 5.78,
    "MAR": 5.39,
    "APR": 5.46,
    "MAY": 5.09,
    "JUN": 4.97,
    "JUL": 5.18,
    "AUG": 5.81,
    "SEP": 5.34,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.47
  },
  {
    "LONG": -48.04,
    "LAT": -2.1,
    "CIDADE": "Pontal",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.24,
    "FEB": 5.76,
    "MAR": 5.43,
    "APR": 5.57,
    "MAY": 5.08,
    "JUN": 4.93,
    "JUL": 5.22,
    "AUG": 5.86,
    "SEP": 5.33,
    "OCT": 5.46,
    "NOV": 5.36,
    "DEC": 5.49
  },
  {
    "LONG": -4.78,
    "LAT": -21.02,
    "CIDADE": "Jardinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.12,
    "FEB": 5.62,
    "MAR": 5.32,
    "APR": 5.53,
    "MAY": 5.08,
    "JUN": 5.01,
    "JUL": 5.19,
    "AUG": 5.87,
    "SEP": 5.39,
    "OCT": 5.39,
    "NOV": 5.21,
    "DEC": 5.31
  },
  {
    "LONG": -47.66,
    "LAT": -2.1,
    "CIDADE": "Brodowski",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.08,
    "FEB": 5.53,
    "MAR": 5.24,
    "APR": 5.45,
    "MAY": 5.04,
    "JUN": 4.99,
    "JUL": 5.17,
    "AUG": 5.86,
    "SEP": 5.36,
    "OCT": 5.34,
    "NOV": 5.16,
    "DEC": 5.25
  },
  {
    "LONG": -47.37,
    "LAT": -21.02,
    "CIDADE": "Altinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.02,
    "FEB": 5.53,
    "MAR": 5.12,
    "APR": 5.42,
    "MAY": 5.09,
    "JUN": 5.03,
    "JUL": 5.23,
    "AUG": 5.96,
    "SEP": 5.42,
    "OCT": 5.34,
    "NOV": 5.08,
    "DEC": 5.16
  },
  {
    "LONG": -51.49,
    "LAT": -20.87,
    "CIDADE": "Castilho",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.38,
    "FEB": 5.68,
    "MAR": 5.58,
    "APR": 5.54,
    "MAY": 4.99,
    "JUN": 4.81,
    "JUL": 4.95,
    "AUG": 5.79,
    "SEP": 5.31,
    "OCT": 5.5,
    "NOV": 5.49,
    "DEC": 5.65
  },
  {
    "LONG": -5.14,
    "LAT": -20.9,
    "CIDADE": "Andradina",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.37,
    "FEB": 5.65,
    "MAR": 5.58,
    "APR": 5.57,
    "MAY": 5.01,
    "JUN": 4.82,
    "JUL": 4.96,
    "AUG": 5.82,
    "SEP": 5.31,
    "OCT": 5.5,
    "NOV": 5.52,
    "DEC": 5.63
  },
  {
    "LONG": -50.5,
    "LAT": -2.09,
    "CIDADE": "Santo Antônio do Aracanguá",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.31,
    "FEB": 5.62,
    "MAR": 5.55,
    "APR": 5.56,
    "MAY": 5,
    "JUN": 4.8,
    "JUL": 5.01,
    "AUG": 5.79,
    "SEP": 5.28,
    "OCT": 5.5,
    "NOV": 5.48,
    "DEC": 5.55
  },
  {
    "LONG": -50.26,
    "LAT": -20.86,
    "CIDADE": "Nova Luzitânia",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.28,
    "FEB": 5.64,
    "MAR": 5.49,
    "APR": 5.59,
    "MAY": 4.98,
    "JUN": 4.8,
    "JUL": 5.03,
    "AUG": 5.78,
    "SEP": 5.28,
    "OCT": 5.49,
    "NOV": 5.49,
    "DEC": 5.54
  },
  {
    "LONG": -50.11,
    "LAT": -20.95,
    "CIDADE": "Turiúba",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.26,
    "FEB": 5.63,
    "MAR": 5.48,
    "APR": 5.62,
    "MAY": 4.99,
    "JUN": 4.85,
    "JUL": 5.04,
    "AUG": 5.81,
    "SEP": 5.31,
    "OCT": 5.52,
    "NOV": 5.51,
    "DEC": 5.51
  },
  {
    "LONG": -49.9,
    "LAT": -2.09,
    "CIDADE": "União Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.27,
    "FEB": 5.62,
    "MAR": 5.45,
    "APR": 5.52,
    "MAY": 5.01,
    "JUN": 4.87,
    "JUL": 5.09,
    "AUG": 5.87,
    "SEP": 5.38,
    "OCT": 5.47,
    "NOV": 5.43,
    "DEC": 5.48
  },
  {
    "LONG": -49.78,
    "LAT": -2.09,
    "CIDADE": "Nipoã",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.27,
    "FEB": 5.63,
    "MAR": 5.45,
    "APR": 5.52,
    "MAY": 4.99,
    "JUN": 4.88,
    "JUL": 5.11,
    "AUG": 5.86,
    "SEP": 5.41,
    "OCT": 5.47,
    "NOV": 5.44,
    "DEC": 5.46
  },
  {
    "LONG": -49.58,
    "LAT": -20.88,
    "CIDADE": "Jaci",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.25,
    "FEB": 5.6,
    "MAR": 5.39,
    "APR": 5.51,
    "MAY": 4.97,
    "JUN": 4.89,
    "JUL": 5.14,
    "AUG": 5.88,
    "SEP": 5.41,
    "OCT": 5.48,
    "NOV": 5.45,
    "DEC": 5.47
  },
  {
    "LONG": -49.44,
    "LAT": -2.09,
    "CIDADE": "Bady Bassitt",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.23,
    "FEB": 5.6,
    "MAR": 5.33,
    "APR": 5.46,
    "MAY": 4.99,
    "JUN": 4.88,
    "JUL": 5.14,
    "AUG": 5.84,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.4,
    "DEC": 5.44
  },
  {
    "LONG": -49.27,
    "LAT": -2.09,
    "CIDADE": "Cedral",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.21,
    "FEB": 5.59,
    "MAR": 5.38,
    "APR": 5.49,
    "MAY": 5,
    "JUN": 4.94,
    "JUL": 5.14,
    "AUG": 5.89,
    "SEP": 5.45,
    "OCT": 5.46,
    "NOV": 5.42,
    "DEC": 5.42
  },
  {
    "LONG": -48.81,
    "LAT": -20.88,
    "CIDADE": "Cajobi",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.26,
    "FEB": 5.66,
    "MAR": 5.39,
    "APR": 5.48,
    "MAY": 5.03,
    "JUN": 4.96,
    "JUL": 5.17,
    "AUG": 5.87,
    "SEP": 5.42,
    "OCT": 5.44,
    "NOV": 5.42,
    "DEC": 5.44
  },
  {
    "LONG": -48.64,
    "LAT": -2.09,
    "CIDADE": "Monte Azul Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.32,
    "FEB": 5.74,
    "MAR": 5.38,
    "APR": 5.51,
    "MAY": 5.08,
    "JUN": 4.96,
    "JUL": 5.17,
    "AUG": 5.85,
    "SEP": 5.4,
    "OCT": 5.42,
    "NOV": 5.41,
    "DEC": 5.41
  },
  {
    "LONG": -48.48,
    "LAT": -20.95,
    "CIDADE": "Bebedouro",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.26,
    "FEB": 5.78,
    "MAR": 5.37,
    "APR": 5.44,
    "MAY": 5.1,
    "JUN": 4.92,
    "JUL": 5.18,
    "AUG": 5.83,
    "SEP": 5.39,
    "OCT": 5.44,
    "NOV": 5.32,
    "DEC": 5.4
  },
  {
    "LONG": -48.29,
    "LAT": -20.87,
    "CIDADE": "Viradouro",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.26,
    "FEB": 5.8,
    "MAR": 5.4,
    "APR": 5.49,
    "MAY": 5.11,
    "JUN": 5,
    "JUL": 5.2,
    "AUG": 5.87,
    "SEP": 5.36,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.48
  },
  {
    "LONG": -47.59,
    "LAT": -20.89,
    "CIDADE": "Batatais",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.09,
    "FEB": 5.56,
    "MAR": 5.21,
    "APR": 5.45,
    "MAY": 5.09,
    "JUN": 5.03,
    "JUL": 5.22,
    "AUG": 5.97,
    "SEP": 5.42,
    "OCT": 5.38,
    "NOV": 5.16,
    "DEC": 5.24
  },
  {
    "LONG": -50.34,
    "LAT": -2.08,
    "CIDADE": "Nova Castilho",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.32,
    "FEB": 5.67,
    "MAR": 5.51,
    "APR": 5.54,
    "MAY": 5.01,
    "JUN": 4.86,
    "JUL": 5.06,
    "AUG": 5.83,
    "SEP": 5.33,
    "OCT": 5.49,
    "NOV": 5.47,
    "DEC": 5.54
  },
  {
    "LONG": -50.19,
    "LAT": -2.08,
    "CIDADE": "Gastão Vidigal",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.28,
    "FEB": 5.67,
    "MAR": 5.47,
    "APR": 5.57,
    "MAY": 5.02,
    "JUN": 4.89,
    "JUL": 5.06,
    "AUG": 5.85,
    "SEP": 5.33,
    "OCT": 5.5,
    "NOV": 5.47,
    "DEC": 5.5
  },
  {
    "LONG": -50.09,
    "LAT": "#VALOR!",
    "CIDADE": "Monções",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.28,
    "FEB": 5.62,
    "MAR": 5.42,
    "APR": 5.55,
    "MAY": 5.04,
    "JUN": 4.87,
    "JUL": 5.11,
    "AUG": 5.86,
    "SEP": 5.34,
    "OCT": 5.49,
    "NOV": 5.46,
    "DEC": 5.46
  },
  {
    "LONG": -49.96,
    "LAT": -2.08,
    "CIDADE": "Macaubal",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.27,
    "FEB": 5.61,
    "MAR": 5.44,
    "APR": 5.56,
    "MAY": 5.02,
    "JUN": 4.88,
    "JUL": 5.13,
    "AUG": 5.88,
    "SEP": 5.37,
    "OCT": 5.49,
    "NOV": 5.44,
    "DEC": 5.46
  },
  {
    "LONG": -49.81,
    "LAT": -20.79,
    "CIDADE": "Poloni",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.23,
    "FEB": 5.61,
    "MAR": 5.43,
    "APR": 5.54,
    "MAY": 5.02,
    "JUN": 4.92,
    "JUL": 5.13,
    "AUG": 5.92,
    "SEP": 5.37,
    "OCT": 5.51,
    "NOV": 5.43,
    "DEC": 5.46
  },
  {
    "LONG": -49.71,
    "LAT": -2.08,
    "CIDADE": "Monte Aprazível",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.24,
    "FEB": 5.6,
    "MAR": 5.43,
    "APR": 5.54,
    "MAY": 5.04,
    "JUN": 4.92,
    "JUL": 5.14,
    "AUG": 5.92,
    "SEP": 5.4,
    "OCT": 5.49,
    "NOV": 5.45,
    "DEC": 5.48
  },
  {
    "LONG": -49.63,
    "LAT": -2.08,
    "CIDADE": "Neves Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.26,
    "FEB": 5.6,
    "MAR": 5.41,
    "APR": 5.52,
    "MAY": 5.05,
    "JUN": 4.9,
    "JUL": 5.13,
    "AUG": 5.88,
    "SEP": 5.4,
    "OCT": 5.49,
    "NOV": 5.46,
    "DEC": 5.47
  },
  {
    "LONG": -4.95,
    "LAT": -20.82,
    "CIDADE": "Mirassol",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.29,
    "FEB": 5.59,
    "MAR": 5.37,
    "APR": 5.51,
    "MAY": 5.01,
    "JUN": 4.91,
    "JUL": 5.15,
    "AUG": 5.89,
    "SEP": 5.41,
    "OCT": 5.49,
    "NOV": 5.44,
    "DEC": 5.45
  },
  {
    "LONG": -49.38,
    "LAT": -20.81,
    "CIDADE": "São José do Rio Preto",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.2,
    "FEB": 5.59,
    "MAR": 5.35,
    "APR": 5.46,
    "MAY": 4.99,
    "JUN": 4.93,
    "JUL": 5.15,
    "AUG": 5.91,
    "SEP": 5.45,
    "OCT": 5.49,
    "NOV": 5.4,
    "DEC": 5.43
  },
  {
    "LONG": -49.22,
    "LAT": -2.08,
    "CIDADE": "Guapiaçu",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.23,
    "FEB": 5.59,
    "MAR": 5.39,
    "APR": 5.49,
    "MAY": 5.01,
    "JUN": 4.96,
    "JUL": 5.15,
    "AUG": 5.92,
    "SEP": 5.46,
    "OCT": 5.45,
    "NOV": 5.41,
    "DEC": 5.45
  },
  {
    "LONG": -48.81,
    "LAT": -20.81,
    "CIDADE": "Severínia",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.25,
    "FEB": 5.69,
    "MAR": 5.39,
    "APR": 5.51,
    "MAY": 5.09,
    "JUN": 4.99,
    "JUL": 5.19,
    "AUG": 5.92,
    "SEP": 5.44,
    "OCT": 5.44,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -48.33,
    "LAT": -20.79,
    "CIDADE": "Terra Roxa",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.29,
    "FEB": 5.82,
    "MAR": 5.42,
    "APR": 5.47,
    "MAY": 5.14,
    "JUN": 5.01,
    "JUL": 5.22,
    "AUG": 5.9,
    "SEP": 5.36,
    "OCT": 5.44,
    "NOV": 5.34,
    "DEC": 5.46
  },
  {
    "LONG": -47.84,
    "LAT": -20.77,
    "CIDADE": "Sales Oliveira",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.15,
    "FEB": 5.61,
    "MAR": 5.24,
    "APR": 5.45,
    "MAY": 5.13,
    "JUN": 5,
    "JUL": 5.2,
    "AUG": 5.93,
    "SEP": 5.39,
    "OCT": 5.4,
    "NOV": 5.24,
    "DEC": 5.3
  },
  {
    "LONG": -50.92,
    "LAT": -20.69,
    "CIDADE": "Sud Mennucci",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.32,
    "FEB": 5.71,
    "MAR": 5.57,
    "APR": 5.5,
    "MAY": 4.98,
    "JUN": 4.74,
    "JUL": 4.95,
    "AUG": 5.75,
    "SEP": 5.27,
    "OCT": 5.51,
    "NOV": 5.51,
    "DEC": 5.61
  },
  {
    "LONG": -50.55,
    "LAT": -2.07,
    "CIDADE": "Auriflama",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.36,
    "FEB": 5.67,
    "MAR": 5.56,
    "APR": 5.53,
    "MAY": 5.04,
    "JUN": 4.9,
    "JUL": 5.08,
    "AUG": 5.81,
    "SEP": 5.4,
    "OCT": 5.5,
    "NOV": 5.47,
    "DEC": 5.54
  },
  {
    "LONG": -50.14,
    "LAT": -2.07,
    "CIDADE": "Floreal",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.47,
    "APR": 5.5,
    "MAY": 5.02,
    "JUN": 4.91,
    "JUL": 5.11,
    "AUG": 5.83,
    "SEP": 5.34,
    "OCT": 5.49,
    "NOV": 5.44,
    "DEC": 5.5
  },
  {
    "LONG": -5,
    "LAT": -2.07,
    "CIDADE": "Nhandeara",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.28,
    "FEB": 5.61,
    "MAR": 5.42,
    "APR": 5.52,
    "MAY": 5.01,
    "JUN": 4.9,
    "JUL": 5.12,
    "AUG": 5.81,
    "SEP": 5.33,
    "OCT": 5.48,
    "NOV": 5.42,
    "DEC": 5.46
  },
  {
    "LONG": -49.93,
    "LAT": -20.65,
    "CIDADE": "Sebastianópolis do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.26,
    "FEB": 5.61,
    "MAR": 5.42,
    "APR": 5.53,
    "MAY": 5.03,
    "JUN": 4.91,
    "JUL": 5.16,
    "AUG": 5.87,
    "SEP": 5.36,
    "OCT": 5.5,
    "NOV": 5.43,
    "DEC": 5.45
  },
  {
    "LONG": -49.58,
    "LAT": -2.07,
    "CIDADE": "Bálsamo",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.28,
    "FEB": 5.61,
    "MAR": 5.36,
    "APR": 5.55,
    "MAY": 5.05,
    "JUN": 4.93,
    "JUL": 5.15,
    "AUG": 5.89,
    "SEP": 5.42,
    "OCT": 5.47,
    "NOV": 5.4,
    "DEC": 5.46
  },
  {
    "LONG": -49.38,
    "LAT": -20.66,
    "CIDADE": "Ipiguá",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.22,
    "FEB": 5.61,
    "MAR": 5.37,
    "APR": 5.52,
    "MAY": 5.03,
    "JUN": 4.97,
    "JUL": 5.16,
    "AUG": 5.93,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.43,
    "DEC": 5.44
  },
  {
    "LONG": -4.89,
    "LAT": -20.74,
    "CIDADE": "Olímpia",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.23,
    "FEB": 5.72,
    "MAR": 5.4,
    "APR": 5.49,
    "MAY": 5.13,
    "JUN": 4.99,
    "JUL": 5.17,
    "AUG": 5.93,
    "SEP": 5.4,
    "OCT": 5.45,
    "NOV": 5.38,
    "DEC": 5.43
  },
  {
    "LONG": -48.54,
    "LAT": -20.71,
    "CIDADE": "Colina",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.29,
    "FEB": 5.76,
    "MAR": 5.4,
    "APR": 5.49,
    "MAY": 5.14,
    "JUN": 5.01,
    "JUL": 5.21,
    "AUG": 5.91,
    "SEP": 5.39,
    "OCT": 5.45,
    "NOV": 5.33,
    "DEC": 5.45
  },
  {
    "LONG": -48.41,
    "LAT": -20.69,
    "CIDADE": "Jaborandi",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.26,
    "FEB": 5.79,
    "MAR": 5.39,
    "APR": 5.51,
    "MAY": 5.15,
    "JUN": 5.02,
    "JUL": 5.22,
    "AUG": 5.9,
    "SEP": 5.37,
    "OCT": 5.46,
    "NOV": 5.32,
    "DEC": 5.47
  },
  {
    "LONG": -48.06,
    "LAT": -20.73,
    "CIDADE": "Morro Agudo",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.24,
    "FEB": 5.73,
    "MAR": 5.33,
    "APR": 5.47,
    "MAY": 5.12,
    "JUN": 5.01,
    "JUL": 5.18,
    "AUG": 5.91,
    "SEP": 5.39,
    "OCT": 5.41,
    "NOV": 5.28,
    "DEC": 5.44
  },
  {
    "LONG": -47.89,
    "LAT": -20.72,
    "CIDADE": "Orlândia",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.12,
    "FEB": 5.65,
    "MAR": 5.27,
    "APR": 5.45,
    "MAY": 5.14,
    "JUN": 5,
    "JUL": 5.22,
    "AUG": 5.98,
    "SEP": 5.37,
    "OCT": 5.4,
    "NOV": 5.24,
    "DEC": 5.3
  },
  {
    "LONG": -47.74,
    "LAT": -20.73,
    "CIDADE": "Nuporanga",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.13,
    "FEB": 5.66,
    "MAR": 5.21,
    "APR": 5.55,
    "MAY": 5.14,
    "JUN": 5.07,
    "JUL": 5.2,
    "AUG": 5.96,
    "SEP": 5.4,
    "OCT": 5.4,
    "NOV": 5.23,
    "DEC": 5.3
  },
  {
    "LONG": -51.51,
    "LAT": -20.64,
    "CIDADE": "Itapura",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.31,
    "FEB": 5.69,
    "MAR": 5.65,
    "APR": 5.51,
    "MAY": 4.98,
    "JUN": 4.77,
    "JUL": 4.94,
    "AUG": 5.71,
    "SEP": 5.21,
    "OCT": 5.41,
    "NOV": 5.46,
    "DEC": 5.58
  },
  {
    "LONG": -51.11,
    "LAT": -20.64,
    "CIDADE": "Pereira Barreto",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.37,
    "FEB": 5.73,
    "MAR": 5.58,
    "APR": 5.54,
    "MAY": 5,
    "JUN": 4.71,
    "JUL": 4.94,
    "AUG": 5.75,
    "SEP": 5.22,
    "OCT": 5.48,
    "NOV": 5.52,
    "DEC": 5.62
  },
  {
    "LONG": -50.66,
    "LAT": "#VALOR!",
    "CIDADE": "Guzolândia",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.29,
    "FEB": 5.74,
    "MAR": 5.52,
    "APR": 5.54,
    "MAY": 5.04,
    "JUN": 4.87,
    "JUL": 5.07,
    "AUG": 5.87,
    "SEP": 5.39,
    "OCT": 5.5,
    "NOV": 5.47,
    "DEC": 5.57
  },
  {
    "LONG": -50.36,
    "LAT": -2.06,
    "CIDADE": "General Salgado",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.29,
    "FEB": 5.67,
    "MAR": 5.49,
    "APR": 5.54,
    "MAY": 5.04,
    "JUN": 4.91,
    "JUL": 5.1,
    "AUG": 5.86,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.45,
    "DEC": 5.52
  },
  {
    "LONG": -50.23,
    "LAT": -2.06,
    "CIDADE": "Magda",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.49,
    "APR": 5.51,
    "MAY": 5.02,
    "JUN": 4.9,
    "JUL": 5.1,
    "AUG": 5.83,
    "SEP": 5.4,
    "OCT": 5.46,
    "NOV": 5.43,
    "DEC": 5.5
  },
  {
    "LONG": -49.66,
    "LAT": -20.62,
    "CIDADE": "Tanabi",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.23,
    "FEB": 5.6,
    "MAR": 5.38,
    "APR": 5.57,
    "MAY": 5.1,
    "JUN": 4.92,
    "JUL": 5.18,
    "AUG": 5.91,
    "SEP": 5.44,
    "OCT": 5.47,
    "NOV": 5.38,
    "DEC": 5.44
  },
  {
    "LONG": -49.46,
    "LAT": -2.06,
    "CIDADE": "Mirassolândia",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.21,
    "FEB": 5.62,
    "MAR": 5.36,
    "APR": 5.58,
    "MAY": 5.07,
    "JUN": 4.98,
    "JUL": 5.17,
    "AUG": 5.92,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -49.3,
    "LAT": -20.61,
    "CIDADE": "Onda Verde",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.22,
    "FEB": 5.65,
    "MAR": 5.38,
    "APR": 5.57,
    "MAY": 5.05,
    "JUN": 4.97,
    "JUL": 5.18,
    "AUG": 5.87,
    "SEP": 5.42,
    "OCT": 5.45,
    "NOV": 5.4,
    "DEC": 5.41
  },
  {
    "LONG": -48.57,
    "LAT": -20.55,
    "CIDADE": "Barretos",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.41,
    "APR": 5.51,
    "MAY": 5.14,
    "JUN": 5.01,
    "JUL": 5.21,
    "AUG": 5.93,
    "SEP": 5.41,
    "OCT": 5.45,
    "NOV": 5.3,
    "DEC": 5.46
  },
  {
    "LONG": -47.86,
    "LAT": -20.58,
    "CIDADE": "São Joaquim da Barra",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.19,
    "FEB": 5.7,
    "MAR": 5.3,
    "APR": 5.49,
    "MAY": 5.14,
    "JUN": 5.03,
    "JUL": 5.28,
    "AUG": 6,
    "SEP": 5.44,
    "OCT": 5.42,
    "NOV": 5.23,
    "DEC": 5.36
  },
  {
    "LONG": -47.64,
    "LAT": -2.06,
    "CIDADE": "São José da Bela Vista",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.11,
    "FEB": 5.61,
    "MAR": 5.19,
    "APR": 5.51,
    "MAY": 5.15,
    "JUN": 5.05,
    "JUL": 5.24,
    "AUG": 6.07,
    "SEP": 5.43,
    "OCT": 5.37,
    "NOV": 5.18,
    "DEC": 5.26
  },
  {
    "LONG": -47.48,
    "LAT": -20.61,
    "CIDADE": "Restinga",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.01,
    "FEB": 5.5,
    "MAR": 5.15,
    "APR": 5.45,
    "MAY": 5.15,
    "JUN": 5.11,
    "JUL": 5.29,
    "AUG": 6.08,
    "SEP": 5.47,
    "OCT": 5.34,
    "NOV": 5.09,
    "DEC": 5.13
  },
  {
    "LONG": -47.22,
    "LAT": -20.64,
    "CIDADE": "Itirapuã",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.01,
    "FEB": 5.53,
    "MAR": 5.13,
    "APR": 5.45,
    "MAY": 5.15,
    "JUN": 5.11,
    "JUL": 5.32,
    "AUG": 6.1,
    "SEP": 5.47,
    "OCT": 5.34,
    "NOV": 5.09,
    "DEC": 5.07
  },
  {
    "LONG": -47.28,
    "LAT": -20.64,
    "CIDADE": "Patrocínio Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.01,
    "FEB": 5.53,
    "MAR": 5.13,
    "APR": 5.45,
    "MAY": 5.15,
    "JUN": 5.11,
    "JUL": 5.32,
    "AUG": 6.1,
    "SEP": 5.47,
    "OCT": 5.34,
    "NOV": 5.09,
    "DEC": 5.07
  },
  {
    "LONG": -51.03,
    "LAT": -20.5,
    "CIDADE": "Suzanápolis",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.36,
    "FEB": 5.77,
    "MAR": 5.59,
    "APR": 5.55,
    "MAY": 5.06,
    "JUN": 4.83,
    "JUL": 5.01,
    "AUG": 5.84,
    "SEP": 5.27,
    "OCT": 5.5,
    "NOV": 5.51,
    "DEC": 5.6
  },
  {
    "LONG": -50.61,
    "LAT": -20.46,
    "CIDADE": "Dirce Reis",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.26,
    "FEB": 5.72,
    "MAR": 5.51,
    "APR": 5.47,
    "MAY": 5.04,
    "JUN": 4.91,
    "JUL": 5.11,
    "AUG": 5.89,
    "SEP": 5.41,
    "OCT": 5.52,
    "NOV": 5.46,
    "DEC": 5.6
  },
  {
    "LONG": -50.36,
    "LAT": -20.51,
    "CIDADE": "São João de Iracema",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.27,
    "FEB": 5.66,
    "MAR": 5.47,
    "APR": 5.53,
    "MAY": 5.05,
    "JUN": 4.93,
    "JUL": 5.13,
    "AUG": 5.85,
    "SEP": 5.44,
    "OCT": 5.48,
    "NOV": 5.42,
    "DEC": 5.51
  },
  {
    "LONG": -49.78,
    "LAT": -2.05,
    "CIDADE": "Cosmorama",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.29,
    "FEB": 5.62,
    "MAR": 5.44,
    "APR": 5.57,
    "MAY": 5.09,
    "JUN": 4.96,
    "JUL": 5.17,
    "AUG": 5.9,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.43,
    "DEC": 5.45
  },
  {
    "LONG": -49.31,
    "LAT": -20.53,
    "CIDADE": "Nova Granada",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.21,
    "FEB": 5.62,
    "MAR": 5.35,
    "APR": 5.58,
    "MAY": 5.1,
    "JUN": 4.95,
    "JUL": 5.2,
    "AUG": 5.92,
    "SEP": 5.43,
    "OCT": 5.46,
    "NOV": 5.42,
    "DEC": 5.42
  },
  {
    "LONG": -49.06,
    "LAT": -2.05,
    "CIDADE": "Altair",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.25,
    "FEB": 5.76,
    "MAR": 5.38,
    "APR": 5.57,
    "MAY": 5.12,
    "JUN": 4.96,
    "JUL": 5.17,
    "AUG": 5.95,
    "SEP": 5.42,
    "OCT": 5.44,
    "NOV": 5.34,
    "DEC": 5.48
  },
  {
    "LONG": -48.94,
    "LAT": -2.05,
    "CIDADE": "Guaraci",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.26,
    "FEB": 5.84,
    "MAR": 5.44,
    "APR": 5.57,
    "MAY": 5.13,
    "JUN": 4.97,
    "JUL": 5.18,
    "AUG": 5.94,
    "SEP": 5.44,
    "OCT": 5.49,
    "NOV": 5.38,
    "DEC": 5.5
  },
  {
    "LONG": -47.59,
    "LAT": -20.46,
    "CIDADE": "Ribeirão Corrente",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.05,
    "FEB": 5.54,
    "MAR": 5.15,
    "APR": 5.49,
    "MAY": 5.18,
    "JUN": 5.15,
    "JUL": 5.33,
    "AUG": 6.11,
    "SEP": 5.47,
    "OCT": 5.34,
    "NOV": 5.17,
    "DEC": 5.23
  },
  {
    "LONG": -47.4,
    "LAT": -20.54,
    "CIDADE": "Franca",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.04,
    "FEB": 5.53,
    "MAR": 5.17,
    "APR": 5.47,
    "MAY": 5.18,
    "JUN": 5.16,
    "JUL": 5.31,
    "AUG": 6.08,
    "SEP": 5.48,
    "OCT": 5.36,
    "NOV": 5.12,
    "DEC": 5.18
  },
  {
    "LONG": -5.13,
    "LAT": -20.43,
    "CIDADE": "Ilha Solteira",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.29,
    "FEB": 5.75,
    "MAR": 5.67,
    "APR": 5.59,
    "MAY": 5.05,
    "JUN": 4.82,
    "JUL": 4.97,
    "AUG": 5.78,
    "SEP": 5.21,
    "OCT": 5.46,
    "NOV": 5.47,
    "DEC": 5.62
  },
  {
    "LONG": -50.95,
    "LAT": -2.04,
    "CIDADE": "Nova Canaã Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.31,
    "FEB": 5.76,
    "MAR": 5.59,
    "APR": 5.6,
    "MAY": 5.1,
    "JUN": 4.83,
    "JUL": 5.07,
    "AUG": 5.87,
    "SEP": 5.33,
    "OCT": 5.54,
    "NOV": 5.52,
    "DEC": 5.65
  },
  {
    "LONG": -50.82,
    "LAT": -2.04,
    "CIDADE": "Marinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.7,
    "MAR": 5.53,
    "APR": 5.5,
    "MAY": 5.08,
    "JUN": 4.89,
    "JUL": 5.08,
    "AUG": 5.88,
    "SEP": 5.39,
    "OCT": 5.55,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -50.88,
    "LAT": -20.45,
    "CIDADE": "Aparecida d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.7,
    "MAR": 5.53,
    "APR": 5.5,
    "MAY": 5.08,
    "JUN": 4.89,
    "JUL": 5.08,
    "AUG": 5.88,
    "SEP": 5.39,
    "OCT": 5.55,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -50.7,
    "LAT": -20.35,
    "CIDADE": "São Francisco",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.69,
    "MAR": 5.52,
    "APR": 5.47,
    "MAY": 5.08,
    "JUN": 4.94,
    "JUL": 5.11,
    "AUG": 5.9,
    "SEP": 5.43,
    "OCT": 5.56,
    "NOV": 5.48,
    "DEC": 5.6
  },
  {
    "LONG": -50.76,
    "LAT": -20.42,
    "CIDADE": "Palmeira d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.69,
    "MAR": 5.52,
    "APR": 5.47,
    "MAY": 5.08,
    "JUN": 4.94,
    "JUL": 5.11,
    "AUG": 5.9,
    "SEP": 5.43,
    "OCT": 5.56,
    "NOV": 5.48,
    "DEC": 5.6
  },
  {
    "LONG": -50.52,
    "LAT": -2.04,
    "CIDADE": "Pontalinda",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.25,
    "FEB": 5.64,
    "MAR": 5.48,
    "APR": 5.51,
    "MAY": 5.05,
    "JUN": 4.93,
    "JUL": 5.09,
    "AUG": 5.86,
    "SEP": 5.44,
    "OCT": 5.52,
    "NOV": 5.47,
    "DEC": 5.57
  },
  {
    "LONG": -50.38,
    "LAT": -20.39,
    "CIDADE": "São João das Duas Pontes",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.3,
    "FEB": 5.68,
    "MAR": 5.43,
    "APR": 5.51,
    "MAY": 5.07,
    "JUN": 4.95,
    "JUL": 5.12,
    "AUG": 5.89,
    "SEP": 5.45,
    "OCT": 5.49,
    "NOV": 5.46,
    "DEC": 5.54
  },
  {
    "LONG": -50.09,
    "LAT": -20.42,
    "CIDADE": "Valentim Gentil",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.44,
    "APR": 5.54,
    "MAY": 5.06,
    "JUN": 4.91,
    "JUL": 5.18,
    "AUG": 5.86,
    "SEP": 5.41,
    "OCT": 5.46,
    "NOV": 5.42,
    "DEC": 5.48
  },
  {
    "LONG": -49.98,
    "LAT": -20.42,
    "CIDADE": "Votuporanga",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.32,
    "FEB": 5.64,
    "MAR": 5.45,
    "APR": 5.55,
    "MAY": 5.08,
    "JUN": 4.96,
    "JUL": 5.19,
    "AUG": 5.87,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.45,
    "DEC": 5.47
  },
  {
    "LONG": -49.43,
    "LAT": -20.39,
    "CIDADE": "Palestina",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.18,
    "FEB": 5.58,
    "MAR": 5.37,
    "APR": 5.65,
    "MAY": 5.1,
    "JUN": 4.94,
    "JUL": 5.21,
    "AUG": 5.95,
    "SEP": 5.41,
    "OCT": 5.45,
    "NOV": 5.38,
    "DEC": 5.44
  },
  {
    "LONG": -48.01,
    "LAT": -20.44,
    "CIDADE": "Ipuã",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.28,
    "FEB": 5.8,
    "MAR": 5.32,
    "APR": 5.61,
    "MAY": 5.21,
    "JUN": 5.08,
    "JUL": 5.31,
    "AUG": 6.03,
    "SEP": 5.42,
    "OCT": 5.45,
    "NOV": 5.27,
    "DEC": 5.43
  },
  {
    "LONG": -4.78,
    "LAT": -20.43,
    "CIDADE": "Guará",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.23,
    "FEB": 5.77,
    "MAR": 5.33,
    "APR": 5.58,
    "MAY": 5.21,
    "JUN": 5.07,
    "JUL": 5.26,
    "AUG": 6.02,
    "SEP": 5.47,
    "OCT": 5.42,
    "NOV": 5.26,
    "DEC": 5.39
  },
  {
    "LONG": -47.42,
    "LAT": -2.04,
    "CIDADE": "Cristais Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.04,
    "FEB": 5.56,
    "MAR": 5.18,
    "APR": 5.53,
    "MAY": 5.19,
    "JUN": 5.19,
    "JUL": 5.39,
    "AUG": 6.09,
    "SEP": 5.5,
    "OCT": 5.36,
    "NOV": 5.1,
    "DEC": 5.19
  },
  {
    "LONG": -50.8,
    "LAT": -2.03,
    "CIDADE": "Santana da Ponte Pensa",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.25,
    "FEB": 5.7,
    "MAR": 5.53,
    "APR": 5.52,
    "MAY": 5.1,
    "JUN": 4.95,
    "JUL": 5.13,
    "AUG": 5.9,
    "SEP": 5.4,
    "OCT": 5.53,
    "NOV": 5.45,
    "DEC": 5.57
  },
  {
    "LONG": -50.55,
    "LAT": -20.27,
    "CIDADE": "Jales",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.25,
    "FEB": 5.63,
    "MAR": 5.49,
    "APR": 5.51,
    "MAY": 5.08,
    "JUN": 4.95,
    "JUL": 5.13,
    "AUG": 5.9,
    "SEP": 5.45,
    "OCT": 5.53,
    "NOV": 5.45,
    "DEC": 5.55
  },
  {
    "LONG": -50.4,
    "LAT": -2.03,
    "CIDADE": "Estrela d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.26,
    "FEB": 5.64,
    "MAR": 5.46,
    "APR": 5.54,
    "MAY": 5.1,
    "JUN": 4.97,
    "JUL": 5.15,
    "AUG": 5.92,
    "SEP": 5.45,
    "OCT": 5.5,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -50.25,
    "LAT": -2.03,
    "CIDADE": "Fernandópolis",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.29,
    "FEB": 5.68,
    "MAR": 5.42,
    "APR": 5.52,
    "MAY": 5.09,
    "JUN": 4.94,
    "JUL": 5.15,
    "AUG": 5.87,
    "SEP": 5.47,
    "OCT": 5.49,
    "NOV": 5.43,
    "DEC": 5.54
  },
  {
    "LONG": -50.18,
    "LAT": -20.35,
    "CIDADE": "Meridiano",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.25,
    "FEB": 5.65,
    "MAR": 5.42,
    "APR": 5.56,
    "MAY": 5.1,
    "JUN": 4.92,
    "JUL": 5.19,
    "AUG": 5.89,
    "SEP": 5.47,
    "OCT": 5.48,
    "NOV": 5.41,
    "DEC": 5.52
  },
  {
    "LONG": -50.02,
    "LAT": -20.3,
    "CIDADE": "Parisi",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.28,
    "FEB": 5.66,
    "MAR": 5.46,
    "APR": 5.55,
    "MAY": 5.12,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 5.89,
    "SEP": 5.45,
    "OCT": 5.49,
    "NOV": 5.41,
    "DEC": 5.48
  },
  {
    "LONG": -49.91,
    "LAT": -2.03,
    "CIDADE": "Álvares Florence",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.67,
    "MAR": 5.48,
    "APR": 5.55,
    "MAY": 5.12,
    "JUN": 4.94,
    "JUL": 5.21,
    "AUG": 5.89,
    "SEP": 5.45,
    "OCT": 5.51,
    "NOV": 5.43,
    "DEC": 5.48
  },
  {
    "LONG": -49.73,
    "LAT": -2.03,
    "CIDADE": "Américo de Campos",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.29,
    "FEB": 5.62,
    "MAR": 5.44,
    "APR": 5.57,
    "MAY": 5.13,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 5.91,
    "SEP": 5.43,
    "OCT": 5.51,
    "NOV": 5.39,
    "DEC": 5.47
  },
  {
    "LONG": -49.19,
    "LAT": -2.03,
    "CIDADE": "Icém",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.24,
    "FEB": 5.74,
    "MAR": 5.4,
    "APR": 5.61,
    "MAY": 5.09,
    "JUN": 4.87,
    "JUL": 5.17,
    "AUG": 5.81,
    "SEP": 5.34,
    "OCT": 5.43,
    "NOV": 5.33,
    "DEC": 5.49
  },
  {
    "LONG": -48.31,
    "LAT": -20.32,
    "CIDADE": "Guaíra",
    "UF": "SÃO PAULO",
    "anual": 5.45,
    "JAN": 5.32,
    "FEB": 5.79,
    "MAR": 5.38,
    "APR": 5.61,
    "MAY": 5.24,
    "JUN": 5.06,
    "JUL": 5.28,
    "AUG": 5.92,
    "SEP": 5.47,
    "OCT": 5.46,
    "NOV": 5.3,
    "DEC": 5.51
  },
  {
    "LONG": -47.79,
    "LAT": -20.34,
    "CIDADE": "Ituverava",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.15,
    "FEB": 5.7,
    "MAR": 5.35,
    "APR": 5.58,
    "MAY": 5.2,
    "JUN": 5.11,
    "JUL": 5.34,
    "AUG": 6.08,
    "SEP": 5.48,
    "OCT": 5.42,
    "NOV": 5.24,
    "DEC": 5.39
  },
  {
    "LONG": -47.59,
    "LAT": -2.03,
    "CIDADE": "Jeriquara",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.06,
    "FEB": 5.65,
    "MAR": 5.28,
    "APR": 5.56,
    "MAY": 5.24,
    "JUN": 5.17,
    "JUL": 5.39,
    "AUG": 6.08,
    "SEP": 5.51,
    "OCT": 5.36,
    "NOV": 5.16,
    "DEC": 5.28
  },
  {
    "LONG": -47.48,
    "LAT": -20.25,
    "CIDADE": "Pedregulho",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.08,
    "FEB": 5.6,
    "MAR": 5.23,
    "APR": 5.54,
    "MAY": 5.2,
    "JUN": 5.17,
    "JUL": 5.41,
    "AUG": 6.11,
    "SEP": 5.51,
    "OCT": 5.34,
    "NOV": 5.13,
    "DEC": 5.25
  },
  {
    "LONG": -51.01,
    "LAT": -20.18,
    "CIDADE": "Rubinéia",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.23,
    "FEB": 5.72,
    "MAR": 5.66,
    "APR": 5.61,
    "MAY": 5.09,
    "JUN": 4.86,
    "JUL": 5.03,
    "AUG": 5.79,
    "SEP": 5.23,
    "OCT": 5.47,
    "NOV": 5.5,
    "DEC": 5.61
  },
  {
    "LONG": -50.93,
    "LAT": -20.21,
    "CIDADE": "Santa Fé do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.26,
    "FEB": 5.72,
    "MAR": 5.65,
    "APR": 5.61,
    "MAY": 5.07,
    "JUN": 4.85,
    "JUL": 5.05,
    "AUG": 5.75,
    "SEP": 5.29,
    "OCT": 5.51,
    "NOV": 5.52,
    "DEC": 5.64
  },
  {
    "LONG": -50.89,
    "LAT": -2.02,
    "CIDADE": "Três Fronteiras",
    "UF": "SÃO PAULO",
    "anual": 5.44,
    "JAN": 5.24,
    "FEB": 5.71,
    "MAR": 5.57,
    "APR": 5.57,
    "MAY": 5.11,
    "JUN": 4.97,
    "JUL": 5.14,
    "AUG": 5.93,
    "SEP": 5.39,
    "OCT": 5.56,
    "NOV": 5.48,
    "DEC": 5.57
  },
  {
    "LONG": -50.73,
    "LAT": -2.02,
    "CIDADE": "Aspásia",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.23,
    "FEB": 5.71,
    "MAR": 5.54,
    "APR": 5.52,
    "MAY": 5.11,
    "JUN": 5,
    "JUL": 5.15,
    "AUG": 5.91,
    "SEP": 5.38,
    "OCT": 5.54,
    "NOV": 5.44,
    "DEC": 5.55
  },
  {
    "LONG": -50.65,
    "LAT": -20.25,
    "CIDADE": "Urânia",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.24,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 5.5,
    "MAY": 5.12,
    "JUN": 4.98,
    "JUL": 5.18,
    "AUG": 5.93,
    "SEP": 5.4,
    "OCT": 5.53,
    "NOV": 5.42,
    "DEC": 5.55
  },
  {
    "LONG": -50.69,
    "LAT": -2.02,
    "CIDADE": "Santa Salete",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.24,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 5.5,
    "MAY": 5.12,
    "JUN": 4.98,
    "JUL": 5.18,
    "AUG": 5.93,
    "SEP": 5.4,
    "OCT": 5.53,
    "NOV": 5.42,
    "DEC": 5.55
  },
  {
    "LONG": -50.48,
    "LAT": -2.02,
    "CIDADE": "Vitória Brasil",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.25,
    "FEB": 5.62,
    "MAR": 5.49,
    "APR": 5.52,
    "MAY": 5.11,
    "JUN": 4.95,
    "JUL": 5.17,
    "AUG": 5.94,
    "SEP": 5.43,
    "OCT": 5.5,
    "NOV": 5.4,
    "DEC": 5.5
  },
  {
    "LONG": -50.11,
    "LAT": -2.02,
    "CIDADE": "Pedranópolis",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.27,
    "FEB": 5.7,
    "MAR": 5.46,
    "APR": 5.57,
    "MAY": 5.14,
    "JUN": 4.97,
    "JUL": 5.2,
    "AUG": 5.91,
    "SEP": 5.46,
    "OCT": 5.53,
    "NOV": 5.41,
    "DEC": 5.51
  },
  {
    "LONG": -4.97,
    "LAT": -20.18,
    "CIDADE": "Pontes Gestal",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.26,
    "FEB": 5.64,
    "MAR": 5.46,
    "APR": 5.55,
    "MAY": 5.11,
    "JUN": 4.93,
    "JUL": 5.19,
    "AUG": 5.92,
    "SEP": 5.45,
    "OCT": 5.5,
    "NOV": 5.37,
    "DEC": 5.5
  },
  {
    "LONG": -49.35,
    "LAT": -2.02,
    "CIDADE": "Orindiúva",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.21,
    "FEB": 5.65,
    "MAR": 5.36,
    "APR": 5.63,
    "MAY": 5.11,
    "JUN": 4.98,
    "JUL": 5.24,
    "AUG": 5.95,
    "SEP": 5.41,
    "OCT": 5.42,
    "NOV": 5.34,
    "DEC": 5.5
  },
  {
    "LONG": -48.69,
    "LAT": -20.18,
    "CIDADE": "Colômbia",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.3,
    "FEB": 5.82,
    "MAR": 5.41,
    "APR": 5.65,
    "MAY": 5.17,
    "JUN": 4.98,
    "JUL": 5.25,
    "AUG": 5.94,
    "SEP": 5.41,
    "OCT": 5.42,
    "NOV": 5.27,
    "DEC": 5.47
  },
  {
    "LONG": -48.03,
    "LAT": -20.18,
    "CIDADE": "Miguelópolis",
    "UF": "SÃO PAULO",
    "anual": 5.44,
    "JAN": 5.23,
    "FEB": 5.83,
    "MAR": 5.38,
    "APR": 5.66,
    "MAY": 5.25,
    "JUN": 5.04,
    "JUL": 5.3,
    "AUG": 5.96,
    "SEP": 5.41,
    "OCT": 5.48,
    "NOV": 5.3,
    "DEC": 5.43
  },
  {
    "LONG": "#VALOR!",
    "LAT": -20.19,
    "CIDADE": "Buritizal",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.13,
    "FEB": 5.72,
    "MAR": 5.36,
    "APR": 5.65,
    "MAY": 5.21,
    "JUN": 5.14,
    "JUL": 5.36,
    "AUG": 6.07,
    "SEP": 5.48,
    "OCT": 5.44,
    "NOV": 5.24,
    "DEC": 5.36
  },
  {
    "LONG": -50.93,
    "LAT": -2.01,
    "CIDADE": "Santa Clara d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.25,
    "FEB": 5.73,
    "MAR": 5.66,
    "APR": 5.59,
    "MAY": 5.05,
    "JUN": 4.83,
    "JUL": 5.05,
    "AUG": 5.76,
    "SEP": 5.26,
    "OCT": 5.49,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -50.83,
    "LAT": -2.01,
    "CIDADE": "Santa Rita d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.6,
    "APR": 5.55,
    "MAY": 5.11,
    "JUN": 4.96,
    "JUL": 5.13,
    "AUG": 5.86,
    "SEP": 5.36,
    "OCT": 5.54,
    "NOV": 5.48,
    "DEC": 5.58
  },
  {
    "LONG": -50.51,
    "LAT": -2.01,
    "CIDADE": "Dolcinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.24,
    "FEB": 5.62,
    "MAR": 5.5,
    "APR": 5.51,
    "MAY": 5.13,
    "JUN": 5,
    "JUL": 5.16,
    "AUG": 5.95,
    "SEP": 5.42,
    "OCT": 5.52,
    "NOV": 5.39,
    "DEC": 5.53
  },
  {
    "LONG": -5.06,
    "LAT": -20.11,
    "CIDADE": "Paranapuã",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.24,
    "FEB": 5.62,
    "MAR": 5.5,
    "APR": 5.51,
    "MAY": 5.13,
    "JUN": 5,
    "JUL": 5.16,
    "AUG": 5.95,
    "SEP": 5.42,
    "OCT": 5.52,
    "NOV": 5.39,
    "DEC": 5.53
  },
  {
    "LONG": -50.48,
    "LAT": -20.05,
    "CIDADE": "Turmalina",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.24,
    "FEB": 5.62,
    "MAR": 5.48,
    "APR": 5.53,
    "MAY": 5.12,
    "JUN": 4.97,
    "JUL": 5.15,
    "AUG": 5.92,
    "SEP": 5.43,
    "OCT": 5.5,
    "NOV": 5.38,
    "DEC": 5.51
  },
  {
    "LONG": -50.34,
    "LAT": -20.08,
    "CIDADE": "Guarani d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.24,
    "FEB": 5.71,
    "MAR": 5.48,
    "APR": 5.57,
    "MAY": 5.13,
    "JUN": 4.98,
    "JUL": 5.16,
    "AUG": 5.9,
    "SEP": 5.46,
    "OCT": 5.52,
    "NOV": 5.38,
    "DEC": 5.5
  },
  {
    "LONG": -50.19,
    "LAT": -2.01,
    "CIDADE": "Macedônia",
    "UF": "SÃO PAULO",
    "anual": 5.45,
    "JAN": 5.3,
    "FEB": 5.74,
    "MAR": 5.5,
    "APR": 5.56,
    "MAY": 5.16,
    "JUN": 5,
    "JUL": 5.24,
    "AUG": 5.9,
    "SEP": 5.46,
    "OCT": 5.55,
    "NOV": 5.42,
    "DEC": 5.52
  },
  {
    "LONG": -49.91,
    "LAT": -2.01,
    "CIDADE": "Cardoso",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.3,
    "FEB": 5.71,
    "MAR": 5.53,
    "APR": 5.55,
    "MAY": 5.13,
    "JUN": 4.91,
    "JUL": 5.18,
    "AUG": 5.84,
    "SEP": 5.45,
    "OCT": 5.56,
    "NOV": 5.42,
    "DEC": 5.55
  },
  {
    "LONG": -47.79,
    "LAT": -20.09,
    "CIDADE": "Aramina",
    "UF": "SÃO PAULO",
    "anual": 5.44,
    "JAN": 5.13,
    "FEB": 5.71,
    "MAR": 5.34,
    "APR": 5.68,
    "MAY": 5.23,
    "JUN": 5.16,
    "JUL": 5.38,
    "AUG": 6.1,
    "SEP": 5.53,
    "OCT": 5.48,
    "NOV": 5.2,
    "DEC": 5.31
  },
  {
    "LONG": -47.43,
    "LAT": -20.08,
    "CIDADE": "Rifaina",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.14,
    "FEB": 5.72,
    "MAR": 5.3,
    "APR": 5.62,
    "MAY": 5.27,
    "JUN": 5.2,
    "JUL": 5.43,
    "AUG": 6.11,
    "SEP": 5.5,
    "OCT": 5.49,
    "NOV": 5.15,
    "DEC": 5.24
  },
  {
    "LONG": -50.73,
    "LAT": -20.03,
    "CIDADE": "Santa Albertina",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.23,
    "FEB": 5.71,
    "MAR": 5.58,
    "APR": 5.53,
    "MAY": 5.1,
    "JUN": 4.97,
    "JUL": 5.15,
    "AUG": 5.85,
    "SEP": 5.32,
    "OCT": 5.52,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -5.06,
    "LAT": -19.97,
    "CIDADE": "Mesópolis",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.26,
    "FEB": 5.65,
    "MAR": 5.55,
    "APR": 5.51,
    "MAY": 5.11,
    "JUN": 4.96,
    "JUL": 5.11,
    "AUG": 5.86,
    "SEP": 5.35,
    "OCT": 5.53,
    "NOV": 5.38,
    "DEC": 5.52
  },
  {
    "LONG": -50.37,
    "LAT": -20,
    "CIDADE": "Ouroeste",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.22,
    "FEB": 5.7,
    "MAR": 5.47,
    "APR": 5.56,
    "MAY": 5.14,
    "JUN": 4.95,
    "JUL": 5.16,
    "AUG": 5.87,
    "SEP": 5.43,
    "OCT": 5.54,
    "NOV": 5.41,
    "DEC": 5.47
  },
  {
    "LONG": -50.29,
    "LAT": -19.98,
    "CIDADE": "Indiaporã",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.24,
    "FEB": 5.74,
    "MAR": 5.51,
    "APR": 5.58,
    "MAY": 5.15,
    "JUN": 4.94,
    "JUL": 5.17,
    "AUG": 5.84,
    "SEP": 5.42,
    "OCT": 5.54,
    "NOV": 5.43,
    "DEC": 5.5
  },
  {
    "LONG": -50.14,
    "LAT": -2,
    "CIDADE": "Mira Estrela",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.28,
    "FEB": 5.78,
    "MAR": 5.53,
    "APR": 5.57,
    "MAY": 5.15,
    "JUN": 4.91,
    "JUL": 5.17,
    "AUG": 5.82,
    "SEP": 5.41,
    "OCT": 5.57,
    "NOV": 5.47,
    "DEC": 5.52
  },
  {
    "LONG": -49.68,
    "LAT": -19.98,
    "CIDADE": "Riolândia",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.21,
    "FEB": 5.68,
    "MAR": 5.43,
    "APR": 5.59,
    "MAY": 5.08,
    "JUN": 4.93,
    "JUL": 5.19,
    "AUG": 5.88,
    "SEP": 5.37,
    "OCT": 5.47,
    "NOV": 5.35,
    "DEC": 5.48
  },
  {
    "LONG": -49.4,
    "LAT": -20.03,
    "CIDADE": "Paulo de Faria",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.19,
    "FEB": 5.68,
    "MAR": 5.4,
    "APR": 5.62,
    "MAY": 5.12,
    "JUN": 4.92,
    "JUL": 5.18,
    "AUG": 5.9,
    "SEP": 5.36,
    "OCT": 5.43,
    "NOV": 5.33,
    "DEC": 5.47
  },
  {
    "LONG": -4.77,
    "LAT": -20.04,
    "CIDADE": "Igarapava",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.05,
    "FEB": 5.62,
    "MAR": 5.25,
    "APR": 5.64,
    "MAY": 5.24,
    "JUN": 5.15,
    "JUL": 5.4,
    "AUG": 6.1,
    "SEP": 5.49,
    "OCT": 5.43,
    "NOV": 5.11,
    "DEC": 5.19
  },
  {
    "LONG": -50.54,
    "LAT": -19.95,
    "CIDADE": "Populina",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.27,
    "FEB": 5.67,
    "MAR": 5.52,
    "APR": 5.52,
    "MAY": 5.11,
    "JUN": 4.95,
    "JUL": 5.11,
    "AUG": 5.85,
    "SEP": 5.34,
    "OCT": 5.53,
    "NOV": 5.34,
    "DEC": 5.47
  },
  {
    "LONG": -37.76,
    "LAT": -11.46,
    "CIDADE": "Cristinápolis",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.8,
    "FEB": 5.83,
    "MAR": 5.87,
    "APR": 5.15,
    "MAY": 4.5,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 4.82,
    "SEP": 5.41,
    "OCT": 5.5,
    "NOV": 5.7,
    "DEC": 5.79
  },
  {
    "LONG": -37.51,
    "LAT": -11.52,
    "CIDADE": "Indiaroba",
    "UF": "SERGIPE",
    "anual": 5.33,
    "JAN": 5.87,
    "FEB": 5.9,
    "MAR": 6.02,
    "APR": 5.22,
    "MAY": 4.59,
    "JUN": 4.38,
    "JUL": 4.48,
    "AUG": 4.84,
    "SEP": 5.43,
    "OCT": 5.57,
    "NOV": 5.79,
    "DEC": 5.9
  },
  {
    "LONG": -37.84,
    "LAT": -11.37,
    "CIDADE": "Tomar do Geru",
    "UF": "SERGIPE",
    "anual": 5.23,
    "JAN": 5.76,
    "FEB": 5.77,
    "MAR": 5.8,
    "APR": 5.1,
    "MAY": 4.49,
    "JUN": 4.33,
    "JUL": 4.44,
    "AUG": 4.81,
    "SEP": 5.37,
    "OCT": 5.49,
    "NOV": 5.65,
    "DEC": 5.74
  },
  {
    "LONG": -37.66,
    "LAT": -11.38,
    "CIDADE": "Umbaúba",
    "UF": "SERGIPE",
    "anual": 5.29,
    "JAN": 5.82,
    "FEB": 5.84,
    "MAR": 5.98,
    "APR": 5.17,
    "MAY": 4.56,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 4.81,
    "SEP": 5.34,
    "OCT": 5.51,
    "NOV": 5.73,
    "DEC": 5.81
  },
  {
    "LONG": -37.46,
    "LAT": -11.35,
    "CIDADE": "Santa Luzia do Itanhy",
    "UF": "SERGIPE",
    "anual": 5.36,
    "JAN": 5.94,
    "FEB": 5.89,
    "MAR": 6.02,
    "APR": 5.28,
    "MAY": 4.6,
    "JUN": 4.38,
    "JUL": 4.47,
    "AUG": 4.87,
    "SEP": 5.44,
    "OCT": 5.58,
    "NOV": 5.88,
    "DEC": 5.96
  },
  {
    "LONG": -37.79,
    "LAT": -11.27,
    "CIDADE": "Itabaianinha",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.76,
    "FEB": 5.77,
    "MAR": 5.89,
    "APR": 5.13,
    "MAY": 4.55,
    "JUN": 4.28,
    "JUL": 4.43,
    "AUG": 4.82,
    "SEP": 5.4,
    "OCT": 5.51,
    "NOV": 5.8,
    "DEC": 5.77
  },
  {
    "LONG": -37.62,
    "LAT": -11.27,
    "CIDADE": "Arauá",
    "UF": "SERGIPE",
    "anual": 5.29,
    "JAN": 5.8,
    "FEB": 5.84,
    "MAR": 5.95,
    "APR": 5.15,
    "MAY": 4.58,
    "JUN": 4.39,
    "JUL": 4.45,
    "AUG": 4.82,
    "SEP": 5.39,
    "OCT": 5.51,
    "NOV": 5.76,
    "DEC": 5.81
  },
  {
    "LONG": -37.44,
    "LAT": -11.27,
    "CIDADE": "Estância",
    "UF": "SERGIPE",
    "anual": 5.32,
    "JAN": 5.84,
    "FEB": 5.8,
    "MAR": 5.96,
    "APR": 5.22,
    "MAY": 4.59,
    "JUN": 4.36,
    "JUL": 4.48,
    "AUG": 4.86,
    "SEP": 5.42,
    "OCT": 5.57,
    "NOV": 5.82,
    "DEC": 5.89
  },
  {
    "LONG": -38,
    "LAT": -11.18,
    "CIDADE": "Tobias Barreto",
    "UF": "SERGIPE",
    "anual": 5.2,
    "JAN": 5.73,
    "FEB": 5.68,
    "MAR": 5.84,
    "APR": 5.17,
    "MAY": 4.48,
    "JUN": 4.23,
    "JUL": 4.41,
    "AUG": 4.8,
    "SEP": 5.29,
    "OCT": 5.44,
    "NOV": 5.65,
    "DEC": 5.64
  },
  {
    "LONG": -37.68,
    "LAT": -11.19,
    "CIDADE": "Pedrinhas",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.75,
    "FEB": 5.81,
    "MAR": 5.93,
    "APR": 5.16,
    "MAY": 4.57,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 4.76,
    "SEP": 5.37,
    "OCT": 5.46,
    "NOV": 5.76,
    "DEC": 5.76
  },
  {
    "LONG": -37.73,
    "LAT": -11.07,
    "CIDADE": "Riachão do Dantas",
    "UF": "SERGIPE",
    "anual": 5.21,
    "JAN": 5.65,
    "FEB": 5.75,
    "MAR": 5.84,
    "APR": 5.16,
    "MAY": 4.5,
    "JUN": 4.28,
    "JUL": 4.39,
    "AUG": 4.76,
    "SEP": 5.35,
    "OCT": 5.43,
    "NOV": 5.7,
    "DEC": 5.69
  },
  {
    "LONG": -37.62,
    "LAT": -11.15,
    "CIDADE": "Boquim",
    "UF": "SERGIPE",
    "anual": 5.25,
    "JAN": 5.7,
    "FEB": 5.78,
    "MAR": 5.87,
    "APR": 5.19,
    "MAY": 4.55,
    "JUN": 4.37,
    "JUL": 4.43,
    "AUG": 4.85,
    "SEP": 5.4,
    "OCT": 5.49,
    "NOV": 5.69,
    "DEC": 5.68
  },
  {
    "LONG": -37.48,
    "LAT": -11.03,
    "CIDADE": "Salgado",
    "UF": "SERGIPE",
    "anual": 5.28,
    "JAN": 5.78,
    "FEB": 5.77,
    "MAR": 5.92,
    "APR": 5.22,
    "MAY": 4.52,
    "JUN": 4.35,
    "JUL": 4.37,
    "AUG": 4.82,
    "SEP": 5.42,
    "OCT": 5.53,
    "NOV": 5.83,
    "DEC": 5.78
  },
  {
    "LONG": -37.31,
    "LAT": -11,
    "CIDADE": "Itaporanga d'Ajuda",
    "UF": "SERGIPE",
    "anual": 5.32,
    "JAN": 5.79,
    "FEB": 5.87,
    "MAR": 6,
    "APR": 5.24,
    "MAY": 4.56,
    "JUN": 4.36,
    "JUL": 4.43,
    "AUG": 4.89,
    "SEP": 5.45,
    "OCT": 5.59,
    "NOV": 5.85,
    "DEC": 5.83
  },
  {
    "LONG": -37.21,
    "LAT": -11.01,
    "CIDADE": "São Cristóvão",
    "UF": "SERGIPE",
    "anual": 5.36,
    "JAN": 5.85,
    "FEB": 5.94,
    "MAR": 6.03,
    "APR": 5.26,
    "MAY": 4.62,
    "JUN": 4.38,
    "JUL": 4.45,
    "AUG": 4.9,
    "SEP": 5.47,
    "OCT": 5.64,
    "NOV": 5.91,
    "DEC": 5.9
  },
  {
    "LONG": -37.67,
    "LAT": -10.91,
    "CIDADE": "Lagarto",
    "UF": "SERGIPE",
    "anual": 5.22,
    "JAN": 5.69,
    "FEB": 5.74,
    "MAR": 5.84,
    "APR": 5.21,
    "MAY": 4.49,
    "JUN": 4.25,
    "JUL": 4.37,
    "AUG": 4.74,
    "SEP": 5.37,
    "OCT": 5.5,
    "NOV": 5.74,
    "DEC": 5.74
  },
  {
    "LONG": -37.13,
    "LAT": -10.85,
    "CIDADE": "Nossa Senhora do Socorro",
    "UF": "SERGIPE",
    "anual": 5.42,
    "JAN": 5.91,
    "FEB": 6.04,
    "MAR": 6.09,
    "APR": 5.3,
    "MAY": 4.67,
    "JUN": 4.36,
    "JUL": 4.41,
    "AUG": 4.94,
    "SEP": 5.52,
    "OCT": 5.78,
    "NOV": 5.99,
    "DEC": 5.98
  },
  {
    "LONG": -37.07,
    "LAT": "#VALOR!",
    "CIDADE": "Aracaju",
    "UF": "SERGIPE",
    "anual": 5.51,
    "JAN": 5.87,
    "FEB": 6.15,
    "MAR": 6.1,
    "APR": 5.33,
    "MAY": 4.8,
    "JUN": 4.48,
    "JUL": 4.58,
    "AUG": 5.12,
    "SEP": 5.68,
    "OCT": 5.91,
    "NOV": 5.99,
    "DEC": 6.08
  },
  {
    "LONG": -37.03,
    "LAT": -10.9,
    "CIDADE": "Barra dos Coqueiros",
    "UF": "SERGIPE",
    "anual": 5.51,
    "JAN": 5.87,
    "FEB": 6.15,
    "MAR": 6.1,
    "APR": 5.33,
    "MAY": 4.8,
    "JUN": 4.48,
    "JUL": 4.58,
    "AUG": 5.12,
    "SEP": 5.68,
    "OCT": 5.91,
    "NOV": 5.99,
    "DEC": 6.08
  },
  {
    "LONG": -37.57,
    "LAT": -10.79,
    "CIDADE": "São Domingos",
    "UF": "SERGIPE",
    "anual": 5.25,
    "JAN": 5.72,
    "FEB": 5.72,
    "MAR": 5.79,
    "APR": 5.19,
    "MAY": 4.53,
    "JUN": 4.3,
    "JUL": 4.4,
    "AUG": 4.8,
    "SEP": 5.46,
    "OCT": 5.52,
    "NOV": 5.8,
    "DEC": 5.77
  },
  {
    "LONG": -37.31,
    "LAT": -10.76,
    "CIDADE": "Areia Branca",
    "UF": "SERGIPE",
    "anual": 5.16,
    "JAN": 5.61,
    "FEB": 5.68,
    "MAR": 5.81,
    "APR": 5.16,
    "MAY": 4.46,
    "JUN": 4.16,
    "JUL": 4.26,
    "AUG": 4.68,
    "SEP": 5.26,
    "OCT": 5.43,
    "NOV": 5.73,
    "DEC": 5.68
  },
  {
    "LONG": -37.17,
    "LAT": -10.8,
    "CIDADE": "Laranjeiras",
    "UF": "SERGIPE",
    "anual": 5.4,
    "JAN": 5.93,
    "FEB": 5.94,
    "MAR": 6.08,
    "APR": 5.33,
    "MAY": 4.69,
    "JUN": 4.33,
    "JUL": 4.46,
    "AUG": 4.89,
    "SEP": 5.51,
    "OCT": 5.7,
    "NOV": 5.97,
    "DEC": 5.93
  },
  {
    "LONG": -37.05,
    "LAT": -10.79,
    "CIDADE": "Santo Amaro das Brotas",
    "UF": "SERGIPE",
    "anual": 5.44,
    "JAN": 5.87,
    "FEB": 6.05,
    "MAR": 6.12,
    "APR": 5.31,
    "MAY": 4.69,
    "JUN": 4.4,
    "JUL": 4.47,
    "AUG": 4.97,
    "SEP": 5.58,
    "OCT": 5.81,
    "NOV": 6.03,
    "DEC": 6.02
  },
  {
    "LONG": -3.82,
    "LAT": -10.72,
    "CIDADE": "Poço Verde",
    "UF": "SERGIPE",
    "anual": 5.23,
    "JAN": 5.74,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.24,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.38,
    "AUG": 4.73,
    "SEP": 5.43,
    "OCT": 5.52,
    "NOV": 5.84,
    "DEC": 5.71
  },
  {
    "LONG": -37.81,
    "LAT": -10.74,
    "CIDADE": "Simão Dias",
    "UF": "SERGIPE",
    "anual": 5.23,
    "JAN": 5.73,
    "FEB": 5.63,
    "MAR": 5.78,
    "APR": 5.31,
    "MAY": 4.59,
    "JUN": 4.23,
    "JUL": 4.38,
    "AUG": 4.81,
    "SEP": 5.36,
    "OCT": 5.45,
    "NOV": 5.81,
    "DEC": 5.69
  },
  {
    "LONG": -37.54,
    "LAT": -10.67,
    "CIDADE": "Macambira",
    "UF": "SERGIPE",
    "anual": 5.23,
    "JAN": 5.7,
    "FEB": 5.7,
    "MAR": 5.8,
    "APR": 5.2,
    "MAY": 4.48,
    "JUN": 4.3,
    "JUL": 4.37,
    "AUG": 4.8,
    "SEP": 5.43,
    "OCT": 5.51,
    "NOV": 5.81,
    "DEC": 5.73
  },
  {
    "LONG": -37.43,
    "LAT": -1.07,
    "CIDADE": "Itabaiana",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.68,
    "FEB": 5.74,
    "MAR": 5.85,
    "APR": 5.24,
    "MAY": 4.54,
    "JUN": 4.31,
    "JUL": 4.36,
    "AUG": 4.81,
    "SEP": 5.42,
    "OCT": 5.57,
    "NOV": 5.85,
    "DEC": 5.79
  },
  {
    "LONG": -37.49,
    "LAT": -10.75,
    "CIDADE": "Campo do Brito",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.68,
    "FEB": 5.74,
    "MAR": 5.85,
    "APR": 5.24,
    "MAY": 4.54,
    "JUN": 4.31,
    "JUL": 4.36,
    "AUG": 4.81,
    "SEP": 5.42,
    "OCT": 5.57,
    "NOV": 5.85,
    "DEC": 5.79
  },
  {
    "LONG": -37.31,
    "LAT": -10.66,
    "CIDADE": "Malhador",
    "UF": "SERGIPE",
    "anual": 5.28,
    "JAN": 5.82,
    "FEB": 5.74,
    "MAR": 5.92,
    "APR": 5.27,
    "MAY": 4.47,
    "JUN": 4.29,
    "JUL": 4.34,
    "AUG": 4.8,
    "SEP": 5.42,
    "OCT": 5.59,
    "NOV": 5.88,
    "DEC": 5.84
  },
  {
    "LONG": -37.15,
    "LAT": -10.68,
    "CIDADE": "Divina Pastora",
    "UF": "SERGIPE",
    "anual": 5.34,
    "JAN": 5.76,
    "FEB": 5.86,
    "MAR": 5.97,
    "APR": 5.28,
    "MAY": 4.67,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 4.92,
    "SEP": 5.49,
    "OCT": 5.62,
    "NOV": 5.89,
    "DEC": 5.78
  },
  {
    "LONG": -37.19,
    "LAT": -10.73,
    "CIDADE": "Riachuelo",
    "UF": "SERGIPE",
    "anual": 5.34,
    "JAN": 5.76,
    "FEB": 5.86,
    "MAR": 5.97,
    "APR": 5.28,
    "MAY": 4.67,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 4.92,
    "SEP": 5.49,
    "OCT": 5.62,
    "NOV": 5.89,
    "DEC": 5.78
  },
  {
    "LONG": -37.03,
    "LAT": -10.7,
    "CIDADE": "Rosário do Catete",
    "UF": "SERGIPE",
    "anual": 5.43,
    "JAN": 5.92,
    "FEB": 5.96,
    "MAR": 6.07,
    "APR": 5.34,
    "MAY": 4.69,
    "JUN": 4.47,
    "JUL": 4.48,
    "AUG": 4.99,
    "SEP": 5.57,
    "OCT": 5.72,
    "NOV": 6.02,
    "DEC": 5.94
  },
  {
    "LONG": -37.09,
    "LAT": -10.74,
    "CIDADE": "Maruim",
    "UF": "SERGIPE",
    "anual": 5.43,
    "JAN": 5.92,
    "FEB": 5.96,
    "MAR": 6.07,
    "APR": 5.34,
    "MAY": 4.69,
    "JUN": 4.47,
    "JUL": 4.48,
    "AUG": 4.99,
    "SEP": 5.57,
    "OCT": 5.72,
    "NOV": 6.02,
    "DEC": 5.94
  },
  {
    "LONG": -36.98,
    "LAT": -10.68,
    "CIDADE": "General Maynard",
    "UF": "SERGIPE",
    "anual": 5.51,
    "JAN": 6.01,
    "FEB": 6.08,
    "MAR": 6.16,
    "APR": 5.41,
    "MAY": 4.73,
    "JUN": 4.5,
    "JUL": 4.54,
    "AUG": 5.1,
    "SEP": 5.66,
    "OCT": 5.84,
    "NOV": 6.09,
    "DEC": 6.03
  },
  {
    "LONG": -36.86,
    "LAT": -10.74,
    "CIDADE": "Pirambu",
    "UF": "SERGIPE",
    "anual": 5.54,
    "JAN": 6.02,
    "FEB": 6.11,
    "MAR": 6.16,
    "APR": 5.43,
    "MAY": 4.76,
    "JUN": 4.54,
    "JUL": 4.59,
    "AUG": 5.2,
    "SEP": 5.72,
    "OCT": 5.89,
    "NOV": 6.03,
    "DEC": 6.06
  },
  {
    "LONG": -37.72,
    "LAT": -10.57,
    "CIDADE": "Pinhão",
    "UF": "SERGIPE",
    "anual": 5.25,
    "JAN": 5.62,
    "FEB": 5.64,
    "MAR": 5.78,
    "APR": 5.29,
    "MAY": 4.62,
    "JUN": 4.32,
    "JUL": 4.45,
    "AUG": 4.9,
    "SEP": 5.46,
    "OCT": 5.45,
    "NOV": 5.81,
    "DEC": 5.64
  },
  {
    "LONG": -37.69,
    "LAT": -1.06,
    "CIDADE": "Pedra Mole",
    "UF": "SERGIPE",
    "anual": 5.24,
    "JAN": 5.65,
    "FEB": 5.65,
    "MAR": 5.84,
    "APR": 5.24,
    "MAY": 4.54,
    "JUN": 4.31,
    "JUL": 4.42,
    "AUG": 4.83,
    "SEP": 5.44,
    "OCT": 5.51,
    "NOV": 5.8,
    "DEC": 5.67
  },
  {
    "LONG": -3.75,
    "LAT": -10.55,
    "CIDADE": "Frei Paulo",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.71,
    "FEB": 5.73,
    "MAR": 5.82,
    "APR": 5.22,
    "MAY": 4.52,
    "JUN": 4.3,
    "JUL": 4.4,
    "AUG": 4.87,
    "SEP": 5.44,
    "OCT": 5.58,
    "NOV": 5.85,
    "DEC": 5.7
  },
  {
    "LONG": -37.34,
    "LAT": -10.58,
    "CIDADE": "Moita Bonita",
    "UF": "SERGIPE",
    "anual": 5.31,
    "JAN": 5.78,
    "FEB": 5.8,
    "MAR": 5.92,
    "APR": 5.3,
    "MAY": 4.54,
    "JUN": 4.33,
    "JUL": 4.4,
    "AUG": 4.89,
    "SEP": 5.47,
    "OCT": 5.63,
    "NOV": 5.87,
    "DEC": 5.83
  },
  {
    "LONG": -37.11,
    "LAT": -10.6,
    "CIDADE": "Siriri",
    "UF": "SERGIPE",
    "anual": 5.3,
    "JAN": 5.8,
    "FEB": 5.78,
    "MAR": 5.88,
    "APR": 5.28,
    "MAY": 4.59,
    "JUN": 4.36,
    "JUL": 4.41,
    "AUG": 4.87,
    "SEP": 5.44,
    "OCT": 5.56,
    "NOV": 5.84,
    "DEC": 5.75
  },
  {
    "LONG": -37.19,
    "LAT": -10.64,
    "CIDADE": "Santa Rosa de Lima",
    "UF": "SERGIPE",
    "anual": 5.3,
    "JAN": 5.8,
    "FEB": 5.78,
    "MAR": 5.88,
    "APR": 5.28,
    "MAY": 4.59,
    "JUN": 4.36,
    "JUL": 4.41,
    "AUG": 4.87,
    "SEP": 5.44,
    "OCT": 5.56,
    "NOV": 5.84,
    "DEC": 5.75
  },
  {
    "LONG": -36.94,
    "LAT": -10.59,
    "CIDADE": "Japaratuba",
    "UF": "SERGIPE",
    "anual": 5.38,
    "JAN": 5.83,
    "FEB": 5.84,
    "MAR": 5.98,
    "APR": 5.35,
    "MAY": 4.68,
    "JUN": 4.46,
    "JUL": 4.47,
    "AUG": 5.02,
    "SEP": 5.53,
    "OCT": 5.64,
    "NOV": 5.92,
    "DEC": 5.82
  },
  {
    "LONG": -36.98,
    "LAT": -1.06,
    "CIDADE": "Carmópolis",
    "UF": "SERGIPE",
    "anual": 5.38,
    "JAN": 5.83,
    "FEB": 5.84,
    "MAR": 5.98,
    "APR": 5.35,
    "MAY": 4.68,
    "JUN": 4.46,
    "JUL": 4.47,
    "AUG": 5.02,
    "SEP": 5.53,
    "OCT": 5.64,
    "NOV": 5.92,
    "DEC": 5.82
  },
  {
    "LONG": -37.44,
    "LAT": -10.54,
    "CIDADE": "Ribeirópolis",
    "UF": "SERGIPE",
    "anual": 5.27,
    "JAN": 5.69,
    "FEB": 5.73,
    "MAR": 5.77,
    "APR": 5.26,
    "MAY": 4.57,
    "JUN": 4.33,
    "JUL": 4.41,
    "AUG": 4.93,
    "SEP": 5.48,
    "OCT": 5.54,
    "NOV": 5.81,
    "DEC": 5.75
  },
  {
    "LONG": -37.2,
    "LAT": -10.49,
    "CIDADE": "Nossa Senhora das Dores",
    "UF": "SERGIPE",
    "anual": 5.28,
    "JAN": 5.81,
    "FEB": 5.72,
    "MAR": 5.81,
    "APR": 5.3,
    "MAY": 4.56,
    "JUN": 4.36,
    "JUL": 4.4,
    "AUG": 4.86,
    "SEP": 5.47,
    "OCT": 5.55,
    "NOV": 5.8,
    "DEC": 5.75
  },
  {
    "LONG": -37.05,
    "LAT": -10.51,
    "CIDADE": "Capela",
    "UF": "SERGIPE",
    "anual": 5.27,
    "JAN": 5.77,
    "FEB": 5.64,
    "MAR": 5.82,
    "APR": 5.28,
    "MAY": 4.61,
    "JUN": 4.36,
    "JUL": 4.44,
    "AUG": 4.88,
    "SEP": 5.46,
    "OCT": 5.52,
    "NOV": 5.75,
    "DEC": 5.69
  },
  {
    "LONG": -36.65,
    "LAT": -10.46,
    "CIDADE": "Pacatuba",
    "UF": "SERGIPE",
    "anual": 5.31,
    "JAN": 5.75,
    "FEB": 5.81,
    "MAR": 5.88,
    "APR": 5.21,
    "MAY": 4.57,
    "JUN": 4.39,
    "JUL": 4.42,
    "AUG": 4.94,
    "SEP": 5.44,
    "OCT": 5.6,
    "NOV": 5.89,
    "DEC": 5.85
  },
  {
    "LONG": -37.7,
    "LAT": -1.04,
    "CIDADE": "Carira",
    "UF": "SERGIPE",
    "anual": 5.29,
    "JAN": 5.73,
    "FEB": 5.77,
    "MAR": 5.82,
    "APR": 5.3,
    "MAY": 4.6,
    "JUN": 4.32,
    "JUL": 4.44,
    "AUG": 4.94,
    "SEP": 5.52,
    "OCT": 5.52,
    "NOV": 5.85,
    "DEC": 5.74
  },
  {
    "LONG": -37.45,
    "LAT": -10.4,
    "CIDADE": "Nossa Senhora Aparecida",
    "UF": "SERGIPE",
    "anual": 5.29,
    "JAN": 5.68,
    "FEB": 5.7,
    "MAR": 5.77,
    "APR": 5.3,
    "MAY": 4.6,
    "JUN": 4.36,
    "JUL": 4.45,
    "AUG": 4.97,
    "SEP": 5.53,
    "OCT": 5.53,
    "NOV": 5.78,
    "DEC": 5.74
  },
  {
    "LONG": -37.38,
    "LAT": -10.39,
    "CIDADE": "São Miguel do Aleixo",
    "UF": "SERGIPE",
    "anual": 5.27,
    "JAN": 5.64,
    "FEB": 5.71,
    "MAR": 5.77,
    "APR": 5.27,
    "MAY": 4.57,
    "JUN": 4.41,
    "JUL": 4.44,
    "AUG": 4.96,
    "SEP": 5.47,
    "OCT": 5.5,
    "NOV": 5.82,
    "DEC": 5.68
  },
  {
    "LONG": -37.19,
    "LAT": -1.04,
    "CIDADE": "Cumbe",
    "UF": "SERGIPE",
    "anual": 5.28,
    "JAN": 5.7,
    "FEB": 5.67,
    "MAR": 5.78,
    "APR": 5.28,
    "MAY": 4.57,
    "JUN": 4.38,
    "JUL": 4.44,
    "AUG": 4.9,
    "SEP": 5.49,
    "OCT": 5.55,
    "NOV": 5.82,
    "DEC": 5.73
  },
  {
    "LONG": -36.96,
    "LAT": -10.42,
    "CIDADE": "Muribeca",
    "UF": "SERGIPE",
    "anual": 5.21,
    "JAN": 5.63,
    "FEB": 5.56,
    "MAR": 5.73,
    "APR": 5.19,
    "MAY": 4.55,
    "JUN": 4.34,
    "JUL": 4.41,
    "AUG": 4.81,
    "SEP": 5.39,
    "OCT": 5.47,
    "NOV": 5.78,
    "DEC": 5.7
  },
  {
    "LONG": -36.55,
    "LAT": -10.44,
    "CIDADE": "Ilha das Flores",
    "UF": "SERGIPE",
    "anual": 5.42,
    "JAN": 5.84,
    "FEB": 5.89,
    "MAR": 6.03,
    "APR": 5.31,
    "MAY": 4.65,
    "JUN": 4.43,
    "JUL": 4.49,
    "AUG": 5.03,
    "SEP": 5.62,
    "OCT": 5.85,
    "NOV": 6.02,
    "DEC": 5.94
  },
  {
    "LONG": -36.46,
    "LAT": -10.43,
    "CIDADE": "Brejo Grande",
    "UF": "SERGIPE",
    "anual": 5.45,
    "JAN": 5.76,
    "FEB": 5.95,
    "MAR": 6.08,
    "APR": 5.27,
    "MAY": 4.71,
    "JUN": 4.5,
    "JUL": 4.51,
    "AUG": 5.14,
    "SEP": 5.62,
    "OCT": 5.86,
    "NOV": 6,
    "DEC": 6
  },
  {
    "LONG": -37.31,
    "LAT": -10.26,
    "CIDADE": "Feira Nova",
    "UF": "SERGIPE",
    "anual": 5.27,
    "JAN": 5.64,
    "FEB": 5.63,
    "MAR": 5.8,
    "APR": 5.25,
    "MAY": 4.56,
    "JUN": 4.37,
    "JUL": 4.41,
    "AUG": 4.95,
    "SEP": 5.49,
    "OCT": 5.5,
    "NOV": 5.88,
    "DEC": 5.72
  },
  {
    "LONG": -37.02,
    "LAT": -10.28,
    "CIDADE": "Aquidabã",
    "UF": "SERGIPE",
    "anual": 5.25,
    "JAN": 5.59,
    "FEB": 5.6,
    "MAR": 5.76,
    "APR": 5.29,
    "MAY": 4.52,
    "JUN": 4.36,
    "JUL": 4.4,
    "AUG": 4.88,
    "SEP": 5.45,
    "OCT": 5.57,
    "NOV": 5.9,
    "DEC": 5.73
  },
  {
    "LONG": -36.92,
    "LAT": -10.34,
    "CIDADE": "Malhada dos Bois",
    "UF": "SERGIPE",
    "anual": 5.22,
    "JAN": 5.55,
    "FEB": 5.53,
    "MAR": 5.72,
    "APR": 5.19,
    "MAY": 4.53,
    "JUN": 4.35,
    "JUL": 4.38,
    "AUG": 4.87,
    "SEP": 5.38,
    "OCT": 5.51,
    "NOV": 5.84,
    "DEC": 5.73
  },
  {
    "LONG": -36.88,
    "LAT": -10.25,
    "CIDADE": "Cedro de São João",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.61,
    "FEB": 5.58,
    "MAR": 5.76,
    "APR": 5.15,
    "MAY": 4.54,
    "JUN": 4.38,
    "JUL": 4.39,
    "AUG": 4.86,
    "SEP": 5.5,
    "OCT": 5.66,
    "NOV": 5.89,
    "DEC": 5.82
  },
  {
    "LONG": -36.88,
    "LAT": -10.35,
    "CIDADE": "São Francisco",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.61,
    "FEB": 5.58,
    "MAR": 5.76,
    "APR": 5.15,
    "MAY": 4.54,
    "JUN": 4.38,
    "JUL": 4.39,
    "AUG": 4.86,
    "SEP": 5.5,
    "OCT": 5.66,
    "NOV": 5.89,
    "DEC": 5.82
  },
  {
    "LONG": -36.8,
    "LAT": -10.34,
    "CIDADE": "Japoatã",
    "UF": "SERGIPE",
    "anual": 5.38,
    "JAN": 5.75,
    "FEB": 5.87,
    "MAR": 5.96,
    "APR": 5.27,
    "MAY": 4.62,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 4.91,
    "SEP": 5.62,
    "OCT": 5.76,
    "NOV": 6,
    "DEC": 5.9
  },
  {
    "LONG": -36.61,
    "LAT": -10.29,
    "CIDADE": "Santana do São Francisco",
    "UF": "SERGIPE",
    "anual": 5.39,
    "JAN": 5.74,
    "FEB": 5.84,
    "MAR": 5.95,
    "APR": 5.3,
    "MAY": 4.65,
    "JUN": 4.44,
    "JUL": 4.49,
    "AUG": 4.98,
    "SEP": 5.6,
    "OCT": 5.79,
    "NOV": 6,
    "DEC": 5.89
  },
  {
    "LONG": -36.59,
    "LAT": -10.32,
    "CIDADE": "Neópolis",
    "UF": "SERGIPE",
    "anual": 5.35,
    "JAN": 5.71,
    "FEB": 5.73,
    "MAR": 5.91,
    "APR": 5.27,
    "MAY": 4.63,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 4.99,
    "SEP": 5.52,
    "OCT": 5.71,
    "NOV": 5.94,
    "DEC": 5.85
  },
  {
    "LONG": -37.42,
    "LAT": -10.22,
    "CIDADE": "Nossa Senhora da Glória",
    "UF": "SERGIPE",
    "anual": 5.33,
    "JAN": 5.75,
    "FEB": 5.71,
    "MAR": 5.77,
    "APR": 5.32,
    "MAY": 4.63,
    "JUN": 4.38,
    "JUL": 4.55,
    "AUG": 5.05,
    "SEP": 5.61,
    "OCT": 5.56,
    "NOV": 5.91,
    "DEC": 5.74
  },
  {
    "LONG": -3.72,
    "LAT": "#VALOR!",
    "CIDADE": "Graccho Cardoso",
    "UF": "SERGIPE",
    "anual": 5.27,
    "JAN": 5.65,
    "FEB": 5.62,
    "MAR": 5.8,
    "APR": 5.27,
    "MAY": 4.54,
    "JUN": 4.36,
    "JUL": 4.46,
    "AUG": 4.9,
    "SEP": 5.53,
    "OCT": 5.58,
    "NOV": 5.81,
    "DEC": 5.74
  },
  {
    "LONG": -36.84,
    "LAT": -10.22,
    "CIDADE": "Propriá",
    "UF": "SERGIPE",
    "anual": 5.44,
    "JAN": 5.93,
    "FEB": 5.9,
    "MAR": 6.01,
    "APR": 5.47,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.5,
    "AUG": 4.98,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.99,
    "DEC": 5.94
  },
  {
    "LONG": -36.88,
    "LAT": -10.21,
    "CIDADE": "Telha",
    "UF": "SERGIPE",
    "anual": 5.44,
    "JAN": 5.93,
    "FEB": 5.9,
    "MAR": 6.01,
    "APR": 5.47,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.5,
    "AUG": 4.98,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.99,
    "DEC": 5.94
  },
  {
    "LONG": -37.11,
    "LAT": -10.13,
    "CIDADE": "Itabi",
    "UF": "SERGIPE",
    "anual": 5.31,
    "JAN": 5.68,
    "FEB": 5.67,
    "MAR": 5.85,
    "APR": 5.33,
    "MAY": 4.58,
    "JUN": 4.35,
    "JUL": 4.48,
    "AUG": 4.97,
    "SEP": 5.58,
    "OCT": 5.65,
    "NOV": 5.87,
    "DEC": 5.74
  },
  {
    "LONG": -37.06,
    "LAT": -10.08,
    "CIDADE": "Nossa Senhora de Lourdes",
    "UF": "SERGIPE",
    "anual": 5.38,
    "JAN": 5.82,
    "FEB": 5.7,
    "MAR": 5.88,
    "APR": 5.38,
    "MAY": 4.66,
    "JUN": 4.47,
    "JUL": 4.5,
    "AUG": 5.03,
    "SEP": 5.65,
    "OCT": 5.69,
    "NOV": 5.95,
    "DEC": 5.81
  },
  {
    "LONG": -36.97,
    "LAT": -10.14,
    "CIDADE": "Canhoba",
    "UF": "SERGIPE",
    "anual": 5.41,
    "JAN": 5.83,
    "FEB": 5.8,
    "MAR": 5.94,
    "APR": 5.43,
    "MAY": 4.69,
    "JUN": 4.51,
    "JUL": 4.53,
    "AUG": 5.03,
    "SEP": 5.63,
    "OCT": 5.7,
    "NOV": 5.94,
    "DEC": 5.84
  },
  {
    "LONG": -36.93,
    "LAT": -10.13,
    "CIDADE": "Amparo de São Francisco",
    "UF": "SERGIPE",
    "anual": 5.41,
    "JAN": 5.83,
    "FEB": 5.8,
    "MAR": 5.94,
    "APR": 5.43,
    "MAY": 4.69,
    "JUN": 4.51,
    "JUL": 4.53,
    "AUG": 5.03,
    "SEP": 5.63,
    "OCT": 5.7,
    "NOV": 5.94,
    "DEC": 5.84
  },
  {
    "LONG": -37.56,
    "LAT": -1,
    "CIDADE": "Monte Alegre de Sergipe",
    "UF": "SERGIPE",
    "anual": 5.31,
    "JAN": 5.67,
    "FEB": 5.7,
    "MAR": 5.87,
    "APR": 5.34,
    "MAY": 4.59,
    "JUN": 4.32,
    "JUL": 4.46,
    "AUG": 4.95,
    "SEP": 5.54,
    "OCT": 5.56,
    "NOV": 5.89,
    "DEC": 5.78
  },
  {
    "LONG": -37.09,
    "LAT": -9.97,
    "CIDADE": "Gararu",
    "UF": "SERGIPE",
    "anual": 5.47,
    "JAN": 5.9,
    "FEB": 5.84,
    "MAR": 6.01,
    "APR": 5.5,
    "MAY": 4.71,
    "JUN": 4.52,
    "JUL": 4.56,
    "AUG": 5.13,
    "SEP": 5.77,
    "OCT": 5.78,
    "NOV": 6.03,
    "DEC": 5.88
  },
  {
    "LONG": -37.28,
    "LAT": -9.92,
    "CIDADE": "Porto da Folha",
    "UF": "SERGIPE",
    "anual": 5.43,
    "JAN": 5.89,
    "FEB": 5.84,
    "MAR": 5.91,
    "APR": 5.42,
    "MAY": 4.64,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.04,
    "SEP": 5.71,
    "OCT": 5.74,
    "NOV": 6.09,
    "DEC": 5.88
  },
  {
    "LONG": -37.68,
    "LAT": -9.81,
    "CIDADE": "Poço Redondo",
    "UF": "SERGIPE",
    "anual": 5.34,
    "JAN": 5.78,
    "FEB": 5.75,
    "MAR": 5.93,
    "APR": 5.38,
    "MAY": 4.64,
    "JUN": 4.3,
    "JUL": 4.39,
    "AUG": 4.86,
    "SEP": 5.53,
    "OCT": 5.66,
    "NOV": 6.01,
    "DEC": 5.88
  },
  {
    "LONG": -37.79,
    "LAT": -9.66,
    "CIDADE": "Canindé de São Francisco",
    "UF": "SERGIPE",
    "anual": 5.43,
    "JAN": 5.95,
    "FEB": 5.87,
    "MAR": 5.97,
    "APR": 5.49,
    "MAY": 4.68,
    "JUN": 4.33,
    "JUL": 4.41,
    "AUG": 5.04,
    "SEP": 5.61,
    "OCT": 5.76,
    "NOV": 6.08,
    "DEC": 5.96
  },
  {
    "LONG": -48.41,
    "LAT": "#VALOR!",
    "CIDADE": "Palmeirópolis",
    "UF": "TOCANTINS",
    "anual": 5.42,
    "JAN": 4.92,
    "FEB": 5.2,
    "MAR": 5.08,
    "APR": 5.51,
    "MAY": 5.65,
    "JUN": 5.61,
    "JUL": 5.95,
    "AUG": 6.33,
    "SEP": 5.95,
    "OCT": 5.38,
    "NOV": 4.7,
    "DEC": 4.78
  },
  {
    "LONG": -49.83,
    "LAT": -12.93,
    "CIDADE": "Araguaçu",
    "UF": "TOCANTINS",
    "anual": 5.42,
    "JAN": 4.94,
    "FEB": 5.22,
    "MAR": 5.12,
    "APR": 5.43,
    "MAY": 5.61,
    "JUN": 5.47,
    "JUL": 5.93,
    "AUG": 6.28,
    "SEP": 5.84,
    "OCT": 5.35,
    "NOV": 4.91,
    "DEC": 4.94
  },
  {
    "LONG": -46.95,
    "LAT": -12.92,
    "CIDADE": "Arraias",
    "UF": "TOCANTINS",
    "anual": 5.65,
    "JAN": 5.28,
    "FEB": 5.38,
    "MAR": 5.26,
    "APR": 5.59,
    "MAY": 5.81,
    "JUN": 5.73,
    "JUL": 6.09,
    "AUG": 6.54,
    "SEP": 6.17,
    "OCT": 5.63,
    "NOV": 5.06,
    "DEC": 5.22
  },
  {
    "LONG": -46.58,
    "LAT": -12.92,
    "CIDADE": "Novo Alegre",
    "UF": "TOCANTINS",
    "anual": 5.68,
    "JAN": 5.49,
    "FEB": 5.54,
    "MAR": 5.47,
    "APR": 5.71,
    "MAY": 5.79,
    "JUN": 5.68,
    "JUL": 5.97,
    "AUG": 6.33,
    "SEP": 6.02,
    "OCT": 5.6,
    "NOV": 5.14,
    "DEC": 5.39
  },
  {
    "LONG": -4.91,
    "LAT": -12.8,
    "CIDADE": "Talismã",
    "UF": "TOCANTINS",
    "anual": 5.43,
    "JAN": 4.97,
    "FEB": 5.26,
    "MAR": 5.14,
    "APR": 5.45,
    "MAY": 5.58,
    "JUN": 5.56,
    "JUL": 5.77,
    "AUG": 6.25,
    "SEP": 5.9,
    "OCT": 5.45,
    "NOV": 4.87,
    "DEC": 4.92
  },
  {
    "LONG": -4.65,
    "LAT": -12.79,
    "CIDADE": "Lavandeira",
    "UF": "TOCANTINS",
    "anual": 5.74,
    "JAN": 5.55,
    "FEB": 5.67,
    "MAR": 5.59,
    "APR": 5.81,
    "MAY": 5.81,
    "JUN": 5.69,
    "JUL": 5.99,
    "AUG": 6.3,
    "SEP": 6.14,
    "OCT": 5.67,
    "NOV": 5.21,
    "DEC": 5.43
  },
  {
    "LONG": -46.55,
    "LAT": -12.81,
    "CIDADE": "Combinado",
    "UF": "TOCANTINS",
    "anual": 5.74,
    "JAN": 5.55,
    "FEB": 5.67,
    "MAR": 5.59,
    "APR": 5.81,
    "MAY": 5.81,
    "JUN": 5.69,
    "JUL": 5.99,
    "AUG": 6.3,
    "SEP": 6.14,
    "OCT": 5.67,
    "NOV": 5.21,
    "DEC": 5.43
  },
  {
    "LONG": -4.86,
    "LAT": -12.65,
    "CIDADE": "Jaú do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.49,
    "JAN": 5.07,
    "FEB": 5.26,
    "MAR": 5.19,
    "APR": 5.51,
    "MAY": 5.72,
    "JUN": 5.62,
    "JUL": 5.83,
    "AUG": 6.39,
    "SEP": 6.01,
    "OCT": 5.42,
    "NOV": 4.87,
    "DEC": 4.95
  },
  {
    "LONG": -48.24,
    "LAT": -12.75,
    "CIDADE": "São Salvador do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.52,
    "JAN": 5.23,
    "FEB": 5.42,
    "MAR": 5.33,
    "APR": 5.63,
    "MAY": 5.65,
    "JUN": 5.49,
    "JUL": 5.75,
    "AUG": 6.26,
    "SEP": 5.98,
    "OCT": 5.53,
    "NOV": 4.93,
    "DEC": 5.02
  },
  {
    "LONG": -4.64,
    "LAT": -12.71,
    "CIDADE": "Aurora do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.72,
    "JAN": 5.49,
    "FEB": 5.59,
    "MAR": 5.53,
    "APR": 5.71,
    "MAY": 5.83,
    "JUN": 5.73,
    "JUL": 5.97,
    "AUG": 6.42,
    "SEP": 6.26,
    "OCT": 5.65,
    "NOV": 5.12,
    "DEC": 5.4
  },
  {
    "LONG": -47.87,
    "LAT": -12.62,
    "CIDADE": "Paranã",
    "UF": "TOCANTINS",
    "anual": 5.62,
    "JAN": 5.33,
    "FEB": 5.49,
    "MAR": 5.41,
    "APR": 5.66,
    "MAY": 5.69,
    "JUN": 5.64,
    "JUL": 6.01,
    "AUG": 6.4,
    "SEP": 6.1,
    "OCT": 5.54,
    "NOV": 5.06,
    "DEC": 5.17
  },
  {
    "LONG": -4.99,
    "LAT": -12.54,
    "CIDADE": "Sandolândia",
    "UF": "TOCANTINS",
    "anual": 5.4,
    "JAN": 4.97,
    "FEB": 5.18,
    "MAR": 5.09,
    "APR": 5.39,
    "MAY": 5.55,
    "JUN": 5.53,
    "JUL": 5.89,
    "AUG": 6.34,
    "SEP": 5.81,
    "OCT": 5.27,
    "NOV": 4.86,
    "DEC": 4.88
  },
  {
    "LONG": -49.13,
    "LAT": -12.48,
    "CIDADE": "Alvorada",
    "UF": "TOCANTINS",
    "anual": 5.43,
    "JAN": 5.05,
    "FEB": 5.24,
    "MAR": 5.19,
    "APR": 5.46,
    "MAY": 5.57,
    "JUN": 5.52,
    "JUL": 5.78,
    "AUG": 6.17,
    "SEP": 5.93,
    "OCT": 5.44,
    "NOV": 4.91,
    "DEC": 4.94
  },
  {
    "LONG": -46.43,
    "LAT": -12.4,
    "CIDADE": "Taguatinga",
    "UF": "TOCANTINS",
    "anual": 5.68,
    "JAN": 5.34,
    "FEB": 5.36,
    "MAR": 5.31,
    "APR": 5.53,
    "MAY": 5.8,
    "JUN": 5.85,
    "JUL": 6.12,
    "AUG": 6.57,
    "SEP": 6.28,
    "OCT": 5.65,
    "NOV": 5.03,
    "DEC": 5.3
  },
  {
    "LONG": -47.3,
    "LAT": -12.22,
    "CIDADE": "Conceição do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.73,
    "JAN": 5.39,
    "FEB": 5.56,
    "MAR": 5.47,
    "APR": 5.68,
    "MAY": 5.87,
    "JUN": 5.8,
    "JUL": 6.06,
    "AUG": 6.45,
    "SEP": 6.28,
    "OCT": 5.7,
    "NOV": 5.24,
    "DEC": 5.27
  },
  {
    "LONG": -46.99,
    "LAT": -12.19,
    "CIDADE": "Taipas do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.73,
    "JAN": 5.41,
    "FEB": 5.58,
    "MAR": 5.46,
    "APR": 5.73,
    "MAY": 5.85,
    "JUN": 5.82,
    "JUL": 6,
    "AUG": 6.4,
    "SEP": 6.29,
    "OCT": 5.72,
    "NOV": 5.23,
    "DEC": 5.33
  },
  {
    "LONG": -49.17,
    "LAT": -12.14,
    "CIDADE": "Figueirópolis",
    "UF": "TOCANTINS",
    "anual": 5.38,
    "JAN": 5,
    "FEB": 5.21,
    "MAR": 5.1,
    "APR": 5.41,
    "MAY": 5.57,
    "JUN": 5.37,
    "JUL": 5.68,
    "AUG": 6.18,
    "SEP": 5.89,
    "OCT": 5.35,
    "NOV": 4.92,
    "DEC": 4.9
  },
  {
    "LONG": -46.47,
    "LAT": -12.09,
    "CIDADE": "Ponte Alta do Bom Jesus",
    "UF": "TOCANTINS",
    "anual": 5.63,
    "JAN": 5.39,
    "FEB": 5.42,
    "MAR": 5.18,
    "APR": 5.33,
    "MAY": 5.75,
    "JUN": 5.76,
    "JUL": 6.1,
    "AUG": 6.49,
    "SEP": 6.31,
    "OCT": 5.64,
    "NOV": 5.01,
    "DEC": 5.17
  },
  {
    "LONG": -48.91,
    "LAT": -11.99,
    "CIDADE": "Sucupira",
    "UF": "TOCANTINS",
    "anual": 5.42,
    "JAN": 5.03,
    "FEB": 5.31,
    "MAR": 5.13,
    "APR": 5.45,
    "MAY": 5.6,
    "JUN": 5.44,
    "JUL": 5.7,
    "AUG": 6.12,
    "SEP": 5.96,
    "OCT": 5.41,
    "NOV": 4.95,
    "DEC": 4.98
  },
  {
    "LONG": -48.54,
    "LAT": -12.04,
    "CIDADE": "Peixe",
    "UF": "TOCANTINS",
    "anual": 5.46,
    "JAN": 5.09,
    "FEB": 5.3,
    "MAR": 5.2,
    "APR": 5.43,
    "MAY": 5.56,
    "JUN": 5.55,
    "JUL": 5.8,
    "AUG": 6.22,
    "SEP": 5.97,
    "OCT": 5.48,
    "NOV": 5,
    "DEC": 4.98
  },
  {
    "LONG": -48.24,
    "LAT": -11.97,
    "CIDADE": "São Valério da Natividade",
    "UF": "TOCANTINS",
    "anual": 5.49,
    "JAN": 5.08,
    "FEB": 5.26,
    "MAR": 5.14,
    "APR": 5.44,
    "MAY": 5.66,
    "JUN": 5.59,
    "JUL": 5.87,
    "AUG": 6.3,
    "SEP": 6.06,
    "OCT": 5.46,
    "NOV": 5.05,
    "DEC": 4.98
  },
  {
    "LONG": -49.16,
    "LAT": -11.89,
    "CIDADE": "Cariri do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.36,
    "JAN": 4.96,
    "FEB": 5.19,
    "MAR": 5.06,
    "APR": 5.34,
    "MAY": 5.55,
    "JUN": 5.39,
    "JUL": 5.68,
    "AUG": 6.14,
    "SEP": 5.86,
    "OCT": 5.31,
    "NOV": 4.92,
    "DEC": 4.87
  },
  {
    "LONG": -49.53,
    "LAT": -11.81,
    "CIDADE": "Formoso do Araguaia",
    "UF": "TOCANTINS",
    "anual": 5.33,
    "JAN": 4.97,
    "FEB": 5.12,
    "MAR": 5.11,
    "APR": 5.27,
    "MAY": 5.5,
    "JUN": 5.4,
    "JUL": 5.56,
    "AUG": 6.16,
    "SEP": 5.75,
    "OCT": 5.33,
    "NOV": 4.92,
    "DEC": 4.92
  },
  {
    "LONG": -4.66,
    "LAT": -11.82,
    "CIDADE": "Novo Jardim",
    "UF": "TOCANTINS",
    "anual": 5.6,
    "JAN": 5.23,
    "FEB": 5.24,
    "MAR": 5.14,
    "APR": 5.44,
    "MAY": 5.75,
    "JUN": 5.82,
    "JUL": 6.01,
    "AUG": 6.57,
    "SEP": 6.34,
    "OCT": 5.57,
    "NOV": 5.05,
    "DEC": 5.07
  },
  {
    "LONG": -49.07,
    "LAT": -11.73,
    "CIDADE": "Gurupi",
    "UF": "TOCANTINS",
    "anual": 5.38,
    "JAN": 4.9,
    "FEB": 5.2,
    "MAR": 5.04,
    "APR": 5.3,
    "MAY": 5.52,
    "JUN": 5.46,
    "JUL": 5.78,
    "AUG": 6.23,
    "SEP": 5.91,
    "OCT": 5.33,
    "NOV": 4.95,
    "DEC": 4.92
  },
  {
    "LONG": -47.73,
    "LAT": -11.71,
    "CIDADE": "Natividade",
    "UF": "TOCANTINS",
    "anual": 5.57,
    "JAN": 5.16,
    "FEB": 5.34,
    "MAR": 5.18,
    "APR": 5.47,
    "MAY": 5.72,
    "JUN": 5.68,
    "JUL": 5.95,
    "AUG": 6.49,
    "SEP": 6.2,
    "OCT": 5.52,
    "NOV": 5.09,
    "DEC": 5.06
  },
  {
    "LONG": -4.77,
    "LAT": -11.62,
    "CIDADE": "Chapada da Natividade",
    "UF": "TOCANTINS",
    "anual": 5.55,
    "JAN": 5.1,
    "FEB": 5.32,
    "MAR": 5.14,
    "APR": 5.44,
    "MAY": 5.71,
    "JUN": 5.69,
    "JUL": 5.94,
    "AUG": 6.47,
    "SEP": 6.18,
    "OCT": 5.53,
    "NOV": 5.07,
    "DEC": 5.03
  },
  {
    "LONG": -47.17,
    "LAT": -11.57,
    "CIDADE": "Almas",
    "UF": "TOCANTINS",
    "anual": 5.64,
    "JAN": 5.28,
    "FEB": 5.34,
    "MAR": 5.22,
    "APR": 5.53,
    "MAY": 5.81,
    "JUN": 5.79,
    "JUL": 6,
    "AUG": 6.52,
    "SEP": 6.26,
    "OCT": 5.6,
    "NOV": 5.12,
    "DEC": 5.16
  },
  {
    "LONG": -47.05,
    "LAT": -11.61,
    "CIDADE": "Porto Alegre do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.64,
    "JAN": 5.24,
    "FEB": 5.3,
    "MAR": 5.19,
    "APR": 5.53,
    "MAY": 5.83,
    "JUN": 5.83,
    "JUL": 6.06,
    "AUG": 6.59,
    "SEP": 6.29,
    "OCT": 5.61,
    "NOV": 5.1,
    "DEC": 5.15
  },
  {
    "LONG": -46.82,
    "LAT": -11.62,
    "CIDADE": "Dianópolis",
    "UF": "TOCANTINS",
    "anual": 5.6,
    "JAN": 5.19,
    "FEB": 5.21,
    "MAR": 5.12,
    "APR": 5.4,
    "MAY": 5.77,
    "JUN": 5.81,
    "JUL": 6.08,
    "AUG": 6.6,
    "SEP": 6.36,
    "OCT": 5.56,
    "NOV": 5.06,
    "DEC": 5.03
  },
  {
    "LONG": -48.12,
    "LAT": -11.45,
    "CIDADE": "Santa Rosa do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.51,
    "JAN": 5.08,
    "FEB": 5.25,
    "MAR": 5.11,
    "APR": 5.36,
    "MAY": 5.63,
    "JUN": 5.68,
    "JUL": 5.96,
    "AUG": 6.47,
    "SEP": 6.06,
    "OCT": 5.49,
    "NOV": 5.05,
    "DEC": 5
  },
  {
    "LONG": -46.88,
    "LAT": -11.4,
    "CIDADE": "Rio da Conceição",
    "UF": "TOCANTINS",
    "anual": 5.47,
    "JAN": 5.1,
    "FEB": 5.08,
    "MAR": 5,
    "APR": 5.24,
    "MAY": 5.57,
    "JUN": 5.71,
    "JUL": 5.92,
    "AUG": 6.46,
    "SEP": 6.23,
    "OCT": 5.43,
    "NOV": 4.95,
    "DEC": 4.93
  },
  {
    "LONG": -4.93,
    "LAT": -11.34,
    "CIDADE": "Dueré",
    "UF": "TOCANTINS",
    "anual": 5.37,
    "JAN": 4.88,
    "FEB": 5.03,
    "MAR": 4.97,
    "APR": 5.26,
    "MAY": 5.49,
    "JUN": 5.61,
    "JUL": 5.91,
    "AUG": 6.39,
    "SEP": 5.82,
    "OCT": 5.28,
    "NOV": 4.9,
    "DEC": 4.91
  },
  {
    "LONG": -48.94,
    "LAT": -1.13,
    "CIDADE": "Aliança do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.38,
    "JAN": 4.87,
    "FEB": 5.11,
    "MAR": 4.95,
    "APR": 5.26,
    "MAY": 5.5,
    "JUN": 5.55,
    "JUL": 5.87,
    "AUG": 6.35,
    "SEP": 5.86,
    "OCT": 5.33,
    "NOV": 4.97,
    "DEC": 4.89
  },
  {
    "LONG": -48.46,
    "LAT": -11.24,
    "CIDADE": "Ipueiras",
    "UF": "TOCANTINS",
    "anual": 5.41,
    "JAN": 4.87,
    "FEB": 5.23,
    "MAR": 5.09,
    "APR": 5.4,
    "MAY": 5.59,
    "JUN": 5.61,
    "JUL": 5.7,
    "AUG": 6.32,
    "SEP": 5.92,
    "OCT": 5.34,
    "NOV": 4.98,
    "DEC": 4.92
  },
  {
    "LONG": -4.89,
    "LAT": -11.1,
    "CIDADE": "Crixás do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.37,
    "JAN": 4.86,
    "FEB": 5.09,
    "MAR": 4.96,
    "APR": 5.26,
    "MAY": 5.52,
    "JUN": 5.59,
    "JUL": 5.92,
    "AUG": 6.38,
    "SEP": 5.78,
    "OCT": 5.26,
    "NOV": 4.96,
    "DEC": 4.85
  },
  {
    "LONG": -48.17,
    "LAT": -11.15,
    "CIDADE": "Silvanópolis",
    "UF": "TOCANTINS",
    "anual": 5.44,
    "JAN": 5.02,
    "FEB": 5.17,
    "MAR": 4.99,
    "APR": 5.39,
    "MAY": 5.6,
    "JUN": 5.63,
    "JUL": 5.82,
    "AUG": 6.38,
    "SEP": 6,
    "OCT": 5.35,
    "NOV": 4.95,
    "DEC": 4.92
  },
  {
    "LONG": -47.58,
    "LAT": -11.14,
    "CIDADE": "Pindorama do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.55,
    "JAN": 5.1,
    "FEB": 5.1,
    "MAR": 5.05,
    "APR": 5.43,
    "MAY": 5.71,
    "JUN": 5.84,
    "JUL": 6.08,
    "AUG": 6.56,
    "SEP": 6.24,
    "OCT": 5.5,
    "NOV": 5.03,
    "DEC": 4.98
  },
  {
    "LONG": -48.57,
    "LAT": -11.02,
    "CIDADE": "Brejinho de Nazaré",
    "UF": "TOCANTINS",
    "anual": 5.37,
    "JAN": 4.91,
    "FEB": 5.09,
    "MAR": 5.01,
    "APR": 5.36,
    "MAY": 5.55,
    "JUN": 5.6,
    "JUL": 5.7,
    "AUG": 6.26,
    "SEP": 5.91,
    "OCT": 5.36,
    "NOV": 4.91,
    "DEC": 4.83
  },
  {
    "LONG": -48.91,
    "LAT": -10.86,
    "CIDADE": "Santa Rita do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.33,
    "JAN": 4.81,
    "FEB": 4.99,
    "MAR": 4.9,
    "APR": 5.2,
    "MAY": 5.48,
    "JUN": 5.66,
    "JUL": 5.9,
    "AUG": 6.29,
    "SEP": 5.79,
    "OCT": 5.21,
    "NOV": 4.92,
    "DEC": 4.77
  },
  {
    "LONG": -49.62,
    "LAT": -10.79,
    "CIDADE": "Lagoa da Confusão",
    "UF": "TOCANTINS",
    "anual": 5.27,
    "JAN": 4.86,
    "FEB": 5.01,
    "MAR": 4.91,
    "APR": 5.15,
    "MAY": 5.41,
    "JUN": 5.53,
    "JUL": 5.69,
    "AUG": 6.15,
    "SEP": 5.66,
    "OCT": 5.22,
    "NOV": 4.88,
    "DEC": 4.8
  },
  {
    "LONG": -4.89,
    "LAT": -10.76,
    "CIDADE": "Fátima",
    "UF": "TOCANTINS",
    "anual": 5.31,
    "JAN": 4.73,
    "FEB": 4.93,
    "MAR": 4.86,
    "APR": 5.17,
    "MAY": 5.43,
    "JUN": 5.66,
    "JUL": 5.99,
    "AUG": 6.36,
    "SEP": 5.81,
    "OCT": 5.17,
    "NOV": 4.87,
    "DEC": 4.72
  },
  {
    "LONG": -48.11,
    "LAT": -10.76,
    "CIDADE": "Monte do Carmo",
    "UF": "TOCANTINS",
    "anual": 5.37,
    "JAN": 4.91,
    "FEB": 4.96,
    "MAR": 4.81,
    "APR": 5.27,
    "MAY": 5.54,
    "JUN": 5.57,
    "JUL": 5.88,
    "AUG": 6.47,
    "SEP": 6.06,
    "OCT": 5.28,
    "NOV": 4.87,
    "DEC": 4.86
  },
  {
    "LONG": -4.89,
    "LAT": -10.71,
    "CIDADE": "Oliveira de Fátima",
    "UF": "TOCANTINS",
    "anual": 5.28,
    "JAN": 4.67,
    "FEB": 4.85,
    "MAR": 4.82,
    "APR": 5.17,
    "MAY": 5.36,
    "JUN": 5.65,
    "JUL": 5.89,
    "AUG": 6.36,
    "SEP": 5.83,
    "OCT": 5.22,
    "NOV": 4.85,
    "DEC": 4.71
  },
  {
    "LONG": -48.41,
    "LAT": -10.7,
    "CIDADE": "Porto Nacional",
    "UF": "TOCANTINS",
    "anual": 5.34,
    "JAN": 4.97,
    "FEB": 5.08,
    "MAR": 4.95,
    "APR": 5.38,
    "MAY": 5.58,
    "JUN": 5.51,
    "JUL": 5.67,
    "AUG": 6.05,
    "SEP": 5.9,
    "OCT": 5.34,
    "NOV": 4.86,
    "DEC": 4.82
  },
  {
    "LONG": -47.54,
    "LAT": -10.74,
    "CIDADE": "Ponte Alta do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.48,
    "JAN": 5,
    "FEB": 5.14,
    "MAR": 5.03,
    "APR": 5.37,
    "MAY": 5.61,
    "JUN": 5.69,
    "JUL": 5.9,
    "AUG": 6.46,
    "SEP": 6.14,
    "OCT": 5.47,
    "NOV": 4.99,
    "DEC": 4.99
  },
  {
    "LONG": -49.19,
    "LAT": -10.6,
    "CIDADE": "Cristalândia",
    "UF": "TOCANTINS",
    "anual": 5.27,
    "JAN": 4.72,
    "FEB": 4.87,
    "MAR": 4.79,
    "APR": 5.09,
    "MAY": 5.37,
    "JUN": 5.68,
    "JUL": 5.82,
    "AUG": 6.38,
    "SEP": 5.79,
    "OCT": 5.23,
    "NOV": 4.83,
    "DEC": 4.67
  },
  {
    "LONG": -48.91,
    "LAT": -10.57,
    "CIDADE": "Nova Rosalândia",
    "UF": "TOCANTINS",
    "anual": 5.29,
    "JAN": 4.72,
    "FEB": 4.88,
    "MAR": 4.82,
    "APR": 5.17,
    "MAY": 5.4,
    "JUN": 5.66,
    "JUL": 5.8,
    "AUG": 6.36,
    "SEP": 5.83,
    "OCT": 5.24,
    "NOV": 4.85,
    "DEC": 4.72
  },
  {
    "LONG": -46.42,
    "LAT": -10.55,
    "CIDADE": "Mateiros",
    "UF": "TOCANTINS",
    "anual": 5.51,
    "JAN": 5.13,
    "FEB": 5.06,
    "MAR": 4.98,
    "APR": 5.3,
    "MAY": 5.58,
    "JUN": 5.64,
    "JUL": 5.87,
    "AUG": 6.51,
    "SEP": 6.35,
    "OCT": 5.59,
    "NOV": 5.06,
    "DEC": 5
  },
  {
    "LONG": -49.18,
    "LAT": -10.44,
    "CIDADE": "Pium",
    "UF": "TOCANTINS",
    "anual": 5.26,
    "JAN": 4.73,
    "FEB": 4.83,
    "MAR": 4.75,
    "APR": 5.06,
    "MAY": 5.36,
    "JUN": 5.64,
    "JUL": 5.9,
    "AUG": 6.38,
    "SEP": 5.79,
    "OCT": 5.22,
    "NOV": 4.83,
    "DEC": 4.68
  },
  {
    "LONG": -48.9,
    "LAT": -10.42,
    "CIDADE": "Pugmil",
    "UF": "TOCANTINS",
    "anual": 5.29,
    "JAN": 4.75,
    "FEB": 4.89,
    "MAR": 4.79,
    "APR": 5.21,
    "MAY": 5.43,
    "JUN": 5.66,
    "JUL": 5.78,
    "AUG": 6.34,
    "SEP": 5.84,
    "OCT": 5.22,
    "NOV": 4.86,
    "DEC": 4.69
  },
  {
    "LONG": -47.81,
    "LAT": -10.28,
    "CIDADE": "Santa Tereza do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.42,
    "JAN": 5.01,
    "FEB": 5.03,
    "MAR": 5.05,
    "APR": 5.32,
    "MAY": 5.62,
    "JUN": 5.63,
    "JUL": 5.92,
    "AUG": 6.37,
    "SEP": 5.98,
    "OCT": 5.3,
    "NOV": 4.95,
    "DEC": 4.92
  },
  {
    "LONG": -47.57,
    "LAT": -10.3,
    "CIDADE": "Lagoa do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.49,
    "JAN": 5.09,
    "FEB": 5.16,
    "MAR": 5.08,
    "APR": 5.38,
    "MAY": 5.66,
    "JUN": 5.65,
    "JUL": 5.83,
    "AUG": 6.34,
    "SEP": 6.17,
    "OCT": 5.44,
    "NOV": 5.1,
    "DEC": 5.03
  },
  {
    "LONG": -48.88,
    "LAT": -10.18,
    "CIDADE": "Paraíso do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.23,
    "JAN": 4.66,
    "FEB": 4.83,
    "MAR": 4.63,
    "APR": 5.06,
    "MAY": 5.4,
    "JUN": 5.61,
    "JUL": 5.79,
    "AUG": 6.41,
    "SEP": 5.79,
    "OCT": 5.14,
    "NOV": 4.78,
    "DEC": 4.61
  },
  {
    "LONG": -48.36,
    "LAT": -10.24,
    "CIDADE": "Palmas",
    "UF": "TOCANTINS",
    "anual": 5.29,
    "JAN": 4.84,
    "FEB": 4.97,
    "MAR": 4.87,
    "APR": 5.18,
    "MAY": 5.38,
    "JUN": 5.55,
    "JUL": 5.72,
    "AUG": 6.31,
    "SEP": 5.81,
    "OCT": 5.28,
    "NOV": 4.88,
    "DEC": 4.75
  },
  {
    "LONG": -46.66,
    "LAT": -10.16,
    "CIDADE": "São Félix do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.55,
    "JAN": 5.13,
    "FEB": 5.13,
    "MAR": 5.02,
    "APR": 5.35,
    "MAY": 5.68,
    "JUN": 5.73,
    "JUL": 5.97,
    "AUG": 6.47,
    "SEP": 6.32,
    "OCT": 5.61,
    "NOV": 5.1,
    "DEC": 5.05
  },
  {
    "LONG": -49.15,
    "LAT": -10.14,
    "CIDADE": "Chapada de Areia",
    "UF": "TOCANTINS",
    "anual": 5.26,
    "JAN": 4.73,
    "FEB": 4.85,
    "MAR": 4.77,
    "APR": 5.05,
    "MAY": 5.41,
    "JUN": 5.6,
    "JUL": 5.84,
    "AUG": 6.36,
    "SEP": 5.75,
    "OCT": 5.2,
    "NOV": 4.84,
    "DEC": 4.72
  },
  {
    "LONG": -48.99,
    "LAT": -10.01,
    "CIDADE": "Monte Santo do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.22,
    "JAN": 4.66,
    "FEB": 4.8,
    "MAR": 4.66,
    "APR": 5.01,
    "MAY": 5.37,
    "JUN": 5.69,
    "JUL": 5.89,
    "AUG": 6.37,
    "SEP": 5.73,
    "OCT": 5.05,
    "NOV": 4.81,
    "DEC": 4.63
  },
  {
    "LONG": -4.8,
    "LAT": -9.95,
    "CIDADE": "Aparecida do Rio Negro",
    "UF": "TOCANTINS",
    "anual": 5.38,
    "JAN": 4.9,
    "FEB": 5.03,
    "MAR": 5.02,
    "APR": 5.23,
    "MAY": 5.54,
    "JUN": 5.57,
    "JUL": 5.78,
    "AUG": 6.43,
    "SEP": 5.95,
    "OCT": 5.31,
    "NOV": 4.88,
    "DEC": 4.86
  },
  {
    "LONG": -47.68,
    "LAT": -9.97,
    "CIDADE": "Novo Acordo",
    "UF": "TOCANTINS",
    "anual": 5.46,
    "JAN": 5,
    "FEB": 5.07,
    "MAR": 5.02,
    "APR": 5.31,
    "MAY": 5.59,
    "JUN": 5.64,
    "JUL": 5.89,
    "AUG": 6.55,
    "SEP": 6.07,
    "OCT": 5.39,
    "NOV": 5,
    "DEC": 4.98
  },
  {
    "LONG": -49.66,
    "LAT": -9.79,
    "CIDADE": "Marianópolis do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.19,
    "JAN": 4.72,
    "FEB": 4.86,
    "MAR": 4.78,
    "APR": 4.91,
    "MAY": 5.26,
    "JUN": 5.53,
    "JUL": 5.79,
    "AUG": 6.25,
    "SEP": 5.61,
    "OCT": 5.07,
    "NOV": 4.79,
    "DEC": 4.73
  },
  {
    "LONG": -49.22,
    "LAT": -9.8,
    "CIDADE": "Divinópolis do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.22,
    "JAN": 4.7,
    "FEB": 4.84,
    "MAR": 4.75,
    "APR": 4.94,
    "MAY": 5.32,
    "JUN": 5.57,
    "JUL": 5.85,
    "AUG": 6.3,
    "SEP": 5.69,
    "OCT": 5.12,
    "NOV": 4.84,
    "DEC": 4.66
  },
  {
    "LONG": -48.73,
    "LAT": -9.83,
    "CIDADE": "Barrolândia",
    "UF": "TOCANTINS",
    "anual": 5.22,
    "JAN": 4.68,
    "FEB": 4.86,
    "MAR": 4.74,
    "APR": 5.06,
    "MAY": 5.38,
    "JUN": 5.6,
    "JUL": 5.79,
    "AUG": 6.36,
    "SEP": 5.71,
    "OCT": 5.02,
    "NOV": 4.78,
    "DEC": 4.62
  },
  {
    "LONG": -48.36,
    "LAT": -9.75,
    "CIDADE": "Lajeado",
    "UF": "TOCANTINS",
    "anual": 5.29,
    "JAN": 4.71,
    "FEB": 4.85,
    "MAR": 4.81,
    "APR": 5.09,
    "MAY": 5.43,
    "JUN": 5.63,
    "JUL": 5.81,
    "AUG": 6.45,
    "SEP": 5.86,
    "OCT": 5.23,
    "NOV": 4.83,
    "DEC": 4.73
  },
  {
    "LONG": -49.16,
    "LAT": -9.62,
    "CIDADE": "Abreulândia",
    "UF": "TOCANTINS",
    "anual": 5.2,
    "JAN": 4.66,
    "FEB": 4.79,
    "MAR": 4.75,
    "APR": 4.94,
    "MAY": 5.34,
    "JUN": 5.57,
    "JUL": 5.82,
    "AUG": 6.34,
    "SEP": 5.69,
    "OCT": 5.05,
    "NOV": 4.82,
    "DEC": 4.64
  },
  {
    "LONG": -48.37,
    "LAT": -9.56,
    "CIDADE": "Tocantínia",
    "UF": "TOCANTINS",
    "anual": 5.32,
    "JAN": 4.75,
    "FEB": 4.91,
    "MAR": 4.9,
    "APR": 5.16,
    "MAY": 5.49,
    "JUN": 5.6,
    "JUL": 5.78,
    "AUG": 6.44,
    "SEP": 5.92,
    "OCT": 5.24,
    "NOV": 4.86,
    "DEC": 4.74
  },
  {
    "LONG": -48.39,
    "LAT": -0.96,
    "CIDADE": "Miracema do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.32,
    "JAN": 4.75,
    "FEB": 4.91,
    "MAR": 4.9,
    "APR": 5.16,
    "MAY": 5.49,
    "JUN": 5.6,
    "JUL": 5.78,
    "AUG": 6.44,
    "SEP": 5.92,
    "OCT": 5.24,
    "NOV": 4.86,
    "DEC": 4.74
  },
  {
    "LONG": -46.67,
    "LAT": -9.6,
    "CIDADE": "Lizarda",
    "UF": "TOCANTINS",
    "anual": 5.46,
    "JAN": 5.06,
    "FEB": 4.98,
    "MAR": 4.97,
    "APR": 5.31,
    "MAY": 5.7,
    "JUN": 5.59,
    "JUL": 5.73,
    "AUG": 6.32,
    "SEP": 6.36,
    "OCT": 5.58,
    "NOV": 5.03,
    "DEC": 4.94
  },
  {
    "LONG": -48.59,
    "LAT": -9.53,
    "CIDADE": "Miranorte",
    "UF": "TOCANTINS",
    "anual": 5.26,
    "JAN": 4.77,
    "FEB": 4.93,
    "MAR": 4.84,
    "APR": 5.11,
    "MAY": 5.43,
    "JUN": 5.56,
    "JUL": 5.73,
    "AUG": 6.36,
    "SEP": 5.85,
    "OCT": 5.08,
    "NOV": 4.78,
    "DEC": 4.66
  },
  {
    "LONG": -49.96,
    "LAT": -9.27,
    "CIDADE": "Caseara",
    "UF": "TOCANTINS",
    "anual": 5.18,
    "JAN": 4.82,
    "FEB": 4.86,
    "MAR": 4.85,
    "APR": 4.93,
    "MAY": 5.27,
    "JUN": 5.48,
    "JUL": 5.72,
    "AUG": 6.1,
    "SEP": 5.5,
    "OCT": 5.08,
    "NOV": 4.84,
    "DEC": 4.74
  },
  {
    "LONG": -49.06,
    "LAT": -9.26,
    "CIDADE": "Dois Irmãos do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.21,
    "JAN": 4.67,
    "FEB": 4.8,
    "MAR": 4.76,
    "APR": 4.93,
    "MAY": 5.41,
    "JUN": 5.56,
    "JUL": 5.76,
    "AUG": 6.3,
    "SEP": 5.74,
    "OCT": 5.11,
    "NOV": 4.81,
    "DEC": 4.62
  },
  {
    "LONG": -48.53,
    "LAT": -9.34,
    "CIDADE": "Rio dos Bois",
    "UF": "TOCANTINS",
    "anual": 5.23,
    "JAN": 4.7,
    "FEB": 4.9,
    "MAR": 4.85,
    "APR": 5.03,
    "MAY": 5.38,
    "JUN": 5.54,
    "JUL": 5.7,
    "AUG": 6.33,
    "SEP": 5.82,
    "OCT": 5.08,
    "NOV": 4.77,
    "DEC": 4.61
  },
  {
    "LONG": -47.89,
    "LAT": -9.35,
    "CIDADE": "Rio Sono",
    "UF": "TOCANTINS",
    "anual": 5.38,
    "JAN": 4.77,
    "FEB": 4.96,
    "MAR": 4.95,
    "APR": 5.24,
    "MAY": 5.57,
    "JUN": 5.68,
    "JUL": 5.82,
    "AUG": 6.53,
    "SEP": 6.05,
    "OCT": 5.3,
    "NOV": 4.9,
    "DEC": 4.77
  },
  {
    "LONG": -48.52,
    "LAT": -9.06,
    "CIDADE": "Fortaleza do Tabocão",
    "UF": "TOCANTINS",
    "anual": 5.21,
    "JAN": 4.66,
    "FEB": 4.88,
    "MAR": 4.82,
    "APR": 4.99,
    "MAY": 5.37,
    "JUN": 5.56,
    "JUL": 5.73,
    "AUG": 6.3,
    "SEP": 5.85,
    "OCT": 5.01,
    "NOV": 4.71,
    "DEC": 4.62
  },
  {
    "LONG": -48.17,
    "LAT": -8.96,
    "CIDADE": "Bom Jesus do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.3,
    "JAN": 4.82,
    "FEB": 5,
    "MAR": 4.96,
    "APR": 5.07,
    "MAY": 5.47,
    "JUN": 5.61,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 5.88,
    "OCT": 5.2,
    "NOV": 4.86,
    "DEC": 4.73
  },
  {
    "LONG": -48.17,
    "LAT": -8.97,
    "CIDADE": "Pedro Afonso",
    "UF": "TOCANTINS",
    "anual": 5.3,
    "JAN": 4.82,
    "FEB": 5,
    "MAR": 4.96,
    "APR": 5.07,
    "MAY": 5.47,
    "JUN": 5.61,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 5.88,
    "OCT": 5.2,
    "NOV": 4.86,
    "DEC": 4.73
  },
  {
    "LONG": -48.19,
    "LAT": -8.98,
    "CIDADE": "Tupirama",
    "UF": "TOCANTINS",
    "anual": 5.3,
    "JAN": 4.82,
    "FEB": 5,
    "MAR": 4.96,
    "APR": 5.07,
    "MAY": 5.47,
    "JUN": 5.61,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 5.88,
    "OCT": 5.2,
    "NOV": 4.86,
    "DEC": 4.73
  },
  {
    "LONG": -4.73,
    "LAT": -8.96,
    "CIDADE": "Centenário",
    "UF": "TOCANTINS",
    "anual": 5.35,
    "JAN": 4.81,
    "FEB": 4.85,
    "MAR": 4.84,
    "APR": 5.09,
    "MAY": 5.54,
    "JUN": 5.65,
    "JUL": 5.85,
    "AUG": 6.53,
    "SEP": 6.2,
    "OCT": 5.35,
    "NOV": 4.79,
    "DEC": 4.7
  },
  {
    "LONG": -49.56,
    "LAT": -0.88,
    "CIDADE": "Araguacema",
    "UF": "TOCANTINS",
    "anual": 5.17,
    "JAN": 4.65,
    "FEB": 4.84,
    "MAR": 4.82,
    "APR": 4.94,
    "MAY": 5.3,
    "JUN": 5.52,
    "JUL": 5.66,
    "AUG": 6.18,
    "SEP": 5.64,
    "OCT": 5,
    "NOV": 4.8,
    "DEC": 4.67
  },
  {
    "LONG": -4.89,
    "LAT": -8.77,
    "CIDADE": "Goianorte",
    "UF": "TOCANTINS",
    "anual": 5.18,
    "JAN": 4.61,
    "FEB": 4.78,
    "MAR": 4.71,
    "APR": 4.92,
    "MAY": 5.34,
    "JUN": 5.56,
    "JUL": 5.77,
    "AUG": 6.39,
    "SEP": 5.76,
    "OCT": 5.01,
    "NOV": 4.74,
    "DEC": 4.56
  },
  {
    "LONG": -48.51,
    "LAT": -8.84,
    "CIDADE": "Guaraí",
    "UF": "TOCANTINS",
    "anual": 5.15,
    "JAN": 4.61,
    "FEB": 4.78,
    "MAR": 4.78,
    "APR": 4.91,
    "MAY": 5.34,
    "JUN": 5.49,
    "JUL": 5.67,
    "AUG": 6.3,
    "SEP": 5.77,
    "OCT": 5.01,
    "NOV": 4.6,
    "DEC": 4.56
  },
  {
    "LONG": -47.75,
    "LAT": -0.88,
    "CIDADE": "Santa Maria do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.3,
    "JAN": 4.72,
    "FEB": 4.9,
    "MAR": 4.86,
    "APR": 5.08,
    "MAY": 5.48,
    "JUN": 5.69,
    "JUL": 5.72,
    "AUG": 6.41,
    "SEP": 6.05,
    "OCT": 5.25,
    "NOV": 4.72,
    "DEC": 4.71
  },
  {
    "LONG": -48.76,
    "LAT": -8.73,
    "CIDADE": "Colméia",
    "UF": "TOCANTINS",
    "anual": 5.13,
    "JAN": 4.56,
    "FEB": 4.67,
    "MAR": 4.65,
    "APR": 4.84,
    "MAY": 5.35,
    "JUN": 5.55,
    "JUL": 5.68,
    "AUG": 6.35,
    "SEP": 5.77,
    "OCT": 4.99,
    "NOV": 4.62,
    "DEC": 4.5
  },
  {
    "LONG": -47.24,
    "LAT": -8.74,
    "CIDADE": "Recursolândia",
    "UF": "TOCANTINS",
    "anual": 5.32,
    "JAN": 4.75,
    "FEB": 4.84,
    "MAR": 4.81,
    "APR": 5.09,
    "MAY": 5.61,
    "JUN": 5.69,
    "JUL": 5.79,
    "AUG": 6.44,
    "SEP": 6.13,
    "OCT": 5.29,
    "NOV": 4.77,
    "DEC": 4.67
  },
  {
    "LONG": -48.93,
    "LAT": -8.59,
    "CIDADE": "Pequizeiro",
    "UF": "TOCANTINS",
    "anual": 5.16,
    "JAN": 4.6,
    "FEB": 4.74,
    "MAR": 4.72,
    "APR": 4.86,
    "MAY": 5.31,
    "JUN": 5.63,
    "JUL": 5.77,
    "AUG": 6.32,
    "SEP": 5.74,
    "OCT": 4.98,
    "NOV": 4.69,
    "DEC": 4.49
  },
  {
    "LONG": -48.69,
    "LAT": -8.57,
    "CIDADE": "Itaporã do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.1,
    "JAN": 4.51,
    "FEB": 4.7,
    "MAR": 4.66,
    "APR": 4.85,
    "MAY": 5.33,
    "JUN": 5.61,
    "JUL": 5.65,
    "AUG": 6.25,
    "SEP": 5.73,
    "OCT": 4.91,
    "NOV": 4.56,
    "DEC": 4.45
  },
  {
    "LONG": -48.51,
    "LAT": -8.54,
    "CIDADE": "Presidente Kennedy",
    "UF": "TOCANTINS",
    "anual": 5.12,
    "JAN": 4.57,
    "FEB": 4.8,
    "MAR": 4.74,
    "APR": 4.9,
    "MAY": 5.31,
    "JUN": 5.61,
    "JUL": 5.65,
    "AUG": 6.16,
    "SEP": 5.72,
    "OCT": 4.95,
    "NOV": 4.59,
    "DEC": 4.49
  },
  {
    "LONG": -48.48,
    "LAT": -8.39,
    "CIDADE": "Brasilândia do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.13,
    "JAN": 4.59,
    "FEB": 4.78,
    "MAR": 4.74,
    "APR": 4.87,
    "MAY": 5.25,
    "JUN": 5.6,
    "JUL": 5.65,
    "AUG": 6.18,
    "SEP": 5.77,
    "OCT": 5.02,
    "NOV": 4.58,
    "DEC": 4.52
  },
  {
    "LONG": -48.13,
    "LAT": -8.4,
    "CIDADE": "Tupiratins",
    "UF": "TOCANTINS",
    "anual": 5.25,
    "JAN": 4.72,
    "FEB": 4.91,
    "MAR": 4.92,
    "APR": 5.09,
    "MAY": 5.45,
    "JUN": 5.62,
    "JUL": 5.56,
    "AUG": 6.31,
    "SEP": 5.9,
    "OCT": 5.2,
    "NOV": 4.69,
    "DEC": 4.63
  },
  {
    "LONG": -48.11,
    "LAT": -8.38,
    "CIDADE": "Itapiratins",
    "UF": "TOCANTINS",
    "anual": 5.25,
    "JAN": 4.72,
    "FEB": 4.91,
    "MAR": 4.92,
    "APR": 5.09,
    "MAY": 5.45,
    "JUN": 5.62,
    "JUL": 5.56,
    "AUG": 6.31,
    "SEP": 5.9,
    "OCT": 5.2,
    "NOV": 4.69,
    "DEC": 4.63
  },
  {
    "LONG": -4.78,
    "LAT": -8.39,
    "CIDADE": "Itacajá",
    "UF": "TOCANTINS",
    "anual": 5.26,
    "JAN": 4.74,
    "FEB": 4.83,
    "MAR": 4.85,
    "APR": 5.03,
    "MAY": 5.42,
    "JUN": 5.6,
    "JUL": 5.72,
    "AUG": 6.38,
    "SEP": 6.06,
    "OCT": 5.23,
    "NOV": 4.67,
    "DEC": 4.58
  },
  {
    "LONG": -4.92,
    "LAT": -8.28,
    "CIDADE": "Couto Magalhães",
    "UF": "TOCANTINS",
    "anual": 5.14,
    "JAN": 4.56,
    "FEB": 4.8,
    "MAR": 4.82,
    "APR": 4.88,
    "MAY": 5.24,
    "JUN": 5.55,
    "JUL": 5.65,
    "AUG": 6.18,
    "SEP": 5.73,
    "OCT": 5.04,
    "NOV": 4.68,
    "DEC": 4.52
  },
  {
    "LONG": -49.1,
    "LAT": -8.12,
    "CIDADE": "Juarina",
    "UF": "TOCANTINS",
    "anual": 5.06,
    "JAN": 4.46,
    "FEB": 4.66,
    "MAR": 4.7,
    "APR": 4.83,
    "MAY": 5.21,
    "JUN": 5.51,
    "JUL": 5.59,
    "AUG": 6.21,
    "SEP": 5.63,
    "OCT": 4.94,
    "NOV": 4.58,
    "DEC": 4.39
  },
  {
    "LONG": -48.48,
    "LAT": -8.06,
    "CIDADE": "Colinas do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.17,
    "JAN": 4.65,
    "FEB": 4.84,
    "MAR": 4.81,
    "APR": 4.99,
    "MAY": 5.27,
    "JUN": 5.52,
    "JUL": 5.62,
    "AUG": 6.29,
    "SEP": 5.82,
    "OCT": 5.03,
    "NOV": 4.65,
    "DEC": 4.52
  },
  {
    "LONG": -46.8,
    "LAT": -7.97,
    "CIDADE": "Campos Lindos",
    "UF": "TOCANTINS",
    "anual": 5.31,
    "JAN": 4.68,
    "FEB": 4.78,
    "MAR": 4.86,
    "APR": 5.11,
    "MAY": 5.49,
    "JUN": 5.72,
    "JUL": 5.78,
    "AUG": 6.22,
    "SEP": 6.34,
    "OCT": 5.38,
    "NOV": 4.73,
    "DEC": 4.59
  },
  {
    "LONG": -48.9,
    "LAT": -7.88,
    "CIDADE": "Bernardo Sayão",
    "UF": "TOCANTINS",
    "anual": 5.08,
    "JAN": 4.49,
    "FEB": 4.68,
    "MAR": 4.7,
    "APR": 4.86,
    "MAY": 5.28,
    "JUN": 5.52,
    "JUL": 5.58,
    "AUG": 6.19,
    "SEP": 5.72,
    "OCT": 5.01,
    "NOV": 4.58,
    "DEC": 4.4
  },
  {
    "LONG": -48.58,
    "LAT": -7.76,
    "CIDADE": "Bandeirantes do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.06,
    "JAN": 4.51,
    "FEB": 4.68,
    "MAR": 4.69,
    "APR": 4.83,
    "MAY": 5.25,
    "JUN": 5.51,
    "JUL": 5.54,
    "AUG": 6.18,
    "SEP": 5.73,
    "OCT": 4.93,
    "NOV": 4.52,
    "DEC": 4.33
  },
  {
    "LONG": -47.93,
    "LAT": -7.85,
    "CIDADE": "Palmeirante",
    "UF": "TOCANTINS",
    "anual": 5.21,
    "JAN": 4.62,
    "FEB": 4.81,
    "MAR": 4.93,
    "APR": 5.03,
    "MAY": 5.37,
    "JUN": 5.59,
    "JUL": 5.67,
    "AUG": 6.14,
    "SEP": 5.94,
    "OCT": 5.16,
    "NOV": 4.68,
    "DEC": 4.56
  },
  {
    "LONG": -49.06,
    "LAT": -0.77,
    "CIDADE": "Arapoema",
    "UF": "TOCANTINS",
    "anual": 5.03,
    "JAN": 4.47,
    "FEB": 4.69,
    "MAR": 4.67,
    "APR": 4.76,
    "MAY": 5.21,
    "JUN": 5.45,
    "JUL": 5.49,
    "AUG": 6.16,
    "SEP": 5.66,
    "OCT": 4.94,
    "NOV": 4.51,
    "DEC": 4.38
  },
  {
    "LONG": -4.77,
    "LAT": -7.7,
    "CIDADE": "Barra do Ouro",
    "UF": "TOCANTINS",
    "anual": 5.25,
    "JAN": 4.7,
    "FEB": 4.92,
    "MAR": 5.01,
    "APR": 5.07,
    "MAY": 5.42,
    "JUN": 5.61,
    "JUL": 5.59,
    "AUG": 6.15,
    "SEP": 6.02,
    "OCT": 5.21,
    "NOV": 4.74,
    "DEC": 4.57
  },
  {
    "LONG": -47.32,
    "LAT": -7.71,
    "CIDADE": "Goiatins",
    "UF": "TOCANTINS",
    "anual": 5.28,
    "JAN": 4.75,
    "FEB": 4.84,
    "MAR": 4.96,
    "APR": 5.08,
    "MAY": 5.46,
    "JUN": 5.68,
    "JUL": 5.7,
    "AUG": 6.29,
    "SEP": 6.13,
    "OCT": 5.21,
    "NOV": 4.71,
    "DEC": 4.53
  },
  {
    "LONG": -48.43,
    "LAT": -7.63,
    "CIDADE": "Nova Olinda",
    "UF": "TOCANTINS",
    "anual": 5.07,
    "JAN": 4.45,
    "FEB": 4.7,
    "MAR": 4.71,
    "APR": 4.9,
    "MAY": 5.21,
    "JUN": 5.56,
    "JUL": 5.58,
    "AUG": 6.2,
    "SEP": 5.77,
    "OCT": 4.92,
    "NOV": 4.5,
    "DEC": 4.38
  },
  {
    "LONG": -49.37,
    "LAT": -7.54,
    "CIDADE": "Pau D'Arco",
    "UF": "TOCANTINS",
    "anual": 5.07,
    "JAN": 4.57,
    "FEB": 4.79,
    "MAR": 4.69,
    "APR": 4.85,
    "MAY": 5.18,
    "JUN": 5.46,
    "JUL": 5.48,
    "AUG": 6.01,
    "SEP": 5.67,
    "OCT": 5.02,
    "NOV": 4.57,
    "DEC": 4.53
  },
  {
    "LONG": -47.5,
    "LAT": -7.34,
    "CIDADE": "Filadélfia",
    "UF": "TOCANTINS",
    "anual": 5.23,
    "JAN": 4.66,
    "FEB": 4.81,
    "MAR": 4.81,
    "APR": 4.99,
    "MAY": 5.39,
    "JUN": 5.73,
    "JUL": 5.66,
    "AUG": 6.19,
    "SEP": 6.09,
    "OCT": 5.24,
    "NOV": 4.65,
    "DEC": 4.54
  },
  {
    "LONG": -48.69,
    "LAT": -7.15,
    "CIDADE": "Santa Fé do Araguaia",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.47,
    "FEB": 4.7,
    "MAR": 4.63,
    "APR": 4.82,
    "MAY": 5.17,
    "JUN": 5.49,
    "JUL": 5.57,
    "AUG": 6.1,
    "SEP": 5.67,
    "OCT": 4.9,
    "NOV": 4.54,
    "DEC": 4.4
  },
  {
    "LONG": -48.53,
    "LAT": -7.17,
    "CIDADE": "Aragominas",
    "UF": "TOCANTINS",
    "anual": 5.03,
    "JAN": 4.45,
    "FEB": 4.66,
    "MAR": 4.58,
    "APR": 4.82,
    "MAY": 5.19,
    "JUN": 5.54,
    "JUL": 5.55,
    "AUG": 6.09,
    "SEP": 5.69,
    "OCT": 4.93,
    "NOV": 4.51,
    "DEC": 4.38
  },
  {
    "LONG": -48.2,
    "LAT": -7.19,
    "CIDADE": "Araguaína",
    "UF": "TOCANTINS",
    "anual": 5.07,
    "JAN": 4.46,
    "FEB": 4.72,
    "MAR": 4.6,
    "APR": 4.9,
    "MAY": 5.18,
    "JUN": 5.56,
    "JUL": 5.61,
    "AUG": 6.16,
    "SEP": 5.76,
    "OCT": 4.97,
    "NOV": 4.55,
    "DEC": 4.4
  },
  {
    "LONG": -47.76,
    "LAT": -7.2,
    "CIDADE": "Babaçulândia",
    "UF": "TOCANTINS",
    "anual": 5.24,
    "JAN": 4.67,
    "FEB": 4.84,
    "MAR": 4.94,
    "APR": 5.05,
    "MAY": 5.37,
    "JUN": 5.7,
    "JUL": 5.66,
    "AUG": 6.07,
    "SEP": 6.08,
    "OCT": 5.22,
    "NOV": 4.69,
    "DEC": 4.53
  },
  {
    "LONG": -48.61,
    "LAT": -7.15,
    "CIDADE": "Muricilândia",
    "UF": "TOCANTINS",
    "anual": 5.03,
    "JAN": 4.5,
    "FEB": 4.69,
    "MAR": 4.62,
    "APR": 4.85,
    "MAY": 5.15,
    "JUN": 5.45,
    "JUL": 5.55,
    "AUG": 6.09,
    "SEP": 5.66,
    "OCT": 4.9,
    "NOV": 4.51,
    "DEC": 4.41
  },
  {
    "LONG": -48.4,
    "LAT": -0.7,
    "CIDADE": "Carmolândia",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.57,
    "FEB": 4.69,
    "MAR": 4.59,
    "APR": 4.9,
    "MAY": 5.15,
    "JUN": 5.55,
    "JUL": 5.55,
    "AUG": 6.1,
    "SEP": 5.74,
    "OCT": 4.88,
    "NOV": 4.54,
    "DEC": 4.36
  },
  {
    "LONG": -47.96,
    "LAT": -6.85,
    "CIDADE": "Wanderlândia",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.42,
    "FEB": 4.6,
    "MAR": 4.63,
    "APR": 4.82,
    "MAY": 5.05,
    "JUN": 5.5,
    "JUL": 5.7,
    "AUG": 6.15,
    "SEP": 5.84,
    "OCT": 4.95,
    "NOV": 4.45,
    "DEC": 4.32
  },
  {
    "LONG": -48.29,
    "LAT": -6.77,
    "CIDADE": "Piraquê",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.53,
    "FEB": 4.66,
    "MAR": 4.65,
    "APR": 4.85,
    "MAY": 5.09,
    "JUN": 5.47,
    "JUL": 5.63,
    "AUG": 6.1,
    "SEP": 5.72,
    "OCT": 4.96,
    "NOV": 4.54,
    "DEC": 4.36
  },
  {
    "LONG": -47.75,
    "LAT": -6.71,
    "CIDADE": "Darcinópolis",
    "UF": "TOCANTINS",
    "anual": 5.12,
    "JAN": 4.53,
    "FEB": 4.71,
    "MAR": 4.76,
    "APR": 4.99,
    "MAY": 5.17,
    "JUN": 5.52,
    "JUL": 5.75,
    "AUG": 6.2,
    "SEP": 5.86,
    "OCT": 5.01,
    "NOV": 4.53,
    "DEC": 4.39
  },
  {
    "LONG": -48.64,
    "LAT": -6.58,
    "CIDADE": "Araguanã",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.58,
    "FEB": 4.72,
    "MAR": 4.76,
    "APR": 4.84,
    "MAY": 5.12,
    "JUN": 5.47,
    "JUL": 5.58,
    "AUG": 6.02,
    "SEP": 5.63,
    "OCT": 4.94,
    "NOV": 4.57,
    "DEC": 4.39
  },
  {
    "LONG": -47.55,
    "LAT": -6.61,
    "CIDADE": "Palmeiras do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.17,
    "JAN": 4.55,
    "FEB": 4.76,
    "MAR": 4.87,
    "APR": 5.02,
    "MAY": 5.22,
    "JUN": 5.61,
    "JUL": 5.74,
    "AUG": 6.15,
    "SEP": 5.9,
    "OCT": 5.1,
    "NOV": 4.61,
    "DEC": 4.53
  },
  {
    "LONG": -47.48,
    "LAT": -6.56,
    "CIDADE": "Aguiarnópolis",
    "UF": "TOCANTINS",
    "anual": 5.2,
    "JAN": 4.62,
    "FEB": 4.79,
    "MAR": 4.89,
    "APR": 5.04,
    "MAY": 5.17,
    "JUN": 5.55,
    "JUL": 5.73,
    "AUG": 6.1,
    "SEP": 5.95,
    "OCT": 5.25,
    "NOV": 4.69,
    "DEC": 4.56
  },
  {
    "LONG": -48.53,
    "LAT": -6.41,
    "CIDADE": "Xambioá",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.49,
    "FEB": 4.74,
    "MAR": 4.72,
    "APR": 4.89,
    "MAY": 5.11,
    "JUN": 5.47,
    "JUL": 5.53,
    "AUG": 5.94,
    "SEP": 5.66,
    "OCT": 4.97,
    "NOV": 4.56,
    "DEC": 4.39
  },
  {
    "LONG": -48.14,
    "LAT": -6.44,
    "CIDADE": "Riachinho",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.48,
    "FEB": 4.69,
    "MAR": 4.71,
    "APR": 4.85,
    "MAY": 5.11,
    "JUN": 5.52,
    "JUL": 5.61,
    "AUG": 6.02,
    "SEP": 5.67,
    "OCT": 4.94,
    "NOV": 4.52,
    "DEC": 4.37
  },
  {
    "LONG": -48.07,
    "LAT": -6.36,
    "CIDADE": "Ananás",
    "UF": "TOCANTINS",
    "anual": 4.99,
    "JAN": 4.35,
    "FEB": 4.6,
    "MAR": 4.62,
    "APR": 4.77,
    "MAY": 5.07,
    "JUN": 5.45,
    "JUL": 5.62,
    "AUG": 6.04,
    "SEP": 5.71,
    "OCT": 4.91,
    "NOV": 4.45,
    "DEC": 4.29
  },
  {
    "LONG": -47.86,
    "LAT": -6.39,
    "CIDADE": "Angico",
    "UF": "TOCANTINS",
    "anual": 5.06,
    "JAN": 4.43,
    "FEB": 4.71,
    "MAR": 4.74,
    "APR": 4.92,
    "MAY": 5.15,
    "JUN": 5.54,
    "JUL": 5.69,
    "AUG": 6.08,
    "SEP": 5.72,
    "OCT": 4.91,
    "NOV": 4.5,
    "DEC": 4.36
  },
  {
    "LONG": -47.66,
    "LAT": -6.38,
    "CIDADE": "Nazaré",
    "UF": "TOCANTINS",
    "anual": 5.12,
    "JAN": 4.47,
    "FEB": 4.75,
    "MAR": 4.79,
    "APR": 4.97,
    "MAY": 5.17,
    "JUN": 5.56,
    "JUL": 5.72,
    "AUG": 6.16,
    "SEP": 5.8,
    "OCT": 5,
    "NOV": 4.57,
    "DEC": 4.45
  },
  {
    "LONG": -47.67,
    "LAT": -6.44,
    "CIDADE": "Santa Terezinha do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.12,
    "JAN": 4.47,
    "FEB": 4.75,
    "MAR": 4.79,
    "APR": 4.97,
    "MAY": 5.17,
    "JUN": 5.56,
    "JUL": 5.72,
    "AUG": 6.16,
    "SEP": 5.8,
    "OCT": 5,
    "NOV": 4.57,
    "DEC": 4.45
  },
  {
    "LONG": -47.42,
    "LAT": -6.32,
    "CIDADE": "Tocantinópolis",
    "UF": "TOCANTINS",
    "anual": 5.18,
    "JAN": 4.58,
    "FEB": 4.78,
    "MAR": 4.89,
    "APR": 5.02,
    "MAY": 5.13,
    "JUN": 5.52,
    "JUL": 5.69,
    "AUG": 6.13,
    "SEP": 5.9,
    "OCT": 5.22,
    "NOV": 4.73,
    "DEC": 4.58
  },
  {
    "LONG": -47.86,
    "LAT": -6.19,
    "CIDADE": "Luzinópolis",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.43,
    "FEB": 4.68,
    "MAR": 4.74,
    "APR": 4.86,
    "MAY": 5.03,
    "JUN": 5.42,
    "JUL": 5.64,
    "AUG": 6.1,
    "SEP": 5.73,
    "OCT": 5.01,
    "NOV": 4.54,
    "DEC": 4.37
  },
  {
    "LONG": -47.92,
    "LAT": -0.61,
    "CIDADE": "Cachoeirinha",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.44,
    "FEB": 4.65,
    "MAR": 4.72,
    "APR": 4.84,
    "MAY": 5.03,
    "JUN": 5.4,
    "JUL": 5.57,
    "AUG": 6.09,
    "SEP": 5.7,
    "OCT": 5.01,
    "NOV": 4.62,
    "DEC": 4.45
  },
  {
    "LONG": -47.92,
    "LAT": -6.03,
    "CIDADE": "São Bento do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.4,
    "FEB": 4.64,
    "MAR": 4.73,
    "APR": 4.86,
    "MAY": 5.05,
    "JUN": 5.44,
    "JUL": 5.6,
    "AUG": 6.1,
    "SEP": 5.66,
    "OCT": 4.97,
    "NOV": 4.59,
    "DEC": 4.46
  },
  {
    "LONG": "#VALOR!",
    "LAT": -5.95,
    "CIDADE": "Maurilândia do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.14,
    "JAN": 4.52,
    "FEB": 4.75,
    "MAR": 4.81,
    "APR": 4.95,
    "MAY": 5.14,
    "JUN": 5.48,
    "JUL": 5.66,
    "AUG": 6.12,
    "SEP": 5.79,
    "OCT": 5.19,
    "NOV": 4.75,
    "DEC": 4.54
  },
  {
    "LONG": -47.48,
    "LAT": -5.78,
    "CIDADE": "Itaguatins",
    "UF": "TOCANTINS",
    "anual": 5.1,
    "JAN": 4.5,
    "FEB": 4.64,
    "MAR": 4.77,
    "APR": 4.92,
    "MAY": 5.08,
    "JUN": 5.42,
    "JUL": 5.6,
    "AUG": 6.02,
    "SEP": 5.64,
    "OCT": 5.18,
    "NOV": 4.78,
    "DEC": 4.59
  },
  {
    "LONG": -48.12,
    "LAT": -0.56,
    "CIDADE": "Araguatins",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.45,
    "FEB": 4.66,
    "MAR": 4.88,
    "APR": 4.99,
    "MAY": 5.03,
    "JUN": 5.38,
    "JUL": 5.47,
    "AUG": 5.92,
    "SEP": 5.51,
    "OCT": 4.97,
    "NOV": 4.7,
    "DEC": 4.51
  },
  {
    "LONG": -47.77,
    "LAT": -0.56,
    "CIDADE": "Axixá do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.41,
    "FEB": 4.63,
    "MAR": 4.78,
    "APR": 4.92,
    "MAY": 4.99,
    "JUN": 5.37,
    "JUL": 5.53,
    "AUG": 5.97,
    "SEP": 5.59,
    "OCT": 5.04,
    "NOV": 4.73,
    "DEC": 4.49
  },
  {
    "LONG": -47.64,
    "LAT": -5.6,
    "CIDADE": "Sítio Novo do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.43,
    "FEB": 4.64,
    "MAR": 4.8,
    "APR": 4.92,
    "MAY": 5.02,
    "JUN": 5.39,
    "JUL": 5.52,
    "AUG": 5.93,
    "SEP": 5.59,
    "OCT": 5.05,
    "NOV": 4.73,
    "DEC": 4.53
  },
  {
    "LONG": -47.58,
    "LAT": -5.55,
    "CIDADE": "São Miguel do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.06,
    "JAN": 4.46,
    "FEB": 4.66,
    "MAR": 4.83,
    "APR": 4.93,
    "MAY": 5.03,
    "JUN": 5.39,
    "JUL": 5.5,
    "AUG": 5.89,
    "SEP": 5.61,
    "OCT": 5.09,
    "NOV": 4.76,
    "DEC": 4.56
  },
  {
    "LONG": -47.89,
    "LAT": -0.55,
    "CIDADE": "Augustinópolis",
    "UF": "TOCANTINS",
    "anual": 5.06,
    "JAN": 4.46,
    "FEB": 4.72,
    "MAR": 4.85,
    "APR": 4.99,
    "MAY": 5.02,
    "JUN": 5.41,
    "JUL": 5.51,
    "AUG": 5.9,
    "SEP": 5.57,
    "OCT": 5.06,
    "NOV": 4.75,
    "DEC": 4.55
  },
  {
    "LONG": -48.54,
    "LAT": -5.37,
    "CIDADE": "Esperantina",
    "UF": "TOCANTINS",
    "anual": 5,
    "JAN": 4.4,
    "FEB": 4.66,
    "MAR": 4.79,
    "APR": 4.96,
    "MAY": 5.01,
    "JUN": 5.39,
    "JUL": 5.45,
    "AUG": 5.88,
    "SEP": 5.51,
    "OCT": 4.88,
    "NOV": 4.71,
    "DEC": 4.41
  },
  {
    "LONG": -47.81,
    "LAT": -5.39,
    "CIDADE": "Praia Norte",
    "UF": "TOCANTINS",
    "anual": 5.09,
    "JAN": 4.47,
    "FEB": 4.71,
    "MAR": 4.91,
    "APR": 5.05,
    "MAY": 5,
    "JUN": 5.4,
    "JUL": 5.51,
    "AUG": 5.89,
    "SEP": 5.62,
    "OCT": 5.15,
    "NOV": 4.78,
    "DEC": 4.58
  },
  {
    "LONG": -47.88,
    "LAT": -5.35,
    "CIDADE": "Sampaio",
    "UF": "TOCANTINS",
    "anual": 5.09,
    "JAN": 4.47,
    "FEB": 4.71,
    "MAR": 4.91,
    "APR": 5.05,
    "MAY": 5,
    "JUN": 5.4,
    "JUL": 5.51,
    "AUG": 5.89,
    "SEP": 5.62,
    "OCT": 5.15,
    "NOV": 4.78,
    "DEC": 4.58
  },
  {
    "LONG": -48.2,
    "LAT": -5.26,
    "CIDADE": "São Sebastião do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.03,
    "JAN": 4.41,
    "FEB": 4.64,
    "MAR": 4.82,
    "APR": 4.97,
    "MAY": 5,
    "JUN": 5.4,
    "JUL": 5.48,
    "AUG": 5.89,
    "SEP": 5.55,
    "OCT": 4.94,
    "NOV": 4.73,
    "DEC": 4.48
  },
  {
    "LONG": -48.23,
    "LAT": -5.31,
    "CIDADE": "Buriti do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.03,
    "JAN": 4.41,
    "FEB": 4.64,
    "MAR": 4.82,
    "APR": 4.97,
    "MAY": 5,
    "JUN": 5.4,
    "JUL": 5.48,
    "AUG": 5.89,
    "SEP": 5.55,
    "OCT": 4.94,
    "NOV": 4.73,
    "DEC": 4.48
  },
  {
    "LONG": -48.04,
    "LAT": -5.32,
    "CIDADE": "Carrasco Bonito",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.47,
    "FEB": 4.64,
    "MAR": 4.86,
    "APR": 5.03,
    "MAY": 5.04,
    "JUN": 5.39,
    "JUL": 5.49,
    "AUG": 5.87,
    "SEP": 5.58,
    "OCT": 5.03,
    "NOV": 4.75,
    "DEC": 4.51
  }
]

  var yearsArray = [];
  var makesArray = [];
  var cidadesorder = new Array();
  var radiacao = 0;
  var $yearDropDown = $("#DropDown_Year");
  var $makeDropDown = $("#DropDown_Make");
  var  $radiacao = $("#radiacao_cidade");
  $yearDropDown.empty();
  $makeDropDown.empty();

  // adding unique years to yearsArray
  $.each(ProductInfo, function (index) {
      var year = ProductInfo[index].UF;
      if ($.inArray(year, yearsArray) == -1) {
          yearsArray.push(year);
      }
  });
  yearsArray.sort();
  // append the years to select
  $.each(yearsArray, function (i) {
      $yearDropDown.append('<option  value="' + yearsArray[i] + '">' + yearsArray[i] + '</option>');
  });


  }

  function cidade(){
  var ProductInfo = [
  {
    "LONG": -68.75,
    "LAT": -11.01,
    "CIDADE": "Brasiléia",
    "UF": "ACRE",
    "anual": 4.68,
    "JAN": 4.38,
    "FEB": 4.5,
    "MAR": 4.28,
    "APR": 4.78,
    "MAY": 4.28,
    "JUN": 4.52,
    "JUL": 4.71,
    "AUG": 5.18,
    "SEP": 5.19,
    "OCT": 4.97,
    "NOV": 4.87,
    "DEC": 4.44
  },
  {
    "LONG": -68.74,
    "LAT": -11.03,
    "CIDADE": "Epitaciolândia",
    "UF": "ACRE",
    "anual": 4.68,
    "JAN": 4.38,
    "FEB": 4.5,
    "MAR": 4.28,
    "APR": 4.78,
    "MAY": 4.28,
    "JUN": 4.52,
    "JUL": 4.71,
    "AUG": 5.18,
    "SEP": 5.19,
    "OCT": 4.97,
    "NOV": 4.87,
    "DEC": 4.44
  },
  {
    "LONG": -69.57,
    "LAT": -10.94,
    "CIDADE": "Assis Brasil",
    "UF": "ACRE",
    "anual": 4.67,
    "JAN": 4.4,
    "FEB": 4.51,
    "MAR": 4.25,
    "APR": 4.74,
    "MAY": 4.24,
    "JUN": 4.45,
    "JUL": 4.71,
    "AUG": 5.28,
    "SEP": 5.23,
    "OCT": 4.96,
    "NOV": 4.82,
    "DEC": 4.44
  },
  {
    "LONG": -68.5,
    "LAT": -1.07,
    "CIDADE": "Xapuri",
    "UF": "ACRE",
    "anual": 4.66,
    "JAN": 4.36,
    "FEB": 4.44,
    "MAR": 4.3,
    "APR": 4.79,
    "MAY": 4.28,
    "JUN": 4.56,
    "JUL": 4.75,
    "AUG": 5.21,
    "SEP": 5.15,
    "OCT": 4.94,
    "NOV": 4.8,
    "DEC": 4.37
  },
  {
    "LONG": -67.68,
    "LAT": -10.57,
    "CIDADE": "Capixaba",
    "UF": "ACRE",
    "anual": 4.59,
    "JAN": 4.23,
    "FEB": 4.39,
    "MAR": 4.28,
    "APR": 4.59,
    "MAY": 4.27,
    "JUN": 4.39,
    "JUL": 4.63,
    "AUG": 5.13,
    "SEP": 5.15,
    "OCT": 5.01,
    "NOV": 4.68,
    "DEC": 4.36
  },
  {
    "LONG": -6.72,
    "LAT": -10.33,
    "CIDADE": "Plácido de Castro",
    "UF": "ACRE",
    "anual": 4.57,
    "JAN": 4.23,
    "FEB": 4.35,
    "MAR": 4.28,
    "APR": 4.57,
    "MAY": 4.28,
    "JUN": 4.5,
    "JUL": 4.7,
    "AUG": 5.12,
    "SEP": 4.93,
    "OCT": 4.95,
    "NOV": 4.66,
    "DEC": 4.33
  },
  {
    "LONG": -67.74,
    "LAT": -10.15,
    "CIDADE": "Senador Guiomard",
    "UF": "ACRE",
    "anual": 4.61,
    "JAN": 4.24,
    "FEB": 4.46,
    "MAR": 4.23,
    "APR": 4.6,
    "MAY": 4.24,
    "JUN": 4.48,
    "JUL": 4.66,
    "AUG": 5.16,
    "SEP": 5.17,
    "OCT": 5.01,
    "NOV": 4.71,
    "DEC": 4.32
  },
  {
    "LONG": -67.05,
    "LAT": -10.08,
    "CIDADE": "Acrelândia",
    "UF": "ACRE",
    "anual": 4.56,
    "JAN": 4.27,
    "FEB": 4.39,
    "MAR": 4.26,
    "APR": 4.55,
    "MAY": 4.26,
    "JUN": 4.48,
    "JUL": 4.67,
    "AUG": 5.09,
    "SEP": 4.99,
    "OCT": 4.89,
    "NOV": 4.63,
    "DEC": 4.28
  },
  {
    "LONG": -67.83,
    "LAT": -9.98,
    "CIDADE": "Rio Branco",
    "UF": "ACRE",
    "anual": 4.61,
    "JAN": 4.22,
    "FEB": 4.47,
    "MAR": 4.23,
    "APR": 4.59,
    "MAY": 4.24,
    "JUN": 4.48,
    "JUL": 4.64,
    "AUG": 5.15,
    "SEP": 5.18,
    "OCT": 4.98,
    "NOV": 4.77,
    "DEC": 4.31
  },
  {
    "LONG": -67.95,
    "LAT": -0.98,
    "CIDADE": "Bujari",
    "UF": "ACRE",
    "anual": 4.61,
    "JAN": 4.26,
    "FEB": 4.49,
    "MAR": 4.24,
    "APR": 4.58,
    "MAY": 4.3,
    "JUN": 4.5,
    "JUL": 4.69,
    "AUG": 5.17,
    "SEP": 5.1,
    "OCT": 5.01,
    "NOV": 4.73,
    "DEC": 4.3
  },
  {
    "LONG": -67.54,
    "LAT": -9.59,
    "CIDADE": "Porto Acre",
    "UF": "ACRE",
    "anual": 4.59,
    "JAN": 4.18,
    "FEB": 4.42,
    "MAR": 4.29,
    "APR": 4.55,
    "MAY": 4.26,
    "JUN": 4.5,
    "JUL": 4.7,
    "AUG": 5.22,
    "SEP": 5.08,
    "OCT": 4.95,
    "NOV": 4.67,
    "DEC": 4.31
  },
  {
    "LONG": -70.49,
    "LAT": -9.45,
    "CIDADE": "Santa Rosa do Purus",
    "UF": "ACRE",
    "anual": 4.64,
    "JAN": 4.34,
    "FEB": 4.57,
    "MAR": 4.19,
    "APR": 4.6,
    "MAY": 4.37,
    "JUN": 4.47,
    "JUL": 4.74,
    "AUG": 5.31,
    "SEP": 5.14,
    "OCT": 4.89,
    "NOV": 4.71,
    "DEC": 4.31
  },
  {
    "LONG": -71.95,
    "LAT": -9.2,
    "CIDADE": "Jordão",
    "UF": "ACRE",
    "anual": 4.68,
    "JAN": 4.45,
    "FEB": 4.67,
    "MAR": 4.13,
    "APR": 4.66,
    "MAY": 4.41,
    "JUN": 4.51,
    "JUL": 4.72,
    "AUG": 5.32,
    "SEP": 5.24,
    "OCT": 4.92,
    "NOV": 4.77,
    "DEC": 4.4
  },
  {
    "LONG": -68.67,
    "LAT": -9.06,
    "CIDADE": "Sena Madureira",
    "UF": "ACRE",
    "anual": 4.58,
    "JAN": 4.25,
    "FEB": 4.49,
    "MAR": 4.29,
    "APR": 4.46,
    "MAY": 4.22,
    "JUN": 4.43,
    "JUL": 4.64,
    "AUG": 5.2,
    "SEP": 5.1,
    "OCT": 4.89,
    "NOV": 4.65,
    "DEC": 4.31
  },
  {
    "LONG": -72.8,
    "LAT": -8.94,
    "CIDADE": "Marechal Thaumaturgo",
    "UF": "ACRE",
    "anual": 4.74,
    "JAN": 4.48,
    "FEB": 4.8,
    "MAR": 4.19,
    "APR": 4.67,
    "MAY": 4.54,
    "JUN": 4.49,
    "JUL": 4.69,
    "AUG": 5.32,
    "SEP": 5.4,
    "OCT": 5.03,
    "NOV": 4.83,
    "DEC": 4.47
  },
  {
    "LONG": -69.27,
    "LAT": -8.83,
    "CIDADE": "Manoel Urbano",
    "UF": "ACRE",
    "anual": 4.59,
    "JAN": 4.24,
    "FEB": 4.57,
    "MAR": 4.22,
    "APR": 4.53,
    "MAY": 4.22,
    "JUN": 4.44,
    "JUL": 4.71,
    "AUG": 5.27,
    "SEP": 5.18,
    "OCT": 4.89,
    "NOV": 4.58,
    "DEC": 4.26
  },
  {
    "LONG": -72.75,
    "LAT": -8.26,
    "CIDADE": "Porto Walter",
    "UF": "ACRE",
    "anual": 4.76,
    "JAN": 4.56,
    "FEB": 4.76,
    "MAR": 4.26,
    "APR": 4.68,
    "MAY": 4.56,
    "JUN": 4.5,
    "JUL": 4.73,
    "AUG": 5.37,
    "SEP": 5.36,
    "OCT": 5.03,
    "NOV": 4.84,
    "DEC": 4.52
  },
  {
    "LONG": -70.77,
    "LAT": -8.16,
    "CIDADE": "Tarauacá",
    "UF": "ACRE",
    "anual": 4.63,
    "JAN": 4.34,
    "FEB": 4.68,
    "MAR": 4.24,
    "APR": 4.54,
    "MAY": 4.32,
    "JUN": 4.42,
    "JUL": 4.64,
    "AUG": 5.24,
    "SEP": 5.25,
    "OCT": 5.01,
    "NOV": 4.64,
    "DEC": 4.29
  },
  {
    "LONG": -70.35,
    "LAT": -8.16,
    "CIDADE": "Feijó",
    "UF": "ACRE",
    "anual": 4.59,
    "JAN": 4.3,
    "FEB": 4.68,
    "MAR": 4.21,
    "APR": 4.52,
    "MAY": 4.22,
    "JUN": 4.39,
    "JUL": 4.57,
    "AUG": 5.18,
    "SEP": 5.14,
    "OCT": 4.98,
    "NOV": 4.58,
    "DEC": 4.27
  },
  {
    "LONG": -72.65,
    "LAT": -7.73,
    "CIDADE": "Rodrigues Alves",
    "UF": "ACRE",
    "anual": 4.71,
    "JAN": 4.55,
    "FEB": 4.8,
    "MAR": 4.25,
    "APR": 4.62,
    "MAY": 4.51,
    "JUN": 4.47,
    "JUL": 4.62,
    "AUG": 5.26,
    "SEP": 5.25,
    "OCT": 4.93,
    "NOV": 4.76,
    "DEC": 4.47
  },
  {
    "LONG": -72.9,
    "LAT": -0.76,
    "CIDADE": "Mâncio Lima",
    "UF": "ACRE",
    "anual": 4.73,
    "JAN": 4.54,
    "FEB": 4.79,
    "MAR": 4.34,
    "APR": 4.62,
    "MAY": 4.51,
    "JUN": 4.45,
    "JUL": 4.6,
    "AUG": 5.31,
    "SEP": 5.25,
    "OCT": 5.01,
    "NOV": 4.8,
    "DEC": 4.52
  },
  {
    "LONG": -72.68,
    "LAT": -0.76,
    "CIDADE": "Cruzeiro do Sul",
    "UF": "ACRE",
    "anual": 4.69,
    "JAN": 4.54,
    "FEB": 4.78,
    "MAR": 4.25,
    "APR": 4.58,
    "MAY": 4.51,
    "JUN": 4.44,
    "JUL": 4.64,
    "AUG": 5.2,
    "SEP": 5.18,
    "OCT": 4.92,
    "NOV": 4.75,
    "DEC": 4.47
  },
  {
    "LONG": -36.43,
    "LAT": -10.41,
    "CIDADE": "Piaçabuçu",
    "UF": "ALAGOAS",
    "anual": 5.45,
    "JAN": 5.76,
    "FEB": 5.95,
    "MAR": 6.08,
    "APR": 5.27,
    "MAY": 4.71,
    "JUN": 4.5,
    "JUL": 4.51,
    "AUG": 5.14,
    "SEP": 5.62,
    "OCT": 5.86,
    "NOV": 6,
    "DEC": 6
  },
  {
    "LONG": -36.58,
    "LAT": -10.29,
    "CIDADE": "Penedo",
    "UF": "ALAGOAS",
    "anual": 5.35,
    "JAN": 5.71,
    "FEB": 5.73,
    "MAR": 5.91,
    "APR": 5.27,
    "MAY": 4.63,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 4.99,
    "SEP": 5.52,
    "OCT": 5.71,
    "NOV": 5.94,
    "DEC": 5.85
  },
  {
    "LONG": -36.3,
    "LAT": -10.29,
    "CIDADE": "Feliz Deserto",
    "UF": "ALAGOAS",
    "anual": 5.44,
    "JAN": 5.86,
    "FEB": 5.99,
    "MAR": 6.07,
    "APR": 5.26,
    "MAY": 4.71,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 5.05,
    "SEP": 5.6,
    "OCT": 5.87,
    "NOV": 5.99,
    "DEC": 6.01
  },
  {
    "LONG": -36.84,
    "LAT": -10.19,
    "CIDADE": "Porto Real do Colégio",
    "UF": "ALAGOAS",
    "anual": 5.44,
    "JAN": 5.93,
    "FEB": 5.9,
    "MAR": 6.01,
    "APR": 5.47,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.5,
    "AUG": 4.98,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.99,
    "DEC": 5.94
  },
  {
    "LONG": -36.9,
    "LAT": -10.12,
    "CIDADE": "São Brás",
    "UF": "ALAGOAS",
    "anual": 5.41,
    "JAN": 5.83,
    "FEB": 5.8,
    "MAR": 5.94,
    "APR": 5.43,
    "MAY": 4.69,
    "JUN": 4.51,
    "JUL": 4.53,
    "AUG": 5.03,
    "SEP": 5.63,
    "OCT": 5.7,
    "NOV": 5.94,
    "DEC": 5.84
  },
  {
    "LONG": -36.81,
    "LAT": -10.06,
    "CIDADE": "Olho d'Água Grande",
    "UF": "ALAGOAS",
    "anual": 5.33,
    "JAN": 5.7,
    "FEB": 5.7,
    "MAR": 5.81,
    "APR": 5.37,
    "MAY": 4.64,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 4.99,
    "SEP": 5.54,
    "OCT": 5.62,
    "NOV": 5.86,
    "DEC": 5.76
  },
  {
    "LONG": -36.66,
    "LAT": -10.13,
    "CIDADE": "Igreja Nova",
    "UF": "ALAGOAS",
    "anual": 5.27,
    "JAN": 5.61,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.22,
    "MAY": 4.57,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 4.89,
    "SEP": 5.46,
    "OCT": 5.6,
    "NOV": 5.87,
    "DEC": 5.75
  },
  {
    "LONG": -3.62,
    "LAT": -10.13,
    "CIDADE": "Coruripe",
    "UF": "ALAGOAS",
    "anual": 5.47,
    "JAN": 5.75,
    "FEB": 5.97,
    "MAR": 6.01,
    "APR": 5.38,
    "MAY": 4.79,
    "JUN": 4.51,
    "JUL": 4.57,
    "AUG": 5.18,
    "SEP": 5.65,
    "OCT": 5.87,
    "NOV": 5.99,
    "DEC": 5.99
  },
  {
    "LONG": -37,
    "LAT": -9.96,
    "CIDADE": "Traipu",
    "UF": "ALAGOAS",
    "anual": 5.47,
    "JAN": 5.9,
    "FEB": 5.84,
    "MAR": 6.01,
    "APR": 5.5,
    "MAY": 4.71,
    "JUN": 4.52,
    "JUL": 4.56,
    "AUG": 5.13,
    "SEP": 5.77,
    "OCT": 5.78,
    "NOV": 6.03,
    "DEC": 5.88
  },
  {
    "LONG": -36.79,
    "LAT": -9.96,
    "CIDADE": "Campo Grande",
    "UF": "ALAGOAS",
    "anual": 5.26,
    "JAN": 5.55,
    "FEB": 5.61,
    "MAR": 5.78,
    "APR": 5.24,
    "MAY": 4.54,
    "JUN": 4.36,
    "JUL": 4.4,
    "AUG": 4.87,
    "SEP": 5.48,
    "OCT": 5.6,
    "NOV": 5.87,
    "DEC": 5.76
  },
  {
    "LONG": -36.01,
    "LAT": -10.01,
    "CIDADE": "Jequiá da Praia",
    "UF": "ALAGOAS",
    "anual": 5.46,
    "JAN": 5.76,
    "FEB": 5.96,
    "MAR": 6.06,
    "APR": 5.4,
    "MAY": 4.73,
    "JUN": 4.44,
    "JUL": 4.51,
    "AUG": 5.1,
    "SEP": 5.66,
    "OCT": 5.89,
    "NOV": 5.99,
    "DEC": 6.03
  },
  {
    "LONG": -36.83,
    "LAT": -9.88,
    "CIDADE": "Girau do Ponciano",
    "UF": "ALAGOAS",
    "anual": 5.29,
    "JAN": 5.65,
    "FEB": 5.62,
    "MAR": 5.85,
    "APR": 5.3,
    "MAY": 4.57,
    "JUN": 4.34,
    "JUL": 4.42,
    "AUG": 4.93,
    "SEP": 5.53,
    "OCT": 5.63,
    "NOV": 5.91,
    "DEC": 5.78
  },
  {
    "LONG": -3.67,
    "LAT": -9.9,
    "CIDADE": "Feira Grande",
    "UF": "ALAGOAS",
    "anual": 5.25,
    "JAN": 5.6,
    "FEB": 5.59,
    "MAR": 5.77,
    "APR": 5.27,
    "MAY": 4.57,
    "JUN": 4.31,
    "JUL": 4.34,
    "AUG": 4.84,
    "SEP": 5.47,
    "OCT": 5.55,
    "NOV": 5.88,
    "DEC": 5.78
  },
  {
    "LONG": -36.55,
    "LAT": -9.93,
    "CIDADE": "São Sebastião",
    "UF": "ALAGOAS",
    "anual": 5.26,
    "JAN": 5.66,
    "FEB": 5.61,
    "MAR": 5.81,
    "APR": 5.27,
    "MAY": 4.61,
    "JUN": 4.34,
    "JUL": 4.28,
    "AUG": 4.88,
    "SEP": 5.44,
    "OCT": 5.57,
    "NOV": 5.88,
    "DEC": 5.77
  },
  {
    "LONG": -36.48,
    "LAT": -9.91,
    "CIDADE": "Junqueiro",
    "UF": "ALAGOAS",
    "anual": 5.24,
    "JAN": 5.63,
    "FEB": 5.61,
    "MAR": 5.81,
    "APR": 5.28,
    "MAY": 4.58,
    "JUN": 4.28,
    "JUL": 4.29,
    "AUG": 4.79,
    "SEP": 5.42,
    "OCT": 5.54,
    "NOV": 5.86,
    "DEC": 5.76
  },
  {
    "LONG": -3.64,
    "LAT": -9.91,
    "CIDADE": "Teotônio Vilela",
    "UF": "ALAGOAS",
    "anual": 5.23,
    "JAN": 5.6,
    "FEB": 5.61,
    "MAR": 5.82,
    "APR": 5.25,
    "MAY": 4.59,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 4.82,
    "SEP": 5.38,
    "OCT": 5.52,
    "NOV": 5.86,
    "DEC": 5.75
  },
  {
    "LONG": -37.28,
    "LAT": -9.82,
    "CIDADE": "Belo Monte",
    "UF": "ALAGOAS",
    "anual": 5.38,
    "JAN": 5.74,
    "FEB": 5.75,
    "MAR": 5.91,
    "APR": 5.42,
    "MAY": 4.67,
    "JUN": 4.42,
    "JUL": 4.48,
    "AUG": 5.04,
    "SEP": 5.63,
    "OCT": 5.66,
    "NOV": 6,
    "DEC": 5.82
  },
  {
    "LONG": -36.74,
    "LAT": -9.83,
    "CIDADE": "Lagoa da Canoa",
    "UF": "ALAGOAS",
    "anual": 5.32,
    "JAN": 5.7,
    "FEB": 5.64,
    "MAR": 5.86,
    "APR": 5.32,
    "MAY": 4.55,
    "JUN": 4.32,
    "JUL": 4.44,
    "AUG": 4.92,
    "SEP": 5.52,
    "OCT": 5.67,
    "NOV": 5.98,
    "DEC": 5.85
  },
  {
    "LONG": -36.66,
    "LAT": -9.76,
    "CIDADE": "Arapiraca",
    "UF": "ALAGOAS",
    "anual": 5.29,
    "JAN": 5.68,
    "FEB": 5.62,
    "MAR": 5.84,
    "APR": 5.3,
    "MAY": 4.55,
    "JUN": 4.3,
    "JUL": 4.39,
    "AUG": 4.88,
    "SEP": 5.47,
    "OCT": 5.62,
    "NOV": 5.95,
    "DEC": 5.85
  },
  {
    "LONG": -36.35,
    "LAT": -9.78,
    "CIDADE": "Campo Alegre",
    "UF": "ALAGOAS",
    "anual": 5.24,
    "JAN": 5.6,
    "FEB": 5.63,
    "MAR": 5.85,
    "APR": 5.26,
    "MAY": 4.54,
    "JUN": 4.23,
    "JUL": 4.34,
    "AUG": 4.8,
    "SEP": 5.38,
    "OCT": 5.55,
    "NOV": 5.84,
    "DEC": 5.83
  },
  {
    "LONG": -36.1,
    "LAT": -9.78,
    "CIDADE": "São Miguel dos Campos",
    "UF": "ALAGOAS",
    "anual": 5.32,
    "JAN": 5.65,
    "FEB": 5.77,
    "MAR": 5.95,
    "APR": 5.23,
    "MAY": 4.64,
    "JUN": 4.31,
    "JUL": 4.37,
    "AUG": 4.85,
    "SEP": 5.51,
    "OCT": 5.7,
    "NOV": 5.98,
    "DEC": 5.87
  },
  {
    "LONG": -3.59,
    "LAT": -9.84,
    "CIDADE": "Barra de São Miguel",
    "UF": "ALAGOAS",
    "anual": 5.4,
    "JAN": 5.76,
    "FEB": 5.9,
    "MAR": 6.03,
    "APR": 5.35,
    "MAY": 4.68,
    "JUN": 4.34,
    "JUL": 4.49,
    "AUG": 5.01,
    "SEP": 5.57,
    "OCT": 5.76,
    "NOV": 5.98,
    "DEC": 5.94
  },
  {
    "LONG": -35.98,
    "LAT": -9.84,
    "CIDADE": "Roteiro",
    "UF": "ALAGOAS",
    "anual": 5.4,
    "JAN": 5.76,
    "FEB": 5.9,
    "MAR": 6.03,
    "APR": 5.35,
    "MAY": 4.68,
    "JUN": 4.34,
    "JUL": 4.49,
    "AUG": 5.01,
    "SEP": 5.57,
    "OCT": 5.76,
    "NOV": 5.98,
    "DEC": 5.94
  },
  {
    "LONG": -37.44,
    "LAT": -9.74,
    "CIDADE": "Pão de Açúcar",
    "UF": "ALAGOAS",
    "anual": 5.45,
    "JAN": 5.83,
    "FEB": 5.87,
    "MAR": 6,
    "APR": 5.54,
    "MAY": 4.74,
    "JUN": 4.48,
    "JUL": 4.55,
    "AUG": 5.1,
    "SEP": 5.64,
    "OCT": 5.72,
    "NOV": 6.08,
    "DEC": 5.86
  },
  {
    "LONG": -37.34,
    "LAT": -9.67,
    "CIDADE": "Palestina",
    "UF": "ALAGOAS",
    "anual": 5.34,
    "JAN": 5.71,
    "FEB": 5.71,
    "MAR": 5.91,
    "APR": 5.4,
    "MAY": 4.69,
    "JUN": 4.35,
    "JUL": 4.44,
    "AUG": 4.9,
    "SEP": 5.53,
    "OCT": 5.62,
    "NOV": 6.03,
    "DEC": 5.82
  },
  {
    "LONG": -37.13,
    "LAT": -9.67,
    "CIDADE": "Batalha",
    "UF": "ALAGOAS",
    "anual": 5.31,
    "JAN": 5.71,
    "FEB": 5.59,
    "MAR": 5.82,
    "APR": 5.36,
    "MAY": 4.57,
    "JUN": 4.31,
    "JUL": 4.41,
    "AUG": 4.96,
    "SEP": 5.57,
    "OCT": 5.59,
    "NOV": 5.99,
    "DEC": 5.83
  },
  {
    "LONG": -37,
    "LAT": -9.66,
    "CIDADE": "Jaramataia",
    "UF": "ALAGOAS",
    "anual": 5.27,
    "JAN": 5.67,
    "FEB": 5.54,
    "MAR": 5.79,
    "APR": 5.28,
    "MAY": 4.53,
    "JUN": 4.31,
    "JUL": 4.36,
    "AUG": 4.88,
    "SEP": 5.49,
    "OCT": 5.57,
    "NOV": 6.01,
    "DEC": 5.78
  },
  {
    "LONG": -36.5,
    "LAT": -9.74,
    "CIDADE": "Limoeiro de Anadia",
    "UF": "ALAGOAS",
    "anual": 5.29,
    "JAN": 5.64,
    "FEB": 5.61,
    "MAR": 5.83,
    "APR": 5.29,
    "MAY": 4.54,
    "JUN": 4.27,
    "JUL": 4.35,
    "AUG": 4.9,
    "SEP": 5.52,
    "OCT": 5.62,
    "NOV": 6.02,
    "DEC": 5.87
  },
  {
    "LONG": -36.31,
    "LAT": -9.69,
    "CIDADE": "Anadia",
    "UF": "ALAGOAS",
    "anual": 5.23,
    "JAN": 5.58,
    "FEB": 5.62,
    "MAR": 5.8,
    "APR": 5.26,
    "MAY": 4.49,
    "JUN": 4.23,
    "JUL": 4.26,
    "AUG": 4.78,
    "SEP": 5.45,
    "OCT": 5.56,
    "NOV": 5.9,
    "DEC": 5.84
  },
  {
    "LONG": -3.59,
    "LAT": -9.71,
    "CIDADE": "Marechal Deodoro",
    "UF": "ALAGOAS",
    "anual": 5.46,
    "JAN": 5.78,
    "FEB": 5.9,
    "MAR": 6.04,
    "APR": 5.4,
    "MAY": 4.72,
    "JUN": 4.42,
    "JUL": 4.55,
    "AUG": 5.15,
    "SEP": 5.67,
    "OCT": 5.86,
    "NOV": 6.01,
    "DEC": 5.97
  },
  {
    "LONG": -35.7,
    "LAT": -9.66,
    "CIDADE": "Maceió",
    "UF": "ALAGOAS",
    "anual": 5.53,
    "JAN": 5.8,
    "FEB": 5.95,
    "MAR": 6.11,
    "APR": 5.47,
    "MAY": 4.77,
    "JUN": 4.51,
    "JUL": 4.65,
    "AUG": 5.28,
    "SEP": 5.81,
    "OCT": 5.89,
    "NOV": 6.07,
    "DEC": 6.06
  },
  {
    "LONG": -37.76,
    "LAT": -9.61,
    "CIDADE": "Piranhas",
    "UF": "ALAGOAS",
    "anual": 5.47,
    "JAN": 5.94,
    "FEB": 5.86,
    "MAR": 5.98,
    "APR": 5.53,
    "MAY": 4.78,
    "JUN": 4.41,
    "JUL": 4.51,
    "AUG": 5.08,
    "SEP": 5.73,
    "OCT": 5.81,
    "NOV": 6.09,
    "DEC": 5.93
  },
  {
    "LONG": -37.38,
    "LAT": -9.56,
    "CIDADE": "São José da Tapera",
    "UF": "ALAGOAS",
    "anual": 5.4,
    "JAN": 5.79,
    "FEB": 5.78,
    "MAR": 5.96,
    "APR": 5.48,
    "MAY": 4.66,
    "JUN": 4.35,
    "JUL": 4.48,
    "AUG": 4.97,
    "SEP": 5.61,
    "OCT": 5.77,
    "NOV": 6.14,
    "DEC": 5.87
  },
  {
    "LONG": -37.21,
    "LAT": -9.64,
    "CIDADE": "Jacaré dos Homens",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.74,
    "FEB": 5.69,
    "MAR": 5.93,
    "APR": 5.43,
    "MAY": 4.65,
    "JUN": 4.31,
    "JUL": 4.43,
    "AUG": 4.91,
    "SEP": 5.53,
    "OCT": 5.71,
    "NOV": 6.12,
    "DEC": 5.84
  },
  {
    "LONG": -37.25,
    "LAT": -0.96,
    "CIDADE": "Monteirópolis",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.74,
    "FEB": 5.69,
    "MAR": 5.93,
    "APR": 5.43,
    "MAY": 4.65,
    "JUN": 4.31,
    "JUL": 4.43,
    "AUG": 4.91,
    "SEP": 5.53,
    "OCT": 5.71,
    "NOV": 6.12,
    "DEC": 5.84
  },
  {
    "LONG": "#VALOR!",
    "LAT": -9.62,
    "CIDADE": "Craíbas",
    "UF": "ALAGOAS",
    "anual": 5.39,
    "JAN": 5.75,
    "FEB": 5.65,
    "MAR": 5.98,
    "APR": 5.44,
    "MAY": 4.61,
    "JUN": 4.31,
    "JUL": 4.45,
    "AUG": 4.97,
    "SEP": 5.66,
    "OCT": 5.76,
    "NOV": 6.08,
    "DEC": 5.95
  },
  {
    "LONG": -36.58,
    "LAT": -9.63,
    "CIDADE": "Coité do Nóia",
    "UF": "ALAGOAS",
    "anual": 5.33,
    "JAN": 5.73,
    "FEB": 5.71,
    "MAR": 5.88,
    "APR": 5.33,
    "MAY": 4.55,
    "JUN": 4.23,
    "JUL": 4.33,
    "AUG": 4.88,
    "SEP": 5.58,
    "OCT": 5.71,
    "NOV": 6.08,
    "DEC": 5.98
  },
  {
    "LONG": -36.49,
    "LAT": -9.57,
    "CIDADE": "Belém",
    "UF": "ALAGOAS",
    "anual": 5.24,
    "JAN": 5.64,
    "FEB": 5.64,
    "MAR": 5.78,
    "APR": 5.32,
    "MAY": 4.48,
    "JUN": 4.15,
    "JUL": 4.21,
    "AUG": 4.77,
    "SEP": 5.44,
    "OCT": 5.58,
    "NOV": 6,
    "DEC": 5.89
  },
  {
    "LONG": -36.49,
    "LAT": -9.65,
    "CIDADE": "Taquarana",
    "UF": "ALAGOAS",
    "anual": 5.24,
    "JAN": 5.64,
    "FEB": 5.64,
    "MAR": 5.78,
    "APR": 5.32,
    "MAY": 4.48,
    "JUN": 4.15,
    "JUL": 4.21,
    "AUG": 4.77,
    "SEP": 5.44,
    "OCT": 5.58,
    "NOV": 6,
    "DEC": 5.89
  },
  {
    "LONG": -36.3,
    "LAT": -0.96,
    "CIDADE": "Maribondo",
    "UF": "ALAGOAS",
    "anual": 5.23,
    "JAN": 5.61,
    "FEB": 5.61,
    "MAR": 5.78,
    "APR": 5.28,
    "MAY": 4.49,
    "JUN": 4.15,
    "JUL": 4.21,
    "AUG": 4.75,
    "SEP": 5.46,
    "OCT": 5.59,
    "NOV": 5.96,
    "DEC": 5.86
  },
  {
    "LONG": -36.21,
    "LAT": -9.64,
    "CIDADE": "Boca da Mata",
    "UF": "ALAGOAS",
    "anual": 5.14,
    "JAN": 5.5,
    "FEB": 5.51,
    "MAR": 5.76,
    "APR": 5.18,
    "MAY": 4.43,
    "JUN": 4.08,
    "JUL": 4.17,
    "AUG": 4.64,
    "SEP": 5.37,
    "OCT": 5.47,
    "NOV": 5.87,
    "DEC": 5.74
  },
  {
    "LONG": -35.95,
    "LAT": -9.6,
    "CIDADE": "Pilar",
    "UF": "ALAGOAS",
    "anual": 5.31,
    "JAN": 5.62,
    "FEB": 5.72,
    "MAR": 5.89,
    "APR": 5.3,
    "MAY": 4.62,
    "JUN": 4.32,
    "JUL": 4.42,
    "AUG": 4.94,
    "SEP": 5.56,
    "OCT": 5.62,
    "NOV": 5.9,
    "DEC": 5.79
  },
  {
    "LONG": -3.58,
    "LAT": -9.57,
    "CIDADE": "Satuba",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.69,
    "FEB": 5.81,
    "MAR": 5.96,
    "APR": 5.29,
    "MAY": 4.61,
    "JUN": 4.35,
    "JUL": 4.46,
    "AUG": 5,
    "SEP": 5.58,
    "OCT": 5.73,
    "NOV": 5.97,
    "DEC": 5.88
  },
  {
    "LONG": -35.82,
    "LAT": -9.6,
    "CIDADE": "Santa Luzia do Norte",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.69,
    "FEB": 5.81,
    "MAR": 5.96,
    "APR": 5.29,
    "MAY": 4.61,
    "JUN": 4.35,
    "JUL": 4.46,
    "AUG": 5,
    "SEP": 5.58,
    "OCT": 5.73,
    "NOV": 5.97,
    "DEC": 5.88
  },
  {
    "LONG": -35.8,
    "LAT": -9.64,
    "CIDADE": "Coqueiro Seco",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.69,
    "FEB": 5.81,
    "MAR": 5.96,
    "APR": 5.29,
    "MAY": 4.61,
    "JUN": 4.35,
    "JUL": 4.46,
    "AUG": 5,
    "SEP": 5.58,
    "OCT": 5.73,
    "NOV": 5.97,
    "DEC": 5.88
  },
  {
    "LONG": -3.78,
    "LAT": -9.5,
    "CIDADE": "Olho d'Água do Casado",
    "UF": "ALAGOAS",
    "anual": 5.48,
    "JAN": 5.95,
    "FEB": 5.88,
    "MAR": 5.99,
    "APR": 5.54,
    "MAY": 4.77,
    "JUN": 4.41,
    "JUL": 4.5,
    "AUG": 5.09,
    "SEP": 5.75,
    "OCT": 5.81,
    "NOV": 6.14,
    "DEC": 5.96
  },
  {
    "LONG": -37.46,
    "LAT": -9.47,
    "CIDADE": "Senador Rui Palmeira",
    "UF": "ALAGOAS",
    "anual": 5.42,
    "JAN": 5.81,
    "FEB": 5.81,
    "MAR": 5.95,
    "APR": 5.46,
    "MAY": 4.67,
    "JUN": 4.34,
    "JUL": 4.45,
    "AUG": 5.01,
    "SEP": 5.68,
    "OCT": 5.81,
    "NOV": 6.14,
    "DEC": 5.91
  },
  {
    "LONG": -37.38,
    "LAT": -9.48,
    "CIDADE": "Carneiros",
    "UF": "ALAGOAS",
    "anual": 5.43,
    "JAN": 5.78,
    "FEB": 5.77,
    "MAR": 6,
    "APR": 5.51,
    "MAY": 4.68,
    "JUN": 4.34,
    "JUL": 4.44,
    "AUG": 5.02,
    "SEP": 5.7,
    "OCT": 5.83,
    "NOV": 6.2,
    "DEC": 5.91
  },
  {
    "LONG": -37.3,
    "LAT": -9.53,
    "CIDADE": "Olho d'Água das Flores",
    "UF": "ALAGOAS",
    "anual": 5.41,
    "JAN": 5.78,
    "FEB": 5.74,
    "MAR": 6.02,
    "APR": 5.48,
    "MAY": 4.67,
    "JUN": 4.32,
    "JUL": 4.41,
    "AUG": 4.96,
    "SEP": 5.64,
    "OCT": 5.81,
    "NOV": 6.19,
    "DEC": 5.92
  },
  {
    "LONG": -37.19,
    "LAT": -9.52,
    "CIDADE": "Olivença",
    "UF": "ALAGOAS",
    "anual": 5.38,
    "JAN": 5.82,
    "FEB": 5.73,
    "MAR": 5.98,
    "APR": 5.4,
    "MAY": 4.63,
    "JUN": 4.29,
    "JUL": 4.38,
    "AUG": 4.89,
    "SEP": 5.57,
    "OCT": 5.79,
    "NOV": 6.15,
    "DEC": 5.91
  },
  {
    "LONG": -36.99,
    "LAT": -9.53,
    "CIDADE": "Major Isidoro",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.76,
    "FEB": 5.64,
    "MAR": 5.93,
    "APR": 5.4,
    "MAY": 4.61,
    "JUN": 4.28,
    "JUL": 4.4,
    "AUG": 4.9,
    "SEP": 5.57,
    "OCT": 5.75,
    "NOV": 6.12,
    "DEC": 5.94
  },
  {
    "LONG": -36.64,
    "LAT": -9.54,
    "CIDADE": "Igaci",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.72,
    "FEB": 5.67,
    "MAR": 5.93,
    "APR": 5.4,
    "MAY": 4.6,
    "JUN": 4.27,
    "JUL": 4.37,
    "AUG": 4.93,
    "SEP": 5.6,
    "OCT": 5.74,
    "NOV": 6.13,
    "DEC": 5.95
  },
  {
    "LONG": -36.43,
    "LAT": -9.53,
    "CIDADE": "Tanque d'Arca",
    "UF": "ALAGOAS",
    "anual": 5.28,
    "JAN": 5.64,
    "FEB": 5.65,
    "MAR": 5.82,
    "APR": 5.31,
    "MAY": 4.53,
    "JUN": 4.15,
    "JUL": 4.23,
    "AUG": 4.81,
    "SEP": 5.53,
    "OCT": 5.68,
    "NOV": 6.09,
    "DEC": 5.9
  },
  {
    "LONG": -36.29,
    "LAT": -9.47,
    "CIDADE": "Pindoba",
    "UF": "ALAGOAS",
    "anual": 5.14,
    "JAN": 5.48,
    "FEB": 5.55,
    "MAR": 5.76,
    "APR": 5.21,
    "MAY": 4.45,
    "JUN": 4.05,
    "JUL": 4.16,
    "AUG": 4.64,
    "SEP": 5.34,
    "OCT": 5.49,
    "NOV": 5.84,
    "DEC": 5.71
  },
  {
    "LONG": -36.01,
    "LAT": -9.51,
    "CIDADE": "Atalaia",
    "UF": "ALAGOAS",
    "anual": 5.21,
    "JAN": 5.53,
    "FEB": 5.6,
    "MAR": 5.8,
    "APR": 5.3,
    "MAY": 4.54,
    "JUN": 4.24,
    "JUL": 4.3,
    "AUG": 4.8,
    "SEP": 5.44,
    "OCT": 5.48,
    "NOV": 5.8,
    "DEC": 5.72
  },
  {
    "LONG": -35.84,
    "LAT": -9.48,
    "CIDADE": "Rio Largo",
    "UF": "ALAGOAS",
    "anual": 5.24,
    "JAN": 5.55,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.22,
    "MAY": 4.51,
    "JUN": 4.28,
    "JUL": 4.36,
    "AUG": 4.87,
    "SEP": 5.46,
    "OCT": 5.55,
    "NOV": 5.86,
    "DEC": 5.74
  },
  {
    "LONG": -35.55,
    "LAT": -9.46,
    "CIDADE": "Paripueira",
    "UF": "ALAGOAS",
    "anual": 5.55,
    "JAN": 5.8,
    "FEB": 5.95,
    "MAR": 6.17,
    "APR": 5.46,
    "MAY": 4.8,
    "JUN": 4.52,
    "JUL": 4.64,
    "AUG": 5.26,
    "SEP": 5.87,
    "OCT": 5.95,
    "NOV": 6.15,
    "DEC": 6.05
  },
  {
    "LONG": -3.8,
    "LAT": -9.39,
    "CIDADE": "Delmiro Gouveia",
    "UF": "ALAGOAS",
    "anual": 5.46,
    "JAN": 5.95,
    "FEB": 5.87,
    "MAR": 5.97,
    "APR": 5.48,
    "MAY": 4.73,
    "JUN": 4.34,
    "JUL": 4.46,
    "AUG": 5.01,
    "SEP": 5.75,
    "OCT": 5.85,
    "NOV": 6.17,
    "DEC": 5.96
  },
  {
    "LONG": -37.25,
    "LAT": -9.37,
    "CIDADE": "Santana do Ipanema",
    "UF": "ALAGOAS",
    "anual": 5.46,
    "JAN": 5.84,
    "FEB": 5.8,
    "MAR": 6.07,
    "APR": 5.51,
    "MAY": 4.71,
    "JUN": 4.33,
    "JUL": 4.43,
    "AUG": 4.99,
    "SEP": 5.68,
    "OCT": 5.9,
    "NOV": 6.22,
    "DEC": 5.99
  },
  {
    "LONG": -37.09,
    "LAT": -9.39,
    "CIDADE": "Dois Riachos",
    "UF": "ALAGOAS",
    "anual": 5.41,
    "JAN": 5.82,
    "FEB": 5.73,
    "MAR": 5.96,
    "APR": 5.44,
    "MAY": 4.67,
    "JUN": 4.28,
    "JUL": 4.42,
    "AUG": 4.97,
    "SEP": 5.66,
    "OCT": 5.83,
    "NOV": 6.14,
    "DEC": 5.98
  },
  {
    "LONG": -36.99,
    "LAT": -9.4,
    "CIDADE": "Cacimbinhas",
    "UF": "ALAGOAS",
    "anual": 5.38,
    "JAN": 5.74,
    "FEB": 5.69,
    "MAR": 5.92,
    "APR": 5.38,
    "MAY": 4.65,
    "JUN": 4.27,
    "JUL": 4.41,
    "AUG": 4.94,
    "SEP": 5.64,
    "OCT": 5.78,
    "NOV": 6.13,
    "DEC": 5.97
  },
  {
    "LONG": "#VALOR!",
    "LAT": -0.94,
    "CIDADE": "Estrela de Alagoas",
    "UF": "ALAGOAS",
    "anual": 5.44,
    "JAN": 5.76,
    "FEB": 5.75,
    "MAR": 5.98,
    "APR": 5.47,
    "MAY": 4.65,
    "JUN": 4.29,
    "JUL": 4.42,
    "AUG": 5.05,
    "SEP": 5.79,
    "OCT": 5.89,
    "NOV": 6.24,
    "DEC": 6.02
  },
  {
    "LONG": -36.63,
    "LAT": -9.41,
    "CIDADE": "Palmeira dos Índios",
    "UF": "ALAGOAS",
    "anual": 5.38,
    "JAN": 5.7,
    "FEB": 5.69,
    "MAR": 5.93,
    "APR": 5.42,
    "MAY": 4.61,
    "JUN": 4.24,
    "JUL": 4.36,
    "AUG": 4.98,
    "SEP": 5.68,
    "OCT": 5.81,
    "NOV": 6.17,
    "DEC": 5.96
  },
  {
    "LONG": -36.39,
    "LAT": -9.45,
    "CIDADE": "Mar Vermelho",
    "UF": "ALAGOAS",
    "anual": 5.23,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.82,
    "APR": 5.27,
    "MAY": 4.5,
    "JUN": 4.07,
    "JUL": 4.18,
    "AUG": 4.73,
    "SEP": 5.46,
    "OCT": 5.6,
    "NOV": 6.02,
    "DEC": 5.85
  },
  {
    "LONG": -36.38,
    "LAT": -9.37,
    "CIDADE": "Paulo Jacinto",
    "UF": "ALAGOAS",
    "anual": 5.23,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.82,
    "APR": 5.27,
    "MAY": 4.5,
    "JUN": 4.07,
    "JUL": 4.18,
    "AUG": 4.73,
    "SEP": 5.46,
    "OCT": 5.6,
    "NOV": 6.02,
    "DEC": 5.85
  },
  {
    "LONG": -36.24,
    "LAT": -9.37,
    "CIDADE": "Viçosa",
    "UF": "ALAGOAS",
    "anual": 5.19,
    "JAN": 5.52,
    "FEB": 5.63,
    "MAR": 5.8,
    "APR": 5.29,
    "MAY": 4.47,
    "JUN": 4.07,
    "JUL": 4.2,
    "AUG": 4.69,
    "SEP": 5.39,
    "OCT": 5.53,
    "NOV": 5.91,
    "DEC": 5.78
  },
  {
    "LONG": -36.16,
    "LAT": -9.4,
    "CIDADE": "Cajueiro",
    "UF": "ALAGOAS",
    "anual": 5.11,
    "JAN": 5.43,
    "FEB": 5.49,
    "MAR": 5.69,
    "APR": 5.26,
    "MAY": 4.47,
    "JUN": 4.08,
    "JUL": 4.15,
    "AUG": 4.62,
    "SEP": 5.3,
    "OCT": 5.37,
    "NOV": 5.78,
    "DEC": 5.65
  },
  {
    "LONG": -36.08,
    "LAT": -9.42,
    "CIDADE": "Capela",
    "UF": "ALAGOAS",
    "anual": 5.15,
    "JAN": 5.45,
    "FEB": 5.49,
    "MAR": 5.74,
    "APR": 5.24,
    "MAY": 4.48,
    "JUN": 4.14,
    "JUL": 4.2,
    "AUG": 4.71,
    "SEP": 5.39,
    "OCT": 5.45,
    "NOV": 5.82,
    "DEC": 5.69
  },
  {
    "LONG": -35.84,
    "LAT": -9.39,
    "CIDADE": "Messias",
    "UF": "ALAGOAS",
    "anual": 5.22,
    "JAN": 5.53,
    "FEB": 5.64,
    "MAR": 5.85,
    "APR": 5.25,
    "MAY": 4.52,
    "JUN": 4.21,
    "JUL": 4.3,
    "AUG": 4.79,
    "SEP": 5.45,
    "OCT": 5.53,
    "NOV": 5.84,
    "DEC": 5.7
  },
  {
    "LONG": -35.51,
    "LAT": -9.4,
    "CIDADE": "Barra de Santo Antônio",
    "UF": "ALAGOAS",
    "anual": 5.44,
    "JAN": 5.73,
    "FEB": 5.83,
    "MAR": 6.12,
    "APR": 5.38,
    "MAY": 4.72,
    "JUN": 4.42,
    "JUL": 4.51,
    "AUG": 5.1,
    "SEP": 5.62,
    "OCT": 5.78,
    "NOV": 6.05,
    "DEC": 5.97
  },
  {
    "LONG": -38,
    "LAT": -9.26,
    "CIDADE": "Pariconha",
    "UF": "ALAGOAS",
    "anual": 5.52,
    "JAN": 5.95,
    "FEB": 5.92,
    "MAR": 5.99,
    "APR": 5.57,
    "MAY": 4.83,
    "JUN": 4.34,
    "JUL": 4.53,
    "AUG": 5.08,
    "SEP": 5.85,
    "OCT": 5.94,
    "NOV": 6.25,
    "DEC": 5.99
  },
  {
    "LONG": -37.94,
    "LAT": -9.26,
    "CIDADE": "Água Branca",
    "UF": "ALAGOAS",
    "anual": 5.47,
    "JAN": 5.91,
    "FEB": 5.86,
    "MAR": 6,
    "APR": 5.49,
    "MAY": 4.74,
    "JUN": 4.29,
    "JUL": 4.38,
    "AUG": 5.03,
    "SEP": 5.78,
    "OCT": 5.89,
    "NOV": 6.23,
    "DEC": 5.97
  },
  {
    "LONG": -37.28,
    "LAT": -9.3,
    "CIDADE": "Poço das Trincheiras",
    "UF": "ALAGOAS",
    "anual": 5.45,
    "JAN": 5.82,
    "FEB": 5.76,
    "MAR": 6.01,
    "APR": 5.5,
    "MAY": 4.69,
    "JUN": 4.27,
    "JUL": 4.48,
    "AUG": 4.96,
    "SEP": 5.82,
    "OCT": 5.93,
    "NOV": 6.21,
    "DEC": 5.94
  },
  {
    "LONG": -3.69,
    "LAT": -9.31,
    "CIDADE": "Minador do Negrão",
    "UF": "ALAGOAS",
    "anual": 5.4,
    "JAN": 5.73,
    "FEB": 5.73,
    "MAR": 5.92,
    "APR": 5.47,
    "MAY": 4.66,
    "JUN": 4.26,
    "JUL": 4.38,
    "AUG": 4.96,
    "SEP": 5.7,
    "OCT": 5.85,
    "NOV": 6.14,
    "DEC": 5.98
  },
  {
    "LONG": -36.47,
    "LAT": -9.32,
    "CIDADE": "Quebrangulo",
    "UF": "ALAGOAS",
    "anual": 5.26,
    "JAN": 5.61,
    "FEB": 5.67,
    "MAR": 5.84,
    "APR": 5.38,
    "MAY": 4.52,
    "JUN": 4.04,
    "JUL": 4.18,
    "AUG": 4.75,
    "SEP": 5.5,
    "OCT": 5.69,
    "NOV": 6.1,
    "DEC": 5.88
  },
  {
    "LONG": -36.3,
    "LAT": -0.93,
    "CIDADE": "Chã Preta",
    "UF": "ALAGOAS",
    "anual": 5.17,
    "JAN": 5.54,
    "FEB": 5.58,
    "MAR": 5.75,
    "APR": 5.28,
    "MAY": 4.47,
    "JUN": 4.02,
    "JUL": 4.09,
    "AUG": 4.68,
    "SEP": 5.36,
    "OCT": 5.5,
    "NOV": 5.94,
    "DEC": 5.8
  },
  {
    "LONG": -35.94,
    "LAT": -9.31,
    "CIDADE": "Murici",
    "UF": "ALAGOAS",
    "anual": 5.18,
    "JAN": 5.48,
    "FEB": 5.63,
    "MAR": 5.83,
    "APR": 5.27,
    "MAY": 4.46,
    "JUN": 4.15,
    "JUL": 4.18,
    "AUG": 4.78,
    "SEP": 5.41,
    "OCT": 5.51,
    "NOV": 5.81,
    "DEC": 5.69
  },
  {
    "LONG": -35.71,
    "LAT": -9.27,
    "CIDADE": "Flexeiras",
    "UF": "ALAGOAS",
    "anual": 5.11,
    "JAN": 5.39,
    "FEB": 5.52,
    "MAR": 5.73,
    "APR": 5.19,
    "MAY": 4.41,
    "JUN": 4.13,
    "JUL": 4.21,
    "AUG": 4.68,
    "SEP": 5.32,
    "OCT": 5.37,
    "NOV": 5.75,
    "DEC": 5.62
  },
  {
    "LONG": -35.56,
    "LAT": -9.32,
    "CIDADE": "São Luís do Quitunde",
    "UF": "ALAGOAS",
    "anual": 5.33,
    "JAN": 5.64,
    "FEB": 5.73,
    "MAR": 6,
    "APR": 5.31,
    "MAY": 4.63,
    "JUN": 4.42,
    "JUL": 4.41,
    "AUG": 5.03,
    "SEP": 5.53,
    "OCT": 5.6,
    "NOV": 5.87,
    "DEC": 5.82
  },
  {
    "LONG": -35.38,
    "LAT": -9.27,
    "CIDADE": "São Miguel dos Milagres",
    "UF": "ALAGOAS",
    "anual": 5.59,
    "JAN": 5.9,
    "FEB": 5.91,
    "MAR": 6.24,
    "APR": 5.54,
    "MAY": 4.82,
    "JUN": 4.55,
    "JUL": 4.67,
    "AUG": 5.34,
    "SEP": 5.88,
    "OCT": 5.97,
    "NOV": 6.18,
    "DEC": 6.1
  },
  {
    "LONG": -37.75,
    "LAT": -9.23,
    "CIDADE": "Inhapi",
    "UF": "ALAGOAS",
    "anual": 5.52,
    "JAN": 6,
    "FEB": 5.93,
    "MAR": 6.06,
    "APR": 5.54,
    "MAY": 4.73,
    "JUN": 4.25,
    "JUL": 4.4,
    "AUG": 5.07,
    "SEP": 5.82,
    "OCT": 6.03,
    "NOV": 6.32,
    "DEC": 6.04
  },
  {
    "LONG": -37.36,
    "LAT": -9.16,
    "CIDADE": "Ouro Branco",
    "UF": "ALAGOAS",
    "anual": 5.48,
    "JAN": 5.87,
    "FEB": 5.83,
    "MAR": 6.03,
    "APR": 5.5,
    "MAY": 4.69,
    "JUN": 4.32,
    "JUL": 4.52,
    "AUG": 5.05,
    "SEP": 5.81,
    "OCT": 5.99,
    "NOV": 6.17,
    "DEC": 5.99
  },
  {
    "LONG": -37.35,
    "LAT": -9.23,
    "CIDADE": "Maravilha",
    "UF": "ALAGOAS",
    "anual": 5.48,
    "JAN": 5.87,
    "FEB": 5.83,
    "MAR": 6.03,
    "APR": 5.5,
    "MAY": 4.69,
    "JUN": 4.32,
    "JUL": 4.52,
    "AUG": 5.05,
    "SEP": 5.81,
    "OCT": 5.99,
    "NOV": 6.17,
    "DEC": 5.99
  },
  {
    "LONG": -36.22,
    "LAT": -9.17,
    "CIDADE": "Santana do Mundaú",
    "UF": "ALAGOAS",
    "anual": 5.12,
    "JAN": 5.52,
    "FEB": 5.55,
    "MAR": 5.7,
    "APR": 5.28,
    "MAY": 4.43,
    "JUN": 3.96,
    "JUL": 4.04,
    "AUG": 4.55,
    "SEP": 5.33,
    "OCT": 5.47,
    "NOV": 5.86,
    "DEC": 5.77
  },
  {
    "LONG": -36.02,
    "LAT": -9.16,
    "CIDADE": "União dos Palmares",
    "UF": "ALAGOAS",
    "anual": 5.17,
    "JAN": 5.45,
    "FEB": 5.57,
    "MAR": 5.73,
    "APR": 5.27,
    "MAY": 4.49,
    "JUN": 4.11,
    "JUL": 4.16,
    "AUG": 4.71,
    "SEP": 5.45,
    "OCT": 5.51,
    "NOV": 5.83,
    "DEC": 5.72
  },
  {
    "LONG": -36.02,
    "LAT": -9.23,
    "CIDADE": "Branquinha",
    "UF": "ALAGOAS",
    "anual": 5.17,
    "JAN": 5.45,
    "FEB": 5.57,
    "MAR": 5.73,
    "APR": 5.27,
    "MAY": 4.49,
    "JUN": 4.11,
    "JUL": 4.16,
    "AUG": 4.71,
    "SEP": 5.45,
    "OCT": 5.51,
    "NOV": 5.83,
    "DEC": 5.72
  },
  {
    "LONG": -35.52,
    "LAT": -9.15,
    "CIDADE": "Matriz de Camaragibe",
    "UF": "ALAGOAS",
    "anual": 5.22,
    "JAN": 5.56,
    "FEB": 5.59,
    "MAR": 5.85,
    "APR": 5.17,
    "MAY": 4.59,
    "JUN": 4.28,
    "JUL": 4.33,
    "AUG": 4.88,
    "SEP": 5.43,
    "OCT": 5.48,
    "NOV": 5.78,
    "DEC": 5.71
  },
  {
    "LONG": -35.49,
    "LAT": -9.24,
    "CIDADE": "Passo de Camaragibe",
    "UF": "ALAGOAS",
    "anual": 5.28,
    "JAN": 5.62,
    "FEB": 5.63,
    "MAR": 5.94,
    "APR": 5.19,
    "MAY": 4.61,
    "JUN": 4.3,
    "JUL": 4.38,
    "AUG": 4.94,
    "SEP": 5.48,
    "OCT": 5.61,
    "NOV": 5.88,
    "DEC": 5.81
  },
  {
    "LONG": -35.31,
    "LAT": -9.16,
    "CIDADE": "Porto de Pedras",
    "UF": "ALAGOAS",
    "anual": 5.42,
    "JAN": 5.67,
    "FEB": 5.79,
    "MAR": 6.12,
    "APR": 5.32,
    "MAY": 4.69,
    "JUN": 4.47,
    "JUL": 4.51,
    "AUG": 5.19,
    "SEP": 5.64,
    "OCT": 5.78,
    "NOV": 5.97,
    "DEC": 5.85
  },
  {
    "LONG": -37.73,
    "LAT": -9.12,
    "CIDADE": "Mata Grande",
    "UF": "ALAGOAS",
    "anual": 5.61,
    "JAN": 6.05,
    "FEB": 5.95,
    "MAR": 6.1,
    "APR": 5.63,
    "MAY": 4.86,
    "JUN": 4.37,
    "JUL": 4.52,
    "AUG": 5.25,
    "SEP": 6,
    "OCT": 6.13,
    "NOV": 6.34,
    "DEC": 6.11
  },
  {
    "LONG": -37.6,
    "LAT": -9.12,
    "CIDADE": "Canapi",
    "UF": "ALAGOAS",
    "anual": 5.52,
    "JAN": 5.96,
    "FEB": 5.88,
    "MAR": 6.09,
    "APR": 5.59,
    "MAY": 4.76,
    "JUN": 4.26,
    "JUL": 4.43,
    "AUG": 5,
    "SEP": 5.82,
    "OCT": 6.04,
    "NOV": 6.35,
    "DEC": 6.06
  },
  {
    "LONG": -35.75,
    "LAT": -9.13,
    "CIDADE": "Joaquim Gomes",
    "UF": "ALAGOAS",
    "anual": 4.98,
    "JAN": 5.23,
    "FEB": 5.38,
    "MAR": 5.64,
    "APR": 5.09,
    "MAY": 4.35,
    "JUN": 4.06,
    "JUL": 4.05,
    "AUG": 4.58,
    "SEP": 5.19,
    "OCT": 5.21,
    "NOV": 5.59,
    "DEC": 5.43
  },
  {
    "LONG": -35.4,
    "LAT": -9.05,
    "CIDADE": "Porto Calvo",
    "UF": "ALAGOAS",
    "anual": 5.35,
    "JAN": 5.62,
    "FEB": 5.73,
    "MAR": 6.05,
    "APR": 5.24,
    "MAY": 4.65,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 5.09,
    "SEP": 5.54,
    "OCT": 5.7,
    "NOV": 5.93,
    "DEC": 5.84
  },
  {
    "LONG": -35.26,
    "LAT": -9.09,
    "CIDADE": "Japaratinga",
    "UF": "ALAGOAS",
    "anual": 5.52,
    "JAN": 5.77,
    "FEB": 5.85,
    "MAR": 6.21,
    "APR": 5.43,
    "MAY": 4.77,
    "JUN": 4.55,
    "JUL": 4.59,
    "AUG": 5.29,
    "SEP": 5.78,
    "OCT": 5.99,
    "NOV": 6.06,
    "DEC": 5.99
  },
  {
    "LONG": -36.05,
    "LAT": -9.01,
    "CIDADE": "São José da Laje",
    "UF": "ALAGOAS",
    "anual": 5.07,
    "JAN": 5.41,
    "FEB": 5.46,
    "MAR": 5.64,
    "APR": 5.2,
    "MAY": 4.43,
    "JUN": 3.99,
    "JUL": 4.05,
    "AUG": 4.63,
    "SEP": 5.32,
    "OCT": 5.38,
    "NOV": 5.78,
    "DEC": 5.63
  },
  {
    "LONG": -35.94,
    "LAT": -8.98,
    "CIDADE": "Ibateguara",
    "UF": "ALAGOAS",
    "anual": 5.14,
    "JAN": 5.53,
    "FEB": 5.63,
    "MAR": 5.76,
    "APR": 5.23,
    "MAY": 4.38,
    "JUN": 3.95,
    "JUL": 4.04,
    "AUG": 4.65,
    "SEP": 5.41,
    "OCT": 5.52,
    "NOV": 5.89,
    "DEC": 5.74
  },
  {
    "LONG": -3.52,
    "LAT": -9.01,
    "CIDADE": "Maragogi",
    "UF": "ALAGOAS",
    "anual": 5.36,
    "JAN": 5.63,
    "FEB": 5.75,
    "MAR": 6.05,
    "APR": 5.29,
    "MAY": 4.69,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 5.11,
    "SEP": 5.52,
    "OCT": 5.7,
    "NOV": 5.84,
    "DEC": 5.8
  },
  {
    "LONG": -35.72,
    "LAT": -8.92,
    "CIDADE": "Colônia Leopoldina",
    "UF": "ALAGOAS",
    "anual": 5.01,
    "JAN": 5.29,
    "FEB": 5.44,
    "MAR": 5.6,
    "APR": 5.09,
    "MAY": 4.4,
    "JUN": 4.02,
    "JUL": 4.07,
    "AUG": 4.6,
    "SEP": 5.22,
    "OCT": 5.3,
    "NOV": 5.59,
    "DEC": 5.5
  },
  {
    "LONG": -35.66,
    "LAT": -8.94,
    "CIDADE": "Novo Lino",
    "UF": "ALAGOAS",
    "anual": 5.08,
    "JAN": 5.36,
    "FEB": 5.46,
    "MAR": 5.64,
    "APR": 5.16,
    "MAY": 4.46,
    "JUN": 4.17,
    "JUL": 4.17,
    "AUG": 4.73,
    "SEP": 5.32,
    "OCT": 5.39,
    "NOV": 5.65,
    "DEC": 5.48
  },
  {
    "LONG": -35.55,
    "LAT": -8.94,
    "CIDADE": "Jundiá",
    "UF": "ALAGOAS",
    "anual": 5.15,
    "JAN": 5.41,
    "FEB": 5.49,
    "MAR": 5.74,
    "APR": 5.18,
    "MAY": 4.51,
    "JUN": 4.24,
    "JUL": 4.25,
    "AUG": 4.8,
    "SEP": 5.41,
    "OCT": 5.48,
    "NOV": 5.7,
    "DEC": 5.58
  },
  {
    "LONG": -35.57,
    "LAT": -8.85,
    "CIDADE": "Campestre",
    "UF": "ALAGOAS",
    "anual": 5.15,
    "JAN": 5.38,
    "FEB": 5.47,
    "MAR": 5.74,
    "APR": 5.18,
    "MAY": 4.5,
    "JUN": 4.22,
    "JUL": 4.22,
    "AUG": 4.81,
    "SEP": 5.45,
    "OCT": 5.51,
    "NOV": 5.76,
    "DEC": 5.6
  },
  {
    "LONG": -35.46,
    "LAT": -8.84,
    "CIDADE": "Jacuípe",
    "UF": "ALAGOAS",
    "anual": 5.18,
    "JAN": 5.39,
    "FEB": 5.51,
    "MAR": 5.76,
    "APR": 5.19,
    "MAY": 4.5,
    "JUN": 4.27,
    "JUL": 4.26,
    "AUG": 4.85,
    "SEP": 5.49,
    "OCT": 5.53,
    "NOV": 5.81,
    "DEC": 5.63
  },
  {
    "LONG": -52.43,
    "LAT": "#VALOR!",
    "CIDADE": "Vitória do Jari",
    "UF": "AMAPÁ",
    "anual": 4.67,
    "JAN": 4.42,
    "FEB": 4.29,
    "MAR": 4.33,
    "APR": 4.35,
    "MAY": 4.57,
    "JUN": 4.7,
    "JUL": 4.78,
    "AUG": 5.03,
    "SEP": 5.1,
    "OCT": 5.06,
    "NOV": 4.9,
    "DEC": 4.47
  },
  {
    "LONG": -52.52,
    "LAT": "#VALOR!",
    "CIDADE": "Laranjal do Jari",
    "UF": "AMAPÁ",
    "anual": 4.66,
    "JAN": 4.33,
    "FEB": 4.34,
    "MAR": 4.36,
    "APR": 4.37,
    "MAY": 4.55,
    "JUN": 4.73,
    "JUL": 4.75,
    "AUG": 5.05,
    "SEP": 5.12,
    "OCT": 5.05,
    "NOV": 4.87,
    "DEC": 4.4
  },
  {
    "LONG": -51.29,
    "LAT": "#VALOR!",
    "CIDADE": "Mazagão",
    "UF": "AMAPÁ",
    "anual": 4.85,
    "JAN": 4.56,
    "FEB": 4.24,
    "MAR": 4.21,
    "APR": 4.23,
    "MAY": 4.64,
    "JUN": 4.91,
    "JUL": 5.08,
    "AUG": 5.31,
    "SEP": 5.49,
    "OCT": 5.4,
    "NOV": 5.28,
    "DEC": 4.79
  },
  {
    "LONG": -51.17,
    "LAT": "#VALOR!",
    "CIDADE": "Santana",
    "UF": "AMAPÁ",
    "anual": 4.83,
    "JAN": 4.53,
    "FEB": 4.19,
    "MAR": 4.12,
    "APR": 4.17,
    "MAY": 4.63,
    "JUN": 4.9,
    "JUL": 4.99,
    "AUG": 5.37,
    "SEP": 5.56,
    "OCT": 5.41,
    "NOV": 5.33,
    "DEC": 4.78
  },
  {
    "LONG": -51.07,
    "LAT": "#VALOR!",
    "CIDADE": "Macapá",
    "UF": "AMAPÁ",
    "anual": 4.94,
    "JAN": 4.71,
    "FEB": 4.32,
    "MAR": 4.23,
    "APR": 4.29,
    "MAY": 4.71,
    "JUN": 4.97,
    "JUL": 5.09,
    "AUG": 5.48,
    "SEP": 5.62,
    "OCT": 5.48,
    "NOV": 5.46,
    "DEC": 4.98
  },
  {
    "LONG": -50.7,
    "LAT": "#VALOR!",
    "CIDADE": "Itaubal",
    "UF": "AMAPÁ",
    "anual": 4.74,
    "JAN": 4.32,
    "FEB": 4.05,
    "MAR": 3.98,
    "APR": 3.98,
    "MAY": 4.44,
    "JUN": 4.8,
    "JUL": 5.03,
    "AUG": 5.45,
    "SEP": 5.52,
    "OCT": 5.46,
    "NOV": 5.15,
    "DEC": 4.65
  },
  {
    "LONG": -51.4,
    "LAT": "#VALOR!",
    "CIDADE": "Porto Grande",
    "UF": "AMAPÁ",
    "anual": 4.55,
    "JAN": 4.19,
    "FEB": 4.06,
    "MAR": 4.05,
    "APR": 4.06,
    "MAY": 4.31,
    "JUN": 4.51,
    "JUL": 4.66,
    "AUG": 5.05,
    "SEP": 5.3,
    "OCT": 5.15,
    "NOV": 4.92,
    "DEC": 4.35
  },
  {
    "LONG": -51.95,
    "LAT": "#VALOR!",
    "CIDADE": "Pedra Branca do Amapari",
    "UF": "AMAPÁ",
    "anual": 4.56,
    "JAN": 4.18,
    "FEB": 4.04,
    "MAR": 4.09,
    "APR": 4.14,
    "MAY": 4.36,
    "JUN": 4.48,
    "JUL": 4.66,
    "AUG": 5.02,
    "SEP": 5.29,
    "OCT": 5.14,
    "NOV": 4.96,
    "DEC": 4.35
  },
  {
    "LONG": -5.2,
    "LAT": "#VALOR!",
    "CIDADE": "Serra do Navio",
    "UF": "AMAPÁ",
    "anual": 4.58,
    "JAN": 4.2,
    "FEB": 4.02,
    "MAR": 4.11,
    "APR": 4.17,
    "MAY": 4.36,
    "JUN": 4.45,
    "JUL": 4.65,
    "AUG": 5.01,
    "SEP": 5.33,
    "OCT": 5.23,
    "NOV": 4.97,
    "DEC": 4.41
  },
  {
    "LONG": -51.18,
    "LAT": "#VALOR!",
    "CIDADE": "Ferreira Gomes",
    "UF": "AMAPÁ",
    "anual": 4.69,
    "JAN": 4.26,
    "FEB": 4.02,
    "MAR": 4.05,
    "APR": 4.08,
    "MAY": 4.41,
    "JUN": 4.65,
    "JUL": 4.86,
    "AUG": 5.34,
    "SEP": 5.48,
    "OCT": 5.45,
    "NOV": 5.18,
    "DEC": 4.5
  },
  {
    "LONG": -5.08,
    "LAT": "#VALOR!",
    "CIDADE": "Cutias",
    "UF": "AMAPÁ",
    "anual": 4.72,
    "JAN": 4.33,
    "FEB": 4.03,
    "MAR": 3.93,
    "APR": 4,
    "MAY": 4.41,
    "JUN": 4.76,
    "JUL": 5,
    "AUG": 5.4,
    "SEP": 5.51,
    "OCT": 5.47,
    "NOV": 5.24,
    "DEC": 4.55
  },
  {
    "LONG": -50.91,
    "LAT": 1.51,
    "CIDADE": "Tartarugalzinho",
    "UF": "AMAPÁ",
    "anual": 4.6,
    "JAN": 4.18,
    "FEB": 3.94,
    "MAR": 3.95,
    "APR": 3.94,
    "MAY": 4.31,
    "JUN": 4.57,
    "JUL": 4.82,
    "AUG": 5.22,
    "SEP": 5.4,
    "OCT": 5.3,
    "NOV": 5.17,
    "DEC": 4.37
  },
  {
    "LONG": -50.78,
    "LAT": 1.74,
    "CIDADE": "Pracuúba",
    "UF": "AMAPÁ",
    "anual": 4.65,
    "JAN": 4.26,
    "FEB": 3.98,
    "MAR": 3.93,
    "APR": 3.92,
    "MAY": 4.31,
    "JUN": 4.5,
    "JUL": 4.81,
    "AUG": 5.21,
    "SEP": 5.54,
    "OCT": 5.48,
    "NOV": 5.3,
    "DEC": 4.59
  },
  {
    "LONG": -5.08,
    "LAT": 2.05,
    "CIDADE": "Amapá",
    "UF": "AMAPÁ",
    "anual": 4.8,
    "JAN": 4.32,
    "FEB": 4.15,
    "MAR": 4.12,
    "APR": 4.15,
    "MAY": 4.53,
    "JUN": 4.76,
    "JUL": 5.04,
    "AUG": 5.43,
    "SEP": 5.54,
    "OCT": 5.49,
    "NOV": 5.29,
    "DEC": 4.74
  },
  {
    "LONG": -50.95,
    "LAT": 2.49,
    "CIDADE": "Calçoene",
    "UF": "AMAPÁ",
    "anual": 4.78,
    "JAN": 4.21,
    "FEB": 4.08,
    "MAR": 4.08,
    "APR": 4.17,
    "MAY": 4.54,
    "JUN": 4.83,
    "JUL": 5.09,
    "AUG": 5.44,
    "SEP": 5.54,
    "OCT": 5.49,
    "NOV": 5.27,
    "DEC": 4.61
  },
  {
    "LONG": -51.83,
    "LAT": 3.84,
    "CIDADE": "Oiapoque",
    "UF": "AMAPÁ",
    "anual": 4.71,
    "JAN": 4.12,
    "FEB": 4.1,
    "MAR": 3.97,
    "APR": 4.41,
    "MAY": 4.31,
    "JUN": 4.54,
    "JUL": 4.88,
    "AUG": 5.35,
    "SEP": 5.73,
    "OCT": 5.52,
    "NOV": 5.25,
    "DEC": 4.39
  },
  {
    "LONG": -67.39,
    "LAT": -8.74,
    "CIDADE": "Boca do Acre",
    "UF": "AMAZONAS",
    "anual": 4.55,
    "JAN": 4.16,
    "FEB": 4.4,
    "MAR": 4.25,
    "APR": 4.48,
    "MAY": 4.16,
    "JUN": 4.48,
    "JUL": 4.71,
    "AUG": 5.17,
    "SEP": 5.14,
    "OCT": 4.86,
    "NOV": 4.54,
    "DEC": 4.29
  },
  {
    "LONG": -66.99,
    "LAT": -7.71,
    "CIDADE": "Pauini",
    "UF": "AMAZONAS",
    "anual": 4.5,
    "JAN": 4.04,
    "FEB": 4.37,
    "MAR": 4.26,
    "APR": 4.37,
    "MAY": 4.1,
    "JUN": 4.5,
    "JUL": 4.67,
    "AUG": 5.18,
    "SEP": 5.09,
    "OCT": 4.76,
    "NOV": 4.45,
    "DEC": 4.16
  },
  {
    "LONG": -72.59,
    "LAT": -7.54,
    "CIDADE": "Guajará",
    "UF": "AMAZONAS",
    "anual": 4.69,
    "JAN": 4.48,
    "FEB": 4.79,
    "MAR": 4.24,
    "APR": 4.57,
    "MAY": 4.51,
    "JUN": 4.48,
    "JUL": 4.63,
    "AUG": 5.28,
    "SEP": 5.18,
    "OCT": 4.93,
    "NOV": 4.75,
    "DEC": 4.44
  },
  {
    "LONG": -63.03,
    "LAT": -7.51,
    "CIDADE": "Humaitá",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.01,
    "FEB": 4.27,
    "MAR": 4.38,
    "APR": 4.4,
    "MAY": 4.13,
    "JUN": 4.73,
    "JUL": 4.81,
    "AUG": 5.14,
    "SEP": 5.01,
    "OCT": 4.8,
    "NOV": 4.47,
    "DEC": 4.19
  },
  {
    "LONG": -70.02,
    "LAT": -7.43,
    "CIDADE": "Envira",
    "UF": "AMAZONAS",
    "anual": 4.57,
    "JAN": 4.22,
    "FEB": 4.59,
    "MAR": 4.34,
    "APR": 4.44,
    "MAY": 4.21,
    "JUN": 4.33,
    "JUL": 4.57,
    "AUG": 5.14,
    "SEP": 5.2,
    "OCT": 4.9,
    "NOV": 4.52,
    "DEC": 4.3
  },
  {
    "LONG": -64.8,
    "LAT": -7.26,
    "CIDADE": "Lábrea",
    "UF": "AMAZONAS",
    "anual": 4.46,
    "JAN": 4.03,
    "FEB": 4.29,
    "MAR": 4.28,
    "APR": 4.29,
    "MAY": 4.01,
    "JUN": 4.52,
    "JUL": 4.64,
    "AUG": 5.07,
    "SEP": 5.01,
    "OCT": 4.77,
    "NOV": 4.46,
    "DEC": 4.15
  },
  {
    "LONG": -59.89,
    "LAT": -7.2,
    "CIDADE": "Apuí",
    "UF": "AMAZONAS",
    "anual": 4.46,
    "JAN": 3.89,
    "FEB": 4.26,
    "MAR": 4.3,
    "APR": 4.15,
    "MAY": 4.16,
    "JUN": 4.78,
    "JUL": 4.82,
    "AUG": 5.15,
    "SEP": 4.77,
    "OCT": 4.67,
    "NOV": 4.47,
    "DEC": 4.14
  },
  {
    "LONG": -71.69,
    "LAT": -0.71,
    "CIDADE": "Ipixuna",
    "UF": "AMAZONAS",
    "anual": 4.63,
    "JAN": 4.34,
    "FEB": 4.71,
    "MAR": 4.28,
    "APR": 4.52,
    "MAY": 4.37,
    "JUN": 4.35,
    "JUL": 4.61,
    "AUG": 5.25,
    "SEP": 5.21,
    "OCT": 4.89,
    "NOV": 4.64,
    "DEC": 4.36
  },
  {
    "LONG": -69.87,
    "LAT": -6.66,
    "CIDADE": "Eirunepé",
    "UF": "AMAZONAS",
    "anual": 4.55,
    "JAN": 4.15,
    "FEB": 4.64,
    "MAR": 4.35,
    "APR": 4.45,
    "MAY": 4.23,
    "JUN": 4.34,
    "JUL": 4.52,
    "AUG": 5.15,
    "SEP": 5.15,
    "OCT": 4.83,
    "NOV": 4.55,
    "DEC": 4.24
  },
  {
    "LONG": -64.4,
    "LAT": -6.53,
    "CIDADE": "Canutama",
    "UF": "AMAZONAS",
    "anual": 4.47,
    "JAN": 4.05,
    "FEB": 4.35,
    "MAR": 4.27,
    "APR": 4.33,
    "MAY": 4.03,
    "JUN": 4.48,
    "JUL": 4.62,
    "AUG": 5.11,
    "SEP": 4.96,
    "OCT": 4.81,
    "NOV": 4.5,
    "DEC": 4.15
  },
  {
    "LONG": -68.26,
    "LAT": -0.64,
    "CIDADE": "Itamarati",
    "UF": "AMAZONAS",
    "anual": 4.48,
    "JAN": 4.07,
    "FEB": 4.44,
    "MAR": 4.28,
    "APR": 4.32,
    "MAY": 4.12,
    "JUN": 4.4,
    "JUL": 4.49,
    "AUG": 5.13,
    "SEP": 5.14,
    "OCT": 4.75,
    "NOV": 4.47,
    "DEC": 4.18
  },
  {
    "LONG": -61.29,
    "LAT": -0.58,
    "CIDADE": "Manicoré",
    "UF": "AMAZONAS",
    "anual": 4.43,
    "JAN": 4.05,
    "FEB": 4.21,
    "MAR": 4.27,
    "APR": 4.18,
    "MAY": 3.95,
    "JUN": 4.5,
    "JUL": 4.63,
    "AUG": 5.12,
    "SEP": 4.94,
    "OCT": 4.69,
    "NOV": 4.52,
    "DEC": 4.13
  },
  {
    "LONG": -63.18,
    "LAT": -5.62,
    "CIDADE": "Tapauá",
    "UF": "AMAZONAS",
    "anual": 4.42,
    "JAN": 4.02,
    "FEB": 4.31,
    "MAR": 4.25,
    "APR": 4.2,
    "MAY": 3.93,
    "JUN": 4.38,
    "JUL": 4.54,
    "AUG": 5.07,
    "SEP": 5,
    "OCT": 4.79,
    "NOV": 4.47,
    "DEC": 4.12
  },
  {
    "LONG": -60.37,
    "LAT": -5.13,
    "CIDADE": "Novo Aripuanã",
    "UF": "AMAZONAS",
    "anual": 4.47,
    "JAN": 4,
    "FEB": 4.22,
    "MAR": 4.26,
    "APR": 4.1,
    "MAY": 4.08,
    "JUN": 4.56,
    "JUL": 4.65,
    "AUG": 5.22,
    "SEP": 5.07,
    "OCT": 4.82,
    "NOV": 4.56,
    "DEC": 4.16
  },
  {
    "LONG": -66.91,
    "LAT": -4.88,
    "CIDADE": "Carauari",
    "UF": "AMAZONAS",
    "anual": 4.44,
    "JAN": 4,
    "FEB": 4.43,
    "MAR": 4.36,
    "APR": 4.29,
    "MAY": 3.97,
    "JUN": 4.3,
    "JUL": 4.43,
    "AUG": 5.01,
    "SEP": 4.98,
    "OCT": 4.87,
    "NOV": 4.49,
    "DEC": 4.18
  },
  {
    "LONG": -70.19,
    "LAT": -4.37,
    "CIDADE": "Atalaia do Norte",
    "UF": "AMAZONAS",
    "anual": 4.52,
    "JAN": 4.22,
    "FEB": 4.6,
    "MAR": 4.49,
    "APR": 4.45,
    "MAY": 4.09,
    "JUN": 4.26,
    "JUL": 4.22,
    "AUG": 4.9,
    "SEP": 5.1,
    "OCT": 4.84,
    "NOV": 4.72,
    "DEC": 4.3
  },
  {
    "LONG": -70.03,
    "LAT": -4.38,
    "CIDADE": "Benjamin Constant",
    "UF": "AMAZONAS",
    "anual": 4.54,
    "JAN": 4.25,
    "FEB": 4.64,
    "MAR": 4.52,
    "APR": 4.47,
    "MAY": 4.14,
    "JUN": 4.3,
    "JUL": 4.25,
    "AUG": 4.96,
    "SEP": 5.09,
    "OCT": 4.85,
    "NOV": 4.73,
    "DEC": 4.3
  },
  {
    "LONG": -59.59,
    "LAT": -4.39,
    "CIDADE": "Borba",
    "UF": "AMAZONAS",
    "anual": 4.42,
    "JAN": 3.88,
    "FEB": 4.09,
    "MAR": 4.12,
    "APR": 4.04,
    "MAY": 4.07,
    "JUN": 4.45,
    "JUL": 4.57,
    "AUG": 5.16,
    "SEP": 5.09,
    "OCT": 4.9,
    "NOV": 4.54,
    "DEC": 4.15
  },
  {
    "LONG": -69.94,
    "LAT": -0.42,
    "CIDADE": "Tabatinga",
    "UF": "AMAZONAS",
    "anual": 4.51,
    "JAN": 4.16,
    "FEB": 4.61,
    "MAR": 4.5,
    "APR": 4.42,
    "MAY": 4.12,
    "JUN": 4.26,
    "JUL": 4.22,
    "AUG": 4.96,
    "SEP": 5.06,
    "OCT": 4.83,
    "NOV": 4.67,
    "DEC": 4.25
  },
  {
    "LONG": -63.14,
    "LAT": -4.09,
    "CIDADE": "Coari",
    "UF": "AMAZONAS",
    "anual": 4.52,
    "JAN": 4.19,
    "FEB": 4.48,
    "MAR": 4.49,
    "APR": 4.17,
    "MAY": 4,
    "JUN": 4.45,
    "JUL": 4.48,
    "AUG": 5.08,
    "SEP": 5.06,
    "OCT": 4.89,
    "NOV": 4.59,
    "DEC": 4.31
  },
  {
    "LONG": -61.36,
    "LAT": -3.9,
    "CIDADE": "Beruri",
    "UF": "AMAZONAS",
    "anual": 4.37,
    "JAN": 4.05,
    "FEB": 4.12,
    "MAR": 4.18,
    "APR": 3.95,
    "MAY": 3.91,
    "JUN": 4.29,
    "JUL": 4.43,
    "AUG": 5.04,
    "SEP": 4.97,
    "OCT": 4.81,
    "NOV": 4.51,
    "DEC": 4.15
  },
  {
    "LONG": -59.09,
    "LAT": -3.9,
    "CIDADE": "Nova Olinda do Norte",
    "UF": "AMAZONAS",
    "anual": 4.39,
    "JAN": 3.82,
    "FEB": 4.06,
    "MAR": 4,
    "APR": 3.93,
    "MAY": 4.04,
    "JUN": 4.49,
    "JUL": 4.6,
    "AUG": 5.12,
    "SEP": 5.09,
    "OCT": 4.84,
    "NOV": 4.61,
    "DEC": 4.09
  },
  {
    "LONG": -62.06,
    "LAT": -0.38,
    "CIDADE": "Codajás",
    "UF": "AMAZONAS",
    "anual": 4.37,
    "JAN": 3.98,
    "FEB": 4.18,
    "MAR": 4.24,
    "APR": 4,
    "MAY": 3.92,
    "JUN": 4.35,
    "JUL": 4.52,
    "AUG": 5.02,
    "SEP": 4.89,
    "OCT": 4.72,
    "NOV": 4.48,
    "DEC": 4.12
  },
  {
    "LONG": -60.37,
    "LAT": -3.77,
    "CIDADE": "Careiro",
    "UF": "AMAZONAS",
    "anual": 4.33,
    "JAN": 3.88,
    "FEB": 4,
    "MAR": 4.04,
    "APR": 3.87,
    "MAY": 3.88,
    "JUN": 4.37,
    "JUL": 4.5,
    "AUG": 5.02,
    "SEP": 4.97,
    "OCT": 4.75,
    "NOV": 4.6,
    "DEC": 4.05
  },
  {
    "LONG": -61.66,
    "LAT": -3.75,
    "CIDADE": "Anori",
    "UF": "AMAZONAS",
    "anual": 4.47,
    "JAN": 4.04,
    "FEB": 4.21,
    "MAR": 4.31,
    "APR": 4.08,
    "MAY": 4.05,
    "JUN": 4.5,
    "JUL": 4.62,
    "AUG": 5.18,
    "SEP": 5.01,
    "OCT": 4.87,
    "NOV": 4.56,
    "DEC": 4.2
  },
  {
    "LONG": -61.4,
    "LAT": -3.57,
    "CIDADE": "Anamã",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.12,
    "FEB": 4.23,
    "MAR": 4.36,
    "APR": 4.12,
    "MAY": 4.11,
    "JUN": 4.64,
    "JUL": 4.66,
    "AUG": 5.24,
    "SEP": 5.09,
    "OCT": 4.95,
    "NOV": 4.65,
    "DEC": 4.22
  },
  {
    "LONG": -5.91,
    "LAT": -3.59,
    "CIDADE": "Autazes",
    "UF": "AMAZONAS",
    "anual": 4.44,
    "JAN": 3.95,
    "FEB": 4.15,
    "MAR": 4.11,
    "APR": 3.92,
    "MAY": 4.06,
    "JUN": 4.49,
    "JUL": 4.51,
    "AUG": 5.12,
    "SEP": 5.15,
    "OCT": 4.92,
    "NOV": 4.68,
    "DEC": 4.18
  },
  {
    "LONG": -6.9,
    "LAT": -3.46,
    "CIDADE": "São Paulo de Olivença",
    "UF": "AMAZONAS",
    "anual": 4.52,
    "JAN": 4.16,
    "FEB": 4.6,
    "MAR": 4.54,
    "APR": 4.43,
    "MAY": 4.12,
    "JUN": 4.26,
    "JUL": 4.16,
    "AUG": 4.9,
    "SEP": 5.06,
    "OCT": 4.94,
    "NOV": 4.73,
    "DEC": 4.34
  },
  {
    "LONG": -66.07,
    "LAT": -3.48,
    "CIDADE": "Juruá",
    "UF": "AMAZONAS",
    "anual": 4.37,
    "JAN": 4.06,
    "FEB": 4.34,
    "MAR": 4.37,
    "APR": 4.19,
    "MAY": 3.88,
    "JUN": 4.14,
    "JUL": 4.27,
    "AUG": 4.83,
    "SEP": 4.84,
    "OCT": 4.74,
    "NOV": 4.53,
    "DEC": 4.22
  },
  {
    "LONG": -68.2,
    "LAT": -3.36,
    "CIDADE": "Amaturá",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.25,
    "FEB": 4.65,
    "MAR": 4.51,
    "APR": 4.41,
    "MAY": 4.05,
    "JUN": 4.27,
    "JUL": 4.22,
    "AUG": 4.93,
    "SEP": 5.06,
    "OCT": 4.96,
    "NOV": 4.68,
    "DEC": 4.35
  },
  {
    "LONG": -64.72,
    "LAT": -3.37,
    "CIDADE": "Tefé",
    "UF": "AMAZONAS",
    "anual": 4.54,
    "JAN": 4.24,
    "FEB": 4.49,
    "MAR": 4.54,
    "APR": 4.3,
    "MAY": 4.02,
    "JUN": 4.35,
    "JUL": 4.43,
    "AUG": 5.03,
    "SEP": 5.05,
    "OCT": 4.95,
    "NOV": 4.65,
    "DEC": 4.42
  },
  {
    "LONG": -60.46,
    "LAT": -3.44,
    "CIDADE": "Manaquiri",
    "UF": "AMAZONAS",
    "anual": 4.5,
    "JAN": 4.06,
    "FEB": 4.19,
    "MAR": 4.21,
    "APR": 4.02,
    "MAY": 4.07,
    "JUN": 4.57,
    "JUL": 4.67,
    "AUG": 5.17,
    "SEP": 5.1,
    "OCT": 4.98,
    "NOV": 4.76,
    "DEC": 4.18
  },
  {
    "LONG": -57.71,
    "LAT": -3.39,
    "CIDADE": "Maués",
    "UF": "AMAZONAS",
    "anual": 4.47,
    "JAN": 3.87,
    "FEB": 4.08,
    "MAR": 4.04,
    "APR": 3.88,
    "MAY": 4.07,
    "JUN": 4.63,
    "JUL": 4.66,
    "AUG": 5.24,
    "SEP": 5.1,
    "OCT": 5.05,
    "NOV": 4.73,
    "DEC": 4.28
  },
  {
    "LONG": -6.12,
    "LAT": -3.32,
    "CIDADE": "Caapiranga",
    "UF": "AMAZONAS",
    "anual": 4.38,
    "JAN": 3.99,
    "FEB": 4.16,
    "MAR": 4.18,
    "APR": 4.03,
    "MAY": 3.92,
    "JUN": 4.47,
    "JUL": 4.59,
    "AUG": 5.04,
    "SEP": 4.89,
    "OCT": 4.79,
    "NOV": 4.44,
    "DEC": 4.09
  },
  {
    "LONG": -6.06,
    "LAT": -0.33,
    "CIDADE": "Manacapuru",
    "UF": "AMAZONAS",
    "anual": 4.47,
    "JAN": 4.04,
    "FEB": 4.16,
    "MAR": 4.22,
    "APR": 4.05,
    "MAY": 4.03,
    "JUN": 4.59,
    "JUL": 4.66,
    "AUG": 5.1,
    "SEP": 4.98,
    "OCT": 4.9,
    "NOV": 4.69,
    "DEC": 4.2
  },
  {
    "LONG": -60.19,
    "LAT": -3.28,
    "CIDADE": "Iranduba",
    "UF": "AMAZONAS",
    "anual": 4.52,
    "JAN": 4.06,
    "FEB": 4.11,
    "MAR": 4.17,
    "APR": 4.07,
    "MAY": 4.14,
    "JUN": 4.67,
    "JUL": 4.75,
    "AUG": 5.23,
    "SEP": 5.15,
    "OCT": 4.96,
    "NOV": 4.8,
    "DEC": 4.16
  },
  {
    "LONG": -64.8,
    "LAT": -3.23,
    "CIDADE": "Alvarães",
    "UF": "AMAZONAS",
    "anual": 4.55,
    "JAN": 4.22,
    "FEB": 4.52,
    "MAR": 4.51,
    "APR": 4.35,
    "MAY": 4.08,
    "JUN": 4.39,
    "JUL": 4.47,
    "AUG": 5.04,
    "SEP": 5.05,
    "OCT": 4.96,
    "NOV": 4.68,
    "DEC": 4.39
  },
  {
    "LONG": -59.83,
    "LAT": -3.2,
    "CIDADE": "Careiro da Várzea",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.03,
    "FEB": 4.14,
    "MAR": 4.19,
    "APR": 4.07,
    "MAY": 4.11,
    "JUN": 4.69,
    "JUL": 4.72,
    "AUG": 5.24,
    "SEP": 5.23,
    "OCT": 4.95,
    "NOV": 4.78,
    "DEC": 4.23
  },
  {
    "LONG": -67.95,
    "LAT": -3.1,
    "CIDADE": "Santo Antônio do Içá",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.25,
    "FEB": 4.65,
    "MAR": 4.5,
    "APR": 4.44,
    "MAY": 4.09,
    "JUN": 4.24,
    "JUL": 4.25,
    "AUG": 4.92,
    "SEP": 5.04,
    "OCT": 4.95,
    "NOV": 4.67,
    "DEC": 4.32
  },
  {
    "LONG": -60.02,
    "LAT": -3.12,
    "CIDADE": "Manaus",
    "UF": "AMAZONAS",
    "anual": 4.33,
    "JAN": 3.87,
    "FEB": 3.97,
    "MAR": 4.04,
    "APR": 3.87,
    "MAY": 3.95,
    "JUN": 4.5,
    "JUL": 4.53,
    "AUG": 5.01,
    "SEP": 4.9,
    "OCT": 4.73,
    "NOV": 4.54,
    "DEC": 4.03
  },
  {
    "LONG": -58.45,
    "LAT": -0.31,
    "CIDADE": "Itacoatiara",
    "UF": "AMAZONAS",
    "anual": 4.54,
    "JAN": 4.03,
    "FEB": 4.13,
    "MAR": 4.14,
    "APR": 4.02,
    "MAY": 4.06,
    "JUN": 4.69,
    "JUL": 4.69,
    "AUG": 5.28,
    "SEP": 5.27,
    "OCT": 5.04,
    "NOV": 4.8,
    "DEC": 4.34
  },
  {
    "LONG": -58.15,
    "LAT": -3.13,
    "CIDADE": "Urucurituba",
    "UF": "AMAZONAS",
    "anual": 4.44,
    "JAN": 3.89,
    "FEB": 4.1,
    "MAR": 4.05,
    "APR": 3.88,
    "MAY": 4.03,
    "JUN": 4.62,
    "JUL": 4.59,
    "AUG": 5.17,
    "SEP": 5.12,
    "OCT": 4.93,
    "NOV": 4.67,
    "DEC": 4.23
  },
  {
    "LONG": -65.11,
    "LAT": -3,
    "CIDADE": "Uarini",
    "UF": "AMAZONAS",
    "anual": 4.57,
    "JAN": 4.34,
    "FEB": 4.65,
    "MAR": 4.58,
    "APR": 4.36,
    "MAY": 4.03,
    "JUN": 4.38,
    "JUL": 4.41,
    "AUG": 5.01,
    "SEP": 5.01,
    "OCT": 4.99,
    "NOV": 4.65,
    "DEC": 4.46
  },
  {
    "LONG": -57.59,
    "LAT": -2.97,
    "CIDADE": "Boa Vista do Ramos",
    "UF": "AMAZONAS",
    "anual": 4.38,
    "JAN": 3.84,
    "FEB": 4.01,
    "MAR": 3.99,
    "APR": 3.77,
    "MAY": 3.89,
    "JUN": 4.49,
    "JUL": 4.48,
    "AUG": 5.11,
    "SEP": 5.07,
    "OCT": 4.94,
    "NOV": 4.72,
    "DEC": 4.23
  },
  {
    "LONG": -67.79,
    "LAT": -2.87,
    "CIDADE": "Tonantins",
    "UF": "AMAZONAS",
    "anual": 4.53,
    "JAN": 4.31,
    "FEB": 4.7,
    "MAR": 4.51,
    "APR": 4.41,
    "MAY": 4.07,
    "JUN": 4.2,
    "JUL": 4.21,
    "AUG": 4.88,
    "SEP": 5.07,
    "OCT": 4.92,
    "NOV": 4.7,
    "DEC": 4.4
  },
  {
    "LONG": -58.21,
    "LAT": -2.84,
    "CIDADE": "Silves",
    "UF": "AMAZONAS",
    "anual": 4.45,
    "JAN": 3.9,
    "FEB": 4.05,
    "MAR": 4.05,
    "APR": 3.92,
    "MAY": 4.04,
    "JUN": 4.67,
    "JUL": 4.6,
    "AUG": 5.23,
    "SEP": 5.18,
    "OCT": 4.88,
    "NOV": 4.67,
    "DEC": 4.27
  },
  {
    "LONG": -57.07,
    "LAT": -2.8,
    "CIDADE": "Barreirinha",
    "UF": "AMAZONAS",
    "anual": 4.57,
    "JAN": 4.04,
    "FEB": 4.18,
    "MAR": 4.15,
    "APR": 4,
    "MAY": 4.15,
    "JUN": 4.75,
    "JUL": 4.65,
    "AUG": 5.29,
    "SEP": 5.13,
    "OCT": 5.17,
    "NOV": 4.96,
    "DEC": 4.42
  },
  {
    "LONG": -66.77,
    "LAT": -0.27,
    "CIDADE": "Jutaí",
    "UF": "AMAZONAS",
    "anual": 4.49,
    "JAN": 4.3,
    "FEB": 4.66,
    "MAR": 4.51,
    "APR": 4.32,
    "MAY": 3.97,
    "JUN": 4.23,
    "JUL": 4.2,
    "AUG": 4.85,
    "SEP": 5.01,
    "OCT": 4.87,
    "NOV": 4.65,
    "DEC": 4.38
  },
  {
    "LONG": -59.69,
    "LAT": -2.7,
    "CIDADE": "Rio Preto da Eva",
    "UF": "AMAZONAS",
    "anual": 4.33,
    "JAN": 3.93,
    "FEB": 4.04,
    "MAR": 4.02,
    "APR": 3.82,
    "MAY": 3.86,
    "JUN": 4.37,
    "JUL": 4.38,
    "AUG": 5.01,
    "SEP": 5.03,
    "OCT": 4.82,
    "NOV": 4.59,
    "DEC": 4.13
  },
  {
    "LONG": -58.03,
    "LAT": -2.74,
    "CIDADE": "Itapiranga",
    "UF": "AMAZONAS",
    "anual": 4.54,
    "JAN": 3.92,
    "FEB": 4.09,
    "MAR": 4.12,
    "APR": 4.02,
    "MAY": 4.15,
    "JUN": 4.69,
    "JUL": 4.63,
    "AUG": 5.26,
    "SEP": 5.3,
    "OCT": 5.08,
    "NOV": 4.86,
    "DEC": 4.36
  },
  {
    "LONG": -60.94,
    "LAT": -2.64,
    "CIDADE": "Novo Airão",
    "UF": "AMAZONAS",
    "anual": 4.54,
    "JAN": 4.11,
    "FEB": 4.26,
    "MAR": 4.24,
    "APR": 4.07,
    "MAY": 4.12,
    "JUN": 4.6,
    "JUL": 4.68,
    "AUG": 5.18,
    "SEP": 5.15,
    "OCT": 5,
    "NOV": 4.74,
    "DEC": 4.3
  },
  {
    "LONG": -57.87,
    "LAT": -2.56,
    "CIDADE": "São Sebastião do Uatumã",
    "UF": "AMAZONAS",
    "anual": 4.57,
    "JAN": 3.96,
    "FEB": 4.08,
    "MAR": 4.11,
    "APR": 4,
    "MAY": 4.14,
    "JUN": 4.8,
    "JUL": 4.73,
    "AUG": 5.32,
    "SEP": 5.26,
    "OCT": 5.14,
    "NOV": 4.9,
    "DEC": 4.39
  },
  {
    "LONG": -56.73,
    "LAT": -2.64,
    "CIDADE": "Parintins",
    "UF": "AMAZONAS",
    "anual": 4.62,
    "JAN": 4.17,
    "FEB": 4.23,
    "MAR": 4.16,
    "APR": 3.95,
    "MAY": 4.12,
    "JUN": 4.69,
    "JUL": 4.63,
    "AUG": 5.33,
    "SEP": 5.2,
    "OCT": 5.32,
    "NOV": 5.1,
    "DEC": 4.53
  },
  {
    "LONG": -66.09,
    "LAT": -2.52,
    "CIDADE": "Fonte Boa",
    "UF": "AMAZONAS",
    "anual": 4.58,
    "JAN": 4.39,
    "FEB": 4.68,
    "MAR": 4.59,
    "APR": 4.37,
    "MAY": 4.1,
    "JUN": 4.3,
    "JUL": 4.32,
    "AUG": 4.94,
    "SEP": 5.11,
    "OCT": 4.93,
    "NOV": 4.74,
    "DEC": 4.48
  },
  {
    "LONG": -57.75,
    "LAT": -2.53,
    "CIDADE": "Urucará",
    "UF": "AMAZONAS",
    "anual": 4.52,
    "JAN": 3.89,
    "FEB": 4.02,
    "MAR": 4.01,
    "APR": 3.92,
    "MAY": 4.07,
    "JUN": 4.76,
    "JUL": 4.73,
    "AUG": 5.34,
    "SEP": 5.25,
    "OCT": 5.1,
    "NOV": 4.8,
    "DEC": 4.33
  },
  {
    "LONG": -56.71,
    "LAT": -2.21,
    "CIDADE": "Nhamundá",
    "UF": "AMAZONAS",
    "anual": 4.65,
    "JAN": 4.1,
    "FEB": 4.16,
    "MAR": 4.21,
    "APR": 4.12,
    "MAY": 4.23,
    "JUN": 4.83,
    "JUL": 4.83,
    "AUG": 5.38,
    "SEP": 5.23,
    "OCT": 5.26,
    "NOV": 5.01,
    "DEC": 4.46
  },
  {
    "LONG": -60.02,
    "LAT": -2.03,
    "CIDADE": "Presidente Figueiredo",
    "UF": "AMAZONAS",
    "anual": 4.37,
    "JAN": 4.09,
    "FEB": 4.13,
    "MAR": 4.14,
    "APR": 3.9,
    "MAY": 3.89,
    "JUN": 4.38,
    "JUL": 4.34,
    "AUG": 4.95,
    "SEP": 4.97,
    "OCT": 4.85,
    "NOV": 4.56,
    "DEC": 4.26
  },
  {
    "LONG": -67,
    "LAT": -1.88,
    "CIDADE": "Japurá",
    "UF": "AMAZONAS",
    "anual": 4.56,
    "JAN": 4.45,
    "FEB": 4.71,
    "MAR": 4.56,
    "APR": 4.34,
    "MAY": 4.01,
    "JUN": 4.21,
    "JUL": 4.24,
    "AUG": 4.9,
    "SEP": 5.06,
    "OCT": 4.94,
    "NOV": 4.75,
    "DEC": 4.5
  },
  {
    "LONG": -65.57,
    "LAT": -1.85,
    "CIDADE": "Maraã",
    "UF": "AMAZONAS",
    "anual": 4.59,
    "JAN": 4.48,
    "FEB": 4.65,
    "MAR": 4.55,
    "APR": 4.38,
    "MAY": 4.06,
    "JUN": 4.31,
    "JUL": 4.39,
    "AUG": 5.01,
    "SEP": 5.12,
    "OCT": 4.96,
    "NOV": 4.7,
    "DEC": 4.47
  },
  {
    "LONG": -62.93,
    "LAT": "#VALOR!",
    "CIDADE": "Barcelos",
    "UF": "AMAZONAS",
    "anual": 4.61,
    "JAN": 4.49,
    "FEB": 4.65,
    "MAR": 4.52,
    "APR": 4.24,
    "MAY": 3.96,
    "JUN": 4.38,
    "JUL": 4.43,
    "AUG": 4.98,
    "SEP": 5.17,
    "OCT": 5.03,
    "NOV": 4.8,
    "DEC": 4.68
  },
  {
    "LONG": -65.01,
    "LAT": "#VALOR!",
    "CIDADE": "Santa Isabel do Rio Negro",
    "UF": "AMAZONAS",
    "anual": 4.6,
    "JAN": 4.56,
    "FEB": 4.72,
    "MAR": 4.57,
    "APR": 4.39,
    "MAY": 3.97,
    "JUN": 4.31,
    "JUL": 4.35,
    "AUG": 4.89,
    "SEP": 5.13,
    "OCT": 5.01,
    "NOV": 4.76,
    "DEC": 4.57
  },
  {
    "LONG": -67.08,
    "LAT": "#VALOR!",
    "CIDADE": "São Gabriel da Cachoeira",
    "UF": "AMAZONAS",
    "anual": 4.66,
    "JAN": 4.67,
    "FEB": 4.86,
    "MAR": 4.92,
    "APR": 4.55,
    "MAY": 4.21,
    "JUN": 4.32,
    "JUL": 4.3,
    "AUG": 4.74,
    "SEP": 5.02,
    "OCT": 5.01,
    "NOV": 4.78,
    "DEC": 4.58
  },
  {
    "LONG": -39.56,
    "LAT": -18.08,
    "CIDADE": "Mucuri",
    "UF": "BAHIA",
    "anual": 5.26,
    "JAN": 5.78,
    "FEB": 6.18,
    "MAR": 5.73,
    "APR": 5.23,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.56,
    "AUG": 5.24,
    "SEP": 5.42,
    "OCT": 5.28,
    "NOV": 5,
    "DEC": 5.56
  },
  {
    "LONG": -39.37,
    "LAT": -17.89,
    "CIDADE": "Nova Viçosa",
    "UF": "BAHIA",
    "anual": 5.18,
    "JAN": 5.64,
    "FEB": 6.01,
    "MAR": 5.63,
    "APR": 5.09,
    "MAY": 4.62,
    "JUN": 4.42,
    "JUL": 4.51,
    "AUG": 5.14,
    "SEP": 5.3,
    "OCT": 5.25,
    "NOV": 5,
    "DEC": 5.56
  },
  {
    "LONG": -40.11,
    "LAT": -17.68,
    "CIDADE": "Ibirapuã",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.6,
    "FEB": 5.89,
    "MAR": 5.47,
    "APR": 5,
    "MAY": 4.47,
    "JUN": 4.13,
    "JUL": 4.27,
    "AUG": 4.82,
    "SEP": 5.1,
    "OCT": 5.07,
    "NOV": 4.83,
    "DEC": 5.52
  },
  {
    "LONG": "#VALOR!",
    "LAT": -17.73,
    "CIDADE": "Caravelas",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.7,
    "FEB": 6.02,
    "MAR": 5.61,
    "APR": 4.94,
    "MAY": 4.48,
    "JUN": 4.29,
    "JUL": 4.42,
    "AUG": 5.04,
    "SEP": 5.2,
    "OCT": 5.25,
    "NOV": 5.06,
    "DEC": 5.67
  },
  {
    "LONG": -40.34,
    "LAT": -17.61,
    "CIDADE": "Lajedão",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.58,
    "FEB": 5.97,
    "MAR": 5.51,
    "APR": 5.05,
    "MAY": 4.46,
    "JUN": 4.15,
    "JUL": 4.21,
    "AUG": 4.78,
    "SEP": 5.15,
    "OCT": 5.11,
    "NOV": 4.86,
    "DEC": 5.46
  },
  {
    "LONG": -39.74,
    "LAT": -17.54,
    "CIDADE": "Teixeira de Freitas",
    "UF": "BAHIA",
    "anual": 4.98,
    "JAN": 5.6,
    "FEB": 5.8,
    "MAR": 5.32,
    "APR": 4.92,
    "MAY": 4.43,
    "JUN": 4.1,
    "JUL": 4.27,
    "AUG": 4.84,
    "SEP": 5.09,
    "OCT": 5.04,
    "NOV": 4.86,
    "DEC": 5.49
  },
  {
    "LONG": -39.2,
    "LAT": -17.52,
    "CIDADE": "Alcobaça",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.83,
    "FEB": 6.17,
    "MAR": 5.65,
    "APR": 4.98,
    "MAY": 4.5,
    "JUN": 4.32,
    "JUL": 4.49,
    "AUG": 5.06,
    "SEP": 5.3,
    "OCT": 5.3,
    "NOV": 5.15,
    "DEC": 5.74
  },
  {
    "LONG": -40.22,
    "LAT": -17.37,
    "CIDADE": "Medeiros Neto",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.61,
    "FEB": 5.93,
    "MAR": 5.56,
    "APR": 5.04,
    "MAY": 4.43,
    "JUN": 4.11,
    "JUL": 4.24,
    "AUG": 4.83,
    "SEP": 5.2,
    "OCT": 5.14,
    "NOV": 4.94,
    "DEC": 5.55
  },
  {
    "LONG": -39.22,
    "LAT": -17.34,
    "CIDADE": "Prado",
    "UF": "BAHIA",
    "anual": 5.22,
    "JAN": 5.76,
    "FEB": 6.15,
    "MAR": 5.69,
    "APR": 5,
    "MAY": 4.57,
    "JUN": 4.39,
    "JUL": 4.51,
    "AUG": 5.11,
    "SEP": 5.36,
    "OCT": 5.33,
    "NOV": 5.13,
    "DEC": 5.71
  },
  {
    "LONG": -40.33,
    "LAT": -17.16,
    "CIDADE": "Itanhém",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.54,
    "FEB": 5.95,
    "MAR": 5.57,
    "APR": 5.07,
    "MAY": 4.44,
    "JUN": 4.14,
    "JUL": 4.28,
    "AUG": 4.9,
    "SEP": 5.29,
    "OCT": 5.15,
    "NOV": 4.92,
    "DEC": 5.59
  },
  {
    "LONG": -40.1,
    "LAT": -17.22,
    "CIDADE": "Vereda",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.59,
    "FEB": 5.99,
    "MAR": 5.61,
    "APR": 5.04,
    "MAY": 4.45,
    "JUN": 4.13,
    "JUL": 4.27,
    "AUG": 4.84,
    "SEP": 5.22,
    "OCT": 5.15,
    "NOV": 4.94,
    "DEC": 5.57
  },
  {
    "LONG": -39.54,
    "LAT": -17.04,
    "CIDADE": "Itamaraju",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.45,
    "FEB": 5.8,
    "MAR": 5.47,
    "APR": 4.85,
    "MAY": 4.39,
    "JUN": 4.08,
    "JUL": 4.24,
    "AUG": 4.77,
    "SEP": 5.03,
    "OCT": 4.98,
    "NOV": 4.84,
    "DEC": 5.43
  },
  {
    "LONG": -40.16,
    "LAT": -16.84,
    "CIDADE": "Jucuruçu",
    "UF": "BAHIA",
    "anual": 4.98,
    "JAN": 5.44,
    "FEB": 5.84,
    "MAR": 5.52,
    "APR": 5.02,
    "MAY": 4.42,
    "JUN": 4.13,
    "JUL": 4.23,
    "AUG": 4.75,
    "SEP": 5.2,
    "OCT": 5.01,
    "NOV": 4.79,
    "DEC": 5.46
  },
  {
    "LONG": -3.98,
    "LAT": -16.58,
    "CIDADE": "Guaratinga",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.38,
    "FEB": 5.73,
    "MAR": 5.49,
    "APR": 5.01,
    "MAY": 4.49,
    "JUN": 4.13,
    "JUL": 4.32,
    "AUG": 4.69,
    "SEP": 5.04,
    "OCT": 4.93,
    "NOV": 4.77,
    "DEC": 5.39
  },
  {
    "LONG": -39.56,
    "LAT": -16.57,
    "CIDADE": "Itabela",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.37,
    "FEB": 5.78,
    "MAR": 5.45,
    "APR": 4.92,
    "MAY": 4.42,
    "JUN": 4.08,
    "JUL": 4.27,
    "AUG": 4.69,
    "SEP": 5.04,
    "OCT": 4.96,
    "NOV": 4.78,
    "DEC": 5.4
  },
  {
    "LONG": -39.58,
    "LAT": -1.64,
    "CIDADE": "Eunápolis",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.38,
    "FEB": 5.69,
    "MAR": 5.41,
    "APR": 4.97,
    "MAY": 4.41,
    "JUN": 4.09,
    "JUL": 4.27,
    "AUG": 4.68,
    "SEP": 5.04,
    "OCT": 4.96,
    "NOV": 4.78,
    "DEC": 5.39
  },
  {
    "LONG": -39.06,
    "LAT": -16.44,
    "CIDADE": "Porto Seguro",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.68,
    "FEB": 6.07,
    "MAR": 5.79,
    "APR": 5.1,
    "MAY": 4.61,
    "JUN": 4.33,
    "JUL": 4.53,
    "AUG": 5.07,
    "SEP": 5.32,
    "OCT": 5.31,
    "NOV": 5.1,
    "DEC": 5.66
  },
  {
    "LONG": -39.02,
    "LAT": -16.28,
    "CIDADE": "Santa Cruz Cabrália",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.64,
    "FEB": 6,
    "MAR": 5.71,
    "APR": 5.08,
    "MAY": 4.54,
    "JUN": 4.32,
    "JUL": 4.48,
    "AUG": 5.06,
    "SEP": 5.25,
    "OCT": 5.25,
    "NOV": 5.02,
    "DEC": 5.6
  },
  {
    "LONG": -39.61,
    "LAT": -16.08,
    "CIDADE": "Itagimirim",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.42,
    "FEB": 5.75,
    "MAR": 5.44,
    "APR": 4.99,
    "MAY": 4.35,
    "JUN": 4.07,
    "JUL": 4.21,
    "AUG": 4.69,
    "SEP": 5.08,
    "OCT": 5,
    "NOV": 4.89,
    "DEC": 5.44
  },
  {
    "LONG": -39.53,
    "LAT": -15.97,
    "CIDADE": "Itapebi",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.43,
    "FEB": 5.73,
    "MAR": 5.46,
    "APR": 4.98,
    "MAY": 4.33,
    "JUN": 4.08,
    "JUL": 4.23,
    "AUG": 4.74,
    "SEP": 5.01,
    "OCT": 4.95,
    "NOV": 4.82,
    "DEC": 5.41
  },
  {
    "LONG": -38.88,
    "LAT": -15.86,
    "CIDADE": "Belmonte",
    "UF": "BAHIA",
    "anual": 5.36,
    "JAN": 5.77,
    "FEB": 6.19,
    "MAR": 5.87,
    "APR": 5.24,
    "MAY": 4.73,
    "JUN": 4.41,
    "JUL": 4.62,
    "AUG": 5.3,
    "SEP": 5.5,
    "OCT": 5.54,
    "NOV": 5.37,
    "DEC": 5.8
  },
  {
    "LONG": -40.07,
    "LAT": -15.65,
    "CIDADE": "Itarantim",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.51,
    "FEB": 5.81,
    "MAR": 5.58,
    "APR": 5.03,
    "MAY": 4.41,
    "JUN": 4.03,
    "JUL": 4.18,
    "AUG": 4.68,
    "SEP": 5.19,
    "OCT": 5.07,
    "NOV": 4.9,
    "DEC": 5.56
  },
  {
    "LONG": -3.9,
    "LAT": -15.67,
    "CIDADE": "Canavieiras",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.73,
    "FEB": 6.12,
    "MAR": 5.84,
    "APR": 5.13,
    "MAY": 4.57,
    "JUN": 4.29,
    "JUL": 4.53,
    "AUG": 5.11,
    "SEP": 5.41,
    "OCT": 5.43,
    "NOV": 5.28,
    "DEC": 5.82
  },
  {
    "LONG": -40.42,
    "LAT": -1.56,
    "CIDADE": "Macarani",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.5,
    "FEB": 5.88,
    "MAR": 5.53,
    "APR": 5.04,
    "MAY": 4.4,
    "JUN": 3.98,
    "JUL": 4.14,
    "AUG": 4.56,
    "SEP": 5.11,
    "OCT": 4.96,
    "NOV": 4.84,
    "DEC": 5.54
  },
  {
    "LONG": -40.26,
    "LAT": -15.62,
    "CIDADE": "Maiquinique",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.4,
    "FEB": 5.78,
    "MAR": 5.42,
    "APR": 5.02,
    "MAY": 4.41,
    "JUN": 4.01,
    "JUL": 4.15,
    "AUG": 4.53,
    "SEP": 5.08,
    "OCT": 4.94,
    "NOV": 4.79,
    "DEC": 5.44
  },
  {
    "LONG": -39.87,
    "LAT": -15.59,
    "CIDADE": "Potiraguá",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.41,
    "FEB": 5.75,
    "MAR": 5.46,
    "APR": 4.94,
    "MAY": 4.34,
    "JUN": 4.01,
    "JUL": 4.17,
    "AUG": 4.67,
    "SEP": 5.05,
    "OCT": 4.95,
    "NOV": 4.82,
    "DEC": 5.46
  },
  {
    "LONG": -39.3,
    "LAT": -15.56,
    "CIDADE": "Mascote",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.39,
    "FEB": 5.68,
    "MAR": 5.51,
    "APR": 4.88,
    "MAY": 4.33,
    "JUN": 4,
    "JUL": 4.24,
    "AUG": 4.72,
    "SEP": 5.04,
    "OCT": 4.93,
    "NOV": 4.82,
    "DEC": 5.41
  },
  {
    "LONG": -41.24,
    "LAT": -15.51,
    "CIDADE": "Cândido Sales",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.52,
    "FEB": 5.87,
    "MAR": 5.42,
    "APR": 5.06,
    "MAY": 4.51,
    "JUN": 4.2,
    "JUL": 4.43,
    "AUG": 4.93,
    "SEP": 5.65,
    "OCT": 5.42,
    "NOV": 5.01,
    "DEC": 5.51
  },
  {
    "LONG": -40.91,
    "LAT": -15.53,
    "CIDADE": "Encruzilhada",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.57,
    "FEB": 5.75,
    "MAR": 5.38,
    "APR": 4.98,
    "MAY": 4.43,
    "JUN": 4.13,
    "JUL": 4.34,
    "AUG": 4.8,
    "SEP": 5.48,
    "OCT": 5.26,
    "NOV": 4.97,
    "DEC": 5.54
  },
  {
    "LONG": -40.74,
    "LAT": -15.45,
    "CIDADE": "Ribeirão do Largo",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.51,
    "FEB": 5.77,
    "MAR": 5.43,
    "APR": 4.96,
    "MAY": 4.4,
    "JUN": 4.06,
    "JUL": 4.25,
    "AUG": 4.68,
    "SEP": 5.3,
    "OCT": 5.18,
    "NOV": 4.88,
    "DEC": 5.47
  },
  {
    "LONG": -39.64,
    "LAT": -15.46,
    "CIDADE": "Pau Brasil",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.36,
    "FEB": 5.71,
    "MAR": 5.4,
    "APR": 4.91,
    "MAY": 4.32,
    "JUN": 4.02,
    "JUL": 4.22,
    "AUG": 4.77,
    "SEP": 5.23,
    "OCT": 5.08,
    "NOV": 4.86,
    "DEC": 5.4
  },
  {
    "LONG": -39.49,
    "LAT": -15.41,
    "CIDADE": "Camacan",
    "UF": "BAHIA",
    "anual": 4.84,
    "JAN": 5.31,
    "FEB": 5.56,
    "MAR": 5.44,
    "APR": 4.81,
    "MAY": 4.35,
    "JUN": 3.92,
    "JUL": 4.09,
    "AUG": 4.6,
    "SEP": 4.97,
    "OCT": 4.85,
    "NOV": 4.78,
    "DEC": 5.43
  },
  {
    "LONG": -3.93,
    "LAT": -15.43,
    "CIDADE": "Santa Luzia",
    "UF": "BAHIA",
    "anual": 4.81,
    "JAN": 5.32,
    "FEB": 5.58,
    "MAR": 5.37,
    "APR": 4.75,
    "MAY": 4.25,
    "JUN": 3.92,
    "JUL": 4.09,
    "AUG": 4.62,
    "SEP": 4.93,
    "OCT": 4.81,
    "NOV": 4.75,
    "DEC": 5.39
  },
  {
    "LONG": -39.42,
    "LAT": -15.27,
    "CIDADE": "Arataca",
    "UF": "BAHIA",
    "anual": 4.75,
    "JAN": 5.15,
    "FEB": 5.51,
    "MAR": 5.37,
    "APR": 4.77,
    "MAY": 4.26,
    "JUN": 3.88,
    "JUL": 4.07,
    "AUG": 4.48,
    "SEP": 4.83,
    "OCT": 4.75,
    "NOV": 4.65,
    "DEC": 5.29
  },
  {
    "LONG": -3.91,
    "LAT": -15.29,
    "CIDADE": "Una",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.6,
    "FEB": 5.94,
    "MAR": 5.65,
    "APR": 4.99,
    "MAY": 4.42,
    "JUN": 4.19,
    "JUL": 4.4,
    "AUG": 4.92,
    "SEP": 5.21,
    "OCT": 5.17,
    "NOV": 5.1,
    "DEC": 5.67
  },
  {
    "LONG": -40.63,
    "LAT": -15.24,
    "CIDADE": "Itambé",
    "UF": "BAHIA",
    "anual": 5,
    "JAN": 5.57,
    "FEB": 5.81,
    "MAR": 5.57,
    "APR": 5.02,
    "MAY": 4.4,
    "JUN": 3.96,
    "JUL": 4.17,
    "AUG": 4.55,
    "SEP": 5.24,
    "OCT": 5.14,
    "NOV": 4.99,
    "DEC": 5.57
  },
  {
    "LONG": -40.25,
    "LAT": -1.52,
    "CIDADE": "Itapetinga",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.4,
    "FEB": 5.79,
    "MAR": 5.48,
    "APR": 4.9,
    "MAY": 4.34,
    "JUN": 3.96,
    "JUL": 4.16,
    "AUG": 4.63,
    "SEP": 5.17,
    "OCT": 5.06,
    "NOV": 4.95,
    "DEC": 5.55
  },
  {
    "LONG": -39.49,
    "LAT": -1.52,
    "CIDADE": "Jussari",
    "UF": "BAHIA",
    "anual": 4.79,
    "JAN": 5.16,
    "FEB": 5.46,
    "MAR": 5.34,
    "APR": 4.81,
    "MAY": 4.31,
    "JUN": 4,
    "JUL": 4.2,
    "AUG": 4.59,
    "SEP": 4.86,
    "OCT": 4.78,
    "NOV": 4.65,
    "DEC": 5.27
  },
  {
    "LONG": -40.07,
    "LAT": -15.11,
    "CIDADE": "Itororó",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.39,
    "FEB": 5.71,
    "MAR": 5.44,
    "APR": 4.93,
    "MAY": 4.39,
    "JUN": 3.97,
    "JUL": 4.17,
    "AUG": 4.59,
    "SEP": 5.05,
    "OCT": 5,
    "NOV": 4.91,
    "DEC": 5.45
  },
  {
    "LONG": -39.72,
    "LAT": -15.14,
    "CIDADE": "Itaju do Colônia",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.45,
    "FEB": 5.57,
    "MAR": 5.48,
    "APR": 4.88,
    "MAY": 4.34,
    "JUN": 4.01,
    "JUL": 4.25,
    "AUG": 4.64,
    "SEP": 5.04,
    "OCT": 5.01,
    "NOV": 4.9,
    "DEC": 5.43
  },
  {
    "LONG": -39.34,
    "LAT": -15.08,
    "CIDADE": "São José da Vitória",
    "UF": "BAHIA",
    "anual": 4.77,
    "JAN": 5.25,
    "FEB": 5.52,
    "MAR": 5.27,
    "APR": 4.71,
    "MAY": 4.2,
    "JUN": 3.9,
    "JUL": 4.13,
    "AUG": 4.54,
    "SEP": 4.91,
    "OCT": 4.78,
    "NOV": 4.66,
    "DEC": 5.32
  },
  {
    "LONG": -42.37,
    "LAT": -15.02,
    "CIDADE": "Mortugaba",
    "UF": "BAHIA",
    "anual": 5.43,
    "JAN": 5.64,
    "FEB": 6.08,
    "MAR": 5.66,
    "APR": 5.24,
    "MAY": 4.91,
    "JUN": 4.64,
    "JUL": 4.97,
    "AUG": 5.57,
    "SEP": 5.93,
    "OCT": 5.76,
    "NOV": 5.1,
    "DEC": 5.59
  },
  {
    "LONG": -41.93,
    "LAT": -15.04,
    "CIDADE": "Cordeiros",
    "UF": "BAHIA",
    "anual": 5.4,
    "JAN": 5.7,
    "FEB": 6.08,
    "MAR": 5.64,
    "APR": 5.25,
    "MAY": 4.89,
    "JUN": 4.5,
    "JUL": 4.82,
    "AUG": 5.42,
    "SEP": 5.91,
    "OCT": 5.73,
    "NOV": 5.17,
    "DEC": 5.68
  },
  {
    "LONG": -4.14,
    "LAT": -14.98,
    "CIDADE": "Tremedal",
    "UF": "BAHIA",
    "anual": 5.33,
    "JAN": 5.67,
    "FEB": 6.04,
    "MAR": 5.68,
    "APR": 5.23,
    "MAY": 4.8,
    "JUN": 4.45,
    "JUL": 4.7,
    "AUG": 5.16,
    "SEP": 5.8,
    "OCT": 5.61,
    "NOV": 5.21,
    "DEC": 5.59
  },
  {
    "LONG": -41.27,
    "LAT": -15.03,
    "CIDADE": "Belo Campo",
    "UF": "BAHIA",
    "anual": 5.31,
    "JAN": 5.65,
    "FEB": 5.89,
    "MAR": 5.6,
    "APR": 5.17,
    "MAY": 4.81,
    "JUN": 4.39,
    "JUL": 4.67,
    "AUG": 5.22,
    "SEP": 5.86,
    "OCT": 5.59,
    "NOV": 5.22,
    "DEC": 5.6
  },
  {
    "LONG": -40.41,
    "LAT": -14.98,
    "CIDADE": "Caatiba",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.47,
    "FEB": 5.76,
    "MAR": 5.5,
    "APR": 4.86,
    "MAY": 4.35,
    "JUN": 3.93,
    "JUL": 4.11,
    "AUG": 4.55,
    "SEP": 5.24,
    "OCT": 5.14,
    "NOV": 4.96,
    "DEC": 5.53
  },
  {
    "LONG": -39.92,
    "LAT": -14.99,
    "CIDADE": "Firmino Alves",
    "UF": "BAHIA",
    "anual": 4.87,
    "JAN": 5.34,
    "FEB": 5.61,
    "MAR": 5.4,
    "APR": 4.87,
    "MAY": 4.32,
    "JUN": 4.01,
    "JUL": 4.17,
    "AUG": 4.57,
    "SEP": 5.02,
    "OCT": 4.9,
    "NOV": 4.83,
    "DEC": 5.42
  },
  {
    "LONG": -39.81,
    "LAT": -14.96,
    "CIDADE": "Santa Cruz da Vitória",
    "UF": "BAHIA",
    "anual": 4.89,
    "JAN": 5.38,
    "FEB": 5.59,
    "MAR": 5.42,
    "APR": 4.89,
    "MAY": 4.32,
    "JUN": 4.01,
    "JUL": 4.2,
    "AUG": 4.6,
    "SEP": 5.02,
    "OCT": 4.91,
    "NOV": 4.87,
    "DEC": 5.43
  },
  {
    "LONG": -39.3,
    "LAT": -14.96,
    "CIDADE": "Buerarema",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.37,
    "FEB": 5.63,
    "MAR": 5.4,
    "APR": 4.83,
    "MAY": 4.26,
    "JUN": 3.97,
    "JUL": 4.22,
    "AUG": 4.65,
    "SEP": 5.02,
    "OCT": 4.93,
    "NOV": 4.79,
    "DEC": 5.44
  },
  {
    "LONG": -41.97,
    "LAT": -14.9,
    "CIDADE": "Condeúba",
    "UF": "BAHIA",
    "anual": 5.43,
    "JAN": 5.71,
    "FEB": 6.08,
    "MAR": 5.73,
    "APR": 5.33,
    "MAY": 4.91,
    "JUN": 4.56,
    "JUL": 4.85,
    "AUG": 5.45,
    "SEP": 5.94,
    "OCT": 5.77,
    "NOV": 5.18,
    "DEC": 5.67
  },
  {
    "LONG": -41.72,
    "LAT": -14.94,
    "CIDADE": "Piripá",
    "UF": "BAHIA",
    "anual": 5.41,
    "JAN": 5.72,
    "FEB": 6.11,
    "MAR": 5.71,
    "APR": 5.37,
    "MAY": 4.85,
    "JUN": 4.46,
    "JUL": 4.82,
    "AUG": 5.39,
    "SEP": 5.87,
    "OCT": 5.73,
    "NOV": 5.2,
    "DEC": 5.63
  },
  {
    "LONG": -40.84,
    "LAT": -14.86,
    "CIDADE": "Vitória da Conquista",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.59,
    "FEB": 5.75,
    "MAR": 5.48,
    "APR": 5.08,
    "MAY": 4.59,
    "JUN": 4.25,
    "JUL": 4.43,
    "AUG": 4.91,
    "SEP": 5.62,
    "OCT": 5.46,
    "NOV": 5.04,
    "DEC": 5.53
  },
  {
    "LONG": -40.58,
    "LAT": -14.87,
    "CIDADE": "Barra do Choça",
    "UF": "BAHIA",
    "anual": 5.01,
    "JAN": 5.45,
    "FEB": 5.74,
    "MAR": 5.49,
    "APR": 4.92,
    "MAY": 4.47,
    "JUN": 4.05,
    "JUL": 4.27,
    "AUG": 4.66,
    "SEP": 5.38,
    "OCT": 5.28,
    "NOV": 4.95,
    "DEC": 5.46
  },
  {
    "LONG": -39.66,
    "LAT": -14.86,
    "CIDADE": "Floresta Azul",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.47,
    "FEB": 5.61,
    "MAR": 5.48,
    "APR": 4.88,
    "MAY": 4.33,
    "JUN": 4.04,
    "JUL": 4.26,
    "AUG": 4.66,
    "SEP": 5.08,
    "OCT": 4.99,
    "NOV": 4.86,
    "DEC": 5.42
  },
  {
    "LONG": -39.59,
    "LAT": -14.86,
    "CIDADE": "Ibicaraí",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.42,
    "FEB": 5.6,
    "MAR": 5.47,
    "APR": 4.88,
    "MAY": 4.35,
    "JUN": 4.02,
    "JUL": 4.22,
    "AUG": 4.65,
    "SEP": 5.06,
    "OCT": 4.96,
    "NOV": 4.86,
    "DEC": 5.43
  },
  {
    "LONG": -39.42,
    "LAT": -14.89,
    "CIDADE": "Itapé",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.39,
    "FEB": 5.64,
    "MAR": 5.49,
    "APR": 4.88,
    "MAY": 4.35,
    "JUN": 4.02,
    "JUL": 4.22,
    "AUG": 4.67,
    "SEP": 5.05,
    "OCT": 4.93,
    "NOV": 4.89,
    "DEC": 5.46
  },
  {
    "LONG": -42.66,
    "LAT": -14.77,
    "CIDADE": "Urandi",
    "UF": "BAHIA",
    "anual": 5.9,
    "JAN": 6.07,
    "FEB": 6.39,
    "MAR": 6.06,
    "APR": 5.86,
    "MAY": 5.49,
    "JUN": 5.41,
    "JUL": 5.54,
    "AUG": 6.06,
    "SEP": 6.29,
    "OCT": 6.15,
    "NOV": 5.54,
    "DEC": 5.97
  },
  {
    "LONG": -4.24,
    "LAT": -14.84,
    "CIDADE": "Jacaraci",
    "UF": "BAHIA",
    "anual": 5.46,
    "JAN": 5.62,
    "FEB": 6.04,
    "MAR": 5.62,
    "APR": 5.33,
    "MAY": 4.94,
    "JUN": 4.7,
    "JUL": 5.08,
    "AUG": 5.68,
    "SEP": 5.95,
    "OCT": 5.82,
    "NOV": 5.14,
    "DEC": 5.58
  },
  {
    "LONG": -40.14,
    "LAT": -1.48,
    "CIDADE": "Nova Canaã",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.41,
    "FEB": 5.76,
    "MAR": 5.52,
    "APR": 4.87,
    "MAY": 4.35,
    "JUN": 3.99,
    "JUL": 4.11,
    "AUG": 4.5,
    "SEP": 5.1,
    "OCT": 5.04,
    "NOV": 5,
    "DEC": 5.56
  },
  {
    "LONG": -40.09,
    "LAT": -14.75,
    "CIDADE": "Iguaí",
    "UF": "BAHIA",
    "anual": 4.9,
    "JAN": 5.34,
    "FEB": 5.69,
    "MAR": 5.5,
    "APR": 4.91,
    "MAY": 4.34,
    "JUN": 3.99,
    "JUL": 4.16,
    "AUG": 4.53,
    "SEP": 5.04,
    "OCT": 4.95,
    "NOV": 4.91,
    "DEC": 5.49
  },
  {
    "LONG": -39.99,
    "LAT": -14.85,
    "CIDADE": "Ibicuí",
    "UF": "BAHIA",
    "anual": 4.89,
    "JAN": 5.4,
    "FEB": 5.62,
    "MAR": 5.51,
    "APR": 4.88,
    "MAY": 4.35,
    "JUN": 4.06,
    "JUL": 4.15,
    "AUG": 4.57,
    "SEP": 4.95,
    "OCT": 4.85,
    "NOV": 4.81,
    "DEC": 5.48
  },
  {
    "LONG": -39.48,
    "LAT": -14.8,
    "CIDADE": "Barro Preto",
    "UF": "BAHIA",
    "anual": 4.81,
    "JAN": 5.22,
    "FEB": 5.48,
    "MAR": 5.41,
    "APR": 4.83,
    "MAY": 4.32,
    "JUN": 3.99,
    "JUL": 4.17,
    "AUG": 4.5,
    "SEP": 4.87,
    "OCT": 4.78,
    "NOV": 4.78,
    "DEC": 5.36
  },
  {
    "LONG": -39.28,
    "LAT": -1.48,
    "CIDADE": "Itabuna",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.4,
    "FEB": 5.71,
    "MAR": 5.53,
    "APR": 4.88,
    "MAY": 4.36,
    "JUN": 4.06,
    "JUL": 4.28,
    "AUG": 4.71,
    "SEP": 5.06,
    "OCT": 4.99,
    "NOV": 4.95,
    "DEC": 5.5
  },
  {
    "LONG": -39.05,
    "LAT": -14.79,
    "CIDADE": "Ilhéus",
    "UF": "BAHIA",
    "anual": 5.24,
    "JAN": 5.69,
    "FEB": 5.94,
    "MAR": 5.83,
    "APR": 5.09,
    "MAY": 4.56,
    "JUN": 4.29,
    "JUL": 4.52,
    "AUG": 4.98,
    "SEP": 5.42,
    "OCT": 5.4,
    "NOV": 5.34,
    "DEC": 5.82
  },
  {
    "LONG": -42.51,
    "LAT": -1.47,
    "CIDADE": "Licínio de Almeida",
    "UF": "BAHIA",
    "anual": 5.69,
    "JAN": 5.81,
    "FEB": 6.18,
    "MAR": 5.85,
    "APR": 5.61,
    "MAY": 5.22,
    "JUN": 5.09,
    "JUL": 5.32,
    "AUG": 5.88,
    "SEP": 6.13,
    "OCT": 6.06,
    "NOV": 5.37,
    "DEC": 5.78
  },
  {
    "LONG": -41.68,
    "LAT": -14.69,
    "CIDADE": "Presidente Jânio Quadros",
    "UF": "BAHIA",
    "anual": 5.39,
    "JAN": 5.65,
    "FEB": 6.03,
    "MAR": 5.67,
    "APR": 5.27,
    "MAY": 4.88,
    "JUN": 4.49,
    "JUL": 4.81,
    "AUG": 5.29,
    "SEP": 5.86,
    "OCT": 5.73,
    "NOV": 5.26,
    "DEC": 5.67
  },
  {
    "LONG": -41.49,
    "LAT": -14.66,
    "CIDADE": "Maetinga",
    "UF": "BAHIA",
    "anual": 5.37,
    "JAN": 5.76,
    "FEB": 5.97,
    "MAR": 5.71,
    "APR": 5.25,
    "MAY": 4.82,
    "JUN": 4.47,
    "JUL": 4.76,
    "AUG": 5.25,
    "SEP": 5.89,
    "OCT": 5.69,
    "NOV": 5.24,
    "DEC": 5.66
  },
  {
    "LONG": -41.26,
    "LAT": -14.73,
    "CIDADE": "Caraíbas",
    "UF": "BAHIA",
    "anual": 5.41,
    "JAN": 5.77,
    "FEB": 6.04,
    "MAR": 5.8,
    "APR": 5.36,
    "MAY": 4.85,
    "JUN": 4.5,
    "JUL": 4.75,
    "AUG": 5.21,
    "SEP": 5.83,
    "OCT": 5.7,
    "NOV": 5.37,
    "DEC": 5.78
  },
  {
    "LONG": -40.47,
    "LAT": -1.47,
    "CIDADE": "Planalto",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.38,
    "FEB": 5.63,
    "MAR": 5.41,
    "APR": 4.85,
    "MAY": 4.48,
    "JUN": 4.12,
    "JUL": 4.29,
    "AUG": 4.7,
    "SEP": 5.35,
    "OCT": 5.27,
    "NOV": 4.97,
    "DEC": 5.42
  },
  {
    "LONG": -39.64,
    "LAT": -14.71,
    "CIDADE": "Almadina",
    "UF": "BAHIA",
    "anual": 4.74,
    "JAN": 5.17,
    "FEB": 5.45,
    "MAR": 5.31,
    "APR": 4.71,
    "MAY": 4.18,
    "JUN": 3.91,
    "JUL": 4.07,
    "AUG": 4.4,
    "SEP": 4.84,
    "OCT": 4.78,
    "NOV": 4.75,
    "DEC": 5.33
  },
  {
    "LONG": -39.37,
    "LAT": -14.68,
    "CIDADE": "Itajuípe",
    "UF": "BAHIA",
    "anual": 4.87,
    "JAN": 5.32,
    "FEB": 5.59,
    "MAR": 5.49,
    "APR": 4.83,
    "MAY": 4.35,
    "JUN": 4.06,
    "JUL": 4.19,
    "AUG": 4.56,
    "SEP": 4.95,
    "OCT": 4.86,
    "NOV": 4.8,
    "DEC": 5.41
  },
  {
    "LONG": -42.94,
    "LAT": -14.57,
    "CIDADE": "Sebastião Laranjeiras",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 6.1,
    "FEB": 6.36,
    "MAR": 6.03,
    "APR": 5.93,
    "MAY": 5.52,
    "JUN": 5.55,
    "JUL": 5.68,
    "AUG": 6.13,
    "SEP": 6.26,
    "OCT": 6.2,
    "NOV": 5.45,
    "DEC": 5.87
  },
  {
    "LONG": -4.11,
    "LAT": -14.61,
    "CIDADE": "Anagé",
    "UF": "BAHIA",
    "anual": 5.37,
    "JAN": 5.75,
    "FEB": 5.98,
    "MAR": 5.82,
    "APR": 5.28,
    "MAY": 4.84,
    "JUN": 4.47,
    "JUL": 4.67,
    "AUG": 5.14,
    "SEP": 5.8,
    "OCT": 5.66,
    "NOV": 5.33,
    "DEC": 5.75
  },
  {
    "LONG": -39.56,
    "LAT": -14.64,
    "CIDADE": "Coaraci",
    "UF": "BAHIA",
    "anual": 4.78,
    "JAN": 5.2,
    "FEB": 5.52,
    "MAR": 5.42,
    "APR": 4.8,
    "MAY": 4.25,
    "JUN": 3.95,
    "JUL": 4.08,
    "AUG": 4.45,
    "SEP": 4.83,
    "OCT": 4.79,
    "NOV": 4.75,
    "DEC": 5.33
  },
  {
    "LONG": -39.29,
    "LAT": -14.59,
    "CIDADE": "Uruçuca",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.42,
    "FEB": 5.66,
    "MAR": 5.56,
    "APR": 4.84,
    "MAY": 4.29,
    "JUN": 4.09,
    "JUL": 4.27,
    "AUG": 4.68,
    "SEP": 5.03,
    "OCT": 4.92,
    "NOV": 4.87,
    "DEC": 5.42
  },
  {
    "LONG": -42.69,
    "LAT": -14.49,
    "CIDADE": "Pindaí",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 6.16,
    "FEB": 6.39,
    "MAR": 6.12,
    "APR": 5.9,
    "MAY": 5.52,
    "JUN": 5.42,
    "JUL": 5.6,
    "AUG": 6.12,
    "SEP": 6.2,
    "OCT": 6.31,
    "NOV": 5.63,
    "DEC": 5.94
  },
  {
    "LONG": -42.22,
    "LAT": -14.5,
    "CIDADE": "Caculé",
    "UF": "BAHIA",
    "anual": 5.51,
    "JAN": 5.81,
    "FEB": 6.15,
    "MAR": 5.74,
    "APR": 5.29,
    "MAY": 4.93,
    "JUN": 4.63,
    "JUL": 4.97,
    "AUG": 5.56,
    "SEP": 5.96,
    "OCT": 5.95,
    "NOV": 5.35,
    "DEC": 5.76
  },
  {
    "LONG": -41.94,
    "LAT": -14.55,
    "CIDADE": "Guajeru",
    "UF": "BAHIA",
    "anual": 5.49,
    "JAN": 5.82,
    "FEB": 6.07,
    "MAR": 5.79,
    "APR": 5.28,
    "MAY": 4.89,
    "JUN": 4.57,
    "JUL": 4.89,
    "AUG": 5.48,
    "SEP": 5.95,
    "OCT": 5.92,
    "NOV": 5.41,
    "DEC": 5.85
  },
  {
    "LONG": -40.36,
    "LAT": -14.52,
    "CIDADE": "Poções",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.4,
    "FEB": 5.61,
    "MAR": 5.45,
    "APR": 4.92,
    "MAY": 4.55,
    "JUN": 4.15,
    "JUL": 4.36,
    "AUG": 4.72,
    "SEP": 5.32,
    "OCT": 5.25,
    "NOV": 5.02,
    "DEC": 5.46
  },
  {
    "LONG": -43.55,
    "LAT": -14.41,
    "CIDADE": "Iuiú",
    "UF": "BAHIA",
    "anual": 5.88,
    "JAN": 5.98,
    "FEB": 6.35,
    "MAR": 5.99,
    "APR": 5.96,
    "MAY": 5.49,
    "JUN": 5.45,
    "JUL": 5.75,
    "AUG": 6.08,
    "SEP": 6.07,
    "OCT": 6.11,
    "NOV": 5.48,
    "DEC": 5.89
  },
  {
    "LONG": -4.29,
    "LAT": -14.41,
    "CIDADE": "Candiba",
    "UF": "BAHIA",
    "anual": 5.91,
    "JAN": 6.06,
    "FEB": 6.38,
    "MAR": 6.1,
    "APR": 5.86,
    "MAY": 5.48,
    "JUN": 5.37,
    "JUL": 5.56,
    "AUG": 6.1,
    "SEP": 6.21,
    "OCT": 6.27,
    "NOV": 5.55,
    "DEC": 5.92
  },
  {
    "LONG": -42.07,
    "LAT": -14.41,
    "CIDADE": "Rio do Antônio",
    "UF": "BAHIA",
    "anual": 5.54,
    "JAN": 5.86,
    "FEB": 6.15,
    "MAR": 5.76,
    "APR": 5.31,
    "MAY": 5,
    "JUN": 4.66,
    "JUL": 4.95,
    "AUG": 5.55,
    "SEP": 5.96,
    "OCT": 6.01,
    "NOV": 5.45,
    "DEC": 5.83
  },
  {
    "LONG": -41.88,
    "LAT": -14.39,
    "CIDADE": "Malhada de Pedras",
    "UF": "BAHIA",
    "anual": 5.52,
    "JAN": 5.91,
    "FEB": 6.14,
    "MAR": 5.86,
    "APR": 5.3,
    "MAY": 4.9,
    "JUN": 4.59,
    "JUL": 4.87,
    "AUG": 5.47,
    "SEP": 5.96,
    "OCT": 5.95,
    "NOV": 5.46,
    "DEC": 5.86
  },
  {
    "LONG": -41.47,
    "LAT": -14.43,
    "CIDADE": "Aracatu",
    "UF": "BAHIA",
    "anual": 5.45,
    "JAN": 5.86,
    "FEB": 6.09,
    "MAR": 5.74,
    "APR": 5.24,
    "MAY": 4.92,
    "JUN": 4.5,
    "JUL": 4.76,
    "AUG": 5.31,
    "SEP": 5.95,
    "OCT": 5.81,
    "NOV": 5.45,
    "DEC": 5.82
  },
  {
    "LONG": -40.5,
    "LAT": -1.44,
    "CIDADE": "Bom Jesus da Serra",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.48,
    "FEB": 5.7,
    "MAR": 5.55,
    "APR": 4.87,
    "MAY": 4.54,
    "JUN": 4.2,
    "JUL": 4.31,
    "AUG": 4.82,
    "SEP": 5.48,
    "OCT": 5.34,
    "NOV": 5.11,
    "DEC": 5.5
  },
  {
    "LONG": -40.21,
    "LAT": -14.36,
    "CIDADE": "Boa Nova",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.45,
    "FEB": 5.73,
    "MAR": 5.55,
    "APR": 4.94,
    "MAY": 4.56,
    "JUN": 4.13,
    "JUL": 4.35,
    "AUG": 4.77,
    "SEP": 5.34,
    "OCT": 5.27,
    "NOV": 5.06,
    "DEC": 5.49
  },
  {
    "LONG": -39.9,
    "LAT": -14.43,
    "CIDADE": "Dário Meira",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.35,
    "FEB": 5.69,
    "MAR": 5.56,
    "APR": 4.85,
    "MAY": 4.41,
    "JUN": 4.04,
    "JUL": 4.17,
    "AUG": 4.49,
    "SEP": 4.94,
    "OCT": 4.84,
    "NOV": 4.84,
    "DEC": 5.43
  },
  {
    "LONG": -39.56,
    "LAT": -1.44,
    "CIDADE": "Itapitanga",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.37,
    "FEB": 5.61,
    "MAR": 5.49,
    "APR": 4.81,
    "MAY": 4.27,
    "JUN": 4.01,
    "JUL": 4.2,
    "AUG": 4.54,
    "SEP": 4.97,
    "OCT": 4.94,
    "NOV": 4.95,
    "DEC": 5.44
  },
  {
    "LONG": -43.77,
    "LAT": -1.43,
    "CIDADE": "Carinhanha",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 6.02,
    "FEB": 6.42,
    "MAR": 6.1,
    "APR": 6.01,
    "MAY": 5.71,
    "JUN": 5.61,
    "JUL": 5.89,
    "AUG": 6.22,
    "SEP": 6.23,
    "OCT": 6.2,
    "NOV": 5.49,
    "DEC": 5.85
  },
  {
    "LONG": -4.38,
    "LAT": -14.34,
    "CIDADE": "Malhada",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 6.02,
    "FEB": 6.42,
    "MAR": 6.1,
    "APR": 6.01,
    "MAY": 5.71,
    "JUN": 5.61,
    "JUL": 5.89,
    "AUG": 6.22,
    "SEP": 6.23,
    "OCT": 6.2,
    "NOV": 5.49,
    "DEC": 5.85
  },
  {
    "LONG": -43.16,
    "LAT": -1.43,
    "CIDADE": "Palmas de Monte Alto",
    "UF": "BAHIA",
    "anual": 5.93,
    "JAN": 6.03,
    "FEB": 6.33,
    "MAR": 6.03,
    "APR": 5.93,
    "MAY": 5.47,
    "JUN": 5.52,
    "JUL": 5.75,
    "AUG": 6.23,
    "SEP": 6.29,
    "OCT": 6.22,
    "NOV": 5.52,
    "DEC": 5.87
  },
  {
    "LONG": -42.26,
    "LAT": -14.27,
    "CIDADE": "Ibiassucê",
    "UF": "BAHIA",
    "anual": 5.58,
    "JAN": 5.81,
    "FEB": 6.14,
    "MAR": 5.77,
    "APR": 5.36,
    "MAY": 5,
    "JUN": 4.72,
    "JUL": 5.14,
    "AUG": 5.69,
    "SEP": 6.04,
    "OCT": 6.02,
    "NOV": 5.42,
    "DEC": 5.8
  },
  {
    "LONG": "#VALOR!",
    "LAT": -14.34,
    "CIDADE": "Caetanos",
    "UF": "BAHIA",
    "anual": 5.3,
    "JAN": 5.68,
    "FEB": 5.91,
    "MAR": 5.72,
    "APR": 5.18,
    "MAY": 4.79,
    "JUN": 4.38,
    "JUL": 4.55,
    "AUG": 5.04,
    "SEP": 5.68,
    "OCT": 5.59,
    "NOV": 5.34,
    "DEC": 5.69
  },
  {
    "LONG": -39.84,
    "LAT": -14.28,
    "CIDADE": "Itagibá",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.38,
    "FEB": 5.66,
    "MAR": 5.55,
    "APR": 4.89,
    "MAY": 4.35,
    "JUN": 4.02,
    "JUL": 4.22,
    "AUG": 4.58,
    "SEP": 4.97,
    "OCT": 4.93,
    "NOV": 4.88,
    "DEC": 5.45
  },
  {
    "LONG": -39.47,
    "LAT": -14.32,
    "CIDADE": "Gongogi",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.44,
    "FEB": 5.63,
    "MAR": 5.53,
    "APR": 4.84,
    "MAY": 4.29,
    "JUN": 3.99,
    "JUL": 4.24,
    "AUG": 4.6,
    "SEP": 5.09,
    "OCT": 5.01,
    "NOV": 4.94,
    "DEC": 5.47
  },
  {
    "LONG": -39.32,
    "LAT": -14.3,
    "CIDADE": "Ubaitaba",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.43,
    "FEB": 5.6,
    "MAR": 5.55,
    "APR": 4.81,
    "MAY": 4.27,
    "JUN": 3.98,
    "JUL": 4.2,
    "AUG": 4.6,
    "SEP": 5.05,
    "OCT": 5.01,
    "NOV": 4.93,
    "DEC": 5.49
  },
  {
    "LONG": -39.32,
    "LAT": -14.32,
    "CIDADE": "Aurelino Leal",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.43,
    "FEB": 5.6,
    "MAR": 5.55,
    "APR": 4.81,
    "MAY": 4.27,
    "JUN": 3.98,
    "JUL": 4.2,
    "AUG": 4.6,
    "SEP": 5.05,
    "OCT": 5.01,
    "NOV": 4.93,
    "DEC": 5.49
  },
  {
    "LONG": -39,
    "LAT": -14.28,
    "CIDADE": "Itacaré",
    "UF": "BAHIA",
    "anual": 5.34,
    "JAN": 5.84,
    "FEB": 6.06,
    "MAR": 5.96,
    "APR": 5.13,
    "MAY": 4.63,
    "JUN": 4.3,
    "JUL": 4.51,
    "AUG": 5.06,
    "SEP": 5.61,
    "OCT": 5.57,
    "NOV": 5.49,
    "DEC": 5.93
  },
  {
    "LONG": -44.54,
    "LAT": -14.18,
    "CIDADE": "Cocos",
    "UF": "BAHIA",
    "anual": 6,
    "JAN": 5.93,
    "FEB": 6.28,
    "MAR": 5.96,
    "APR": 6.08,
    "MAY": 5.83,
    "JUN": 5.79,
    "JUL": 6.08,
    "AUG": 6.45,
    "SEP": 6.45,
    "OCT": 6.11,
    "NOV": 5.34,
    "DEC": 5.71
  },
  {
    "LONG": -44.28,
    "LAT": -14.21,
    "CIDADE": "Feira da Mata",
    "UF": "BAHIA",
    "anual": 5.97,
    "JAN": 5.92,
    "FEB": 6.28,
    "MAR": 5.94,
    "APR": 6.05,
    "MAY": 5.8,
    "JUN": 5.72,
    "JUL": 6.05,
    "AUG": 6.34,
    "SEP": 6.34,
    "OCT": 6.07,
    "NOV": 5.34,
    "DEC": 5.75
  },
  {
    "LONG": -42.78,
    "LAT": -14.22,
    "CIDADE": "Guanambi",
    "UF": "BAHIA",
    "anual": 5.95,
    "JAN": 6.12,
    "FEB": 6.42,
    "MAR": 6.17,
    "APR": 5.91,
    "MAY": 5.54,
    "JUN": 5.39,
    "JUL": 5.62,
    "AUG": 6.08,
    "SEP": 6.13,
    "OCT": 6.35,
    "NOV": 5.62,
    "DEC": 6
  },
  {
    "LONG": -41.67,
    "LAT": -14.2,
    "CIDADE": "Brumado",
    "UF": "BAHIA",
    "anual": 5.51,
    "JAN": 5.89,
    "FEB": 6.14,
    "MAR": 5.81,
    "APR": 5.29,
    "MAY": 4.9,
    "JUN": 4.56,
    "JUL": 4.85,
    "AUG": 5.4,
    "SEP": 5.92,
    "OCT": 5.9,
    "NOV": 5.51,
    "DEC": 5.93
  },
  {
    "LONG": -40.77,
    "LAT": -14.24,
    "CIDADE": "Mirante",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.56,
    "FEB": 5.78,
    "MAR": 5.64,
    "APR": 5.04,
    "MAY": 4.67,
    "JUN": 4.31,
    "JUL": 4.47,
    "AUG": 4.99,
    "SEP": 5.64,
    "OCT": 5.49,
    "NOV": 5.31,
    "DEC": 5.59
  },
  {
    "LONG": -40,
    "LAT": -14.16,
    "CIDADE": "Itagi",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.42,
    "FEB": 5.73,
    "MAR": 5.54,
    "APR": 4.82,
    "MAY": 4.4,
    "JUN": 4.03,
    "JUL": 4.16,
    "AUG": 4.55,
    "SEP": 5.06,
    "OCT": 5.03,
    "NOV": 4.99,
    "DEC": 5.47
  },
  {
    "LONG": -39.6,
    "LAT": -14.21,
    "CIDADE": "Barra do Rocha",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.44,
    "FEB": 5.71,
    "MAR": 5.63,
    "APR": 4.87,
    "MAY": 4.28,
    "JUN": 3.99,
    "JUL": 4.23,
    "AUG": 4.65,
    "SEP": 5.1,
    "OCT": 5.02,
    "NOV": 4.97,
    "DEC": 5.43
  },
  {
    "LONG": -39.52,
    "LAT": -14.22,
    "CIDADE": "Ubatã",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.38,
    "FEB": 5.6,
    "MAR": 5.58,
    "APR": 4.81,
    "MAY": 4.27,
    "JUN": 4,
    "JUL": 4.22,
    "AUG": 4.59,
    "SEP": 5.08,
    "OCT": 5.04,
    "NOV": 4.95,
    "DEC": 5.41
  },
  {
    "LONG": -39.37,
    "LAT": -14.16,
    "CIDADE": "Ibirapitanga",
    "UF": "BAHIA",
    "anual": 4.86,
    "JAN": 5.38,
    "FEB": 5.53,
    "MAR": 5.49,
    "APR": 4.75,
    "MAY": 4.24,
    "JUN": 3.95,
    "JUL": 4.16,
    "AUG": 4.54,
    "SEP": 5,
    "OCT": 4.97,
    "NOV": 4.91,
    "DEC": 5.43
  },
  {
    "LONG": -42.49,
    "LAT": -14.07,
    "CIDADE": "Caetité",
    "UF": "BAHIA",
    "anual": 5.69,
    "JAN": 5.87,
    "FEB": 6.23,
    "MAR": 5.82,
    "APR": 5.51,
    "MAY": 5.1,
    "JUN": 4.96,
    "JUL": 5.28,
    "AUG": 5.9,
    "SEP": 6.17,
    "OCT": 6.13,
    "NOV": 5.47,
    "DEC": 5.81
  },
  {
    "LONG": -40.24,
    "LAT": -14.15,
    "CIDADE": "Manoel Vitorino",
    "UF": "BAHIA",
    "anual": 5,
    "JAN": 5.42,
    "FEB": 5.67,
    "MAR": 5.52,
    "APR": 4.87,
    "MAY": 4.44,
    "JUN": 4.08,
    "JUL": 4.22,
    "AUG": 4.65,
    "SEP": 5.27,
    "OCT": 5.15,
    "NOV": 5.09,
    "DEC": 5.57
  },
  {
    "LONG": -3.99,
    "LAT": -14.13,
    "CIDADE": "Aiquara",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.45,
    "FEB": 5.75,
    "MAR": 5.63,
    "APR": 4.87,
    "MAY": 4.36,
    "JUN": 3.99,
    "JUL": 4.16,
    "AUG": 4.61,
    "SEP": 5.13,
    "OCT": 5.04,
    "NOV": 5.02,
    "DEC": 5.49
  },
  {
    "LONG": -39.74,
    "LAT": -14.13,
    "CIDADE": "Ipiaú",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.42,
    "FEB": 5.7,
    "MAR": 5.57,
    "APR": 4.84,
    "MAY": 4.32,
    "JUN": 3.98,
    "JUL": 4.18,
    "AUG": 4.59,
    "SEP": 5.11,
    "OCT": 5.03,
    "NOV": 4.95,
    "DEC": 5.42
  },
  {
    "LONG": -39.64,
    "LAT": -14.06,
    "CIDADE": "Ibirataia",
    "UF": "BAHIA",
    "anual": 4.89,
    "JAN": 5.41,
    "FEB": 5.57,
    "MAR": 5.55,
    "APR": 4.77,
    "MAY": 4.26,
    "JUN": 3.94,
    "JUL": 4.15,
    "AUG": 4.55,
    "SEP": 5.08,
    "OCT": 5.01,
    "NOV": 4.94,
    "DEC": 5.39
  },
  {
    "LONG": -3.9,
    "LAT": -1.41,
    "CIDADE": "Maraú",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.51,
    "FEB": 5.78,
    "MAR": 5.7,
    "APR": 4.87,
    "MAY": 4.42,
    "JUN": 4.13,
    "JUL": 4.34,
    "AUG": 4.81,
    "SEP": 5.17,
    "OCT": 5.14,
    "NOV": 5.17,
    "DEC": 5.63
  },
  {
    "LONG": -42.14,
    "LAT": -1.4,
    "CIDADE": "Lagoa Real",
    "UF": "BAHIA",
    "anual": 5.63,
    "JAN": 5.89,
    "FEB": 6.23,
    "MAR": 5.86,
    "APR": 5.37,
    "MAY": 4.97,
    "JUN": 4.81,
    "JUL": 5.11,
    "AUG": 5.74,
    "SEP": 6.11,
    "OCT": 6.11,
    "NOV": 5.46,
    "DEC": 5.91
  },
  {
    "LONG": -41.25,
    "LAT": -14.02,
    "CIDADE": "Tanhaçu",
    "UF": "BAHIA",
    "anual": 5.41,
    "JAN": 5.79,
    "FEB": 6.01,
    "MAR": 5.81,
    "APR": 5.22,
    "MAY": 4.82,
    "JUN": 4.5,
    "JUL": 4.63,
    "AUG": 5.21,
    "SEP": 5.84,
    "OCT": 5.75,
    "NOV": 5.47,
    "DEC": 5.87
  },
  {
    "LONG": -39.89,
    "LAT": -14.02,
    "CIDADE": "Jitaúna",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.4,
    "FEB": 5.69,
    "MAR": 5.59,
    "APR": 4.82,
    "MAY": 4.35,
    "JUN": 3.93,
    "JUL": 4.11,
    "AUG": 4.54,
    "SEP": 5.08,
    "OCT": 4.99,
    "NOV": 5,
    "DEC": 5.46
  },
  {
    "LONG": -42.84,
    "LAT": -13.91,
    "CIDADE": "Matina",
    "UF": "BAHIA",
    "anual": 5.93,
    "JAN": 6.14,
    "FEB": 6.34,
    "MAR": 6.1,
    "APR": 5.9,
    "MAY": 5.56,
    "JUN": 5.45,
    "JUL": 5.66,
    "AUG": 6.11,
    "SEP": 6.24,
    "OCT": 6.29,
    "NOV": 5.51,
    "DEC": 5.91
  },
  {
    "LONG": -4.01,
    "LAT": -13.85,
    "CIDADE": "Jequié",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.48,
    "FEB": 5.78,
    "MAR": 5.62,
    "APR": 4.88,
    "MAY": 4.41,
    "JUN": 4.06,
    "JUL": 4.25,
    "AUG": 4.64,
    "SEP": 5.26,
    "OCT": 5.19,
    "NOV": 5.1,
    "DEC": 5.55
  },
  {
    "LONG": -39.74,
    "LAT": -13.86,
    "CIDADE": "Apuarema",
    "UF": "BAHIA",
    "anual": 4.8,
    "JAN": 5.27,
    "FEB": 5.5,
    "MAR": 5.43,
    "APR": 4.71,
    "MAY": 4.24,
    "JUN": 3.79,
    "JUL": 3.95,
    "AUG": 4.45,
    "SEP": 4.94,
    "OCT": 4.92,
    "NOV": 4.99,
    "DEC": 5.38
  },
  {
    "LONG": -39.11,
    "LAT": -13.94,
    "CIDADE": "Camamu",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.46,
    "FEB": 5.71,
    "MAR": 5.57,
    "APR": 4.76,
    "MAY": 4.29,
    "JUN": 3.94,
    "JUL": 4.17,
    "AUG": 4.61,
    "SEP": 5.04,
    "OCT": 5.02,
    "NOV": 5.03,
    "DEC": 5.5
  },
  {
    "LONG": -44.46,
    "LAT": -13.83,
    "CIDADE": "Coribe",
    "UF": "BAHIA",
    "anual": 5.93,
    "JAN": 5.9,
    "FEB": 6.17,
    "MAR": 5.78,
    "APR": 5.95,
    "MAY": 5.77,
    "JUN": 5.72,
    "JUL": 6.01,
    "AUG": 6.39,
    "SEP": 6.39,
    "OCT": 6.1,
    "NOV": 5.28,
    "DEC": 5.68
  },
  {
    "LONG": -42.72,
    "LAT": "#VALOR!",
    "CIDADE": "Igaporã",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 6,
    "FEB": 6.28,
    "MAR": 5.99,
    "APR": 5.94,
    "MAY": 5.53,
    "JUN": 5.43,
    "JUL": 5.69,
    "AUG": 6.24,
    "SEP": 6.38,
    "OCT": 6.24,
    "NOV": 5.47,
    "DEC": 5.84
  },
  {
    "LONG": -41.77,
    "LAT": -13.76,
    "CIDADE": "Dom Basílio",
    "UF": "BAHIA",
    "anual": 5.68,
    "JAN": 5.96,
    "FEB": 6.24,
    "MAR": 5.96,
    "APR": 5.52,
    "MAY": 5.05,
    "JUN": 4.81,
    "JUL": 5.09,
    "AUG": 5.62,
    "SEP": 6.17,
    "OCT": 6.13,
    "NOV": 5.61,
    "DEC": 5.95
  },
  {
    "LONG": -41.3,
    "LAT": -13.81,
    "CIDADE": "Ituaçu",
    "UF": "BAHIA",
    "anual": 5.33,
    "JAN": 5.7,
    "FEB": 5.9,
    "MAR": 5.68,
    "APR": 5.06,
    "MAY": 4.71,
    "JUN": 4.4,
    "JUL": 4.61,
    "AUG": 5.19,
    "SEP": 5.87,
    "OCT": 5.77,
    "NOV": 5.35,
    "DEC": 5.76
  },
  {
    "LONG": -4.1,
    "LAT": -13.76,
    "CIDADE": "Contendas do Sincorá",
    "UF": "BAHIA",
    "anual": 5.25,
    "JAN": 5.64,
    "FEB": 5.88,
    "MAR": 5.73,
    "APR": 5.07,
    "MAY": 4.63,
    "JUN": 4.31,
    "JUL": 4.52,
    "AUG": 5.03,
    "SEP": 5.74,
    "OCT": 5.53,
    "NOV": 5.26,
    "DEC": 5.68
  },
  {
    "LONG": -39.63,
    "LAT": -1.38,
    "CIDADE": "Nova Ibiá",
    "UF": "BAHIA",
    "anual": 4.85,
    "JAN": 5.43,
    "FEB": 5.58,
    "MAR": 5.49,
    "APR": 4.7,
    "MAY": 4.25,
    "JUN": 3.9,
    "JUL": 4.01,
    "AUG": 4.43,
    "SEP": 4.94,
    "OCT": 4.96,
    "NOV": 5.04,
    "DEC": 5.42
  },
  {
    "LONG": -39.68,
    "LAT": -13.78,
    "CIDADE": "Itamari",
    "UF": "BAHIA",
    "anual": 4.85,
    "JAN": 5.43,
    "FEB": 5.58,
    "MAR": 5.49,
    "APR": 4.7,
    "MAY": 4.25,
    "JUN": 3.9,
    "JUL": 4.01,
    "AUG": 4.43,
    "SEP": 4.94,
    "OCT": 4.96,
    "NOV": 5.04,
    "DEC": 5.42
  },
  {
    "LONG": -39.38,
    "LAT": -13.76,
    "CIDADE": "Piraí do Norte",
    "UF": "BAHIA",
    "anual": 4.84,
    "JAN": 5.4,
    "FEB": 5.56,
    "MAR": 5.53,
    "APR": 4.68,
    "MAY": 4.22,
    "JUN": 3.92,
    "JUL": 4.11,
    "AUG": 4.47,
    "SEP": 4.95,
    "OCT": 4.93,
    "NOV": 4.97,
    "DEC": 5.4
  },
  {
    "LONG": -39.14,
    "LAT": -13.82,
    "CIDADE": "Igrapiúna",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.54,
    "FEB": 5.64,
    "MAR": 5.67,
    "APR": 4.84,
    "MAY": 4.32,
    "JUN": 4.01,
    "JUL": 4.22,
    "AUG": 4.58,
    "SEP": 5.08,
    "OCT": 5.04,
    "NOV": 5.08,
    "DEC": 5.5
  },
  {
    "LONG": -40.21,
    "LAT": -1.37,
    "CIDADE": "Lafaiete Coutinho",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.41,
    "FEB": 5.66,
    "MAR": 5.5,
    "APR": 4.73,
    "MAY": 4.33,
    "JUN": 4,
    "JUL": 4.16,
    "AUG": 4.62,
    "SEP": 5.35,
    "OCT": 5.17,
    "NOV": 5.06,
    "DEC": 5.43
  },
  {
    "LONG": -39.48,
    "LAT": -13.69,
    "CIDADE": "Wenceslau Guimarães",
    "UF": "BAHIA",
    "anual": 4.87,
    "JAN": 5.45,
    "FEB": 5.62,
    "MAR": 5.58,
    "APR": 4.73,
    "MAY": 4.23,
    "JUN": 3.86,
    "JUL": 4.13,
    "AUG": 4.51,
    "SEP": 5,
    "OCT": 4.95,
    "NOV": 5,
    "DEC": 5.42
  },
  {
    "LONG": -39.49,
    "LAT": -13.75,
    "CIDADE": "Gandu",
    "UF": "BAHIA",
    "anual": 4.87,
    "JAN": 5.45,
    "FEB": 5.62,
    "MAR": 5.58,
    "APR": 4.73,
    "MAY": 4.23,
    "JUN": 3.86,
    "JUL": 4.13,
    "AUG": 4.51,
    "SEP": 5,
    "OCT": 4.95,
    "NOV": 5,
    "DEC": 5.42
  },
  {
    "LONG": -39.15,
    "LAT": -1.37,
    "CIDADE": "Ituberá",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.54,
    "FEB": 5.61,
    "MAR": 5.62,
    "APR": 4.85,
    "MAY": 4.33,
    "JUN": 4.01,
    "JUL": 4.26,
    "AUG": 4.6,
    "SEP": 5.09,
    "OCT": 4.99,
    "NOV": 5.06,
    "DEC": 5.45
  },
  {
    "LONG": -4.45,
    "LAT": -13.61,
    "CIDADE": "Jaborandi",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 5.94,
    "FEB": 6.08,
    "MAR": 5.77,
    "APR": 5.91,
    "MAY": 5.75,
    "JUN": 5.67,
    "JUL": 6.01,
    "AUG": 6.36,
    "SEP": 6.32,
    "OCT": 6.08,
    "NOV": 5.38,
    "DEC": 5.71
  },
  {
    "LONG": -42.94,
    "LAT": -1.36,
    "CIDADE": "Riacho de Santana",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 6.01,
    "FEB": 6.28,
    "MAR": 6.04,
    "APR": 5.9,
    "MAY": 5.6,
    "JUN": 5.5,
    "JUL": 5.76,
    "AUG": 6.21,
    "SEP": 6.36,
    "OCT": 6.09,
    "NOV": 5.4,
    "DEC": 5.85
  },
  {
    "LONG": -41.81,
    "LAT": -13.58,
    "CIDADE": "Rio de Contas",
    "UF": "BAHIA",
    "anual": 5.56,
    "JAN": 5.8,
    "FEB": 6.02,
    "MAR": 5.81,
    "APR": 5.29,
    "MAY": 4.92,
    "JUN": 4.72,
    "JUL": 5.01,
    "AUG": 5.64,
    "SEP": 6.18,
    "OCT": 6.05,
    "NOV": 5.41,
    "DEC": 5.83
  },
  {
    "LONG": -41.84,
    "LAT": -13.64,
    "CIDADE": "Livramento de Nossa Senhora",
    "UF": "BAHIA",
    "anual": 5.56,
    "JAN": 5.8,
    "FEB": 6.02,
    "MAR": 5.81,
    "APR": 5.29,
    "MAY": 4.92,
    "JUN": 4.72,
    "JUL": 5.01,
    "AUG": 5.64,
    "SEP": 6.18,
    "OCT": 6.05,
    "NOV": 5.41,
    "DEC": 5.83
  },
  {
    "LONG": -41.33,
    "LAT": -13.63,
    "CIDADE": "Barra da Estiva",
    "UF": "BAHIA",
    "anual": 5.28,
    "JAN": 5.68,
    "FEB": 5.92,
    "MAR": 5.65,
    "APR": 4.97,
    "MAY": 4.62,
    "JUN": 4.33,
    "JUL": 4.45,
    "AUG": 5.12,
    "SEP": 5.87,
    "OCT": 5.75,
    "NOV": 5.31,
    "DEC": 5.73
  },
  {
    "LONG": -39.49,
    "LAT": -13.59,
    "CIDADE": "Teolândia",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.49,
    "FEB": 5.55,
    "MAR": 5.59,
    "APR": 4.74,
    "MAY": 4.3,
    "JUN": 3.89,
    "JUL": 4.11,
    "AUG": 4.5,
    "SEP": 5.04,
    "OCT": 4.93,
    "NOV": 5,
    "DEC": 5.41
  },
  {
    "LONG": -39.11,
    "LAT": -13.6,
    "CIDADE": "Nilo Peçanha",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.5,
    "FEB": 5.56,
    "MAR": 5.58,
    "APR": 4.81,
    "MAY": 4.35,
    "JUN": 4.01,
    "JUL": 4.24,
    "AUG": 4.63,
    "SEP": 5.1,
    "OCT": 5,
    "NOV": 5.11,
    "DEC": 5.46
  },
  {
    "LONG": -43.58,
    "LAT": -13.54,
    "CIDADE": "Serra do Ramalho",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 6.15,
    "FEB": 6.33,
    "MAR": 6.03,
    "APR": 6.03,
    "MAY": 5.73,
    "JUN": 5.6,
    "JUL": 5.82,
    "AUG": 6.13,
    "SEP": 6.22,
    "OCT": 6.28,
    "NOV": 5.61,
    "DEC": 5.91
  },
  {
    "LONG": -42.49,
    "LAT": -13.55,
    "CIDADE": "Tanque Novo",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 6.05,
    "FEB": 6.33,
    "MAR": 6.07,
    "APR": 5.85,
    "MAY": 5.58,
    "JUN": 5.4,
    "JUL": 5.62,
    "AUG": 6.1,
    "SEP": 6.31,
    "OCT": 6.27,
    "NOV": 5.56,
    "DEC": 5.96
  },
  {
    "LONG": -41.59,
    "LAT": -1.35,
    "CIDADE": "Jussiape",
    "UF": "BAHIA",
    "anual": 5.47,
    "JAN": 5.75,
    "FEB": 5.95,
    "MAR": 5.79,
    "APR": 5.19,
    "MAY": 4.85,
    "JUN": 4.58,
    "JUL": 4.77,
    "AUG": 5.45,
    "SEP": 6.08,
    "OCT": 5.94,
    "NOV": 5.44,
    "DEC": 5.89
  },
  {
    "LONG": -40.22,
    "LAT": -13.47,
    "CIDADE": "Lajedo do Tabocal",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.28,
    "FEB": 5.62,
    "MAR": 5.52,
    "APR": 4.73,
    "MAY": 4.41,
    "JUN": 3.97,
    "JUL": 4.18,
    "AUG": 4.67,
    "SEP": 5.39,
    "OCT": 5.23,
    "NOV": 5.11,
    "DEC": 5.39
  },
  {
    "LONG": -40.15,
    "LAT": -13.53,
    "CIDADE": "Itiruçu",
    "UF": "BAHIA",
    "anual": 4.9,
    "JAN": 5.33,
    "FEB": 5.59,
    "MAR": 5.46,
    "APR": 4.69,
    "MAY": 4.3,
    "JUN": 3.87,
    "JUL": 4.08,
    "AUG": 4.56,
    "SEP": 5.29,
    "OCT": 5.11,
    "NOV": 5.06,
    "DEC": 5.4
  },
  {
    "LONG": -39.96,
    "LAT": -13.53,
    "CIDADE": "Jaguaquara",
    "UF": "BAHIA",
    "anual": 4.92,
    "JAN": 5.32,
    "FEB": 5.6,
    "MAR": 5.54,
    "APR": 4.71,
    "MAY": 4.28,
    "JUN": 3.97,
    "JUL": 4.09,
    "AUG": 4.62,
    "SEP": 5.27,
    "OCT": 5.12,
    "NOV": 5.09,
    "DEC": 5.43
  },
  {
    "LONG": -39.42,
    "LAT": -13.45,
    "CIDADE": "Presidente Tancredo Neves",
    "UF": "BAHIA",
    "anual": 4.83,
    "JAN": 5.44,
    "FEB": 5.47,
    "MAR": 5.56,
    "APR": 4.68,
    "MAY": 4.29,
    "JUN": 3.87,
    "JUL": 4.07,
    "AUG": 4.45,
    "SEP": 4.94,
    "OCT": 4.85,
    "NOV": 4.97,
    "DEC": 5.33
  },
  {
    "LONG": -39.1,
    "LAT": -13.54,
    "CIDADE": "Taperoá",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.52,
    "FEB": 5.66,
    "MAR": 5.6,
    "APR": 4.78,
    "MAY": 4.32,
    "JUN": 4,
    "JUL": 4.22,
    "AUG": 4.63,
    "SEP": 5.12,
    "OCT": 5.02,
    "NOV": 5.17,
    "DEC": 5.51
  },
  {
    "LONG": -39.05,
    "LAT": -13.49,
    "CIDADE": "Cairu",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.63,
    "FEB": 5.81,
    "MAR": 5.74,
    "APR": 4.84,
    "MAY": 4.39,
    "JUN": 4.06,
    "JUL": 4.31,
    "AUG": 4.68,
    "SEP": 5.26,
    "OCT": 5.2,
    "NOV": 5.36,
    "DEC": 5.68
  },
  {
    "LONG": -44.2,
    "LAT": -13.39,
    "CIDADE": "Santa Maria da Vitória",
    "UF": "BAHIA",
    "anual": 5.97,
    "JAN": 6.06,
    "FEB": 6.11,
    "MAR": 5.9,
    "APR": 5.97,
    "MAY": 5.81,
    "JUN": 5.73,
    "JUL": 6.03,
    "AUG": 6.34,
    "SEP": 6.35,
    "OCT": 6.13,
    "NOV": 5.43,
    "DEC": 5.77
  },
  {
    "LONG": -44.19,
    "LAT": -13.41,
    "CIDADE": "São Félix do Coribe",
    "UF": "BAHIA",
    "anual": 5.97,
    "JAN": 6.06,
    "FEB": 6.11,
    "MAR": 5.89,
    "APR": 5.97,
    "MAY": 5.77,
    "JUN": 5.71,
    "JUL": 5.99,
    "AUG": 6.29,
    "SEP": 6.39,
    "OCT": 6.17,
    "NOV": 5.46,
    "DEC": 5.79
  },
  {
    "LONG": -42.52,
    "LAT": -13.38,
    "CIDADE": "Botuporã",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 6.08,
    "FEB": 6.26,
    "MAR": 6.02,
    "APR": 5.78,
    "MAY": 5.53,
    "JUN": 5.48,
    "JUL": 5.64,
    "AUG": 6.18,
    "SEP": 6.35,
    "OCT": 6.21,
    "NOV": 5.56,
    "DEC": 5.92
  },
  {
    "LONG": -42.24,
    "LAT": -13.44,
    "CIDADE": "Paramirim",
    "UF": "BAHIA",
    "anual": 5.95,
    "JAN": 6.05,
    "FEB": 6.33,
    "MAR": 6.15,
    "APR": 5.91,
    "MAY": 5.61,
    "JUN": 5.47,
    "JUL": 5.58,
    "AUG": 6.02,
    "SEP": 6.28,
    "OCT": 6.3,
    "NOV": 5.68,
    "DEC": 5.99
  },
  {
    "LONG": -42.14,
    "LAT": -13.42,
    "CIDADE": "Érico Cardoso",
    "UF": "BAHIA",
    "anual": 5.87,
    "JAN": 5.91,
    "FEB": 6.2,
    "MAR": 6.01,
    "APR": 5.81,
    "MAY": 5.51,
    "JUN": 5.42,
    "JUL": 5.59,
    "AUG": 6.11,
    "SEP": 6.38,
    "OCT": 6.18,
    "NOV": 5.5,
    "DEC": 5.86
  },
  {
    "LONG": -41.28,
    "LAT": -13.41,
    "CIDADE": "Ibicoara",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.52,
    "FEB": 5.82,
    "MAR": 5.62,
    "APR": 4.88,
    "MAY": 4.51,
    "JUN": 4.17,
    "JUL": 4.34,
    "AUG": 4.92,
    "SEP": 5.67,
    "OCT": 5.51,
    "NOV": 5.15,
    "DEC": 5.54
  },
  {
    "LONG": -40.43,
    "LAT": -13.44,
    "CIDADE": "Maracás",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.31,
    "FEB": 5.59,
    "MAR": 5.44,
    "APR": 4.71,
    "MAY": 4.41,
    "JUN": 4.17,
    "JUL": 4.32,
    "AUG": 4.86,
    "SEP": 5.58,
    "OCT": 5.34,
    "NOV": 5.09,
    "DEC": 5.39
  },
  {
    "LONG": -39.94,
    "LAT": -13.45,
    "CIDADE": "Itaquara",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.35,
    "FEB": 5.58,
    "MAR": 5.51,
    "APR": 4.71,
    "MAY": 4.22,
    "JUN": 3.87,
    "JUL": 4.07,
    "AUG": 4.56,
    "SEP": 5.25,
    "OCT": 5.07,
    "NOV": 5.01,
    "DEC": 5.42
  },
  {
    "LONG": -3.98,
    "LAT": -13.36,
    "CIDADE": "Cravolândia",
    "UF": "BAHIA",
    "anual": 4.89,
    "JAN": 5.32,
    "FEB": 5.57,
    "MAR": 5.51,
    "APR": 4.71,
    "MAY": 4.24,
    "JUN": 3.91,
    "JUL": 4.14,
    "AUG": 4.59,
    "SEP": 5.22,
    "OCT": 5.08,
    "NOV": 4.99,
    "DEC": 5.4
  },
  {
    "LONG": -39.07,
    "LAT": -13.37,
    "CIDADE": "Valença",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.71,
    "FEB": 5.79,
    "MAR": 5.83,
    "APR": 4.82,
    "MAY": 4.4,
    "JUN": 4.05,
    "JUL": 4.26,
    "AUG": 4.68,
    "SEP": 5.3,
    "OCT": 5.29,
    "NOV": 5.42,
    "DEC": 5.72
  },
  {
    "LONG": -44.63,
    "LAT": -13.34,
    "CIDADE": "Correntina",
    "UF": "BAHIA",
    "anual": 5.96,
    "JAN": 5.94,
    "FEB": 6.09,
    "MAR": 5.85,
    "APR": 6.03,
    "MAY": 5.88,
    "JUN": 5.76,
    "JUL": 6.15,
    "AUG": 6.45,
    "SEP": 6.36,
    "OCT": 6.05,
    "NOV": 5.29,
    "DEC": 5.7
  },
  {
    "LONG": -43.41,
    "LAT": -1.33,
    "CIDADE": "Bom Jesus da Lapa",
    "UF": "BAHIA",
    "anual": 6,
    "JAN": 6.1,
    "FEB": 6.38,
    "MAR": 6.12,
    "APR": 6.11,
    "MAY": 5.74,
    "JUN": 5.62,
    "JUL": 5.83,
    "AUG": 6.13,
    "SEP": 6.23,
    "OCT": 6.25,
    "NOV": 5.57,
    "DEC": 5.91
  },
  {
    "LONG": -42.29,
    "LAT": -13.33,
    "CIDADE": "Caturama",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 5.97,
    "FEB": 6.32,
    "MAR": 6.12,
    "APR": 5.84,
    "MAY": 5.58,
    "JUN": 5.49,
    "JUL": 5.61,
    "AUG": 6.16,
    "SEP": 6.36,
    "OCT": 6.23,
    "NOV": 5.64,
    "DEC": 6
  },
  {
    "LONG": -41.66,
    "LAT": -13.25,
    "CIDADE": "Abaíra",
    "UF": "BAHIA",
    "anual": 5.37,
    "JAN": 5.64,
    "FEB": 5.9,
    "MAR": 5.64,
    "APR": 5.11,
    "MAY": 4.72,
    "JUN": 4.51,
    "JUL": 4.72,
    "AUG": 5.36,
    "SEP": 5.94,
    "OCT": 5.75,
    "NOV": 5.34,
    "DEC": 5.75
  },
  {
    "LONG": -40.96,
    "LAT": -13.29,
    "CIDADE": "Iramaia",
    "UF": "BAHIA",
    "anual": 5.12,
    "JAN": 5.42,
    "FEB": 5.79,
    "MAR": 5.66,
    "APR": 4.98,
    "MAY": 4.57,
    "JUN": 4.24,
    "JUL": 4.37,
    "AUG": 4.89,
    "SEP": 5.62,
    "OCT": 5.36,
    "NOV": 5.12,
    "DEC": 5.43
  },
  {
    "LONG": -40.37,
    "LAT": -13.26,
    "CIDADE": "Planaltino",
    "UF": "BAHIA",
    "anual": 5.03,
    "JAN": 5.39,
    "FEB": 5.72,
    "MAR": 5.56,
    "APR": 4.77,
    "MAY": 4.46,
    "JUN": 4.12,
    "JUL": 4.3,
    "AUG": 4.81,
    "SEP": 5.5,
    "OCT": 5.29,
    "NOV": 5.06,
    "DEC": 5.36
  },
  {
    "LONG": -40.09,
    "LAT": -13.26,
    "CIDADE": "Irajuba",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.37,
    "FEB": 5.51,
    "MAR": 5.49,
    "APR": 4.68,
    "MAY": 4.21,
    "JUN": 3.89,
    "JUL": 4.09,
    "AUG": 4.56,
    "SEP": 5.29,
    "OCT": 5.1,
    "NOV": 5.01,
    "DEC": 5.33
  },
  {
    "LONG": -39.82,
    "LAT": -13.3,
    "CIDADE": "Santa Inês",
    "UF": "BAHIA",
    "anual": 4.88,
    "JAN": 5.35,
    "FEB": 5.56,
    "MAR": 5.55,
    "APR": 4.71,
    "MAY": 4.18,
    "JUN": 3.84,
    "JUL": 4.08,
    "AUG": 4.53,
    "SEP": 5.21,
    "OCT": 5.11,
    "NOV": 5.06,
    "DEC": 5.44
  },
  {
    "LONG": -39.66,
    "LAT": -13.27,
    "CIDADE": "Ubaíra",
    "UF": "BAHIA",
    "anual": 4.86,
    "JAN": 5.39,
    "FEB": 5.63,
    "MAR": 5.59,
    "APR": 4.72,
    "MAY": 4.22,
    "JUN": 3.86,
    "JUL": 4.04,
    "AUG": 4.48,
    "SEP": 5.08,
    "OCT": 4.97,
    "NOV": 4.99,
    "DEC": 5.42
  },
  {
    "LONG": -39.57,
    "LAT": -1.33,
    "CIDADE": "Jiquiriçá",
    "UF": "BAHIA",
    "anual": 4.85,
    "JAN": 5.36,
    "FEB": 5.6,
    "MAR": 5.58,
    "APR": 4.74,
    "MAY": 4.28,
    "JUN": 3.92,
    "JUL": 4.06,
    "AUG": 4.42,
    "SEP": 4.99,
    "OCT": 4.86,
    "NOV": 4.96,
    "DEC": 5.38
  },
  {
    "LONG": -41.77,
    "LAT": -13.15,
    "CIDADE": "Piatã",
    "UF": "BAHIA",
    "anual": 5.32,
    "JAN": 5.5,
    "FEB": 5.84,
    "MAR": 5.56,
    "APR": 5.03,
    "MAY": 4.68,
    "JUN": 4.51,
    "JUL": 4.72,
    "AUG": 5.38,
    "SEP": 5.99,
    "OCT": 5.76,
    "NOV": 5.26,
    "DEC": 5.61
  },
  {
    "LONG": -39.5,
    "LAT": -13.23,
    "CIDADE": "Mutuípe",
    "UF": "BAHIA",
    "anual": 4.83,
    "JAN": 5.37,
    "FEB": 5.53,
    "MAR": 5.59,
    "APR": 4.74,
    "MAY": 4.27,
    "JUN": 3.87,
    "JUL": 4.04,
    "AUG": 4.43,
    "SEP": 4.98,
    "OCT": 4.86,
    "NOV": 4.93,
    "DEC": 5.38
  },
  {
    "LONG": -39.42,
    "LAT": -13.17,
    "CIDADE": "Laje",
    "UF": "BAHIA",
    "anual": 4.91,
    "JAN": 5.43,
    "FEB": 5.54,
    "MAR": 5.64,
    "APR": 4.81,
    "MAY": 4.32,
    "JUN": 3.99,
    "JUL": 4.14,
    "AUG": 4.53,
    "SEP": 5.09,
    "OCT": 4.99,
    "NOV": 5.07,
    "DEC": 5.42
  },
  {
    "LONG": -44.2,
    "LAT": -13.07,
    "CIDADE": "Canápolis",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 5.93,
    "FEB": 6.02,
    "MAR": 5.74,
    "APR": 5.86,
    "MAY": 5.85,
    "JUN": 5.76,
    "JUL": 6.13,
    "AUG": 6.49,
    "SEP": 6.44,
    "OCT": 6.09,
    "NOV": 5.32,
    "DEC": 5.63
  },
  {
    "LONG": -4.35,
    "LAT": -13.08,
    "CIDADE": "Sítio do Mato",
    "UF": "BAHIA",
    "anual": 6,
    "JAN": 6.14,
    "FEB": 6.31,
    "MAR": 6.1,
    "APR": 6.07,
    "MAY": 5.74,
    "JUN": 5.65,
    "JUL": 5.86,
    "AUG": 6.19,
    "SEP": 6.24,
    "OCT": 6.23,
    "NOV": 5.57,
    "DEC": 5.92
  },
  {
    "LONG": -4.23,
    "LAT": -1.31,
    "CIDADE": "Rio do Pires",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 6.01,
    "FEB": 6.33,
    "MAR": 6.11,
    "APR": 5.82,
    "MAY": 5.57,
    "JUN": 5.41,
    "JUL": 5.56,
    "AUG": 6.14,
    "SEP": 6.38,
    "OCT": 6.24,
    "NOV": 5.67,
    "DEC": 6
  },
  {
    "LONG": -39.79,
    "LAT": -1.31,
    "CIDADE": "Brejões",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.41,
    "FEB": 5.68,
    "MAR": 5.6,
    "APR": 4.73,
    "MAY": 4.3,
    "JUN": 4,
    "JUL": 4.16,
    "AUG": 4.64,
    "SEP": 5.23,
    "OCT": 5.14,
    "NOV": 5.09,
    "DEC": 5.43
  },
  {
    "LONG": -39,
    "LAT": -13.08,
    "CIDADE": "Aratuípe",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.65,
    "FEB": 5.77,
    "MAR": 5.82,
    "APR": 4.91,
    "MAY": 4.43,
    "JUN": 4.06,
    "JUL": 4.26,
    "AUG": 4.76,
    "SEP": 5.27,
    "OCT": 5.21,
    "NOV": 5.32,
    "DEC": 5.59
  },
  {
    "LONG": -38.89,
    "LAT": -13.11,
    "CIDADE": "Jaguaripe",
    "UF": "BAHIA",
    "anual": 5.25,
    "JAN": 5.8,
    "FEB": 5.92,
    "MAR": 5.91,
    "APR": 5,
    "MAY": 4.52,
    "JUN": 4.18,
    "JUL": 4.4,
    "AUG": 4.94,
    "SEP": 5.5,
    "OCT": 5.5,
    "NOV": 5.54,
    "DEC": 5.8
  },
  {
    "LONG": -4.41,
    "LAT": -12.98,
    "CIDADE": "Santana",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 6.06,
    "FEB": 6.16,
    "MAR": 5.92,
    "APR": 5.99,
    "MAY": 5.82,
    "JUN": 5.74,
    "JUL": 5.98,
    "AUG": 6.33,
    "SEP": 6.33,
    "OCT": 6.14,
    "NOV": 5.51,
    "DEC": 5.83
  },
  {
    "LONG": -42.69,
    "LAT": -1.3,
    "CIDADE": "Macaúbas",
    "UF": "BAHIA",
    "anual": 5.96,
    "JAN": 6.14,
    "FEB": 6.37,
    "MAR": 6.09,
    "APR": 5.92,
    "MAY": 5.57,
    "JUN": 5.48,
    "JUL": 5.65,
    "AUG": 6.14,
    "SEP": 6.27,
    "OCT": 6.21,
    "NOV": 5.7,
    "DEC": 6.01
  },
  {
    "LONG": -41.37,
    "LAT": -13.01,
    "CIDADE": "Mucugê",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.41,
    "FEB": 5.75,
    "MAR": 5.57,
    "APR": 4.94,
    "MAY": 4.55,
    "JUN": 4.29,
    "JUL": 4.41,
    "AUG": 5.02,
    "SEP": 5.66,
    "OCT": 5.34,
    "NOV": 4.99,
    "DEC": 5.41
  },
  {
    "LONG": -40.96,
    "LAT": -12.98,
    "CIDADE": "Itaeté",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.49,
    "FEB": 5.89,
    "MAR": 5.68,
    "APR": 5.02,
    "MAY": 4.62,
    "JUN": 4.3,
    "JUL": 4.47,
    "AUG": 5.03,
    "SEP": 5.62,
    "OCT": 5.35,
    "NOV": 5.08,
    "DEC": 5.37
  },
  {
    "LONG": -40.53,
    "LAT": -13.01,
    "CIDADE": "Marcionílio Souza",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.61,
    "FEB": 5.87,
    "MAR": 5.79,
    "APR": 5.06,
    "MAY": 4.62,
    "JUN": 4.23,
    "JUL": 4.45,
    "AUG": 4.93,
    "SEP": 5.62,
    "OCT": 5.45,
    "NOV": 5.32,
    "DEC": 5.56
  },
  {
    "LONG": -40.07,
    "LAT": -13.03,
    "CIDADE": "Nova Itarana",
    "UF": "BAHIA",
    "anual": 4.94,
    "JAN": 5.35,
    "FEB": 5.53,
    "MAR": 5.54,
    "APR": 4.78,
    "MAY": 4.32,
    "JUN": 3.97,
    "JUL": 4.22,
    "AUG": 4.66,
    "SEP": 5.3,
    "OCT": 5.13,
    "NOV": 5.06,
    "DEC": 5.39
  },
  {
    "LONG": -39.6,
    "LAT": -13.03,
    "CIDADE": "Amargosa",
    "UF": "BAHIA",
    "anual": 5.03,
    "JAN": 5.47,
    "FEB": 5.71,
    "MAR": 5.68,
    "APR": 4.88,
    "MAY": 4.46,
    "JUN": 4.1,
    "JUL": 4.27,
    "AUG": 4.7,
    "SEP": 5.29,
    "OCT": 5.18,
    "NOV": 5.17,
    "DEC": 5.46
  },
  {
    "LONG": -39.46,
    "LAT": -13.04,
    "CIDADE": "São Miguel das Matas",
    "UF": "BAHIA",
    "anual": 4.93,
    "JAN": 5.41,
    "FEB": 5.61,
    "MAR": 5.63,
    "APR": 4.78,
    "MAY": 4.32,
    "JUN": 3.95,
    "JUL": 4.14,
    "AUG": 4.57,
    "SEP": 5.14,
    "OCT": 5.05,
    "NOV": 5.12,
    "DEC": 5.44
  },
  {
    "LONG": -39.39,
    "LAT": -12.97,
    "CIDADE": "Varzedo",
    "UF": "BAHIA",
    "anual": 4.97,
    "JAN": 5.49,
    "FEB": 5.65,
    "MAR": 5.69,
    "APR": 4.84,
    "MAY": 4.35,
    "JUN": 3.99,
    "JUL": 4.15,
    "AUG": 4.6,
    "SEP": 5.15,
    "OCT": 5.09,
    "NOV": 5.17,
    "DEC": 5.47
  },
  {
    "LONG": -39.26,
    "LAT": -12.96,
    "CIDADE": "Santo Antônio de Jesus",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.55,
    "FEB": 5.69,
    "MAR": 5.68,
    "APR": 4.85,
    "MAY": 4.38,
    "JUN": 4.04,
    "JUL": 4.19,
    "AUG": 4.65,
    "SEP": 5.24,
    "OCT": 5.19,
    "NOV": 5.27,
    "DEC": 5.52
  },
  {
    "LONG": -39.11,
    "LAT": -1.3,
    "CIDADE": "Muniz Ferreira",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.58,
    "FEB": 5.69,
    "MAR": 5.74,
    "APR": 4.88,
    "MAY": 4.42,
    "JUN": 4.03,
    "JUL": 4.22,
    "AUG": 4.73,
    "SEP": 5.3,
    "OCT": 5.22,
    "NOV": 5.35,
    "DEC": 5.59
  },
  {
    "LONG": -39.01,
    "LAT": -13.03,
    "CIDADE": "Nazaré",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.6,
    "FEB": 5.72,
    "MAR": 5.78,
    "APR": 4.9,
    "MAY": 4.4,
    "JUN": 4.06,
    "JUL": 4.28,
    "AUG": 4.75,
    "SEP": 5.27,
    "OCT": 5.21,
    "NOV": 5.33,
    "DEC": 5.61
  },
  {
    "LONG": -38.62,
    "LAT": -12.96,
    "CIDADE": "Vera Cruz",
    "UF": "BAHIA",
    "anual": 5.36,
    "JAN": 5.96,
    "FEB": 6.04,
    "MAR": 5.99,
    "APR": 5.08,
    "MAY": 4.47,
    "JUN": 4.29,
    "JUL": 4.51,
    "AUG": 5.06,
    "SEP": 5.63,
    "OCT": 5.68,
    "NOV": 5.67,
    "DEC": 5.89
  },
  {
    "LONG": -38.5,
    "LAT": -12.97,
    "CIDADE": "Salvador",
    "UF": "BAHIA",
    "anual": 5.4,
    "JAN": 5.96,
    "FEB": 6.04,
    "MAR": 6.08,
    "APR": 5.13,
    "MAY": 4.53,
    "JUN": 4.36,
    "JUL": 4.54,
    "AUG": 5.13,
    "SEP": 5.66,
    "OCT": 5.7,
    "NOV": 5.7,
    "DEC": 5.92
  },
  {
    "LONG": -42.49,
    "LAT": -12.88,
    "CIDADE": "Ibipitanga",
    "UF": "BAHIA",
    "anual": 6.01,
    "JAN": 6.16,
    "FEB": 6.33,
    "MAR": 6.22,
    "APR": 5.92,
    "MAY": 5.6,
    "JUN": 5.49,
    "JUL": 5.64,
    "AUG": 6.22,
    "SEP": 6.37,
    "OCT": 6.35,
    "NOV": 5.73,
    "DEC": 6.08
  },
  {
    "LONG": -39.86,
    "LAT": -12.87,
    "CIDADE": "Milagres",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.45,
    "FEB": 5.67,
    "MAR": 5.64,
    "APR": 4.78,
    "MAY": 4.33,
    "JUN": 4.06,
    "JUL": 4.26,
    "AUG": 4.72,
    "SEP": 5.33,
    "OCT": 5.14,
    "NOV": 5.07,
    "DEC": 5.42
  },
  {
    "LONG": -3.95,
    "LAT": -12.95,
    "CIDADE": "Elísio Medrado",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.48,
    "FEB": 5.68,
    "MAR": 5.68,
    "APR": 4.84,
    "MAY": 4.38,
    "JUN": 4.15,
    "JUL": 4.29,
    "AUG": 4.7,
    "SEP": 5.32,
    "OCT": 5.19,
    "NOV": 5.13,
    "DEC": 5.45
  },
  {
    "LONG": -39.19,
    "LAT": -1.29,
    "CIDADE": "Dom Macedo Costa",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.61,
    "FEB": 5.71,
    "MAR": 5.75,
    "APR": 4.9,
    "MAY": 4.45,
    "JUN": 4.06,
    "JUL": 4.25,
    "AUG": 4.71,
    "SEP": 5.31,
    "OCT": 5.24,
    "NOV": 5.29,
    "DEC": 5.6
  },
  {
    "LONG": -38.76,
    "LAT": -12.87,
    "CIDADE": "Salinas da Margarida",
    "UF": "BAHIA",
    "anual": 5.34,
    "JAN": 5.94,
    "FEB": 5.97,
    "MAR": 6.04,
    "APR": 5.05,
    "MAY": 4.49,
    "JUN": 4.2,
    "JUL": 4.46,
    "AUG": 4.94,
    "SEP": 5.63,
    "OCT": 5.72,
    "NOV": 5.73,
    "DEC": 5.9
  },
  {
    "LONG": -38.68,
    "LAT": -12.89,
    "CIDADE": "Itaparica",
    "UF": "BAHIA",
    "anual": 5.37,
    "JAN": 5.9,
    "FEB": 6,
    "MAR": 6.07,
    "APR": 5.09,
    "MAY": 4.48,
    "JUN": 4.22,
    "JUL": 4.48,
    "AUG": 5,
    "SEP": 5.7,
    "OCT": 5.79,
    "NOV": 5.76,
    "DEC": 5.94
  },
  {
    "LONG": -38.32,
    "LAT": -12.9,
    "CIDADE": "Lauro de Freitas",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.79,
    "FEB": 5.88,
    "MAR": 5.9,
    "APR": 4.91,
    "MAY": 4.47,
    "JUN": 4.21,
    "JUL": 4.46,
    "AUG": 4.93,
    "SEP": 5.54,
    "OCT": 5.62,
    "NOV": 5.67,
    "DEC": 5.84
  },
  {
    "LONG": -43.95,
    "LAT": -12.76,
    "CIDADE": "Serra Dourada",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 6.08,
    "FEB": 6.14,
    "MAR": 5.93,
    "APR": 5.99,
    "MAY": 5.79,
    "JUN": 5.71,
    "JUL": 5.92,
    "AUG": 6.29,
    "SEP": 6.25,
    "OCT": 6.19,
    "NOV": 5.61,
    "DEC": 5.92
  },
  {
    "LONG": -42.73,
    "LAT": -1.28,
    "CIDADE": "Boquira",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 6.15,
    "FEB": 6.27,
    "MAR": 6.11,
    "APR": 5.85,
    "MAY": 5.57,
    "JUN": 5.59,
    "JUL": 5.74,
    "AUG": 6.27,
    "SEP": 6.36,
    "OCT": 6.27,
    "NOV": 5.73,
    "DEC": 5.99
  },
  {
    "LONG": -42.17,
    "LAT": -12.81,
    "CIDADE": "Novo Horizonte",
    "UF": "BAHIA",
    "anual": 5.82,
    "JAN": 5.87,
    "FEB": 6.05,
    "MAR": 5.92,
    "APR": 5.6,
    "MAY": 5.31,
    "JUN": 5.27,
    "JUL": 5.49,
    "AUG": 6.13,
    "SEP": 6.45,
    "OCT": 6.22,
    "NOV": 5.56,
    "DEC": 5.93
  },
  {
    "LONG": -41.33,
    "LAT": -12.8,
    "CIDADE": "Andaraí",
    "UF": "BAHIA",
    "anual": 5.03,
    "JAN": 5.38,
    "FEB": 5.73,
    "MAR": 5.62,
    "APR": 4.93,
    "MAY": 4.48,
    "JUN": 4.22,
    "JUL": 4.3,
    "AUG": 4.84,
    "SEP": 5.5,
    "OCT": 5.21,
    "NOV": 4.9,
    "DEC": 5.26
  },
  {
    "LONG": -41.07,
    "LAT": -12.82,
    "CIDADE": "Nova Redenção",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.34,
    "FEB": 5.65,
    "MAR": 5.62,
    "APR": 4.94,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.31,
    "AUG": 4.89,
    "SEP": 5.55,
    "OCT": 5.27,
    "NOV": 5,
    "DEC": 5.29
  },
  {
    "LONG": -40.21,
    "LAT": -12.77,
    "CIDADE": "Iaçu",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.55,
    "FEB": 5.7,
    "MAR": 5.78,
    "APR": 4.94,
    "MAY": 4.39,
    "JUN": 4.1,
    "JUL": 4.25,
    "AUG": 4.73,
    "SEP": 5.42,
    "OCT": 5.22,
    "NOV": 5.2,
    "DEC": 5.46
  },
  {
    "LONG": -39.52,
    "LAT": -12.77,
    "CIDADE": "Santa Teresinha",
    "UF": "BAHIA",
    "anual": 4.98,
    "JAN": 5.43,
    "FEB": 5.63,
    "MAR": 5.62,
    "APR": 4.79,
    "MAY": 4.33,
    "JUN": 4.09,
    "JUL": 4.27,
    "AUG": 4.67,
    "SEP": 5.23,
    "OCT": 5.14,
    "NOV": 5.11,
    "DEC": 5.41
  },
  {
    "LONG": -39.43,
    "LAT": -12.77,
    "CIDADE": "Castro Alves",
    "UF": "BAHIA",
    "anual": 4.98,
    "JAN": 5.44,
    "FEB": 5.64,
    "MAR": 5.6,
    "APR": 4.81,
    "MAY": 4.37,
    "JUN": 4.09,
    "JUL": 4.32,
    "AUG": 4.74,
    "SEP": 5.22,
    "OCT": 5.11,
    "NOV": 5.08,
    "DEC": 5.38
  },
  {
    "LONG": -39.17,
    "LAT": -1.28,
    "CIDADE": "Conceição do Almeida",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.53,
    "FEB": 5.69,
    "MAR": 5.72,
    "APR": 4.88,
    "MAY": 4.4,
    "JUN": 4.08,
    "JUL": 4.29,
    "AUG": 4.71,
    "SEP": 5.29,
    "OCT": 5.3,
    "NOV": 5.34,
    "DEC": 5.56
  },
  {
    "LONG": -39.09,
    "LAT": -12.85,
    "CIDADE": "São Felipe",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.63,
    "FEB": 5.68,
    "MAR": 5.74,
    "APR": 4.86,
    "MAY": 4.35,
    "JUN": 4.03,
    "JUL": 4.24,
    "AUG": 4.69,
    "SEP": 5.31,
    "OCT": 5.32,
    "NOV": 5.4,
    "DEC": 5.59
  },
  {
    "LONG": -38.92,
    "LAT": -12.78,
    "CIDADE": "Maragogipe",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.77,
    "FEB": 5.78,
    "MAR": 5.83,
    "APR": 4.92,
    "MAY": 4.36,
    "JUN": 4.04,
    "JUL": 4.29,
    "AUG": 4.71,
    "SEP": 5.37,
    "OCT": 5.41,
    "NOV": 5.53,
    "DEC": 5.72
  },
  {
    "LONG": -38.4,
    "LAT": -1.28,
    "CIDADE": "Simões Filho",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.71,
    "FEB": 5.73,
    "MAR": 5.73,
    "APR": 4.79,
    "MAY": 4.33,
    "JUN": 4.09,
    "JUL": 4.32,
    "AUG": 4.74,
    "SEP": 5.3,
    "OCT": 5.42,
    "NOV": 5.47,
    "DEC": 5.68
  },
  {
    "LONG": -44.01,
    "LAT": -12.7,
    "CIDADE": "Tabocas do Brejo Velho",
    "UF": "BAHIA",
    "anual": 5.97,
    "JAN": 5.98,
    "FEB": 6.1,
    "MAR": 5.88,
    "APR": 5.97,
    "MAY": 5.82,
    "JUN": 5.78,
    "JUL": 6.01,
    "AUG": 6.36,
    "SEP": 6.31,
    "OCT": 6.18,
    "NOV": 5.49,
    "DEC": 5.82
  },
  {
    "LONG": -43.18,
    "LAT": -12.69,
    "CIDADE": "Paratinga",
    "UF": "BAHIA",
    "anual": 6,
    "JAN": 6.12,
    "FEB": 6.27,
    "MAR": 6.12,
    "APR": 6.02,
    "MAY": 5.67,
    "JUN": 5.56,
    "JUL": 5.81,
    "AUG": 6.35,
    "SEP": 6.29,
    "OCT": 6.21,
    "NOV": 5.63,
    "DEC": 5.95
  },
  {
    "LONG": -42.22,
    "LAT": -12.65,
    "CIDADE": "Ibitiara",
    "UF": "BAHIA",
    "anual": 5.85,
    "JAN": 5.9,
    "FEB": 6.05,
    "MAR": 5.96,
    "APR": 5.61,
    "MAY": 5.41,
    "JUN": 5.34,
    "JUL": 5.53,
    "AUG": 6.16,
    "SEP": 6.47,
    "OCT": 6.25,
    "NOV": 5.61,
    "DEC": 5.94
  },
  {
    "LONG": -41.83,
    "LAT": -12.71,
    "CIDADE": "Boninal",
    "UF": "BAHIA",
    "anual": 5.53,
    "JAN": 5.68,
    "FEB": 5.86,
    "MAR": 5.8,
    "APR": 5.24,
    "MAY": 4.95,
    "JUN": 4.78,
    "JUL": 4.99,
    "AUG": 5.63,
    "SEP": 6.13,
    "OCT": 6.01,
    "NOV": 5.48,
    "DEC": 5.76
  },
  {
    "LONG": -40.61,
    "LAT": -12.66,
    "CIDADE": "Boa Vista do Tupim",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.5,
    "FEB": 5.77,
    "MAR": 5.68,
    "APR": 4.94,
    "MAY": 4.45,
    "JUN": 4.21,
    "JUL": 4.28,
    "AUG": 4.85,
    "SEP": 5.48,
    "OCT": 5.23,
    "NOV": 5.07,
    "DEC": 5.38
  },
  {
    "LONG": -39.7,
    "LAT": -12.71,
    "CIDADE": "Itatim",
    "UF": "BAHIA",
    "anual": 5.03,
    "JAN": 5.53,
    "FEB": 5.68,
    "MAR": 5.71,
    "APR": 4.86,
    "MAY": 4.34,
    "JUN": 4.07,
    "JUL": 4.27,
    "AUG": 4.7,
    "SEP": 5.3,
    "OCT": 5.18,
    "NOV": 5.22,
    "DEC": 5.47
  },
  {
    "LONG": -39.1,
    "LAT": -1.27,
    "CIDADE": "Cruz das Almas",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.63,
    "FEB": 5.66,
    "MAR": 5.71,
    "APR": 4.88,
    "MAY": 4.37,
    "JUN": 4.08,
    "JUL": 4.27,
    "AUG": 4.73,
    "SEP": 5.35,
    "OCT": 5.34,
    "NOV": 5.35,
    "DEC": 5.53
  },
  {
    "LONG": -3.92,
    "LAT": -12.73,
    "CIDADE": "Sapeaçu",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.63,
    "FEB": 5.66,
    "MAR": 5.71,
    "APR": 4.88,
    "MAY": 4.37,
    "JUN": 4.08,
    "JUL": 4.27,
    "AUG": 4.73,
    "SEP": 5.35,
    "OCT": 5.34,
    "NOV": 5.35,
    "DEC": 5.53
  },
  {
    "LONG": -3.88,
    "LAT": -12.74,
    "CIDADE": "Saubara",
    "UF": "BAHIA",
    "anual": 5.34,
    "JAN": 5.89,
    "FEB": 5.97,
    "MAR": 6.04,
    "APR": 5.13,
    "MAY": 4.55,
    "JUN": 4.24,
    "JUL": 4.51,
    "AUG": 4.98,
    "SEP": 5.62,
    "OCT": 5.65,
    "NOV": 5.65,
    "DEC": 5.84
  },
  {
    "LONG": -38.62,
    "LAT": -12.75,
    "CIDADE": "Madre de Deus",
    "UF": "BAHIA",
    "anual": 5.25,
    "JAN": 5.76,
    "FEB": 5.84,
    "MAR": 5.98,
    "APR": 5.08,
    "MAY": 4.51,
    "JUN": 4.23,
    "JUL": 4.48,
    "AUG": 4.89,
    "SEP": 5.49,
    "OCT": 5.47,
    "NOV": 5.48,
    "DEC": 5.76
  },
  {
    "LONG": -38.54,
    "LAT": -12.67,
    "CIDADE": "Candeias",
    "UF": "BAHIA",
    "anual": 5,
    "JAN": 5.54,
    "FEB": 5.52,
    "MAR": 5.64,
    "APR": 4.77,
    "MAY": 4.29,
    "JUN": 4.04,
    "JUL": 4.29,
    "AUG": 4.6,
    "SEP": 5.2,
    "OCT": 5.26,
    "NOV": 5.32,
    "DEC": 5.56
  },
  {
    "LONG": -38.33,
    "LAT": -12.7,
    "CIDADE": "Camaçari",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.67,
    "FEB": 5.71,
    "MAR": 5.7,
    "APR": 4.73,
    "MAY": 4.25,
    "JUN": 4.11,
    "JUL": 4.3,
    "AUG": 4.72,
    "SEP": 5.21,
    "OCT": 5.34,
    "NOV": 5.45,
    "DEC": 5.58
  },
  {
    "LONG": -41.39,
    "LAT": -12.56,
    "CIDADE": "Lençóis",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.49,
    "FEB": 5.72,
    "MAR": 5.63,
    "APR": 5.01,
    "MAY": 4.53,
    "JUN": 4.26,
    "JUL": 4.39,
    "AUG": 4.96,
    "SEP": 5.47,
    "OCT": 5.29,
    "NOV": 5.05,
    "DEC": 5.29
  },
  {
    "LONG": -40.93,
    "LAT": -12.65,
    "CIDADE": "Ibiquera",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.45,
    "FEB": 5.67,
    "MAR": 5.54,
    "APR": 4.79,
    "MAY": 4.4,
    "JUN": 4.18,
    "JUL": 4.33,
    "AUG": 4.89,
    "SEP": 5.63,
    "OCT": 5.33,
    "NOV": 5.09,
    "DEC": 5.31
  },
  {
    "LONG": -39.04,
    "LAT": -12.6,
    "CIDADE": "Governador Mangabeira",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.64,
    "FEB": 5.74,
    "MAR": 5.83,
    "APR": 4.91,
    "MAY": 4.42,
    "JUN": 4.09,
    "JUL": 4.35,
    "AUG": 4.83,
    "SEP": 5.46,
    "OCT": 5.45,
    "NOV": 5.43,
    "DEC": 5.61
  },
  {
    "LONG": -3.9,
    "LAT": -12.6,
    "CIDADE": "Cachoeira",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.66,
    "FEB": 5.75,
    "MAR": 5.79,
    "APR": 4.93,
    "MAY": 4.39,
    "JUN": 4.06,
    "JUL": 4.36,
    "AUG": 4.77,
    "SEP": 5.41,
    "OCT": 5.4,
    "NOV": 5.44,
    "DEC": 5.59
  },
  {
    "LONG": -38.97,
    "LAT": -12.61,
    "CIDADE": "São Félix",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.66,
    "FEB": 5.75,
    "MAR": 5.79,
    "APR": 4.93,
    "MAY": 4.39,
    "JUN": 4.06,
    "JUL": 4.36,
    "AUG": 4.77,
    "SEP": 5.41,
    "OCT": 5.4,
    "NOV": 5.44,
    "DEC": 5.59
  },
  {
    "LONG": -38.99,
    "LAT": "#VALOR!",
    "CIDADE": "Muritiba",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.66,
    "FEB": 5.75,
    "MAR": 5.79,
    "APR": 4.93,
    "MAY": 4.39,
    "JUN": 4.06,
    "JUL": 4.36,
    "AUG": 4.77,
    "SEP": 5.41,
    "OCT": 5.4,
    "NOV": 5.44,
    "DEC": 5.59
  },
  {
    "LONG": -38.68,
    "LAT": -12.62,
    "CIDADE": "São Francisco do Conde",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.64,
    "FEB": 5.58,
    "MAR": 5.76,
    "APR": 4.9,
    "MAY": 4.35,
    "JUN": 4.07,
    "JUL": 4.3,
    "AUG": 4.65,
    "SEP": 5.25,
    "OCT": 5.32,
    "NOV": 5.4,
    "DEC": 5.64
  },
  {
    "LONG": -3.83,
    "LAT": -12.62,
    "CIDADE": "Dias d'Ávila",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.75,
    "FEB": 5.87,
    "MAR": 5.88,
    "APR": 4.93,
    "MAY": 4.35,
    "JUN": 4.16,
    "JUL": 4.37,
    "AUG": 4.78,
    "SEP": 5.43,
    "OCT": 5.56,
    "NOV": 5.62,
    "DEC": 5.78
  },
  {
    "LONG": -43.96,
    "LAT": -12.49,
    "CIDADE": "Brejolândia",
    "UF": "BAHIA",
    "anual": 5.96,
    "JAN": 5.98,
    "FEB": 6.05,
    "MAR": 5.86,
    "APR": 5.97,
    "MAY": 5.83,
    "JUN": 5.78,
    "JUL": 6.01,
    "AUG": 6.35,
    "SEP": 6.3,
    "OCT": 6.15,
    "NOV": 5.46,
    "DEC": 5.78
  },
  {
    "LONG": -41.58,
    "LAT": -12.51,
    "CIDADE": "Palmeiras",
    "UF": "BAHIA",
    "anual": 5.33,
    "JAN": 5.61,
    "FEB": 5.86,
    "MAR": 5.73,
    "APR": 5.15,
    "MAY": 4.79,
    "JUN": 4.52,
    "JUL": 4.66,
    "AUG": 5.21,
    "SEP": 5.83,
    "OCT": 5.71,
    "NOV": 5.33,
    "DEC": 5.61
  },
  {
    "LONG": -40.31,
    "LAT": -12.52,
    "CIDADE": "Itaberaba",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.45,
    "FEB": 5.66,
    "MAR": 5.74,
    "APR": 4.86,
    "MAY": 4.33,
    "JUN": 4.07,
    "JUL": 4.2,
    "AUG": 4.68,
    "SEP": 5.3,
    "OCT": 5.09,
    "NOV": 5.09,
    "DEC": 5.35
  },
  {
    "LONG": -39.19,
    "LAT": -12.53,
    "CIDADE": "Cabaceiras do Paraguaçu",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.7,
    "FEB": 5.77,
    "MAR": 5.8,
    "APR": 4.97,
    "MAY": 4.41,
    "JUN": 4.05,
    "JUL": 4.31,
    "AUG": 4.82,
    "SEP": 5.38,
    "OCT": 5.38,
    "NOV": 5.44,
    "DEC": 5.63
  },
  {
    "LONG": -39,
    "LAT": -12.51,
    "CIDADE": "Conceição da Feira",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.62,
    "FEB": 5.7,
    "MAR": 5.75,
    "APR": 4.9,
    "MAY": 4.37,
    "JUN": 4.04,
    "JUL": 4.31,
    "AUG": 4.74,
    "SEP": 5.39,
    "OCT": 5.4,
    "NOV": 5.4,
    "DEC": 5.58
  },
  {
    "LONG": -3.87,
    "LAT": -12.55,
    "CIDADE": "Santo Amaro",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.68,
    "FEB": 5.67,
    "MAR": 5.76,
    "APR": 4.9,
    "MAY": 4.37,
    "JUN": 4.05,
    "JUL": 4.28,
    "AUG": 4.64,
    "SEP": 5.27,
    "OCT": 5.34,
    "NOV": 5.4,
    "DEC": 5.66
  },
  {
    "LONG": -38.49,
    "LAT": -12.51,
    "CIDADE": "São Sebastião do Passé",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.61,
    "FEB": 5.64,
    "MAR": 5.72,
    "APR": 4.79,
    "MAY": 4.25,
    "JUN": 4.04,
    "JUL": 4.24,
    "AUG": 4.67,
    "SEP": 5.27,
    "OCT": 5.4,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -38.3,
    "LAT": -12.53,
    "CIDADE": "Mata de São João",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.65,
    "FEB": 5.69,
    "MAR": 5.76,
    "APR": 4.85,
    "MAY": 4.3,
    "JUN": 4.14,
    "JUL": 4.31,
    "AUG": 4.75,
    "SEP": 5.3,
    "OCT": 5.39,
    "NOV": 5.51,
    "DEC": 5.67
  },
  {
    "LONG": -44.97,
    "LAT": -12.36,
    "CIDADE": "São Desidério",
    "UF": "BAHIA",
    "anual": 5.92,
    "JAN": 5.83,
    "FEB": 5.85,
    "MAR": 5.75,
    "APR": 5.87,
    "MAY": 5.88,
    "JUN": 5.77,
    "JUL": 6.18,
    "AUG": 6.59,
    "SEP": 6.31,
    "OCT": 5.97,
    "NOV": 5.38,
    "DEC": 5.67
  },
  {
    "LONG": -41.77,
    "LAT": -12.42,
    "CIDADE": "Seabra",
    "UF": "BAHIA",
    "anual": 5.28,
    "JAN": 5.55,
    "FEB": 5.74,
    "MAR": 5.68,
    "APR": 5.08,
    "MAY": 4.74,
    "JUN": 4.45,
    "JUL": 4.64,
    "AUG": 5.21,
    "SEP": 5.82,
    "OCT": 5.67,
    "NOV": 5.26,
    "DEC": 5.49
  },
  {
    "LONG": -40.9,
    "LAT": -12.36,
    "CIDADE": "Lajedinho",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.48,
    "FEB": 5.63,
    "MAR": 5.55,
    "APR": 4.79,
    "MAY": 4.38,
    "JUN": 4.18,
    "JUL": 4.29,
    "AUG": 4.87,
    "SEP": 5.59,
    "OCT": 5.36,
    "NOV": 5.17,
    "DEC": 5.4
  },
  {
    "LONG": -3.95,
    "LAT": -12.41,
    "CIDADE": "Rafael Jambeiro",
    "UF": "BAHIA",
    "anual": 5.04,
    "JAN": 5.58,
    "FEB": 5.61,
    "MAR": 5.72,
    "APR": 4.89,
    "MAY": 4.35,
    "JUN": 4.03,
    "JUL": 4.26,
    "AUG": 4.76,
    "SEP": 5.3,
    "OCT": 5.2,
    "NOV": 5.28,
    "DEC": 5.46
  },
  {
    "LONG": -39.25,
    "LAT": -12.43,
    "CIDADE": "Santo Estêvão",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.6,
    "FEB": 5.71,
    "MAR": 5.81,
    "APR": 4.96,
    "MAY": 4.37,
    "JUN": 4.11,
    "JUL": 4.28,
    "AUG": 4.8,
    "SEP": 5.33,
    "OCT": 5.31,
    "NOV": 5.36,
    "DEC": 5.53
  },
  {
    "LONG": -39.12,
    "LAT": -12.43,
    "CIDADE": "Antônio Cardoso",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.65,
    "FEB": 5.73,
    "MAR": 5.74,
    "APR": 4.98,
    "MAY": 4.42,
    "JUN": 4.07,
    "JUL": 4.3,
    "AUG": 4.79,
    "SEP": 5.32,
    "OCT": 5.31,
    "NOV": 5.34,
    "DEC": 5.54
  },
  {
    "LONG": -38.95,
    "LAT": -12.44,
    "CIDADE": "São Gonçalo dos Campos",
    "UF": "BAHIA",
    "anual": 5.06,
    "JAN": 5.6,
    "FEB": 5.63,
    "MAR": 5.7,
    "APR": 4.89,
    "MAY": 4.32,
    "JUN": 3.98,
    "JUL": 4.24,
    "AUG": 4.66,
    "SEP": 5.36,
    "OCT": 5.37,
    "NOV": 5.39,
    "DEC": 5.56
  },
  {
    "LONG": -38.75,
    "LAT": -12.4,
    "CIDADE": "Amélia Rodrigues",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.65,
    "FEB": 5.66,
    "MAR": 5.72,
    "APR": 4.91,
    "MAY": 4.35,
    "JUN": 4.03,
    "JUL": 4.22,
    "AUG": 4.62,
    "SEP": 5.32,
    "OCT": 5.35,
    "NOV": 5.4,
    "DEC": 5.64
  },
  {
    "LONG": -38.62,
    "LAT": -12.4,
    "CIDADE": "Terra Nova",
    "UF": "BAHIA",
    "anual": 5.03,
    "JAN": 5.55,
    "FEB": 5.54,
    "MAR": 5.67,
    "APR": 4.86,
    "MAY": 4.3,
    "JUN": 4,
    "JUL": 4.21,
    "AUG": 4.6,
    "SEP": 5.29,
    "OCT": 5.32,
    "NOV": 5.39,
    "DEC": 5.59
  },
  {
    "LONG": -38.34,
    "LAT": -12.43,
    "CIDADE": "Pojuca",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.63,
    "FEB": 5.68,
    "MAR": 5.73,
    "APR": 4.89,
    "MAY": 4.31,
    "JUN": 4.17,
    "JUL": 4.32,
    "AUG": 4.75,
    "SEP": 5.3,
    "OCT": 5.38,
    "NOV": 5.48,
    "DEC": 5.65
  },
  {
    "LONG": -38.38,
    "LAT": -12.35,
    "CIDADE": "Catu",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.63,
    "FEB": 5.68,
    "MAR": 5.73,
    "APR": 4.89,
    "MAY": 4.31,
    "JUN": 4.17,
    "JUL": 4.32,
    "AUG": 4.75,
    "SEP": 5.3,
    "OCT": 5.38,
    "NOV": 5.48,
    "DEC": 5.65
  },
  {
    "LONG": -44.86,
    "LAT": -12.32,
    "CIDADE": "Catolândia",
    "UF": "BAHIA",
    "anual": 5.88,
    "JAN": 5.73,
    "FEB": 5.76,
    "MAR": 5.62,
    "APR": 5.81,
    "MAY": 5.87,
    "JUN": 5.82,
    "JUL": 6.17,
    "AUG": 6.55,
    "SEP": 6.36,
    "OCT": 5.93,
    "NOV": 5.33,
    "DEC": 5.64
  },
  {
    "LONG": -44.54,
    "LAT": -12.31,
    "CIDADE": "Baianópolis",
    "UF": "BAHIA",
    "anual": 5.91,
    "JAN": 5.81,
    "FEB": 5.83,
    "MAR": 5.72,
    "APR": 5.87,
    "MAY": 5.84,
    "JUN": 5.82,
    "JUL": 6.08,
    "AUG": 6.57,
    "SEP": 6.41,
    "OCT": 5.97,
    "NOV": 5.41,
    "DEC": 5.65
  },
  {
    "LONG": -42.89,
    "LAT": -12.32,
    "CIDADE": "Oliveira dos Brejinhos",
    "UF": "BAHIA",
    "anual": 6.04,
    "JAN": 6.09,
    "FEB": 6.2,
    "MAR": 6.15,
    "APR": 6,
    "MAY": 5.73,
    "JUN": 5.66,
    "JUL": 5.81,
    "AUG": 6.35,
    "SEP": 6.43,
    "OCT": 6.36,
    "NOV": 5.78,
    "DEC": 5.94
  },
  {
    "LONG": -41.17,
    "LAT": -12.28,
    "CIDADE": "Wagner",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.37,
    "FEB": 5.46,
    "MAR": 5.48,
    "APR": 4.8,
    "MAY": 4.32,
    "JUN": 4.06,
    "JUL": 4.16,
    "AUG": 4.79,
    "SEP": 5.45,
    "OCT": 5.24,
    "NOV": 5.04,
    "DEC": 5.23
  },
  {
    "LONG": -40.49,
    "LAT": -12.28,
    "CIDADE": "Ruy Barbosa",
    "UF": "BAHIA",
    "anual": 5,
    "JAN": 5.46,
    "FEB": 5.64,
    "MAR": 5.75,
    "APR": 4.9,
    "MAY": 4.35,
    "JUN": 4.08,
    "JUL": 4.17,
    "AUG": 4.66,
    "SEP": 5.35,
    "OCT": 5.15,
    "NOV": 5.16,
    "DEC": 5.39
  },
  {
    "LONG": -39.31,
    "LAT": -12.32,
    "CIDADE": "Ipecaetá",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.53,
    "FEB": 5.71,
    "MAR": 5.78,
    "APR": 4.95,
    "MAY": 4.37,
    "JUN": 4.06,
    "JUL": 4.27,
    "AUG": 4.76,
    "SEP": 5.32,
    "OCT": 5.29,
    "NOV": 5.33,
    "DEC": 5.52
  },
  {
    "LONG": -38.97,
    "LAT": -12.27,
    "CIDADE": "Feira de Santana",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.63,
    "FEB": 5.66,
    "MAR": 5.77,
    "APR": 4.87,
    "MAY": 4.31,
    "JUN": 3.95,
    "JUL": 4.26,
    "AUG": 4.64,
    "SEP": 5.35,
    "OCT": 5.38,
    "NOV": 5.38,
    "DEC": 5.6
  },
  {
    "LONG": -38.77,
    "LAT": -12.33,
    "CIDADE": "Conceição do Jacuípe",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.65,
    "FEB": 5.68,
    "MAR": 5.75,
    "APR": 4.89,
    "MAY": 4.33,
    "JUN": 4.05,
    "JUL": 4.29,
    "AUG": 4.68,
    "SEP": 5.37,
    "OCT": 5.35,
    "NOV": 5.42,
    "DEC": 5.59
  },
  {
    "LONG": -38.64,
    "LAT": -12.3,
    "CIDADE": "Teodoro Sampaio",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.61,
    "FEB": 5.64,
    "MAR": 5.73,
    "APR": 4.91,
    "MAY": 4.3,
    "JUN": 4.05,
    "JUL": 4.29,
    "AUG": 4.67,
    "SEP": 5.34,
    "OCT": 5.34,
    "NOV": 5.45,
    "DEC": 5.58
  },
  {
    "LONG": -38.04,
    "LAT": -12.25,
    "CIDADE": "Itanagra",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.71,
    "FEB": 5.76,
    "MAR": 5.88,
    "APR": 4.99,
    "MAY": 4.41,
    "JUN": 4.31,
    "JUL": 4.43,
    "AUG": 4.76,
    "SEP": 5.41,
    "OCT": 5.47,
    "NOV": 5.59,
    "DEC": 5.79
  },
  {
    "LONG": -44.42,
    "LAT": -12.24,
    "CIDADE": "Cristópolis",
    "UF": "BAHIA",
    "anual": 5.93,
    "JAN": 5.84,
    "FEB": 5.82,
    "MAR": 5.67,
    "APR": 5.79,
    "MAY": 5.85,
    "JUN": 5.83,
    "JUL": 6.15,
    "AUG": 6.57,
    "SEP": 6.48,
    "OCT": 6.05,
    "NOV": 5.44,
    "DEC": 5.66
  },
  {
    "LONG": -4.32,
    "LAT": -12.18,
    "CIDADE": "Ibotirama",
    "UF": "BAHIA",
    "anual": 6.08,
    "JAN": 6.11,
    "FEB": 6.27,
    "MAR": 6.26,
    "APR": 6.13,
    "MAY": 5.83,
    "JUN": 5.7,
    "JUL": 5.84,
    "AUG": 6.35,
    "SEP": 6.38,
    "OCT": 6.3,
    "NOV": 5.8,
    "DEC": 5.98
  },
  {
    "LONG": -41.62,
    "LAT": -12.24,
    "CIDADE": "Iraquara",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.59,
    "FEB": 5.71,
    "MAR": 5.68,
    "APR": 5.09,
    "MAY": 4.62,
    "JUN": 4.4,
    "JUL": 4.54,
    "AUG": 5.14,
    "SEP": 5.84,
    "OCT": 5.69,
    "NOV": 5.38,
    "DEC": 5.61
  },
  {
    "LONG": -39.74,
    "LAT": -12.16,
    "CIDADE": "Ipirá",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.61,
    "FEB": 5.69,
    "MAR": 5.77,
    "APR": 4.89,
    "MAY": 4.33,
    "JUN": 4.13,
    "JUL": 4.26,
    "AUG": 4.77,
    "SEP": 5.39,
    "OCT": 5.31,
    "NOV": 5.37,
    "DEC": 5.47
  },
  {
    "LONG": -3.93,
    "LAT": -12.16,
    "CIDADE": "Serra Preta",
    "UF": "BAHIA",
    "anual": 5.05,
    "JAN": 5.59,
    "FEB": 5.67,
    "MAR": 5.71,
    "APR": 4.91,
    "MAY": 4.35,
    "JUN": 4.01,
    "JUL": 4.23,
    "AUG": 4.73,
    "SEP": 5.35,
    "OCT": 5.27,
    "NOV": 5.33,
    "DEC": 5.48
  },
  {
    "LONG": -39.24,
    "LAT": -12.15,
    "CIDADE": "Anguera",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.58,
    "FEB": 5.64,
    "MAR": 5.69,
    "APR": 4.88,
    "MAY": 4.29,
    "JUN": 3.98,
    "JUL": 4.21,
    "AUG": 4.67,
    "SEP": 5.26,
    "OCT": 5.23,
    "NOV": 5.32,
    "DEC": 5.52
  },
  {
    "LONG": -38.75,
    "LAT": -12.25,
    "CIDADE": "Coração de Maria",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.63,
    "FEB": 5.62,
    "MAR": 5.76,
    "APR": 4.95,
    "MAY": 4.32,
    "JUN": 4.09,
    "JUL": 4.3,
    "AUG": 4.75,
    "SEP": 5.39,
    "OCT": 5.38,
    "NOV": 5.46,
    "DEC": 5.6
  },
  {
    "LONG": -3.82,
    "LAT": -12.21,
    "CIDADE": "Araças",
    "UF": "BAHIA",
    "anual": 5.12,
    "JAN": 5.61,
    "FEB": 5.65,
    "MAR": 5.75,
    "APR": 4.93,
    "MAY": 4.36,
    "JUN": 4.23,
    "JUL": 4.35,
    "AUG": 4.71,
    "SEP": 5.31,
    "OCT": 5.38,
    "NOV": 5.51,
    "DEC": 5.66
  },
  {
    "LONG": -45.8,
    "LAT": -1.21,
    "CIDADE": "Luís Eduardo Magalhães",
    "UF": "BAHIA",
    "anual": 5.73,
    "JAN": 5.64,
    "FEB": 5.56,
    "MAR": 5.38,
    "APR": 5.6,
    "MAY": 5.73,
    "JUN": 5.71,
    "JUL": 5.98,
    "AUG": 6.38,
    "SEP": 6.36,
    "OCT": 5.85,
    "NOV": 5.25,
    "DEC": 5.37
  },
  {
    "LONG": -45,
    "LAT": -12.14,
    "CIDADE": "Barreiras",
    "UF": "BAHIA",
    "anual": 5.96,
    "JAN": 5.91,
    "FEB": 5.9,
    "MAR": 5.85,
    "APR": 5.88,
    "MAY": 5.85,
    "JUN": 5.79,
    "JUL": 6.11,
    "AUG": 6.54,
    "SEP": 6.4,
    "OCT": 6.1,
    "NOV": 5.52,
    "DEC": 5.74
  },
  {
    "LONG": -43.9,
    "LAT": -12.11,
    "CIDADE": "Wanderley",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 6.05,
    "FEB": 6.07,
    "MAR": 5.97,
    "APR": 6,
    "MAY": 5.87,
    "JUN": 5.69,
    "JUL": 5.9,
    "AUG": 6.34,
    "SEP": 6.34,
    "OCT": 6.12,
    "NOV": 5.62,
    "DEC": 5.87
  },
  {
    "LONG": -43.55,
    "LAT": -12.07,
    "CIDADE": "Muquém de São Francisco",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 6.07,
    "FEB": 6.08,
    "MAR": 6.09,
    "APR": 6.02,
    "MAY": 5.83,
    "JUN": 5.65,
    "JUL": 5.85,
    "AUG": 6.29,
    "SEP": 6.31,
    "OCT": 6.13,
    "NOV": 5.67,
    "DEC": 5.89
  },
  {
    "LONG": -41.64,
    "LAT": -12.09,
    "CIDADE": "Souto Soares",
    "UF": "BAHIA",
    "anual": 5.34,
    "JAN": 5.65,
    "FEB": 5.72,
    "MAR": 5.73,
    "APR": 5.1,
    "MAY": 4.66,
    "JUN": 4.43,
    "JUL": 4.58,
    "AUG": 5.24,
    "SEP": 5.94,
    "OCT": 5.84,
    "NOV": 5.48,
    "DEC": 5.7
  },
  {
    "LONG": -41.1,
    "LAT": -12.08,
    "CIDADE": "Utinga",
    "UF": "BAHIA",
    "anual": 5,
    "JAN": 5.42,
    "FEB": 5.52,
    "MAR": 5.52,
    "APR": 4.76,
    "MAY": 4.28,
    "JUN": 4.05,
    "JUL": 4.22,
    "AUG": 4.82,
    "SEP": 5.56,
    "OCT": 5.35,
    "NOV": 5.18,
    "DEC": 5.34
  },
  {
    "LONG": -40.36,
    "LAT": -12.14,
    "CIDADE": "Macajuba",
    "UF": "BAHIA",
    "anual": 4.95,
    "JAN": 5.44,
    "FEB": 5.65,
    "MAR": 5.69,
    "APR": 4.8,
    "MAY": 4.29,
    "JUN": 3.99,
    "JUL": 4.11,
    "AUG": 4.6,
    "SEP": 5.29,
    "OCT": 5.1,
    "NOV": 5.15,
    "DEC": 5.35
  },
  {
    "LONG": -38.76,
    "LAT": -12.05,
    "CIDADE": "Irará",
    "UF": "BAHIA",
    "anual": 5.12,
    "JAN": 5.66,
    "FEB": 5.59,
    "MAR": 5.75,
    "APR": 4.96,
    "MAY": 4.32,
    "JUN": 4.09,
    "JUL": 4.33,
    "AUG": 4.75,
    "SEP": 5.4,
    "OCT": 5.4,
    "NOV": 5.51,
    "DEC": 5.64
  },
  {
    "LONG": -38.64,
    "LAT": -12.15,
    "CIDADE": "Pedrão",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.66,
    "FEB": 5.62,
    "MAR": 5.74,
    "APR": 4.96,
    "MAY": 4.32,
    "JUN": 4.1,
    "JUL": 4.33,
    "AUG": 4.73,
    "SEP": 5.39,
    "OCT": 5.38,
    "NOV": 5.52,
    "DEC": 5.63
  },
  {
    "LONG": -38.42,
    "LAT": -1.21,
    "CIDADE": "Alagoinhas",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.65,
    "FEB": 5.65,
    "MAR": 5.74,
    "APR": 4.96,
    "MAY": 4.25,
    "JUN": 4.08,
    "JUL": 4.3,
    "AUG": 4.67,
    "SEP": 5.31,
    "OCT": 5.35,
    "NOV": 5.48,
    "DEC": 5.66
  },
  {
    "LONG": -38.5,
    "LAT": -12.09,
    "CIDADE": "Aramari",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.65,
    "FEB": 5.65,
    "MAR": 5.74,
    "APR": 4.96,
    "MAY": 4.25,
    "JUN": 4.08,
    "JUL": 4.3,
    "AUG": 4.67,
    "SEP": 5.31,
    "OCT": 5.35,
    "NOV": 5.48,
    "DEC": 5.66
  },
  {
    "LONG": -44.7,
    "LAT": -12.01,
    "CIDADE": "Angical",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 5.93,
    "FEB": 5.96,
    "MAR": 5.92,
    "APR": 5.92,
    "MAY": 5.88,
    "JUN": 5.79,
    "JUL": 6.05,
    "AUG": 6.48,
    "SEP": 6.34,
    "OCT": 6.09,
    "NOV": 5.59,
    "DEC": 5.79
  },
  {
    "LONG": -44.26,
    "LAT": -12.03,
    "CIDADE": "Cotegipe",
    "UF": "BAHIA",
    "anual": 5.98,
    "JAN": 6.03,
    "FEB": 5.98,
    "MAR": 5.87,
    "APR": 5.97,
    "MAY": 5.87,
    "JUN": 5.76,
    "JUL": 6.01,
    "AUG": 6.42,
    "SEP": 6.33,
    "OCT": 6.06,
    "NOV": 5.63,
    "DEC": 5.85
  },
  {
    "LONG": -42.63,
    "LAT": -12,
    "CIDADE": "Brotas de Macaúbas",
    "UF": "BAHIA",
    "anual": 5.88,
    "JAN": 5.86,
    "FEB": 5.92,
    "MAR": 5.87,
    "APR": 5.75,
    "MAY": 5.54,
    "JUN": 5.54,
    "JUL": 5.74,
    "AUG": 6.4,
    "SEP": 6.57,
    "OCT": 6.11,
    "NOV": 5.5,
    "DEC": 5.75
  },
  {
    "LONG": -41.64,
    "LAT": -11.97,
    "CIDADE": "Mulungu do Morro",
    "UF": "BAHIA",
    "anual": 5.43,
    "JAN": 5.73,
    "FEB": 5.78,
    "MAR": 5.82,
    "APR": 5.17,
    "MAY": 4.75,
    "JUN": 4.54,
    "JUL": 4.71,
    "AUG": 5.38,
    "SEP": 6.06,
    "OCT": 5.98,
    "NOV": 5.56,
    "DEC": 5.75
  },
  {
    "LONG": -4.13,
    "LAT": -11.97,
    "CIDADE": "Bonito",
    "UF": "BAHIA",
    "anual": 5.07,
    "JAN": 5.49,
    "FEB": 5.58,
    "MAR": 5.56,
    "APR": 4.77,
    "MAY": 4.32,
    "JUN": 4.09,
    "JUL": 4.24,
    "AUG": 4.91,
    "SEP": 5.6,
    "OCT": 5.51,
    "NOV": 5.3,
    "DEC": 5.46
  },
  {
    "LONG": -40.17,
    "LAT": -11.96,
    "CIDADE": "Baixa Grande",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.53,
    "FEB": 5.66,
    "MAR": 5.75,
    "APR": 4.85,
    "MAY": 4.26,
    "JUN": 4,
    "JUL": 4.12,
    "AUG": 4.67,
    "SEP": 5.36,
    "OCT": 5.23,
    "NOV": 5.28,
    "DEC": 5.47
  },
  {
    "LONG": -39.1,
    "LAT": -11.98,
    "CIDADE": "Tanquinho",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.64,
    "FEB": 5.69,
    "MAR": 5.73,
    "APR": 4.96,
    "MAY": 4.38,
    "JUN": 4.08,
    "JUL": 4.3,
    "AUG": 4.7,
    "SEP": 5.31,
    "OCT": 5.31,
    "NOV": 5.39,
    "DEC": 5.53
  },
  {
    "LONG": -38.97,
    "LAT": -11.95,
    "CIDADE": "Santa Bárbara",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.69,
    "FEB": 5.69,
    "MAR": 5.71,
    "APR": 4.93,
    "MAY": 4.32,
    "JUN": 4.06,
    "JUL": 4.33,
    "AUG": 4.71,
    "SEP": 5.3,
    "OCT": 5.3,
    "NOV": 5.42,
    "DEC": 5.6
  },
  {
    "LONG": -38.87,
    "LAT": -12.03,
    "CIDADE": "Santanópolis",
    "UF": "BAHIA",
    "anual": 5.12,
    "JAN": 5.65,
    "FEB": 5.68,
    "MAR": 5.74,
    "APR": 4.99,
    "MAY": 4.29,
    "JUN": 4.09,
    "JUL": 4.34,
    "AUG": 4.77,
    "SEP": 5.44,
    "OCT": 5.37,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -38.63,
    "LAT": -12.01,
    "CIDADE": "Ouriçangas",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.68,
    "FEB": 5.65,
    "MAR": 5.74,
    "APR": 4.97,
    "MAY": 4.31,
    "JUN": 4.11,
    "JUL": 4.37,
    "AUG": 4.73,
    "SEP": 5.41,
    "OCT": 5.41,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -40.47,
    "LAT": -1.19,
    "CIDADE": "Mundo Novo",
    "UF": "BAHIA",
    "anual": 4.96,
    "JAN": 5.5,
    "FEB": 5.58,
    "MAR": 5.69,
    "APR": 4.82,
    "MAY": 4.24,
    "JUN": 3.97,
    "JUL": 4.07,
    "AUG": 4.67,
    "SEP": 5.32,
    "OCT": 5.15,
    "NOV": 5.19,
    "DEC": 5.33
  },
  {
    "LONG": -38.76,
    "LAT": -11.86,
    "CIDADE": "Água Fria",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.67,
    "FEB": 5.61,
    "MAR": 5.69,
    "APR": 4.97,
    "MAY": 4.33,
    "JUN": 4.1,
    "JUL": 4.37,
    "AUG": 4.76,
    "SEP": 5.42,
    "OCT": 5.38,
    "NOV": 5.49,
    "DEC": 5.58
  },
  {
    "LONG": -38.09,
    "LAT": -11.94,
    "CIDADE": "Entre Rios",
    "UF": "BAHIA",
    "anual": 5.19,
    "JAN": 5.69,
    "FEB": 5.67,
    "MAR": 5.8,
    "APR": 5.05,
    "MAY": 4.44,
    "JUN": 4.32,
    "JUL": 4.45,
    "AUG": 4.74,
    "SEP": 5.4,
    "OCT": 5.44,
    "NOV": 5.56,
    "DEC": 5.69
  },
  {
    "LONG": -37.95,
    "LAT": -11.94,
    "CIDADE": "Cardeal da Silva",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.66,
    "FEB": 5.65,
    "MAR": 5.77,
    "APR": 5.01,
    "MAY": 4.41,
    "JUN": 4.28,
    "JUL": 4.43,
    "AUG": 4.75,
    "SEP": 5.33,
    "OCT": 5.42,
    "NOV": 5.55,
    "DEC": 5.69
  },
  {
    "LONG": -42.61,
    "LAT": -1.18,
    "CIDADE": "Ipupiara",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 5.98,
    "FEB": 6.07,
    "MAR": 6.08,
    "APR": 5.94,
    "MAY": 5.64,
    "JUN": 5.58,
    "JUL": 5.73,
    "AUG": 6.3,
    "SEP": 6.49,
    "OCT": 6.3,
    "NOV": 5.78,
    "DEC": 5.95
  },
  {
    "LONG": -42.06,
    "LAT": -11.81,
    "CIDADE": "Barra do Mendes",
    "UF": "BAHIA",
    "anual": 5.64,
    "JAN": 5.79,
    "FEB": 5.85,
    "MAR": 5.93,
    "APR": 5.35,
    "MAY": 5.04,
    "JUN": 4.88,
    "JUL": 5.11,
    "AUG": 5.77,
    "SEP": 6.32,
    "OCT": 6.12,
    "NOV": 5.7,
    "DEC": 5.76
  },
  {
    "LONG": -41.91,
    "LAT": -11.76,
    "CIDADE": "Barro Alto",
    "UF": "BAHIA",
    "anual": 5.64,
    "JAN": 5.85,
    "FEB": 5.9,
    "MAR": 5.95,
    "APR": 5.34,
    "MAY": 5.03,
    "JUN": 4.88,
    "JUL": 5.11,
    "AUG": 5.71,
    "SEP": 6.28,
    "OCT": 6.13,
    "NOV": 5.7,
    "DEC": 5.79
  },
  {
    "LONG": -4.08,
    "LAT": -11.85,
    "CIDADE": "Tapiramutá",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.51,
    "FEB": 5.56,
    "MAR": 5.58,
    "APR": 4.81,
    "MAY": 4.24,
    "JUN": 4.02,
    "JUL": 4.14,
    "AUG": 4.75,
    "SEP": 5.51,
    "OCT": 5.26,
    "NOV": 5.21,
    "DEC": 5.34
  },
  {
    "LONG": -39.91,
    "LAT": -11.81,
    "CIDADE": "Pintadas",
    "UF": "BAHIA",
    "anual": 5.04,
    "JAN": 5.65,
    "FEB": 5.65,
    "MAR": 5.75,
    "APR": 4.92,
    "MAY": 4.24,
    "JUN": 4.03,
    "JUL": 4.12,
    "AUG": 4.6,
    "SEP": 5.35,
    "OCT": 5.27,
    "NOV": 5.38,
    "DEC": 5.52
  },
  {
    "LONG": -39.61,
    "LAT": -11.83,
    "CIDADE": "Pé de Serra",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.65,
    "FEB": 5.74,
    "MAR": 5.84,
    "APR": 5.02,
    "MAY": 4.32,
    "JUN": 4.07,
    "JUL": 4.28,
    "AUG": 4.74,
    "SEP": 5.43,
    "OCT": 5.41,
    "NOV": 5.47,
    "DEC": 5.57
  },
  {
    "LONG": -39.38,
    "LAT": -11.81,
    "CIDADE": "Riachão do Jacuípe",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.77,
    "FEB": 5.73,
    "MAR": 5.8,
    "APR": 5.01,
    "MAY": 4.33,
    "JUN": 4.08,
    "JUL": 4.31,
    "AUG": 4.73,
    "SEP": 5.43,
    "OCT": 5.4,
    "NOV": 5.49,
    "DEC": 5.52
  },
  {
    "LONG": -39.12,
    "LAT": -11.81,
    "CIDADE": "Candeal",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.76,
    "FEB": 5.79,
    "MAR": 5.8,
    "APR": 5.06,
    "MAY": 4.39,
    "JUN": 4.11,
    "JUL": 4.38,
    "AUG": 4.73,
    "SEP": 5.37,
    "OCT": 5.36,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -38.89,
    "LAT": -11.77,
    "CIDADE": "Lamarão",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.68,
    "FEB": 5.63,
    "MAR": 5.69,
    "APR": 4.96,
    "MAY": 4.33,
    "JUN": 4.08,
    "JUL": 4.31,
    "AUG": 4.64,
    "SEP": 5.25,
    "OCT": 5.29,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -38.36,
    "LAT": -11.79,
    "CIDADE": "Inhambupe",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.63,
    "FEB": 5.6,
    "MAR": 5.71,
    "APR": 5.03,
    "MAY": 4.48,
    "JUN": 4.22,
    "JUL": 4.4,
    "AUG": 4.78,
    "SEP": 5.37,
    "OCT": 5.37,
    "NOV": 5.52,
    "DEC": 5.62
  },
  {
    "LONG": -37.95,
    "LAT": -1.18,
    "CIDADE": "Esplanada",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.67,
    "FEB": 5.68,
    "MAR": 5.71,
    "APR": 5.02,
    "MAY": 4.37,
    "JUN": 4.26,
    "JUL": 4.35,
    "AUG": 4.68,
    "SEP": 5.33,
    "OCT": 5.46,
    "NOV": 5.63,
    "DEC": 5.71
  },
  {
    "LONG": -37.61,
    "LAT": -11.82,
    "CIDADE": "Conde",
    "UF": "BAHIA",
    "anual": 5.38,
    "JAN": 5.87,
    "FEB": 5.92,
    "MAR": 6.07,
    "APR": 5.2,
    "MAY": 4.66,
    "JUN": 4.42,
    "JUL": 4.51,
    "AUG": 4.94,
    "SEP": 5.54,
    "OCT": 5.64,
    "NOV": 5.8,
    "DEC": 5.95
  },
  {
    "LONG": -44.91,
    "LAT": -1.17,
    "CIDADE": "Riachão das Neves",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 5.81,
    "FEB": 5.8,
    "MAR": 5.71,
    "APR": 5.81,
    "MAY": 5.89,
    "JUN": 5.93,
    "JUL": 6.13,
    "AUG": 6.51,
    "SEP": 6.45,
    "OCT": 6.03,
    "NOV": 5.51,
    "DEC": 5.65
  },
  {
    "LONG": -4.18,
    "LAT": -11.69,
    "CIDADE": "Canarana",
    "UF": "BAHIA",
    "anual": 5.63,
    "JAN": 5.87,
    "FEB": 5.94,
    "MAR": 5.97,
    "APR": 5.33,
    "MAY": 4.97,
    "JUN": 4.83,
    "JUL": 5.02,
    "AUG": 5.65,
    "SEP": 6.23,
    "OCT": 6.14,
    "NOV": 5.75,
    "DEC": 5.9
  },
  {
    "LONG": -41.47,
    "LAT": -11.69,
    "CIDADE": "Cafarnaum",
    "UF": "BAHIA",
    "anual": 5.62,
    "JAN": 5.92,
    "FEB": 5.93,
    "MAR": 5.94,
    "APR": 5.28,
    "MAY": 4.9,
    "JUN": 4.79,
    "JUL": 4.92,
    "AUG": 5.58,
    "SEP": 6.2,
    "OCT": 6.18,
    "NOV": 5.84,
    "DEC": 5.92
  },
  {
    "LONG": -40.55,
    "LAT": -11.73,
    "CIDADE": "Piritiba",
    "UF": "BAHIA",
    "anual": 5.02,
    "JAN": 5.61,
    "FEB": 5.6,
    "MAR": 5.66,
    "APR": 4.9,
    "MAY": 4.26,
    "JUN": 4.03,
    "JUL": 4.12,
    "AUG": 4.7,
    "SEP": 5.47,
    "OCT": 5.26,
    "NOV": 5.29,
    "DEC": 5.39
  },
  {
    "LONG": -40.15,
    "LAT": -1.17,
    "CIDADE": "Mairi",
    "UF": "BAHIA",
    "anual": 5.04,
    "JAN": 5.58,
    "FEB": 5.62,
    "MAR": 5.75,
    "APR": 4.89,
    "MAY": 4.31,
    "JUN": 4.04,
    "JUL": 4.23,
    "AUG": 4.67,
    "SEP": 5.36,
    "OCT": 5.25,
    "NOV": 5.3,
    "DEC": 5.45
  },
  {
    "LONG": -39.84,
    "LAT": -11.67,
    "CIDADE": "Capela do Alto Alegre",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.68,
    "FEB": 5.71,
    "MAR": 5.8,
    "APR": 4.94,
    "MAY": 4.28,
    "JUN": 3.97,
    "JUL": 4.19,
    "AUG": 4.68,
    "SEP": 5.43,
    "OCT": 5.36,
    "NOV": 5.46,
    "DEC": 5.57
  },
  {
    "LONG": -39.19,
    "LAT": -11.75,
    "CIDADE": "Ichu",
    "UF": "BAHIA",
    "anual": 5.14,
    "JAN": 5.7,
    "FEB": 5.65,
    "MAR": 5.78,
    "APR": 5.04,
    "MAY": 4.35,
    "JUN": 4.12,
    "JUL": 4.33,
    "AUG": 4.71,
    "SEP": 5.41,
    "OCT": 5.43,
    "NOV": 5.54,
    "DEC": 5.58
  },
  {
    "LONG": -39.01,
    "LAT": -11.66,
    "CIDADE": "Serrinha",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.78,
    "FEB": 5.71,
    "MAR": 5.79,
    "APR": 5.01,
    "MAY": 4.37,
    "JUN": 4.07,
    "JUL": 4.31,
    "AUG": 4.73,
    "SEP": 5.42,
    "OCT": 5.44,
    "NOV": 5.59,
    "DEC": 5.65
  },
  {
    "LONG": -38.02,
    "LAT": -1.17,
    "CIDADE": "Acajutiba",
    "UF": "BAHIA",
    "anual": 5.19,
    "JAN": 5.69,
    "FEB": 5.66,
    "MAR": 5.75,
    "APR": 5.05,
    "MAY": 4.43,
    "JUN": 4.29,
    "JUL": 4.44,
    "AUG": 4.71,
    "SEP": 5.37,
    "OCT": 5.47,
    "NOV": 5.64,
    "DEC": 5.74
  },
  {
    "LONG": -38.08,
    "LAT": -11.66,
    "CIDADE": "Aporá",
    "UF": "BAHIA",
    "anual": 5.19,
    "JAN": 5.69,
    "FEB": 5.66,
    "MAR": 5.75,
    "APR": 5.05,
    "MAY": 4.43,
    "JUN": 4.29,
    "JUL": 4.44,
    "AUG": 4.71,
    "SEP": 5.37,
    "OCT": 5.47,
    "NOV": 5.64,
    "DEC": 5.74
  },
  {
    "LONG": -43.28,
    "LAT": -11.56,
    "CIDADE": "Morpará",
    "UF": "BAHIA",
    "anual": 6.04,
    "JAN": 5.96,
    "FEB": 6.06,
    "MAR": 6.13,
    "APR": 6.04,
    "MAY": 5.84,
    "JUN": 5.67,
    "JUL": 5.91,
    "AUG": 6.41,
    "SEP": 6.56,
    "OCT": 6.27,
    "NOV": 5.79,
    "DEC": 5.88
  },
  {
    "LONG": -42.02,
    "LAT": -11.64,
    "CIDADE": "Ibipeba",
    "UF": "BAHIA",
    "anual": 5.65,
    "JAN": 5.85,
    "FEB": 5.82,
    "MAR": 5.91,
    "APR": 5.39,
    "MAY": 5.06,
    "JUN": 4.92,
    "JUL": 5.16,
    "AUG": 5.81,
    "SEP": 6.35,
    "OCT": 6.11,
    "NOV": 5.64,
    "DEC": 5.76
  },
  {
    "LONG": -40.14,
    "LAT": -1.16,
    "CIDADE": "Várzea da Roça",
    "UF": "BAHIA",
    "anual": 5.08,
    "JAN": 5.63,
    "FEB": 5.67,
    "MAR": 5.75,
    "APR": 4.9,
    "MAY": 4.32,
    "JUN": 4.09,
    "JUL": 4.25,
    "AUG": 4.71,
    "SEP": 5.49,
    "OCT": 5.31,
    "NOV": 5.39,
    "DEC": 5.49
  },
  {
    "LONG": -39.63,
    "LAT": -11.61,
    "CIDADE": "Nova Fátima",
    "UF": "BAHIA",
    "anual": 5.17,
    "JAN": 5.77,
    "FEB": 5.78,
    "MAR": 5.86,
    "APR": 5.06,
    "MAY": 4.34,
    "JUN": 4.08,
    "JUL": 4.32,
    "AUG": 4.76,
    "SEP": 5.49,
    "OCT": 5.45,
    "NOV": 5.54,
    "DEC": 5.6
  },
  {
    "LONG": -39.28,
    "LAT": -11.56,
    "CIDADE": "Conceição do Coité",
    "UF": "BAHIA",
    "anual": 5.18,
    "JAN": 5.74,
    "FEB": 5.72,
    "MAR": 5.82,
    "APR": 5.06,
    "MAY": 4.43,
    "JUN": 4.15,
    "JUL": 4.34,
    "AUG": 4.85,
    "SEP": 5.49,
    "OCT": 5.48,
    "NOV": 5.55,
    "DEC": 5.54
  },
  {
    "LONG": -38.81,
    "LAT": -11.62,
    "CIDADE": "Biritinga",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.74,
    "FEB": 5.69,
    "MAR": 5.75,
    "APR": 5.08,
    "MAY": 4.37,
    "JUN": 4.17,
    "JUL": 4.37,
    "AUG": 4.73,
    "SEP": 5.42,
    "OCT": 5.42,
    "NOV": 5.55,
    "DEC": 5.61
  },
  {
    "LONG": -38.58,
    "LAT": -1.16,
    "CIDADE": "Sátiro Dias",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.63,
    "FEB": 5.67,
    "MAR": 5.78,
    "APR": 5.05,
    "MAY": 4.42,
    "JUN": 4.22,
    "JUL": 4.38,
    "AUG": 4.79,
    "SEP": 5.39,
    "OCT": 5.38,
    "NOV": 5.59,
    "DEC": 5.6
  },
  {
    "LONG": -37.79,
    "LAT": -11.56,
    "CIDADE": "Jandaíra",
    "UF": "BAHIA",
    "anual": 5.24,
    "JAN": 5.72,
    "FEB": 5.77,
    "MAR": 5.84,
    "APR": 5.1,
    "MAY": 4.48,
    "JUN": 4.38,
    "JUL": 4.43,
    "AUG": 4.81,
    "SEP": 5.39,
    "OCT": 5.49,
    "NOV": 5.66,
    "DEC": 5.78
  },
  {
    "LONG": -41.98,
    "LAT": -11.54,
    "CIDADE": "Ibititá",
    "UF": "BAHIA",
    "anual": 5.75,
    "JAN": 5.97,
    "FEB": 5.99,
    "MAR": 6.03,
    "APR": 5.5,
    "MAY": 5.16,
    "JUN": 5.02,
    "JUL": 5.24,
    "AUG": 5.93,
    "SEP": 6.36,
    "OCT": 6.2,
    "NOV": 5.76,
    "DEC": 5.88
  },
  {
    "LONG": -41.16,
    "LAT": -11.55,
    "CIDADE": "Morro do Chapéu",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.67,
    "FEB": 5.71,
    "MAR": 5.75,
    "APR": 4.95,
    "MAY": 4.49,
    "JUN": 4.3,
    "JUL": 4.46,
    "AUG": 5.1,
    "SEP": 5.89,
    "OCT": 5.83,
    "NOV": 5.51,
    "DEC": 5.59
  },
  {
    "LONG": -40.32,
    "LAT": -11.53,
    "CIDADE": "Várzea do Poço",
    "UF": "BAHIA",
    "anual": 4.99,
    "JAN": 5.6,
    "FEB": 5.62,
    "MAR": 5.63,
    "APR": 4.83,
    "MAY": 4.18,
    "JUN": 3.98,
    "JUL": 4.11,
    "AUG": 4.66,
    "SEP": 5.39,
    "OCT": 5.24,
    "NOV": 5.3,
    "DEC": 5.38
  },
  {
    "LONG": -40.02,
    "LAT": -11.51,
    "CIDADE": "São José do Jacuípe",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.67,
    "FEB": 5.69,
    "MAR": 5.77,
    "APR": 4.88,
    "MAY": 4.31,
    "JUN": 4.06,
    "JUL": 4.22,
    "AUG": 4.71,
    "SEP": 5.45,
    "OCT": 5.36,
    "NOV": 5.45,
    "DEC": 5.49
  },
  {
    "LONG": -39.78,
    "LAT": -11.47,
    "CIDADE": "Gavião",
    "UF": "BAHIA",
    "anual": 5.17,
    "JAN": 5.78,
    "FEB": 5.74,
    "MAR": 5.83,
    "APR": 5.01,
    "MAY": 4.37,
    "JUN": 4.07,
    "JUL": 4.31,
    "AUG": 4.82,
    "SEP": 5.5,
    "OCT": 5.44,
    "NOV": 5.58,
    "DEC": 5.53
  },
  {
    "LONG": -39.53,
    "LAT": -11.47,
    "CIDADE": "São Domingos",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.68,
    "FEB": 5.71,
    "MAR": 5.86,
    "APR": 5.03,
    "MAY": 4.36,
    "JUN": 4.1,
    "JUL": 4.33,
    "AUG": 4.76,
    "SEP": 5.5,
    "OCT": 5.43,
    "NOV": 5.52,
    "DEC": 5.52
  },
  {
    "LONG": -39.43,
    "LAT": -11.49,
    "CIDADE": "Retirolândia",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.7,
    "FEB": 5.68,
    "MAR": 5.86,
    "APR": 5.05,
    "MAY": 4.38,
    "JUN": 4.13,
    "JUL": 4.36,
    "AUG": 4.77,
    "SEP": 5.5,
    "OCT": 5.44,
    "NOV": 5.53,
    "DEC": 5.54
  },
  {
    "LONG": -39.08,
    "LAT": -11.53,
    "CIDADE": "Barrocas",
    "UF": "BAHIA",
    "anual": 5.2,
    "JAN": 5.73,
    "FEB": 5.71,
    "MAR": 5.83,
    "APR": 5.1,
    "MAY": 4.42,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 4.78,
    "SEP": 5.49,
    "OCT": 5.48,
    "NOV": 5.62,
    "DEC": 5.61
  },
  {
    "LONG": -39,
    "LAT": -1.15,
    "CIDADE": "Teofilândia",
    "UF": "BAHIA",
    "anual": 5.2,
    "JAN": 5.76,
    "FEB": 5.73,
    "MAR": 5.81,
    "APR": 5.12,
    "MAY": 4.45,
    "JUN": 4.23,
    "JUL": 4.44,
    "AUG": 4.81,
    "SEP": 5.44,
    "OCT": 5.44,
    "NOV": 5.58,
    "DEC": 5.62
  },
  {
    "LONG": -38.15,
    "LAT": -11.51,
    "CIDADE": "Crisópolis",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.68,
    "FEB": 5.7,
    "MAR": 5.75,
    "APR": 5.11,
    "MAY": 4.48,
    "JUN": 4.36,
    "JUL": 4.45,
    "AUG": 4.85,
    "SEP": 5.37,
    "OCT": 5.43,
    "NOV": 5.67,
    "DEC": 5.65
  },
  {
    "LONG": -37.93,
    "LAT": -11.48,
    "CIDADE": "Rio Real",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.77,
    "FEB": 5.77,
    "MAR": 5.79,
    "APR": 5.1,
    "MAY": 4.47,
    "JUN": 4.34,
    "JUL": 4.44,
    "AUG": 4.8,
    "SEP": 5.4,
    "OCT": 5.5,
    "NOV": 5.66,
    "DEC": 5.74
  },
  {
    "LONG": -4.25,
    "LAT": -11.43,
    "CIDADE": "Gentio do Ouro",
    "UF": "BAHIA",
    "anual": 6.06,
    "JAN": 5.99,
    "FEB": 6.06,
    "MAR": 6.1,
    "APR": 5.89,
    "MAY": 5.66,
    "JUN": 5.66,
    "JUL": 5.94,
    "AUG": 6.57,
    "SEP": 6.75,
    "OCT": 6.39,
    "NOV": 5.84,
    "DEC": 5.92
  },
  {
    "LONG": -4.18,
    "LAT": -11.39,
    "CIDADE": "Lapão",
    "UF": "BAHIA",
    "anual": 5.79,
    "JAN": 6,
    "FEB": 5.99,
    "MAR": 6.03,
    "APR": 5.51,
    "MAY": 5.2,
    "JUN": 5.09,
    "JUL": 5.3,
    "AUG": 5.96,
    "SEP": 6.38,
    "OCT": 6.28,
    "NOV": 5.85,
    "DEC": 5.93
  },
  {
    "LONG": -41.44,
    "LAT": -11.44,
    "CIDADE": "América Dourada",
    "UF": "BAHIA",
    "anual": 5.65,
    "JAN": 5.9,
    "FEB": 5.92,
    "MAR": 5.94,
    "APR": 5.32,
    "MAY": 4.94,
    "JUN": 4.91,
    "JUL": 5.03,
    "AUG": 5.66,
    "SEP": 6.27,
    "OCT": 6.21,
    "NOV": 5.85,
    "DEC": 5.87
  },
  {
    "LONG": -40.6,
    "LAT": -11.43,
    "CIDADE": "Miguel Calmon",
    "UF": "BAHIA",
    "anual": 5.18,
    "JAN": 5.67,
    "FEB": 5.73,
    "MAR": 5.8,
    "APR": 4.99,
    "MAY": 4.31,
    "JUN": 4.09,
    "JUL": 4.26,
    "AUG": 4.92,
    "SEP": 5.77,
    "OCT": 5.59,
    "NOV": 5.53,
    "DEC": 5.56
  },
  {
    "LONG": -4.03,
    "LAT": -11.42,
    "CIDADE": "Serrolândia",
    "UF": "BAHIA",
    "anual": 5.01,
    "JAN": 5.65,
    "FEB": 5.62,
    "MAR": 5.63,
    "APR": 4.84,
    "MAY": 4.2,
    "JUN": 3.98,
    "JUL": 4.13,
    "AUG": 4.69,
    "SEP": 5.42,
    "OCT": 5.22,
    "NOV": 5.31,
    "DEC": 5.36
  },
  {
    "LONG": -40.13,
    "LAT": -11.41,
    "CIDADE": "Quixabeira",
    "UF": "BAHIA",
    "anual": 5.11,
    "JAN": 5.67,
    "FEB": 5.75,
    "MAR": 5.78,
    "APR": 4.92,
    "MAY": 4.35,
    "JUN": 4.1,
    "JUL": 4.25,
    "AUG": 4.76,
    "SEP": 5.51,
    "OCT": 5.33,
    "NOV": 5.4,
    "DEC": 5.46
  },
  {
    "LONG": -40.01,
    "LAT": -11.38,
    "CIDADE": "Capim Grosso",
    "UF": "BAHIA",
    "anual": 5.12,
    "JAN": 5.67,
    "FEB": 5.71,
    "MAR": 5.78,
    "APR": 4.9,
    "MAY": 4.34,
    "JUN": 4.09,
    "JUL": 4.28,
    "AUG": 4.8,
    "SEP": 5.5,
    "OCT": 5.38,
    "NOV": 5.46,
    "DEC": 5.5
  },
  {
    "LONG": -39.47,
    "LAT": -11.41,
    "CIDADE": "Valente",
    "UF": "BAHIA",
    "anual": 5.17,
    "JAN": 5.69,
    "FEB": 5.7,
    "MAR": 5.84,
    "APR": 5.07,
    "MAY": 4.4,
    "JUN": 4.16,
    "JUL": 4.36,
    "AUG": 4.78,
    "SEP": 5.48,
    "OCT": 5.42,
    "NOV": 5.56,
    "DEC": 5.56
  },
  {
    "LONG": -38.33,
    "LAT": -11.36,
    "CIDADE": "Olindina",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.73,
    "FEB": 5.71,
    "MAR": 5.82,
    "APR": 5.13,
    "MAY": 4.44,
    "JUN": 4.27,
    "JUL": 4.41,
    "AUG": 4.81,
    "SEP": 5.4,
    "OCT": 5.44,
    "NOV": 5.69,
    "DEC": 5.62
  },
  {
    "LONG": -42.14,
    "LAT": -11.34,
    "CIDADE": "Uibaí",
    "UF": "BAHIA",
    "anual": 5.88,
    "JAN": 5.99,
    "FEB": 5.99,
    "MAR": 6.04,
    "APR": 5.65,
    "MAY": 5.37,
    "JUN": 5.32,
    "JUL": 5.54,
    "AUG": 6.16,
    "SEP": 6.49,
    "OCT": 6.29,
    "NOV": 5.83,
    "DEC": 5.91
  },
  {
    "LONG": -41.98,
    "LAT": -11.29,
    "CIDADE": "Presidente Dutra",
    "UF": "BAHIA",
    "anual": 5.84,
    "JAN": 5.96,
    "FEB": 6.01,
    "MAR": 6.01,
    "APR": 5.57,
    "MAY": 5.27,
    "JUN": 5.2,
    "JUL": 5.43,
    "AUG": 6.06,
    "SEP": 6.47,
    "OCT": 6.29,
    "NOV": 5.83,
    "DEC": 5.92
  },
  {
    "LONG": -41.85,
    "LAT": -11.3,
    "CIDADE": "Irecê",
    "UF": "BAHIA",
    "anual": 5.82,
    "JAN": 5.94,
    "FEB": 6.01,
    "MAR": 5.99,
    "APR": 5.53,
    "MAY": 5.23,
    "JUN": 5.16,
    "JUL": 5.38,
    "AUG": 6.02,
    "SEP": 6.4,
    "OCT": 6.3,
    "NOV": 5.88,
    "DEC": 5.95
  },
  {
    "LONG": -41.66,
    "LAT": -11.35,
    "CIDADE": "João Dourado",
    "UF": "BAHIA",
    "anual": 5.8,
    "JAN": 6,
    "FEB": 5.97,
    "MAR": 6.01,
    "APR": 5.47,
    "MAY": 5.18,
    "JUN": 5.13,
    "JUL": 5.3,
    "AUG": 5.95,
    "SEP": 6.4,
    "OCT": 6.29,
    "NOV": 5.95,
    "DEC": 5.95
  },
  {
    "LONG": -40.94,
    "LAT": -11.26,
    "CIDADE": "Várzea Nova",
    "UF": "BAHIA",
    "anual": 5.36,
    "JAN": 5.8,
    "FEB": 5.73,
    "MAR": 5.82,
    "APR": 5.09,
    "MAY": 4.63,
    "JUN": 4.44,
    "JUL": 4.65,
    "AUG": 5.15,
    "SEP": 5.93,
    "OCT": 5.84,
    "NOV": 5.65,
    "DEC": 5.65
  },
  {
    "LONG": -39.38,
    "LAT": -11.25,
    "CIDADE": "Santaluz",
    "UF": "BAHIA",
    "anual": 5.17,
    "JAN": 5.74,
    "FEB": 5.69,
    "MAR": 5.81,
    "APR": 5.03,
    "MAY": 4.35,
    "JUN": 4.13,
    "JUL": 4.33,
    "AUG": 4.72,
    "SEP": 5.47,
    "OCT": 5.44,
    "NOV": 5.69,
    "DEC": 5.61
  },
  {
    "LONG": -38.96,
    "LAT": -11.33,
    "CIDADE": "Araci",
    "UF": "BAHIA",
    "anual": 5.22,
    "JAN": 5.79,
    "FEB": 5.75,
    "MAR": 5.81,
    "APR": 5.14,
    "MAY": 4.46,
    "JUN": 4.23,
    "JUL": 4.37,
    "AUG": 4.77,
    "SEP": 5.44,
    "OCT": 5.49,
    "NOV": 5.67,
    "DEC": 5.67
  },
  {
    "LONG": -38.22,
    "LAT": -11.31,
    "CIDADE": "Itapicuru",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.78,
    "FEB": 5.77,
    "MAR": 5.83,
    "APR": 5.16,
    "MAY": 4.49,
    "JUN": 4.3,
    "JUL": 4.44,
    "AUG": 4.81,
    "SEP": 5.39,
    "OCT": 5.47,
    "NOV": 5.69,
    "DEC": 5.68
  },
  {
    "LONG": -41.88,
    "LAT": -11.23,
    "CIDADE": "São Gabriel",
    "UF": "BAHIA",
    "anual": 5.82,
    "JAN": 5.93,
    "FEB": 5.97,
    "MAR": 5.95,
    "APR": 5.52,
    "MAY": 5.25,
    "JUN": 5.19,
    "JUL": 5.42,
    "AUG": 6.04,
    "SEP": 6.49,
    "OCT": 6.27,
    "NOV": 5.88,
    "DEC": 5.92
  },
  {
    "LONG": -4.05,
    "LAT": -11.18,
    "CIDADE": "Jacobina",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.8,
    "FEB": 5.79,
    "MAR": 5.92,
    "APR": 5.09,
    "MAY": 4.39,
    "JUN": 4.19,
    "JUL": 4.36,
    "AUG": 5,
    "SEP": 5.79,
    "OCT": 5.58,
    "NOV": 5.64,
    "DEC": 5.66
  },
  {
    "LONG": -3.85,
    "LAT": -11.24,
    "CIDADE": "Nova Soure",
    "UF": "BAHIA",
    "anual": 5.24,
    "JAN": 5.74,
    "FEB": 5.81,
    "MAR": 5.88,
    "APR": 5.22,
    "MAY": 4.48,
    "JUN": 4.28,
    "JUL": 4.45,
    "AUG": 4.82,
    "SEP": 5.41,
    "OCT": 5.46,
    "NOV": 5.67,
    "DEC": 5.64
  },
  {
    "LONG": -43.15,
    "LAT": -11.09,
    "CIDADE": "Barra",
    "UF": "BAHIA",
    "anual": 6.03,
    "JAN": 5.97,
    "FEB": 6.05,
    "MAR": 6.14,
    "APR": 6.02,
    "MAY": 5.74,
    "JUN": 5.7,
    "JUL": 5.84,
    "AUG": 6.29,
    "SEP": 6.46,
    "OCT": 6.33,
    "NOV": 5.9,
    "DEC": 5.92
  },
  {
    "LONG": -4.21,
    "LAT": -1.11,
    "CIDADE": "Central",
    "UF": "BAHIA",
    "anual": 5.89,
    "JAN": 5.92,
    "FEB": 5.92,
    "MAR": 6.03,
    "APR": 5.64,
    "MAY": 5.44,
    "JUN": 5.36,
    "JUL": 5.54,
    "AUG": 6.22,
    "SEP": 6.58,
    "OCT": 6.27,
    "NOV": 5.86,
    "DEC": 5.88
  },
  {
    "LONG": -40.43,
    "LAT": -11.07,
    "CIDADE": "Caém",
    "UF": "BAHIA",
    "anual": 5.09,
    "JAN": 5.68,
    "FEB": 5.65,
    "MAR": 5.71,
    "APR": 4.91,
    "MAY": 4.25,
    "JUN": 3.97,
    "JUL": 4.2,
    "AUG": 4.83,
    "SEP": 5.56,
    "OCT": 5.44,
    "NOV": 5.45,
    "DEC": 5.45
  },
  {
    "LONG": -38.52,
    "LAT": -11.1,
    "CIDADE": "Cipó",
    "UF": "BAHIA",
    "anual": 5.22,
    "JAN": 5.75,
    "FEB": 5.77,
    "MAR": 5.92,
    "APR": 5.2,
    "MAY": 4.46,
    "JUN": 4.25,
    "JUL": 4.34,
    "AUG": 4.73,
    "SEP": 5.41,
    "OCT": 5.5,
    "NOV": 5.66,
    "DEC": 5.65
  },
  {
    "LONG": -45.19,
    "LAT": -11.04,
    "CIDADE": "Formosa do Rio Preto",
    "UF": "BAHIA",
    "anual": 5.87,
    "JAN": 5.59,
    "FEB": 5.58,
    "MAR": 5.58,
    "APR": 5.68,
    "MAY": 5.91,
    "JUN": 5.95,
    "JUL": 6.19,
    "AUG": 6.65,
    "SEP": 6.53,
    "OCT": 5.89,
    "NOV": 5.41,
    "DEC": 5.53
  },
  {
    "LONG": -44.52,
    "LAT": -11,
    "CIDADE": "Santa Rita de Cássia",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 5.82,
    "FEB": 5.73,
    "MAR": 5.68,
    "APR": 5.77,
    "MAY": 5.92,
    "JUN": 5.85,
    "JUL": 6.08,
    "AUG": 6.52,
    "SEP": 6.57,
    "OCT": 6.08,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -42.4,
    "LAT": -11.01,
    "CIDADE": "Itaguaçu da Bahia",
    "UF": "BAHIA",
    "anual": 6.06,
    "JAN": 6.09,
    "FEB": 6.12,
    "MAR": 6.24,
    "APR": 5.84,
    "MAY": 5.65,
    "JUN": 5.64,
    "JUL": 5.81,
    "AUG": 6.43,
    "SEP": 6.56,
    "OCT": 6.34,
    "NOV": 6.04,
    "DEC": 6
  },
  {
    "LONG": -41.97,
    "LAT": -11.04,
    "CIDADE": "Jussara",
    "UF": "BAHIA",
    "anual": 5.89,
    "JAN": 5.94,
    "FEB": 5.99,
    "MAR": 6.03,
    "APR": 5.59,
    "MAY": 5.35,
    "JUN": 5.32,
    "JUL": 5.51,
    "AUG": 6.19,
    "SEP": 6.58,
    "OCT": 6.27,
    "NOV": 5.99,
    "DEC": 5.95
  },
  {
    "LONG": -41.08,
    "LAT": -10.97,
    "CIDADE": "Ourolândia",
    "UF": "BAHIA",
    "anual": 5.44,
    "JAN": 5.84,
    "FEB": 5.72,
    "MAR": 5.85,
    "APR": 5.13,
    "MAY": 4.73,
    "JUN": 4.54,
    "JUL": 4.7,
    "AUG": 5.37,
    "SEP": 6.09,
    "OCT": 5.9,
    "NOV": 5.76,
    "DEC": 5.69
  },
  {
    "LONG": -40.57,
    "LAT": -10.95,
    "CIDADE": "Mirangaba",
    "UF": "BAHIA",
    "anual": 5.29,
    "JAN": 5.79,
    "FEB": 5.77,
    "MAR": 5.85,
    "APR": 5.08,
    "MAY": 4.45,
    "JUN": 4.21,
    "JUL": 4.43,
    "AUG": 5.08,
    "SEP": 5.81,
    "OCT": 5.68,
    "NOV": 5.68,
    "DEC": 5.64
  },
  {
    "LONG": -40.3,
    "LAT": -11.03,
    "CIDADE": "Caldeirão Grande",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.81,
    "FEB": 5.66,
    "MAR": 5.7,
    "APR": 4.93,
    "MAY": 4.33,
    "JUN": 4.11,
    "JUL": 4.33,
    "AUG": 4.82,
    "SEP": 5.53,
    "OCT": 5.39,
    "NOV": 5.48,
    "DEC": 5.47
  },
  {
    "LONG": -39.63,
    "LAT": -1.1,
    "CIDADE": "Queimadas",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.81,
    "FEB": 5.78,
    "MAR": 5.84,
    "APR": 5.09,
    "MAY": 4.38,
    "JUN": 4.12,
    "JUL": 4.35,
    "AUG": 4.8,
    "SEP": 5.54,
    "OCT": 5.53,
    "NOV": 5.63,
    "DEC": 5.66
  },
  {
    "LONG": -38.79,
    "LAT": -10.96,
    "CIDADE": "Tucano",
    "UF": "BAHIA",
    "anual": 5.34,
    "JAN": 5.84,
    "FEB": 5.86,
    "MAR": 6,
    "APR": 5.32,
    "MAY": 4.59,
    "JUN": 4.32,
    "JUL": 4.5,
    "AUG": 4.92,
    "SEP": 5.59,
    "OCT": 5.61,
    "NOV": 5.8,
    "DEC": 5.72
  },
  {
    "LONG": -38.42,
    "LAT": -11.04,
    "CIDADE": "Ribeira do Amparo",
    "UF": "BAHIA",
    "anual": 5.16,
    "JAN": 5.71,
    "FEB": 5.63,
    "MAR": 5.82,
    "APR": 5.13,
    "MAY": 4.46,
    "JUN": 4.18,
    "JUL": 4.28,
    "AUG": 4.7,
    "SEP": 5.3,
    "OCT": 5.38,
    "NOV": 5.65,
    "DEC": 5.62
  },
  {
    "LONG": -40.42,
    "LAT": -10.94,
    "CIDADE": "Saúde",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.8,
    "FEB": 5.73,
    "MAR": 5.76,
    "APR": 4.99,
    "MAY": 4.34,
    "JUN": 4.08,
    "JUL": 4.34,
    "AUG": 4.95,
    "SEP": 5.73,
    "OCT": 5.63,
    "NOV": 5.63,
    "DEC": 5.57
  },
  {
    "LONG": -40.13,
    "LAT": -10.86,
    "CIDADE": "Ponto Novo",
    "UF": "BAHIA",
    "anual": 5.18,
    "JAN": 5.79,
    "FEB": 5.75,
    "MAR": 5.79,
    "APR": 4.94,
    "MAY": 4.35,
    "JUN": 4.13,
    "JUL": 4.32,
    "AUG": 4.79,
    "SEP": 5.61,
    "OCT": 5.49,
    "NOV": 5.65,
    "DEC": 5.54
  },
  {
    "LONG": -42.72,
    "LAT": -10.82,
    "CIDADE": "Xique-Xique",
    "UF": "BAHIA",
    "anual": 6.08,
    "JAN": 6.01,
    "FEB": 6.05,
    "MAR": 6.26,
    "APR": 5.92,
    "MAY": 5.76,
    "JUN": 5.69,
    "JUL": 5.88,
    "AUG": 6.46,
    "SEP": 6.59,
    "OCT": 6.36,
    "NOV": 6.01,
    "DEC": 5.95
  },
  {
    "LONG": -39.43,
    "LAT": -10.83,
    "CIDADE": "Nordestina",
    "UF": "BAHIA",
    "anual": 5.26,
    "JAN": 5.76,
    "FEB": 5.78,
    "MAR": 5.86,
    "APR": 5.15,
    "MAY": 4.46,
    "JUN": 4.21,
    "JUL": 4.36,
    "AUG": 4.88,
    "SEP": 5.65,
    "OCT": 5.56,
    "NOV": 5.71,
    "DEC": 5.7
  },
  {
    "LONG": -39.21,
    "LAT": -10.75,
    "CIDADE": "Quijingue",
    "UF": "BAHIA",
    "anual": 5.26,
    "JAN": 5.74,
    "FEB": 5.77,
    "MAR": 5.91,
    "APR": 5.16,
    "MAY": 4.52,
    "JUN": 4.17,
    "JUL": 4.38,
    "AUG": 4.84,
    "SEP": 5.66,
    "OCT": 5.57,
    "NOV": 5.8,
    "DEC": 5.64
  },
  {
    "LONG": -38.54,
    "LAT": -10.84,
    "CIDADE": "Ribeira do Pombal",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.73,
    "FEB": 5.71,
    "MAR": 5.89,
    "APR": 5.26,
    "MAY": 4.54,
    "JUN": 4.21,
    "JUL": 4.36,
    "AUG": 4.75,
    "SEP": 5.43,
    "OCT": 5.46,
    "NOV": 5.74,
    "DEC": 5.67
  },
  {
    "LONG": -44.04,
    "LAT": -10.72,
    "CIDADE": "Mansidão",
    "UF": "BAHIA",
    "anual": 5.88,
    "JAN": 5.63,
    "FEB": 5.67,
    "MAR": 5.63,
    "APR": 5.71,
    "MAY": 5.82,
    "JUN": 5.82,
    "JUL": 5.99,
    "AUG": 6.53,
    "SEP": 6.59,
    "OCT": 6.06,
    "NOV": 5.5,
    "DEC": 5.61
  },
  {
    "LONG": -4.36,
    "LAT": -10.72,
    "CIDADE": "Buritirama",
    "UF": "BAHIA",
    "anual": 5.97,
    "JAN": 5.75,
    "FEB": 5.71,
    "MAR": 5.79,
    "APR": 5.8,
    "MAY": 5.78,
    "JUN": 5.82,
    "JUL": 6.1,
    "AUG": 6.63,
    "SEP": 6.6,
    "OCT": 6.25,
    "NOV": 5.71,
    "DEC": 5.69
  },
  {
    "LONG": -41.33,
    "LAT": -10.73,
    "CIDADE": "Umburanas",
    "UF": "BAHIA",
    "anual": 5.62,
    "JAN": 5.95,
    "FEB": 5.75,
    "MAR": 5.93,
    "APR": 5.23,
    "MAY": 4.92,
    "JUN": 4.75,
    "JUL": 5.02,
    "AUG": 5.71,
    "SEP": 6.29,
    "OCT": 6.11,
    "NOV": 5.96,
    "DEC": 5.8
  },
  {
    "LONG": -40.37,
    "LAT": -10.74,
    "CIDADE": "Pindobaçu",
    "UF": "BAHIA",
    "anual": 5.1,
    "JAN": 5.65,
    "FEB": 5.63,
    "MAR": 5.7,
    "APR": 4.87,
    "MAY": 4.19,
    "JUN": 3.92,
    "JUL": 4.17,
    "AUG": 4.75,
    "SEP": 5.65,
    "OCT": 5.52,
    "NOV": 5.56,
    "DEC": 5.54
  },
  {
    "LONG": -40.13,
    "LAT": -10.74,
    "CIDADE": "Filadélfia",
    "UF": "BAHIA",
    "anual": 5.2,
    "JAN": 5.7,
    "FEB": 5.71,
    "MAR": 5.87,
    "APR": 5.02,
    "MAY": 4.39,
    "JUN": 4.13,
    "JUL": 4.34,
    "AUG": 4.82,
    "SEP": 5.65,
    "OCT": 5.52,
    "NOV": 5.65,
    "DEC": 5.57
  },
  {
    "LONG": -39.85,
    "LAT": -10.69,
    "CIDADE": "Itiúba",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.78,
    "FEB": 5.73,
    "MAR": 5.82,
    "APR": 5.03,
    "MAY": 4.47,
    "JUN": 4.13,
    "JUL": 4.37,
    "AUG": 4.9,
    "SEP": 5.69,
    "OCT": 5.59,
    "NOV": 5.71,
    "DEC": 5.6
  },
  {
    "LONG": -39.49,
    "LAT": -10.67,
    "CIDADE": "Cansanção",
    "UF": "BAHIA",
    "anual": 5.26,
    "JAN": 5.69,
    "FEB": 5.67,
    "MAR": 5.89,
    "APR": 5.16,
    "MAY": 4.53,
    "JUN": 4.2,
    "JUL": 4.42,
    "AUG": 4.86,
    "SEP": 5.71,
    "OCT": 5.58,
    "NOV": 5.8,
    "DEC": 5.68
  },
  {
    "LONG": -3.83,
    "LAT": -10.68,
    "CIDADE": "Heliópolis",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.75,
    "FEB": 5.7,
    "MAR": 5.85,
    "APR": 5.26,
    "MAY": 4.55,
    "JUN": 4.24,
    "JUL": 4.36,
    "AUG": 4.75,
    "SEP": 5.39,
    "OCT": 5.46,
    "NOV": 5.81,
    "DEC": 5.67
  },
  {
    "LONG": -37.86,
    "LAT": -10.69,
    "CIDADE": "Paripiranga",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.73,
    "FEB": 5.63,
    "MAR": 5.78,
    "APR": 5.31,
    "MAY": 4.59,
    "JUN": 4.23,
    "JUL": 4.38,
    "AUG": 4.81,
    "SEP": 5.36,
    "OCT": 5.45,
    "NOV": 5.81,
    "DEC": 5.69
  },
  {
    "LONG": -40.28,
    "LAT": -10.57,
    "CIDADE": "Antônio Gonçalves",
    "UF": "BAHIA",
    "anual": 5.13,
    "JAN": 5.61,
    "FEB": 5.64,
    "MAR": 5.78,
    "APR": 4.93,
    "MAY": 4.29,
    "JUN": 4.06,
    "JUL": 4.28,
    "AUG": 4.79,
    "SEP": 5.66,
    "OCT": 5.5,
    "NOV": 5.55,
    "DEC": 5.51
  },
  {
    "LONG": -38.61,
    "LAT": -1.06,
    "CIDADE": "Banzaê",
    "UF": "BAHIA",
    "anual": 5.29,
    "JAN": 5.79,
    "FEB": 5.75,
    "MAR": 5.89,
    "APR": 5.28,
    "MAY": 4.56,
    "JUN": 4.22,
    "JUL": 4.38,
    "AUG": 4.86,
    "SEP": 5.54,
    "OCT": 5.58,
    "NOV": 5.87,
    "DEC": 5.75
  },
  {
    "LONG": -38.38,
    "LAT": -10.6,
    "CIDADE": "Cícero Dantas",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.73,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.32,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.3,
    "AUG": 4.72,
    "SEP": 5.45,
    "OCT": 5.49,
    "NOV": 5.84,
    "DEC": 5.73
  },
  {
    "LONG": -38.21,
    "LAT": -1.06,
    "CIDADE": "Fátima",
    "UF": "BAHIA",
    "anual": 5.21,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.83,
    "APR": 5.26,
    "MAY": 4.52,
    "JUN": 4.2,
    "JUL": 4.29,
    "AUG": 4.71,
    "SEP": 5.41,
    "OCT": 5.42,
    "NOV": 5.81,
    "DEC": 5.72
  },
  {
    "LONG": -40.32,
    "LAT": -1.05,
    "CIDADE": "Campo Formoso",
    "UF": "BAHIA",
    "anual": 5.33,
    "JAN": 5.8,
    "FEB": 5.76,
    "MAR": 5.88,
    "APR": 5.11,
    "MAY": 4.47,
    "JUN": 4.18,
    "JUL": 4.46,
    "AUG": 5.11,
    "SEP": 5.88,
    "OCT": 5.78,
    "NOV": 5.8,
    "DEC": 5.75
  },
  {
    "LONG": -40.19,
    "LAT": -10.46,
    "CIDADE": "Senhor do Bonfim",
    "UF": "BAHIA",
    "anual": 5.15,
    "JAN": 5.65,
    "FEB": 5.64,
    "MAR": 5.73,
    "APR": 4.96,
    "MAY": 4.35,
    "JUN": 4.04,
    "JUL": 4.28,
    "AUG": 4.79,
    "SEP": 5.65,
    "OCT": 5.51,
    "NOV": 5.63,
    "DEC": 5.57
  },
  {
    "LONG": -39.02,
    "LAT": -10.51,
    "CIDADE": "Euclides da Cunha",
    "UF": "BAHIA",
    "anual": 5.37,
    "JAN": 5.9,
    "FEB": 5.83,
    "MAR": 5.94,
    "APR": 5.32,
    "MAY": 4.6,
    "JUN": 4.21,
    "JUL": 4.44,
    "AUG": 4.92,
    "SEP": 5.8,
    "OCT": 5.7,
    "NOV": 5.98,
    "DEC": 5.76
  },
  {
    "LONG": -38.12,
    "LAT": -10.54,
    "CIDADE": "Adustina",
    "UF": "BAHIA",
    "anual": 5.22,
    "JAN": 5.75,
    "FEB": 5.65,
    "MAR": 5.76,
    "APR": 5.28,
    "MAY": 4.5,
    "JUN": 4.24,
    "JUL": 4.31,
    "AUG": 4.73,
    "SEP": 5.41,
    "OCT": 5.49,
    "NOV": 5.79,
    "DEC": 5.74
  },
  {
    "LONG": -39.33,
    "LAT": -10.44,
    "CIDADE": "Monte Santo",
    "UF": "BAHIA",
    "anual": 5.3,
    "JAN": 5.75,
    "FEB": 5.77,
    "MAR": 5.87,
    "APR": 5.2,
    "MAY": 4.55,
    "JUN": 4.2,
    "JUL": 4.4,
    "AUG": 4.87,
    "SEP": 5.78,
    "OCT": 5.62,
    "NOV": 5.88,
    "DEC": 5.73
  },
  {
    "LONG": -38.33,
    "LAT": -10.41,
    "CIDADE": "Antas",
    "UF": "BAHIA",
    "anual": 5.24,
    "JAN": 5.76,
    "FEB": 5.67,
    "MAR": 5.81,
    "APR": 5.3,
    "MAY": 4.54,
    "JUN": 4.22,
    "JUL": 4.35,
    "AUG": 4.79,
    "SEP": 5.44,
    "OCT": 5.46,
    "NOV": 5.8,
    "DEC": 5.71
  },
  {
    "LONG": -38.2,
    "LAT": -10.37,
    "CIDADE": "Sítio do Quinto",
    "UF": "BAHIA",
    "anual": 5.25,
    "JAN": 5.76,
    "FEB": 5.66,
    "MAR": 5.8,
    "APR": 5.3,
    "MAY": 4.58,
    "JUN": 4.24,
    "JUL": 4.39,
    "AUG": 4.79,
    "SEP": 5.45,
    "OCT": 5.48,
    "NOV": 5.84,
    "DEC": 5.73
  },
  {
    "LONG": -40.2,
    "LAT": -10.26,
    "CIDADE": "Jaguarari",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.72,
    "FEB": 5.68,
    "MAR": 5.83,
    "APR": 5.04,
    "MAY": 4.5,
    "JUN": 4.23,
    "JUL": 4.4,
    "AUG": 5.01,
    "SEP": 5.81,
    "OCT": 5.68,
    "NOV": 5.76,
    "DEC": 5.61
  },
  {
    "LONG": -39.83,
    "LAT": -10.34,
    "CIDADE": "Andorinha",
    "UF": "BAHIA",
    "anual": 5.32,
    "JAN": 5.74,
    "FEB": 5.79,
    "MAR": 5.84,
    "APR": 5.13,
    "MAY": 4.56,
    "JUN": 4.28,
    "JUL": 4.42,
    "AUG": 4.95,
    "SEP": 5.83,
    "OCT": 5.71,
    "NOV": 5.87,
    "DEC": 5.71
  },
  {
    "LONG": -3.84,
    "LAT": -10.33,
    "CIDADE": "Novo Triunfo",
    "UF": "BAHIA",
    "anual": 5.23,
    "JAN": 5.72,
    "FEB": 5.71,
    "MAR": 5.83,
    "APR": 5.23,
    "MAY": 4.52,
    "JUN": 4.16,
    "JUL": 4.26,
    "AUG": 4.77,
    "SEP": 5.47,
    "OCT": 5.5,
    "NOV": 5.82,
    "DEC": 5.8
  },
  {
    "LONG": -37.93,
    "LAT": -10.28,
    "CIDADE": "Coronel João Sá",
    "UF": "BAHIA",
    "anual": 5.27,
    "JAN": 5.66,
    "FEB": 5.71,
    "MAR": 5.78,
    "APR": 5.3,
    "MAY": 4.57,
    "JUN": 4.27,
    "JUL": 4.5,
    "AUG": 4.93,
    "SEP": 5.5,
    "OCT": 5.5,
    "NOV": 5.77,
    "DEC": 5.73
  },
  {
    "LONG": -3.83,
    "LAT": -10.08,
    "CIDADE": "Jeremoabo",
    "UF": "BAHIA",
    "anual": 5.29,
    "JAN": 5.75,
    "FEB": 5.73,
    "MAR": 5.86,
    "APR": 5.35,
    "MAY": 4.57,
    "JUN": 4.22,
    "JUL": 4.35,
    "AUG": 4.86,
    "SEP": 5.54,
    "OCT": 5.57,
    "NOV": 5.88,
    "DEC": 5.8
  },
  {
    "LONG": -4.25,
    "LAT": -1,
    "CIDADE": "Pilão Arcado",
    "UF": "BAHIA",
    "anual": 6.06,
    "JAN": 6.09,
    "FEB": 6.01,
    "MAR": 6.05,
    "APR": 5.86,
    "MAY": 5.85,
    "JUN": 5.7,
    "JUL": 5.96,
    "AUG": 6.37,
    "SEP": 6.45,
    "OCT": 6.38,
    "NOV": 6.11,
    "DEC": 5.93
  },
  {
    "LONG": -37.89,
    "LAT": -10.01,
    "CIDADE": "Pedro Alexandre",
    "UF": "BAHIA",
    "anual": 5.25,
    "JAN": 5.66,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.26,
    "MAY": 4.56,
    "JUN": 4.28,
    "JUL": 4.38,
    "AUG": 4.9,
    "SEP": 5.48,
    "OCT": 5.5,
    "NOV": 5.85,
    "DEC": 5.74
  },
  {
    "LONG": -39.03,
    "LAT": -9.9,
    "CIDADE": "Canudos",
    "UF": "BAHIA",
    "anual": 5.41,
    "JAN": 5.93,
    "FEB": 5.76,
    "MAR": 5.89,
    "APR": 5.39,
    "MAY": 4.67,
    "JUN": 4.31,
    "JUL": 4.48,
    "AUG": 5,
    "SEP": 5.85,
    "OCT": 5.82,
    "NOV": 5.99,
    "DEC": 5.9
  },
  {
    "LONG": -39.48,
    "LAT": -9.84,
    "CIDADE": "Uauá",
    "UF": "BAHIA",
    "anual": 5.49,
    "JAN": 5.88,
    "FEB": 5.88,
    "MAR": 5.91,
    "APR": 5.35,
    "MAY": 4.8,
    "JUN": 4.5,
    "JUL": 4.66,
    "AUG": 5.22,
    "SEP": 6.03,
    "OCT": 5.9,
    "NOV": 5.96,
    "DEC": 5.86
  },
  {
    "LONG": -41.88,
    "LAT": -9.74,
    "CIDADE": "Sento Sé",
    "UF": "BAHIA",
    "anual": 6.06,
    "JAN": 6.05,
    "FEB": 6.07,
    "MAR": 6.29,
    "APR": 5.9,
    "MAY": 5.85,
    "JUN": 5.86,
    "JUL": 6.02,
    "AUG": 6.27,
    "SEP": 6.05,
    "OCT": 6.16,
    "NOV": 6.22,
    "DEC": 5.94
  },
  {
    "LONG": -38.13,
    "LAT": -0.97,
    "CIDADE": "Santa Brígida",
    "UF": "BAHIA",
    "anual": 5.36,
    "JAN": 5.81,
    "FEB": 5.83,
    "MAR": 5.87,
    "APR": 5.41,
    "MAY": 4.67,
    "JUN": 4.25,
    "JUL": 4.44,
    "AUG": 4.89,
    "SEP": 5.57,
    "OCT": 5.7,
    "NOV": 6.01,
    "DEC": 5.87
  },
  {
    "LONG": -42.09,
    "LAT": -9.62,
    "CIDADE": "Remanso",
    "UF": "BAHIA",
    "anual": 5.99,
    "JAN": 6.07,
    "FEB": 5.98,
    "MAR": 6.12,
    "APR": 5.88,
    "MAY": 5.79,
    "JUN": 5.7,
    "JUL": 5.84,
    "AUG": 5.96,
    "SEP": 6.02,
    "OCT": 6.35,
    "NOV": 6.2,
    "DEC": 5.92
  },
  {
    "LONG": -43.01,
    "LAT": -0.95,
    "CIDADE": "Campo Alegre de Lourdes",
    "UF": "BAHIA",
    "anual": 5.94,
    "JAN": 5.65,
    "FEB": 5.56,
    "MAR": 5.66,
    "APR": 5.63,
    "MAY": 5.7,
    "JUN": 5.75,
    "JUL": 6.12,
    "AUG": 6.79,
    "SEP": 6.71,
    "OCT": 6.29,
    "NOV": 5.8,
    "DEC": 5.64
  },
  {
    "LONG": -40.79,
    "LAT": -9.47,
    "CIDADE": "Sobradinho",
    "UF": "BAHIA",
    "anual": 5.83,
    "JAN": 6.03,
    "FEB": 5.89,
    "MAR": 6.02,
    "APR": 5.45,
    "MAY": 5.3,
    "JUN": 5.13,
    "JUL": 5.38,
    "AUG": 5.99,
    "SEP": 6.42,
    "OCT": 6.27,
    "NOV": 6.23,
    "DEC": 5.9
  },
  {
    "LONG": -40.5,
    "LAT": -9.42,
    "CIDADE": "Juazeiro",
    "UF": "BAHIA",
    "anual": 5.78,
    "JAN": 6.01,
    "FEB": 5.97,
    "MAR": 6.04,
    "APR": 5.45,
    "MAY": 5.16,
    "JUN": 4.97,
    "JUL": 5.2,
    "AUG": 5.85,
    "SEP": 6.41,
    "OCT": 6.25,
    "NOV": 6.16,
    "DEC": 5.95
  },
  {
    "LONG": -38.22,
    "LAT": -9.4,
    "CIDADE": "Paulo Afonso",
    "UF": "BAHIA",
    "anual": 5.54,
    "JAN": 5.97,
    "FEB": 6.04,
    "MAR": 6.07,
    "APR": 5.6,
    "MAY": 4.85,
    "JUN": 4.39,
    "JUL": 4.51,
    "AUG": 5.07,
    "SEP": 5.87,
    "OCT": 5.95,
    "NOV": 6.09,
    "DEC": 6.04
  },
  {
    "LONG": -38.26,
    "LAT": -9.34,
    "CIDADE": "Glória",
    "UF": "BAHIA",
    "anual": 5.6,
    "JAN": 5.94,
    "FEB": 6.11,
    "MAR": 6.12,
    "APR": 5.69,
    "MAY": 4.95,
    "JUN": 4.49,
    "JUL": 4.68,
    "AUG": 5.23,
    "SEP": 5.89,
    "OCT": 5.91,
    "NOV": 6.16,
    "DEC": 6.02
  },
  {
    "LONG": -40.97,
    "LAT": -9.17,
    "CIDADE": "Casa Nova",
    "UF": "BAHIA",
    "anual": 5.8,
    "JAN": 5.96,
    "FEB": 5.79,
    "MAR": 5.9,
    "APR": 5.4,
    "MAY": 5.24,
    "JUN": 5.13,
    "JUL": 5.39,
    "AUG": 6.04,
    "SEP": 6.45,
    "OCT": 6.26,
    "NOV": 6.14,
    "DEC": 5.84
  },
  {
    "LONG": -39.05,
    "LAT": -9.16,
    "CIDADE": "Macururé",
    "UF": "BAHIA",
    "anual": 5.57,
    "JAN": 5.87,
    "FEB": 5.81,
    "MAR": 5.91,
    "APR": 5.5,
    "MAY": 4.88,
    "JUN": 4.55,
    "JUL": 4.72,
    "AUG": 5.38,
    "SEP": 6.11,
    "OCT": 6.05,
    "NOV": 6.13,
    "DEC": 5.92
  },
  {
    "LONG": -39.91,
    "LAT": -0.9,
    "CIDADE": "Curaçá",
    "UF": "BAHIA",
    "anual": 5.68,
    "JAN": 5.92,
    "FEB": 5.78,
    "MAR": 5.96,
    "APR": 5.5,
    "MAY": 5.02,
    "JUN": 4.76,
    "JUL": 4.98,
    "AUG": 5.71,
    "SEP": 6.33,
    "OCT": 6.17,
    "NOV": 6.11,
    "DEC": 5.85
  },
  {
    "LONG": -39.1,
    "LAT": -8.97,
    "CIDADE": "Chorrochó",
    "UF": "BAHIA",
    "anual": 5.62,
    "JAN": 5.87,
    "FEB": 5.78,
    "MAR": 5.98,
    "APR": 5.57,
    "MAY": 4.92,
    "JUN": 4.56,
    "JUL": 4.79,
    "AUG": 5.45,
    "SEP": 6.14,
    "OCT": 6.13,
    "NOV": 6.23,
    "DEC": 5.99
  },
  {
    "LONG": -38.78,
    "LAT": -8.85,
    "CIDADE": "Rodelas",
    "UF": "BAHIA",
    "anual": 5.8,
    "JAN": 6.12,
    "FEB": 6.1,
    "MAR": 6.3,
    "APR": 5.83,
    "MAY": 5.14,
    "JUN": 4.71,
    "JUL": 4.93,
    "AUG": 5.55,
    "SEP": 6.21,
    "OCT": 6.22,
    "NOV": 6.25,
    "DEC": 6.21
  },
  {
    "LONG": -39.11,
    "LAT": -8.72,
    "CIDADE": "Abaré",
    "UF": "BAHIA",
    "anual": 5.82,
    "JAN": 6.1,
    "FEB": 6.01,
    "MAR": 6.27,
    "APR": 5.82,
    "MAY": 5.17,
    "JUN": 4.81,
    "JUL": 4.97,
    "AUG": 5.64,
    "SEP": 6.25,
    "OCT": 6.31,
    "NOV": 6.35,
    "DEC": 6.19
  },
  {
    "LONG": -39.07,
    "LAT": -7.83,
    "CIDADE": "Penaforte",
    "UF": "CEARÁ",
    "anual": 5.75,
    "JAN": 5.7,
    "FEB": 5.84,
    "MAR": 5.98,
    "APR": 5.64,
    "MAY": 5.08,
    "JUN": 4.8,
    "JUL": 5.07,
    "AUG": 5.76,
    "SEP": 6.51,
    "OCT": 6.3,
    "NOV": 6.32,
    "DEC": 5.96
  },
  {
    "LONG": -39.01,
    "LAT": -7.69,
    "CIDADE": "Jati",
    "UF": "CEARÁ",
    "anual": 5.72,
    "JAN": 5.66,
    "FEB": 5.8,
    "MAR": 5.88,
    "APR": 5.62,
    "MAY": 5.08,
    "JUN": 4.78,
    "JUL": 5.04,
    "AUG": 5.77,
    "SEP": 6.48,
    "OCT": 6.27,
    "NOV": 6.27,
    "DEC": 5.94
  },
  {
    "LONG": -39.28,
    "LAT": -7.59,
    "CIDADE": "Jardim",
    "UF": "CEARÁ",
    "anual": 5.71,
    "JAN": 5.68,
    "FEB": 5.64,
    "MAR": 5.79,
    "APR": 5.52,
    "MAY": 4.98,
    "JUN": 4.73,
    "JUL": 5,
    "AUG": 5.85,
    "SEP": 6.56,
    "OCT": 6.38,
    "NOV": 6.39,
    "DEC": 6.01
  },
  {
    "LONG": -39.12,
    "LAT": -7.53,
    "CIDADE": "Porteiras",
    "UF": "CEARÁ",
    "anual": 5.77,
    "JAN": 5.67,
    "FEB": 5.66,
    "MAR": 5.78,
    "APR": 5.54,
    "MAY": 5.15,
    "JUN": 4.92,
    "JUL": 5.22,
    "AUG": 6.01,
    "SEP": 6.6,
    "OCT": 6.36,
    "NOV": 6.35,
    "DEC": 5.99
  },
  {
    "LONG": -38.99,
    "LAT": -0.75,
    "CIDADE": "Brejo Santo",
    "UF": "CEARÁ",
    "anual": 5.88,
    "JAN": 5.8,
    "FEB": 5.89,
    "MAR": 6.02,
    "APR": 5.85,
    "MAY": 5.32,
    "JUN": 5.04,
    "JUL": 5.31,
    "AUG": 5.99,
    "SEP": 6.57,
    "OCT": 6.38,
    "NOV": 6.37,
    "DEC": 5.99
  },
  {
    "LONG": -39.05,
    "LAT": -7.36,
    "CIDADE": "Abaiara",
    "UF": "CEARÁ",
    "anual": 5.92,
    "JAN": 5.74,
    "FEB": 5.79,
    "MAR": 5.89,
    "APR": 5.81,
    "MAY": 5.45,
    "JUN": 5.25,
    "JUL": 5.55,
    "AUG": 6.21,
    "SEP": 6.62,
    "OCT": 6.43,
    "NOV": 6.34,
    "DEC": 5.94
  },
  {
    "LONG": -38.77,
    "LAT": -7.39,
    "CIDADE": "Mauriti",
    "UF": "CEARÁ",
    "anual": 5.9,
    "JAN": 5.76,
    "FEB": 5.81,
    "MAR": 6,
    "APR": 5.87,
    "MAY": 5.36,
    "JUN": 5.12,
    "JUL": 5.4,
    "AUG": 6.1,
    "SEP": 6.62,
    "OCT": 6.43,
    "NOV": 6.38,
    "DEC": 5.93
  },
  {
    "LONG": -4.05,
    "LAT": -7.28,
    "CIDADE": "Salitre",
    "UF": "CEARÁ",
    "anual": 5.74,
    "JAN": 5.21,
    "FEB": 5.27,
    "MAR": 5.39,
    "APR": 5.43,
    "MAY": 5.42,
    "JUN": 5.48,
    "JUL": 5.79,
    "AUG": 6.4,
    "SEP": 6.56,
    "OCT": 6.29,
    "NOV": 6.06,
    "DEC": 5.59
  },
  {
    "LONG": -39.31,
    "LAT": -7.3,
    "CIDADE": "Barbalha",
    "UF": "CEARÁ",
    "anual": 5.85,
    "JAN": 5.63,
    "FEB": 5.68,
    "MAR": 5.8,
    "APR": 5.6,
    "MAY": 5.35,
    "JUN": 5.25,
    "JUL": 5.5,
    "AUG": 6.21,
    "SEP": 6.58,
    "OCT": 6.38,
    "NOV": 6.29,
    "DEC": 5.9
  },
  {
    "LONG": -38.94,
    "LAT": -7.3,
    "CIDADE": "Milagres",
    "UF": "CEARÁ",
    "anual": 5.91,
    "JAN": 5.74,
    "FEB": 5.79,
    "MAR": 5.93,
    "APR": 5.83,
    "MAY": 5.45,
    "JUN": 5.23,
    "JUL": 5.54,
    "AUG": 6.15,
    "SEP": 6.61,
    "OCT": 6.41,
    "NOV": 6.33,
    "DEC": 5.87
  },
  {
    "LONG": -40.14,
    "LAT": -7.21,
    "CIDADE": "Araripe",
    "UF": "CEARÁ",
    "anual": 5.77,
    "JAN": 5.23,
    "FEB": 5.32,
    "MAR": 5.49,
    "APR": 5.38,
    "MAY": 5.34,
    "JUN": 5.46,
    "JUL": 5.8,
    "AUG": 6.46,
    "SEP": 6.61,
    "OCT": 6.32,
    "NOV": 6.12,
    "DEC": 5.65
  },
  {
    "LONG": -39.74,
    "LAT": -7.18,
    "CIDADE": "Santana do Cariri",
    "UF": "CEARÁ",
    "anual": 5.73,
    "JAN": 5.35,
    "FEB": 5.35,
    "MAR": 5.54,
    "APR": 5.4,
    "MAY": 5.28,
    "JUN": 5.24,
    "JUL": 5.64,
    "AUG": 6.33,
    "SEP": 6.53,
    "OCT": 6.25,
    "NOV": 6.14,
    "DEC": 5.73
  },
  {
    "LONG": -39.41,
    "LAT": -0.72,
    "CIDADE": "Crato",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.56,
    "FEB": 5.61,
    "MAR": 5.68,
    "APR": 5.61,
    "MAY": 5.41,
    "JUN": 5.3,
    "JUL": 5.65,
    "AUG": 6.37,
    "SEP": 6.64,
    "OCT": 6.43,
    "NOV": 6.28,
    "DEC": 5.87
  },
  {
    "LONG": -39.32,
    "LAT": -7.22,
    "CIDADE": "Juazeiro do Norte",
    "UF": "CEARÁ",
    "anual": 5.89,
    "JAN": 5.65,
    "FEB": 5.73,
    "MAR": 5.85,
    "APR": 5.71,
    "MAY": 5.43,
    "JUN": 5.26,
    "JUL": 5.6,
    "AUG": 6.23,
    "SEP": 6.57,
    "OCT": 6.44,
    "NOV": 6.33,
    "DEC": 5.91
  },
  {
    "LONG": -39.14,
    "LAT": -7.25,
    "CIDADE": "Missão Velha",
    "UF": "CEARÁ",
    "anual": 5.88,
    "JAN": 5.74,
    "FEB": 5.74,
    "MAR": 5.93,
    "APR": 5.79,
    "MAY": 5.4,
    "JUN": 5.16,
    "JUL": 5.48,
    "AUG": 6.16,
    "SEP": 6.58,
    "OCT": 6.4,
    "NOV": 6.33,
    "DEC": 5.87
  },
  {
    "LONG": -38.78,
    "LAT": -0.72,
    "CIDADE": "Barro",
    "UF": "CEARÁ",
    "anual": 5.89,
    "JAN": 5.65,
    "FEB": 5.76,
    "MAR": 5.84,
    "APR": 5.71,
    "MAY": 5.39,
    "JUN": 5.21,
    "JUL": 5.54,
    "AUG": 6.17,
    "SEP": 6.62,
    "OCT": 6.44,
    "NOV": 6.41,
    "DEC": 5.91
  },
  {
    "LONG": -40.38,
    "LAT": -7.07,
    "CIDADE": "Campos Sales",
    "UF": "CEARÁ",
    "anual": 5.81,
    "JAN": 5.32,
    "FEB": 5.43,
    "MAR": 5.61,
    "APR": 5.5,
    "MAY": 5.38,
    "JUN": 5.34,
    "JUL": 5.8,
    "AUG": 6.41,
    "SEP": 6.64,
    "OCT": 6.35,
    "NOV": 6.23,
    "DEC": 5.72
  },
  {
    "LONG": -40.03,
    "LAT": -0.71,
    "CIDADE": "Potengi",
    "UF": "CEARÁ",
    "anual": 5.8,
    "JAN": 5.31,
    "FEB": 5.39,
    "MAR": 5.61,
    "APR": 5.47,
    "MAY": 5.37,
    "JUN": 5.37,
    "JUL": 5.74,
    "AUG": 6.43,
    "SEP": 6.63,
    "OCT": 6.36,
    "NOV": 6.17,
    "DEC": 5.72
  },
  {
    "LONG": -39.68,
    "LAT": -7.09,
    "CIDADE": "Nova Olinda",
    "UF": "CEARÁ",
    "anual": 5.88,
    "JAN": 5.58,
    "FEB": 5.64,
    "MAR": 5.78,
    "APR": 5.63,
    "MAY": 5.45,
    "JUN": 5.36,
    "JUL": 5.65,
    "AUG": 6.3,
    "SEP": 6.61,
    "OCT": 6.42,
    "NOV": 6.27,
    "DEC": 5.88
  },
  {
    "LONG": -39.74,
    "LAT": -7,
    "CIDADE": "Altaneira",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.52,
    "FEB": 5.57,
    "MAR": 5.76,
    "APR": 5.56,
    "MAY": 5.48,
    "JUN": 5.41,
    "JUL": 5.72,
    "AUG": 6.38,
    "SEP": 6.68,
    "OCT": 6.43,
    "NOV": 6.22,
    "DEC": 5.78
  },
  {
    "LONG": -39.28,
    "LAT": -7.03,
    "CIDADE": "Caririaçu",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.55,
    "FEB": 5.67,
    "MAR": 5.78,
    "APR": 5.63,
    "MAY": 5.42,
    "JUN": 5.34,
    "JUL": 5.62,
    "AUG": 6.29,
    "SEP": 6.57,
    "OCT": 6.4,
    "NOV": 6.32,
    "DEC": 5.8
  },
  {
    "LONG": -39.87,
    "LAT": -6.87,
    "CIDADE": "Assaré",
    "UF": "CEARÁ",
    "anual": 5.83,
    "JAN": 5.41,
    "FEB": 5.52,
    "MAR": 5.72,
    "APR": 5.48,
    "MAY": 5.49,
    "JUN": 5.41,
    "JUL": 5.7,
    "AUG": 6.38,
    "SEP": 6.6,
    "OCT": 6.42,
    "NOV": 6.17,
    "DEC": 5.71
  },
  {
    "LONG": -39.57,
    "LAT": -6.92,
    "CIDADE": "Farias Brito",
    "UF": "CEARÁ",
    "anual": 5.85,
    "JAN": 5.48,
    "FEB": 5.61,
    "MAR": 5.8,
    "APR": 5.61,
    "MAY": 5.45,
    "JUN": 5.39,
    "JUL": 5.68,
    "AUG": 6.35,
    "SEP": 6.57,
    "OCT": 6.35,
    "NOV": 6.2,
    "DEC": 5.73
  },
  {
    "LONG": -39.22,
    "LAT": -6.89,
    "CIDADE": "Granjeiro",
    "UF": "CEARÁ",
    "anual": 5.9,
    "JAN": 5.61,
    "FEB": 5.72,
    "MAR": 5.82,
    "APR": 5.65,
    "MAY": 5.44,
    "JUN": 5.44,
    "JUL": 5.69,
    "AUG": 6.33,
    "SEP": 6.58,
    "OCT": 6.43,
    "NOV": 6.3,
    "DEC": 5.84
  },
  {
    "LONG": -38.97,
    "LAT": -6.93,
    "CIDADE": "Aurora",
    "UF": "CEARÁ",
    "anual": 5.91,
    "JAN": 5.67,
    "FEB": 5.79,
    "MAR": 5.86,
    "APR": 5.76,
    "MAY": 5.46,
    "JUN": 5.29,
    "JUL": 5.6,
    "AUG": 6.26,
    "SEP": 6.55,
    "OCT": 6.42,
    "NOV": 6.36,
    "DEC": 5.9
  },
  {
    "LONG": -39.99,
    "LAT": -6.77,
    "CIDADE": "Antonina do Norte",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.3,
    "FEB": 5.45,
    "MAR": 5.7,
    "APR": 5.49,
    "MAY": 5.41,
    "JUN": 5.29,
    "JUL": 5.64,
    "AUG": 6.38,
    "SEP": 6.59,
    "OCT": 6.36,
    "NOV": 6.11,
    "DEC": 5.63
  },
  {
    "LONG": -39.3,
    "LAT": -6.79,
    "CIDADE": "Várzea Alegre",
    "UF": "CEARÁ",
    "anual": 5.91,
    "JAN": 5.63,
    "FEB": 5.75,
    "MAR": 5.82,
    "APR": 5.71,
    "MAY": 5.49,
    "JUN": 5.45,
    "JUL": 5.71,
    "AUG": 6.34,
    "SEP": 6.52,
    "OCT": 6.41,
    "NOV": 6.27,
    "DEC": 5.83
  },
  {
    "LONG": -38.72,
    "LAT": -6.78,
    "CIDADE": "Ipaumirim",
    "UF": "CEARÁ",
    "anual": 5.98,
    "JAN": 5.77,
    "FEB": 5.9,
    "MAR": 5.99,
    "APR": 5.87,
    "MAY": 5.51,
    "JUN": 5.34,
    "JUL": 5.63,
    "AUG": 6.28,
    "SEP": 6.55,
    "OCT": 6.44,
    "NOV": 6.43,
    "DEC": 6
  },
  {
    "LONG": -39.76,
    "LAT": -6.68,
    "CIDADE": "Tarrafas",
    "UF": "CEARÁ",
    "anual": 5.81,
    "JAN": 5.39,
    "FEB": 5.55,
    "MAR": 5.67,
    "APR": 5.51,
    "MAY": 5.43,
    "JUN": 5.39,
    "JUL": 5.7,
    "AUG": 6.38,
    "SEP": 6.59,
    "OCT": 6.33,
    "NOV": 6.11,
    "DEC": 5.62
  },
  {
    "LONG": -38.97,
    "LAT": -6.75,
    "CIDADE": "Lavras da Mangabeira",
    "UF": "CEARÁ",
    "anual": 5.95,
    "JAN": 5.73,
    "FEB": 5.83,
    "MAR": 5.89,
    "APR": 5.75,
    "MAY": 5.45,
    "JUN": 5.38,
    "JUL": 5.62,
    "AUG": 6.31,
    "SEP": 6.57,
    "OCT": 6.49,
    "NOV": 6.4,
    "DEC": 5.93
  },
  {
    "LONG": -3.87,
    "LAT": -6.72,
    "CIDADE": "Baixio",
    "UF": "CEARÁ",
    "anual": 5.99,
    "JAN": 5.8,
    "FEB": 5.9,
    "MAR": 6.02,
    "APR": 5.86,
    "MAY": 5.5,
    "JUN": 5.36,
    "JUL": 5.66,
    "AUG": 6.29,
    "SEP": 6.51,
    "OCT": 6.51,
    "NOV": 6.45,
    "DEC": 5.96
  },
  {
    "LONG": -40.12,
    "LAT": -6.57,
    "CIDADE": "Aiuaba",
    "UF": "CEARÁ",
    "anual": 5.74,
    "JAN": 5.28,
    "FEB": 5.31,
    "MAR": 5.51,
    "APR": 5.3,
    "MAY": 5.31,
    "JUN": 5.42,
    "JUL": 5.75,
    "AUG": 6.47,
    "SEP": 6.61,
    "OCT": 6.33,
    "NOV": 6.07,
    "DEC": 5.51
  },
  {
    "LONG": -39.06,
    "LAT": -6.6,
    "CIDADE": "Cedro",
    "UF": "CEARÁ",
    "anual": 5.95,
    "JAN": 5.66,
    "FEB": 5.84,
    "MAR": 5.88,
    "APR": 5.74,
    "MAY": 5.51,
    "JUN": 5.44,
    "JUL": 5.67,
    "AUG": 6.38,
    "SEP": 6.54,
    "OCT": 6.44,
    "NOV": 6.42,
    "DEC": 5.88
  },
  {
    "LONG": -38.7,
    "LAT": -6.64,
    "CIDADE": "Umari",
    "UF": "CEARÁ",
    "anual": 5.95,
    "JAN": 5.66,
    "FEB": 5.78,
    "MAR": 5.88,
    "APR": 5.71,
    "MAY": 5.46,
    "JUN": 5.39,
    "JUL": 5.67,
    "AUG": 6.37,
    "SEP": 6.56,
    "OCT": 6.54,
    "NOV": 6.46,
    "DEC": 5.87
  },
  {
    "LONG": -39.9,
    "LAT": -0.65,
    "CIDADE": "Saboeiro",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.33,
    "FEB": 5.47,
    "MAR": 5.59,
    "APR": 5.41,
    "MAY": 5.35,
    "JUN": 5.38,
    "JUL": 5.74,
    "AUG": 6.42,
    "SEP": 6.6,
    "OCT": 6.34,
    "NOV": 6.12,
    "DEC": 5.55
  },
  {
    "LONG": -3.95,
    "LAT": -6.52,
    "CIDADE": "Jucás",
    "UF": "CEARÁ",
    "anual": 5.82,
    "JAN": 5.39,
    "FEB": 5.59,
    "MAR": 5.73,
    "APR": 5.48,
    "MAY": 5.4,
    "JUN": 5.39,
    "JUL": 5.69,
    "AUG": 6.39,
    "SEP": 6.57,
    "OCT": 6.32,
    "NOV": 6.2,
    "DEC": 5.67
  },
  {
    "LONG": -39.5,
    "LAT": -6.53,
    "CIDADE": "Cariús",
    "UF": "CEARÁ",
    "anual": 5.85,
    "JAN": 5.45,
    "FEB": 5.62,
    "MAR": 5.78,
    "APR": 5.56,
    "MAY": 5.44,
    "JUN": 5.41,
    "JUL": 5.7,
    "AUG": 6.39,
    "SEP": 6.53,
    "OCT": 6.34,
    "NOV": 6.23,
    "DEC": 5.73
  },
  {
    "LONG": -39.29,
    "LAT": -6.36,
    "CIDADE": "Iguatu",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.49,
    "FEB": 5.67,
    "MAR": 5.84,
    "APR": 5.65,
    "MAY": 5.48,
    "JUN": 5.41,
    "JUL": 5.69,
    "AUG": 6.34,
    "SEP": 6.46,
    "OCT": 6.31,
    "NOV": 6.27,
    "DEC": 5.78
  },
  {
    "LONG": -38.86,
    "LAT": -6.4,
    "CIDADE": "Icó",
    "UF": "CEARÁ",
    "anual": 5.93,
    "JAN": 5.63,
    "FEB": 5.77,
    "MAR": 5.96,
    "APR": 5.83,
    "MAY": 5.47,
    "JUN": 5.37,
    "JUL": 5.66,
    "AUG": 6.35,
    "SEP": 6.47,
    "OCT": 6.48,
    "NOV": 6.36,
    "DEC": 5.82
  },
  {
    "LONG": -40.17,
    "LAT": -6.32,
    "CIDADE": "Arneiroz",
    "UF": "CEARÁ",
    "anual": 5.7,
    "JAN": 5.18,
    "FEB": 5.31,
    "MAR": 5.43,
    "APR": 5.31,
    "MAY": 5.31,
    "JUN": 5.32,
    "JUL": 5.73,
    "AUG": 6.43,
    "SEP": 6.56,
    "OCT": 6.3,
    "NOV": 6.05,
    "DEC": 5.49
  },
  {
    "LONG": -38.91,
    "LAT": -6.25,
    "CIDADE": "Orós",
    "UF": "CEARÁ",
    "anual": 5.9,
    "JAN": 5.6,
    "FEB": 5.71,
    "MAR": 5.94,
    "APR": 5.75,
    "MAY": 5.49,
    "JUN": 5.38,
    "JUL": 5.69,
    "AUG": 6.36,
    "SEP": 6.44,
    "OCT": 6.35,
    "NOV": 6.31,
    "DEC": 5.8
  },
  {
    "LONG": -40.69,
    "LAT": -6.21,
    "CIDADE": "Parambu",
    "UF": "CEARÁ",
    "anual": 5.64,
    "JAN": 5.08,
    "FEB": 5.18,
    "MAR": 5.36,
    "APR": 5.16,
    "MAY": 5.19,
    "JUN": 5.31,
    "JUL": 5.71,
    "AUG": 6.46,
    "SEP": 6.59,
    "OCT": 6.27,
    "NOV": 6,
    "DEC": 5.38
  },
  {
    "LONG": -39.2,
    "LAT": -6.25,
    "CIDADE": "Quixelô",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.51,
    "FEB": 5.75,
    "MAR": 5.86,
    "APR": 5.6,
    "MAY": 5.48,
    "JUN": 5.41,
    "JUL": 5.71,
    "AUG": 6.39,
    "SEP": 6.45,
    "OCT": 6.34,
    "NOV": 6.26,
    "DEC": 5.73
  },
  {
    "LONG": -3.99,
    "LAT": -6.12,
    "CIDADE": "Catarina",
    "UF": "CEARÁ",
    "anual": 5.73,
    "JAN": 5.27,
    "FEB": 5.42,
    "MAR": 5.52,
    "APR": 5.29,
    "MAY": 5.27,
    "JUN": 5.34,
    "JUL": 5.69,
    "AUG": 6.41,
    "SEP": 6.6,
    "OCT": 6.28,
    "NOV": 6.11,
    "DEC": 5.51
  },
  {
    "LONG": -39.45,
    "LAT": -6.09,
    "CIDADE": "Acopiara",
    "UF": "CEARÁ",
    "anual": 5.73,
    "JAN": 5.3,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.36,
    "MAY": 5.25,
    "JUN": 5.27,
    "JUL": 5.62,
    "AUG": 6.35,
    "SEP": 6.57,
    "OCT": 6.34,
    "NOV": 6.1,
    "DEC": 5.54
  },
  {
    "LONG": -40.3,
    "LAT": -5.99,
    "CIDADE": "Tauá",
    "UF": "CEARÁ",
    "anual": 5.65,
    "JAN": 5.06,
    "FEB": 5.25,
    "MAR": 5.4,
    "APR": 5.18,
    "MAY": 5.22,
    "JUN": 5.27,
    "JUL": 5.67,
    "AUG": 6.4,
    "SEP": 6.61,
    "OCT": 6.29,
    "NOV": 6.06,
    "DEC": 5.44
  },
  {
    "LONG": -38.46,
    "LAT": -6.04,
    "CIDADE": "Pereiro",
    "UF": "CEARÁ",
    "anual": 5.94,
    "JAN": 5.67,
    "FEB": 5.78,
    "MAR": 5.93,
    "APR": 5.72,
    "MAY": 5.49,
    "JUN": 5.37,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 6.52,
    "OCT": 6.54,
    "NOV": 6.41,
    "DEC": 5.87
  },
  {
    "LONG": -38.35,
    "LAT": -6.03,
    "CIDADE": "Ererê",
    "UF": "CEARÁ",
    "anual": 5.92,
    "JAN": 5.63,
    "FEB": 5.77,
    "MAR": 5.96,
    "APR": 5.77,
    "MAY": 5.52,
    "JUN": 5.34,
    "JUL": 5.65,
    "AUG": 6.27,
    "SEP": 6.49,
    "OCT": 6.46,
    "NOV": 6.34,
    "DEC": 5.79
  },
  {
    "LONG": -39.26,
    "LAT": -5.92,
    "CIDADE": "Deputado Irapuan Pinheiro",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.29,
    "FEB": 5.52,
    "MAR": 5.74,
    "APR": 5.42,
    "MAY": 5.31,
    "JUN": 5.25,
    "JUL": 5.64,
    "AUG": 6.31,
    "SEP": 6.56,
    "OCT": 6.38,
    "NOV": 6.25,
    "DEC": 5.64
  },
  {
    "LONG": -3.86,
    "LAT": -5.9,
    "CIDADE": "Jaguaribe",
    "UF": "CEARÁ",
    "anual": 5.92,
    "JAN": 5.65,
    "FEB": 5.76,
    "MAR": 6,
    "APR": 5.69,
    "MAY": 5.5,
    "JUN": 5.35,
    "JUL": 5.62,
    "AUG": 6.25,
    "SEP": 6.47,
    "OCT": 6.49,
    "NOV": 6.35,
    "DEC": 5.85
  },
  {
    "LONG": -40.7,
    "LAT": -5.84,
    "CIDADE": "Quiterianópolis",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 4.9,
    "FEB": 5.05,
    "MAR": 5.23,
    "APR": 5.02,
    "MAY": 5.08,
    "JUN": 5.24,
    "JUL": 5.62,
    "AUG": 6.4,
    "SEP": 6.56,
    "OCT": 6.27,
    "NOV": 5.97,
    "DEC": 5.34
  },
  {
    "LONG": -39.42,
    "LAT": -5.8,
    "CIDADE": "Piquet Carneiro",
    "UF": "CEARÁ",
    "anual": 5.7,
    "JAN": 5.2,
    "FEB": 5.37,
    "MAR": 5.58,
    "APR": 5.28,
    "MAY": 5.23,
    "JUN": 5.27,
    "JUL": 5.61,
    "AUG": 6.28,
    "SEP": 6.52,
    "OCT": 6.35,
    "NOV": 6.18,
    "DEC": 5.59
  },
  {
    "LONG": -38.29,
    "LAT": -5.81,
    "CIDADE": "Iracema",
    "UF": "CEARÁ",
    "anual": 5.92,
    "JAN": 5.67,
    "FEB": 5.81,
    "MAR": 5.97,
    "APR": 5.74,
    "MAY": 5.51,
    "JUN": 5.32,
    "JUL": 5.63,
    "AUG": 6.22,
    "SEP": 6.47,
    "OCT": 6.5,
    "NOV": 6.39,
    "DEC": 5.82
  },
  {
    "LONG": -39.63,
    "LAT": -5.74,
    "CIDADE": "Mombaça",
    "UF": "CEARÁ",
    "anual": 5.67,
    "JAN": 5.16,
    "FEB": 5.36,
    "MAR": 5.59,
    "APR": 5.31,
    "MAY": 5.19,
    "JUN": 5.22,
    "JUL": 5.6,
    "AUG": 6.24,
    "SEP": 6.5,
    "OCT": 6.29,
    "NOV": 6.08,
    "DEC": 5.47
  },
  {
    "LONG": -39.19,
    "LAT": -5.67,
    "CIDADE": "Milhã",
    "UF": "CEARÁ",
    "anual": 5.77,
    "JAN": 5.38,
    "FEB": 5.47,
    "MAR": 5.72,
    "APR": 5.39,
    "MAY": 5.22,
    "JUN": 5.25,
    "JUL": 5.59,
    "AUG": 6.26,
    "SEP": 6.53,
    "OCT": 6.41,
    "NOV": 6.29,
    "DEC": 5.7
  },
  {
    "LONG": -3.9,
    "LAT": -5.72,
    "CIDADE": "Solonópole",
    "UF": "CEARÁ",
    "anual": 5.81,
    "JAN": 5.42,
    "FEB": 5.56,
    "MAR": 5.78,
    "APR": 5.44,
    "MAY": 5.29,
    "JUN": 5.28,
    "JUL": 5.61,
    "AUG": 6.22,
    "SEP": 6.54,
    "OCT": 6.5,
    "NOV": 6.34,
    "DEC": 5.78
  },
  {
    "LONG": -38.16,
    "LAT": -5.71,
    "CIDADE": "Potiretama",
    "UF": "CEARÁ",
    "anual": 5.93,
    "JAN": 5.69,
    "FEB": 5.84,
    "MAR": 5.95,
    "APR": 5.73,
    "MAY": 5.51,
    "JUN": 5.32,
    "JUL": 5.67,
    "AUG": 6.24,
    "SEP": 6.45,
    "OCT": 6.5,
    "NOV": 6.4,
    "DEC": 5.8
  },
  {
    "LONG": -39.37,
    "LAT": -5.58,
    "CIDADE": "Senador Pompeu",
    "UF": "CEARÁ",
    "anual": 5.69,
    "JAN": 5.25,
    "FEB": 5.4,
    "MAR": 5.62,
    "APR": 5.23,
    "MAY": 5.2,
    "JUN": 5.18,
    "JUL": 5.58,
    "AUG": 6.21,
    "SEP": 6.51,
    "OCT": 6.35,
    "NOV": 6.19,
    "DEC": 5.54
  },
  {
    "LONG": -38.76,
    "LAT": -5.61,
    "CIDADE": "Jaguaretama",
    "UF": "CEARÁ",
    "anual": 5.92,
    "JAN": 5.66,
    "FEB": 5.79,
    "MAR": 6.06,
    "APR": 5.6,
    "MAY": 5.51,
    "JUN": 5.32,
    "JUL": 5.66,
    "AUG": 6.3,
    "SEP": 6.46,
    "OCT": 6.47,
    "NOV": 6.31,
    "DEC": 5.87
  },
  {
    "LONG": -40.77,
    "LAT": -5.53,
    "CIDADE": "Novo Oriente",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 4.9,
    "FEB": 5.13,
    "MAR": 5.36,
    "APR": 5.13,
    "MAY": 5.11,
    "JUN": 5.26,
    "JUL": 5.62,
    "AUG": 6.31,
    "SEP": 6.51,
    "OCT": 6.24,
    "NOV": 6,
    "DEC": 5.36
  },
  {
    "LONG": -39.71,
    "LAT": -5.45,
    "CIDADE": "Pedra Branca",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 5.17,
    "FEB": 5.3,
    "MAR": 5.48,
    "APR": 5.14,
    "MAY": 5.04,
    "JUN": 5.13,
    "JUL": 5.55,
    "AUG": 6.18,
    "SEP": 6.45,
    "OCT": 6.22,
    "NOV": 6.09,
    "DEC": 5.48
  },
  {
    "LONG": -38.46,
    "LAT": -0.55,
    "CIDADE": "Jaguaribara",
    "UF": "CEARÁ",
    "anual": 5.85,
    "JAN": 5.58,
    "FEB": 5.7,
    "MAR": 5.92,
    "APR": 5.66,
    "MAY": 5.57,
    "JUN": 5.33,
    "JUL": 5.61,
    "AUG": 6.23,
    "SEP": 6.35,
    "OCT": 6.35,
    "NOV": 6.2,
    "DEC": 5.74
  },
  {
    "LONG": -38.27,
    "LAT": -5.51,
    "CIDADE": "Alto Santo",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.53,
    "FEB": 5.76,
    "MAR": 5.89,
    "APR": 5.66,
    "MAY": 5.47,
    "JUN": 5.27,
    "JUL": 5.61,
    "AUG": 6.22,
    "SEP": 6.48,
    "OCT": 6.47,
    "NOV": 6.28,
    "DEC": 5.77
  },
  {
    "LONG": -40.31,
    "LAT": -5.39,
    "CIDADE": "Independência",
    "UF": "CEARÁ",
    "anual": 5.68,
    "JAN": 5.15,
    "FEB": 5.28,
    "MAR": 5.47,
    "APR": 5.21,
    "MAY": 5.23,
    "JUN": 5.3,
    "JUL": 5.62,
    "AUG": 6.3,
    "SEP": 6.6,
    "OCT": 6.39,
    "NOV": 6.1,
    "DEC": 5.54
  },
  {
    "LONG": -38.91,
    "LAT": -5.3,
    "CIDADE": "Banabuiú",
    "UF": "CEARÁ",
    "anual": 5.76,
    "JAN": 5.39,
    "FEB": 5.53,
    "MAR": 5.77,
    "APR": 5.39,
    "MAY": 5.36,
    "JUN": 5.2,
    "JUL": 5.56,
    "AUG": 6.23,
    "SEP": 6.5,
    "OCT": 6.36,
    "NOV": 6.18,
    "DEC": 5.65
  },
  {
    "LONG": -38.27,
    "LAT": -5.28,
    "CIDADE": "São João do Jaguaribe",
    "UF": "CEARÁ",
    "anual": 5.83,
    "JAN": 5.48,
    "FEB": 5.68,
    "MAR": 5.92,
    "APR": 5.59,
    "MAY": 5.47,
    "JUN": 5.24,
    "JUL": 5.6,
    "AUG": 6.2,
    "SEP": 6.42,
    "OCT": 6.47,
    "NOV": 6.23,
    "DEC": 5.66
  },
  {
    "LONG": -4.07,
    "LAT": -5.17,
    "CIDADE": "Crateús",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 4.93,
    "FEB": 5.21,
    "MAR": 5.42,
    "APR": 5.09,
    "MAY": 5.12,
    "JUN": 5.2,
    "JUL": 5.6,
    "AUG": 6.24,
    "SEP": 6.54,
    "OCT": 6.32,
    "NOV": 6.1,
    "DEC": 5.4
  },
  {
    "LONG": -39.29,
    "LAT": -5.19,
    "CIDADE": "Quixeramobim",
    "UF": "CEARÁ",
    "anual": 5.69,
    "JAN": 5.28,
    "FEB": 5.39,
    "MAR": 5.63,
    "APR": 5.26,
    "MAY": 5.28,
    "JUN": 5.13,
    "JUL": 5.5,
    "AUG": 6.19,
    "SEP": 6.48,
    "OCT": 6.35,
    "NOV": 6.17,
    "DEC": 5.6
  },
  {
    "LONG": -38.13,
    "LAT": -5.24,
    "CIDADE": "Tabuleiro do Norte",
    "UF": "CEARÁ",
    "anual": 5.84,
    "JAN": 5.57,
    "FEB": 5.7,
    "MAR": 5.93,
    "APR": 5.57,
    "MAY": 5.44,
    "JUN": 5.29,
    "JUL": 5.57,
    "AUG": 6.2,
    "SEP": 6.42,
    "OCT": 6.48,
    "NOV": 6.23,
    "DEC": 5.74
  },
  {
    "LONG": -3.97,
    "LAT": -0.51,
    "CIDADE": "Boa Viagem",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 5.11,
    "FEB": 5.29,
    "MAR": 5.46,
    "APR": 5.09,
    "MAY": 5.14,
    "JUN": 5.12,
    "JUL": 5.45,
    "AUG": 6.13,
    "SEP": 6.52,
    "OCT": 6.31,
    "NOV": 6.12,
    "DEC": 5.49
  },
  {
    "LONG": -38.37,
    "LAT": -5.1,
    "CIDADE": "Morada Nova",
    "UF": "CEARÁ",
    "anual": 5.85,
    "JAN": 5.56,
    "FEB": 5.69,
    "MAR": 5.93,
    "APR": 5.55,
    "MAY": 5.48,
    "JUN": 5.27,
    "JUL": 5.66,
    "AUG": 6.23,
    "SEP": 6.45,
    "OCT": 6.48,
    "NOV": 6.24,
    "DEC": 5.7
  },
  {
    "LONG": -3.81,
    "LAT": -5.14,
    "CIDADE": "Limoeiro do Norte",
    "UF": "CEARÁ",
    "anual": 5.8,
    "JAN": 5.57,
    "FEB": 5.7,
    "MAR": 5.86,
    "APR": 5.52,
    "MAY": 5.45,
    "JUN": 5.2,
    "JUL": 5.52,
    "AUG": 6.18,
    "SEP": 6.34,
    "OCT": 6.38,
    "NOV": 6.22,
    "DEC": 5.68
  },
  {
    "LONG": -37.98,
    "LAT": -5.07,
    "CIDADE": "Quixeré",
    "UF": "CEARÁ",
    "anual": 5.81,
    "JAN": 5.49,
    "FEB": 5.72,
    "MAR": 5.79,
    "APR": 5.57,
    "MAY": 5.47,
    "JUN": 5.29,
    "JUL": 5.58,
    "AUG": 6.15,
    "SEP": 6.37,
    "OCT": 6.4,
    "NOV": 6.2,
    "DEC": 5.69
  },
  {
    "LONG": -39.02,
    "LAT": -4.97,
    "CIDADE": "Quixadá",
    "UF": "CEARÁ",
    "anual": 5.69,
    "JAN": 5.31,
    "FEB": 5.45,
    "MAR": 5.6,
    "APR": 5.24,
    "MAY": 5.3,
    "JUN": 5.16,
    "JUL": 5.52,
    "AUG": 6.21,
    "SEP": 6.54,
    "OCT": 6.34,
    "NOV": 6.09,
    "DEC": 5.54
  },
  {
    "LONG": -38.64,
    "LAT": -4.97,
    "CIDADE": "Ibicuitinga",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.45,
    "FEB": 5.6,
    "MAR": 5.76,
    "APR": 5.38,
    "MAY": 5.38,
    "JUN": 5.26,
    "JUL": 5.63,
    "AUG": 6.26,
    "SEP": 6.5,
    "OCT": 6.44,
    "NOV": 6.12,
    "DEC": 5.61
  },
  {
    "LONG": -4.08,
    "LAT": -0.49,
    "CIDADE": "Ipaporanga",
    "UF": "CEARÁ",
    "anual": 5.54,
    "JAN": 4.88,
    "FEB": 5.16,
    "MAR": 5.37,
    "APR": 4.98,
    "MAY": 5.09,
    "JUN": 5.03,
    "JUL": 5.49,
    "AUG": 6.14,
    "SEP": 6.55,
    "OCT": 6.35,
    "NOV": 6.08,
    "DEC": 5.35
  },
  {
    "LONG": -37.97,
    "LAT": -4.93,
    "CIDADE": "Russas",
    "UF": "CEARÁ",
    "anual": 5.79,
    "JAN": 5.57,
    "FEB": 5.69,
    "MAR": 5.84,
    "APR": 5.5,
    "MAY": 5.43,
    "JUN": 5.27,
    "JUL": 5.52,
    "AUG": 6.12,
    "SEP": 6.35,
    "OCT": 6.36,
    "NOV": 6.15,
    "DEC": 5.69
  },
  {
    "LONG": -40.32,
    "LAT": -4.83,
    "CIDADE": "Tamboril",
    "UF": "CEARÁ",
    "anual": 5.62,
    "JAN": 4.96,
    "FEB": 5.21,
    "MAR": 5.29,
    "APR": 5.06,
    "MAY": 5.25,
    "JUN": 5.24,
    "JUL": 5.56,
    "AUG": 6.3,
    "SEP": 6.64,
    "OCT": 6.41,
    "NOV": 6.15,
    "DEC": 5.34
  },
  {
    "LONG": -4.01,
    "LAT": -4.79,
    "CIDADE": "Monsenhor Tabosa",
    "UF": "CEARÁ",
    "anual": 5.48,
    "JAN": 4.98,
    "FEB": 5.09,
    "MAR": 5.19,
    "APR": 4.81,
    "MAY": 4.96,
    "JUN": 4.91,
    "JUL": 5.42,
    "AUG": 6.14,
    "SEP": 6.52,
    "OCT": 6.28,
    "NOV": 6.07,
    "DEC": 5.35
  },
  {
    "LONG": -39.57,
    "LAT": -4.85,
    "CIDADE": "Madalena",
    "UF": "CEARÁ",
    "anual": 5.52,
    "JAN": 5.02,
    "FEB": 5.22,
    "MAR": 5.3,
    "APR": 5.02,
    "MAY": 5.05,
    "JUN": 5,
    "JUL": 5.37,
    "AUG": 6.1,
    "SEP": 6.5,
    "OCT": 6.3,
    "NOV": 6.03,
    "DEC": 5.36
  },
  {
    "LONG": -39.13,
    "LAT": -4.84,
    "CIDADE": "Choró",
    "UF": "CEARÁ",
    "anual": 5.64,
    "JAN": 5.22,
    "FEB": 5.34,
    "MAR": 5.54,
    "APR": 5.13,
    "MAY": 5.27,
    "JUN": 5.21,
    "JUL": 5.57,
    "AUG": 6.23,
    "SEP": 6.57,
    "OCT": 6.29,
    "NOV": 6,
    "DEC": 5.36
  },
  {
    "LONG": -38.82,
    "LAT": -4.82,
    "CIDADE": "Ibaretama",
    "UF": "CEARÁ",
    "anual": 5.71,
    "JAN": 5.35,
    "FEB": 5.47,
    "MAR": 5.65,
    "APR": 5.21,
    "MAY": 5.41,
    "JUN": 5.17,
    "JUL": 5.54,
    "AUG": 6.29,
    "SEP": 6.52,
    "OCT": 6.39,
    "NOV": 6.05,
    "DEC": 5.48
  },
  {
    "LONG": -37.78,
    "LAT": -4.83,
    "CIDADE": "Jaguaruana",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.56,
    "FEB": 5.68,
    "MAR": 5.81,
    "APR": 5.46,
    "MAY": 5.47,
    "JUN": 5.23,
    "JUL": 5.53,
    "AUG": 6.11,
    "SEP": 6.27,
    "OCT": 6.31,
    "NOV": 6.21,
    "DEC": 5.78
  },
  {
    "LONG": -40.92,
    "LAT": -4.75,
    "CIDADE": "Poranga",
    "UF": "CEARÁ",
    "anual": 5.8,
    "JAN": 5.14,
    "FEB": 5.3,
    "MAR": 5.51,
    "APR": 5.16,
    "MAY": 5.33,
    "JUN": 5.42,
    "JUL": 5.83,
    "AUG": 6.47,
    "SEP": 6.82,
    "OCT": 6.63,
    "NOV": 6.42,
    "DEC": 5.62
  },
  {
    "LONG": -40.83,
    "LAT": -4.75,
    "CIDADE": "Ararendá",
    "UF": "CEARÁ",
    "anual": 5.53,
    "JAN": 4.84,
    "FEB": 5,
    "MAR": 5.16,
    "APR": 4.81,
    "MAY": 4.97,
    "JUN": 5.01,
    "JUL": 5.55,
    "AUG": 6.31,
    "SEP": 6.7,
    "OCT": 6.47,
    "NOV": 6.16,
    "DEC": 5.42
  },
  {
    "LONG": -40.56,
    "LAT": -4.71,
    "CIDADE": "Nova Russas",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 4.95,
    "FEB": 5.26,
    "MAR": 5.44,
    "APR": 5.13,
    "MAY": 5.27,
    "JUN": 5.17,
    "JUL": 5.5,
    "AUG": 6.18,
    "SEP": 6.55,
    "OCT": 6.34,
    "NOV": 6.06,
    "DEC": 5.36
  },
  {
    "LONG": -37.97,
    "LAT": -4.74,
    "CIDADE": "Palhano",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.55,
    "FEB": 5.74,
    "MAR": 5.82,
    "APR": 5.4,
    "MAY": 5.48,
    "JUN": 5.25,
    "JUL": 5.52,
    "AUG": 6.11,
    "SEP": 6.31,
    "OCT": 6.27,
    "NOV": 6.15,
    "DEC": 5.76
  },
  {
    "LONG": -37.83,
    "LAT": -4.67,
    "CIDADE": "Itaiçaba",
    "UF": "CEARÁ",
    "anual": 5.82,
    "JAN": 5.61,
    "FEB": 5.78,
    "MAR": 5.85,
    "APR": 5.41,
    "MAY": 5.5,
    "JUN": 5.27,
    "JUL": 5.56,
    "AUG": 6.15,
    "SEP": 6.36,
    "OCT": 6.28,
    "NOV": 6.21,
    "DEC": 5.84
  },
  {
    "LONG": -37.35,
    "LAT": -4.71,
    "CIDADE": "Icapuí",
    "UF": "CEARÁ",
    "anual": 5.98,
    "JAN": 6.09,
    "FEB": 6.2,
    "MAR": 6.18,
    "APR": 5.82,
    "MAY": 5.73,
    "JUN": 5.56,
    "JUL": 5.73,
    "AUG": 6.24,
    "SEP": 6,
    "OCT": 5.99,
    "NOV": 6.1,
    "DEC": 6.17
  },
  {
    "LONG": -40.2,
    "LAT": -4.64,
    "CIDADE": "Catunda",
    "UF": "CEARÁ",
    "anual": 5.66,
    "JAN": 5.09,
    "FEB": 5.34,
    "MAR": 5.45,
    "APR": 5.09,
    "MAY": 5.18,
    "JUN": 5.12,
    "JUL": 5.5,
    "AUG": 6.22,
    "SEP": 6.67,
    "OCT": 6.47,
    "NOV": 6.21,
    "DEC": 5.52
  },
  {
    "LONG": -38.93,
    "LAT": -4.56,
    "CIDADE": "Itapiúna",
    "UF": "CEARÁ",
    "anual": 5.59,
    "JAN": 5.2,
    "FEB": 5.38,
    "MAR": 5.44,
    "APR": 5.07,
    "MAY": 5.25,
    "JUN": 5.03,
    "JUL": 5.45,
    "AUG": 6.18,
    "SEP": 6.43,
    "OCT": 6.24,
    "NOV": 5.99,
    "DEC": 5.42
  },
  {
    "LONG": -37.77,
    "LAT": -4.56,
    "CIDADE": "Aracati",
    "UF": "CEARÁ",
    "anual": 5.91,
    "JAN": 5.88,
    "FEB": 6.01,
    "MAR": 6.02,
    "APR": 5.49,
    "MAY": 5.52,
    "JUN": 5.31,
    "JUL": 5.63,
    "AUG": 6.19,
    "SEP": 6.24,
    "OCT": 6.26,
    "NOV": 6.24,
    "DEC": 6.11
  },
  {
    "LONG": -40.71,
    "LAT": -4.54,
    "CIDADE": "Ipueiras",
    "UF": "CEARÁ",
    "anual": 5.47,
    "JAN": 4.81,
    "FEB": 5.05,
    "MAR": 5.22,
    "APR": 4.9,
    "MAY": 5.01,
    "JUN": 5.06,
    "JUL": 5.45,
    "AUG": 6.13,
    "SEP": 6.5,
    "OCT": 6.26,
    "NOV": 6,
    "DEC": 5.24
  },
  {
    "LONG": -39.62,
    "LAT": -4.53,
    "CIDADE": "Itatira",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 5.08,
    "FEB": 5.25,
    "MAR": 5.33,
    "APR": 4.92,
    "MAY": 5.03,
    "JUN": 5.03,
    "JUL": 5.49,
    "AUG": 6.26,
    "SEP": 6.65,
    "OCT": 6.43,
    "NOV": 6.09,
    "DEC": 5.38
  },
  {
    "LONG": -38.91,
    "LAT": -4.46,
    "CIDADE": "Capistrano",
    "UF": "CEARÁ",
    "anual": 5.52,
    "JAN": 5.17,
    "FEB": 5.27,
    "MAR": 5.34,
    "APR": 4.9,
    "MAY": 5.13,
    "JUN": 5.01,
    "JUL": 5.4,
    "AUG": 6.13,
    "SEP": 6.38,
    "OCT": 6.18,
    "NOV": 5.96,
    "DEC": 5.38
  },
  {
    "LONG": -38.59,
    "LAT": -4.49,
    "CIDADE": "Ocara",
    "UF": "CEARÁ",
    "anual": 5.68,
    "JAN": 5.32,
    "FEB": 5.46,
    "MAR": 5.58,
    "APR": 5.22,
    "MAY": 5.4,
    "JUN": 5.27,
    "JUL": 5.62,
    "AUG": 6.18,
    "SEP": 6.38,
    "OCT": 6.24,
    "NOV": 5.98,
    "DEC": 5.5
  },
  {
    "LONG": -37.8,
    "LAT": -4.45,
    "CIDADE": "Fortim",
    "UF": "CEARÁ",
    "anual": 5.67,
    "JAN": 5.69,
    "FEB": 5.85,
    "MAR": 5.82,
    "APR": 5.41,
    "MAY": 5.48,
    "JUN": 5.25,
    "JUL": 5.36,
    "AUG": 5.81,
    "SEP": 5.83,
    "OCT": 5.9,
    "NOV": 5.91,
    "DEC": 5.72
  },
  {
    "LONG": -40.9,
    "LAT": -4.41,
    "CIDADE": "Croatá",
    "UF": "CEARÁ",
    "anual": 5.74,
    "JAN": 5.21,
    "FEB": 5.35,
    "MAR": 5.48,
    "APR": 5.14,
    "MAY": 5.24,
    "JUN": 5.3,
    "JUL": 5.66,
    "AUG": 6.34,
    "SEP": 6.69,
    "OCT": 6.51,
    "NOV": 6.34,
    "DEC": 5.63
  },
  {
    "LONG": -40.41,
    "LAT": "#VALOR!",
    "CIDADE": "Hidrolândia",
    "UF": "CEARÁ",
    "anual": 5.62,
    "JAN": 5.12,
    "FEB": 5.3,
    "MAR": 5.49,
    "APR": 5.14,
    "MAY": 5.27,
    "JUN": 5.17,
    "JUL": 5.4,
    "AUG": 6.14,
    "SEP": 6.57,
    "OCT": 6.37,
    "NOV": 6.08,
    "DEC": 5.41
  },
  {
    "LONG": -39.32,
    "LAT": -0.44,
    "CIDADE": "Canindé",
    "UF": "CEARÁ",
    "anual": 5.64,
    "JAN": 5.14,
    "FEB": 5.37,
    "MAR": 5.46,
    "APR": 5.08,
    "MAY": 5.25,
    "JUN": 5.15,
    "JUL": 5.52,
    "AUG": 6.22,
    "SEP": 6.62,
    "OCT": 6.35,
    "NOV": 6.06,
    "DEC": 5.4
  },
  {
    "LONG": -39.05,
    "LAT": -4.41,
    "CIDADE": "Aratuba",
    "UF": "CEARÁ",
    "anual": 5.63,
    "JAN": 5.24,
    "FEB": 5.3,
    "MAR": 5.31,
    "APR": 4.87,
    "MAY": 5.18,
    "JUN": 5.11,
    "JUL": 5.55,
    "AUG": 6.36,
    "SEP": 6.66,
    "OCT": 6.39,
    "NOV": 6.16,
    "DEC": 5.49
  },
  {
    "LONG": -38.81,
    "LAT": -4.37,
    "CIDADE": "Aracoiaba",
    "UF": "CEARÁ",
    "anual": 5.45,
    "JAN": 5.11,
    "FEB": 5.24,
    "MAR": 5.33,
    "APR": 4.89,
    "MAY": 5.07,
    "JUN": 4.92,
    "JUL": 5.34,
    "AUG": 6.02,
    "SEP": 6.23,
    "OCT": 6.06,
    "NOV": 5.86,
    "DEC": 5.31
  },
  {
    "LONG": -40.71,
    "LAT": -4.32,
    "CIDADE": "Ipu",
    "UF": "CEARÁ",
    "anual": 5.5,
    "JAN": 4.95,
    "FEB": 5.09,
    "MAR": 5.21,
    "APR": 4.88,
    "MAY": 4.93,
    "JUN": 5.07,
    "JUL": 5.47,
    "AUG": 6.22,
    "SEP": 6.54,
    "OCT": 6.31,
    "NOV": 6.02,
    "DEC": 5.3
  },
  {
    "LONG": -40.15,
    "LAT": -4.34,
    "CIDADE": "Santa Quitéria",
    "UF": "CEARÁ",
    "anual": 5.64,
    "JAN": 5.15,
    "FEB": 5.33,
    "MAR": 5.41,
    "APR": 5.1,
    "MAY": 5.19,
    "JUN": 5.18,
    "JUL": 5.45,
    "AUG": 6.19,
    "SEP": 6.59,
    "OCT": 6.42,
    "NOV": 6.18,
    "DEC": 5.48
  },
  {
    "LONG": -38.93,
    "LAT": -4.26,
    "CIDADE": "Guaramiranga",
    "UF": "CEARÁ",
    "anual": 5.44,
    "JAN": 5.09,
    "FEB": 5.08,
    "MAR": 5.1,
    "APR": 4.66,
    "MAY": 4.98,
    "JUN": 4.88,
    "JUL": 5.43,
    "AUG": 6.22,
    "SEP": 6.43,
    "OCT": 6.18,
    "NOV": 5.97,
    "DEC": 5.31
  },
  {
    "LONG": -39,
    "LAT": -4.3,
    "CIDADE": "Mulungu",
    "UF": "CEARÁ",
    "anual": 5.44,
    "JAN": 5.09,
    "FEB": 5.08,
    "MAR": 5.1,
    "APR": 4.66,
    "MAY": 4.98,
    "JUN": 4.88,
    "JUL": 5.43,
    "AUG": 6.22,
    "SEP": 6.43,
    "OCT": 6.18,
    "NOV": 5.97,
    "DEC": 5.31
  },
  {
    "LONG": -38.88,
    "LAT": -4.33,
    "CIDADE": "Baturité",
    "UF": "CEARÁ",
    "anual": 5.3,
    "JAN": 4.91,
    "FEB": 4.99,
    "MAR": 5.12,
    "APR": 4.67,
    "MAY": 4.93,
    "JUN": 4.74,
    "JUL": 5.27,
    "AUG": 5.96,
    "SEP": 6.15,
    "OCT": 5.92,
    "NOV": 5.75,
    "DEC": 5.15
  },
  {
    "LONG": -38.64,
    "LAT": -4.29,
    "CIDADE": "Barreira",
    "UF": "CEARÁ",
    "anual": 5.53,
    "JAN": 5.21,
    "FEB": 5.33,
    "MAR": 5.3,
    "APR": 4.91,
    "MAY": 5.19,
    "JUN": 5.07,
    "JUL": 5.47,
    "AUG": 6.1,
    "SEP": 6.31,
    "OCT": 6.13,
    "NOV": 5.94,
    "DEC": 5.41
  },
  {
    "LONG": -38.5,
    "LAT": -4.3,
    "CIDADE": "Chorozinho",
    "UF": "CEARÁ",
    "anual": 5.62,
    "JAN": 5.31,
    "FEB": 5.42,
    "MAR": 5.47,
    "APR": 5.03,
    "MAY": 5.31,
    "JUN": 5.18,
    "JUL": 5.53,
    "AUG": 6.14,
    "SEP": 6.29,
    "OCT": 6.2,
    "NOV": 6.03,
    "DEC": 5.49
  },
  {
    "LONG": -40.94,
    "LAT": -4.16,
    "CIDADE": "Carnaubal",
    "UF": "CEARÁ",
    "anual": 5.62,
    "JAN": 5.15,
    "FEB": 5.27,
    "MAR": 5.32,
    "APR": 4.96,
    "MAY": 5.07,
    "JUN": 5.17,
    "JUL": 5.53,
    "AUG": 6.22,
    "SEP": 6.58,
    "OCT": 6.39,
    "NOV": 6.24,
    "DEC": 5.53
  },
  {
    "LONG": -40.75,
    "LAT": -4.16,
    "CIDADE": "Guaraciaba do Norte",
    "UF": "CEARÁ",
    "anual": 5.5,
    "JAN": 4.98,
    "FEB": 5.12,
    "MAR": 5.18,
    "APR": 4.84,
    "MAY": 4.93,
    "JUN": 5.09,
    "JUL": 5.46,
    "AUG": 6.21,
    "SEP": 6.54,
    "OCT": 6.33,
    "NOV": 5.99,
    "DEC": 5.28
  },
  {
    "LONG": -40.64,
    "LAT": -4.24,
    "CIDADE": "Pires Ferreira",
    "UF": "CEARÁ",
    "anual": 5.41,
    "JAN": 4.88,
    "FEB": 5.13,
    "MAR": 5.17,
    "APR": 4.89,
    "MAY": 4.97,
    "JUN": 5.01,
    "JUL": 5.31,
    "AUG": 6,
    "SEP": 6.39,
    "OCT": 6.2,
    "NOV": 5.87,
    "DEC": 5.1
  },
  {
    "LONG": -40.47,
    "LAT": -4.19,
    "CIDADE": "Varjota",
    "UF": "CEARÁ",
    "anual": 5.61,
    "JAN": 5.12,
    "FEB": 5.31,
    "MAR": 5.43,
    "APR": 5.12,
    "MAY": 5.24,
    "JUN": 5.25,
    "JUL": 5.48,
    "AUG": 6.16,
    "SEP": 6.53,
    "OCT": 6.25,
    "NOV": 6.04,
    "DEC": 5.38
  },
  {
    "LONG": -39.19,
    "LAT": -4.23,
    "CIDADE": "Caridade",
    "UF": "CEARÁ",
    "anual": 5.69,
    "JAN": 5.15,
    "FEB": 5.42,
    "MAR": 5.32,
    "APR": 5.01,
    "MAY": 5.39,
    "JUN": 5.32,
    "JUL": 5.58,
    "AUG": 6.34,
    "SEP": 6.63,
    "OCT": 6.43,
    "NOV": 6.19,
    "DEC": 5.46
  },
  {
    "LONG": -38.92,
    "LAT": -4.23,
    "CIDADE": "Pacoti",
    "UF": "CEARÁ",
    "anual": 5.62,
    "JAN": 5.21,
    "FEB": 5.25,
    "MAR": 5.2,
    "APR": 4.79,
    "MAY": 5.23,
    "JUN": 5.17,
    "JUL": 5.59,
    "AUG": 6.36,
    "SEP": 6.63,
    "OCT": 6.39,
    "NOV": 6.15,
    "DEC": 5.48
  },
  {
    "LONG": -38.71,
    "LAT": -4.22,
    "CIDADE": "Acarape",
    "UF": "CEARÁ",
    "anual": 5.42,
    "JAN": 4.99,
    "FEB": 5.11,
    "MAR": 5.12,
    "APR": 4.76,
    "MAY": 5.1,
    "JUN": 5.04,
    "JUL": 5.49,
    "AUG": 6.13,
    "SEP": 6.28,
    "OCT": 5.99,
    "NOV": 5.81,
    "DEC": 5.19
  },
  {
    "LONG": -3.87,
    "LAT": -4.22,
    "CIDADE": "Redenção",
    "UF": "CEARÁ",
    "anual": 5.42,
    "JAN": 4.99,
    "FEB": 5.11,
    "MAR": 5.12,
    "APR": 4.76,
    "MAY": 5.1,
    "JUN": 5.04,
    "JUL": 5.49,
    "AUG": 6.13,
    "SEP": 6.28,
    "OCT": 5.99,
    "NOV": 5.81,
    "DEC": 5.19
  },
  {
    "LONG": -38.47,
    "LAT": -4.17,
    "CIDADE": "Pacajus",
    "UF": "CEARÁ",
    "anual": 5.7,
    "JAN": 5.48,
    "FEB": 5.58,
    "MAR": 5.51,
    "APR": 5.04,
    "MAY": 5.39,
    "JUN": 5.29,
    "JUL": 5.59,
    "AUG": 6.17,
    "SEP": 6.26,
    "OCT": 6.25,
    "NOV": 6.14,
    "DEC": 5.68
  },
  {
    "LONG": -38.13,
    "LAT": -4.18,
    "CIDADE": "Beberibe",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.77,
    "FEB": 5.95,
    "MAR": 5.8,
    "APR": 5.29,
    "MAY": 5.5,
    "JUN": 5.51,
    "JUL": 5.74,
    "AUG": 6.23,
    "SEP": 6.1,
    "OCT": 6.35,
    "NOV": 6.27,
    "DEC": 5.98
  },
  {
    "LONG": -40.58,
    "LAT": -4.14,
    "CIDADE": "Reriutaba",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 5.01,
    "FEB": 5.3,
    "MAR": 5.32,
    "APR": 5.06,
    "MAY": 5.17,
    "JUN": 5.22,
    "JUL": 5.47,
    "AUG": 6.18,
    "SEP": 6.52,
    "OCT": 6.3,
    "NOV": 5.91,
    "DEC": 5.27
  },
  {
    "LONG": -3.92,
    "LAT": -4.09,
    "CIDADE": "Paramoti",
    "UF": "CEARÁ",
    "anual": 5.61,
    "JAN": 5.09,
    "FEB": 5.3,
    "MAR": 5.27,
    "APR": 4.99,
    "MAY": 5.33,
    "JUN": 5.23,
    "JUL": 5.49,
    "AUG": 6.2,
    "SEP": 6.57,
    "OCT": 6.36,
    "NOV": 6.08,
    "DEC": 5.46
  },
  {
    "LONG": -38.84,
    "LAT": -4.14,
    "CIDADE": "Palmácia",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 5.18,
    "FEB": 5.31,
    "MAR": 5.31,
    "APR": 4.91,
    "MAY": 5.32,
    "JUN": 5.29,
    "JUL": 5.59,
    "AUG": 6.28,
    "SEP": 6.43,
    "OCT": 6.2,
    "NOV": 6,
    "DEC": 5.36
  },
  {
    "LONG": -38.49,
    "LAT": -4.11,
    "CIDADE": "Horizonte",
    "UF": "CEARÁ",
    "anual": 5.72,
    "JAN": 5.57,
    "FEB": 5.62,
    "MAR": 5.52,
    "APR": 4.98,
    "MAY": 5.34,
    "JUN": 5.33,
    "JUL": 5.63,
    "AUG": 6.19,
    "SEP": 6.23,
    "OCT": 6.3,
    "NOV": 6.21,
    "DEC": 5.73
  },
  {
    "LONG": -38.24,
    "LAT": -4.13,
    "CIDADE": "Cascavel",
    "UF": "CEARÁ",
    "anual": 5.87,
    "JAN": 5.77,
    "FEB": 5.9,
    "MAR": 5.75,
    "APR": 5.23,
    "MAY": 5.58,
    "JUN": 5.53,
    "JUL": 5.77,
    "AUG": 6.21,
    "SEP": 6.12,
    "OCT": 6.34,
    "NOV": 6.26,
    "DEC": 5.92
  },
  {
    "LONG": -40.86,
    "LAT": -4.05,
    "CIDADE": "São Benedito",
    "UF": "CEARÁ",
    "anual": 5.42,
    "JAN": 4.83,
    "FEB": 4.9,
    "MAR": 4.98,
    "APR": 4.61,
    "MAY": 4.86,
    "JUN": 5.02,
    "JUL": 5.45,
    "AUG": 6.19,
    "SEP": 6.56,
    "OCT": 6.29,
    "NOV": 6.06,
    "DEC": 5.33
  },
  {
    "LONG": -40.7,
    "LAT": -3.98,
    "CIDADE": "Pacujá",
    "UF": "CEARÁ",
    "anual": 5.41,
    "JAN": 4.84,
    "FEB": 5.08,
    "MAR": 5.12,
    "APR": 4.85,
    "MAY": 5,
    "JUN": 5.08,
    "JUL": 5.38,
    "AUG": 6.05,
    "SEP": 6.41,
    "OCT": 6.2,
    "NOV": 5.83,
    "DEC": 5.14
  },
  {
    "LONG": -40.75,
    "LAT": -4.04,
    "CIDADE": "Graça",
    "UF": "CEARÁ",
    "anual": 5.41,
    "JAN": 4.84,
    "FEB": 5.08,
    "MAR": 5.12,
    "APR": 4.85,
    "MAY": 5,
    "JUN": 5.08,
    "JUL": 5.38,
    "AUG": 6.05,
    "SEP": 6.41,
    "OCT": 6.2,
    "NOV": 5.83,
    "DEC": 5.14
  },
  {
    "LONG": -40.48,
    "LAT": -3.95,
    "CIDADE": "Cariré",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 5.03,
    "FEB": 5.38,
    "MAR": 5.3,
    "APR": 5.02,
    "MAY": 5.14,
    "JUN": 5.17,
    "JUL": 5.41,
    "AUG": 6.11,
    "SEP": 6.53,
    "OCT": 6.28,
    "NOV": 5.96,
    "DEC": 5.34
  },
  {
    "LONG": -39.58,
    "LAT": -3.99,
    "CIDADE": "Tejuçuoca",
    "UF": "CEARÁ",
    "anual": 5.6,
    "JAN": 5.02,
    "FEB": 5.3,
    "MAR": 5.3,
    "APR": 5.02,
    "MAY": 5.32,
    "JUN": 5.17,
    "JUL": 5.5,
    "AUG": 6.25,
    "SEP": 6.57,
    "OCT": 6.35,
    "NOV": 6.01,
    "DEC": 5.37
  },
  {
    "LONG": -39.45,
    "LAT": -4.04,
    "CIDADE": "General Sampaio",
    "UF": "CEARÁ",
    "anual": 5.55,
    "JAN": 4.96,
    "FEB": 5.24,
    "MAR": 5.23,
    "APR": 4.99,
    "MAY": 5.27,
    "JUN": 5.14,
    "JUL": 5.46,
    "AUG": 6.17,
    "SEP": 6.52,
    "OCT": 6.27,
    "NOV": 5.97,
    "DEC": 5.34
  },
  {
    "LONG": -38.62,
    "LAT": -3.98,
    "CIDADE": "Pacatuba",
    "UF": "CEARÁ",
    "anual": 5.44,
    "JAN": 5.18,
    "FEB": 5.25,
    "MAR": 5.16,
    "APR": 4.66,
    "MAY": 5.05,
    "JUN": 5.08,
    "JUL": 5.47,
    "AUG": 6.09,
    "SEP": 6.17,
    "OCT": 5.96,
    "NOV": 5.88,
    "DEC": 5.32
  },
  {
    "LONG": -38.64,
    "LAT": -0.4,
    "CIDADE": "Guaiúba",
    "UF": "CEARÁ",
    "anual": 5.44,
    "JAN": 5.18,
    "FEB": 5.25,
    "MAR": 5.16,
    "APR": 4.66,
    "MAY": 5.05,
    "JUN": 5.08,
    "JUL": 5.47,
    "AUG": 6.09,
    "SEP": 6.17,
    "OCT": 5.96,
    "NOV": 5.88,
    "DEC": 5.32
  },
  {
    "LONG": -38.53,
    "LAT": -3.97,
    "CIDADE": "Itaitinga",
    "UF": "CEARÁ",
    "anual": 5.59,
    "JAN": 5.37,
    "FEB": 5.44,
    "MAR": 5.39,
    "APR": 4.85,
    "MAY": 5.24,
    "JUN": 5.27,
    "JUL": 5.55,
    "AUG": 6.15,
    "SEP": 6.2,
    "OCT": 6.13,
    "NOV": 6.01,
    "DEC": 5.49
  },
  {
    "LONG": -38.31,
    "LAT": -4.02,
    "CIDADE": "Pindoretama",
    "UF": "CEARÁ",
    "anual": 5.8,
    "JAN": 5.65,
    "FEB": 5.82,
    "MAR": 5.63,
    "APR": 5.09,
    "MAY": 5.47,
    "JUN": 5.51,
    "JUL": 5.71,
    "AUG": 6.19,
    "SEP": 6.21,
    "OCT": 6.31,
    "NOV": 6.26,
    "DEC": 5.8
  },
  {
    "LONG": -40.92,
    "LAT": -3.85,
    "CIDADE": "Ubajara",
    "UF": "CEARÁ",
    "anual": 5.67,
    "JAN": 5.08,
    "FEB": 5.21,
    "MAR": 5.29,
    "APR": 4.91,
    "MAY": 5.16,
    "JUN": 5.31,
    "JUL": 5.62,
    "AUG": 6.36,
    "SEP": 6.68,
    "OCT": 6.47,
    "NOV": 6.31,
    "DEC": 5.57
  },
  {
    "LONG": -40.89,
    "LAT": -3.92,
    "CIDADE": "Ibiapina",
    "UF": "CEARÁ",
    "anual": 5.33,
    "JAN": 4.72,
    "FEB": 4.9,
    "MAR": 4.92,
    "APR": 4.59,
    "MAY": 4.82,
    "JUN": 4.96,
    "JUL": 5.35,
    "AUG": 6.07,
    "SEP": 6.4,
    "OCT": 6.18,
    "NOV": 5.89,
    "DEC": 5.14
  },
  {
    "LONG": -4.07,
    "LAT": -0.39,
    "CIDADE": "Mucambo",
    "UF": "CEARÁ",
    "anual": 5.44,
    "JAN": 4.82,
    "FEB": 5.11,
    "MAR": 5.17,
    "APR": 4.91,
    "MAY": 5.07,
    "JUN": 5.1,
    "JUL": 5.4,
    "AUG": 6.05,
    "SEP": 6.43,
    "OCT": 6.24,
    "NOV": 5.84,
    "DEC": 5.16
  },
  {
    "LONG": -40.39,
    "LAT": -3.92,
    "CIDADE": "Groaíras",
    "UF": "CEARÁ",
    "anual": 5.53,
    "JAN": 5.02,
    "FEB": 5.25,
    "MAR": 5.25,
    "APR": 4.96,
    "MAY": 5.1,
    "JUN": 5.17,
    "JUL": 5.41,
    "AUG": 6.11,
    "SEP": 6.5,
    "OCT": 6.3,
    "NOV": 6,
    "DEC": 5.34
  },
  {
    "LONG": -39.44,
    "LAT": -3.95,
    "CIDADE": "Apuiarés",
    "UF": "CEARÁ",
    "anual": 5.51,
    "JAN": 5.01,
    "FEB": 5.26,
    "MAR": 5.24,
    "APR": 4.87,
    "MAY": 5.21,
    "JUN": 5.1,
    "JUL": 5.41,
    "AUG": 6.09,
    "SEP": 6.49,
    "OCT": 6.23,
    "NOV": 5.92,
    "DEC": 5.34
  },
  {
    "LONG": -38.63,
    "LAT": -3.87,
    "CIDADE": "Maracanaú",
    "UF": "CEARÁ",
    "anual": 5.45,
    "JAN": 5.19,
    "FEB": 5.33,
    "MAR": 5.2,
    "APR": 4.67,
    "MAY": 5.1,
    "JUN": 5.1,
    "JUL": 5.42,
    "AUG": 6.03,
    "SEP": 6.1,
    "OCT": 6.02,
    "NOV": 5.89,
    "DEC": 5.36
  },
  {
    "LONG": -38.68,
    "LAT": -3.89,
    "CIDADE": "Maranguape",
    "UF": "CEARÁ",
    "anual": 5.45,
    "JAN": 5.19,
    "FEB": 5.33,
    "MAR": 5.2,
    "APR": 4.67,
    "MAY": 5.1,
    "JUN": 5.1,
    "JUL": 5.42,
    "AUG": 6.03,
    "SEP": 6.1,
    "OCT": 6.02,
    "NOV": 5.89,
    "DEC": 5.36
  },
  {
    "LONG": -38.46,
    "LAT": -3.89,
    "CIDADE": "Eusébio",
    "UF": "CEARÁ",
    "anual": 5.73,
    "JAN": 5.56,
    "FEB": 5.72,
    "MAR": 5.6,
    "APR": 4.97,
    "MAY": 5.35,
    "JUN": 5.41,
    "JUL": 5.61,
    "AUG": 6.06,
    "SEP": 6.2,
    "OCT": 6.29,
    "NOV": 6.22,
    "DEC": 5.79
  },
  {
    "LONG": -38.39,
    "LAT": -3.9,
    "CIDADE": "Aquiraz",
    "UF": "CEARÁ",
    "anual": 5.73,
    "JAN": 5.65,
    "FEB": 5.8,
    "MAR": 5.66,
    "APR": 5.08,
    "MAY": 5.43,
    "JUN": 5.43,
    "JUL": 5.57,
    "AUG": 5.89,
    "SEP": 6,
    "OCT": 6.15,
    "NOV": 6.22,
    "DEC": 5.89
  },
  {
    "LONG": -40.83,
    "LAT": -3.77,
    "CIDADE": "Frecheirinha",
    "UF": "CEARÁ",
    "anual": 5.39,
    "JAN": 4.74,
    "FEB": 5.03,
    "MAR": 5.03,
    "APR": 4.77,
    "MAY": 4.93,
    "JUN": 5.08,
    "JUL": 5.41,
    "AUG": 6.06,
    "SEP": 6.38,
    "OCT": 6.19,
    "NOV": 5.89,
    "DEC": 5.15
  },
  {
    "LONG": -40.26,
    "LAT": -3.8,
    "CIDADE": "Forquilha",
    "UF": "CEARÁ",
    "anual": 5.54,
    "JAN": 5.07,
    "FEB": 5.23,
    "MAR": 5.24,
    "APR": 4.93,
    "MAY": 5.15,
    "JUN": 5.08,
    "JUL": 5.39,
    "AUG": 6.12,
    "SEP": 6.51,
    "OCT": 6.31,
    "NOV": 6.03,
    "DEC": 5.37
  },
  {
    "LONG": -39.27,
    "LAT": -3.79,
    "CIDADE": "Pentecoste",
    "UF": "CEARÁ",
    "anual": 5.61,
    "JAN": 5.17,
    "FEB": 5.37,
    "MAR": 5.29,
    "APR": 4.92,
    "MAY": 5.36,
    "JUN": 5.25,
    "JUL": 5.5,
    "AUG": 6.22,
    "SEP": 6.47,
    "OCT": 6.27,
    "NOV": 6.06,
    "DEC": 5.45
  },
  {
    "LONG": -40.99,
    "LAT": "#VALOR!",
    "CIDADE": "Tianguá",
    "UF": "CEARÁ",
    "anual": 5.48,
    "JAN": 4.92,
    "FEB": 4.95,
    "MAR": 4.99,
    "APR": 4.7,
    "MAY": 4.96,
    "JUN": 5.15,
    "JUL": 5.51,
    "AUG": 6.22,
    "SEP": 6.54,
    "OCT": 6.36,
    "NOV": 6.11,
    "DEC": 5.38
  },
  {
    "LONG": -40.35,
    "LAT": -3.69,
    "CIDADE": "Sobral",
    "UF": "CEARÁ",
    "anual": 5.49,
    "JAN": 5.06,
    "FEB": 5.23,
    "MAR": 5.25,
    "APR": 4.96,
    "MAY": 5.12,
    "JUN": 5.06,
    "JUL": 5.34,
    "AUG": 5.98,
    "SEP": 6.41,
    "OCT": 6.23,
    "NOV": 5.98,
    "DEC": 5.29
  },
  {
    "LONG": -39.78,
    "LAT": -3.75,
    "CIDADE": "Irauçuba",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 5.07,
    "FEB": 5.24,
    "MAR": 5.18,
    "APR": 4.83,
    "MAY": 5.09,
    "JUN": 5.16,
    "JUL": 5.47,
    "AUG": 6.2,
    "SEP": 6.54,
    "OCT": 6.35,
    "NOV": 6.08,
    "DEC": 5.47
  },
  {
    "LONG": -39.59,
    "LAT": -3.68,
    "CIDADE": "Itapajé",
    "UF": "CEARÁ",
    "anual": 5.42,
    "JAN": 4.95,
    "FEB": 5.12,
    "MAR": 5.11,
    "APR": 4.72,
    "MAY": 5.05,
    "JUN": 5.02,
    "JUL": 5.33,
    "AUG": 6.1,
    "SEP": 6.37,
    "OCT": 6.12,
    "NOV": 5.85,
    "DEC": 5.29
  },
  {
    "LONG": -39.35,
    "LAT": -3.68,
    "CIDADE": "Umirim",
    "UF": "CEARÁ",
    "anual": 5.52,
    "JAN": 5.04,
    "FEB": 5.23,
    "MAR": 5.17,
    "APR": 4.88,
    "MAY": 5.28,
    "JUN": 5.16,
    "JUL": 5.46,
    "AUG": 6.18,
    "SEP": 6.4,
    "OCT": 6.18,
    "NOV": 5.92,
    "DEC": 5.31
  },
  {
    "LONG": -39.24,
    "LAT": -3.67,
    "CIDADE": "São Luís do Curu",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 5.15,
    "FEB": 5.27,
    "MAR": 5.2,
    "APR": 4.89,
    "MAY": 5.33,
    "JUN": 5.22,
    "JUL": 5.51,
    "AUG": 6.19,
    "SEP": 6.37,
    "OCT": 6.19,
    "NOV": 6,
    "DEC": 5.37
  },
  {
    "LONG": -38.66,
    "LAT": -3.73,
    "CIDADE": "Caucaia",
    "UF": "CEARÁ",
    "anual": 5.7,
    "JAN": 5.6,
    "FEB": 5.61,
    "MAR": 5.52,
    "APR": 4.86,
    "MAY": 5.29,
    "JUN": 5.36,
    "JUL": 5.63,
    "AUG": 6.12,
    "SEP": 6.16,
    "OCT": 6.24,
    "NOV": 6.19,
    "DEC": 5.83
  },
  {
    "LONG": -38.54,
    "LAT": -3.72,
    "CIDADE": "Fortaleza",
    "UF": "CEARÁ",
    "anual": 5.78,
    "JAN": 5.72,
    "FEB": 5.72,
    "MAR": 5.64,
    "APR": 4.97,
    "MAY": 5.43,
    "JUN": 5.47,
    "JUL": 5.69,
    "AUG": 6.04,
    "SEP": 6.1,
    "OCT": 6.27,
    "NOV": 6.29,
    "DEC": 5.97
  },
  {
    "LONG": -41.09,
    "LAT": -3.57,
    "CIDADE": "Viçosa do Ceará",
    "UF": "CEARÁ",
    "anual": 5.49,
    "JAN": 4.85,
    "FEB": 5.02,
    "MAR": 5.01,
    "APR": 4.78,
    "MAY": 5.02,
    "JUN": 5.18,
    "JUL": 5.46,
    "AUG": 6.21,
    "SEP": 6.53,
    "OCT": 6.36,
    "NOV": 6.14,
    "DEC": 5.33
  },
  {
    "LONG": -40.65,
    "LAT": -3.55,
    "CIDADE": "Coreaú",
    "UF": "CEARÁ",
    "anual": 5.48,
    "JAN": 4.85,
    "FEB": 5.1,
    "MAR": 5.11,
    "APR": 4.88,
    "MAY": 5.16,
    "JUN": 5.18,
    "JUL": 5.46,
    "AUG": 6.11,
    "SEP": 6.47,
    "OCT": 6.3,
    "NOV": 5.95,
    "DEC": 5.15
  },
  {
    "LONG": -40.54,
    "LAT": -3.58,
    "CIDADE": "Alcântaras",
    "UF": "CEARÁ",
    "anual": 5.5,
    "JAN": 4.84,
    "FEB": 5.06,
    "MAR": 4.99,
    "APR": 4.75,
    "MAY": 5.08,
    "JUN": 5.22,
    "JUL": 5.49,
    "AUG": 6.19,
    "SEP": 6.59,
    "OCT": 6.4,
    "NOV": 6.1,
    "DEC": 5.34
  },
  {
    "LONG": -39.97,
    "LAT": -3.57,
    "CIDADE": "Miraíma",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 5.04,
    "FEB": 5.19,
    "MAR": 5.1,
    "APR": 4.88,
    "MAY": 5.26,
    "JUN": 5.25,
    "JUL": 5.49,
    "AUG": 6.27,
    "SEP": 6.55,
    "OCT": 6.33,
    "NOV": 6.04,
    "DEC": 5.32
  },
  {
    "LONG": -3.95,
    "LAT": -3.62,
    "CIDADE": "Uruburetama",
    "UF": "CEARÁ",
    "anual": 5.36,
    "JAN": 4.77,
    "FEB": 4.93,
    "MAR": 4.88,
    "APR": 4.57,
    "MAY": 4.98,
    "JUN": 5.11,
    "JUL": 5.44,
    "AUG": 6.23,
    "SEP": 6.42,
    "OCT": 6.13,
    "NOV": 5.8,
    "DEC": 5.08
  },
  {
    "LONG": "#VALOR!",
    "LAT": -3.58,
    "CIDADE": "Tururu",
    "UF": "CEARÁ",
    "anual": 5.34,
    "JAN": 4.73,
    "FEB": 4.99,
    "MAR": 4.97,
    "APR": 4.67,
    "MAY": 5.07,
    "JUN": 5.12,
    "JUL": 5.4,
    "AUG": 6.1,
    "SEP": 6.3,
    "OCT": 5.97,
    "NOV": 5.73,
    "DEC": 5
  },
  {
    "LONG": -3.9,
    "LAT": -3.61,
    "CIDADE": "São Gonçalo do Amarante",
    "UF": "CEARÁ",
    "anual": 5.77,
    "JAN": 5.59,
    "FEB": 5.62,
    "MAR": 5.47,
    "APR": 4.8,
    "MAY": 5.36,
    "JUN": 5.42,
    "JUL": 5.72,
    "AUG": 6.29,
    "SEP": 6.37,
    "OCT": 6.33,
    "NOV": 6.37,
    "DEC": 5.88
  },
  {
    "LONG": -40.68,
    "LAT": -3.47,
    "CIDADE": "Moraújo",
    "UF": "CEARÁ",
    "anual": 5.55,
    "JAN": 5.05,
    "FEB": 5.2,
    "MAR": 5.11,
    "APR": 4.88,
    "MAY": 5.19,
    "JUN": 5.28,
    "JUL": 5.5,
    "AUG": 6.14,
    "SEP": 6.48,
    "OCT": 6.34,
    "NOV": 6.04,
    "DEC": 5.44
  },
  {
    "LONG": -40.45,
    "LAT": -3.54,
    "CIDADE": "Meruoca",
    "UF": "CEARÁ",
    "anual": 5.61,
    "JAN": 4.99,
    "FEB": 5.21,
    "MAR": 5.19,
    "APR": 4.87,
    "MAY": 5.25,
    "JUN": 5.27,
    "JUL": 5.6,
    "AUG": 6.27,
    "SEP": 6.63,
    "OCT": 6.46,
    "NOV": 6.16,
    "DEC": 5.44
  },
  {
    "LONG": -40.34,
    "LAT": -0.35,
    "CIDADE": "Massapê",
    "UF": "CEARÁ",
    "anual": 5.52,
    "JAN": 4.82,
    "FEB": 5.14,
    "MAR": 5.19,
    "APR": 4.92,
    "MAY": 5.3,
    "JUN": 5.22,
    "JUL": 5.48,
    "AUG": 6.15,
    "SEP": 6.48,
    "OCT": 6.33,
    "NOV": 5.94,
    "DEC": 5.25
  },
  {
    "LONG": -40.21,
    "LAT": -3.46,
    "CIDADE": "Santana do Acaraú",
    "UF": "CEARÁ",
    "anual": 5.51,
    "JAN": 4.92,
    "FEB": 5.09,
    "MAR": 5.15,
    "APR": 4.92,
    "MAY": 5.25,
    "JUN": 5.16,
    "JUL": 5.42,
    "AUG": 6.15,
    "SEP": 6.47,
    "OCT": 6.3,
    "NOV": 5.99,
    "DEC": 5.31
  },
  {
    "LONG": -39.58,
    "LAT": -3.5,
    "CIDADE": "Itapipoca",
    "UF": "CEARÁ",
    "anual": 5.57,
    "JAN": 5.04,
    "FEB": 5.16,
    "MAR": 5.12,
    "APR": 4.76,
    "MAY": 5.32,
    "JUN": 5.44,
    "JUL": 5.66,
    "AUG": 6.26,
    "SEP": 6.47,
    "OCT": 6.25,
    "NOV": 6.03,
    "DEC": 5.3
  },
  {
    "LONG": -40.47,
    "LAT": -3.35,
    "CIDADE": "Senador Sá",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 4.96,
    "FEB": 5.13,
    "MAR": 5.15,
    "APR": 4.95,
    "MAY": 5.36,
    "JUN": 5.34,
    "JUL": 5.59,
    "AUG": 6.18,
    "SEP": 6.51,
    "OCT": 6.38,
    "NOV": 6.03,
    "DEC": 5.32
  },
  {
    "LONG": -39.83,
    "LAT": -3.36,
    "CIDADE": "Amontada",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 5.18,
    "FEB": 5.29,
    "MAR": 5.2,
    "APR": 4.81,
    "MAY": 5.25,
    "JUN": 5.26,
    "JUL": 5.41,
    "AUG": 6.15,
    "SEP": 6.46,
    "OCT": 6.31,
    "NOV": 6.14,
    "DEC": 5.51
  },
  {
    "LONG": -39.15,
    "LAT": -3.44,
    "CIDADE": "Paraipaba",
    "UF": "CEARÁ",
    "anual": 5.68,
    "JAN": 5.59,
    "FEB": 5.67,
    "MAR": 5.46,
    "APR": 4.81,
    "MAY": 5.28,
    "JUN": 5.36,
    "JUL": 5.57,
    "AUG": 6,
    "SEP": 6.16,
    "OCT": 6.14,
    "NOV": 6.24,
    "DEC": 5.91
  },
  {
    "LONG": -39.03,
    "LAT": -3.41,
    "CIDADE": "Paracuru",
    "UF": "CEARÁ",
    "anual": 5.69,
    "JAN": 5.64,
    "FEB": 5.75,
    "MAR": 5.53,
    "APR": 4.86,
    "MAY": 5.37,
    "JUN": 5.41,
    "JUL": 5.61,
    "AUG": 5.98,
    "SEP": 5.98,
    "OCT": 6.04,
    "NOV": 6.22,
    "DEC": 5.91
  },
  {
    "LONG": -40.56,
    "LAT": -3.31,
    "CIDADE": "Uruoca",
    "UF": "CEARÁ",
    "anual": 5.56,
    "JAN": 4.94,
    "FEB": 5.04,
    "MAR": 5.09,
    "APR": 4.95,
    "MAY": 5.31,
    "JUN": 5.4,
    "JUL": 5.6,
    "AUG": 6.14,
    "SEP": 6.52,
    "OCT": 6.35,
    "NOV": 6.01,
    "DEC": 5.36
  },
  {
    "LONG": -39.27,
    "LAT": -3.27,
    "CIDADE": "Trairi",
    "UF": "CEARÁ",
    "anual": 5.59,
    "JAN": 5.5,
    "FEB": 5.6,
    "MAR": 5.44,
    "APR": 4.78,
    "MAY": 5.25,
    "JUN": 5.3,
    "JUL": 5.51,
    "AUG": 5.88,
    "SEP": 5.98,
    "OCT": 5.96,
    "NOV": 6.14,
    "DEC": 5.78
  },
  {
    "LONG": -4.07,
    "LAT": -3.22,
    "CIDADE": "Martinópole",
    "UF": "CEARÁ",
    "anual": 5.54,
    "JAN": 4.98,
    "FEB": 4.97,
    "MAR": 5.01,
    "APR": 4.83,
    "MAY": 5.28,
    "JUN": 5.42,
    "JUL": 5.52,
    "AUG": 6.15,
    "SEP": 6.55,
    "OCT": 6.39,
    "NOV": 6.03,
    "DEC": 5.3
  },
  {
    "LONG": -40.12,
    "LAT": -3.23,
    "CIDADE": "Morrinhos",
    "UF": "CEARÁ",
    "anual": 5.5,
    "JAN": 5.05,
    "FEB": 5.08,
    "MAR": 5.01,
    "APR": 4.69,
    "MAY": 5.25,
    "JUN": 5.3,
    "JUL": 5.47,
    "AUG": 6.13,
    "SEP": 6.42,
    "OCT": 6.2,
    "NOV": 6.03,
    "DEC": 5.41
  },
  {
    "LONG": -4.08,
    "LAT": -3.12,
    "CIDADE": "Granja",
    "UF": "CEARÁ",
    "anual": 5.46,
    "JAN": 4.94,
    "FEB": 4.93,
    "MAR": 4.91,
    "APR": 4.81,
    "MAY": 5.19,
    "JUN": 5.3,
    "JUL": 5.43,
    "AUG": 6.04,
    "SEP": 6.45,
    "OCT": 6.35,
    "NOV": 5.94,
    "DEC": 5.23
  },
  {
    "LONG": -40.15,
    "LAT": -3.12,
    "CIDADE": "Marco",
    "UF": "CEARÁ",
    "anual": 5.53,
    "JAN": 5.11,
    "FEB": 5.11,
    "MAR": 4.98,
    "APR": 4.63,
    "MAY": 5.23,
    "JUN": 5.42,
    "JUL": 5.52,
    "AUG": 6.14,
    "SEP": 6.38,
    "OCT": 6.18,
    "NOV": 6.07,
    "DEC": 5.55
  },
  {
    "LONG": -41.24,
    "LAT": -3.04,
    "CIDADE": "Chaval",
    "UF": "CEARÁ",
    "anual": 5.57,
    "JAN": 5.22,
    "FEB": 5.11,
    "MAR": 4.97,
    "APR": 4.82,
    "MAY": 5.17,
    "JUN": 5.42,
    "JUL": 5.65,
    "AUG": 6.19,
    "SEP": 6.3,
    "OCT": 6.16,
    "NOV": 6.17,
    "DEC": 5.67
  },
  {
    "LONG": -41.14,
    "LAT": -3.02,
    "CIDADE": "Barroquinha",
    "UF": "CEARÁ",
    "anual": 5.61,
    "JAN": 5.21,
    "FEB": 5.07,
    "MAR": 4.92,
    "APR": 4.84,
    "MAY": 5.14,
    "JUN": 5.42,
    "JUL": 5.69,
    "AUG": 6.26,
    "SEP": 6.41,
    "OCT": 6.34,
    "NOV": 6.24,
    "DEC": 5.71
  },
  {
    "LONG": -40.17,
    "LAT": -3.05,
    "CIDADE": "Bela Cruz",
    "UF": "CEARÁ",
    "anual": 5.57,
    "JAN": 5.26,
    "FEB": 5.19,
    "MAR": 5.04,
    "APR": 4.6,
    "MAY": 5.25,
    "JUN": 5.39,
    "JUL": 5.63,
    "AUG": 6.13,
    "SEP": 6.32,
    "OCT": 6.14,
    "NOV": 6.16,
    "DEC": 5.69
  },
  {
    "LONG": -4.08,
    "LAT": -2.9,
    "CIDADE": "Camocim",
    "UF": "CEARÁ",
    "anual": 5.53,
    "JAN": 5.27,
    "FEB": 5.15,
    "MAR": 4.91,
    "APR": 4.72,
    "MAY": 5.11,
    "JUN": 5.33,
    "JUL": 5.56,
    "AUG": 6.02,
    "SEP": 6.22,
    "OCT": 6.14,
    "NOV": 6.14,
    "DEC": 5.77
  },
  {
    "LONG": -4.04,
    "LAT": -0.29,
    "CIDADE": "Jijoca de Jericoacoara",
    "UF": "CEARÁ",
    "anual": 5.46,
    "JAN": 5.16,
    "FEB": 5.15,
    "MAR": 4.94,
    "APR": 4.57,
    "MAY": 5.11,
    "JUN": 5.26,
    "JUL": 5.5,
    "AUG": 6,
    "SEP": 6.14,
    "OCT": 6,
    "NOV": 6.06,
    "DEC": 5.59
  },
  {
    "LONG": -40.18,
    "LAT": -2.92,
    "CIDADE": "Cruz",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 5.38,
    "FEB": 5.34,
    "MAR": 5.14,
    "APR": 4.62,
    "MAY": 5.22,
    "JUN": 5.35,
    "JUL": 5.6,
    "AUG": 6.07,
    "SEP": 6.15,
    "OCT": 6.02,
    "NOV": 6.22,
    "DEC": 5.81
  },
  {
    "LONG": -40.12,
    "LAT": -2.89,
    "CIDADE": "Acaraú",
    "UF": "CEARÁ",
    "anual": 5.58,
    "JAN": 5.38,
    "FEB": 5.34,
    "MAR": 5.14,
    "APR": 4.62,
    "MAY": 5.22,
    "JUN": 5.35,
    "JUL": 5.6,
    "AUG": 6.07,
    "SEP": 6.15,
    "OCT": 6.02,
    "NOV": 6.22,
    "DEC": 5.81
  },
  {
    "LONG": -3.99,
    "LAT": -2.93,
    "CIDADE": "Itarema",
    "UF": "CEARÁ",
    "anual": 5.63,
    "JAN": 5.53,
    "FEB": 5.5,
    "MAR": 5.36,
    "APR": 4.77,
    "MAY": 5.33,
    "JUN": 5.43,
    "JUL": 5.66,
    "AUG": 5.97,
    "SEP": 5.93,
    "OCT": 5.95,
    "NOV": 6.21,
    "DEC": 5.92
  },
  {
    "LONG": -47.93,
    "LAT": -15.78,
    "CIDADE": "Brasília",
    "UF": "DISTRITO FEDERAL",
    "anual": 5.43,
    "JAN": 4.9,
    "FEB": 5.41,
    "MAR": 5.07,
    "APR": 5.39,
    "MAY": 5.5,
    "JUN": 5.57,
    "JUL": 5.85,
    "AUG": 6.57,
    "SEP": 5.93,
    "OCT": 5.4,
    "NOV": 4.68,
    "DEC": 4.89
  },
  {
    "LONG": -41.57,
    "LAT": -21.15,
    "CIDADE": "Apiacá",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.11,
    "JAN": 5.58,
    "FEB": 6.11,
    "MAR": 5.39,
    "APR": 5.11,
    "MAY": 4.68,
    "JUN": 4.52,
    "JUL": 4.56,
    "AUG": 5.21,
    "SEP": 5.21,
    "OCT": 5.05,
    "NOV": 4.64,
    "DEC": 5.27
  },
  {
    "LONG": -41.67,
    "LAT": -21.12,
    "CIDADE": "Bom Jesus do Norte",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.07,
    "JAN": 5.5,
    "FEB": 5.97,
    "MAR": 5.3,
    "APR": 5.06,
    "MAY": 4.67,
    "JUN": 4.57,
    "JUL": 4.61,
    "AUG": 5.24,
    "SEP": 5.22,
    "OCT": 4.98,
    "NOV": 4.6,
    "DEC": 5.17
  },
  {
    "LONG": -41.36,
    "LAT": -21.06,
    "CIDADE": "Mimoso do Sul",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.03,
    "JAN": 5.55,
    "FEB": 6,
    "MAR": 5.25,
    "APR": 5.08,
    "MAY": 4.6,
    "JUN": 4.56,
    "JUL": 4.5,
    "AUG": 5.15,
    "SEP": 5.16,
    "OCT": 4.94,
    "NOV": 4.57,
    "DEC": 5.05
  },
  {
    "LONG": -41.05,
    "LAT": -21.1,
    "CIDADE": "Presidente Kennedy",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.15,
    "JAN": 5.7,
    "FEB": 6.31,
    "MAR": 5.44,
    "APR": 5.13,
    "MAY": 4.66,
    "JUN": 4.59,
    "JUL": 4.54,
    "AUG": 5.22,
    "SEP": 5.18,
    "OCT": 5.05,
    "NOV": 4.72,
    "DEC": 5.24
  },
  {
    "LONG": -41.66,
    "LAT": -21.03,
    "CIDADE": "São José do Calçado",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.99,
    "JAN": 5.36,
    "FEB": 5.85,
    "MAR": 5.18,
    "APR": 5,
    "MAY": 4.56,
    "JUN": 4.55,
    "JUL": 4.58,
    "AUG": 5.18,
    "SEP": 5.21,
    "OCT": 4.9,
    "NOV": 4.51,
    "DEC": 5.04
  },
  {
    "LONG": -41.35,
    "LAT": -20.95,
    "CIDADE": "Muqui",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.98,
    "JAN": 5.44,
    "FEB": 5.98,
    "MAR": 5.22,
    "APR": 4.96,
    "MAY": 4.56,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 5.12,
    "SEP": 5.18,
    "OCT": 4.87,
    "NOV": 4.48,
    "DEC": 5.03
  },
  {
    "LONG": -4.08,
    "LAT": "#VALOR!",
    "CIDADE": "Itapemirim",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.21,
    "JAN": 5.88,
    "FEB": 6.32,
    "MAR": 5.52,
    "APR": 5.15,
    "MAY": 4.63,
    "JUN": 4.6,
    "JUL": 4.57,
    "AUG": 5.29,
    "SEP": 5.29,
    "OCT": 5.17,
    "NOV": 4.77,
    "DEC": 5.36
  },
  {
    "LONG": -40.84,
    "LAT": -21.04,
    "CIDADE": "Marataízes",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.21,
    "JAN": 5.88,
    "FEB": 6.32,
    "MAR": 5.52,
    "APR": 5.15,
    "MAY": 4.63,
    "JUN": 4.6,
    "JUL": 4.57,
    "AUG": 5.29,
    "SEP": 5.29,
    "OCT": 5.17,
    "NOV": 4.77,
    "DEC": 5.36
  },
  {
    "LONG": -41.2,
    "LAT": -20.91,
    "CIDADE": "Atilio Vivacqua",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.14,
    "JAN": 5.62,
    "FEB": 6.19,
    "MAR": 5.43,
    "APR": 5.13,
    "MAY": 4.69,
    "JUN": 4.6,
    "JUL": 4.59,
    "AUG": 5.2,
    "SEP": 5.26,
    "OCT": 5.04,
    "NOV": 4.69,
    "DEC": 5.25
  },
  {
    "LONG": -40.94,
    "LAT": -20.86,
    "CIDADE": "Rio Novo do Sul",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.06,
    "JAN": 5.59,
    "FEB": 6.12,
    "MAR": 5.34,
    "APR": 5.03,
    "MAY": 4.59,
    "JUN": 4.57,
    "JUL": 4.49,
    "AUG": 5.13,
    "SEP": 5.14,
    "OCT": 4.95,
    "NOV": 4.56,
    "DEC": 5.16
  },
  {
    "LONG": -41.67,
    "LAT": -20.77,
    "CIDADE": "Guaçuí",
    "UF": "ESPÍRITO SANTO",
    "anual": 5,
    "JAN": 5.4,
    "FEB": 5.9,
    "MAR": 5.12,
    "APR": 4.91,
    "MAY": 4.57,
    "JUN": 4.54,
    "JUL": 4.62,
    "AUG": 5.24,
    "SEP": 5.26,
    "OCT": 4.94,
    "NOV": 4.48,
    "DEC": 4.96
  },
  {
    "LONG": -41.54,
    "LAT": -20.76,
    "CIDADE": "Alegre",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.09,
    "JAN": 5.52,
    "FEB": 6.05,
    "MAR": 5.3,
    "APR": 5.02,
    "MAY": 4.65,
    "JUN": 4.61,
    "JUL": 4.64,
    "AUG": 5.25,
    "SEP": 5.32,
    "OCT": 5.02,
    "NOV": 4.62,
    "DEC": 5.1
  },
  {
    "LONG": -41.4,
    "LAT": -20.8,
    "CIDADE": "Jerônimo Monteiro",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.15,
    "JAN": 5.56,
    "FEB": 6.08,
    "MAR": 5.41,
    "APR": 5.16,
    "MAY": 4.77,
    "JUN": 4.67,
    "JUL": 4.67,
    "AUG": 5.24,
    "SEP": 5.32,
    "OCT": 5.06,
    "NOV": 4.69,
    "DEC": 5.22
  },
  {
    "LONG": -41.12,
    "LAT": -20.85,
    "CIDADE": "Cachoeiro de Itapemirim",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.21,
    "JAN": 5.72,
    "FEB": 6.21,
    "MAR": 5.51,
    "APR": 5.21,
    "MAY": 4.77,
    "JUN": 4.7,
    "JUL": 4.66,
    "AUG": 5.26,
    "SEP": 5.32,
    "OCT": 5.12,
    "NOV": 4.76,
    "DEC": 5.32
  },
  {
    "LONG": -40.81,
    "LAT": -20.79,
    "CIDADE": "Iconha",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.85,
    "JAN": 5.39,
    "FEB": 5.93,
    "MAR": 5.1,
    "APR": 4.79,
    "MAY": 4.41,
    "JUN": 4.38,
    "JUL": 4.35,
    "AUG": 4.94,
    "SEP": 4.96,
    "OCT": 4.71,
    "NOV": 4.34,
    "DEC": 4.89
  },
  {
    "LONG": -40.73,
    "LAT": -20.83,
    "CIDADE": "Piúma",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.05,
    "JAN": 5.61,
    "FEB": 6.2,
    "MAR": 5.4,
    "APR": 5.05,
    "MAY": 4.57,
    "JUN": 4.53,
    "JUL": 4.48,
    "AUG": 5.09,
    "SEP": 5.11,
    "OCT": 4.93,
    "NOV": 4.56,
    "DEC": 5.12
  },
  {
    "LONG": -40.64,
    "LAT": -2.08,
    "CIDADE": "Anchieta",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.21,
    "JAN": 5.76,
    "FEB": 6.33,
    "MAR": 5.58,
    "APR": 5.28,
    "MAY": 4.71,
    "JUN": 4.62,
    "JUL": 4.61,
    "AUG": 5.23,
    "SEP": 5.25,
    "OCT": 5.12,
    "NOV": 4.76,
    "DEC": 5.31
  },
  {
    "LONG": -41.84,
    "LAT": -20.69,
    "CIDADE": "Dores do Rio Preto",
    "UF": "ESPÍRITO SANTO",
    "anual": 5,
    "JAN": 5.32,
    "FEB": 5.77,
    "MAR": 5.04,
    "APR": 4.93,
    "MAY": 4.57,
    "JUN": 4.65,
    "JUL": 4.71,
    "AUG": 5.36,
    "SEP": 5.27,
    "OCT": 4.95,
    "NOV": 4.44,
    "DEC": 5.01
  },
  {
    "LONG": -41.01,
    "LAT": -20.67,
    "CIDADE": "Vargem Alta",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.01,
    "JAN": 5.51,
    "FEB": 5.87,
    "MAR": 5.21,
    "APR": 5.03,
    "MAY": 4.62,
    "JUN": 4.58,
    "JUL": 4.51,
    "AUG": 5.14,
    "SEP": 5.19,
    "OCT": 4.89,
    "NOV": 4.5,
    "DEC": 5.08
  },
  {
    "LONG": -40.51,
    "LAT": -20.68,
    "CIDADE": "Guarapari",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.14,
    "JAN": 5.78,
    "FEB": 6.26,
    "MAR": 5.48,
    "APR": 5.15,
    "MAY": 4.7,
    "JUN": 4.57,
    "JUL": 4.54,
    "AUG": 5.17,
    "SEP": 5.2,
    "OCT": 4.95,
    "NOV": 4.65,
    "DEC": 5.24
  },
  {
    "LONG": -41.68,
    "LAT": -20.62,
    "CIDADE": "Divino de São Lourenço",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.98,
    "JAN": 5.33,
    "FEB": 5.88,
    "MAR": 5.11,
    "APR": 4.87,
    "MAY": 4.49,
    "JUN": 4.58,
    "JUL": 4.66,
    "AUG": 5.28,
    "SEP": 5.27,
    "OCT": 4.9,
    "NOV": 4.46,
    "DEC": 4.94
  },
  {
    "LONG": -41.2,
    "LAT": -20.6,
    "CIDADE": "Castelo",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.09,
    "JAN": 5.5,
    "FEB": 5.98,
    "MAR": 5.33,
    "APR": 5.07,
    "MAY": 4.69,
    "JUN": 4.63,
    "JUL": 4.66,
    "AUG": 5.25,
    "SEP": 5.28,
    "OCT": 4.98,
    "NOV": 4.63,
    "DEC": 5.12
  },
  {
    "LONG": -40.75,
    "LAT": -20.64,
    "CIDADE": "Alfredo Chaves",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.89,
    "JAN": 5.37,
    "FEB": 5.91,
    "MAR": 5.13,
    "APR": 4.84,
    "MAY": 4.44,
    "JUN": 4.44,
    "JUL": 4.41,
    "AUG": 4.97,
    "SEP": 5.03,
    "OCT": 4.75,
    "NOV": 4.42,
    "DEC": 4.95
  },
  {
    "LONG": -41.67,
    "LAT": -20.55,
    "CIDADE": "Ibitirama",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.93,
    "JAN": 5.22,
    "FEB": 5.82,
    "MAR": 5.06,
    "APR": 4.83,
    "MAY": 4.48,
    "JUN": 4.54,
    "JUL": 4.62,
    "AUG": 5.22,
    "SEP": 5.23,
    "OCT": 4.83,
    "NOV": 4.36,
    "DEC": 4.96
  },
  {
    "LONG": -4.14,
    "LAT": -20.47,
    "CIDADE": "Muniz Freire",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.06,
    "JAN": 5.45,
    "FEB": 5.93,
    "MAR": 5.28,
    "APR": 5.1,
    "MAY": 4.66,
    "JUN": 4.67,
    "JUL": 4.64,
    "AUG": 5.27,
    "SEP": 5.22,
    "OCT": 4.92,
    "NOV": 4.53,
    "DEC": 5.08
  },
  {
    "LONG": -41.64,
    "LAT": -20.35,
    "CIDADE": "Irupi",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.91,
    "JAN": 5.21,
    "FEB": 5.78,
    "MAR": 5.1,
    "APR": 4.89,
    "MAY": 4.5,
    "JUN": 4.52,
    "JUL": 4.57,
    "AUG": 5.14,
    "SEP": 5.12,
    "OCT": 4.79,
    "NOV": 4.38,
    "DEC": 4.98
  },
  {
    "LONG": -41.53,
    "LAT": -20.35,
    "CIDADE": "Iúna",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.31,
    "FEB": 5.86,
    "MAR": 5.16,
    "APR": 4.98,
    "MAY": 4.55,
    "JUN": 4.55,
    "JUL": 4.54,
    "AUG": 5.17,
    "SEP": 5.12,
    "OCT": 4.85,
    "NOV": 4.46,
    "DEC": 5.06
  },
  {
    "LONG": -41.24,
    "LAT": -20.36,
    "CIDADE": "Conceição do Castelo",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.94,
    "JAN": 5.32,
    "FEB": 5.84,
    "MAR": 5.14,
    "APR": 4.88,
    "MAY": 4.47,
    "JUN": 4.53,
    "JUL": 4.57,
    "AUG": 5.13,
    "SEP": 5.17,
    "OCT": 4.83,
    "NOV": 4.46,
    "DEC": 4.97
  },
  {
    "LONG": -40.66,
    "LAT": -20.36,
    "CIDADE": "Domingos Martins",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.84,
    "JAN": 5.28,
    "FEB": 5.86,
    "MAR": 5.2,
    "APR": 4.83,
    "MAY": 4.45,
    "JUN": 4.36,
    "JUL": 4.34,
    "AUG": 4.84,
    "SEP": 4.96,
    "OCT": 4.65,
    "NOV": 4.35,
    "DEC": 4.94
  },
  {
    "LONG": -40.68,
    "LAT": -20.41,
    "CIDADE": "Marechal Floriano",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.84,
    "JAN": 5.28,
    "FEB": 5.86,
    "MAR": 5.2,
    "APR": 4.83,
    "MAY": 4.45,
    "JUN": 4.36,
    "JUL": 4.34,
    "AUG": 4.84,
    "SEP": 4.96,
    "OCT": 4.65,
    "NOV": 4.35,
    "DEC": 4.94
  },
  {
    "LONG": -40.49,
    "LAT": -2.04,
    "CIDADE": "Viana",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.04,
    "JAN": 5.52,
    "FEB": 6.15,
    "MAR": 5.47,
    "APR": 5.07,
    "MAY": 4.62,
    "JUN": 4.51,
    "JUL": 4.46,
    "AUG": 5.02,
    "SEP": 5.09,
    "OCT": 4.8,
    "NOV": 4.58,
    "DEC": 5.19
  },
  {
    "LONG": -41.14,
    "LAT": -20.33,
    "CIDADE": "Venda Nova do Imigrante",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.86,
    "JAN": 5.27,
    "FEB": 5.79,
    "MAR": 5.1,
    "APR": 4.88,
    "MAY": 4.44,
    "JUN": 4.41,
    "JUL": 4.45,
    "AUG": 5.02,
    "SEP": 4.98,
    "OCT": 4.73,
    "NOV": 4.38,
    "DEC": 4.86
  },
  {
    "LONG": -40.42,
    "LAT": -20.26,
    "CIDADE": "Cariacica",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.95,
    "JAN": 5.45,
    "FEB": 6.05,
    "MAR": 5.4,
    "APR": 4.97,
    "MAY": 4.54,
    "JUN": 4.43,
    "JUL": 4.42,
    "AUG": 4.89,
    "SEP": 4.98,
    "OCT": 4.68,
    "NOV": 4.49,
    "DEC": 5.1
  },
  {
    "LONG": -40.31,
    "LAT": -20.32,
    "CIDADE": "Vitória",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.13,
    "JAN": 5.58,
    "FEB": 6.23,
    "MAR": 5.57,
    "APR": 5.16,
    "MAY": 4.71,
    "JUN": 4.52,
    "JUL": 4.55,
    "AUG": 5.13,
    "SEP": 5.19,
    "OCT": 4.91,
    "NOV": 4.73,
    "DEC": 5.3
  },
  {
    "LONG": -40.29,
    "LAT": -20.34,
    "CIDADE": "Vila Velha",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.2,
    "JAN": 5.69,
    "FEB": 6.3,
    "MAR": 5.62,
    "APR": 5.24,
    "MAY": 4.75,
    "JUN": 4.54,
    "JUL": 4.59,
    "AUG": 5.19,
    "SEP": 5.26,
    "OCT": 5.04,
    "NOV": 4.85,
    "DEC": 5.37
  },
  {
    "LONG": -4.15,
    "LAT": -20.24,
    "CIDADE": "Ibatiba",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.88,
    "JAN": 5.25,
    "FEB": 5.7,
    "MAR": 5.09,
    "APR": 5.02,
    "MAY": 4.52,
    "JUN": 4.43,
    "JUL": 4.49,
    "AUG": 5.04,
    "SEP": 5.02,
    "OCT": 4.72,
    "NOV": 4.41,
    "DEC": 4.9
  },
  {
    "LONG": -41.29,
    "LAT": "#VALOR!",
    "CIDADE": "Brejetuba",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.79,
    "JAN": 5.1,
    "FEB": 5.67,
    "MAR": 5.02,
    "APR": 4.93,
    "MAY": 4.47,
    "JUN": 4.33,
    "JUL": 4.39,
    "AUG": 4.94,
    "SEP": 4.88,
    "OCT": 4.63,
    "NOV": 4.33,
    "DEC": 4.84
  },
  {
    "LONG": -41.13,
    "LAT": -20.08,
    "CIDADE": "Afonso Cláudio",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.93,
    "JAN": 5.33,
    "FEB": 5.87,
    "MAR": 5.21,
    "APR": 5.09,
    "MAY": 4.55,
    "JUN": 4.43,
    "JUL": 4.45,
    "AUG": 5.04,
    "SEP": 4.98,
    "OCT": 4.76,
    "NOV": 4.45,
    "DEC": 4.96
  },
  {
    "LONG": -40.53,
    "LAT": -20.1,
    "CIDADE": "Santa Leopoldina",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.75,
    "JAN": 5.17,
    "FEB": 5.69,
    "MAR": 5.15,
    "APR": 4.78,
    "MAY": 4.41,
    "JUN": 4.28,
    "JUL": 4.26,
    "AUG": 4.71,
    "SEP": 4.8,
    "OCT": 4.52,
    "NOV": 4.27,
    "DEC": 4.9
  },
  {
    "LONG": -40.31,
    "LAT": -20.12,
    "CIDADE": "Serra",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.07,
    "JAN": 5.51,
    "FEB": 6.13,
    "MAR": 5.56,
    "APR": 5.09,
    "MAY": 4.68,
    "JUN": 4.51,
    "JUL": 4.56,
    "AUG": 5,
    "SEP": 5.13,
    "OCT": 4.84,
    "NOV": 4.62,
    "DEC": 5.24
  },
  {
    "LONG": -40.74,
    "LAT": -20.03,
    "CIDADE": "Santa Maria de Jetibá",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.78,
    "JAN": 5.25,
    "FEB": 5.77,
    "MAR": 5.15,
    "APR": 4.87,
    "MAY": 4.44,
    "JUN": 4.29,
    "JUL": 4.28,
    "AUG": 4.78,
    "SEP": 4.81,
    "OCT": 4.51,
    "NOV": 4.33,
    "DEC": 4.84
  },
  {
    "LONG": -41.06,
    "LAT": -19.9,
    "CIDADE": "Laranja da Terra",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.01,
    "JAN": 5.46,
    "FEB": 5.91,
    "MAR": 5.4,
    "APR": 5.25,
    "MAY": 4.7,
    "JUN": 4.49,
    "JUL": 4.51,
    "AUG": 5.03,
    "SEP": 4.98,
    "OCT": 4.79,
    "NOV": 4.52,
    "DEC": 5.07
  },
  {
    "LONG": -40.88,
    "LAT": -19.88,
    "CIDADE": "Itarana",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.38,
    "FEB": 5.99,
    "MAR": 5.37,
    "APR": 5.14,
    "MAY": 4.67,
    "JUN": 4.38,
    "JUL": 4.48,
    "AUG": 4.95,
    "SEP": 4.98,
    "OCT": 4.75,
    "NOV": 4.53,
    "DEC": 4.99
  },
  {
    "LONG": -40.6,
    "LAT": -19.94,
    "CIDADE": "Santa Teresa",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.69,
    "JAN": 5.14,
    "FEB": 5.64,
    "MAR": 5.14,
    "APR": 4.71,
    "MAY": 4.35,
    "JUN": 4.2,
    "JUL": 4.17,
    "AUG": 4.63,
    "SEP": 4.72,
    "OCT": 4.43,
    "NOV": 4.26,
    "DEC": 4.85
  },
  {
    "LONG": -40.41,
    "LAT": -19.94,
    "CIDADE": "Fundão",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.75,
    "JAN": 5.23,
    "FEB": 5.74,
    "MAR": 5.22,
    "APR": 4.75,
    "MAY": 4.39,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 4.65,
    "SEP": 4.75,
    "OCT": 4.5,
    "NOV": 4.3,
    "DEC": 4.94
  },
  {
    "LONG": -40.86,
    "LAT": -19.8,
    "CIDADE": "Itaguaçu",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.99,
    "JAN": 5.43,
    "FEB": 6.02,
    "MAR": 5.41,
    "APR": 5.14,
    "MAY": 4.65,
    "JUN": 4.43,
    "JUL": 4.49,
    "AUG": 4.95,
    "SEP": 4.99,
    "OCT": 4.76,
    "NOV": 4.5,
    "DEC": 5.05
  },
  {
    "LONG": -40.39,
    "LAT": -19.76,
    "CIDADE": "João Neiva",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.91,
    "JAN": 5.39,
    "FEB": 5.87,
    "MAR": 5.42,
    "APR": 4.98,
    "MAY": 4.58,
    "JUN": 4.4,
    "JUL": 4.43,
    "AUG": 4.8,
    "SEP": 4.89,
    "OCT": 4.62,
    "NOV": 4.45,
    "DEC": 5.04
  },
  {
    "LONG": -40.37,
    "LAT": -19.84,
    "CIDADE": "Ibiraçu",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.91,
    "JAN": 5.39,
    "FEB": 5.87,
    "MAR": 5.42,
    "APR": 4.98,
    "MAY": 4.58,
    "JUN": 4.4,
    "JUL": 4.43,
    "AUG": 4.8,
    "SEP": 4.89,
    "OCT": 4.62,
    "NOV": 4.45,
    "DEC": 5.04
  },
  {
    "LONG": -40.28,
    "LAT": -19.82,
    "CIDADE": "Aracruz",
    "UF": "ESPÍRITO SANTO",
    "anual": 5,
    "JAN": 5.5,
    "FEB": 5.97,
    "MAR": 5.51,
    "APR": 5.04,
    "MAY": 4.65,
    "JUN": 4.48,
    "JUL": 4.48,
    "AUG": 4.9,
    "SEP": 4.96,
    "OCT": 4.74,
    "NOV": 4.57,
    "DEC": 5.15
  },
  {
    "LONG": -40.65,
    "LAT": -19.74,
    "CIDADE": "São Roque do Canaã",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.47,
    "FEB": 5.91,
    "MAR": 5.42,
    "APR": 5.08,
    "MAY": 4.66,
    "JUN": 4.45,
    "JUL": 4.45,
    "AUG": 4.86,
    "SEP": 4.98,
    "OCT": 4.72,
    "NOV": 4.53,
    "DEC": 5.12
  },
  {
    "LONG": -41.01,
    "LAT": -19.52,
    "CIDADE": "Baixo Guandu",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.12,
    "JAN": 5.62,
    "FEB": 6.04,
    "MAR": 5.49,
    "APR": 5.25,
    "MAY": 4.75,
    "JUN": 4.5,
    "JUL": 4.59,
    "AUG": 5.09,
    "SEP": 5.15,
    "OCT": 4.96,
    "NOV": 4.71,
    "DEC": 5.28
  },
  {
    "LONG": -40.63,
    "LAT": -19.54,
    "CIDADE": "Colatina",
    "UF": "ESPÍRITO SANTO",
    "anual": 5,
    "JAN": 5.52,
    "FEB": 5.92,
    "MAR": 5.48,
    "APR": 5.09,
    "MAY": 4.66,
    "JUN": 4.44,
    "JUL": 4.43,
    "AUG": 4.92,
    "SEP": 5.02,
    "OCT": 4.81,
    "NOV": 4.6,
    "DEC": 5.14
  },
  {
    "LONG": -4.05,
    "LAT": -19.41,
    "CIDADE": "Marilândia",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.89,
    "JAN": 5.43,
    "FEB": 5.81,
    "MAR": 5.35,
    "APR": 4.93,
    "MAY": 4.5,
    "JUN": 4.34,
    "JUL": 4.32,
    "AUG": 4.78,
    "SEP": 4.9,
    "OCT": 4.74,
    "NOV": 4.51,
    "DEC": 5.12
  },
  {
    "LONG": -40.06,
    "LAT": -19.4,
    "CIDADE": "Linhares",
    "UF": "ESPÍRITO SANTO",
    "anual": 5,
    "JAN": 5.48,
    "FEB": 5.85,
    "MAR": 5.41,
    "APR": 4.98,
    "MAY": 4.65,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 4.89,
    "SEP": 5.07,
    "OCT": 4.85,
    "NOV": 4.66,
    "DEC": 5.27
  },
  {
    "LONG": -40.46,
    "LAT": -19.25,
    "CIDADE": "Governador Lindenberg",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.82,
    "JAN": 5.36,
    "FEB": 5.74,
    "MAR": 5.3,
    "APR": 4.85,
    "MAY": 4.44,
    "JUN": 4.26,
    "JUL": 4.27,
    "AUG": 4.67,
    "SEP": 4.83,
    "OCT": 4.64,
    "NOV": 4.43,
    "DEC": 5.06
  },
  {
    "LONG": -40.34,
    "LAT": -19.27,
    "CIDADE": "Rio Bananal",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.86,
    "JAN": 5.37,
    "FEB": 5.75,
    "MAR": 5.35,
    "APR": 4.88,
    "MAY": 4.52,
    "JUN": 4.33,
    "JUL": 4.33,
    "AUG": 4.68,
    "SEP": 4.89,
    "OCT": 4.64,
    "NOV": 4.44,
    "DEC": 5.1
  },
  {
    "LONG": -40.85,
    "LAT": -19.22,
    "CIDADE": "Pancas",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.89,
    "JAN": 5.34,
    "FEB": 5.76,
    "MAR": 5.29,
    "APR": 4.94,
    "MAY": 4.48,
    "JUN": 4.32,
    "JUL": 4.37,
    "AUG": 4.79,
    "SEP": 4.98,
    "OCT": 4.82,
    "NOV": 4.51,
    "DEC": 5.01
  },
  {
    "LONG": -40.1,
    "LAT": -19.19,
    "CIDADE": "Sooretama",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.95,
    "JAN": 5.52,
    "FEB": 5.8,
    "MAR": 5.41,
    "APR": 4.94,
    "MAY": 4.56,
    "JUN": 4.31,
    "JUL": 4.34,
    "AUG": 4.85,
    "SEP": 5.02,
    "OCT": 4.8,
    "NOV": 4.62,
    "DEC": 5.27
  },
  {
    "LONG": -41.02,
    "LAT": -19.06,
    "CIDADE": "Alto Rio Novo",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.95,
    "JAN": 5.44,
    "FEB": 5.82,
    "MAR": 5.32,
    "APR": 5.03,
    "MAY": 4.56,
    "JUN": 4.32,
    "JUL": 4.37,
    "AUG": 4.88,
    "SEP": 5.09,
    "OCT": 4.93,
    "NOV": 4.57,
    "DEC": 5.13
  },
  {
    "LONG": -40.62,
    "LAT": -19.14,
    "CIDADE": "São Domingos do Norte",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.94,
    "JAN": 5.48,
    "FEB": 5.87,
    "MAR": 5.45,
    "APR": 5.03,
    "MAY": 4.55,
    "JUN": 4.38,
    "JUL": 4.33,
    "AUG": 4.81,
    "SEP": 4.95,
    "OCT": 4.78,
    "NOV": 4.57,
    "DEC": 5.14
  },
  {
    "LONG": -4.07,
    "LAT": -18.99,
    "CIDADE": "Águia Branca",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.94,
    "JAN": 5.47,
    "FEB": 5.81,
    "MAR": 5.41,
    "APR": 5.07,
    "MAY": 4.56,
    "JUN": 4.41,
    "JUL": 4.36,
    "AUG": 4.78,
    "SEP": 4.91,
    "OCT": 4.81,
    "NOV": 4.53,
    "DEC": 5.15
  },
  {
    "LONG": -40.54,
    "LAT": -19.02,
    "CIDADE": "São Gabriel da Palha",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.94,
    "JAN": 5.52,
    "FEB": 5.91,
    "MAR": 5.41,
    "APR": 5.05,
    "MAY": 4.5,
    "JUN": 4.34,
    "JUL": 4.32,
    "AUG": 4.72,
    "SEP": 4.92,
    "OCT": 4.78,
    "NOV": 4.57,
    "DEC": 5.17
  },
  {
    "LONG": -40.39,
    "LAT": -19,
    "CIDADE": "Vila Valério",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.92,
    "JAN": 5.51,
    "FEB": 5.83,
    "MAR": 5.42,
    "APR": 4.99,
    "MAY": 4.52,
    "JUN": 4.33,
    "JUL": 4.29,
    "AUG": 4.75,
    "SEP": 4.94,
    "OCT": 4.77,
    "NOV": 4.53,
    "DEC": 5.15
  },
  {
    "LONG": -41.12,
    "LAT": -18.86,
    "CIDADE": "Mantenópolis",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.92,
    "JAN": 5.4,
    "FEB": 5.72,
    "MAR": 5.28,
    "APR": 4.91,
    "MAY": 4.48,
    "JUN": 4.3,
    "JUL": 4.33,
    "AUG": 4.85,
    "SEP": 5.07,
    "OCT": 4.94,
    "NOV": 4.58,
    "DEC": 5.14
  },
  {
    "LONG": -40.08,
    "LAT": -18.91,
    "CIDADE": "Jaguaré",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.94,
    "JAN": 5.48,
    "FEB": 5.84,
    "MAR": 5.44,
    "APR": 4.93,
    "MAY": 4.46,
    "JUN": 4.29,
    "JUL": 4.27,
    "AUG": 4.81,
    "SEP": 5.03,
    "OCT": 4.89,
    "NOV": 4.64,
    "DEC": 5.23
  },
  {
    "LONG": -40.9,
    "LAT": -18.76,
    "CIDADE": "Barra de São Francisco",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.98,
    "JAN": 5.56,
    "FEB": 5.92,
    "MAR": 5.47,
    "APR": 5.11,
    "MAY": 4.54,
    "JUN": 4.38,
    "JUL": 4.34,
    "AUG": 4.76,
    "SEP": 4.99,
    "OCT": 4.83,
    "NOV": 4.6,
    "DEC": 5.22
  },
  {
    "LONG": -40.41,
    "LAT": -18.72,
    "CIDADE": "Nova Venécia",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.93,
    "JAN": 5.51,
    "FEB": 5.83,
    "MAR": 5.41,
    "APR": 5.04,
    "MAY": 4.51,
    "JUN": 4.26,
    "JUL": 4.29,
    "AUG": 4.76,
    "SEP": 4.99,
    "OCT": 4.8,
    "NOV": 4.58,
    "DEC": 5.17
  },
  {
    "LONG": -39.86,
    "LAT": -18.72,
    "CIDADE": "São Mateus",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.12,
    "JAN": 5.68,
    "FEB": 6.09,
    "MAR": 5.64,
    "APR": 5.03,
    "MAY": 4.61,
    "JUN": 4.34,
    "JUL": 4.43,
    "AUG": 5.01,
    "SEP": 5.25,
    "OCT": 5.1,
    "NOV": 4.87,
    "DEC": 5.44
  },
  {
    "LONG": -40.61,
    "LAT": -18.61,
    "CIDADE": "Vila Pavão",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.92,
    "JAN": 5.43,
    "FEB": 5.82,
    "MAR": 5.38,
    "APR": 5.09,
    "MAY": 4.57,
    "JUN": 4.3,
    "JUL": 4.28,
    "AUG": 4.7,
    "SEP": 4.95,
    "OCT": 4.79,
    "NOV": 4.53,
    "DEC": 5.23
  },
  {
    "LONG": -39.74,
    "LAT": -18.59,
    "CIDADE": "Conceição da Barra",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.27,
    "JAN": 5.77,
    "FEB": 6.17,
    "MAR": 5.74,
    "APR": 5.14,
    "MAY": 4.76,
    "JUN": 4.45,
    "JUL": 4.54,
    "AUG": 5.15,
    "SEP": 5.5,
    "OCT": 5.33,
    "NOV": 5.06,
    "DEC": 5.57
  },
  {
    "LONG": -40.99,
    "LAT": -18.55,
    "CIDADE": "Água Doce do Norte",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.89,
    "JAN": 5.37,
    "FEB": 5.79,
    "MAR": 5.32,
    "APR": 5,
    "MAY": 4.43,
    "JUN": 4.29,
    "JUL": 4.33,
    "AUG": 4.71,
    "SEP": 5,
    "OCT": 4.8,
    "NOV": 4.53,
    "DEC": 5.12
  },
  {
    "LONG": -40.29,
    "LAT": -18.54,
    "CIDADE": "Boa Esperança",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.51,
    "FEB": 5.85,
    "MAR": 5.39,
    "APR": 5.03,
    "MAY": 4.5,
    "JUN": 4.27,
    "JUL": 4.32,
    "AUG": 4.81,
    "SEP": 5.06,
    "OCT": 4.93,
    "NOV": 4.66,
    "DEC": 5.28
  },
  {
    "LONG": -4.08,
    "LAT": -18.37,
    "CIDADE": "Ecoporanga",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.9,
    "JAN": 5.42,
    "FEB": 5.84,
    "MAR": 5.37,
    "APR": 5.08,
    "MAY": 4.43,
    "JUN": 4.25,
    "JUL": 4.26,
    "AUG": 4.68,
    "SEP": 4.97,
    "OCT": 4.81,
    "NOV": 4.58,
    "DEC": 5.18
  },
  {
    "LONG": -40.22,
    "LAT": -18.41,
    "CIDADE": "Pinheiros",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.98,
    "JAN": 5.56,
    "FEB": 5.88,
    "MAR": 5.41,
    "APR": 5,
    "MAY": 4.46,
    "JUN": 4.29,
    "JUL": 4.32,
    "AUG": 4.78,
    "SEP": 5.06,
    "OCT": 4.97,
    "NOV": 4.66,
    "DEC": 5.32
  },
  {
    "LONG": -39.95,
    "LAT": -18.3,
    "CIDADE": "Pedro Canário",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.01,
    "JAN": 5.55,
    "FEB": 5.9,
    "MAR": 5.43,
    "APR": 4.97,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.34,
    "AUG": 4.91,
    "SEP": 5.12,
    "OCT": 4.99,
    "NOV": 4.77,
    "DEC": 5.39
  },
  {
    "LONG": -40.54,
    "LAT": -18.12,
    "CIDADE": "Ponto Belo",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.57,
    "FEB": 5.92,
    "MAR": 5.45,
    "APR": 5.05,
    "MAY": 4.4,
    "JUN": 4.15,
    "JUL": 4.28,
    "AUG": 4.76,
    "SEP": 5.03,
    "OCT": 5,
    "NOV": 4.67,
    "DEC": 5.36
  },
  {
    "LONG": -40.52,
    "LAT": -18.09,
    "CIDADE": "Mucurici",
    "UF": "ESPÍRITO SANTO",
    "anual": 4.97,
    "JAN": 5.57,
    "FEB": 5.92,
    "MAR": 5.45,
    "APR": 5.05,
    "MAY": 4.4,
    "JUN": 4.15,
    "JUL": 4.28,
    "AUG": 4.76,
    "SEP": 5.03,
    "OCT": 5,
    "NOV": 4.67,
    "DEC": 5.36
  },
  {
    "LONG": -40.36,
    "LAT": -18.13,
    "CIDADE": "Montanha",
    "UF": "ESPÍRITO SANTO",
    "anual": 5.01,
    "JAN": 5.63,
    "FEB": 5.89,
    "MAR": 5.48,
    "APR": 5.06,
    "MAY": 4.43,
    "JUN": 4.27,
    "JUL": 4.32,
    "AUG": 4.8,
    "SEP": 5.07,
    "OCT": 5.05,
    "NOV": 4.75,
    "DEC": 5.39
  },
  {
    "LONG": -51.4,
    "LAT": -19.19,
    "CIDADE": "Lagoa Santa",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.22,
    "FEB": 5.62,
    "MAR": 5.53,
    "APR": 5.68,
    "MAY": 5.32,
    "JUN": 5.12,
    "JUL": 5.36,
    "AUG": 6.02,
    "SEP": 5.49,
    "OCT": 5.46,
    "NOV": 5.34,
    "DEC": 5.47
  },
  {
    "LONG": -51.55,
    "LAT": -19.07,
    "CIDADE": "Itajá",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.23,
    "FEB": 5.61,
    "MAR": 5.54,
    "APR": 5.69,
    "MAY": 5.33,
    "JUN": 5.16,
    "JUL": 5.35,
    "AUG": 6,
    "SEP": 5.47,
    "OCT": 5.42,
    "NOV": 5.3,
    "DEC": 5.42
  },
  {
    "LONG": -51.93,
    "LAT": -1.9,
    "CIDADE": "Aporé",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 5.14,
    "FEB": 5.59,
    "MAR": 5.48,
    "APR": 5.51,
    "MAY": 5.23,
    "JUN": 5.16,
    "JUL": 5.29,
    "AUG": 6,
    "SEP": 5.4,
    "OCT": 5.32,
    "NOV": 5.21,
    "DEC": 5.4
  },
  {
    "LONG": -50.55,
    "LAT": -19,
    "CIDADE": "São Simão",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.22,
    "FEB": 5.65,
    "MAR": 5.53,
    "APR": 5.64,
    "MAY": 5.33,
    "JUN": 5.15,
    "JUL": 5.3,
    "AUG": 5.96,
    "SEP": 5.43,
    "OCT": 5.44,
    "NOV": 5.37,
    "DEC": 5.4
  },
  {
    "LONG": -50.65,
    "LAT": -18.91,
    "CIDADE": "Paranaiguara",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.16,
    "FEB": 5.49,
    "MAR": 5.46,
    "APR": 5.64,
    "MAY": 5.36,
    "JUN": 5.16,
    "JUL": 5.41,
    "AUG": 6.09,
    "SEP": 5.49,
    "OCT": 5.4,
    "NOV": 5.26,
    "DEC": 5.32
  },
  {
    "LONG": -51.35,
    "LAT": -1.88,
    "CIDADE": "Itarumã",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.25,
    "FEB": 5.58,
    "MAR": 5.53,
    "APR": 5.66,
    "MAY": 5.33,
    "JUN": 5.17,
    "JUL": 5.34,
    "AUG": 6.08,
    "SEP": 5.48,
    "OCT": 5.46,
    "NOV": 5.32,
    "DEC": 5.39
  },
  {
    "LONG": -50.94,
    "LAT": -18.76,
    "CIDADE": "Cachoeira Alta",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.24,
    "FEB": 5.54,
    "MAR": 5.45,
    "APR": 5.64,
    "MAY": 5.36,
    "JUN": 5.14,
    "JUL": 5.38,
    "AUG": 6.11,
    "SEP": 5.49,
    "OCT": 5.4,
    "NOV": 5.29,
    "DEC": 5.33
  },
  {
    "LONG": -51.13,
    "LAT": -18.56,
    "CIDADE": "Caçu",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.18,
    "FEB": 5.49,
    "MAR": 5.49,
    "APR": 5.66,
    "MAY": 5.37,
    "JUN": 5.15,
    "JUL": 5.43,
    "AUG": 6.17,
    "SEP": 5.49,
    "OCT": 5.37,
    "NOV": 5.32,
    "DEC": 5.28
  },
  {
    "LONG": -49.99,
    "LAT": -18.49,
    "CIDADE": "Inaciolândia",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.27,
    "FEB": 5.48,
    "MAR": 5.37,
    "APR": 5.59,
    "MAY": 5.41,
    "JUN": 5.27,
    "JUL": 5.44,
    "AUG": 6.19,
    "SEP": 5.59,
    "OCT": 5.43,
    "NOV": 5.3,
    "DEC": 5.33
  },
  {
    "LONG": -4.95,
    "LAT": -18.49,
    "CIDADE": "Cachoeira Dourada",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.11,
    "FEB": 5.5,
    "MAR": 5.31,
    "APR": 5.64,
    "MAY": 5.42,
    "JUN": 5.31,
    "JUL": 5.45,
    "AUG": 6.18,
    "SEP": 5.48,
    "OCT": 5.4,
    "NOV": 5.24,
    "DEC": 5.21
  },
  {
    "LONG": -52.66,
    "LAT": -18.39,
    "CIDADE": "Chapadão do Céu",
    "UF": "GOIÁS",
    "anual": 5.27,
    "JAN": 5.05,
    "FEB": 5.32,
    "MAR": 5.21,
    "APR": 5.48,
    "MAY": 5.25,
    "JUN": 5.13,
    "JUL": 5.25,
    "AUG": 5.9,
    "SEP": 5.36,
    "OCT": 5.1,
    "NOV": 4.99,
    "DEC": 5.2
  },
  {
    "LONG": -50.46,
    "LAT": -18.45,
    "CIDADE": "Quirinópolis",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.12,
    "FEB": 5.42,
    "MAR": 5.37,
    "APR": 5.63,
    "MAY": 5.4,
    "JUN": 5.29,
    "JUL": 5.4,
    "AUG": 6.21,
    "SEP": 5.57,
    "OCT": 5.41,
    "NOV": 5.25,
    "DEC": 5.2
  },
  {
    "LONG": -5.01,
    "LAT": -1.84,
    "CIDADE": "Gouvelândia",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.22,
    "FEB": 5.44,
    "MAR": 5.42,
    "APR": 5.65,
    "MAY": 5.46,
    "JUN": 5.32,
    "JUL": 5.48,
    "AUG": 6.31,
    "SEP": 5.6,
    "OCT": 5.48,
    "NOV": 5.32,
    "DEC": 5.32
  },
  {
    "LONG": -49.22,
    "LAT": -18.41,
    "CIDADE": "Itumbiara",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.08,
    "FEB": 5.44,
    "MAR": 5.29,
    "APR": 5.62,
    "MAY": 5.48,
    "JUN": 5.39,
    "JUL": 5.5,
    "AUG": 6.32,
    "SEP": 5.58,
    "OCT": 5.43,
    "NOV": 5.16,
    "DEC": 5.19
  },
  {
    "LONG": -47.78,
    "LAT": -1.84,
    "CIDADE": "Três Ranchos",
    "UF": "GOIÁS",
    "anual": 5.57,
    "JAN": 5.18,
    "FEB": 5.76,
    "MAR": 5.43,
    "APR": 5.8,
    "MAY": 5.6,
    "JUN": 5.45,
    "JUL": 5.56,
    "AUG": 6.31,
    "SEP": 5.81,
    "OCT": 5.53,
    "NOV": 5.15,
    "DEC": 5.23
  },
  {
    "LONG": -51.96,
    "LAT": -18.31,
    "CIDADE": "Serranópolis",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 5.06,
    "FEB": 5.32,
    "MAR": 5.35,
    "APR": 5.57,
    "MAY": 5.33,
    "JUN": 5.18,
    "JUL": 5.34,
    "AUG": 6.01,
    "SEP": 5.46,
    "OCT": 5.21,
    "NOV": 5.15,
    "DEC": 5.24
  },
  {
    "LONG": -5.12,
    "LAT": -18.29,
    "CIDADE": "Aparecida do Rio Doce",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 5.05,
    "FEB": 5.43,
    "MAR": 5.38,
    "APR": 5.57,
    "MAY": 5.37,
    "JUN": 5.26,
    "JUL": 5.39,
    "AUG": 6.18,
    "SEP": 5.49,
    "OCT": 5.3,
    "NOV": 5.18,
    "DEC": 5.16
  },
  {
    "LONG": -4.82,
    "LAT": -1.83,
    "CIDADE": "Anhanguera",
    "UF": "GOIÁS",
    "anual": 5.53,
    "JAN": 5.13,
    "FEB": 5.66,
    "MAR": 5.33,
    "APR": 5.7,
    "MAY": 5.56,
    "JUN": 5.4,
    "JUL": 5.58,
    "AUG": 6.32,
    "SEP": 5.8,
    "OCT": 5.54,
    "NOV": 5.13,
    "DEC": 5.23
  },
  {
    "LONG": -48.15,
    "LAT": -18.26,
    "CIDADE": "Cumari",
    "UF": "GOIÁS",
    "anual": 5.51,
    "JAN": 5.1,
    "FEB": 5.6,
    "MAR": 5.29,
    "APR": 5.67,
    "MAY": 5.52,
    "JUN": 5.41,
    "JUL": 5.6,
    "AUG": 6.3,
    "SEP": 5.8,
    "OCT": 5.54,
    "NOV": 5.11,
    "DEC": 5.2
  },
  {
    "LONG": -49.74,
    "LAT": -18.22,
    "CIDADE": "Bom Jesus de Goiás",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 5.16,
    "FEB": 5.39,
    "MAR": 5.22,
    "APR": 5.56,
    "MAY": 5.4,
    "JUN": 5.28,
    "JUL": 5.51,
    "AUG": 6.22,
    "SEP": 5.57,
    "OCT": 5.36,
    "NOV": 5.12,
    "DEC": 5.15
  },
  {
    "LONG": -49.35,
    "LAT": -1.82,
    "CIDADE": "Panamá",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 5.05,
    "FEB": 5.43,
    "MAR": 5.21,
    "APR": 5.61,
    "MAY": 5.47,
    "JUN": 5.32,
    "JUL": 5.5,
    "AUG": 6.17,
    "SEP": 5.54,
    "OCT": 5.39,
    "NOV": 5.1,
    "DEC": 5.14
  },
  {
    "LONG": -47.94,
    "LAT": -18.17,
    "CIDADE": "Catalão",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.15,
    "FEB": 5.62,
    "MAR": 5.26,
    "APR": 5.64,
    "MAY": 5.51,
    "JUN": 5.42,
    "JUL": 5.65,
    "AUG": 6.39,
    "SEP": 5.8,
    "OCT": 5.51,
    "NOV": 5.09,
    "DEC": 5.15
  },
  {
    "LONG": -47.83,
    "LAT": -18.23,
    "CIDADE": "Ouvidor",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.14,
    "FEB": 5.64,
    "MAR": 5.27,
    "APR": 5.66,
    "MAY": 5.53,
    "JUN": 5.44,
    "JUL": 5.68,
    "AUG": 6.41,
    "SEP": 5.81,
    "OCT": 5.5,
    "NOV": 5.09,
    "DEC": 5.13
  },
  {
    "LONG": -47.56,
    "LAT": -18.15,
    "CIDADE": "Davinópolis",
    "UF": "GOIÁS",
    "anual": 5.58,
    "JAN": 5.17,
    "FEB": 5.76,
    "MAR": 5.31,
    "APR": 5.73,
    "MAY": 5.59,
    "JUN": 5.49,
    "JUL": 5.78,
    "AUG": 6.45,
    "SEP": 5.92,
    "OCT": 5.55,
    "NOV": 5.08,
    "DEC": 5.19
  },
  {
    "LONG": -50.21,
    "LAT": -18.09,
    "CIDADE": "Castelândia",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.24,
    "FEB": 5.52,
    "MAR": 5.42,
    "APR": 5.67,
    "MAY": 5.5,
    "JUN": 5.32,
    "JUL": 5.47,
    "AUG": 6.29,
    "SEP": 5.6,
    "OCT": 5.42,
    "NOV": 5.25,
    "DEC": 5.26
  },
  {
    "LONG": -49.04,
    "LAT": -18.14,
    "CIDADE": "Buriti Alegre",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.03,
    "FEB": 5.52,
    "MAR": 5.24,
    "APR": 5.66,
    "MAY": 5.5,
    "JUN": 5.39,
    "JUL": 5.51,
    "AUG": 6.23,
    "SEP": 5.56,
    "OCT": 5.44,
    "NOV": 5.1,
    "DEC": 5.1
  },
  {
    "LONG": -48.76,
    "LAT": -18.08,
    "CIDADE": "Água Limpa",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.11,
    "FEB": 5.56,
    "MAR": 5.36,
    "APR": 5.7,
    "MAY": 5.55,
    "JUN": 5.39,
    "JUL": 5.52,
    "AUG": 6.12,
    "SEP": 5.64,
    "OCT": 5.46,
    "NOV": 5.15,
    "DEC": 5.18
  },
  {
    "LONG": -4.86,
    "LAT": -1.81,
    "CIDADE": "Corumbaíba",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.37,
    "APR": 5.71,
    "MAY": 5.57,
    "JUN": 5.4,
    "JUL": 5.56,
    "AUG": 6.25,
    "SEP": 5.76,
    "OCT": 5.48,
    "NOV": 5.17,
    "DEC": 5.17
  },
  {
    "LONG": -48.26,
    "LAT": -18.06,
    "CIDADE": "Nova Aurora",
    "UF": "GOIÁS",
    "anual": 5.53,
    "JAN": 5.13,
    "FEB": 5.62,
    "MAR": 5.33,
    "APR": 5.73,
    "MAY": 5.58,
    "JUN": 5.44,
    "JUL": 5.63,
    "AUG": 6.37,
    "SEP": 5.78,
    "OCT": 5.5,
    "NOV": 5.12,
    "DEC": 5.16
  },
  {
    "LONG": -48.09,
    "LAT": -18.14,
    "CIDADE": "Goiandira",
    "UF": "GOIÁS",
    "anual": 5.54,
    "JAN": 5.18,
    "FEB": 5.65,
    "MAR": 5.31,
    "APR": 5.68,
    "MAY": 5.54,
    "JUN": 5.43,
    "JUL": 5.65,
    "AUG": 6.37,
    "SEP": 5.84,
    "OCT": 5.55,
    "NOV": 5.13,
    "DEC": 5.18
  },
  {
    "LONG": -50.34,
    "LAT": -17.97,
    "CIDADE": "Maurilândia",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.22,
    "FEB": 5.53,
    "MAR": 5.43,
    "APR": 5.63,
    "MAY": 5.52,
    "JUN": 5.32,
    "JUL": 5.52,
    "AUG": 6.34,
    "SEP": 5.61,
    "OCT": 5.42,
    "NOV": 5.23,
    "DEC": 5.23
  },
  {
    "LONG": -49.37,
    "LAT": -1.8,
    "CIDADE": "Goiatuba",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.08,
    "FEB": 5.44,
    "MAR": 5.25,
    "APR": 5.56,
    "MAY": 5.45,
    "JUN": 5.32,
    "JUL": 5.51,
    "AUG": 6.2,
    "SEP": 5.61,
    "OCT": 5.41,
    "NOV": 5.13,
    "DEC": 5.14
  },
  {
    "LONG": -48.64,
    "LAT": -17.98,
    "CIDADE": "Marzagão",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.13,
    "FEB": 5.57,
    "MAR": 5.33,
    "APR": 5.66,
    "MAY": 5.56,
    "JUN": 5.36,
    "JUL": 5.55,
    "AUG": 6.21,
    "SEP": 5.75,
    "OCT": 5.47,
    "NOV": 5.17,
    "DEC": 5.13
  },
  {
    "LONG": -51.72,
    "LAT": -17.88,
    "CIDADE": "Jataí",
    "UF": "GOIÁS",
    "anual": 5.36,
    "JAN": 5.08,
    "FEB": 5.26,
    "MAR": 5.28,
    "APR": 5.57,
    "MAY": 5.42,
    "JUN": 5.27,
    "JUL": 5.35,
    "AUG": 6.1,
    "SEP": 5.46,
    "OCT": 5.2,
    "NOV": 5.14,
    "DEC": 5.21
  },
  {
    "LONG": -50.3,
    "LAT": -17.85,
    "CIDADE": "Turvelândia",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.24,
    "FEB": 5.54,
    "MAR": 5.4,
    "APR": 5.7,
    "MAY": 5.56,
    "JUN": 5.35,
    "JUL": 5.56,
    "AUG": 6.3,
    "SEP": 5.61,
    "OCT": 5.4,
    "NOV": 5.17,
    "DEC": 5.2
  },
  {
    "LONG": -50.92,
    "LAT": -17.79,
    "CIDADE": "Rio Verde",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 5.12,
    "FEB": 5.34,
    "MAR": 5.26,
    "APR": 5.57,
    "MAY": 5.46,
    "JUN": 5.33,
    "JUL": 5.43,
    "AUG": 6.27,
    "SEP": 5.57,
    "OCT": 5.28,
    "NOV": 4.97,
    "DEC": 5.13
  },
  {
    "LONG": -50.6,
    "LAT": -1.78,
    "CIDADE": "Santa Helena de Goiás",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.16,
    "FEB": 5.5,
    "MAR": 5.39,
    "APR": 5.61,
    "MAY": 5.53,
    "JUN": 5.31,
    "JUL": 5.49,
    "AUG": 6.29,
    "SEP": 5.57,
    "OCT": 5.37,
    "NOV": 5.13,
    "DEC": 5.2
  },
  {
    "LONG": -50.17,
    "LAT": -17.81,
    "CIDADE": "Porteirão",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.23,
    "FEB": 5.54,
    "MAR": 5.44,
    "APR": 5.66,
    "MAY": 5.57,
    "JUN": 5.36,
    "JUL": 5.53,
    "AUG": 6.31,
    "SEP": 5.59,
    "OCT": 5.44,
    "NOV": 5.19,
    "DEC": 5.17
  },
  {
    "LONG": -49.63,
    "LAT": -17.81,
    "CIDADE": "Joviânia",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.11,
    "FEB": 5.44,
    "MAR": 5.3,
    "APR": 5.63,
    "MAY": 5.5,
    "JUN": 5.29,
    "JUL": 5.5,
    "AUG": 6.27,
    "SEP": 5.59,
    "OCT": 5.44,
    "NOV": 5.15,
    "DEC": 5.16
  },
  {
    "LONG": -48.76,
    "LAT": -17.77,
    "CIDADE": "Rio Quente",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.11,
    "FEB": 5.56,
    "MAR": 5.38,
    "APR": 5.69,
    "MAY": 5.6,
    "JUN": 5.45,
    "JUL": 5.64,
    "AUG": 6.33,
    "SEP": 5.73,
    "OCT": 5.49,
    "NOV": 5.17,
    "DEC": 5.13
  },
  {
    "LONG": -49.81,
    "LAT": -17.73,
    "CIDADE": "Vicentinópolis",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.21,
    "FEB": 5.61,
    "MAR": 5.42,
    "APR": 5.65,
    "MAY": 5.56,
    "JUN": 5.28,
    "JUL": 5.53,
    "AUG": 6.29,
    "SEP": 5.6,
    "OCT": 5.44,
    "NOV": 5.18,
    "DEC": 5.18
  },
  {
    "LONG": -49.48,
    "LAT": -17.73,
    "CIDADE": "Aloândia",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.18,
    "FEB": 5.53,
    "MAR": 5.43,
    "APR": 5.67,
    "MAY": 5.53,
    "JUN": 5.36,
    "JUL": 5.52,
    "AUG": 6.2,
    "SEP": 5.62,
    "OCT": 5.46,
    "NOV": 5.17,
    "DEC": 5.15
  },
  {
    "LONG": -49.11,
    "LAT": -17.73,
    "CIDADE": "Morrinhos",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.13,
    "FEB": 5.53,
    "MAR": 5.41,
    "APR": 5.7,
    "MAY": 5.57,
    "JUN": 5.35,
    "JUL": 5.54,
    "AUG": 6.26,
    "SEP": 5.68,
    "OCT": 5.44,
    "NOV": 5.13,
    "DEC": 5.15
  },
  {
    "LONG": -4.86,
    "LAT": -17.74,
    "CIDADE": "Caldas Novas",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.14,
    "FEB": 5.6,
    "MAR": 5.32,
    "APR": 5.69,
    "MAY": 5.54,
    "JUN": 5.38,
    "JUL": 5.58,
    "AUG": 6.26,
    "SEP": 5.69,
    "OCT": 5.46,
    "NOV": 5.1,
    "DEC": 5.16
  },
  {
    "LONG": -48.16,
    "LAT": -1.77,
    "CIDADE": "Ipameri",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.21,
    "FEB": 5.54,
    "MAR": 5.24,
    "APR": 5.64,
    "MAY": 5.53,
    "JUN": 5.4,
    "JUL": 5.63,
    "AUG": 6.36,
    "SEP": 5.66,
    "OCT": 5.46,
    "NOV": 5.02,
    "DEC": 5.12
  },
  {
    "LONG": -52.55,
    "LAT": -17.57,
    "CIDADE": "Mineiros",
    "UF": "GOIÁS",
    "anual": 5.37,
    "JAN": 5.11,
    "FEB": 5.39,
    "MAR": 5.32,
    "APR": 5.61,
    "MAY": 5.44,
    "JUN": 5.24,
    "JUL": 5.4,
    "AUG": 6.05,
    "SEP": 5.5,
    "OCT": 5.09,
    "NOV": 5.16,
    "DEC": 5.17
  },
  {
    "LONG": -5.06,
    "LAT": -17.56,
    "CIDADE": "Santo Antônio da Barra",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.12,
    "FEB": 5.47,
    "MAR": 5.35,
    "APR": 5.64,
    "MAY": 5.54,
    "JUN": 5.35,
    "JUL": 5.51,
    "AUG": 6.27,
    "SEP": 5.57,
    "OCT": 5.34,
    "NOV": 5.13,
    "DEC": 5.15
  },
  {
    "LONG": -47.78,
    "LAT": -17.64,
    "CIDADE": "Campo Alegre de Goiás",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.11,
    "FEB": 5.65,
    "MAR": 5.16,
    "APR": 5.6,
    "MAY": 5.48,
    "JUN": 5.47,
    "JUL": 5.68,
    "AUG": 6.34,
    "SEP": 5.75,
    "OCT": 5.5,
    "NOV": 4.98,
    "DEC": 5.08
  },
  {
    "LONG": -52.07,
    "LAT": -17.53,
    "CIDADE": "Perolândia",
    "UF": "GOIÁS",
    "anual": 5.36,
    "JAN": 5.04,
    "FEB": 5.31,
    "MAR": 5.24,
    "APR": 5.56,
    "MAY": 5.47,
    "JUN": 5.31,
    "JUL": 5.35,
    "AUG": 6.14,
    "SEP": 5.51,
    "OCT": 5.15,
    "NOV": 5.13,
    "DEC": 5.07
  },
  {
    "LONG": -49.45,
    "LAT": -17.52,
    "CIDADE": "Pontalina",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.11,
    "FEB": 5.46,
    "MAR": 5.46,
    "APR": 5.68,
    "MAY": 5.55,
    "JUN": 5.25,
    "JUL": 5.46,
    "AUG": 6.2,
    "SEP": 5.62,
    "OCT": 5.46,
    "NOV": 5.11,
    "DEC": 5.12
  },
  {
    "LONG": -48.2,
    "LAT": -17.47,
    "CIDADE": "Urutaí",
    "UF": "GOIÁS",
    "anual": 5.55,
    "JAN": 5.23,
    "FEB": 5.61,
    "MAR": 5.41,
    "APR": 5.78,
    "MAY": 5.62,
    "JUN": 5.45,
    "JUL": 5.66,
    "AUG": 6.34,
    "SEP": 5.68,
    "OCT": 5.54,
    "NOV": 5.1,
    "DEC": 5.2
  },
  {
    "LONG": -52.68,
    "LAT": -17.36,
    "CIDADE": "Portelândia",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 5.07,
    "FEB": 5.32,
    "MAR": 5.27,
    "APR": 5.56,
    "MAY": 5.46,
    "JUN": 5.26,
    "JUL": 5.34,
    "AUG": 5.96,
    "SEP": 5.54,
    "OCT": 5.15,
    "NOV": 5.15,
    "DEC": 5.15
  },
  {
    "LONG": -51.17,
    "LAT": -17.44,
    "CIDADE": "Montividiu",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 5.1,
    "FEB": 5.27,
    "MAR": 5.17,
    "APR": 5.56,
    "MAY": 5.46,
    "JUN": 5.34,
    "JUL": 5.39,
    "AUG": 6.14,
    "SEP": 5.43,
    "OCT": 5.21,
    "NOV": 5,
    "DEC": 5.11
  },
  {
    "LONG": -50.38,
    "LAT": -17.4,
    "CIDADE": "Acreúna",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.17,
    "FEB": 5.52,
    "MAR": 5.35,
    "APR": 5.6,
    "MAY": 5.58,
    "JUN": 5.28,
    "JUL": 5.58,
    "AUG": 6.27,
    "SEP": 5.61,
    "OCT": 5.33,
    "NOV": 5.12,
    "DEC": 5.14
  },
  {
    "LONG": -49.66,
    "LAT": -17.42,
    "CIDADE": "Edealina",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.15,
    "FEB": 5.55,
    "MAR": 5.47,
    "APR": 5.7,
    "MAY": 5.53,
    "JUN": 5.34,
    "JUL": 5.51,
    "AUG": 6.24,
    "SEP": 5.67,
    "OCT": 5.49,
    "NOV": 5.17,
    "DEC": 5.13
  },
  {
    "LONG": -53.2,
    "LAT": -17.33,
    "CIDADE": "Santa Rita do Araguaia",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 5.01,
    "FEB": 5.34,
    "MAR": 5.24,
    "APR": 5.59,
    "MAY": 5.41,
    "JUN": 5.32,
    "JUL": 5.4,
    "AUG": 6.11,
    "SEP": 5.56,
    "OCT": 5.11,
    "NOV": 5.02,
    "DEC": 5.06
  },
  {
    "LONG": -49.93,
    "LAT": -17.34,
    "CIDADE": "Edéia",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.19,
    "FEB": 5.49,
    "MAR": 5.49,
    "APR": 5.65,
    "MAY": 5.51,
    "JUN": 5.32,
    "JUL": 5.51,
    "AUG": 6.2,
    "SEP": 5.64,
    "OCT": 5.43,
    "NOV": 5.16,
    "DEC": 5.12
  },
  {
    "LONG": -49.49,
    "LAT": -1.73,
    "CIDADE": "Mairipotaba",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.14,
    "FEB": 5.47,
    "MAR": 5.4,
    "APR": 5.67,
    "MAY": 5.53,
    "JUN": 5.37,
    "JUL": 5.58,
    "AUG": 6.29,
    "SEP": 5.67,
    "OCT": 5.43,
    "NOV": 5.08,
    "DEC": 5.08
  },
  {
    "LONG": -49.38,
    "LAT": -17.29,
    "CIDADE": "Cromínia",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.14,
    "FEB": 5.46,
    "MAR": 5.37,
    "APR": 5.66,
    "MAY": 5.55,
    "JUN": 5.36,
    "JUL": 5.57,
    "AUG": 6.3,
    "SEP": 5.68,
    "OCT": 5.43,
    "NOV": 5.07,
    "DEC": 5.1
  },
  {
    "LONG": -49.25,
    "LAT": -17.25,
    "CIDADE": "Professor Jamil",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.12,
    "FEB": 5.47,
    "MAR": 5.39,
    "APR": 5.67,
    "MAY": 5.59,
    "JUN": 5.33,
    "JUL": 5.56,
    "AUG": 6.24,
    "SEP": 5.68,
    "OCT": 5.44,
    "NOV": 5.06,
    "DEC": 5.1
  },
  {
    "LONG": -4.9,
    "LAT": -17.3,
    "CIDADE": "Piracanjuba",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.13,
    "FEB": 5.49,
    "MAR": 5.36,
    "APR": 5.67,
    "MAY": 5.58,
    "JUN": 5.4,
    "JUL": 5.53,
    "AUG": 6.25,
    "SEP": 5.69,
    "OCT": 5.48,
    "NOV": 5.07,
    "DEC": 5.13
  },
  {
    "LONG": -48.43,
    "LAT": -17.33,
    "CIDADE": "Palmelo",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.18,
    "FEB": 5.53,
    "MAR": 5.36,
    "APR": 5.73,
    "MAY": 5.57,
    "JUN": 5.49,
    "JUL": 5.69,
    "AUG": 6.38,
    "SEP": 5.66,
    "OCT": 5.55,
    "NOV": 5,
    "DEC": 5.12
  },
  {
    "LONG": -48.48,
    "LAT": -1.73,
    "CIDADE": "Santa Cruz de Goiás",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.18,
    "FEB": 5.53,
    "MAR": 5.36,
    "APR": 5.73,
    "MAY": 5.57,
    "JUN": 5.49,
    "JUL": 5.69,
    "AUG": 6.38,
    "SEP": 5.66,
    "OCT": 5.55,
    "NOV": 5,
    "DEC": 5.12
  },
  {
    "LONG": -48.28,
    "LAT": -17.3,
    "CIDADE": "Pires do Rio",
    "UF": "GOIÁS",
    "anual": 5.55,
    "JAN": 5.2,
    "FEB": 5.63,
    "MAR": 5.39,
    "APR": 5.81,
    "MAY": 5.58,
    "JUN": 5.48,
    "JUL": 5.64,
    "AUG": 6.39,
    "SEP": 5.67,
    "OCT": 5.55,
    "NOV": 5.05,
    "DEC": 5.16
  },
  {
    "LONG": -48.7,
    "LAT": -17.2,
    "CIDADE": "Cristianópolis",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.14,
    "FEB": 5.49,
    "MAR": 5.28,
    "APR": 5.67,
    "MAY": 5.53,
    "JUN": 5.5,
    "JUL": 5.68,
    "AUG": 6.34,
    "SEP": 5.77,
    "OCT": 5.5,
    "NOV": 5.01,
    "DEC": 5.05
  },
  {
    "LONG": -49.99,
    "LAT": -17.14,
    "CIDADE": "Indiara",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.16,
    "FEB": 5.55,
    "MAR": 5.47,
    "APR": 5.65,
    "MAY": 5.56,
    "JUN": 5.32,
    "JUL": 5.52,
    "AUG": 6.21,
    "SEP": 5.68,
    "OCT": 5.43,
    "NOV": 5.1,
    "DEC": 5.04
  },
  {
    "LONG": -48.66,
    "LAT": -17.06,
    "CIDADE": "São Miguel do Passa Quatro",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.14,
    "FEB": 5.53,
    "MAR": 5.27,
    "APR": 5.64,
    "MAY": 5.59,
    "JUN": 5.5,
    "JUL": 5.68,
    "AUG": 6.39,
    "SEP": 5.78,
    "OCT": 5.5,
    "NOV": 4.9,
    "DEC": 5.07
  },
  {
    "LONG": -51.81,
    "LAT": -16.95,
    "CIDADE": "Caiapônia",
    "UF": "GOIÁS",
    "anual": 5.36,
    "JAN": 5.03,
    "FEB": 5.32,
    "MAR": 5.27,
    "APR": 5.6,
    "MAY": 5.54,
    "JUN": 5.27,
    "JUL": 5.43,
    "AUG": 6.13,
    "SEP": 5.54,
    "OCT": 5.18,
    "NOV": 4.97,
    "DEC": 5.05
  },
  {
    "LONG": -50.15,
    "LAT": -17.05,
    "CIDADE": "Jandaia",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.11,
    "FEB": 5.56,
    "MAR": 5.39,
    "APR": 5.61,
    "MAY": 5.57,
    "JUN": 5.34,
    "JUL": 5.51,
    "AUG": 6.12,
    "SEP": 5.66,
    "OCT": 5.43,
    "NOV": 5.11,
    "DEC": 5.05
  },
  {
    "LONG": -49.78,
    "LAT": -16.97,
    "CIDADE": "Cezarina",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.12,
    "FEB": 5.48,
    "MAR": 5.42,
    "APR": 5.7,
    "MAY": 5.58,
    "JUN": 5.37,
    "JUL": 5.6,
    "AUG": 6.29,
    "SEP": 5.66,
    "OCT": 5.43,
    "NOV": 5.05,
    "DEC": 5.05
  },
  {
    "LONG": -49.63,
    "LAT": -1.7,
    "CIDADE": "Varjão",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.08,
    "FEB": 5.42,
    "MAR": 5.43,
    "APR": 5.69,
    "MAY": 5.57,
    "JUN": 5.41,
    "JUL": 5.59,
    "AUG": 6.3,
    "SEP": 5.67,
    "OCT": 5.4,
    "NOV": 5.06,
    "DEC": 5.06
  },
  {
    "LONG": -49.23,
    "LAT": -16.97,
    "CIDADE": "Hidrolândia",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.05,
    "FEB": 5.4,
    "MAR": 5.28,
    "APR": 5.57,
    "MAY": 5.52,
    "JUN": 5.43,
    "JUL": 5.59,
    "AUG": 6.29,
    "SEP": 5.7,
    "OCT": 5.43,
    "NOV": 5,
    "DEC": 5.06
  },
  {
    "LONG": -48.95,
    "LAT": -16.97,
    "CIDADE": "Bela Vista de Goiás",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.09,
    "FEB": 5.47,
    "MAR": 5.33,
    "APR": 5.61,
    "MAY": 5.57,
    "JUN": 5.5,
    "JUL": 5.6,
    "AUG": 6.29,
    "SEP": 5.79,
    "OCT": 5.48,
    "NOV": 5.03,
    "DEC": 5.06
  },
  {
    "LONG": -4.83,
    "LAT": -17.03,
    "CIDADE": "Orizona",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.17,
    "FEB": 5.56,
    "MAR": 5.21,
    "APR": 5.55,
    "MAY": 5.55,
    "JUN": 5.5,
    "JUL": 5.7,
    "AUG": 6.37,
    "SEP": 5.86,
    "OCT": 5.51,
    "NOV": 4.91,
    "DEC": 5.13
  },
  {
    "LONG": -50.45,
    "LAT": -16.95,
    "CIDADE": "Paraúna",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.04,
    "FEB": 5.37,
    "MAR": 5.34,
    "APR": 5.68,
    "MAY": 5.56,
    "JUN": 5.4,
    "JUL": 5.6,
    "AUG": 6.13,
    "SEP": 5.63,
    "OCT": 5.39,
    "NOV": 5.09,
    "DEC": 5.06
  },
  {
    "LONG": -4.94,
    "LAT": -16.91,
    "CIDADE": "Aragoiânia",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.06,
    "FEB": 5.34,
    "MAR": 5.37,
    "APR": 5.61,
    "MAY": 5.56,
    "JUN": 5.41,
    "JUL": 5.57,
    "AUG": 6.2,
    "SEP": 5.69,
    "OCT": 5.41,
    "NOV": 5.03,
    "DEC": 4.99
  },
  {
    "LONG": -50.41,
    "LAT": -1.68,
    "CIDADE": "São João da Paraúna",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.11,
    "FEB": 5.45,
    "MAR": 5.42,
    "APR": 5.72,
    "MAY": 5.6,
    "JUN": 5.43,
    "JUL": 5.56,
    "AUG": 6.24,
    "SEP": 5.67,
    "OCT": 5.45,
    "NOV": 5.16,
    "DEC": 5.08
  },
  {
    "LONG": -50.17,
    "LAT": -16.79,
    "CIDADE": "Palminópolis",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.14,
    "FEB": 5.49,
    "MAR": 5.36,
    "APR": 5.63,
    "MAY": 5.56,
    "JUN": 5.38,
    "JUL": 5.56,
    "AUG": 6.18,
    "SEP": 5.71,
    "OCT": 5.41,
    "NOV": 5.13,
    "DEC": 5.03
  },
  {
    "LONG": -49.92,
    "LAT": -16.8,
    "CIDADE": "Palmeiras de Goiás",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.11,
    "FEB": 5.49,
    "MAR": 5.37,
    "APR": 5.7,
    "MAY": 5.57,
    "JUN": 5.39,
    "JUL": 5.57,
    "AUG": 6.27,
    "SEP": 5.7,
    "OCT": 5.41,
    "NOV": 5,
    "DEC": 5.03
  },
  {
    "LONG": -49.69,
    "LAT": -16.76,
    "CIDADE": "Campestre de Goiás",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.07,
    "FEB": 5.4,
    "MAR": 5.39,
    "APR": 5.67,
    "MAY": 5.56,
    "JUN": 5.41,
    "JUL": 5.55,
    "AUG": 6.22,
    "SEP": 5.68,
    "OCT": 5.41,
    "NOV": 5.02,
    "DEC": 5.03
  },
  {
    "LONG": -49.53,
    "LAT": -16.83,
    "CIDADE": "Guapó",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.11,
    "FEB": 5.41,
    "MAR": 5.4,
    "APR": 5.67,
    "MAY": 5.58,
    "JUN": 5.44,
    "JUL": 5.59,
    "AUG": 6.22,
    "SEP": 5.72,
    "OCT": 5.44,
    "NOV": 5.07,
    "DEC": 5.06
  },
  {
    "LONG": -49.44,
    "LAT": -16.76,
    "CIDADE": "Abadia de Goiás",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.05,
    "FEB": 5.34,
    "MAR": 5.33,
    "APR": 5.6,
    "MAY": 5.6,
    "JUN": 5.47,
    "JUL": 5.55,
    "AUG": 6.26,
    "SEP": 5.74,
    "OCT": 5.41,
    "NOV": 5.03,
    "DEC": 4.99
  },
  {
    "LONG": -49.25,
    "LAT": -16.82,
    "CIDADE": "Aparecida de Goiânia",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5,
    "FEB": 5.33,
    "MAR": 5.26,
    "APR": 5.54,
    "MAY": 5.51,
    "JUN": 5.42,
    "JUL": 5.6,
    "AUG": 6.31,
    "SEP": 5.81,
    "OCT": 5.47,
    "NOV": 5.03,
    "DEC": 5.04
  },
  {
    "LONG": -47.61,
    "LAT": -16.77,
    "CIDADE": "Cristalina",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.17,
    "FEB": 5.59,
    "MAR": 5.22,
    "APR": 5.54,
    "MAY": 5.49,
    "JUN": 5.49,
    "JUL": 5.77,
    "AUG": 6.43,
    "SEP": 5.83,
    "OCT": 5.51,
    "NOV": 4.83,
    "DEC": 5.05
  },
  {
    "LONG": -52.31,
    "LAT": -16.72,
    "CIDADE": "Doverlândia",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.04,
    "FEB": 5.4,
    "MAR": 5.39,
    "APR": 5.71,
    "MAY": 5.56,
    "JUN": 5.35,
    "JUL": 5.46,
    "AUG": 6.04,
    "SEP": 5.58,
    "OCT": 5.19,
    "NOV": 5.15,
    "DEC": 5.14
  },
  {
    "LONG": -51.53,
    "LAT": -16.74,
    "CIDADE": "Palestina de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.06,
    "FEB": 5.38,
    "MAR": 5.34,
    "APR": 5.7,
    "MAY": 5.58,
    "JUN": 5.36,
    "JUL": 5.44,
    "AUG": 6.07,
    "SEP": 5.58,
    "OCT": 5.37,
    "NOV": 5.1,
    "DEC": 5.03
  },
  {
    "LONG": -50.64,
    "LAT": -16.67,
    "CIDADE": "Cachoeira de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.03,
    "FEB": 5.34,
    "MAR": 5.31,
    "APR": 5.66,
    "MAY": 5.56,
    "JUN": 5.41,
    "JUL": 5.51,
    "AUG": 6.09,
    "SEP": 5.59,
    "OCT": 5.38,
    "NOV": 5.08,
    "DEC": 5.04
  },
  {
    "LONG": -50.46,
    "LAT": -16.68,
    "CIDADE": "Aurilândia",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.18,
    "FEB": 5.47,
    "MAR": 5.49,
    "APR": 5.73,
    "MAY": 5.62,
    "JUN": 5.49,
    "JUL": 5.57,
    "AUG": 6.21,
    "SEP": 5.66,
    "OCT": 5.46,
    "NOV": 5.22,
    "DEC": 5.1
  },
  {
    "LONG": -49.49,
    "LAT": -16.65,
    "CIDADE": "Trindade",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.05,
    "FEB": 5.35,
    "MAR": 5.31,
    "APR": 5.59,
    "MAY": 5.59,
    "JUN": 5.48,
    "JUL": 5.58,
    "AUG": 6.31,
    "SEP": 5.76,
    "OCT": 5.41,
    "NOV": 5.01,
    "DEC": 5.01
  },
  {
    "LONG": -49.27,
    "LAT": -16.69,
    "CIDADE": "Goiânia",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5,
    "FEB": 5.29,
    "MAR": 5.21,
    "APR": 5.5,
    "MAY": 5.52,
    "JUN": 5.45,
    "JUL": 5.63,
    "AUG": 6.36,
    "SEP": 5.81,
    "OCT": 5.46,
    "NOV": 5.01,
    "DEC": 5
  },
  {
    "LONG": -49.09,
    "LAT": -16.71,
    "CIDADE": "Senador Canedo",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.04,
    "FEB": 5.42,
    "MAR": 5.28,
    "APR": 5.61,
    "MAY": 5.62,
    "JUN": 5.52,
    "JUL": 5.64,
    "AUG": 6.35,
    "SEP": 5.8,
    "OCT": 5.47,
    "NOV": 4.99,
    "DEC": 4.98
  },
  {
    "LONG": -49,
    "LAT": -16.72,
    "CIDADE": "Caldazinha",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.04,
    "FEB": 5.42,
    "MAR": 5.28,
    "APR": 5.61,
    "MAY": 5.62,
    "JUN": 5.52,
    "JUL": 5.64,
    "AUG": 6.35,
    "SEP": 5.8,
    "OCT": 5.47,
    "NOV": 4.99,
    "DEC": 4.98
  },
  {
    "LONG": -48.61,
    "LAT": -16.66,
    "CIDADE": "Silvânia",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.05,
    "FEB": 5.51,
    "MAR": 5.19,
    "APR": 5.55,
    "MAY": 5.54,
    "JUN": 5.47,
    "JUL": 5.65,
    "AUG": 6.41,
    "SEP": 5.75,
    "OCT": 5.46,
    "NOV": 4.84,
    "DEC": 5
  },
  {
    "LONG": -48.51,
    "LAT": -16.75,
    "CIDADE": "Vianópolis",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.26,
    "APR": 5.61,
    "MAY": 5.56,
    "JUN": 5.51,
    "JUL": 5.71,
    "AUG": 6.43,
    "SEP": 5.83,
    "OCT": 5.53,
    "NOV": 4.92,
    "DEC": 5.04
  },
  {
    "LONG": -51.09,
    "LAT": "#VALOR!",
    "CIDADE": "Amorinópolis",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.08,
    "FEB": 5.38,
    "MAR": 5.4,
    "APR": 5.77,
    "MAY": 5.66,
    "JUN": 5.34,
    "JUL": 5.52,
    "AUG": 6.1,
    "SEP": 5.61,
    "OCT": 5.42,
    "NOV": 5.06,
    "DEC": 5.05
  },
  {
    "LONG": -50.79,
    "LAT": -16.61,
    "CIDADE": "Ivolândia",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.11,
    "FEB": 5.37,
    "MAR": 5.38,
    "APR": 5.69,
    "MAY": 5.63,
    "JUN": 5.37,
    "JUL": 5.61,
    "AUG": 6.16,
    "SEP": 5.62,
    "OCT": 5.41,
    "NOV": 5.08,
    "DEC": 5.05
  },
  {
    "LONG": -50.3,
    "LAT": -16.58,
    "CIDADE": "Firminópolis",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.17,
    "FEB": 5.39,
    "MAR": 5.37,
    "APR": 5.71,
    "MAY": 5.63,
    "JUN": 5.42,
    "JUL": 5.58,
    "AUG": 6.19,
    "SEP": 5.68,
    "OCT": 5.42,
    "NOV": 5.2,
    "DEC": 5.05
  },
  {
    "LONG": -50.14,
    "LAT": -16.61,
    "CIDADE": "Turvânia",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.09,
    "FEB": 5.42,
    "MAR": 5.37,
    "APR": 5.66,
    "MAY": 5.56,
    "JUN": 5.43,
    "JUL": 5.64,
    "AUG": 6.33,
    "SEP": 5.69,
    "OCT": 5.43,
    "NOV": 5.09,
    "DEC": 5.05
  },
  {
    "LONG": -49.88,
    "LAT": -16.58,
    "CIDADE": "Nazário",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.05,
    "FEB": 5.37,
    "MAR": 5.31,
    "APR": 5.69,
    "MAY": 5.56,
    "JUN": 5.48,
    "JUL": 5.62,
    "AUG": 6.38,
    "SEP": 5.72,
    "OCT": 5.42,
    "NOV": 4.97,
    "DEC": 5.02
  },
  {
    "LONG": -49.7,
    "LAT": -16.58,
    "CIDADE": "Santa Bárbara de Goiás",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.03,
    "FEB": 5.35,
    "MAR": 5.32,
    "APR": 5.7,
    "MAY": 5.58,
    "JUN": 5.45,
    "JUL": 5.61,
    "AUG": 6.35,
    "SEP": 5.69,
    "OCT": 5.39,
    "NOV": 4.96,
    "DEC": 5
  },
  {
    "LONG": -4.9,
    "LAT": -16.62,
    "CIDADE": "Bonfinópolis",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.01,
    "FEB": 5.39,
    "MAR": 5.25,
    "APR": 5.54,
    "MAY": 5.62,
    "JUN": 5.47,
    "JUL": 5.7,
    "AUG": 6.36,
    "SEP": 5.75,
    "OCT": 5.43,
    "NOV": 4.86,
    "DEC": 4.88
  },
  {
    "LONG": -48.74,
    "LAT": -16.62,
    "CIDADE": "Leopoldo de Bulhões",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.04,
    "FEB": 5.46,
    "MAR": 5.22,
    "APR": 5.56,
    "MAY": 5.55,
    "JUN": 5.51,
    "JUL": 5.68,
    "AUG": 6.41,
    "SEP": 5.77,
    "OCT": 5.43,
    "NOV": 4.81,
    "DEC": 4.92
  },
  {
    "LONG": -50.74,
    "LAT": -16.54,
    "CIDADE": "Moiporá",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.11,
    "FEB": 5.36,
    "MAR": 5.35,
    "APR": 5.72,
    "MAY": 5.63,
    "JUN": 5.38,
    "JUL": 5.57,
    "AUG": 6.11,
    "SEP": 5.61,
    "OCT": 5.41,
    "NOV": 5.1,
    "DEC": 5.03
  },
  {
    "LONG": -5.04,
    "LAT": -16.52,
    "CIDADE": "São Luís de Montes Belos",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.15,
    "FEB": 5.4,
    "MAR": 5.37,
    "APR": 5.7,
    "MAY": 5.63,
    "JUN": 5.41,
    "JUL": 5.6,
    "AUG": 6.21,
    "SEP": 5.67,
    "OCT": 5.43,
    "NOV": 5.19,
    "DEC": 5.03
  },
  {
    "LONG": -49.96,
    "LAT": -1.65,
    "CIDADE": "Anicuns",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.01,
    "FEB": 5.42,
    "MAR": 5.3,
    "APR": 5.64,
    "MAY": 5.58,
    "JUN": 5.51,
    "JUL": 5.67,
    "AUG": 6.38,
    "SEP": 5.7,
    "OCT": 5.4,
    "NOV": 4.97,
    "DEC": 5.01
  },
  {
    "LONG": -49.76,
    "LAT": -16.47,
    "CIDADE": "Avelinópolis",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.05,
    "FEB": 5.35,
    "MAR": 5.29,
    "APR": 5.68,
    "MAY": 5.62,
    "JUN": 5.45,
    "JUL": 5.63,
    "AUG": 6.41,
    "SEP": 5.67,
    "OCT": 5.43,
    "NOV": 4.96,
    "DEC": 4.98
  },
  {
    "LONG": -49.42,
    "LAT": -16.51,
    "CIDADE": "Goianira",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.03,
    "FEB": 5.34,
    "MAR": 5.28,
    "APR": 5.6,
    "MAY": 5.6,
    "JUN": 5.49,
    "JUL": 5.66,
    "AUG": 6.44,
    "SEP": 5.78,
    "OCT": 5.4,
    "NOV": 4.91,
    "DEC": 4.94
  },
  {
    "LONG": -49.31,
    "LAT": -16.49,
    "CIDADE": "Santo Antônio de Goiás",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.04,
    "FEB": 5.35,
    "MAR": 5.27,
    "APR": 5.58,
    "MAY": 5.63,
    "JUN": 5.49,
    "JUL": 5.68,
    "AUG": 6.41,
    "SEP": 5.76,
    "OCT": 5.42,
    "NOV": 4.92,
    "DEC": 4.94
  },
  {
    "LONG": -49.02,
    "LAT": -16.5,
    "CIDADE": "Goianápolis",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.01,
    "FEB": 5.32,
    "MAR": 5.22,
    "APR": 5.56,
    "MAY": 5.59,
    "JUN": 5.5,
    "JUL": 5.7,
    "AUG": 6.38,
    "SEP": 5.74,
    "OCT": 5.4,
    "NOV": 4.86,
    "DEC": 4.9
  },
  {
    "LONG": -48.64,
    "LAT": -16.48,
    "CIDADE": "Gameleira de Goiás",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.03,
    "FEB": 5.49,
    "MAR": 5.17,
    "APR": 5.53,
    "MAY": 5.52,
    "JUN": 5.56,
    "JUL": 5.79,
    "AUG": 6.47,
    "SEP": 5.79,
    "OCT": 5.42,
    "NOV": 4.74,
    "DEC": 5.01
  },
  {
    "LONG": -51.82,
    "LAT": -16.43,
    "CIDADE": "Piranhas",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.08,
    "FEB": 5.49,
    "MAR": 5.36,
    "APR": 5.83,
    "MAY": 5.66,
    "JUN": 5.39,
    "JUL": 5.39,
    "AUG": 6.01,
    "SEP": 5.58,
    "OCT": 5.4,
    "NOV": 5.13,
    "DEC": 5.07
  },
  {
    "LONG": -51.56,
    "LAT": -16.39,
    "CIDADE": "Arenópolis",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.08,
    "FEB": 5.44,
    "MAR": 5.43,
    "APR": 5.75,
    "MAY": 5.66,
    "JUN": 5.43,
    "JUL": 5.42,
    "AUG": 6.1,
    "SEP": 5.6,
    "OCT": 5.42,
    "NOV": 5.12,
    "DEC": 5.05
  },
  {
    "LONG": -51.12,
    "LAT": -16.44,
    "CIDADE": "Iporá",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.04,
    "FEB": 5.31,
    "MAR": 5.3,
    "APR": 5.74,
    "MAY": 5.6,
    "JUN": 5.46,
    "JUL": 5.54,
    "AUG": 6.15,
    "SEP": 5.59,
    "OCT": 5.35,
    "NOV": 5.01,
    "DEC": 5.01
  },
  {
    "LONG": -50.17,
    "LAT": -16.42,
    "CIDADE": "Adelândia",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.05,
    "FEB": 5.36,
    "MAR": 5.3,
    "APR": 5.61,
    "MAY": 5.59,
    "JUN": 5.48,
    "JUL": 5.63,
    "AUG": 6.3,
    "SEP": 5.66,
    "OCT": 5.41,
    "NOV": 5.04,
    "DEC": 5.03
  },
  {
    "LONG": -49.68,
    "LAT": -16.36,
    "CIDADE": "Araçu",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.04,
    "FEB": 5.33,
    "MAR": 5.25,
    "APR": 5.63,
    "MAY": 5.66,
    "JUN": 5.46,
    "JUL": 5.66,
    "AUG": 6.43,
    "SEP": 5.72,
    "OCT": 5.41,
    "NOV": 4.95,
    "DEC": 4.95
  },
  {
    "LONG": -49.5,
    "LAT": -16.36,
    "CIDADE": "Inhumas",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.01,
    "FEB": 5.3,
    "MAR": 5.24,
    "APR": 5.59,
    "MAY": 5.65,
    "JUN": 5.42,
    "JUL": 5.67,
    "AUG": 6.47,
    "SEP": 5.73,
    "OCT": 5.38,
    "NOV": 4.88,
    "DEC": 4.93
  },
  {
    "LONG": -49.5,
    "LAT": -16.45,
    "CIDADE": "Caturaí",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5,
    "FEB": 5.33,
    "MAR": 5.27,
    "APR": 5.55,
    "MAY": 5.63,
    "JUN": 5.45,
    "JUL": 5.65,
    "AUG": 6.47,
    "SEP": 5.78,
    "OCT": 5.38,
    "NOV": 4.91,
    "DEC": 4.95
  },
  {
    "LONG": -49.39,
    "LAT": -16.43,
    "CIDADE": "Brazabrantes",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.02,
    "FEB": 5.33,
    "MAR": 5.27,
    "APR": 5.6,
    "MAY": 5.65,
    "JUN": 5.45,
    "JUL": 5.67,
    "AUG": 6.45,
    "SEP": 5.76,
    "OCT": 5.4,
    "NOV": 4.93,
    "DEC": 4.97
  },
  {
    "LONG": -49.32,
    "LAT": -16.37,
    "CIDADE": "Nova Veneza",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.02,
    "FEB": 5.33,
    "MAR": 5.27,
    "APR": 5.6,
    "MAY": 5.65,
    "JUN": 5.45,
    "JUL": 5.67,
    "AUG": 6.45,
    "SEP": 5.76,
    "OCT": 5.4,
    "NOV": 4.93,
    "DEC": 4.97
  },
  {
    "LONG": -49.22,
    "LAT": -16.41,
    "CIDADE": "Nerópolis",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.06,
    "FEB": 5.3,
    "MAR": 5.23,
    "APR": 5.59,
    "MAY": 5.6,
    "JUN": 5.47,
    "JUL": 5.69,
    "AUG": 6.43,
    "SEP": 5.77,
    "OCT": 5.4,
    "NOV": 4.9,
    "DEC": 4.93
  },
  {
    "LONG": -49.08,
    "LAT": -1.64,
    "CIDADE": "Terezópolis de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 4.95,
    "FEB": 5.32,
    "MAR": 5.18,
    "APR": 5.56,
    "MAY": 5.59,
    "JUN": 5.5,
    "JUL": 5.67,
    "AUG": 6.4,
    "SEP": 5.76,
    "OCT": 5.4,
    "NOV": 4.85,
    "DEC": 4.87
  },
  {
    "LONG": -50.91,
    "LAT": -16.31,
    "CIDADE": "Israelândia",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.08,
    "FEB": 5.43,
    "MAR": 5.47,
    "APR": 5.81,
    "MAY": 5.59,
    "JUN": 5.42,
    "JUL": 5.46,
    "AUG": 5.97,
    "SEP": 5.58,
    "OCT": 5.42,
    "NOV": 5.09,
    "DEC": 5.04
  },
  {
    "LONG": -50.55,
    "LAT": -16.3,
    "CIDADE": "Córrego do Ouro",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.06,
    "FEB": 5.35,
    "MAR": 5.33,
    "APR": 5.69,
    "MAY": 5.63,
    "JUN": 5.45,
    "JUL": 5.62,
    "AUG": 6.11,
    "SEP": 5.63,
    "OCT": 5.4,
    "NOV": 5.1,
    "DEC": 4.97
  },
  {
    "LONG": -49.98,
    "LAT": -1.63,
    "CIDADE": "Americano do Brasil",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 4.97,
    "FEB": 5.28,
    "MAR": 5.23,
    "APR": 5.53,
    "MAY": 5.6,
    "JUN": 5.51,
    "JUL": 5.67,
    "AUG": 6.36,
    "SEP": 5.72,
    "OCT": 5.38,
    "NOV": 4.9,
    "DEC": 4.94
  },
  {
    "LONG": -49.37,
    "LAT": -16.25,
    "CIDADE": "Damolândia",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.96,
    "FEB": 5.34,
    "MAR": 5.25,
    "APR": 5.63,
    "MAY": 5.64,
    "JUN": 5.53,
    "JUL": 5.68,
    "AUG": 6.5,
    "SEP": 5.78,
    "OCT": 5.38,
    "NOV": 4.9,
    "DEC": 4.97
  },
  {
    "LONG": -49.09,
    "LAT": -16.29,
    "CIDADE": "Campo Limpo de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 4.93,
    "FEB": 5.29,
    "MAR": 5.13,
    "APR": 5.52,
    "MAY": 5.61,
    "JUN": 5.53,
    "JUL": 5.75,
    "AUG": 6.47,
    "SEP": 5.75,
    "OCT": 5.35,
    "NOV": 4.8,
    "DEC": 4.92
  },
  {
    "LONG": -48.95,
    "LAT": -16.33,
    "CIDADE": "Anápolis",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 4.93,
    "FEB": 5.3,
    "MAR": 5.12,
    "APR": 5.47,
    "MAY": 5.52,
    "JUN": 5.51,
    "JUL": 5.75,
    "AUG": 6.42,
    "SEP": 5.76,
    "OCT": 5.35,
    "NOV": 4.77,
    "DEC": 4.88
  },
  {
    "LONG": -47.95,
    "LAT": -16.25,
    "CIDADE": "Luziânia",
    "UF": "GOIÁS",
    "anual": 5.52,
    "JAN": 5.17,
    "FEB": 5.57,
    "MAR": 5.28,
    "APR": 5.59,
    "MAY": 5.55,
    "JUN": 5.57,
    "JUL": 5.8,
    "AUG": 6.44,
    "SEP": 5.87,
    "OCT": 5.49,
    "NOV": 4.86,
    "DEC": 5.07
  },
  {
    "LONG": -52.54,
    "LAT": -16.2,
    "CIDADE": "Baliza",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.07,
    "FEB": 5.4,
    "MAR": 5.34,
    "APR": 5.8,
    "MAY": 5.65,
    "JUN": 5.41,
    "JUL": 5.46,
    "AUG": 6.06,
    "SEP": 5.64,
    "OCT": 5.31,
    "NOV": 5.14,
    "DEC": 5.1
  },
  {
    "LONG": -52.18,
    "LAT": -16.2,
    "CIDADE": "Bom Jardim de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.07,
    "FEB": 5.41,
    "MAR": 5.3,
    "APR": 5.75,
    "MAY": 5.62,
    "JUN": 5.37,
    "JUL": 5.42,
    "AUG": 6.01,
    "SEP": 5.57,
    "OCT": 5.34,
    "NOV": 5.1,
    "DEC": 5.08
  },
  {
    "LONG": -51.26,
    "LAT": -16.23,
    "CIDADE": "Diorama",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.11,
    "FEB": 5.36,
    "MAR": 5.35,
    "APR": 5.79,
    "MAY": 5.62,
    "JUN": 5.43,
    "JUL": 5.41,
    "AUG": 6.02,
    "SEP": 5.64,
    "OCT": 5.38,
    "NOV": 5,
    "DEC": 5.02
  },
  {
    "LONG": -50.95,
    "LAT": -16.18,
    "CIDADE": "Jaupaci",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.07,
    "FEB": 5.43,
    "MAR": 5.45,
    "APR": 5.8,
    "MAY": 5.66,
    "JUN": 5.41,
    "JUL": 5.53,
    "AUG": 6,
    "SEP": 5.61,
    "OCT": 5.44,
    "NOV": 5.09,
    "DEC": 5.07
  },
  {
    "LONG": -50.78,
    "LAT": "#VALOR!",
    "CIDADE": "Fazenda Nova",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.1,
    "FEB": 5.4,
    "MAR": 5.43,
    "APR": 5.77,
    "MAY": 5.68,
    "JUN": 5.43,
    "JUL": 5.5,
    "AUG": 6.06,
    "SEP": 5.63,
    "OCT": 5.49,
    "NOV": 5.1,
    "DEC": 5.01
  },
  {
    "LONG": -50.43,
    "LAT": -16.18,
    "CIDADE": "Buriti de Goiás",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.12,
    "FEB": 5.37,
    "MAR": 5.42,
    "APR": 5.74,
    "MAY": 5.64,
    "JUN": 5.47,
    "JUL": 5.58,
    "AUG": 6.17,
    "SEP": 5.6,
    "OCT": 5.46,
    "NOV": 5.16,
    "DEC": 5.01
  },
  {
    "LONG": -50.3,
    "LAT": -16.21,
    "CIDADE": "Sanclerlândia",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.08,
    "FEB": 5.38,
    "MAR": 5.41,
    "APR": 5.73,
    "MAY": 5.63,
    "JUN": 5.53,
    "JUL": 5.57,
    "AUG": 6.28,
    "SEP": 5.64,
    "OCT": 5.45,
    "NOV": 5.12,
    "DEC": 5.03
  },
  {
    "LONG": -49.6,
    "LAT": -16.2,
    "CIDADE": "Itauçu",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5,
    "FEB": 5.36,
    "MAR": 5.21,
    "APR": 5.59,
    "MAY": 5.6,
    "JUN": 5.48,
    "JUL": 5.62,
    "AUG": 6.36,
    "SEP": 5.71,
    "OCT": 5.39,
    "NOV": 4.92,
    "DEC": 4.91
  },
  {
    "LONG": -49.2,
    "LAT": -16.22,
    "CIDADE": "Ouro Verde de Goiás",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.94,
    "FEB": 5.3,
    "MAR": 5.2,
    "APR": 5.55,
    "MAY": 5.58,
    "JUN": 5.5,
    "JUL": 5.67,
    "AUG": 6.45,
    "SEP": 5.75,
    "OCT": 5.38,
    "NOV": 4.86,
    "DEC": 4.93
  },
  {
    "LONG": -48.71,
    "LAT": -16.2,
    "CIDADE": "Abadiânia",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.97,
    "FEB": 5.37,
    "MAR": 5.21,
    "APR": 5.55,
    "MAY": 5.63,
    "JUN": 5.54,
    "JUL": 5.77,
    "AUG": 6.46,
    "SEP": 5.84,
    "OCT": 5.41,
    "NOV": 4.77,
    "DEC": 4.93
  },
  {
    "LONG": -50.21,
    "LAT": -16.12,
    "CIDADE": "Mossâmedes",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.99,
    "FEB": 5.32,
    "MAR": 5.31,
    "APR": 5.69,
    "MAY": 5.67,
    "JUN": 5.45,
    "JUL": 5.58,
    "AUG": 6.33,
    "SEP": 5.67,
    "OCT": 5.47,
    "NOV": 5.01,
    "DEC": 4.93
  },
  {
    "LONG": -49.6,
    "LAT": -1.61,
    "CIDADE": "Taquaral de Goiás",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 5.01,
    "FEB": 5.42,
    "MAR": 5.27,
    "APR": 5.62,
    "MAY": 5.63,
    "JUN": 5.49,
    "JUL": 5.55,
    "AUG": 6.29,
    "SEP": 5.71,
    "OCT": 5.42,
    "NOV": 4.91,
    "DEC": 4.94
  },
  {
    "LONG": -49.49,
    "LAT": -16.08,
    "CIDADE": "Santa Rosa de Goiás",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.99,
    "FEB": 5.4,
    "MAR": 5.29,
    "APR": 5.65,
    "MAY": 5.64,
    "JUN": 5.52,
    "JUL": 5.65,
    "AUG": 6.39,
    "SEP": 5.74,
    "OCT": 5.41,
    "NOV": 4.89,
    "DEC": 4.95
  },
  {
    "LONG": -49.34,
    "LAT": -16.1,
    "CIDADE": "Petrolina de Goiás",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.03,
    "FEB": 5.43,
    "MAR": 5.33,
    "APR": 5.71,
    "MAY": 5.65,
    "JUN": 5.54,
    "JUL": 5.67,
    "AUG": 6.42,
    "SEP": 5.79,
    "OCT": 5.45,
    "NOV": 4.94,
    "DEC": 4.99
  },
  {
    "LONG": -4.85,
    "LAT": -16.08,
    "CIDADE": "Alexânia",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.98,
    "FEB": 5.31,
    "MAR": 5.1,
    "APR": 5.49,
    "MAY": 5.57,
    "JUN": 5.52,
    "JUL": 5.78,
    "AUG": 6.49,
    "SEP": 5.79,
    "OCT": 5.42,
    "NOV": 4.76,
    "DEC": 4.96
  },
  {
    "LONG": -48.03,
    "LAT": -16.05,
    "CIDADE": "Novo Gama",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.05,
    "FEB": 5.46,
    "MAR": 5.2,
    "APR": 5.53,
    "MAY": 5.57,
    "JUN": 5.63,
    "JUL": 5.81,
    "AUG": 6.5,
    "SEP": 5.84,
    "OCT": 5.4,
    "NOV": 4.8,
    "DEC": 4.93
  },
  {
    "LONG": -47.93,
    "LAT": -16.08,
    "CIDADE": "Cidade Ocidental",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.02,
    "FEB": 5.48,
    "MAR": 5.16,
    "APR": 5.53,
    "MAY": 5.55,
    "JUN": 5.6,
    "JUL": 5.83,
    "AUG": 6.49,
    "SEP": 5.89,
    "OCT": 5.37,
    "NOV": 4.74,
    "DEC": 4.92
  },
  {
    "LONG": -47.98,
    "LAT": -16.07,
    "CIDADE": "Valparaíso de Goiás",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.02,
    "FEB": 5.48,
    "MAR": 5.16,
    "APR": 5.53,
    "MAY": 5.55,
    "JUN": 5.6,
    "JUL": 5.83,
    "AUG": 6.49,
    "SEP": 5.89,
    "OCT": 5.37,
    "NOV": 4.74,
    "DEC": 4.92
  },
  {
    "LONG": -51.4,
    "LAT": -16.01,
    "CIDADE": "Montes Claros de Goiás",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.07,
    "FEB": 5.44,
    "MAR": 5.39,
    "APR": 5.75,
    "MAY": 5.63,
    "JUN": 5.42,
    "JUL": 5.42,
    "AUG": 5.98,
    "SEP": 5.63,
    "OCT": 5.37,
    "NOV": 5,
    "DEC": 5.05
  },
  {
    "LONG": -50.71,
    "LAT": -16.03,
    "CIDADE": "Novo Brasil",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.08,
    "FEB": 5.48,
    "MAR": 5.47,
    "APR": 5.85,
    "MAY": 5.7,
    "JUN": 5.44,
    "JUL": 5.5,
    "AUG": 5.99,
    "SEP": 5.65,
    "OCT": 5.5,
    "NOV": 5.11,
    "DEC": 5.01
  },
  {
    "LONG": -49.81,
    "LAT": -1.6,
    "CIDADE": "Itaberaí",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.9,
    "FEB": 5.41,
    "MAR": 5.25,
    "APR": 5.59,
    "MAY": 5.62,
    "JUN": 5.49,
    "JUL": 5.59,
    "AUG": 6.42,
    "SEP": 5.73,
    "OCT": 5.4,
    "NOV": 4.93,
    "DEC": 4.91
  },
  {
    "LONG": -49.38,
    "LAT": -15.96,
    "CIDADE": "Jesúpolis",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.01,
    "FEB": 5.44,
    "MAR": 5.37,
    "APR": 5.71,
    "MAY": 5.65,
    "JUN": 5.52,
    "JUL": 5.65,
    "AUG": 6.38,
    "SEP": 5.77,
    "OCT": 5.45,
    "NOV": 4.98,
    "DEC": 5
  },
  {
    "LONG": -5.22,
    "LAT": -15.9,
    "CIDADE": "Aragarças",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.03,
    "FEB": 5.37,
    "MAR": 5.28,
    "APR": 5.76,
    "MAY": 5.7,
    "JUN": 5.43,
    "JUL": 5.5,
    "AUG": 6.09,
    "SEP": 5.49,
    "OCT": 5.34,
    "NOV": 5.05,
    "DEC": 5.04
  },
  {
    "LONG": -50.87,
    "LAT": -15.87,
    "CIDADE": "Jussara",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.06,
    "FEB": 5.41,
    "MAR": 5.47,
    "APR": 5.84,
    "MAY": 5.63,
    "JUN": 5.48,
    "JUL": 5.44,
    "AUG": 6.04,
    "SEP": 5.64,
    "OCT": 5.45,
    "NOV": 5.04,
    "DEC": 5.01
  },
  {
    "LONG": -50.14,
    "LAT": -15.94,
    "CIDADE": "Goiás",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.94,
    "FEB": 5.34,
    "MAR": 5.33,
    "APR": 5.65,
    "MAY": 5.66,
    "JUN": 5.51,
    "JUL": 5.5,
    "AUG": 6.32,
    "SEP": 5.66,
    "OCT": 5.43,
    "NOV": 4.96,
    "DEC": 4.88
  },
  {
    "LONG": -49.6,
    "LAT": -15.92,
    "CIDADE": "Itaguari",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.01,
    "FEB": 5.46,
    "MAR": 5.31,
    "APR": 5.61,
    "MAY": 5.62,
    "JUN": 5.52,
    "JUL": 5.55,
    "AUG": 6.33,
    "SEP": 5.71,
    "OCT": 5.42,
    "NOV": 4.92,
    "DEC": 4.92
  },
  {
    "LONG": -49.26,
    "LAT": -1.59,
    "CIDADE": "São Francisco de Goiás",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.09,
    "FEB": 5.43,
    "MAR": 5.32,
    "APR": 5.63,
    "MAY": 5.68,
    "JUN": 5.49,
    "JUL": 5.65,
    "AUG": 6.35,
    "SEP": 5.75,
    "OCT": 5.47,
    "NOV": 4.98,
    "DEC": 4.96
  },
  {
    "LONG": -48.95,
    "LAT": -15.85,
    "CIDADE": "Pirenópolis",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 4.94,
    "FEB": 5.23,
    "MAR": 5.18,
    "APR": 5.55,
    "MAY": 5.63,
    "JUN": 5.45,
    "JUL": 5.7,
    "AUG": 6.36,
    "SEP": 5.74,
    "OCT": 5.36,
    "NOV": 4.78,
    "DEC": 4.8
  },
  {
    "LONG": -4.88,
    "LAT": -15.93,
    "CIDADE": "Corumbá de Goiás",
    "UF": "GOIÁS",
    "anual": 5.37,
    "JAN": 4.87,
    "FEB": 5.2,
    "MAR": 5.09,
    "APR": 5.44,
    "MAY": 5.59,
    "JUN": 5.44,
    "JUL": 5.75,
    "AUG": 6.47,
    "SEP": 5.74,
    "OCT": 5.33,
    "NOV": 4.75,
    "DEC": 4.77
  },
  {
    "LONG": -4.83,
    "LAT": -15.95,
    "CIDADE": "Santo Antônio do Descoberto",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 4.96,
    "FEB": 5.4,
    "MAR": 5.14,
    "APR": 5.42,
    "MAY": 5.64,
    "JUN": 5.72,
    "JUL": 5.87,
    "AUG": 6.61,
    "SEP": 5.84,
    "OCT": 5.38,
    "NOV": 4.78,
    "DEC": 4.96
  },
  {
    "LONG": -51.1,
    "LAT": -15.77,
    "CIDADE": "Santa Fé de Goiás",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 5.07,
    "FEB": 5.4,
    "MAR": 5.36,
    "APR": 5.74,
    "MAY": 5.63,
    "JUN": 5.4,
    "JUL": 5.5,
    "AUG": 6.02,
    "SEP": 5.61,
    "OCT": 5.35,
    "NOV": 4.98,
    "DEC": 5.02
  },
  {
    "LONG": -50.61,
    "LAT": -15.82,
    "CIDADE": "Itapirapuã",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.04,
    "FEB": 5.42,
    "MAR": 5.43,
    "APR": 5.75,
    "MAY": 5.64,
    "JUN": 5.49,
    "JUL": 5.54,
    "AUG": 6.19,
    "SEP": 5.66,
    "OCT": 5.44,
    "NOV": 5.02,
    "DEC": 5
  },
  {
    "LONG": -4.96,
    "LAT": -15.76,
    "CIDADE": "Itaguaru",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.98,
    "FEB": 5.46,
    "MAR": 5.33,
    "APR": 5.64,
    "MAY": 5.64,
    "JUN": 5.47,
    "JUL": 5.42,
    "AUG": 6.27,
    "SEP": 5.69,
    "OCT": 5.45,
    "NOV": 4.91,
    "DEC": 4.97
  },
  {
    "LONG": -48.77,
    "LAT": -15.78,
    "CIDADE": "Cocalzinho de Goiás",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 4.96,
    "FEB": 5.26,
    "MAR": 5.08,
    "APR": 5.45,
    "MAY": 5.6,
    "JUN": 5.5,
    "JUL": 5.74,
    "AUG": 6.57,
    "SEP": 5.81,
    "OCT": 5.35,
    "NOV": 4.72,
    "DEC": 4.84
  },
  {
    "LONG": -46.92,
    "LAT": -15.81,
    "CIDADE": "Cabeceiras",
    "UF": "GOIÁS",
    "anual": 5.69,
    "JAN": 5.34,
    "FEB": 5.86,
    "MAR": 5.46,
    "APR": 5.71,
    "MAY": 5.71,
    "JUN": 5.7,
    "JUL": 5.94,
    "AUG": 6.49,
    "SEP": 6.18,
    "OCT": 5.7,
    "NOV": 4.91,
    "DEC": 5.23
  },
  {
    "LONG": -49.83,
    "LAT": -15.72,
    "CIDADE": "Heitoraí",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 4.93,
    "FEB": 5.38,
    "MAR": 5.22,
    "APR": 5.64,
    "MAY": 5.61,
    "JUN": 5.52,
    "JUL": 5.45,
    "AUG": 6.34,
    "SEP": 5.64,
    "OCT": 5.4,
    "NOV": 4.85,
    "DEC": 4.84
  },
  {
    "LONG": -49.33,
    "LAT": -15.75,
    "CIDADE": "Jaraguá",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5.02,
    "FEB": 5.48,
    "MAR": 5.33,
    "APR": 5.63,
    "MAY": 5.63,
    "JUN": 5.52,
    "JUL": 5.57,
    "AUG": 6.36,
    "SEP": 5.81,
    "OCT": 5.51,
    "NOV": 4.97,
    "DEC": 4.95
  },
  {
    "LONG": -48.26,
    "LAT": -15.74,
    "CIDADE": "Águas Lindas de Goiás",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.96,
    "FEB": 5.32,
    "MAR": 5.13,
    "APR": 5.35,
    "MAY": 5.57,
    "JUN": 5.62,
    "JUL": 5.79,
    "AUG": 6.6,
    "SEP": 5.9,
    "OCT": 5.38,
    "NOV": 4.78,
    "DEC": 4.91
  },
  {
    "LONG": -50.03,
    "LAT": -15.63,
    "CIDADE": "Guaraíta",
    "UF": "GOIÁS",
    "anual": 5.38,
    "JAN": 4.84,
    "FEB": 5.28,
    "MAR": 5.23,
    "APR": 5.6,
    "MAY": 5.67,
    "JUN": 5.48,
    "JUL": 5.52,
    "AUG": 6.29,
    "SEP": 5.66,
    "OCT": 5.38,
    "NOV": 4.8,
    "DEC": 4.86
  },
  {
    "LONG": -49.94,
    "LAT": -15.56,
    "CIDADE": "Itapuranga",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 4.9,
    "FEB": 5.38,
    "MAR": 5.28,
    "APR": 5.68,
    "MAY": 5.67,
    "JUN": 5.47,
    "JUL": 5.45,
    "AUG": 6.25,
    "SEP": 5.69,
    "OCT": 5.42,
    "NOV": 4.86,
    "DEC": 4.9
  },
  {
    "LONG": -49.68,
    "LAT": -1.55,
    "CIDADE": "Uruana",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.98,
    "FEB": 5.46,
    "MAR": 5.36,
    "APR": 5.67,
    "MAY": 5.7,
    "JUN": 5.47,
    "JUL": 5.45,
    "AUG": 6.28,
    "SEP": 5.74,
    "OCT": 5.49,
    "NOV": 4.92,
    "DEC": 4.91
  },
  {
    "LONG": -48.88,
    "LAT": -1.55,
    "CIDADE": "Vila Propício",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.09,
    "FEB": 5.49,
    "MAR": 5.31,
    "APR": 5.65,
    "MAY": 5.65,
    "JUN": 5.51,
    "JUL": 5.58,
    "AUG": 6.48,
    "SEP": 5.85,
    "OCT": 5.5,
    "NOV": 4.91,
    "DEC": 4.96
  },
  {
    "LONG": -47.61,
    "LAT": -15.45,
    "CIDADE": "Planaltina",
    "UF": "GOIÁS",
    "anual": 5.58,
    "JAN": 5.07,
    "FEB": 5.61,
    "MAR": 5.3,
    "APR": 5.59,
    "MAY": 5.63,
    "JUN": 5.68,
    "JUL": 5.94,
    "AUG": 6.69,
    "SEP": 6.14,
    "OCT": 5.52,
    "NOV": 4.79,
    "DEC": 5.03
  },
  {
    "LONG": -47.34,
    "LAT": -15.54,
    "CIDADE": "Formosa",
    "UF": "GOIÁS",
    "anual": 5.62,
    "JAN": 5.22,
    "FEB": 5.63,
    "MAR": 5.35,
    "APR": 5.6,
    "MAY": 5.64,
    "JUN": 5.65,
    "JUL": 6.02,
    "AUG": 6.58,
    "SEP": 6.16,
    "OCT": 5.62,
    "NOV": 4.84,
    "DEC": 5.12
  },
  {
    "LONG": -5.07,
    "LAT": -15.43,
    "CIDADE": "Matrinchã",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.02,
    "FEB": 5.38,
    "MAR": 5.44,
    "APR": 5.71,
    "MAY": 5.63,
    "JUN": 5.32,
    "JUL": 5.49,
    "AUG": 6.13,
    "SEP": 5.64,
    "OCT": 5.43,
    "NOV": 5.03,
    "DEC": 4.98
  },
  {
    "LONG": -50.36,
    "LAT": -15.45,
    "CIDADE": "Faina",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.96,
    "FEB": 5.34,
    "MAR": 5.4,
    "APR": 5.7,
    "MAY": 5.7,
    "JUN": 5.42,
    "JUL": 5.58,
    "AUG": 6.27,
    "SEP": 5.69,
    "OCT": 5.47,
    "NOV": 5.03,
    "DEC": 4.95
  },
  {
    "LONG": -49.71,
    "LAT": -15.36,
    "CIDADE": "Carmo do Rio Verde",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.92,
    "FEB": 5.37,
    "MAR": 5.28,
    "APR": 5.62,
    "MAY": 5.68,
    "JUN": 5.52,
    "JUL": 5.58,
    "AUG": 6.38,
    "SEP": 5.69,
    "OCT": 5.41,
    "NOV": 4.85,
    "DEC": 4.9
  },
  {
    "LONG": -49.51,
    "LAT": -15.45,
    "CIDADE": "Rianápolis",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.96,
    "FEB": 5.41,
    "MAR": 5.31,
    "APR": 5.56,
    "MAY": 5.64,
    "JUN": 5.48,
    "JUL": 5.53,
    "AUG": 6.39,
    "SEP": 5.72,
    "OCT": 5.41,
    "NOV": 4.9,
    "DEC": 4.9
  },
  {
    "LONG": -50.06,
    "LAT": -15.32,
    "CIDADE": "Morro Agudo de Goiás",
    "UF": "GOIÁS",
    "anual": 5.36,
    "JAN": 4.83,
    "FEB": 5.19,
    "MAR": 5.15,
    "APR": 5.46,
    "MAY": 5.66,
    "JUN": 5.48,
    "JUL": 5.63,
    "AUG": 6.32,
    "SEP": 5.64,
    "OCT": 5.35,
    "NOV": 4.84,
    "DEC": 4.8
  },
  {
    "LONG": -49.82,
    "LAT": -15.35,
    "CIDADE": "São Patrício",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 4.84,
    "FEB": 5.23,
    "MAR": 5.15,
    "APR": 5.49,
    "MAY": 5.65,
    "JUN": 5.43,
    "JUL": 5.53,
    "AUG": 6.34,
    "SEP": 5.66,
    "OCT": 5.33,
    "NOV": 4.82,
    "DEC": 4.79
  },
  {
    "LONG": -49.6,
    "LAT": -15.31,
    "CIDADE": "Ceres",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 4.88,
    "FEB": 5.31,
    "MAR": 5.18,
    "APR": 5.56,
    "MAY": 5.66,
    "JUN": 5.46,
    "JUL": 5.61,
    "AUG": 6.4,
    "SEP": 5.72,
    "OCT": 5.38,
    "NOV": 4.8,
    "DEC": 4.84
  },
  {
    "LONG": -49.58,
    "LAT": -15.31,
    "CIDADE": "Rialma",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.94,
    "FEB": 5.41,
    "MAR": 5.29,
    "APR": 5.64,
    "MAY": 5.64,
    "JUN": 5.43,
    "JUL": 5.57,
    "AUG": 6.39,
    "SEP": 5.75,
    "OCT": 5.42,
    "NOV": 4.87,
    "DEC": 4.9
  },
  {
    "LONG": -4.94,
    "LAT": -15.3,
    "CIDADE": "Santa Isabel",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.94,
    "FEB": 5.43,
    "MAR": 5.29,
    "APR": 5.58,
    "MAY": 5.63,
    "JUN": 5.42,
    "JUL": 5.57,
    "AUG": 6.4,
    "SEP": 5.76,
    "OCT": 5.38,
    "NOV": 4.86,
    "DEC": 4.92
  },
  {
    "LONG": -49.12,
    "LAT": -15.31,
    "CIDADE": "Goianésia",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 4.93,
    "FEB": 5.37,
    "MAR": 5.26,
    "APR": 5.59,
    "MAY": 5.64,
    "JUN": 5.51,
    "JUL": 5.61,
    "AUG": 6.3,
    "SEP": 5.79,
    "OCT": 5.37,
    "NOV": 4.84,
    "DEC": 4.89
  },
  {
    "LONG": -51.17,
    "LAT": -15.24,
    "CIDADE": "Britânia",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.19,
    "FEB": 5.5,
    "MAR": 5.52,
    "APR": 5.79,
    "MAY": 5.66,
    "JUN": 5.44,
    "JUL": 5.57,
    "AUG": 6.08,
    "SEP": 5.73,
    "OCT": 5.41,
    "NOV": 5.05,
    "DEC": 5.08
  },
  {
    "LONG": -49.8,
    "LAT": -15.16,
    "CIDADE": "Rubiataba",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 4.85,
    "FEB": 5.23,
    "MAR": 5.19,
    "APR": 5.52,
    "MAY": 5.66,
    "JUN": 5.48,
    "JUL": 5.6,
    "AUG": 6.37,
    "SEP": 5.74,
    "OCT": 5.37,
    "NOV": 4.85,
    "DEC": 4.83
  },
  {
    "LONG": -49.67,
    "LAT": -15.17,
    "CIDADE": "Ipiranga de Goiás",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 4.89,
    "FEB": 5.32,
    "MAR": 5.25,
    "APR": 5.56,
    "MAY": 5.65,
    "JUN": 5.41,
    "JUL": 5.57,
    "AUG": 6.3,
    "SEP": 5.76,
    "OCT": 5.38,
    "NOV": 4.85,
    "DEC": 4.86
  },
  {
    "LONG": -48.29,
    "LAT": -15.16,
    "CIDADE": "Padre Bernardo",
    "UF": "GOIÁS",
    "anual": 5.6,
    "JAN": 5.22,
    "FEB": 5.63,
    "MAR": 5.49,
    "APR": 5.7,
    "MAY": 5.72,
    "JUN": 5.57,
    "JUL": 5.67,
    "AUG": 6.47,
    "SEP": 5.94,
    "OCT": 5.62,
    "NOV": 5,
    "DEC": 5.12
  },
  {
    "LONG": -50.63,
    "LAT": -15.09,
    "CIDADE": "Araguapaz",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.01,
    "FEB": 5.38,
    "MAR": 5.46,
    "APR": 5.7,
    "MAY": 5.61,
    "JUN": 5.37,
    "JUL": 5.57,
    "AUG": 6.07,
    "SEP": 5.62,
    "OCT": 5.44,
    "NOV": 5.03,
    "DEC": 4.94
  },
  {
    "LONG": -49.57,
    "LAT": -15.15,
    "CIDADE": "Nova Glória",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 4.89,
    "FEB": 5.36,
    "MAR": 5.29,
    "APR": 5.58,
    "MAY": 5.58,
    "JUN": 5.39,
    "JUL": 5.55,
    "AUG": 6.28,
    "SEP": 5.79,
    "OCT": 5.44,
    "NOV": 4.87,
    "DEC": 4.88
  },
  {
    "LONG": -49.12,
    "LAT": -15.14,
    "CIDADE": "Santa Rita do Novo Destino",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.98,
    "FEB": 5.43,
    "MAR": 5.34,
    "APR": 5.61,
    "MAY": 5.66,
    "JUN": 5.5,
    "JUL": 5.62,
    "AUG": 6.28,
    "SEP": 5.89,
    "OCT": 5.46,
    "NOV": 4.9,
    "DEC": 4.91
  },
  {
    "LONG": -48.16,
    "LAT": -15.06,
    "CIDADE": "Mimoso de Goiás",
    "UF": "GOIÁS",
    "anual": 5.57,
    "JAN": 5.09,
    "FEB": 5.54,
    "MAR": 5.39,
    "APR": 5.66,
    "MAY": 5.76,
    "JUN": 5.62,
    "JUL": 5.79,
    "AUG": 6.58,
    "SEP": 5.93,
    "OCT": 5.54,
    "NOV": 4.91,
    "DEC": 5.04
  },
  {
    "LONG": -49.9,
    "LAT": -1.5,
    "CIDADE": "Nova América",
    "UF": "GOIÁS",
    "anual": 5.35,
    "JAN": 4.75,
    "FEB": 5.18,
    "MAR": 5.07,
    "APR": 5.44,
    "MAY": 5.6,
    "JUN": 5.48,
    "JUL": 5.65,
    "AUG": 6.36,
    "SEP": 5.69,
    "OCT": 5.36,
    "NOV": 4.84,
    "DEC": 4.79
  },
  {
    "LONG": -49.55,
    "LAT": -14.95,
    "CIDADE": "Itapaci",
    "UF": "GOIÁS",
    "anual": 5.4,
    "JAN": 4.87,
    "FEB": 5.34,
    "MAR": 5.26,
    "APR": 5.57,
    "MAY": 5.57,
    "JUN": 5.4,
    "JUL": 5.57,
    "AUG": 6.29,
    "SEP": 5.8,
    "OCT": 5.37,
    "NOV": 4.88,
    "DEC": 4.88
  },
  {
    "LONG": -4.89,
    "LAT": -14.97,
    "CIDADE": "Barro Alto",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.89,
    "FEB": 5.33,
    "MAR": 5.23,
    "APR": 5.58,
    "MAY": 5.65,
    "JUN": 5.47,
    "JUL": 5.55,
    "AUG": 6.51,
    "SEP": 5.86,
    "OCT": 5.44,
    "NOV": 4.8,
    "DEC": 4.9
  },
  {
    "LONG": -47.78,
    "LAT": -14.98,
    "CIDADE": "Água Fria de Goiás",
    "UF": "GOIÁS",
    "anual": 5.55,
    "JAN": 5.1,
    "FEB": 5.56,
    "MAR": 5.32,
    "APR": 5.67,
    "MAY": 5.66,
    "JUN": 5.56,
    "JUL": 5.81,
    "AUG": 6.58,
    "SEP": 6.06,
    "OCT": 5.51,
    "NOV": 4.7,
    "DEC": 5.02
  },
  {
    "LONG": -47.05,
    "LAT": -15.03,
    "CIDADE": "Vila Boa",
    "UF": "GOIÁS",
    "anual": 5.74,
    "JAN": 5.46,
    "FEB": 5.85,
    "MAR": 5.57,
    "APR": 5.79,
    "MAY": 5.68,
    "JUN": 5.7,
    "JUL": 6.07,
    "AUG": 6.5,
    "SEP": 6.17,
    "OCT": 5.72,
    "NOV": 5.06,
    "DEC": 5.3
  },
  {
    "LONG": -51.08,
    "LAT": -14.93,
    "CIDADE": "Aruanã",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.1,
    "FEB": 5.45,
    "MAR": 5.49,
    "APR": 5.78,
    "MAY": 5.62,
    "JUN": 5.33,
    "JUL": 5.58,
    "AUG": 5.95,
    "SEP": 5.69,
    "OCT": 5.43,
    "NOV": 5.07,
    "DEC": 5
  },
  {
    "LONG": -49.33,
    "LAT": -1.49,
    "CIDADE": "São Luiz do Norte",
    "UF": "GOIÁS",
    "anual": 5.42,
    "JAN": 4.96,
    "FEB": 5.44,
    "MAR": 5.32,
    "APR": 5.58,
    "MAY": 5.61,
    "JUN": 5.36,
    "JUL": 5.54,
    "AUG": 6.26,
    "SEP": 5.77,
    "OCT": 5.42,
    "NOV": 4.9,
    "DEC": 4.92
  },
  {
    "LONG": -49.58,
    "LAT": -14.76,
    "CIDADE": "Pilar de Goiás",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 4.91,
    "FEB": 5.23,
    "MAR": 5.11,
    "APR": 5.43,
    "MAY": 5.6,
    "JUN": 5.46,
    "JUL": 5.67,
    "AUG": 6.55,
    "SEP": 5.75,
    "OCT": 5.32,
    "NOV": 4.81,
    "DEC": 4.87
  },
  {
    "LONG": -46.25,
    "LAT": -14.8,
    "CIDADE": "Sítio d'Abadia",
    "UF": "GOIÁS",
    "anual": 5.69,
    "JAN": 5.47,
    "FEB": 5.74,
    "MAR": 5.48,
    "APR": 5.76,
    "MAY": 5.58,
    "JUN": 5.6,
    "JUL": 6.04,
    "AUG": 6.49,
    "SEP": 6.23,
    "OCT": 5.68,
    "NOV": 4.95,
    "DEC": 5.31
  },
  {
    "LONG": -50.57,
    "LAT": -14.75,
    "CIDADE": "Mozarlândia",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 4.99,
    "FEB": 5.43,
    "MAR": 5.38,
    "APR": 5.68,
    "MAY": 5.58,
    "JUN": 5.39,
    "JUL": 5.59,
    "AUG": 6.17,
    "SEP": 5.66,
    "OCT": 5.36,
    "NOV": 4.98,
    "DEC": 4.92
  },
  {
    "LONG": -49.7,
    "LAT": -14.73,
    "CIDADE": "Guarinos",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 4.98,
    "FEB": 5.37,
    "MAR": 5.33,
    "APR": 5.59,
    "MAY": 5.6,
    "JUN": 5.54,
    "JUL": 5.73,
    "AUG": 6.46,
    "SEP": 5.77,
    "OCT": 5.47,
    "NOV": 4.94,
    "DEC": 4.94
  },
  {
    "LONG": -49.47,
    "LAT": -14.72,
    "CIDADE": "Hidrolina",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 4.91,
    "FEB": 5.25,
    "MAR": 5.21,
    "APR": 5.47,
    "MAY": 5.57,
    "JUN": 5.5,
    "JUL": 5.7,
    "AUG": 6.48,
    "SEP": 5.8,
    "OCT": 5.33,
    "NOV": 4.82,
    "DEC": 4.88
  },
  {
    "LONG": -47.52,
    "LAT": -14.71,
    "CIDADE": "São João d'Aliança",
    "UF": "GOIÁS",
    "anual": 5.58,
    "JAN": 5.16,
    "FEB": 5.56,
    "MAR": 5.34,
    "APR": 5.55,
    "MAY": 5.66,
    "JUN": 5.74,
    "JUL": 5.92,
    "AUG": 6.66,
    "SEP": 6.13,
    "OCT": 5.5,
    "NOV": 4.69,
    "DEC": 5.03
  },
  {
    "LONG": -46.17,
    "LAT": -14.56,
    "CIDADE": "Damianópolis",
    "UF": "GOIÁS",
    "anual": 5.74,
    "JAN": 5.54,
    "FEB": 5.78,
    "MAR": 5.54,
    "APR": 5.81,
    "MAY": 5.69,
    "JUN": 5.69,
    "JUL": 6.03,
    "AUG": 6.53,
    "SEP": 6.23,
    "OCT": 5.74,
    "NOV": 4.95,
    "DEC": 5.37
  },
  {
    "LONG": -49.98,
    "LAT": -14.54,
    "CIDADE": "Crixás",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.98,
    "FEB": 5.38,
    "MAR": 5.29,
    "APR": 5.64,
    "MAY": 5.62,
    "JUN": 5.44,
    "JUL": 5.68,
    "AUG": 6.28,
    "SEP": 5.75,
    "OCT": 5.47,
    "NOV": 4.93,
    "DEC": 4.95
  },
  {
    "LONG": "#VALOR!",
    "LAT": -14.52,
    "CIDADE": "Uruaçu",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.97,
    "FEB": 5.46,
    "MAR": 5.34,
    "APR": 5.57,
    "MAY": 5.65,
    "JUN": 5.41,
    "JUL": 5.64,
    "AUG": 6.29,
    "SEP": 5.82,
    "OCT": 5.45,
    "NOV": 4.84,
    "DEC": 4.98
  },
  {
    "LONG": -48.46,
    "LAT": -14.47,
    "CIDADE": "Niquelândia",
    "UF": "GOIÁS",
    "anual": 5.49,
    "JAN": 5.01,
    "FEB": 5.38,
    "MAR": 5.21,
    "APR": 5.67,
    "MAY": 5.69,
    "JUN": 5.46,
    "JUL": 5.85,
    "AUG": 6.53,
    "SEP": 5.88,
    "OCT": 5.51,
    "NOV": 4.78,
    "DEC": 4.93
  },
  {
    "LONG": -46.43,
    "LAT": -14.46,
    "CIDADE": "Buritinópolis",
    "UF": "GOIÁS",
    "anual": 5.76,
    "JAN": 5.6,
    "FEB": 5.88,
    "MAR": 5.64,
    "APR": 5.92,
    "MAY": 5.71,
    "JUN": 5.64,
    "JUL": 5.96,
    "AUG": 6.44,
    "SEP": 6.16,
    "OCT": 5.72,
    "NOV": 5.05,
    "DEC": 5.42
  },
  {
    "LONG": -46.49,
    "LAT": -14.46,
    "CIDADE": "Simolândia",
    "UF": "GOIÁS",
    "anual": 5.76,
    "JAN": 5.6,
    "FEB": 5.88,
    "MAR": 5.64,
    "APR": 5.92,
    "MAY": 5.71,
    "JUN": 5.64,
    "JUL": 5.96,
    "AUG": 6.44,
    "SEP": 6.16,
    "OCT": 5.72,
    "NOV": 5.05,
    "DEC": 5.42
  },
  {
    "LONG": -46.49,
    "LAT": -14.48,
    "CIDADE": "Alvorada do Norte",
    "UF": "GOIÁS",
    "anual": 5.76,
    "JAN": 5.6,
    "FEB": 5.88,
    "MAR": 5.64,
    "APR": 5.92,
    "MAY": 5.71,
    "JUN": 5.64,
    "JUL": 5.96,
    "AUG": 6.44,
    "SEP": 6.16,
    "OCT": 5.72,
    "NOV": 5.05,
    "DEC": 5.42
  },
  {
    "LONG": -4.61,
    "LAT": -14.5,
    "CIDADE": "Mambaí",
    "UF": "GOIÁS",
    "anual": 5.76,
    "JAN": 5.57,
    "FEB": 5.79,
    "MAR": 5.57,
    "APR": 5.87,
    "MAY": 5.72,
    "JUN": 5.73,
    "JUL": 6.06,
    "AUG": 6.57,
    "SEP": 6.22,
    "OCT": 5.73,
    "NOV": 4.94,
    "DEC": 5.37
  },
  {
    "LONG": -49.71,
    "LAT": -14.43,
    "CIDADE": "Santa Terezinha de Goiás",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 5,
    "FEB": 5.38,
    "MAR": 5.35,
    "APR": 5.69,
    "MAY": 5.63,
    "JUN": 5.46,
    "JUL": 5.73,
    "AUG": 6.29,
    "SEP": 5.78,
    "OCT": 5.48,
    "NOV": 4.95,
    "DEC": 4.99
  },
  {
    "LONG": -47.04,
    "LAT": -14.44,
    "CIDADE": "Flores de Goiás",
    "UF": "GOIÁS",
    "anual": 5.74,
    "JAN": 5.49,
    "FEB": 5.85,
    "MAR": 5.6,
    "APR": 5.83,
    "MAY": 5.71,
    "JUN": 5.73,
    "JUL": 6.03,
    "AUG": 6.42,
    "SEP": 6.22,
    "OCT": 5.71,
    "NOV": 5,
    "DEC": 5.35
  },
  {
    "LONG": -49.92,
    "LAT": -1.43,
    "CIDADE": "Uirapuru",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.99,
    "FEB": 5.35,
    "MAR": 5.29,
    "APR": 5.67,
    "MAY": 5.55,
    "JUN": 5.45,
    "JUL": 5.71,
    "AUG": 6.3,
    "SEP": 5.78,
    "OCT": 5.45,
    "NOV": 4.94,
    "DEC": 4.96
  },
  {
    "LONG": -49.66,
    "LAT": -14.27,
    "CIDADE": "Campos Verdes",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.05,
    "FEB": 5.38,
    "MAR": 5.37,
    "APR": 5.63,
    "MAY": 5.61,
    "JUN": 5.37,
    "JUL": 5.74,
    "AUG": 6.27,
    "SEP": 5.8,
    "OCT": 5.52,
    "NOV": 4.97,
    "DEC": 5
  },
  {
    "LONG": -49.39,
    "LAT": -14.29,
    "CIDADE": "Nova Iguaçu de Goiás",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.07,
    "FEB": 5.43,
    "MAR": 5.4,
    "APR": 5.6,
    "MAY": 5.64,
    "JUN": 5.43,
    "JUL": 5.81,
    "AUG": 6.32,
    "SEP": 5.83,
    "OCT": 5.55,
    "NOV": 4.95,
    "DEC": 4.98
  },
  {
    "LONG": -49.15,
    "LAT": -14.31,
    "CIDADE": "Campinorte",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.01,
    "FEB": 5.41,
    "MAR": 5.34,
    "APR": 5.56,
    "MAY": 5.66,
    "JUN": 5.48,
    "JUL": 5.75,
    "AUG": 6.22,
    "SEP": 5.81,
    "OCT": 5.49,
    "NOV": 4.88,
    "DEC": 4.94
  },
  {
    "LONG": -49.34,
    "LAT": -14.2,
    "CIDADE": "Alto Horizonte",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 5.05,
    "FEB": 5.43,
    "MAR": 5.38,
    "APR": 5.59,
    "MAY": 5.63,
    "JUN": 5.45,
    "JUL": 5.85,
    "AUG": 6.29,
    "SEP": 5.83,
    "OCT": 5.55,
    "NOV": 4.94,
    "DEC": 4.98
  },
  {
    "LONG": -48.08,
    "LAT": -14.15,
    "CIDADE": "Colinas do Sul",
    "UF": "GOIÁS",
    "anual": 5.53,
    "JAN": 5.02,
    "FEB": 5.34,
    "MAR": 5.34,
    "APR": 5.6,
    "MAY": 5.7,
    "JUN": 5.66,
    "JUL": 5.9,
    "AUG": 6.56,
    "SEP": 5.97,
    "OCT": 5.51,
    "NOV": 4.86,
    "DEC": 4.96
  },
  {
    "LONG": -5.03,
    "LAT": -14.1,
    "CIDADE": "Nova Crixás",
    "UF": "GOIÁS",
    "anual": 5.39,
    "JAN": 5.01,
    "FEB": 5.4,
    "MAR": 5.26,
    "APR": 5.55,
    "MAY": 5.52,
    "JUN": 5.33,
    "JUL": 5.67,
    "AUG": 6.18,
    "SEP": 5.69,
    "OCT": 5.33,
    "NOV": 4.84,
    "DEC": 4.94
  },
  {
    "LONG": -47.52,
    "LAT": "#VALOR!",
    "CIDADE": "Alto Paraíso de Goiás",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.85,
    "FEB": 5.17,
    "MAR": 4.97,
    "APR": 5.35,
    "MAY": 5.62,
    "JUN": 5.83,
    "JUL": 6.26,
    "AUG": 6.83,
    "SEP": 6.04,
    "OCT": 5.22,
    "NOV": 4.39,
    "DEC": 4.74
  },
  {
    "LONG": -46.63,
    "LAT": -14.1,
    "CIDADE": "Iaciara",
    "UF": "GOIÁS",
    "anual": 5.76,
    "JAN": 5.54,
    "FEB": 5.78,
    "MAR": 5.58,
    "APR": 5.79,
    "MAY": 5.72,
    "JUN": 5.75,
    "JUL": 6.09,
    "AUG": 6.44,
    "SEP": 6.17,
    "OCT": 5.7,
    "NOV": 5.08,
    "DEC": 5.43
  },
  {
    "LONG": -46.36,
    "LAT": -14.09,
    "CIDADE": "Posse",
    "UF": "GOIÁS",
    "anual": 5.62,
    "JAN": 5.35,
    "FEB": 5.55,
    "MAR": 5.35,
    "APR": 5.54,
    "MAY": 5.59,
    "JUN": 5.67,
    "JUL": 6.06,
    "AUG": 6.58,
    "SEP": 6.13,
    "OCT": 5.58,
    "NOV": 4.81,
    "DEC": 5.21
  },
  {
    "LONG": -4.92,
    "LAT": -14.02,
    "CIDADE": "Mara Rosa",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 5.02,
    "FEB": 5.3,
    "MAR": 5.2,
    "APR": 5.53,
    "MAY": 5.63,
    "JUN": 5.5,
    "JUL": 5.86,
    "AUG": 6.39,
    "SEP": 5.84,
    "OCT": 5.42,
    "NOV": 4.84,
    "DEC": 4.9
  },
  {
    "LONG": -49.3,
    "LAT": -13.93,
    "CIDADE": "Amaralina",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 5.01,
    "FEB": 5.37,
    "MAR": 5.19,
    "APR": 5.6,
    "MAY": 5.62,
    "JUN": 5.39,
    "JUL": 5.81,
    "AUG": 6.45,
    "SEP": 5.84,
    "OCT": 5.43,
    "NOV": 4.88,
    "DEC": 4.94
  },
  {
    "LONG": -49.07,
    "LAT": -13.87,
    "CIDADE": "Estrela do Norte",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.96,
    "FEB": 5.28,
    "MAR": 5.18,
    "APR": 5.49,
    "MAY": 5.64,
    "JUN": 5.6,
    "JUL": 5.92,
    "AUG": 6.46,
    "SEP": 5.86,
    "OCT": 5.42,
    "NOV": 4.81,
    "DEC": 4.9
  },
  {
    "LONG": -46.49,
    "LAT": -13.95,
    "CIDADE": "Guarani de Goiás",
    "UF": "GOIÁS",
    "anual": 5.7,
    "JAN": 5.48,
    "FEB": 5.7,
    "MAR": 5.54,
    "APR": 5.73,
    "MAY": 5.68,
    "JUN": 5.7,
    "JUL": 6.03,
    "AUG": 6.55,
    "SEP": 6.16,
    "OCT": 5.55,
    "NOV": 4.98,
    "DEC": 5.31
  },
  {
    "LONG": -50.28,
    "LAT": -13.77,
    "CIDADE": "Mundo Novo",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.98,
    "FEB": 5.42,
    "MAR": 5.3,
    "APR": 5.54,
    "MAY": 5.55,
    "JUN": 5.43,
    "JUL": 5.81,
    "AUG": 6.18,
    "SEP": 5.82,
    "OCT": 5.39,
    "NOV": 4.88,
    "DEC": 4.97
  },
  {
    "LONG": -48.57,
    "LAT": -13.79,
    "CIDADE": "Campinaçu",
    "UF": "GOIÁS",
    "anual": 5.41,
    "JAN": 4.88,
    "FEB": 5.22,
    "MAR": 5.13,
    "APR": 5.46,
    "MAY": 5.64,
    "JUN": 5.51,
    "JUL": 5.91,
    "AUG": 6.45,
    "SEP": 5.9,
    "OCT": 5.37,
    "NOV": 4.72,
    "DEC": 4.79
  },
  {
    "LONG": -4.75,
    "LAT": -13.79,
    "CIDADE": "Cavalcante",
    "UF": "GOIÁS",
    "anual": 5.55,
    "JAN": 5.09,
    "FEB": 5.3,
    "MAR": 5.16,
    "APR": 5.51,
    "MAY": 5.7,
    "JUN": 5.84,
    "JUL": 6.16,
    "AUG": 6.63,
    "SEP": 6.18,
    "OCT": 5.45,
    "NOV": 4.72,
    "DEC": 4.88
  },
  {
    "LONG": -47.27,
    "LAT": -13.78,
    "CIDADE": "Teresina de Goiás",
    "UF": "GOIÁS",
    "anual": 5.58,
    "JAN": 5.17,
    "FEB": 5.42,
    "MAR": 5.25,
    "APR": 5.49,
    "MAY": 5.67,
    "JUN": 5.74,
    "JUL": 6.16,
    "AUG": 6.57,
    "SEP": 6.19,
    "OCT": 5.55,
    "NOV": 4.77,
    "DEC": 5.02
  },
  {
    "LONG": -49.83,
    "LAT": -13.65,
    "CIDADE": "Bonópolis",
    "UF": "GOIÁS",
    "anual": 5.45,
    "JAN": 4.98,
    "FEB": 5.36,
    "MAR": 5.28,
    "APR": 5.59,
    "MAY": 5.58,
    "JUN": 5.47,
    "JUL": 5.8,
    "AUG": 6.23,
    "SEP": 5.88,
    "OCT": 5.41,
    "NOV": 4.88,
    "DEC": 4.96
  },
  {
    "LONG": -49.27,
    "LAT": -13.73,
    "CIDADE": "Mutunópolis",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.98,
    "FEB": 5.33,
    "MAR": 5.2,
    "APR": 5.57,
    "MAY": 5.64,
    "JUN": 5.49,
    "JUL": 5.83,
    "AUG": 6.32,
    "SEP": 5.87,
    "OCT": 5.49,
    "NOV": 4.85,
    "DEC": 4.89
  },
  {
    "LONG": -49.01,
    "LAT": -13.71,
    "CIDADE": "Santa Tereza de Goiás",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 4.99,
    "FEB": 5.36,
    "MAR": 5.24,
    "APR": 5.58,
    "MAY": 5.64,
    "JUN": 5.53,
    "JUL": 5.83,
    "AUG": 6.39,
    "SEP": 5.86,
    "OCT": 5.46,
    "NOV": 4.85,
    "DEC": 4.89
  },
  {
    "LONG": -46.89,
    "LAT": -13.74,
    "CIDADE": "Nova Roma",
    "UF": "GOIÁS",
    "anual": 5.75,
    "JAN": 5.51,
    "FEB": 5.68,
    "MAR": 5.53,
    "APR": 5.81,
    "MAY": 5.77,
    "JUN": 5.83,
    "JUL": 6.13,
    "AUG": 6.5,
    "SEP": 6.18,
    "OCT": 5.67,
    "NOV": 5.01,
    "DEC": 5.36
  },
  {
    "LONG": -48.88,
    "LAT": -13.65,
    "CIDADE": "Formoso",
    "UF": "GOIÁS",
    "anual": 5.48,
    "JAN": 4.97,
    "FEB": 5.34,
    "MAR": 5.22,
    "APR": 5.56,
    "MAY": 5.72,
    "JUN": 5.52,
    "JUL": 5.89,
    "AUG": 6.42,
    "SEP": 5.91,
    "OCT": 5.45,
    "NOV": 4.84,
    "DEC": 4.94
  },
  {
    "LONG": -48.74,
    "LAT": -13.51,
    "CIDADE": "Trombas",
    "UF": "GOIÁS",
    "anual": 5.5,
    "JAN": 4.98,
    "FEB": 5.35,
    "MAR": 5.2,
    "APR": 5.55,
    "MAY": 5.71,
    "JUN": 5.59,
    "JUL": 5.94,
    "AUG": 6.5,
    "SEP": 5.95,
    "OCT": 5.47,
    "NOV": 4.84,
    "DEC": 4.92
  },
  {
    "LONG": -4.82,
    "LAT": -13.53,
    "CIDADE": "Minaçu",
    "UF": "GOIÁS",
    "anual": 5.43,
    "JAN": 5.02,
    "FEB": 5.29,
    "MAR": 5.25,
    "APR": 5.51,
    "MAY": 5.65,
    "JUN": 5.41,
    "JUL": 5.79,
    "AUG": 6.29,
    "SEP": 5.86,
    "OCT": 5.39,
    "NOV": 4.81,
    "DEC": 4.9
  },
  {
    "LONG": -49.15,
    "LAT": -13.44,
    "CIDADE": "Porangatu",
    "UF": "GOIÁS",
    "anual": 5.47,
    "JAN": 5.01,
    "FEB": 5.34,
    "MAR": 5.2,
    "APR": 5.61,
    "MAY": 5.61,
    "JUN": 5.52,
    "JUL": 5.82,
    "AUG": 6.37,
    "SEP": 5.92,
    "OCT": 5.47,
    "NOV": 4.86,
    "DEC": 4.95
  },
  {
    "LONG": -46.31,
    "LAT": -13.4,
    "CIDADE": "São Domingos",
    "UF": "GOIÁS",
    "anual": 5.68,
    "JAN": 5.48,
    "FEB": 5.54,
    "MAR": 5.38,
    "APR": 5.65,
    "MAY": 5.76,
    "JUN": 5.82,
    "JUL": 6.1,
    "AUG": 6.53,
    "SEP": 6.19,
    "OCT": 5.61,
    "NOV": 4.87,
    "DEC": 5.25
  },
  {
    "LONG": -50.16,
    "LAT": -13.27,
    "CIDADE": "São Miguel do Araguaia",
    "UF": "GOIÁS",
    "anual": 5.38,
    "JAN": 4.94,
    "FEB": 5.2,
    "MAR": 5.17,
    "APR": 5.4,
    "MAY": 5.53,
    "JUN": 5.5,
    "JUL": 5.78,
    "AUG": 6.15,
    "SEP": 5.8,
    "OCT": 5.3,
    "NOV": 4.84,
    "DEC": 4.93
  },
  {
    "LONG": -48.69,
    "LAT": -13.35,
    "CIDADE": "Montividiu do Norte",
    "UF": "GOIÁS",
    "anual": 5.46,
    "JAN": 4.97,
    "FEB": 5.24,
    "MAR": 5.18,
    "APR": 5.53,
    "MAY": 5.59,
    "JUN": 5.61,
    "JUL": 5.95,
    "AUG": 6.48,
    "SEP": 5.96,
    "OCT": 5.39,
    "NOV": 4.76,
    "DEC": 4.85
  },
  {
    "LONG": -46.89,
    "LAT": -13.26,
    "CIDADE": "Monte Alegre de Goiás",
    "UF": "GOIÁS",
    "anual": 5.68,
    "JAN": 5.47,
    "FEB": 5.54,
    "MAR": 5.46,
    "APR": 5.78,
    "MAY": 5.75,
    "JUN": 5.71,
    "JUL": 6.04,
    "AUG": 6.41,
    "SEP": 6.11,
    "OCT": 5.58,
    "NOV": 5.02,
    "DEC": 5.33
  },
  {
    "LONG": -46.4,
    "LAT": -13.29,
    "CIDADE": "Divinópolis de Goiás",
    "UF": "GOIÁS",
    "anual": 5.65,
    "JAN": 5.47,
    "FEB": 5.54,
    "MAR": 5.28,
    "APR": 5.53,
    "MAY": 5.65,
    "JUN": 5.8,
    "JUL": 6.15,
    "AUG": 6.5,
    "SEP": 6.23,
    "OCT": 5.59,
    "NOV": 4.82,
    "DEC": 5.2
  },
  {
    "LONG": -49.51,
    "LAT": -13.24,
    "CIDADE": "Novo Planalto",
    "UF": "GOIÁS",
    "anual": 5.44,
    "JAN": 4.96,
    "FEB": 5.24,
    "MAR": 5.16,
    "APR": 5.52,
    "MAY": 5.58,
    "JUN": 5.52,
    "JUL": 5.83,
    "AUG": 6.37,
    "SEP": 5.85,
    "OCT": 5.43,
    "NOV": 4.88,
    "DEC": 4.99
  },
  {
    "LONG": -46.77,
    "LAT": -13.04,
    "CIDADE": "Campos Belos",
    "UF": "GOIÁS",
    "anual": 5.66,
    "JAN": 5.43,
    "FEB": 5.44,
    "MAR": 5.32,
    "APR": 5.68,
    "MAY": 5.72,
    "JUN": 5.71,
    "JUL": 6.07,
    "AUG": 6.48,
    "SEP": 6.09,
    "OCT": 5.63,
    "NOV": 5.12,
    "DEC": 5.26
  },
  {
    "LONG": -45.93,
    "LAT": -9.11,
    "CIDADE": "Alto Parnaíba",
    "UF": "MARANHÃO",
    "anual": 5.57,
    "JAN": 5.05,
    "FEB": 5.09,
    "MAR": 5.12,
    "APR": 5.37,
    "MAY": 5.67,
    "JUN": 5.78,
    "JUL": 5.9,
    "AUG": 6.5,
    "SEP": 6.49,
    "OCT": 5.68,
    "NOV": 5.12,
    "DEC": 5.07
  },
  {
    "LONG": -45.75,
    "LAT": -8.47,
    "CIDADE": "Tasso Fragoso",
    "UF": "MARANHÃO",
    "anual": 5.53,
    "JAN": 4.91,
    "FEB": 5.06,
    "MAR": 5.11,
    "APR": 5.43,
    "MAY": 5.68,
    "JUN": 5.81,
    "JUL": 5.92,
    "AUG": 6.25,
    "SEP": 6.52,
    "OCT": 5.69,
    "NOV": 5.11,
    "DEC": 4.91
  },
  {
    "LONG": -46.04,
    "LAT": -7.53,
    "CIDADE": "Balsas",
    "UF": "MARANHÃO",
    "anual": 5.39,
    "JAN": 4.73,
    "FEB": 4.94,
    "MAR": 5.07,
    "APR": 5.26,
    "MAY": 5.48,
    "JUN": 5.68,
    "JUL": 5.68,
    "AUG": 6.04,
    "SEP": 6.46,
    "OCT": 5.6,
    "NOV": 4.97,
    "DEC": 4.81
  },
  {
    "LONG": -46.62,
    "LAT": -7.36,
    "CIDADE": "Riachão",
    "UF": "MARANHÃO",
    "anual": 5.26,
    "JAN": 4.56,
    "FEB": 4.77,
    "MAR": 4.82,
    "APR": 5.15,
    "MAY": 5.49,
    "JUN": 5.68,
    "JUL": 5.59,
    "AUG": 6.13,
    "SEP": 6.26,
    "OCT": 5.36,
    "NOV": 4.72,
    "DEC": 4.56
  },
  {
    "LONG": -47.46,
    "LAT": -7.34,
    "CIDADE": "Carolina",
    "UF": "MARANHÃO",
    "anual": 5.23,
    "JAN": 4.66,
    "FEB": 4.81,
    "MAR": 4.81,
    "APR": 4.99,
    "MAY": 5.39,
    "JUN": 5.73,
    "JUL": 5.66,
    "AUG": 6.19,
    "SEP": 6.09,
    "OCT": 5.24,
    "NOV": 4.65,
    "DEC": 4.54
  },
  {
    "LONG": -44.55,
    "LAT": -7.22,
    "CIDADE": "Benedito Leite",
    "UF": "MARANHÃO",
    "anual": 5.54,
    "JAN": 4.8,
    "FEB": 4.97,
    "MAR": 5.02,
    "APR": 5.26,
    "MAY": 5.57,
    "JUN": 5.76,
    "JUL": 5.96,
    "AUG": 6.27,
    "SEP": 6.62,
    "OCT": 5.92,
    "NOV": 5.34,
    "DEC": 5.04
  },
  {
    "LONG": -4.63,
    "LAT": -0.71,
    "CIDADE": "Nova Colinas",
    "UF": "MARANHÃO",
    "anual": 5.26,
    "JAN": 4.42,
    "FEB": 4.7,
    "MAR": 4.68,
    "APR": 5.05,
    "MAY": 5.36,
    "JUN": 5.74,
    "JUL": 5.81,
    "AUG": 6.34,
    "SEP": 6.31,
    "OCT": 5.38,
    "NOV": 4.79,
    "DEC": 4.54
  },
  {
    "LONG": -45.35,
    "LAT": -7.14,
    "CIDADE": "Sambaíba",
    "UF": "MARANHÃO",
    "anual": 5.46,
    "JAN": 4.66,
    "FEB": 4.91,
    "MAR": 5.02,
    "APR": 5.23,
    "MAY": 5.54,
    "JUN": 5.76,
    "JUL": 5.87,
    "AUG": 6.25,
    "SEP": 6.59,
    "OCT": 5.73,
    "NOV": 5.08,
    "DEC": 4.84
  },
  {
    "LONG": -45.14,
    "LAT": -7.09,
    "CIDADE": "Loreto",
    "UF": "MARANHÃO",
    "anual": 5.49,
    "JAN": 4.66,
    "FEB": 5.03,
    "MAR": 5.09,
    "APR": 5.3,
    "MAY": 5.51,
    "JUN": 5.68,
    "JUL": 5.94,
    "AUG": 6.3,
    "SEP": 6.6,
    "OCT": 5.78,
    "NOV": 5.13,
    "DEC": 4.89
  },
  {
    "LONG": -44.81,
    "LAT": -7.08,
    "CIDADE": "São Félix de Balsas",
    "UF": "MARANHÃO",
    "anual": 5.52,
    "JAN": 4.76,
    "FEB": 5,
    "MAR": 5.04,
    "APR": 5.26,
    "MAY": 5.54,
    "JUN": 5.76,
    "JUL": 5.85,
    "AUG": 6.28,
    "SEP": 6.62,
    "OCT": 5.88,
    "NOV": 5.26,
    "DEC": 5
  },
  {
    "LONG": -4.67,
    "LAT": -6.96,
    "CIDADE": "Feira Nova do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.44,
    "FEB": 4.62,
    "MAR": 4.73,
    "APR": 4.99,
    "MAY": 5.28,
    "JUN": 5.7,
    "JUL": 5.8,
    "AUG": 6.28,
    "SEP": 6.24,
    "OCT": 5.33,
    "NOV": 4.67,
    "DEC": 4.52
  },
  {
    "LONG": -46.18,
    "LAT": -6.96,
    "CIDADE": "Fortaleza dos Nogueiras",
    "UF": "MARANHÃO",
    "anual": 5.27,
    "JAN": 4.44,
    "FEB": 4.71,
    "MAR": 4.73,
    "APR": 5.01,
    "MAY": 5.36,
    "JUN": 5.71,
    "JUL": 5.82,
    "AUG": 6.34,
    "SEP": 6.34,
    "OCT": 5.48,
    "NOV": 4.77,
    "DEC": 4.54
  },
  {
    "LONG": -45.48,
    "LAT": -7.02,
    "CIDADE": "São Raimundo das Mangabeiras",
    "UF": "MARANHÃO",
    "anual": 5.4,
    "JAN": 4.55,
    "FEB": 4.79,
    "MAR": 4.87,
    "APR": 5.13,
    "MAY": 5.47,
    "JUN": 5.72,
    "JUL": 5.85,
    "AUG": 6.35,
    "SEP": 6.54,
    "OCT": 5.69,
    "NOV": 5.03,
    "DEC": 4.75
  },
  {
    "LONG": -46.53,
    "LAT": -6.82,
    "CIDADE": "São Pedro dos Crentes",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.44,
    "FEB": 4.67,
    "MAR": 4.74,
    "APR": 4.94,
    "MAY": 5.25,
    "JUN": 5.64,
    "JUL": 5.77,
    "AUG": 6.24,
    "SEP": 6.21,
    "OCT": 5.41,
    "NOV": 4.71,
    "DEC": 4.61
  },
  {
    "LONG": -44.64,
    "LAT": -6.81,
    "CIDADE": "São Domingos do Azeitão",
    "UF": "MARANHÃO",
    "anual": 5.41,
    "JAN": 4.56,
    "FEB": 4.85,
    "MAR": 4.94,
    "APR": 5.08,
    "MAY": 5.36,
    "JUN": 5.61,
    "JUL": 5.89,
    "AUG": 6.26,
    "SEP": 6.56,
    "OCT": 5.78,
    "NOV": 5.13,
    "DEC": 4.87
  },
  {
    "LONG": -43.03,
    "LAT": -6.76,
    "CIDADE": "Barão de Grajaú",
    "UF": "MARANHÃO",
    "anual": 5.67,
    "JAN": 5.02,
    "FEB": 5.18,
    "MAR": 5.26,
    "APR": 5.32,
    "MAY": 5.48,
    "JUN": 5.69,
    "JUL": 5.89,
    "AUG": 6.32,
    "SEP": 6.63,
    "OCT": 6.23,
    "NOV": 5.75,
    "DEC": 5.29
  },
  {
    "LONG": -44.05,
    "LAT": -6.73,
    "CIDADE": "Nova Iorque",
    "UF": "MARANHÃO",
    "anual": 5.54,
    "JAN": 4.69,
    "FEB": 4.93,
    "MAR": 5.14,
    "APR": 5.25,
    "MAY": 5.46,
    "JUN": 5.79,
    "JUL": 5.9,
    "AUG": 6.22,
    "SEP": 6.59,
    "OCT": 6.07,
    "NOV": 5.44,
    "DEC": 5.02
  },
  {
    "LONG": -47.44,
    "LAT": -6.56,
    "CIDADE": "Estreito",
    "UF": "MARANHÃO",
    "anual": 5.2,
    "JAN": 4.62,
    "FEB": 4.79,
    "MAR": 4.89,
    "APR": 5.04,
    "MAY": 5.17,
    "JUN": 5.55,
    "JUL": 5.73,
    "AUG": 6.1,
    "SEP": 5.95,
    "OCT": 5.25,
    "NOV": 4.69,
    "DEC": 4.56
  },
  {
    "LONG": -44.08,
    "LAT": -6.6,
    "CIDADE": "Pastos Bons",
    "UF": "MARANHÃO",
    "anual": 5.5,
    "JAN": 4.68,
    "FEB": 4.87,
    "MAR": 4.97,
    "APR": 5.14,
    "MAY": 5.36,
    "JUN": 5.73,
    "JUL": 5.95,
    "AUG": 6.44,
    "SEP": 6.65,
    "OCT": 5.95,
    "NOV": 5.34,
    "DEC": 4.96
  },
  {
    "LONG": -47.06,
    "LAT": -6.47,
    "CIDADE": "São João do Paraíso",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.47,
    "FEB": 4.68,
    "MAR": 4.7,
    "APR": 4.85,
    "MAY": 5.16,
    "JUN": 5.6,
    "JUL": 5.74,
    "AUG": 6.24,
    "SEP": 5.99,
    "OCT": 5.25,
    "NOV": 4.64,
    "DEC": 4.56
  },
  {
    "LONG": -44.19,
    "LAT": -6.48,
    "CIDADE": "Sucupira do Norte",
    "UF": "MARANHÃO",
    "anual": 5.45,
    "JAN": 4.5,
    "FEB": 4.8,
    "MAR": 4.85,
    "APR": 5.08,
    "MAY": 5.38,
    "JUN": 5.74,
    "JUL": 5.99,
    "AUG": 6.51,
    "SEP": 6.61,
    "OCT": 5.86,
    "NOV": 5.22,
    "DEC": 4.83
  },
  {
    "LONG": -4.37,
    "LAT": -6.49,
    "CIDADE": "São João dos Patos",
    "UF": "MARANHÃO",
    "anual": 5.46,
    "JAN": 4.57,
    "FEB": 4.85,
    "MAR": 4.93,
    "APR": 5.05,
    "MAY": 5.31,
    "JUN": 5.7,
    "JUL": 5.97,
    "AUG": 6.47,
    "SEP": 6.58,
    "OCT": 5.9,
    "NOV": 5.32,
    "DEC": 4.87
  },
  {
    "LONG": -46.19,
    "LAT": -6.44,
    "CIDADE": "Formosa da Serra Negra",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.51,
    "FEB": 4.7,
    "MAR": 4.78,
    "APR": 4.86,
    "MAY": 5.23,
    "JUN": 5.59,
    "JUL": 5.78,
    "AUG": 6.28,
    "SEP": 6.14,
    "OCT": 5.37,
    "NOV": 4.75,
    "DEC": 4.59
  },
  {
    "LONG": -44.37,
    "LAT": -0.64,
    "CIDADE": "Mirador",
    "UF": "MARANHÃO",
    "anual": 5.45,
    "JAN": 4.58,
    "FEB": 4.84,
    "MAR": 4.94,
    "APR": 5.04,
    "MAY": 5.33,
    "JUN": 5.7,
    "JUL": 5.93,
    "AUG": 6.49,
    "SEP": 6.59,
    "OCT": 5.83,
    "NOV": 5.25,
    "DEC": 4.86
  },
  {
    "LONG": -43.99,
    "LAT": -0.64,
    "CIDADE": "Paraibano",
    "UF": "MARANHÃO",
    "anual": 5.48,
    "JAN": 4.61,
    "FEB": 4.82,
    "MAR": 4.94,
    "APR": 5.03,
    "MAY": 5.3,
    "JUN": 5.68,
    "JUL": 5.97,
    "AUG": 6.52,
    "SEP": 6.65,
    "OCT": 5.91,
    "NOV": 5.37,
    "DEC": 4.91
  },
  {
    "LONG": -43.55,
    "LAT": -6.42,
    "CIDADE": "Sucupira do Riachão",
    "UF": "MARANHÃO",
    "anual": 5.52,
    "JAN": 4.63,
    "FEB": 4.95,
    "MAR": 4.99,
    "APR": 5.12,
    "MAY": 5.33,
    "JUN": 5.68,
    "JUL": 6,
    "AUG": 6.53,
    "SEP": 6.56,
    "OCT": 5.98,
    "NOV": 5.47,
    "DEC": 4.94
  },
  {
    "LONG": -47.4,
    "LAT": -6.34,
    "CIDADE": "Porto Franco",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.59,
    "FEB": 4.77,
    "MAR": 4.87,
    "APR": 5.03,
    "MAY": 5.14,
    "JUN": 5.48,
    "JUL": 5.65,
    "AUG": 6.12,
    "SEP": 5.84,
    "OCT": 5.19,
    "NOV": 4.71,
    "DEC": 4.59
  },
  {
    "LONG": -47.36,
    "LAT": -6.17,
    "CIDADE": "Campestre do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.57,
    "FEB": 4.77,
    "MAR": 4.87,
    "APR": 4.97,
    "MAY": 5.1,
    "JUN": 5.47,
    "JUL": 5.64,
    "AUG": 6.1,
    "SEP": 5.78,
    "OCT": 5.2,
    "NOV": 4.72,
    "DEC": 4.56
  },
  {
    "LONG": -47.04,
    "LAT": -6.19,
    "CIDADE": "Lajeado Novo",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.5,
    "FEB": 4.69,
    "MAR": 4.77,
    "APR": 4.97,
    "MAY": 5.15,
    "JUN": 5.58,
    "JUL": 5.7,
    "AUG": 6.17,
    "SEP": 5.9,
    "OCT": 5.23,
    "NOV": 4.71,
    "DEC": 4.59
  },
  {
    "LONG": -44.9,
    "LAT": -6.16,
    "CIDADE": "Fernando Falcão",
    "UF": "MARANHÃO",
    "anual": 5.35,
    "JAN": 4.45,
    "FEB": 4.78,
    "MAR": 4.85,
    "APR": 5.03,
    "MAY": 5.22,
    "JUN": 5.61,
    "JUL": 5.86,
    "AUG": 6.45,
    "SEP": 6.52,
    "OCT": 5.64,
    "NOV": 5.03,
    "DEC": 4.75
  },
  {
    "LONG": -43.78,
    "LAT": -6.18,
    "CIDADE": "Passagem Franca",
    "UF": "MARANHÃO",
    "anual": 5.48,
    "JAN": 4.6,
    "FEB": 4.86,
    "MAR": 4.89,
    "APR": 5.05,
    "MAY": 5.28,
    "JUN": 5.68,
    "JUL": 5.97,
    "AUG": 6.54,
    "SEP": 6.59,
    "OCT": 5.95,
    "NOV": 5.38,
    "DEC": 4.91
  },
  {
    "LONG": -42.86,
    "LAT": -6.25,
    "CIDADE": "São Francisco do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.65,
    "JAN": 4.86,
    "FEB": 5.1,
    "MAR": 5.2,
    "APR": 5.26,
    "MAY": 5.5,
    "JUN": 5.72,
    "JUL": 5.96,
    "AUG": 6.36,
    "SEP": 6.6,
    "OCT": 6.16,
    "NOV": 5.78,
    "DEC": 5.26
  },
  {
    "LONG": -44.25,
    "LAT": -6.03,
    "CIDADE": "Colinas",
    "UF": "MARANHÃO",
    "anual": 5.43,
    "JAN": 4.54,
    "FEB": 4.86,
    "MAR": 4.99,
    "APR": 5.04,
    "MAY": 5.24,
    "JUN": 5.64,
    "JUL": 5.89,
    "AUG": 6.42,
    "SEP": 6.53,
    "OCT": 5.79,
    "NOV": 5.31,
    "DEC": 4.9
  },
  {
    "LONG": -43.53,
    "LAT": -6.05,
    "CIDADE": "Lagoa do Mato",
    "UF": "MARANHÃO",
    "anual": 5.52,
    "JAN": 4.72,
    "FEB": 4.97,
    "MAR": 4.97,
    "APR": 5.16,
    "MAY": 5.34,
    "JUN": 5.68,
    "JUL": 5.88,
    "AUG": 6.41,
    "SEP": 6.53,
    "OCT": 6.02,
    "NOV": 5.51,
    "DEC": 5.04
  },
  {
    "LONG": -47.39,
    "LAT": -5.93,
    "CIDADE": "Ribamar Fiquene",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.49,
    "FEB": 4.64,
    "MAR": 4.76,
    "APR": 4.9,
    "MAY": 5.06,
    "JUN": 5.43,
    "JUL": 5.63,
    "AUG": 6.04,
    "SEP": 5.7,
    "OCT": 5.18,
    "NOV": 4.77,
    "DEC": 4.59
  },
  {
    "LONG": -46.7,
    "LAT": -5.88,
    "CIDADE": "Sítio Novo",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.57,
    "FEB": 4.72,
    "MAR": 4.85,
    "APR": 4.93,
    "MAY": 5.08,
    "JUN": 5.45,
    "JUL": 5.66,
    "AUG": 6.08,
    "SEP": 5.86,
    "OCT": 5.26,
    "NOV": 4.75,
    "DEC": 4.67
  },
  {
    "LONG": -47.07,
    "LAT": -5.83,
    "CIDADE": "Montes Altos",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.54,
    "FEB": 4.71,
    "MAR": 4.8,
    "APR": 4.98,
    "MAY": 5.15,
    "JUN": 5.51,
    "JUL": 5.64,
    "AUG": 6.04,
    "SEP": 5.79,
    "OCT": 5.18,
    "NOV": 4.8,
    "DEC": 4.64
  },
  {
    "LONG": -46.16,
    "LAT": -5.82,
    "CIDADE": "Grajaú",
    "UF": "MARANHÃO",
    "anual": 5.14,
    "JAN": 4.49,
    "FEB": 4.72,
    "MAR": 4.77,
    "APR": 4.92,
    "MAY": 5.1,
    "JUN": 5.39,
    "JUL": 5.63,
    "AUG": 6.06,
    "SEP": 5.97,
    "OCT": 5.34,
    "NOV": 4.77,
    "DEC": 4.58
  },
  {
    "LONG": -44.22,
    "LAT": -5.82,
    "CIDADE": "Jatobá",
    "UF": "MARANHÃO",
    "anual": 5.36,
    "JAN": 4.53,
    "FEB": 4.77,
    "MAR": 4.95,
    "APR": 5.01,
    "MAY": 5.16,
    "JUN": 5.54,
    "JUL": 5.82,
    "AUG": 6.37,
    "SEP": 6.44,
    "OCT": 5.7,
    "NOV": 5.18,
    "DEC": 4.86
  },
  {
    "LONG": -43.84,
    "LAT": -5.83,
    "CIDADE": "Buriti Bravo",
    "UF": "MARANHÃO",
    "anual": 5.45,
    "JAN": 4.64,
    "FEB": 4.89,
    "MAR": 4.98,
    "APR": 5.04,
    "MAY": 5.28,
    "JUN": 5.63,
    "JUL": 5.85,
    "AUG": 6.45,
    "SEP": 6.49,
    "OCT": 5.88,
    "NOV": 5.35,
    "DEC": 4.95
  },
  {
    "LONG": -4.74,
    "LAT": -5.75,
    "CIDADE": "Governador Edison Lobão",
    "UF": "MARANHÃO",
    "anual": 5.06,
    "JAN": 4.46,
    "FEB": 4.59,
    "MAR": 4.75,
    "APR": 4.91,
    "MAY": 5.04,
    "JUN": 5.41,
    "JUL": 5.58,
    "AUG": 5.93,
    "SEP": 5.65,
    "OCT": 5.11,
    "NOV": 4.75,
    "DEC": 4.58
  },
  {
    "LONG": -44.16,
    "LAT": -5.73,
    "CIDADE": "Fortuna",
    "UF": "MARANHÃO",
    "anual": 5.38,
    "JAN": 4.55,
    "FEB": 4.85,
    "MAR": 4.98,
    "APR": 5.07,
    "MAY": 5.19,
    "JUN": 5.55,
    "JUL": 5.8,
    "AUG": 6.36,
    "SEP": 6.4,
    "OCT": 5.71,
    "NOV": 5.2,
    "DEC": 4.89
  },
  {
    "LONG": -43.11,
    "LAT": -5.68,
    "CIDADE": "Parnarama",
    "UF": "MARANHÃO",
    "anual": 5.56,
    "JAN": 4.77,
    "FEB": 5.04,
    "MAR": 5.09,
    "APR": 5.16,
    "MAY": 5.23,
    "JUN": 5.63,
    "JUL": 5.87,
    "AUG": 6.41,
    "SEP": 6.56,
    "OCT": 6.12,
    "NOV": 5.61,
    "DEC": 5.29
  },
  {
    "LONG": -47.01,
    "LAT": -5.6,
    "CIDADE": "Buritirana",
    "UF": "MARANHÃO",
    "anual": 5.08,
    "JAN": 4.44,
    "FEB": 4.61,
    "MAR": 4.72,
    "APR": 4.92,
    "MAY": 5.02,
    "JUN": 5.43,
    "JUL": 5.59,
    "AUG": 5.93,
    "SEP": 5.7,
    "OCT": 5.21,
    "NOV": 4.76,
    "DEC": 4.59
  },
  {
    "LONG": -46.75,
    "LAT": -5.57,
    "CIDADE": "Amarante do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.43,
    "FEB": 4.68,
    "MAR": 4.74,
    "APR": 4.94,
    "MAY": 5.03,
    "JUN": 5.39,
    "JUL": 5.6,
    "AUG": 5.94,
    "SEP": 5.78,
    "OCT": 5.22,
    "NOV": 4.8,
    "DEC": 4.61
  },
  {
    "LONG": -44.38,
    "LAT": -5.58,
    "CIDADE": "São Domingos do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.34,
    "JAN": 4.57,
    "FEB": 4.69,
    "MAR": 4.94,
    "APR": 5.05,
    "MAY": 5.15,
    "JUN": 5.48,
    "JUL": 5.75,
    "AUG": 6.29,
    "SEP": 6.37,
    "OCT": 5.67,
    "NOV": 5.19,
    "DEC": 4.87
  },
  {
    "LONG": -47.48,
    "LAT": -5.52,
    "CIDADE": "Imperatriz",
    "UF": "MARANHÃO",
    "anual": 5.05,
    "JAN": 4.48,
    "FEB": 4.63,
    "MAR": 4.79,
    "APR": 4.93,
    "MAY": 4.97,
    "JUN": 5.4,
    "JUL": 5.49,
    "AUG": 5.88,
    "SEP": 5.62,
    "OCT": 5.1,
    "NOV": 4.76,
    "DEC": 4.56
  },
  {
    "LONG": -4.74,
    "LAT": -5.53,
    "CIDADE": "Davinópolis",
    "UF": "MARANHÃO",
    "anual": 5.08,
    "JAN": 4.49,
    "FEB": 4.63,
    "MAR": 4.79,
    "APR": 4.97,
    "MAY": 5.03,
    "JUN": 5.46,
    "JUL": 5.57,
    "AUG": 5.93,
    "SEP": 5.64,
    "OCT": 5.13,
    "NOV": 4.75,
    "DEC": 4.54
  },
  {
    "LONG": -45.25,
    "LAT": -5.5,
    "CIDADE": "Barra do Corda",
    "UF": "MARANHÃO",
    "anual": 5.23,
    "JAN": 4.56,
    "FEB": 4.79,
    "MAR": 4.92,
    "APR": 4.92,
    "MAY": 5.1,
    "JUN": 5.36,
    "JUL": 5.63,
    "AUG": 6.1,
    "SEP": 6.14,
    "OCT": 5.45,
    "NOV": 5.02,
    "DEC": 4.73
  },
  {
    "LONG": -44.56,
    "LAT": -5.5,
    "CIDADE": "Santa Filomena do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.3,
    "JAN": 4.6,
    "FEB": 4.72,
    "MAR": 4.92,
    "APR": 4.93,
    "MAY": 5.11,
    "JUN": 5.46,
    "JUL": 5.72,
    "AUG": 6.27,
    "SEP": 6.32,
    "OCT": 5.58,
    "NOV": 5.18,
    "DEC": 4.85
  },
  {
    "LONG": -44.07,
    "LAT": -5.47,
    "CIDADE": "Governador Luiz Rocha",
    "UF": "MARANHÃO",
    "anual": 5.42,
    "JAN": 4.59,
    "FEB": 4.85,
    "MAR": 5.02,
    "APR": 5.08,
    "MAY": 5.23,
    "JUN": 5.48,
    "JUL": 5.83,
    "AUG": 6.38,
    "SEP": 6.47,
    "OCT": 5.82,
    "NOV": 5.31,
    "DEC": 4.94
  },
  {
    "LONG": -43.2,
    "LAT": -0.55,
    "CIDADE": "Matões",
    "UF": "MARANHÃO",
    "anual": 5.53,
    "JAN": 4.78,
    "FEB": 4.97,
    "MAR": 5.02,
    "APR": 5.07,
    "MAY": 5.25,
    "JUN": 5.6,
    "JUL": 5.85,
    "AUG": 6.41,
    "SEP": 6.57,
    "OCT": 6.08,
    "NOV": 5.59,
    "DEC": 5.21
  },
  {
    "LONG": -47.41,
    "LAT": -0.54,
    "CIDADE": "João Lisboa",
    "UF": "MARANHÃO",
    "anual": 5.04,
    "JAN": 4.46,
    "FEB": 4.61,
    "MAR": 4.77,
    "APR": 4.93,
    "MAY": 4.99,
    "JUN": 5.43,
    "JUL": 5.55,
    "AUG": 5.88,
    "SEP": 5.58,
    "OCT": 5.08,
    "NOV": 4.72,
    "DEC": 4.54
  },
  {
    "LONG": -47.3,
    "LAT": -5.45,
    "CIDADE": "Senador La Rocque",
    "UF": "MARANHÃO",
    "anual": 5.04,
    "JAN": 4.44,
    "FEB": 4.57,
    "MAR": 4.72,
    "APR": 4.91,
    "MAY": 5.01,
    "JUN": 5.46,
    "JUL": 5.56,
    "AUG": 5.87,
    "SEP": 5.62,
    "OCT": 5.11,
    "NOV": 4.73,
    "DEC": 4.53
  },
  {
    "LONG": -4.56,
    "LAT": -5.37,
    "CIDADE": "Jenipapo dos Vieiras",
    "UF": "MARANHÃO",
    "anual": 5.12,
    "JAN": 4.33,
    "FEB": 4.61,
    "MAR": 4.78,
    "APR": 4.92,
    "MAY": 5.02,
    "JUN": 5.37,
    "JUL": 5.59,
    "AUG": 6.09,
    "SEP": 5.99,
    "OCT": 5.32,
    "NOV": 4.82,
    "DEC": 4.59
  },
  {
    "LONG": -44.34,
    "LAT": -5.41,
    "CIDADE": "Graça Aranha",
    "UF": "MARANHÃO",
    "anual": 5.34,
    "JAN": 4.54,
    "FEB": 4.75,
    "MAR": 4.9,
    "APR": 5,
    "MAY": 5.18,
    "JUN": 5.45,
    "JUL": 5.74,
    "AUG": 6.32,
    "SEP": 6.38,
    "OCT": 5.7,
    "NOV": 5.22,
    "DEC": 4.86
  },
  {
    "LONG": -44.64,
    "LAT": -5.26,
    "CIDADE": "Tuntum",
    "UF": "MARANHÃO",
    "anual": 5.3,
    "JAN": 4.62,
    "FEB": 4.84,
    "MAR": 4.92,
    "APR": 5,
    "MAY": 5.06,
    "JUN": 5.41,
    "JUL": 5.61,
    "AUG": 6.18,
    "SEP": 6.23,
    "OCT": 5.57,
    "NOV": 5.23,
    "DEC": 4.87
  },
  {
    "LONG": -44.5,
    "LAT": -5.29,
    "CIDADE": "Presidente Dutra",
    "UF": "MARANHÃO",
    "anual": 5.32,
    "JAN": 4.67,
    "FEB": 4.79,
    "MAR": 4.95,
    "APR": 5.01,
    "MAY": 5.14,
    "JUN": 5.4,
    "JUL": 5.66,
    "AUG": 6.22,
    "SEP": 6.29,
    "OCT": 5.63,
    "NOV": 5.2,
    "DEC": 4.89
  },
  {
    "LONG": -44.25,
    "LAT": -5.32,
    "CIDADE": "Governador Eugênio Barros",
    "UF": "MARANHÃO",
    "anual": 5.34,
    "JAN": 4.55,
    "FEB": 4.78,
    "MAR": 4.94,
    "APR": 5,
    "MAY": 5.11,
    "JUN": 5.43,
    "JUL": 5.75,
    "AUG": 6.25,
    "SEP": 6.38,
    "OCT": 5.74,
    "NOV": 5.24,
    "DEC": 4.86
  },
  {
    "LONG": -44.06,
    "LAT": "#VALOR!",
    "CIDADE": "Senador Alexandre Costa",
    "UF": "MARANHÃO",
    "anual": 5.36,
    "JAN": 4.54,
    "FEB": 4.81,
    "MAR": 4.96,
    "APR": 5,
    "MAY": 5.14,
    "JUN": 5.43,
    "JUL": 5.71,
    "AUG": 6.27,
    "SEP": 6.41,
    "OCT": 5.81,
    "NOV": 5.33,
    "DEC": 4.91
  },
  {
    "LONG": -4.81,
    "LAT": -0.52,
    "CIDADE": "Vila Nova dos Martírios",
    "UF": "MARANHÃO",
    "anual": 4.95,
    "JAN": 4.3,
    "FEB": 4.53,
    "MAR": 4.68,
    "APR": 4.92,
    "MAY": 4.96,
    "JUN": 5.45,
    "JUL": 5.45,
    "AUG": 5.81,
    "SEP": 5.46,
    "OCT": 4.88,
    "NOV": 4.6,
    "DEC": 4.36
  },
  {
    "LONG": -47.78,
    "LAT": -5.18,
    "CIDADE": "Cidelândia",
    "UF": "MARANHÃO",
    "anual": 4.97,
    "JAN": 4.36,
    "FEB": 4.56,
    "MAR": 4.66,
    "APR": 4.87,
    "MAY": 4.93,
    "JUN": 5.38,
    "JUL": 5.51,
    "AUG": 5.79,
    "SEP": 5.52,
    "OCT": 4.97,
    "NOV": 4.67,
    "DEC": 4.44
  },
  {
    "LONG": -48.43,
    "LAT": -5.09,
    "CIDADE": "São Pedro da Água Branca",
    "UF": "MARANHÃO",
    "anual": 4.9,
    "JAN": 4.26,
    "FEB": 4.49,
    "MAR": 4.64,
    "APR": 4.81,
    "MAY": 4.87,
    "JUN": 5.3,
    "JUL": 5.41,
    "AUG": 5.78,
    "SEP": 5.4,
    "OCT": 4.8,
    "NOV": 4.65,
    "DEC": 4.35
  },
  {
    "LONG": -47.39,
    "LAT": -5.13,
    "CIDADE": "São Francisco do Brejão",
    "UF": "MARANHÃO",
    "anual": 4.93,
    "JAN": 4.35,
    "FEB": 4.61,
    "MAR": 4.66,
    "APR": 4.8,
    "MAY": 4.9,
    "JUN": 5.23,
    "JUL": 5.38,
    "AUG": 5.72,
    "SEP": 5.46,
    "OCT": 4.92,
    "NOV": 4.6,
    "DEC": 4.49
  },
  {
    "LONG": -45.79,
    "LAT": -5.14,
    "CIDADE": "Itaipava do Grajaú",
    "UF": "MARANHÃO",
    "anual": 5.14,
    "JAN": 4.51,
    "FEB": 4.77,
    "MAR": 4.88,
    "APR": 4.95,
    "MAY": 4.98,
    "JUN": 5.3,
    "JUL": 5.55,
    "AUG": 5.91,
    "SEP": 5.93,
    "OCT": 5.34,
    "NOV": 4.91,
    "DEC": 4.7
  },
  {
    "LONG": -45.08,
    "LAT": -5.11,
    "CIDADE": "São Raimundo do Doca Bezerra",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.51,
    "FEB": 4.83,
    "MAR": 4.97,
    "APR": 5.01,
    "MAY": 5.08,
    "JUN": 5.28,
    "JUL": 5.53,
    "AUG": 6.05,
    "SEP": 6.15,
    "OCT": 5.46,
    "NOV": 5.04,
    "DEC": 4.73
  },
  {
    "LONG": -44.58,
    "LAT": -5.06,
    "CIDADE": "São José dos Basílios",
    "UF": "MARANHÃO",
    "anual": 5.26,
    "JAN": 4.63,
    "FEB": 4.76,
    "MAR": 4.89,
    "APR": 4.93,
    "MAY": 5.11,
    "JUN": 5.38,
    "JUL": 5.61,
    "AUG": 6.14,
    "SEP": 6.24,
    "OCT": 5.56,
    "NOV": 5.16,
    "DEC": 4.74
  },
  {
    "LONG": -44.3,
    "LAT": -5.15,
    "CIDADE": "Gonçalves Dias",
    "UF": "MARANHÃO",
    "anual": 5.34,
    "JAN": 4.63,
    "FEB": 4.83,
    "MAR": 4.91,
    "APR": 4.96,
    "MAY": 5.13,
    "JUN": 5.38,
    "JUL": 5.7,
    "AUG": 6.28,
    "SEP": 6.37,
    "OCT": 5.72,
    "NOV": 5.27,
    "DEC": 4.86
  },
  {
    "LONG": -43.82,
    "LAT": -5.11,
    "CIDADE": "São João do Soter",
    "UF": "MARANHÃO",
    "anual": 5.41,
    "JAN": 4.65,
    "FEB": 4.9,
    "MAR": 4.99,
    "APR": 5.03,
    "MAY": 5.2,
    "JUN": 5.35,
    "JUL": 5.71,
    "AUG": 6.36,
    "SEP": 6.47,
    "OCT": 5.91,
    "NOV": 5.41,
    "DEC": 4.94
  },
  {
    "LONG": -42.83,
    "LAT": -5.1,
    "CIDADE": "Timon",
    "UF": "MARANHÃO",
    "anual": 5.58,
    "JAN": 4.94,
    "FEB": 5.12,
    "MAR": 5.14,
    "APR": 5.13,
    "MAY": 5.23,
    "JUN": 5.41,
    "JUL": 5.69,
    "AUG": 6.19,
    "SEP": 6.49,
    "OCT": 6.25,
    "NOV": 5.97,
    "DEC": 5.43
  },
  {
    "LONG": -4.54,
    "LAT": -4.99,
    "CIDADE": "Lagoa Grande do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.09,
    "JAN": 4.43,
    "FEB": 4.67,
    "MAR": 4.79,
    "APR": 4.88,
    "MAY": 4.95,
    "JUN": 5.21,
    "JUL": 5.46,
    "AUG": 5.88,
    "SEP": 5.94,
    "OCT": 5.33,
    "NOV": 4.89,
    "DEC": 4.64
  },
  {
    "LONG": -44.99,
    "LAT": -5.02,
    "CIDADE": "São Roberto",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.53,
    "FEB": 4.81,
    "MAR": 4.93,
    "APR": 5.02,
    "MAY": 5.13,
    "JUN": 5.29,
    "JUL": 5.51,
    "AUG": 6.05,
    "SEP": 6.13,
    "OCT": 5.5,
    "NOV": 5.07,
    "DEC": 4.72
  },
  {
    "LONG": -44.7,
    "LAT": -4.99,
    "CIDADE": "Joselândia",
    "UF": "MARANHÃO",
    "anual": 5.27,
    "JAN": 4.63,
    "FEB": 4.82,
    "MAR": 4.95,
    "APR": 4.99,
    "MAY": 5.09,
    "JUN": 5.35,
    "JUL": 5.59,
    "AUG": 6.07,
    "SEP": 6.18,
    "OCT": 5.58,
    "NOV": 5.16,
    "DEC": 4.78
  },
  {
    "LONG": -44.44,
    "LAT": -5.04,
    "CIDADE": "Dom Pedro",
    "UF": "MARANHÃO",
    "anual": 5.29,
    "JAN": 4.63,
    "FEB": 4.79,
    "MAR": 4.87,
    "APR": 4.89,
    "MAY": 5.09,
    "JUN": 5.34,
    "JUL": 5.65,
    "AUG": 6.19,
    "SEP": 6.33,
    "OCT": 5.73,
    "NOV": 5.22,
    "DEC": 4.81
  },
  {
    "LONG": -44.28,
    "LAT": -5.02,
    "CIDADE": "Governador Archer",
    "UF": "MARANHÃO",
    "anual": 5.33,
    "JAN": 4.64,
    "FEB": 4.84,
    "MAR": 4.99,
    "APR": 4.97,
    "MAY": 5.13,
    "JUN": 5.34,
    "JUL": 5.62,
    "AUG": 6.19,
    "SEP": 6.31,
    "OCT": 5.75,
    "NOV": 5.32,
    "DEC": 4.88
  },
  {
    "LONG": -47.5,
    "LAT": -4.95,
    "CIDADE": "Açailândia",
    "UF": "MARANHÃO",
    "anual": 4.86,
    "JAN": 4.31,
    "FEB": 4.56,
    "MAR": 4.66,
    "APR": 4.84,
    "MAY": 4.82,
    "JUN": 5.18,
    "JUL": 5.21,
    "AUG": 5.54,
    "SEP": 5.34,
    "OCT": 4.86,
    "NOV": 4.58,
    "DEC": 4.44
  },
  {
    "LONG": -46.01,
    "LAT": -4.89,
    "CIDADE": "Arame",
    "UF": "MARANHÃO",
    "anual": 4.97,
    "JAN": 4.4,
    "FEB": 4.62,
    "MAR": 4.8,
    "APR": 4.78,
    "MAY": 4.89,
    "JUN": 5.14,
    "JUL": 5.38,
    "AUG": 5.72,
    "SEP": 5.72,
    "OCT": 5.11,
    "NOV": 4.63,
    "DEC": 4.51
  },
  {
    "LONG": -4.47,
    "LAT": -4.88,
    "CIDADE": "Esperantinópolis",
    "UF": "MARANHÃO",
    "anual": 5.25,
    "JAN": 4.58,
    "FEB": 4.85,
    "MAR": 4.95,
    "APR": 4.98,
    "MAY": 5.08,
    "JUN": 5.3,
    "JUL": 5.56,
    "AUG": 6.04,
    "SEP": 6.2,
    "OCT": 5.62,
    "NOV": 5.17,
    "DEC": 4.74
  },
  {
    "LONG": -44.36,
    "LAT": -4.87,
    "CIDADE": "Santo Antônio dos Lopes",
    "UF": "MARANHÃO",
    "anual": 5.31,
    "JAN": 4.67,
    "FEB": 4.79,
    "MAR": 4.94,
    "APR": 4.93,
    "MAY": 5.11,
    "JUN": 5.31,
    "JUL": 5.61,
    "AUG": 6.18,
    "SEP": 6.32,
    "OCT": 5.76,
    "NOV": 5.31,
    "DEC": 4.85
  },
  {
    "LONG": -43.36,
    "LAT": -4.87,
    "CIDADE": "Caxias",
    "UF": "MARANHÃO",
    "anual": 5.49,
    "JAN": 4.74,
    "FEB": 5.01,
    "MAR": 5.1,
    "APR": 5.11,
    "MAY": 5.16,
    "JUN": 5.38,
    "JUL": 5.65,
    "AUG": 6.21,
    "SEP": 6.44,
    "OCT": 6.13,
    "NOV": 5.78,
    "DEC": 5.24
  },
  {
    "LONG": -44.94,
    "LAT": -4.75,
    "CIDADE": "Poção de Pedras",
    "UF": "MARANHÃO",
    "anual": 5.19,
    "JAN": 4.54,
    "FEB": 4.79,
    "MAR": 4.85,
    "APR": 4.94,
    "MAY": 5,
    "JUN": 5.24,
    "JUL": 5.46,
    "AUG": 5.98,
    "SEP": 6.11,
    "OCT": 5.55,
    "NOV": 5.08,
    "DEC": 4.78
  },
  {
    "LONG": -44.86,
    "LAT": -4.66,
    "CIDADE": "Igarapé Grande",
    "UF": "MARANHÃO",
    "anual": 5.2,
    "JAN": 4.51,
    "FEB": 4.81,
    "MAR": 4.88,
    "APR": 4.93,
    "MAY": 5.01,
    "JUN": 5.25,
    "JUL": 5.48,
    "AUG": 5.96,
    "SEP": 6.11,
    "OCT": 5.56,
    "NOV": 5.11,
    "DEC": 4.77
  },
  {
    "LONG": -44.33,
    "LAT": -0.47,
    "CIDADE": "Capinzal do Norte",
    "UF": "MARANHÃO",
    "anual": 5.31,
    "JAN": 4.68,
    "FEB": 4.8,
    "MAR": 4.91,
    "APR": 4.95,
    "MAY": 5.04,
    "JUN": 5.26,
    "JUL": 5.59,
    "AUG": 6.12,
    "SEP": 6.3,
    "OCT": 5.83,
    "NOV": 5.34,
    "DEC": 4.93
  },
  {
    "LONG": -45.46,
    "LAT": -4.63,
    "CIDADE": "Marajá do Sena",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.49,
    "FEB": 4.76,
    "MAR": 4.92,
    "APR": 4.97,
    "MAY": 4.96,
    "JUN": 5.21,
    "JUL": 5.36,
    "AUG": 5.69,
    "SEP": 5.87,
    "OCT": 5.37,
    "NOV": 4.91,
    "DEC": 4.65
  },
  {
    "LONG": -45.13,
    "LAT": -4.56,
    "CIDADE": "Lago da Pedra",
    "UF": "MARANHÃO",
    "anual": 5.11,
    "JAN": 4.5,
    "FEB": 4.67,
    "MAR": 4.87,
    "APR": 4.93,
    "MAY": 4.88,
    "JUN": 5.2,
    "JUL": 5.38,
    "AUG": 5.82,
    "SEP": 5.96,
    "OCT": 5.45,
    "NOV": 4.95,
    "DEC": 4.67
  },
  {
    "LONG": -45.06,
    "LAT": -4.61,
    "CIDADE": "Lago do Junco",
    "UF": "MARANHÃO",
    "anual": 5.13,
    "JAN": 4.5,
    "FEB": 4.71,
    "MAR": 4.87,
    "APR": 4.93,
    "MAY": 4.92,
    "JUN": 5.22,
    "JUL": 5.39,
    "AUG": 5.89,
    "SEP": 6.01,
    "OCT": 5.46,
    "NOV": 5,
    "DEC": 4.7
  },
  {
    "LONG": -44.98,
    "LAT": -4.61,
    "CIDADE": "Lago dos Rodrigues",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.55,
    "FEB": 4.75,
    "MAR": 4.9,
    "APR": 4.92,
    "MAY": 4.96,
    "JUN": 5.18,
    "JUL": 5.36,
    "AUG": 5.88,
    "SEP": 6.03,
    "OCT": 5.48,
    "NOV": 5.02,
    "DEC": 4.79
  },
  {
    "LONG": -44.76,
    "LAT": -4.63,
    "CIDADE": "Bernardo do Mearim",
    "UF": "MARANHÃO",
    "anual": 5.2,
    "JAN": 4.57,
    "FEB": 4.73,
    "MAR": 4.88,
    "APR": 4.92,
    "MAY": 4.97,
    "JUN": 5.26,
    "JUL": 5.49,
    "AUG": 5.96,
    "SEP": 6.17,
    "OCT": 5.6,
    "NOV": 5.12,
    "DEC": 4.78
  },
  {
    "LONG": -4.46,
    "LAT": -4.57,
    "CIDADE": "Pedreiras",
    "UF": "MARANHÃO",
    "anual": 5.24,
    "JAN": 4.61,
    "FEB": 4.77,
    "MAR": 4.94,
    "APR": 4.94,
    "MAY": 4.98,
    "JUN": 5.27,
    "JUL": 5.49,
    "AUG": 5.96,
    "SEP": 6.21,
    "OCT": 5.65,
    "NOV": 5.17,
    "DEC": 4.85
  },
  {
    "LONG": -44.62,
    "LAT": -4.57,
    "CIDADE": "Trizidela do Vale",
    "UF": "MARANHÃO",
    "anual": 5.24,
    "JAN": 4.61,
    "FEB": 4.77,
    "MAR": 4.94,
    "APR": 4.94,
    "MAY": 4.98,
    "JUN": 5.27,
    "JUL": 5.49,
    "AUG": 5.96,
    "SEP": 6.21,
    "OCT": 5.65,
    "NOV": 5.17,
    "DEC": 4.85
  },
  {
    "LONG": -43.47,
    "LAT": -4.63,
    "CIDADE": "Aldeias Altas",
    "UF": "MARANHÃO",
    "anual": 5.5,
    "JAN": 4.81,
    "FEB": 4.99,
    "MAR": 5.11,
    "APR": 5.13,
    "MAY": 5.21,
    "JUN": 5.42,
    "JUL": 5.66,
    "AUG": 6.26,
    "SEP": 6.42,
    "OCT": 6.07,
    "NOV": 5.68,
    "DEC": 5.23
  },
  {
    "LONG": -47.53,
    "LAT": -4.46,
    "CIDADE": "Itinga do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.82,
    "JAN": 4.31,
    "FEB": 4.59,
    "MAR": 4.68,
    "APR": 4.87,
    "MAY": 4.82,
    "JUN": 5.04,
    "JUL": 5.14,
    "AUG": 5.37,
    "SEP": 5.24,
    "OCT": 4.83,
    "NOV": 4.53,
    "DEC": 4.4
  },
  {
    "LONG": -46.85,
    "LAT": -4.48,
    "CIDADE": "Bom Jesus das Selvas",
    "UF": "MARANHÃO",
    "anual": 4.94,
    "JAN": 4.45,
    "FEB": 4.69,
    "MAR": 4.84,
    "APR": 4.85,
    "MAY": 4.88,
    "JUN": 5.06,
    "JUL": 5.24,
    "AUG": 5.47,
    "SEP": 5.51,
    "OCT": 5.09,
    "NOV": 4.65,
    "DEC": 4.57
  },
  {
    "LONG": -4.45,
    "LAT": -4.52,
    "CIDADE": "Lima Campos",
    "UF": "MARANHÃO",
    "anual": 5.27,
    "JAN": 4.57,
    "FEB": 4.74,
    "MAR": 4.89,
    "APR": 4.95,
    "MAY": 5,
    "JUN": 5.28,
    "JUL": 5.51,
    "AUG": 6.04,
    "SEP": 6.28,
    "OCT": 5.77,
    "NOV": 5.29,
    "DEC": 4.88
  },
  {
    "LONG": -43.89,
    "LAT": -0.45,
    "CIDADE": "Codó",
    "UF": "MARANHÃO",
    "anual": 5.39,
    "JAN": 4.72,
    "FEB": 4.9,
    "MAR": 5.03,
    "APR": 4.95,
    "MAY": 5.03,
    "JUN": 5.28,
    "JUL": 5.51,
    "AUG": 6.1,
    "SEP": 6.41,
    "OCT": 6.04,
    "NOV": 5.58,
    "DEC": 5.16
  },
  {
    "LONG": -45.24,
    "LAT": -4.45,
    "CIDADE": "Paulo Ramos",
    "UF": "MARANHÃO",
    "anual": 5.07,
    "JAN": 4.45,
    "FEB": 4.74,
    "MAR": 4.89,
    "APR": 4.92,
    "MAY": 4.92,
    "JUN": 5.12,
    "JUL": 5.32,
    "AUG": 5.73,
    "SEP": 5.88,
    "OCT": 5.3,
    "NOV": 4.88,
    "DEC": 4.65
  },
  {
    "LONG": -45.04,
    "LAT": -4.37,
    "CIDADE": "Bom Lugar",
    "UF": "MARANHÃO",
    "anual": 5.12,
    "JAN": 4.49,
    "FEB": 4.77,
    "MAR": 4.85,
    "APR": 4.94,
    "MAY": 4.94,
    "JUN": 5.13,
    "JUL": 5.36,
    "AUG": 5.8,
    "SEP": 5.97,
    "OCT": 5.43,
    "NOV": 4.96,
    "DEC": 4.78
  },
  {
    "LONG": -4.47,
    "LAT": -4.38,
    "CIDADE": "São Luís Gonzaga do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.23,
    "JAN": 4.54,
    "FEB": 4.73,
    "MAR": 4.89,
    "APR": 4.94,
    "MAY": 4.98,
    "JUN": 5.24,
    "JUL": 5.51,
    "AUG": 5.98,
    "SEP": 6.21,
    "OCT": 5.68,
    "NOV": 5.21,
    "DEC": 4.85
  },
  {
    "LONG": -44.33,
    "LAT": -0.44,
    "CIDADE": "Peritoró",
    "UF": "MARANHÃO",
    "anual": 5.31,
    "JAN": 4.56,
    "FEB": 4.81,
    "MAR": 4.95,
    "APR": 4.95,
    "MAY": 5.04,
    "JUN": 5.24,
    "JUL": 5.57,
    "AUG": 6.08,
    "SEP": 6.32,
    "OCT": 5.87,
    "NOV": 5.39,
    "DEC": 4.95
  },
  {
    "LONG": -46.45,
    "LAT": -4.32,
    "CIDADE": "Buriticupu",
    "UF": "MARANHÃO",
    "anual": 4.89,
    "JAN": 4.33,
    "FEB": 4.67,
    "MAR": 4.78,
    "APR": 4.85,
    "MAY": 4.82,
    "JUN": 4.98,
    "JUL": 5.18,
    "AUG": 5.49,
    "SEP": 5.52,
    "OCT": 5.02,
    "NOV": 4.62,
    "DEC": 4.48
  },
  {
    "LONG": -45.58,
    "LAT": -4.33,
    "CIDADE": "Brejo de Areia",
    "UF": "MARANHÃO",
    "anual": 5.01,
    "JAN": 4.42,
    "FEB": 4.68,
    "MAR": 4.82,
    "APR": 4.95,
    "MAY": 4.9,
    "JUN": 5.1,
    "JUL": 5.27,
    "AUG": 5.65,
    "SEP": 5.77,
    "OCT": 5.23,
    "NOV": 4.77,
    "DEC": 4.59
  },
  {
    "LONG": -45.25,
    "LAT": -4.29,
    "CIDADE": "Vitorino Freire",
    "UF": "MARANHÃO",
    "anual": 5.07,
    "JAN": 4.5,
    "FEB": 4.72,
    "MAR": 4.9,
    "APR": 4.92,
    "MAY": 4.91,
    "JUN": 5.1,
    "JUL": 5.32,
    "AUG": 5.66,
    "SEP": 5.84,
    "OCT": 5.33,
    "NOV": 4.93,
    "DEC": 4.7
  },
  {
    "LONG": -43.93,
    "LAT": -4.26,
    "CIDADE": "Timbiras",
    "UF": "MARANHÃO",
    "anual": 5.38,
    "JAN": 4.71,
    "FEB": 4.86,
    "MAR": 5.01,
    "APR": 4.97,
    "MAY": 5.05,
    "JUN": 5.3,
    "JUL": 5.57,
    "AUG": 6.15,
    "SEP": 6.34,
    "OCT": 5.96,
    "NOV": 5.53,
    "DEC": 5.09
  },
  {
    "LONG": -43.01,
    "LAT": -4.25,
    "CIDADE": "Coelho Neto",
    "UF": "MARANHÃO",
    "anual": 5.59,
    "JAN": 4.96,
    "FEB": 5.17,
    "MAR": 5.23,
    "APR": 5.23,
    "MAY": 5.24,
    "JUN": 5.36,
    "JUL": 5.64,
    "AUG": 6.2,
    "SEP": 6.47,
    "OCT": 6.29,
    "NOV": 5.88,
    "DEC": 5.43
  },
  {
    "LONG": -4.55,
    "LAT": -4.17,
    "CIDADE": "Altamira do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.03,
    "JAN": 4.45,
    "FEB": 4.7,
    "MAR": 4.85,
    "APR": 4.96,
    "MAY": 4.92,
    "JUN": 5.01,
    "JUL": 5.21,
    "AUG": 5.6,
    "SEP": 5.82,
    "OCT": 5.3,
    "NOV": 4.85,
    "DEC": 4.62
  },
  {
    "LONG": -44.78,
    "LAT": -4.22,
    "CIDADE": "Bacabal",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.59,
    "FEB": 4.8,
    "MAR": 4.89,
    "APR": 4.97,
    "MAY": 4.98,
    "JUN": 5.16,
    "JUL": 5.42,
    "AUG": 5.9,
    "SEP": 6.17,
    "OCT": 5.68,
    "NOV": 5.2,
    "DEC": 4.88
  },
  {
    "LONG": -44.45,
    "LAT": -4.21,
    "CIDADE": "Alto Alegre do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.3,
    "JAN": 4.61,
    "FEB": 4.8,
    "MAR": 4.95,
    "APR": 4.92,
    "MAY": 4.98,
    "JUN": 5.27,
    "JUL": 5.53,
    "AUG": 6.08,
    "SEP": 6.33,
    "OCT": 5.85,
    "NOV": 5.34,
    "DEC": 4.96
  },
  {
    "LONG": -42.94,
    "LAT": -4.15,
    "CIDADE": "Duque Bacelar",
    "UF": "MARANHÃO",
    "anual": 5.59,
    "JAN": 4.97,
    "FEB": 5.2,
    "MAR": 5.27,
    "APR": 5.26,
    "MAY": 5.22,
    "JUN": 5.35,
    "JUL": 5.65,
    "AUG": 6.11,
    "SEP": 6.42,
    "OCT": 6.25,
    "NOV": 5.91,
    "DEC": 5.42
  },
  {
    "LONG": -45.12,
    "LAT": -4.14,
    "CIDADE": "Olho d'Água das Cunhãs",
    "UF": "MARANHÃO",
    "anual": 5.13,
    "JAN": 4.57,
    "FEB": 4.82,
    "MAR": 4.96,
    "APR": 4.95,
    "MAY": 4.92,
    "JUN": 5.11,
    "JUL": 5.3,
    "AUG": 5.72,
    "SEP": 5.92,
    "OCT": 5.43,
    "NOV": 5.06,
    "DEC": 4.78
  },
  {
    "LONG": -44.12,
    "LAT": -4.13,
    "CIDADE": "Coroatá",
    "UF": "MARANHÃO",
    "anual": 5.36,
    "JAN": 4.69,
    "FEB": 4.86,
    "MAR": 5.02,
    "APR": 5.01,
    "MAY": 5.07,
    "JUN": 5.3,
    "JUL": 5.58,
    "AUG": 6.05,
    "SEP": 6.35,
    "OCT": 5.95,
    "NOV": 5.44,
    "DEC": 5.03
  },
  {
    "LONG": -43.33,
    "LAT": -4.13,
    "CIDADE": "Afonso Cunha",
    "UF": "MARANHÃO",
    "anual": 5.53,
    "JAN": 4.92,
    "FEB": 5.15,
    "MAR": 5.1,
    "APR": 5.16,
    "MAY": 5.14,
    "JUN": 5.41,
    "JUL": 5.58,
    "AUG": 6.12,
    "SEP": 6.4,
    "OCT": 6.22,
    "NOV": 5.8,
    "DEC": 5.31
  },
  {
    "LONG": -45.66,
    "LAT": -3.96,
    "CIDADE": "Santa Luzia",
    "UF": "MARANHÃO",
    "anual": 4.98,
    "JAN": 4.45,
    "FEB": 4.71,
    "MAR": 4.86,
    "APR": 4.91,
    "MAY": 4.8,
    "JUN": 5.02,
    "JUL": 5.17,
    "AUG": 5.53,
    "SEP": 5.68,
    "OCT": 5.2,
    "NOV": 4.82,
    "DEC": 4.64
  },
  {
    "LONG": -45.24,
    "LAT": -4.04,
    "CIDADE": "Satubinha",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.53,
    "FEB": 4.77,
    "MAR": 4.92,
    "APR": 4.98,
    "MAY": 4.89,
    "JUN": 5.13,
    "JUL": 5.22,
    "AUG": 5.68,
    "SEP": 5.86,
    "OCT": 5.42,
    "NOV": 5.03,
    "DEC": 4.79
  },
  {
    "LONG": -44.93,
    "LAT": -4.04,
    "CIDADE": "Lago Verde",
    "UF": "MARANHÃO",
    "anual": 5.17,
    "JAN": 4.64,
    "FEB": 4.77,
    "MAR": 4.97,
    "APR": 4.95,
    "MAY": 4.97,
    "JUN": 5.16,
    "JUL": 5.32,
    "AUG": 5.8,
    "SEP": 6.05,
    "OCT": 5.52,
    "NOV": 5.07,
    "DEC": 4.88
  },
  {
    "LONG": -4.45,
    "LAT": -4.04,
    "CIDADE": "São Mateus do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.32,
    "JAN": 4.77,
    "FEB": 4.88,
    "MAR": 5.06,
    "APR": 5.04,
    "MAY": 5.07,
    "JUN": 5.27,
    "JUL": 5.54,
    "AUG": 5.92,
    "SEP": 6.18,
    "OCT": 5.77,
    "NOV": 5.32,
    "DEC": 5.02
  },
  {
    "LONG": -45.18,
    "LAT": -3.89,
    "CIDADE": "Pio XII",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.57,
    "FEB": 4.85,
    "MAR": 4.94,
    "APR": 4.95,
    "MAY": 4.92,
    "JUN": 5.15,
    "JUL": 5.31,
    "AUG": 5.72,
    "SEP": 5.93,
    "OCT": 5.5,
    "NOV": 5.07,
    "DEC": 4.83
  },
  {
    "LONG": -42.92,
    "LAT": -3.94,
    "CIDADE": "Buriti",
    "UF": "MARANHÃO",
    "anual": 5.57,
    "JAN": 5.03,
    "FEB": 5.21,
    "MAR": 5.22,
    "APR": 5.19,
    "MAY": 5.2,
    "JUN": 5.4,
    "JUL": 5.61,
    "AUG": 6.09,
    "SEP": 6.3,
    "OCT": 6.21,
    "NOV": 5.91,
    "DEC": 5.43
  },
  {
    "LONG": -4.49,
    "LAT": -3.84,
    "CIDADE": "Conceição do Lago-Açu",
    "UF": "MARANHÃO",
    "anual": 5.29,
    "JAN": 4.69,
    "FEB": 4.86,
    "MAR": 5.01,
    "APR": 5.03,
    "MAY": 5.06,
    "JUN": 5.31,
    "JUL": 5.45,
    "AUG": 5.98,
    "SEP": 6.16,
    "OCT": 5.73,
    "NOV": 5.24,
    "DEC": 5
  },
  {
    "LONG": -45.84,
    "LAT": -3.67,
    "CIDADE": "Alto Alegre do Pindaré",
    "UF": "MARANHÃO",
    "anual": 4.93,
    "JAN": 4.42,
    "FEB": 4.71,
    "MAR": 4.85,
    "APR": 4.91,
    "MAY": 4.76,
    "JUN": 4.97,
    "JUL": 5.05,
    "AUG": 5.41,
    "SEP": 5.59,
    "OCT": 5.14,
    "NOV": 4.79,
    "DEC": 4.61
  },
  {
    "LONG": -45.62,
    "LAT": -3.67,
    "CIDADE": "Tufilândia",
    "UF": "MARANHÃO",
    "anual": 5.02,
    "JAN": 4.52,
    "FEB": 4.82,
    "MAR": 4.88,
    "APR": 4.96,
    "MAY": 4.86,
    "JUN": 5.03,
    "JUL": 5.19,
    "AUG": 5.5,
    "SEP": 5.7,
    "OCT": 5.26,
    "NOV": 4.86,
    "DEC": 4.61
  },
  {
    "LONG": -45.38,
    "LAT": -3.65,
    "CIDADE": "Santa Inês",
    "UF": "MARANHÃO",
    "anual": 5.07,
    "JAN": 4.53,
    "FEB": 4.8,
    "MAR": 4.91,
    "APR": 4.94,
    "MAY": 4.86,
    "JUN": 5.05,
    "JUL": 5.12,
    "AUG": 5.61,
    "SEP": 5.8,
    "OCT": 5.43,
    "NOV": 5.03,
    "DEC": 4.81
  },
  {
    "LONG": -45.25,
    "LAT": -3.74,
    "CIDADE": "Bela Vista do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.07,
    "JAN": 4.53,
    "FEB": 4.73,
    "MAR": 4.83,
    "APR": 4.89,
    "MAY": 4.87,
    "JUN": 5.13,
    "JUL": 5.18,
    "AUG": 5.63,
    "SEP": 5.85,
    "OCT": 5.39,
    "NOV": 4.95,
    "DEC": 4.83
  },
  {
    "LONG": -44.23,
    "LAT": -0.37,
    "CIDADE": "Pirapemas",
    "UF": "MARANHÃO",
    "anual": 5.32,
    "JAN": 4.67,
    "FEB": 4.93,
    "MAR": 5.01,
    "APR": 4.94,
    "MAY": 5.08,
    "JUN": 5.29,
    "JUL": 5.48,
    "AUG": 6.01,
    "SEP": 6.18,
    "OCT": 5.87,
    "NOV": 5.41,
    "DEC": 5.04
  },
  {
    "LONG": -43.35,
    "LAT": -3.74,
    "CIDADE": "Chapadinha",
    "UF": "MARANHÃO",
    "anual": 5.51,
    "JAN": 4.9,
    "FEB": 5.08,
    "MAR": 5.2,
    "APR": 5.1,
    "MAY": 5.09,
    "JUN": 5.35,
    "JUL": 5.62,
    "AUG": 6.08,
    "SEP": 6.38,
    "OCT": 6.2,
    "NOV": 5.81,
    "DEC": 5.33
  },
  {
    "LONG": -43.11,
    "LAT": -3.67,
    "CIDADE": "Anapurus",
    "UF": "MARANHÃO",
    "anual": 5.55,
    "JAN": 5.07,
    "FEB": 5.22,
    "MAR": 5.24,
    "APR": 5.19,
    "MAY": 5.17,
    "JUN": 5.39,
    "JUL": 5.55,
    "AUG": 6.07,
    "SEP": 6.32,
    "OCT": 6.18,
    "NOV": 5.87,
    "DEC": 5.4
  },
  {
    "LONG": -4.28,
    "LAT": -3.68,
    "CIDADE": "Brejo",
    "UF": "MARANHÃO",
    "anual": 5.64,
    "JAN": 5.11,
    "FEB": 5.32,
    "MAR": 5.36,
    "APR": 5.2,
    "MAY": 5.27,
    "JUN": 5.47,
    "JUL": 5.68,
    "AUG": 6.15,
    "SEP": 6.43,
    "OCT": 6.31,
    "NOV": 5.95,
    "DEC": 5.41
  },
  {
    "LONG": -46.25,
    "LAT": -3.55,
    "CIDADE": "São João do Carú",
    "UF": "MARANHÃO",
    "anual": 4.9,
    "JAN": 4.38,
    "FEB": 4.59,
    "MAR": 4.71,
    "APR": 4.84,
    "MAY": 4.77,
    "JUN": 5,
    "JUL": 5.12,
    "AUG": 5.42,
    "SEP": 5.48,
    "OCT": 5.08,
    "NOV": 4.73,
    "DEC": 4.62
  },
  {
    "LONG": -45.34,
    "LAT": -3.61,
    "CIDADE": "Pindaré-Mirim",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.61,
    "FEB": 4.89,
    "MAR": 4.97,
    "APR": 5.05,
    "MAY": 4.97,
    "JUN": 5.14,
    "JUL": 5.3,
    "AUG": 5.72,
    "SEP": 5.86,
    "OCT": 5.47,
    "NOV": 5.04,
    "DEC": 4.85
  },
  {
    "LONG": -45.18,
    "LAT": -3.64,
    "CIDADE": "Igarapé do Meio",
    "UF": "MARANHÃO",
    "anual": 5.11,
    "JAN": 4.59,
    "FEB": 4.7,
    "MAR": 4.81,
    "APR": 4.9,
    "MAY": 4.87,
    "JUN": 5.11,
    "JUL": 5.23,
    "AUG": 5.84,
    "SEP": 5.93,
    "OCT": 5.45,
    "NOV": 5.04,
    "DEC": 4.84
  },
  {
    "LONG": -44.55,
    "LAT": -3.62,
    "CIDADE": "Matões do Norte",
    "UF": "MARANHÃO",
    "anual": 5.21,
    "JAN": 4.6,
    "FEB": 4.79,
    "MAR": 4.88,
    "APR": 4.86,
    "MAY": 4.97,
    "JUN": 5.21,
    "JUL": 5.38,
    "AUG": 5.92,
    "SEP": 6.1,
    "OCT": 5.66,
    "NOV": 5.21,
    "DEC": 4.93
  },
  {
    "LONG": -44.58,
    "LAT": -3.56,
    "CIDADE": "Miranda do Norte",
    "UF": "MARANHÃO",
    "anual": 5.21,
    "JAN": 4.6,
    "FEB": 4.79,
    "MAR": 4.88,
    "APR": 4.86,
    "MAY": 4.97,
    "JUN": 5.21,
    "JUL": 5.38,
    "AUG": 5.92,
    "SEP": 6.1,
    "OCT": 5.66,
    "NOV": 5.21,
    "DEC": 4.93
  },
  {
    "LONG": -44.38,
    "LAT": -3.63,
    "CIDADE": "Cantanhede",
    "UF": "MARANHÃO",
    "anual": 5.27,
    "JAN": 4.5,
    "FEB": 4.75,
    "MAR": 4.91,
    "APR": 4.88,
    "MAY": 5,
    "JUN": 5.25,
    "JUL": 5.53,
    "AUG": 5.96,
    "SEP": 6.25,
    "OCT": 5.81,
    "NOV": 5.37,
    "DEC": 4.98
  },
  {
    "LONG": -43.12,
    "LAT": "#VALOR!",
    "CIDADE": "Mata Roma",
    "UF": "MARANHÃO",
    "anual": 5.56,
    "JAN": 4.92,
    "FEB": 5.15,
    "MAR": 5.22,
    "APR": 5.2,
    "MAY": 5.19,
    "JUN": 5.36,
    "JUL": 5.63,
    "AUG": 6.18,
    "SEP": 6.44,
    "OCT": 6.22,
    "NOV": 5.83,
    "DEC": 5.38
  },
  {
    "LONG": -42.61,
    "LAT": -3.58,
    "CIDADE": "Milagres do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.65,
    "JAN": 5.14,
    "FEB": 5.22,
    "MAR": 5.37,
    "APR": 5.23,
    "MAY": 5.33,
    "JUN": 5.49,
    "JUL": 5.68,
    "AUG": 6.2,
    "SEP": 6.43,
    "OCT": 6.28,
    "NOV": 5.96,
    "DEC": 5.47
  },
  {
    "LONG": -45.61,
    "LAT": -3.54,
    "CIDADE": "Bom Jardim",
    "UF": "MARANHÃO",
    "anual": 4.98,
    "JAN": 4.42,
    "FEB": 4.75,
    "MAR": 4.85,
    "APR": 4.89,
    "MAY": 4.83,
    "JUN": 4.99,
    "JUL": 5.1,
    "AUG": 5.5,
    "SEP": 5.63,
    "OCT": 5.27,
    "NOV": 4.89,
    "DEC": 4.68
  },
  {
    "LONG": "#VALOR!",
    "LAT": -3.48,
    "CIDADE": "Monção",
    "UF": "MARANHÃO",
    "anual": 5.16,
    "JAN": 4.56,
    "FEB": 4.86,
    "MAR": 4.95,
    "APR": 5.02,
    "MAY": 4.9,
    "JUN": 5.19,
    "JUL": 5.29,
    "AUG": 5.7,
    "SEP": 5.91,
    "OCT": 5.51,
    "NOV": 5.17,
    "DEC": 4.86
  },
  {
    "LONG": -44.86,
    "LAT": -3.45,
    "CIDADE": "Vitória do Mearim",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.66,
    "FEB": 4.88,
    "MAR": 4.94,
    "APR": 4.92,
    "MAY": 4.97,
    "JUN": 5.19,
    "JUL": 5.35,
    "AUG": 5.84,
    "SEP": 6.03,
    "OCT": 5.62,
    "NOV": 5.23,
    "DEC": 4.98
  },
  {
    "LONG": -44.78,
    "LAT": -3.46,
    "CIDADE": "Arari",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.64,
    "FEB": 4.87,
    "MAR": 4.9,
    "APR": 4.9,
    "MAY": 4.97,
    "JUN": 5.19,
    "JUL": 5.38,
    "AUG": 5.87,
    "SEP": 6.07,
    "OCT": 5.64,
    "NOV": 5.22,
    "DEC": 4.94
  },
  {
    "LONG": -43.91,
    "LAT": -3.47,
    "CIDADE": "Nina Rodrigues",
    "UF": "MARANHÃO",
    "anual": 5.33,
    "JAN": 4.67,
    "FEB": 4.84,
    "MAR": 5,
    "APR": 4.9,
    "MAY": 5.08,
    "JUN": 5.26,
    "JUL": 5.45,
    "AUG": 5.99,
    "SEP": 6.28,
    "OCT": 5.99,
    "NOV": 5.48,
    "DEC": 5.05
  },
  {
    "LONG": -43.92,
    "LAT": -3.55,
    "CIDADE": "Vargem Grande",
    "UF": "MARANHÃO",
    "anual": 5.33,
    "JAN": 4.67,
    "FEB": 4.84,
    "MAR": 5,
    "APR": 4.9,
    "MAY": 5.08,
    "JUN": 5.26,
    "JUL": 5.45,
    "AUG": 5.99,
    "SEP": 6.28,
    "OCT": 5.99,
    "NOV": 5.48,
    "DEC": 5.05
  },
  {
    "LONG": -42.56,
    "LAT": -3.5,
    "CIDADE": "Santa Quitéria do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.62,
    "JAN": 5.15,
    "FEB": 5.23,
    "MAR": 5.39,
    "APR": 5.21,
    "MAY": 5.27,
    "JUN": 5.46,
    "JUL": 5.68,
    "AUG": 6.1,
    "SEP": 6.33,
    "OCT": 6.26,
    "NOV": 5.95,
    "DEC": 5.42
  },
  {
    "LONG": -45.66,
    "LAT": -3.43,
    "CIDADE": "Governador Newton Bello",
    "UF": "MARANHÃO",
    "anual": 4.98,
    "JAN": 4.44,
    "FEB": 4.72,
    "MAR": 4.81,
    "APR": 4.87,
    "MAY": 4.82,
    "JUN": 4.99,
    "JUL": 5.11,
    "AUG": 5.46,
    "SEP": 5.65,
    "OCT": 5.3,
    "NOV": 4.93,
    "DEC": 4.69
  },
  {
    "LONG": -44.35,
    "LAT": -3.4,
    "CIDADE": "Itapecuru Mirim",
    "UF": "MARANHÃO",
    "anual": 5.21,
    "JAN": 4.62,
    "FEB": 4.71,
    "MAR": 4.81,
    "APR": 4.84,
    "MAY": 4.97,
    "JUN": 5.18,
    "JUL": 5.34,
    "AUG": 5.88,
    "SEP": 6.12,
    "OCT": 5.77,
    "NOV": 5.33,
    "DEC": 4.97
  },
  {
    "LONG": -44.02,
    "LAT": -3.41,
    "CIDADE": "Presidente Vargas",
    "UF": "MARANHÃO",
    "anual": 5.32,
    "JAN": 4.63,
    "FEB": 4.84,
    "MAR": 4.89,
    "APR": 4.86,
    "MAY": 5.06,
    "JUN": 5.29,
    "JUL": 5.5,
    "AUG": 6.06,
    "SEP": 6.3,
    "OCT": 5.96,
    "NOV": 5.43,
    "DEC": 5
  },
  {
    "LONG": -42.42,
    "LAT": -3.37,
    "CIDADE": "São Bernardo",
    "UF": "MARANHÃO",
    "anual": 5.59,
    "JAN": 5.09,
    "FEB": 5.18,
    "MAR": 5.28,
    "APR": 5.12,
    "MAY": 5.24,
    "JUN": 5.46,
    "JUL": 5.66,
    "AUG": 6.12,
    "SEP": 6.36,
    "OCT": 6.33,
    "NOV": 5.92,
    "DEC": 5.36
  },
  {
    "LONG": -42.2,
    "LAT": -3.4,
    "CIDADE": "Magalhães de Almeida",
    "UF": "MARANHÃO",
    "anual": 5.58,
    "JAN": 5.09,
    "FEB": 5.24,
    "MAR": 5.24,
    "APR": 5.11,
    "MAY": 5.26,
    "JUN": 5.48,
    "JUL": 5.67,
    "AUG": 6.05,
    "SEP": 6.28,
    "OCT": 6.27,
    "NOV": 5.93,
    "DEC": 5.37
  },
  {
    "LONG": -45.66,
    "LAT": -3.27,
    "CIDADE": "Zé Doca",
    "UF": "MARANHÃO",
    "anual": 5.02,
    "JAN": 4.52,
    "FEB": 4.75,
    "MAR": 4.82,
    "APR": 4.89,
    "MAY": 4.88,
    "JUN": 5.05,
    "JUL": 5.14,
    "AUG": 5.48,
    "SEP": 5.71,
    "OCT": 5.33,
    "NOV": 4.95,
    "DEC": 4.71
  },
  {
    "LONG": -45.18,
    "LAT": -3.28,
    "CIDADE": "Penalva",
    "UF": "MARANHÃO",
    "anual": 5.28,
    "JAN": 4.64,
    "FEB": 4.99,
    "MAR": 5.05,
    "APR": 5.06,
    "MAY": 5.05,
    "JUN": 5.28,
    "JUL": 5.39,
    "AUG": 5.84,
    "SEP": 6.06,
    "OCT": 5.71,
    "NOV": 5.3,
    "DEC": 4.97
  },
  {
    "LONG": -45.01,
    "LAT": -3.33,
    "CIDADE": "Cajari",
    "UF": "MARANHÃO",
    "anual": 5.28,
    "JAN": 4.63,
    "FEB": 4.96,
    "MAR": 5.04,
    "APR": 5.05,
    "MAY": 5.05,
    "JUN": 5.26,
    "JUL": 5.38,
    "AUG": 5.86,
    "SEP": 6.08,
    "OCT": 5.74,
    "NOV": 5.32,
    "DEC": 4.97
  },
  {
    "LONG": -44.62,
    "LAT": -3.26,
    "CIDADE": "Anajatuba",
    "UF": "MARANHÃO",
    "anual": 5.21,
    "JAN": 4.61,
    "FEB": 4.83,
    "MAR": 4.89,
    "APR": 4.82,
    "MAY": 4.95,
    "JUN": 5.17,
    "JUL": 5.34,
    "AUG": 5.91,
    "SEP": 6.05,
    "OCT": 5.66,
    "NOV": 5.29,
    "DEC": 4.97
  },
  {
    "LONG": -43.53,
    "LAT": -3.34,
    "CIDADE": "São Benedito do Rio Preto",
    "UF": "MARANHÃO",
    "anual": 5.37,
    "JAN": 4.71,
    "FEB": 4.8,
    "MAR": 4.87,
    "APR": 4.87,
    "MAY": 5.03,
    "JUN": 5.35,
    "JUL": 5.49,
    "AUG": 6.07,
    "SEP": 6.32,
    "OCT": 6.06,
    "NOV": 5.68,
    "DEC": 5.15
  },
  {
    "LONG": -45,
    "LAT": -3.21,
    "CIDADE": "Viana",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.57,
    "FEB": 4.91,
    "MAR": 4.98,
    "APR": 4.97,
    "MAY": 5.05,
    "JUN": 5.25,
    "JUL": 5.35,
    "AUG": 5.83,
    "SEP": 5.98,
    "OCT": 5.62,
    "NOV": 5.25,
    "DEC": 4.89
  },
  {
    "LONG": -43.51,
    "LAT": -3.16,
    "CIDADE": "Belágua",
    "UF": "MARANHÃO",
    "anual": 5.34,
    "JAN": 4.66,
    "FEB": 4.75,
    "MAR": 4.84,
    "APR": 4.83,
    "MAY": 5.02,
    "JUN": 5.32,
    "JUL": 5.5,
    "AUG": 6.08,
    "SEP": 6.31,
    "OCT": 6.05,
    "NOV": 5.66,
    "DEC": 5.09
  },
  {
    "LONG": -43.39,
    "LAT": -3.21,
    "CIDADE": "Urbano Santos",
    "UF": "MARANHÃO",
    "anual": 5.36,
    "JAN": 4.74,
    "FEB": 4.78,
    "MAR": 4.85,
    "APR": 4.86,
    "MAY": 5.07,
    "JUN": 5.31,
    "JUL": 5.48,
    "AUG": 6.04,
    "SEP": 6.32,
    "OCT": 6.07,
    "NOV": 5.73,
    "DEC": 5.1
  },
  {
    "LONG": -45.04,
    "LAT": -3.1,
    "CIDADE": "Matinha",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.55,
    "FEB": 4.83,
    "MAR": 4.88,
    "APR": 4.82,
    "MAY": 4.97,
    "JUN": 5.14,
    "JUL": 5.24,
    "AUG": 5.73,
    "SEP": 5.93,
    "OCT": 5.62,
    "NOV": 5.21,
    "DEC": 4.86
  },
  {
    "LONG": -4.43,
    "LAT": -3.14,
    "CIDADE": "Santa Rita",
    "UF": "MARANHÃO",
    "anual": 5.18,
    "JAN": 4.55,
    "FEB": 4.73,
    "MAR": 4.69,
    "APR": 4.64,
    "MAY": 4.86,
    "JUN": 5.17,
    "JUL": 5.37,
    "AUG": 5.95,
    "SEP": 6.13,
    "OCT": 5.77,
    "NOV": 5.37,
    "DEC": 4.91
  },
  {
    "LONG": -42.41,
    "LAT": -3.11,
    "CIDADE": "Santana do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.46,
    "JAN": 4.91,
    "FEB": 4.88,
    "MAR": 4.92,
    "APR": 4.89,
    "MAY": 5.11,
    "JUN": 5.3,
    "JUL": 5.55,
    "AUG": 6.14,
    "SEP": 6.38,
    "OCT": 6.26,
    "NOV": 5.91,
    "DEC": 5.29
  },
  {
    "LONG": -45.67,
    "LAT": -2.95,
    "CIDADE": "Araguanã",
    "UF": "MARANHÃO",
    "anual": 4.96,
    "JAN": 4.48,
    "FEB": 4.7,
    "MAR": 4.63,
    "APR": 4.72,
    "MAY": 4.81,
    "JUN": 4.98,
    "JUL": 5.1,
    "AUG": 5.52,
    "SEP": 5.66,
    "OCT": 5.32,
    "NOV": 4.97,
    "DEC": 4.63
  },
  {
    "LONG": -45.35,
    "LAT": -2.97,
    "CIDADE": "Pedro do Rosário",
    "UF": "MARANHÃO",
    "anual": 5.04,
    "JAN": 4.55,
    "FEB": 4.69,
    "MAR": 4.69,
    "APR": 4.72,
    "MAY": 4.82,
    "JUN": 5.06,
    "JUL": 5.16,
    "AUG": 5.69,
    "SEP": 5.75,
    "OCT": 5.47,
    "NOV": 5.14,
    "DEC": 4.73
  },
  {
    "LONG": -44.99,
    "LAT": -3,
    "CIDADE": "Olinda Nova do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.15,
    "JAN": 4.51,
    "FEB": 4.78,
    "MAR": 4.77,
    "APR": 4.84,
    "MAY": 4.94,
    "JUN": 5.17,
    "JUL": 5.3,
    "AUG": 5.75,
    "SEP": 5.93,
    "OCT": 5.6,
    "NOV": 5.33,
    "DEC": 4.93
  },
  {
    "LONG": -44.8,
    "LAT": -2.96,
    "CIDADE": "São João Batista",
    "UF": "MARANHÃO",
    "anual": 5.22,
    "JAN": 4.68,
    "FEB": 4.82,
    "MAR": 4.79,
    "APR": 4.84,
    "MAY": 4.92,
    "JUN": 5.16,
    "JUL": 5.23,
    "AUG": 5.71,
    "SEP": 6.01,
    "OCT": 5.82,
    "NOV": 5.57,
    "DEC": 5.13
  },
  {
    "LONG": -44.31,
    "LAT": -2.98,
    "CIDADE": "Bacabeira",
    "UF": "MARANHÃO",
    "anual": 5.13,
    "JAN": 4.59,
    "FEB": 4.62,
    "MAR": 4.56,
    "APR": 4.57,
    "MAY": 4.78,
    "JUN": 5.12,
    "JUL": 5.3,
    "AUG": 5.88,
    "SEP": 6.07,
    "OCT": 5.72,
    "NOV": 5.41,
    "DEC": 4.98
  },
  {
    "LONG": -44.88,
    "LAT": -2.89,
    "CIDADE": "São Vicente Ferrer",
    "UF": "MARANHÃO",
    "anual": 5.14,
    "JAN": 4.64,
    "FEB": 4.79,
    "MAR": 4.8,
    "APR": 4.84,
    "MAY": 4.9,
    "JUN": 5.13,
    "JUL": 5.21,
    "AUG": 5.7,
    "SEP": 5.82,
    "OCT": 5.54,
    "NOV": 5.34,
    "DEC": 4.98
  },
  {
    "LONG": -44.67,
    "LAT": -2.87,
    "CIDADE": "Cajapió",
    "UF": "MARANHÃO",
    "anual": 5.27,
    "JAN": 4.75,
    "FEB": 4.82,
    "MAR": 4.76,
    "APR": 4.74,
    "MAY": 4.84,
    "JUN": 5.12,
    "JUL": 5.29,
    "AUG": 5.88,
    "SEP": 6.11,
    "OCT": 5.99,
    "NOV": 5.71,
    "DEC": 5.25
  },
  {
    "LONG": -44.25,
    "LAT": -2.94,
    "CIDADE": "Rosário",
    "UF": "MARANHÃO",
    "anual": 5.17,
    "JAN": 4.61,
    "FEB": 4.59,
    "MAR": 4.56,
    "APR": 4.51,
    "MAY": 4.7,
    "JUN": 5.02,
    "JUL": 5.19,
    "AUG": 5.83,
    "SEP": 6.19,
    "OCT": 5.99,
    "NOV": 5.64,
    "DEC": 5.2
  },
  {
    "LONG": -44.04,
    "LAT": -2.87,
    "CIDADE": "Morros",
    "UF": "MARANHÃO",
    "anual": 4.99,
    "JAN": 4.4,
    "FEB": 4.47,
    "MAR": 4.49,
    "APR": 4.48,
    "MAY": 4.67,
    "JUN": 5.02,
    "JUL": 5.16,
    "AUG": 5.66,
    "SEP": 5.95,
    "OCT": 5.63,
    "NOV": 5.21,
    "DEC": 4.75
  },
  {
    "LONG": -44.05,
    "LAT": -2.93,
    "CIDADE": "Cachoeira Grande",
    "UF": "MARANHÃO",
    "anual": 4.99,
    "JAN": 4.4,
    "FEB": 4.47,
    "MAR": 4.49,
    "APR": 4.48,
    "MAY": 4.67,
    "JUN": 5.02,
    "JUL": 5.16,
    "AUG": 5.66,
    "SEP": 5.95,
    "OCT": 5.63,
    "NOV": 5.21,
    "DEC": 4.75
  },
  {
    "LONG": -44.07,
    "LAT": -2.93,
    "CIDADE": "Presidente Juscelino",
    "UF": "MARANHÃO",
    "anual": 4.99,
    "JAN": 4.4,
    "FEB": 4.47,
    "MAR": 4.49,
    "APR": 4.48,
    "MAY": 4.67,
    "JUN": 5.02,
    "JUL": 5.16,
    "AUG": 5.66,
    "SEP": 5.95,
    "OCT": 5.63,
    "NOV": 5.21,
    "DEC": 4.75
  },
  {
    "LONG": -41.91,
    "LAT": -2.89,
    "CIDADE": "Araioses",
    "UF": "MARANHÃO",
    "anual": 5.68,
    "JAN": 5.45,
    "FEB": 5.31,
    "MAR": 5.23,
    "APR": 5,
    "MAY": 5.23,
    "JUN": 5.44,
    "JUL": 5.64,
    "AUG": 6.24,
    "SEP": 6.31,
    "OCT": 6.12,
    "NOV": 6.25,
    "DEC": 5.92
  },
  {
    "LONG": -45.7,
    "LAT": -2.81,
    "CIDADE": "Nova Olinda do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.42,
    "FEB": 4.66,
    "MAR": 4.54,
    "APR": 4.72,
    "MAY": 4.79,
    "JUN": 5.02,
    "JUL": 5.07,
    "AUG": 5.43,
    "SEP": 5.55,
    "OCT": 5.27,
    "NOV": 5,
    "DEC": 4.58
  },
  {
    "LONG": -44.06,
    "LAT": -2.77,
    "CIDADE": "Icatu",
    "UF": "MARANHÃO",
    "anual": 4.97,
    "JAN": 4.4,
    "FEB": 4.42,
    "MAR": 4.44,
    "APR": 4.43,
    "MAY": 4.59,
    "JUN": 4.89,
    "JUL": 5.13,
    "AUG": 5.64,
    "SEP": 5.95,
    "OCT": 5.65,
    "NOV": 5.27,
    "DEC": 4.79
  },
  {
    "LONG": -44.06,
    "LAT": -2.84,
    "CIDADE": "Axixá",
    "UF": "MARANHÃO",
    "anual": 4.97,
    "JAN": 4.4,
    "FEB": 4.42,
    "MAR": 4.44,
    "APR": 4.43,
    "MAY": 4.59,
    "JUN": 4.89,
    "JUL": 5.13,
    "AUG": 5.64,
    "SEP": 5.95,
    "OCT": 5.65,
    "NOV": 5.27,
    "DEC": 4.79
  },
  {
    "LONG": -42.83,
    "LAT": -2.75,
    "CIDADE": "Barreirinhas",
    "UF": "MARANHÃO",
    "anual": 5.42,
    "JAN": 5.04,
    "FEB": 5.04,
    "MAR": 4.91,
    "APR": 4.79,
    "MAY": 4.99,
    "JUN": 5.09,
    "JUL": 5.32,
    "AUG": 6.04,
    "SEP": 6.36,
    "OCT": 6.07,
    "NOV": 5.9,
    "DEC": 5.52
  },
  {
    "LONG": -42.28,
    "LAT": -2.76,
    "CIDADE": "Tutóia",
    "UF": "MARANHÃO",
    "anual": 5.55,
    "JAN": 5.37,
    "FEB": 5.26,
    "MAR": 5.17,
    "APR": 5.02,
    "MAY": 5.14,
    "JUN": 5.31,
    "JUL": 5.51,
    "AUG": 6,
    "SEP": 6.05,
    "OCT": 5.83,
    "NOV": 6.1,
    "DEC": 5.82
  },
  {
    "LONG": -42.12,
    "LAT": -2.84,
    "CIDADE": "Água Doce do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.6,
    "JAN": 5.4,
    "FEB": 5.28,
    "MAR": 5.19,
    "APR": 5,
    "MAY": 5.17,
    "JUN": 5.36,
    "JUL": 5.58,
    "AUG": 6.11,
    "SEP": 6.16,
    "OCT": 5.9,
    "NOV": 6.17,
    "DEC": 5.85
  },
  {
    "LONG": -44.83,
    "LAT": -2.7,
    "CIDADE": "São Bento",
    "UF": "MARANHÃO",
    "anual": 5.14,
    "JAN": 4.58,
    "FEB": 4.79,
    "MAR": 4.79,
    "APR": 4.89,
    "MAY": 4.93,
    "JUN": 5.23,
    "JUL": 5.28,
    "AUG": 5.72,
    "SEP": 5.81,
    "OCT": 5.52,
    "NOV": 5.3,
    "DEC": 4.9
  },
  {
    "LONG": -4.47,
    "LAT": -2.71,
    "CIDADE": "Bacurituba",
    "UF": "MARANHÃO",
    "anual": 5.2,
    "JAN": 4.73,
    "FEB": 4.85,
    "MAR": 4.8,
    "APR": 4.86,
    "MAY": 4.91,
    "JUN": 5.21,
    "JUL": 5.29,
    "AUG": 5.77,
    "SEP": 5.86,
    "OCT": 5.6,
    "NOV": 5.43,
    "DEC": 5.08
  },
  {
    "LONG": -42.54,
    "LAT": -2.72,
    "CIDADE": "Paulino Neves",
    "UF": "MARANHÃO",
    "anual": 5.29,
    "JAN": 5.2,
    "FEB": 5.23,
    "MAR": 5.13,
    "APR": 5.01,
    "MAY": 5.12,
    "JUN": 5.15,
    "JUL": 5.26,
    "AUG": 5.47,
    "SEP": 5.44,
    "OCT": 5.34,
    "NOV": 5.58,
    "DEC": 5.54
  },
  {
    "LONG": -45.36,
    "LAT": -2.59,
    "CIDADE": "Presidente Sarney",
    "UF": "MARANHÃO",
    "anual": 5.01,
    "JAN": 4.5,
    "FEB": 4.58,
    "MAR": 4.49,
    "APR": 4.6,
    "MAY": 4.76,
    "JUN": 5.05,
    "JUL": 5.15,
    "AUG": 5.68,
    "SEP": 5.82,
    "OCT": 5.53,
    "NOV": 5.21,
    "DEC": 4.78
  },
  {
    "LONG": -44.85,
    "LAT": -2.58,
    "CIDADE": "Peri Mirim",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.6,
    "FEB": 4.67,
    "MAR": 4.66,
    "APR": 4.74,
    "MAY": 4.83,
    "JUN": 5.15,
    "JUL": 5.3,
    "AUG": 5.74,
    "SEP": 5.85,
    "OCT": 5.54,
    "NOV": 5.25,
    "DEC": 4.91
  },
  {
    "LONG": -44.9,
    "LAT": -2.65,
    "CIDADE": "Palmeirândia",
    "UF": "MARANHÃO",
    "anual": 5.1,
    "JAN": 4.6,
    "FEB": 4.67,
    "MAR": 4.66,
    "APR": 4.74,
    "MAY": 4.83,
    "JUN": 5.15,
    "JUL": 5.3,
    "AUG": 5.74,
    "SEP": 5.85,
    "OCT": 5.54,
    "NOV": 5.25,
    "DEC": 4.91
  },
  {
    "LONG": -44.06,
    "LAT": -2.55,
    "CIDADE": "São José de Ribamar",
    "UF": "MARANHÃO",
    "anual": 5.32,
    "JAN": 4.93,
    "FEB": 4.83,
    "MAR": 4.73,
    "APR": 4.61,
    "MAY": 4.79,
    "JUN": 5.07,
    "JUL": 5.29,
    "AUG": 5.92,
    "SEP": 6.19,
    "OCT": 6.01,
    "NOV": 5.86,
    "DEC": 5.54
  },
  {
    "LONG": -43.47,
    "LAT": -2.6,
    "CIDADE": "Humberto de Campos",
    "UF": "MARANHÃO",
    "anual": 5.25,
    "JAN": 4.81,
    "FEB": 4.84,
    "MAR": 4.73,
    "APR": 4.67,
    "MAY": 4.8,
    "JUN": 5.01,
    "JUL": 5.21,
    "AUG": 5.9,
    "SEP": 6.22,
    "OCT": 5.91,
    "NOV": 5.64,
    "DEC": 5.3
  },
  {
    "LONG": -46.04,
    "LAT": -2.45,
    "CIDADE": "Centro do Guilherme",
    "UF": "MARANHÃO",
    "anual": 4.85,
    "JAN": 4.37,
    "FEB": 4.58,
    "MAR": 4.56,
    "APR": 4.59,
    "MAY": 4.72,
    "JUN": 4.95,
    "JUL": 5.01,
    "AUG": 5.35,
    "SEP": 5.49,
    "OCT": 5.16,
    "NOV": 4.91,
    "DEC": 4.53
  },
  {
    "LONG": -45.78,
    "LAT": -2.54,
    "CIDADE": "Santa Luzia do Paruá",
    "UF": "MARANHÃO",
    "anual": 4.95,
    "JAN": 4.47,
    "FEB": 4.59,
    "MAR": 4.53,
    "APR": 4.63,
    "MAY": 4.73,
    "JUN": 4.99,
    "JUL": 5.09,
    "AUG": 5.62,
    "SEP": 5.74,
    "OCT": 5.37,
    "NOV": 5.01,
    "DEC": 4.68
  },
  {
    "LONG": -45.08,
    "LAT": -2.52,
    "CIDADE": "Pinheiro",
    "UF": "MARANHÃO",
    "anual": 5.04,
    "JAN": 4.59,
    "FEB": 4.62,
    "MAR": 4.55,
    "APR": 4.53,
    "MAY": 4.79,
    "JUN": 5.07,
    "JUL": 5.21,
    "AUG": 5.73,
    "SEP": 5.83,
    "OCT": 5.5,
    "NOV": 5.23,
    "DEC": 4.82
  },
  {
    "LONG": -44.28,
    "LAT": -2.54,
    "CIDADE": "São Luís",
    "UF": "MARANHÃO",
    "anual": 5.21,
    "JAN": 4.87,
    "FEB": 4.94,
    "MAR": 4.78,
    "APR": 4.68,
    "MAY": 4.76,
    "JUN": 4.94,
    "JUL": 5.17,
    "AUG": 5.73,
    "SEP": 6.01,
    "OCT": 5.69,
    "NOV": 5.55,
    "DEC": 5.37
  },
  {
    "LONG": -44.11,
    "LAT": -2.52,
    "CIDADE": "Paço do Lumiar",
    "UF": "MARANHÃO",
    "anual": 5.17,
    "JAN": 4.82,
    "FEB": 4.86,
    "MAR": 4.76,
    "APR": 4.63,
    "MAY": 4.74,
    "JUN": 4.93,
    "JUL": 5.15,
    "AUG": 5.72,
    "SEP": 5.97,
    "OCT": 5.68,
    "NOV": 5.47,
    "DEC": 5.29
  },
  {
    "LONG": -43.42,
    "LAT": -2.51,
    "CIDADE": "Primeira Cruz",
    "UF": "MARANHÃO",
    "anual": 5.31,
    "JAN": 4.85,
    "FEB": 4.86,
    "MAR": 4.74,
    "APR": 4.73,
    "MAY": 4.95,
    "JUN": 5.12,
    "JUL": 5.3,
    "AUG": 5.99,
    "SEP": 6.18,
    "OCT": 5.94,
    "NOV": 5.69,
    "DEC": 5.32
  },
  {
    "LONG": -43.25,
    "LAT": -2.5,
    "CIDADE": "Santo Amaro do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.36,
    "JAN": 5,
    "FEB": 5.03,
    "MAR": 4.89,
    "APR": 4.87,
    "MAY": 5.05,
    "JUN": 5.28,
    "JUL": 5.46,
    "AUG": 5.88,
    "SEP": 6.02,
    "OCT": 5.86,
    "NOV": 5.65,
    "DEC": 5.35
  },
  {
    "LONG": -45.82,
    "LAT": -0.24,
    "CIDADE": "Presidente Médici",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.44,
    "FEB": 4.51,
    "MAR": 4.46,
    "APR": 4.66,
    "MAY": 4.69,
    "JUN": 5,
    "JUL": 5.1,
    "AUG": 5.58,
    "SEP": 5.69,
    "OCT": 5.35,
    "NOV": 5.01,
    "DEC": 4.6
  },
  {
    "LONG": -44.79,
    "LAT": -2.45,
    "CIDADE": "Bequimão",
    "UF": "MARANHÃO",
    "anual": 5.04,
    "JAN": 4.71,
    "FEB": 4.64,
    "MAR": 4.6,
    "APR": 4.51,
    "MAY": 4.66,
    "JUN": 4.99,
    "JUL": 5.1,
    "AUG": 5.6,
    "SEP": 5.8,
    "OCT": 5.48,
    "NOV": 5.32,
    "DEC": 5.07
  },
  {
    "LONG": -44.42,
    "LAT": -2.41,
    "CIDADE": "Alcântara",
    "UF": "MARANHÃO",
    "anual": 5.24,
    "JAN": 4.93,
    "FEB": 4.91,
    "MAR": 4.79,
    "APR": 4.73,
    "MAY": 4.92,
    "JUN": 5.14,
    "JUL": 5.29,
    "AUG": 5.71,
    "SEP": 5.92,
    "OCT": 5.68,
    "NOV": 5.53,
    "DEC": 5.26
  },
  {
    "LONG": -44.1,
    "LAT": -2.44,
    "CIDADE": "Raposa",
    "UF": "MARANHÃO",
    "anual": 5.38,
    "JAN": 5.15,
    "FEB": 5.09,
    "MAR": 4.95,
    "APR": 4.8,
    "MAY": 4.96,
    "JUN": 5.22,
    "JUL": 5.41,
    "AUG": 5.77,
    "SEP": 5.96,
    "OCT": 5.89,
    "NOV": 5.81,
    "DEC": 5.6
  },
  {
    "LONG": -45.86,
    "LAT": -2.24,
    "CIDADE": "Maranhãozinho",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.49,
    "FEB": 4.43,
    "MAR": 4.39,
    "APR": 4.54,
    "MAY": 4.62,
    "JUN": 4.96,
    "JUL": 5.07,
    "AUG": 5.57,
    "SEP": 5.73,
    "OCT": 5.42,
    "NOV": 5.1,
    "DEC": 4.66
  },
  {
    "LONG": -45.31,
    "LAT": -2.22,
    "CIDADE": "Turilândia",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.53,
    "FEB": 4.45,
    "MAR": 4.37,
    "APR": 4.4,
    "MAY": 4.63,
    "JUN": 4.96,
    "JUL": 5.02,
    "AUG": 5.56,
    "SEP": 5.75,
    "OCT": 5.43,
    "NOV": 5.15,
    "DEC": 4.74
  },
  {
    "LONG": -45.3,
    "LAT": -0.22,
    "CIDADE": "Santa Helena",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.53,
    "FEB": 4.45,
    "MAR": 4.37,
    "APR": 4.4,
    "MAY": 4.63,
    "JUN": 4.96,
    "JUL": 5.02,
    "AUG": 5.56,
    "SEP": 5.75,
    "OCT": 5.43,
    "NOV": 5.15,
    "DEC": 4.74
  },
  {
    "LONG": -44.83,
    "LAT": -2.2,
    "CIDADE": "Central do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.05,
    "JAN": 4.76,
    "FEB": 4.67,
    "MAR": 4.57,
    "APR": 4.5,
    "MAY": 4.71,
    "JUN": 4.99,
    "JUL": 5.12,
    "AUG": 5.63,
    "SEP": 5.8,
    "OCT": 5.48,
    "NOV": 5.36,
    "DEC": 5.07
  },
  {
    "LONG": -46.12,
    "LAT": -0.21,
    "CIDADE": "Centro Novo do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.8,
    "JAN": 4.36,
    "FEB": 4.42,
    "MAR": 4.41,
    "APR": 4.49,
    "MAY": 4.59,
    "JUN": 4.87,
    "JUL": 4.96,
    "AUG": 5.38,
    "SEP": 5.51,
    "OCT": 5.17,
    "NOV": 4.9,
    "DEC": 4.51
  },
  {
    "LONG": -45.89,
    "LAT": -0.21,
    "CIDADE": "Governador Nunes Freire",
    "UF": "MARANHÃO",
    "anual": 4.87,
    "JAN": 4.45,
    "FEB": 4.4,
    "MAR": 4.37,
    "APR": 4.5,
    "MAY": 4.56,
    "JUN": 4.91,
    "JUL": 5.03,
    "AUG": 5.49,
    "SEP": 5.65,
    "OCT": 5.32,
    "NOV": 5.08,
    "DEC": 4.61
  },
  {
    "LONG": -44.78,
    "LAT": -2.07,
    "CIDADE": "Mirinzal",
    "UF": "MARANHÃO",
    "anual": 5.03,
    "JAN": 4.66,
    "FEB": 4.66,
    "MAR": 4.57,
    "APR": 4.48,
    "MAY": 4.73,
    "JUN": 5.03,
    "JUL": 5.16,
    "AUG": 5.64,
    "SEP": 5.74,
    "OCT": 5.43,
    "NOV": 5.3,
    "DEC": 4.97
  },
  {
    "LONG": -44.61,
    "LAT": -2.13,
    "CIDADE": "Guimarães",
    "UF": "MARANHÃO",
    "anual": 5.05,
    "JAN": 4.71,
    "FEB": 4.72,
    "MAR": 4.64,
    "APR": 4.51,
    "MAY": 4.78,
    "JUN": 5.09,
    "JUL": 5.21,
    "AUG": 5.56,
    "SEP": 5.73,
    "OCT": 5.43,
    "NOV": 5.29,
    "DEC": 4.97
  },
  {
    "LONG": -45.96,
    "LAT": -2.05,
    "CIDADE": "Maracaçumé",
    "UF": "MARANHÃO",
    "anual": 4.84,
    "JAN": 4.43,
    "FEB": 4.39,
    "MAR": 4.36,
    "APR": 4.41,
    "MAY": 4.56,
    "JUN": 4.91,
    "JUL": 4.99,
    "AUG": 5.43,
    "SEP": 5.61,
    "OCT": 5.31,
    "NOV": 5.05,
    "DEC": 4.65
  },
  {
    "LONG": -44.53,
    "LAT": -2,
    "CIDADE": "Cedral",
    "UF": "MARANHÃO",
    "anual": 5.13,
    "JAN": 4.86,
    "FEB": 4.87,
    "MAR": 4.78,
    "APR": 4.56,
    "MAY": 4.9,
    "JUN": 5.09,
    "JUL": 5.14,
    "AUG": 5.48,
    "SEP": 5.76,
    "OCT": 5.54,
    "NOV": 5.45,
    "DEC": 5.15
  },
  {
    "LONG": -46.07,
    "LAT": -1.88,
    "CIDADE": "Junco do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.87,
    "JAN": 4.5,
    "FEB": 4.38,
    "MAR": 4.35,
    "APR": 4.4,
    "MAY": 4.55,
    "JUN": 4.89,
    "JUL": 4.98,
    "AUG": 5.46,
    "SEP": 5.68,
    "OCT": 5.36,
    "NOV": 5.16,
    "DEC": 4.68
  },
  {
    "LONG": -45.12,
    "LAT": -1.85,
    "CIDADE": "Serrano do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.9,
    "JAN": 4.58,
    "FEB": 4.47,
    "MAR": 4.32,
    "APR": 4.26,
    "MAY": 4.54,
    "JUN": 4.89,
    "JUL": 5.04,
    "AUG": 5.48,
    "SEP": 5.71,
    "OCT": 5.4,
    "NOV": 5.2,
    "DEC": 4.91
  },
  {
    "LONG": -44.6,
    "LAT": -1.86,
    "CIDADE": "Porto Rico do Maranhão",
    "UF": "MARANHÃO",
    "anual": 5.18,
    "JAN": 4.92,
    "FEB": 4.96,
    "MAR": 4.79,
    "APR": 4.59,
    "MAY": 4.9,
    "JUN": 5.12,
    "JUL": 5.16,
    "AUG": 5.52,
    "SEP": 5.81,
    "OCT": 5.64,
    "NOV": 5.54,
    "DEC": 5.23
  },
  {
    "LONG": -46.3,
    "LAT": -1.78,
    "CIDADE": "Boa Vista do Gurupi",
    "UF": "MARANHÃO",
    "anual": 4.82,
    "JAN": 4.36,
    "FEB": 4.32,
    "MAR": 4.31,
    "APR": 4.42,
    "MAY": 4.57,
    "JUN": 4.84,
    "JUL": 5.02,
    "AUG": 5.4,
    "SEP": 5.59,
    "OCT": 5.27,
    "NOV": 5.06,
    "DEC": 4.68
  },
  {
    "LONG": -44.87,
    "LAT": -1.82,
    "CIDADE": "Cururupu",
    "UF": "MARANHÃO",
    "anual": 5.03,
    "JAN": 4.83,
    "FEB": 4.73,
    "MAR": 4.56,
    "APR": 4.44,
    "MAY": 4.67,
    "JUN": 4.97,
    "JUL": 5.06,
    "AUG": 5.46,
    "SEP": 5.76,
    "OCT": 5.46,
    "NOV": 5.38,
    "DEC": 5.04
  },
  {
    "LONG": -46,
    "LAT": -1.68,
    "CIDADE": "Amapá do Maranhão",
    "UF": "MARANHÃO",
    "anual": 4.82,
    "JAN": 4.5,
    "FEB": 4.33,
    "MAR": 4.24,
    "APR": 4.29,
    "MAY": 4.46,
    "JUN": 4.78,
    "JUL": 4.92,
    "AUG": 5.46,
    "SEP": 5.69,
    "OCT": 5.35,
    "NOV": 5.11,
    "DEC": 4.68
  },
  {
    "LONG": -45.37,
    "LAT": -1.67,
    "CIDADE": "Turiaçu",
    "UF": "MARANHÃO",
    "anual": 4.83,
    "JAN": 4.56,
    "FEB": 4.43,
    "MAR": 4.2,
    "APR": 4.2,
    "MAY": 4.42,
    "JUN": 4.76,
    "JUL": 4.93,
    "AUG": 5.37,
    "SEP": 5.63,
    "OCT": 5.37,
    "NOV": 5.17,
    "DEC": 4.88
  },
  {
    "LONG": "#VALOR!",
    "LAT": -1.73,
    "CIDADE": "Bacuri",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.65,
    "FEB": 4.5,
    "MAR": 4.31,
    "APR": 4.25,
    "MAY": 4.55,
    "JUN": 4.88,
    "JUL": 5.05,
    "AUG": 5.5,
    "SEP": 5.73,
    "OCT": 5.4,
    "NOV": 5.28,
    "DEC": 4.92
  },
  {
    "LONG": -45.08,
    "LAT": -1.53,
    "CIDADE": "Apicum-Açu",
    "UF": "MARANHÃO",
    "anual": 5.11,
    "JAN": 4.92,
    "FEB": 4.67,
    "MAR": 4.53,
    "APR": 4.44,
    "MAY": 4.7,
    "JUN": 5,
    "JUL": 5.15,
    "AUG": 5.59,
    "SEP": 5.93,
    "OCT": 5.65,
    "NOV": 5.52,
    "DEC": 5.25
  },
  {
    "LONG": -45.78,
    "LAT": -0.14,
    "CIDADE": "Godofredo Viana",
    "UF": "MARANHÃO",
    "anual": 4.88,
    "JAN": 4.69,
    "FEB": 4.45,
    "MAR": 4.31,
    "APR": 4.24,
    "MAY": 4.51,
    "JUN": 4.82,
    "JUL": 4.91,
    "AUG": 5.32,
    "SEP": 5.65,
    "OCT": 5.42,
    "NOV": 5.26,
    "DEC": 4.97
  },
  {
    "LONG": -45.73,
    "LAT": -0.14,
    "CIDADE": "Cândido Mendes",
    "UF": "MARANHÃO",
    "anual": 4.88,
    "JAN": 4.69,
    "FEB": 4.45,
    "MAR": 4.31,
    "APR": 4.24,
    "MAY": 4.51,
    "JUN": 4.82,
    "JUL": 4.91,
    "AUG": 5.32,
    "SEP": 5.65,
    "OCT": 5.42,
    "NOV": 5.26,
    "DEC": 4.97
  },
  {
    "LONG": -45.9,
    "LAT": -1.33,
    "CIDADE": "Luís Domingues",
    "UF": "MARANHÃO",
    "anual": 4.92,
    "JAN": 4.74,
    "FEB": 4.49,
    "MAR": 4.36,
    "APR": 4.31,
    "MAY": 4.58,
    "JUN": 4.82,
    "JUL": 4.99,
    "AUG": 5.33,
    "SEP": 5.7,
    "OCT": 5.44,
    "NOV": 5.3,
    "DEC": 4.99
  },
  {
    "LONG": -46.02,
    "LAT": -1.2,
    "CIDADE": "Carutapera",
    "UF": "MARANHÃO",
    "anual": 4.93,
    "JAN": 4.74,
    "FEB": 4.51,
    "MAR": 4.34,
    "APR": 4.31,
    "MAY": 4.53,
    "JUN": 4.83,
    "JUL": 4.94,
    "AUG": 5.28,
    "SEP": 5.67,
    "OCT": 5.51,
    "NOV": 5.37,
    "DEC": 5.07
  },
  {
    "LONG": -53.28,
    "LAT": -17.83,
    "CIDADE": "Alto Taquari",
    "UF": "MATO GROSSO",
    "anual": 5.26,
    "JAN": 4.96,
    "FEB": 5.28,
    "MAR": 5.18,
    "APR": 5.4,
    "MAY": 5.27,
    "JUN": 5.2,
    "JUL": 5.3,
    "AUG": 6.01,
    "SEP": 5.47,
    "OCT": 5.05,
    "NOV": 4.95,
    "DEC": 5.05
  },
  {
    "LONG": -53.22,
    "LAT": -17.32,
    "CIDADE": "Alto Araguaia",
    "UF": "MATO GROSSO",
    "anual": 5.35,
    "JAN": 5.01,
    "FEB": 5.34,
    "MAR": 5.24,
    "APR": 5.59,
    "MAY": 5.41,
    "JUN": 5.32,
    "JUL": 5.4,
    "AUG": 6.11,
    "SEP": 5.56,
    "OCT": 5.11,
    "NOV": 5.02,
    "DEC": 5.06
  },
  {
    "LONG": -5.41,
    "LAT": -17.2,
    "CIDADE": "Itiquira",
    "UF": "MATO GROSSO",
    "anual": 5.35,
    "JAN": 5.11,
    "FEB": 5.28,
    "MAR": 5.32,
    "APR": 5.47,
    "MAY": 5.3,
    "JUN": 5.26,
    "JUL": 5.43,
    "AUG": 6.1,
    "SEP": 5.45,
    "OCT": 5.13,
    "NOV": 5.18,
    "DEC": 5.17
  },
  {
    "LONG": -53.53,
    "LAT": -16.95,
    "CIDADE": "Alto Garças",
    "UF": "MATO GROSSO",
    "anual": 5.32,
    "JAN": 5.04,
    "FEB": 5.3,
    "MAR": 5.19,
    "APR": 5.51,
    "MAY": 5.4,
    "JUN": 5.26,
    "JUL": 5.39,
    "AUG": 5.97,
    "SEP": 5.5,
    "OCT": 5.14,
    "NOV": 5.06,
    "DEC": 5.1
  },
  {
    "LONG": -53.03,
    "LAT": -16.86,
    "CIDADE": "Araguainha",
    "UF": "MATO GROSSO",
    "anual": 5.42,
    "JAN": 4.98,
    "FEB": 5.39,
    "MAR": 5.39,
    "APR": 5.71,
    "MAY": 5.58,
    "JUN": 5.36,
    "JUL": 5.43,
    "AUG": 6.16,
    "SEP": 5.61,
    "OCT": 5.18,
    "NOV": 5.15,
    "DEC": 5.16
  },
  {
    "LONG": -52.84,
    "LAT": -16.77,
    "CIDADE": "Ponte Branca",
    "UF": "MATO GROSSO",
    "anual": 5.43,
    "JAN": 5.05,
    "FEB": 5.46,
    "MAR": 5.32,
    "APR": 5.71,
    "MAY": 5.61,
    "JUN": 5.35,
    "JUL": 5.35,
    "AUG": 6.15,
    "SEP": 5.61,
    "OCT": 5.22,
    "NOV": 5.12,
    "DEC": 5.17
  },
  {
    "LONG": -54.48,
    "LAT": -1.66,
    "CIDADE": "Pedra Preta",
    "UF": "MATO GROSSO",
    "anual": 5.44,
    "JAN": 5.18,
    "FEB": 5.41,
    "MAR": 5.49,
    "APR": 5.64,
    "MAY": 5.44,
    "JUN": 5.36,
    "JUL": 5.44,
    "AUG": 6.13,
    "SEP": 5.45,
    "OCT": 5.23,
    "NOV": 5.25,
    "DEC": 5.2
  },
  {
    "LONG": -54.64,
    "LAT": -16.47,
    "CIDADE": "Rondonópolis",
    "UF": "MATO GROSSO",
    "anual": 5.34,
    "JAN": 5.09,
    "FEB": 5.29,
    "MAR": 5.35,
    "APR": 5.52,
    "MAY": 5.33,
    "JUN": 5.26,
    "JUL": 5.36,
    "AUG": 6.11,
    "SEP": 5.42,
    "OCT": 5.13,
    "NOV": 5.08,
    "DEC": 5.1
  },
  {
    "LONG": -54.25,
    "LAT": -16.47,
    "CIDADE": "São José do Povo",
    "UF": "MATO GROSSO",
    "anual": 5.46,
    "JAN": 5.18,
    "FEB": 5.4,
    "MAR": 5.49,
    "APR": 5.66,
    "MAY": 5.48,
    "JUN": 5.4,
    "JUL": 5.5,
    "AUG": 6.17,
    "SEP": 5.49,
    "OCT": 5.27,
    "NOV": 5.26,
    "DEC": 5.23
  },
  {
    "LONG": -52.69,
    "LAT": -16.49,
    "CIDADE": "Ribeirãozinho",
    "UF": "MATO GROSSO",
    "anual": 5.43,
    "JAN": 5.15,
    "FEB": 5.43,
    "MAR": 5.35,
    "APR": 5.75,
    "MAY": 5.59,
    "JUN": 5.37,
    "JUL": 5.34,
    "AUG": 5.99,
    "SEP": 5.6,
    "OCT": 5.24,
    "NOV": 5.2,
    "DEC": 5.17
  },
  {
    "LONG": -56.63,
    "LAT": -16.27,
    "CIDADE": "Poconé",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.2,
    "MAR": 5.24,
    "APR": 5.26,
    "MAY": 4.87,
    "JUN": 4.97,
    "JUL": 5.12,
    "AUG": 5.82,
    "SEP": 5.23,
    "OCT": 5.19,
    "NOV": 5.12,
    "DEC": 5.23
  },
  {
    "LONG": -53.76,
    "LAT": -16.35,
    "CIDADE": "Guiratinga",
    "UF": "MATO GROSSO",
    "anual": 5.41,
    "JAN": 5,
    "FEB": 5.21,
    "MAR": 5.32,
    "APR": 5.58,
    "MAY": 5.49,
    "JUN": 5.45,
    "JUL": 5.57,
    "AUG": 6.26,
    "SEP": 5.59,
    "OCT": 5.22,
    "NOV": 5.13,
    "DEC": 5.07
  },
  {
    "LONG": -55.97,
    "LAT": -16.2,
    "CIDADE": "Barão de Melgaço",
    "UF": "MATO GROSSO",
    "anual": 5.32,
    "JAN": 5.17,
    "FEB": 5.33,
    "MAR": 5.43,
    "APR": 5.59,
    "MAY": 5.09,
    "JUN": 5.2,
    "JUL": 5.22,
    "AUG": 6.06,
    "SEP": 5.26,
    "OCT": 5.17,
    "NOV": 5.19,
    "DEC": 5.18
  },
  {
    "LONG": -52.56,
    "LAT": -1.62,
    "CIDADE": "Torixoréu",
    "UF": "MATO GROSSO",
    "anual": 5.45,
    "JAN": 5.07,
    "FEB": 5.4,
    "MAR": 5.34,
    "APR": 5.8,
    "MAY": 5.65,
    "JUN": 5.41,
    "JUL": 5.46,
    "AUG": 6.06,
    "SEP": 5.64,
    "OCT": 5.31,
    "NOV": 5.14,
    "DEC": 5.1
  },
  {
    "LONG": -57.68,
    "LAT": -16.08,
    "CIDADE": "Cáceres",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.16,
    "MAR": 5.25,
    "APR": 5.25,
    "MAY": 4.86,
    "JUN": 5.02,
    "JUL": 5.08,
    "AUG": 5.74,
    "SEP": 5.33,
    "OCT": 5.22,
    "NOV": 5.17,
    "DEC": 5.16
  },
  {
    "LONG": -54.89,
    "LAT": -16.05,
    "CIDADE": "Juscimeira",
    "UF": "MATO GROSSO",
    "anual": 5.32,
    "JAN": 5.03,
    "FEB": 5.25,
    "MAR": 5.26,
    "APR": 5.51,
    "MAY": 5.33,
    "JUN": 5.35,
    "JUL": 5.4,
    "AUG": 6.2,
    "SEP": 5.38,
    "OCT": 5.06,
    "NOV": 5.06,
    "DEC": 5.06
  },
  {
    "LONG": -53.55,
    "LAT": -16.08,
    "CIDADE": "Tesouro",
    "UF": "MATO GROSSO",
    "anual": 5.42,
    "JAN": 5.08,
    "FEB": 5.3,
    "MAR": 5.28,
    "APR": 5.7,
    "MAY": 5.58,
    "JUN": 5.45,
    "JUL": 5.55,
    "AUG": 6.17,
    "SEP": 5.61,
    "OCT": 5.21,
    "NOV": 5.09,
    "DEC": 5.04
  },
  {
    "LONG": -54.97,
    "LAT": -15.96,
    "CIDADE": "Jaciara",
    "UF": "MATO GROSSO",
    "anual": 5.3,
    "JAN": 4.94,
    "FEB": 5.15,
    "MAR": 5.2,
    "APR": 5.54,
    "MAY": 5.35,
    "JUN": 5.39,
    "JUL": 5.41,
    "AUG": 6.21,
    "SEP": 5.36,
    "OCT": 5,
    "NOV": 5.02,
    "DEC": 4.99
  },
  {
    "LONG": -54.92,
    "LAT": -16,
    "CIDADE": "São Pedro da Cipa",
    "UF": "MATO GROSSO",
    "anual": 5.3,
    "JAN": 4.94,
    "FEB": 5.15,
    "MAR": 5.2,
    "APR": 5.54,
    "MAY": 5.35,
    "JUN": 5.39,
    "JUL": 5.41,
    "AUG": 6.21,
    "SEP": 5.36,
    "OCT": 5,
    "NOV": 5.02,
    "DEC": 4.99
  },
  {
    "LONG": -58.46,
    "LAT": -15.86,
    "CIDADE": "Porto Esperidião",
    "UF": "MATO GROSSO",
    "anual": 5.1,
    "JAN": 5.02,
    "FEB": 5.11,
    "MAR": 5.13,
    "APR": 5.17,
    "MAY": 4.64,
    "JUN": 4.86,
    "JUL": 4.96,
    "AUG": 5.5,
    "SEP": 5.27,
    "OCT": 5.22,
    "NOV": 5.16,
    "DEC": 5.11
  },
  {
    "LONG": -56.08,
    "LAT": -15.86,
    "CIDADE": "Santo Antônio do Leverger",
    "UF": "MATO GROSSO",
    "anual": 5.29,
    "JAN": 5.1,
    "FEB": 5.25,
    "MAR": 5.35,
    "APR": 5.47,
    "MAY": 5.12,
    "JUN": 5.16,
    "JUL": 5.24,
    "AUG": 6.05,
    "SEP": 5.27,
    "OCT": 5.14,
    "NOV": 5.15,
    "DEC": 5.21
  },
  {
    "LONG": -5.23,
    "LAT": -15.89,
    "CIDADE": "Barra do Garças",
    "UF": "MATO GROSSO",
    "anual": 5.42,
    "JAN": 5.03,
    "FEB": 5.37,
    "MAR": 5.28,
    "APR": 5.76,
    "MAY": 5.7,
    "JUN": 5.43,
    "JUL": 5.5,
    "AUG": 6.09,
    "SEP": 5.49,
    "OCT": 5.34,
    "NOV": 5.05,
    "DEC": 5.04
  },
  {
    "LONG": -52.26,
    "LAT": -1.59,
    "CIDADE": "Pontal do Araguaia",
    "UF": "MATO GROSSO",
    "anual": 5.42,
    "JAN": 5.03,
    "FEB": 5.37,
    "MAR": 5.28,
    "APR": 5.76,
    "MAY": 5.7,
    "JUN": 5.43,
    "JUL": 5.5,
    "AUG": 6.09,
    "SEP": 5.49,
    "OCT": 5.34,
    "NOV": 5.05,
    "DEC": 5.04
  },
  {
    "LONG": -58.31,
    "LAT": -15.77,
    "CIDADE": "Glória D'Oeste",
    "UF": "MATO GROSSO",
    "anual": 5.09,
    "JAN": 5,
    "FEB": 5.08,
    "MAR": 5.12,
    "APR": 5.17,
    "MAY": 4.65,
    "JUN": 4.85,
    "JUL": 4.97,
    "AUG": 5.55,
    "SEP": 5.26,
    "OCT": 5.21,
    "NOV": 5.15,
    "DEC": 5.13
  },
  {
    "LONG": -56.34,
    "LAT": -15.77,
    "CIDADE": "Nossa Senhora do Livramento",
    "UF": "MATO GROSSO",
    "anual": 5.24,
    "JAN": 5.04,
    "FEB": 5.19,
    "MAR": 5.24,
    "APR": 5.33,
    "MAY": 5,
    "JUN": 5.15,
    "JUL": 5.25,
    "AUG": 5.97,
    "SEP": 5.29,
    "OCT": 5.08,
    "NOV": 5.16,
    "DEC": 5.16
  },
  {
    "LONG": -54.92,
    "LAT": -15.81,
    "CIDADE": "Dom Aquino",
    "UF": "MATO GROSSO",
    "anual": 5.27,
    "JAN": 4.9,
    "FEB": 5.06,
    "MAR": 5.15,
    "APR": 5.53,
    "MAY": 5.35,
    "JUN": 5.42,
    "JUL": 5.44,
    "AUG": 6.23,
    "SEP": 5.31,
    "OCT": 4.95,
    "NOV": 4.95,
    "DEC": 4.98
  },
  {
    "LONG": -54.4,
    "LAT": -15.83,
    "CIDADE": "Poxoréu",
    "UF": "MATO GROSSO",
    "anual": 5.31,
    "JAN": 4.91,
    "FEB": 5.1,
    "MAR": 5.2,
    "APR": 5.49,
    "MAY": 5.42,
    "JUN": 5.42,
    "JUL": 5.53,
    "AUG": 6.3,
    "SEP": 5.38,
    "OCT": 5.04,
    "NOV": 5.03,
    "DEC": 4.88
  },
  {
    "LONG": -58.09,
    "LAT": -15.67,
    "CIDADE": "Mirassol d'Oeste",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.98,
    "FEB": 4.99,
    "MAR": 5.08,
    "APR": 5.13,
    "MAY": 4.71,
    "JUN": 4.94,
    "JUL": 4.98,
    "AUG": 5.59,
    "SEP": 5.22,
    "OCT": 5.1,
    "NOV": 5.01,
    "DEC": 5.01
  },
  {
    "LONG": -52.76,
    "LAT": -15.71,
    "CIDADE": "General Carneiro",
    "UF": "MATO GROSSO",
    "anual": 5.44,
    "JAN": 5,
    "FEB": 5.34,
    "MAR": 5.34,
    "APR": 5.8,
    "MAY": 5.72,
    "JUN": 5.44,
    "JUL": 5.59,
    "AUG": 6.12,
    "SEP": 5.59,
    "OCT": 5.28,
    "NOV": 5.01,
    "DEC": 5.01
  },
  {
    "LONG": -51.84,
    "LAT": -15.72,
    "CIDADE": "Araguaiana",
    "UF": "MATO GROSSO",
    "anual": 5.42,
    "JAN": 5.14,
    "FEB": 5.49,
    "MAR": 5.33,
    "APR": 5.8,
    "MAY": 5.63,
    "JUN": 5.3,
    "JUL": 5.48,
    "AUG": 5.82,
    "SEP": 5.56,
    "OCT": 5.37,
    "NOV": 5.03,
    "DEC": 5.07
  },
  {
    "LONG": -58.18,
    "LAT": -1.56,
    "CIDADE": "São José dos Quatro Marcos",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.94,
    "FEB": 5.01,
    "MAR": 5.06,
    "APR": 5.12,
    "MAY": 4.69,
    "JUN": 4.95,
    "JUL": 4.97,
    "AUG": 5.58,
    "SEP": 5.23,
    "OCT": 5.08,
    "NOV": 5.02,
    "DEC": 5.02
  },
  {
    "LONG": -57.91,
    "LAT": -15.61,
    "CIDADE": "Curvelândia",
    "UF": "MATO GROSSO",
    "anual": 5.08,
    "JAN": 4.96,
    "FEB": 4.98,
    "MAR": 5.09,
    "APR": 5.16,
    "MAY": 4.77,
    "JUN": 4.98,
    "JUL": 5.03,
    "AUG": 5.64,
    "SEP": 5.23,
    "OCT": 5.05,
    "NOV": 5.02,
    "DEC": 5.01
  },
  {
    "LONG": -56.13,
    "LAT": -15.65,
    "CIDADE": "Várzea Grande",
    "UF": "MATO GROSSO",
    "anual": 5.27,
    "JAN": 5.06,
    "FEB": 5.16,
    "MAR": 5.27,
    "APR": 5.35,
    "MAY": 5.07,
    "JUN": 5.15,
    "JUL": 5.27,
    "AUG": 6,
    "SEP": 5.31,
    "OCT": 5.17,
    "NOV": 5.18,
    "DEC": 5.23
  },
  {
    "LONG": -56.1,
    "LAT": -15.6,
    "CIDADE": "Cuiabá",
    "UF": "MATO GROSSO",
    "anual": 5.24,
    "JAN": 5.02,
    "FEB": 5.12,
    "MAR": 5.24,
    "APR": 5.31,
    "MAY": 5.04,
    "JUN": 5.14,
    "JUL": 5.26,
    "AUG": 5.99,
    "SEP": 5.32,
    "OCT": 5.17,
    "NOV": 5.16,
    "DEC": 5.17
  },
  {
    "LONG": -54.3,
    "LAT": -15.55,
    "CIDADE": "Primavera do Leste",
    "UF": "MATO GROSSO",
    "anual": 5.25,
    "JAN": 4.98,
    "FEB": 5.05,
    "MAR": 5.06,
    "APR": 5.44,
    "MAY": 5.41,
    "JUN": 5.32,
    "JUL": 5.47,
    "AUG": 6.07,
    "SEP": 5.32,
    "OCT": 4.99,
    "NOV": 4.93,
    "DEC": 4.91
  },
  {
    "LONG": -58.58,
    "LAT": -15.5,
    "CIDADE": "Indiavaí",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.84,
    "FEB": 4.89,
    "MAR": 4.98,
    "APR": 5.07,
    "MAY": 4.62,
    "JUN": 4.9,
    "JUL": 4.92,
    "AUG": 5.59,
    "SEP": 5.16,
    "OCT": 5.1,
    "NOV": 5.07,
    "DEC": 4.91
  },
  {
    "LONG": -58.34,
    "LAT": -15.46,
    "CIDADE": "Araputanga",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.89,
    "FEB": 4.9,
    "MAR": 4.99,
    "APR": 5.08,
    "MAY": 4.63,
    "JUN": 4.92,
    "JUL": 4.97,
    "AUG": 5.65,
    "SEP": 5.19,
    "OCT": 5.03,
    "NOV": 5,
    "DEC": 4.93
  },
  {
    "LONG": -55.75,
    "LAT": -15.46,
    "CIDADE": "Chapada dos Guimarães",
    "UF": "MATO GROSSO",
    "anual": 5.11,
    "JAN": 4.73,
    "FEB": 4.82,
    "MAR": 4.91,
    "APR": 5.15,
    "MAY": 5.07,
    "JUN": 5.26,
    "JUL": 5.39,
    "AUG": 6.06,
    "SEP": 5.26,
    "OCT": 4.95,
    "NOV": 4.88,
    "DEC": 4.87
  },
  {
    "LONG": -5.52,
    "LAT": -15.55,
    "CIDADE": "Campo Verde",
    "UF": "MATO GROSSO",
    "anual": 5.2,
    "JAN": 4.9,
    "FEB": 4.97,
    "MAR": 4.98,
    "APR": 5.34,
    "MAY": 5.32,
    "JUN": 5.33,
    "JUL": 5.43,
    "AUG": 6,
    "SEP": 5.29,
    "OCT": 4.93,
    "NOV": 4.99,
    "DEC": 4.93
  },
  {
    "LONG": -58.74,
    "LAT": -1.54,
    "CIDADE": "Figueirópolis D'Oeste",
    "UF": "MATO GROSSO",
    "anual": 4.99,
    "JAN": 4.8,
    "FEB": 4.84,
    "MAR": 4.91,
    "APR": 5.02,
    "MAY": 4.6,
    "JUN": 4.92,
    "JUL": 4.98,
    "AUG": 5.59,
    "SEP": 5.19,
    "OCT": 5.07,
    "NOV": 5.05,
    "DEC": 4.91
  },
  {
    "LONG": -59.06,
    "LAT": -15.3,
    "CIDADE": "Vale de São Domingos",
    "UF": "MATO GROSSO",
    "anual": 5.05,
    "JAN": 4.86,
    "FEB": 4.84,
    "MAR": 4.87,
    "APR": 5.1,
    "MAY": 4.7,
    "JUN": 5.01,
    "JUL": 5.04,
    "AUG": 5.56,
    "SEP": 5.26,
    "OCT": 5.19,
    "NOV": 5.13,
    "DEC": 5.02
  },
  {
    "LONG": -58.87,
    "LAT": -15.34,
    "CIDADE": "Jauru",
    "UF": "MATO GROSSO",
    "anual": 5,
    "JAN": 4.79,
    "FEB": 4.79,
    "MAR": 4.86,
    "APR": 5,
    "MAY": 4.62,
    "JUN": 4.94,
    "JUL": 4.98,
    "AUG": 5.65,
    "SEP": 5.19,
    "OCT": 5.08,
    "NOV": 5.1,
    "DEC": 4.94
  },
  {
    "LONG": -58,
    "LAT": -15.32,
    "CIDADE": "Lambari D'Oeste",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.72,
    "FEB": 4.85,
    "MAR": 4.96,
    "APR": 5.1,
    "MAY": 4.78,
    "JUN": 5.03,
    "JUL": 5.07,
    "AUG": 5.72,
    "SEP": 5.17,
    "OCT": 4.96,
    "NOV": 4.93,
    "DEC": 4.79
  },
  {
    "LONG": -57.2,
    "LAT": -15.33,
    "CIDADE": "Porto Estrela",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 4.96,
    "FEB": 5.06,
    "MAR": 5.2,
    "APR": 5.25,
    "MAY": 5.01,
    "JUN": 5.15,
    "JUL": 5.23,
    "AUG": 5.65,
    "SEP": 5.3,
    "OCT": 5.19,
    "NOV": 5.14,
    "DEC": 5.11
  },
  {
    "LONG": -59.34,
    "LAT": -15.23,
    "CIDADE": "Pontes e Lacerda",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.8,
    "FEB": 4.82,
    "MAR": 4.93,
    "APR": 5.16,
    "MAY": 4.78,
    "JUN": 5.02,
    "JUL": 5.1,
    "AUG": 5.55,
    "SEP": 5.25,
    "OCT": 5.17,
    "NOV": 5.14,
    "DEC": 4.98
  },
  {
    "LONG": -58.13,
    "LAT": -15.25,
    "CIDADE": "Rio Branco",
    "UF": "MATO GROSSO",
    "anual": 4.92,
    "JAN": 4.63,
    "FEB": 4.71,
    "MAR": 4.78,
    "APR": 5,
    "MAY": 4.68,
    "JUN": 4.99,
    "JUL": 5.05,
    "AUG": 5.7,
    "SEP": 5.09,
    "OCT": 4.87,
    "NOV": 4.86,
    "DEC": 4.67
  },
  {
    "LONG": -56.49,
    "LAT": -15.24,
    "CIDADE": "Jangada",
    "UF": "MATO GROSSO",
    "anual": 5.26,
    "JAN": 4.97,
    "FEB": 5.16,
    "MAR": 5.24,
    "APR": 5.34,
    "MAY": 5.09,
    "JUN": 5.25,
    "JUL": 5.33,
    "AUG": 5.97,
    "SEP": 5.3,
    "OCT": 5.14,
    "NOV": 5.18,
    "DEC": 5.16
  },
  {
    "LONG": -56.36,
    "LAT": -15.19,
    "CIDADE": "Acorizal",
    "UF": "MATO GROSSO",
    "anual": 5.27,
    "JAN": 5,
    "FEB": 5.16,
    "MAR": 5.24,
    "APR": 5.35,
    "MAY": 5.09,
    "JUN": 5.23,
    "JUL": 5.32,
    "AUG": 6,
    "SEP": 5.29,
    "OCT": 5.16,
    "NOV": 5.21,
    "DEC": 5.19
  },
  {
    "LONG": -58.38,
    "LAT": -15.12,
    "CIDADE": "Reserva do Cabaçal",
    "UF": "MATO GROSSO",
    "anual": 4.85,
    "JAN": 4.52,
    "FEB": 4.59,
    "MAR": 4.66,
    "APR": 4.87,
    "MAY": 4.59,
    "JUN": 4.96,
    "JUL": 5.02,
    "AUG": 5.71,
    "SEP": 5.04,
    "OCT": 4.82,
    "NOV": 4.79,
    "DEC": 4.65
  },
  {
    "LONG": -58.13,
    "LAT": -15.13,
    "CIDADE": "Salto do Céu",
    "UF": "MATO GROSSO",
    "anual": 4.92,
    "JAN": 4.55,
    "FEB": 4.67,
    "MAR": 4.78,
    "APR": 5.02,
    "MAY": 4.73,
    "JUN": 5.02,
    "JUL": 5.08,
    "AUG": 5.71,
    "SEP": 5.09,
    "OCT": 4.88,
    "NOV": 4.85,
    "DEC": 4.65
  },
  {
    "LONG": -57.19,
    "LAT": -15.07,
    "CIDADE": "Barra do Bugres",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 4.95,
    "FEB": 5.07,
    "MAR": 5.15,
    "APR": 5.29,
    "MAY": 4.99,
    "JUN": 5.17,
    "JUL": 5.22,
    "AUG": 5.75,
    "SEP": 5.29,
    "OCT": 5.18,
    "NOV": 5.13,
    "DEC": 5.06
  },
  {
    "LONG": -59.95,
    "LAT": -15,
    "CIDADE": "Vila Bela da Santíssima Trindade",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.73,
    "FEB": 4.72,
    "MAR": 4.96,
    "APR": 5.14,
    "MAY": 4.73,
    "JUN": 5,
    "JUL": 5.1,
    "AUG": 5.62,
    "SEP": 5.19,
    "OCT": 5.13,
    "NOV": 5.01,
    "DEC": 4.81
  },
  {
    "LONG": -54.97,
    "LAT": -14.93,
    "CIDADE": "Nova Brasilândia",
    "UF": "MATO GROSSO",
    "anual": 5.32,
    "JAN": 4.99,
    "FEB": 5.08,
    "MAR": 5.22,
    "APR": 5.55,
    "MAY": 5.45,
    "JUN": 5.45,
    "JUL": 5.46,
    "AUG": 6.07,
    "SEP": 5.44,
    "OCT": 5.13,
    "NOV": 5,
    "DEC": 4.99
  },
  {
    "LONG": -53.03,
    "LAT": -14.91,
    "CIDADE": "Novo São Joaquim",
    "UF": "MATO GROSSO",
    "anual": 5.44,
    "JAN": 5.03,
    "FEB": 5.31,
    "MAR": 5.36,
    "APR": 5.82,
    "MAY": 5.67,
    "JUN": 5.48,
    "JUL": 5.69,
    "AUG": 6.03,
    "SEP": 5.54,
    "OCT": 5.3,
    "NOV": 4.98,
    "DEC": 5.03
  },
  {
    "LONG": -57.28,
    "LAT": -14.78,
    "CIDADE": "Nova Olímpia",
    "UF": "MATO GROSSO",
    "anual": 5.09,
    "JAN": 4.83,
    "FEB": 4.96,
    "MAR": 5.02,
    "APR": 5.13,
    "MAY": 4.9,
    "JUN": 5.06,
    "JUL": 5.18,
    "AUG": 5.7,
    "SEP": 5.15,
    "OCT": 5.09,
    "NOV": 5.05,
    "DEC": 4.96
  },
  {
    "LONG": -5.64,
    "LAT": -14.83,
    "CIDADE": "Rosário Oeste",
    "UF": "MATO GROSSO",
    "anual": 5.18,
    "JAN": 4.76,
    "FEB": 4.99,
    "MAR": 5.07,
    "APR": 5.2,
    "MAY": 5.07,
    "JUN": 5.19,
    "JUL": 5.35,
    "AUG": 5.97,
    "SEP": 5.31,
    "OCT": 5.08,
    "NOV": 5.09,
    "DEC": 5.05
  },
  {
    "LONG": -53.61,
    "LAT": -14.81,
    "CIDADE": "Santo Antônio do Leste",
    "UF": "MATO GROSSO",
    "anual": 5.34,
    "JAN": 4.97,
    "FEB": 5.24,
    "MAR": 5.21,
    "APR": 5.55,
    "MAY": 5.49,
    "JUN": 5.43,
    "JUL": 5.62,
    "AUG": 6.04,
    "SEP": 5.44,
    "OCT": 5.16,
    "NOV": 4.98,
    "DEC": 4.96
  },
  {
    "LONG": -57.05,
    "LAT": -1.47,
    "CIDADE": "Denise",
    "UF": "MATO GROSSO",
    "anual": 5.1,
    "JAN": 4.8,
    "FEB": 5,
    "MAR": 5.05,
    "APR": 5.13,
    "MAY": 4.93,
    "JUN": 5.13,
    "JUL": 5.24,
    "AUG": 5.76,
    "SEP": 5.15,
    "OCT": 5.08,
    "NOV": 4.99,
    "DEC": 4.95
  },
  {
    "LONG": -56.33,
    "LAT": -14.72,
    "CIDADE": "Nobres",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 4.81,
    "FEB": 4.98,
    "MAR": 5.09,
    "APR": 5.18,
    "MAY": 5.09,
    "JUN": 5.23,
    "JUL": 5.41,
    "AUG": 6.01,
    "SEP": 5.34,
    "OCT": 5.09,
    "NOV": 5.06,
    "DEC": 5.03
  },
  {
    "LONG": -54.78,
    "LAT": -14.66,
    "CIDADE": "Planalto da Serra",
    "UF": "MATO GROSSO",
    "anual": 5.32,
    "JAN": 4.91,
    "FEB": 5.1,
    "MAR": 5.2,
    "APR": 5.6,
    "MAY": 5.46,
    "JUN": 5.47,
    "JUL": 5.53,
    "AUG": 6.08,
    "SEP": 5.38,
    "OCT": 5.17,
    "NOV": 5.02,
    "DEC": 4.93
  },
  {
    "LONG": -52.35,
    "LAT": -14.67,
    "CIDADE": "Nova Xavantina",
    "UF": "MATO GROSSO",
    "anual": 5.45,
    "JAN": 5.06,
    "FEB": 5.41,
    "MAR": 5.39,
    "APR": 5.72,
    "MAY": 5.62,
    "JUN": 5.39,
    "JUL": 5.62,
    "AUG": 6.02,
    "SEP": 5.66,
    "OCT": 5.38,
    "NOV": 5.06,
    "DEC": 5.02
  },
  {
    "LONG": -57.49,
    "LAT": -14.62,
    "CIDADE": "Tangará da Serra",
    "UF": "MATO GROSSO",
    "anual": 5.08,
    "JAN": 4.73,
    "FEB": 4.9,
    "MAR": 5.02,
    "APR": 5.11,
    "MAY": 4.91,
    "JUN": 5.1,
    "JUL": 5.23,
    "AUG": 5.8,
    "SEP": 5.22,
    "OCT": 5.08,
    "NOV": 4.94,
    "DEC": 4.94
  },
  {
    "LONG": -59.59,
    "LAT": -14.48,
    "CIDADE": "Nova Lacerda",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.66,
    "FEB": 4.66,
    "MAR": 4.79,
    "APR": 5.01,
    "MAY": 4.82,
    "JUN": 5.02,
    "JUL": 5.19,
    "AUG": 5.58,
    "SEP": 5.18,
    "OCT": 5.13,
    "NOV": 5.07,
    "DEC": 4.94
  },
  {
    "LONG": -59.54,
    "LAT": -14.54,
    "CIDADE": "Conquista D'Oeste",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.66,
    "FEB": 4.66,
    "MAR": 4.79,
    "APR": 5.01,
    "MAY": 4.82,
    "JUN": 5.02,
    "JUL": 5.19,
    "AUG": 5.58,
    "SEP": 5.18,
    "OCT": 5.13,
    "NOV": 5.07,
    "DEC": 4.94
  },
  {
    "LONG": -57.01,
    "LAT": -14.49,
    "CIDADE": "Santo Afonso",
    "UF": "MATO GROSSO",
    "anual": 5.12,
    "JAN": 4.75,
    "FEB": 4.95,
    "MAR": 5.03,
    "APR": 5.17,
    "MAY": 4.98,
    "JUN": 5.15,
    "JUL": 5.28,
    "AUG": 5.83,
    "SEP": 5.27,
    "OCT": 5.1,
    "NOV": 4.99,
    "DEC": 4.97
  },
  {
    "LONG": -56.8,
    "LAT": -14.45,
    "CIDADE": "Nortelândia",
    "UF": "MATO GROSSO",
    "anual": 5.16,
    "JAN": 4.88,
    "FEB": 5.01,
    "MAR": 5.02,
    "APR": 5.17,
    "MAY": 5.08,
    "JUN": 5.19,
    "JUL": 5.33,
    "AUG": 5.88,
    "SEP": 5.26,
    "OCT": 5.11,
    "NOV": 5,
    "DEC": 4.94
  },
  {
    "LONG": -56.84,
    "LAT": -14.46,
    "CIDADE": "Arenápolis",
    "UF": "MATO GROSSO",
    "anual": 5.16,
    "JAN": 4.88,
    "FEB": 5.01,
    "MAR": 5.02,
    "APR": 5.17,
    "MAY": 5.08,
    "JUN": 5.19,
    "JUL": 5.33,
    "AUG": 5.88,
    "SEP": 5.26,
    "OCT": 5.11,
    "NOV": 5,
    "DEC": 4.94
  },
  {
    "LONG": -56.48,
    "LAT": -14.51,
    "CIDADE": "Alto Paraguai",
    "UF": "MATO GROSSO",
    "anual": 5.2,
    "JAN": 4.84,
    "FEB": 4.99,
    "MAR": 5.08,
    "APR": 5.24,
    "MAY": 5.1,
    "JUN": 5.26,
    "JUL": 5.41,
    "AUG": 6.03,
    "SEP": 5.31,
    "OCT": 5.1,
    "NOV": 5.04,
    "DEC": 5
  },
  {
    "LONG": -5.29,
    "LAT": -14.52,
    "CIDADE": "Campinápolis",
    "UF": "MATO GROSSO",
    "anual": 5.44,
    "JAN": 5.02,
    "FEB": 5.32,
    "MAR": 5.34,
    "APR": 5.71,
    "MAY": 5.62,
    "JUN": 5.53,
    "JUL": 5.79,
    "AUG": 6.14,
    "SEP": 5.54,
    "OCT": 5.26,
    "NOV": 4.98,
    "DEC": 4.99
  },
  {
    "LONG": -56.98,
    "LAT": -14.37,
    "CIDADE": "Nova Marilândia",
    "UF": "MATO GROSSO",
    "anual": 5.15,
    "JAN": 4.79,
    "FEB": 4.96,
    "MAR": 5.02,
    "APR": 5.19,
    "MAY": 5.05,
    "JUN": 5.21,
    "JUL": 5.34,
    "AUG": 5.97,
    "SEP": 5.28,
    "OCT": 5.09,
    "NOV": 4.97,
    "DEC": 4.94
  },
  {
    "LONG": -56.44,
    "LAT": -14.4,
    "CIDADE": "Diamantino",
    "UF": "MATO GROSSO",
    "anual": 5.18,
    "JAN": 4.85,
    "FEB": 4.95,
    "MAR": 4.99,
    "APR": 5.19,
    "MAY": 5.14,
    "JUN": 5.24,
    "JUL": 5.42,
    "AUG": 6.03,
    "SEP": 5.31,
    "OCT": 5.1,
    "NOV": 4.94,
    "DEC": 4.98
  },
  {
    "LONG": -54.05,
    "LAT": -14.43,
    "CIDADE": "Paranatinga",
    "UF": "MATO GROSSO",
    "anual": 5.31,
    "JAN": 4.91,
    "FEB": 5.13,
    "MAR": 5.13,
    "APR": 5.56,
    "MAY": 5.43,
    "JUN": 5.44,
    "JUL": 5.6,
    "AUG": 6.05,
    "SEP": 5.4,
    "OCT": 5.22,
    "NOV": 4.94,
    "DEC": 4.93
  },
  {
    "LONG": -51,
    "LAT": -1.44,
    "CIDADE": "Cocalinho",
    "UF": "MATO GROSSO",
    "anual": 5.41,
    "JAN": 5.01,
    "FEB": 5.43,
    "MAR": 5.36,
    "APR": 5.68,
    "MAY": 5.52,
    "JUN": 5.34,
    "JUL": 5.63,
    "AUG": 5.98,
    "SEP": 5.67,
    "OCT": 5.37,
    "NOV": 4.99,
    "DEC": 4.97
  },
  {
    "LONG": -52.16,
    "LAT": -14.05,
    "CIDADE": "Água Boa",
    "UF": "MATO GROSSO",
    "anual": 5.4,
    "JAN": 4.87,
    "FEB": 5.32,
    "MAR": 5.24,
    "APR": 5.62,
    "MAY": 5.63,
    "JUN": 5.45,
    "JUL": 5.78,
    "AUG": 6.07,
    "SEP": 5.61,
    "OCT": 5.32,
    "NOV": 4.95,
    "DEC": 4.94
  },
  {
    "LONG": -51.79,
    "LAT": -1.4,
    "CIDADE": "Nova Nazaré",
    "UF": "MATO GROSSO",
    "anual": 5.45,
    "JAN": 5,
    "FEB": 5.38,
    "MAR": 5.34,
    "APR": 5.68,
    "MAY": 5.64,
    "JUN": 5.48,
    "JUL": 5.79,
    "AUG": 6.17,
    "SEP": 5.67,
    "OCT": 5.35,
    "NOV": 4.98,
    "DEC": 4.94
  },
  {
    "LONG": -56.08,
    "LAT": -13.82,
    "CIDADE": "Nova Mutum",
    "UF": "MATO GROSSO",
    "anual": 5.15,
    "JAN": 4.83,
    "FEB": 4.88,
    "MAR": 4.97,
    "APR": 5.17,
    "MAY": 5.13,
    "JUN": 5.22,
    "JUL": 5.46,
    "AUG": 5.84,
    "SEP": 5.32,
    "OCT": 5.13,
    "NOV": 4.95,
    "DEC": 4.95
  },
  {
    "LONG": -55.27,
    "LAT": -13.82,
    "CIDADE": "Santa Rita do Trivelato",
    "UF": "MATO GROSSO",
    "anual": 5.21,
    "JAN": 4.82,
    "FEB": 4.99,
    "MAR": 5.02,
    "APR": 5.38,
    "MAY": 5.37,
    "JUN": 5.38,
    "JUL": 5.49,
    "AUG": 5.88,
    "SEP": 5.28,
    "OCT": 5.15,
    "NOV": 4.91,
    "DEC": 4.89
  },
  {
    "LONG": -59.79,
    "LAT": -13.66,
    "CIDADE": "Comodoro",
    "UF": "MATO GROSSO",
    "anual": 4.79,
    "JAN": 4.39,
    "FEB": 4.39,
    "MAR": 4.52,
    "APR": 4.76,
    "MAY": 4.69,
    "JUN": 4.9,
    "JUL": 5.16,
    "AUG": 5.48,
    "SEP": 5.01,
    "OCT": 4.91,
    "NOV": 4.69,
    "DEC": 4.61
  },
  {
    "LONG": -59.26,
    "LAT": -13.69,
    "CIDADE": "Campos de Júlio",
    "UF": "MATO GROSSO",
    "anual": 4.86,
    "JAN": 4.54,
    "FEB": 4.56,
    "MAR": 4.71,
    "APR": 4.96,
    "MAY": 4.76,
    "JUN": 4.92,
    "JUL": 5.01,
    "AUG": 5.41,
    "SEP": 4.98,
    "OCT": 4.9,
    "NOV": 4.78,
    "DEC": 4.8
  },
  {
    "LONG": -57.89,
    "LAT": -13.66,
    "CIDADE": "Campo Novo do Parecis",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.66,
    "FEB": 4.8,
    "MAR": 4.79,
    "APR": 5.04,
    "MAY": 4.96,
    "JUN": 5.1,
    "JUL": 5.18,
    "AUG": 5.7,
    "SEP": 5.13,
    "OCT": 4.98,
    "NOV": 4.86,
    "DEC": 4.91
  },
  {
    "LONG": "#VALOR!",
    "LAT": -13.55,
    "CIDADE": "Canarana",
    "UF": "MATO GROSSO",
    "anual": 5.36,
    "JAN": 4.94,
    "FEB": 5.21,
    "MAR": 5.14,
    "APR": 5.5,
    "MAY": 5.52,
    "JUN": 5.46,
    "JUL": 5.85,
    "AUG": 6.19,
    "SEP": 5.52,
    "OCT": 5.19,
    "NOV": 4.85,
    "DEC": 4.95
  },
  {
    "LONG": -58.82,
    "LAT": -13.55,
    "CIDADE": "Sapezal",
    "UF": "MATO GROSSO",
    "anual": 4.91,
    "JAN": 4.59,
    "FEB": 4.62,
    "MAR": 4.85,
    "APR": 4.98,
    "MAY": 4.77,
    "JUN": 4.96,
    "JUL": 5.05,
    "AUG": 5.46,
    "SEP": 5,
    "OCT": 4.92,
    "NOV": 4.85,
    "DEC": 4.82
  },
  {
    "LONG": -5.67,
    "LAT": -13.45,
    "CIDADE": "São José do Rio Claro",
    "UF": "MATO GROSSO",
    "anual": 5.17,
    "JAN": 4.79,
    "FEB": 4.83,
    "MAR": 5.03,
    "APR": 5.21,
    "MAY": 5.26,
    "JUN": 5.29,
    "JUL": 5.4,
    "AUG": 5.79,
    "SEP": 5.36,
    "OCT": 5.28,
    "NOV": 4.96,
    "DEC": 4.87
  },
  {
    "LONG": -53.26,
    "LAT": -13.18,
    "CIDADE": "Gaúcha do Norte",
    "UF": "MATO GROSSO",
    "anual": 5.29,
    "JAN": 4.87,
    "FEB": 5.17,
    "MAR": 5.15,
    "APR": 5.5,
    "MAY": 5.46,
    "JUN": 5.38,
    "JUL": 5.72,
    "AUG": 6.01,
    "SEP": 5.39,
    "OCT": 5.14,
    "NOV": 4.87,
    "DEC": 4.82
  },
  {
    "LONG": -55.91,
    "LAT": -13.07,
    "CIDADE": "Lucas do Rio Verde",
    "UF": "MATO GROSSO",
    "anual": 5.1,
    "JAN": 4.82,
    "FEB": 4.82,
    "MAR": 4.99,
    "APR": 5.27,
    "MAY": 5.2,
    "JUN": 5.11,
    "JUL": 5.24,
    "AUG": 5.58,
    "SEP": 5.25,
    "OCT": 5.13,
    "NOV": 4.92,
    "DEC": 4.89
  },
  {
    "LONG": -57.09,
    "LAT": -13.03,
    "CIDADE": "Nova Maringá",
    "UF": "MATO GROSSO",
    "anual": 5.1,
    "JAN": 4.76,
    "FEB": 4.81,
    "MAR": 4.93,
    "APR": 5.13,
    "MAY": 5.15,
    "JUN": 5.26,
    "JUL": 5.35,
    "AUG": 5.61,
    "SEP": 5.3,
    "OCT": 5.18,
    "NOV": 4.86,
    "DEC": 4.86
  },
  {
    "LONG": -55.25,
    "LAT": -13.04,
    "CIDADE": "Nova Ubiratã",
    "UF": "MATO GROSSO",
    "anual": 5.16,
    "JAN": 4.8,
    "FEB": 4.95,
    "MAR": 5.03,
    "APR": 5.32,
    "MAY": 5.31,
    "JUN": 5.18,
    "JUL": 5.44,
    "AUG": 5.72,
    "SEP": 5.28,
    "OCT": 5.14,
    "NOV": 4.9,
    "DEC": 4.88
  },
  {
    "LONG": -51.82,
    "LAT": -12.94,
    "CIDADE": "Ribeirão Cascalheira",
    "UF": "MATO GROSSO",
    "anual": 5.31,
    "JAN": 4.75,
    "FEB": 5.12,
    "MAR": 5.1,
    "APR": 5.37,
    "MAY": 5.51,
    "JUN": 5.5,
    "JUL": 5.86,
    "AUG": 6.14,
    "SEP": 5.55,
    "OCT": 5.15,
    "NOV": 4.83,
    "DEC": 4.85
  },
  {
    "LONG": -56.51,
    "LAT": -12.74,
    "CIDADE": "Tapurah",
    "UF": "MATO GROSSO",
    "anual": 5.11,
    "JAN": 4.79,
    "FEB": 4.83,
    "MAR": 4.95,
    "APR": 5.09,
    "MAY": 5.16,
    "JUN": 5.28,
    "JUL": 5.42,
    "AUG": 5.64,
    "SEP": 5.27,
    "OCT": 5.22,
    "NOV": 4.85,
    "DEC": 4.85
  },
  {
    "LONG": -52.21,
    "LAT": -12.59,
    "CIDADE": "Querência",
    "UF": "MATO GROSSO",
    "anual": 5.24,
    "JAN": 4.82,
    "FEB": 5.19,
    "MAR": 4.98,
    "APR": 5.25,
    "MAY": 5.45,
    "JUN": 5.33,
    "JUL": 5.78,
    "AUG": 5.86,
    "SEP": 5.4,
    "OCT": 5.16,
    "NOV": 4.84,
    "DEC": 4.85
  },
  {
    "LONG": -55.72,
    "LAT": -1.25,
    "CIDADE": "Sorriso",
    "UF": "MATO GROSSO",
    "anual": 5.13,
    "JAN": 4.83,
    "FEB": 4.87,
    "MAR": 5.02,
    "APR": 5.21,
    "MAY": 5.26,
    "JUN": 5.23,
    "JUL": 5.45,
    "AUG": 5.62,
    "SEP": 5.22,
    "OCT": 5.17,
    "NOV": 4.85,
    "DEC": 4.86
  },
  {
    "LONG": -54.94,
    "LAT": -12.38,
    "CIDADE": "Feliz Natal",
    "UF": "MATO GROSSO",
    "anual": 5.18,
    "JAN": 4.79,
    "FEB": 4.97,
    "MAR": 5.01,
    "APR": 5.27,
    "MAY": 5.44,
    "JUN": 5.31,
    "JUL": 5.56,
    "AUG": 5.7,
    "SEP": 5.27,
    "OCT": 5.15,
    "NOV": 4.83,
    "DEC": 4.85
  },
  {
    "LONG": -55.3,
    "LAT": -12.29,
    "CIDADE": "Vera",
    "UF": "MATO GROSSO",
    "anual": 5.14,
    "JAN": 4.79,
    "FEB": 4.9,
    "MAR": 4.97,
    "APR": 5.19,
    "MAY": 5.32,
    "JUN": 5.28,
    "JUL": 5.53,
    "AUG": 5.68,
    "SEP": 5.21,
    "OCT": 5.13,
    "NOV": 4.84,
    "DEC": 4.89
  },
  {
    "LONG": -50.97,
    "LAT": -12.29,
    "CIDADE": "Novo Santo Antônio",
    "UF": "MATO GROSSO",
    "anual": 5.38,
    "JAN": 4.81,
    "FEB": 5.2,
    "MAR": 5.22,
    "APR": 5.4,
    "MAY": 5.59,
    "JUN": 5.39,
    "JUL": 5.83,
    "AUG": 6.31,
    "SEP": 5.72,
    "OCT": 5.26,
    "NOV": 4.94,
    "DEC": 4.88
  },
  {
    "LONG": -56.64,
    "LAT": -12.24,
    "CIDADE": "Itanhangá",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.73,
    "FEB": 4.71,
    "MAR": 4.84,
    "APR": 5.01,
    "MAY": 5.12,
    "JUN": 5.29,
    "JUL": 5.47,
    "AUG": 5.68,
    "SEP": 5.21,
    "OCT": 5.16,
    "NOV": 4.77,
    "DEC": 4.75
  },
  {
    "LONG": -56.15,
    "LAT": -12.24,
    "CIDADE": "Ipiranga do Norte",
    "UF": "MATO GROSSO",
    "anual": 5.05,
    "JAN": 4.78,
    "FEB": 4.82,
    "MAR": 4.88,
    "APR": 5.06,
    "MAY": 5.14,
    "JUN": 5.12,
    "JUL": 5.26,
    "AUG": 5.6,
    "SEP": 5.17,
    "OCT": 5.1,
    "NOV": 4.77,
    "DEC": 4.84
  },
  {
    "LONG": -51.5,
    "LAT": -1.22,
    "CIDADE": "Bom Jesus do Araguaia",
    "UF": "MATO GROSSO",
    "anual": 5.26,
    "JAN": 4.79,
    "FEB": 5.03,
    "MAR": 5,
    "APR": 5.23,
    "MAY": 5.52,
    "JUN": 5.47,
    "JUL": 5.74,
    "AUG": 6.03,
    "SEP": 5.47,
    "OCT": 5.15,
    "NOV": 4.91,
    "DEC": 4.84
  },
  {
    "LONG": -58,
    "LAT": -12.12,
    "CIDADE": "Brasnorte",
    "UF": "MATO GROSSO",
    "anual": 4.98,
    "JAN": 4.58,
    "FEB": 4.68,
    "MAR": 4.84,
    "APR": 4.98,
    "MAY": 4.97,
    "JUN": 5.09,
    "JUL": 5.19,
    "AUG": 5.57,
    "SEP": 5.17,
    "OCT": 5.12,
    "NOV": 4.79,
    "DEC": 4.79
  },
  {
    "LONG": -51.4,
    "LAT": -12.09,
    "CIDADE": "Serra Nova Dourada",
    "UF": "MATO GROSSO",
    "anual": 5.27,
    "JAN": 4.74,
    "FEB": 5.01,
    "MAR": 5.01,
    "APR": 5.21,
    "MAY": 5.5,
    "JUN": 5.46,
    "JUL": 5.76,
    "AUG": 6.18,
    "SEP": 5.49,
    "OCT": 5.15,
    "NOV": 4.87,
    "DEC": 4.82
  },
  {
    "LONG": -55.28,
    "LAT": -11.98,
    "CIDADE": "Santa Carmem",
    "UF": "MATO GROSSO",
    "anual": 5.12,
    "JAN": 4.81,
    "FEB": 4.86,
    "MAR": 4.97,
    "APR": 5.15,
    "MAY": 5.24,
    "JUN": 5.19,
    "JUL": 5.49,
    "AUG": 5.62,
    "SEP": 5.24,
    "OCT": 5.16,
    "NOV": 4.85,
    "DEC": 4.86
  },
  {
    "LONG": -55.51,
    "LAT": -11.86,
    "CIDADE": "Sinop",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.72,
    "FEB": 4.81,
    "MAR": 4.97,
    "APR": 5.08,
    "MAY": 5.07,
    "JUN": 5.14,
    "JUL": 5.4,
    "AUG": 5.56,
    "SEP": 5.17,
    "OCT": 5.16,
    "NOV": 4.76,
    "DEC": 4.83
  },
  {
    "LONG": -51.38,
    "LAT": -11.67,
    "CIDADE": "Alto Boa Vista",
    "UF": "MATO GROSSO",
    "anual": 5.24,
    "JAN": 4.79,
    "FEB": 5.03,
    "MAR": 5.02,
    "APR": 5.09,
    "MAY": 5.39,
    "JUN": 5.38,
    "JUL": 5.6,
    "AUG": 6.02,
    "SEP": 5.54,
    "OCT": 5.14,
    "NOV": 4.89,
    "DEC": 4.93
  },
  {
    "LONG": -50.68,
    "LAT": -11.62,
    "CIDADE": "São Félix do Araguaia",
    "UF": "MATO GROSSO",
    "anual": 5.31,
    "JAN": 4.81,
    "FEB": 5.07,
    "MAR": 5.1,
    "APR": 5.18,
    "MAY": 5.45,
    "JUN": 5.49,
    "JUL": 5.85,
    "AUG": 6.17,
    "SEP": 5.6,
    "OCT": 5.16,
    "NOV": 4.89,
    "DEC": 4.9
  },
  {
    "LONG": -57.41,
    "LAT": -11.53,
    "CIDADE": "Porto dos Gaúchos",
    "UF": "MATO GROSSO",
    "anual": 4.95,
    "JAN": 4.59,
    "FEB": 4.61,
    "MAR": 4.7,
    "APR": 4.89,
    "MAY": 4.97,
    "JUN": 5.2,
    "JUL": 5.34,
    "AUG": 5.58,
    "SEP": 5.13,
    "OCT": 5.05,
    "NOV": 4.7,
    "DEC": 4.68
  },
  {
    "LONG": -54.88,
    "LAT": -1.15,
    "CIDADE": "Cláudia",
    "UF": "MATO GROSSO",
    "anual": 5.09,
    "JAN": 4.73,
    "FEB": 4.89,
    "MAR": 4.89,
    "APR": 5.12,
    "MAY": 5.2,
    "JUN": 5.19,
    "JUL": 5.54,
    "AUG": 5.66,
    "SEP": 5.22,
    "OCT": 5.08,
    "NOV": 4.83,
    "DEC": 4.75
  },
  {
    "LONG": -54.37,
    "LAT": -11.53,
    "CIDADE": "União do Sul",
    "UF": "MATO GROSSO",
    "anual": 5.13,
    "JAN": 4.72,
    "FEB": 4.89,
    "MAR": 4.87,
    "APR": 5.16,
    "MAY": 5.31,
    "JUN": 5.29,
    "JUL": 5.64,
    "AUG": 5.84,
    "SEP": 5.26,
    "OCT": 5.04,
    "NOV": 4.79,
    "DEC": 4.76
  },
  {
    "LONG": -58.77,
    "LAT": -1.14,
    "CIDADE": "Juína",
    "UF": "MATO GROSSO",
    "anual": 4.85,
    "JAN": 4.36,
    "FEB": 4.45,
    "MAR": 4.66,
    "APR": 4.74,
    "MAY": 4.75,
    "JUN": 5.06,
    "JUL": 5.19,
    "AUG": 5.59,
    "SEP": 5.1,
    "OCT": 5.05,
    "NOV": 4.64,
    "DEC": 4.62
  },
  {
    "LONG": -57.32,
    "LAT": -11.39,
    "CIDADE": "Novo Horizonte do Norte",
    "UF": "MATO GROSSO",
    "anual": 4.96,
    "JAN": 4.61,
    "FEB": 4.64,
    "MAR": 4.73,
    "APR": 4.85,
    "MAY": 4.94,
    "JUN": 5.15,
    "JUL": 5.39,
    "AUG": 5.56,
    "SEP": 5.15,
    "OCT": 5.08,
    "NOV": 4.73,
    "DEC": 4.68
  },
  {
    "LONG": -57.51,
    "LAT": -11.25,
    "CIDADE": "Juara",
    "UF": "MATO GROSSO",
    "anual": 4.94,
    "JAN": 4.5,
    "FEB": 4.61,
    "MAR": 4.71,
    "APR": 4.83,
    "MAY": 4.93,
    "JUN": 5.12,
    "JUL": 5.3,
    "AUG": 5.58,
    "SEP": 5.14,
    "OCT": 5.1,
    "NOV": 4.74,
    "DEC": 4.66
  },
  {
    "LONG": -56.82,
    "LAT": -11.31,
    "CIDADE": "Tabaporã",
    "UF": "MATO GROSSO",
    "anual": 4.95,
    "JAN": 4.54,
    "FEB": 4.67,
    "MAR": 4.71,
    "APR": 4.8,
    "MAY": 4.98,
    "JUN": 5.16,
    "JUL": 5.37,
    "AUG": 5.72,
    "SEP": 5.13,
    "OCT": 4.99,
    "NOV": 4.62,
    "DEC": 4.67
  },
  {
    "LONG": -5.07,
    "LAT": -11.22,
    "CIDADE": "Luciara",
    "UF": "MATO GROSSO",
    "anual": 5.27,
    "JAN": 4.76,
    "FEB": 5.1,
    "MAR": 5.01,
    "APR": 5.09,
    "MAY": 5.41,
    "JUN": 5.6,
    "JUL": 5.75,
    "AUG": 6.08,
    "SEP": 5.57,
    "OCT": 5.15,
    "NOV": 4.84,
    "DEC": 4.87
  },
  {
    "LONG": -58.61,
    "LAT": -11.14,
    "CIDADE": "Castanheira",
    "UF": "MATO GROSSO",
    "anual": 4.86,
    "JAN": 4.32,
    "FEB": 4.47,
    "MAR": 4.65,
    "APR": 4.77,
    "MAY": 4.77,
    "JUN": 5.07,
    "JUL": 5.18,
    "AUG": 5.59,
    "SEP": 5.13,
    "OCT": 5.06,
    "NOV": 4.66,
    "DEC": 4.64
  },
  {
    "LONG": -5.45,
    "LAT": -11.08,
    "CIDADE": "Marcelândia",
    "UF": "MATO GROSSO",
    "anual": 5.09,
    "JAN": 4.72,
    "FEB": 4.81,
    "MAR": 4.83,
    "APR": 5.07,
    "MAY": 5.19,
    "JUN": 5.18,
    "JUL": 5.64,
    "AUG": 5.89,
    "SEP": 5.21,
    "OCT": 5.02,
    "NOV": 4.77,
    "DEC": 4.7
  },
  {
    "LONG": -55.24,
    "LAT": -11.01,
    "CIDADE": "Itaúba",
    "UF": "MATO GROSSO",
    "anual": 4.99,
    "JAN": 4.63,
    "FEB": 4.66,
    "MAR": 4.73,
    "APR": 4.85,
    "MAY": 5.09,
    "JUN": 5.21,
    "JUL": 5.56,
    "AUG": 5.79,
    "SEP": 5.13,
    "OCT": 4.94,
    "NOV": 4.69,
    "DEC": 4.61
  },
  {
    "LONG": -51.83,
    "LAT": "#VALOR!",
    "CIDADE": "Canabrava do Norte",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 4.77,
    "FEB": 5.01,
    "MAR": 5,
    "APR": 5.08,
    "MAY": 5.34,
    "JUN": 5.4,
    "JUL": 5.48,
    "AUG": 6.01,
    "SEP": 5.36,
    "OCT": 5.08,
    "NOV": 4.84,
    "DEC": 4.87
  },
  {
    "LONG": -51.64,
    "LAT": -10.88,
    "CIDADE": "Porto Alegre do Norte",
    "UF": "MATO GROSSO",
    "anual": 5.13,
    "JAN": 4.69,
    "FEB": 4.98,
    "MAR": 4.95,
    "APR": 4.97,
    "MAY": 5.34,
    "JUN": 5.19,
    "JUL": 5.48,
    "AUG": 5.87,
    "SEP": 5.36,
    "OCT": 5.09,
    "NOV": 4.85,
    "DEC": 4.84
  },
  {
    "LONG": -61.47,
    "LAT": -1.08,
    "CIDADE": "Rondolândia",
    "UF": "MATO GROSSO",
    "anual": 4.66,
    "JAN": 4.15,
    "FEB": 4.13,
    "MAR": 4.4,
    "APR": 4.62,
    "MAY": 4.4,
    "JUN": 4.97,
    "JUL": 5.08,
    "AUG": 5.34,
    "SEP": 5.03,
    "OCT": 4.97,
    "NOV": 4.56,
    "DEC": 4.31
  },
  {
    "LONG": -55.46,
    "LAT": -10.81,
    "CIDADE": "Colíder",
    "UF": "MATO GROSSO",
    "anual": 5.06,
    "JAN": 4.7,
    "FEB": 4.78,
    "MAR": 4.83,
    "APR": 4.96,
    "MAY": 5.08,
    "JUN": 5.18,
    "JUL": 5.46,
    "AUG": 5.83,
    "SEP": 5.22,
    "OCT": 5.08,
    "NOV": 4.82,
    "DEC": 4.72
  },
  {
    "LONG": -55.18,
    "LAT": -1.08,
    "CIDADE": "Nova Santa Helena",
    "UF": "MATO GROSSO",
    "anual": 5.04,
    "JAN": 4.68,
    "FEB": 4.75,
    "MAR": 4.76,
    "APR": 4.91,
    "MAY": 5.15,
    "JUN": 5.19,
    "JUL": 5.5,
    "AUG": 5.86,
    "SEP": 5.2,
    "OCT": 5,
    "NOV": 4.79,
    "DEC": 4.7
  },
  {
    "LONG": -52.74,
    "LAT": -10.8,
    "CIDADE": "São José do Xingu",
    "UF": "MATO GROSSO",
    "anual": 5.13,
    "JAN": 4.67,
    "FEB": 4.91,
    "MAR": 4.85,
    "APR": 5,
    "MAY": 5.28,
    "JUN": 5.41,
    "JUL": 5.65,
    "AUG": 5.98,
    "SEP": 5.32,
    "OCT": 4.95,
    "NOV": 4.76,
    "DEC": 4.82
  },
  {
    "LONG": -55.95,
    "LAT": -10.56,
    "CIDADE": "Nova Canaã do Norte",
    "UF": "MATO GROSSO",
    "anual": 4.97,
    "JAN": 4.59,
    "FEB": 4.7,
    "MAR": 4.77,
    "APR": 4.88,
    "MAY": 5.04,
    "JUN": 5.13,
    "JUL": 5.29,
    "AUG": 5.66,
    "SEP": 5.14,
    "OCT": 5.03,
    "NOV": 4.74,
    "DEC": 4.71
  },
  {
    "LONG": -55.12,
    "LAT": -10.6,
    "CIDADE": "Terra Nova do Norte",
    "UF": "MATO GROSSO",
    "anual": 5.05,
    "JAN": 4.73,
    "FEB": 4.77,
    "MAR": 4.8,
    "APR": 4.94,
    "MAY": 5.09,
    "JUN": 5.17,
    "JUL": 5.5,
    "AUG": 5.82,
    "SEP": 5.2,
    "OCT": 5.07,
    "NOV": 4.82,
    "DEC": 4.73
  },
  {
    "LONG": -51.57,
    "LAT": -10.64,
    "CIDADE": "Confresa",
    "UF": "MATO GROSSO",
    "anual": 5.09,
    "JAN": 4.59,
    "FEB": 4.85,
    "MAR": 4.81,
    "APR": 4.86,
    "MAY": 5.3,
    "JUN": 5.39,
    "JUL": 5.51,
    "AUG": 6.02,
    "SEP": 5.32,
    "OCT": 4.96,
    "NOV": 4.74,
    "DEC": 4.73
  },
  {
    "LONG": -50.51,
    "LAT": -1.05,
    "CIDADE": "Santa Terezinha",
    "UF": "MATO GROSSO",
    "anual": 5.19,
    "JAN": 4.73,
    "FEB": 4.98,
    "MAR": 4.9,
    "APR": 5,
    "MAY": 5.33,
    "JUN": 5.47,
    "JUL": 5.73,
    "AUG": 6.08,
    "SEP": 5.51,
    "OCT": 5.05,
    "NOV": 4.79,
    "DEC": 4.77
  },
  {
    "LONG": -58.5,
    "LAT": -10.34,
    "CIDADE": "Juruena",
    "UF": "MATO GROSSO",
    "anual": 4.78,
    "JAN": 4.22,
    "FEB": 4.37,
    "MAR": 4.59,
    "APR": 4.61,
    "MAY": 4.73,
    "JUN": 5.12,
    "JUL": 5.23,
    "AUG": 5.42,
    "SEP": 5,
    "OCT": 5.03,
    "NOV": 4.53,
    "DEC": 4.46
  },
  {
    "LONG": -55.41,
    "LAT": -10.31,
    "CIDADE": "Nova Guarita",
    "UF": "MATO GROSSO",
    "anual": 5.01,
    "JAN": 4.64,
    "FEB": 4.74,
    "MAR": 4.83,
    "APR": 4.92,
    "MAY": 5.08,
    "JUN": 5.09,
    "JUL": 5.36,
    "AUG": 5.63,
    "SEP": 5.23,
    "OCT": 5.14,
    "NOV": 4.78,
    "DEC": 4.73
  },
  {
    "LONG": -59.46,
    "LAT": -10.17,
    "CIDADE": "Aripuanã",
    "UF": "MATO GROSSO",
    "anual": 4.74,
    "JAN": 4.17,
    "FEB": 4.3,
    "MAR": 4.59,
    "APR": 4.58,
    "MAY": 4.6,
    "JUN": 5.04,
    "JUL": 5.05,
    "AUG": 5.37,
    "SEP": 5.11,
    "OCT": 4.96,
    "NOV": 4.62,
    "DEC": 4.47
  },
  {
    "LONG": -54.95,
    "LAT": -10.18,
    "CIDADE": "Matupá",
    "UF": "MATO GROSSO",
    "anual": 4.99,
    "JAN": 4.65,
    "FEB": 4.74,
    "MAR": 4.77,
    "APR": 4.88,
    "MAY": 5.12,
    "JUN": 4.99,
    "JUL": 5.28,
    "AUG": 5.67,
    "SEP": 5.17,
    "OCT": 5.13,
    "NOV": 4.78,
    "DEC": 4.69
  },
  {
    "LONG": -54.98,
    "LAT": -10.23,
    "CIDADE": "Peixoto de Azevedo",
    "UF": "MATO GROSSO",
    "anual": 4.99,
    "JAN": 4.65,
    "FEB": 4.74,
    "MAR": 4.77,
    "APR": 4.88,
    "MAY": 5.12,
    "JUN": 4.99,
    "JUL": 5.28,
    "AUG": 5.67,
    "SEP": 5.17,
    "OCT": 5.13,
    "NOV": 4.78,
    "DEC": 4.69
  },
  {
    "LONG": -52.4,
    "LAT": -10.15,
    "CIDADE": "Santa Cruz do Xingu",
    "UF": "MATO GROSSO",
    "anual": 5.04,
    "JAN": 4.56,
    "FEB": 4.84,
    "MAR": 4.8,
    "APR": 4.8,
    "MAY": 5.14,
    "JUN": 5.23,
    "JUL": 5.36,
    "AUG": 5.89,
    "SEP": 5.29,
    "OCT": 5.02,
    "NOV": 4.78,
    "DEC": 4.73
  },
  {
    "LONG": -57.53,
    "LAT": -10,
    "CIDADE": "Nova Monte Verde",
    "UF": "MATO GROSSO",
    "anual": 4.82,
    "JAN": 4.37,
    "FEB": 4.47,
    "MAR": 4.53,
    "APR": 4.67,
    "MAY": 4.78,
    "JUN": 5.03,
    "JUL": 5.14,
    "AUG": 5.56,
    "SEP": 5.02,
    "OCT": 5.01,
    "NOV": 4.69,
    "DEC": 4.57
  },
  {
    "LONG": -55.18,
    "LAT": -9.97,
    "CIDADE": "Novo Mundo",
    "UF": "MATO GROSSO",
    "anual": 4.96,
    "JAN": 4.57,
    "FEB": 4.64,
    "MAR": 4.76,
    "APR": 4.83,
    "MAY": 5.05,
    "JUN": 5.06,
    "JUL": 5.3,
    "AUG": 5.75,
    "SEP": 5.15,
    "OCT": 5.04,
    "NOV": 4.73,
    "DEC": 4.64
  },
  {
    "LONG": -54.91,
    "LAT": -9.96,
    "CIDADE": "Guarantã do Norte",
    "UF": "MATO GROSSO",
    "anual": 4.95,
    "JAN": 4.53,
    "FEB": 4.62,
    "MAR": 4.74,
    "APR": 4.8,
    "MAY": 5.03,
    "JUN": 5.15,
    "JUL": 5.32,
    "AUG": 5.74,
    "SEP": 5.09,
    "OCT": 5.02,
    "NOV": 4.7,
    "DEC": 4.61
  },
  {
    "LONG": -5.11,
    "LAT": -10.01,
    "CIDADE": "Vila Rica",
    "UF": "MATO GROSSO",
    "anual": 5.11,
    "JAN": 4.67,
    "FEB": 4.83,
    "MAR": 4.83,
    "APR": 4.95,
    "MAY": 5.15,
    "JUN": 5.41,
    "JUL": 5.6,
    "AUG": 6.02,
    "SEP": 5.36,
    "OCT": 4.97,
    "NOV": 4.75,
    "DEC": 4.79
  },
  {
    "LONG": -58.57,
    "LAT": -9.9,
    "CIDADE": "Cotriguaçu",
    "UF": "MATO GROSSO",
    "anual": 4.72,
    "JAN": 4.14,
    "FEB": 4.34,
    "MAR": 4.47,
    "APR": 4.57,
    "MAY": 4.53,
    "JUN": 5.05,
    "JUL": 5.1,
    "AUG": 5.43,
    "SEP": 4.99,
    "OCT": 5,
    "NOV": 4.58,
    "DEC": 4.42
  },
  {
    "LONG": -56.09,
    "LAT": -9.87,
    "CIDADE": "Alta Floresta",
    "UF": "MATO GROSSO",
    "anual": 4.95,
    "JAN": 4.54,
    "FEB": 4.67,
    "MAR": 4.68,
    "APR": 4.76,
    "MAY": 4.91,
    "JUN": 5.15,
    "JUL": 5.22,
    "AUG": 5.69,
    "SEP": 5.2,
    "OCT": 5.11,
    "NOV": 4.77,
    "DEC": 4.67
  },
  {
    "LONG": -55.84,
    "LAT": -9.95,
    "CIDADE": "Carlinda",
    "UF": "MATO GROSSO",
    "anual": 4.94,
    "JAN": 4.56,
    "FEB": 4.65,
    "MAR": 4.67,
    "APR": 4.82,
    "MAY": 4.98,
    "JUN": 5.13,
    "JUL": 5.25,
    "AUG": 5.58,
    "SEP": 5.17,
    "OCT": 5.06,
    "NOV": 4.8,
    "DEC": 4.66
  },
  {
    "LONG": -57.83,
    "LAT": -9.85,
    "CIDADE": "Nova Bandeirantes",
    "UF": "MATO GROSSO",
    "anual": 4.78,
    "JAN": 4.31,
    "FEB": 4.44,
    "MAR": 4.48,
    "APR": 4.59,
    "MAY": 4.71,
    "JUN": 4.99,
    "JUL": 5.16,
    "AUG": 5.52,
    "SEP": 4.99,
    "OCT": 4.99,
    "NOV": 4.6,
    "DEC": 4.52
  },
  {
    "LONG": -56.48,
    "LAT": -9.66,
    "CIDADE": "Paranaíta",
    "UF": "MATO GROSSO",
    "anual": 4.86,
    "JAN": 4.47,
    "FEB": 4.57,
    "MAR": 4.58,
    "APR": 4.62,
    "MAY": 4.82,
    "JUN": 5.1,
    "JUL": 5.19,
    "AUG": 5.58,
    "SEP": 5.12,
    "OCT": 5.01,
    "NOV": 4.68,
    "DEC": 4.58
  },
  {
    "LONG": -59.23,
    "LAT": -9.46,
    "CIDADE": "Colniza",
    "UF": "MATO GROSSO",
    "anual": 4.66,
    "JAN": 4.15,
    "FEB": 4.31,
    "MAR": 4.41,
    "APR": 4.46,
    "MAY": 4.49,
    "JUN": 4.94,
    "JUL": 5.03,
    "AUG": 5.27,
    "SEP": 4.95,
    "OCT": 4.92,
    "NOV": 4.62,
    "DEC": 4.38
  },
  {
    "LONG": -57.46,
    "LAT": -9.54,
    "CIDADE": "Apiacás",
    "UF": "MATO GROSSO",
    "anual": 4.76,
    "JAN": 4.28,
    "FEB": 4.42,
    "MAR": 4.49,
    "APR": 4.61,
    "MAY": 4.66,
    "JUN": 5.03,
    "JUL": 5.15,
    "AUG": 5.49,
    "SEP": 5.02,
    "OCT": 4.93,
    "NOV": 4.59,
    "DEC": 4.46
  },
  {
    "LONG": -55.04,
    "LAT": -2.4,
    "CIDADE": "Sete Quedas",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.02,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.6,
    "APR": 5.02,
    "MAY": 4.28,
    "JUN": 3.88,
    "JUL": 4.15,
    "AUG": 5.08,
    "SEP": 4.89,
    "OCT": 5.23,
    "NOV": 5.48,
    "DEC": 5.65
  },
  {
    "LONG": -55.43,
    "LAT": -23.89,
    "CIDADE": "Paranhos",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5,
    "JAN": 5.46,
    "FEB": 5.46,
    "MAR": 5.59,
    "APR": 5,
    "MAY": 4.25,
    "JUN": 3.89,
    "JUL": 4.05,
    "AUG": 5.01,
    "SEP": 4.9,
    "OCT": 5.2,
    "NOV": 5.45,
    "DEC": 5.7
  },
  {
    "LONG": -54.41,
    "LAT": -23.89,
    "CIDADE": "Japorã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.04,
    "JAN": 5.43,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.09,
    "MAY": 4.34,
    "JUN": 3.95,
    "JUL": 4.19,
    "AUG": 5.14,
    "SEP": 4.92,
    "OCT": 5.21,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -54.28,
    "LAT": -2.39,
    "CIDADE": "Mundo Novo",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.05,
    "JAN": 5.48,
    "FEB": 5.53,
    "MAR": 5.59,
    "APR": 5.11,
    "MAY": 4.34,
    "JUN": 3.97,
    "JUL": 4.19,
    "AUG": 5.11,
    "SEP": 4.91,
    "OCT": 5.22,
    "NOV": 5.49,
    "DEC": 5.65
  },
  {
    "LONG": -54.28,
    "LAT": -23.79,
    "CIDADE": "Eldorado",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.06,
    "JAN": 5.44,
    "FEB": 5.53,
    "MAR": 5.6,
    "APR": 5.1,
    "MAY": 4.36,
    "JUN": 4,
    "JUL": 4.2,
    "AUG": 5.15,
    "SEP": 4.93,
    "OCT": 5.24,
    "NOV": 5.5,
    "DEC": 5.66
  },
  {
    "LONG": -54.56,
    "LAT": -2.37,
    "CIDADE": "Iguatemi",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.03,
    "JAN": 5.39,
    "FEB": 5.53,
    "MAR": 5.55,
    "APR": 5.04,
    "MAY": 4.34,
    "JUN": 3.96,
    "JUL": 4.2,
    "AUG": 5.12,
    "SEP": 4.93,
    "OCT": 5.22,
    "NOV": 5.45,
    "DEC": 5.61
  },
  {
    "LONG": -55.01,
    "LAT": -23.64,
    "CIDADE": "Tacuru",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5,
    "JAN": 5.34,
    "FEB": 5.44,
    "MAR": 5.52,
    "APR": 5.05,
    "MAY": 4.33,
    "JUN": 3.98,
    "JUL": 4.15,
    "AUG": 5.07,
    "SEP": 4.95,
    "OCT": 5.2,
    "NOV": 5.44,
    "DEC": 5.59
  },
  {
    "LONG": -54.19,
    "LAT": -23.48,
    "CIDADE": "Itaquiraí",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.08,
    "JAN": 5.38,
    "FEB": 5.51,
    "MAR": 5.55,
    "APR": 5.1,
    "MAY": 4.38,
    "JUN": 4.08,
    "JUL": 4.3,
    "AUG": 5.18,
    "SEP": 4.97,
    "OCT": 5.24,
    "NOV": 5.51,
    "DEC": 5.71
  },
  {
    "LONG": -55.53,
    "LAT": -23.27,
    "CIDADE": "Coronel Sapucaia",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.01,
    "JAN": 5.39,
    "FEB": 5.38,
    "MAR": 5.52,
    "APR": 5.04,
    "MAY": 4.31,
    "JUN": 4.08,
    "JUL": 4.18,
    "AUG": 5.07,
    "SEP": 4.97,
    "OCT": 5.2,
    "NOV": 5.34,
    "DEC": 5.66
  },
  {
    "LONG": -55.23,
    "LAT": -23.11,
    "CIDADE": "Amambai",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5,
    "JAN": 5.28,
    "FEB": 5.33,
    "MAR": 5.46,
    "APR": 5.03,
    "MAY": 4.33,
    "JUN": 4.12,
    "JUL": 4.21,
    "AUG": 5.19,
    "SEP": 4.95,
    "OCT": 5.17,
    "NOV": 5.33,
    "DEC": 5.6
  },
  {
    "LONG": -54.2,
    "LAT": -23.06,
    "CIDADE": "Naviraí",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.07,
    "JAN": 5.33,
    "FEB": 5.51,
    "MAR": 5.55,
    "APR": 5.12,
    "MAY": 4.39,
    "JUN": 4.13,
    "JUL": 4.32,
    "AUG": 5.19,
    "SEP": 4.98,
    "OCT": 5.25,
    "NOV": 5.5,
    "DEC": 5.61
  },
  {
    "LONG": -55.63,
    "LAT": -22.94,
    "CIDADE": "Aral Moreira",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5,
    "JAN": 5.33,
    "FEB": 5.37,
    "MAR": 5.53,
    "APR": 5.04,
    "MAY": 4.28,
    "JUN": 4.08,
    "JUL": 4.14,
    "AUG": 5.14,
    "SEP": 4.96,
    "OCT": 5.2,
    "NOV": 5.36,
    "DEC": 5.54
  },
  {
    "LONG": -54.61,
    "LAT": -22.86,
    "CIDADE": "Juti",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.08,
    "JAN": 5.31,
    "FEB": 5.49,
    "MAR": 5.57,
    "APR": 5.09,
    "MAY": 4.41,
    "JUN": 4.19,
    "JUL": 4.32,
    "AUG": 5.24,
    "SEP": 5.01,
    "OCT": 5.23,
    "NOV": 5.44,
    "DEC": 5.61
  },
  {
    "LONG": -53.86,
    "LAT": -22.66,
    "CIDADE": "Novo Horizonte do Sul",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.16,
    "JAN": 5.28,
    "FEB": 5.57,
    "MAR": 5.59,
    "APR": 5.25,
    "MAY": 4.58,
    "JUN": 4.31,
    "JUL": 4.47,
    "AUG": 5.33,
    "SEP": 5.08,
    "OCT": 5.29,
    "NOV": 5.51,
    "DEC": 5.64
  },
  {
    "LONG": -54.82,
    "LAT": -22.64,
    "CIDADE": "Caarapó",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.09,
    "JAN": 5.28,
    "FEB": 5.46,
    "MAR": 5.53,
    "APR": 5.14,
    "MAY": 4.5,
    "JUN": 4.28,
    "JUL": 4.34,
    "AUG": 5.27,
    "SEP": 5.04,
    "OCT": 5.22,
    "NOV": 5.4,
    "DEC": 5.57
  },
  {
    "LONG": -55.72,
    "LAT": -22.53,
    "CIDADE": "Ponta Porã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.03,
    "JAN": 5.21,
    "FEB": 5.35,
    "MAR": 5.5,
    "APR": 5.11,
    "MAY": 4.37,
    "JUN": 4.2,
    "JUL": 4.26,
    "AUG": 5.26,
    "SEP": 5.06,
    "OCT": 5.22,
    "NOV": 5.32,
    "DEC": 5.52
  },
  {
    "LONG": -55.15,
    "LAT": -22.55,
    "CIDADE": "Laguna Carapã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.05,
    "JAN": 5.26,
    "FEB": 5.44,
    "MAR": 5.46,
    "APR": 5.1,
    "MAY": 4.43,
    "JUN": 4.25,
    "JUL": 4.31,
    "AUG": 5.26,
    "SEP": 5.06,
    "OCT": 5.21,
    "NOV": 5.35,
    "DEC": 5.5
  },
  {
    "LONG": -54.31,
    "LAT": -22.48,
    "CIDADE": "Jateí",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.16,
    "JAN": 5.33,
    "FEB": 5.61,
    "MAR": 5.6,
    "APR": 5.21,
    "MAY": 4.55,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 5.35,
    "SEP": 5.13,
    "OCT": 5.26,
    "NOV": 5.48,
    "DEC": 5.65
  },
  {
    "LONG": -53.35,
    "LAT": -2.25,
    "CIDADE": "Taquarussu",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.21,
    "JAN": 5.37,
    "FEB": 5.61,
    "MAR": 5.64,
    "APR": 5.35,
    "MAY": 4.62,
    "JUN": 4.36,
    "JUL": 4.51,
    "AUG": 5.37,
    "SEP": 5.12,
    "OCT": 5.31,
    "NOV": 5.53,
    "DEC": 5.69
  },
  {
    "LONG": -54.51,
    "LAT": -22.38,
    "CIDADE": "Fátima do Sul",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.12,
    "JAN": 5.31,
    "FEB": 5.5,
    "MAR": 5.53,
    "APR": 5.21,
    "MAY": 4.54,
    "JUN": 4.31,
    "JUL": 4.42,
    "AUG": 5.29,
    "SEP": 5.09,
    "OCT": 5.22,
    "NOV": 5.42,
    "DEC": 5.63
  },
  {
    "LONG": -54.44,
    "LAT": -22.41,
    "CIDADE": "Vicentina",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.15,
    "JAN": 5.39,
    "FEB": 5.57,
    "MAR": 5.57,
    "APR": 5.23,
    "MAY": 4.54,
    "JUN": 4.34,
    "JUL": 4.44,
    "AUG": 5.32,
    "SEP": 5.11,
    "OCT": 5.25,
    "NOV": 5.45,
    "DEC": 5.64
  },
  {
    "LONG": -54.23,
    "LAT": -2.24,
    "CIDADE": "Glória de Dourados",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.18,
    "JAN": 5.35,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.25,
    "MAY": 4.57,
    "JUN": 4.34,
    "JUL": 4.49,
    "AUG": 5.37,
    "SEP": 5.12,
    "OCT": 5.3,
    "NOV": 5.52,
    "DEC": 5.64
  },
  {
    "LONG": -54.17,
    "LAT": -22.28,
    "CIDADE": "Deodápolis",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.19,
    "JAN": 5.35,
    "FEB": 5.59,
    "MAR": 5.6,
    "APR": 5.25,
    "MAY": 4.62,
    "JUN": 4.37,
    "JUL": 4.52,
    "AUG": 5.36,
    "SEP": 5.13,
    "OCT": 5.32,
    "NOV": 5.53,
    "DEC": 5.65
  },
  {
    "LONG": -53.82,
    "LAT": -2.23,
    "CIDADE": "Ivinhema",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.17,
    "JAN": 5.26,
    "FEB": 5.54,
    "MAR": 5.61,
    "APR": 5.28,
    "MAY": 4.61,
    "JUN": 4.35,
    "JUL": 4.5,
    "AUG": 5.37,
    "SEP": 5.12,
    "OCT": 5.33,
    "NOV": 5.45,
    "DEC": 5.63
  },
  {
    "LONG": -53.27,
    "LAT": -22.29,
    "CIDADE": "Batayporã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.2,
    "JAN": 5.29,
    "FEB": 5.56,
    "MAR": 5.59,
    "APR": 5.37,
    "MAY": 4.67,
    "JUN": 4.4,
    "JUL": 4.54,
    "AUG": 5.39,
    "SEP": 5.15,
    "OCT": 5.32,
    "NOV": 5.51,
    "DEC": 5.61
  },
  {
    "LONG": -55.95,
    "LAT": -2.22,
    "CIDADE": "Antônio João",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.08,
    "JAN": 5.37,
    "FEB": 5.39,
    "MAR": 5.53,
    "APR": 5.19,
    "MAY": 4.45,
    "JUN": 4.31,
    "JUL": 4.32,
    "AUG": 5.28,
    "SEP": 5.05,
    "OCT": 5.26,
    "NOV": 5.34,
    "DEC": 5.49
  },
  {
    "LONG": -54.81,
    "LAT": -22.22,
    "CIDADE": "Dourados",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.1,
    "JAN": 5.31,
    "FEB": 5.46,
    "MAR": 5.47,
    "APR": 5.15,
    "MAY": 4.55,
    "JUN": 4.31,
    "JUL": 4.4,
    "AUG": 5.29,
    "SEP": 5.09,
    "OCT": 5.19,
    "NOV": 5.38,
    "DEC": 5.59
  },
  {
    "LONG": -53.77,
    "LAT": -22.15,
    "CIDADE": "Angélica",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.19,
    "JAN": 5.24,
    "FEB": 5.57,
    "MAR": 5.62,
    "APR": 5.34,
    "MAY": 4.65,
    "JUN": 4.4,
    "JUL": 4.54,
    "AUG": 5.39,
    "SEP": 5.12,
    "OCT": 5.3,
    "NOV": 5.49,
    "DEC": 5.66
  },
  {
    "LONG": -53.34,
    "LAT": -22.24,
    "CIDADE": "Nova Andradina",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.19,
    "JAN": 5.21,
    "FEB": 5.52,
    "MAR": 5.54,
    "APR": 5.35,
    "MAY": 4.66,
    "JUN": 4.41,
    "JUL": 4.55,
    "AUG": 5.42,
    "SEP": 5.16,
    "OCT": 5.32,
    "NOV": 5.47,
    "DEC": 5.61
  },
  {
    "LONG": -52.72,
    "LAT": -22.19,
    "CIDADE": "Anaurilândia",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.22,
    "JAN": 5.29,
    "FEB": 5.57,
    "MAR": 5.63,
    "APR": 5.43,
    "MAY": 4.73,
    "JUN": 4.43,
    "JUL": 4.6,
    "AUG": 5.4,
    "SEP": 5.16,
    "OCT": 5.33,
    "NOV": 5.49,
    "DEC": 5.63
  },
  {
    "LONG": -56.53,
    "LAT": -22.11,
    "CIDADE": "Bela Vista",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.05,
    "JAN": 5.45,
    "FEB": 5.44,
    "MAR": 5.51,
    "APR": 5.2,
    "MAY": 4.37,
    "JUN": 4.12,
    "JUL": 4.17,
    "AUG": 5.1,
    "SEP": 4.97,
    "OCT": 5.25,
    "NOV": 5.41,
    "DEC": 5.59
  },
  {
    "LONG": -54.79,
    "LAT": -22.08,
    "CIDADE": "Itaporã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.13,
    "JAN": 5.34,
    "FEB": 5.48,
    "MAR": 5.49,
    "APR": 5.22,
    "MAY": 4.61,
    "JUN": 4.37,
    "JUL": 4.42,
    "AUG": 5.25,
    "SEP": 5.12,
    "OCT": 5.22,
    "NOV": 5.42,
    "DEC": 5.6
  },
  {
    "LONG": -57.03,
    "LAT": -22.01,
    "CIDADE": "Caracol",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.04,
    "JAN": 5.42,
    "FEB": 5.41,
    "MAR": 5.5,
    "APR": 5.2,
    "MAY": 4.37,
    "JUN": 4.07,
    "JUL": 4.15,
    "AUG": 5.1,
    "SEP": 4.98,
    "OCT": 5.26,
    "NOV": 5.44,
    "DEC": 5.61
  },
  {
    "LONG": -54.61,
    "LAT": "#VALOR!",
    "CIDADE": "Douradina",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.16,
    "JAN": 5.35,
    "FEB": 5.54,
    "MAR": 5.57,
    "APR": 5.24,
    "MAY": 4.62,
    "JUN": 4.38,
    "JUL": 4.46,
    "AUG": 5.29,
    "SEP": 5.12,
    "OCT": 5.27,
    "NOV": 5.45,
    "DEC": 5.61
  },
  {
    "LONG": -54.54,
    "LAT": -21.8,
    "CIDADE": "Rio Brilhante",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.15,
    "JAN": 5.32,
    "FEB": 5.48,
    "MAR": 5.53,
    "APR": 5.25,
    "MAY": 4.63,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 5.35,
    "SEP": 5.09,
    "OCT": 5.24,
    "NOV": 5.42,
    "DEC": 5.6
  },
  {
    "LONG": -5.79,
    "LAT": -21.7,
    "CIDADE": "Porto Murtinho",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.09,
    "JAN": 5.61,
    "FEB": 5.54,
    "MAR": 5.6,
    "APR": 5.28,
    "MAY": 4.36,
    "JUN": 4.02,
    "JUL": 4.1,
    "AUG": 5.04,
    "SEP": 4.94,
    "OCT": 5.33,
    "NOV": 5.51,
    "DEC": 5.73
  },
  {
    "LONG": -52.42,
    "LAT": -21.72,
    "CIDADE": "Bataguassu",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.29,
    "JAN": 5.24,
    "FEB": 5.64,
    "MAR": 5.65,
    "APR": 5.48,
    "MAY": 4.87,
    "JUN": 4.59,
    "JUL": 4.71,
    "AUG": 5.59,
    "SEP": 5.17,
    "OCT": 5.33,
    "NOV": 5.57,
    "DEC": 5.63
  },
  {
    "LONG": -55.17,
    "LAT": -2.16,
    "CIDADE": "Maracaju",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.12,
    "JAN": 5.3,
    "FEB": 5.43,
    "MAR": 5.44,
    "APR": 5.24,
    "MAY": 4.61,
    "JUN": 4.38,
    "JUL": 4.51,
    "AUG": 5.3,
    "SEP": 5.11,
    "OCT": 5.23,
    "NOV": 5.35,
    "DEC": 5.57
  },
  {
    "LONG": -56.11,
    "LAT": -21.46,
    "CIDADE": "Guia Lopes da Laguna",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.15,
    "JAN": 5.3,
    "FEB": 5.48,
    "MAR": 5.64,
    "APR": 5.35,
    "MAY": 4.54,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.32,
    "SEP": 5.07,
    "OCT": 5.31,
    "NOV": 5.42,
    "DEC": 5.51
  },
  {
    "LONG": -56.15,
    "LAT": -21.48,
    "CIDADE": "Jardim",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.15,
    "JAN": 5.3,
    "FEB": 5.48,
    "MAR": 5.64,
    "APR": 5.35,
    "MAY": 4.54,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.32,
    "SEP": 5.07,
    "OCT": 5.31,
    "NOV": 5.42,
    "DEC": 5.51
  },
  {
    "LONG": -54.38,
    "LAT": -21.47,
    "CIDADE": "Nova Alvorada do Sul",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.16,
    "JAN": 5.2,
    "FEB": 5.47,
    "MAR": 5.52,
    "APR": 5.31,
    "MAY": 4.7,
    "JUN": 4.48,
    "JUL": 4.55,
    "AUG": 5.38,
    "SEP": 5.13,
    "OCT": 5.26,
    "NOV": 5.37,
    "DEC": 5.47
  },
  {
    "LONG": -52.83,
    "LAT": -21.3,
    "CIDADE": "Santa Rita do Pardo",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.29,
    "JAN": 5.21,
    "FEB": 5.62,
    "MAR": 5.59,
    "APR": 5.44,
    "MAY": 4.92,
    "JUN": 4.68,
    "JUL": 4.79,
    "AUG": 5.63,
    "SEP": 5.18,
    "OCT": 5.31,
    "NOV": 5.48,
    "DEC": 5.64
  },
  {
    "LONG": -52.04,
    "LAT": -21.25,
    "CIDADE": "Brasilândia",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.34,
    "JAN": 5.27,
    "FEB": 5.69,
    "MAR": 5.62,
    "APR": 5.52,
    "MAY": 4.98,
    "JUN": 4.74,
    "JUL": 4.85,
    "AUG": 5.66,
    "SEP": 5.28,
    "OCT": 5.44,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -55.83,
    "LAT": -21.16,
    "CIDADE": "Nioaque",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.18,
    "JAN": 5.28,
    "FEB": 5.48,
    "MAR": 5.6,
    "APR": 5.36,
    "MAY": 4.63,
    "JUN": 4.44,
    "JUL": 4.56,
    "AUG": 5.36,
    "SEP": 5.12,
    "OCT": 5.35,
    "NOV": 5.48,
    "DEC": 5.56
  },
  {
    "LONG": -5.65,
    "LAT": -21.13,
    "CIDADE": "Bonito",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.11,
    "JAN": 5.23,
    "FEB": 5.36,
    "MAR": 5.49,
    "APR": 5.31,
    "MAY": 4.54,
    "JUN": 4.39,
    "JUL": 4.52,
    "AUG": 5.31,
    "SEP": 5.07,
    "OCT": 5.26,
    "NOV": 5.42,
    "DEC": 5.41
  },
  {
    "LONG": -54.97,
    "LAT": -20.93,
    "CIDADE": "Sidrolândia",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.22,
    "JAN": 5.17,
    "FEB": 5.52,
    "MAR": 5.56,
    "APR": 5.39,
    "MAY": 4.75,
    "JUN": 4.6,
    "JUL": 4.7,
    "AUG": 5.45,
    "SEP": 5.24,
    "OCT": 5.32,
    "NOV": 5.45,
    "DEC": 5.49
  },
  {
    "LONG": -51.7,
    "LAT": -20.79,
    "CIDADE": "Três Lagoas",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.37,
    "JAN": 5.32,
    "FEB": 5.7,
    "MAR": 5.64,
    "APR": 5.48,
    "MAY": 4.97,
    "JUN": 4.8,
    "JUL": 4.97,
    "AUG": 5.76,
    "SEP": 5.28,
    "OCT": 5.46,
    "NOV": 5.49,
    "DEC": 5.61
  },
  {
    "LONG": -55.28,
    "LAT": -20.69,
    "CIDADE": "Dois Irmãos do Buriti",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.24,
    "JAN": 5.19,
    "FEB": 5.53,
    "MAR": 5.6,
    "APR": 5.41,
    "MAY": 4.75,
    "JUN": 4.65,
    "JUL": 4.73,
    "AUG": 5.44,
    "SEP": 5.22,
    "OCT": 5.36,
    "NOV": 5.48,
    "DEC": 5.51
  },
  {
    "LONG": -56.67,
    "LAT": -20.55,
    "CIDADE": "Bodoquena",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.13,
    "JAN": 5.21,
    "FEB": 5.32,
    "MAR": 5.49,
    "APR": 5.38,
    "MAY": 4.54,
    "JUN": 4.48,
    "JUL": 4.57,
    "AUG": 5.33,
    "SEP": 5.09,
    "OCT": 5.3,
    "NOV": 5.35,
    "DEC": 5.42
  },
  {
    "LONG": -55.81,
    "LAT": -20.48,
    "CIDADE": "Anastácio",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.25,
    "JAN": 5.24,
    "FEB": 5.58,
    "MAR": 5.58,
    "APR": 5.43,
    "MAY": 4.75,
    "JUN": 4.61,
    "JUL": 4.72,
    "AUG": 5.42,
    "SEP": 5.15,
    "OCT": 5.41,
    "NOV": 5.52,
    "DEC": 5.58
  },
  {
    "LONG": -55.79,
    "LAT": -2.05,
    "CIDADE": "Aquidauana",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.24,
    "JAN": 5.24,
    "FEB": 5.46,
    "MAR": 5.58,
    "APR": 5.44,
    "MAY": 4.72,
    "JUN": 4.61,
    "JUL": 4.72,
    "AUG": 5.45,
    "SEP": 5.16,
    "OCT": 5.38,
    "NOV": 5.53,
    "DEC": 5.58
  },
  {
    "LONG": -54.87,
    "LAT": -20.44,
    "CIDADE": "Terenos",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.27,
    "JAN": 5.16,
    "FEB": 5.52,
    "MAR": 5.58,
    "APR": 5.47,
    "MAY": 4.85,
    "JUN": 4.73,
    "JUL": 4.88,
    "AUG": 5.58,
    "SEP": 5.23,
    "OCT": 5.3,
    "NOV": 5.42,
    "DEC": 5.48
  },
  {
    "LONG": -54.63,
    "LAT": -20.45,
    "CIDADE": "Campo Grande",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.24,
    "JAN": 5.11,
    "FEB": 5.42,
    "MAR": 5.51,
    "APR": 5.43,
    "MAY": 4.87,
    "JUN": 4.74,
    "JUL": 4.85,
    "AUG": 5.6,
    "SEP": 5.23,
    "OCT": 5.28,
    "NOV": 5.4,
    "DEC": 5.4
  },
  {
    "LONG": -53.76,
    "LAT": -2.04,
    "CIDADE": "Ribas do Rio Pardo",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.25,
    "JAN": 5.16,
    "FEB": 5.54,
    "MAR": 5.55,
    "APR": 5.43,
    "MAY": 4.86,
    "JUN": 4.7,
    "JUL": 4.86,
    "AUG": 5.62,
    "SEP": 5.26,
    "OCT": 5.29,
    "NOV": 5.32,
    "DEC": 5.44
  },
  {
    "LONG": -52.88,
    "LAT": -20.45,
    "CIDADE": "Água Clara",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.31,
    "JAN": 5.17,
    "FEB": 5.62,
    "MAR": 5.62,
    "APR": 5.46,
    "MAY": 4.96,
    "JUN": 4.8,
    "JUL": 4.97,
    "AUG": 5.67,
    "SEP": 5.3,
    "OCT": 5.29,
    "NOV": 5.34,
    "DEC": 5.52
  },
  {
    "LONG": -51.42,
    "LAT": -20.36,
    "CIDADE": "Selvíria",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.38,
    "JAN": 5.28,
    "FEB": 5.68,
    "MAR": 5.63,
    "APR": 5.54,
    "MAY": 5.04,
    "JUN": 4.86,
    "JUL": 5.01,
    "AUG": 5.75,
    "SEP": 5.29,
    "OCT": 5.48,
    "NOV": 5.45,
    "DEC": 5.58
  },
  {
    "LONG": -5.64,
    "LAT": -2.02,
    "CIDADE": "Miranda",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.18,
    "JAN": 5.25,
    "FEB": 5.45,
    "MAR": 5.48,
    "APR": 5.43,
    "MAY": 4.67,
    "JUN": 4.56,
    "JUL": 4.68,
    "AUG": 5.32,
    "SEP": 5.15,
    "OCT": 5.33,
    "NOV": 5.4,
    "DEC": 5.49
  },
  {
    "LONG": -54.4,
    "LAT": -20.14,
    "CIDADE": "Jaraguari",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.25,
    "JAN": 5.08,
    "FEB": 5.4,
    "MAR": 5.5,
    "APR": 5.46,
    "MAY": 4.92,
    "JUN": 4.8,
    "JUL": 4.96,
    "AUG": 5.6,
    "SEP": 5.25,
    "OCT": 5.32,
    "NOV": 5.37,
    "DEC": 5.38
  },
  {
    "LONG": -51.1,
    "LAT": -20.09,
    "CIDADE": "Aparecida do Taboado",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.39,
    "JAN": 5.21,
    "FEB": 5.69,
    "MAR": 5.6,
    "APR": 5.61,
    "MAY": 5.11,
    "JUN": 4.89,
    "JUL": 5.04,
    "AUG": 5.77,
    "SEP": 5.27,
    "OCT": 5.48,
    "NOV": 5.49,
    "DEC": 5.57
  },
  {
    "LONG": -54.89,
    "LAT": -19.95,
    "CIDADE": "Rochedo",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.26,
    "JAN": 5.1,
    "FEB": 5.43,
    "MAR": 5.55,
    "APR": 5.49,
    "MAY": 4.86,
    "JUN": 4.77,
    "JUL": 4.88,
    "AUG": 5.61,
    "SEP": 5.28,
    "OCT": 5.34,
    "NOV": 5.38,
    "DEC": 5.42
  },
  {
    "LONG": -5.44,
    "LAT": -19.92,
    "CIDADE": "Bandeirantes",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.23,
    "JAN": 5.1,
    "FEB": 5.32,
    "MAR": 5.41,
    "APR": 5.41,
    "MAY": 4.93,
    "JUN": 4.83,
    "JUL": 4.98,
    "AUG": 5.6,
    "SEP": 5.26,
    "OCT": 5.27,
    "NOV": 5.28,
    "DEC": 5.36
  },
  {
    "LONG": -54.83,
    "LAT": -19.83,
    "CIDADE": "Corguinho",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.28,
    "JAN": 5.12,
    "FEB": 5.4,
    "MAR": 5.57,
    "APR": 5.48,
    "MAY": 4.9,
    "JUN": 4.81,
    "JUL": 4.96,
    "AUG": 5.63,
    "SEP": 5.3,
    "OCT": 5.34,
    "NOV": 5.4,
    "DEC": 5.41
  },
  {
    "LONG": -51.93,
    "LAT": -19.73,
    "CIDADE": "Inocência",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.38,
    "JAN": 5.13,
    "FEB": 5.58,
    "MAR": 5.5,
    "APR": 5.56,
    "MAY": 5.18,
    "JUN": 5.03,
    "JUL": 5.15,
    "AUG": 5.89,
    "SEP": 5.41,
    "OCT": 5.35,
    "NOV": 5.34,
    "DEC": 5.49
  },
  {
    "LONG": -51.19,
    "LAT": -19.68,
    "CIDADE": "Paranaíba",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.45,
    "JAN": 5.15,
    "FEB": 5.69,
    "MAR": 5.59,
    "APR": 5.66,
    "MAY": 5.19,
    "JUN": 5.03,
    "JUL": 5.23,
    "AUG": 6,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.41,
    "DEC": 5.49
  },
  {
    "LONG": -54.04,
    "LAT": -19.54,
    "CIDADE": "Camapuã",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.35,
    "JAN": 5.15,
    "FEB": 5.56,
    "MAR": 5.57,
    "APR": 5.52,
    "MAY": 5.04,
    "JUN": 5.04,
    "JUL": 5.1,
    "AUG": 5.71,
    "SEP": 5.39,
    "OCT": 5.39,
    "NOV": 5.38,
    "DEC": 5.39
  },
  {
    "LONG": -54.99,
    "LAT": -19.45,
    "CIDADE": "Rio Negro",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.29,
    "JAN": 5,
    "FEB": 5.47,
    "MAR": 5.58,
    "APR": 5.52,
    "MAY": 4.93,
    "JUN": 4.91,
    "JUL": 5.01,
    "AUG": 5.64,
    "SEP": 5.34,
    "OCT": 5.33,
    "NOV": 5.34,
    "DEC": 5.37
  },
  {
    "LONG": -54.58,
    "LAT": -19.39,
    "CIDADE": "São Gabriel do Oeste",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.21,
    "JAN": 5.06,
    "FEB": 5.27,
    "MAR": 5.29,
    "APR": 5.34,
    "MAY": 4.94,
    "JUN": 4.96,
    "JUL": 5.02,
    "AUG": 5.62,
    "SEP": 5.31,
    "OCT": 5.2,
    "NOV": 5.18,
    "DEC": 5.28
  },
  {
    "LONG": -51.73,
    "LAT": -19.12,
    "CIDADE": "Cassilândia",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.44,
    "JAN": 5.16,
    "FEB": 5.59,
    "MAR": 5.51,
    "APR": 5.67,
    "MAY": 5.29,
    "JUN": 5.17,
    "JUL": 5.34,
    "AUG": 6.02,
    "SEP": 5.44,
    "OCT": 5.42,
    "NOV": 5.28,
    "DEC": 5.4
  },
  {
    "LONG": -57.65,
    "LAT": -19.01,
    "CIDADE": "Corumbá",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.12,
    "JAN": 5.18,
    "FEB": 5.35,
    "MAR": 5.41,
    "APR": 5.49,
    "MAY": 4.54,
    "JUN": 4.48,
    "JUL": 4.65,
    "AUG": 5.28,
    "SEP": 5.04,
    "OCT": 5.3,
    "NOV": 5.26,
    "DEC": 5.43
  },
  {
    "LONG": -57.6,
    "LAT": -19.01,
    "CIDADE": "Ladário",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.14,
    "JAN": 5.2,
    "FEB": 5.31,
    "MAR": 5.37,
    "APR": 5.5,
    "MAY": 4.55,
    "JUN": 4.54,
    "JUL": 4.74,
    "AUG": 5.36,
    "SEP": 5.05,
    "OCT": 5.33,
    "NOV": 5.28,
    "DEC": 5.44
  },
  {
    "LONG": -53.01,
    "LAT": -19.02,
    "CIDADE": "Paraíso das Águas",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.36,
    "JAN": 5.26,
    "FEB": 5.44,
    "MAR": 5.44,
    "APR": 5.53,
    "MAY": 5.2,
    "JUN": 5.08,
    "JUL": 5.18,
    "AUG": 5.94,
    "SEP": 5.37,
    "OCT": 5.28,
    "NOV": 5.21,
    "DEC": 5.37
  },
  {
    "LONG": -54.84,
    "LAT": -18.92,
    "CIDADE": "Rio Verde de Mato Grosso",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.3,
    "JAN": 5.06,
    "FEB": 5.4,
    "MAR": 5.45,
    "APR": 5.49,
    "MAY": 5.01,
    "JUN": 4.99,
    "JUL": 5.11,
    "AUG": 5.69,
    "SEP": 5.41,
    "OCT": 5.35,
    "NOV": 5.29,
    "DEC": 5.32
  },
  {
    "LONG": -52.63,
    "LAT": -18.79,
    "CIDADE": "Chapadão do Sul",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.27,
    "JAN": 5.15,
    "FEB": 5.39,
    "MAR": 5.18,
    "APR": 5.44,
    "MAY": 5.21,
    "JUN": 5.08,
    "JUL": 5.23,
    "AUG": 5.89,
    "SEP": 5.33,
    "OCT": 5.08,
    "NOV": 5.01,
    "DEC": 5.31
  },
  {
    "LONG": -53.64,
    "LAT": -18.68,
    "CIDADE": "Figueirão",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.43,
    "JAN": 5.16,
    "FEB": 5.59,
    "MAR": 5.58,
    "APR": 5.65,
    "MAY": 5.28,
    "JUN": 5.12,
    "JUL": 5.21,
    "AUG": 5.88,
    "SEP": 5.49,
    "OCT": 5.4,
    "NOV": 5.44,
    "DEC": 5.38
  },
  {
    "LONG": -54.75,
    "LAT": -18.5,
    "CIDADE": "Coxim",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.35,
    "JAN": 5.08,
    "FEB": 5.47,
    "MAR": 5.59,
    "APR": 5.51,
    "MAY": 5.05,
    "JUN": 5.08,
    "JUL": 5.1,
    "AUG": 5.84,
    "SEP": 5.39,
    "OCT": 5.38,
    "NOV": 5.36,
    "DEC": 5.35
  },
  {
    "LONG": -53.13,
    "LAT": -18.54,
    "CIDADE": "Costa Rica",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.3,
    "JAN": 5.06,
    "FEB": 5.36,
    "MAR": 5.31,
    "APR": 5.44,
    "MAY": 5.22,
    "JUN": 5.12,
    "JUL": 5.26,
    "AUG": 5.94,
    "SEP": 5.41,
    "OCT": 5.21,
    "NOV": 5.11,
    "DEC": 5.14
  },
  {
    "LONG": -53.7,
    "LAT": -1.83,
    "CIDADE": "Alcinópolis",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.43,
    "JAN": 5.13,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.64,
    "MAY": 5.33,
    "JUN": 5.16,
    "JUL": 5.26,
    "AUG": 5.94,
    "SEP": 5.5,
    "OCT": 5.36,
    "NOV": 5.34,
    "DEC": 5.33
  },
  {
    "LONG": -54.55,
    "LAT": -18.1,
    "CIDADE": "Pedro Gomes",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.35,
    "JAN": 5.05,
    "FEB": 5.42,
    "MAR": 5.56,
    "APR": 5.57,
    "MAY": 5.18,
    "JUN": 5.17,
    "JUL": 5.17,
    "AUG": 5.92,
    "SEP": 5.35,
    "OCT": 5.3,
    "NOV": 5.27,
    "DEC": 5.28
  },
  {
    "LONG": -54.75,
    "LAT": -17.58,
    "CIDADE": "Sonora",
    "UF": "MATO GROSSO DO SUL",
    "anual": 5.22,
    "JAN": 4.95,
    "FEB": 5.27,
    "MAR": 5.2,
    "APR": 5.21,
    "MAY": 5.12,
    "JUN": 5.08,
    "JUL": 5.24,
    "AUG": 5.89,
    "SEP": 5.25,
    "OCT": 5.11,
    "NOV": 5.14,
    "DEC": 5.2
  },
  {
    "LONG": -46.32,
    "LAT": -22.85,
    "CIDADE": "Extrema",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 4.75,
    "FEB": 5.26,
    "MAR": 4.99,
    "APR": 5.2,
    "MAY": 4.68,
    "JUN": 4.55,
    "JUL": 4.63,
    "AUG": 5.54,
    "SEP": 5.14,
    "OCT": 5.17,
    "NOV": 4.97,
    "DEC": 5.14
  },
  {
    "LONG": -46.22,
    "LAT": -2.28,
    "CIDADE": "Itapeva",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 4.62,
    "FEB": 5.2,
    "MAR": 4.93,
    "APR": 5.17,
    "MAY": 4.69,
    "JUN": 4.62,
    "JUL": 4.74,
    "AUG": 5.57,
    "SEP": 5.22,
    "OCT": 5.17,
    "NOV": 4.93,
    "DEC": 5.03
  },
  {
    "LONG": -46.15,
    "LAT": -2.28,
    "CIDADE": "Camanducaia",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.65,
    "FEB": 5.25,
    "MAR": 4.99,
    "APR": 5.21,
    "MAY": 4.72,
    "JUN": 4.62,
    "JUL": 4.77,
    "AUG": 5.59,
    "SEP": 5.25,
    "OCT": 5.24,
    "NOV": 5.02,
    "DEC": 5.09
  },
  {
    "LONG": -46.37,
    "LAT": -22.74,
    "CIDADE": "Toledo",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 4.67,
    "FEB": 5.21,
    "MAR": 4.92,
    "APR": 5.14,
    "MAY": 4.72,
    "JUN": 4.65,
    "JUL": 4.78,
    "AUG": 5.6,
    "SEP": 5.25,
    "OCT": 5.23,
    "NOV": 4.95,
    "DEC": 5.03
  },
  {
    "LONG": -4.59,
    "LAT": -2.27,
    "CIDADE": "Gonçalves",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 4.57,
    "FEB": 5.15,
    "MAR": 4.75,
    "APR": 4.93,
    "MAY": 4.56,
    "JUN": 4.45,
    "JUL": 4.68,
    "AUG": 5.56,
    "SEP": 5.13,
    "OCT": 5.05,
    "NOV": 4.68,
    "DEC": 4.89
  },
  {
    "LONG": -45.74,
    "LAT": -22.74,
    "CIDADE": "Sapucaí-Mirim",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 4.74,
    "FEB": 5.36,
    "MAR": 5,
    "APR": 5.09,
    "MAY": 4.63,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.55,
    "SEP": 5.15,
    "OCT": 5.14,
    "NOV": 4.85,
    "DEC": 5.07
  },
  {
    "LONG": -46.36,
    "LAT": -22.61,
    "CIDADE": "Munhoz",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.77,
    "FEB": 5.28,
    "MAR": 5.04,
    "APR": 5.19,
    "MAY": 4.77,
    "JUN": 4.68,
    "JUL": 4.84,
    "AUG": 5.64,
    "SEP": 5.31,
    "OCT": 5.27,
    "NOV": 5.05,
    "DEC": 5.11
  },
  {
    "LONG": -46.18,
    "LAT": -22.59,
    "CIDADE": "Senador Amaral",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 4.59,
    "FEB": 5.14,
    "MAR": 4.82,
    "APR": 5.01,
    "MAY": 4.67,
    "JUN": 4.57,
    "JUL": 4.77,
    "AUG": 5.59,
    "SEP": 5.18,
    "OCT": 5.07,
    "NOV": 4.74,
    "DEC": 4.88
  },
  {
    "LONG": -46.02,
    "LAT": -22.63,
    "CIDADE": "Córrego do Bom Jesus",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 4.72,
    "FEB": 5.29,
    "MAR": 4.95,
    "APR": 5.18,
    "MAY": 4.74,
    "JUN": 4.6,
    "JUL": 4.79,
    "AUG": 5.61,
    "SEP": 5.22,
    "OCT": 5.16,
    "NOV": 4.84,
    "DEC": 4.96
  },
  {
    "LONG": -46.06,
    "LAT": -2.26,
    "CIDADE": "Cambuí",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 4.72,
    "FEB": 5.29,
    "MAR": 4.95,
    "APR": 5.18,
    "MAY": 4.74,
    "JUN": 4.6,
    "JUL": 4.79,
    "AUG": 5.61,
    "SEP": 5.22,
    "OCT": 5.16,
    "NOV": 4.84,
    "DEC": 4.96
  },
  {
    "LONG": -45.78,
    "LAT": -22.55,
    "CIDADE": "Paraisópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.81,
    "FEB": 5.4,
    "MAR": 5.09,
    "APR": 5.21,
    "MAY": 4.73,
    "JUN": 4.61,
    "JUL": 4.79,
    "AUG": 5.58,
    "SEP": 5.27,
    "OCT": 5.24,
    "NOV": 4.93,
    "DEC": 5.11
  },
  {
    "LONG": -46.14,
    "LAT": -2.25,
    "CIDADE": "Bom Repouso",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 4.65,
    "FEB": 5.22,
    "MAR": 4.86,
    "APR": 5.05,
    "MAY": 4.67,
    "JUN": 4.55,
    "JUL": 4.8,
    "AUG": 5.64,
    "SEP": 5.23,
    "OCT": 5.13,
    "NOV": 4.8,
    "DEC": 4.94
  },
  {
    "LONG": -46.02,
    "LAT": -22.46,
    "CIDADE": "Estiva",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 4.64,
    "FEB": 5.26,
    "MAR": 4.87,
    "APR": 5.08,
    "MAY": 4.66,
    "JUN": 4.53,
    "JUL": 4.78,
    "AUG": 5.56,
    "SEP": 5.18,
    "OCT": 5.11,
    "NOV": 4.79,
    "DEC": 4.96
  },
  {
    "LONG": -45.93,
    "LAT": -22.55,
    "CIDADE": "Consolação",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 4.75,
    "FEB": 5.38,
    "MAR": 4.99,
    "APR": 5.23,
    "MAY": 4.73,
    "JUN": 4.58,
    "JUL": 4.82,
    "AUG": 5.61,
    "SEP": 5.25,
    "OCT": 5.22,
    "NOV": 4.89,
    "DEC": 5.05
  },
  {
    "LONG": -45.61,
    "LAT": -22.47,
    "CIDADE": "Brazópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.85,
    "FEB": 5.42,
    "MAR": 5.07,
    "APR": 5.21,
    "MAY": 4.72,
    "JUN": 4.59,
    "JUL": 4.83,
    "AUG": 5.55,
    "SEP": 5.29,
    "OCT": 5.24,
    "NOV": 4.93,
    "DEC": 5.13
  },
  {
    "LONG": -45.49,
    "LAT": -22.53,
    "CIDADE": "Piranguçu",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.86,
    "FEB": 5.43,
    "MAR": 5.12,
    "APR": 5.24,
    "MAY": 4.74,
    "JUN": 4.62,
    "JUL": 4.81,
    "AUG": 5.58,
    "SEP": 5.33,
    "OCT": 5.29,
    "NOV": 4.93,
    "DEC": 5.16
  },
  {
    "LONG": -4.54,
    "LAT": -22.54,
    "CIDADE": "Wenceslau Braz",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.84,
    "FEB": 5.39,
    "MAR": 5.07,
    "APR": 5.16,
    "MAY": 4.73,
    "JUN": 4.65,
    "JUL": 4.82,
    "AUG": 5.6,
    "SEP": 5.32,
    "OCT": 5.28,
    "NOV": 4.9,
    "DEC": 5.05
  },
  {
    "LONG": -45.28,
    "LAT": -22.5,
    "CIDADE": "Delfim Moreira",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 4.71,
    "FEB": 5.18,
    "MAR": 4.83,
    "APR": 4.96,
    "MAY": 4.64,
    "JUN": 4.62,
    "JUL": 4.78,
    "AUG": 5.58,
    "SEP": 5.23,
    "OCT": 5.1,
    "NOV": 4.73,
    "DEC": 4.84
  },
  {
    "LONG": -46.57,
    "LAT": -2.24,
    "CIDADE": "Monte Sião",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 4.92,
    "FEB": 5.41,
    "MAR": 5.24,
    "APR": 5.38,
    "MAY": 4.85,
    "JUN": 4.71,
    "JUL": 4.91,
    "AUG": 5.62,
    "SEP": 5.3,
    "OCT": 5.3,
    "NOV": 5.12,
    "DEC": 5.13
  },
  {
    "LONG": -46.35,
    "LAT": -22.44,
    "CIDADE": "Bueno Brandão",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 4.91,
    "FEB": 5.45,
    "MAR": 5.23,
    "APR": 5.33,
    "MAY": 4.87,
    "JUN": 4.7,
    "JUL": 4.91,
    "AUG": 5.64,
    "SEP": 5.35,
    "OCT": 5.31,
    "NOV": 5.09,
    "DEC": 5.14
  },
  {
    "LONG": -46.1,
    "LAT": -22.37,
    "CIDADE": "Tocos do Moji",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.72,
    "FEB": 5.36,
    "MAR": 5,
    "APR": 5.16,
    "MAY": 4.72,
    "JUN": 4.57,
    "JUL": 4.86,
    "AUG": 5.64,
    "SEP": 5.26,
    "OCT": 5.2,
    "NOV": 4.89,
    "DEC": 5.03
  },
  {
    "LONG": "#VALOR!",
    "LAT": -22.41,
    "CIDADE": "Conceição dos Ouros",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.82,
    "FEB": 5.44,
    "MAR": 5.05,
    "APR": 5.26,
    "MAY": 4.79,
    "JUN": 4.66,
    "JUL": 4.86,
    "AUG": 5.66,
    "SEP": 5.29,
    "OCT": 5.29,
    "NOV": 4.96,
    "DEC": 5.09
  },
  {
    "LONG": -45.78,
    "LAT": -22.35,
    "CIDADE": "Cachoeira de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.89,
    "FEB": 5.41,
    "MAR": 5.1,
    "APR": 5.26,
    "MAY": 4.78,
    "JUN": 4.67,
    "JUL": 4.84,
    "AUG": 5.57,
    "SEP": 5.29,
    "OCT": 5.27,
    "NOV": 4.99,
    "DEC": 5.13
  },
  {
    "LONG": -45.53,
    "LAT": -22.4,
    "CIDADE": "Piranguinho",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 4.89,
    "FEB": 5.45,
    "MAR": 5.13,
    "APR": 5.29,
    "MAY": 4.75,
    "JUN": 4.65,
    "JUL": 4.85,
    "AUG": 5.61,
    "SEP": 5.34,
    "OCT": 5.3,
    "NOV": 4.96,
    "DEC": 5.15
  },
  {
    "LONG": -45.46,
    "LAT": -2.24,
    "CIDADE": "Itajubá",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.87,
    "FEB": 5.42,
    "MAR": 5.11,
    "APR": 5.22,
    "MAY": 4.77,
    "JUN": 4.67,
    "JUL": 4.86,
    "AUG": 5.6,
    "SEP": 5.34,
    "OCT": 5.3,
    "NOV": 4.95,
    "DEC": 5.12
  },
  {
    "LONG": -45.16,
    "LAT": -22.45,
    "CIDADE": "Marmelópolis",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 4.53,
    "FEB": 5.14,
    "MAR": 4.74,
    "APR": 4.92,
    "MAY": 4.62,
    "JUN": 4.54,
    "JUL": 4.75,
    "AUG": 5.59,
    "SEP": 5.26,
    "OCT": 5.06,
    "NOV": 4.59,
    "DEC": 4.8
  },
  {
    "LONG": -44.97,
    "LAT": -22.39,
    "CIDADE": "Passa Quatro",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.82,
    "FEB": 5.44,
    "MAR": 5.08,
    "APR": 5.15,
    "MAY": 4.79,
    "JUN": 4.62,
    "JUL": 4.79,
    "AUG": 5.57,
    "SEP": 5.34,
    "OCT": 5.26,
    "NOV": 4.81,
    "DEC": 5.07
  },
  {
    "LONG": -4.66,
    "LAT": -22.29,
    "CIDADE": "Jacutinga",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.88,
    "FEB": 5.3,
    "MAR": 5.14,
    "APR": 5.3,
    "MAY": 4.83,
    "JUN": 4.74,
    "JUL": 4.92,
    "AUG": 5.67,
    "SEP": 5.3,
    "OCT": 5.28,
    "NOV": 5.03,
    "DEC": 5.08
  },
  {
    "LONG": -4.64,
    "LAT": -22.28,
    "CIDADE": "Ouro Fino",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.79,
    "FEB": 5.32,
    "MAR": 5.09,
    "APR": 5.29,
    "MAY": 4.84,
    "JUN": 4.71,
    "JUL": 4.92,
    "AUG": 5.63,
    "SEP": 5.32,
    "OCT": 5.27,
    "NOV": 5.01,
    "DEC": 5.05
  },
  {
    "LONG": -46.33,
    "LAT": -2.23,
    "CIDADE": "Inconfidentes",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.79,
    "FEB": 5.32,
    "MAR": 5.09,
    "APR": 5.29,
    "MAY": 4.84,
    "JUN": 4.71,
    "JUL": 4.92,
    "AUG": 5.63,
    "SEP": 5.32,
    "OCT": 5.27,
    "NOV": 5.01,
    "DEC": 5.05
  },
  {
    "LONG": -46.17,
    "LAT": -22.27,
    "CIDADE": "Borda da Mata",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.72,
    "FEB": 5.33,
    "MAR": 5.06,
    "APR": 5.21,
    "MAY": 4.81,
    "JUN": 4.61,
    "JUL": 4.91,
    "AUG": 5.66,
    "SEP": 5.34,
    "OCT": 5.25,
    "NOV": 4.96,
    "DEC": 5.03
  },
  {
    "LONG": -45.53,
    "LAT": -22.32,
    "CIDADE": "São José do Alegre",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.82,
    "FEB": 5.36,
    "MAR": 5.04,
    "APR": 5.19,
    "MAY": 4.73,
    "JUN": 4.68,
    "JUL": 4.86,
    "AUG": 5.62,
    "SEP": 5.29,
    "OCT": 5.23,
    "NOV": 4.85,
    "DEC": 5.02
  },
  {
    "LONG": -45.38,
    "LAT": -22.3,
    "CIDADE": "Maria da Fé",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 4.76,
    "FEB": 5.26,
    "MAR": 4.92,
    "APR": 5.06,
    "MAY": 4.71,
    "JUN": 4.66,
    "JUL": 4.86,
    "AUG": 5.63,
    "SEP": 5.29,
    "OCT": 5.16,
    "NOV": 4.78,
    "DEC": 4.91
  },
  {
    "LONG": -45.16,
    "LAT": -22.25,
    "CIDADE": "Dom Viçoso",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 4.59,
    "FEB": 5.25,
    "MAR": 4.83,
    "APR": 4.99,
    "MAY": 4.7,
    "JUN": 4.48,
    "JUL": 4.77,
    "AUG": 5.57,
    "SEP": 5.24,
    "OCT": 5.09,
    "NOV": 4.63,
    "DEC": 4.85
  },
  {
    "LONG": -45.1,
    "LAT": -22.33,
    "CIDADE": "Virgínia",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.79,
    "FEB": 5.45,
    "MAR": 5.08,
    "APR": 5.21,
    "MAY": 4.83,
    "JUN": 4.59,
    "JUL": 4.84,
    "AUG": 5.61,
    "SEP": 5.36,
    "OCT": 5.28,
    "NOV": 4.86,
    "DEC": 5.06
  },
  {
    "LONG": -44.94,
    "LAT": -22.29,
    "CIDADE": "Itanhandu",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 4.96,
    "FEB": 5.55,
    "MAR": 5.2,
    "APR": 5.31,
    "MAY": 4.88,
    "JUN": 4.67,
    "JUL": 4.86,
    "AUG": 5.59,
    "SEP": 5.4,
    "OCT": 5.37,
    "NOV": 4.96,
    "DEC": 5.15
  },
  {
    "LONG": -44.87,
    "LAT": -22.29,
    "CIDADE": "Itamonte",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.87,
    "FEB": 5.41,
    "MAR": 5.12,
    "APR": 5.2,
    "MAY": 4.8,
    "JUN": 4.68,
    "JUL": 4.86,
    "AUG": 5.6,
    "SEP": 5.37,
    "OCT": 5.34,
    "NOV": 4.86,
    "DEC": 5.08
  },
  {
    "LONG": -46.61,
    "LAT": -22.2,
    "CIDADE": "Albertina",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.83,
    "FEB": 5.27,
    "MAR": 5.04,
    "APR": 5.26,
    "MAY": 4.83,
    "JUN": 4.73,
    "JUL": 4.93,
    "AUG": 5.64,
    "SEP": 5.31,
    "OCT": 5.27,
    "NOV": 5.04,
    "DEC": 5.07
  },
  {
    "LONG": -46.18,
    "LAT": -22.16,
    "CIDADE": "Senador José Bento",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 4.62,
    "FEB": 5.21,
    "MAR": 4.92,
    "APR": 5.11,
    "MAY": 4.77,
    "JUN": 4.59,
    "JUL": 4.89,
    "AUG": 5.65,
    "SEP": 5.28,
    "OCT": 5.15,
    "NOV": 4.81,
    "DEC": 4.89
  },
  {
    "LONG": -45.94,
    "LAT": -22.23,
    "CIDADE": "Pouso Alegre",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.76,
    "FEB": 5.31,
    "MAR": 4.99,
    "APR": 5.22,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.91,
    "AUG": 5.67,
    "SEP": 5.33,
    "OCT": 5.2,
    "NOV": 4.91,
    "DEC": 4.97
  },
  {
    "LONG": -45.7,
    "LAT": -22.25,
    "CIDADE": "Santa Rita do Sapucaí",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.86,
    "FEB": 5.45,
    "MAR": 5.08,
    "APR": 5.25,
    "MAY": 4.8,
    "JUN": 4.68,
    "JUL": 4.87,
    "AUG": 5.69,
    "SEP": 5.29,
    "OCT": 5.25,
    "NOV": 4.89,
    "DEC": 5.05
  },
  {
    "LONG": -4.58,
    "LAT": -22.16,
    "CIDADE": "São Sebastião da Bela Vista",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.86,
    "FEB": 5.45,
    "MAR": 5.08,
    "APR": 5.25,
    "MAY": 4.8,
    "JUN": 4.68,
    "JUL": 4.87,
    "AUG": 5.69,
    "SEP": 5.29,
    "OCT": 5.25,
    "NOV": 4.89,
    "DEC": 5.05
  },
  {
    "LONG": -45.46,
    "LAT": -22.16,
    "CIDADE": "Conceição das Pedras",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.79,
    "FEB": 5.34,
    "MAR": 4.93,
    "APR": 5.12,
    "MAY": 4.72,
    "JUN": 4.67,
    "JUL": 4.9,
    "AUG": 5.66,
    "SEP": 5.29,
    "OCT": 5.2,
    "NOV": 4.78,
    "DEC": 4.95
  },
  {
    "LONG": -45.47,
    "LAT": -22.24,
    "CIDADE": "Pedralva",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.79,
    "FEB": 5.34,
    "MAR": 4.93,
    "APR": 5.12,
    "MAY": 4.72,
    "JUN": 4.67,
    "JUL": 4.9,
    "AUG": 5.66,
    "SEP": 5.29,
    "OCT": 5.2,
    "NOV": 4.78,
    "DEC": 4.95
  },
  {
    "LONG": -45.27,
    "LAT": -22.21,
    "CIDADE": "Cristina",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.82,
    "FEB": 5.35,
    "MAR": 5,
    "APR": 5.12,
    "MAY": 4.77,
    "JUN": 4.63,
    "JUL": 4.86,
    "AUG": 5.63,
    "SEP": 5.32,
    "OCT": 5.21,
    "NOV": 4.77,
    "DEC": 4.92
  },
  {
    "LONG": -44.98,
    "LAT": -22.2,
    "CIDADE": "Pouso Alto",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 4.95,
    "FEB": 5.52,
    "MAR": 5.15,
    "APR": 5.3,
    "MAY": 4.88,
    "JUN": 4.66,
    "JUL": 4.87,
    "AUG": 5.61,
    "SEP": 5.38,
    "OCT": 5.36,
    "NOV": 4.9,
    "DEC": 5.08
  },
  {
    "LONG": -44.98,
    "LAT": -22.22,
    "CIDADE": "São Sebastião do Rio Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 4.95,
    "FEB": 5.52,
    "MAR": 5.15,
    "APR": 5.3,
    "MAY": 4.88,
    "JUN": 4.66,
    "JUL": 4.87,
    "AUG": 5.61,
    "SEP": 5.38,
    "OCT": 5.36,
    "NOV": 4.9,
    "DEC": 5.08
  },
  {
    "LONG": -44.64,
    "LAT": -22.17,
    "CIDADE": "Alagoa",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 4.63,
    "FEB": 5.12,
    "MAR": 4.7,
    "APR": 4.9,
    "MAY": 4.61,
    "JUN": 4.59,
    "JUL": 4.78,
    "AUG": 5.53,
    "SEP": 5.18,
    "OCT": 4.89,
    "NOV": 4.43,
    "DEC": 4.68
  },
  {
    "LONG": -44.4,
    "LAT": -22.17,
    "CIDADE": "Bocaina de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.63,
    "FEB": 5.33,
    "MAR": 4.79,
    "APR": 4.75,
    "MAY": 4.45,
    "JUN": 4.46,
    "JUL": 4.45,
    "AUG": 5.3,
    "SEP": 4.9,
    "OCT": 4.65,
    "NOV": 4.26,
    "DEC": 4.78
  },
  {
    "LONG": -44.24,
    "LAT": -22.21,
    "CIDADE": "Passa-Vinte",
    "UF": "MINAS GERAIS",
    "anual": 4.71,
    "JAN": 4.73,
    "FEB": 5.36,
    "MAR": 4.81,
    "APR": 4.72,
    "MAY": 4.39,
    "JUN": 4.44,
    "JUL": 4.41,
    "AUG": 5.25,
    "SEP": 4.82,
    "OCT": 4.59,
    "NOV": 4.27,
    "DEC": 4.73
  },
  {
    "LONG": -46.57,
    "LAT": "#VALOR!",
    "CIDADE": "Andradas",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 4.77,
    "FEB": 5.22,
    "MAR": 4.97,
    "APR": 5.2,
    "MAY": 4.83,
    "JUN": 4.75,
    "JUL": 4.9,
    "AUG": 5.66,
    "SEP": 5.29,
    "OCT": 5.2,
    "NOV": 4.93,
    "DEC": 4.93
  },
  {
    "LONG": -46.44,
    "LAT": -22.06,
    "CIDADE": "Ibitiúra de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.71,
    "FEB": 5.18,
    "MAR": 4.91,
    "APR": 5.15,
    "MAY": 4.79,
    "JUN": 4.75,
    "JUL": 4.92,
    "AUG": 5.69,
    "SEP": 5.3,
    "OCT": 5.16,
    "NOV": 4.88,
    "DEC": 4.88
  },
  {
    "LONG": -46.19,
    "LAT": -22.1,
    "CIDADE": "Ipuiúna",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 4.62,
    "FEB": 5.15,
    "MAR": 4.78,
    "APR": 4.99,
    "MAY": 4.72,
    "JUN": 4.6,
    "JUL": 4.87,
    "AUG": 5.67,
    "SEP": 5.26,
    "OCT": 5.07,
    "NOV": 4.7,
    "DEC": 4.78
  },
  {
    "LONG": -46.04,
    "LAT": -22.15,
    "CIDADE": "Congonhal",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 4.62,
    "FEB": 5.17,
    "MAR": 4.73,
    "APR": 5,
    "MAY": 4.7,
    "JUN": 4.59,
    "JUL": 4.85,
    "AUG": 5.68,
    "SEP": 5.23,
    "OCT": 5.09,
    "NOV": 4.7,
    "DEC": 4.84
  },
  {
    "LONG": -45.55,
    "LAT": -22.06,
    "CIDADE": "Heliodora",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.88,
    "FEB": 5.45,
    "MAR": 5.01,
    "APR": 5.2,
    "MAY": 4.82,
    "JUN": 4.71,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.32,
    "OCT": 5.28,
    "NOV": 4.84,
    "DEC": 5.04
  },
  {
    "LONG": -45.51,
    "LAT": -22.12,
    "CIDADE": "Natércia",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.88,
    "FEB": 5.45,
    "MAR": 5.01,
    "APR": 5.2,
    "MAY": 4.82,
    "JUN": 4.71,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.32,
    "OCT": 5.28,
    "NOV": 4.84,
    "DEC": 5.04
  },
  {
    "LONG": -45.27,
    "LAT": -2.21,
    "CIDADE": "Olímpio Noronha",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 4.83,
    "FEB": 5.42,
    "MAR": 5.01,
    "APR": 5.17,
    "MAY": 4.77,
    "JUN": 4.62,
    "JUL": 4.87,
    "AUG": 5.61,
    "SEP": 5.33,
    "OCT": 5.22,
    "NOV": 4.8,
    "DEC": 4.97
  },
  {
    "LONG": -45.13,
    "LAT": -22.12,
    "CIDADE": "Carmo de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.82,
    "FEB": 5.43,
    "MAR": 5.07,
    "APR": 5.2,
    "MAY": 4.81,
    "JUN": 4.62,
    "JUL": 4.84,
    "AUG": 5.59,
    "SEP": 5.34,
    "OCT": 5.25,
    "NOV": 4.83,
    "DEC": 4.99
  },
  {
    "LONG": -45.05,
    "LAT": -22.06,
    "CIDADE": "Soledade de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.91,
    "FEB": 5.51,
    "MAR": 5.14,
    "APR": 5.26,
    "MAY": 4.82,
    "JUN": 4.66,
    "JUL": 4.85,
    "AUG": 5.6,
    "SEP": 5.39,
    "OCT": 5.32,
    "NOV": 4.93,
    "DEC": 5.06
  },
  {
    "LONG": -4.51,
    "LAT": -22.12,
    "CIDADE": "São Lourenço",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.91,
    "FEB": 5.51,
    "MAR": 5.14,
    "APR": 5.26,
    "MAY": 4.82,
    "JUN": 4.66,
    "JUL": 4.85,
    "AUG": 5.6,
    "SEP": 5.39,
    "OCT": 5.32,
    "NOV": 4.93,
    "DEC": 5.06
  },
  {
    "LONG": -44.1,
    "LAT": -22.15,
    "CIDADE": "Santa Rita de Jacutinga",
    "UF": "MINAS GERAIS",
    "anual": 4.75,
    "JAN": 4.8,
    "FEB": 5.42,
    "MAR": 4.89,
    "APR": 4.8,
    "MAY": 4.39,
    "JUN": 4.43,
    "JUL": 4.44,
    "AUG": 5.21,
    "SEP": 4.83,
    "OCT": 4.64,
    "NOV": 4.34,
    "DEC": 4.79
  },
  {
    "LONG": -43.83,
    "LAT": -22.09,
    "CIDADE": "Rio Preto",
    "UF": "MINAS GERAIS",
    "anual": 4.76,
    "JAN": 4.86,
    "FEB": 5.48,
    "MAR": 4.92,
    "APR": 4.79,
    "MAY": 4.38,
    "JUN": 4.39,
    "JUL": 4.41,
    "AUG": 5.17,
    "SEP": 4.87,
    "OCT": 4.64,
    "NOV": 4.36,
    "DEC": 4.79
  },
  {
    "LONG": -46.34,
    "LAT": -22.03,
    "CIDADE": "Santa Rita de Caldas",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 4.66,
    "FEB": 5.22,
    "MAR": 4.79,
    "APR": 5.02,
    "MAY": 4.75,
    "JUN": 4.66,
    "JUL": 4.9,
    "AUG": 5.71,
    "SEP": 5.28,
    "OCT": 5.1,
    "NOV": 4.71,
    "DEC": 4.84
  },
  {
    "LONG": -45.96,
    "LAT": -22.05,
    "CIDADE": "Espírito Santo do Dourado",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 4.67,
    "FEB": 5.25,
    "MAR": 4.81,
    "APR": 5,
    "MAY": 4.71,
    "JUN": 4.61,
    "JUL": 4.87,
    "AUG": 5.64,
    "SEP": 5.26,
    "OCT": 5.13,
    "NOV": 4.68,
    "DEC": 4.87
  },
  {
    "LONG": -45.84,
    "LAT": -22.03,
    "CIDADE": "Silvianópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.76,
    "FEB": 5.37,
    "MAR": 4.92,
    "APR": 5.13,
    "MAY": 4.78,
    "JUN": 4.68,
    "JUL": 4.89,
    "AUG": 5.7,
    "SEP": 5.29,
    "OCT": 5.18,
    "NOV": 4.75,
    "DEC": 4.93
  },
  {
    "LONG": -45.7,
    "LAT": -22.04,
    "CIDADE": "Careaçu",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.9,
    "FEB": 5.47,
    "MAR": 5.04,
    "APR": 5.24,
    "MAY": 4.81,
    "JUN": 4.71,
    "JUL": 4.89,
    "AUG": 5.68,
    "SEP": 5.32,
    "OCT": 5.27,
    "NOV": 4.84,
    "DEC": 5.02
  },
  {
    "LONG": -45.35,
    "LAT": -21.97,
    "CIDADE": "Lambari",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 4.87,
    "FEB": 5.33,
    "MAR": 4.95,
    "APR": 5.12,
    "MAY": 4.78,
    "JUN": 4.63,
    "JUL": 4.89,
    "AUG": 5.64,
    "SEP": 5.33,
    "OCT": 5.23,
    "NOV": 4.72,
    "DEC": 4.91
  },
  {
    "LONG": -45.29,
    "LAT": -21.99,
    "CIDADE": "Jesuânia",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.91,
    "FEB": 5.41,
    "MAR": 5.05,
    "APR": 5.2,
    "MAY": 4.85,
    "JUN": 4.64,
    "JUL": 4.91,
    "AUG": 5.68,
    "SEP": 5.36,
    "OCT": 5.26,
    "NOV": 4.85,
    "DEC": 4.99
  },
  {
    "LONG": -44.93,
    "LAT": -21.98,
    "CIDADE": "Caxambu",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 4.95,
    "FEB": 5.53,
    "MAR": 5.17,
    "APR": 5.27,
    "MAY": 4.8,
    "JUN": 4.71,
    "JUL": 4.93,
    "AUG": 5.64,
    "SEP": 5.41,
    "OCT": 5.31,
    "NOV": 4.92,
    "DEC": 5.09
  },
  {
    "LONG": -44.89,
    "LAT": -21.96,
    "CIDADE": "Baependi",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 4.96,
    "FEB": 5.54,
    "MAR": 5.21,
    "APR": 5.29,
    "MAY": 4.84,
    "JUN": 4.73,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.46,
    "OCT": 5.3,
    "NOV": 4.91,
    "DEC": 5.07
  },
  {
    "LONG": -44.6,
    "LAT": -21.97,
    "CIDADE": "Aiuruoca",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 4.83,
    "FEB": 5.4,
    "MAR": 5.04,
    "APR": 5.09,
    "MAY": 4.67,
    "JUN": 4.62,
    "JUL": 4.85,
    "AUG": 5.57,
    "SEP": 5.29,
    "OCT": 5.12,
    "NOV": 4.72,
    "DEC": 4.94
  },
  {
    "LONG": -44.46,
    "LAT": -22,
    "CIDADE": "Carvalhos",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 4.89,
    "FEB": 5.48,
    "MAR": 5.06,
    "APR": 5.05,
    "MAY": 4.62,
    "JUN": 4.54,
    "JUL": 4.72,
    "AUG": 5.5,
    "SEP": 5.21,
    "OCT": 5.01,
    "NOV": 4.66,
    "DEC": 4.93
  },
  {
    "LONG": -44.32,
    "LAT": -2.2,
    "CIDADE": "Liberdade",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 4.87,
    "FEB": 5.5,
    "MAR": 5.03,
    "APR": 5.04,
    "MAY": 4.55,
    "JUN": 4.53,
    "JUL": 4.63,
    "AUG": 5.46,
    "SEP": 5.13,
    "OCT": 4.92,
    "NOV": 4.63,
    "DEC": 4.89
  },
  {
    "LONG": -43.7,
    "LAT": -21.96,
    "CIDADE": "Santa Bárbara do Monte Verde",
    "UF": "MINAS GERAIS",
    "anual": 4.72,
    "JAN": 4.83,
    "FEB": 5.47,
    "MAR": 4.88,
    "APR": 4.75,
    "MAY": 4.34,
    "JUN": 4.39,
    "JUL": 4.41,
    "AUG": 5.14,
    "SEP": 4.83,
    "OCT": 4.6,
    "NOV": 4.28,
    "DEC": 4.75
  },
  {
    "LONG": -43.31,
    "LAT": -21.96,
    "CIDADE": "Simão Pereira",
    "UF": "MINAS GERAIS",
    "anual": 4.8,
    "JAN": 5.06,
    "FEB": 5.61,
    "MAR": 5.05,
    "APR": 4.85,
    "MAY": 4.32,
    "JUN": 4.28,
    "JUL": 4.4,
    "AUG": 4.98,
    "SEP": 4.88,
    "OCT": 4.78,
    "NOV": 4.47,
    "DEC": 4.97
  },
  {
    "LONG": -43.16,
    "LAT": -21.95,
    "CIDADE": "Santana do Deserto",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 5.2,
    "FEB": 5.7,
    "MAR": 5.13,
    "APR": 4.91,
    "MAY": 4.32,
    "JUN": 4.31,
    "JUL": 4.42,
    "AUG": 5.01,
    "SEP": 4.88,
    "OCT": 4.8,
    "NOV": 4.5,
    "DEC": 5
  },
  {
    "LONG": -4.31,
    "LAT": -22,
    "CIDADE": "Chiador",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.23,
    "FEB": 5.72,
    "MAR": 5.19,
    "APR": 4.93,
    "MAY": 4.35,
    "JUN": 4.29,
    "JUL": 4.44,
    "AUG": 5.03,
    "SEP": 4.9,
    "OCT": 4.82,
    "NOV": 4.56,
    "DEC": 5.03
  },
  {
    "LONG": -46.38,
    "LAT": -21.92,
    "CIDADE": "Caldas",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 4.72,
    "FEB": 5.27,
    "MAR": 4.85,
    "APR": 5.11,
    "MAY": 4.79,
    "JUN": 4.69,
    "JUL": 4.94,
    "AUG": 5.73,
    "SEP": 5.32,
    "OCT": 5.14,
    "NOV": 4.73,
    "DEC": 4.86
  },
  {
    "LONG": -45.93,
    "LAT": -21.93,
    "CIDADE": "São João da Mata",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 4.76,
    "FEB": 5.33,
    "MAR": 4.88,
    "APR": 5.08,
    "MAY": 4.77,
    "JUN": 4.63,
    "JUL": 4.89,
    "AUG": 5.71,
    "SEP": 5.3,
    "OCT": 5.16,
    "NOV": 4.7,
    "DEC": 4.95
  },
  {
    "LONG": -45.79,
    "LAT": -21.87,
    "CIDADE": "Turvolândia",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.9,
    "FEB": 5.45,
    "MAR": 5.05,
    "APR": 5.18,
    "MAY": 4.83,
    "JUN": 4.75,
    "JUL": 4.93,
    "AUG": 5.68,
    "SEP": 5.33,
    "OCT": 5.26,
    "NOV": 4.83,
    "DEC": 5.02
  },
  {
    "LONG": -45.59,
    "LAT": -21.89,
    "CIDADE": "São Gonçalo do Sapucaí",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.87,
    "FEB": 5.43,
    "MAR": 4.98,
    "APR": 5.21,
    "MAY": 4.79,
    "JUN": 4.7,
    "JUL": 4.89,
    "AUG": 5.65,
    "SEP": 5.34,
    "OCT": 5.29,
    "NOV": 4.79,
    "DEC": 4.97
  },
  {
    "LONG": "#VALOR!",
    "LAT": -21.85,
    "CIDADE": "Cambuquira",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.86,
    "FEB": 5.42,
    "MAR": 5.02,
    "APR": 5.2,
    "MAY": 4.8,
    "JUN": 4.69,
    "JUL": 4.92,
    "AUG": 5.67,
    "SEP": 5.35,
    "OCT": 5.27,
    "NOV": 4.8,
    "DEC": 4.98
  },
  {
    "LONG": -45.09,
    "LAT": -21.88,
    "CIDADE": "Conceição do Rio Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.9,
    "FEB": 5.49,
    "MAR": 5.13,
    "APR": 5.23,
    "MAY": 4.78,
    "JUN": 4.69,
    "JUL": 4.89,
    "AUG": 5.6,
    "SEP": 5.36,
    "OCT": 5.27,
    "NOV": 4.87,
    "DEC": 5.04
  },
  {
    "LONG": -44.51,
    "LAT": -21.89,
    "CIDADE": "Serranos",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 4.94,
    "FEB": 5.44,
    "MAR": 5.07,
    "APR": 5.08,
    "MAY": 4.62,
    "JUN": 4.54,
    "JUL": 4.74,
    "AUG": 5.51,
    "SEP": 5.22,
    "OCT": 5.1,
    "NOV": 4.71,
    "DEC": 4.94
  },
  {
    "LONG": -44.52,
    "LAT": -21.91,
    "CIDADE": "Seritinga",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 4.94,
    "FEB": 5.44,
    "MAR": 5.07,
    "APR": 5.08,
    "MAY": 4.62,
    "JUN": 4.54,
    "JUL": 4.74,
    "AUG": 5.51,
    "SEP": 5.22,
    "OCT": 5.1,
    "NOV": 4.71,
    "DEC": 4.94
  },
  {
    "LONG": -44.26,
    "LAT": -21.91,
    "CIDADE": "Arantina",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 4.91,
    "FEB": 5.5,
    "MAR": 5.02,
    "APR": 4.93,
    "MAY": 4.54,
    "JUN": 4.53,
    "JUL": 4.61,
    "AUG": 5.45,
    "SEP": 5.13,
    "OCT": 4.92,
    "NOV": 4.62,
    "DEC": 4.88
  },
  {
    "LONG": -44.19,
    "LAT": -21.95,
    "CIDADE": "Bom Jardim de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 4.89,
    "FEB": 5.46,
    "MAR": 5,
    "APR": 4.88,
    "MAY": 4.52,
    "JUN": 4.5,
    "JUL": 4.6,
    "AUG": 5.42,
    "SEP": 5.12,
    "OCT": 4.88,
    "NOV": 4.57,
    "DEC": 4.83
  },
  {
    "LONG": -43.94,
    "LAT": -21.86,
    "CIDADE": "Olaria",
    "UF": "MINAS GERAIS",
    "anual": 4.79,
    "JAN": 4.85,
    "FEB": 5.44,
    "MAR": 4.9,
    "APR": 4.8,
    "MAY": 4.41,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 5.28,
    "SEP": 4.99,
    "OCT": 4.71,
    "NOV": 4.4,
    "DEC": 4.79
  },
  {
    "LONG": -43.41,
    "LAT": -21.94,
    "CIDADE": "Belmiro Braga",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.92,
    "FEB": 5.52,
    "MAR": 4.96,
    "APR": 4.72,
    "MAY": 4.29,
    "JUN": 4.26,
    "JUL": 4.37,
    "AUG": 5.02,
    "SEP": 4.81,
    "OCT": 4.71,
    "NOV": 4.38,
    "DEC": 4.84
  },
  {
    "LONG": -43.31,
    "LAT": -21.87,
    "CIDADE": "Matias Barbosa",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 5.03,
    "FEB": 5.59,
    "MAR": 4.99,
    "APR": 4.76,
    "MAY": 4.28,
    "JUN": 4.27,
    "JUL": 4.41,
    "AUG": 5,
    "SEP": 4.85,
    "OCT": 4.76,
    "NOV": 4.4,
    "DEC": 4.87
  },
  {
    "LONG": -43.01,
    "LAT": -21.87,
    "CIDADE": "Mar de Espanha",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5.17,
    "FEB": 5.66,
    "MAR": 5.11,
    "APR": 4.83,
    "MAY": 4.31,
    "JUN": 4.23,
    "JUL": 4.42,
    "AUG": 4.97,
    "SEP": 4.89,
    "OCT": 4.82,
    "NOV": 4.46,
    "DEC": 4.96
  },
  {
    "LONG": -4.27,
    "LAT": -21.88,
    "CIDADE": "Além Paraíba",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.25,
    "FEB": 5.81,
    "MAR": 5.28,
    "APR": 5.05,
    "MAY": 4.41,
    "JUN": 4.3,
    "JUL": 4.44,
    "AUG": 5.05,
    "SEP": 4.95,
    "OCT": 4.82,
    "NOV": 4.51,
    "DEC": 5.07
  },
  {
    "LONG": -46.57,
    "LAT": -21.78,
    "CIDADE": "Poços de Caldas",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.77,
    "FEB": 5.27,
    "MAR": 4.88,
    "APR": 5.14,
    "MAY": 4.81,
    "JUN": 4.74,
    "JUL": 5,
    "AUG": 5.78,
    "SEP": 5.35,
    "OCT": 5.19,
    "NOV": 4.84,
    "DEC": 4.93
  },
  {
    "LONG": -45.97,
    "LAT": -21.78,
    "CIDADE": "Poço Fundo",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.84,
    "FEB": 5.4,
    "MAR": 4.95,
    "APR": 5.13,
    "MAY": 4.82,
    "JUN": 4.7,
    "JUL": 4.92,
    "AUG": 5.74,
    "SEP": 5.35,
    "OCT": 5.18,
    "NOV": 4.76,
    "DEC": 4.98
  },
  {
    "LONG": -45.84,
    "LAT": -2.18,
    "CIDADE": "Carvalhópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.91,
    "FEB": 5.45,
    "MAR": 4.98,
    "APR": 5.18,
    "MAY": 4.84,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.72,
    "SEP": 5.35,
    "OCT": 5.22,
    "NOV": 4.81,
    "DEC": 5
  },
  {
    "LONG": -45.7,
    "LAT": -21.79,
    "CIDADE": "Cordislândia",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 4.93,
    "FEB": 5.46,
    "MAR": 5.04,
    "APR": 5.2,
    "MAY": 4.84,
    "JUN": 4.73,
    "JUL": 4.95,
    "AUG": 5.7,
    "SEP": 5.37,
    "OCT": 5.25,
    "NOV": 4.83,
    "DEC": 5.01
  },
  {
    "LONG": -45.54,
    "LAT": -21.76,
    "CIDADE": "Monsenhor Paulo",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.9,
    "FEB": 5.46,
    "MAR": 5.02,
    "APR": 5.21,
    "MAY": 4.81,
    "JUN": 4.71,
    "JUL": 4.91,
    "AUG": 5.63,
    "SEP": 5.35,
    "OCT": 5.26,
    "NOV": 4.81,
    "DEC": 5.01
  },
  {
    "LONG": -45.4,
    "LAT": -21.84,
    "CIDADE": "Campanha",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.89,
    "FEB": 5.47,
    "MAR": 5.08,
    "APR": 5.21,
    "MAY": 4.82,
    "JUN": 4.7,
    "JUL": 4.9,
    "AUG": 5.65,
    "SEP": 5.37,
    "OCT": 5.27,
    "NOV": 4.83,
    "DEC": 5.04
  },
  {
    "LONG": -44.81,
    "LAT": -21.84,
    "CIDADE": "Cruzília",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.88,
    "FEB": 5.45,
    "MAR": 5.09,
    "APR": 5.15,
    "MAY": 4.72,
    "JUN": 4.62,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.39,
    "OCT": 5.21,
    "NOV": 4.75,
    "DEC": 4.95
  },
  {
    "LONG": -43.79,
    "LAT": -21.84,
    "CIDADE": "Lima Duarte",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.85,
    "FEB": 5.44,
    "MAR": 4.92,
    "APR": 4.71,
    "MAY": 4.33,
    "JUN": 4.26,
    "JUL": 4.42,
    "AUG": 5.15,
    "SEP": 4.88,
    "OCT": 4.69,
    "NOV": 4.34,
    "DEC": 4.84
  },
  {
    "LONG": -43.34,
    "LAT": "#VALOR!",
    "CIDADE": "Juiz de Fora",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.99,
    "FEB": 5.55,
    "MAR": 4.92,
    "APR": 4.67,
    "MAY": 4.25,
    "JUN": 4.23,
    "JUL": 4.38,
    "AUG": 4.99,
    "SEP": 4.85,
    "OCT": 4.72,
    "NOV": 4.36,
    "DEC": 4.84
  },
  {
    "LONG": -43.11,
    "LAT": -21.83,
    "CIDADE": "Pequeri",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.04,
    "APR": 4.74,
    "MAY": 4.26,
    "JUN": 4.22,
    "JUL": 4.41,
    "AUG": 4.96,
    "SEP": 4.88,
    "OCT": 4.75,
    "NOV": 4.36,
    "DEC": 4.85
  },
  {
    "LONG": -42.94,
    "LAT": -2.18,
    "CIDADE": "Senador Cortes",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5.17,
    "FEB": 5.66,
    "MAR": 5.11,
    "APR": 4.81,
    "MAY": 4.32,
    "JUN": 4.21,
    "JUL": 4.41,
    "AUG": 4.98,
    "SEP": 4.93,
    "OCT": 4.83,
    "NOV": 4.41,
    "DEC": 4.96
  },
  {
    "LONG": -42.81,
    "LAT": -21.76,
    "CIDADE": "Santo Antônio do Aventureiro",
    "UF": "MINAS GERAIS",
    "anual": 4.76,
    "JAN": 5.08,
    "FEB": 5.63,
    "MAR": 5.03,
    "APR": 4.78,
    "MAY": 4.26,
    "JUN": 4.16,
    "JUL": 4.35,
    "AUG": 4.91,
    "SEP": 4.88,
    "OCT": 4.75,
    "NOV": 4.32,
    "DEC": 4.92
  },
  {
    "LONG": -42.54,
    "LAT": -21.77,
    "CIDADE": "Volta Grande",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.31,
    "FEB": 5.81,
    "MAR": 5.29,
    "APR": 5.02,
    "MAY": 4.46,
    "JUN": 4.32,
    "JUL": 4.43,
    "AUG": 5.03,
    "SEP": 4.97,
    "OCT": 4.85,
    "NOV": 4.56,
    "DEC": 5.12
  },
  {
    "LONG": -46.38,
    "LAT": -21.73,
    "CIDADE": "Bandeira do Sul",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 4.85,
    "FEB": 5.38,
    "MAR": 5.01,
    "APR": 5.17,
    "MAY": 4.84,
    "JUN": 4.8,
    "JUL": 5.02,
    "AUG": 5.78,
    "SEP": 5.41,
    "OCT": 5.25,
    "NOV": 4.82,
    "DEC": 4.95
  },
  {
    "LONG": -46.24,
    "LAT": -21.71,
    "CIDADE": "Campestre",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.83,
    "FEB": 5.37,
    "MAR": 4.97,
    "APR": 5.15,
    "MAY": 4.83,
    "JUN": 4.72,
    "JUL": 4.99,
    "AUG": 5.79,
    "SEP": 5.41,
    "OCT": 5.23,
    "NOV": 4.8,
    "DEC": 4.95
  },
  {
    "LONG": -45.92,
    "LAT": -21.68,
    "CIDADE": "Machado",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.89,
    "FEB": 5.43,
    "MAR": 4.93,
    "APR": 5.15,
    "MAY": 4.84,
    "JUN": 4.74,
    "JUL": 4.96,
    "AUG": 5.76,
    "SEP": 5.37,
    "OCT": 5.18,
    "NOV": 4.77,
    "DEC": 4.94
  },
  {
    "LONG": -45.25,
    "LAT": -21.69,
    "CIDADE": "Três Corações",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 4.94,
    "FEB": 5.4,
    "MAR": 5.01,
    "APR": 5.23,
    "MAY": 4.77,
    "JUN": 4.66,
    "JUL": 4.9,
    "AUG": 5.63,
    "SEP": 5.39,
    "OCT": 5.27,
    "NOV": 4.79,
    "DEC": 5.02
  },
  {
    "LONG": -44.99,
    "LAT": -21.73,
    "CIDADE": "São Thomé das Letras",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.9,
    "FEB": 5.39,
    "MAR": 5.05,
    "APR": 5.15,
    "MAY": 4.76,
    "JUN": 4.66,
    "JUL": 4.91,
    "AUG": 5.63,
    "SEP": 5.39,
    "OCT": 5.24,
    "NOV": 4.79,
    "DEC": 4.95
  },
  {
    "LONG": -44.61,
    "LAT": -21.68,
    "CIDADE": "Minduri",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 4.96,
    "FEB": 5.48,
    "MAR": 5.04,
    "APR": 5.1,
    "MAY": 4.66,
    "JUN": 4.6,
    "JUL": 4.82,
    "AUG": 5.61,
    "SEP": 5.33,
    "OCT": 5.15,
    "NOV": 4.76,
    "DEC": 4.94
  },
  {
    "LONG": -44.44,
    "LAT": -21.7,
    "CIDADE": "São Vicente de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 4.87,
    "FEB": 5.51,
    "MAR": 5.01,
    "APR": 5,
    "MAY": 4.54,
    "JUN": 4.55,
    "JUL": 4.71,
    "AUG": 5.53,
    "SEP": 5.22,
    "OCT": 5.05,
    "NOV": 4.72,
    "DEC": 4.92
  },
  {
    "LONG": -44.31,
    "LAT": -21.74,
    "CIDADE": "Andrelândia",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 4.91,
    "FEB": 5.56,
    "MAR": 5.07,
    "APR": 4.94,
    "MAY": 4.54,
    "JUN": 4.54,
    "JUL": 4.69,
    "AUG": 5.51,
    "SEP": 5.24,
    "OCT": 5.04,
    "NOV": 4.72,
    "DEC": 4.97
  },
  {
    "LONG": -43.74,
    "LAT": -21.71,
    "CIDADE": "Pedro Teixeira",
    "UF": "MINAS GERAIS",
    "anual": 4.75,
    "JAN": 4.88,
    "FEB": 5.45,
    "MAR": 4.91,
    "APR": 4.7,
    "MAY": 4.34,
    "JUN": 4.31,
    "JUL": 4.42,
    "AUG": 5.16,
    "SEP": 4.9,
    "OCT": 4.71,
    "NOV": 4.31,
    "DEC": 4.85
  },
  {
    "LONG": -43.22,
    "LAT": -21.67,
    "CIDADE": "Chácara",
    "UF": "MINAS GERAIS",
    "anual": 4.75,
    "JAN": 5.05,
    "FEB": 5.59,
    "MAR": 4.96,
    "APR": 4.71,
    "MAY": 4.26,
    "JUN": 4.23,
    "JUL": 4.39,
    "AUG": 4.99,
    "SEP": 4.89,
    "OCT": 4.76,
    "NOV": 4.35,
    "DEC": 4.87
  },
  {
    "LONG": -43.06,
    "LAT": -21.72,
    "CIDADE": "Bicas",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.19,
    "FEB": 5.72,
    "MAR": 5.16,
    "APR": 4.81,
    "MAY": 4.33,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 5.03,
    "SEP": 4.95,
    "OCT": 4.83,
    "NOV": 4.42,
    "DEC": 4.97
  },
  {
    "LONG": -43.03,
    "LAT": -21.73,
    "CIDADE": "Guarará",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.19,
    "FEB": 5.72,
    "MAR": 5.16,
    "APR": 4.81,
    "MAY": 4.33,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 5.03,
    "SEP": 4.95,
    "OCT": 4.83,
    "NOV": 4.42,
    "DEC": 4.97
  },
  {
    "LONG": -4.3,
    "LAT": -21.7,
    "CIDADE": "Maripá de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.19,
    "FEB": 5.71,
    "MAR": 5.13,
    "APR": 4.82,
    "MAY": 4.36,
    "JUN": 4.22,
    "JUL": 4.39,
    "AUG": 5.03,
    "SEP": 4.96,
    "OCT": 4.84,
    "NOV": 4.42,
    "DEC": 5.01
  },
  {
    "LONG": -42.46,
    "LAT": -21.74,
    "CIDADE": "Estrela Dalva",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.32,
    "FEB": 5.77,
    "MAR": 5.26,
    "APR": 4.99,
    "MAY": 4.42,
    "JUN": 4.33,
    "JUL": 4.41,
    "AUG": 5,
    "SEP": 5,
    "OCT": 4.88,
    "NOV": 4.57,
    "DEC": 5.11
  },
  {
    "LONG": -42.34,
    "LAT": -21.66,
    "CIDADE": "Pirapetinga",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.41,
    "FEB": 5.81,
    "MAR": 5.31,
    "APR": 5,
    "MAY": 4.49,
    "JUN": 4.37,
    "JUL": 4.46,
    "AUG": 5.04,
    "SEP": 5.07,
    "OCT": 4.94,
    "NOV": 4.6,
    "DEC": 5.18
  },
  {
    "LONG": -46.39,
    "LAT": -21.64,
    "CIDADE": "Botelhos",
    "UF": "MINAS GERAIS",
    "anual": 5.13,
    "JAN": 4.91,
    "FEB": 5.42,
    "MAR": 5.02,
    "APR": 5.18,
    "MAY": 4.89,
    "JUN": 4.83,
    "JUL": 5.04,
    "AUG": 5.79,
    "SEP": 5.43,
    "OCT": 5.27,
    "NOV": 4.84,
    "DEC": 4.96
  },
  {
    "LONG": -45.57,
    "LAT": -21.61,
    "CIDADE": "Elói Mendes",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 5,
    "FEB": 5.54,
    "MAR": 5.13,
    "APR": 5.24,
    "MAY": 4.86,
    "JUN": 4.75,
    "JUL": 5.02,
    "AUG": 5.72,
    "SEP": 5.45,
    "OCT": 5.28,
    "NOV": 4.86,
    "DEC": 5.05
  },
  {
    "LONG": -45.44,
    "LAT": -21.56,
    "CIDADE": "Varginha",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.95,
    "FEB": 5.46,
    "MAR": 5.05,
    "APR": 5.23,
    "MAY": 4.82,
    "JUN": 4.71,
    "JUL": 4.99,
    "AUG": 5.7,
    "SEP": 5.41,
    "OCT": 5.26,
    "NOV": 4.79,
    "DEC": 5.05
  },
  {
    "LONG": -45.07,
    "LAT": -21.58,
    "CIDADE": "São Bento Abade",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.93,
    "FEB": 5.38,
    "MAR": 4.94,
    "APR": 5.14,
    "MAY": 4.74,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.64,
    "SEP": 5.38,
    "OCT": 5.21,
    "NOV": 4.8,
    "DEC": 4.96
  },
  {
    "LONG": -44.11,
    "LAT": -21.6,
    "CIDADE": "Santana do Garambéu",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.01,
    "FEB": 5.56,
    "MAR": 5.04,
    "APR": 4.98,
    "MAY": 4.54,
    "JUN": 4.55,
    "JUL": 4.72,
    "AUG": 5.53,
    "SEP": 5.25,
    "OCT": 5.01,
    "NOV": 4.66,
    "DEC": 4.98
  },
  {
    "LONG": -43.92,
    "LAT": -21.57,
    "CIDADE": "Santa Rita de Ibitipoca",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 4.88,
    "FEB": 5.48,
    "MAR": 5,
    "APR": 4.85,
    "MAY": 4.53,
    "JUN": 4.52,
    "JUL": 4.65,
    "AUG": 5.43,
    "SEP": 5.14,
    "OCT": 4.94,
    "NOV": 4.51,
    "DEC": 4.92
  },
  {
    "LONG": -43.76,
    "LAT": -21.6,
    "CIDADE": "Bias Fortes",
    "UF": "MINAS GERAIS",
    "anual": 4.75,
    "JAN": 4.85,
    "FEB": 5.45,
    "MAR": 4.9,
    "APR": 4.71,
    "MAY": 4.39,
    "JUN": 4.35,
    "JUL": 4.48,
    "AUG": 5.16,
    "SEP": 4.94,
    "OCT": 4.71,
    "NOV": 4.27,
    "DEC": 4.83
  },
  {
    "LONG": -43.26,
    "LAT": -21.59,
    "CIDADE": "Coronel Pacheco",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5.07,
    "FEB": 5.68,
    "MAR": 5.1,
    "APR": 4.82,
    "MAY": 4.33,
    "JUN": 4.27,
    "JUL": 4.47,
    "AUG": 5.08,
    "SEP": 4.94,
    "OCT": 4.8,
    "NOV": 4.41,
    "DEC": 4.92
  },
  {
    "LONG": -43.02,
    "LAT": -21.63,
    "CIDADE": "Rochedo de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.21,
    "FEB": 5.74,
    "MAR": 5.18,
    "APR": 4.88,
    "MAY": 4.37,
    "JUN": 4.25,
    "JUL": 4.43,
    "AUG": 5.04,
    "SEP": 5,
    "OCT": 4.87,
    "NOV": 4.47,
    "DEC": 5.04
  },
  {
    "LONG": -42.83,
    "LAT": -21.61,
    "CIDADE": "Argirita",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.22,
    "FEB": 5.72,
    "MAR": 5.25,
    "APR": 4.89,
    "MAY": 4.4,
    "JUN": 4.3,
    "JUL": 4.44,
    "AUG": 5.09,
    "SEP": 5.03,
    "OCT": 4.91,
    "NOV": 4.47,
    "DEC": 5.07
  },
  {
    "LONG": -46.39,
    "LAT": -21.47,
    "CIDADE": "Cabo Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.89,
    "FEB": 5.39,
    "MAR": 4.94,
    "APR": 5.18,
    "MAY": 4.84,
    "JUN": 4.75,
    "JUL": 5.04,
    "AUG": 5.85,
    "SEP": 5.4,
    "OCT": 5.22,
    "NOV": 4.8,
    "DEC": 4.9
  },
  {
    "LONG": -46.19,
    "LAT": -21.51,
    "CIDADE": "Divisa Nova",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 5.06,
    "FEB": 5.53,
    "MAR": 5.08,
    "APR": 5.24,
    "MAY": 4.89,
    "JUN": 4.73,
    "JUL": 4.99,
    "AUG": 5.81,
    "SEP": 5.43,
    "OCT": 5.28,
    "NOV": 4.88,
    "DEC": 5.02
  },
  {
    "LONG": -46.04,
    "LAT": -21.54,
    "CIDADE": "Serrania",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.15,
    "FEB": 5.59,
    "MAR": 5.12,
    "APR": 5.26,
    "MAY": 4.89,
    "JUN": 4.69,
    "JUL": 4.99,
    "AUG": 5.77,
    "SEP": 5.44,
    "OCT": 5.32,
    "NOV": 4.92,
    "DEC": 5.07
  },
  {
    "LONG": -45.74,
    "LAT": -2.15,
    "CIDADE": "Paraguaçu",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.09,
    "FEB": 5.57,
    "MAR": 5.13,
    "APR": 5.31,
    "MAY": 4.89,
    "JUN": 4.73,
    "JUL": 4.99,
    "AUG": 5.73,
    "SEP": 5.44,
    "OCT": 5.3,
    "NOV": 4.93,
    "DEC": 5.1
  },
  {
    "LONG": -45.22,
    "LAT": -21.46,
    "CIDADE": "Carmo da Cachoeira",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 4.93,
    "FEB": 5.4,
    "MAR": 4.98,
    "APR": 5.14,
    "MAY": 4.76,
    "JUN": 4.76,
    "JUL": 5.03,
    "AUG": 5.76,
    "SEP": 5.42,
    "OCT": 5.23,
    "NOV": 4.76,
    "DEC": 4.99
  },
  {
    "LONG": -44.9,
    "LAT": -2.15,
    "CIDADE": "Luminárias",
    "UF": "MINAS GERAIS",
    "anual": 5.13,
    "JAN": 5.02,
    "FEB": 5.5,
    "MAR": 5.06,
    "APR": 5.17,
    "MAY": 4.78,
    "JUN": 4.72,
    "JUL": 4.99,
    "AUG": 5.73,
    "SEP": 5.41,
    "OCT": 5.25,
    "NOV": 4.84,
    "DEC": 5.04
  },
  {
    "LONG": -44.64,
    "LAT": -21.49,
    "CIDADE": "Carrancas",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.04,
    "FEB": 5.52,
    "MAR": 5.1,
    "APR": 5.11,
    "MAY": 4.75,
    "JUN": 4.64,
    "JUL": 4.9,
    "AUG": 5.69,
    "SEP": 5.42,
    "OCT": 5.23,
    "NOV": 4.78,
    "DEC": 5
  },
  {
    "LONG": -44.33,
    "LAT": -21.48,
    "CIDADE": "Madre de Deus de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.01,
    "FEB": 5.55,
    "MAR": 5.12,
    "APR": 5.01,
    "MAY": 4.58,
    "JUN": 4.54,
    "JUL": 4.73,
    "AUG": 5.55,
    "SEP": 5.31,
    "OCT": 5.1,
    "NOV": 4.7,
    "DEC": 5.03
  },
  {
    "LONG": -44.19,
    "LAT": -21.47,
    "CIDADE": "Piedade do Rio Grande",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.01,
    "FEB": 5.49,
    "MAR": 5.03,
    "APR": 4.96,
    "MAY": 4.6,
    "JUN": 4.57,
    "JUL": 4.71,
    "AUG": 5.55,
    "SEP": 5.25,
    "OCT": 5.02,
    "NOV": 4.61,
    "DEC": 4.99
  },
  {
    "LONG": -43.51,
    "LAT": -21.55,
    "CIDADE": "Ewbank da Câmara",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.82,
    "FEB": 5.52,
    "MAR": 4.85,
    "APR": 4.67,
    "MAY": 4.28,
    "JUN": 4.31,
    "JUL": 4.47,
    "AUG": 5.12,
    "SEP": 4.94,
    "OCT": 4.71,
    "NOV": 4.29,
    "DEC": 4.82
  },
  {
    "LONG": -43.55,
    "LAT": -21.46,
    "CIDADE": "Santos Dumont",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.82,
    "FEB": 5.52,
    "MAR": 4.85,
    "APR": 4.67,
    "MAY": 4.28,
    "JUN": 4.31,
    "JUL": 4.47,
    "AUG": 5.12,
    "SEP": 4.94,
    "OCT": 4.71,
    "NOV": 4.29,
    "DEC": 4.82
  },
  {
    "LONG": -43.31,
    "LAT": -21.51,
    "CIDADE": "Piau",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 4.92,
    "FEB": 5.61,
    "MAR": 5,
    "APR": 4.74,
    "MAY": 4.31,
    "JUN": 4.26,
    "JUL": 4.41,
    "AUG": 5.06,
    "SEP": 4.93,
    "OCT": 4.74,
    "NOV": 4.32,
    "DEC": 4.89
  },
  {
    "LONG": -43.12,
    "LAT": -21.47,
    "CIDADE": "Rio Novo",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.17,
    "FEB": 5.67,
    "MAR": 5.09,
    "APR": 4.83,
    "MAY": 4.37,
    "JUN": 4.34,
    "JUL": 4.49,
    "AUG": 5.13,
    "SEP": 5.01,
    "OCT": 4.86,
    "NOV": 4.45,
    "DEC": 4.99
  },
  {
    "LONG": -4.32,
    "LAT": -21.54,
    "CIDADE": "Goianá",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.17,
    "FEB": 5.67,
    "MAR": 5.09,
    "APR": 4.83,
    "MAY": 4.37,
    "JUN": 4.34,
    "JUL": 4.49,
    "AUG": 5.13,
    "SEP": 5.01,
    "OCT": 4.86,
    "NOV": 4.45,
    "DEC": 4.99
  },
  {
    "LONG": -43.01,
    "LAT": -21.54,
    "CIDADE": "São João Nepomuceno",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.14,
    "APR": 4.9,
    "MAY": 4.39,
    "JUN": 4.34,
    "JUL": 4.5,
    "AUG": 5.13,
    "SEP": 5.04,
    "OCT": 4.89,
    "NOV": 4.5,
    "DEC": 5.01
  },
  {
    "LONG": -42.96,
    "LAT": -21.46,
    "CIDADE": "Descoberto",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.29,
    "FEB": 5.74,
    "MAR": 5.17,
    "APR": 4.92,
    "MAY": 4.4,
    "JUN": 4.35,
    "JUL": 4.52,
    "AUG": 5.14,
    "SEP": 5.06,
    "OCT": 4.9,
    "NOV": 4.5,
    "DEC": 5.02
  },
  {
    "LONG": -42.64,
    "LAT": -21.53,
    "CIDADE": "Leopoldina",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.36,
    "FEB": 5.91,
    "MAR": 5.38,
    "APR": 5,
    "MAY": 4.52,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.17,
    "SEP": 5.07,
    "OCT": 5,
    "NOV": 4.62,
    "DEC": 5.22
  },
  {
    "LONG": -4.25,
    "LAT": -21.53,
    "CIDADE": "Recreio",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.41,
    "FEB": 5.93,
    "MAR": 5.38,
    "APR": 5.02,
    "MAY": 4.51,
    "JUN": 4.42,
    "JUL": 4.52,
    "AUG": 5.15,
    "SEP": 5.11,
    "OCT": 5.02,
    "NOV": 4.66,
    "DEC": 5.28
  },
  {
    "LONG": -46.94,
    "LAT": -21.36,
    "CIDADE": "Arceburgo",
    "UF": "MINAS GERAIS",
    "anual": 5.27,
    "JAN": 5.02,
    "FEB": 5.51,
    "MAR": 5.21,
    "APR": 5.5,
    "MAY": 5,
    "JUN": 4.93,
    "JUL": 5.11,
    "AUG": 5.84,
    "SEP": 5.37,
    "OCT": 5.37,
    "NOV": 5.17,
    "DEC": 5.17
  },
  {
    "LONG": -46.52,
    "LAT": -21.37,
    "CIDADE": "Muzambinho",
    "UF": "MINAS GERAIS",
    "anual": 5.13,
    "JAN": 4.92,
    "FEB": 5.36,
    "MAR": 4.96,
    "APR": 5.23,
    "MAY": 4.87,
    "JUN": 4.8,
    "JUL": 5.09,
    "AUG": 5.83,
    "SEP": 5.43,
    "OCT": 5.25,
    "NOV": 4.89,
    "DEC": 4.93
  },
  {
    "LONG": -46.14,
    "LAT": -21.36,
    "CIDADE": "Areado",
    "UF": "MINAS GERAIS",
    "anual": 5.15,
    "JAN": 5.04,
    "FEB": 5.52,
    "MAR": 5.07,
    "APR": 5.23,
    "MAY": 4.84,
    "JUN": 4.7,
    "JUL": 5,
    "AUG": 5.83,
    "SEP": 5.41,
    "OCT": 5.28,
    "NOV": 4.84,
    "DEC": 5
  },
  {
    "LONG": -45.95,
    "LAT": -21.43,
    "CIDADE": "Alfenas",
    "UF": "MINAS GERAIS",
    "anual": 5.17,
    "JAN": 5.15,
    "FEB": 5.61,
    "MAR": 5.14,
    "APR": 5.26,
    "MAY": 4.84,
    "JUN": 4.65,
    "JUL": 4.97,
    "AUG": 5.71,
    "SEP": 5.42,
    "OCT": 5.33,
    "NOV": 4.92,
    "DEC": 5.09
  },
  {
    "LONG": -4.58,
    "LAT": -21.41,
    "CIDADE": "Fama",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.11,
    "FEB": 5.61,
    "MAR": 5.16,
    "APR": 5.27,
    "MAY": 4.87,
    "JUN": 4.7,
    "JUL": 5.01,
    "AUG": 5.68,
    "SEP": 5.41,
    "OCT": 5.31,
    "NOV": 4.91,
    "DEC": 5.13
  },
  {
    "LONG": -45.51,
    "LAT": -21.37,
    "CIDADE": "Três Pontas",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.03,
    "FEB": 5.48,
    "MAR": 5.01,
    "APR": 5.18,
    "MAY": 4.87,
    "JUN": 4.79,
    "JUL": 5.05,
    "AUG": 5.8,
    "SEP": 5.45,
    "OCT": 5.27,
    "NOV": 4.78,
    "DEC": 4.97
  },
  {
    "LONG": -44.92,
    "LAT": -21.4,
    "CIDADE": "Ingaí",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.02,
    "FEB": 5.49,
    "MAR": 5.08,
    "APR": 5.23,
    "MAY": 4.79,
    "JUN": 4.76,
    "JUL": 5,
    "AUG": 5.75,
    "SEP": 5.44,
    "OCT": 5.27,
    "NOV": 4.83,
    "DEC": 5.03
  },
  {
    "LONG": -43.96,
    "LAT": -21.43,
    "CIDADE": "Ibertioga",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 4.98,
    "FEB": 5.53,
    "MAR": 4.99,
    "APR": 4.9,
    "MAY": 4.56,
    "JUN": 4.54,
    "JUL": 4.69,
    "AUG": 5.47,
    "SEP": 5.22,
    "OCT": 4.98,
    "NOV": 4.57,
    "DEC": 4.96
  },
  {
    "LONG": -43.24,
    "LAT": -21.36,
    "CIDADE": "Tabuleiro",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5,
    "FEB": 5.64,
    "MAR": 5.02,
    "APR": 4.74,
    "MAY": 4.35,
    "JUN": 4.32,
    "JUL": 4.45,
    "AUG": 5.12,
    "SEP": 4.99,
    "OCT": 4.85,
    "NOV": 4.38,
    "DEC": 4.99
  },
  {
    "LONG": -43.03,
    "LAT": -21.36,
    "CIDADE": "Guarani",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.22,
    "FEB": 5.73,
    "MAR": 5.13,
    "APR": 4.9,
    "MAY": 4.42,
    "JUN": 4.38,
    "JUL": 4.51,
    "AUG": 5.16,
    "SEP": 5.07,
    "OCT": 4.92,
    "NOV": 4.49,
    "DEC": 5.02
  },
  {
    "LONG": -42.81,
    "LAT": -21.42,
    "CIDADE": "Itamarati de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.26,
    "FEB": 5.77,
    "MAR": 5.22,
    "APR": 4.86,
    "MAY": 4.4,
    "JUN": 4.35,
    "JUL": 4.51,
    "AUG": 5.14,
    "SEP": 5.05,
    "OCT": 4.94,
    "NOV": 4.52,
    "DEC": 5.05
  },
  {
    "LONG": "#VALOR!",
    "LAT": -21.39,
    "CIDADE": "Cataguases",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.35,
    "FEB": 5.85,
    "MAR": 5.29,
    "APR": 4.98,
    "MAY": 4.49,
    "JUN": 4.42,
    "JUL": 4.53,
    "AUG": 5.19,
    "SEP": 5.1,
    "OCT": 4.98,
    "NOV": 4.6,
    "DEC": 5.19
  },
  {
    "LONG": -42.47,
    "LAT": -2.14,
    "CIDADE": "Laranjal",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.38,
    "FEB": 5.88,
    "MAR": 5.31,
    "APR": 5,
    "MAY": 4.5,
    "JUN": 4.4,
    "JUL": 4.51,
    "AUG": 5.15,
    "SEP": 5.1,
    "OCT": 4.99,
    "NOV": 4.57,
    "DEC": 5.21
  },
  {
    "LONG": -42.31,
    "LAT": -21.38,
    "CIDADE": "Palma",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.38,
    "FEB": 5.84,
    "MAR": 5.25,
    "APR": 4.97,
    "MAY": 4.48,
    "JUN": 4.38,
    "JUL": 4.48,
    "AUG": 5.16,
    "SEP": 5.11,
    "OCT": 5,
    "NOV": 4.54,
    "DEC": 5.2
  },
  {
    "LONG": -46.71,
    "LAT": -21.31,
    "CIDADE": "Guaxupé",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 4.96,
    "FEB": 5.39,
    "MAR": 5.02,
    "APR": 5.37,
    "MAY": 4.98,
    "JUN": 4.94,
    "JUL": 5.14,
    "AUG": 5.91,
    "SEP": 5.44,
    "OCT": 5.29,
    "NOV": 4.94,
    "DEC": 4.98
  },
  {
    "LONG": -46.8,
    "LAT": -21.3,
    "CIDADE": "Guaranésia",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 4.96,
    "FEB": 5.39,
    "MAR": 5.02,
    "APR": 5.37,
    "MAY": 4.98,
    "JUN": 4.94,
    "JUL": 5.14,
    "AUG": 5.91,
    "SEP": 5.44,
    "OCT": 5.29,
    "NOV": 4.94,
    "DEC": 4.98
  },
  {
    "LONG": -46.36,
    "LAT": -21.33,
    "CIDADE": "Monte Belo",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 4.91,
    "FEB": 5.44,
    "MAR": 4.95,
    "APR": 5.18,
    "MAY": 4.81,
    "JUN": 4.71,
    "JUL": 5.02,
    "AUG": 5.88,
    "SEP": 5.43,
    "OCT": 5.26,
    "NOV": 4.81,
    "DEC": 4.92
  },
  {
    "LONG": -44.87,
    "LAT": -21.32,
    "CIDADE": "Itumirim",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.13,
    "FEB": 5.6,
    "MAR": 5.19,
    "APR": 5.25,
    "MAY": 4.78,
    "JUN": 4.7,
    "JUL": 4.98,
    "AUG": 5.78,
    "SEP": 5.46,
    "OCT": 5.29,
    "NOV": 4.84,
    "DEC": 5.1
  },
  {
    "LONG": -44.66,
    "LAT": -21.3,
    "CIDADE": "Itutinga",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.11,
    "FEB": 5.55,
    "MAR": 5.17,
    "APR": 5.2,
    "MAY": 4.76,
    "JUN": 4.64,
    "JUL": 4.86,
    "AUG": 5.7,
    "SEP": 5.44,
    "OCT": 5.25,
    "NOV": 4.78,
    "DEC": 5.03
  },
  {
    "LONG": -43.75,
    "LAT": -21.32,
    "CIDADE": "Antônio Carlos",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 4.87,
    "FEB": 5.46,
    "MAR": 4.92,
    "APR": 4.78,
    "MAY": 4.43,
    "JUN": 4.44,
    "JUL": 4.62,
    "AUG": 5.39,
    "SEP": 5.14,
    "OCT": 4.93,
    "NOV": 4.45,
    "DEC": 4.93
  },
  {
    "LONG": -43.41,
    "LAT": -21.29,
    "CIDADE": "Paiva",
    "UF": "MINAS GERAIS",
    "anual": 4.79,
    "JAN": 4.92,
    "FEB": 5.53,
    "MAR": 4.98,
    "APR": 4.73,
    "MAY": 4.35,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.19,
    "SEP": 4.99,
    "OCT": 4.78,
    "NOV": 4.31,
    "DEC": 4.86
  },
  {
    "LONG": -43.45,
    "LAT": -21.34,
    "CIDADE": "Oliveira Fortes",
    "UF": "MINAS GERAIS",
    "anual": 4.79,
    "JAN": 4.92,
    "FEB": 5.53,
    "MAR": 4.98,
    "APR": 4.73,
    "MAY": 4.35,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.19,
    "SEP": 4.99,
    "OCT": 4.78,
    "NOV": 4.31,
    "DEC": 4.86
  },
  {
    "LONG": -4.34,
    "LAT": -21.35,
    "CIDADE": "Aracitaba",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 4.99,
    "FEB": 5.58,
    "MAR": 5.01,
    "APR": 4.78,
    "MAY": 4.37,
    "JUN": 4.36,
    "JUL": 4.48,
    "AUG": 5.15,
    "SEP": 4.99,
    "OCT": 4.86,
    "NOV": 4.38,
    "DEC": 4.91
  },
  {
    "LONG": "#VALOR!",
    "LAT": -21.27,
    "CIDADE": "Rio Pomba",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.13,
    "FEB": 5.65,
    "MAR": 5.07,
    "APR": 4.84,
    "MAY": 4.41,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.17,
    "SEP": 5.08,
    "OCT": 4.9,
    "NOV": 4.46,
    "DEC": 4.94
  },
  {
    "LONG": -43.02,
    "LAT": -2.13,
    "CIDADE": "Piraúba",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.19,
    "FEB": 5.72,
    "MAR": 5.14,
    "APR": 4.9,
    "MAY": 4.44,
    "JUN": 4.41,
    "JUL": 4.56,
    "AUG": 5.21,
    "SEP": 5.11,
    "OCT": 4.96,
    "NOV": 4.52,
    "DEC": 5
  },
  {
    "LONG": -42.81,
    "LAT": -21.32,
    "CIDADE": "Dona Eusébia",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.3,
    "FEB": 5.83,
    "MAR": 5.29,
    "APR": 4.99,
    "MAY": 4.48,
    "JUN": 4.44,
    "JUL": 4.59,
    "AUG": 5.21,
    "SEP": 5.17,
    "OCT": 5.06,
    "NOV": 4.64,
    "DEC": 5.11
  },
  {
    "LONG": -42.86,
    "LAT": -21.32,
    "CIDADE": "Astolfo Dutra",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.3,
    "FEB": 5.83,
    "MAR": 5.29,
    "APR": 4.99,
    "MAY": 4.48,
    "JUN": 4.44,
    "JUL": 4.59,
    "AUG": 5.21,
    "SEP": 5.17,
    "OCT": 5.06,
    "NOV": 4.64,
    "DEC": 5.11
  },
  {
    "LONG": -42.55,
    "LAT": -21.29,
    "CIDADE": "Santana de Cataguases",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.33,
    "FEB": 5.82,
    "MAR": 5.18,
    "APR": 4.92,
    "MAY": 4.46,
    "JUN": 4.4,
    "JUL": 4.53,
    "AUG": 5.19,
    "SEP": 5.1,
    "OCT": 4.96,
    "NOV": 4.52,
    "DEC": 5.1
  },
  {
    "LONG": -46.98,
    "LAT": -21.19,
    "CIDADE": "Monte Santo de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 4.89,
    "FEB": 5.41,
    "MAR": 5.01,
    "APR": 5.33,
    "MAY": 4.99,
    "JUN": 4.93,
    "JUL": 5.15,
    "AUG": 5.91,
    "SEP": 5.42,
    "OCT": 5.27,
    "NOV": 5,
    "DEC": 5.02
  },
  {
    "LONG": -46.57,
    "LAT": -21.25,
    "CIDADE": "Juruaia",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 4.91,
    "FEB": 5.35,
    "MAR": 4.91,
    "APR": 5.19,
    "MAY": 4.86,
    "JUN": 4.78,
    "JUL": 5.09,
    "AUG": 5.91,
    "SEP": 5.42,
    "OCT": 5.22,
    "NOV": 4.82,
    "DEC": 4.89
  },
  {
    "LONG": -46.14,
    "LAT": -21.25,
    "CIDADE": "Alterosa",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 4.99,
    "FEB": 5.55,
    "MAR": 4.99,
    "APR": 5.2,
    "MAY": 4.84,
    "JUN": 4.76,
    "JUL": 5.05,
    "AUG": 5.9,
    "SEP": 5.45,
    "OCT": 5.28,
    "NOV": 4.8,
    "DEC": 4.92
  },
  {
    "LONG": -45.76,
    "LAT": -21.24,
    "CIDADE": "Campos Gerais",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.07,
    "FEB": 5.54,
    "MAR": 5.08,
    "APR": 5.25,
    "MAY": 4.91,
    "JUN": 4.84,
    "JUL": 5.12,
    "AUG": 5.83,
    "SEP": 5.48,
    "OCT": 5.33,
    "NOV": 4.85,
    "DEC": 5.02
  },
  {
    "LONG": -45.5,
    "LAT": -21.25,
    "CIDADE": "Santana da Vargem",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.05,
    "FEB": 5.56,
    "MAR": 5.08,
    "APR": 5.29,
    "MAY": 4.87,
    "JUN": 4.82,
    "JUL": 5.1,
    "AUG": 5.87,
    "SEP": 5.5,
    "OCT": 5.32,
    "NOV": 4.82,
    "DEC": 5
  },
  {
    "LONG": -4.54,
    "LAT": -21.19,
    "CIDADE": "Coqueiral",
    "UF": "MINAS GERAIS",
    "anual": 5.21,
    "JAN": 5.06,
    "FEB": 5.56,
    "MAR": 5.09,
    "APR": 5.33,
    "MAY": 4.93,
    "JUN": 4.82,
    "JUL": 5.11,
    "AUG": 5.85,
    "SEP": 5.52,
    "OCT": 5.35,
    "NOV": 4.83,
    "DEC": 5.07
  },
  {
    "LONG": -45.24,
    "LAT": -21.23,
    "CIDADE": "Nepomuceno",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.12,
    "FEB": 5.57,
    "MAR": 5.15,
    "APR": 5.36,
    "MAY": 4.91,
    "JUN": 4.81,
    "JUL": 5.08,
    "AUG": 5.87,
    "SEP": 5.51,
    "OCT": 5.34,
    "NOV": 4.89,
    "DEC": 5.07
  },
  {
    "LONG": -45.06,
    "LAT": -21.19,
    "CIDADE": "Ribeirão Vermelho",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.19,
    "APR": 5.34,
    "MAY": 4.89,
    "JUN": 4.81,
    "JUL": 5.07,
    "AUG": 5.83,
    "SEP": 5.54,
    "OCT": 5.31,
    "NOV": 4.83,
    "DEC": 5.08
  },
  {
    "LONG": -45,
    "LAT": -21.25,
    "CIDADE": "Lavras",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.19,
    "APR": 5.34,
    "MAY": 4.89,
    "JUN": 4.81,
    "JUL": 5.07,
    "AUG": 5.83,
    "SEP": 5.54,
    "OCT": 5.31,
    "NOV": 4.83,
    "DEC": 5.08
  },
  {
    "LONG": -44.92,
    "LAT": -21.17,
    "CIDADE": "Ijaci",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.07,
    "FEB": 5.53,
    "MAR": 5.19,
    "APR": 5.29,
    "MAY": 4.83,
    "JUN": 4.76,
    "JUL": 5.01,
    "AUG": 5.78,
    "SEP": 5.51,
    "OCT": 5.28,
    "NOV": 4.84,
    "DEC": 5.05
  },
  {
    "LONG": -44.75,
    "LAT": -21.15,
    "CIDADE": "Ibituruna",
    "UF": "MINAS GERAIS",
    "anual": 5.15,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.17,
    "APR": 5.25,
    "MAY": 4.8,
    "JUN": 4.72,
    "JUL": 4.95,
    "AUG": 5.79,
    "SEP": 5.47,
    "OCT": 5.26,
    "NOV": 4.79,
    "DEC": 5.02
  },
  {
    "LONG": -44.61,
    "LAT": -21.22,
    "CIDADE": "Nazareno",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 5.05,
    "FEB": 5.53,
    "MAR": 5.12,
    "APR": 5.2,
    "MAY": 4.74,
    "JUN": 4.65,
    "JUL": 4.87,
    "AUG": 5.74,
    "SEP": 5.46,
    "OCT": 5.2,
    "NOV": 4.74,
    "DEC": 4.98
  },
  {
    "LONG": -43.97,
    "LAT": -21.19,
    "CIDADE": "Barroso",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5,
    "FEB": 5.55,
    "MAR": 5.03,
    "APR": 4.91,
    "MAY": 4.56,
    "JUN": 4.58,
    "JUL": 4.73,
    "AUG": 5.54,
    "SEP": 5.24,
    "OCT": 5.04,
    "NOV": 4.58,
    "DEC": 4.96
  },
  {
    "LONG": -43.77,
    "LAT": -2.12,
    "CIDADE": "Alfredo Vasconcelos",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 4.94,
    "FEB": 5.51,
    "MAR": 4.96,
    "APR": 4.83,
    "MAY": 4.49,
    "JUN": 4.48,
    "JUL": 4.67,
    "AUG": 5.44,
    "SEP": 5.18,
    "OCT": 4.98,
    "NOV": 4.49,
    "DEC": 4.92
  },
  {
    "LONG": -43.77,
    "LAT": -21.22,
    "CIDADE": "Barbacena",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 4.94,
    "FEB": 5.51,
    "MAR": 4.96,
    "APR": 4.83,
    "MAY": 4.49,
    "JUN": 4.48,
    "JUL": 4.67,
    "AUG": 5.44,
    "SEP": 5.18,
    "OCT": 4.98,
    "NOV": 4.49,
    "DEC": 4.92
  },
  {
    "LONG": -43.56,
    "LAT": -21.24,
    "CIDADE": "Santa Bárbara do Tugúrio",
    "UF": "MINAS GERAIS",
    "anual": 4.73,
    "JAN": 4.81,
    "FEB": 5.39,
    "MAR": 4.82,
    "APR": 4.7,
    "MAY": 4.27,
    "JUN": 4.31,
    "JUL": 4.48,
    "AUG": 5.21,
    "SEP": 4.99,
    "OCT": 4.74,
    "NOV": 4.23,
    "DEC": 4.82
  },
  {
    "LONG": -4.33,
    "LAT": -21.2,
    "CIDADE": "Mercês",
    "UF": "MINAS GERAIS",
    "anual": 4.81,
    "JAN": 4.97,
    "FEB": 5.56,
    "MAR": 4.95,
    "APR": 4.76,
    "MAY": 4.36,
    "JUN": 4.38,
    "JUL": 4.47,
    "AUG": 5.16,
    "SEP": 5,
    "OCT": 4.8,
    "NOV": 4.36,
    "DEC": 4.89
  },
  {
    "LONG": -43.21,
    "LAT": -2.12,
    "CIDADE": "Silveirânia",
    "UF": "MINAS GERAIS",
    "anual": 4.8,
    "JAN": 4.98,
    "FEB": 5.56,
    "MAR": 4.95,
    "APR": 4.75,
    "MAY": 4.34,
    "JUN": 4.36,
    "JUL": 4.46,
    "AUG": 5.14,
    "SEP": 5.02,
    "OCT": 4.8,
    "NOV": 4.35,
    "DEC": 4.88
  },
  {
    "LONG": -43.01,
    "LAT": -21.18,
    "CIDADE": "Tocantins",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.12,
    "FEB": 5.68,
    "MAR": 5.07,
    "APR": 4.86,
    "MAY": 4.4,
    "JUN": 4.39,
    "JUL": 4.56,
    "AUG": 5.21,
    "SEP": 5.1,
    "OCT": 4.91,
    "NOV": 4.47,
    "DEC": 4.95
  },
  {
    "LONG": -4.29,
    "LAT": -21.2,
    "CIDADE": "Rodeiro",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.31,
    "FEB": 5.83,
    "MAR": 5.25,
    "APR": 4.99,
    "MAY": 4.5,
    "JUN": 4.46,
    "JUL": 4.62,
    "AUG": 5.25,
    "SEP": 5.19,
    "OCT": 5.06,
    "NOV": 4.63,
    "DEC": 5.1
  },
  {
    "LONG": -42.79,
    "LAT": -21.16,
    "CIDADE": "Guidoval",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.24,
    "FEB": 5.78,
    "MAR": 5.19,
    "APR": 4.98,
    "MAY": 4.49,
    "JUN": 4.46,
    "JUL": 4.58,
    "AUG": 5.25,
    "SEP": 5.22,
    "OCT": 5.05,
    "NOV": 4.61,
    "DEC": 5.1
  },
  {
    "LONG": -42.61,
    "LAT": -21.2,
    "CIDADE": "Miraí",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.16,
    "FEB": 5.69,
    "MAR": 5.07,
    "APR": 4.88,
    "MAY": 4.44,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.19,
    "SEP": 5.12,
    "OCT": 4.95,
    "NOV": 4.5,
    "DEC": 5.01
  },
  {
    "LONG": -42.21,
    "LAT": -21.15,
    "CIDADE": "Patrocínio do Muriaé",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 5.48,
    "FEB": 6.02,
    "MAR": 5.38,
    "APR": 5.11,
    "MAY": 4.64,
    "JUN": 4.55,
    "JUL": 4.6,
    "AUG": 5.27,
    "SEP": 5.24,
    "OCT": 5.09,
    "NOV": 4.67,
    "DEC": 5.28
  },
  {
    "LONG": -42.24,
    "LAT": -21.24,
    "CIDADE": "Barão de Monte Alto",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 5.48,
    "FEB": 6.02,
    "MAR": 5.38,
    "APR": 5.11,
    "MAY": 4.64,
    "JUN": 4.55,
    "JUL": 4.6,
    "AUG": 5.27,
    "SEP": 5.24,
    "OCT": 5.09,
    "NOV": 4.67,
    "DEC": 5.28
  },
  {
    "LONG": -47.05,
    "LAT": -21.08,
    "CIDADE": "Itamogi",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 4.99,
    "FEB": 5.43,
    "MAR": 5.08,
    "APR": 5.32,
    "MAY": 5.07,
    "JUN": 4.99,
    "JUL": 5.22,
    "AUG": 5.91,
    "SEP": 5.42,
    "OCT": 5.36,
    "NOV": 5.08,
    "DEC": 5.1
  },
  {
    "LONG": -46.61,
    "LAT": -21.13,
    "CIDADE": "São Pedro da União",
    "UF": "MINAS GERAIS",
    "anual": 5.17,
    "JAN": 4.89,
    "FEB": 5.44,
    "MAR": 4.99,
    "APR": 5.21,
    "MAY": 4.93,
    "JUN": 4.9,
    "JUL": 5.14,
    "AUG": 5.92,
    "SEP": 5.45,
    "OCT": 5.3,
    "NOV": 4.89,
    "DEC": 4.92
  },
  {
    "LONG": -46.42,
    "LAT": -21.13,
    "CIDADE": "Nova Resende",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 4.92,
    "FEB": 5.38,
    "MAR": 4.91,
    "APR": 5.15,
    "MAY": 4.9,
    "JUN": 4.87,
    "JUL": 5.13,
    "AUG": 5.95,
    "SEP": 5.48,
    "OCT": 5.28,
    "NOV": 4.83,
    "DEC": 4.92
  },
  {
    "LONG": -46.21,
    "LAT": -21.1,
    "CIDADE": "Conceição da Aparecida",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.93,
    "FEB": 5.48,
    "MAR": 4.91,
    "APR": 5.15,
    "MAY": 4.84,
    "JUN": 4.78,
    "JUL": 5.1,
    "AUG": 5.92,
    "SEP": 5.46,
    "OCT": 5.24,
    "NOV": 4.76,
    "DEC": 4.88
  },
  {
    "LONG": -45.83,
    "LAT": -21.11,
    "CIDADE": "Campo do Meio",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 5.07,
    "FEB": 5.61,
    "MAR": 5.1,
    "APR": 5.34,
    "MAY": 4.92,
    "JUN": 4.77,
    "JUL": 5.09,
    "AUG": 5.71,
    "SEP": 5.45,
    "OCT": 5.36,
    "NOV": 4.92,
    "DEC": 5.1
  },
  {
    "LONG": -45.56,
    "LAT": -21.09,
    "CIDADE": "Boa Esperança",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 4.99,
    "FEB": 5.58,
    "MAR": 5.09,
    "APR": 5.3,
    "MAY": 4.94,
    "JUN": 4.85,
    "JUL": 5.13,
    "AUG": 5.87,
    "SEP": 5.48,
    "OCT": 5.32,
    "NOV": 4.83,
    "DEC": 5
  },
  {
    "LONG": "#VALOR!",
    "LAT": -21.09,
    "CIDADE": "Perdões",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.13,
    "APR": 5.35,
    "MAY": 4.88,
    "JUN": 4.8,
    "JUL": 5.08,
    "AUG": 5.82,
    "SEP": 5.52,
    "OCT": 5.28,
    "NOV": 4.76,
    "DEC": 5.02
  },
  {
    "LONG": -44.47,
    "LAT": -2.11,
    "CIDADE": "Conceição da Barra de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 4.97,
    "FEB": 5.55,
    "MAR": 5.1,
    "APR": 5.14,
    "MAY": 4.72,
    "JUN": 4.63,
    "JUL": 4.87,
    "AUG": 5.72,
    "SEP": 5.43,
    "OCT": 5.16,
    "NOV": 4.68,
    "DEC": 4.95
  },
  {
    "LONG": -44.22,
    "LAT": -21.12,
    "CIDADE": "Santa Cruz de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.02,
    "FEB": 5.51,
    "MAR": 5.05,
    "APR": 5,
    "MAY": 4.62,
    "JUN": 4.58,
    "JUL": 4.83,
    "AUG": 5.65,
    "SEP": 5.36,
    "OCT": 5.12,
    "NOV": 4.62,
    "DEC": 4.96
  },
  {
    "LONG": -4.43,
    "LAT": -21.13,
    "CIDADE": "São João del Rei",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.02,
    "FEB": 5.51,
    "MAR": 5.05,
    "APR": 5,
    "MAY": 4.62,
    "JUN": 4.58,
    "JUL": 4.83,
    "AUG": 5.65,
    "SEP": 5.36,
    "OCT": 5.12,
    "NOV": 4.62,
    "DEC": 4.96
  },
  {
    "LONG": -44.17,
    "LAT": -21.11,
    "CIDADE": "Tiradentes",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.01,
    "FEB": 5.53,
    "MAR": 5,
    "APR": 4.97,
    "MAY": 4.61,
    "JUN": 4.59,
    "JUL": 4.81,
    "AUG": 5.62,
    "SEP": 5.33,
    "OCT": 5.1,
    "NOV": 4.61,
    "DEC": 4.96
  },
  {
    "LONG": -44.02,
    "LAT": -21.11,
    "CIDADE": "Dores de Campos",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 4.99,
    "APR": 4.95,
    "MAY": 4.59,
    "JUN": 4.56,
    "JUL": 4.79,
    "AUG": 5.6,
    "SEP": 5.28,
    "OCT": 5.05,
    "NOV": 4.59,
    "DEC": 4.91
  },
  {
    "LONG": -44.08,
    "LAT": -21.06,
    "CIDADE": "Prados",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 4.99,
    "APR": 4.95,
    "MAY": 4.59,
    "JUN": 4.56,
    "JUL": 4.79,
    "AUG": 5.6,
    "SEP": 5.28,
    "OCT": 5.05,
    "NOV": 4.59,
    "DEC": 4.91
  },
  {
    "LONG": -43.76,
    "LAT": -21.06,
    "CIDADE": "Ressaquinha",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.02,
    "FEB": 5.57,
    "MAR": 4.97,
    "APR": 4.87,
    "MAY": 4.52,
    "JUN": 4.51,
    "JUL": 4.74,
    "AUG": 5.52,
    "SEP": 5.21,
    "OCT": 5.03,
    "NOV": 4.51,
    "DEC": 4.94
  },
  {
    "LONG": -43.52,
    "LAT": -21.14,
    "CIDADE": "Desterro do Melo",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 4.84,
    "FEB": 5.47,
    "MAR": 4.87,
    "APR": 4.71,
    "MAY": 4.33,
    "JUN": 4.29,
    "JUL": 4.47,
    "AUG": 5.24,
    "SEP": 5.06,
    "OCT": 4.82,
    "NOV": 4.28,
    "DEC": 4.88
  },
  {
    "LONG": -42.94,
    "LAT": -21.12,
    "CIDADE": "Ubá",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.1,
    "FEB": 5.73,
    "MAR": 5.09,
    "APR": 4.86,
    "MAY": 4.45,
    "JUN": 4.44,
    "JUL": 4.57,
    "AUG": 5.26,
    "SEP": 5.12,
    "OCT": 4.95,
    "NOV": 4.47,
    "DEC": 4.98
  },
  {
    "LONG": -4.26,
    "LAT": -21.07,
    "CIDADE": "São Sebastião da Vargem Alegre",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.18,
    "FEB": 5.75,
    "MAR": 5.16,
    "APR": 4.91,
    "MAY": 4.5,
    "JUN": 4.47,
    "JUL": 4.61,
    "AUG": 5.27,
    "SEP": 5.24,
    "OCT": 5.02,
    "NOV": 4.57,
    "DEC": 5.08
  },
  {
    "LONG": -42.37,
    "LAT": -21.13,
    "CIDADE": "Muriaé",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.39,
    "FEB": 5.96,
    "MAR": 5.32,
    "APR": 5.03,
    "MAY": 4.6,
    "JUN": 4.56,
    "JUL": 4.65,
    "AUG": 5.33,
    "SEP": 5.22,
    "OCT": 5.04,
    "NOV": 4.5,
    "DEC": 5.17
  },
  {
    "LONG": -42.19,
    "LAT": -21.1,
    "CIDADE": "Eugenópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.41,
    "FEB": 5.93,
    "MAR": 5.26,
    "APR": 5.08,
    "MAY": 4.61,
    "JUN": 4.53,
    "JUL": 4.62,
    "AUG": 5.28,
    "SEP": 5.21,
    "OCT": 5.02,
    "NOV": 4.58,
    "DEC": 5.18
  },
  {
    "LONG": -46.74,
    "LAT": -21.01,
    "CIDADE": "Jacuí",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5,
    "FEB": 5.46,
    "MAR": 5.12,
    "APR": 5.31,
    "MAY": 5,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 5.91,
    "SEP": 5.47,
    "OCT": 5.35,
    "NOV": 4.96,
    "DEC": 4.99
  },
  {
    "LONG": -46.52,
    "LAT": -21.02,
    "CIDADE": "Bom Jesus da Penha",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 5.08,
    "FEB": 5.51,
    "MAR": 5.09,
    "APR": 5.3,
    "MAY": 4.98,
    "JUN": 4.94,
    "JUL": 5.16,
    "AUG": 5.96,
    "SEP": 5.52,
    "OCT": 5.36,
    "NOV": 4.9,
    "DEC": 5.02
  },
  {
    "LONG": -46.12,
    "LAT": -20.97,
    "CIDADE": "Carmo do Rio Claro",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 5.09,
    "FEB": 5.58,
    "MAR": 5.03,
    "APR": 5.28,
    "MAY": 4.94,
    "JUN": 4.81,
    "JUL": 5.11,
    "AUG": 5.86,
    "SEP": 5.49,
    "OCT": 5.34,
    "NOV": 4.91,
    "DEC": 5.01
  },
  {
    "LONG": -45.18,
    "LAT": -21.02,
    "CIDADE": "Cana Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 5.06,
    "FEB": 5.54,
    "MAR": 5.06,
    "APR": 5.35,
    "MAY": 4.91,
    "JUN": 4.88,
    "JUL": 5.09,
    "AUG": 5.89,
    "SEP": 5.56,
    "OCT": 5.28,
    "NOV": 4.76,
    "DEC": 4.99
  },
  {
    "LONG": -44.75,
    "LAT": -21.03,
    "CIDADE": "Bom Sucesso",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 4.93,
    "FEB": 5.48,
    "MAR": 5.02,
    "APR": 5.2,
    "MAY": 4.8,
    "JUN": 4.8,
    "JUL": 5.03,
    "AUG": 5.81,
    "SEP": 5.52,
    "OCT": 5.2,
    "NOV": 4.72,
    "DEC": 4.95
  },
  {
    "LONG": -44.32,
    "LAT": -2.1,
    "CIDADE": "Ritápolis",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.01,
    "FEB": 5.5,
    "MAR": 4.98,
    "APR": 5.04,
    "MAY": 4.68,
    "JUN": 4.7,
    "JUL": 4.93,
    "AUG": 5.74,
    "SEP": 5.43,
    "OCT": 5.11,
    "NOV": 4.6,
    "DEC": 4.91
  },
  {
    "LONG": -4.42,
    "LAT": -21.03,
    "CIDADE": "Coronel Xavier Chaves",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.05,
    "FEB": 5.55,
    "MAR": 4.99,
    "APR": 4.98,
    "MAY": 4.63,
    "JUN": 4.61,
    "JUL": 4.86,
    "AUG": 5.71,
    "SEP": 5.39,
    "OCT": 5.08,
    "NOV": 4.58,
    "DEC": 4.92
  },
  {
    "LONG": -43.81,
    "LAT": -20.96,
    "CIDADE": "Carandaí",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.05,
    "FEB": 5.62,
    "MAR": 5.04,
    "APR": 4.95,
    "MAY": 4.55,
    "JUN": 4.58,
    "JUL": 4.82,
    "AUG": 5.61,
    "SEP": 5.32,
    "OCT": 5.11,
    "NOV": 4.59,
    "DEC": 4.97
  },
  {
    "LONG": -43.58,
    "LAT": -21.04,
    "CIDADE": "Senhora dos Remédios",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.07,
    "FEB": 5.59,
    "MAR": 4.98,
    "APR": 4.79,
    "MAY": 4.4,
    "JUN": 4.39,
    "JUL": 4.57,
    "AUG": 5.31,
    "SEP": 5.14,
    "OCT": 4.92,
    "NOV": 4.39,
    "DEC": 4.94
  },
  {
    "LONG": -43.41,
    "LAT": -21.03,
    "CIDADE": "Alto Rio Doce",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5,
    "FEB": 5.59,
    "MAR": 5.03,
    "APR": 4.79,
    "MAY": 4.4,
    "JUN": 4.38,
    "JUL": 4.54,
    "AUG": 5.31,
    "SEP": 5.12,
    "OCT": 4.9,
    "NOV": 4.38,
    "DEC": 4.93
  },
  {
    "LONG": -43.18,
    "LAT": -2.1,
    "CIDADE": "Dores do Turvo",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 5.06,
    "FEB": 5.59,
    "MAR": 4.96,
    "APR": 4.76,
    "MAY": 4.36,
    "JUN": 4.39,
    "JUL": 4.57,
    "AUG": 5.2,
    "SEP": 5.08,
    "OCT": 4.87,
    "NOV": 4.41,
    "DEC": 4.9
  },
  {
    "LONG": -43,
    "LAT": -20.99,
    "CIDADE": "Divinésia",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 5.08,
    "FEB": 5.6,
    "MAR": 5,
    "APR": 4.75,
    "MAY": 4.36,
    "JUN": 4.42,
    "JUL": 4.56,
    "AUG": 5.2,
    "SEP": 5.08,
    "OCT": 4.87,
    "NOV": 4.42,
    "DEC": 4.88
  },
  {
    "LONG": -42.84,
    "LAT": -21.01,
    "CIDADE": "Visconde do Rio Branco",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.25,
    "FEB": 5.77,
    "MAR": 5.2,
    "APR": 4.94,
    "MAY": 4.52,
    "JUN": 4.5,
    "JUL": 4.67,
    "AUG": 5.34,
    "SEP": 5.18,
    "OCT": 5.02,
    "NOV": 4.58,
    "DEC": 5.03
  },
  {
    "LONG": -4.27,
    "LAT": -21.01,
    "CIDADE": "Guiricema",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.3,
    "FEB": 5.84,
    "MAR": 5.25,
    "APR": 5.03,
    "MAY": 4.6,
    "JUN": 4.57,
    "JUL": 4.75,
    "AUG": 5.39,
    "SEP": 5.27,
    "OCT": 5.09,
    "NOV": 4.65,
    "DEC": 5.1
  },
  {
    "LONG": -42.51,
    "LAT": -20.98,
    "CIDADE": "Rosário da Limeira",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 4.97,
    "FEB": 5.5,
    "MAR": 4.91,
    "APR": 4.76,
    "MAY": 4.43,
    "JUN": 4.45,
    "JUL": 4.53,
    "AUG": 5.19,
    "SEP": 5.12,
    "OCT": 4.81,
    "NOV": 4.34,
    "DEC": 4.89
  },
  {
    "LONG": -42.11,
    "LAT": -21.02,
    "CIDADE": "Antônio Prado de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.29,
    "FEB": 5.72,
    "MAR": 5.08,
    "APR": 4.93,
    "MAY": 4.54,
    "JUN": 4.54,
    "JUL": 4.61,
    "AUG": 5.3,
    "SEP": 5.2,
    "OCT": 4.91,
    "NOV": 4.44,
    "DEC": 4.98
  },
  {
    "LONG": -46.98,
    "LAT": -20.92,
    "CIDADE": "São Sebastião do Paraíso",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.07,
    "FEB": 5.62,
    "MAR": 5.18,
    "APR": 5.41,
    "MAY": 5.13,
    "JUN": 5.05,
    "JUL": 5.25,
    "AUG": 5.96,
    "SEP": 5.47,
    "OCT": 5.4,
    "NOV": 5.06,
    "DEC": 5.08
  },
  {
    "LONG": -46.71,
    "LAT": -20.85,
    "CIDADE": "Fortaleza de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.08,
    "FEB": 5.6,
    "MAR": 5.19,
    "APR": 5.43,
    "MAY": 5.11,
    "JUN": 5.01,
    "JUL": 5.22,
    "AUG": 5.95,
    "SEP": 5.49,
    "OCT": 5.42,
    "NOV": 5.04,
    "DEC": 5.07
  },
  {
    "LONG": -46.39,
    "LAT": -20.86,
    "CIDADE": "Alpinópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.15,
    "FEB": 5.55,
    "MAR": 5.11,
    "APR": 5.35,
    "MAY": 5.04,
    "JUN": 5.01,
    "JUL": 5.24,
    "AUG": 5.94,
    "SEP": 5.57,
    "OCT": 5.38,
    "NOV": 4.95,
    "DEC": 5.06
  },
  {
    "LONG": -45.83,
    "LAT": -20.94,
    "CIDADE": "Ilicínea",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 4.98,
    "FEB": 5.59,
    "MAR": 5.1,
    "APR": 5.33,
    "MAY": 5.03,
    "JUN": 4.96,
    "JUL": 5.21,
    "AUG": 5.96,
    "SEP": 5.55,
    "OCT": 5.35,
    "NOV": 4.84,
    "DEC": 5.04
  },
  {
    "LONG": -45.52,
    "LAT": -20.87,
    "CIDADE": "Cristais",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 5.11,
    "FEB": 5.6,
    "MAR": 5.15,
    "APR": 5.41,
    "MAY": 5.01,
    "JUN": 4.87,
    "JUL": 5.15,
    "AUG": 5.86,
    "SEP": 5.52,
    "OCT": 5.33,
    "NOV": 4.81,
    "DEC": 5.06
  },
  {
    "LONG": -45.39,
    "LAT": -20.94,
    "CIDADE": "Aguanil",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.05,
    "FEB": 5.5,
    "MAR": 5.07,
    "APR": 5.36,
    "MAY": 4.97,
    "JUN": 4.91,
    "JUL": 5.22,
    "AUG": 5.89,
    "SEP": 5.56,
    "OCT": 5.3,
    "NOV": 4.77,
    "DEC": 5.03
  },
  {
    "LONG": -45.27,
    "LAT": -20.89,
    "CIDADE": "Campo Belo",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.08,
    "FEB": 5.47,
    "MAR": 5.01,
    "APR": 5.3,
    "MAY": 4.92,
    "JUN": 4.87,
    "JUL": 5.16,
    "AUG": 5.9,
    "SEP": 5.56,
    "OCT": 5.26,
    "NOV": 4.7,
    "DEC": 4.99
  },
  {
    "LONG": -45.13,
    "LAT": -20.9,
    "CIDADE": "Santana do Jacaré",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.06,
    "FEB": 5.5,
    "MAR": 5,
    "APR": 5.34,
    "MAY": 4.91,
    "JUN": 4.85,
    "JUL": 5.12,
    "AUG": 5.93,
    "SEP": 5.56,
    "OCT": 5.23,
    "NOV": 4.7,
    "DEC": 4.95
  },
  {
    "LONG": -4.49,
    "LAT": -20.94,
    "CIDADE": "Santo Antônio do Amparo",
    "UF": "MINAS GERAIS",
    "anual": 5.15,
    "JAN": 5.01,
    "FEB": 5.47,
    "MAR": 4.98,
    "APR": 5.26,
    "MAY": 4.9,
    "JUN": 4.85,
    "JUL": 5.13,
    "AUG": 5.87,
    "SEP": 5.53,
    "OCT": 5.21,
    "NOV": 4.67,
    "DEC": 4.93
  },
  {
    "LONG": -44.51,
    "LAT": -20.91,
    "CIDADE": "São Tiago",
    "UF": "MINAS GERAIS",
    "anual": 5.13,
    "JAN": 5.05,
    "FEB": 5.49,
    "MAR": 5.02,
    "APR": 5.14,
    "MAY": 4.79,
    "JUN": 4.81,
    "JUL": 5.04,
    "AUG": 5.83,
    "SEP": 5.51,
    "OCT": 5.21,
    "NOV": 4.67,
    "DEC": 4.94
  },
  {
    "LONG": -44.24,
    "LAT": -20.92,
    "CIDADE": "Resende Costa",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.05,
    "FEB": 5.56,
    "MAR": 5,
    "APR": 5.02,
    "MAY": 4.64,
    "JUN": 4.68,
    "JUL": 4.91,
    "AUG": 5.75,
    "SEP": 5.42,
    "OCT": 5.14,
    "NOV": 4.61,
    "DEC": 4.93
  },
  {
    "LONG": -44.08,
    "LAT": -20.91,
    "CIDADE": "Lagoa Dourada",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.05,
    "FEB": 5.58,
    "MAR": 5,
    "APR": 4.96,
    "MAY": 4.6,
    "JUN": 4.63,
    "JUL": 4.85,
    "AUG": 5.67,
    "SEP": 5.38,
    "OCT": 5.13,
    "NOV": 4.59,
    "DEC": 4.95
  },
  {
    "LONG": -43.74,
    "LAT": -20.87,
    "CIDADE": "Caranaíba",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 4.97,
    "FEB": 5.6,
    "MAR": 4.98,
    "APR": 4.87,
    "MAY": 4.51,
    "JUN": 4.5,
    "JUL": 4.68,
    "AUG": 5.48,
    "SEP": 5.26,
    "OCT": 5.01,
    "NOV": 4.48,
    "DEC": 4.93
  },
  {
    "LONG": -43.62,
    "LAT": -20.92,
    "CIDADE": "Capela Nova",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.02,
    "FEB": 5.58,
    "MAR": 5.01,
    "APR": 4.83,
    "MAY": 4.45,
    "JUN": 4.45,
    "JUL": 4.62,
    "AUG": 5.43,
    "SEP": 5.21,
    "OCT": 4.97,
    "NOV": 4.41,
    "DEC": 4.92
  },
  {
    "LONG": -43.47,
    "LAT": -20.86,
    "CIDADE": "Rio Espera",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.03,
    "FEB": 5.66,
    "MAR": 5.04,
    "APR": 4.85,
    "MAY": 4.44,
    "JUN": 4.39,
    "JUL": 4.57,
    "AUG": 5.38,
    "SEP": 5.17,
    "OCT": 4.96,
    "NOV": 4.45,
    "DEC": 4.96
  },
  {
    "LONG": -43.36,
    "LAT": -20.9,
    "CIDADE": "Cipotânea",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.03,
    "FEB": 5.63,
    "MAR": 4.99,
    "APR": 4.84,
    "MAY": 4.42,
    "JUN": 4.41,
    "JUL": 4.59,
    "AUG": 5.33,
    "SEP": 5.14,
    "OCT": 4.93,
    "NOV": 4.4,
    "DEC": 4.92
  },
  {
    "LONG": -43.09,
    "LAT": -20.92,
    "CIDADE": "Senador Firmino",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.08,
    "FEB": 5.63,
    "MAR": 5,
    "APR": 4.78,
    "MAY": 4.38,
    "JUN": 4.39,
    "JUL": 4.59,
    "AUG": 5.22,
    "SEP": 5.1,
    "OCT": 4.88,
    "NOV": 4.42,
    "DEC": 4.9
  },
  {
    "LONG": -42.98,
    "LAT": -20.88,
    "CIDADE": "Paula Cândido",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.1,
    "FEB": 5.63,
    "MAR": 5.01,
    "APR": 4.76,
    "MAY": 4.41,
    "JUN": 4.4,
    "JUL": 4.61,
    "AUG": 5.21,
    "SEP": 5.11,
    "OCT": 4.89,
    "NOV": 4.43,
    "DEC": 4.91
  },
  {
    "LONG": -42.8,
    "LAT": -2.09,
    "CIDADE": "Coimbra",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.16,
    "FEB": 5.69,
    "MAR": 5.14,
    "APR": 4.91,
    "MAY": 4.49,
    "JUN": 4.49,
    "JUL": 4.65,
    "AUG": 5.31,
    "SEP": 5.16,
    "OCT": 4.95,
    "NOV": 4.54,
    "DEC": 4.99
  },
  {
    "LONG": -42.84,
    "LAT": -20.93,
    "CIDADE": "São Geraldo",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.16,
    "FEB": 5.69,
    "MAR": 5.14,
    "APR": 4.91,
    "MAY": 4.49,
    "JUN": 4.49,
    "JUL": 4.65,
    "AUG": 5.31,
    "SEP": 5.16,
    "OCT": 4.95,
    "NOV": 4.54,
    "DEC": 4.99
  },
  {
    "LONG": -42.35,
    "LAT": -20.89,
    "CIDADE": "Miradouro",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.14,
    "FEB": 5.72,
    "MAR": 5.05,
    "APR": 4.9,
    "MAY": 4.52,
    "JUN": 4.57,
    "JUL": 4.66,
    "AUG": 5.32,
    "SEP": 5.2,
    "OCT": 4.87,
    "NOV": 4.35,
    "DEC": 4.93
  },
  {
    "LONG": -42.24,
    "LAT": -20.87,
    "CIDADE": "Vieiras",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.27,
    "FEB": 5.71,
    "MAR": 5.12,
    "APR": 4.92,
    "MAY": 4.57,
    "JUN": 4.57,
    "JUL": 4.63,
    "AUG": 5.35,
    "SEP": 5.25,
    "OCT": 4.91,
    "NOV": 4.45,
    "DEC": 4.97
  },
  {
    "LONG": -42.02,
    "LAT": -20.91,
    "CIDADE": "Tombos",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.42,
    "FEB": 5.92,
    "MAR": 5.25,
    "APR": 5.09,
    "MAY": 4.59,
    "JUN": 4.62,
    "JUL": 4.7,
    "AUG": 5.34,
    "SEP": 5.25,
    "OCT": 5,
    "NOV": 4.54,
    "DEC": 5.09
  },
  {
    "LONG": -47.1,
    "LAT": -20.78,
    "CIDADE": "São Tomás de Aquino",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.03,
    "FEB": 5.57,
    "MAR": 5.17,
    "APR": 5.47,
    "MAY": 5.12,
    "JUN": 5.09,
    "JUL": 5.31,
    "AUG": 6.04,
    "SEP": 5.48,
    "OCT": 5.37,
    "NOV": 5.08,
    "DEC": 5.09
  },
  {
    "LONG": -45.92,
    "LAT": -20.76,
    "CIDADE": "Guapé",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.07,
    "FEB": 5.67,
    "MAR": 5.16,
    "APR": 5.5,
    "MAY": 5.13,
    "JUN": 5.04,
    "JUL": 5.26,
    "AUG": 5.94,
    "SEP": 5.52,
    "OCT": 5.42,
    "NOV": 4.95,
    "DEC": 5.1
  },
  {
    "LONG": -45.28,
    "LAT": -20.77,
    "CIDADE": "Candeias",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.06,
    "FEB": 5.44,
    "MAR": 5,
    "APR": 5.28,
    "MAY": 5,
    "JUN": 4.87,
    "JUL": 5.19,
    "AUG": 5.89,
    "SEP": 5.59,
    "OCT": 5.26,
    "NOV": 4.67,
    "DEC": 4.97
  },
  {
    "LONG": -43.93,
    "LAT": -2.08,
    "CIDADE": "Casa Grande",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.11,
    "FEB": 5.63,
    "MAR": 5.08,
    "APR": 5.03,
    "MAY": 4.67,
    "JUN": 4.67,
    "JUL": 4.87,
    "AUG": 5.68,
    "SEP": 5.4,
    "OCT": 5.14,
    "NOV": 4.63,
    "DEC": 4.96
  },
  {
    "LONG": -43.82,
    "LAT": -20.83,
    "CIDADE": "Cristiano Otoni",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.1,
    "FEB": 5.65,
    "MAR": 5.05,
    "APR": 5.01,
    "MAY": 4.64,
    "JUN": 4.62,
    "JUL": 4.8,
    "AUG": 5.62,
    "SEP": 5.36,
    "OCT": 5.11,
    "NOV": 4.6,
    "DEC": 4.98
  },
  {
    "LONG": -43.7,
    "LAT": -20.79,
    "CIDADE": "Santana dos Montes",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5,
    "FEB": 5.64,
    "MAR": 5.01,
    "APR": 4.91,
    "MAY": 4.52,
    "JUN": 4.47,
    "JUL": 4.64,
    "AUG": 5.52,
    "SEP": 5.26,
    "OCT": 4.99,
    "NOV": 4.46,
    "DEC": 4.94
  },
  {
    "LONG": -4.35,
    "LAT": -20.79,
    "CIDADE": "Lamim",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.08,
    "FEB": 5.75,
    "MAR": 5.08,
    "APR": 4.88,
    "MAY": 4.49,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.47,
    "SEP": 5.24,
    "OCT": 5.02,
    "NOV": 4.45,
    "DEC": 4.95
  },
  {
    "LONG": -43.34,
    "LAT": -20.8,
    "CIDADE": "Senhora de Oliveira",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.01,
    "FEB": 5.65,
    "MAR": 4.96,
    "APR": 4.81,
    "MAY": 4.46,
    "JUN": 4.45,
    "JUL": 4.66,
    "AUG": 5.36,
    "SEP": 5.15,
    "OCT": 4.93,
    "NOV": 4.36,
    "DEC": 4.87
  },
  {
    "LONG": -43.24,
    "LAT": -20.84,
    "CIDADE": "Brás Pires",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 4.98,
    "FEB": 5.6,
    "MAR": 4.95,
    "APR": 4.81,
    "MAY": 4.41,
    "JUN": 4.42,
    "JUL": 4.61,
    "AUG": 5.33,
    "SEP": 5.08,
    "OCT": 4.88,
    "NOV": 4.36,
    "DEC": 4.83
  },
  {
    "LONG": -43.19,
    "LAT": -2.08,
    "CIDADE": "Presidente Bernardes",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.06,
    "FEB": 5.66,
    "MAR": 4.99,
    "APR": 4.86,
    "MAY": 4.4,
    "JUN": 4.4,
    "JUL": 4.57,
    "AUG": 5.3,
    "SEP": 5.1,
    "OCT": 4.88,
    "NOV": 4.36,
    "DEC": 4.86
  },
  {
    "LONG": -42.87,
    "LAT": -20.76,
    "CIDADE": "Viçosa",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.08,
    "FEB": 5.64,
    "MAR": 5.08,
    "APR": 4.89,
    "MAY": 4.46,
    "JUN": 4.41,
    "JUL": 4.62,
    "AUG": 5.31,
    "SEP": 5.15,
    "OCT": 4.95,
    "NOV": 4.47,
    "DEC": 4.95
  },
  {
    "LONG": -42.79,
    "LAT": -20.79,
    "CIDADE": "Cajuri",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.12,
    "FEB": 5.71,
    "MAR": 5.11,
    "APR": 4.91,
    "MAY": 4.5,
    "JUN": 4.47,
    "JUL": 4.66,
    "AUG": 5.35,
    "SEP": 5.21,
    "OCT": 5.01,
    "NOV": 4.53,
    "DEC": 4.97
  },
  {
    "LONG": -42.65,
    "LAT": -20.84,
    "CIDADE": "Ervália",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.18,
    "FEB": 5.78,
    "MAR": 5.18,
    "APR": 4.96,
    "MAY": 4.59,
    "JUN": 4.58,
    "JUL": 4.74,
    "AUG": 5.39,
    "SEP": 5.27,
    "OCT": 5.08,
    "NOV": 4.6,
    "DEC": 5.04
  },
  {
    "LONG": -42.27,
    "LAT": -20.79,
    "CIDADE": "São Francisco do Glória",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.11,
    "APR": 4.91,
    "MAY": 4.58,
    "JUN": 4.63,
    "JUL": 4.7,
    "AUG": 5.38,
    "SEP": 5.28,
    "OCT": 4.94,
    "NOV": 4.43,
    "DEC": 4.97
  },
  {
    "LONG": -42.15,
    "LAT": -20.83,
    "CIDADE": "Pedra Dourada",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.04,
    "APR": 4.87,
    "MAY": 4.48,
    "JUN": 4.56,
    "JUL": 4.63,
    "AUG": 5.31,
    "SEP": 5.21,
    "OCT": 4.87,
    "NOV": 4.39,
    "DEC": 4.97
  },
  {
    "LONG": -42.02,
    "LAT": -20.81,
    "CIDADE": "Faria Lemos",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.36,
    "FEB": 5.91,
    "MAR": 5.2,
    "APR": 5.06,
    "MAY": 4.57,
    "JUN": 4.62,
    "JUL": 4.68,
    "AUG": 5.31,
    "SEP": 5.26,
    "OCT": 4.97,
    "NOV": 4.51,
    "DEC": 5.09
  },
  {
    "LONG": -46.86,
    "LAT": -20.74,
    "CIDADE": "Pratápolis",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.16,
    "FEB": 5.68,
    "MAR": 5.22,
    "APR": 5.45,
    "MAY": 5.16,
    "JUN": 5.12,
    "JUL": 5.32,
    "AUG": 6.06,
    "SEP": 5.51,
    "OCT": 5.46,
    "NOV": 5.12,
    "DEC": 5.13
  },
  {
    "LONG": -46.75,
    "LAT": -2.07,
    "CIDADE": "Itaú de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.22,
    "FEB": 5.72,
    "MAR": 5.27,
    "APR": 5.48,
    "MAY": 5.16,
    "JUN": 5.11,
    "JUL": 5.29,
    "AUG": 6,
    "SEP": 5.5,
    "OCT": 5.49,
    "NOV": 5.15,
    "DEC": 5.19
  },
  {
    "LONG": -46.61,
    "LAT": -20.72,
    "CIDADE": "Passos",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.24,
    "FEB": 5.7,
    "MAR": 5.29,
    "APR": 5.47,
    "MAY": 5.15,
    "JUN": 5.09,
    "JUL": 5.27,
    "AUG": 5.9,
    "SEP": 5.54,
    "OCT": 5.49,
    "NOV": 5.16,
    "DEC": 5.2
  },
  {
    "LONG": -46.31,
    "LAT": -20.72,
    "CIDADE": "São José da Barra",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.1,
    "FEB": 5.55,
    "MAR": 5.18,
    "APR": 5.45,
    "MAY": 5.17,
    "JUN": 5.08,
    "JUL": 5.29,
    "AUG": 5.96,
    "SEP": 5.55,
    "OCT": 5.44,
    "NOV": 4.98,
    "DEC": 5.04
  },
  {
    "LONG": -44.98,
    "LAT": -20.7,
    "CIDADE": "São Francisco de Paula",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.11,
    "FEB": 5.58,
    "MAR": 5.03,
    "APR": 5.33,
    "MAY": 4.97,
    "JUN": 4.94,
    "JUL": 5.23,
    "AUG": 5.88,
    "SEP": 5.61,
    "OCT": 5.3,
    "NOV": 4.75,
    "DEC": 4.98
  },
  {
    "LONG": -44.83,
    "LAT": -20.7,
    "CIDADE": "Oliveira",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.1,
    "FEB": 5.58,
    "MAR": 5.14,
    "APR": 5.32,
    "MAY": 4.97,
    "JUN": 4.98,
    "JUL": 5.22,
    "AUG": 5.92,
    "SEP": 5.64,
    "OCT": 5.33,
    "NOV": 4.78,
    "DEC": 5.01
  },
  {
    "LONG": -4.45,
    "LAT": -20.65,
    "CIDADE": "Passa Tempo",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.11,
    "FEB": 5.6,
    "MAR": 5.14,
    "APR": 5.25,
    "MAY": 4.91,
    "JUN": 4.96,
    "JUL": 5.14,
    "AUG": 5.9,
    "SEP": 5.61,
    "OCT": 5.34,
    "NOV": 4.72,
    "DEC": 5.04
  },
  {
    "LONG": -44.33,
    "LAT": -20.67,
    "CIDADE": "Desterro de Entre Rios",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.58,
    "MAR": 5.13,
    "APR": 5.22,
    "MAY": 4.85,
    "JUN": 4.9,
    "JUL": 5.06,
    "AUG": 5.85,
    "SEP": 5.57,
    "OCT": 5.3,
    "NOV": 4.71,
    "DEC": 4.98
  },
  {
    "LONG": -44.07,
    "LAT": -20.67,
    "CIDADE": "Entre Rios de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 5.11,
    "FEB": 5.59,
    "MAR": 5.05,
    "APR": 5.07,
    "MAY": 4.7,
    "JUN": 4.68,
    "JUL": 4.89,
    "AUG": 5.74,
    "SEP": 5.46,
    "OCT": 5.17,
    "NOV": 4.65,
    "DEC": 4.94
  },
  {
    "LONG": -43.89,
    "LAT": -2.07,
    "CIDADE": "Queluzito",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.13,
    "FEB": 5.67,
    "MAR": 5.11,
    "APR": 5.07,
    "MAY": 4.72,
    "JUN": 4.69,
    "JUL": 4.87,
    "AUG": 5.72,
    "SEP": 5.41,
    "OCT": 5.16,
    "NOV": 4.66,
    "DEC": 4.98
  },
  {
    "LONG": -4.38,
    "LAT": -20.66,
    "CIDADE": "Conselheiro Lafaiete",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 4.99,
    "FEB": 5.64,
    "MAR": 5.09,
    "APR": 5.07,
    "MAY": 4.7,
    "JUN": 4.67,
    "JUL": 4.78,
    "AUG": 5.7,
    "SEP": 5.4,
    "OCT": 5.15,
    "NOV": 4.63,
    "DEC": 4.97
  },
  {
    "LONG": -43.61,
    "LAT": -20.68,
    "CIDADE": "Itaverava",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.03,
    "FEB": 5.68,
    "MAR": 5.01,
    "APR": 4.94,
    "MAY": 4.63,
    "JUN": 4.55,
    "JUL": 4.69,
    "AUG": 5.61,
    "SEP": 5.34,
    "OCT": 5.05,
    "NOV": 4.46,
    "DEC": 4.91
  },
  {
    "LONG": -43.49,
    "LAT": -20.69,
    "CIDADE": "Catas Altas da Noruega",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.01,
    "FEB": 5.67,
    "MAR": 4.97,
    "APR": 4.83,
    "MAY": 4.49,
    "JUN": 4.51,
    "JUL": 4.69,
    "AUG": 5.5,
    "SEP": 5.24,
    "OCT": 4.94,
    "NOV": 4.35,
    "DEC": 4.8
  },
  {
    "LONG": -43.3,
    "LAT": -20.68,
    "CIDADE": "Piranga",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 4.96,
    "FEB": 5.59,
    "MAR": 4.98,
    "APR": 4.84,
    "MAY": 4.46,
    "JUN": 4.42,
    "JUL": 4.64,
    "AUG": 5.37,
    "SEP": 5.13,
    "OCT": 4.88,
    "NOV": 4.36,
    "DEC": 4.79
  },
  {
    "LONG": -43.08,
    "LAT": -20.66,
    "CIDADE": "Porto Firme",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.1,
    "FEB": 5.63,
    "MAR": 5.07,
    "APR": 4.88,
    "MAY": 4.47,
    "JUN": 4.36,
    "JUL": 4.57,
    "AUG": 5.33,
    "SEP": 5.14,
    "OCT": 4.91,
    "NOV": 4.4,
    "DEC": 4.89
  },
  {
    "LONG": -42.86,
    "LAT": -20.66,
    "CIDADE": "Teixeiras",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.08,
    "FEB": 5.62,
    "MAR": 5.05,
    "APR": 4.89,
    "MAY": 4.47,
    "JUN": 4.39,
    "JUL": 4.6,
    "AUG": 5.27,
    "SEP": 5.12,
    "OCT": 4.92,
    "NOV": 4.44,
    "DEC": 4.92
  },
  {
    "LONG": -42.72,
    "LAT": -20.71,
    "CIDADE": "São Miguel do Anta",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.11,
    "FEB": 5.68,
    "MAR": 5.11,
    "APR": 4.92,
    "MAY": 4.52,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.34,
    "SEP": 5.18,
    "OCT": 5,
    "NOV": 4.49,
    "DEC": 4.95
  },
  {
    "LONG": -4.26,
    "LAT": -20.69,
    "CIDADE": "Canaã",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.2,
    "FEB": 5.79,
    "MAR": 5.24,
    "APR": 5.03,
    "MAY": 4.65,
    "JUN": 4.6,
    "JUL": 4.74,
    "AUG": 5.41,
    "SEP": 5.29,
    "OCT": 5.1,
    "NOV": 4.59,
    "DEC": 5.04
  },
  {
    "LONG": -42.52,
    "LAT": -20.67,
    "CIDADE": "Araponga",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.12,
    "FEB": 5.6,
    "MAR": 5.08,
    "APR": 4.97,
    "MAY": 4.62,
    "JUN": 4.61,
    "JUL": 4.75,
    "AUG": 5.39,
    "SEP": 5.3,
    "OCT": 5.04,
    "NOV": 4.52,
    "DEC": 5
  },
  {
    "LONG": -42.28,
    "LAT": -20.73,
    "CIDADE": "Fervedouro",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.24,
    "FEB": 5.78,
    "MAR": 5.06,
    "APR": 4.91,
    "MAY": 4.54,
    "JUN": 4.64,
    "JUL": 4.74,
    "AUG": 5.37,
    "SEP": 5.3,
    "OCT": 4.92,
    "NOV": 4.38,
    "DEC": 4.96
  },
  {
    "LONG": -42.03,
    "LAT": -20.73,
    "CIDADE": "Carangola",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.3,
    "FEB": 5.84,
    "MAR": 5.18,
    "APR": 5.06,
    "MAY": 4.6,
    "JUN": 4.65,
    "JUL": 4.7,
    "AUG": 5.36,
    "SEP": 5.28,
    "OCT": 4.96,
    "NOV": 4.49,
    "DEC": 5.08
  },
  {
    "LONG": -41.91,
    "LAT": -20.65,
    "CIDADE": "Espera Feliz",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.3,
    "FEB": 5.8,
    "MAR": 5.1,
    "APR": 4.96,
    "MAY": 4.57,
    "JUN": 4.67,
    "JUL": 4.73,
    "AUG": 5.34,
    "SEP": 5.27,
    "OCT": 4.97,
    "NOV": 4.44,
    "DEC": 5.01
  },
  {
    "LONG": -41.93,
    "LAT": -20.7,
    "CIDADE": "Caiana",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.3,
    "FEB": 5.8,
    "MAR": 5.1,
    "APR": 4.96,
    "MAY": 4.57,
    "JUN": 4.67,
    "JUL": 4.73,
    "AUG": 5.34,
    "SEP": 5.27,
    "OCT": 4.97,
    "NOV": 4.44,
    "DEC": 5.01
  },
  {
    "LONG": -47.06,
    "LAT": -20.62,
    "CIDADE": "Capetinga",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.03,
    "FEB": 5.56,
    "MAR": 5.09,
    "APR": 5.42,
    "MAY": 5.15,
    "JUN": 5.11,
    "JUL": 5.35,
    "AUG": 6.07,
    "SEP": 5.49,
    "OCT": 5.35,
    "NOV": 5.01,
    "DEC": 5.03
  },
  {
    "LONG": -46.92,
    "LAT": -20.58,
    "CIDADE": "Cássia",
    "UF": "MINAS GERAIS",
    "anual": 5.33,
    "JAN": 5.12,
    "FEB": 5.65,
    "MAR": 5.15,
    "APR": 5.42,
    "MAY": 5.16,
    "JUN": 5.06,
    "JUL": 5.34,
    "AUG": 6.03,
    "SEP": 5.53,
    "OCT": 5.37,
    "NOV": 5.01,
    "DEC": 5.06
  },
  {
    "LONG": -46.51,
    "LAT": -20.64,
    "CIDADE": "São João Batista do Glória",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.14,
    "FEB": 5.64,
    "MAR": 5.19,
    "APR": 5.46,
    "MAY": 5.15,
    "JUN": 5.04,
    "JUL": 5.25,
    "AUG": 5.9,
    "SEP": 5.53,
    "OCT": 5.44,
    "NOV": 5.02,
    "DEC": 5.1
  },
  {
    "LONG": -46.05,
    "LAT": -20.62,
    "CIDADE": "Capitólio",
    "UF": "MINAS GERAIS",
    "anual": 5.27,
    "JAN": 5.06,
    "FEB": 5.61,
    "MAR": 5.02,
    "APR": 5.37,
    "MAY": 5.11,
    "JUN": 5.03,
    "JUL": 5.29,
    "AUG": 5.99,
    "SEP": 5.6,
    "OCT": 5.4,
    "NOV": 4.78,
    "DEC": 4.95
  },
  {
    "LONG": -45.16,
    "LAT": -20.63,
    "CIDADE": "Camacho",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.1,
    "FEB": 5.59,
    "MAR": 5.1,
    "APR": 5.39,
    "MAY": 5.07,
    "JUN": 4.99,
    "JUL": 5.26,
    "AUG": 6,
    "SEP": 5.68,
    "OCT": 5.34,
    "NOV": 4.71,
    "DEC": 4.91
  },
  {
    "LONG": -44.87,
    "LAT": -2.06,
    "CIDADE": "Carmo da Mata",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.14,
    "FEB": 5.67,
    "MAR": 5.17,
    "APR": 5.42,
    "MAY": 5.02,
    "JUN": 5.06,
    "JUL": 5.25,
    "AUG": 5.95,
    "SEP": 5.69,
    "OCT": 5.39,
    "NOV": 4.8,
    "DEC": 5.02
  },
  {
    "LONG": -43.95,
    "LAT": -20.62,
    "CIDADE": "São Brás do Suaçuí",
    "UF": "MINAS GERAIS",
    "anual": 5.15,
    "JAN": 5.2,
    "FEB": 5.63,
    "MAR": 5.14,
    "APR": 5.16,
    "MAY": 4.83,
    "JUN": 4.76,
    "JUL": 4.93,
    "AUG": 5.79,
    "SEP": 5.52,
    "OCT": 5.22,
    "NOV": 4.72,
    "DEC": 4.97
  },
  {
    "LONG": -43.01,
    "LAT": -2.06,
    "CIDADE": "Guaraciaba",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.07,
    "FEB": 5.64,
    "MAR": 5.06,
    "APR": 4.92,
    "MAY": 4.45,
    "JUN": 4.41,
    "JUL": 4.61,
    "AUG": 5.32,
    "SEP": 5.14,
    "OCT": 4.88,
    "NOV": 4.4,
    "DEC": 4.85
  },
  {
    "LONG": -42.71,
    "LAT": -20.6,
    "CIDADE": "Pedra do Anta",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.11,
    "FEB": 5.71,
    "MAR": 5.15,
    "APR": 4.92,
    "MAY": 4.52,
    "JUN": 4.44,
    "JUL": 4.64,
    "AUG": 5.31,
    "SEP": 5.17,
    "OCT": 4.97,
    "NOV": 4.49,
    "DEC": 4.97
  },
  {
    "LONG": -42.14,
    "LAT": -20.61,
    "CIDADE": "Divino",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.23,
    "FEB": 5.76,
    "MAR": 5.12,
    "APR": 5.02,
    "MAY": 4.59,
    "JUN": 4.64,
    "JUL": 4.75,
    "AUG": 5.35,
    "SEP": 5.34,
    "OCT": 5,
    "NOV": 4.44,
    "DEC": 5
  },
  {
    "LONG": -47.12,
    "LAT": -20.46,
    "CIDADE": "Ibiraci",
    "UF": "MINAS GERAIS",
    "anual": 5.33,
    "JAN": 5.02,
    "FEB": 5.53,
    "MAR": 5.12,
    "APR": 5.45,
    "MAY": 5.17,
    "JUN": 5.13,
    "JUL": 5.37,
    "AUG": 6.07,
    "SEP": 5.53,
    "OCT": 5.39,
    "NOV": 5.08,
    "DEC": 5.05
  },
  {
    "LONG": -45.96,
    "LAT": -20.48,
    "CIDADE": "Piumhi",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.09,
    "FEB": 5.64,
    "MAR": 5.11,
    "APR": 5.42,
    "MAY": 5.13,
    "JUN": 5.09,
    "JUL": 5.32,
    "AUG": 6.07,
    "SEP": 5.66,
    "OCT": 5.42,
    "NOV": 4.79,
    "DEC": 4.99
  },
  {
    "LONG": -45.81,
    "LAT": -20.48,
    "CIDADE": "Pimenta",
    "UF": "MINAS GERAIS",
    "anual": 5.27,
    "JAN": 5.07,
    "FEB": 5.56,
    "MAR": 5.02,
    "APR": 5.39,
    "MAY": 5.11,
    "JUN": 5.02,
    "JUL": 5.3,
    "AUG": 6.04,
    "SEP": 5.63,
    "OCT": 5.39,
    "NOV": 4.72,
    "DEC": 5.01
  },
  {
    "LONG": -45.43,
    "LAT": -20.46,
    "CIDADE": "Formiga",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 5.09,
    "FEB": 5.59,
    "MAR": 5.06,
    "APR": 5.4,
    "MAY": 5.1,
    "JUN": 5.05,
    "JUL": 5.28,
    "AUG": 6.04,
    "SEP": 5.67,
    "OCT": 5.35,
    "NOV": 4.76,
    "DEC": 5.02
  },
  {
    "LONG": -45.13,
    "LAT": -20.47,
    "CIDADE": "Itapecerica",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 5.12,
    "FEB": 5.62,
    "MAR": 5.14,
    "APR": 5.43,
    "MAY": 5.09,
    "JUN": 5.02,
    "JUL": 5.29,
    "AUG": 6,
    "SEP": 5.72,
    "OCT": 5.36,
    "NOV": 4.76,
    "DEC": 4.96
  },
  {
    "LONG": -44.63,
    "LAT": "#VALOR!",
    "CIDADE": "Carmópolis de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.15,
    "FEB": 5.63,
    "MAR": 5.22,
    "APR": 5.41,
    "MAY": 5.03,
    "JUN": 5.01,
    "JUL": 5.29,
    "AUG": 5.95,
    "SEP": 5.7,
    "OCT": 5.41,
    "NOV": 4.86,
    "DEC": 5.06
  },
  {
    "LONG": -44.48,
    "LAT": -20.51,
    "CIDADE": "Piracema",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 5.12,
    "FEB": 5.63,
    "MAR": 5.23,
    "APR": 5.38,
    "MAY": 5.06,
    "JUN": 5.05,
    "JUL": 5.27,
    "AUG": 5.95,
    "SEP": 5.68,
    "OCT": 5.36,
    "NOV": 4.8,
    "DEC": 4.99
  },
  {
    "LONG": -44.22,
    "LAT": -2.05,
    "CIDADE": "Piedade dos Gerais",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 5.16,
    "FEB": 5.64,
    "MAR": 5.11,
    "APR": 5.27,
    "MAY": 4.98,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 5.88,
    "SEP": 5.65,
    "OCT": 5.3,
    "NOV": 4.76,
    "DEC": 4.99
  },
  {
    "LONG": -43.99,
    "LAT": -20.53,
    "CIDADE": "Jeceaba",
    "UF": "MINAS GERAIS",
    "anual": 5.18,
    "JAN": 5.12,
    "FEB": 5.58,
    "MAR": 5.11,
    "APR": 5.19,
    "MAY": 4.94,
    "JUN": 4.88,
    "JUL": 5.04,
    "AUG": 5.86,
    "SEP": 5.56,
    "OCT": 5.2,
    "NOV": 4.69,
    "DEC": 4.94
  },
  {
    "LONG": -43.85,
    "LAT": -20.5,
    "CIDADE": "Congonhas",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 4.97,
    "FEB": 5.44,
    "MAR": 4.93,
    "APR": 4.99,
    "MAY": 4.85,
    "JUN": 4.83,
    "JUL": 4.95,
    "AUG": 5.79,
    "SEP": 5.44,
    "OCT": 5.06,
    "NOV": 4.51,
    "DEC": 4.8
  },
  {
    "LONG": -43.7,
    "LAT": -20.53,
    "CIDADE": "Ouro Branco",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 4.97,
    "FEB": 5.48,
    "MAR": 4.92,
    "APR": 4.92,
    "MAY": 4.72,
    "JUN": 4.78,
    "JUL": 4.93,
    "AUG": 5.76,
    "SEP": 5.41,
    "OCT": 5.06,
    "NOV": 4.45,
    "DEC": 4.77
  },
  {
    "LONG": -43.2,
    "LAT": -20.49,
    "CIDADE": "Diogo de Vasconcelos",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.06,
    "FEB": 5.6,
    "MAR": 5.03,
    "APR": 4.91,
    "MAY": 4.47,
    "JUN": 4.42,
    "JUL": 4.62,
    "AUG": 5.33,
    "SEP": 5.12,
    "OCT": 4.83,
    "NOV": 4.36,
    "DEC": 4.79
  },
  {
    "LONG": -42.8,
    "LAT": -20.51,
    "CIDADE": "Amparo do Serra",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.05,
    "FEB": 5.72,
    "MAR": 5.16,
    "APR": 4.95,
    "MAY": 4.52,
    "JUN": 4.42,
    "JUL": 4.63,
    "AUG": 5.3,
    "SEP": 5.16,
    "OCT": 4.95,
    "NOV": 4.5,
    "DEC": 4.94
  },
  {
    "LONG": -42.67,
    "LAT": -20.45,
    "CIDADE": "Jequeri",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 5.28,
    "FEB": 5.83,
    "MAR": 5.3,
    "APR": 5.11,
    "MAY": 4.69,
    "JUN": 4.58,
    "JUL": 4.78,
    "AUG": 5.42,
    "SEP": 5.25,
    "OCT": 5.08,
    "NOV": 4.62,
    "DEC": 5.12
  },
  {
    "LONG": -42.48,
    "LAT": -20.48,
    "CIDADE": "Sericita",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5,
    "FEB": 5.55,
    "MAR": 5.04,
    "APR": 4.85,
    "MAY": 4.51,
    "JUN": 4.47,
    "JUL": 4.68,
    "AUG": 5.27,
    "SEP": 5.22,
    "OCT": 4.9,
    "NOV": 4.38,
    "DEC": 4.89
  },
  {
    "LONG": -42.33,
    "LAT": -20.52,
    "CIDADE": "Pedra Bonita",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 4.95,
    "FEB": 5.54,
    "MAR": 4.96,
    "APR": 4.85,
    "MAY": 4.43,
    "JUN": 4.4,
    "JUL": 4.66,
    "AUG": 5.19,
    "SEP": 5.18,
    "OCT": 4.86,
    "NOV": 4.32,
    "DEC": 4.86
  },
  {
    "LONG": -42.2,
    "LAT": -20.51,
    "CIDADE": "Orizânia",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.05,
    "FEB": 5.61,
    "MAR": 5.02,
    "APR": 4.93,
    "MAY": 4.46,
    "JUN": 4.49,
    "JUL": 4.67,
    "AUG": 5.27,
    "SEP": 5.24,
    "OCT": 4.9,
    "NOV": 4.39,
    "DEC": 4.93
  },
  {
    "LONG": -41.91,
    "LAT": -20.53,
    "CIDADE": "Caparaó",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.19,
    "FEB": 5.69,
    "MAR": 5.04,
    "APR": 4.91,
    "MAY": 4.52,
    "JUN": 4.53,
    "JUL": 4.67,
    "AUG": 5.21,
    "SEP": 5.19,
    "OCT": 4.86,
    "NOV": 4.39,
    "DEC": 4.9
  },
  {
    "LONG": -47.28,
    "LAT": -20.4,
    "CIDADE": "Claraval",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.07,
    "FEB": 5.69,
    "MAR": 5.28,
    "APR": 5.58,
    "MAY": 5.22,
    "JUN": 5.21,
    "JUL": 5.35,
    "AUG": 6.11,
    "SEP": 5.53,
    "OCT": 5.44,
    "NOV": 5.18,
    "DEC": 5.18
  },
  {
    "LONG": -45.66,
    "LAT": -20.37,
    "CIDADE": "Pains",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 5.2,
    "FEB": 5.71,
    "MAR": 5.16,
    "APR": 5.5,
    "MAY": 5.19,
    "JUN": 5.09,
    "JUL": 5.34,
    "AUG": 6.09,
    "SEP": 5.7,
    "OCT": 5.37,
    "NOV": 4.8,
    "DEC": 5.1
  },
  {
    "LONG": -45.56,
    "LAT": -20.45,
    "CIDADE": "Córrego Fundo",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.18,
    "FEB": 5.63,
    "MAR": 5.17,
    "APR": 5.43,
    "MAY": 5.16,
    "JUN": 5.05,
    "JUL": 5.34,
    "AUG": 6.03,
    "SEP": 5.7,
    "OCT": 5.35,
    "NOV": 4.79,
    "DEC": 5.06
  },
  {
    "LONG": -44.77,
    "LAT": -20.44,
    "CIDADE": "Cláudio",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.26,
    "FEB": 5.67,
    "MAR": 5.27,
    "APR": 5.45,
    "MAY": 5.15,
    "JUN": 5.06,
    "JUL": 5.34,
    "AUG": 6.05,
    "SEP": 5.76,
    "OCT": 5.43,
    "NOV": 4.9,
    "DEC": 5.03
  },
  {
    "LONG": -44.49,
    "LAT": -20.4,
    "CIDADE": "Itaguara",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.07,
    "FEB": 5.53,
    "MAR": 5.12,
    "APR": 5.27,
    "MAY": 5.03,
    "JUN": 5.05,
    "JUL": 5.27,
    "AUG": 6,
    "SEP": 5.7,
    "OCT": 5.29,
    "NOV": 4.73,
    "DEC": 4.92
  },
  {
    "LONG": -44.33,
    "LAT": -20.39,
    "CIDADE": "Crucilândia",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.11,
    "FEB": 5.58,
    "MAR": 5.15,
    "APR": 5.26,
    "MAY": 5.01,
    "JUN": 5.02,
    "JUL": 5.29,
    "AUG": 5.95,
    "SEP": 5.69,
    "OCT": 5.32,
    "NOV": 4.74,
    "DEC": 4.92
  },
  {
    "LONG": -44.03,
    "LAT": -20.41,
    "CIDADE": "Belo Vale",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.24,
    "FEB": 5.7,
    "MAR": 5.27,
    "APR": 5.38,
    "MAY": 5,
    "JUN": 5.05,
    "JUL": 5.19,
    "AUG": 5.91,
    "SEP": 5.68,
    "OCT": 5.36,
    "NOV": 4.85,
    "DEC": 5.01
  },
  {
    "LONG": -43.51,
    "LAT": "#VALOR!",
    "CIDADE": "Ouro Preto",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.07,
    "FEB": 5.56,
    "MAR": 5.05,
    "APR": 4.94,
    "MAY": 4.72,
    "JUN": 4.78,
    "JUL": 4.94,
    "AUG": 5.74,
    "SEP": 5.42,
    "OCT": 5.04,
    "NOV": 4.45,
    "DEC": 4.8
  },
  {
    "LONG": -43.41,
    "LAT": -2.04,
    "CIDADE": "Mariana",
    "UF": "MINAS GERAIS",
    "anual": 4.76,
    "JAN": 4.75,
    "FEB": 5.29,
    "MAR": 4.69,
    "APR": 4.69,
    "MAY": 4.46,
    "JUN": 4.47,
    "JUL": 4.64,
    "AUG": 5.39,
    "SEP": 5.14,
    "OCT": 4.75,
    "NOV": 4.22,
    "DEC": 4.59
  },
  {
    "LONG": -43.14,
    "LAT": -20.36,
    "CIDADE": "Acaiaca",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.1,
    "FEB": 5.66,
    "MAR": 5.15,
    "APR": 4.98,
    "MAY": 4.57,
    "JUN": 4.49,
    "JUL": 4.68,
    "AUG": 5.41,
    "SEP": 5.18,
    "OCT": 4.86,
    "NOV": 4.44,
    "DEC": 4.89
  },
  {
    "LONG": -42.9,
    "LAT": -20.41,
    "CIDADE": "Ponte Nova",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.18,
    "FEB": 5.81,
    "MAR": 5.22,
    "APR": 5.05,
    "MAY": 4.59,
    "JUN": 4.48,
    "JUL": 4.72,
    "AUG": 5.36,
    "SEP": 5.22,
    "OCT": 5.02,
    "NOV": 4.52,
    "DEC": 5
  },
  {
    "LONG": -42.74,
    "LAT": -20.35,
    "CIDADE": "Urucânia",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.19,
    "FEB": 5.79,
    "MAR": 5.18,
    "APR": 5.01,
    "MAY": 4.6,
    "JUN": 4.5,
    "JUL": 4.71,
    "AUG": 5.35,
    "SEP": 5.2,
    "OCT": 5.01,
    "NOV": 4.51,
    "DEC": 5.02
  },
  {
    "LONG": -42.8,
    "LAT": -20.43,
    "CIDADE": "Oratórios",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.19,
    "FEB": 5.79,
    "MAR": 5.18,
    "APR": 5.01,
    "MAY": 4.6,
    "JUN": 4.5,
    "JUL": 4.71,
    "AUG": 5.35,
    "SEP": 5.2,
    "OCT": 5.01,
    "NOV": 4.51,
    "DEC": 5.02
  },
  {
    "LONG": -42.25,
    "LAT": -20.38,
    "CIDADE": "Santa Margarida",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.12,
    "FEB": 5.63,
    "MAR": 5.13,
    "APR": 5,
    "MAY": 4.54,
    "JUN": 4.59,
    "JUL": 4.71,
    "AUG": 5.29,
    "SEP": 5.25,
    "OCT": 4.91,
    "NOV": 4.42,
    "DEC": 4.97
  },
  {
    "LONG": -42.15,
    "LAT": -20.39,
    "CIDADE": "São João do Manhuaçu",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 5.03,
    "FEB": 5.52,
    "MAR": 4.97,
    "APR": 4.9,
    "MAY": 4.45,
    "JUN": 4.48,
    "JUL": 4.59,
    "AUG": 5.19,
    "SEP": 5.12,
    "OCT": 4.8,
    "NOV": 4.3,
    "DEC": 4.83
  },
  {
    "LONG": -4.21,
    "LAT": -20.45,
    "CIDADE": "Luisburgo",
    "UF": "MINAS GERAIS",
    "anual": 4.81,
    "JAN": 4.97,
    "FEB": 5.54,
    "MAR": 4.95,
    "APR": 4.89,
    "MAY": 4.47,
    "JUN": 4.43,
    "JUL": 4.53,
    "AUG": 5.13,
    "SEP": 5.04,
    "OCT": 4.76,
    "NOV": 4.24,
    "DEC": 4.73
  },
  {
    "LONG": -41.96,
    "LAT": -20.36,
    "CIDADE": "Manhumirim",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.09,
    "FEB": 5.61,
    "MAR": 5.04,
    "APR": 4.92,
    "MAY": 4.51,
    "JUN": 4.45,
    "JUL": 4.58,
    "AUG": 5.11,
    "SEP": 5.03,
    "OCT": 4.78,
    "NOV": 4.34,
    "DEC": 4.84
  },
  {
    "LONG": -41.97,
    "LAT": -20.42,
    "CIDADE": "Alto Jequitibá",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.09,
    "FEB": 5.61,
    "MAR": 5.04,
    "APR": 4.92,
    "MAY": 4.51,
    "JUN": 4.45,
    "JUL": 4.58,
    "AUG": 5.11,
    "SEP": 5.03,
    "OCT": 4.78,
    "NOV": 4.34,
    "DEC": 4.84
  },
  {
    "LONG": -41.87,
    "LAT": -20.43,
    "CIDADE": "Alto Caparaó",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.06,
    "FEB": 5.54,
    "MAR": 4.93,
    "APR": 4.83,
    "MAY": 4.55,
    "JUN": 4.48,
    "JUL": 4.61,
    "AUG": 5.15,
    "SEP": 5.15,
    "OCT": 4.82,
    "NOV": 4.35,
    "DEC": 4.85
  },
  {
    "LONG": -49.2,
    "LAT": -20.28,
    "CIDADE": "Fronteira",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.23,
    "FEB": 5.72,
    "MAR": 5.36,
    "APR": 5.67,
    "MAY": 5.12,
    "JUN": 4.98,
    "JUL": 5.22,
    "AUG": 5.89,
    "SEP": 5.38,
    "OCT": 5.43,
    "NOV": 5.33,
    "DEC": 5.49
  },
  {
    "LONG": -46.85,
    "LAT": -20.35,
    "CIDADE": "Delfinópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 4.97,
    "FEB": 5.46,
    "MAR": 5.06,
    "APR": 5.45,
    "MAY": 5.2,
    "JUN": 5.11,
    "JUL": 5.34,
    "AUG": 6.03,
    "SEP": 5.56,
    "OCT": 5.41,
    "NOV": 4.97,
    "DEC": 4.97
  },
  {
    "LONG": -46.37,
    "LAT": -20.33,
    "CIDADE": "Vargem Bonita",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 4.92,
    "FEB": 5.52,
    "MAR": 4.82,
    "APR": 5.27,
    "MAY": 5.1,
    "JUN": 5.06,
    "JUL": 5.32,
    "AUG": 6.16,
    "SEP": 5.59,
    "OCT": 5.22,
    "NOV": 4.55,
    "DEC": 4.75
  },
  {
    "LONG": -45.9,
    "LAT": -20.29,
    "CIDADE": "Doresópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.14,
    "FEB": 5.72,
    "MAR": 5.13,
    "APR": 5.48,
    "MAY": 5.27,
    "JUN": 5.15,
    "JUL": 5.4,
    "AUG": 6.19,
    "SEP": 5.72,
    "OCT": 5.38,
    "NOV": 4.77,
    "DEC": 5.02
  },
  {
    "LONG": -45.54,
    "LAT": -20.29,
    "CIDADE": "Arcos",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.26,
    "FEB": 5.68,
    "MAR": 5.24,
    "APR": 5.51,
    "MAY": 5.24,
    "JUN": 5.09,
    "JUL": 5.39,
    "AUG": 6.06,
    "SEP": 5.77,
    "OCT": 5.4,
    "NOV": 4.82,
    "DEC": 5.13
  },
  {
    "LONG": -4.52,
    "LAT": -20.26,
    "CIDADE": "Pedra do Indaiá",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.1,
    "APR": 5.43,
    "MAY": 5.15,
    "JUN": 5.08,
    "JUL": 5.31,
    "AUG": 6.03,
    "SEP": 5.76,
    "OCT": 5.35,
    "NOV": 4.7,
    "DEC": 4.95
  },
  {
    "LONG": -45.01,
    "LAT": -20.28,
    "CIDADE": "São Sebastião do Oeste",
    "UF": "MINAS GERAIS",
    "anual": 5.34,
    "JAN": 5.17,
    "FEB": 5.7,
    "MAR": 5.15,
    "APR": 5.47,
    "MAY": 5.14,
    "JUN": 5.13,
    "JUL": 5.36,
    "AUG": 6.07,
    "SEP": 5.78,
    "OCT": 5.4,
    "NOV": 4.8,
    "DEC": 4.99
  },
  {
    "LONG": -44.31,
    "LAT": -2.03,
    "CIDADE": "Rio Manso",
    "UF": "MINAS GERAIS",
    "anual": 5.27,
    "JAN": 5.14,
    "FEB": 5.61,
    "MAR": 5.14,
    "APR": 5.27,
    "MAY": 5.04,
    "JUN": 5.03,
    "JUL": 5.26,
    "AUG": 6,
    "SEP": 5.72,
    "OCT": 5.33,
    "NOV": 4.77,
    "DEC": 4.92
  },
  {
    "LONG": -44.24,
    "LAT": -20.33,
    "CIDADE": "Bonfim",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.22,
    "FEB": 5.66,
    "MAR": 5.21,
    "APR": 5.34,
    "MAY": 5.05,
    "JUN": 5.02,
    "JUL": 5.23,
    "AUG": 5.97,
    "SEP": 5.71,
    "OCT": 5.36,
    "NOV": 4.83,
    "DEC": 4.99
  },
  {
    "LONG": -44.05,
    "LAT": -20.34,
    "CIDADE": "Moeda",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.25,
    "FEB": 5.66,
    "MAR": 5.27,
    "APR": 5.36,
    "MAY": 5.06,
    "JUN": 5.02,
    "JUL": 5.22,
    "AUG": 5.94,
    "SEP": 5.71,
    "OCT": 5.36,
    "NOV": 4.84,
    "DEC": 5.01
  },
  {
    "LONG": -43.8,
    "LAT": -20.25,
    "CIDADE": "Itabirito",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 4.93,
    "FEB": 5.4,
    "MAR": 4.96,
    "APR": 5.02,
    "MAY": 4.8,
    "JUN": 4.86,
    "JUL": 5.09,
    "AUG": 5.82,
    "SEP": 5.49,
    "OCT": 5.07,
    "NOV": 4.53,
    "DEC": 4.74
  },
  {
    "LONG": -43.04,
    "LAT": -20.29,
    "CIDADE": "Barra Longa",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.11,
    "FEB": 5.67,
    "MAR": 5.12,
    "APR": 5.02,
    "MAY": 4.53,
    "JUN": 4.49,
    "JUL": 4.65,
    "AUG": 5.39,
    "SEP": 5.23,
    "OCT": 4.89,
    "NOV": 4.42,
    "DEC": 4.9
  },
  {
    "LONG": -42.61,
    "LAT": -20.32,
    "CIDADE": "Santo Antônio do Grama",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.27,
    "FEB": 5.85,
    "MAR": 5.29,
    "APR": 5.08,
    "MAY": 4.67,
    "JUN": 4.61,
    "JUL": 4.77,
    "AUG": 5.38,
    "SEP": 5.26,
    "OCT": 5.02,
    "NOV": 4.56,
    "DEC": 5.07
  },
  {
    "LONG": -42.47,
    "LAT": -20.3,
    "CIDADE": "Abre Campo",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.24,
    "FEB": 5.77,
    "MAR": 5.22,
    "APR": 5.03,
    "MAY": 4.62,
    "JUN": 4.57,
    "JUL": 4.67,
    "AUG": 5.31,
    "SEP": 5.21,
    "OCT": 4.96,
    "NOV": 4.53,
    "DEC": 5.07
  },
  {
    "LONG": -42.34,
    "LAT": -20.29,
    "CIDADE": "Matipó",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.18,
    "FEB": 5.71,
    "MAR": 5.15,
    "APR": 5.01,
    "MAY": 4.57,
    "JUN": 4.55,
    "JUL": 4.7,
    "AUG": 5.27,
    "SEP": 5.21,
    "OCT": 4.91,
    "NOV": 4.44,
    "DEC": 5.02
  },
  {
    "LONG": -42.03,
    "LAT": -20.26,
    "CIDADE": "Manhuaçu",
    "UF": "MINAS GERAIS",
    "anual": 4.81,
    "JAN": 5.02,
    "FEB": 5.53,
    "MAR": 4.93,
    "APR": 4.9,
    "MAY": 4.47,
    "JUN": 4.42,
    "JUL": 4.56,
    "AUG": 5.13,
    "SEP": 5.01,
    "OCT": 4.75,
    "NOV": 4.26,
    "DEC": 4.76
  },
  {
    "LONG": -41.88,
    "LAT": -2.03,
    "CIDADE": "Martins Soares",
    "UF": "MINAS GERAIS",
    "anual": 4.83,
    "JAN": 5.08,
    "FEB": 5.59,
    "MAR": 4.99,
    "APR": 4.92,
    "MAY": 4.49,
    "JUN": 4.41,
    "JUL": 4.53,
    "AUG": 5.12,
    "SEP": 5,
    "OCT": 4.71,
    "NOV": 4.34,
    "DEC": 4.82
  },
  {
    "LONG": -46.36,
    "LAT": -20.25,
    "CIDADE": "São Roque de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 4.93,
    "FEB": 5.54,
    "MAR": 4.9,
    "APR": 5.34,
    "MAY": 5.13,
    "JUN": 5.08,
    "JUL": 5.36,
    "AUG": 6.22,
    "SEP": 5.64,
    "OCT": 5.24,
    "NOV": 4.6,
    "DEC": 4.78
  },
  {
    "LONG": -45.71,
    "LAT": -20.18,
    "CIDADE": "Iguatama",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.25,
    "FEB": 5.74,
    "MAR": 5.2,
    "APR": 5.51,
    "MAY": 5.28,
    "JUN": 5.17,
    "JUL": 5.44,
    "AUG": 6.23,
    "SEP": 5.82,
    "OCT": 5.43,
    "NOV": 4.8,
    "DEC": 5.08
  },
  {
    "LONG": -44.77,
    "LAT": -20.19,
    "CIDADE": "Carmo do Cajuru",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.28,
    "FEB": 5.75,
    "MAR": 5.24,
    "APR": 5.46,
    "MAY": 5.13,
    "JUN": 5.12,
    "JUL": 5.34,
    "AUG": 6.04,
    "SEP": 5.77,
    "OCT": 5.47,
    "NOV": 4.89,
    "DEC": 5.05
  },
  {
    "LONG": -44.42,
    "LAT": -20.2,
    "CIDADE": "Itatiaiuçu",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.18,
    "FEB": 5.61,
    "MAR": 5.14,
    "APR": 5.29,
    "MAY": 5.06,
    "JUN": 5.08,
    "JUL": 5.31,
    "AUG": 6.03,
    "SEP": 5.79,
    "OCT": 5.37,
    "NOV": 4.8,
    "DEC": 4.93
  },
  {
    "LONG": -4.42,
    "LAT": -20.15,
    "CIDADE": "Brumadinho",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.28,
    "FEB": 5.65,
    "MAR": 5.22,
    "APR": 5.33,
    "MAY": 5.04,
    "JUN": 5,
    "JUL": 5.23,
    "AUG": 5.97,
    "SEP": 5.74,
    "OCT": 5.4,
    "NOV": 4.85,
    "DEC": 5
  },
  {
    "LONG": -4.3,
    "LAT": -20.16,
    "CIDADE": "Dom Silvério",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.04,
    "FEB": 5.61,
    "MAR": 5.03,
    "APR": 4.95,
    "MAY": 4.52,
    "JUN": 4.49,
    "JUL": 4.66,
    "AUG": 5.34,
    "SEP": 5.19,
    "OCT": 4.89,
    "NOV": 4.4,
    "DEC": 4.86
  },
  {
    "LONG": -42.9,
    "LAT": -20.24,
    "CIDADE": "Rio Doce",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.04,
    "FEB": 5.61,
    "MAR": 5.03,
    "APR": 4.95,
    "MAY": 4.52,
    "JUN": 4.49,
    "JUL": 4.66,
    "AUG": 5.34,
    "SEP": 5.19,
    "OCT": 4.89,
    "NOV": 4.4,
    "DEC": 4.86
  },
  {
    "LONG": -42.82,
    "LAT": -20.24,
    "CIDADE": "Santa Cruz do Escalvado",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.17,
    "FEB": 5.77,
    "MAR": 5.2,
    "APR": 5.11,
    "MAY": 4.58,
    "JUN": 4.51,
    "JUL": 4.77,
    "AUG": 5.4,
    "SEP": 5.28,
    "OCT": 5,
    "NOV": 4.48,
    "DEC": 4.92
  },
  {
    "LONG": -42.74,
    "LAT": -20.24,
    "CIDADE": "Piedade de Ponte Nova",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.24,
    "FEB": 5.83,
    "MAR": 5.25,
    "APR": 5.14,
    "MAY": 4.65,
    "JUN": 4.58,
    "JUL": 4.8,
    "AUG": 5.41,
    "SEP": 5.28,
    "OCT": 5.02,
    "NOV": 4.56,
    "DEC": 5.02
  },
  {
    "LONG": -42.65,
    "LAT": -2.02,
    "CIDADE": "Rio Casca",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.27,
    "FEB": 5.82,
    "MAR": 5.24,
    "APR": 5.14,
    "MAY": 4.69,
    "JUN": 4.61,
    "JUL": 4.79,
    "AUG": 5.38,
    "SEP": 5.26,
    "OCT": 5.04,
    "NOV": 4.58,
    "DEC": 5.09
  },
  {
    "LONG": -42.53,
    "LAT": -20.17,
    "CIDADE": "São Pedro dos Ferros",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 5.31,
    "FEB": 5.81,
    "MAR": 5.28,
    "APR": 5.18,
    "MAY": 4.73,
    "JUN": 4.61,
    "JUL": 4.76,
    "AUG": 5.36,
    "SEP": 5.24,
    "OCT": 5.03,
    "NOV": 4.63,
    "DEC": 5.11
  },
  {
    "LONG": -42.27,
    "LAT": -20.17,
    "CIDADE": "Caputira",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.14,
    "FEB": 5.61,
    "MAR": 5.11,
    "APR": 5.04,
    "MAY": 4.59,
    "JUN": 4.56,
    "JUL": 4.7,
    "AUG": 5.3,
    "SEP": 5.21,
    "OCT": 4.92,
    "NOV": 4.48,
    "DEC": 4.99
  },
  {
    "LONG": -41.99,
    "LAT": -20.24,
    "CIDADE": "Reduto",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5.14,
    "FEB": 5.62,
    "MAR": 5.04,
    "APR": 4.97,
    "MAY": 4.53,
    "JUN": 4.44,
    "JUL": 4.59,
    "AUG": 5.12,
    "SEP": 5.02,
    "OCT": 4.77,
    "NOV": 4.37,
    "DEC": 4.82
  },
  {
    "LONG": -41.8,
    "LAT": -20.21,
    "CIDADE": "Durandé",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.24,
    "FEB": 5.69,
    "MAR": 5.14,
    "APR": 5.06,
    "MAY": 4.53,
    "JUN": 4.48,
    "JUL": 4.59,
    "AUG": 5.1,
    "SEP": 5.03,
    "OCT": 4.75,
    "NOV": 4.45,
    "DEC": 4.9
  },
  {
    "LONG": -41.62,
    "LAT": -20.15,
    "CIDADE": "Lajinha",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.28,
    "FEB": 5.71,
    "MAR": 5.15,
    "APR": 5.07,
    "MAY": 4.58,
    "JUN": 4.48,
    "JUL": 4.56,
    "AUG": 5.09,
    "SEP": 5.02,
    "OCT": 4.75,
    "NOV": 4.45,
    "DEC": 4.95
  },
  {
    "LONG": -4.87,
    "LAT": -2.01,
    "CIDADE": "Planura",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.24,
    "FEB": 5.75,
    "MAR": 5.32,
    "APR": 5.63,
    "MAY": 5.14,
    "JUN": 4.96,
    "JUL": 5.25,
    "AUG": 6,
    "SEP": 5.42,
    "OCT": 5.38,
    "NOV": 5.21,
    "DEC": 5.33
  },
  {
    "LONG": -48.11,
    "LAT": -2.01,
    "CIDADE": "Água Comprida",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.15,
    "FEB": 5.71,
    "MAR": 5.33,
    "APR": 5.67,
    "MAY": 5.2,
    "JUN": 4.93,
    "JUL": 5.24,
    "AUG": 5.92,
    "SEP": 5.36,
    "OCT": 5.38,
    "NOV": 5.25,
    "DEC": 5.32
  },
  {
    "LONG": -45.5,
    "LAT": -20.14,
    "CIDADE": "Japaraíba",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.35,
    "FEB": 5.69,
    "MAR": 5.26,
    "APR": 5.58,
    "MAY": 5.25,
    "JUN": 5.18,
    "JUL": 5.44,
    "AUG": 6.13,
    "SEP": 5.84,
    "OCT": 5.47,
    "NOV": 4.89,
    "DEC": 5.1
  },
  {
    "LONG": -45.3,
    "LAT": -20.09,
    "CIDADE": "Santo Antônio do Monte",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 5.21,
    "FEB": 5.68,
    "MAR": 5.12,
    "APR": 5.46,
    "MAY": 5.16,
    "JUN": 5.1,
    "JUL": 5.33,
    "AUG": 6.04,
    "SEP": 5.8,
    "OCT": 5.41,
    "NOV": 4.86,
    "DEC": 5.04
  },
  {
    "LONG": -44.89,
    "LAT": -20.15,
    "CIDADE": "Divinópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.32,
    "FEB": 5.75,
    "MAR": 5.3,
    "APR": 5.52,
    "MAY": 5.16,
    "JUN": 5.15,
    "JUL": 5.36,
    "AUG": 6.09,
    "SEP": 5.82,
    "OCT": 5.44,
    "NOV": 4.91,
    "DEC": 5.05
  },
  {
    "LONG": -44.58,
    "LAT": -20.08,
    "CIDADE": "Itaúna",
    "UF": "MINAS GERAIS",
    "anual": 5.34,
    "JAN": 5.2,
    "FEB": 5.68,
    "MAR": 5.18,
    "APR": 5.41,
    "MAY": 5.05,
    "JUN": 5.11,
    "JUL": 5.34,
    "AUG": 6.02,
    "SEP": 5.79,
    "OCT": 5.41,
    "NOV": 4.85,
    "DEC": 5
  },
  {
    "LONG": -44.3,
    "LAT": -20.07,
    "CIDADE": "Igarapé",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.31,
    "FEB": 5.71,
    "MAR": 5.25,
    "APR": 5.34,
    "MAY": 5.07,
    "JUN": 5.1,
    "JUL": 5.35,
    "AUG": 6.05,
    "SEP": 5.82,
    "OCT": 5.45,
    "NOV": 4.88,
    "DEC": 4.97
  },
  {
    "LONG": -44.19,
    "LAT": -20.06,
    "CIDADE": "Mário Campos",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 5.31,
    "FEB": 5.65,
    "MAR": 5.28,
    "APR": 5.35,
    "MAY": 5.05,
    "JUN": 5.05,
    "JUL": 5.26,
    "AUG": 6.02,
    "SEP": 5.83,
    "OCT": 5.44,
    "NOV": 4.88,
    "DEC": 5.07
  },
  {
    "LONG": -43.79,
    "LAT": -2.01,
    "CIDADE": "Rio Acima",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.06,
    "FEB": 5.65,
    "MAR": 5.23,
    "APR": 5.2,
    "MAY": 4.94,
    "JUN": 5,
    "JUL": 5.13,
    "AUG": 5.88,
    "SEP": 5.67,
    "OCT": 5.3,
    "NOV": 4.72,
    "DEC": 4.91
  },
  {
    "LONG": -43.41,
    "LAT": -20.07,
    "CIDADE": "Catas Altas",
    "UF": "MINAS GERAIS",
    "anual": 4.81,
    "JAN": 4.77,
    "FEB": 5.38,
    "MAR": 4.77,
    "APR": 4.74,
    "MAY": 4.45,
    "JUN": 4.54,
    "JUL": 4.71,
    "AUG": 5.41,
    "SEP": 5.24,
    "OCT": 4.87,
    "NOV": 4.23,
    "DEC": 4.62
  },
  {
    "LONG": -43.05,
    "LAT": -20.11,
    "CIDADE": "Alvinópolis",
    "UF": "MINAS GERAIS",
    "anual": 4.87,
    "JAN": 5,
    "FEB": 5.52,
    "MAR": 4.93,
    "APR": 4.9,
    "MAY": 4.47,
    "JUN": 4.48,
    "JUL": 4.66,
    "AUG": 5.3,
    "SEP": 5.17,
    "OCT": 4.81,
    "NOV": 4.34,
    "DEC": 4.8
  },
  {
    "LONG": -42.85,
    "LAT": -20.1,
    "CIDADE": "Sem-Peixe",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.09,
    "FEB": 5.67,
    "MAR": 5.05,
    "APR": 4.98,
    "MAY": 4.49,
    "JUN": 4.43,
    "JUL": 4.66,
    "AUG": 5.27,
    "SEP": 5.17,
    "OCT": 4.88,
    "NOV": 4.37,
    "DEC": 4.81
  },
  {
    "LONG": -42.45,
    "LAT": -20.11,
    "CIDADE": "Raul Soares",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 5.38,
    "FEB": 5.79,
    "MAR": 5.28,
    "APR": 5.24,
    "MAY": 4.76,
    "JUN": 4.66,
    "JUL": 4.74,
    "AUG": 5.36,
    "SEP": 5.25,
    "OCT": 5.03,
    "NOV": 4.66,
    "DEC": 5.17
  },
  {
    "LONG": -42.01,
    "LAT": -20.13,
    "CIDADE": "Simonésia",
    "UF": "MINAS GERAIS",
    "anual": 4.66,
    "JAN": 4.86,
    "FEB": 5.38,
    "MAR": 4.71,
    "APR": 4.65,
    "MAY": 4.28,
    "JUN": 4.28,
    "JUL": 4.45,
    "AUG": 5.06,
    "SEP": 4.92,
    "OCT": 4.55,
    "NOV": 4.14,
    "DEC": 4.64
  },
  {
    "LONG": -41.93,
    "LAT": -20.1,
    "CIDADE": "Santana do Manhuaçu",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.13,
    "FEB": 5.6,
    "MAR": 5,
    "APR": 4.94,
    "MAY": 4.52,
    "JUN": 4.44,
    "JUL": 4.57,
    "AUG": 5.12,
    "SEP": 4.98,
    "OCT": 4.69,
    "NOV": 4.32,
    "DEC": 4.79
  },
  {
    "LONG": -48.94,
    "LAT": -20.03,
    "CIDADE": "Frutal",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.17,
    "FEB": 5.66,
    "MAR": 5.32,
    "APR": 5.61,
    "MAY": 5.19,
    "JUN": 5.04,
    "JUL": 5.28,
    "AUG": 6.01,
    "SEP": 5.43,
    "OCT": 5.41,
    "NOV": 5.22,
    "DEC": 5.35
  },
  {
    "LONG": -47.77,
    "LAT": -19.97,
    "CIDADE": "Delta",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.05,
    "FEB": 5.62,
    "MAR": 5.25,
    "APR": 5.64,
    "MAY": 5.24,
    "JUN": 5.15,
    "JUL": 5.4,
    "AUG": 6.1,
    "SEP": 5.49,
    "OCT": 5.43,
    "NOV": 5.11,
    "DEC": 5.19
  },
  {
    "LONG": -46.23,
    "LAT": -19.99,
    "CIDADE": "Medeiros",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 4.94,
    "FEB": 5.51,
    "MAR": 4.86,
    "APR": 5.33,
    "MAY": 5.17,
    "JUN": 5.14,
    "JUL": 5.38,
    "AUG": 6.26,
    "SEP": 5.67,
    "OCT": 5.22,
    "NOV": 4.57,
    "DEC": 4.81
  },
  {
    "LONG": -45.98,
    "LAT": -20.02,
    "CIDADE": "Bambuí",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.23,
    "FEB": 5.77,
    "MAR": 5.14,
    "APR": 5.55,
    "MAY": 5.32,
    "JUN": 5.25,
    "JUL": 5.49,
    "AUG": 6.34,
    "SEP": 5.83,
    "OCT": 5.4,
    "NOV": 4.79,
    "DEC": 5.03
  },
  {
    "LONG": -45.54,
    "LAT": -20.02,
    "CIDADE": "Lagoa da Prata",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.33,
    "FEB": 5.78,
    "MAR": 5.32,
    "APR": 5.61,
    "MAY": 5.28,
    "JUN": 5.19,
    "JUL": 5.43,
    "AUG": 6.16,
    "SEP": 5.85,
    "OCT": 5.47,
    "NOV": 4.9,
    "DEC": 5.1
  },
  {
    "LONG": -45.09,
    "LAT": -19.95,
    "CIDADE": "Perdigão",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.29,
    "FEB": 5.73,
    "MAR": 5.25,
    "APR": 5.52,
    "MAY": 5.21,
    "JUN": 5.2,
    "JUL": 5.39,
    "AUG": 6.13,
    "SEP": 5.83,
    "OCT": 5.42,
    "NOV": 4.87,
    "DEC": 5.04
  },
  {
    "LONG": -44.86,
    "LAT": -19.98,
    "CIDADE": "São Gonçalo do Pará",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.35,
    "FEB": 5.77,
    "MAR": 5.3,
    "APR": 5.48,
    "MAY": 5.14,
    "JUN": 5.2,
    "JUL": 5.39,
    "AUG": 6.11,
    "SEP": 5.81,
    "OCT": 5.45,
    "NOV": 4.92,
    "DEC": 5.06
  },
  {
    "LONG": -4.44,
    "LAT": -2,
    "CIDADE": "Mateus Leme",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 5.27,
    "FEB": 5.73,
    "MAR": 5.21,
    "APR": 5.4,
    "MAY": 5.06,
    "JUN": 5.11,
    "JUL": 5.37,
    "AUG": 6.05,
    "SEP": 5.78,
    "OCT": 5.41,
    "NOV": 4.84,
    "DEC": 4.96
  },
  {
    "LONG": -44.34,
    "LAT": -19.96,
    "CIDADE": "Juatuba",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.3,
    "FEB": 5.74,
    "MAR": 5.3,
    "APR": 5.44,
    "MAY": 5.07,
    "JUN": 5.1,
    "JUL": 5.34,
    "AUG": 6.01,
    "SEP": 5.78,
    "OCT": 5.43,
    "NOV": 4.88,
    "DEC": 4.97
  },
  {
    "LONG": -44.2,
    "LAT": -19.97,
    "CIDADE": "Betim",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.31,
    "FEB": 5.75,
    "MAR": 5.34,
    "APR": 5.44,
    "MAY": 5.09,
    "JUN": 5.12,
    "JUL": 5.36,
    "AUG": 6.03,
    "SEP": 5.84,
    "OCT": 5.46,
    "NOV": 4.91,
    "DEC": 5.02
  },
  {
    "LONG": -44.28,
    "LAT": -20.05,
    "CIDADE": "São Joaquim de Bicas",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.31,
    "FEB": 5.75,
    "MAR": 5.34,
    "APR": 5.44,
    "MAY": 5.09,
    "JUN": 5.12,
    "JUL": 5.36,
    "AUG": 6.03,
    "SEP": 5.84,
    "OCT": 5.46,
    "NOV": 4.91,
    "DEC": 5.02
  },
  {
    "LONG": -4.41,
    "LAT": -20.04,
    "CIDADE": "Sarzedo",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.25,
    "FEB": 5.72,
    "MAR": 5.31,
    "APR": 5.4,
    "MAY": 5.09,
    "JUN": 5.09,
    "JUL": 5.34,
    "AUG": 6.02,
    "SEP": 5.86,
    "OCT": 5.47,
    "NOV": 4.88,
    "DEC": 5.02
  },
  {
    "LONG": -44.06,
    "LAT": -20.03,
    "CIDADE": "Ibirité",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.13,
    "FEB": 5.64,
    "MAR": 5.23,
    "APR": 5.3,
    "MAY": 5.04,
    "JUN": 5.02,
    "JUL": 5.28,
    "AUG": 5.99,
    "SEP": 5.88,
    "OCT": 5.48,
    "NOV": 4.85,
    "DEC": 4.99
  },
  {
    "LONG": -4.38,
    "LAT": -2,
    "CIDADE": "Raposos",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.12,
    "FEB": 5.64,
    "MAR": 5.2,
    "APR": 5.24,
    "MAY": 4.89,
    "JUN": 4.99,
    "JUL": 5.2,
    "AUG": 5.88,
    "SEP": 5.68,
    "OCT": 5.33,
    "NOV": 4.74,
    "DEC": 4.91
  },
  {
    "LONG": -43.85,
    "LAT": -19.98,
    "CIDADE": "Nova Lima",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.12,
    "FEB": 5.64,
    "MAR": 5.2,
    "APR": 5.24,
    "MAY": 4.89,
    "JUN": 4.99,
    "JUL": 5.2,
    "AUG": 5.88,
    "SEP": 5.68,
    "OCT": 5.33,
    "NOV": 4.74,
    "DEC": 4.91
  },
  {
    "LONG": -43.41,
    "LAT": -19.96,
    "CIDADE": "Santa Bárbara",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 4.92,
    "FEB": 5.54,
    "MAR": 4.97,
    "APR": 4.93,
    "MAY": 4.58,
    "JUN": 4.56,
    "JUL": 4.75,
    "AUG": 5.41,
    "SEP": 5.23,
    "OCT": 4.91,
    "NOV": 4.42,
    "DEC": 4.72
  },
  {
    "LONG": -42.27,
    "LAT": -20.04,
    "CIDADE": "Vermelho Novo",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.22,
    "FEB": 5.68,
    "MAR": 5.07,
    "APR": 5,
    "MAY": 4.55,
    "JUN": 4.55,
    "JUL": 4.61,
    "AUG": 5.21,
    "SEP": 5.19,
    "OCT": 4.9,
    "NOV": 4.48,
    "DEC": 5.03
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2,
    "CIDADE": "Santa Bárbara do Leste",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.12,
    "FEB": 5.54,
    "MAR": 4.92,
    "APR": 4.86,
    "MAY": 4.43,
    "JUN": 4.46,
    "JUL": 4.53,
    "AUG": 5.17,
    "SEP": 5.17,
    "OCT": 4.81,
    "NOV": 4.38,
    "DEC": 4.91
  },
  {
    "LONG": -41.75,
    "LAT": -20.01,
    "CIDADE": "São José do Mantimento",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.25,
    "FEB": 5.73,
    "MAR": 5.16,
    "APR": 5.12,
    "MAY": 4.56,
    "JUN": 4.49,
    "JUL": 4.52,
    "AUG": 5.05,
    "SEP": 5,
    "OCT": 4.76,
    "NOV": 4.45,
    "DEC": 4.97
  },
  {
    "LONG": -41.69,
    "LAT": -20.05,
    "CIDADE": "Chalé",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.27,
    "FEB": 5.79,
    "MAR": 5.23,
    "APR": 5.18,
    "MAY": 4.62,
    "JUN": 4.49,
    "JUL": 4.55,
    "AUG": 5.08,
    "SEP": 5,
    "OCT": 4.8,
    "NOV": 4.5,
    "DEC": 5.01
  },
  {
    "LONG": -49.77,
    "LAT": -19.86,
    "CIDADE": "São Francisco de Sales",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.21,
    "FEB": 5.59,
    "MAR": 5.38,
    "APR": 5.6,
    "MAY": 5.12,
    "JUN": 4.98,
    "JUL": 5.22,
    "AUG": 5.91,
    "SEP": 5.41,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.42
  },
  {
    "LONG": -49.37,
    "LAT": -19.9,
    "CIDADE": "Itapagipe",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.16,
    "FEB": 5.63,
    "MAR": 5.34,
    "APR": 5.62,
    "MAY": 5.18,
    "JUN": 5.01,
    "JUL": 5.28,
    "AUG": 5.99,
    "SEP": 5.42,
    "OCT": 5.42,
    "NOV": 5.32,
    "DEC": 5.43
  },
  {
    "LONG": -48.7,
    "LAT": -19.91,
    "CIDADE": "Pirajuba",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.16,
    "FEB": 5.71,
    "MAR": 5.28,
    "APR": 5.6,
    "MAY": 5.19,
    "JUN": 5.09,
    "JUL": 5.32,
    "AUG": 6.09,
    "SEP": 5.47,
    "OCT": 5.35,
    "NOV": 5.18,
    "DEC": 5.35
  },
  {
    "LONG": -48.38,
    "LAT": -19.92,
    "CIDADE": "Conceição das Alagoas",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.18,
    "FEB": 5.66,
    "MAR": 5.23,
    "APR": 5.57,
    "MAY": 5.27,
    "JUN": 5.16,
    "JUL": 5.39,
    "AUG": 6.12,
    "SEP": 5.48,
    "OCT": 5.38,
    "NOV": 5.18,
    "DEC": 5.3
  },
  {
    "LONG": -47.54,
    "LAT": -19.94,
    "CIDADE": "Conquista",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 4.96,
    "FEB": 5.56,
    "MAR": 5.07,
    "APR": 5.5,
    "MAY": 5.3,
    "JUN": 5.2,
    "JUL": 5.45,
    "AUG": 6.18,
    "SEP": 5.53,
    "OCT": 5.36,
    "NOV": 4.95,
    "DEC": 5.15
  },
  {
    "LONG": -47.44,
    "LAT": "#VALOR!",
    "CIDADE": "Sacramento",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 4.93,
    "FEB": 5.53,
    "MAR": 5.09,
    "APR": 5.48,
    "MAY": 5.31,
    "JUN": 5.21,
    "JUL": 5.51,
    "AUG": 6.15,
    "SEP": 5.56,
    "OCT": 5.39,
    "NOV": 4.99,
    "DEC": 5.14
  },
  {
    "LONG": -46.82,
    "LAT": -19.92,
    "CIDADE": "Tapira",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 4.92,
    "FEB": 5.44,
    "MAR": 4.94,
    "APR": 5.35,
    "MAY": 5.26,
    "JUN": 5.17,
    "JUL": 5.4,
    "AUG": 6.27,
    "SEP": 5.65,
    "OCT": 5.36,
    "NOV": 4.79,
    "DEC": 4.92
  },
  {
    "LONG": -46.02,
    "LAT": -1.99,
    "CIDADE": "Tapiraí",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.18,
    "FEB": 5.72,
    "MAR": 5.07,
    "APR": 5.53,
    "MAY": 5.3,
    "JUN": 5.27,
    "JUL": 5.5,
    "AUG": 6.34,
    "SEP": 5.79,
    "OCT": 5.35,
    "NOV": 4.7,
    "DEC": 4.93
  },
  {
    "LONG": -45.17,
    "LAT": -19.95,
    "CIDADE": "Araújos",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.28,
    "FEB": 5.75,
    "MAR": 5.26,
    "APR": 5.57,
    "MAY": 5.23,
    "JUN": 5.24,
    "JUL": 5.4,
    "AUG": 6.15,
    "SEP": 5.85,
    "OCT": 5.46,
    "NOV": 4.86,
    "DEC": 5.08
  },
  {
    "LONG": -4.5,
    "LAT": -19.87,
    "CIDADE": "Nova Serrana",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.25,
    "FEB": 5.75,
    "MAR": 5.31,
    "APR": 5.48,
    "MAY": 5.17,
    "JUN": 5.12,
    "JUL": 5.34,
    "AUG": 6.06,
    "SEP": 5.81,
    "OCT": 5.46,
    "NOV": 4.92,
    "DEC": 5.05
  },
  {
    "LONG": -44.71,
    "LAT": -19.95,
    "CIDADE": "Igaratinga",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.31,
    "FEB": 5.79,
    "MAR": 5.32,
    "APR": 5.49,
    "MAY": 5.17,
    "JUN": 5.16,
    "JUL": 5.38,
    "AUG": 6.08,
    "SEP": 5.83,
    "OCT": 5.45,
    "NOV": 4.96,
    "DEC": 5.05
  },
  {
    "LONG": -44.61,
    "LAT": -19.85,
    "CIDADE": "Pará de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.23,
    "FEB": 5.77,
    "MAR": 5.23,
    "APR": 5.5,
    "MAY": 5.14,
    "JUN": 5.15,
    "JUL": 5.38,
    "AUG": 6.02,
    "SEP": 5.84,
    "OCT": 5.41,
    "NOV": 4.92,
    "DEC": 5.02
  },
  {
    "LONG": -44.43,
    "LAT": -19.89,
    "CIDADE": "Florestal",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.32,
    "FEB": 5.77,
    "MAR": 5.27,
    "APR": 5.44,
    "MAY": 5.15,
    "JUN": 5.16,
    "JUL": 5.38,
    "AUG": 6.07,
    "SEP": 5.83,
    "OCT": 5.44,
    "NOV": 4.89,
    "DEC": 5.03
  },
  {
    "LONG": -44.05,
    "LAT": -19.93,
    "CIDADE": "Contagem",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.24,
    "FEB": 5.72,
    "MAR": 5.32,
    "APR": 5.39,
    "MAY": 5.03,
    "JUN": 5.04,
    "JUL": 5.28,
    "AUG": 5.97,
    "SEP": 5.88,
    "OCT": 5.51,
    "NOV": 4.89,
    "DEC": 5.03
  },
  {
    "LONG": -43.93,
    "LAT": -19.91,
    "CIDADE": "Belo Horizonte",
    "UF": "MINAS GERAIS",
    "anual": 5.35,
    "JAN": 5.25,
    "FEB": 5.71,
    "MAR": 5.3,
    "APR": 5.33,
    "MAY": 4.99,
    "JUN": 5.05,
    "JUL": 5.25,
    "AUG": 5.95,
    "SEP": 5.87,
    "OCT": 5.52,
    "NOV": 4.94,
    "DEC": 5.04
  },
  {
    "LONG": -43.83,
    "LAT": -19.88,
    "CIDADE": "Sabará",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.26,
    "FEB": 5.7,
    "MAR": 5.27,
    "APR": 5.32,
    "MAY": 4.94,
    "JUN": 5.03,
    "JUL": 5.23,
    "AUG": 5.88,
    "SEP": 5.8,
    "OCT": 5.44,
    "NOV": 4.88,
    "DEC": 5.04
  },
  {
    "LONG": -43.67,
    "LAT": -19.88,
    "CIDADE": "Caeté",
    "UF": "MINAS GERAIS",
    "anual": 5.19,
    "JAN": 5.08,
    "FEB": 5.63,
    "MAR": 5.2,
    "APR": 5.18,
    "MAY": 4.89,
    "JUN": 4.9,
    "JUL": 5.08,
    "AUG": 5.78,
    "SEP": 5.62,
    "OCT": 5.29,
    "NOV": 4.72,
    "DEC": 4.96
  },
  {
    "LONG": -43.48,
    "LAT": -19.94,
    "CIDADE": "Barão de Cocais",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 4.95,
    "FEB": 5.46,
    "MAR": 4.88,
    "APR": 4.84,
    "MAY": 4.55,
    "JUN": 4.52,
    "JUL": 4.73,
    "AUG": 5.43,
    "SEP": 5.23,
    "OCT": 4.88,
    "NOV": 4.44,
    "DEC": 4.74
  },
  {
    "LONG": -4.32,
    "LAT": -1.99,
    "CIDADE": "Rio Piracicaba",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 4.95,
    "FEB": 5.52,
    "MAR": 4.92,
    "APR": 4.86,
    "MAY": 4.48,
    "JUN": 4.47,
    "JUL": 4.65,
    "AUG": 5.28,
    "SEP": 5.17,
    "OCT": 4.83,
    "NOV": 4.38,
    "DEC": 4.81
  },
  {
    "LONG": -42.97,
    "LAT": -19.87,
    "CIDADE": "São Domingos do Prata",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.05,
    "FEB": 5.65,
    "MAR": 5.03,
    "APR": 4.93,
    "MAY": 4.5,
    "JUN": 4.4,
    "JUL": 4.65,
    "AUG": 5.29,
    "SEP": 5.19,
    "OCT": 4.89,
    "NOV": 4.38,
    "DEC": 4.91
  },
  {
    "LONG": -42.7,
    "LAT": -19.92,
    "CIDADE": "São José do Goiabal",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.16,
    "FEB": 5.75,
    "MAR": 5.17,
    "APR": 5.12,
    "MAY": 4.6,
    "JUN": 4.54,
    "JUL": 4.75,
    "AUG": 5.27,
    "SEP": 5.19,
    "OCT": 4.95,
    "NOV": 4.44,
    "DEC": 4.92
  },
  {
    "LONG": -42.13,
    "LAT": -19.87,
    "CIDADE": "Santa Rita de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.02,
    "APR": 4.93,
    "MAY": 4.5,
    "JUN": 4.44,
    "JUL": 4.55,
    "AUG": 5.13,
    "SEP": 5.16,
    "OCT": 4.82,
    "NOV": 4.42,
    "DEC": 4.99
  },
  {
    "LONG": -41.69,
    "LAT": -19.93,
    "CIDADE": "Conceição de Ipanema",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.33,
    "FEB": 5.85,
    "MAR": 5.27,
    "APR": 5.21,
    "MAY": 4.65,
    "JUN": 4.5,
    "JUL": 4.58,
    "AUG": 5.09,
    "SEP": 5.01,
    "OCT": 4.81,
    "NOV": 4.54,
    "DEC": 5.05
  },
  {
    "LONG": -4.86,
    "LAT": -19.76,
    "CIDADE": "Campo Florido",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.12,
    "FEB": 5.65,
    "MAR": 5.25,
    "APR": 5.61,
    "MAY": 5.26,
    "JUN": 5.11,
    "JUL": 5.37,
    "AUG": 6.1,
    "SEP": 5.49,
    "OCT": 5.37,
    "NOV": 5.18,
    "DEC": 5.27
  },
  {
    "LONG": -46.38,
    "LAT": -19.75,
    "CIDADE": "Pratinha",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.02,
    "FEB": 5.51,
    "MAR": 4.92,
    "APR": 5.27,
    "MAY": 5.19,
    "JUN": 5.19,
    "JUL": 5.41,
    "AUG": 6.35,
    "SEP": 5.79,
    "OCT": 5.32,
    "NOV": 4.66,
    "DEC": 4.94
  },
  {
    "LONG": -45.9,
    "LAT": -19.82,
    "CIDADE": "Córrego Danta",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.14,
    "FEB": 5.67,
    "MAR": 5.04,
    "APR": 5.48,
    "MAY": 5.29,
    "JUN": 5.26,
    "JUL": 5.51,
    "AUG": 6.37,
    "SEP": 5.8,
    "OCT": 5.28,
    "NOV": 4.67,
    "DEC": 4.86
  },
  {
    "LONG": -45.68,
    "LAT": -19.79,
    "CIDADE": "Luz",
    "UF": "MINAS GERAIS",
    "anual": 5.47,
    "JAN": 5.31,
    "FEB": 5.83,
    "MAR": 5.26,
    "APR": 5.59,
    "MAY": 5.35,
    "JUN": 5.25,
    "JUL": 5.52,
    "AUG": 6.25,
    "SEP": 5.86,
    "OCT": 5.47,
    "NOV": 4.9,
    "DEC": 5.07
  },
  {
    "LONG": -45.41,
    "LAT": -19.84,
    "CIDADE": "Moema",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.33,
    "FEB": 5.87,
    "MAR": 5.28,
    "APR": 5.62,
    "MAY": 5.34,
    "JUN": 5.24,
    "JUL": 5.47,
    "AUG": 6.2,
    "SEP": 5.84,
    "OCT": 5.49,
    "NOV": 4.97,
    "DEC": 5.1
  },
  {
    "LONG": -4.43,
    "LAT": -1.98,
    "CIDADE": "Esmeraldas",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.35,
    "FEB": 5.8,
    "MAR": 5.33,
    "APR": 5.49,
    "MAY": 5.17,
    "JUN": 5.16,
    "JUL": 5.38,
    "AUG": 6.04,
    "SEP": 5.88,
    "OCT": 5.48,
    "NOV": 4.94,
    "DEC": 5.1
  },
  {
    "LONG": -44.08,
    "LAT": -19.76,
    "CIDADE": "Ribeirão das Neves",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.27,
    "FEB": 5.76,
    "MAR": 5.33,
    "APR": 5.41,
    "MAY": 5.08,
    "JUN": 5.03,
    "JUL": 5.29,
    "AUG": 5.97,
    "SEP": 5.87,
    "OCT": 5.49,
    "NOV": 4.94,
    "DEC": 5.01
  },
  {
    "LONG": "#VALOR!",
    "LAT": -19.76,
    "CIDADE": "Santa Luzia",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.32,
    "FEB": 5.82,
    "MAR": 5.46,
    "APR": 5.45,
    "MAY": 5.05,
    "JUN": 5.08,
    "JUL": 5.33,
    "AUG": 5.98,
    "SEP": 5.87,
    "OCT": 5.51,
    "NOV": 4.98,
    "DEC": 5.11
  },
  {
    "LONG": -43.37,
    "LAT": -19.82,
    "CIDADE": "São Gonçalo do Rio Abaixo",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 5.02,
    "APR": 4.97,
    "MAY": 4.56,
    "JUN": 4.55,
    "JUL": 4.79,
    "AUG": 5.44,
    "SEP": 5.29,
    "OCT": 4.98,
    "NOV": 4.48,
    "DEC": 4.86
  },
  {
    "LONG": -43.17,
    "LAT": -19.81,
    "CIDADE": "João Monlevade",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 4.99,
    "FEB": 5.54,
    "MAR": 5,
    "APR": 4.93,
    "MAY": 4.52,
    "JUN": 4.53,
    "JUL": 4.73,
    "AUG": 5.38,
    "SEP": 5.23,
    "OCT": 4.9,
    "NOV": 4.39,
    "DEC": 4.83
  },
  {
    "LONG": -43.03,
    "LAT": -1.98,
    "CIDADE": "Nova Era",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.12,
    "FEB": 5.67,
    "MAR": 5.1,
    "APR": 4.99,
    "MAY": 4.55,
    "JUN": 4.45,
    "JUL": 4.7,
    "AUG": 5.34,
    "SEP": 5.24,
    "OCT": 4.93,
    "NOV": 4.41,
    "DEC": 4.91
  },
  {
    "LONG": -43.09,
    "LAT": -19.83,
    "CIDADE": "Bela Vista de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.12,
    "FEB": 5.67,
    "MAR": 5.1,
    "APR": 4.99,
    "MAY": 4.55,
    "JUN": 4.45,
    "JUL": 4.7,
    "AUG": 5.34,
    "SEP": 5.24,
    "OCT": 4.93,
    "NOV": 4.41,
    "DEC": 4.91
  },
  {
    "LONG": -4.28,
    "LAT": -1.98,
    "CIDADE": "Dionísio",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.05,
    "FEB": 5.64,
    "MAR": 4.99,
    "APR": 5,
    "MAY": 4.5,
    "JUN": 4.44,
    "JUL": 4.64,
    "AUG": 5.17,
    "SEP": 5.06,
    "OCT": 4.85,
    "NOV": 4.35,
    "DEC": 4.86
  },
  {
    "LONG": -42.4,
    "LAT": -19.84,
    "CIDADE": "Córrego Novo",
    "UF": "MINAS GERAIS",
    "anual": 5.15,
    "JAN": 5.37,
    "FEB": 5.93,
    "MAR": 5.34,
    "APR": 5.28,
    "MAY": 4.79,
    "JUN": 4.71,
    "JUL": 4.82,
    "AUG": 5.36,
    "SEP": 5.27,
    "OCT": 5.06,
    "NOV": 4.67,
    "DEC": 5.22
  },
  {
    "LONG": -4.23,
    "LAT": -19.84,
    "CIDADE": "Bom Jesus do Galho",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.25,
    "FEB": 5.74,
    "MAR": 5.16,
    "APR": 5.13,
    "MAY": 4.68,
    "JUN": 4.59,
    "JUL": 4.68,
    "AUG": 5.22,
    "SEP": 5.18,
    "OCT": 4.95,
    "NOV": 4.58,
    "DEC": 5.14
  },
  {
    "LONG": -42.13,
    "LAT": -19.79,
    "CIDADE": "Caratinga",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.13,
    "FEB": 5.62,
    "MAR": 5.01,
    "APR": 4.93,
    "MAY": 4.5,
    "JUN": 4.41,
    "JUL": 4.53,
    "AUG": 5.1,
    "SEP": 5.09,
    "OCT": 4.84,
    "NOV": 4.44,
    "DEC": 4.98
  },
  {
    "LONG": -42.08,
    "LAT": -19.76,
    "CIDADE": "Piedade de Caratinga",
    "UF": "MINAS GERAIS",
    "anual": 4.79,
    "JAN": 4.98,
    "FEB": 5.51,
    "MAR": 4.96,
    "APR": 4.8,
    "MAY": 4.4,
    "JUN": 4.34,
    "JUL": 4.47,
    "AUG": 5.02,
    "SEP": 5.02,
    "OCT": 4.77,
    "NOV": 4.33,
    "DEC": 4.89
  },
  {
    "LONG": -41.72,
    "LAT": -19.8,
    "CIDADE": "Ipanema",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.25,
    "FEB": 5.76,
    "MAR": 5.16,
    "APR": 5.17,
    "MAY": 4.56,
    "JUN": 4.41,
    "JUL": 4.53,
    "AUG": 5.04,
    "SEP": 4.94,
    "OCT": 4.73,
    "NOV": 4.41,
    "DEC": 4.95
  },
  {
    "LONG": -41.61,
    "LAT": -19.76,
    "CIDADE": "Taparuba",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.36,
    "FEB": 5.91,
    "MAR": 5.3,
    "APR": 5.25,
    "MAY": 4.61,
    "JUN": 4.48,
    "JUL": 4.54,
    "AUG": 5.1,
    "SEP": 5.05,
    "OCT": 4.85,
    "NOV": 4.55,
    "DEC": 5.1
  },
  {
    "LONG": -4.14,
    "LAT": -19.81,
    "CIDADE": "Mutum",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.43,
    "FEB": 5.94,
    "MAR": 5.33,
    "APR": 5.27,
    "MAY": 4.67,
    "JUN": 4.53,
    "JUL": 4.55,
    "AUG": 5.05,
    "SEP": 5.02,
    "OCT": 4.83,
    "NOV": 4.59,
    "DEC": 5.08
  },
  {
    "LONG": -50.69,
    "LAT": -19.7,
    "CIDADE": "Carneirinho",
    "UF": "MINAS GERAIS",
    "anual": 5.44,
    "JAN": 5.23,
    "FEB": 5.67,
    "MAR": 5.52,
    "APR": 5.59,
    "MAY": 5.18,
    "JUN": 5.08,
    "JUL": 5.24,
    "AUG": 5.96,
    "SEP": 5.44,
    "OCT": 5.52,
    "NOV": 5.35,
    "DEC": 5.47
  },
  {
    "LONG": -5.02,
    "LAT": -1.97,
    "CIDADE": "Iturama",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.2,
    "FEB": 5.6,
    "MAR": 5.45,
    "APR": 5.6,
    "MAY": 5.19,
    "JUN": 5.02,
    "JUL": 5.31,
    "AUG": 5.94,
    "SEP": 5.48,
    "OCT": 5.5,
    "NOV": 5.34,
    "DEC": 5.41
  },
  {
    "LONG": -49.08,
    "LAT": -1.97,
    "CIDADE": "Comendador Gomes",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.16,
    "FEB": 5.59,
    "MAR": 5.34,
    "APR": 5.65,
    "MAY": 5.24,
    "JUN": 5.11,
    "JUL": 5.37,
    "AUG": 6.08,
    "SEP": 5.47,
    "OCT": 5.42,
    "NOV": 5.28,
    "DEC": 5.35
  },
  {
    "LONG": -4.83,
    "LAT": -1.97,
    "CIDADE": "Veríssimo",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.09,
    "FEB": 5.6,
    "MAR": 5.31,
    "APR": 5.61,
    "MAY": 5.3,
    "JUN": 5.19,
    "JUL": 5.42,
    "AUG": 6.17,
    "SEP": 5.49,
    "OCT": 5.4,
    "NOV": 5.18,
    "DEC": 5.24
  },
  {
    "LONG": -47.94,
    "LAT": -19.75,
    "CIDADE": "Uberaba",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.05,
    "FEB": 5.55,
    "MAR": 5.16,
    "APR": 5.54,
    "MAY": 5.29,
    "JUN": 5.17,
    "JUL": 5.46,
    "AUG": 6.2,
    "SEP": 5.51,
    "OCT": 5.4,
    "NOV": 5.06,
    "DEC": 5.17
  },
  {
    "LONG": -4.62,
    "LAT": -19.69,
    "CIDADE": "Campos Altos",
    "UF": "MINAS GERAIS",
    "anual": 5.27,
    "JAN": 5.01,
    "FEB": 5.49,
    "MAR": 4.87,
    "APR": 5.29,
    "MAY": 5.23,
    "JUN": 5.2,
    "JUL": 5.44,
    "AUG": 6.33,
    "SEP": 5.78,
    "OCT": 5.21,
    "NOV": 4.55,
    "DEC": 4.81
  },
  {
    "LONG": -45.25,
    "LAT": -19.73,
    "CIDADE": "Bom Despacho",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.32,
    "FEB": 5.85,
    "MAR": 5.34,
    "APR": 5.55,
    "MAY": 5.32,
    "JUN": 5.27,
    "JUL": 5.53,
    "AUG": 6.22,
    "SEP": 5.87,
    "OCT": 5.48,
    "NOV": 4.96,
    "DEC": 5.11
  },
  {
    "LONG": -45.02,
    "LAT": -19.72,
    "CIDADE": "Leandro Ferreira",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.31,
    "FEB": 5.81,
    "MAR": 5.35,
    "APR": 5.55,
    "MAY": 5.27,
    "JUN": 5.24,
    "JUL": 5.51,
    "AUG": 6.17,
    "SEP": 5.89,
    "OCT": 5.47,
    "NOV": 4.91,
    "DEC": 5.1
  },
  {
    "LONG": -44.81,
    "LAT": -1.97,
    "CIDADE": "Onça de Pitangui",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.29,
    "APR": 5.47,
    "MAY": 5.21,
    "JUN": 5.2,
    "JUL": 5.47,
    "AUG": 6.14,
    "SEP": 5.88,
    "OCT": 5.45,
    "NOV": 4.9,
    "DEC": 5.07
  },
  {
    "LONG": "#VALOR!",
    "LAT": -1.97,
    "CIDADE": "Pitangui",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.29,
    "APR": 5.47,
    "MAY": 5.21,
    "JUN": 5.2,
    "JUL": 5.47,
    "AUG": 6.14,
    "SEP": 5.88,
    "OCT": 5.45,
    "NOV": 4.9,
    "DEC": 5.07
  },
  {
    "LONG": -44.9,
    "LAT": -19.75,
    "CIDADE": "Conceição do Pará",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.29,
    "APR": 5.47,
    "MAY": 5.21,
    "JUN": 5.2,
    "JUL": 5.47,
    "AUG": 6.14,
    "SEP": 5.88,
    "OCT": 5.45,
    "NOV": 4.9,
    "DEC": 5.07
  },
  {
    "LONG": -44.56,
    "LAT": -1.97,
    "CIDADE": "São José da Varginha",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.41,
    "FEB": 5.78,
    "MAR": 5.32,
    "APR": 5.5,
    "MAY": 5.18,
    "JUN": 5.16,
    "JUL": 5.44,
    "AUG": 6.1,
    "SEP": 5.9,
    "OCT": 5.49,
    "NOV": 4.99,
    "DEC": 5.1
  },
  {
    "LONG": -43.92,
    "LAT": -19.69,
    "CIDADE": "Vespasiano",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.79,
    "MAR": 5.44,
    "APR": 5.46,
    "MAY": 5.14,
    "JUN": 5.07,
    "JUL": 5.35,
    "AUG": 6,
    "SEP": 5.89,
    "OCT": 5.48,
    "NOV": 5.02,
    "DEC": 5.1
  },
  {
    "LONG": -4.4,
    "LAT": -19.7,
    "CIDADE": "São José da Lapa",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.79,
    "MAR": 5.44,
    "APR": 5.46,
    "MAY": 5.14,
    "JUN": 5.07,
    "JUL": 5.35,
    "AUG": 6,
    "SEP": 5.89,
    "OCT": 5.48,
    "NOV": 5.02,
    "DEC": 5.1
  },
  {
    "LONG": -43.69,
    "LAT": -19.67,
    "CIDADE": "Taquaraçu de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.4,
    "JAN": 5.29,
    "FEB": 5.85,
    "MAR": 5.41,
    "APR": 5.38,
    "MAY": 5.1,
    "JUN": 5.1,
    "JUL": 5.32,
    "AUG": 5.95,
    "SEP": 5.85,
    "OCT": 5.54,
    "NOV": 4.96,
    "DEC": 5.1
  },
  {
    "LONG": -43.58,
    "LAT": -19.69,
    "CIDADE": "Nova União",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.18,
    "FEB": 5.72,
    "MAR": 5.23,
    "APR": 5.17,
    "MAY": 4.91,
    "JUN": 4.92,
    "JUL": 5.15,
    "AUG": 5.84,
    "SEP": 5.64,
    "OCT": 5.35,
    "NOV": 4.75,
    "DEC": 4.96
  },
  {
    "LONG": -43.47,
    "LAT": -19.71,
    "CIDADE": "Bom Jesus do Amparo",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.08,
    "FEB": 5.65,
    "MAR": 5.09,
    "APR": 5.06,
    "MAY": 4.64,
    "JUN": 4.66,
    "JUL": 4.95,
    "AUG": 5.59,
    "SEP": 5.42,
    "OCT": 5.09,
    "NOV": 4.55,
    "DEC": 4.89
  },
  {
    "LONG": -42.88,
    "LAT": -19.65,
    "CIDADE": "Antônio Dias",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.15,
    "FEB": 5.71,
    "MAR": 5.04,
    "APR": 5.02,
    "MAY": 4.56,
    "JUN": 4.5,
    "JUL": 4.67,
    "AUG": 5.26,
    "SEP": 5.21,
    "OCT": 4.96,
    "NOV": 4.35,
    "DEC": 4.95
  },
  {
    "LONG": -42.73,
    "LAT": -19.71,
    "CIDADE": "Marliéria",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.15,
    "FEB": 5.7,
    "MAR": 5.07,
    "APR": 5.03,
    "MAY": 4.51,
    "JUN": 4.46,
    "JUL": 4.66,
    "AUG": 5.2,
    "SEP": 5.13,
    "OCT": 4.93,
    "NOV": 4.38,
    "DEC": 4.96
  },
  {
    "LONG": -42.41,
    "LAT": -19.73,
    "CIDADE": "Pingo-d'Água",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.39,
    "FEB": 5.94,
    "MAR": 5.38,
    "APR": 5.26,
    "MAY": 4.76,
    "JUN": 4.68,
    "JUL": 4.83,
    "AUG": 5.33,
    "SEP": 5.25,
    "OCT": 5.04,
    "NOV": 4.63,
    "DEC": 5.19
  },
  {
    "LONG": -50.58,
    "LAT": -19.55,
    "CIDADE": "Limeira do Oeste",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.26,
    "FEB": 5.66,
    "MAR": 5.48,
    "APR": 5.54,
    "MAY": 5.22,
    "JUN": 5.1,
    "JUL": 5.3,
    "AUG": 5.96,
    "SEP": 5.47,
    "OCT": 5.52,
    "NOV": 5.38,
    "DEC": 5.48
  },
  {
    "LONG": -46.94,
    "LAT": -1.96,
    "CIDADE": "Araxá",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5,
    "FEB": 5.64,
    "MAR": 5.05,
    "APR": 5.46,
    "MAY": 5.36,
    "JUN": 5.28,
    "JUL": 5.48,
    "AUG": 6.36,
    "SEP": 5.78,
    "OCT": 5.5,
    "NOV": 4.93,
    "DEC": 5.09
  },
  {
    "LONG": -44.66,
    "LAT": -19.63,
    "CIDADE": "Pequi",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.39,
    "FEB": 5.86,
    "MAR": 5.34,
    "APR": 5.51,
    "MAY": 5.2,
    "JUN": 5.12,
    "JUL": 5.45,
    "AUG": 6.13,
    "SEP": 5.93,
    "OCT": 5.48,
    "NOV": 4.96,
    "DEC": 5.1
  },
  {
    "LONG": -4.44,
    "LAT": -1.96,
    "CIDADE": "Fortuna de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.36,
    "FEB": 5.75,
    "MAR": 5.35,
    "APR": 5.49,
    "MAY": 5.2,
    "JUN": 5.13,
    "JUL": 5.43,
    "AUG": 6.13,
    "SEP": 5.93,
    "OCT": 5.5,
    "NOV": 5,
    "DEC": 5.11
  },
  {
    "LONG": -4.4,
    "LAT": -1.96,
    "CIDADE": "Pedro Leopoldo",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.34,
    "FEB": 5.75,
    "MAR": 5.37,
    "APR": 5.46,
    "MAY": 5.18,
    "JUN": 5.07,
    "JUL": 5.41,
    "AUG": 6.01,
    "SEP": 5.94,
    "OCT": 5.5,
    "NOV": 5,
    "DEC": 5.07
  },
  {
    "LONG": -44.09,
    "LAT": -19.55,
    "CIDADE": "Matozinhos",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.34,
    "FEB": 5.75,
    "MAR": 5.37,
    "APR": 5.46,
    "MAY": 5.18,
    "JUN": 5.07,
    "JUL": 5.41,
    "AUG": 6.01,
    "SEP": 5.94,
    "OCT": 5.5,
    "NOV": 5,
    "DEC": 5.07
  },
  {
    "LONG": -43.99,
    "LAT": -19.63,
    "CIDADE": "Confins",
    "UF": "MINAS GERAIS",
    "anual": 5.44,
    "JAN": 5.34,
    "FEB": 5.81,
    "MAR": 5.44,
    "APR": 5.48,
    "MAY": 5.2,
    "JUN": 5.1,
    "JUL": 5.39,
    "AUG": 5.99,
    "SEP": 5.92,
    "OCT": 5.49,
    "NOV": 5.03,
    "DEC": 5.12
  },
  {
    "LONG": "#VALOR!",
    "LAT": -1.96,
    "CIDADE": "Lagoa Santa",
    "UF": "MINAS GERAIS",
    "anual": 5.47,
    "JAN": 5.35,
    "FEB": 5.85,
    "MAR": 5.48,
    "APR": 5.53,
    "MAY": 5.22,
    "JUN": 5.14,
    "JUL": 5.42,
    "AUG": 6.01,
    "SEP": 5.92,
    "OCT": 5.53,
    "NOV": 5.07,
    "DEC": 5.18
  },
  {
    "LONG": -43.23,
    "LAT": -19.62,
    "CIDADE": "Itabira",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.01,
    "FEB": 5.56,
    "MAR": 4.94,
    "APR": 4.9,
    "MAY": 4.63,
    "JUN": 4.61,
    "JUL": 4.77,
    "AUG": 5.41,
    "SEP": 5.28,
    "OCT": 4.95,
    "NOV": 4.42,
    "DEC": 4.9
  },
  {
    "LONG": -42.75,
    "LAT": -19.65,
    "CIDADE": "Jaguaraçu",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.27,
    "FEB": 5.81,
    "MAR": 5.2,
    "APR": 5.14,
    "MAY": 4.6,
    "JUN": 4.55,
    "JUL": 4.74,
    "AUG": 5.32,
    "SEP": 5.21,
    "OCT": 5,
    "NOV": 4.44,
    "DEC": 4.99
  },
  {
    "LONG": -4.26,
    "LAT": -19.58,
    "CIDADE": "Timóteo",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.24,
    "FEB": 5.77,
    "MAR": 5.15,
    "APR": 5.08,
    "MAY": 4.5,
    "JUN": 4.51,
    "JUL": 4.68,
    "AUG": 5.22,
    "SEP": 5.17,
    "OCT": 4.95,
    "NOV": 4.48,
    "DEC": 4.98
  },
  {
    "LONG": -42.3,
    "LAT": -19.6,
    "CIDADE": "Vargem Alegre",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.25,
    "FEB": 5.65,
    "MAR": 5.14,
    "APR": 4.97,
    "MAY": 4.5,
    "JUN": 4.4,
    "JUL": 4.53,
    "AUG": 5.07,
    "SEP": 5.08,
    "OCT": 4.84,
    "NOV": 4.44,
    "DEC": 5.02
  },
  {
    "LONG": -42.23,
    "LAT": -19.62,
    "CIDADE": "Entre Folhas",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.25,
    "FEB": 5.65,
    "MAR": 5.14,
    "APR": 4.97,
    "MAY": 4.5,
    "JUN": 4.4,
    "JUL": 4.53,
    "AUG": 5.07,
    "SEP": 5.08,
    "OCT": 4.84,
    "NOV": 4.44,
    "DEC": 5.02
  },
  {
    "LONG": -42.11,
    "LAT": -19.64,
    "CIDADE": "Ubaporanga",
    "UF": "MINAS GERAIS",
    "anual": 4.9,
    "JAN": 5.26,
    "FEB": 5.66,
    "MAR": 5.15,
    "APR": 4.97,
    "MAY": 4.44,
    "JUN": 4.38,
    "JUL": 4.52,
    "AUG": 5.06,
    "SEP": 5.07,
    "OCT": 4.83,
    "NOV": 4.44,
    "DEC": 4.99
  },
  {
    "LONG": -41.97,
    "LAT": -19.6,
    "CIDADE": "Imbé de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.83,
    "JAN": 5.18,
    "FEB": 5.61,
    "MAR": 5.03,
    "APR": 4.98,
    "MAY": 4.39,
    "JUN": 4.31,
    "JUL": 4.47,
    "AUG": 4.98,
    "SEP": 4.97,
    "OCT": 4.75,
    "NOV": 4.39,
    "DEC": 4.93
  },
  {
    "LONG": -41.63,
    "LAT": -19.62,
    "CIDADE": "Pocrane",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.34,
    "FEB": 5.88,
    "MAR": 5.33,
    "APR": 5.16,
    "MAY": 4.59,
    "JUN": 4.44,
    "JUL": 4.56,
    "AUG": 5.07,
    "SEP": 5.09,
    "OCT": 4.88,
    "NOV": 4.58,
    "DEC": 5.07
  },
  {
    "LONG": -50.34,
    "LAT": "#VALOR!",
    "CIDADE": "União de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.26,
    "FEB": 5.63,
    "MAR": 5.46,
    "APR": 5.58,
    "MAY": 5.25,
    "JUN": 5.1,
    "JUL": 5.32,
    "AUG": 5.99,
    "SEP": 5.48,
    "OCT": 5.5,
    "NOV": 5.38,
    "DEC": 5.44
  },
  {
    "LONG": -49.49,
    "LAT": -19.53,
    "CIDADE": "Campina Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.2,
    "FEB": 5.52,
    "MAR": 5.33,
    "APR": 5.59,
    "MAY": 5.26,
    "JUN": 5.09,
    "JUL": 5.37,
    "AUG": 6.09,
    "SEP": 5.54,
    "OCT": 5.42,
    "NOV": 5.32,
    "DEC": 5.35
  },
  {
    "LONG": -46.55,
    "LAT": -1.95,
    "CIDADE": "Ibiá",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.03,
    "FEB": 5.6,
    "MAR": 5.04,
    "APR": 5.41,
    "MAY": 5.3,
    "JUN": 5.24,
    "JUL": 5.44,
    "AUG": 6.37,
    "SEP": 5.76,
    "OCT": 5.39,
    "NOV": 4.83,
    "DEC": 5.05
  },
  {
    "LONG": -45.97,
    "LAT": -19.53,
    "CIDADE": "Santa Rosa da Serra",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.13,
    "FEB": 5.71,
    "MAR": 5.03,
    "APR": 5.43,
    "MAY": 5.32,
    "JUN": 5.29,
    "JUL": 5.55,
    "AUG": 6.4,
    "SEP": 5.85,
    "OCT": 5.32,
    "NOV": 4.65,
    "DEC": 4.86
  },
  {
    "LONG": "#VALOR!",
    "LAT": -19.52,
    "CIDADE": "Estrela do Indaiá",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.28,
    "FEB": 5.83,
    "MAR": 5.24,
    "APR": 5.53,
    "MAY": 5.45,
    "JUN": 5.35,
    "JUL": 5.56,
    "AUG": 6.34,
    "SEP": 5.9,
    "OCT": 5.48,
    "NOV": 4.83,
    "DEC": 5.06
  },
  {
    "LONG": -45.6,
    "LAT": -19.46,
    "CIDADE": "Dores do Indaiá",
    "UF": "MINAS GERAIS",
    "anual": 5.52,
    "JAN": 5.31,
    "FEB": 5.87,
    "MAR": 5.27,
    "APR": 5.55,
    "MAY": 5.46,
    "JUN": 5.37,
    "JUL": 5.57,
    "AUG": 6.38,
    "SEP": 5.94,
    "OCT": 5.51,
    "NOV": 4.88,
    "DEC": 5.09
  },
  {
    "LONG": -4.47,
    "LAT": -1.95,
    "CIDADE": "Maravilhas",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.43,
    "FEB": 5.79,
    "MAR": 5.38,
    "APR": 5.55,
    "MAY": 5.29,
    "JUN": 5.2,
    "JUL": 5.49,
    "AUG": 6.17,
    "SEP": 5.94,
    "OCT": 5.5,
    "NOV": 5,
    "DEC": 5.14
  },
  {
    "LONG": -4.45,
    "LAT": -1.95,
    "CIDADE": "Cachoeira da Prata",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.44,
    "FEB": 5.84,
    "MAR": 5.38,
    "APR": 5.5,
    "MAY": 5.25,
    "JUN": 5.15,
    "JUL": 5.48,
    "AUG": 6.14,
    "SEP": 5.95,
    "OCT": 5.49,
    "NOV": 4.98,
    "DEC": 5.13
  },
  {
    "LONG": -44.39,
    "LAT": -19.49,
    "CIDADE": "Inhaúma",
    "UF": "MINAS GERAIS",
    "anual": 5.47,
    "JAN": 5.4,
    "FEB": 5.85,
    "MAR": 5.34,
    "APR": 5.51,
    "MAY": 5.23,
    "JUN": 5.16,
    "JUL": 5.49,
    "AUG": 6.13,
    "SEP": 5.98,
    "OCT": 5.51,
    "NOV": 4.99,
    "DEC": 5.11
  },
  {
    "LONG": -44.24,
    "LAT": -19.46,
    "CIDADE": "Sete Lagoas",
    "UF": "MINAS GERAIS",
    "anual": 5.44,
    "JAN": 5.37,
    "FEB": 5.82,
    "MAR": 5.33,
    "APR": 5.47,
    "MAY": 5.2,
    "JUN": 5.1,
    "JUL": 5.45,
    "AUG": 6.07,
    "SEP": 5.96,
    "OCT": 5.51,
    "NOV": 4.99,
    "DEC": 5.07
  },
  {
    "LONG": -44.16,
    "LAT": -19.47,
    "CIDADE": "Prudente de Morais",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.39,
    "FEB": 5.86,
    "MAR": 5.39,
    "APR": 5.49,
    "MAY": 5.2,
    "JUN": 5.11,
    "JUL": 5.42,
    "AUG": 6.08,
    "SEP": 5.97,
    "OCT": 5.53,
    "NOV": 5.02,
    "DEC": 5.08
  },
  {
    "LONG": -44.13,
    "LAT": -19.55,
    "CIDADE": "Capim Branco",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.39,
    "FEB": 5.86,
    "MAR": 5.39,
    "APR": 5.49,
    "MAY": 5.2,
    "JUN": 5.11,
    "JUL": 5.42,
    "AUG": 6.08,
    "SEP": 5.97,
    "OCT": 5.53,
    "NOV": 5.02,
    "DEC": 5.08
  },
  {
    "LONG": -4.37,
    "LAT": -1.95,
    "CIDADE": "Jaboticatubas",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.37,
    "FEB": 5.81,
    "MAR": 5.45,
    "APR": 5.45,
    "MAY": 5.25,
    "JUN": 5.12,
    "JUL": 5.43,
    "AUG": 6.08,
    "SEP": 6.01,
    "OCT": 5.6,
    "NOV": 5.06,
    "DEC": 5.16
  },
  {
    "LONG": -42.63,
    "LAT": -19.52,
    "CIDADE": "Coronel Fabriciano",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.01,
    "FEB": 5.62,
    "MAR": 4.98,
    "APR": 4.92,
    "MAY": 4.43,
    "JUN": 4.44,
    "JUL": 4.56,
    "AUG": 5.09,
    "SEP": 5.05,
    "OCT": 4.83,
    "NOV": 4.27,
    "DEC": 4.85
  },
  {
    "LONG": -4.25,
    "LAT": -19.47,
    "CIDADE": "Ipatinga",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.25,
    "FEB": 5.87,
    "MAR": 5.22,
    "APR": 5.12,
    "MAY": 4.57,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.2,
    "SEP": 5.21,
    "OCT": 4.99,
    "NOV": 4.47,
    "DEC": 5.03
  },
  {
    "LONG": -42.12,
    "LAT": -19.55,
    "CIDADE": "Inhapim",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.22,
    "FEB": 5.73,
    "MAR": 5.17,
    "APR": 4.94,
    "MAY": 4.49,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 4.99,
    "SEP": 5.02,
    "OCT": 4.83,
    "NOV": 4.43,
    "DEC": 5.09
  },
  {
    "LONG": -42.01,
    "LAT": -19.53,
    "CIDADE": "São Domingos das Dores",
    "UF": "MINAS GERAIS",
    "anual": 4.86,
    "JAN": 5.22,
    "FEB": 5.72,
    "MAR": 5.1,
    "APR": 4.84,
    "MAY": 4.4,
    "JUN": 4.31,
    "JUL": 4.43,
    "AUG": 4.96,
    "SEP": 5.03,
    "OCT": 4.82,
    "NOV": 4.44,
    "DEC": 5.06
  },
  {
    "LONG": -41.98,
    "LAT": -19.5,
    "CIDADE": "São Sebastião do Anta",
    "UF": "MINAS GERAIS",
    "anual": 4.83,
    "JAN": 5.17,
    "FEB": 5.68,
    "MAR": 5.09,
    "APR": 4.89,
    "MAY": 4.35,
    "JUN": 4.27,
    "JUL": 4.4,
    "AUG": 4.91,
    "SEP": 4.99,
    "OCT": 4.77,
    "NOV": 4.41,
    "DEC": 5.02
  },
  {
    "LONG": -4.11,
    "LAT": -19.5,
    "CIDADE": "Aimorés",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.62,
    "FEB": 6.04,
    "MAR": 5.49,
    "APR": 5.25,
    "MAY": 4.75,
    "JUN": 4.5,
    "JUL": 4.59,
    "AUG": 5.09,
    "SEP": 5.15,
    "OCT": 4.96,
    "NOV": 4.71,
    "DEC": 5.28
  },
  {
    "LONG": "#VALOR!",
    "LAT": -19.44,
    "CIDADE": "Serra da Saudade",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.15,
    "FEB": 5.78,
    "MAR": 5.08,
    "APR": 5.49,
    "MAY": 5.4,
    "JUN": 5.26,
    "JUL": 5.56,
    "AUG": 6.41,
    "SEP": 5.89,
    "OCT": 5.39,
    "NOV": 4.74,
    "DEC": 4.93
  },
  {
    "LONG": -44.75,
    "LAT": -19.44,
    "CIDADE": "Papagaios",
    "UF": "MINAS GERAIS",
    "anual": 5.52,
    "JAN": 5.4,
    "FEB": 5.88,
    "MAR": 5.38,
    "APR": 5.61,
    "MAY": 5.35,
    "JUN": 5.2,
    "JUL": 5.55,
    "AUG": 6.18,
    "SEP": 5.98,
    "OCT": 5.51,
    "NOV": 4.98,
    "DEC": 5.2
  },
  {
    "LONG": -44.06,
    "LAT": -19.37,
    "CIDADE": "Funilândia",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.42,
    "FEB": 5.85,
    "MAR": 5.5,
    "APR": 5.52,
    "MAY": 5.25,
    "JUN": 5.12,
    "JUL": 5.47,
    "AUG": 6.08,
    "SEP": 6.02,
    "OCT": 5.57,
    "NOV": 5.06,
    "DEC": 5.23
  },
  {
    "LONG": -43.32,
    "LAT": -19.42,
    "CIDADE": "Itambé do Mato Dentro",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 4.98,
    "FEB": 5.5,
    "MAR": 4.95,
    "APR": 4.83,
    "MAY": 4.5,
    "JUN": 4.51,
    "JUL": 4.68,
    "AUG": 5.36,
    "SEP": 5.24,
    "OCT": 4.88,
    "NOV": 4.33,
    "DEC": 4.81
  },
  {
    "LONG": -43.14,
    "LAT": -19.35,
    "CIDADE": "Passabém",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.21,
    "FEB": 5.72,
    "MAR": 5.16,
    "APR": 4.94,
    "MAY": 4.57,
    "JUN": 4.49,
    "JUL": 4.66,
    "AUG": 5.36,
    "SEP": 5.28,
    "OCT": 5.03,
    "NOV": 4.5,
    "DEC": 5.05
  },
  {
    "LONG": -4.31,
    "LAT": -1.94,
    "CIDADE": "Santa Maria de Itabira",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.21,
    "FEB": 5.72,
    "MAR": 5.16,
    "APR": 4.94,
    "MAY": 4.57,
    "JUN": 4.49,
    "JUL": 4.66,
    "AUG": 5.36,
    "SEP": 5.28,
    "OCT": 5.03,
    "NOV": 4.5,
    "DEC": 5.05
  },
  {
    "LONG": -42.54,
    "LAT": -19.37,
    "CIDADE": "Santana do Paraíso",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.21,
    "FEB": 5.75,
    "MAR": 5.12,
    "APR": 5,
    "MAY": 4.48,
    "JUN": 4.42,
    "JUL": 4.6,
    "AUG": 5.12,
    "SEP": 5.1,
    "OCT": 4.88,
    "NOV": 4.4,
    "DEC": 4.96
  },
  {
    "LONG": -4.24,
    "LAT": -1.94,
    "CIDADE": "Ipaba",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.41,
    "FEB": 5.94,
    "MAR": 5.31,
    "APR": 5.23,
    "MAY": 4.66,
    "JUN": 4.56,
    "JUL": 4.73,
    "AUG": 5.27,
    "SEP": 5.23,
    "OCT": 5.01,
    "NOV": 4.56,
    "DEC": 5.1
  },
  {
    "LONG": -42.22,
    "LAT": -19.44,
    "CIDADE": "Iapu",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.33,
    "FEB": 5.78,
    "MAR": 5.26,
    "APR": 5.09,
    "MAY": 4.59,
    "JUN": 4.44,
    "JUL": 4.55,
    "AUG": 5.07,
    "SEP": 5.1,
    "OCT": 4.93,
    "NOV": 4.51,
    "DEC": 5.17
  },
  {
    "LONG": -42.26,
    "LAT": -19.42,
    "CIDADE": "Bugre",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.33,
    "FEB": 5.78,
    "MAR": 5.26,
    "APR": 5.09,
    "MAY": 4.59,
    "JUN": 4.44,
    "JUL": 4.55,
    "AUG": 5.07,
    "SEP": 5.1,
    "OCT": 4.93,
    "NOV": 4.51,
    "DEC": 5.17
  },
  {
    "LONG": -42.11,
    "LAT": -19.37,
    "CIDADE": "Dom Cavati",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.28,
    "FEB": 5.74,
    "MAR": 5.25,
    "APR": 5.05,
    "MAY": 4.57,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.05,
    "SEP": 5.08,
    "OCT": 4.9,
    "NOV": 4.48,
    "DEC": 5.14
  },
  {
    "LONG": -41.73,
    "LAT": -19.42,
    "CIDADE": "Alvarenga",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5.19,
    "FEB": 5.61,
    "MAR": 5.03,
    "APR": 4.91,
    "MAY": 4.39,
    "JUN": 4.33,
    "JUL": 4.36,
    "AUG": 4.9,
    "SEP": 5.01,
    "OCT": 4.75,
    "NOV": 4.43,
    "DEC": 4.96
  },
  {
    "LONG": -41.38,
    "LAT": -19.36,
    "CIDADE": "Santa Rita do Itueto",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.29,
    "FEB": 5.71,
    "MAR": 5.18,
    "APR": 4.95,
    "MAY": 4.46,
    "JUN": 4.37,
    "JUL": 4.4,
    "AUG": 4.92,
    "SEP": 5.01,
    "OCT": 4.79,
    "NOV": 4.44,
    "DEC": 5.01
  },
  {
    "LONG": -4.12,
    "LAT": -19.4,
    "CIDADE": "Itueta",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.56,
    "FEB": 6.02,
    "MAR": 5.48,
    "APR": 5.19,
    "MAY": 4.71,
    "JUN": 4.48,
    "JUL": 4.53,
    "AUG": 4.95,
    "SEP": 5.11,
    "OCT": 4.89,
    "NOV": 4.65,
    "DEC": 5.21
  },
  {
    "LONG": -4.89,
    "LAT": -19.31,
    "CIDADE": "Prata",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.11,
    "FEB": 5.56,
    "MAR": 5.26,
    "APR": 5.63,
    "MAY": 5.31,
    "JUN": 5.18,
    "JUL": 5.42,
    "AUG": 6.18,
    "SEP": 5.5,
    "OCT": 5.44,
    "NOV": 5.23,
    "DEC": 5.28
  },
  {
    "LONG": -47.53,
    "LAT": -1.93,
    "CIDADE": "Santa Juliana",
    "UF": "MINAS GERAIS",
    "anual": 5.44,
    "JAN": 5.06,
    "FEB": 5.68,
    "MAR": 5.12,
    "APR": 5.51,
    "MAY": 5.35,
    "JUN": 5.32,
    "JUL": 5.52,
    "AUG": 6.33,
    "SEP": 5.75,
    "OCT": 5.48,
    "NOV": 5.03,
    "DEC": 5.16
  },
  {
    "LONG": -47.29,
    "LAT": -19.35,
    "CIDADE": "Perdizes",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.07,
    "FEB": 5.7,
    "MAR": 5.15,
    "APR": 5.48,
    "MAY": 5.41,
    "JUN": 5.35,
    "JUL": 5.55,
    "AUG": 6.36,
    "SEP": 5.78,
    "OCT": 5.46,
    "NOV": 5.02,
    "DEC": 5.13
  },
  {
    "LONG": -46.05,
    "LAT": -19.31,
    "CIDADE": "São Gotardo",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.12,
    "FEB": 5.71,
    "MAR": 4.99,
    "APR": 5.35,
    "MAY": 5.3,
    "JUN": 5.24,
    "JUL": 5.51,
    "AUG": 6.34,
    "SEP": 5.83,
    "OCT": 5.34,
    "NOV": 4.66,
    "DEC": 4.88
  },
  {
    "LONG": -45.56,
    "LAT": -19.27,
    "CIDADE": "Quartel Geral",
    "UF": "MINAS GERAIS",
    "anual": 5.54,
    "JAN": 5.35,
    "FEB": 5.9,
    "MAR": 5.34,
    "APR": 5.6,
    "MAY": 5.46,
    "JUN": 5.34,
    "JUL": 5.59,
    "AUG": 6.4,
    "SEP": 5.98,
    "OCT": 5.56,
    "NOV": 4.92,
    "DEC": 5.1
  },
  {
    "LONG": -45.24,
    "LAT": -19.32,
    "CIDADE": "Martinho Campos",
    "UF": "MINAS GERAIS",
    "anual": 5.54,
    "JAN": 5.34,
    "FEB": 5.85,
    "MAR": 5.36,
    "APR": 5.62,
    "MAY": 5.4,
    "JUN": 5.33,
    "JUL": 5.57,
    "AUG": 6.27,
    "SEP": 5.99,
    "OCT": 5.54,
    "NOV": 5.03,
    "DEC": 5.16
  },
  {
    "LONG": -44.4,
    "LAT": -19.27,
    "CIDADE": "Paraopeba",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.44,
    "FEB": 5.89,
    "MAR": 5.41,
    "APR": 5.6,
    "MAY": 5.28,
    "JUN": 5.15,
    "JUL": 5.52,
    "AUG": 6.13,
    "SEP": 6.01,
    "OCT": 5.54,
    "NOV": 5.03,
    "DEC": 5.17
  },
  {
    "LONG": -44.42,
    "LAT": -19.3,
    "CIDADE": "Caetanópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.44,
    "FEB": 5.89,
    "MAR": 5.41,
    "APR": 5.6,
    "MAY": 5.28,
    "JUN": 5.15,
    "JUL": 5.52,
    "AUG": 6.13,
    "SEP": 6.01,
    "OCT": 5.54,
    "NOV": 5.03,
    "DEC": 5.17
  },
  {
    "LONG": -4.4,
    "LAT": -1.93,
    "CIDADE": "Baldim",
    "UF": "MINAS GERAIS",
    "anual": 5.54,
    "JAN": 5.38,
    "FEB": 5.95,
    "MAR": 5.52,
    "APR": 5.57,
    "MAY": 5.31,
    "JUN": 5.15,
    "JUL": 5.51,
    "AUG": 6.12,
    "SEP": 6.05,
    "OCT": 5.62,
    "NOV": 5.08,
    "DEC": 5.23
  },
  {
    "LONG": -43.17,
    "LAT": -1.93,
    "CIDADE": "São Sebastião do Rio Preto",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.3,
    "FEB": 5.8,
    "MAR": 5.27,
    "APR": 5.09,
    "MAY": 4.58,
    "JUN": 4.49,
    "JUL": 4.71,
    "AUG": 5.4,
    "SEP": 5.32,
    "OCT": 5.08,
    "NOV": 4.56,
    "DEC": 5.09
  },
  {
    "LONG": -4.22,
    "LAT": -1.93,
    "CIDADE": "São João do Oriente",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.33,
    "FEB": 5.78,
    "MAR": 5.28,
    "APR": 5.1,
    "MAY": 4.6,
    "JUN": 4.45,
    "JUL": 4.5,
    "AUG": 5.04,
    "SEP": 5.1,
    "OCT": 4.93,
    "NOV": 4.54,
    "DEC": 5.2
  },
  {
    "LONG": -42.01,
    "LAT": -19.28,
    "CIDADE": "Tarumirim",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.27,
    "FEB": 5.77,
    "MAR": 5.19,
    "APR": 4.99,
    "MAY": 4.49,
    "JUN": 4.37,
    "JUL": 4.42,
    "AUG": 4.97,
    "SEP": 5.07,
    "OCT": 4.88,
    "NOV": 4.47,
    "DEC": 5.14
  },
  {
    "LONG": -41.25,
    "LAT": -19.32,
    "CIDADE": "Resplendor",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.55,
    "FEB": 6,
    "MAR": 5.51,
    "APR": 5.18,
    "MAY": 4.65,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 4.98,
    "SEP": 5.09,
    "OCT": 4.89,
    "NOV": 4.62,
    "DEC": 5.21
  },
  {
    "LONG": -4.98,
    "LAT": -19.21,
    "CIDADE": "Gurinhatã",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.24,
    "FEB": 5.52,
    "MAR": 5.38,
    "APR": 5.6,
    "MAY": 5.33,
    "JUN": 5.18,
    "JUL": 5.41,
    "AUG": 6.11,
    "SEP": 5.54,
    "OCT": 5.45,
    "NOV": 5.39,
    "DEC": 5.34
  },
  {
    "LONG": -4.77,
    "LAT": -19.17,
    "CIDADE": "Nova Ponte",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.01,
    "FEB": 5.62,
    "MAR": 5.11,
    "APR": 5.57,
    "MAY": 5.36,
    "JUN": 5.28,
    "JUL": 5.43,
    "AUG": 6.22,
    "SEP": 5.65,
    "OCT": 5.47,
    "NOV": 5.03,
    "DEC": 5.15
  },
  {
    "LONG": -47.46,
    "LAT": -19.23,
    "CIDADE": "Pedrinópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.47,
    "JAN": 5.1,
    "FEB": 5.76,
    "MAR": 5.21,
    "APR": 5.63,
    "MAY": 5.43,
    "JUN": 5.33,
    "JUL": 5.49,
    "AUG": 6.25,
    "SEP": 5.68,
    "OCT": 5.51,
    "NOV": 5.1,
    "DEC": 5.17
  },
  {
    "LONG": -46.25,
    "LAT": -19.19,
    "CIDADE": "Rio Paranaíba",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.14,
    "FEB": 5.67,
    "MAR": 5.1,
    "APR": 5.53,
    "MAY": 5.41,
    "JUN": 5.28,
    "JUL": 5.53,
    "AUG": 6.36,
    "SEP": 5.84,
    "OCT": 5.42,
    "NOV": 4.82,
    "DEC": 5
  },
  {
    "LONG": -45.97,
    "LAT": -19.22,
    "CIDADE": "Matutina",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.19,
    "FEB": 5.75,
    "MAR": 5.09,
    "APR": 5.46,
    "MAY": 5.37,
    "JUN": 5.3,
    "JUL": 5.58,
    "AUG": 6.41,
    "SEP": 5.92,
    "OCT": 5.38,
    "NOV": 4.68,
    "DEC": 4.94
  },
  {
    "LONG": -45.46,
    "LAT": -19.15,
    "CIDADE": "Abaeté",
    "UF": "MINAS GERAIS",
    "anual": 5.58,
    "JAN": 5.42,
    "FEB": 5.88,
    "MAR": 5.39,
    "APR": 5.64,
    "MAY": 5.47,
    "JUN": 5.42,
    "JUL": 5.61,
    "AUG": 6.34,
    "SEP": 6.03,
    "OCT": 5.58,
    "NOV": 4.98,
    "DEC": 5.18
  },
  {
    "LONG": -45.01,
    "LAT": -19.23,
    "CIDADE": "Pompéu",
    "UF": "MINAS GERAIS",
    "anual": 5.58,
    "JAN": 5.46,
    "FEB": 5.91,
    "MAR": 5.42,
    "APR": 5.67,
    "MAY": 5.42,
    "JUN": 5.31,
    "JUL": 5.64,
    "AUG": 6.22,
    "SEP": 6.03,
    "OCT": 5.57,
    "NOV": 5.04,
    "DEC": 5.22
  },
  {
    "LONG": -44.25,
    "LAT": -1.92,
    "CIDADE": "Araçaí",
    "UF": "MINAS GERAIS",
    "anual": 5.54,
    "JAN": 5.42,
    "FEB": 5.95,
    "MAR": 5.49,
    "APR": 5.59,
    "MAY": 5.3,
    "JUN": 5.19,
    "JUL": 5.53,
    "AUG": 6.12,
    "SEP": 6.03,
    "OCT": 5.59,
    "NOV": 5.05,
    "DEC": 5.23
  },
  {
    "LONG": -4.4,
    "LAT": -1.92,
    "CIDADE": "Jequitibá",
    "UF": "MINAS GERAIS",
    "anual": 5.59,
    "JAN": 5.48,
    "FEB": 5.99,
    "MAR": 5.59,
    "APR": 5.63,
    "MAY": 5.38,
    "JUN": 5.2,
    "JUL": 5.58,
    "AUG": 6.16,
    "SEP": 6.05,
    "OCT": 5.65,
    "NOV": 5.09,
    "DEC": 5.23
  },
  {
    "LONG": -4.37,
    "LAT": -1.92,
    "CIDADE": "Santana do Riacho",
    "UF": "MINAS GERAIS",
    "anual": 5.55,
    "JAN": 5.45,
    "FEB": 5.95,
    "MAR": 5.49,
    "APR": 5.49,
    "MAY": 5.3,
    "JUN": 5.22,
    "JUL": 5.55,
    "AUG": 6.1,
    "SEP": 6.07,
    "OCT": 5.67,
    "NOV": 5.04,
    "DEC": 5.22
  },
  {
    "LONG": -43.38,
    "LAT": -19.22,
    "CIDADE": "Morro do Pilar",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.11,
    "FEB": 5.64,
    "MAR": 5.06,
    "APR": 4.99,
    "MAY": 4.55,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.36,
    "SEP": 5.26,
    "OCT": 4.97,
    "NOV": 4.42,
    "DEC": 4.91
  },
  {
    "LONG": -4.33,
    "LAT": -1.92,
    "CIDADE": "Santo Antônio do Rio Abaixo",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.1,
    "FEB": 5.6,
    "MAR": 5.04,
    "APR": 4.98,
    "MAY": 4.52,
    "JUN": 4.45,
    "JUL": 4.65,
    "AUG": 5.35,
    "SEP": 5.24,
    "OCT": 4.98,
    "NOV": 4.45,
    "DEC": 4.93
  },
  {
    "LONG": -43.02,
    "LAT": -19.23,
    "CIDADE": "Ferros",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.22,
    "FEB": 5.75,
    "MAR": 5.16,
    "APR": 5.01,
    "MAY": 4.54,
    "JUN": 4.41,
    "JUL": 4.66,
    "AUG": 5.28,
    "SEP": 5.24,
    "OCT": 5.03,
    "NOV": 4.47,
    "DEC": 4.94
  },
  {
    "LONG": -42.61,
    "LAT": -19.22,
    "CIDADE": "Mesquita",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.39,
    "FEB": 5.91,
    "MAR": 5.32,
    "APR": 5.17,
    "MAY": 4.65,
    "JUN": 4.53,
    "JUL": 4.67,
    "AUG": 5.23,
    "SEP": 5.2,
    "OCT": 5.04,
    "NOV": 4.6,
    "DEC": 5.16
  },
  {
    "LONG": -42.68,
    "LAT": -19.17,
    "CIDADE": "Joanésia",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.39,
    "FEB": 5.91,
    "MAR": 5.32,
    "APR": 5.17,
    "MAY": 4.65,
    "JUN": 4.53,
    "JUL": 4.67,
    "AUG": 5.23,
    "SEP": 5.2,
    "OCT": 5.04,
    "NOV": 4.6,
    "DEC": 5.16
  },
  {
    "LONG": -42.48,
    "LAT": -19.22,
    "CIDADE": "Belo Oriente",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.34,
    "FEB": 5.91,
    "MAR": 5.34,
    "APR": 5.19,
    "MAY": 4.61,
    "JUN": 4.55,
    "JUL": 4.66,
    "AUG": 5.18,
    "SEP": 5.21,
    "OCT": 4.99,
    "NOV": 4.52,
    "DEC": 5.12
  },
  {
    "LONG": -42.33,
    "LAT": -19.23,
    "CIDADE": "Naque",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.37,
    "FEB": 5.91,
    "MAR": 5.33,
    "APR": 5.17,
    "MAY": 4.62,
    "JUN": 4.57,
    "JUL": 4.66,
    "AUG": 5.17,
    "SEP": 5.21,
    "OCT": 4.99,
    "NOV": 4.56,
    "DEC": 5.11
  },
  {
    "LONG": -4.22,
    "LAT": -19.16,
    "CIDADE": "Periquito",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.42,
    "FEB": 5.92,
    "MAR": 5.38,
    "APR": 5.2,
    "MAY": 4.64,
    "JUN": 4.54,
    "JUL": 4.62,
    "AUG": 5.15,
    "SEP": 5.18,
    "OCT": 5.02,
    "NOV": 4.59,
    "DEC": 5.21
  },
  {
    "LONG": -42.05,
    "LAT": -1.92,
    "CIDADE": "Engenheiro Caldas",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.39,
    "FEB": 5.83,
    "MAR": 5.25,
    "APR": 5.04,
    "MAY": 4.54,
    "JUN": 4.42,
    "JUL": 4.44,
    "AUG": 4.99,
    "SEP": 5.11,
    "OCT": 4.97,
    "NOV": 4.54,
    "DEC": 5.17
  },
  {
    "LONG": -42.08,
    "LAT": -19.15,
    "CIDADE": "Fernandes Tourinho",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.39,
    "FEB": 5.83,
    "MAR": 5.25,
    "APR": 5.04,
    "MAY": 4.54,
    "JUN": 4.42,
    "JUL": 4.44,
    "AUG": 4.99,
    "SEP": 5.11,
    "OCT": 4.97,
    "NOV": 4.54,
    "DEC": 5.17
  },
  {
    "LONG": -4.21,
    "LAT": -1.92,
    "CIDADE": "Sobrália",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.39,
    "FEB": 5.83,
    "MAR": 5.25,
    "APR": 5.04,
    "MAY": 4.54,
    "JUN": 4.42,
    "JUL": 4.44,
    "AUG": 4.99,
    "SEP": 5.11,
    "OCT": 4.97,
    "NOV": 4.54,
    "DEC": 5.17
  },
  {
    "LONG": -41.86,
    "LAT": -19.17,
    "CIDADE": "Itanhomi",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.4,
    "FEB": 5.8,
    "MAR": 5.28,
    "APR": 5.01,
    "MAY": 4.44,
    "JUN": 4.33,
    "JUL": 4.42,
    "AUG": 5.01,
    "SEP": 5.13,
    "OCT": 4.97,
    "NOV": 4.56,
    "DEC": 5.18
  },
  {
    "LONG": -41.47,
    "LAT": -19.18,
    "CIDADE": "Conselheiro Pena",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.64,
    "FEB": 6.03,
    "MAR": 5.54,
    "APR": 5.25,
    "MAY": 4.71,
    "JUN": 4.53,
    "JUL": 4.58,
    "AUG": 5.06,
    "SEP": 5.21,
    "OCT": 5.07,
    "NOV": 4.7,
    "DEC": 5.31
  },
  {
    "LONG": -46.68,
    "LAT": -19.11,
    "CIDADE": "Serra do Salitre",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.1,
    "FEB": 5.67,
    "MAR": 5.07,
    "APR": 5.52,
    "MAY": 5.46,
    "JUN": 5.34,
    "JUL": 5.62,
    "AUG": 6.41,
    "SEP": 5.85,
    "OCT": 5.43,
    "NOV": 4.87,
    "DEC": 5.01
  },
  {
    "LONG": -45.71,
    "LAT": -19.15,
    "CIDADE": "Cedro do Abaeté",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.27,
    "FEB": 5.78,
    "MAR": 5.23,
    "APR": 5.59,
    "MAY": 5.42,
    "JUN": 5.39,
    "JUL": 5.73,
    "AUG": 6.46,
    "SEP": 5.98,
    "OCT": 5.46,
    "NOV": 4.81,
    "DEC": 5
  },
  {
    "LONG": -44.32,
    "LAT": -19.12,
    "CIDADE": "Cordisburgo",
    "UF": "MINAS GERAIS",
    "anual": 5.55,
    "JAN": 5.42,
    "FEB": 5.95,
    "MAR": 5.48,
    "APR": 5.63,
    "MAY": 5.32,
    "JUN": 5.21,
    "JUL": 5.55,
    "AUG": 6.05,
    "SEP": 6.05,
    "OCT": 5.61,
    "NOV": 5.05,
    "DEC": 5.22
  },
  {
    "LONG": -43.14,
    "LAT": -19.09,
    "CIDADE": "Carmésia",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.1,
    "FEB": 5.63,
    "MAR": 5.04,
    "APR": 4.93,
    "MAY": 4.52,
    "JUN": 4.43,
    "JUL": 4.6,
    "AUG": 5.3,
    "SEP": 5.26,
    "OCT": 4.99,
    "NOV": 4.44,
    "DEC": 4.92
  },
  {
    "LONG": -42.93,
    "LAT": -19.05,
    "CIDADE": "Dores de Guanhães",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.23,
    "FEB": 5.71,
    "MAR": 5.08,
    "APR": 4.89,
    "MAY": 4.51,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.2,
    "SEP": 5.24,
    "OCT": 4.98,
    "NOV": 4.46,
    "DEC": 4.93
  },
  {
    "LONG": -42.71,
    "LAT": -19.06,
    "CIDADE": "Braúnas",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.29,
    "FEB": 5.78,
    "MAR": 5.23,
    "APR": 5.12,
    "MAY": 4.61,
    "JUN": 4.53,
    "JUL": 4.67,
    "AUG": 5.2,
    "SEP": 5.19,
    "OCT": 5.01,
    "NOV": 4.5,
    "DEC": 5.02
  },
  {
    "LONG": -42.55,
    "LAT": -19.07,
    "CIDADE": "Açucena",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.17,
    "FEB": 5.65,
    "MAR": 5.07,
    "APR": 4.97,
    "MAY": 4.44,
    "JUN": 4.42,
    "JUL": 4.52,
    "AUG": 5.04,
    "SEP": 5.11,
    "OCT": 4.87,
    "NOV": 4.34,
    "DEC": 4.93
  },
  {
    "LONG": -41.86,
    "LAT": -19.08,
    "CIDADE": "Capitão Andrade",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.44,
    "FEB": 5.84,
    "MAR": 5.36,
    "APR": 5.1,
    "MAY": 4.49,
    "JUN": 4.4,
    "JUL": 4.46,
    "AUG": 5.02,
    "SEP": 5.14,
    "OCT": 4.99,
    "NOV": 4.59,
    "DEC": 5.2
  },
  {
    "LONG": -49.46,
    "LAT": -18.98,
    "CIDADE": "Ituiutaba",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.2,
    "FEB": 5.46,
    "MAR": 5.36,
    "APR": 5.56,
    "MAY": 5.3,
    "JUN": 5.26,
    "JUL": 5.44,
    "AUG": 6.21,
    "SEP": 5.58,
    "OCT": 5.46,
    "NOV": 5.26,
    "DEC": 5.29
  },
  {
    "LONG": -47.92,
    "LAT": -19.04,
    "CIDADE": "Indianópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5,
    "FEB": 5.62,
    "MAR": 5.13,
    "APR": 5.47,
    "MAY": 5.35,
    "JUN": 5.3,
    "JUL": 5.48,
    "AUG": 6.3,
    "SEP": 5.74,
    "OCT": 5.45,
    "NOV": 5.09,
    "DEC": 5.14
  },
  {
    "LONG": -47.46,
    "LAT": -18.99,
    "CIDADE": "Iraí de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.38,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 4.99,
    "APR": 5.36,
    "MAY": 5.4,
    "JUN": 5.23,
    "JUL": 5.52,
    "AUG": 6.32,
    "SEP": 5.76,
    "OCT": 5.39,
    "NOV": 4.96,
    "DEC": 5.07
  },
  {
    "LONG": -46.31,
    "LAT": -19,
    "CIDADE": "Carmo do Paranaíba",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.22,
    "FEB": 5.75,
    "MAR": 5.17,
    "APR": 5.56,
    "MAY": 5.43,
    "JUN": 5.29,
    "JUL": 5.66,
    "AUG": 6.42,
    "SEP": 5.92,
    "OCT": 5.43,
    "NOV": 4.89,
    "DEC": 5.07
  },
  {
    "LONG": -46.15,
    "LAT": -1.9,
    "CIDADE": "Arapuá",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.2,
    "FEB": 5.78,
    "MAR": 5.17,
    "APR": 5.57,
    "MAY": 5.43,
    "JUN": 5.34,
    "JUL": 5.68,
    "AUG": 6.47,
    "SEP": 5.96,
    "OCT": 5.41,
    "NOV": 4.79,
    "DEC": 5.04
  },
  {
    "LONG": -4.6,
    "LAT": -19,
    "CIDADE": "Tiros",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.2,
    "FEB": 5.76,
    "MAR": 5.16,
    "APR": 5.54,
    "MAY": 5.46,
    "JUN": 5.37,
    "JUL": 5.67,
    "AUG": 6.45,
    "SEP": 5.99,
    "OCT": 5.44,
    "NOV": 4.77,
    "DEC": 4.97
  },
  {
    "LONG": -44.04,
    "LAT": -19,
    "CIDADE": "Santana de Pirapama",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.5,
    "FEB": 6.12,
    "MAR": 5.64,
    "APR": 5.71,
    "MAY": 5.42,
    "JUN": 5.28,
    "JUL": 5.62,
    "AUG": 6.13,
    "SEP": 6.13,
    "OCT": 5.74,
    "NOV": 5.16,
    "DEC": 5.35
  },
  {
    "LONG": -43.42,
    "LAT": -19.03,
    "CIDADE": "Conceição do Mato Dentro",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.09,
    "FEB": 5.62,
    "MAR": 5.08,
    "APR": 5,
    "MAY": 4.63,
    "JUN": 4.53,
    "JUL": 4.7,
    "AUG": 5.35,
    "SEP": 5.31,
    "OCT": 5.05,
    "NOV": 4.46,
    "DEC": 4.89
  },
  {
    "LONG": -43.25,
    "LAT": -18.96,
    "CIDADE": "Dom Joaquim",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.21,
    "FEB": 5.73,
    "MAR": 5.15,
    "APR": 5,
    "MAY": 4.55,
    "JUN": 4.47,
    "JUL": 4.64,
    "AUG": 5.32,
    "SEP": 5.27,
    "OCT": 5.05,
    "NOV": 4.54,
    "DEC": 4.98
  },
  {
    "LONG": -41.97,
    "LAT": -18.97,
    "CIDADE": "Alpercata",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.45,
    "FEB": 5.88,
    "MAR": 5.45,
    "APR": 5.19,
    "MAY": 4.56,
    "JUN": 4.49,
    "JUL": 4.52,
    "AUG": 5.12,
    "SEP": 5.19,
    "OCT": 5.05,
    "NOV": 4.61,
    "DEC": 5.24
  },
  {
    "LONG": -4.17,
    "LAT": -18.98,
    "CIDADE": "Tumiritinga",
    "UF": "MINAS GERAIS",
    "anual": 5.11,
    "JAN": 5.61,
    "FEB": 6,
    "MAR": 5.52,
    "APR": 5.19,
    "MAY": 4.58,
    "JUN": 4.53,
    "JUL": 4.53,
    "AUG": 5.04,
    "SEP": 5.2,
    "OCT": 5.09,
    "NOV": 4.69,
    "DEC": 5.28
  },
  {
    "LONG": -4.15,
    "LAT": -19,
    "CIDADE": "Galiléia",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.56,
    "FEB": 5.95,
    "MAR": 5.48,
    "APR": 5.19,
    "MAY": 4.6,
    "JUN": 4.5,
    "JUL": 4.48,
    "AUG": 5.01,
    "SEP": 5.15,
    "OCT": 5.06,
    "NOV": 4.66,
    "DEC": 5.26
  },
  {
    "LONG": -41.22,
    "LAT": -18.98,
    "CIDADE": "Goiabeira",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.52,
    "FEB": 5.91,
    "MAR": 5.44,
    "APR": 5.1,
    "MAY": 4.58,
    "JUN": 4.39,
    "JUL": 4.45,
    "AUG": 4.94,
    "SEP": 5.09,
    "OCT": 4.96,
    "NOV": 4.65,
    "DEC": 5.2
  },
  {
    "LONG": -4.11,
    "LAT": -18.97,
    "CIDADE": "Cuparaque",
    "UF": "MINAS GERAIS",
    "anual": 4.89,
    "JAN": 5.34,
    "FEB": 5.75,
    "MAR": 5.22,
    "APR": 4.92,
    "MAY": 4.47,
    "JUN": 4.25,
    "JUL": 4.31,
    "AUG": 4.84,
    "SEP": 5.07,
    "OCT": 4.92,
    "NOV": 4.53,
    "DEC": 5.07
  },
  {
    "LONG": -4.89,
    "LAT": -1.89,
    "CIDADE": "Monte Alegre de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.08,
    "FEB": 5.56,
    "MAR": 5.19,
    "APR": 5.56,
    "MAY": 5.32,
    "JUN": 5.23,
    "JUL": 5.49,
    "AUG": 6.31,
    "SEP": 5.6,
    "OCT": 5.47,
    "NOV": 5.2,
    "DEC": 5.19
  },
  {
    "LONG": -48.28,
    "LAT": -18.91,
    "CIDADE": "Uberlândia",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.05,
    "FEB": 5.6,
    "MAR": 5.24,
    "APR": 5.59,
    "MAY": 5.37,
    "JUN": 5.33,
    "JUL": 5.51,
    "AUG": 6.26,
    "SEP": 5.74,
    "OCT": 5.52,
    "NOV": 5.15,
    "DEC": 5.18
  },
  {
    "LONG": -4.76,
    "LAT": -18.88,
    "CIDADE": "Romaria",
    "UF": "MINAS GERAIS",
    "anual": 5.43,
    "JAN": 5.08,
    "FEB": 5.59,
    "MAR": 5.09,
    "APR": 5.42,
    "MAY": 5.4,
    "JUN": 5.32,
    "JUL": 5.59,
    "AUG": 6.39,
    "SEP": 5.82,
    "OCT": 5.43,
    "NOV": 4.98,
    "DEC": 5.1
  },
  {
    "LONG": -46.99,
    "LAT": -18.94,
    "CIDADE": "Patrocínio",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.11,
    "FEB": 5.63,
    "MAR": 5.16,
    "APR": 5.53,
    "MAY": 5.55,
    "JUN": 5.39,
    "JUL": 5.72,
    "AUG": 6.44,
    "SEP": 5.91,
    "OCT": 5.48,
    "NOV": 4.91,
    "DEC": 4.99
  },
  {
    "LONG": -46.67,
    "LAT": -18.95,
    "CIDADE": "Cruzeiro da Fortaleza",
    "UF": "MINAS GERAIS",
    "anual": 5.5,
    "JAN": 5.17,
    "FEB": 5.81,
    "MAR": 5.16,
    "APR": 5.57,
    "MAY": 5.51,
    "JUN": 5.38,
    "JUL": 5.66,
    "AUG": 6.44,
    "SEP": 5.93,
    "OCT": 5.47,
    "NOV": 4.87,
    "DEC": 5.06
  },
  {
    "LONG": -4.55,
    "LAT": -18.9,
    "CIDADE": "Paineiras",
    "UF": "MINAS GERAIS",
    "anual": 5.6,
    "JAN": 5.42,
    "FEB": 5.92,
    "MAR": 5.39,
    "APR": 5.7,
    "MAY": 5.55,
    "JUN": 5.35,
    "JUL": 5.75,
    "AUG": 6.4,
    "SEP": 6.08,
    "OCT": 5.59,
    "NOV": 4.95,
    "DEC": 5.15
  },
  {
    "LONG": -43.08,
    "LAT": -18.89,
    "CIDADE": "Senhora do Porto",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.19,
    "FEB": 5.65,
    "MAR": 5.06,
    "APR": 4.88,
    "MAY": 4.53,
    "JUN": 4.47,
    "JUL": 4.57,
    "AUG": 5.18,
    "SEP": 5.23,
    "OCT": 4.96,
    "NOV": 4.44,
    "DEC": 4.94
  },
  {
    "LONG": -41.96,
    "LAT": -18.85,
    "CIDADE": "Governador Valadares",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.44,
    "FEB": 5.84,
    "MAR": 5.39,
    "APR": 5.07,
    "MAY": 4.54,
    "JUN": 4.41,
    "JUL": 4.47,
    "AUG": 5.11,
    "SEP": 5.21,
    "OCT": 5.06,
    "NOV": 4.58,
    "DEC": 5.19
  },
  {
    "LONG": -41.36,
    "LAT": -18.91,
    "CIDADE": "São Geraldo do Baixio",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.42,
    "FEB": 5.83,
    "MAR": 5.34,
    "APR": 5.04,
    "MAY": 4.56,
    "JUN": 4.35,
    "JUL": 4.38,
    "AUG": 4.9,
    "SEP": 5.09,
    "OCT": 4.98,
    "NOV": 4.6,
    "DEC": 5.16
  },
  {
    "LONG": -50.12,
    "LAT": -18.84,
    "CIDADE": "Santa Vitória",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.33,
    "FEB": 5.6,
    "MAR": 5.52,
    "APR": 5.68,
    "MAY": 5.34,
    "JUN": 5.22,
    "JUL": 5.37,
    "AUG": 6.12,
    "SEP": 5.57,
    "OCT": 5.5,
    "NOV": 5.41,
    "DEC": 5.42
  },
  {
    "LONG": -4.68,
    "LAT": -18.84,
    "CIDADE": "Guimarânia",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.07,
    "FEB": 5.69,
    "MAR": 5.09,
    "APR": 5.53,
    "MAY": 5.54,
    "JUN": 5.39,
    "JUL": 5.68,
    "AUG": 6.45,
    "SEP": 5.92,
    "OCT": 5.46,
    "NOV": 4.88,
    "DEC": 5
  },
  {
    "LONG": -46.4,
    "LAT": -1.88,
    "CIDADE": "Lagoa Formosa",
    "UF": "MINAS GERAIS",
    "anual": 5.54,
    "JAN": 5.24,
    "FEB": 5.75,
    "MAR": 5.24,
    "APR": 5.59,
    "MAY": 5.55,
    "JUN": 5.42,
    "JUL": 5.7,
    "AUG": 6.45,
    "SEP": 5.99,
    "OCT": 5.52,
    "NOV": 4.95,
    "DEC": 5.08
  },
  {
    "LONG": -45.5,
    "LAT": -18.77,
    "CIDADE": "Biquinhas",
    "UF": "MINAS GERAIS",
    "anual": 5.66,
    "JAN": 5.47,
    "FEB": 5.97,
    "MAR": 5.49,
    "APR": 5.77,
    "MAY": 5.58,
    "JUN": 5.4,
    "JUL": 5.78,
    "AUG": 6.39,
    "SEP": 6.14,
    "OCT": 5.7,
    "NOV": 5.05,
    "DEC": 5.2
  },
  {
    "LONG": -44.9,
    "LAT": -18.75,
    "CIDADE": "Felixlândia",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.5,
    "FEB": 6.04,
    "MAR": 5.58,
    "APR": 5.86,
    "MAY": 5.5,
    "JUN": 5.3,
    "JUL": 5.72,
    "AUG": 6.17,
    "SEP": 6.12,
    "OCT": 5.61,
    "NOV": 5.1,
    "DEC": 5.27
  },
  {
    "LONG": -44.43,
    "LAT": -18.75,
    "CIDADE": "Curvelo",
    "UF": "MINAS GERAIS",
    "anual": 5.63,
    "JAN": 5.49,
    "FEB": 6.06,
    "MAR": 5.62,
    "APR": 5.75,
    "MAY": 5.46,
    "JUN": 5.24,
    "JUL": 5.67,
    "AUG": 6.1,
    "SEP": 6.11,
    "OCT": 5.69,
    "NOV": 5.12,
    "DEC": 5.29
  },
  {
    "LONG": -43.68,
    "LAT": -18.8,
    "CIDADE": "Congonhas do Norte",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.2,
    "FEB": 5.64,
    "MAR": 5.11,
    "APR": 5.04,
    "MAY": 4.8,
    "JUN": 4.76,
    "JUL": 4.99,
    "AUG": 5.62,
    "SEP": 5.62,
    "OCT": 5.31,
    "NOV": 4.6,
    "DEC": 4.97
  },
  {
    "LONG": -42.93,
    "LAT": -18.77,
    "CIDADE": "Guanhães",
    "UF": "MINAS GERAIS",
    "anual": 4.91,
    "JAN": 5.18,
    "FEB": 5.67,
    "MAR": 5,
    "APR": 4.79,
    "MAY": 4.48,
    "JUN": 4.44,
    "JUL": 4.56,
    "AUG": 5.13,
    "SEP": 5.23,
    "OCT": 5.03,
    "NOV": 4.48,
    "DEC": 4.96
  },
  {
    "LONG": -42.7,
    "LAT": -18.82,
    "CIDADE": "Virginópolis",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.11,
    "FEB": 5.6,
    "MAR": 4.97,
    "APR": 4.77,
    "MAY": 4.37,
    "JUN": 4.37,
    "JUL": 4.47,
    "AUG": 5.02,
    "SEP": 5.15,
    "OCT": 4.93,
    "NOV": 4.36,
    "DEC": 4.89
  },
  {
    "LONG": -42.62,
    "LAT": -18.81,
    "CIDADE": "Divinolândia de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.82,
    "JAN": 5.12,
    "FEB": 5.61,
    "MAR": 4.96,
    "APR": 4.77,
    "MAY": 4.34,
    "JUN": 4.34,
    "JUL": 4.45,
    "AUG": 4.99,
    "SEP": 5.15,
    "OCT": 4.92,
    "NOV": 4.34,
    "DEC": 4.89
  },
  {
    "LONG": -42.44,
    "LAT": -18.82,
    "CIDADE": "Santa Efigênia de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 4.97,
    "APR": 4.72,
    "MAY": 4.29,
    "JUN": 4.27,
    "JUL": 4.34,
    "AUG": 4.94,
    "SEP": 5.08,
    "OCT": 4.87,
    "NOV": 4.26,
    "DEC": 4.87
  },
  {
    "LONG": -42.48,
    "LAT": -18.82,
    "CIDADE": "Gonzaga",
    "UF": "MINAS GERAIS",
    "anual": 4.77,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 4.97,
    "APR": 4.72,
    "MAY": 4.29,
    "JUN": 4.27,
    "JUL": 4.34,
    "AUG": 4.94,
    "SEP": 5.08,
    "OCT": 4.87,
    "NOV": 4.26,
    "DEC": 4.87
  },
  {
    "LONG": -42.36,
    "LAT": -18.78,
    "CIDADE": "Sardoá",
    "UF": "MINAS GERAIS",
    "anual": 4.81,
    "JAN": 5.14,
    "FEB": 5.59,
    "MAR": 5.05,
    "APR": 4.79,
    "MAY": 4.33,
    "JUN": 4.28,
    "JUL": 4.34,
    "AUG": 4.94,
    "SEP": 5.09,
    "OCT": 4.89,
    "NOV": 4.31,
    "DEC": 4.92
  },
  {
    "LONG": -42.29,
    "LAT": -18.84,
    "CIDADE": "São Geraldo da Piedade",
    "UF": "MINAS GERAIS",
    "anual": 4.88,
    "JAN": 5.26,
    "FEB": 5.71,
    "MAR": 5.16,
    "APR": 4.95,
    "MAY": 4.41,
    "JUN": 4.35,
    "JUL": 4.41,
    "AUG": 4.96,
    "SEP": 5.1,
    "OCT": 4.91,
    "NOV": 4.39,
    "DEC": 4.98
  },
  {
    "LONG": -41.48,
    "LAT": -1.88,
    "CIDADE": "Divino das Laranjeiras",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.48,
    "FEB": 5.89,
    "MAR": 5.38,
    "APR": 5.07,
    "MAY": 4.55,
    "JUN": 4.41,
    "JUL": 4.44,
    "AUG": 4.96,
    "SEP": 5.13,
    "OCT": 5.02,
    "NOV": 4.61,
    "DEC": 5.16
  },
  {
    "LONG": -41.31,
    "LAT": -18.76,
    "CIDADE": "Central de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.02,
    "JAN": 5.49,
    "FEB": 5.92,
    "MAR": 5.39,
    "APR": 5.07,
    "MAY": 4.55,
    "JUN": 4.38,
    "JUL": 4.41,
    "AUG": 4.95,
    "SEP": 5.15,
    "OCT": 5.04,
    "NOV": 4.64,
    "DEC": 5.2
  },
  {
    "LONG": -40.99,
    "LAT": -18.78,
    "CIDADE": "Mantena",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.49,
    "FEB": 5.91,
    "MAR": 5.46,
    "APR": 5.06,
    "MAY": 4.52,
    "JUN": 4.35,
    "JUL": 4.36,
    "AUG": 4.77,
    "SEP": 5.03,
    "OCT": 4.86,
    "NOV": 4.62,
    "DEC": 5.22
  },
  {
    "LONG": -4.99,
    "LAT": -18.69,
    "CIDADE": "Ipiaçu",
    "UF": "MINAS GERAIS",
    "anual": 5.5,
    "JAN": 5.4,
    "FEB": 5.58,
    "MAR": 5.47,
    "APR": 5.71,
    "MAY": 5.4,
    "JUN": 5.19,
    "JUL": 5.29,
    "AUG": 6.02,
    "SEP": 5.55,
    "OCT": 5.47,
    "NOV": 5.43,
    "DEC": 5.43
  },
  {
    "LONG": -4.96,
    "LAT": -18.69,
    "CIDADE": "Capinópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.47,
    "JAN": 5.22,
    "FEB": 5.52,
    "MAR": 5.31,
    "APR": 5.66,
    "MAY": 5.43,
    "JUN": 5.3,
    "JUL": 5.46,
    "AUG": 6.16,
    "SEP": 5.53,
    "OCT": 5.47,
    "NOV": 5.3,
    "DEC": 5.28
  },
  {
    "LONG": -4.92,
    "LAT": -18.72,
    "CIDADE": "Canápolis",
    "UF": "MINAS GERAIS",
    "anual": 5.5,
    "JAN": 5.19,
    "FEB": 5.56,
    "MAR": 5.45,
    "APR": 5.66,
    "MAY": 5.48,
    "JUN": 5.34,
    "JUL": 5.47,
    "AUG": 6.25,
    "SEP": 5.56,
    "OCT": 5.47,
    "NOV": 5.31,
    "DEC": 5.26
  },
  {
    "LONG": -4.77,
    "LAT": -18.74,
    "CIDADE": "Estrela do Sul",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.16,
    "FEB": 5.73,
    "MAR": 5.31,
    "APR": 5.6,
    "MAY": 5.49,
    "JUN": 5.37,
    "JUL": 5.6,
    "AUG": 6.33,
    "SEP": 5.85,
    "OCT": 5.49,
    "NOV": 5.06,
    "DEC": 5.18
  },
  {
    "LONG": -47.49,
    "LAT": -18.73,
    "CIDADE": "Monte Carmelo",
    "UF": "MINAS GERAIS",
    "anual": 5.53,
    "JAN": 5.11,
    "FEB": 5.65,
    "MAR": 5.25,
    "APR": 5.57,
    "MAY": 5.49,
    "JUN": 5.42,
    "JUL": 5.66,
    "AUG": 6.42,
    "SEP": 5.91,
    "OCT": 5.56,
    "NOV": 5.09,
    "DEC": 5.19
  },
  {
    "LONG": -4.44,
    "LAT": -1.87,
    "CIDADE": "Inimutaba",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.56,
    "FEB": 6.08,
    "MAR": 5.65,
    "APR": 5.86,
    "MAY": 5.49,
    "JUN": 5.18,
    "JUL": 5.65,
    "AUG": 6.06,
    "SEP": 6.11,
    "OCT": 5.72,
    "NOV": 5.13,
    "DEC": 5.32
  },
  {
    "LONG": -43.36,
    "LAT": -18.73,
    "CIDADE": "Alvorada de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.18,
    "FEB": 5.7,
    "MAR": 5.07,
    "APR": 4.97,
    "MAY": 4.63,
    "JUN": 4.56,
    "JUL": 4.7,
    "AUG": 5.35,
    "SEP": 5.36,
    "OCT": 5.12,
    "NOV": 4.48,
    "DEC": 4.91
  },
  {
    "LONG": -43.08,
    "LAT": -18.67,
    "CIDADE": "Sabinópolis",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.2,
    "FEB": 5.69,
    "MAR": 5.05,
    "APR": 4.88,
    "MAY": 4.52,
    "JUN": 4.46,
    "JUL": 4.62,
    "AUG": 5.22,
    "SEP": 5.28,
    "OCT": 5.05,
    "NOV": 4.5,
    "DEC": 4.98
  },
  {
    "LONG": -41.41,
    "LAT": -18.66,
    "CIDADE": "Mendes Pimentel",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.38,
    "FEB": 5.78,
    "MAR": 5.28,
    "APR": 5.02,
    "MAY": 4.52,
    "JUN": 4.35,
    "JUL": 4.43,
    "AUG": 4.9,
    "SEP": 5.14,
    "OCT": 5.02,
    "NOV": 4.56,
    "DEC": 5.08
  },
  {
    "LONG": -41.16,
    "LAT": -18.72,
    "CIDADE": "São João do Manteninha",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.59,
    "FEB": 5.96,
    "MAR": 5.49,
    "APR": 5.18,
    "MAY": 4.61,
    "JUN": 4.41,
    "JUL": 4.48,
    "AUG": 4.96,
    "SEP": 5.2,
    "OCT": 5.08,
    "NOV": 4.72,
    "DEC": 5.24
  },
  {
    "LONG": -49.2,
    "LAT": -18.59,
    "CIDADE": "Centralina",
    "UF": "MINAS GERAIS",
    "anual": 5.51,
    "JAN": 5.18,
    "FEB": 5.58,
    "MAR": 5.47,
    "APR": 5.72,
    "MAY": 5.53,
    "JUN": 5.37,
    "JUL": 5.45,
    "AUG": 6.22,
    "SEP": 5.53,
    "OCT": 5.5,
    "NOV": 5.33,
    "DEC": 5.24
  },
  {
    "LONG": -4.87,
    "LAT": -1.86,
    "CIDADE": "Tupaciguara",
    "UF": "MINAS GERAIS",
    "anual": 5.48,
    "JAN": 5.14,
    "FEB": 5.64,
    "MAR": 5.28,
    "APR": 5.6,
    "MAY": 5.43,
    "JUN": 5.33,
    "JUL": 5.48,
    "AUG": 6.21,
    "SEP": 5.64,
    "OCT": 5.5,
    "NOV": 5.23,
    "DEC": 5.28
  },
  {
    "LONG": -48.19,
    "LAT": -1.86,
    "CIDADE": "Araguari",
    "UF": "MINAS GERAIS",
    "anual": 5.52,
    "JAN": 5.07,
    "FEB": 5.66,
    "MAR": 5.3,
    "APR": 5.73,
    "MAY": 5.5,
    "JUN": 5.44,
    "JUL": 5.56,
    "AUG": 6.37,
    "SEP": 5.8,
    "OCT": 5.53,
    "NOV": 5.12,
    "DEC": 5.19
  },
  {
    "LONG": -47.87,
    "LAT": -18.58,
    "CIDADE": "Cascalho Rico",
    "UF": "MINAS GERAIS",
    "anual": 5.58,
    "JAN": 5.18,
    "FEB": 5.83,
    "MAR": 5.42,
    "APR": 5.74,
    "MAY": 5.51,
    "JUN": 5.47,
    "JUL": 5.56,
    "AUG": 6.36,
    "SEP": 5.85,
    "OCT": 5.61,
    "NOV": 5.17,
    "DEC": 5.29
  },
  {
    "LONG": -46.51,
    "LAT": -18.58,
    "CIDADE": "Patos de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.53,
    "JAN": 5.18,
    "FEB": 5.71,
    "MAR": 5.25,
    "APR": 5.58,
    "MAY": 5.52,
    "JUN": 5.41,
    "JUL": 5.71,
    "AUG": 6.49,
    "SEP": 6.02,
    "OCT": 5.53,
    "NOV": 4.91,
    "DEC": 5.05
  },
  {
    "LONG": -45.36,
    "LAT": -18.6,
    "CIDADE": "Morada Nova de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.69,
    "JAN": 5.58,
    "FEB": 6.05,
    "MAR": 5.62,
    "APR": 5.81,
    "MAY": 5.53,
    "JUN": 5.34,
    "JUL": 5.74,
    "AUG": 6.19,
    "SEP": 6.13,
    "OCT": 5.78,
    "NOV": 5.19,
    "DEC": 5.31
  },
  {
    "LONG": -4.41,
    "LAT": -1.86,
    "CIDADE": "Presidente Juscelino",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.53,
    "FEB": 6.05,
    "MAR": 5.58,
    "APR": 5.81,
    "MAY": 5.48,
    "JUN": 5.27,
    "JUL": 5.66,
    "AUG": 6.1,
    "SEP": 6.12,
    "OCT": 5.74,
    "NOV": 5.13,
    "DEC": 5.3
  },
  {
    "LONG": -4.36,
    "LAT": -1.86,
    "CIDADE": "Presidente Kubitschek",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.33,
    "FEB": 5.83,
    "MAR": 5.22,
    "APR": 5.12,
    "MAY": 4.93,
    "JUN": 4.87,
    "JUL": 5.12,
    "AUG": 5.75,
    "SEP": 5.77,
    "OCT": 5.4,
    "NOV": 4.67,
    "DEC": 5.05
  },
  {
    "LONG": -43.37,
    "LAT": -18.6,
    "CIDADE": "Serro",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.23,
    "FEB": 5.75,
    "MAR": 5.1,
    "APR": 4.99,
    "MAY": 4.67,
    "JUN": 4.62,
    "JUL": 4.75,
    "AUG": 5.38,
    "SEP": 5.43,
    "OCT": 5.16,
    "NOV": 4.48,
    "DEC": 4.92
  },
  {
    "LONG": -42.28,
    "LAT": -1.86,
    "CIDADE": "Coroaci",
    "UF": "MINAS GERAIS",
    "anual": 4.85,
    "JAN": 5.24,
    "FEB": 5.73,
    "MAR": 5.11,
    "APR": 4.8,
    "MAY": 4.34,
    "JUN": 4.27,
    "JUL": 4.37,
    "AUG": 4.93,
    "SEP": 5.11,
    "OCT": 4.93,
    "NOV": 4.37,
    "DEC": 5.01
  },
  {
    "LONG": -41.91,
    "LAT": -18.56,
    "CIDADE": "Frei Inocêncio",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.53,
    "FEB": 5.93,
    "MAR": 5.48,
    "APR": 5.12,
    "MAY": 4.59,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 4.99,
    "SEP": 5.23,
    "OCT": 5.09,
    "NOV": 4.61,
    "DEC": 5.29
  },
  {
    "LONG": -41.92,
    "LAT": -18.59,
    "CIDADE": "Mathias Lobato",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.53,
    "FEB": 5.93,
    "MAR": 5.48,
    "APR": 5.12,
    "MAY": 4.59,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 4.99,
    "SEP": 5.23,
    "OCT": 5.09,
    "NOV": 4.61,
    "DEC": 5.29
  },
  {
    "LONG": -41.49,
    "LAT": -18.6,
    "CIDADE": "São Félix de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.93,
    "JAN": 5.37,
    "FEB": 5.73,
    "MAR": 5.22,
    "APR": 4.95,
    "MAY": 4.43,
    "JUN": 4.32,
    "JUL": 4.39,
    "AUG": 4.89,
    "SEP": 5.12,
    "OCT": 5.04,
    "NOV": 4.58,
    "DEC": 5.11
  },
  {
    "LONG": -41.23,
    "LAT": -18.57,
    "CIDADE": "Itabirinha",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.39,
    "FEB": 5.79,
    "MAR": 5.31,
    "APR": 5.06,
    "MAY": 4.5,
    "JUN": 4.35,
    "JUL": 4.43,
    "AUG": 4.91,
    "SEP": 5.1,
    "OCT": 4.96,
    "NOV": 4.57,
    "DEC": 5.11
  },
  {
    "LONG": -49.5,
    "LAT": -18.52,
    "CIDADE": "Cachoeira Dourada",
    "UF": "MINAS GERAIS",
    "anual": 5.45,
    "JAN": 5.11,
    "FEB": 5.5,
    "MAR": 5.36,
    "APR": 5.63,
    "MAY": 5.46,
    "JUN": 5.28,
    "JUL": 5.48,
    "AUG": 6.22,
    "SEP": 5.52,
    "OCT": 5.42,
    "NOV": 5.25,
    "DEC": 5.19
  },
  {
    "LONG": -47.72,
    "LAT": -18.5,
    "CIDADE": "Grupiara",
    "UF": "MINAS GERAIS",
    "anual": 5.59,
    "JAN": 5.25,
    "FEB": 5.84,
    "MAR": 5.49,
    "APR": 5.77,
    "MAY": 5.55,
    "JUN": 5.37,
    "JUL": 5.54,
    "AUG": 6.28,
    "SEP": 5.86,
    "OCT": 5.58,
    "NOV": 5.22,
    "DEC": 5.33
  },
  {
    "LONG": -47.39,
    "LAT": -18.48,
    "CIDADE": "Abadia dos Dourados",
    "UF": "MINAS GERAIS",
    "anual": 5.56,
    "JAN": 5.12,
    "FEB": 5.74,
    "MAR": 5.3,
    "APR": 5.63,
    "MAY": 5.56,
    "JUN": 5.41,
    "JUL": 5.72,
    "AUG": 6.46,
    "SEP": 5.95,
    "OCT": 5.58,
    "NOV": 5.07,
    "DEC": 5.22
  },
  {
    "LONG": -47.19,
    "LAT": -18.47,
    "CIDADE": "Coromandel",
    "UF": "MINAS GERAIS",
    "anual": 5.56,
    "JAN": 5.15,
    "FEB": 5.73,
    "MAR": 5.3,
    "APR": 5.59,
    "MAY": 5.58,
    "JUN": 5.39,
    "JUL": 5.75,
    "AUG": 6.42,
    "SEP": 5.97,
    "OCT": 5.61,
    "NOV": 5.04,
    "DEC": 5.16
  },
  {
    "LONG": -4.46,
    "LAT": -1.85,
    "CIDADE": "Morro da Garça",
    "UF": "MINAS GERAIS",
    "anual": 5.66,
    "JAN": 5.54,
    "FEB": 6.05,
    "MAR": 5.56,
    "APR": 5.83,
    "MAY": 5.52,
    "JUN": 5.31,
    "JUL": 5.73,
    "AUG": 6.14,
    "SEP": 6.13,
    "OCT": 5.69,
    "NOV": 5.08,
    "DEC": 5.29
  },
  {
    "LONG": -43.74,
    "LAT": -18.45,
    "CIDADE": "Gouveia",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.29,
    "FEB": 5.82,
    "MAR": 5.22,
    "APR": 5.23,
    "MAY": 5.12,
    "JUN": 5.07,
    "JUL": 5.38,
    "AUG": 5.98,
    "SEP": 5.95,
    "OCT": 5.5,
    "NOV": 4.73,
    "DEC": 5.07
  },
  {
    "LONG": -43.3,
    "LAT": -1.85,
    "CIDADE": "Santo Antônio do Itambé",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.19,
    "FEB": 5.7,
    "MAR": 5.07,
    "APR": 4.92,
    "MAY": 4.7,
    "JUN": 4.71,
    "JUL": 4.88,
    "AUG": 5.52,
    "SEP": 5.55,
    "OCT": 5.21,
    "NOV": 4.48,
    "DEC": 4.91
  },
  {
    "LONG": -43.06,
    "LAT": -18.47,
    "CIDADE": "Materlândia",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.39,
    "FEB": 5.82,
    "MAR": 5.17,
    "APR": 4.92,
    "MAY": 4.61,
    "JUN": 4.52,
    "JUL": 4.62,
    "AUG": 5.3,
    "SEP": 5.37,
    "OCT": 5.19,
    "NOV": 4.51,
    "DEC": 5.06
  },
  {
    "LONG": -42.77,
    "LAT": -18.55,
    "CIDADE": "São João Evangelista",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.33,
    "FEB": 5.82,
    "MAR": 5.2,
    "APR": 4.93,
    "MAY": 4.52,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.1,
    "SEP": 5.26,
    "OCT": 5.11,
    "NOV": 4.49,
    "DEC": 5.11
  },
  {
    "LONG": -42.63,
    "LAT": -18.52,
    "CIDADE": "Cantagalo",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.33,
    "FEB": 5.84,
    "MAR": 5.23,
    "APR": 4.95,
    "MAY": 4.52,
    "JUN": 4.42,
    "JUL": 4.49,
    "AUG": 5.09,
    "SEP": 5.27,
    "OCT": 5.11,
    "NOV": 4.48,
    "DEC": 5.12
  },
  {
    "LONG": -42.56,
    "LAT": -18.54,
    "CIDADE": "Peçanha",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.38,
    "FEB": 5.9,
    "MAR": 5.29,
    "APR": 4.94,
    "MAY": 4.5,
    "JUN": 4.39,
    "JUL": 4.47,
    "AUG": 5.07,
    "SEP": 5.26,
    "OCT": 5.1,
    "NOV": 4.49,
    "DEC": 5.15
  },
  {
    "LONG": -4.23,
    "LAT": -18.47,
    "CIDADE": "Virgolândia",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.5,
    "FEB": 5.89,
    "MAR": 5.28,
    "APR": 4.9,
    "MAY": 4.46,
    "JUN": 4.39,
    "JUL": 4.38,
    "AUG": 5.04,
    "SEP": 5.23,
    "OCT": 5.09,
    "NOV": 4.49,
    "DEC": 5.18
  },
  {
    "LONG": -42.25,
    "LAT": -18.45,
    "CIDADE": "Nacip Raydan",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.47,
    "FEB": 5.84,
    "MAR": 5.34,
    "APR": 4.96,
    "MAY": 4.47,
    "JUN": 4.4,
    "JUL": 4.42,
    "AUG": 5.06,
    "SEP": 5.22,
    "OCT": 5.09,
    "NOV": 4.53,
    "DEC": 5.18
  },
  {
    "LONG": -42.08,
    "LAT": -18.51,
    "CIDADE": "Marilac",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.46,
    "FEB": 5.95,
    "MAR": 5.5,
    "APR": 5.11,
    "MAY": 4.61,
    "JUN": 4.52,
    "JUL": 4.51,
    "AUG": 5.09,
    "SEP": 5.21,
    "OCT": 5.13,
    "NOV": 4.59,
    "DEC": 5.26
  },
  {
    "LONG": -41.81,
    "LAT": -18.46,
    "CIDADE": "Jampruca",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.47,
    "FEB": 5.91,
    "MAR": 5.47,
    "APR": 5.15,
    "MAY": 4.59,
    "JUN": 4.41,
    "JUL": 4.46,
    "AUG": 5,
    "SEP": 5.19,
    "OCT": 5.09,
    "NOV": 4.62,
    "DEC": 5.27
  },
  {
    "LONG": -4.14,
    "LAT": -18.48,
    "CIDADE": "São José do Divino",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.5,
    "FEB": 5.86,
    "MAR": 5.34,
    "APR": 5,
    "MAY": 4.45,
    "JUN": 4.33,
    "JUL": 4.38,
    "AUG": 4.86,
    "SEP": 5.1,
    "OCT": 5.02,
    "NOV": 4.64,
    "DEC": 5.23
  },
  {
    "LONG": -4.11,
    "LAT": -1.85,
    "CIDADE": "Nova Belém",
    "UF": "MINAS GERAIS",
    "anual": 4.84,
    "JAN": 5.31,
    "FEB": 5.66,
    "MAR": 5.15,
    "APR": 4.81,
    "MAY": 4.33,
    "JUN": 4.24,
    "JUL": 4.32,
    "AUG": 4.74,
    "SEP": 5.01,
    "OCT": 4.87,
    "NOV": 4.51,
    "DEC": 5.1
  },
  {
    "LONG": -49.19,
    "LAT": -18.44,
    "CIDADE": "Araporã",
    "UF": "MINAS GERAIS",
    "anual": 5.46,
    "JAN": 5.12,
    "FEB": 5.54,
    "MAR": 5.33,
    "APR": 5.61,
    "MAY": 5.49,
    "JUN": 5.34,
    "JUL": 5.44,
    "AUG": 6.14,
    "SEP": 5.57,
    "OCT": 5.49,
    "NOV": 5.23,
    "DEC": 5.2
  },
  {
    "LONG": -47.61,
    "LAT": -18.43,
    "CIDADE": "Douradoquara",
    "UF": "MINAS GERAIS",
    "anual": 5.59,
    "JAN": 5.21,
    "FEB": 5.78,
    "MAR": 5.42,
    "APR": 5.79,
    "MAY": 5.59,
    "JUN": 5.38,
    "JUL": 5.63,
    "AUG": 6.37,
    "SEP": 5.85,
    "OCT": 5.6,
    "NOV": 5.17,
    "DEC": 5.24
  },
  {
    "LONG": -46.42,
    "LAT": -18.41,
    "CIDADE": "Presidente Olegário",
    "UF": "MINAS GERAIS",
    "anual": 5.57,
    "JAN": 5.25,
    "FEB": 5.77,
    "MAR": 5.21,
    "APR": 5.57,
    "MAY": 5.57,
    "JUN": 5.47,
    "JUL": 5.84,
    "AUG": 6.49,
    "SEP": 6.07,
    "OCT": 5.56,
    "NOV": 4.97,
    "DEC": 5.08
  },
  {
    "LONG": -4.6,
    "LAT": -18.38,
    "CIDADE": "Varjão de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.61,
    "JAN": 5.4,
    "FEB": 5.83,
    "MAR": 5.3,
    "APR": 5.62,
    "MAY": 5.56,
    "JUN": 5.52,
    "JUL": 5.81,
    "AUG": 6.49,
    "SEP": 6.12,
    "OCT": 5.53,
    "NOV": 4.95,
    "DEC": 5.16
  },
  {
    "LONG": -44.45,
    "LAT": -18.37,
    "CIDADE": "Corinto",
    "UF": "MINAS GERAIS",
    "anual": 5.69,
    "JAN": 5.51,
    "FEB": 6.11,
    "MAR": 5.63,
    "APR": 5.85,
    "MAY": 5.53,
    "JUN": 5.37,
    "JUL": 5.74,
    "AUG": 6.16,
    "SEP": 6.16,
    "OCT": 5.78,
    "NOV": 5.13,
    "DEC": 5.33
  },
  {
    "LONG": -43.66,
    "LAT": -18.45,
    "CIDADE": "Datas",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.24,
    "FEB": 5.74,
    "MAR": 5.13,
    "APR": 5.1,
    "MAY": 4.99,
    "JUN": 5.01,
    "JUL": 5.37,
    "AUG": 5.95,
    "SEP": 5.9,
    "OCT": 5.45,
    "NOV": 4.68,
    "DEC": 5.03
  },
  {
    "LONG": -43.17,
    "LAT": -18.36,
    "CIDADE": "Serra Azul de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.32,
    "FEB": 5.82,
    "MAR": 5.18,
    "APR": 4.92,
    "MAY": 4.62,
    "JUN": 4.59,
    "JUL": 4.68,
    "AUG": 5.42,
    "SEP": 5.44,
    "OCT": 5.2,
    "NOV": 4.47,
    "DEC": 5.01
  },
  {
    "LONG": -42.87,
    "LAT": -18.43,
    "CIDADE": "Paulistas",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.41,
    "FEB": 5.9,
    "MAR": 5.24,
    "APR": 5,
    "MAY": 4.59,
    "JUN": 4.51,
    "JUL": 4.57,
    "AUG": 5.22,
    "SEP": 5.37,
    "OCT": 5.19,
    "NOV": 4.53,
    "DEC": 5.12
  },
  {
    "LONG": -4.26,
    "LAT": -1.84,
    "CIDADE": "São Pedro do Suaçuí",
    "UF": "MINAS GERAIS",
    "anual": 5.04,
    "JAN": 5.35,
    "FEB": 5.88,
    "MAR": 5.31,
    "APR": 5.05,
    "MAY": 4.54,
    "JUN": 4.5,
    "JUL": 4.54,
    "AUG": 5.14,
    "SEP": 5.29,
    "OCT": 5.14,
    "NOV": 4.56,
    "DEC": 5.15
  },
  {
    "LONG": -41.6,
    "LAT": -18.36,
    "CIDADE": "Pescador",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.45,
    "FEB": 5.9,
    "MAR": 5.45,
    "APR": 5.1,
    "MAY": 4.52,
    "JUN": 4.32,
    "JUL": 4.43,
    "AUG": 4.99,
    "SEP": 5.22,
    "OCT": 5.1,
    "NOV": 4.63,
    "DEC": 5.23
  },
  {
    "LONG": -41.5,
    "LAT": -18.44,
    "CIDADE": "Nova Módica",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.62,
    "FEB": 5.99,
    "MAR": 5.46,
    "APR": 5.07,
    "MAY": 4.51,
    "JUN": 4.37,
    "JUL": 4.38,
    "AUG": 4.99,
    "SEP": 5.19,
    "OCT": 5.12,
    "NOV": 4.71,
    "DEC": 5.27
  },
  {
    "LONG": -45.83,
    "LAT": -18.34,
    "CIDADE": "São Gonçalo do Abaeté",
    "UF": "MINAS GERAIS",
    "anual": 5.6,
    "JAN": 5.42,
    "FEB": 5.89,
    "MAR": 5.28,
    "APR": 5.61,
    "MAY": 5.54,
    "JUN": 5.4,
    "JUL": 5.82,
    "AUG": 6.45,
    "SEP": 6.16,
    "OCT": 5.54,
    "NOV": 4.92,
    "DEC": 5.13
  },
  {
    "LONG": -44.22,
    "LAT": -18.3,
    "CIDADE": "Santo Hipólito",
    "UF": "MINAS GERAIS",
    "anual": 5.71,
    "JAN": 5.59,
    "FEB": 6.16,
    "MAR": 5.69,
    "APR": 5.88,
    "MAY": 5.57,
    "JUN": 5.36,
    "JUL": 5.68,
    "AUG": 6.06,
    "SEP": 6.16,
    "OCT": 5.82,
    "NOV": 5.12,
    "DEC": 5.37
  },
  {
    "LONG": -44.12,
    "LAT": -18.32,
    "CIDADE": "Monjolos",
    "UF": "MINAS GERAIS",
    "anual": 5.67,
    "JAN": 5.58,
    "FEB": 6.13,
    "MAR": 5.62,
    "APR": 5.78,
    "MAY": 5.53,
    "JUN": 5.33,
    "JUL": 5.63,
    "AUG": 6.08,
    "SEP": 6.12,
    "OCT": 5.77,
    "NOV": 5.07,
    "DEC": 5.36
  },
  {
    "LONG": -4.3,
    "LAT": -1.83,
    "CIDADE": "Rio Vermelho",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.39,
    "FEB": 5.86,
    "MAR": 5.18,
    "APR": 4.94,
    "MAY": 4.62,
    "JUN": 4.57,
    "JUL": 4.68,
    "AUG": 5.4,
    "SEP": 5.47,
    "OCT": 5.21,
    "NOV": 4.5,
    "DEC": 5.03
  },
  {
    "LONG": -42.67,
    "LAT": -18.27,
    "CIDADE": "São José do Jacuri",
    "UF": "MINAS GERAIS",
    "anual": 4.99,
    "JAN": 5.3,
    "FEB": 5.83,
    "MAR": 5.24,
    "APR": 4.97,
    "MAY": 4.5,
    "JUN": 4.48,
    "JUL": 4.49,
    "AUG": 5.14,
    "SEP": 5.26,
    "OCT": 5.14,
    "NOV": 4.49,
    "DEC": 5.05
  },
  {
    "LONG": -42.15,
    "LAT": -18.32,
    "CIDADE": "São José da Safira",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.32,
    "FEB": 5.78,
    "MAR": 5.2,
    "APR": 4.91,
    "MAY": 4.48,
    "JUN": 4.38,
    "JUL": 4.45,
    "AUG": 5.06,
    "SEP": 5.19,
    "OCT": 5.07,
    "NOV": 4.48,
    "DEC": 5.07
  },
  {
    "LONG": -46.81,
    "LAT": -18.18,
    "CIDADE": "Lagamar",
    "UF": "MINAS GERAIS",
    "anual": 5.62,
    "JAN": 5.29,
    "FEB": 5.83,
    "MAR": 5.32,
    "APR": 5.72,
    "MAY": 5.6,
    "JUN": 5.51,
    "JUL": 5.78,
    "AUG": 6.48,
    "SEP": 6.06,
    "OCT": 5.65,
    "NOV": 5.06,
    "DEC": 5.17
  },
  {
    "LONG": -45.23,
    "LAT": -18.2,
    "CIDADE": "Três Marias",
    "UF": "MINAS GERAIS",
    "anual": 5.71,
    "JAN": 5.47,
    "FEB": 6.01,
    "MAR": 5.6,
    "APR": 5.86,
    "MAY": 5.63,
    "JUN": 5.46,
    "JUL": 5.83,
    "AUG": 6.29,
    "SEP": 6.16,
    "OCT": 5.74,
    "NOV": 5.13,
    "DEC": 5.29
  },
  {
    "LONG": -43.6,
    "LAT": -18.24,
    "CIDADE": "Diamantina",
    "UF": "MINAS GERAIS",
    "anual": 5.31,
    "JAN": 5.32,
    "FEB": 5.85,
    "MAR": 5.2,
    "APR": 5.15,
    "MAY": 4.97,
    "JUN": 5,
    "JUL": 5.26,
    "AUG": 5.93,
    "SEP": 5.88,
    "OCT": 5.42,
    "NOV": 4.67,
    "DEC": 5.06
  },
  {
    "LONG": "#VALOR!",
    "LAT": -1.82,
    "CIDADE": "Coluna",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.34,
    "FEB": 5.85,
    "MAR": 5.21,
    "APR": 4.95,
    "MAY": 4.59,
    "JUN": 4.54,
    "JUL": 4.63,
    "AUG": 5.34,
    "SEP": 5.46,
    "OCT": 5.22,
    "NOV": 4.48,
    "DEC": 5.05
  },
  {
    "LONG": -4.28,
    "LAT": -1.82,
    "CIDADE": "Frei Lagonegro",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.3,
    "FEB": 5.84,
    "MAR": 5.22,
    "APR": 4.92,
    "MAY": 4.53,
    "JUN": 4.48,
    "JUL": 4.53,
    "AUG": 5.25,
    "SEP": 5.37,
    "OCT": 5.19,
    "NOV": 4.47,
    "DEC": 5.03
  },
  {
    "LONG": -4.25,
    "LAT": "#VALOR!",
    "CIDADE": "José Raydan",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.43,
    "FEB": 5.97,
    "MAR": 5.37,
    "APR": 5.02,
    "MAY": 4.6,
    "JUN": 4.48,
    "JUL": 4.55,
    "AUG": 5.18,
    "SEP": 5.34,
    "OCT": 5.18,
    "NOV": 4.59,
    "DEC": 5.17
  },
  {
    "LONG": -42.42,
    "LAT": -18.19,
    "CIDADE": "Santa Maria do Suaçuí",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.43,
    "FEB": 5.97,
    "MAR": 5.37,
    "APR": 5.02,
    "MAY": 4.6,
    "JUN": 4.48,
    "JUL": 4.55,
    "AUG": 5.18,
    "SEP": 5.34,
    "OCT": 5.18,
    "NOV": 4.59,
    "DEC": 5.17
  },
  {
    "LONG": -41.74,
    "LAT": -18.24,
    "CIDADE": "Campanário",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.52,
    "FEB": 5.98,
    "MAR": 5.52,
    "APR": 5.11,
    "MAY": 4.55,
    "JUN": 4.42,
    "JUL": 4.44,
    "AUG": 4.97,
    "SEP": 5.2,
    "OCT": 5.11,
    "NOV": 4.63,
    "DEC": 5.24
  },
  {
    "LONG": -44.27,
    "LAT": -18.1,
    "CIDADE": "Augusto de Lima",
    "UF": "MINAS GERAIS",
    "anual": 5.7,
    "JAN": 5.58,
    "FEB": 6.18,
    "MAR": 5.64,
    "APR": 5.81,
    "MAY": 5.6,
    "JUN": 5.37,
    "JUL": 5.82,
    "AUG": 6.17,
    "SEP": 6.22,
    "OCT": 5.81,
    "NOV": 4.98,
    "DEC": 5.23
  },
  {
    "LONG": -43.47,
    "LAT": -18.07,
    "CIDADE": "Couto de Magalhães de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.41,
    "FEB": 5.92,
    "MAR": 5.25,
    "APR": 5.2,
    "MAY": 4.89,
    "JUN": 4.91,
    "JUL": 5.16,
    "AUG": 5.77,
    "SEP": 5.81,
    "OCT": 5.36,
    "NOV": 4.6,
    "DEC": 5.11
  },
  {
    "LONG": -43.24,
    "LAT": -1.81,
    "CIDADE": "Felício dos Santos",
    "UF": "MINAS GERAIS",
    "anual": 5.2,
    "JAN": 5.29,
    "FEB": 5.87,
    "MAR": 5.23,
    "APR": 5.09,
    "MAY": 4.8,
    "JUN": 4.8,
    "JUL": 4.98,
    "AUG": 5.61,
    "SEP": 5.69,
    "OCT": 5.29,
    "NOV": 4.6,
    "DEC": 5.09
  },
  {
    "LONG": -42.57,
    "LAT": -18.09,
    "CIDADE": "São Sebastião do Maranhão",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.4,
    "FEB": 5.93,
    "MAR": 5.31,
    "APR": 5.02,
    "MAY": 4.59,
    "JUN": 4.49,
    "JUL": 4.56,
    "AUG": 5.24,
    "SEP": 5.38,
    "OCT": 5.17,
    "NOV": 4.52,
    "DEC": 5.1
  },
  {
    "LONG": -41.43,
    "LAT": -18.07,
    "CIDADE": "Frei Gaspar",
    "UF": "MINAS GERAIS",
    "anual": 4.94,
    "JAN": 5.46,
    "FEB": 5.83,
    "MAR": 5.35,
    "APR": 5.01,
    "MAY": 4.39,
    "JUN": 4.28,
    "JUL": 4.32,
    "AUG": 4.86,
    "SEP": 5.07,
    "OCT": 4.98,
    "NOV": 4.56,
    "DEC": 5.18
  },
  {
    "LONG": -41.27,
    "LAT": -18.07,
    "CIDADE": "Ouro Verde de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.45,
    "FEB": 5.89,
    "MAR": 5.38,
    "APR": 5,
    "MAY": 4.41,
    "JUN": 4.29,
    "JUL": 4.3,
    "AUG": 4.82,
    "SEP": 5.09,
    "OCT": 5.02,
    "NOV": 4.61,
    "DEC": 5.2
  },
  {
    "LONG": -46.9,
    "LAT": -17.99,
    "CIDADE": "Vazante",
    "UF": "MINAS GERAIS",
    "anual": 5.6,
    "JAN": 5.32,
    "FEB": 5.79,
    "MAR": 5.29,
    "APR": 5.7,
    "MAY": 5.6,
    "JUN": 5.46,
    "JUL": 5.83,
    "AUG": 6.44,
    "SEP": 6.03,
    "OCT": 5.66,
    "NOV": 5.01,
    "DEC": 5.14
  },
  {
    "LONG": -43.38,
    "LAT": -18,
    "CIDADE": "São Gonçalo do Rio Preto",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.4,
    "FEB": 5.94,
    "MAR": 5.25,
    "APR": 5.1,
    "MAY": 4.79,
    "JUN": 4.82,
    "JUL": 4.94,
    "AUG": 5.66,
    "SEP": 5.75,
    "OCT": 5.33,
    "NOV": 4.62,
    "DEC": 5.14
  },
  {
    "LONG": -4.24,
    "LAT": -17.99,
    "CIDADE": "Água Boa",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.51,
    "FEB": 6.01,
    "MAR": 5.39,
    "APR": 5.08,
    "MAY": 4.62,
    "JUN": 4.51,
    "JUL": 4.64,
    "AUG": 5.23,
    "SEP": 5.39,
    "OCT": 5.25,
    "NOV": 4.6,
    "DEC": 5.21
  },
  {
    "LONG": -4.2,
    "LAT": "#VALOR!",
    "CIDADE": "Franciscópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.5,
    "FEB": 5.9,
    "MAR": 5.42,
    "APR": 5.03,
    "MAY": 4.56,
    "JUN": 4.42,
    "JUL": 4.51,
    "AUG": 5.08,
    "SEP": 5.31,
    "OCT": 5.26,
    "NOV": 4.66,
    "DEC": 5.26
  },
  {
    "LONG": -41.68,
    "LAT": -18.04,
    "CIDADE": "Itambacuri",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.4,
    "FEB": 5.92,
    "MAR": 5.35,
    "APR": 4.97,
    "MAY": 4.38,
    "JUN": 4.26,
    "JUL": 4.34,
    "AUG": 4.96,
    "SEP": 5.13,
    "OCT": 5.09,
    "NOV": 4.59,
    "DEC": 5.18
  },
  {
    "LONG": -41.12,
    "LAT": -18.04,
    "CIDADE": "Ataléia",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.47,
    "FEB": 5.91,
    "MAR": 5.37,
    "APR": 4.97,
    "MAY": 4.43,
    "JUN": 4.19,
    "JUL": 4.26,
    "AUG": 4.72,
    "SEP": 4.98,
    "OCT": 4.93,
    "NOV": 4.59,
    "DEC": 5.2
  },
  {
    "LONG": -4.46,
    "LAT": -1.79,
    "CIDADE": "Lassance",
    "UF": "MINAS GERAIS",
    "anual": 5.74,
    "JAN": 5.53,
    "FEB": 6.14,
    "MAR": 5.67,
    "APR": 5.92,
    "MAY": 5.69,
    "JUN": 5.47,
    "JUL": 5.85,
    "AUG": 6.21,
    "SEP": 6.28,
    "OCT": 5.75,
    "NOV": 5.06,
    "DEC": 5.34
  },
  {
    "LONG": -44.18,
    "LAT": -17.87,
    "CIDADE": "Buenópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.56,
    "FEB": 6.14,
    "MAR": 5.49,
    "APR": 5.68,
    "MAY": 5.48,
    "JUN": 5.39,
    "JUL": 5.73,
    "AUG": 6.21,
    "SEP": 6.23,
    "OCT": 5.73,
    "NOV": 4.88,
    "DEC": 5.28
  },
  {
    "LONG": -4.32,
    "LAT": -1.79,
    "CIDADE": "Senador Modestino Gonçalves",
    "UF": "MINAS GERAIS",
    "anual": 5.21,
    "JAN": 5.35,
    "FEB": 5.84,
    "MAR": 5.29,
    "APR": 5.05,
    "MAY": 4.76,
    "JUN": 4.81,
    "JUL": 4.94,
    "AUG": 5.66,
    "SEP": 5.74,
    "OCT": 5.32,
    "NOV": 4.65,
    "DEC": 5.17
  },
  {
    "LONG": -42.86,
    "LAT": -17.86,
    "CIDADE": "Itamarandiba",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.32,
    "FEB": 5.84,
    "MAR": 5.17,
    "APR": 4.96,
    "MAY": 4.61,
    "JUN": 4.62,
    "JUL": 4.73,
    "AUG": 5.48,
    "SEP": 5.58,
    "OCT": 5.25,
    "NOV": 4.58,
    "DEC": 5.08
  },
  {
    "LONG": -4.26,
    "LAT": -1.79,
    "CIDADE": "Aricanduva",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.32,
    "FEB": 5.87,
    "MAR": 5.23,
    "APR": 4.91,
    "MAY": 4.5,
    "JUN": 4.49,
    "JUL": 4.64,
    "AUG": 5.31,
    "SEP": 5.47,
    "OCT": 5.2,
    "NOV": 4.51,
    "DEC": 5.09
  },
  {
    "LONG": -41.51,
    "LAT": "#VALOR!",
    "CIDADE": "Teófilo Otoni",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.56,
    "FEB": 5.96,
    "MAR": 5.44,
    "APR": 5.01,
    "MAY": 4.4,
    "JUN": 4.22,
    "JUL": 4.3,
    "AUG": 4.88,
    "SEP": 5.07,
    "OCT": 5.05,
    "NOV": 4.62,
    "DEC": 5.25
  },
  {
    "LONG": -47.1,
    "LAT": -17.77,
    "CIDADE": "Guarda-Mor",
    "UF": "MINAS GERAIS",
    "anual": 5.6,
    "JAN": 5.25,
    "FEB": 5.85,
    "MAR": 5.29,
    "APR": 5.77,
    "MAY": 5.62,
    "JUN": 5.49,
    "JUL": 5.75,
    "AUG": 6.48,
    "SEP": 5.98,
    "OCT": 5.66,
    "NOV": 5.02,
    "DEC": 5.1
  },
  {
    "LONG": -46.52,
    "LAT": -17.84,
    "CIDADE": "Lagoa Grande",
    "UF": "MINAS GERAIS",
    "anual": 5.72,
    "JAN": 5.48,
    "FEB": 6.02,
    "MAR": 5.53,
    "APR": 5.82,
    "MAY": 5.64,
    "JUN": 5.55,
    "JUL": 5.85,
    "AUG": 6.4,
    "SEP": 6.12,
    "OCT": 5.77,
    "NOV": 5.12,
    "DEC": 5.31
  },
  {
    "LONG": -4.42,
    "LAT": -1.78,
    "CIDADE": "Joaquim Felício",
    "UF": "MINAS GERAIS",
    "anual": 5.64,
    "JAN": 5.48,
    "FEB": 6.11,
    "MAR": 5.44,
    "APR": 5.62,
    "MAY": 5.46,
    "JUN": 5.42,
    "JUL": 5.76,
    "AUG": 6.3,
    "SEP": 6.25,
    "OCT": 5.72,
    "NOV": 4.88,
    "DEC": 5.27
  },
  {
    "LONG": -42.08,
    "LAT": -17.85,
    "CIDADE": "Malacacheta",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.28,
    "FEB": 5.78,
    "MAR": 5.23,
    "APR": 4.8,
    "MAY": 4.38,
    "JUN": 4.37,
    "JUL": 4.52,
    "AUG": 5.09,
    "SEP": 5.32,
    "OCT": 5.18,
    "NOV": 4.49,
    "DEC": 5.06
  },
  {
    "LONG": -41.79,
    "LAT": -17.81,
    "CIDADE": "Poté",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.5,
    "FEB": 5.96,
    "MAR": 5.45,
    "APR": 4.97,
    "MAY": 4.44,
    "JUN": 4.26,
    "JUL": 4.34,
    "AUG": 4.97,
    "SEP": 5.16,
    "OCT": 5.17,
    "NOV": 4.65,
    "DEC": 5.28
  },
  {
    "LONG": -40.35,
    "LAT": -17.85,
    "CIDADE": "Nanuque",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.57,
    "FEB": 5.9,
    "MAR": 5.51,
    "APR": 5.07,
    "MAY": 4.43,
    "JUN": 4.19,
    "JUL": 4.25,
    "AUG": 4.8,
    "SEP": 5.06,
    "OCT": 5.05,
    "NOV": 4.81,
    "DEC": 5.46
  },
  {
    "LONG": -40.25,
    "LAT": -17.79,
    "CIDADE": "Serra dos Aimorés",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.6,
    "FEB": 5.88,
    "MAR": 5.48,
    "APR": 5.06,
    "MAY": 4.42,
    "JUN": 4.13,
    "JUL": 4.22,
    "AUG": 4.81,
    "SEP": 5.05,
    "OCT": 5.06,
    "NOV": 4.77,
    "DEC": 5.47
  },
  {
    "LONG": -4.62,
    "LAT": -17.74,
    "CIDADE": "João Pinheiro",
    "UF": "MINAS GERAIS",
    "anual": 5.72,
    "JAN": 5.55,
    "FEB": 6.02,
    "MAR": 5.54,
    "APR": 5.77,
    "MAY": 5.64,
    "JUN": 5.53,
    "JUL": 5.83,
    "AUG": 6.45,
    "SEP": 6.11,
    "OCT": 5.68,
    "NOV": 5.14,
    "DEC": 5.33
  },
  {
    "LONG": -42.52,
    "LAT": -17.69,
    "CIDADE": "Capelinha",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 5.34,
    "FEB": 5.83,
    "MAR": 5.24,
    "APR": 4.9,
    "MAY": 4.6,
    "JUN": 4.55,
    "JUL": 4.68,
    "AUG": 5.37,
    "SEP": 5.56,
    "OCT": 5.27,
    "NOV": 4.57,
    "DEC": 5.19
  },
  {
    "LONG": -4.23,
    "LAT": -1.77,
    "CIDADE": "Angelândia",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.33,
    "FEB": 5.83,
    "MAR": 5.12,
    "APR": 4.82,
    "MAY": 4.46,
    "JUN": 4.42,
    "JUL": 4.62,
    "AUG": 5.22,
    "SEP": 5.45,
    "OCT": 5.22,
    "NOV": 4.54,
    "DEC": 5.08
  },
  {
    "LONG": -40.77,
    "LAT": -17.7,
    "CIDADE": "Carlos Chagas",
    "UF": "MINAS GERAIS",
    "anual": 5,
    "JAN": 5.58,
    "FEB": 5.97,
    "MAR": 5.47,
    "APR": 5.08,
    "MAY": 4.42,
    "JUN": 4.18,
    "JUL": 4.21,
    "AUG": 4.77,
    "SEP": 5.09,
    "OCT": 5.05,
    "NOV": 4.8,
    "DEC": 5.43
  },
  {
    "LONG": -4.47,
    "LAT": -1.76,
    "CIDADE": "Várzea da Palma",
    "UF": "MINAS GERAIS",
    "anual": 5.79,
    "JAN": 5.64,
    "FEB": 6.18,
    "MAR": 5.67,
    "APR": 5.93,
    "MAY": 5.63,
    "JUN": 5.46,
    "JUL": 5.8,
    "AUG": 6.32,
    "SEP": 6.34,
    "OCT": 5.87,
    "NOV": 5.22,
    "DEC": 5.39
  },
  {
    "LONG": -4.22,
    "LAT": -17.6,
    "CIDADE": "Setubinha",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.46,
    "FEB": 5.87,
    "MAR": 5.27,
    "APR": 4.88,
    "MAY": 4.45,
    "JUN": 4.37,
    "JUL": 4.55,
    "AUG": 5.17,
    "SEP": 5.36,
    "OCT": 5.23,
    "NOV": 4.58,
    "DEC": 5.19
  },
  {
    "LONG": -41.75,
    "LAT": -17.63,
    "CIDADE": "Ladainha",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.43,
    "FEB": 5.95,
    "MAR": 5.34,
    "APR": 4.99,
    "MAY": 4.48,
    "JUN": 4.26,
    "JUL": 4.4,
    "AUG": 5,
    "SEP": 5.22,
    "OCT": 5.22,
    "NOV": 4.62,
    "DEC": 5.19
  },
  {
    "LONG": -4.3,
    "LAT": -1.75,
    "CIDADE": "Carbonita",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.48,
    "FEB": 6.02,
    "MAR": 5.3,
    "APR": 5.07,
    "MAY": 4.75,
    "JUN": 4.79,
    "JUL": 4.96,
    "AUG": 5.63,
    "SEP": 5.72,
    "OCT": 5.45,
    "NOV": 4.73,
    "DEC": 5.22
  },
  {
    "LONG": -4.19,
    "LAT": -17.47,
    "CIDADE": "Novo Cruzeiro",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.3,
    "FEB": 5.83,
    "MAR": 5.19,
    "APR": 4.92,
    "MAY": 4.43,
    "JUN": 4.28,
    "JUL": 4.45,
    "AUG": 5.06,
    "SEP": 5.29,
    "OCT": 5.2,
    "NOV": 4.59,
    "DEC": 5.16
  },
  {
    "LONG": -4.5,
    "LAT": -1.74,
    "CIDADE": "Buritizeiro",
    "UF": "MINAS GERAIS",
    "anual": 5.82,
    "JAN": 5.72,
    "FEB": 6.25,
    "MAR": 5.69,
    "APR": 6.02,
    "MAY": 5.68,
    "JUN": 5.43,
    "JUL": 5.85,
    "AUG": 6.36,
    "SEP": 6.36,
    "OCT": 5.91,
    "NOV": 5.22,
    "DEC": 5.39
  },
  {
    "LONG": -43.57,
    "LAT": -17.4,
    "CIDADE": "Olhos-d'Água",
    "UF": "MINAS GERAIS",
    "anual": 5.49,
    "JAN": 5.53,
    "FEB": 6.09,
    "MAR": 5.48,
    "APR": 5.34,
    "MAY": 5.15,
    "JUN": 5.1,
    "JUL": 5.25,
    "AUG": 6.02,
    "SEP": 6.06,
    "OCT": 5.62,
    "NOV": 4.88,
    "DEC": 5.35
  },
  {
    "LONG": -42.73,
    "LAT": -17.4,
    "CIDADE": "Veredinha",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.51,
    "FEB": 6,
    "MAR": 5.35,
    "APR": 5.05,
    "MAY": 4.66,
    "JUN": 4.67,
    "JUL": 4.83,
    "AUG": 5.49,
    "SEP": 5.62,
    "OCT": 5.39,
    "NOV": 4.76,
    "DEC": 5.25
  },
  {
    "LONG": "#VALOR!",
    "LAT": -17.4,
    "CIDADE": "Itaipé",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.45,
    "FEB": 5.99,
    "MAR": 5.32,
    "APR": 4.84,
    "MAY": 4.39,
    "JUN": 4.21,
    "JUL": 4.31,
    "AUG": 4.99,
    "SEP": 5.2,
    "OCT": 5.19,
    "NOV": 4.64,
    "DEC": 5.19
  },
  {
    "LONG": -41.22,
    "LAT": -17.41,
    "CIDADE": "Novo Oriente de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.09,
    "JAN": 5.69,
    "FEB": 6.19,
    "MAR": 5.57,
    "APR": 5.12,
    "MAY": 4.53,
    "JUN": 4.15,
    "JUL": 4.32,
    "AUG": 4.96,
    "SEP": 5.2,
    "OCT": 5.16,
    "NOV": 4.78,
    "DEC": 5.44
  },
  {
    "LONG": -41,
    "LAT": -17.43,
    "CIDADE": "Pavão",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.6,
    "FEB": 6.05,
    "MAR": 5.5,
    "APR": 5.04,
    "MAY": 4.43,
    "JUN": 4.1,
    "JUL": 4.24,
    "AUG": 4.86,
    "SEP": 5.14,
    "OCT": 5.08,
    "NOV": 4.8,
    "DEC": 5.46
  },
  {
    "LONG": -44.93,
    "LAT": -17.34,
    "CIDADE": "Pirapora",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.75,
    "FEB": 6.26,
    "MAR": 5.68,
    "APR": 6.03,
    "MAY": 5.72,
    "JUN": 5.48,
    "JUL": 5.89,
    "AUG": 6.44,
    "SEP": 6.33,
    "OCT": 5.9,
    "NOV": 5.18,
    "DEC": 5.39
  },
  {
    "LONG": -44.23,
    "LAT": -17.31,
    "CIDADE": "Francisco Dumont",
    "UF": "MINAS GERAIS",
    "anual": 5.81,
    "JAN": 5.75,
    "FEB": 6.32,
    "MAR": 5.81,
    "APR": 5.92,
    "MAY": 5.67,
    "JUN": 5.44,
    "JUL": 5.79,
    "AUG": 6.28,
    "SEP": 6.29,
    "OCT": 5.87,
    "NOV": 5.17,
    "DEC": 5.46
  },
  {
    "LONG": -43.95,
    "LAT": -17.28,
    "CIDADE": "Engenheiro Navarro",
    "UF": "MINAS GERAIS",
    "anual": 5.74,
    "JAN": 5.68,
    "FEB": 6.26,
    "MAR": 5.67,
    "APR": 5.78,
    "MAY": 5.53,
    "JUN": 5.36,
    "JUL": 5.69,
    "AUG": 6.29,
    "SEP": 6.3,
    "OCT": 5.82,
    "NOV": 5.08,
    "DEC": 5.44
  },
  {
    "LONG": -42.73,
    "LAT": -17.28,
    "CIDADE": "Turmalina",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.55,
    "FEB": 6.07,
    "MAR": 5.44,
    "APR": 5.09,
    "MAY": 4.68,
    "JUN": 4.68,
    "JUL": 4.84,
    "AUG": 5.51,
    "SEP": 5.65,
    "OCT": 5.44,
    "NOV": 4.82,
    "DEC": 5.28
  },
  {
    "LONG": -4.15,
    "LAT": -17.3,
    "CIDADE": "Catuji",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.45,
    "FEB": 5.93,
    "MAR": 5.31,
    "APR": 4.83,
    "MAY": 4.36,
    "JUN": 4.14,
    "JUL": 4.25,
    "AUG": 4.95,
    "SEP": 5.16,
    "OCT": 5.15,
    "NOV": 4.63,
    "DEC": 5.2
  },
  {
    "LONG": -40.58,
    "LAT": -17.26,
    "CIDADE": "Umburatiba",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.57,
    "FEB": 6.01,
    "MAR": 5.54,
    "APR": 5.08,
    "MAY": 4.4,
    "JUN": 4.09,
    "JUL": 4.25,
    "AUG": 4.82,
    "SEP": 5.22,
    "OCT": 5.17,
    "NOV": 4.88,
    "DEC": 5.58
  },
  {
    "LONG": -46.87,
    "LAT": -17.23,
    "CIDADE": "Paracatu",
    "UF": "MINAS GERAIS",
    "anual": 5.65,
    "JAN": 5.38,
    "FEB": 5.92,
    "MAR": 5.41,
    "APR": 5.81,
    "MAY": 5.62,
    "JUN": 5.5,
    "JUL": 5.88,
    "AUG": 6.43,
    "SEP": 5.95,
    "OCT": 5.69,
    "NOV": 5.05,
    "DEC": 5.21
  },
  {
    "LONG": -44.44,
    "LAT": -17.23,
    "CIDADE": "Jequitaí",
    "UF": "MINAS GERAIS",
    "anual": 5.82,
    "JAN": 5.75,
    "FEB": 6.3,
    "MAR": 5.79,
    "APR": 6.04,
    "MAY": 5.75,
    "JUN": 5.47,
    "JUL": 5.78,
    "AUG": 6.23,
    "SEP": 6.25,
    "OCT": 5.9,
    "NOV": 5.11,
    "DEC": 5.46
  },
  {
    "LONG": -42.59,
    "LAT": -17.22,
    "CIDADE": "Minas Novas",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.55,
    "FEB": 6.06,
    "MAR": 5.44,
    "APR": 5.06,
    "MAY": 4.64,
    "JUN": 4.6,
    "JUL": 4.78,
    "AUG": 5.47,
    "SEP": 5.61,
    "OCT": 5.41,
    "NOV": 4.81,
    "DEC": 5.31
  },
  {
    "LONG": -41.7,
    "LAT": -17.19,
    "CIDADE": "Caraí",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.43,
    "FEB": 5.98,
    "MAR": 5.35,
    "APR": 4.92,
    "MAY": 4.49,
    "JUN": 4.29,
    "JUL": 4.46,
    "AUG": 5.19,
    "SEP": 5.4,
    "OCT": 5.32,
    "NOV": 4.74,
    "DEC": 5.26
  },
  {
    "LONG": -40.92,
    "LAT": -17.24,
    "CIDADE": "Crisólita",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.64,
    "FEB": 6.12,
    "MAR": 5.54,
    "APR": 5.1,
    "MAY": 4.45,
    "JUN": 4.15,
    "JUL": 4.3,
    "AUG": 4.85,
    "SEP": 5.23,
    "OCT": 5.15,
    "NOV": 4.84,
    "DEC": 5.54
  },
  {
    "LONG": -4.42,
    "LAT": "#VALOR!",
    "CIDADE": "Claro dos Poções",
    "UF": "MINAS GERAIS",
    "anual": 5.76,
    "JAN": 5.7,
    "FEB": 6.2,
    "MAR": 5.71,
    "APR": 5.88,
    "MAY": 5.62,
    "JUN": 5.44,
    "JUL": 5.77,
    "AUG": 6.35,
    "SEP": 6.3,
    "OCT": 5.76,
    "NOV": 5.01,
    "DEC": 5.38
  },
  {
    "LONG": -43.81,
    "LAT": -1.71,
    "CIDADE": "Bocaiúva",
    "UF": "MINAS GERAIS",
    "anual": 5.72,
    "JAN": 5.65,
    "FEB": 6.2,
    "MAR": 5.62,
    "APR": 5.77,
    "MAY": 5.48,
    "JUN": 5.33,
    "JUL": 5.74,
    "AUG": 6.29,
    "SEP": 6.3,
    "OCT": 5.82,
    "NOV": 5.07,
    "DEC": 5.44
  },
  {
    "LONG": -43.31,
    "LAT": -17.06,
    "CIDADE": "Itacambira",
    "UF": "MINAS GERAIS",
    "anual": 5.57,
    "JAN": 5.62,
    "FEB": 6.17,
    "MAR": 5.43,
    "APR": 5.32,
    "MAY": 5.22,
    "JUN": 5.21,
    "JUL": 5.41,
    "AUG": 6.16,
    "SEP": 6.2,
    "OCT": 5.84,
    "NOV": 4.9,
    "DEC": 5.37
  },
  {
    "LONG": -42.69,
    "LAT": -17.08,
    "CIDADE": "Leme do Prado",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.55,
    "FEB": 6.15,
    "MAR": 5.48,
    "APR": 5.12,
    "MAY": 4.72,
    "JUN": 4.66,
    "JUL": 4.85,
    "AUG": 5.55,
    "SEP": 5.67,
    "OCT": 5.47,
    "NOV": 4.85,
    "DEC": 5.3
  },
  {
    "LONG": -42.54,
    "LAT": -17.09,
    "CIDADE": "Chapada do Norte",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.62,
    "FEB": 6.13,
    "MAR": 5.48,
    "APR": 5.08,
    "MAY": 4.67,
    "JUN": 4.61,
    "JUL": 4.78,
    "AUG": 5.48,
    "SEP": 5.65,
    "OCT": 5.47,
    "NOV": 4.86,
    "DEC": 5.36
  },
  {
    "LONG": -42.26,
    "LAT": -17.08,
    "CIDADE": "Jenipapo de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.71,
    "FEB": 6.16,
    "MAR": 5.54,
    "APR": 5.14,
    "MAY": 4.69,
    "JUN": 4.57,
    "JUL": 4.77,
    "AUG": 5.43,
    "SEP": 5.65,
    "OCT": 5.48,
    "NOV": 4.93,
    "DEC": 5.46
  },
  {
    "LONG": -41.48,
    "LAT": -17.08,
    "CIDADE": "Padre Paraíso",
    "UF": "MINAS GERAIS",
    "anual": 4.98,
    "JAN": 5.48,
    "FEB": 5.97,
    "MAR": 5.34,
    "APR": 4.89,
    "MAY": 4.38,
    "JUN": 4.15,
    "JUL": 4.29,
    "AUG": 4.97,
    "SEP": 5.25,
    "OCT": 5.19,
    "NOV": 4.64,
    "DEC": 5.2
  },
  {
    "LONG": -40.94,
    "LAT": -17.08,
    "CIDADE": "Águas Formosas",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.64,
    "FEB": 6.16,
    "MAR": 5.56,
    "APR": 5.13,
    "MAY": 4.48,
    "JUN": 4.15,
    "JUL": 4.32,
    "AUG": 4.9,
    "SEP": 5.28,
    "OCT": 5.18,
    "NOV": 4.84,
    "DEC": 5.54
  },
  {
    "LONG": -40.72,
    "LAT": -17.07,
    "CIDADE": "Machacalis",
    "UF": "MINAS GERAIS",
    "anual": 5.08,
    "JAN": 5.57,
    "FEB": 6.07,
    "MAR": 5.55,
    "APR": 5.09,
    "MAY": 4.42,
    "JUN": 4.11,
    "JUL": 4.29,
    "AUG": 4.87,
    "SEP": 5.3,
    "OCT": 5.19,
    "NOV": 4.89,
    "DEC": 5.58
  },
  {
    "LONG": -40.58,
    "LAT": -17.06,
    "CIDADE": "Bertópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.06,
    "JAN": 5.57,
    "FEB": 5.97,
    "MAR": 5.54,
    "APR": 5.08,
    "MAY": 4.39,
    "JUN": 4.09,
    "JUL": 4.28,
    "AUG": 4.86,
    "SEP": 5.26,
    "OCT": 5.16,
    "NOV": 4.92,
    "DEC": 5.55
  },
  {
    "LONG": -46.01,
    "LAT": -17,
    "CIDADE": "Brasilândia de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.78,
    "JAN": 5.64,
    "FEB": 6.09,
    "MAR": 5.58,
    "APR": 5.97,
    "MAY": 5.77,
    "JUN": 5.59,
    "JUL": 5.94,
    "AUG": 6.49,
    "SEP": 6.08,
    "OCT": 5.78,
    "NOV": 5.09,
    "DEC": 5.36
  },
  {
    "LONG": -44.58,
    "LAT": -16.98,
    "CIDADE": "Lagoa dos Patos",
    "UF": "MINAS GERAIS",
    "anual": 5.75,
    "JAN": 5.65,
    "FEB": 6.17,
    "MAR": 5.61,
    "APR": 5.92,
    "MAY": 5.66,
    "JUN": 5.44,
    "JUL": 5.78,
    "AUG": 6.35,
    "SEP": 6.26,
    "OCT": 5.76,
    "NOV": 5.05,
    "DEC": 5.35
  },
  {
    "LONG": -4.37,
    "LAT": -1.7,
    "CIDADE": "Guaraciama",
    "UF": "MINAS GERAIS",
    "anual": 5.67,
    "JAN": 5.62,
    "FEB": 6.14,
    "MAR": 5.56,
    "APR": 5.61,
    "MAY": 5.47,
    "JUN": 5.3,
    "JUL": 5.6,
    "AUG": 6.19,
    "SEP": 6.26,
    "OCT": 5.8,
    "NOV": 5.05,
    "DEC": 5.47
  },
  {
    "LONG": -42.46,
    "LAT": -16.95,
    "CIDADE": "Berilo",
    "UF": "MINAS GERAIS",
    "anual": 5.33,
    "JAN": 5.74,
    "FEB": 6.2,
    "MAR": 5.61,
    "APR": 5.2,
    "MAY": 4.72,
    "JUN": 4.64,
    "JUL": 4.81,
    "AUG": 5.47,
    "SEP": 5.69,
    "OCT": 5.55,
    "NOV": 4.92,
    "DEC": 5.44
  },
  {
    "LONG": -42.36,
    "LAT": -16.99,
    "CIDADE": "Francisco Badaró",
    "UF": "MINAS GERAIS",
    "anual": 5.34,
    "JAN": 5.75,
    "FEB": 6.17,
    "MAR": 5.63,
    "APR": 5.22,
    "MAY": 4.73,
    "JUN": 4.64,
    "JUL": 4.83,
    "AUG": 5.47,
    "SEP": 5.68,
    "OCT": 5.56,
    "NOV": 4.94,
    "DEC": 5.43
  },
  {
    "LONG": -44.91,
    "LAT": -16.86,
    "CIDADE": "Ibiaí",
    "UF": "MINAS GERAIS",
    "anual": 5.86,
    "JAN": 5.8,
    "FEB": 6.24,
    "MAR": 5.74,
    "APR": 6.08,
    "MAY": 5.73,
    "JUN": 5.57,
    "JUL": 5.82,
    "AUG": 6.45,
    "SEP": 6.3,
    "OCT": 5.95,
    "NOV": 5.19,
    "DEC": 5.43
  },
  {
    "LONG": -44.35,
    "LAT": -16.85,
    "CIDADE": "São João da Lagoa",
    "UF": "MINAS GERAIS",
    "anual": 5.74,
    "JAN": 5.6,
    "FEB": 6.13,
    "MAR": 5.58,
    "APR": 5.87,
    "MAY": 5.66,
    "JUN": 5.47,
    "JUL": 5.81,
    "AUG": 6.38,
    "SEP": 6.25,
    "OCT": 5.78,
    "NOV": 5.02,
    "DEC": 5.38
  },
  {
    "LONG": -43.01,
    "LAT": -16.87,
    "CIDADE": "Botumirim",
    "UF": "MINAS GERAIS",
    "anual": 5.29,
    "JAN": 5.42,
    "FEB": 6.01,
    "MAR": 5.29,
    "APR": 5.01,
    "MAY": 4.81,
    "JUN": 4.69,
    "JUL": 4.99,
    "AUG": 5.68,
    "SEP": 5.87,
    "OCT": 5.6,
    "NOV": 4.82,
    "DEC": 5.3
  },
  {
    "LONG": -42.6,
    "LAT": -16.91,
    "CIDADE": "José Gonçalves de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.55,
    "FEB": 6.08,
    "MAR": 5.37,
    "APR": 5.09,
    "MAY": 4.66,
    "JUN": 4.6,
    "JUL": 4.83,
    "AUG": 5.55,
    "SEP": 5.71,
    "OCT": 5.48,
    "NOV": 4.82,
    "DEC": 5.29
  },
  {
    "LONG": -42.06,
    "LAT": -16.85,
    "CIDADE": "Araçuaí",
    "UF": "MINAS GERAIS",
    "anual": 5.37,
    "JAN": 5.8,
    "FEB": 6.21,
    "MAR": 5.67,
    "APR": 5.29,
    "MAY": 4.78,
    "JUN": 4.62,
    "JUL": 4.79,
    "AUG": 5.39,
    "SEP": 5.7,
    "OCT": 5.58,
    "NOV": 5.08,
    "DEC": 5.58
  },
  {
    "LONG": -41.25,
    "LAT": -16.87,
    "CIDADE": "Monte Formoso",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.45,
    "FEB": 6.01,
    "MAR": 5.35,
    "APR": 4.91,
    "MAY": 4.37,
    "JUN": 4.13,
    "JUL": 4.32,
    "AUG": 4.9,
    "SEP": 5.31,
    "OCT": 5.2,
    "NOV": 4.75,
    "DEC": 5.37
  },
  {
    "LONG": -40.92,
    "LAT": -16.89,
    "CIDADE": "Fronteira dos Vales",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.56,
    "FEB": 6.05,
    "MAR": 5.49,
    "APR": 5.08,
    "MAY": 4.46,
    "JUN": 4.19,
    "JUL": 4.29,
    "AUG": 4.92,
    "SEP": 5.34,
    "OCT": 5.18,
    "NOV": 4.76,
    "DEC": 5.49
  },
  {
    "LONG": -40.68,
    "LAT": -16.94,
    "CIDADE": "Santa Helena de Minas",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.42,
    "FEB": 5.9,
    "MAR": 5.37,
    "APR": 4.95,
    "MAY": 4.31,
    "JUN": 4.05,
    "JUL": 4.26,
    "AUG": 4.82,
    "SEP": 5.27,
    "OCT": 5.14,
    "NOV": 4.74,
    "DEC": 5.44
  },
  {
    "LONG": -43.69,
    "LAT": -16.85,
    "CIDADE": "Glaucilândia",
    "UF": "MINAS GERAIS",
    "anual": 5.72,
    "JAN": 5.73,
    "FEB": 6.21,
    "MAR": 5.64,
    "APR": 5.74,
    "MAY": 5.51,
    "JUN": 5.33,
    "JUL": 5.6,
    "AUG": 6.22,
    "SEP": 6.28,
    "OCT": 5.81,
    "NOV": 5.05,
    "DEC": 5.52
  },
  {
    "LONG": -43.59,
    "LAT": -16.85,
    "CIDADE": "Juramento",
    "UF": "MINAS GERAIS",
    "anual": 5.71,
    "JAN": 5.74,
    "FEB": 6.19,
    "MAR": 5.62,
    "APR": 5.71,
    "MAY": 5.49,
    "JUN": 5.34,
    "JUL": 5.58,
    "AUG": 6.15,
    "SEP": 6.27,
    "OCT": 5.85,
    "NOV": 5.06,
    "DEC": 5.52
  },
  {
    "LONG": -42.34,
    "LAT": -16.81,
    "CIDADE": "Virgem da Lapa",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.58,
    "FEB": 5.97,
    "MAR": 5.43,
    "APR": 5.17,
    "MAY": 4.65,
    "JUN": 4.57,
    "JUL": 4.77,
    "AUG": 5.39,
    "SEP": 5.63,
    "OCT": 5.45,
    "NOV": 4.84,
    "DEC": 5.35
  },
  {
    "LONG": -46.27,
    "LAT": -16.65,
    "CIDADE": "Dom Bosco",
    "UF": "MINAS GERAIS",
    "anual": 5.72,
    "JAN": 5.55,
    "FEB": 6.01,
    "MAR": 5.53,
    "APR": 5.83,
    "MAY": 5.71,
    "JUN": 5.52,
    "JUL": 5.92,
    "AUG": 6.55,
    "SEP": 6.07,
    "OCT": 5.73,
    "NOV": 4.94,
    "DEC": 5.3
  },
  {
    "LONG": -45.41,
    "LAT": -16.69,
    "CIDADE": "Santa Fé de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.71,
    "FEB": 6.26,
    "MAR": 5.67,
    "APR": 6.04,
    "MAY": 5.79,
    "JUN": 5.59,
    "JUL": 5.89,
    "AUG": 6.55,
    "SEP": 6.2,
    "OCT": 5.94,
    "NOV": 5.1,
    "DEC": 5.38
  },
  {
    "LONG": -44.36,
    "LAT": -16.68,
    "CIDADE": "Coração de Jesus",
    "UF": "MINAS GERAIS",
    "anual": 5.8,
    "JAN": 5.67,
    "FEB": 6.24,
    "MAR": 5.65,
    "APR": 5.93,
    "MAY": 5.69,
    "JUN": 5.55,
    "JUL": 5.83,
    "AUG": 6.49,
    "SEP": 6.26,
    "OCT": 5.78,
    "NOV": 5.04,
    "DEC": 5.43
  },
  {
    "LONG": -43.86,
    "LAT": -16.73,
    "CIDADE": "Montes Claros",
    "UF": "MINAS GERAIS",
    "anual": 5.73,
    "JAN": 5.65,
    "FEB": 6.21,
    "MAR": 5.6,
    "APR": 5.78,
    "MAY": 5.49,
    "JUN": 5.37,
    "JUL": 5.68,
    "AUG": 6.31,
    "SEP": 6.29,
    "OCT": 5.81,
    "NOV": 5.05,
    "DEC": 5.5
  },
  {
    "LONG": -42.86,
    "LAT": -16.72,
    "CIDADE": "Cristália",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.56,
    "FEB": 6.08,
    "MAR": 5.42,
    "APR": 5.12,
    "MAY": 4.75,
    "JUN": 4.59,
    "JUL": 4.83,
    "AUG": 5.59,
    "SEP": 5.76,
    "OCT": 5.54,
    "NOV": 4.81,
    "DEC": 5.35
  },
  {
    "LONG": -41.51,
    "LAT": -16.75,
    "CIDADE": "Ponto dos Volantes",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 5.6,
    "FEB": 6.12,
    "MAR": 5.48,
    "APR": 5.06,
    "MAY": 4.58,
    "JUN": 4.23,
    "JUL": 4.45,
    "AUG": 5.06,
    "SEP": 5.5,
    "OCT": 5.37,
    "NOV": 4.95,
    "DEC": 5.5
  },
  {
    "LONG": -41.02,
    "LAT": -16.65,
    "CIDADE": "Joaíma",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.64,
    "FEB": 6.12,
    "MAR": 5.55,
    "APR": 5.15,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.29,
    "AUG": 4.8,
    "SEP": 5.32,
    "OCT": 5.2,
    "NOV": 4.89,
    "DEC": 5.5
  },
  {
    "LONG": -40.43,
    "LAT": -16.74,
    "CIDADE": "Palmópolis",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.39,
    "FEB": 5.75,
    "MAR": 5.37,
    "APR": 4.94,
    "MAY": 4.35,
    "JUN": 4.04,
    "JUL": 4.13,
    "AUG": 4.71,
    "SEP": 5.19,
    "OCT": 5.01,
    "NOV": 4.72,
    "DEC": 5.4
  },
  {
    "LONG": -45.99,
    "LAT": -1.66,
    "CIDADE": "Bonfinópolis de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.78,
    "JAN": 5.62,
    "FEB": 6.12,
    "MAR": 5.58,
    "APR": 5.91,
    "MAY": 5.66,
    "JUN": 5.6,
    "JUL": 5.92,
    "AUG": 6.61,
    "SEP": 6.08,
    "OCT": 5.84,
    "NOV": 5.04,
    "DEC": 5.33
  },
  {
    "LONG": -45.06,
    "LAT": -16.63,
    "CIDADE": "Ponto Chique",
    "UF": "MINAS GERAIS",
    "anual": 5.89,
    "JAN": 5.76,
    "FEB": 6.28,
    "MAR": 5.84,
    "APR": 6.08,
    "MAY": 5.8,
    "JUN": 5.66,
    "JUL": 5.88,
    "AUG": 6.44,
    "SEP": 6.25,
    "OCT": 5.94,
    "NOV": 5.21,
    "DEC": 5.51
  },
  {
    "LONG": -42.89,
    "LAT": -16.57,
    "CIDADE": "Grão Mogol",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.54,
    "FEB": 6.07,
    "MAR": 5.42,
    "APR": 5.12,
    "MAY": 4.77,
    "JUN": 4.62,
    "JUL": 4.88,
    "AUG": 5.63,
    "SEP": 5.78,
    "OCT": 5.55,
    "NOV": 4.83,
    "DEC": 5.36
  },
  {
    "LONG": -42.18,
    "LAT": -16.62,
    "CIDADE": "Coronel Murta",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.62,
    "FEB": 6.05,
    "MAR": 5.49,
    "APR": 5.18,
    "MAY": 4.71,
    "JUN": 4.48,
    "JUL": 4.71,
    "AUG": 5.31,
    "SEP": 5.68,
    "OCT": 5.49,
    "NOV": 4.9,
    "DEC": 5.45
  },
  {
    "LONG": -41.77,
    "LAT": -16.61,
    "CIDADE": "Itinga",
    "UF": "MINAS GERAIS",
    "anual": 5.39,
    "JAN": 5.83,
    "FEB": 6.33,
    "MAR": 5.72,
    "APR": 5.33,
    "MAY": 4.79,
    "JUN": 4.53,
    "JUL": 4.71,
    "AUG": 5.35,
    "SEP": 5.74,
    "OCT": 5.6,
    "NOV": 5.14,
    "DEC": 5.66
  },
  {
    "LONG": -4.15,
    "LAT": -16.56,
    "CIDADE": "Itaobim",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.75,
    "FEB": 6.31,
    "MAR": 5.67,
    "APR": 5.25,
    "MAY": 4.72,
    "JUN": 4.37,
    "JUL": 4.59,
    "AUG": 5.19,
    "SEP": 5.68,
    "OCT": 5.55,
    "NOV": 5.1,
    "DEC": 5.66
  },
  {
    "LONG": -40.76,
    "LAT": -16.64,
    "CIDADE": "Felisburgo",
    "UF": "MINAS GERAIS",
    "anual": 4.95,
    "JAN": 5.45,
    "FEB": 5.87,
    "MAR": 5.35,
    "APR": 4.97,
    "MAY": 4.39,
    "JUN": 4.07,
    "JUL": 4.17,
    "AUG": 4.69,
    "SEP": 5.24,
    "OCT": 5.07,
    "NOV": 4.75,
    "DEC": 5.41
  },
  {
    "LONG": -40.57,
    "LAT": -16.61,
    "CIDADE": "Rio do Prado",
    "UF": "MINAS GERAIS",
    "anual": 5.03,
    "JAN": 5.53,
    "FEB": 5.97,
    "MAR": 5.5,
    "APR": 5.12,
    "MAY": 4.5,
    "JUN": 4.14,
    "JUL": 4.2,
    "AUG": 4.73,
    "SEP": 5.24,
    "OCT": 5.05,
    "NOV": 4.84,
    "DEC": 5.5
  },
  {
    "LONG": -46.49,
    "LAT": -16.51,
    "CIDADE": "Natalândia",
    "UF": "MINAS GERAIS",
    "anual": 5.67,
    "JAN": 5.46,
    "FEB": 5.97,
    "MAR": 5.45,
    "APR": 5.74,
    "MAY": 5.64,
    "JUN": 5.55,
    "JUL": 5.89,
    "AUG": 6.54,
    "SEP": 6.04,
    "OCT": 5.68,
    "NOV": 4.91,
    "DEC": 5.19
  },
  {
    "LONG": -44.81,
    "LAT": -1.65,
    "CIDADE": "Campo Azul",
    "UF": "MINAS GERAIS",
    "anual": 5.81,
    "JAN": 5.81,
    "FEB": 6.15,
    "MAR": 5.69,
    "APR": 5.96,
    "MAY": 5.65,
    "JUN": 5.5,
    "JUL": 5.8,
    "AUG": 6.47,
    "SEP": 6.24,
    "OCT": 5.87,
    "NOV": 5.1,
    "DEC": 5.47
  },
  {
    "LONG": -44.53,
    "LAT": -16.53,
    "CIDADE": "São João do Pacuí",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.77,
    "FEB": 6.25,
    "MAR": 5.74,
    "APR": 6,
    "MAY": 5.72,
    "JUN": 5.55,
    "JUL": 5.89,
    "AUG": 6.5,
    "SEP": 6.24,
    "OCT": 5.85,
    "NOV": 5.03,
    "DEC": 5.5
  },
  {
    "LONG": "#VALOR!",
    "LAT": -16.48,
    "CIDADE": "Francisco Sá",
    "UF": "MINAS GERAIS",
    "anual": 5.78,
    "JAN": 5.77,
    "FEB": 6.32,
    "MAR": 5.76,
    "APR": 5.84,
    "MAY": 5.52,
    "JUN": 5.36,
    "JUL": 5.63,
    "AUG": 6.15,
    "SEP": 6.27,
    "OCT": 5.95,
    "NOV": 5.19,
    "DEC": 5.58
  },
  {
    "LONG": -42.52,
    "LAT": -16.54,
    "CIDADE": "Josenópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.24,
    "JAN": 5.55,
    "FEB": 6.05,
    "MAR": 5.42,
    "APR": 5.06,
    "MAY": 4.59,
    "JUN": 4.49,
    "JUL": 4.78,
    "AUG": 5.45,
    "SEP": 5.71,
    "OCT": 5.52,
    "NOV": 4.86,
    "DEC": 5.34
  },
  {
    "LONG": -40.18,
    "LAT": -16.53,
    "CIDADE": "Santo Antônio do Jacinto",
    "UF": "MINAS GERAIS",
    "anual": 4.92,
    "JAN": 5.33,
    "FEB": 5.74,
    "MAR": 5.34,
    "APR": 5.05,
    "MAY": 4.39,
    "JUN": 4.13,
    "JUL": 4.2,
    "AUG": 4.68,
    "SEP": 5.11,
    "OCT": 4.97,
    "NOV": 4.71,
    "DEC": 5.35
  },
  {
    "LONG": -46.9,
    "LAT": -16.36,
    "CIDADE": "Unaí",
    "UF": "MINAS GERAIS",
    "anual": 5.7,
    "JAN": 5.42,
    "FEB": 5.9,
    "MAR": 5.55,
    "APR": 5.79,
    "MAY": 5.64,
    "JUN": 5.59,
    "JUL": 5.87,
    "AUG": 6.56,
    "SEP": 6.06,
    "OCT": 5.71,
    "NOV": 5.02,
    "DEC": 5.26
  },
  {
    "LONG": -45.07,
    "LAT": -16.37,
    "CIDADE": "São Romão",
    "UF": "MINAS GERAIS",
    "anual": 5.89,
    "JAN": 5.78,
    "FEB": 6.27,
    "MAR": 5.78,
    "APR": 6.07,
    "MAY": 5.79,
    "JUN": 5.63,
    "JUL": 5.9,
    "AUG": 6.54,
    "SEP": 6.25,
    "OCT": 5.97,
    "NOV": 5.18,
    "DEC": 5.49
  },
  {
    "LONG": -42.52,
    "LAT": -16.36,
    "CIDADE": "Padre Carvalho",
    "UF": "MINAS GERAIS",
    "anual": 5.25,
    "JAN": 5.54,
    "FEB": 6.07,
    "MAR": 5.42,
    "APR": 5.09,
    "MAY": 4.62,
    "JUN": 4.49,
    "JUL": 4.76,
    "AUG": 5.46,
    "SEP": 5.77,
    "OCT": 5.55,
    "NOV": 4.84,
    "DEC": 5.38
  },
  {
    "LONG": -42.26,
    "LAT": -16.41,
    "CIDADE": "Rubelita",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.72,
    "FEB": 6.11,
    "MAR": 5.52,
    "APR": 5.17,
    "MAY": 4.69,
    "JUN": 4.48,
    "JUL": 4.74,
    "AUG": 5.4,
    "SEP": 5.77,
    "OCT": 5.53,
    "NOV": 4.97,
    "DEC": 5.55
  },
  {
    "LONG": -41.01,
    "LAT": -16.44,
    "CIDADE": "Jequitinhonha",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.59,
    "FEB": 5.99,
    "MAR": 5.53,
    "APR": 5.16,
    "MAY": 4.56,
    "JUN": 4.27,
    "JUL": 4.41,
    "AUG": 4.94,
    "SEP": 5.44,
    "OCT": 5.25,
    "NOV": 4.83,
    "DEC": 5.46
  },
  {
    "LONG": -40.54,
    "LAT": -1.64,
    "CIDADE": "Rubim",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.54,
    "FEB": 6,
    "MAR": 5.55,
    "APR": 5.21,
    "MAY": 4.55,
    "JUN": 4.16,
    "JUL": 4.27,
    "AUG": 4.72,
    "SEP": 5.19,
    "OCT": 5.05,
    "NOV": 4.87,
    "DEC": 5.51
  },
  {
    "LONG": -44.78,
    "LAT": -1.63,
    "CIDADE": "Ubaí",
    "UF": "MINAS GERAIS",
    "anual": 5.83,
    "JAN": 5.72,
    "FEB": 6.18,
    "MAR": 5.7,
    "APR": 6,
    "MAY": 5.71,
    "JUN": 5.54,
    "JUL": 5.85,
    "AUG": 6.53,
    "SEP": 6.22,
    "OCT": 5.86,
    "NOV": 5.1,
    "DEC": 5.52
  },
  {
    "LONG": -44.16,
    "LAT": -1.63,
    "CIDADE": "Mirabela",
    "UF": "MINAS GERAIS",
    "anual": 5.85,
    "JAN": 5.78,
    "FEB": 6.27,
    "MAR": 5.73,
    "APR": 6.01,
    "MAY": 5.69,
    "JUN": 5.53,
    "JUL": 5.88,
    "AUG": 6.55,
    "SEP": 6.35,
    "OCT": 5.85,
    "NOV": 5.03,
    "DEC": 5.53
  },
  {
    "LONG": -43.71,
    "LAT": -1.63,
    "CIDADE": "Capitão Enéas",
    "UF": "MINAS GERAIS",
    "anual": 5.82,
    "JAN": 5.81,
    "FEB": 6.32,
    "MAR": 5.81,
    "APR": 5.86,
    "MAY": 5.6,
    "JUN": 5.44,
    "JUL": 5.73,
    "AUG": 6.34,
    "SEP": 6.29,
    "OCT": 5.86,
    "NOV": 5.19,
    "DEC": 5.62
  },
  {
    "LONG": -41.79,
    "LAT": -16.3,
    "CIDADE": "Comercinho",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.59,
    "FEB": 6.08,
    "MAR": 5.56,
    "APR": 5.15,
    "MAY": 4.63,
    "JUN": 4.36,
    "JUL": 4.61,
    "AUG": 5.17,
    "SEP": 5.69,
    "OCT": 5.45,
    "NOV": 4.95,
    "DEC": 5.51
  },
  {
    "LONG": -46,
    "LAT": -16.22,
    "CIDADE": "Riachinho",
    "UF": "MINAS GERAIS",
    "anual": 5.85,
    "JAN": 5.71,
    "FEB": 6.16,
    "MAR": 5.67,
    "APR": 6.07,
    "MAY": 5.82,
    "JUN": 5.65,
    "JUL": 5.96,
    "AUG": 6.59,
    "SEP": 6.22,
    "OCT": 5.89,
    "NOV": 5.04,
    "DEC": 5.43
  },
  {
    "LONG": -44.9,
    "LAT": -16.22,
    "CIDADE": "Icaraí de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.87,
    "JAN": 5.78,
    "FEB": 6.22,
    "MAR": 5.79,
    "APR": 6.02,
    "MAY": 5.73,
    "JUN": 5.59,
    "JUL": 5.85,
    "AUG": 6.57,
    "SEP": 6.23,
    "OCT": 5.9,
    "NOV": 5.21,
    "DEC": 5.54
  },
  {
    "LONG": -4.44,
    "LAT": -16.21,
    "CIDADE": "Brasília de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.7,
    "FEB": 6.22,
    "MAR": 5.69,
    "APR": 6.02,
    "MAY": 5.76,
    "JUN": 5.59,
    "JUL": 5.89,
    "AUG": 6.57,
    "SEP": 6.2,
    "OCT": 5.82,
    "NOV": 5.05,
    "DEC": 5.5
  },
  {
    "LONG": -4.23,
    "LAT": -16.17,
    "CIDADE": "Salinas",
    "UF": "MINAS GERAIS",
    "anual": 5.3,
    "JAN": 5.65,
    "FEB": 6.08,
    "MAR": 5.55,
    "APR": 5.17,
    "MAY": 4.7,
    "JUN": 4.46,
    "JUL": 4.71,
    "AUG": 5.38,
    "SEP": 5.81,
    "OCT": 5.51,
    "NOV": 5.03,
    "DEC": 5.56
  },
  {
    "LONG": -41.47,
    "LAT": -1.62,
    "CIDADE": "Medina",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.53,
    "FEB": 5.98,
    "MAR": 5.47,
    "APR": 5.11,
    "MAY": 4.56,
    "JUN": 4.2,
    "JUL": 4.4,
    "AUG": 5.01,
    "SEP": 5.55,
    "OCT": 5.32,
    "NOV": 4.93,
    "DEC": 5.43
  },
  {
    "LONG": -40.69,
    "LAT": -16.18,
    "CIDADE": "Almenara",
    "UF": "MINAS GERAIS",
    "anual": 5.1,
    "JAN": 5.64,
    "FEB": 6.11,
    "MAR": 5.61,
    "APR": 5.24,
    "MAY": 4.57,
    "JUN": 4.16,
    "JUL": 4.28,
    "AUG": 4.69,
    "SEP": 5.26,
    "OCT": 5.08,
    "NOV": 4.97,
    "DEC": 5.57
  },
  {
    "LONG": -40.15,
    "LAT": -16.25,
    "CIDADE": "Santa Maria do Salto",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.51,
    "FEB": 5.91,
    "MAR": 5.55,
    "APR": 5.12,
    "MAY": 4.43,
    "JUN": 4.12,
    "JUL": 4.24,
    "AUG": 4.6,
    "SEP": 5.07,
    "OCT": 4.92,
    "NOV": 4.79,
    "DEC": 5.44
  },
  {
    "LONG": -46.26,
    "LAT": -16.06,
    "CIDADE": "Uruana de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.77,
    "JAN": 5.55,
    "FEB": 6.09,
    "MAR": 5.58,
    "APR": 5.96,
    "MAY": 5.76,
    "JUN": 5.59,
    "JUL": 5.95,
    "AUG": 6.53,
    "SEP": 6.15,
    "OCT": 5.77,
    "NOV": 4.94,
    "DEC": 5.33
  },
  {
    "LONG": -4.57,
    "LAT": -16.12,
    "CIDADE": "Urucuia",
    "UF": "MINAS GERAIS",
    "anual": 5.85,
    "JAN": 5.72,
    "FEB": 6.14,
    "MAR": 5.63,
    "APR": 6,
    "MAY": 5.8,
    "JUN": 5.65,
    "JUL": 6,
    "AUG": 6.67,
    "SEP": 6.22,
    "OCT": 5.86,
    "NOV": 5.07,
    "DEC": 5.41
  },
  {
    "LONG": -45.15,
    "LAT": -16.06,
    "CIDADE": "Pintópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.94,
    "JAN": 5.86,
    "FEB": 6.34,
    "MAR": 5.86,
    "APR": 6.15,
    "MAY": 5.85,
    "JUN": 5.66,
    "JUL": 5.91,
    "AUG": 6.57,
    "SEP": 6.26,
    "OCT": 6.01,
    "NOV": 5.23,
    "DEC": 5.54
  },
  {
    "LONG": -44.59,
    "LAT": -16.12,
    "CIDADE": "Luislândia",
    "UF": "MINAS GERAIS",
    "anual": 5.85,
    "JAN": 5.78,
    "FEB": 6.26,
    "MAR": 5.73,
    "APR": 6.01,
    "MAY": 5.74,
    "JUN": 5.57,
    "JUL": 5.86,
    "AUG": 6.59,
    "SEP": 6.22,
    "OCT": 5.83,
    "NOV": 5.11,
    "DEC": 5.52
  },
  {
    "LONG": -44.08,
    "LAT": -16.08,
    "CIDADE": "Patis",
    "UF": "MINAS GERAIS",
    "anual": 5.83,
    "JAN": 5.76,
    "FEB": 6.28,
    "MAR": 5.78,
    "APR": 5.96,
    "MAY": 5.63,
    "JUN": 5.5,
    "JUL": 5.8,
    "AUG": 6.41,
    "SEP": 6.25,
    "OCT": 5.86,
    "NOV": 5.08,
    "DEC": 5.6
  },
  {
    "LONG": -42.52,
    "LAT": -16.12,
    "CIDADE": "Fruta de Leite",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.65,
    "FEB": 6.14,
    "MAR": 5.54,
    "APR": 5.19,
    "MAY": 4.78,
    "JUN": 4.55,
    "JUL": 4.9,
    "AUG": 5.58,
    "SEP": 5.9,
    "OCT": 5.63,
    "NOV": 4.96,
    "DEC": 5.52
  },
  {
    "LONG": -41.75,
    "LAT": -16.1,
    "CIDADE": "Santa Cruz de Salinas",
    "UF": "MINAS GERAIS",
    "anual": 5.22,
    "JAN": 5.51,
    "FEB": 6.02,
    "MAR": 5.48,
    "APR": 5.09,
    "MAY": 4.62,
    "JUN": 4.35,
    "JUL": 4.61,
    "AUG": 5.18,
    "SEP": 5.79,
    "OCT": 5.51,
    "NOV": 4.95,
    "DEC": 5.51
  },
  {
    "LONG": -40.3,
    "LAT": -16.14,
    "CIDADE": "Jacinto",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.51,
    "FEB": 5.92,
    "MAR": 5.59,
    "APR": 5.18,
    "MAY": 4.49,
    "JUN": 4.18,
    "JUL": 4.28,
    "AUG": 4.68,
    "SEP": 5.09,
    "OCT": 4.96,
    "NOV": 4.84,
    "DEC": 5.47
  },
  {
    "LONG": -47.09,
    "LAT": -1.6,
    "CIDADE": "Cabeceira Grande",
    "UF": "MINAS GERAIS",
    "anual": 5.58,
    "JAN": 5.23,
    "FEB": 5.68,
    "MAR": 5.3,
    "APR": 5.58,
    "MAY": 5.54,
    "JUN": 5.58,
    "JUL": 5.93,
    "AUG": 6.49,
    "SEP": 6.07,
    "OCT": 5.62,
    "NOV": 4.83,
    "DEC": 5.11
  },
  {
    "LONG": -44.86,
    "LAT": -15.95,
    "CIDADE": "São Francisco",
    "UF": "MINAS GERAIS",
    "anual": 5.89,
    "JAN": 5.83,
    "FEB": 6.26,
    "MAR": 5.86,
    "APR": 6.06,
    "MAY": 5.74,
    "JUN": 5.56,
    "JUL": 5.88,
    "AUG": 6.49,
    "SEP": 6.19,
    "OCT": 5.95,
    "NOV": 5.28,
    "DEC": 5.58
  },
  {
    "LONG": -44.28,
    "LAT": -16,
    "CIDADE": "Japonvar",
    "UF": "MINAS GERAIS",
    "anual": 5.88,
    "JAN": 5.77,
    "FEB": 6.27,
    "MAR": 5.8,
    "APR": 6.04,
    "MAY": 5.74,
    "JUN": 5.6,
    "JUL": 5.92,
    "AUG": 6.47,
    "SEP": 6.36,
    "OCT": 5.95,
    "NOV": 5.09,
    "DEC": 5.53
  },
  {
    "LONG": -43.05,
    "LAT": -16.01,
    "CIDADE": "Riacho dos Machados",
    "UF": "MINAS GERAIS",
    "anual": 5.76,
    "JAN": 5.92,
    "FEB": 6.29,
    "MAR": 5.78,
    "APR": 5.73,
    "MAY": 5.42,
    "JUN": 5.24,
    "JUL": 5.55,
    "AUG": 6.14,
    "SEP": 6.28,
    "OCT": 5.94,
    "NOV": 5.2,
    "DEC": 5.63
  },
  {
    "LONG": -42.4,
    "LAT": -16.02,
    "CIDADE": "Novorizonte",
    "UF": "MINAS GERAIS",
    "anual": 5.32,
    "JAN": 5.64,
    "FEB": 6.04,
    "MAR": 5.5,
    "APR": 5.17,
    "MAY": 4.73,
    "JUN": 4.49,
    "JUL": 4.83,
    "AUG": 5.5,
    "SEP": 5.87,
    "OCT": 5.6,
    "NOV": 4.99,
    "DEC": 5.52
  },
  {
    "LONG": -41.5,
    "LAT": -15.97,
    "CIDADE": "Cachoeira de Pajeú",
    "UF": "MINAS GERAIS",
    "anual": 5.16,
    "JAN": 5.55,
    "FEB": 6.03,
    "MAR": 5.48,
    "APR": 5.12,
    "MAY": 4.61,
    "JUN": 4.23,
    "JUL": 4.4,
    "AUG": 5.02,
    "SEP": 5.62,
    "OCT": 5.39,
    "NOV": 4.93,
    "DEC": 5.5
  },
  {
    "LONG": -41.29,
    "LAT": -16.01,
    "CIDADE": "Pedra Azul",
    "UF": "MINAS GERAIS",
    "anual": 5.14,
    "JAN": 5.57,
    "FEB": 6.02,
    "MAR": 5.49,
    "APR": 5.08,
    "MAY": 4.58,
    "JUN": 4.21,
    "JUL": 4.35,
    "AUG": 4.96,
    "SEP": 5.56,
    "OCT": 5.4,
    "NOV": 4.94,
    "DEC": 5.53
  },
  {
    "LONG": -39.94,
    "LAT": -16.01,
    "CIDADE": "Salto da Divisa",
    "UF": "MINAS GERAIS",
    "anual": 4.97,
    "JAN": 5.53,
    "FEB": 5.85,
    "MAR": 5.46,
    "APR": 5.06,
    "MAY": 4.38,
    "JUN": 4.1,
    "JUL": 4.25,
    "AUG": 4.64,
    "SEP": 5.11,
    "OCT": 4.98,
    "NOV": 4.82,
    "DEC": 5.49
  },
  {
    "LONG": -46.11,
    "LAT": -15.91,
    "CIDADE": "Arinos",
    "UF": "MINAS GERAIS",
    "anual": 5.82,
    "JAN": 5.6,
    "FEB": 6.1,
    "MAR": 5.62,
    "APR": 5.93,
    "MAY": 5.82,
    "JUN": 5.67,
    "JUL": 6.03,
    "AUG": 6.61,
    "SEP": 6.28,
    "OCT": 5.8,
    "NOV": 5.04,
    "DEC": 5.37
  },
  {
    "LONG": -44.31,
    "LAT": -15.9,
    "CIDADE": "Lontra",
    "UF": "MINAS GERAIS",
    "anual": 5.86,
    "JAN": 5.76,
    "FEB": 6.25,
    "MAR": 5.76,
    "APR": 6.01,
    "MAY": 5.72,
    "JUN": 5.56,
    "JUL": 5.89,
    "AUG": 6.46,
    "SEP": 6.29,
    "OCT": 5.92,
    "NOV": 5.12,
    "DEC": 5.57
  },
  {
    "LONG": -44.01,
    "LAT": -15.93,
    "CIDADE": "São João da Ponte",
    "UF": "MINAS GERAIS",
    "anual": 5.85,
    "JAN": 5.74,
    "FEB": 6.36,
    "MAR": 5.8,
    "APR": 6.01,
    "MAY": 5.66,
    "JUN": 5.52,
    "JUL": 5.82,
    "AUG": 6.43,
    "SEP": 6.28,
    "OCT": 5.9,
    "NOV": 5.13,
    "DEC": 5.59
  },
  {
    "LONG": -4.19,
    "LAT": -15.93,
    "CIDADE": "Curral de Dentro",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.67,
    "FEB": 5.99,
    "MAR": 5.54,
    "APR": 5.12,
    "MAY": 4.67,
    "JUN": 4.39,
    "JUL": 4.62,
    "AUG": 5.22,
    "SEP": 5.85,
    "OCT": 5.56,
    "NOV": 4.99,
    "DEC": 5.51
  },
  {
    "LONG": -40.56,
    "LAT": -15.88,
    "CIDADE": "Bandeira",
    "UF": "MINAS GERAIS",
    "anual": 5.05,
    "JAN": 5.58,
    "FEB": 5.95,
    "MAR": 5.61,
    "APR": 5.2,
    "MAY": 4.51,
    "JUN": 4.17,
    "JUL": 4.29,
    "AUG": 4.75,
    "SEP": 5.22,
    "OCT": 5.03,
    "NOV": 4.84,
    "DEC": 5.46
  },
  {
    "LONG": -4.02,
    "LAT": -15.9,
    "CIDADE": "Jordânia",
    "UF": "MINAS GERAIS",
    "anual": 5.01,
    "JAN": 5.47,
    "FEB": 5.89,
    "MAR": 5.59,
    "APR": 5.18,
    "MAY": 4.47,
    "JUN": 4.11,
    "JUL": 4.28,
    "AUG": 4.67,
    "SEP": 5.14,
    "OCT": 4.99,
    "NOV": 4.86,
    "DEC": 5.53
  },
  {
    "LONG": -43.31,
    "LAT": -15.8,
    "CIDADE": "Janaúba",
    "UF": "MINAS GERAIS",
    "anual": 5.88,
    "JAN": 6,
    "FEB": 6.44,
    "MAR": 5.93,
    "APR": 5.96,
    "MAY": 5.58,
    "JUN": 5.41,
    "JUL": 5.62,
    "AUG": 6.19,
    "SEP": 6.21,
    "OCT": 6.03,
    "NOV": 5.39,
    "DEC": 5.75
  },
  {
    "LONG": -43.3,
    "LAT": -15.8,
    "CIDADE": "Nova Porteirinha",
    "UF": "MINAS GERAIS",
    "anual": 5.86,
    "JAN": 5.98,
    "FEB": 6.4,
    "MAR": 5.93,
    "APR": 5.97,
    "MAY": 5.59,
    "JUN": 5.38,
    "JUL": 5.59,
    "AUG": 6.14,
    "SEP": 6.22,
    "OCT": 6.01,
    "NOV": 5.4,
    "DEC": 5.73
  },
  {
    "LONG": -42.87,
    "LAT": -15.81,
    "CIDADE": "Serranópolis de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 6,
    "FEB": 6.48,
    "MAR": 5.87,
    "APR": 5.88,
    "MAY": 5.5,
    "JUN": 5.34,
    "JUL": 5.53,
    "AUG": 6.13,
    "SEP": 6.3,
    "OCT": 6.02,
    "NOV": 5.29,
    "DEC": 5.69
  },
  {
    "LONG": -42.23,
    "LAT": -15.81,
    "CIDADE": "Taiobeiras",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.6,
    "FEB": 6.02,
    "MAR": 5.52,
    "APR": 5.08,
    "MAY": 4.7,
    "JUN": 4.41,
    "JUL": 4.71,
    "AUG": 5.37,
    "SEP": 5.85,
    "OCT": 5.55,
    "NOV": 5.03,
    "DEC": 5.52
  },
  {
    "LONG": -44.16,
    "LAT": -1.57,
    "CIDADE": "Ibiracatu",
    "UF": "MINAS GERAIS",
    "anual": 5.89,
    "JAN": 5.81,
    "FEB": 6.33,
    "MAR": 5.82,
    "APR": 6.12,
    "MAY": 5.74,
    "JUN": 5.57,
    "JUL": 5.9,
    "AUG": 6.42,
    "SEP": 6.35,
    "OCT": 5.93,
    "NOV": 5.12,
    "DEC": 5.56
  },
  {
    "LONG": -44.03,
    "LAT": -15.7,
    "CIDADE": "Varzelândia",
    "UF": "MINAS GERAIS",
    "anual": 5.89,
    "JAN": 5.83,
    "FEB": 6.39,
    "MAR": 5.83,
    "APR": 6.06,
    "MAY": 5.72,
    "JUN": 5.53,
    "JUL": 5.86,
    "AUG": 6.42,
    "SEP": 6.34,
    "OCT": 5.93,
    "NOV": 5.18,
    "DEC": 5.61
  },
  {
    "LONG": -43.03,
    "LAT": -15.74,
    "CIDADE": "Porteirinha",
    "UF": "MINAS GERAIS",
    "anual": 5.82,
    "JAN": 6.03,
    "FEB": 6.46,
    "MAR": 5.92,
    "APR": 5.9,
    "MAY": 5.5,
    "JUN": 5.32,
    "JUL": 5.54,
    "AUG": 5.98,
    "SEP": 6.1,
    "OCT": 5.97,
    "NOV": 5.36,
    "DEC": 5.79
  },
  {
    "LONG": -41.46,
    "LAT": -15.75,
    "CIDADE": "Águas Vermelhas",
    "UF": "MINAS GERAIS",
    "anual": 5.12,
    "JAN": 5.48,
    "FEB": 5.85,
    "MAR": 5.43,
    "APR": 5.04,
    "MAY": 4.59,
    "JUN": 4.2,
    "JUL": 4.41,
    "AUG": 4.97,
    "SEP": 5.65,
    "OCT": 5.42,
    "NOV": 4.93,
    "DEC": 5.46
  },
  {
    "LONG": -41.35,
    "LAT": -15.72,
    "CIDADE": "Divisa Alegre",
    "UF": "MINAS GERAIS",
    "anual": 5.13,
    "JAN": 5.49,
    "FEB": 5.9,
    "MAR": 5.45,
    "APR": 5.04,
    "MAY": 4.6,
    "JUN": 4.23,
    "JUL": 4.44,
    "AUG": 4.99,
    "SEP": 5.66,
    "OCT": 5.4,
    "NOV": 4.94,
    "DEC": 5.46
  },
  {
    "LONG": 0,
    "LAT": -15.73,
    "CIDADE": "Divisópolis",
    "UF": "MINAS GERAIS",
    "anual": 5.07,
    "JAN": 5.47,
    "FEB": 5.8,
    "MAR": 5.44,
    "APR": 5.01,
    "MAY": 4.53,
    "JUN": 4.15,
    "JUL": 4.4,
    "AUG": 4.9,
    "SEP": 5.51,
    "OCT": 5.28,
    "NOV": 4.88,
    "DEC": 5.46
  },
  {
    "LONG": -40.74,
    "LAT": -15.69,
    "CIDADE": "Mata Verde",
    "UF": "MINAS GERAIS",
    "anual": 4.96,
    "JAN": 5.48,
    "FEB": 5.74,
    "MAR": 5.42,
    "APR": 4.94,
    "MAY": 4.38,
    "JUN": 4.02,
    "JUL": 4.2,
    "AUG": 4.66,
    "SEP": 5.29,
    "OCT": 5.14,
    "NOV": 4.85,
    "DEC": 5.43
  },
  {
    "LONG": -46.42,
    "LAT": -15.62,
    "CIDADE": "Buritis",
    "UF": "MINAS GERAIS",
    "anual": 5.79,
    "JAN": 5.57,
    "FEB": 6.01,
    "MAR": 5.61,
    "APR": 6.01,
    "MAY": 5.76,
    "JUN": 5.68,
    "JUL": 5.97,
    "AUG": 6.48,
    "SEP": 6.28,
    "OCT": 5.79,
    "NOV": 5,
    "DEC": 5.31
  },
  {
    "LONG": -44.39,
    "LAT": -15.6,
    "CIDADE": "Pedras de Maria da Cruz",
    "UF": "MINAS GERAIS",
    "anual": 5.94,
    "JAN": 5.9,
    "FEB": 6.39,
    "MAR": 6,
    "APR": 6.12,
    "MAY": 5.81,
    "JUN": 5.6,
    "JUL": 5.91,
    "AUG": 6.41,
    "SEP": 6.29,
    "OCT": 6,
    "NOV": 5.24,
    "DEC": 5.61
  },
  {
    "LONG": -4.36,
    "LAT": -15.59,
    "CIDADE": "Verdelândia",
    "UF": "MINAS GERAIS",
    "anual": 5.88,
    "JAN": 6.01,
    "FEB": 6.46,
    "MAR": 5.92,
    "APR": 5.98,
    "MAY": 5.61,
    "JUN": 5.42,
    "JUL": 5.75,
    "AUG": 6.26,
    "SEP": 6.14,
    "OCT": 5.94,
    "NOV": 5.34,
    "DEC": 5.73
  },
  {
    "LONG": -42.54,
    "LAT": -15.62,
    "CIDADE": "Rio Pardo de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.36,
    "JAN": 5.59,
    "FEB": 6.04,
    "MAR": 5.55,
    "APR": 5.22,
    "MAY": 4.79,
    "JUN": 4.58,
    "JUL": 4.9,
    "AUG": 5.55,
    "SEP": 5.93,
    "OCT": 5.61,
    "NOV": 5.01,
    "DEC": 5.52
  },
  {
    "LONG": -41.74,
    "LAT": -15.61,
    "CIDADE": "Berizal",
    "UF": "MINAS GERAIS",
    "anual": 5.23,
    "JAN": 5.63,
    "FEB": 6.03,
    "MAR": 5.5,
    "APR": 5.14,
    "MAY": 4.61,
    "JUN": 4.33,
    "JUL": 4.54,
    "AUG": 5.17,
    "SEP": 5.78,
    "OCT": 5.55,
    "NOV": 4.99,
    "DEC": 5.54
  },
  {
    "LONG": -44.36,
    "LAT": -15.48,
    "CIDADE": "Januária",
    "UF": "MINAS GERAIS",
    "anual": 5.97,
    "JAN": 5.93,
    "FEB": 6.42,
    "MAR": 6.03,
    "APR": 6.16,
    "MAY": 5.84,
    "JUN": 5.63,
    "JUL": 5.94,
    "AUG": 6.41,
    "SEP": 6.33,
    "OCT": 6.05,
    "NOV": 5.25,
    "DEC": 5.64
  },
  {
    "LONG": -43.07,
    "LAT": -15.53,
    "CIDADE": "Pai Pedro",
    "UF": "MINAS GERAIS",
    "anual": 5.9,
    "JAN": 6.07,
    "FEB": 6.48,
    "MAR": 6.04,
    "APR": 5.98,
    "MAY": 5.6,
    "JUN": 5.39,
    "JUL": 5.61,
    "AUG": 6.05,
    "SEP": 6.22,
    "OCT": 6.05,
    "NOV": 5.42,
    "DEC": 5.84
  },
  {
    "LONG": -42.2,
    "LAT": -15.49,
    "CIDADE": "Indaiabira",
    "UF": "MINAS GERAIS",
    "anual": 5.28,
    "JAN": 5.56,
    "FEB": 6.05,
    "MAR": 5.52,
    "APR": 5.11,
    "MAY": 4.7,
    "JUN": 4.41,
    "JUL": 4.71,
    "AUG": 5.34,
    "SEP": 5.87,
    "OCT": 5.58,
    "NOV": 5.01,
    "DEC": 5.52
  },
  {
    "LONG": -42.96,
    "LAT": -1.54,
    "CIDADE": "Catuti",
    "UF": "MINAS GERAIS",
    "anual": 5.91,
    "JAN": 6.08,
    "FEB": 6.53,
    "MAR": 6.07,
    "APR": 6.04,
    "MAY": 5.58,
    "JUN": 5.48,
    "JUL": 5.63,
    "AUG": 5.97,
    "SEP": 6.13,
    "OCT": 6.08,
    "NOV": 5.51,
    "DEC": 5.88
  },
  {
    "LONG": -42.86,
    "LAT": -15.39,
    "CIDADE": "Mato Verde",
    "UF": "MINAS GERAIS",
    "anual": 5.93,
    "JAN": 6.08,
    "FEB": 6.51,
    "MAR": 6.12,
    "APR": 6.05,
    "MAY": 5.58,
    "JUN": 5.42,
    "JUL": 5.6,
    "AUG": 6.05,
    "SEP": 6.24,
    "OCT": 6.1,
    "NOV": 5.52,
    "DEC": 5.89
  },
  {
    "LONG": -42.31,
    "LAT": -15.4,
    "CIDADE": "Vargem Grande do Rio Pardo",
    "UF": "MINAS GERAIS",
    "anual": 5.34,
    "JAN": 5.51,
    "FEB": 6.03,
    "MAR": 5.55,
    "APR": 5.16,
    "MAY": 4.78,
    "JUN": 4.54,
    "JUL": 4.87,
    "AUG": 5.48,
    "SEP": 5.98,
    "OCT": 5.63,
    "NOV": 5,
    "DEC": 5.54
  },
  {
    "LONG": -45.62,
    "LAT": -15.3,
    "CIDADE": "Chapada Gaúcha",
    "UF": "MINAS GERAIS",
    "anual": 5.73,
    "JAN": 5.55,
    "FEB": 6,
    "MAR": 5.48,
    "APR": 5.92,
    "MAY": 5.65,
    "JUN": 5.62,
    "JUL": 5.93,
    "AUG": 6.45,
    "SEP": 6.19,
    "OCT": 5.7,
    "NOV": 4.95,
    "DEC": 5.35
  },
  {
    "LONG": -44.76,
    "LAT": -15.32,
    "CIDADE": "Bonito de Minas",
    "UF": "MINAS GERAIS",
    "anual": 5.9,
    "JAN": 5.81,
    "FEB": 6.22,
    "MAR": 5.79,
    "APR": 6.1,
    "MAY": 5.77,
    "JUN": 5.72,
    "JUL": 6.07,
    "AUG": 6.58,
    "SEP": 6.36,
    "OCT": 5.91,
    "NOV": 5.08,
    "DEC": 5.43
  },
  {
    "LONG": -44.41,
    "LAT": -15.3,
    "CIDADE": "Cônego Marinho",
    "UF": "MINAS GERAIS",
    "anual": 5.93,
    "JAN": 5.84,
    "FEB": 6.31,
    "MAR": 5.89,
    "APR": 6.11,
    "MAY": 5.84,
    "JUN": 5.68,
    "JUL": 6.07,
    "AUG": 6.48,
    "SEP": 6.36,
    "OCT": 5.93,
    "NOV": 5.12,
    "DEC": 5.53
  },
  {
    "LONG": -43.67,
    "LAT": -15.34,
    "CIDADE": "Jaíba",
    "UF": "MINAS GERAIS",
    "anual": 5.88,
    "JAN": 5.92,
    "FEB": 6.41,
    "MAR": 5.96,
    "APR": 6.02,
    "MAY": 5.63,
    "JUN": 5.49,
    "JUL": 5.72,
    "AUG": 6.16,
    "SEP": 6.14,
    "OCT": 6.01,
    "NOV": 5.31,
    "DEC": 5.75
  },
  {
    "LONG": -42.62,
    "LAT": -15.34,
    "CIDADE": "Santo Antônio do Retiro",
    "UF": "MINAS GERAIS",
    "anual": 5.41,
    "JAN": 5.63,
    "FEB": 6.07,
    "MAR": 5.53,
    "APR": 5.23,
    "MAY": 4.85,
    "JUN": 4.66,
    "JUL": 5.03,
    "AUG": 5.68,
    "SEP": 6.02,
    "OCT": 5.67,
    "NOV": 4.99,
    "DEC": 5.52
  },
  {
    "LONG": -42.02,
    "LAT": -15.32,
    "CIDADE": "São João do Paraíso",
    "UF": "MINAS GERAIS",
    "anual": 5.26,
    "JAN": 5.54,
    "FEB": 6,
    "MAR": 5.42,
    "APR": 5.11,
    "MAY": 4.68,
    "JUN": 4.41,
    "JUL": 4.65,
    "AUG": 5.29,
    "SEP": 5.91,
    "OCT": 5.62,
    "NOV": 5.05,
    "DEC": 5.5
  },
  {
    "LONG": -41.76,
    "LAT": "#VALOR!",
    "CIDADE": "Ninheira",
    "UF": "MINAS GERAIS",
    "anual": 5.21,
    "JAN": 5.57,
    "FEB": 5.87,
    "MAR": 5.5,
    "APR": 5.03,
    "MAY": 4.58,
    "JUN": 4.29,
    "JUL": 4.58,
    "AUG": 5.17,
    "SEP": 5.82,
    "OCT": 5.57,
    "NOV": 5.05,
    "DEC": 5.54
  },
  {
    "LONG": -42.87,
    "LAT": -15.15,
    "CIDADE": "Monte Azul",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.92,
    "FEB": 6.35,
    "MAR": 5.94,
    "APR": 5.85,
    "MAY": 5.5,
    "JUN": 5.42,
    "JUL": 5.6,
    "AUG": 6.15,
    "SEP": 6.27,
    "OCT": 6.03,
    "NOV": 5.33,
    "DEC": 5.76
  },
  {
    "LONG": -42.5,
    "LAT": -15.18,
    "CIDADE": "Montezuma",
    "UF": "MINAS GERAIS",
    "anual": 5.42,
    "JAN": 5.6,
    "FEB": 6.07,
    "MAR": 5.6,
    "APR": 5.23,
    "MAY": 4.89,
    "JUN": 4.66,
    "JUL": 5.04,
    "AUG": 5.65,
    "SEP": 6.03,
    "OCT": 5.7,
    "NOV": 5.06,
    "DEC": 5.57
  },
  {
    "LONG": -44.1,
    "LAT": -15.1,
    "CIDADE": "Itacarambi",
    "UF": "MINAS GERAIS",
    "anual": 5.95,
    "JAN": 5.87,
    "FEB": 6.42,
    "MAR": 6.03,
    "APR": 6.17,
    "MAY": 5.73,
    "JUN": 5.62,
    "JUL": 5.86,
    "AUG": 6.24,
    "SEP": 6.27,
    "OCT": 6.09,
    "NOV": 5.34,
    "DEC": 5.71
  },
  {
    "LONG": -43.12,
    "LAT": -15.08,
    "CIDADE": "Gameleiras",
    "UF": "MINAS GERAIS",
    "anual": 5.84,
    "JAN": 5.88,
    "FEB": 6.3,
    "MAR": 5.94,
    "APR": 5.84,
    "MAY": 5.49,
    "JUN": 5.4,
    "JUL": 5.67,
    "AUG": 6.22,
    "SEP": 6.36,
    "OCT": 5.98,
    "NOV": 5.26,
    "DEC": 5.75
  },
  {
    "LONG": -42.95,
    "LAT": -15.05,
    "CIDADE": "Mamonas",
    "UF": "MINAS GERAIS",
    "anual": 5.81,
    "JAN": 5.98,
    "FEB": 6.31,
    "MAR": 5.89,
    "APR": 5.75,
    "MAY": 5.44,
    "JUN": 5.39,
    "JUL": 5.62,
    "AUG": 6.09,
    "SEP": 6.21,
    "OCT": 5.99,
    "NOV": 5.32,
    "DEC": 5.73
  },
  {
    "LONG": -46.23,
    "LAT": -14.95,
    "CIDADE": "Formoso",
    "UF": "MINAS GERAIS",
    "anual": 5.7,
    "JAN": 5.44,
    "FEB": 5.83,
    "MAR": 5.41,
    "APR": 5.74,
    "MAY": 5.66,
    "JUN": 5.64,
    "JUL": 6.03,
    "AUG": 6.5,
    "SEP": 6.24,
    "OCT": 5.68,
    "NOV": 4.94,
    "DEC": 5.24
  },
  {
    "LONG": -44.08,
    "LAT": -14.88,
    "CIDADE": "São João das Missões",
    "UF": "MINAS GERAIS",
    "anual": 5.95,
    "JAN": 5.9,
    "FEB": 6.37,
    "MAR": 5.99,
    "APR": 6.14,
    "MAY": 5.68,
    "JUN": 5.62,
    "JUL": 5.96,
    "AUG": 6.31,
    "SEP": 6.43,
    "OCT": 6.05,
    "NOV": 5.3,
    "DEC": 5.69
  },
  {
    "LONG": -43.92,
    "LAT": -14.85,
    "CIDADE": "Matias Cardoso",
    "UF": "MINAS GERAIS",
    "anual": 5.96,
    "JAN": 5.95,
    "FEB": 6.35,
    "MAR": 6.02,
    "APR": 6.13,
    "MAY": 5.69,
    "JUN": 5.62,
    "JUL": 5.95,
    "AUG": 6.36,
    "SEP": 6.4,
    "OCT": 6.06,
    "NOV": 5.32,
    "DEC": 5.71
  },
  {
    "LONG": -42.81,
    "LAT": -14.93,
    "CIDADE": "Espinosa",
    "UF": "MINAS GERAIS",
    "anual": 5.86,
    "JAN": 6,
    "FEB": 6.35,
    "MAR": 6,
    "APR": 5.81,
    "MAY": 5.46,
    "JUN": 5.38,
    "JUL": 5.57,
    "AUG": 6.1,
    "SEP": 6.22,
    "OCT": 6.11,
    "NOV": 5.43,
    "DEC": 5.87
  },
  {
    "LONG": -43.94,
    "LAT": -14.75,
    "CIDADE": "Manga",
    "UF": "MINAS GERAIS",
    "anual": 5.98,
    "JAN": 5.98,
    "FEB": 6.39,
    "MAR": 6.04,
    "APR": 6.13,
    "MAY": 5.69,
    "JUN": 5.63,
    "JUL": 5.94,
    "AUG": 6.31,
    "SEP": 6.43,
    "OCT": 6.11,
    "NOV": 5.39,
    "DEC": 5.71
  },
  {
    "LONG": -44.42,
    "LAT": -14.74,
    "CIDADE": "Miravânia",
    "UF": "MINAS GERAIS",
    "anual": 5.96,
    "JAN": 5.8,
    "FEB": 6.31,
    "MAR": 5.82,
    "APR": 6.07,
    "MAY": 5.76,
    "JUN": 5.71,
    "JUL": 6.13,
    "AUG": 6.58,
    "SEP": 6.51,
    "OCT": 6.02,
    "NOV": 5.2,
    "DEC": 5.63
  },
  {
    "LONG": -44.37,
    "LAT": -14.42,
    "CIDADE": "Montalvânia",
    "UF": "MINAS GERAIS",
    "anual": 5.94,
    "JAN": 5.94,
    "FEB": 6.24,
    "MAR": 5.9,
    "APR": 6.06,
    "MAY": 5.72,
    "JUN": 5.64,
    "JUL": 6,
    "AUG": 6.31,
    "SEP": 6.36,
    "OCT": 6.03,
    "NOV": 5.36,
    "DEC": 5.74
  },
  {
    "LONG": "#VALOR!",
    "LAT": -14.27,
    "CIDADE": "Juvenília",
    "UF": "MINAS GERAIS",
    "anual": 5.98,
    "JAN": 6.02,
    "FEB": 6.28,
    "MAR": 6,
    "APR": 6.09,
    "MAY": 5.78,
    "JUN": 5.64,
    "JUL": 6.03,
    "AUG": 6.33,
    "SEP": 6.38,
    "OCT": 6.1,
    "NOV": 5.37,
    "DEC": 5.77
  },
  {
    "LONG": -50.35,
    "LAT": -9.33,
    "CIDADE": "Santana do Araguaia",
    "UF": "PARÁ",
    "anual": 5.11,
    "JAN": 4.66,
    "FEB": 4.82,
    "MAR": 4.82,
    "APR": 4.87,
    "MAY": 5.18,
    "JUN": 5.43,
    "JUL": 5.56,
    "AUG": 6.1,
    "SEP": 5.44,
    "OCT": 4.98,
    "NOV": 4.8,
    "DEC": 4.65
  },
  {
    "LONG": -49.72,
    "LAT": -0.89,
    "CIDADE": "Santa Maria das Barreiras",
    "UF": "PARÁ",
    "anual": 5.17,
    "JAN": 4.65,
    "FEB": 4.86,
    "MAR": 4.81,
    "APR": 4.94,
    "MAY": 5.28,
    "JUN": 5.55,
    "JUL": 5.65,
    "AUG": 6.2,
    "SEP": 5.61,
    "OCT": 5.01,
    "NOV": 4.82,
    "DEC": 4.67
  },
  {
    "LONG": -49.27,
    "LAT": -8.26,
    "CIDADE": "Conceição do Araguaia",
    "UF": "PARÁ",
    "anual": 5.14,
    "JAN": 4.56,
    "FEB": 4.8,
    "MAR": 4.82,
    "APR": 4.88,
    "MAY": 5.24,
    "JUN": 5.55,
    "JUL": 5.65,
    "AUG": 6.18,
    "SEP": 5.73,
    "OCT": 5.04,
    "NOV": 4.68,
    "DEC": 4.52
  },
  {
    "LONG": -50.03,
    "LAT": -8.03,
    "CIDADE": "Redenção",
    "UF": "PARÁ",
    "anual": 5.03,
    "JAN": 4.62,
    "FEB": 4.77,
    "MAR": 4.72,
    "APR": 4.84,
    "MAY": 5.09,
    "JUN": 5.38,
    "JUL": 5.32,
    "AUG": 5.96,
    "SEP": 5.49,
    "OCT": 4.95,
    "NOV": 4.7,
    "DEC": 4.51
  },
  {
    "LONG": -50.77,
    "LAT": -7.81,
    "CIDADE": "Cumaru do Norte",
    "UF": "PARÁ",
    "anual": 4.85,
    "JAN": 4.4,
    "FEB": 4.56,
    "MAR": 4.5,
    "APR": 4.61,
    "MAY": 4.97,
    "JUN": 5.39,
    "JUL": 5.4,
    "AUG": 5.83,
    "SEP": 5.22,
    "OCT": 4.65,
    "NOV": 4.46,
    "DEC": 4.26
  },
  {
    "LONG": -50.04,
    "LAT": -7.83,
    "CIDADE": "Pau D'Arco",
    "UF": "PARÁ",
    "anual": 5.02,
    "JAN": 4.64,
    "FEB": 4.7,
    "MAR": 4.71,
    "APR": 4.84,
    "MAY": 5.11,
    "JUN": 5.4,
    "JUL": 5.45,
    "AUG": 6.04,
    "SEP": 5.45,
    "OCT": 4.84,
    "NOV": 4.64,
    "DEC": 4.48
  },
  {
    "LONG": -49.7,
    "LAT": -7.56,
    "CIDADE": "Floresta do Araguaia",
    "UF": "PARÁ",
    "anual": 5,
    "JAN": 4.53,
    "FEB": 4.67,
    "MAR": 4.69,
    "APR": 4.78,
    "MAY": 5.07,
    "JUN": 5.41,
    "JUL": 5.47,
    "AUG": 5.97,
    "SEP": 5.49,
    "OCT": 4.9,
    "NOV": 4.58,
    "DEC": 4.46
  },
  {
    "LONG": -50.41,
    "LAT": -7.35,
    "CIDADE": "Bannach",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.42,
    "FEB": 4.55,
    "MAR": 4.59,
    "APR": 4.67,
    "MAY": 4.97,
    "JUN": 5.35,
    "JUL": 5.28,
    "AUG": 5.81,
    "SEP": 5.25,
    "OCT": 4.7,
    "NOV": 4.49,
    "DEC": 4.33
  },
  {
    "LONG": -50.04,
    "LAT": -7.32,
    "CIDADE": "Rio Maria",
    "UF": "PARÁ",
    "anual": 4.98,
    "JAN": 4.55,
    "FEB": 4.69,
    "MAR": 4.68,
    "APR": 4.82,
    "MAY": 5.11,
    "JUN": 5.37,
    "JUL": 5.36,
    "AUG": 5.9,
    "SEP": 5.39,
    "OCT": 4.87,
    "NOV": 4.59,
    "DEC": 4.45
  },
  {
    "LONG": -5.54,
    "LAT": -7.14,
    "CIDADE": "Novo Progresso",
    "UF": "PARÁ",
    "anual": 4.63,
    "JAN": 4.16,
    "FEB": 4.23,
    "MAR": 4.3,
    "APR": 4.29,
    "MAY": 4.57,
    "JUN": 4.97,
    "JUL": 4.94,
    "AUG": 5.42,
    "SEP": 4.97,
    "OCT": 4.82,
    "NOV": 4.55,
    "DEC": 4.35
  },
  {
    "LONG": -49.94,
    "LAT": -7.1,
    "CIDADE": "Xinguara",
    "UF": "PARÁ",
    "anual": 4.97,
    "JAN": 4.52,
    "FEB": 4.68,
    "MAR": 4.66,
    "APR": 4.79,
    "MAY": 5.11,
    "JUN": 5.39,
    "JUL": 5.34,
    "AUG": 5.87,
    "SEP": 5.43,
    "OCT": 4.84,
    "NOV": 4.56,
    "DEC": 4.42
  },
  {
    "LONG": -49.7,
    "LAT": -6.94,
    "CIDADE": "Sapucaia",
    "UF": "PARÁ",
    "anual": 4.96,
    "JAN": 4.49,
    "FEB": 4.65,
    "MAR": 4.68,
    "APR": 4.77,
    "MAY": 5.09,
    "JUN": 5.37,
    "JUL": 5.38,
    "AUG": 5.82,
    "SEP": 5.44,
    "OCT": 4.86,
    "NOV": 4.52,
    "DEC": 4.41
  },
  {
    "LONG": -51.09,
    "LAT": -6.75,
    "CIDADE": "Ourilândia do Norte",
    "UF": "PARÁ",
    "anual": 4.78,
    "JAN": 4.28,
    "FEB": 4.47,
    "MAR": 4.48,
    "APR": 4.55,
    "MAY": 4.86,
    "JUN": 5.18,
    "JUL": 5.17,
    "AUG": 5.56,
    "SEP": 5.27,
    "OCT": 4.74,
    "NOV": 4.49,
    "DEC": 4.27
  },
  {
    "LONG": -5.05,
    "LAT": -6.81,
    "CIDADE": "Água Azul do Norte",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.49,
    "FEB": 4.61,
    "MAR": 4.66,
    "APR": 4.67,
    "MAY": 4.97,
    "JUN": 5.22,
    "JUL": 5.24,
    "AUG": 5.68,
    "SEP": 5.29,
    "OCT": 4.79,
    "NOV": 4.55,
    "DEC": 4.34
  },
  {
    "LONG": -5.12,
    "LAT": -6.75,
    "CIDADE": "Tucumã",
    "UF": "PARÁ",
    "anual": 4.79,
    "JAN": 4.32,
    "FEB": 4.5,
    "MAR": 4.47,
    "APR": 4.59,
    "MAY": 4.9,
    "JUN": 5.25,
    "JUL": 5.16,
    "AUG": 5.6,
    "SEP": 5.23,
    "OCT": 4.73,
    "NOV": 4.52,
    "DEC": 4.27
  },
  {
    "LONG": -51.99,
    "LAT": -6.64,
    "CIDADE": "São Félix do Xingu",
    "UF": "PARÁ",
    "anual": 4.74,
    "JAN": 4.17,
    "FEB": 4.48,
    "MAR": 4.52,
    "APR": 4.54,
    "MAY": 4.84,
    "JUN": 5.2,
    "JUL": 5.1,
    "AUG": 5.48,
    "SEP": 5.13,
    "OCT": 4.72,
    "NOV": 4.47,
    "DEC": 4.2
  },
  {
    "LONG": -4.99,
    "LAT": -0.65,
    "CIDADE": "Canaã dos Carajás",
    "UF": "PARÁ",
    "anual": 4.94,
    "JAN": 4.49,
    "FEB": 4.7,
    "MAR": 4.74,
    "APR": 4.8,
    "MAY": 5,
    "JUN": 5.28,
    "JUL": 5.32,
    "AUG": 5.71,
    "SEP": 5.39,
    "OCT": 4.88,
    "NOV": 4.58,
    "DEC": 4.43
  },
  {
    "LONG": -48.86,
    "LAT": -6.44,
    "CIDADE": "Piçarra",
    "UF": "PARÁ",
    "anual": 4.98,
    "JAN": 4.39,
    "FEB": 4.68,
    "MAR": 4.61,
    "APR": 4.83,
    "MAY": 5.04,
    "JUN": 5.43,
    "JUL": 5.55,
    "AUG": 5.96,
    "SEP": 5.49,
    "OCT": 4.89,
    "NOV": 4.53,
    "DEC": 4.37
  },
  {
    "LONG": -48.56,
    "LAT": -6.4,
    "CIDADE": "São Geraldo do Araguaia",
    "UF": "PARÁ",
    "anual": 5.04,
    "JAN": 4.49,
    "FEB": 4.74,
    "MAR": 4.72,
    "APR": 4.89,
    "MAY": 5.11,
    "JUN": 5.47,
    "JUL": 5.53,
    "AUG": 5.94,
    "SEP": 5.66,
    "OCT": 4.97,
    "NOV": 4.56,
    "DEC": 4.39
  },
  {
    "LONG": -57.75,
    "LAT": -6.22,
    "CIDADE": "Jacareacanga",
    "UF": "PARÁ",
    "anual": 4.58,
    "JAN": 4.04,
    "FEB": 4.3,
    "MAR": 4.31,
    "APR": 4.17,
    "MAY": 4.32,
    "JUN": 4.84,
    "JUL": 4.79,
    "AUG": 5.45,
    "SEP": 5.08,
    "OCT": 4.81,
    "NOV": 4.59,
    "DEC": 4.3
  },
  {
    "LONG": -49.9,
    "LAT": -6.07,
    "CIDADE": "Parauapebas",
    "UF": "PARÁ",
    "anual": 4.81,
    "JAN": 4.34,
    "FEB": 4.47,
    "MAR": 4.49,
    "APR": 4.6,
    "MAY": 4.91,
    "JUN": 5.23,
    "JUL": 5.41,
    "AUG": 5.79,
    "SEP": 5.26,
    "OCT": 4.72,
    "NOV": 4.35,
    "DEC": 4.15
  },
  {
    "LONG": -49.6,
    "LAT": -0.61,
    "CIDADE": "Curionópolis",
    "UF": "PARÁ",
    "anual": 4.8,
    "JAN": 4.3,
    "FEB": 4.57,
    "MAR": 4.52,
    "APR": 4.74,
    "MAY": 4.92,
    "JUN": 5.24,
    "JUL": 5.32,
    "AUG": 5.71,
    "SEP": 5.18,
    "OCT": 4.6,
    "NOV": 4.32,
    "DEC": 4.15
  },
  {
    "LONG": -49.36,
    "LAT": -6.1,
    "CIDADE": "Eldorado do Carajás",
    "UF": "PARÁ",
    "anual": 4.94,
    "JAN": 4.44,
    "FEB": 4.68,
    "MAR": 4.7,
    "APR": 4.83,
    "MAY": 5,
    "JUN": 5.34,
    "JUL": 5.43,
    "AUG": 5.79,
    "SEP": 5.26,
    "OCT": 4.81,
    "NOV": 4.56,
    "DEC": 4.38
  },
  {
    "LONG": -48.4,
    "LAT": -5.7,
    "CIDADE": "Brejo Grande do Araguaia",
    "UF": "PARÁ",
    "anual": 4.95,
    "JAN": 4.44,
    "FEB": 4.65,
    "MAR": 4.74,
    "APR": 4.8,
    "MAY": 4.9,
    "JUN": 5.3,
    "JUL": 5.49,
    "AUG": 5.86,
    "SEP": 5.46,
    "OCT": 4.83,
    "NOV": 4.58,
    "DEC": 4.36
  },
  {
    "LONG": -48.32,
    "LAT": -5.74,
    "CIDADE": "Palestina do Pará",
    "UF": "PARÁ",
    "anual": 4.98,
    "JAN": 4.45,
    "FEB": 4.64,
    "MAR": 4.76,
    "APR": 4.87,
    "MAY": 4.96,
    "JUN": 5.37,
    "JUL": 5.48,
    "AUG": 5.89,
    "SEP": 5.47,
    "OCT": 4.87,
    "NOV": 4.62,
    "DEC": 4.41
  },
  {
    "LONG": -48.73,
    "LAT": -0.55,
    "CIDADE": "São Domingos do Araguaia",
    "UF": "PARÁ",
    "anual": 4.94,
    "JAN": 4.44,
    "FEB": 4.66,
    "MAR": 4.74,
    "APR": 4.88,
    "MAY": 4.91,
    "JUN": 5.29,
    "JUL": 5.36,
    "AUG": 5.77,
    "SEP": 5.4,
    "OCT": 4.78,
    "NOV": 4.62,
    "DEC": 4.37
  },
  {
    "LONG": -49.13,
    "LAT": -5.38,
    "CIDADE": "Marabá",
    "UF": "PARÁ",
    "anual": 4.91,
    "JAN": 4.42,
    "FEB": 4.62,
    "MAR": 4.7,
    "APR": 4.88,
    "MAY": 4.87,
    "JUN": 5.25,
    "JUL": 5.37,
    "AUG": 5.66,
    "SEP": 5.36,
    "OCT": 4.82,
    "NOV": 4.6,
    "DEC": 4.32
  },
  {
    "LONG": -48.79,
    "LAT": -5.36,
    "CIDADE": "São João do Araguaia",
    "UF": "PARÁ",
    "anual": 5.03,
    "JAN": 4.58,
    "FEB": 4.72,
    "MAR": 4.85,
    "APR": 5.08,
    "MAY": 5.03,
    "JUN": 5.36,
    "JUL": 5.38,
    "AUG": 5.82,
    "SEP": 5.51,
    "OCT": 4.9,
    "NOV": 4.72,
    "DEC": 4.46
  },
  {
    "LONG": -49.33,
    "LAT": -5.13,
    "CIDADE": "Itupiranga",
    "UF": "PARÁ",
    "anual": 4.82,
    "JAN": 4.27,
    "FEB": 4.54,
    "MAR": 4.56,
    "APR": 4.74,
    "MAY": 4.83,
    "JUN": 5.25,
    "JUL": 5.33,
    "AUG": 5.6,
    "SEP": 5.21,
    "OCT": 4.72,
    "NOV": 4.51,
    "DEC": 4.22
  },
  {
    "LONG": -48.61,
    "LAT": -5.04,
    "CIDADE": "Bom Jesus do Tocantins",
    "UF": "PARÁ",
    "anual": 4.85,
    "JAN": 4.28,
    "FEB": 4.43,
    "MAR": 4.6,
    "APR": 4.69,
    "MAY": 4.84,
    "JUN": 5.25,
    "JUL": 5.32,
    "AUG": 5.65,
    "SEP": 5.34,
    "OCT": 4.77,
    "NOV": 4.65,
    "DEC": 4.33
  },
  {
    "LONG": -48.39,
    "LAT": -4.95,
    "CIDADE": "Abel Figueiredo",
    "UF": "PARÁ",
    "anual": 4.89,
    "JAN": 4.3,
    "FEB": 4.5,
    "MAR": 4.64,
    "APR": 4.72,
    "MAY": 4.84,
    "JUN": 5.27,
    "JUL": 5.41,
    "AUG": 5.7,
    "SEP": 5.36,
    "OCT": 4.87,
    "NOV": 4.63,
    "DEC": 4.43
  },
  {
    "LONG": -49.08,
    "LAT": -4.92,
    "CIDADE": "Nova Ipixuna",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.26,
    "FEB": 4.39,
    "MAR": 4.49,
    "APR": 4.58,
    "MAY": 4.7,
    "JUN": 5.17,
    "JUL": 5.27,
    "AUG": 5.58,
    "SEP": 5.25,
    "OCT": 4.68,
    "NOV": 4.49,
    "DEC": 4.22
  },
  {
    "LONG": -48.07,
    "LAT": -4.78,
    "CIDADE": "Rondon do Pará",
    "UF": "PARÁ",
    "anual": 4.85,
    "JAN": 4.31,
    "FEB": 4.52,
    "MAR": 4.62,
    "APR": 4.8,
    "MAY": 4.87,
    "JUN": 5.15,
    "JUL": 5.25,
    "AUG": 5.59,
    "SEP": 5.28,
    "OCT": 4.81,
    "NOV": 4.57,
    "DEC": 4.4
  },
  {
    "LONG": -55.94,
    "LAT": -4.57,
    "CIDADE": "Trairão",
    "UF": "PARÁ",
    "anual": 4.5,
    "JAN": 3.93,
    "FEB": 4.1,
    "MAR": 4.12,
    "APR": 4.03,
    "MAY": 4.18,
    "JUN": 4.65,
    "JUL": 4.62,
    "AUG": 5.21,
    "SEP": 5.09,
    "OCT": 4.99,
    "NOV": 4.69,
    "DEC": 4.34
  },
  {
    "LONG": -49.12,
    "LAT": -4.45,
    "CIDADE": "Jacundá",
    "UF": "PARÁ",
    "anual": 4.74,
    "JAN": 4.28,
    "FEB": 4.48,
    "MAR": 4.58,
    "APR": 4.71,
    "MAY": 4.69,
    "JUN": 5.01,
    "JUL": 5.14,
    "AUG": 5.37,
    "SEP": 5.19,
    "OCT": 4.66,
    "NOV": 4.51,
    "DEC": 4.27
  },
  {
    "LONG": -55.99,
    "LAT": -4.27,
    "CIDADE": "Itaituba",
    "UF": "PARÁ",
    "anual": 4.68,
    "JAN": 4.2,
    "FEB": 4.34,
    "MAR": 4.39,
    "APR": 4.29,
    "MAY": 4.35,
    "JUN": 4.69,
    "JUL": 4.67,
    "AUG": 5.31,
    "SEP": 5.2,
    "OCT": 5.21,
    "NOV": 4.95,
    "DEC": 4.6
  },
  {
    "LONG": -47.55,
    "LAT": -4.3,
    "CIDADE": "Dom Eliseu",
    "UF": "PARÁ",
    "anual": 4.79,
    "JAN": 4.33,
    "FEB": 4.59,
    "MAR": 4.65,
    "APR": 4.84,
    "MAY": 4.81,
    "JUN": 5.05,
    "JUL": 5.07,
    "AUG": 5.3,
    "SEP": 5.24,
    "OCT": 4.81,
    "NOV": 4.49,
    "DEC": 4.36
  },
  {
    "LONG": -49.95,
    "LAT": -4.25,
    "CIDADE": "Novo Repartimento",
    "UF": "PARÁ",
    "anual": 4.7,
    "JAN": 4.14,
    "FEB": 4.47,
    "MAR": 4.63,
    "APR": 4.68,
    "MAY": 4.82,
    "JUN": 5.09,
    "JUL": 5.17,
    "AUG": 5.31,
    "SEP": 4.97,
    "OCT": 4.52,
    "NOV": 4.4,
    "DEC": 4.15
  },
  {
    "LONG": -54.91,
    "LAT": -4.1,
    "CIDADE": "Rurópolis",
    "UF": "PARÁ",
    "anual": 4.54,
    "JAN": 4,
    "FEB": 4.2,
    "MAR": 4.24,
    "APR": 4.22,
    "MAY": 4.29,
    "JUN": 4.66,
    "JUL": 4.6,
    "AUG": 5.18,
    "SEP": 4.99,
    "OCT": 4.98,
    "NOV": 4.74,
    "DEC": 4.34
  },
  {
    "LONG": -54.21,
    "LAT": -3.87,
    "CIDADE": "Placas",
    "UF": "PARÁ",
    "anual": 4.51,
    "JAN": 4.03,
    "FEB": 4.18,
    "MAR": 4.22,
    "APR": 4.19,
    "MAY": 4.37,
    "JUN": 4.77,
    "JUL": 4.64,
    "AUG": 5.09,
    "SEP": 4.81,
    "OCT": 4.92,
    "NOV": 4.65,
    "DEC": 4.23
  },
  {
    "LONG": -5.06,
    "LAT": -3.84,
    "CIDADE": "Pacajá",
    "UF": "PARÁ",
    "anual": 4.56,
    "JAN": 4.13,
    "FEB": 4.31,
    "MAR": 4.43,
    "APR": 4.47,
    "MAY": 4.59,
    "JUN": 4.89,
    "JUL": 4.95,
    "AUG": 5.15,
    "SEP": 4.83,
    "OCT": 4.51,
    "NOV": 4.39,
    "DEC": 4.11
  },
  {
    "LONG": -49.68,
    "LAT": -3.77,
    "CIDADE": "Tucuruí",
    "UF": "PARÁ",
    "anual": 4.7,
    "JAN": 4.25,
    "FEB": 4.48,
    "MAR": 4.57,
    "APR": 4.72,
    "MAY": 4.72,
    "JUN": 5.02,
    "JUL": 5.11,
    "AUG": 5.35,
    "SEP": 5.02,
    "OCT": 4.61,
    "NOV": 4.42,
    "DEC": 4.14
  },
  {
    "LONG": -49.57,
    "LAT": -3.77,
    "CIDADE": "Breu Branco",
    "UF": "PARÁ",
    "anual": 4.66,
    "JAN": 4.22,
    "FEB": 4.47,
    "MAR": 4.56,
    "APR": 4.69,
    "MAY": 4.69,
    "JUN": 4.96,
    "JUL": 5.04,
    "AUG": 5.3,
    "SEP": 4.96,
    "OCT": 4.54,
    "NOV": 4.39,
    "DEC": 4.1
  },
  {
    "LONG": -49.1,
    "LAT": -3.84,
    "CIDADE": "Goianésia do Pará",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.28,
    "FEB": 4.5,
    "MAR": 4.66,
    "APR": 4.71,
    "MAY": 4.76,
    "JUN": 5.1,
    "JUL": 5.12,
    "AUG": 5.33,
    "SEP": 5.1,
    "OCT": 4.76,
    "NOV": 4.53,
    "DEC": 4.21
  },
  {
    "LONG": -53.74,
    "LAT": -3.72,
    "CIDADE": "Uruará",
    "UF": "PARÁ",
    "anual": 4.46,
    "JAN": 4.11,
    "FEB": 4.17,
    "MAR": 4.24,
    "APR": 4.09,
    "MAY": 4.38,
    "JUN": 4.66,
    "JUL": 4.59,
    "AUG": 5.02,
    "SEP": 4.79,
    "OCT": 4.77,
    "NOV": 4.56,
    "DEC": 4.11
  },
  {
    "LONG": -47.5,
    "LAT": -3.74,
    "CIDADE": "Ulianópolis",
    "UF": "PARÁ",
    "anual": 4.79,
    "JAN": 4.34,
    "FEB": 4.56,
    "MAR": 4.74,
    "APR": 4.81,
    "MAY": 4.81,
    "JUN": 4.99,
    "JUL": 5.02,
    "AUG": 5.2,
    "SEP": 5.17,
    "OCT": 4.83,
    "NOV": 4.59,
    "DEC": 4.39
  },
  {
    "LONG": -55.32,
    "LAT": -3.61,
    "CIDADE": "Aveiro",
    "UF": "PARÁ",
    "anual": 4.68,
    "JAN": 4.12,
    "FEB": 4.35,
    "MAR": 4.34,
    "APR": 4.21,
    "MAY": 4.33,
    "JUN": 4.77,
    "JUL": 4.74,
    "AUG": 5.34,
    "SEP": 5.24,
    "OCT": 5.27,
    "NOV": 5.01,
    "DEC": 4.51
  },
  {
    "LONG": -51.2,
    "LAT": -3.47,
    "CIDADE": "Anapu",
    "UF": "PARÁ",
    "anual": 4.49,
    "JAN": 4.2,
    "FEB": 4.26,
    "MAR": 4.38,
    "APR": 4.43,
    "MAY": 4.47,
    "JUN": 4.77,
    "JUL": 4.83,
    "AUG": 4.94,
    "SEP": 4.7,
    "OCT": 4.43,
    "NOV": 4.31,
    "DEC": 4.11
  },
  {
    "LONG": -52.89,
    "LAT": -3.45,
    "CIDADE": "Medicilândia",
    "UF": "PARÁ",
    "anual": 4.37,
    "JAN": 4.05,
    "FEB": 4.21,
    "MAR": 4.24,
    "APR": 4.22,
    "MAY": 4.27,
    "JUN": 4.56,
    "JUL": 4.55,
    "AUG": 4.79,
    "SEP": 4.56,
    "OCT": 4.54,
    "NOV": 4.4,
    "DEC": 4.01
  },
  {
    "LONG": -52.54,
    "LAT": -3.3,
    "CIDADE": "Brasil Novo",
    "UF": "PARÁ",
    "anual": 4.38,
    "JAN": 4.04,
    "FEB": 4.16,
    "MAR": 4.26,
    "APR": 4.31,
    "MAY": 4.34,
    "JUN": 4.62,
    "JUL": 4.58,
    "AUG": 4.81,
    "SEP": 4.59,
    "OCT": 4.48,
    "NOV": 4.37,
    "DEC": 4.06
  },
  {
    "LONG": -52.21,
    "LAT": -3.2,
    "CIDADE": "Altamira",
    "UF": "PARÁ",
    "anual": 4.44,
    "JAN": 4.06,
    "FEB": 4.27,
    "MAR": 4.32,
    "APR": 4.38,
    "MAY": 4.44,
    "JUN": 4.69,
    "JUL": 4.62,
    "AUG": 4.87,
    "SEP": 4.68,
    "OCT": 4.49,
    "NOV": 4.46,
    "DEC": 4.07
  },
  {
    "LONG": -47.35,
    "LAT": -3,
    "CIDADE": "Paragominas",
    "UF": "PARÁ",
    "anual": 4.89,
    "JAN": 4.51,
    "FEB": 4.63,
    "MAR": 4.7,
    "APR": 4.77,
    "MAY": 4.8,
    "JUN": 4.95,
    "JUL": 5.04,
    "AUG": 5.29,
    "SEP": 5.42,
    "OCT": 5.18,
    "NOV": 4.82,
    "DEC": 4.61
  },
  {
    "LONG": -52.01,
    "LAT": -2.89,
    "CIDADE": "Vitória do Xingu",
    "UF": "PARÁ",
    "anual": 4.61,
    "JAN": 4.26,
    "FEB": 4.37,
    "MAR": 4.4,
    "APR": 4.56,
    "MAY": 4.54,
    "JUN": 4.74,
    "JUL": 4.78,
    "AUG": 4.97,
    "SEP": 4.93,
    "OCT": 4.84,
    "NOV": 4.66,
    "DEC": 4.29
  },
  {
    "LONG": -48.95,
    "LAT": -2.95,
    "CIDADE": "Tailândia",
    "UF": "PARÁ",
    "anual": 4.78,
    "JAN": 4.36,
    "FEB": 4.47,
    "MAR": 4.66,
    "APR": 4.74,
    "MAY": 4.8,
    "JUN": 5,
    "JUL": 5.09,
    "AUG": 5.28,
    "SEP": 5.13,
    "OCT": 4.86,
    "NOV": 4.6,
    "DEC": 4.39
  },
  {
    "LONG": -49.67,
    "LAT": -2.79,
    "CIDADE": "Baião",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.38,
    "FEB": 4.53,
    "MAR": 4.54,
    "APR": 4.69,
    "MAY": 4.76,
    "JUN": 4.97,
    "JUL": 5.05,
    "AUG": 5.33,
    "SEP": 5.09,
    "OCT": 4.85,
    "NOV": 4.58,
    "DEC": 4.3
  },
  {
    "LONG": -54.65,
    "LAT": -2.68,
    "CIDADE": "Mojuí dos Campos",
    "UF": "PARÁ",
    "anual": 4.55,
    "JAN": 4.33,
    "FEB": 4.33,
    "MAR": 4.23,
    "APR": 4.15,
    "MAY": 4.26,
    "JUN": 4.54,
    "JUL": 4.47,
    "AUG": 4.98,
    "SEP": 4.87,
    "OCT": 5.1,
    "NOV": 4.91,
    "DEC": 4.39
  },
  {
    "LONG": -54.94,
    "LAT": -2.64,
    "CIDADE": "Belterra",
    "UF": "PARÁ",
    "anual": 4.63,
    "JAN": 4.36,
    "FEB": 4.4,
    "MAR": 4.34,
    "APR": 4.17,
    "MAY": 4.26,
    "JUN": 4.59,
    "JUL": 4.52,
    "AUG": 5.09,
    "SEP": 5.05,
    "OCT": 5.19,
    "NOV": 5.11,
    "DEC": 4.5
  },
  {
    "LONG": -51.95,
    "LAT": -2.59,
    "CIDADE": "Senador José Porfírio",
    "UF": "PARÁ",
    "anual": 4.56,
    "JAN": 4.19,
    "FEB": 4.29,
    "MAR": 4.34,
    "APR": 4.41,
    "MAY": 4.47,
    "JUN": 4.68,
    "JUL": 4.79,
    "AUG": 5.09,
    "SEP": 4.92,
    "OCT": 4.78,
    "NOV": 4.58,
    "DEC": 4.19
  },
  {
    "LONG": -49.5,
    "LAT": -2.58,
    "CIDADE": "Mocajuba",
    "UF": "PARÁ",
    "anual": 4.9,
    "JAN": 4.47,
    "FEB": 4.61,
    "MAR": 4.69,
    "APR": 4.76,
    "MAY": 4.88,
    "JUN": 5.04,
    "JUL": 5.13,
    "AUG": 5.4,
    "SEP": 5.29,
    "OCT": 5.11,
    "NOV": 4.88,
    "DEC": 4.49
  },
  {
    "LONG": -47.5,
    "LAT": -2.56,
    "CIDADE": "Ipixuna do Pará",
    "UF": "PARÁ",
    "anual": 4.89,
    "JAN": 4.5,
    "FEB": 4.57,
    "MAR": 4.58,
    "APR": 4.69,
    "MAY": 4.8,
    "JUN": 5.05,
    "JUL": 5.1,
    "AUG": 5.41,
    "SEP": 5.38,
    "OCT": 5.19,
    "NOV": 4.83,
    "DEC": 4.56
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.44,
    "CIDADE": "Santarém",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.58,
    "FEB": 4.7,
    "MAR": 4.61,
    "APR": 4.43,
    "MAY": 4.61,
    "JUN": 4.96,
    "JUL": 4.79,
    "AUG": 5.18,
    "SEP": 5.08,
    "OCT": 5.43,
    "NOV": 5.36,
    "DEC": 4.76
  },
  {
    "LONG": -48.15,
    "LAT": -2.42,
    "CIDADE": "Tomé-Açu",
    "UF": "PARÁ",
    "anual": 4.86,
    "JAN": 4.52,
    "FEB": 4.53,
    "MAR": 4.67,
    "APR": 4.71,
    "MAY": 4.76,
    "JUN": 5.01,
    "JUL": 5.1,
    "AUG": 5.34,
    "SEP": 5.28,
    "OCT": 5.04,
    "NOV": 4.8,
    "DEC": 4.58
  },
  {
    "LONG": -46.97,
    "LAT": -0.23,
    "CIDADE": "Nova Esperança do Piriá",
    "UF": "PARÁ",
    "anual": 4.93,
    "JAN": 4.5,
    "FEB": 4.56,
    "MAR": 4.58,
    "APR": 4.63,
    "MAY": 4.79,
    "JUN": 5.02,
    "JUL": 5.1,
    "AUG": 5.47,
    "SEP": 5.52,
    "OCT": 5.26,
    "NOV": 5.02,
    "DEC": 4.77
  },
  {
    "LONG": -56.74,
    "LAT": -2.17,
    "CIDADE": "Faro",
    "UF": "PARÁ",
    "anual": 4.65,
    "JAN": 4.1,
    "FEB": 4.16,
    "MAR": 4.21,
    "APR": 4.12,
    "MAY": 4.23,
    "JUN": 4.83,
    "JUL": 4.83,
    "AUG": 5.38,
    "SEP": 5.23,
    "OCT": 5.26,
    "NOV": 5.01,
    "DEC": 4.46
  },
  {
    "LONG": -56.09,
    "LAT": -2.16,
    "CIDADE": "Juruti",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.28,
    "FEB": 4.37,
    "MAR": 4.38,
    "APR": 4.15,
    "MAY": 4.25,
    "JUN": 4.75,
    "JUL": 4.71,
    "AUG": 5.33,
    "SEP": 5.3,
    "OCT": 5.41,
    "NOV": 5.19,
    "DEC": 4.65
  },
  {
    "LONG": -49.5,
    "LAT": -2.24,
    "CIDADE": "Cametá",
    "UF": "PARÁ",
    "anual": 5.01,
    "JAN": 4.56,
    "FEB": 4.64,
    "MAR": 4.74,
    "APR": 4.85,
    "MAY": 5,
    "JUN": 5.14,
    "JUL": 5.17,
    "AUG": 5.45,
    "SEP": 5.49,
    "OCT": 5.27,
    "NOV": 5.13,
    "DEC": 4.73
  },
  {
    "LONG": -56.49,
    "LAT": -2.1,
    "CIDADE": "Terra Santa",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.27,
    "FEB": 4.36,
    "MAR": 4.35,
    "APR": 4.23,
    "MAY": 4.35,
    "JUN": 4.9,
    "JUL": 4.84,
    "AUG": 5.3,
    "SEP": 5.35,
    "OCT": 5.38,
    "NOV": 5.17,
    "DEC": 4.62
  },
  {
    "LONG": -47.56,
    "LAT": -2.13,
    "CIDADE": "Aurora do Pará",
    "UF": "PARÁ",
    "anual": 4.86,
    "JAN": 4.47,
    "FEB": 4.52,
    "MAR": 4.6,
    "APR": 4.57,
    "MAY": 4.74,
    "JUN": 4.98,
    "JUL": 5.1,
    "AUG": 5.34,
    "SEP": 5.38,
    "OCT": 5.18,
    "NOV": 4.89,
    "DEC": 4.57
  },
  {
    "LONG": -54.07,
    "LAT": -2,
    "CIDADE": "Monte Alegre",
    "UF": "PARÁ",
    "anual": 4.97,
    "JAN": 4.82,
    "FEB": 4.73,
    "MAR": 4.78,
    "APR": 4.66,
    "MAY": 4.76,
    "JUN": 5.02,
    "JUL": 4.97,
    "AUG": 5.26,
    "SEP": 5.13,
    "OCT": 5.37,
    "NOV": 5.31,
    "DEC": 4.88
  },
  {
    "LONG": -49.86,
    "LAT": -0.2,
    "CIDADE": "Oeiras do Pará",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.56,
    "FEB": 4.53,
    "MAR": 4.61,
    "APR": 4.67,
    "MAY": 4.74,
    "JUN": 4.87,
    "JUL": 4.96,
    "AUG": 5.27,
    "SEP": 5.33,
    "OCT": 5.24,
    "NOV": 5.03,
    "DEC": 4.7
  },
  {
    "LONG": -48.96,
    "LAT": -1.98,
    "CIDADE": "Igarapé-Miri",
    "UF": "PARÁ",
    "anual": 4.84,
    "JAN": 4.43,
    "FEB": 4.49,
    "MAR": 4.56,
    "APR": 4.66,
    "MAY": 4.78,
    "JUN": 5.01,
    "JUL": 5.15,
    "AUG": 5.34,
    "SEP": 5.25,
    "OCT": 5.02,
    "NOV": 4.85,
    "DEC": 4.57
  },
  {
    "LONG": -48.2,
    "LAT": -1.96,
    "CIDADE": "Acará",
    "UF": "PARÁ",
    "anual": 4.91,
    "JAN": 4.57,
    "FEB": 4.5,
    "MAR": 4.6,
    "APR": 4.62,
    "MAY": 4.79,
    "JUN": 5,
    "JUL": 5.15,
    "AUG": 5.41,
    "SEP": 5.44,
    "OCT": 5.22,
    "NOV": 4.93,
    "DEC": 4.7
  },
  {
    "LONG": -47.94,
    "LAT": -1.99,
    "CIDADE": "Concórdia do Pará",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.52,
    "FEB": 4.53,
    "MAR": 4.59,
    "APR": 4.62,
    "MAY": 4.76,
    "JUN": 4.99,
    "JUL": 5.11,
    "AUG": 5.39,
    "SEP": 5.38,
    "OCT": 5.17,
    "NOV": 4.89,
    "DEC": 4.59
  },
  {
    "LONG": -47.56,
    "LAT": -2.05,
    "CIDADE": "Mãe do Rio",
    "UF": "PARÁ",
    "anual": 4.92,
    "JAN": 4.55,
    "FEB": 4.47,
    "MAR": 4.6,
    "APR": 4.62,
    "MAY": 4.83,
    "JUN": 5.05,
    "JUL": 5.17,
    "AUG": 5.42,
    "SEP": 5.47,
    "OCT": 5.24,
    "NOV": 4.97,
    "DEC": 4.66
  },
  {
    "LONG": -55.52,
    "LAT": -1.9,
    "CIDADE": "Óbidos",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.27,
    "FEB": 4.42,
    "MAR": 4.39,
    "APR": 4.32,
    "MAY": 4.42,
    "JUN": 4.88,
    "JUL": 4.78,
    "AUG": 5.36,
    "SEP": 5.26,
    "OCT": 5.33,
    "NOV": 5.09,
    "DEC": 4.58
  },
  {
    "LONG": -55.12,
    "LAT": -1.89,
    "CIDADE": "Curuá",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.47,
    "FEB": 4.58,
    "MAR": 4.51,
    "APR": 4.48,
    "MAY": 4.65,
    "JUN": 4.99,
    "JUL": 4.88,
    "AUG": 5.38,
    "SEP": 5.33,
    "OCT": 5.41,
    "NOV": 5.21,
    "DEC": 4.67
  },
  {
    "LONG": -54.74,
    "LAT": -1.95,
    "CIDADE": "Alenquer",
    "UF": "PARÁ",
    "anual": 4.75,
    "JAN": 4.45,
    "FEB": 4.55,
    "MAR": 4.43,
    "APR": 4.34,
    "MAY": 4.5,
    "JUN": 4.74,
    "JUL": 4.74,
    "AUG": 5.25,
    "SEP": 5.21,
    "OCT": 5.2,
    "NOV": 5.08,
    "DEC": 4.52
  },
  {
    "LONG": -50.82,
    "LAT": -1.94,
    "CIDADE": "Portel",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.53,
    "FEB": 4.46,
    "MAR": 4.59,
    "APR": 4.63,
    "MAY": 4.77,
    "JUN": 4.99,
    "JUL": 5.06,
    "AUG": 5.31,
    "SEP": 5.31,
    "OCT": 5.1,
    "NOV": 4.99,
    "DEC": 4.65
  },
  {
    "LONG": -50.2,
    "LAT": -1.9,
    "CIDADE": "Bagre",
    "UF": "PARÁ",
    "anual": 5.08,
    "JAN": 4.74,
    "FEB": 4.69,
    "MAR": 4.8,
    "APR": 4.83,
    "MAY": 4.93,
    "JUN": 5.07,
    "JUL": 5.16,
    "AUG": 5.46,
    "SEP": 5.51,
    "OCT": 5.46,
    "NOV": 5.32,
    "DEC": 4.93
  },
  {
    "LONG": -49.38,
    "LAT": -1.9,
    "CIDADE": "Limoeiro do Ajuru",
    "UF": "PARÁ",
    "anual": 5.15,
    "JAN": 4.72,
    "FEB": 4.74,
    "MAR": 4.87,
    "APR": 4.99,
    "MAY": 5.08,
    "JUN": 5.24,
    "JUL": 5.28,
    "AUG": 5.5,
    "SEP": 5.59,
    "OCT": 5.49,
    "NOV": 5.31,
    "DEC": 4.95
  },
  {
    "LONG": -48.77,
    "LAT": -1.89,
    "CIDADE": "Moju",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.44,
    "FEB": 4.48,
    "MAR": 4.56,
    "APR": 4.63,
    "MAY": 4.79,
    "JUN": 5.01,
    "JUL": 5.14,
    "AUG": 5.36,
    "SEP": 5.31,
    "OCT": 5.17,
    "NOV": 4.95,
    "DEC": 4.66
  },
  {
    "LONG": -47.05,
    "LAT": -1.93,
    "CIDADE": "Garrafão do Norte",
    "UF": "PARÁ",
    "anual": 4.94,
    "JAN": 4.58,
    "FEB": 4.42,
    "MAR": 4.54,
    "APR": 4.57,
    "MAY": 4.73,
    "JUN": 5.02,
    "JUL": 5.16,
    "AUG": 5.49,
    "SEP": 5.58,
    "OCT": 5.32,
    "NOV": 5.1,
    "DEC": 4.7
  },
  {
    "LONG": -55.86,
    "LAT": -1.76,
    "CIDADE": "Oriximiná",
    "UF": "PARÁ",
    "anual": 4.66,
    "JAN": 4.2,
    "FEB": 4.26,
    "MAR": 4.26,
    "APR": 4.12,
    "MAY": 4.3,
    "JUN": 4.76,
    "JUL": 4.75,
    "AUG": 5.28,
    "SEP": 5.21,
    "OCT": 5.23,
    "NOV": 5.05,
    "DEC": 4.5
  },
  {
    "LONG": -53.48,
    "LAT": -1.79,
    "CIDADE": "Prainha",
    "UF": "PARÁ",
    "anual": 4.91,
    "JAN": 4.6,
    "FEB": 4.62,
    "MAR": 4.69,
    "APR": 4.65,
    "MAY": 4.77,
    "JUN": 5.04,
    "JUL": 5,
    "AUG": 5.26,
    "SEP": 5.11,
    "OCT": 5.32,
    "NOV": 5.13,
    "DEC": 4.69
  },
  {
    "LONG": -50.72,
    "LAT": -1.8,
    "CIDADE": "Melgaço",
    "UF": "PARÁ",
    "anual": 4.85,
    "JAN": 4.51,
    "FEB": 4.4,
    "MAR": 4.51,
    "APR": 4.54,
    "MAY": 4.75,
    "JUN": 4.99,
    "JUL": 5.11,
    "AUG": 5.32,
    "SEP": 5.3,
    "OCT": 5.12,
    "NOV": 4.99,
    "DEC": 4.66
  },
  {
    "LONG": -49.8,
    "LAT": -1.81,
    "CIDADE": "Curralinho",
    "UF": "PARÁ",
    "anual": 5.08,
    "JAN": 4.58,
    "FEB": 4.6,
    "MAR": 4.78,
    "APR": 4.78,
    "MAY": 5.06,
    "JUN": 5.23,
    "JUL": 5.19,
    "AUG": 5.43,
    "SEP": 5.6,
    "OCT": 5.52,
    "NOV": 5.31,
    "DEC": 4.86
  },
  {
    "LONG": -47.45,
    "LAT": -1.77,
    "CIDADE": "Irituia",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.45,
    "FEB": 4.47,
    "MAR": 4.44,
    "APR": 4.56,
    "MAY": 4.71,
    "JUN": 5.03,
    "JUL": 5.13,
    "AUG": 5.4,
    "SEP": 5.45,
    "OCT": 5.17,
    "NOV": 5.01,
    "DEC": 4.55
  },
  {
    "LONG": -46.55,
    "LAT": -1.76,
    "CIDADE": "Cachoeira do Piriá",
    "UF": "PARÁ",
    "anual": 4.79,
    "JAN": 4.43,
    "FEB": 4.32,
    "MAR": 4.32,
    "APR": 4.43,
    "MAY": 4.61,
    "JUN": 4.86,
    "JUL": 5.02,
    "AUG": 5.37,
    "SEP": 5.52,
    "OCT": 5.19,
    "NOV": 4.95,
    "DEC": 4.52
  },
  {
    "LONG": -52.24,
    "LAT": -1.75,
    "CIDADE": "Porto de Moz",
    "UF": "PARÁ",
    "anual": 4.72,
    "JAN": 4.42,
    "FEB": 4.48,
    "MAR": 4.48,
    "APR": 4.49,
    "MAY": 4.55,
    "JUN": 4.73,
    "JUL": 4.75,
    "AUG": 5.09,
    "SEP": 5.14,
    "OCT": 5.08,
    "NOV": 4.91,
    "DEC": 4.51
  },
  {
    "LONG": -50.48,
    "LAT": -1.68,
    "CIDADE": "Breves",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.35,
    "FEB": 4.29,
    "MAR": 4.3,
    "APR": 4.44,
    "MAY": 4.65,
    "JUN": 4.9,
    "JUL": 4.98,
    "AUG": 5.27,
    "SEP": 5.15,
    "OCT": 5.03,
    "NOV": 4.92,
    "DEC": 4.46
  },
  {
    "LONG": -49.53,
    "LAT": -1.72,
    "CIDADE": "São Sebastião da Boa Vista",
    "UF": "PARÁ",
    "anual": 5.05,
    "JAN": 4.58,
    "FEB": 4.55,
    "MAR": 4.69,
    "APR": 4.71,
    "MAY": 5.05,
    "JUN": 5.13,
    "JUL": 5.23,
    "AUG": 5.37,
    "SEP": 5.57,
    "OCT": 5.46,
    "NOV": 5.34,
    "DEC": 4.88
  },
  {
    "LONG": -48.88,
    "LAT": -1.72,
    "CIDADE": "Abaetetuba",
    "UF": "PARÁ",
    "anual": 4.9,
    "JAN": 4.5,
    "FEB": 4.41,
    "MAR": 4.53,
    "APR": 4.57,
    "MAY": 4.8,
    "JUN": 5.01,
    "JUL": 5.14,
    "AUG": 5.4,
    "SEP": 5.42,
    "OCT": 5.26,
    "NOV": 5.04,
    "DEC": 4.7
  },
  {
    "LONG": -47.77,
    "LAT": -1.69,
    "CIDADE": "São Domingos do Capim",
    "UF": "PARÁ",
    "anual": 4.9,
    "JAN": 4.53,
    "FEB": 4.49,
    "MAR": 4.47,
    "APR": 4.57,
    "MAY": 4.78,
    "JUN": 5.06,
    "JUL": 5.15,
    "AUG": 5.39,
    "SEP": 5.44,
    "OCT": 5.29,
    "NOV": 5.03,
    "DEC": 4.61
  },
  {
    "LONG": -47.06,
    "LAT": -1.75,
    "CIDADE": "Capitão Poço",
    "UF": "PARÁ",
    "anual": 4.86,
    "JAN": 4.54,
    "FEB": 4.37,
    "MAR": 4.45,
    "APR": 4.47,
    "MAY": 4.62,
    "JUN": 4.97,
    "JUL": 5.11,
    "AUG": 5.39,
    "SEP": 5.51,
    "OCT": 5.26,
    "NOV": 4.99,
    "DEC": 4.59
  },
  {
    "LONG": -47.48,
    "LAT": -1.61,
    "CIDADE": "São Miguel do Guamá",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.51,
    "FEB": 4.38,
    "MAR": 4.38,
    "APR": 4.52,
    "MAY": 4.71,
    "JUN": 5.07,
    "JUL": 5.15,
    "AUG": 5.45,
    "SEP": 5.53,
    "OCT": 5.22,
    "NOV": 5.02,
    "DEC": 4.6
  },
  {
    "LONG": -5.26,
    "LAT": -1.52,
    "CIDADE": "Almeirim",
    "UF": "PARÁ",
    "anual": 4.8,
    "JAN": 4.47,
    "FEB": 4.55,
    "MAR": 4.57,
    "APR": 4.56,
    "MAY": 4.73,
    "JUN": 4.96,
    "JUL": 4.91,
    "AUG": 5.12,
    "SEP": 5.08,
    "OCT": 5.14,
    "NOV": 4.98,
    "DEC": 4.56
  },
  {
    "LONG": -49.22,
    "LAT": -1.54,
    "CIDADE": "Muaná",
    "UF": "PARÁ",
    "anual": 4.88,
    "JAN": 4.43,
    "FEB": 4.36,
    "MAR": 4.47,
    "APR": 4.53,
    "MAY": 4.79,
    "JUN": 4.93,
    "JUL": 5.09,
    "AUG": 5.34,
    "SEP": 5.38,
    "OCT": 5.35,
    "NOV": 5.19,
    "DEC": 4.75
  },
  {
    "LONG": -48.62,
    "LAT": -1.51,
    "CIDADE": "Barcarena",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.38,
    "FEB": 4.4,
    "MAR": 4.49,
    "APR": 4.62,
    "MAY": 4.78,
    "JUN": 5.01,
    "JUL": 5.11,
    "AUG": 5.33,
    "SEP": 5.39,
    "OCT": 5.22,
    "NOV": 5.11,
    "DEC": 4.65
  },
  {
    "LONG": -48.49,
    "LAT": -1.46,
    "CIDADE": "Belém",
    "UF": "PARÁ",
    "anual": 4.87,
    "JAN": 4.4,
    "FEB": 4.38,
    "MAR": 4.41,
    "APR": 4.57,
    "MAY": 4.76,
    "JUN": 5.03,
    "JUL": 5.13,
    "AUG": 5.34,
    "SEP": 5.39,
    "OCT": 5.22,
    "NOV": 5.07,
    "DEC": 4.7
  },
  {
    "LONG": -48.04,
    "LAT": -0.15,
    "CIDADE": "Bujaru",
    "UF": "PARÁ",
    "anual": 4.85,
    "JAN": 4.38,
    "FEB": 4.35,
    "MAR": 4.38,
    "APR": 4.41,
    "MAY": 4.74,
    "JUN": 5.06,
    "JUL": 5.17,
    "AUG": 5.46,
    "SEP": 5.44,
    "OCT": 5.27,
    "NOV": 4.95,
    "DEC": 4.58
  },
  {
    "LONG": -47.12,
    "LAT": -1.55,
    "CIDADE": "Ourém",
    "UF": "PARÁ",
    "anual": 4.81,
    "JAN": 4.39,
    "FEB": 4.29,
    "MAR": 4.31,
    "APR": 4.4,
    "MAY": 4.62,
    "JUN": 4.92,
    "JUL": 5.08,
    "AUG": 5.33,
    "SEP": 5.52,
    "OCT": 5.21,
    "NOV": 4.99,
    "DEC": 4.63
  },
  {
    "LONG": -46.9,
    "LAT": -1.52,
    "CIDADE": "Santa Luzia do Pará",
    "UF": "PARÁ",
    "anual": 4.8,
    "JAN": 4.44,
    "FEB": 4.27,
    "MAR": 4.31,
    "APR": 4.37,
    "MAY": 4.57,
    "JUN": 4.87,
    "JUL": 5.07,
    "AUG": 5.32,
    "SEP": 5.49,
    "OCT": 5.24,
    "NOV": 5,
    "DEC": 4.62
  },
  {
    "LONG": -51.63,
    "LAT": -1.41,
    "CIDADE": "Gurupá",
    "UF": "PARÁ",
    "anual": 4.79,
    "JAN": 4.47,
    "FEB": 4.37,
    "MAR": 4.38,
    "APR": 4.45,
    "MAY": 4.67,
    "JUN": 4.89,
    "JUL": 4.89,
    "AUG": 5.2,
    "SEP": 5.3,
    "OCT": 5.17,
    "NOV": 5.13,
    "DEC": 4.61
  },
  {
    "LONG": -48.87,
    "LAT": -1.4,
    "CIDADE": "Ponta de Pedras",
    "UF": "PARÁ",
    "anual": 5.15,
    "JAN": 4.76,
    "FEB": 4.63,
    "MAR": 4.73,
    "APR": 4.83,
    "MAY": 5.04,
    "JUN": 5.23,
    "JUL": 5.28,
    "AUG": 5.54,
    "SEP": 5.68,
    "OCT": 5.55,
    "NOV": 5.44,
    "DEC": 5.09
  },
  {
    "LONG": -4.83,
    "LAT": -1.36,
    "CIDADE": "Marituba",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.25,
    "FEB": 4.26,
    "MAR": 4.27,
    "APR": 4.33,
    "MAY": 4.64,
    "JUN": 4.99,
    "JUL": 5.09,
    "AUG": 5.26,
    "SEP": 5.29,
    "OCT": 5.04,
    "NOV": 4.88,
    "DEC": 4.49
  },
  {
    "LONG": -48.37,
    "LAT": -1.36,
    "CIDADE": "Ananindeua",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.25,
    "FEB": 4.26,
    "MAR": 4.27,
    "APR": 4.33,
    "MAY": 4.64,
    "JUN": 4.99,
    "JUL": 5.09,
    "AUG": 5.26,
    "SEP": 5.29,
    "OCT": 5.04,
    "NOV": 4.88,
    "DEC": 4.49
  },
  {
    "LONG": -48.24,
    "LAT": -1.36,
    "CIDADE": "Benevides",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.28,
    "FEB": 4.23,
    "MAR": 4.29,
    "APR": 4.36,
    "MAY": 4.64,
    "JUN": 4.97,
    "JUL": 5.11,
    "AUG": 5.33,
    "SEP": 5.35,
    "OCT": 5.1,
    "NOV": 4.92,
    "DEC": 4.5
  },
  {
    "LONG": -47.91,
    "LAT": -1.43,
    "CIDADE": "Inhangapi",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.33,
    "FEB": 4.26,
    "MAR": 4.31,
    "APR": 4.33,
    "MAY": 4.64,
    "JUN": 4.94,
    "JUL": 5.05,
    "AUG": 5.33,
    "SEP": 5.4,
    "OCT": 5.18,
    "NOV": 4.92,
    "DEC": 4.48
  },
  {
    "LONG": -47.31,
    "LAT": -1.37,
    "CIDADE": "Bonito",
    "UF": "PARÁ",
    "anual": 4.78,
    "JAN": 4.35,
    "FEB": 4.22,
    "MAR": 4.26,
    "APR": 4.36,
    "MAY": 4.6,
    "JUN": 4.89,
    "JUL": 5.05,
    "AUG": 5.34,
    "SEP": 5.47,
    "OCT": 5.21,
    "NOV": 4.98,
    "DEC": 4.58
  },
  {
    "LONG": -4.82,
    "LAT": -1.3,
    "CIDADE": "Santa Izabel do Pará",
    "UF": "PARÁ",
    "anual": 4.71,
    "JAN": 4.29,
    "FEB": 4.17,
    "MAR": 4.16,
    "APR": 4.31,
    "MAY": 4.58,
    "JUN": 4.91,
    "JUL": 5.03,
    "AUG": 5.27,
    "SEP": 5.37,
    "OCT": 5.1,
    "NOV": 4.86,
    "DEC": 4.51
  },
  {
    "LONG": -47.92,
    "LAT": -1.3,
    "CIDADE": "Castanhal",
    "UF": "PARÁ",
    "anual": 4.71,
    "JAN": 4.33,
    "FEB": 4.18,
    "MAR": 4.26,
    "APR": 4.33,
    "MAY": 4.6,
    "JUN": 4.89,
    "JUL": 5,
    "AUG": 5.23,
    "SEP": 5.28,
    "OCT": 5.09,
    "NOV": 4.87,
    "DEC": 4.47
  },
  {
    "LONG": -47.57,
    "LAT": -1.35,
    "CIDADE": "Santa Maria do Pará",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.31,
    "FEB": 4.19,
    "MAR": 4.24,
    "APR": 4.35,
    "MAY": 4.64,
    "JUN": 4.9,
    "JUL": 5.02,
    "AUG": 5.29,
    "SEP": 5.43,
    "OCT": 5.21,
    "NOV": 4.95,
    "DEC": 4.56
  },
  {
    "LONG": -48.3,
    "LAT": -1.23,
    "CIDADE": "Santa Bárbara do Pará",
    "UF": "PARÁ",
    "anual": 4.68,
    "JAN": 4.26,
    "FEB": 4.15,
    "MAR": 4.12,
    "APR": 4.24,
    "MAY": 4.52,
    "JUN": 4.87,
    "JUL": 4.99,
    "AUG": 5.25,
    "SEP": 5.31,
    "OCT": 5.12,
    "NOV": 4.9,
    "DEC": 4.48
  },
  {
    "LONG": -48.13,
    "LAT": -1.15,
    "CIDADE": "Santo Antônio do Tauá",
    "UF": "PARÁ",
    "anual": 4.69,
    "JAN": 4.28,
    "FEB": 4.14,
    "MAR": 4.08,
    "APR": 4.24,
    "MAY": 4.53,
    "JUN": 4.89,
    "JUL": 5.02,
    "AUG": 5.25,
    "SEP": 5.32,
    "OCT": 5.13,
    "NOV": 4.86,
    "DEC": 4.48
  },
  {
    "LONG": -47.79,
    "LAT": -1.17,
    "CIDADE": "São Francisco do Pará",
    "UF": "PARÁ",
    "anual": 4.74,
    "JAN": 4.31,
    "FEB": 4.2,
    "MAR": 4.2,
    "APR": 4.3,
    "MAY": 4.61,
    "JUN": 4.86,
    "JUL": 5.05,
    "AUG": 5.25,
    "SEP": 5.37,
    "OCT": 5.24,
    "NOV": 5,
    "DEC": 4.53
  },
  {
    "LONG": -47.32,
    "LAT": -1.19,
    "CIDADE": "Peixe-Boi",
    "UF": "PARÁ",
    "anual": 4.78,
    "JAN": 4.35,
    "FEB": 4.2,
    "MAR": 4.21,
    "APR": 4.29,
    "MAY": 4.58,
    "JUN": 4.86,
    "JUL": 4.96,
    "AUG": 5.27,
    "SEP": 5.51,
    "OCT": 5.31,
    "NOV": 5.09,
    "DEC": 4.67
  },
  {
    "LONG": -47.39,
    "LAT": -1.2,
    "CIDADE": "Nova Timboteua",
    "UF": "PARÁ",
    "anual": 4.78,
    "JAN": 4.35,
    "FEB": 4.2,
    "MAR": 4.21,
    "APR": 4.29,
    "MAY": 4.58,
    "JUN": 4.86,
    "JUL": 4.96,
    "AUG": 5.27,
    "SEP": 5.51,
    "OCT": 5.31,
    "NOV": 5.09,
    "DEC": 4.67
  },
  {
    "LONG": -47.18,
    "LAT": -1.21,
    "CIDADE": "Capanema",
    "UF": "PARÁ",
    "anual": 4.75,
    "JAN": 4.37,
    "FEB": 4.19,
    "MAR": 4.16,
    "APR": 4.28,
    "MAY": 4.55,
    "JUN": 4.8,
    "JUL": 4.93,
    "AUG": 5.25,
    "SEP": 5.51,
    "OCT": 5.27,
    "NOV": 5.03,
    "DEC": 4.67
  },
  {
    "LONG": -46.14,
    "LAT": -1.2,
    "CIDADE": "Viseu",
    "UF": "PARÁ",
    "anual": 4.93,
    "JAN": 4.74,
    "FEB": 4.47,
    "MAR": 4.32,
    "APR": 4.29,
    "MAY": 4.57,
    "JUN": 4.83,
    "JUL": 4.96,
    "AUG": 5.33,
    "SEP": 5.68,
    "OCT": 5.5,
    "NOV": 5.4,
    "DEC": 5.06
  },
  {
    "LONG": -47.62,
    "LAT": -1.13,
    "CIDADE": "Igarapé-Açu",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.32,
    "FEB": 4.18,
    "MAR": 4.13,
    "APR": 4.28,
    "MAY": 4.57,
    "JUN": 4.84,
    "JUL": 4.98,
    "AUG": 5.23,
    "SEP": 5.41,
    "OCT": 5.21,
    "NOV": 5.02,
    "DEC": 4.58
  },
  {
    "LONG": -46.9,
    "LAT": -1.08,
    "CIDADE": "Tracuateua",
    "UF": "PARÁ",
    "anual": 4.8,
    "JAN": 4.5,
    "FEB": 4.3,
    "MAR": 4.14,
    "APR": 4.23,
    "MAY": 4.51,
    "JUN": 4.83,
    "JUL": 4.92,
    "AUG": 5.26,
    "SEP": 5.54,
    "OCT": 5.34,
    "NOV": 5.17,
    "DEC": 4.82
  },
  {
    "LONG": -4.68,
    "LAT": -0.11,
    "CIDADE": "Bragança",
    "UF": "PARÁ",
    "anual": 4.84,
    "JAN": 4.62,
    "FEB": 4.33,
    "MAR": 4.18,
    "APR": 4.21,
    "MAY": 4.51,
    "JUN": 4.79,
    "JUL": 4.9,
    "AUG": 5.29,
    "SEP": 5.57,
    "OCT": 5.44,
    "NOV": 5.27,
    "DEC": 4.94
  },
  {
    "LONG": -49.94,
    "LAT": "#VALOR!",
    "CIDADE": "Anajás",
    "UF": "PARÁ",
    "anual": 4.66,
    "JAN": 4.19,
    "FEB": 4.09,
    "MAR": 4.12,
    "APR": 4.19,
    "MAY": 4.59,
    "JUN": 4.76,
    "JUL": 5,
    "AUG": 5.18,
    "SEP": 5.25,
    "OCT": 5.12,
    "NOV": 5.03,
    "DEC": 4.45
  },
  {
    "LONG": -48.96,
    "LAT": -1.01,
    "CIDADE": "Cachoeira do Arari",
    "UF": "PARÁ",
    "anual": 4.83,
    "JAN": 4.41,
    "FEB": 4.28,
    "MAR": 4.36,
    "APR": 4.45,
    "MAY": 4.86,
    "JUN": 4.97,
    "JUL": 5.1,
    "AUG": 5.26,
    "SEP": 5.46,
    "OCT": 5.19,
    "NOV": 5.04,
    "DEC": 4.62
  },
  {
    "LONG": -47.91,
    "LAT": -0.1,
    "CIDADE": "Terra Alta",
    "UF": "PARÁ",
    "anual": 4.69,
    "JAN": 4.31,
    "FEB": 4.04,
    "MAR": 4.01,
    "APR": 4.18,
    "MAY": 4.51,
    "JUN": 4.85,
    "JUL": 4.95,
    "AUG": 5.22,
    "SEP": 5.38,
    "OCT": 5.19,
    "NOV": 5.03,
    "DEC": 4.58
  },
  {
    "LONG": -46.65,
    "LAT": -0.1,
    "CIDADE": "Augusto Corrêa",
    "UF": "PARÁ",
    "anual": 4.96,
    "JAN": 4.79,
    "FEB": 4.5,
    "MAR": 4.29,
    "APR": 4.36,
    "MAY": 4.62,
    "JUN": 4.85,
    "JUL": 4.98,
    "AUG": 5.36,
    "SEP": 5.68,
    "OCT": 5.54,
    "NOV": 5.44,
    "DEC": 5.1
  },
  {
    "LONG": -48.28,
    "LAT": "#VALOR!",
    "CIDADE": "Colares",
    "UF": "PARÁ",
    "anual": 4.7,
    "JAN": 4.32,
    "FEB": 4.12,
    "MAR": 4.09,
    "APR": 4.16,
    "MAY": 4.48,
    "JUN": 4.73,
    "JUL": 4.85,
    "AUG": 5.13,
    "SEP": 5.4,
    "OCT": 5.31,
    "NOV": 5.2,
    "DEC": 4.67
  },
  {
    "LONG": -4.81,
    "LAT": "#VALOR!",
    "CIDADE": "Vigia",
    "UF": "PARÁ",
    "anual": 4.63,
    "JAN": 4.25,
    "FEB": 4.02,
    "MAR": 3.98,
    "APR": 4.07,
    "MAY": 4.43,
    "JUN": 4.7,
    "JUL": 4.84,
    "AUG": 5.16,
    "SEP": 5.34,
    "OCT": 5.17,
    "NOV": 5.04,
    "DEC": 4.49
  },
  {
    "LONG": -4.79,
    "LAT": "#VALOR!",
    "CIDADE": "São João da Ponta",
    "UF": "PARÁ",
    "anual": 4.68,
    "JAN": 4.29,
    "FEB": 4.04,
    "MAR": 3.99,
    "APR": 4.14,
    "MAY": 4.44,
    "JUN": 4.79,
    "JUL": 4.89,
    "AUG": 5.2,
    "SEP": 5.42,
    "OCT": 5.28,
    "NOV": 5.05,
    "DEC": 4.63
  },
  {
    "LONG": -47.4,
    "LAT": "#VALOR!",
    "CIDADE": "Santarém Novo",
    "UF": "PARÁ",
    "anual": 4.8,
    "JAN": 4.56,
    "FEB": 4.25,
    "MAR": 4.2,
    "APR": 4.21,
    "MAY": 4.5,
    "JUN": 4.79,
    "JUL": 4.94,
    "AUG": 5.23,
    "SEP": 5.5,
    "OCT": 5.36,
    "NOV": 5.19,
    "DEC": 4.91
  },
  {
    "LONG": -47.13,
    "LAT": "#VALOR!",
    "CIDADE": "Primavera",
    "UF": "PARÁ",
    "anual": 4.83,
    "JAN": 4.57,
    "FEB": 4.34,
    "MAR": 4.22,
    "APR": 4.24,
    "MAY": 4.51,
    "JUN": 4.84,
    "JUL": 4.91,
    "AUG": 5.22,
    "SEP": 5.52,
    "OCT": 5.39,
    "NOV": 5.24,
    "DEC": 4.94
  },
  {
    "LONG": -4.7,
    "LAT": "#VALOR!",
    "CIDADE": "Quatipuru",
    "UF": "PARÁ",
    "anual": 4.86,
    "JAN": 4.61,
    "FEB": 4.39,
    "MAR": 4.25,
    "APR": 4.28,
    "MAY": 4.51,
    "JUN": 4.81,
    "JUL": 4.93,
    "AUG": 5.27,
    "SEP": 5.54,
    "OCT": 5.45,
    "NOV": 5.29,
    "DEC": 5.01
  },
  {
    "LONG": -48.51,
    "LAT": "#VALOR!",
    "CIDADE": "Salvaterra",
    "UF": "PARÁ",
    "anual": 5.05,
    "JAN": 4.67,
    "FEB": 4.37,
    "MAR": 4.41,
    "APR": 4.49,
    "MAY": 4.87,
    "JUN": 5.08,
    "JUL": 5.13,
    "AUG": 5.48,
    "SEP": 5.71,
    "OCT": 5.65,
    "NOV": 5.58,
    "DEC": 5.12
  },
  {
    "LONG": -4.76,
    "LAT": "#VALOR!",
    "CIDADE": "Magalhães Barata",
    "UF": "PARÁ",
    "anual": 4.76,
    "JAN": 4.49,
    "FEB": 4.22,
    "MAR": 4.15,
    "APR": 4.18,
    "MAY": 4.5,
    "JUN": 4.77,
    "JUL": 4.86,
    "AUG": 5.22,
    "SEP": 5.46,
    "OCT": 5.32,
    "NOV": 5.16,
    "DEC": 4.81
  },
  {
    "LONG": -47.45,
    "LAT": "#VALOR!",
    "CIDADE": "Maracanã",
    "UF": "PARÁ",
    "anual": 4.84,
    "JAN": 4.59,
    "FEB": 4.3,
    "MAR": 4.2,
    "APR": 4.23,
    "MAY": 4.55,
    "JUN": 4.81,
    "JUL": 4.96,
    "AUG": 5.27,
    "SEP": 5.5,
    "OCT": 5.39,
    "NOV": 5.27,
    "DEC": 4.97
  },
  {
    "LONG": -47.18,
    "LAT": "#VALOR!",
    "CIDADE": "São João de Pirabas",
    "UF": "PARÁ",
    "anual": 4.95,
    "JAN": 4.73,
    "FEB": 4.47,
    "MAR": 4.31,
    "APR": 4.36,
    "MAY": 4.69,
    "JUN": 4.92,
    "JUL": 5.03,
    "AUG": 5.32,
    "SEP": 5.59,
    "OCT": 5.48,
    "NOV": 5.36,
    "DEC": 5.08
  },
  {
    "LONG": -49.18,
    "LAT": "#VALOR!",
    "CIDADE": "Santa Cruz do Arari",
    "UF": "PARÁ",
    "anual": 4.73,
    "JAN": 4.3,
    "FEB": 4.12,
    "MAR": 4.24,
    "APR": 4.32,
    "MAY": 4.71,
    "JUN": 4.94,
    "JUL": 5.08,
    "AUG": 5.21,
    "SEP": 5.3,
    "OCT": 5.08,
    "NOV": 4.97,
    "DEC": 4.51
  },
  {
    "LONG": -48.52,
    "LAT": "#VALOR!",
    "CIDADE": "Soure",
    "UF": "PARÁ",
    "anual": 5.03,
    "JAN": 4.7,
    "FEB": 4.34,
    "MAR": 4.38,
    "APR": 4.48,
    "MAY": 4.88,
    "JUN": 5.11,
    "JUL": 5.19,
    "AUG": 5.4,
    "SEP": 5.67,
    "OCT": 5.58,
    "NOV": 5.51,
    "DEC": 5.12
  },
  {
    "LONG": -48.02,
    "LAT": "#VALOR!",
    "CIDADE": "São Caetano de Odivelas",
    "UF": "PARÁ",
    "anual": 4.91,
    "JAN": 4.65,
    "FEB": 4.24,
    "MAR": 4.21,
    "APR": 4.29,
    "MAY": 4.52,
    "JUN": 4.84,
    "JUL": 4.97,
    "AUG": 5.39,
    "SEP": 5.65,
    "OCT": 5.59,
    "NOV": 5.52,
    "DEC": 5.11
  },
  {
    "LONG": -47.85,
    "LAT": "#VALOR!",
    "CIDADE": "Curuçá",
    "UF": "PARÁ",
    "anual": 4.82,
    "JAN": 4.57,
    "FEB": 4.24,
    "MAR": 4.13,
    "APR": 4.22,
    "MAY": 4.51,
    "JUN": 4.83,
    "JUL": 4.94,
    "AUG": 5.27,
    "SEP": 5.49,
    "OCT": 5.41,
    "NOV": 5.32,
    "DEC": 4.96
  },
  {
    "LONG": -47.7,
    "LAT": "#VALOR!",
    "CIDADE": "Marapanim",
    "UF": "PARÁ",
    "anual": 4.93,
    "JAN": 4.72,
    "FEB": 4.34,
    "MAR": 4.26,
    "APR": 4.3,
    "MAY": 4.62,
    "JUN": 4.89,
    "JUL": 5.04,
    "AUG": 5.37,
    "SEP": 5.6,
    "OCT": 5.53,
    "NOV": 5.45,
    "DEC": 5.07
  },
  {
    "LONG": -47.35,
    "LAT": "#VALOR!",
    "CIDADE": "Salinópolis",
    "UF": "PARÁ",
    "anual": 5.06,
    "JAN": 4.92,
    "FEB": 4.52,
    "MAR": 4.45,
    "APR": 4.52,
    "MAY": 4.82,
    "JUN": 5.13,
    "JUL": 5.24,
    "AUG": 5.25,
    "SEP": 5.66,
    "OCT": 5.53,
    "NOV": 5.48,
    "DEC": 5.2
  },
  {
    "LONG": -50.39,
    "LAT": "#VALOR!",
    "CIDADE": "Afuá",
    "UF": "PARÁ",
    "anual": 4.7,
    "JAN": 4.38,
    "FEB": 4.06,
    "MAR": 3.96,
    "APR": 3.99,
    "MAY": 4.43,
    "JUN": 4.69,
    "JUL": 4.85,
    "AUG": 5.25,
    "SEP": 5.5,
    "OCT": 5.39,
    "NOV": 5.22,
    "DEC": 4.69
  },
  {
    "LONG": -49.99,
    "LAT": "#VALOR!",
    "CIDADE": "Chaves",
    "UF": "PARÁ",
    "anual": 4.82,
    "JAN": 4.49,
    "FEB": 4.08,
    "MAR": 4,
    "APR": 4.05,
    "MAY": 4.54,
    "JUN": 4.92,
    "JUL": 5.08,
    "AUG": 5.39,
    "SEP": 5.62,
    "OCT": 5.5,
    "NOV": 5.34,
    "DEC": 4.78
  },
  {
    "LONG": -37.01,
    "LAT": -8.15,
    "CIDADE": "São Sebastião do Umbuzeiro",
    "UF": "PARAÍBA",
    "anual": 5.66,
    "JAN": 5.87,
    "FEB": 5.81,
    "MAR": 6.04,
    "APR": 5.7,
    "MAY": 5.02,
    "JUN": 4.58,
    "JUL": 4.66,
    "AUG": 5.44,
    "SEP": 6.2,
    "OCT": 6.25,
    "NOV": 6.36,
    "DEC": 6.01
  },
  {
    "LONG": -37.1,
    "LAT": -0.81,
    "CIDADE": "Zabelê",
    "UF": "PARAÍBA",
    "anual": 5.68,
    "JAN": 5.87,
    "FEB": 5.86,
    "MAR": 5.99,
    "APR": 5.69,
    "MAY": 5.01,
    "JUN": 4.61,
    "JUL": 4.75,
    "AUG": 5.44,
    "SEP": 6.23,
    "OCT": 6.28,
    "NOV": 6.41,
    "DEC": 6.06
  },
  {
    "LONG": -36.86,
    "LAT": -8.08,
    "CIDADE": "São João do Tigre",
    "UF": "PARAÍBA",
    "anual": 5.66,
    "JAN": 5.82,
    "FEB": 5.8,
    "MAR": 5.99,
    "APR": 5.77,
    "MAY": 4.99,
    "JUN": 4.55,
    "JUL": 4.72,
    "AUG": 5.51,
    "SEP": 6.19,
    "OCT": 6.2,
    "NOV": 6.36,
    "DEC": 6.05
  },
  {
    "LONG": -37.12,
    "LAT": -7.89,
    "CIDADE": "Monteiro",
    "UF": "PARAÍBA",
    "anual": 5.79,
    "JAN": 5.98,
    "FEB": 5.96,
    "MAR": 6.15,
    "APR": 5.88,
    "MAY": 5.18,
    "JUN": 4.7,
    "JUL": 4.84,
    "AUG": 5.54,
    "SEP": 6.34,
    "OCT": 6.37,
    "NOV": 6.46,
    "DEC": 6.06
  },
  {
    "LONG": -36.82,
    "LAT": -7.89,
    "CIDADE": "Camalaú",
    "UF": "PARAÍBA",
    "anual": 5.73,
    "JAN": 5.89,
    "FEB": 5.93,
    "MAR": 6.1,
    "APR": 5.85,
    "MAY": 5.12,
    "JUN": 4.7,
    "JUL": 4.78,
    "AUG": 5.49,
    "SEP": 6.22,
    "OCT": 6.29,
    "NOV": 6.35,
    "DEC": 6.02
  },
  {
    "LONG": -36.66,
    "LAT": -7.79,
    "CIDADE": "Congo",
    "UF": "PARAÍBA",
    "anual": 5.66,
    "JAN": 5.83,
    "FEB": 5.84,
    "MAR": 6.05,
    "APR": 5.81,
    "MAY": 5.11,
    "JUN": 4.65,
    "JUL": 4.75,
    "AUG": 5.42,
    "SEP": 6.08,
    "OCT": 6.19,
    "NOV": 6.25,
    "DEC": 5.92
  },
  {
    "LONG": -36.32,
    "LAT": -7.75,
    "CIDADE": "Barra de São Miguel",
    "UF": "PARAÍBA",
    "anual": 5.54,
    "JAN": 5.71,
    "FEB": 5.75,
    "MAR": 5.93,
    "APR": 5.63,
    "MAY": 5.05,
    "JUN": 4.61,
    "JUL": 4.73,
    "AUG": 5.38,
    "SEP": 5.9,
    "OCT": 6,
    "NOV": 6.08,
    "DEC": 5.76
  },
  {
    "LONG": -38.1,
    "LAT": -7.74,
    "CIDADE": "São José de Princesa",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.85,
    "FEB": 5.94,
    "MAR": 6.14,
    "APR": 5.9,
    "MAY": 5.33,
    "JUN": 5.01,
    "JUL": 5.25,
    "AUG": 6.02,
    "SEP": 6.53,
    "OCT": 6.42,
    "NOV": 6.43,
    "DEC": 5.96
  },
  {
    "LONG": -38.15,
    "LAT": -7.7,
    "CIDADE": "Manaíra",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.85,
    "FEB": 5.94,
    "MAR": 6.14,
    "APR": 5.9,
    "MAY": 5.33,
    "JUN": 5.01,
    "JUL": 5.25,
    "AUG": 6.02,
    "SEP": 6.53,
    "OCT": 6.42,
    "NOV": 6.43,
    "DEC": 5.96
  },
  {
    "LONG": -3.8,
    "LAT": -7.74,
    "CIDADE": "Princesa Isabel",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.88,
    "FEB": 5.97,
    "MAR": 6.13,
    "APR": 5.95,
    "MAY": 5.33,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 5.97,
    "SEP": 6.5,
    "OCT": 6.41,
    "NOV": 6.39,
    "DEC": 5.99
  },
  {
    "LONG": -37.08,
    "LAT": -0.77,
    "CIDADE": "Prata",
    "UF": "PARAÍBA",
    "anual": 5.77,
    "JAN": 5.88,
    "FEB": 5.93,
    "MAR": 6.06,
    "APR": 5.91,
    "MAY": 5.22,
    "JUN": 4.75,
    "JUL": 4.89,
    "AUG": 5.6,
    "SEP": 6.29,
    "OCT": 6.34,
    "NOV": 6.37,
    "DEC": 6
  },
  {
    "LONG": -36.88,
    "LAT": -7.67,
    "CIDADE": "Sumé",
    "UF": "PARAÍBA",
    "anual": 5.7,
    "JAN": 5.82,
    "FEB": 5.89,
    "MAR": 6.03,
    "APR": 5.81,
    "MAY": 5.15,
    "JUN": 4.69,
    "JUL": 4.85,
    "AUG": 5.49,
    "SEP": 6.19,
    "OCT": 6.25,
    "NOV": 6.29,
    "DEC": 5.93
  },
  {
    "LONG": -36.49,
    "LAT": -7.73,
    "CIDADE": "Caraúbas",
    "UF": "PARAÍBA",
    "anual": 5.54,
    "JAN": 5.68,
    "FEB": 5.73,
    "MAR": 5.95,
    "APR": 5.64,
    "MAY": 5.02,
    "JUN": 4.53,
    "JUL": 4.7,
    "AUG": 5.37,
    "SEP": 5.92,
    "OCT": 6.04,
    "NOV": 6.1,
    "DEC": 5.79
  },
  {
    "LONG": -36.16,
    "LAT": -0.77,
    "CIDADE": "Riacho de Santo Antônio",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.61,
    "FEB": 5.7,
    "MAR": 5.85,
    "APR": 5.51,
    "MAY": 4.96,
    "JUN": 4.45,
    "JUL": 4.64,
    "AUG": 5.25,
    "SEP": 5.76,
    "OCT": 5.88,
    "NOV": 6,
    "DEC": 5.67
  },
  {
    "LONG": -36.05,
    "LAT": -7.74,
    "CIDADE": "Alcantil",
    "UF": "PARAÍBA",
    "anual": 5.39,
    "JAN": 5.58,
    "FEB": 5.66,
    "MAR": 5.79,
    "APR": 5.45,
    "MAY": 4.93,
    "JUN": 4.43,
    "JUL": 4.63,
    "AUG": 5.19,
    "SEP": 5.68,
    "OCT": 5.81,
    "NOV": 5.93,
    "DEC": 5.59
  },
  {
    "LONG": -35.88,
    "LAT": -0.77,
    "CIDADE": "Santa Cecília",
    "UF": "PARAÍBA",
    "anual": 5.34,
    "JAN": 5.45,
    "FEB": 5.61,
    "MAR": 5.78,
    "APR": 5.39,
    "MAY": 4.84,
    "JUN": 4.42,
    "JUL": 4.58,
    "AUG": 5.2,
    "SEP": 5.66,
    "OCT": 5.75,
    "NOV": 5.85,
    "DEC": 5.59
  },
  {
    "LONG": -35.66,
    "LAT": -7.7,
    "CIDADE": "Umbuzeiro",
    "UF": "PARAÍBA",
    "anual": 5.31,
    "JAN": 5.4,
    "FEB": 5.62,
    "MAR": 5.83,
    "APR": 5.35,
    "MAY": 4.75,
    "JUN": 4.29,
    "JUL": 4.49,
    "AUG": 5.15,
    "SEP": 5.62,
    "OCT": 5.71,
    "NOV": 5.87,
    "DEC": 5.66
  },
  {
    "LONG": -38.51,
    "LAT": -7.56,
    "CIDADE": "Conceição",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.9,
    "FEB": 5.9,
    "MAR": 6.02,
    "APR": 5.86,
    "MAY": 5.35,
    "JUN": 5.02,
    "JUL": 5.24,
    "AUG": 6.02,
    "SEP": 6.59,
    "OCT": 6.42,
    "NOV": 6.48,
    "DEC": 6.07
  },
  {
    "LONG": -38.56,
    "LAT": -7.63,
    "CIDADE": "Santa Inês",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.9,
    "FEB": 5.9,
    "MAR": 6.02,
    "APR": 5.86,
    "MAY": 5.35,
    "JUN": 5.02,
    "JUL": 5.24,
    "AUG": 6.02,
    "SEP": 6.59,
    "OCT": 6.42,
    "NOV": 6.48,
    "DEC": 6.07
  },
  {
    "LONG": -38.34,
    "LAT": -0.76,
    "CIDADE": "Santana de Mangueira",
    "UF": "PARAÍBA",
    "anual": 5.93,
    "JAN": 5.86,
    "FEB": 5.94,
    "MAR": 6.08,
    "APR": 5.96,
    "MAY": 5.39,
    "JUN": 5.07,
    "JUL": 5.33,
    "AUG": 6.03,
    "SEP": 6.55,
    "OCT": 6.44,
    "NOV": 6.47,
    "DEC": 6.03
  },
  {
    "LONG": -37.87,
    "LAT": -7.63,
    "CIDADE": "Tavares",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.83,
    "FEB": 5.97,
    "MAR": 6.12,
    "APR": 5.95,
    "MAY": 5.34,
    "JUN": 5.05,
    "JUL": 5.3,
    "AUG": 6.01,
    "SEP": 6.48,
    "OCT": 6.37,
    "NOV": 6.35,
    "DEC": 5.93
  },
  {
    "LONG": -37.15,
    "LAT": -7.62,
    "CIDADE": "Ouro Velho",
    "UF": "PARAÍBA",
    "anual": 5.81,
    "JAN": 5.84,
    "FEB": 5.95,
    "MAR": 6.1,
    "APR": 5.92,
    "MAY": 5.25,
    "JUN": 4.83,
    "JUL": 5,
    "AUG": 5.69,
    "SEP": 6.37,
    "OCT": 6.39,
    "NOV": 6.38,
    "DEC": 5.99
  },
  {
    "LONG": -3.71,
    "LAT": -7.57,
    "CIDADE": "Amparo",
    "UF": "PARAÍBA",
    "anual": 5.78,
    "JAN": 5.91,
    "FEB": 5.93,
    "MAR": 6.06,
    "APR": 5.88,
    "MAY": 5.23,
    "JUN": 4.79,
    "JUL": 4.98,
    "AUG": 5.69,
    "SEP": 6.3,
    "OCT": 6.34,
    "NOV": 6.32,
    "DEC": 5.97
  },
  {
    "LONG": -36.61,
    "LAT": -7.63,
    "CIDADE": "Coxixola",
    "UF": "PARAÍBA",
    "anual": 5.61,
    "JAN": 5.68,
    "FEB": 5.75,
    "MAR": 5.99,
    "APR": 5.71,
    "MAY": 5.14,
    "JUN": 4.64,
    "JUL": 4.79,
    "AUG": 5.44,
    "SEP": 6.06,
    "OCT": 6.13,
    "NOV": 6.13,
    "DEC": 5.81
  },
  {
    "LONG": -36.44,
    "LAT": -7.63,
    "CIDADE": "São Domingos do Cariri",
    "UF": "PARAÍBA",
    "anual": 5.5,
    "JAN": 5.61,
    "FEB": 5.62,
    "MAR": 5.92,
    "APR": 5.55,
    "MAY": 5.03,
    "JUN": 4.48,
    "JUL": 4.69,
    "AUG": 5.29,
    "SEP": 5.91,
    "OCT": 6.03,
    "NOV": 6.07,
    "DEC": 5.8
  },
  {
    "LONG": -35.79,
    "LAT": -7.58,
    "CIDADE": "Gado Bravo",
    "UF": "PARAÍBA",
    "anual": 5.28,
    "JAN": 5.4,
    "FEB": 5.58,
    "MAR": 5.75,
    "APR": 5.36,
    "MAY": 4.8,
    "JUN": 4.32,
    "JUL": 4.54,
    "AUG": 5.1,
    "SEP": 5.56,
    "OCT": 5.67,
    "NOV": 5.78,
    "DEC": 5.49
  },
  {
    "LONG": -35.55,
    "LAT": -7.64,
    "CIDADE": "Natuba",
    "UF": "PARAÍBA",
    "anual": 5.37,
    "JAN": 5.44,
    "FEB": 5.76,
    "MAR": 5.9,
    "APR": 5.45,
    "MAY": 4.78,
    "JUN": 4.33,
    "JUL": 4.47,
    "AUG": 5.2,
    "SEP": 5.66,
    "OCT": 5.8,
    "NOV": 5.96,
    "DEC": 5.73
  },
  {
    "LONG": -38.41,
    "LAT": "#VALOR!",
    "CIDADE": "Ibiara",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.94,
    "FEB": 5.97,
    "MAR": 6.04,
    "APR": 5.93,
    "MAY": 5.41,
    "JUN": 5.06,
    "JUL": 5.31,
    "AUG": 6.06,
    "SEP": 6.6,
    "OCT": 6.45,
    "NOV": 6.49,
    "DEC": 6.11
  },
  {
    "LONG": -3.82,
    "LAT": -7.54,
    "CIDADE": "Curral Velho",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.92,
    "FEB": 5.96,
    "MAR": 6.22,
    "APR": 6.04,
    "MAY": 5.49,
    "JUN": 5.09,
    "JUL": 5.31,
    "AUG": 6.11,
    "SEP": 6.61,
    "OCT": 6.5,
    "NOV": 6.49,
    "DEC": 6.06
  },
  {
    "LONG": -38.04,
    "LAT": -7.48,
    "CIDADE": "Nova Olinda",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.95,
    "FEB": 6.01,
    "MAR": 6.25,
    "APR": 6.07,
    "MAY": 5.47,
    "JUN": 5.08,
    "JUL": 5.31,
    "AUG": 6.11,
    "SEP": 6.59,
    "OCT": 6.47,
    "NOV": 6.48,
    "DEC": 6.07
  },
  {
    "LONG": -37.82,
    "LAT": -7.54,
    "CIDADE": "Juru",
    "UF": "PARAÍBA",
    "anual": 5.91,
    "JAN": 5.77,
    "FEB": 5.91,
    "MAR": 6.04,
    "APR": 5.95,
    "MAY": 5.4,
    "JUN": 5.1,
    "JUL": 5.33,
    "AUG": 6.07,
    "SEP": 6.59,
    "OCT": 6.44,
    "NOV": 6.4,
    "DEC": 5.92
  },
  {
    "LONG": -3.76,
    "LAT": -7.51,
    "CIDADE": "Água Branca",
    "UF": "PARAÍBA",
    "anual": 5.91,
    "JAN": 5.84,
    "FEB": 5.91,
    "MAR": 6.08,
    "APR": 5.98,
    "MAY": 5.37,
    "JUN": 5.08,
    "JUL": 5.32,
    "AUG": 6.05,
    "SEP": 6.55,
    "OCT": 6.42,
    "NOV": 6.39,
    "DEC": 5.93
  },
  {
    "LONG": -36.67,
    "LAT": -7.48,
    "CIDADE": "Serra Branca",
    "UF": "PARAÍBA",
    "anual": 5.61,
    "JAN": 5.68,
    "FEB": 5.78,
    "MAR": 5.98,
    "APR": 5.69,
    "MAY": 5.13,
    "JUN": 4.62,
    "JUL": 4.87,
    "AUG": 5.47,
    "SEP": 6.06,
    "OCT": 6.12,
    "NOV": 6.14,
    "DEC": 5.81
  },
  {
    "LONG": -36.29,
    "LAT": -7.49,
    "CIDADE": "Cabaceiras",
    "UF": "PARAÍBA",
    "anual": 5.56,
    "JAN": 5.65,
    "FEB": 5.9,
    "MAR": 6.02,
    "APR": 5.66,
    "MAY": 5.08,
    "JUN": 4.5,
    "JUL": 4.73,
    "AUG": 5.34,
    "SEP": 5.91,
    "OCT": 6.01,
    "NOV": 6.1,
    "DEC": 5.86
  },
  {
    "LONG": -36.13,
    "LAT": -7.48,
    "CIDADE": "Boqueirão",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.61,
    "FEB": 5.73,
    "MAR": 5.87,
    "APR": 5.54,
    "MAY": 4.98,
    "JUN": 4.44,
    "JUL": 4.62,
    "AUG": 5.21,
    "SEP": 5.75,
    "OCT": 5.84,
    "NOV": 5.98,
    "DEC": 5.74
  },
  {
    "LONG": -36,
    "LAT": -7.52,
    "CIDADE": "Barra de Santana",
    "UF": "PARAÍBA",
    "anual": 5.34,
    "JAN": 5.53,
    "FEB": 5.61,
    "MAR": 5.79,
    "APR": 5.42,
    "MAY": 4.85,
    "JUN": 4.3,
    "JUL": 4.5,
    "AUG": 5.14,
    "SEP": 5.63,
    "OCT": 5.75,
    "NOV": 5.91,
    "DEC": 5.6
  },
  {
    "LONG": -35.71,
    "LAT": -7.55,
    "CIDADE": "Aroeiras",
    "UF": "PARAÍBA",
    "anual": 5.3,
    "JAN": 5.46,
    "FEB": 5.59,
    "MAR": 5.79,
    "APR": 5.4,
    "MAY": 4.78,
    "JUN": 4.29,
    "JUL": 4.48,
    "AUG": 5.14,
    "SEP": 5.59,
    "OCT": 5.71,
    "NOV": 5.81,
    "DEC": 5.6
  },
  {
    "LONG": -34.91,
    "LAT": -7.51,
    "CIDADE": "Caaporã",
    "UF": "PARAÍBA",
    "anual": 5.41,
    "JAN": 5.47,
    "FEB": 5.85,
    "MAR": 6,
    "APR": 5.46,
    "MAY": 4.9,
    "JUN": 4.54,
    "JUL": 4.59,
    "AUG": 5.31,
    "SEP": 5.59,
    "OCT": 5.75,
    "NOV": 5.78,
    "DEC": 5.72
  },
  {
    "LONG": -34.82,
    "LAT": -7.47,
    "CIDADE": "Pitimbu",
    "UF": "PARAÍBA",
    "anual": 5.51,
    "JAN": 5.53,
    "FEB": 5.91,
    "MAR": 6.05,
    "APR": 5.51,
    "MAY": 5.04,
    "JUN": 4.65,
    "JUL": 4.68,
    "AUG": 5.45,
    "SEP": 5.71,
    "OCT": 5.91,
    "NOV": 5.82,
    "DEC": 5.83
  },
  {
    "LONG": -38.22,
    "LAT": -7.42,
    "CIDADE": "Boa Ventura",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.9,
    "FEB": 6.01,
    "MAR": 6.21,
    "APR": 6.06,
    "MAY": 5.49,
    "JUN": 5.11,
    "JUL": 5.37,
    "AUG": 6.1,
    "SEP": 6.6,
    "OCT": 6.46,
    "NOV": 6.5,
    "DEC": 6.13
  },
  {
    "LONG": -38.27,
    "LAT": -7.43,
    "CIDADE": "Diamante",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.9,
    "FEB": 6.01,
    "MAR": 6.21,
    "APR": 6.06,
    "MAY": 5.49,
    "JUN": 5.11,
    "JUL": 5.37,
    "AUG": 6.1,
    "SEP": 6.6,
    "OCT": 6.46,
    "NOV": 6.5,
    "DEC": 6.13
  },
  {
    "LONG": -38.07,
    "LAT": -7.43,
    "CIDADE": "Pedra Branca",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.94,
    "FEB": 6.05,
    "MAR": 6.28,
    "APR": 6.1,
    "MAY": 5.5,
    "JUN": 5.13,
    "JUL": 5.33,
    "AUG": 6.13,
    "SEP": 6.54,
    "OCT": 6.44,
    "NOV": 6.48,
    "DEC": 6.09
  },
  {
    "LONG": -37.99,
    "LAT": -7.39,
    "CIDADE": "Santana dos Garrotes",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.82,
    "FEB": 6,
    "MAR": 6.16,
    "APR": 6.03,
    "MAY": 5.45,
    "JUN": 5.08,
    "JUL": 5.37,
    "AUG": 6.1,
    "SEP": 6.54,
    "OCT": 6.42,
    "NOV": 6.44,
    "DEC": 6.04
  },
  {
    "LONG": -37.51,
    "LAT": -7.39,
    "CIDADE": "Imaculada",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.81,
    "FEB": 5.83,
    "MAR": 6.03,
    "APR": 5.93,
    "MAY": 5.37,
    "JUN": 5.04,
    "JUL": 5.36,
    "AUG": 6.05,
    "SEP": 6.56,
    "OCT": 6.43,
    "NOV": 6.38,
    "DEC": 5.95
  },
  {
    "LONG": -36.95,
    "LAT": -7.38,
    "CIDADE": "Livramento",
    "UF": "PARAÍBA",
    "anual": 5.72,
    "JAN": 5.77,
    "FEB": 5.86,
    "MAR": 6.04,
    "APR": 5.79,
    "MAY": 5.17,
    "JUN": 4.76,
    "JUL": 4.97,
    "AUG": 5.63,
    "SEP": 6.23,
    "OCT": 6.27,
    "NOV": 6.24,
    "DEC": 5.89
  },
  {
    "LONG": -36.81,
    "LAT": -7.39,
    "CIDADE": "São José dos Cordeiros",
    "UF": "PARAÍBA",
    "anual": 5.69,
    "JAN": 5.74,
    "FEB": 5.89,
    "MAR": 6.01,
    "APR": 5.76,
    "MAY": 5.15,
    "JUN": 4.74,
    "JUL": 4.94,
    "AUG": 5.6,
    "SEP": 6.16,
    "OCT": 6.21,
    "NOV": 6.2,
    "DEC": 5.86
  },
  {
    "LONG": -36.53,
    "LAT": -7.39,
    "CIDADE": "São João do Cariri",
    "UF": "PARAÍBA",
    "anual": 5.61,
    "JAN": 5.7,
    "FEB": 5.77,
    "MAR": 6.02,
    "APR": 5.64,
    "MAY": 5.12,
    "JUN": 4.59,
    "JUL": 4.84,
    "AUG": 5.45,
    "SEP": 6.03,
    "OCT": 6.11,
    "NOV": 6.16,
    "DEC": 5.86
  },
  {
    "LONG": -36.02,
    "LAT": "#VALOR!",
    "CIDADE": "Caturité",
    "UF": "PARAÍBA",
    "anual": 5.42,
    "JAN": 5.59,
    "FEB": 5.66,
    "MAR": 5.85,
    "APR": 5.49,
    "MAY": 4.93,
    "JUN": 4.42,
    "JUL": 4.62,
    "AUG": 5.22,
    "SEP": 5.72,
    "OCT": 5.86,
    "NOV": 5.97,
    "DEC": 5.69
  },
  {
    "LONG": -35.9,
    "LAT": -0.74,
    "CIDADE": "Queimadas",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.56,
    "FEB": 5.67,
    "MAR": 5.84,
    "APR": 5.48,
    "MAY": 4.9,
    "JUN": 4.34,
    "JUL": 4.55,
    "AUG": 5.18,
    "SEP": 5.64,
    "OCT": 5.8,
    "NOV": 5.95,
    "DEC": 5.67
  },
  {
    "LONG": -35.79,
    "LAT": -7.36,
    "CIDADE": "Fagundes",
    "UF": "PARAÍBA",
    "anual": 5.27,
    "JAN": 5.45,
    "FEB": 5.6,
    "MAR": 5.8,
    "APR": 5.4,
    "MAY": 4.78,
    "JUN": 4.31,
    "JUL": 4.41,
    "AUG": 5.07,
    "SEP": 5.46,
    "OCT": 5.62,
    "NOV": 5.75,
    "DEC": 5.6
  },
  {
    "LONG": -35.63,
    "LAT": -7.38,
    "CIDADE": "Itatuba",
    "UF": "PARAÍBA",
    "anual": 5.28,
    "JAN": 5.36,
    "FEB": 5.6,
    "MAR": 5.81,
    "APR": 5.39,
    "MAY": 4.81,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 5.09,
    "SEP": 5.47,
    "OCT": 5.62,
    "NOV": 5.81,
    "DEC": 5.56
  },
  {
    "LONG": -35.44,
    "LAT": -7.36,
    "CIDADE": "Salgado de São Félix",
    "UF": "PARAÍBA",
    "anual": 5.36,
    "JAN": 5.43,
    "FEB": 5.72,
    "MAR": 5.89,
    "APR": 5.43,
    "MAY": 4.84,
    "JUN": 4.42,
    "JUL": 4.52,
    "AUG": 5.3,
    "SEP": 5.63,
    "OCT": 5.7,
    "NOV": 5.87,
    "DEC": 5.63
  },
  {
    "LONG": -35.24,
    "LAT": -7.36,
    "CIDADE": "Juripiranga",
    "UF": "PARAÍBA",
    "anual": 5.39,
    "JAN": 5.43,
    "FEB": 5.72,
    "MAR": 5.87,
    "APR": 5.48,
    "MAY": 4.88,
    "JUN": 4.51,
    "JUL": 4.63,
    "AUG": 5.29,
    "SEP": 5.64,
    "OCT": 5.7,
    "NOV": 5.85,
    "DEC": 5.66
  },
  {
    "LONG": -35.11,
    "LAT": -7.4,
    "CIDADE": "Pedras de Fogo",
    "UF": "PARAÍBA",
    "anual": 5.4,
    "JAN": 5.47,
    "FEB": 5.78,
    "MAR": 5.92,
    "APR": 5.49,
    "MAY": 4.87,
    "JUN": 4.51,
    "JUL": 4.61,
    "AUG": 5.25,
    "SEP": 5.63,
    "OCT": 5.73,
    "NOV": 5.88,
    "DEC": 5.71
  },
  {
    "LONG": -3.49,
    "LAT": -7.43,
    "CIDADE": "Alhandra",
    "UF": "PARAÍBA",
    "anual": 5.42,
    "JAN": 5.48,
    "FEB": 5.82,
    "MAR": 6,
    "APR": 5.45,
    "MAY": 4.95,
    "JUN": 4.56,
    "JUL": 4.59,
    "AUG": 5.34,
    "SEP": 5.59,
    "OCT": 5.76,
    "NOV": 5.77,
    "DEC": 5.73
  },
  {
    "LONG": -38.51,
    "LAT": -7.31,
    "CIDADE": "Bonito de Santa Fé",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.71,
    "FEB": 5.87,
    "MAR": 6,
    "APR": 5.8,
    "MAY": 5.35,
    "JUN": 5.22,
    "JUL": 5.5,
    "AUG": 6.18,
    "SEP": 6.68,
    "OCT": 6.53,
    "NOV": 6.5,
    "DEC": 6.05
  },
  {
    "LONG": -38.3,
    "LAT": -7.25,
    "CIDADE": "São José de Caiana",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.85,
    "FEB": 5.91,
    "MAR": 6.09,
    "APR": 5.98,
    "MAY": 5.53,
    "JUN": 5.26,
    "JUL": 5.48,
    "AUG": 6.22,
    "SEP": 6.65,
    "OCT": 6.51,
    "NOV": 6.54,
    "DEC": 6.11
  },
  {
    "LONG": -38.15,
    "LAT": -7.3,
    "CIDADE": "Itaporanga",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.92,
    "FEB": 6.01,
    "MAR": 6.25,
    "APR": 6.04,
    "MAY": 5.48,
    "JUN": 5.16,
    "JUL": 5.39,
    "AUG": 6.15,
    "SEP": 6.6,
    "OCT": 6.47,
    "NOV": 6.49,
    "DEC": 6.1
  },
  {
    "LONG": -37.43,
    "LAT": -7.26,
    "CIDADE": "Mãe d'Água",
    "UF": "PARAÍBA",
    "anual": 5.91,
    "JAN": 5.75,
    "FEB": 5.88,
    "MAR": 6.05,
    "APR": 5.96,
    "MAY": 5.38,
    "JUN": 5.01,
    "JUL": 5.37,
    "AUG": 6.09,
    "SEP": 6.53,
    "OCT": 6.45,
    "NOV": 6.39,
    "DEC": 6.02
  },
  {
    "LONG": -37.35,
    "LAT": -7.26,
    "CIDADE": "Maturéia",
    "UF": "PARAÍBA",
    "anual": 5.88,
    "JAN": 5.79,
    "FEB": 5.85,
    "MAR": 6.04,
    "APR": 5.87,
    "MAY": 5.32,
    "JUN": 4.99,
    "JUL": 5.36,
    "AUG": 6.03,
    "SEP": 6.51,
    "OCT": 6.42,
    "NOV": 6.41,
    "DEC": 5.91
  },
  {
    "LONG": -37.09,
    "LAT": -7.29,
    "CIDADE": "Desterro",
    "UF": "PARAÍBA",
    "anual": 5.76,
    "JAN": 5.79,
    "FEB": 5.84,
    "MAR": 6.09,
    "APR": 5.85,
    "MAY": 5.23,
    "JUN": 4.79,
    "JUL": 5.04,
    "AUG": 5.69,
    "SEP": 6.32,
    "OCT": 6.31,
    "NOV": 6.27,
    "DEC": 5.91
  },
  {
    "LONG": -36.66,
    "LAT": -7.32,
    "CIDADE": "Parari",
    "UF": "PARAÍBA",
    "anual": 5.63,
    "JAN": 5.65,
    "FEB": 5.79,
    "MAR": 6.02,
    "APR": 5.71,
    "MAY": 5.17,
    "JUN": 4.66,
    "JUL": 4.86,
    "AUG": 5.56,
    "SEP": 6.08,
    "OCT": 6.12,
    "NOV": 6.16,
    "DEC": 5.82
  },
  {
    "LONG": -36.24,
    "LAT": -7.26,
    "CIDADE": "Boa Vista",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.55,
    "FEB": 5.63,
    "MAR": 5.88,
    "APR": 5.5,
    "MAY": 4.94,
    "JUN": 4.44,
    "JUL": 4.67,
    "AUG": 5.27,
    "SEP": 5.79,
    "OCT": 5.94,
    "NOV": 6.01,
    "DEC": 5.7
  },
  {
    "LONG": -35.67,
    "LAT": -7.25,
    "CIDADE": "Riachão do Bacamarte",
    "UF": "PARAÍBA",
    "anual": 5.27,
    "JAN": 5.35,
    "FEB": 5.53,
    "MAR": 5.75,
    "APR": 5.39,
    "MAY": 4.88,
    "JUN": 4.39,
    "JUL": 4.46,
    "AUG": 5.06,
    "SEP": 5.47,
    "OCT": 5.61,
    "NOV": 5.81,
    "DEC": 5.53
  },
  {
    "LONG": -35.61,
    "LAT": -7.29,
    "CIDADE": "Ingá",
    "UF": "PARAÍBA",
    "anual": 5.27,
    "JAN": 5.35,
    "FEB": 5.53,
    "MAR": 5.75,
    "APR": 5.39,
    "MAY": 4.88,
    "JUN": 4.39,
    "JUL": 4.46,
    "AUG": 5.06,
    "SEP": 5.47,
    "OCT": 5.61,
    "NOV": 5.81,
    "DEC": 5.53
  },
  {
    "LONG": -35.48,
    "LAT": -7.3,
    "CIDADE": "Mogeiro",
    "UF": "PARAÍBA",
    "anual": 5.28,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.8,
    "APR": 5.43,
    "MAY": 4.82,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 5.18,
    "SEP": 5.46,
    "OCT": 5.53,
    "NOV": 5.74,
    "DEC": 5.54
  },
  {
    "LONG": -35.34,
    "LAT": -7.34,
    "CIDADE": "Itabaiana",
    "UF": "PARAÍBA",
    "anual": 5.36,
    "JAN": 5.4,
    "FEB": 5.72,
    "MAR": 5.84,
    "APR": 5.5,
    "MAY": 4.92,
    "JUN": 4.49,
    "JUL": 4.6,
    "AUG": 5.3,
    "SEP": 5.56,
    "OCT": 5.65,
    "NOV": 5.75,
    "DEC": 5.63
  },
  {
    "LONG": -3.53,
    "LAT": -7.27,
    "CIDADE": "Pilar",
    "UF": "PARAÍBA",
    "anual": 5.39,
    "JAN": 5.44,
    "FEB": 5.72,
    "MAR": 5.84,
    "APR": 5.49,
    "MAY": 4.91,
    "JUN": 4.53,
    "JUL": 4.64,
    "AUG": 5.35,
    "SEP": 5.63,
    "OCT": 5.69,
    "NOV": 5.81,
    "DEC": 5.64
  },
  {
    "LONG": -34.9,
    "LAT": -7.26,
    "CIDADE": "Conde",
    "UF": "PARAÍBA",
    "anual": 5.45,
    "JAN": 5.54,
    "FEB": 5.83,
    "MAR": 6.01,
    "APR": 5.46,
    "MAY": 4.98,
    "JUN": 4.59,
    "JUL": 4.63,
    "AUG": 5.39,
    "SEP": 5.63,
    "OCT": 5.8,
    "NOV": 5.81,
    "DEC": 5.73
  },
  {
    "LONG": -3.86,
    "LAT": -7.22,
    "CIDADE": "Monte Horebe",
    "UF": "PARAÍBA",
    "anual": 5.96,
    "JAN": 5.73,
    "FEB": 5.85,
    "MAR": 5.97,
    "APR": 5.79,
    "MAY": 5.43,
    "JUN": 5.29,
    "JUL": 5.53,
    "AUG": 6.23,
    "SEP": 6.66,
    "OCT": 6.5,
    "NOV": 6.48,
    "DEC": 6.07
  },
  {
    "LONG": -38.37,
    "LAT": -7.21,
    "CIDADE": "Serra Grande",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.87,
    "FEB": 5.94,
    "MAR": 6.06,
    "APR": 5.95,
    "MAY": 5.51,
    "JUN": 5.3,
    "JUL": 5.51,
    "AUG": 6.27,
    "SEP": 6.64,
    "OCT": 6.5,
    "NOV": 6.52,
    "DEC": 6.09
  },
  {
    "LONG": -38.15,
    "LAT": -7.18,
    "CIDADE": "Igaracy",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.85,
    "FEB": 5.96,
    "MAR": 6.22,
    "APR": 6.04,
    "MAY": 5.51,
    "JUN": 5.18,
    "JUL": 5.45,
    "AUG": 6.18,
    "SEP": 6.6,
    "OCT": 6.49,
    "NOV": 6.49,
    "DEC": 6.11
  },
  {
    "LONG": -37.93,
    "LAT": -7.19,
    "CIDADE": "Piancó",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.91,
    "FEB": 6.04,
    "MAR": 6.32,
    "APR": 6.08,
    "MAY": 5.5,
    "JUN": 5.14,
    "JUL": 5.36,
    "AUG": 6.09,
    "SEP": 6.55,
    "OCT": 6.52,
    "NOV": 6.46,
    "DEC": 6.14
  },
  {
    "LONG": -37.75,
    "LAT": -7.23,
    "CIDADE": "Olho d'Água",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.96,
    "FEB": 6,
    "MAR": 6.27,
    "APR": 6.1,
    "MAY": 5.46,
    "JUN": 5.13,
    "JUL": 5.35,
    "AUG": 6.08,
    "SEP": 6.55,
    "OCT": 6.5,
    "NOV": 6.48,
    "DEC": 6.1
  },
  {
    "LONG": -37.31,
    "LAT": -7.16,
    "CIDADE": "São José do Bonfim",
    "UF": "PARAÍBA",
    "anual": 5.96,
    "JAN": 5.94,
    "FEB": 6.04,
    "MAR": 6.25,
    "APR": 6.01,
    "MAY": 5.41,
    "JUN": 4.97,
    "JUL": 5.3,
    "AUG": 6.11,
    "SEP": 6.53,
    "OCT": 6.48,
    "NOV": 6.45,
    "DEC": 6
  },
  {
    "LONG": -37.25,
    "LAT": -7.22,
    "CIDADE": "Teixeira",
    "UF": "PARAÍBA",
    "anual": 5.92,
    "JAN": 5.88,
    "FEB": 6,
    "MAR": 6.22,
    "APR": 5.99,
    "MAY": 5.36,
    "JUN": 4.96,
    "JUL": 5.29,
    "AUG": 6.08,
    "SEP": 6.5,
    "OCT": 6.43,
    "NOV": 6.39,
    "DEC": 5.96
  },
  {
    "LONG": -37.06,
    "LAT": -7.21,
    "CIDADE": "Cacimbas",
    "UF": "PARAÍBA",
    "anual": 5.82,
    "JAN": 5.78,
    "FEB": 5.86,
    "MAR": 6.04,
    "APR": 5.84,
    "MAY": 5.31,
    "JUN": 4.87,
    "JUL": 5.18,
    "AUG": 5.93,
    "SEP": 6.39,
    "OCT": 6.39,
    "NOV": 6.32,
    "DEC": 5.91
  },
  {
    "LONG": -36.82,
    "LAT": -7.21,
    "CIDADE": "Taperoá",
    "UF": "PARAÍBA",
    "anual": 5.71,
    "JAN": 5.7,
    "FEB": 5.82,
    "MAR": 5.94,
    "APR": 5.81,
    "MAY": 5.2,
    "JUN": 4.71,
    "JUL": 5.04,
    "AUG": 5.73,
    "SEP": 6.21,
    "OCT": 6.26,
    "NOV": 6.24,
    "DEC": 5.87
  },
  {
    "LONG": -36.63,
    "LAT": -7.22,
    "CIDADE": "Santo André",
    "UF": "PARAÍBA",
    "anual": 5.63,
    "JAN": 5.69,
    "FEB": 5.8,
    "MAR": 5.96,
    "APR": 5.7,
    "MAY": 5.15,
    "JUN": 4.62,
    "JUL": 4.91,
    "AUG": 5.54,
    "SEP": 6.03,
    "OCT": 6.16,
    "NOV": 6.15,
    "DEC": 5.82
  },
  {
    "LONG": -3.65,
    "LAT": -7.25,
    "CIDADE": "Gurjão",
    "UF": "PARAÍBA",
    "anual": 5.54,
    "JAN": 5.61,
    "FEB": 5.77,
    "MAR": 5.9,
    "APR": 5.62,
    "MAY": 5.07,
    "JUN": 4.56,
    "JUL": 4.74,
    "AUG": 5.41,
    "SEP": 5.93,
    "OCT": 6.05,
    "NOV": 6.09,
    "DEC": 5.78
  },
  {
    "LONG": -35.85,
    "LAT": -7.16,
    "CIDADE": "Lagoa Seca",
    "UF": "PARAÍBA",
    "anual": 5.25,
    "JAN": 5.31,
    "FEB": 5.53,
    "MAR": 5.76,
    "APR": 5.37,
    "MAY": 4.85,
    "JUN": 4.3,
    "JUL": 4.38,
    "AUG": 5.07,
    "SEP": 5.53,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.49
  },
  {
    "LONG": -35.87,
    "LAT": -7.22,
    "CIDADE": "Campina Grande",
    "UF": "PARAÍBA",
    "anual": 5.25,
    "JAN": 5.31,
    "FEB": 5.53,
    "MAR": 5.76,
    "APR": 5.37,
    "MAY": 4.85,
    "JUN": 4.3,
    "JUL": 4.38,
    "AUG": 5.07,
    "SEP": 5.53,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.49
  },
  {
    "LONG": -35.79,
    "LAT": -7.19,
    "CIDADE": "Massaranduba",
    "UF": "PARAÍBA",
    "anual": 5.22,
    "JAN": 5.19,
    "FEB": 5.46,
    "MAR": 5.71,
    "APR": 5.35,
    "MAY": 4.87,
    "JUN": 4.32,
    "JUL": 4.41,
    "AUG": 5.08,
    "SEP": 5.48,
    "OCT": 5.57,
    "NOV": 5.7,
    "DEC": 5.48
  },
  {
    "LONG": -35.68,
    "LAT": -7.19,
    "CIDADE": "Serra Redonda",
    "UF": "PARAÍBA",
    "anual": 5.19,
    "JAN": 5.27,
    "FEB": 5.49,
    "MAR": 5.63,
    "APR": 5.28,
    "MAY": 4.81,
    "JUN": 4.35,
    "JUL": 4.47,
    "AUG": 5.05,
    "SEP": 5.44,
    "OCT": 5.47,
    "NOV": 5.6,
    "DEC": 5.42
  },
  {
    "LONG": -35.57,
    "LAT": -7.17,
    "CIDADE": "Juarez Távora",
    "UF": "PARAÍBA",
    "anual": 5.26,
    "JAN": 5.38,
    "FEB": 5.57,
    "MAR": 5.71,
    "APR": 5.33,
    "MAY": 4.84,
    "JUN": 4.33,
    "JUL": 4.51,
    "AUG": 5.16,
    "SEP": 5.51,
    "OCT": 5.53,
    "NOV": 5.67,
    "DEC": 5.54
  },
  {
    "LONG": -35.38,
    "LAT": -7.25,
    "CIDADE": "São José dos Ramos",
    "UF": "PARAÍBA",
    "anual": 5.33,
    "JAN": 5.39,
    "FEB": 5.65,
    "MAR": 5.85,
    "APR": 5.48,
    "MAY": 4.87,
    "JUN": 4.47,
    "JUL": 4.59,
    "AUG": 5.27,
    "SEP": 5.55,
    "OCT": 5.6,
    "NOV": 5.72,
    "DEC": 5.53
  },
  {
    "LONG": -35.28,
    "LAT": -7.18,
    "CIDADE": "Riachão do Poço",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.39,
    "FEB": 5.63,
    "MAR": 5.89,
    "APR": 5.45,
    "MAY": 4.87,
    "JUN": 4.5,
    "JUL": 4.58,
    "AUG": 5.31,
    "SEP": 5.58,
    "OCT": 5.65,
    "NOV": 5.74,
    "DEC": 5.58
  },
  {
    "LONG": -35.2,
    "LAT": -7.25,
    "CIDADE": "São Miguel de Taipu",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.39,
    "FEB": 5.63,
    "MAR": 5.89,
    "APR": 5.45,
    "MAY": 4.87,
    "JUN": 4.5,
    "JUL": 4.58,
    "AUG": 5.31,
    "SEP": 5.58,
    "OCT": 5.65,
    "NOV": 5.74,
    "DEC": 5.58
  },
  {
    "LONG": -34.97,
    "LAT": -7.15,
    "CIDADE": "Santa Rita",
    "UF": "PARAÍBA",
    "anual": 5.47,
    "JAN": 5.57,
    "FEB": 5.8,
    "MAR": 5.99,
    "APR": 5.48,
    "MAY": 5.02,
    "JUN": 4.6,
    "JUL": 4.69,
    "AUG": 5.41,
    "SEP": 5.65,
    "OCT": 5.78,
    "NOV": 5.87,
    "DEC": 5.71
  },
  {
    "LONG": -38.5,
    "LAT": -7.12,
    "CIDADE": "São José de Piranhas",
    "UF": "PARAÍBA",
    "anual": 6.03,
    "JAN": 5.9,
    "FEB": 5.99,
    "MAR": 6.11,
    "APR": 5.99,
    "MAY": 5.53,
    "JUN": 5.29,
    "JUL": 5.54,
    "AUG": 6.25,
    "SEP": 6.65,
    "OCT": 6.53,
    "NOV": 6.48,
    "DEC": 6.13
  },
  {
    "LONG": -38.17,
    "LAT": -7.09,
    "CIDADE": "Aguiar",
    "UF": "PARAÍBA",
    "anual": 6.05,
    "JAN": 6.03,
    "FEB": 6,
    "MAR": 6.23,
    "APR": 6.06,
    "MAY": 5.55,
    "JUN": 5.22,
    "JUL": 5.5,
    "AUG": 6.16,
    "SEP": 6.61,
    "OCT": 6.53,
    "NOV": 6.55,
    "DEC": 6.15
  },
  {
    "LONG": -37.72,
    "LAT": -7.1,
    "CIDADE": "Emas",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.95,
    "FEB": 5.96,
    "MAR": 6.28,
    "APR": 6.01,
    "MAY": 5.46,
    "JUN": 5.08,
    "JUL": 5.31,
    "AUG": 6.07,
    "SEP": 6.53,
    "OCT": 6.52,
    "NOV": 6.5,
    "DEC": 6.07
  },
  {
    "LONG": -37.61,
    "LAT": -7.13,
    "CIDADE": "Catingueira",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.93,
    "FEB": 6.02,
    "MAR": 6.33,
    "APR": 6.05,
    "MAY": 5.46,
    "JUN": 5.1,
    "JUL": 5.33,
    "AUG": 6.03,
    "SEP": 6.54,
    "OCT": 6.5,
    "NOV": 6.45,
    "DEC": 6.14
  },
  {
    "LONG": -3.74,
    "LAT": -7.08,
    "CIDADE": "Santa Teresinha",
    "UF": "PARAÍBA",
    "anual": 6.04,
    "JAN": 5.96,
    "FEB": 6.17,
    "MAR": 6.42,
    "APR": 6.13,
    "MAY": 5.51,
    "JUN": 5.09,
    "JUL": 5.3,
    "AUG": 6.09,
    "SEP": 6.55,
    "OCT": 6.54,
    "NOV": 6.51,
    "DEC": 6.18
  },
  {
    "LONG": -37.16,
    "LAT": -7.13,
    "CIDADE": "Cacimba de Areia",
    "UF": "PARAÍBA",
    "anual": 5.96,
    "JAN": 5.97,
    "FEB": 6.1,
    "MAR": 6.25,
    "APR": 6.06,
    "MAY": 5.44,
    "JUN": 4.98,
    "JUL": 5.31,
    "AUG": 6.03,
    "SEP": 6.5,
    "OCT": 6.46,
    "NOV": 6.38,
    "DEC": 5.99
  },
  {
    "LONG": -37.05,
    "LAT": -7.14,
    "CIDADE": "Passagem",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.83,
    "FEB": 6.02,
    "MAR": 6.16,
    "APR": 5.97,
    "MAY": 5.41,
    "JUN": 4.91,
    "JUL": 5.28,
    "AUG": 6.01,
    "SEP": 6.46,
    "OCT": 6.37,
    "NOV": 6.32,
    "DEC": 5.94
  },
  {
    "LONG": -3.69,
    "LAT": -7.12,
    "CIDADE": "Areia de Baraúnas",
    "UF": "PARAÍBA",
    "anual": 5.85,
    "JAN": 5.8,
    "FEB": 5.97,
    "MAR": 6.1,
    "APR": 5.92,
    "MAY": 5.37,
    "JUN": 4.87,
    "JUL": 5.21,
    "AUG": 5.94,
    "SEP": 6.4,
    "OCT": 6.36,
    "NOV": 6.31,
    "DEC": 5.92
  },
  {
    "LONG": -3.68,
    "LAT": -7.1,
    "CIDADE": "Salgadinho",
    "UF": "PARAÍBA",
    "anual": 5.75,
    "JAN": 5.77,
    "FEB": 5.88,
    "MAR": 5.98,
    "APR": 5.81,
    "MAY": 5.24,
    "JUN": 4.77,
    "JUL": 5.05,
    "AUG": 5.78,
    "SEP": 6.26,
    "OCT": 6.3,
    "NOV": 6.28,
    "DEC": 5.89
  },
  {
    "LONG": -36.73,
    "LAT": -0.71,
    "CIDADE": "Assunção",
    "UF": "PARAÍBA",
    "anual": 5.65,
    "JAN": 5.7,
    "FEB": 5.81,
    "MAR": 5.92,
    "APR": 5.67,
    "MAY": 5.15,
    "JUN": 4.67,
    "JUL": 4.91,
    "AUG": 5.64,
    "SEP": 6.13,
    "OCT": 6.19,
    "NOV": 6.17,
    "DEC": 5.79
  },
  {
    "LONG": -36.58,
    "LAT": -7.07,
    "CIDADE": "Juazeirinho",
    "UF": "PARAÍBA",
    "anual": 5.56,
    "JAN": 5.55,
    "FEB": 5.7,
    "MAR": 5.9,
    "APR": 5.65,
    "MAY": 5.06,
    "JUN": 4.61,
    "JUL": 4.84,
    "AUG": 5.51,
    "SEP": 6.01,
    "OCT": 6.07,
    "NOV": 6.12,
    "DEC": 5.76
  },
  {
    "LONG": -36.36,
    "LAT": -7.06,
    "CIDADE": "Soledade",
    "UF": "PARAÍBA",
    "anual": 5.48,
    "JAN": 5.52,
    "FEB": 5.68,
    "MAR": 5.86,
    "APR": 5.53,
    "MAY": 4.99,
    "JUN": 4.5,
    "JUL": 4.67,
    "AUG": 5.33,
    "SEP": 5.87,
    "OCT": 5.99,
    "NOV": 6.06,
    "DEC": 5.74
  },
  {
    "LONG": -36.06,
    "LAT": -7.07,
    "CIDADE": "Pocinhos",
    "UF": "PARAÍBA",
    "anual": 5.42,
    "JAN": 5.51,
    "FEB": 5.67,
    "MAR": 5.87,
    "APR": 5.45,
    "MAY": 4.94,
    "JUN": 4.42,
    "JUL": 4.64,
    "AUG": 5.25,
    "SEP": 5.74,
    "OCT": 5.9,
    "NOV": 5.93,
    "DEC": 5.69
  },
  {
    "LONG": -35.96,
    "LAT": -7.09,
    "CIDADE": "Montadas",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.56,
    "FEB": 5.78,
    "MAR": 5.87,
    "APR": 5.52,
    "MAY": 5.01,
    "JUN": 4.47,
    "JUL": 4.61,
    "AUG": 5.32,
    "SEP": 5.72,
    "OCT": 5.84,
    "NOV": 5.9,
    "DEC": 5.67
  },
  {
    "LONG": -35.97,
    "LAT": -7.15,
    "CIDADE": "Puxinanã",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.56,
    "FEB": 5.78,
    "MAR": 5.87,
    "APR": 5.52,
    "MAY": 5.01,
    "JUN": 4.47,
    "JUL": 4.61,
    "AUG": 5.32,
    "SEP": 5.72,
    "OCT": 5.84,
    "NOV": 5.9,
    "DEC": 5.67
  },
  {
    "LONG": -35.87,
    "LAT": -7.11,
    "CIDADE": "São Sebastião de Lagoa de Roça",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.45,
    "FEB": 5.67,
    "MAR": 5.79,
    "APR": 5.47,
    "MAY": 4.91,
    "JUN": 4.39,
    "JUL": 4.49,
    "AUG": 5.22,
    "SEP": 5.6,
    "OCT": 5.76,
    "NOV": 5.84,
    "DEC": 5.62
  },
  {
    "LONG": -35.76,
    "LAT": -7.06,
    "CIDADE": "Alagoa Nova",
    "UF": "PARAÍBA",
    "anual": 5.26,
    "JAN": 5.36,
    "FEB": 5.59,
    "MAR": 5.71,
    "APR": 5.42,
    "MAY": 4.84,
    "JUN": 4.33,
    "JUL": 4.38,
    "AUG": 5.12,
    "SEP": 5.47,
    "OCT": 5.63,
    "NOV": 5.78,
    "DEC": 5.53
  },
  {
    "LONG": -35.77,
    "LAT": -7.12,
    "CIDADE": "Matinhas",
    "UF": "PARAÍBA",
    "anual": 5.26,
    "JAN": 5.36,
    "FEB": 5.59,
    "MAR": 5.71,
    "APR": 5.42,
    "MAY": 4.84,
    "JUN": 4.33,
    "JUL": 4.38,
    "AUG": 5.12,
    "SEP": 5.47,
    "OCT": 5.63,
    "NOV": 5.78,
    "DEC": 5.53
  },
  {
    "LONG": -35.43,
    "LAT": -0.71,
    "CIDADE": "Gurinhém",
    "UF": "PARAÍBA",
    "anual": 5.32,
    "JAN": 5.33,
    "FEB": 5.62,
    "MAR": 5.8,
    "APR": 5.41,
    "MAY": 4.9,
    "JUN": 4.44,
    "JUL": 4.61,
    "AUG": 5.27,
    "SEP": 5.54,
    "OCT": 5.63,
    "NOV": 5.77,
    "DEC": 5.55
  },
  {
    "LONG": -35.32,
    "LAT": -7.06,
    "CIDADE": "Mari",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.44,
    "FEB": 5.6,
    "MAR": 5.88,
    "APR": 5.46,
    "MAY": 4.89,
    "JUN": 4.53,
    "JUL": 4.6,
    "AUG": 5.34,
    "SEP": 5.59,
    "OCT": 5.72,
    "NOV": 5.85,
    "DEC": 5.64
  },
  {
    "LONG": -3.53,
    "LAT": -7.1,
    "CIDADE": "Caldas Brandão",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.44,
    "FEB": 5.6,
    "MAR": 5.88,
    "APR": 5.46,
    "MAY": 4.89,
    "JUN": 4.53,
    "JUL": 4.6,
    "AUG": 5.34,
    "SEP": 5.59,
    "OCT": 5.72,
    "NOV": 5.85,
    "DEC": 5.64
  },
  {
    "LONG": -35.23,
    "LAT": -7.09,
    "CIDADE": "Sapé",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.44,
    "FEB": 5.7,
    "MAR": 5.92,
    "APR": 5.45,
    "MAY": 4.91,
    "JUN": 4.49,
    "JUL": 4.56,
    "AUG": 5.29,
    "SEP": 5.59,
    "OCT": 5.72,
    "NOV": 5.85,
    "DEC": 5.65
  },
  {
    "LONG": -3.52,
    "LAT": -7.14,
    "CIDADE": "Sobrado",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.44,
    "FEB": 5.7,
    "MAR": 5.92,
    "APR": 5.45,
    "MAY": 4.91,
    "JUN": 4.49,
    "JUL": 4.56,
    "AUG": 5.29,
    "SEP": 5.59,
    "OCT": 5.72,
    "NOV": 5.85,
    "DEC": 5.65
  },
  {
    "LONG": -3.51,
    "LAT": -7.14,
    "CIDADE": "Cruz do Espírito Santo",
    "UF": "PARAÍBA",
    "anual": 5.39,
    "JAN": 5.49,
    "FEB": 5.75,
    "MAR": 5.96,
    "APR": 5.45,
    "MAY": 4.92,
    "JUN": 4.46,
    "JUL": 4.54,
    "AUG": 5.33,
    "SEP": 5.6,
    "OCT": 5.7,
    "NOV": 5.84,
    "DEC": 5.69
  },
  {
    "LONG": -34.93,
    "LAT": -7.13,
    "CIDADE": "Bayeux",
    "UF": "PARAÍBA",
    "anual": 5.47,
    "JAN": 5.56,
    "FEB": 5.82,
    "MAR": 6.03,
    "APR": 5.5,
    "MAY": 5,
    "JUN": 4.55,
    "JUL": 4.65,
    "AUG": 5.39,
    "SEP": 5.67,
    "OCT": 5.8,
    "NOV": 5.95,
    "DEC": 5.78
  },
  {
    "LONG": -34.86,
    "LAT": -7.12,
    "CIDADE": "João Pessoa",
    "UF": "PARAÍBA",
    "anual": 5.53,
    "JAN": 5.59,
    "FEB": 5.85,
    "MAR": 6,
    "APR": 5.51,
    "MAY": 5.07,
    "JUN": 4.63,
    "JUL": 4.72,
    "AUG": 5.45,
    "SEP": 5.8,
    "OCT": 5.93,
    "NOV": 5.99,
    "DEC": 5.83
  },
  {
    "LONG": -3.83,
    "LAT": -7.04,
    "CIDADE": "Carrapateira",
    "UF": "PARAÍBA",
    "anual": 6.05,
    "JAN": 5.86,
    "FEB": 6.01,
    "MAR": 6.1,
    "APR": 6,
    "MAY": 5.59,
    "JUN": 5.35,
    "JUL": 5.65,
    "AUG": 6.29,
    "SEP": 6.63,
    "OCT": 6.52,
    "NOV": 6.54,
    "DEC": 6.05
  },
  {
    "LONG": -37.93,
    "LAT": -7.01,
    "CIDADE": "Coremas",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.95,
    "FEB": 6.06,
    "MAR": 6.22,
    "APR": 6.08,
    "MAY": 5.6,
    "JUN": 5.22,
    "JUL": 5.48,
    "AUG": 6.22,
    "SEP": 6.49,
    "OCT": 6.44,
    "NOV": 6.45,
    "DEC": 6.04
  },
  {
    "LONG": -37.8,
    "LAT": -6.96,
    "CIDADE": "Cajazeirinhas",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.91,
    "FEB": 6.02,
    "MAR": 6.18,
    "APR": 6.06,
    "MAY": 5.51,
    "JUN": 5.17,
    "JUL": 5.4,
    "AUG": 6.13,
    "SEP": 6.52,
    "OCT": 6.47,
    "NOV": 6.48,
    "DEC": 6
  },
  {
    "LONG": -3.73,
    "LAT": -7.02,
    "CIDADE": "Patos",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.95,
    "FEB": 6.03,
    "MAR": 6.28,
    "APR": 6.06,
    "MAY": 5.42,
    "JUN": 5.03,
    "JUL": 5.34,
    "AUG": 6.06,
    "SEP": 6.44,
    "OCT": 6.42,
    "NOV": 6.31,
    "DEC": 6.02
  },
  {
    "LONG": -37.15,
    "LAT": -7.03,
    "CIDADE": "Quixaba",
    "UF": "PARAÍBA",
    "anual": 5.92,
    "JAN": 5.94,
    "FEB": 6.04,
    "MAR": 6.23,
    "APR": 6.02,
    "MAY": 5.41,
    "JUN": 4.99,
    "JUL": 5.31,
    "AUG": 5.99,
    "SEP": 6.44,
    "OCT": 6.41,
    "NOV": 6.3,
    "DEC": 5.96
  },
  {
    "LONG": -36.72,
    "LAT": -6.99,
    "CIDADE": "Junco do Seridó",
    "UF": "PARAÍBA",
    "anual": 5.74,
    "JAN": 5.75,
    "FEB": 5.82,
    "MAR": 5.95,
    "APR": 5.72,
    "MAY": 5.24,
    "JUN": 4.83,
    "JUL": 5.11,
    "AUG": 5.83,
    "SEP": 6.27,
    "OCT": 6.29,
    "NOV": 6.24,
    "DEC": 5.84
  },
  {
    "LONG": -3.62,
    "LAT": -6.99,
    "CIDADE": "Olivedos",
    "UF": "PARAÍBA",
    "anual": 5.45,
    "JAN": 5.55,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.45,
    "MAY": 5,
    "JUN": 4.43,
    "JUL": 4.69,
    "AUG": 5.34,
    "SEP": 5.82,
    "OCT": 5.98,
    "NOV": 6.03,
    "DEC": 5.68
  },
  {
    "LONG": -35.93,
    "LAT": -7.05,
    "CIDADE": "Areial",
    "UF": "PARAÍBA",
    "anual": 5.46,
    "JAN": 5.58,
    "FEB": 5.7,
    "MAR": 5.9,
    "APR": 5.53,
    "MAY": 5.02,
    "JUN": 4.5,
    "JUL": 4.68,
    "AUG": 5.34,
    "SEP": 5.72,
    "OCT": 5.86,
    "NOV": 5.96,
    "DEC": 5.67
  },
  {
    "LONG": -35.86,
    "LAT": -7.02,
    "CIDADE": "Esperança",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.53,
    "FEB": 5.74,
    "MAR": 5.87,
    "APR": 5.54,
    "MAY": 5,
    "JUN": 4.48,
    "JUL": 4.64,
    "AUG": 5.32,
    "SEP": 5.7,
    "OCT": 5.83,
    "NOV": 5.96,
    "DEC": 5.7
  },
  {
    "LONG": -35.8,
    "LAT": -6.96,
    "CIDADE": "Remígio",
    "UF": "PARAÍBA",
    "anual": 5.37,
    "JAN": 5.47,
    "FEB": 5.71,
    "MAR": 5.79,
    "APR": 5.48,
    "MAY": 4.92,
    "JUN": 4.39,
    "JUL": 4.52,
    "AUG": 5.2,
    "SEP": 5.63,
    "OCT": 5.76,
    "NOV": 5.92,
    "DEC": 5.67
  },
  {
    "LONG": -35.7,
    "LAT": -6.97,
    "CIDADE": "Areia",
    "UF": "PARAÍBA",
    "anual": 5.37,
    "JAN": 5.47,
    "FEB": 5.71,
    "MAR": 5.79,
    "APR": 5.48,
    "MAY": 4.92,
    "JUN": 4.39,
    "JUL": 4.52,
    "AUG": 5.2,
    "SEP": 5.63,
    "OCT": 5.76,
    "NOV": 5.92,
    "DEC": 5.67
  },
  {
    "LONG": -35.63,
    "LAT": -7.04,
    "CIDADE": "Alagoa Grande",
    "UF": "PARAÍBA",
    "anual": 5.21,
    "JAN": 5.34,
    "FEB": 5.53,
    "MAR": 5.66,
    "APR": 5.3,
    "MAY": 4.8,
    "JUN": 4.27,
    "JUL": 4.35,
    "AUG": 5.03,
    "SEP": 5.42,
    "OCT": 5.59,
    "NOV": 5.72,
    "DEC": 5.48
  },
  {
    "LONG": -35.46,
    "LAT": -7.03,
    "CIDADE": "Mulungu",
    "UF": "PARAÍBA",
    "anual": 5.3,
    "JAN": 5.34,
    "FEB": 5.58,
    "MAR": 5.74,
    "APR": 5.37,
    "MAY": 4.84,
    "JUN": 4.46,
    "JUL": 4.55,
    "AUG": 5.26,
    "SEP": 5.56,
    "OCT": 5.63,
    "NOV": 5.74,
    "DEC": 5.5
  },
  {
    "LONG": -34.83,
    "LAT": -6.99,
    "CIDADE": "Cabedelo",
    "UF": "PARAÍBA",
    "anual": 5.62,
    "JAN": 5.66,
    "FEB": 5.91,
    "MAR": 6.09,
    "APR": 5.63,
    "MAY": 5.17,
    "JUN": 4.72,
    "JUL": 4.82,
    "AUG": 5.59,
    "SEP": 5.93,
    "OCT": 6.05,
    "NOV": 6.05,
    "DEC": 5.85
  },
  {
    "LONG": -38.68,
    "LAT": -6.92,
    "CIDADE": "Cachoeira dos Índios",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.84,
    "FEB": 5.95,
    "MAR": 6.05,
    "APR": 5.93,
    "MAY": 5.58,
    "JUN": 5.38,
    "JUL": 5.63,
    "AUG": 6.29,
    "SEP": 6.55,
    "OCT": 6.45,
    "NOV": 6.48,
    "DEC": 6.05
  },
  {
    "LONG": -3.86,
    "LAT": -6.88,
    "CIDADE": "Cajazeiras",
    "UF": "PARAÍBA",
    "anual": 6.03,
    "JAN": 5.89,
    "FEB": 5.98,
    "MAR": 6.08,
    "APR": 5.98,
    "MAY": 5.57,
    "JUN": 5.37,
    "JUL": 5.6,
    "AUG": 6.29,
    "SEP": 6.56,
    "OCT": 6.49,
    "NOV": 6.49,
    "DEC": 6.08
  },
  {
    "LONG": -38.32,
    "LAT": -6.91,
    "CIDADE": "Nazarezinho",
    "UF": "PARAÍBA",
    "anual": 6.07,
    "JAN": 5.91,
    "FEB": 6.03,
    "MAR": 6.2,
    "APR": 6.02,
    "MAY": 5.59,
    "JUN": 5.38,
    "JUL": 5.63,
    "AUG": 6.27,
    "SEP": 6.59,
    "OCT": 6.55,
    "NOV": 6.53,
    "DEC": 6.14
  },
  {
    "LONG": -38.16,
    "LAT": -6.94,
    "CIDADE": "São José da Lagoa Tapada",
    "UF": "PARAÍBA",
    "anual": 6.05,
    "JAN": 5.84,
    "FEB": 5.98,
    "MAR": 6.19,
    "APR": 6.05,
    "MAY": 5.62,
    "JUN": 5.37,
    "JUL": 5.58,
    "AUG": 6.29,
    "SEP": 6.6,
    "OCT": 6.54,
    "NOV": 6.51,
    "DEC": 6.07
  },
  {
    "LONG": -37.73,
    "LAT": -6.89,
    "CIDADE": "São Bentinho",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.91,
    "FEB": 6.05,
    "MAR": 6.28,
    "APR": 6.04,
    "MAY": 5.54,
    "JUN": 5.18,
    "JUL": 5.35,
    "AUG": 6.12,
    "SEP": 6.56,
    "OCT": 6.55,
    "NOV": 6.5,
    "DEC": 6.06
  },
  {
    "LONG": -37.61,
    "LAT": -6.9,
    "CIDADE": "Condado",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.92,
    "FEB": 6.11,
    "MAR": 6.33,
    "APR": 6.08,
    "MAY": 5.54,
    "JUN": 5.18,
    "JUL": 5.34,
    "AUG": 6.11,
    "SEP": 6.56,
    "OCT": 6.53,
    "NOV": 6.45,
    "DEC": 6.12
  },
  {
    "LONG": -37.52,
    "LAT": -6.9,
    "CIDADE": "Malta",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.94,
    "FEB": 6.1,
    "MAR": 6.33,
    "APR": 6.09,
    "MAY": 5.54,
    "JUN": 5.14,
    "JUL": 5.38,
    "AUG": 6.14,
    "SEP": 6.56,
    "OCT": 6.51,
    "NOV": 6.39,
    "DEC": 6.11
  },
  {
    "LONG": -37.1,
    "LAT": -6.92,
    "CIDADE": "São Mamede",
    "UF": "PARAÍBA",
    "anual": 5.9,
    "JAN": 5.85,
    "FEB": 6,
    "MAR": 6.18,
    "APR": 5.96,
    "MAY": 5.43,
    "JUN": 4.93,
    "JUL": 5.29,
    "AUG": 6.01,
    "SEP": 6.41,
    "OCT": 6.43,
    "NOV": 6.38,
    "DEC": 5.93
  },
  {
    "LONG": -36.92,
    "LAT": -6.87,
    "CIDADE": "Santa Luzia",
    "UF": "PARAÍBA",
    "anual": 5.87,
    "JAN": 5.81,
    "FEB": 5.98,
    "MAR": 6.14,
    "APR": 5.91,
    "MAY": 5.36,
    "JUN": 4.92,
    "JUL": 5.26,
    "AUG": 5.98,
    "SEP": 6.36,
    "OCT": 6.41,
    "NOV": 6.34,
    "DEC": 5.91
  },
  {
    "LONG": -36.63,
    "LAT": -0.69,
    "CIDADE": "Tenório",
    "UF": "PARAÍBA",
    "anual": 5.69,
    "JAN": 5.68,
    "FEB": 5.77,
    "MAR": 5.95,
    "APR": 5.68,
    "MAY": 5.17,
    "JUN": 4.8,
    "JUL": 5.08,
    "AUG": 5.76,
    "SEP": 6.17,
    "OCT": 6.27,
    "NOV": 6.17,
    "DEC": 5.82
  },
  {
    "LONG": -36.4,
    "LAT": -6.93,
    "CIDADE": "São Vicente do Seridó",
    "UF": "PARAÍBA",
    "anual": 5.66,
    "JAN": 5.64,
    "FEB": 5.78,
    "MAR": 5.97,
    "APR": 5.67,
    "MAY": 5.17,
    "JUN": 4.77,
    "JUL": 5.03,
    "AUG": 5.67,
    "SEP": 6.09,
    "OCT": 6.21,
    "NOV": 6.19,
    "DEC": 5.76
  },
  {
    "LONG": -36.35,
    "LAT": -6.86,
    "CIDADE": "Cubati",
    "UF": "PARAÍBA",
    "anual": 5.6,
    "JAN": 5.6,
    "FEB": 5.74,
    "MAR": 5.93,
    "APR": 5.59,
    "MAY": 5.13,
    "JUN": 4.69,
    "JUL": 4.94,
    "AUG": 5.58,
    "SEP": 5.98,
    "OCT": 6.13,
    "NOV": 6.15,
    "DEC": 5.73
  },
  {
    "LONG": -36.01,
    "LAT": -6.9,
    "CIDADE": "Algodão de Jandaíra",
    "UF": "PARAÍBA",
    "anual": 5.4,
    "JAN": 5.46,
    "FEB": 5.61,
    "MAR": 5.77,
    "APR": 5.46,
    "MAY": 4.98,
    "JUN": 4.47,
    "JUL": 4.61,
    "AUG": 5.32,
    "SEP": 5.69,
    "OCT": 5.89,
    "NOV": 5.95,
    "DEC": 5.64
  },
  {
    "LONG": -35.61,
    "LAT": -6.87,
    "CIDADE": "Pilões",
    "UF": "PARAÍBA",
    "anual": 5.29,
    "JAN": 5.39,
    "FEB": 5.61,
    "MAR": 5.72,
    "APR": 5.36,
    "MAY": 4.85,
    "JUN": 4.37,
    "JUL": 4.47,
    "AUG": 5.22,
    "SEP": 5.55,
    "OCT": 5.69,
    "NOV": 5.76,
    "DEC": 5.53
  },
  {
    "LONG": -35.52,
    "LAT": -6.89,
    "CIDADE": "Cuitegi",
    "UF": "PARAÍBA",
    "anual": 5.19,
    "JAN": 5.26,
    "FEB": 5.47,
    "MAR": 5.63,
    "APR": 5.3,
    "MAY": 4.77,
    "JUN": 4.35,
    "JUL": 4.41,
    "AUG": 5.12,
    "SEP": 5.41,
    "OCT": 5.51,
    "NOV": 5.64,
    "DEC": 5.39
  },
  {
    "LONG": -35.53,
    "LAT": -6.95,
    "CIDADE": "Alagoinha",
    "UF": "PARAÍBA",
    "anual": 5.19,
    "JAN": 5.26,
    "FEB": 5.47,
    "MAR": 5.63,
    "APR": 5.3,
    "MAY": 4.77,
    "JUN": 4.35,
    "JUL": 4.41,
    "AUG": 5.12,
    "SEP": 5.41,
    "OCT": 5.51,
    "NOV": 5.64,
    "DEC": 5.39
  },
  {
    "LONG": -35.49,
    "LAT": -6.85,
    "CIDADE": "Guarabira",
    "UF": "PARAÍBA",
    "anual": 5.24,
    "JAN": 5.27,
    "FEB": 5.51,
    "MAR": 5.69,
    "APR": 5.35,
    "MAY": 4.81,
    "JUN": 4.42,
    "JUL": 4.48,
    "AUG": 5.19,
    "SEP": 5.46,
    "OCT": 5.6,
    "NOV": 5.68,
    "DEC": 5.47
  },
  {
    "LONG": -35.38,
    "LAT": -6.85,
    "CIDADE": "Araçagi",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.4,
    "FEB": 5.55,
    "MAR": 5.81,
    "APR": 5.46,
    "MAY": 4.93,
    "JUN": 4.46,
    "JUL": 4.59,
    "AUG": 5.26,
    "SEP": 5.56,
    "OCT": 5.7,
    "NOV": 5.82,
    "DEC": 5.61
  },
  {
    "LONG": -35.25,
    "LAT": -6.91,
    "CIDADE": "Cuité de Mamanguape",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.46,
    "FEB": 5.59,
    "MAR": 5.84,
    "APR": 5.43,
    "MAY": 4.91,
    "JUN": 4.52,
    "JUL": 4.6,
    "AUG": 5.32,
    "SEP": 5.58,
    "OCT": 5.69,
    "NOV": 5.89,
    "DEC": 5.67
  },
  {
    "LONG": -35.17,
    "LAT": -6.92,
    "CIDADE": "Capim",
    "UF": "PARAÍBA",
    "anual": 5.41,
    "JAN": 5.52,
    "FEB": 5.65,
    "MAR": 5.91,
    "APR": 5.44,
    "MAY": 4.91,
    "JUN": 4.55,
    "JUL": 4.59,
    "AUG": 5.32,
    "SEP": 5.61,
    "OCT": 5.75,
    "NOV": 5.97,
    "DEC": 5.75
  },
  {
    "LONG": -34.88,
    "LAT": -6.9,
    "CIDADE": "Lucena",
    "UF": "PARAÍBA",
    "anual": 5.73,
    "JAN": 5.76,
    "FEB": 6.02,
    "MAR": 6.19,
    "APR": 5.71,
    "MAY": 5.26,
    "JUN": 4.8,
    "JUL": 4.9,
    "AUG": 5.74,
    "SEP": 6.03,
    "OCT": 6.17,
    "NOV": 6.21,
    "DEC": 6.01
  },
  {
    "LONG": -38.65,
    "LAT": -6.82,
    "CIDADE": "Bom Jesus",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.81,
    "FEB": 5.93,
    "MAR": 6.05,
    "APR": 5.92,
    "MAY": 5.55,
    "JUN": 5.39,
    "JUL": 5.66,
    "AUG": 6.29,
    "SEP": 6.54,
    "OCT": 6.45,
    "NOV": 6.47,
    "DEC": 6.04
  },
  {
    "LONG": -38.35,
    "LAT": -6.85,
    "CIDADE": "Marizópolis",
    "UF": "PARAÍBA",
    "anual": 6.05,
    "JAN": 5.91,
    "FEB": 5.99,
    "MAR": 6.19,
    "APR": 5.98,
    "MAY": 5.57,
    "JUN": 5.36,
    "JUL": 5.61,
    "AUG": 6.28,
    "SEP": 6.56,
    "OCT": 6.54,
    "NOV": 6.54,
    "DEC": 6.13
  },
  {
    "LONG": -3.82,
    "LAT": -6.76,
    "CIDADE": "Sousa",
    "UF": "PARAÍBA",
    "anual": 6.06,
    "JAN": 5.91,
    "FEB": 6.03,
    "MAR": 6.2,
    "APR": 6.03,
    "MAY": 5.6,
    "JUN": 5.36,
    "JUL": 5.59,
    "AUG": 6.26,
    "SEP": 6.54,
    "OCT": 6.56,
    "NOV": 6.55,
    "DEC": 6.1
  },
  {
    "LONG": -38.08,
    "LAT": -6.79,
    "CIDADE": "Aparecida",
    "UF": "PARAÍBA",
    "anual": 6.03,
    "JAN": 5.82,
    "FEB": 5.97,
    "MAR": 6.22,
    "APR": 6.04,
    "MAY": 5.57,
    "JUN": 5.3,
    "JUL": 5.5,
    "AUG": 6.25,
    "SEP": 6.56,
    "OCT": 6.53,
    "NOV": 6.53,
    "DEC": 6.07
  },
  {
    "LONG": -37.94,
    "LAT": -6.82,
    "CIDADE": "São Domingos",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.85,
    "FEB": 5.95,
    "MAR": 6.18,
    "APR": 5.99,
    "MAY": 5.57,
    "JUN": 5.22,
    "JUL": 5.48,
    "AUG": 6.15,
    "SEP": 6.56,
    "OCT": 6.55,
    "NOV": 6.56,
    "DEC": 6.04
  },
  {
    "LONG": -37.8,
    "LAT": -6.77,
    "CIDADE": "Pombal",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.89,
    "FEB": 6,
    "MAR": 6.24,
    "APR": 6.01,
    "MAY": 5.56,
    "JUN": 5.21,
    "JUL": 5.44,
    "AUG": 6.14,
    "SEP": 6.58,
    "OCT": 6.56,
    "NOV": 6.51,
    "DEC": 6.04
  },
  {
    "LONG": -37.32,
    "LAT": -6.84,
    "CIDADE": "São José de Espinharas",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.83,
    "FEB": 6.04,
    "MAR": 6.23,
    "APR": 6.04,
    "MAY": 5.49,
    "JUN": 5.07,
    "JUL": 5.37,
    "AUG": 6.08,
    "SEP": 6.44,
    "OCT": 6.45,
    "NOV": 6.36,
    "DEC": 6.02
  },
  {
    "LONG": -36.99,
    "LAT": -6.77,
    "CIDADE": "Várzea",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.82,
    "FEB": 5.99,
    "MAR": 6.23,
    "APR": 5.95,
    "MAY": 5.41,
    "JUN": 4.94,
    "JUL": 5.28,
    "AUG": 6,
    "SEP": 6.36,
    "OCT": 6.4,
    "NOV": 6.33,
    "DEC": 5.93
  },
  {
    "LONG": -36.8,
    "LAT": -6.77,
    "CIDADE": "São José do Sabugi",
    "UF": "PARAÍBA",
    "anual": 5.81,
    "JAN": 5.77,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.83,
    "MAY": 5.31,
    "JUN": 4.92,
    "JUL": 5.21,
    "AUG": 5.94,
    "SEP": 6.31,
    "OCT": 6.32,
    "NOV": 6.22,
    "DEC": 5.89
  },
  {
    "LONG": -36.47,
    "LAT": -0.68,
    "CIDADE": "Pedra Lavrada",
    "UF": "PARAÍBA",
    "anual": 5.66,
    "JAN": 5.65,
    "FEB": 5.74,
    "MAR": 5.93,
    "APR": 5.66,
    "MAY": 5.15,
    "JUN": 4.75,
    "JUL": 5.01,
    "AUG": 5.66,
    "SEP": 6.09,
    "OCT": 6.24,
    "NOV": 6.22,
    "DEC": 5.79
  },
  {
    "LONG": -36.25,
    "LAT": -6.77,
    "CIDADE": "Sossêgo",
    "UF": "PARAÍBA",
    "anual": 5.5,
    "JAN": 5.59,
    "FEB": 5.61,
    "MAR": 5.79,
    "APR": 5.5,
    "MAY": 5.02,
    "JUN": 4.58,
    "JUL": 4.78,
    "AUG": 5.45,
    "SEP": 5.86,
    "OCT": 6.03,
    "NOV": 6.06,
    "DEC": 5.7
  },
  {
    "LONG": -35.82,
    "LAT": "#VALOR!",
    "CIDADE": "Casserengue",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.54,
    "FEB": 5.64,
    "MAR": 5.86,
    "APR": 5.48,
    "MAY": 4.99,
    "JUN": 4.56,
    "JUL": 4.71,
    "AUG": 5.4,
    "SEP": 5.72,
    "OCT": 5.83,
    "NOV": 5.93,
    "DEC": 5.63
  },
  {
    "LONG": -35.76,
    "LAT": -6.83,
    "CIDADE": "Arara",
    "UF": "PARAÍBA",
    "anual": 5.43,
    "JAN": 5.51,
    "FEB": 5.66,
    "MAR": 5.89,
    "APR": 5.47,
    "MAY": 5,
    "JUN": 4.53,
    "JUL": 4.7,
    "AUG": 5.42,
    "SEP": 5.72,
    "OCT": 5.77,
    "NOV": 5.92,
    "DEC": 5.61
  },
  {
    "LONG": -35.66,
    "LAT": -6.76,
    "CIDADE": "Solânea",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.43,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.42,
    "MAY": 4.91,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.29,
    "SEP": 5.63,
    "OCT": 5.73,
    "NOV": 5.82,
    "DEC": 5.58
  },
  {
    "LONG": -3.56,
    "LAT": -6.8,
    "CIDADE": "Borborema",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.43,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.42,
    "MAY": 4.91,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.29,
    "SEP": 5.63,
    "OCT": 5.73,
    "NOV": 5.82,
    "DEC": 5.58
  },
  {
    "LONG": -35.63,
    "LAT": -6.82,
    "CIDADE": "Serraria",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.43,
    "FEB": 5.65,
    "MAR": 5.8,
    "APR": 5.42,
    "MAY": 4.91,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.29,
    "SEP": 5.63,
    "OCT": 5.73,
    "NOV": 5.82,
    "DEC": 5.58
  },
  {
    "LONG": -35.53,
    "LAT": -6.84,
    "CIDADE": "Pilõezinhos",
    "UF": "PARAÍBA",
    "anual": 5.21,
    "JAN": 5.28,
    "FEB": 5.47,
    "MAR": 5.65,
    "APR": 5.3,
    "MAY": 4.82,
    "JUN": 4.38,
    "JUL": 4.44,
    "AUG": 5.16,
    "SEP": 5.47,
    "OCT": 5.57,
    "NOV": 5.66,
    "DEC": 5.38
  },
  {
    "LONG": -35.44,
    "LAT": -6.75,
    "CIDADE": "Sertãozinho",
    "UF": "PARAÍBA",
    "anual": 5.27,
    "JAN": 5.31,
    "FEB": 5.47,
    "MAR": 5.68,
    "APR": 5.35,
    "MAY": 4.85,
    "JUN": 4.43,
    "JUL": 4.5,
    "AUG": 5.2,
    "SEP": 5.52,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.47
  },
  {
    "LONG": -35.49,
    "LAT": "#VALOR!",
    "CIDADE": "Pirpirituba",
    "UF": "PARAÍBA",
    "anual": 5.27,
    "JAN": 5.31,
    "FEB": 5.47,
    "MAR": 5.68,
    "APR": 5.35,
    "MAY": 4.85,
    "JUN": 4.43,
    "JUL": 4.5,
    "AUG": 5.2,
    "SEP": 5.52,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.47
  },
  {
    "LONG": -35.25,
    "LAT": -6.83,
    "CIDADE": "Itapororoca",
    "UF": "PARAÍBA",
    "anual": 5.41,
    "JAN": 5.52,
    "FEB": 5.67,
    "MAR": 5.94,
    "APR": 5.47,
    "MAY": 4.91,
    "JUN": 4.49,
    "JUL": 4.56,
    "AUG": 5.28,
    "SEP": 5.61,
    "OCT": 5.79,
    "NOV": 5.97,
    "DEC": 5.77
  },
  {
    "LONG": -35.12,
    "LAT": -6.83,
    "CIDADE": "Mamanguape",
    "UF": "PARAÍBA",
    "anual": 5.43,
    "JAN": 5.56,
    "FEB": 5.7,
    "MAR": 5.98,
    "APR": 5.48,
    "MAY": 4.91,
    "JUN": 4.49,
    "JUL": 4.54,
    "AUG": 5.29,
    "SEP": 5.62,
    "OCT": 5.79,
    "NOV": 5.98,
    "DEC": 5.78
  },
  {
    "LONG": -35.01,
    "LAT": -6.77,
    "CIDADE": "Marcação",
    "UF": "PARAÍBA",
    "anual": 5.47,
    "JAN": 5.6,
    "FEB": 5.81,
    "MAR": 6.06,
    "APR": 5.51,
    "MAY": 4.99,
    "JUN": 4.54,
    "JUL": 4.57,
    "AUG": 5.36,
    "SEP": 5.65,
    "OCT": 5.82,
    "NOV": 5.96,
    "DEC": 5.78
  },
  {
    "LONG": -35.08,
    "LAT": -6.8,
    "CIDADE": "Rio Tinto",
    "UF": "PARAÍBA",
    "anual": 5.47,
    "JAN": 5.6,
    "FEB": 5.81,
    "MAR": 6.06,
    "APR": 5.51,
    "MAY": 4.99,
    "JUN": 4.54,
    "JUL": 4.57,
    "AUG": 5.36,
    "SEP": 5.65,
    "OCT": 5.82,
    "NOV": 5.96,
    "DEC": 5.78
  },
  {
    "LONG": -3.86,
    "LAT": -0.67,
    "CIDADE": "Santa Helena",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.84,
    "FEB": 5.92,
    "MAR": 6.08,
    "APR": 5.89,
    "MAY": 5.5,
    "JUN": 5.36,
    "JUL": 5.68,
    "AUG": 6.27,
    "SEP": 6.49,
    "OCT": 6.47,
    "NOV": 6.47,
    "DEC": 6.01
  },
  {
    "LONG": -38.46,
    "LAT": -6.72,
    "CIDADE": "São João do Rio do Peixe",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.9,
    "FEB": 5.93,
    "MAR": 6.06,
    "APR": 5.95,
    "MAY": 5.49,
    "JUN": 5.31,
    "JUL": 5.61,
    "AUG": 6.27,
    "SEP": 6.47,
    "OCT": 6.5,
    "NOV": 6.48,
    "DEC": 6.04
  },
  {
    "LONG": -37.57,
    "LAT": -6.74,
    "CIDADE": "Vista Serrana",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.8,
    "FEB": 6.06,
    "MAR": 6.28,
    "APR": 6.04,
    "MAY": 5.57,
    "JUN": 5.12,
    "JUL": 5.43,
    "AUG": 6.12,
    "SEP": 6.49,
    "OCT": 6.47,
    "NOV": 6.39,
    "DEC": 5.99
  },
  {
    "LONG": -36.42,
    "LAT": -6.68,
    "CIDADE": "Nova Palmeira",
    "UF": "PARAÍBA",
    "anual": 5.68,
    "JAN": 5.73,
    "FEB": 5.77,
    "MAR": 5.89,
    "APR": 5.67,
    "MAY": 5.17,
    "JUN": 4.75,
    "JUL": 5.02,
    "AUG": 5.7,
    "SEP": 6.11,
    "OCT": 6.31,
    "NOV": 6.24,
    "DEC": 5.83
  },
  {
    "LONG": -36.07,
    "LAT": -6.72,
    "CIDADE": "Barra de Santa Rosa",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.48,
    "FEB": 5.63,
    "MAR": 5.82,
    "APR": 5.48,
    "MAY": 5.01,
    "JUN": 4.58,
    "JUL": 4.69,
    "AUG": 5.35,
    "SEP": 5.75,
    "OCT": 5.89,
    "NOV": 5.94,
    "DEC": 5.64
  },
  {
    "LONG": -35.63,
    "LAT": -6.74,
    "CIDADE": "Bananeiras",
    "UF": "PARAÍBA",
    "anual": 5.44,
    "JAN": 5.44,
    "FEB": 5.69,
    "MAR": 5.88,
    "APR": 5.48,
    "MAY": 5.04,
    "JUN": 4.62,
    "JUL": 4.7,
    "AUG": 5.42,
    "SEP": 5.76,
    "OCT": 5.79,
    "NOV": 5.9,
    "DEC": 5.61
  },
  {
    "LONG": -35.52,
    "LAT": -6.74,
    "CIDADE": "Belém",
    "UF": "PARAÍBA",
    "anual": 5.33,
    "JAN": 5.38,
    "FEB": 5.54,
    "MAR": 5.73,
    "APR": 5.37,
    "MAY": 4.99,
    "JUN": 4.54,
    "JUL": 4.59,
    "AUG": 5.3,
    "SEP": 5.63,
    "OCT": 5.68,
    "NOV": 5.75,
    "DEC": 5.44
  },
  {
    "LONG": -35.42,
    "LAT": -6.69,
    "CIDADE": "Duas Estradas",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.44,
    "FEB": 5.55,
    "MAR": 5.73,
    "APR": 5.4,
    "MAY": 4.96,
    "JUN": 4.52,
    "JUL": 4.58,
    "AUG": 5.31,
    "SEP": 5.63,
    "OCT": 5.76,
    "NOV": 5.8,
    "DEC": 5.51
  },
  {
    "LONG": -35.44,
    "LAT": -6.69,
    "CIDADE": "Serra da Raiz",
    "UF": "PARAÍBA",
    "anual": 5.35,
    "JAN": 5.44,
    "FEB": 5.55,
    "MAR": 5.73,
    "APR": 5.4,
    "MAY": 4.96,
    "JUN": 4.52,
    "JUL": 4.58,
    "AUG": 5.31,
    "SEP": 5.63,
    "OCT": 5.76,
    "NOV": 5.8,
    "DEC": 5.51
  },
  {
    "LONG": -35.37,
    "LAT": -6.67,
    "CIDADE": "Lagoa de Dentro",
    "UF": "PARAÍBA",
    "anual": 5.39,
    "JAN": 5.52,
    "FEB": 5.64,
    "MAR": 5.81,
    "APR": 5.45,
    "MAY": 4.94,
    "JUN": 4.49,
    "JUL": 4.64,
    "AUG": 5.31,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.9,
    "DEC": 5.63
  },
  {
    "LONG": -35.27,
    "LAT": -6.72,
    "CIDADE": "Curral de Cima",
    "UF": "PARAÍBA",
    "anual": 5.43,
    "JAN": 5.59,
    "FEB": 5.74,
    "MAR": 5.92,
    "APR": 5.46,
    "MAY": 4.94,
    "JUN": 4.48,
    "JUL": 4.59,
    "AUG": 5.3,
    "SEP": 5.65,
    "OCT": 5.81,
    "NOV": 5.96,
    "DEC": 5.71
  },
  {
    "LONG": -34.93,
    "LAT": -6.69,
    "CIDADE": "Baía da Traição",
    "UF": "PARAÍBA",
    "anual": 5.76,
    "JAN": 5.88,
    "FEB": 6,
    "MAR": 6.22,
    "APR": 5.75,
    "MAY": 5.3,
    "JUN": 4.8,
    "JUL": 4.94,
    "AUG": 5.76,
    "SEP": 6.04,
    "OCT": 6.17,
    "NOV": 6.24,
    "DEC": 5.99
  },
  {
    "LONG": -38.51,
    "LAT": -6.56,
    "CIDADE": "Poço de José de Moura",
    "UF": "PARAÍBA",
    "anual": 5.94,
    "JAN": 5.81,
    "FEB": 5.89,
    "MAR": 5.91,
    "APR": 5.82,
    "MAY": 5.42,
    "JUN": 5.26,
    "JUL": 5.56,
    "AUG": 6.25,
    "SEP": 6.51,
    "OCT": 6.45,
    "NOV": 6.47,
    "DEC": 5.94
  },
  {
    "LONG": -38.6,
    "LAT": -6.57,
    "CIDADE": "Triunfo",
    "UF": "PARAÍBA",
    "anual": 5.94,
    "JAN": 5.81,
    "FEB": 5.89,
    "MAR": 5.91,
    "APR": 5.82,
    "MAY": 5.42,
    "JUN": 5.26,
    "JUL": 5.56,
    "AUG": 6.25,
    "SEP": 6.51,
    "OCT": 6.45,
    "NOV": 6.47,
    "DEC": 5.94
  },
  {
    "LONG": -38.1,
    "LAT": -6.62,
    "CIDADE": "São Francisco",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.76,
    "FEB": 5.85,
    "MAR": 6.1,
    "APR": 5.94,
    "MAY": 5.54,
    "JUN": 5.27,
    "JUL": 5.53,
    "AUG": 6.21,
    "SEP": 6.6,
    "OCT": 6.52,
    "NOV": 6.52,
    "DEC": 5.96
  },
  {
    "LONG": -37.92,
    "LAT": -6.59,
    "CIDADE": "Lagoa",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.83,
    "FEB": 5.93,
    "MAR": 6.12,
    "APR": 5.96,
    "MAY": 5.56,
    "JUN": 5.28,
    "JUL": 5.55,
    "AUG": 6.24,
    "SEP": 6.62,
    "OCT": 6.55,
    "NOV": 6.48,
    "DEC": 5.97
  },
  {
    "LONG": -3.78,
    "LAT": -6.55,
    "CIDADE": "Jericó",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.82,
    "FEB": 5.94,
    "MAR": 6.13,
    "APR": 5.97,
    "MAY": 5.56,
    "JUN": 5.24,
    "JUL": 5.52,
    "AUG": 6.2,
    "SEP": 6.57,
    "OCT": 6.52,
    "NOV": 6.42,
    "DEC": 5.95
  },
  {
    "LONG": -37.62,
    "LAT": -6.59,
    "CIDADE": "Paulista",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.97,
    "FEB": 6.07,
    "MAR": 6.24,
    "APR": 6.01,
    "MAY": 5.58,
    "JUN": 5.19,
    "JUL": 5.45,
    "AUG": 6.17,
    "SEP": 6.54,
    "OCT": 6.52,
    "NOV": 6.4,
    "DEC": 6.03
  },
  {
    "LONG": -3.63,
    "LAT": -6.64,
    "CIDADE": "Baraúna",
    "UF": "PARAÍBA",
    "anual": 5.58,
    "JAN": 5.63,
    "FEB": 5.71,
    "MAR": 5.9,
    "APR": 5.64,
    "MAY": 5.09,
    "JUN": 4.74,
    "JUL": 4.94,
    "AUG": 5.57,
    "SEP": 5.98,
    "OCT": 6.1,
    "NOV": 6.02,
    "DEC": 5.66
  },
  {
    "LONG": -35.91,
    "LAT": -0.66,
    "CIDADE": "Damião",
    "UF": "PARAÍBA",
    "anual": 5.45,
    "JAN": 5.52,
    "FEB": 5.59,
    "MAR": 5.85,
    "APR": 5.51,
    "MAY": 4.99,
    "JUN": 4.62,
    "JUL": 4.78,
    "AUG": 5.46,
    "SEP": 5.74,
    "OCT": 5.87,
    "NOV": 5.91,
    "DEC": 5.57
  },
  {
    "LONG": -35.79,
    "LAT": -6.64,
    "CIDADE": "Cacimba de Dentro",
    "UF": "PARAÍBA",
    "anual": 5.33,
    "JAN": 5.45,
    "FEB": 5.59,
    "MAR": 5.81,
    "APR": 5.4,
    "MAY": 4.95,
    "JUN": 4.43,
    "JUL": 4.57,
    "AUG": 5.23,
    "SEP": 5.58,
    "OCT": 5.7,
    "NOV": 5.77,
    "DEC": 5.48
  },
  {
    "LONG": -35.63,
    "LAT": -6.6,
    "CIDADE": "Dona Inês",
    "UF": "PARAÍBA",
    "anual": 5.37,
    "JAN": 5.39,
    "FEB": 5.62,
    "MAR": 5.82,
    "APR": 5.48,
    "MAY": 5.04,
    "JUN": 4.5,
    "JUL": 4.68,
    "AUG": 5.37,
    "SEP": 5.61,
    "OCT": 5.68,
    "NOV": 5.77,
    "DEC": 5.47
  },
  {
    "LONG": -35.44,
    "LAT": -6.62,
    "CIDADE": "Logradouro",
    "UF": "PARAÍBA",
    "anual": 5.46,
    "JAN": 5.51,
    "FEB": 5.64,
    "MAR": 5.87,
    "APR": 5.48,
    "MAY": 5.08,
    "JUN": 4.66,
    "JUL": 4.82,
    "AUG": 5.45,
    "SEP": 5.75,
    "OCT": 5.82,
    "NOV": 5.89,
    "DEC": 5.54
  },
  {
    "LONG": -35.46,
    "LAT": -6.62,
    "CIDADE": "Caiçara",
    "UF": "PARAÍBA",
    "anual": 5.46,
    "JAN": 5.51,
    "FEB": 5.64,
    "MAR": 5.87,
    "APR": 5.48,
    "MAY": 5.08,
    "JUN": 4.66,
    "JUL": 4.82,
    "AUG": 5.45,
    "SEP": 5.75,
    "OCT": 5.82,
    "NOV": 5.89,
    "DEC": 5.54
  },
  {
    "LONG": -35.29,
    "LAT": -6.61,
    "CIDADE": "Jacaraú",
    "UF": "PARAÍBA",
    "anual": 5.51,
    "JAN": 5.67,
    "FEB": 5.83,
    "MAR": 5.98,
    "APR": 5.53,
    "MAY": 5.06,
    "JUN": 4.63,
    "JUL": 4.71,
    "AUG": 5.41,
    "SEP": 5.75,
    "OCT": 5.84,
    "NOV": 6.02,
    "DEC": 5.75
  },
  {
    "LONG": -35.27,
    "LAT": -6.64,
    "CIDADE": "Pedro Régis",
    "UF": "PARAÍBA",
    "anual": 5.51,
    "JAN": 5.67,
    "FEB": 5.83,
    "MAR": 5.98,
    "APR": 5.53,
    "MAY": 5.06,
    "JUN": 4.63,
    "JUL": 4.71,
    "AUG": 5.41,
    "SEP": 5.75,
    "OCT": 5.84,
    "NOV": 6.02,
    "DEC": 5.75
  },
  {
    "LONG": -35.05,
    "LAT": -6.6,
    "CIDADE": "Mataraca",
    "UF": "PARAÍBA",
    "anual": 5.6,
    "JAN": 5.75,
    "FEB": 5.88,
    "MAR": 6.1,
    "APR": 5.64,
    "MAY": 5.15,
    "JUN": 4.71,
    "JUL": 4.83,
    "AUG": 5.57,
    "SEP": 5.85,
    "OCT": 5.93,
    "NOV": 6.02,
    "DEC": 5.75
  },
  {
    "LONG": -38.55,
    "LAT": -6.45,
    "CIDADE": "Bernardino Batista",
    "UF": "PARAÍBA",
    "anual": 5.88,
    "JAN": 5.72,
    "FEB": 5.75,
    "MAR": 5.81,
    "APR": 5.64,
    "MAY": 5.32,
    "JUN": 5.21,
    "JUL": 5.57,
    "AUG": 6.26,
    "SEP": 6.54,
    "OCT": 6.48,
    "NOV": 6.44,
    "DEC": 5.85
  },
  {
    "LONG": -38.41,
    "LAT": -6.52,
    "CIDADE": "Uiraúna",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.73,
    "FEB": 5.8,
    "MAR": 5.92,
    "APR": 5.7,
    "MAY": 5.37,
    "JUN": 5.22,
    "JUL": 5.54,
    "AUG": 6.23,
    "SEP": 6.51,
    "OCT": 6.44,
    "NOV": 6.41,
    "DEC": 5.86
  },
  {
    "LONG": -38.48,
    "LAT": -0.65,
    "CIDADE": "Joca Claudino",
    "UF": "PARAÍBA",
    "anual": 5.89,
    "JAN": 5.73,
    "FEB": 5.8,
    "MAR": 5.92,
    "APR": 5.7,
    "MAY": 5.37,
    "JUN": 5.22,
    "JUL": 5.54,
    "AUG": 6.23,
    "SEP": 6.51,
    "OCT": 6.44,
    "NOV": 6.41,
    "DEC": 5.86
  },
  {
    "LONG": -38.28,
    "LAT": -6.55,
    "CIDADE": "Vieirópolis",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.77,
    "FEB": 5.87,
    "MAR": 6,
    "APR": 5.81,
    "MAY": 5.48,
    "JUN": 5.29,
    "JUL": 5.59,
    "AUG": 6.28,
    "SEP": 6.49,
    "OCT": 6.48,
    "NOV": 6.46,
    "DEC": 5.89
  },
  {
    "LONG": -38.17,
    "LAT": -6.51,
    "CIDADE": "Lastro",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.76,
    "FEB": 5.82,
    "MAR": 6.05,
    "APR": 5.86,
    "MAY": 5.51,
    "JUN": 5.27,
    "JUL": 5.55,
    "AUG": 6.25,
    "SEP": 6.51,
    "OCT": 6.47,
    "NOV": 6.48,
    "DEC": 5.9
  },
  {
    "LONG": -38.06,
    "LAT": -6.53,
    "CIDADE": "Santa Cruz",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.82,
    "FEB": 5.9,
    "MAR": 6.13,
    "APR": 5.91,
    "MAY": 5.55,
    "JUN": 5.28,
    "JUL": 5.55,
    "AUG": 6.21,
    "SEP": 6.55,
    "OCT": 6.54,
    "NOV": 6.49,
    "DEC": 5.98
  },
  {
    "LONG": -37.71,
    "LAT": -6.54,
    "CIDADE": "Mato Grosso",
    "UF": "PARAÍBA",
    "anual": 6.02,
    "JAN": 5.9,
    "FEB": 6.03,
    "MAR": 6.2,
    "APR": 6.02,
    "MAY": 5.59,
    "JUN": 5.22,
    "JUL": 5.53,
    "AUG": 6.21,
    "SEP": 6.57,
    "OCT": 6.52,
    "NOV": 6.44,
    "DEC": 6.01
  },
  {
    "LONG": -37.45,
    "LAT": -6.49,
    "CIDADE": "São Bento",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.94,
    "FEB": 6.08,
    "MAR": 6.23,
    "APR": 6.06,
    "MAY": 5.55,
    "JUN": 5.13,
    "JUL": 5.4,
    "AUG": 6.08,
    "SEP": 6.48,
    "OCT": 6.49,
    "NOV": 6.35,
    "DEC": 6
  },
  {
    "LONG": "#VALOR!",
    "LAT": -6.51,
    "CIDADE": "Picuí",
    "UF": "PARAÍBA",
    "anual": 5.68,
    "JAN": 5.72,
    "FEB": 5.76,
    "MAR": 5.9,
    "APR": 5.67,
    "MAY": 5.19,
    "JUN": 4.84,
    "JUL": 5.08,
    "AUG": 5.75,
    "SEP": 6.13,
    "OCT": 6.21,
    "NOV": 6.15,
    "DEC": 5.71
  },
  {
    "LONG": -36.2,
    "LAT": -6.46,
    "CIDADE": "Nova Floresta",
    "UF": "PARAÍBA",
    "anual": 5.6,
    "JAN": 5.63,
    "FEB": 5.7,
    "MAR": 5.87,
    "APR": 5.63,
    "MAY": 5.14,
    "JUN": 4.75,
    "JUL": 4.96,
    "AUG": 5.65,
    "SEP": 6.04,
    "OCT": 6.13,
    "NOV": 6.07,
    "DEC": 5.66
  },
  {
    "LONG": -36.15,
    "LAT": -6.49,
    "CIDADE": "Cuité",
    "UF": "PARAÍBA",
    "anual": 5.43,
    "JAN": 5.47,
    "FEB": 5.59,
    "MAR": 5.68,
    "APR": 5.48,
    "MAY": 4.97,
    "JUN": 4.55,
    "JUL": 4.74,
    "AUG": 5.44,
    "SEP": 5.81,
    "OCT": 5.94,
    "NOV": 5.96,
    "DEC": 5.54
  },
  {
    "LONG": -35.74,
    "LAT": -6.53,
    "CIDADE": "Araruna",
    "UF": "PARAÍBA",
    "anual": 5.49,
    "JAN": 5.54,
    "FEB": 5.72,
    "MAR": 5.93,
    "APR": 5.54,
    "MAY": 5.1,
    "JUN": 4.65,
    "JUL": 4.82,
    "AUG": 5.47,
    "SEP": 5.81,
    "OCT": 5.83,
    "NOV": 5.9,
    "DEC": 5.56
  },
  {
    "LONG": -35.66,
    "LAT": -6.54,
    "CIDADE": "Riachão",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.38,
    "FEB": 5.6,
    "MAR": 5.78,
    "APR": 5.5,
    "MAY": 5.06,
    "JUN": 4.62,
    "JUL": 4.76,
    "AUG": 5.39,
    "SEP": 5.62,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.43
  },
  {
    "LONG": -3.56,
    "LAT": -0.65,
    "CIDADE": "Tacima",
    "UF": "PARAÍBA",
    "anual": 5.38,
    "JAN": 5.38,
    "FEB": 5.6,
    "MAR": 5.78,
    "APR": 5.5,
    "MAY": 5.06,
    "JUN": 4.62,
    "JUL": 4.76,
    "AUG": 5.39,
    "SEP": 5.62,
    "OCT": 5.65,
    "NOV": 5.77,
    "DEC": 5.43
  },
  {
    "LONG": -38.49,
    "LAT": -6.4,
    "CIDADE": "Poço Dantas",
    "UF": "PARAÍBA",
    "anual": 5.88,
    "JAN": 5.67,
    "FEB": 5.7,
    "MAR": 5.86,
    "APR": 5.64,
    "MAY": 5.36,
    "JUN": 5.22,
    "JUL": 5.58,
    "AUG": 6.28,
    "SEP": 6.52,
    "OCT": 6.45,
    "NOV": 6.4,
    "DEC": 5.83
  },
  {
    "LONG": -37.92,
    "LAT": -6.44,
    "CIDADE": "Bom Sucesso",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.88,
    "FEB": 5.96,
    "MAR": 6.13,
    "APR": 5.97,
    "MAY": 5.57,
    "JUN": 5.33,
    "JUL": 5.61,
    "AUG": 6.25,
    "SEP": 6.52,
    "OCT": 6.5,
    "NOV": 6.46,
    "DEC": 5.98
  },
  {
    "LONG": -37.83,
    "LAT": -6.37,
    "CIDADE": "Brejo dos Santos",
    "UF": "PARAÍBA",
    "anual": 6.01,
    "JAN": 5.87,
    "FEB": 5.98,
    "MAR": 6.1,
    "APR": 5.95,
    "MAY": 5.56,
    "JUN": 5.28,
    "JUL": 5.58,
    "AUG": 6.23,
    "SEP": 6.59,
    "OCT": 6.51,
    "NOV": 6.43,
    "DEC": 5.98
  },
  {
    "LONG": -37.65,
    "LAT": -6.44,
    "CIDADE": "Riacho dos Cavalos",
    "UF": "PARAÍBA",
    "anual": 5.99,
    "JAN": 5.87,
    "FEB": 6.03,
    "MAR": 6.17,
    "APR": 6.02,
    "MAY": 5.57,
    "JUN": 5.18,
    "JUL": 5.54,
    "AUG": 6.17,
    "SEP": 6.54,
    "OCT": 6.49,
    "NOV": 6.36,
    "DEC": 5.96
  },
  {
    "LONG": -36.45,
    "LAT": -0.64,
    "CIDADE": "Frei Martinho",
    "UF": "PARAÍBA",
    "anual": 5.68,
    "JAN": 5.71,
    "FEB": 5.75,
    "MAR": 5.89,
    "APR": 5.7,
    "MAY": 5.21,
    "JUN": 4.8,
    "JUL": 5.04,
    "AUG": 5.81,
    "SEP": 6.18,
    "OCT": 6.2,
    "NOV": 6.19,
    "DEC": 5.7
  },
  {
    "LONG": -37.75,
    "LAT": -0.63,
    "CIDADE": "Catolé do Rocha",
    "UF": "PARAÍBA",
    "anual": 5.96,
    "JAN": 5.79,
    "FEB": 5.94,
    "MAR": 6.06,
    "APR": 5.87,
    "MAY": 5.54,
    "JUN": 5.21,
    "JUL": 5.57,
    "AUG": 6.24,
    "SEP": 6.58,
    "OCT": 6.47,
    "NOV": 6.32,
    "DEC": 5.91
  },
  {
    "LONG": -37.49,
    "LAT": -6.34,
    "CIDADE": "Brejo do Cruz",
    "UF": "PARAÍBA",
    "anual": 6,
    "JAN": 5.92,
    "FEB": 6.08,
    "MAR": 6.15,
    "APR": 6,
    "MAY": 5.6,
    "JUN": 5.23,
    "JUL": 5.49,
    "AUG": 6.12,
    "SEP": 6.52,
    "OCT": 6.51,
    "NOV": 6.4,
    "DEC": 5.98
  },
  {
    "LONG": -37.54,
    "LAT": -6.19,
    "CIDADE": "Belém do Brejo do Cruz",
    "UF": "PARAÍBA",
    "anual": 5.98,
    "JAN": 5.84,
    "FEB": 5.98,
    "MAR": 6.1,
    "APR": 6.01,
    "MAY": 5.59,
    "JUN": 5.25,
    "JUL": 5.53,
    "AUG": 6.15,
    "SEP": 6.54,
    "OCT": 6.48,
    "NOV": 6.36,
    "DEC": 5.91
  },
  {
    "LONG": -37.35,
    "LAT": -6.21,
    "CIDADE": "São José do Brejo do Cruz",
    "UF": "PARAÍBA",
    "anual": 5.95,
    "JAN": 5.85,
    "FEB": 5.98,
    "MAR": 6.02,
    "APR": 5.99,
    "MAY": 5.55,
    "JUN": 5.15,
    "JUL": 5.48,
    "AUG": 6.15,
    "SEP": 6.52,
    "OCT": 6.47,
    "NOV": 6.35,
    "DEC": 5.9
  },
  {
    "LONG": -51.99,
    "LAT": -26.48,
    "CIDADE": "Palmas",
    "UF": "PARANÁ",
    "anual": 4.82,
    "JAN": 5.26,
    "FEB": 5.24,
    "MAR": 5.3,
    "APR": 4.88,
    "MAY": 4.09,
    "JUN": 3.75,
    "JUL": 4.05,
    "AUG": 4.94,
    "SEP": 4.55,
    "OCT": 4.95,
    "NOV": 5.47,
    "DEC": 5.37
  },
  {
    "LONG": -52.55,
    "LAT": -26.35,
    "CIDADE": "Mariópolis",
    "UF": "PARANÁ",
    "anual": 4.91,
    "JAN": 5.47,
    "FEB": 5.37,
    "MAR": 5.42,
    "APR": 4.97,
    "MAY": 4.22,
    "JUN": 3.79,
    "JUL": 4.11,
    "AUG": 5,
    "SEP": 4.59,
    "OCT": 5.05,
    "NOV": 5.51,
    "DEC": 5.45
  },
  {
    "LONG": -52.35,
    "LAT": -26.4,
    "CIDADE": "Clevelândia",
    "UF": "PARANÁ",
    "anual": 4.89,
    "JAN": 5.41,
    "FEB": 5.33,
    "MAR": 5.39,
    "APR": 4.95,
    "MAY": 4.22,
    "JUN": 3.79,
    "JUL": 4.1,
    "AUG": 5.01,
    "SEP": 4.59,
    "OCT": 5,
    "NOV": 5.5,
    "DEC": 5.42
  },
  {
    "LONG": -51.32,
    "LAT": -26.43,
    "CIDADE": "General Carneiro",
    "UF": "PARANÁ",
    "anual": 4.4,
    "JAN": 4.86,
    "FEB": 4.88,
    "MAR": 4.79,
    "APR": 4.28,
    "MAY": 3.72,
    "JUN": 3.4,
    "JUL": 3.69,
    "AUG": 4.64,
    "SEP": 4.2,
    "OCT": 4.41,
    "NOV": 4.99,
    "DEC": 4.98
  },
  {
    "LONG": -53.63,
    "LAT": -26.25,
    "CIDADE": "Barracão",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.6,
    "FEB": 5.54,
    "MAR": 5.48,
    "APR": 4.91,
    "MAY": 4.16,
    "JUN": 3.74,
    "JUL": 4.06,
    "AUG": 4.91,
    "SEP": 4.64,
    "OCT": 5.12,
    "NOV": 5.46,
    "DEC": 5.49
  },
  {
    "LONG": -53.31,
    "LAT": -26.25,
    "CIDADE": "Flor da Serra do Sul",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.58,
    "FEB": 5.43,
    "MAR": 5.43,
    "APR": 4.96,
    "MAY": 4.18,
    "JUN": 3.73,
    "JUL": 4.08,
    "AUG": 4.94,
    "SEP": 4.66,
    "OCT": 5.09,
    "NOV": 5.47,
    "DEC": 5.45
  },
  {
    "LONG": -52.78,
    "LAT": -26.27,
    "CIDADE": "Vitorino",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.51,
    "FEB": 5.39,
    "MAR": 5.43,
    "APR": 4.98,
    "MAY": 4.22,
    "JUN": 3.84,
    "JUL": 4.12,
    "AUG": 5.02,
    "SEP": 4.63,
    "OCT": 5.06,
    "NOV": 5.52,
    "DEC": 5.48
  },
  {
    "LONG": -53.6,
    "LAT": -26.2,
    "CIDADE": "Bom Jesus do Sul",
    "UF": "PARANÁ",
    "anual": 4.97,
    "JAN": 5.7,
    "FEB": 5.49,
    "MAR": 5.53,
    "APR": 4.99,
    "MAY": 4.2,
    "JUN": 3.77,
    "JUL": 4.11,
    "AUG": 5,
    "SEP": 4.66,
    "OCT": 5.15,
    "NOV": 5.52,
    "DEC": 5.55
  },
  {
    "LONG": -53.36,
    "LAT": -26.18,
    "CIDADE": "Salgado Filho",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.6,
    "FEB": 5.45,
    "MAR": 5.47,
    "APR": 4.99,
    "MAY": 4.21,
    "JUN": 3.77,
    "JUL": 4.13,
    "AUG": 4.99,
    "SEP": 4.68,
    "OCT": 5.13,
    "NOV": 5.53,
    "DEC": 5.48
  },
  {
    "LONG": -52.97,
    "LAT": -26.16,
    "CIDADE": "Renascença",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.47,
    "FEB": 5.42,
    "MAR": 5.48,
    "APR": 5,
    "MAY": 4.24,
    "JUN": 3.83,
    "JUL": 4.11,
    "AUG": 5.02,
    "SEP": 4.66,
    "OCT": 5.1,
    "NOV": 5.51,
    "DEC": 5.47
  },
  {
    "LONG": -52.67,
    "LAT": -26.23,
    "CIDADE": "Pato Branco",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.55,
    "FEB": 5.38,
    "MAR": 5.48,
    "APR": 4.98,
    "MAY": 4.21,
    "JUN": 3.84,
    "JUL": 4.11,
    "AUG": 5.08,
    "SEP": 4.62,
    "OCT": 5.07,
    "NOV": 5.48,
    "DEC": 5.49
  },
  {
    "LONG": -52.04,
    "LAT": -26.23,
    "CIDADE": "Coronel Domingos Soares",
    "UF": "PARANÁ",
    "anual": 4.85,
    "JAN": 5.33,
    "FEB": 5.22,
    "MAR": 5.31,
    "APR": 4.89,
    "MAY": 4.14,
    "JUN": 3.81,
    "JUL": 4.09,
    "AUG": 5.03,
    "SEP": 4.63,
    "OCT": 4.99,
    "NOV": 5.46,
    "DEC": 5.36
  },
  {
    "LONG": -51.55,
    "LAT": -26.16,
    "CIDADE": "Bituruna",
    "UF": "PARANÁ",
    "anual": 4.5,
    "JAN": 4.96,
    "FEB": 5.03,
    "MAR": 4.95,
    "APR": 4.38,
    "MAY": 3.73,
    "JUN": 3.39,
    "JUL": 3.71,
    "AUG": 4.71,
    "SEP": 4.29,
    "OCT": 4.6,
    "NOV": 5.14,
    "DEC": 5.14
  },
  {
    "LONG": -51.23,
    "LAT": -26.17,
    "CIDADE": "Porto Vitória",
    "UF": "PARANÁ",
    "anual": 4.42,
    "JAN": 4.98,
    "FEB": 5,
    "MAR": 4.92,
    "APR": 4.29,
    "MAY": 3.66,
    "JUN": 3.22,
    "JUL": 3.48,
    "AUG": 4.59,
    "SEP": 4.24,
    "OCT": 4.5,
    "NOV": 5.1,
    "DEC": 5.11
  },
  {
    "LONG": -51.09,
    "LAT": -26.23,
    "CIDADE": "União da Vitória",
    "UF": "PARANÁ",
    "anual": 4.33,
    "JAN": 4.9,
    "FEB": 4.87,
    "MAR": 4.82,
    "APR": 4.16,
    "MAY": 3.63,
    "JUN": 3.19,
    "JUL": 3.43,
    "AUG": 4.5,
    "SEP": 4.16,
    "OCT": 4.4,
    "NOV": 4.98,
    "DEC": 4.96
  },
  {
    "LONG": -50.93,
    "LAT": -2.62,
    "CIDADE": "Paula Freitas",
    "UF": "PARANÁ",
    "anual": 4.28,
    "JAN": 4.77,
    "FEB": 4.84,
    "MAR": 4.8,
    "APR": 4.1,
    "MAY": 3.6,
    "JUN": 3.15,
    "JUL": 3.4,
    "AUG": 4.45,
    "SEP": 4.1,
    "OCT": 4.32,
    "NOV": 4.9,
    "DEC": 4.9
  },
  {
    "LONG": -53.73,
    "LAT": -26.07,
    "CIDADE": "Santo Antônio do Sudoeste",
    "UF": "PARANÁ",
    "anual": 4.97,
    "JAN": 5.65,
    "FEB": 5.56,
    "MAR": 5.5,
    "APR": 5.01,
    "MAY": 4.2,
    "JUN": 3.79,
    "JUL": 4.11,
    "AUG": 4.96,
    "SEP": 4.64,
    "OCT": 5.14,
    "NOV": 5.51,
    "DEC": 5.59
  },
  {
    "LONG": -53.32,
    "LAT": -26.15,
    "CIDADE": "Manfrinópolis",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.53,
    "FEB": 5.5,
    "MAR": 5.53,
    "APR": 5,
    "MAY": 4.18,
    "JUN": 3.81,
    "JUL": 4.12,
    "AUG": 5.03,
    "SEP": 4.68,
    "OCT": 5.12,
    "NOV": 5.53,
    "DEC": 5.52
  },
  {
    "LONG": -53.05,
    "LAT": -26.08,
    "CIDADE": "Francisco Beltrão",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.5,
    "FEB": 5.39,
    "MAR": 5.49,
    "APR": 4.98,
    "MAY": 4.23,
    "JUN": 3.81,
    "JUL": 4.13,
    "AUG": 5.04,
    "SEP": 4.68,
    "OCT": 5.12,
    "NOV": 5.51,
    "DEC": 5.51
  },
  {
    "LONG": -53.03,
    "LAT": -26.15,
    "CIDADE": "Marmeleiro",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.5,
    "FEB": 5.39,
    "MAR": 5.49,
    "APR": 4.98,
    "MAY": 4.23,
    "JUN": 3.81,
    "JUL": 4.13,
    "AUG": 5.04,
    "SEP": 4.68,
    "OCT": 5.12,
    "NOV": 5.51,
    "DEC": 5.51
  },
  {
    "LONG": -52.84,
    "LAT": -26.07,
    "CIDADE": "Bom Sucesso do Sul",
    "UF": "PARANÁ",
    "anual": 4.97,
    "JAN": 5.5,
    "FEB": 5.43,
    "MAR": 5.53,
    "APR": 5.04,
    "MAY": 4.25,
    "JUN": 3.81,
    "JUL": 4.12,
    "AUG": 5.07,
    "SEP": 4.66,
    "OCT": 5.12,
    "NOV": 5.57,
    "DEC": 5.55
  },
  {
    "LONG": -52.39,
    "LAT": -26.14,
    "CIDADE": "Honório Serpa",
    "UF": "PARANÁ",
    "anual": 4.91,
    "JAN": 5.48,
    "FEB": 5.35,
    "MAR": 5.41,
    "APR": 4.91,
    "MAY": 4.21,
    "JUN": 3.84,
    "JUL": 4.15,
    "AUG": 5.04,
    "SEP": 4.64,
    "OCT": 5.04,
    "NOV": 5.48,
    "DEC": 5.4
  },
  {
    "LONG": -49.8,
    "LAT": -26.1,
    "CIDADE": "Rio Negro",
    "UF": "PARANÁ",
    "anual": 4.34,
    "JAN": 4.88,
    "FEB": 4.9,
    "MAR": 4.77,
    "APR": 4.24,
    "MAY": 3.67,
    "JUN": 3.31,
    "JUL": 3.61,
    "AUG": 4.56,
    "SEP": 4.08,
    "OCT": 4.31,
    "NOV": 4.82,
    "DEC": 4.93
  },
  {
    "LONG": -4.94,
    "LAT": -26.1,
    "CIDADE": "Piên",
    "UF": "PARANÁ",
    "anual": 4.34,
    "JAN": 4.81,
    "FEB": 4.87,
    "MAR": 4.73,
    "APR": 4.3,
    "MAY": 3.75,
    "JUN": 3.47,
    "JUL": 3.68,
    "AUG": 4.55,
    "SEP": 4.06,
    "OCT": 4.22,
    "NOV": 4.76,
    "DEC": 4.87
  },
  {
    "LONG": -53.74,
    "LAT": -26.02,
    "CIDADE": "Pranchita",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.67,
    "FEB": 5.6,
    "MAR": 5.53,
    "APR": 4.98,
    "MAY": 4.22,
    "JUN": 3.79,
    "JUL": 4.11,
    "AUG": 4.98,
    "SEP": 4.64,
    "OCT": 5.14,
    "NOV": 5.53,
    "DEC": 5.63
  },
  {
    "LONG": -53.48,
    "LAT": -26.03,
    "CIDADE": "Pinhal de São Bento",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.63,
    "FEB": 5.56,
    "MAR": 5.55,
    "APR": 5.01,
    "MAY": 4.23,
    "JUN": 3.85,
    "JUL": 4.14,
    "AUG": 5.03,
    "SEP": 4.68,
    "OCT": 5.15,
    "NOV": 5.54,
    "DEC": 5.59
  },
  {
    "LONG": -52.81,
    "LAT": -25.96,
    "CIDADE": "Itapejara d'Oeste",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.53,
    "FEB": 5.45,
    "MAR": 5.57,
    "APR": 5.07,
    "MAY": 4.26,
    "JUN": 3.81,
    "JUL": 4.12,
    "AUG": 5.08,
    "SEP": 4.69,
    "OCT": 5.14,
    "NOV": 5.61,
    "DEC": 5.57
  },
  {
    "LONG": -52.56,
    "LAT": -25.98,
    "CIDADE": "Coronel Vivida",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.53,
    "FEB": 5.38,
    "MAR": 5.52,
    "APR": 5.01,
    "MAY": 4.24,
    "JUN": 3.87,
    "JUL": 4.12,
    "AUG": 5.05,
    "SEP": 4.65,
    "OCT": 5.11,
    "NOV": 5.53,
    "DEC": 5.46
  },
  {
    "LONG": -51.34,
    "LAT": -26.02,
    "CIDADE": "Cruz Machado",
    "UF": "PARANÁ",
    "anual": 4.5,
    "JAN": 5,
    "FEB": 4.97,
    "MAR": 5,
    "APR": 4.39,
    "MAY": 3.77,
    "JUN": 3.37,
    "JUL": 3.63,
    "AUG": 4.68,
    "SEP": 4.34,
    "OCT": 4.62,
    "NOV": 5.16,
    "DEC": 5.12
  },
  {
    "LONG": -50.83,
    "LAT": -2.6,
    "CIDADE": "Paulo Frontin",
    "UF": "PARANÁ",
    "anual": 4.25,
    "JAN": 4.67,
    "FEB": 4.72,
    "MAR": 4.7,
    "APR": 4.08,
    "MAY": 3.58,
    "JUN": 3.24,
    "JUL": 3.48,
    "AUG": 4.5,
    "SEP": 4.12,
    "OCT": 4.23,
    "NOV": 4.86,
    "DEC": 4.83
  },
  {
    "LONG": -50.2,
    "LAT": -25.98,
    "CIDADE": "Antônio Olinto",
    "UF": "PARANÁ",
    "anual": 4.37,
    "JAN": 4.85,
    "FEB": 4.86,
    "MAR": 4.84,
    "APR": 4.21,
    "MAY": 3.68,
    "JUN": 3.35,
    "JUL": 3.61,
    "AUG": 4.6,
    "SEP": 4.17,
    "OCT": 4.38,
    "NOV": 4.9,
    "DEC": 4.96
  },
  {
    "LONG": -49.68,
    "LAT": -25.98,
    "CIDADE": "Campo do Tenente",
    "UF": "PARANÁ",
    "anual": 4.37,
    "JAN": 4.86,
    "FEB": 4.91,
    "MAR": 4.76,
    "APR": 4.31,
    "MAY": 3.73,
    "JUN": 3.4,
    "JUL": 3.64,
    "AUG": 4.61,
    "SEP": 4.13,
    "OCT": 4.32,
    "NOV": 4.81,
    "DEC": 4.95
  },
  {
    "LONG": -49.33,
    "LAT": -25.99,
    "CIDADE": "Agudos do Sul",
    "UF": "PARANÁ",
    "anual": 4.35,
    "JAN": 4.82,
    "FEB": 4.87,
    "MAR": 4.73,
    "APR": 4.3,
    "MAY": 3.77,
    "JUN": 3.48,
    "JUL": 3.69,
    "AUG": 4.58,
    "SEP": 4.07,
    "OCT": 4.25,
    "NOV": 4.74,
    "DEC": 4.9
  },
  {
    "LONG": -5.37,
    "LAT": -25.88,
    "CIDADE": "Bela Vista da Caroba",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.65,
    "FEB": 5.62,
    "MAR": 5.59,
    "APR": 5.02,
    "MAY": 4.25,
    "JUN": 3.82,
    "JUL": 4.12,
    "AUG": 5.01,
    "SEP": 4.67,
    "OCT": 5.18,
    "NOV": 5.58,
    "DEC": 5.6
  },
  {
    "LONG": -53.47,
    "LAT": -25.92,
    "CIDADE": "Ampére",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.66,
    "FEB": 5.55,
    "MAR": 5.53,
    "APR": 5.01,
    "MAY": 4.25,
    "JUN": 3.87,
    "JUL": 4.13,
    "AUG": 5.04,
    "SEP": 4.67,
    "OCT": 5.15,
    "NOV": 5.53,
    "DEC": 5.6
  },
  {
    "LONG": -53.27,
    "LAT": -25.91,
    "CIDADE": "Nova Esperança do Sudoeste",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.56,
    "FEB": 5.49,
    "MAR": 5.55,
    "APR": 5.04,
    "MAY": 4.24,
    "JUN": 3.84,
    "JUL": 4.16,
    "AUG": 5.08,
    "SEP": 4.7,
    "OCT": 5.17,
    "NOV": 5.56,
    "DEC": 5.6
  },
  {
    "LONG": -53.17,
    "LAT": -2.59,
    "CIDADE": "Enéas Marques",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.55,
    "FEB": 5.48,
    "MAR": 5.54,
    "APR": 5.04,
    "MAY": 4.24,
    "JUN": 3.83,
    "JUL": 4.16,
    "AUG": 5.07,
    "SEP": 4.69,
    "OCT": 5.15,
    "NOV": 5.56,
    "DEC": 5.58
  },
  {
    "LONG": -52.91,
    "LAT": -25.88,
    "CIDADE": "Verê",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.57,
    "FEB": 5.51,
    "MAR": 5.58,
    "APR": 5.08,
    "MAY": 4.24,
    "JUN": 3.81,
    "JUL": 4.14,
    "AUG": 5.08,
    "SEP": 4.7,
    "OCT": 5.15,
    "NOV": 5.63,
    "DEC": 5.6
  },
  {
    "LONG": -52.52,
    "LAT": -2.59,
    "CIDADE": "Chopinzinho",
    "UF": "PARANÁ",
    "anual": 4.97,
    "JAN": 5.53,
    "FEB": 5.42,
    "MAR": 5.5,
    "APR": 5.03,
    "MAY": 4.24,
    "JUN": 3.86,
    "JUL": 4.14,
    "AUG": 5.07,
    "SEP": 4.66,
    "OCT": 5.14,
    "NOV": 5.54,
    "DEC": 5.47
  },
  {
    "LONG": -52.17,
    "LAT": -25.94,
    "CIDADE": "Mangueirinha",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.49,
    "FEB": 5.43,
    "MAR": 5.53,
    "APR": 5.02,
    "MAY": 4.2,
    "JUN": 3.84,
    "JUL": 4.09,
    "AUG": 5.07,
    "SEP": 4.65,
    "OCT": 5.12,
    "NOV": 5.55,
    "DEC": 5.51
  },
  {
    "LONG": -50.82,
    "LAT": -25.88,
    "CIDADE": "Mallet",
    "UF": "PARANÁ",
    "anual": 4.29,
    "JAN": 4.71,
    "FEB": 4.76,
    "MAR": 4.68,
    "APR": 4.14,
    "MAY": 3.62,
    "JUN": 3.31,
    "JUL": 3.55,
    "AUG": 4.57,
    "SEP": 4.15,
    "OCT": 4.24,
    "NOV": 4.87,
    "DEC": 4.84
  },
  {
    "LONG": -50.38,
    "LAT": -25.87,
    "CIDADE": "São Mateus do Sul",
    "UF": "PARANÁ",
    "anual": 4.34,
    "JAN": 4.84,
    "FEB": 4.86,
    "MAR": 4.75,
    "APR": 4.15,
    "MAY": 3.66,
    "JUN": 3.31,
    "JUL": 3.57,
    "AUG": 4.58,
    "SEP": 4.2,
    "OCT": 4.39,
    "NOV": 4.92,
    "DEC": 4.9
  },
  {
    "LONG": -49.5,
    "LAT": -25.87,
    "CIDADE": "Quitandinha",
    "UF": "PARANÁ",
    "anual": 4.38,
    "JAN": 4.86,
    "FEB": 4.9,
    "MAR": 4.72,
    "APR": 4.31,
    "MAY": 3.77,
    "JUN": 3.48,
    "JUL": 3.7,
    "AUG": 4.62,
    "SEP": 4.12,
    "OCT": 4.29,
    "NOV": 4.82,
    "DEC": 4.92
  },
  {
    "LONG": -49.2,
    "LAT": -25.93,
    "CIDADE": "Tijucas do Sul",
    "UF": "PARANÁ",
    "anual": 4.32,
    "JAN": 4.77,
    "FEB": 4.85,
    "MAR": 4.65,
    "APR": 4.27,
    "MAY": 3.8,
    "JUN": 3.52,
    "JUL": 3.65,
    "AUG": 4.57,
    "SEP": 4.04,
    "OCT": 4.19,
    "NOV": 4.69,
    "DEC": 4.81
  },
  {
    "LONG": -48.58,
    "LAT": -25.88,
    "CIDADE": "Guaratuba",
    "UF": "PARANÁ",
    "anual": 4.15,
    "JAN": 4.87,
    "FEB": 5.02,
    "MAR": 4.71,
    "APR": 4.28,
    "MAY": 3.8,
    "JUN": 3.28,
    "JUL": 3.25,
    "AUG": 3.86,
    "SEP": 3.57,
    "OCT": 3.86,
    "NOV": 4.55,
    "DEC": 4.75
  },
  {
    "LONG": -53.75,
    "LAT": -25.82,
    "CIDADE": "Pérola d'Oeste",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.64,
    "FEB": 5.63,
    "MAR": 5.6,
    "APR": 5.05,
    "MAY": 4.25,
    "JUN": 3.82,
    "JUL": 4.11,
    "AUG": 5.01,
    "SEP": 4.66,
    "OCT": 5.18,
    "NOV": 5.58,
    "DEC": 5.62
  },
  {
    "LONG": -53.53,
    "LAT": -25.77,
    "CIDADE": "Realeza",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.67,
    "FEB": 5.61,
    "MAR": 5.62,
    "APR": 5.03,
    "MAY": 4.28,
    "JUN": 3.84,
    "JUL": 4.11,
    "AUG": 5.02,
    "SEP": 4.66,
    "OCT": 5.2,
    "NOV": 5.58,
    "DEC": 5.63
  },
  {
    "LONG": -53.48,
    "LAT": -25.82,
    "CIDADE": "Santa Izabel do Oeste",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.66,
    "FEB": 5.59,
    "MAR": 5.56,
    "APR": 5.03,
    "MAY": 4.26,
    "JUN": 3.86,
    "JUL": 4.13,
    "AUG": 5.05,
    "SEP": 4.68,
    "OCT": 5.19,
    "NOV": 5.56,
    "DEC": 5.63
  },
  {
    "LONG": -5.33,
    "LAT": -25.78,
    "CIDADE": "Salto do Lontra",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.64,
    "FEB": 5.58,
    "MAR": 5.56,
    "APR": 5.07,
    "MAY": 4.26,
    "JUN": 3.86,
    "JUL": 4.12,
    "AUG": 5.07,
    "SEP": 4.7,
    "OCT": 5.21,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -52.73,
    "LAT": -25.82,
    "CIDADE": "São João",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.61,
    "FEB": 5.51,
    "MAR": 5.56,
    "APR": 5.04,
    "MAY": 4.25,
    "JUN": 3.87,
    "JUL": 4.16,
    "AUG": 5.07,
    "SEP": 4.71,
    "OCT": 5.16,
    "NOV": 5.58,
    "DEC": 5.55
  },
  {
    "LONG": -52.03,
    "LAT": -25.83,
    "CIDADE": "Reserva do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.46,
    "FEB": 5.33,
    "MAR": 5.5,
    "APR": 5.01,
    "MAY": 4.22,
    "JUN": 3.87,
    "JUL": 4.1,
    "AUG": 5.08,
    "SEP": 4.66,
    "OCT": 5.1,
    "NOV": 5.45,
    "DEC": 5.44
  },
  {
    "LONG": -49.72,
    "LAT": -25.77,
    "CIDADE": "Lapa",
    "UF": "PARANÁ",
    "anual": 4.42,
    "JAN": 4.8,
    "FEB": 4.91,
    "MAR": 4.76,
    "APR": 4.35,
    "MAY": 3.8,
    "JUN": 3.54,
    "JUL": 3.73,
    "AUG": 4.74,
    "SEP": 4.22,
    "OCT": 4.38,
    "NOV": 4.89,
    "DEC": 4.96
  },
  {
    "LONG": -49.33,
    "LAT": -25.78,
    "CIDADE": "Mandirituba",
    "UF": "PARANÁ",
    "anual": 4.38,
    "JAN": 4.83,
    "FEB": 4.88,
    "MAR": 4.71,
    "APR": 4.33,
    "MAY": 3.79,
    "JUN": 3.52,
    "JUL": 3.7,
    "AUG": 4.64,
    "SEP": 4.12,
    "OCT": 4.28,
    "NOV": 4.79,
    "DEC": 4.93
  },
  {
    "LONG": -48.55,
    "LAT": -25.82,
    "CIDADE": "Matinhos",
    "UF": "PARANÁ",
    "anual": 4.02,
    "JAN": 4.74,
    "FEB": 4.85,
    "MAR": 4.55,
    "APR": 4.16,
    "MAY": 3.71,
    "JUN": 3.23,
    "JUL": 3.22,
    "AUG": 3.8,
    "SEP": 3.41,
    "OCT": 3.64,
    "NOV": 4.36,
    "DEC": 4.57
  },
  {
    "LONG": -5.38,
    "LAT": -25.67,
    "CIDADE": "Capanema",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.62,
    "FEB": 5.58,
    "MAR": 5.63,
    "APR": 5.03,
    "MAY": 4.21,
    "JUN": 3.77,
    "JUL": 4.04,
    "AUG": 4.98,
    "SEP": 4.65,
    "OCT": 5.18,
    "NOV": 5.55,
    "DEC": 5.62
  },
  {
    "LONG": -53.76,
    "LAT": -25.72,
    "CIDADE": "Planalto",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.62,
    "FEB": 5.6,
    "MAR": 5.61,
    "APR": 5.03,
    "MAY": 4.22,
    "JUN": 3.79,
    "JUL": 4.07,
    "AUG": 5,
    "SEP": 4.65,
    "OCT": 5.18,
    "NOV": 5.55,
    "DEC": 5.62
  },
  {
    "LONG": -53.06,
    "LAT": -25.74,
    "CIDADE": "Dois Vizinhos",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.64,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.06,
    "MAY": 4.22,
    "JUN": 3.81,
    "JUL": 4.1,
    "AUG": 5.05,
    "SEP": 4.69,
    "OCT": 5.17,
    "NOV": 5.6,
    "DEC": 5.6
  },
  {
    "LONG": -52.91,
    "LAT": -25.7,
    "CIDADE": "São Jorge d'Oeste",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.57,
    "FEB": 5.47,
    "MAR": 5.55,
    "APR": 5.07,
    "MAY": 4.22,
    "JUN": 3.81,
    "JUL": 4.06,
    "AUG": 5.03,
    "SEP": 4.7,
    "OCT": 5.16,
    "NOV": 5.59,
    "DEC": 5.56
  },
  {
    "LONG": -52.73,
    "LAT": -2.57,
    "CIDADE": "Sulina",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.51,
    "FEB": 5.49,
    "MAR": 5.54,
    "APR": 5.07,
    "MAY": 4.26,
    "JUN": 3.88,
    "JUL": 4.1,
    "AUG": 5.05,
    "SEP": 4.71,
    "OCT": 5.18,
    "NOV": 5.6,
    "DEC": 5.53
  },
  {
    "LONG": -52.62,
    "LAT": -25.69,
    "CIDADE": "Saudade do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.49,
    "FEB": 5.45,
    "MAR": 5.58,
    "APR": 5.07,
    "MAY": 4.25,
    "JUN": 3.88,
    "JUL": 4.09,
    "AUG": 5.06,
    "SEP": 4.7,
    "OCT": 5.17,
    "NOV": 5.57,
    "DEC": 5.49
  },
  {
    "LONG": -52.12,
    "LAT": -25.74,
    "CIDADE": "Foz do Jordão",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.45,
    "FEB": 5.34,
    "MAR": 5.5,
    "APR": 5.05,
    "MAY": 4.24,
    "JUN": 3.85,
    "JUL": 4.1,
    "AUG": 5.08,
    "SEP": 4.68,
    "OCT": 5.12,
    "NOV": 5.51,
    "DEC": 5.5
  },
  {
    "LONG": -5.17,
    "LAT": -25.69,
    "CIDADE": "Pinhão",
    "UF": "PARANÁ",
    "anual": 4.84,
    "JAN": 5.26,
    "FEB": 5.17,
    "MAR": 5.31,
    "APR": 4.81,
    "MAY": 4.12,
    "JUN": 3.86,
    "JUL": 4.1,
    "AUG": 5.07,
    "SEP": 4.69,
    "OCT": 4.99,
    "NOV": 5.32,
    "DEC": 5.34
  },
  {
    "LONG": -5.08,
    "LAT": -25.73,
    "CIDADE": "Rio Azul",
    "UF": "PARANÁ",
    "anual": 4.4,
    "JAN": 4.88,
    "FEB": 4.94,
    "MAR": 4.74,
    "APR": 4.27,
    "MAY": 3.72,
    "JUN": 3.38,
    "JUL": 3.71,
    "AUG": 4.68,
    "SEP": 4.25,
    "OCT": 4.39,
    "NOV": 4.97,
    "DEC": 4.92
  },
  {
    "LONG": -50.3,
    "LAT": -25.68,
    "CIDADE": "São João do Triunfo",
    "UF": "PARANÁ",
    "anual": 4.43,
    "JAN": 4.85,
    "FEB": 4.88,
    "MAR": 4.78,
    "APR": 4.31,
    "MAY": 3.76,
    "JUN": 3.52,
    "JUL": 3.7,
    "AUG": 4.72,
    "SEP": 4.27,
    "OCT": 4.43,
    "NOV": 4.96,
    "DEC": 4.94
  },
  {
    "LONG": -49.54,
    "LAT": -25.68,
    "CIDADE": "Contenda",
    "UF": "PARANÁ",
    "anual": 4.42,
    "JAN": 4.83,
    "FEB": 4.93,
    "MAR": 4.73,
    "APR": 4.35,
    "MAY": 3.81,
    "JUN": 3.59,
    "JUL": 3.74,
    "AUG": 4.7,
    "SEP": 4.2,
    "OCT": 4.35,
    "NOV": 4.86,
    "DEC": 4.94
  },
  {
    "LONG": -49.31,
    "LAT": -25.66,
    "CIDADE": "Fazenda Rio Grande",
    "UF": "PARANÁ",
    "anual": 4.39,
    "JAN": 4.82,
    "FEB": 4.95,
    "MAR": 4.72,
    "APR": 4.33,
    "MAY": 3.77,
    "JUN": 3.55,
    "JUL": 3.69,
    "AUG": 4.65,
    "SEP": 4.13,
    "OCT": 4.28,
    "NOV": 4.8,
    "DEC": 4.94
  },
  {
    "LONG": -48.51,
    "LAT": -2.57,
    "CIDADE": "Pontal do Paraná",
    "UF": "PARANÁ",
    "anual": 3.97,
    "JAN": 4.6,
    "FEB": 4.82,
    "MAR": 4.46,
    "APR": 4.08,
    "MAY": 3.7,
    "JUN": 3.24,
    "JUL": 3.22,
    "AUG": 3.83,
    "SEP": 3.37,
    "OCT": 3.59,
    "NOV": 4.26,
    "DEC": 4.53
  },
  {
    "LONG": -53.35,
    "LAT": -25.63,
    "CIDADE": "Nova Prata do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.62,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.02,
    "MAY": 4.2,
    "JUN": 3.81,
    "JUL": 4.05,
    "AUG": 5.02,
    "SEP": 4.62,
    "OCT": 5.18,
    "NOV": 5.56,
    "DEC": 5.61
  },
  {
    "LONG": -53.21,
    "LAT": -25.63,
    "CIDADE": "Boa Esperança do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.63,
    "FEB": 5.53,
    "MAR": 5.59,
    "APR": 5.04,
    "MAY": 4.19,
    "JUN": 3.81,
    "JUL": 4.06,
    "AUG": 5.01,
    "SEP": 4.63,
    "OCT": 5.17,
    "NOV": 5.55,
    "DEC": 5.6
  },
  {
    "LONG": -53.13,
    "LAT": -25.62,
    "CIDADE": "Cruzeiro do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.59,
    "FEB": 5.52,
    "MAR": 5.59,
    "APR": 5.06,
    "MAY": 4.2,
    "JUN": 3.8,
    "JUL": 4.06,
    "AUG": 5.01,
    "SEP": 4.64,
    "OCT": 5.17,
    "NOV": 5.56,
    "DEC": 5.6
  },
  {
    "LONG": -52.04,
    "LAT": -25.58,
    "CIDADE": "Candói",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.38,
    "FEB": 5.31,
    "MAR": 5.44,
    "APR": 5.01,
    "MAY": 4.22,
    "JUN": 3.88,
    "JUL": 4.11,
    "AUG": 5.1,
    "SEP": 4.7,
    "OCT": 5.09,
    "NOV": 5.45,
    "DEC": 5.44
  },
  {
    "LONG": -51.08,
    "LAT": -25.57,
    "CIDADE": "Inácio Martins",
    "UF": "PARANÁ",
    "anual": 4.46,
    "JAN": 4.8,
    "FEB": 4.84,
    "MAR": 4.75,
    "APR": 4.37,
    "MAY": 3.8,
    "JUN": 3.56,
    "JUL": 3.82,
    "AUG": 4.85,
    "SEP": 4.38,
    "OCT": 4.48,
    "NOV": 4.99,
    "DEC": 4.92
  },
  {
    "LONG": -50.69,
    "LAT": -25.62,
    "CIDADE": "Rebouças",
    "UF": "PARANÁ",
    "anual": 4.46,
    "JAN": 4.93,
    "FEB": 4.94,
    "MAR": 4.77,
    "APR": 4.35,
    "MAY": 3.81,
    "JUN": 3.43,
    "JUL": 3.76,
    "AUG": 4.76,
    "SEP": 4.31,
    "OCT": 4.48,
    "NOV": 5.04,
    "DEC": 4.96
  },
  {
    "LONG": -49.63,
    "LAT": -25.58,
    "CIDADE": "Balsa Nova",
    "UF": "PARANÁ",
    "anual": 4.47,
    "JAN": 4.85,
    "FEB": 4.96,
    "MAR": 4.81,
    "APR": 4.42,
    "MAY": 3.85,
    "JUN": 3.64,
    "JUL": 3.81,
    "AUG": 4.79,
    "SEP": 4.26,
    "OCT": 4.41,
    "NOV": 4.9,
    "DEC": 4.97
  },
  {
    "LONG": -49.41,
    "LAT": -25.59,
    "CIDADE": "Araucária",
    "UF": "PARANÁ",
    "anual": 4.41,
    "JAN": 4.84,
    "FEB": 4.92,
    "MAR": 4.71,
    "APR": 4.37,
    "MAY": 3.8,
    "JUN": 3.57,
    "JUL": 3.73,
    "AUG": 4.69,
    "SEP": 4.18,
    "OCT": 4.34,
    "NOV": 4.85,
    "DEC": 4.95
  },
  {
    "LONG": -54.58,
    "LAT": -25.54,
    "CIDADE": "Foz do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.69,
    "FEB": 5.65,
    "MAR": 5.67,
    "APR": 4.95,
    "MAY": 4.19,
    "JUN": 3.74,
    "JUL": 3.97,
    "AUG": 4.87,
    "SEP": 4.67,
    "OCT": 5.2,
    "NOV": 5.58,
    "DEC": 5.73
  },
  {
    "LONG": -53.61,
    "LAT": -25.48,
    "CIDADE": "Capitão Leônidas Marques",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.48,
    "FEB": 5.46,
    "MAR": 5.54,
    "APR": 4.98,
    "MAY": 4.21,
    "JUN": 3.8,
    "JUL": 4.01,
    "AUG": 4.99,
    "SEP": 4.63,
    "OCT": 5.15,
    "NOV": 5.5,
    "DEC": 5.49
  },
  {
    "LONG": -52.53,
    "LAT": -25.49,
    "CIDADE": "Rio Bonito do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.4,
    "FEB": 5.34,
    "MAR": 5.47,
    "APR": 5.04,
    "MAY": 4.23,
    "JUN": 3.9,
    "JUL": 4.14,
    "AUG": 5.06,
    "SEP": 4.7,
    "OCT": 5.09,
    "NOV": 5.49,
    "DEC": 5.4
  },
  {
    "LONG": -52.41,
    "LAT": -25.55,
    "CIDADE": "Porto Barreiro",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.4,
    "FEB": 5.34,
    "MAR": 5.45,
    "APR": 5.02,
    "MAY": 4.23,
    "JUN": 3.91,
    "JUL": 4.17,
    "AUG": 5.09,
    "SEP": 4.73,
    "OCT": 5.08,
    "NOV": 5.48,
    "DEC": 5.4
  },
  {
    "LONG": -50.65,
    "LAT": -25.47,
    "CIDADE": "Irati",
    "UF": "PARANÁ",
    "anual": 4.52,
    "JAN": 4.97,
    "FEB": 5.02,
    "MAR": 4.81,
    "APR": 4.41,
    "MAY": 3.86,
    "JUN": 3.53,
    "JUL": 3.82,
    "AUG": 4.82,
    "SEP": 4.4,
    "OCT": 4.54,
    "NOV": 5.09,
    "DEC": 5.02
  },
  {
    "LONG": -4.99,
    "LAT": -25.54,
    "CIDADE": "Porto Amazonas",
    "UF": "PARANÁ",
    "anual": 4.53,
    "JAN": 4.89,
    "FEB": 5.02,
    "MAR": 4.9,
    "APR": 4.5,
    "MAY": 3.86,
    "JUN": 3.71,
    "JUL": 3.87,
    "AUG": 4.86,
    "SEP": 4.36,
    "OCT": 4.5,
    "NOV": 4.92,
    "DEC": 5.01
  },
  {
    "LONG": -49.53,
    "LAT": -2.55,
    "CIDADE": "Campo Largo",
    "UF": "PARANÁ",
    "anual": 4.4,
    "JAN": 4.77,
    "FEB": 4.94,
    "MAR": 4.75,
    "APR": 4.37,
    "MAY": 3.81,
    "JUN": 3.52,
    "JUL": 3.74,
    "AUG": 4.71,
    "SEP": 4.19,
    "OCT": 4.31,
    "NOV": 4.84,
    "DEC": 4.89
  },
  {
    "LONG": -49.2,
    "LAT": -25.53,
    "CIDADE": "São José dos Pinhais",
    "UF": "PARANÁ",
    "anual": 4.42,
    "JAN": 4.79,
    "FEB": 4.94,
    "MAR": 4.71,
    "APR": 4.4,
    "MAY": 3.87,
    "JUN": 3.64,
    "JUL": 3.76,
    "AUG": 4.71,
    "SEP": 4.17,
    "OCT": 4.33,
    "NOV": 4.82,
    "DEC": 4.91
  },
  {
    "LONG": -4.88,
    "LAT": -25.47,
    "CIDADE": "Morretes",
    "UF": "PARANÁ",
    "anual": 3.97,
    "JAN": 4.5,
    "FEB": 4.65,
    "MAR": 4.37,
    "APR": 4.08,
    "MAY": 3.68,
    "JUN": 3.36,
    "JUL": 3.37,
    "AUG": 4.03,
    "SEP": 3.41,
    "OCT": 3.58,
    "NOV": 4.21,
    "DEC": 4.45
  },
  {
    "LONG": -48.52,
    "LAT": -25.52,
    "CIDADE": "Paranaguá",
    "UF": "PARANÁ",
    "anual": 4.08,
    "JAN": 4.76,
    "FEB": 4.97,
    "MAR": 4.58,
    "APR": 4.21,
    "MAY": 3.76,
    "JUN": 3.33,
    "JUL": 3.26,
    "AUG": 3.89,
    "SEP": 3.45,
    "OCT": 3.67,
    "NOV": 4.4,
    "DEC": 4.69
  },
  {
    "LONG": -54.4,
    "LAT": -25.43,
    "CIDADE": "Santa Terezinha de Itaipu",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.77,
    "FEB": 5.68,
    "MAR": 5.68,
    "APR": 4.98,
    "MAY": 4.21,
    "JUN": 3.77,
    "JUL": 4,
    "AUG": 4.89,
    "SEP": 4.65,
    "OCT": 5.18,
    "NOV": 5.57,
    "DEC": 5.8
  },
  {
    "LONG": -54.05,
    "LAT": -25.38,
    "CIDADE": "Serranópolis do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.53,
    "FEB": 5.45,
    "MAR": 5.48,
    "APR": 4.98,
    "MAY": 4.17,
    "JUN": 3.78,
    "JUL": 4.03,
    "AUG": 4.96,
    "SEP": 4.69,
    "OCT": 5.13,
    "NOV": 5.46,
    "DEC": 5.6
  },
  {
    "LONG": -53.56,
    "LAT": -25.41,
    "CIDADE": "Santa Lúcia",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.43,
    "FEB": 5.39,
    "MAR": 5.45,
    "APR": 4.99,
    "MAY": 4.21,
    "JUN": 3.82,
    "JUL": 4.04,
    "AUG": 5.07,
    "SEP": 4.66,
    "OCT": 5.14,
    "NOV": 5.47,
    "DEC": 5.48
  },
  {
    "LONG": -53.41,
    "LAT": -25.43,
    "CIDADE": "Boa Vista da Aparecida",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.45,
    "FEB": 5.41,
    "MAR": 5.48,
    "APR": 4.99,
    "MAY": 4.21,
    "JUN": 3.84,
    "JUL": 4.06,
    "AUG": 5.06,
    "SEP": 4.67,
    "OCT": 5.13,
    "NOV": 5.49,
    "DEC": 5.49
  },
  {
    "LONG": -53.18,
    "LAT": -2.54,
    "CIDADE": "Três Barras do Paraná",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.52,
    "FEB": 5.37,
    "MAR": 5.49,
    "APR": 5.02,
    "MAY": 4.23,
    "JUN": 3.85,
    "JUL": 4.07,
    "AUG": 5.07,
    "SEP": 4.67,
    "OCT": 5.09,
    "NOV": 5.47,
    "DEC": 5.47
  },
  {
    "LONG": -52.91,
    "LAT": -25.45,
    "CIDADE": "Quedas do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.97,
    "JAN": 5.55,
    "FEB": 5.4,
    "MAR": 5.5,
    "APR": 5.07,
    "MAY": 4.23,
    "JUN": 3.88,
    "JUL": 4.11,
    "AUG": 5.09,
    "SEP": 4.71,
    "OCT": 5.12,
    "NOV": 5.51,
    "DEC": 5.49
  },
  {
    "LONG": -52.84,
    "LAT": -25.42,
    "CIDADE": "Espigão Alto do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.52,
    "FEB": 5.37,
    "MAR": 5.46,
    "APR": 5.05,
    "MAY": 4.24,
    "JUN": 3.9,
    "JUL": 4.13,
    "AUG": 5.07,
    "SEP": 4.71,
    "OCT": 5.1,
    "NOV": 5.49,
    "DEC": 5.47
  },
  {
    "LONG": -52.41,
    "LAT": -25.41,
    "CIDADE": "Laranjeiras do Sul",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.42,
    "FEB": 5.34,
    "MAR": 5.47,
    "APR": 5.03,
    "MAY": 4.25,
    "JUN": 3.97,
    "JUL": 4.21,
    "AUG": 5.14,
    "SEP": 4.75,
    "OCT": 5.1,
    "NOV": 5.48,
    "DEC": 5.4
  },
  {
    "LONG": -52.2,
    "LAT": -25.38,
    "CIDADE": "Virmond",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.34,
    "FEB": 5.28,
    "MAR": 5.41,
    "APR": 5.04,
    "MAY": 4.25,
    "JUN": 3.92,
    "JUL": 4.18,
    "AUG": 5.11,
    "SEP": 4.75,
    "OCT": 5.06,
    "NOV": 5.45,
    "DEC": 5.39
  },
  {
    "LONG": -52.12,
    "LAT": -25.37,
    "CIDADE": "Cantagalo",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.34,
    "FEB": 5.3,
    "MAR": 5.42,
    "APR": 5.06,
    "MAY": 4.27,
    "JUN": 3.91,
    "JUL": 4.17,
    "AUG": 5.12,
    "SEP": 4.75,
    "OCT": 5.06,
    "NOV": 5.47,
    "DEC": 5.38
  },
  {
    "LONG": -51.46,
    "LAT": -25.39,
    "CIDADE": "Guarapuava",
    "UF": "PARANÁ",
    "anual": 4.79,
    "JAN": 5.15,
    "FEB": 5.1,
    "MAR": 5.12,
    "APR": 4.83,
    "MAY": 4.11,
    "JUN": 3.82,
    "JUL": 4.12,
    "AUG": 5.05,
    "SEP": 4.68,
    "OCT": 4.9,
    "NOV": 5.31,
    "DEC": 5.25
  },
  {
    "LONG": -5.05,
    "LAT": -25.41,
    "CIDADE": "Fernandes Pinheiro",
    "UF": "PARANÁ",
    "anual": 4.55,
    "JAN": 4.95,
    "FEB": 5.02,
    "MAR": 4.86,
    "APR": 4.51,
    "MAY": 3.9,
    "JUN": 3.56,
    "JUL": 3.86,
    "AUG": 4.85,
    "SEP": 4.42,
    "OCT": 4.57,
    "NOV": 5.08,
    "DEC": 5.02
  },
  {
    "LONG": -50.46,
    "LAT": -25.37,
    "CIDADE": "Teixeira Soares",
    "UF": "PARANÁ",
    "anual": 4.57,
    "JAN": 4.99,
    "FEB": 5.03,
    "MAR": 4.91,
    "APR": 4.58,
    "MAY": 3.94,
    "JUN": 3.59,
    "JUL": 3.88,
    "AUG": 4.86,
    "SEP": 4.42,
    "OCT": 4.59,
    "NOV": 5.09,
    "DEC": 5.02
  },
  {
    "LONG": -50.01,
    "LAT": -25.43,
    "CIDADE": "Palmeira",
    "UF": "PARANÁ",
    "anual": 4.6,
    "JAN": 4.98,
    "FEB": 5.1,
    "MAR": 4.92,
    "APR": 4.57,
    "MAY": 3.95,
    "JUN": 3.71,
    "JUL": 3.91,
    "AUG": 4.9,
    "SEP": 4.43,
    "OCT": 4.58,
    "NOV": 5.04,
    "DEC": 5.08
  },
  {
    "LONG": -49.45,
    "LAT": -25.37,
    "CIDADE": "Campo Magro",
    "UF": "PARANÁ",
    "anual": 4.42,
    "JAN": 4.76,
    "FEB": 4.98,
    "MAR": 4.8,
    "APR": 4.4,
    "MAY": 3.8,
    "JUN": 3.54,
    "JUL": 3.74,
    "AUG": 4.74,
    "SEP": 4.21,
    "OCT": 4.34,
    "NOV": 4.86,
    "DEC": 4.94
  },
  {
    "LONG": -49.27,
    "LAT": -25.42,
    "CIDADE": "Curitiba",
    "UF": "PARANÁ",
    "anual": 4.41,
    "JAN": 4.78,
    "FEB": 4.94,
    "MAR": 4.75,
    "APR": 4.38,
    "MAY": 3.83,
    "JUN": 3.65,
    "JUL": 3.75,
    "AUG": 4.72,
    "SEP": 4.15,
    "OCT": 4.27,
    "NOV": 4.79,
    "DEC": 4.91
  },
  {
    "LONG": -49.19,
    "LAT": -25.44,
    "CIDADE": "Pinhais",
    "UF": "PARANÁ",
    "anual": 4.39,
    "JAN": 4.76,
    "FEB": 4.93,
    "MAR": 4.71,
    "APR": 4.37,
    "MAY": 3.82,
    "JUN": 3.6,
    "JUL": 3.75,
    "AUG": 4.67,
    "SEP": 4.11,
    "OCT": 4.25,
    "NOV": 4.77,
    "DEC": 4.91
  },
  {
    "LONG": -49.08,
    "LAT": -25.37,
    "CIDADE": "Quatro Barras",
    "UF": "PARANÁ",
    "anual": 4.37,
    "JAN": 4.78,
    "FEB": 4.93,
    "MAR": 4.7,
    "APR": 4.32,
    "MAY": 3.82,
    "JUN": 3.57,
    "JUL": 3.73,
    "AUG": 4.62,
    "SEP": 4.1,
    "OCT": 4.27,
    "NOV": 4.76,
    "DEC": 4.9
  },
  {
    "LONG": -49.06,
    "LAT": -25.44,
    "CIDADE": "Piraquara",
    "UF": "PARANÁ",
    "anual": 4.37,
    "JAN": 4.78,
    "FEB": 4.93,
    "MAR": 4.7,
    "APR": 4.32,
    "MAY": 3.82,
    "JUN": 3.57,
    "JUL": 3.73,
    "AUG": 4.62,
    "SEP": 4.1,
    "OCT": 4.27,
    "NOV": 4.76,
    "DEC": 4.9
  },
  {
    "LONG": -48.72,
    "LAT": -25.44,
    "CIDADE": "Antonina",
    "UF": "PARANÁ",
    "anual": 4.01,
    "JAN": 4.54,
    "FEB": 4.72,
    "MAR": 4.48,
    "APR": 4.17,
    "MAY": 3.72,
    "JUN": 3.38,
    "JUL": 3.29,
    "AUG": 3.98,
    "SEP": 3.43,
    "OCT": 3.59,
    "NOV": 4.27,
    "DEC": 4.53
  },
  {
    "LONG": -5.42,
    "LAT": -25.35,
    "CIDADE": "São Miguel do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.63,
    "FEB": 5.53,
    "MAR": 5.6,
    "APR": 5,
    "MAY": 4.19,
    "JUN": 3.79,
    "JUL": 4,
    "AUG": 4.92,
    "SEP": 4.69,
    "OCT": 5.14,
    "NOV": 5.54,
    "DEC": 5.73
  },
  {
    "LONG": -54.09,
    "LAT": -25.3,
    "CIDADE": "Medianeira",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.53,
    "FEB": 5.48,
    "MAR": 5.46,
    "APR": 4.95,
    "MAY": 4.16,
    "JUN": 3.82,
    "JUL": 4.05,
    "AUG": 4.98,
    "SEP": 4.71,
    "OCT": 5.14,
    "NOV": 5.45,
    "DEC": 5.58
  },
  {
    "LONG": -53.57,
    "LAT": -25.26,
    "CIDADE": "Lindoeste",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.42,
    "FEB": 5.36,
    "MAR": 5.4,
    "APR": 4.97,
    "MAY": 4.2,
    "JUN": 3.84,
    "JUL": 4.09,
    "AUG": 5.09,
    "SEP": 4.7,
    "OCT": 5.1,
    "NOV": 5.42,
    "DEC": 5.45
  },
  {
    "LONG": -52.55,
    "LAT": -25.31,
    "CIDADE": "Nova Laranjeiras",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.44,
    "FEB": 5.28,
    "MAR": 5.47,
    "APR": 5.07,
    "MAY": 4.23,
    "JUN": 3.94,
    "JUL": 4.18,
    "AUG": 5.15,
    "SEP": 4.74,
    "OCT": 5.12,
    "NOV": 5.46,
    "DEC": 5.42
  },
  {
    "LONG": -49.3,
    "LAT": -25.32,
    "CIDADE": "Almirante Tamandaré",
    "UF": "PARANÁ",
    "anual": 4.45,
    "JAN": 4.77,
    "FEB": 4.99,
    "MAR": 4.84,
    "APR": 4.41,
    "MAY": 3.81,
    "JUN": 3.61,
    "JUL": 3.79,
    "AUG": 4.78,
    "SEP": 4.24,
    "OCT": 4.35,
    "NOV": 4.85,
    "DEC": 4.97
  },
  {
    "LONG": -49.23,
    "LAT": -2.53,
    "CIDADE": "Colombo",
    "UF": "PARANÁ",
    "anual": 4.41,
    "JAN": 4.72,
    "FEB": 4.97,
    "MAR": 4.77,
    "APR": 4.37,
    "MAY": 3.8,
    "JUN": 3.64,
    "JUL": 3.76,
    "AUG": 4.73,
    "SEP": 4.17,
    "OCT": 4.26,
    "NOV": 4.8,
    "DEC": 4.91
  },
  {
    "LONG": -49.06,
    "LAT": -25.3,
    "CIDADE": "Campina Grande do Sul",
    "UF": "PARANÁ",
    "anual": 4.39,
    "JAN": 4.77,
    "FEB": 4.95,
    "MAR": 4.75,
    "APR": 4.32,
    "MAY": 3.83,
    "JUN": 3.59,
    "JUL": 3.75,
    "AUG": 4.66,
    "SEP": 4.09,
    "OCT": 4.24,
    "NOV": 4.76,
    "DEC": 4.92
  },
  {
    "LONG": -48.32,
    "LAT": -25.31,
    "CIDADE": "Guaraqueçaba",
    "UF": "PARANÁ",
    "anual": 4.08,
    "JAN": 4.77,
    "FEB": 4.99,
    "MAR": 4.57,
    "APR": 4.23,
    "MAY": 3.75,
    "JUN": 3.36,
    "JUL": 3.23,
    "AUG": 3.88,
    "SEP": 3.51,
    "OCT": 3.6,
    "NOV": 4.37,
    "DEC": 4.72
  },
  {
    "LONG": -53.99,
    "LAT": "#VALOR!",
    "CIDADE": "Matelândia",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.5,
    "FEB": 5.49,
    "MAR": 5.42,
    "APR": 4.92,
    "MAY": 4.22,
    "JUN": 3.86,
    "JUL": 4.08,
    "AUG": 5.06,
    "SEP": 4.73,
    "OCT": 5.14,
    "NOV": 5.43,
    "DEC": 5.57
  },
  {
    "LONG": -53.16,
    "LAT": -25.2,
    "CIDADE": "Catanduvas",
    "UF": "PARANÁ",
    "anual": 4.94,
    "JAN": 5.5,
    "FEB": 5.31,
    "MAR": 5.36,
    "APR": 4.99,
    "MAY": 4.24,
    "JUN": 3.93,
    "JUL": 4.17,
    "AUG": 5.15,
    "SEP": 4.74,
    "OCT": 5.05,
    "NOV": 5.36,
    "DEC": 5.44
  },
  {
    "LONG": -51.99,
    "LAT": -25.19,
    "CIDADE": "Goioxim",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.31,
    "FEB": 5.3,
    "MAR": 5.44,
    "APR": 5.06,
    "MAY": 4.27,
    "JUN": 3.97,
    "JUL": 4.26,
    "AUG": 5.16,
    "SEP": 4.78,
    "OCT": 5.11,
    "NOV": 5.41,
    "DEC": 5.36
  },
  {
    "LONG": -50.98,
    "LAT": -25.21,
    "CIDADE": "Prudentópolis",
    "UF": "PARANÁ",
    "anual": 4.68,
    "JAN": 5.12,
    "FEB": 5.16,
    "MAR": 5.02,
    "APR": 4.67,
    "MAY": 4.01,
    "JUN": 3.65,
    "JUL": 3.95,
    "AUG": 4.9,
    "SEP": 4.56,
    "OCT": 4.72,
    "NOV": 5.18,
    "DEC": 5.19
  },
  {
    "LONG": -50.8,
    "LAT": -25.19,
    "CIDADE": "Guamiranga",
    "UF": "PARANÁ",
    "anual": 4.72,
    "JAN": 5.1,
    "FEB": 5.22,
    "MAR": 5.08,
    "APR": 4.7,
    "MAY": 4.04,
    "JUN": 3.71,
    "JUL": 3.99,
    "AUG": 4.95,
    "SEP": 4.61,
    "OCT": 4.77,
    "NOV": 5.24,
    "DEC": 5.22
  },
  {
    "LONG": -50.6,
    "LAT": -2.52,
    "CIDADE": "Imbituva",
    "UF": "PARANÁ",
    "anual": 4.67,
    "JAN": 5.03,
    "FEB": 5.17,
    "MAR": 5.03,
    "APR": 4.66,
    "MAY": 3.99,
    "JUN": 3.69,
    "JUL": 3.98,
    "AUG": 4.95,
    "SEP": 4.55,
    "OCT": 4.7,
    "NOV": 5.15,
    "DEC": 5.16
  },
  {
    "LONG": -4.93,
    "LAT": -25.19,
    "CIDADE": "Rio Branco do Sul",
    "UF": "PARANÁ",
    "anual": 4.47,
    "JAN": 4.82,
    "FEB": 5.02,
    "MAR": 4.87,
    "APR": 4.46,
    "MAY": 3.85,
    "JUN": 3.58,
    "JUL": 3.8,
    "AUG": 4.77,
    "SEP": 4.26,
    "OCT": 4.38,
    "NOV": 4.88,
    "DEC": 4.94
  },
  {
    "LONG": -49.35,
    "LAT": -25.22,
    "CIDADE": "Itaperuçu",
    "UF": "PARANÁ",
    "anual": 4.47,
    "JAN": 4.82,
    "FEB": 5.02,
    "MAR": 4.87,
    "APR": 4.46,
    "MAY": 3.85,
    "JUN": 3.58,
    "JUL": 3.8,
    "AUG": 4.77,
    "SEP": 4.26,
    "OCT": 4.38,
    "NOV": 4.88,
    "DEC": 4.94
  },
  {
    "LONG": -49.11,
    "LAT": -25.21,
    "CIDADE": "Bocaiúva do Sul",
    "UF": "PARANÁ",
    "anual": 4.43,
    "JAN": 4.77,
    "FEB": 4.98,
    "MAR": 4.79,
    "APR": 4.43,
    "MAY": 3.85,
    "JUN": 3.62,
    "JUL": 3.81,
    "AUG": 4.75,
    "SEP": 4.17,
    "OCT": 4.31,
    "NOV": 4.8,
    "DEC": 4.95
  },
  {
    "LONG": -54.3,
    "LAT": -25.14,
    "CIDADE": "Itaipulândia",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.68,
    "FEB": 5.66,
    "MAR": 5.69,
    "APR": 5,
    "MAY": 4.21,
    "JUN": 3.83,
    "JUL": 4.03,
    "AUG": 4.95,
    "SEP": 4.7,
    "OCT": 5.19,
    "NOV": 5.59,
    "DEC": 5.81
  },
  {
    "LONG": -54.25,
    "LAT": -25.09,
    "CIDADE": "Missal",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.58,
    "FEB": 5.53,
    "MAR": 5.61,
    "APR": 5.01,
    "MAY": 4.21,
    "JUN": 3.86,
    "JUL": 4.04,
    "AUG": 4.98,
    "SEP": 4.75,
    "OCT": 5.16,
    "NOV": 5.56,
    "DEC": 5.73
  },
  {
    "LONG": -54.02,
    "LAT": -25.12,
    "CIDADE": "Ramilândia",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.51,
    "FEB": 5.53,
    "MAR": 5.54,
    "APR": 4.99,
    "MAY": 4.2,
    "JUN": 3.89,
    "JUL": 4.11,
    "AUG": 5.07,
    "SEP": 4.77,
    "OCT": 5.17,
    "NOV": 5.51,
    "DEC": 5.6
  },
  {
    "LONG": -53.84,
    "LAT": -25.15,
    "CIDADE": "Céu Azul",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.55,
    "FEB": 5.43,
    "MAR": 5.33,
    "APR": 4.97,
    "MAY": 4.21,
    "JUN": 3.88,
    "JUL": 4.14,
    "AUG": 5.09,
    "SEP": 4.74,
    "OCT": 5.1,
    "NOV": 5.39,
    "DEC": 5.56
  },
  {
    "LONG": -53.88,
    "LAT": -25.06,
    "CIDADE": "Vera Cruz do Oeste",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.55,
    "FEB": 5.43,
    "MAR": 5.33,
    "APR": 4.97,
    "MAY": 4.21,
    "JUN": 3.88,
    "JUL": 4.14,
    "AUG": 5.09,
    "SEP": 4.74,
    "OCT": 5.1,
    "NOV": 5.39,
    "DEC": 5.56
  },
  {
    "LONG": -53.63,
    "LAT": -25.05,
    "CIDADE": "Santa Tereza do Oeste",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.42,
    "FEB": 5.32,
    "MAR": 5.34,
    "APR": 4.95,
    "MAY": 4.19,
    "JUN": 3.93,
    "JUL": 4.14,
    "AUG": 5.12,
    "SEP": 4.73,
    "OCT": 5.07,
    "NOV": 5.37,
    "DEC": 5.49
  },
  {
    "LONG": -53.02,
    "LAT": -25.11,
    "CIDADE": "Ibema",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.5,
    "FEB": 5.28,
    "MAR": 5.39,
    "APR": 5.05,
    "MAY": 4.25,
    "JUN": 3.99,
    "JUL": 4.22,
    "AUG": 5.19,
    "SEP": 4.78,
    "OCT": 5.06,
    "NOV": 5.37,
    "DEC": 5.43
  },
  {
    "LONG": -5.29,
    "LAT": -25.1,
    "CIDADE": "Guaraniaçu",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.52,
    "FEB": 5.31,
    "MAR": 5.53,
    "APR": 5.09,
    "MAY": 4.29,
    "JUN": 4,
    "JUL": 4.25,
    "AUG": 5.19,
    "SEP": 4.81,
    "OCT": 5.13,
    "NOV": 5.49,
    "DEC": 5.45
  },
  {
    "LONG": -52.25,
    "LAT": -25.11,
    "CIDADE": "Marquinho",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.42,
    "FEB": 5.36,
    "MAR": 5.52,
    "APR": 5.16,
    "MAY": 4.32,
    "JUN": 4,
    "JUL": 4.26,
    "AUG": 5.21,
    "SEP": 4.82,
    "OCT": 5.14,
    "NOV": 5.5,
    "DEC": 5.46
  },
  {
    "LONG": -51.82,
    "LAT": -25.08,
    "CIDADE": "Campina do Simão",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.18,
    "FEB": 5.24,
    "MAR": 5.35,
    "APR": 5.05,
    "MAY": 4.25,
    "JUN": 3.95,
    "JUL": 4.23,
    "AUG": 5.18,
    "SEP": 4.75,
    "OCT": 5.07,
    "NOV": 5.4,
    "DEC": 5.36
  },
  {
    "LONG": -50.17,
    "LAT": -25.09,
    "CIDADE": "Ponta Grossa",
    "UF": "PARANÁ",
    "anual": 4.73,
    "JAN": 5.01,
    "FEB": 5.14,
    "MAR": 5,
    "APR": 4.78,
    "MAY": 4.14,
    "JUN": 3.86,
    "JUL": 4.04,
    "AUG": 5.01,
    "SEP": 4.63,
    "OCT": 4.77,
    "NOV": 5.16,
    "DEC": 5.17
  },
  {
    "LONG": -53.46,
    "LAT": -24.96,
    "CIDADE": "Cascavel",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.51,
    "FEB": 5.27,
    "MAR": 5.36,
    "APR": 5.01,
    "MAY": 4.26,
    "JUN": 3.97,
    "JUL": 4.18,
    "AUG": 5.16,
    "SEP": 4.77,
    "OCT": 5.09,
    "NOV": 5.4,
    "DEC": 5.5
  },
  {
    "LONG": -52.99,
    "LAT": -25.03,
    "CIDADE": "Campo Bonito",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.53,
    "FEB": 5.34,
    "MAR": 5.57,
    "APR": 5.11,
    "MAY": 4.34,
    "JUN": 4.01,
    "JUL": 4.26,
    "AUG": 5.21,
    "SEP": 4.82,
    "OCT": 5.12,
    "NOV": 5.49,
    "DEC": 5.52
  },
  {
    "LONG": -52.68,
    "LAT": -25.04,
    "CIDADE": "Diamante do Sul",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.58,
    "FEB": 5.42,
    "MAR": 5.62,
    "APR": 5.16,
    "MAY": 4.33,
    "JUN": 4.01,
    "JUL": 4.24,
    "AUG": 5.22,
    "SEP": 4.83,
    "OCT": 5.19,
    "NOV": 5.58,
    "DEC": 5.56
  },
  {
    "LONG": -51.53,
    "LAT": -25.04,
    "CIDADE": "Turvo",
    "UF": "PARANÁ",
    "anual": 4.86,
    "JAN": 5.07,
    "FEB": 5.22,
    "MAR": 5.16,
    "APR": 4.97,
    "MAY": 4.21,
    "JUN": 3.91,
    "JUL": 4.23,
    "AUG": 5.17,
    "SEP": 4.77,
    "OCT": 4.98,
    "NOV": 5.3,
    "DEC": 5.29
  },
  {
    "LONG": -50.86,
    "LAT": -25.01,
    "CIDADE": "Ivaí",
    "UF": "PARANÁ",
    "anual": 4.79,
    "JAN": 5.16,
    "FEB": 5.22,
    "MAR": 5.19,
    "APR": 4.82,
    "MAY": 4.11,
    "JUN": 3.78,
    "JUL": 4.08,
    "AUG": 5.05,
    "SEP": 4.68,
    "OCT": 4.86,
    "NOV": 5.29,
    "DEC": 5.25
  },
  {
    "LONG": -50.58,
    "LAT": -25.02,
    "CIDADE": "Ipiranga",
    "UF": "PARANÁ",
    "anual": 4.75,
    "JAN": 5.08,
    "FEB": 5.18,
    "MAR": 5.11,
    "APR": 4.8,
    "MAY": 4.14,
    "JUN": 3.81,
    "JUL": 4.07,
    "AUG": 5.02,
    "SEP": 4.66,
    "OCT": 4.81,
    "NOV": 5.21,
    "DEC": 5.17
  },
  {
    "LONG": -49.09,
    "LAT": -24.97,
    "CIDADE": "Tunas do Paraná",
    "UF": "PARANÁ",
    "anual": 4.45,
    "JAN": 4.85,
    "FEB": 5.04,
    "MAR": 4.78,
    "APR": 4.41,
    "MAY": 3.89,
    "JUN": 3.66,
    "JUL": 3.81,
    "AUG": 4.73,
    "SEP": 4.21,
    "OCT": 4.32,
    "NOV": 4.8,
    "DEC": 4.96
  },
  {
    "LONG": -54.34,
    "LAT": -2.49,
    "CIDADE": "Santa Helena",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.69,
    "FEB": 5.68,
    "MAR": 5.7,
    "APR": 5.03,
    "MAY": 4.2,
    "JUN": 3.86,
    "JUL": 4.06,
    "AUG": 4.97,
    "SEP": 4.73,
    "OCT": 5.24,
    "NOV": 5.6,
    "DEC": 5.77
  },
  {
    "LONG": -54.11,
    "LAT": -24.94,
    "CIDADE": "Diamante D'Oeste",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.56,
    "FEB": 5.5,
    "MAR": 5.61,
    "APR": 5.01,
    "MAY": 4.2,
    "JUN": 3.86,
    "JUL": 4.09,
    "AUG": 5.06,
    "SEP": 4.76,
    "OCT": 5.19,
    "NOV": 5.55,
    "DEC": 5.68
  },
  {
    "LONG": -53.85,
    "LAT": -24.94,
    "CIDADE": "São Pedro do Iguaçu",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.57,
    "FEB": 5.42,
    "MAR": 5.46,
    "APR": 4.99,
    "MAY": 4.24,
    "JUN": 3.92,
    "JUL": 4.16,
    "AUG": 5.11,
    "SEP": 4.78,
    "OCT": 5.11,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -52.47,
    "LAT": -24.89,
    "CIDADE": "Laranjal",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.52,
    "FEB": 5.37,
    "MAR": 5.56,
    "APR": 5.19,
    "MAY": 4.36,
    "JUN": 4.04,
    "JUL": 4.26,
    "AUG": 5.22,
    "SEP": 4.84,
    "OCT": 5.15,
    "NOV": 5.52,
    "DEC": 5.5
  },
  {
    "LONG": -52.2,
    "LAT": -24.89,
    "CIDADE": "Palmital",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.42,
    "FEB": 5.36,
    "MAR": 5.49,
    "APR": 5.17,
    "MAY": 4.33,
    "JUN": 4.05,
    "JUL": 4.27,
    "AUG": 5.18,
    "SEP": 4.84,
    "OCT": 5.13,
    "NOV": 5.52,
    "DEC": 5.47
  },
  {
    "LONG": "#VALOR!",
    "LAT": -24.94,
    "CIDADE": "Santa Maria do Oeste",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.17,
    "FEB": 5.26,
    "MAR": 5.33,
    "APR": 5.02,
    "MAY": 4.28,
    "JUN": 3.98,
    "JUL": 4.27,
    "AUG": 5.2,
    "SEP": 4.81,
    "OCT": 5.06,
    "NOV": 5.42,
    "DEC": 5.36
  },
  {
    "LONG": -5.15,
    "LAT": -24.88,
    "CIDADE": "Boa Ventura de São Roque",
    "UF": "PARANÁ",
    "anual": 4.91,
    "JAN": 5.2,
    "FEB": 5.27,
    "MAR": 5.24,
    "APR": 5.02,
    "MAY": 4.26,
    "JUN": 3.91,
    "JUL": 4.28,
    "AUG": 5.19,
    "SEP": 4.81,
    "OCT": 5.01,
    "NOV": 5.36,
    "DEC": 5.35
  },
  {
    "LONG": -50.11,
    "LAT": -24.95,
    "CIDADE": "Carambeí",
    "UF": "PARANÁ",
    "anual": 4.8,
    "JAN": 5.08,
    "FEB": 5.2,
    "MAR": 5.04,
    "APR": 4.9,
    "MAY": 4.17,
    "JUN": 3.94,
    "JUL": 4.19,
    "AUG": 5.11,
    "SEP": 4.71,
    "OCT": 4.88,
    "NOV": 5.2,
    "DEC": 5.22
  },
  {
    "LONG": -54.06,
    "LAT": -24.84,
    "CIDADE": "São José das Palmeiras",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.54,
    "FEB": 5.44,
    "MAR": 5.61,
    "APR": 4.99,
    "MAY": 4.21,
    "JUN": 3.9,
    "JUL": 4.12,
    "AUG": 5.07,
    "SEP": 4.79,
    "OCT": 5.17,
    "NOV": 5.55,
    "DEC": 5.64
  },
  {
    "LONG": -53.9,
    "LAT": -24.79,
    "CIDADE": "Ouro Verde do Oeste",
    "UF": "PARANÁ",
    "anual": 5,
    "JAN": 5.56,
    "FEB": 5.44,
    "MAR": 5.57,
    "APR": 4.99,
    "MAY": 4.24,
    "JUN": 3.93,
    "JUL": 4.13,
    "AUG": 5.1,
    "SEP": 4.78,
    "OCT": 5.16,
    "NOV": 5.53,
    "DEC": 5.63
  },
  {
    "LONG": -5.33,
    "LAT": -24.8,
    "CIDADE": "Corbélia",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.61,
    "FEB": 5.3,
    "MAR": 5.46,
    "APR": 5.05,
    "MAY": 4.32,
    "JUN": 4.04,
    "JUL": 4.22,
    "AUG": 5.16,
    "SEP": 4.84,
    "OCT": 5.09,
    "NOV": 5.47,
    "DEC": 5.59
  },
  {
    "LONG": -53.12,
    "LAT": -24.82,
    "CIDADE": "Braganey",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.61,
    "FEB": 5.35,
    "MAR": 5.51,
    "APR": 5.11,
    "MAY": 4.37,
    "JUN": 4.02,
    "JUL": 4.24,
    "AUG": 5.19,
    "SEP": 4.86,
    "OCT": 5.12,
    "NOV": 5.48,
    "DEC": 5.58
  },
  {
    "LONG": -52.71,
    "LAT": -24.8,
    "CIDADE": "Altamira do Paraná",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.56,
    "FEB": 5.32,
    "MAR": 5.59,
    "APR": 5.19,
    "MAY": 4.38,
    "JUN": 4.05,
    "JUL": 4.22,
    "AUG": 5.21,
    "SEP": 4.85,
    "OCT": 5.19,
    "NOV": 5.53,
    "DEC": 5.57
  },
  {
    "LONG": -51.76,
    "LAT": -24.76,
    "CIDADE": "Pitanga",
    "UF": "PARANÁ",
    "anual": 4.91,
    "JAN": 5.14,
    "FEB": 5.27,
    "MAR": 5.29,
    "APR": 5.02,
    "MAY": 4.28,
    "JUN": 3.93,
    "JUL": 4.3,
    "AUG": 5.18,
    "SEP": 4.84,
    "OCT": 5.02,
    "NOV": 5.39,
    "DEC": 5.32
  },
  {
    "LONG": -50.01,
    "LAT": -24.79,
    "CIDADE": "Castro",
    "UF": "PARANÁ",
    "anual": 4.62,
    "JAN": 4.86,
    "FEB": 5.06,
    "MAR": 4.88,
    "APR": 4.68,
    "MAY": 4.03,
    "JUN": 3.76,
    "JUL": 3.99,
    "AUG": 4.95,
    "SEP": 4.54,
    "OCT": 4.63,
    "NOV": 5.02,
    "DEC": 5.05
  },
  {
    "LONG": -49.26,
    "LAT": -2.48,
    "CIDADE": "Cerro Azul",
    "UF": "PARANÁ",
    "anual": 4.4,
    "JAN": 4.84,
    "FEB": 5.08,
    "MAR": 4.88,
    "APR": 4.46,
    "MAY": 3.79,
    "JUN": 3.3,
    "JUL": 3.44,
    "AUG": 4.44,
    "SEP": 4.21,
    "OCT": 4.4,
    "NOV": 4.9,
    "DEC": 5.05
  },
  {
    "LONG": -5.42,
    "LAT": -24.7,
    "CIDADE": "Entre Rios do Oeste",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.65,
    "FEB": 5.57,
    "MAR": 5.61,
    "APR": 5.04,
    "MAY": 4.22,
    "JUN": 3.86,
    "JUL": 4.07,
    "AUG": 4.98,
    "SEP": 4.75,
    "OCT": 5.19,
    "NOV": 5.55,
    "DEC": 5.7
  },
  {
    "LONG": -53.74,
    "LAT": -24.73,
    "CIDADE": "Toledo",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.58,
    "FEB": 5.37,
    "MAR": 5.47,
    "APR": 5.07,
    "MAY": 4.3,
    "JUN": 4,
    "JUL": 4.17,
    "AUG": 5.13,
    "SEP": 4.84,
    "OCT": 5.14,
    "NOV": 5.53,
    "DEC": 5.65
  },
  {
    "LONG": -53.08,
    "LAT": -24.72,
    "CIDADE": "Iguatu",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.57,
    "FEB": 5.35,
    "MAR": 5.58,
    "APR": 5.17,
    "MAY": 4.43,
    "JUN": 4.04,
    "JUL": 4.23,
    "AUG": 5.18,
    "SEP": 4.88,
    "OCT": 5.17,
    "NOV": 5.53,
    "DEC": 5.61
  },
  {
    "LONG": -52.57,
    "LAT": -24.67,
    "CIDADE": "Nova Cantu",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.58,
    "FEB": 5.33,
    "MAR": 5.46,
    "APR": 5.18,
    "MAY": 4.4,
    "JUN": 4.04,
    "JUL": 4.26,
    "AUG": 5.2,
    "SEP": 4.83,
    "OCT": 5.08,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -52.15,
    "LAT": "#VALOR!",
    "CIDADE": "Mato Rico",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.45,
    "FEB": 5.41,
    "MAR": 5.43,
    "APR": 5.21,
    "MAY": 4.39,
    "JUN": 4.08,
    "JUL": 4.31,
    "AUG": 5.23,
    "SEP": 4.85,
    "OCT": 5.13,
    "NOV": 5.49,
    "DEC": 5.47
  },
  {
    "LONG": -48.99,
    "LAT": -24.66,
    "CIDADE": "Adrianópolis",
    "UF": "PARANÁ",
    "anual": 4.41,
    "JAN": 4.89,
    "FEB": 5.12,
    "MAR": 4.9,
    "APR": 4.5,
    "MAY": 3.85,
    "JUN": 3.45,
    "JUL": 3.5,
    "AUG": 4.43,
    "SEP": 4.15,
    "OCT": 4.33,
    "NOV": 4.81,
    "DEC": 5.03
  },
  {
    "LONG": -54.23,
    "LAT": -24.63,
    "CIDADE": "Pato Bragado",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.58,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.06,
    "MAY": 4.22,
    "JUN": 3.88,
    "JUL": 4.07,
    "AUG": 5,
    "SEP": 4.77,
    "OCT": 5.19,
    "NOV": 5.54,
    "DEC": 5.69
  },
  {
    "LONG": -54.06,
    "LAT": -24.56,
    "CIDADE": "Marechal Cândido Rondon",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.52,
    "FEB": 5.44,
    "MAR": 5.54,
    "APR": 5.04,
    "MAY": 4.28,
    "JUN": 3.95,
    "JUL": 4.15,
    "AUG": 5.08,
    "SEP": 4.83,
    "OCT": 5.16,
    "NOV": 5.53,
    "DEC": 5.66
  },
  {
    "LONG": -53.98,
    "LAT": -24.58,
    "CIDADE": "Quatro Pontes",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.49,
    "FEB": 5.37,
    "MAR": 5.55,
    "APR": 5.05,
    "MAY": 4.31,
    "JUN": 3.95,
    "JUL": 4.18,
    "AUG": 5.1,
    "SEP": 4.83,
    "OCT": 5.18,
    "NOV": 5.55,
    "DEC": 5.66
  },
  {
    "LONG": -5.35,
    "LAT": -24.59,
    "CIDADE": "Tupãssi",
    "UF": "PARANÁ",
    "anual": 5.05,
    "JAN": 5.66,
    "FEB": 5.32,
    "MAR": 5.53,
    "APR": 5.14,
    "MAY": 4.38,
    "JUN": 4.05,
    "JUL": 4.21,
    "AUG": 5.1,
    "SEP": 4.9,
    "OCT": 5.15,
    "NOV": 5.52,
    "DEC": 5.64
  },
  {
    "LONG": -53.32,
    "LAT": -24.62,
    "CIDADE": "Cafelândia",
    "UF": "PARANÁ",
    "anual": 5.05,
    "JAN": 5.61,
    "FEB": 5.35,
    "MAR": 5.51,
    "APR": 5.12,
    "MAY": 4.37,
    "JUN": 4.07,
    "JUL": 4.24,
    "AUG": 5.13,
    "SEP": 4.89,
    "OCT": 5.17,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -53.13,
    "LAT": -24.65,
    "CIDADE": "Anahy",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.63,
    "FEB": 5.38,
    "MAR": 5.58,
    "APR": 5.17,
    "MAY": 4.41,
    "JUN": 4.07,
    "JUL": 4.23,
    "AUG": 5.2,
    "SEP": 4.89,
    "OCT": 5.16,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -5.28,
    "LAT": -24.59,
    "CIDADE": "Campina da Lagoa",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.57,
    "FEB": 5.24,
    "MAR": 5.43,
    "APR": 5.16,
    "MAY": 4.42,
    "JUN": 4.04,
    "JUL": 4.25,
    "AUG": 5.17,
    "SEP": 4.86,
    "OCT": 5.1,
    "NOV": 5.46,
    "DEC": 5.61
  },
  {
    "LONG": -5.23,
    "LAT": -24.6,
    "CIDADE": "Roncador",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.49,
    "FEB": 5.39,
    "MAR": 5.45,
    "APR": 5.16,
    "MAY": 4.41,
    "JUN": 4.09,
    "JUL": 4.32,
    "AUG": 5.24,
    "SEP": 4.85,
    "OCT": 5.1,
    "NOV": 5.43,
    "DEC": 5.46
  },
  {
    "LONG": -51.34,
    "LAT": -24.57,
    "CIDADE": "Cândido de Abreu",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.44,
    "FEB": 5.5,
    "MAR": 5.45,
    "APR": 5.18,
    "MAY": 4.39,
    "JUN": 4.05,
    "JUL": 4.29,
    "AUG": 5.25,
    "SEP": 4.9,
    "OCT": 5.16,
    "NOV": 5.52,
    "DEC": 5.52
  },
  {
    "LONG": -5.08,
    "LAT": -24.65,
    "CIDADE": "Reserva",
    "UF": "PARANÁ",
    "anual": 4.91,
    "JAN": 5.12,
    "FEB": 5.29,
    "MAR": 5.3,
    "APR": 5.03,
    "MAY": 4.27,
    "JUN": 3.98,
    "JUL": 4.26,
    "AUG": 5.21,
    "SEP": 4.83,
    "OCT": 4.98,
    "NOV": 5.34,
    "DEC": 5.31
  },
  {
    "LONG": -49.42,
    "LAT": -2.46,
    "CIDADE": "Doutor Ulysses",
    "UF": "PARANÁ",
    "anual": 4.39,
    "JAN": 4.56,
    "FEB": 4.9,
    "MAR": 4.68,
    "APR": 4.42,
    "MAY": 3.87,
    "JUN": 3.67,
    "JUL": 3.77,
    "AUG": 4.76,
    "SEP": 4.24,
    "OCT": 4.28,
    "NOV": 4.71,
    "DEC": 4.88
  },
  {
    "LONG": -54.16,
    "LAT": -24.45,
    "CIDADE": "Mercedes",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.54,
    "FEB": 5.49,
    "MAR": 5.6,
    "APR": 5.05,
    "MAY": 4.28,
    "JUN": 3.95,
    "JUL": 4.12,
    "AUG": 5.07,
    "SEP": 4.86,
    "OCT": 5.18,
    "NOV": 5.53,
    "DEC": 5.66
  },
  {
    "LONG": -53.96,
    "LAT": -24.47,
    "CIDADE": "Nova Santa Rosa",
    "UF": "PARANÁ",
    "anual": 5.03,
    "JAN": 5.5,
    "FEB": 5.4,
    "MAR": 5.55,
    "APR": 5.09,
    "MAY": 4.31,
    "JUN": 3.96,
    "JUL": 4.16,
    "AUG": 5.11,
    "SEP": 4.86,
    "OCT": 5.17,
    "NOV": 5.55,
    "DEC": 5.68
  },
  {
    "LONG": -53.26,
    "LAT": -24.53,
    "CIDADE": "Nova Aurora",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.64,
    "FEB": 5.38,
    "MAR": 5.53,
    "APR": 5.13,
    "MAY": 4.41,
    "JUN": 4.05,
    "JUL": 4.23,
    "AUG": 5.17,
    "SEP": 4.9,
    "OCT": 5.18,
    "NOV": 5.49,
    "DEC": 5.61
  },
  {
    "LONG": -52.99,
    "LAT": -24.54,
    "CIDADE": "Ubiratã",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.58,
    "FEB": 5.3,
    "MAR": 5.48,
    "APR": 5.19,
    "MAY": 4.41,
    "JUN": 4.07,
    "JUL": 4.25,
    "AUG": 5.13,
    "SEP": 4.86,
    "OCT": 5.12,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -51.67,
    "LAT": -24.51,
    "CIDADE": "Manoel Ribas",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.36,
    "FEB": 5.44,
    "MAR": 5.38,
    "APR": 5.2,
    "MAY": 4.41,
    "JUN": 4.1,
    "JUL": 4.34,
    "AUG": 5.28,
    "SEP": 4.92,
    "OCT": 5.09,
    "NOV": 5.47,
    "DEC": 5.44
  },
  {
    "LONG": -50.42,
    "LAT": -24.51,
    "CIDADE": "Tibagi",
    "UF": "PARANÁ",
    "anual": 4.9,
    "JAN": 5.12,
    "FEB": 5.23,
    "MAR": 5.22,
    "APR": 5.07,
    "MAY": 4.35,
    "JUN": 3.97,
    "JUL": 4.27,
    "AUG": 5.2,
    "SEP": 4.84,
    "OCT": 4.96,
    "NOV": 5.33,
    "DEC": 5.28
  },
  {
    "LONG": -49.94,
    "LAT": -24.53,
    "CIDADE": "Piraí do Sul",
    "UF": "PARANÁ",
    "anual": 4.77,
    "JAN": 4.87,
    "FEB": 5.14,
    "MAR": 4.97,
    "APR": 4.85,
    "MAY": 4.22,
    "JUN": 3.95,
    "JUL": 4.18,
    "AUG": 5.16,
    "SEP": 4.75,
    "OCT": 4.81,
    "NOV": 5.15,
    "DEC": 5.17
  },
  {
    "LONG": -53.83,
    "LAT": -24.42,
    "CIDADE": "Maripá",
    "UF": "PARANÁ",
    "anual": 5.05,
    "JAN": 5.48,
    "FEB": 5.4,
    "MAR": 5.54,
    "APR": 5.14,
    "MAY": 4.35,
    "JUN": 4.02,
    "JUL": 4.19,
    "AUG": 5.13,
    "SEP": 4.91,
    "OCT": 5.2,
    "NOV": 5.56,
    "DEC": 5.7
  },
  {
    "LONG": -53.52,
    "LAT": -24.42,
    "CIDADE": "Assis Chateaubriand",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.6,
    "FEB": 5.43,
    "MAR": 5.51,
    "APR": 5.14,
    "MAY": 4.37,
    "JUN": 4.04,
    "JUL": 4.19,
    "AUG": 5.16,
    "SEP": 4.91,
    "OCT": 5.19,
    "NOV": 5.55,
    "DEC": 5.67
  },
  {
    "LONG": -53.35,
    "LAT": -24.43,
    "CIDADE": "Iracema do Oeste",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.56,
    "FEB": 5.46,
    "MAR": 5.55,
    "APR": 5.13,
    "MAY": 4.4,
    "JUN": 4.04,
    "JUL": 4.23,
    "AUG": 5.2,
    "SEP": 4.93,
    "OCT": 5.21,
    "NOV": 5.52,
    "DEC": 5.63
  },
  {
    "LONG": -53.39,
    "LAT": -24.38,
    "CIDADE": "Jesuítas",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.56,
    "FEB": 5.46,
    "MAR": 5.55,
    "APR": 5.13,
    "MAY": 4.4,
    "JUN": 4.04,
    "JUL": 4.23,
    "AUG": 5.2,
    "SEP": 4.93,
    "OCT": 5.21,
    "NOV": 5.52,
    "DEC": 5.63
  },
  {
    "LONG": -52.84,
    "LAT": -24.42,
    "CIDADE": "Juranda",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.54,
    "FEB": 5.37,
    "MAR": 5.41,
    "APR": 5.2,
    "MAY": 4.43,
    "JUN": 4.08,
    "JUL": 4.27,
    "AUG": 5.12,
    "SEP": 4.87,
    "OCT": 5.14,
    "NOV": 5.45,
    "DEC": 5.64
  },
  {
    "LONG": -52.1,
    "LAT": -24.43,
    "CIDADE": "Iretama",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.45,
    "FEB": 5.46,
    "MAR": 5.51,
    "APR": 5.26,
    "MAY": 4.46,
    "JUN": 4.13,
    "JUL": 4.34,
    "AUG": 5.27,
    "SEP": 4.94,
    "OCT": 5.15,
    "NOV": 5.57,
    "DEC": 5.53
  },
  {
    "LONG": -51.95,
    "LAT": -24.44,
    "CIDADE": "Nova Tebas",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.45,
    "FEB": 5.49,
    "MAR": 5.57,
    "APR": 5.32,
    "MAY": 4.46,
    "JUN": 4.16,
    "JUL": 4.35,
    "AUG": 5.27,
    "SEP": 4.96,
    "OCT": 5.18,
    "NOV": 5.6,
    "DEC": 5.57
  },
  {
    "LONG": -51.58,
    "LAT": -24.39,
    "CIDADE": "Ariranha do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.41,
    "FEB": 5.45,
    "MAR": 5.47,
    "APR": 5.29,
    "MAY": 4.43,
    "JUN": 4.06,
    "JUL": 4.35,
    "AUG": 5.3,
    "SEP": 4.93,
    "OCT": 5.12,
    "NOV": 5.55,
    "DEC": 5.5
  },
  {
    "LONG": -50.75,
    "LAT": -24.45,
    "CIDADE": "Imbaú",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.1,
    "FEB": 5.27,
    "MAR": 5.28,
    "APR": 5.09,
    "MAY": 4.31,
    "JUN": 3.96,
    "JUL": 4.31,
    "AUG": 5.25,
    "SEP": 4.84,
    "OCT": 4.95,
    "NOV": 5.33,
    "DEC": 5.33
  },
  {
    "LONG": -53.84,
    "LAT": -24.29,
    "CIDADE": "Palotina",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.49,
    "FEB": 5.47,
    "MAR": 5.58,
    "APR": 5.13,
    "MAY": 4.36,
    "JUN": 4.07,
    "JUL": 4.19,
    "AUG": 5.14,
    "SEP": 4.93,
    "OCT": 5.19,
    "NOV": 5.56,
    "DEC": 5.69
  },
  {
    "LONG": -53.31,
    "LAT": -24.3,
    "CIDADE": "Formosa do Oeste",
    "UF": "PARANÁ",
    "anual": 5.08,
    "JAN": 5.56,
    "FEB": 5.53,
    "MAR": 5.57,
    "APR": 5.17,
    "MAY": 4.42,
    "JUN": 4.03,
    "JUL": 4.21,
    "AUG": 5.19,
    "SEP": 4.92,
    "OCT": 5.22,
    "NOV": 5.52,
    "DEC": 5.66
  },
  {
    "LONG": -53.08,
    "LAT": -2.43,
    "CIDADE": "Quarto Centenário",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.55,
    "FEB": 5.46,
    "MAR": 5.49,
    "APR": 5.2,
    "MAY": 4.43,
    "JUN": 4.06,
    "JUL": 4.25,
    "AUG": 5.15,
    "SEP": 4.9,
    "OCT": 5.2,
    "NOV": 5.51,
    "DEC": 5.66
  },
  {
    "LONG": -52.95,
    "LAT": -2.43,
    "CIDADE": "Rancho Alegre D'Oeste",
    "UF": "PARANÁ",
    "anual": 5.08,
    "JAN": 5.52,
    "FEB": 5.48,
    "MAR": 5.49,
    "APR": 5.22,
    "MAY": 4.43,
    "JUN": 4.1,
    "JUL": 4.29,
    "AUG": 5.16,
    "SEP": 4.9,
    "OCT": 5.21,
    "NOV": 5.53,
    "DEC": 5.63
  },
  {
    "LONG": -52.53,
    "LAT": -24.32,
    "CIDADE": "Mamborê",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.53,
    "FEB": 5.4,
    "MAR": 5.42,
    "APR": 5.14,
    "MAY": 4.45,
    "JUN": 4.18,
    "JUL": 4.37,
    "AUG": 5.17,
    "SEP": 4.89,
    "OCT": 5.16,
    "NOV": 5.45,
    "DEC": 5.63
  },
  {
    "LONG": -52.27,
    "LAT": -24.29,
    "CIDADE": "Luiziana",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.41,
    "FEB": 5.41,
    "MAR": 5.41,
    "APR": 5.2,
    "MAY": 4.43,
    "JUN": 4.1,
    "JUL": 4.36,
    "AUG": 5.24,
    "SEP": 4.9,
    "OCT": 5.1,
    "NOV": 5.52,
    "DEC": 5.58
  },
  {
    "LONG": -51.79,
    "LAT": -24.31,
    "CIDADE": "Arapuã",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.4,
    "FEB": 5.51,
    "MAR": 5.5,
    "APR": 5.28,
    "MAY": 4.45,
    "JUN": 4.16,
    "JUL": 4.37,
    "AUG": 5.3,
    "SEP": 4.92,
    "OCT": 5.15,
    "NOV": 5.53,
    "DEC": 5.53
  },
  {
    "LONG": -51.32,
    "LAT": -24.32,
    "CIDADE": "Rio Branco do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.32,
    "FEB": 5.45,
    "MAR": 5.49,
    "APR": 5.27,
    "MAY": 4.44,
    "JUN": 4.1,
    "JUL": 4.36,
    "AUG": 5.3,
    "SEP": 4.94,
    "OCT": 5.15,
    "NOV": 5.52,
    "DEC": 5.52
  },
  {
    "LONG": -51.24,
    "LAT": -24.25,
    "CIDADE": "Rosário do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.34,
    "FEB": 5.43,
    "MAR": 5.55,
    "APR": 5.3,
    "MAY": 4.43,
    "JUN": 4.14,
    "JUL": 4.4,
    "AUG": 5.32,
    "SEP": 4.97,
    "OCT": 5.17,
    "NOV": 5.58,
    "DEC": 5.55
  },
  {
    "LONG": -5.06,
    "LAT": -2.43,
    "CIDADE": "Telêmaco Borba",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.12,
    "FEB": 5.3,
    "MAR": 5.31,
    "APR": 5.13,
    "MAY": 4.32,
    "JUN": 3.97,
    "JUL": 4.29,
    "AUG": 5.24,
    "SEP": 4.85,
    "OCT": 4.93,
    "NOV": 5.32,
    "DEC": 5.34
  },
  {
    "LONG": -49.71,
    "LAT": -2.43,
    "CIDADE": "Jaguariaíva",
    "UF": "PARANÁ",
    "anual": 4.85,
    "JAN": 4.93,
    "FEB": 5.25,
    "MAR": 5.04,
    "APR": 4.94,
    "MAY": 4.31,
    "JUN": 4.06,
    "JUL": 4.31,
    "AUG": 5.23,
    "SEP": 4.84,
    "OCT": 4.89,
    "NOV": 5.18,
    "DEC": 5.25
  },
  {
    "LONG": -54.1,
    "LAT": -24.16,
    "CIDADE": "Terra Roxa",
    "UF": "PARANÁ",
    "anual": 5.08,
    "JAN": 5.58,
    "FEB": 5.63,
    "MAR": 5.62,
    "APR": 5.12,
    "MAY": 4.3,
    "JUN": 3.96,
    "JUL": 4.2,
    "AUG": 5.11,
    "SEP": 4.89,
    "OCT": 5.23,
    "NOV": 5.54,
    "DEC": 5.71
  },
  {
    "LONG": -5.35,
    "LAT": -24.2,
    "CIDADE": "Brasilândia do Sul",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.56,
    "FEB": 5.55,
    "MAR": 5.55,
    "APR": 5.13,
    "MAY": 4.41,
    "JUN": 4.05,
    "JUL": 4.23,
    "AUG": 5.18,
    "SEP": 4.93,
    "OCT": 5.24,
    "NOV": 5.56,
    "DEC": 5.68
  },
  {
    "LONG": -53.03,
    "LAT": -24.18,
    "CIDADE": "Goioerê",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.54,
    "FEB": 5.51,
    "MAR": 5.57,
    "APR": 5.21,
    "MAY": 4.43,
    "JUN": 4.08,
    "JUL": 4.28,
    "AUG": 5.18,
    "SEP": 4.91,
    "OCT": 5.25,
    "NOV": 5.54,
    "DEC": 5.66
  },
  {
    "LONG": -5.28,
    "LAT": -24.25,
    "CIDADE": "Boa Esperança",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.5,
    "FEB": 5.48,
    "MAR": 5.51,
    "APR": 5.2,
    "MAY": 4.44,
    "JUN": 4.16,
    "JUL": 4.37,
    "AUG": 5.2,
    "SEP": 4.93,
    "OCT": 5.23,
    "NOV": 5.51,
    "DEC": 5.65
  },
  {
    "LONG": -51.93,
    "LAT": -24.17,
    "CIDADE": "Godoy Moreira",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.44,
    "FEB": 5.57,
    "MAR": 5.57,
    "APR": 5.38,
    "MAY": 4.49,
    "JUN": 4.15,
    "JUL": 4.4,
    "AUG": 5.29,
    "SEP": 4.98,
    "OCT": 5.21,
    "NOV": 5.6,
    "DEC": 5.57
  },
  {
    "LONG": -51.69,
    "LAT": -24.18,
    "CIDADE": "Jardim Alegre",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.39,
    "FEB": 5.51,
    "MAR": 5.54,
    "APR": 5.35,
    "MAY": 4.46,
    "JUN": 4.19,
    "JUL": 4.39,
    "AUG": 5.34,
    "SEP": 4.96,
    "OCT": 5.16,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -51.68,
    "LAT": -2.42,
    "CIDADE": "Ivaiporã",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.39,
    "FEB": 5.51,
    "MAR": 5.54,
    "APR": 5.35,
    "MAY": 4.46,
    "JUN": 4.19,
    "JUL": 4.39,
    "AUG": 5.34,
    "SEP": 4.96,
    "OCT": 5.16,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -50.93,
    "LAT": -2.42,
    "CIDADE": "Ortigueira",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.06,
    "FEB": 5.22,
    "MAR": 5.25,
    "APR": 5.14,
    "MAY": 4.36,
    "JUN": 4.03,
    "JUL": 4.31,
    "AUG": 5.26,
    "SEP": 4.85,
    "OCT": 4.97,
    "NOV": 5.3,
    "DEC": 5.26
  },
  {
    "LONG": -50.24,
    "LAT": -2.42,
    "CIDADE": "Ventania",
    "UF": "PARANÁ",
    "anual": 4.92,
    "JAN": 5.04,
    "FEB": 5.25,
    "MAR": 5.2,
    "APR": 5.08,
    "MAY": 4.37,
    "JUN": 4.07,
    "JUL": 4.33,
    "AUG": 5.3,
    "SEP": 4.92,
    "OCT": 4.95,
    "NOV": 5.26,
    "DEC": 5.27
  },
  {
    "LONG": -54.26,
    "LAT": -24.09,
    "CIDADE": "Guaíra",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.59,
    "FEB": 5.61,
    "MAR": 5.62,
    "APR": 5.1,
    "MAY": 4.32,
    "JUN": 3.92,
    "JUL": 4.15,
    "AUG": 5.08,
    "SEP": 4.84,
    "OCT": 5.21,
    "NOV": 5.55,
    "DEC": 5.68
  },
  {
    "LONG": -53.85,
    "LAT": -24.07,
    "CIDADE": "Francisco Alves",
    "UF": "PARANÁ",
    "anual": 5.08,
    "JAN": 5.47,
    "FEB": 5.56,
    "MAR": 5.56,
    "APR": 5.12,
    "MAY": 4.41,
    "JUN": 4.02,
    "JUL": 4.21,
    "AUG": 5.19,
    "SEP": 4.92,
    "OCT": 5.23,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -53.01,
    "LAT": -24.05,
    "CIDADE": "Moreira Sales",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.51,
    "FEB": 5.56,
    "MAR": 5.59,
    "APR": 5.22,
    "MAY": 4.43,
    "JUN": 4.11,
    "JUL": 4.31,
    "AUG": 5.21,
    "SEP": 4.94,
    "OCT": 5.27,
    "NOV": 5.57,
    "DEC": 5.64
  },
  {
    "LONG": -52.78,
    "LAT": -24.14,
    "CIDADE": "Janiópolis",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.51,
    "FEB": 5.44,
    "MAR": 5.53,
    "APR": 5.21,
    "MAY": 4.47,
    "JUN": 4.13,
    "JUL": 4.37,
    "AUG": 5.23,
    "SEP": 4.95,
    "OCT": 5.24,
    "NOV": 5.53,
    "DEC": 5.66
  },
  {
    "LONG": -52.62,
    "LAT": -24.1,
    "CIDADE": "Farol",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.53,
    "FEB": 5.43,
    "MAR": 5.49,
    "APR": 5.21,
    "MAY": 4.47,
    "JUN": 4.14,
    "JUL": 4.38,
    "AUG": 5.22,
    "SEP": 4.94,
    "OCT": 5.21,
    "NOV": 5.51,
    "DEC": 5.66
  },
  {
    "LONG": -52.12,
    "LAT": -24.1,
    "CIDADE": "Corumbataí do Sul",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.41,
    "FEB": 5.48,
    "MAR": 5.53,
    "APR": 5.3,
    "MAY": 4.51,
    "JUN": 4.21,
    "JUL": 4.4,
    "AUG": 5.3,
    "SEP": 4.97,
    "OCT": 5.19,
    "NOV": 5.58,
    "DEC": 5.61
  },
  {
    "LONG": -51.74,
    "LAT": -24.08,
    "CIDADE": "Lunardelli",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.43,
    "FEB": 5.53,
    "MAR": 5.57,
    "APR": 5.35,
    "MAY": 4.5,
    "JUN": 4.22,
    "JUL": 4.43,
    "AUG": 5.33,
    "SEP": 4.97,
    "OCT": 5.16,
    "NOV": 5.53,
    "DEC": 5.59
  },
  {
    "LONG": -51.65,
    "LAT": -24.11,
    "CIDADE": "Lidianópolis",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.4,
    "FEB": 5.51,
    "MAR": 5.54,
    "APR": 5.34,
    "MAY": 4.49,
    "JUN": 4.25,
    "JUL": 4.42,
    "AUG": 5.33,
    "SEP": 4.97,
    "OCT": 5.19,
    "NOV": 5.56,
    "DEC": 5.61
  },
  {
    "LONG": -51.51,
    "LAT": -24.15,
    "CIDADE": "Grandes Rios",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.39,
    "FEB": 5.53,
    "MAR": 5.57,
    "APR": 5.37,
    "MAY": 4.47,
    "JUN": 4.19,
    "JUL": 4.4,
    "AUG": 5.33,
    "SEP": 4.96,
    "OCT": 5.19,
    "NOV": 5.57,
    "DEC": 5.61
  },
  {
    "LONG": -4.98,
    "LAT": -24.14,
    "CIDADE": "Arapoti",
    "UF": "PARANÁ",
    "anual": 4.98,
    "JAN": 5.05,
    "FEB": 5.38,
    "MAR": 5.27,
    "APR": 5.12,
    "MAY": 4.45,
    "JUN": 4.12,
    "JUL": 4.41,
    "AUG": 5.27,
    "SEP": 4.95,
    "OCT": 5,
    "NOV": 5.37,
    "DEC": 5.39
  },
  {
    "LONG": -4.95,
    "LAT": -24.11,
    "CIDADE": "Sengés",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 4.98,
    "FEB": 5.44,
    "MAR": 5.25,
    "APR": 5.05,
    "MAY": 4.38,
    "JUN": 4.09,
    "JUL": 4.33,
    "AUG": 5.26,
    "SEP": 4.89,
    "OCT": 4.98,
    "NOV": 5.26,
    "DEC": 5.43
  },
  {
    "LONG": -53.71,
    "LAT": -24.01,
    "CIDADE": "Iporã",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.53,
    "FEB": 5.56,
    "MAR": 5.59,
    "APR": 5.13,
    "MAY": 4.38,
    "JUN": 4.07,
    "JUL": 4.22,
    "AUG": 5.19,
    "SEP": 4.92,
    "OCT": 5.24,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -53.44,
    "LAT": -24.02,
    "CIDADE": "Alto Piquiri",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.49,
    "FEB": 5.58,
    "MAR": 5.62,
    "APR": 5.19,
    "MAY": 4.46,
    "JUN": 4.09,
    "JUL": 4.27,
    "AUG": 5.23,
    "SEP": 4.94,
    "OCT": 5.26,
    "NOV": 5.58,
    "DEC": 5.63
  },
  {
    "LONG": -53.14,
    "LAT": -24.01,
    "CIDADE": "Mariluz",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.44,
    "FEB": 5.55,
    "MAR": 5.64,
    "APR": 5.23,
    "MAY": 4.46,
    "JUN": 4.12,
    "JUL": 4.29,
    "AUG": 5.22,
    "SEP": 4.95,
    "OCT": 5.27,
    "NOV": 5.58,
    "DEC": 5.66
  },
  {
    "LONG": -52.38,
    "LAT": -24.05,
    "CIDADE": "Campo Mourão",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.43,
    "FEB": 5.47,
    "MAR": 5.51,
    "APR": 5.29,
    "MAY": 4.53,
    "JUN": 4.18,
    "JUL": 4.39,
    "AUG": 5.25,
    "SEP": 4.95,
    "OCT": 5.17,
    "NOV": 5.54,
    "DEC": 5.65
  },
  {
    "LONG": -52,
    "LAT": -24.03,
    "CIDADE": "Barbosa Ferraz",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.48,
    "FEB": 5.55,
    "MAR": 5.56,
    "APR": 5.34,
    "MAY": 4.52,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 5.28,
    "SEP": 4.99,
    "OCT": 5.17,
    "NOV": 5.55,
    "DEC": 5.62
  },
  {
    "LONG": -5.18,
    "LAT": -23.98,
    "CIDADE": "São João do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.51,
    "FEB": 5.54,
    "MAR": 5.6,
    "APR": 5.37,
    "MAY": 4.5,
    "JUN": 4.24,
    "JUL": 4.44,
    "AUG": 5.33,
    "SEP": 4.99,
    "OCT": 5.19,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -5.14,
    "LAT": -23.99,
    "CIDADE": "Cruzmaltina",
    "UF": "PARANÁ",
    "anual": 5.06,
    "JAN": 5.22,
    "FEB": 5.4,
    "MAR": 5.41,
    "APR": 5.24,
    "MAY": 4.45,
    "JUN": 4.24,
    "JUL": 4.45,
    "AUG": 5.32,
    "SEP": 4.94,
    "OCT": 5.12,
    "NOV": 5.41,
    "DEC": 5.48
  },
  {
    "LONG": -51.32,
    "LAT": -24.01,
    "CIDADE": "Faxinal",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.15,
    "FEB": 5.36,
    "MAR": 5.33,
    "APR": 5.19,
    "MAY": 4.42,
    "JUN": 4.23,
    "JUL": 4.44,
    "AUG": 5.3,
    "SEP": 4.91,
    "OCT": 5.06,
    "NOV": 5.35,
    "DEC": 5.39
  },
  {
    "LONG": -50.46,
    "LAT": -24.04,
    "CIDADE": "Curiúva",
    "UF": "PARANÁ",
    "anual": 4.96,
    "JAN": 5.08,
    "FEB": 5.29,
    "MAR": 5.31,
    "APR": 5.16,
    "MAY": 4.38,
    "JUN": 4.06,
    "JUL": 4.39,
    "AUG": 5.3,
    "SEP": 4.93,
    "OCT": 5,
    "NOV": 5.27,
    "DEC": 5.33
  },
  {
    "LONG": -53.9,
    "LAT": -23.88,
    "CIDADE": "Altônia",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.52,
    "FEB": 5.6,
    "MAR": 5.59,
    "APR": 5.14,
    "MAY": 4.38,
    "JUN": 4.05,
    "JUL": 4.22,
    "AUG": 5.2,
    "SEP": 4.93,
    "OCT": 5.25,
    "NOV": 5.54,
    "DEC": 5.64
  },
  {
    "LONG": -53.51,
    "LAT": -2.39,
    "CIDADE": "Cafezal do Sul",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.48,
    "FEB": 5.57,
    "MAR": 5.59,
    "APR": 5.18,
    "MAY": 4.44,
    "JUN": 4.08,
    "JUL": 4.29,
    "AUG": 5.23,
    "SEP": 4.94,
    "OCT": 5.24,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -53.41,
    "LAT": -23.9,
    "CIDADE": "Perobal",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.47,
    "FEB": 5.58,
    "MAR": 5.6,
    "APR": 5.2,
    "MAY": 4.45,
    "JUN": 4.09,
    "JUL": 4.3,
    "AUG": 5.23,
    "SEP": 4.95,
    "OCT": 5.24,
    "NOV": 5.6,
    "DEC": 5.65
  },
  {
    "LONG": -52.88,
    "LAT": -23.87,
    "CIDADE": "Tuneiras do Oeste",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.45,
    "FEB": 5.52,
    "MAR": 5.63,
    "APR": 5.26,
    "MAY": 4.49,
    "JUN": 4.18,
    "JUL": 4.37,
    "AUG": 5.26,
    "SEP": 4.96,
    "OCT": 5.25,
    "NOV": 5.56,
    "DEC": 5.63
  },
  {
    "LONG": -52.5,
    "LAT": -2.39,
    "CIDADE": "Araruna",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.43,
    "FEB": 5.49,
    "MAR": 5.62,
    "APR": 5.29,
    "MAY": 4.52,
    "JUN": 4.2,
    "JUL": 4.4,
    "AUG": 5.28,
    "SEP": 5,
    "OCT": 5.24,
    "NOV": 5.56,
    "DEC": 5.63
  },
  {
    "LONG": -52.34,
    "LAT": -23.91,
    "CIDADE": "Peabiru",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.43,
    "FEB": 5.49,
    "MAR": 5.52,
    "APR": 5.32,
    "MAY": 4.55,
    "JUN": 4.2,
    "JUL": 4.39,
    "AUG": 5.24,
    "SEP": 4.98,
    "OCT": 5.16,
    "NOV": 5.54,
    "DEC": 5.65
  },
  {
    "LONG": -52.13,
    "LAT": -23.85,
    "CIDADE": "Quinta do Sol",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.44,
    "FEB": 5.52,
    "MAR": 5.55,
    "APR": 5.33,
    "MAY": 4.55,
    "JUN": 4.25,
    "JUL": 4.43,
    "AUG": 5.26,
    "SEP": 4.99,
    "OCT": 5.16,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -5.2,
    "LAT": -2.39,
    "CIDADE": "Fênix",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.45,
    "FEB": 5.55,
    "MAR": 5.56,
    "APR": 5.36,
    "MAY": 4.53,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 5.29,
    "SEP": 4.97,
    "OCT": 5.15,
    "NOV": 5.55,
    "DEC": 5.6
  },
  {
    "LONG": -51.86,
    "LAT": -23.86,
    "CIDADE": "São Pedro do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.42,
    "FEB": 5.53,
    "MAR": 5.58,
    "APR": 5.34,
    "MAY": 4.51,
    "JUN": 4.23,
    "JUL": 4.42,
    "AUG": 5.33,
    "SEP": 4.97,
    "OCT": 5.15,
    "NOV": 5.54,
    "DEC": 5.58
  },
  {
    "LONG": -51.59,
    "LAT": -23.94,
    "CIDADE": "Borrazópolis",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.27,
    "FEB": 5.45,
    "MAR": 5.49,
    "APR": 5.28,
    "MAY": 4.47,
    "JUN": 4.27,
    "JUL": 4.46,
    "AUG": 5.38,
    "SEP": 4.98,
    "OCT": 5.13,
    "NOV": 5.48,
    "DEC": 5.49
  },
  {
    "LONG": -51.23,
    "LAT": -23.9,
    "CIDADE": "Mauá da Serra",
    "UF": "PARANÁ",
    "anual": 5.02,
    "JAN": 5.15,
    "FEB": 5.32,
    "MAR": 5.34,
    "APR": 5.18,
    "MAY": 4.44,
    "JUN": 4.28,
    "JUL": 4.49,
    "AUG": 5.37,
    "SEP": 4.92,
    "OCT": 5.09,
    "NOV": 5.31,
    "DEC": 5.38
  },
  {
    "LONG": -50.58,
    "LAT": -2.39,
    "CIDADE": "Sapopema",
    "UF": "PARANÁ",
    "anual": 4.93,
    "JAN": 5.02,
    "FEB": 5.22,
    "MAR": 5.23,
    "APR": 5.12,
    "MAY": 4.4,
    "JUN": 4.09,
    "JUL": 4.37,
    "AUG": 5.32,
    "SEP": 4.92,
    "OCT": 4.97,
    "NOV": 5.24,
    "DEC": 5.28
  },
  {
    "LONG": -49.8,
    "LAT": -23.87,
    "CIDADE": "Wenceslau Braz",
    "UF": "PARANÁ",
    "anual": 5.04,
    "JAN": 5.11,
    "FEB": 5.47,
    "MAR": 5.33,
    "APR": 5.19,
    "MAY": 4.46,
    "JUN": 4.19,
    "JUL": 4.43,
    "AUG": 5.33,
    "SEP": 5.01,
    "OCT": 5.1,
    "NOV": 5.44,
    "DEC": 5.44
  },
  {
    "LONG": -49.65,
    "LAT": -2.39,
    "CIDADE": "São José da Boa Vista",
    "UF": "PARANÁ",
    "anual": 4.99,
    "JAN": 5.12,
    "FEB": 5.45,
    "MAR": 5.29,
    "APR": 5.13,
    "MAY": 4.42,
    "JUN": 4.12,
    "JUL": 4.34,
    "AUG": 5.29,
    "SEP": 4.94,
    "OCT": 5.04,
    "NOV": 5.34,
    "DEC": 5.44
  },
  {
    "LONG": -53.88,
    "LAT": -23.77,
    "CIDADE": "São Jorge do Patrocínio",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.51,
    "FEB": 5.61,
    "MAR": 5.6,
    "APR": 5.17,
    "MAY": 4.4,
    "JUN": 4.09,
    "JUL": 4.26,
    "AUG": 5.21,
    "SEP": 4.96,
    "OCT": 5.25,
    "NOV": 5.56,
    "DEC": 5.67
  },
  {
    "LONG": -53.68,
    "LAT": -23.8,
    "CIDADE": "Pérola",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.43,
    "FEB": 5.61,
    "MAR": 5.59,
    "APR": 5.2,
    "MAY": 4.4,
    "JUN": 4.11,
    "JUL": 4.3,
    "AUG": 5.22,
    "SEP": 4.96,
    "OCT": 5.25,
    "NOV": 5.57,
    "DEC": 5.64
  },
  {
    "LONG": -53.32,
    "LAT": -23.77,
    "CIDADE": "Umuarama",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.44,
    "FEB": 5.57,
    "MAR": 5.59,
    "APR": 5.23,
    "MAY": 4.43,
    "JUN": 4.11,
    "JUL": 4.36,
    "AUG": 5.26,
    "SEP": 4.97,
    "OCT": 5.27,
    "NOV": 5.57,
    "DEC": 5.67
  },
  {
    "LONG": -53.08,
    "LAT": -23.78,
    "CIDADE": "Cruzeiro do Oeste",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.46,
    "FEB": 5.57,
    "MAR": 5.66,
    "APR": 5.25,
    "MAY": 4.47,
    "JUN": 4.15,
    "JUL": 4.35,
    "AUG": 5.27,
    "SEP": 4.98,
    "OCT": 5.27,
    "NOV": 5.6,
    "DEC": 5.64
  },
  {
    "LONG": -52.45,
    "LAT": -23.77,
    "CIDADE": "Terra Boa",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.45,
    "FEB": 5.51,
    "MAR": 5.53,
    "APR": 5.32,
    "MAY": 4.55,
    "JUN": 4.22,
    "JUL": 4.43,
    "AUG": 5.29,
    "SEP": 5.02,
    "OCT": 5.22,
    "NOV": 5.55,
    "DEC": 5.63
  },
  {
    "LONG": -52.27,
    "LAT": -23.8,
    "CIDADE": "Engenheiro Beltrão",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.41,
    "FEB": 5.51,
    "MAR": 5.58,
    "APR": 5.33,
    "MAY": 4.56,
    "JUN": 4.25,
    "JUL": 4.41,
    "AUG": 5.28,
    "SEP": 4.99,
    "OCT": 5.18,
    "NOV": 5.57,
    "DEC": 5.61
  },
  {
    "LONG": -51.67,
    "LAT": -23.82,
    "CIDADE": "Kaloré",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.23,
    "FEB": 5.45,
    "MAR": 5.47,
    "APR": 5.26,
    "MAY": 4.48,
    "JUN": 4.25,
    "JUL": 4.45,
    "AUG": 5.37,
    "SEP": 4.99,
    "OCT": 5.15,
    "NOV": 5.44,
    "DEC": 5.48
  },
  {
    "LONG": -51.51,
    "LAT": -23.76,
    "CIDADE": "Novo Itacolomi",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.21,
    "FEB": 5.47,
    "MAR": 5.52,
    "APR": 5.31,
    "MAY": 4.49,
    "JUN": 4.28,
    "JUL": 4.47,
    "AUG": 5.41,
    "SEP": 4.99,
    "OCT": 5.15,
    "NOV": 5.44,
    "DEC": 5.45
  },
  {
    "LONG": -51.41,
    "LAT": -23.76,
    "CIDADE": "Rio Bom",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.22,
    "FEB": 5.45,
    "MAR": 5.53,
    "APR": 5.31,
    "MAY": 4.48,
    "JUN": 4.28,
    "JUL": 4.46,
    "AUG": 5.38,
    "SEP": 5,
    "OCT": 5.15,
    "NOV": 5.44,
    "DEC": 5.48
  },
  {
    "LONG": -50.4,
    "LAT": -2.38,
    "CIDADE": "Figueira",
    "UF": "PARANÁ",
    "anual": 4.95,
    "JAN": 5.04,
    "FEB": 5.31,
    "MAR": 5.18,
    "APR": 5.12,
    "MAY": 4.42,
    "JUN": 4.12,
    "JUL": 4.39,
    "AUG": 5.34,
    "SEP": 4.94,
    "OCT": 4.97,
    "NOV": 5.25,
    "DEC": 5.34
  },
  {
    "LONG": -50.14,
    "LAT": -23.81,
    "CIDADE": "Japira",
    "UF": "PARANÁ",
    "anual": 5.05,
    "JAN": 5.13,
    "FEB": 5.44,
    "MAR": 5.35,
    "APR": 5.2,
    "MAY": 4.5,
    "JUN": 4.19,
    "JUL": 4.45,
    "AUG": 5.37,
    "SEP": 5.01,
    "OCT": 5.1,
    "NOV": 5.39,
    "DEC": 5.47
  },
  {
    "LONG": -50.19,
    "LAT": -23.85,
    "CIDADE": "Ibaiti",
    "UF": "PARANÁ",
    "anual": 5.05,
    "JAN": 5.13,
    "FEB": 5.44,
    "MAR": 5.35,
    "APR": 5.2,
    "MAY": 4.5,
    "JUN": 4.19,
    "JUL": 4.45,
    "AUG": 5.37,
    "SEP": 5.01,
    "OCT": 5.1,
    "NOV": 5.39,
    "DEC": 5.47
  },
  {
    "LONG": -50.06,
    "LAT": -2.38,
    "CIDADE": "Pinhalão",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.13,
    "FEB": 5.49,
    "MAR": 5.36,
    "APR": 5.22,
    "MAY": 4.5,
    "JUN": 4.19,
    "JUL": 4.45,
    "AUG": 5.36,
    "SEP": 5.02,
    "OCT": 5.14,
    "NOV": 5.43,
    "DEC": 5.51
  },
  {
    "LONG": -49.95,
    "LAT": -23.78,
    "CIDADE": "Tomazina",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.12,
    "FEB": 5.5,
    "MAR": 5.37,
    "APR": 5.22,
    "MAY": 4.49,
    "JUN": 4.18,
    "JUL": 4.44,
    "AUG": 5.36,
    "SEP": 5.03,
    "OCT": 5.12,
    "NOV": 5.44,
    "DEC": 5.52
  },
  {
    "LONG": -49.63,
    "LAT": -23.76,
    "CIDADE": "Santana do Itararé",
    "UF": "PARANÁ",
    "anual": 5.01,
    "JAN": 5.12,
    "FEB": 5.46,
    "MAR": 5.34,
    "APR": 5.17,
    "MAY": 4.41,
    "JUN": 4.11,
    "JUL": 4.33,
    "AUG": 5.3,
    "SEP": 4.96,
    "OCT": 5.07,
    "NOV": 5.32,
    "DEC": 5.47
  },
  {
    "LONG": -53.81,
    "LAT": -23.72,
    "CIDADE": "Esperança Nova",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.52,
    "FEB": 5.63,
    "MAR": 5.61,
    "APR": 5.17,
    "MAY": 4.41,
    "JUN": 4.1,
    "JUL": 4.27,
    "AUG": 5.21,
    "SEP": 4.95,
    "OCT": 5.26,
    "NOV": 5.56,
    "DEC": 5.71
  },
  {
    "LONG": -53.49,
    "LAT": -23.74,
    "CIDADE": "Xambrê",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.46,
    "FEB": 5.59,
    "MAR": 5.58,
    "APR": 5.21,
    "MAY": 4.44,
    "JUN": 4.13,
    "JUL": 4.35,
    "AUG": 5.23,
    "SEP": 4.96,
    "OCT": 5.28,
    "NOV": 5.56,
    "DEC": 5.68
  },
  {
    "LONG": -52.87,
    "LAT": -23.73,
    "CIDADE": "Tapejara",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.48,
    "FEB": 5.6,
    "MAR": 5.64,
    "APR": 5.27,
    "MAY": 4.47,
    "JUN": 4.22,
    "JUL": 4.41,
    "AUG": 5.28,
    "SEP": 4.99,
    "OCT": 5.29,
    "NOV": 5.56,
    "DEC": 5.61
  },
  {
    "LONG": -52.61,
    "LAT": -23.66,
    "CIDADE": "Cianorte",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.42,
    "FEB": 5.53,
    "MAR": 5.61,
    "APR": 5.28,
    "MAY": 4.52,
    "JUN": 4.24,
    "JUL": 4.44,
    "AUG": 5.33,
    "SEP": 5.02,
    "OCT": 5.27,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -51.99,
    "LAT": -23.66,
    "CIDADE": "Itambé",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.4,
    "FEB": 5.44,
    "MAR": 5.46,
    "APR": 5.3,
    "MAY": 4.54,
    "JUN": 4.3,
    "JUL": 4.45,
    "AUG": 5.26,
    "SEP": 4.99,
    "OCT": 5.16,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -51.77,
    "LAT": -23.71,
    "CIDADE": "Bom Sucesso",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.24,
    "FEB": 5.49,
    "MAR": 5.49,
    "APR": 5.27,
    "MAY": 4.48,
    "JUN": 4.29,
    "JUL": 4.47,
    "AUG": 5.37,
    "SEP": 5.02,
    "OCT": 5.14,
    "NOV": 5.43,
    "DEC": 5.47
  },
  {
    "LONG": -51.64,
    "LAT": -23.71,
    "CIDADE": "Marumbi",
    "UF": "PARANÁ",
    "anual": 5.08,
    "JAN": 5.2,
    "FEB": 5.44,
    "MAR": 5.45,
    "APR": 5.26,
    "MAY": 4.48,
    "JUN": 4.27,
    "JUL": 4.48,
    "AUG": 5.39,
    "SEP": 5.02,
    "OCT": 5.17,
    "NOV": 5.4,
    "DEC": 5.45
  },
  {
    "LONG": -51.36,
    "LAT": -23.66,
    "CIDADE": "Califórnia",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.26,
    "FEB": 5.37,
    "MAR": 5.5,
    "APR": 5.3,
    "MAY": 4.49,
    "JUN": 4.28,
    "JUL": 4.47,
    "AUG": 5.38,
    "SEP": 5,
    "OCT": 5.13,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -51.31,
    "LAT": -2.37,
    "CIDADE": "Marilândia do Sul",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.26,
    "FEB": 5.37,
    "MAR": 5.5,
    "APR": 5.3,
    "MAY": 4.49,
    "JUN": 4.28,
    "JUL": 4.47,
    "AUG": 5.38,
    "SEP": 5,
    "OCT": 5.13,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -51.1,
    "LAT": -23.72,
    "CIDADE": "Tamarana",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.14,
    "FEB": 5.38,
    "MAR": 5.41,
    "APR": 5.28,
    "MAY": 4.53,
    "JUN": 4.3,
    "JUL": 4.49,
    "AUG": 5.39,
    "SEP": 5.01,
    "OCT": 5.12,
    "NOV": 5.39,
    "DEC": 5.43
  },
  {
    "LONG": -50.74,
    "LAT": -23.72,
    "CIDADE": "São Jerônimo da Serra",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.17,
    "FEB": 5.39,
    "MAR": 5.43,
    "APR": 5.24,
    "MAY": 4.52,
    "JUN": 4.3,
    "JUL": 4.53,
    "AUG": 5.42,
    "SEP": 5.04,
    "OCT": 5.12,
    "NOV": 5.33,
    "DEC": 5.41
  },
  {
    "LONG": -50.07,
    "LAT": -2.37,
    "CIDADE": "Jaboti",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.17,
    "FEB": 5.54,
    "MAR": 5.4,
    "APR": 5.23,
    "MAY": 4.54,
    "JUN": 4.22,
    "JUL": 4.49,
    "AUG": 5.39,
    "SEP": 5.04,
    "OCT": 5.16,
    "NOV": 5.44,
    "DEC": 5.54
  },
  {
    "LONG": -49.83,
    "LAT": -2.37,
    "CIDADE": "Siqueira Campos",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.11,
    "FEB": 5.49,
    "MAR": 5.39,
    "APR": 5.24,
    "MAY": 4.49,
    "JUN": 4.16,
    "JUL": 4.43,
    "AUG": 5.39,
    "SEP": 5.01,
    "OCT": 5.16,
    "NOV": 5.42,
    "DEC": 5.49
  },
  {
    "LONG": -53.21,
    "LAT": -23.62,
    "CIDADE": "Maria Helena",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.43,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.26,
    "MAY": 4.48,
    "JUN": 4.17,
    "JUL": 4.37,
    "AUG": 5.27,
    "SEP": 4.98,
    "OCT": 5.3,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -52.47,
    "LAT": -23.62,
    "CIDADE": "Jussara",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.41,
    "FEB": 5.56,
    "MAR": 5.59,
    "APR": 5.29,
    "MAY": 4.57,
    "JUN": 4.26,
    "JUL": 4.46,
    "AUG": 5.26,
    "SEP": 5,
    "OCT": 5.23,
    "NOV": 5.54,
    "DEC": 5.62
  },
  {
    "LONG": -52.22,
    "LAT": -23.56,
    "CIDADE": "Doutor Camargo",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.43,
    "FEB": 5.42,
    "MAR": 5.46,
    "APR": 5.34,
    "MAY": 4.58,
    "JUN": 4.26,
    "JUL": 4.44,
    "AUG": 5.23,
    "SEP": 5.01,
    "OCT": 5.17,
    "NOV": 5.49,
    "DEC": 5.66
  },
  {
    "LONG": -52.22,
    "LAT": -23.62,
    "CIDADE": "Ivatuba",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.43,
    "FEB": 5.42,
    "MAR": 5.46,
    "APR": 5.34,
    "MAY": 4.58,
    "JUN": 4.26,
    "JUL": 4.44,
    "AUG": 5.23,
    "SEP": 5.01,
    "OCT": 5.17,
    "NOV": 5.49,
    "DEC": 5.66
  },
  {
    "LONG": -52.08,
    "LAT": -23.6,
    "CIDADE": "Floresta",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.34,
    "FEB": 5.47,
    "MAR": 5.43,
    "APR": 5.31,
    "MAY": 4.55,
    "JUN": 4.29,
    "JUL": 4.45,
    "AUG": 5.26,
    "SEP": 5.02,
    "OCT": 5.15,
    "NOV": 5.48,
    "DEC": 5.58
  },
  {
    "LONG": -51.65,
    "LAT": -23.6,
    "CIDADE": "Jandaia do Sul",
    "UF": "PARANÁ",
    "anual": 5.1,
    "JAN": 5.16,
    "FEB": 5.46,
    "MAR": 5.48,
    "APR": 5.28,
    "MAY": 4.5,
    "JUN": 4.33,
    "JUL": 4.51,
    "AUG": 5.41,
    "SEP": 5.03,
    "OCT": 5.19,
    "NOV": 5.44,
    "DEC": 5.44
  },
  {
    "LONG": -51.58,
    "LAT": -23.59,
    "CIDADE": "Cambira",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.13,
    "FEB": 5.41,
    "MAR": 5.46,
    "APR": 5.29,
    "MAY": 4.51,
    "JUN": 4.34,
    "JUL": 4.53,
    "AUG": 5.41,
    "SEP": 5.04,
    "OCT": 5.18,
    "NOV": 5.4,
    "DEC": 5.41
  },
  {
    "LONG": -50.76,
    "LAT": -23.59,
    "CIDADE": "Nova Santa Bárbara",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.23,
    "FEB": 5.48,
    "MAR": 5.42,
    "APR": 5.29,
    "MAY": 4.57,
    "JUN": 4.37,
    "JUL": 4.55,
    "AUG": 5.43,
    "SEP": 5.05,
    "OCT": 5.14,
    "NOV": 5.39,
    "DEC": 5.47
  },
  {
    "LONG": -50.55,
    "LAT": -2.36,
    "CIDADE": "Congonhinhas",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.18,
    "FEB": 5.45,
    "MAR": 5.37,
    "APR": 5.26,
    "MAY": 4.54,
    "JUN": 4.3,
    "JUL": 4.5,
    "AUG": 5.46,
    "SEP": 5.02,
    "OCT": 5.14,
    "NOV": 5.41,
    "DEC": 5.45
  },
  {
    "LONG": -50.17,
    "LAT": -23.63,
    "CIDADE": "Conselheiro Mairinck",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.22,
    "FEB": 5.48,
    "MAR": 5.43,
    "APR": 5.27,
    "MAY": 4.51,
    "JUN": 4.22,
    "JUL": 4.47,
    "AUG": 5.41,
    "SEP": 5.03,
    "OCT": 5.15,
    "NOV": 5.4,
    "DEC": 5.53
  },
  {
    "LONG": -49.92,
    "LAT": -23.57,
    "CIDADE": "Quatiguá",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.17,
    "FEB": 5.54,
    "MAR": 5.44,
    "APR": 5.25,
    "MAY": 4.53,
    "JUN": 4.26,
    "JUL": 4.49,
    "AUG": 5.44,
    "SEP": 5.06,
    "OCT": 5.2,
    "NOV": 5.43,
    "DEC": 5.55
  },
  {
    "LONG": -49.64,
    "LAT": -23.61,
    "CIDADE": "Salto do Itararé",
    "UF": "PARANÁ",
    "anual": 5.07,
    "JAN": 5.16,
    "FEB": 5.59,
    "MAR": 5.44,
    "APR": 5.23,
    "MAY": 4.42,
    "JUN": 4.18,
    "JUL": 4.38,
    "AUG": 5.34,
    "SEP": 5,
    "OCT": 5.16,
    "NOV": 5.39,
    "DEC": 5.58
  },
  {
    "LONG": -5.37,
    "LAT": -23.51,
    "CIDADE": "Alto Paraíso",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.51,
    "FEB": 5.68,
    "MAR": 5.67,
    "APR": 5.17,
    "MAY": 4.44,
    "JUN": 4.16,
    "JUL": 4.32,
    "AUG": 5.19,
    "SEP": 4.97,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.74
  },
  {
    "LONG": -53.09,
    "LAT": -23.47,
    "CIDADE": "Nova Olímpia",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.41,
    "FEB": 5.58,
    "MAR": 5.62,
    "APR": 5.3,
    "MAY": 4.54,
    "JUN": 4.22,
    "JUL": 4.42,
    "AUG": 5.32,
    "SEP": 5.01,
    "OCT": 5.3,
    "NOV": 5.56,
    "DEC": 5.68
  },
  {
    "LONG": -52.7,
    "LAT": -23.48,
    "CIDADE": "Indianópolis",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.37,
    "FEB": 5.61,
    "MAR": 5.69,
    "APR": 5.26,
    "MAY": 4.53,
    "JUN": 4.26,
    "JUL": 4.42,
    "AUG": 5.33,
    "SEP": 5.01,
    "OCT": 5.28,
    "NOV": 5.57,
    "DEC": 5.65
  },
  {
    "LONG": -52.56,
    "LAT": -23.47,
    "CIDADE": "Japurá",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.41,
    "FEB": 5.57,
    "MAR": 5.57,
    "APR": 5.28,
    "MAY": 4.54,
    "JUN": 4.27,
    "JUL": 4.47,
    "AUG": 5.3,
    "SEP": 5.03,
    "OCT": 5.26,
    "NOV": 5.55,
    "DEC": 5.66
  },
  {
    "LONG": -52.59,
    "LAT": -23.54,
    "CIDADE": "São Tomé",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.41,
    "FEB": 5.57,
    "MAR": 5.57,
    "APR": 5.28,
    "MAY": 4.54,
    "JUN": 4.27,
    "JUL": 4.47,
    "AUG": 5.3,
    "SEP": 5.03,
    "OCT": 5.26,
    "NOV": 5.55,
    "DEC": 5.66
  },
  {
    "LONG": -52.05,
    "LAT": -2.35,
    "CIDADE": "Paiçandu",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.28,
    "FEB": 5.51,
    "MAR": 5.47,
    "APR": 5.26,
    "MAY": 4.55,
    "JUN": 4.29,
    "JUL": 4.5,
    "AUG": 5.3,
    "SEP": 5.03,
    "OCT": 5.2,
    "NOV": 5.46,
    "DEC": 5.55
  },
  {
    "LONG": -51.79,
    "LAT": -23.48,
    "CIDADE": "Marialva",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.22,
    "FEB": 5.54,
    "MAR": 5.51,
    "APR": 5.3,
    "MAY": 4.55,
    "JUN": 4.34,
    "JUL": 4.53,
    "AUG": 5.38,
    "SEP": 5.05,
    "OCT": 5.22,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -51.67,
    "LAT": -23.55,
    "CIDADE": "Mandaguari",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.22,
    "FEB": 5.49,
    "MAR": 5.54,
    "APR": 5.33,
    "MAY": 4.55,
    "JUN": 4.33,
    "JUL": 4.53,
    "AUG": 5.42,
    "SEP": 5.05,
    "OCT": 5.24,
    "NOV": 5.45,
    "DEC": 5.49
  },
  {
    "LONG": -51.46,
    "LAT": -23.55,
    "CIDADE": "Apucarana",
    "UF": "PARANÁ",
    "anual": 5.11,
    "JAN": 5.25,
    "FEB": 5.44,
    "MAR": 5.43,
    "APR": 5.27,
    "MAY": 4.55,
    "JUN": 4.39,
    "JUL": 4.53,
    "AUG": 5.38,
    "SEP": 5.05,
    "OCT": 5.21,
    "NOV": 5.39,
    "DEC": 5.45
  },
  {
    "LONG": -5.08,
    "LAT": -23.47,
    "CIDADE": "São Sebastião da Amoreira",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.28,
    "FEB": 5.47,
    "MAR": 5.39,
    "APR": 5.31,
    "MAY": 4.57,
    "JUN": 4.4,
    "JUL": 4.56,
    "AUG": 5.42,
    "SEP": 5.04,
    "OCT": 5.16,
    "NOV": 5.44,
    "DEC": 5.5
  },
  {
    "LONG": -50.78,
    "LAT": -2.35,
    "CIDADE": "Santa Cecília do Pavão",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.28,
    "FEB": 5.47,
    "MAR": 5.39,
    "APR": 5.31,
    "MAY": 4.57,
    "JUN": 4.4,
    "JUL": 4.56,
    "AUG": 5.42,
    "SEP": 5.04,
    "OCT": 5.16,
    "NOV": 5.44,
    "DEC": 5.5
  },
  {
    "LONG": -50.65,
    "LAT": -2.35,
    "CIDADE": "Santo Antônio do Paraíso",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.26,
    "FEB": 5.48,
    "MAR": 5.42,
    "APR": 5.27,
    "MAY": 4.57,
    "JUN": 4.38,
    "JUL": 4.54,
    "AUG": 5.44,
    "SEP": 5.03,
    "OCT": 5.17,
    "NOV": 5.46,
    "DEC": 5.51
  },
  {
    "LONG": -50.04,
    "LAT": -23.52,
    "CIDADE": "Guapirama",
    "UF": "PARANÁ",
    "anual": 5.12,
    "JAN": 5.21,
    "FEB": 5.53,
    "MAR": 5.42,
    "APR": 5.32,
    "MAY": 4.55,
    "JUN": 4.26,
    "JUL": 4.5,
    "AUG": 5.44,
    "SEP": 5.03,
    "OCT": 5.2,
    "NOV": 5.45,
    "DEC": 5.56
  },
  {
    "LONG": -49.92,
    "LAT": -2.35,
    "CIDADE": "Joaquim Távora",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.18,
    "FEB": 5.53,
    "MAR": 5.46,
    "APR": 5.32,
    "MAY": 4.54,
    "JUN": 4.29,
    "JUL": 4.51,
    "AUG": 5.46,
    "SEP": 5.04,
    "OCT": 5.21,
    "NOV": 5.45,
    "DEC": 5.6
  },
  {
    "LONG": -53.62,
    "LAT": -23.39,
    "CIDADE": "Icaraíma",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.5,
    "FEB": 5.7,
    "MAR": 5.69,
    "APR": 5.24,
    "MAY": 4.49,
    "JUN": 4.19,
    "JUL": 4.37,
    "AUG": 5.22,
    "SEP": 4.98,
    "OCT": 5.3,
    "NOV": 5.58,
    "DEC": 5.72
  },
  {
    "LONG": -53.37,
    "LAT": -23.41,
    "CIDADE": "Ivaté",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.39,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.23,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.4,
    "AUG": 5.27,
    "SEP": 5,
    "OCT": 5.31,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -53.29,
    "LAT": -23.38,
    "CIDADE": "Douradina",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.39,
    "FEB": 5.62,
    "MAR": 5.62,
    "APR": 5.26,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.42,
    "AUG": 5.3,
    "SEP": 5,
    "OCT": 5.33,
    "NOV": 5.58,
    "DEC": 5.66
  },
  {
    "LONG": -5.29,
    "LAT": -23.38,
    "CIDADE": "Cidade Gaúcha",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.42,
    "FEB": 5.65,
    "MAR": 5.66,
    "APR": 5.31,
    "MAY": 4.54,
    "JUN": 4.29,
    "JUL": 4.42,
    "AUG": 5.31,
    "SEP": 5.03,
    "OCT": 5.31,
    "NOV": 5.57,
    "DEC": 5.66
  },
  {
    "LONG": -52.77,
    "LAT": -23.41,
    "CIDADE": "Rondon",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.39,
    "FEB": 5.62,
    "MAR": 5.69,
    "APR": 5.29,
    "MAY": 4.55,
    "JUN": 4.26,
    "JUL": 4.44,
    "AUG": 5.34,
    "SEP": 5.03,
    "OCT": 5.29,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -52.65,
    "LAT": -23.39,
    "CIDADE": "São Manoel do Paraná",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.39,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.29,
    "MAY": 4.52,
    "JUN": 4.26,
    "JUL": 4.45,
    "AUG": 5.31,
    "SEP": 5.03,
    "OCT": 5.29,
    "NOV": 5.55,
    "DEC": 5.65
  },
  {
    "LONG": -52.29,
    "LAT": -23.43,
    "CIDADE": "São Jorge do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.33,
    "FEB": 5.57,
    "MAR": 5.52,
    "APR": 5.3,
    "MAY": 4.56,
    "JUN": 4.3,
    "JUL": 4.48,
    "AUG": 5.27,
    "SEP": 5.04,
    "OCT": 5.26,
    "NOV": 5.49,
    "DEC": 5.57
  },
  {
    "LONG": -52.2,
    "LAT": -23.41,
    "CIDADE": "Ourizona",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.25,
    "FEB": 5.61,
    "MAR": 5.55,
    "APR": 5.3,
    "MAY": 4.55,
    "JUN": 4.32,
    "JUL": 4.49,
    "AUG": 5.37,
    "SEP": 5.04,
    "OCT": 5.26,
    "NOV": 5.51,
    "DEC": 5.55
  },
  {
    "LONG": -51.93,
    "LAT": -2.34,
    "CIDADE": "Maringá",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.26,
    "FEB": 5.53,
    "MAR": 5.45,
    "APR": 5.26,
    "MAY": 4.56,
    "JUN": 4.37,
    "JUL": 4.53,
    "AUG": 5.36,
    "SEP": 5.07,
    "OCT": 5.2,
    "NOV": 5.43,
    "DEC": 5.54
  },
  {
    "LONG": -51.88,
    "LAT": -23.44,
    "CIDADE": "Sarandi",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.28,
    "FEB": 5.53,
    "MAR": 5.51,
    "APR": 5.31,
    "MAY": 4.57,
    "JUN": 4.39,
    "JUL": 4.54,
    "AUG": 5.37,
    "SEP": 5.07,
    "OCT": 5.21,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -51.43,
    "LAT": -23.42,
    "CIDADE": "Arapongas",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.26,
    "FEB": 5.48,
    "MAR": 5.43,
    "APR": 5.31,
    "MAY": 4.55,
    "JUN": 4.43,
    "JUL": 4.58,
    "AUG": 5.39,
    "SEP": 5.06,
    "OCT": 5.22,
    "NOV": 5.41,
    "DEC": 5.44
  },
  {
    "LONG": -50.85,
    "LAT": -23.37,
    "CIDADE": "Assaí",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.28,
    "FEB": 5.5,
    "MAR": 5.49,
    "APR": 5.33,
    "MAY": 4.61,
    "JUN": 4.42,
    "JUL": 4.59,
    "AUG": 5.45,
    "SEP": 5.07,
    "OCT": 5.17,
    "NOV": 5.43,
    "DEC": 5.52
  },
  {
    "LONG": -50.56,
    "LAT": -2.34,
    "CIDADE": "Nova Fátima",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.26,
    "FEB": 5.54,
    "MAR": 5.44,
    "APR": 5.29,
    "MAY": 4.58,
    "JUN": 4.38,
    "JUL": 4.57,
    "AUG": 5.46,
    "SEP": 5.07,
    "OCT": 5.18,
    "NOV": 5.45,
    "DEC": 5.57
  },
  {
    "LONG": -50.36,
    "LAT": -2.34,
    "CIDADE": "Ribeirão do Pinhal",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.22,
    "FEB": 5.52,
    "MAR": 5.4,
    "APR": 5.33,
    "MAY": 4.62,
    "JUN": 4.36,
    "JUL": 4.56,
    "AUG": 5.48,
    "SEP": 5.08,
    "OCT": 5.23,
    "NOV": 5.42,
    "DEC": 5.55
  },
  {
    "LONG": "#VALOR!",
    "LAT": -23.44,
    "CIDADE": "Jundiaí do Sul",
    "UF": "PARANÁ",
    "anual": 5.13,
    "JAN": 5.16,
    "FEB": 5.53,
    "MAR": 5.36,
    "APR": 5.31,
    "MAY": 4.6,
    "JUN": 4.31,
    "JUL": 4.55,
    "AUG": 5.46,
    "SEP": 5.07,
    "OCT": 5.21,
    "NOV": 5.42,
    "DEC": 5.53
  },
  {
    "LONG": -49.72,
    "LAT": -2.34,
    "CIDADE": "Carlópolis",
    "UF": "PARANÁ",
    "anual": 5.09,
    "JAN": 5.17,
    "FEB": 5.56,
    "MAR": 5.41,
    "APR": 5.26,
    "MAY": 4.47,
    "JUN": 4.2,
    "JUL": 4.46,
    "AUG": 5.37,
    "SEP": 5,
    "OCT": 5.18,
    "NOV": 5.43,
    "DEC": 5.6
  },
  {
    "LONG": -53.07,
    "LAT": -23.32,
    "CIDADE": "Tapira",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.37,
    "FEB": 5.68,
    "MAR": 5.67,
    "APR": 5.31,
    "MAY": 4.56,
    "JUN": 4.24,
    "JUL": 4.43,
    "AUG": 5.31,
    "SEP": 5.03,
    "OCT": 5.31,
    "NOV": 5.59,
    "DEC": 5.69
  },
  {
    "LONG": -52.78,
    "LAT": -23.26,
    "CIDADE": "Mirador",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.38,
    "FEB": 5.6,
    "MAR": 5.65,
    "APR": 5.29,
    "MAY": 4.55,
    "JUN": 4.27,
    "JUL": 4.45,
    "AUG": 5.36,
    "SEP": 5.04,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.62
  },
  {
    "LONG": -5.28,
    "LAT": -23.34,
    "CIDADE": "Guaporema",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.38,
    "FEB": 5.6,
    "MAR": 5.65,
    "APR": 5.29,
    "MAY": 4.55,
    "JUN": 4.27,
    "JUL": 4.45,
    "AUG": 5.36,
    "SEP": 5.04,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.62
  },
  {
    "LONG": -52.61,
    "LAT": -23.28,
    "CIDADE": "Paraíso do Norte",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.38,
    "FEB": 5.58,
    "MAR": 5.62,
    "APR": 5.33,
    "MAY": 4.52,
    "JUN": 4.27,
    "JUL": 4.47,
    "AUG": 5.35,
    "SEP": 5.04,
    "OCT": 5.32,
    "NOV": 5.57,
    "DEC": 5.62
  },
  {
    "LONG": -52.48,
    "LAT": -23.32,
    "CIDADE": "São Carlos do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.33,
    "FEB": 5.6,
    "MAR": 5.62,
    "APR": 5.32,
    "MAY": 4.54,
    "JUN": 4.28,
    "JUL": 4.48,
    "AUG": 5.32,
    "SEP": 5.06,
    "OCT": 5.31,
    "NOV": 5.57,
    "DEC": 5.62
  },
  {
    "LONG": -52.3,
    "LAT": -23.32,
    "CIDADE": "Floraí",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.32,
    "FEB": 5.62,
    "MAR": 5.63,
    "APR": 5.32,
    "MAY": 4.55,
    "JUN": 4.31,
    "JUL": 4.5,
    "AUG": 5.31,
    "SEP": 5.05,
    "OCT": 5.3,
    "NOV": 5.55,
    "DEC": 5.6
  },
  {
    "LONG": -5.22,
    "LAT": -23.28,
    "CIDADE": "Presidente Castelo Branco",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.27,
    "FEB": 5.59,
    "MAR": 5.61,
    "APR": 5.34,
    "MAY": 4.56,
    "JUN": 4.35,
    "JUL": 4.54,
    "AUG": 5.41,
    "SEP": 5.06,
    "OCT": 5.29,
    "NOV": 5.54,
    "DEC": 5.56
  },
  {
    "LONG": -52.09,
    "LAT": -23.35,
    "CIDADE": "Mandaguaçu",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.27,
    "FEB": 5.56,
    "MAR": 5.53,
    "APR": 5.32,
    "MAY": 4.56,
    "JUN": 4.38,
    "JUL": 4.54,
    "AUG": 5.4,
    "SEP": 5.06,
    "OCT": 5.26,
    "NOV": 5.5,
    "DEC": 5.56
  },
  {
    "LONG": -51.56,
    "LAT": -2.33,
    "CIDADE": "Sabáudia",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.31,
    "FEB": 5.52,
    "MAR": 5.54,
    "APR": 5.36,
    "MAY": 4.57,
    "JUN": 4.44,
    "JUL": 4.58,
    "AUG": 5.4,
    "SEP": 5.06,
    "OCT": 5.24,
    "NOV": 5.49,
    "DEC": 5.52
  },
  {
    "LONG": -51.37,
    "LAT": -23.31,
    "CIDADE": "Rolândia",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.25,
    "FEB": 5.5,
    "MAR": 5.43,
    "APR": 5.33,
    "MAY": 4.56,
    "JUN": 4.43,
    "JUL": 4.62,
    "AUG": 5.44,
    "SEP": 5.08,
    "OCT": 5.19,
    "NOV": 5.43,
    "DEC": 5.48
  },
  {
    "LONG": -51.28,
    "LAT": -2.33,
    "CIDADE": "Cambé",
    "UF": "PARANÁ",
    "anual": 5.14,
    "JAN": 5.24,
    "FEB": 5.43,
    "MAR": 5.48,
    "APR": 5.32,
    "MAY": 4.54,
    "JUN": 4.44,
    "JUL": 4.59,
    "AUG": 5.43,
    "SEP": 5.08,
    "OCT": 5.16,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -51.17,
    "LAT": -23.3,
    "CIDADE": "Londrina",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.23,
    "FEB": 5.45,
    "MAR": 5.49,
    "APR": 5.35,
    "MAY": 4.57,
    "JUN": 4.42,
    "JUL": 4.6,
    "AUG": 5.46,
    "SEP": 5.08,
    "OCT": 5.15,
    "NOV": 5.46,
    "DEC": 5.49
  },
  {
    "LONG": -51.05,
    "LAT": -23.27,
    "CIDADE": "Ibiporã",
    "UF": "PARANÁ",
    "anual": 5.15,
    "JAN": 5.25,
    "FEB": 5.47,
    "MAR": 5.49,
    "APR": 5.38,
    "MAY": 4.58,
    "JUN": 4.42,
    "JUL": 4.6,
    "AUG": 5.46,
    "SEP": 5.1,
    "OCT": 5.17,
    "NOV": 5.45,
    "DEC": 5.48
  },
  {
    "LONG": -50.98,
    "LAT": -23.26,
    "CIDADE": "Jataizinho",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.31,
    "FEB": 5.51,
    "MAR": 5.52,
    "APR": 5.38,
    "MAY": 4.63,
    "JUN": 4.41,
    "JUL": 4.59,
    "AUG": 5.47,
    "SEP": 5.08,
    "OCT": 5.19,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -50.72,
    "LAT": -23.33,
    "CIDADE": "Nova América da Colina",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.28,
    "FEB": 5.55,
    "MAR": 5.5,
    "APR": 5.33,
    "MAY": 4.63,
    "JUN": 4.43,
    "JUL": 4.6,
    "AUG": 5.45,
    "SEP": 5.1,
    "OCT": 5.23,
    "NOV": 5.46,
    "DEC": 5.54
  },
  {
    "LONG": -50.43,
    "LAT": -23.27,
    "CIDADE": "Santa Amélia",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.3,
    "FEB": 5.56,
    "MAR": 5.41,
    "APR": 5.32,
    "MAY": 4.6,
    "JUN": 4.39,
    "JUL": 4.58,
    "AUG": 5.48,
    "SEP": 5.1,
    "OCT": 5.23,
    "NOV": 5.42,
    "DEC": 5.56
  },
  {
    "LONG": -50.31,
    "LAT": -2.33,
    "CIDADE": "Abatiá",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.25,
    "FEB": 5.58,
    "MAR": 5.45,
    "APR": 5.35,
    "MAY": 4.63,
    "JUN": 4.42,
    "JUL": 4.6,
    "AUG": 5.51,
    "SEP": 5.11,
    "OCT": 5.25,
    "NOV": 5.44,
    "DEC": 5.57
  },
  {
    "LONG": -50.08,
    "LAT": -23.3,
    "CIDADE": "Santo Antônio da Platina",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.21,
    "FEB": 5.58,
    "MAR": 5.47,
    "APR": 5.37,
    "MAY": 4.59,
    "JUN": 4.38,
    "JUL": 4.61,
    "AUG": 5.5,
    "SEP": 5.1,
    "OCT": 5.27,
    "NOV": 5.46,
    "DEC": 5.58
  },
  {
    "LONG": -52.6,
    "LAT": -23.18,
    "CIDADE": "Nova Aliança do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.35,
    "FEB": 5.58,
    "MAR": 5.63,
    "APR": 5.35,
    "MAY": 4.53,
    "JUN": 4.31,
    "JUL": 4.49,
    "AUG": 5.36,
    "SEP": 5.07,
    "OCT": 5.32,
    "NOV": 5.57,
    "DEC": 5.6
  },
  {
    "LONG": -52.47,
    "LAT": -2.32,
    "CIDADE": "Tamboara",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.32,
    "FEB": 5.63,
    "MAR": 5.62,
    "APR": 5.35,
    "MAY": 4.53,
    "JUN": 4.32,
    "JUL": 4.49,
    "AUG": 5.35,
    "SEP": 5.09,
    "OCT": 5.32,
    "NOV": 5.58,
    "DEC": 5.6
  },
  {
    "LONG": -52.2,
    "LAT": -23.18,
    "CIDADE": "Nova Esperança",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.31,
    "FEB": 5.62,
    "MAR": 5.66,
    "APR": 5.35,
    "MAY": 4.55,
    "JUN": 4.35,
    "JUL": 4.53,
    "AUG": 5.37,
    "SEP": 5.08,
    "OCT": 5.32,
    "NOV": 5.58,
    "DEC": 5.58
  },
  {
    "LONG": -52.06,
    "LAT": -23.15,
    "CIDADE": "Atalaia",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.29,
    "FEB": 5.56,
    "MAR": 5.56,
    "APR": 5.38,
    "MAY": 4.59,
    "JUN": 4.41,
    "JUL": 4.57,
    "AUG": 5.39,
    "SEP": 5.06,
    "OCT": 5.28,
    "NOV": 5.53,
    "DEC": 5.58
  },
  {
    "LONG": -51.92,
    "LAT": -23.2,
    "CIDADE": "Ângulo",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.31,
    "FEB": 5.58,
    "MAR": 5.55,
    "APR": 5.38,
    "MAY": 4.6,
    "JUN": 4.43,
    "JUL": 4.55,
    "AUG": 5.39,
    "SEP": 5.07,
    "OCT": 5.27,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -5.18,
    "LAT": -23.2,
    "CIDADE": "Iguaraçu",
    "UF": "PARANÁ",
    "anual": 5.21,
    "JAN": 5.36,
    "FEB": 5.65,
    "MAR": 5.59,
    "APR": 5.35,
    "MAY": 4.6,
    "JUN": 4.4,
    "JUL": 4.56,
    "AUG": 5.42,
    "SEP": 5.08,
    "OCT": 5.31,
    "NOV": 5.54,
    "DEC": 5.6
  },
  {
    "LONG": -51.67,
    "LAT": -23.23,
    "CIDADE": "Astorga",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.3,
    "FEB": 5.52,
    "MAR": 5.55,
    "APR": 5.43,
    "MAY": 4.61,
    "JUN": 4.44,
    "JUL": 4.61,
    "AUG": 5.45,
    "SEP": 5.1,
    "OCT": 5.29,
    "NOV": 5.52,
    "DEC": 5.6
  },
  {
    "LONG": -51.59,
    "LAT": -23.23,
    "CIDADE": "Pitangueiras",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.32,
    "FEB": 5.57,
    "MAR": 5.56,
    "APR": 5.41,
    "MAY": 4.59,
    "JUN": 4.45,
    "JUL": 4.59,
    "AUG": 5.43,
    "SEP": 5.08,
    "OCT": 5.28,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -50.79,
    "LAT": -23.2,
    "CIDADE": "Uraí",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.31,
    "FEB": 5.61,
    "MAR": 5.54,
    "APR": 5.36,
    "MAY": 4.64,
    "JUN": 4.45,
    "JUL": 4.61,
    "AUG": 5.49,
    "SEP": 5.12,
    "OCT": 5.28,
    "NOV": 5.48,
    "DEC": 5.53
  },
  {
    "LONG": -50.65,
    "LAT": -23.18,
    "CIDADE": "Cornélio Procópio",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.32,
    "FEB": 5.61,
    "MAR": 5.52,
    "APR": 5.34,
    "MAY": 4.6,
    "JUN": 4.45,
    "JUL": 4.61,
    "AUG": 5.5,
    "SEP": 5.14,
    "OCT": 5.28,
    "NOV": 5.51,
    "DEC": 5.56
  },
  {
    "LONG": -49.97,
    "LAT": -23.16,
    "CIDADE": "Jacarezinho",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.18,
    "FEB": 5.55,
    "MAR": 5.5,
    "APR": 5.4,
    "MAY": 4.61,
    "JUN": 4.42,
    "JUL": 4.64,
    "AUG": 5.52,
    "SEP": 5.12,
    "OCT": 5.3,
    "NOV": 5.43,
    "DEC": 5.59
  },
  {
    "LONG": -49.76,
    "LAT": -2.32,
    "CIDADE": "Ribeirão Claro",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.13,
    "FEB": 5.57,
    "MAR": 5.52,
    "APR": 5.39,
    "MAY": 4.6,
    "JUN": 4.38,
    "JUL": 4.58,
    "AUG": 5.49,
    "SEP": 5.08,
    "OCT": 5.29,
    "NOV": 5.46,
    "DEC": 5.56
  },
  {
    "LONG": -53.48,
    "LAT": -23.08,
    "CIDADE": "Querência do Norte",
    "UF": "PARANÁ",
    "anual": 5.17,
    "JAN": 5.37,
    "FEB": 5.6,
    "MAR": 5.7,
    "APR": 5.29,
    "MAY": 4.57,
    "JUN": 4.25,
    "JUL": 4.43,
    "AUG": 5.26,
    "SEP": 5.01,
    "OCT": 5.32,
    "NOV": 5.56,
    "DEC": 5.65
  },
  {
    "LONG": -53.11,
    "LAT": -23.11,
    "CIDADE": "Santa Mônica",
    "UF": "PARANÁ",
    "anual": 5.16,
    "JAN": 5.26,
    "FEB": 5.58,
    "MAR": 5.65,
    "APR": 5.32,
    "MAY": 4.57,
    "JUN": 4.25,
    "JUL": 4.47,
    "AUG": 5.34,
    "SEP": 5.05,
    "OCT": 5.3,
    "NOV": 5.56,
    "DEC": 5.6
  },
  {
    "LONG": -5.28,
    "LAT": -23.09,
    "CIDADE": "Amaporã",
    "UF": "PARANÁ",
    "anual": 5.18,
    "JAN": 5.34,
    "FEB": 5.6,
    "MAR": 5.63,
    "APR": 5.25,
    "MAY": 4.54,
    "JUN": 4.33,
    "JUL": 4.49,
    "AUG": 5.35,
    "SEP": 5.07,
    "OCT": 5.32,
    "NOV": 5.57,
    "DEC": 5.62
  },
  {
    "LONG": -52.46,
    "LAT": -23.08,
    "CIDADE": "Paranavaí",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.37,
    "FEB": 5.6,
    "MAR": 5.63,
    "APR": 5.33,
    "MAY": 4.56,
    "JUN": 4.37,
    "JUL": 4.54,
    "AUG": 5.38,
    "SEP": 5.11,
    "OCT": 5.33,
    "NOV": 5.6,
    "DEC": 5.62
  },
  {
    "LONG": -52.32,
    "LAT": -23.13,
    "CIDADE": "Alto Paraná",
    "UF": "PARANÁ",
    "anual": 5.21,
    "JAN": 5.36,
    "FEB": 5.62,
    "MAR": 5.67,
    "APR": 5.35,
    "MAY": 4.57,
    "JUN": 4.35,
    "JUL": 4.56,
    "AUG": 5.42,
    "SEP": 5.11,
    "OCT": 5.32,
    "NOV": 5.6,
    "DEC": 5.62
  },
  {
    "LONG": -52.16,
    "LAT": -23.09,
    "CIDADE": "Uniflor",
    "UF": "PARANÁ",
    "anual": 5.22,
    "JAN": 5.34,
    "FEB": 5.61,
    "MAR": 5.66,
    "APR": 5.4,
    "MAY": 4.6,
    "JUN": 4.41,
    "JUL": 4.57,
    "AUG": 5.41,
    "SEP": 5.1,
    "OCT": 5.32,
    "NOV": 5.62,
    "DEC": 5.61
  },
  {
    "LONG": -5.2,
    "LAT": -23.09,
    "CIDADE": "Flórida",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.42,
    "FEB": 5.64,
    "MAR": 5.61,
    "APR": 5.41,
    "MAY": 4.62,
    "JUN": 4.42,
    "JUL": 4.56,
    "AUG": 5.45,
    "SEP": 5.11,
    "OCT": 5.32,
    "NOV": 5.57,
    "DEC": 5.62
  },
  {
    "LONG": -51.77,
    "LAT": -23.15,
    "CIDADE": "Munhoz de Melo",
    "UF": "PARANÁ",
    "anual": 5.22,
    "JAN": 5.35,
    "FEB": 5.64,
    "MAR": 5.63,
    "APR": 5.38,
    "MAY": 4.6,
    "JUN": 4.46,
    "JUL": 4.59,
    "AUG": 5.43,
    "SEP": 5.11,
    "OCT": 5.32,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -51.53,
    "LAT": -23.11,
    "CIDADE": "Jaguapitã",
    "UF": "PARANÁ",
    "anual": 5.24,
    "JAN": 5.3,
    "FEB": 5.65,
    "MAR": 5.66,
    "APR": 5.48,
    "MAY": 4.59,
    "JUN": 4.5,
    "JUL": 4.62,
    "AUG": 5.45,
    "SEP": 5.15,
    "OCT": 5.35,
    "NOV": 5.6,
    "DEC": 5.59
  },
  {
    "LONG": -51.04,
    "LAT": -23.06,
    "CIDADE": "Sertanópolis",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.3,
    "FEB": 5.57,
    "MAR": 5.49,
    "APR": 5.41,
    "MAY": 4.61,
    "JUN": 4.45,
    "JUL": 4.61,
    "AUG": 5.47,
    "SEP": 5.09,
    "OCT": 5.25,
    "NOV": 5.48,
    "DEC": 5.58
  },
  {
    "LONG": -50.91,
    "LAT": -23.07,
    "CIDADE": "Rancho Alegre",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.36,
    "FEB": 5.62,
    "MAR": 5.55,
    "APR": 5.36,
    "MAY": 4.66,
    "JUN": 4.43,
    "JUL": 4.6,
    "AUG": 5.44,
    "SEP": 5.05,
    "OCT": 5.28,
    "NOV": 5.47,
    "DEC": 5.61
  },
  {
    "LONG": -50.75,
    "LAT": "#VALOR!",
    "CIDADE": "Leópolis",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.38,
    "FEB": 5.69,
    "MAR": 5.54,
    "APR": 5.34,
    "MAY": 4.68,
    "JUN": 4.48,
    "JUL": 4.64,
    "AUG": 5.51,
    "SEP": 5.11,
    "OCT": 5.31,
    "NOV": 5.5,
    "DEC": 5.6
  },
  {
    "LONG": -50.52,
    "LAT": -2.31,
    "CIDADE": "Santa Mariana",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.3,
    "FEB": 5.63,
    "MAR": 5.46,
    "APR": 5.36,
    "MAY": 4.65,
    "JUN": 4.45,
    "JUL": 4.62,
    "AUG": 5.48,
    "SEP": 5.09,
    "OCT": 5.26,
    "NOV": 5.46,
    "DEC": 5.61
  },
  {
    "LONG": -50.37,
    "LAT": -23.11,
    "CIDADE": "Bandeirantes",
    "UF": "PARANÁ",
    "anual": 5.21,
    "JAN": 5.26,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 5.4,
    "MAY": 4.63,
    "JUN": 4.44,
    "JUL": 4.63,
    "AUG": 5.52,
    "SEP": 5.1,
    "OCT": 5.3,
    "NOV": 5.43,
    "DEC": 5.6
  },
  {
    "LONG": -50.23,
    "LAT": -23.05,
    "CIDADE": "Andirá",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.24,
    "FEB": 5.65,
    "MAR": 5.46,
    "APR": 5.41,
    "MAY": 4.63,
    "JUN": 4.46,
    "JUL": 4.65,
    "AUG": 5.51,
    "SEP": 5.1,
    "OCT": 5.27,
    "NOV": 5.37,
    "DEC": 5.61
  },
  {
    "LONG": -50.18,
    "LAT": -23.12,
    "CIDADE": "Barra do Jacaré",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.23,
    "FEB": 5.66,
    "MAR": 5.43,
    "APR": 5.38,
    "MAY": 4.64,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.49,
    "SEP": 5.1,
    "OCT": 5.27,
    "NOV": 5.38,
    "DEC": 5.61
  },
  {
    "LONG": -53.29,
    "LAT": -22.96,
    "CIDADE": "Santa Cruz de Monte Castelo",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.31,
    "FEB": 5.65,
    "MAR": 5.66,
    "APR": 5.34,
    "MAY": 4.59,
    "JUN": 4.32,
    "JUL": 4.48,
    "AUG": 5.34,
    "SEP": 5.09,
    "OCT": 5.32,
    "NOV": 5.59,
    "DEC": 5.66
  },
  {
    "LONG": -53.2,
    "LAT": -23,
    "CIDADE": "Santa Isabel do Ivaí",
    "UF": "PARANÁ",
    "anual": 5.19,
    "JAN": 5.27,
    "FEB": 5.62,
    "MAR": 5.63,
    "APR": 5.33,
    "MAY": 4.6,
    "JUN": 4.32,
    "JUL": 4.49,
    "AUG": 5.36,
    "SEP": 5.09,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -52.92,
    "LAT": -23.01,
    "CIDADE": "Planaltina do Paraná",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.33,
    "FEB": 5.64,
    "MAR": 5.65,
    "APR": 5.33,
    "MAY": 4.56,
    "JUN": 4.33,
    "JUL": 4.49,
    "AUG": 5.38,
    "SEP": 5.09,
    "OCT": 5.3,
    "NOV": 5.6,
    "DEC": 5.64
  },
  {
    "LONG": -52.16,
    "LAT": -22.96,
    "CIDADE": "Cruzeiro do Sul",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.33,
    "FEB": 5.61,
    "MAR": 5.61,
    "APR": 5.4,
    "MAY": 4.63,
    "JUN": 4.46,
    "JUL": 4.59,
    "AUG": 5.44,
    "SEP": 5.12,
    "OCT": 5.34,
    "NOV": 5.61,
    "DEC": 5.59
  },
  {
    "LONG": -51.95,
    "LAT": -23.01,
    "CIDADE": "Lobato",
    "UF": "PARANÁ",
    "anual": 5.24,
    "JAN": 5.35,
    "FEB": 5.67,
    "MAR": 5.64,
    "APR": 5.39,
    "MAY": 4.65,
    "JUN": 4.44,
    "JUL": 4.59,
    "AUG": 5.45,
    "SEP": 5.12,
    "OCT": 5.35,
    "NOV": 5.61,
    "DEC": 5.61
  },
  {
    "LONG": -51.81,
    "LAT": -23.04,
    "CIDADE": "Santa Fé",
    "UF": "PARANÁ",
    "anual": 5.24,
    "JAN": 5.3,
    "FEB": 5.67,
    "MAR": 5.66,
    "APR": 5.39,
    "MAY": 4.61,
    "JUN": 4.47,
    "JUL": 4.6,
    "AUG": 5.48,
    "SEP": 5.13,
    "OCT": 5.35,
    "NOV": 5.59,
    "DEC": 5.62
  },
  {
    "LONG": -51.65,
    "LAT": -22.97,
    "CIDADE": "Guaraci",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.36,
    "FEB": 5.66,
    "MAR": 5.69,
    "APR": 5.46,
    "MAY": 4.64,
    "JUN": 4.49,
    "JUL": 4.63,
    "AUG": 5.47,
    "SEP": 5.16,
    "OCT": 5.38,
    "NOV": 5.63,
    "DEC": 5.64
  },
  {
    "LONG": -51.44,
    "LAT": -23.04,
    "CIDADE": "Prado Ferreira",
    "UF": "PARANÁ",
    "anual": 5.25,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.64,
    "APR": 5.46,
    "MAY": 4.63,
    "JUN": 4.47,
    "JUL": 4.68,
    "AUG": 5.5,
    "SEP": 5.15,
    "OCT": 5.34,
    "NOV": 5.62,
    "DEC": 5.6
  },
  {
    "LONG": -5.15,
    "LAT": -22.97,
    "CIDADE": "Miraselva",
    "UF": "PARANÁ",
    "anual": 5.25,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.64,
    "APR": 5.46,
    "MAY": 4.63,
    "JUN": 4.47,
    "JUL": 4.68,
    "AUG": 5.5,
    "SEP": 5.15,
    "OCT": 5.34,
    "NOV": 5.62,
    "DEC": 5.6
  },
  {
    "LONG": -51.19,
    "LAT": -22.99,
    "CIDADE": "Bela Vista do Paraíso",
    "UF": "PARANÁ",
    "anual": 5.24,
    "JAN": 5.35,
    "FEB": 5.62,
    "MAR": 5.58,
    "APR": 5.44,
    "MAY": 4.67,
    "JUN": 4.49,
    "JUL": 4.67,
    "AUG": 5.5,
    "SEP": 5.1,
    "OCT": 5.31,
    "NOV": 5.55,
    "DEC": 5.63
  },
  {
    "LONG": -50.82,
    "LAT": -23.04,
    "CIDADE": "Sertaneja",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.4,
    "FEB": 5.7,
    "MAR": 5.51,
    "APR": 5.43,
    "MAY": 4.68,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.46,
    "SEP": 5.02,
    "OCT": 5.31,
    "NOV": 5.49,
    "DEC": 5.67
  },
  {
    "LONG": -50.41,
    "LAT": -2.3,
    "CIDADE": "Itambaracá",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.3,
    "FEB": 5.61,
    "MAR": 5.5,
    "APR": 5.37,
    "MAY": 4.65,
    "JUN": 4.42,
    "JUL": 4.6,
    "AUG": 5.48,
    "SEP": 5.08,
    "OCT": 5.28,
    "NOV": 5.46,
    "DEC": 5.61
  },
  {
    "LONG": -50.08,
    "LAT": -23.04,
    "CIDADE": "Cambará",
    "UF": "PARANÁ",
    "anual": 5.2,
    "JAN": 5.19,
    "FEB": 5.64,
    "MAR": 5.49,
    "APR": 5.39,
    "MAY": 4.65,
    "JUN": 4.48,
    "JUL": 4.65,
    "AUG": 5.52,
    "SEP": 5.12,
    "OCT": 5.31,
    "NOV": 5.38,
    "DEC": 5.61
  },
  {
    "LONG": -53.14,
    "LAT": -22.92,
    "CIDADE": "Loanda",
    "UF": "PARANÁ",
    "anual": 5.22,
    "JAN": 5.33,
    "FEB": 5.68,
    "MAR": 5.65,
    "APR": 5.36,
    "MAY": 4.61,
    "JUN": 4.37,
    "JUL": 4.52,
    "AUG": 5.36,
    "SEP": 5.12,
    "OCT": 5.33,
    "NOV": 5.6,
    "DEC": 5.68
  },
  {
    "LONG": -5.27,
    "LAT": -2.29,
    "CIDADE": "Guairaçá",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.4,
    "FEB": 5.7,
    "MAR": 5.63,
    "APR": 5.35,
    "MAY": 4.59,
    "JUN": 4.37,
    "JUL": 4.57,
    "AUG": 5.43,
    "SEP": 5.14,
    "OCT": 5.33,
    "NOV": 5.61,
    "DEC": 5.65
  },
  {
    "LONG": -5.23,
    "LAT": -2.29,
    "CIDADE": "São João do Caiuá",
    "UF": "PARANÁ",
    "anual": 5.25,
    "JAN": 5.38,
    "FEB": 5.67,
    "MAR": 5.66,
    "APR": 5.38,
    "MAY": 4.63,
    "JUN": 4.43,
    "JUL": 4.58,
    "AUG": 5.44,
    "SEP": 5.14,
    "OCT": 5.36,
    "NOV": 5.64,
    "DEC": 5.67
  },
  {
    "LONG": -52.16,
    "LAT": -22.93,
    "CIDADE": "Paranacity",
    "UF": "PARANÁ",
    "anual": 5.23,
    "JAN": 5.32,
    "FEB": 5.61,
    "MAR": 5.63,
    "APR": 5.4,
    "MAY": 4.63,
    "JUN": 4.47,
    "JUL": 4.6,
    "AUG": 5.46,
    "SEP": 5.14,
    "OCT": 5.34,
    "NOV": 5.61,
    "DEC": 5.62
  },
  {
    "LONG": -51.8,
    "LAT": -22.91,
    "CIDADE": "Nossa Senhora das Graças",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.36,
    "FEB": 5.69,
    "MAR": 5.69,
    "APR": 5.43,
    "MAY": 4.66,
    "JUN": 4.49,
    "JUL": 4.65,
    "AUG": 5.5,
    "SEP": 5.16,
    "OCT": 5.39,
    "NOV": 5.62,
    "DEC": 5.65
  },
  {
    "LONG": -51.39,
    "LAT": -22.86,
    "CIDADE": "Florestópolis",
    "UF": "PARANÁ",
    "anual": 5.25,
    "JAN": 5.35,
    "FEB": 5.66,
    "MAR": 5.64,
    "APR": 5.45,
    "MAY": 4.65,
    "JUN": 4.46,
    "JUL": 4.67,
    "AUG": 5.47,
    "SEP": 5.13,
    "OCT": 5.34,
    "NOV": 5.59,
    "DEC": 5.64
  },
  {
    "LONG": -51.03,
    "LAT": -2.29,
    "CIDADE": "Primeiro de Maio",
    "UF": "PARANÁ",
    "anual": 5.28,
    "JAN": 5.43,
    "FEB": 5.71,
    "MAR": 5.63,
    "APR": 5.5,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.68,
    "AUG": 5.46,
    "SEP": 5.08,
    "OCT": 5.35,
    "NOV": 5.59,
    "DEC": 5.73
  },
  {
    "LONG": -53.27,
    "LAT": -22.78,
    "CIDADE": "Porto Rico",
    "UF": "PARANÁ",
    "anual": 5.21,
    "JAN": 5.39,
    "FEB": 5.71,
    "MAR": 5.71,
    "APR": 5.37,
    "MAY": 4.59,
    "JUN": 4.35,
    "JUL": 4.47,
    "AUG": 5.28,
    "SEP": 5.06,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.7
  },
  {
    "LONG": -53.22,
    "LAT": -22.82,
    "CIDADE": "São Pedro do Paraná",
    "UF": "PARANÁ",
    "anual": 5.21,
    "JAN": 5.39,
    "FEB": 5.71,
    "MAR": 5.71,
    "APR": 5.37,
    "MAY": 4.59,
    "JUN": 4.35,
    "JUL": 4.47,
    "AUG": 5.28,
    "SEP": 5.06,
    "OCT": 5.31,
    "NOV": 5.58,
    "DEC": 5.7
  },
  {
    "LONG": -52.99,
    "LAT": -22.76,
    "CIDADE": "Nova Londrina",
    "UF": "PARANÁ",
    "anual": 5.24,
    "JAN": 5.42,
    "FEB": 5.71,
    "MAR": 5.67,
    "APR": 5.34,
    "MAY": 4.61,
    "JUN": 4.4,
    "JUL": 4.54,
    "AUG": 5.38,
    "SEP": 5.14,
    "OCT": 5.32,
    "NOV": 5.63,
    "DEC": 5.7
  },
  {
    "LONG": -52.2,
    "LAT": -22.75,
    "CIDADE": "Inajá",
    "UF": "PARANÁ",
    "anual": 5.26,
    "JAN": 5.36,
    "FEB": 5.7,
    "MAR": 5.69,
    "APR": 5.4,
    "MAY": 4.66,
    "JUN": 4.46,
    "JUL": 4.6,
    "AUG": 5.46,
    "SEP": 5.14,
    "OCT": 5.36,
    "NOV": 5.64,
    "DEC": 5.66
  },
  {
    "LONG": -51.97,
    "LAT": -22.84,
    "CIDADE": "Colorado",
    "UF": "PARANÁ",
    "anual": 5.28,
    "JAN": 5.34,
    "FEB": 5.72,
    "MAR": 5.7,
    "APR": 5.44,
    "MAY": 4.67,
    "JUN": 4.49,
    "JUL": 4.63,
    "AUG": 5.53,
    "SEP": 5.16,
    "OCT": 5.39,
    "NOV": 5.62,
    "DEC": 5.64
  },
  {
    "LONG": -51.71,
    "LAT": -22.79,
    "CIDADE": "Cafeara",
    "UF": "PARANÁ",
    "anual": 5.3,
    "JAN": 5.37,
    "FEB": 5.75,
    "MAR": 5.74,
    "APR": 5.46,
    "MAY": 4.68,
    "JUN": 4.5,
    "JUL": 4.68,
    "AUG": 5.51,
    "SEP": 5.19,
    "OCT": 5.41,
    "NOV": 5.63,
    "DEC": 5.67
  },
  {
    "LONG": -51.66,
    "LAT": -22.76,
    "CIDADE": "Lupionópolis",
    "UF": "PARANÁ",
    "anual": 5.29,
    "JAN": 5.36,
    "FEB": 5.74,
    "MAR": 5.74,
    "APR": 5.47,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.69,
    "AUG": 5.5,
    "SEP": 5.17,
    "OCT": 5.4,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -51.6,
    "LAT": -22.82,
    "CIDADE": "Centenário do Sul",
    "UF": "PARANÁ",
    "anual": 5.29,
    "JAN": 5.38,
    "FEB": 5.69,
    "MAR": 5.69,
    "APR": 5.5,
    "MAY": 4.68,
    "JUN": 4.48,
    "JUL": 4.69,
    "AUG": 5.51,
    "SEP": 5.17,
    "OCT": 5.39,
    "NOV": 5.57,
    "DEC": 5.66
  },
  {
    "LONG": -51.38,
    "LAT": -22.75,
    "CIDADE": "Porecatu",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.35,
    "FEB": 5.68,
    "MAR": 5.71,
    "APR": 5.5,
    "MAY": 4.67,
    "JUN": 4.45,
    "JUL": 4.65,
    "AUG": 5.49,
    "SEP": 5.09,
    "OCT": 5.36,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -51.23,
    "LAT": -22.78,
    "CIDADE": "Alvorada do Sul",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.43,
    "FEB": 5.73,
    "MAR": 5.7,
    "APR": 5.5,
    "MAY": 4.7,
    "JUN": 4.43,
    "JUL": 4.65,
    "AUG": 5.46,
    "SEP": 5.03,
    "OCT": 5.36,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -53.04,
    "LAT": -2.27,
    "CIDADE": "Marilena",
    "UF": "PARANÁ",
    "anual": 5.26,
    "JAN": 5.46,
    "FEB": 5.77,
    "MAR": 5.75,
    "APR": 5.4,
    "MAY": 4.63,
    "JUN": 4.38,
    "JUL": 4.54,
    "AUG": 5.32,
    "SEP": 5.12,
    "OCT": 5.34,
    "NOV": 5.63,
    "DEC": 5.74
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.27,
    "CIDADE": "Diamante do Norte",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.46,
    "FEB": 5.78,
    "MAR": 5.7,
    "APR": 5.36,
    "MAY": 4.66,
    "JUN": 4.44,
    "JUL": 4.57,
    "AUG": 5.39,
    "SEP": 5.16,
    "OCT": 5.33,
    "NOV": 5.63,
    "DEC": 5.75
  },
  {
    "LONG": -52.89,
    "LAT": -22.73,
    "CIDADE": "Itaúna do Sul",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.46,
    "FEB": 5.78,
    "MAR": 5.7,
    "APR": 5.36,
    "MAY": 4.66,
    "JUN": 4.44,
    "JUL": 4.57,
    "AUG": 5.39,
    "SEP": 5.16,
    "OCT": 5.33,
    "NOV": 5.63,
    "DEC": 5.75
  },
  {
    "LONG": -52.62,
    "LAT": -22.73,
    "CIDADE": "Terra Rica",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.44,
    "FEB": 5.78,
    "MAR": 5.7,
    "APR": 5.41,
    "MAY": 4.64,
    "JUN": 4.4,
    "JUL": 4.58,
    "AUG": 5.42,
    "SEP": 5.14,
    "OCT": 5.34,
    "NOV": 5.64,
    "DEC": 5.74
  },
  {
    "LONG": -52.34,
    "LAT": -22.74,
    "CIDADE": "Santo Antônio do Caiuá",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.43,
    "FEB": 5.73,
    "MAR": 5.73,
    "APR": 5.4,
    "MAY": 4.67,
    "JUN": 4.44,
    "JUL": 4.6,
    "AUG": 5.45,
    "SEP": 5.13,
    "OCT": 5.35,
    "NOV": 5.65,
    "DEC": 5.67
  },
  {
    "LONG": -52.08,
    "LAT": -22.66,
    "CIDADE": "Paranapoema",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.36,
    "FEB": 5.69,
    "MAR": 5.7,
    "APR": 5.42,
    "MAY": 4.7,
    "JUN": 4.48,
    "JUL": 4.64,
    "AUG": 5.52,
    "SEP": 5.16,
    "OCT": 5.35,
    "NOV": 5.61,
    "DEC": 5.65
  },
  {
    "LONG": -5.18,
    "LAT": -2.27,
    "CIDADE": "Santo Inácio",
    "UF": "PARANÁ",
    "anual": 5.28,
    "JAN": 5.36,
    "FEB": 5.72,
    "MAR": 5.74,
    "APR": 5.47,
    "MAY": 4.69,
    "JUN": 4.48,
    "JUL": 4.67,
    "AUG": 5.48,
    "SEP": 5.16,
    "OCT": 5.38,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -52.04,
    "LAT": -22.55,
    "CIDADE": "Jardim Olinda",
    "UF": "PARANÁ",
    "anual": 5.27,
    "JAN": 5.38,
    "FEB": 5.69,
    "MAR": 5.72,
    "APR": 5.42,
    "MAY": 4.68,
    "JUN": 4.5,
    "JUL": 4.64,
    "AUG": 5.49,
    "SEP": 5.13,
    "OCT": 5.37,
    "NOV": 5.59,
    "DEC": 5.66
  },
  {
    "LONG": -51.9,
    "LAT": -22.64,
    "CIDADE": "Santa Inês",
    "UF": "PARANÁ",
    "anual": 5.28,
    "JAN": 5.39,
    "FEB": 5.72,
    "MAR": 5.73,
    "APR": 5.45,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.63,
    "AUG": 5.49,
    "SEP": 5.12,
    "OCT": 5.36,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -51.97,
    "LAT": -22.62,
    "CIDADE": "Itaguajé",
    "UF": "PARANÁ",
    "anual": 5.28,
    "JAN": 5.39,
    "FEB": 5.72,
    "MAR": 5.73,
    "APR": 5.45,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.63,
    "AUG": 5.49,
    "SEP": 5.12,
    "OCT": 5.36,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -40.5,
    "LAT": -9.39,
    "CIDADE": "Petrolina",
    "UF": "PERNAMBUCO",
    "anual": 5.78,
    "JAN": 6.01,
    "FEB": 5.97,
    "MAR": 6.04,
    "APR": 5.45,
    "MAY": 5.16,
    "JUN": 4.97,
    "JUL": 5.2,
    "AUG": 5.85,
    "SEP": 6.41,
    "OCT": 6.25,
    "NOV": 6.16,
    "DEC": 5.95
  },
  {
    "LONG": -38.27,
    "LAT": -9.19,
    "CIDADE": "Jatobá",
    "UF": "PERNAMBUCO",
    "anual": 5.58,
    "JAN": 5.98,
    "FEB": 5.95,
    "MAR": 6.09,
    "APR": 5.63,
    "MAY": 4.93,
    "JUN": 4.41,
    "JUL": 4.6,
    "AUG": 5.18,
    "SEP": 5.96,
    "OCT": 5.96,
    "NOV": 6.24,
    "DEC": 6.01
  },
  {
    "LONG": -3.67,
    "LAT": -9.17,
    "CIDADE": "Bom Conselho",
    "UF": "PERNAMBUCO",
    "anual": 5.35,
    "JAN": 5.72,
    "FEB": 5.72,
    "MAR": 5.9,
    "APR": 5.4,
    "MAY": 4.52,
    "JUN": 4.1,
    "JUL": 4.26,
    "AUG": 4.83,
    "SEP": 5.63,
    "OCT": 5.85,
    "NOV": 6.22,
    "DEC": 6.01
  },
  {
    "LONG": "#VALOR!",
    "LAT": -9.08,
    "CIDADE": "Petrolândia",
    "UF": "PERNAMBUCO",
    "anual": 5.64,
    "JAN": 5.97,
    "FEB": 5.93,
    "MAR": 6.1,
    "APR": 5.66,
    "MAY": 4.98,
    "JUN": 4.49,
    "JUL": 4.7,
    "AUG": 5.38,
    "SEP": 6.08,
    "OCT": 6.03,
    "NOV": 6.3,
    "DEC": 6.04
  },
  {
    "LONG": -38.15,
    "LAT": -9.1,
    "CIDADE": "Tacaratu",
    "UF": "PERNAMBUCO",
    "anual": 5.64,
    "JAN": 6.02,
    "FEB": 5.92,
    "MAR": 6.06,
    "APR": 5.6,
    "MAY": 4.92,
    "JUN": 4.46,
    "JUL": 4.63,
    "AUG": 5.33,
    "SEP": 6.14,
    "OCT": 6.14,
    "NOV": 6.36,
    "DEC": 6.05
  },
  {
    "LONG": -37.12,
    "LAT": -9.11,
    "CIDADE": "Águas Belas",
    "UF": "PERNAMBUCO",
    "anual": 5.47,
    "JAN": 5.87,
    "FEB": 5.82,
    "MAR": 6.02,
    "APR": 5.5,
    "MAY": 4.68,
    "JUN": 4.26,
    "JUL": 4.47,
    "AUG": 5.02,
    "SEP": 5.79,
    "OCT": 5.96,
    "NOV": 6.22,
    "DEC": 5.98
  },
  {
    "LONG": -36.62,
    "LAT": -0.91,
    "CIDADE": "Terezinha",
    "UF": "PERNAMBUCO",
    "anual": 5.39,
    "JAN": 5.72,
    "FEB": 5.73,
    "MAR": 5.94,
    "APR": 5.45,
    "MAY": 4.55,
    "JUN": 4.22,
    "JUL": 4.33,
    "AUG": 4.91,
    "SEP": 5.69,
    "OCT": 5.86,
    "NOV": 6.24,
    "DEC": 6.02
  },
  {
    "LONG": -36.46,
    "LAT": -9.13,
    "CIDADE": "Lagoa do Ouro",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.68,
    "FEB": 5.66,
    "MAR": 5.84,
    "APR": 5.38,
    "MAY": 4.51,
    "JUN": 4.06,
    "JUL": 4.13,
    "AUG": 4.72,
    "SEP": 5.53,
    "OCT": 5.71,
    "NOV": 6.07,
    "DEC": 5.91
  },
  {
    "LONG": -36.32,
    "LAT": -9.12,
    "CIDADE": "Correntes",
    "UF": "PERNAMBUCO",
    "anual": 5.24,
    "JAN": 5.67,
    "FEB": 5.66,
    "MAR": 5.87,
    "APR": 5.38,
    "MAY": 4.51,
    "JUN": 4.03,
    "JUL": 4.08,
    "AUG": 4.71,
    "SEP": 5.46,
    "OCT": 5.62,
    "NOV": 6.01,
    "DEC": 5.85
  },
  {
    "LONG": -40.27,
    "LAT": -8.99,
    "CIDADE": "Lagoa Grande",
    "UF": "PERNAMBUCO",
    "anual": 5.71,
    "JAN": 5.86,
    "FEB": 5.74,
    "MAR": 5.93,
    "APR": 5.47,
    "MAY": 5.07,
    "JUN": 4.88,
    "JUL": 5.11,
    "AUG": 5.85,
    "SEP": 6.43,
    "OCT": 6.22,
    "NOV": 6.14,
    "DEC": 5.82
  },
  {
    "LONG": -37.62,
    "LAT": -0.9,
    "CIDADE": "Manari",
    "UF": "PERNAMBUCO",
    "anual": 5.57,
    "JAN": 6.02,
    "FEB": 5.92,
    "MAR": 6.07,
    "APR": 5.66,
    "MAY": 4.79,
    "JUN": 4.27,
    "JUL": 4.46,
    "AUG": 5.12,
    "SEP": 5.95,
    "OCT": 6.12,
    "NOV": 6.37,
    "DEC": 6.08
  },
  {
    "LONG": -36.85,
    "LAT": -9.04,
    "CIDADE": "Iati",
    "UF": "PERNAMBUCO",
    "anual": 5.43,
    "JAN": 5.81,
    "FEB": 5.72,
    "MAR": 5.89,
    "APR": 5.51,
    "MAY": 4.68,
    "JUN": 4.25,
    "JUL": 4.39,
    "AUG": 5.01,
    "SEP": 5.77,
    "OCT": 5.96,
    "NOV": 6.22,
    "DEC": 5.98
  },
  {
    "LONG": -36.69,
    "LAT": -8.97,
    "CIDADE": "Saloá",
    "UF": "PERNAMBUCO",
    "anual": 5.34,
    "JAN": 5.74,
    "FEB": 5.67,
    "MAR": 5.9,
    "APR": 5.42,
    "MAY": 4.51,
    "JUN": 4.08,
    "JUL": 4.21,
    "AUG": 4.81,
    "SEP": 5.63,
    "OCT": 5.85,
    "NOV": 6.23,
    "DEC": 5.99
  },
  {
    "LONG": -36.57,
    "LAT": -9.03,
    "CIDADE": "Brejão",
    "UF": "PERNAMBUCO",
    "anual": 5.29,
    "JAN": 5.77,
    "FEB": 5.68,
    "MAR": 5.86,
    "APR": 5.38,
    "MAY": 4.5,
    "JUN": 4,
    "JUL": 4.12,
    "AUG": 4.73,
    "SEP": 5.57,
    "OCT": 5.77,
    "NOV": 6.19,
    "DEC": 5.94
  },
  {
    "LONG": -36.32,
    "LAT": -9.01,
    "CIDADE": "Palmeirina",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.65,
    "FEB": 5.55,
    "MAR": 5.81,
    "APR": 5.33,
    "MAY": 4.47,
    "JUN": 3.96,
    "JUL": 4.04,
    "AUG": 4.64,
    "SEP": 5.42,
    "OCT": 5.57,
    "NOV": 6.04,
    "DEC": 5.88
  },
  {
    "LONG": -37.83,
    "LAT": "#VALOR!",
    "CIDADE": "Inajá",
    "UF": "PERNAMBUCO",
    "anual": 5.61,
    "JAN": 6.06,
    "FEB": 5.9,
    "MAR": 6.13,
    "APR": 5.69,
    "MAY": 4.83,
    "JUN": 4.34,
    "JUL": 4.53,
    "AUG": 5.2,
    "SEP": 6.01,
    "OCT": 6.17,
    "NOV": 6.34,
    "DEC": 6.14
  },
  {
    "LONG": -37.42,
    "LAT": -0.89,
    "CIDADE": "Itaíba",
    "UF": "PERNAMBUCO",
    "anual": 5.62,
    "JAN": 6.02,
    "FEB": 5.95,
    "MAR": 6.19,
    "APR": 5.71,
    "MAY": 4.81,
    "JUN": 4.35,
    "JUL": 4.52,
    "AUG": 5.22,
    "SEP": 6.03,
    "OCT": 6.18,
    "NOV": 6.38,
    "DEC": 6.08
  },
  {
    "LONG": -36.66,
    "LAT": -8.92,
    "CIDADE": "Paranatama",
    "UF": "PERNAMBUCO",
    "anual": 5.4,
    "JAN": 5.81,
    "FEB": 5.71,
    "MAR": 5.94,
    "APR": 5.45,
    "MAY": 4.61,
    "JUN": 4.19,
    "JUL": 4.34,
    "AUG": 4.97,
    "SEP": 5.76,
    "OCT": 5.9,
    "NOV": 6.18,
    "DEC": 5.99
  },
  {
    "LONG": -36.5,
    "LAT": -8.88,
    "CIDADE": "Garanhuns",
    "UF": "PERNAMBUCO",
    "anual": 5.25,
    "JAN": 5.68,
    "FEB": 5.54,
    "MAR": 5.82,
    "APR": 5.31,
    "MAY": 4.53,
    "JUN": 4.08,
    "JUL": 4.2,
    "AUG": 4.79,
    "SEP": 5.5,
    "OCT": 5.64,
    "NOV": 6.02,
    "DEC": 5.85
  },
  {
    "LONG": -36.37,
    "LAT": -8.88,
    "CIDADE": "São João",
    "UF": "PERNAMBUCO",
    "anual": 5.21,
    "JAN": 5.62,
    "FEB": 5.51,
    "MAR": 5.79,
    "APR": 5.29,
    "MAY": 4.54,
    "JUN": 4.08,
    "JUL": 4.17,
    "AUG": 4.75,
    "SEP": 5.45,
    "OCT": 5.58,
    "NOV": 5.97,
    "DEC": 5.8
  },
  {
    "LONG": -3.63,
    "LAT": -8.89,
    "CIDADE": "Angelim",
    "UF": "PERNAMBUCO",
    "anual": 5.19,
    "JAN": 5.52,
    "FEB": 5.53,
    "MAR": 5.76,
    "APR": 5.31,
    "MAY": 4.52,
    "JUN": 4.08,
    "JUL": 4.18,
    "AUG": 4.74,
    "SEP": 5.44,
    "OCT": 5.55,
    "NOV": 5.92,
    "DEC": 5.78
  },
  {
    "LONG": -36.2,
    "LAT": -8.88,
    "CIDADE": "Canhotinho",
    "UF": "PERNAMBUCO",
    "anual": 5.15,
    "JAN": 5.5,
    "FEB": 5.51,
    "MAR": 5.69,
    "APR": 5.27,
    "MAY": 4.49,
    "JUN": 4.05,
    "JUL": 4.1,
    "AUG": 4.7,
    "SEP": 5.4,
    "OCT": 5.52,
    "NOV": 5.86,
    "DEC": 5.72
  },
  {
    "LONG": -35.15,
    "LAT": -8.89,
    "CIDADE": "São José da Coroa Grande",
    "UF": "PERNAMBUCO",
    "anual": 5.4,
    "JAN": 5.62,
    "FEB": 5.79,
    "MAR": 6.05,
    "APR": 5.3,
    "MAY": 4.7,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 5.14,
    "SEP": 5.67,
    "OCT": 5.83,
    "NOV": 5.89,
    "DEC": 5.85
  },
  {
    "LONG": -39.82,
    "LAT": -8.8,
    "CIDADE": "Santa Maria da Boa Vista",
    "UF": "PERNAMBUCO",
    "anual": 5.68,
    "JAN": 5.85,
    "FEB": 5.75,
    "MAR": 5.97,
    "APR": 5.51,
    "MAY": 5,
    "JUN": 4.75,
    "JUL": 4.93,
    "AUG": 5.65,
    "SEP": 6.34,
    "OCT": 6.25,
    "NOV": 6.21,
    "DEC": 5.91
  },
  {
    "LONG": -38.96,
    "LAT": -8.75,
    "CIDADE": "Belém de São Francisco",
    "UF": "PERNAMBUCO",
    "anual": 5.75,
    "JAN": 6.02,
    "FEB": 6.08,
    "MAR": 6.23,
    "APR": 5.76,
    "MAY": 5.07,
    "JUN": 4.63,
    "JUL": 4.85,
    "AUG": 5.49,
    "SEP": 6.17,
    "OCT": 6.15,
    "NOV": 6.27,
    "DEC": 6.21
  },
  {
    "LONG": -38.7,
    "LAT": -8.82,
    "CIDADE": "Itacuruba",
    "UF": "PERNAMBUCO",
    "anual": 5.82,
    "JAN": 6.16,
    "FEB": 6.13,
    "MAR": 6.33,
    "APR": 5.87,
    "MAY": 5.19,
    "JUN": 4.87,
    "JUL": 5.11,
    "AUG": 5.62,
    "SEP": 6.16,
    "OCT": 6.09,
    "NOV": 6.19,
    "DEC": 6.15
  },
  {
    "LONG": -37.34,
    "LAT": -8.75,
    "CIDADE": "Tupanatinga",
    "UF": "PERNAMBUCO",
    "anual": 5.62,
    "JAN": 5.98,
    "FEB": 5.95,
    "MAR": 6.2,
    "APR": 5.75,
    "MAY": 4.8,
    "JUN": 4.31,
    "JUL": 4.46,
    "AUG": 5.18,
    "SEP": 6.05,
    "OCT": 6.21,
    "NOV": 6.43,
    "DEC": 6.15
  },
  {
    "LONG": -36.63,
    "LAT": -8.77,
    "CIDADE": "Caetés",
    "UF": "PERNAMBUCO",
    "anual": 5.44,
    "JAN": 5.83,
    "FEB": 5.77,
    "MAR": 5.99,
    "APR": 5.52,
    "MAY": 4.64,
    "JUN": 4.2,
    "JUL": 4.39,
    "AUG": 5.02,
    "SEP": 5.75,
    "OCT": 5.91,
    "NOV": 6.19,
    "DEC": 6.01
  },
  {
    "LONG": -3.6,
    "LAT": -8.81,
    "CIDADE": "Quipapá",
    "UF": "PERNAMBUCO",
    "anual": 5.06,
    "JAN": 5.42,
    "FEB": 5.46,
    "MAR": 5.66,
    "APR": 5.15,
    "MAY": 4.37,
    "JUN": 3.97,
    "JUL": 3.96,
    "AUG": 4.52,
    "SEP": 5.27,
    "OCT": 5.46,
    "NOV": 5.76,
    "DEC": 5.7
  },
  {
    "LONG": -35.95,
    "LAT": -0.88,
    "CIDADE": "São Benedito do Sul",
    "UF": "PERNAMBUCO",
    "anual": 5.09,
    "JAN": 5.47,
    "FEB": 5.49,
    "MAR": 5.71,
    "APR": 5.22,
    "MAY": 4.37,
    "JUN": 3.98,
    "JUL": 4.06,
    "AUG": 4.61,
    "SEP": 5.29,
    "OCT": 5.42,
    "NOV": 5.74,
    "DEC": 5.7
  },
  {
    "LONG": -35.83,
    "LAT": -8.79,
    "CIDADE": "Maraial",
    "UF": "PERNAMBUCO",
    "anual": 5.06,
    "JAN": 5.36,
    "FEB": 5.46,
    "MAR": 5.66,
    "APR": 5.2,
    "MAY": 4.43,
    "JUN": 4,
    "JUL": 4.08,
    "AUG": 4.61,
    "SEP": 5.29,
    "OCT": 5.37,
    "NOV": 5.69,
    "DEC": 5.59
  },
  {
    "LONG": -35.62,
    "LAT": -0.88,
    "CIDADE": "Xexéu",
    "UF": "PERNAMBUCO",
    "anual": 5.09,
    "JAN": 5.34,
    "FEB": 5.42,
    "MAR": 5.65,
    "APR": 5.18,
    "MAY": 4.48,
    "JUN": 4.17,
    "JUL": 4.17,
    "AUG": 4.75,
    "SEP": 5.34,
    "OCT": 5.38,
    "NOV": 5.65,
    "DEC": 5.54
  },
  {
    "LONG": -35.1,
    "LAT": -8.76,
    "CIDADE": "Tamandaré",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.51,
    "FEB": 5.73,
    "MAR": 6,
    "APR": 5.25,
    "MAY": 4.6,
    "JUN": 4.41,
    "JUL": 4.46,
    "AUG": 5.07,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.83,
    "DEC": 5.78
  },
  {
    "LONG": -35.18,
    "LAT": -8.82,
    "CIDADE": "Barreiros",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.51,
    "FEB": 5.73,
    "MAR": 6,
    "APR": 5.25,
    "MAY": 4.6,
    "JUN": 4.41,
    "JUL": 4.46,
    "AUG": 5.07,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.83,
    "DEC": 5.78
  },
  {
    "LONG": -3.66,
    "LAT": -8.74,
    "CIDADE": "Capoeiras",
    "UF": "PERNAMBUCO",
    "anual": 5.47,
    "JAN": 5.88,
    "FEB": 5.75,
    "MAR": 5.95,
    "APR": 5.55,
    "MAY": 4.66,
    "JUN": 4.29,
    "JUL": 4.38,
    "AUG": 5.12,
    "SEP": 5.81,
    "OCT": 6,
    "NOV": 6.26,
    "DEC": 6.02
  },
  {
    "LONG": -36.42,
    "LAT": -0.87,
    "CIDADE": "Jupi",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.85,
    "APR": 5.39,
    "MAY": 4.59,
    "JUN": 4.16,
    "JUL": 4.24,
    "AUG": 4.92,
    "SEP": 5.57,
    "OCT": 5.68,
    "NOV": 6,
    "DEC": 5.84
  },
  {
    "LONG": -36.49,
    "LAT": -8.71,
    "CIDADE": "Jucati",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.85,
    "APR": 5.39,
    "MAY": 4.59,
    "JUN": 4.16,
    "JUL": 4.24,
    "AUG": 4.92,
    "SEP": 5.57,
    "OCT": 5.68,
    "NOV": 6,
    "DEC": 5.84
  },
  {
    "LONG": -3.63,
    "LAT": -8.66,
    "CIDADE": "Lajedo",
    "UF": "PERNAMBUCO",
    "anual": 5.29,
    "JAN": 5.67,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.36,
    "MAY": 4.61,
    "JUN": 4.19,
    "JUL": 4.27,
    "AUG": 4.93,
    "SEP": 5.58,
    "OCT": 5.63,
    "NOV": 5.96,
    "DEC": 5.82
  },
  {
    "LONG": -36.34,
    "LAT": -0.87,
    "CIDADE": "Calçado",
    "UF": "PERNAMBUCO",
    "anual": 5.29,
    "JAN": 5.67,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.36,
    "MAY": 4.61,
    "JUN": 4.19,
    "JUL": 4.27,
    "AUG": 4.93,
    "SEP": 5.58,
    "OCT": 5.63,
    "NOV": 5.96,
    "DEC": 5.82
  },
  {
    "LONG": -3.61,
    "LAT": -8.71,
    "CIDADE": "Jurema",
    "UF": "PERNAMBUCO",
    "anual": 5.28,
    "JAN": 5.6,
    "FEB": 5.6,
    "MAR": 5.76,
    "APR": 5.3,
    "MAY": 4.64,
    "JUN": 4.24,
    "JUL": 4.3,
    "AUG": 4.93,
    "SEP": 5.55,
    "OCT": 5.68,
    "NOV": 5.94,
    "DEC": 5.77
  },
  {
    "LONG": -36.01,
    "LAT": -8.66,
    "CIDADE": "Panelas",
    "UF": "PERNAMBUCO",
    "anual": 5.22,
    "JAN": 5.58,
    "FEB": 5.55,
    "MAR": 5.72,
    "APR": 5.25,
    "MAY": 4.59,
    "JUN": 4.21,
    "JUL": 4.24,
    "AUG": 4.85,
    "SEP": 5.45,
    "OCT": 5.61,
    "NOV": 5.9,
    "DEC": 5.74
  },
  {
    "LONG": -35.9,
    "LAT": -8.66,
    "CIDADE": "Lagoa dos Gatos",
    "UF": "PERNAMBUCO",
    "anual": 5.16,
    "JAN": 5.53,
    "FEB": 5.52,
    "MAR": 5.71,
    "APR": 5.22,
    "MAY": 4.49,
    "JUN": 4.15,
    "JUL": 4.17,
    "AUG": 4.75,
    "SEP": 5.36,
    "OCT": 5.52,
    "NOV": 5.83,
    "DEC": 5.67
  },
  {
    "LONG": -35.7,
    "LAT": -8.68,
    "CIDADE": "Catende",
    "UF": "PERNAMBUCO",
    "anual": 5.09,
    "JAN": 5.34,
    "FEB": 5.5,
    "MAR": 5.67,
    "APR": 5.21,
    "MAY": 4.51,
    "JUN": 4.09,
    "JUL": 4.1,
    "AUG": 4.68,
    "SEP": 5.31,
    "OCT": 5.43,
    "NOV": 5.71,
    "DEC": 5.56
  },
  {
    "LONG": -35.79,
    "LAT": -8.73,
    "CIDADE": "Jaqueira",
    "UF": "PERNAMBUCO",
    "anual": 5.09,
    "JAN": 5.34,
    "FEB": 5.5,
    "MAR": 5.67,
    "APR": 5.21,
    "MAY": 4.51,
    "JUN": 4.09,
    "JUL": 4.1,
    "AUG": 4.68,
    "SEP": 5.31,
    "OCT": 5.43,
    "NOV": 5.71,
    "DEC": 5.56
  },
  {
    "LONG": -35.53,
    "LAT": -8.71,
    "CIDADE": "Água Preta",
    "UF": "PERNAMBUCO",
    "anual": 5.14,
    "JAN": 5.41,
    "FEB": 5.47,
    "MAR": 5.74,
    "APR": 5.17,
    "MAY": 4.55,
    "JUN": 4.22,
    "JUL": 4.23,
    "AUG": 4.76,
    "SEP": 5.41,
    "OCT": 5.49,
    "NOV": 5.7,
    "DEC": 5.6
  },
  {
    "LONG": -35.59,
    "LAT": -8.68,
    "CIDADE": "Palmares",
    "UF": "PERNAMBUCO",
    "anual": 5.14,
    "JAN": 5.41,
    "FEB": 5.47,
    "MAR": 5.74,
    "APR": 5.17,
    "MAY": 4.55,
    "JUN": 4.22,
    "JUL": 4.23,
    "AUG": 4.76,
    "SEP": 5.41,
    "OCT": 5.49,
    "NOV": 5.7,
    "DEC": 5.6
  },
  {
    "LONG": -35.15,
    "LAT": -8.66,
    "CIDADE": "Rio Formoso",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.52,
    "FEB": 5.72,
    "MAR": 5.96,
    "APR": 5.27,
    "MAY": 4.66,
    "JUN": 4.36,
    "JUL": 4.43,
    "AUG": 5.04,
    "SEP": 5.5,
    "OCT": 5.63,
    "NOV": 5.81,
    "DEC": 5.76
  },
  {
    "LONG": -3.96,
    "LAT": -8.61,
    "CIDADE": "Orocó",
    "UF": "PERNAMBUCO",
    "anual": 5.76,
    "JAN": 5.91,
    "FEB": 5.83,
    "MAR": 6.04,
    "APR": 5.65,
    "MAY": 5.1,
    "JUN": 4.76,
    "JUL": 4.99,
    "AUG": 5.69,
    "SEP": 6.39,
    "OCT": 6.35,
    "NOV": 6.35,
    "DEC": 6.07
  },
  {
    "LONG": -3.86,
    "LAT": -8.6,
    "CIDADE": "Floresta",
    "UF": "PERNAMBUCO",
    "anual": 5.73,
    "JAN": 6.01,
    "FEB": 5.97,
    "MAR": 6.14,
    "APR": 5.81,
    "MAY": 5.03,
    "JUN": 4.49,
    "JUL": 4.76,
    "AUG": 5.45,
    "SEP": 6.28,
    "OCT": 6.32,
    "NOV": 6.4,
    "DEC": 6.1
  },
  {
    "LONG": -37.16,
    "LAT": -0.86,
    "CIDADE": "Buíque",
    "UF": "PERNAMBUCO",
    "anual": 5.64,
    "JAN": 5.96,
    "FEB": 5.92,
    "MAR": 6.18,
    "APR": 5.74,
    "MAY": 4.89,
    "JUN": 4.4,
    "JUL": 4.54,
    "AUG": 5.27,
    "SEP": 6.08,
    "OCT": 6.21,
    "NOV": 6.41,
    "DEC": 6.12
  },
  {
    "LONG": -36.88,
    "LAT": -8.58,
    "CIDADE": "Venturosa",
    "UF": "PERNAMBUCO",
    "anual": 5.5,
    "JAN": 5.91,
    "FEB": 5.83,
    "MAR": 6.04,
    "APR": 5.62,
    "MAY": 4.74,
    "JUN": 4.28,
    "JUL": 4.28,
    "AUG": 5.05,
    "SEP": 5.87,
    "OCT": 6.04,
    "NOV": 6.3,
    "DEC": 6.03
  },
  {
    "LONG": -36.18,
    "LAT": -8.58,
    "CIDADE": "Ibirajuba",
    "UF": "PERNAMBUCO",
    "anual": 5.23,
    "JAN": 5.58,
    "FEB": 5.53,
    "MAR": 5.75,
    "APR": 5.32,
    "MAY": 4.58,
    "JUN": 4.14,
    "JUL": 4.27,
    "AUG": 4.82,
    "SEP": 5.45,
    "OCT": 5.62,
    "NOV": 5.99,
    "DEC": 5.74
  },
  {
    "LONG": -35.95,
    "LAT": -8.62,
    "CIDADE": "Cupira",
    "UF": "PERNAMBUCO",
    "anual": 5.28,
    "JAN": 5.69,
    "FEB": 5.65,
    "MAR": 5.94,
    "APR": 5.35,
    "MAY": 4.61,
    "JUN": 4.22,
    "JUL": 4.24,
    "AUG": 4.84,
    "SEP": 5.48,
    "OCT": 5.64,
    "NOV": 5.93,
    "DEC": 5.76
  },
  {
    "LONG": -35.83,
    "LAT": -8.63,
    "CIDADE": "Belém de Maria",
    "UF": "PERNAMBUCO",
    "anual": 5.25,
    "JAN": 5.6,
    "FEB": 5.67,
    "MAR": 5.89,
    "APR": 5.35,
    "MAY": 4.61,
    "JUN": 4.18,
    "JUL": 4.2,
    "AUG": 4.82,
    "SEP": 5.44,
    "OCT": 5.61,
    "NOV": 5.9,
    "DEC": 5.75
  },
  {
    "LONG": -35.53,
    "LAT": -8.62,
    "CIDADE": "Joaquim Nabuco",
    "UF": "PERNAMBUCO",
    "anual": 5.04,
    "JAN": 5.36,
    "FEB": 5.41,
    "MAR": 5.62,
    "APR": 5.1,
    "MAY": 4.43,
    "JUN": 4.09,
    "JUL": 4.06,
    "AUG": 4.61,
    "SEP": 5.25,
    "OCT": 5.36,
    "NOV": 5.66,
    "DEC": 5.55
  },
  {
    "LONG": -35.38,
    "LAT": -8.58,
    "CIDADE": "Gameleira",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.39,
    "FEB": 5.58,
    "MAR": 5.73,
    "APR": 5.2,
    "MAY": 4.61,
    "JUN": 4.24,
    "JUL": 4.3,
    "AUG": 4.87,
    "SEP": 5.43,
    "OCT": 5.56,
    "NOV": 5.76,
    "DEC": 5.72
  },
  {
    "LONG": -35.11,
    "LAT": -8.59,
    "CIDADE": "Sirinhaém",
    "UF": "PERNAMBUCO",
    "anual": 5.28,
    "JAN": 5.43,
    "FEB": 5.73,
    "MAR": 5.96,
    "APR": 5.23,
    "MAY": 4.67,
    "JUN": 4.33,
    "JUL": 4.42,
    "AUG": 5.01,
    "SEP": 5.5,
    "OCT": 5.65,
    "NOV": 5.76,
    "DEC": 5.67
  },
  {
    "LONG": -41.01,
    "LAT": -8.51,
    "CIDADE": "Afrânio",
    "UF": "PERNAMBUCO",
    "anual": 5.87,
    "JAN": 5.8,
    "FEB": 5.65,
    "MAR": 5.77,
    "APR": 5.45,
    "MAY": 5.34,
    "JUN": 5.28,
    "JUL": 5.63,
    "AUG": 6.41,
    "SEP": 6.71,
    "OCT": 6.45,
    "NOV": 6.14,
    "DEC": 5.79
  },
  {
    "LONG": -39.31,
    "LAT": -8.51,
    "CIDADE": "Cabrobó",
    "UF": "PERNAMBUCO",
    "anual": 5.78,
    "JAN": 5.92,
    "FEB": 5.9,
    "MAR": 6.14,
    "APR": 5.7,
    "MAY": 5.13,
    "JUN": 4.79,
    "JUL": 5.04,
    "AUG": 5.71,
    "SEP": 6.33,
    "OCT": 6.31,
    "NOV": 6.36,
    "DEC": 6.06
  },
  {
    "LONG": -3.77,
    "LAT": -0.85,
    "CIDADE": "Ibimirim",
    "UF": "PERNAMBUCO",
    "anual": 5.73,
    "JAN": 6.03,
    "FEB": 6.03,
    "MAR": 6.25,
    "APR": 5.9,
    "MAY": 5.08,
    "JUN": 4.52,
    "JUL": 4.72,
    "AUG": 5.44,
    "SEP": 6.19,
    "OCT": 6.19,
    "NOV": 6.33,
    "DEC": 6.12
  },
  {
    "LONG": -36.95,
    "LAT": "#VALOR!",
    "CIDADE": "Pedra",
    "UF": "PERNAMBUCO",
    "anual": 5.56,
    "JAN": 5.86,
    "FEB": 5.9,
    "MAR": 6.11,
    "APR": 5.69,
    "MAY": 4.8,
    "JUN": 4.26,
    "JUL": 4.4,
    "AUG": 5.14,
    "SEP": 6,
    "OCT": 6.16,
    "NOV": 6.34,
    "DEC": 6.07
  },
  {
    "LONG": -36.78,
    "LAT": -8.47,
    "CIDADE": "Alagoinha",
    "UF": "PERNAMBUCO",
    "anual": 5.45,
    "JAN": 5.91,
    "FEB": 5.74,
    "MAR": 5.95,
    "APR": 5.55,
    "MAY": 4.64,
    "JUN": 4.17,
    "JUL": 4.29,
    "AUG": 5.03,
    "SEP": 5.82,
    "OCT": 6,
    "NOV": 6.3,
    "DEC": 6.04
  },
  {
    "LONG": -36.45,
    "LAT": -8.53,
    "CIDADE": "São Bento do Una",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.67,
    "FEB": 5.63,
    "MAR": 5.86,
    "APR": 5.46,
    "MAY": 4.65,
    "JUN": 4.15,
    "JUL": 4.28,
    "AUG": 4.9,
    "SEP": 5.54,
    "OCT": 5.72,
    "NOV": 6.02,
    "DEC": 5.82
  },
  {
    "LONG": -3.62,
    "LAT": -8.49,
    "CIDADE": "Cachoeirinha",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.62,
    "FEB": 5.63,
    "MAR": 5.86,
    "APR": 5.45,
    "MAY": 4.65,
    "JUN": 4.19,
    "JUL": 4.25,
    "AUG": 4.87,
    "SEP": 5.48,
    "OCT": 5.72,
    "NOV": 6.07,
    "DEC": 5.8
  },
  {
    "LONG": -36.06,
    "LAT": -8.49,
    "CIDADE": "Altinho",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.61,
    "FEB": 5.65,
    "MAR": 5.88,
    "APR": 5.38,
    "MAY": 4.64,
    "JUN": 4.21,
    "JUL": 4.27,
    "AUG": 4.87,
    "SEP": 5.49,
    "OCT": 5.63,
    "NOV": 5.92,
    "DEC": 5.7
  },
  {
    "LONG": -3.59,
    "LAT": -8.46,
    "CIDADE": "Agrestina",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.64,
    "FEB": 5.68,
    "MAR": 5.96,
    "APR": 5.41,
    "MAY": 4.62,
    "JUN": 4.25,
    "JUL": 4.34,
    "AUG": 4.93,
    "SEP": 5.55,
    "OCT": 5.67,
    "NOV": 5.9,
    "DEC": 5.71
  },
  {
    "LONG": -35.73,
    "LAT": -8.47,
    "CIDADE": "Bonito",
    "UF": "PERNAMBUCO",
    "anual": 5.17,
    "JAN": 5.5,
    "FEB": 5.57,
    "MAR": 5.79,
    "APR": 5.25,
    "MAY": 4.54,
    "JUN": 4.07,
    "JUL": 4.14,
    "AUG": 4.76,
    "SEP": 5.34,
    "OCT": 5.54,
    "NOV": 5.86,
    "DEC": 5.65
  },
  {
    "LONG": -35.55,
    "LAT": -8.47,
    "CIDADE": "Cortês",
    "UF": "PERNAMBUCO",
    "anual": 4.98,
    "JAN": 5.29,
    "FEB": 5.34,
    "MAR": 5.61,
    "APR": 5.06,
    "MAY": 4.4,
    "JUN": 4.03,
    "JUL": 4.03,
    "AUG": 4.57,
    "SEP": 5.13,
    "OCT": 5.26,
    "NOV": 5.6,
    "DEC": 5.51
  },
  {
    "LONG": -35.37,
    "LAT": "#VALOR!",
    "CIDADE": "Ribeirão",
    "UF": "PERNAMBUCO",
    "anual": 5.16,
    "JAN": 5.37,
    "FEB": 5.51,
    "MAR": 5.7,
    "APR": 5.17,
    "MAY": 4.56,
    "JUN": 4.22,
    "JUL": 4.28,
    "AUG": 4.82,
    "SEP": 5.39,
    "OCT": 5.54,
    "NOV": 5.7,
    "DEC": 5.65
  },
  {
    "LONG": -40.77,
    "LAT": -8.44,
    "CIDADE": "Dormentes",
    "UF": "PERNAMBUCO",
    "anual": 5.74,
    "JAN": 5.68,
    "FEB": 5.58,
    "MAR": 5.72,
    "APR": 5.36,
    "MAY": 5.09,
    "JUN": 4.99,
    "JUL": 5.34,
    "AUG": 6.21,
    "SEP": 6.62,
    "OCT": 6.36,
    "NOV": 6.11,
    "DEC": 5.76
  },
  {
    "LONG": -37.06,
    "LAT": -8.42,
    "CIDADE": "Arcoverde",
    "UF": "PERNAMBUCO",
    "anual": 5.65,
    "JAN": 5.81,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.76,
    "MAY": 4.98,
    "JUN": 4.5,
    "JUL": 4.66,
    "AUG": 5.41,
    "SEP": 6.15,
    "OCT": 6.21,
    "NOV": 6.32,
    "DEC": 6.03
  },
  {
    "LONG": -36.7,
    "LAT": -8.36,
    "CIDADE": "Pesqueira",
    "UF": "PERNAMBUCO",
    "anual": 5.39,
    "JAN": 5.79,
    "FEB": 5.75,
    "MAR": 5.93,
    "APR": 5.55,
    "MAY": 4.67,
    "JUN": 4.13,
    "JUL": 4.22,
    "AUG": 4.96,
    "SEP": 5.71,
    "OCT": 5.91,
    "NOV": 6.16,
    "DEC": 5.95
  },
  {
    "LONG": -36.56,
    "LAT": -8.36,
    "CIDADE": "Sanharó",
    "UF": "PERNAMBUCO",
    "anual": 5.37,
    "JAN": 5.7,
    "FEB": 5.7,
    "MAR": 5.92,
    "APR": 5.51,
    "MAY": 4.65,
    "JUN": 4.17,
    "JUL": 4.25,
    "AUG": 4.95,
    "SEP": 5.69,
    "OCT": 5.89,
    "NOV": 6.11,
    "DEC": 5.9
  },
  {
    "LONG": -35.8,
    "LAT": -8.43,
    "CIDADE": "São Joaquim do Monte",
    "UF": "PERNAMBUCO",
    "anual": 5.18,
    "JAN": 5.45,
    "FEB": 5.54,
    "MAR": 5.73,
    "APR": 5.24,
    "MAY": 4.55,
    "JUN": 4.12,
    "JUL": 4.22,
    "AUG": 4.85,
    "SEP": 5.44,
    "OCT": 5.58,
    "NOV": 5.85,
    "DEC": 5.6
  },
  {
    "LONG": -35.77,
    "LAT": -8.36,
    "CIDADE": "Camocim de São Félix",
    "UF": "PERNAMBUCO",
    "anual": 5.19,
    "JAN": 5.49,
    "FEB": 5.55,
    "MAR": 5.8,
    "APR": 5.24,
    "MAY": 4.53,
    "JUN": 4.09,
    "JUL": 4.18,
    "AUG": 4.82,
    "SEP": 5.41,
    "OCT": 5.62,
    "NOV": 5.86,
    "DEC": 5.66
  },
  {
    "LONG": -35.66,
    "LAT": -8.42,
    "CIDADE": "Barra de Guabiraba",
    "UF": "PERNAMBUCO",
    "anual": 5.12,
    "JAN": 5.41,
    "FEB": 5.5,
    "MAR": 5.74,
    "APR": 5.2,
    "MAY": 4.47,
    "JUN": 4.08,
    "JUL": 4.12,
    "AUG": 4.68,
    "SEP": 5.27,
    "OCT": 5.47,
    "NOV": 5.81,
    "DEC": 5.67
  },
  {
    "LONG": -35.45,
    "LAT": -8.38,
    "CIDADE": "Amaraji",
    "UF": "PERNAMBUCO",
    "anual": 4.98,
    "JAN": 5.27,
    "FEB": 5.39,
    "MAR": 5.63,
    "APR": 5.03,
    "MAY": 4.4,
    "JUN": 4.02,
    "JUL": 4,
    "AUG": 4.52,
    "SEP": 5.15,
    "OCT": 5.3,
    "NOV": 5.57,
    "DEC": 5.5
  },
  {
    "LONG": -35.22,
    "LAT": -8.36,
    "CIDADE": "Escada",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.36,
    "FEB": 5.6,
    "MAR": 5.79,
    "APR": 5.18,
    "MAY": 4.56,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 4.94,
    "SEP": 5.45,
    "OCT": 5.58,
    "NOV": 5.71,
    "DEC": 5.64
  },
  {
    "LONG": -35.06,
    "LAT": -8.39,
    "CIDADE": "Ipojuca",
    "UF": "PERNAMBUCO",
    "anual": 5.28,
    "JAN": 5.43,
    "FEB": 5.67,
    "MAR": 5.91,
    "APR": 5.31,
    "MAY": 4.66,
    "JUN": 4.41,
    "JUL": 4.47,
    "AUG": 5.08,
    "SEP": 5.45,
    "OCT": 5.61,
    "NOV": 5.7,
    "DEC": 5.66
  },
  {
    "LONG": -38.74,
    "LAT": -8.32,
    "CIDADE": "Carnaubeira da Penha",
    "UF": "PERNAMBUCO",
    "anual": 5.74,
    "JAN": 5.86,
    "FEB": 5.9,
    "MAR": 6.06,
    "APR": 5.73,
    "MAY": 5.08,
    "JUN": 4.62,
    "JUL": 4.88,
    "AUG": 5.62,
    "SEP": 6.44,
    "OCT": 6.35,
    "NOV": 6.37,
    "DEC": 6.03
  },
  {
    "LONG": -38.04,
    "LAT": -8.27,
    "CIDADE": "Betânia",
    "UF": "PERNAMBUCO",
    "anual": 5.75,
    "JAN": 6.01,
    "FEB": 5.95,
    "MAR": 6.13,
    "APR": 5.83,
    "MAY": 5.01,
    "JUN": 4.53,
    "JUL": 4.81,
    "AUG": 5.51,
    "SEP": 6.3,
    "OCT": 6.35,
    "NOV": 6.44,
    "DEC": 6.12
  },
  {
    "LONG": -36.42,
    "LAT": -8.34,
    "CIDADE": "Belo Jardim",
    "UF": "PERNAMBUCO",
    "anual": 5.32,
    "JAN": 5.74,
    "FEB": 5.67,
    "MAR": 5.81,
    "APR": 5.43,
    "MAY": 4.58,
    "JUN": 4.08,
    "JUL": 4.21,
    "AUG": 4.85,
    "SEP": 5.66,
    "OCT": 5.85,
    "NOV": 6.1,
    "DEC": 5.85
  },
  {
    "LONG": -36.29,
    "LAT": -8.32,
    "CIDADE": "Tacaimbó",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.54,
    "FEB": 5.51,
    "MAR": 5.73,
    "APR": 5.39,
    "MAY": 4.7,
    "JUN": 4.26,
    "JUL": 4.31,
    "AUG": 4.99,
    "SEP": 5.57,
    "OCT": 5.68,
    "NOV": 5.9,
    "DEC": 5.65
  },
  {
    "LONG": -36.14,
    "LAT": -8.33,
    "CIDADE": "São Caitano",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.54,
    "FEB": 5.53,
    "MAR": 5.74,
    "APR": 5.36,
    "MAY": 4.66,
    "JUN": 4.27,
    "JUL": 4.32,
    "AUG": 5.01,
    "SEP": 5.55,
    "OCT": 5.68,
    "NOV": 5.89,
    "DEC": 5.62
  },
  {
    "LONG": -35.97,
    "LAT": -0.83,
    "CIDADE": "Caruaru",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.44,
    "FEB": 5.61,
    "MAR": 5.71,
    "APR": 5.34,
    "MAY": 4.69,
    "JUN": 4.28,
    "JUL": 4.38,
    "AUG": 5.01,
    "SEP": 5.55,
    "OCT": 5.65,
    "NOV": 5.83,
    "DEC": 5.6
  },
  {
    "LONG": -35.71,
    "LAT": -8.33,
    "CIDADE": "Sairé",
    "UF": "PERNAMBUCO",
    "anual": 5.21,
    "JAN": 5.46,
    "FEB": 5.56,
    "MAR": 5.77,
    "APR": 5.24,
    "MAY": 4.58,
    "JUN": 4.19,
    "JUL": 4.27,
    "AUG": 4.9,
    "SEP": 5.48,
    "OCT": 5.64,
    "NOV": 5.83,
    "DEC": 5.6
  },
  {
    "LONG": -35.35,
    "LAT": -8.33,
    "CIDADE": "Primavera",
    "UF": "PERNAMBUCO",
    "anual": 5,
    "JAN": 5.22,
    "FEB": 5.4,
    "MAR": 5.57,
    "APR": 5.04,
    "MAY": 4.42,
    "JUN": 4.09,
    "JUL": 4.12,
    "AUG": 4.65,
    "SEP": 5.23,
    "OCT": 5.32,
    "NOV": 5.5,
    "DEC": 5.47
  },
  {
    "LONG": -35.03,
    "LAT": -8.28,
    "CIDADE": "Cabo de Santo Agostinho",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.4,
    "FEB": 5.64,
    "MAR": 5.81,
    "APR": 5.26,
    "MAY": 4.64,
    "JUN": 4.38,
    "JUL": 4.44,
    "AUG": 5.05,
    "SEP": 5.47,
    "OCT": 5.61,
    "NOV": 5.7,
    "DEC": 5.67
  },
  {
    "LONG": -40.61,
    "LAT": -8.16,
    "CIDADE": "Santa Filomena",
    "UF": "PERNAMBUCO",
    "anual": 5.79,
    "JAN": 5.62,
    "FEB": 5.55,
    "MAR": 5.73,
    "APR": 5.43,
    "MAY": 5.19,
    "JUN": 5.1,
    "JUL": 5.43,
    "AUG": 6.32,
    "SEP": 6.71,
    "OCT": 6.43,
    "NOV": 6.16,
    "DEC": 5.81
  },
  {
    "LONG": -40.34,
    "LAT": -0.82,
    "CIDADE": "Santa Cruz",
    "UF": "PERNAMBUCO",
    "anual": 5.6,
    "JAN": 5.57,
    "FEB": 5.44,
    "MAR": 5.67,
    "APR": 5.26,
    "MAY": 4.93,
    "JUN": 4.77,
    "JUL": 5.14,
    "AUG": 5.95,
    "SEP": 6.44,
    "OCT": 6.24,
    "NOV": 6.07,
    "DEC": 5.74
  },
  {
    "LONG": -39.38,
    "LAT": -8.23,
    "CIDADE": "Terra Nova",
    "UF": "PERNAMBUCO",
    "anual": 5.73,
    "JAN": 5.85,
    "FEB": 5.75,
    "MAR": 5.87,
    "APR": 5.62,
    "MAY": 5.08,
    "JUN": 4.72,
    "JUL": 5.02,
    "AUG": 5.71,
    "SEP": 6.47,
    "OCT": 6.35,
    "NOV": 6.31,
    "DEC": 5.96
  },
  {
    "LONG": -3.67,
    "LAT": -8.19,
    "CIDADE": "Poção",
    "UF": "PERNAMBUCO",
    "anual": 5.67,
    "JAN": 5.92,
    "FEB": 5.89,
    "MAR": 6.05,
    "APR": 5.71,
    "MAY": 4.98,
    "JUN": 4.51,
    "JUL": 4.68,
    "AUG": 5.47,
    "SEP": 6.22,
    "OCT": 6.25,
    "NOV": 6.33,
    "DEC": 6.01
  },
  {
    "LONG": -35.8,
    "LAT": -8.23,
    "CIDADE": "Bezerros",
    "UF": "PERNAMBUCO",
    "anual": 5.16,
    "JAN": 5.36,
    "FEB": 5.49,
    "MAR": 5.64,
    "APR": 5.18,
    "MAY": 4.57,
    "JUN": 4.2,
    "JUL": 4.29,
    "AUG": 4.89,
    "SEP": 5.45,
    "OCT": 5.59,
    "NOV": 5.75,
    "DEC": 5.54
  },
  {
    "LONG": -35.57,
    "LAT": -8.21,
    "CIDADE": "Gravatá",
    "UF": "PERNAMBUCO",
    "anual": 5.25,
    "JAN": 5.47,
    "FEB": 5.55,
    "MAR": 5.71,
    "APR": 5.25,
    "MAY": 4.69,
    "JUN": 4.41,
    "JUL": 4.44,
    "AUG": 5.14,
    "SEP": 5.55,
    "OCT": 5.59,
    "NOV": 5.67,
    "DEC": 5.51
  },
  {
    "LONG": -35.46,
    "LAT": -8.24,
    "CIDADE": "Chã Grande",
    "UF": "PERNAMBUCO",
    "anual": 5.25,
    "JAN": 5.49,
    "FEB": 5.56,
    "MAR": 5.72,
    "APR": 5.29,
    "MAY": 4.68,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 5.12,
    "SEP": 5.54,
    "OCT": 5.56,
    "NOV": 5.68,
    "DEC": 5.57
  },
  {
    "LONG": -34.92,
    "LAT": -8.17,
    "CIDADE": "Jaboatão dos Guararapes",
    "UF": "PERNAMBUCO",
    "anual": 5.45,
    "JAN": 5.62,
    "FEB": 5.83,
    "MAR": 5.98,
    "APR": 5.4,
    "MAY": 4.83,
    "JUN": 4.56,
    "JUL": 4.61,
    "AUG": 5.28,
    "SEP": 5.67,
    "OCT": 5.83,
    "NOV": 5.92,
    "DEC": 5.89
  },
  {
    "LONG": -39.58,
    "LAT": -8.09,
    "CIDADE": "Parnamirim",
    "UF": "PERNAMBUCO",
    "anual": 5.71,
    "JAN": 5.74,
    "FEB": 5.7,
    "MAR": 5.89,
    "APR": 5.57,
    "MAY": 5.02,
    "JUN": 4.76,
    "JUL": 4.99,
    "AUG": 5.77,
    "SEP": 6.45,
    "OCT": 6.35,
    "NOV": 6.28,
    "DEC": 5.96
  },
  {
    "LONG": -3.91,
    "LAT": -8.07,
    "CIDADE": "Salgueiro",
    "UF": "PERNAMBUCO",
    "anual": 5.71,
    "JAN": 5.75,
    "FEB": 5.74,
    "MAR": 5.89,
    "APR": 5.63,
    "MAY": 5.04,
    "JUN": 4.75,
    "JUL": 5.04,
    "AUG": 5.77,
    "SEP": 6.43,
    "OCT": 6.29,
    "NOV": 6.29,
    "DEC": 5.89
  },
  {
    "LONG": -38.74,
    "LAT": -8.12,
    "CIDADE": "Mirandiba",
    "UF": "PERNAMBUCO",
    "anual": 5.78,
    "JAN": 5.9,
    "FEB": 5.83,
    "MAR": 6.02,
    "APR": 5.72,
    "MAY": 5.1,
    "JUN": 4.71,
    "JUL": 4.97,
    "AUG": 5.72,
    "SEP": 6.49,
    "OCT": 6.38,
    "NOV": 6.43,
    "DEC": 6.04
  },
  {
    "LONG": -37.64,
    "LAT": -8.09,
    "CIDADE": "Custódia",
    "UF": "PERNAMBUCO",
    "anual": 5.79,
    "JAN": 5.99,
    "FEB": 6.07,
    "MAR": 6.13,
    "APR": 5.92,
    "MAY": 5.14,
    "JUN": 4.57,
    "JUL": 4.83,
    "AUG": 5.56,
    "SEP": 6.32,
    "OCT": 6.38,
    "NOV": 6.47,
    "DEC": 6.1
  },
  {
    "LONG": -37.27,
    "LAT": -8.07,
    "CIDADE": "Sertânia",
    "UF": "PERNAMBUCO",
    "anual": 5.73,
    "JAN": 5.95,
    "FEB": 5.95,
    "MAR": 6.04,
    "APR": 5.76,
    "MAY": 5.08,
    "JUN": 4.6,
    "JUL": 4.76,
    "AUG": 5.49,
    "SEP": 6.24,
    "OCT": 6.29,
    "NOV": 6.44,
    "DEC": 6.13
  },
  {
    "LONG": -3.64,
    "LAT": -8.14,
    "CIDADE": "Brejo da Madre de Deus",
    "UF": "PERNAMBUCO",
    "anual": 5.46,
    "JAN": 5.66,
    "FEB": 5.73,
    "MAR": 5.86,
    "APR": 5.57,
    "MAY": 4.86,
    "JUN": 4.48,
    "JUL": 4.58,
    "AUG": 5.26,
    "SEP": 5.81,
    "OCT": 5.87,
    "NOV": 6.01,
    "DEC": 5.79
  },
  {
    "LONG": -35.87,
    "LAT": -8.14,
    "CIDADE": "Riacho das Almas",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.47,
    "FEB": 5.6,
    "MAR": 5.74,
    "APR": 5.28,
    "MAY": 4.72,
    "JUN": 4.3,
    "JUL": 4.44,
    "AUG": 4.96,
    "SEP": 5.5,
    "OCT": 5.64,
    "NOV": 5.81,
    "DEC": 5.61
  },
  {
    "LONG": -3.54,
    "LAT": -8.14,
    "CIDADE": "Pombos",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.39,
    "FEB": 5.56,
    "MAR": 5.75,
    "APR": 5.24,
    "MAY": 4.69,
    "JUN": 4.37,
    "JUL": 4.48,
    "AUG": 5.06,
    "SEP": 5.56,
    "OCT": 5.63,
    "NOV": 5.68,
    "DEC": 5.67
  },
  {
    "LONG": -35.3,
    "LAT": -8.13,
    "CIDADE": "Vitória de Santo Antão",
    "UF": "PERNAMBUCO",
    "anual": 5.26,
    "JAN": 5.44,
    "FEB": 5.61,
    "MAR": 5.78,
    "APR": 5.22,
    "MAY": 4.67,
    "JUN": 4.38,
    "JUL": 4.46,
    "AUG": 5.08,
    "SEP": 5.58,
    "OCT": 5.61,
    "NOV": 5.68,
    "DEC": 5.67
  },
  {
    "LONG": -35.08,
    "LAT": -8.11,
    "CIDADE": "Moreno",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.52,
    "FEB": 5.72,
    "MAR": 5.89,
    "APR": 5.3,
    "MAY": 4.64,
    "JUN": 4.41,
    "JUL": 4.5,
    "AUG": 5.1,
    "SEP": 5.52,
    "OCT": 5.6,
    "NOV": 5.73,
    "DEC": 5.66
  },
  {
    "LONG": -38.29,
    "LAT": -7.98,
    "CIDADE": "Serra Talhada",
    "UF": "PERNAMBUCO",
    "anual": 5.83,
    "JAN": 5.95,
    "FEB": 6.02,
    "MAR": 6.12,
    "APR": 5.89,
    "MAY": 5.13,
    "JUN": 4.71,
    "JUL": 4.95,
    "AUG": 5.75,
    "SEP": 6.48,
    "OCT": 6.45,
    "NOV": 6.45,
    "DEC": 6.07
  },
  {
    "LONG": -36.5,
    "LAT": -7.98,
    "CIDADE": "Jataúba",
    "UF": "PERNAMBUCO",
    "anual": 5.52,
    "JAN": 5.72,
    "FEB": 5.74,
    "MAR": 5.91,
    "APR": 5.66,
    "MAY": 4.93,
    "JUN": 4.54,
    "JUL": 4.65,
    "AUG": 5.29,
    "SEP": 5.89,
    "OCT": 6,
    "NOV": 6.09,
    "DEC": 5.82
  },
  {
    "LONG": -36.06,
    "LAT": -8,
    "CIDADE": "Toritama",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.56,
    "FEB": 5.65,
    "MAR": 5.87,
    "APR": 5.49,
    "MAY": 4.8,
    "JUN": 4.29,
    "JUL": 4.37,
    "AUG": 4.99,
    "SEP": 5.56,
    "OCT": 5.75,
    "NOV": 5.95,
    "DEC": 5.73
  },
  {
    "LONG": -3.57,
    "LAT": -8.01,
    "CIDADE": "Cumaru",
    "UF": "PERNAMBUCO",
    "anual": 5.21,
    "JAN": 5.43,
    "FEB": 5.51,
    "MAR": 5.71,
    "APR": 5.28,
    "MAY": 4.67,
    "JUN": 4.3,
    "JUL": 4.38,
    "AUG": 4.96,
    "SEP": 5.46,
    "OCT": 5.54,
    "NOV": 5.68,
    "DEC": 5.59
  },
  {
    "LONG": -35.58,
    "LAT": -7.98,
    "CIDADE": "Passira",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.4,
    "FEB": 5.48,
    "MAR": 5.67,
    "APR": 5.25,
    "MAY": 4.64,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 4.99,
    "SEP": 5.45,
    "OCT": 5.5,
    "NOV": 5.64,
    "DEC": 5.53
  },
  {
    "LONG": -35.2,
    "LAT": -8.01,
    "CIDADE": "Chã de Alegria",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.49,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.34,
    "MAY": 4.75,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.16,
    "SEP": 5.62,
    "OCT": 5.67,
    "NOV": 5.74,
    "DEC": 5.71
  },
  {
    "LONG": -35.29,
    "LAT": -8.01,
    "CIDADE": "Glória do Goitá",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.49,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.34,
    "MAY": 4.75,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.16,
    "SEP": 5.62,
    "OCT": 5.67,
    "NOV": 5.74,
    "DEC": 5.71
  },
  {
    "LONG": -35.01,
    "LAT": -8.01,
    "CIDADE": "São Lourenço da Mata",
    "UF": "PERNAMBUCO",
    "anual": 5.32,
    "JAN": 5.51,
    "FEB": 5.74,
    "MAR": 5.88,
    "APR": 5.32,
    "MAY": 4.68,
    "JUN": 4.42,
    "JUL": 4.52,
    "AUG": 5.15,
    "SEP": 5.53,
    "OCT": 5.63,
    "NOV": 5.76,
    "DEC": 5.7
  },
  {
    "LONG": -34.98,
    "LAT": -8.02,
    "CIDADE": "Camaragibe",
    "UF": "PERNAMBUCO",
    "anual": 5.29,
    "JAN": 5.45,
    "FEB": 5.71,
    "MAR": 5.81,
    "APR": 5.31,
    "MAY": 4.69,
    "JUN": 4.42,
    "JUL": 4.53,
    "AUG": 5.18,
    "SEP": 5.42,
    "OCT": 5.6,
    "NOV": 5.7,
    "DEC": 5.64
  },
  {
    "LONG": -34.88,
    "LAT": -8.05,
    "CIDADE": "Recife",
    "UF": "PERNAMBUCO",
    "anual": 5.45,
    "JAN": 5.58,
    "FEB": 5.82,
    "MAR": 5.91,
    "APR": 5.4,
    "MAY": 4.79,
    "JUN": 4.53,
    "JUL": 4.66,
    "AUG": 5.37,
    "SEP": 5.69,
    "OCT": 5.89,
    "NOV": 5.9,
    "DEC": 5.83
  },
  {
    "LONG": -34.85,
    "LAT": -8.01,
    "CIDADE": "Olinda",
    "UF": "PERNAMBUCO",
    "anual": 5.45,
    "JAN": 5.58,
    "FEB": 5.82,
    "MAR": 5.91,
    "APR": 5.4,
    "MAY": 4.79,
    "JUN": 4.53,
    "JUL": 4.66,
    "AUG": 5.37,
    "SEP": 5.69,
    "OCT": 5.89,
    "NOV": 5.9,
    "DEC": 5.83
  },
  {
    "LONG": -40.08,
    "LAT": -7.88,
    "CIDADE": "Ouricuri",
    "UF": "PERNAMBUCO",
    "anual": 5.59,
    "JAN": 5.63,
    "FEB": 5.47,
    "MAR": 5.76,
    "APR": 5.36,
    "MAY": 4.93,
    "JUN": 4.65,
    "JUL": 4.9,
    "AUG": 5.79,
    "SEP": 6.33,
    "OCT": 6.28,
    "NOV": 6.16,
    "DEC": 5.88
  },
  {
    "LONG": -39.3,
    "LAT": -7.94,
    "CIDADE": "Serrita",
    "UF": "PERNAMBUCO",
    "anual": 5.7,
    "JAN": 5.73,
    "FEB": 5.73,
    "MAR": 5.78,
    "APR": 5.59,
    "MAY": 4.98,
    "JUN": 4.74,
    "JUL": 4.99,
    "AUG": 5.75,
    "SEP": 6.5,
    "OCT": 6.33,
    "NOV": 6.32,
    "DEC": 5.92
  },
  {
    "LONG": -38.97,
    "LAT": -7.93,
    "CIDADE": "Verdejante",
    "UF": "PERNAMBUCO",
    "anual": 5.74,
    "JAN": 5.69,
    "FEB": 5.76,
    "MAR": 5.92,
    "APR": 5.63,
    "MAY": 5.13,
    "JUN": 4.82,
    "JUL": 5.1,
    "AUG": 5.8,
    "SEP": 6.52,
    "OCT": 6.3,
    "NOV": 6.29,
    "DEC": 5.89
  },
  {
    "LONG": -38.76,
    "LAT": -7.87,
    "CIDADE": "São José do Belmonte",
    "UF": "PERNAMBUCO",
    "anual": 5.79,
    "JAN": 5.85,
    "FEB": 5.87,
    "MAR": 5.95,
    "APR": 5.74,
    "MAY": 5.14,
    "JUN": 4.83,
    "JUL": 5.1,
    "AUG": 5.83,
    "SEP": 6.56,
    "OCT": 6.36,
    "NOV": 6.32,
    "DEC": 5.99
  },
  {
    "LONG": -38.15,
    "LAT": -7.94,
    "CIDADE": "Calumbi",
    "UF": "PERNAMBUCO",
    "anual": 5.82,
    "JAN": 5.9,
    "FEB": 5.94,
    "MAR": 6.14,
    "APR": 5.9,
    "MAY": 5.11,
    "JUN": 4.73,
    "JUL": 4.97,
    "AUG": 5.79,
    "SEP": 6.5,
    "OCT": 6.44,
    "NOV": 6.42,
    "DEC": 6.04
  },
  {
    "LONG": -37.97,
    "LAT": -7.86,
    "CIDADE": "Flores",
    "UF": "PERNAMBUCO",
    "anual": 5.78,
    "JAN": 5.9,
    "FEB": 6.01,
    "MAR": 6.14,
    "APR": 5.92,
    "MAY": 5.1,
    "JUN": 4.64,
    "JUL": 4.86,
    "AUG": 5.64,
    "SEP": 6.39,
    "OCT": 6.37,
    "NOV": 6.4,
    "DEC": 6.03
  },
  {
    "LONG": -36.21,
    "LAT": -7.95,
    "CIDADE": "Santa Cruz do Capibaribe",
    "UF": "PERNAMBUCO",
    "anual": 5.43,
    "JAN": 5.6,
    "FEB": 5.72,
    "MAR": 5.86,
    "APR": 5.53,
    "MAY": 4.89,
    "JUN": 4.48,
    "JUL": 4.6,
    "AUG": 5.23,
    "SEP": 5.76,
    "OCT": 5.89,
    "NOV": 5.98,
    "DEC": 5.66
  },
  {
    "LONG": -36.05,
    "LAT": -0.79,
    "CIDADE": "Taquaritinga do Norte",
    "UF": "PERNAMBUCO",
    "anual": 5.4,
    "JAN": 5.61,
    "FEB": 5.73,
    "MAR": 5.91,
    "APR": 5.46,
    "MAY": 4.87,
    "JUN": 4.37,
    "JUL": 4.47,
    "AUG": 5.11,
    "SEP": 5.68,
    "OCT": 5.87,
    "NOV": 6,
    "DEC": 5.74
  },
  {
    "LONG": -35.92,
    "LAT": -7.94,
    "CIDADE": "Frei Miguelinho",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.45,
    "FEB": 5.62,
    "MAR": 5.84,
    "APR": 5.35,
    "MAY": 4.81,
    "JUN": 4.31,
    "JUL": 4.52,
    "AUG": 5.07,
    "SEP": 5.52,
    "OCT": 5.68,
    "NOV": 5.85,
    "DEC": 5.66
  },
  {
    "LONG": -35.99,
    "LAT": -7.9,
    "CIDADE": "Vertentes",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.45,
    "FEB": 5.62,
    "MAR": 5.84,
    "APR": 5.35,
    "MAY": 4.81,
    "JUN": 4.31,
    "JUL": 4.52,
    "AUG": 5.07,
    "SEP": 5.52,
    "OCT": 5.68,
    "NOV": 5.85,
    "DEC": 5.66
  },
  {
    "LONG": -35.63,
    "LAT": -7.94,
    "CIDADE": "Salgadinho",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.43,
    "FEB": 5.56,
    "MAR": 5.81,
    "APR": 5.34,
    "MAY": 4.71,
    "JUN": 4.37,
    "JUL": 4.5,
    "AUG": 5.05,
    "SEP": 5.56,
    "OCT": 5.59,
    "NOV": 5.75,
    "DEC": 5.61
  },
  {
    "LONG": -35.59,
    "LAT": -7.86,
    "CIDADE": "João Alfredo",
    "UF": "PERNAMBUCO",
    "anual": 5.17,
    "JAN": 5.35,
    "FEB": 5.39,
    "MAR": 5.66,
    "APR": 5.25,
    "MAY": 4.63,
    "JUN": 4.32,
    "JUL": 4.41,
    "AUG": 4.99,
    "SEP": 5.44,
    "OCT": 5.45,
    "NOV": 5.64,
    "DEC": 5.49
  },
  {
    "LONG": -35.45,
    "LAT": -7.88,
    "CIDADE": "Limoeiro",
    "UF": "PERNAMBUCO",
    "anual": 5.19,
    "JAN": 5.4,
    "FEB": 5.44,
    "MAR": 5.65,
    "APR": 5.27,
    "MAY": 4.62,
    "JUN": 4.34,
    "JUL": 4.42,
    "AUG": 5,
    "SEP": 5.47,
    "OCT": 5.54,
    "NOV": 5.68,
    "DEC": 5.52
  },
  {
    "LONG": -35.39,
    "LAT": -0.79,
    "CIDADE": "Feira Nova",
    "UF": "PERNAMBUCO",
    "anual": 5.29,
    "JAN": 5.47,
    "FEB": 5.64,
    "MAR": 5.75,
    "APR": 5.36,
    "MAY": 4.7,
    "JUN": 4.4,
    "JUL": 4.51,
    "AUG": 5.09,
    "SEP": 5.59,
    "OCT": 5.63,
    "NOV": 5.72,
    "DEC": 5.62
  },
  {
    "LONG": -35.29,
    "LAT": -7.93,
    "CIDADE": "Lagoa do Itaenga",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.47,
    "FEB": 5.68,
    "MAR": 5.8,
    "APR": 5.39,
    "MAY": 4.74,
    "JUN": 4.43,
    "JUL": 4.54,
    "AUG": 5.2,
    "SEP": 5.58,
    "OCT": 5.66,
    "NOV": 5.74,
    "DEC": 5.68
  },
  {
    "LONG": -35.17,
    "LAT": -7.9,
    "CIDADE": "Paudalho",
    "UF": "PERNAMBUCO",
    "anual": 5.34,
    "JAN": 5.46,
    "FEB": 5.72,
    "MAR": 5.84,
    "APR": 5.38,
    "MAY": 4.76,
    "JUN": 4.46,
    "JUL": 4.55,
    "AUG": 5.24,
    "SEP": 5.6,
    "OCT": 5.66,
    "NOV": 5.77,
    "DEC": 5.7
  },
  {
    "LONG": -34.91,
    "LAT": -7.9,
    "CIDADE": "Abreu e Lima",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.44,
    "FEB": 5.71,
    "MAR": 5.83,
    "APR": 5.3,
    "MAY": 4.72,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.2,
    "SEP": 5.45,
    "OCT": 5.57,
    "NOV": 5.73,
    "DEC": 5.64
  },
  {
    "LONG": -34.87,
    "LAT": -7.94,
    "CIDADE": "Paulista",
    "UF": "PERNAMBUCO",
    "anual": 5.39,
    "JAN": 5.52,
    "FEB": 5.78,
    "MAR": 5.9,
    "APR": 5.37,
    "MAY": 4.78,
    "JUN": 4.51,
    "JUL": 4.6,
    "AUG": 5.31,
    "SEP": 5.57,
    "OCT": 5.77,
    "NOV": 5.86,
    "DEC": 5.73
  },
  {
    "LONG": -4.03,
    "LAT": -7.76,
    "CIDADE": "Trindade",
    "UF": "PERNAMBUCO",
    "anual": 5.64,
    "JAN": 5.58,
    "FEB": 5.43,
    "MAR": 5.76,
    "APR": 5.36,
    "MAY": 4.99,
    "JUN": 4.78,
    "JUL": 5.03,
    "AUG": 5.97,
    "SEP": 6.4,
    "OCT": 6.28,
    "NOV": 6.2,
    "DEC": 5.91
  },
  {
    "LONG": -39.93,
    "LAT": -0.78,
    "CIDADE": "Bodocó",
    "UF": "PERNAMBUCO",
    "anual": 5.65,
    "JAN": 5.64,
    "FEB": 5.57,
    "MAR": 5.82,
    "APR": 5.43,
    "MAY": 5,
    "JUN": 4.67,
    "JUL": 5,
    "AUG": 5.84,
    "SEP": 6.36,
    "OCT": 6.32,
    "NOV": 6.24,
    "DEC": 5.92
  },
  {
    "LONG": -38.11,
    "LAT": -7.83,
    "CIDADE": "Triunfo",
    "UF": "PERNAMBUCO",
    "anual": 5.87,
    "JAN": 5.83,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.89,
    "MAY": 5.23,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 6,
    "SEP": 6.59,
    "OCT": 6.44,
    "NOV": 6.41,
    "DEC": 5.97
  },
  {
    "LONG": -38.15,
    "LAT": -7.82,
    "CIDADE": "Santa Cruz da Baixa Verde",
    "UF": "PERNAMBUCO",
    "anual": 5.87,
    "JAN": 5.83,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.89,
    "MAY": 5.23,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 6,
    "SEP": 6.59,
    "OCT": 6.44,
    "NOV": 6.41,
    "DEC": 5.97
  },
  {
    "LONG": -37.8,
    "LAT": -7.8,
    "CIDADE": "Carnaíba",
    "UF": "PERNAMBUCO",
    "anual": 5.85,
    "JAN": 5.95,
    "FEB": 5.99,
    "MAR": 6.13,
    "APR": 5.92,
    "MAY": 5.25,
    "JUN": 4.85,
    "JUL": 5,
    "AUG": 5.76,
    "SEP": 6.44,
    "OCT": 6.39,
    "NOV": 6.44,
    "DEC": 6.05
  },
  {
    "LONG": -37.64,
    "LAT": -7.75,
    "CIDADE": "Afogados da Ingazeira",
    "UF": "PERNAMBUCO",
    "anual": 5.82,
    "JAN": 5.89,
    "FEB": 5.98,
    "MAR": 6.08,
    "APR": 5.88,
    "MAY": 5.24,
    "JUN": 4.85,
    "JUL": 4.97,
    "AUG": 5.74,
    "SEP": 6.42,
    "OCT": 6.36,
    "NOV": 6.41,
    "DEC": 6.02
  },
  {
    "LONG": -37.51,
    "LAT": -7.84,
    "CIDADE": "Iguaracy",
    "UF": "PERNAMBUCO",
    "anual": 5.84,
    "JAN": 5.91,
    "FEB": 5.98,
    "MAR": 6.09,
    "APR": 5.89,
    "MAY": 5.27,
    "JUN": 4.88,
    "JUL": 5.03,
    "AUG": 5.79,
    "SEP": 6.43,
    "OCT": 6.38,
    "NOV": 6.43,
    "DEC": 6.05
  },
  {
    "LONG": -35.85,
    "LAT": -7.77,
    "CIDADE": "Vertente do Lério",
    "UF": "PERNAMBUCO",
    "anual": 5.34,
    "JAN": 5.52,
    "FEB": 5.66,
    "MAR": 5.87,
    "APR": 5.42,
    "MAY": 4.82,
    "JUN": 4.36,
    "JUL": 4.54,
    "AUG": 5.16,
    "SEP": 5.59,
    "OCT": 5.71,
    "NOV": 5.87,
    "DEC": 5.63
  },
  {
    "LONG": -35.88,
    "LAT": -0.78,
    "CIDADE": "Santa Maria do Cambucá",
    "UF": "PERNAMBUCO",
    "anual": 5.34,
    "JAN": 5.52,
    "FEB": 5.66,
    "MAR": 5.87,
    "APR": 5.42,
    "MAY": 4.82,
    "JUN": 4.36,
    "JUL": 4.54,
    "AUG": 5.16,
    "SEP": 5.59,
    "OCT": 5.71,
    "NOV": 5.87,
    "DEC": 5.63
  },
  {
    "LONG": -35.76,
    "LAT": -7.85,
    "CIDADE": "Surubim",
    "UF": "PERNAMBUCO",
    "anual": 5.3,
    "JAN": 5.52,
    "FEB": 5.58,
    "MAR": 5.81,
    "APR": 5.4,
    "MAY": 4.76,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 5.13,
    "SEP": 5.58,
    "OCT": 5.64,
    "NOV": 5.81,
    "DEC": 5.59
  },
  {
    "LONG": -3.56,
    "LAT": -0.78,
    "CIDADE": "Bom Jardim",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.37,
    "FEB": 5.44,
    "MAR": 5.66,
    "APR": 5.3,
    "MAY": 4.64,
    "JUN": 4.3,
    "JUL": 4.39,
    "AUG": 5,
    "SEP": 5.49,
    "OCT": 5.55,
    "NOV": 5.71,
    "DEC": 5.52
  },
  {
    "LONG": -35.31,
    "LAT": -7.84,
    "CIDADE": "Lagoa do Carro",
    "UF": "PERNAMBUCO",
    "anual": 5.2,
    "JAN": 5.36,
    "FEB": 5.52,
    "MAR": 5.69,
    "APR": 5.31,
    "MAY": 4.64,
    "JUN": 4.34,
    "JUL": 4.44,
    "AUG": 5.03,
    "SEP": 5.48,
    "OCT": 5.53,
    "NOV": 5.64,
    "DEC": 5.46
  },
  {
    "LONG": -35.24,
    "LAT": -7.8,
    "CIDADE": "Tracunhaém",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.38,
    "FEB": 5.62,
    "MAR": 5.77,
    "APR": 5.33,
    "MAY": 4.72,
    "JUN": 4.4,
    "JUL": 4.48,
    "AUG": 5.14,
    "SEP": 5.53,
    "OCT": 5.58,
    "NOV": 5.71,
    "DEC": 5.56
  },
  {
    "LONG": -35.26,
    "LAT": -7.85,
    "CIDADE": "Carpina",
    "UF": "PERNAMBUCO",
    "anual": 5.27,
    "JAN": 5.38,
    "FEB": 5.62,
    "MAR": 5.77,
    "APR": 5.33,
    "MAY": 4.72,
    "JUN": 4.4,
    "JUL": 4.48,
    "AUG": 5.14,
    "SEP": 5.53,
    "OCT": 5.58,
    "NOV": 5.71,
    "DEC": 5.56
  },
  {
    "LONG": -35.08,
    "LAT": -7.78,
    "CIDADE": "Araçoiaba",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.45,
    "FEB": 5.71,
    "MAR": 5.9,
    "APR": 5.31,
    "MAY": 4.7,
    "JUN": 4.39,
    "JUL": 4.55,
    "AUG": 5.22,
    "SEP": 5.49,
    "OCT": 5.61,
    "NOV": 5.73,
    "DEC": 5.7
  },
  {
    "LONG": -34.9,
    "LAT": -7.84,
    "CIDADE": "Igarassu",
    "UF": "PERNAMBUCO",
    "anual": 5.34,
    "JAN": 5.49,
    "FEB": 5.76,
    "MAR": 5.97,
    "APR": 5.32,
    "MAY": 4.73,
    "JUN": 4.45,
    "JUL": 4.55,
    "AUG": 5.22,
    "SEP": 5.45,
    "OCT": 5.61,
    "NOV": 5.78,
    "DEC": 5.7
  },
  {
    "LONG": -34.9,
    "LAT": -7.77,
    "CIDADE": "Itapissuma",
    "UF": "PERNAMBUCO",
    "anual": 5.52,
    "JAN": 5.65,
    "FEB": 5.9,
    "MAR": 6.07,
    "APR": 5.49,
    "MAY": 4.89,
    "JUN": 4.59,
    "JUL": 4.67,
    "AUG": 5.43,
    "SEP": 5.72,
    "OCT": 5.92,
    "NOV": 5.96,
    "DEC": 5.9
  },
  {
    "LONG": -39.62,
    "LAT": -7.71,
    "CIDADE": "Granito",
    "UF": "PERNAMBUCO",
    "anual": 5.68,
    "JAN": 5.68,
    "FEB": 5.64,
    "MAR": 5.81,
    "APR": 5.5,
    "MAY": 5.02,
    "JUN": 4.68,
    "JUL": 4.98,
    "AUG": 5.82,
    "SEP": 6.44,
    "OCT": 6.35,
    "NOV": 6.25,
    "DEC": 5.94
  },
  {
    "LONG": -39.24,
    "LAT": -7.71,
    "CIDADE": "Cedro",
    "UF": "PERNAMBUCO",
    "anual": 5.7,
    "JAN": 5.75,
    "FEB": 5.68,
    "MAR": 5.9,
    "APR": 5.58,
    "MAY": 4.97,
    "JUN": 4.68,
    "JUL": 4.93,
    "AUG": 5.73,
    "SEP": 6.5,
    "OCT": 6.33,
    "NOV": 6.34,
    "DEC": 5.99
  },
  {
    "LONG": -37.85,
    "LAT": -7.72,
    "CIDADE": "Quixaba",
    "UF": "PERNAMBUCO",
    "anual": 5.88,
    "JAN": 5.88,
    "FEB": 5.97,
    "MAR": 6.16,
    "APR": 5.96,
    "MAY": 5.31,
    "JUN": 4.9,
    "JUL": 5.15,
    "AUG": 5.93,
    "SEP": 6.49,
    "OCT": 6.4,
    "NOV": 6.41,
    "DEC": 6.01
  },
  {
    "LONG": -37.46,
    "LAT": -7.68,
    "CIDADE": "Ingazeira",
    "UF": "PERNAMBUCO",
    "anual": 5.82,
    "JAN": 5.87,
    "FEB": 5.97,
    "MAR": 6.12,
    "APR": 5.91,
    "MAY": 5.22,
    "JUN": 4.81,
    "JUL": 4.96,
    "AUG": 5.74,
    "SEP": 6.4,
    "OCT": 6.37,
    "NOV": 6.43,
    "DEC": 6.01
  },
  {
    "LONG": -35.72,
    "LAT": -7.75,
    "CIDADE": "Casinhas",
    "UF": "PERNAMBUCO",
    "anual": 5.32,
    "JAN": 5.43,
    "FEB": 5.62,
    "MAR": 5.78,
    "APR": 5.36,
    "MAY": 4.8,
    "JUN": 4.38,
    "JUL": 4.58,
    "AUG": 5.18,
    "SEP": 5.63,
    "OCT": 5.7,
    "NOV": 5.82,
    "DEC": 5.6
  },
  {
    "LONG": -35.6,
    "LAT": -7.75,
    "CIDADE": "Orobó",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.4,
    "FEB": 5.62,
    "MAR": 5.83,
    "APR": 5.35,
    "MAY": 4.75,
    "JUN": 4.29,
    "JUL": 4.49,
    "AUG": 5.15,
    "SEP": 5.62,
    "OCT": 5.71,
    "NOV": 5.87,
    "DEC": 5.66
  },
  {
    "LONG": -35.52,
    "LAT": -7.69,
    "CIDADE": "Machados",
    "UF": "PERNAMBUCO",
    "anual": 5.24,
    "JAN": 5.37,
    "FEB": 5.58,
    "MAR": 5.78,
    "APR": 5.33,
    "MAY": 4.69,
    "JUN": 4.23,
    "JUL": 4.35,
    "AUG": 5.04,
    "SEP": 5.48,
    "OCT": 5.61,
    "NOV": 5.78,
    "DEC": 5.6
  },
  {
    "LONG": -35.32,
    "LAT": -7.66,
    "CIDADE": "Vicência",
    "UF": "PERNAMBUCO",
    "anual": 5.14,
    "JAN": 5.22,
    "FEB": 5.47,
    "MAR": 5.69,
    "APR": 5.23,
    "MAY": 4.61,
    "JUN": 4.27,
    "JUL": 4.33,
    "AUG": 5.01,
    "SEP": 5.4,
    "OCT": 5.44,
    "NOV": 5.64,
    "DEC": 5.43
  },
  {
    "LONG": -35.33,
    "LAT": -7.72,
    "CIDADE": "Buenos Aires",
    "UF": "PERNAMBUCO",
    "anual": 5.14,
    "JAN": 5.22,
    "FEB": 5.47,
    "MAR": 5.69,
    "APR": 5.23,
    "MAY": 4.61,
    "JUN": 4.27,
    "JUL": 4.33,
    "AUG": 5.01,
    "SEP": 5.4,
    "OCT": 5.44,
    "NOV": 5.64,
    "DEC": 5.43
  },
  {
    "LONG": -35.22,
    "LAT": -7.74,
    "CIDADE": "Nazaré da Mata",
    "UF": "PERNAMBUCO",
    "anual": 5.22,
    "JAN": 5.27,
    "FEB": 5.53,
    "MAR": 5.76,
    "APR": 5.3,
    "MAY": 4.67,
    "JUN": 4.33,
    "JUL": 4.42,
    "AUG": 5.09,
    "SEP": 5.47,
    "OCT": 5.53,
    "NOV": 5.72,
    "DEC": 5.54
  },
  {
    "LONG": -35.1,
    "LAT": -7.66,
    "CIDADE": "Itaquitinga",
    "UF": "PERNAMBUCO",
    "anual": 5.31,
    "JAN": 5.37,
    "FEB": 5.64,
    "MAR": 5.85,
    "APR": 5.35,
    "MAY": 4.75,
    "JUN": 4.39,
    "JUL": 4.49,
    "AUG": 5.18,
    "SEP": 5.56,
    "OCT": 5.64,
    "NOV": 5.83,
    "DEC": 5.68
  },
  {
    "LONG": -34.83,
    "LAT": -7.75,
    "CIDADE": "Ilha de Itamaracá",
    "UF": "PERNAMBUCO",
    "anual": 5.54,
    "JAN": 5.59,
    "FEB": 5.95,
    "MAR": 6.09,
    "APR": 5.5,
    "MAY": 4.96,
    "JUN": 4.64,
    "JUL": 4.76,
    "AUG": 5.46,
    "SEP": 5.74,
    "OCT": 5.94,
    "NOV": 5.94,
    "DEC": 5.86
  },
  {
    "LONG": -4.05,
    "LAT": -7.57,
    "CIDADE": "Araripina",
    "UF": "PERNAMBUCO",
    "anual": 5.65,
    "JAN": 5.43,
    "FEB": 5.3,
    "MAR": 5.54,
    "APR": 5.29,
    "MAY": 5.09,
    "JUN": 4.96,
    "JUL": 5.35,
    "AUG": 6.16,
    "SEP": 6.49,
    "OCT": 6.31,
    "NOV": 6.12,
    "DEC": 5.73
  },
  {
    "LONG": -40.15,
    "LAT": -7.65,
    "CIDADE": "Ipubi",
    "UF": "PERNAMBUCO",
    "anual": 5.6,
    "JAN": 5.47,
    "FEB": 5.44,
    "MAR": 5.62,
    "APR": 5.3,
    "MAY": 4.97,
    "JUN": 4.73,
    "JUL": 5.06,
    "AUG": 5.93,
    "SEP": 6.44,
    "OCT": 6.25,
    "NOV": 6.16,
    "DEC": 5.79
  },
  {
    "LONG": -39.55,
    "LAT": -0.76,
    "CIDADE": "Moreilândia",
    "UF": "PERNAMBUCO",
    "anual": 5.68,
    "JAN": 5.54,
    "FEB": 5.58,
    "MAR": 5.77,
    "APR": 5.53,
    "MAY": 5.04,
    "JUN": 4.76,
    "JUL": 5.04,
    "AUG": 5.87,
    "SEP": 6.5,
    "OCT": 6.39,
    "NOV": 6.25,
    "DEC": 5.88
  },
  {
    "LONG": -37.65,
    "LAT": -0.76,
    "CIDADE": "Solidão",
    "UF": "PERNAMBUCO",
    "anual": 5.89,
    "JAN": 5.85,
    "FEB": 6.01,
    "MAR": 6.14,
    "APR": 5.97,
    "MAY": 5.29,
    "JUN": 4.97,
    "JUL": 5.12,
    "AUG": 5.94,
    "SEP": 6.51,
    "OCT": 6.44,
    "NOV": 6.43,
    "DEC": 5.98
  },
  {
    "LONG": -3.75,
    "LAT": -7.58,
    "CIDADE": "Tabira",
    "UF": "PERNAMBUCO",
    "anual": 5.86,
    "JAN": 5.87,
    "FEB": 6.05,
    "MAR": 6.14,
    "APR": 5.97,
    "MAY": 5.27,
    "JUN": 4.91,
    "JUL": 5.05,
    "AUG": 5.85,
    "SEP": 6.45,
    "OCT": 6.38,
    "NOV": 6.43,
    "DEC": 6
  },
  {
    "LONG": "#VALOR!",
    "LAT": -7.6,
    "CIDADE": "Tuparetama",
    "UF": "PERNAMBUCO",
    "anual": 5.81,
    "JAN": 5.93,
    "FEB": 5.89,
    "MAR": 6.08,
    "APR": 5.84,
    "MAY": 5.25,
    "JUN": 4.8,
    "JUL": 4.98,
    "AUG": 5.73,
    "SEP": 6.4,
    "OCT": 6.39,
    "NOV": 6.42,
    "DEC": 5.99
  },
  {
    "LONG": "#VALOR!",
    "LAT": -7.55,
    "CIDADE": "Macaparana",
    "UF": "PERNAMBUCO",
    "anual": 5.17,
    "JAN": 5.26,
    "FEB": 5.54,
    "MAR": 5.69,
    "APR": 5.3,
    "MAY": 4.63,
    "JUN": 4.19,
    "JUL": 4.29,
    "AUG": 4.97,
    "SEP": 5.38,
    "OCT": 5.48,
    "NOV": 5.76,
    "DEC": 5.53
  },
  {
    "LONG": -35.49,
    "LAT": -7.59,
    "CIDADE": "São Vicente Ferrer",
    "UF": "PERNAMBUCO",
    "anual": 5.17,
    "JAN": 5.26,
    "FEB": 5.54,
    "MAR": 5.69,
    "APR": 5.3,
    "MAY": 4.63,
    "JUN": 4.19,
    "JUL": 4.29,
    "AUG": 4.97,
    "SEP": 5.38,
    "OCT": 5.48,
    "NOV": 5.76,
    "DEC": 5.53
  },
  {
    "LONG": -35.23,
    "LAT": -7.6,
    "CIDADE": "Aliança",
    "UF": "PERNAMBUCO",
    "anual": 5.21,
    "JAN": 5.23,
    "FEB": 5.49,
    "MAR": 5.74,
    "APR": 5.28,
    "MAY": 4.72,
    "JUN": 4.34,
    "JUL": 4.43,
    "AUG": 5.1,
    "SEP": 5.44,
    "OCT": 5.51,
    "NOV": 5.69,
    "DEC": 5.52
  },
  {
    "LONG": -35.1,
    "LAT": -7.59,
    "CIDADE": "Condado",
    "UF": "PERNAMBUCO",
    "anual": 5.33,
    "JAN": 5.38,
    "FEB": 5.63,
    "MAR": 5.86,
    "APR": 5.35,
    "MAY": 4.79,
    "JUN": 4.4,
    "JUL": 4.52,
    "AUG": 5.23,
    "SEP": 5.57,
    "OCT": 5.68,
    "NOV": 5.83,
    "DEC": 5.71
  },
  {
    "LONG": -35,
    "LAT": -0.76,
    "CIDADE": "Goiana",
    "UF": "PERNAMBUCO",
    "anual": 5.45,
    "JAN": 5.52,
    "FEB": 5.95,
    "MAR": 6.04,
    "APR": 5.46,
    "MAY": 4.91,
    "JUN": 4.56,
    "JUL": 4.63,
    "AUG": 5.34,
    "SEP": 5.59,
    "OCT": 5.81,
    "NOV": 5.84,
    "DEC": 5.79
  },
  {
    "LONG": -39.72,
    "LAT": -0.75,
    "CIDADE": "Exu",
    "UF": "PERNAMBUCO",
    "anual": 5.66,
    "JAN": 5.57,
    "FEB": 5.55,
    "MAR": 5.72,
    "APR": 5.42,
    "MAY": 5,
    "JUN": 4.77,
    "JUL": 5.06,
    "AUG": 5.94,
    "SEP": 6.52,
    "OCT": 6.37,
    "NOV": 6.19,
    "DEC": 5.77
  },
  {
    "LONG": -37.27,
    "LAT": -7.47,
    "CIDADE": "São José do Egito",
    "UF": "PERNAMBUCO",
    "anual": 5.77,
    "JAN": 5.85,
    "FEB": 5.88,
    "MAR": 6.03,
    "APR": 5.83,
    "MAY": 5.22,
    "JUN": 4.77,
    "JUL": 4.98,
    "AUG": 5.73,
    "SEP": 6.34,
    "OCT": 6.36,
    "NOV": 6.37,
    "DEC": 5.95
  },
  {
    "LONG": -35.32,
    "LAT": -7.51,
    "CIDADE": "Timbaúba",
    "UF": "PERNAMBUCO",
    "anual": 5.23,
    "JAN": 5.28,
    "FEB": 5.56,
    "MAR": 5.72,
    "APR": 5.3,
    "MAY": 4.72,
    "JUN": 4.4,
    "JUL": 4.48,
    "AUG": 5.17,
    "SEP": 5.47,
    "OCT": 5.51,
    "NOV": 5.65,
    "DEC": 5.48
  },
  {
    "LONG": -37.48,
    "LAT": -7.38,
    "CIDADE": "Santa Terezinha",
    "UF": "PERNAMBUCO",
    "anual": 5.87,
    "JAN": 5.81,
    "FEB": 5.85,
    "MAR": 6.01,
    "APR": 5.91,
    "MAY": 5.32,
    "JUN": 4.96,
    "JUL": 5.3,
    "AUG": 6.03,
    "SEP": 6.53,
    "OCT": 6.43,
    "NOV": 6.39,
    "DEC": 5.96
  },
  {
    "LONG": -37.19,
    "LAT": -7.38,
    "CIDADE": "Itapetim",
    "UF": "PERNAMBUCO",
    "anual": 5.76,
    "JAN": 5.79,
    "FEB": 5.83,
    "MAR": 6.02,
    "APR": 5.81,
    "MAY": 5.19,
    "JUN": 4.81,
    "JUL": 5.05,
    "AUG": 5.74,
    "SEP": 6.33,
    "OCT": 6.33,
    "NOV": 6.31,
    "DEC": 5.91
  },
  {
    "LONG": -35.24,
    "LAT": -7.45,
    "CIDADE": "Ferreiros",
    "UF": "PERNAMBUCO",
    "anual": 5.39,
    "JAN": 5.43,
    "FEB": 5.72,
    "MAR": 5.87,
    "APR": 5.48,
    "MAY": 4.88,
    "JUN": 4.51,
    "JUL": 4.63,
    "AUG": 5.29,
    "SEP": 5.64,
    "OCT": 5.7,
    "NOV": 5.85,
    "DEC": 5.66
  },
  {
    "LONG": -35.27,
    "LAT": -7.41,
    "CIDADE": "Camutanga",
    "UF": "PERNAMBUCO",
    "anual": 5.39,
    "JAN": 5.43,
    "FEB": 5.72,
    "MAR": 5.87,
    "APR": 5.48,
    "MAY": 4.88,
    "JUN": 4.51,
    "JUL": 4.63,
    "AUG": 5.29,
    "SEP": 5.64,
    "OCT": 5.7,
    "NOV": 5.85,
    "DEC": 5.66
  },
  {
    "LONG": -35.11,
    "LAT": -7.41,
    "CIDADE": "Itambé",
    "UF": "PERNAMBUCO",
    "anual": 5.4,
    "JAN": 5.47,
    "FEB": 5.78,
    "MAR": 5.92,
    "APR": 5.49,
    "MAY": 4.87,
    "JUN": 4.51,
    "JUL": 4.61,
    "AUG": 5.25,
    "SEP": 5.63,
    "OCT": 5.73,
    "NOV": 5.88,
    "DEC": 5.71
  },
  {
    "LONG": -37.29,
    "LAT": -7.35,
    "CIDADE": "Brejinho",
    "UF": "PERNAMBUCO",
    "anual": 5.84,
    "JAN": 5.79,
    "FEB": 5.84,
    "MAR": 6.04,
    "APR": 5.87,
    "MAY": 5.29,
    "JUN": 4.94,
    "JUL": 5.28,
    "AUG": 5.94,
    "SEP": 6.44,
    "OCT": 6.41,
    "NOV": 6.37,
    "DEC": 5.89
  },
  {
    "LONG": -44.83,
    "LAT": -10.82,
    "CIDADE": "Sebastião Barros",
    "UF": "PIAUÍ",
    "anual": 5.86,
    "JAN": 5.53,
    "FEB": 5.55,
    "MAR": 5.57,
    "APR": 5.69,
    "MAY": 5.95,
    "JUN": 5.91,
    "JUL": 6.1,
    "AUG": 6.57,
    "SEP": 6.58,
    "OCT": 5.99,
    "NOV": 5.43,
    "DEC": 5.46
  },
  {
    "LONG": -45.19,
    "LAT": -10.64,
    "CIDADE": "Cristalândia do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.87,
    "JAN": 5.57,
    "FEB": 5.55,
    "MAR": 5.57,
    "APR": 5.68,
    "MAY": 5.85,
    "JUN": 5.9,
    "JUL": 6.1,
    "AUG": 6.67,
    "SEP": 6.51,
    "OCT": 5.95,
    "NOV": 5.49,
    "DEC": 5.58
  },
  {
    "LONG": -45.16,
    "LAT": -10.43,
    "CIDADE": "Corrente",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.47,
    "FEB": 5.51,
    "MAR": 5.48,
    "APR": 5.64,
    "MAY": 5.87,
    "JUN": 5.89,
    "JUL": 6.1,
    "AUG": 6.61,
    "SEP": 6.46,
    "OCT": 5.98,
    "NOV": 5.54,
    "DEC": 5.53
  },
  {
    "LONG": -44.24,
    "LAT": -10.33,
    "CIDADE": "Júlio Borges",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.6,
    "FEB": 5.64,
    "MAR": 5.7,
    "APR": 5.71,
    "MAY": 5.81,
    "JUN": 5.84,
    "JUL": 6.11,
    "AUG": 6.66,
    "SEP": 6.72,
    "OCT": 6.06,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -44.64,
    "LAT": -1.02,
    "CIDADE": "Parnaguá",
    "UF": "PIAUÍ",
    "anual": 5.9,
    "JAN": 5.62,
    "FEB": 5.61,
    "MAR": 5.64,
    "APR": 5.75,
    "MAY": 5.94,
    "JUN": 5.85,
    "JUL": 6.09,
    "AUG": 6.58,
    "SEP": 6.56,
    "OCT": 6.08,
    "NOV": 5.57,
    "DEC": 5.57
  },
  {
    "LONG": -44.95,
    "LAT": -10.13,
    "CIDADE": "Riacho Frio",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.53,
    "FEB": 5.58,
    "MAR": 5.55,
    "APR": 5.69,
    "MAY": 5.9,
    "JUN": 5.86,
    "JUL": 6,
    "AUG": 6.53,
    "SEP": 6.52,
    "OCT": 6.05,
    "NOV": 5.53,
    "DEC": 5.48
  },
  {
    "LONG": -43.95,
    "LAT": -10.13,
    "CIDADE": "Avelino Lopes",
    "UF": "PIAUÍ",
    "anual": 5.93,
    "JAN": 5.7,
    "FEB": 5.6,
    "MAR": 5.7,
    "APR": 5.78,
    "MAY": 5.83,
    "JUN": 5.8,
    "JUL": 6.05,
    "AUG": 6.61,
    "SEP": 6.59,
    "OCT": 6.17,
    "NOV": 5.68,
    "DEC": 5.62
  },
  {
    "LONG": -45.3,
    "LAT": -10.03,
    "CIDADE": "São Gonçalo do Gurguéia",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.49,
    "FEB": 5.5,
    "MAR": 5.44,
    "APR": 5.53,
    "MAY": 5.88,
    "JUN": 5.86,
    "JUL": 5.98,
    "AUG": 6.48,
    "SEP": 6.53,
    "OCT": 6,
    "NOV": 5.42,
    "DEC": 5.44
  },
  {
    "LONG": -44.3,
    "LAT": -1,
    "CIDADE": "Curimatá",
    "UF": "PIAUÍ",
    "anual": 5.92,
    "JAN": 5.65,
    "FEB": 5.62,
    "MAR": 5.71,
    "APR": 5.79,
    "MAY": 5.87,
    "JUN": 5.81,
    "JUL": 6.05,
    "AUG": 6.56,
    "SEP": 6.58,
    "OCT": 6.11,
    "NOV": 5.64,
    "DEC": 5.59
  },
  {
    "LONG": -45.47,
    "LAT": -9.92,
    "CIDADE": "Barreiras do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5.49,
    "FEB": 5.55,
    "MAR": 5.5,
    "APR": 5.57,
    "MAY": 5.87,
    "JUN": 5.7,
    "JUL": 5.7,
    "AUG": 6.22,
    "SEP": 6.38,
    "OCT": 5.99,
    "NOV": 5.39,
    "DEC": 5.44
  },
  {
    "LONG": -45.3,
    "LAT": -9.75,
    "CIDADE": "Monte Alegre do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.47,
    "APR": 5.62,
    "MAY": 5.92,
    "JUN": 5.73,
    "JUL": 5.81,
    "AUG": 6.41,
    "SEP": 6.48,
    "OCT": 5.99,
    "NOV": 5.41,
    "DEC": 5.41
  },
  {
    "LONG": -4.53,
    "LAT": -9.83,
    "CIDADE": "Gilbués",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.47,
    "APR": 5.62,
    "MAY": 5.92,
    "JUN": 5.73,
    "JUL": 5.81,
    "AUG": 6.41,
    "SEP": 6.48,
    "OCT": 5.99,
    "NOV": 5.41,
    "DEC": 5.41
  },
  {
    "LONG": -43.91,
    "LAT": -9.73,
    "CIDADE": "Morro Cabeça no Tempo",
    "UF": "PIAUÍ",
    "anual": 5.94,
    "JAN": 5.57,
    "FEB": 5.47,
    "MAR": 5.6,
    "APR": 5.66,
    "MAY": 5.9,
    "JUN": 5.97,
    "JUL": 6.29,
    "AUG": 6.81,
    "SEP": 6.8,
    "OCT": 6.15,
    "NOV": 5.58,
    "DEC": 5.47
  },
  {
    "LONG": -44.58,
    "LAT": -9.49,
    "CIDADE": "Redenção do Gurguéia",
    "UF": "PIAUÍ",
    "anual": 5.8,
    "JAN": 5.46,
    "FEB": 5.39,
    "MAR": 5.48,
    "APR": 5.62,
    "MAY": 5.83,
    "JUN": 5.82,
    "JUL": 6.08,
    "AUG": 6.56,
    "SEP": 6.57,
    "OCT": 6.01,
    "NOV": 5.46,
    "DEC": 5.37
  },
  {
    "LONG": -42.79,
    "LAT": -9.48,
    "CIDADE": "Fartura do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.95,
    "JAN": 5.69,
    "FEB": 5.63,
    "MAR": 5.7,
    "APR": 5.63,
    "MAY": 5.64,
    "JUN": 5.72,
    "JUL": 6.12,
    "AUG": 6.72,
    "SEP": 6.71,
    "OCT": 6.31,
    "NOV": 5.86,
    "DEC": 5.67
  },
  {
    "LONG": -43.69,
    "LAT": -9.39,
    "CIDADE": "Guaribas",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.51,
    "FEB": 5.39,
    "MAR": 5.46,
    "APR": 5.56,
    "MAY": 5.82,
    "JUN": 5.89,
    "JUL": 6.2,
    "AUG": 6.82,
    "SEP": 6.77,
    "OCT": 6.16,
    "NOV": 5.6,
    "DEC": 5.43
  },
  {
    "LONG": -43.33,
    "LAT": -9.28,
    "CIDADE": "Caracol",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.62,
    "FEB": 5.47,
    "MAR": 5.61,
    "APR": 5.55,
    "MAY": 5.67,
    "JUN": 5.77,
    "JUL": 6.15,
    "AUG": 6.81,
    "SEP": 6.66,
    "OCT": 6.27,
    "NOV": 5.78,
    "DEC": 5.56
  },
  {
    "LONG": -42.44,
    "LAT": -9.34,
    "CIDADE": "Dirceu Arcoverde",
    "UF": "PIAUÍ",
    "anual": 5.95,
    "JAN": 5.71,
    "FEB": 5.62,
    "MAR": 5.66,
    "APR": 5.66,
    "MAY": 5.64,
    "JUN": 5.69,
    "JUL": 6.05,
    "AUG": 6.69,
    "SEP": 6.66,
    "OCT": 6.35,
    "NOV": 5.98,
    "DEC": 5.69
  },
  {
    "LONG": -43.13,
    "LAT": -9.23,
    "CIDADE": "Jurema",
    "UF": "PIAUÍ",
    "anual": 5.93,
    "JAN": 5.68,
    "FEB": 5.52,
    "MAR": 5.58,
    "APR": 5.58,
    "MAY": 5.65,
    "JUN": 5.74,
    "JUL": 6.14,
    "AUG": 6.73,
    "SEP": 6.77,
    "OCT": 6.33,
    "NOV": 5.81,
    "DEC": 5.58
  },
  {
    "LONG": -43.05,
    "LAT": -9.19,
    "CIDADE": "Anísio de Abreu",
    "UF": "PIAUÍ",
    "anual": 5.95,
    "JAN": 5.69,
    "FEB": 5.56,
    "MAR": 5.65,
    "APR": 5.65,
    "MAY": 5.63,
    "JUN": 5.74,
    "JUL": 6.12,
    "AUG": 6.74,
    "SEP": 6.76,
    "OCT": 6.32,
    "NOV": 5.88,
    "DEC": 5.66
  },
  {
    "LONG": -42.96,
    "LAT": -9.24,
    "CIDADE": "Várzea Branca",
    "UF": "PIAUÍ",
    "anual": 5.96,
    "JAN": 5.67,
    "FEB": 5.55,
    "MAR": 5.65,
    "APR": 5.69,
    "MAY": 5.64,
    "JUN": 5.73,
    "JUL": 6.11,
    "AUG": 6.72,
    "SEP": 6.74,
    "OCT": 6.34,
    "NOV": 5.92,
    "DEC": 5.69
  },
  {
    "LONG": -42.88,
    "LAT": -9.16,
    "CIDADE": "Bonfim do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.94,
    "JAN": 5.62,
    "FEB": 5.55,
    "MAR": 5.65,
    "APR": 5.66,
    "MAY": 5.65,
    "JUN": 5.71,
    "JUL": 6.1,
    "AUG": 6.73,
    "SEP": 6.69,
    "OCT": 6.34,
    "NOV": 5.91,
    "DEC": 5.69
  },
  {
    "LONG": -42.55,
    "LAT": -9.17,
    "CIDADE": "São Lourenço do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.95,
    "JAN": 5.66,
    "FEB": 5.59,
    "MAR": 5.71,
    "APR": 5.64,
    "MAY": 5.6,
    "JUN": 5.68,
    "JUL": 6.02,
    "AUG": 6.71,
    "SEP": 6.72,
    "OCT": 6.37,
    "NOV": 5.97,
    "DEC": 5.7
  },
  {
    "LONG": -45.92,
    "LAT": -9.12,
    "CIDADE": "Santa Filomena",
    "UF": "PIAUÍ",
    "anual": 5.57,
    "JAN": 5.05,
    "FEB": 5.09,
    "MAR": 5.12,
    "APR": 5.37,
    "MAY": 5.67,
    "JUN": 5.78,
    "JUL": 5.9,
    "AUG": 6.5,
    "SEP": 6.49,
    "OCT": 5.68,
    "NOV": 5.12,
    "DEC": 5.07
  },
  {
    "LONG": -44.36,
    "LAT": -9.07,
    "CIDADE": "Bom Jesus",
    "UF": "PIAUÍ",
    "anual": 5.77,
    "JAN": 5.37,
    "FEB": 5.33,
    "MAR": 5.49,
    "APR": 5.64,
    "MAY": 5.75,
    "JUN": 5.74,
    "JUL": 6.01,
    "AUG": 6.51,
    "SEP": 6.6,
    "OCT": 6,
    "NOV": 5.48,
    "DEC": 5.33
  },
  {
    "LONG": -43,
    "LAT": -9.06,
    "CIDADE": "São Braz do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.94,
    "JAN": 5.61,
    "FEB": 5.51,
    "MAR": 5.58,
    "APR": 5.64,
    "MAY": 5.67,
    "JUN": 5.78,
    "JUL": 6.14,
    "AUG": 6.75,
    "SEP": 6.82,
    "OCT": 6.34,
    "NOV": 5.82,
    "DEC": 5.58
  },
  {
    "LONG": -44.4,
    "LAT": -9.01,
    "CIDADE": "Currais",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.32,
    "FEB": 5.33,
    "MAR": 5.39,
    "APR": 5.61,
    "MAY": 5.76,
    "JUN": 5.79,
    "JUL": 6.11,
    "AUG": 6.68,
    "SEP": 6.73,
    "OCT": 6.01,
    "NOV": 5.45,
    "DEC": 5.31
  },
  {
    "LONG": -4.27,
    "LAT": -9.01,
    "CIDADE": "São Raimundo Nonato",
    "UF": "PIAUÍ",
    "anual": 5.94,
    "JAN": 5.63,
    "FEB": 5.54,
    "MAR": 5.65,
    "APR": 5.61,
    "MAY": 5.62,
    "JUN": 5.69,
    "JUL": 6.05,
    "AUG": 6.7,
    "SEP": 6.77,
    "OCT": 6.39,
    "NOV": 5.92,
    "DEC": 5.69
  },
  {
    "LONG": -41.97,
    "LAT": -9.01,
    "CIDADE": "Dom Inocêncio",
    "UF": "PIAUÍ",
    "anual": 5.99,
    "JAN": 5.79,
    "FEB": 5.76,
    "MAR": 5.8,
    "APR": 5.59,
    "MAY": 5.61,
    "JUN": 5.67,
    "JUL": 6.06,
    "AUG": 6.69,
    "SEP": 6.62,
    "OCT": 6.46,
    "NOV": 6.02,
    "DEC": 5.77
  },
  {
    "LONG": -44.13,
    "LAT": -8.95,
    "CIDADE": "Santa Luz",
    "UF": "PIAUÍ",
    "anual": 5.74,
    "JAN": 5.28,
    "FEB": 5.28,
    "MAR": 5.34,
    "APR": 5.6,
    "MAY": 5.73,
    "JUN": 5.73,
    "JUL": 6.02,
    "AUG": 6.57,
    "SEP": 6.65,
    "OCT": 5.96,
    "NOV": 5.42,
    "DEC": 5.27
  },
  {
    "LONG": -44.22,
    "LAT": -8.82,
    "CIDADE": "Cristino Castro",
    "UF": "PIAUÍ",
    "anual": 5.78,
    "JAN": 5.32,
    "FEB": 5.26,
    "MAR": 5.37,
    "APR": 5.6,
    "MAY": 5.76,
    "JUN": 5.74,
    "JUL": 6.11,
    "AUG": 6.7,
    "SEP": 6.75,
    "OCT": 6.01,
    "NOV": 5.48,
    "DEC": 5.3
  },
  {
    "LONG": -4.25,
    "LAT": -8.83,
    "CIDADE": "Coronel José Dias",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.55,
    "FEB": 5.53,
    "MAR": 5.57,
    "APR": 5.54,
    "MAY": 5.58,
    "JUN": 5.66,
    "JUL": 6.1,
    "AUG": 6.76,
    "SEP": 6.74,
    "OCT": 6.4,
    "NOV": 5.89,
    "DEC": 5.59
  },
  {
    "LONG": -44.25,
    "LAT": -0.87,
    "CIDADE": "Palmeira do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.33,
    "FEB": 5.23,
    "MAR": 5.38,
    "APR": 5.67,
    "MAY": 5.76,
    "JUN": 5.83,
    "JUL": 6.09,
    "AUG": 6.71,
    "SEP": 6.76,
    "OCT": 6.02,
    "NOV": 5.45,
    "DEC": 5.27
  },
  {
    "LONG": -41.41,
    "LAT": -8.57,
    "CIDADE": "Queimada Nova",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.73,
    "FEB": 5.66,
    "MAR": 5.73,
    "APR": 5.52,
    "MAY": 5.45,
    "JUN": 5.48,
    "JUL": 5.81,
    "AUG": 6.52,
    "SEP": 6.55,
    "OCT": 6.44,
    "NOV": 6.15,
    "DEC": 5.8
  },
  {
    "LONG": -42.42,
    "LAT": -8.51,
    "CIDADE": "João Costa",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.49,
    "FEB": 5.57,
    "MAR": 5.56,
    "APR": 5.58,
    "MAY": 5.64,
    "JUN": 5.71,
    "JUL": 6.02,
    "AUG": 6.75,
    "SEP": 6.7,
    "OCT": 6.39,
    "NOV": 5.9,
    "DEC": 5.61
  },
  {
    "LONG": -41.82,
    "LAT": -8.5,
    "CIDADE": "Capitão Gervásio Oliveira",
    "UF": "PIAUÍ",
    "anual": 5.89,
    "JAN": 5.58,
    "FEB": 5.57,
    "MAR": 5.64,
    "APR": 5.5,
    "MAY": 5.49,
    "JUN": 5.53,
    "JUL": 5.95,
    "AUG": 6.66,
    "SEP": 6.65,
    "OCT": 6.41,
    "NOV": 5.98,
    "DEC": 5.7
  },
  {
    "LONG": -41.54,
    "LAT": -8.48,
    "CIDADE": "Lagoa do Barro do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.96,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.77,
    "APR": 5.6,
    "MAY": 5.58,
    "JUN": 5.58,
    "JUL": 5.95,
    "AUG": 6.63,
    "SEP": 6.58,
    "OCT": 6.49,
    "NOV": 6.14,
    "DEC": 5.79
  },
  {
    "LONG": -43.87,
    "LAT": -8.44,
    "CIDADE": "Alvorada do Gurguéia",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.25,
    "FEB": 5.16,
    "MAR": 5.31,
    "APR": 5.56,
    "MAY": 5.68,
    "JUN": 5.71,
    "JUL": 5.98,
    "AUG": 6.48,
    "SEP": 6.63,
    "OCT": 6.06,
    "NOV": 5.54,
    "DEC": 5.24
  },
  {
    "LONG": -42.91,
    "LAT": -8.4,
    "CIDADE": "Tamboril do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.82,
    "JAN": 5.3,
    "FEB": 5.43,
    "MAR": 5.44,
    "APR": 5.52,
    "MAY": 5.59,
    "JUN": 5.62,
    "JUL": 6,
    "AUG": 6.76,
    "SEP": 6.68,
    "OCT": 6.26,
    "NOV": 5.72,
    "DEC": 5.47
  },
  {
    "LONG": -42.26,
    "LAT": -8.36,
    "CIDADE": "São João do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.89,
    "JAN": 5.42,
    "FEB": 5.52,
    "MAR": 5.65,
    "APR": 5.63,
    "MAY": 5.59,
    "JUN": 5.68,
    "JUL": 5.98,
    "AUG": 6.65,
    "SEP": 6.64,
    "OCT": 6.35,
    "NOV": 5.94,
    "DEC": 5.63
  },
  {
    "LONG": -41.84,
    "LAT": -8.38,
    "CIDADE": "Campo Alegre do Fidalgo",
    "UF": "PIAUÍ",
    "anual": 5.91,
    "JAN": 5.5,
    "FEB": 5.58,
    "MAR": 5.65,
    "APR": 5.53,
    "MAY": 5.61,
    "JUN": 5.63,
    "JUL": 5.99,
    "AUG": 6.66,
    "SEP": 6.64,
    "OCT": 6.46,
    "NOV": 6,
    "DEC": 5.67
  },
  {
    "LONG": -43.79,
    "LAT": -8.18,
    "CIDADE": "Colônia do Gurguéia",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 5.14,
    "FEB": 5.21,
    "MAR": 5.27,
    "APR": 5.43,
    "MAY": 5.65,
    "JUN": 5.73,
    "JUL": 5.96,
    "AUG": 6.47,
    "SEP": 6.56,
    "OCT": 6.07,
    "NOV": 5.53,
    "DEC": 5.26
  },
  {
    "LONG": -42.84,
    "LAT": -8.21,
    "CIDADE": "Brejo do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.34,
    "FEB": 5.46,
    "MAR": 5.52,
    "APR": 5.55,
    "MAY": 5.64,
    "JUN": 5.67,
    "JUL": 5.94,
    "AUG": 6.66,
    "SEP": 6.58,
    "OCT": 6.31,
    "NOV": 5.83,
    "DEC": 5.57
  },
  {
    "LONG": -41.69,
    "LAT": -8.24,
    "CIDADE": "São Francisco de Assis do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.92,
    "JAN": 5.46,
    "FEB": 5.52,
    "MAR": 5.67,
    "APR": 5.54,
    "MAY": 5.56,
    "JUN": 5.63,
    "JUL": 5.97,
    "AUG": 6.63,
    "SEP": 6.74,
    "OCT": 6.5,
    "NOV": 6.09,
    "DEC": 5.7
  },
  {
    "LONG": -41.08,
    "LAT": -8.22,
    "CIDADE": "Acauã",
    "UF": "PIAUÍ",
    "anual": 5.89,
    "JAN": 5.7,
    "FEB": 5.6,
    "MAR": 5.77,
    "APR": 5.57,
    "MAY": 5.39,
    "JUN": 5.38,
    "JUL": 5.73,
    "AUG": 6.45,
    "SEP": 6.67,
    "OCT": 6.48,
    "NOV": 6.16,
    "DEC": 5.82
  },
  {
    "LONG": -43.66,
    "LAT": -8.1,
    "CIDADE": "Eliseu Martins",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 5.16,
    "FEB": 5.23,
    "MAR": 5.25,
    "APR": 5.46,
    "MAY": 5.6,
    "JUN": 5.67,
    "JUL": 6.03,
    "AUG": 6.42,
    "SEP": 6.58,
    "OCT": 6.15,
    "NOV": 5.52,
    "DEC": 5.28
  },
  {
    "LONG": -42.95,
    "LAT": -8.11,
    "CIDADE": "Canto do Buriti",
    "UF": "PIAUÍ",
    "anual": 5.81,
    "JAN": 5.27,
    "FEB": 5.41,
    "MAR": 5.48,
    "APR": 5.57,
    "MAY": 5.63,
    "JUN": 5.7,
    "JUL": 5.93,
    "AUG": 6.6,
    "SEP": 6.58,
    "OCT": 6.28,
    "NOV": 5.76,
    "DEC": 5.51
  },
  {
    "LONG": -42.29,
    "LAT": -8.07,
    "CIDADE": "Pedro Laurentino",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.35,
    "FEB": 5.44,
    "MAR": 5.56,
    "APR": 5.51,
    "MAY": 5.57,
    "JUN": 5.58,
    "JUL": 5.92,
    "AUG": 6.63,
    "SEP": 6.63,
    "OCT": 6.34,
    "NOV": 5.92,
    "DEC": 5.51
  },
  {
    "LONG": -42.05,
    "LAT": -8.1,
    "CIDADE": "Nova Santa Rita",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.37,
    "FEB": 5.42,
    "MAR": 5.57,
    "APR": 5.53,
    "MAY": 5.59,
    "JUN": 5.66,
    "JUL": 5.94,
    "AUG": 6.6,
    "SEP": 6.66,
    "OCT": 6.36,
    "NOV": 5.95,
    "DEC": 5.58
  },
  {
    "LONG": -41.14,
    "LAT": -8.13,
    "CIDADE": "Paulistana",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.59,
    "FEB": 5.51,
    "MAR": 5.74,
    "APR": 5.57,
    "MAY": 5.45,
    "JUN": 5.42,
    "JUL": 5.8,
    "AUG": 6.51,
    "SEP": 6.63,
    "OCT": 6.48,
    "NOV": 6.14,
    "DEC": 5.76
  },
  {
    "LONG": -40.8,
    "LAT": -8.14,
    "CIDADE": "Betânia do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.64,
    "FEB": 5.54,
    "MAR": 5.81,
    "APR": 5.5,
    "MAY": 5.34,
    "JUN": 5.29,
    "JUL": 5.68,
    "AUG": 6.46,
    "SEP": 6.72,
    "OCT": 6.52,
    "NOV": 6.21,
    "DEC": 5.83
  },
  {
    "LONG": -43.87,
    "LAT": -8,
    "CIDADE": "Manoel Emídio",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.13,
    "FEB": 5.17,
    "MAR": 5.23,
    "APR": 5.51,
    "MAY": 5.67,
    "JUN": 5.78,
    "JUL": 6.03,
    "AUG": 6.55,
    "SEP": 6.63,
    "OCT": 6.12,
    "NOV": 5.57,
    "DEC": 5.24
  },
  {
    "LONG": -43.23,
    "LAT": -0.8,
    "CIDADE": "Pavussu",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.21,
    "FEB": 5.3,
    "MAR": 5.28,
    "APR": 5.41,
    "MAY": 5.71,
    "JUN": 5.7,
    "JUL": 6.05,
    "AUG": 6.58,
    "SEP": 6.67,
    "OCT": 6.23,
    "NOV": 5.63,
    "DEC": 5.39
  },
  {
    "LONG": -41.87,
    "LAT": -7.99,
    "CIDADE": "Bela Vista do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.45,
    "FEB": 5.48,
    "MAR": 5.62,
    "APR": 5.49,
    "MAY": 5.58,
    "JUN": 5.63,
    "JUL": 5.94,
    "AUG": 6.56,
    "SEP": 6.66,
    "OCT": 6.42,
    "NOV": 6.02,
    "DEC": 5.65
  },
  {
    "LONG": -45.21,
    "LAT": -7.86,
    "CIDADE": "Baixa Grande do Ribeiro",
    "UF": "PIAUÍ",
    "anual": 5.49,
    "JAN": 4.78,
    "FEB": 4.95,
    "MAR": 4.92,
    "APR": 5.35,
    "MAY": 5.63,
    "JUN": 5.74,
    "JUL": 5.91,
    "AUG": 6.3,
    "SEP": 6.55,
    "OCT": 5.79,
    "NOV": 5.09,
    "DEC": 4.87
  },
  {
    "LONG": -42.82,
    "LAT": -7.86,
    "CIDADE": "Pajeú do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.81,
    "JAN": 5.22,
    "FEB": 5.25,
    "MAR": 5.41,
    "APR": 5.49,
    "MAY": 5.59,
    "JUN": 5.76,
    "JUL": 6,
    "AUG": 6.65,
    "SEP": 6.76,
    "OCT": 6.33,
    "NOV": 5.77,
    "DEC": 5.44
  },
  {
    "LONG": -42.5,
    "LAT": -7.86,
    "CIDADE": "Socorro do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.33,
    "FEB": 5.37,
    "MAR": 5.49,
    "APR": 5.48,
    "MAY": 5.6,
    "JUN": 5.69,
    "JUL": 6,
    "AUG": 6.62,
    "SEP": 6.69,
    "OCT": 6.36,
    "NOV": 5.82,
    "DEC": 5.49
  },
  {
    "LONG": -41.91,
    "LAT": -7.86,
    "CIDADE": "Simplício Mendes",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.49,
    "FEB": 5.42,
    "MAR": 5.6,
    "APR": 5.53,
    "MAY": 5.57,
    "JUN": 5.66,
    "JUL": 5.95,
    "AUG": 6.61,
    "SEP": 6.72,
    "OCT": 6.38,
    "NOV": 6.02,
    "DEC": 5.64
  },
  {
    "LONG": -41.59,
    "LAT": -7.88,
    "CIDADE": "Conceição do Canindé",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.48,
    "FEB": 5.47,
    "MAR": 5.65,
    "APR": 5.57,
    "MAY": 5.54,
    "JUN": 5.64,
    "JUL": 5.9,
    "AUG": 6.53,
    "SEP": 6.72,
    "OCT": 6.39,
    "NOV": 6.04,
    "DEC": 5.65
  },
  {
    "LONG": -41.21,
    "LAT": -7.93,
    "CIDADE": "Jacobina do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.89,
    "JAN": 5.57,
    "FEB": 5.51,
    "MAR": 5.67,
    "APR": 5.52,
    "MAY": 5.45,
    "JUN": 5.53,
    "JUL": 5.87,
    "AUG": 6.6,
    "SEP": 6.72,
    "OCT": 6.44,
    "NOV": 6.11,
    "DEC": 5.74
  },
  {
    "LONG": -43.14,
    "LAT": -7.77,
    "CIDADE": "Rio Grande do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5.1,
    "FEB": 5.29,
    "MAR": 5.34,
    "APR": 5.42,
    "MAY": 5.58,
    "JUN": 5.64,
    "JUL": 5.99,
    "AUG": 6.46,
    "SEP": 6.63,
    "OCT": 6.24,
    "NOV": 5.67,
    "DEC": 5.39
  },
  {
    "LONG": -42.92,
    "LAT": -7.79,
    "CIDADE": "Flores do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.77,
    "JAN": 5.17,
    "FEB": 5.22,
    "MAR": 5.3,
    "APR": 5.47,
    "MAY": 5.6,
    "JUN": 5.72,
    "JUL": 5.98,
    "AUG": 6.55,
    "SEP": 6.78,
    "OCT": 6.27,
    "NOV": 5.7,
    "DEC": 5.44
  },
  {
    "LONG": -42.25,
    "LAT": -7.78,
    "CIDADE": "Paes Landim",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.35,
    "FEB": 5.39,
    "MAR": 5.6,
    "APR": 5.52,
    "MAY": 5.62,
    "JUN": 5.66,
    "JUL": 5.95,
    "AUG": 6.57,
    "SEP": 6.64,
    "OCT": 6.35,
    "NOV": 5.9,
    "DEC": 5.55
  },
  {
    "LONG": -40.9,
    "LAT": -7.83,
    "CIDADE": "Curral Novo do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.9,
    "JAN": 5.51,
    "FEB": 5.46,
    "MAR": 5.74,
    "APR": 5.53,
    "MAY": 5.45,
    "JUN": 5.49,
    "JUL": 5.9,
    "AUG": 6.56,
    "SEP": 6.73,
    "OCT": 6.46,
    "NOV": 6.18,
    "DEC": 5.77
  },
  {
    "LONG": -43.72,
    "LAT": -7.69,
    "CIDADE": "Canavieira",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 5.08,
    "FEB": 5.13,
    "MAR": 5.23,
    "APR": 5.36,
    "MAY": 5.65,
    "JUN": 5.76,
    "JUL": 6.04,
    "AUG": 6.44,
    "SEP": 6.58,
    "OCT": 6.15,
    "NOV": 5.6,
    "DEC": 5.25
  },
  {
    "LONG": -42.71,
    "LAT": -7.69,
    "CIDADE": "Ribeira do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.8,
    "JAN": 5.23,
    "FEB": 5.29,
    "MAR": 5.41,
    "APR": 5.43,
    "MAY": 5.64,
    "JUN": 5.72,
    "JUL": 5.95,
    "AUG": 6.6,
    "SEP": 6.75,
    "OCT": 6.33,
    "NOV": 5.77,
    "DEC": 5.47
  },
  {
    "LONG": -41.88,
    "LAT": -7.66,
    "CIDADE": "Campinas do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.86,
    "JAN": 5.48,
    "FEB": 5.46,
    "MAR": 5.65,
    "APR": 5.52,
    "MAY": 5.55,
    "JUN": 5.63,
    "JUL": 5.87,
    "AUG": 6.45,
    "SEP": 6.61,
    "OCT": 6.4,
    "NOV": 6.08,
    "DEC": 5.67
  },
  {
    "LONG": -41.68,
    "LAT": -7.74,
    "CIDADE": "Isaías Coelho",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.43,
    "FEB": 5.45,
    "MAR": 5.59,
    "APR": 5.49,
    "MAY": 5.55,
    "JUN": 5.6,
    "JUL": 5.92,
    "AUG": 6.56,
    "SEP": 6.76,
    "OCT": 6.43,
    "NOV": 6.08,
    "DEC": 5.67
  },
  {
    "LONG": -41.24,
    "LAT": -7.68,
    "CIDADE": "Patos do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.87,
    "JAN": 5.5,
    "FEB": 5.5,
    "MAR": 5.7,
    "APR": 5.51,
    "MAY": 5.44,
    "JUN": 5.51,
    "JUL": 5.88,
    "AUG": 6.48,
    "SEP": 6.69,
    "OCT": 6.37,
    "NOV": 6.14,
    "DEC": 5.76
  },
  {
    "LONG": -40.99,
    "LAT": -7.73,
    "CIDADE": "Caridade do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.42,
    "FEB": 5.41,
    "MAR": 5.66,
    "APR": 5.5,
    "MAY": 5.45,
    "JUN": 5.49,
    "JUL": 5.91,
    "AUG": 6.59,
    "SEP": 6.75,
    "OCT": 6.45,
    "NOV": 6.15,
    "DEC": 5.76
  },
  {
    "LONG": -45.25,
    "LAT": -7.56,
    "CIDADE": "Ribeiro Gonçalves",
    "UF": "PIAUÍ",
    "anual": 5.52,
    "JAN": 4.74,
    "FEB": 5.06,
    "MAR": 5,
    "APR": 5.35,
    "MAY": 5.59,
    "JUN": 5.77,
    "JUL": 5.89,
    "AUG": 6.33,
    "SEP": 6.6,
    "OCT": 5.81,
    "NOV": 5.1,
    "DEC": 4.94
  },
  {
    "LONG": -44.06,
    "LAT": -7.57,
    "CIDADE": "Sebastião Leal",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 4.99,
    "FEB": 5.07,
    "MAR": 5.11,
    "APR": 5.39,
    "MAY": 5.65,
    "JUN": 5.8,
    "JUL": 5.92,
    "AUG": 6.4,
    "SEP": 6.63,
    "OCT": 6.13,
    "NOV": 5.46,
    "DEC": 5.19
  },
  {
    "LONG": -43.95,
    "LAT": -7.63,
    "CIDADE": "Bertolínia",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 5,
    "FEB": 5.08,
    "MAR": 5.12,
    "APR": 5.33,
    "MAY": 5.61,
    "JUN": 5.77,
    "JUL": 5.97,
    "AUG": 6.49,
    "SEP": 6.62,
    "OCT": 6.12,
    "NOV": 5.5,
    "DEC": 5.2
  },
  {
    "LONG": -43.03,
    "LAT": -7.6,
    "CIDADE": "Itaueira",
    "UF": "PIAUÍ",
    "anual": 5.74,
    "JAN": 5.15,
    "FEB": 5.22,
    "MAR": 5.33,
    "APR": 5.45,
    "MAY": 5.59,
    "JUN": 5.67,
    "JUL": 5.98,
    "AUG": 6.42,
    "SEP": 6.64,
    "OCT": 6.24,
    "NOV": 5.73,
    "DEC": 5.47
  },
  {
    "LONG": -42.37,
    "LAT": -7.6,
    "CIDADE": "São Miguel do Fidalgo",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.38,
    "FEB": 5.37,
    "MAR": 5.51,
    "APR": 5.6,
    "MAY": 5.6,
    "JUN": 5.65,
    "JUL": 5.9,
    "AUG": 6.53,
    "SEP": 6.64,
    "OCT": 6.36,
    "NOV": 5.86,
    "DEC": 5.53
  },
  {
    "LONG": -41.47,
    "LAT": -7.6,
    "CIDADE": "Vera Mendes",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.43,
    "FEB": 5.39,
    "MAR": 5.56,
    "APR": 5.48,
    "MAY": 5.52,
    "JUN": 5.64,
    "JUL": 5.96,
    "AUG": 6.56,
    "SEP": 6.79,
    "OCT": 6.43,
    "NOV": 6.12,
    "DEC": 5.67
  },
  {
    "LONG": -40.82,
    "LAT": -0.76,
    "CIDADE": "Simões",
    "UF": "PIAUÍ",
    "anual": 5.88,
    "JAN": 5.46,
    "FEB": 5.37,
    "MAR": 5.6,
    "APR": 5.51,
    "MAY": 5.52,
    "JUN": 5.56,
    "JUL": 5.95,
    "AUG": 6.54,
    "SEP": 6.77,
    "OCT": 6.47,
    "NOV": 6.1,
    "DEC": 5.71
  },
  {
    "LONG": -42.57,
    "LAT": -0.75,
    "CIDADE": "São José do Peixe",
    "UF": "PIAUÍ",
    "anual": 5.78,
    "JAN": 5.23,
    "FEB": 5.31,
    "MAR": 5.44,
    "APR": 5.48,
    "MAY": 5.59,
    "JUN": 5.67,
    "JUL": 6,
    "AUG": 6.43,
    "SEP": 6.67,
    "OCT": 6.32,
    "NOV": 5.8,
    "DEC": 5.47
  },
  {
    "LONG": -41.8,
    "LAT": -7.47,
    "CIDADE": "Floresta do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.39,
    "FEB": 5.37,
    "MAR": 5.57,
    "APR": 5.47,
    "MAY": 5.55,
    "JUN": 5.63,
    "JUL": 5.91,
    "AUG": 6.41,
    "SEP": 6.67,
    "OCT": 6.35,
    "NOV": 6.01,
    "DEC": 5.58
  },
  {
    "LONG": -41.11,
    "LAT": -7.48,
    "CIDADE": "Massapê do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.4,
    "FEB": 5.47,
    "MAR": 5.67,
    "APR": 5.48,
    "MAY": 5.47,
    "JUN": 5.51,
    "JUL": 5.84,
    "AUG": 6.47,
    "SEP": 6.71,
    "OCT": 6.41,
    "NOV": 6.09,
    "DEC": 5.69
  },
  {
    "LONG": -41.91,
    "LAT": -0.74,
    "CIDADE": "Santo Inácio do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.33,
    "FEB": 5.33,
    "MAR": 5.5,
    "APR": 5.45,
    "MAY": 5.57,
    "JUN": 5.65,
    "JUL": 5.95,
    "AUG": 6.55,
    "SEP": 6.73,
    "OCT": 6.4,
    "NOV": 6,
    "DEC": 5.56
  },
  {
    "LONG": -41.48,
    "LAT": -7.45,
    "CIDADE": "Itainópolis",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.29,
    "FEB": 5.37,
    "MAR": 5.59,
    "APR": 5.49,
    "MAY": 5.55,
    "JUN": 5.58,
    "JUL": 5.94,
    "AUG": 6.47,
    "SEP": 6.75,
    "OCT": 6.38,
    "NOV": 6.05,
    "DEC": 5.59
  },
  {
    "LONG": -41.14,
    "LAT": -7.36,
    "CIDADE": "Jaicós",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.34,
    "FEB": 5.4,
    "MAR": 5.6,
    "APR": 5.48,
    "MAY": 5.49,
    "JUN": 5.55,
    "JUL": 5.85,
    "AUG": 6.47,
    "SEP": 6.74,
    "OCT": 6.38,
    "NOV": 6.04,
    "DEC": 5.64
  },
  {
    "LONG": -40.97,
    "LAT": -7.37,
    "CIDADE": "Belém do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.34,
    "FEB": 5.39,
    "MAR": 5.56,
    "APR": 5.44,
    "MAY": 5.47,
    "JUN": 5.51,
    "JUL": 5.86,
    "AUG": 6.46,
    "SEP": 6.73,
    "OCT": 6.36,
    "NOV": 6.11,
    "DEC": 5.7
  },
  {
    "LONG": -40.66,
    "LAT": -7.44,
    "CIDADE": "Marcolândia",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.28,
    "FEB": 5.35,
    "MAR": 5.45,
    "APR": 5.5,
    "MAY": 5.51,
    "JUN": 5.54,
    "JUL": 5.86,
    "AUG": 6.31,
    "SEP": 6.58,
    "OCT": 6.38,
    "NOV": 6.1,
    "DEC": 5.66
  },
  {
    "LONG": -43.93,
    "LAT": -7.26,
    "CIDADE": "Landri Sales",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 4.91,
    "FEB": 5.01,
    "MAR": 5.04,
    "APR": 5.28,
    "MAY": 5.6,
    "JUN": 5.78,
    "JUL": 6,
    "AUG": 6.41,
    "SEP": 6.68,
    "OCT": 6.12,
    "NOV": 5.51,
    "DEC": 5.14
  },
  {
    "LONG": -41.57,
    "LAT": -7.28,
    "CIDADE": "Aroeiras do Itaim",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.25,
    "FEB": 5.34,
    "MAR": 5.61,
    "APR": 5.48,
    "MAY": 5.54,
    "JUN": 5.6,
    "JUL": 5.94,
    "AUG": 6.46,
    "SEP": 6.74,
    "OCT": 6.37,
    "NOV": 6.03,
    "DEC": 5.62
  },
  {
    "LONG": -40.91,
    "LAT": -0.73,
    "CIDADE": "Padre Marcos",
    "UF": "PIAUÍ",
    "anual": 5.8,
    "JAN": 5.29,
    "FEB": 5.4,
    "MAR": 5.57,
    "APR": 5.42,
    "MAY": 5.47,
    "JUN": 5.45,
    "JUL": 5.81,
    "AUG": 6.39,
    "SEP": 6.69,
    "OCT": 6.32,
    "NOV": 6.08,
    "DEC": 5.7
  },
  {
    "LONG": -40.79,
    "LAT": -7.33,
    "CIDADE": "Francisco Macedo",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.25,
    "FEB": 5.35,
    "MAR": 5.5,
    "APR": 5.49,
    "MAY": 5.5,
    "JUN": 5.54,
    "JUL": 5.97,
    "AUG": 6.58,
    "SEP": 6.78,
    "OCT": 6.39,
    "NOV": 6.15,
    "DEC": 5.67
  },
  {
    "LONG": -40.64,
    "LAT": -7.33,
    "CIDADE": "Caldeirão Grande do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.86,
    "JAN": 5.19,
    "FEB": 5.35,
    "MAR": 5.45,
    "APR": 5.5,
    "MAY": 5.52,
    "JUN": 5.59,
    "JUL": 6.02,
    "AUG": 6.57,
    "SEP": 6.8,
    "OCT": 6.44,
    "NOV": 6.15,
    "DEC": 5.68
  },
  {
    "LONG": -4.46,
    "LAT": -7.24,
    "CIDADE": "Uruçuí",
    "UF": "PIAUÍ",
    "anual": 5.54,
    "JAN": 4.8,
    "FEB": 4.97,
    "MAR": 5.02,
    "APR": 5.26,
    "MAY": 5.57,
    "JUN": 5.76,
    "JUL": 5.96,
    "AUG": 6.27,
    "SEP": 6.62,
    "OCT": 5.92,
    "NOV": 5.34,
    "DEC": 5.04
  },
  {
    "LONG": -44.19,
    "LAT": -7.22,
    "CIDADE": "Antônio Almeida",
    "UF": "PIAUÍ",
    "anual": 5.57,
    "JAN": 4.85,
    "FEB": 4.93,
    "MAR": 4.97,
    "APR": 5.28,
    "MAY": 5.6,
    "JUN": 5.79,
    "JUL": 5.97,
    "AUG": 6.38,
    "SEP": 6.67,
    "OCT": 6.01,
    "NOV": 5.41,
    "DEC": 5.01
  },
  {
    "LONG": -42.54,
    "LAT": -7.25,
    "CIDADE": "São Francisco do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.74,
    "JAN": 5.15,
    "FEB": 5.21,
    "MAR": 5.32,
    "APR": 5.39,
    "MAY": 5.57,
    "JUN": 5.59,
    "JUL": 5.92,
    "AUG": 6.43,
    "SEP": 6.72,
    "OCT": 6.27,
    "NOV": 5.83,
    "DEC": 5.45
  },
  {
    "LONG": -42.18,
    "LAT": -7.23,
    "CIDADE": "Colônia do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.81,
    "JAN": 5.27,
    "FEB": 5.27,
    "MAR": 5.42,
    "APR": 5.45,
    "MAY": 5.65,
    "JUN": 5.63,
    "JUL": 5.96,
    "AUG": 6.45,
    "SEP": 6.77,
    "OCT": 6.34,
    "NOV": 6.03,
    "DEC": 5.55
  },
  {
    "LONG": -41.91,
    "LAT": -7.23,
    "CIDADE": "Wall Ferraz",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.35,
    "FEB": 5.33,
    "MAR": 5.54,
    "APR": 5.5,
    "MAY": 5.61,
    "JUN": 5.65,
    "JUL": 5.97,
    "AUG": 6.44,
    "SEP": 6.75,
    "OCT": 6.38,
    "NOV": 6.08,
    "DEC": 5.61
  },
  {
    "LONG": -41.76,
    "LAT": -7.18,
    "CIDADE": "Santa Cruz do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.85,
    "JAN": 5.31,
    "FEB": 5.32,
    "MAR": 5.61,
    "APR": 5.5,
    "MAY": 5.58,
    "JUN": 5.6,
    "JUL": 5.95,
    "AUG": 6.44,
    "SEP": 6.7,
    "OCT": 6.39,
    "NOV": 6.17,
    "DEC": 5.66
  },
  {
    "LONG": -41.36,
    "LAT": -7.16,
    "CIDADE": "Geminiano",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.25,
    "FEB": 5.27,
    "MAR": 5.49,
    "APR": 5.44,
    "MAY": 5.51,
    "JUN": 5.49,
    "JUL": 5.87,
    "AUG": 6.5,
    "SEP": 6.72,
    "OCT": 6.3,
    "NOV": 6.02,
    "DEC": 5.59
  },
  {
    "LONG": -40.86,
    "LAT": -7.24,
    "CIDADE": "Alegrete do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.35,
    "FEB": 5.35,
    "MAR": 5.61,
    "APR": 5.42,
    "MAY": 5.44,
    "JUN": 5.42,
    "JUL": 5.84,
    "AUG": 6.32,
    "SEP": 6.66,
    "OCT": 6.3,
    "NOV": 6.13,
    "DEC": 5.7
  },
  {
    "LONG": -4.39,
    "LAT": -7.12,
    "CIDADE": "Marcos Parente",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 4.89,
    "FEB": 4.97,
    "MAR": 5.07,
    "APR": 5.2,
    "MAY": 5.53,
    "JUN": 5.77,
    "JUL": 6.04,
    "AUG": 6.39,
    "SEP": 6.7,
    "OCT": 6.13,
    "NOV": 5.53,
    "DEC": 5.15
  },
  {
    "LONG": -43.5,
    "LAT": -7.09,
    "CIDADE": "Jerumenha",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.95,
    "FEB": 5.05,
    "MAR": 5.16,
    "APR": 5.3,
    "MAY": 5.53,
    "JUN": 5.77,
    "JUL": 5.96,
    "AUG": 6.44,
    "SEP": 6.66,
    "OCT": 6.21,
    "NOV": 5.67,
    "DEC": 5.29
  },
  {
    "LONG": -41.7,
    "LAT": -7.1,
    "CIDADE": "Paquetá",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.19,
    "FEB": 5.31,
    "MAR": 5.48,
    "APR": 5.53,
    "MAY": 5.53,
    "JUN": 5.57,
    "JUL": 5.99,
    "AUG": 6.54,
    "SEP": 6.78,
    "OCT": 6.41,
    "NOV": 6.08,
    "DEC": 5.56
  },
  {
    "LONG": -41.47,
    "LAT": -7.08,
    "CIDADE": "Picos",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.35,
    "FEB": 5.37,
    "MAR": 5.62,
    "APR": 5.5,
    "MAY": 5.44,
    "JUN": 5.44,
    "JUL": 5.83,
    "AUG": 6.52,
    "SEP": 6.73,
    "OCT": 6.31,
    "NOV": 6.1,
    "DEC": 5.7
  },
  {
    "LONG": -41.03,
    "LAT": -7.13,
    "CIDADE": "Campo Grande do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.33,
    "FEB": 5.33,
    "MAR": 5.61,
    "APR": 5.44,
    "MAY": 5.38,
    "JUN": 5.4,
    "JUL": 5.84,
    "AUG": 6.41,
    "SEP": 6.66,
    "OCT": 6.31,
    "NOV": 6.1,
    "DEC": 5.67
  },
  {
    "LONG": -40.94,
    "LAT": -7.14,
    "CIDADE": "Vila Nova do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.78,
    "JAN": 5.31,
    "FEB": 5.31,
    "MAR": 5.61,
    "APR": 5.44,
    "MAY": 5.33,
    "JUN": 5.38,
    "JUL": 5.83,
    "AUG": 6.43,
    "SEP": 6.71,
    "OCT": 6.3,
    "NOV": 6.1,
    "DEC": 5.68
  },
  {
    "LONG": -40.84,
    "LAT": -7.08,
    "CIDADE": "São Julião",
    "UF": "PIAUÍ",
    "anual": 5.82,
    "JAN": 5.33,
    "FEB": 5.38,
    "MAR": 5.58,
    "APR": 5.44,
    "MAY": 5.36,
    "JUN": 5.37,
    "JUL": 5.83,
    "AUG": 6.5,
    "SEP": 6.75,
    "OCT": 6.36,
    "NOV": 6.25,
    "DEC": 5.75
  },
  {
    "LONG": -40.61,
    "LAT": -7.09,
    "CIDADE": "Fronteiras",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.3,
    "FEB": 5.4,
    "MAR": 5.58,
    "APR": 5.43,
    "MAY": 5.42,
    "JUN": 5.45,
    "JUL": 5.81,
    "AUG": 6.51,
    "SEP": 6.74,
    "OCT": 6.39,
    "NOV": 6.19,
    "DEC": 5.71
  },
  {
    "LONG": -44.2,
    "LAT": -0.7,
    "CIDADE": "Porto Alegre do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.55,
    "JAN": 4.79,
    "FEB": 4.94,
    "MAR": 5.02,
    "APR": 5.26,
    "MAY": 5.51,
    "JUN": 5.75,
    "JUL": 5.94,
    "AUG": 6.29,
    "SEP": 6.6,
    "OCT": 6.02,
    "NOV": 5.47,
    "DEC": 5.06
  },
  {
    "LONG": -42.67,
    "LAT": -6.97,
    "CIDADE": "Nazaré do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5.07,
    "FEB": 5.23,
    "MAR": 5.31,
    "APR": 5.46,
    "MAY": 5.61,
    "JUN": 5.67,
    "JUL": 5.93,
    "AUG": 6.39,
    "SEP": 6.57,
    "OCT": 6.25,
    "NOV": 5.83,
    "DEC": 5.39
  },
  {
    "LONG": -42.13,
    "LAT": -7.02,
    "CIDADE": "Oeiras",
    "UF": "PIAUÍ",
    "anual": 5.81,
    "JAN": 5.21,
    "FEB": 5.26,
    "MAR": 5.4,
    "APR": 5.52,
    "MAY": 5.63,
    "JUN": 5.63,
    "JUL": 5.98,
    "AUG": 6.38,
    "SEP": 6.69,
    "OCT": 6.37,
    "NOV": 6.02,
    "DEC": 5.58
  },
  {
    "LONG": -41.65,
    "LAT": -6.96,
    "CIDADE": "Dom Expedito Lopes",
    "UF": "PIAUÍ",
    "anual": 5.83,
    "JAN": 5.22,
    "FEB": 5.22,
    "MAR": 5.45,
    "APR": 5.5,
    "MAY": 5.52,
    "JUN": 5.54,
    "JUL": 6,
    "AUG": 6.6,
    "SEP": 6.81,
    "OCT": 6.41,
    "NOV": 6.06,
    "DEC": 5.59
  },
  {
    "LONG": -41.41,
    "LAT": -7.03,
    "CIDADE": "Sussuapara",
    "UF": "PIAUÍ",
    "anual": 5.82,
    "JAN": 5.3,
    "FEB": 5.32,
    "MAR": 5.55,
    "APR": 5.48,
    "MAY": 5.4,
    "JUN": 5.43,
    "JUL": 5.85,
    "AUG": 6.6,
    "SEP": 6.79,
    "OCT": 6.36,
    "NOV": 6.08,
    "DEC": 5.66
  },
  {
    "LONG": -41.23,
    "LAT": -6.98,
    "CIDADE": "Santo Antônio de Lisboa",
    "UF": "PIAUÍ",
    "anual": 5.82,
    "JAN": 5.4,
    "FEB": 5.33,
    "MAR": 5.55,
    "APR": 5.43,
    "MAY": 5.39,
    "JUN": 5.42,
    "JUL": 5.84,
    "AUG": 6.47,
    "SEP": 6.7,
    "OCT": 6.4,
    "NOV": 6.19,
    "DEC": 5.71
  },
  {
    "LONG": -41.13,
    "LAT": -6.99,
    "CIDADE": "Francisco Santos",
    "UF": "PIAUÍ",
    "anual": 5.84,
    "JAN": 5.43,
    "FEB": 5.35,
    "MAR": 5.63,
    "APR": 5.46,
    "MAY": 5.45,
    "JUN": 5.4,
    "JUL": 5.89,
    "AUG": 6.46,
    "SEP": 6.73,
    "OCT": 6.39,
    "NOV": 6.21,
    "DEC": 5.74
  },
  {
    "LONG": "#VALOR!",
    "LAT": -7,
    "CIDADE": "Monsenhor Hipólito",
    "UF": "PIAUÍ",
    "anual": 5.82,
    "JAN": 5.35,
    "FEB": 5.34,
    "MAR": 5.67,
    "APR": 5.46,
    "MAY": 5.39,
    "JUN": 5.39,
    "JUL": 5.81,
    "AUG": 6.47,
    "SEP": 6.74,
    "OCT": 6.35,
    "NOV": 6.16,
    "DEC": 5.74
  },
  {
    "LONG": -4.09,
    "LAT": -7.01,
    "CIDADE": "Alagoinha do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.3,
    "FEB": 5.29,
    "MAR": 5.63,
    "APR": 5.44,
    "MAY": 5.34,
    "JUN": 5.38,
    "JUL": 5.8,
    "AUG": 6.47,
    "SEP": 6.7,
    "OCT": 6.33,
    "NOV": 6.13,
    "DEC": 5.7
  },
  {
    "LONG": -41.86,
    "LAT": -6.92,
    "CIDADE": "São João da Varjota",
    "UF": "PIAUÍ",
    "anual": 5.74,
    "JAN": 5.01,
    "FEB": 5.08,
    "MAR": 5.21,
    "APR": 5.38,
    "MAY": 5.56,
    "JUN": 5.59,
    "JUL": 5.98,
    "AUG": 6.5,
    "SEP": 6.79,
    "OCT": 6.34,
    "NOV": 5.95,
    "DEC": 5.46
  },
  {
    "LONG": -41.52,
    "LAT": -6.95,
    "CIDADE": "Santana do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.8,
    "JAN": 5.22,
    "FEB": 5.24,
    "MAR": 5.42,
    "APR": 5.5,
    "MAY": 5.48,
    "JUN": 5.49,
    "JUL": 5.94,
    "AUG": 6.57,
    "SEP": 6.78,
    "OCT": 6.37,
    "NOV": 6.05,
    "DEC": 5.6
  },
  {
    "LONG": -41.48,
    "LAT": -6.87,
    "CIDADE": "São José do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.8,
    "JAN": 5.26,
    "FEB": 5.23,
    "MAR": 5.45,
    "APR": 5.49,
    "MAY": 5.41,
    "JUN": 5.46,
    "JUL": 5.85,
    "AUG": 6.57,
    "SEP": 6.79,
    "OCT": 6.37,
    "NOV": 6.07,
    "DEC": 5.59
  },
  {
    "LONG": -41.32,
    "LAT": -6.95,
    "CIDADE": "Bocaina",
    "UF": "PIAUÍ",
    "anual": 5.79,
    "JAN": 5.28,
    "FEB": 5.29,
    "MAR": 5.47,
    "APR": 5.45,
    "MAY": 5.39,
    "JUN": 5.43,
    "JUL": 5.86,
    "AUG": 6.5,
    "SEP": 6.75,
    "OCT": 6.32,
    "NOV": 6.1,
    "DEC": 5.62
  },
  {
    "LONG": -43.56,
    "LAT": -6.78,
    "CIDADE": "Guadalupe",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 4.86,
    "FEB": 5.06,
    "MAR": 5.15,
    "APR": 5.33,
    "MAY": 5.43,
    "JUN": 5.72,
    "JUL": 5.98,
    "AUG": 6.36,
    "SEP": 6.61,
    "OCT": 6.15,
    "NOV": 5.64,
    "DEC": 5.17
  },
  {
    "LONG": -43.02,
    "LAT": -6.77,
    "CIDADE": "Floriano",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 5.02,
    "FEB": 5.18,
    "MAR": 5.26,
    "APR": 5.32,
    "MAY": 5.48,
    "JUN": 5.69,
    "JUL": 5.89,
    "AUG": 6.32,
    "SEP": 6.63,
    "OCT": 6.23,
    "NOV": 5.75,
    "DEC": 5.29
  },
  {
    "LONG": -42.39,
    "LAT": -6.8,
    "CIDADE": "Cajazeiras do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.74,
    "JAN": 5.08,
    "FEB": 5.28,
    "MAR": 5.29,
    "APR": 5.41,
    "MAY": 5.62,
    "JUN": 5.6,
    "JUL": 5.94,
    "AUG": 6.37,
    "SEP": 6.57,
    "OCT": 6.31,
    "NOV": 5.99,
    "DEC": 5.46
  },
  {
    "LONG": -42.28,
    "LAT": -6.8,
    "CIDADE": "Santa Rosa do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5.07,
    "FEB": 5.24,
    "MAR": 5.26,
    "APR": 5.4,
    "MAY": 5.6,
    "JUN": 5.64,
    "JUL": 5.97,
    "AUG": 6.33,
    "SEP": 6.57,
    "OCT": 6.27,
    "NOV": 5.97,
    "DEC": 5.42
  },
  {
    "LONG": -41.74,
    "LAT": -6.82,
    "CIDADE": "Ipiranga do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 4.97,
    "FEB": 5.1,
    "MAR": 5.21,
    "APR": 5.33,
    "MAY": 5.5,
    "JUN": 5.47,
    "JUL": 5.95,
    "AUG": 6.47,
    "SEP": 6.74,
    "OCT": 6.28,
    "NOV": 5.91,
    "DEC": 5.43
  },
  {
    "LONG": -41.34,
    "LAT": -6.82,
    "CIDADE": "São João da Canabrava",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.19,
    "FEB": 5.2,
    "MAR": 5.37,
    "APR": 5.39,
    "MAY": 5.44,
    "JUN": 5.48,
    "JUL": 5.88,
    "AUG": 6.53,
    "SEP": 6.79,
    "OCT": 6.34,
    "NOV": 5.98,
    "DEC": 5.5
  },
  {
    "LONG": -41.32,
    "LAT": -6.83,
    "CIDADE": "São Luis do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.19,
    "FEB": 5.2,
    "MAR": 5.37,
    "APR": 5.39,
    "MAY": 5.44,
    "JUN": 5.48,
    "JUL": 5.88,
    "AUG": 6.53,
    "SEP": 6.79,
    "OCT": 6.34,
    "NOV": 5.98,
    "DEC": 5.5
  },
  {
    "LONG": -4.06,
    "LAT": -6.83,
    "CIDADE": "Pio IX",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.18,
    "FEB": 5.22,
    "MAR": 5.38,
    "APR": 5.26,
    "MAY": 5.22,
    "JUN": 5.27,
    "JUL": 5.72,
    "AUG": 6.52,
    "SEP": 6.73,
    "OCT": 6.42,
    "NOV": 6.09,
    "DEC": 5.65
  },
  {
    "LONG": -42.53,
    "LAT": -6.65,
    "CIDADE": "Arraial",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.02,
    "FEB": 5.21,
    "MAR": 5.26,
    "APR": 5.42,
    "MAY": 5.56,
    "JUN": 5.63,
    "JUL": 5.97,
    "AUG": 6.38,
    "SEP": 6.54,
    "OCT": 6.3,
    "NOV": 5.87,
    "DEC": 5.42
  },
  {
    "LONG": -41.71,
    "LAT": -0.67,
    "CIDADE": "Inhuma",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 5,
    "FEB": 5.15,
    "MAR": 5.18,
    "APR": 5.28,
    "MAY": 5.52,
    "JUN": 5.49,
    "JUL": 5.93,
    "AUG": 6.45,
    "SEP": 6.71,
    "OCT": 6.31,
    "NOV": 5.92,
    "DEC": 5.47
  },
  {
    "LONG": -42.69,
    "LAT": -6.63,
    "CIDADE": "Francisco Ayres",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 4.98,
    "FEB": 5.16,
    "MAR": 5.24,
    "APR": 5.34,
    "MAY": 5.55,
    "JUN": 5.7,
    "JUL": 5.95,
    "AUG": 6.38,
    "SEP": 6.56,
    "OCT": 6.27,
    "NOV": 5.84,
    "DEC": 5.39
  },
  {
    "LONG": -42.28,
    "LAT": -6.6,
    "CIDADE": "Tanque do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.93,
    "FEB": 5.04,
    "MAR": 5.11,
    "APR": 5.24,
    "MAY": 5.51,
    "JUN": 5.65,
    "JUL": 6.03,
    "AUG": 6.41,
    "SEP": 6.69,
    "OCT": 6.22,
    "NOV": 5.84,
    "DEC": 5.38
  },
  {
    "LONG": -42.25,
    "LAT": -6.55,
    "CIDADE": "Várzea Grande",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.95,
    "FEB": 5.09,
    "MAR": 5.15,
    "APR": 5.27,
    "MAY": 5.49,
    "JUN": 5.61,
    "JUL": 5.95,
    "AUG": 6.41,
    "SEP": 6.63,
    "OCT": 6.19,
    "NOV": 5.86,
    "DEC": 5.4
  },
  {
    "LONG": -42.11,
    "LAT": -6.52,
    "CIDADE": "Barra D'Alcântara",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.97,
    "FEB": 5.1,
    "MAR": 5.17,
    "APR": 5.27,
    "MAY": 5.46,
    "JUN": 5.57,
    "JUL": 5.93,
    "AUG": 6.4,
    "SEP": 6.64,
    "OCT": 6.18,
    "NOV": 5.87,
    "DEC": 5.41
  },
  {
    "LONG": -41.57,
    "LAT": -0.65,
    "CIDADE": "Lagoa do Sítio",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 4.99,
    "FEB": 5.16,
    "MAR": 5.22,
    "APR": 5.3,
    "MAY": 5.43,
    "JUN": 5.49,
    "JUL": 5.92,
    "AUG": 6.47,
    "SEP": 6.63,
    "OCT": 6.24,
    "NOV": 5.91,
    "DEC": 5.39
  },
  {
    "LONG": -42.27,
    "LAT": -6.4,
    "CIDADE": "Francinópolis",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 4.98,
    "FEB": 5.17,
    "MAR": 5.21,
    "APR": 5.27,
    "MAY": 5.49,
    "JUN": 5.58,
    "JUL": 5.89,
    "AUG": 6.44,
    "SEP": 6.61,
    "OCT": 6.17,
    "NOV": 5.88,
    "DEC": 5.43
  },
  {
    "LONG": -41.93,
    "LAT": -6.45,
    "CIDADE": "Novo Oriente do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.05,
    "FEB": 5.19,
    "MAR": 5.3,
    "APR": 5.36,
    "MAY": 5.5,
    "JUN": 5.59,
    "JUL": 5.9,
    "AUG": 6.4,
    "SEP": 6.61,
    "OCT": 6.23,
    "NOV": 5.96,
    "DEC": 5.49
  },
  {
    "LONG": -41.74,
    "LAT": -6.4,
    "CIDADE": "Valença do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.02,
    "FEB": 5.12,
    "MAR": 5.25,
    "APR": 5.36,
    "MAY": 5.45,
    "JUN": 5.54,
    "JUL": 5.95,
    "AUG": 6.43,
    "SEP": 6.64,
    "OCT": 6.29,
    "NOV": 5.98,
    "DEC": 5.46
  },
  {
    "LONG": -42.85,
    "LAT": -6.24,
    "CIDADE": "Amarante",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 4.86,
    "FEB": 5.1,
    "MAR": 5.2,
    "APR": 5.26,
    "MAY": 5.5,
    "JUN": 5.72,
    "JUL": 5.96,
    "AUG": 6.36,
    "SEP": 6.6,
    "OCT": 6.16,
    "NOV": 5.78,
    "DEC": 5.26
  },
  {
    "LONG": -42.68,
    "LAT": -6.23,
    "CIDADE": "Regeneração",
    "UF": "PIAUÍ",
    "anual": 5.61,
    "JAN": 4.82,
    "FEB": 5.07,
    "MAR": 5.18,
    "APR": 5.19,
    "MAY": 5.43,
    "JUN": 5.6,
    "JUL": 5.93,
    "AUG": 6.48,
    "SEP": 6.59,
    "OCT": 6.16,
    "NOV": 5.7,
    "DEC": 5.22
  },
  {
    "LONG": -42.14,
    "LAT": -6.2,
    "CIDADE": "Elesbão Veloso",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.08,
    "FEB": 5.25,
    "MAR": 5.3,
    "APR": 5.44,
    "MAY": 5.44,
    "JUN": 5.49,
    "JUL": 5.81,
    "AUG": 6.37,
    "SEP": 6.62,
    "OCT": 6.24,
    "NOV": 6,
    "DEC": 5.54
  },
  {
    "LONG": -41.41,
    "LAT": -6.24,
    "CIDADE": "Pimenteiras",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 4.97,
    "FEB": 5.12,
    "MAR": 5.28,
    "APR": 5.39,
    "MAY": 5.39,
    "JUN": 5.48,
    "JUL": 5.78,
    "AUG": 6.39,
    "SEP": 6.67,
    "OCT": 6.32,
    "NOV": 6,
    "DEC": 5.43
  },
  {
    "LONG": -42.74,
    "LAT": -6.09,
    "CIDADE": "Angical do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.86,
    "FEB": 5.09,
    "MAR": 5.2,
    "APR": 5.23,
    "MAY": 5.43,
    "JUN": 5.67,
    "JUL": 5.96,
    "AUG": 6.45,
    "SEP": 6.59,
    "OCT": 6.14,
    "NOV": 5.76,
    "DEC": 5.22
  },
  {
    "LONG": -42.63,
    "LAT": -6.1,
    "CIDADE": "Jardim do Mulato",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 4.82,
    "FEB": 5.13,
    "MAR": 5.23,
    "APR": 5.24,
    "MAY": 5.46,
    "JUN": 5.64,
    "JUL": 5.97,
    "AUG": 6.51,
    "SEP": 6.61,
    "OCT": 6.18,
    "NOV": 5.77,
    "DEC": 5.23
  },
  {
    "LONG": -41.78,
    "LAT": -6.11,
    "CIDADE": "Aroazes",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.98,
    "FEB": 5.08,
    "MAR": 5.14,
    "APR": 5.32,
    "MAY": 5.44,
    "JUN": 5.55,
    "JUL": 5.88,
    "AUG": 6.4,
    "SEP": 6.65,
    "OCT": 6.28,
    "NOV": 5.94,
    "DEC": 5.43
  },
  {
    "LONG": -43.06,
    "LAT": -5.97,
    "CIDADE": "Palmeirais",
    "UF": "PIAUÍ",
    "anual": 5.59,
    "JAN": 4.78,
    "FEB": 5,
    "MAR": 5.05,
    "APR": 5.14,
    "MAY": 5.33,
    "JUN": 5.68,
    "JUL": 5.97,
    "AUG": 6.43,
    "SEP": 6.62,
    "OCT": 6.13,
    "NOV": 5.69,
    "DEC": 5.25
  },
  {
    "LONG": -42.7,
    "LAT": -0.6,
    "CIDADE": "São Gonçalo do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.82,
    "FEB": 5.06,
    "MAR": 5.16,
    "APR": 5.18,
    "MAY": 5.42,
    "JUN": 5.67,
    "JUL": 5.96,
    "AUG": 6.47,
    "SEP": 6.62,
    "OCT": 6.18,
    "NOV": 5.72,
    "DEC": 5.29
  },
  {
    "LONG": -42.71,
    "LAT": -6.05,
    "CIDADE": "Santo Antônio dos Milagres",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.82,
    "FEB": 5.06,
    "MAR": 5.16,
    "APR": 5.18,
    "MAY": 5.42,
    "JUN": 5.67,
    "JUL": 5.96,
    "AUG": 6.47,
    "SEP": 6.62,
    "OCT": 6.18,
    "NOV": 5.72,
    "DEC": 5.29
  },
  {
    "LONG": -42.56,
    "LAT": -0.6,
    "CIDADE": "Hugo Napoleão",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.87,
    "FEB": 5.11,
    "MAR": 5.29,
    "APR": 5.28,
    "MAY": 5.45,
    "JUN": 5.7,
    "JUL": 5.9,
    "AUG": 6.41,
    "SEP": 6.62,
    "OCT": 6.18,
    "NOV": 5.77,
    "DEC": 5.35
  },
  {
    "LONG": -42.72,
    "LAT": -5.92,
    "CIDADE": "São Pedro do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.64,
    "JAN": 4.76,
    "FEB": 5.07,
    "MAR": 5.17,
    "APR": 5.19,
    "MAY": 5.44,
    "JUN": 5.7,
    "JUL": 5.96,
    "AUG": 6.46,
    "SEP": 6.63,
    "OCT": 6.22,
    "NOV": 5.72,
    "DEC": 5.3
  },
  {
    "LONG": -42.63,
    "LAT": -5.89,
    "CIDADE": "Água Branca",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.85,
    "FEB": 5.15,
    "MAR": 5.25,
    "APR": 5.23,
    "MAY": 5.45,
    "JUN": 5.72,
    "JUL": 5.96,
    "AUG": 6.4,
    "SEP": 6.61,
    "OCT": 6.25,
    "NOV": 5.78,
    "DEC": 5.37
  },
  {
    "LONG": -42.44,
    "LAT": -5.86,
    "CIDADE": "Passagem Franca do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 4.89,
    "FEB": 5.15,
    "MAR": 5.3,
    "APR": 5.3,
    "MAY": 5.47,
    "JUN": 5.69,
    "JUL": 5.92,
    "AUG": 6.45,
    "SEP": 6.68,
    "OCT": 6.29,
    "NOV": 5.91,
    "DEC": 5.45
  },
  {
    "LONG": -42.19,
    "LAT": -5.86,
    "CIDADE": "São Miguel da Baixa Grande",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.04,
    "FEB": 5.22,
    "MAR": 5.35,
    "APR": 5.37,
    "MAY": 5.5,
    "JUN": 5.57,
    "JUL": 5.84,
    "AUG": 6.38,
    "SEP": 6.59,
    "OCT": 6.26,
    "NOV": 6,
    "DEC": 5.48
  },
  {
    "LONG": -42.11,
    "LAT": -5.94,
    "CIDADE": "São Félix do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.04,
    "FEB": 5.22,
    "MAR": 5.35,
    "APR": 5.37,
    "MAY": 5.5,
    "JUN": 5.57,
    "JUL": 5.84,
    "AUG": 6.38,
    "SEP": 6.59,
    "OCT": 6.26,
    "NOV": 6,
    "DEC": 5.48
  },
  {
    "LONG": -41.04,
    "LAT": -5.87,
    "CIDADE": "Assunção do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.76,
    "JAN": 5.15,
    "FEB": 5.16,
    "MAR": 5.39,
    "APR": 5.22,
    "MAY": 5.32,
    "JUN": 5.44,
    "JUL": 5.8,
    "AUG": 6.49,
    "SEP": 6.75,
    "OCT": 6.51,
    "NOV": 6.28,
    "DEC": 5.65
  },
  {
    "LONG": -42.63,
    "LAT": -5.83,
    "CIDADE": "Lagoinha do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.78,
    "FEB": 5.13,
    "MAR": 5.21,
    "APR": 5.22,
    "MAY": 5.44,
    "JUN": 5.73,
    "JUL": 5.97,
    "AUG": 6.44,
    "SEP": 6.64,
    "OCT": 6.25,
    "NOV": 5.78,
    "DEC": 5.35
  },
  {
    "LONG": -42.66,
    "LAT": -0.58,
    "CIDADE": "Agricolândia",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.78,
    "FEB": 5.13,
    "MAR": 5.21,
    "APR": 5.22,
    "MAY": 5.44,
    "JUN": 5.73,
    "JUL": 5.97,
    "AUG": 6.44,
    "SEP": 6.64,
    "OCT": 6.25,
    "NOV": 5.78,
    "DEC": 5.35
  },
  {
    "LONG": -4.25,
    "LAT": -5.82,
    "CIDADE": "Barro Duro",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.79,
    "FEB": 5.1,
    "MAR": 5.19,
    "APR": 5.22,
    "MAY": 5.44,
    "JUN": 5.7,
    "JUL": 5.95,
    "AUG": 6.44,
    "SEP": 6.67,
    "OCT": 6.28,
    "NOV": 5.86,
    "DEC": 5.35
  },
  {
    "LONG": -42.57,
    "LAT": -0.58,
    "CIDADE": "Olho d'Água do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.79,
    "FEB": 5.1,
    "MAR": 5.19,
    "APR": 5.22,
    "MAY": 5.44,
    "JUN": 5.7,
    "JUL": 5.95,
    "AUG": 6.44,
    "SEP": 6.67,
    "OCT": 6.28,
    "NOV": 5.86,
    "DEC": 5.35
  },
  {
    "LONG": -4.2,
    "LAT": -5.81,
    "CIDADE": "Santa Cruz dos Milagres",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5,
    "FEB": 5.28,
    "MAR": 5.36,
    "APR": 5.41,
    "MAY": 5.5,
    "JUN": 5.56,
    "JUL": 5.82,
    "AUG": 6.44,
    "SEP": 6.62,
    "OCT": 6.29,
    "NOV": 6.01,
    "DEC": 5.48
  },
  {
    "LONG": -42.74,
    "LAT": -5.68,
    "CIDADE": "Miguel Leão",
    "UF": "PIAUÍ",
    "anual": 5.59,
    "JAN": 4.71,
    "FEB": 5.02,
    "MAR": 5.14,
    "APR": 5.11,
    "MAY": 5.37,
    "JUN": 5.66,
    "JUL": 5.93,
    "AUG": 6.39,
    "SEP": 6.57,
    "OCT": 6.19,
    "NOV": 5.71,
    "DEC": 5.31
  },
  {
    "LONG": -4.22,
    "LAT": -5.67,
    "CIDADE": "Prata do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.73,
    "JAN": 5.1,
    "FEB": 5.22,
    "MAR": 5.33,
    "APR": 5.38,
    "MAY": 5.48,
    "JUN": 5.59,
    "JUL": 5.86,
    "AUG": 6.33,
    "SEP": 6.58,
    "OCT": 6.32,
    "NOV": 6.05,
    "DEC": 5.55
  },
  {
    "LONG": -42.83,
    "LAT": -5.62,
    "CIDADE": "Curralinhos",
    "UF": "PIAUÍ",
    "anual": 5.59,
    "JAN": 4.75,
    "FEB": 5,
    "MAR": 5.14,
    "APR": 5.15,
    "MAY": 5.33,
    "JUN": 5.68,
    "JUL": 5.85,
    "AUG": 6.43,
    "SEP": 6.58,
    "OCT": 6.19,
    "NOV": 5.76,
    "DEC": 5.29
  },
  {
    "LONG": -42.61,
    "LAT": -5.56,
    "CIDADE": "Monsenhor Gil",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.85,
    "FEB": 5.08,
    "MAR": 5.2,
    "APR": 5.16,
    "MAY": 5.38,
    "JUN": 5.67,
    "JUL": 5.8,
    "AUG": 6.45,
    "SEP": 6.62,
    "OCT": 6.3,
    "NOV": 5.92,
    "DEC": 5.47
  },
  {
    "LONG": -42.36,
    "LAT": -5.46,
    "CIDADE": "Beneditinos",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.04,
    "FEB": 5.22,
    "MAR": 5.29,
    "APR": 5.31,
    "MAY": 5.44,
    "JUN": 5.65,
    "JUL": 5.89,
    "AUG": 6.32,
    "SEP": 6.59,
    "OCT": 6.34,
    "NOV": 6.04,
    "DEC": 5.57
  },
  {
    "LONG": -41.89,
    "LAT": -5.51,
    "CIDADE": "São João da Serra",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 4.99,
    "FEB": 5.23,
    "MAR": 5.32,
    "APR": 5.3,
    "MAY": 5.41,
    "JUN": 5.49,
    "JUL": 5.77,
    "AUG": 6.37,
    "SEP": 6.6,
    "OCT": 6.27,
    "NOV": 6.06,
    "DEC": 5.49
  },
  {
    "LONG": -41.32,
    "LAT": -5.5,
    "CIDADE": "São Miguel do Tapuio",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 5,
    "FEB": 5.21,
    "MAR": 5.38,
    "APR": 5.22,
    "MAY": 5.28,
    "JUN": 5.36,
    "JUL": 5.67,
    "AUG": 6.39,
    "SEP": 6.7,
    "OCT": 6.44,
    "NOV": 6.16,
    "DEC": 5.54
  },
  {
    "LONG": -42.68,
    "LAT": -5.36,
    "CIDADE": "Demerval Lobão",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.91,
    "FEB": 5.05,
    "MAR": 5.25,
    "APR": 5.21,
    "MAY": 5.33,
    "JUN": 5.6,
    "JUL": 5.86,
    "AUG": 6.37,
    "SEP": 6.64,
    "OCT": 6.3,
    "NOV": 5.93,
    "DEC": 5.49
  },
  {
    "LONG": -4.26,
    "LAT": -5.41,
    "CIDADE": "Lagoa do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.91,
    "FEB": 5.05,
    "MAR": 5.25,
    "APR": 5.21,
    "MAY": 5.33,
    "JUN": 5.6,
    "JUL": 5.86,
    "AUG": 6.37,
    "SEP": 6.64,
    "OCT": 6.3,
    "NOV": 5.93,
    "DEC": 5.49
  },
  {
    "LONG": -42.81,
    "LAT": -5.35,
    "CIDADE": "Nazária",
    "UF": "PIAUÍ",
    "anual": 5.64,
    "JAN": 4.89,
    "FEB": 5.15,
    "MAR": 5.22,
    "APR": 5.18,
    "MAY": 5.27,
    "JUN": 5.55,
    "JUL": 5.86,
    "AUG": 6.36,
    "SEP": 6.6,
    "OCT": 6.26,
    "NOV": 5.9,
    "DEC": 5.48
  },
  {
    "LONG": -4.24,
    "LAT": -5.25,
    "CIDADE": "Pau D'Arco do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.04,
    "FEB": 5.18,
    "MAR": 5.33,
    "APR": 5.22,
    "MAY": 5.34,
    "JUN": 5.56,
    "JUL": 5.83,
    "AUG": 6.44,
    "SEP": 6.61,
    "OCT": 6.35,
    "NOV": 6.06,
    "DEC": 5.59
  },
  {
    "LONG": -42.21,
    "LAT": -5.26,
    "CIDADE": "Alto Longá",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.03,
    "FEB": 5.2,
    "MAR": 5.35,
    "APR": 5.24,
    "MAY": 5.35,
    "JUN": 5.56,
    "JUL": 5.85,
    "AUG": 6.38,
    "SEP": 6.6,
    "OCT": 6.36,
    "NOV": 6.04,
    "DEC": 5.57
  },
  {
    "LONG": -41.93,
    "LAT": -5.29,
    "CIDADE": "Novo Santo Antônio",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 4.99,
    "FEB": 5.25,
    "MAR": 5.44,
    "APR": 5.31,
    "MAY": 5.36,
    "JUN": 5.47,
    "JUL": 5.78,
    "AUG": 6.32,
    "SEP": 6.58,
    "OCT": 6.31,
    "NOV": 6.07,
    "DEC": 5.55
  },
  {
    "LONG": -41.55,
    "LAT": -5.32,
    "CIDADE": "Castelo do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 4.96,
    "FEB": 5.16,
    "MAR": 5.32,
    "APR": 5.2,
    "MAY": 5.32,
    "JUN": 5.39,
    "JUL": 5.7,
    "AUG": 6.38,
    "SEP": 6.65,
    "OCT": 6.37,
    "NOV": 6.11,
    "DEC": 5.51
  },
  {
    "LONG": -41.09,
    "LAT": -5.31,
    "CIDADE": "Buriti dos Montes",
    "UF": "PIAUÍ",
    "anual": 5.77,
    "JAN": 5.07,
    "FEB": 5.19,
    "MAR": 5.43,
    "APR": 5.11,
    "MAY": 5.24,
    "JUN": 5.47,
    "JUL": 5.87,
    "AUG": 6.52,
    "SEP": 6.78,
    "OCT": 6.58,
    "NOV": 6.33,
    "DEC": 5.68
  },
  {
    "LONG": -41.7,
    "LAT": -0.52,
    "CIDADE": "Juazeiro do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 5.01,
    "FEB": 5.24,
    "MAR": 5.39,
    "APR": 5.25,
    "MAY": 5.36,
    "JUN": 5.4,
    "JUL": 5.66,
    "AUG": 6.32,
    "SEP": 6.59,
    "OCT": 6.33,
    "NOV": 6.09,
    "DEC": 5.58
  },
  {
    "LONG": -4.28,
    "LAT": -5.09,
    "CIDADE": "Teresina",
    "UF": "PIAUÍ",
    "anual": 5.58,
    "JAN": 4.94,
    "FEB": 5.12,
    "MAR": 5.14,
    "APR": 5.13,
    "MAY": 5.23,
    "JUN": 5.41,
    "JUL": 5.69,
    "AUG": 6.19,
    "SEP": 6.49,
    "OCT": 6.25,
    "NOV": 5.97,
    "DEC": 5.43
  },
  {
    "LONG": -42.21,
    "LAT": -5.09,
    "CIDADE": "Coivaras",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 4.98,
    "FEB": 5.18,
    "MAR": 5.4,
    "APR": 5.23,
    "MAY": 5.31,
    "JUN": 5.54,
    "JUL": 5.79,
    "AUG": 6.39,
    "SEP": 6.54,
    "OCT": 6.33,
    "NOV": 6.06,
    "DEC": 5.51
  },
  {
    "LONG": -42.46,
    "LAT": -5.04,
    "CIDADE": "Altos",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.98,
    "FEB": 5.15,
    "MAR": 5.32,
    "APR": 5.17,
    "MAY": 5.28,
    "JUN": 5.51,
    "JUL": 5.8,
    "AUG": 6.35,
    "SEP": 6.57,
    "OCT": 6.33,
    "NOV": 5.99,
    "DEC": 5.46
  },
  {
    "LONG": -41.73,
    "LAT": -0.49,
    "CIDADE": "Sigefredo Pacheco",
    "UF": "PIAUÍ",
    "anual": 5.75,
    "JAN": 5.1,
    "FEB": 5.3,
    "MAR": 5.53,
    "APR": 5.32,
    "MAY": 5.45,
    "JUN": 5.47,
    "JUL": 5.75,
    "AUG": 6.33,
    "SEP": 6.64,
    "OCT": 6.42,
    "NOV": 6.18,
    "DEC": 5.56
  },
  {
    "LONG": -42.57,
    "LAT": -4.75,
    "CIDADE": "José de Freitas",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 5.01,
    "FEB": 5.19,
    "MAR": 5.3,
    "APR": 5.22,
    "MAY": 5.25,
    "JUN": 5.49,
    "JUL": 5.76,
    "AUG": 6.29,
    "SEP": 6.52,
    "OCT": 6.3,
    "NOV": 5.99,
    "DEC": 5.47
  },
  {
    "LONG": -42.16,
    "LAT": -4.83,
    "CIDADE": "Campo Maior",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 5.15,
    "FEB": 5.33,
    "MAR": 5.5,
    "APR": 5.38,
    "MAY": 5.39,
    "JUN": 5.49,
    "JUL": 5.66,
    "AUG": 6.19,
    "SEP": 6.48,
    "OCT": 6.34,
    "NOV": 6.04,
    "DEC": 5.5
  },
  {
    "LONG": -41.82,
    "LAT": -4.77,
    "CIDADE": "Jatobá do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 5.07,
    "FEB": 5.25,
    "MAR": 5.41,
    "APR": 5.2,
    "MAY": 5.33,
    "JUN": 5.4,
    "JUL": 5.66,
    "AUG": 6.24,
    "SEP": 6.56,
    "OCT": 6.4,
    "NOV": 6.11,
    "DEC": 5.49
  },
  {
    "LONG": -41.42,
    "LAT": -4.68,
    "CIDADE": "Milton Brandão",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.03,
    "FEB": 5.25,
    "MAR": 5.49,
    "APR": 5.21,
    "MAY": 5.3,
    "JUN": 5.37,
    "JUL": 5.66,
    "AUG": 6.34,
    "SEP": 6.72,
    "OCT": 6.48,
    "NOV": 6.2,
    "DEC": 5.53
  },
  {
    "LONG": -42.86,
    "LAT": -4.59,
    "CIDADE": "União",
    "UF": "PIAUÍ",
    "anual": 5.6,
    "JAN": 5.02,
    "FEB": 5.15,
    "MAR": 5.24,
    "APR": 5.22,
    "MAY": 5.21,
    "JUN": 5.4,
    "JUL": 5.67,
    "AUG": 6.25,
    "SEP": 6.46,
    "OCT": 6.24,
    "NOV": 5.91,
    "DEC": 5.46
  },
  {
    "LONG": -42.17,
    "LAT": -4.63,
    "CIDADE": "Nossa Senhora de Nazaré",
    "UF": "PIAUÍ",
    "anual": 5.64,
    "JAN": 5.07,
    "FEB": 5.18,
    "MAR": 5.29,
    "APR": 5.18,
    "MAY": 5.28,
    "JUN": 5.55,
    "JUL": 5.74,
    "AUG": 6.19,
    "SEP": 6.45,
    "OCT": 6.28,
    "NOV": 6.03,
    "DEC": 5.51
  },
  {
    "LONG": -41.97,
    "LAT": -4.55,
    "CIDADE": "Cocal de Telha",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 5.01,
    "FEB": 5.23,
    "MAR": 5.28,
    "APR": 5.13,
    "MAY": 5.28,
    "JUN": 5.51,
    "JUL": 5.78,
    "AUG": 6.34,
    "SEP": 6.59,
    "OCT": 6.38,
    "NOV": 6.1,
    "DEC": 5.5
  },
  {
    "LONG": -42.62,
    "LAT": -4.51,
    "CIDADE": "Lagoa Alegre",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.99,
    "FEB": 5.11,
    "MAR": 5.28,
    "APR": 5.19,
    "MAY": 5.24,
    "JUN": 5.49,
    "JUL": 5.76,
    "AUG": 6.22,
    "SEP": 6.54,
    "OCT": 6.35,
    "NOV": 5.97,
    "DEC": 5.45
  },
  {
    "LONG": -42.31,
    "LAT": -4.47,
    "CIDADE": "Cabeceiras do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 4.98,
    "FEB": 5.25,
    "MAR": 5.34,
    "APR": 5.19,
    "MAY": 5.3,
    "JUN": 5.5,
    "JUL": 5.74,
    "AUG": 6.28,
    "SEP": 6.48,
    "OCT": 6.29,
    "NOV": 6.03,
    "DEC": 5.48
  },
  {
    "LONG": -42.1,
    "LAT": -4.49,
    "CIDADE": "Boqueirão do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 5.07,
    "FEB": 5.22,
    "MAR": 5.3,
    "APR": 5.13,
    "MAY": 5.32,
    "JUN": 5.53,
    "JUL": 5.76,
    "AUG": 6.28,
    "SEP": 6.51,
    "OCT": 6.32,
    "NOV": 6.09,
    "DEC": 5.52
  },
  {
    "LONG": -41.94,
    "LAT": -4.46,
    "CIDADE": "Capitão de Campos",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 5.04,
    "FEB": 5.24,
    "MAR": 5.32,
    "APR": 5.12,
    "MAY": 5.32,
    "JUN": 5.51,
    "JUL": 5.79,
    "AUG": 6.32,
    "SEP": 6.6,
    "OCT": 6.35,
    "NOV": 6.11,
    "DEC": 5.52
  },
  {
    "LONG": -42.12,
    "LAT": -4.41,
    "CIDADE": "Boa Hora",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 5.11,
    "FEB": 5.24,
    "MAR": 5.28,
    "APR": 5.11,
    "MAY": 5.28,
    "JUN": 5.5,
    "JUL": 5.76,
    "AUG": 6.25,
    "SEP": 6.5,
    "OCT": 6.28,
    "NOV": 6.1,
    "DEC": 5.55
  },
  {
    "LONG": -41.6,
    "LAT": -4.39,
    "CIDADE": "Lagoa de São Francisco",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.97,
    "FEB": 5.19,
    "MAR": 5.24,
    "APR": 5.02,
    "MAY": 5.26,
    "JUN": 5.43,
    "JUL": 5.65,
    "AUG": 6.27,
    "SEP": 6.59,
    "OCT": 6.37,
    "NOV": 6.08,
    "DEC": 5.45
  },
  {
    "LONG": -4.15,
    "LAT": -4.43,
    "CIDADE": "Pedro II",
    "UF": "PIAUÍ",
    "anual": 5.69,
    "JAN": 5.07,
    "FEB": 5.21,
    "MAR": 5.32,
    "APR": 5.07,
    "MAY": 5.28,
    "JUN": 5.4,
    "JUL": 5.71,
    "AUG": 6.32,
    "SEP": 6.68,
    "OCT": 6.45,
    "NOV": 6.24,
    "DEC": 5.57
  },
  {
    "LONG": -41.78,
    "LAT": -4.27,
    "CIDADE": "Piripiri",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 5.06,
    "FEB": 5.26,
    "MAR": 5.36,
    "APR": 5.14,
    "MAY": 5.38,
    "JUN": 5.45,
    "JUL": 5.68,
    "AUG": 6.26,
    "SEP": 6.54,
    "OCT": 6.34,
    "NOV": 6.09,
    "DEC": 5.51
  },
  {
    "LONG": -41.27,
    "LAT": -4.25,
    "CIDADE": "Domingos Mourão",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 4.97,
    "FEB": 5.18,
    "MAR": 5.31,
    "APR": 5.11,
    "MAY": 5.23,
    "JUN": 5.31,
    "JUL": 5.58,
    "AUG": 6.18,
    "SEP": 6.57,
    "OCT": 6.45,
    "NOV": 6.23,
    "DEC": 5.48
  },
  {
    "LONG": -42.9,
    "LAT": -0.42,
    "CIDADE": "Miguel Alves",
    "UF": "PIAUÍ",
    "anual": 5.58,
    "JAN": 4.93,
    "FEB": 5.19,
    "MAR": 5.23,
    "APR": 5.15,
    "MAY": 5.22,
    "JUN": 5.45,
    "JUL": 5.64,
    "AUG": 6.12,
    "SEP": 6.42,
    "OCT": 6.27,
    "NOV": 5.89,
    "DEC": 5.4
  },
  {
    "LONG": -42.29,
    "LAT": -4.25,
    "CIDADE": "Barras",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 5.17,
    "FEB": 5.28,
    "MAR": 5.23,
    "APR": 5.05,
    "MAY": 5.21,
    "JUN": 5.45,
    "JUL": 5.77,
    "AUG": 6.35,
    "SEP": 6.5,
    "OCT": 6.35,
    "NOV": 6.15,
    "DEC": 5.59
  },
  {
    "LONG": -41.79,
    "LAT": -4.13,
    "CIDADE": "Brasileira",
    "UF": "PIAUÍ",
    "anual": 5.75,
    "JAN": 5.2,
    "FEB": 5.37,
    "MAR": 5.39,
    "APR": 5.23,
    "MAY": 5.39,
    "JUN": 5.53,
    "JUL": 5.8,
    "AUG": 6.28,
    "SEP": 6.56,
    "OCT": 6.45,
    "NOV": 6.21,
    "DEC": 5.56
  },
  {
    "LONG": -42.62,
    "LAT": -3.98,
    "CIDADE": "Nossa Senhora dos Remédios",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 5.14,
    "FEB": 5.24,
    "MAR": 5.31,
    "APR": 5.1,
    "MAY": 5.2,
    "JUN": 5.46,
    "JUL": 5.78,
    "AUG": 6.2,
    "SEP": 6.43,
    "OCT": 6.37,
    "NOV": 6.01,
    "DEC": 5.52
  },
  {
    "LONG": -4.21,
    "LAT": -4.02,
    "CIDADE": "Batalha",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.17,
    "FEB": 5.38,
    "MAR": 5.38,
    "APR": 5.26,
    "MAY": 5.36,
    "JUN": 5.5,
    "JUL": 5.76,
    "AUG": 6.21,
    "SEP": 6.47,
    "OCT": 6.4,
    "NOV": 6.13,
    "DEC": 5.53
  },
  {
    "LONG": -42.71,
    "LAT": -3.89,
    "CIDADE": "Porto",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 5.13,
    "FEB": 5.32,
    "MAR": 5.36,
    "APR": 5.26,
    "MAY": 5.3,
    "JUN": 5.49,
    "JUL": 5.72,
    "AUG": 6.19,
    "SEP": 6.4,
    "OCT": 6.25,
    "NOV": 5.99,
    "DEC": 5.49
  },
  {
    "LONG": -42.23,
    "LAT": -0.39,
    "CIDADE": "Esperantina",
    "UF": "PIAUÍ",
    "anual": 5.72,
    "JAN": 5.16,
    "FEB": 5.41,
    "MAR": 5.39,
    "APR": 5.21,
    "MAY": 5.33,
    "JUN": 5.51,
    "JUL": 5.7,
    "AUG": 6.27,
    "SEP": 6.52,
    "OCT": 6.4,
    "NOV": 6.16,
    "DEC": 5.54
  },
  {
    "LONG": -41.71,
    "LAT": -3.93,
    "CIDADE": "Piracuruca",
    "UF": "PIAUÍ",
    "anual": 5.71,
    "JAN": 5.15,
    "FEB": 5.32,
    "MAR": 5.3,
    "APR": 5.1,
    "MAY": 5.38,
    "JUN": 5.6,
    "JUL": 5.8,
    "AUG": 6.18,
    "SEP": 6.46,
    "OCT": 6.48,
    "NOV": 6.22,
    "DEC": 5.54
  },
  {
    "LONG": -41.26,
    "LAT": -3.94,
    "CIDADE": "São João da Fronteira",
    "UF": "PIAUÍ",
    "anual": 5.61,
    "JAN": 5.12,
    "FEB": 5.19,
    "MAR": 5.15,
    "APR": 4.89,
    "MAY": 5.16,
    "JUN": 5.27,
    "JUL": 5.51,
    "AUG": 6.12,
    "SEP": 6.62,
    "OCT": 6.5,
    "NOV": 6.28,
    "DEC": 5.56
  },
  {
    "LONG": -42.63,
    "LAT": -0.38,
    "CIDADE": "Campo Largo do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 5.1,
    "FEB": 5.27,
    "MAR": 5.31,
    "APR": 5.19,
    "MAY": 5.28,
    "JUN": 5.46,
    "JUL": 5.67,
    "AUG": 6.13,
    "SEP": 6.39,
    "OCT": 6.24,
    "NOV": 5.99,
    "DEC": 5.43
  },
  {
    "LONG": -42.45,
    "LAT": -3.82,
    "CIDADE": "São João do Arraial",
    "UF": "PIAUÍ",
    "anual": 5.7,
    "JAN": 5.16,
    "FEB": 5.38,
    "MAR": 5.35,
    "APR": 5.18,
    "MAY": 5.31,
    "JUN": 5.53,
    "JUL": 5.74,
    "AUG": 6.29,
    "SEP": 6.55,
    "OCT": 6.39,
    "NOV": 6.07,
    "DEC": 5.47
  },
  {
    "LONG": -41.83,
    "LAT": -0.38,
    "CIDADE": "São José do Divino",
    "UF": "PIAUÍ",
    "anual": 5.67,
    "JAN": 5.08,
    "FEB": 5.29,
    "MAR": 5.25,
    "APR": 5.12,
    "MAY": 5.37,
    "JUN": 5.56,
    "JUL": 5.73,
    "AUG": 6.17,
    "SEP": 6.46,
    "OCT": 6.43,
    "NOV": 6.16,
    "DEC": 5.46
  },
  {
    "LONG": -4.26,
    "LAT": -3.71,
    "CIDADE": "Matias Olímpio",
    "UF": "PIAUÍ",
    "anual": 5.64,
    "JAN": 5.07,
    "FEB": 5.29,
    "MAR": 5.37,
    "APR": 5.21,
    "MAY": 5.24,
    "JUN": 5.49,
    "JUL": 5.66,
    "AUG": 6.23,
    "SEP": 6.46,
    "OCT": 6.28,
    "NOV": 5.95,
    "DEC": 5.37
  },
  {
    "LONG": -42.31,
    "LAT": -3.74,
    "CIDADE": "Morro do Chapéu do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.66,
    "JAN": 5.13,
    "FEB": 5.34,
    "MAR": 5.36,
    "APR": 5.21,
    "MAY": 5.27,
    "JUN": 5.54,
    "JUL": 5.68,
    "AUG": 6.22,
    "SEP": 6.47,
    "OCT": 6.34,
    "NOV": 5.98,
    "DEC": 5.4
  },
  {
    "LONG": -41.44,
    "LAT": -3.62,
    "CIDADE": "Cocal dos Alves",
    "UF": "PIAUÍ",
    "anual": 5.54,
    "JAN": 5.02,
    "FEB": 5.09,
    "MAR": 5,
    "APR": 4.82,
    "MAY": 5.15,
    "JUN": 5.31,
    "JUL": 5.51,
    "AUG": 6.04,
    "SEP": 6.47,
    "OCT": 6.43,
    "NOV": 6.24,
    "DEC": 5.45
  },
  {
    "LONG": -42.5,
    "LAT": -3.48,
    "CIDADE": "Madeiro",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 5.15,
    "FEB": 5.23,
    "MAR": 5.39,
    "APR": 5.21,
    "MAY": 5.27,
    "JUN": 5.46,
    "JUL": 5.68,
    "AUG": 6.1,
    "SEP": 6.33,
    "OCT": 6.26,
    "NOV": 5.95,
    "DEC": 5.42
  },
  {
    "LONG": -4.24,
    "LAT": -3.5,
    "CIDADE": "Joca Marques",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 5.16,
    "FEB": 5.27,
    "MAR": 5.37,
    "APR": 5.17,
    "MAY": 5.3,
    "JUN": 5.46,
    "JUL": 5.68,
    "AUG": 6.13,
    "SEP": 6.38,
    "OCT": 6.28,
    "NOV": 5.96,
    "DEC": 5.39
  },
  {
    "LONG": -42.37,
    "LAT": -3.46,
    "CIDADE": "Luzilândia",
    "UF": "PIAUÍ",
    "anual": 5.62,
    "JAN": 5.12,
    "FEB": 5.33,
    "MAR": 5.3,
    "APR": 5.16,
    "MAY": 5.33,
    "JUN": 5.44,
    "JUL": 5.67,
    "AUG": 6.09,
    "SEP": 6.32,
    "OCT": 6.26,
    "NOV": 5.96,
    "DEC": 5.4
  },
  {
    "LONG": -42.2,
    "LAT": -3.51,
    "CIDADE": "Joaquim Pires",
    "UF": "PIAUÍ",
    "anual": 5.59,
    "JAN": 5.07,
    "FEB": 5.25,
    "MAR": 5.17,
    "APR": 5.05,
    "MAY": 5.3,
    "JUN": 5.51,
    "JUL": 5.65,
    "AUG": 6.05,
    "SEP": 6.36,
    "OCT": 6.31,
    "NOV": 5.95,
    "DEC": 5.35
  },
  {
    "LONG": -41.84,
    "LAT": -3.48,
    "CIDADE": "Caraúbas do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.58,
    "JAN": 5,
    "FEB": 5.18,
    "MAR": 5.16,
    "APR": 4.96,
    "MAY": 5.28,
    "JUN": 5.51,
    "JUL": 5.65,
    "AUG": 6.03,
    "SEP": 6.42,
    "OCT": 6.36,
    "NOV": 6.06,
    "DEC": 5.35
  },
  {
    "LONG": -4.16,
    "LAT": -3.47,
    "CIDADE": "Cocal",
    "UF": "PIAUÍ",
    "anual": 5.57,
    "JAN": 5.02,
    "FEB": 5.16,
    "MAR": 5.1,
    "APR": 4.87,
    "MAY": 5.16,
    "JUN": 5.37,
    "JUL": 5.59,
    "AUG": 6.14,
    "SEP": 6.47,
    "OCT": 6.42,
    "NOV": 6.15,
    "DEC": 5.43
  },
  {
    "LONG": -41.89,
    "LAT": -3.42,
    "CIDADE": "Caxingó",
    "UF": "PIAUÍ",
    "anual": 5.57,
    "JAN": 5.02,
    "FEB": 5.16,
    "MAR": 5.1,
    "APR": 4.88,
    "MAY": 5.21,
    "JUN": 5.49,
    "JUL": 5.69,
    "AUG": 6.1,
    "SEP": 6.44,
    "OCT": 6.38,
    "NOV": 6.08,
    "DEC": 5.33
  },
  {
    "LONG": -42.09,
    "LAT": -3.32,
    "CIDADE": "Murici dos Portelas",
    "UF": "PIAUÍ",
    "anual": 5.61,
    "JAN": 5.09,
    "FEB": 5.15,
    "MAR": 5.2,
    "APR": 5.06,
    "MAY": 5.22,
    "JUN": 5.53,
    "JUL": 5.71,
    "AUG": 6.13,
    "SEP": 6.35,
    "OCT": 6.32,
    "NOV": 6.09,
    "DEC": 5.49
  },
  {
    "LONG": -41.87,
    "LAT": -3.18,
    "CIDADE": "Buriti dos Lopes",
    "UF": "PIAUÍ",
    "anual": 5.55,
    "JAN": 5.05,
    "FEB": 5.09,
    "MAR": 5.05,
    "APR": 4.93,
    "MAY": 5.11,
    "JUN": 5.41,
    "JUL": 5.53,
    "AUG": 6.06,
    "SEP": 6.38,
    "OCT": 6.38,
    "NOV": 6.14,
    "DEC": 5.46
  },
  {
    "LONG": -41.64,
    "LAT": -3.19,
    "CIDADE": "Bom Princípio do Piauí",
    "UF": "PIAUÍ",
    "anual": 5.65,
    "JAN": 5.16,
    "FEB": 5.17,
    "MAR": 5.15,
    "APR": 4.96,
    "MAY": 5.26,
    "JUN": 5.46,
    "JUL": 5.58,
    "AUG": 6.14,
    "SEP": 6.51,
    "OCT": 6.53,
    "NOV": 6.28,
    "DEC": 5.55
  },
  {
    "LONG": -41.82,
    "LAT": -2.86,
    "CIDADE": "Ilha Grande",
    "UF": "PIAUÍ",
    "anual": 5.68,
    "JAN": 5.49,
    "FEB": 5.39,
    "MAR": 5.32,
    "APR": 5.05,
    "MAY": 5.3,
    "JUN": 5.47,
    "JUL": 5.63,
    "AUG": 6.18,
    "SEP": 6.1,
    "OCT": 5.99,
    "NOV": 6.25,
    "DEC": 5.93
  },
  {
    "LONG": -41.78,
    "LAT": -2.91,
    "CIDADE": "Parnaíba",
    "UF": "PIAUÍ",
    "anual": 5.58,
    "JAN": 5.47,
    "FEB": 5.46,
    "MAR": 5.36,
    "APR": 5.09,
    "MAY": 5.26,
    "JUN": 5.42,
    "JUL": 5.58,
    "AUG": 5.92,
    "SEP": 5.73,
    "OCT": 5.71,
    "NOV": 6.07,
    "DEC": 5.85
  },
  {
    "LONG": -41.66,
    "LAT": -2.88,
    "CIDADE": "Luís Correia",
    "UF": "PIAUÍ",
    "anual": 5.48,
    "JAN": 5.48,
    "FEB": 5.44,
    "MAR": 5.32,
    "APR": 5.05,
    "MAY": 5.26,
    "JUN": 5.41,
    "JUL": 5.5,
    "AUG": 5.73,
    "SEP": 5.44,
    "OCT": 5.54,
    "NOV": 5.87,
    "DEC": 5.76
  },
  {
    "LONG": -41.34,
    "LAT": -2.93,
    "CIDADE": "Cajueiro da Praia",
    "UF": "PIAUÍ",
    "anual": 5.63,
    "JAN": 5.54,
    "FEB": 5.4,
    "MAR": 5.22,
    "APR": 5.01,
    "MAY": 5.3,
    "JUN": 5.48,
    "JUL": 5.69,
    "AUG": 6.02,
    "SEP": 5.89,
    "OCT": 5.88,
    "NOV": 6.21,
    "DEC": 5.98
  },
  {
    "LONG": -4.47,
    "LAT": -23.22,
    "CIDADE": "Paraty",
    "UF": "RIO DE JANEIRO",
    "anual": 4.33,
    "JAN": 4.53,
    "FEB": 5.08,
    "MAR": 4.47,
    "APR": 4.47,
    "MAY": 4.1,
    "JUN": 4.04,
    "JUL": 3.95,
    "AUG": 4.61,
    "SEP": 4.18,
    "OCT": 4.09,
    "NOV": 4,
    "DEC": 4.45
  },
  {
    "LONG": -44.32,
    "LAT": -23,
    "CIDADE": "Angra dos Reis",
    "UF": "RIO DE JANEIRO",
    "anual": 4.56,
    "JAN": 4.84,
    "FEB": 5.39,
    "MAR": 4.81,
    "APR": 4.86,
    "MAY": 4.3,
    "JUN": 4.18,
    "JUL": 4.12,
    "AUG": 4.77,
    "SEP": 4.31,
    "OCT": 4.3,
    "NOV": 4.21,
    "DEC": 4.6
  },
  {
    "LONG": -44.04,
    "LAT": -22.96,
    "CIDADE": "Mangaratiba",
    "UF": "RIO DE JANEIRO",
    "anual": 4.61,
    "JAN": 4.92,
    "FEB": 5.56,
    "MAR": 4.86,
    "APR": 4.86,
    "MAY": 4.28,
    "JUN": 4.16,
    "JUL": 4.09,
    "AUG": 4.81,
    "SEP": 4.38,
    "OCT": 4.43,
    "NOV": 4.26,
    "DEC": 4.72
  },
  {
    "LONG": -42.03,
    "LAT": -22.98,
    "CIDADE": "Arraial do Cabo",
    "UF": "RIO DE JANEIRO",
    "anual": 5.24,
    "JAN": 5.76,
    "FEB": 6.4,
    "MAR": 5.6,
    "APR": 5.34,
    "MAY": 4.63,
    "JUN": 4.43,
    "JUL": 4.44,
    "AUG": 5.21,
    "SEP": 5.03,
    "OCT": 5.24,
    "NOV": 5.16,
    "DEC": 5.62
  },
  {
    "LONG": -43.78,
    "LAT": -22.86,
    "CIDADE": "Itaguaí",
    "UF": "RIO DE JANEIRO",
    "anual": 4.9,
    "JAN": 5.3,
    "FEB": 5.9,
    "MAR": 5.2,
    "APR": 5.13,
    "MAY": 4.46,
    "JUN": 4.29,
    "JUL": 4.26,
    "AUG": 5,
    "SEP": 4.64,
    "OCT": 4.81,
    "NOV": 4.67,
    "DEC": 5.15
  },
  {
    "LONG": -4.32,
    "LAT": -22.91,
    "CIDADE": "Rio de Janeiro",
    "UF": "RIO DE JANEIRO",
    "anual": 4.93,
    "JAN": 5.41,
    "FEB": 5.92,
    "MAR": 5.25,
    "APR": 4.98,
    "MAY": 4.43,
    "JUN": 4.33,
    "JUL": 4.22,
    "AUG": 4.99,
    "SEP": 4.75,
    "OCT": 4.98,
    "NOV": 4.71,
    "DEC": 5.23
  },
  {
    "LONG": -43.1,
    "LAT": -22.88,
    "CIDADE": "Niterói",
    "UF": "RIO DE JANEIRO",
    "anual": 4.99,
    "JAN": 5.54,
    "FEB": 6.04,
    "MAR": 5.36,
    "APR": 5.1,
    "MAY": 4.44,
    "JUN": 4.32,
    "JUL": 4.22,
    "AUG": 5.01,
    "SEP": 4.72,
    "OCT": 4.97,
    "NOV": 4.78,
    "DEC": 5.34
  },
  {
    "LONG": -4.28,
    "LAT": -22.94,
    "CIDADE": "Maricá",
    "UF": "RIO DE JANEIRO",
    "anual": 4.98,
    "JAN": 5.55,
    "FEB": 6.07,
    "MAR": 5.32,
    "APR": 5.05,
    "MAY": 4.44,
    "JUN": 4.33,
    "JUL": 4.27,
    "AUG": 5,
    "SEP": 4.76,
    "OCT": 4.94,
    "NOV": 4.78,
    "DEC": 5.32
  },
  {
    "LONG": -42.51,
    "LAT": -22.93,
    "CIDADE": "Saquarema",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.54,
    "FEB": 6.13,
    "MAR": 5.31,
    "APR": 5.07,
    "MAY": 4.43,
    "JUN": 4.37,
    "JUL": 4.27,
    "AUG": 4.95,
    "SEP": 4.7,
    "OCT": 4.92,
    "NOV": 4.69,
    "DEC": 5.22
  },
  {
    "LONG": -4.23,
    "LAT": -22.87,
    "CIDADE": "Araruama",
    "UF": "RIO DE JANEIRO",
    "anual": 5.07,
    "JAN": 5.61,
    "FEB": 6.16,
    "MAR": 5.45,
    "APR": 5.17,
    "MAY": 4.51,
    "JUN": 4.4,
    "JUL": 4.34,
    "AUG": 5.05,
    "SEP": 4.83,
    "OCT": 5.03,
    "NOV": 4.85,
    "DEC": 5.38
  },
  {
    "LONG": -4.2,
    "LAT": -22.89,
    "CIDADE": "Cabo Frio",
    "UF": "RIO DE JANEIRO",
    "anual": 5.12,
    "JAN": 5.7,
    "FEB": 6.18,
    "MAR": 5.46,
    "APR": 5.18,
    "MAY": 4.57,
    "JUN": 4.35,
    "JUL": 4.33,
    "AUG": 5.1,
    "SEP": 4.93,
    "OCT": 5.16,
    "NOV": 5.01,
    "DEC": 5.5
  },
  {
    "LONG": -43.72,
    "LAT": -22.75,
    "CIDADE": "Seropédica",
    "UF": "RIO DE JANEIRO",
    "anual": 4.89,
    "JAN": 5.31,
    "FEB": 5.93,
    "MAR": 5.18,
    "APR": 5.07,
    "MAY": 4.43,
    "JUN": 4.28,
    "JUL": 4.27,
    "AUG": 5.01,
    "SEP": 4.66,
    "OCT": 4.79,
    "NOV": 4.6,
    "DEC": 5.13
  },
  {
    "LONG": -43.4,
    "LAT": -22.76,
    "CIDADE": "Belford Roxo",
    "UF": "RIO DE JANEIRO",
    "anual": 4.82,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.08,
    "APR": 4.93,
    "MAY": 4.42,
    "JUN": 4.31,
    "JUL": 4.2,
    "AUG": 4.94,
    "SEP": 4.65,
    "OCT": 4.79,
    "NOV": 4.53,
    "DEC": 5.01
  },
  {
    "LONG": -43.42,
    "LAT": -22.81,
    "CIDADE": "Nilópolis",
    "UF": "RIO DE JANEIRO",
    "anual": 4.82,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.08,
    "APR": 4.93,
    "MAY": 4.42,
    "JUN": 4.31,
    "JUL": 4.2,
    "AUG": 4.94,
    "SEP": 4.65,
    "OCT": 4.79,
    "NOV": 4.53,
    "DEC": 5.01
  },
  {
    "LONG": -43.46,
    "LAT": -22.76,
    "CIDADE": "Nova Iguaçu",
    "UF": "RIO DE JANEIRO",
    "anual": 4.82,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.08,
    "APR": 4.93,
    "MAY": 4.42,
    "JUN": 4.31,
    "JUL": 4.2,
    "AUG": 4.94,
    "SEP": 4.65,
    "OCT": 4.79,
    "NOV": 4.53,
    "DEC": 5.01
  },
  {
    "LONG": -43.46,
    "LAT": -22.8,
    "CIDADE": "Mesquita",
    "UF": "RIO DE JANEIRO",
    "anual": 4.82,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.08,
    "APR": 4.93,
    "MAY": 4.42,
    "JUN": 4.31,
    "JUL": 4.2,
    "AUG": 4.94,
    "SEP": 4.65,
    "OCT": 4.79,
    "NOV": 4.53,
    "DEC": 5.01
  },
  {
    "LONG": -43.31,
    "LAT": -22.79,
    "CIDADE": "Duque de Caxias",
    "UF": "RIO DE JANEIRO",
    "anual": 4.91,
    "JAN": 5.35,
    "FEB": 5.85,
    "MAR": 5.21,
    "APR": 4.98,
    "MAY": 4.47,
    "JUN": 4.38,
    "JUL": 4.26,
    "AUG": 5.01,
    "SEP": 4.74,
    "OCT": 4.9,
    "NOV": 4.64,
    "DEC": 5.08
  },
  {
    "LONG": -43.37,
    "LAT": -22.81,
    "CIDADE": "São João de Meriti",
    "UF": "RIO DE JANEIRO",
    "anual": 4.91,
    "JAN": 5.35,
    "FEB": 5.85,
    "MAR": 5.21,
    "APR": 4.98,
    "MAY": 4.47,
    "JUN": 4.38,
    "JUL": 4.26,
    "AUG": 5.01,
    "SEP": 4.74,
    "OCT": 4.9,
    "NOV": 4.64,
    "DEC": 5.08
  },
  {
    "LONG": -43.06,
    "LAT": -22.83,
    "CIDADE": "São Gonçalo",
    "UF": "RIO DE JANEIRO",
    "anual": 4.96,
    "JAN": 5.5,
    "FEB": 6.02,
    "MAR": 5.3,
    "APR": 5.09,
    "MAY": 4.44,
    "JUN": 4.35,
    "JUL": 4.27,
    "AUG": 5,
    "SEP": 4.7,
    "OCT": 4.91,
    "NOV": 4.68,
    "DEC": 5.23
  },
  {
    "LONG": -42.86,
    "LAT": -2.28,
    "CIDADE": "Itaboraí",
    "UF": "RIO DE JANEIRO",
    "anual": 4.99,
    "JAN": 5.54,
    "FEB": 6.04,
    "MAR": 5.29,
    "APR": 5.11,
    "MAY": 4.47,
    "JUN": 4.4,
    "JUL": 4.32,
    "AUG": 5.03,
    "SEP": 4.78,
    "OCT": 4.95,
    "NOV": 4.72,
    "DEC": 5.25
  },
  {
    "LONG": -42.23,
    "LAT": -22.85,
    "CIDADE": "Iguaba Grande",
    "UF": "RIO DE JANEIRO",
    "anual": 5.07,
    "JAN": 5.62,
    "FEB": 6.18,
    "MAR": 5.4,
    "APR": 5.16,
    "MAY": 4.52,
    "JUN": 4.47,
    "JUL": 4.38,
    "AUG": 5.09,
    "SEP": 4.87,
    "OCT": 5.05,
    "NOV": 4.78,
    "DEC": 5.35
  },
  {
    "LONG": -4.21,
    "LAT": -22.84,
    "CIDADE": "São Pedro da Aldeia",
    "UF": "RIO DE JANEIRO",
    "anual": 5.1,
    "JAN": 5.65,
    "FEB": 6.23,
    "MAR": 5.42,
    "APR": 5.18,
    "MAY": 4.56,
    "JUN": 4.45,
    "JUL": 4.39,
    "AUG": 5.12,
    "SEP": 4.91,
    "OCT": 5.08,
    "NOV": 4.8,
    "DEC": 5.39
  },
  {
    "LONG": -41.88,
    "LAT": -22.75,
    "CIDADE": "Armação dos Búzios",
    "UF": "RIO DE JANEIRO",
    "anual": 5.28,
    "JAN": 5.82,
    "FEB": 6.45,
    "MAR": 5.6,
    "APR": 5.44,
    "MAY": 4.7,
    "JUN": 4.47,
    "JUL": 4.48,
    "AUG": 5.31,
    "SEP": 5.08,
    "OCT": 5.31,
    "NOV": 5.09,
    "DEC": 5.62
  },
  {
    "LONG": -44.14,
    "LAT": -22.72,
    "CIDADE": "Rio Claro",
    "UF": "RIO DE JANEIRO",
    "anual": 4.82,
    "JAN": 5.04,
    "FEB": 5.73,
    "MAR": 5.02,
    "APR": 4.87,
    "MAY": 4.36,
    "JUN": 4.29,
    "JUL": 4.28,
    "AUG": 5.04,
    "SEP": 4.78,
    "OCT": 4.8,
    "NOV": 4.57,
    "DEC": 5.07
  },
  {
    "LONG": -43.55,
    "LAT": -22.71,
    "CIDADE": "Queimados",
    "UF": "RIO DE JANEIRO",
    "anual": 4.89,
    "JAN": 5.27,
    "FEB": 5.84,
    "MAR": 5.2,
    "APR": 5.06,
    "MAY": 4.47,
    "JUN": 4.37,
    "JUL": 4.28,
    "AUG": 5.02,
    "SEP": 4.7,
    "OCT": 4.8,
    "NOV": 4.57,
    "DEC": 5.05
  },
  {
    "LONG": -43.03,
    "LAT": -22.66,
    "CIDADE": "Magé",
    "UF": "RIO DE JANEIRO",
    "anual": 4.88,
    "JAN": 5.35,
    "FEB": 5.88,
    "MAR": 5.18,
    "APR": 5.06,
    "MAY": 4.42,
    "JUN": 4.37,
    "JUL": 4.28,
    "AUG": 4.98,
    "SEP": 4.66,
    "OCT": 4.78,
    "NOV": 4.51,
    "DEC": 5.03
  },
  {
    "LONG": -42.72,
    "LAT": -22.74,
    "CIDADE": "Tanguá",
    "UF": "RIO DE JANEIRO",
    "anual": 4.96,
    "JAN": 5.44,
    "FEB": 6.02,
    "MAR": 5.23,
    "APR": 5.08,
    "MAY": 4.48,
    "JUN": 4.46,
    "JUL": 4.35,
    "AUG": 5.04,
    "SEP": 4.77,
    "OCT": 4.86,
    "NOV": 4.62,
    "DEC": 5.12
  },
  {
    "LONG": -42.63,
    "LAT": -22.72,
    "CIDADE": "Rio Bonito",
    "UF": "RIO DE JANEIRO",
    "anual": 4.94,
    "JAN": 5.41,
    "FEB": 6,
    "MAR": 5.19,
    "APR": 5.05,
    "MAY": 4.49,
    "JUN": 4.46,
    "JUL": 4.36,
    "AUG": 5.04,
    "SEP": 4.78,
    "OCT": 4.85,
    "NOV": 4.59,
    "DEC": 5.09
  },
  {
    "LONG": -42.4,
    "LAT": -22.66,
    "CIDADE": "Silva Jardim",
    "UF": "RIO DE JANEIRO",
    "anual": 4.99,
    "JAN": 5.5,
    "FEB": 6.09,
    "MAR": 5.28,
    "APR": 5.1,
    "MAY": 4.52,
    "JUN": 4.44,
    "JUL": 4.35,
    "AUG": 5.09,
    "SEP": 4.81,
    "OCT": 4.93,
    "NOV": 4.61,
    "DEC": 5.13
  },
  {
    "LONG": -43.91,
    "LAT": -2.26,
    "CIDADE": "Piraí",
    "UF": "RIO DE JANEIRO",
    "anual": 4.86,
    "JAN": 5.17,
    "FEB": 5.81,
    "MAR": 5.12,
    "APR": 4.95,
    "MAY": 4.37,
    "JUN": 4.29,
    "JUL": 4.28,
    "AUG": 5.05,
    "SEP": 4.78,
    "OCT": 4.8,
    "NOV": 4.64,
    "DEC": 5.11
  },
  {
    "LONG": -43.71,
    "LAT": -22.61,
    "CIDADE": "Paracambi",
    "UF": "RIO DE JANEIRO",
    "anual": 4.86,
    "JAN": 5.21,
    "FEB": 5.85,
    "MAR": 5.16,
    "APR": 4.99,
    "MAY": 4.41,
    "JUN": 4.32,
    "JUL": 4.27,
    "AUG": 5.03,
    "SEP": 4.74,
    "OCT": 4.78,
    "NOV": 4.55,
    "DEC": 5.02
  },
  {
    "LONG": -43.66,
    "LAT": -2.26,
    "CIDADE": "Japeri",
    "UF": "RIO DE JANEIRO",
    "anual": 4.84,
    "JAN": 5.16,
    "FEB": 5.83,
    "MAR": 5.12,
    "APR": 5,
    "MAY": 4.42,
    "JUN": 4.36,
    "JUL": 4.28,
    "AUG": 4.99,
    "SEP": 4.69,
    "OCT": 4.72,
    "NOV": 4.51,
    "DEC": 4.96
  },
  {
    "LONG": -44.57,
    "LAT": -22.49,
    "CIDADE": "Itatiaia",
    "UF": "RIO DE JANEIRO",
    "anual": 4.67,
    "JAN": 4.72,
    "FEB": 5.16,
    "MAR": 4.76,
    "APR": 4.83,
    "MAY": 4.34,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 5.13,
    "SEP": 4.75,
    "OCT": 4.6,
    "NOV": 4.35,
    "DEC": 4.78
  },
  {
    "LONG": -44.45,
    "LAT": -2.25,
    "CIDADE": "Resende",
    "UF": "RIO DE JANEIRO",
    "anual": 4.78,
    "JAN": 4.88,
    "FEB": 5.43,
    "MAR": 4.96,
    "APR": 4.92,
    "MAY": 4.4,
    "JUN": 4.32,
    "JUL": 4.31,
    "AUG": 5.18,
    "SEP": 4.81,
    "OCT": 4.72,
    "NOV": 4.5,
    "DEC": 4.93
  },
  {
    "LONG": -44.18,
    "LAT": -22.55,
    "CIDADE": "Barra Mansa",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.08,
    "FEB": 5.7,
    "MAR": 5.09,
    "APR": 4.95,
    "MAY": 4.41,
    "JUN": 4.35,
    "JUL": 4.31,
    "AUG": 5.15,
    "SEP": 4.89,
    "OCT": 4.85,
    "NOV": 4.62,
    "DEC": 5.08
  },
  {
    "LONG": -44.1,
    "LAT": -22.52,
    "CIDADE": "Volta Redonda",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.08,
    "FEB": 5.7,
    "MAR": 5.09,
    "APR": 4.95,
    "MAY": 4.41,
    "JUN": 4.35,
    "JUL": 4.31,
    "AUG": 5.15,
    "SEP": 4.89,
    "OCT": 4.85,
    "NOV": 4.62,
    "DEC": 5.08
  },
  {
    "LONG": -44,
    "LAT": -22.52,
    "CIDADE": "Pinheiral",
    "UF": "RIO DE JANEIRO",
    "anual": 4.89,
    "JAN": 5.16,
    "FEB": 5.74,
    "MAR": 5.12,
    "APR": 4.98,
    "MAY": 4.41,
    "JUN": 4.36,
    "JUL": 4.32,
    "AUG": 5.15,
    "SEP": 4.86,
    "OCT": 4.84,
    "NOV": 4.65,
    "DEC": 5.1
  },
  {
    "LONG": -43.83,
    "LAT": -2.25,
    "CIDADE": "Barra do Piraí",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.21,
    "FEB": 5.78,
    "MAR": 5.13,
    "APR": 4.96,
    "MAY": 4.4,
    "JUN": 4.29,
    "JUL": 4.3,
    "AUG": 5.02,
    "SEP": 4.81,
    "OCT": 4.79,
    "NOV": 4.63,
    "DEC": 5.09
  },
  {
    "LONG": -43.73,
    "LAT": -2.25,
    "CIDADE": "Mendes",
    "UF": "RIO DE JANEIRO",
    "anual": 4.92,
    "JAN": 5.27,
    "FEB": 5.84,
    "MAR": 5.17,
    "APR": 5,
    "MAY": 4.43,
    "JUN": 4.38,
    "JUL": 4.37,
    "AUG": 5.08,
    "SEP": 4.86,
    "OCT": 4.84,
    "NOV": 4.69,
    "DEC": 5.1
  },
  {
    "LONG": -43.68,
    "LAT": -22.55,
    "CIDADE": "Engenheiro Paulo de Frontin",
    "UF": "RIO DE JANEIRO",
    "anual": 4.9,
    "JAN": 5.26,
    "FEB": 5.85,
    "MAR": 5.15,
    "APR": 4.98,
    "MAY": 4.4,
    "JUN": 4.37,
    "JUL": 4.36,
    "AUG": 5.04,
    "SEP": 4.82,
    "OCT": 4.82,
    "NOV": 4.64,
    "DEC": 5.08
  },
  {
    "LONG": -43.48,
    "LAT": -22.46,
    "CIDADE": "Miguel Pereira",
    "UF": "RIO DE JANEIRO",
    "anual": 4.91,
    "JAN": 5.2,
    "FEB": 5.87,
    "MAR": 5.14,
    "APR": 4.94,
    "MAY": 4.45,
    "JUN": 4.38,
    "JUL": 4.42,
    "AUG": 5.07,
    "SEP": 4.87,
    "OCT": 4.88,
    "NOV": 4.62,
    "DEC": 5.1
  },
  {
    "LONG": -4.32,
    "LAT": -22.52,
    "CIDADE": "Petrópolis",
    "UF": "RIO DE JANEIRO",
    "anual": 4.72,
    "JAN": 4.99,
    "FEB": 5.54,
    "MAR": 4.85,
    "APR": 4.72,
    "MAY": 4.25,
    "JUN": 4.3,
    "JUL": 4.34,
    "AUG": 5.02,
    "SEP": 4.8,
    "OCT": 4.71,
    "NOV": 4.38,
    "DEC": 4.79
  },
  {
    "LONG": -42.99,
    "LAT": -22.54,
    "CIDADE": "Guapimirim",
    "UF": "RIO DE JANEIRO",
    "anual": 4.53,
    "JAN": 4.75,
    "FEB": 5.44,
    "MAR": 4.67,
    "APR": 4.57,
    "MAY": 4.21,
    "JUN": 4.19,
    "JUL": 4.16,
    "AUG": 4.81,
    "SEP": 4.6,
    "OCT": 4.41,
    "NOV": 4.04,
    "DEC": 4.5
  },
  {
    "LONG": -42.65,
    "LAT": -22.47,
    "CIDADE": "Cachoeiras de Macacu",
    "UF": "RIO DE JANEIRO",
    "anual": 4.8,
    "JAN": 5.12,
    "FEB": 5.74,
    "MAR": 5,
    "APR": 4.93,
    "MAY": 4.39,
    "JUN": 4.47,
    "JUL": 4.35,
    "AUG": 5.05,
    "SEP": 4.76,
    "OCT": 4.67,
    "NOV": 4.29,
    "DEC": 4.8
  },
  {
    "LONG": -4.22,
    "LAT": -22.48,
    "CIDADE": "Casimiro de Abreu",
    "UF": "RIO DE JANEIRO",
    "anual": 4.8,
    "JAN": 5.19,
    "FEB": 5.8,
    "MAR": 4.98,
    "APR": 4.93,
    "MAY": 4.43,
    "JUN": 4.39,
    "JUL": 4.29,
    "AUG": 5.02,
    "SEP": 4.73,
    "OCT": 4.73,
    "NOV": 4.27,
    "DEC": 4.79
  },
  {
    "LONG": -41.95,
    "LAT": -22.52,
    "CIDADE": "Rio das Ostras",
    "UF": "RIO DE JANEIRO",
    "anual": 5,
    "JAN": 5.57,
    "FEB": 6.12,
    "MAR": 5.3,
    "APR": 5.1,
    "MAY": 4.54,
    "JUN": 4.41,
    "JUL": 4.39,
    "AUG": 5.08,
    "SEP": 4.83,
    "OCT": 4.93,
    "NOV": 4.59,
    "DEC": 5.18
  },
  {
    "LONG": -44.26,
    "LAT": -2.24,
    "CIDADE": "Quatis",
    "UF": "RIO DE JANEIRO",
    "anual": 4.83,
    "JAN": 4.97,
    "FEB": 5.55,
    "MAR": 5,
    "APR": 4.93,
    "MAY": 4.43,
    "JUN": 4.39,
    "JUL": 4.34,
    "AUG": 5.2,
    "SEP": 4.88,
    "OCT": 4.76,
    "NOV": 4.52,
    "DEC": 4.95
  },
  {
    "LONG": -44.3,
    "LAT": -2.24,
    "CIDADE": "Porto Real",
    "UF": "RIO DE JANEIRO",
    "anual": 4.83,
    "JAN": 4.97,
    "FEB": 5.55,
    "MAR": 5,
    "APR": 4.93,
    "MAY": 4.43,
    "JUN": 4.39,
    "JUL": 4.34,
    "AUG": 5.2,
    "SEP": 4.88,
    "OCT": 4.76,
    "NOV": 4.52,
    "DEC": 4.95
  },
  {
    "LONG": -43.67,
    "LAT": -22.41,
    "CIDADE": "Vassouras",
    "UF": "RIO DE JANEIRO",
    "anual": 4.91,
    "JAN": 5.23,
    "FEB": 5.82,
    "MAR": 5.17,
    "APR": 4.99,
    "MAY": 4.4,
    "JUN": 4.34,
    "JUL": 4.38,
    "AUG": 5.06,
    "SEP": 4.86,
    "OCT": 4.85,
    "NOV": 4.68,
    "DEC": 5.12
  },
  {
    "LONG": -43.43,
    "LAT": -22.43,
    "CIDADE": "Paty do Alferes",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.27,
    "FEB": 5.91,
    "MAR": 5.27,
    "APR": 5.02,
    "MAY": 4.45,
    "JUN": 4.34,
    "JUL": 4.43,
    "AUG": 5.09,
    "SEP": 4.96,
    "OCT": 4.99,
    "NOV": 4.74,
    "DEC": 5.2
  },
  {
    "LONG": -42.98,
    "LAT": -22.42,
    "CIDADE": "Teresópolis",
    "UF": "RIO DE JANEIRO",
    "anual": 4.66,
    "JAN": 4.91,
    "FEB": 5.53,
    "MAR": 4.83,
    "APR": 4.65,
    "MAY": 4.23,
    "JUN": 4.22,
    "JUL": 4.26,
    "AUG": 4.9,
    "SEP": 4.77,
    "OCT": 4.64,
    "NOV": 4.25,
    "DEC": 4.74
  },
  {
    "LONG": -41.79,
    "LAT": -22.38,
    "CIDADE": "Macaé",
    "UF": "RIO DE JANEIRO",
    "anual": 5.14,
    "JAN": 5.69,
    "FEB": 6.28,
    "MAR": 5.46,
    "APR": 5.31,
    "MAY": 4.69,
    "JUN": 4.51,
    "JUL": 4.47,
    "AUG": 5.2,
    "SEP": 4.93,
    "OCT": 5.02,
    "NOV": 4.76,
    "DEC": 5.37
  },
  {
    "LONG": -42.54,
    "LAT": -22.29,
    "CIDADE": "Nova Friburgo",
    "UF": "RIO DE JANEIRO",
    "anual": 4.67,
    "JAN": 4.98,
    "FEB": 5.57,
    "MAR": 4.85,
    "APR": 4.68,
    "MAY": 4.22,
    "JUN": 4.17,
    "JUL": 4.31,
    "AUG": 4.91,
    "SEP": 4.76,
    "OCT": 4.65,
    "NOV": 4.25,
    "DEC": 4.77
  },
  {
    "LONG": -43.71,
    "LAT": -2.22,
    "CIDADE": "Valença",
    "UF": "RIO DE JANEIRO",
    "anual": 4.89,
    "JAN": 5.07,
    "FEB": 5.73,
    "MAR": 5.13,
    "APR": 4.96,
    "MAY": 4.45,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 5.16,
    "SEP": 4.93,
    "OCT": 4.81,
    "NOV": 4.58,
    "DEC": 5.05
  },
  {
    "LONG": -4.36,
    "LAT": -22.17,
    "CIDADE": "Rio das Flores",
    "UF": "RIO DE JANEIRO",
    "anual": 4.84,
    "JAN": 5.18,
    "FEB": 5.73,
    "MAR": 5.11,
    "APR": 4.89,
    "MAY": 4.38,
    "JUN": 4.3,
    "JUL": 4.36,
    "AUG": 5.02,
    "SEP": 4.87,
    "OCT": 4.81,
    "NOV": 4.53,
    "DEC": 4.96
  },
  {
    "LONG": -43.3,
    "LAT": -22.16,
    "CIDADE": "Paraíba do Sul",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.3,
    "FEB": 5.84,
    "MAR": 5.29,
    "APR": 5.05,
    "MAY": 4.44,
    "JUN": 4.31,
    "JUL": 4.45,
    "AUG": 5.14,
    "SEP": 5,
    "OCT": 4.94,
    "NOV": 4.67,
    "DEC": 5.16
  },
  {
    "LONG": -4.31,
    "LAT": -22.24,
    "CIDADE": "Areal",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.29,
    "FEB": 5.85,
    "MAR": 5.32,
    "APR": 5.1,
    "MAY": 4.45,
    "JUN": 4.34,
    "JUL": 4.44,
    "AUG": 5.13,
    "SEP": 4.99,
    "OCT": 4.94,
    "NOV": 4.66,
    "DEC": 5.16
  },
  {
    "LONG": -42.93,
    "LAT": -2.22,
    "CIDADE": "São José do Vale do Rio Preto",
    "UF": "RIO DE JANEIRO",
    "anual": 4.85,
    "JAN": 5.19,
    "FEB": 5.75,
    "MAR": 5.17,
    "APR": 4.87,
    "MAY": 4.36,
    "JUN": 4.26,
    "JUL": 4.38,
    "AUG": 5.03,
    "SEP": 4.88,
    "OCT": 4.85,
    "NOV": 4.5,
    "DEC": 5.04
  },
  {
    "LONG": -42.43,
    "LAT": -2.22,
    "CIDADE": "Bom Jardim",
    "UF": "RIO DE JANEIRO",
    "anual": 4.75,
    "JAN": 5.16,
    "FEB": 5.69,
    "MAR": 5.01,
    "APR": 4.79,
    "MAY": 4.27,
    "JUN": 4.18,
    "JUL": 4.33,
    "AUG": 4.89,
    "SEP": 4.77,
    "OCT": 4.65,
    "NOV": 4.33,
    "DEC": 4.88
  },
  {
    "LONG": -41.66,
    "LAT": -22.18,
    "CIDADE": "Carapebus",
    "UF": "RIO DE JANEIRO",
    "anual": 5.08,
    "JAN": 5.66,
    "FEB": 6.19,
    "MAR": 5.34,
    "APR": 5.19,
    "MAY": 4.64,
    "JUN": 4.5,
    "JUL": 4.47,
    "AUG": 5.15,
    "SEP": 4.95,
    "OCT": 4.95,
    "NOV": 4.67,
    "DEC": 5.26
  },
  {
    "LONG": -43.22,
    "LAT": -2.21,
    "CIDADE": "Três Rios",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.16,
    "FEB": 5.72,
    "MAR": 5.17,
    "APR": 4.94,
    "MAY": 4.36,
    "JUN": 4.3,
    "JUL": 4.42,
    "AUG": 5.04,
    "SEP": 4.9,
    "OCT": 4.84,
    "NOV": 4.55,
    "DEC": 5.04
  },
  {
    "LONG": -42.52,
    "LAT": -22.05,
    "CIDADE": "Duas Barras",
    "UF": "RIO DE JANEIRO",
    "anual": 4.8,
    "JAN": 5.15,
    "FEB": 5.77,
    "MAR": 5.15,
    "APR": 4.87,
    "MAY": 4.33,
    "JUN": 4.23,
    "JUL": 4.33,
    "AUG": 4.92,
    "SEP": 4.82,
    "OCT": 4.72,
    "NOV": 4.31,
    "DEC": 4.97
  },
  {
    "LONG": -4.21,
    "LAT": -22.06,
    "CIDADE": "Trajano de Moraes",
    "UF": "RIO DE JANEIRO",
    "anual": 4.79,
    "JAN": 5.27,
    "FEB": 5.86,
    "MAR": 5.06,
    "APR": 4.78,
    "MAY": 4.31,
    "JUN": 4.22,
    "JUL": 4.27,
    "AUG": 4.88,
    "SEP": 4.8,
    "OCT": 4.65,
    "NOV": 4.35,
    "DEC": 4.98
  },
  {
    "LONG": -41.87,
    "LAT": -22.08,
    "CIDADE": "Conceição de Macabu",
    "UF": "RIO DE JANEIRO",
    "anual": 4.86,
    "JAN": 5.28,
    "FEB": 5.91,
    "MAR": 5.17,
    "APR": 4.98,
    "MAY": 4.52,
    "JUN": 4.4,
    "JUL": 4.36,
    "AUG": 4.96,
    "SEP": 4.77,
    "OCT": 4.63,
    "NOV": 4.36,
    "DEC": 4.98
  },
  {
    "LONG": -41.47,
    "LAT": -22.1,
    "CIDADE": "Quissamã",
    "UF": "RIO DE JANEIRO",
    "anual": 5.15,
    "JAN": 5.74,
    "FEB": 6.3,
    "MAR": 5.45,
    "APR": 5.23,
    "MAY": 4.65,
    "JUN": 4.48,
    "JUL": 4.51,
    "AUG": 5.23,
    "SEP": 5,
    "OCT": 5.05,
    "NOV": 4.77,
    "DEC": 5.4
  },
  {
    "LONG": -43.21,
    "LAT": -22.04,
    "CIDADE": "Comendador Levy Gasparian",
    "UF": "RIO DE JANEIRO",
    "anual": 4.8,
    "JAN": 5.12,
    "FEB": 5.62,
    "MAR": 5.07,
    "APR": 4.86,
    "MAY": 4.29,
    "JUN": 4.28,
    "JUL": 4.4,
    "AUG": 4.96,
    "SEP": 4.86,
    "OCT": 4.77,
    "NOV": 4.45,
    "DEC": 4.95
  },
  {
    "LONG": -42.91,
    "LAT": -22,
    "CIDADE": "Sapucaia",
    "UF": "RIO DE JANEIRO",
    "anual": 4.92,
    "JAN": 5.23,
    "FEB": 5.75,
    "MAR": 5.27,
    "APR": 4.97,
    "MAY": 4.38,
    "JUN": 4.31,
    "JUL": 4.44,
    "AUG": 5.07,
    "SEP": 4.95,
    "OCT": 4.9,
    "NOV": 4.62,
    "DEC": 5.1
  },
  {
    "LONG": -42.68,
    "LAT": -22.05,
    "CIDADE": "Sumidouro",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.34,
    "FEB": 5.92,
    "MAR": 5.39,
    "APR": 5.15,
    "MAY": 4.47,
    "JUN": 4.3,
    "JUL": 4.45,
    "AUG": 5.09,
    "SEP": 4.95,
    "OCT": 4.85,
    "NOV": 4.54,
    "DEC": 5.16
  },
  {
    "LONG": -42.37,
    "LAT": -22.03,
    "CIDADE": "Cordeiro",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.3,
    "FEB": 5.86,
    "MAR": 5.19,
    "APR": 4.9,
    "MAY": 4.4,
    "JUN": 4.28,
    "JUL": 4.42,
    "AUG": 4.99,
    "SEP": 4.86,
    "OCT": 4.74,
    "NOV": 4.46,
    "DEC": 5.05
  },
  {
    "LONG": -42.37,
    "LAT": -21.98,
    "CIDADE": "Cantagalo",
    "UF": "RIO DE JANEIRO",
    "anual": 4.87,
    "JAN": 5.3,
    "FEB": 5.86,
    "MAR": 5.19,
    "APR": 4.9,
    "MAY": 4.4,
    "JUN": 4.28,
    "JUL": 4.42,
    "AUG": 4.99,
    "SEP": 4.86,
    "OCT": 4.74,
    "NOV": 4.46,
    "DEC": 5.05
  },
  {
    "LONG": -42.25,
    "LAT": -21.98,
    "CIDADE": "Macuco",
    "UF": "RIO DE JANEIRO",
    "anual": 4.94,
    "JAN": 5.4,
    "FEB": 5.92,
    "MAR": 5.3,
    "APR": 4.99,
    "MAY": 4.45,
    "JUN": 4.33,
    "JUL": 4.45,
    "AUG": 5.04,
    "SEP": 4.91,
    "OCT": 4.82,
    "NOV": 4.55,
    "DEC": 5.14
  },
  {
    "LONG": -42.13,
    "LAT": -21.96,
    "CIDADE": "São Sebastião do Alto",
    "UF": "RIO DE JANEIRO",
    "anual": 4.98,
    "JAN": 5.43,
    "FEB": 5.99,
    "MAR": 5.35,
    "APR": 5.05,
    "MAY": 4.5,
    "JUN": 4.36,
    "JUL": 4.45,
    "AUG": 5.07,
    "SEP": 4.96,
    "OCT": 4.85,
    "NOV": 4.62,
    "DEC": 5.19
  },
  {
    "LONG": -42.01,
    "LAT": -21.96,
    "CIDADE": "Santa Maria Madalena",
    "UF": "RIO DE JANEIRO",
    "anual": 4.94,
    "JAN": 5.37,
    "FEB": 6,
    "MAR": 5.29,
    "APR": 4.93,
    "MAY": 4.46,
    "JUN": 4.32,
    "JUL": 4.38,
    "AUG": 5.02,
    "SEP": 4.93,
    "OCT": 4.83,
    "NOV": 4.56,
    "DEC": 5.14
  },
  {
    "LONG": -4.26,
    "LAT": -21.93,
    "CIDADE": "Carmo",
    "UF": "RIO DE JANEIRO",
    "anual": 5,
    "JAN": 5.35,
    "FEB": 5.94,
    "MAR": 5.42,
    "APR": 5.14,
    "MAY": 4.5,
    "JUN": 4.35,
    "JUL": 4.48,
    "AUG": 5.12,
    "SEP": 5.01,
    "OCT": 4.88,
    "NOV": 4.57,
    "DEC": 5.18
  },
  {
    "LONG": -41.32,
    "LAT": -21.76,
    "CIDADE": "Campos dos Goytacazes",
    "UF": "RIO DE JANEIRO",
    "anual": 5.11,
    "JAN": 5.67,
    "FEB": 6.24,
    "MAR": 5.44,
    "APR": 5.13,
    "MAY": 4.63,
    "JUN": 4.51,
    "JUL": 4.48,
    "AUG": 5.14,
    "SEP": 5.01,
    "OCT": 4.98,
    "NOV": 4.75,
    "DEC": 5.35
  },
  {
    "LONG": -42.08,
    "LAT": -21.68,
    "CIDADE": "Itaocara",
    "UF": "RIO DE JANEIRO",
    "anual": 5.14,
    "JAN": 5.6,
    "FEB": 6.11,
    "MAR": 5.5,
    "APR": 5.16,
    "MAY": 4.66,
    "JUN": 4.5,
    "JUL": 4.54,
    "AUG": 5.2,
    "SEP": 5.16,
    "OCT": 5.1,
    "NOV": 4.82,
    "DEC": 5.35
  },
  {
    "LONG": -4.21,
    "LAT": -21.63,
    "CIDADE": "Aperibé",
    "UF": "RIO DE JANEIRO",
    "anual": 5.06,
    "JAN": 5.54,
    "FEB": 6.02,
    "MAR": 5.39,
    "APR": 5.07,
    "MAY": 4.54,
    "JUN": 4.39,
    "JUL": 4.49,
    "AUG": 5.09,
    "SEP": 5.13,
    "OCT": 5.04,
    "NOV": 4.7,
    "DEC": 5.34
  },
  {
    "LONG": -41.92,
    "LAT": -21.57,
    "CIDADE": "Cambuci",
    "UF": "RIO DE JANEIRO",
    "anual": 5.04,
    "JAN": 5.57,
    "FEB": 5.97,
    "MAR": 5.32,
    "APR": 5.07,
    "MAY": 4.6,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 5.09,
    "SEP": 5.08,
    "OCT": 4.94,
    "NOV": 4.62,
    "DEC": 5.21
  },
  {
    "LONG": -41.75,
    "LAT": -21.65,
    "CIDADE": "São Fidélis",
    "UF": "RIO DE JANEIRO",
    "anual": 5.1,
    "JAN": 5.62,
    "FEB": 6.08,
    "MAR": 5.4,
    "APR": 5.08,
    "MAY": 4.7,
    "JUN": 4.55,
    "JUL": 4.54,
    "AUG": 5.14,
    "SEP": 5.09,
    "OCT": 4.99,
    "NOV": 4.69,
    "DEC": 5.3
  },
  {
    "LONG": -41.04,
    "LAT": -21.64,
    "CIDADE": "São João da Barra",
    "UF": "RIO DE JANEIRO",
    "anual": 5.26,
    "JAN": 5.82,
    "FEB": 6.38,
    "MAR": 5.58,
    "APR": 5.25,
    "MAY": 4.7,
    "JUN": 4.56,
    "JUL": 4.57,
    "AUG": 5.34,
    "SEP": 5.25,
    "OCT": 5.26,
    "NOV": 4.94,
    "DEC": 5.52
  },
  {
    "LONG": -42.18,
    "LAT": -21.54,
    "CIDADE": "Santo Antônio de Pádua",
    "UF": "RIO DE JANEIRO",
    "anual": 5.05,
    "JAN": 5.46,
    "FEB": 5.99,
    "MAR": 5.4,
    "APR": 5.07,
    "MAY": 4.55,
    "JUN": 4.39,
    "JUL": 4.47,
    "AUG": 5.15,
    "SEP": 5.13,
    "OCT": 5.03,
    "NOV": 4.66,
    "DEC": 5.28
  },
  {
    "LONG": -41.62,
    "LAT": -21.49,
    "CIDADE": "Cardoso Moreira",
    "UF": "RIO DE JANEIRO",
    "anual": 5.12,
    "JAN": 5.68,
    "FEB": 6.13,
    "MAR": 5.42,
    "APR": 5.11,
    "MAY": 4.65,
    "JUN": 4.49,
    "JUL": 4.53,
    "AUG": 5.13,
    "SEP": 5.15,
    "OCT": 5.05,
    "NOV": 4.74,
    "DEC": 5.32
  },
  {
    "LONG": -41.11,
    "LAT": -21.48,
    "CIDADE": "São Francisco de Itabapoana",
    "UF": "RIO DE JANEIRO",
    "anual": 5.19,
    "JAN": 5.82,
    "FEB": 6.37,
    "MAR": 5.49,
    "APR": 5.11,
    "MAY": 4.67,
    "JUN": 4.52,
    "JUL": 4.52,
    "AUG": 5.28,
    "SEP": 5.16,
    "OCT": 5.14,
    "NOV": 4.82,
    "DEC": 5.39
  },
  {
    "LONG": -42.19,
    "LAT": -21.42,
    "CIDADE": "Miracema",
    "UF": "RIO DE JANEIRO",
    "anual": 5,
    "JAN": 5.36,
    "FEB": 5.9,
    "MAR": 5.3,
    "APR": 4.99,
    "MAY": 4.54,
    "JUN": 4.39,
    "JUL": 4.47,
    "AUG": 5.13,
    "SEP": 5.09,
    "OCT": 5,
    "NOV": 4.58,
    "DEC": 5.2
  },
  {
    "LONG": -41.94,
    "LAT": -21.36,
    "CIDADE": "São José de Ubá",
    "UF": "RIO DE JANEIRO",
    "anual": 5.08,
    "JAN": 5.53,
    "FEB": 6.07,
    "MAR": 5.36,
    "APR": 5.04,
    "MAY": 4.6,
    "JUN": 4.45,
    "JUL": 4.52,
    "AUG": 5.17,
    "SEP": 5.16,
    "OCT": 5.08,
    "NOV": 4.7,
    "DEC": 5.32
  },
  {
    "LONG": -41.7,
    "LAT": -21.43,
    "CIDADE": "Italva",
    "UF": "RIO DE JANEIRO",
    "anual": 5.13,
    "JAN": 5.64,
    "FEB": 6.13,
    "MAR": 5.44,
    "APR": 5.09,
    "MAY": 4.71,
    "JUN": 4.47,
    "JUL": 4.52,
    "AUG": 5.18,
    "SEP": 5.18,
    "OCT": 5.08,
    "NOV": 4.77,
    "DEC": 5.36
  },
  {
    "LONG": -42.12,
    "LAT": -21.21,
    "CIDADE": "Laje do Muriaé",
    "UF": "RIO DE JANEIRO",
    "anual": 5.11,
    "JAN": 5.52,
    "FEB": 6.03,
    "MAR": 5.37,
    "APR": 5.11,
    "MAY": 4.62,
    "JUN": 4.54,
    "JUL": 4.58,
    "AUG": 5.25,
    "SEP": 5.22,
    "OCT": 5.1,
    "NOV": 4.69,
    "DEC": 5.3
  },
  {
    "LONG": -4.19,
    "LAT": -21.2,
    "CIDADE": "Itaperuna",
    "UF": "RIO DE JANEIRO",
    "anual": 5.15,
    "JAN": 5.57,
    "FEB": 6.09,
    "MAR": 5.41,
    "APR": 5.17,
    "MAY": 4.66,
    "JUN": 4.54,
    "JUL": 4.6,
    "AUG": 5.29,
    "SEP": 5.25,
    "OCT": 5.11,
    "NOV": 4.74,
    "DEC": 5.37
  },
  {
    "LONG": -41.68,
    "LAT": -21.15,
    "CIDADE": "Bom Jesus do Itabapoana",
    "UF": "RIO DE JANEIRO",
    "anual": 5.07,
    "JAN": 5.5,
    "FEB": 5.97,
    "MAR": 5.3,
    "APR": 5.06,
    "MAY": 4.67,
    "JUN": 4.57,
    "JUL": 4.61,
    "AUG": 5.24,
    "SEP": 5.22,
    "OCT": 4.98,
    "NOV": 4.6,
    "DEC": 5.17
  },
  {
    "LONG": -4.2,
    "LAT": -20.97,
    "CIDADE": "Porciúncula",
    "UF": "RIO DE JANEIRO",
    "anual": 5.1,
    "JAN": 5.47,
    "FEB": 5.99,
    "MAR": 5.3,
    "APR": 5.08,
    "MAY": 4.64,
    "JUN": 4.63,
    "JUL": 4.69,
    "AUG": 5.34,
    "SEP": 5.28,
    "OCT": 5.05,
    "NOV": 4.6,
    "DEC": 5.18
  },
  {
    "LONG": -41.97,
    "LAT": -21.04,
    "CIDADE": "Natividade",
    "UF": "RIO DE JANEIRO",
    "anual": 5.13,
    "JAN": 5.48,
    "FEB": 6,
    "MAR": 5.33,
    "APR": 5.16,
    "MAY": 4.72,
    "JUN": 4.65,
    "JUL": 4.69,
    "AUG": 5.32,
    "SEP": 5.25,
    "OCT": 5.05,
    "NOV": 4.64,
    "DEC": 5.22
  },
  {
    "LONG": -41.87,
    "LAT": -2.09,
    "CIDADE": "Varre-Sai",
    "UF": "RIO DE JANEIRO",
    "anual": 4.97,
    "JAN": 5.3,
    "FEB": 5.76,
    "MAR": 5.09,
    "APR": 4.93,
    "MAY": 4.56,
    "JUN": 4.57,
    "JUL": 4.62,
    "AUG": 5.24,
    "SEP": 5.2,
    "OCT": 4.89,
    "NOV": 4.45,
    "DEC": 5.03
  },
  {
    "LONG": -51.21,
    "LAT": -30.03,
    "CIDADE": "Porto Alegre",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 4.67,
    "JAN": 5.68,
    "FEB": 5.63,
    "MAR": 5.24,
    "APR": 4.71,
    "MAY": 3.76,
    "JUN": 3.27,
    "JUL": 3.53,
    "AUG": 4.08,
    "SEP": 4.02,
    "OCT": 4.78,
    "NOV": 5.64,
    "DEC": 5.75
  },
  {
    "LONG": -36.72,
    "LAT": -6.95,
    "CIDADE": "Equador",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.79,
    "JAN": 5.75,
    "FEB": 5.82,
    "MAR": 6.02,
    "APR": 5.76,
    "MAY": 5.27,
    "JUN": 4.92,
    "JUL": 5.22,
    "AUG": 5.94,
    "SEP": 6.32,
    "OCT": 6.35,
    "NOV": 6.25,
    "DEC": 5.86
  },
  {
    "LONG": -37.2,
    "LAT": -0.68,
    "CIDADE": "Ipueira",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.93,
    "JAN": 5.9,
    "FEB": 6.02,
    "MAR": 6.2,
    "APR": 6.01,
    "MAY": 5.46,
    "JUN": 5.05,
    "JUL": 5.31,
    "AUG": 6.01,
    "SEP": 6.42,
    "OCT": 6.45,
    "NOV": 6.34,
    "DEC": 5.97
  },
  {
    "LONG": -36.73,
    "LAT": "#VALOR!",
    "CIDADE": "Santana do Seridó",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.77,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.83,
    "MAY": 5.31,
    "JUN": 4.92,
    "JUL": 5.21,
    "AUG": 5.94,
    "SEP": 6.31,
    "OCT": 6.32,
    "NOV": 6.22,
    "DEC": 5.89
  },
  {
    "LONG": -37.4,
    "LAT": -6.67,
    "CIDADE": "Serra Negra do Norte",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.83,
    "FEB": 6.07,
    "MAR": 6.17,
    "APR": 5.99,
    "MAY": 5.47,
    "JUN": 5.07,
    "JUL": 5.37,
    "AUG": 6.05,
    "SEP": 6.42,
    "OCT": 6.47,
    "NOV": 6.41,
    "DEC": 5.96
  },
  {
    "LONG": -3.72,
    "LAT": -6.71,
    "CIDADE": "São João do Sabugi",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.88,
    "FEB": 6.08,
    "MAR": 6.2,
    "APR": 6,
    "MAY": 5.48,
    "JUN": 5.06,
    "JUL": 5.36,
    "AUG": 6.05,
    "SEP": 6.44,
    "OCT": 6.49,
    "NOV": 6.4,
    "DEC": 6.02
  },
  {
    "LONG": -36.95,
    "LAT": -6.7,
    "CIDADE": "Ouro Branco",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.91,
    "JAN": 5.85,
    "FEB": 6.03,
    "MAR": 6.22,
    "APR": 6,
    "MAY": 5.43,
    "JUN": 5.01,
    "JUL": 5.3,
    "AUG": 6.01,
    "SEP": 6.37,
    "OCT": 6.43,
    "NOV": 6.34,
    "DEC": 5.99
  },
  {
    "LONG": -36.66,
    "LAT": -6.69,
    "CIDADE": "Parelhas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.79,
    "JAN": 5.78,
    "FEB": 5.91,
    "MAR": 6.03,
    "APR": 5.85,
    "MAY": 5.32,
    "JUN": 4.91,
    "JUL": 5.19,
    "AUG": 5.88,
    "SEP": 6.26,
    "OCT": 6.28,
    "NOV": 6.22,
    "DEC": 5.88
  },
  {
    "LONG": -36.78,
    "LAT": -6.59,
    "CIDADE": "Jardim do Seridó",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.83,
    "JAN": 5.78,
    "FEB": 5.9,
    "MAR": 6.1,
    "APR": 5.86,
    "MAY": 5.4,
    "JUN": 4.88,
    "JUL": 5.25,
    "AUG": 5.96,
    "SEP": 6.36,
    "OCT": 6.4,
    "NOV": 6.25,
    "DEC": 5.85
  },
  {
    "LONG": -36.59,
    "LAT": -6.56,
    "CIDADE": "Carnaúba dos Dantas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.7,
    "JAN": 5.68,
    "FEB": 5.72,
    "MAR": 5.94,
    "APR": 5.65,
    "MAY": 5.18,
    "JUN": 4.79,
    "JUL": 5.09,
    "AUG": 5.8,
    "SEP": 6.19,
    "OCT": 6.29,
    "NOV": 6.23,
    "DEC": 5.78
  },
  {
    "LONG": -38.31,
    "LAT": -6.48,
    "CIDADE": "Paraná",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.78,
    "FEB": 5.87,
    "MAR": 6.01,
    "APR": 5.8,
    "MAY": 5.45,
    "JUN": 5.25,
    "JUL": 5.56,
    "AUG": 6.26,
    "SEP": 6.5,
    "OCT": 6.48,
    "NOV": 6.45,
    "DEC": 5.9
  },
  {
    "LONG": -38.18,
    "LAT": -6.46,
    "CIDADE": "Tenente Ananias",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.76,
    "FEB": 5.82,
    "MAR": 6.05,
    "APR": 5.86,
    "MAY": 5.51,
    "JUN": 5.27,
    "JUL": 5.55,
    "AUG": 6.25,
    "SEP": 6.51,
    "OCT": 6.47,
    "NOV": 6.48,
    "DEC": 5.9
  },
  {
    "LONG": -37.27,
    "LAT": -6.46,
    "CIDADE": "Timbaúba dos Batistas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.87,
    "FEB": 6.11,
    "MAR": 6.17,
    "APR": 6.01,
    "MAY": 5.55,
    "JUN": 5.11,
    "JUL": 5.39,
    "AUG": 6.06,
    "SEP": 6.43,
    "OCT": 6.48,
    "NOV": 6.32,
    "DEC": 5.95
  },
  {
    "LONG": -37.1,
    "LAT": -6.46,
    "CIDADE": "Caicó",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.91,
    "JAN": 5.9,
    "FEB": 6.04,
    "MAR": 6.14,
    "APR": 5.95,
    "MAY": 5.44,
    "JUN": 5.04,
    "JUL": 5.32,
    "AUG": 6.01,
    "SEP": 6.42,
    "OCT": 6.44,
    "NOV": 6.29,
    "DEC": 5.99
  },
  {
    "LONG": -35.95,
    "LAT": -6.47,
    "CIDADE": "Japi",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.44,
    "JAN": 5.49,
    "FEB": 5.63,
    "MAR": 5.78,
    "APR": 5.46,
    "MAY": 5.02,
    "JUN": 4.62,
    "JUL": 4.77,
    "AUG": 5.48,
    "SEP": 5.77,
    "OCT": 5.85,
    "NOV": 5.92,
    "DEC": 5.53
  },
  {
    "LONG": -35.43,
    "LAT": -6.48,
    "CIDADE": "Nova Cruz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.46,
    "JAN": 5.53,
    "FEB": 5.67,
    "MAR": 5.87,
    "APR": 5.51,
    "MAY": 5.07,
    "JUN": 4.65,
    "JUL": 4.81,
    "AUG": 5.44,
    "SEP": 5.73,
    "OCT": 5.8,
    "NOV": 5.9,
    "DEC": 5.58
  },
  {
    "LONG": -35.28,
    "LAT": -6.49,
    "CIDADE": "Montanhas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.53,
    "JAN": 5.67,
    "FEB": 5.87,
    "MAR": 6.02,
    "APR": 5.55,
    "MAY": 5.07,
    "JUN": 4.64,
    "JUL": 4.75,
    "AUG": 5.44,
    "SEP": 5.79,
    "OCT": 5.87,
    "NOV": 5.97,
    "DEC": 5.73
  },
  {
    "LONG": -38.32,
    "LAT": -6.4,
    "CIDADE": "Major Sales",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.75,
    "FEB": 5.84,
    "MAR": 6.01,
    "APR": 5.78,
    "MAY": 5.45,
    "JUN": 5.23,
    "JUL": 5.6,
    "AUG": 6.28,
    "SEP": 6.52,
    "OCT": 6.46,
    "NOV": 6.44,
    "DEC": 5.9
  },
  {
    "LONG": -38.39,
    "LAT": -6.41,
    "CIDADE": "Luís Gomes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.75,
    "FEB": 5.84,
    "MAR": 6.01,
    "APR": 5.78,
    "MAY": 5.45,
    "JUN": 5.23,
    "JUL": 5.6,
    "AUG": 6.28,
    "SEP": 6.52,
    "OCT": 6.46,
    "NOV": 6.44,
    "DEC": 5.9
  },
  {
    "LONG": -38.01,
    "LAT": -6.41,
    "CIDADE": "Alexandria",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.99,
    "JAN": 5.84,
    "FEB": 5.92,
    "MAR": 6.12,
    "APR": 5.94,
    "MAY": 5.52,
    "JUN": 5.25,
    "JUL": 5.57,
    "AUG": 6.27,
    "SEP": 6.53,
    "OCT": 6.51,
    "NOV": 6.43,
    "DEC": 5.93
  },
  {
    "LONG": -37.35,
    "LAT": -6.38,
    "CIDADE": "Jardim de Piranhas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.93,
    "FEB": 6.09,
    "MAR": 6.21,
    "APR": 6.01,
    "MAY": 5.54,
    "JUN": 5.12,
    "JUL": 5.43,
    "AUG": 6.13,
    "SEP": 6.46,
    "OCT": 6.48,
    "NOV": 6.35,
    "DEC": 5.91
  },
  {
    "LONG": -37.19,
    "LAT": -6.38,
    "CIDADE": "São Fernando",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.93,
    "JAN": 5.88,
    "FEB": 5.98,
    "MAR": 6.1,
    "APR": 5.98,
    "MAY": 5.48,
    "JUN": 5.1,
    "JUL": 5.4,
    "AUG": 6.08,
    "SEP": 6.47,
    "OCT": 6.46,
    "NOV": 6.34,
    "DEC": 5.92
  },
  {
    "LONG": -36.88,
    "LAT": -0.64,
    "CIDADE": "São José do Seridó",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.77,
    "FEB": 5.89,
    "MAR": 6.07,
    "APR": 5.84,
    "MAY": 5.33,
    "JUN": 4.95,
    "JUL": 5.2,
    "AUG": 5.91,
    "SEP": 6.33,
    "OCT": 6.35,
    "NOV": 6.22,
    "DEC": 5.86
  },
  {
    "LONG": -3.68,
    "LAT": -6.41,
    "CIDADE": "Cruzeta",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.78,
    "JAN": 5.74,
    "FEB": 5.87,
    "MAR": 6.03,
    "APR": 5.77,
    "MAY": 5.31,
    "JUN": 4.94,
    "JUL": 5.17,
    "AUG": 5.85,
    "SEP": 6.31,
    "OCT": 6.36,
    "NOV": 6.25,
    "DEC": 5.79
  },
  {
    "LONG": -36.64,
    "LAT": -6.44,
    "CIDADE": "Acari",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.76,
    "JAN": 5.76,
    "FEB": 5.83,
    "MAR": 5.98,
    "APR": 5.74,
    "MAY": 5.26,
    "JUN": 4.9,
    "JUL": 5.13,
    "AUG": 5.81,
    "SEP": 6.28,
    "OCT": 6.38,
    "NOV": 6.27,
    "DEC": 5.83
  },
  {
    "LONG": -36.22,
    "LAT": -6.39,
    "CIDADE": "Coronel Ezequiel",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.65,
    "JAN": 5.69,
    "FEB": 5.8,
    "MAR": 5.91,
    "APR": 5.65,
    "MAY": 5.18,
    "JUN": 4.8,
    "JUL": 4.98,
    "AUG": 5.69,
    "SEP": 6.06,
    "OCT": 6.18,
    "NOV": 6.15,
    "DEC": 5.73
  },
  {
    "LONG": -36.2,
    "LAT": -6.42,
    "CIDADE": "Jaçanã",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.65,
    "JAN": 5.69,
    "FEB": 5.8,
    "MAR": 5.91,
    "APR": 5.65,
    "MAY": 5.18,
    "JUN": 4.8,
    "JUL": 4.98,
    "AUG": 5.69,
    "SEP": 6.06,
    "OCT": 6.18,
    "NOV": 6.15,
    "DEC": 5.73
  },
  {
    "LONG": -35.7,
    "LAT": -6.42,
    "CIDADE": "Serra de São Bento",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.58,
    "FEB": 5.79,
    "MAR": 5.98,
    "APR": 5.61,
    "MAY": 5.13,
    "JUN": 4.75,
    "JUL": 4.86,
    "AUG": 5.55,
    "SEP": 5.82,
    "OCT": 5.84,
    "NOV": 5.94,
    "DEC": 5.63
  },
  {
    "LONG": -35.78,
    "LAT": -6.44,
    "CIDADE": "Monte das Gameleiras",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.58,
    "FEB": 5.79,
    "MAR": 5.98,
    "APR": 5.61,
    "MAY": 5.13,
    "JUN": 4.75,
    "JUL": 4.86,
    "AUG": 5.55,
    "SEP": 5.82,
    "OCT": 5.84,
    "NOV": 5.94,
    "DEC": 5.63
  },
  {
    "LONG": -35.64,
    "LAT": -6.43,
    "CIDADE": "Passa e Fica",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.49,
    "JAN": 5.52,
    "FEB": 5.67,
    "MAR": 5.86,
    "APR": 5.57,
    "MAY": 5.14,
    "JUN": 4.77,
    "JUL": 4.92,
    "AUG": 5.52,
    "SEP": 5.76,
    "OCT": 5.79,
    "NOV": 5.84,
    "DEC": 5.47
  },
  {
    "LONG": -35.6,
    "LAT": -6.39,
    "CIDADE": "Lagoa d'Anta",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.48,
    "JAN": 5.51,
    "FEB": 5.69,
    "MAR": 5.87,
    "APR": 5.55,
    "MAY": 5.1,
    "JUN": 4.72,
    "JUL": 4.87,
    "AUG": 5.49,
    "SEP": 5.75,
    "OCT": 5.79,
    "NOV": 5.87,
    "DEC": 5.54
  },
  {
    "LONG": -35.22,
    "LAT": -0.64,
    "CIDADE": "Pedro Velho",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.5,
    "JAN": 5.6,
    "FEB": 5.82,
    "MAR": 6.01,
    "APR": 5.54,
    "MAY": 5.06,
    "JUN": 4.65,
    "JUL": 4.74,
    "AUG": 5.44,
    "SEP": 5.75,
    "OCT": 5.83,
    "NOV": 5.93,
    "DEC": 5.68
  },
  {
    "LONG": -35.13,
    "LAT": -6.38,
    "CIDADE": "Canguaretama",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.55,
    "JAN": 5.62,
    "FEB": 5.87,
    "MAR": 6.05,
    "APR": 5.59,
    "MAY": 5.08,
    "JUN": 4.67,
    "JUL": 4.77,
    "AUG": 5.47,
    "SEP": 5.85,
    "OCT": 5.91,
    "NOV": 5.94,
    "DEC": 5.74
  },
  {
    "LONG": -35.01,
    "LAT": -6.37,
    "CIDADE": "Baía Formosa",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.63,
    "JAN": 5.68,
    "FEB": 5.92,
    "MAR": 6.1,
    "APR": 5.68,
    "MAY": 5.21,
    "JUN": 4.79,
    "JUL": 4.94,
    "AUG": 5.66,
    "SEP": 5.91,
    "OCT": 5.97,
    "NOV": 5.98,
    "DEC": 5.75
  },
  {
    "LONG": -38.49,
    "LAT": -6.32,
    "CIDADE": "Venha-Ver",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.92,
    "JAN": 5.71,
    "FEB": 5.76,
    "MAR": 5.9,
    "APR": 5.7,
    "MAY": 5.44,
    "JUN": 5.25,
    "JUL": 5.64,
    "AUG": 6.31,
    "SEP": 6.53,
    "OCT": 6.46,
    "NOV": 6.46,
    "DEC": 5.87
  },
  {
    "LONG": -38.31,
    "LAT": -6.25,
    "CIDADE": "Riacho de Santana",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.91,
    "JAN": 5.71,
    "FEB": 5.79,
    "MAR": 5.95,
    "APR": 5.75,
    "MAY": 5.44,
    "JUN": 5.23,
    "JUL": 5.62,
    "AUG": 6.26,
    "SEP": 6.51,
    "OCT": 6.43,
    "NOV": 6.39,
    "DEC": 5.87
  },
  {
    "LONG": -38.28,
    "LAT": -6.31,
    "CIDADE": "José da Penha",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.75,
    "FEB": 5.9,
    "MAR": 6.01,
    "APR": 5.86,
    "MAY": 5.52,
    "JUN": 5.27,
    "JUL": 5.59,
    "AUG": 6.21,
    "SEP": 6.48,
    "OCT": 6.43,
    "NOV": 6.37,
    "DEC": 5.89
  },
  {
    "LONG": -38.16,
    "LAT": -0.63,
    "CIDADE": "Marcelino Vieira",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.79,
    "FEB": 5.88,
    "MAR": 6.06,
    "APR": 5.92,
    "MAY": 5.49,
    "JUN": 5.21,
    "JUL": 5.57,
    "AUG": 6.24,
    "SEP": 6.5,
    "OCT": 6.45,
    "NOV": 6.37,
    "DEC": 5.87
  },
  {
    "LONG": -38.05,
    "LAT": -0.63,
    "CIDADE": "Pilões",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.98,
    "JAN": 5.83,
    "FEB": 5.87,
    "MAR": 6.12,
    "APR": 5.97,
    "MAY": 5.51,
    "JUN": 5.24,
    "JUL": 5.59,
    "AUG": 6.3,
    "SEP": 6.54,
    "OCT": 6.51,
    "NOV": 6.41,
    "DEC": 5.9
  },
  {
    "LONG": -37.79,
    "LAT": -6.27,
    "CIDADE": "João Dias",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.96,
    "JAN": 5.79,
    "FEB": 5.94,
    "MAR": 6.06,
    "APR": 5.87,
    "MAY": 5.54,
    "JUN": 5.21,
    "JUL": 5.57,
    "AUG": 6.24,
    "SEP": 6.58,
    "OCT": 6.47,
    "NOV": 6.32,
    "DEC": 5.91
  },
  {
    "LONG": -36.51,
    "LAT": -6.26,
    "CIDADE": "Currais Novos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.69,
    "JAN": 5.68,
    "FEB": 5.8,
    "MAR": 5.86,
    "APR": 5.69,
    "MAY": 5.17,
    "JUN": 4.85,
    "JUL": 5.07,
    "AUG": 5.76,
    "SEP": 6.22,
    "OCT": 6.25,
    "NOV": 6.2,
    "DEC": 5.8
  },
  {
    "LONG": -36.09,
    "LAT": -6.34,
    "CIDADE": "São Bento do Trairí",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.47,
    "JAN": 5.48,
    "FEB": 5.68,
    "MAR": 5.84,
    "APR": 5.56,
    "MAY": 5.1,
    "JUN": 4.58,
    "JUL": 4.75,
    "AUG": 5.47,
    "SEP": 5.81,
    "OCT": 5.9,
    "NOV": 5.95,
    "DEC": 5.52
  },
  {
    "LONG": -35.71,
    "LAT": -6.32,
    "CIDADE": "São José do Campestre",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.53,
    "JAN": 5.57,
    "FEB": 5.77,
    "MAR": 5.93,
    "APR": 5.62,
    "MAY": 5.17,
    "JUN": 4.74,
    "JUL": 4.84,
    "AUG": 5.52,
    "SEP": 5.78,
    "OCT": 5.85,
    "NOV": 5.93,
    "DEC": 5.6
  },
  {
    "LONG": -35.48,
    "LAT": -6.31,
    "CIDADE": "Santo Antônio",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.52,
    "JAN": 5.51,
    "FEB": 5.76,
    "MAR": 6.02,
    "APR": 5.55,
    "MAY": 5.12,
    "JUN": 4.72,
    "JUL": 4.88,
    "AUG": 5.47,
    "SEP": 5.78,
    "OCT": 5.88,
    "NOV": 6,
    "DEC": 5.59
  },
  {
    "LONG": -35.5,
    "LAT": -6.28,
    "CIDADE": "Serrinha",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.52,
    "JAN": 5.51,
    "FEB": 5.76,
    "MAR": 6.02,
    "APR": 5.55,
    "MAY": 5.12,
    "JUN": 4.72,
    "JUL": 4.88,
    "AUG": 5.47,
    "SEP": 5.78,
    "OCT": 5.88,
    "NOV": 6,
    "DEC": 5.59
  },
  {
    "LONG": -35.31,
    "LAT": -6.33,
    "CIDADE": "Espírito Santo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.55,
    "JAN": 5.61,
    "FEB": 5.86,
    "MAR": 6.08,
    "APR": 5.56,
    "MAY": 5.16,
    "JUN": 4.68,
    "JUL": 4.77,
    "AUG": 5.53,
    "SEP": 5.78,
    "OCT": 5.92,
    "NOV": 6.04,
    "DEC": 5.64
  },
  {
    "LONG": -35.37,
    "LAT": -6.35,
    "CIDADE": "Várzea",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.55,
    "JAN": 5.61,
    "FEB": 5.86,
    "MAR": 6.08,
    "APR": 5.56,
    "MAY": 5.16,
    "JUN": 4.68,
    "JUL": 4.77,
    "AUG": 5.53,
    "SEP": 5.78,
    "OCT": 5.92,
    "NOV": 6.04,
    "DEC": 5.64
  },
  {
    "LONG": -35.33,
    "LAT": -6.27,
    "CIDADE": "Jundiá",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.55,
    "JAN": 5.61,
    "FEB": 5.86,
    "MAR": 6.08,
    "APR": 5.56,
    "MAY": 5.16,
    "JUN": 4.68,
    "JUL": 4.77,
    "AUG": 5.53,
    "SEP": 5.78,
    "OCT": 5.92,
    "NOV": 6.04,
    "DEC": 5.64
  },
  {
    "LONG": -35.37,
    "LAT": -6.27,
    "CIDADE": "Passagem",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.55,
    "JAN": 5.61,
    "FEB": 5.86,
    "MAR": 6.08,
    "APR": 5.56,
    "MAY": 5.16,
    "JUN": 4.68,
    "JUL": 4.77,
    "AUG": 5.53,
    "SEP": 5.78,
    "OCT": 5.92,
    "NOV": 6.04,
    "DEC": 5.64
  },
  {
    "LONG": -35.21,
    "LAT": -6.27,
    "CIDADE": "Goianinha",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.61,
    "FEB": 5.85,
    "MAR": 6.06,
    "APR": 5.57,
    "MAY": 5.1,
    "JUN": 4.66,
    "JUL": 4.74,
    "AUG": 5.49,
    "SEP": 5.82,
    "OCT": 5.9,
    "NOV": 6,
    "DEC": 5.71
  },
  {
    "LONG": -35.08,
    "LAT": -6.31,
    "CIDADE": "Vila Flor",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.72,
    "JAN": 5.76,
    "FEB": 6,
    "MAR": 6.19,
    "APR": 5.72,
    "MAY": 5.27,
    "JUN": 4.83,
    "JUL": 4.98,
    "AUG": 5.73,
    "SEP": 6.06,
    "OCT": 6.13,
    "NOV": 6.12,
    "DEC": 5.88
  },
  {
    "LONG": -38.44,
    "LAT": -6.25,
    "CIDADE": "Coronel João Pessoa",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.89,
    "JAN": 5.63,
    "FEB": 5.74,
    "MAR": 5.83,
    "APR": 5.68,
    "MAY": 5.39,
    "JUN": 5.31,
    "JUL": 5.64,
    "AUG": 6.31,
    "SEP": 6.51,
    "OCT": 6.45,
    "NOV": 6.42,
    "DEC": 5.82
  },
  {
    "LONG": -3.85,
    "LAT": -6.2,
    "CIDADE": "São Miguel",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.89,
    "JAN": 5.63,
    "FEB": 5.74,
    "MAR": 5.83,
    "APR": 5.68,
    "MAY": 5.39,
    "JUN": 5.31,
    "JUL": 5.64,
    "AUG": 6.31,
    "SEP": 6.51,
    "OCT": 6.45,
    "NOV": 6.42,
    "DEC": 5.82
  },
  {
    "LONG": -38.29,
    "LAT": -6.2,
    "CIDADE": "Água Nova",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.69,
    "FEB": 5.89,
    "MAR": 6.01,
    "APR": 5.83,
    "MAY": 5.52,
    "JUN": 5.27,
    "JUL": 5.59,
    "AUG": 6.25,
    "SEP": 6.48,
    "OCT": 6.45,
    "NOV": 6.37,
    "DEC": 5.87
  },
  {
    "LONG": -38.22,
    "LAT": -6.19,
    "CIDADE": "Rafael Fernandes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.69,
    "FEB": 5.89,
    "MAR": 6.01,
    "APR": 5.83,
    "MAY": 5.52,
    "JUN": 5.27,
    "JUL": 5.59,
    "AUG": 6.25,
    "SEP": 6.48,
    "OCT": 6.45,
    "NOV": 6.37,
    "DEC": 5.87
  },
  {
    "LONG": -37.84,
    "LAT": -6.16,
    "CIDADE": "Frutuoso Gomes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.81,
    "FEB": 5.9,
    "MAR": 6.03,
    "APR": 5.93,
    "MAY": 5.57,
    "JUN": 5.26,
    "JUL": 5.6,
    "AUG": 6.24,
    "SEP": 6.54,
    "OCT": 6.51,
    "NOV": 6.39,
    "DEC": 5.9
  },
  {
    "LONG": -37.88,
    "LAT": -6.21,
    "CIDADE": "Antônio Martins",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.81,
    "FEB": 5.9,
    "MAR": 6.03,
    "APR": 5.93,
    "MAY": 5.57,
    "JUN": 5.26,
    "JUL": 5.6,
    "AUG": 6.24,
    "SEP": 6.54,
    "OCT": 6.51,
    "NOV": 6.39,
    "DEC": 5.9
  },
  {
    "LONG": -3.67,
    "LAT": -6.22,
    "CIDADE": "São Vicente",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.75,
    "JAN": 5.71,
    "FEB": 5.78,
    "MAR": 5.9,
    "APR": 5.67,
    "MAY": 5.28,
    "JUN": 4.89,
    "JUL": 5.16,
    "AUG": 5.9,
    "SEP": 6.28,
    "OCT": 6.34,
    "NOV": 6.25,
    "DEC": 5.79
  },
  {
    "LONG": -36.18,
    "LAT": -6.24,
    "CIDADE": "Campo Redondo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.53,
    "JAN": 5.51,
    "FEB": 5.7,
    "MAR": 5.76,
    "APR": 5.57,
    "MAY": 5.16,
    "JUN": 4.72,
    "JUL": 4.92,
    "AUG": 5.55,
    "SEP": 5.94,
    "OCT": 5.99,
    "NOV": 5.98,
    "DEC": 5.5
  },
  {
    "LONG": -36.02,
    "LAT": -6.23,
    "CIDADE": "Santa Cruz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.47,
    "JAN": 5.46,
    "FEB": 5.7,
    "MAR": 5.76,
    "APR": 5.52,
    "MAY": 5.13,
    "JUN": 4.67,
    "JUL": 4.85,
    "AUG": 5.45,
    "SEP": 5.83,
    "OCT": 5.89,
    "NOV": 5.9,
    "DEC": 5.5
  },
  {
    "LONG": -35.8,
    "LAT": -6.2,
    "CIDADE": "Tangará",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.48,
    "JAN": 5.53,
    "FEB": 5.71,
    "MAR": 5.83,
    "APR": 5.61,
    "MAY": 5.17,
    "JUN": 4.7,
    "JUL": 4.83,
    "AUG": 5.47,
    "SEP": 5.76,
    "OCT": 5.8,
    "NOV": 5.85,
    "DEC": 5.49
  },
  {
    "LONG": -35.6,
    "LAT": -6.16,
    "CIDADE": "Januário Cicco",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.49,
    "JAN": 5.46,
    "FEB": 5.68,
    "MAR": 5.86,
    "APR": 5.61,
    "MAY": 5.16,
    "JUN": 4.76,
    "JUL": 4.87,
    "AUG": 5.51,
    "SEP": 5.77,
    "OCT": 5.82,
    "NOV": 5.9,
    "DEC": 5.51
  },
  {
    "LONG": -35.44,
    "LAT": -6.16,
    "CIDADE": "Lagoa de Pedras",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.62,
    "FEB": 5.79,
    "MAR": 5.97,
    "APR": 5.6,
    "MAY": 5.12,
    "JUN": 4.77,
    "JUL": 4.89,
    "AUG": 5.54,
    "SEP": 5.8,
    "OCT": 5.87,
    "NOV": 5.95,
    "DEC": 5.58
  },
  {
    "LONG": -35.36,
    "LAT": -6.2,
    "CIDADE": "Brejinho",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.56,
    "JAN": 5.65,
    "FEB": 5.82,
    "MAR": 6.07,
    "APR": 5.6,
    "MAY": 5.14,
    "JUN": 4.72,
    "JUL": 4.8,
    "AUG": 5.5,
    "SEP": 5.82,
    "OCT": 5.9,
    "NOV": 6.02,
    "DEC": 5.7
  },
  {
    "LONG": -35.16,
    "LAT": -0.62,
    "CIDADE": "Arês",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.66,
    "JAN": 5.69,
    "FEB": 5.99,
    "MAR": 6.14,
    "APR": 5.67,
    "MAY": 5.27,
    "JUN": 4.74,
    "JUL": 4.89,
    "AUG": 5.64,
    "SEP": 5.95,
    "OCT": 6.07,
    "NOV": 6.07,
    "DEC": 5.82
  },
  {
    "LONG": -35.13,
    "LAT": -6.16,
    "CIDADE": "Senador Georgino Avelino",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.66,
    "JAN": 5.69,
    "FEB": 5.99,
    "MAR": 6.14,
    "APR": 5.67,
    "MAY": 5.27,
    "JUN": 4.74,
    "JUL": 4.89,
    "AUG": 5.64,
    "SEP": 5.95,
    "OCT": 6.07,
    "NOV": 6.07,
    "DEC": 5.82
  },
  {
    "LONG": -35.09,
    "LAT": -6.19,
    "CIDADE": "Tibau do Sul",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.84,
    "JAN": 5.91,
    "FEB": 6.13,
    "MAR": 6.24,
    "APR": 5.8,
    "MAY": 5.43,
    "JUN": 4.9,
    "JUL": 5.08,
    "AUG": 5.91,
    "SEP": 6.17,
    "OCT": 6.22,
    "NOV": 6.3,
    "DEC": 6.03
  },
  {
    "LONG": -38.3,
    "LAT": -6.11,
    "CIDADE": "Encanto",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.88,
    "JAN": 5.58,
    "FEB": 5.72,
    "MAR": 5.86,
    "APR": 5.72,
    "MAY": 5.44,
    "JUN": 5.3,
    "JUL": 5.62,
    "AUG": 6.24,
    "SEP": 6.52,
    "OCT": 6.44,
    "NOV": 6.37,
    "DEC": 5.77
  },
  {
    "LONG": -38.38,
    "LAT": -6.08,
    "CIDADE": "Doutor Severiano",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.88,
    "JAN": 5.58,
    "FEB": 5.72,
    "MAR": 5.86,
    "APR": 5.72,
    "MAY": 5.44,
    "JUN": 5.3,
    "JUL": 5.62,
    "AUG": 6.24,
    "SEP": 6.52,
    "OCT": 6.44,
    "NOV": 6.37,
    "DEC": 5.77
  },
  {
    "LONG": -3.82,
    "LAT": -6.11,
    "CIDADE": "Pau dos Ferros",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.93,
    "JAN": 5.63,
    "FEB": 5.82,
    "MAR": 6.01,
    "APR": 5.83,
    "MAY": 5.54,
    "JUN": 5.29,
    "JUL": 5.63,
    "AUG": 6.25,
    "SEP": 6.47,
    "OCT": 6.46,
    "NOV": 6.38,
    "DEC": 5.82
  },
  {
    "LONG": -38.12,
    "LAT": -6.07,
    "CIDADE": "Francisco Dantas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.93,
    "JAN": 5.69,
    "FEB": 5.83,
    "MAR": 6,
    "APR": 5.79,
    "MAY": 5.55,
    "JUN": 5.29,
    "JUL": 5.64,
    "AUG": 6.24,
    "SEP": 6.45,
    "OCT": 6.48,
    "NOV": 6.37,
    "DEC": 5.82
  },
  {
    "LONG": -37.91,
    "LAT": -6.08,
    "CIDADE": "Martins",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6,
    "JAN": 5.85,
    "FEB": 5.87,
    "MAR": 6.02,
    "APR": 5.9,
    "MAY": 5.55,
    "JUN": 5.33,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 6.56,
    "OCT": 6.56,
    "NOV": 6.46,
    "DEC": 5.91
  },
  {
    "LONG": -37.96,
    "LAT": -6.11,
    "CIDADE": "Serrinha dos Pintos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6,
    "JAN": 5.85,
    "FEB": 5.87,
    "MAR": 6.02,
    "APR": 5.9,
    "MAY": 5.55,
    "JUN": 5.33,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 6.56,
    "OCT": 6.56,
    "NOV": 6.46,
    "DEC": 5.91
  },
  {
    "LONG": -37.81,
    "LAT": -6.11,
    "CIDADE": "Lucrécia",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.99,
    "JAN": 5.86,
    "FEB": 5.87,
    "MAR": 6.04,
    "APR": 5.95,
    "MAY": 5.57,
    "JUN": 5.3,
    "JUL": 5.65,
    "AUG": 6.28,
    "SEP": 6.55,
    "OCT": 6.53,
    "NOV": 6.42,
    "DEC": 5.87
  },
  {
    "LONG": -37.72,
    "LAT": -6.07,
    "CIDADE": "Rafael Godeiro",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.01,
    "JAN": 5.9,
    "FEB": 5.92,
    "MAR": 6.12,
    "APR": 6.02,
    "MAY": 5.65,
    "JUN": 5.35,
    "JUL": 5.64,
    "AUG": 6.26,
    "SEP": 6.54,
    "OCT": 6.5,
    "NOV": 6.4,
    "DEC": 5.88
  },
  {
    "LONG": -37.76,
    "LAT": -6.15,
    "CIDADE": "Almino Afonso",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.01,
    "JAN": 5.9,
    "FEB": 5.92,
    "MAR": 6.12,
    "APR": 6.02,
    "MAY": 5.65,
    "JUN": 5.35,
    "JUL": 5.64,
    "AUG": 6.26,
    "SEP": 6.54,
    "OCT": 6.5,
    "NOV": 6.4,
    "DEC": 5.88
  },
  {
    "LONG": -37.64,
    "LAT": -0.61,
    "CIDADE": "Patu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.02,
    "JAN": 5.91,
    "FEB": 6.02,
    "MAR": 6.15,
    "APR": 6.01,
    "MAY": 5.67,
    "JUN": 5.37,
    "JUL": 5.6,
    "AUG": 6.18,
    "SEP": 6.59,
    "OCT": 6.49,
    "NOV": 6.38,
    "DEC": 5.91
  },
  {
    "LONG": -37.52,
    "LAT": -6.07,
    "CIDADE": "Messias Targino",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.99,
    "JAN": 5.86,
    "FEB": 5.98,
    "MAR": 6.1,
    "APR": 6,
    "MAY": 5.62,
    "JUN": 5.29,
    "JUL": 5.55,
    "AUG": 6.17,
    "SEP": 6.56,
    "OCT": 6.48,
    "NOV": 6.39,
    "DEC": 5.89
  },
  {
    "LONG": -36.81,
    "LAT": -6.12,
    "CIDADE": "Florânia",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.77,
    "JAN": 5.63,
    "FEB": 5.78,
    "MAR": 5.91,
    "APR": 5.76,
    "MAY": 5.3,
    "JUN": 5.02,
    "JUL": 5.33,
    "AUG": 6.09,
    "SEP": 6.42,
    "OCT": 6.32,
    "NOV": 6.15,
    "DEC": 5.59
  },
  {
    "LONG": -36.72,
    "LAT": -6.15,
    "CIDADE": "Tenente Laurentino Cruz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.73,
    "JAN": 5.6,
    "FEB": 5.6,
    "MAR": 5.8,
    "APR": 5.61,
    "MAY": 5.26,
    "JUN": 4.96,
    "JUL": 5.32,
    "AUG": 6.06,
    "SEP": 6.38,
    "OCT": 6.33,
    "NOV": 6.2,
    "DEC": 5.59
  },
  {
    "LONG": -36.47,
    "LAT": -6.09,
    "CIDADE": "Lagoa Nova",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.6,
    "JAN": 5.52,
    "FEB": 5.69,
    "MAR": 5.81,
    "APR": 5.53,
    "MAY": 5.15,
    "JUN": 4.82,
    "JUL": 5.02,
    "AUG": 5.76,
    "SEP": 6.14,
    "OCT": 6.12,
    "NOV": 6.1,
    "DEC": 5.61
  },
  {
    "LONG": -36.12,
    "LAT": -6.15,
    "CIDADE": "Lajes Pintadas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.53,
    "JAN": 5.46,
    "FEB": 5.67,
    "MAR": 5.71,
    "APR": 5.58,
    "MAY": 5.15,
    "JUN": 4.76,
    "JUL": 5,
    "AUG": 5.64,
    "SEP": 5.95,
    "OCT": 6.01,
    "NOV": 5.97,
    "DEC": 5.49
  },
  {
    "LONG": -3.59,
    "LAT": -0.61,
    "CIDADE": "Sítio Novo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.5,
    "JAN": 5.51,
    "FEB": 5.75,
    "MAR": 5.79,
    "APR": 5.61,
    "MAY": 5.18,
    "JUN": 4.73,
    "JUL": 4.92,
    "AUG": 5.52,
    "SEP": 5.84,
    "OCT": 5.86,
    "NOV": 5.9,
    "DEC": 5.45
  },
  {
    "LONG": -35.71,
    "LAT": -6.11,
    "CIDADE": "Serra Caiada",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.5,
    "JAN": 5.48,
    "FEB": 5.67,
    "MAR": 5.78,
    "APR": 5.6,
    "MAY": 5.19,
    "JUN": 4.73,
    "JUL": 4.9,
    "AUG": 5.57,
    "SEP": 5.78,
    "OCT": 5.82,
    "NOV": 5.91,
    "DEC": 5.55
  },
  {
    "LONG": -35.48,
    "LAT": -6.12,
    "CIDADE": "Lagoa Salgada",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.52,
    "JAN": 5.55,
    "FEB": 5.71,
    "MAR": 5.97,
    "APR": 5.58,
    "MAY": 5.13,
    "JUN": 4.72,
    "JUL": 4.84,
    "AUG": 5.49,
    "SEP": 5.77,
    "OCT": 5.84,
    "NOV": 5.96,
    "DEC": 5.66
  },
  {
    "LONG": -35.33,
    "LAT": -6.07,
    "CIDADE": "Monte Alegre",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.6,
    "JAN": 5.73,
    "FEB": 5.89,
    "MAR": 6.07,
    "APR": 5.63,
    "MAY": 5.16,
    "JUN": 4.73,
    "JUL": 4.79,
    "AUG": 5.49,
    "SEP": 5.87,
    "OCT": 5.96,
    "NOV": 6.1,
    "DEC": 5.73
  },
  {
    "LONG": -3.52,
    "LAT": -6.09,
    "CIDADE": "Nísia Floresta",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.68,
    "JAN": 5.77,
    "FEB": 6.03,
    "MAR": 6.12,
    "APR": 5.69,
    "MAY": 5.26,
    "JUN": 4.77,
    "JUL": 4.88,
    "AUG": 5.61,
    "SEP": 5.97,
    "OCT": 6.08,
    "NOV": 6.13,
    "DEC": 5.82
  },
  {
    "LONG": -35.24,
    "LAT": -0.61,
    "CIDADE": "São José de Mipibu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.68,
    "JAN": 5.77,
    "FEB": 6.03,
    "MAR": 6.12,
    "APR": 5.69,
    "MAY": 5.26,
    "JUN": 4.77,
    "JUL": 4.88,
    "AUG": 5.61,
    "SEP": 5.97,
    "OCT": 6.08,
    "NOV": 6.13,
    "DEC": 5.82
  },
  {
    "LONG": -38.15,
    "LAT": -5.98,
    "CIDADE": "São Francisco do Oeste",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.93,
    "JAN": 5.61,
    "FEB": 5.82,
    "MAR": 5.99,
    "APR": 5.77,
    "MAY": 5.52,
    "JUN": 5.33,
    "JUL": 5.66,
    "AUG": 6.28,
    "SEP": 6.48,
    "OCT": 6.48,
    "NOV": 6.37,
    "DEC": 5.81
  },
  {
    "LONG": -37.95,
    "LAT": -5.98,
    "CIDADE": "Viçosa",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.03,
    "JAN": 5.81,
    "FEB": 5.98,
    "MAR": 6.13,
    "APR": 5.98,
    "MAY": 5.63,
    "JUN": 5.38,
    "JUL": 5.7,
    "AUG": 6.3,
    "SEP": 6.54,
    "OCT": 6.55,
    "NOV": 6.45,
    "DEC": 5.87
  },
  {
    "LONG": -37.99,
    "LAT": -6.02,
    "CIDADE": "Portalegre",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.03,
    "JAN": 5.81,
    "FEB": 5.98,
    "MAR": 6.13,
    "APR": 5.98,
    "MAY": 5.63,
    "JUN": 5.38,
    "JUL": 5.7,
    "AUG": 6.3,
    "SEP": 6.54,
    "OCT": 6.55,
    "NOV": 6.45,
    "DEC": 5.87
  },
  {
    "LONG": -37.82,
    "LAT": -5.98,
    "CIDADE": "Umarizal",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6.03,
    "JAN": 5.85,
    "FEB": 5.98,
    "MAR": 6.15,
    "APR": 6.01,
    "MAY": 5.69,
    "JUN": 5.37,
    "JUL": 5.71,
    "AUG": 6.3,
    "SEP": 6.52,
    "OCT": 6.54,
    "NOV": 6.43,
    "DEC": 5.86
  },
  {
    "LONG": -37.41,
    "LAT": -6.02,
    "CIDADE": "Janduís",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.77,
    "FEB": 5.94,
    "MAR": 6.07,
    "APR": 5.98,
    "MAY": 5.61,
    "JUN": 5.23,
    "JUL": 5.58,
    "AUG": 6.22,
    "SEP": 6.53,
    "OCT": 6.48,
    "NOV": 6.36,
    "DEC": 5.84
  },
  {
    "LONG": -37.02,
    "LAT": -6.04,
    "CIDADE": "Jucurutu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.89,
    "JAN": 5.77,
    "FEB": 5.96,
    "MAR": 6.16,
    "APR": 5.93,
    "MAY": 5.53,
    "JUN": 5.14,
    "JUL": 5.38,
    "AUG": 6.03,
    "SEP": 6.41,
    "OCT": 6.36,
    "NOV": 6.28,
    "DEC": 5.79
  },
  {
    "LONG": -36.41,
    "LAT": -0.6,
    "CIDADE": "Bodó",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.84,
    "JAN": 5.76,
    "FEB": 5.88,
    "MAR": 6,
    "APR": 5.7,
    "MAY": 5.4,
    "JUN": 5.05,
    "JUL": 5.33,
    "AUG": 6.04,
    "SEP": 6.37,
    "OCT": 6.42,
    "NOV": 6.3,
    "DEC": 5.79
  },
  {
    "LONG": -36.35,
    "LAT": -6.04,
    "CIDADE": "Cerro Corá",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.78,
    "JAN": 5.72,
    "FEB": 5.82,
    "MAR": 5.93,
    "APR": 5.66,
    "MAY": 5.39,
    "JUN": 5.02,
    "JUL": 5.27,
    "AUG": 5.97,
    "SEP": 6.3,
    "OCT": 6.35,
    "NOV": 6.25,
    "DEC": 5.71
  },
  {
    "LONG": -3.61,
    "LAT": -5.97,
    "CIDADE": "São Tomé",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.56,
    "FEB": 5.7,
    "MAR": 5.8,
    "APR": 5.6,
    "MAY": 5.2,
    "JUN": 4.73,
    "JUL": 4.96,
    "AUG": 5.55,
    "SEP": 5.92,
    "OCT": 5.95,
    "NOV": 5.95,
    "DEC": 5.57
  },
  {
    "LONG": -3.59,
    "LAT": -5.95,
    "CIDADE": "Barcelona",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.52,
    "JAN": 5.53,
    "FEB": 5.66,
    "MAR": 5.79,
    "APR": 5.61,
    "MAY": 5.18,
    "JUN": 4.77,
    "JUL": 4.95,
    "AUG": 5.53,
    "SEP": 5.83,
    "OCT": 5.89,
    "NOV": 5.92,
    "DEC": 5.53
  },
  {
    "LONG": -35.87,
    "LAT": -6,
    "CIDADE": "Lagoa de Velhos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.49,
    "JAN": 5.53,
    "FEB": 5.64,
    "MAR": 5.76,
    "APR": 5.58,
    "MAY": 5.16,
    "JUN": 4.73,
    "JUL": 4.94,
    "AUG": 5.55,
    "SEP": 5.78,
    "OCT": 5.86,
    "NOV": 5.9,
    "DEC": 5.51
  },
  {
    "LONG": -35.69,
    "LAT": -0.6,
    "CIDADE": "Senador Elói de Souza",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.56,
    "JAN": 5.66,
    "FEB": 5.74,
    "MAR": 5.9,
    "APR": 5.64,
    "MAY": 5.18,
    "JUN": 4.77,
    "JUL": 4.92,
    "AUG": 5.59,
    "SEP": 5.84,
    "OCT": 5.9,
    "NOV": 5.98,
    "DEC": 5.62
  },
  {
    "LONG": -35.58,
    "LAT": -5.99,
    "CIDADE": "Bom Jesus",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.58,
    "JAN": 5.68,
    "FEB": 5.78,
    "MAR": 5.97,
    "APR": 5.64,
    "MAY": 5.18,
    "JUN": 4.75,
    "JUL": 4.91,
    "AUG": 5.56,
    "SEP": 5.84,
    "OCT": 5.94,
    "NOV": 6.02,
    "DEC": 5.68
  },
  {
    "LONG": -35.43,
    "LAT": -6.04,
    "CIDADE": "Vera Cruz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.59,
    "JAN": 5.71,
    "FEB": 5.81,
    "MAR": 6.01,
    "APR": 5.64,
    "MAY": 5.18,
    "JUN": 4.73,
    "JUL": 4.88,
    "AUG": 5.53,
    "SEP": 5.85,
    "OCT": 5.96,
    "NOV": 6.04,
    "DEC": 5.7
  },
  {
    "LONG": -3.8,
    "LAT": -5.92,
    "CIDADE": "Taboleiro Grande",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.72,
    "FEB": 5.94,
    "MAR": 6.06,
    "APR": 5.86,
    "MAY": 5.58,
    "JUN": 5.28,
    "JUL": 5.62,
    "AUG": 6.21,
    "SEP": 6.49,
    "OCT": 6.53,
    "NOV": 6.42,
    "DEC": 5.89
  },
  {
    "LONG": -37.95,
    "LAT": -0.59,
    "CIDADE": "Riacho da Cruz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 6,
    "JAN": 5.78,
    "FEB": 5.96,
    "MAR": 6.1,
    "APR": 5.94,
    "MAY": 5.64,
    "JUN": 5.34,
    "JUL": 5.67,
    "AUG": 6.28,
    "SEP": 6.49,
    "OCT": 6.54,
    "NOV": 6.43,
    "DEC": 5.87
  },
  {
    "LONG": -3.77,
    "LAT": -0.59,
    "CIDADE": "Olho-d'Água do Borges",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.74,
    "FEB": 5.9,
    "MAR": 6.07,
    "APR": 5.89,
    "MAY": 5.6,
    "JUN": 5.29,
    "JUL": 5.64,
    "AUG": 6.24,
    "SEP": 6.47,
    "OCT": 6.5,
    "NOV": 6.37,
    "DEC": 5.86
  },
  {
    "LONG": -37.31,
    "LAT": -0.59,
    "CIDADE": "Augusto Severo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.8,
    "FEB": 5.95,
    "MAR": 6.1,
    "APR": 5.96,
    "MAY": 5.61,
    "JUN": 5.23,
    "JUL": 5.53,
    "AUG": 6.16,
    "SEP": 6.47,
    "OCT": 6.43,
    "NOV": 6.35,
    "DEC": 5.82
  },
  {
    "LONG": -37.19,
    "LAT": -5.87,
    "CIDADE": "Triunfo Potiguar",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.96,
    "JAN": 5.82,
    "FEB": 6,
    "MAR": 6.16,
    "APR": 5.93,
    "MAY": 5.61,
    "JUN": 5.2,
    "JUL": 5.51,
    "AUG": 6.11,
    "SEP": 6.45,
    "OCT": 6.45,
    "NOV": 6.33,
    "DEC": 5.89
  },
  {
    "LONG": -36.65,
    "LAT": -5.95,
    "CIDADE": "Santana do Matos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.75,
    "FEB": 5.83,
    "MAR": 6.01,
    "APR": 5.77,
    "MAY": 5.43,
    "JUN": 4.98,
    "JUL": 5.31,
    "AUG": 5.95,
    "SEP": 6.31,
    "OCT": 6.37,
    "NOV": 6.25,
    "DEC": 5.8
  },
  {
    "LONG": -35.94,
    "LAT": -5.88,
    "CIDADE": "Ruy Barbosa",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.49,
    "JAN": 5.51,
    "FEB": 5.58,
    "MAR": 5.74,
    "APR": 5.57,
    "MAY": 5.14,
    "JUN": 4.75,
    "JUL": 4.96,
    "AUG": 5.53,
    "SEP": 5.82,
    "OCT": 5.88,
    "NOV": 5.9,
    "DEC": 5.49
  },
  {
    "LONG": -35.76,
    "LAT": -5.9,
    "CIDADE": "São Paulo do Potengi",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.52,
    "JAN": 5.58,
    "FEB": 5.69,
    "MAR": 5.82,
    "APR": 5.57,
    "MAY": 5.18,
    "JUN": 4.75,
    "JUL": 4.98,
    "AUG": 5.54,
    "SEP": 5.81,
    "OCT": 5.86,
    "NOV": 5.93,
    "DEC": 5.57
  },
  {
    "LONG": -35.63,
    "LAT": -5.9,
    "CIDADE": "São Pedro",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.61,
    "FEB": 5.73,
    "MAR": 5.86,
    "APR": 5.6,
    "MAY": 5.17,
    "JUN": 4.76,
    "JUL": 4.91,
    "AUG": 5.55,
    "SEP": 5.82,
    "OCT": 5.88,
    "NOV": 5.96,
    "DEC": 5.59
  },
  {
    "LONG": -35.35,
    "LAT": -5.86,
    "CIDADE": "Macaíba",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.64,
    "JAN": 5.69,
    "FEB": 5.9,
    "MAR": 6.07,
    "APR": 5.65,
    "MAY": 5.28,
    "JUN": 4.83,
    "JUL": 4.91,
    "AUG": 5.65,
    "SEP": 5.96,
    "OCT": 6,
    "NOV": 6.04,
    "DEC": 5.7
  },
  {
    "LONG": -35.27,
    "LAT": -5.91,
    "CIDADE": "Parnamirim",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.65,
    "JAN": 5.72,
    "FEB": 5.91,
    "MAR": 6.05,
    "APR": 5.66,
    "MAY": 5.29,
    "JUN": 4.9,
    "JUL": 4.97,
    "AUG": 5.71,
    "SEP": 5.93,
    "OCT": 5.98,
    "NOV": 6.01,
    "DEC": 5.7
  },
  {
    "LONG": -38.06,
    "LAT": -5.78,
    "CIDADE": "Rodolfo Fernandes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.98,
    "JAN": 5.75,
    "FEB": 5.96,
    "MAR": 6.04,
    "APR": 5.85,
    "MAY": 5.59,
    "JUN": 5.31,
    "JUL": 5.65,
    "AUG": 6.27,
    "SEP": 6.46,
    "OCT": 6.55,
    "NOV": 6.44,
    "DEC": 5.87
  },
  {
    "LONG": -37.96,
    "LAT": -5.78,
    "CIDADE": "Severiano Melo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.98,
    "JAN": 5.77,
    "FEB": 5.94,
    "MAR": 6.07,
    "APR": 5.85,
    "MAY": 5.59,
    "JUN": 5.31,
    "JUL": 5.66,
    "AUG": 6.26,
    "SEP": 6.47,
    "OCT": 6.55,
    "NOV": 6.41,
    "DEC": 5.87
  },
  {
    "LONG": -37.99,
    "LAT": -5.84,
    "CIDADE": "Itaú",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.98,
    "JAN": 5.77,
    "FEB": 5.94,
    "MAR": 6.07,
    "APR": 5.85,
    "MAY": 5.59,
    "JUN": 5.31,
    "JUL": 5.66,
    "AUG": 6.26,
    "SEP": 6.47,
    "OCT": 6.55,
    "NOV": 6.41,
    "DEC": 5.87
  },
  {
    "LONG": -37.56,
    "LAT": -5.78,
    "CIDADE": "Caraúbas",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.94,
    "JAN": 5.74,
    "FEB": 6,
    "MAR": 6.04,
    "APR": 5.9,
    "MAY": 5.6,
    "JUN": 5.29,
    "JUL": 5.64,
    "AUG": 6.15,
    "SEP": 6.39,
    "OCT": 6.41,
    "NOV": 6.33,
    "DEC": 5.78
  },
  {
    "LONG": -3.71,
    "LAT": -5.78,
    "CIDADE": "Paraú",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.97,
    "JAN": 5.84,
    "FEB": 6.03,
    "MAR": 6.2,
    "APR": 5.95,
    "MAY": 5.62,
    "JUN": 5.22,
    "JUL": 5.49,
    "AUG": 6.1,
    "SEP": 6.44,
    "OCT": 6.48,
    "NOV": 6.32,
    "DEC": 5.9
  },
  {
    "LONG": -36.89,
    "LAT": -5.8,
    "CIDADE": "São Rafael",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.83,
    "JAN": 5.75,
    "FEB": 5.83,
    "MAR": 6.11,
    "APR": 5.95,
    "MAY": 5.49,
    "JUN": 5.06,
    "JUL": 5.31,
    "AUG": 5.93,
    "SEP": 6.27,
    "OCT": 6.23,
    "NOV": 6.18,
    "DEC": 5.83
  },
  {
    "LONG": -36,
    "LAT": -5.76,
    "CIDADE": "Caiçara do Rio do Vento",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.58,
    "JAN": 5.48,
    "FEB": 5.66,
    "MAR": 5.78,
    "APR": 5.53,
    "MAY": 5.26,
    "JUN": 4.91,
    "JUL": 5.12,
    "AUG": 5.72,
    "SEP": 5.98,
    "OCT": 6.05,
    "NOV": 5.97,
    "DEC": 5.51
  },
  {
    "LONG": -3.58,
    "LAT": -5.81,
    "CIDADE": "Riachuelo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.48,
    "JAN": 5.5,
    "FEB": 5.59,
    "MAR": 5.75,
    "APR": 5.51,
    "MAY": 5.17,
    "JUN": 4.75,
    "JUL": 5,
    "AUG": 5.51,
    "SEP": 5.79,
    "OCT": 5.86,
    "NOV": 5.87,
    "DEC": 5.5
  },
  {
    "LONG": -35.69,
    "LAT": -5.84,
    "CIDADE": "Santa Maria",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.54,
    "JAN": 5.58,
    "FEB": 5.73,
    "MAR": 5.85,
    "APR": 5.6,
    "MAY": 5.19,
    "JUN": 4.77,
    "JUL": 4.92,
    "AUG": 5.53,
    "SEP": 5.84,
    "OCT": 5.9,
    "NOV": 5.97,
    "DEC": 5.56
  },
  {
    "LONG": -35.55,
    "LAT": -5.82,
    "CIDADE": "Ielmo Marinho",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.58,
    "JAN": 5.66,
    "FEB": 5.79,
    "MAR": 5.94,
    "APR": 5.63,
    "MAY": 5.2,
    "JUN": 4.8,
    "JUL": 4.96,
    "AUG": 5.56,
    "SEP": 5.86,
    "OCT": 5.94,
    "NOV": 6.03,
    "DEC": 5.61
  },
  {
    "LONG": -35.33,
    "LAT": -5.79,
    "CIDADE": "São Gonçalo do Amarante",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.66,
    "JAN": 5.75,
    "FEB": 5.92,
    "MAR": 6.1,
    "APR": 5.68,
    "MAY": 5.3,
    "JUN": 4.84,
    "JUL": 4.93,
    "AUG": 5.65,
    "SEP": 5.97,
    "OCT": 6.03,
    "NOV": 6.07,
    "DEC": 5.71
  },
  {
    "LONG": -35.2,
    "LAT": -5.79,
    "CIDADE": "Natal",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.68,
    "JAN": 5.79,
    "FEB": 5.9,
    "MAR": 6.07,
    "APR": 5.68,
    "MAY": 5.28,
    "JUN": 4.89,
    "JUL": 4.99,
    "AUG": 5.74,
    "SEP": 6,
    "OCT": 6.01,
    "NOV": 6.06,
    "DEC": 5.71
  },
  {
    "LONG": -37.79,
    "LAT": -5.65,
    "CIDADE": "Apodi",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.95,
    "JAN": 5.74,
    "FEB": 6.02,
    "MAR": 6.06,
    "APR": 5.89,
    "MAY": 5.57,
    "JUN": 5.29,
    "JUL": 5.58,
    "AUG": 6.15,
    "SEP": 6.4,
    "OCT": 6.47,
    "NOV": 6.31,
    "DEC": 5.9
  },
  {
    "LONG": -36.61,
    "LAT": -5.66,
    "CIDADE": "Angicos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.69,
    "FEB": 5.78,
    "MAR": 5.97,
    "APR": 5.77,
    "MAY": 5.48,
    "JUN": 5.05,
    "JUL": 5.31,
    "AUG": 5.99,
    "SEP": 6.3,
    "OCT": 6.37,
    "NOV": 6.22,
    "DEC": 5.76
  },
  {
    "LONG": -36.53,
    "LAT": -5.69,
    "CIDADE": "Fernando Pedroza",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.78,
    "JAN": 5.69,
    "FEB": 5.79,
    "MAR": 5.98,
    "APR": 5.67,
    "MAY": 5.43,
    "JUN": 5.04,
    "JUL": 5.31,
    "AUG": 5.96,
    "SEP": 6.27,
    "OCT": 6.32,
    "NOV": 6.2,
    "DEC": 5.74
  },
  {
    "LONG": -36.25,
    "LAT": -5.7,
    "CIDADE": "Lajes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.77,
    "JAN": 5.72,
    "FEB": 5.84,
    "MAR": 5.88,
    "APR": 5.73,
    "MAY": 5.45,
    "JUN": 5,
    "JUL": 5.25,
    "AUG": 5.93,
    "SEP": 6.21,
    "OCT": 6.32,
    "NOV": 6.19,
    "DEC": 5.75
  },
  {
    "LONG": -35.82,
    "LAT": -5.69,
    "CIDADE": "Bento Fernandes",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.56,
    "JAN": 5.55,
    "FEB": 5.63,
    "MAR": 5.76,
    "APR": 5.61,
    "MAY": 5.23,
    "JUN": 4.86,
    "JUL": 5.06,
    "AUG": 5.68,
    "SEP": 5.93,
    "OCT": 5.97,
    "NOV": 5.97,
    "DEC": 5.53
  },
  {
    "LONG": -35.29,
    "LAT": -5.7,
    "CIDADE": "Extremoz",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.69,
    "JAN": 5.77,
    "FEB": 5.92,
    "MAR": 6.02,
    "APR": 5.7,
    "MAY": 5.32,
    "JUN": 4.93,
    "JUL": 5.09,
    "AUG": 5.79,
    "SEP": 6.04,
    "OCT": 5.98,
    "NOV": 6.05,
    "DEC": 5.72
  },
  {
    "LONG": -37.7,
    "LAT": -0.56,
    "CIDADE": "Felipe Guerra",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.91,
    "JAN": 5.75,
    "FEB": 5.92,
    "MAR": 5.95,
    "APR": 5.83,
    "MAY": 5.55,
    "JUN": 5.23,
    "JUL": 5.63,
    "AUG": 6.2,
    "SEP": 6.38,
    "OCT": 6.45,
    "NOV": 6.3,
    "DEC": 5.78
  },
  {
    "LONG": -37.26,
    "LAT": -5.64,
    "CIDADE": "Upanema",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.92,
    "JAN": 5.75,
    "FEB": 5.86,
    "MAR": 6.02,
    "APR": 5.91,
    "MAY": 5.59,
    "JUN": 5.3,
    "JUL": 5.53,
    "AUG": 6.16,
    "SEP": 6.41,
    "OCT": 6.46,
    "NOV": 6.31,
    "DEC": 5.78
  },
  {
    "LONG": -36.91,
    "LAT": -5.58,
    "CIDADE": "Açu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.9,
    "JAN": 5.71,
    "FEB": 5.92,
    "MAR": 5.99,
    "APR": 5.88,
    "MAY": 5.59,
    "JUN": 5.22,
    "JUL": 5.51,
    "AUG": 6.11,
    "SEP": 6.37,
    "OCT": 6.39,
    "NOV": 6.29,
    "DEC": 5.77
  },
  {
    "LONG": -36.87,
    "LAT": -5.64,
    "CIDADE": "Itajá",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.63,
    "FEB": 5.88,
    "MAR": 5.93,
    "APR": 5.76,
    "MAY": 5.48,
    "JUN": 5.08,
    "JUL": 5.35,
    "AUG": 6.01,
    "SEP": 6.3,
    "OCT": 6.3,
    "NOV": 6.21,
    "DEC": 5.75
  },
  {
    "LONG": -36.1,
    "LAT": -5.58,
    "CIDADE": "Pedra Preta",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.69,
    "JAN": 5.63,
    "FEB": 5.71,
    "MAR": 5.77,
    "APR": 5.62,
    "MAY": 5.37,
    "JUN": 5.03,
    "JUL": 5.24,
    "AUG": 5.89,
    "SEP": 6.11,
    "OCT": 6.19,
    "NOV": 6.1,
    "DEC": 5.66
  },
  {
    "LONG": -35.97,
    "LAT": -5.65,
    "CIDADE": "Jardim de Angicos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.59,
    "JAN": 5.54,
    "FEB": 5.65,
    "MAR": 5.76,
    "APR": 5.61,
    "MAY": 5.26,
    "JUN": 4.91,
    "JUL": 5.11,
    "AUG": 5.69,
    "SEP": 5.93,
    "OCT": 6.02,
    "NOV": 6,
    "DEC": 5.54
  },
  {
    "LONG": -35.66,
    "LAT": -5.62,
    "CIDADE": "Poço Branco",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.58,
    "JAN": 5.64,
    "FEB": 5.82,
    "MAR": 5.87,
    "APR": 5.65,
    "MAY": 5.22,
    "JUN": 4.85,
    "JUL": 4.96,
    "AUG": 5.6,
    "SEP": 5.93,
    "OCT": 5.94,
    "NOV": 5.99,
    "DEC": 5.56
  },
  {
    "LONG": -35.6,
    "LAT": -5.63,
    "CIDADE": "Taipu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.62,
    "JAN": 5.68,
    "FEB": 5.9,
    "MAR": 5.98,
    "APR": 5.65,
    "MAY": 5.21,
    "JUN": 4.86,
    "JUL": 4.95,
    "AUG": 5.59,
    "SEP": 5.96,
    "OCT": 5.98,
    "NOV": 6.03,
    "DEC": 5.62
  },
  {
    "LONG": "#VALOR!",
    "LAT": -5.64,
    "CIDADE": "Ceará-Mirim",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.64,
    "JAN": 5.69,
    "FEB": 5.94,
    "MAR": 6.05,
    "APR": 5.67,
    "MAY": 5.2,
    "JUN": 4.88,
    "JUL": 4.95,
    "AUG": 5.6,
    "SEP": 5.98,
    "OCT": 6.02,
    "NOV": 6.04,
    "DEC": 5.68
  },
  {
    "LONG": -36.86,
    "LAT": -5.51,
    "CIDADE": "Ipanguaçu",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.69,
    "FEB": 5.84,
    "MAR": 5.94,
    "APR": 5.72,
    "MAY": 5.53,
    "JUN": 5.07,
    "JUL": 5.33,
    "AUG": 6.02,
    "SEP": 6.3,
    "OCT": 6.3,
    "NOV": 6.19,
    "DEC": 5.78
  },
  {
    "LONG": -36.51,
    "LAT": -5.49,
    "CIDADE": "Afonso Bezerra",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.7,
    "JAN": 5.54,
    "FEB": 5.75,
    "MAR": 5.76,
    "APR": 5.62,
    "MAY": 5.36,
    "JUN": 5,
    "JUL": 5.26,
    "AUG": 5.91,
    "SEP": 6.16,
    "OCT": 6.24,
    "NOV": 6.17,
    "DEC": 5.63
  },
  {
    "LONG": -3.64,
    "LAT": -5.52,
    "CIDADE": "Pedro Avelino",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.69,
    "JAN": 5.55,
    "FEB": 5.74,
    "MAR": 5.73,
    "APR": 5.56,
    "MAY": 5.38,
    "JUN": 5.02,
    "JUL": 5.23,
    "AUG": 5.91,
    "SEP": 6.12,
    "OCT": 6.2,
    "NOV": 6.12,
    "DEC": 5.67
  },
  {
    "LONG": -35.82,
    "LAT": -5.54,
    "CIDADE": "João Câmara",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.59,
    "JAN": 5.59,
    "FEB": 5.67,
    "MAR": 5.78,
    "APR": 5.58,
    "MAY": 5.23,
    "JUN": 4.9,
    "JUL": 5.07,
    "AUG": 5.68,
    "SEP": 5.98,
    "OCT": 6.01,
    "NOV": 6.02,
    "DEC": 5.6
  },
  {
    "LONG": -35.56,
    "LAT": -5.46,
    "CIDADE": "Pureza",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.61,
    "JAN": 5.61,
    "FEB": 5.87,
    "MAR": 5.97,
    "APR": 5.6,
    "MAY": 5.23,
    "JUN": 4.87,
    "JUL": 4.94,
    "AUG": 5.61,
    "SEP": 5.96,
    "OCT": 5.95,
    "NOV": 6.01,
    "DEC": 5.65
  },
  {
    "LONG": -3.53,
    "LAT": -5.52,
    "CIDADE": "Maxaranguape",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.85,
    "JAN": 5.93,
    "FEB": 6.09,
    "MAR": 6.11,
    "APR": 5.81,
    "MAY": 5.44,
    "JUN": 5.05,
    "JUL": 5.2,
    "AUG": 5.94,
    "SEP": 6.26,
    "OCT": 6.22,
    "NOV": 6.26,
    "DEC": 5.94
  },
  {
    "LONG": -37.52,
    "LAT": -5.45,
    "CIDADE": "Governador Dix-Sept Rosado",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.86,
    "JAN": 5.58,
    "FEB": 5.71,
    "MAR": 5.89,
    "APR": 5.76,
    "MAY": 5.55,
    "JUN": 5.28,
    "JUL": 5.56,
    "AUG": 6.17,
    "SEP": 6.39,
    "OCT": 6.41,
    "NOV": 6.29,
    "DEC": 5.73
  },
  {
    "LONG": -36.13,
    "LAT": -5.35,
    "CIDADE": "Jandaíra",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.68,
    "JAN": 5.58,
    "FEB": 5.68,
    "MAR": 5.72,
    "APR": 5.58,
    "MAY": 5.4,
    "JUN": 5.06,
    "JUL": 5.3,
    "AUG": 5.92,
    "SEP": 6.07,
    "OCT": 6.16,
    "NOV": 6.06,
    "DEC": 5.62
  },
  {
    "LONG": -36.83,
    "LAT": -5.34,
    "CIDADE": "Carnaubais",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.81,
    "JAN": 5.72,
    "FEB": 5.8,
    "MAR": 5.91,
    "APR": 5.62,
    "MAY": 5.5,
    "JUN": 5.1,
    "JUL": 5.31,
    "AUG": 5.98,
    "SEP": 6.26,
    "OCT": 6.33,
    "NOV": 6.27,
    "DEC": 5.87
  },
  {
    "LONG": -36.72,
    "LAT": -5.26,
    "CIDADE": "Pendências",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.74,
    "JAN": 5.63,
    "FEB": 5.78,
    "MAR": 5.86,
    "APR": 5.63,
    "MAY": 5.41,
    "JUN": 5.02,
    "JUL": 5.27,
    "AUG": 5.85,
    "SEP": 6.18,
    "OCT": 6.26,
    "NOV": 6.19,
    "DEC": 5.8
  },
  {
    "LONG": -36.76,
    "LAT": -5.29,
    "CIDADE": "Alto do Rodrigues",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.74,
    "JAN": 5.63,
    "FEB": 5.78,
    "MAR": 5.86,
    "APR": 5.63,
    "MAY": 5.41,
    "JUN": 5.02,
    "JUL": 5.27,
    "AUG": 5.85,
    "SEP": 6.18,
    "OCT": 6.26,
    "NOV": 6.19,
    "DEC": 5.8
  },
  {
    "LONG": -35.38,
    "LAT": -5.27,
    "CIDADE": "Rio do Fogo",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.86,
    "JAN": 5.94,
    "FEB": 6.07,
    "MAR": 6.11,
    "APR": 5.82,
    "MAY": 5.43,
    "JUN": 5.07,
    "JUL": 5.22,
    "AUG": 5.98,
    "SEP": 6.26,
    "OCT": 6.17,
    "NOV": 6.3,
    "DEC": 5.98
  },
  {
    "LONG": -37.35,
    "LAT": -5.18,
    "CIDADE": "Mossoró",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.8,
    "JAN": 5.64,
    "FEB": 5.74,
    "MAR": 5.92,
    "APR": 5.66,
    "MAY": 5.46,
    "JUN": 5.15,
    "JUL": 5.43,
    "AUG": 6.02,
    "SEP": 6.27,
    "OCT": 6.29,
    "NOV": 6.25,
    "DEC": 5.84
  },
  {
    "LONG": "#VALOR!",
    "LAT": -5.17,
    "CIDADE": "Serra do Mel",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.84,
    "JAN": 5.71,
    "FEB": 5.76,
    "MAR": 5.8,
    "APR": 5.56,
    "MAY": 5.5,
    "JUN": 5.23,
    "JUL": 5.44,
    "AUG": 6.08,
    "SEP": 6.42,
    "OCT": 6.38,
    "NOV": 6.31,
    "DEC": 5.87
  },
  {
    "LONG": -35.84,
    "LAT": -5.22,
    "CIDADE": "Parazinho",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.65,
    "JAN": 5.64,
    "FEB": 5.73,
    "MAR": 5.85,
    "APR": 5.54,
    "MAY": 5.34,
    "JUN": 5.04,
    "JUL": 5.22,
    "AUG": 5.83,
    "SEP": 5.96,
    "OCT": 6.02,
    "NOV": 6.02,
    "DEC": 5.59
  },
  {
    "LONG": -35.46,
    "LAT": -5.2,
    "CIDADE": "Touros",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.83,
    "JAN": 5.96,
    "FEB": 6.09,
    "MAR": 6.09,
    "APR": 5.76,
    "MAY": 5.41,
    "JUN": 5.1,
    "JUL": 5.25,
    "AUG": 5.88,
    "SEP": 6.23,
    "OCT": 6.08,
    "NOV": 6.17,
    "DEC": 5.87
  },
  {
    "LONG": -37.61,
    "LAT": -5.07,
    "CIDADE": "Baraúna",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.83,
    "JAN": 5.56,
    "FEB": 5.69,
    "MAR": 5.86,
    "APR": 5.63,
    "MAY": 5.5,
    "JUN": 5.33,
    "JUL": 5.63,
    "AUG": 6.11,
    "SEP": 6.39,
    "OCT": 6.32,
    "NOV": 6.18,
    "DEC": 5.78
  },
  {
    "LONG": "#VALOR!",
    "LAT": -5.07,
    "CIDADE": "Porto do Mangue",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.82,
    "JAN": 6.02,
    "FEB": 5.94,
    "MAR": 5.97,
    "APR": 5.77,
    "MAY": 5.42,
    "JUN": 5.13,
    "JUL": 5.3,
    "AUG": 5.89,
    "SEP": 6.2,
    "OCT": 6.02,
    "NOV": 6.1,
    "DEC": 6.03
  },
  {
    "LONG": -36.63,
    "LAT": -0.51,
    "CIDADE": "Macau",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.85,
    "JAN": 6.02,
    "FEB": 5.96,
    "MAR": 5.97,
    "APR": 5.74,
    "MAY": 5.51,
    "JUN": 5.11,
    "JUL": 5.31,
    "AUG": 5.92,
    "SEP": 6.29,
    "OCT": 6.16,
    "NOV": 6.17,
    "DEC": 6.07
  },
  {
    "LONG": -36.32,
    "LAT": -5.11,
    "CIDADE": "Guamaré",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.62,
    "JAN": 5.73,
    "FEB": 5.72,
    "MAR": 5.8,
    "APR": 5.52,
    "MAY": 5.31,
    "JUN": 4.87,
    "JUL": 5.03,
    "AUG": 5.62,
    "SEP": 6.09,
    "OCT": 5.96,
    "NOV": 6,
    "DEC": 5.77
  },
  {
    "LONG": -36.28,
    "LAT": -5.09,
    "CIDADE": "Galinhos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.75,
    "JAN": 5.84,
    "FEB": 5.81,
    "MAR": 5.88,
    "APR": 5.63,
    "MAY": 5.47,
    "JUN": 5.03,
    "JUL": 5.18,
    "AUG": 5.82,
    "SEP": 6.1,
    "OCT": 6.06,
    "NOV": 6.2,
    "DEC": 5.97
  },
  {
    "LONG": -36.05,
    "LAT": -5.06,
    "CIDADE": "Caiçara do Norte",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.59,
    "JAN": 5.65,
    "FEB": 5.71,
    "MAR": 5.85,
    "APR": 5.43,
    "MAY": 5.25,
    "JUN": 4.9,
    "JUL": 5.12,
    "AUG": 5.62,
    "SEP": 5.85,
    "OCT": 5.93,
    "NOV": 6.04,
    "DEC": 5.72
  },
  {
    "LONG": -35.98,
    "LAT": -5.11,
    "CIDADE": "São Bento do Norte",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.63,
    "JAN": 5.68,
    "FEB": 5.79,
    "MAR": 5.92,
    "APR": 5.51,
    "MAY": 5.26,
    "JUN": 4.96,
    "JUL": 5.08,
    "AUG": 5.71,
    "SEP": 5.85,
    "OCT": 5.95,
    "NOV": 6.09,
    "DEC": 5.73
  },
  {
    "LONG": -35.88,
    "LAT": -5.15,
    "CIDADE": "Pedra Grande",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.58,
    "JAN": 5.67,
    "FEB": 5.82,
    "MAR": 5.92,
    "APR": 5.5,
    "MAY": 5.2,
    "JUN": 4.9,
    "JUL": 5.01,
    "AUG": 5.63,
    "SEP": 5.83,
    "OCT": 5.84,
    "NOV": 5.96,
    "DEC": 5.63
  },
  {
    "LONG": -35.64,
    "LAT": -5.13,
    "CIDADE": "São Miguel do Gostoso",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.86,
    "JAN": 6.02,
    "FEB": 6.04,
    "MAR": 6.17,
    "APR": 5.72,
    "MAY": 5.49,
    "JUN": 5.04,
    "JUL": 5.2,
    "AUG": 5.91,
    "SEP": 6.21,
    "OCT": 6.21,
    "NOV": 6.32,
    "DEC": 5.98
  },
  {
    "LONG": -37.13,
    "LAT": -4.95,
    "CIDADE": "Areia Branca",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.75,
    "JAN": 5.78,
    "FEB": 5.76,
    "MAR": 5.82,
    "APR": 5.58,
    "MAY": 5.45,
    "JUN": 5.12,
    "JUL": 5.36,
    "AUG": 5.86,
    "SEP": 6.21,
    "OCT": 6,
    "NOV": 6.07,
    "DEC": 5.94
  },
  {
    "LONG": -37.16,
    "LAT": -4.98,
    "CIDADE": "Grossos",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.75,
    "JAN": 5.78,
    "FEB": 5.76,
    "MAR": 5.82,
    "APR": 5.58,
    "MAY": 5.45,
    "JUN": 5.12,
    "JUL": 5.36,
    "AUG": 5.86,
    "SEP": 6.21,
    "OCT": 6,
    "NOV": 6.07,
    "DEC": 5.94
  },
  {
    "LONG": -37.26,
    "LAT": -4.84,
    "CIDADE": "Tibau",
    "UF": "RIO GRANDE DO NORTE",
    "anual": 5.96,
    "JAN": 6.11,
    "FEB": 6.14,
    "MAR": 6.12,
    "APR": 5.87,
    "MAY": 5.71,
    "JUN": 5.47,
    "JUL": 5.67,
    "AUG": 6.19,
    "SEP": 6.1,
    "OCT": 5.97,
    "NOV": 6.06,
    "DEC": 6.16
  },
  {
    "LONG": -53.46,
    "LAT": -33.69,
    "CIDADE": "Chuí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.78,
    "JAN": 5.79,
    "FEB": 5.55,
    "MAR": 5.55,
    "APR": 4.65,
    "MAY": 3.69,
    "JUN": 3.34,
    "JUL": 3.39,
    "AUG": 3.93,
    "SEP": 4.39,
    "OCT": 5.24,
    "NOV": 5.84,
    "DEC": 5.96
  },
  {
    "LONG": -53.37,
    "LAT": -33.53,
    "CIDADE": "Santa Vitória do Palmar",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.75,
    "FEB": 5.51,
    "MAR": 5.5,
    "APR": 4.66,
    "MAY": 3.71,
    "JUN": 3.33,
    "JUL": 3.37,
    "AUG": 3.91,
    "SEP": 4.36,
    "OCT": 5.19,
    "NOV": 5.78,
    "DEC": 5.88
  },
  {
    "LONG": -53.38,
    "LAT": -32.56,
    "CIDADE": "Jaguarão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.72,
    "FEB": 5.58,
    "MAR": 5.39,
    "APR": 4.66,
    "MAY": 3.73,
    "JUN": 3.28,
    "JUL": 3.4,
    "AUG": 3.92,
    "SEP": 4.23,
    "OCT": 5.04,
    "NOV": 5.75,
    "DEC": 5.87
  },
  {
    "LONG": -53.09,
    "LAT": -32.23,
    "CIDADE": "Arroio Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.58,
    "FEB": 5.39,
    "MAR": 5.24,
    "APR": 4.6,
    "MAY": 3.69,
    "JUN": 3.3,
    "JUL": 3.44,
    "AUG": 3.93,
    "SEP": 4.09,
    "OCT": 4.9,
    "NOV": 5.59,
    "DEC": 5.72
  },
  {
    "LONG": -53.39,
    "LAT": -32.02,
    "CIDADE": "Herval",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.5,
    "FEB": 5.44,
    "MAR": 5.28,
    "APR": 4.66,
    "MAY": 3.68,
    "JUN": 3.32,
    "JUL": 3.54,
    "AUG": 3.97,
    "SEP": 4.12,
    "OCT": 4.97,
    "NOV": 5.66,
    "DEC": 5.78
  },
  {
    "LONG": -52.11,
    "LAT": -32.04,
    "CIDADE": "Rio Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.58,
    "FEB": 5.5,
    "MAR": 5.3,
    "APR": 4.57,
    "MAY": 3.69,
    "JUN": 3.25,
    "JUL": 3.41,
    "AUG": 3.91,
    "SEP": 4.09,
    "OCT": 4.91,
    "NOV": 5.6,
    "DEC": 5.76
  },
  {
    "LONG": -52.03,
    "LAT": -32.01,
    "CIDADE": "São José do Norte",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.6,
    "FEB": 5.52,
    "MAR": 5.3,
    "APR": 4.59,
    "MAY": 3.69,
    "JUN": 3.26,
    "JUL": 3.4,
    "AUG": 3.9,
    "SEP": 4.09,
    "OCT": 4.89,
    "NOV": 5.59,
    "DEC": 5.74
  },
  {
    "LONG": -54.16,
    "LAT": -3.19,
    "CIDADE": "Aceguá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.77,
    "FEB": 5.69,
    "MAR": 5.53,
    "APR": 4.77,
    "MAY": 3.76,
    "JUN": 3.36,
    "JUL": 3.6,
    "AUG": 4.09,
    "SEP": 4.36,
    "OCT": 5.19,
    "NOV": 5.83,
    "DEC": 5.96
  },
  {
    "LONG": -52.82,
    "LAT": -31.86,
    "CIDADE": "Pedro Osório",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.54,
    "FEB": 5.43,
    "MAR": 5.23,
    "APR": 4.61,
    "MAY": 3.68,
    "JUN": 3.31,
    "JUL": 3.47,
    "AUG": 3.93,
    "SEP": 4.04,
    "OCT": 4.87,
    "NOV": 5.58,
    "DEC": 5.72
  },
  {
    "LONG": -52.8,
    "LAT": -31.84,
    "CIDADE": "Cerrito",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.48,
    "FEB": 5.4,
    "MAR": 5.17,
    "APR": 4.56,
    "MAY": 3.65,
    "JUN": 3.26,
    "JUL": 3.47,
    "AUG": 3.92,
    "SEP": 4,
    "OCT": 4.85,
    "NOV": 5.56,
    "DEC": 5.68
  },
  {
    "LONG": -52.49,
    "LAT": -31.76,
    "CIDADE": "Capão do Leão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.45,
    "FEB": 5.35,
    "MAR": 5.17,
    "APR": 4.53,
    "MAY": 3.67,
    "JUN": 3.29,
    "JUL": 3.45,
    "AUG": 3.9,
    "SEP": 3.98,
    "OCT": 4.76,
    "NOV": 5.5,
    "DEC": 5.64
  },
  {
    "LONG": -52.34,
    "LAT": -31.77,
    "CIDADE": "Pelotas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.49,
    "FEB": 5.41,
    "MAR": 5.23,
    "APR": 4.57,
    "MAY": 3.68,
    "JUN": 3.27,
    "JUL": 3.4,
    "AUG": 3.88,
    "SEP": 3.98,
    "OCT": 4.79,
    "NOV": 5.54,
    "DEC": 5.68
  },
  {
    "LONG": -53.58,
    "LAT": -31.74,
    "CIDADE": "Pedras Altas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.74,
    "JAN": 5.63,
    "FEB": 5.58,
    "MAR": 5.37,
    "APR": 4.68,
    "MAY": 3.71,
    "JUN": 3.35,
    "JUL": 3.62,
    "AUG": 4.08,
    "SEP": 4.25,
    "OCT": 5.06,
    "NOV": 5.74,
    "DEC": 5.86
  },
  {
    "LONG": -53.67,
    "LAT": -31.56,
    "CIDADE": "Candiota",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.61,
    "FEB": 5.59,
    "MAR": 5.39,
    "APR": 4.69,
    "MAY": 3.72,
    "JUN": 3.35,
    "JUL": 3.6,
    "AUG": 4.11,
    "SEP": 4.24,
    "OCT": 5.05,
    "NOV": 5.75,
    "DEC": 5.88
  },
  {
    "LONG": -53.38,
    "LAT": -31.58,
    "CIDADE": "Pinheiro Machado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.51,
    "FEB": 5.55,
    "MAR": 5.31,
    "APR": 4.65,
    "MAY": 3.68,
    "JUN": 3.34,
    "JUL": 3.56,
    "AUG": 4.07,
    "SEP": 4.16,
    "OCT": 4.97,
    "NOV": 5.68,
    "DEC": 5.83
  },
  {
    "LONG": -52.63,
    "LAT": -31.59,
    "CIDADE": "Morro Redondo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.43,
    "FEB": 5.31,
    "MAR": 5.14,
    "APR": 4.53,
    "MAY": 3.66,
    "JUN": 3.24,
    "JUL": 3.48,
    "AUG": 3.95,
    "SEP": 3.96,
    "OCT": 4.75,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -53.87,
    "LAT": -31.41,
    "CIDADE": "Hulha Negra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.66,
    "FEB": 5.67,
    "MAR": 5.49,
    "APR": 4.75,
    "MAY": 3.76,
    "JUN": 3.36,
    "JUL": 3.64,
    "AUG": 4.15,
    "SEP": 4.28,
    "OCT": 5.08,
    "NOV": 5.77,
    "DEC": 5.87
  },
  {
    "LONG": -53.1,
    "LAT": -31.45,
    "CIDADE": "Piratini",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.5,
    "FEB": 5.49,
    "MAR": 5.26,
    "APR": 4.63,
    "MAY": 3.72,
    "JUN": 3.3,
    "JUL": 3.57,
    "AUG": 4.08,
    "SEP": 4.15,
    "OCT": 4.92,
    "NOV": 5.64,
    "DEC": 5.77
  },
  {
    "LONG": -52.68,
    "LAT": -31.4,
    "CIDADE": "Canguçu",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.4,
    "FEB": 5.38,
    "MAR": 5.13,
    "APR": 4.58,
    "MAY": 3.69,
    "JUN": 3.29,
    "JUL": 3.53,
    "AUG": 4.01,
    "SEP": 4.04,
    "OCT": 4.79,
    "NOV": 5.47,
    "DEC": 5.66
  },
  {
    "LONG": -52.42,
    "LAT": -31.44,
    "CIDADE": "Arroio do Padre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.37,
    "FEB": 5.31,
    "MAR": 5.08,
    "APR": 4.56,
    "MAY": 3.67,
    "JUN": 3.27,
    "JUL": 3.52,
    "AUG": 3.96,
    "SEP": 3.95,
    "OCT": 4.72,
    "NOV": 5.41,
    "DEC": 5.54
  },
  {
    "LONG": -52.18,
    "LAT": -3.14,
    "CIDADE": "Turuçu",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.45,
    "FEB": 5.35,
    "MAR": 5.12,
    "APR": 4.57,
    "MAY": 3.72,
    "JUN": 3.31,
    "JUL": 3.49,
    "AUG": 3.97,
    "SEP": 3.96,
    "OCT": 4.68,
    "NOV": 5.47,
    "DEC": 5.61
  },
  {
    "LONG": -51.97,
    "LAT": -3.14,
    "CIDADE": "São Lourenço do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.68,
    "FEB": 5.58,
    "MAR": 5.32,
    "APR": 4.63,
    "MAY": 3.74,
    "JUN": 3.3,
    "JUL": 3.44,
    "AUG": 3.94,
    "SEP": 4.09,
    "OCT": 4.84,
    "NOV": 5.65,
    "DEC": 5.78
  },
  {
    "LONG": -54.11,
    "LAT": -3.13,
    "CIDADE": "Bagé",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.67,
    "FEB": 5.72,
    "MAR": 5.48,
    "APR": 4.75,
    "MAY": 3.79,
    "JUN": 3.38,
    "JUL": 3.68,
    "AUG": 4.2,
    "SEP": 4.34,
    "OCT": 5.14,
    "NOV": 5.8,
    "DEC": 5.9
  },
  {
    "LONG": -51.09,
    "LAT": -31.29,
    "CIDADE": "Tavares",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.79,
    "FEB": 5.59,
    "MAR": 5.31,
    "APR": 4.72,
    "MAY": 3.76,
    "JUN": 3.31,
    "JUL": 3.49,
    "AUG": 4.01,
    "SEP": 4.07,
    "OCT": 4.82,
    "NOV": 5.65,
    "DEC": 5.77
  },
  {
    "LONG": -50.92,
    "LAT": -31.11,
    "CIDADE": "Mostardas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.66,
    "FEB": 5.58,
    "MAR": 5.25,
    "APR": 4.69,
    "MAY": 3.74,
    "JUN": 3.26,
    "JUL": 3.45,
    "AUG": 4,
    "SEP": 4.02,
    "OCT": 4.77,
    "NOV": 5.58,
    "DEC": 5.74
  },
  {
    "LONG": -54.67,
    "LAT": -30.98,
    "CIDADE": "Dom Pedrito",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.86,
    "FEB": 5.78,
    "MAR": 5.58,
    "APR": 4.88,
    "MAY": 3.86,
    "JUN": 3.42,
    "JUL": 3.68,
    "AUG": 4.29,
    "SEP": 4.37,
    "OCT": 5.23,
    "NOV": 5.81,
    "DEC": 5.95
  },
  {
    "LONG": -52.05,
    "LAT": -31,
    "CIDADE": "Cristal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.52,
    "FEB": 5.44,
    "MAR": 5.16,
    "APR": 4.61,
    "MAY": 3.72,
    "JUN": 3.27,
    "JUL": 3.46,
    "AUG": 3.98,
    "SEP": 3.99,
    "OCT": 4.69,
    "NOV": 5.48,
    "DEC": 5.63
  },
  {
    "LONG": -5.55,
    "LAT": -30.89,
    "CIDADE": "Sant'Ana do Livramento",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.84,
    "FEB": 5.75,
    "MAR": 5.56,
    "APR": 4.95,
    "MAY": 3.84,
    "JUN": 3.37,
    "JUL": 3.75,
    "AUG": 4.34,
    "SEP": 4.45,
    "OCT": 5.26,
    "NOV": 5.79,
    "DEC": 5.97
  },
  {
    "LONG": -53.11,
    "LAT": -3.09,
    "CIDADE": "Santana da Boa Vista",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.56,
    "FEB": 5.62,
    "MAR": 5.35,
    "APR": 4.68,
    "MAY": 3.72,
    "JUN": 3.28,
    "JUL": 3.54,
    "AUG": 4.11,
    "SEP": 4.17,
    "OCT": 4.95,
    "NOV": 5.64,
    "DEC": 5.84
  },
  {
    "LONG": -52.25,
    "LAT": -30.88,
    "CIDADE": "Amaral Ferrador",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.53,
    "FEB": 5.44,
    "MAR": 5.15,
    "APR": 4.59,
    "MAY": 3.72,
    "JUN": 3.28,
    "JUL": 3.47,
    "AUG": 3.99,
    "SEP": 4,
    "OCT": 4.7,
    "NOV": 5.51,
    "DEC": 5.68
  },
  {
    "LONG": -5.15,
    "LAT": -30.91,
    "CIDADE": "Arambaré",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.69,
    "FEB": 5.59,
    "MAR": 5.31,
    "APR": 4.66,
    "MAY": 3.81,
    "JUN": 3.26,
    "JUL": 3.5,
    "AUG": 3.99,
    "SEP": 4.04,
    "OCT": 4.71,
    "NOV": 5.57,
    "DEC": 5.7
  },
  {
    "LONG": -53.9,
    "LAT": -3.08,
    "CIDADE": "Lavras do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.65,
    "FEB": 5.66,
    "MAR": 5.46,
    "APR": 4.79,
    "MAY": 3.8,
    "JUN": 3.34,
    "JUL": 3.7,
    "AUG": 4.22,
    "SEP": 4.32,
    "OCT": 5.04,
    "NOV": 5.7,
    "DEC": 5.87
  },
  {
    "LONG": -51.97,
    "LAT": -30.75,
    "CIDADE": "Chuvisca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.5,
    "FEB": 5.38,
    "MAR": 5.09,
    "APR": 4.61,
    "MAY": 3.7,
    "JUN": 3.26,
    "JUL": 3.51,
    "AUG": 4.02,
    "SEP": 3.97,
    "OCT": 4.62,
    "NOV": 5.45,
    "DEC": 5.57
  },
  {
    "LONG": -51.8,
    "LAT": -30.85,
    "CIDADE": "Camaquã",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.49,
    "FEB": 5.31,
    "MAR": 5.08,
    "APR": 4.58,
    "MAY": 3.72,
    "JUN": 3.25,
    "JUL": 3.49,
    "AUG": 3.99,
    "SEP": 3.94,
    "OCT": 4.6,
    "NOV": 5.43,
    "DEC": 5.53
  },
  {
    "LONG": -52.1,
    "LAT": -30.7,
    "CIDADE": "Dom Feliciano",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.46,
    "FEB": 5.41,
    "MAR": 5.14,
    "APR": 4.63,
    "MAY": 3.72,
    "JUN": 3.28,
    "JUL": 3.55,
    "AUG": 4.03,
    "SEP": 3.98,
    "OCT": 4.66,
    "NOV": 5.46,
    "DEC": 5.61
  },
  {
    "LONG": -51.4,
    "LAT": -30.67,
    "CIDADE": "Tapes",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.71,
    "FEB": 5.49,
    "MAR": 5.26,
    "APR": 4.69,
    "MAY": 3.82,
    "JUN": 3.3,
    "JUL": 3.53,
    "AUG": 3.98,
    "SEP": 4.07,
    "OCT": 4.72,
    "NOV": 5.59,
    "DEC": 5.64
  },
  {
    "LONG": -51.75,
    "LAT": -30.6,
    "CIDADE": "Cerro Grande do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.51,
    "JAN": 5.4,
    "FEB": 5.24,
    "MAR": 5.04,
    "APR": 4.59,
    "MAY": 3.71,
    "JUN": 3.29,
    "JUL": 3.49,
    "AUG": 4,
    "SEP": 3.92,
    "OCT": 4.56,
    "NOV": 5.41,
    "DEC": 5.48
  },
  {
    "LONG": -51.59,
    "LAT": -30.61,
    "CIDADE": "Sentinela do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.5,
    "FEB": 5.34,
    "MAR": 5.11,
    "APR": 4.64,
    "MAY": 3.78,
    "JUN": 3.28,
    "JUL": 3.53,
    "AUG": 4,
    "SEP": 3.98,
    "OCT": 4.61,
    "NOV": 5.5,
    "DEC": 5.5
  },
  {
    "LONG": -53.48,
    "LAT": -30.51,
    "CIDADE": "Caçapava do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.66,
    "FEB": 5.66,
    "MAR": 5.44,
    "APR": 4.81,
    "MAY": 3.83,
    "JUN": 3.35,
    "JUL": 3.62,
    "AUG": 4.25,
    "SEP": 4.28,
    "OCT": 5,
    "NOV": 5.69,
    "DEC": 5.85
  },
  {
    "LONG": -52.52,
    "LAT": -30.54,
    "CIDADE": "Encruzilhada do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.64,
    "FEB": 5.57,
    "MAR": 5.29,
    "APR": 4.75,
    "MAY": 3.81,
    "JUN": 3.36,
    "JUL": 3.62,
    "AUG": 4.17,
    "SEP": 4.18,
    "OCT": 4.89,
    "NOV": 5.59,
    "DEC": 5.74
  },
  {
    "LONG": -51.6,
    "LAT": -30.46,
    "CIDADE": "Sertão Santana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.54,
    "JAN": 5.4,
    "FEB": 5.3,
    "MAR": 5.05,
    "APR": 4.62,
    "MAY": 3.74,
    "JUN": 3.33,
    "JUL": 3.53,
    "AUG": 4.03,
    "SEP": 3.92,
    "OCT": 4.58,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -56.45,
    "LAT": -30.38,
    "CIDADE": "Quaraí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.1,
    "JAN": 6,
    "FEB": 5.96,
    "MAR": 5.79,
    "APR": 5.13,
    "MAY": 4.07,
    "JUN": 3.51,
    "JUL": 3.92,
    "AUG": 4.55,
    "SEP": 4.79,
    "OCT": 5.42,
    "NOV": 5.93,
    "DEC": 6.11
  },
  {
    "LONG": -51.74,
    "LAT": -30.39,
    "CIDADE": "Barão do Triunfo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.42,
    "FEB": 5.38,
    "MAR": 5.11,
    "APR": 4.66,
    "MAY": 3.76,
    "JUN": 3.38,
    "JUL": 3.59,
    "AUG": 4.11,
    "SEP": 3.99,
    "OCT": 4.68,
    "NOV": 5.51,
    "DEC": 5.59
  },
  {
    "LONG": -51.58,
    "LAT": -3.04,
    "CIDADE": "Mariana Pimentel",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.48,
    "FEB": 5.36,
    "MAR": 5.09,
    "APR": 4.6,
    "MAY": 3.75,
    "JUN": 3.32,
    "JUL": 3.54,
    "AUG": 4.02,
    "SEP": 3.95,
    "OCT": 4.64,
    "NOV": 5.48,
    "DEC": 5.55
  },
  {
    "LONG": -54.91,
    "LAT": -3.03,
    "CIDADE": "Rosário do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.9,
    "FEB": 5.8,
    "MAR": 5.6,
    "APR": 4.92,
    "MAY": 3.93,
    "JUN": 3.42,
    "JUL": 3.72,
    "AUG": 4.37,
    "SEP": 4.45,
    "OCT": 5.19,
    "NOV": 5.75,
    "DEC": 5.99
  },
  {
    "LONG": -54.32,
    "LAT": -3.03,
    "CIDADE": "São Gabriel",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.8,
    "FEB": 5.84,
    "MAR": 5.62,
    "APR": 4.94,
    "MAY": 3.92,
    "JUN": 3.4,
    "JUL": 3.66,
    "AUG": 4.31,
    "SEP": 4.38,
    "OCT": 5.12,
    "NOV": 5.74,
    "DEC": 5.94
  },
  {
    "LONG": -54.08,
    "LAT": -30.34,
    "CIDADE": "Santa Margarida do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.77,
    "FEB": 5.82,
    "MAR": 5.55,
    "APR": 4.9,
    "MAY": 3.92,
    "JUN": 3.43,
    "JUL": 3.69,
    "AUG": 4.31,
    "SEP": 4.37,
    "OCT": 5.08,
    "NOV": 5.75,
    "DEC": 5.95
  },
  {
    "LONG": -53.88,
    "LAT": -30.35,
    "CIDADE": "Vila Nova do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.74,
    "FEB": 5.83,
    "MAR": 5.55,
    "APR": 4.91,
    "MAY": 3.9,
    "JUN": 3.42,
    "JUL": 3.66,
    "AUG": 4.32,
    "SEP": 4.34,
    "OCT": 5.07,
    "NOV": 5.77,
    "DEC": 5.92
  },
  {
    "LONG": -51.3,
    "LAT": -30.29,
    "CIDADE": "Barra do Ribeiro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.74,
    "FEB": 5.57,
    "MAR": 5.24,
    "APR": 4.71,
    "MAY": 3.79,
    "JUN": 3.33,
    "JUL": 3.58,
    "AUG": 4.07,
    "SEP": 4.05,
    "OCT": 4.77,
    "NOV": 5.58,
    "DEC": 5.7
  },
  {
    "LONG": -50.51,
    "LAT": -3.03,
    "CIDADE": "Palmares do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.75,
    "FEB": 5.7,
    "MAR": 5.27,
    "APR": 4.78,
    "MAY": 3.81,
    "JUN": 3.31,
    "JUL": 3.54,
    "AUG": 4.06,
    "SEP": 4.02,
    "OCT": 4.8,
    "NOV": 5.59,
    "DEC": 5.76
  },
  {
    "LONG": -57.55,
    "LAT": -30.2,
    "CIDADE": "Barra do Quaraí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.17,
    "JAN": 6.18,
    "FEB": 6.08,
    "MAR": 5.82,
    "APR": 5.2,
    "MAY": 4.13,
    "JUN": 3.51,
    "JUL": 4,
    "AUG": 4.63,
    "SEP": 4.88,
    "OCT": 5.55,
    "NOV": 5.96,
    "DEC": 6.17
  },
  {
    "LONG": -53.56,
    "LAT": -3.02,
    "CIDADE": "São Sepé",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.69,
    "FEB": 5.79,
    "MAR": 5.5,
    "APR": 4.81,
    "MAY": 3.85,
    "JUN": 3.33,
    "JUL": 3.55,
    "AUG": 4.25,
    "SEP": 4.27,
    "OCT": 4.99,
    "NOV": 5.71,
    "DEC": 5.88
  },
  {
    "LONG": -52.37,
    "LAT": -30.19,
    "CIDADE": "Pantano Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.69,
    "FEB": 5.72,
    "MAR": 5.36,
    "APR": 4.71,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.14,
    "SEP": 4.17,
    "OCT": 4.87,
    "NOV": 5.61,
    "DEC": 5.76
  },
  {
    "LONG": -50.22,
    "LAT": -30.17,
    "CIDADE": "Cidreira",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.67,
    "FEB": 5.55,
    "MAR": 5.17,
    "APR": 4.75,
    "MAY": 3.78,
    "JUN": 3.34,
    "JUL": 3.52,
    "AUG": 4.01,
    "SEP": 3.97,
    "OCT": 4.68,
    "NOV": 5.56,
    "DEC": 5.71
  },
  {
    "LONG": -50.23,
    "LAT": -30.24,
    "CIDADE": "Balneário Pinhal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.67,
    "FEB": 5.55,
    "MAR": 5.17,
    "APR": 4.75,
    "MAY": 3.78,
    "JUN": 3.34,
    "JUL": 3.52,
    "AUG": 4.01,
    "SEP": 3.97,
    "OCT": 4.68,
    "NOV": 5.56,
    "DEC": 5.71
  },
  {
    "LONG": -52.05,
    "LAT": -3.01,
    "CIDADE": "Minas do Leão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.67,
    "FEB": 5.63,
    "MAR": 5.3,
    "APR": 4.7,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.53,
    "AUG": 4.12,
    "SEP": 4.11,
    "OCT": 4.83,
    "NOV": 5.64,
    "DEC": 5.75
  },
  {
    "LONG": -51.96,
    "LAT": -30.12,
    "CIDADE": "Butiá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.63,
    "FEB": 5.62,
    "MAR": 5.26,
    "APR": 4.69,
    "MAY": 3.75,
    "JUN": 3.29,
    "JUL": 3.54,
    "AUG": 4.11,
    "SEP": 4.09,
    "OCT": 4.8,
    "NOV": 5.63,
    "DEC": 5.73
  },
  {
    "LONG": -51.73,
    "LAT": -3.01,
    "CIDADE": "Arroio dos Ratos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.63,
    "FEB": 5.59,
    "MAR": 5.27,
    "APR": 4.69,
    "MAY": 3.73,
    "JUN": 3.29,
    "JUL": 3.54,
    "AUG": 4.1,
    "SEP": 4.05,
    "OCT": 4.8,
    "NOV": 5.6,
    "DEC": 5.72
  },
  {
    "LONG": -51.32,
    "LAT": -30.11,
    "CIDADE": "Guaíba",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.73,
    "FEB": 5.63,
    "MAR": 5.29,
    "APR": 4.74,
    "MAY": 3.78,
    "JUN": 3.29,
    "JUL": 3.56,
    "AUG": 4.12,
    "SEP": 4.06,
    "OCT": 4.83,
    "NOV": 5.66,
    "DEC": 5.78
  },
  {
    "LONG": -51.02,
    "LAT": -30.08,
    "CIDADE": "Viamão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.58,
    "FEB": 5.5,
    "MAR": 5.15,
    "APR": 4.66,
    "MAY": 3.73,
    "JUN": 3.29,
    "JUL": 3.52,
    "AUG": 4.06,
    "SEP": 3.97,
    "OCT": 4.7,
    "NOV": 5.57,
    "DEC": 5.63
  },
  {
    "LONG": -50.51,
    "LAT": -3.01,
    "CIDADE": "Capivari do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.73,
    "FEB": 5.65,
    "MAR": 5.24,
    "APR": 4.78,
    "MAY": 3.8,
    "JUN": 3.36,
    "JUL": 3.57,
    "AUG": 4.1,
    "SEP": 3.98,
    "OCT": 4.75,
    "NOV": 5.6,
    "DEC": 5.72
  },
  {
    "LONG": -53.5,
    "LAT": -30,
    "CIDADE": "Formigueiro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.74,
    "FEB": 5.8,
    "MAR": 5.49,
    "APR": 4.81,
    "MAY": 3.82,
    "JUN": 3.32,
    "JUL": 3.54,
    "AUG": 4.25,
    "SEP": 4.27,
    "OCT": 4.94,
    "NOV": 5.69,
    "DEC": 5.9
  },
  {
    "LONG": -52.89,
    "LAT": -3,
    "CIDADE": "Cachoeira do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.4,
    "APR": 4.72,
    "MAY": 3.77,
    "JUN": 3.26,
    "JUL": 3.48,
    "AUG": 4.16,
    "SEP": 4.15,
    "OCT": 4.83,
    "NOV": 5.62,
    "DEC": 5.78
  },
  {
    "LONG": -5.24,
    "LAT": -29.99,
    "CIDADE": "Rio Pardo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.65,
    "FEB": 5.65,
    "MAR": 5.3,
    "APR": 4.68,
    "MAY": 3.79,
    "JUN": 3.29,
    "JUL": 3.47,
    "AUG": 4.12,
    "SEP": 4.07,
    "OCT": 4.79,
    "NOV": 5.61,
    "DEC": 5.74
  },
  {
    "LONG": -51.72,
    "LAT": -29.96,
    "CIDADE": "São Jerônimo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.65,
    "FEB": 5.63,
    "MAR": 5.26,
    "APR": 4.67,
    "MAY": 3.74,
    "JUN": 3.24,
    "JUL": 3.51,
    "AUG": 4.11,
    "SEP": 4.04,
    "OCT": 4.79,
    "NOV": 5.6,
    "DEC": 5.73
  },
  {
    "LONG": -51.63,
    "LAT": -3,
    "CIDADE": "Charqueadas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.67,
    "FEB": 5.63,
    "MAR": 5.25,
    "APR": 4.68,
    "MAY": 3.74,
    "JUN": 3.24,
    "JUL": 3.52,
    "AUG": 4.12,
    "SEP": 4.04,
    "OCT": 4.79,
    "NOV": 5.6,
    "DEC": 5.72
  },
  {
    "LONG": -51.31,
    "LAT": -30,
    "CIDADE": "Eldorado do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.73,
    "FEB": 5.67,
    "MAR": 5.28,
    "APR": 4.71,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.53,
    "AUG": 4.11,
    "SEP": 4.03,
    "OCT": 4.81,
    "NOV": 5.66,
    "DEC": 5.78
  },
  {
    "LONG": -51.08,
    "LAT": -29.99,
    "CIDADE": "Alvorada",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.59,
    "FEB": 5.52,
    "MAR": 5.19,
    "APR": 4.65,
    "MAY": 3.73,
    "JUN": 3.26,
    "JUL": 3.5,
    "AUG": 4.05,
    "SEP": 3.97,
    "OCT": 4.68,
    "NOV": 5.58,
    "DEC": 5.64
  },
  {
    "LONG": -50.13,
    "LAT": -29.98,
    "CIDADE": "Imbé",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.61,
    "FEB": 5.5,
    "MAR": 5.14,
    "APR": 4.74,
    "MAY": 3.88,
    "JUN": 3.37,
    "JUL": 3.54,
    "AUG": 4.09,
    "SEP": 4.01,
    "OCT": 4.65,
    "NOV": 5.54,
    "DEC": 5.65
  },
  {
    "LONG": -50.13,
    "LAT": -29.98,
    "CIDADE": "Tramandaí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.61,
    "FEB": 5.5,
    "MAR": 5.14,
    "APR": 4.74,
    "MAY": 3.88,
    "JUN": 3.37,
    "JUL": 3.54,
    "AUG": 4.09,
    "SEP": 4.01,
    "OCT": 4.65,
    "NOV": 5.54,
    "DEC": 5.65
  },
  {
    "LONG": -54.82,
    "LAT": -29.88,
    "CIDADE": "Cacequi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.83,
    "FEB": 5.83,
    "MAR": 5.58,
    "APR": 4.88,
    "MAY": 3.95,
    "JUN": 3.41,
    "JUL": 3.69,
    "AUG": 4.32,
    "SEP": 4.41,
    "OCT": 5.11,
    "NOV": 5.73,
    "DEC": 5.95
  },
  {
    "LONG": -51.71,
    "LAT": -29.93,
    "CIDADE": "Triunfo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.63,
    "FEB": 5.61,
    "MAR": 5.26,
    "APR": 4.67,
    "MAY": 3.73,
    "JUN": 3.23,
    "JUL": 3.48,
    "AUG": 4.13,
    "SEP": 4,
    "OCT": 4.73,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -51.76,
    "LAT": -29.9,
    "CIDADE": "General Câmara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.63,
    "FEB": 5.61,
    "MAR": 5.26,
    "APR": 4.67,
    "MAY": 3.73,
    "JUN": 3.23,
    "JUL": 3.48,
    "AUG": 4.13,
    "SEP": 4,
    "OCT": 4.73,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -51.18,
    "LAT": -29.85,
    "CIDADE": "Esteio",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.58,
    "FEB": 5.55,
    "MAR": 5.2,
    "APR": 4.66,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.99,
    "OCT": 4.69,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -51.1,
    "LAT": -29.95,
    "CIDADE": "Cachoeirinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.58,
    "FEB": 5.55,
    "MAR": 5.2,
    "APR": 4.66,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.99,
    "OCT": 4.69,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -51.19,
    "LAT": -29.91,
    "CIDADE": "Canoas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.58,
    "FEB": 5.55,
    "MAR": 5.2,
    "APR": 4.66,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.99,
    "OCT": 4.69,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -51.02,
    "LAT": -29.94,
    "CIDADE": "Gravataí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.57,
    "FEB": 5.52,
    "MAR": 5.17,
    "APR": 4.66,
    "MAY": 3.76,
    "JUN": 3.29,
    "JUL": 3.49,
    "AUG": 4.08,
    "SEP": 3.98,
    "OCT": 4.66,
    "NOV": 5.58,
    "DEC": 5.66
  },
  {
    "LONG": -50.77,
    "LAT": -29.88,
    "CIDADE": "Glorinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.62,
    "FEB": 5.59,
    "MAR": 5.21,
    "APR": 4.72,
    "MAY": 3.82,
    "JUN": 3.34,
    "JUL": 3.57,
    "AUG": 4.08,
    "SEP": 4.02,
    "OCT": 4.7,
    "NOV": 5.63,
    "DEC": 5.7
  },
  {
    "LONG": -50.27,
    "LAT": -29.89,
    "CIDADE": "Osório",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.49,
    "FEB": 5.33,
    "MAR": 5.02,
    "APR": 4.68,
    "MAY": 3.81,
    "JUN": 3.38,
    "JUL": 3.59,
    "AUG": 4.13,
    "SEP": 3.9,
    "OCT": 4.49,
    "NOV": 5.39,
    "DEC": 5.48
  },
  {
    "LONG": -57.09,
    "LAT": -29.76,
    "CIDADE": "Uruguaiana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.11,
    "JAN": 6.1,
    "FEB": 5.96,
    "MAR": 5.74,
    "APR": 5.11,
    "MAY": 4.14,
    "JUN": 3.5,
    "JUL": 3.94,
    "AUG": 4.57,
    "SEP": 4.79,
    "OCT": 5.46,
    "NOV": 5.88,
    "DEC": 6.06
  },
  {
    "LONG": -55.8,
    "LAT": -29.79,
    "CIDADE": "Alegrete",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.05,
    "JAN": 6,
    "FEB": 5.89,
    "MAR": 5.74,
    "APR": 5.09,
    "MAY": 4.05,
    "JUN": 3.51,
    "JUL": 3.86,
    "AUG": 4.51,
    "SEP": 4.67,
    "OCT": 5.38,
    "NOV": 5.86,
    "DEC": 6.02
  },
  {
    "LONG": -53.37,
    "LAT": -2.98,
    "CIDADE": "Restinga Sêca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.7,
    "FEB": 5.72,
    "MAR": 5.43,
    "APR": 4.75,
    "MAY": 3.82,
    "JUN": 3.29,
    "JUL": 3.56,
    "AUG": 4.19,
    "SEP": 4.2,
    "OCT": 4.88,
    "NOV": 5.68,
    "DEC": 5.83
  },
  {
    "LONG": -52.18,
    "LAT": -2.98,
    "CIDADE": "Vale Verde",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.5,
    "FEB": 5.6,
    "MAR": 5.25,
    "APR": 4.68,
    "MAY": 3.8,
    "JUN": 3.29,
    "JUL": 3.55,
    "AUG": 4.12,
    "SEP": 4.05,
    "OCT": 4.71,
    "NOV": 5.57,
    "DEC": 5.68
  },
  {
    "LONG": -51.87,
    "LAT": -29.79,
    "CIDADE": "Taquari",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.63,
    "FEB": 5.61,
    "MAR": 5.26,
    "APR": 4.69,
    "MAY": 3.74,
    "JUN": 3.24,
    "JUL": 3.5,
    "AUG": 4.17,
    "SEP": 4.01,
    "OCT": 4.69,
    "NOV": 5.6,
    "DEC": 5.71
  },
  {
    "LONG": "#VALOR!",
    "LAT": -29.85,
    "CIDADE": "Nova Santa Rita",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.56,
    "FEB": 5.6,
    "MAR": 5.27,
    "APR": 4.68,
    "MAY": 3.77,
    "JUN": 3.31,
    "JUL": 3.54,
    "AUG": 4.08,
    "SEP": 3.96,
    "OCT": 4.64,
    "NOV": 5.57,
    "DEC": 5.68
  },
  {
    "LONG": -51.15,
    "LAT": -2.98,
    "CIDADE": "São Leopoldo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.56,
    "FEB": 5.55,
    "MAR": 5.18,
    "APR": 4.67,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.5,
    "AUG": 4.1,
    "SEP": 3.96,
    "OCT": 4.63,
    "NOV": 5.54,
    "DEC": 5.66
  },
  {
    "LONG": -51.15,
    "LAT": -29.83,
    "CIDADE": "Sapucaia do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.56,
    "FEB": 5.55,
    "MAR": 5.18,
    "APR": 4.67,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.5,
    "AUG": 4.1,
    "SEP": 3.96,
    "OCT": 4.63,
    "NOV": 5.54,
    "DEC": 5.66
  },
  {
    "LONG": -50.52,
    "LAT": -29.83,
    "CIDADE": "Santo Antônio da Patrulha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.64,
    "FEB": 5.59,
    "MAR": 5.19,
    "APR": 4.71,
    "MAY": 3.81,
    "JUN": 3.32,
    "JUL": 3.59,
    "AUG": 4.11,
    "SEP": 4.01,
    "OCT": 4.7,
    "NOV": 5.61,
    "DEC": 5.71
  },
  {
    "LONG": -5.04,
    "LAT": -29.79,
    "CIDADE": "Caraá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.61,
    "FEB": 5.55,
    "MAR": 5.16,
    "APR": 4.7,
    "MAY": 3.78,
    "JUN": 3.32,
    "JUL": 3.57,
    "AUG": 4.09,
    "SEP": 3.96,
    "OCT": 4.66,
    "NOV": 5.57,
    "DEC": 5.67
  },
  {
    "LONG": -50.03,
    "LAT": -29.76,
    "CIDADE": "Capão da Canoa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.53,
    "FEB": 5.37,
    "MAR": 5.1,
    "APR": 4.77,
    "MAY": 3.95,
    "JUN": 3.44,
    "JUL": 3.66,
    "AUG": 4.13,
    "SEP": 3.98,
    "OCT": 4.56,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -50.04,
    "LAT": -2.98,
    "CIDADE": "Xangri-lá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.53,
    "FEB": 5.37,
    "MAR": 5.1,
    "APR": 4.77,
    "MAY": 3.95,
    "JUN": 3.44,
    "JUL": 3.66,
    "AUG": 4.13,
    "SEP": 3.98,
    "OCT": 4.56,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -5.47,
    "LAT": -29.69,
    "CIDADE": "São Vicente do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.79,
    "FEB": 5.78,
    "MAR": 5.54,
    "APR": 4.88,
    "MAY": 3.95,
    "JUN": 3.42,
    "JUL": 3.73,
    "AUG": 4.37,
    "SEP": 4.36,
    "OCT": 5.03,
    "NOV": 5.73,
    "DEC": 5.94
  },
  {
    "LONG": -54.21,
    "LAT": -29.71,
    "CIDADE": "Dilermando de Aguiar",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.77,
    "FEB": 5.83,
    "MAR": 5.48,
    "APR": 4.83,
    "MAY": 3.87,
    "JUN": 3.36,
    "JUL": 3.63,
    "AUG": 4.31,
    "SEP": 4.32,
    "OCT": 4.95,
    "NOV": 5.69,
    "DEC": 5.91
  },
  {
    "LONG": -53.82,
    "LAT": -29.69,
    "CIDADE": "Santa Maria",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.67,
    "FEB": 5.73,
    "MAR": 5.36,
    "APR": 4.8,
    "MAY": 3.79,
    "JUN": 3.29,
    "JUL": 3.56,
    "AUG": 4.25,
    "SEP": 4.21,
    "OCT": 4.88,
    "NOV": 5.65,
    "DEC": 5.81
  },
  {
    "LONG": -53.14,
    "LAT": -2.97,
    "CIDADE": "Paraíso do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.58,
    "FEB": 5.63,
    "MAR": 5.36,
    "APR": 4.68,
    "MAY": 3.74,
    "JUN": 3.27,
    "JUL": 3.54,
    "AUG": 4.21,
    "SEP": 4.1,
    "OCT": 4.83,
    "NOV": 5.61,
    "DEC": 5.76
  },
  {
    "LONG": -52.94,
    "LAT": -29.66,
    "CIDADE": "Cerro Branco",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.44,
    "FEB": 5.55,
    "MAR": 5.29,
    "APR": 4.69,
    "MAY": 3.76,
    "JUN": 3.29,
    "JUL": 3.53,
    "AUG": 4.17,
    "SEP": 4.04,
    "OCT": 4.72,
    "NOV": 5.5,
    "DEC": 5.64
  },
  {
    "LONG": -52.95,
    "LAT": -29.73,
    "CIDADE": "Novo Cabrais",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.44,
    "FEB": 5.55,
    "MAR": 5.29,
    "APR": 4.69,
    "MAY": 3.76,
    "JUN": 3.29,
    "JUL": 3.53,
    "AUG": 4.17,
    "SEP": 4.04,
    "OCT": 4.72,
    "NOV": 5.5,
    "DEC": 5.64
  },
  {
    "LONG": "#VALOR!",
    "LAT": -29.67,
    "CIDADE": "Candelária",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.49,
    "FEB": 5.59,
    "MAR": 5.29,
    "APR": 4.65,
    "MAY": 3.78,
    "JUN": 3.28,
    "JUL": 3.54,
    "AUG": 4.14,
    "SEP": 4.02,
    "OCT": 4.69,
    "NOV": 5.49,
    "DEC": 5.63
  },
  {
    "LONG": -52.52,
    "LAT": -29.72,
    "CIDADE": "Vera Cruz",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.42,
    "FEB": 5.53,
    "MAR": 5.25,
    "APR": 4.65,
    "MAY": 3.76,
    "JUN": 3.28,
    "JUL": 3.52,
    "AUG": 4.12,
    "SEP": 4,
    "OCT": 4.66,
    "NOV": 5.5,
    "DEC": 5.61
  },
  {
    "LONG": -52.43,
    "LAT": -29.72,
    "CIDADE": "Santa Cruz do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.4,
    "FEB": 5.49,
    "MAR": 5.21,
    "APR": 4.65,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.53,
    "AUG": 4.13,
    "SEP": 4,
    "OCT": 4.63,
    "NOV": 5.51,
    "DEC": 5.61
  },
  {
    "LONG": -52.28,
    "LAT": -29.75,
    "CIDADE": "Passo do Sobrado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.41,
    "FEB": 5.56,
    "MAR": 5.21,
    "APR": 4.65,
    "MAY": 3.74,
    "JUN": 3.25,
    "JUL": 3.5,
    "AUG": 4.1,
    "SEP": 3.99,
    "OCT": 4.64,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -51.46,
    "LAT": -29.69,
    "CIDADE": "Montenegro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.48,
    "FEB": 5.54,
    "MAR": 5.24,
    "APR": 4.65,
    "MAY": 3.77,
    "JUN": 3.27,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.94,
    "OCT": 4.58,
    "NOV": 5.54,
    "DEC": 5.65
  },
  {
    "LONG": -51.32,
    "LAT": -2.97,
    "CIDADE": "Capela de Santana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.5,
    "FEB": 5.55,
    "MAR": 5.26,
    "APR": 4.65,
    "MAY": 3.79,
    "JUN": 3.29,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.95,
    "OCT": 4.61,
    "NOV": 5.55,
    "DEC": 5.66
  },
  {
    "LONG": -51.24,
    "LAT": -2.97,
    "CIDADE": "Portão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.51,
    "FEB": 5.56,
    "MAR": 5.24,
    "APR": 4.64,
    "MAY": 3.8,
    "JUN": 3.3,
    "JUL": 3.53,
    "AUG": 4.11,
    "SEP": 3.98,
    "OCT": 4.61,
    "NOV": 5.51,
    "DEC": 5.67
  },
  {
    "LONG": -51.13,
    "LAT": -2.97,
    "CIDADE": "Novo Hamburgo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.49,
    "FEB": 5.51,
    "MAR": 5.16,
    "APR": 4.63,
    "MAY": 3.81,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.96,
    "OCT": 4.58,
    "NOV": 5.48,
    "DEC": 5.62
  },
  {
    "LONG": -51.18,
    "LAT": -2.97,
    "CIDADE": "Estância Velha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.49,
    "FEB": 5.51,
    "MAR": 5.16,
    "APR": 4.63,
    "MAY": 3.81,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.08,
    "SEP": 3.96,
    "OCT": 4.58,
    "NOV": 5.48,
    "DEC": 5.62
  },
  {
    "LONG": -51.06,
    "LAT": -29.68,
    "CIDADE": "Campo Bom",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.49,
    "FEB": 5.47,
    "MAR": 5.12,
    "APR": 4.63,
    "MAY": 3.8,
    "JUN": 3.29,
    "JUL": 3.5,
    "AUG": 4.06,
    "SEP": 3.96,
    "OCT": 4.57,
    "NOV": 5.48,
    "DEC": 5.59
  },
  {
    "LONG": -50.78,
    "LAT": -2.97,
    "CIDADE": "Taquara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.51,
    "FEB": 5.49,
    "MAR": 5.16,
    "APR": 4.68,
    "MAY": 3.82,
    "JUN": 3.31,
    "JUL": 3.54,
    "AUG": 4.12,
    "SEP": 4.01,
    "OCT": 4.64,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -50.58,
    "LAT": -2.97,
    "CIDADE": "Rolante",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.56,
    "FEB": 5.49,
    "MAR": 5.12,
    "APR": 4.67,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.59,
    "AUG": 4.12,
    "SEP": 3.97,
    "OCT": 4.64,
    "NOV": 5.57,
    "DEC": 5.6
  },
  {
    "LONG": -50.21,
    "LAT": -2.97,
    "CIDADE": "Maquiné",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.35,
    "JAN": 5.08,
    "FEB": 4.96,
    "MAR": 4.7,
    "APR": 4.51,
    "MAY": 3.66,
    "JUN": 3.34,
    "JUL": 3.6,
    "AUG": 4.13,
    "SEP": 3.79,
    "OCT": 4.23,
    "NOV": 5.08,
    "DEC": 5.16
  },
  {
    "LONG": -55.48,
    "LAT": -29.59,
    "CIDADE": "Manoel Viana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.99,
    "JAN": 5.96,
    "FEB": 5.87,
    "MAR": 5.67,
    "APR": 4.98,
    "MAY": 4.04,
    "JUN": 3.52,
    "JUL": 3.78,
    "AUG": 4.49,
    "SEP": 4.56,
    "OCT": 5.22,
    "NOV": 5.77,
    "DEC": 5.98
  },
  {
    "LONG": -55.13,
    "LAT": -29.56,
    "CIDADE": "São Francisco de Assis",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.85,
    "FEB": 5.83,
    "MAR": 5.6,
    "APR": 4.93,
    "MAY": 4,
    "JUN": 3.5,
    "JUL": 3.78,
    "AUG": 4.45,
    "SEP": 4.48,
    "OCT": 5.15,
    "NOV": 5.72,
    "DEC": 5.94
  },
  {
    "LONG": -54.46,
    "LAT": -29.57,
    "CIDADE": "Mata",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.72,
    "FEB": 5.78,
    "MAR": 5.48,
    "APR": 4.85,
    "MAY": 3.89,
    "JUN": 3.4,
    "JUL": 3.7,
    "AUG": 4.34,
    "SEP": 4.33,
    "OCT": 4.97,
    "NOV": 5.67,
    "DEC": 5.88
  },
  {
    "LONG": -5.42,
    "LAT": -29.62,
    "CIDADE": "São Pedro do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.7,
    "FEB": 5.74,
    "MAR": 5.45,
    "APR": 4.83,
    "MAY": 3.87,
    "JUN": 3.36,
    "JUL": 3.67,
    "AUG": 4.32,
    "SEP": 4.29,
    "OCT": 4.94,
    "NOV": 5.68,
    "DEC": 5.85
  },
  {
    "LONG": -53.76,
    "LAT": "#VALOR!",
    "CIDADE": "Itaara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.6,
    "FEB": 5.68,
    "MAR": 5.35,
    "APR": 4.79,
    "MAY": 3.86,
    "JUN": 3.36,
    "JUL": 3.65,
    "AUG": 4.3,
    "SEP": 4.23,
    "OCT": 4.87,
    "NOV": 5.6,
    "DEC": 5.75
  },
  {
    "LONG": -53.59,
    "LAT": -2.96,
    "CIDADE": "Silveira Martins",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.51,
    "FEB": 5.67,
    "MAR": 5.35,
    "APR": 4.75,
    "MAY": 3.84,
    "JUN": 3.33,
    "JUL": 3.61,
    "AUG": 4.29,
    "SEP": 4.19,
    "OCT": 4.83,
    "NOV": 5.63,
    "DEC": 5.72
  },
  {
    "LONG": -53.45,
    "LAT": -2.96,
    "CIDADE": "São João do Polêsine",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.56,
    "FEB": 5.68,
    "MAR": 5.38,
    "APR": 4.75,
    "MAY": 3.82,
    "JUN": 3.32,
    "JUL": 3.6,
    "AUG": 4.27,
    "SEP": 4.15,
    "OCT": 4.85,
    "NOV": 5.63,
    "DEC": 5.75
  },
  {
    "LONG": -53.44,
    "LAT": -2.96,
    "CIDADE": "Faxinal do Soturno",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.56,
    "FEB": 5.68,
    "MAR": 5.38,
    "APR": 4.75,
    "MAY": 3.82,
    "JUN": 3.32,
    "JUL": 3.6,
    "AUG": 4.27,
    "SEP": 4.15,
    "OCT": 4.85,
    "NOV": 5.63,
    "DEC": 5.75
  },
  {
    "LONG": -53.36,
    "LAT": -2.96,
    "CIDADE": "Dona Francisca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.58,
    "FEB": 5.66,
    "MAR": 5.39,
    "APR": 4.73,
    "MAY": 3.81,
    "JUN": 3.31,
    "JUL": 3.58,
    "AUG": 4.26,
    "SEP": 4.12,
    "OCT": 4.84,
    "NOV": 5.61,
    "DEC": 5.75
  },
  {
    "LONG": -53.25,
    "LAT": -29.65,
    "CIDADE": "Agudo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.56,
    "FEB": 5.64,
    "MAR": 5.38,
    "APR": 4.72,
    "MAY": 3.78,
    "JUN": 3.32,
    "JUL": 3.58,
    "AUG": 4.28,
    "SEP": 4.11,
    "OCT": 4.83,
    "NOV": 5.61,
    "DEC": 5.75
  },
  {
    "LONG": -52.69,
    "LAT": -29.6,
    "CIDADE": "Vale do Sol",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.36,
    "FEB": 5.46,
    "MAR": 5.22,
    "APR": 4.63,
    "MAY": 3.75,
    "JUN": 3.29,
    "JUL": 3.56,
    "AUG": 4.17,
    "SEP": 3.99,
    "OCT": 4.63,
    "NOV": 5.45,
    "DEC": 5.55
  },
  {
    "LONG": -52.19,
    "LAT": -29.61,
    "CIDADE": "Venâncio Aires",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.32,
    "FEB": 5.48,
    "MAR": 5.15,
    "APR": 4.66,
    "MAY": 3.75,
    "JUN": 3.26,
    "JUL": 3.52,
    "AUG": 4.15,
    "SEP": 3.99,
    "OCT": 4.57,
    "NOV": 5.49,
    "DEC": 5.54
  },
  {
    "LONG": -51.94,
    "LAT": -29.6,
    "CIDADE": "Bom Retiro do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.5,
    "FEB": 5.58,
    "MAR": 5.22,
    "APR": 4.65,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.54,
    "AUG": 4.17,
    "SEP": 4.03,
    "OCT": 4.62,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -51.82,
    "LAT": -2.96,
    "CIDADE": "Fazenda Vilanova",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.56,
    "FEB": 5.56,
    "MAR": 5.27,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.52,
    "AUG": 4.17,
    "SEP": 4.02,
    "OCT": 4.62,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -51.68,
    "LAT": -29.64,
    "CIDADE": "Tabaí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.44,
    "FEB": 5.51,
    "MAR": 5.2,
    "APR": 4.63,
    "MAY": 3.74,
    "JUN": 3.26,
    "JUL": 3.52,
    "AUG": 4.13,
    "SEP": 3.95,
    "OCT": 4.56,
    "NOV": 5.52,
    "DEC": 5.59
  },
  {
    "LONG": -5.16,
    "LAT": -29.55,
    "CIDADE": "Brochier",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.44,
    "FEB": 5.51,
    "MAR": 5.19,
    "APR": 4.64,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.51,
    "AUG": 4.09,
    "SEP": 3.93,
    "OCT": 4.51,
    "NOV": 5.5,
    "DEC": 5.56
  },
  {
    "LONG": -51.42,
    "LAT": -29.55,
    "CIDADE": "Harmonia",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.41,
    "FEB": 5.49,
    "MAR": 5.18,
    "APR": 4.62,
    "MAY": 3.76,
    "JUN": 3.29,
    "JUL": 3.51,
    "AUG": 4.09,
    "SEP": 3.94,
    "OCT": 4.5,
    "NOV": 5.5,
    "DEC": 5.55
  },
  {
    "LONG": -51.38,
    "LAT": -2.96,
    "CIDADE": "São Sebastião do Caí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.43,
    "FEB": 5.53,
    "MAR": 5.2,
    "APR": 4.63,
    "MAY": 3.79,
    "JUN": 3.31,
    "JUL": 3.52,
    "AUG": 4.11,
    "SEP": 3.94,
    "OCT": 4.55,
    "NOV": 5.51,
    "DEC": 5.6
  },
  {
    "LONG": -51.4,
    "LAT": -2.96,
    "CIDADE": "Pareci Novo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.43,
    "FEB": 5.53,
    "MAR": 5.2,
    "APR": 4.63,
    "MAY": 3.79,
    "JUN": 3.31,
    "JUL": 3.52,
    "AUG": 4.11,
    "SEP": 3.94,
    "OCT": 4.55,
    "NOV": 5.51,
    "DEC": 5.6
  },
  {
    "LONG": -51.21,
    "LAT": -2.96,
    "CIDADE": "Lindolfo Collor",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.46,
    "FEB": 5.53,
    "MAR": 5.22,
    "APR": 4.64,
    "MAY": 3.8,
    "JUN": 3.32,
    "JUL": 3.52,
    "AUG": 4.11,
    "SEP": 3.95,
    "OCT": 4.57,
    "NOV": 5.51,
    "DEC": 5.63
  },
  {
    "LONG": -51.15,
    "LAT": -29.6,
    "CIDADE": "Ivoti",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.47,
    "FEB": 5.52,
    "MAR": 5.16,
    "APR": 4.63,
    "MAY": 3.79,
    "JUN": 3.31,
    "JUL": 3.52,
    "AUG": 4.11,
    "SEP": 3.93,
    "OCT": 4.58,
    "NOV": 5.49,
    "DEC": 5.61
  },
  {
    "LONG": -51.09,
    "LAT": -2.96,
    "CIDADE": "Dois Irmãos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.45,
    "FEB": 5.45,
    "MAR": 5.08,
    "APR": 4.59,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.52,
    "AUG": 4.1,
    "SEP": 3.9,
    "OCT": 4.55,
    "NOV": 5.44,
    "DEC": 5.56
  },
  {
    "LONG": -51.01,
    "LAT": -29.64,
    "CIDADE": "Sapiranga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.56,
    "JAN": 5.45,
    "FEB": 5.45,
    "MAR": 5.08,
    "APR": 4.59,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.52,
    "AUG": 4.1,
    "SEP": 3.9,
    "OCT": 4.55,
    "NOV": 5.44,
    "DEC": 5.56
  },
  {
    "LONG": -50.91,
    "LAT": -29.58,
    "CIDADE": "Nova Hartz",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.52,
    "JAN": 5.36,
    "FEB": 5.36,
    "MAR": 5,
    "APR": 4.56,
    "MAY": 3.74,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.11,
    "SEP": 3.88,
    "OCT": 4.5,
    "NOV": 5.4,
    "DEC": 5.47
  },
  {
    "LONG": -50.93,
    "LAT": -29.62,
    "CIDADE": "Araricá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.52,
    "JAN": 5.36,
    "FEB": 5.36,
    "MAR": 5,
    "APR": 4.56,
    "MAY": 3.74,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.11,
    "SEP": 3.88,
    "OCT": 4.5,
    "NOV": 5.4,
    "DEC": 5.47
  },
  {
    "LONG": -50.83,
    "LAT": -2.96,
    "CIDADE": "Parobé",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.34,
    "FEB": 5.33,
    "MAR": 5,
    "APR": 4.59,
    "MAY": 3.76,
    "JUN": 3.32,
    "JUL": 3.55,
    "AUG": 4.14,
    "SEP": 3.92,
    "OCT": 4.49,
    "NOV": 5.41,
    "DEC": 5.46
  },
  {
    "LONG": -50.79,
    "LAT": -29.57,
    "CIDADE": "Igrejinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.41,
    "FEB": 5.39,
    "MAR": 5.06,
    "APR": 4.63,
    "MAY": 3.78,
    "JUN": 3.34,
    "JUL": 3.56,
    "AUG": 4.15,
    "SEP": 3.95,
    "OCT": 4.55,
    "NOV": 5.48,
    "DEC": 5.51
  },
  {
    "LONG": -50.45,
    "LAT": -29.64,
    "CIDADE": "Riozinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.51,
    "JAN": 5.32,
    "FEB": 5.24,
    "MAR": 4.93,
    "APR": 4.59,
    "MAY": 3.69,
    "JUN": 3.3,
    "JUL": 3.6,
    "AUG": 4.16,
    "SEP": 3.9,
    "OCT": 4.52,
    "NOV": 5.41,
    "DEC": 5.43
  },
  {
    "LONG": -50.06,
    "LAT": -29.58,
    "CIDADE": "Terra de Areia",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.44,
    "JAN": 5.11,
    "FEB": 5.03,
    "MAR": 4.81,
    "APR": 4.63,
    "MAY": 3.86,
    "JUN": 3.45,
    "JUL": 3.68,
    "AUG": 4.2,
    "SEP": 3.86,
    "OCT": 4.26,
    "NOV": 5.15,
    "DEC": 5.2
  },
  {
    "LONG": -54.69,
    "LAT": -2.95,
    "CIDADE": "Jaguari",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.75,
    "FEB": 5.73,
    "MAR": 5.49,
    "APR": 4.85,
    "MAY": 3.96,
    "JUN": 3.45,
    "JUL": 3.73,
    "AUG": 4.38,
    "SEP": 4.37,
    "OCT": 4.99,
    "NOV": 5.71,
    "DEC": 5.87
  },
  {
    "LONG": -54.22,
    "LAT": -29.48,
    "CIDADE": "Toropi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.68,
    "FEB": 5.7,
    "MAR": 5.45,
    "APR": 4.84,
    "MAY": 3.92,
    "JUN": 3.41,
    "JUL": 3.72,
    "AUG": 4.36,
    "SEP": 4.31,
    "OCT": 4.95,
    "NOV": 5.67,
    "DEC": 5.81
  },
  {
    "LONG": -53.85,
    "LAT": -2.95,
    "CIDADE": "São Martinho da Serra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.65,
    "FEB": 5.71,
    "MAR": 5.41,
    "APR": 4.83,
    "MAY": 3.95,
    "JUN": 3.43,
    "JUL": 3.74,
    "AUG": 4.36,
    "SEP": 4.32,
    "OCT": 4.95,
    "NOV": 5.65,
    "DEC": 5.76
  },
  {
    "LONG": -53.58,
    "LAT": "#VALOR!",
    "CIDADE": "Ivorá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.51,
    "FEB": 5.68,
    "MAR": 5.4,
    "APR": 4.76,
    "MAY": 3.88,
    "JUN": 3.34,
    "JUL": 3.64,
    "AUG": 4.31,
    "SEP": 4.22,
    "OCT": 4.86,
    "NOV": 5.63,
    "DEC": 5.74
  },
  {
    "LONG": -53.47,
    "LAT": -29.47,
    "CIDADE": "Nova Palma",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.74,
    "JAN": 5.51,
    "FEB": 5.67,
    "MAR": 5.39,
    "APR": 4.76,
    "MAY": 3.87,
    "JUN": 3.34,
    "JUL": 3.64,
    "AUG": 4.31,
    "SEP": 4.19,
    "OCT": 4.87,
    "NOV": 5.62,
    "DEC": 5.74
  },
  {
    "LONG": -53.01,
    "LAT": -29.49,
    "CIDADE": "Lagoa Bonita do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.46,
    "FEB": 5.63,
    "MAR": 5.34,
    "APR": 4.76,
    "MAY": 3.86,
    "JUN": 3.39,
    "JUL": 3.67,
    "AUG": 4.32,
    "SEP": 4.13,
    "OCT": 4.89,
    "NOV": 5.6,
    "DEC": 5.71
  },
  {
    "LONG": -52.65,
    "LAT": -29.45,
    "CIDADE": "Herveiras",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.37,
    "FEB": 5.45,
    "MAR": 5.16,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.33,
    "JUL": 3.62,
    "AUG": 4.25,
    "SEP": 4.01,
    "OCT": 4.68,
    "NOV": 5.47,
    "DEC": 5.55
  },
  {
    "LONG": -52.52,
    "LAT": -2.95,
    "CIDADE": "Sinimbu",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.36,
    "FEB": 5.44,
    "MAR": 5.14,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.34,
    "JUL": 3.61,
    "AUG": 4.24,
    "SEP": 3.99,
    "OCT": 4.64,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -52.13,
    "LAT": -2.95,
    "CIDADE": "Mato Leitão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.26,
    "FEB": 5.4,
    "MAR": 5.1,
    "APR": 4.63,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.19,
    "SEP": 3.96,
    "OCT": 4.53,
    "NOV": 5.42,
    "DEC": 5.47
  },
  {
    "LONG": -52.09,
    "LAT": -2.95,
    "CIDADE": "Santa Clara do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.35,
    "FEB": 5.48,
    "MAR": 5.15,
    "APR": 4.64,
    "MAY": 3.78,
    "JUN": 3.29,
    "JUL": 3.54,
    "AUG": 4.18,
    "SEP": 3.99,
    "OCT": 4.56,
    "NOV": 5.47,
    "DEC": 5.53
  },
  {
    "LONG": -51.96,
    "LAT": -29.46,
    "CIDADE": "Lajeado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.41,
    "FEB": 5.53,
    "MAR": 5.19,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.56,
    "AUG": 4.17,
    "SEP": 3.99,
    "OCT": 4.59,
    "NOV": 5.53,
    "DEC": 5.57
  },
  {
    "LONG": -51.95,
    "LAT": -29.49,
    "CIDADE": "Estrela",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.41,
    "FEB": 5.53,
    "MAR": 5.19,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.56,
    "AUG": 4.17,
    "SEP": 3.99,
    "OCT": 4.59,
    "NOV": 5.53,
    "DEC": 5.57
  },
  {
    "LONG": -51.98,
    "LAT": -2.95,
    "CIDADE": "Cruzeiro do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.41,
    "FEB": 5.53,
    "MAR": 5.19,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.56,
    "AUG": 4.17,
    "SEP": 3.99,
    "OCT": 4.59,
    "NOV": 5.53,
    "DEC": 5.57
  },
  {
    "LONG": -51.73,
    "LAT": -2.95,
    "CIDADE": "Paverama",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.49,
    "FEB": 5.49,
    "MAR": 5.21,
    "APR": 4.63,
    "MAY": 3.75,
    "JUN": 3.27,
    "JUL": 3.56,
    "AUG": 4.16,
    "SEP": 3.97,
    "OCT": 4.57,
    "NOV": 5.51,
    "DEC": 5.57
  },
  {
    "LONG": -51.56,
    "LAT": -29.55,
    "CIDADE": "Maratá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.42,
    "FEB": 5.43,
    "MAR": 5.11,
    "APR": 4.62,
    "MAY": 3.73,
    "JUN": 3.3,
    "JUL": 3.56,
    "AUG": 4.11,
    "SEP": 3.92,
    "OCT": 4.5,
    "NOV": 5.45,
    "DEC": 5.49
  },
  {
    "LONG": -51.49,
    "LAT": -29.54,
    "CIDADE": "São José do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.33,
    "FEB": 5.39,
    "MAR": 5.09,
    "APR": 4.59,
    "MAY": 3.75,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.12,
    "SEP": 3.91,
    "OCT": 4.46,
    "NOV": 5.42,
    "DEC": 5.46
  },
  {
    "LONG": -51.42,
    "LAT": -29.48,
    "CIDADE": "Tupandi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.33,
    "FEB": 5.39,
    "MAR": 5.09,
    "APR": 4.59,
    "MAY": 3.75,
    "JUN": 3.3,
    "JUL": 3.54,
    "AUG": 4.12,
    "SEP": 3.91,
    "OCT": 4.46,
    "NOV": 5.42,
    "DEC": 5.46
  },
  {
    "LONG": -51.3,
    "LAT": -29.45,
    "CIDADE": "Feliz",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.36,
    "FEB": 5.41,
    "MAR": 5.11,
    "APR": 4.62,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.55,
    "AUG": 4.12,
    "SEP": 3.92,
    "OCT": 4.51,
    "NOV": 5.46,
    "DEC": 5.5
  },
  {
    "LONG": -51.36,
    "LAT": -29.49,
    "CIDADE": "Bom Princípio",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.36,
    "FEB": 5.41,
    "MAR": 5.11,
    "APR": 4.62,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.55,
    "AUG": 4.12,
    "SEP": 3.92,
    "OCT": 4.51,
    "NOV": 5.46,
    "DEC": 5.5
  },
  {
    "LONG": -51.2,
    "LAT": -29.47,
    "CIDADE": "Linha Nova",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.15,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.31,
    "JUL": 3.56,
    "AUG": 4.13,
    "SEP": 3.94,
    "OCT": 4.54,
    "NOV": 5.5,
    "DEC": 5.57
  },
  {
    "LONG": -51.25,
    "LAT": -29.52,
    "CIDADE": "São José do Hortêncio",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.15,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.31,
    "JUL": 3.56,
    "AUG": 4.13,
    "SEP": 3.94,
    "OCT": 4.54,
    "NOV": 5.5,
    "DEC": 5.57
  },
  {
    "LONG": -51.13,
    "LAT": -29.45,
    "CIDADE": "Picada Café",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.47,
    "FEB": 5.5,
    "MAR": 5.14,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.33,
    "JUL": 3.58,
    "AUG": 4.16,
    "SEP": 3.96,
    "OCT": 4.6,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -51.18,
    "LAT": -29.52,
    "CIDADE": "Presidente Lucena",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.47,
    "FEB": 5.5,
    "MAR": 5.14,
    "APR": 4.64,
    "MAY": 3.77,
    "JUN": 3.33,
    "JUL": 3.58,
    "AUG": 4.16,
    "SEP": 3.96,
    "OCT": 4.6,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -51.08,
    "LAT": -29.54,
    "CIDADE": "Morro Reuter",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.46,
    "FEB": 5.45,
    "MAR": 5.07,
    "APR": 4.6,
    "MAY": 3.76,
    "JUN": 3.33,
    "JUL": 3.59,
    "AUG": 4.17,
    "SEP": 3.95,
    "OCT": 4.58,
    "NOV": 5.47,
    "DEC": 5.56
  },
  {
    "LONG": -50.99,
    "LAT": -29.49,
    "CIDADE": "Santa Maria do Herval",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.3,
    "FEB": 5.32,
    "MAR": 4.93,
    "APR": 4.56,
    "MAY": 3.73,
    "JUN": 3.34,
    "JUL": 3.6,
    "AUG": 4.18,
    "SEP": 3.91,
    "OCT": 4.55,
    "NOV": 5.42,
    "DEC": 5.48
  },
  {
    "LONG": -50.77,
    "LAT": -29.51,
    "CIDADE": "Três Coroas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.54,
    "JAN": 5.32,
    "FEB": 5.35,
    "MAR": 4.99,
    "APR": 4.61,
    "MAY": 3.76,
    "JUN": 3.36,
    "JUL": 3.6,
    "AUG": 4.2,
    "SEP": 3.92,
    "OCT": 4.52,
    "NOV": 5.42,
    "DEC": 5.45
  },
  {
    "LONG": -5.01,
    "LAT": -29.49,
    "CIDADE": "Itati",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.32,
    "JAN": 4.85,
    "FEB": 4.79,
    "MAR": 4.58,
    "APR": 4.51,
    "MAY": 3.74,
    "JUN": 3.4,
    "JUL": 3.69,
    "AUG": 4.24,
    "SEP": 3.8,
    "OCT": 4.2,
    "NOV": 5,
    "DEC": 5.05
  },
  {
    "LONG": -50.06,
    "LAT": -2.95,
    "CIDADE": "Três Forquilhas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.33,
    "JAN": 4.9,
    "FEB": 4.85,
    "MAR": 4.61,
    "APR": 4.54,
    "MAY": 3.79,
    "JUN": 3.4,
    "JUL": 3.66,
    "AUG": 4.22,
    "SEP": 3.77,
    "OCT": 4.14,
    "NOV": 4.99,
    "DEC": 5.04
  },
  {
    "LONG": -49.92,
    "LAT": -2.95,
    "CIDADE": "Três Cachoeiras",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.33,
    "JAN": 4.91,
    "FEB": 4.86,
    "MAR": 4.63,
    "APR": 4.57,
    "MAY": 3.89,
    "JUN": 3.44,
    "JUL": 3.65,
    "AUG": 4.23,
    "SEP": 3.78,
    "OCT": 4.07,
    "NOV": 4.97,
    "DEC": 4.94
  },
  {
    "LONG": "#VALOR!",
    "LAT": -29.54,
    "CIDADE": "Arroio do Sal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.49,
    "JAN": 5.17,
    "FEB": 5.16,
    "MAR": 4.88,
    "APR": 4.7,
    "MAY": 3.99,
    "JUN": 3.43,
    "JUL": 3.66,
    "AUG": 4.21,
    "SEP": 3.9,
    "OCT": 4.31,
    "NOV": 5.21,
    "DEC": 5.26
  },
  {
    "LONG": -54.83,
    "LAT": -29.41,
    "CIDADE": "Nova Esperança do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.73,
    "FEB": 5.76,
    "MAR": 5.52,
    "APR": 4.9,
    "MAY": 3.96,
    "JUN": 3.49,
    "JUL": 3.8,
    "AUG": 4.42,
    "SEP": 4.45,
    "OCT": 5.03,
    "NOV": 5.73,
    "DEC": 5.84
  },
  {
    "LONG": -54.07,
    "LAT": -2.94,
    "CIDADE": "Quevedos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.63,
    "FEB": 5.69,
    "MAR": 5.44,
    "APR": 4.84,
    "MAY": 3.94,
    "JUN": 3.44,
    "JUL": 3.77,
    "AUG": 4.41,
    "SEP": 4.35,
    "OCT": 4.93,
    "NOV": 5.64,
    "DEC": 5.76
  },
  {
    "LONG": "#VALOR!",
    "LAT": -29.42,
    "CIDADE": "Ibarama",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.76,
    "JAN": 5.49,
    "FEB": 5.67,
    "MAR": 5.44,
    "APR": 4.79,
    "MAY": 3.87,
    "JUN": 3.36,
    "JUL": 3.68,
    "AUG": 4.34,
    "SEP": 4.17,
    "OCT": 4.9,
    "NOV": 5.64,
    "DEC": 5.75
  },
  {
    "LONG": -53.03,
    "LAT": -29.42,
    "CIDADE": "Sobradinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.77,
    "JAN": 5.52,
    "FEB": 5.68,
    "MAR": 5.41,
    "APR": 4.8,
    "MAY": 3.89,
    "JUN": 3.4,
    "JUL": 3.69,
    "AUG": 4.36,
    "SEP": 4.17,
    "OCT": 4.92,
    "NOV": 5.66,
    "DEC": 5.73
  },
  {
    "LONG": -52.98,
    "LAT": -29.35,
    "CIDADE": "Segredo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.45,
    "FEB": 5.66,
    "MAR": 5.41,
    "APR": 4.77,
    "MAY": 3.87,
    "JUN": 3.41,
    "JUL": 3.7,
    "AUG": 4.36,
    "SEP": 4.15,
    "OCT": 4.9,
    "NOV": 5.64,
    "DEC": 5.72
  },
  {
    "LONG": -52.97,
    "LAT": -29.45,
    "CIDADE": "Passa Sete",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.45,
    "FEB": 5.66,
    "MAR": 5.41,
    "APR": 4.77,
    "MAY": 3.87,
    "JUN": 3.41,
    "JUL": 3.7,
    "AUG": 4.36,
    "SEP": 4.15,
    "OCT": 4.9,
    "NOV": 5.64,
    "DEC": 5.72
  },
  {
    "LONG": -52.27,
    "LAT": -2.94,
    "CIDADE": "Sério",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.25,
    "FEB": 5.36,
    "MAR": 5.07,
    "APR": 4.57,
    "MAY": 3.75,
    "JUN": 3.32,
    "JUL": 3.59,
    "AUG": 4.22,
    "SEP": 3.98,
    "OCT": 4.54,
    "NOV": 5.43,
    "DEC": 5.45
  },
  {
    "LONG": -52.1,
    "LAT": -29.38,
    "CIDADE": "Forquetinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.34,
    "FEB": 5.47,
    "MAR": 5.16,
    "APR": 4.62,
    "MAY": 3.78,
    "JUN": 3.3,
    "JUL": 3.57,
    "AUG": 4.2,
    "SEP": 3.98,
    "OCT": 4.6,
    "NOV": 5.46,
    "DEC": 5.53
  },
  {
    "LONG": -51.94,
    "LAT": -2.94,
    "CIDADE": "Arroio do Meio",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.36,
    "FEB": 5.49,
    "MAR": 5.16,
    "APR": 4.61,
    "MAY": 3.76,
    "JUN": 3.27,
    "JUL": 3.58,
    "AUG": 4.19,
    "SEP": 3.96,
    "OCT": 4.58,
    "NOV": 5.47,
    "DEC": 5.52
  },
  {
    "LONG": -51.8,
    "LAT": -29.45,
    "CIDADE": "Teutônia",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.47,
    "FEB": 5.53,
    "MAR": 5.23,
    "APR": 4.6,
    "MAY": 3.78,
    "JUN": 3.27,
    "JUL": 3.55,
    "AUG": 4.19,
    "SEP": 3.97,
    "OCT": 4.61,
    "NOV": 5.51,
    "DEC": 5.54
  },
  {
    "LONG": -51.85,
    "LAT": -29.4,
    "CIDADE": "Colinas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.47,
    "FEB": 5.53,
    "MAR": 5.23,
    "APR": 4.6,
    "MAY": 3.78,
    "JUN": 3.27,
    "JUL": 3.55,
    "AUG": 4.19,
    "SEP": 3.97,
    "OCT": 4.61,
    "NOV": 5.51,
    "DEC": 5.54
  },
  {
    "LONG": -51.76,
    "LAT": -29.43,
    "CIDADE": "Westfália",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.47,
    "FEB": 5.47,
    "MAR": 5.17,
    "APR": 4.61,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.59,
    "AUG": 4.2,
    "SEP": 3.96,
    "OCT": 4.59,
    "NOV": 5.5,
    "DEC": 5.53
  },
  {
    "LONG": -51.78,
    "LAT": -29.35,
    "CIDADE": "Imigrante",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.47,
    "FEB": 5.47,
    "MAR": 5.17,
    "APR": 4.61,
    "MAY": 3.75,
    "JUN": 3.28,
    "JUL": 3.59,
    "AUG": 4.2,
    "SEP": 3.96,
    "OCT": 4.59,
    "NOV": 5.5,
    "DEC": 5.53
  },
  {
    "LONG": -51.67,
    "LAT": -29.36,
    "CIDADE": "Boa Vista do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.46,
    "FEB": 5.42,
    "MAR": 5.12,
    "APR": 4.62,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.61,
    "AUG": 4.21,
    "SEP": 3.95,
    "OCT": 4.59,
    "NOV": 5.48,
    "DEC": 5.5
  },
  {
    "LONG": -51.67,
    "LAT": -29.45,
    "CIDADE": "Poço das Antas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.46,
    "FEB": 5.42,
    "MAR": 5.12,
    "APR": 4.62,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.61,
    "AUG": 4.21,
    "SEP": 3.95,
    "OCT": 4.59,
    "NOV": 5.48,
    "DEC": 5.5
  },
  {
    "LONG": -51.5,
    "LAT": -29.38,
    "CIDADE": "Barão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.41,
    "FEB": 5.4,
    "MAR": 5.07,
    "APR": 4.62,
    "MAY": 3.74,
    "JUN": 3.32,
    "JUL": 3.63,
    "AUG": 4.19,
    "SEP": 3.95,
    "OCT": 4.58,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -51.51,
    "LAT": -29.44,
    "CIDADE": "Salvador do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.41,
    "FEB": 5.4,
    "MAR": 5.07,
    "APR": 4.62,
    "MAY": 3.74,
    "JUN": 3.32,
    "JUL": 3.63,
    "AUG": 4.19,
    "SEP": 3.95,
    "OCT": 4.58,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -51.51,
    "LAT": -29.42,
    "CIDADE": "São Pedro da Serra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.41,
    "FEB": 5.4,
    "MAR": 5.07,
    "APR": 4.62,
    "MAY": 3.74,
    "JUN": 3.32,
    "JUL": 3.63,
    "AUG": 4.19,
    "SEP": 3.95,
    "OCT": 4.58,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -51.31,
    "LAT": -29.38,
    "CIDADE": "Alto Feliz",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.35,
    "FEB": 5.35,
    "MAR": 5.07,
    "APR": 4.62,
    "MAY": 3.76,
    "JUN": 3.32,
    "JUL": 3.6,
    "AUG": 4.18,
    "SEP": 3.94,
    "OCT": 4.52,
    "NOV": 5.41,
    "DEC": 5.46
  },
  {
    "LONG": -51.37,
    "LAT": -29.37,
    "CIDADE": "São Vendelino",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.55,
    "JAN": 5.35,
    "FEB": 5.35,
    "MAR": 5.07,
    "APR": 4.62,
    "MAY": 3.76,
    "JUN": 3.32,
    "JUL": 3.6,
    "AUG": 4.18,
    "SEP": 3.94,
    "OCT": 4.52,
    "NOV": 5.41,
    "DEC": 5.46
  },
  {
    "LONG": -51.26,
    "LAT": -29.39,
    "CIDADE": "Vale Real",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.4,
    "FEB": 5.43,
    "MAR": 5.1,
    "APR": 4.63,
    "MAY": 3.75,
    "JUN": 3.32,
    "JUL": 3.61,
    "AUG": 4.17,
    "SEP": 3.94,
    "OCT": 4.54,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -5.11,
    "LAT": -29.37,
    "CIDADE": "Nova Petrópolis",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.44,
    "FEB": 5.44,
    "MAR": 5.08,
    "APR": 4.61,
    "MAY": 3.75,
    "JUN": 3.31,
    "JUL": 3.61,
    "AUG": 4.17,
    "SEP": 3.97,
    "OCT": 4.57,
    "NOV": 5.45,
    "DEC": 5.51
  },
  {
    "LONG": -50.81,
    "LAT": -29.36,
    "CIDADE": "Canela",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.26,
    "FEB": 5.31,
    "MAR": 4.94,
    "APR": 4.58,
    "MAY": 3.74,
    "JUN": 3.35,
    "JUL": 3.62,
    "AUG": 4.23,
    "SEP": 3.93,
    "OCT": 4.55,
    "NOV": 5.39,
    "DEC": 5.46
  },
  {
    "LONG": -50.88,
    "LAT": -29.37,
    "CIDADE": "Gramado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.53,
    "JAN": 5.26,
    "FEB": 5.31,
    "MAR": 4.94,
    "APR": 4.58,
    "MAY": 3.74,
    "JUN": 3.35,
    "JUL": 3.62,
    "AUG": 4.23,
    "SEP": 3.93,
    "OCT": 4.55,
    "NOV": 5.39,
    "DEC": 5.46
  },
  {
    "LONG": -50.58,
    "LAT": -29.45,
    "CIDADE": "São Francisco de Paula",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.57,
    "JAN": 5.33,
    "FEB": 5.3,
    "MAR": 4.92,
    "APR": 4.66,
    "MAY": 3.75,
    "JUN": 3.41,
    "JUL": 3.71,
    "AUG": 4.28,
    "SEP": 3.99,
    "OCT": 4.64,
    "NOV": 5.43,
    "DEC": 5.45
  },
  {
    "LONG": -49.93,
    "LAT": -2.94,
    "CIDADE": "Morrinhos do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.22,
    "JAN": 4.71,
    "FEB": 4.75,
    "MAR": 4.49,
    "APR": 4.45,
    "MAY": 3.82,
    "JUN": 3.37,
    "JUL": 3.63,
    "AUG": 4.22,
    "SEP": 3.72,
    "OCT": 3.89,
    "NOV": 4.75,
    "DEC": 4.79
  },
  {
    "LONG": -49.85,
    "LAT": -29.37,
    "CIDADE": "Dom Pedro de Alcântara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.42,
    "JAN": 5.02,
    "FEB": 5.06,
    "MAR": 4.77,
    "APR": 4.64,
    "MAY": 3.95,
    "JUN": 3.43,
    "JUL": 3.68,
    "AUG": 4.22,
    "SEP": 3.85,
    "OCT": 4.15,
    "NOV": 5.09,
    "DEC": 5.13
  },
  {
    "LONG": -54.22,
    "LAT": -29.29,
    "CIDADE": "Jari",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.69,
    "FEB": 5.77,
    "MAR": 5.51,
    "APR": 4.88,
    "MAY": 4.02,
    "JUN": 3.48,
    "JUL": 3.84,
    "AUG": 4.47,
    "SEP": 4.41,
    "OCT": 5,
    "NOV": 5.69,
    "DEC": 5.8
  },
  {
    "LONG": -53.34,
    "LAT": -29.34,
    "CIDADE": "Pinhal Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.56,
    "FEB": 5.72,
    "MAR": 5.44,
    "APR": 4.78,
    "MAY": 3.96,
    "JUN": 3.39,
    "JUL": 3.74,
    "AUG": 4.42,
    "SEP": 4.28,
    "OCT": 4.93,
    "NOV": 5.66,
    "DEC": 5.72
  },
  {
    "LONG": -53.09,
    "LAT": -29.33,
    "CIDADE": "Arroio do Tigre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.54,
    "FEB": 5.72,
    "MAR": 5.44,
    "APR": 4.8,
    "MAY": 3.92,
    "JUN": 3.41,
    "JUL": 3.72,
    "AUG": 4.41,
    "SEP": 4.19,
    "OCT": 4.93,
    "NOV": 5.65,
    "DEC": 5.76
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.93,
    "CIDADE": "Gramado Xavier",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.42,
    "FEB": 5.46,
    "MAR": 5.23,
    "APR": 4.72,
    "MAY": 3.81,
    "JUN": 3.37,
    "JUL": 3.7,
    "AUG": 4.34,
    "SEP": 4.04,
    "OCT": 4.74,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -52.43,
    "LAT": -29.31,
    "CIDADE": "Boqueirão do Leão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.38,
    "FEB": 5.45,
    "MAR": 5.2,
    "APR": 4.69,
    "MAY": 3.82,
    "JUN": 3.37,
    "JUL": 3.67,
    "AUG": 4.32,
    "SEP": 4.05,
    "OCT": 4.69,
    "NOV": 5.51,
    "DEC": 5.55
  },
  {
    "LONG": -52.24,
    "LAT": -29.33,
    "CIDADE": "Canudos do Vale",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.39,
    "FEB": 5.46,
    "MAR": 5.16,
    "APR": 4.63,
    "MAY": 3.77,
    "JUN": 3.33,
    "JUL": 3.64,
    "AUG": 4.28,
    "SEP": 4.03,
    "OCT": 4.62,
    "NOV": 5.47,
    "DEC": 5.54
  },
  {
    "LONG": -52.05,
    "LAT": -29.3,
    "CIDADE": "Travesseiro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.36,
    "FEB": 5.47,
    "MAR": 5.14,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.6,
    "AUG": 4.23,
    "SEP": 3.99,
    "OCT": 4.6,
    "NOV": 5.45,
    "DEC": 5.52
  },
  {
    "LONG": -52.1,
    "LAT": -29.33,
    "CIDADE": "Marques de Souza",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.36,
    "FEB": 5.47,
    "MAR": 5.14,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.6,
    "AUG": 4.23,
    "SEP": 3.99,
    "OCT": 4.6,
    "NOV": 5.45,
    "DEC": 5.52
  },
  {
    "LONG": -51.99,
    "LAT": -29.27,
    "CIDADE": "Capitão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.58,
    "JAN": 5.35,
    "FEB": 5.45,
    "MAR": 5.12,
    "APR": 4.61,
    "MAY": 3.76,
    "JUN": 3.27,
    "JUL": 3.58,
    "AUG": 4.24,
    "SEP": 4,
    "OCT": 4.59,
    "NOV": 5.46,
    "DEC": 5.51
  },
  {
    "LONG": -51.87,
    "LAT": -29.29,
    "CIDADE": "Roca Sales",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.47,
    "FEB": 5.51,
    "MAR": 5.19,
    "APR": 4.6,
    "MAY": 3.76,
    "JUN": 3.24,
    "JUL": 3.59,
    "AUG": 4.23,
    "SEP": 4.01,
    "OCT": 4.65,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -51.69,
    "LAT": "#VALOR!",
    "CIDADE": "Coronel Pilar",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.44,
    "FEB": 5.48,
    "MAR": 5.17,
    "APR": 4.65,
    "MAY": 3.75,
    "JUN": 3.3,
    "JUL": 3.68,
    "AUG": 4.29,
    "SEP": 4.02,
    "OCT": 4.68,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -51.5,
    "LAT": -29.3,
    "CIDADE": "Carlos Barbosa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.43,
    "FEB": 5.44,
    "MAR": 5.09,
    "APR": 4.67,
    "MAY": 3.77,
    "JUN": 3.35,
    "JUL": 3.67,
    "AUG": 4.3,
    "SEP": 4.02,
    "OCT": 4.67,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -51.54,
    "LAT": -29.26,
    "CIDADE": "Garibaldi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.43,
    "FEB": 5.44,
    "MAR": 5.09,
    "APR": 4.67,
    "MAY": 3.77,
    "JUN": 3.35,
    "JUL": 3.67,
    "AUG": 4.3,
    "SEP": 4.02,
    "OCT": 4.67,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -49.73,
    "LAT": -29.33,
    "CIDADE": "Torres",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.5,
    "JAN": 5.19,
    "FEB": 5.2,
    "MAR": 4.91,
    "APR": 4.69,
    "MAY": 4,
    "JUN": 3.45,
    "JUL": 3.68,
    "AUG": 4.25,
    "SEP": 3.91,
    "OCT": 4.28,
    "NOV": 5.21,
    "DEC": 5.26
  },
  {
    "LONG": -54.87,
    "LAT": -29.19,
    "CIDADE": "Santiago",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.72,
    "FEB": 5.8,
    "MAR": 5.6,
    "APR": 4.96,
    "MAY": 4.05,
    "JUN": 3.49,
    "JUL": 3.89,
    "AUG": 4.48,
    "SEP": 4.52,
    "OCT": 5.11,
    "NOV": 5.8,
    "DEC": 5.87
  },
  {
    "LONG": -53.68,
    "LAT": -29.23,
    "CIDADE": "Júlio de Castilhos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.6,
    "FEB": 5.69,
    "MAR": 5.45,
    "APR": 4.84,
    "MAY": 3.97,
    "JUN": 3.43,
    "JUL": 3.8,
    "AUG": 4.49,
    "SEP": 4.36,
    "OCT": 4.96,
    "NOV": 5.68,
    "DEC": 5.78
  },
  {
    "LONG": -53.16,
    "LAT": -2.92,
    "CIDADE": "Estrela Velha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.6,
    "FEB": 5.71,
    "MAR": 5.44,
    "APR": 4.78,
    "MAY": 3.94,
    "JUN": 3.4,
    "JUL": 3.73,
    "AUG": 4.45,
    "SEP": 4.24,
    "OCT": 4.92,
    "NOV": 5.65,
    "DEC": 5.75
  },
  {
    "LONG": -5.28,
    "LAT": -29.22,
    "CIDADE": "Lagoão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.48,
    "FEB": 5.62,
    "MAR": 5.33,
    "APR": 4.8,
    "MAY": 3.88,
    "JUN": 3.41,
    "JUL": 3.74,
    "AUG": 4.4,
    "SEP": 4.15,
    "OCT": 4.86,
    "NOV": 5.63,
    "DEC": 5.68
  },
  {
    "LONG": -52.31,
    "LAT": -2.92,
    "CIDADE": "Progresso",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.4,
    "FEB": 5.49,
    "MAR": 5.21,
    "APR": 4.68,
    "MAY": 3.8,
    "JUN": 3.31,
    "JUL": 3.64,
    "AUG": 4.34,
    "SEP": 4.05,
    "OCT": 4.68,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -52.21,
    "LAT": -2.92,
    "CIDADE": "Pouso Novo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.61,
    "JAN": 5.4,
    "FEB": 5.46,
    "MAR": 5.17,
    "APR": 4.63,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.61,
    "AUG": 4.29,
    "SEP": 4.03,
    "OCT": 4.63,
    "NOV": 5.46,
    "DEC": 5.55
  },
  {
    "LONG": -52.1,
    "LAT": -29.18,
    "CIDADE": "Coqueiro Baixo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.39,
    "FEB": 5.47,
    "MAR": 5.13,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.29,
    "JUL": 3.62,
    "AUG": 4.27,
    "SEP": 4.02,
    "OCT": 4.6,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -52.03,
    "LAT": -2.92,
    "CIDADE": "Nova Bréscia",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.39,
    "FEB": 5.47,
    "MAR": 5.13,
    "APR": 4.62,
    "MAY": 3.77,
    "JUN": 3.29,
    "JUL": 3.62,
    "AUG": 4.27,
    "SEP": 4.02,
    "OCT": 4.6,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -51.87,
    "LAT": -29.17,
    "CIDADE": "Muçum",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.48,
    "FEB": 5.52,
    "MAR": 5.19,
    "APR": 4.63,
    "MAY": 3.76,
    "JUN": 3.28,
    "JUL": 3.59,
    "AUG": 4.24,
    "SEP": 4.04,
    "OCT": 4.67,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -51.87,
    "LAT": -29.24,
    "CIDADE": "Encantado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.48,
    "FEB": 5.52,
    "MAR": 5.19,
    "APR": 4.63,
    "MAY": 3.76,
    "JUN": 3.28,
    "JUL": 3.59,
    "AUG": 4.24,
    "SEP": 4.04,
    "OCT": 4.67,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -51.74,
    "LAT": -29.17,
    "CIDADE": "Santa Tereza",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.46,
    "FEB": 5.51,
    "MAR": 5.19,
    "APR": 4.66,
    "MAY": 3.76,
    "JUN": 3.28,
    "JUL": 3.64,
    "AUG": 4.27,
    "SEP": 4.04,
    "OCT": 4.68,
    "NOV": 5.52,
    "DEC": 5.58
  },
  {
    "LONG": -51.63,
    "LAT": -29.16,
    "CIDADE": "Monte Belo do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.47,
    "FEB": 5.51,
    "MAR": 5.19,
    "APR": 4.68,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.68,
    "AUG": 4.3,
    "SEP": 4.05,
    "OCT": 4.71,
    "NOV": 5.54,
    "DEC": 5.6
  },
  {
    "LONG": -51.52,
    "LAT": -29.17,
    "CIDADE": "Bento Gonçalves",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.45,
    "FEB": 5.53,
    "MAR": 5.13,
    "APR": 4.68,
    "MAY": 3.78,
    "JUN": 3.35,
    "JUL": 3.68,
    "AUG": 4.33,
    "SEP": 4.06,
    "OCT": 4.73,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -51.34,
    "LAT": -29.22,
    "CIDADE": "Farroupilha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.64,
    "JAN": 5.45,
    "FEB": 5.45,
    "MAR": 5.09,
    "APR": 4.67,
    "MAY": 3.76,
    "JUN": 3.35,
    "JUL": 3.69,
    "AUG": 4.33,
    "SEP": 4.06,
    "OCT": 4.7,
    "NOV": 5.56,
    "DEC": 5.56
  },
  {
    "LONG": -51.18,
    "LAT": -29.16,
    "CIDADE": "Caxias do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.38,
    "FEB": 5.4,
    "MAR": 4.99,
    "APR": 4.63,
    "MAY": 3.75,
    "JUN": 3.35,
    "JUL": 3.74,
    "AUG": 4.32,
    "SEP": 4.04,
    "OCT": 4.67,
    "NOV": 5.48,
    "DEC": 5.5
  },
  {
    "LONG": -4.99,
    "LAT": -29.21,
    "CIDADE": "Mampituba",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.21,
    "JAN": 4.6,
    "FEB": 4.66,
    "MAR": 4.47,
    "APR": 4.45,
    "MAY": 3.82,
    "JUN": 3.41,
    "JUL": 3.68,
    "AUG": 4.26,
    "SEP": 3.73,
    "OCT": 3.94,
    "NOV": 4.78,
    "DEC": 4.77
  },
  {
    "LONG": -5.66,
    "LAT": -29.13,
    "CIDADE": "Itaqui",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.08,
    "JAN": 6.04,
    "FEB": 6,
    "MAR": 5.74,
    "APR": 5.07,
    "MAY": 4.09,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.59,
    "SEP": 4.68,
    "OCT": 5.37,
    "NOV": 5.85,
    "DEC": 6.03
  },
  {
    "LONG": -56.07,
    "LAT": -29.14,
    "CIDADE": "Maçambará",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.05,
    "JAN": 6,
    "FEB": 5.99,
    "MAR": 5.72,
    "APR": 4.98,
    "MAY": 4.12,
    "JUN": 3.53,
    "JUL": 3.89,
    "AUG": 4.57,
    "SEP": 4.64,
    "OCT": 5.34,
    "NOV": 5.83,
    "DEC": 5.99
  },
  {
    "LONG": -53.84,
    "LAT": -2.91,
    "CIDADE": "Tupanciretã",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.67,
    "FEB": 5.74,
    "MAR": 5.5,
    "APR": 4.89,
    "MAY": 4.02,
    "JUN": 3.48,
    "JUL": 3.81,
    "AUG": 4.49,
    "SEP": 4.39,
    "OCT": 4.98,
    "NOV": 5.7,
    "DEC": 5.76
  },
  {
    "LONG": -53.21,
    "LAT": -29.1,
    "CIDADE": "Salto do Jacuí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.6,
    "FEB": 5.66,
    "MAR": 5.43,
    "APR": 4.79,
    "MAY": 3.97,
    "JUN": 3.4,
    "JUL": 3.74,
    "AUG": 4.44,
    "SEP": 4.25,
    "OCT": 4.93,
    "NOV": 5.63,
    "DEC": 5.73
  },
  {
    "LONG": -52.95,
    "LAT": -29.1,
    "CIDADE": "Tunas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.59,
    "FEB": 5.67,
    "MAR": 5.46,
    "APR": 4.79,
    "MAY": 3.97,
    "JUN": 3.41,
    "JUL": 3.73,
    "AUG": 4.45,
    "SEP": 4.22,
    "OCT": 4.93,
    "NOV": 5.66,
    "DEC": 5.72
  },
  {
    "LONG": -52.58,
    "LAT": -2.91,
    "CIDADE": "Barros Cassal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.49,
    "FEB": 5.53,
    "MAR": 5.3,
    "APR": 4.76,
    "MAY": 3.89,
    "JUN": 3.41,
    "JUL": 3.73,
    "AUG": 4.39,
    "SEP": 4.14,
    "OCT": 4.78,
    "NOV": 5.57,
    "DEC": 5.6
  },
  {
    "LONG": -52.08,
    "LAT": -29.12,
    "CIDADE": "Relvado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.59,
    "JAN": 5.35,
    "FEB": 5.43,
    "MAR": 5.1,
    "APR": 4.6,
    "MAY": 3.76,
    "JUN": 3.3,
    "JUL": 3.67,
    "AUG": 4.3,
    "SEP": 4.05,
    "OCT": 4.6,
    "NOV": 5.44,
    "DEC": 5.48
  },
  {
    "LONG": -51.99,
    "LAT": -2.91,
    "CIDADE": "Doutor Ricardo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.6,
    "JAN": 5.38,
    "FEB": 5.47,
    "MAR": 5.12,
    "APR": 4.61,
    "MAY": 3.75,
    "JUN": 3.26,
    "JUL": 3.63,
    "AUG": 4.29,
    "SEP": 4.06,
    "OCT": 4.63,
    "NOV": 5.47,
    "DEC": 5.49
  },
  {
    "LONG": -51.86,
    "LAT": -2.91,
    "CIDADE": "Vespasiano Corrêa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.45,
    "FEB": 5.51,
    "MAR": 5.2,
    "APR": 4.63,
    "MAY": 3.77,
    "JUN": 3.3,
    "JUL": 3.64,
    "AUG": 4.29,
    "SEP": 4.07,
    "OCT": 4.66,
    "NOV": 5.49,
    "DEC": 5.55
  },
  {
    "LONG": -5.15,
    "LAT": -29.1,
    "CIDADE": "Pinto Bandeira",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.49,
    "FEB": 5.51,
    "MAR": 5.18,
    "APR": 4.67,
    "MAY": 3.81,
    "JUN": 3.33,
    "JUL": 3.67,
    "AUG": 4.36,
    "SEP": 4.09,
    "OCT": 4.73,
    "NOV": 5.56,
    "DEC": 5.59
  },
  {
    "LONG": -55.15,
    "LAT": -29.05,
    "CIDADE": "Unistalda",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5,
    "JAN": 5.78,
    "FEB": 5.88,
    "MAR": 5.64,
    "APR": 4.98,
    "MAY": 4.11,
    "JUN": 3.56,
    "JUL": 3.95,
    "AUG": 4.55,
    "SEP": 4.56,
    "OCT": 5.22,
    "NOV": 5.84,
    "DEC": 5.92
  },
  {
    "LONG": -53.06,
    "LAT": -29.03,
    "CIDADE": "Jacuizinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.63,
    "FEB": 5.69,
    "MAR": 5.46,
    "APR": 4.82,
    "MAY": 3.98,
    "JUN": 3.42,
    "JUL": 3.76,
    "AUG": 4.47,
    "SEP": 4.28,
    "OCT": 4.95,
    "NOV": 5.68,
    "DEC": 5.77
  },
  {
    "LONG": -52.34,
    "LAT": -28.98,
    "CIDADE": "Fontoura Xavier",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.38,
    "FEB": 5.49,
    "MAR": 5.21,
    "APR": 4.71,
    "MAY": 3.86,
    "JUN": 3.4,
    "JUL": 3.77,
    "AUG": 4.4,
    "SEP": 4.14,
    "OCT": 4.77,
    "NOV": 5.53,
    "DEC": 5.56
  },
  {
    "LONG": -52.3,
    "LAT": -2.9,
    "CIDADE": "São José do Herval",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.36,
    "FEB": 5.45,
    "MAR": 5.16,
    "APR": 4.67,
    "MAY": 3.82,
    "JUN": 3.37,
    "JUL": 3.75,
    "AUG": 4.36,
    "SEP": 4.08,
    "OCT": 4.69,
    "NOV": 5.5,
    "DEC": 5.53
  },
  {
    "LONG": -52.16,
    "LAT": -2.9,
    "CIDADE": "Putinga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.63,
    "JAN": 5.37,
    "FEB": 5.4,
    "MAR": 5.13,
    "APR": 4.67,
    "MAY": 3.81,
    "JUN": 3.37,
    "JUL": 3.75,
    "AUG": 4.36,
    "SEP": 4.08,
    "OCT": 4.66,
    "NOV": 5.47,
    "DEC": 5.52
  },
  {
    "LONG": -52.01,
    "LAT": -28.97,
    "CIDADE": "Anta Gorda",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.62,
    "JAN": 5.34,
    "FEB": 5.43,
    "MAR": 5.13,
    "APR": 4.64,
    "MAY": 3.78,
    "JUN": 3.34,
    "JUL": 3.72,
    "AUG": 4.34,
    "SEP": 4.1,
    "OCT": 4.65,
    "NOV": 5.47,
    "DEC": 5.49
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.98,
    "CIDADE": "Dois Lajeados",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.44,
    "FEB": 5.53,
    "MAR": 5.21,
    "APR": 4.67,
    "MAY": 3.79,
    "JUN": 3.3,
    "JUL": 3.71,
    "AUG": 4.31,
    "SEP": 4.09,
    "OCT": 4.68,
    "NOV": 5.5,
    "DEC": 5.55
  },
  {
    "LONG": -51.76,
    "LAT": -2.9,
    "CIDADE": "São Valentim do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.47,
    "FEB": 5.51,
    "MAR": 5.23,
    "APR": 4.67,
    "MAY": 3.79,
    "JUN": 3.3,
    "JUL": 3.71,
    "AUG": 4.31,
    "SEP": 4.08,
    "OCT": 4.69,
    "NOV": 5.51,
    "DEC": 5.57
  },
  {
    "LONG": -51.7,
    "LAT": -28.99,
    "CIDADE": "Cotiporã",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.47,
    "FEB": 5.5,
    "MAR": 5.23,
    "APR": 4.68,
    "MAY": 3.8,
    "JUN": 3.32,
    "JUL": 3.73,
    "AUG": 4.34,
    "SEP": 4.08,
    "OCT": 4.69,
    "NOV": 5.5,
    "DEC": 5.57
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.99,
    "CIDADE": "Nova Roma do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.46,
    "FEB": 5.47,
    "MAR": 5.22,
    "APR": 4.67,
    "MAY": 3.79,
    "JUN": 3.34,
    "JUL": 3.7,
    "AUG": 4.36,
    "SEP": 4.09,
    "OCT": 4.73,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -51.31,
    "LAT": -2.9,
    "CIDADE": "Nova Pádua",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.47,
    "FEB": 5.49,
    "MAR": 5.21,
    "APR": 4.67,
    "MAY": 3.79,
    "JUN": 3.35,
    "JUL": 3.7,
    "AUG": 4.37,
    "SEP": 4.09,
    "OCT": 4.75,
    "NOV": 5.55,
    "DEC": 5.56
  },
  {
    "LONG": -51.19,
    "LAT": -29.03,
    "CIDADE": "Flores da Cunha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.43,
    "FEB": 5.48,
    "MAR": 5.14,
    "APR": 4.68,
    "MAY": 3.81,
    "JUN": 3.35,
    "JUL": 3.75,
    "AUG": 4.37,
    "SEP": 4.11,
    "OCT": 4.77,
    "NOV": 5.58,
    "DEC": 5.56
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.97,
    "CIDADE": "São Marcos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.44,
    "FEB": 5.47,
    "MAR": 5.14,
    "APR": 4.69,
    "MAY": 3.81,
    "JUN": 3.37,
    "JUL": 3.79,
    "AUG": 4.38,
    "SEP": 4.11,
    "OCT": 4.78,
    "NOV": 5.58,
    "DEC": 5.55
  },
  {
    "LONG": -50.15,
    "LAT": -29.05,
    "CIDADE": "Cambará do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.27,
    "FEB": 5.23,
    "MAR": 5.01,
    "APR": 4.7,
    "MAY": 3.86,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.51,
    "SEP": 4.09,
    "OCT": 4.74,
    "NOV": 5.48,
    "DEC": 5.44
  },
  {
    "LONG": -54.56,
    "LAT": -28.93,
    "CIDADE": "Capão do Cipó",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.73,
    "FEB": 5.83,
    "MAR": 5.59,
    "APR": 4.9,
    "MAY": 4.11,
    "JUN": 3.5,
    "JUL": 3.91,
    "AUG": 4.54,
    "SEP": 4.48,
    "OCT": 5.1,
    "NOV": 5.75,
    "DEC": 5.85
  },
  {
    "LONG": -53,
    "LAT": -28.89,
    "CIDADE": "Campos Borges",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.6,
    "FEB": 5.68,
    "MAR": 5.47,
    "APR": 4.84,
    "MAY": 4,
    "JUN": 3.39,
    "JUL": 3.77,
    "AUG": 4.47,
    "SEP": 4.3,
    "OCT": 4.96,
    "NOV": 5.68,
    "DEC": 5.76
  },
  {
    "LONG": -52.13,
    "LAT": -28.93,
    "CIDADE": "Ilópolis",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.36,
    "FEB": 5.44,
    "MAR": 5.13,
    "APR": 4.68,
    "MAY": 3.82,
    "JUN": 3.38,
    "JUL": 3.76,
    "AUG": 4.41,
    "SEP": 4.12,
    "OCT": 4.72,
    "NOV": 5.5,
    "DEC": 5.54
  },
  {
    "LONG": -52.18,
    "LAT": -28.87,
    "CIDADE": "Arvorezinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.65,
    "JAN": 5.36,
    "FEB": 5.44,
    "MAR": 5.13,
    "APR": 4.68,
    "MAY": 3.82,
    "JUN": 3.38,
    "JUL": 3.76,
    "AUG": 4.41,
    "SEP": 4.12,
    "OCT": 4.72,
    "NOV": 5.5,
    "DEC": 5.54
  },
  {
    "LONG": -51.7,
    "LAT": -28.88,
    "CIDADE": "Fagundes Varela",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.49,
    "FEB": 5.5,
    "MAR": 5.2,
    "APR": 4.71,
    "MAY": 3.82,
    "JUN": 3.32,
    "JUL": 3.74,
    "AUG": 4.35,
    "SEP": 4.11,
    "OCT": 4.71,
    "NOV": 5.51,
    "DEC": 5.57
  },
  {
    "LONG": -51.55,
    "LAT": -28.86,
    "CIDADE": "Vila Flores",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.39,
    "FEB": 5.43,
    "MAR": 5.17,
    "APR": 4.68,
    "MAY": 3.84,
    "JUN": 3.39,
    "JUL": 3.76,
    "AUG": 4.38,
    "SEP": 4.13,
    "OCT": 4.72,
    "NOV": 5.51,
    "DEC": 5.53
  },
  {
    "LONG": -51.55,
    "LAT": -28.93,
    "CIDADE": "Veranópolis",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.39,
    "FEB": 5.43,
    "MAR": 5.17,
    "APR": 4.68,
    "MAY": 3.84,
    "JUN": 3.39,
    "JUL": 3.76,
    "AUG": 4.38,
    "SEP": 4.13,
    "OCT": 4.72,
    "NOV": 5.51,
    "DEC": 5.53
  },
  {
    "LONG": -51.29,
    "LAT": -2.89,
    "CIDADE": "Antônio Prado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.37,
    "FEB": 5.45,
    "MAR": 5.19,
    "APR": 4.68,
    "MAY": 3.81,
    "JUN": 3.38,
    "JUL": 3.78,
    "AUG": 4.39,
    "SEP": 4.11,
    "OCT": 4.74,
    "NOV": 5.57,
    "DEC": 5.54
  },
  {
    "LONG": -50.36,
    "LAT": -28.88,
    "CIDADE": "Jaquirana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.36,
    "FEB": 5.37,
    "MAR": 5.11,
    "APR": 4.73,
    "MAY": 3.87,
    "JUN": 3.48,
    "JUL": 3.86,
    "AUG": 4.5,
    "SEP": 4.12,
    "OCT": 4.8,
    "NOV": 5.53,
    "DEC": 5.51
  },
  {
    "LONG": -55.25,
    "LAT": -28.79,
    "CIDADE": "Itacurubi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.99,
    "JAN": 5.82,
    "FEB": 5.86,
    "MAR": 5.66,
    "APR": 4.92,
    "MAY": 4.14,
    "JUN": 3.53,
    "JUL": 3.89,
    "AUG": 4.58,
    "SEP": 4.55,
    "OCT": 5.19,
    "NOV": 5.81,
    "DEC": 5.88
  },
  {
    "LONG": -53.39,
    "LAT": -28.82,
    "CIDADE": "Boa Vista do Incra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.57,
    "FEB": 5.71,
    "MAR": 5.46,
    "APR": 4.82,
    "MAY": 4.04,
    "JUN": 3.41,
    "JUL": 3.82,
    "AUG": 4.47,
    "SEP": 4.33,
    "OCT": 4.95,
    "NOV": 5.62,
    "DEC": 5.7
  },
  {
    "LONG": -53.23,
    "LAT": -28.8,
    "CIDADE": "Fortaleza dos Valos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.62,
    "FEB": 5.66,
    "MAR": 5.44,
    "APR": 4.85,
    "MAY": 4.01,
    "JUN": 3.37,
    "JUL": 3.78,
    "AUG": 4.49,
    "SEP": 4.34,
    "OCT": 4.94,
    "NOV": 5.63,
    "DEC": 5.7
  },
  {
    "LONG": -52.99,
    "LAT": -28.78,
    "CIDADE": "Alto Alegre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.58,
    "FEB": 5.67,
    "MAR": 5.45,
    "APR": 4.83,
    "MAY": 4.04,
    "JUN": 3.42,
    "JUL": 3.77,
    "AUG": 4.48,
    "SEP": 4.29,
    "OCT": 4.98,
    "NOV": 5.67,
    "DEC": 5.72
  },
  {
    "LONG": -52.51,
    "LAT": -28.83,
    "CIDADE": "Soledade",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.52,
    "FEB": 5.6,
    "MAR": 5.39,
    "APR": 4.83,
    "MAY": 4,
    "JUN": 3.45,
    "JUL": 3.82,
    "AUG": 4.48,
    "SEP": 4.26,
    "OCT": 4.98,
    "NOV": 5.66,
    "DEC": 5.73
  },
  {
    "LONG": -52.17,
    "LAT": -28.78,
    "CIDADE": "Itapuca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.36,
    "FEB": 5.52,
    "MAR": 5.22,
    "APR": 4.74,
    "MAY": 3.85,
    "JUN": 3.39,
    "JUL": 3.78,
    "AUG": 4.43,
    "SEP": 4.17,
    "OCT": 4.79,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -52.02,
    "LAT": -28.78,
    "CIDADE": "União da Serra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.39,
    "FEB": 5.56,
    "MAR": 5.22,
    "APR": 4.7,
    "MAY": 3.84,
    "JUN": 3.36,
    "JUL": 3.74,
    "AUG": 4.4,
    "SEP": 4.15,
    "OCT": 4.78,
    "NOV": 5.55,
    "DEC": 5.62
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.84,
    "CIDADE": "Guaporé",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.36,
    "FEB": 5.5,
    "MAR": 5.17,
    "APR": 4.66,
    "MAY": 3.81,
    "JUN": 3.38,
    "JUL": 3.74,
    "AUG": 4.36,
    "SEP": 4.14,
    "OCT": 4.73,
    "NOV": 5.52,
    "DEC": 5.58
  },
  {
    "LONG": -51.8,
    "LAT": -28.81,
    "CIDADE": "Vista Alegre do Prata",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.42,
    "FEB": 5.52,
    "MAR": 5.17,
    "APR": 4.71,
    "MAY": 3.85,
    "JUN": 3.39,
    "JUL": 3.78,
    "AUG": 4.39,
    "SEP": 4.13,
    "OCT": 4.73,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -51.61,
    "LAT": -28.78,
    "CIDADE": "Nova Prata",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.43,
    "FEB": 5.51,
    "MAR": 5.16,
    "APR": 4.72,
    "MAY": 3.86,
    "JUN": 3.38,
    "JUL": 3.78,
    "AUG": 4.39,
    "SEP": 4.15,
    "OCT": 4.72,
    "NOV": 5.51,
    "DEC": 5.54
  },
  {
    "LONG": -51.48,
    "LAT": -28.76,
    "CIDADE": "Protásio Alves",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.67,
    "JAN": 5.39,
    "FEB": 5.41,
    "MAR": 5.22,
    "APR": 4.71,
    "MAY": 3.84,
    "JUN": 3.38,
    "JUL": 3.76,
    "AUG": 4.39,
    "SEP": 4.11,
    "OCT": 4.75,
    "NOV": 5.55,
    "DEC": 5.53
  },
  {
    "LONG": -51.29,
    "LAT": -28.82,
    "CIDADE": "Ipê",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.34,
    "FEB": 5.44,
    "MAR": 5.19,
    "APR": 4.7,
    "MAY": 3.83,
    "JUN": 3.43,
    "JUL": 3.81,
    "AUG": 4.44,
    "SEP": 4.14,
    "OCT": 4.78,
    "NOV": 5.57,
    "DEC": 5.55
  },
  {
    "LONG": -51.09,
    "LAT": -28.79,
    "CIDADE": "Campestre da Serra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.66,
    "JAN": 5.32,
    "FEB": 5.41,
    "MAR": 5.19,
    "APR": 4.64,
    "MAY": 3.84,
    "JUN": 3.4,
    "JUL": 3.8,
    "AUG": 4.42,
    "SEP": 4.14,
    "OCT": 4.77,
    "NOV": 5.53,
    "DEC": 5.51
  },
  {
    "LONG": -56,
    "LAT": -28.66,
    "CIDADE": "São Borja",
    "UF": "RIO GRANDE DO SUL",
    "anual": 5.02,
    "JAN": 5.98,
    "FEB": 5.92,
    "MAR": 5.68,
    "APR": 4.93,
    "MAY": 4.1,
    "JUN": 3.51,
    "JUL": 3.9,
    "AUG": 4.54,
    "SEP": 4.6,
    "OCT": 5.28,
    "NOV": 5.8,
    "DEC": 5.97
  },
  {
    "LONG": -5.49,
    "LAT": -28.73,
    "CIDADE": "Bossoroca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.97,
    "JAN": 5.79,
    "FEB": 5.85,
    "MAR": 5.66,
    "APR": 4.92,
    "MAY": 4.13,
    "JUN": 3.53,
    "JUL": 3.93,
    "AUG": 4.57,
    "SEP": 4.52,
    "OCT": 5.14,
    "NOV": 5.77,
    "DEC": 5.85
  },
  {
    "LONG": -53.09,
    "LAT": -2.87,
    "CIDADE": "Quinze de Novembro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.64,
    "FEB": 5.7,
    "MAR": 5.43,
    "APR": 4.86,
    "MAY": 4.05,
    "JUN": 3.45,
    "JUL": 3.8,
    "AUG": 4.48,
    "SEP": 4.31,
    "OCT": 4.98,
    "NOV": 5.64,
    "DEC": 5.7
  },
  {
    "LONG": -52.85,
    "LAT": -28.73,
    "CIDADE": "Espumoso",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.57,
    "FEB": 5.68,
    "MAR": 5.4,
    "APR": 4.84,
    "MAY": 4.02,
    "JUN": 3.44,
    "JUL": 3.79,
    "AUG": 4.5,
    "SEP": 4.28,
    "OCT": 4.98,
    "NOV": 5.64,
    "DEC": 5.71
  },
  {
    "LONG": -52.69,
    "LAT": -2.87,
    "CIDADE": "Mormaço",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.53,
    "FEB": 5.61,
    "MAR": 5.4,
    "APR": 4.83,
    "MAY": 4.03,
    "JUN": 3.44,
    "JUL": 3.81,
    "AUG": 4.49,
    "SEP": 4.27,
    "OCT": 4.98,
    "NOV": 5.65,
    "DEC": 5.69
  },
  {
    "LONG": -52.16,
    "LAT": -28.68,
    "CIDADE": "Nova Alvorada",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.74,
    "JAN": 5.44,
    "FEB": 5.59,
    "MAR": 5.26,
    "APR": 4.79,
    "MAY": 3.88,
    "JUN": 3.4,
    "JUL": 3.79,
    "AUG": 4.47,
    "SEP": 4.2,
    "OCT": 4.86,
    "NOV": 5.58,
    "DEC": 5.68
  },
  {
    "LONG": -51.94,
    "LAT": -28.71,
    "CIDADE": "Serafina Corrêa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.39,
    "FEB": 5.57,
    "MAR": 5.2,
    "APR": 4.74,
    "MAY": 3.85,
    "JUN": 3.43,
    "JUL": 3.78,
    "AUG": 4.43,
    "SEP": 4.17,
    "OCT": 4.79,
    "NOV": 5.53,
    "DEC": 5.61
  },
  {
    "LONG": -51.75,
    "LAT": -28.65,
    "CIDADE": "Nova Araçá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.39,
    "FEB": 5.48,
    "MAR": 5.21,
    "APR": 4.76,
    "MAY": 3.86,
    "JUN": 3.45,
    "JUL": 3.82,
    "AUG": 4.42,
    "SEP": 4.15,
    "OCT": 4.76,
    "NOV": 5.52,
    "DEC": 5.54
  },
  {
    "LONG": -51.71,
    "LAT": -28.73,
    "CIDADE": "Nova Bassano",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.39,
    "FEB": 5.48,
    "MAR": 5.21,
    "APR": 4.76,
    "MAY": 3.86,
    "JUN": 3.45,
    "JUL": 3.82,
    "AUG": 4.42,
    "SEP": 4.15,
    "OCT": 4.76,
    "NOV": 5.52,
    "DEC": 5.54
  },
  {
    "LONG": -50.78,
    "LAT": -2.87,
    "CIDADE": "Monte Alegre dos Campos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.68,
    "JAN": 5.3,
    "FEB": 5.38,
    "MAR": 5.12,
    "APR": 4.74,
    "MAY": 3.87,
    "JUN": 3.44,
    "JUL": 3.85,
    "AUG": 4.52,
    "SEP": 4.17,
    "OCT": 4.8,
    "NOV": 5.52,
    "DEC": 5.45
  },
  {
    "LONG": -50.43,
    "LAT": -28.67,
    "CIDADE": "Bom Jesus",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.33,
    "FEB": 5.38,
    "MAR": 5.11,
    "APR": 4.72,
    "MAY": 3.87,
    "JUN": 3.45,
    "JUL": 3.86,
    "AUG": 4.5,
    "SEP": 4.2,
    "OCT": 4.82,
    "NOV": 5.51,
    "DEC": 5.48
  },
  {
    "LONG": -50.07,
    "LAT": -28.75,
    "CIDADE": "São José dos Ausentes",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.72,
    "JAN": 5.3,
    "FEB": 5.3,
    "MAR": 5.12,
    "APR": 4.8,
    "MAY": 3.93,
    "JUN": 3.57,
    "JUL": 3.96,
    "AUG": 4.61,
    "SEP": 4.21,
    "OCT": 4.83,
    "NOV": 5.53,
    "DEC": 5.52
  },
  {
    "LONG": -54.56,
    "LAT": -28.56,
    "CIDADE": "São Miguel das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.74,
    "FEB": 5.79,
    "MAR": 5.61,
    "APR": 4.92,
    "MAY": 4.09,
    "JUN": 3.5,
    "JUL": 3.85,
    "AUG": 4.54,
    "SEP": 4.46,
    "OCT": 5.08,
    "NOV": 5.73,
    "DEC": 5.78
  },
  {
    "LONG": -54.11,
    "LAT": -2.86,
    "CIDADE": "Jóia",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.72,
    "FEB": 5.78,
    "MAR": 5.61,
    "APR": 4.93,
    "MAY": 4.09,
    "JUN": 3.51,
    "JUL": 3.86,
    "AUG": 4.51,
    "SEP": 4.42,
    "OCT": 5.04,
    "NOV": 5.7,
    "DEC": 5.77
  },
  {
    "LONG": -53.81,
    "LAT": -28.58,
    "CIDADE": "Boa Vista do Cadeado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.67,
    "FEB": 5.79,
    "MAR": 5.51,
    "APR": 4.92,
    "MAY": 4.1,
    "JUN": 3.48,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.39,
    "OCT": 5.01,
    "NOV": 5.67,
    "DEC": 5.74
  },
  {
    "LONG": -53.61,
    "LAT": -28.65,
    "CIDADE": "Cruz Alta",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.62,
    "FEB": 5.76,
    "MAR": 5.48,
    "APR": 4.86,
    "MAY": 4.07,
    "JUN": 3.47,
    "JUL": 3.85,
    "AUG": 4.51,
    "SEP": 4.36,
    "OCT": 4.97,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -53.1,
    "LAT": -28.63,
    "CIDADE": "Ibirubá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.61,
    "FEB": 5.68,
    "MAR": 5.43,
    "APR": 4.82,
    "MAY": 4.03,
    "JUN": 3.46,
    "JUL": 3.79,
    "AUG": 4.5,
    "SEP": 4.31,
    "OCT": 4.97,
    "NOV": 5.64,
    "DEC": 5.67
  },
  {
    "LONG": -52.95,
    "LAT": -28.63,
    "CIDADE": "Selbach",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.58,
    "FEB": 5.64,
    "MAR": 5.39,
    "APR": 4.86,
    "MAY": 4.06,
    "JUN": 3.45,
    "JUL": 3.85,
    "AUG": 4.52,
    "SEP": 4.31,
    "OCT": 4.97,
    "NOV": 5.63,
    "DEC": 5.71
  },
  {
    "LONG": -52.86,
    "LAT": -28.57,
    "CIDADE": "Lagoa dos Três Cantos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.57,
    "FEB": 5.64,
    "MAR": 5.38,
    "APR": 4.84,
    "MAY": 4.05,
    "JUN": 3.45,
    "JUL": 3.83,
    "AUG": 4.52,
    "SEP": 4.29,
    "OCT": 4.99,
    "NOV": 5.65,
    "DEC": 5.7
  },
  {
    "LONG": -52.87,
    "LAT": -2.86,
    "CIDADE": "Tapera",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.57,
    "FEB": 5.64,
    "MAR": 5.38,
    "APR": 4.84,
    "MAY": 4.05,
    "JUN": 3.45,
    "JUL": 3.83,
    "AUG": 4.52,
    "SEP": 4.29,
    "OCT": 4.99,
    "NOV": 5.65,
    "DEC": 5.7
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.56,
    "CIDADE": "Victor Graeff",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.59,
    "FEB": 5.66,
    "MAR": 5.38,
    "APR": 4.82,
    "MAY": 4.02,
    "JUN": 3.46,
    "JUL": 3.82,
    "AUG": 4.5,
    "SEP": 4.27,
    "OCT": 4.97,
    "NOV": 5.65,
    "DEC": 5.69
  },
  {
    "LONG": -5.26,
    "LAT": -28.57,
    "CIDADE": "Tio Hugo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.54,
    "FEB": 5.62,
    "MAR": 5.38,
    "APR": 4.85,
    "MAY": 4.02,
    "JUN": 3.44,
    "JUL": 3.81,
    "AUG": 4.51,
    "SEP": 4.25,
    "OCT": 4.97,
    "NOV": 5.64,
    "DEC": 5.69
  },
  {
    "LONG": -52.51,
    "LAT": -2.86,
    "CIDADE": "Ibirapuitã",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.54,
    "FEB": 5.62,
    "MAR": 5.38,
    "APR": 4.85,
    "MAY": 4.02,
    "JUN": 3.44,
    "JUL": 3.81,
    "AUG": 4.51,
    "SEP": 4.25,
    "OCT": 4.97,
    "NOV": 5.64,
    "DEC": 5.69
  },
  {
    "LONG": -52.2,
    "LAT": -28.59,
    "CIDADE": "Camargo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.77,
    "JAN": 5.49,
    "FEB": 5.59,
    "MAR": 5.3,
    "APR": 4.8,
    "MAY": 3.94,
    "JUN": 3.43,
    "JUL": 3.8,
    "AUG": 4.48,
    "SEP": 4.24,
    "OCT": 4.93,
    "NOV": 5.6,
    "DEC": 5.68
  },
  {
    "LONG": -52.08,
    "LAT": -28.65,
    "CIDADE": "Montauri",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.42,
    "FEB": 5.53,
    "MAR": 5.25,
    "APR": 4.78,
    "MAY": 3.88,
    "JUN": 3.45,
    "JUL": 3.81,
    "AUG": 4.44,
    "SEP": 4.18,
    "OCT": 4.85,
    "NOV": 5.52,
    "DEC": 5.64
  },
  {
    "LONG": -51.98,
    "LAT": -2.86,
    "CIDADE": "Casca",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.38,
    "FEB": 5.52,
    "MAR": 5.21,
    "APR": 4.75,
    "MAY": 3.87,
    "JUN": 3.46,
    "JUL": 3.83,
    "AUG": 4.42,
    "SEP": 4.17,
    "OCT": 4.81,
    "NOV": 5.48,
    "DEC": 5.59
  },
  {
    "LONG": -51.79,
    "LAT": -28.6,
    "CIDADE": "Paraí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.7,
    "JAN": 5.37,
    "FEB": 5.45,
    "MAR": 5.22,
    "APR": 4.78,
    "MAY": 3.84,
    "JUN": 3.44,
    "JUL": 3.82,
    "AUG": 4.42,
    "SEP": 4.16,
    "OCT": 4.81,
    "NOV": 5.52,
    "DEC": 5.55
  },
  {
    "LONG": -51.58,
    "LAT": -28.63,
    "CIDADE": "André da Rocha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.37,
    "FEB": 5.4,
    "MAR": 5.17,
    "APR": 4.7,
    "MAY": 3.88,
    "JUN": 3.43,
    "JUL": 3.86,
    "AUG": 4.43,
    "SEP": 4.17,
    "OCT": 4.8,
    "NOV": 5.53,
    "DEC": 5.5
  },
  {
    "LONG": -55.23,
    "LAT": -2.85,
    "CIDADE": "Santo Antônio das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.99,
    "JAN": 5.83,
    "FEB": 5.91,
    "MAR": 5.67,
    "APR": 4.93,
    "MAY": 4.15,
    "JUN": 3.52,
    "JUL": 3.91,
    "AUG": 4.55,
    "SEP": 4.57,
    "OCT": 5.16,
    "NOV": 5.78,
    "DEC": 5.85
  },
  {
    "LONG": -54.15,
    "LAT": -28.53,
    "CIDADE": "Eugênio de Castro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.71,
    "FEB": 5.81,
    "MAR": 5.58,
    "APR": 4.92,
    "MAY": 4.1,
    "JUN": 3.52,
    "JUL": 3.86,
    "AUG": 4.52,
    "SEP": 4.44,
    "OCT": 5.05,
    "NOV": 5.67,
    "DEC": 5.76
  },
  {
    "LONG": -53.99,
    "LAT": -28.52,
    "CIDADE": "Augusto Pestana",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.69,
    "FEB": 5.79,
    "MAR": 5.56,
    "APR": 4.91,
    "MAY": 4.1,
    "JUN": 3.49,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.4,
    "OCT": 5.02,
    "NOV": 5.67,
    "DEC": 5.74
  },
  {
    "LONG": -52.99,
    "LAT": -28.53,
    "CIDADE": "Colorado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.58,
    "FEB": 5.62,
    "MAR": 5.37,
    "APR": 4.86,
    "MAY": 4.05,
    "JUN": 3.48,
    "JUL": 3.87,
    "AUG": 4.52,
    "SEP": 4.3,
    "OCT": 4.98,
    "NOV": 5.62,
    "DEC": 5.67
  },
  {
    "LONG": -52.82,
    "LAT": -28.46,
    "CIDADE": "Não-Me-Toque",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.58,
    "FEB": 5.62,
    "MAR": 5.36,
    "APR": 4.86,
    "MAY": 4.05,
    "JUN": 3.47,
    "JUL": 3.87,
    "AUG": 4.5,
    "SEP": 4.29,
    "OCT": 4.97,
    "NOV": 5.63,
    "DEC": 5.67
  },
  {
    "LONG": -52.58,
    "LAT": -28.5,
    "CIDADE": "Ernestina",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.54,
    "FEB": 5.6,
    "MAR": 5.35,
    "APR": 4.83,
    "MAY": 4.02,
    "JUN": 3.43,
    "JUL": 3.83,
    "AUG": 4.5,
    "SEP": 4.25,
    "OCT": 4.95,
    "NOV": 5.61,
    "DEC": 5.67
  },
  {
    "LONG": -52.47,
    "LAT": -28.53,
    "CIDADE": "Nicolau Vergueiro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.53,
    "FEB": 5.62,
    "MAR": 5.36,
    "APR": 4.86,
    "MAY": 3.98,
    "JUN": 3.43,
    "JUL": 3.82,
    "AUG": 4.51,
    "SEP": 4.25,
    "OCT": 4.95,
    "NOV": 5.61,
    "DEC": 5.68
  },
  {
    "LONG": -52.15,
    "LAT": -28.54,
    "CIDADE": "Vila Maria",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.43,
    "FEB": 5.52,
    "MAR": 5.29,
    "APR": 4.81,
    "MAY": 3.95,
    "JUN": 3.46,
    "JUL": 3.83,
    "AUG": 4.45,
    "SEP": 4.21,
    "OCT": 4.89,
    "NOV": 5.56,
    "DEC": 5.63
  },
  {
    "LONG": -52.03,
    "LAT": -2.85,
    "CIDADE": "Santo Antônio do Palma",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.4,
    "FEB": 5.49,
    "MAR": 5.25,
    "APR": 4.8,
    "MAY": 3.93,
    "JUN": 3.47,
    "JUL": 3.84,
    "AUG": 4.44,
    "SEP": 4.19,
    "OCT": 4.87,
    "NOV": 5.53,
    "DEC": 5.58
  },
  {
    "LONG": -51.85,
    "LAT": -28.48,
    "CIDADE": "Vanini",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.4,
    "FEB": 5.45,
    "MAR": 5.24,
    "APR": 4.79,
    "MAY": 3.88,
    "JUN": 3.45,
    "JUL": 3.85,
    "AUG": 4.43,
    "SEP": 4.18,
    "OCT": 4.82,
    "NOV": 5.5,
    "DEC": 5.55
  },
  {
    "LONG": -51.89,
    "LAT": -28.53,
    "CIDADE": "São Domingos do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.4,
    "FEB": 5.45,
    "MAR": 5.24,
    "APR": 4.79,
    "MAY": 3.88,
    "JUN": 3.45,
    "JUL": 3.85,
    "AUG": 4.43,
    "SEP": 4.18,
    "OCT": 4.82,
    "NOV": 5.5,
    "DEC": 5.55
  },
  {
    "LONG": -51.71,
    "LAT": -28.5,
    "CIDADE": "São Jorge",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.37,
    "FEB": 5.43,
    "MAR": 5.2,
    "APR": 4.78,
    "MAY": 3.88,
    "JUN": 3.48,
    "JUL": 3.85,
    "AUG": 4.45,
    "SEP": 4.18,
    "OCT": 4.83,
    "NOV": 5.52,
    "DEC": 5.54
  },
  {
    "LONG": -51.7,
    "LAT": -28.54,
    "CIDADE": "Guabiju",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.71,
    "JAN": 5.34,
    "FEB": 5.42,
    "MAR": 5.18,
    "APR": 4.78,
    "MAY": 3.89,
    "JUN": 3.51,
    "JUL": 3.86,
    "AUG": 4.44,
    "SEP": 4.17,
    "OCT": 4.83,
    "NOV": 5.53,
    "DEC": 5.52
  },
  {
    "LONG": -50.94,
    "LAT": -28.51,
    "CIDADE": "Vacaria",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.69,
    "JAN": 5.33,
    "FEB": 5.42,
    "MAR": 5.11,
    "APR": 4.68,
    "MAY": 3.88,
    "JUN": 3.48,
    "JUL": 3.85,
    "AUG": 4.55,
    "SEP": 4.22,
    "OCT": 4.81,
    "NOV": 5.51,
    "DEC": 5.45
  },
  {
    "LONG": -54.96,
    "LAT": -2.84,
    "CIDADE": "São Luiz Gonzaga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.95,
    "JAN": 5.77,
    "FEB": 5.86,
    "MAR": 5.63,
    "APR": 4.93,
    "MAY": 4.14,
    "JUN": 3.51,
    "JUL": 3.9,
    "AUG": 4.54,
    "SEP": 4.51,
    "OCT": 5.09,
    "NOV": 5.74,
    "DEC": 5.82
  },
  {
    "LONG": -54.5,
    "LAT": -2.84,
    "CIDADE": "Vitória das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.76,
    "FEB": 5.85,
    "MAR": 5.58,
    "APR": 4.93,
    "MAY": 4.1,
    "JUN": 3.5,
    "JUL": 3.87,
    "AUG": 4.54,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.72,
    "DEC": 5.78
  },
  {
    "LONG": -54.27,
    "LAT": "#VALOR!",
    "CIDADE": "Entre-Ijuís",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.72,
    "FEB": 5.8,
    "MAR": 5.57,
    "APR": 4.92,
    "MAY": 4.1,
    "JUN": 3.51,
    "JUL": 3.85,
    "AUG": 4.49,
    "SEP": 4.43,
    "OCT": 5.07,
    "NOV": 5.67,
    "DEC": 5.76
  },
  {
    "LONG": -54.07,
    "LAT": -2.84,
    "CIDADE": "Coronel Barros",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.72,
    "FEB": 5.78,
    "MAR": 5.57,
    "APR": 4.89,
    "MAY": 4.08,
    "JUN": 3.5,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.41,
    "OCT": 5.04,
    "NOV": 5.66,
    "DEC": 5.73
  },
  {
    "LONG": -53.92,
    "LAT": -28.39,
    "CIDADE": "Ijuí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.7,
    "FEB": 5.76,
    "MAR": 5.56,
    "APR": 4.88,
    "MAY": 4.07,
    "JUN": 3.48,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.39,
    "OCT": 5.04,
    "NOV": 5.66,
    "DEC": 5.72
  },
  {
    "LONG": -53.77,
    "LAT": -28.37,
    "CIDADE": "Bozano",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.67,
    "FEB": 5.75,
    "MAR": 5.52,
    "APR": 4.85,
    "MAY": 4.07,
    "JUN": 3.48,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.38,
    "OCT": 5.01,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -53.66,
    "LAT": -2.84,
    "CIDADE": "Pejuçara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.65,
    "FEB": 5.7,
    "MAR": 5.49,
    "APR": 4.85,
    "MAY": 4.08,
    "JUN": 3.48,
    "JUL": 3.86,
    "AUG": 4.49,
    "SEP": 4.36,
    "OCT": 5.01,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -53.25,
    "LAT": -28.37,
    "CIDADE": "Santa Bárbara do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.62,
    "FEB": 5.67,
    "MAR": 5.39,
    "APR": 4.87,
    "MAY": 4.05,
    "JUN": 3.49,
    "JUL": 3.88,
    "AUG": 4.52,
    "SEP": 4.31,
    "OCT": 5,
    "NOV": 5.59,
    "DEC": 5.68
  },
  {
    "LONG": -53.1,
    "LAT": -28.39,
    "CIDADE": "Saldanha Marinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.58,
    "FEB": 5.66,
    "MAR": 5.4,
    "APR": 4.85,
    "MAY": 4.05,
    "JUN": 3.48,
    "JUL": 3.87,
    "AUG": 4.52,
    "SEP": 4.28,
    "OCT": 4.98,
    "NOV": 5.6,
    "DEC": 5.68
  },
  {
    "LONG": -52.69,
    "LAT": -2.84,
    "CIDADE": "Santo Antônio do Planalto",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.52,
    "FEB": 5.61,
    "MAR": 5.35,
    "APR": 4.85,
    "MAY": 4.03,
    "JUN": 3.47,
    "JUL": 3.88,
    "AUG": 4.52,
    "SEP": 4.27,
    "OCT": 4.95,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -52.2,
    "LAT": -28.45,
    "CIDADE": "Marau",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.77,
    "JAN": 5.45,
    "FEB": 5.5,
    "MAR": 5.27,
    "APR": 4.84,
    "MAY": 3.98,
    "JUN": 3.46,
    "JUL": 3.87,
    "AUG": 4.5,
    "SEP": 4.23,
    "OCT": 4.93,
    "NOV": 5.6,
    "DEC": 5.63
  },
  {
    "LONG": -52.03,
    "LAT": -28.43,
    "CIDADE": "Gentil",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.4,
    "FEB": 5.5,
    "MAR": 5.27,
    "APR": 4.82,
    "MAY": 3.94,
    "JUN": 3.5,
    "JUL": 3.86,
    "AUG": 4.49,
    "SEP": 4.22,
    "OCT": 4.9,
    "NOV": 5.55,
    "DEC": 5.58
  },
  {
    "LONG": -51.85,
    "LAT": -28.39,
    "CIDADE": "David Canabarro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.4,
    "FEB": 5.41,
    "MAR": 5.23,
    "APR": 4.8,
    "MAY": 3.92,
    "JUN": 3.47,
    "JUL": 3.88,
    "AUG": 4.5,
    "SEP": 4.2,
    "OCT": 4.85,
    "NOV": 5.52,
    "DEC": 5.54
  },
  {
    "LONG": -51.64,
    "LAT": -28.37,
    "CIDADE": "Ibiraiaras",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.36,
    "FEB": 5.45,
    "MAR": 5.19,
    "APR": 4.8,
    "MAY": 3.93,
    "JUN": 3.53,
    "JUL": 3.89,
    "AUG": 4.49,
    "SEP": 4.19,
    "OCT": 4.83,
    "NOV": 5.54,
    "DEC": 5.54
  },
  {
    "LONG": -54.82,
    "LAT": -2.83,
    "CIDADE": "Rolador",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.95,
    "JAN": 5.81,
    "FEB": 5.88,
    "MAR": 5.61,
    "APR": 4.95,
    "MAY": 4.13,
    "JUN": 3.52,
    "JUL": 3.89,
    "AUG": 4.53,
    "SEP": 4.5,
    "OCT": 5.09,
    "NOV": 5.73,
    "DEC": 5.8
  },
  {
    "LONG": -54.62,
    "LAT": -28.26,
    "CIDADE": "Mato Queimado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.77,
    "FEB": 5.87,
    "MAR": 5.61,
    "APR": 4.96,
    "MAY": 4.12,
    "JUN": 3.51,
    "JUL": 3.85,
    "AUG": 4.53,
    "SEP": 4.48,
    "OCT": 5.09,
    "NOV": 5.71,
    "DEC": 5.78
  },
  {
    "LONG": -54.64,
    "LAT": -2.83,
    "CIDADE": "Caibaté",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.77,
    "FEB": 5.87,
    "MAR": 5.61,
    "APR": 4.96,
    "MAY": 4.12,
    "JUN": 3.51,
    "JUL": 3.85,
    "AUG": 4.53,
    "SEP": 4.48,
    "OCT": 5.09,
    "NOV": 5.71,
    "DEC": 5.78
  },
  {
    "LONG": -54.27,
    "LAT": -28.3,
    "CIDADE": "Santo Ângelo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.68,
    "FEB": 5.73,
    "MAR": 5.56,
    "APR": 4.88,
    "MAY": 4.1,
    "JUN": 3.51,
    "JUL": 3.86,
    "AUG": 4.49,
    "SEP": 4.42,
    "OCT": 5.08,
    "NOV": 5.65,
    "DEC": 5.74
  },
  {
    "LONG": -54.01,
    "LAT": -28.26,
    "CIDADE": "Catuípe",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.71,
    "FEB": 5.78,
    "MAR": 5.52,
    "APR": 4.87,
    "MAY": 4.07,
    "JUN": 3.51,
    "JUL": 3.84,
    "AUG": 4.51,
    "SEP": 4.41,
    "OCT": 5.04,
    "NOV": 5.64,
    "DEC": 5.7
  },
  {
    "LONG": -53.5,
    "LAT": -28.28,
    "CIDADE": "Panambi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.61,
    "FEB": 5.67,
    "MAR": 5.48,
    "APR": 4.9,
    "MAY": 4.08,
    "JUN": 3.49,
    "JUL": 3.84,
    "AUG": 4.54,
    "SEP": 4.36,
    "OCT": 5.05,
    "NOV": 5.61,
    "DEC": 5.68
  },
  {
    "LONG": -52.79,
    "LAT": -28.3,
    "CIDADE": "Carazinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.47,
    "FEB": 5.62,
    "MAR": 5.36,
    "APR": 4.89,
    "MAY": 4.04,
    "JUN": 3.49,
    "JUL": 3.85,
    "AUG": 4.53,
    "SEP": 4.28,
    "OCT": 4.98,
    "NOV": 5.62,
    "DEC": 5.63
  },
  {
    "LONG": -52.41,
    "LAT": -2.83,
    "CIDADE": "Passo Fundo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.52,
    "FEB": 5.55,
    "MAR": 5.29,
    "APR": 4.86,
    "MAY": 3.99,
    "JUN": 3.49,
    "JUL": 3.88,
    "AUG": 4.53,
    "SEP": 4.25,
    "OCT": 4.98,
    "NOV": 5.61,
    "DEC": 5.65
  },
  {
    "LONG": -52.19,
    "LAT": -28.28,
    "CIDADE": "Mato Castelhano",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.76,
    "JAN": 5.42,
    "FEB": 5.49,
    "MAR": 5.25,
    "APR": 4.83,
    "MAY": 3.97,
    "JUN": 3.47,
    "JUL": 3.88,
    "AUG": 4.53,
    "SEP": 4.25,
    "OCT": 4.91,
    "NOV": 5.55,
    "DEC": 5.59
  },
  {
    "LONG": -51.87,
    "LAT": -28.34,
    "CIDADE": "Ciríaco",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.76,
    "JAN": 5.44,
    "FEB": 5.42,
    "MAR": 5.24,
    "APR": 4.83,
    "MAY": 3.98,
    "JUN": 3.5,
    "JUL": 3.89,
    "AUG": 4.54,
    "SEP": 4.22,
    "OCT": 4.88,
    "NOV": 5.58,
    "DEC": 5.57
  },
  {
    "LONG": -51.77,
    "LAT": -28.33,
    "CIDADE": "Muliterno",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.76,
    "JAN": 5.4,
    "FEB": 5.44,
    "MAR": 5.24,
    "APR": 4.84,
    "MAY": 3.96,
    "JUN": 3.53,
    "JUL": 3.91,
    "AUG": 4.54,
    "SEP": 4.21,
    "OCT": 4.86,
    "NOV": 5.56,
    "DEC": 5.57
  },
  {
    "LONG": -51.69,
    "LAT": -28.26,
    "CIDADE": "Caseiros",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.74,
    "JAN": 5.35,
    "FEB": 5.45,
    "MAR": 5.23,
    "APR": 4.84,
    "MAY": 3.94,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.53,
    "SEP": 4.23,
    "OCT": 4.85,
    "NOV": 5.54,
    "DEC": 5.56
  },
  {
    "LONG": -5.12,
    "LAT": -28.31,
    "CIDADE": "Muitos Capões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.73,
    "JAN": 5.35,
    "FEB": 5.44,
    "MAR": 5.13,
    "APR": 4.73,
    "MAY": 3.89,
    "JUN": 3.52,
    "JUL": 3.92,
    "AUG": 4.58,
    "SEP": 4.29,
    "OCT": 4.85,
    "NOV": 5.52,
    "DEC": 5.52
  },
  {
    "LONG": -55.64,
    "LAT": -28.19,
    "CIDADE": "Garruchos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.98,
    "JAN": 5.88,
    "FEB": 5.87,
    "MAR": 5.66,
    "APR": 4.89,
    "MAY": 4.13,
    "JUN": 3.51,
    "JUL": 3.89,
    "AUG": 4.54,
    "SEP": 4.58,
    "OCT": 5.15,
    "NOV": 5.77,
    "DEC": 5.86
  },
  {
    "LONG": -55.27,
    "LAT": -28.18,
    "CIDADE": "São Nicolau",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.97,
    "JAN": 5.8,
    "FEB": 5.85,
    "MAR": 5.66,
    "APR": 4.93,
    "MAY": 4.13,
    "JUN": 3.53,
    "JUL": 3.91,
    "AUG": 4.55,
    "SEP": 4.54,
    "OCT": 5.12,
    "NOV": 5.75,
    "DEC": 5.82
  },
  {
    "LONG": -55.05,
    "LAT": -28.23,
    "CIDADE": "Dezesseis de Novembro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.95,
    "JAN": 5.75,
    "FEB": 5.81,
    "MAR": 5.67,
    "APR": 4.93,
    "MAY": 4.13,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.53,
    "SEP": 4.51,
    "OCT": 5.11,
    "NOV": 5.76,
    "DEC": 5.82
  },
  {
    "LONG": -53.77,
    "LAT": -2.82,
    "CIDADE": "Ajuricaba",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.64,
    "FEB": 5.73,
    "MAR": 5.47,
    "APR": 4.87,
    "MAY": 4.11,
    "JUN": 3.52,
    "JUL": 3.86,
    "AUG": 4.54,
    "SEP": 4.37,
    "OCT": 5.03,
    "NOV": 5.56,
    "DEC": 5.66
  },
  {
    "LONG": -5.35,
    "LAT": -2.82,
    "CIDADE": "Condor",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.56,
    "FEB": 5.66,
    "MAR": 5.45,
    "APR": 4.87,
    "MAY": 4.07,
    "JUN": 3.53,
    "JUL": 3.87,
    "AUG": 4.52,
    "SEP": 4.34,
    "OCT": 5.04,
    "NOV": 5.56,
    "DEC": 5.65
  },
  {
    "LONG": -52.03,
    "LAT": -28.17,
    "CIDADE": "Água Santa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.48,
    "FEB": 5.48,
    "MAR": 5.28,
    "APR": 4.86,
    "MAY": 3.99,
    "JUN": 3.48,
    "JUL": 3.91,
    "AUG": 4.61,
    "SEP": 4.28,
    "OCT": 4.95,
    "NOV": 5.59,
    "DEC": 5.59
  },
  {
    "LONG": -51.93,
    "LAT": -28.16,
    "CIDADE": "Santa Cecília do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.78,
    "JAN": 5.46,
    "FEB": 5.47,
    "MAR": 5.27,
    "APR": 4.84,
    "MAY": 3.96,
    "JUN": 3.48,
    "JUL": 3.93,
    "AUG": 4.61,
    "SEP": 4.26,
    "OCT": 4.91,
    "NOV": 5.58,
    "DEC": 5.58
  },
  {
    "LONG": -51.53,
    "LAT": -28.21,
    "CIDADE": "Lagoa Vermelha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.76,
    "JAN": 5.38,
    "FEB": 5.47,
    "MAR": 5.24,
    "APR": 4.8,
    "MAY": 3.96,
    "JUN": 3.49,
    "JUL": 3.94,
    "AUG": 4.58,
    "SEP": 4.24,
    "OCT": 4.86,
    "NOV": 5.57,
    "DEC": 5.55
  },
  {
    "LONG": -55.03,
    "LAT": -28.13,
    "CIDADE": "Roque Gonzales",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.95,
    "JAN": 5.8,
    "FEB": 5.85,
    "MAR": 5.6,
    "APR": 4.94,
    "MAY": 4.1,
    "JUN": 3.52,
    "JUL": 3.87,
    "AUG": 4.57,
    "SEP": 4.5,
    "OCT": 5.07,
    "NOV": 5.73,
    "DEC": 5.8
  },
  {
    "LONG": -54.84,
    "LAT": -28.12,
    "CIDADE": "Salvador das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.81,
    "FEB": 5.83,
    "MAR": 5.59,
    "APR": 4.95,
    "MAY": 4.12,
    "JUN": 3.51,
    "JUL": 3.89,
    "AUG": 4.54,
    "SEP": 4.47,
    "OCT": 5.07,
    "NOV": 5.72,
    "DEC": 5.78
  },
  {
    "LONG": -54.89,
    "LAT": -2.81,
    "CIDADE": "São Pedro do Butiá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.81,
    "FEB": 5.83,
    "MAR": 5.59,
    "APR": 4.95,
    "MAY": 4.12,
    "JUN": 3.51,
    "JUL": 3.89,
    "AUG": 4.54,
    "SEP": 4.47,
    "OCT": 5.07,
    "NOV": 5.72,
    "DEC": 5.78
  },
  {
    "LONG": -54.74,
    "LAT": -28.15,
    "CIDADE": "Cerro Largo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.75,
    "FEB": 5.81,
    "MAR": 5.57,
    "APR": 4.96,
    "MAY": 4.13,
    "JUN": 3.51,
    "JUL": 3.9,
    "AUG": 4.54,
    "SEP": 4.47,
    "OCT": 5.07,
    "NOV": 5.71,
    "DEC": 5.76
  },
  {
    "LONG": -54.56,
    "LAT": -2.81,
    "CIDADE": "Guarani das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.74,
    "FEB": 5.83,
    "MAR": 5.53,
    "APR": 4.93,
    "MAY": 4.12,
    "JUN": 3.52,
    "JUL": 3.89,
    "AUG": 4.57,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.67,
    "DEC": 5.75
  },
  {
    "LONG": -54.46,
    "LAT": -2.81,
    "CIDADE": "Sete de Setembro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.74,
    "FEB": 5.8,
    "MAR": 5.52,
    "APR": 4.93,
    "MAY": 4.13,
    "JUN": 3.54,
    "JUL": 3.92,
    "AUG": 4.58,
    "SEP": 4.46,
    "OCT": 5.08,
    "NOV": 5.67,
    "DEC": 5.74
  },
  {
    "LONG": -53.7,
    "LAT": -28.07,
    "CIDADE": "Nova Ramada",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.61,
    "FEB": 5.75,
    "MAR": 5.45,
    "APR": 4.89,
    "MAY": 4.11,
    "JUN": 3.6,
    "JUL": 3.89,
    "AUG": 4.57,
    "SEP": 4.38,
    "OCT": 5.04,
    "NOV": 5.55,
    "DEC": 5.67
  },
  {
    "LONG": -5.31,
    "LAT": -28.06,
    "CIDADE": "Chapada",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.55,
    "FEB": 5.65,
    "MAR": 5.4,
    "APR": 4.9,
    "MAY": 4.07,
    "JUN": 3.51,
    "JUL": 3.91,
    "AUG": 4.6,
    "SEP": 4.33,
    "OCT": 5.04,
    "NOV": 5.64,
    "DEC": 5.66
  },
  {
    "LONG": -52.91,
    "LAT": -28.12,
    "CIDADE": "Almirante Tamandaré do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.37,
    "APR": 4.94,
    "MAY": 4.08,
    "JUN": 3.54,
    "JUL": 3.92,
    "AUG": 4.59,
    "SEP": 4.3,
    "OCT": 5.03,
    "NOV": 5.64,
    "DEC": 5.69
  },
  {
    "LONG": -52.78,
    "LAT": -28.12,
    "CIDADE": "Coqueiros do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.59,
    "FEB": 5.59,
    "MAR": 5.36,
    "APR": 4.89,
    "MAY": 4.05,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.57,
    "SEP": 4.29,
    "OCT": 5.01,
    "NOV": 5.6,
    "DEC": 5.65
  },
  {
    "LONG": -52.68,
    "LAT": -2.81,
    "CIDADE": "Pontão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.57,
    "FEB": 5.58,
    "MAR": 5.3,
    "APR": 4.92,
    "MAY": 4.05,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.57,
    "SEP": 4.29,
    "OCT": 5,
    "NOV": 5.58,
    "DEC": 5.63
  },
  {
    "LONG": -52.3,
    "LAT": -28.13,
    "CIDADE": "Coxilha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.5,
    "FEB": 5.57,
    "MAR": 5.34,
    "APR": 4.88,
    "MAY": 4.02,
    "JUN": 3.48,
    "JUL": 3.95,
    "AUG": 4.59,
    "SEP": 4.29,
    "OCT": 4.97,
    "NOV": 5.59,
    "DEC": 5.58
  },
  {
    "LONG": -52.14,
    "LAT": -28.11,
    "CIDADE": "Vila Lângaro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.48,
    "FEB": 5.57,
    "MAR": 5.27,
    "APR": 4.87,
    "MAY": 4.01,
    "JUN": 3.47,
    "JUL": 3.89,
    "AUG": 4.59,
    "SEP": 4.3,
    "OCT": 4.95,
    "NOV": 5.56,
    "DEC": 5.59
  },
  {
    "LONG": -52.01,
    "LAT": -28.07,
    "CIDADE": "Tapejara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.48,
    "FEB": 5.55,
    "MAR": 5.31,
    "APR": 4.87,
    "MAY": 3.99,
    "JUN": 3.5,
    "JUL": 3.91,
    "AUG": 4.6,
    "SEP": 4.3,
    "OCT": 4.94,
    "NOV": 5.58,
    "DEC": 5.6
  },
  {
    "LONG": -51.86,
    "LAT": -2.81,
    "CIDADE": "Ibiaçá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.79,
    "JAN": 5.47,
    "FEB": 5.48,
    "MAR": 5.32,
    "APR": 4.86,
    "MAY": 3.97,
    "JUN": 3.48,
    "JUL": 3.92,
    "AUG": 4.59,
    "SEP": 4.27,
    "OCT": 4.93,
    "NOV": 5.61,
    "DEC": 5.62
  },
  {
    "LONG": -51.4,
    "LAT": -28.13,
    "CIDADE": "Capão Bonito do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.78,
    "JAN": 5.46,
    "FEB": 5.54,
    "MAR": 5.25,
    "APR": 4.75,
    "MAY": 3.94,
    "JUN": 3.59,
    "JUL": 3.93,
    "AUG": 4.6,
    "SEP": 4.3,
    "OCT": 4.87,
    "NOV": 5.55,
    "DEC": 5.53
  },
  {
    "LONG": -51.19,
    "LAT": -28.05,
    "CIDADE": "Esmeralda",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.46,
    "FEB": 5.45,
    "MAR": 5.21,
    "APR": 4.74,
    "MAY": 3.92,
    "JUN": 3.55,
    "JUL": 3.94,
    "AUG": 4.59,
    "SEP": 4.28,
    "OCT": 4.85,
    "NOV": 5.54,
    "DEC": 5.5
  },
  {
    "LONG": -55.2,
    "LAT": -28.04,
    "CIDADE": "Pirapó",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.8,
    "FEB": 5.83,
    "MAR": 5.62,
    "APR": 4.91,
    "MAY": 4.12,
    "JUN": 3.46,
    "JUL": 3.84,
    "AUG": 4.52,
    "SEP": 4.51,
    "OCT": 5.11,
    "NOV": 5.73,
    "DEC": 5.78
  },
  {
    "LONG": -54.94,
    "LAT": -2.8,
    "CIDADE": "São Paulo das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.94,
    "JAN": 5.77,
    "FEB": 5.81,
    "MAR": 5.59,
    "APR": 4.93,
    "MAY": 4.11,
    "JUN": 3.51,
    "JUL": 3.89,
    "AUG": 4.56,
    "SEP": 4.48,
    "OCT": 5.08,
    "NOV": 5.72,
    "DEC": 5.79
  },
  {
    "LONG": -54.84,
    "LAT": -27.99,
    "CIDADE": "Campina das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.76,
    "FEB": 5.78,
    "MAR": 5.56,
    "APR": 4.93,
    "MAY": 4.12,
    "JUN": 3.5,
    "JUL": 3.9,
    "AUG": 4.56,
    "SEP": 4.47,
    "OCT": 5.08,
    "NOV": 5.69,
    "DEC": 5.77
  },
  {
    "LONG": -54.75,
    "LAT": -2.8,
    "CIDADE": "Cândido Godói",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.72,
    "FEB": 5.77,
    "MAR": 5.55,
    "APR": 4.94,
    "MAY": 4.14,
    "JUN": 3.52,
    "JUL": 3.91,
    "AUG": 4.55,
    "SEP": 4.47,
    "OCT": 5.08,
    "NOV": 5.68,
    "DEC": 5.76
  },
  {
    "LONG": -54.68,
    "LAT": -2.8,
    "CIDADE": "Ubiretama",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.71,
    "FEB": 5.78,
    "MAR": 5.54,
    "APR": 4.95,
    "MAY": 4.13,
    "JUN": 3.52,
    "JUL": 3.89,
    "AUG": 4.56,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.67,
    "DEC": 5.76
  },
  {
    "LONG": -54.55,
    "LAT": -28.03,
    "CIDADE": "Senador Salgado Filho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.72,
    "FEB": 5.8,
    "MAR": 5.53,
    "APR": 4.95,
    "MAY": 4.14,
    "JUN": 3.52,
    "JUL": 3.9,
    "AUG": 4.58,
    "SEP": 4.48,
    "OCT": 5.09,
    "NOV": 5.67,
    "DEC": 5.75
  },
  {
    "LONG": -54.35,
    "LAT": -28.03,
    "CIDADE": "Giruá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.93,
    "JAN": 5.73,
    "FEB": 5.8,
    "MAR": 5.52,
    "APR": 4.94,
    "MAY": 4.14,
    "JUN": 3.56,
    "JUL": 3.91,
    "AUG": 4.6,
    "SEP": 4.46,
    "OCT": 5.1,
    "NOV": 5.66,
    "DEC": 5.74
  },
  {
    "LONG": -52.98,
    "LAT": -27.99,
    "CIDADE": "Nova Boa Vista",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.55,
    "FEB": 5.66,
    "MAR": 5.4,
    "APR": 4.97,
    "MAY": 4.07,
    "JUN": 3.57,
    "JUL": 3.93,
    "AUG": 4.59,
    "SEP": 4.32,
    "OCT": 5.07,
    "NOV": 5.64,
    "DEC": 5.71
  },
  {
    "LONG": -52.26,
    "LAT": -27.98,
    "CIDADE": "Sertão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.5,
    "FEB": 5.56,
    "MAR": 5.3,
    "APR": 4.86,
    "MAY": 4.02,
    "JUN": 3.49,
    "JUL": 3.95,
    "AUG": 4.61,
    "SEP": 4.33,
    "OCT": 4.98,
    "NOV": 5.56,
    "DEC": 5.59
  },
  {
    "LONG": -55.14,
    "LAT": -27.91,
    "CIDADE": "Porto Xavier",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.79,
    "FEB": 5.78,
    "MAR": 5.59,
    "APR": 4.89,
    "MAY": 4.1,
    "JUN": 3.46,
    "JUL": 3.83,
    "AUG": 4.51,
    "SEP": 4.47,
    "OCT": 5.06,
    "NOV": 5.68,
    "DEC": 5.76
  },
  {
    "LONG": -55.01,
    "LAT": -27.86,
    "CIDADE": "Porto Lucena",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.74,
    "FEB": 5.78,
    "MAR": 5.57,
    "APR": 4.89,
    "MAY": 4.11,
    "JUN": 3.48,
    "JUL": 3.85,
    "AUG": 4.5,
    "SEP": 4.48,
    "OCT": 5.05,
    "NOV": 5.69,
    "DEC": 5.76
  },
  {
    "LONG": -54.48,
    "LAT": -27.87,
    "CIDADE": "Santa Rosa",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.7,
    "FEB": 5.77,
    "MAR": 5.53,
    "APR": 4.95,
    "MAY": 4.12,
    "JUN": 3.52,
    "JUL": 3.88,
    "AUG": 4.57,
    "SEP": 4.46,
    "OCT": 5.09,
    "NOV": 5.66,
    "DEC": 5.75
  },
  {
    "LONG": -54.02,
    "LAT": -27.88,
    "CIDADE": "Inhacorá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.7,
    "FEB": 5.75,
    "MAR": 5.53,
    "APR": 4.94,
    "MAY": 4.11,
    "JUN": 3.55,
    "JUL": 3.91,
    "AUG": 4.62,
    "SEP": 4.42,
    "OCT": 5.1,
    "NOV": 5.63,
    "DEC": 5.76
  },
  {
    "LONG": -53.94,
    "LAT": -27.92,
    "CIDADE": "Chiapetta",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.67,
    "FEB": 5.74,
    "MAR": 5.47,
    "APR": 4.92,
    "MAY": 4.11,
    "JUN": 3.56,
    "JUL": 3.93,
    "AUG": 4.63,
    "SEP": 4.41,
    "OCT": 5.07,
    "NOV": 5.59,
    "DEC": 5.74
  },
  {
    "LONG": -53.78,
    "LAT": -2.79,
    "CIDADE": "Santo Augusto",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.62,
    "FEB": 5.72,
    "MAR": 5.43,
    "APR": 4.9,
    "MAY": 4.13,
    "JUN": 3.57,
    "JUL": 3.96,
    "AUG": 4.63,
    "SEP": 4.41,
    "OCT": 5.07,
    "NOV": 5.58,
    "DEC": 5.72
  },
  {
    "LONG": -53.31,
    "LAT": -27.9,
    "CIDADE": "Palmeira das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.64,
    "FEB": 5.7,
    "MAR": 5.35,
    "APR": 4.88,
    "MAY": 4.12,
    "JUN": 3.59,
    "JUL": 3.97,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.06,
    "NOV": 5.59,
    "DEC": 5.7
  },
  {
    "LONG": -53.11,
    "LAT": -27.91,
    "CIDADE": "Novo Barreiro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.61,
    "FEB": 5.67,
    "MAR": 5.4,
    "APR": 4.94,
    "MAY": 4.1,
    "JUN": 3.55,
    "JUL": 3.95,
    "AUG": 4.59,
    "SEP": 4.39,
    "OCT": 5.06,
    "NOV": 5.63,
    "DEC": 5.7
  },
  {
    "LONG": -53.04,
    "LAT": -27.92,
    "CIDADE": "Barra Funda",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.63,
    "FEB": 5.7,
    "MAR": 5.45,
    "APR": 4.96,
    "MAY": 4.08,
    "JUN": 3.53,
    "JUL": 3.95,
    "AUG": 4.59,
    "SEP": 4.35,
    "OCT": 5.07,
    "NOV": 5.66,
    "DEC": 5.73
  },
  {
    "LONG": -52.92,
    "LAT": -27.94,
    "CIDADE": "Sarandi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.6,
    "FEB": 5.7,
    "MAR": 5.4,
    "APR": 4.96,
    "MAY": 4.09,
    "JUN": 3.56,
    "JUL": 3.96,
    "AUG": 4.61,
    "SEP": 4.33,
    "OCT": 5.06,
    "NOV": 5.62,
    "DEC": 5.71
  },
  {
    "LONG": -52.43,
    "LAT": -27.94,
    "CIDADE": "Ipiranga do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.53,
    "FEB": 5.58,
    "MAR": 5.3,
    "APR": 4.9,
    "MAY": 4.07,
    "JUN": 3.57,
    "JUL": 3.99,
    "AUG": 4.64,
    "SEP": 4.32,
    "OCT": 4.98,
    "NOV": 5.53,
    "DEC": 5.62
  },
  {
    "LONG": -52.3,
    "LAT": -27.85,
    "CIDADE": "Erebango",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.54,
    "FEB": 5.56,
    "MAR": 5.28,
    "APR": 4.87,
    "MAY": 4.04,
    "JUN": 3.57,
    "JUL": 3.97,
    "AUG": 4.63,
    "SEP": 4.34,
    "OCT": 4.97,
    "NOV": 5.55,
    "DEC": 5.61
  },
  {
    "LONG": -52.23,
    "LAT": -27.89,
    "CIDADE": "Getúlio Vargas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.52,
    "FEB": 5.54,
    "MAR": 5.28,
    "APR": 4.85,
    "MAY": 4.01,
    "JUN": 3.53,
    "JUL": 3.96,
    "AUG": 4.62,
    "SEP": 4.33,
    "OCT": 4.98,
    "NOV": 5.56,
    "DEC": 5.59
  },
  {
    "LONG": -5.23,
    "LAT": -27.91,
    "CIDADE": "Estação",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.52,
    "FEB": 5.54,
    "MAR": 5.28,
    "APR": 4.85,
    "MAY": 4.01,
    "JUN": 3.53,
    "JUL": 3.96,
    "AUG": 4.62,
    "SEP": 4.33,
    "OCT": 4.98,
    "NOV": 5.56,
    "DEC": 5.59
  },
  {
    "LONG": -52.02,
    "LAT": -27.95,
    "CIDADE": "Charrua",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.48,
    "FEB": 5.51,
    "MAR": 5.33,
    "APR": 4.88,
    "MAY": 4.01,
    "JUN": 3.48,
    "JUL": 3.9,
    "AUG": 4.59,
    "SEP": 4.31,
    "OCT": 4.95,
    "NOV": 5.58,
    "DEC": 5.59
  },
  {
    "LONG": -52.08,
    "LAT": -27.86,
    "CIDADE": "Floriano Peixoto",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.48,
    "FEB": 5.51,
    "MAR": 5.33,
    "APR": 4.88,
    "MAY": 4.01,
    "JUN": 3.48,
    "JUL": 3.9,
    "AUG": 4.59,
    "SEP": 4.31,
    "OCT": 4.95,
    "NOV": 5.58,
    "DEC": 5.59
  },
  {
    "LONG": -51.81,
    "LAT": -27.95,
    "CIDADE": "Sananduva",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.51,
    "FEB": 5.53,
    "MAR": 5.35,
    "APR": 4.92,
    "MAY": 3.99,
    "JUN": 3.54,
    "JUL": 3.93,
    "AUG": 4.6,
    "SEP": 4.3,
    "OCT": 4.95,
    "NOV": 5.64,
    "DEC": 5.62
  },
  {
    "LONG": -51.64,
    "LAT": -27.91,
    "CIDADE": "Santo Expedito do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.55,
    "FEB": 5.52,
    "MAR": 5.31,
    "APR": 4.87,
    "MAY": 3.98,
    "JUN": 3.6,
    "JUL": 3.97,
    "AUG": 4.65,
    "SEP": 4.32,
    "OCT": 4.93,
    "NOV": 5.62,
    "DEC": 5.61
  },
  {
    "LONG": -51.54,
    "LAT": -27.92,
    "CIDADE": "Tupanci do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.53,
    "FEB": 5.5,
    "MAR": 5.3,
    "APR": 4.85,
    "MAY": 3.98,
    "JUN": 3.6,
    "JUL": 3.96,
    "AUG": 4.65,
    "SEP": 4.32,
    "OCT": 4.91,
    "NOV": 5.6,
    "DEC": 5.58
  },
  {
    "LONG": -51.17,
    "LAT": -27.88,
    "CIDADE": "Pinhal da Serra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.75,
    "JAN": 5.41,
    "FEB": 5.47,
    "MAR": 5.24,
    "APR": 4.78,
    "MAY": 3.9,
    "JUN": 3.56,
    "JUL": 3.9,
    "AUG": 4.58,
    "SEP": 4.28,
    "OCT": 4.83,
    "NOV": 5.55,
    "DEC": 5.53
  },
  {
    "LONG": -54.66,
    "LAT": -27.83,
    "CIDADE": "Santo Cristo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.7,
    "FEB": 5.73,
    "MAR": 5.53,
    "APR": 4.94,
    "MAY": 4.09,
    "JUN": 3.46,
    "JUL": 3.83,
    "AUG": 4.58,
    "SEP": 4.46,
    "OCT": 5.09,
    "NOV": 5.63,
    "DEC": 5.76
  },
  {
    "LONG": -54.48,
    "LAT": -27.76,
    "CIDADE": "Tuparendi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.71,
    "FEB": 5.76,
    "MAR": 5.52,
    "APR": 4.94,
    "MAY": 4.1,
    "JUN": 3.52,
    "JUL": 3.86,
    "AUG": 4.59,
    "SEP": 4.46,
    "OCT": 5.11,
    "NOV": 5.64,
    "DEC": 5.74
  },
  {
    "LONG": -54.24,
    "LAT": -27.78,
    "CIDADE": "Três de Maio",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.73,
    "FEB": 5.78,
    "MAR": 5.48,
    "APR": 4.94,
    "MAY": 4.12,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.61,
    "SEP": 4.44,
    "OCT": 5.11,
    "NOV": 5.6,
    "DEC": 5.74
  },
  {
    "LONG": -54.19,
    "LAT": -27.84,
    "CIDADE": "Independência",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.74,
    "FEB": 5.78,
    "MAR": 5.51,
    "APR": 4.94,
    "MAY": 4.12,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.6,
    "SEP": 4.42,
    "OCT": 5.14,
    "NOV": 5.65,
    "DEC": 5.75
  },
  {
    "LONG": -54.06,
    "LAT": -27.83,
    "CIDADE": "Alegria",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.7,
    "FEB": 5.73,
    "MAR": 5.5,
    "APR": 4.94,
    "MAY": 4.11,
    "JUN": 3.57,
    "JUL": 3.92,
    "AUG": 4.63,
    "SEP": 4.41,
    "OCT": 5.12,
    "NOV": 5.63,
    "DEC": 5.76
  },
  {
    "LONG": -53.94,
    "LAT": -27.79,
    "CIDADE": "São Valério do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.67,
    "FEB": 5.73,
    "MAR": 5.44,
    "APR": 4.91,
    "MAY": 4.1,
    "JUN": 3.58,
    "JUL": 3.93,
    "AUG": 4.64,
    "SEP": 4.4,
    "OCT": 5.08,
    "NOV": 5.59,
    "DEC": 5.73
  },
  {
    "LONG": -53.25,
    "LAT": -2.78,
    "CIDADE": "São Pedro das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.62,
    "FEB": 5.63,
    "MAR": 5.37,
    "APR": 4.95,
    "MAY": 4.11,
    "JUN": 3.57,
    "JUL": 3.97,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.06,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -53.12,
    "LAT": -27.78,
    "CIDADE": "São José das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.62,
    "FEB": 5.66,
    "MAR": 5.41,
    "APR": 4.97,
    "MAY": 4.1,
    "JUN": 3.55,
    "JUL": 3.96,
    "AUG": 4.6,
    "SEP": 4.38,
    "OCT": 5.07,
    "NOV": 5.64,
    "DEC": 5.73
  },
  {
    "LONG": -52.91,
    "LAT": -27.83,
    "CIDADE": "Rondinha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.59,
    "FEB": 5.68,
    "MAR": 5.42,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.56,
    "JUL": 3.99,
    "AUG": 4.62,
    "SEP": 4.35,
    "OCT": 5.07,
    "NOV": 5.61,
    "DEC": 5.72
  },
  {
    "LONG": -5.28,
    "LAT": -27.78,
    "CIDADE": "Ronda Alta",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.57,
    "FEB": 5.65,
    "MAR": 5.4,
    "APR": 4.94,
    "MAY": 4.09,
    "JUN": 3.56,
    "JUL": 3.98,
    "AUG": 4.62,
    "SEP": 4.34,
    "OCT": 5.06,
    "NOV": 5.58,
    "DEC": 5.69
  },
  {
    "LONG": -52.44,
    "LAT": -27.83,
    "CIDADE": "Quatro Irmãos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.51,
    "FEB": 5.56,
    "MAR": 5.33,
    "APR": 4.9,
    "MAY": 4.06,
    "JUN": 3.58,
    "JUL": 3.97,
    "AUG": 4.67,
    "SEP": 4.34,
    "OCT": 5.01,
    "NOV": 5.54,
    "DEC": 5.62
  },
  {
    "LONG": -52,
    "LAT": -2.78,
    "CIDADE": "Centenário",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.81,
    "JAN": 5.47,
    "FEB": 5.49,
    "MAR": 5.34,
    "APR": 4.9,
    "MAY": 4.01,
    "JUN": 3.48,
    "JUL": 3.92,
    "AUG": 4.61,
    "SEP": 4.33,
    "OCT": 4.98,
    "NOV": 5.6,
    "DEC": 5.6
  },
  {
    "LONG": -51.83,
    "LAT": "#VALOR!",
    "CIDADE": "São João da Urtiga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.52,
    "FEB": 5.52,
    "MAR": 5.36,
    "APR": 4.92,
    "MAY": 3.98,
    "JUN": 3.54,
    "JUL": 3.92,
    "AUG": 4.62,
    "SEP": 4.32,
    "OCT": 4.97,
    "NOV": 5.64,
    "DEC": 5.62
  },
  {
    "LONG": -51.66,
    "LAT": -27.77,
    "CIDADE": "Cacique Doble",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.55,
    "FEB": 5.52,
    "MAR": 5.32,
    "APR": 4.89,
    "MAY": 4,
    "JUN": 3.62,
    "JUL": 3.98,
    "AUG": 4.67,
    "SEP": 4.33,
    "OCT": 4.97,
    "NOV": 5.62,
    "DEC": 5.62
  },
  {
    "LONG": -51.6,
    "LAT": -27.77,
    "CIDADE": "São José do Ouro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.55,
    "FEB": 5.52,
    "MAR": 5.29,
    "APR": 4.84,
    "MAY": 3.97,
    "JUN": 3.58,
    "JUL": 3.97,
    "AUG": 4.67,
    "SEP": 4.33,
    "OCT": 4.93,
    "NOV": 5.58,
    "DEC": 5.58
  },
  {
    "LONG": -54.9,
    "LAT": -2.77,
    "CIDADE": "Porto Vera Cruz",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.69,
    "FEB": 5.68,
    "MAR": 5.54,
    "APR": 4.91,
    "MAY": 4.09,
    "JUN": 3.45,
    "JUL": 3.81,
    "AUG": 4.55,
    "SEP": 4.44,
    "OCT": 5.07,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -54.77,
    "LAT": -27.66,
    "CIDADE": "Alecrim",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.71,
    "FEB": 5.71,
    "MAR": 5.52,
    "APR": 4.91,
    "MAY": 4.07,
    "JUN": 3.46,
    "JUL": 3.84,
    "AUG": 4.56,
    "SEP": 4.45,
    "OCT": 5.11,
    "NOV": 5.61,
    "DEC": 5.76
  },
  {
    "LONG": -54.44,
    "LAT": -27.66,
    "CIDADE": "Tucunduva",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.73,
    "FEB": 5.72,
    "MAR": 5.5,
    "APR": 4.91,
    "MAY": 4.08,
    "JUN": 3.49,
    "JUL": 3.87,
    "AUG": 4.57,
    "SEP": 4.45,
    "OCT": 5.12,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -54.11,
    "LAT": -27.67,
    "CIDADE": "Boa Vista do Buricá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.76,
    "FEB": 5.73,
    "MAR": 5.52,
    "APR": 4.94,
    "MAY": 4.13,
    "JUN": 3.52,
    "JUL": 3.89,
    "AUG": 4.64,
    "SEP": 4.41,
    "OCT": 5.15,
    "NOV": 5.64,
    "DEC": 5.75
  },
  {
    "LONG": -54.13,
    "LAT": -27.73,
    "CIDADE": "São José do Inhacorá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.92,
    "JAN": 5.76,
    "FEB": 5.73,
    "MAR": 5.52,
    "APR": 4.94,
    "MAY": 4.13,
    "JUN": 3.52,
    "JUL": 3.89,
    "AUG": 4.64,
    "SEP": 4.41,
    "OCT": 5.15,
    "NOV": 5.64,
    "DEC": 5.75
  },
  {
    "LONG": -53.97,
    "LAT": -27.71,
    "CIDADE": "São Martinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.69,
    "FEB": 5.71,
    "MAR": 5.47,
    "APR": 4.92,
    "MAY": 4.12,
    "JUN": 3.58,
    "JUL": 3.94,
    "AUG": 4.62,
    "SEP": 4.39,
    "OCT": 5.11,
    "NOV": 5.58,
    "DEC": 5.72
  },
  {
    "LONG": -53.81,
    "LAT": -27.68,
    "CIDADE": "Campo Novo",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.69,
    "FEB": 5.72,
    "MAR": 5.45,
    "APR": 4.9,
    "MAY": 4.12,
    "JUN": 3.57,
    "JUL": 3.97,
    "AUG": 4.65,
    "SEP": 4.4,
    "OCT": 5.09,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -53.7,
    "LAT": -27.72,
    "CIDADE": "Coronel Bicaco",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.69,
    "FEB": 5.7,
    "MAR": 5.45,
    "APR": 4.9,
    "MAY": 4.13,
    "JUN": 3.56,
    "JUL": 3.96,
    "AUG": 4.64,
    "SEP": 4.41,
    "OCT": 5.1,
    "NOV": 5.6,
    "DEC": 5.69
  },
  {
    "LONG": -53.64,
    "LAT": -27.66,
    "CIDADE": "Redentora",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.66,
    "FEB": 5.68,
    "MAR": 5.41,
    "APR": 4.89,
    "MAY": 4.11,
    "JUN": 3.58,
    "JUL": 3.97,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.08,
    "NOV": 5.58,
    "DEC": 5.71
  },
  {
    "LONG": -53.53,
    "LAT": -27.66,
    "CIDADE": "Dois Irmãos das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.67,
    "FEB": 5.66,
    "MAR": 5.41,
    "APR": 4.92,
    "MAY": 4.12,
    "JUN": 3.56,
    "JUL": 3.99,
    "AUG": 4.65,
    "SEP": 4.4,
    "OCT": 5.1,
    "NOV": 5.58,
    "DEC": 5.7
  },
  {
    "LONG": -53.31,
    "LAT": -27.67,
    "CIDADE": "Boa Vista das Missões",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.62,
    "FEB": 5.64,
    "MAR": 5.39,
    "APR": 4.92,
    "MAY": 4.12,
    "JUN": 3.57,
    "JUL": 4,
    "AUG": 4.65,
    "SEP": 4.4,
    "OCT": 5.07,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -53.14,
    "LAT": -2.77,
    "CIDADE": "Sagrada Família",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.62,
    "FEB": 5.63,
    "MAR": 5.44,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.53,
    "JUL": 3.96,
    "AUG": 4.63,
    "SEP": 4.39,
    "OCT": 5.08,
    "NOV": 5.66,
    "DEC": 5.77
  },
  {
    "LONG": -53.18,
    "LAT": -27.69,
    "CIDADE": "Lajeado do Bugre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.62,
    "FEB": 5.63,
    "MAR": 5.44,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.53,
    "JUL": 3.96,
    "AUG": 4.63,
    "SEP": 4.39,
    "OCT": 5.08,
    "NOV": 5.66,
    "DEC": 5.77
  },
  {
    "LONG": -53.06,
    "LAT": -27.75,
    "CIDADE": "Novo Xingu",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.59,
    "FEB": 5.67,
    "MAR": 5.49,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.54,
    "JUL": 3.93,
    "AUG": 4.6,
    "SEP": 4.37,
    "OCT": 5.09,
    "NOV": 5.65,
    "DEC": 5.76
  },
  {
    "LONG": -5.29,
    "LAT": -27.71,
    "CIDADE": "Engenho Velho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.53,
    "FEB": 5.64,
    "MAR": 5.43,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.57,
    "JUL": 3.99,
    "AUG": 4.64,
    "SEP": 4.37,
    "OCT": 5.07,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -52.99,
    "LAT": -27.73,
    "CIDADE": "Constantina",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.53,
    "FEB": 5.64,
    "MAR": 5.43,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.57,
    "JUL": 3.99,
    "AUG": 4.64,
    "SEP": 4.37,
    "OCT": 5.07,
    "NOV": 5.61,
    "DEC": 5.7
  },
  {
    "LONG": -52.65,
    "LAT": -27.67,
    "CIDADE": "Cruzaltense",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.61,
    "FEB": 5.67,
    "MAR": 5.38,
    "APR": 4.93,
    "MAY": 4.07,
    "JUN": 3.58,
    "JUL": 3.96,
    "AUG": 4.69,
    "SEP": 4.37,
    "OCT": 5.05,
    "NOV": 5.56,
    "DEC": 5.67
  },
  {
    "LONG": -52.63,
    "LAT": -27.72,
    "CIDADE": "Campinas do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.61,
    "FEB": 5.67,
    "MAR": 5.38,
    "APR": 4.93,
    "MAY": 4.07,
    "JUN": 3.58,
    "JUL": 3.96,
    "AUG": 4.69,
    "SEP": 4.37,
    "OCT": 5.05,
    "NOV": 5.56,
    "DEC": 5.67
  },
  {
    "LONG": -52.54,
    "LAT": -27.73,
    "CIDADE": "Jacutinga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.53,
    "FEB": 5.59,
    "MAR": 5.37,
    "APR": 4.92,
    "MAY": 4.06,
    "JUN": 3.58,
    "JUL": 3.97,
    "AUG": 4.68,
    "SEP": 4.35,
    "OCT": 5.02,
    "NOV": 5.55,
    "DEC": 5.64
  },
  {
    "LONG": -52.42,
    "LAT": -27.71,
    "CIDADE": "Paulo Bento",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.48,
    "FEB": 5.52,
    "MAR": 5.35,
    "APR": 4.89,
    "MAY": 4.02,
    "JUN": 3.57,
    "JUL": 3.99,
    "AUG": 4.69,
    "SEP": 4.34,
    "OCT": 5.01,
    "NOV": 5.54,
    "DEC": 5.61
  },
  {
    "LONG": -52.49,
    "LAT": -27.66,
    "CIDADE": "Ponte Preta",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.48,
    "FEB": 5.52,
    "MAR": 5.35,
    "APR": 4.89,
    "MAY": 4.02,
    "JUN": 3.57,
    "JUL": 3.99,
    "AUG": 4.69,
    "SEP": 4.34,
    "OCT": 5.01,
    "NOV": 5.54,
    "DEC": 5.61
  },
  {
    "LONG": -5.21,
    "LAT": -27.69,
    "CIDADE": "Áurea",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.82,
    "JAN": 5.49,
    "FEB": 5.53,
    "MAR": 5.34,
    "APR": 4.86,
    "MAY": 4.02,
    "JUN": 3.5,
    "JUL": 3.93,
    "AUG": 4.64,
    "SEP": 4.36,
    "OCT": 4.97,
    "NOV": 5.58,
    "DEC": 5.58
  },
  {
    "LONG": -51.91,
    "LAT": -27.72,
    "CIDADE": "Carlos Gomes",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.5,
    "FEB": 5.54,
    "MAR": 5.38,
    "APR": 4.87,
    "MAY": 4.01,
    "JUN": 3.47,
    "JUL": 3.91,
    "AUG": 4.63,
    "SEP": 4.35,
    "OCT": 5,
    "NOV": 5.63,
    "DEC": 5.63
  },
  {
    "LONG": -51.76,
    "LAT": -2.77,
    "CIDADE": "Paim Filho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.53,
    "FEB": 5.52,
    "MAR": 5.36,
    "APR": 4.92,
    "MAY": 4.02,
    "JUN": 3.6,
    "JUL": 3.94,
    "AUG": 4.65,
    "SEP": 4.34,
    "OCT": 4.97,
    "NOV": 5.64,
    "DEC": 5.63
  },
  {
    "LONG": -5.15,
    "LAT": -27.67,
    "CIDADE": "Barracão",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.8,
    "JAN": 5.49,
    "FEB": 5.49,
    "MAR": 5.29,
    "APR": 4.83,
    "MAY": 3.96,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.65,
    "SEP": 4.34,
    "OCT": 4.91,
    "NOV": 5.59,
    "DEC": 5.59
  },
  {
    "LONG": -54.67,
    "LAT": "#VALOR!",
    "CIDADE": "Porto Mauá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.71,
    "FEB": 5.7,
    "MAR": 5.5,
    "APR": 4.86,
    "MAY": 4.01,
    "JUN": 3.39,
    "JUL": 3.76,
    "AUG": 4.52,
    "SEP": 4.43,
    "OCT": 5.11,
    "NOV": 5.6,
    "DEC": 5.72
  },
  {
    "LONG": -54.5,
    "LAT": -27.58,
    "CIDADE": "Novo Machado",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.73,
    "FEB": 5.72,
    "MAR": 5.52,
    "APR": 4.86,
    "MAY": 4.04,
    "JUN": 3.44,
    "JUL": 3.79,
    "AUG": 4.54,
    "SEP": 4.43,
    "OCT": 5.1,
    "NOV": 5.59,
    "DEC": 5.73
  },
  {
    "LONG": -54.31,
    "LAT": -27.63,
    "CIDADE": "Horizontina",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.69,
    "FEB": 5.76,
    "MAR": 5.52,
    "APR": 4.87,
    "MAY": 4.07,
    "JUN": 3.49,
    "JUL": 3.86,
    "AUG": 4.6,
    "SEP": 4.44,
    "OCT": 5.12,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -54.11,
    "LAT": -27.61,
    "CIDADE": "Nova Candelária",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.72,
    "FEB": 5.72,
    "MAR": 5.5,
    "APR": 4.89,
    "MAY": 4.11,
    "JUN": 3.5,
    "JUL": 3.9,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.16,
    "NOV": 5.61,
    "DEC": 5.72
  },
  {
    "LONG": "#VALOR!",
    "LAT": -27.57,
    "CIDADE": "Humaitá",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.66,
    "FEB": 5.68,
    "MAR": 5.51,
    "APR": 4.89,
    "MAY": 4.12,
    "JUN": 3.55,
    "JUL": 3.97,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.13,
    "NOV": 5.6,
    "DEC": 5.71
  },
  {
    "LONG": -53.95,
    "LAT": -27.64,
    "CIDADE": "Sede Nova",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.66,
    "FEB": 5.68,
    "MAR": 5.51,
    "APR": 4.89,
    "MAY": 4.12,
    "JUN": 3.55,
    "JUL": 3.97,
    "AUG": 4.62,
    "SEP": 4.4,
    "OCT": 5.13,
    "NOV": 5.6,
    "DEC": 5.71
  },
  {
    "LONG": -5.37,
    "LAT": -27.62,
    "CIDADE": "Braga",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.91,
    "JAN": 5.71,
    "FEB": 5.68,
    "MAR": 5.5,
    "APR": 4.88,
    "MAY": 4.13,
    "JUN": 3.55,
    "JUL": 3.99,
    "AUG": 4.62,
    "SEP": 4.42,
    "OCT": 5.11,
    "NOV": 5.62,
    "DEC": 5.7
  },
  {
    "LONG": -53.28,
    "LAT": -27.64,
    "CIDADE": "Jaboticaba",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.56,
    "FEB": 5.65,
    "MAR": 5.43,
    "APR": 4.96,
    "MAY": 4.11,
    "JUN": 3.5,
    "JUL": 3.96,
    "AUG": 4.65,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.66,
    "DEC": 5.72
  },
  {
    "LONG": -53.18,
    "LAT": -27.57,
    "CIDADE": "Novo Tiradentes",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.57,
    "FEB": 5.66,
    "MAR": 5.47,
    "APR": 4.97,
    "MAY": 4.09,
    "JUN": 3.49,
    "JUL": 3.92,
    "AUG": 4.64,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.69,
    "DEC": 5.73
  },
  {
    "LONG": -53.17,
    "LAT": -27.61,
    "CIDADE": "Cerro Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.57,
    "FEB": 5.66,
    "MAR": 5.47,
    "APR": 4.97,
    "MAY": 4.09,
    "JUN": 3.49,
    "JUL": 3.92,
    "AUG": 4.64,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.69,
    "DEC": 5.73
  },
  {
    "LONG": -53.08,
    "LAT": -27.6,
    "CIDADE": "Liberato Salzano",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.59,
    "FEB": 5.64,
    "MAR": 5.48,
    "APR": 4.99,
    "MAY": 4.1,
    "JUN": 3.52,
    "JUL": 3.92,
    "AUG": 4.63,
    "SEP": 4.38,
    "OCT": 5.1,
    "NOV": 5.66,
    "DEC": 5.73
  },
  {
    "LONG": -52.84,
    "LAT": -27.61,
    "CIDADE": "Três Palmeiras",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.48,
    "FEB": 5.54,
    "MAR": 5.37,
    "APR": 4.92,
    "MAY": 4.07,
    "JUN": 3.58,
    "JUL": 3.98,
    "AUG": 4.66,
    "SEP": 4.38,
    "OCT": 5.05,
    "NOV": 5.56,
    "DEC": 5.64
  },
  {
    "LONG": -52.52,
    "LAT": -27.56,
    "CIDADE": "São Valentim",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.46,
    "FEB": 5.48,
    "MAR": 5.34,
    "APR": 4.94,
    "MAY": 4.06,
    "JUN": 3.59,
    "JUL": 3.98,
    "AUG": 4.7,
    "SEP": 4.37,
    "OCT": 5.02,
    "NOV": 5.55,
    "DEC": 5.59
  },
  {
    "LONG": -52.38,
    "LAT": -27.62,
    "CIDADE": "Barão de Cotegipe",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.47,
    "FEB": 5.5,
    "MAR": 5.33,
    "APR": 4.88,
    "MAY": 4.04,
    "JUN": 3.57,
    "JUL": 4,
    "AUG": 4.68,
    "SEP": 4.37,
    "OCT": 4.99,
    "NOV": 5.6,
    "DEC": 5.57
  },
  {
    "LONG": -52.27,
    "LAT": -27.64,
    "CIDADE": "Erechim",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.5,
    "FEB": 5.53,
    "MAR": 5.33,
    "APR": 4.87,
    "MAY": 4.03,
    "JUN": 3.56,
    "JUL": 3.99,
    "AUG": 4.66,
    "SEP": 4.37,
    "OCT": 4.97,
    "NOV": 5.59,
    "DEC": 5.59
  },
  {
    "LONG": -5.21,
    "LAT": -27.59,
    "CIDADE": "Gaurama",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.54,
    "FEB": 5.51,
    "MAR": 5.34,
    "APR": 4.86,
    "MAY": 4.02,
    "JUN": 3.53,
    "JUL": 3.94,
    "AUG": 4.66,
    "SEP": 4.38,
    "OCT": 4.98,
    "NOV": 5.61,
    "DEC": 5.61
  },
  {
    "LONG": -52.02,
    "LAT": -2.76,
    "CIDADE": "Viadutos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.83,
    "JAN": 5.54,
    "FEB": 5.51,
    "MAR": 5.34,
    "APR": 4.86,
    "MAY": 4.02,
    "JUN": 3.53,
    "JUL": 3.94,
    "AUG": 4.66,
    "SEP": 4.38,
    "OCT": 4.98,
    "NOV": 5.61,
    "DEC": 5.61
  },
  {
    "LONG": -51.8,
    "LAT": -2.76,
    "CIDADE": "Maximiliano de Almeida",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.59,
    "FEB": 5.58,
    "MAR": 5.43,
    "APR": 4.92,
    "MAY": 4.03,
    "JUN": 3.52,
    "JUL": 3.91,
    "AUG": 4.64,
    "SEP": 4.37,
    "OCT": 5.01,
    "NOV": 5.67,
    "DEC": 5.69
  },
  {
    "LONG": -51.67,
    "LAT": -27.57,
    "CIDADE": "Machadinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.59,
    "FEB": 5.54,
    "MAR": 5.38,
    "APR": 4.95,
    "MAY": 4.05,
    "JUN": 3.65,
    "JUL": 3.94,
    "AUG": 4.68,
    "SEP": 4.37,
    "OCT": 4.99,
    "NOV": 5.63,
    "DEC": 5.66
  },
  {
    "LONG": -54.36,
    "LAT": -27.51,
    "CIDADE": "Doutor Maurício Cardoso",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.68,
    "FEB": 5.72,
    "MAR": 5.53,
    "APR": 4.82,
    "MAY": 4.05,
    "JUN": 3.44,
    "JUL": 3.81,
    "AUG": 4.6,
    "SEP": 4.41,
    "OCT": 5.12,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -54.1,
    "LAT": -27.5,
    "CIDADE": "Crissiumal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.68,
    "FEB": 5.7,
    "MAR": 5.48,
    "APR": 4.85,
    "MAY": 4.09,
    "JUN": 3.48,
    "JUL": 3.9,
    "AUG": 4.61,
    "SEP": 4.41,
    "OCT": 5.16,
    "NOV": 5.61,
    "DEC": 5.71
  },
  {
    "LONG": -53.93,
    "LAT": -27.46,
    "CIDADE": "Três Passos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.64,
    "FEB": 5.66,
    "MAR": 5.49,
    "APR": 4.88,
    "MAY": 4.11,
    "JUN": 3.54,
    "JUL": 3.94,
    "AUG": 4.62,
    "SEP": 4.42,
    "OCT": 5.14,
    "NOV": 5.6,
    "DEC": 5.71
  },
  {
    "LONG": -53.87,
    "LAT": -27.54,
    "CIDADE": "Bom Progresso",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.67,
    "FEB": 5.66,
    "MAR": 5.5,
    "APR": 4.86,
    "MAY": 4.09,
    "JUN": 3.51,
    "JUL": 3.94,
    "AUG": 4.63,
    "SEP": 4.42,
    "OCT": 5.13,
    "NOV": 5.62,
    "DEC": 5.7
  },
  {
    "LONG": -53.69,
    "LAT": -27.5,
    "CIDADE": "Miraguaí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.68,
    "FEB": 5.63,
    "MAR": 5.49,
    "APR": 4.89,
    "MAY": 4.1,
    "JUN": 3.5,
    "JUL": 3.96,
    "AUG": 4.63,
    "SEP": 4.41,
    "OCT": 5.1,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -5.35,
    "LAT": -27.54,
    "CIDADE": "Erval Seco",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.64,
    "FEB": 5.6,
    "MAR": 5.48,
    "APR": 4.92,
    "MAY": 4.09,
    "JUN": 3.51,
    "JUL": 3.93,
    "AUG": 4.63,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.64,
    "DEC": 5.69
  },
  {
    "LONG": -53.4,
    "LAT": -27.48,
    "CIDADE": "Seberi",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.64,
    "FEB": 5.59,
    "MAR": 5.46,
    "APR": 4.91,
    "MAY": 4.1,
    "JUN": 3.52,
    "JUL": 3.94,
    "AUG": 4.65,
    "SEP": 4.41,
    "OCT": 5.11,
    "NOV": 5.63,
    "DEC": 5.7
  },
  {
    "LONG": -53.21,
    "LAT": -27.51,
    "CIDADE": "Pinhal",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 4.94,
    "MAY": 4.09,
    "JUN": 3.48,
    "JUL": 3.93,
    "AUG": 4.66,
    "SEP": 4.39,
    "OCT": 5.11,
    "NOV": 5.66,
    "DEC": 5.71
  },
  {
    "LONG": -53.24,
    "LAT": -27.45,
    "CIDADE": "Cristal do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 4.94,
    "MAY": 4.09,
    "JUN": 3.48,
    "JUL": 3.93,
    "AUG": 4.66,
    "SEP": 4.39,
    "OCT": 5.11,
    "NOV": 5.66,
    "DEC": 5.71
  },
  {
    "LONG": -5.32,
    "LAT": -27.47,
    "CIDADE": "Rodeio Bonito",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.58,
    "FEB": 5.65,
    "MAR": 5.48,
    "APR": 4.94,
    "MAY": 4.08,
    "JUN": 3.49,
    "JUL": 3.92,
    "AUG": 4.65,
    "SEP": 4.39,
    "OCT": 5.1,
    "NOV": 5.65,
    "DEC": 5.72
  },
  {
    "LONG": -5.29,
    "LAT": -27.52,
    "CIDADE": "Trindade do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.51,
    "FEB": 5.54,
    "MAR": 5.39,
    "APR": 4.93,
    "MAY": 4.07,
    "JUN": 3.6,
    "JUL": 3.96,
    "AUG": 4.68,
    "SEP": 4.4,
    "OCT": 5.04,
    "NOV": 5.54,
    "DEC": 5.63
  },
  {
    "LONG": -52.74,
    "LAT": -27.53,
    "CIDADE": "Entre Rios do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.51,
    "FEB": 5.55,
    "MAR": 5.38,
    "APR": 4.9,
    "MAY": 4.08,
    "JUN": 3.59,
    "JUL": 3.96,
    "AUG": 4.68,
    "SEP": 4.39,
    "OCT": 5.02,
    "NOV": 5.52,
    "DEC": 5.6
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.75,
    "CIDADE": "Benjamin Constant do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.52,
    "FEB": 5.52,
    "MAR": 5.42,
    "APR": 4.93,
    "MAY": 4.08,
    "JUN": 3.61,
    "JUL": 3.97,
    "AUG": 4.71,
    "SEP": 4.4,
    "OCT": 5.03,
    "NOV": 5.57,
    "DEC": 5.61
  },
  {
    "LONG": -52.15,
    "LAT": -27.5,
    "CIDADE": "Três Arroios",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.58,
    "FEB": 5.59,
    "MAR": 5.42,
    "APR": 4.89,
    "MAY": 4.04,
    "JUN": 3.57,
    "JUL": 3.98,
    "AUG": 4.68,
    "SEP": 4.39,
    "OCT": 5.02,
    "NOV": 5.63,
    "DEC": 5.65
  },
  {
    "LONG": "#VALOR!",
    "LAT": -27.47,
    "CIDADE": "Marcelino Ramos",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.59,
    "FEB": 5.56,
    "MAR": 5.43,
    "APR": 4.87,
    "MAY": 4.01,
    "JUN": 3.48,
    "JUL": 3.89,
    "AUG": 4.66,
    "SEP": 4.39,
    "OCT": 5.02,
    "NOV": 5.68,
    "DEC": 5.68
  },
  {
    "LONG": -54.08,
    "LAT": -27.4,
    "CIDADE": "Tiradentes do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.66,
    "FEB": 5.66,
    "MAR": 5.48,
    "APR": 4.86,
    "MAY": 4.08,
    "JUN": 3.49,
    "JUL": 3.87,
    "AUG": 4.59,
    "SEP": 4.41,
    "OCT": 5.14,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -53.99,
    "LAT": -27.36,
    "CIDADE": "Esperança do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.64,
    "FEB": 5.63,
    "MAR": 5.47,
    "APR": 4.85,
    "MAY": 4.09,
    "JUN": 3.51,
    "JUL": 3.89,
    "AUG": 4.61,
    "SEP": 4.42,
    "OCT": 5.14,
    "NOV": 5.59,
    "DEC": 5.68
  },
  {
    "LONG": -53.76,
    "LAT": -27.37,
    "CIDADE": "Tenente Portela",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.65,
    "FEB": 5.63,
    "MAR": 5.47,
    "APR": 4.85,
    "MAY": 4.08,
    "JUN": 3.51,
    "JUL": 3.92,
    "AUG": 4.63,
    "SEP": 4.41,
    "OCT": 5.1,
    "NOV": 5.57,
    "DEC": 5.66
  },
  {
    "LONG": -53.56,
    "LAT": "#VALOR!",
    "CIDADE": "Palmitinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.89,
    "JAN": 5.7,
    "FEB": 5.59,
    "MAR": 5.52,
    "APR": 4.9,
    "MAY": 4.08,
    "JUN": 3.52,
    "JUL": 3.92,
    "AUG": 4.65,
    "SEP": 4.39,
    "OCT": 5.12,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -53.49,
    "LAT": -27.37,
    "CIDADE": "Vista Alegre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.67,
    "FEB": 5.58,
    "MAR": 5.49,
    "APR": 4.89,
    "MAY": 4.09,
    "JUN": 3.54,
    "JUL": 3.93,
    "AUG": 4.66,
    "SEP": 4.39,
    "OCT": 5.11,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -53.47,
    "LAT": -27.4,
    "CIDADE": "Taquaruçu do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.67,
    "FEB": 5.58,
    "MAR": 5.49,
    "APR": 4.89,
    "MAY": 4.09,
    "JUN": 3.54,
    "JUL": 3.93,
    "AUG": 4.66,
    "SEP": 4.39,
    "OCT": 5.11,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -53.4,
    "LAT": -27.36,
    "CIDADE": "Frederico Westphalen",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.61,
    "FEB": 5.6,
    "MAR": 5.46,
    "APR": 4.88,
    "MAY": 4.07,
    "JUN": 3.48,
    "JUL": 3.9,
    "AUG": 4.67,
    "SEP": 4.39,
    "OCT": 5.1,
    "NOV": 5.62,
    "DEC": 5.67
  },
  {
    "LONG": -53.18,
    "LAT": -27.36,
    "CIDADE": "Ametista do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.59,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 4.91,
    "MAY": 4.08,
    "JUN": 3.51,
    "JUL": 3.9,
    "AUG": 4.67,
    "SEP": 4.38,
    "OCT": 5.09,
    "NOV": 5.6,
    "DEC": 5.69
  },
  {
    "LONG": -52.92,
    "LAT": -27.44,
    "CIDADE": "Gramado dos Loureiros",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.51,
    "FEB": 5.56,
    "MAR": 5.46,
    "APR": 4.97,
    "MAY": 4.09,
    "JUN": 3.61,
    "JUL": 3.95,
    "AUG": 4.68,
    "SEP": 4.41,
    "OCT": 5.07,
    "NOV": 5.57,
    "DEC": 5.65
  },
  {
    "LONG": -5.28,
    "LAT": -27.37,
    "CIDADE": "Nonoai",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.55,
    "FEB": 5.56,
    "MAR": 5.45,
    "APR": 4.91,
    "MAY": 4.09,
    "JUN": 3.62,
    "JUL": 3.93,
    "AUG": 4.71,
    "SEP": 4.42,
    "OCT": 5.05,
    "NOV": 5.53,
    "DEC": 5.62
  },
  {
    "LONG": -52.68,
    "LAT": -27.42,
    "CIDADE": "Faxinalzinho",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.53,
    "FEB": 5.53,
    "MAR": 5.43,
    "APR": 4.91,
    "MAY": 4.08,
    "JUN": 3.62,
    "JUL": 3.94,
    "AUG": 4.72,
    "SEP": 4.42,
    "OCT": 5.07,
    "NOV": 5.55,
    "DEC": 5.63
  },
  {
    "LONG": -52.57,
    "LAT": -27.39,
    "CIDADE": "Erval Grande",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.48,
    "FEB": 5.5,
    "MAR": 5.45,
    "APR": 4.9,
    "MAY": 4.05,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.7,
    "SEP": 4.41,
    "OCT": 5.05,
    "NOV": 5.57,
    "DEC": 5.64
  },
  {
    "LONG": -52.41,
    "LAT": -27.41,
    "CIDADE": "Barra do Rio Azul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.5,
    "FEB": 5.53,
    "MAR": 5.45,
    "APR": 4.91,
    "MAY": 4,
    "JUN": 3.44,
    "JUL": 3.89,
    "AUG": 4.68,
    "SEP": 4.41,
    "OCT": 5.04,
    "NOV": 5.63,
    "DEC": 5.65
  },
  {
    "LONG": -52.45,
    "LAT": -27.39,
    "CIDADE": "Itatiba do Sul",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.5,
    "FEB": 5.53,
    "MAR": 5.45,
    "APR": 4.91,
    "MAY": 4,
    "JUN": 3.44,
    "JUL": 3.89,
    "AUG": 4.68,
    "SEP": 4.41,
    "OCT": 5.04,
    "NOV": 5.63,
    "DEC": 5.65
  },
  {
    "LONG": -52.3,
    "LAT": -27.4,
    "CIDADE": "Aratiba",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.59,
    "FEB": 5.61,
    "MAR": 5.49,
    "APR": 4.85,
    "MAY": 3.99,
    "JUN": 3.46,
    "JUL": 3.86,
    "AUG": 4.65,
    "SEP": 4.39,
    "OCT": 5.03,
    "NOV": 5.65,
    "DEC": 5.69
  },
  {
    "LONG": -52.15,
    "LAT": -27.36,
    "CIDADE": "Mariano Moro",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.64,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 4.86,
    "MAY": 4,
    "JUN": 3.48,
    "JUL": 3.87,
    "AUG": 4.68,
    "SEP": 4.4,
    "OCT": 5.03,
    "NOV": 5.67,
    "DEC": 5.71
  },
  {
    "LONG": -52.12,
    "LAT": -27.44,
    "CIDADE": "Severiano de Almeida",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.64,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 4.86,
    "MAY": 4,
    "JUN": 3.48,
    "JUL": 3.87,
    "AUG": 4.68,
    "SEP": 4.4,
    "OCT": 5.03,
    "NOV": 5.67,
    "DEC": 5.71
  },
  {
    "LONG": -5.39,
    "LAT": -27.26,
    "CIDADE": "Derrubadas",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.85,
    "JAN": 5.62,
    "FEB": 5.62,
    "MAR": 5.48,
    "APR": 4.81,
    "MAY": 4.06,
    "JUN": 3.45,
    "JUL": 3.85,
    "AUG": 4.64,
    "SEP": 4.39,
    "OCT": 5.09,
    "NOV": 5.53,
    "DEC": 5.65
  },
  {
    "LONG": -53.7,
    "LAT": -27.29,
    "CIDADE": "Vista Gaúcha",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.7,
    "FEB": 5.6,
    "MAR": 5.52,
    "APR": 4.84,
    "MAY": 4.04,
    "JUN": 3.48,
    "JUL": 3.89,
    "AUG": 4.63,
    "SEP": 4.4,
    "OCT": 5.12,
    "NOV": 5.58,
    "DEC": 5.68
  },
  {
    "LONG": -53.43,
    "LAT": -27.28,
    "CIDADE": "Caiçara",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.69,
    "FEB": 5.6,
    "MAR": 5.52,
    "APR": 4.86,
    "MAY": 4.08,
    "JUN": 3.52,
    "JUL": 3.92,
    "AUG": 4.66,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.59,
    "DEC": 5.66
  },
  {
    "LONG": -53.03,
    "LAT": -27.25,
    "CIDADE": "Alpestre",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.55,
    "FEB": 5.58,
    "MAR": 5.52,
    "APR": 4.89,
    "MAY": 4.07,
    "JUN": 3.57,
    "JUL": 3.9,
    "AUG": 4.68,
    "SEP": 4.42,
    "OCT": 5.09,
    "NOV": 5.59,
    "DEC": 5.69
  },
  {
    "LONG": -5.31,
    "LAT": -27.33,
    "CIDADE": "Planalto",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.88,
    "JAN": 5.55,
    "FEB": 5.58,
    "MAR": 5.52,
    "APR": 4.89,
    "MAY": 4.07,
    "JUN": 3.57,
    "JUL": 3.9,
    "AUG": 4.68,
    "SEP": 4.42,
    "OCT": 5.09,
    "NOV": 5.59,
    "DEC": 5.69
  },
  {
    "LONG": -52.84,
    "LAT": -27.3,
    "CIDADE": "Rio dos Índios",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.9,
    "JAN": 5.55,
    "FEB": 5.61,
    "MAR": 5.52,
    "APR": 4.95,
    "MAY": 4.11,
    "JUN": 3.61,
    "JUL": 3.95,
    "AUG": 4.72,
    "SEP": 4.43,
    "OCT": 5.09,
    "NOV": 5.57,
    "DEC": 5.66
  },
  {
    "LONG": -53.71,
    "LAT": -27.19,
    "CIDADE": "Barra do Guarita",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.84,
    "JAN": 5.65,
    "FEB": 5.6,
    "MAR": 5.5,
    "APR": 4.8,
    "MAY": 4.02,
    "JUN": 3.4,
    "JUL": 3.82,
    "AUG": 4.61,
    "SEP": 4.39,
    "OCT": 5.09,
    "NOV": 5.56,
    "DEC": 5.64
  },
  {
    "LONG": -53.61,
    "LAT": -27.21,
    "CIDADE": "Pinheirinho do Vale",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.69,
    "FEB": 5.6,
    "MAR": 5.5,
    "APR": 4.82,
    "MAY": 4.03,
    "JUN": 3.42,
    "JUL": 3.83,
    "AUG": 4.64,
    "SEP": 4.4,
    "OCT": 5.11,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -53.4,
    "LAT": -27.16,
    "CIDADE": "Vicente Dutra",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.86,
    "JAN": 5.67,
    "FEB": 5.6,
    "MAR": 5.5,
    "APR": 4.83,
    "MAY": 4.03,
    "JUN": 3.44,
    "JUL": 3.83,
    "AUG": 4.64,
    "SEP": 4.41,
    "OCT": 5.09,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -53.25,
    "LAT": -27.2,
    "CIDADE": "Iraí",
    "UF": "RIO GRANDE DO SUL",
    "anual": 4.87,
    "JAN": 5.61,
    "FEB": 5.62,
    "MAR": 5.53,
    "APR": 4.85,
    "MAY": 4.03,
    "JUN": 3.49,
    "JUL": 3.85,
    "AUG": 4.64,
    "SEP": 4.4,
    "OCT": 5.09,
    "NOV": 5.59,
    "DEC": 5.69
  },
  {
    "LONG": -61.05,
    "LAT": -13.48,
    "CIDADE": "Pimenteiras do Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.93,
    "JAN": 4.51,
    "FEB": 4.53,
    "MAR": 4.74,
    "APR": 5.04,
    "MAY": 4.74,
    "JUN": 5,
    "JUL": 5.21,
    "AUG": 5.55,
    "SEP": 5.19,
    "OCT": 5.12,
    "NOV": 4.91,
    "DEC": 4.69
  },
  {
    "LONG": -60.54,
    "LAT": -13.5,
    "CIDADE": "Cabixi",
    "UF": "RONDÔNIA",
    "anual": 4.89,
    "JAN": 4.51,
    "FEB": 4.47,
    "MAR": 4.73,
    "APR": 5.08,
    "MAY": 4.72,
    "JUN": 4.95,
    "JUL": 5.11,
    "AUG": 5.42,
    "SEP": 5.07,
    "OCT": 5.1,
    "NOV": 4.87,
    "DEC": 4.69
  },
  {
    "LONG": -60.82,
    "LAT": -13.19,
    "CIDADE": "Cerejeiras",
    "UF": "RONDÔNIA",
    "anual": 4.86,
    "JAN": 4.43,
    "FEB": 4.5,
    "MAR": 4.74,
    "APR": 4.92,
    "MAY": 4.7,
    "JUN": 4.96,
    "JUL": 5.06,
    "AUG": 5.38,
    "SEP": 5.07,
    "OCT": 5.1,
    "NOV": 4.84,
    "DEC": 4.64
  },
  {
    "LONG": -60.55,
    "LAT": -13.12,
    "CIDADE": "Colorado do Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.78,
    "JAN": 4.33,
    "FEB": 4.31,
    "MAR": 4.56,
    "APR": 4.83,
    "MAY": 4.62,
    "JUN": 4.95,
    "JUL": 5.11,
    "AUG": 5.43,
    "SEP": 5.03,
    "OCT": 4.95,
    "NOV": 4.72,
    "DEC": 4.53
  },
  {
    "LONG": -60.94,
    "LAT": -13,
    "CIDADE": "Corumbiara",
    "UF": "RONDÔNIA",
    "anual": 4.82,
    "JAN": 4.36,
    "FEB": 4.43,
    "MAR": 4.6,
    "APR": 4.86,
    "MAY": 4.65,
    "JUN": 4.97,
    "JUL": 5.08,
    "AUG": 5.44,
    "SEP": 5.05,
    "OCT": 5.05,
    "NOV": 4.8,
    "DEC": 4.56
  },
  {
    "LONG": -60.15,
    "LAT": -12.75,
    "CIDADE": "Vilhena",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.29,
    "FEB": 4.3,
    "MAR": 4.46,
    "APR": 4.78,
    "MAY": 4.68,
    "JUN": 4.94,
    "JUL": 5.09,
    "AUG": 5.45,
    "SEP": 4.9,
    "OCT": 4.82,
    "NOV": 4.58,
    "DEC": 4.46
  },
  {
    "LONG": -60.9,
    "LAT": -12.55,
    "CIDADE": "Chupinguaia",
    "UF": "RONDÔNIA",
    "anual": 4.81,
    "JAN": 4.33,
    "FEB": 4.42,
    "MAR": 4.6,
    "APR": 4.85,
    "MAY": 4.69,
    "JUN": 4.95,
    "JUL": 5.04,
    "AUG": 5.34,
    "SEP": 5.07,
    "OCT": 5.09,
    "NOV": 4.81,
    "DEC": 4.57
  },
  {
    "LONG": -64.23,
    "LAT": -12.44,
    "CIDADE": "Costa Marques",
    "UF": "RONDÔNIA",
    "anual": 4.71,
    "JAN": 4.36,
    "FEB": 4.38,
    "MAR": 4.51,
    "APR": 4.81,
    "MAY": 4.3,
    "JUN": 4.69,
    "JUL": 4.89,
    "AUG": 5.26,
    "SEP": 5.04,
    "OCT": 4.99,
    "NOV": 4.78,
    "DEC": 4.55
  },
  {
    "LONG": -61.6,
    "LAT": -12.18,
    "CIDADE": "Parecis",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.15,
    "FEB": 4.26,
    "MAR": 4.52,
    "APR": 4.82,
    "MAY": 4.56,
    "JUN": 4.87,
    "JUL": 5,
    "AUG": 5.36,
    "SEP": 5.05,
    "OCT": 5,
    "NOV": 4.71,
    "DEC": 4.46
  },
  {
    "LONG": -63.57,
    "LAT": -12.05,
    "CIDADE": "São Francisco do Guaporé",
    "UF": "RONDÔNIA",
    "anual": 4.66,
    "JAN": 4.25,
    "FEB": 4.27,
    "MAR": 4.43,
    "APR": 4.71,
    "MAY": 4.26,
    "JUN": 4.7,
    "JUL": 4.9,
    "AUG": 5.22,
    "SEP": 5.01,
    "OCT": 4.99,
    "NOV": 4.69,
    "DEC": 4.45
  },
  {
    "LONG": -61.84,
    "LAT": -12.14,
    "CIDADE": "Alto Alegre dos Parecis",
    "UF": "RONDÔNIA",
    "anual": 4.71,
    "JAN": 4.18,
    "FEB": 4.25,
    "MAR": 4.45,
    "APR": 4.73,
    "MAY": 4.48,
    "JUN": 4.84,
    "JUL": 4.96,
    "AUG": 5.37,
    "SEP": 5.12,
    "OCT": 5,
    "NOV": 4.68,
    "DEC": 4.42
  },
  {
    "LONG": -62,
    "LAT": -11.93,
    "CIDADE": "Alta Floresta D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.71,
    "JAN": 4.14,
    "FEB": 4.24,
    "MAR": 4.52,
    "APR": 4.78,
    "MAY": 4.49,
    "JUN": 4.84,
    "JUL": 4.95,
    "AUG": 5.34,
    "SEP": 5.08,
    "OCT": 5.03,
    "NOV": 4.72,
    "DEC": 4.42
  },
  {
    "LONG": -61.78,
    "LAT": -11.91,
    "CIDADE": "Santa Luzia D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.75,
    "JAN": 4.23,
    "FEB": 4.27,
    "MAR": 4.58,
    "APR": 4.8,
    "MAY": 4.57,
    "JUN": 4.9,
    "JUL": 5,
    "AUG": 5.32,
    "SEP": 5.09,
    "OCT": 5.01,
    "NOV": 4.71,
    "DEC": 4.46
  },
  {
    "LONG": -61.5,
    "LAT": -11.9,
    "CIDADE": "São Felipe D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.76,
    "JAN": 4.25,
    "FEB": 4.31,
    "MAR": 4.6,
    "APR": 4.84,
    "MAY": 4.58,
    "JUN": 4.9,
    "JUL": 5,
    "AUG": 5.31,
    "SEP": 5.06,
    "OCT": 5.05,
    "NOV": 4.72,
    "DEC": 4.49
  },
  {
    "LONG": -63.03,
    "LAT": -11.77,
    "CIDADE": "Seringueiras",
    "UF": "RONDÔNIA",
    "anual": 4.65,
    "JAN": 4.2,
    "FEB": 4.28,
    "MAR": 4.43,
    "APR": 4.66,
    "MAY": 4.3,
    "JUN": 4.75,
    "JUL": 4.93,
    "AUG": 5.27,
    "SEP": 5.05,
    "OCT": 4.91,
    "NOV": 4.67,
    "DEC": 4.35
  },
  {
    "LONG": -61.32,
    "LAT": -11.83,
    "CIDADE": "Primavera de Rondônia",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.18,
    "FEB": 4.28,
    "MAR": 4.55,
    "APR": 4.76,
    "MAY": 4.61,
    "JUN": 4.88,
    "JUL": 5.05,
    "AUG": 5.29,
    "SEP": 5,
    "OCT": 4.95,
    "NOV": 4.69,
    "DEC": 4.45
  },
  {
    "LONG": -62.72,
    "LAT": -11.7,
    "CIDADE": "São Miguel do Guaporé",
    "UF": "RONDÔNIA",
    "anual": 4.68,
    "JAN": 4.18,
    "FEB": 4.31,
    "MAR": 4.47,
    "APR": 4.7,
    "MAY": 4.32,
    "JUN": 4.78,
    "JUL": 4.97,
    "AUG": 5.27,
    "SEP": 5.12,
    "OCT": 4.98,
    "NOV": 4.7,
    "DEC": 4.4
  },
  {
    "LONG": -62.31,
    "LAT": -11.73,
    "CIDADE": "Nova Brasilândia D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.67,
    "JAN": 4.18,
    "FEB": 4.26,
    "MAR": 4.45,
    "APR": 4.69,
    "MAY": 4.34,
    "JUN": 4.86,
    "JUL": 4.93,
    "AUG": 5.3,
    "SEP": 5.03,
    "OCT": 4.98,
    "NOV": 4.63,
    "DEC": 4.4
  },
  {
    "LONG": -62,
    "LAT": -11.71,
    "CIDADE": "Novo Horizonte do Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.23,
    "FEB": 4.27,
    "MAR": 4.57,
    "APR": 4.79,
    "MAY": 4.49,
    "JUN": 4.9,
    "JUL": 4.94,
    "AUG": 5.32,
    "SEP": 5.07,
    "OCT": 5.01,
    "NOV": 4.73,
    "DEC": 4.4
  },
  {
    "LONG": -61.77,
    "LAT": -11.73,
    "CIDADE": "Rolim de Moura",
    "UF": "RONDÔNIA",
    "anual": 4.71,
    "JAN": 4.18,
    "FEB": 4.27,
    "MAR": 4.59,
    "APR": 4.76,
    "MAY": 4.49,
    "JUN": 4.87,
    "JUL": 4.99,
    "AUG": 5.22,
    "SEP": 5.04,
    "OCT": 5,
    "NOV": 4.66,
    "DEC": 4.43
  },
  {
    "LONG": -61.2,
    "LAT": -11.67,
    "CIDADE": "Pimenta Bueno",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.16,
    "FEB": 4.27,
    "MAR": 4.57,
    "APR": 4.74,
    "MAY": 4.58,
    "JUN": 4.92,
    "JUL": 5.05,
    "AUG": 5.35,
    "SEP": 4.98,
    "OCT": 4.96,
    "NOV": 4.7,
    "DEC": 4.48
  },
  {
    "LONG": -61.03,
    "LAT": -1.15,
    "CIDADE": "Espigão D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.73,
    "JAN": 4.22,
    "FEB": 4.31,
    "MAR": 4.52,
    "APR": 4.71,
    "MAY": 4.54,
    "JUN": 4.94,
    "JUL": 5.06,
    "AUG": 5.3,
    "SEP": 5.02,
    "OCT": 5.02,
    "NOV": 4.74,
    "DEC": 4.43
  },
  {
    "LONG": -61.95,
    "LAT": -11.43,
    "CIDADE": "Castanheiras",
    "UF": "RONDÔNIA",
    "anual": 4.7,
    "JAN": 4.17,
    "FEB": 4.24,
    "MAR": 4.53,
    "APR": 4.8,
    "MAY": 4.51,
    "JUN": 4.84,
    "JUL": 4.95,
    "AUG": 5.21,
    "SEP": 4.97,
    "OCT": 5.02,
    "NOV": 4.76,
    "DEC": 4.42
  },
  {
    "LONG": -61.46,
    "LAT": -11.43,
    "CIDADE": "Cacoal",
    "UF": "RONDÔNIA",
    "anual": 4.7,
    "JAN": 4.08,
    "FEB": 4.23,
    "MAR": 4.5,
    "APR": 4.74,
    "MAY": 4.51,
    "JUN": 4.89,
    "JUL": 5.02,
    "AUG": 5.3,
    "SEP": 5.01,
    "OCT": 4.98,
    "NOV": 4.69,
    "DEC": 4.41
  },
  {
    "LONG": -62.29,
    "LAT": -11.35,
    "CIDADE": "Alvorada D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.69,
    "JAN": 4.2,
    "FEB": 4.33,
    "MAR": 4.5,
    "APR": 4.77,
    "MAY": 4.42,
    "JUN": 4.83,
    "JUL": 4.95,
    "AUG": 5.26,
    "SEP": 4.99,
    "OCT": 4.95,
    "NOV": 4.72,
    "DEC": 4.41
  },
  {
    "LONG": -61.9,
    "LAT": -11.17,
    "CIDADE": "Presidente Médici",
    "UF": "RONDÔNIA",
    "anual": 4.71,
    "JAN": 4.22,
    "FEB": 4.28,
    "MAR": 4.51,
    "APR": 4.74,
    "MAY": 4.48,
    "JUN": 4.89,
    "JUL": 4.93,
    "AUG": 5.22,
    "SEP": 5.01,
    "OCT": 5.04,
    "NOV": 4.69,
    "DEC": 4.43
  },
  {
    "LONG": -61.52,
    "LAT": -11.2,
    "CIDADE": "Ministro Andreazza",
    "UF": "RONDÔNIA",
    "anual": 4.66,
    "JAN": 4.09,
    "FEB": 4.24,
    "MAR": 4.44,
    "APR": 4.65,
    "MAY": 4.42,
    "JUN": 4.86,
    "JUL": 5.02,
    "AUG": 5.25,
    "SEP": 4.96,
    "OCT": 4.89,
    "NOV": 4.67,
    "DEC": 4.44
  },
  {
    "LONG": -62.36,
    "LAT": -11.13,
    "CIDADE": "Urupá",
    "UF": "RONDÔNIA",
    "anual": 4.68,
    "JAN": 4.16,
    "FEB": 4.25,
    "MAR": 4.43,
    "APR": 4.74,
    "MAY": 4.41,
    "JUN": 4.84,
    "JUL": 4.96,
    "AUG": 5.21,
    "SEP": 4.99,
    "OCT": 5.06,
    "NOV": 4.68,
    "DEC": 4.39
  },
  {
    "LONG": -62.67,
    "LAT": -11.03,
    "CIDADE": "Mirante da Serra",
    "UF": "RONDÔNIA",
    "anual": 4.64,
    "JAN": 4.15,
    "FEB": 4.19,
    "MAR": 4.4,
    "APR": 4.66,
    "MAY": 4.33,
    "JUN": 4.79,
    "JUL": 4.91,
    "AUG": 5.22,
    "SEP": 5,
    "OCT": 4.99,
    "NOV": 4.68,
    "DEC": 4.35
  },
  {
    "LONG": -62.56,
    "LAT": -10.91,
    "CIDADE": "Nova União",
    "UF": "RONDÔNIA",
    "anual": 4.64,
    "JAN": 4.12,
    "FEB": 4.22,
    "MAR": 4.47,
    "APR": 4.67,
    "MAY": 4.34,
    "JUN": 4.79,
    "JUL": 4.89,
    "AUG": 5.2,
    "SEP": 5.02,
    "OCT": 5,
    "NOV": 4.69,
    "DEC": 4.33
  },
  {
    "LONG": -62.26,
    "LAT": -10.93,
    "CIDADE": "Teixeirópolis",
    "UF": "RONDÔNIA",
    "anual": 4.69,
    "JAN": 4.26,
    "FEB": 4.25,
    "MAR": 4.45,
    "APR": 4.7,
    "MAY": 4.4,
    "JUN": 4.86,
    "JUL": 4.97,
    "AUG": 5.27,
    "SEP": 4.95,
    "OCT": 5.05,
    "NOV": 4.72,
    "DEC": 4.39
  },
  {
    "LONG": -61.93,
    "LAT": -10.88,
    "CIDADE": "Ji-Paraná",
    "UF": "RONDÔNIA",
    "anual": 4.67,
    "JAN": 4.15,
    "FEB": 4.29,
    "MAR": 4.47,
    "APR": 4.72,
    "MAY": 4.42,
    "JUN": 4.83,
    "JUL": 4.87,
    "AUG": 5.18,
    "SEP": 4.96,
    "OCT": 5.02,
    "NOV": 4.7,
    "DEC": 4.42
  },
  {
    "LONG": "#VALOR!",
    "LAT": -10.79,
    "CIDADE": "Guajará-Mirim",
    "UF": "RONDÔNIA",
    "anual": 4.64,
    "JAN": 4.25,
    "FEB": 4.33,
    "MAR": 4.37,
    "APR": 4.64,
    "MAY": 4.29,
    "JUN": 4.58,
    "JUL": 4.81,
    "AUG": 5.23,
    "SEP": 5.05,
    "OCT": 5.01,
    "NOV": 4.65,
    "DEC": 4.5
  },
  {
    "LONG": -6.23,
    "LAT": -10.72,
    "CIDADE": "Ouro Preto do Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.64,
    "JAN": 4.16,
    "FEB": 4.14,
    "MAR": 4.42,
    "APR": 4.7,
    "MAY": 4.41,
    "JUN": 4.84,
    "JUL": 4.89,
    "AUG": 5.19,
    "SEP": 4.92,
    "OCT": 4.97,
    "NOV": 4.65,
    "DEC": 4.33
  },
  {
    "LONG": -63.62,
    "LAT": -10.57,
    "CIDADE": "Campo Novo de Rondônia",
    "UF": "RONDÔNIA",
    "anual": 4.59,
    "JAN": 4,
    "FEB": 4.22,
    "MAR": 4.34,
    "APR": 4.66,
    "MAY": 4.42,
    "JUN": 4.82,
    "JUL": 4.8,
    "AUG": 5.16,
    "SEP": 4.97,
    "OCT": 4.89,
    "NOV": 4.51,
    "DEC": 4.25
  },
  {
    "LONG": -62.74,
    "LAT": -10.61,
    "CIDADE": "Governador Jorge Teixeira",
    "UF": "RONDÔNIA",
    "anual": 4.63,
    "JAN": 4.12,
    "FEB": 4.17,
    "MAR": 4.41,
    "APR": 4.72,
    "MAY": 4.33,
    "JUN": 4.8,
    "JUL": 4.89,
    "AUG": 5.19,
    "SEP": 5.01,
    "OCT": 4.96,
    "NOV": 4.59,
    "DEC": 4.37
  },
  {
    "LONG": -65.34,
    "LAT": -10.41,
    "CIDADE": "Nova Mamoré",
    "UF": "RONDÔNIA",
    "anual": 4.62,
    "JAN": 4.24,
    "FEB": 4.38,
    "MAR": 4.37,
    "APR": 4.61,
    "MAY": 4.25,
    "JUN": 4.6,
    "JUL": 4.81,
    "AUG": 5.16,
    "SEP": 5.08,
    "OCT": 4.99,
    "NOV": 4.55,
    "DEC": 4.43
  },
  {
    "LONG": -62.48,
    "LAT": -10.43,
    "CIDADE": "Jaru",
    "UF": "RONDÔNIA",
    "anual": 4.62,
    "JAN": 4.1,
    "FEB": 4.23,
    "MAR": 4.39,
    "APR": 4.69,
    "MAY": 4.35,
    "JUN": 4.88,
    "JUL": 4.88,
    "AUG": 5.12,
    "SEP": 4.98,
    "OCT": 4.94,
    "NOV": 4.6,
    "DEC": 4.3
  },
  {
    "LONG": -62.13,
    "LAT": -10.43,
    "CIDADE": "Vale do Paraíso",
    "UF": "RONDÔNIA",
    "anual": 4.64,
    "JAN": 4.12,
    "FEB": 4.2,
    "MAR": 4.39,
    "APR": 4.71,
    "MAY": 4.38,
    "JUN": 4.88,
    "JUL": 4.94,
    "AUG": 5.17,
    "SEP": 4.94,
    "OCT": 4.94,
    "NOV": 4.61,
    "DEC": 4.36
  },
  {
    "LONG": -63.3,
    "LAT": -10.26,
    "CIDADE": "Monte Negro",
    "UF": "RONDÔNIA",
    "anual": 4.59,
    "JAN": 3.98,
    "FEB": 4.28,
    "MAR": 4.4,
    "APR": 4.65,
    "MAY": 4.31,
    "JUN": 4.82,
    "JUL": 4.84,
    "AUG": 5.2,
    "SEP": 4.99,
    "OCT": 4.89,
    "NOV": 4.51,
    "DEC": 4.22
  },
  {
    "LONG": -62.9,
    "LAT": -10.34,
    "CIDADE": "Cacaulândia",
    "UF": "RONDÔNIA",
    "anual": 4.6,
    "JAN": 4.03,
    "FEB": 4.14,
    "MAR": 4.4,
    "APR": 4.69,
    "MAY": 4.35,
    "JUN": 4.8,
    "JUL": 4.86,
    "AUG": 5.18,
    "SEP": 4.96,
    "OCT": 4.92,
    "NOV": 4.55,
    "DEC": 4.26
  },
  {
    "LONG": -63.83,
    "LAT": -1.02,
    "CIDADE": "Buritis",
    "UF": "RONDÔNIA",
    "anual": 4.56,
    "JAN": 4.06,
    "FEB": 4.26,
    "MAR": 4.34,
    "APR": 4.56,
    "MAY": 4.28,
    "JUN": 4.73,
    "JUL": 4.78,
    "AUG": 5.12,
    "SEP": 4.98,
    "OCT": 4.85,
    "NOV": 4.5,
    "DEC": 4.22
  },
  {
    "LONG": -62.35,
    "LAT": -10.25,
    "CIDADE": "Theobroma",
    "UF": "RONDÔNIA",
    "anual": 4.6,
    "JAN": 4.14,
    "FEB": 4.16,
    "MAR": 4.31,
    "APR": 4.67,
    "MAY": 4.35,
    "JUN": 4.87,
    "JUL": 4.91,
    "AUG": 5.12,
    "SEP": 4.91,
    "OCT": 4.87,
    "NOV": 4.54,
    "DEC": 4.32
  },
  {
    "LONG": -6.3,
    "LAT": -9.91,
    "CIDADE": "Ariquemes",
    "UF": "RONDÔNIA",
    "anual": 4.54,
    "JAN": 4.07,
    "FEB": 4.19,
    "MAR": 4.3,
    "APR": 4.6,
    "MAY": 4.27,
    "JUN": 4.75,
    "JUL": 4.78,
    "AUG": 5,
    "SEP": 4.92,
    "OCT": 4.85,
    "NOV": 4.49,
    "DEC": 4.33
  },
  {
    "LONG": -62.17,
    "LAT": -9.86,
    "CIDADE": "Vale do Anari",
    "UF": "RONDÔNIA",
    "anual": 4.59,
    "JAN": 4.1,
    "FEB": 4.24,
    "MAR": 4.35,
    "APR": 4.64,
    "MAY": 4.36,
    "JUN": 4.83,
    "JUL": 4.94,
    "AUG": 5.12,
    "SEP": 4.87,
    "OCT": 4.85,
    "NOV": 4.48,
    "DEC": 4.3
  },
  {
    "LONG": -63.32,
    "LAT": -9.71,
    "CIDADE": "Alto Paraíso",
    "UF": "RONDÔNIA",
    "anual": 4.56,
    "JAN": 4.07,
    "FEB": 4.25,
    "MAR": 4.33,
    "APR": 4.55,
    "MAY": 4.3,
    "JUN": 4.73,
    "JUL": 4.8,
    "AUG": 5.12,
    "SEP": 4.92,
    "OCT": 4.91,
    "NOV": 4.53,
    "DEC": 4.24
  },
  {
    "LONG": -62.9,
    "LAT": -9.7,
    "CIDADE": "Rio Crespo",
    "UF": "RONDÔNIA",
    "anual": 4.56,
    "JAN": 3.99,
    "FEB": 4.23,
    "MAR": 4.29,
    "APR": 4.54,
    "MAY": 4.34,
    "JUN": 4.77,
    "JUL": 4.84,
    "AUG": 5.1,
    "SEP": 4.91,
    "OCT": 4.87,
    "NOV": 4.57,
    "DEC": 4.31
  },
  {
    "LONG": -6.26,
    "LAT": -9.36,
    "CIDADE": "Cujubim",
    "UF": "RONDÔNIA",
    "anual": 4.54,
    "JAN": 3.95,
    "FEB": 4.17,
    "MAR": 4.35,
    "APR": 4.45,
    "MAY": 4.28,
    "JUN": 4.82,
    "JUL": 4.87,
    "AUG": 5.13,
    "SEP": 4.86,
    "OCT": 4.85,
    "NOV": 4.5,
    "DEC": 4.28
  },
  {
    "LONG": -62,
    "LAT": -9.43,
    "CIDADE": "Machadinho D'Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.57,
    "JAN": 3.98,
    "FEB": 4.19,
    "MAR": 4.34,
    "APR": 4.51,
    "MAY": 4.33,
    "JUN": 4.89,
    "JUL": 4.97,
    "AUG": 5.11,
    "SEP": 4.9,
    "OCT": 4.87,
    "NOV": 4.46,
    "DEC": 4.25
  },
  {
    "LONG": -63.18,
    "LAT": -9.2,
    "CIDADE": "Itapuã do Oeste",
    "UF": "RONDÔNIA",
    "anual": 4.57,
    "JAN": 3.98,
    "FEB": 4.27,
    "MAR": 4.3,
    "APR": 4.49,
    "MAY": 4.26,
    "JUN": 4.8,
    "JUL": 4.92,
    "AUG": 5.1,
    "SEP": 5.02,
    "OCT": 4.92,
    "NOV": 4.56,
    "DEC": 4.23
  },
  {
    "LONG": -63.9,
    "LAT": -8.76,
    "CIDADE": "Porto Velho",
    "UF": "RONDÔNIA",
    "anual": 4.52,
    "JAN": 4,
    "FEB": 4.32,
    "MAR": 4.32,
    "APR": 4.5,
    "MAY": 4.22,
    "JUN": 4.63,
    "JUL": 4.81,
    "AUG": 5.08,
    "SEP": 4.91,
    "OCT": 4.84,
    "NOV": 4.44,
    "DEC": 4.17
  },
  {
    "LONG": -6.37,
    "LAT": -8.79,
    "CIDADE": "Candeias do Jamari",
    "UF": "RONDÔNIA",
    "anual": 4.51,
    "JAN": 4.03,
    "FEB": 4.27,
    "MAR": 4.35,
    "APR": 4.47,
    "MAY": 4.26,
    "JUN": 4.73,
    "JUL": 4.83,
    "AUG": 4.97,
    "SEP": 4.82,
    "OCT": 4.76,
    "NOV": 4.45,
    "DEC": 4.15
  },
  {
    "LONG": -60.44,
    "LAT": "#VALOR!",
    "CIDADE": "Rorainópolis",
    "UF": "RORAIMA",
    "anual": 4.71,
    "JAN": 4.54,
    "FEB": 4.62,
    "MAR": 4.66,
    "APR": 4.42,
    "MAY": 4.09,
    "JUN": 4.35,
    "JUL": 4.43,
    "AUG": 4.99,
    "SEP": 5.35,
    "OCT": 5.35,
    "NOV": 5.03,
    "DEC": 4.74
  },
  {
    "LONG": -59.7,
    "LAT": "#VALOR!",
    "CIDADE": "Caroebe",
    "UF": "RORAIMA",
    "anual": 4.68,
    "JAN": 4.44,
    "FEB": 4.5,
    "MAR": 4.49,
    "APR": 4.32,
    "MAY": 4.12,
    "JUN": 4.42,
    "JUL": 4.48,
    "AUG": 5.08,
    "SEP": 5.29,
    "OCT": 5.27,
    "NOV": 5.08,
    "DEC": 4.65
  },
  {
    "LONG": -60.04,
    "LAT": 1.02,
    "CIDADE": "São Luiz",
    "UF": "RORAIMA",
    "anual": 4.72,
    "JAN": 4.49,
    "FEB": 4.54,
    "MAR": 4.62,
    "APR": 4.4,
    "MAY": 4.13,
    "JUN": 4.39,
    "JUL": 4.44,
    "AUG": 5.03,
    "SEP": 5.35,
    "OCT": 5.36,
    "NOV": 5.13,
    "DEC": 4.71
  },
  {
    "LONG": -59.91,
    "LAT": "#VALOR!",
    "CIDADE": "São João da Baliza",
    "UF": "RORAIMA",
    "anual": 4.7,
    "JAN": 4.49,
    "FEB": 4.55,
    "MAR": 4.6,
    "APR": 4.37,
    "MAY": 4.1,
    "JUN": 4.42,
    "JUL": 4.41,
    "AUG": 4.98,
    "SEP": 5.31,
    "OCT": 5.33,
    "NOV": 5.13,
    "DEC": 4.73
  },
  {
    "LONG": -61.13,
    "LAT": 1.82,
    "CIDADE": "Caracaraí",
    "UF": "RORAIMA",
    "anual": 4.83,
    "JAN": 4.69,
    "FEB": 4.8,
    "MAR": 4.93,
    "APR": 4.57,
    "MAY": 4.15,
    "JUN": 4.44,
    "JUL": 4.44,
    "AUG": 4.96,
    "SEP": 5.46,
    "OCT": 5.43,
    "NOV": 5.2,
    "DEC": 4.88
  },
  {
    "LONG": -61.05,
    "LAT": 2.17,
    "CIDADE": "Iracema",
    "UF": "RORAIMA",
    "anual": 4.87,
    "JAN": 4.73,
    "FEB": 4.8,
    "MAR": 5.08,
    "APR": 4.65,
    "MAY": 4.28,
    "JUN": 4.44,
    "JUL": 4.49,
    "AUG": 4.94,
    "SEP": 5.48,
    "OCT": 5.45,
    "NOV": 5.23,
    "DEC": 4.89
  },
  {
    "LONG": -60.92,
    "LAT": 2.44,
    "CIDADE": "Mucajaí",
    "UF": "RORAIMA",
    "anual": 4.87,
    "JAN": 4.69,
    "FEB": 4.73,
    "MAR": 4.95,
    "APR": 4.63,
    "MAY": 4.22,
    "JUN": 4.46,
    "JUL": 4.51,
    "AUG": 5.02,
    "SEP": 5.55,
    "OCT": 5.48,
    "NOV": 5.3,
    "DEC": 4.86
  },
  {
    "LONG": -60.6,
    "LAT": 2.61,
    "CIDADE": "Cantá",
    "UF": "RORAIMA",
    "anual": 4.88,
    "JAN": 4.67,
    "FEB": 4.72,
    "MAR": 4.96,
    "APR": 4.65,
    "MAY": 4.27,
    "JUN": 4.48,
    "JUL": 4.51,
    "AUG": 5.04,
    "SEP": 5.62,
    "OCT": 5.53,
    "NOV": 5.29,
    "DEC": 4.81
  },
  {
    "LONG": -60.68,
    "LAT": 2.82,
    "CIDADE": "Boa Vista",
    "UF": "RORAIMA",
    "anual": 4.9,
    "JAN": 4.7,
    "FEB": 4.77,
    "MAR": 4.96,
    "APR": 4.76,
    "MAY": 4.36,
    "JUN": 4.51,
    "JUL": 4.61,
    "AUG": 5.09,
    "SEP": 5.53,
    "OCT": 5.49,
    "NOV": 5.22,
    "DEC": 4.78
  },
  {
    "LONG": -61.31,
    "LAT": 2.99,
    "CIDADE": "Alto Alegre",
    "UF": "RORAIMA",
    "anual": 4.86,
    "JAN": 4.64,
    "FEB": 4.79,
    "MAR": 4.96,
    "APR": 4.73,
    "MAY": 4.32,
    "JUN": 4.5,
    "JUL": 4.66,
    "AUG": 5.09,
    "SEP": 5.45,
    "OCT": 5.37,
    "NOV": 5.09,
    "DEC": 4.75
  },
  {
    "LONG": -59.83,
    "LAT": 3.36,
    "CIDADE": "Bonfim",
    "UF": "RORAIMA",
    "anual": 5.1,
    "JAN": 4.88,
    "FEB": 4.92,
    "MAR": 5.16,
    "APR": 4.99,
    "MAY": 4.56,
    "JUN": 4.74,
    "JUL": 4.79,
    "AUG": 5.26,
    "SEP": 5.75,
    "OCT": 5.74,
    "NOV": 5.45,
    "DEC": 4.96
  },
  {
    "LONG": -61.42,
    "LAT": 3.65,
    "CIDADE": "Amajari",
    "UF": "RORAIMA",
    "anual": 5.15,
    "JAN": 5.01,
    "FEB": 5.09,
    "MAR": 5.23,
    "APR": 5.08,
    "MAY": 4.65,
    "JUN": 4.71,
    "JUL": 4.86,
    "AUG": 5.36,
    "SEP": 5.76,
    "OCT": 5.62,
    "NOV": 5.38,
    "DEC": 5.01
  },
  {
    "LONG": -59.63,
    "LAT": 3.88,
    "CIDADE": "Normandia",
    "UF": "RORAIMA",
    "anual": 5.11,
    "JAN": 4.86,
    "FEB": 4.97,
    "MAR": 5.17,
    "APR": 5.03,
    "MAY": 4.7,
    "JUN": 4.83,
    "JUL": 4.93,
    "AUG": 5.4,
    "SEP": 5.78,
    "OCT": 5.49,
    "NOV": 5.28,
    "DEC": 4.89
  },
  {
    "LONG": -61.15,
    "LAT": 4.48,
    "CIDADE": "Pacaraima",
    "UF": "RORAIMA",
    "anual": 5.11,
    "JAN": 4.99,
    "FEB": 5.15,
    "MAR": 5.23,
    "APR": 5.22,
    "MAY": 4.75,
    "JUN": 4.59,
    "JUL": 4.73,
    "AUG": 5.13,
    "SEP": 5.63,
    "OCT": 5.57,
    "NOV": 5.33,
    "DEC": 4.98
  },
  {
    "LONG": -60.16,
    "LAT": 4.59,
    "CIDADE": "Uiramutã",
    "UF": "RORAIMA",
    "anual": 5.16,
    "JAN": 5.01,
    "FEB": 5.19,
    "MAR": 5.34,
    "APR": 5.19,
    "MAY": 4.75,
    "JUN": 4.7,
    "JUL": 4.94,
    "AUG": 5.32,
    "SEP": 5.72,
    "OCT": 5.63,
    "NOV": 5.34,
    "DEC": 4.84
  },
  {
    "LONG": -49.72,
    "LAT": -29.31,
    "CIDADE": "Passo de Torres",
    "UF": "SANTA CATARINA",
    "anual": 4.5,
    "JAN": 5.19,
    "FEB": 5.2,
    "MAR": 4.91,
    "APR": 4.69,
    "MAY": 4,
    "JUN": 3.45,
    "JUL": 3.68,
    "AUG": 4.25,
    "SEP": 3.91,
    "OCT": 4.28,
    "NOV": 5.21,
    "DEC": 5.26
  },
  {
    "LONG": -49.95,
    "LAT": -29.2,
    "CIDADE": "Praia Grande",
    "UF": "SANTA CATARINA",
    "anual": 4.21,
    "JAN": 4.6,
    "FEB": 4.66,
    "MAR": 4.47,
    "APR": 4.45,
    "MAY": 3.82,
    "JUN": 3.41,
    "JUL": 3.68,
    "AUG": 4.26,
    "SEP": 3.73,
    "OCT": 3.94,
    "NOV": 4.78,
    "DEC": 4.77
  },
  {
    "LONG": -49.8,
    "LAT": -29.22,
    "CIDADE": "São João do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.31,
    "JAN": 4.73,
    "FEB": 4.82,
    "MAR": 4.61,
    "APR": 4.55,
    "MAY": 3.88,
    "JUN": 3.44,
    "JUL": 3.72,
    "AUG": 4.27,
    "SEP": 3.83,
    "OCT": 4.05,
    "NOV": 4.9,
    "DEC": 4.91
  },
  {
    "LONG": -49.58,
    "LAT": -29.15,
    "CIDADE": "Balneário Gaivota",
    "UF": "SANTA CATARINA",
    "anual": 4.63,
    "JAN": 5.42,
    "FEB": 5.38,
    "MAR": 5.11,
    "APR": 4.82,
    "MAY": 4.02,
    "JUN": 3.49,
    "JUL": 3.7,
    "AUG": 4.27,
    "SEP": 3.98,
    "OCT": 4.51,
    "NOV": 5.42,
    "DEC": 5.49
  },
  {
    "LONG": -49.71,
    "LAT": -29.14,
    "CIDADE": "Santa Rosa do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.37,
    "JAN": 4.9,
    "FEB": 4.95,
    "MAR": 4.73,
    "APR": 4.6,
    "MAY": 3.93,
    "JUN": 3.43,
    "JUL": 3.7,
    "AUG": 4.27,
    "SEP": 3.83,
    "OCT": 4.11,
    "NOV": 5,
    "DEC": 5.01
  },
  {
    "LONG": -49.63,
    "LAT": -29.11,
    "CIDADE": "Sombrio",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.1,
    "FEB": 5.15,
    "MAR": 4.91,
    "APR": 4.68,
    "MAY": 4.01,
    "JUN": 3.44,
    "JUL": 3.72,
    "AUG": 4.29,
    "SEP": 3.9,
    "OCT": 4.27,
    "NOV": 5.19,
    "DEC": 5.23
  },
  {
    "LONG": -49.76,
    "LAT": -29,
    "CIDADE": "Jacinto Machado",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 4.85,
    "FEB": 4.93,
    "MAR": 4.72,
    "APR": 4.58,
    "MAY": 3.92,
    "JUN": 3.42,
    "JUL": 3.7,
    "AUG": 4.3,
    "SEP": 3.84,
    "OCT": 4.12,
    "NOV": 4.99,
    "DEC": 4.99
  },
  {
    "LONG": -49.64,
    "LAT": -28.99,
    "CIDADE": "Ermo",
    "UF": "SANTA CATARINA",
    "anual": 4.46,
    "JAN": 5,
    "FEB": 5.09,
    "MAR": 4.86,
    "APR": 4.66,
    "MAY": 3.99,
    "JUN": 3.43,
    "JUL": 3.71,
    "AUG": 4.31,
    "SEP": 3.87,
    "OCT": 4.24,
    "NOV": 5.13,
    "DEC": 5.17
  },
  {
    "LONG": -49.42,
    "LAT": -28.98,
    "CIDADE": "Balneário Arroio do Silva",
    "UF": "SANTA CATARINA",
    "anual": 4.6,
    "JAN": 5.35,
    "FEB": 5.33,
    "MAR": 5.02,
    "APR": 4.76,
    "MAY": 4.07,
    "JUN": 3.45,
    "JUL": 3.71,
    "AUG": 4.31,
    "SEP": 3.97,
    "OCT": 4.43,
    "NOV": 5.33,
    "DEC": 5.45
  },
  {
    "LONG": -49.68,
    "LAT": -28.93,
    "CIDADE": "Turvo",
    "UF": "SANTA CATARINA",
    "anual": 4.42,
    "JAN": 4.95,
    "FEB": 5.03,
    "MAR": 4.78,
    "APR": 4.65,
    "MAY": 3.96,
    "JUN": 3.43,
    "JUL": 3.72,
    "AUG": 4.31,
    "SEP": 3.86,
    "OCT": 4.18,
    "NOV": 5.07,
    "DEC": 5.09
  },
  {
    "LONG": -49.49,
    "LAT": -28.94,
    "CIDADE": "Araranguá",
    "UF": "SANTA CATARINA",
    "anual": 4.56,
    "JAN": 5.24,
    "FEB": 5.25,
    "MAR": 5,
    "APR": 4.7,
    "MAY": 4.06,
    "JUN": 3.45,
    "JUL": 3.72,
    "AUG": 4.32,
    "SEP": 3.94,
    "OCT": 4.35,
    "NOV": 5.27,
    "DEC": 5.37
  },
  {
    "LONG": -49.84,
    "LAT": -28.83,
    "CIDADE": "Timbé do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.19,
    "JAN": 4.51,
    "FEB": 4.63,
    "MAR": 4.49,
    "APR": 4.41,
    "MAY": 3.75,
    "JUN": 3.38,
    "JUL": 3.72,
    "AUG": 4.25,
    "SEP": 3.66,
    "OCT": 3.93,
    "NOV": 4.74,
    "DEC": 4.77
  },
  {
    "LONG": -49.72,
    "LAT": -28.8,
    "CIDADE": "Morro Grande",
    "UF": "SANTA CATARINA",
    "anual": 4.21,
    "JAN": 4.63,
    "FEB": 4.71,
    "MAR": 4.52,
    "APR": 4.42,
    "MAY": 3.82,
    "JUN": 3.4,
    "JUL": 3.67,
    "AUG": 4.25,
    "SEP": 3.71,
    "OCT": 3.92,
    "NOV": 4.78,
    "DEC": 4.76
  },
  {
    "LONG": -49.64,
    "LAT": -28.82,
    "CIDADE": "Meleiro",
    "UF": "SANTA CATARINA",
    "anual": 4.31,
    "JAN": 4.82,
    "FEB": 4.86,
    "MAR": 4.62,
    "APR": 4.51,
    "MAY": 3.89,
    "JUN": 3.41,
    "JUL": 3.7,
    "AUG": 4.27,
    "SEP": 3.79,
    "OCT": 4.05,
    "NOV": 4.93,
    "DEC": 4.92
  },
  {
    "LONG": -4.95,
    "LAT": -28.85,
    "CIDADE": "Maracajá",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.15,
    "FEB": 5.11,
    "MAR": 4.87,
    "APR": 4.64,
    "MAY": 4.02,
    "JUN": 3.45,
    "JUL": 3.72,
    "AUG": 4.33,
    "SEP": 3.91,
    "OCT": 4.28,
    "NOV": 5.19,
    "DEC": 5.19
  },
  {
    "LONG": -49.24,
    "LAT": -28.8,
    "CIDADE": "Balneário Rincão",
    "UF": "SANTA CATARINA",
    "anual": 4.57,
    "JAN": 5.34,
    "FEB": 5.29,
    "MAR": 4.95,
    "APR": 4.69,
    "MAY": 4.02,
    "JUN": 3.48,
    "JUL": 3.68,
    "AUG": 4.31,
    "SEP": 3.98,
    "OCT": 4.44,
    "NOV": 5.32,
    "DEC": 5.39
  },
  {
    "LONG": -49.48,
    "LAT": -28.75,
    "CIDADE": "Forquilhinha",
    "UF": "SANTA CATARINA",
    "anual": 4.37,
    "JAN": 4.86,
    "FEB": 4.89,
    "MAR": 4.72,
    "APR": 4.53,
    "MAY": 3.94,
    "JUN": 3.47,
    "JUL": 3.71,
    "AUG": 4.31,
    "SEP": 3.84,
    "OCT": 4.15,
    "NOV": 5.03,
    "DEC": 5.01
  },
  {
    "LONG": -49.37,
    "LAT": -28.67,
    "CIDADE": "Criciúma",
    "UF": "SANTA CATARINA",
    "anual": 4.45,
    "JAN": 5.02,
    "FEB": 5.02,
    "MAR": 4.8,
    "APR": 4.59,
    "MAY": 3.98,
    "JUN": 3.49,
    "JUL": 3.71,
    "AUG": 4.33,
    "SEP": 3.9,
    "OCT": 4.26,
    "NOV": 5.13,
    "DEC": 5.14
  },
  {
    "LONG": -49.31,
    "LAT": -28.71,
    "CIDADE": "Içara",
    "UF": "SANTA CATARINA",
    "anual": 4.45,
    "JAN": 5.02,
    "FEB": 5.02,
    "MAR": 4.8,
    "APR": 4.59,
    "MAY": 3.98,
    "JUN": 3.49,
    "JUL": 3.71,
    "AUG": 4.33,
    "SEP": 3.9,
    "OCT": 4.26,
    "NOV": 5.13,
    "DEC": 5.14
  },
  {
    "LONG": -49.22,
    "LAT": -28.65,
    "CIDADE": "Morro da Fumaça",
    "UF": "SANTA CATARINA",
    "anual": 4.52,
    "JAN": 5.2,
    "FEB": 5.16,
    "MAR": 4.87,
    "APR": 4.64,
    "MAY": 4.01,
    "JUN": 3.5,
    "JUL": 3.72,
    "AUG": 4.35,
    "SEP": 3.94,
    "OCT": 4.35,
    "NOV": 5.24,
    "DEC": 5.28
  },
  {
    "LONG": -4.95,
    "LAT": -28.63,
    "CIDADE": "Nova Veneza",
    "UF": "SANTA CATARINA",
    "anual": 4.21,
    "JAN": 4.52,
    "FEB": 4.64,
    "MAR": 4.46,
    "APR": 4.32,
    "MAY": 3.82,
    "JUN": 3.45,
    "JUL": 3.67,
    "AUG": 4.27,
    "SEP": 3.71,
    "OCT": 3.97,
    "NOV": 4.8,
    "DEC": 4.83
  },
  {
    "LONG": -49.43,
    "LAT": -2.86,
    "CIDADE": "Siderópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.27,
    "JAN": 4.66,
    "FEB": 4.72,
    "MAR": 4.57,
    "APR": 4.46,
    "MAY": 3.85,
    "JUN": 3.44,
    "JUL": 3.7,
    "AUG": 4.29,
    "SEP": 3.79,
    "OCT": 4.04,
    "NOV": 4.89,
    "DEC": 4.86
  },
  {
    "LONG": -4.93,
    "LAT": -28.6,
    "CIDADE": "Cocal do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.37,
    "JAN": 4.87,
    "FEB": 4.89,
    "MAR": 4.71,
    "APR": 4.53,
    "MAY": 3.92,
    "JUN": 3.48,
    "JUL": 3.71,
    "AUG": 4.33,
    "SEP": 3.84,
    "OCT": 4.16,
    "NOV": 5.02,
    "DEC": 5.02
  },
  {
    "LONG": -4.92,
    "LAT": -28.55,
    "CIDADE": "Treze de Maio",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.13,
    "FEB": 5.1,
    "MAR": 4.84,
    "APR": 4.58,
    "MAY": 3.98,
    "JUN": 3.5,
    "JUL": 3.72,
    "AUG": 4.35,
    "SEP": 3.9,
    "OCT": 4.31,
    "NOV": 5.2,
    "DEC": 5.26
  },
  {
    "LONG": -49.13,
    "LAT": -28.63,
    "CIDADE": "Sangão",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.13,
    "FEB": 5.1,
    "MAR": 4.84,
    "APR": 4.58,
    "MAY": 3.98,
    "JUN": 3.5,
    "JUL": 3.72,
    "AUG": 4.35,
    "SEP": 3.9,
    "OCT": 4.31,
    "NOV": 5.2,
    "DEC": 5.26
  },
  {
    "LONG": "#VALOR!",
    "LAT": -28.62,
    "CIDADE": "Jaguaruna",
    "UF": "SANTA CATARINA",
    "anual": 4.53,
    "JAN": 5.25,
    "FEB": 5.18,
    "MAR": 4.88,
    "APR": 4.59,
    "MAY": 4.01,
    "JUN": 3.49,
    "JUL": 3.73,
    "AUG": 4.35,
    "SEP": 3.94,
    "OCT": 4.35,
    "NOV": 5.24,
    "DEC": 5.34
  },
  {
    "LONG": -49.46,
    "LAT": -28.51,
    "CIDADE": "Treviso",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.59,
    "FEB": 4.62,
    "MAR": 4.5,
    "APR": 4.39,
    "MAY": 3.81,
    "JUN": 3.47,
    "JUL": 3.67,
    "AUG": 4.3,
    "SEP": 3.74,
    "OCT": 3.96,
    "NOV": 4.79,
    "DEC": 4.81
  },
  {
    "LONG": -49.32,
    "LAT": -28.52,
    "CIDADE": "Urussanga",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 4.84,
    "FEB": 4.88,
    "MAR": 4.7,
    "APR": 4.53,
    "MAY": 3.9,
    "JUN": 3.48,
    "JUL": 3.68,
    "AUG": 4.33,
    "SEP": 3.84,
    "OCT": 4.14,
    "NOV": 4.99,
    "DEC": 5.03
  },
  {
    "LONG": -49.01,
    "LAT": -28.47,
    "CIDADE": "Tubarão",
    "UF": "SANTA CATARINA",
    "anual": 4.5,
    "JAN": 5.12,
    "FEB": 5.13,
    "MAR": 4.85,
    "APR": 4.56,
    "MAY": 3.99,
    "JUN": 3.52,
    "JUL": 3.73,
    "AUG": 4.38,
    "SEP": 3.95,
    "OCT": 4.33,
    "NOV": 5.18,
    "DEC": 5.28
  },
  {
    "LONG": -48.78,
    "LAT": -28.48,
    "CIDADE": "Laguna",
    "UF": "SANTA CATARINA",
    "anual": 4.69,
    "JAN": 5.51,
    "FEB": 5.41,
    "MAR": 5.16,
    "APR": 4.7,
    "MAY": 4.06,
    "JUN": 3.53,
    "JUL": 3.75,
    "AUG": 4.33,
    "SEP": 4.08,
    "OCT": 4.59,
    "NOV": 5.49,
    "DEC": 5.6
  },
  {
    "LONG": -49.4,
    "LAT": -28.39,
    "CIDADE": "Lauro Muller",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.59,
    "FEB": 4.6,
    "MAR": 4.46,
    "APR": 4.36,
    "MAY": 3.78,
    "JUN": 3.49,
    "JUL": 3.67,
    "AUG": 4.31,
    "SEP": 3.79,
    "OCT": 3.97,
    "NOV": 4.78,
    "DEC": 4.79
  },
  {
    "LONG": -49.2,
    "LAT": -28.43,
    "CIDADE": "Pedras Grandes",
    "UF": "SANTA CATARINA",
    "anual": 4.48,
    "JAN": 5.06,
    "FEB": 5.07,
    "MAR": 4.85,
    "APR": 4.56,
    "MAY": 3.93,
    "JUN": 3.52,
    "JUL": 3.72,
    "AUG": 4.39,
    "SEP": 3.96,
    "OCT": 4.32,
    "NOV": 5.16,
    "DEC": 5.21
  },
  {
    "LONG": -48.96,
    "LAT": -28.45,
    "CIDADE": "Capivari de Baixo",
    "UF": "SANTA CATARINA",
    "anual": 4.54,
    "JAN": 5.2,
    "FEB": 5.21,
    "MAR": 4.91,
    "APR": 4.6,
    "MAY": 3.97,
    "JUN": 3.54,
    "JUL": 3.77,
    "AUG": 4.39,
    "SEP": 3.98,
    "OCT": 4.39,
    "NOV": 5.22,
    "DEC": 5.33
  },
  {
    "LONG": -48.88,
    "LAT": -2.84,
    "CIDADE": "Pescaria Brava",
    "UF": "SANTA CATARINA",
    "anual": 4.55,
    "JAN": 5.25,
    "FEB": 5.21,
    "MAR": 4.95,
    "APR": 4.59,
    "MAY": 3.99,
    "JUN": 3.5,
    "JUL": 3.74,
    "AUG": 4.35,
    "SEP": 3.98,
    "OCT": 4.38,
    "NOV": 5.27,
    "DEC": 5.37
  },
  {
    "LONG": -49.95,
    "LAT": -28.29,
    "CIDADE": "São Joaquim",
    "UF": "SANTA CATARINA",
    "anual": 4.73,
    "JAN": 5.18,
    "FEB": 5.25,
    "MAR": 5.11,
    "APR": 4.74,
    "MAY": 3.98,
    "JUN": 3.65,
    "JUL": 3.96,
    "AUG": 4.64,
    "SEP": 4.32,
    "OCT": 4.81,
    "NOV": 5.52,
    "DEC": 5.54
  },
  {
    "LONG": -49.64,
    "LAT": -28.34,
    "CIDADE": "Bom Jardim da Serra",
    "UF": "SANTA CATARINA",
    "anual": 4.63,
    "JAN": 4.93,
    "FEB": 5.08,
    "MAR": 4.94,
    "APR": 4.65,
    "MAY": 3.98,
    "JUN": 3.72,
    "JUL": 3.96,
    "AUG": 4.65,
    "SEP": 4.28,
    "OCT": 4.68,
    "NOV": 5.34,
    "DEC": 5.33
  },
  {
    "LONG": -49.3,
    "LAT": -28.35,
    "CIDADE": "Orleans",
    "UF": "SANTA CATARINA",
    "anual": 4.3,
    "JAN": 4.69,
    "FEB": 4.72,
    "MAR": 4.6,
    "APR": 4.45,
    "MAY": 3.85,
    "JUN": 3.49,
    "JUL": 3.69,
    "AUG": 4.35,
    "SEP": 3.89,
    "OCT": 4.1,
    "NOV": 4.92,
    "DEC": 4.89
  },
  {
    "LONG": -49.17,
    "LAT": -28.27,
    "CIDADE": "Braço do Norte",
    "UF": "SANTA CATARINA",
    "anual": 4.47,
    "JAN": 5.06,
    "FEB": 5.03,
    "MAR": 4.82,
    "APR": 4.57,
    "MAY": 3.93,
    "JUN": 3.52,
    "JUL": 3.73,
    "AUG": 4.41,
    "SEP": 3.97,
    "OCT": 4.32,
    "NOV": 5.14,
    "DEC": 5.2
  },
  {
    "LONG": -49.18,
    "LAT": -28.31,
    "CIDADE": "São Ludgero",
    "UF": "SANTA CATARINA",
    "anual": 4.47,
    "JAN": 5.06,
    "FEB": 5.03,
    "MAR": 4.82,
    "APR": 4.57,
    "MAY": 3.93,
    "JUN": 3.52,
    "JUL": 3.73,
    "AUG": 4.41,
    "SEP": 3.97,
    "OCT": 4.32,
    "NOV": 5.14,
    "DEC": 5.2
  },
  {
    "LONG": -49.04,
    "LAT": -28.32,
    "CIDADE": "Gravatal",
    "UF": "SANTA CATARINA",
    "anual": 4.51,
    "JAN": 5.12,
    "FEB": 5.09,
    "MAR": 4.83,
    "APR": 4.59,
    "MAY": 3.97,
    "JUN": 3.54,
    "JUL": 3.76,
    "AUG": 4.41,
    "SEP": 3.99,
    "OCT": 4.36,
    "NOV": 5.2,
    "DEC": 5.24
  },
  {
    "LONG": -48.82,
    "LAT": -28.33,
    "CIDADE": "Imaruí",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.13,
    "FEB": 5.12,
    "MAR": 4.85,
    "APR": 4.52,
    "MAY": 3.95,
    "JUN": 3.49,
    "JUL": 3.73,
    "AUG": 4.36,
    "SEP": 3.95,
    "OCT": 4.28,
    "NOV": 5.17,
    "DEC": 5.28
  },
  {
    "LONG": -49.23,
    "LAT": -28.18,
    "CIDADE": "Grão Pará",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 4.87,
    "FEB": 4.84,
    "MAR": 4.7,
    "APR": 4.49,
    "MAY": 3.87,
    "JUN": 3.54,
    "JUL": 3.72,
    "AUG": 4.36,
    "SEP": 3.9,
    "OCT": 4.14,
    "NOV": 4.97,
    "DEC": 4.95
  },
  {
    "LONG": -49.02,
    "LAT": -28.25,
    "CIDADE": "Armazém",
    "UF": "SANTA CATARINA",
    "anual": 4.51,
    "JAN": 5.1,
    "FEB": 5.09,
    "MAR": 4.87,
    "APR": 4.59,
    "MAY": 3.96,
    "JUN": 3.59,
    "JUL": 3.77,
    "AUG": 4.42,
    "SEP": 3.99,
    "OCT": 4.36,
    "NOV": 5.16,
    "DEC": 5.21
  },
  {
    "LONG": -48.99,
    "LAT": -28.16,
    "CIDADE": "São Martinho",
    "UF": "SANTA CATARINA",
    "anual": 4.53,
    "JAN": 5.17,
    "FEB": 5.14,
    "MAR": 4.88,
    "APR": 4.6,
    "MAY": 3.97,
    "JUN": 3.58,
    "JUL": 3.78,
    "AUG": 4.41,
    "SEP": 4,
    "OCT": 4.37,
    "NOV": 5.2,
    "DEC": 5.26
  },
  {
    "LONG": -48.67,
    "LAT": -28.23,
    "CIDADE": "Imbituba",
    "UF": "SANTA CATARINA",
    "anual": 4.56,
    "JAN": 5.28,
    "FEB": 5.24,
    "MAR": 5.02,
    "APR": 4.56,
    "MAY": 4.03,
    "JUN": 3.52,
    "JUL": 3.74,
    "AUG": 4.31,
    "SEP": 4.02,
    "OCT": 4.39,
    "NOV": 5.26,
    "DEC": 5.38
  },
  {
    "LONG": -49.11,
    "LAT": -28.12,
    "CIDADE": "Rio Fortuna",
    "UF": "SANTA CATARINA",
    "anual": 4.43,
    "JAN": 4.93,
    "FEB": 4.99,
    "MAR": 4.81,
    "APR": 4.55,
    "MAY": 3.88,
    "JUN": 3.54,
    "JUL": 3.75,
    "AUG": 4.4,
    "SEP": 3.93,
    "OCT": 4.24,
    "NOV": 5.06,
    "DEC": 5.07
  },
  {
    "LONG": -49.87,
    "LAT": -27.96,
    "CIDADE": "Urupema",
    "UF": "SANTA CATARINA",
    "anual": 4.59,
    "JAN": 5.15,
    "FEB": 5.13,
    "MAR": 4.94,
    "APR": 4.57,
    "MAY": 3.91,
    "JUN": 3.6,
    "JUL": 3.85,
    "AUG": 4.59,
    "SEP": 4.26,
    "OCT": 4.49,
    "NOV": 5.31,
    "DEC": 5.29
  },
  {
    "LONG": -4.96,
    "LAT": -28.02,
    "CIDADE": "Urubici",
    "UF": "SANTA CATARINA",
    "anual": 4.48,
    "JAN": 5.04,
    "FEB": 5.11,
    "MAR": 4.88,
    "APR": 4.55,
    "MAY": 3.85,
    "JUN": 3.48,
    "JUL": 3.69,
    "AUG": 4.41,
    "SEP": 4.11,
    "OCT": 4.3,
    "NOV": 5.15,
    "DEC": 5.16
  },
  {
    "LONG": -49.13,
    "LAT": -28.03,
    "CIDADE": "Santa Rosa de Lima",
    "UF": "SANTA CATARINA",
    "anual": 4.38,
    "JAN": 4.83,
    "FEB": 4.89,
    "MAR": 4.72,
    "APR": 4.48,
    "MAY": 3.85,
    "JUN": 3.54,
    "JUL": 3.73,
    "AUG": 4.39,
    "SEP": 3.93,
    "OCT": 4.18,
    "NOV": 5.01,
    "DEC": 5.01
  },
  {
    "LONG": -48.67,
    "LAT": -27.96,
    "CIDADE": "Paulo Lopes",
    "UF": "SANTA CATARINA",
    "anual": 4.39,
    "JAN": 5.04,
    "FEB": 4.96,
    "MAR": 4.75,
    "APR": 4.39,
    "MAY": 3.97,
    "JUN": 3.51,
    "JUL": 3.67,
    "AUG": 4.31,
    "SEP": 3.9,
    "OCT": 4.13,
    "NOV": 4.97,
    "DEC": 5.09
  },
  {
    "LONG": -48.62,
    "LAT": -28.03,
    "CIDADE": "Garopaba",
    "UF": "SANTA CATARINA",
    "anual": 4.39,
    "JAN": 5.04,
    "FEB": 4.96,
    "MAR": 4.75,
    "APR": 4.39,
    "MAY": 3.97,
    "JUN": 3.51,
    "JUL": 3.67,
    "AUG": 4.31,
    "SEP": 3.9,
    "OCT": 4.13,
    "NOV": 4.97,
    "DEC": 5.09
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.79,
    "CIDADE": "Campo Belo do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.6,
    "JAN": 5.29,
    "FEB": 5.29,
    "MAR": 5.03,
    "APR": 4.6,
    "MAY": 3.82,
    "JUN": 3.44,
    "JUL": 3.73,
    "AUG": 4.5,
    "SEP": 4.15,
    "OCT": 4.61,
    "NOV": 5.34,
    "DEC": 5.38
  },
  {
    "LONG": -50.51,
    "LAT": -27.94,
    "CIDADE": "Capão Alto",
    "UF": "SANTA CATARINA",
    "anual": 4.53,
    "JAN": 5.19,
    "FEB": 5.27,
    "MAR": 4.93,
    "APR": 4.52,
    "MAY": 3.74,
    "JUN": 3.37,
    "JUL": 3.7,
    "AUG": 4.48,
    "SEP": 4.1,
    "OCT": 4.5,
    "NOV": 5.27,
    "DEC": 5.32
  },
  {
    "LONG": -50.1,
    "LAT": -27.92,
    "CIDADE": "Painel",
    "UF": "SANTA CATARINA",
    "anual": 4.53,
    "JAN": 5.14,
    "FEB": 5.17,
    "MAR": 4.97,
    "APR": 4.52,
    "MAY": 3.78,
    "JUN": 3.43,
    "JUL": 3.72,
    "AUG": 4.42,
    "SEP": 4.14,
    "OCT": 4.46,
    "NOV": 5.27,
    "DEC": 5.27
  },
  {
    "LONG": -49.78,
    "LAT": -27.86,
    "CIDADE": "Rio Rufino",
    "UF": "SANTA CATARINA",
    "anual": 4.42,
    "JAN": 5.04,
    "FEB": 5.12,
    "MAR": 4.83,
    "APR": 4.49,
    "MAY": 3.81,
    "JUN": 3.42,
    "JUL": 3.63,
    "AUG": 4.34,
    "SEP": 4,
    "OCT": 4.2,
    "NOV": 5.06,
    "DEC": 5.12
  },
  {
    "LONG": -49.13,
    "LAT": -27.9,
    "CIDADE": "Anitápolis",
    "UF": "SANTA CATARINA",
    "anual": 4.33,
    "JAN": 4.81,
    "FEB": 4.9,
    "MAR": 4.65,
    "APR": 4.4,
    "MAY": 3.79,
    "JUN": 3.49,
    "JUL": 3.68,
    "AUG": 4.34,
    "SEP": 3.85,
    "OCT": 4.08,
    "NOV": 4.93,
    "DEC": 5.01
  },
  {
    "LONG": -4.89,
    "LAT": -27.9,
    "CIDADE": "São Bonifácio",
    "UF": "SANTA CATARINA",
    "anual": 4.27,
    "JAN": 4.8,
    "FEB": 4.8,
    "MAR": 4.6,
    "APR": 4.32,
    "MAY": 3.83,
    "JUN": 3.51,
    "JUL": 3.66,
    "AUG": 4.24,
    "SEP": 3.76,
    "OCT": 3.99,
    "NOV": 4.81,
    "DEC": 4.89
  },
  {
    "LONG": -50.87,
    "LAT": -27.79,
    "CIDADE": "Cerro Negro",
    "UF": "SANTA CATARINA",
    "anual": 4.63,
    "JAN": 5.27,
    "FEB": 5.31,
    "MAR": 5.09,
    "APR": 4.65,
    "MAY": 3.8,
    "JUN": 3.44,
    "JUL": 3.76,
    "AUG": 4.52,
    "SEP": 4.21,
    "OCT": 4.65,
    "NOV": 5.38,
    "DEC": 5.43
  },
  {
    "LONG": -50.33,
    "LAT": -27.82,
    "CIDADE": "Lages",
    "UF": "SANTA CATARINA",
    "anual": 4.45,
    "JAN": 5.12,
    "FEB": 5.22,
    "MAR": 4.88,
    "APR": 4.44,
    "MAY": 3.7,
    "JUN": 3.33,
    "JUL": 3.6,
    "AUG": 4.35,
    "SEP": 4.03,
    "OCT": 4.34,
    "NOV": 5.14,
    "DEC": 5.21
  },
  {
    "LONG": -49.49,
    "LAT": -27.8,
    "CIDADE": "Bom Retiro",
    "UF": "SANTA CATARINA",
    "anual": 4.41,
    "JAN": 5.07,
    "FEB": 5.09,
    "MAR": 4.87,
    "APR": 4.46,
    "MAY": 3.8,
    "JUN": 3.39,
    "JUL": 3.62,
    "AUG": 4.33,
    "SEP": 3.96,
    "OCT": 4.14,
    "NOV": 5.05,
    "DEC": 5.11
  },
  {
    "LONG": -51.13,
    "LAT": -27.69,
    "CIDADE": "Anita Garibaldi",
    "UF": "SANTA CATARINA",
    "anual": 4.75,
    "JAN": 5.41,
    "FEB": 5.37,
    "MAR": 5.24,
    "APR": 4.8,
    "MAY": 3.89,
    "JUN": 3.54,
    "JUL": 3.89,
    "AUG": 4.61,
    "SEP": 4.31,
    "OCT": 4.82,
    "NOV": 5.55,
    "DEC": 5.52
  },
  {
    "LONG": -50.57,
    "LAT": -27.66,
    "CIDADE": "São José do Cerrito",
    "UF": "SANTA CATARINA",
    "anual": 4.53,
    "JAN": 5.12,
    "FEB": 5.19,
    "MAR": 4.93,
    "APR": 4.54,
    "MAY": 3.75,
    "JUN": 3.44,
    "JUL": 3.7,
    "AUG": 4.47,
    "SEP": 4.15,
    "OCT": 4.48,
    "NOV": 5.24,
    "DEC": 5.3
  },
  {
    "LONG": -49.94,
    "LAT": -2.77,
    "CIDADE": "Bocaina do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.37,
    "JAN": 5.05,
    "FEB": 5.12,
    "MAR": 4.87,
    "APR": 4.38,
    "MAY": 3.74,
    "JUN": 3.31,
    "JUL": 3.55,
    "AUG": 4.25,
    "SEP": 3.93,
    "OCT": 4.12,
    "NOV": 5.04,
    "DEC": 5.1
  },
  {
    "LONG": -49.33,
    "LAT": -27.7,
    "CIDADE": "Alfredo Wagner",
    "UF": "SANTA CATARINA",
    "anual": 4.42,
    "JAN": 5.13,
    "FEB": 5.12,
    "MAR": 5,
    "APR": 4.53,
    "MAY": 3.84,
    "JUN": 3.37,
    "JUL": 3.58,
    "AUG": 4.29,
    "SEP": 3.91,
    "OCT": 4.1,
    "NOV": 5.06,
    "DEC": 5.16
  },
  {
    "LONG": -49.02,
    "LAT": -27.67,
    "CIDADE": "Rancho Queimado",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.77,
    "FEB": 4.83,
    "MAR": 4.62,
    "APR": 4.33,
    "MAY": 3.82,
    "JUN": 3.49,
    "JUL": 3.57,
    "AUG": 4.22,
    "SEP": 3.75,
    "OCT": 3.92,
    "NOV": 4.76,
    "DEC": 4.87
  },
  {
    "LONG": -48.82,
    "LAT": -27.7,
    "CIDADE": "Águas Mornas",
    "UF": "SANTA CATARINA",
    "anual": 4.26,
    "JAN": 4.83,
    "FEB": 4.87,
    "MAR": 4.66,
    "APR": 4.33,
    "MAY": 3.9,
    "JUN": 3.53,
    "JUL": 3.59,
    "AUG": 4.21,
    "SEP": 3.74,
    "OCT": 3.88,
    "NOV": 4.74,
    "DEC": 4.88
  },
  {
    "LONG": -48.78,
    "LAT": -27.69,
    "CIDADE": "Santo Amaro da Imperatriz",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 4.91,
    "FEB": 5.02,
    "MAR": 4.75,
    "APR": 4.36,
    "MAY": 3.95,
    "JUN": 3.54,
    "JUL": 3.62,
    "AUG": 4.23,
    "SEP": 3.79,
    "OCT": 4.01,
    "NOV": 4.83,
    "DEC": 5
  },
  {
    "LONG": -51.34,
    "LAT": -27.63,
    "CIDADE": "Celso Ramos",
    "UF": "SANTA CATARINA",
    "anual": 4.77,
    "JAN": 5.42,
    "FEB": 5.4,
    "MAR": 5.22,
    "APR": 4.84,
    "MAY": 3.94,
    "JUN": 3.58,
    "JUL": 3.92,
    "AUG": 4.65,
    "SEP": 4.32,
    "OCT": 4.84,
    "NOV": 5.54,
    "DEC": 5.53
  },
  {
    "LONG": -51.02,
    "LAT": -27.61,
    "CIDADE": "Abdon Batista",
    "UF": "SANTA CATARINA",
    "anual": 4.67,
    "JAN": 5.33,
    "FEB": 5.29,
    "MAR": 5.17,
    "APR": 4.72,
    "MAY": 3.83,
    "JUN": 3.49,
    "JUL": 3.81,
    "AUG": 4.58,
    "SEP": 4.26,
    "OCT": 4.72,
    "NOV": 5.43,
    "DEC": 5.38
  },
  {
    "LONG": -50.36,
    "LAT": -27.59,
    "CIDADE": "Correia Pinto",
    "UF": "SANTA CATARINA",
    "anual": 4.42,
    "JAN": 5.05,
    "FEB": 5.16,
    "MAR": 4.91,
    "APR": 4.41,
    "MAY": 3.71,
    "JUN": 3.32,
    "JUL": 3.55,
    "AUG": 4.31,
    "SEP": 4.01,
    "OCT": 4.32,
    "NOV": 5.13,
    "DEC": 5.21
  },
  {
    "LONG": -50.16,
    "LAT": -27.58,
    "CIDADE": "Palmeira",
    "UF": "SANTA CATARINA",
    "anual": 4.33,
    "JAN": 5.02,
    "FEB": 5.08,
    "MAR": 4.75,
    "APR": 4.32,
    "MAY": 3.68,
    "JUN": 3.22,
    "JUL": 3.52,
    "AUG": 4.21,
    "SEP": 3.9,
    "OCT": 4.11,
    "NOV": 5.01,
    "DEC": 5.1
  },
  {
    "LONG": -49.55,
    "LAT": -2.76,
    "CIDADE": "Chapadão do Lageado",
    "UF": "SANTA CATARINA",
    "anual": 4.39,
    "JAN": 5.15,
    "FEB": 5.21,
    "MAR": 4.93,
    "APR": 4.46,
    "MAY": 3.8,
    "JUN": 3.26,
    "JUL": 3.48,
    "AUG": 4.2,
    "SEP": 3.87,
    "OCT": 4.12,
    "NOV": 5,
    "DEC": 5.18
  },
  {
    "LONG": -48.99,
    "LAT": -27.57,
    "CIDADE": "Angelina",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.84,
    "FEB": 4.93,
    "MAR": 4.7,
    "APR": 4.31,
    "MAY": 3.86,
    "JUN": 3.44,
    "JUL": 3.57,
    "AUG": 4.18,
    "SEP": 3.71,
    "OCT": 3.89,
    "NOV": 4.66,
    "DEC": 4.91
  },
  {
    "LONG": -48.81,
    "LAT": -2.76,
    "CIDADE": "São Pedro de Alcântara",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.79,
    "FEB": 4.83,
    "MAR": 4.68,
    "APR": 4.3,
    "MAY": 3.87,
    "JUN": 3.48,
    "JUL": 3.54,
    "AUG": 4.16,
    "SEP": 3.67,
    "OCT": 3.81,
    "NOV": 4.63,
    "DEC": 4.85
  },
  {
    "LONG": -48.67,
    "LAT": -2.76,
    "CIDADE": "Palhoça",
    "UF": "SANTA CATARINA",
    "anual": 4.39,
    "JAN": 5.12,
    "FEB": 5.14,
    "MAR": 4.85,
    "APR": 4.45,
    "MAY": 3.97,
    "JUN": 3.49,
    "JUL": 3.58,
    "AUG": 4.2,
    "SEP": 3.78,
    "OCT": 4.07,
    "NOV": 4.92,
    "DEC": 5.11
  },
  {
    "LONG": -4.86,
    "LAT": -27.59,
    "CIDADE": "São José",
    "UF": "SANTA CATARINA",
    "anual": 4.39,
    "JAN": 5.12,
    "FEB": 5.14,
    "MAR": 4.85,
    "APR": 4.45,
    "MAY": 3.97,
    "JUN": 3.49,
    "JUL": 3.58,
    "AUG": 4.2,
    "SEP": 3.78,
    "OCT": 4.07,
    "NOV": 4.92,
    "DEC": 5.11
  },
  {
    "LONG": -48.55,
    "LAT": -27.6,
    "CIDADE": "Florianópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 5.23,
    "FEB": 5.23,
    "MAR": 5.01,
    "APR": 4.51,
    "MAY": 4,
    "JUN": 3.53,
    "JUL": 3.61,
    "AUG": 4.22,
    "SEP": 3.89,
    "OCT": 4.29,
    "NOV": 5.06,
    "DEC": 5.25
  },
  {
    "LONG": -51.55,
    "LAT": -27.45,
    "CIDADE": "Zortéa",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.47,
    "FEB": 5.44,
    "MAR": 5.35,
    "APR": 4.88,
    "MAY": 4.02,
    "JUN": 3.64,
    "JUL": 3.92,
    "AUG": 4.66,
    "SEP": 4.37,
    "OCT": 4.94,
    "NOV": 5.59,
    "DEC": 5.57
  },
  {
    "LONG": -50.97,
    "LAT": -27.49,
    "CIDADE": "Vargem",
    "UF": "SANTA CATARINA",
    "anual": 4.6,
    "JAN": 5.15,
    "FEB": 5.22,
    "MAR": 5.08,
    "APR": 4.64,
    "MAY": 3.79,
    "JUN": 3.46,
    "JUL": 3.76,
    "AUG": 4.54,
    "SEP": 4.25,
    "OCT": 4.63,
    "NOV": 5.34,
    "DEC": 5.34
  },
  {
    "LONG": -50.38,
    "LAT": -2.75,
    "CIDADE": "Ponte Alta",
    "UF": "SANTA CATARINA",
    "anual": 4.44,
    "JAN": 5.03,
    "FEB": 5.06,
    "MAR": 4.92,
    "APR": 4.44,
    "MAY": 3.74,
    "JUN": 3.4,
    "JUL": 3.6,
    "AUG": 4.41,
    "SEP": 4.06,
    "OCT": 4.32,
    "NOV": 5.15,
    "DEC": 5.19
  },
  {
    "LONG": -50.12,
    "LAT": -27.48,
    "CIDADE": "Otacílio Costa",
    "UF": "SANTA CATARINA",
    "anual": 4.31,
    "JAN": 4.95,
    "FEB": 5.03,
    "MAR": 4.79,
    "APR": 4.34,
    "MAY": 3.68,
    "JUN": 3.24,
    "JUL": 3.5,
    "AUG": 4.21,
    "SEP": 3.88,
    "OCT": 4.06,
    "NOV": 5,
    "DEC": 5.05
  },
  {
    "LONG": -49.69,
    "LAT": -27.54,
    "CIDADE": "Petrolândia",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 5.09,
    "FEB": 5.14,
    "MAR": 4.95,
    "APR": 4.39,
    "MAY": 3.76,
    "JUN": 3.21,
    "JUL": 3.39,
    "AUG": 4.15,
    "SEP": 3.85,
    "OCT": 4.08,
    "NOV": 4.98,
    "DEC": 5.08
  },
  {
    "LONG": -49.42,
    "LAT": -27.49,
    "CIDADE": "Imbuia",
    "UF": "SANTA CATARINA",
    "anual": 4.31,
    "JAN": 5.06,
    "FEB": 5.06,
    "MAR": 4.83,
    "APR": 4.37,
    "MAY": 3.76,
    "JUN": 3.23,
    "JUL": 3.47,
    "AUG": 4.18,
    "SEP": 3.84,
    "OCT": 4,
    "NOV": 4.92,
    "DEC": 5.02
  },
  {
    "LONG": -49.28,
    "LAT": -27.51,
    "CIDADE": "Leoberto Leal",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.93,
    "FEB": 4.99,
    "MAR": 4.74,
    "APR": 4.37,
    "MAY": 3.82,
    "JUN": 3.34,
    "JUL": 3.52,
    "AUG": 4.2,
    "SEP": 3.8,
    "OCT": 3.94,
    "NOV": 4.83,
    "DEC": 4.95
  },
  {
    "LONG": -48.77,
    "LAT": -27.52,
    "CIDADE": "Antônio Carlos",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.84,
    "FEB": 4.92,
    "MAR": 4.7,
    "APR": 4.31,
    "MAY": 3.9,
    "JUN": 3.49,
    "JUL": 3.54,
    "AUG": 4.15,
    "SEP": 3.7,
    "OCT": 3.86,
    "NOV": 4.69,
    "DEC": 4.88
  },
  {
    "LONG": -48.66,
    "LAT": -27.5,
    "CIDADE": "Biguaçu",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 5.06,
    "FEB": 5.1,
    "MAR": 4.83,
    "APR": 4.43,
    "MAY": 3.95,
    "JUN": 3.49,
    "JUL": 3.57,
    "AUG": 4.18,
    "SEP": 3.77,
    "OCT": 4.03,
    "NOV": 4.87,
    "DEC": 5.03
  },
  {
    "LONG": -51.9,
    "LAT": -27.38,
    "CIDADE": "Peritiba",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.58,
    "FEB": 5.48,
    "MAR": 5.45,
    "APR": 4.84,
    "MAY": 4.01,
    "JUN": 3.43,
    "JUL": 3.84,
    "AUG": 4.64,
    "SEP": 4.38,
    "OCT": 4.99,
    "NOV": 5.65,
    "DEC": 5.68
  },
  {
    "LONG": -51.9,
    "LAT": -27.43,
    "CIDADE": "Alto Bela Vista",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.58,
    "FEB": 5.48,
    "MAR": 5.45,
    "APR": 4.84,
    "MAY": 4.01,
    "JUN": 3.43,
    "JUL": 3.84,
    "AUG": 4.64,
    "SEP": 4.38,
    "OCT": 4.99,
    "NOV": 5.65,
    "DEC": 5.68
  },
  {
    "LONG": -51.77,
    "LAT": -27.42,
    "CIDADE": "Piratuba",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.44,
    "FEB": 5.42,
    "MAR": 5.43,
    "APR": 4.9,
    "MAY": 4.03,
    "JUN": 3.59,
    "JUL": 3.94,
    "AUG": 4.68,
    "SEP": 4.39,
    "OCT": 4.96,
    "NOV": 5.63,
    "DEC": 5.6
  },
  {
    "LONG": -51.78,
    "LAT": -27.4,
    "CIDADE": "Ipira",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.44,
    "FEB": 5.42,
    "MAR": 5.43,
    "APR": 4.9,
    "MAY": 4.03,
    "JUN": 3.59,
    "JUL": 3.94,
    "AUG": 4.68,
    "SEP": 4.39,
    "OCT": 4.96,
    "NOV": 5.63,
    "DEC": 5.6
  },
  {
    "LONG": -5.12,
    "LAT": -27.4,
    "CIDADE": "Campos Novos",
    "UF": "SANTA CATARINA",
    "anual": 4.77,
    "JAN": 5.31,
    "FEB": 5.34,
    "MAR": 5.22,
    "APR": 4.81,
    "MAY": 3.95,
    "JUN": 3.68,
    "JUL": 3.95,
    "AUG": 4.7,
    "SEP": 4.41,
    "OCT": 4.85,
    "NOV": 5.49,
    "DEC": 5.47
  },
  {
    "LONG": -49.88,
    "LAT": -27.36,
    "CIDADE": "Braço do Trombudo",
    "UF": "SANTA CATARINA",
    "anual": 4.3,
    "JAN": 5.04,
    "FEB": 5.13,
    "MAR": 4.88,
    "APR": 4.34,
    "MAY": 3.73,
    "JUN": 3.15,
    "JUL": 3.32,
    "AUG": 4.08,
    "SEP": 3.83,
    "OCT": 4.03,
    "NOV": 4.94,
    "DEC": 5.06
  },
  {
    "LONG": -49.82,
    "LAT": -27.41,
    "CIDADE": "Agrolândia",
    "UF": "SANTA CATARINA",
    "anual": 4.3,
    "JAN": 5.04,
    "FEB": 5.13,
    "MAR": 4.88,
    "APR": 4.34,
    "MAY": 3.73,
    "JUN": 3.15,
    "JUL": 3.32,
    "AUG": 4.08,
    "SEP": 3.83,
    "OCT": 4.03,
    "NOV": 4.94,
    "DEC": 5.06
  },
  {
    "LONG": -49.78,
    "LAT": -27.42,
    "CIDADE": "Atalanta",
    "UF": "SANTA CATARINA",
    "anual": 4.32,
    "JAN": 5.07,
    "FEB": 5.16,
    "MAR": 4.92,
    "APR": 4.35,
    "MAY": 3.74,
    "JUN": 3.14,
    "JUL": 3.33,
    "AUG": 4.1,
    "SEP": 3.85,
    "OCT": 4.07,
    "NOV": 4.97,
    "DEC": 5.08
  },
  {
    "LONG": -49.6,
    "LAT": -27.42,
    "CIDADE": "Ituporanga",
    "UF": "SANTA CATARINA",
    "anual": 4.3,
    "JAN": 5.06,
    "FEB": 5.13,
    "MAR": 4.91,
    "APR": 4.32,
    "MAY": 3.7,
    "JUN": 3.13,
    "JUL": 3.33,
    "AUG": 4.1,
    "SEP": 3.83,
    "OCT": 4.05,
    "NOV": 4.95,
    "DEC": 5.07
  },
  {
    "LONG": -49.36,
    "LAT": -27.39,
    "CIDADE": "Vidal Ramos",
    "UF": "SANTA CATARINA",
    "anual": 4.26,
    "JAN": 4.95,
    "FEB": 4.96,
    "MAR": 4.74,
    "APR": 4.34,
    "MAY": 3.77,
    "JUN": 3.28,
    "JUL": 3.47,
    "AUG": 4.16,
    "SEP": 3.78,
    "OCT": 3.93,
    "NOV": 4.83,
    "DEC": 4.9
  },
  {
    "LONG": -48.95,
    "LAT": -27.42,
    "CIDADE": "Major Gercino",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.87,
    "FEB": 5.05,
    "MAR": 4.8,
    "APR": 4.33,
    "MAY": 3.9,
    "JUN": 3.41,
    "JUL": 3.52,
    "AUG": 4.12,
    "SEP": 3.7,
    "OCT": 3.8,
    "NOV": 4.68,
    "DEC": 4.89
  },
  {
    "LONG": -5.25,
    "LAT": -27.25,
    "CIDADE": "Paial",
    "UF": "SANTA CATARINA",
    "anual": 4.81,
    "JAN": 5.51,
    "FEB": 5.53,
    "MAR": 5.48,
    "APR": 4.87,
    "MAY": 3.94,
    "JUN": 3.33,
    "JUL": 3.75,
    "AUG": 4.63,
    "SEP": 4.39,
    "OCT": 5.04,
    "NOV": 5.64,
    "DEC": 5.67
  },
  {
    "LONG": -52.33,
    "LAT": -27.28,
    "CIDADE": "Itá",
    "UF": "SANTA CATARINA",
    "anual": 4.81,
    "JAN": 5.57,
    "FEB": 5.59,
    "MAR": 5.48,
    "APR": 4.78,
    "MAY": 3.95,
    "JUN": 3.34,
    "JUL": 3.75,
    "AUG": 4.6,
    "SEP": 4.38,
    "OCT": 5.01,
    "NOV": 5.63,
    "DEC": 5.68
  },
  {
    "LONG": -51.61,
    "LAT": -27.35,
    "CIDADE": "Capinzal",
    "UF": "SANTA CATARINA",
    "anual": 4.8,
    "JAN": 5.34,
    "FEB": 5.32,
    "MAR": 5.38,
    "APR": 4.87,
    "MAY": 4.02,
    "JUN": 3.61,
    "JUL": 3.96,
    "AUG": 4.69,
    "SEP": 4.43,
    "OCT": 4.9,
    "NOV": 5.57,
    "DEC": 5.52
  },
  {
    "LONG": -51.62,
    "LAT": -27.34,
    "CIDADE": "Ouro",
    "UF": "SANTA CATARINA",
    "anual": 4.8,
    "JAN": 5.34,
    "FEB": 5.32,
    "MAR": 5.38,
    "APR": 4.87,
    "MAY": 4.02,
    "JUN": 3.61,
    "JUL": 3.96,
    "AUG": 4.69,
    "SEP": 4.43,
    "OCT": 4.9,
    "NOV": 5.57,
    "DEC": 5.52
  },
  {
    "LONG": -51.56,
    "LAT": -27.26,
    "CIDADE": "Lacerdópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.37,
    "FEB": 5.36,
    "MAR": 5.41,
    "APR": 4.91,
    "MAY": 4.01,
    "JUN": 3.65,
    "JUL": 3.96,
    "AUG": 4.68,
    "SEP": 4.45,
    "OCT": 4.93,
    "NOV": 5.6,
    "DEC": 5.53
  },
  {
    "LONG": -51.44,
    "LAT": -27.27,
    "CIDADE": "Erval Velho",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.39,
    "FEB": 5.33,
    "MAR": 5.38,
    "APR": 4.95,
    "MAY": 4.01,
    "JUN": 3.67,
    "JUL": 3.93,
    "AUG": 4.71,
    "SEP": 4.43,
    "OCT": 4.91,
    "NOV": 5.58,
    "DEC": 5.53
  },
  {
    "LONG": -50.84,
    "LAT": -27.31,
    "CIDADE": "Brunópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.59,
    "JAN": 5.09,
    "FEB": 5.17,
    "MAR": 5.03,
    "APR": 4.6,
    "MAY": 3.83,
    "JUN": 3.5,
    "JUL": 3.8,
    "AUG": 4.62,
    "SEP": 4.26,
    "OCT": 4.58,
    "NOV": 5.28,
    "DEC": 5.31
  },
  {
    "LONG": -50.58,
    "LAT": -27.28,
    "CIDADE": "Curitibanos",
    "UF": "SANTA CATARINA",
    "anual": 4.55,
    "JAN": 5.05,
    "FEB": 5.12,
    "MAR": 4.95,
    "APR": 4.55,
    "MAY": 3.78,
    "JUN": 3.55,
    "JUL": 3.78,
    "AUG": 4.6,
    "SEP": 4.19,
    "OCT": 4.49,
    "NOV": 5.25,
    "DEC": 5.25
  },
  {
    "LONG": -50.44,
    "LAT": -27.27,
    "CIDADE": "São Cristovão do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.48,
    "JAN": 4.95,
    "FEB": 5.07,
    "MAR": 4.84,
    "APR": 4.48,
    "MAY": 3.76,
    "JUN": 3.54,
    "JUL": 3.69,
    "AUG": 4.57,
    "SEP": 4.13,
    "OCT": 4.4,
    "NOV": 5.14,
    "DEC": 5.16
  },
  {
    "LONG": -49.93,
    "LAT": -27.26,
    "CIDADE": "Pouso Redondo",
    "UF": "SANTA CATARINA",
    "anual": 4.23,
    "JAN": 4.94,
    "FEB": 5.06,
    "MAR": 4.82,
    "APR": 4.26,
    "MAY": 3.67,
    "JUN": 3.08,
    "JUL": 3.29,
    "AUG": 4.02,
    "SEP": 3.78,
    "OCT": 4.01,
    "NOV": 4.9,
    "DEC": 4.97
  },
  {
    "LONG": -49.71,
    "LAT": -27.27,
    "CIDADE": "Agronômica",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 5.06,
    "FEB": 5.14,
    "MAR": 4.92,
    "APR": 4.31,
    "MAY": 3.69,
    "JUN": 3.09,
    "JUL": 3.25,
    "AUG": 4.07,
    "SEP": 3.84,
    "OCT": 4.08,
    "NOV": 4.95,
    "DEC": 5.09
  },
  {
    "LONG": -49.79,
    "LAT": -27.3,
    "CIDADE": "Trombudo Central",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 5.06,
    "FEB": 5.14,
    "MAR": 4.92,
    "APR": 4.31,
    "MAY": 3.69,
    "JUN": 3.09,
    "JUL": 3.25,
    "AUG": 4.07,
    "SEP": 3.84,
    "OCT": 4.08,
    "NOV": 4.95,
    "DEC": 5.09
  },
  {
    "LONG": "#VALOR!",
    "LAT": -27.31,
    "CIDADE": "Aurora",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 5.04,
    "FEB": 5.1,
    "MAR": 4.89,
    "APR": 4.3,
    "MAY": 3.69,
    "JUN": 3.12,
    "JUL": 3.3,
    "AUG": 4.1,
    "SEP": 3.85,
    "OCT": 4.08,
    "NOV": 4.95,
    "DEC": 5.05
  },
  {
    "LONG": -49.39,
    "LAT": -27.28,
    "CIDADE": "Presidente Nereu",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.91,
    "FEB": 4.93,
    "MAR": 4.75,
    "APR": 4.33,
    "MAY": 3.76,
    "JUN": 3.26,
    "JUL": 3.45,
    "AUG": 4.14,
    "SEP": 3.74,
    "OCT": 3.9,
    "NOV": 4.78,
    "DEC": 4.88
  },
  {
    "LONG": -48.93,
    "LAT": -27.28,
    "CIDADE": "Nova Trento",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.88,
    "FEB": 5.04,
    "MAR": 4.83,
    "APR": 4.32,
    "MAY": 3.92,
    "JUN": 3.39,
    "JUL": 3.5,
    "AUG": 4.08,
    "SEP": 3.66,
    "OCT": 3.77,
    "NOV": 4.64,
    "DEC": 4.86
  },
  {
    "LONG": -48.85,
    "LAT": -27.28,
    "CIDADE": "São João Batista",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 4.94,
    "FEB": 5.08,
    "MAR": 4.82,
    "APR": 4.37,
    "MAY": 3.96,
    "JUN": 3.41,
    "JUL": 3.53,
    "AUG": 4.1,
    "SEP": 3.75,
    "OCT": 3.86,
    "NOV": 4.71,
    "DEC": 4.93
  },
  {
    "LONG": -48.77,
    "LAT": -27.26,
    "CIDADE": "Canelinha",
    "UF": "SANTA CATARINA",
    "anual": 4.27,
    "JAN": 4.94,
    "FEB": 5.04,
    "MAR": 4.74,
    "APR": 4.32,
    "MAY": 3.93,
    "JUN": 3.38,
    "JUL": 3.53,
    "AUG": 4.07,
    "SEP": 3.75,
    "OCT": 3.9,
    "NOV": 4.72,
    "DEC": 4.91
  },
  {
    "LONG": -4.86,
    "LAT": -27.32,
    "CIDADE": "Governador Celso Ramos",
    "UF": "SANTA CATARINA",
    "anual": 4.48,
    "JAN": 5.24,
    "FEB": 5.31,
    "MAR": 5.04,
    "APR": 4.51,
    "MAY": 4.01,
    "JUN": 3.49,
    "JUL": 3.61,
    "AUG": 4.16,
    "SEP": 3.92,
    "OCT": 4.23,
    "NOV": 5.06,
    "DEC": 5.16
  },
  {
    "LONG": -53.72,
    "LAT": -27.17,
    "CIDADE": "Itapiranga",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.65,
    "FEB": 5.6,
    "MAR": 5.5,
    "APR": 4.8,
    "MAY": 4.02,
    "JUN": 3.4,
    "JUL": 3.82,
    "AUG": 4.61,
    "SEP": 4.39,
    "OCT": 5.09,
    "NOV": 5.56,
    "DEC": 5.64
  },
  {
    "LONG": -52.88,
    "LAT": -27.16,
    "CIDADE": "Caxambu do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.54,
    "FEB": 5.57,
    "MAR": 5.56,
    "APR": 4.91,
    "MAY": 4.09,
    "JUN": 3.56,
    "JUL": 3.92,
    "AUG": 4.69,
    "SEP": 4.41,
    "OCT": 5.09,
    "NOV": 5.58,
    "DEC": 5.68
  },
  {
    "LONG": -52.3,
    "LAT": -27.16,
    "CIDADE": "Seara",
    "UF": "SANTA CATARINA",
    "anual": 4.81,
    "JAN": 5.47,
    "FEB": 5.48,
    "MAR": 5.44,
    "APR": 4.82,
    "MAY": 4.01,
    "JUN": 3.45,
    "JUL": 3.83,
    "AUG": 4.68,
    "SEP": 4.4,
    "OCT": 4.99,
    "NOV": 5.57,
    "DEC": 5.56
  },
  {
    "LONG": -52.14,
    "LAT": -27.16,
    "CIDADE": "Arabutã",
    "UF": "SANTA CATARINA",
    "anual": 4.8,
    "JAN": 5.44,
    "FEB": 5.39,
    "MAR": 5.44,
    "APR": 4.82,
    "MAY": 4.04,
    "JUN": 3.54,
    "JUL": 3.86,
    "AUG": 4.71,
    "SEP": 4.4,
    "OCT": 4.96,
    "NOV": 5.55,
    "DEC": 5.51
  },
  {
    "LONG": -52.03,
    "LAT": -27.23,
    "CIDADE": "Concórdia",
    "UF": "SANTA CATARINA",
    "anual": 4.81,
    "JAN": 5.4,
    "FEB": 5.35,
    "MAR": 5.39,
    "APR": 4.86,
    "MAY": 4.06,
    "JUN": 3.62,
    "JUL": 3.93,
    "AUG": 4.73,
    "SEP": 4.42,
    "OCT": 4.93,
    "NOV": 5.53,
    "DEC": 5.49
  },
  {
    "LONG": -51.8,
    "LAT": -27.23,
    "CIDADE": "Presidente Castello Branco",
    "UF": "SANTA CATARINA",
    "anual": 4.78,
    "JAN": 5.24,
    "FEB": 5.27,
    "MAR": 5.3,
    "APR": 4.83,
    "MAY": 4.04,
    "JUN": 3.66,
    "JUL": 3.97,
    "AUG": 4.72,
    "SEP": 4.43,
    "OCT": 4.88,
    "NOV": 5.51,
    "DEC": 5.43
  },
  {
    "LONG": -51.74,
    "LAT": -27.17,
    "CIDADE": "Jaborá",
    "UF": "SANTA CATARINA",
    "anual": 4.77,
    "JAN": 5.26,
    "FEB": 5.25,
    "MAR": 5.32,
    "APR": 4.82,
    "MAY": 4.05,
    "JUN": 3.66,
    "JUL": 3.98,
    "AUG": 4.71,
    "SEP": 4.44,
    "OCT": 4.88,
    "NOV": 5.51,
    "DEC": 5.41
  },
  {
    "LONG": -51.51,
    "LAT": -27.17,
    "CIDADE": "Joaçaba",
    "UF": "SANTA CATARINA",
    "anual": 4.75,
    "JAN": 5.23,
    "FEB": 5.2,
    "MAR": 5.31,
    "APR": 4.85,
    "MAY": 4,
    "JUN": 3.63,
    "JUL": 3.92,
    "AUG": 4.72,
    "SEP": 4.43,
    "OCT": 4.85,
    "NOV": 5.52,
    "DEC": 5.39
  },
  {
    "LONG": -51.49,
    "LAT": -27.19,
    "CIDADE": "Herval d'Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.78,
    "JAN": 5.24,
    "FEB": 5.28,
    "MAR": 5.33,
    "APR": 4.89,
    "MAY": 3.99,
    "JUN": 3.65,
    "JUL": 3.9,
    "AUG": 4.72,
    "SEP": 4.42,
    "OCT": 4.88,
    "NOV": 5.56,
    "DEC": 5.43
  },
  {
    "LONG": -51.24,
    "LAT": -27.19,
    "CIDADE": "Ibiam",
    "UF": "SANTA CATARINA",
    "anual": 4.73,
    "JAN": 5.27,
    "FEB": 5.28,
    "MAR": 5.27,
    "APR": 4.8,
    "MAY": 3.94,
    "JUN": 3.64,
    "JUL": 3.86,
    "AUG": 4.71,
    "SEP": 4.36,
    "OCT": 4.79,
    "NOV": 5.47,
    "DEC": 5.42
  },
  {
    "LONG": -50.98,
    "LAT": -27.22,
    "CIDADE": "Monte Carlo",
    "UF": "SANTA CATARINA",
    "anual": 4.62,
    "JAN": 5.11,
    "FEB": 5.15,
    "MAR": 5.06,
    "APR": 4.61,
    "MAY": 3.87,
    "JUN": 3.58,
    "JUL": 3.87,
    "AUG": 4.65,
    "SEP": 4.26,
    "OCT": 4.63,
    "NOV": 5.31,
    "DEC": 5.29
  },
  {
    "LONG": -50.81,
    "LAT": -27.18,
    "CIDADE": "Frei Rogério",
    "UF": "SANTA CATARINA",
    "anual": 4.59,
    "JAN": 5.05,
    "FEB": 5.12,
    "MAR": 5.02,
    "APR": 4.57,
    "MAY": 3.85,
    "JUN": 3.57,
    "JUL": 3.86,
    "AUG": 4.66,
    "SEP": 4.25,
    "OCT": 4.58,
    "NOV": 5.28,
    "DEC": 5.28
  },
  {
    "LONG": -50.47,
    "LAT": -27.16,
    "CIDADE": "Ponte Alta do Norte",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 4.95,
    "FEB": 5.06,
    "MAR": 4.88,
    "APR": 4.47,
    "MAY": 3.78,
    "JUN": 3.53,
    "JUL": 3.75,
    "AUG": 4.57,
    "SEP": 4.14,
    "OCT": 4.41,
    "NOV": 5.18,
    "DEC": 5.16
  },
  {
    "LONG": -50.07,
    "LAT": -27.2,
    "CIDADE": "Mirim Doce",
    "UF": "SANTA CATARINA",
    "anual": 4.2,
    "JAN": 4.87,
    "FEB": 4.96,
    "MAR": 4.79,
    "APR": 4.26,
    "MAY": 3.66,
    "JUN": 3.08,
    "JUL": 3.25,
    "AUG": 4.06,
    "SEP": 3.74,
    "OCT": 3.93,
    "NOV": 4.88,
    "DEC": 4.92
  },
  {
    "LONG": -49.8,
    "LAT": -27.2,
    "CIDADE": "Rio do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.98,
    "FEB": 5.02,
    "MAR": 4.91,
    "APR": 4.31,
    "MAY": 3.67,
    "JUN": 3.06,
    "JUL": 3.18,
    "AUG": 4.05,
    "SEP": 3.82,
    "OCT": 4.06,
    "NOV": 4.95,
    "DEC": 5.03
  },
  {
    "LONG": -49.73,
    "LAT": -27.22,
    "CIDADE": "Laurentino",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.97,
    "FEB": 5.03,
    "MAR": 4.88,
    "APR": 4.29,
    "MAY": 3.66,
    "JUN": 3.04,
    "JUL": 3.23,
    "AUG": 4.06,
    "SEP": 3.81,
    "OCT": 4.06,
    "NOV": 4.95,
    "DEC": 5.02
  },
  {
    "LONG": -49.64,
    "LAT": -27.22,
    "CIDADE": "Rio do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.26,
    "JAN": 4.98,
    "FEB": 5.03,
    "MAR": 4.86,
    "APR": 4.3,
    "MAY": 3.68,
    "JUN": 3.1,
    "JUL": 3.29,
    "AUG": 4.1,
    "SEP": 3.81,
    "OCT": 4.03,
    "NOV": 4.92,
    "DEC": 5.02
  },
  {
    "LONG": -49.54,
    "LAT": -27.17,
    "CIDADE": "Lontras",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 5.01,
    "FEB": 5.05,
    "MAR": 4.9,
    "APR": 4.34,
    "MAY": 3.73,
    "JUN": 3.16,
    "JUL": 3.35,
    "AUG": 4.1,
    "SEP": 3.8,
    "OCT": 4.02,
    "NOV": 4.91,
    "DEC": 4.99
  },
  {
    "LONG": -49.07,
    "LAT": -27.2,
    "CIDADE": "Botuverá",
    "UF": "SANTA CATARINA",
    "anual": 4.17,
    "JAN": 4.79,
    "FEB": 4.92,
    "MAR": 4.67,
    "APR": 4.26,
    "MAY": 3.88,
    "JUN": 3.33,
    "JUL": 3.49,
    "AUG": 4.07,
    "SEP": 3.64,
    "OCT": 3.7,
    "NOV": 4.57,
    "DEC": 4.77
  },
  {
    "LONG": -48.63,
    "LAT": -27.24,
    "CIDADE": "Tijucas",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 5.01,
    "FEB": 5.06,
    "MAR": 4.77,
    "APR": 4.29,
    "MAY": 3.9,
    "JUN": 3.4,
    "JUL": 3.56,
    "AUG": 4.05,
    "SEP": 3.73,
    "OCT": 3.9,
    "NOV": 4.73,
    "DEC": 4.93
  },
  {
    "LONG": -48.55,
    "LAT": -27.16,
    "CIDADE": "Porto Belo",
    "UF": "SANTA CATARINA",
    "anual": 4.43,
    "JAN": 5.2,
    "FEB": 5.25,
    "MAR": 5,
    "APR": 4.46,
    "MAY": 3.97,
    "JUN": 3.46,
    "JUL": 3.58,
    "AUG": 4.11,
    "SEP": 3.87,
    "OCT": 4.19,
    "NOV": 5,
    "DEC": 5.13
  },
  {
    "LONG": -53.6,
    "LAT": -27.1,
    "CIDADE": "São João do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.56,
    "FEB": 5.51,
    "MAR": 5.43,
    "APR": 4.81,
    "MAY": 4.04,
    "JUN": 3.51,
    "JUL": 3.87,
    "AUG": 4.67,
    "SEP": 4.41,
    "OCT": 5.05,
    "NOV": 5.54,
    "DEC": 5.57
  },
  {
    "LONG": -53.4,
    "LAT": -27.1,
    "CIDADE": "Mondaí",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.57,
    "FEB": 5.52,
    "MAR": 5.44,
    "APR": 4.79,
    "MAY": 3.99,
    "JUN": 3.47,
    "JUL": 3.83,
    "AUG": 4.65,
    "SEP": 4.42,
    "OCT": 5.06,
    "NOV": 5.56,
    "DEC": 5.58
  },
  {
    "LONG": -53.33,
    "LAT": -27.07,
    "CIDADE": "Riqueza",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.63,
    "FEB": 5.58,
    "MAR": 5.5,
    "APR": 4.79,
    "MAY": 3.96,
    "JUN": 3.45,
    "JUL": 3.82,
    "AUG": 4.63,
    "SEP": 4.39,
    "OCT": 5.06,
    "NOV": 5.56,
    "DEC": 5.62
  },
  {
    "LONG": -53.25,
    "LAT": -27.07,
    "CIDADE": "Caibi",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.55,
    "FEB": 5.59,
    "MAR": 5.47,
    "APR": 4.84,
    "MAY": 4.02,
    "JUN": 3.49,
    "JUL": 3.9,
    "AUG": 4.69,
    "SEP": 4.4,
    "OCT": 5.05,
    "NOV": 5.53,
    "DEC": 5.62
  },
  {
    "LONG": -53.16,
    "LAT": -27.07,
    "CIDADE": "Palmitos",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.5,
    "FEB": 5.55,
    "MAR": 5.44,
    "APR": 4.85,
    "MAY": 4.04,
    "JUN": 3.52,
    "JUL": 3.91,
    "AUG": 4.7,
    "SEP": 4.4,
    "OCT": 5.04,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -53,
    "LAT": -27.08,
    "CIDADE": "São Carlos",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.51,
    "FEB": 5.53,
    "MAR": 5.44,
    "APR": 4.87,
    "MAY": 4.03,
    "JUN": 3.53,
    "JUL": 3.88,
    "AUG": 4.67,
    "SEP": 4.41,
    "OCT": 5.06,
    "NOV": 5.56,
    "DEC": 5.61
  },
  {
    "LONG": -52.98,
    "LAT": -27.08,
    "CIDADE": "Águas de Chapecó",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.51,
    "FEB": 5.57,
    "MAR": 5.56,
    "APR": 4.91,
    "MAY": 4.06,
    "JUN": 3.54,
    "JUL": 3.87,
    "AUG": 4.7,
    "SEP": 4.41,
    "OCT": 5.09,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -52.87,
    "LAT": -27.07,
    "CIDADE": "Planalto Alegre",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.5,
    "FEB": 5.55,
    "MAR": 5.56,
    "APR": 4.92,
    "MAY": 4.08,
    "JUN": 3.59,
    "JUL": 3.92,
    "AUG": 4.71,
    "SEP": 4.41,
    "OCT": 5.07,
    "NOV": 5.59,
    "DEC": 5.65
  },
  {
    "LONG": -52.79,
    "LAT": -27.13,
    "CIDADE": "Guatambú",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.48,
    "FEB": 5.48,
    "MAR": 5.49,
    "APR": 4.91,
    "MAY": 4.12,
    "JUN": 3.64,
    "JUL": 3.97,
    "AUG": 4.72,
    "SEP": 4.42,
    "OCT": 5.05,
    "NOV": 5.55,
    "DEC": 5.6
  },
  {
    "LONG": -52.62,
    "LAT": -27.1,
    "CIDADE": "Chapecó",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.46,
    "FEB": 5.45,
    "MAR": 5.42,
    "APR": 4.88,
    "MAY": 4.12,
    "JUN": 3.64,
    "JUL": 3.97,
    "AUG": 4.74,
    "SEP": 4.44,
    "OCT": 5.07,
    "NOV": 5.57,
    "DEC": 5.58
  },
  {
    "LONG": -52.45,
    "LAT": -27.08,
    "CIDADE": "Arvoredo",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.51,
    "FEB": 5.48,
    "MAR": 5.46,
    "APR": 4.85,
    "MAY": 4.06,
    "JUN": 3.56,
    "JUL": 3.91,
    "AUG": 4.71,
    "SEP": 4.42,
    "OCT": 5.03,
    "NOV": 5.57,
    "DEC": 5.55
  },
  {
    "LONG": -52.34,
    "LAT": -27.07,
    "CIDADE": "Xavantina",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.38,
    "FEB": 5.37,
    "MAR": 5.41,
    "APR": 4.87,
    "MAY": 4.09,
    "JUN": 3.62,
    "JUL": 3.96,
    "AUG": 4.74,
    "SEP": 4.45,
    "OCT": 5,
    "NOV": 5.54,
    "DEC": 5.51
  },
  {
    "LONG": -52.13,
    "LAT": -27.08,
    "CIDADE": "Ipumirim",
    "UF": "SANTA CATARINA",
    "anual": 4.78,
    "JAN": 5.35,
    "FEB": 5.23,
    "MAR": 5.34,
    "APR": 4.83,
    "MAY": 4.05,
    "JUN": 3.64,
    "JUL": 3.94,
    "AUG": 4.77,
    "SEP": 4.42,
    "OCT": 4.91,
    "NOV": 5.48,
    "DEC": 5.44
  },
  {
    "LONG": -52.08,
    "LAT": -27.05,
    "CIDADE": "Lindóia do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.77,
    "JAN": 5.26,
    "FEB": 5.26,
    "MAR": 5.3,
    "APR": 4.85,
    "MAY": 4.04,
    "JUN": 3.65,
    "JUL": 3.97,
    "AUG": 4.75,
    "SEP": 4.42,
    "OCT": 4.9,
    "NOV": 5.47,
    "DEC": 5.4
  },
  {
    "LONG": -51.66,
    "LAT": -27.07,
    "CIDADE": "Catanduvas",
    "UF": "SANTA CATARINA",
    "anual": 4.74,
    "JAN": 5.15,
    "FEB": 5.18,
    "MAR": 5.25,
    "APR": 4.8,
    "MAY": 4.04,
    "JUN": 3.69,
    "JUL": 3.97,
    "AUG": 4.74,
    "SEP": 4.45,
    "OCT": 4.81,
    "NOV": 5.45,
    "DEC": 5.29
  },
  {
    "LONG": -51.47,
    "LAT": -27.13,
    "CIDADE": "Luzerna",
    "UF": "SANTA CATARINA",
    "anual": 4.73,
    "JAN": 5.16,
    "FEB": 5.21,
    "MAR": 5.28,
    "APR": 4.82,
    "MAY": 3.99,
    "JUN": 3.67,
    "JUL": 3.89,
    "AUG": 4.71,
    "SEP": 4.43,
    "OCT": 4.82,
    "NOV": 5.46,
    "DEC": 5.36
  },
  {
    "LONG": -51.37,
    "LAT": -27.09,
    "CIDADE": "Ibicaré",
    "UF": "SANTA CATARINA",
    "anual": 4.74,
    "JAN": 5.22,
    "FEB": 5.23,
    "MAR": 5.29,
    "APR": 4.8,
    "MAY": 3.98,
    "JUN": 3.66,
    "JUL": 3.85,
    "AUG": 4.71,
    "SEP": 4.41,
    "OCT": 4.83,
    "NOV": 5.49,
    "DEC": 5.4
  },
  {
    "LONG": -51.25,
    "LAT": -27.1,
    "CIDADE": "Tangará",
    "UF": "SANTA CATARINA",
    "anual": 4.7,
    "JAN": 5.18,
    "FEB": 5.24,
    "MAR": 5.23,
    "APR": 4.79,
    "MAY": 3.9,
    "JUN": 3.61,
    "JUL": 3.84,
    "AUG": 4.71,
    "SEP": 4.35,
    "OCT": 4.77,
    "NOV": 5.42,
    "DEC": 5.41
  },
  {
    "LONG": -50,
    "LAT": -27.12,
    "CIDADE": "Taió",
    "UF": "SANTA CATARINA",
    "anual": 4.19,
    "JAN": 4.81,
    "FEB": 4.88,
    "MAR": 4.81,
    "APR": 4.25,
    "MAY": 3.63,
    "JUN": 3.11,
    "JUL": 3.29,
    "AUG": 4.08,
    "SEP": 3.78,
    "OCT": 3.94,
    "NOV": 4.87,
    "DEC": 4.88
  },
  {
    "LONG": -49.52,
    "LAT": -27.06,
    "CIDADE": "Ibirama",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.99,
    "FEB": 5.08,
    "MAR": 4.92,
    "APR": 4.34,
    "MAY": 3.74,
    "JUN": 3.13,
    "JUL": 3.35,
    "AUG": 4.09,
    "SEP": 3.8,
    "OCT": 4.03,
    "NOV": 4.92,
    "DEC": 4.99
  },
  {
    "LONG": -48.98,
    "LAT": -27.08,
    "CIDADE": "Guabiruba",
    "UF": "SANTA CATARINA",
    "anual": 4.2,
    "JAN": 4.88,
    "FEB": 5.01,
    "MAR": 4.8,
    "APR": 4.26,
    "MAY": 3.88,
    "JUN": 3.32,
    "JUL": 3.46,
    "AUG": 3.97,
    "SEP": 3.64,
    "OCT": 3.76,
    "NOV": 4.58,
    "DEC": 4.83
  },
  {
    "LONG": -48.91,
    "LAT": -27.1,
    "CIDADE": "Brusque",
    "UF": "SANTA CATARINA",
    "anual": 4.2,
    "JAN": 4.88,
    "FEB": 5.01,
    "MAR": 4.8,
    "APR": 4.26,
    "MAY": 3.88,
    "JUN": 3.32,
    "JUL": 3.46,
    "AUG": 3.97,
    "SEP": 3.64,
    "OCT": 3.76,
    "NOV": 4.58,
    "DEC": 4.83
  },
  {
    "LONG": -48.62,
    "LAT": -27.09,
    "CIDADE": "Itapema",
    "UF": "SANTA CATARINA",
    "anual": 4.26,
    "JAN": 4.94,
    "FEB": 5.05,
    "MAR": 4.78,
    "APR": 4.31,
    "MAY": 3.88,
    "JUN": 3.37,
    "JUL": 3.5,
    "AUG": 3.99,
    "SEP": 3.73,
    "OCT": 3.9,
    "NOV": 4.72,
    "DEC": 4.9
  },
  {
    "LONG": -4.85,
    "LAT": -27.14,
    "CIDADE": "Bombinhas",
    "UF": "SANTA CATARINA",
    "anual": 4.46,
    "JAN": 5.23,
    "FEB": 5.3,
    "MAR": 5.06,
    "APR": 4.51,
    "MAY": 3.98,
    "JUN": 3.47,
    "JUL": 3.55,
    "AUG": 4.08,
    "SEP": 3.86,
    "OCT": 4.26,
    "NOV": 5.05,
    "DEC": 5.18
  },
  {
    "LONG": -53.64,
    "LAT": -26.97,
    "CIDADE": "Tunápolis",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.58,
    "FEB": 5.53,
    "MAR": 5.43,
    "APR": 4.84,
    "MAY": 4.05,
    "JUN": 3.55,
    "JUL": 3.88,
    "AUG": 4.68,
    "SEP": 4.41,
    "OCT": 5.05,
    "NOV": 5.49,
    "DEC": 5.54
  },
  {
    "LONG": -53.54,
    "LAT": -26.99,
    "CIDADE": "Iporã do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.58,
    "FEB": 5.52,
    "MAR": 5.41,
    "APR": 4.87,
    "MAY": 4.06,
    "JUN": 3.57,
    "JUL": 3.92,
    "AUG": 4.69,
    "SEP": 4.44,
    "OCT": 5.05,
    "NOV": 5.5,
    "DEC": 5.54
  },
  {
    "LONG": -53.09,
    "LAT": -26.97,
    "CIDADE": "Cunhataí",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.47,
    "FEB": 5.46,
    "MAR": 5.41,
    "APR": 4.88,
    "MAY": 4.07,
    "JUN": 3.58,
    "JUL": 3.92,
    "AUG": 4.72,
    "SEP": 4.44,
    "OCT": 5.06,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -5.26,
    "LAT": -26.98,
    "CIDADE": "Cordilheira Alta",
    "UF": "SANTA CATARINA",
    "anual": 4.89,
    "JAN": 5.51,
    "FEB": 5.48,
    "MAR": 5.48,
    "APR": 4.92,
    "MAY": 4.12,
    "JUN": 3.69,
    "JUL": 3.98,
    "AUG": 4.76,
    "SEP": 4.46,
    "OCT": 5.08,
    "NOV": 5.59,
    "DEC": 5.6
  },
  {
    "LONG": -52.54,
    "LAT": -26.96,
    "CIDADE": "Xaxim",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.48,
    "FEB": 5.46,
    "MAR": 5.45,
    "APR": 4.89,
    "MAY": 4.09,
    "JUN": 3.65,
    "JUL": 3.96,
    "AUG": 4.75,
    "SEP": 4.45,
    "OCT": 5.06,
    "NOV": 5.57,
    "DEC": 5.57
  },
  {
    "LONG": -51.9,
    "LAT": -27.03,
    "CIDADE": "Irani",
    "UF": "SANTA CATARINA",
    "anual": 4.76,
    "JAN": 5.21,
    "FEB": 5.19,
    "MAR": 5.25,
    "APR": 4.86,
    "MAY": 4.05,
    "JUN": 3.71,
    "JUL": 3.99,
    "AUG": 4.78,
    "SEP": 4.45,
    "OCT": 4.88,
    "NOV": 5.42,
    "DEC": 5.35
  },
  {
    "LONG": -51.74,
    "LAT": -27.01,
    "CIDADE": "Vargem Bonita",
    "UF": "SANTA CATARINA",
    "anual": 4.75,
    "JAN": 5.13,
    "FEB": 5.16,
    "MAR": 5.26,
    "APR": 4.82,
    "MAY": 4.03,
    "JUN": 3.75,
    "JUL": 4.01,
    "AUG": 4.79,
    "SEP": 4.47,
    "OCT": 4.85,
    "NOV": 5.41,
    "DEC": 5.3
  },
  {
    "LONG": -51.55,
    "LAT": -27,
    "CIDADE": "Água Doce",
    "UF": "SANTA CATARINA",
    "anual": 4.71,
    "JAN": 5.06,
    "FEB": 5.06,
    "MAR": 5.2,
    "APR": 4.8,
    "MAY": 4,
    "JUN": 3.74,
    "JUL": 3.96,
    "AUG": 4.79,
    "SEP": 4.45,
    "OCT": 4.79,
    "NOV": 5.39,
    "DEC": 5.28
  },
  {
    "LONG": -51.41,
    "LAT": -27,
    "CIDADE": "Treze Tílias",
    "UF": "SANTA CATARINA",
    "anual": 4.7,
    "JAN": 5.08,
    "FEB": 5.08,
    "MAR": 5.2,
    "APR": 4.77,
    "MAY": 3.99,
    "JUN": 3.73,
    "JUL": 3.92,
    "AUG": 4.77,
    "SEP": 4.43,
    "OCT": 4.79,
    "NOV": 5.39,
    "DEC": 5.3
  },
  {
    "LONG": -51.24,
    "LAT": -27,
    "CIDADE": "Iomerê",
    "UF": "SANTA CATARINA",
    "anual": 4.65,
    "JAN": 5.12,
    "FEB": 5.12,
    "MAR": 5.14,
    "APR": 4.69,
    "MAY": 3.9,
    "JUN": 3.61,
    "JUL": 3.87,
    "AUG": 4.71,
    "SEP": 4.35,
    "OCT": 4.69,
    "NOV": 5.35,
    "DEC": 5.29
  },
  {
    "LONG": -51.23,
    "LAT": -27.04,
    "CIDADE": "Pinheiro Preto",
    "UF": "SANTA CATARINA",
    "anual": 4.65,
    "JAN": 5.12,
    "FEB": 5.12,
    "MAR": 5.14,
    "APR": 4.69,
    "MAY": 3.9,
    "JUN": 3.61,
    "JUL": 3.87,
    "AUG": 4.71,
    "SEP": 4.35,
    "OCT": 4.69,
    "NOV": 5.35,
    "DEC": 5.29
  },
  {
    "LONG": -51.15,
    "LAT": -27.01,
    "CIDADE": "Videira",
    "UF": "SANTA CATARINA",
    "anual": 4.64,
    "JAN": 5.12,
    "FEB": 5.1,
    "MAR": 5.15,
    "APR": 4.67,
    "MAY": 3.9,
    "JUN": 3.57,
    "JUL": 3.86,
    "AUG": 4.7,
    "SEP": 4.33,
    "OCT": 4.69,
    "NOV": 5.32,
    "DEC": 5.27
  },
  {
    "LONG": -50.92,
    "LAT": -27.02,
    "CIDADE": "Fraiburgo",
    "UF": "SANTA CATARINA",
    "anual": 4.6,
    "JAN": 5.07,
    "FEB": 5.05,
    "MAR": 5.06,
    "APR": 4.58,
    "MAY": 3.86,
    "JUN": 3.6,
    "JUL": 3.87,
    "AUG": 4.68,
    "SEP": 4.28,
    "OCT": 4.6,
    "NOV": 5.26,
    "DEC": 5.23
  },
  {
    "LONG": -50.43,
    "LAT": -26.96,
    "CIDADE": "Santa Cecília",
    "UF": "SANTA CATARINA",
    "anual": 4.49,
    "JAN": 4.91,
    "FEB": 4.97,
    "MAR": 4.85,
    "APR": 4.49,
    "MAY": 3.79,
    "JUN": 3.58,
    "JUL": 3.8,
    "AUG": 4.66,
    "SEP": 4.18,
    "OCT": 4.42,
    "NOV": 5.11,
    "DEC": 5.1
  },
  {
    "LONG": -50,
    "LAT": -26.98,
    "CIDADE": "Salete",
    "UF": "SANTA CATARINA",
    "anual": 4.17,
    "JAN": 4.72,
    "FEB": 4.82,
    "MAR": 4.72,
    "APR": 4.19,
    "MAY": 3.62,
    "JUN": 3.16,
    "JUL": 3.37,
    "AUG": 4.16,
    "SEP": 3.8,
    "OCT": 3.92,
    "NOV": 4.8,
    "DEC": 4.81
  },
  {
    "LONG": -49.73,
    "LAT": -26.98,
    "CIDADE": "Dona Emma",
    "UF": "SANTA CATARINA",
    "anual": 4.23,
    "JAN": 4.88,
    "FEB": 4.98,
    "MAR": 4.82,
    "APR": 4.24,
    "MAY": 3.67,
    "JUN": 3.09,
    "JUL": 3.32,
    "AUG": 4.12,
    "SEP": 3.8,
    "OCT": 4.03,
    "NOV": 4.87,
    "DEC": 4.92
  },
  {
    "LONG": -49.63,
    "LAT": -2.7,
    "CIDADE": "José Boiteux",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.92,
    "FEB": 5.01,
    "MAR": 4.85,
    "APR": 4.26,
    "MAY": 3.67,
    "JUN": 3.1,
    "JUL": 3.32,
    "AUG": 4.11,
    "SEP": 3.8,
    "OCT": 4.05,
    "NOV": 4.88,
    "DEC": 4.95
  },
  {
    "LONG": -4.96,
    "LAT": -27.04,
    "CIDADE": "Presidente Getúlio",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.92,
    "FEB": 5.01,
    "MAR": 4.85,
    "APR": 4.26,
    "MAY": 3.67,
    "JUN": 3.1,
    "JUL": 3.32,
    "AUG": 4.11,
    "SEP": 3.8,
    "OCT": 4.05,
    "NOV": 4.88,
    "DEC": 4.95
  },
  {
    "LONG": -49.39,
    "LAT": -2.7,
    "CIDADE": "Apiúna",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.88,
    "FEB": 4.97,
    "MAR": 4.83,
    "APR": 4.33,
    "MAY": 3.83,
    "JUN": 3.26,
    "JUL": 3.38,
    "AUG": 4.06,
    "SEP": 3.69,
    "OCT": 3.84,
    "NOV": 4.72,
    "DEC": 4.83
  },
  {
    "LONG": -49.38,
    "LAT": -26.96,
    "CIDADE": "Ascurra",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.88,
    "FEB": 4.97,
    "MAR": 4.83,
    "APR": 4.33,
    "MAY": 3.83,
    "JUN": 3.26,
    "JUL": 3.38,
    "AUG": 4.06,
    "SEP": 3.69,
    "OCT": 3.84,
    "NOV": 4.72,
    "DEC": 4.83
  },
  {
    "LONG": -48.65,
    "LAT": -27.02,
    "CIDADE": "Camboriú",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 5.09,
    "FEB": 5.2,
    "MAR": 4.89,
    "APR": 4.38,
    "MAY": 3.91,
    "JUN": 3.39,
    "JUL": 3.49,
    "AUG": 3.96,
    "SEP": 3.82,
    "OCT": 4.08,
    "NOV": 4.87,
    "DEC": 5.04
  },
  {
    "LONG": -48.64,
    "LAT": -26.99,
    "CIDADE": "Balneário Camboriú",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 5.09,
    "FEB": 5.2,
    "MAR": 4.89,
    "APR": 4.38,
    "MAY": 3.91,
    "JUN": 3.39,
    "JUL": 3.49,
    "AUG": 3.96,
    "SEP": 3.82,
    "OCT": 4.08,
    "NOV": 4.87,
    "DEC": 5.04
  },
  {
    "LONG": -53.62,
    "LAT": -26.94,
    "CIDADE": "Santa Helena",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.53,
    "FEB": 5.51,
    "MAR": 5.41,
    "APR": 4.83,
    "MAY": 4.07,
    "JUN": 3.56,
    "JUL": 3.9,
    "AUG": 4.68,
    "SEP": 4.42,
    "OCT": 5.03,
    "NOV": 5.46,
    "DEC": 5.51
  },
  {
    "LONG": -53.17,
    "LAT": -26.9,
    "CIDADE": "Cunha Porã",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.47,
    "FEB": 5.48,
    "MAR": 5.38,
    "APR": 4.92,
    "MAY": 4.12,
    "JUN": 3.61,
    "JUL": 3.96,
    "AUG": 4.74,
    "SEP": 4.46,
    "OCT": 5.02,
    "NOV": 5.46,
    "DEC": 5.52
  },
  {
    "LONG": -53,
    "LAT": -26.93,
    "CIDADE": "Saudades",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.48,
    "FEB": 5.44,
    "MAR": 5.38,
    "APR": 4.87,
    "MAY": 4.09,
    "JUN": 3.58,
    "JUL": 3.95,
    "AUG": 4.73,
    "SEP": 4.44,
    "OCT": 5.02,
    "NOV": 5.46,
    "DEC": 5.54
  },
  {
    "LONG": -5.29,
    "LAT": -26.9,
    "CIDADE": "Nova Erechim",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.44,
    "FEB": 5.43,
    "MAR": 5.45,
    "APR": 4.91,
    "MAY": 4.08,
    "JUN": 3.59,
    "JUL": 3.92,
    "AUG": 4.74,
    "SEP": 4.44,
    "OCT": 5.05,
    "NOV": 5.52,
    "DEC": 5.53
  },
  {
    "LONG": -52.81,
    "LAT": -26.94,
    "CIDADE": "Nova Itaberaba",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.49,
    "FEB": 5.49,
    "MAR": 5.5,
    "APR": 4.94,
    "MAY": 4.11,
    "JUN": 3.61,
    "JUL": 3.94,
    "AUG": 4.75,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.56,
    "DEC": 5.57
  },
  {
    "LONG": -52.86,
    "LAT": -26.88,
    "CIDADE": "Águas Frias",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.49,
    "FEB": 5.49,
    "MAR": 5.5,
    "APR": 4.94,
    "MAY": 4.11,
    "JUN": 3.61,
    "JUL": 3.94,
    "AUG": 4.75,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.56,
    "DEC": 5.57
  },
  {
    "LONG": -52.7,
    "LAT": -26.91,
    "CIDADE": "Coronel Freitas",
    "UF": "SANTA CATARINA",
    "anual": 4.9,
    "JAN": 5.51,
    "FEB": 5.51,
    "MAR": 5.54,
    "APR": 4.93,
    "MAY": 4.15,
    "JUN": 3.65,
    "JUL": 3.95,
    "AUG": 4.79,
    "SEP": 4.48,
    "OCT": 5.1,
    "NOV": 5.59,
    "DEC": 5.57
  },
  {
    "LONG": -52.57,
    "LAT": -26.86,
    "CIDADE": "Lajeado Grande",
    "UF": "SANTA CATARINA",
    "anual": 4.9,
    "JAN": 5.54,
    "FEB": 5.47,
    "MAR": 5.51,
    "APR": 4.96,
    "MAY": 4.13,
    "JUN": 3.69,
    "JUL": 4,
    "AUG": 4.8,
    "SEP": 4.48,
    "OCT": 5.06,
    "NOV": 5.58,
    "DEC": 5.55
  },
  {
    "LONG": -5.24,
    "LAT": -26.88,
    "CIDADE": "Xanxerê",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.5,
    "FEB": 5.4,
    "MAR": 5.41,
    "APR": 4.91,
    "MAY": 4.12,
    "JUN": 3.7,
    "JUL": 4.02,
    "AUG": 4.81,
    "SEP": 4.48,
    "OCT": 5.01,
    "NOV": 5.5,
    "DEC": 5.5
  },
  {
    "LONG": -52.16,
    "LAT": -26.86,
    "CIDADE": "Vargeão",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.38,
    "FEB": 5.29,
    "MAR": 5.36,
    "APR": 4.87,
    "MAY": 4.07,
    "JUN": 3.73,
    "JUL": 4.02,
    "AUG": 4.8,
    "SEP": 4.48,
    "OCT": 4.96,
    "NOV": 5.45,
    "DEC": 5.41
  },
  {
    "LONG": -52.01,
    "LAT": -26.87,
    "CIDADE": "Ponte Serrada",
    "UF": "SANTA CATARINA",
    "anual": 4.79,
    "JAN": 5.27,
    "FEB": 5.21,
    "MAR": 5.32,
    "APR": 4.88,
    "MAY": 4.08,
    "JUN": 3.73,
    "JUL": 4.02,
    "AUG": 4.81,
    "SEP": 4.48,
    "OCT": 4.93,
    "NOV": 5.43,
    "DEC": 5.38
  },
  {
    "LONG": -51.4,
    "LAT": -26.9,
    "CIDADE": "Salto Veloso",
    "UF": "SANTA CATARINA",
    "anual": 4.67,
    "JAN": 5.07,
    "FEB": 5.02,
    "MAR": 5.1,
    "APR": 4.71,
    "MAY": 3.93,
    "JUN": 3.71,
    "JUL": 3.94,
    "AUG": 4.79,
    "SEP": 4.43,
    "OCT": 4.75,
    "NOV": 5.34,
    "DEC": 5.25
  },
  {
    "LONG": -5.14,
    "LAT": -26.86,
    "CIDADE": "Macieira",
    "UF": "SANTA CATARINA",
    "anual": 4.65,
    "JAN": 5.06,
    "FEB": 5.01,
    "MAR": 5.08,
    "APR": 4.67,
    "MAY": 3.9,
    "JUN": 3.68,
    "JUL": 3.92,
    "AUG": 4.77,
    "SEP": 4.39,
    "OCT": 4.71,
    "NOV": 5.31,
    "DEC": 5.24
  },
  {
    "LONG": -51.34,
    "LAT": -26.93,
    "CIDADE": "Arroio Trinta",
    "UF": "SANTA CATARINA",
    "anual": 4.65,
    "JAN": 5.06,
    "FEB": 5.01,
    "MAR": 5.08,
    "APR": 4.67,
    "MAY": 3.9,
    "JUN": 3.68,
    "JUL": 3.92,
    "AUG": 4.77,
    "SEP": 4.39,
    "OCT": 4.71,
    "NOV": 5.31,
    "DEC": 5.24
  },
  {
    "LONG": -51.07,
    "LAT": -26.9,
    "CIDADE": "Rio das Antas",
    "UF": "SANTA CATARINA",
    "anual": 4.58,
    "JAN": 5.03,
    "FEB": 5.01,
    "MAR": 5.05,
    "APR": 4.58,
    "MAY": 3.85,
    "JUN": 3.57,
    "JUL": 3.85,
    "AUG": 4.7,
    "SEP": 4.28,
    "OCT": 4.6,
    "NOV": 5.24,
    "DEC": 5.2
  },
  {
    "LONG": -50.69,
    "LAT": -26.93,
    "CIDADE": "Lebon Régis",
    "UF": "SANTA CATARINA",
    "anual": 4.52,
    "JAN": 4.92,
    "FEB": 4.98,
    "MAR": 4.89,
    "APR": 4.5,
    "MAY": 3.8,
    "JUN": 3.6,
    "JUL": 3.86,
    "AUG": 4.69,
    "SEP": 4.27,
    "OCT": 4.49,
    "NOV": 5.14,
    "DEC": 5.13
  },
  {
    "LONG": -50.14,
    "LAT": -26.95,
    "CIDADE": "Rio do Campo",
    "UF": "SANTA CATARINA",
    "anual": 4.18,
    "JAN": 4.67,
    "FEB": 4.72,
    "MAR": 4.65,
    "APR": 4.17,
    "MAY": 3.66,
    "JUN": 3.21,
    "JUL": 3.47,
    "AUG": 4.28,
    "SEP": 3.86,
    "OCT": 3.94,
    "NOV": 4.77,
    "DEC": 4.75
  },
  {
    "LONG": -49.83,
    "LAT": -26.88,
    "CIDADE": "Vitor Meireles",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.79,
    "FEB": 4.9,
    "MAR": 4.83,
    "APR": 4.2,
    "MAY": 3.63,
    "JUN": 3.16,
    "JUL": 3.36,
    "AUG": 4.18,
    "SEP": 3.85,
    "OCT": 4.07,
    "NOV": 4.85,
    "DEC": 4.85
  },
  {
    "LONG": -49.8,
    "LAT": -2.69,
    "CIDADE": "Witmarsum",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.85,
    "FEB": 4.97,
    "MAR": 4.82,
    "APR": 4.23,
    "MAY": 3.64,
    "JUN": 3.13,
    "JUL": 3.34,
    "AUG": 4.2,
    "SEP": 3.84,
    "OCT": 4.1,
    "NOV": 4.87,
    "DEC": 4.91
  },
  {
    "LONG": -49.37,
    "LAT": -26.92,
    "CIDADE": "Rodeio",
    "UF": "SANTA CATARINA",
    "anual": 4.16,
    "JAN": 4.79,
    "FEB": 4.88,
    "MAR": 4.74,
    "APR": 4.27,
    "MAY": 3.83,
    "JUN": 3.28,
    "JUL": 3.39,
    "AUG": 4.05,
    "SEP": 3.62,
    "OCT": 3.75,
    "NOV": 4.62,
    "DEC": 4.75
  },
  {
    "LONG": -49.24,
    "LAT": -26.9,
    "CIDADE": "Indaial",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.95,
    "FEB": 5,
    "MAR": 4.85,
    "APR": 4.39,
    "MAY": 3.9,
    "JUN": 3.32,
    "JUL": 3.44,
    "AUG": 4.05,
    "SEP": 3.68,
    "OCT": 3.81,
    "NOV": 4.68,
    "DEC": 4.84
  },
  {
    "LONG": -49.07,
    "LAT": -2.69,
    "CIDADE": "Blumenau",
    "UF": "SANTA CATARINA",
    "anual": 4.22,
    "JAN": 4.9,
    "FEB": 5.02,
    "MAR": 4.8,
    "APR": 4.33,
    "MAY": 3.9,
    "JUN": 3.34,
    "JUL": 3.46,
    "AUG": 3.99,
    "SEP": 3.66,
    "OCT": 3.75,
    "NOV": 4.62,
    "DEC": 4.84
  },
  {
    "LONG": -48.95,
    "LAT": -26.93,
    "CIDADE": "Gaspar",
    "UF": "SANTA CATARINA",
    "anual": 4.17,
    "JAN": 4.85,
    "FEB": 4.96,
    "MAR": 4.74,
    "APR": 4.28,
    "MAY": 3.87,
    "JUN": 3.31,
    "JUL": 3.43,
    "AUG": 3.94,
    "SEP": 3.63,
    "OCT": 3.7,
    "NOV": 4.57,
    "DEC": 4.8
  },
  {
    "LONG": -48.83,
    "LAT": -26.9,
    "CIDADE": "Ilhota",
    "UF": "SANTA CATARINA",
    "anual": 4.19,
    "JAN": 4.88,
    "FEB": 4.97,
    "MAR": 4.75,
    "APR": 4.27,
    "MAY": 3.86,
    "JUN": 3.33,
    "JUL": 3.45,
    "AUG": 3.94,
    "SEP": 3.63,
    "OCT": 3.76,
    "NOV": 4.59,
    "DEC": 4.79
  },
  {
    "LONG": -48.67,
    "LAT": -26.91,
    "CIDADE": "Itajaí",
    "UF": "SANTA CATARINA",
    "anual": 4.35,
    "JAN": 5.13,
    "FEB": 5.21,
    "MAR": 4.92,
    "APR": 4.36,
    "MAY": 3.9,
    "JUN": 3.38,
    "JUL": 3.46,
    "AUG": 3.97,
    "SEP": 3.8,
    "OCT": 4.13,
    "NOV": 4.91,
    "DEC": 5.07
  },
  {
    "LONG": -4.87,
    "LAT": -26.89,
    "CIDADE": "Navegantes",
    "UF": "SANTA CATARINA",
    "anual": 4.35,
    "JAN": 5.13,
    "FEB": 5.21,
    "MAR": 4.92,
    "APR": 4.36,
    "MAY": 3.9,
    "JUN": 3.38,
    "JUL": 3.46,
    "AUG": 3.97,
    "SEP": 3.8,
    "OCT": 4.13,
    "NOV": 4.91,
    "DEC": 5.07
  },
  {
    "LONG": -53.64,
    "LAT": -2.68,
    "CIDADE": "Bandeirante",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.52,
    "FEB": 5.51,
    "MAR": 5.41,
    "APR": 4.84,
    "MAY": 4.09,
    "JUN": 3.6,
    "JUL": 3.92,
    "AUG": 4.7,
    "SEP": 4.46,
    "OCT": 5.04,
    "NOV": 5.45,
    "DEC": 5.49
  },
  {
    "LONG": -53.5,
    "LAT": -26.83,
    "CIDADE": "Descanso",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.57,
    "FEB": 5.49,
    "MAR": 5.38,
    "APR": 4.88,
    "MAY": 4.11,
    "JUN": 3.65,
    "JUL": 3.99,
    "AUG": 4.75,
    "SEP": 4.49,
    "OCT": 5.06,
    "NOV": 5.43,
    "DEC": 5.54
  },
  {
    "LONG": -53.58,
    "LAT": -26.84,
    "CIDADE": "Belmonte",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.57,
    "FEB": 5.49,
    "MAR": 5.38,
    "APR": 4.88,
    "MAY": 4.11,
    "JUN": 3.65,
    "JUL": 3.99,
    "AUG": 4.75,
    "SEP": 4.49,
    "OCT": 5.06,
    "NOV": 5.43,
    "DEC": 5.54
  },
  {
    "LONG": -53.35,
    "LAT": -26.78,
    "CIDADE": "Flor do Sertão",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.55,
    "FEB": 5.54,
    "MAR": 5.47,
    "APR": 4.86,
    "MAY": 4.1,
    "JUN": 3.54,
    "JUL": 3.91,
    "AUG": 4.71,
    "SEP": 4.46,
    "OCT": 5.07,
    "NOV": 5.5,
    "DEC": 5.54
  },
  {
    "LONG": -53.28,
    "LAT": -2.68,
    "CIDADE": "Iraceminha",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.5,
    "FEB": 5.55,
    "MAR": 5.44,
    "APR": 4.98,
    "MAY": 4.12,
    "JUN": 3.63,
    "JUL": 3.96,
    "AUG": 4.73,
    "SEP": 4.49,
    "OCT": 5.05,
    "NOV": 5.48,
    "DEC": 5.52
  },
  {
    "LONG": -53.17,
    "LAT": -2.68,
    "CIDADE": "Maravilha",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.48,
    "FEB": 5.48,
    "MAR": 5.38,
    "APR": 4.93,
    "MAY": 4.13,
    "JUN": 3.63,
    "JUL": 3.98,
    "AUG": 4.76,
    "SEP": 4.49,
    "OCT": 5.02,
    "NOV": 5.46,
    "DEC": 5.5
  },
  {
    "LONG": -53.04,
    "LAT": -26.77,
    "CIDADE": "Modelo",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.46,
    "FEB": 5.41,
    "MAR": 5.36,
    "APR": 4.85,
    "MAY": 4.1,
    "JUN": 3.58,
    "JUL": 3.99,
    "AUG": 4.76,
    "SEP": 4.47,
    "OCT": 5.01,
    "NOV": 5.42,
    "DEC": 5.49
  },
  {
    "LONG": -52.99,
    "LAT": "#VALOR!",
    "CIDADE": "Pinhalzinho",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.42,
    "FEB": 5.38,
    "MAR": 5.43,
    "APR": 4.89,
    "MAY": 4.09,
    "JUN": 3.57,
    "JUL": 3.95,
    "AUG": 4.77,
    "SEP": 4.47,
    "OCT": 5.02,
    "NOV": 5.49,
    "DEC": 5.5
  },
  {
    "LONG": -52.85,
    "LAT": -26.76,
    "CIDADE": "União do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.43,
    "FEB": 5.43,
    "MAR": 5.46,
    "APR": 4.91,
    "MAY": 4.12,
    "JUN": 3.6,
    "JUL": 3.97,
    "AUG": 4.78,
    "SEP": 4.47,
    "OCT": 5.03,
    "NOV": 5.53,
    "DEC": 5.52
  },
  {
    "LONG": -52.63,
    "LAT": -26.8,
    "CIDADE": "Marema",
    "UF": "SANTA CATARINA",
    "anual": 4.91,
    "JAN": 5.59,
    "FEB": 5.49,
    "MAR": 5.55,
    "APR": 5,
    "MAY": 4.12,
    "JUN": 3.67,
    "JUL": 3.97,
    "AUG": 4.8,
    "SEP": 4.5,
    "OCT": 5.06,
    "NOV": 5.6,
    "DEC": 5.54
  },
  {
    "LONG": -52.26,
    "LAT": -26.85,
    "CIDADE": "Faxinal dos Guedes",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.49,
    "FEB": 5.28,
    "MAR": 5.34,
    "APR": 4.85,
    "MAY": 4.14,
    "JUN": 3.75,
    "JUL": 4.02,
    "AUG": 4.82,
    "SEP": 4.5,
    "OCT": 5.01,
    "NOV": 5.45,
    "DEC": 5.44
  },
  {
    "LONG": -52.06,
    "LAT": -26.78,
    "CIDADE": "Passos Maia",
    "UF": "SANTA CATARINA",
    "anual": 4.82,
    "JAN": 5.29,
    "FEB": 5.25,
    "MAR": 5.36,
    "APR": 4.88,
    "MAY": 4.09,
    "JUN": 3.75,
    "JUL": 4.04,
    "AUG": 4.84,
    "SEP": 4.52,
    "OCT": 4.98,
    "NOV": 5.46,
    "DEC": 5.42
  },
  {
    "LONG": -51.01,
    "LAT": -26.78,
    "CIDADE": "Caçador",
    "UF": "SANTA CATARINA",
    "anual": 4.52,
    "JAN": 4.88,
    "FEB": 4.93,
    "MAR": 4.96,
    "APR": 4.5,
    "MAY": 3.81,
    "JUN": 3.57,
    "JUL": 3.82,
    "AUG": 4.69,
    "SEP": 4.27,
    "OCT": 4.54,
    "NOV": 5.16,
    "DEC": 5.13
  },
  {
    "LONG": -50.01,
    "LAT": -26.78,
    "CIDADE": "Santa Terezinha",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.76,
    "FEB": 4.9,
    "MAR": 4.82,
    "APR": 4.19,
    "MAY": 3.65,
    "JUN": 3.18,
    "JUL": 3.48,
    "AUG": 4.3,
    "SEP": 3.89,
    "OCT": 4.08,
    "NOV": 4.87,
    "DEC": 4.87
  },
  {
    "LONG": -49.36,
    "LAT": -26.78,
    "CIDADE": "Benedito Novo",
    "UF": "SANTA CATARINA",
    "anual": 4.16,
    "JAN": 4.73,
    "FEB": 4.79,
    "MAR": 4.7,
    "APR": 4.27,
    "MAY": 3.86,
    "JUN": 3.34,
    "JUL": 3.45,
    "AUG": 4.11,
    "SEP": 3.62,
    "OCT": 3.77,
    "NOV": 4.6,
    "DEC": 4.69
  },
  {
    "LONG": -49.27,
    "LAT": -26.83,
    "CIDADE": "Timbó",
    "UF": "SANTA CATARINA",
    "anual": 4.18,
    "JAN": 4.86,
    "FEB": 4.88,
    "MAR": 4.73,
    "APR": 4.31,
    "MAY": 3.88,
    "JUN": 3.28,
    "JUL": 3.43,
    "AUG": 4.03,
    "SEP": 3.61,
    "OCT": 3.76,
    "NOV": 4.61,
    "DEC": 4.74
  },
  {
    "LONG": -48.65,
    "LAT": -26.78,
    "CIDADE": "Penha",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 5.13,
    "FEB": 5.21,
    "MAR": 4.95,
    "APR": 4.35,
    "MAY": 3.91,
    "JUN": 3.39,
    "JUL": 3.44,
    "AUG": 3.98,
    "SEP": 3.79,
    "OCT": 4.17,
    "NOV": 4.95,
    "DEC": 5.08
  },
  {
    "LONG": -48.68,
    "LAT": -26.77,
    "CIDADE": "Balneário Piçarras",
    "UF": "SANTA CATARINA",
    "anual": 4.36,
    "JAN": 5.13,
    "FEB": 5.21,
    "MAR": 4.95,
    "APR": 4.35,
    "MAY": 3.91,
    "JUN": 3.39,
    "JUL": 3.44,
    "AUG": 3.98,
    "SEP": 3.79,
    "OCT": 4.17,
    "NOV": 4.95,
    "DEC": 5.08
  },
  {
    "LONG": -53.52,
    "LAT": -26.72,
    "CIDADE": "São Miguel do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.59,
    "FEB": 5.49,
    "MAR": 5.4,
    "APR": 4.89,
    "MAY": 4.12,
    "JUN": 3.7,
    "JUL": 4.01,
    "AUG": 4.78,
    "SEP": 4.53,
    "OCT": 5.06,
    "NOV": 5.45,
    "DEC": 5.55
  },
  {
    "LONG": -53.44,
    "LAT": -26.65,
    "CIDADE": "Barra Bonita",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.55,
    "FEB": 5.49,
    "MAR": 5.39,
    "APR": 4.87,
    "MAY": 4.1,
    "JUN": 3.63,
    "JUL": 3.96,
    "AUG": 4.75,
    "SEP": 4.5,
    "OCT": 5.05,
    "NOV": 5.47,
    "DEC": 5.51
  },
  {
    "LONG": -53.32,
    "LAT": -26.68,
    "CIDADE": "Romelândia",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.57,
    "FEB": 5.54,
    "MAR": 5.46,
    "APR": 4.89,
    "MAY": 4.12,
    "JUN": 3.58,
    "JUL": 3.91,
    "AUG": 4.73,
    "SEP": 4.49,
    "OCT": 5.05,
    "NOV": 5.5,
    "DEC": 5.53
  },
  {
    "LONG": -53.25,
    "LAT": -26.69,
    "CIDADE": "São Miguel da Boa Vista",
    "UF": "SANTA CATARINA",
    "anual": 4.89,
    "JAN": 5.55,
    "FEB": 5.52,
    "MAR": 5.46,
    "APR": 4.94,
    "MAY": 4.13,
    "JUN": 3.68,
    "JUL": 3.97,
    "AUG": 4.79,
    "SEP": 4.52,
    "OCT": 5.04,
    "NOV": 5.5,
    "DEC": 5.54
  },
  {
    "LONG": -5.32,
    "LAT": -26.69,
    "CIDADE": "Tigrinhos",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.5,
    "FEB": 5.48,
    "MAR": 5.44,
    "APR": 4.93,
    "MAY": 4.13,
    "JUN": 3.69,
    "JUL": 3.99,
    "AUG": 4.79,
    "SEP": 4.51,
    "OCT": 5.02,
    "NOV": 5.48,
    "DEC": 5.51
  },
  {
    "LONG": -53.04,
    "LAT": -26.72,
    "CIDADE": "Serra Alta",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.42,
    "FEB": 5.38,
    "MAR": 5.38,
    "APR": 4.88,
    "MAY": 4.13,
    "JUN": 3.65,
    "JUL": 4.02,
    "AUG": 4.79,
    "SEP": 4.52,
    "OCT": 5.01,
    "NOV": 5.45,
    "DEC": 5.47
  },
  {
    "LONG": -53.1,
    "LAT": -26.69,
    "CIDADE": "Bom Jesus do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.84,
    "JAN": 5.42,
    "FEB": 5.38,
    "MAR": 5.38,
    "APR": 4.88,
    "MAY": 4.13,
    "JUN": 3.65,
    "JUL": 4.02,
    "AUG": 4.79,
    "SEP": 4.52,
    "OCT": 5.01,
    "NOV": 5.45,
    "DEC": 5.47
  },
  {
    "LONG": -52.96,
    "LAT": -26.74,
    "CIDADE": "Sul Brasil",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.42,
    "FEB": 5.4,
    "MAR": 5.41,
    "APR": 4.89,
    "MAY": 4.08,
    "JUN": 3.6,
    "JUL": 4.04,
    "AUG": 4.8,
    "SEP": 4.52,
    "OCT": 5.03,
    "NOV": 5.49,
    "DEC": 5.47
  },
  {
    "LONG": -5.29,
    "LAT": -26.72,
    "CIDADE": "Jardinópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.46,
    "FEB": 5.38,
    "MAR": 5.43,
    "APR": 4.91,
    "MAY": 4.1,
    "JUN": 3.63,
    "JUL": 4.04,
    "AUG": 4.82,
    "SEP": 4.52,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.49
  },
  {
    "LONG": -5.29,
    "LAT": -26.65,
    "CIDADE": "Irati",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.46,
    "FEB": 5.38,
    "MAR": 5.43,
    "APR": 4.91,
    "MAY": 4.1,
    "JUN": 3.63,
    "JUL": 4.04,
    "AUG": 4.82,
    "SEP": 4.52,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.49
  },
  {
    "LONG": -52.72,
    "LAT": -26.73,
    "CIDADE": "Quilombo",
    "UF": "SANTA CATARINA",
    "anual": 4.85,
    "JAN": 5.42,
    "FEB": 5.35,
    "MAR": 5.42,
    "APR": 4.89,
    "MAY": 4.12,
    "JUN": 3.66,
    "JUL": 3.99,
    "AUG": 4.83,
    "SEP": 4.51,
    "OCT": 5.01,
    "NOV": 5.5,
    "DEC": 5.47
  },
  {
    "LONG": -5.26,
    "LAT": -26.72,
    "CIDADE": "Entre Rios",
    "UF": "SANTA CATARINA",
    "anual": 4.89,
    "JAN": 5.53,
    "FEB": 5.41,
    "MAR": 5.47,
    "APR": 4.95,
    "MAY": 4.17,
    "JUN": 3.69,
    "JUL": 3.99,
    "AUG": 4.85,
    "SEP": 4.53,
    "OCT": 5.04,
    "NOV": 5.57,
    "DEC": 5.49
  },
  {
    "LONG": -52.31,
    "LAT": -26.69,
    "CIDADE": "Ouro Verde",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.47,
    "FEB": 5.29,
    "MAR": 5.32,
    "APR": 4.91,
    "MAY": 4.18,
    "JUN": 3.77,
    "JUL": 4.05,
    "AUG": 4.86,
    "SEP": 4.53,
    "OCT": 5.01,
    "NOV": 5.49,
    "DEC": 5.42
  },
  {
    "LONG": -52.39,
    "LAT": -26.73,
    "CIDADE": "Bom Jesus",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.47,
    "FEB": 5.29,
    "MAR": 5.32,
    "APR": 4.91,
    "MAY": 4.18,
    "JUN": 3.77,
    "JUL": 4.05,
    "AUG": 4.86,
    "SEP": 4.53,
    "OCT": 5.01,
    "NOV": 5.49,
    "DEC": 5.42
  },
  {
    "LONG": -49.48,
    "LAT": -26.72,
    "CIDADE": "Doutor Pedrinho",
    "UF": "SANTA CATARINA",
    "anual": 4.13,
    "JAN": 4.62,
    "FEB": 4.62,
    "MAR": 4.59,
    "APR": 4.15,
    "MAY": 3.74,
    "JUN": 3.36,
    "JUL": 3.51,
    "AUG": 4.26,
    "SEP": 3.71,
    "OCT": 3.87,
    "NOV": 4.57,
    "DEC": 4.63
  },
  {
    "LONG": -49.27,
    "LAT": -26.74,
    "CIDADE": "Rio dos Cedros",
    "UF": "SANTA CATARINA",
    "anual": 4.08,
    "JAN": 4.67,
    "FEB": 4.73,
    "MAR": 4.58,
    "APR": 4.18,
    "MAY": 3.77,
    "JUN": 3.26,
    "JUL": 3.4,
    "AUG": 4.05,
    "SEP": 3.55,
    "OCT": 3.68,
    "NOV": 4.47,
    "DEC": 4.63
  },
  {
    "LONG": -49.18,
    "LAT": -26.74,
    "CIDADE": "Pomerode",
    "UF": "SANTA CATARINA",
    "anual": 4.08,
    "JAN": 4.71,
    "FEB": 4.81,
    "MAR": 4.64,
    "APR": 4.19,
    "MAY": 3.78,
    "JUN": 3.24,
    "JUL": 3.37,
    "AUG": 3.95,
    "SEP": 3.53,
    "OCT": 3.64,
    "NOV": 4.47,
    "DEC": 4.66
  },
  {
    "LONG": -48.93,
    "LAT": -26.72,
    "CIDADE": "Luiz Alves",
    "UF": "SANTA CATARINA",
    "anual": 4.13,
    "JAN": 4.77,
    "FEB": 4.91,
    "MAR": 4.71,
    "APR": 4.26,
    "MAY": 3.86,
    "JUN": 3.32,
    "JUL": 3.4,
    "AUG": 3.91,
    "SEP": 3.56,
    "OCT": 3.68,
    "NOV": 4.5,
    "DEC": 4.7
  },
  {
    "LONG": -53.67,
    "LAT": -26.62,
    "CIDADE": "Paraíso",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.57,
    "FEB": 5.52,
    "MAR": 5.43,
    "APR": 4.88,
    "MAY": 4.11,
    "JUN": 3.69,
    "JUL": 3.98,
    "AUG": 4.78,
    "SEP": 4.54,
    "OCT": 5.06,
    "NOV": 5.45,
    "DEC": 5.5
  },
  {
    "LONG": -53.52,
    "LAT": -26.6,
    "CIDADE": "Guaraciaba",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.57,
    "FEB": 5.46,
    "MAR": 5.42,
    "APR": 4.9,
    "MAY": 4.14,
    "JUN": 3.68,
    "JUL": 3.98,
    "AUG": 4.8,
    "SEP": 4.57,
    "OCT": 5.09,
    "NOV": 5.45,
    "DEC": 5.52
  },
  {
    "LONG": -53.2,
    "LAT": -26.62,
    "CIDADE": "Santa Terezinha do Progresso",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.47,
    "FEB": 5.49,
    "MAR": 5.41,
    "APR": 4.91,
    "MAY": 4.15,
    "JUN": 3.68,
    "JUL": 4,
    "AUG": 4.81,
    "SEP": 4.54,
    "OCT": 5.02,
    "NOV": 5.47,
    "DEC": 5.47
  },
  {
    "LONG": -53.06,
    "LAT": -26.61,
    "CIDADE": "Saltinho",
    "UF": "SANTA CATARINA",
    "anual": 4.83,
    "JAN": 5.43,
    "FEB": 5.39,
    "MAR": 5.35,
    "APR": 4.87,
    "MAY": 4.12,
    "JUN": 3.67,
    "JUL": 4.03,
    "AUG": 4.83,
    "SEP": 4.53,
    "OCT": 4.99,
    "NOV": 5.38,
    "DEC": 5.42
  },
  {
    "LONG": -52.8,
    "LAT": -26.65,
    "CIDADE": "Formosa do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.44,
    "FEB": 5.36,
    "MAR": 5.41,
    "APR": 4.92,
    "MAY": 4.13,
    "JUN": 3.7,
    "JUL": 4.03,
    "AUG": 4.86,
    "SEP": 4.56,
    "OCT": 5.03,
    "NOV": 5.48,
    "DEC": 5.43
  },
  {
    "LONG": -52.68,
    "LAT": -26.64,
    "CIDADE": "Santiago do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.4,
    "FEB": 5.41,
    "MAR": 5.46,
    "APR": 4.93,
    "MAY": 4.14,
    "JUN": 3.7,
    "JUL": 4.04,
    "AUG": 4.89,
    "SEP": 4.55,
    "OCT": 5.04,
    "NOV": 5.51,
    "DEC": 5.5
  },
  {
    "LONG": -52.53,
    "LAT": -26.56,
    "CIDADE": "São Domingos",
    "UF": "SANTA CATARINA",
    "anual": 4.87,
    "JAN": 5.4,
    "FEB": 5.37,
    "MAR": 5.41,
    "APR": 4.91,
    "MAY": 4.15,
    "JUN": 3.72,
    "JUL": 4.04,
    "AUG": 4.89,
    "SEP": 4.56,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.47
  },
  {
    "LONG": -52.46,
    "LAT": -26.63,
    "CIDADE": "Ipuaçu",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.45,
    "FEB": 5.35,
    "MAR": 5.34,
    "APR": 4.88,
    "MAY": 4.16,
    "JUN": 3.76,
    "JUL": 4.03,
    "AUG": 4.87,
    "SEP": 4.54,
    "OCT": 5,
    "NOV": 5.51,
    "DEC": 5.48
  },
  {
    "LONG": -52.32,
    "LAT": -26.57,
    "CIDADE": "Abelardo Luz",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.43,
    "FEB": 5.35,
    "MAR": 5.3,
    "APR": 4.91,
    "MAY": 4.19,
    "JUN": 3.77,
    "JUL": 4.06,
    "AUG": 4.9,
    "SEP": 4.54,
    "OCT": 5.01,
    "NOV": 5.46,
    "DEC": 5.43
  },
  {
    "LONG": -51.1,
    "LAT": -26.59,
    "CIDADE": "Calmon",
    "UF": "SANTA CATARINA",
    "anual": 4.43,
    "JAN": 4.81,
    "FEB": 4.89,
    "MAR": 4.83,
    "APR": 4.32,
    "MAY": 3.77,
    "JUN": 3.48,
    "JUL": 3.74,
    "AUG": 4.67,
    "SEP": 4.23,
    "OCT": 4.43,
    "NOV": 5.01,
    "DEC": 5.02
  },
  {
    "LONG": -50.66,
    "LAT": -26.61,
    "CIDADE": "Timbó Grande",
    "UF": "SANTA CATARINA",
    "anual": 4.32,
    "JAN": 4.8,
    "FEB": 4.87,
    "MAR": 4.75,
    "APR": 4.21,
    "MAY": 3.65,
    "JUN": 3.3,
    "JUL": 3.61,
    "AUG": 4.53,
    "SEP": 4.1,
    "OCT": 4.26,
    "NOV": 4.93,
    "DEC": 4.85
  },
  {
    "LONG": -49.01,
    "LAT": -26.61,
    "CIDADE": "Massaranduba",
    "UF": "SANTA CATARINA",
    "anual": 4.13,
    "JAN": 4.72,
    "FEB": 4.89,
    "MAR": 4.69,
    "APR": 4.23,
    "MAY": 3.82,
    "JUN": 3.31,
    "JUL": 3.39,
    "AUG": 3.97,
    "SEP": 3.57,
    "OCT": 3.75,
    "NOV": 4.52,
    "DEC": 4.71
  },
  {
    "LONG": -48.77,
    "LAT": -26.62,
    "CIDADE": "São João do Itaperiú",
    "UF": "SANTA CATARINA",
    "anual": 4.2,
    "JAN": 4.89,
    "FEB": 5.04,
    "MAR": 4.8,
    "APR": 4.35,
    "MAY": 3.86,
    "JUN": 3.28,
    "JUL": 3.36,
    "AUG": 3.86,
    "SEP": 3.64,
    "OCT": 3.91,
    "NOV": 4.65,
    "DEC": 4.83
  },
  {
    "LONG": -48.69,
    "LAT": -26.64,
    "CIDADE": "Barra Velha",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 5.1,
    "FEB": 5.25,
    "MAR": 4.98,
    "APR": 4.46,
    "MAY": 3.92,
    "JUN": 3.36,
    "JUL": 3.4,
    "AUG": 3.91,
    "SEP": 3.73,
    "OCT": 4.1,
    "NOV": 4.84,
    "DEC": 5.03
  },
  {
    "LONG": -53.5,
    "LAT": -26.46,
    "CIDADE": "São José do Cedro",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.54,
    "FEB": 5.5,
    "MAR": 5.39,
    "APR": 4.89,
    "MAY": 4.16,
    "JUN": 3.7,
    "JUL": 4.01,
    "AUG": 4.84,
    "SEP": 4.58,
    "OCT": 5.06,
    "NOV": 5.44,
    "DEC": 5.48
  },
  {
    "LONG": -53.33,
    "LAT": -26.54,
    "CIDADE": "Anchieta",
    "UF": "SANTA CATARINA",
    "anual": 4.9,
    "JAN": 5.53,
    "FEB": 5.51,
    "MAR": 5.43,
    "APR": 4.94,
    "MAY": 4.18,
    "JUN": 3.72,
    "JUL": 4.04,
    "AUG": 4.86,
    "SEP": 4.59,
    "OCT": 5.06,
    "NOV": 5.49,
    "DEC": 5.5
  },
  {
    "LONG": -52.97,
    "LAT": -26.47,
    "CIDADE": "São Bernardino",
    "UF": "SANTA CATARINA",
    "anual": 4.86,
    "JAN": 5.45,
    "FEB": 5.4,
    "MAR": 5.38,
    "APR": 4.9,
    "MAY": 4.14,
    "JUN": 3.71,
    "JUL": 4.04,
    "AUG": 4.9,
    "SEP": 4.56,
    "OCT": 5.01,
    "NOV": 5.45,
    "DEC": 5.43
  },
  {
    "LONG": -5.27,
    "LAT": -26.46,
    "CIDADE": "Galvão",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.43,
    "FEB": 5.4,
    "MAR": 5.39,
    "APR": 4.94,
    "MAY": 4.19,
    "JUN": 3.73,
    "JUL": 4.07,
    "AUG": 4.91,
    "SEP": 4.57,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.44
  },
  {
    "LONG": -52.67,
    "LAT": -26.51,
    "CIDADE": "Coronel Martins",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.43,
    "FEB": 5.4,
    "MAR": 5.39,
    "APR": 4.94,
    "MAY": 4.19,
    "JUN": 3.73,
    "JUL": 4.07,
    "AUG": 4.91,
    "SEP": 4.57,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.44
  },
  {
    "LONG": -51.15,
    "LAT": -26.47,
    "CIDADE": "Matos Costa",
    "UF": "SANTA CATARINA",
    "anual": 4.42,
    "JAN": 4.85,
    "FEB": 4.91,
    "MAR": 4.84,
    "APR": 4.3,
    "MAY": 3.74,
    "JUN": 3.43,
    "JUL": 3.71,
    "AUG": 4.66,
    "SEP": 4.22,
    "OCT": 4.43,
    "NOV": 5,
    "DEC": 5.01
  },
  {
    "LONG": -50.23,
    "LAT": -26.46,
    "CIDADE": "Monte Castelo",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 4.86,
    "FEB": 4.85,
    "MAR": 4.79,
    "APR": 4.16,
    "MAY": 3.63,
    "JUN": 3.24,
    "JUL": 3.52,
    "AUG": 4.41,
    "SEP": 4.03,
    "OCT": 4.18,
    "NOV": 4.89,
    "DEC": 4.87
  },
  {
    "LONG": -49,
    "LAT": -26.47,
    "CIDADE": "Guaramirim",
    "UF": "SANTA CATARINA",
    "anual": 4.13,
    "JAN": 4.72,
    "FEB": 4.85,
    "MAR": 4.67,
    "APR": 4.25,
    "MAY": 3.84,
    "JUN": 3.31,
    "JUL": 3.38,
    "AUG": 3.99,
    "SEP": 3.58,
    "OCT": 3.77,
    "NOV": 4.53,
    "DEC": 4.68
  },
  {
    "LONG": -49.07,
    "LAT": -26.49,
    "CIDADE": "Jaraguá do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.13,
    "JAN": 4.72,
    "FEB": 4.85,
    "MAR": 4.67,
    "APR": 4.25,
    "MAY": 3.84,
    "JUN": 3.31,
    "JUL": 3.38,
    "AUG": 3.99,
    "SEP": 3.58,
    "OCT": 3.77,
    "NOV": 4.53,
    "DEC": 4.68
  },
  {
    "LONG": -48.61,
    "LAT": -26.46,
    "CIDADE": "Balneário Barra do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.25,
    "JAN": 4.95,
    "FEB": 5.13,
    "MAR": 4.88,
    "APR": 4.38,
    "MAY": 3.87,
    "JUN": 3.32,
    "JUL": 3.34,
    "AUG": 3.89,
    "SEP": 3.66,
    "OCT": 3.99,
    "NOV": 4.71,
    "DEC": 4.92
  },
  {
    "LONG": -53.6,
    "LAT": -26.44,
    "CIDADE": "Princesa",
    "UF": "SANTA CATARINA",
    "anual": 4.92,
    "JAN": 5.58,
    "FEB": 5.56,
    "MAR": 5.46,
    "APR": 4.9,
    "MAY": 4.16,
    "JUN": 3.74,
    "JUL": 4.04,
    "AUG": 4.85,
    "SEP": 4.62,
    "OCT": 5.11,
    "NOV": 5.47,
    "DEC": 5.5
  },
  {
    "LONG": "#VALOR!",
    "LAT": -26.39,
    "CIDADE": "Guarujá do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.91,
    "JAN": 5.55,
    "FEB": 5.49,
    "MAR": 5.42,
    "APR": 4.91,
    "MAY": 4.19,
    "JUN": 3.75,
    "JUL": 4.07,
    "AUG": 4.9,
    "SEP": 4.62,
    "OCT": 5.09,
    "NOV": 5.43,
    "DEC": 5.5
  },
  {
    "LONG": -53.09,
    "LAT": -26.39,
    "CIDADE": "Campo Erê",
    "UF": "SANTA CATARINA",
    "anual": 4.89,
    "JAN": 5.48,
    "FEB": 5.38,
    "MAR": 5.36,
    "APR": 4.91,
    "MAY": 4.2,
    "JUN": 3.75,
    "JUL": 4.07,
    "AUG": 4.95,
    "SEP": 4.62,
    "OCT": 5.05,
    "NOV": 5.45,
    "DEC": 5.43
  },
  {
    "LONG": -52.85,
    "LAT": -26.36,
    "CIDADE": "São Lourenço do Oeste",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.42,
    "FEB": 5.4,
    "MAR": 5.37,
    "APR": 4.92,
    "MAY": 4.18,
    "JUN": 3.76,
    "JUL": 4.1,
    "AUG": 4.95,
    "SEP": 4.61,
    "OCT": 5.02,
    "NOV": 5.46,
    "DEC": 5.42
  },
  {
    "LONG": -52.83,
    "LAT": -26.44,
    "CIDADE": "Novo Horizonte",
    "UF": "SANTA CATARINA",
    "anual": 4.88,
    "JAN": 5.42,
    "FEB": 5.4,
    "MAR": 5.37,
    "APR": 4.92,
    "MAY": 4.18,
    "JUN": 3.76,
    "JUL": 4.1,
    "AUG": 4.95,
    "SEP": 4.61,
    "OCT": 5.02,
    "NOV": 5.46,
    "DEC": 5.42
  },
  {
    "LONG": -52.73,
    "LAT": -26.4,
    "CIDADE": "Jupiá",
    "UF": "SANTA CATARINA",
    "anual": 4.9,
    "JAN": 5.45,
    "FEB": 5.38,
    "MAR": 5.38,
    "APR": 4.94,
    "MAY": 4.19,
    "JUN": 3.79,
    "JUL": 4.1,
    "AUG": 4.97,
    "SEP": 4.6,
    "OCT": 5.03,
    "NOV": 5.5,
    "DEC": 5.43
  },
  {
    "LONG": -5.03,
    "LAT": -26.37,
    "CIDADE": "Major Vieira",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.86,
    "FEB": 4.83,
    "MAR": 4.78,
    "APR": 4.12,
    "MAY": 3.61,
    "JUN": 3.22,
    "JUL": 3.49,
    "AUG": 4.44,
    "SEP": 4.04,
    "OCT": 4.2,
    "NOV": 4.88,
    "DEC": 4.9
  },
  {
    "LONG": -50.14,
    "LAT": -26.41,
    "CIDADE": "Papanduva",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.78,
    "FEB": 4.83,
    "MAR": 4.74,
    "APR": 4.11,
    "MAY": 3.63,
    "JUN": 3.25,
    "JUL": 3.5,
    "AUG": 4.46,
    "SEP": 4.06,
    "OCT": 4.22,
    "NOV": 4.85,
    "DEC": 4.87
  },
  {
    "LONG": -49.25,
    "LAT": -26.43,
    "CIDADE": "Corupá",
    "UF": "SANTA CATARINA",
    "anual": 4.16,
    "JAN": 4.64,
    "FEB": 4.74,
    "MAR": 4.65,
    "APR": 4.22,
    "MAY": 3.79,
    "JUN": 3.35,
    "JUL": 3.51,
    "AUG": 4.24,
    "SEP": 3.68,
    "OCT": 3.88,
    "NOV": 4.56,
    "DEC": 4.69
  },
  {
    "LONG": -49.07,
    "LAT": -26.41,
    "CIDADE": "Schroeder",
    "UF": "SANTA CATARINA",
    "anual": 3.99,
    "JAN": 4.5,
    "FEB": 4.59,
    "MAR": 4.46,
    "APR": 4.12,
    "MAY": 3.74,
    "JUN": 3.26,
    "JUL": 3.34,
    "AUG": 3.98,
    "SEP": 3.44,
    "OCT": 3.63,
    "NOV": 4.35,
    "DEC": 4.45
  },
  {
    "LONG": -48.72,
    "LAT": -26.38,
    "CIDADE": "Araquari",
    "UF": "SANTA CATARINA",
    "anual": 4.12,
    "JAN": 4.74,
    "FEB": 4.92,
    "MAR": 4.68,
    "APR": 4.26,
    "MAY": 3.82,
    "JUN": 3.27,
    "JUL": 3.31,
    "AUG": 3.86,
    "SEP": 3.55,
    "OCT": 3.85,
    "NOV": 4.54,
    "DEC": 4.71
  },
  {
    "LONG": -53.64,
    "LAT": -26.27,
    "CIDADE": "Dionísio Cerqueira",
    "UF": "SANTA CATARINA",
    "anual": 4.93,
    "JAN": 5.6,
    "FEB": 5.54,
    "MAR": 5.48,
    "APR": 4.91,
    "MAY": 4.16,
    "JUN": 3.74,
    "JUL": 4.06,
    "AUG": 4.91,
    "SEP": 4.64,
    "OCT": 5.12,
    "NOV": 5.46,
    "DEC": 5.49
  },
  {
    "LONG": -53.28,
    "LAT": -26.35,
    "CIDADE": "Palma Sola",
    "UF": "SANTA CATARINA",
    "anual": 4.9,
    "JAN": 5.5,
    "FEB": 5.4,
    "MAR": 5.41,
    "APR": 4.92,
    "MAY": 4.19,
    "JUN": 3.74,
    "JUL": 4.1,
    "AUG": 4.97,
    "SEP": 4.66,
    "OCT": 5.08,
    "NOV": 5.44,
    "DEC": 5.44
  },
  {
    "LONG": -50.47,
    "LAT": -26.28,
    "CIDADE": "Bela Vista do Toldo",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.83,
    "FEB": 4.84,
    "MAR": 4.74,
    "APR": 4.09,
    "MAY": 3.62,
    "JUN": 3.16,
    "JUL": 3.45,
    "AUG": 4.45,
    "SEP": 4.09,
    "OCT": 4.25,
    "NOV": 4.9,
    "DEC": 4.91
  },
  {
    "LONG": -49.91,
    "LAT": -26.34,
    "CIDADE": "Itaiópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.34,
    "JAN": 4.86,
    "FEB": 4.86,
    "MAR": 4.81,
    "APR": 4.18,
    "MAY": 3.71,
    "JUN": 3.31,
    "JUL": 3.63,
    "AUG": 4.54,
    "SEP": 4.09,
    "OCT": 4.3,
    "NOV": 4.87,
    "DEC": 4.95
  },
  {
    "LONG": -49.52,
    "LAT": -26.26,
    "CIDADE": "Rio Negrinho",
    "UF": "SANTA CATARINA",
    "anual": 4.29,
    "JAN": 4.78,
    "FEB": 4.86,
    "MAR": 4.71,
    "APR": 4.2,
    "MAY": 3.68,
    "JUN": 3.33,
    "JUL": 3.62,
    "AUG": 4.5,
    "SEP": 4.02,
    "OCT": 4.21,
    "NOV": 4.74,
    "DEC": 4.88
  },
  {
    "LONG": -48.85,
    "LAT": -2.63,
    "CIDADE": "Joinville",
    "UF": "SANTA CATARINA",
    "anual": 3.98,
    "JAN": 4.53,
    "FEB": 4.64,
    "MAR": 4.46,
    "APR": 4.11,
    "MAY": 3.74,
    "JUN": 3.24,
    "JUL": 3.26,
    "AUG": 3.85,
    "SEP": 3.43,
    "OCT": 3.64,
    "NOV": 4.36,
    "DEC": 4.49
  },
  {
    "LONG": -48.63,
    "LAT": -26.26,
    "CIDADE": "São Francisco do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.11,
    "JAN": 4.73,
    "FEB": 4.93,
    "MAR": 4.67,
    "APR": 4.27,
    "MAY": 3.83,
    "JUN": 3.25,
    "JUL": 3.25,
    "AUG": 3.81,
    "SEP": 3.51,
    "OCT": 3.83,
    "NOV": 4.5,
    "DEC": 4.73
  },
  {
    "LONG": -51.08,
    "LAT": -26.25,
    "CIDADE": "Porto União",
    "UF": "SANTA CATARINA",
    "anual": 4.33,
    "JAN": 4.9,
    "FEB": 4.87,
    "MAR": 4.82,
    "APR": 4.16,
    "MAY": 3.63,
    "JUN": 3.19,
    "JUL": 3.43,
    "AUG": 4.5,
    "SEP": 4.16,
    "OCT": 4.4,
    "NOV": 4.98,
    "DEC": 4.96
  },
  {
    "LONG": -50.8,
    "LAT": -26.24,
    "CIDADE": "Irineópolis",
    "UF": "SANTA CATARINA",
    "anual": 4.24,
    "JAN": 4.75,
    "FEB": 4.79,
    "MAR": 4.73,
    "APR": 4.09,
    "MAY": 3.52,
    "JUN": 3.12,
    "JUL": 3.39,
    "AUG": 4.4,
    "SEP": 4.1,
    "OCT": 4.25,
    "NOV": 4.88,
    "DEC": 4.86
  },
  {
    "LONG": -50.4,
    "LAT": -26.18,
    "CIDADE": "Canoinhas",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.85,
    "FEB": 4.88,
    "MAR": 4.73,
    "APR": 4.08,
    "MAY": 3.56,
    "JUN": 3.19,
    "JUL": 3.45,
    "AUG": 4.47,
    "SEP": 4.09,
    "OCT": 4.27,
    "NOV": 4.87,
    "DEC": 4.91
  },
  {
    "LONG": -49.38,
    "LAT": "#VALOR!",
    "CIDADE": "São Bento do Sul",
    "UF": "SANTA CATARINA",
    "anual": 4.28,
    "JAN": 4.73,
    "FEB": 4.78,
    "MAR": 4.62,
    "APR": 4.21,
    "MAY": 3.73,
    "JUN": 3.43,
    "JUL": 3.65,
    "AUG": 4.51,
    "SEP": 4.02,
    "OCT": 4.16,
    "NOV": 4.68,
    "DEC": 4.8
  },
  {
    "LONG": -4.93,
    "LAT": -26.2,
    "CIDADE": "Campo Alegre",
    "UF": "SANTA CATARINA",
    "anual": 4.27,
    "JAN": 4.73,
    "FEB": 4.76,
    "MAR": 4.59,
    "APR": 4.22,
    "MAY": 3.75,
    "JUN": 3.45,
    "JUL": 3.68,
    "AUG": 4.51,
    "SEP": 3.98,
    "OCT": 4.13,
    "NOV": 4.65,
    "DEC": 4.79
  },
  {
    "LONG": -50.32,
    "LAT": -26.11,
    "CIDADE": "Três Barras",
    "UF": "SANTA CATARINA",
    "anual": 4.31,
    "JAN": 4.86,
    "FEB": 4.91,
    "MAR": 4.76,
    "APR": 4.12,
    "MAY": 3.59,
    "JUN": 3.21,
    "JUL": 3.49,
    "AUG": 4.52,
    "SEP": 4.15,
    "OCT": 4.31,
    "NOV": 4.91,
    "DEC": 4.93
  },
  {
    "LONG": -49.81,
    "LAT": -26.12,
    "CIDADE": "Mafra",
    "UF": "SANTA CATARINA",
    "anual": 4.35,
    "JAN": 4.92,
    "FEB": 4.94,
    "MAR": 4.79,
    "APR": 4.23,
    "MAY": 3.66,
    "JUN": 3.29,
    "JUL": 3.58,
    "AUG": 4.58,
    "SEP": 4.11,
    "OCT": 4.34,
    "NOV": 4.85,
    "DEC": 4.96
  },
  {
    "LONG": -48.62,
    "LAT": -26.12,
    "CIDADE": "Itapoá",
    "UF": "SANTA CATARINA",
    "anual": 4.08,
    "JAN": 4.75,
    "FEB": 4.89,
    "MAR": 4.62,
    "APR": 4.3,
    "MAY": 3.8,
    "JUN": 3.26,
    "JUL": 3.23,
    "AUG": 3.82,
    "SEP": 3.47,
    "OCT": 3.74,
    "NOV": 4.42,
    "DEC": 4.66
  },
  {
    "LONG": -48.85,
    "LAT": -26.03,
    "CIDADE": "Garuva",
    "UF": "SANTA CATARINA",
    "anual": 3.78,
    "JAN": 4.24,
    "FEB": 4.34,
    "MAR": 4.13,
    "APR": 3.84,
    "MAY": 3.59,
    "JUN": 3.2,
    "JUL": 3.27,
    "AUG": 3.91,
    "SEP": 3.28,
    "OCT": 3.38,
    "NOV": 4.02,
    "DEC": 4.18
  },
  {
    "LONG": -47.93,
    "LAT": -25.01,
    "CIDADE": "Cananéia",
    "UF": "SÃO PAULO",
    "anual": 4.11,
    "JAN": 4.72,
    "FEB": 4.99,
    "MAR": 4.57,
    "APR": 4.34,
    "MAY": 3.76,
    "JUN": 3.37,
    "JUL": 3.33,
    "AUG": 3.89,
    "SEP": 3.58,
    "OCT": 3.68,
    "NOV": 4.39,
    "DEC": 4.72
  },
  {
    "LONG": -48.5,
    "LAT": -2.48,
    "CIDADE": "Barra do Turvo",
    "UF": "SÃO PAULO",
    "anual": 4.23,
    "JAN": 4.74,
    "FEB": 4.99,
    "MAR": 4.66,
    "APR": 4.33,
    "MAY": 3.77,
    "JUN": 3.38,
    "JUL": 3.41,
    "AUG": 4.24,
    "SEP": 3.85,
    "OCT": 4.06,
    "NOV": 4.51,
    "DEC": 4.85
  },
  {
    "LONG": -4.9,
    "LAT": -24.65,
    "CIDADE": "Ribeira",
    "UF": "SÃO PAULO",
    "anual": 4.43,
    "JAN": 4.89,
    "FEB": 5.14,
    "MAR": 4.89,
    "APR": 4.49,
    "MAY": 3.85,
    "JUN": 3.46,
    "JUL": 3.5,
    "AUG": 4.47,
    "SEP": 4.17,
    "OCT": 4.35,
    "NOV": 4.84,
    "DEC": 5.05
  },
  {
    "LONG": -48.11,
    "LAT": -24.73,
    "CIDADE": "Cajati",
    "UF": "SÃO PAULO",
    "anual": 4.28,
    "JAN": 4.87,
    "FEB": 5.19,
    "MAR": 4.72,
    "APR": 4.53,
    "MAY": 3.89,
    "JUN": 3.58,
    "JUL": 3.49,
    "AUG": 4.07,
    "SEP": 3.78,
    "OCT": 3.89,
    "NOV": 4.46,
    "DEC": 4.86
  },
  {
    "LONG": -48.01,
    "LAT": -24.7,
    "CIDADE": "Jacupiranga",
    "UF": "SÃO PAULO",
    "anual": 4.25,
    "JAN": 4.87,
    "FEB": 5.16,
    "MAR": 4.73,
    "APR": 4.47,
    "MAY": 3.91,
    "JUN": 3.51,
    "JUL": 3.42,
    "AUG": 4,
    "SEP": 3.74,
    "OCT": 3.91,
    "NOV": 4.46,
    "DEC": 4.82
  },
  {
    "LONG": -47.88,
    "LAT": -2.47,
    "CIDADE": "Pariquera-Açu",
    "UF": "SÃO PAULO",
    "anual": 4.23,
    "JAN": 4.85,
    "FEB": 5.18,
    "MAR": 4.7,
    "APR": 4.47,
    "MAY": 3.84,
    "JUN": 3.46,
    "JUL": 3.37,
    "AUG": 3.95,
    "SEP": 3.72,
    "OCT": 3.87,
    "NOV": 4.48,
    "DEC": 4.89
  },
  {
    "LONG": -4.76,
    "LAT": -2.47,
    "CIDADE": "Iguape",
    "UF": "SÃO PAULO",
    "anual": 4.27,
    "JAN": 4.99,
    "FEB": 5.32,
    "MAR": 4.8,
    "APR": 4.54,
    "MAY": 3.81,
    "JUN": 3.39,
    "JUL": 3.28,
    "AUG": 3.87,
    "SEP": 3.74,
    "OCT": 3.89,
    "NOV": 4.6,
    "DEC": 5
  },
  {
    "LONG": -47.54,
    "LAT": -24.74,
    "CIDADE": "Ilha Comprida",
    "UF": "SÃO PAULO",
    "anual": 4.27,
    "JAN": 4.99,
    "FEB": 5.32,
    "MAR": 4.8,
    "APR": 4.54,
    "MAY": 3.81,
    "JUN": 3.39,
    "JUL": 3.28,
    "AUG": 3.87,
    "SEP": 3.74,
    "OCT": 3.89,
    "NOV": 4.6,
    "DEC": 5
  },
  {
    "LONG": -49.17,
    "LAT": -24.57,
    "CIDADE": "Itapirapuã Paulista",
    "UF": "SÃO PAULO",
    "anual": 4.37,
    "JAN": 4.71,
    "FEB": 4.96,
    "MAR": 4.78,
    "APR": 4.46,
    "MAY": 3.85,
    "JUN": 3.52,
    "JUL": 3.53,
    "AUG": 4.54,
    "SEP": 4.13,
    "OCT": 4.3,
    "NOV": 4.76,
    "DEC": 4.92
  },
  {
    "LONG": -48.84,
    "LAT": -24.64,
    "CIDADE": "Itaóca",
    "UF": "SÃO PAULO",
    "anual": 4.32,
    "JAN": 4.7,
    "FEB": 5,
    "MAR": 4.69,
    "APR": 4.42,
    "MAY": 3.8,
    "JUN": 3.48,
    "JUL": 3.53,
    "AUG": 4.44,
    "SEP": 4.05,
    "OCT": 4.19,
    "NOV": 4.61,
    "DEC": 4.87
  },
  {
    "LONG": -48.59,
    "LAT": -2.46,
    "CIDADE": "Iporanga",
    "UF": "SÃO PAULO",
    "anual": 4.25,
    "JAN": 4.76,
    "FEB": 5.02,
    "MAR": 4.69,
    "APR": 4.44,
    "MAY": 3.83,
    "JUN": 3.44,
    "JUL": 3.42,
    "AUG": 4.22,
    "SEP": 3.86,
    "OCT": 4.01,
    "NOV": 4.46,
    "DEC": 4.82
  },
  {
    "LONG": -49.02,
    "LAT": -24.47,
    "CIDADE": "Barra do Chapéu",
    "UF": "SÃO PAULO",
    "anual": 4.42,
    "JAN": 4.7,
    "FEB": 4.95,
    "MAR": 4.75,
    "APR": 4.5,
    "MAY": 3.91,
    "JUN": 3.67,
    "JUL": 3.7,
    "AUG": 4.68,
    "SEP": 4.25,
    "OCT": 4.38,
    "NOV": 4.69,
    "DEC": 4.88
  },
  {
    "LONG": -48.84,
    "LAT": -24.51,
    "CIDADE": "Apiaí",
    "UF": "SÃO PAULO",
    "anual": 4.39,
    "JAN": 4.69,
    "FEB": 4.98,
    "MAR": 4.66,
    "APR": 4.46,
    "MAY": 3.89,
    "JUN": 3.69,
    "JUL": 3.73,
    "AUG": 4.65,
    "SEP": 4.2,
    "OCT": 4.29,
    "NOV": 4.61,
    "DEC": 4.87
  },
  {
    "LONG": -48.11,
    "LAT": "#VALOR!",
    "CIDADE": "Eldorado",
    "UF": "SÃO PAULO",
    "anual": 4.11,
    "JAN": 4.69,
    "FEB": 4.95,
    "MAR": 4.53,
    "APR": 4.38,
    "MAY": 3.77,
    "JUN": 3.45,
    "JUL": 3.37,
    "AUG": 3.99,
    "SEP": 3.63,
    "OCT": 3.67,
    "NOV": 4.24,
    "DEC": 4.63
  },
  {
    "LONG": -47.85,
    "LAT": -24.5,
    "CIDADE": "Registro",
    "UF": "SÃO PAULO",
    "anual": 4.18,
    "JAN": 4.84,
    "FEB": 5.14,
    "MAR": 4.64,
    "APR": 4.44,
    "MAY": 3.78,
    "JUN": 3.38,
    "JUL": 3.3,
    "AUG": 3.88,
    "SEP": 3.68,
    "OCT": 3.8,
    "NOV": 4.41,
    "DEC": 4.84
  },
  {
    "LONG": -47.93,
    "LAT": -24.38,
    "CIDADE": "Sete Barras",
    "UF": "SÃO PAULO",
    "anual": 4.11,
    "JAN": 4.72,
    "FEB": 5.02,
    "MAR": 4.54,
    "APR": 4.4,
    "MAY": 3.72,
    "JUN": 3.36,
    "JUL": 3.29,
    "AUG": 3.86,
    "SEP": 3.64,
    "OCT": 3.68,
    "NOV": 4.3,
    "DEC": 4.74
  },
  {
    "LONG": -49.14,
    "LAT": -2.43,
    "CIDADE": "Bom Sucesso de Itararé",
    "UF": "SÃO PAULO",
    "anual": 4.68,
    "JAN": 4.78,
    "FEB": 5.11,
    "MAR": 4.89,
    "APR": 4.74,
    "MAY": 4.15,
    "JUN": 3.97,
    "JUL": 4.11,
    "AUG": 5.09,
    "SEP": 4.63,
    "OCT": 4.63,
    "NOV": 4.92,
    "DEC": 5.12
  },
  {
    "LONG": -4.76,
    "LAT": -2.43,
    "CIDADE": "Juquiá",
    "UF": "SÃO PAULO",
    "anual": 4.15,
    "JAN": 4.67,
    "FEB": 5.08,
    "MAR": 4.58,
    "APR": 4.4,
    "MAY": 3.75,
    "JUN": 3.45,
    "JUL": 3.37,
    "AUG": 3.96,
    "SEP": 3.68,
    "OCT": 3.74,
    "NOV": 4.31,
    "DEC": 4.75
  },
  {
    "LONG": -47.46,
    "LAT": -24.28,
    "CIDADE": "Miracatu",
    "UF": "SÃO PAULO",
    "anual": 4.23,
    "JAN": 4.7,
    "FEB": 5.14,
    "MAR": 4.69,
    "APR": 4.46,
    "MAY": 3.84,
    "JUN": 3.52,
    "JUL": 3.48,
    "AUG": 4.09,
    "SEP": 3.8,
    "OCT": 3.8,
    "NOV": 4.38,
    "DEC": 4.84
  },
  {
    "LONG": -4.72,
    "LAT": -2.43,
    "CIDADE": "Pedro de Toledo",
    "UF": "SÃO PAULO",
    "anual": 4.37,
    "JAN": 4.82,
    "FEB": 5.24,
    "MAR": 4.8,
    "APR": 4.61,
    "MAY": 3.99,
    "JUN": 3.68,
    "JUL": 3.66,
    "AUG": 4.3,
    "SEP": 3.92,
    "OCT": 3.99,
    "NOV": 4.48,
    "DEC": 4.94
  },
  {
    "LONG": -4.72,
    "LAT": -2.43,
    "CIDADE": "Itariri",
    "UF": "SÃO PAULO",
    "anual": 4.32,
    "JAN": 4.73,
    "FEB": 5.2,
    "MAR": 4.76,
    "APR": 4.55,
    "MAY": 3.97,
    "JUN": 3.66,
    "JUL": 3.63,
    "AUG": 4.26,
    "SEP": 3.87,
    "OCT": 3.96,
    "NOV": 4.42,
    "DEC": 4.87
  },
  {
    "LONG": -47,
    "LAT": -24.31,
    "CIDADE": "Peruíbe",
    "UF": "SÃO PAULO",
    "anual": 4.24,
    "JAN": 4.67,
    "FEB": 5.09,
    "MAR": 4.64,
    "APR": 4.52,
    "MAY": 3.95,
    "JUN": 3.63,
    "JUL": 3.59,
    "AUG": 4.15,
    "SEP": 3.77,
    "OCT": 3.79,
    "NOV": 4.32,
    "DEC": 4.74
  },
  {
    "LONG": -4.88,
    "LAT": -24.22,
    "CIDADE": "Ribeirão Branco",
    "UF": "SÃO PAULO",
    "anual": 4.69,
    "JAN": 4.93,
    "FEB": 5.22,
    "MAR": 4.93,
    "APR": 4.76,
    "MAY": 4.15,
    "JUN": 3.96,
    "JUL": 4.1,
    "AUG": 5.01,
    "SEP": 4.54,
    "OCT": 4.59,
    "NOV": 4.92,
    "DEC": 5.19
  },
  {
    "LONG": "#VALOR!",
    "LAT": -24.19,
    "CIDADE": "Guapiara",
    "UF": "SÃO PAULO",
    "anual": 4.59,
    "JAN": 4.81,
    "FEB": 5.16,
    "MAR": 4.85,
    "APR": 4.69,
    "MAY": 4.1,
    "JUN": 3.9,
    "JUL": 3.97,
    "AUG": 4.89,
    "SEP": 4.4,
    "OCT": 4.45,
    "NOV": 4.78,
    "DEC": 5.1
  },
  {
    "LONG": -4.68,
    "LAT": -24.18,
    "CIDADE": "Itanhaém",
    "UF": "SÃO PAULO",
    "anual": 4.33,
    "JAN": 4.85,
    "FEB": 5.22,
    "MAR": 4.76,
    "APR": 4.67,
    "MAY": 4,
    "JUN": 3.67,
    "JUL": 3.62,
    "AUG": 4.17,
    "SEP": 3.81,
    "OCT": 3.88,
    "NOV": 4.48,
    "DEC": 4.8
  },
  {
    "LONG": -49.34,
    "LAT": -24.11,
    "CIDADE": "Itararé",
    "UF": "SÃO PAULO",
    "anual": 4.94,
    "JAN": 4.99,
    "FEB": 5.42,
    "MAR": 5.21,
    "APR": 5.02,
    "MAY": 4.36,
    "JUN": 4.1,
    "JUL": 4.34,
    "AUG": 5.26,
    "SEP": 4.91,
    "OCT": 4.96,
    "NOV": 5.24,
    "DEC": 5.42
  },
  {
    "LONG": -48.9,
    "LAT": -24.12,
    "CIDADE": "Nova Campina",
    "UF": "SÃO PAULO",
    "anual": 4.85,
    "JAN": 4.99,
    "FEB": 5.35,
    "MAR": 5.15,
    "APR": 4.94,
    "MAY": 4.3,
    "JUN": 4.06,
    "JUL": 4.22,
    "AUG": 5.16,
    "SEP": 4.78,
    "OCT": 4.82,
    "NOV": 5.11,
    "DEC": 5.31
  },
  {
    "LONG": -48.36,
    "LAT": -2.41,
    "CIDADE": "Ribeirão Grande",
    "UF": "SÃO PAULO",
    "anual": 4.67,
    "JAN": 4.95,
    "FEB": 5.22,
    "MAR": 4.93,
    "APR": 4.76,
    "MAY": 4.13,
    "JUN": 3.96,
    "JUL": 4.05,
    "AUG": 4.92,
    "SEP": 4.48,
    "OCT": 4.57,
    "NOV": 4.89,
    "DEC": 5.17
  },
  {
    "LONG": -4.66,
    "LAT": -2.41,
    "CIDADE": "Mongaguá",
    "UF": "SÃO PAULO",
    "anual": 4.11,
    "JAN": 4.59,
    "FEB": 4.94,
    "MAR": 4.46,
    "APR": 4.45,
    "MAY": 3.89,
    "JUN": 3.55,
    "JUL": 3.53,
    "AUG": 3.99,
    "SEP": 3.58,
    "OCT": 3.62,
    "NOV": 4.17,
    "DEC": 4.53
  },
  {
    "LONG": -48.88,
    "LAT": -23.98,
    "CIDADE": "Itapeva",
    "UF": "SÃO PAULO",
    "anual": 4.86,
    "JAN": 5.02,
    "FEB": 5.35,
    "MAR": 5.14,
    "APR": 4.97,
    "MAY": 4.28,
    "JUN": 4.08,
    "JUL": 4.23,
    "AUG": 5.15,
    "SEP": 4.76,
    "OCT": 4.86,
    "NOV": 5.11,
    "DEC": 5.36
  },
  {
    "LONG": -48.35,
    "LAT": -2.4,
    "CIDADE": "Capão Bonito",
    "UF": "SÃO PAULO",
    "anual": 4.7,
    "JAN": 4.88,
    "FEB": 5.24,
    "MAR": 4.98,
    "APR": 4.84,
    "MAY": 4.18,
    "JUN": 3.98,
    "JUL": 4.1,
    "AUG": 4.96,
    "SEP": 4.52,
    "OCT": 4.63,
    "NOV": 4.91,
    "DEC": 5.18
  },
  {
    "LONG": -47.51,
    "LAT": -23.96,
    "CIDADE": "Tapiraí",
    "UF": "SÃO PAULO",
    "anual": 4.32,
    "JAN": 4.41,
    "FEB": 5,
    "MAR": 4.6,
    "APR": 4.44,
    "MAY": 3.88,
    "JUN": 3.82,
    "JUL": 3.81,
    "AUG": 4.53,
    "SEP": 4.02,
    "OCT": 4.06,
    "NOV": 4.42,
    "DEC": 4.8
  },
  {
    "LONG": -46.41,
    "LAT": -24.01,
    "CIDADE": "Praia Grande",
    "UF": "SÃO PAULO",
    "anual": 4.09,
    "JAN": 4.44,
    "FEB": 4.87,
    "MAR": 4.44,
    "APR": 4.38,
    "MAY": 3.9,
    "JUN": 3.6,
    "JUL": 3.53,
    "AUG": 4.06,
    "SEP": 3.61,
    "OCT": 3.68,
    "NOV": 4.09,
    "DEC": 4.45
  },
  {
    "LONG": -46.34,
    "LAT": -2.4,
    "CIDADE": "Santos",
    "UF": "SÃO PAULO",
    "anual": 4.33,
    "JAN": 4.78,
    "FEB": 5.21,
    "MAR": 4.76,
    "APR": 4.63,
    "MAY": 4.05,
    "JUN": 3.74,
    "JUL": 3.69,
    "AUG": 4.25,
    "SEP": 3.8,
    "OCT": 3.9,
    "NOV": 4.44,
    "DEC": 4.74
  },
  {
    "LONG": -46.39,
    "LAT": -23.96,
    "CIDADE": "São Vicente",
    "UF": "SÃO PAULO",
    "anual": 4.33,
    "JAN": 4.78,
    "FEB": 5.21,
    "MAR": 4.76,
    "APR": 4.63,
    "MAY": 4.05,
    "JUN": 3.74,
    "JUL": 3.69,
    "AUG": 4.25,
    "SEP": 3.8,
    "OCT": 3.9,
    "NOV": 4.44,
    "DEC": 4.74
  },
  {
    "LONG": -46.26,
    "LAT": -23.99,
    "CIDADE": "Guarujá",
    "UF": "SÃO PAULO",
    "anual": 4.37,
    "JAN": 4.91,
    "FEB": 5.25,
    "MAR": 4.8,
    "APR": 4.64,
    "MAY": 4.07,
    "JUN": 3.73,
    "JUL": 3.7,
    "AUG": 4.27,
    "SEP": 3.83,
    "OCT": 3.94,
    "NOV": 4.47,
    "DEC": 4.83
  },
  {
    "LONG": -49.14,
    "LAT": -23.86,
    "CIDADE": "Itaberá",
    "UF": "SÃO PAULO",
    "anual": 4.96,
    "JAN": 5.11,
    "FEB": 5.46,
    "MAR": 5.25,
    "APR": 5.09,
    "MAY": 4.36,
    "JUN": 4.14,
    "JUL": 4.32,
    "AUG": 5.25,
    "SEP": 4.9,
    "OCT": 5,
    "NOV": 5.23,
    "DEC": 5.45
  },
  {
    "LONG": -48.7,
    "LAT": -23.92,
    "CIDADE": "Taquarivaí",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.94,
    "FEB": 5.27,
    "MAR": 5.06,
    "APR": 4.93,
    "MAY": 4.25,
    "JUN": 4.03,
    "JUL": 4.17,
    "AUG": 5.09,
    "SEP": 4.69,
    "OCT": 4.76,
    "NOV": 5,
    "DEC": 5.27
  },
  {
    "LONG": -4.8,
    "LAT": -23.88,
    "CIDADE": "São Miguel Arcanjo",
    "UF": "SÃO PAULO",
    "anual": 4.74,
    "JAN": 4.88,
    "FEB": 5.29,
    "MAR": 4.97,
    "APR": 4.89,
    "MAY": 4.23,
    "JUN": 4.12,
    "JUL": 4.13,
    "AUG": 4.99,
    "SEP": 4.56,
    "OCT": 4.65,
    "NOV": 4.96,
    "DEC": 5.21
  },
  {
    "LONG": -47.07,
    "LAT": -23.92,
    "CIDADE": "Juquitiba",
    "UF": "SÃO PAULO",
    "anual": 4.42,
    "JAN": 4.5,
    "FEB": 5.05,
    "MAR": 4.65,
    "APR": 4.56,
    "MAY": 4.08,
    "JUN": 3.96,
    "JUL": 3.94,
    "AUG": 4.73,
    "SEP": 4.21,
    "OCT": 4.16,
    "NOV": 4.45,
    "DEC": 4.79
  },
  {
    "LONG": -46.42,
    "LAT": -23.89,
    "CIDADE": "Cubatão",
    "UF": "SÃO PAULO",
    "anual": 4.05,
    "JAN": 4.35,
    "FEB": 4.81,
    "MAR": 4.34,
    "APR": 4.31,
    "MAY": 3.88,
    "JUN": 3.64,
    "JUL": 3.61,
    "AUG": 4.15,
    "SEP": 3.62,
    "OCT": 3.63,
    "NOV": 3.9,
    "DEC": 4.38
  },
  {
    "LONG": -49.43,
    "LAT": -23.83,
    "CIDADE": "Riversul",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 5.14,
    "FEB": 5.52,
    "MAR": 5.37,
    "APR": 5.17,
    "MAY": 4.44,
    "JUN": 4.11,
    "JUL": 4.32,
    "AUG": 5.29,
    "SEP": 4.97,
    "OCT": 5.06,
    "NOV": 5.36,
    "DEC": 5.51
  },
  {
    "LONG": -48.6,
    "LAT": -23.8,
    "CIDADE": "Buri",
    "UF": "SÃO PAULO",
    "anual": 4.82,
    "JAN": 4.96,
    "FEB": 5.33,
    "MAR": 5.09,
    "APR": 4.96,
    "MAY": 4.25,
    "JUN": 4.07,
    "JUL": 4.22,
    "AUG": 5.09,
    "SEP": 4.73,
    "OCT": 4.81,
    "NOV": 5.04,
    "DEC": 5.28
  },
  {
    "LONG": -47.72,
    "LAT": -23.81,
    "CIDADE": "Pilar do Sul",
    "UF": "SÃO PAULO",
    "anual": 4.91,
    "JAN": 4.87,
    "FEB": 5.37,
    "MAR": 5.13,
    "APR": 5.07,
    "MAY": 4.38,
    "JUN": 4.33,
    "JUL": 4.33,
    "AUG": 5.19,
    "SEP": 4.84,
    "OCT": 4.92,
    "NOV": 5.12,
    "DEC": 5.36
  },
  {
    "LONG": -46.94,
    "LAT": -23.85,
    "CIDADE": "São Lourenço da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.48,
    "JAN": 4.55,
    "FEB": 5.07,
    "MAR": 4.67,
    "APR": 4.64,
    "MAY": 4.08,
    "JUN": 4.02,
    "JUL": 4,
    "AUG": 4.87,
    "SEP": 4.3,
    "OCT": 4.27,
    "NOV": 4.49,
    "DEC": 4.81
  },
  {
    "LONG": -4.68,
    "LAT": -23.83,
    "CIDADE": "Embu-Guaçu",
    "UF": "SÃO PAULO",
    "anual": 4.49,
    "JAN": 4.65,
    "FEB": 5.12,
    "MAR": 4.72,
    "APR": 4.65,
    "MAY": 4.07,
    "JUN": 3.97,
    "JUL": 4,
    "AUG": 4.83,
    "SEP": 4.28,
    "OCT": 4.24,
    "NOV": 4.49,
    "DEC": 4.84
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.38,
    "CIDADE": "Bertioga",
    "UF": "SÃO PAULO",
    "anual": 4.16,
    "JAN": 4.4,
    "FEB": 4.91,
    "MAR": 4.37,
    "APR": 4.34,
    "MAY": 3.92,
    "JUN": 3.72,
    "JUL": 3.75,
    "AUG": 4.37,
    "SEP": 3.83,
    "OCT": 3.86,
    "NOV": 4.02,
    "DEC": 4.46
  },
  {
    "LONG": -45.41,
    "LAT": -23.8,
    "CIDADE": "São Sebastião",
    "UF": "SÃO PAULO",
    "anual": 4.41,
    "JAN": 4.96,
    "FEB": 5.37,
    "MAR": 4.74,
    "APR": 4.7,
    "MAY": 4.09,
    "JUN": 3.82,
    "JUL": 3.87,
    "AUG": 4.46,
    "SEP": 3.87,
    "OCT": 4.03,
    "NOV": 4.28,
    "DEC": 4.72
  },
  {
    "LONG": -45.36,
    "LAT": -2.38,
    "CIDADE": "Ilhabela",
    "UF": "SÃO PAULO",
    "anual": 4.59,
    "JAN": 5.11,
    "FEB": 5.57,
    "MAR": 4.92,
    "APR": 4.86,
    "MAY": 4.19,
    "JUN": 3.93,
    "JUL": 3.96,
    "AUG": 4.64,
    "SEP": 4.12,
    "OCT": 4.33,
    "NOV": 4.54,
    "DEC": 4.92
  },
  {
    "LONG": -49.48,
    "LAT": -23.7,
    "CIDADE": "Itaporanga",
    "UF": "SÃO PAULO",
    "anual": 5.03,
    "JAN": 5.14,
    "FEB": 5.56,
    "MAR": 5.36,
    "APR": 5.19,
    "MAY": 4.43,
    "JUN": 4.14,
    "JUL": 4.33,
    "AUG": 5.3,
    "SEP": 4.97,
    "OCT": 5.1,
    "NOV": 5.33,
    "DEC": 5.53
  },
  {
    "LONG": -4.74,
    "LAT": -23.71,
    "CIDADE": "Piedade",
    "UF": "SÃO PAULO",
    "anual": 4.96,
    "JAN": 4.89,
    "FEB": 5.4,
    "MAR": 5.21,
    "APR": 5.07,
    "MAY": 4.47,
    "JUN": 4.39,
    "JUL": 4.39,
    "AUG": 5.31,
    "SEP": 4.98,
    "OCT": 5,
    "NOV": 5.12,
    "DEC": 5.34
  },
  {
    "LONG": -47.22,
    "LAT": -23.66,
    "CIDADE": "Ibiúna",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.75,
    "FEB": 5.3,
    "MAR": 5.01,
    "APR": 4.93,
    "MAY": 4.34,
    "JUN": 4.21,
    "JUL": 4.23,
    "AUG": 5.15,
    "SEP": 4.7,
    "OCT": 4.77,
    "NOV": 4.9,
    "DEC": 5.16
  },
  {
    "LONG": -46.86,
    "LAT": -23.72,
    "CIDADE": "Itapecerica da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.62,
    "JAN": 4.73,
    "FEB": 5.25,
    "MAR": 4.86,
    "APR": 4.78,
    "MAY": 4.15,
    "JUN": 4.04,
    "JUL": 4.06,
    "AUG": 4.96,
    "SEP": 4.43,
    "OCT": 4.48,
    "NOV": 4.67,
    "DEC": 5.01
  },
  {
    "LONG": -46.62,
    "LAT": -23.68,
    "CIDADE": "Diadema",
    "UF": "SÃO PAULO",
    "anual": 4.6,
    "JAN": 4.74,
    "FEB": 5.19,
    "MAR": 4.82,
    "APR": 4.75,
    "MAY": 4.16,
    "JUN": 4.01,
    "JUL": 4.07,
    "AUG": 4.87,
    "SEP": 4.4,
    "OCT": 4.45,
    "NOV": 4.68,
    "DEC": 5.01
  },
  {
    "LONG": -46.54,
    "LAT": -23.67,
    "CIDADE": "Santo André",
    "UF": "SÃO PAULO",
    "anual": 4.55,
    "JAN": 4.6,
    "FEB": 5.17,
    "MAR": 4.78,
    "APR": 4.67,
    "MAY": 4.13,
    "JUN": 3.99,
    "JUL": 4.05,
    "AUG": 4.83,
    "SEP": 4.39,
    "OCT": 4.42,
    "NOV": 4.61,
    "DEC": 4.95
  },
  {
    "LONG": -4.66,
    "LAT": -23.69,
    "CIDADE": "São Bernardo do Campo",
    "UF": "SÃO PAULO",
    "anual": 4.55,
    "JAN": 4.6,
    "FEB": 5.17,
    "MAR": 4.78,
    "APR": 4.67,
    "MAY": 4.13,
    "JUN": 3.99,
    "JUL": 4.05,
    "AUG": 4.83,
    "SEP": 4.39,
    "OCT": 4.42,
    "NOV": 4.61,
    "DEC": 4.95
  },
  {
    "LONG": -46.41,
    "LAT": -23.71,
    "CIDADE": "Ribeirão Pires",
    "UF": "SÃO PAULO",
    "anual": 4.49,
    "JAN": 4.54,
    "FEB": 5.16,
    "MAR": 4.72,
    "APR": 4.61,
    "MAY": 4.08,
    "JUN": 3.92,
    "JUL": 3.99,
    "AUG": 4.74,
    "SEP": 4.31,
    "OCT": 4.36,
    "NOV": 4.54,
    "DEC": 4.9
  },
  {
    "LONG": -46.46,
    "LAT": -23.67,
    "CIDADE": "Mauá",
    "UF": "SÃO PAULO",
    "anual": 4.49,
    "JAN": 4.54,
    "FEB": 5.16,
    "MAR": 4.72,
    "APR": 4.61,
    "MAY": 4.08,
    "JUN": 3.92,
    "JUL": 3.99,
    "AUG": 4.74,
    "SEP": 4.31,
    "OCT": 4.36,
    "NOV": 4.54,
    "DEC": 4.9
  },
  {
    "LONG": -46.4,
    "LAT": -23.74,
    "CIDADE": "Rio Grande da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.45,
    "JAN": 4.57,
    "FEB": 5.16,
    "MAR": 4.71,
    "APR": 4.55,
    "MAY": 4.03,
    "JUN": 3.86,
    "JUL": 3.94,
    "AUG": 4.72,
    "SEP": 4.26,
    "OCT": 4.33,
    "NOV": 4.46,
    "DEC": 4.87
  },
  {
    "LONG": -49.56,
    "LAT": -23.63,
    "CIDADE": "Barão de Antonina",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 5.23,
    "FEB": 5.63,
    "MAR": 5.47,
    "APR": 5.26,
    "MAY": 4.42,
    "JUN": 4.16,
    "JUL": 4.36,
    "AUG": 5.31,
    "SEP": 4.97,
    "OCT": 5.15,
    "NOV": 5.38,
    "DEC": 5.59
  },
  {
    "LONG": -49.31,
    "LAT": -2.36,
    "CIDADE": "Coronel Macedo",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 5.13,
    "FEB": 5.5,
    "MAR": 5.35,
    "APR": 5.18,
    "MAY": 4.44,
    "JUN": 4.18,
    "JUL": 4.36,
    "AUG": 5.3,
    "SEP": 4.95,
    "OCT": 5.09,
    "NOV": 5.3,
    "DEC": 5.46
  },
  {
    "LONG": -48.48,
    "LAT": -2.36,
    "CIDADE": "Campina do Monte Alegre",
    "UF": "SÃO PAULO",
    "anual": 4.91,
    "JAN": 4.95,
    "FEB": 5.43,
    "MAR": 5.19,
    "APR": 5.03,
    "MAY": 4.31,
    "JUN": 4.2,
    "JUL": 4.25,
    "AUG": 5.2,
    "SEP": 4.88,
    "OCT": 4.95,
    "NOV": 5.13,
    "DEC": 5.35
  },
  {
    "LONG": -48.05,
    "LAT": -23.59,
    "CIDADE": "Itapetininga",
    "UF": "SÃO PAULO",
    "anual": 4.91,
    "JAN": 4.87,
    "FEB": 5.39,
    "MAR": 5.13,
    "APR": 5.06,
    "MAY": 4.39,
    "JUN": 4.28,
    "JUL": 4.36,
    "AUG": 5.26,
    "SEP": 4.9,
    "OCT": 4.94,
    "NOV": 5.06,
    "DEC": 5.3
  },
  {
    "LONG": -47.9,
    "LAT": -23.55,
    "CIDADE": "Alambari",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.93,
    "FEB": 5.44,
    "MAR": 5.22,
    "APR": 5.15,
    "MAY": 4.47,
    "JUN": 4.36,
    "JUL": 4.4,
    "AUG": 5.33,
    "SEP": 4.94,
    "OCT": 5.02,
    "NOV": 5.15,
    "DEC": 5.31
  },
  {
    "LONG": -47.83,
    "LAT": -23.64,
    "CIDADE": "Sarapuí",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.93,
    "FEB": 5.44,
    "MAR": 5.22,
    "APR": 5.15,
    "MAY": 4.47,
    "JUN": 4.36,
    "JUL": 4.4,
    "AUG": 5.33,
    "SEP": 4.94,
    "OCT": 5.02,
    "NOV": 5.15,
    "DEC": 5.31
  },
  {
    "LONG": -47.57,
    "LAT": -23.65,
    "CIDADE": "Salto de Pirapora",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.94,
    "FEB": 5.44,
    "MAR": 5.22,
    "APR": 5.12,
    "MAY": 4.46,
    "JUN": 4.37,
    "JUL": 4.42,
    "AUG": 5.36,
    "SEP": 4.97,
    "OCT": 5.02,
    "NOV": 5.11,
    "DEC": 5.32
  },
  {
    "LONG": -47.02,
    "LAT": -23.6,
    "CIDADE": "Vargem Grande Paulista",
    "UF": "SÃO PAULO",
    "anual": 4.78,
    "JAN": 4.77,
    "FEB": 5.31,
    "MAR": 5.04,
    "APR": 4.91,
    "MAY": 4.32,
    "JUN": 4.24,
    "JUL": 4.24,
    "AUG": 5.18,
    "SEP": 4.7,
    "OCT": 4.72,
    "NOV": 4.85,
    "DEC": 5.12
  },
  {
    "LONG": -46.92,
    "LAT": -23.6,
    "CIDADE": "Cotia",
    "UF": "SÃO PAULO",
    "anual": 4.73,
    "JAN": 4.82,
    "FEB": 5.3,
    "MAR": 5,
    "APR": 4.86,
    "MAY": 4.27,
    "JUN": 4.15,
    "JUL": 4.17,
    "AUG": 5.09,
    "SEP": 4.58,
    "OCT": 4.67,
    "NOV": 4.8,
    "DEC": 5.11
  },
  {
    "LONG": -46.86,
    "LAT": -23.64,
    "CIDADE": "Embu das Artes",
    "UF": "SÃO PAULO",
    "anual": 4.72,
    "JAN": 4.82,
    "FEB": 5.32,
    "MAR": 4.96,
    "APR": 4.85,
    "MAY": 4.26,
    "JUN": 4.11,
    "JUL": 4.15,
    "AUG": 5.06,
    "SEP": 4.58,
    "OCT": 4.67,
    "NOV": 4.81,
    "DEC": 5.12
  },
  {
    "LONG": -46.75,
    "LAT": -23.6,
    "CIDADE": "Taboão da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.7,
    "JAN": 4.77,
    "FEB": 5.28,
    "MAR": 4.92,
    "APR": 4.81,
    "MAY": 4.26,
    "JUN": 4.11,
    "JUL": 4.14,
    "AUG": 5.03,
    "SEP": 4.55,
    "OCT": 4.65,
    "NOV": 4.78,
    "DEC": 5.1
  },
  {
    "LONG": -46.56,
    "LAT": -23.62,
    "CIDADE": "São Caetano do Sul",
    "UF": "SÃO PAULO",
    "anual": 4.64,
    "JAN": 4.68,
    "FEB": 5.2,
    "MAR": 4.86,
    "APR": 4.74,
    "MAY": 4.2,
    "JUN": 4.09,
    "JUL": 4.14,
    "AUG": 4.98,
    "SEP": 4.5,
    "OCT": 4.59,
    "NOV": 4.69,
    "DEC": 5.02
  },
  {
    "LONG": -46.04,
    "LAT": -23.57,
    "CIDADE": "Biritiba-Mirim",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.82,
    "FEB": 5.4,
    "MAR": 4.87,
    "APR": 4.76,
    "MAY": 4.14,
    "JUN": 3.97,
    "JUL": 4.08,
    "AUG": 4.89,
    "SEP": 4.43,
    "OCT": 4.58,
    "NOV": 4.65,
    "DEC": 5.02
  },
  {
    "LONG": -45.41,
    "LAT": -2.36,
    "CIDADE": "Caraguatatuba",
    "UF": "SÃO PAULO",
    "anual": 4.43,
    "JAN": 4.79,
    "FEB": 5.34,
    "MAR": 4.78,
    "APR": 4.65,
    "MAY": 4.1,
    "JUN": 3.89,
    "JUL": 3.92,
    "AUG": 4.55,
    "SEP": 4.02,
    "OCT": 4.15,
    "NOV": 4.24,
    "DEC": 4.74
  },
  {
    "LONG": -49.41,
    "LAT": -2.35,
    "CIDADE": "Taguaí",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 5.17,
    "FEB": 5.58,
    "MAR": 5.43,
    "APR": 5.22,
    "MAY": 4.48,
    "JUN": 4.25,
    "JUL": 4.41,
    "AUG": 5.33,
    "SEP": 4.98,
    "OCT": 5.16,
    "NOV": 5.38,
    "DEC": 5.53
  },
  {
    "LONG": -49.24,
    "LAT": -2.35,
    "CIDADE": "Taquarituba",
    "UF": "SÃO PAULO",
    "anual": 4.97,
    "JAN": 5.05,
    "FEB": 5.43,
    "MAR": 5.25,
    "APR": 5.11,
    "MAY": 4.41,
    "JUN": 4.18,
    "JUL": 4.35,
    "AUG": 5.28,
    "SEP": 4.93,
    "OCT": 5.08,
    "NOV": 5.26,
    "DEC": 5.36
  },
  {
    "LONG": -48.41,
    "LAT": -23.49,
    "CIDADE": "Angatuba",
    "UF": "SÃO PAULO",
    "anual": 4.93,
    "JAN": 4.91,
    "FEB": 5.38,
    "MAR": 5.21,
    "APR": 5.1,
    "MAY": 4.37,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 5.27,
    "SEP": 4.91,
    "OCT": 4.95,
    "NOV": 5.15,
    "DEC": 5.33
  },
  {
    "LONG": -47.74,
    "LAT": -23.47,
    "CIDADE": "Capela do Alto",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 4.96,
    "FEB": 5.48,
    "MAR": 5.28,
    "APR": 5.15,
    "MAY": 4.48,
    "JUN": 4.4,
    "JUL": 4.45,
    "AUG": 5.36,
    "SEP": 5,
    "OCT": 5.11,
    "NOV": 5.19,
    "DEC": 5.41
  },
  {
    "LONG": -4.76,
    "LAT": -23.5,
    "CIDADE": "Araçoiaba da Serra",
    "UF": "SÃO PAULO",
    "anual": 5,
    "JAN": 4.95,
    "FEB": 5.47,
    "MAR": 5.26,
    "APR": 5.13,
    "MAY": 4.51,
    "JUN": 4.41,
    "JUL": 4.43,
    "AUG": 5.37,
    "SEP": 4.99,
    "OCT": 5.06,
    "NOV": 5.13,
    "DEC": 5.34
  },
  {
    "LONG": -47.45,
    "LAT": -23.5,
    "CIDADE": "Sorocaba",
    "UF": "SÃO PAULO",
    "anual": 5.01,
    "JAN": 4.98,
    "FEB": 5.48,
    "MAR": 5.19,
    "APR": 5.12,
    "MAY": 4.53,
    "JUN": 4.41,
    "JUL": 4.44,
    "AUG": 5.35,
    "SEP": 5.01,
    "OCT": 5.1,
    "NOV": 5.11,
    "DEC": 5.37
  },
  {
    "LONG": -47.44,
    "LAT": -23.55,
    "CIDADE": "Votorantim",
    "UF": "SÃO PAULO",
    "anual": 5.01,
    "JAN": 4.98,
    "FEB": 5.48,
    "MAR": 5.19,
    "APR": 5.12,
    "MAY": 4.53,
    "JUN": 4.41,
    "JUL": 4.44,
    "AUG": 5.35,
    "SEP": 5.01,
    "OCT": 5.1,
    "NOV": 5.11,
    "DEC": 5.37
  },
  {
    "LONG": -4.73,
    "LAT": -2.35,
    "CIDADE": "Alumínio",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 4.96,
    "FEB": 5.49,
    "MAR": 5.25,
    "APR": 5.13,
    "MAY": 4.55,
    "JUN": 4.42,
    "JUL": 4.47,
    "AUG": 5.4,
    "SEP": 4.98,
    "OCT": 5.06,
    "NOV": 5.15,
    "DEC": 5.36
  },
  {
    "LONG": -47.14,
    "LAT": -23.52,
    "CIDADE": "São Roque",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.9,
    "FEB": 5.44,
    "MAR": 5.21,
    "APR": 5.09,
    "MAY": 4.49,
    "JUN": 4.37,
    "JUL": 4.39,
    "AUG": 5.35,
    "SEP": 4.91,
    "OCT": 4.96,
    "NOV": 5.04,
    "DEC": 5.29
  },
  {
    "LONG": -47.19,
    "LAT": -23.54,
    "CIDADE": "Mairinque",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.9,
    "FEB": 5.44,
    "MAR": 5.21,
    "APR": 5.09,
    "MAY": 4.49,
    "JUN": 4.37,
    "JUL": 4.39,
    "AUG": 5.35,
    "SEP": 4.91,
    "OCT": 4.96,
    "NOV": 5.04,
    "DEC": 5.29
  },
  {
    "LONG": -46.9,
    "LAT": -2.35,
    "CIDADE": "Jandira",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.8,
    "FEB": 5.31,
    "MAR": 5,
    "APR": 4.94,
    "MAY": 4.37,
    "JUN": 4.22,
    "JUL": 4.25,
    "AUG": 5.17,
    "SEP": 4.68,
    "OCT": 4.78,
    "NOV": 4.86,
    "DEC": 5.13
  },
  {
    "LONG": -46.93,
    "LAT": -23.55,
    "CIDADE": "Itapevi",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.8,
    "FEB": 5.31,
    "MAR": 5,
    "APR": 4.94,
    "MAY": 4.37,
    "JUN": 4.22,
    "JUL": 4.25,
    "AUG": 5.17,
    "SEP": 4.68,
    "OCT": 4.78,
    "NOV": 4.86,
    "DEC": 5.13
  },
  {
    "LONG": -46.84,
    "LAT": -23.52,
    "CIDADE": "Carapicuíba",
    "UF": "SÃO PAULO",
    "anual": 4.75,
    "JAN": 4.76,
    "FEB": 5.32,
    "MAR": 4.96,
    "APR": 4.88,
    "MAY": 4.32,
    "JUN": 4.16,
    "JUL": 4.22,
    "AUG": 5.13,
    "SEP": 4.65,
    "OCT": 4.71,
    "NOV": 4.81,
    "DEC": 5.13
  },
  {
    "LONG": -46.88,
    "LAT": -23.51,
    "CIDADE": "Barueri",
    "UF": "SÃO PAULO",
    "anual": 4.75,
    "JAN": 4.76,
    "FEB": 5.32,
    "MAR": 4.96,
    "APR": 4.88,
    "MAY": 4.32,
    "JUN": 4.16,
    "JUL": 4.22,
    "AUG": 5.13,
    "SEP": 4.65,
    "OCT": 4.71,
    "NOV": 4.81,
    "DEC": 5.13
  },
  {
    "LONG": -4.68,
    "LAT": -23.53,
    "CIDADE": "Osasco",
    "UF": "SÃO PAULO",
    "anual": 4.72,
    "JAN": 4.74,
    "FEB": 5.29,
    "MAR": 4.92,
    "APR": 4.83,
    "MAY": 4.29,
    "JUN": 4.15,
    "JUL": 4.19,
    "AUG": 5.1,
    "SEP": 4.59,
    "OCT": 4.67,
    "NOV": 4.76,
    "DEC": 5.11
  },
  {
    "LONG": -46.64,
    "LAT": -23.53,
    "CIDADE": "São Paulo",
    "UF": "SÃO PAULO",
    "anual": 4.67,
    "JAN": 4.71,
    "FEB": 5.24,
    "MAR": 4.89,
    "APR": 4.75,
    "MAY": 4.24,
    "JUN": 4.11,
    "JUL": 4.12,
    "AUG": 5.02,
    "SEP": 4.56,
    "OCT": 4.66,
    "NOV": 4.71,
    "DEC": 5.05
  },
  {
    "LONG": -46.53,
    "LAT": -23.45,
    "CIDADE": "Guarulhos",
    "UF": "SÃO PAULO",
    "anual": 4.66,
    "JAN": 4.69,
    "FEB": 5.27,
    "MAR": 4.88,
    "APR": 4.75,
    "MAY": 4.21,
    "JUN": 4.11,
    "JUL": 4.15,
    "AUG": 5.02,
    "SEP": 4.52,
    "OCT": 4.63,
    "NOV": 4.67,
    "DEC": 5.02
  },
  {
    "LONG": -46.31,
    "LAT": -23.55,
    "CIDADE": "Suzano",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.79,
    "FEB": 5.27,
    "MAR": 4.83,
    "APR": 4.71,
    "MAY": 4.12,
    "JUN": 4.01,
    "JUL": 4.09,
    "AUG": 4.89,
    "SEP": 4.52,
    "OCT": 4.62,
    "NOV": 4.7,
    "DEC": 5.03
  },
  {
    "LONG": -46.35,
    "LAT": -2.35,
    "CIDADE": "Itaquaquecetuba",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.79,
    "FEB": 5.27,
    "MAR": 4.83,
    "APR": 4.71,
    "MAY": 4.12,
    "JUN": 4.01,
    "JUL": 4.09,
    "AUG": 4.89,
    "SEP": 4.52,
    "OCT": 4.62,
    "NOV": 4.7,
    "DEC": 5.03
  },
  {
    "LONG": -46.35,
    "LAT": -23.53,
    "CIDADE": "Poá",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.79,
    "FEB": 5.27,
    "MAR": 4.83,
    "APR": 4.71,
    "MAY": 4.12,
    "JUN": 4.01,
    "JUL": 4.09,
    "AUG": 4.89,
    "SEP": 4.52,
    "OCT": 4.62,
    "NOV": 4.7,
    "DEC": 5.03
  },
  {
    "LONG": -46.37,
    "LAT": -23.54,
    "CIDADE": "Ferraz de Vasconcelos",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.79,
    "FEB": 5.27,
    "MAR": 4.83,
    "APR": 4.71,
    "MAY": 4.12,
    "JUN": 4.01,
    "JUL": 4.09,
    "AUG": 4.89,
    "SEP": 4.52,
    "OCT": 4.62,
    "NOV": 4.7,
    "DEC": 5.03
  },
  {
    "LONG": -46.19,
    "LAT": -23.52,
    "CIDADE": "Mogi das Cruzes",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.79,
    "FEB": 5.34,
    "MAR": 4.91,
    "APR": 4.7,
    "MAY": 4.1,
    "JUN": 3.95,
    "JUL": 4.04,
    "AUG": 4.91,
    "SEP": 4.48,
    "OCT": 4.65,
    "NOV": 4.69,
    "DEC": 5.03
  },
  {
    "LONG": -45.85,
    "LAT": -23.53,
    "CIDADE": "Salesópolis",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.95,
    "FEB": 5.49,
    "MAR": 5.02,
    "APR": 4.92,
    "MAY": 4.24,
    "JUN": 4.17,
    "JUL": 4.2,
    "AUG": 5.06,
    "SEP": 4.63,
    "OCT": 4.85,
    "NOV": 4.85,
    "DEC": 5.17
  },
  {
    "LONG": -49.51,
    "LAT": -23.39,
    "CIDADE": "Fartura",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 5.24,
    "FEB": 5.65,
    "MAR": 5.5,
    "APR": 5.26,
    "MAY": 4.51,
    "JUN": 4.24,
    "JUL": 4.45,
    "AUG": 5.35,
    "SEP": 4.99,
    "OCT": 5.22,
    "NOV": 5.43,
    "DEC": 5.65
  },
  {
    "LONG": -49.09,
    "LAT": -23.42,
    "CIDADE": "Itaí",
    "UF": "SÃO PAULO",
    "anual": 5.04,
    "JAN": 5.06,
    "FEB": 5.53,
    "MAR": 5.35,
    "APR": 5.21,
    "MAY": 4.47,
    "JUN": 4.26,
    "JUL": 4.4,
    "AUG": 5.33,
    "SEP": 4.96,
    "OCT": 5.16,
    "NOV": 5.33,
    "DEC": 5.43
  },
  {
    "LONG": -48.72,
    "LAT": -23.39,
    "CIDADE": "Paranapanema",
    "UF": "SÃO PAULO",
    "anual": 5.01,
    "JAN": 5.04,
    "FEB": 5.53,
    "MAR": 5.34,
    "APR": 5.15,
    "MAY": 4.41,
    "JUN": 4.22,
    "JUL": 4.37,
    "AUG": 5.28,
    "SEP": 4.91,
    "OCT": 5.12,
    "NOV": 5.32,
    "DEC": 5.46
  },
  {
    "LONG": -48.18,
    "LAT": -23.37,
    "CIDADE": "Guareí",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.89,
    "FEB": 5.43,
    "MAR": 5.22,
    "APR": 5.14,
    "MAY": 4.49,
    "JUN": 4.38,
    "JUL": 4.4,
    "AUG": 5.35,
    "SEP": 4.97,
    "OCT": 5.05,
    "NOV": 5.12,
    "DEC": 5.28
  },
  {
    "LONG": -47.69,
    "LAT": -23.35,
    "CIDADE": "Iperó",
    "UF": "SÃO PAULO",
    "anual": 5.06,
    "JAN": 5,
    "FEB": 5.53,
    "MAR": 5.31,
    "APR": 5.22,
    "MAY": 4.57,
    "JUN": 4.45,
    "JUL": 4.47,
    "AUG": 5.42,
    "SEP": 5.05,
    "OCT": 5.13,
    "NOV": 5.15,
    "DEC": 5.38
  },
  {
    "LONG": -47,
    "LAT": -2.34,
    "CIDADE": "Pirapora do Bom Jesus",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.88,
    "FEB": 5.44,
    "MAR": 5.2,
    "APR": 5.11,
    "MAY": 4.51,
    "JUN": 4.35,
    "JUL": 4.41,
    "AUG": 5.35,
    "SEP": 4.89,
    "OCT": 4.96,
    "NOV": 5.03,
    "DEC": 5.29
  },
  {
    "LONG": -47.06,
    "LAT": -23.44,
    "CIDADE": "Araçariguama",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.88,
    "FEB": 5.44,
    "MAR": 5.2,
    "APR": 5.11,
    "MAY": 4.51,
    "JUN": 4.35,
    "JUL": 4.41,
    "AUG": 5.35,
    "SEP": 4.89,
    "OCT": 4.96,
    "NOV": 5.03,
    "DEC": 5.29
  },
  {
    "LONG": -46.92,
    "LAT": -2.34,
    "CIDADE": "Santana de Parnaíba",
    "UF": "SÃO PAULO",
    "anual": 4.82,
    "JAN": 4.77,
    "FEB": 5.32,
    "MAR": 5,
    "APR": 4.96,
    "MAY": 4.4,
    "JUN": 4.27,
    "JUL": 4.3,
    "AUG": 5.25,
    "SEP": 4.73,
    "OCT": 4.8,
    "NOV": 4.85,
    "DEC": 5.15
  },
  {
    "LONG": -46.88,
    "LAT": -23.36,
    "CIDADE": "Cajamar",
    "UF": "SÃO PAULO",
    "anual": 4.78,
    "JAN": 4.76,
    "FEB": 5.31,
    "MAR": 4.97,
    "APR": 4.93,
    "MAY": 4.36,
    "JUN": 4.25,
    "JUL": 4.28,
    "AUG": 5.2,
    "SEP": 4.68,
    "OCT": 4.75,
    "NOV": 4.78,
    "DEC": 5.1
  },
  {
    "LONG": -46.74,
    "LAT": -23.36,
    "CIDADE": "Caieiras",
    "UF": "SÃO PAULO",
    "anual": 4.8,
    "JAN": 4.79,
    "FEB": 5.36,
    "MAR": 4.98,
    "APR": 4.95,
    "MAY": 4.37,
    "JUN": 4.26,
    "JUL": 4.28,
    "AUG": 5.2,
    "SEP": 4.72,
    "OCT": 4.78,
    "NOV": 4.81,
    "DEC": 5.14
  },
  {
    "LONG": -46.32,
    "LAT": -2.34,
    "CIDADE": "Arujá",
    "UF": "SÃO PAULO",
    "anual": 4.61,
    "JAN": 4.67,
    "FEB": 5.23,
    "MAR": 4.84,
    "APR": 4.72,
    "MAY": 4.14,
    "JUN": 4.04,
    "JUL": 4.07,
    "AUG": 4.97,
    "SEP": 4.51,
    "OCT": 4.59,
    "NOV": 4.59,
    "DEC": 4.97
  },
  {
    "LONG": -46.04,
    "LAT": -23.41,
    "CIDADE": "Guararema",
    "UF": "SÃO PAULO",
    "anual": 4.73,
    "JAN": 4.83,
    "FEB": 5.44,
    "MAR": 5,
    "APR": 4.84,
    "MAY": 4.17,
    "JUN": 3.97,
    "JUL": 4.09,
    "AUG": 5,
    "SEP": 4.63,
    "OCT": 4.81,
    "NOV": 4.79,
    "DEC": 5.16
  },
  {
    "LONG": -45.89,
    "LAT": -23.39,
    "CIDADE": "Santa Branca",
    "UF": "SÃO PAULO",
    "anual": 4.83,
    "JAN": 4.94,
    "FEB": 5.51,
    "MAR": 5.06,
    "APR": 4.98,
    "MAY": 4.27,
    "JUN": 4.17,
    "JUL": 4.22,
    "AUG": 5.08,
    "SEP": 4.69,
    "OCT": 4.95,
    "NOV": 4.9,
    "DEC": 5.2
  },
  {
    "LONG": -45.66,
    "LAT": -23.39,
    "CIDADE": "Paraibuna",
    "UF": "SÃO PAULO",
    "anual": 4.88,
    "JAN": 5.12,
    "FEB": 5.63,
    "MAR": 5.1,
    "APR": 5.04,
    "MAY": 4.33,
    "JUN": 4.22,
    "JUL": 4.25,
    "AUG": 5.11,
    "SEP": 4.69,
    "OCT": 4.93,
    "NOV": 4.91,
    "DEC": 5.26
  },
  {
    "LONG": -45.45,
    "LAT": -23.37,
    "CIDADE": "Natividade da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.83,
    "JAN": 5.1,
    "FEB": 5.56,
    "MAR": 5.05,
    "APR": 4.99,
    "MAY": 4.37,
    "JUN": 4.24,
    "JUL": 4.28,
    "AUG": 5.09,
    "SEP": 4.64,
    "OCT": 4.81,
    "NOV": 4.73,
    "DEC": 5.16
  },
  {
    "LONG": -45.08,
    "LAT": -23.43,
    "CIDADE": "Ubatuba",
    "UF": "SÃO PAULO",
    "anual": 4.23,
    "JAN": 4.42,
    "FEB": 5.02,
    "MAR": 4.42,
    "APR": 4.49,
    "MAY": 4.02,
    "JUN": 3.93,
    "JUL": 3.89,
    "AUG": 4.49,
    "SEP": 3.92,
    "OCT": 3.91,
    "NOV": 3.89,
    "DEC": 4.39
  },
  {
    "LONG": -49.48,
    "LAT": -2.33,
    "CIDADE": "Sarutaiá",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 5.07,
    "FEB": 5.48,
    "MAR": 5.35,
    "APR": 5.24,
    "MAY": 4.52,
    "JUN": 4.34,
    "JUL": 4.54,
    "AUG": 5.44,
    "SEP": 5.03,
    "OCT": 5.19,
    "NOV": 5.34,
    "DEC": 5.46
  },
  {
    "LONG": -49.38,
    "LAT": -2.33,
    "CIDADE": "Tejupá",
    "UF": "SÃO PAULO",
    "anual": 5.05,
    "JAN": 5.03,
    "FEB": 5.49,
    "MAR": 5.32,
    "APR": 5.19,
    "MAY": 4.46,
    "JUN": 4.29,
    "JUL": 4.47,
    "AUG": 5.39,
    "SEP": 5,
    "OCT": 5.17,
    "NOV": 5.33,
    "DEC": 5.45
  },
  {
    "LONG": -48.06,
    "LAT": -23.3,
    "CIDADE": "Quadra",
    "UF": "SÃO PAULO",
    "anual": 5.06,
    "JAN": 4.98,
    "FEB": 5.53,
    "MAR": 5.27,
    "APR": 5.23,
    "MAY": 4.54,
    "JUN": 4.45,
    "JUL": 4.48,
    "AUG": 5.42,
    "SEP": 5.06,
    "OCT": 5.15,
    "NOV": 5.22,
    "DEC": 5.35
  },
  {
    "LONG": -47.85,
    "LAT": -23.35,
    "CIDADE": "Tatuí",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.99,
    "FEB": 5.52,
    "MAR": 5.31,
    "APR": 5.22,
    "MAY": 4.57,
    "JUN": 4.48,
    "JUL": 4.5,
    "AUG": 5.44,
    "SEP": 5.07,
    "OCT": 5.15,
    "NOV": 5.2,
    "DEC": 5.36
  },
  {
    "LONG": -4.77,
    "LAT": -2.33,
    "CIDADE": "Boituva",
    "UF": "SÃO PAULO",
    "anual": 5.09,
    "JAN": 5.03,
    "FEB": 5.56,
    "MAR": 5.33,
    "APR": 5.24,
    "MAY": 4.62,
    "JUN": 4.48,
    "JUL": 4.54,
    "AUG": 5.45,
    "SEP": 5.09,
    "OCT": 5.17,
    "NOV": 5.21,
    "DEC": 5.39
  },
  {
    "LONG": -47.29,
    "LAT": -23.25,
    "CIDADE": "Itu",
    "UF": "SÃO PAULO",
    "anual": 5.09,
    "JAN": 5,
    "FEB": 5.55,
    "MAR": 5.31,
    "APR": 5.27,
    "MAY": 4.64,
    "JUN": 4.46,
    "JUL": 4.56,
    "AUG": 5.44,
    "SEP": 5.09,
    "OCT": 5.19,
    "NOV": 5.22,
    "DEC": 5.4
  },
  {
    "LONG": -47.14,
    "LAT": -23.31,
    "CIDADE": "Cabreúva",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.96,
    "FEB": 5.5,
    "MAR": 5.28,
    "APR": 5.26,
    "MAY": 4.64,
    "JUN": 4.44,
    "JUL": 4.54,
    "AUG": 5.46,
    "SEP": 5.08,
    "OCT": 5.18,
    "NOV": 5.18,
    "DEC": 5.37
  },
  {
    "LONG": -46.75,
    "LAT": -23.28,
    "CIDADE": "Francisco Morato",
    "UF": "SÃO PAULO",
    "anual": 4.9,
    "JAN": 4.85,
    "FEB": 5.42,
    "MAR": 5.09,
    "APR": 5.02,
    "MAY": 4.47,
    "JUN": 4.36,
    "JUL": 4.41,
    "AUG": 5.33,
    "SEP": 4.85,
    "OCT": 4.94,
    "NOV": 4.92,
    "DEC": 5.19
  },
  {
    "LONG": -46.73,
    "LAT": -23.32,
    "CIDADE": "Franco da Rocha",
    "UF": "SÃO PAULO",
    "anual": 4.9,
    "JAN": 4.85,
    "FEB": 5.42,
    "MAR": 5.09,
    "APR": 5.02,
    "MAY": 4.47,
    "JUN": 4.36,
    "JUL": 4.41,
    "AUG": 5.33,
    "SEP": 4.85,
    "OCT": 4.94,
    "NOV": 4.92,
    "DEC": 5.19
  },
  {
    "LONG": -46.59,
    "LAT": -23.32,
    "CIDADE": "Mairiporã",
    "UF": "SÃO PAULO",
    "anual": 4.87,
    "JAN": 4.79,
    "FEB": 5.38,
    "MAR": 5.07,
    "APR": 5,
    "MAY": 4.42,
    "JUN": 4.37,
    "JUL": 4.4,
    "AUG": 5.32,
    "SEP": 4.83,
    "OCT": 4.89,
    "NOV": 4.82,
    "DEC": 5.15
  },
  {
    "LONG": -46.22,
    "LAT": -23.32,
    "CIDADE": "Santa Isabel",
    "UF": "SÃO PAULO",
    "anual": 4.68,
    "JAN": 4.75,
    "FEB": 5.35,
    "MAR": 4.93,
    "APR": 4.79,
    "MAY": 4.16,
    "JUN": 4.07,
    "JUL": 4.07,
    "AUG": 5.03,
    "SEP": 4.56,
    "OCT": 4.69,
    "NOV": 4.66,
    "DEC": 5.06
  },
  {
    "LONG": -45.97,
    "LAT": -23.3,
    "CIDADE": "Jacareí",
    "UF": "SÃO PAULO",
    "anual": 4.76,
    "JAN": 4.86,
    "FEB": 5.43,
    "MAR": 5.01,
    "APR": 4.88,
    "MAY": 4.19,
    "JUN": 4.04,
    "JUL": 4.13,
    "AUG": 5.04,
    "SEP": 4.66,
    "OCT": 4.88,
    "NOV": 4.82,
    "DEC": 5.16
  },
  {
    "LONG": -45.69,
    "LAT": -23.25,
    "CIDADE": "Jambeiro",
    "UF": "SÃO PAULO",
    "anual": 4.88,
    "JAN": 5.06,
    "FEB": 5.57,
    "MAR": 5.09,
    "APR": 5.02,
    "MAY": 4.36,
    "JUN": 4.26,
    "JUL": 4.31,
    "AUG": 5.16,
    "SEP": 4.73,
    "OCT": 4.95,
    "NOV": 4.87,
    "DEC": 5.21
  },
  {
    "LONG": -45.54,
    "LAT": -23.26,
    "CIDADE": "Redenção da Serra",
    "UF": "SÃO PAULO",
    "anual": 4.87,
    "JAN": 5.06,
    "FEB": 5.59,
    "MAR": 5.06,
    "APR": 5.01,
    "MAY": 4.38,
    "JUN": 4.26,
    "JUL": 4.33,
    "AUG": 5.17,
    "SEP": 4.72,
    "OCT": 4.9,
    "NOV": 4.8,
    "DEC": 5.18
  },
  {
    "LONG": -49.61,
    "LAT": -2.32,
    "CIDADE": "Timburi",
    "UF": "SÃO PAULO",
    "anual": 5.13,
    "JAN": 5.12,
    "FEB": 5.56,
    "MAR": 5.44,
    "APR": 5.33,
    "MAY": 4.56,
    "JUN": 4.35,
    "JUL": 4.54,
    "AUG": 5.45,
    "SEP": 5.04,
    "OCT": 5.24,
    "NOV": 5.4,
    "DEC": 5.55
  },
  {
    "LONG": -49.38,
    "LAT": -23.2,
    "CIDADE": "Piraju",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 5.08,
    "FEB": 5.48,
    "MAR": 5.36,
    "APR": 5.22,
    "MAY": 4.48,
    "JUN": 4.32,
    "JUL": 4.48,
    "AUG": 5.39,
    "SEP": 5.02,
    "OCT": 5.19,
    "NOV": 5.38,
    "DEC": 5.51
  },
  {
    "LONG": -48.12,
    "LAT": -23.18,
    "CIDADE": "Porangaba",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 5.05,
    "FEB": 5.52,
    "MAR": 5.27,
    "APR": 5.22,
    "MAY": 4.55,
    "JUN": 4.43,
    "JUL": 4.53,
    "AUG": 5.43,
    "SEP": 5.07,
    "OCT": 5.14,
    "NOV": 5.23,
    "DEC": 5.36
  },
  {
    "LONG": -48.2,
    "LAT": -23.25,
    "CIDADE": "Torre de Pedra",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 5.05,
    "FEB": 5.52,
    "MAR": 5.27,
    "APR": 5.22,
    "MAY": 4.55,
    "JUN": 4.43,
    "JUL": 4.53,
    "AUG": 5.43,
    "SEP": 5.07,
    "OCT": 5.14,
    "NOV": 5.23,
    "DEC": 5.36
  },
  {
    "LONG": -47.95,
    "LAT": -23.23,
    "CIDADE": "Cesário Lange",
    "UF": "SÃO PAULO",
    "anual": 5.11,
    "JAN": 5.03,
    "FEB": 5.54,
    "MAR": 5.35,
    "APR": 5.28,
    "MAY": 4.6,
    "JUN": 4.5,
    "JUL": 4.53,
    "AUG": 5.48,
    "SEP": 5.12,
    "OCT": 5.22,
    "NOV": 5.24,
    "DEC": 5.39
  },
  {
    "LONG": -47.75,
    "LAT": -2.32,
    "CIDADE": "Cerquilho",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 5.07,
    "FEB": 5.57,
    "MAR": 5.35,
    "APR": 5.28,
    "MAY": 4.62,
    "JUN": 4.51,
    "JUL": 4.56,
    "AUG": 5.44,
    "SEP": 5.11,
    "OCT": 5.23,
    "NOV": 5.24,
    "DEC": 5.42
  },
  {
    "LONG": -47.53,
    "LAT": -23.21,
    "CIDADE": "Porto Feliz",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 5.04,
    "FEB": 5.61,
    "MAR": 5.36,
    "APR": 5.23,
    "MAY": 4.67,
    "JUN": 4.49,
    "JUL": 4.55,
    "AUG": 5.47,
    "SEP": 5.09,
    "OCT": 5.24,
    "NOV": 5.23,
    "DEC": 5.42
  },
  {
    "LONG": -47.29,
    "LAT": -23.2,
    "CIDADE": "Salto",
    "UF": "SÃO PAULO",
    "anual": 5.11,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 5.31,
    "APR": 5.27,
    "MAY": 4.66,
    "JUN": 4.48,
    "JUL": 4.59,
    "AUG": 5.46,
    "SEP": 5.1,
    "OCT": 5.21,
    "NOV": 5.21,
    "DEC": 5.41
  },
  {
    "LONG": -47.06,
    "LAT": -23.15,
    "CIDADE": "Itupeva",
    "UF": "SÃO PAULO",
    "anual": 5.1,
    "JAN": 4.93,
    "FEB": 5.51,
    "MAR": 5.27,
    "APR": 5.25,
    "MAY": 4.65,
    "JUN": 4.51,
    "JUL": 4.61,
    "AUG": 5.5,
    "SEP": 5.11,
    "OCT": 5.24,
    "NOV": 5.18,
    "DEC": 5.38
  },
  {
    "LONG": -46.9,
    "LAT": -23.19,
    "CIDADE": "Jundiaí",
    "UF": "SÃO PAULO",
    "anual": 5.03,
    "JAN": 4.93,
    "FEB": 5.46,
    "MAR": 5.23,
    "APR": 5.13,
    "MAY": 4.57,
    "JUN": 4.46,
    "JUL": 4.54,
    "AUG": 5.42,
    "SEP": 5.04,
    "OCT": 5.17,
    "NOV": 5.11,
    "DEC": 5.3
  },
  {
    "LONG": -46.82,
    "LAT": -23.21,
    "CIDADE": "Várzea Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.03,
    "JAN": 4.93,
    "FEB": 5.46,
    "MAR": 5.23,
    "APR": 5.13,
    "MAY": 4.57,
    "JUN": 4.46,
    "JUL": 4.54,
    "AUG": 5.42,
    "SEP": 5.04,
    "OCT": 5.17,
    "NOV": 5.11,
    "DEC": 5.3
  },
  {
    "LONG": -46.79,
    "LAT": -23.21,
    "CIDADE": "Campo Limpo Paulista",
    "UF": "SÃO PAULO",
    "anual": 5,
    "JAN": 4.9,
    "FEB": 5.48,
    "MAR": 5.17,
    "APR": 5.1,
    "MAY": 4.56,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.45,
    "SEP": 4.99,
    "OCT": 5.1,
    "NOV": 5.02,
    "DEC": 5.24
  },
  {
    "LONG": -46.4,
    "LAT": -23.18,
    "CIDADE": "Nazaré Paulista",
    "UF": "SÃO PAULO",
    "anual": 4.86,
    "JAN": 4.87,
    "FEB": 5.46,
    "MAR": 5.04,
    "APR": 4.96,
    "MAY": 4.4,
    "JUN": 4.28,
    "JUL": 4.31,
    "AUG": 5.27,
    "SEP": 4.78,
    "OCT": 4.9,
    "NOV": 4.83,
    "DEC": 5.21
  },
  {
    "LONG": -46.16,
    "LAT": -23.2,
    "CIDADE": "Igaratá",
    "UF": "SÃO PAULO",
    "anual": 4.69,
    "JAN": 4.75,
    "FEB": 5.29,
    "MAR": 4.93,
    "APR": 4.85,
    "MAY": 4.16,
    "JUN": 4.07,
    "JUL": 4.09,
    "AUG": 5.06,
    "SEP": 4.6,
    "OCT": 4.73,
    "NOV": 4.69,
    "DEC": 5.04
  },
  {
    "LONG": -45.88,
    "LAT": "#VALOR!",
    "CIDADE": "São José dos Campos",
    "UF": "SÃO PAULO",
    "anual": 4.79,
    "JAN": 4.88,
    "FEB": 5.43,
    "MAR": 5.02,
    "APR": 4.9,
    "MAY": 4.23,
    "JUN": 4.14,
    "JUL": 4.23,
    "AUG": 5.13,
    "SEP": 4.72,
    "OCT": 4.89,
    "NOV": 4.81,
    "DEC": 5.15
  },
  {
    "LONG": -45.31,
    "LAT": -2.32,
    "CIDADE": "São Luíz do Paraitinga",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 5.15,
    "FEB": 5.59,
    "MAR": 5.12,
    "APR": 5.08,
    "MAY": 4.44,
    "JUN": 4.41,
    "JUL": 4.42,
    "AUG": 5.26,
    "SEP": 4.84,
    "OCT": 5,
    "NOV": 4.87,
    "DEC": 5.25
  },
  {
    "LONG": -49.63,
    "LAT": -2.31,
    "CIDADE": "Ipaussu",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5.06,
    "FEB": 5.54,
    "MAR": 5.46,
    "APR": 5.38,
    "MAY": 4.59,
    "JUN": 4.41,
    "JUL": 4.57,
    "AUG": 5.48,
    "SEP": 5.07,
    "OCT": 5.26,
    "NOV": 5.37,
    "DEC": 5.51
  },
  {
    "LONG": -49.05,
    "LAT": -2.31,
    "CIDADE": "Arandu",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.99,
    "FEB": 5.42,
    "MAR": 5.25,
    "APR": 5.25,
    "MAY": 4.57,
    "JUN": 4.41,
    "JUL": 4.53,
    "AUG": 5.47,
    "SEP": 5.09,
    "OCT": 5.18,
    "NOV": 5.28,
    "DEC": 5.39
  },
  {
    "LONG": -48.93,
    "LAT": -23.11,
    "CIDADE": "Avaré",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.98,
    "FEB": 5.42,
    "MAR": 5.25,
    "APR": 5.23,
    "MAY": 4.55,
    "JUN": 4.46,
    "JUL": 4.51,
    "AUG": 5.48,
    "SEP": 5.11,
    "OCT": 5.18,
    "NOV": 5.27,
    "DEC": 5.35
  },
  {
    "LONG": -48.62,
    "LAT": -23.11,
    "CIDADE": "Itatinga",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 4.89,
    "FEB": 5.36,
    "MAR": 5.22,
    "APR": 5.23,
    "MAY": 4.53,
    "JUN": 4.42,
    "JUL": 4.5,
    "AUG": 5.47,
    "SEP": 5.06,
    "OCT": 5.11,
    "NOV": 5.19,
    "DEC": 5.25
  },
  {
    "LONG": -48.37,
    "LAT": -2.31,
    "CIDADE": "Pardinho",
    "UF": "SÃO PAULO",
    "anual": 5.01,
    "JAN": 4.87,
    "FEB": 5.35,
    "MAR": 5.13,
    "APR": 5.19,
    "MAY": 4.54,
    "JUN": 4.47,
    "JUL": 4.52,
    "AUG": 5.42,
    "SEP": 5.06,
    "OCT": 5.13,
    "NOV": 5.13,
    "DEC": 5.25
  },
  {
    "LONG": -4.83,
    "LAT": -2.31,
    "CIDADE": "Bofete",
    "UF": "SÃO PAULO",
    "anual": 5.05,
    "JAN": 4.95,
    "FEB": 5.45,
    "MAR": 5.23,
    "APR": 5.26,
    "MAY": 4.56,
    "JUN": 4.5,
    "JUL": 4.54,
    "AUG": 5.43,
    "SEP": 5.05,
    "OCT": 5.12,
    "NOV": 5.18,
    "DEC": 5.3
  },
  {
    "LONG": -47.97,
    "LAT": -23.08,
    "CIDADE": "Pereiras",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5.07,
    "FEB": 5.59,
    "MAR": 5.38,
    "APR": 5.33,
    "MAY": 4.62,
    "JUN": 4.53,
    "JUL": 4.59,
    "AUG": 5.5,
    "SEP": 5.14,
    "OCT": 5.23,
    "NOV": 5.27,
    "DEC": 5.42
  },
  {
    "LONG": -4.78,
    "LAT": -2.31,
    "CIDADE": "Laranjal Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.13,
    "JAN": 5.06,
    "FEB": 5.58,
    "MAR": 5.33,
    "APR": 5.33,
    "MAY": 4.61,
    "JUN": 4.51,
    "JUL": 4.58,
    "AUG": 5.49,
    "SEP": 5.12,
    "OCT": 5.27,
    "NOV": 5.25,
    "DEC": 5.42
  },
  {
    "LONG": -47.72,
    "LAT": -23.11,
    "CIDADE": "Tietê",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.06,
    "FEB": 5.63,
    "MAR": 5.4,
    "APR": 5.33,
    "MAY": 4.67,
    "JUN": 4.54,
    "JUL": 4.58,
    "AUG": 5.48,
    "SEP": 5.14,
    "OCT": 5.27,
    "NOV": 5.29,
    "DEC": 5.43
  },
  {
    "LONG": -47.79,
    "LAT": -23.09,
    "CIDADE": "Jumirim",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.06,
    "FEB": 5.63,
    "MAR": 5.4,
    "APR": 5.33,
    "MAY": 4.67,
    "JUN": 4.54,
    "JUL": 4.58,
    "AUG": 5.48,
    "SEP": 5.14,
    "OCT": 5.27,
    "NOV": 5.29,
    "DEC": 5.43
  },
  {
    "LONG": -47.21,
    "LAT": -23.08,
    "CIDADE": "Indaiatuba",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5.12,
    "FEB": 5.57,
    "MAR": 5.31,
    "APR": 5.31,
    "MAY": 4.63,
    "JUN": 4.51,
    "JUL": 4.64,
    "AUG": 5.47,
    "SEP": 5.14,
    "OCT": 5.28,
    "NOV": 5.2,
    "DEC": 5.46
  },
  {
    "LONG": -46.95,
    "LAT": -2.31,
    "CIDADE": "Louveira",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 4.98,
    "FEB": 5.53,
    "MAR": 5.27,
    "APR": 5.26,
    "MAY": 4.69,
    "JUN": 4.57,
    "JUL": 4.68,
    "AUG": 5.52,
    "SEP": 5.14,
    "OCT": 5.3,
    "NOV": 5.2,
    "DEC": 5.36
  },
  {
    "LONG": -46.73,
    "LAT": -23.1,
    "CIDADE": "Jarinu",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.97,
    "FEB": 5.51,
    "MAR": 5.27,
    "APR": 5.2,
    "MAY": 4.64,
    "JUN": 4.5,
    "JUL": 4.61,
    "AUG": 5.48,
    "SEP": 5.07,
    "OCT": 5.2,
    "NOV": 5.09,
    "DEC": 5.33
  },
  {
    "LONG": -46.56,
    "LAT": -23.12,
    "CIDADE": "Atibaia",
    "UF": "SÃO PAULO",
    "anual": 5.03,
    "JAN": 4.91,
    "FEB": 5.46,
    "MAR": 5.19,
    "APR": 5.17,
    "MAY": 4.59,
    "JUN": 4.5,
    "JUL": 4.55,
    "AUG": 5.47,
    "SEP": 5.04,
    "OCT": 5.14,
    "NOV": 5.03,
    "DEC": 5.32
  },
  {
    "LONG": -46.47,
    "LAT": -23.14,
    "CIDADE": "Bom Jesus dos Perdões",
    "UF": "SÃO PAULO",
    "anual": 5,
    "JAN": 4.87,
    "FEB": 5.48,
    "MAR": 5.14,
    "APR": 5.17,
    "MAY": 4.57,
    "JUN": 4.43,
    "JUL": 4.49,
    "AUG": 5.43,
    "SEP": 4.96,
    "OCT": 5.1,
    "NOV": 5,
    "DEC": 5.32
  },
  {
    "LONG": -46.36,
    "LAT": -2.31,
    "CIDADE": "Piracaia",
    "UF": "SÃO PAULO",
    "anual": 4.96,
    "JAN": 4.82,
    "FEB": 5.47,
    "MAR": 5.09,
    "APR": 5.08,
    "MAY": 4.55,
    "JUN": 4.39,
    "JUL": 4.43,
    "AUG": 5.41,
    "SEP": 4.92,
    "OCT": 5.04,
    "NOV": 4.97,
    "DEC": 5.28
  },
  {
    "LONG": -4.57,
    "LAT": -23.1,
    "CIDADE": "Caçapava",
    "UF": "SÃO PAULO",
    "anual": 4.86,
    "JAN": 4.86,
    "FEB": 5.41,
    "MAR": 5.1,
    "APR": 5.05,
    "MAY": 4.38,
    "JUN": 4.29,
    "JUL": 4.35,
    "AUG": 5.23,
    "SEP": 4.81,
    "OCT": 4.9,
    "NOV": 4.8,
    "DEC": 5.09
  },
  {
    "LONG": -45.19,
    "LAT": -23.09,
    "CIDADE": "Lagoinha",
    "UF": "SÃO PAULO",
    "anual": 4.93,
    "JAN": 4.97,
    "FEB": 5.49,
    "MAR": 5.02,
    "APR": 5.06,
    "MAY": 4.51,
    "JUN": 4.47,
    "JUL": 4.51,
    "AUG": 5.33,
    "SEP": 4.87,
    "OCT": 4.98,
    "NOV": 4.78,
    "DEC": 5.12
  },
  {
    "LONG": -44.96,
    "LAT": -23.07,
    "CIDADE": "Cunha",
    "UF": "SÃO PAULO",
    "anual": 5.02,
    "JAN": 5.19,
    "FEB": 5.59,
    "MAR": 5.16,
    "APR": 5.18,
    "MAY": 4.55,
    "JUN": 4.51,
    "JUL": 4.56,
    "AUG": 5.34,
    "SEP": 4.96,
    "OCT": 5.09,
    "NOV": 4.88,
    "DEC": 5.25
  },
  {
    "LONG": -49.87,
    "LAT": -22.98,
    "CIDADE": "Ourinhos",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.09,
    "FEB": 5.53,
    "MAR": 5.42,
    "APR": 5.38,
    "MAY": 4.63,
    "JUN": 4.46,
    "JUL": 4.62,
    "AUG": 5.54,
    "SEP": 5.09,
    "OCT": 5.28,
    "NOV": 5.38,
    "DEC": 5.56
  },
  {
    "LONG": "#VALOR!",
    "LAT": -23.04,
    "CIDADE": "Chavantes",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.42,
    "APR": 5.43,
    "MAY": 4.65,
    "JUN": 4.47,
    "JUL": 4.64,
    "AUG": 5.57,
    "SEP": 5.11,
    "OCT": 5.28,
    "NOV": 5.38,
    "DEC": 5.52
  },
  {
    "LONG": -49.78,
    "LAT": -2.3,
    "CIDADE": "Canitar",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.42,
    "APR": 5.43,
    "MAY": 4.65,
    "JUN": 4.47,
    "JUL": 4.64,
    "AUG": 5.57,
    "SEP": 5.11,
    "OCT": 5.28,
    "NOV": 5.38,
    "DEC": 5.52
  },
  {
    "LONG": -49.47,
    "LAT": -2.3,
    "CIDADE": "Bernardino de Campos",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.07,
    "FEB": 5.51,
    "MAR": 5.38,
    "APR": 5.37,
    "MAY": 4.66,
    "JUN": 4.46,
    "JUL": 4.65,
    "AUG": 5.56,
    "SEP": 5.1,
    "OCT": 5.27,
    "NOV": 5.36,
    "DEC": 5.53
  },
  {
    "LONG": -49.32,
    "LAT": -2.3,
    "CIDADE": "Manduri",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 5,
    "FEB": 5.48,
    "MAR": 5.35,
    "APR": 5.34,
    "MAY": 4.61,
    "JUN": 4.44,
    "JUL": 4.62,
    "AUG": 5.52,
    "SEP": 5.06,
    "OCT": 5.24,
    "NOV": 5.33,
    "DEC": 5.45
  },
  {
    "LONG": -49.17,
    "LAT": -2.3,
    "CIDADE": "Cerqueira César",
    "UF": "SÃO PAULO",
    "anual": 5.11,
    "JAN": 4.99,
    "FEB": 5.46,
    "MAR": 5.3,
    "APR": 5.31,
    "MAY": 4.58,
    "JUN": 4.48,
    "JUL": 4.62,
    "AUG": 5.52,
    "SEP": 5.11,
    "OCT": 5.24,
    "NOV": 5.31,
    "DEC": 5.41
  },
  {
    "LONG": -48.01,
    "LAT": -23.02,
    "CIDADE": "Conchas",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.07,
    "FEB": 5.57,
    "MAR": 5.4,
    "APR": 5.34,
    "MAY": 4.64,
    "JUN": 4.5,
    "JUL": 4.6,
    "AUG": 5.51,
    "SEP": 5.14,
    "OCT": 5.23,
    "NOV": 5.29,
    "DEC": 5.44
  },
  {
    "LONG": -47.51,
    "LAT": -23,
    "CIDADE": "Capivari",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5.07,
    "FEB": 5.61,
    "MAR": 5.33,
    "APR": 5.3,
    "MAY": 4.71,
    "JUN": 4.54,
    "JUL": 4.62,
    "AUG": 5.49,
    "SEP": 5.14,
    "OCT": 5.3,
    "NOV": 5.21,
    "DEC": 5.4
  },
  {
    "LONG": -47.53,
    "LAT": -2.3,
    "CIDADE": "Rafard",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5.07,
    "FEB": 5.61,
    "MAR": 5.33,
    "APR": 5.3,
    "MAY": 4.71,
    "JUN": 4.54,
    "JUL": 4.62,
    "AUG": 5.49,
    "SEP": 5.14,
    "OCT": 5.3,
    "NOV": 5.21,
    "DEC": 5.4
  },
  {
    "LONG": -47.37,
    "LAT": -23.04,
    "CIDADE": "Elias Fausto",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.15,
    "FEB": 5.57,
    "MAR": 5.33,
    "APR": 5.37,
    "MAY": 4.71,
    "JUN": 4.57,
    "JUL": 4.67,
    "AUG": 5.53,
    "SEP": 5.17,
    "OCT": 5.31,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -46.98,
    "LAT": -23.03,
    "CIDADE": "Vinhedo",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.97,
    "FEB": 5.5,
    "MAR": 5.28,
    "APR": 5.29,
    "MAY": 4.69,
    "JUN": 4.6,
    "JUL": 4.71,
    "AUG": 5.53,
    "SEP": 5.19,
    "OCT": 5.33,
    "NOV": 5.21,
    "DEC": 5.36
  },
  {
    "LONG": -47,
    "LAT": -22.97,
    "CIDADE": "Valinhos",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.97,
    "FEB": 5.5,
    "MAR": 5.28,
    "APR": 5.29,
    "MAY": 4.69,
    "JUN": 4.6,
    "JUL": 4.71,
    "AUG": 5.53,
    "SEP": 5.19,
    "OCT": 5.33,
    "NOV": 5.21,
    "DEC": 5.36
  },
  {
    "LONG": -46.85,
    "LAT": -2.3,
    "CIDADE": "Itatiba",
    "UF": "SÃO PAULO",
    "anual": 5.13,
    "JAN": 4.97,
    "FEB": 5.55,
    "MAR": 5.28,
    "APR": 5.31,
    "MAY": 4.7,
    "JUN": 4.58,
    "JUL": 4.69,
    "AUG": 5.54,
    "SEP": 5.16,
    "OCT": 5.27,
    "NOV": 5.18,
    "DEC": 5.37
  },
  {
    "LONG": -46.54,
    "LAT": -22.95,
    "CIDADE": "Bragança Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.06,
    "JAN": 4.87,
    "FEB": 5.46,
    "MAR": 5.18,
    "APR": 5.21,
    "MAY": 4.63,
    "JUN": 4.49,
    "JUL": 4.62,
    "AUG": 5.53,
    "SEP": 5.11,
    "OCT": 5.18,
    "NOV": 5.1,
    "DEC": 5.32
  },
  {
    "LONG": -45.84,
    "LAT": -22.95,
    "CIDADE": "Monteiro Lobato",
    "UF": "SÃO PAULO",
    "anual": 4.68,
    "JAN": 4.52,
    "FEB": 5.09,
    "MAR": 4.74,
    "APR": 4.83,
    "MAY": 4.38,
    "JUN": 4.34,
    "JUL": 4.35,
    "AUG": 5.26,
    "SEP": 4.71,
    "OCT": 4.67,
    "NOV": 4.51,
    "DEC": 4.78
  },
  {
    "LONG": -45.55,
    "LAT": -22.96,
    "CIDADE": "Tremembé",
    "UF": "SÃO PAULO",
    "anual": 4.88,
    "JAN": 4.88,
    "FEB": 5.44,
    "MAR": 5.03,
    "APR": 5.06,
    "MAY": 4.43,
    "JUN": 4.3,
    "JUL": 4.37,
    "AUG": 5.26,
    "SEP": 4.87,
    "OCT": 4.97,
    "NOV": 4.83,
    "DEC": 5.15
  },
  {
    "LONG": -45.56,
    "LAT": -23.01,
    "CIDADE": "Taubaté",
    "UF": "SÃO PAULO",
    "anual": 4.88,
    "JAN": 4.88,
    "FEB": 5.44,
    "MAR": 5.03,
    "APR": 5.06,
    "MAY": 4.43,
    "JUN": 4.3,
    "JUL": 4.37,
    "AUG": 5.26,
    "SEP": 4.87,
    "OCT": 4.97,
    "NOV": 4.83,
    "DEC": 5.15
  },
  {
    "LONG": -50.73,
    "LAT": -22.9,
    "CIDADE": "Florínia",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.39,
    "FEB": 5.63,
    "MAR": 5.51,
    "APR": 5.43,
    "MAY": 4.69,
    "JUN": 4.46,
    "JUL": 4.61,
    "AUG": 5.46,
    "SEP": 5.05,
    "OCT": 5.27,
    "NOV": 5.49,
    "DEC": 5.66
  },
  {
    "LONG": -49.98,
    "LAT": -22.89,
    "CIDADE": "Salto Grande",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.12,
    "FEB": 5.54,
    "MAR": 5.36,
    "APR": 5.39,
    "MAY": 4.65,
    "JUN": 4.48,
    "JUL": 4.65,
    "AUG": 5.5,
    "SEP": 5.08,
    "OCT": 5.27,
    "NOV": 5.33,
    "DEC": 5.58
  },
  {
    "LONG": -49.64,
    "LAT": -22.9,
    "CIDADE": "Santa Cruz do Rio Pardo",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.09,
    "FEB": 5.53,
    "MAR": 5.38,
    "APR": 5.39,
    "MAY": 4.64,
    "JUN": 4.5,
    "JUL": 4.66,
    "AUG": 5.56,
    "SEP": 5.1,
    "OCT": 5.31,
    "NOV": 5.34,
    "DEC": 5.52
  },
  {
    "LONG": -49.34,
    "LAT": -22.94,
    "CIDADE": "Óleo",
    "UF": "SÃO PAULO",
    "anual": 5.13,
    "JAN": 5,
    "FEB": 5.49,
    "MAR": 5.36,
    "APR": 5.35,
    "MAY": 4.64,
    "JUN": 4.48,
    "JUL": 4.64,
    "AUG": 5.54,
    "SEP": 5.06,
    "OCT": 5.26,
    "NOV": 5.33,
    "DEC": 5.44
  },
  {
    "LONG": -49.24,
    "LAT": -22.88,
    "CIDADE": "Águas de Santa Bárbara",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 5,
    "FEB": 5.49,
    "MAR": 5.34,
    "APR": 5.35,
    "MAY": 4.62,
    "JUN": 4.5,
    "JUL": 4.66,
    "AUG": 5.55,
    "SEP": 5.08,
    "OCT": 5.26,
    "NOV": 5.35,
    "DEC": 5.42
  },
  {
    "LONG": -49.16,
    "LAT": -2.29,
    "CIDADE": "Iaras",
    "UF": "SÃO PAULO",
    "anual": 5.13,
    "JAN": 4.96,
    "FEB": 5.47,
    "MAR": 5.31,
    "APR": 5.35,
    "MAY": 4.61,
    "JUN": 4.5,
    "JUL": 4.67,
    "AUG": 5.58,
    "SEP": 5.11,
    "OCT": 5.24,
    "NOV": 5.31,
    "DEC": 5.4
  },
  {
    "LONG": -48.44,
    "LAT": -22.88,
    "CIDADE": "Botucatu",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 4.95,
    "FEB": 5.56,
    "MAR": 5.34,
    "APR": 5.33,
    "MAY": 4.67,
    "JUN": 4.54,
    "JUL": 4.66,
    "AUG": 5.55,
    "SEP": 5.18,
    "OCT": 5.3,
    "NOV": 5.3,
    "DEC": 5.36
  },
  {
    "LONG": -47.56,
    "LAT": -2.29,
    "CIDADE": "Mombuca",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.12,
    "FEB": 5.59,
    "MAR": 5.34,
    "APR": 5.28,
    "MAY": 4.72,
    "JUN": 4.53,
    "JUL": 4.69,
    "AUG": 5.53,
    "SEP": 5.16,
    "OCT": 5.31,
    "NOV": 5.19,
    "DEC": 5.38
  },
  {
    "LONG": -47.31,
    "LAT": -22.95,
    "CIDADE": "Monte Mor",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.14,
    "FEB": 5.57,
    "MAR": 5.36,
    "APR": 5.38,
    "MAY": 4.75,
    "JUN": 4.59,
    "JUL": 4.7,
    "AUG": 5.55,
    "SEP": 5.2,
    "OCT": 5.34,
    "NOV": 5.24,
    "DEC": 5.41
  },
  {
    "LONG": -47.21,
    "LAT": -22.85,
    "CIDADE": "Hortolândia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.1,
    "FEB": 5.52,
    "MAR": 5.34,
    "APR": 5.38,
    "MAY": 4.73,
    "JUN": 4.63,
    "JUL": 4.73,
    "AUG": 5.58,
    "SEP": 5.22,
    "OCT": 5.37,
    "NOV": 5.26,
    "DEC": 5.45
  },
  {
    "LONG": -47.07,
    "LAT": -22.91,
    "CIDADE": "Campinas",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.05,
    "FEB": 5.5,
    "MAR": 5.3,
    "APR": 5.32,
    "MAY": 4.73,
    "JUN": 4.61,
    "JUL": 4.74,
    "AUG": 5.51,
    "SEP": 5.22,
    "OCT": 5.36,
    "NOV": 5.18,
    "DEC": 5.39
  },
  {
    "LONG": "#VALOR!",
    "LAT": -22.88,
    "CIDADE": "Morungaba",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 4.92,
    "FEB": 5.53,
    "MAR": 5.24,
    "APR": 5.3,
    "MAY": 4.73,
    "JUN": 4.55,
    "JUL": 4.68,
    "AUG": 5.57,
    "SEP": 5.18,
    "OCT": 5.27,
    "NOV": 5.14,
    "DEC": 5.34
  },
  {
    "LONG": -46.41,
    "LAT": -22.89,
    "CIDADE": "Vargem",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 4.82,
    "FEB": 5.42,
    "MAR": 5.15,
    "APR": 5.3,
    "MAY": 4.69,
    "JUN": 4.57,
    "JUL": 4.68,
    "AUG": 5.55,
    "SEP": 5.16,
    "OCT": 5.23,
    "NOV": 5.1,
    "DEC": 5.27
  },
  {
    "LONG": -46.27,
    "LAT": -22.93,
    "CIDADE": "Joanópolis",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.7,
    "FEB": 5.24,
    "MAR": 4.96,
    "APR": 5.15,
    "MAY": 4.65,
    "JUN": 4.55,
    "JUL": 4.64,
    "AUG": 5.54,
    "SEP": 5.13,
    "OCT": 5.16,
    "NOV": 4.92,
    "DEC": 5.08
  },
  {
    "LONG": -45.46,
    "LAT": -22.93,
    "CIDADE": "Pindamonhangaba",
    "UF": "SÃO PAULO",
    "anual": 4.83,
    "JAN": 4.88,
    "FEB": 5.29,
    "MAR": 4.93,
    "APR": 4.96,
    "MAY": 4.42,
    "JUN": 4.38,
    "JUL": 4.41,
    "AUG": 5.29,
    "SEP": 4.86,
    "OCT": 4.89,
    "NOV": 4.7,
    "DEC": 5.02
  },
  {
    "LONG": -45.31,
    "LAT": -22.89,
    "CIDADE": "Roseira",
    "UF": "SÃO PAULO",
    "anual": 4.93,
    "JAN": 4.99,
    "FEB": 5.46,
    "MAR": 5.08,
    "APR": 5.07,
    "MAY": 4.47,
    "JUN": 4.4,
    "JUL": 4.42,
    "AUG": 5.33,
    "SEP": 4.95,
    "OCT": 5.01,
    "NOV": 4.8,
    "DEC": 5.15
  },
  {
    "LONG": -50.79,
    "LAT": -2.28,
    "CIDADE": "Pedrinhas Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.34,
    "FEB": 5.54,
    "MAR": 5.44,
    "APR": 5.4,
    "MAY": 4.71,
    "JUN": 4.46,
    "JUL": 4.64,
    "AUG": 5.47,
    "SEP": 5.07,
    "OCT": 5.26,
    "NOV": 5.44,
    "DEC": 5.63
  },
  {
    "LONG": -50.22,
    "LAT": -22.79,
    "CIDADE": "Palmital",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.15,
    "FEB": 5.53,
    "MAR": 5.37,
    "APR": 5.37,
    "MAY": 4.67,
    "JUN": 4.51,
    "JUL": 4.69,
    "AUG": 5.5,
    "SEP": 5.14,
    "OCT": 5.25,
    "NOV": 5.38,
    "DEC": 5.54
  },
  {
    "LONG": -50.07,
    "LAT": -2.28,
    "CIDADE": "Ibirarema",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.08,
    "FEB": 5.52,
    "MAR": 5.35,
    "APR": 5.39,
    "MAY": 4.67,
    "JUN": 4.49,
    "JUL": 4.68,
    "AUG": 5.52,
    "SEP": 5.12,
    "OCT": 5.29,
    "NOV": 5.35,
    "DEC": 5.53
  },
  {
    "LONG": -49.93,
    "LAT": -2.28,
    "CIDADE": "Ribeirão do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.09,
    "FEB": 5.52,
    "MAR": 5.32,
    "APR": 5.38,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.69,
    "AUG": 5.51,
    "SEP": 5.09,
    "OCT": 5.28,
    "NOV": 5.35,
    "DEC": 5.54
  },
  {
    "LONG": -4.87,
    "LAT": -22.81,
    "CIDADE": "Pratânia",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.03,
    "FEB": 5.5,
    "MAR": 5.34,
    "APR": 5.31,
    "MAY": 4.71,
    "JUN": 4.58,
    "JUL": 4.72,
    "AUG": 5.61,
    "SEP": 5.17,
    "OCT": 5.33,
    "NOV": 5.32,
    "DEC": 5.42
  },
  {
    "LONG": -4.81,
    "LAT": -2.28,
    "CIDADE": "Anhembi",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 5.11,
    "FEB": 5.66,
    "MAR": 5.45,
    "APR": 5.31,
    "MAY": 4.67,
    "JUN": 4.54,
    "JUL": 4.6,
    "AUG": 5.48,
    "SEP": 5.16,
    "OCT": 5.34,
    "NOV": 5.39,
    "DEC": 5.51
  },
  {
    "LONG": -47.61,
    "LAT": -22.84,
    "CIDADE": "Rio das Pedras",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.08,
    "FEB": 5.56,
    "MAR": 5.34,
    "APR": 5.31,
    "MAY": 4.74,
    "JUN": 4.59,
    "JUL": 4.71,
    "AUG": 5.54,
    "SEP": 5.19,
    "OCT": 5.32,
    "NOV": 5.23,
    "DEC": 5.38
  },
  {
    "LONG": -47.68,
    "LAT": -22.84,
    "CIDADE": "Saltinho",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.08,
    "FEB": 5.56,
    "MAR": 5.34,
    "APR": 5.31,
    "MAY": 4.74,
    "JUN": 4.59,
    "JUL": 4.71,
    "AUG": 5.54,
    "SEP": 5.19,
    "OCT": 5.32,
    "NOV": 5.23,
    "DEC": 5.38
  },
  {
    "LONG": -47.41,
    "LAT": -22.76,
    "CIDADE": "Santa Bárbara d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 5.11,
    "FEB": 5.58,
    "MAR": 5.36,
    "APR": 5.32,
    "MAY": 4.73,
    "JUN": 4.6,
    "JUL": 4.7,
    "AUG": 5.52,
    "SEP": 5.21,
    "OCT": 5.34,
    "NOV": 5.2,
    "DEC": 5.43
  },
  {
    "LONG": -47.27,
    "LAT": -22.82,
    "CIDADE": "Sumaré",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.53,
    "MAR": 5.38,
    "APR": 5.34,
    "MAY": 4.73,
    "JUN": 4.63,
    "JUL": 4.73,
    "AUG": 5.57,
    "SEP": 5.23,
    "OCT": 5.35,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -47.29,
    "LAT": -22.78,
    "CIDADE": "Nova Odessa",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.53,
    "MAR": 5.38,
    "APR": 5.34,
    "MAY": 4.73,
    "JUN": 4.63,
    "JUL": 4.73,
    "AUG": 5.57,
    "SEP": 5.23,
    "OCT": 5.35,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -47.15,
    "LAT": -22.75,
    "CIDADE": "Paulínia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.07,
    "FEB": 5.56,
    "MAR": 5.37,
    "APR": 5.37,
    "MAY": 4.74,
    "JUN": 4.65,
    "JUL": 4.76,
    "AUG": 5.55,
    "SEP": 5.24,
    "OCT": 5.36,
    "NOV": 5.23,
    "DEC": 5.44
  },
  {
    "LONG": -46.69,
    "LAT": -22.82,
    "CIDADE": "Tuiuti",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.88,
    "FEB": 5.5,
    "MAR": 5.22,
    "APR": 5.26,
    "MAY": 4.77,
    "JUN": 4.62,
    "JUL": 4.76,
    "AUG": 5.61,
    "SEP": 5.27,
    "OCT": 5.31,
    "NOV": 5.13,
    "DEC": 5.32
  },
  {
    "LONG": -46.59,
    "LAT": -22.78,
    "CIDADE": "Pinhalzinho",
    "UF": "SÃO PAULO",
    "anual": 5.11,
    "JAN": 4.86,
    "FEB": 5.43,
    "MAR": 5.19,
    "APR": 5.27,
    "MAY": 4.75,
    "JUN": 4.6,
    "JUL": 4.75,
    "AUG": 5.58,
    "SEP": 5.24,
    "OCT": 5.3,
    "NOV": 5.13,
    "DEC": 5.29
  },
  {
    "LONG": -46.45,
    "LAT": -22.79,
    "CIDADE": "Pedra Bela",
    "UF": "SÃO PAULO",
    "anual": 5.08,
    "JAN": 4.8,
    "FEB": 5.36,
    "MAR": 5.1,
    "APR": 5.23,
    "MAY": 4.72,
    "JUN": 4.6,
    "JUL": 4.74,
    "AUG": 5.59,
    "SEP": 5.23,
    "OCT": 5.27,
    "NOV": 5.06,
    "DEC": 5.19
  },
  {
    "LONG": -45.66,
    "LAT": -22.83,
    "CIDADE": "Santo Antônio do Pinhal",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.74,
    "FEB": 5.33,
    "MAR": 4.95,
    "APR": 5.07,
    "MAY": 4.61,
    "JUN": 4.54,
    "JUL": 4.66,
    "AUG": 5.52,
    "SEP": 5.06,
    "OCT": 5.08,
    "NOV": 4.78,
    "DEC": 5.09
  },
  {
    "LONG": -45.23,
    "LAT": "#VALOR!",
    "CIDADE": "Aparecida",
    "UF": "SÃO PAULO",
    "anual": 4.87,
    "JAN": 4.86,
    "FEB": 5.39,
    "MAR": 5,
    "APR": 5.02,
    "MAY": 4.46,
    "JUN": 4.37,
    "JUL": 4.45,
    "AUG": 5.34,
    "SEP": 4.94,
    "OCT": 4.93,
    "NOV": 4.68,
    "DEC": 5.05
  },
  {
    "LONG": -45.26,
    "LAT": -22.83,
    "CIDADE": "Potim",
    "UF": "SÃO PAULO",
    "anual": 4.87,
    "JAN": 4.86,
    "FEB": 5.39,
    "MAR": 5,
    "APR": 5.02,
    "MAY": 4.46,
    "JUN": 4.37,
    "JUL": 4.45,
    "AUG": 5.34,
    "SEP": 4.94,
    "OCT": 4.93,
    "NOV": 4.68,
    "DEC": 5.05
  },
  {
    "LONG": -45.19,
    "LAT": -2.28,
    "CIDADE": "Guaratinguetá",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 5.03,
    "FEB": 5.55,
    "MAR": 5.11,
    "APR": 5.14,
    "MAY": 4.55,
    "JUN": 4.41,
    "JUL": 4.5,
    "AUG": 5.38,
    "SEP": 5.03,
    "OCT": 5.04,
    "NOV": 4.83,
    "DEC": 5.22
  },
  {
    "LONG": -51.08,
    "LAT": -22.66,
    "CIDADE": "Iepê",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.29,
    "FEB": 5.58,
    "MAR": 5.59,
    "APR": 5.44,
    "MAY": 4.69,
    "JUN": 4.44,
    "JUL": 4.66,
    "AUG": 5.54,
    "SEP": 5.09,
    "OCT": 5.32,
    "NOV": 5.51,
    "DEC": 5.61
  },
  {
    "LONG": -50.79,
    "LAT": -22.74,
    "CIDADE": "Cruzália",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.31,
    "FEB": 5.52,
    "MAR": 5.43,
    "APR": 5.38,
    "MAY": 4.72,
    "JUN": 4.48,
    "JUL": 4.69,
    "AUG": 5.52,
    "SEP": 5.12,
    "OCT": 5.26,
    "NOV": 5.42,
    "DEC": 5.56
  },
  {
    "LONG": -5.06,
    "LAT": -22.74,
    "CIDADE": "Tarumã",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.17,
    "FEB": 5.54,
    "MAR": 5.48,
    "APR": 5.42,
    "MAY": 4.72,
    "JUN": 4.5,
    "JUL": 4.72,
    "AUG": 5.59,
    "SEP": 5.15,
    "OCT": 5.3,
    "NOV": 5.44,
    "DEC": 5.5
  },
  {
    "LONG": -50.42,
    "LAT": -22.66,
    "CIDADE": "Assis",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.14,
    "FEB": 5.54,
    "MAR": 5.45,
    "APR": 5.37,
    "MAY": 4.69,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.57,
    "SEP": 5.12,
    "OCT": 5.31,
    "NOV": 5.45,
    "DEC": 5.52
  },
  {
    "LONG": -50.39,
    "LAT": -22.75,
    "CIDADE": "Cândido Mota",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 5.12,
    "FEB": 5.54,
    "MAR": 5.42,
    "APR": 5.35,
    "MAY": 4.67,
    "JUN": 4.51,
    "JUL": 4.71,
    "AUG": 5.53,
    "SEP": 5.12,
    "OCT": 5.28,
    "NOV": 5.42,
    "DEC": 5.5
  },
  {
    "LONG": -49.74,
    "LAT": -22.75,
    "CIDADE": "São Pedro do Turvo",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 5.08,
    "FEB": 5.52,
    "MAR": 5.39,
    "APR": 5.41,
    "MAY": 4.66,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.6,
    "SEP": 5.12,
    "OCT": 5.32,
    "NOV": 5.38,
    "DEC": 5.47
  },
  {
    "LONG": -49.43,
    "LAT": -2.27,
    "CIDADE": "Espírito Santo do Turvo",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 5.04,
    "FEB": 5.51,
    "MAR": 5.41,
    "APR": 5.38,
    "MAY": 4.67,
    "JUN": 4.52,
    "JUL": 4.73,
    "AUG": 5.61,
    "SEP": 5.11,
    "OCT": 5.31,
    "NOV": 5.37,
    "DEC": 5.46
  },
  {
    "LONG": -48.66,
    "LAT": -2.27,
    "CIDADE": "Areiópolis",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.1,
    "FEB": 5.58,
    "MAR": 5.45,
    "APR": 5.36,
    "MAY": 4.74,
    "JUN": 4.61,
    "JUL": 4.75,
    "AUG": 5.63,
    "SEP": 5.2,
    "OCT": 5.37,
    "NOV": 5.37,
    "DEC": 5.47
  },
  {
    "LONG": -48.57,
    "LAT": -22.73,
    "CIDADE": "São Manuel",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.06,
    "FEB": 5.66,
    "MAR": 5.48,
    "APR": 5.4,
    "MAY": 4.76,
    "JUN": 4.61,
    "JUL": 4.74,
    "AUG": 5.62,
    "SEP": 5.21,
    "OCT": 5.38,
    "NOV": 5.4,
    "DEC": 5.5
  },
  {
    "LONG": -4.76,
    "LAT": -22.73,
    "CIDADE": "Piracicaba",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.06,
    "FEB": 5.53,
    "MAR": 5.32,
    "APR": 5.3,
    "MAY": 4.72,
    "JUN": 4.57,
    "JUL": 4.71,
    "AUG": 5.56,
    "SEP": 5.2,
    "OCT": 5.3,
    "NOV": 5.19,
    "DEC": 5.35
  },
  {
    "LONG": -47.33,
    "LAT": -22.74,
    "CIDADE": "Americana",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 5.07,
    "FEB": 5.51,
    "MAR": 5.33,
    "APR": 5.3,
    "MAY": 4.73,
    "JUN": 4.62,
    "JUL": 4.74,
    "AUG": 5.58,
    "SEP": 5.24,
    "OCT": 5.32,
    "NOV": 5.18,
    "DEC": 5.38
  },
  {
    "LONG": -46.99,
    "LAT": -22.7,
    "CIDADE": "Jaguariúna",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.03,
    "FEB": 5.56,
    "MAR": 5.39,
    "APR": 5.38,
    "MAY": 4.83,
    "JUN": 4.65,
    "JUL": 4.83,
    "AUG": 5.6,
    "SEP": 5.26,
    "OCT": 5.4,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -46.9,
    "LAT": -22.74,
    "CIDADE": "Pedreira",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.01,
    "FEB": 5.56,
    "MAR": 5.35,
    "APR": 5.4,
    "MAY": 4.85,
    "JUN": 4.64,
    "JUL": 4.83,
    "AUG": 5.58,
    "SEP": 5.28,
    "OCT": 5.4,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -46.77,
    "LAT": -22.71,
    "CIDADE": "Amparo",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 4.96,
    "FEB": 5.51,
    "MAR": 5.29,
    "APR": 5.36,
    "MAY": 4.83,
    "JUN": 4.63,
    "JUL": 4.83,
    "AUG": 5.58,
    "SEP": 5.29,
    "OCT": 5.38,
    "NOV": 5.19,
    "DEC": 5.36
  },
  {
    "LONG": -46.68,
    "LAT": -22.68,
    "CIDADE": "Monte Alegre do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.85,
    "FEB": 5.47,
    "MAR": 5.22,
    "APR": 5.28,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.8,
    "AUG": 5.62,
    "SEP": 5.27,
    "OCT": 5.33,
    "NOV": 5.12,
    "DEC": 5.26
  },
  {
    "LONG": -45.73,
    "LAT": -22.68,
    "CIDADE": "São Bento do Sapucaí",
    "UF": "SÃO PAULO",
    "anual": 4.98,
    "JAN": 4.74,
    "FEB": 5.36,
    "MAR": 5,
    "APR": 5.09,
    "MAY": 4.63,
    "JUN": 4.52,
    "JUL": 4.7,
    "AUG": 5.55,
    "SEP": 5.15,
    "OCT": 5.14,
    "NOV": 4.85,
    "DEC": 5.07
  },
  {
    "LONG": -45.58,
    "LAT": -22.73,
    "CIDADE": "Campos do Jordão",
    "UF": "SÃO PAULO",
    "anual": 4.82,
    "JAN": 4.55,
    "FEB": 5.05,
    "MAR": 4.7,
    "APR": 4.86,
    "MAY": 4.52,
    "JUN": 4.5,
    "JUL": 4.63,
    "AUG": 5.53,
    "SEP": 5.11,
    "OCT": 4.98,
    "NOV": 4.56,
    "DEC": 4.82
  },
  {
    "LONG": -45.12,
    "LAT": -22.73,
    "CIDADE": "Lorena",
    "UF": "SÃO PAULO",
    "anual": 4.85,
    "JAN": 4.83,
    "FEB": 5.37,
    "MAR": 4.95,
    "APR": 4.96,
    "MAY": 4.47,
    "JUN": 4.4,
    "JUL": 4.48,
    "AUG": 5.35,
    "SEP": 4.91,
    "OCT": 4.88,
    "NOV": 4.6,
    "DEC": 4.98
  },
  {
    "LONG": -45.02,
    "LAT": -2.27,
    "CIDADE": "Cachoeira Paulista",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.96,
    "FEB": 5.49,
    "MAR": 5.05,
    "APR": 5.07,
    "MAY": 4.53,
    "JUN": 4.47,
    "JUL": 4.52,
    "AUG": 5.41,
    "SEP": 5,
    "OCT": 5.01,
    "NOV": 4.74,
    "DEC": 5.11
  },
  {
    "LONG": -45.05,
    "LAT": -22.7,
    "CIDADE": "Canas",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 4.96,
    "FEB": 5.49,
    "MAR": 5.05,
    "APR": 5.07,
    "MAY": 4.53,
    "JUN": 4.47,
    "JUL": 4.52,
    "AUG": 5.41,
    "SEP": 5,
    "OCT": 5.01,
    "NOV": 4.74,
    "DEC": 5.11
  },
  {
    "LONG": -44.85,
    "LAT": -22.66,
    "CIDADE": "Silveiras",
    "UF": "SÃO PAULO",
    "anual": 5.09,
    "JAN": 5.18,
    "FEB": 5.67,
    "MAR": 5.22,
    "APR": 5.18,
    "MAY": 4.65,
    "JUN": 4.59,
    "JUL": 4.62,
    "AUG": 5.44,
    "SEP": 5.11,
    "OCT": 5.15,
    "NOV": 4.94,
    "DEC": 5.33
  },
  {
    "LONG": -44.44,
    "LAT": -22.67,
    "CIDADE": "Arapeí",
    "UF": "SÃO PAULO",
    "anual": 4.82,
    "JAN": 4.97,
    "FEB": 5.59,
    "MAR": 4.91,
    "APR": 4.82,
    "MAY": 4.41,
    "JUN": 4.34,
    "JUL": 4.37,
    "AUG": 5.11,
    "SEP": 4.84,
    "OCT": 4.8,
    "NOV": 4.54,
    "DEC": 5.09
  },
  {
    "LONG": -44.33,
    "LAT": -22.68,
    "CIDADE": "Bananal",
    "UF": "SÃO PAULO",
    "anual": 4.85,
    "JAN": 5.05,
    "FEB": 5.69,
    "MAR": 5.01,
    "APR": 4.87,
    "MAY": 4.41,
    "JUN": 4.37,
    "JUL": 4.34,
    "AUG": 5.09,
    "SEP": 4.84,
    "OCT": 4.81,
    "NOV": 4.58,
    "DEC": 5.09
  },
  {
    "LONG": -53.06,
    "LAT": "#VALOR!",
    "CIDADE": "Rosana",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.41,
    "FEB": 5.78,
    "MAR": 5.78,
    "APR": 5.4,
    "MAY": 4.62,
    "JUN": 4.37,
    "JUL": 4.52,
    "AUG": 5.32,
    "SEP": 5.08,
    "OCT": 5.32,
    "NOV": 5.58,
    "DEC": 5.73
  },
  {
    "LONG": -52.59,
    "LAT": -2.26,
    "CIDADE": "Euclides da Cunha Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.44,
    "FEB": 5.72,
    "MAR": 5.69,
    "APR": 5.39,
    "MAY": 4.62,
    "JUN": 4.37,
    "JUL": 4.55,
    "AUG": 5.36,
    "SEP": 5.09,
    "OCT": 5.3,
    "NOV": 5.59,
    "DEC": 5.67
  },
  {
    "LONG": -51.24,
    "LAT": -22.62,
    "CIDADE": "Nantes",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.25,
    "FEB": 5.56,
    "MAR": 5.58,
    "APR": 5.43,
    "MAY": 4.71,
    "JUN": 4.43,
    "JUL": 4.7,
    "AUG": 5.53,
    "SEP": 5.1,
    "OCT": 5.33,
    "NOV": 5.51,
    "DEC": 5.57
  },
  {
    "LONG": -50.67,
    "LAT": -22.62,
    "CIDADE": "Maracaí",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.2,
    "FEB": 5.5,
    "MAR": 5.5,
    "APR": 5.44,
    "MAY": 4.72,
    "JUN": 4.55,
    "JUL": 4.71,
    "AUG": 5.59,
    "SEP": 5.18,
    "OCT": 5.31,
    "NOV": 5.45,
    "DEC": 5.48
  },
  {
    "LONG": -50.2,
    "LAT": -22.64,
    "CIDADE": "Platina",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.16,
    "FEB": 5.52,
    "MAR": 5.47,
    "APR": 5.41,
    "MAY": 4.71,
    "JUN": 4.55,
    "JUL": 4.71,
    "AUG": 5.57,
    "SEP": 5.13,
    "OCT": 5.33,
    "NOV": 5.41,
    "DEC": 5.53
  },
  {
    "LONG": -50,
    "LAT": -22.6,
    "CIDADE": "Campos Novos Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.11,
    "FEB": 5.56,
    "MAR": 5.43,
    "APR": 5.44,
    "MAY": 4.69,
    "JUN": 4.54,
    "JUL": 4.71,
    "AUG": 5.56,
    "SEP": 5.14,
    "OCT": 5.33,
    "NOV": 5.38,
    "DEC": 5.53
  },
  {
    "LONG": -49.4,
    "LAT": -22.58,
    "CIDADE": "Paulistânia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.02,
    "FEB": 5.52,
    "MAR": 5.42,
    "APR": 5.39,
    "MAY": 4.71,
    "JUN": 4.54,
    "JUL": 4.74,
    "AUG": 5.64,
    "SEP": 5.14,
    "OCT": 5.33,
    "NOV": 5.34,
    "DEC": 5.43
  },
  {
    "LONG": -48.97,
    "LAT": -2.26,
    "CIDADE": "Borebi",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 4.98,
    "FEB": 5.5,
    "MAR": 5.39,
    "APR": 5.39,
    "MAY": 4.75,
    "JUN": 4.61,
    "JUL": 4.72,
    "AUG": 5.58,
    "SEP": 5.16,
    "OCT": 5.32,
    "NOV": 5.37,
    "DEC": 5.42
  },
  {
    "LONG": -48.8,
    "LAT": -22.6,
    "CIDADE": "Lençóis Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.01,
    "FEB": 5.54,
    "MAR": 5.39,
    "APR": 5.37,
    "MAY": 4.74,
    "JUN": 4.62,
    "JUL": 4.72,
    "AUG": 5.6,
    "SEP": 5.17,
    "OCT": 5.35,
    "NOV": 5.35,
    "DEC": 5.44
  },
  {
    "LONG": -48.16,
    "LAT": -22.57,
    "CIDADE": "Santa Maria da Serra",
    "UF": "SÃO PAULO",
    "anual": 5.11,
    "JAN": 4.99,
    "FEB": 5.51,
    "MAR": 5.34,
    "APR": 5.3,
    "MAY": 4.64,
    "JUN": 4.47,
    "JUL": 4.57,
    "AUG": 5.48,
    "SEP": 5.12,
    "OCT": 5.27,
    "NOV": 5.29,
    "DEC": 5.39
  },
  {
    "LONG": -47.87,
    "LAT": -22.6,
    "CIDADE": "Águas de São Pedro",
    "UF": "SÃO PAULO",
    "anual": 5.07,
    "JAN": 4.86,
    "FEB": 5.38,
    "MAR": 5.2,
    "APR": 5.31,
    "MAY": 4.64,
    "JUN": 4.53,
    "JUL": 4.62,
    "AUG": 5.59,
    "SEP": 5.14,
    "OCT": 5.23,
    "NOV": 5.09,
    "DEC": 5.27
  },
  {
    "LONG": -47.52,
    "LAT": -22.58,
    "CIDADE": "Iracemápolis",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.07,
    "FEB": 5.57,
    "MAR": 5.34,
    "APR": 5.31,
    "MAY": 4.73,
    "JUN": 4.56,
    "JUL": 4.74,
    "AUG": 5.62,
    "SEP": 5.2,
    "OCT": 5.33,
    "NOV": 5.11,
    "DEC": 5.29
  },
  {
    "LONG": -47.4,
    "LAT": -22.57,
    "CIDADE": "Limeira",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 5.02,
    "FEB": 5.49,
    "MAR": 5.32,
    "APR": 5.28,
    "MAY": 4.75,
    "JUN": 4.64,
    "JUL": 4.74,
    "AUG": 5.6,
    "SEP": 5.22,
    "OCT": 5.33,
    "NOV": 5.16,
    "DEC": 5.32
  },
  {
    "LONG": -47.17,
    "LAT": -22.57,
    "CIDADE": "Artur Nogueira",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.03,
    "FEB": 5.57,
    "MAR": 5.36,
    "APR": 5.35,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.78,
    "AUG": 5.63,
    "SEP": 5.27,
    "OCT": 5.38,
    "NOV": 5.21,
    "DEC": 5.42
  },
  {
    "LONG": -4.72,
    "LAT": -22.64,
    "CIDADE": "Cosmópolis",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.03,
    "FEB": 5.57,
    "MAR": 5.36,
    "APR": 5.35,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.78,
    "AUG": 5.63,
    "SEP": 5.27,
    "OCT": 5.38,
    "NOV": 5.21,
    "DEC": 5.42
  },
  {
    "LONG": -47.05,
    "LAT": -2.26,
    "CIDADE": "Holambra",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.02,
    "FEB": 5.57,
    "MAR": 5.35,
    "APR": 5.37,
    "MAY": 4.82,
    "JUN": 4.64,
    "JUL": 4.81,
    "AUG": 5.63,
    "SEP": 5.26,
    "OCT": 5.4,
    "NOV": 5.23,
    "DEC": 5.43
  },
  {
    "LONG": -46.92,
    "LAT": -22.6,
    "CIDADE": "Santo Antônio de Posse",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.01,
    "FEB": 5.57,
    "MAR": 5.38,
    "APR": 5.37,
    "MAY": 4.82,
    "JUN": 4.66,
    "JUL": 4.83,
    "AUG": 5.63,
    "SEP": 5.29,
    "OCT": 5.41,
    "NOV": 5.25,
    "DEC": 5.42
  },
  {
    "LONG": -46.7,
    "LAT": -22.61,
    "CIDADE": "Serra Negra",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 4.99,
    "FEB": 5.55,
    "MAR": 5.36,
    "APR": 5.38,
    "MAY": 4.86,
    "JUN": 4.66,
    "JUL": 4.87,
    "AUG": 5.62,
    "SEP": 5.31,
    "OCT": 5.41,
    "NOV": 5.24,
    "DEC": 5.36
  },
  {
    "LONG": -46.53,
    "LAT": -22.59,
    "CIDADE": "Socorro",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 4.96,
    "FEB": 5.49,
    "MAR": 5.3,
    "APR": 5.33,
    "MAY": 4.83,
    "JUN": 4.64,
    "JUL": 4.84,
    "AUG": 5.64,
    "SEP": 5.32,
    "OCT": 5.4,
    "NOV": 5.18,
    "DEC": 5.25
  },
  {
    "LONG": -45.19,
    "LAT": -22.61,
    "CIDADE": "Piquete",
    "UF": "SÃO PAULO",
    "anual": 4.71,
    "JAN": 4.57,
    "FEB": 5.12,
    "MAR": 4.75,
    "APR": 4.75,
    "MAY": 4.35,
    "JUN": 4.42,
    "JUL": 4.48,
    "AUG": 5.34,
    "SEP": 4.84,
    "OCT": 4.74,
    "NOV": 4.36,
    "DEC": 4.75
  },
  {
    "LONG": -44.9,
    "LAT": -22.57,
    "CIDADE": "Lavrinhas",
    "UF": "SÃO PAULO",
    "anual": 4.93,
    "JAN": 4.91,
    "FEB": 5.41,
    "MAR": 4.98,
    "APR": 5.06,
    "MAY": 4.55,
    "JUN": 4.51,
    "JUL": 4.57,
    "AUG": 5.4,
    "SEP": 5.01,
    "OCT": 4.98,
    "NOV": 4.69,
    "DEC": 5.1
  },
  {
    "LONG": -44.97,
    "LAT": -22.57,
    "CIDADE": "Cruzeiro",
    "UF": "SÃO PAULO",
    "anual": 4.93,
    "JAN": 4.91,
    "FEB": 5.41,
    "MAR": 4.98,
    "APR": 5.06,
    "MAY": 4.55,
    "JUN": 4.51,
    "JUL": 4.57,
    "AUG": 5.4,
    "SEP": 5.01,
    "OCT": 4.98,
    "NOV": 4.69,
    "DEC": 5.1
  },
  {
    "LONG": -44.7,
    "LAT": -22.58,
    "CIDADE": "Areias",
    "UF": "SÃO PAULO",
    "anual": 4.95,
    "JAN": 5.03,
    "FEB": 5.48,
    "MAR": 5.07,
    "APR": 5.05,
    "MAY": 4.6,
    "JUN": 4.48,
    "JUL": 4.53,
    "AUG": 5.3,
    "SEP": 4.96,
    "OCT": 4.99,
    "NOV": 4.74,
    "DEC": 5.16
  },
  {
    "LONG": -44.58,
    "LAT": -22.64,
    "CIDADE": "São José do Barreiro",
    "UF": "SÃO PAULO",
    "anual": 4.9,
    "JAN": 5.06,
    "FEB": 5.53,
    "MAR": 5.12,
    "APR": 5.01,
    "MAY": 4.52,
    "JUN": 4.37,
    "JUL": 4.39,
    "AUG": 5.23,
    "SEP": 4.9,
    "OCT": 4.9,
    "NOV": 4.67,
    "DEC": 5.14
  },
  {
    "LONG": -52.17,
    "LAT": -22.53,
    "CIDADE": "Teodoro Sampaio",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.39,
    "FEB": 5.67,
    "MAR": 5.62,
    "APR": 5.42,
    "MAY": 4.68,
    "JUN": 4.48,
    "JUL": 4.66,
    "AUG": 5.47,
    "SEP": 5.14,
    "OCT": 5.33,
    "NOV": 5.55,
    "DEC": 5.59
  },
  {
    "LONG": -5.18,
    "LAT": -2.25,
    "CIDADE": "Sandovalina",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.31,
    "FEB": 5.66,
    "MAR": 5.67,
    "APR": 5.47,
    "MAY": 4.74,
    "JUN": 4.54,
    "JUL": 4.71,
    "AUG": 5.55,
    "SEP": 5.15,
    "OCT": 5.38,
    "NOV": 5.53,
    "DEC": 5.67
  },
  {
    "LONG": "#VALOR!",
    "LAT": -22.49,
    "CIDADE": "Estrela do Norte",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.29,
    "FEB": 5.64,
    "MAR": 5.65,
    "APR": 5.47,
    "MAY": 4.72,
    "JUN": 4.55,
    "JUL": 4.72,
    "AUG": 5.58,
    "SEP": 5.19,
    "OCT": 5.38,
    "NOV": 5.53,
    "DEC": 5.63
  },
  {
    "LONG": -49.66,
    "LAT": -22.53,
    "CIDADE": "Ubirajara",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.09,
    "FEB": 5.51,
    "MAR": 5.38,
    "APR": 5.42,
    "MAY": 4.71,
    "JUN": 4.54,
    "JUL": 4.76,
    "AUG": 5.64,
    "SEP": 5.18,
    "OCT": 5.34,
    "NOV": 5.35,
    "DEC": 5.44
  },
  {
    "LONG": -49.34,
    "LAT": -2.25,
    "CIDADE": "Cabrália Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.09,
    "FEB": 5.56,
    "MAR": 5.4,
    "APR": 5.39,
    "MAY": 4.71,
    "JUN": 4.56,
    "JUL": 4.76,
    "AUG": 5.59,
    "SEP": 5.14,
    "OCT": 5.37,
    "NOV": 5.33,
    "DEC": 5.41
  },
  {
    "LONG": -48.99,
    "LAT": -22.47,
    "CIDADE": "Agudos",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 4.97,
    "FEB": 5.54,
    "MAR": 5.41,
    "APR": 5.43,
    "MAY": 4.74,
    "JUN": 4.53,
    "JUL": 4.73,
    "AUG": 5.58,
    "SEP": 5.14,
    "OCT": 5.34,
    "NOV": 5.31,
    "DEC": 5.4
  },
  {
    "LONG": -48.71,
    "LAT": -22.5,
    "CIDADE": "Macatuba",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.11,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 5.38,
    "MAY": 4.74,
    "JUN": 4.63,
    "JUL": 4.77,
    "AUG": 5.63,
    "SEP": 5.24,
    "OCT": 5.43,
    "NOV": 5.42,
    "DEC": 5.52
  },
  {
    "LONG": -48.56,
    "LAT": -22.51,
    "CIDADE": "Igaraçu do Tietê",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.11,
    "FEB": 5.7,
    "MAR": 5.52,
    "APR": 5.41,
    "MAY": 4.73,
    "JUN": 4.57,
    "JUL": 4.73,
    "AUG": 5.57,
    "SEP": 5.22,
    "OCT": 5.43,
    "NOV": 5.46,
    "DEC": 5.55
  },
  {
    "LONG": -48.56,
    "LAT": -22.49,
    "CIDADE": "Barra Bonita",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.11,
    "FEB": 5.7,
    "MAR": 5.52,
    "APR": 5.41,
    "MAY": 4.73,
    "JUN": 4.57,
    "JUL": 4.73,
    "AUG": 5.57,
    "SEP": 5.22,
    "OCT": 5.43,
    "NOV": 5.46,
    "DEC": 5.55
  },
  {
    "LONG": "#VALOR!",
    "LAT": -22.55,
    "CIDADE": "São Pedro",
    "UF": "SÃO PAULO",
    "anual": 5.09,
    "JAN": 4.82,
    "FEB": 5.34,
    "MAR": 5.15,
    "APR": 5.29,
    "MAY": 4.72,
    "JUN": 4.57,
    "JUL": 4.74,
    "AUG": 5.64,
    "SEP": 5.2,
    "OCT": 5.24,
    "NOV": 5.14,
    "DEC": 5.19
  },
  {
    "LONG": -47.78,
    "LAT": -22.51,
    "CIDADE": "Charqueada",
    "UF": "SÃO PAULO",
    "anual": 5.09,
    "JAN": 4.9,
    "FEB": 5.42,
    "MAR": 5.19,
    "APR": 5.28,
    "MAY": 4.7,
    "JUN": 4.55,
    "JUL": 4.72,
    "AUG": 5.61,
    "SEP": 5.16,
    "OCT": 5.24,
    "NOV": 5.12,
    "DEC": 5.23
  },
  {
    "LONG": -47.53,
    "LAT": -22.46,
    "CIDADE": "Santa Gertrudes",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.05,
    "FEB": 5.45,
    "MAR": 5.28,
    "APR": 5.32,
    "MAY": 4.73,
    "JUN": 4.59,
    "JUL": 4.76,
    "AUG": 5.63,
    "SEP": 5.2,
    "OCT": 5.33,
    "NOV": 5.13,
    "DEC": 5.32
  },
  {
    "LONG": -47.45,
    "LAT": -22.48,
    "CIDADE": "Cordeirópolis",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.02,
    "FEB": 5.48,
    "MAR": 5.27,
    "APR": 5.3,
    "MAY": 4.74,
    "JUN": 4.64,
    "JUL": 4.76,
    "AUG": 5.65,
    "SEP": 5.2,
    "OCT": 5.31,
    "NOV": 5.14,
    "DEC": 5.29
  },
  {
    "LONG": -47.21,
    "LAT": -2.25,
    "CIDADE": "Engenheiro Coelho",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.06,
    "FEB": 5.61,
    "MAR": 5.37,
    "APR": 5.31,
    "MAY": 4.83,
    "JUN": 4.7,
    "JUL": 4.78,
    "AUG": 5.63,
    "SEP": 5.27,
    "OCT": 5.38,
    "NOV": 5.18,
    "DEC": 5.39
  },
  {
    "LONG": -46.63,
    "LAT": -22.47,
    "CIDADE": "Águas de Lindóia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 4.99,
    "FEB": 5.46,
    "MAR": 5.31,
    "APR": 5.4,
    "MAY": 4.88,
    "JUN": 4.71,
    "JUL": 4.89,
    "AUG": 5.59,
    "SEP": 5.31,
    "OCT": 5.36,
    "NOV": 5.17,
    "DEC": 5.25
  },
  {
    "LONG": -46.65,
    "LAT": -2.25,
    "CIDADE": "Lindóia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 4.99,
    "FEB": 5.46,
    "MAR": 5.31,
    "APR": 5.4,
    "MAY": 4.88,
    "JUN": 4.71,
    "JUL": 4.89,
    "AUG": 5.59,
    "SEP": 5.31,
    "OCT": 5.36,
    "NOV": 5.17,
    "DEC": 5.25
  },
  {
    "LONG": -44.78,
    "LAT": -22.53,
    "CIDADE": "Queluz",
    "UF": "SÃO PAULO",
    "anual": 4.63,
    "JAN": 4.56,
    "FEB": 4.98,
    "MAR": 4.64,
    "APR": 4.72,
    "MAY": 4.4,
    "JUN": 4.38,
    "JUL": 4.39,
    "AUG": 5.17,
    "SEP": 4.78,
    "OCT": 4.6,
    "NOV": 4.29,
    "DEC": 4.69
  },
  {
    "LONG": -51.52,
    "LAT": -22.41,
    "CIDADE": "Narandiba",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.34,
    "FEB": 5.66,
    "MAR": 5.65,
    "APR": 5.47,
    "MAY": 4.75,
    "JUN": 4.51,
    "JUL": 4.74,
    "AUG": 5.57,
    "SEP": 5.19,
    "OCT": 5.4,
    "NOV": 5.55,
    "DEC": 5.69
  },
  {
    "LONG": -5.13,
    "LAT": "#VALOR!",
    "CIDADE": "Taciba",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.29,
    "FEB": 5.62,
    "MAR": 5.6,
    "APR": 5.44,
    "MAY": 4.75,
    "JUN": 4.58,
    "JUL": 4.74,
    "AUG": 5.61,
    "SEP": 5.18,
    "OCT": 5.38,
    "NOV": 5.54,
    "DEC": 5.61
  },
  {
    "LONG": -50.58,
    "LAT": -2.24,
    "CIDADE": "Paraguaçu Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.19,
    "FEB": 5.55,
    "MAR": 5.51,
    "APR": 5.41,
    "MAY": 4.77,
    "JUN": 4.63,
    "JUL": 4.78,
    "AUG": 5.64,
    "SEP": 5.19,
    "OCT": 5.34,
    "NOV": 5.44,
    "DEC": 5.52
  },
  {
    "LONG": -50.2,
    "LAT": -2.24,
    "CIDADE": "Echaporã",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.17,
    "FEB": 5.57,
    "MAR": 5.54,
    "APR": 5.46,
    "MAY": 4.76,
    "JUN": 4.63,
    "JUL": 4.76,
    "AUG": 5.64,
    "SEP": 5.19,
    "OCT": 5.38,
    "NOV": 5.43,
    "DEC": 5.55
  },
  {
    "LONG": -49.92,
    "LAT": -22.44,
    "CIDADE": "Ocauçu",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.1,
    "FEB": 5.58,
    "MAR": 5.51,
    "APR": 5.46,
    "MAY": 4.77,
    "JUN": 4.58,
    "JUL": 4.78,
    "AUG": 5.66,
    "SEP": 5.19,
    "OCT": 5.37,
    "NOV": 5.39,
    "DEC": 5.49
  },
  {
    "LONG": -49.82,
    "LAT": -22.42,
    "CIDADE": "Lupércio",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.09,
    "FEB": 5.55,
    "MAR": 5.44,
    "APR": 5.45,
    "MAY": 4.77,
    "JUN": 4.57,
    "JUL": 4.79,
    "AUG": 5.68,
    "SEP": 5.19,
    "OCT": 5.36,
    "NOV": 5.38,
    "DEC": 5.5
  },
  {
    "LONG": -49.76,
    "LAT": -22.44,
    "CIDADE": "Alvinlândia",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.07,
    "FEB": 5.54,
    "MAR": 5.4,
    "APR": 5.42,
    "MAY": 4.74,
    "JUN": 4.55,
    "JUL": 4.78,
    "AUG": 5.67,
    "SEP": 5.18,
    "OCT": 5.36,
    "NOV": 5.33,
    "DEC": 5.44
  },
  {
    "LONG": -49.52,
    "LAT": -22.36,
    "CIDADE": "Fernão",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.09,
    "FEB": 5.51,
    "MAR": 5.32,
    "APR": 5.37,
    "MAY": 4.73,
    "JUN": 4.59,
    "JUL": 4.78,
    "AUG": 5.66,
    "SEP": 5.19,
    "OCT": 5.39,
    "NOV": 5.34,
    "DEC": 5.44
  },
  {
    "LONG": -49.52,
    "LAT": -22.43,
    "CIDADE": "Lucianópolis",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.09,
    "FEB": 5.51,
    "MAR": 5.32,
    "APR": 5.37,
    "MAY": 4.73,
    "JUN": 4.59,
    "JUL": 4.78,
    "AUG": 5.66,
    "SEP": 5.19,
    "OCT": 5.39,
    "NOV": 5.34,
    "DEC": 5.44
  },
  {
    "LONG": -49.41,
    "LAT": -2.24,
    "CIDADE": "Duartina",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 5.09,
    "FEB": 5.56,
    "MAR": 5.37,
    "APR": 5.37,
    "MAY": 4.74,
    "JUN": 4.6,
    "JUL": 4.78,
    "AUG": 5.61,
    "SEP": 5.17,
    "OCT": 5.4,
    "NOV": 5.34,
    "DEC": 5.44
  },
  {
    "LONG": -49.13,
    "LAT": -22.41,
    "CIDADE": "Piratininga",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.06,
    "FEB": 5.56,
    "MAR": 5.45,
    "APR": 5.42,
    "MAY": 4.77,
    "JUN": 4.59,
    "JUL": 4.78,
    "AUG": 5.61,
    "SEP": 5.17,
    "OCT": 5.41,
    "NOV": 5.39,
    "DEC": 5.48
  },
  {
    "LONG": -48.78,
    "LAT": -22.35,
    "CIDADE": "Pederneiras",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.13,
    "FEB": 5.67,
    "MAR": 5.49,
    "APR": 5.37,
    "MAY": 4.73,
    "JUN": 4.63,
    "JUL": 4.77,
    "AUG": 5.56,
    "SEP": 5.21,
    "OCT": 5.45,
    "NOV": 5.41,
    "DEC": 5.55
  },
  {
    "LONG": -48.45,
    "LAT": -22.41,
    "CIDADE": "Mineiros do Tietê",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.03,
    "FEB": 5.59,
    "MAR": 5.41,
    "APR": 5.38,
    "MAY": 4.79,
    "JUN": 4.65,
    "JUL": 4.8,
    "AUG": 5.61,
    "SEP": 5.26,
    "OCT": 5.43,
    "NOV": 5.36,
    "DEC": 5.44
  },
  {
    "LONG": -48.38,
    "LAT": -22.37,
    "CIDADE": "Dois Córregos",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 5.01,
    "FEB": 5.55,
    "MAR": 5.33,
    "APR": 5.37,
    "MAY": 4.76,
    "JUN": 4.63,
    "JUL": 4.71,
    "AUG": 5.61,
    "SEP": 5.25,
    "OCT": 5.39,
    "NOV": 5.34,
    "DEC": 5.38
  },
  {
    "LONG": -4.82,
    "LAT": -2.24,
    "CIDADE": "Torrinha",
    "UF": "SÃO PAULO",
    "anual": 5.17,
    "JAN": 4.96,
    "FEB": 5.49,
    "MAR": 5.29,
    "APR": 5.3,
    "MAY": 4.78,
    "JUN": 4.65,
    "JUL": 4.79,
    "AUG": 5.64,
    "SEP": 5.26,
    "OCT": 5.33,
    "NOV": 5.26,
    "DEC": 5.31
  },
  {
    "LONG": -47.72,
    "LAT": -2.24,
    "CIDADE": "Ipeúna",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.93,
    "FEB": 5.43,
    "MAR": 5.24,
    "APR": 5.32,
    "MAY": 4.75,
    "JUN": 4.64,
    "JUL": 4.77,
    "AUG": 5.65,
    "SEP": 5.19,
    "OCT": 5.29,
    "NOV": 5.18,
    "DEC": 5.24
  },
  {
    "LONG": -4.76,
    "LAT": -22.4,
    "CIDADE": "Rio Claro",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 5.01,
    "FEB": 5.46,
    "MAR": 5.29,
    "APR": 5.33,
    "MAY": 4.75,
    "JUN": 4.64,
    "JUL": 4.76,
    "AUG": 5.6,
    "SEP": 5.21,
    "OCT": 5.34,
    "NOV": 5.14,
    "DEC": 5.28
  },
  {
    "LONG": -47.38,
    "LAT": -22.36,
    "CIDADE": "Araras",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.02,
    "FEB": 5.56,
    "MAR": 5.33,
    "APR": 5.33,
    "MAY": 4.79,
    "JUN": 4.69,
    "JUL": 4.81,
    "AUG": 5.66,
    "SEP": 5.27,
    "OCT": 5.37,
    "NOV": 5.19,
    "DEC": 5.33
  },
  {
    "LONG": -46.94,
    "LAT": -22.37,
    "CIDADE": "Mogi Guaçu",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.03,
    "FEB": 5.59,
    "MAR": 5.35,
    "APR": 5.38,
    "MAY": 4.84,
    "JUN": 4.7,
    "JUL": 4.86,
    "AUG": 5.59,
    "SEP": 5.3,
    "OCT": 5.41,
    "NOV": 5.24,
    "DEC": 5.38
  },
  {
    "LONG": -46.95,
    "LAT": -22.43,
    "CIDADE": "Mogi Mirim",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.03,
    "FEB": 5.59,
    "MAR": 5.35,
    "APR": 5.38,
    "MAY": 4.84,
    "JUN": 4.7,
    "JUL": 4.86,
    "AUG": 5.59,
    "SEP": 5.3,
    "OCT": 5.41,
    "NOV": 5.24,
    "DEC": 5.38
  },
  {
    "LONG": -46.82,
    "LAT": -22.44,
    "CIDADE": "Itapira",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.01,
    "FEB": 5.59,
    "MAR": 5.34,
    "APR": 5.41,
    "MAY": 4.85,
    "JUN": 4.7,
    "JUL": 4.87,
    "AUG": 5.6,
    "SEP": 5.32,
    "OCT": 5.44,
    "NOV": 5.24,
    "DEC": 5.35
  },
  {
    "LONG": -51.91,
    "LAT": -22.29,
    "CIDADE": "Mirante do Paranapanema",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.41,
    "FEB": 5.7,
    "MAR": 5.65,
    "APR": 5.47,
    "MAY": 4.76,
    "JUN": 4.56,
    "JUL": 4.73,
    "AUG": 5.58,
    "SEP": 5.17,
    "OCT": 5.43,
    "NOV": 5.59,
    "DEC": 5.68
  },
  {
    "LONG": -51.56,
    "LAT": -22.3,
    "CIDADE": "Tarabai",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.36,
    "FEB": 5.72,
    "MAR": 5.7,
    "APR": 5.49,
    "MAY": 4.75,
    "JUN": 4.57,
    "JUL": 4.77,
    "AUG": 5.62,
    "SEP": 5.21,
    "OCT": 5.43,
    "NOV": 5.58,
    "DEC": 5.71
  },
  {
    "LONG": -51.5,
    "LAT": -22.27,
    "CIDADE": "Pirapozinho",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.36,
    "FEB": 5.73,
    "MAR": 5.68,
    "APR": 5.47,
    "MAY": 4.78,
    "JUN": 4.59,
    "JUL": 4.78,
    "AUG": 5.64,
    "SEP": 5.23,
    "OCT": 5.42,
    "NOV": 5.6,
    "DEC": 5.7
  },
  {
    "LONG": -51.39,
    "LAT": -2.23,
    "CIDADE": "Anhumas",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.35,
    "FEB": 5.7,
    "MAR": 5.64,
    "APR": 5.47,
    "MAY": 4.79,
    "JUN": 4.61,
    "JUL": 4.79,
    "AUG": 5.64,
    "SEP": 5.22,
    "OCT": 5.4,
    "NOV": 5.6,
    "DEC": 5.68
  },
  {
    "LONG": -50.54,
    "LAT": -22.27,
    "CIDADE": "Borá",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.24,
    "FEB": 5.57,
    "MAR": 5.52,
    "APR": 5.44,
    "MAY": 4.8,
    "JUN": 4.61,
    "JUL": 4.81,
    "AUG": 5.67,
    "SEP": 5.18,
    "OCT": 5.34,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -50.39,
    "LAT": -22.34,
    "CIDADE": "Lutécia",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.23,
    "FEB": 5.6,
    "MAR": 5.52,
    "APR": 5.46,
    "MAY": 4.78,
    "JUN": 4.62,
    "JUL": 4.81,
    "AUG": 5.65,
    "SEP": 5.16,
    "OCT": 5.36,
    "NOV": 5.47,
    "DEC": 5.54
  },
  {
    "LONG": -50.28,
    "LAT": -22.32,
    "CIDADE": "Oscar Bressane",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.21,
    "FEB": 5.6,
    "MAR": 5.54,
    "APR": 5.49,
    "MAY": 4.77,
    "JUN": 4.62,
    "JUL": 4.8,
    "AUG": 5.65,
    "SEP": 5.17,
    "OCT": 5.39,
    "NOV": 5.46,
    "DEC": 5.54
  },
  {
    "LONG": -49.55,
    "LAT": -22.29,
    "CIDADE": "Gália",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.08,
    "FEB": 5.56,
    "MAR": 5.39,
    "APR": 5.36,
    "MAY": 4.78,
    "JUN": 4.62,
    "JUL": 4.81,
    "AUG": 5.68,
    "SEP": 5.2,
    "OCT": 5.41,
    "NOV": 5.36,
    "DEC": 5.45
  },
  {
    "LONG": -49.09,
    "LAT": -2.23,
    "CIDADE": "Bauru",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.09,
    "FEB": 5.59,
    "MAR": 5.44,
    "APR": 5.42,
    "MAY": 4.79,
    "JUN": 4.6,
    "JUL": 4.81,
    "AUG": 5.57,
    "SEP": 5.17,
    "OCT": 5.44,
    "NOV": 5.4,
    "DEC": 5.53
  },
  {
    "LONG": -48.56,
    "LAT": -22.29,
    "CIDADE": "Jaú",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.12,
    "FEB": 5.65,
    "MAR": 5.45,
    "APR": 5.41,
    "MAY": 4.8,
    "JUN": 4.69,
    "JUL": 4.86,
    "AUG": 5.64,
    "SEP": 5.26,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.44
  },
  {
    "LONG": -48.13,
    "LAT": -22.28,
    "CIDADE": "Brotas",
    "UF": "SÃO PAULO",
    "anual": 5.2,
    "JAN": 5.02,
    "FEB": 5.56,
    "MAR": 5.35,
    "APR": 5.37,
    "MAY": 4.81,
    "JUN": 4.67,
    "JUL": 4.82,
    "AUG": 5.66,
    "SEP": 5.24,
    "OCT": 5.37,
    "NOV": 5.25,
    "DEC": 5.32
  },
  {
    "LONG": -4.78,
    "LAT": -22.26,
    "CIDADE": "Itirapina",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.89,
    "FEB": 5.39,
    "MAR": 5.22,
    "APR": 5.3,
    "MAY": 4.78,
    "JUN": 4.69,
    "JUL": 4.84,
    "AUG": 5.66,
    "SEP": 5.21,
    "OCT": 5.32,
    "NOV": 5.16,
    "DEC": 5.2
  },
  {
    "LONG": -47.17,
    "LAT": -2.23,
    "CIDADE": "Conchal",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.04,
    "FEB": 5.59,
    "MAR": 5.38,
    "APR": 5.37,
    "MAY": 4.84,
    "JUN": 4.77,
    "JUL": 4.84,
    "AUG": 5.66,
    "SEP": 5.3,
    "OCT": 5.44,
    "NOV": 5.2,
    "DEC": 5.34
  },
  {
    "LONG": -46.95,
    "LAT": -22.27,
    "CIDADE": "Estiva Gerbi",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.04,
    "FEB": 5.55,
    "MAR": 5.36,
    "APR": 5.37,
    "MAY": 4.87,
    "JUN": 4.71,
    "JUL": 4.88,
    "AUG": 5.64,
    "SEP": 5.32,
    "OCT": 5.42,
    "NOV": 5.27,
    "DEC": 5.39
  },
  {
    "LONG": -51.31,
    "LAT": -22.22,
    "CIDADE": "Regente Feijó",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.36,
    "FEB": 5.7,
    "MAR": 5.63,
    "APR": 5.47,
    "MAY": 4.81,
    "JUN": 4.63,
    "JUL": 4.8,
    "AUG": 5.67,
    "SEP": 5.25,
    "OCT": 5.39,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -51.26,
    "LAT": -22.17,
    "CIDADE": "Indiana",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.37,
    "FEB": 5.68,
    "MAR": 5.65,
    "APR": 5.48,
    "MAY": 4.82,
    "JUN": 4.66,
    "JUL": 4.78,
    "AUG": 5.68,
    "SEP": 5.22,
    "OCT": 5.4,
    "NOV": 5.56,
    "DEC": 5.66
  },
  {
    "LONG": -50.89,
    "LAT": -22.23,
    "CIDADE": "Rancharia",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.32,
    "FEB": 5.63,
    "MAR": 5.57,
    "APR": 5.44,
    "MAY": 4.81,
    "JUN": 4.64,
    "JUL": 4.8,
    "AUG": 5.62,
    "SEP": 5.2,
    "OCT": 5.38,
    "NOV": 5.47,
    "DEC": 5.59
  },
  {
    "LONG": -50.77,
    "LAT": "#VALOR!",
    "CIDADE": "João Ramalho",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.3,
    "FEB": 5.61,
    "MAR": 5.55,
    "APR": 5.41,
    "MAY": 4.8,
    "JUN": 4.68,
    "JUL": 4.79,
    "AUG": 5.63,
    "SEP": 5.2,
    "OCT": 5.36,
    "NOV": 5.45,
    "DEC": 5.59
  },
  {
    "LONG": -50.7,
    "LAT": -2.22,
    "CIDADE": "Quatá",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.25,
    "FEB": 5.61,
    "MAR": 5.53,
    "APR": 5.4,
    "MAY": 4.82,
    "JUN": 4.58,
    "JUL": 4.84,
    "AUG": 5.67,
    "SEP": 5.18,
    "OCT": 5.37,
    "NOV": 5.45,
    "DEC": 5.56
  },
  {
    "LONG": -50.09,
    "LAT": -2.22,
    "CIDADE": "Oriente",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.17,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 5.48,
    "MAY": 4.78,
    "JUN": 4.6,
    "JUL": 4.81,
    "AUG": 5.7,
    "SEP": 5.18,
    "OCT": 5.39,
    "NOV": 5.43,
    "DEC": 5.44
  },
  {
    "LONG": -49.95,
    "LAT": -22.22,
    "CIDADE": "Marília",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.15,
    "FEB": 5.6,
    "MAR": 5.47,
    "APR": 5.46,
    "MAY": 4.75,
    "JUN": 4.59,
    "JUL": 4.83,
    "AUG": 5.68,
    "SEP": 5.2,
    "OCT": 5.4,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -49.82,
    "LAT": -22.22,
    "CIDADE": "Vera Cruz",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.13,
    "FEB": 5.62,
    "MAR": 5.51,
    "APR": 5.46,
    "MAY": 4.76,
    "JUN": 4.64,
    "JUL": 4.88,
    "AUG": 5.68,
    "SEP": 5.22,
    "OCT": 5.43,
    "NOV": 5.42,
    "DEC": 5.44
  },
  {
    "LONG": -4.97,
    "LAT": -2.22,
    "CIDADE": "Garça",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.1,
    "FEB": 5.56,
    "MAR": 5.46,
    "APR": 5.41,
    "MAY": 4.81,
    "JUN": 4.63,
    "JUL": 4.85,
    "AUG": 5.68,
    "SEP": 5.21,
    "OCT": 5.44,
    "NOV": 5.39,
    "DEC": 5.49
  },
  {
    "LONG": -4.93,
    "LAT": -22.16,
    "CIDADE": "Avaí",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.09,
    "FEB": 5.6,
    "MAR": 5.42,
    "APR": 5.42,
    "MAY": 4.78,
    "JUN": 4.61,
    "JUL": 4.79,
    "AUG": 5.56,
    "SEP": 5.18,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.46
  },
  {
    "LONG": -48.78,
    "LAT": -22.19,
    "CIDADE": "Boracéia",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.2,
    "FEB": 5.7,
    "MAR": 5.49,
    "APR": 5.41,
    "MAY": 4.8,
    "JUN": 4.63,
    "JUL": 4.77,
    "AUG": 5.49,
    "SEP": 5.17,
    "OCT": 5.44,
    "NOV": 5.41,
    "DEC": 5.55
  },
  {
    "LONG": -48.72,
    "LAT": -22.23,
    "CIDADE": "Itapuí",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.2,
    "FEB": 5.7,
    "MAR": 5.49,
    "APR": 5.41,
    "MAY": 4.8,
    "JUN": 4.63,
    "JUL": 4.77,
    "AUG": 5.49,
    "SEP": 5.17,
    "OCT": 5.44,
    "NOV": 5.41,
    "DEC": 5.55
  },
  {
    "LONG": -47.62,
    "LAT": -22.22,
    "CIDADE": "Corumbataí",
    "UF": "SÃO PAULO",
    "anual": 5.14,
    "JAN": 4.92,
    "FEB": 5.45,
    "MAR": 5.23,
    "APR": 5.33,
    "MAY": 4.77,
    "JUN": 4.67,
    "JUL": 4.77,
    "AUG": 5.69,
    "SEP": 5.23,
    "OCT": 5.31,
    "NOV": 5.12,
    "DEC": 5.18
  },
  {
    "LONG": -47.38,
    "LAT": -22.18,
    "CIDADE": "Leme",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5.06,
    "FEB": 5.59,
    "MAR": 5.33,
    "APR": 5.36,
    "MAY": 4.85,
    "JUN": 4.76,
    "JUL": 4.84,
    "AUG": 5.69,
    "SEP": 5.32,
    "OCT": 5.38,
    "NOV": 5.21,
    "DEC": 5.31
  },
  {
    "LONG": -46.75,
    "LAT": -22.19,
    "CIDADE": "Espírito Santo do Pinhal",
    "UF": "SÃO PAULO",
    "anual": 5.16,
    "JAN": 4.94,
    "FEB": 5.39,
    "MAR": 5.18,
    "APR": 5.33,
    "MAY": 4.87,
    "JUN": 4.73,
    "JUL": 4.92,
    "AUG": 5.65,
    "SEP": 5.31,
    "OCT": 5.33,
    "NOV": 5.13,
    "DEC": 5.17
  },
  {
    "LONG": -51.96,
    "LAT": -22.11,
    "CIDADE": "Marabá Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.39,
    "FEB": 5.69,
    "MAR": 5.64,
    "APR": 5.45,
    "MAY": 4.83,
    "JUN": 4.65,
    "JUL": 4.77,
    "AUG": 5.6,
    "SEP": 5.19,
    "OCT": 5.43,
    "NOV": 5.58,
    "DEC": 5.67
  },
  {
    "LONG": -51.47,
    "LAT": -22.08,
    "CIDADE": "Álvares Machado",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.38,
    "FEB": 5.73,
    "MAR": 5.69,
    "APR": 5.52,
    "MAY": 4.85,
    "JUN": 4.63,
    "JUL": 4.84,
    "AUG": 5.67,
    "SEP": 5.24,
    "OCT": 5.44,
    "NOV": 5.56,
    "DEC": 5.69
  },
  {
    "LONG": -5.14,
    "LAT": -22.12,
    "CIDADE": "Presidente Prudente",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.39,
    "FEB": 5.71,
    "MAR": 5.68,
    "APR": 5.51,
    "MAY": 4.85,
    "JUN": 4.62,
    "JUL": 4.85,
    "AUG": 5.68,
    "SEP": 5.22,
    "OCT": 5.42,
    "NOV": 5.56,
    "DEC": 5.67
  },
  {
    "LONG": -51.17,
    "LAT": -22.15,
    "CIDADE": "Martinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.38,
    "FEB": 5.67,
    "MAR": 5.68,
    "APR": 5.52,
    "MAY": 4.87,
    "JUN": 4.68,
    "JUL": 4.83,
    "AUG": 5.7,
    "SEP": 5.2,
    "OCT": 5.43,
    "NOV": 5.55,
    "DEC": 5.64
  },
  {
    "LONG": -50.31,
    "LAT": -22.07,
    "CIDADE": "Quintana",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.26,
    "FEB": 5.62,
    "MAR": 5.53,
    "APR": 5.44,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.83,
    "AUG": 5.69,
    "SEP": 5.14,
    "OCT": 5.38,
    "NOV": 5.45,
    "DEC": 5.51
  },
  {
    "LONG": -50.18,
    "LAT": -22.11,
    "CIDADE": "Pompéia",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.21,
    "FEB": 5.62,
    "MAR": 5.47,
    "APR": 5.46,
    "MAY": 4.8,
    "JUN": 4.65,
    "JUL": 4.84,
    "AUG": 5.69,
    "SEP": 5.18,
    "OCT": 5.41,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -49.72,
    "LAT": -2.21,
    "CIDADE": "Álvaro de Carvalho",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.14,
    "FEB": 5.6,
    "MAR": 5.48,
    "APR": 5.48,
    "MAY": 4.82,
    "JUN": 4.68,
    "JUL": 4.84,
    "AUG": 5.68,
    "SEP": 5.22,
    "OCT": 5.46,
    "NOV": 5.44,
    "DEC": 5.5
  },
  {
    "LONG": -49.44,
    "LAT": -22.1,
    "CIDADE": "Presidente Alves",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.09,
    "FEB": 5.61,
    "MAR": 5.43,
    "APR": 5.44,
    "MAY": 4.82,
    "JUN": 4.64,
    "JUL": 4.87,
    "AUG": 5.65,
    "SEP": 5.22,
    "OCT": 5.45,
    "NOV": 5.39,
    "DEC": 5.48
  },
  {
    "LONG": -48.74,
    "LAT": -22.07,
    "CIDADE": "Bariri",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.23,
    "FEB": 5.72,
    "MAR": 5.47,
    "APR": 5.42,
    "MAY": 4.81,
    "JUN": 4.7,
    "JUL": 4.87,
    "AUG": 5.6,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.41,
    "DEC": 5.53
  },
  {
    "LONG": -48.52,
    "LAT": -2.21,
    "CIDADE": "Bocaina",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.07,
    "FEB": 5.62,
    "MAR": 5.4,
    "APR": 5.36,
    "MAY": 4.81,
    "JUN": 4.73,
    "JUL": 4.87,
    "AUG": 5.61,
    "SEP": 5.24,
    "OCT": 5.45,
    "NOV": 5.33,
    "DEC": 5.43
  },
  {
    "LONG": -48.31,
    "LAT": -22.11,
    "CIDADE": "Dourado",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.1,
    "FEB": 5.62,
    "MAR": 5.4,
    "APR": 5.38,
    "MAY": 4.84,
    "JUN": 4.76,
    "JUL": 4.89,
    "AUG": 5.68,
    "SEP": 5.23,
    "OCT": 5.43,
    "NOV": 5.3,
    "DEC": 5.42
  },
  {
    "LONG": -48.18,
    "LAT": -22.07,
    "CIDADE": "Ribeirão Bonito",
    "UF": "SÃO PAULO",
    "anual": 5.23,
    "JAN": 5.01,
    "FEB": 5.61,
    "MAR": 5.37,
    "APR": 5.39,
    "MAY": 4.86,
    "JUN": 4.73,
    "JUL": 4.89,
    "AUG": 5.68,
    "SEP": 5.26,
    "OCT": 5.4,
    "NOV": 5.22,
    "DEC": 5.35
  },
  {
    "LONG": -47.66,
    "LAT": -22.13,
    "CIDADE": "Analândia",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 4.97,
    "FEB": 5.52,
    "MAR": 5.26,
    "APR": 5.33,
    "MAY": 4.83,
    "JUN": 4.77,
    "JUL": 4.86,
    "AUG": 5.72,
    "SEP": 5.27,
    "OCT": 5.36,
    "NOV": 5.15,
    "DEC": 5.21
  },
  {
    "LONG": -47.45,
    "LAT": -2.21,
    "CIDADE": "Santa Cruz da Conceição",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.04,
    "FEB": 5.6,
    "MAR": 5.34,
    "APR": 5.4,
    "MAY": 4.87,
    "JUN": 4.77,
    "JUL": 4.89,
    "AUG": 5.7,
    "SEP": 5.32,
    "OCT": 5.4,
    "NOV": 5.22,
    "DEC": 5.3
  },
  {
    "LONG": -46.97,
    "LAT": -22.06,
    "CIDADE": "Aguaí",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.07,
    "FEB": 5.58,
    "MAR": 5.32,
    "APR": 5.43,
    "MAY": 4.9,
    "JUN": 4.76,
    "JUL": 4.92,
    "AUG": 5.67,
    "SEP": 5.34,
    "OCT": 5.43,
    "NOV": 5.29,
    "DEC": 5.33
  },
  {
    "LONG": -46.68,
    "LAT": -22.11,
    "CIDADE": "Santo Antônio do Jardim",
    "UF": "SÃO PAULO",
    "anual": 5.12,
    "JAN": 4.86,
    "FEB": 5.33,
    "MAR": 5.04,
    "APR": 5.25,
    "MAY": 4.88,
    "JUN": 4.79,
    "JUL": 4.92,
    "AUG": 5.69,
    "SEP": 5.32,
    "OCT": 5.24,
    "NOV": 5.01,
    "DEC": 5.06
  },
  {
    "LONG": -51.65,
    "LAT": -21.98,
    "CIDADE": "Santo Anastácio",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.37,
    "FEB": 5.71,
    "MAR": 5.65,
    "APR": 5.48,
    "MAY": 4.87,
    "JUN": 4.67,
    "JUL": 4.83,
    "AUG": 5.6,
    "SEP": 5.24,
    "OCT": 5.45,
    "NOV": 5.59,
    "DEC": 5.65
  },
  {
    "LONG": -51.56,
    "LAT": -22,
    "CIDADE": "Presidente Bernardes",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.38,
    "FEB": 5.76,
    "MAR": 5.73,
    "APR": 5.56,
    "MAY": 4.87,
    "JUN": 4.64,
    "JUL": 4.85,
    "AUG": 5.66,
    "SEP": 5.21,
    "OCT": 5.44,
    "NOV": 5.58,
    "DEC": 5.65
  },
  {
    "LONG": -51.41,
    "LAT": -21.95,
    "CIDADE": "Alfredo Marcondes",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.41,
    "FEB": 5.77,
    "MAR": 5.74,
    "APR": 5.59,
    "MAY": 4.89,
    "JUN": 4.65,
    "JUL": 4.86,
    "AUG": 5.71,
    "SEP": 5.24,
    "OCT": 5.45,
    "NOV": 5.6,
    "DEC": 5.67
  },
  {
    "LONG": -5.12,
    "LAT": -2.2,
    "CIDADE": "Caiabu",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.42,
    "FEB": 5.74,
    "MAR": 5.71,
    "APR": 5.55,
    "MAY": 4.89,
    "JUN": 4.69,
    "JUL": 4.84,
    "AUG": 5.71,
    "SEP": 5.21,
    "OCT": 5.42,
    "NOV": 5.56,
    "DEC": 5.67
  },
  {
    "LONG": -50.39,
    "LAT": -22,
    "CIDADE": "Herculândia",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.27,
    "FEB": 5.65,
    "MAR": 5.51,
    "APR": 5.42,
    "MAY": 4.8,
    "JUN": 4.67,
    "JUL": 4.83,
    "AUG": 5.7,
    "SEP": 5.17,
    "OCT": 5.41,
    "NOV": 5.48,
    "DEC": 5.56
  },
  {
    "LONG": -49.79,
    "LAT": -2.2,
    "CIDADE": "Júlio Mesquita",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.15,
    "FEB": 5.61,
    "MAR": 5.42,
    "APR": 5.46,
    "MAY": 4.82,
    "JUN": 4.64,
    "JUL": 4.85,
    "AUG": 5.63,
    "SEP": 5.2,
    "OCT": 5.47,
    "NOV": 5.42,
    "DEC": 5.48
  },
  {
    "LONG": -49.46,
    "LAT": -2.2,
    "CIDADE": "Pirajuí",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.14,
    "FEB": 5.6,
    "MAR": 5.4,
    "APR": 5.47,
    "MAY": 4.81,
    "JUN": 4.62,
    "JUL": 4.89,
    "AUG": 5.64,
    "SEP": 5.21,
    "OCT": 5.46,
    "NOV": 5.39,
    "DEC": 5.49
  },
  {
    "LONG": -48.91,
    "LAT": -22.03,
    "CIDADE": "Arealva",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.21,
    "FEB": 5.71,
    "MAR": 5.49,
    "APR": 5.53,
    "MAY": 4.84,
    "JUN": 4.75,
    "JUL": 4.9,
    "AUG": 5.65,
    "SEP": 5.24,
    "OCT": 5.52,
    "NOV": 5.47,
    "DEC": 5.55
  },
  {
    "LONG": -48.81,
    "LAT": -21.99,
    "CIDADE": "Itaju",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.26,
    "FEB": 5.69,
    "MAR": 5.51,
    "APR": 5.47,
    "MAY": 4.8,
    "JUN": 4.72,
    "JUL": 4.87,
    "AUG": 5.59,
    "SEP": 5.23,
    "OCT": 5.49,
    "NOV": 5.46,
    "DEC": 5.55
  },
  {
    "LONG": -4.84,
    "LAT": -21.99,
    "CIDADE": "Boa Esperança do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.1,
    "FEB": 5.56,
    "MAR": 5.33,
    "APR": 5.39,
    "MAY": 4.86,
    "JUN": 4.74,
    "JUL": 4.92,
    "AUG": 5.72,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.27,
    "DEC": 5.4
  },
  {
    "LONG": -48.33,
    "LAT": -22.04,
    "CIDADE": "Trabiju",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.1,
    "FEB": 5.56,
    "MAR": 5.33,
    "APR": 5.39,
    "MAY": 4.86,
    "JUN": 4.74,
    "JUL": 4.92,
    "AUG": 5.72,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.27,
    "DEC": 5.4
  },
  {
    "LONG": -4.8,
    "LAT": -21.96,
    "CIDADE": "Ibaté",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 4.99,
    "FEB": 5.59,
    "MAR": 5.35,
    "APR": 5.34,
    "MAY": 4.88,
    "JUN": 4.78,
    "JUL": 4.85,
    "AUG": 5.66,
    "SEP": 5.27,
    "OCT": 5.37,
    "NOV": 5.19,
    "DEC": 5.32
  },
  {
    "LONG": -47.89,
    "LAT": -22.02,
    "CIDADE": "São Carlos",
    "UF": "SÃO PAULO",
    "anual": 5.22,
    "JAN": 5,
    "FEB": 5.55,
    "MAR": 5.25,
    "APR": 5.34,
    "MAY": 4.88,
    "JUN": 4.77,
    "JUL": 4.94,
    "AUG": 5.72,
    "SEP": 5.31,
    "OCT": 5.4,
    "NOV": 5.2,
    "DEC": 5.3
  },
  {
    "LONG": -47.43,
    "LAT": -22,
    "CIDADE": "Pirassununga",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.1,
    "FEB": 5.66,
    "MAR": 5.36,
    "APR": 5.41,
    "MAY": 4.89,
    "JUN": 4.76,
    "JUL": 4.91,
    "AUG": 5.68,
    "SEP": 5.32,
    "OCT": 5.43,
    "NOV": 5.24,
    "DEC": 5.34
  },
  {
    "LONG": -46.79,
    "LAT": -21.97,
    "CIDADE": "São João da Boa Vista",
    "UF": "SÃO PAULO",
    "anual": 5.19,
    "JAN": 4.98,
    "FEB": 5.43,
    "MAR": 5.18,
    "APR": 5.36,
    "MAY": 4.91,
    "JUN": 4.78,
    "JUL": 4.93,
    "AUG": 5.68,
    "SEP": 5.32,
    "OCT": 5.34,
    "NOV": 5.15,
    "DEC": 5.19
  },
  {
    "LONG": -51.85,
    "LAT": -21.87,
    "CIDADE": "Presidente Venceslau",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.42,
    "FEB": 5.78,
    "MAR": 5.69,
    "APR": 5.45,
    "MAY": 4.88,
    "JUN": 4.65,
    "JUL": 4.82,
    "AUG": 5.64,
    "SEP": 5.23,
    "OCT": 5.42,
    "NOV": 5.56,
    "DEC": 5.69
  },
  {
    "LONG": -51.73,
    "LAT": -21.89,
    "CIDADE": "Piquerobi",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.36,
    "FEB": 5.73,
    "MAR": 5.67,
    "APR": 5.49,
    "MAY": 4.9,
    "JUN": 4.65,
    "JUL": 4.83,
    "AUG": 5.61,
    "SEP": 5.23,
    "OCT": 5.43,
    "NOV": 5.57,
    "DEC": 5.68
  },
  {
    "LONG": -51.09,
    "LAT": -21.85,
    "CIDADE": "Pracinha",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.35,
    "FEB": 5.7,
    "MAR": 5.67,
    "APR": 5.51,
    "MAY": 4.9,
    "JUN": 4.71,
    "JUL": 4.83,
    "AUG": 5.74,
    "SEP": 5.16,
    "OCT": 5.43,
    "NOV": 5.55,
    "DEC": 5.63
  },
  {
    "LONG": -50.96,
    "LAT": -21.88,
    "CIDADE": "Sagres",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.32,
    "FEB": 5.68,
    "MAR": 5.64,
    "APR": 5.49,
    "MAY": 4.89,
    "JUN": 4.72,
    "JUL": 4.85,
    "AUG": 5.71,
    "SEP": 5.19,
    "OCT": 5.45,
    "NOV": 5.54,
    "DEC": 5.59
  },
  {
    "LONG": -50.74,
    "LAT": -21.92,
    "CIDADE": "Bastos",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.32,
    "FEB": 5.63,
    "MAR": 5.59,
    "APR": 5.42,
    "MAY": 4.88,
    "JUN": 4.69,
    "JUL": 4.87,
    "AUG": 5.74,
    "SEP": 5.19,
    "OCT": 5.41,
    "NOV": 5.48,
    "DEC": 5.56
  },
  {
    "LONG": -50.69,
    "LAT": -2.19,
    "CIDADE": "Iacri",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.27,
    "FEB": 5.64,
    "MAR": 5.54,
    "APR": 5.44,
    "MAY": 4.87,
    "JUN": 4.67,
    "JUL": 4.87,
    "AUG": 5.75,
    "SEP": 5.18,
    "OCT": 5.42,
    "NOV": 5.49,
    "DEC": 5.58
  },
  {
    "LONG": -50.52,
    "LAT": -2.19,
    "CIDADE": "Tupã",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.29,
    "FEB": 5.65,
    "MAR": 5.54,
    "APR": 5.43,
    "MAY": 4.87,
    "JUN": 4.69,
    "JUL": 4.88,
    "AUG": 5.75,
    "SEP": 5.18,
    "OCT": 5.43,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -4.99,
    "LAT": -21.91,
    "CIDADE": "Guaimbê",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.21,
    "FEB": 5.62,
    "MAR": 5.48,
    "APR": 5.48,
    "MAY": 4.82,
    "JUN": 4.66,
    "JUL": 4.88,
    "AUG": 5.66,
    "SEP": 5.24,
    "OCT": 5.47,
    "NOV": 5.42,
    "DEC": 5.5
  },
  {
    "LONG": -49.59,
    "LAT": -2.19,
    "CIDADE": "Guarantã",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.19,
    "FEB": 5.6,
    "MAR": 5.46,
    "APR": 5.43,
    "MAY": 4.81,
    "JUN": 4.71,
    "JUL": 4.89,
    "AUG": 5.65,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.39,
    "DEC": 5.47
  },
  {
    "LONG": -49.36,
    "LAT": "#VALOR!",
    "CIDADE": "Balbinos",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.23,
    "FEB": 5.68,
    "MAR": 5.5,
    "APR": 5.46,
    "MAY": 4.85,
    "JUN": 4.68,
    "JUL": 4.93,
    "AUG": 5.68,
    "SEP": 5.29,
    "OCT": 5.49,
    "NOV": 5.43,
    "DEC": 5.5
  },
  {
    "LONG": -49.22,
    "LAT": -2.19,
    "CIDADE": "Reginópolis",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.25,
    "FEB": 5.68,
    "MAR": 5.53,
    "APR": 5.43,
    "MAY": 4.86,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.68,
    "SEP": 5.25,
    "OCT": 5.51,
    "NOV": 5.43,
    "DEC": 5.53
  },
  {
    "LONG": -49.02,
    "LAT": "#VALOR!",
    "CIDADE": "Iacanga",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.26,
    "FEB": 5.75,
    "MAR": 5.49,
    "APR": 5.48,
    "MAY": 4.83,
    "JUN": 4.75,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.27,
    "OCT": 5.54,
    "NOV": 5.48,
    "DEC": 5.56
  },
  {
    "LONG": -47.62,
    "LAT": -21.9,
    "CIDADE": "Descalvado",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.08,
    "FEB": 5.62,
    "MAR": 5.32,
    "APR": 5.44,
    "MAY": 4.95,
    "JUN": 4.79,
    "JUL": 4.95,
    "AUG": 5.69,
    "SEP": 5.31,
    "OCT": 5.41,
    "NOV": 5.21,
    "DEC": 5.31
  },
  {
    "LONG": -4.67,
    "LAT": -21.93,
    "CIDADE": "Águas da Prata",
    "UF": "SÃO PAULO",
    "anual": 5.15,
    "JAN": 4.9,
    "FEB": 5.37,
    "MAR": 5.11,
    "APR": 5.32,
    "MAY": 4.9,
    "JUN": 4.79,
    "JUL": 4.95,
    "AUG": 5.71,
    "SEP": 5.33,
    "OCT": 5.26,
    "NOV": 5.05,
    "DEC": 5.09
  },
  {
    "LONG": -52.11,
    "LAT": -21.77,
    "CIDADE": "Presidente Epitácio",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.45,
    "FEB": 5.88,
    "MAR": 5.86,
    "APR": 5.53,
    "MAY": 4.88,
    "JUN": 4.62,
    "JUL": 4.75,
    "AUG": 5.58,
    "SEP": 5.09,
    "OCT": 5.38,
    "NOV": 5.6,
    "DEC": 5.78
  },
  {
    "LONG": -51.99,
    "LAT": -21.83,
    "CIDADE": "Caiuá",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.46,
    "FEB": 5.78,
    "MAR": 5.7,
    "APR": 5.49,
    "MAY": 4.94,
    "JUN": 4.63,
    "JUL": 4.8,
    "AUG": 5.57,
    "SEP": 5.18,
    "OCT": 5.43,
    "NOV": 5.6,
    "DEC": 5.72
  },
  {
    "LONG": -5.16,
    "LAT": -21.84,
    "CIDADE": "Ribeirão dos Índios",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.37,
    "FEB": 5.73,
    "MAR": 5.65,
    "APR": 5.5,
    "MAY": 4.9,
    "JUN": 4.67,
    "JUL": 4.83,
    "AUG": 5.62,
    "SEP": 5.2,
    "OCT": 5.45,
    "NOV": 5.57,
    "DEC": 5.68
  },
  {
    "LONG": -51.48,
    "LAT": -21.83,
    "CIDADE": "Emilianópolis",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.34,
    "FEB": 5.69,
    "MAR": 5.65,
    "APR": 5.51,
    "MAY": 4.89,
    "JUN": 4.71,
    "JUL": 4.84,
    "AUG": 5.66,
    "SEP": 5.16,
    "OCT": 5.42,
    "NOV": 5.53,
    "DEC": 5.6
  },
  {
    "LONG": -51.39,
    "LAT": -21.85,
    "CIDADE": "Santo Expedito",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.33,
    "FEB": 5.68,
    "MAR": 5.65,
    "APR": 5.52,
    "MAY": 4.9,
    "JUN": 4.73,
    "JUL": 4.84,
    "AUG": 5.68,
    "SEP": 5.17,
    "OCT": 5.4,
    "NOV": 5.52,
    "DEC": 5.6
  },
  {
    "LONG": -51.18,
    "LAT": -21.8,
    "CIDADE": "Mariápolis",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.35,
    "FEB": 5.7,
    "MAR": 5.69,
    "APR": 5.52,
    "MAY": 4.92,
    "JUN": 4.72,
    "JUL": 4.86,
    "AUG": 5.72,
    "SEP": 5.21,
    "OCT": 5.43,
    "NOV": 5.53,
    "DEC": 5.61
  },
  {
    "LONG": -50.96,
    "LAT": "#VALOR!",
    "CIDADE": "Inúbia Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.35,
    "FEB": 5.67,
    "MAR": 5.62,
    "APR": 5.5,
    "MAY": 4.91,
    "JUN": 4.73,
    "JUL": 4.85,
    "AUG": 5.73,
    "SEP": 5.23,
    "OCT": 5.43,
    "NOV": 5.53,
    "DEC": 5.56
  },
  {
    "LONG": -50.88,
    "LAT": -21.8,
    "CIDADE": "Osvaldo Cruz",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.33,
    "FEB": 5.64,
    "MAR": 5.59,
    "APR": 5.47,
    "MAY": 4.92,
    "JUN": 4.72,
    "JUL": 4.88,
    "AUG": 5.74,
    "SEP": 5.21,
    "OCT": 5.41,
    "NOV": 5.52,
    "DEC": 5.56
  },
  {
    "LONG": -50.8,
    "LAT": -21.78,
    "CIDADE": "Parapuã",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.31,
    "FEB": 5.62,
    "MAR": 5.57,
    "APR": 5.46,
    "MAY": 4.91,
    "JUN": 4.73,
    "JUL": 4.89,
    "AUG": 5.74,
    "SEP": 5.2,
    "OCT": 5.42,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -50.47,
    "LAT": -21.77,
    "CIDADE": "Arco-Íris",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.33,
    "FEB": 5.66,
    "MAR": 5.55,
    "APR": 5.43,
    "MAY": 4.87,
    "JUN": 4.68,
    "JUL": 4.89,
    "AUG": 5.7,
    "SEP": 5.19,
    "OCT": 5.44,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -50.24,
    "LAT": -2.18,
    "CIDADE": "Queiroz",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.31,
    "FEB": 5.65,
    "MAR": 5.53,
    "APR": 5.49,
    "MAY": 4.82,
    "JUN": 4.68,
    "JUL": 4.89,
    "AUG": 5.66,
    "SEP": 5.19,
    "OCT": 5.46,
    "NOV": 5.47,
    "DEC": 5.61
  },
  {
    "LONG": -49.93,
    "LAT": -2.18,
    "CIDADE": "Getulina",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.28,
    "FEB": 5.63,
    "MAR": 5.5,
    "APR": 5.51,
    "MAY": 4.83,
    "JUN": 4.65,
    "JUL": 4.88,
    "AUG": 5.62,
    "SEP": 5.23,
    "OCT": 5.45,
    "NOV": 5.42,
    "DEC": 5.52
  },
  {
    "LONG": -49.61,
    "LAT": -21.8,
    "CIDADE": "Cafelândia",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.21,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 5.48,
    "MAY": 4.82,
    "JUN": 4.68,
    "JUL": 4.88,
    "AUG": 5.67,
    "SEP": 5.29,
    "OCT": 5.47,
    "NOV": 5.41,
    "DEC": 5.46
  },
  {
    "LONG": -49.28,
    "LAT": -2.18,
    "CIDADE": "Uru",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.34,
    "FEB": 5.76,
    "MAR": 5.59,
    "APR": 5.48,
    "MAY": 4.85,
    "JUN": 4.74,
    "JUL": 4.95,
    "AUG": 5.67,
    "SEP": 5.28,
    "OCT": 5.53,
    "NOV": 5.46,
    "DEC": 5.57
  },
  {
    "LONG": -48.83,
    "LAT": -21.76,
    "CIDADE": "Ibitinga",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.23,
    "FEB": 5.69,
    "MAR": 5.42,
    "APR": 5.42,
    "MAY": 4.84,
    "JUN": 4.69,
    "JUL": 4.92,
    "AUG": 5.63,
    "SEP": 5.3,
    "OCT": 5.47,
    "NOV": 5.37,
    "DEC": 5.48
  },
  {
    "LONG": -4.86,
    "LAT": -2.18,
    "CIDADE": "Nova Europa",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.15,
    "FEB": 5.61,
    "MAR": 5.33,
    "APR": 5.4,
    "MAY": 4.87,
    "JUN": 4.76,
    "JUL": 4.93,
    "AUG": 5.68,
    "SEP": 5.28,
    "OCT": 5.42,
    "NOV": 5.32,
    "DEC": 5.42
  },
  {
    "LONG": -48.5,
    "LAT": -21.84,
    "CIDADE": "Gavião Peixoto",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.12,
    "FEB": 5.6,
    "MAR": 5.32,
    "APR": 5.39,
    "MAY": 4.9,
    "JUN": 4.73,
    "JUL": 4.94,
    "AUG": 5.7,
    "SEP": 5.31,
    "OCT": 5.42,
    "NOV": 5.28,
    "DEC": 5.35
  },
  {
    "LONG": -48.18,
    "LAT": -2.18,
    "CIDADE": "Araraquara",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.08,
    "FEB": 5.6,
    "MAR": 5.31,
    "APR": 5.38,
    "MAY": 4.91,
    "JUN": 4.76,
    "JUL": 4.97,
    "AUG": 5.72,
    "SEP": 5.31,
    "OCT": 5.4,
    "NOV": 5.25,
    "DEC": 5.3
  },
  {
    "LONG": -47.49,
    "LAT": -21.85,
    "CIDADE": "Porto Ferreira",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.11,
    "FEB": 5.59,
    "MAR": 5.32,
    "APR": 5.45,
    "MAY": 4.95,
    "JUN": 4.82,
    "JUL": 4.96,
    "AUG": 5.76,
    "SEP": 5.31,
    "OCT": 5.41,
    "NOV": 5.25,
    "DEC": 5.35
  },
  {
    "LONG": -47.25,
    "LAT": -21.82,
    "CIDADE": "Santa Cruz das Palmeiras",
    "UF": "SÃO PAULO",
    "anual": 5.25,
    "JAN": 5.06,
    "FEB": 5.54,
    "MAR": 5.29,
    "APR": 5.44,
    "MAY": 4.94,
    "JUN": 4.8,
    "JUL": 4.95,
    "AUG": 5.76,
    "SEP": 5.32,
    "OCT": 5.39,
    "NOV": 5.22,
    "DEC": 5.27
  },
  {
    "LONG": -47.09,
    "LAT": -21.77,
    "CIDADE": "Casa Branca",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.08,
    "FEB": 5.6,
    "MAR": 5.31,
    "APR": 5.43,
    "MAY": 4.95,
    "JUN": 4.81,
    "JUL": 4.97,
    "AUG": 5.74,
    "SEP": 5.35,
    "OCT": 5.42,
    "NOV": 5.3,
    "DEC": 5.32
  },
  {
    "LONG": -46.89,
    "LAT": -21.83,
    "CIDADE": "Vargem Grande do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.06,
    "FEB": 5.53,
    "MAR": 5.25,
    "APR": 5.47,
    "MAY": 4.95,
    "JUN": 4.85,
    "JUL": 5.01,
    "AUG": 5.75,
    "SEP": 5.39,
    "OCT": 5.4,
    "NOV": 5.22,
    "DEC": 5.25
  },
  {
    "LONG": -51.38,
    "LAT": -2.17,
    "CIDADE": "Flora Rica",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.31,
    "FEB": 5.67,
    "MAR": 5.63,
    "APR": 5.52,
    "MAY": 4.92,
    "JUN": 4.74,
    "JUL": 4.83,
    "AUG": 5.66,
    "SEP": 5.21,
    "OCT": 5.43,
    "NOV": 5.52,
    "DEC": 5.59
  },
  {
    "LONG": -51.02,
    "LAT": -21.72,
    "CIDADE": "Lucélia",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.36,
    "FEB": 5.67,
    "MAR": 5.69,
    "APR": 5.56,
    "MAY": 4.93,
    "JUN": 4.72,
    "JUL": 4.89,
    "AUG": 5.71,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.53,
    "DEC": 5.6
  },
  {
    "LONG": -51.07,
    "LAT": -21.68,
    "CIDADE": "Adamantina",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.36,
    "FEB": 5.67,
    "MAR": 5.69,
    "APR": 5.56,
    "MAY": 4.93,
    "JUN": 4.72,
    "JUL": 4.89,
    "AUG": 5.71,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.53,
    "DEC": 5.6
  },
  {
    "LONG": -50.72,
    "LAT": -21.73,
    "CIDADE": "Rinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.29,
    "FEB": 5.62,
    "MAR": 5.58,
    "APR": 5.49,
    "MAY": 4.89,
    "JUN": 4.72,
    "JUL": 4.9,
    "AUG": 5.7,
    "SEP": 5.16,
    "OCT": 5.41,
    "NOV": 5.52,
    "DEC": 5.55
  },
  {
    "LONG": -50.33,
    "LAT": -2.17,
    "CIDADE": "Luiziânia",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.35,
    "FEB": 5.67,
    "MAR": 5.54,
    "APR": 5.5,
    "MAY": 4.88,
    "JUN": 4.69,
    "JUL": 4.89,
    "AUG": 5.66,
    "SEP": 5.21,
    "OCT": 5.44,
    "NOV": 5.49,
    "DEC": 5.59
  },
  {
    "LONG": -4.98,
    "LAT": -21.67,
    "CIDADE": "Lins",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.27,
    "FEB": 5.69,
    "MAR": 5.51,
    "APR": 5.49,
    "MAY": 4.84,
    "JUN": 4.67,
    "JUL": 4.92,
    "AUG": 5.67,
    "SEP": 5.29,
    "OCT": 5.49,
    "NOV": 5.42,
    "DEC": 5.48
  },
  {
    "LONG": -49.36,
    "LAT": -21.74,
    "CIDADE": "Pongaí",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.38,
    "FEB": 5.83,
    "MAR": 5.63,
    "APR": 5.51,
    "MAY": 4.85,
    "JUN": 4.72,
    "JUL": 4.96,
    "AUG": 5.65,
    "SEP": 5.32,
    "OCT": 5.55,
    "NOV": 5.49,
    "DEC": 5.58
  },
  {
    "LONG": -48.69,
    "LAT": -21.73,
    "CIDADE": "Tabatinga",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.2,
    "FEB": 5.63,
    "MAR": 5.32,
    "APR": 5.4,
    "MAY": 4.91,
    "JUN": 4.83,
    "JUL": 4.97,
    "AUG": 5.74,
    "SEP": 5.32,
    "OCT": 5.46,
    "NOV": 5.34,
    "DEC": 5.45
  },
  {
    "LONG": -48.12,
    "LAT": -21.73,
    "CIDADE": "Américo Brasiliense",
    "UF": "SÃO PAULO",
    "anual": 5.27,
    "JAN": 5.1,
    "FEB": 5.66,
    "MAR": 5.33,
    "APR": 5.38,
    "MAY": 4.94,
    "JUN": 4.82,
    "JUL": 5.01,
    "AUG": 5.69,
    "SEP": 5.3,
    "OCT": 5.4,
    "NOV": 5.27,
    "DEC": 5.3
  },
  {
    "LONG": -4.81,
    "LAT": -2.17,
    "CIDADE": "Santa Lúcia",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.09,
    "FEB": 5.63,
    "MAR": 5.31,
    "APR": 5.38,
    "MAY": 4.94,
    "JUN": 4.81,
    "JUL": 5,
    "AUG": 5.67,
    "SEP": 5.31,
    "OCT": 5.39,
    "NOV": 5.27,
    "DEC": 5.27
  },
  {
    "LONG": -47.48,
    "LAT": -21.71,
    "CIDADE": "Santa Rita do Passa Quatro",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.08,
    "FEB": 5.58,
    "MAR": 5.33,
    "APR": 5.45,
    "MAY": 4.95,
    "JUN": 4.87,
    "JUL": 4.97,
    "AUG": 5.76,
    "SEP": 5.26,
    "OCT": 5.35,
    "NOV": 5.24,
    "DEC": 5.32
  },
  {
    "LONG": -47.27,
    "LAT": -21.7,
    "CIDADE": "Tambaú",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.08,
    "FEB": 5.54,
    "MAR": 5.31,
    "APR": 5.44,
    "MAY": 4.95,
    "JUN": 4.8,
    "JUL": 4.99,
    "AUG": 5.75,
    "SEP": 5.32,
    "OCT": 5.38,
    "NOV": 5.23,
    "DEC": 5.26
  },
  {
    "LONG": -46.97,
    "LAT": -21.73,
    "CIDADE": "Itobi",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.15,
    "FEB": 5.62,
    "MAR": 5.41,
    "APR": 5.55,
    "MAY": 4.99,
    "JUN": 4.88,
    "JUL": 5.02,
    "AUG": 5.74,
    "SEP": 5.39,
    "OCT": 5.46,
    "NOV": 5.33,
    "DEC": 5.32
  },
  {
    "LONG": -46.82,
    "LAT": -21.7,
    "CIDADE": "São Sebastião da Grama",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.11,
    "FEB": 5.57,
    "MAR": 5.32,
    "APR": 5.53,
    "MAY": 4.99,
    "JUN": 4.91,
    "JUL": 5.08,
    "AUG": 5.78,
    "SEP": 5.42,
    "OCT": 5.44,
    "NOV": 5.27,
    "DEC": 5.28
  },
  {
    "LONG": -46.74,
    "LAT": -21.66,
    "CIDADE": "Divinolândia",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.05,
    "FEB": 5.5,
    "MAR": 5.21,
    "APR": 5.45,
    "MAY": 4.97,
    "JUN": 4.93,
    "JUL": 5.09,
    "AUG": 5.8,
    "SEP": 5.41,
    "OCT": 5.37,
    "NOV": 5.17,
    "DEC": 5.19
  },
  {
    "LONG": -51.35,
    "LAT": -21.57,
    "CIDADE": "Irapuru",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.32,
    "FEB": 5.73,
    "MAR": 5.58,
    "APR": 5.53,
    "MAY": 4.91,
    "JUN": 4.71,
    "JUL": 4.88,
    "AUG": 5.68,
    "SEP": 5.25,
    "OCT": 5.43,
    "NOV": 5.51,
    "DEC": 5.59
  },
  {
    "LONG": -51.27,
    "LAT": -21.56,
    "CIDADE": "Pacaembu",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.34,
    "FEB": 5.68,
    "MAR": 5.65,
    "APR": 5.56,
    "MAY": 4.94,
    "JUN": 4.76,
    "JUL": 4.88,
    "AUG": 5.68,
    "SEP": 5.23,
    "OCT": 5.43,
    "NOV": 5.55,
    "DEC": 5.57
  },
  {
    "LONG": -51.17,
    "LAT": -21.61,
    "CIDADE": "Flórida Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.32,
    "FEB": 5.72,
    "MAR": 5.66,
    "APR": 5.58,
    "MAY": 4.95,
    "JUN": 4.77,
    "JUL": 4.9,
    "AUG": 5.67,
    "SEP": 5.21,
    "OCT": 5.43,
    "NOV": 5.53,
    "DEC": 5.57
  },
  {
    "LONG": -50.86,
    "LAT": -2.16,
    "CIDADE": "Salmourão",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.32,
    "FEB": 5.65,
    "MAR": 5.58,
    "APR": 5.47,
    "MAY": 4.89,
    "JUN": 4.71,
    "JUL": 4.89,
    "AUG": 5.68,
    "SEP": 5.19,
    "OCT": 5.37,
    "NOV": 5.47,
    "DEC": 5.52
  },
  {
    "LONG": -50.5,
    "LAT": -2.16,
    "CIDADE": "Santópolis do Aguapeí",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.32,
    "FEB": 5.62,
    "MAR": 5.54,
    "APR": 5.5,
    "MAY": 4.89,
    "JUN": 4.73,
    "JUL": 4.94,
    "AUG": 5.71,
    "SEP": 5.21,
    "OCT": 5.45,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -50.6,
    "LAT": -2.16,
    "CIDADE": "Piacatu",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.32,
    "FEB": 5.62,
    "MAR": 5.54,
    "APR": 5.5,
    "MAY": 4.89,
    "JUN": 4.73,
    "JUL": 4.94,
    "AUG": 5.71,
    "SEP": 5.21,
    "OCT": 5.45,
    "NOV": 5.52,
    "DEC": 5.57
  },
  {
    "LONG": -50.45,
    "LAT": "#VALOR!",
    "CIDADE": "Clementina",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.31,
    "FEB": 5.63,
    "MAR": 5.55,
    "APR": 5.51,
    "MAY": 4.89,
    "JUN": 4.73,
    "JUL": 4.94,
    "AUG": 5.71,
    "SEP": 5.24,
    "OCT": 5.44,
    "NOV": 5.5,
    "DEC": 5.56
  },
  {
    "LONG": -50.16,
    "LAT": -2.16,
    "CIDADE": "Alto Alegre",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.31,
    "FEB": 5.68,
    "MAR": 5.55,
    "APR": 5.5,
    "MAY": 4.88,
    "JUN": 4.69,
    "JUL": 4.86,
    "AUG": 5.67,
    "SEP": 5.27,
    "OCT": 5.48,
    "NOV": 5.49,
    "DEC": 5.55
  },
  {
    "LONG": -49.8,
    "LAT": -2.16,
    "CIDADE": "Guaiçara",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.31,
    "FEB": 5.74,
    "MAR": 5.59,
    "APR": 5.55,
    "MAY": 4.87,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.64,
    "SEP": 5.32,
    "OCT": 5.51,
    "NOV": 5.48,
    "DEC": 5.52
  },
  {
    "LONG": -49.07,
    "LAT": -21.62,
    "CIDADE": "Borborema",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.26,
    "FEB": 5.68,
    "MAR": 5.43,
    "APR": 5.45,
    "MAY": 4.9,
    "JUN": 4.79,
    "JUL": 4.96,
    "AUG": 5.68,
    "SEP": 5.32,
    "OCT": 5.44,
    "NOV": 5.37,
    "DEC": 5.46
  },
  {
    "LONG": -48.82,
    "LAT": -21.59,
    "CIDADE": "Itápolis",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.2,
    "FEB": 5.68,
    "MAR": 5.39,
    "APR": 5.41,
    "MAY": 4.9,
    "JUN": 4.79,
    "JUL": 4.99,
    "AUG": 5.67,
    "SEP": 5.34,
    "OCT": 5.45,
    "NOV": 5.33,
    "DEC": 5.4
  },
  {
    "LONG": -48.36,
    "LAT": -2.16,
    "CIDADE": "Matão",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.18,
    "FEB": 5.64,
    "MAR": 5.35,
    "APR": 5.37,
    "MAY": 4.95,
    "JUN": 4.83,
    "JUL": 4.97,
    "AUG": 5.72,
    "SEP": 5.32,
    "OCT": 5.44,
    "NOV": 5.3,
    "DEC": 5.38
  },
  {
    "LONG": -48.07,
    "LAT": -21.59,
    "CIDADE": "Rincão",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.08,
    "FEB": 5.68,
    "MAR": 5.36,
    "APR": 5.45,
    "MAY": 5,
    "JUN": 4.84,
    "JUL": 5.02,
    "AUG": 5.73,
    "SEP": 5.29,
    "OCT": 5.39,
    "NOV": 5.25,
    "DEC": 5.4
  },
  {
    "LONG": -47.7,
    "LAT": -21.55,
    "CIDADE": "Luís Antônio",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.07,
    "FEB": 5.63,
    "MAR": 5.28,
    "APR": 5.46,
    "MAY": 4.96,
    "JUN": 4.85,
    "JUL": 5,
    "AUG": 5.74,
    "SEP": 5.27,
    "OCT": 5.35,
    "NOV": 5.16,
    "DEC": 5.31
  },
  {
    "LONG": -46.89,
    "LAT": -21.6,
    "CIDADE": "São José do Rio Pardo",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.15,
    "FEB": 5.63,
    "MAR": 5.31,
    "APR": 5.51,
    "MAY": 5.01,
    "JUN": 4.9,
    "JUL": 5.09,
    "AUG": 5.79,
    "SEP": 5.42,
    "OCT": 5.42,
    "NOV": 5.29,
    "DEC": 5.27
  },
  {
    "LONG": -51.7,
    "LAT": -21.49,
    "CIDADE": "Ouro Verde",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.32,
    "FEB": 5.71,
    "MAR": 5.62,
    "APR": 5.52,
    "MAY": 4.94,
    "JUN": 4.7,
    "JUL": 4.86,
    "AUG": 5.63,
    "SEP": 5.23,
    "OCT": 5.45,
    "NOV": 5.49,
    "DEC": 5.6
  },
  {
    "LONG": -51.54,
    "LAT": -21.48,
    "CIDADE": "Dracena",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.32,
    "FEB": 5.71,
    "MAR": 5.64,
    "APR": 5.48,
    "MAY": 4.95,
    "JUN": 4.75,
    "JUL": 4.88,
    "AUG": 5.64,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.52,
    "DEC": 5.61
  },
  {
    "LONG": -51.43,
    "LAT": -21.51,
    "CIDADE": "Junqueirópolis",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.33,
    "FEB": 5.71,
    "MAR": 5.62,
    "APR": 5.48,
    "MAY": 4.95,
    "JUN": 4.75,
    "JUL": 4.89,
    "AUG": 5.67,
    "SEP": 5.23,
    "OCT": 5.45,
    "NOV": 5.52,
    "DEC": 5.59
  },
  {
    "LONG": -50.55,
    "LAT": -2.15,
    "CIDADE": "Gabriel Monteiro",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.36,
    "FEB": 5.66,
    "MAR": 5.58,
    "APR": 5.56,
    "MAY": 4.89,
    "JUN": 4.75,
    "JUL": 4.93,
    "AUG": 5.71,
    "SEP": 5.24,
    "OCT": 5.46,
    "NOV": 5.53,
    "DEC": 5.55
  },
  {
    "LONG": -50.32,
    "LAT": -21.5,
    "CIDADE": "Braúna",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.37,
    "FEB": 5.7,
    "MAR": 5.54,
    "APR": 5.52,
    "MAY": 4.89,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.7,
    "SEP": 5.25,
    "OCT": 5.45,
    "NOV": 5.52,
    "DEC": 5.56
  },
  {
    "LONG": -49.95,
    "LAT": -21.46,
    "CIDADE": "Avanhandava",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.3,
    "FEB": 5.67,
    "MAR": 5.55,
    "APR": 5.53,
    "MAY": 4.88,
    "JUN": 4.74,
    "JUL": 4.96,
    "AUG": 5.66,
    "SEP": 5.29,
    "OCT": 5.5,
    "NOV": 5.5,
    "DEC": 5.57
  },
  {
    "LONG": -49.86,
    "LAT": -2.15,
    "CIDADE": "Promissão",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.32,
    "FEB": 5.75,
    "MAR": 5.57,
    "APR": 5.56,
    "MAY": 4.85,
    "JUN": 4.75,
    "JUL": 4.95,
    "AUG": 5.66,
    "SEP": 5.31,
    "OCT": 5.49,
    "NOV": 5.49,
    "DEC": 5.59
  },
  {
    "LONG": -4.96,
    "LAT": -21.46,
    "CIDADE": "Sabino",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.42,
    "FEB": 5.85,
    "MAR": 5.65,
    "APR": 5.48,
    "MAY": 4.87,
    "JUN": 4.69,
    "JUL": 4.96,
    "AUG": 5.63,
    "SEP": 5.3,
    "OCT": 5.52,
    "NOV": 5.52,
    "DEC": 5.63
  },
  {
    "LONG": -49.22,
    "LAT": -21.47,
    "CIDADE": "Novo Horizonte",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.31,
    "FEB": 5.69,
    "MAR": 5.49,
    "APR": 5.47,
    "MAY": 4.92,
    "JUN": 4.76,
    "JUL": 5.01,
    "AUG": 5.74,
    "SEP": 5.32,
    "OCT": 5.49,
    "NOV": 5.42,
    "DEC": 5.47
  },
  {
    "LONG": -4.84,
    "LAT": -2.15,
    "CIDADE": "Santa Ernestina",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.17,
    "FEB": 5.62,
    "MAR": 5.32,
    "APR": 5.43,
    "MAY": 4.98,
    "JUN": 4.86,
    "JUL": 5.02,
    "AUG": 5.72,
    "SEP": 5.33,
    "OCT": 5.44,
    "NOV": 5.31,
    "DEC": 5.38
  },
  {
    "LONG": -48.39,
    "LAT": -2.15,
    "CIDADE": "Dobrada",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.17,
    "FEB": 5.62,
    "MAR": 5.32,
    "APR": 5.43,
    "MAY": 4.98,
    "JUN": 4.86,
    "JUL": 5.02,
    "AUG": 5.72,
    "SEP": 5.33,
    "OCT": 5.44,
    "NOV": 5.31,
    "DEC": 5.38
  },
  {
    "LONG": -4.82,
    "LAT": -2.15,
    "CIDADE": "Motuca",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.13,
    "FEB": 5.64,
    "MAR": 5.33,
    "APR": 5.47,
    "MAY": 4.99,
    "JUN": 4.88,
    "JUL": 5.03,
    "AUG": 5.67,
    "SEP": 5.31,
    "OCT": 5.36,
    "NOV": 5.26,
    "DEC": 5.37
  },
  {
    "LONG": -4.8,
    "LAT": -21.49,
    "CIDADE": "Guatapará",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.18,
    "FEB": 5.7,
    "MAR": 5.37,
    "APR": 5.42,
    "MAY": 4.97,
    "JUN": 4.87,
    "JUL": 5.02,
    "AUG": 5.71,
    "SEP": 5.28,
    "OCT": 5.38,
    "NOV": 5.26,
    "DEC": 5.4
  },
  {
    "LONG": -47.55,
    "LAT": -21.47,
    "CIDADE": "São Simão",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5.09,
    "FEB": 5.6,
    "MAR": 5.27,
    "APR": 5.44,
    "MAY": 4.97,
    "JUN": 4.87,
    "JUL": 5.03,
    "AUG": 5.76,
    "SEP": 5.3,
    "OCT": 5.38,
    "NOV": 5.15,
    "DEC": 5.3
  },
  {
    "LONG": -47.36,
    "LAT": -2.15,
    "CIDADE": "Santa Rosa de Viterbo",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.12,
    "FEB": 5.63,
    "MAR": 5.31,
    "APR": 5.49,
    "MAY": 4.98,
    "JUN": 4.87,
    "JUL": 5.05,
    "AUG": 5.77,
    "SEP": 5.32,
    "OCT": 5.43,
    "NOV": 5.26,
    "DEC": 5.3
  },
  {
    "LONG": -47,
    "LAT": -21.47,
    "CIDADE": "Mococa",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.1,
    "FEB": 5.57,
    "MAR": 5.31,
    "APR": 5.47,
    "MAY": 5,
    "JUN": 4.88,
    "JUL": 5.07,
    "AUG": 5.76,
    "SEP": 5.33,
    "OCT": 5.41,
    "NOV": 5.25,
    "DEC": 5.25
  },
  {
    "LONG": -46.75,
    "LAT": -21.47,
    "CIDADE": "Tapiratiba",
    "UF": "SÃO PAULO",
    "anual": 5.24,
    "JAN": 5.06,
    "FEB": 5.52,
    "MAR": 5.14,
    "APR": 5.41,
    "MAY": 4.98,
    "JUN": 4.9,
    "JUL": 5.08,
    "AUG": 5.81,
    "SEP": 5.4,
    "OCT": 5.36,
    "NOV": 5.11,
    "DEC": 5.15
  },
  {
    "LONG": -46.64,
    "LAT": -21.53,
    "CIDADE": "Caconde",
    "UF": "SÃO PAULO",
    "anual": 5.18,
    "JAN": 4.99,
    "FEB": 5.41,
    "MAR": 5.05,
    "APR": 5.39,
    "MAY": 4.94,
    "JUN": 4.78,
    "JUL": 5.08,
    "AUG": 5.77,
    "SEP": 5.39,
    "OCT": 5.31,
    "NOV": 5.04,
    "DEC": 5.03
  },
  {
    "LONG": -51.86,
    "LAT": -21.35,
    "CIDADE": "Panorama",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.35,
    "FEB": 5.81,
    "MAR": 5.69,
    "APR": 5.5,
    "MAY": 4.9,
    "JUN": 4.62,
    "JUL": 4.8,
    "AUG": 5.52,
    "SEP": 5.16,
    "OCT": 5.44,
    "NOV": 5.54,
    "DEC": 5.6
  },
  {
    "LONG": -51.75,
    "LAT": -21.35,
    "CIDADE": "Santa Mercedes",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.35,
    "FEB": 5.72,
    "MAR": 5.66,
    "APR": 5.51,
    "MAY": 4.94,
    "JUN": 4.71,
    "JUL": 4.87,
    "AUG": 5.64,
    "SEP": 5.25,
    "OCT": 5.46,
    "NOV": 5.52,
    "DEC": 5.6
  },
  {
    "LONG": -51.57,
    "LAT": -21.39,
    "CIDADE": "Tupi Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.32,
    "FEB": 5.7,
    "MAR": 5.64,
    "APR": 5.54,
    "MAY": 4.96,
    "JUN": 4.76,
    "JUL": 4.88,
    "AUG": 5.64,
    "SEP": 5.27,
    "OCT": 5.44,
    "NOV": 5.53,
    "DEC": 5.6
  },
  {
    "LONG": -50.48,
    "LAT": -21.4,
    "CIDADE": "Bilac",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.39,
    "FEB": 5.69,
    "MAR": 5.57,
    "APR": 5.53,
    "MAY": 4.91,
    "JUN": 4.72,
    "JUL": 4.96,
    "AUG": 5.73,
    "SEP": 5.24,
    "OCT": 5.46,
    "NOV": 5.52,
    "DEC": 5.56
  },
  {
    "LONG": -50.29,
    "LAT": -21.35,
    "CIDADE": "Coroados",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.38,
    "FEB": 5.77,
    "MAR": 5.55,
    "APR": 5.5,
    "MAY": 4.92,
    "JUN": 4.73,
    "JUL": 4.96,
    "AUG": 5.71,
    "SEP": 5.27,
    "OCT": 5.48,
    "NOV": 5.52,
    "DEC": 5.59
  },
  {
    "LONG": -50.21,
    "LAT": -2.14,
    "CIDADE": "Glicério",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.38,
    "FEB": 5.77,
    "MAR": 5.55,
    "APR": 5.5,
    "MAY": 4.92,
    "JUN": 4.73,
    "JUL": 4.96,
    "AUG": 5.71,
    "SEP": 5.27,
    "OCT": 5.48,
    "NOV": 5.52,
    "DEC": 5.59
  },
  {
    "LONG": -50.08,
    "LAT": -21.42,
    "CIDADE": "Penápolis",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.34,
    "FEB": 5.73,
    "MAR": 5.6,
    "APR": 5.53,
    "MAY": 4.91,
    "JUN": 4.75,
    "JUL": 5,
    "AUG": 5.72,
    "SEP": 5.28,
    "OCT": 5.52,
    "NOV": 5.57,
    "DEC": 5.6
  },
  {
    "LONG": -48.51,
    "LAT": -21.41,
    "CIDADE": "Taquaritinga",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.18,
    "FEB": 5.66,
    "MAR": 5.34,
    "APR": 5.45,
    "MAY": 5.02,
    "JUN": 4.87,
    "JUL": 5.04,
    "AUG": 5.8,
    "SEP": 5.36,
    "OCT": 5.47,
    "NOV": 5.39,
    "DEC": 5.42
  },
  {
    "LONG": -4.82,
    "LAT": -21.36,
    "CIDADE": "Guariba",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.18,
    "FEB": 5.69,
    "MAR": 5.35,
    "APR": 5.46,
    "MAY": 5.01,
    "JUN": 4.87,
    "JUL": 5.06,
    "AUG": 5.71,
    "SEP": 5.33,
    "OCT": 5.4,
    "NOV": 5.31,
    "DEC": 5.39
  },
  {
    "LONG": -48.07,
    "LAT": -2.14,
    "CIDADE": "Pradópolis",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.15,
    "FEB": 5.67,
    "MAR": 5.4,
    "APR": 5.4,
    "MAY": 5.01,
    "JUN": 4.88,
    "JUL": 5.03,
    "AUG": 5.69,
    "SEP": 5.26,
    "OCT": 5.37,
    "NOV": 5.26,
    "DEC": 5.41
  },
  {
    "LONG": -51.83,
    "LAT": -21.32,
    "CIDADE": "Paulicéia",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.31,
    "FEB": 5.78,
    "MAR": 5.68,
    "APR": 5.51,
    "MAY": 4.92,
    "JUN": 4.67,
    "JUL": 4.79,
    "AUG": 5.52,
    "SEP": 5.17,
    "OCT": 5.42,
    "NOV": 5.5,
    "DEC": 5.59
  },
  {
    "LONG": -51.64,
    "LAT": -2.13,
    "CIDADE": "Nova Guataporanga",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.37,
    "FEB": 5.7,
    "MAR": 5.68,
    "APR": 5.5,
    "MAY": 4.95,
    "JUN": 4.73,
    "JUL": 4.88,
    "AUG": 5.7,
    "SEP": 5.24,
    "OCT": 5.44,
    "NOV": 5.55,
    "DEC": 5.61
  },
  {
    "LONG": -5.17,
    "LAT": -2.13,
    "CIDADE": "São João do Pau d'Alho",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.37,
    "FEB": 5.7,
    "MAR": 5.68,
    "APR": 5.5,
    "MAY": 4.95,
    "JUN": 4.73,
    "JUL": 4.88,
    "AUG": 5.7,
    "SEP": 5.24,
    "OCT": 5.44,
    "NOV": 5.55,
    "DEC": 5.61
  },
  {
    "LONG": -51.57,
    "LAT": -21.3,
    "CIDADE": "Monte Castelo",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.29,
    "FEB": 5.69,
    "MAR": 5.61,
    "APR": 5.52,
    "MAY": 4.97,
    "JUN": 4.74,
    "JUL": 4.89,
    "AUG": 5.67,
    "SEP": 5.25,
    "OCT": 5.44,
    "NOV": 5.55,
    "DEC": 5.63
  },
  {
    "LONG": -50.81,
    "LAT": -2.13,
    "CIDADE": "Bento de Abreu",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.33,
    "FEB": 5.66,
    "MAR": 5.62,
    "APR": 5.54,
    "MAY": 4.91,
    "JUN": 4.77,
    "JUL": 4.93,
    "AUG": 5.72,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.51,
    "DEC": 5.56
  },
  {
    "LONG": "#VALOR!",
    "LAT": -2.13,
    "CIDADE": "Rubiácea",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.33,
    "FEB": 5.66,
    "MAR": 5.6,
    "APR": 5.53,
    "MAY": 4.9,
    "JUN": 4.75,
    "JUL": 4.94,
    "AUG": 5.7,
    "SEP": 5.27,
    "OCT": 5.46,
    "NOV": 5.5,
    "DEC": 5.56
  },
  {
    "LONG": -50.65,
    "LAT": -21.25,
    "CIDADE": "Guararapes",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.35,
    "FEB": 5.7,
    "MAR": 5.58,
    "APR": 5.56,
    "MAY": 4.9,
    "JUN": 4.74,
    "JUL": 4.97,
    "AUG": 5.73,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.56,
    "DEC": 5.57
  },
  {
    "LONG": -50.34,
    "LAT": -21.29,
    "CIDADE": "Birigui",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.38,
    "FEB": 5.7,
    "MAR": 5.55,
    "APR": 5.52,
    "MAY": 4.94,
    "JUN": 4.76,
    "JUL": 4.98,
    "AUG": 5.7,
    "SEP": 5.28,
    "OCT": 5.5,
    "NOV": 5.55,
    "DEC": 5.6
  },
  {
    "LONG": -49.95,
    "LAT": -21.27,
    "CIDADE": "Barbosa",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.35,
    "FEB": 5.74,
    "MAR": 5.59,
    "APR": 5.49,
    "MAY": 4.88,
    "JUN": 4.72,
    "JUL": 4.94,
    "AUG": 5.64,
    "SEP": 5.27,
    "OCT": 5.51,
    "NOV": 5.54,
    "DEC": 5.64
  },
  {
    "LONG": -49.41,
    "LAT": -21.28,
    "CIDADE": "Irapuã",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.34,
    "FEB": 5.69,
    "MAR": 5.49,
    "APR": 5.51,
    "MAY": 4.93,
    "JUN": 4.79,
    "JUL": 4.99,
    "AUG": 5.7,
    "SEP": 5.33,
    "OCT": 5.49,
    "NOV": 5.43,
    "DEC": 5.52
  },
  {
    "LONG": -49.5,
    "LAT": -21.34,
    "CIDADE": "Sales",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.34,
    "FEB": 5.69,
    "MAR": 5.49,
    "APR": 5.51,
    "MAY": 4.93,
    "JUN": 4.79,
    "JUL": 4.99,
    "AUG": 5.7,
    "SEP": 5.33,
    "OCT": 5.49,
    "NOV": 5.43,
    "DEC": 5.52
  },
  {
    "LONG": -49.13,
    "LAT": -21.26,
    "CIDADE": "Marapoama",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.27,
    "FEB": 5.63,
    "MAR": 5.43,
    "APR": 5.45,
    "MAY": 4.95,
    "JUN": 4.85,
    "JUL": 5.04,
    "AUG": 5.8,
    "SEP": 5.39,
    "OCT": 5.48,
    "NOV": 5.36,
    "DEC": 5.43
  },
  {
    "LONG": -49.05,
    "LAT": -2.13,
    "CIDADE": "Itajobi",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.27,
    "FEB": 5.61,
    "MAR": 5.39,
    "APR": 5.44,
    "MAY": 4.97,
    "JUN": 4.84,
    "JUL": 5.03,
    "AUG": 5.82,
    "SEP": 5.39,
    "OCT": 5.47,
    "NOV": 5.37,
    "DEC": 5.41
  },
  {
    "LONG": -48.69,
    "LAT": -21.27,
    "CIDADE": "Fernando Prestes",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.18,
    "FEB": 5.68,
    "MAR": 5.42,
    "APR": 5.49,
    "MAY": 5.04,
    "JUN": 4.9,
    "JUL": 5.1,
    "AUG": 5.82,
    "SEP": 5.41,
    "OCT": 5.48,
    "NOV": 5.39,
    "DEC": 5.43
  },
  {
    "LONG": -4.86,
    "LAT": -2.13,
    "CIDADE": "Cândido Rodrigues",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.18,
    "FEB": 5.68,
    "MAR": 5.42,
    "APR": 5.49,
    "MAY": 5.04,
    "JUN": 4.9,
    "JUL": 5.1,
    "AUG": 5.82,
    "SEP": 5.41,
    "OCT": 5.48,
    "NOV": 5.39,
    "DEC": 5.43
  },
  {
    "LONG": -48.5,
    "LAT": -2.13,
    "CIDADE": "Monte Alto",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.15,
    "FEB": 5.65,
    "MAR": 5.31,
    "APR": 5.44,
    "MAY": 5.04,
    "JUN": 4.9,
    "JUL": 5.11,
    "AUG": 5.78,
    "SEP": 5.38,
    "OCT": 5.48,
    "NOV": 5.38,
    "DEC": 5.42
  },
  {
    "LONG": -48.33,
    "LAT": -21.25,
    "CIDADE": "Jaboticabal",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.21,
    "FEB": 5.67,
    "MAR": 5.35,
    "APR": 5.46,
    "MAY": 5.04,
    "JUN": 4.92,
    "JUL": 5.11,
    "AUG": 5.72,
    "SEP": 5.35,
    "OCT": 5.45,
    "NOV": 5.33,
    "DEC": 5.41
  },
  {
    "LONG": -47.73,
    "LAT": -21.34,
    "CIDADE": "Cravinhos",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.05,
    "FEB": 5.63,
    "MAR": 5.26,
    "APR": 5.42,
    "MAY": 4.97,
    "JUN": 4.97,
    "JUL": 5.1,
    "AUG": 5.75,
    "SEP": 5.33,
    "OCT": 5.36,
    "NOV": 5.22,
    "DEC": 5.33
  },
  {
    "LONG": -47.56,
    "LAT": -21.31,
    "CIDADE": "Serra Azul",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.07,
    "FEB": 5.6,
    "MAR": 5.32,
    "APR": 5.46,
    "MAY": 5.01,
    "JUN": 4.95,
    "JUL": 5.11,
    "AUG": 5.82,
    "SEP": 5.31,
    "OCT": 5.39,
    "NOV": 5.21,
    "DEC": 5.37
  },
  {
    "LONG": -47.43,
    "LAT": -21.3,
    "CIDADE": "Santa Cruz da Esperança",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.1,
    "FEB": 5.59,
    "MAR": 5.31,
    "APR": 5.47,
    "MAY": 5,
    "JUN": 4.91,
    "JUL": 5.12,
    "AUG": 5.81,
    "SEP": 5.32,
    "OCT": 5.42,
    "NOV": 5.22,
    "DEC": 5.35
  },
  {
    "LONG": -47.3,
    "LAT": -21.28,
    "CIDADE": "Cajuru",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.05,
    "FEB": 5.52,
    "MAR": 5.24,
    "APR": 5.42,
    "MAY": 5.01,
    "JUN": 4.91,
    "JUL": 5.12,
    "AUG": 5.85,
    "SEP": 5.35,
    "OCT": 5.4,
    "NOV": 5.17,
    "DEC": 5.27
  },
  {
    "LONG": -47.16,
    "LAT": -21.28,
    "CIDADE": "Cássia dos Coqueiros",
    "UF": "SÃO PAULO",
    "anual": 5.21,
    "JAN": 4.92,
    "FEB": 5.39,
    "MAR": 5.05,
    "APR": 5.35,
    "MAY": 5.01,
    "JUN": 4.96,
    "JUL": 5.13,
    "AUG": 5.88,
    "SEP": 5.34,
    "OCT": 5.33,
    "NOV": 5.03,
    "DEC": 5.09
  },
  {
    "LONG": -51.04,
    "LAT": -21.17,
    "CIDADE": "Lavínia",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.33,
    "FEB": 5.69,
    "MAR": 5.62,
    "APR": 5.56,
    "MAY": 4.95,
    "JUN": 4.8,
    "JUL": 4.93,
    "AUG": 5.74,
    "SEP": 5.25,
    "OCT": 5.43,
    "NOV": 5.51,
    "DEC": 5.54
  },
  {
    "LONG": -50.87,
    "LAT": -21.22,
    "CIDADE": "Valparaíso",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.35,
    "FEB": 5.68,
    "MAR": 5.61,
    "APR": 5.53,
    "MAY": 4.93,
    "JUN": 4.78,
    "JUL": 4.96,
    "AUG": 5.74,
    "SEP": 5.29,
    "OCT": 5.45,
    "NOV": 5.51,
    "DEC": 5.57
  },
  {
    "LONG": -50.44,
    "LAT": -21.21,
    "CIDADE": "Araçatuba",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.39,
    "FEB": 5.72,
    "MAR": 5.6,
    "APR": 5.55,
    "MAY": 4.94,
    "JUN": 4.76,
    "JUL": 4.97,
    "AUG": 5.75,
    "SEP": 5.27,
    "OCT": 5.5,
    "NOV": 5.58,
    "DEC": 5.59
  },
  {
    "LONG": -50.18,
    "LAT": -2.12,
    "CIDADE": "Brejo Alegre",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.33,
    "FEB": 5.71,
    "MAR": 5.56,
    "APR": 5.49,
    "MAY": 4.86,
    "JUN": 4.69,
    "JUL": 4.91,
    "AUG": 5.62,
    "SEP": 5.21,
    "OCT": 5.48,
    "NOV": 5.53,
    "DEC": 5.61
  },
  {
    "LONG": -49.72,
    "LAT": -21.17,
    "CIDADE": "Ubarana",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.31,
    "FEB": 5.66,
    "MAR": 5.46,
    "APR": 5.51,
    "MAY": 4.9,
    "JUN": 4.79,
    "JUL": 5,
    "AUG": 5.7,
    "SEP": 5.33,
    "OCT": 5.49,
    "NOV": 5.48,
    "DEC": 5.52
  },
  {
    "LONG": -49.65,
    "LAT": -2.12,
    "CIDADE": "Adolfo",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.33,
    "FEB": 5.66,
    "MAR": 5.48,
    "APR": 5.49,
    "MAY": 4.88,
    "JUN": 4.78,
    "JUL": 4.99,
    "AUG": 5.7,
    "SEP": 5.35,
    "OCT": 5.5,
    "NOV": 5.45,
    "DEC": 5.48
  },
  {
    "LONG": -49.58,
    "LAT": -21.18,
    "CIDADE": "Mendonça",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.33,
    "FEB": 5.65,
    "MAR": 5.48,
    "APR": 5.49,
    "MAY": 4.92,
    "JUN": 4.78,
    "JUL": 4.99,
    "AUG": 5.68,
    "SEP": 5.34,
    "OCT": 5.49,
    "NOV": 5.43,
    "DEC": 5.47
  },
  {
    "LONG": -49.29,
    "LAT": -21.2,
    "CIDADE": "Urupês",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.27,
    "FEB": 5.66,
    "MAR": 5.43,
    "APR": 5.48,
    "MAY": 4.96,
    "JUN": 4.86,
    "JUL": 5.05,
    "AUG": 5.81,
    "SEP": 5.4,
    "OCT": 5.48,
    "NOV": 5.41,
    "DEC": 5.44
  },
  {
    "LONG": -49.11,
    "LAT": -2.12,
    "CIDADE": "Elisiário",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.23,
    "FEB": 5.66,
    "MAR": 5.41,
    "APR": 5.48,
    "MAY": 4.97,
    "JUN": 4.85,
    "JUL": 5.05,
    "AUG": 5.8,
    "SEP": 5.41,
    "OCT": 5.47,
    "NOV": 5.37,
    "DEC": 5.44
  },
  {
    "LONG": -4.89,
    "LAT": -21.19,
    "CIDADE": "Pindorama",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.19,
    "FEB": 5.54,
    "MAR": 5.34,
    "APR": 5.45,
    "MAY": 4.98,
    "JUN": 4.88,
    "JUL": 5.07,
    "AUG": 5.85,
    "SEP": 5.41,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.4
  },
  {
    "LONG": -48.81,
    "LAT": -21.24,
    "CIDADE": "Santa Adélia",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.22,
    "FEB": 5.6,
    "MAR": 5.36,
    "APR": 5.48,
    "MAY": 5.01,
    "JUN": 4.89,
    "JUL": 5.08,
    "AUG": 5.83,
    "SEP": 5.42,
    "OCT": 5.47,
    "NOV": 5.36,
    "DEC": 5.42
  },
  {
    "LONG": -48.79,
    "LAT": -21.19,
    "CIDADE": "Ariranha",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.25,
    "FEB": 5.68,
    "MAR": 5.37,
    "APR": 5.48,
    "MAY": 5.02,
    "JUN": 4.9,
    "JUL": 5.09,
    "AUG": 5.81,
    "SEP": 5.42,
    "OCT": 5.46,
    "NOV": 5.36,
    "DEC": 5.43
  },
  {
    "LONG": -4.86,
    "LAT": -2.12,
    "CIDADE": "Vista Alegre do Alto",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.23,
    "FEB": 5.69,
    "MAR": 5.39,
    "APR": 5.46,
    "MAY": 5.04,
    "JUN": 4.94,
    "JUL": 5.13,
    "AUG": 5.81,
    "SEP": 5.44,
    "OCT": 5.48,
    "NOV": 5.39,
    "DEC": 5.42
  },
  {
    "LONG": -4.82,
    "LAT": -2.12,
    "CIDADE": "Barrinha",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.19,
    "FEB": 5.75,
    "MAR": 5.36,
    "APR": 5.5,
    "MAY": 5.07,
    "JUN": 4.88,
    "JUL": 5.12,
    "AUG": 5.78,
    "SEP": 5.32,
    "OCT": 5.42,
    "NOV": 5.33,
    "DEC": 5.38
  },
  {
    "LONG": -4.8,
    "LAT": -21.23,
    "CIDADE": "Dumont",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.17,
    "FEB": 5.7,
    "MAR": 5.35,
    "APR": 5.48,
    "MAY": 5.03,
    "JUN": 4.95,
    "JUL": 5.15,
    "AUG": 5.79,
    "SEP": 5.33,
    "OCT": 5.39,
    "NOV": 5.28,
    "DEC": 5.43
  },
  {
    "LONG": -47.81,
    "LAT": -21.17,
    "CIDADE": "Ribeirão Preto",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.09,
    "FEB": 5.66,
    "MAR": 5.34,
    "APR": 5.45,
    "MAY": 5.02,
    "JUN": 4.98,
    "JUL": 5.15,
    "AUG": 5.77,
    "SEP": 5.34,
    "OCT": 5.38,
    "NOV": 5.24,
    "DEC": 5.39
  },
  {
    "LONG": -47.6,
    "LAT": -21.2,
    "CIDADE": "Serrana",
    "UF": "SÃO PAULO",
    "anual": 5.3,
    "JAN": 5.06,
    "FEB": 5.55,
    "MAR": 5.29,
    "APR": 5.46,
    "MAY": 5.03,
    "JUN": 4.96,
    "JUL": 5.12,
    "AUG": 5.86,
    "SEP": 5.34,
    "OCT": 5.4,
    "NOV": 5.18,
    "DEC": 5.31
  },
  {
    "LONG": -5.15,
    "LAT": -21.1,
    "CIDADE": "Nova Independência",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.3,
    "FEB": 5.66,
    "MAR": 5.61,
    "APR": 5.54,
    "MAY": 4.99,
    "JUN": 4.78,
    "JUL": 4.95,
    "AUG": 5.74,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.5,
    "DEC": 5.61
  },
  {
    "LONG": -51.1,
    "LAT": -21.13,
    "CIDADE": "Mirandópolis",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.31,
    "FEB": 5.68,
    "MAR": 5.62,
    "APR": 5.54,
    "MAY": 5,
    "JUN": 4.82,
    "JUL": 4.94,
    "AUG": 5.8,
    "SEP": 5.31,
    "OCT": 5.46,
    "NOV": 5.55,
    "DEC": 5.56
  },
  {
    "LONG": -50.15,
    "LAT": -21.07,
    "CIDADE": "Buritama",
    "UF": "SÃO PAULO",
    "anual": 5.33,
    "JAN": 5.23,
    "FEB": 5.62,
    "MAR": 5.52,
    "APR": 5.55,
    "MAY": 4.9,
    "JUN": 4.7,
    "JUL": 4.94,
    "AUG": 5.66,
    "SEP": 5.26,
    "OCT": 5.47,
    "NOV": 5.5,
    "DEC": 5.58
  },
  {
    "LONG": -50.05,
    "LAT": -2.11,
    "CIDADE": "Zacarias",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.32,
    "FEB": 5.66,
    "MAR": 5.54,
    "APR": 5.55,
    "MAY": 4.94,
    "JUN": 4.72,
    "JUL": 4.98,
    "AUG": 5.69,
    "SEP": 5.3,
    "OCT": 5.46,
    "NOV": 5.51,
    "DEC": 5.54
  },
  {
    "LONG": -49.69,
    "LAT": -21.06,
    "CIDADE": "José Bonifácio",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.32,
    "FEB": 5.61,
    "MAR": 5.44,
    "APR": 5.5,
    "MAY": 4.95,
    "JUN": 4.83,
    "JUL": 5.07,
    "AUG": 5.78,
    "SEP": 5.38,
    "OCT": 5.47,
    "NOV": 5.43,
    "DEC": 5.46
  },
  {
    "LONG": -49.25,
    "LAT": -21.08,
    "CIDADE": "Ibirá",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.26,
    "FEB": 5.67,
    "MAR": 5.41,
    "APR": 5.49,
    "MAY": 5,
    "JUN": 4.84,
    "JUL": 5.07,
    "AUG": 5.81,
    "SEP": 5.43,
    "OCT": 5.48,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -4.91,
    "LAT": -21.05,
    "CIDADE": "Catiguá",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.18,
    "FEB": 5.63,
    "MAR": 5.38,
    "APR": 5.44,
    "MAY": 5,
    "JUN": 4.88,
    "JUL": 5.08,
    "AUG": 5.85,
    "SEP": 5.4,
    "OCT": 5.46,
    "NOV": 5.37,
    "DEC": 5.42
  },
  {
    "LONG": -48.98,
    "LAT": -21.13,
    "CIDADE": "Catanduva",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.21,
    "FEB": 5.59,
    "MAR": 5.34,
    "APR": 5.43,
    "MAY": 4.99,
    "JUN": 4.91,
    "JUL": 5.09,
    "AUG": 5.88,
    "SEP": 5.43,
    "OCT": 5.45,
    "NOV": 5.37,
    "DEC": 5.44
  },
  {
    "LONG": -48.8,
    "LAT": -21.09,
    "CIDADE": "Palmares Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.26,
    "FEB": 5.64,
    "MAR": 5.37,
    "APR": 5.46,
    "MAY": 5.01,
    "JUN": 4.93,
    "JUL": 5.11,
    "AUG": 5.86,
    "SEP": 5.45,
    "OCT": 5.48,
    "NOV": 5.37,
    "DEC": 5.43
  },
  {
    "LONG": -48.66,
    "LAT": -21.09,
    "CIDADE": "Pirangi",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.29,
    "FEB": 5.7,
    "MAR": 5.35,
    "APR": 5.42,
    "MAY": 5.03,
    "JUN": 4.96,
    "JUL": 5.14,
    "AUG": 5.81,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.37,
    "DEC": 5.41
  },
  {
    "LONG": -48.51,
    "LAT": -21.14,
    "CIDADE": "Taiaçu",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.25,
    "FEB": 5.73,
    "MAR": 5.38,
    "APR": 5.45,
    "MAY": 5.04,
    "JUN": 4.95,
    "JUL": 5.15,
    "AUG": 5.8,
    "SEP": 5.42,
    "OCT": 5.46,
    "NOV": 5.38,
    "DEC": 5.43
  },
  {
    "LONG": -4.84,
    "LAT": -21.07,
    "CIDADE": "Taquaral",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.2,
    "FEB": 5.71,
    "MAR": 5.39,
    "APR": 5.47,
    "MAY": 5.04,
    "JUN": 4.94,
    "JUL": 5.15,
    "AUG": 5.83,
    "SEP": 5.41,
    "OCT": 5.45,
    "NOV": 5.39,
    "DEC": 5.44
  },
  {
    "LONG": -4.85,
    "LAT": -2.11,
    "CIDADE": "Taiúva",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.2,
    "FEB": 5.71,
    "MAR": 5.39,
    "APR": 5.47,
    "MAY": 5.04,
    "JUN": 4.94,
    "JUL": 5.15,
    "AUG": 5.83,
    "SEP": 5.41,
    "OCT": 5.45,
    "NOV": 5.39,
    "DEC": 5.44
  },
  {
    "LONG": -47.99,
    "LAT": -21.13,
    "CIDADE": "Sertãozinho",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.19,
    "FEB": 5.7,
    "MAR": 5.38,
    "APR": 5.51,
    "MAY": 5.08,
    "JUN": 4.97,
    "JUL": 5.15,
    "AUG": 5.85,
    "SEP": 5.33,
    "OCT": 5.41,
    "NOV": 5.32,
    "DEC": 5.43
  },
  {
    "LONG": -47.15,
    "LAT": -21.09,
    "CIDADE": "Santo Antônio da Alegria",
    "UF": "SÃO PAULO",
    "anual": 5.26,
    "JAN": 5,
    "FEB": 5.46,
    "MAR": 5.15,
    "APR": 5.37,
    "MAY": 5.07,
    "JUN": 5,
    "JUL": 5.19,
    "AUG": 5.89,
    "SEP": 5.39,
    "OCT": 5.39,
    "NOV": 5.13,
    "DEC": 5.14
  },
  {
    "LONG": -51.21,
    "LAT": -21.03,
    "CIDADE": "Guaraçaí",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.31,
    "FEB": 5.65,
    "MAR": 5.61,
    "APR": 5.59,
    "MAY": 5,
    "JUN": 4.83,
    "JUL": 4.95,
    "AUG": 5.77,
    "SEP": 5.32,
    "OCT": 5.5,
    "NOV": 5.54,
    "DEC": 5.55
  },
  {
    "LONG": -51.28,
    "LAT": -20.99,
    "CIDADE": "Murutinga do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.31,
    "FEB": 5.65,
    "MAR": 5.61,
    "APR": 5.59,
    "MAY": 5,
    "JUN": 4.83,
    "JUL": 4.95,
    "AUG": 5.77,
    "SEP": 5.32,
    "OCT": 5.5,
    "NOV": 5.54,
    "DEC": 5.55
  },
  {
    "LONG": -50.23,
    "LAT": -20.97,
    "CIDADE": "Lourdes",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.28,
    "FEB": 5.63,
    "MAR": 5.49,
    "APR": 5.57,
    "MAY": 4.98,
    "JUN": 4.76,
    "JUL": 5.01,
    "AUG": 5.75,
    "SEP": 5.27,
    "OCT": 5.49,
    "NOV": 5.49,
    "DEC": 5.56
  },
  {
    "LONG": -49.93,
    "LAT": -2.1,
    "CIDADE": "Planalto",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.28,
    "FEB": 5.65,
    "MAR": 5.46,
    "APR": 5.57,
    "MAY": 4.99,
    "JUN": 4.85,
    "JUL": 5.02,
    "AUG": 5.77,
    "SEP": 5.35,
    "OCT": 5.47,
    "NOV": 5.48,
    "DEC": 5.51
  },
  {
    "LONG": -49.5,
    "LAT": -21.02,
    "CIDADE": "Nova Aliança",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.2,
    "FEB": 5.64,
    "MAR": 5.4,
    "APR": 5.49,
    "MAY": 4.98,
    "JUN": 4.88,
    "JUL": 5.12,
    "AUG": 5.83,
    "SEP": 5.41,
    "OCT": 5.46,
    "NOV": 5.42,
    "DEC": 5.46
  },
  {
    "LONG": -49.38,
    "LAT": -2.1,
    "CIDADE": "Potirendaba",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.23,
    "FEB": 5.59,
    "MAR": 5.37,
    "APR": 5.48,
    "MAY": 5.01,
    "JUN": 4.9,
    "JUL": 5.09,
    "AUG": 5.84,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.39,
    "DEC": 5.41
  },
  {
    "LONG": -49.17,
    "LAT": -2.1,
    "CIDADE": "Uchoa",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.23,
    "FEB": 5.62,
    "MAR": 5.38,
    "APR": 5.48,
    "MAY": 5.01,
    "JUN": 4.92,
    "JUL": 5.11,
    "AUG": 5.83,
    "SEP": 5.43,
    "OCT": 5.45,
    "NOV": 5.38,
    "DEC": 5.43
  },
  {
    "LONG": -49.03,
    "LAT": -20.96,
    "CIDADE": "Tabapuã",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.24,
    "FEB": 5.63,
    "MAR": 5.39,
    "APR": 5.47,
    "MAY": 5.02,
    "JUN": 4.93,
    "JUL": 5.12,
    "AUG": 5.86,
    "SEP": 5.44,
    "OCT": 5.46,
    "NOV": 5.38,
    "DEC": 5.43
  },
  {
    "LONG": -48.92,
    "LAT": -2.1,
    "CIDADE": "Novais",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.27,
    "FEB": 5.61,
    "MAR": 5.37,
    "APR": 5.44,
    "MAY": 5.01,
    "JUN": 4.95,
    "JUL": 5.15,
    "AUG": 5.87,
    "SEP": 5.45,
    "OCT": 5.45,
    "NOV": 5.4,
    "DEC": 5.42
  },
  {
    "LONG": -48.83,
    "LAT": -20.98,
    "CIDADE": "Embaúba",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.25,
    "FEB": 5.65,
    "MAR": 5.37,
    "APR": 5.42,
    "MAY": 5,
    "JUN": 4.95,
    "JUL": 5.18,
    "AUG": 5.87,
    "SEP": 5.44,
    "OCT": 5.46,
    "NOV": 5.39,
    "DEC": 5.42
  },
  {
    "LONG": -48.78,
    "LAT": -21.02,
    "CIDADE": "Paraíso",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.23,
    "FEB": 5.67,
    "MAR": 5.38,
    "APR": 5.42,
    "MAY": 5.02,
    "JUN": 4.95,
    "JUL": 5.19,
    "AUG": 5.87,
    "SEP": 5.42,
    "OCT": 5.45,
    "NOV": 5.37,
    "DEC": 5.43
  },
  {
    "LONG": -48.22,
    "LAT": -21.01,
    "CIDADE": "Pitangueiras",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.22,
    "FEB": 5.78,
    "MAR": 5.39,
    "APR": 5.46,
    "MAY": 5.09,
    "JUN": 4.97,
    "JUL": 5.18,
    "AUG": 5.81,
    "SEP": 5.34,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.47
  },
  {
    "LONG": -48.04,
    "LAT": -2.1,
    "CIDADE": "Pontal",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.24,
    "FEB": 5.76,
    "MAR": 5.43,
    "APR": 5.57,
    "MAY": 5.08,
    "JUN": 4.93,
    "JUL": 5.22,
    "AUG": 5.86,
    "SEP": 5.33,
    "OCT": 5.46,
    "NOV": 5.36,
    "DEC": 5.49
  },
  {
    "LONG": -4.78,
    "LAT": -21.02,
    "CIDADE": "Jardinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.12,
    "FEB": 5.62,
    "MAR": 5.32,
    "APR": 5.53,
    "MAY": 5.08,
    "JUN": 5.01,
    "JUL": 5.19,
    "AUG": 5.87,
    "SEP": 5.39,
    "OCT": 5.39,
    "NOV": 5.21,
    "DEC": 5.31
  },
  {
    "LONG": -47.66,
    "LAT": -2.1,
    "CIDADE": "Brodowski",
    "UF": "SÃO PAULO",
    "anual": 5.29,
    "JAN": 5.08,
    "FEB": 5.53,
    "MAR": 5.24,
    "APR": 5.45,
    "MAY": 5.04,
    "JUN": 4.99,
    "JUL": 5.17,
    "AUG": 5.86,
    "SEP": 5.36,
    "OCT": 5.34,
    "NOV": 5.16,
    "DEC": 5.25
  },
  {
    "LONG": -47.37,
    "LAT": -21.02,
    "CIDADE": "Altinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.28,
    "JAN": 5.02,
    "FEB": 5.53,
    "MAR": 5.12,
    "APR": 5.42,
    "MAY": 5.09,
    "JUN": 5.03,
    "JUL": 5.23,
    "AUG": 5.96,
    "SEP": 5.42,
    "OCT": 5.34,
    "NOV": 5.08,
    "DEC": 5.16
  },
  {
    "LONG": -51.49,
    "LAT": -20.87,
    "CIDADE": "Castilho",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.38,
    "FEB": 5.68,
    "MAR": 5.58,
    "APR": 5.54,
    "MAY": 4.99,
    "JUN": 4.81,
    "JUL": 4.95,
    "AUG": 5.79,
    "SEP": 5.31,
    "OCT": 5.5,
    "NOV": 5.49,
    "DEC": 5.65
  },
  {
    "LONG": -5.14,
    "LAT": -20.9,
    "CIDADE": "Andradina",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.37,
    "FEB": 5.65,
    "MAR": 5.58,
    "APR": 5.57,
    "MAY": 5.01,
    "JUN": 4.82,
    "JUL": 4.96,
    "AUG": 5.82,
    "SEP": 5.31,
    "OCT": 5.5,
    "NOV": 5.52,
    "DEC": 5.63
  },
  {
    "LONG": -50.5,
    "LAT": -2.09,
    "CIDADE": "Santo Antônio do Aracanguá",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.31,
    "FEB": 5.62,
    "MAR": 5.55,
    "APR": 5.56,
    "MAY": 5,
    "JUN": 4.8,
    "JUL": 5.01,
    "AUG": 5.79,
    "SEP": 5.28,
    "OCT": 5.5,
    "NOV": 5.48,
    "DEC": 5.55
  },
  {
    "LONG": -50.26,
    "LAT": -20.86,
    "CIDADE": "Nova Luzitânia",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.28,
    "FEB": 5.64,
    "MAR": 5.49,
    "APR": 5.59,
    "MAY": 4.98,
    "JUN": 4.8,
    "JUL": 5.03,
    "AUG": 5.78,
    "SEP": 5.28,
    "OCT": 5.49,
    "NOV": 5.49,
    "DEC": 5.54
  },
  {
    "LONG": -50.11,
    "LAT": -20.95,
    "CIDADE": "Turiúba",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.26,
    "FEB": 5.63,
    "MAR": 5.48,
    "APR": 5.62,
    "MAY": 4.99,
    "JUN": 4.85,
    "JUL": 5.04,
    "AUG": 5.81,
    "SEP": 5.31,
    "OCT": 5.52,
    "NOV": 5.51,
    "DEC": 5.51
  },
  {
    "LONG": -49.9,
    "LAT": -2.09,
    "CIDADE": "União Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.27,
    "FEB": 5.62,
    "MAR": 5.45,
    "APR": 5.52,
    "MAY": 5.01,
    "JUN": 4.87,
    "JUL": 5.09,
    "AUG": 5.87,
    "SEP": 5.38,
    "OCT": 5.47,
    "NOV": 5.43,
    "DEC": 5.48
  },
  {
    "LONG": -49.78,
    "LAT": -2.09,
    "CIDADE": "Nipoã",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.27,
    "FEB": 5.63,
    "MAR": 5.45,
    "APR": 5.52,
    "MAY": 4.99,
    "JUN": 4.88,
    "JUL": 5.11,
    "AUG": 5.86,
    "SEP": 5.41,
    "OCT": 5.47,
    "NOV": 5.44,
    "DEC": 5.46
  },
  {
    "LONG": -49.58,
    "LAT": -20.88,
    "CIDADE": "Jaci",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.25,
    "FEB": 5.6,
    "MAR": 5.39,
    "APR": 5.51,
    "MAY": 4.97,
    "JUN": 4.89,
    "JUL": 5.14,
    "AUG": 5.88,
    "SEP": 5.41,
    "OCT": 5.48,
    "NOV": 5.45,
    "DEC": 5.47
  },
  {
    "LONG": -49.44,
    "LAT": -2.09,
    "CIDADE": "Bady Bassitt",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.23,
    "FEB": 5.6,
    "MAR": 5.33,
    "APR": 5.46,
    "MAY": 4.99,
    "JUN": 4.88,
    "JUL": 5.14,
    "AUG": 5.84,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.4,
    "DEC": 5.44
  },
  {
    "LONG": -49.27,
    "LAT": -2.09,
    "CIDADE": "Cedral",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.21,
    "FEB": 5.59,
    "MAR": 5.38,
    "APR": 5.49,
    "MAY": 5,
    "JUN": 4.94,
    "JUL": 5.14,
    "AUG": 5.89,
    "SEP": 5.45,
    "OCT": 5.46,
    "NOV": 5.42,
    "DEC": 5.42
  },
  {
    "LONG": -48.81,
    "LAT": -20.88,
    "CIDADE": "Cajobi",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.26,
    "FEB": 5.66,
    "MAR": 5.39,
    "APR": 5.48,
    "MAY": 5.03,
    "JUN": 4.96,
    "JUL": 5.17,
    "AUG": 5.87,
    "SEP": 5.42,
    "OCT": 5.44,
    "NOV": 5.42,
    "DEC": 5.44
  },
  {
    "LONG": -48.64,
    "LAT": -2.09,
    "CIDADE": "Monte Azul Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.32,
    "FEB": 5.74,
    "MAR": 5.38,
    "APR": 5.51,
    "MAY": 5.08,
    "JUN": 4.96,
    "JUL": 5.17,
    "AUG": 5.85,
    "SEP": 5.4,
    "OCT": 5.42,
    "NOV": 5.41,
    "DEC": 5.41
  },
  {
    "LONG": -48.48,
    "LAT": -20.95,
    "CIDADE": "Bebedouro",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.26,
    "FEB": 5.78,
    "MAR": 5.37,
    "APR": 5.44,
    "MAY": 5.1,
    "JUN": 4.92,
    "JUL": 5.18,
    "AUG": 5.83,
    "SEP": 5.39,
    "OCT": 5.44,
    "NOV": 5.32,
    "DEC": 5.4
  },
  {
    "LONG": -48.29,
    "LAT": -20.87,
    "CIDADE": "Viradouro",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.26,
    "FEB": 5.8,
    "MAR": 5.4,
    "APR": 5.49,
    "MAY": 5.11,
    "JUN": 5,
    "JUL": 5.2,
    "AUG": 5.87,
    "SEP": 5.36,
    "OCT": 5.44,
    "NOV": 5.35,
    "DEC": 5.48
  },
  {
    "LONG": -47.59,
    "LAT": -20.89,
    "CIDADE": "Batatais",
    "UF": "SÃO PAULO",
    "anual": 5.32,
    "JAN": 5.09,
    "FEB": 5.56,
    "MAR": 5.21,
    "APR": 5.45,
    "MAY": 5.09,
    "JUN": 5.03,
    "JUL": 5.22,
    "AUG": 5.97,
    "SEP": 5.42,
    "OCT": 5.38,
    "NOV": 5.16,
    "DEC": 5.24
  },
  {
    "LONG": -50.34,
    "LAT": -2.08,
    "CIDADE": "Nova Castilho",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.32,
    "FEB": 5.67,
    "MAR": 5.51,
    "APR": 5.54,
    "MAY": 5.01,
    "JUN": 4.86,
    "JUL": 5.06,
    "AUG": 5.83,
    "SEP": 5.33,
    "OCT": 5.49,
    "NOV": 5.47,
    "DEC": 5.54
  },
  {
    "LONG": -50.19,
    "LAT": -2.08,
    "CIDADE": "Gastão Vidigal",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.28,
    "FEB": 5.67,
    "MAR": 5.47,
    "APR": 5.57,
    "MAY": 5.02,
    "JUN": 4.89,
    "JUL": 5.06,
    "AUG": 5.85,
    "SEP": 5.33,
    "OCT": 5.5,
    "NOV": 5.47,
    "DEC": 5.5
  },
  {
    "LONG": -50.09,
    "LAT": "#VALOR!",
    "CIDADE": "Monções",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.28,
    "FEB": 5.62,
    "MAR": 5.42,
    "APR": 5.55,
    "MAY": 5.04,
    "JUN": 4.87,
    "JUL": 5.11,
    "AUG": 5.86,
    "SEP": 5.34,
    "OCT": 5.49,
    "NOV": 5.46,
    "DEC": 5.46
  },
  {
    "LONG": -49.96,
    "LAT": -2.08,
    "CIDADE": "Macaubal",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.27,
    "FEB": 5.61,
    "MAR": 5.44,
    "APR": 5.56,
    "MAY": 5.02,
    "JUN": 4.88,
    "JUL": 5.13,
    "AUG": 5.88,
    "SEP": 5.37,
    "OCT": 5.49,
    "NOV": 5.44,
    "DEC": 5.46
  },
  {
    "LONG": -49.81,
    "LAT": -20.79,
    "CIDADE": "Poloni",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.23,
    "FEB": 5.61,
    "MAR": 5.43,
    "APR": 5.54,
    "MAY": 5.02,
    "JUN": 4.92,
    "JUL": 5.13,
    "AUG": 5.92,
    "SEP": 5.37,
    "OCT": 5.51,
    "NOV": 5.43,
    "DEC": 5.46
  },
  {
    "LONG": -49.71,
    "LAT": -2.08,
    "CIDADE": "Monte Aprazível",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.24,
    "FEB": 5.6,
    "MAR": 5.43,
    "APR": 5.54,
    "MAY": 5.04,
    "JUN": 4.92,
    "JUL": 5.14,
    "AUG": 5.92,
    "SEP": 5.4,
    "OCT": 5.49,
    "NOV": 5.45,
    "DEC": 5.48
  },
  {
    "LONG": -49.63,
    "LAT": -2.08,
    "CIDADE": "Neves Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.26,
    "FEB": 5.6,
    "MAR": 5.41,
    "APR": 5.52,
    "MAY": 5.05,
    "JUN": 4.9,
    "JUL": 5.13,
    "AUG": 5.88,
    "SEP": 5.4,
    "OCT": 5.49,
    "NOV": 5.46,
    "DEC": 5.47
  },
  {
    "LONG": -4.95,
    "LAT": -20.82,
    "CIDADE": "Mirassol",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.29,
    "FEB": 5.59,
    "MAR": 5.37,
    "APR": 5.51,
    "MAY": 5.01,
    "JUN": 4.91,
    "JUL": 5.15,
    "AUG": 5.89,
    "SEP": 5.41,
    "OCT": 5.49,
    "NOV": 5.44,
    "DEC": 5.45
  },
  {
    "LONG": -49.38,
    "LAT": -20.81,
    "CIDADE": "São José do Rio Preto",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.2,
    "FEB": 5.59,
    "MAR": 5.35,
    "APR": 5.46,
    "MAY": 4.99,
    "JUN": 4.93,
    "JUL": 5.15,
    "AUG": 5.91,
    "SEP": 5.45,
    "OCT": 5.49,
    "NOV": 5.4,
    "DEC": 5.43
  },
  {
    "LONG": -49.22,
    "LAT": -2.08,
    "CIDADE": "Guapiaçu",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.23,
    "FEB": 5.59,
    "MAR": 5.39,
    "APR": 5.49,
    "MAY": 5.01,
    "JUN": 4.96,
    "JUL": 5.15,
    "AUG": 5.92,
    "SEP": 5.46,
    "OCT": 5.45,
    "NOV": 5.41,
    "DEC": 5.45
  },
  {
    "LONG": -48.81,
    "LAT": -20.81,
    "CIDADE": "Severínia",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.25,
    "FEB": 5.69,
    "MAR": 5.39,
    "APR": 5.51,
    "MAY": 5.09,
    "JUN": 4.99,
    "JUL": 5.19,
    "AUG": 5.92,
    "SEP": 5.44,
    "OCT": 5.44,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -48.33,
    "LAT": -20.79,
    "CIDADE": "Terra Roxa",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.29,
    "FEB": 5.82,
    "MAR": 5.42,
    "APR": 5.47,
    "MAY": 5.14,
    "JUN": 5.01,
    "JUL": 5.22,
    "AUG": 5.9,
    "SEP": 5.36,
    "OCT": 5.44,
    "NOV": 5.34,
    "DEC": 5.46
  },
  {
    "LONG": -47.84,
    "LAT": -20.77,
    "CIDADE": "Sales Oliveira",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.15,
    "FEB": 5.61,
    "MAR": 5.24,
    "APR": 5.45,
    "MAY": 5.13,
    "JUN": 5,
    "JUL": 5.2,
    "AUG": 5.93,
    "SEP": 5.39,
    "OCT": 5.4,
    "NOV": 5.24,
    "DEC": 5.3
  },
  {
    "LONG": -50.92,
    "LAT": -20.69,
    "CIDADE": "Sud Mennucci",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.32,
    "FEB": 5.71,
    "MAR": 5.57,
    "APR": 5.5,
    "MAY": 4.98,
    "JUN": 4.74,
    "JUL": 4.95,
    "AUG": 5.75,
    "SEP": 5.27,
    "OCT": 5.51,
    "NOV": 5.51,
    "DEC": 5.61
  },
  {
    "LONG": -50.55,
    "LAT": -2.07,
    "CIDADE": "Auriflama",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.36,
    "FEB": 5.67,
    "MAR": 5.56,
    "APR": 5.53,
    "MAY": 5.04,
    "JUN": 4.9,
    "JUL": 5.08,
    "AUG": 5.81,
    "SEP": 5.4,
    "OCT": 5.5,
    "NOV": 5.47,
    "DEC": 5.54
  },
  {
    "LONG": -50.14,
    "LAT": -2.07,
    "CIDADE": "Floreal",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.47,
    "APR": 5.5,
    "MAY": 5.02,
    "JUN": 4.91,
    "JUL": 5.11,
    "AUG": 5.83,
    "SEP": 5.34,
    "OCT": 5.49,
    "NOV": 5.44,
    "DEC": 5.5
  },
  {
    "LONG": -5,
    "LAT": -2.07,
    "CIDADE": "Nhandeara",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.28,
    "FEB": 5.61,
    "MAR": 5.42,
    "APR": 5.52,
    "MAY": 5.01,
    "JUN": 4.9,
    "JUL": 5.12,
    "AUG": 5.81,
    "SEP": 5.33,
    "OCT": 5.48,
    "NOV": 5.42,
    "DEC": 5.46
  },
  {
    "LONG": -49.93,
    "LAT": -20.65,
    "CIDADE": "Sebastianópolis do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.26,
    "FEB": 5.61,
    "MAR": 5.42,
    "APR": 5.53,
    "MAY": 5.03,
    "JUN": 4.91,
    "JUL": 5.16,
    "AUG": 5.87,
    "SEP": 5.36,
    "OCT": 5.5,
    "NOV": 5.43,
    "DEC": 5.45
  },
  {
    "LONG": -49.58,
    "LAT": -2.07,
    "CIDADE": "Bálsamo",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.28,
    "FEB": 5.61,
    "MAR": 5.36,
    "APR": 5.55,
    "MAY": 5.05,
    "JUN": 4.93,
    "JUL": 5.15,
    "AUG": 5.89,
    "SEP": 5.42,
    "OCT": 5.47,
    "NOV": 5.4,
    "DEC": 5.46
  },
  {
    "LONG": -49.38,
    "LAT": -20.66,
    "CIDADE": "Ipiguá",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.22,
    "FEB": 5.61,
    "MAR": 5.37,
    "APR": 5.52,
    "MAY": 5.03,
    "JUN": 4.97,
    "JUL": 5.16,
    "AUG": 5.93,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.43,
    "DEC": 5.44
  },
  {
    "LONG": -4.89,
    "LAT": -20.74,
    "CIDADE": "Olímpia",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.23,
    "FEB": 5.72,
    "MAR": 5.4,
    "APR": 5.49,
    "MAY": 5.13,
    "JUN": 4.99,
    "JUL": 5.17,
    "AUG": 5.93,
    "SEP": 5.4,
    "OCT": 5.45,
    "NOV": 5.38,
    "DEC": 5.43
  },
  {
    "LONG": -48.54,
    "LAT": -20.71,
    "CIDADE": "Colina",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.29,
    "FEB": 5.76,
    "MAR": 5.4,
    "APR": 5.49,
    "MAY": 5.14,
    "JUN": 5.01,
    "JUL": 5.21,
    "AUG": 5.91,
    "SEP": 5.39,
    "OCT": 5.45,
    "NOV": 5.33,
    "DEC": 5.45
  },
  {
    "LONG": -48.41,
    "LAT": -20.69,
    "CIDADE": "Jaborandi",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.26,
    "FEB": 5.79,
    "MAR": 5.39,
    "APR": 5.51,
    "MAY": 5.15,
    "JUN": 5.02,
    "JUL": 5.22,
    "AUG": 5.9,
    "SEP": 5.37,
    "OCT": 5.46,
    "NOV": 5.32,
    "DEC": 5.47
  },
  {
    "LONG": -48.06,
    "LAT": -20.73,
    "CIDADE": "Morro Agudo",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.24,
    "FEB": 5.73,
    "MAR": 5.33,
    "APR": 5.47,
    "MAY": 5.12,
    "JUN": 5.01,
    "JUL": 5.18,
    "AUG": 5.91,
    "SEP": 5.39,
    "OCT": 5.41,
    "NOV": 5.28,
    "DEC": 5.44
  },
  {
    "LONG": -47.89,
    "LAT": -20.72,
    "CIDADE": "Orlândia",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.12,
    "FEB": 5.65,
    "MAR": 5.27,
    "APR": 5.45,
    "MAY": 5.14,
    "JUN": 5,
    "JUL": 5.22,
    "AUG": 5.98,
    "SEP": 5.37,
    "OCT": 5.4,
    "NOV": 5.24,
    "DEC": 5.3
  },
  {
    "LONG": -47.74,
    "LAT": -20.73,
    "CIDADE": "Nuporanga",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.13,
    "FEB": 5.66,
    "MAR": 5.21,
    "APR": 5.55,
    "MAY": 5.14,
    "JUN": 5.07,
    "JUL": 5.2,
    "AUG": 5.96,
    "SEP": 5.4,
    "OCT": 5.4,
    "NOV": 5.23,
    "DEC": 5.3
  },
  {
    "LONG": -51.51,
    "LAT": -20.64,
    "CIDADE": "Itapura",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.31,
    "FEB": 5.69,
    "MAR": 5.65,
    "APR": 5.51,
    "MAY": 4.98,
    "JUN": 4.77,
    "JUL": 4.94,
    "AUG": 5.71,
    "SEP": 5.21,
    "OCT": 5.41,
    "NOV": 5.46,
    "DEC": 5.58
  },
  {
    "LONG": -51.11,
    "LAT": -20.64,
    "CIDADE": "Pereira Barreto",
    "UF": "SÃO PAULO",
    "anual": 5.37,
    "JAN": 5.37,
    "FEB": 5.73,
    "MAR": 5.58,
    "APR": 5.54,
    "MAY": 5,
    "JUN": 4.71,
    "JUL": 4.94,
    "AUG": 5.75,
    "SEP": 5.22,
    "OCT": 5.48,
    "NOV": 5.52,
    "DEC": 5.62
  },
  {
    "LONG": -50.66,
    "LAT": "#VALOR!",
    "CIDADE": "Guzolândia",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.29,
    "FEB": 5.74,
    "MAR": 5.52,
    "APR": 5.54,
    "MAY": 5.04,
    "JUN": 4.87,
    "JUL": 5.07,
    "AUG": 5.87,
    "SEP": 5.39,
    "OCT": 5.5,
    "NOV": 5.47,
    "DEC": 5.57
  },
  {
    "LONG": -50.36,
    "LAT": -2.06,
    "CIDADE": "General Salgado",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.29,
    "FEB": 5.67,
    "MAR": 5.49,
    "APR": 5.54,
    "MAY": 5.04,
    "JUN": 4.91,
    "JUL": 5.1,
    "AUG": 5.86,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.45,
    "DEC": 5.52
  },
  {
    "LONG": -50.23,
    "LAT": -2.06,
    "CIDADE": "Magda",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.49,
    "APR": 5.51,
    "MAY": 5.02,
    "JUN": 4.9,
    "JUL": 5.1,
    "AUG": 5.83,
    "SEP": 5.4,
    "OCT": 5.46,
    "NOV": 5.43,
    "DEC": 5.5
  },
  {
    "LONG": -49.66,
    "LAT": -20.62,
    "CIDADE": "Tanabi",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.23,
    "FEB": 5.6,
    "MAR": 5.38,
    "APR": 5.57,
    "MAY": 5.1,
    "JUN": 4.92,
    "JUL": 5.18,
    "AUG": 5.91,
    "SEP": 5.44,
    "OCT": 5.47,
    "NOV": 5.38,
    "DEC": 5.44
  },
  {
    "LONG": -49.46,
    "LAT": -2.06,
    "CIDADE": "Mirassolândia",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.21,
    "FEB": 5.62,
    "MAR": 5.36,
    "APR": 5.58,
    "MAY": 5.07,
    "JUN": 4.98,
    "JUL": 5.17,
    "AUG": 5.92,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.42,
    "DEC": 5.43
  },
  {
    "LONG": -49.3,
    "LAT": -20.61,
    "CIDADE": "Onda Verde",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.22,
    "FEB": 5.65,
    "MAR": 5.38,
    "APR": 5.57,
    "MAY": 5.05,
    "JUN": 4.97,
    "JUL": 5.18,
    "AUG": 5.87,
    "SEP": 5.42,
    "OCT": 5.45,
    "NOV": 5.4,
    "DEC": 5.41
  },
  {
    "LONG": -48.57,
    "LAT": -20.55,
    "CIDADE": "Barretos",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.26,
    "FEB": 5.76,
    "MAR": 5.41,
    "APR": 5.51,
    "MAY": 5.14,
    "JUN": 5.01,
    "JUL": 5.21,
    "AUG": 5.93,
    "SEP": 5.41,
    "OCT": 5.45,
    "NOV": 5.3,
    "DEC": 5.46
  },
  {
    "LONG": -47.86,
    "LAT": -20.58,
    "CIDADE": "São Joaquim da Barra",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.19,
    "FEB": 5.7,
    "MAR": 5.3,
    "APR": 5.49,
    "MAY": 5.14,
    "JUN": 5.03,
    "JUL": 5.28,
    "AUG": 6,
    "SEP": 5.44,
    "OCT": 5.42,
    "NOV": 5.23,
    "DEC": 5.36
  },
  {
    "LONG": -47.64,
    "LAT": -2.06,
    "CIDADE": "São José da Bela Vista",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.11,
    "FEB": 5.61,
    "MAR": 5.19,
    "APR": 5.51,
    "MAY": 5.15,
    "JUN": 5.05,
    "JUL": 5.24,
    "AUG": 6.07,
    "SEP": 5.43,
    "OCT": 5.37,
    "NOV": 5.18,
    "DEC": 5.26
  },
  {
    "LONG": -47.48,
    "LAT": -20.61,
    "CIDADE": "Restinga",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.01,
    "FEB": 5.5,
    "MAR": 5.15,
    "APR": 5.45,
    "MAY": 5.15,
    "JUN": 5.11,
    "JUL": 5.29,
    "AUG": 6.08,
    "SEP": 5.47,
    "OCT": 5.34,
    "NOV": 5.09,
    "DEC": 5.13
  },
  {
    "LONG": -47.22,
    "LAT": -20.64,
    "CIDADE": "Itirapuã",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.01,
    "FEB": 5.53,
    "MAR": 5.13,
    "APR": 5.45,
    "MAY": 5.15,
    "JUN": 5.11,
    "JUL": 5.32,
    "AUG": 6.1,
    "SEP": 5.47,
    "OCT": 5.34,
    "NOV": 5.09,
    "DEC": 5.07
  },
  {
    "LONG": -47.28,
    "LAT": -20.64,
    "CIDADE": "Patrocínio Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.31,
    "JAN": 5.01,
    "FEB": 5.53,
    "MAR": 5.13,
    "APR": 5.45,
    "MAY": 5.15,
    "JUN": 5.11,
    "JUL": 5.32,
    "AUG": 6.1,
    "SEP": 5.47,
    "OCT": 5.34,
    "NOV": 5.09,
    "DEC": 5.07
  },
  {
    "LONG": -51.03,
    "LAT": -20.5,
    "CIDADE": "Suzanápolis",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.36,
    "FEB": 5.77,
    "MAR": 5.59,
    "APR": 5.55,
    "MAY": 5.06,
    "JUN": 4.83,
    "JUL": 5.01,
    "AUG": 5.84,
    "SEP": 5.27,
    "OCT": 5.5,
    "NOV": 5.51,
    "DEC": 5.6
  },
  {
    "LONG": -50.61,
    "LAT": -20.46,
    "CIDADE": "Dirce Reis",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.26,
    "FEB": 5.72,
    "MAR": 5.51,
    "APR": 5.47,
    "MAY": 5.04,
    "JUN": 4.91,
    "JUL": 5.11,
    "AUG": 5.89,
    "SEP": 5.41,
    "OCT": 5.52,
    "NOV": 5.46,
    "DEC": 5.6
  },
  {
    "LONG": -50.36,
    "LAT": -20.51,
    "CIDADE": "São João de Iracema",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.27,
    "FEB": 5.66,
    "MAR": 5.47,
    "APR": 5.53,
    "MAY": 5.05,
    "JUN": 4.93,
    "JUL": 5.13,
    "AUG": 5.85,
    "SEP": 5.44,
    "OCT": 5.48,
    "NOV": 5.42,
    "DEC": 5.51
  },
  {
    "LONG": -49.78,
    "LAT": -2.05,
    "CIDADE": "Cosmorama",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.29,
    "FEB": 5.62,
    "MAR": 5.44,
    "APR": 5.57,
    "MAY": 5.09,
    "JUN": 4.96,
    "JUL": 5.17,
    "AUG": 5.9,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.43,
    "DEC": 5.45
  },
  {
    "LONG": -49.31,
    "LAT": -20.53,
    "CIDADE": "Nova Granada",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.21,
    "FEB": 5.62,
    "MAR": 5.35,
    "APR": 5.58,
    "MAY": 5.1,
    "JUN": 4.95,
    "JUL": 5.2,
    "AUG": 5.92,
    "SEP": 5.43,
    "OCT": 5.46,
    "NOV": 5.42,
    "DEC": 5.42
  },
  {
    "LONG": -49.06,
    "LAT": -2.05,
    "CIDADE": "Altair",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.25,
    "FEB": 5.76,
    "MAR": 5.38,
    "APR": 5.57,
    "MAY": 5.12,
    "JUN": 4.96,
    "JUL": 5.17,
    "AUG": 5.95,
    "SEP": 5.42,
    "OCT": 5.44,
    "NOV": 5.34,
    "DEC": 5.48
  },
  {
    "LONG": -48.94,
    "LAT": -2.05,
    "CIDADE": "Guaraci",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.26,
    "FEB": 5.84,
    "MAR": 5.44,
    "APR": 5.57,
    "MAY": 5.13,
    "JUN": 4.97,
    "JUL": 5.18,
    "AUG": 5.94,
    "SEP": 5.44,
    "OCT": 5.49,
    "NOV": 5.38,
    "DEC": 5.5
  },
  {
    "LONG": -47.59,
    "LAT": -20.46,
    "CIDADE": "Ribeirão Corrente",
    "UF": "SÃO PAULO",
    "anual": 5.35,
    "JAN": 5.05,
    "FEB": 5.54,
    "MAR": 5.15,
    "APR": 5.49,
    "MAY": 5.18,
    "JUN": 5.15,
    "JUL": 5.33,
    "AUG": 6.11,
    "SEP": 5.47,
    "OCT": 5.34,
    "NOV": 5.17,
    "DEC": 5.23
  },
  {
    "LONG": -47.4,
    "LAT": -20.54,
    "CIDADE": "Franca",
    "UF": "SÃO PAULO",
    "anual": 5.34,
    "JAN": 5.04,
    "FEB": 5.53,
    "MAR": 5.17,
    "APR": 5.47,
    "MAY": 5.18,
    "JUN": 5.16,
    "JUL": 5.31,
    "AUG": 6.08,
    "SEP": 5.48,
    "OCT": 5.36,
    "NOV": 5.12,
    "DEC": 5.18
  },
  {
    "LONG": -5.13,
    "LAT": -20.43,
    "CIDADE": "Ilha Solteira",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.29,
    "FEB": 5.75,
    "MAR": 5.67,
    "APR": 5.59,
    "MAY": 5.05,
    "JUN": 4.82,
    "JUL": 4.97,
    "AUG": 5.78,
    "SEP": 5.21,
    "OCT": 5.46,
    "NOV": 5.47,
    "DEC": 5.62
  },
  {
    "LONG": -50.95,
    "LAT": -2.04,
    "CIDADE": "Nova Canaã Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.31,
    "FEB": 5.76,
    "MAR": 5.59,
    "APR": 5.6,
    "MAY": 5.1,
    "JUN": 4.83,
    "JUL": 5.07,
    "AUG": 5.87,
    "SEP": 5.33,
    "OCT": 5.54,
    "NOV": 5.52,
    "DEC": 5.65
  },
  {
    "LONG": -50.82,
    "LAT": -2.04,
    "CIDADE": "Marinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.7,
    "MAR": 5.53,
    "APR": 5.5,
    "MAY": 5.08,
    "JUN": 4.89,
    "JUL": 5.08,
    "AUG": 5.88,
    "SEP": 5.39,
    "OCT": 5.55,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -50.88,
    "LAT": -20.45,
    "CIDADE": "Aparecida d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.7,
    "MAR": 5.53,
    "APR": 5.5,
    "MAY": 5.08,
    "JUN": 4.89,
    "JUL": 5.08,
    "AUG": 5.88,
    "SEP": 5.39,
    "OCT": 5.55,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -50.7,
    "LAT": -20.35,
    "CIDADE": "São Francisco",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.69,
    "MAR": 5.52,
    "APR": 5.47,
    "MAY": 5.08,
    "JUN": 4.94,
    "JUL": 5.11,
    "AUG": 5.9,
    "SEP": 5.43,
    "OCT": 5.56,
    "NOV": 5.48,
    "DEC": 5.6
  },
  {
    "LONG": -50.76,
    "LAT": -20.42,
    "CIDADE": "Palmeira d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.69,
    "MAR": 5.52,
    "APR": 5.47,
    "MAY": 5.08,
    "JUN": 4.94,
    "JUL": 5.11,
    "AUG": 5.9,
    "SEP": 5.43,
    "OCT": 5.56,
    "NOV": 5.48,
    "DEC": 5.6
  },
  {
    "LONG": -50.52,
    "LAT": -2.04,
    "CIDADE": "Pontalinda",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.25,
    "FEB": 5.64,
    "MAR": 5.48,
    "APR": 5.51,
    "MAY": 5.05,
    "JUN": 4.93,
    "JUL": 5.09,
    "AUG": 5.86,
    "SEP": 5.44,
    "OCT": 5.52,
    "NOV": 5.47,
    "DEC": 5.57
  },
  {
    "LONG": -50.38,
    "LAT": -20.39,
    "CIDADE": "São João das Duas Pontes",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.3,
    "FEB": 5.68,
    "MAR": 5.43,
    "APR": 5.51,
    "MAY": 5.07,
    "JUN": 4.95,
    "JUL": 5.12,
    "AUG": 5.89,
    "SEP": 5.45,
    "OCT": 5.49,
    "NOV": 5.46,
    "DEC": 5.54
  },
  {
    "LONG": -50.09,
    "LAT": -20.42,
    "CIDADE": "Valentim Gentil",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.3,
    "FEB": 5.64,
    "MAR": 5.44,
    "APR": 5.54,
    "MAY": 5.06,
    "JUN": 4.91,
    "JUL": 5.18,
    "AUG": 5.86,
    "SEP": 5.41,
    "OCT": 5.46,
    "NOV": 5.42,
    "DEC": 5.48
  },
  {
    "LONG": -49.98,
    "LAT": -20.42,
    "CIDADE": "Votuporanga",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.32,
    "FEB": 5.64,
    "MAR": 5.45,
    "APR": 5.55,
    "MAY": 5.08,
    "JUN": 4.96,
    "JUL": 5.19,
    "AUG": 5.87,
    "SEP": 5.43,
    "OCT": 5.47,
    "NOV": 5.45,
    "DEC": 5.47
  },
  {
    "LONG": -49.43,
    "LAT": -20.39,
    "CIDADE": "Palestina",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.18,
    "FEB": 5.58,
    "MAR": 5.37,
    "APR": 5.65,
    "MAY": 5.1,
    "JUN": 4.94,
    "JUL": 5.21,
    "AUG": 5.95,
    "SEP": 5.41,
    "OCT": 5.45,
    "NOV": 5.38,
    "DEC": 5.44
  },
  {
    "LONG": -48.01,
    "LAT": -20.44,
    "CIDADE": "Ipuã",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.28,
    "FEB": 5.8,
    "MAR": 5.32,
    "APR": 5.61,
    "MAY": 5.21,
    "JUN": 5.08,
    "JUL": 5.31,
    "AUG": 6.03,
    "SEP": 5.42,
    "OCT": 5.45,
    "NOV": 5.27,
    "DEC": 5.43
  },
  {
    "LONG": -4.78,
    "LAT": -20.43,
    "CIDADE": "Guará",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.23,
    "FEB": 5.77,
    "MAR": 5.33,
    "APR": 5.58,
    "MAY": 5.21,
    "JUN": 5.07,
    "JUL": 5.26,
    "AUG": 6.02,
    "SEP": 5.47,
    "OCT": 5.42,
    "NOV": 5.26,
    "DEC": 5.39
  },
  {
    "LONG": -47.42,
    "LAT": -2.04,
    "CIDADE": "Cristais Paulista",
    "UF": "SÃO PAULO",
    "anual": 5.36,
    "JAN": 5.04,
    "FEB": 5.56,
    "MAR": 5.18,
    "APR": 5.53,
    "MAY": 5.19,
    "JUN": 5.19,
    "JUL": 5.39,
    "AUG": 6.09,
    "SEP": 5.5,
    "OCT": 5.36,
    "NOV": 5.1,
    "DEC": 5.19
  },
  {
    "LONG": -50.8,
    "LAT": -2.03,
    "CIDADE": "Santana da Ponte Pensa",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.25,
    "FEB": 5.7,
    "MAR": 5.53,
    "APR": 5.52,
    "MAY": 5.1,
    "JUN": 4.95,
    "JUL": 5.13,
    "AUG": 5.9,
    "SEP": 5.4,
    "OCT": 5.53,
    "NOV": 5.45,
    "DEC": 5.57
  },
  {
    "LONG": -50.55,
    "LAT": -20.27,
    "CIDADE": "Jales",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.25,
    "FEB": 5.63,
    "MAR": 5.49,
    "APR": 5.51,
    "MAY": 5.08,
    "JUN": 4.95,
    "JUL": 5.13,
    "AUG": 5.9,
    "SEP": 5.45,
    "OCT": 5.53,
    "NOV": 5.45,
    "DEC": 5.55
  },
  {
    "LONG": -50.4,
    "LAT": -2.03,
    "CIDADE": "Estrela d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.26,
    "FEB": 5.64,
    "MAR": 5.46,
    "APR": 5.54,
    "MAY": 5.1,
    "JUN": 4.97,
    "JUL": 5.15,
    "AUG": 5.92,
    "SEP": 5.45,
    "OCT": 5.5,
    "NOV": 5.44,
    "DEC": 5.51
  },
  {
    "LONG": -50.25,
    "LAT": -2.03,
    "CIDADE": "Fernandópolis",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.29,
    "FEB": 5.68,
    "MAR": 5.42,
    "APR": 5.52,
    "MAY": 5.09,
    "JUN": 4.94,
    "JUL": 5.15,
    "AUG": 5.87,
    "SEP": 5.47,
    "OCT": 5.49,
    "NOV": 5.43,
    "DEC": 5.54
  },
  {
    "LONG": -50.18,
    "LAT": -20.35,
    "CIDADE": "Meridiano",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.25,
    "FEB": 5.65,
    "MAR": 5.42,
    "APR": 5.56,
    "MAY": 5.1,
    "JUN": 4.92,
    "JUL": 5.19,
    "AUG": 5.89,
    "SEP": 5.47,
    "OCT": 5.48,
    "NOV": 5.41,
    "DEC": 5.52
  },
  {
    "LONG": -50.02,
    "LAT": -20.3,
    "CIDADE": "Parisi",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.28,
    "FEB": 5.66,
    "MAR": 5.46,
    "APR": 5.55,
    "MAY": 5.12,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 5.89,
    "SEP": 5.45,
    "OCT": 5.49,
    "NOV": 5.41,
    "DEC": 5.48
  },
  {
    "LONG": -49.91,
    "LAT": -2.03,
    "CIDADE": "Álvares Florence",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.29,
    "FEB": 5.67,
    "MAR": 5.48,
    "APR": 5.55,
    "MAY": 5.12,
    "JUN": 4.94,
    "JUL": 5.21,
    "AUG": 5.89,
    "SEP": 5.45,
    "OCT": 5.51,
    "NOV": 5.43,
    "DEC": 5.48
  },
  {
    "LONG": -49.73,
    "LAT": -2.03,
    "CIDADE": "Américo de Campos",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.29,
    "FEB": 5.62,
    "MAR": 5.44,
    "APR": 5.57,
    "MAY": 5.13,
    "JUN": 4.94,
    "JUL": 5.19,
    "AUG": 5.91,
    "SEP": 5.43,
    "OCT": 5.51,
    "NOV": 5.39,
    "DEC": 5.47
  },
  {
    "LONG": -49.19,
    "LAT": -2.03,
    "CIDADE": "Icém",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.24,
    "FEB": 5.74,
    "MAR": 5.4,
    "APR": 5.61,
    "MAY": 5.09,
    "JUN": 4.87,
    "JUL": 5.17,
    "AUG": 5.81,
    "SEP": 5.34,
    "OCT": 5.43,
    "NOV": 5.33,
    "DEC": 5.49
  },
  {
    "LONG": -48.31,
    "LAT": -20.32,
    "CIDADE": "Guaíra",
    "UF": "SÃO PAULO",
    "anual": 5.45,
    "JAN": 5.32,
    "FEB": 5.79,
    "MAR": 5.38,
    "APR": 5.61,
    "MAY": 5.24,
    "JUN": 5.06,
    "JUL": 5.28,
    "AUG": 5.92,
    "SEP": 5.47,
    "OCT": 5.46,
    "NOV": 5.3,
    "DEC": 5.51
  },
  {
    "LONG": -47.79,
    "LAT": -20.34,
    "CIDADE": "Ituverava",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.15,
    "FEB": 5.7,
    "MAR": 5.35,
    "APR": 5.58,
    "MAY": 5.2,
    "JUN": 5.11,
    "JUL": 5.34,
    "AUG": 6.08,
    "SEP": 5.48,
    "OCT": 5.42,
    "NOV": 5.24,
    "DEC": 5.39
  },
  {
    "LONG": -47.59,
    "LAT": -2.03,
    "CIDADE": "Jeriquara",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.06,
    "FEB": 5.65,
    "MAR": 5.28,
    "APR": 5.56,
    "MAY": 5.24,
    "JUN": 5.17,
    "JUL": 5.39,
    "AUG": 6.08,
    "SEP": 5.51,
    "OCT": 5.36,
    "NOV": 5.16,
    "DEC": 5.28
  },
  {
    "LONG": -47.48,
    "LAT": -20.25,
    "CIDADE": "Pedregulho",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.08,
    "FEB": 5.6,
    "MAR": 5.23,
    "APR": 5.54,
    "MAY": 5.2,
    "JUN": 5.17,
    "JUL": 5.41,
    "AUG": 6.11,
    "SEP": 5.51,
    "OCT": 5.34,
    "NOV": 5.13,
    "DEC": 5.25
  },
  {
    "LONG": -51.01,
    "LAT": -20.18,
    "CIDADE": "Rubinéia",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.23,
    "FEB": 5.72,
    "MAR": 5.66,
    "APR": 5.61,
    "MAY": 5.09,
    "JUN": 4.86,
    "JUL": 5.03,
    "AUG": 5.79,
    "SEP": 5.23,
    "OCT": 5.47,
    "NOV": 5.5,
    "DEC": 5.61
  },
  {
    "LONG": -50.93,
    "LAT": -20.21,
    "CIDADE": "Santa Fé do Sul",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.26,
    "FEB": 5.72,
    "MAR": 5.65,
    "APR": 5.61,
    "MAY": 5.07,
    "JUN": 4.85,
    "JUL": 5.05,
    "AUG": 5.75,
    "SEP": 5.29,
    "OCT": 5.51,
    "NOV": 5.52,
    "DEC": 5.64
  },
  {
    "LONG": -50.89,
    "LAT": -2.02,
    "CIDADE": "Três Fronteiras",
    "UF": "SÃO PAULO",
    "anual": 5.44,
    "JAN": 5.24,
    "FEB": 5.71,
    "MAR": 5.57,
    "APR": 5.57,
    "MAY": 5.11,
    "JUN": 4.97,
    "JUL": 5.14,
    "AUG": 5.93,
    "SEP": 5.39,
    "OCT": 5.56,
    "NOV": 5.48,
    "DEC": 5.57
  },
  {
    "LONG": -50.73,
    "LAT": -2.02,
    "CIDADE": "Aspásia",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.23,
    "FEB": 5.71,
    "MAR": 5.54,
    "APR": 5.52,
    "MAY": 5.11,
    "JUN": 5,
    "JUL": 5.15,
    "AUG": 5.91,
    "SEP": 5.38,
    "OCT": 5.54,
    "NOV": 5.44,
    "DEC": 5.55
  },
  {
    "LONG": -50.65,
    "LAT": -20.25,
    "CIDADE": "Urânia",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.24,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 5.5,
    "MAY": 5.12,
    "JUN": 4.98,
    "JUL": 5.18,
    "AUG": 5.93,
    "SEP": 5.4,
    "OCT": 5.53,
    "NOV": 5.42,
    "DEC": 5.55
  },
  {
    "LONG": -50.69,
    "LAT": -2.02,
    "CIDADE": "Santa Salete",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.24,
    "FEB": 5.65,
    "MAR": 5.49,
    "APR": 5.5,
    "MAY": 5.12,
    "JUN": 4.98,
    "JUL": 5.18,
    "AUG": 5.93,
    "SEP": 5.4,
    "OCT": 5.53,
    "NOV": 5.42,
    "DEC": 5.55
  },
  {
    "LONG": -50.48,
    "LAT": -2.02,
    "CIDADE": "Vitória Brasil",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.25,
    "FEB": 5.62,
    "MAR": 5.49,
    "APR": 5.52,
    "MAY": 5.11,
    "JUN": 4.95,
    "JUL": 5.17,
    "AUG": 5.94,
    "SEP": 5.43,
    "OCT": 5.5,
    "NOV": 5.4,
    "DEC": 5.5
  },
  {
    "LONG": -50.11,
    "LAT": -2.02,
    "CIDADE": "Pedranópolis",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.27,
    "FEB": 5.7,
    "MAR": 5.46,
    "APR": 5.57,
    "MAY": 5.14,
    "JUN": 4.97,
    "JUL": 5.2,
    "AUG": 5.91,
    "SEP": 5.46,
    "OCT": 5.53,
    "NOV": 5.41,
    "DEC": 5.51
  },
  {
    "LONG": -4.97,
    "LAT": -20.18,
    "CIDADE": "Pontes Gestal",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.26,
    "FEB": 5.64,
    "MAR": 5.46,
    "APR": 5.55,
    "MAY": 5.11,
    "JUN": 4.93,
    "JUL": 5.19,
    "AUG": 5.92,
    "SEP": 5.45,
    "OCT": 5.5,
    "NOV": 5.37,
    "DEC": 5.5
  },
  {
    "LONG": -49.35,
    "LAT": -2.02,
    "CIDADE": "Orindiúva",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.21,
    "FEB": 5.65,
    "MAR": 5.36,
    "APR": 5.63,
    "MAY": 5.11,
    "JUN": 4.98,
    "JUL": 5.24,
    "AUG": 5.95,
    "SEP": 5.41,
    "OCT": 5.42,
    "NOV": 5.34,
    "DEC": 5.5
  },
  {
    "LONG": -48.69,
    "LAT": -20.18,
    "CIDADE": "Colômbia",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.3,
    "FEB": 5.82,
    "MAR": 5.41,
    "APR": 5.65,
    "MAY": 5.17,
    "JUN": 4.98,
    "JUL": 5.25,
    "AUG": 5.94,
    "SEP": 5.41,
    "OCT": 5.42,
    "NOV": 5.27,
    "DEC": 5.47
  },
  {
    "LONG": -48.03,
    "LAT": -20.18,
    "CIDADE": "Miguelópolis",
    "UF": "SÃO PAULO",
    "anual": 5.44,
    "JAN": 5.23,
    "FEB": 5.83,
    "MAR": 5.38,
    "APR": 5.66,
    "MAY": 5.25,
    "JUN": 5.04,
    "JUL": 5.3,
    "AUG": 5.96,
    "SEP": 5.41,
    "OCT": 5.48,
    "NOV": 5.3,
    "DEC": 5.43
  },
  {
    "LONG": "#VALOR!",
    "LAT": -20.19,
    "CIDADE": "Buritizal",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.13,
    "FEB": 5.72,
    "MAR": 5.36,
    "APR": 5.65,
    "MAY": 5.21,
    "JUN": 5.14,
    "JUL": 5.36,
    "AUG": 6.07,
    "SEP": 5.48,
    "OCT": 5.44,
    "NOV": 5.24,
    "DEC": 5.36
  },
  {
    "LONG": -50.93,
    "LAT": -2.01,
    "CIDADE": "Santa Clara d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.25,
    "FEB": 5.73,
    "MAR": 5.66,
    "APR": 5.59,
    "MAY": 5.05,
    "JUN": 4.83,
    "JUL": 5.05,
    "AUG": 5.76,
    "SEP": 5.26,
    "OCT": 5.49,
    "NOV": 5.49,
    "DEC": 5.62
  },
  {
    "LONG": -50.83,
    "LAT": -2.01,
    "CIDADE": "Santa Rita d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.26,
    "FEB": 5.73,
    "MAR": 5.6,
    "APR": 5.55,
    "MAY": 5.11,
    "JUN": 4.96,
    "JUL": 5.13,
    "AUG": 5.86,
    "SEP": 5.36,
    "OCT": 5.54,
    "NOV": 5.48,
    "DEC": 5.58
  },
  {
    "LONG": -50.51,
    "LAT": -2.01,
    "CIDADE": "Dolcinópolis",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.24,
    "FEB": 5.62,
    "MAR": 5.5,
    "APR": 5.51,
    "MAY": 5.13,
    "JUN": 5,
    "JUL": 5.16,
    "AUG": 5.95,
    "SEP": 5.42,
    "OCT": 5.52,
    "NOV": 5.39,
    "DEC": 5.53
  },
  {
    "LONG": -5.06,
    "LAT": -20.11,
    "CIDADE": "Paranapuã",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.24,
    "FEB": 5.62,
    "MAR": 5.5,
    "APR": 5.51,
    "MAY": 5.13,
    "JUN": 5,
    "JUL": 5.16,
    "AUG": 5.95,
    "SEP": 5.42,
    "OCT": 5.52,
    "NOV": 5.39,
    "DEC": 5.53
  },
  {
    "LONG": -50.48,
    "LAT": -20.05,
    "CIDADE": "Turmalina",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.24,
    "FEB": 5.62,
    "MAR": 5.48,
    "APR": 5.53,
    "MAY": 5.12,
    "JUN": 4.97,
    "JUL": 5.15,
    "AUG": 5.92,
    "SEP": 5.43,
    "OCT": 5.5,
    "NOV": 5.38,
    "DEC": 5.51
  },
  {
    "LONG": -50.34,
    "LAT": -20.08,
    "CIDADE": "Guarani d'Oeste",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.24,
    "FEB": 5.71,
    "MAR": 5.48,
    "APR": 5.57,
    "MAY": 5.13,
    "JUN": 4.98,
    "JUL": 5.16,
    "AUG": 5.9,
    "SEP": 5.46,
    "OCT": 5.52,
    "NOV": 5.38,
    "DEC": 5.5
  },
  {
    "LONG": -50.19,
    "LAT": -2.01,
    "CIDADE": "Macedônia",
    "UF": "SÃO PAULO",
    "anual": 5.45,
    "JAN": 5.3,
    "FEB": 5.74,
    "MAR": 5.5,
    "APR": 5.56,
    "MAY": 5.16,
    "JUN": 5,
    "JUL": 5.24,
    "AUG": 5.9,
    "SEP": 5.46,
    "OCT": 5.55,
    "NOV": 5.42,
    "DEC": 5.52
  },
  {
    "LONG": -49.91,
    "LAT": -2.01,
    "CIDADE": "Cardoso",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.3,
    "FEB": 5.71,
    "MAR": 5.53,
    "APR": 5.55,
    "MAY": 5.13,
    "JUN": 4.91,
    "JUL": 5.18,
    "AUG": 5.84,
    "SEP": 5.45,
    "OCT": 5.56,
    "NOV": 5.42,
    "DEC": 5.55
  },
  {
    "LONG": -47.79,
    "LAT": -20.09,
    "CIDADE": "Aramina",
    "UF": "SÃO PAULO",
    "anual": 5.44,
    "JAN": 5.13,
    "FEB": 5.71,
    "MAR": 5.34,
    "APR": 5.68,
    "MAY": 5.23,
    "JUN": 5.16,
    "JUL": 5.38,
    "AUG": 6.1,
    "SEP": 5.53,
    "OCT": 5.48,
    "NOV": 5.2,
    "DEC": 5.31
  },
  {
    "LONG": -47.43,
    "LAT": -20.08,
    "CIDADE": "Rifaina",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.14,
    "FEB": 5.72,
    "MAR": 5.3,
    "APR": 5.62,
    "MAY": 5.27,
    "JUN": 5.2,
    "JUL": 5.43,
    "AUG": 6.11,
    "SEP": 5.5,
    "OCT": 5.49,
    "NOV": 5.15,
    "DEC": 5.24
  },
  {
    "LONG": -50.73,
    "LAT": -20.03,
    "CIDADE": "Santa Albertina",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.23,
    "FEB": 5.71,
    "MAR": 5.58,
    "APR": 5.53,
    "MAY": 5.1,
    "JUN": 4.97,
    "JUL": 5.15,
    "AUG": 5.85,
    "SEP": 5.32,
    "OCT": 5.52,
    "NOV": 5.44,
    "DEC": 5.53
  },
  {
    "LONG": -5.06,
    "LAT": -19.97,
    "CIDADE": "Mesópolis",
    "UF": "SÃO PAULO",
    "anual": 5.4,
    "JAN": 5.26,
    "FEB": 5.65,
    "MAR": 5.55,
    "APR": 5.51,
    "MAY": 5.11,
    "JUN": 4.96,
    "JUL": 5.11,
    "AUG": 5.86,
    "SEP": 5.35,
    "OCT": 5.53,
    "NOV": 5.38,
    "DEC": 5.52
  },
  {
    "LONG": -50.37,
    "LAT": -20,
    "CIDADE": "Ouroeste",
    "UF": "SÃO PAULO",
    "anual": 5.41,
    "JAN": 5.22,
    "FEB": 5.7,
    "MAR": 5.47,
    "APR": 5.56,
    "MAY": 5.14,
    "JUN": 4.95,
    "JUL": 5.16,
    "AUG": 5.87,
    "SEP": 5.43,
    "OCT": 5.54,
    "NOV": 5.41,
    "DEC": 5.47
  },
  {
    "LONG": -50.29,
    "LAT": -19.98,
    "CIDADE": "Indiaporã",
    "UF": "SÃO PAULO",
    "anual": 5.42,
    "JAN": 5.24,
    "FEB": 5.74,
    "MAR": 5.51,
    "APR": 5.58,
    "MAY": 5.15,
    "JUN": 4.94,
    "JUL": 5.17,
    "AUG": 5.84,
    "SEP": 5.42,
    "OCT": 5.54,
    "NOV": 5.43,
    "DEC": 5.5
  },
  {
    "LONG": -50.14,
    "LAT": -2,
    "CIDADE": "Mira Estrela",
    "UF": "SÃO PAULO",
    "anual": 5.43,
    "JAN": 5.28,
    "FEB": 5.78,
    "MAR": 5.53,
    "APR": 5.57,
    "MAY": 5.15,
    "JUN": 4.91,
    "JUL": 5.17,
    "AUG": 5.82,
    "SEP": 5.41,
    "OCT": 5.57,
    "NOV": 5.47,
    "DEC": 5.52
  },
  {
    "LONG": -49.68,
    "LAT": -19.98,
    "CIDADE": "Riolândia",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.21,
    "FEB": 5.68,
    "MAR": 5.43,
    "APR": 5.59,
    "MAY": 5.08,
    "JUN": 4.93,
    "JUL": 5.19,
    "AUG": 5.88,
    "SEP": 5.37,
    "OCT": 5.47,
    "NOV": 5.35,
    "DEC": 5.48
  },
  {
    "LONG": -49.4,
    "LAT": -20.03,
    "CIDADE": "Paulo de Faria",
    "UF": "SÃO PAULO",
    "anual": 5.38,
    "JAN": 5.19,
    "FEB": 5.68,
    "MAR": 5.4,
    "APR": 5.62,
    "MAY": 5.12,
    "JUN": 4.92,
    "JUL": 5.18,
    "AUG": 5.9,
    "SEP": 5.36,
    "OCT": 5.43,
    "NOV": 5.33,
    "DEC": 5.47
  },
  {
    "LONG": -4.77,
    "LAT": -20.04,
    "CIDADE": "Igarapava",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.05,
    "FEB": 5.62,
    "MAR": 5.25,
    "APR": 5.64,
    "MAY": 5.24,
    "JUN": 5.15,
    "JUL": 5.4,
    "AUG": 6.1,
    "SEP": 5.49,
    "OCT": 5.43,
    "NOV": 5.11,
    "DEC": 5.19
  },
  {
    "LONG": -50.54,
    "LAT": -19.95,
    "CIDADE": "Populina",
    "UF": "SÃO PAULO",
    "anual": 5.39,
    "JAN": 5.27,
    "FEB": 5.67,
    "MAR": 5.52,
    "APR": 5.52,
    "MAY": 5.11,
    "JUN": 4.95,
    "JUL": 5.11,
    "AUG": 5.85,
    "SEP": 5.34,
    "OCT": 5.53,
    "NOV": 5.34,
    "DEC": 5.47
  },
  {
    "LONG": -37.76,
    "LAT": -11.46,
    "CIDADE": "Cristinápolis",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.8,
    "FEB": 5.83,
    "MAR": 5.87,
    "APR": 5.15,
    "MAY": 4.5,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 4.82,
    "SEP": 5.41,
    "OCT": 5.5,
    "NOV": 5.7,
    "DEC": 5.79
  },
  {
    "LONG": -37.51,
    "LAT": -11.52,
    "CIDADE": "Indiaroba",
    "UF": "SERGIPE",
    "anual": 5.33,
    "JAN": 5.87,
    "FEB": 5.9,
    "MAR": 6.02,
    "APR": 5.22,
    "MAY": 4.59,
    "JUN": 4.38,
    "JUL": 4.48,
    "AUG": 4.84,
    "SEP": 5.43,
    "OCT": 5.57,
    "NOV": 5.79,
    "DEC": 5.9
  },
  {
    "LONG": -37.84,
    "LAT": -11.37,
    "CIDADE": "Tomar do Geru",
    "UF": "SERGIPE",
    "anual": 5.23,
    "JAN": 5.76,
    "FEB": 5.77,
    "MAR": 5.8,
    "APR": 5.1,
    "MAY": 4.49,
    "JUN": 4.33,
    "JUL": 4.44,
    "AUG": 4.81,
    "SEP": 5.37,
    "OCT": 5.49,
    "NOV": 5.65,
    "DEC": 5.74
  },
  {
    "LONG": -37.66,
    "LAT": -11.38,
    "CIDADE": "Umbaúba",
    "UF": "SERGIPE",
    "anual": 5.29,
    "JAN": 5.82,
    "FEB": 5.84,
    "MAR": 5.98,
    "APR": 5.17,
    "MAY": 4.56,
    "JUN": 4.35,
    "JUL": 4.49,
    "AUG": 4.81,
    "SEP": 5.34,
    "OCT": 5.51,
    "NOV": 5.73,
    "DEC": 5.81
  },
  {
    "LONG": -37.46,
    "LAT": -11.35,
    "CIDADE": "Santa Luzia do Itanhy",
    "UF": "SERGIPE",
    "anual": 5.36,
    "JAN": 5.94,
    "FEB": 5.89,
    "MAR": 6.02,
    "APR": 5.28,
    "MAY": 4.6,
    "JUN": 4.38,
    "JUL": 4.47,
    "AUG": 4.87,
    "SEP": 5.44,
    "OCT": 5.58,
    "NOV": 5.88,
    "DEC": 5.96
  },
  {
    "LONG": -37.79,
    "LAT": -11.27,
    "CIDADE": "Itabaianinha",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.76,
    "FEB": 5.77,
    "MAR": 5.89,
    "APR": 5.13,
    "MAY": 4.55,
    "JUN": 4.28,
    "JUL": 4.43,
    "AUG": 4.82,
    "SEP": 5.4,
    "OCT": 5.51,
    "NOV": 5.8,
    "DEC": 5.77
  },
  {
    "LONG": -37.62,
    "LAT": -11.27,
    "CIDADE": "Arauá",
    "UF": "SERGIPE",
    "anual": 5.29,
    "JAN": 5.8,
    "FEB": 5.84,
    "MAR": 5.95,
    "APR": 5.15,
    "MAY": 4.58,
    "JUN": 4.39,
    "JUL": 4.45,
    "AUG": 4.82,
    "SEP": 5.39,
    "OCT": 5.51,
    "NOV": 5.76,
    "DEC": 5.81
  },
  {
    "LONG": -37.44,
    "LAT": -11.27,
    "CIDADE": "Estância",
    "UF": "SERGIPE",
    "anual": 5.32,
    "JAN": 5.84,
    "FEB": 5.8,
    "MAR": 5.96,
    "APR": 5.22,
    "MAY": 4.59,
    "JUN": 4.36,
    "JUL": 4.48,
    "AUG": 4.86,
    "SEP": 5.42,
    "OCT": 5.57,
    "NOV": 5.82,
    "DEC": 5.89
  },
  {
    "LONG": -38,
    "LAT": -11.18,
    "CIDADE": "Tobias Barreto",
    "UF": "SERGIPE",
    "anual": 5.2,
    "JAN": 5.73,
    "FEB": 5.68,
    "MAR": 5.84,
    "APR": 5.17,
    "MAY": 4.48,
    "JUN": 4.23,
    "JUL": 4.41,
    "AUG": 4.8,
    "SEP": 5.29,
    "OCT": 5.44,
    "NOV": 5.65,
    "DEC": 5.64
  },
  {
    "LONG": -37.68,
    "LAT": -11.19,
    "CIDADE": "Pedrinhas",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.75,
    "FEB": 5.81,
    "MAR": 5.93,
    "APR": 5.16,
    "MAY": 4.57,
    "JUN": 4.35,
    "JUL": 4.45,
    "AUG": 4.76,
    "SEP": 5.37,
    "OCT": 5.46,
    "NOV": 5.76,
    "DEC": 5.76
  },
  {
    "LONG": -37.73,
    "LAT": -11.07,
    "CIDADE": "Riachão do Dantas",
    "UF": "SERGIPE",
    "anual": 5.21,
    "JAN": 5.65,
    "FEB": 5.75,
    "MAR": 5.84,
    "APR": 5.16,
    "MAY": 4.5,
    "JUN": 4.28,
    "JUL": 4.39,
    "AUG": 4.76,
    "SEP": 5.35,
    "OCT": 5.43,
    "NOV": 5.7,
    "DEC": 5.69
  },
  {
    "LONG": -37.62,
    "LAT": -11.15,
    "CIDADE": "Boquim",
    "UF": "SERGIPE",
    "anual": 5.25,
    "JAN": 5.7,
    "FEB": 5.78,
    "MAR": 5.87,
    "APR": 5.19,
    "MAY": 4.55,
    "JUN": 4.37,
    "JUL": 4.43,
    "AUG": 4.85,
    "SEP": 5.4,
    "OCT": 5.49,
    "NOV": 5.69,
    "DEC": 5.68
  },
  {
    "LONG": -37.48,
    "LAT": -11.03,
    "CIDADE": "Salgado",
    "UF": "SERGIPE",
    "anual": 5.28,
    "JAN": 5.78,
    "FEB": 5.77,
    "MAR": 5.92,
    "APR": 5.22,
    "MAY": 4.52,
    "JUN": 4.35,
    "JUL": 4.37,
    "AUG": 4.82,
    "SEP": 5.42,
    "OCT": 5.53,
    "NOV": 5.83,
    "DEC": 5.78
  },
  {
    "LONG": -37.31,
    "LAT": -11,
    "CIDADE": "Itaporanga d'Ajuda",
    "UF": "SERGIPE",
    "anual": 5.32,
    "JAN": 5.79,
    "FEB": 5.87,
    "MAR": 6,
    "APR": 5.24,
    "MAY": 4.56,
    "JUN": 4.36,
    "JUL": 4.43,
    "AUG": 4.89,
    "SEP": 5.45,
    "OCT": 5.59,
    "NOV": 5.85,
    "DEC": 5.83
  },
  {
    "LONG": -37.21,
    "LAT": -11.01,
    "CIDADE": "São Cristóvão",
    "UF": "SERGIPE",
    "anual": 5.36,
    "JAN": 5.85,
    "FEB": 5.94,
    "MAR": 6.03,
    "APR": 5.26,
    "MAY": 4.62,
    "JUN": 4.38,
    "JUL": 4.45,
    "AUG": 4.9,
    "SEP": 5.47,
    "OCT": 5.64,
    "NOV": 5.91,
    "DEC": 5.9
  },
  {
    "LONG": -37.67,
    "LAT": -10.91,
    "CIDADE": "Lagarto",
    "UF": "SERGIPE",
    "anual": 5.22,
    "JAN": 5.69,
    "FEB": 5.74,
    "MAR": 5.84,
    "APR": 5.21,
    "MAY": 4.49,
    "JUN": 4.25,
    "JUL": 4.37,
    "AUG": 4.74,
    "SEP": 5.37,
    "OCT": 5.5,
    "NOV": 5.74,
    "DEC": 5.74
  },
  {
    "LONG": -37.13,
    "LAT": -10.85,
    "CIDADE": "Nossa Senhora do Socorro",
    "UF": "SERGIPE",
    "anual": 5.42,
    "JAN": 5.91,
    "FEB": 6.04,
    "MAR": 6.09,
    "APR": 5.3,
    "MAY": 4.67,
    "JUN": 4.36,
    "JUL": 4.41,
    "AUG": 4.94,
    "SEP": 5.52,
    "OCT": 5.78,
    "NOV": 5.99,
    "DEC": 5.98
  },
  {
    "LONG": -37.07,
    "LAT": "#VALOR!",
    "CIDADE": "Aracaju",
    "UF": "SERGIPE",
    "anual": 5.51,
    "JAN": 5.87,
    "FEB": 6.15,
    "MAR": 6.1,
    "APR": 5.33,
    "MAY": 4.8,
    "JUN": 4.48,
    "JUL": 4.58,
    "AUG": 5.12,
    "SEP": 5.68,
    "OCT": 5.91,
    "NOV": 5.99,
    "DEC": 6.08
  },
  {
    "LONG": -37.03,
    "LAT": -10.9,
    "CIDADE": "Barra dos Coqueiros",
    "UF": "SERGIPE",
    "anual": 5.51,
    "JAN": 5.87,
    "FEB": 6.15,
    "MAR": 6.1,
    "APR": 5.33,
    "MAY": 4.8,
    "JUN": 4.48,
    "JUL": 4.58,
    "AUG": 5.12,
    "SEP": 5.68,
    "OCT": 5.91,
    "NOV": 5.99,
    "DEC": 6.08
  },
  {
    "LONG": -37.57,
    "LAT": -10.79,
    "CIDADE": "São Domingos",
    "UF": "SERGIPE",
    "anual": 5.25,
    "JAN": 5.72,
    "FEB": 5.72,
    "MAR": 5.79,
    "APR": 5.19,
    "MAY": 4.53,
    "JUN": 4.3,
    "JUL": 4.4,
    "AUG": 4.8,
    "SEP": 5.46,
    "OCT": 5.52,
    "NOV": 5.8,
    "DEC": 5.77
  },
  {
    "LONG": -37.31,
    "LAT": -10.76,
    "CIDADE": "Areia Branca",
    "UF": "SERGIPE",
    "anual": 5.16,
    "JAN": 5.61,
    "FEB": 5.68,
    "MAR": 5.81,
    "APR": 5.16,
    "MAY": 4.46,
    "JUN": 4.16,
    "JUL": 4.26,
    "AUG": 4.68,
    "SEP": 5.26,
    "OCT": 5.43,
    "NOV": 5.73,
    "DEC": 5.68
  },
  {
    "LONG": -37.17,
    "LAT": -10.8,
    "CIDADE": "Laranjeiras",
    "UF": "SERGIPE",
    "anual": 5.4,
    "JAN": 5.93,
    "FEB": 5.94,
    "MAR": 6.08,
    "APR": 5.33,
    "MAY": 4.69,
    "JUN": 4.33,
    "JUL": 4.46,
    "AUG": 4.89,
    "SEP": 5.51,
    "OCT": 5.7,
    "NOV": 5.97,
    "DEC": 5.93
  },
  {
    "LONG": -37.05,
    "LAT": -10.79,
    "CIDADE": "Santo Amaro das Brotas",
    "UF": "SERGIPE",
    "anual": 5.44,
    "JAN": 5.87,
    "FEB": 6.05,
    "MAR": 6.12,
    "APR": 5.31,
    "MAY": 4.69,
    "JUN": 4.4,
    "JUL": 4.47,
    "AUG": 4.97,
    "SEP": 5.58,
    "OCT": 5.81,
    "NOV": 6.03,
    "DEC": 6.02
  },
  {
    "LONG": -3.82,
    "LAT": -10.72,
    "CIDADE": "Poço Verde",
    "UF": "SERGIPE",
    "anual": 5.23,
    "JAN": 5.74,
    "FEB": 5.67,
    "MAR": 5.83,
    "APR": 5.24,
    "MAY": 4.5,
    "JUN": 4.21,
    "JUL": 4.38,
    "AUG": 4.73,
    "SEP": 5.43,
    "OCT": 5.52,
    "NOV": 5.84,
    "DEC": 5.71
  },
  {
    "LONG": -37.81,
    "LAT": -10.74,
    "CIDADE": "Simão Dias",
    "UF": "SERGIPE",
    "anual": 5.23,
    "JAN": 5.73,
    "FEB": 5.63,
    "MAR": 5.78,
    "APR": 5.31,
    "MAY": 4.59,
    "JUN": 4.23,
    "JUL": 4.38,
    "AUG": 4.81,
    "SEP": 5.36,
    "OCT": 5.45,
    "NOV": 5.81,
    "DEC": 5.69
  },
  {
    "LONG": -37.54,
    "LAT": -10.67,
    "CIDADE": "Macambira",
    "UF": "SERGIPE",
    "anual": 5.23,
    "JAN": 5.7,
    "FEB": 5.7,
    "MAR": 5.8,
    "APR": 5.2,
    "MAY": 4.48,
    "JUN": 4.3,
    "JUL": 4.37,
    "AUG": 4.8,
    "SEP": 5.43,
    "OCT": 5.51,
    "NOV": 5.81,
    "DEC": 5.73
  },
  {
    "LONG": -37.43,
    "LAT": -1.07,
    "CIDADE": "Itabaiana",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.68,
    "FEB": 5.74,
    "MAR": 5.85,
    "APR": 5.24,
    "MAY": 4.54,
    "JUN": 4.31,
    "JUL": 4.36,
    "AUG": 4.81,
    "SEP": 5.42,
    "OCT": 5.57,
    "NOV": 5.85,
    "DEC": 5.79
  },
  {
    "LONG": -37.49,
    "LAT": -10.75,
    "CIDADE": "Campo do Brito",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.68,
    "FEB": 5.74,
    "MAR": 5.85,
    "APR": 5.24,
    "MAY": 4.54,
    "JUN": 4.31,
    "JUL": 4.36,
    "AUG": 4.81,
    "SEP": 5.42,
    "OCT": 5.57,
    "NOV": 5.85,
    "DEC": 5.79
  },
  {
    "LONG": -37.31,
    "LAT": -10.66,
    "CIDADE": "Malhador",
    "UF": "SERGIPE",
    "anual": 5.28,
    "JAN": 5.82,
    "FEB": 5.74,
    "MAR": 5.92,
    "APR": 5.27,
    "MAY": 4.47,
    "JUN": 4.29,
    "JUL": 4.34,
    "AUG": 4.8,
    "SEP": 5.42,
    "OCT": 5.59,
    "NOV": 5.88,
    "DEC": 5.84
  },
  {
    "LONG": -37.15,
    "LAT": -10.68,
    "CIDADE": "Divina Pastora",
    "UF": "SERGIPE",
    "anual": 5.34,
    "JAN": 5.76,
    "FEB": 5.86,
    "MAR": 5.97,
    "APR": 5.28,
    "MAY": 4.67,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 4.92,
    "SEP": 5.49,
    "OCT": 5.62,
    "NOV": 5.89,
    "DEC": 5.78
  },
  {
    "LONG": -37.19,
    "LAT": -10.73,
    "CIDADE": "Riachuelo",
    "UF": "SERGIPE",
    "anual": 5.34,
    "JAN": 5.76,
    "FEB": 5.86,
    "MAR": 5.97,
    "APR": 5.28,
    "MAY": 4.67,
    "JUN": 4.41,
    "JUL": 4.49,
    "AUG": 4.92,
    "SEP": 5.49,
    "OCT": 5.62,
    "NOV": 5.89,
    "DEC": 5.78
  },
  {
    "LONG": -37.03,
    "LAT": -10.7,
    "CIDADE": "Rosário do Catete",
    "UF": "SERGIPE",
    "anual": 5.43,
    "JAN": 5.92,
    "FEB": 5.96,
    "MAR": 6.07,
    "APR": 5.34,
    "MAY": 4.69,
    "JUN": 4.47,
    "JUL": 4.48,
    "AUG": 4.99,
    "SEP": 5.57,
    "OCT": 5.72,
    "NOV": 6.02,
    "DEC": 5.94
  },
  {
    "LONG": -37.09,
    "LAT": -10.74,
    "CIDADE": "Maruim",
    "UF": "SERGIPE",
    "anual": 5.43,
    "JAN": 5.92,
    "FEB": 5.96,
    "MAR": 6.07,
    "APR": 5.34,
    "MAY": 4.69,
    "JUN": 4.47,
    "JUL": 4.48,
    "AUG": 4.99,
    "SEP": 5.57,
    "OCT": 5.72,
    "NOV": 6.02,
    "DEC": 5.94
  },
  {
    "LONG": -36.98,
    "LAT": -10.68,
    "CIDADE": "General Maynard",
    "UF": "SERGIPE",
    "anual": 5.51,
    "JAN": 6.01,
    "FEB": 6.08,
    "MAR": 6.16,
    "APR": 5.41,
    "MAY": 4.73,
    "JUN": 4.5,
    "JUL": 4.54,
    "AUG": 5.1,
    "SEP": 5.66,
    "OCT": 5.84,
    "NOV": 6.09,
    "DEC": 6.03
  },
  {
    "LONG": -36.86,
    "LAT": -10.74,
    "CIDADE": "Pirambu",
    "UF": "SERGIPE",
    "anual": 5.54,
    "JAN": 6.02,
    "FEB": 6.11,
    "MAR": 6.16,
    "APR": 5.43,
    "MAY": 4.76,
    "JUN": 4.54,
    "JUL": 4.59,
    "AUG": 5.2,
    "SEP": 5.72,
    "OCT": 5.89,
    "NOV": 6.03,
    "DEC": 6.06
  },
  {
    "LONG": -37.72,
    "LAT": -10.57,
    "CIDADE": "Pinhão",
    "UF": "SERGIPE",
    "anual": 5.25,
    "JAN": 5.62,
    "FEB": 5.64,
    "MAR": 5.78,
    "APR": 5.29,
    "MAY": 4.62,
    "JUN": 4.32,
    "JUL": 4.45,
    "AUG": 4.9,
    "SEP": 5.46,
    "OCT": 5.45,
    "NOV": 5.81,
    "DEC": 5.64
  },
  {
    "LONG": -37.69,
    "LAT": -1.06,
    "CIDADE": "Pedra Mole",
    "UF": "SERGIPE",
    "anual": 5.24,
    "JAN": 5.65,
    "FEB": 5.65,
    "MAR": 5.84,
    "APR": 5.24,
    "MAY": 4.54,
    "JUN": 4.31,
    "JUL": 4.42,
    "AUG": 4.83,
    "SEP": 5.44,
    "OCT": 5.51,
    "NOV": 5.8,
    "DEC": 5.67
  },
  {
    "LONG": -3.75,
    "LAT": -10.55,
    "CIDADE": "Frei Paulo",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.71,
    "FEB": 5.73,
    "MAR": 5.82,
    "APR": 5.22,
    "MAY": 4.52,
    "JUN": 4.3,
    "JUL": 4.4,
    "AUG": 4.87,
    "SEP": 5.44,
    "OCT": 5.58,
    "NOV": 5.85,
    "DEC": 5.7
  },
  {
    "LONG": -37.34,
    "LAT": -10.58,
    "CIDADE": "Moita Bonita",
    "UF": "SERGIPE",
    "anual": 5.31,
    "JAN": 5.78,
    "FEB": 5.8,
    "MAR": 5.92,
    "APR": 5.3,
    "MAY": 4.54,
    "JUN": 4.33,
    "JUL": 4.4,
    "AUG": 4.89,
    "SEP": 5.47,
    "OCT": 5.63,
    "NOV": 5.87,
    "DEC": 5.83
  },
  {
    "LONG": -37.11,
    "LAT": -10.6,
    "CIDADE": "Siriri",
    "UF": "SERGIPE",
    "anual": 5.3,
    "JAN": 5.8,
    "FEB": 5.78,
    "MAR": 5.88,
    "APR": 5.28,
    "MAY": 4.59,
    "JUN": 4.36,
    "JUL": 4.41,
    "AUG": 4.87,
    "SEP": 5.44,
    "OCT": 5.56,
    "NOV": 5.84,
    "DEC": 5.75
  },
  {
    "LONG": -37.19,
    "LAT": -10.64,
    "CIDADE": "Santa Rosa de Lima",
    "UF": "SERGIPE",
    "anual": 5.3,
    "JAN": 5.8,
    "FEB": 5.78,
    "MAR": 5.88,
    "APR": 5.28,
    "MAY": 4.59,
    "JUN": 4.36,
    "JUL": 4.41,
    "AUG": 4.87,
    "SEP": 5.44,
    "OCT": 5.56,
    "NOV": 5.84,
    "DEC": 5.75
  },
  {
    "LONG": -36.94,
    "LAT": -10.59,
    "CIDADE": "Japaratuba",
    "UF": "SERGIPE",
    "anual": 5.38,
    "JAN": 5.83,
    "FEB": 5.84,
    "MAR": 5.98,
    "APR": 5.35,
    "MAY": 4.68,
    "JUN": 4.46,
    "JUL": 4.47,
    "AUG": 5.02,
    "SEP": 5.53,
    "OCT": 5.64,
    "NOV": 5.92,
    "DEC": 5.82
  },
  {
    "LONG": -36.98,
    "LAT": -1.06,
    "CIDADE": "Carmópolis",
    "UF": "SERGIPE",
    "anual": 5.38,
    "JAN": 5.83,
    "FEB": 5.84,
    "MAR": 5.98,
    "APR": 5.35,
    "MAY": 4.68,
    "JUN": 4.46,
    "JUL": 4.47,
    "AUG": 5.02,
    "SEP": 5.53,
    "OCT": 5.64,
    "NOV": 5.92,
    "DEC": 5.82
  },
  {
    "LONG": -37.44,
    "LAT": -10.54,
    "CIDADE": "Ribeirópolis",
    "UF": "SERGIPE",
    "anual": 5.27,
    "JAN": 5.69,
    "FEB": 5.73,
    "MAR": 5.77,
    "APR": 5.26,
    "MAY": 4.57,
    "JUN": 4.33,
    "JUL": 4.41,
    "AUG": 4.93,
    "SEP": 5.48,
    "OCT": 5.54,
    "NOV": 5.81,
    "DEC": 5.75
  },
  {
    "LONG": -37.2,
    "LAT": -10.49,
    "CIDADE": "Nossa Senhora das Dores",
    "UF": "SERGIPE",
    "anual": 5.28,
    "JAN": 5.81,
    "FEB": 5.72,
    "MAR": 5.81,
    "APR": 5.3,
    "MAY": 4.56,
    "JUN": 4.36,
    "JUL": 4.4,
    "AUG": 4.86,
    "SEP": 5.47,
    "OCT": 5.55,
    "NOV": 5.8,
    "DEC": 5.75
  },
  {
    "LONG": -37.05,
    "LAT": -10.51,
    "CIDADE": "Capela",
    "UF": "SERGIPE",
    "anual": 5.27,
    "JAN": 5.77,
    "FEB": 5.64,
    "MAR": 5.82,
    "APR": 5.28,
    "MAY": 4.61,
    "JUN": 4.36,
    "JUL": 4.44,
    "AUG": 4.88,
    "SEP": 5.46,
    "OCT": 5.52,
    "NOV": 5.75,
    "DEC": 5.69
  },
  {
    "LONG": -36.65,
    "LAT": -10.46,
    "CIDADE": "Pacatuba",
    "UF": "SERGIPE",
    "anual": 5.31,
    "JAN": 5.75,
    "FEB": 5.81,
    "MAR": 5.88,
    "APR": 5.21,
    "MAY": 4.57,
    "JUN": 4.39,
    "JUL": 4.42,
    "AUG": 4.94,
    "SEP": 5.44,
    "OCT": 5.6,
    "NOV": 5.89,
    "DEC": 5.85
  },
  {
    "LONG": -37.7,
    "LAT": -1.04,
    "CIDADE": "Carira",
    "UF": "SERGIPE",
    "anual": 5.29,
    "JAN": 5.73,
    "FEB": 5.77,
    "MAR": 5.82,
    "APR": 5.3,
    "MAY": 4.6,
    "JUN": 4.32,
    "JUL": 4.44,
    "AUG": 4.94,
    "SEP": 5.52,
    "OCT": 5.52,
    "NOV": 5.85,
    "DEC": 5.74
  },
  {
    "LONG": -37.45,
    "LAT": -10.4,
    "CIDADE": "Nossa Senhora Aparecida",
    "UF": "SERGIPE",
    "anual": 5.29,
    "JAN": 5.68,
    "FEB": 5.7,
    "MAR": 5.77,
    "APR": 5.3,
    "MAY": 4.6,
    "JUN": 4.36,
    "JUL": 4.45,
    "AUG": 4.97,
    "SEP": 5.53,
    "OCT": 5.53,
    "NOV": 5.78,
    "DEC": 5.74
  },
  {
    "LONG": -37.38,
    "LAT": -10.39,
    "CIDADE": "São Miguel do Aleixo",
    "UF": "SERGIPE",
    "anual": 5.27,
    "JAN": 5.64,
    "FEB": 5.71,
    "MAR": 5.77,
    "APR": 5.27,
    "MAY": 4.57,
    "JUN": 4.41,
    "JUL": 4.44,
    "AUG": 4.96,
    "SEP": 5.47,
    "OCT": 5.5,
    "NOV": 5.82,
    "DEC": 5.68
  },
  {
    "LONG": -37.19,
    "LAT": -1.04,
    "CIDADE": "Cumbe",
    "UF": "SERGIPE",
    "anual": 5.28,
    "JAN": 5.7,
    "FEB": 5.67,
    "MAR": 5.78,
    "APR": 5.28,
    "MAY": 4.57,
    "JUN": 4.38,
    "JUL": 4.44,
    "AUG": 4.9,
    "SEP": 5.49,
    "OCT": 5.55,
    "NOV": 5.82,
    "DEC": 5.73
  },
  {
    "LONG": -36.96,
    "LAT": -10.42,
    "CIDADE": "Muribeca",
    "UF": "SERGIPE",
    "anual": 5.21,
    "JAN": 5.63,
    "FEB": 5.56,
    "MAR": 5.73,
    "APR": 5.19,
    "MAY": 4.55,
    "JUN": 4.34,
    "JUL": 4.41,
    "AUG": 4.81,
    "SEP": 5.39,
    "OCT": 5.47,
    "NOV": 5.78,
    "DEC": 5.7
  },
  {
    "LONG": -36.55,
    "LAT": -10.44,
    "CIDADE": "Ilha das Flores",
    "UF": "SERGIPE",
    "anual": 5.42,
    "JAN": 5.84,
    "FEB": 5.89,
    "MAR": 6.03,
    "APR": 5.31,
    "MAY": 4.65,
    "JUN": 4.43,
    "JUL": 4.49,
    "AUG": 5.03,
    "SEP": 5.62,
    "OCT": 5.85,
    "NOV": 6.02,
    "DEC": 5.94
  },
  {
    "LONG": -36.46,
    "LAT": -10.43,
    "CIDADE": "Brejo Grande",
    "UF": "SERGIPE",
    "anual": 5.45,
    "JAN": 5.76,
    "FEB": 5.95,
    "MAR": 6.08,
    "APR": 5.27,
    "MAY": 4.71,
    "JUN": 4.5,
    "JUL": 4.51,
    "AUG": 5.14,
    "SEP": 5.62,
    "OCT": 5.86,
    "NOV": 6,
    "DEC": 6
  },
  {
    "LONG": -37.31,
    "LAT": -10.26,
    "CIDADE": "Feira Nova",
    "UF": "SERGIPE",
    "anual": 5.27,
    "JAN": 5.64,
    "FEB": 5.63,
    "MAR": 5.8,
    "APR": 5.25,
    "MAY": 4.56,
    "JUN": 4.37,
    "JUL": 4.41,
    "AUG": 4.95,
    "SEP": 5.49,
    "OCT": 5.5,
    "NOV": 5.88,
    "DEC": 5.72
  },
  {
    "LONG": -37.02,
    "LAT": -10.28,
    "CIDADE": "Aquidabã",
    "UF": "SERGIPE",
    "anual": 5.25,
    "JAN": 5.59,
    "FEB": 5.6,
    "MAR": 5.76,
    "APR": 5.29,
    "MAY": 4.52,
    "JUN": 4.36,
    "JUL": 4.4,
    "AUG": 4.88,
    "SEP": 5.45,
    "OCT": 5.57,
    "NOV": 5.9,
    "DEC": 5.73
  },
  {
    "LONG": -36.92,
    "LAT": -10.34,
    "CIDADE": "Malhada dos Bois",
    "UF": "SERGIPE",
    "anual": 5.22,
    "JAN": 5.55,
    "FEB": 5.53,
    "MAR": 5.72,
    "APR": 5.19,
    "MAY": 4.53,
    "JUN": 4.35,
    "JUL": 4.38,
    "AUG": 4.87,
    "SEP": 5.38,
    "OCT": 5.51,
    "NOV": 5.84,
    "DEC": 5.73
  },
  {
    "LONG": -36.88,
    "LAT": -10.25,
    "CIDADE": "Cedro de São João",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.61,
    "FEB": 5.58,
    "MAR": 5.76,
    "APR": 5.15,
    "MAY": 4.54,
    "JUN": 4.38,
    "JUL": 4.39,
    "AUG": 4.86,
    "SEP": 5.5,
    "OCT": 5.66,
    "NOV": 5.89,
    "DEC": 5.82
  },
  {
    "LONG": -36.88,
    "LAT": -10.35,
    "CIDADE": "São Francisco",
    "UF": "SERGIPE",
    "anual": 5.26,
    "JAN": 5.61,
    "FEB": 5.58,
    "MAR": 5.76,
    "APR": 5.15,
    "MAY": 4.54,
    "JUN": 4.38,
    "JUL": 4.39,
    "AUG": 4.86,
    "SEP": 5.5,
    "OCT": 5.66,
    "NOV": 5.89,
    "DEC": 5.82
  },
  {
    "LONG": -36.8,
    "LAT": -10.34,
    "CIDADE": "Japoatã",
    "UF": "SERGIPE",
    "anual": 5.38,
    "JAN": 5.75,
    "FEB": 5.87,
    "MAR": 5.96,
    "APR": 5.27,
    "MAY": 4.62,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 4.91,
    "SEP": 5.62,
    "OCT": 5.76,
    "NOV": 6,
    "DEC": 5.9
  },
  {
    "LONG": -36.61,
    "LAT": -10.29,
    "CIDADE": "Santana do São Francisco",
    "UF": "SERGIPE",
    "anual": 5.39,
    "JAN": 5.74,
    "FEB": 5.84,
    "MAR": 5.95,
    "APR": 5.3,
    "MAY": 4.65,
    "JUN": 4.44,
    "JUL": 4.49,
    "AUG": 4.98,
    "SEP": 5.6,
    "OCT": 5.79,
    "NOV": 6,
    "DEC": 5.89
  },
  {
    "LONG": -36.59,
    "LAT": -10.32,
    "CIDADE": "Neópolis",
    "UF": "SERGIPE",
    "anual": 5.35,
    "JAN": 5.71,
    "FEB": 5.73,
    "MAR": 5.91,
    "APR": 5.27,
    "MAY": 4.63,
    "JUN": 4.43,
    "JUL": 4.46,
    "AUG": 4.99,
    "SEP": 5.52,
    "OCT": 5.71,
    "NOV": 5.94,
    "DEC": 5.85
  },
  {
    "LONG": -37.42,
    "LAT": -10.22,
    "CIDADE": "Nossa Senhora da Glória",
    "UF": "SERGIPE",
    "anual": 5.33,
    "JAN": 5.75,
    "FEB": 5.71,
    "MAR": 5.77,
    "APR": 5.32,
    "MAY": 4.63,
    "JUN": 4.38,
    "JUL": 4.55,
    "AUG": 5.05,
    "SEP": 5.61,
    "OCT": 5.56,
    "NOV": 5.91,
    "DEC": 5.74
  },
  {
    "LONG": -3.72,
    "LAT": "#VALOR!",
    "CIDADE": "Graccho Cardoso",
    "UF": "SERGIPE",
    "anual": 5.27,
    "JAN": 5.65,
    "FEB": 5.62,
    "MAR": 5.8,
    "APR": 5.27,
    "MAY": 4.54,
    "JUN": 4.36,
    "JUL": 4.46,
    "AUG": 4.9,
    "SEP": 5.53,
    "OCT": 5.58,
    "NOV": 5.81,
    "DEC": 5.74
  },
  {
    "LONG": -36.84,
    "LAT": -10.22,
    "CIDADE": "Propriá",
    "UF": "SERGIPE",
    "anual": 5.44,
    "JAN": 5.93,
    "FEB": 5.9,
    "MAR": 6.01,
    "APR": 5.47,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.5,
    "AUG": 4.98,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.99,
    "DEC": 5.94
  },
  {
    "LONG": -36.88,
    "LAT": -10.21,
    "CIDADE": "Telha",
    "UF": "SERGIPE",
    "anual": 5.44,
    "JAN": 5.93,
    "FEB": 5.9,
    "MAR": 6.01,
    "APR": 5.47,
    "MAY": 4.68,
    "JUN": 4.49,
    "JUL": 4.5,
    "AUG": 4.98,
    "SEP": 5.62,
    "OCT": 5.75,
    "NOV": 5.99,
    "DEC": 5.94
  },
  {
    "LONG": -37.11,
    "LAT": -10.13,
    "CIDADE": "Itabi",
    "UF": "SERGIPE",
    "anual": 5.31,
    "JAN": 5.68,
    "FEB": 5.67,
    "MAR": 5.85,
    "APR": 5.33,
    "MAY": 4.58,
    "JUN": 4.35,
    "JUL": 4.48,
    "AUG": 4.97,
    "SEP": 5.58,
    "OCT": 5.65,
    "NOV": 5.87,
    "DEC": 5.74
  },
  {
    "LONG": -37.06,
    "LAT": -10.08,
    "CIDADE": "Nossa Senhora de Lourdes",
    "UF": "SERGIPE",
    "anual": 5.38,
    "JAN": 5.82,
    "FEB": 5.7,
    "MAR": 5.88,
    "APR": 5.38,
    "MAY": 4.66,
    "JUN": 4.47,
    "JUL": 4.5,
    "AUG": 5.03,
    "SEP": 5.65,
    "OCT": 5.69,
    "NOV": 5.95,
    "DEC": 5.81
  },
  {
    "LONG": -36.97,
    "LAT": -10.14,
    "CIDADE": "Canhoba",
    "UF": "SERGIPE",
    "anual": 5.41,
    "JAN": 5.83,
    "FEB": 5.8,
    "MAR": 5.94,
    "APR": 5.43,
    "MAY": 4.69,
    "JUN": 4.51,
    "JUL": 4.53,
    "AUG": 5.03,
    "SEP": 5.63,
    "OCT": 5.7,
    "NOV": 5.94,
    "DEC": 5.84
  },
  {
    "LONG": -36.93,
    "LAT": -10.13,
    "CIDADE": "Amparo de São Francisco",
    "UF": "SERGIPE",
    "anual": 5.41,
    "JAN": 5.83,
    "FEB": 5.8,
    "MAR": 5.94,
    "APR": 5.43,
    "MAY": 4.69,
    "JUN": 4.51,
    "JUL": 4.53,
    "AUG": 5.03,
    "SEP": 5.63,
    "OCT": 5.7,
    "NOV": 5.94,
    "DEC": 5.84
  },
  {
    "LONG": -37.56,
    "LAT": -1,
    "CIDADE": "Monte Alegre de Sergipe",
    "UF": "SERGIPE",
    "anual": 5.31,
    "JAN": 5.67,
    "FEB": 5.7,
    "MAR": 5.87,
    "APR": 5.34,
    "MAY": 4.59,
    "JUN": 4.32,
    "JUL": 4.46,
    "AUG": 4.95,
    "SEP": 5.54,
    "OCT": 5.56,
    "NOV": 5.89,
    "DEC": 5.78
  },
  {
    "LONG": -37.09,
    "LAT": -9.97,
    "CIDADE": "Gararu",
    "UF": "SERGIPE",
    "anual": 5.47,
    "JAN": 5.9,
    "FEB": 5.84,
    "MAR": 6.01,
    "APR": 5.5,
    "MAY": 4.71,
    "JUN": 4.52,
    "JUL": 4.56,
    "AUG": 5.13,
    "SEP": 5.77,
    "OCT": 5.78,
    "NOV": 6.03,
    "DEC": 5.88
  },
  {
    "LONG": -37.28,
    "LAT": -9.92,
    "CIDADE": "Porto da Folha",
    "UF": "SERGIPE",
    "anual": 5.43,
    "JAN": 5.89,
    "FEB": 5.84,
    "MAR": 5.91,
    "APR": 5.42,
    "MAY": 4.64,
    "JUN": 4.44,
    "JUL": 4.53,
    "AUG": 5.04,
    "SEP": 5.71,
    "OCT": 5.74,
    "NOV": 6.09,
    "DEC": 5.88
  },
  {
    "LONG": -37.68,
    "LAT": -9.81,
    "CIDADE": "Poço Redondo",
    "UF": "SERGIPE",
    "anual": 5.34,
    "JAN": 5.78,
    "FEB": 5.75,
    "MAR": 5.93,
    "APR": 5.38,
    "MAY": 4.64,
    "JUN": 4.3,
    "JUL": 4.39,
    "AUG": 4.86,
    "SEP": 5.53,
    "OCT": 5.66,
    "NOV": 6.01,
    "DEC": 5.88
  },
  {
    "LONG": -37.79,
    "LAT": -9.66,
    "CIDADE": "Canindé de São Francisco",
    "UF": "SERGIPE",
    "anual": 5.43,
    "JAN": 5.95,
    "FEB": 5.87,
    "MAR": 5.97,
    "APR": 5.49,
    "MAY": 4.68,
    "JUN": 4.33,
    "JUL": 4.41,
    "AUG": 5.04,
    "SEP": 5.61,
    "OCT": 5.76,
    "NOV": 6.08,
    "DEC": 5.96
  },
  {
    "LONG": -48.41,
    "LAT": "#VALOR!",
    "CIDADE": "Palmeirópolis",
    "UF": "TOCANTINS",
    "anual": 5.42,
    "JAN": 4.92,
    "FEB": 5.2,
    "MAR": 5.08,
    "APR": 5.51,
    "MAY": 5.65,
    "JUN": 5.61,
    "JUL": 5.95,
    "AUG": 6.33,
    "SEP": 5.95,
    "OCT": 5.38,
    "NOV": 4.7,
    "DEC": 4.78
  },
  {
    "LONG": -49.83,
    "LAT": -12.93,
    "CIDADE": "Araguaçu",
    "UF": "TOCANTINS",
    "anual": 5.42,
    "JAN": 4.94,
    "FEB": 5.22,
    "MAR": 5.12,
    "APR": 5.43,
    "MAY": 5.61,
    "JUN": 5.47,
    "JUL": 5.93,
    "AUG": 6.28,
    "SEP": 5.84,
    "OCT": 5.35,
    "NOV": 4.91,
    "DEC": 4.94
  },
  {
    "LONG": -46.95,
    "LAT": -12.92,
    "CIDADE": "Arraias",
    "UF": "TOCANTINS",
    "anual": 5.65,
    "JAN": 5.28,
    "FEB": 5.38,
    "MAR": 5.26,
    "APR": 5.59,
    "MAY": 5.81,
    "JUN": 5.73,
    "JUL": 6.09,
    "AUG": 6.54,
    "SEP": 6.17,
    "OCT": 5.63,
    "NOV": 5.06,
    "DEC": 5.22
  },
  {
    "LONG": -46.58,
    "LAT": -12.92,
    "CIDADE": "Novo Alegre",
    "UF": "TOCANTINS",
    "anual": 5.68,
    "JAN": 5.49,
    "FEB": 5.54,
    "MAR": 5.47,
    "APR": 5.71,
    "MAY": 5.79,
    "JUN": 5.68,
    "JUL": 5.97,
    "AUG": 6.33,
    "SEP": 6.02,
    "OCT": 5.6,
    "NOV": 5.14,
    "DEC": 5.39
  },
  {
    "LONG": -4.91,
    "LAT": -12.8,
    "CIDADE": "Talismã",
    "UF": "TOCANTINS",
    "anual": 5.43,
    "JAN": 4.97,
    "FEB": 5.26,
    "MAR": 5.14,
    "APR": 5.45,
    "MAY": 5.58,
    "JUN": 5.56,
    "JUL": 5.77,
    "AUG": 6.25,
    "SEP": 5.9,
    "OCT": 5.45,
    "NOV": 4.87,
    "DEC": 4.92
  },
  {
    "LONG": -4.65,
    "LAT": -12.79,
    "CIDADE": "Lavandeira",
    "UF": "TOCANTINS",
    "anual": 5.74,
    "JAN": 5.55,
    "FEB": 5.67,
    "MAR": 5.59,
    "APR": 5.81,
    "MAY": 5.81,
    "JUN": 5.69,
    "JUL": 5.99,
    "AUG": 6.3,
    "SEP": 6.14,
    "OCT": 5.67,
    "NOV": 5.21,
    "DEC": 5.43
  },
  {
    "LONG": -46.55,
    "LAT": -12.81,
    "CIDADE": "Combinado",
    "UF": "TOCANTINS",
    "anual": 5.74,
    "JAN": 5.55,
    "FEB": 5.67,
    "MAR": 5.59,
    "APR": 5.81,
    "MAY": 5.81,
    "JUN": 5.69,
    "JUL": 5.99,
    "AUG": 6.3,
    "SEP": 6.14,
    "OCT": 5.67,
    "NOV": 5.21,
    "DEC": 5.43
  },
  {
    "LONG": -4.86,
    "LAT": -12.65,
    "CIDADE": "Jaú do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.49,
    "JAN": 5.07,
    "FEB": 5.26,
    "MAR": 5.19,
    "APR": 5.51,
    "MAY": 5.72,
    "JUN": 5.62,
    "JUL": 5.83,
    "AUG": 6.39,
    "SEP": 6.01,
    "OCT": 5.42,
    "NOV": 4.87,
    "DEC": 4.95
  },
  {
    "LONG": -48.24,
    "LAT": -12.75,
    "CIDADE": "São Salvador do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.52,
    "JAN": 5.23,
    "FEB": 5.42,
    "MAR": 5.33,
    "APR": 5.63,
    "MAY": 5.65,
    "JUN": 5.49,
    "JUL": 5.75,
    "AUG": 6.26,
    "SEP": 5.98,
    "OCT": 5.53,
    "NOV": 4.93,
    "DEC": 5.02
  },
  {
    "LONG": -4.64,
    "LAT": -12.71,
    "CIDADE": "Aurora do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.72,
    "JAN": 5.49,
    "FEB": 5.59,
    "MAR": 5.53,
    "APR": 5.71,
    "MAY": 5.83,
    "JUN": 5.73,
    "JUL": 5.97,
    "AUG": 6.42,
    "SEP": 6.26,
    "OCT": 5.65,
    "NOV": 5.12,
    "DEC": 5.4
  },
  {
    "LONG": -47.87,
    "LAT": -12.62,
    "CIDADE": "Paranã",
    "UF": "TOCANTINS",
    "anual": 5.62,
    "JAN": 5.33,
    "FEB": 5.49,
    "MAR": 5.41,
    "APR": 5.66,
    "MAY": 5.69,
    "JUN": 5.64,
    "JUL": 6.01,
    "AUG": 6.4,
    "SEP": 6.1,
    "OCT": 5.54,
    "NOV": 5.06,
    "DEC": 5.17
  },
  {
    "LONG": -4.99,
    "LAT": -12.54,
    "CIDADE": "Sandolândia",
    "UF": "TOCANTINS",
    "anual": 5.4,
    "JAN": 4.97,
    "FEB": 5.18,
    "MAR": 5.09,
    "APR": 5.39,
    "MAY": 5.55,
    "JUN": 5.53,
    "JUL": 5.89,
    "AUG": 6.34,
    "SEP": 5.81,
    "OCT": 5.27,
    "NOV": 4.86,
    "DEC": 4.88
  },
  {
    "LONG": -49.13,
    "LAT": -12.48,
    "CIDADE": "Alvorada",
    "UF": "TOCANTINS",
    "anual": 5.43,
    "JAN": 5.05,
    "FEB": 5.24,
    "MAR": 5.19,
    "APR": 5.46,
    "MAY": 5.57,
    "JUN": 5.52,
    "JUL": 5.78,
    "AUG": 6.17,
    "SEP": 5.93,
    "OCT": 5.44,
    "NOV": 4.91,
    "DEC": 4.94
  },
  {
    "LONG": -46.43,
    "LAT": -12.4,
    "CIDADE": "Taguatinga",
    "UF": "TOCANTINS",
    "anual": 5.68,
    "JAN": 5.34,
    "FEB": 5.36,
    "MAR": 5.31,
    "APR": 5.53,
    "MAY": 5.8,
    "JUN": 5.85,
    "JUL": 6.12,
    "AUG": 6.57,
    "SEP": 6.28,
    "OCT": 5.65,
    "NOV": 5.03,
    "DEC": 5.3
  },
  {
    "LONG": -47.3,
    "LAT": -12.22,
    "CIDADE": "Conceição do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.73,
    "JAN": 5.39,
    "FEB": 5.56,
    "MAR": 5.47,
    "APR": 5.68,
    "MAY": 5.87,
    "JUN": 5.8,
    "JUL": 6.06,
    "AUG": 6.45,
    "SEP": 6.28,
    "OCT": 5.7,
    "NOV": 5.24,
    "DEC": 5.27
  },
  {
    "LONG": -46.99,
    "LAT": -12.19,
    "CIDADE": "Taipas do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.73,
    "JAN": 5.41,
    "FEB": 5.58,
    "MAR": 5.46,
    "APR": 5.73,
    "MAY": 5.85,
    "JUN": 5.82,
    "JUL": 6,
    "AUG": 6.4,
    "SEP": 6.29,
    "OCT": 5.72,
    "NOV": 5.23,
    "DEC": 5.33
  },
  {
    "LONG": -49.17,
    "LAT": -12.14,
    "CIDADE": "Figueirópolis",
    "UF": "TOCANTINS",
    "anual": 5.38,
    "JAN": 5,
    "FEB": 5.21,
    "MAR": 5.1,
    "APR": 5.41,
    "MAY": 5.57,
    "JUN": 5.37,
    "JUL": 5.68,
    "AUG": 6.18,
    "SEP": 5.89,
    "OCT": 5.35,
    "NOV": 4.92,
    "DEC": 4.9
  },
  {
    "LONG": -46.47,
    "LAT": -12.09,
    "CIDADE": "Ponte Alta do Bom Jesus",
    "UF": "TOCANTINS",
    "anual": 5.63,
    "JAN": 5.39,
    "FEB": 5.42,
    "MAR": 5.18,
    "APR": 5.33,
    "MAY": 5.75,
    "JUN": 5.76,
    "JUL": 6.1,
    "AUG": 6.49,
    "SEP": 6.31,
    "OCT": 5.64,
    "NOV": 5.01,
    "DEC": 5.17
  },
  {
    "LONG": -48.91,
    "LAT": -11.99,
    "CIDADE": "Sucupira",
    "UF": "TOCANTINS",
    "anual": 5.42,
    "JAN": 5.03,
    "FEB": 5.31,
    "MAR": 5.13,
    "APR": 5.45,
    "MAY": 5.6,
    "JUN": 5.44,
    "JUL": 5.7,
    "AUG": 6.12,
    "SEP": 5.96,
    "OCT": 5.41,
    "NOV": 4.95,
    "DEC": 4.98
  },
  {
    "LONG": -48.54,
    "LAT": -12.04,
    "CIDADE": "Peixe",
    "UF": "TOCANTINS",
    "anual": 5.46,
    "JAN": 5.09,
    "FEB": 5.3,
    "MAR": 5.2,
    "APR": 5.43,
    "MAY": 5.56,
    "JUN": 5.55,
    "JUL": 5.8,
    "AUG": 6.22,
    "SEP": 5.97,
    "OCT": 5.48,
    "NOV": 5,
    "DEC": 4.98
  },
  {
    "LONG": -48.24,
    "LAT": -11.97,
    "CIDADE": "São Valério da Natividade",
    "UF": "TOCANTINS",
    "anual": 5.49,
    "JAN": 5.08,
    "FEB": 5.26,
    "MAR": 5.14,
    "APR": 5.44,
    "MAY": 5.66,
    "JUN": 5.59,
    "JUL": 5.87,
    "AUG": 6.3,
    "SEP": 6.06,
    "OCT": 5.46,
    "NOV": 5.05,
    "DEC": 4.98
  },
  {
    "LONG": -49.16,
    "LAT": -11.89,
    "CIDADE": "Cariri do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.36,
    "JAN": 4.96,
    "FEB": 5.19,
    "MAR": 5.06,
    "APR": 5.34,
    "MAY": 5.55,
    "JUN": 5.39,
    "JUL": 5.68,
    "AUG": 6.14,
    "SEP": 5.86,
    "OCT": 5.31,
    "NOV": 4.92,
    "DEC": 4.87
  },
  {
    "LONG": -49.53,
    "LAT": -11.81,
    "CIDADE": "Formoso do Araguaia",
    "UF": "TOCANTINS",
    "anual": 5.33,
    "JAN": 4.97,
    "FEB": 5.12,
    "MAR": 5.11,
    "APR": 5.27,
    "MAY": 5.5,
    "JUN": 5.4,
    "JUL": 5.56,
    "AUG": 6.16,
    "SEP": 5.75,
    "OCT": 5.33,
    "NOV": 4.92,
    "DEC": 4.92
  },
  {
    "LONG": -4.66,
    "LAT": -11.82,
    "CIDADE": "Novo Jardim",
    "UF": "TOCANTINS",
    "anual": 5.6,
    "JAN": 5.23,
    "FEB": 5.24,
    "MAR": 5.14,
    "APR": 5.44,
    "MAY": 5.75,
    "JUN": 5.82,
    "JUL": 6.01,
    "AUG": 6.57,
    "SEP": 6.34,
    "OCT": 5.57,
    "NOV": 5.05,
    "DEC": 5.07
  },
  {
    "LONG": -49.07,
    "LAT": -11.73,
    "CIDADE": "Gurupi",
    "UF": "TOCANTINS",
    "anual": 5.38,
    "JAN": 4.9,
    "FEB": 5.2,
    "MAR": 5.04,
    "APR": 5.3,
    "MAY": 5.52,
    "JUN": 5.46,
    "JUL": 5.78,
    "AUG": 6.23,
    "SEP": 5.91,
    "OCT": 5.33,
    "NOV": 4.95,
    "DEC": 4.92
  },
  {
    "LONG": -47.73,
    "LAT": -11.71,
    "CIDADE": "Natividade",
    "UF": "TOCANTINS",
    "anual": 5.57,
    "JAN": 5.16,
    "FEB": 5.34,
    "MAR": 5.18,
    "APR": 5.47,
    "MAY": 5.72,
    "JUN": 5.68,
    "JUL": 5.95,
    "AUG": 6.49,
    "SEP": 6.2,
    "OCT": 5.52,
    "NOV": 5.09,
    "DEC": 5.06
  },
  {
    "LONG": -4.77,
    "LAT": -11.62,
    "CIDADE": "Chapada da Natividade",
    "UF": "TOCANTINS",
    "anual": 5.55,
    "JAN": 5.1,
    "FEB": 5.32,
    "MAR": 5.14,
    "APR": 5.44,
    "MAY": 5.71,
    "JUN": 5.69,
    "JUL": 5.94,
    "AUG": 6.47,
    "SEP": 6.18,
    "OCT": 5.53,
    "NOV": 5.07,
    "DEC": 5.03
  },
  {
    "LONG": -47.17,
    "LAT": -11.57,
    "CIDADE": "Almas",
    "UF": "TOCANTINS",
    "anual": 5.64,
    "JAN": 5.28,
    "FEB": 5.34,
    "MAR": 5.22,
    "APR": 5.53,
    "MAY": 5.81,
    "JUN": 5.79,
    "JUL": 6,
    "AUG": 6.52,
    "SEP": 6.26,
    "OCT": 5.6,
    "NOV": 5.12,
    "DEC": 5.16
  },
  {
    "LONG": -47.05,
    "LAT": -11.61,
    "CIDADE": "Porto Alegre do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.64,
    "JAN": 5.24,
    "FEB": 5.3,
    "MAR": 5.19,
    "APR": 5.53,
    "MAY": 5.83,
    "JUN": 5.83,
    "JUL": 6.06,
    "AUG": 6.59,
    "SEP": 6.29,
    "OCT": 5.61,
    "NOV": 5.1,
    "DEC": 5.15
  },
  {
    "LONG": -46.82,
    "LAT": -11.62,
    "CIDADE": "Dianópolis",
    "UF": "TOCANTINS",
    "anual": 5.6,
    "JAN": 5.19,
    "FEB": 5.21,
    "MAR": 5.12,
    "APR": 5.4,
    "MAY": 5.77,
    "JUN": 5.81,
    "JUL": 6.08,
    "AUG": 6.6,
    "SEP": 6.36,
    "OCT": 5.56,
    "NOV": 5.06,
    "DEC": 5.03
  },
  {
    "LONG": -48.12,
    "LAT": -11.45,
    "CIDADE": "Santa Rosa do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.51,
    "JAN": 5.08,
    "FEB": 5.25,
    "MAR": 5.11,
    "APR": 5.36,
    "MAY": 5.63,
    "JUN": 5.68,
    "JUL": 5.96,
    "AUG": 6.47,
    "SEP": 6.06,
    "OCT": 5.49,
    "NOV": 5.05,
    "DEC": 5
  },
  {
    "LONG": -46.88,
    "LAT": -11.4,
    "CIDADE": "Rio da Conceição",
    "UF": "TOCANTINS",
    "anual": 5.47,
    "JAN": 5.1,
    "FEB": 5.08,
    "MAR": 5,
    "APR": 5.24,
    "MAY": 5.57,
    "JUN": 5.71,
    "JUL": 5.92,
    "AUG": 6.46,
    "SEP": 6.23,
    "OCT": 5.43,
    "NOV": 4.95,
    "DEC": 4.93
  },
  {
    "LONG": -4.93,
    "LAT": -11.34,
    "CIDADE": "Dueré",
    "UF": "TOCANTINS",
    "anual": 5.37,
    "JAN": 4.88,
    "FEB": 5.03,
    "MAR": 4.97,
    "APR": 5.26,
    "MAY": 5.49,
    "JUN": 5.61,
    "JUL": 5.91,
    "AUG": 6.39,
    "SEP": 5.82,
    "OCT": 5.28,
    "NOV": 4.9,
    "DEC": 4.91
  },
  {
    "LONG": -48.94,
    "LAT": -1.13,
    "CIDADE": "Aliança do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.38,
    "JAN": 4.87,
    "FEB": 5.11,
    "MAR": 4.95,
    "APR": 5.26,
    "MAY": 5.5,
    "JUN": 5.55,
    "JUL": 5.87,
    "AUG": 6.35,
    "SEP": 5.86,
    "OCT": 5.33,
    "NOV": 4.97,
    "DEC": 4.89
  },
  {
    "LONG": -48.46,
    "LAT": -11.24,
    "CIDADE": "Ipueiras",
    "UF": "TOCANTINS",
    "anual": 5.41,
    "JAN": 4.87,
    "FEB": 5.23,
    "MAR": 5.09,
    "APR": 5.4,
    "MAY": 5.59,
    "JUN": 5.61,
    "JUL": 5.7,
    "AUG": 6.32,
    "SEP": 5.92,
    "OCT": 5.34,
    "NOV": 4.98,
    "DEC": 4.92
  },
  {
    "LONG": -4.89,
    "LAT": -11.1,
    "CIDADE": "Crixás do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.37,
    "JAN": 4.86,
    "FEB": 5.09,
    "MAR": 4.96,
    "APR": 5.26,
    "MAY": 5.52,
    "JUN": 5.59,
    "JUL": 5.92,
    "AUG": 6.38,
    "SEP": 5.78,
    "OCT": 5.26,
    "NOV": 4.96,
    "DEC": 4.85
  },
  {
    "LONG": -48.17,
    "LAT": -11.15,
    "CIDADE": "Silvanópolis",
    "UF": "TOCANTINS",
    "anual": 5.44,
    "JAN": 5.02,
    "FEB": 5.17,
    "MAR": 4.99,
    "APR": 5.39,
    "MAY": 5.6,
    "JUN": 5.63,
    "JUL": 5.82,
    "AUG": 6.38,
    "SEP": 6,
    "OCT": 5.35,
    "NOV": 4.95,
    "DEC": 4.92
  },
  {
    "LONG": -47.58,
    "LAT": -11.14,
    "CIDADE": "Pindorama do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.55,
    "JAN": 5.1,
    "FEB": 5.1,
    "MAR": 5.05,
    "APR": 5.43,
    "MAY": 5.71,
    "JUN": 5.84,
    "JUL": 6.08,
    "AUG": 6.56,
    "SEP": 6.24,
    "OCT": 5.5,
    "NOV": 5.03,
    "DEC": 4.98
  },
  {
    "LONG": -48.57,
    "LAT": -11.02,
    "CIDADE": "Brejinho de Nazaré",
    "UF": "TOCANTINS",
    "anual": 5.37,
    "JAN": 4.91,
    "FEB": 5.09,
    "MAR": 5.01,
    "APR": 5.36,
    "MAY": 5.55,
    "JUN": 5.6,
    "JUL": 5.7,
    "AUG": 6.26,
    "SEP": 5.91,
    "OCT": 5.36,
    "NOV": 4.91,
    "DEC": 4.83
  },
  {
    "LONG": -48.91,
    "LAT": -10.86,
    "CIDADE": "Santa Rita do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.33,
    "JAN": 4.81,
    "FEB": 4.99,
    "MAR": 4.9,
    "APR": 5.2,
    "MAY": 5.48,
    "JUN": 5.66,
    "JUL": 5.9,
    "AUG": 6.29,
    "SEP": 5.79,
    "OCT": 5.21,
    "NOV": 4.92,
    "DEC": 4.77
  },
  {
    "LONG": -49.62,
    "LAT": -10.79,
    "CIDADE": "Lagoa da Confusão",
    "UF": "TOCANTINS",
    "anual": 5.27,
    "JAN": 4.86,
    "FEB": 5.01,
    "MAR": 4.91,
    "APR": 5.15,
    "MAY": 5.41,
    "JUN": 5.53,
    "JUL": 5.69,
    "AUG": 6.15,
    "SEP": 5.66,
    "OCT": 5.22,
    "NOV": 4.88,
    "DEC": 4.8
  },
  {
    "LONG": -4.89,
    "LAT": -10.76,
    "CIDADE": "Fátima",
    "UF": "TOCANTINS",
    "anual": 5.31,
    "JAN": 4.73,
    "FEB": 4.93,
    "MAR": 4.86,
    "APR": 5.17,
    "MAY": 5.43,
    "JUN": 5.66,
    "JUL": 5.99,
    "AUG": 6.36,
    "SEP": 5.81,
    "OCT": 5.17,
    "NOV": 4.87,
    "DEC": 4.72
  },
  {
    "LONG": -48.11,
    "LAT": -10.76,
    "CIDADE": "Monte do Carmo",
    "UF": "TOCANTINS",
    "anual": 5.37,
    "JAN": 4.91,
    "FEB": 4.96,
    "MAR": 4.81,
    "APR": 5.27,
    "MAY": 5.54,
    "JUN": 5.57,
    "JUL": 5.88,
    "AUG": 6.47,
    "SEP": 6.06,
    "OCT": 5.28,
    "NOV": 4.87,
    "DEC": 4.86
  },
  {
    "LONG": -4.89,
    "LAT": -10.71,
    "CIDADE": "Oliveira de Fátima",
    "UF": "TOCANTINS",
    "anual": 5.28,
    "JAN": 4.67,
    "FEB": 4.85,
    "MAR": 4.82,
    "APR": 5.17,
    "MAY": 5.36,
    "JUN": 5.65,
    "JUL": 5.89,
    "AUG": 6.36,
    "SEP": 5.83,
    "OCT": 5.22,
    "NOV": 4.85,
    "DEC": 4.71
  },
  {
    "LONG": -48.41,
    "LAT": -10.7,
    "CIDADE": "Porto Nacional",
    "UF": "TOCANTINS",
    "anual": 5.34,
    "JAN": 4.97,
    "FEB": 5.08,
    "MAR": 4.95,
    "APR": 5.38,
    "MAY": 5.58,
    "JUN": 5.51,
    "JUL": 5.67,
    "AUG": 6.05,
    "SEP": 5.9,
    "OCT": 5.34,
    "NOV": 4.86,
    "DEC": 4.82
  },
  {
    "LONG": -47.54,
    "LAT": -10.74,
    "CIDADE": "Ponte Alta do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.48,
    "JAN": 5,
    "FEB": 5.14,
    "MAR": 5.03,
    "APR": 5.37,
    "MAY": 5.61,
    "JUN": 5.69,
    "JUL": 5.9,
    "AUG": 6.46,
    "SEP": 6.14,
    "OCT": 5.47,
    "NOV": 4.99,
    "DEC": 4.99
  },
  {
    "LONG": -49.19,
    "LAT": -10.6,
    "CIDADE": "Cristalândia",
    "UF": "TOCANTINS",
    "anual": 5.27,
    "JAN": 4.72,
    "FEB": 4.87,
    "MAR": 4.79,
    "APR": 5.09,
    "MAY": 5.37,
    "JUN": 5.68,
    "JUL": 5.82,
    "AUG": 6.38,
    "SEP": 5.79,
    "OCT": 5.23,
    "NOV": 4.83,
    "DEC": 4.67
  },
  {
    "LONG": -48.91,
    "LAT": -10.57,
    "CIDADE": "Nova Rosalândia",
    "UF": "TOCANTINS",
    "anual": 5.29,
    "JAN": 4.72,
    "FEB": 4.88,
    "MAR": 4.82,
    "APR": 5.17,
    "MAY": 5.4,
    "JUN": 5.66,
    "JUL": 5.8,
    "AUG": 6.36,
    "SEP": 5.83,
    "OCT": 5.24,
    "NOV": 4.85,
    "DEC": 4.72
  },
  {
    "LONG": -46.42,
    "LAT": -10.55,
    "CIDADE": "Mateiros",
    "UF": "TOCANTINS",
    "anual": 5.51,
    "JAN": 5.13,
    "FEB": 5.06,
    "MAR": 4.98,
    "APR": 5.3,
    "MAY": 5.58,
    "JUN": 5.64,
    "JUL": 5.87,
    "AUG": 6.51,
    "SEP": 6.35,
    "OCT": 5.59,
    "NOV": 5.06,
    "DEC": 5
  },
  {
    "LONG": -49.18,
    "LAT": -10.44,
    "CIDADE": "Pium",
    "UF": "TOCANTINS",
    "anual": 5.26,
    "JAN": 4.73,
    "FEB": 4.83,
    "MAR": 4.75,
    "APR": 5.06,
    "MAY": 5.36,
    "JUN": 5.64,
    "JUL": 5.9,
    "AUG": 6.38,
    "SEP": 5.79,
    "OCT": 5.22,
    "NOV": 4.83,
    "DEC": 4.68
  },
  {
    "LONG": -48.9,
    "LAT": -10.42,
    "CIDADE": "Pugmil",
    "UF": "TOCANTINS",
    "anual": 5.29,
    "JAN": 4.75,
    "FEB": 4.89,
    "MAR": 4.79,
    "APR": 5.21,
    "MAY": 5.43,
    "JUN": 5.66,
    "JUL": 5.78,
    "AUG": 6.34,
    "SEP": 5.84,
    "OCT": 5.22,
    "NOV": 4.86,
    "DEC": 4.69
  },
  {
    "LONG": -47.81,
    "LAT": -10.28,
    "CIDADE": "Santa Tereza do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.42,
    "JAN": 5.01,
    "FEB": 5.03,
    "MAR": 5.05,
    "APR": 5.32,
    "MAY": 5.62,
    "JUN": 5.63,
    "JUL": 5.92,
    "AUG": 6.37,
    "SEP": 5.98,
    "OCT": 5.3,
    "NOV": 4.95,
    "DEC": 4.92
  },
  {
    "LONG": -47.57,
    "LAT": -10.3,
    "CIDADE": "Lagoa do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.49,
    "JAN": 5.09,
    "FEB": 5.16,
    "MAR": 5.08,
    "APR": 5.38,
    "MAY": 5.66,
    "JUN": 5.65,
    "JUL": 5.83,
    "AUG": 6.34,
    "SEP": 6.17,
    "OCT": 5.44,
    "NOV": 5.1,
    "DEC": 5.03
  },
  {
    "LONG": -48.88,
    "LAT": -10.18,
    "CIDADE": "Paraíso do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.23,
    "JAN": 4.66,
    "FEB": 4.83,
    "MAR": 4.63,
    "APR": 5.06,
    "MAY": 5.4,
    "JUN": 5.61,
    "JUL": 5.79,
    "AUG": 6.41,
    "SEP": 5.79,
    "OCT": 5.14,
    "NOV": 4.78,
    "DEC": 4.61
  },
  {
    "LONG": -48.36,
    "LAT": -10.24,
    "CIDADE": "Palmas",
    "UF": "TOCANTINS",
    "anual": 5.29,
    "JAN": 4.84,
    "FEB": 4.97,
    "MAR": 4.87,
    "APR": 5.18,
    "MAY": 5.38,
    "JUN": 5.55,
    "JUL": 5.72,
    "AUG": 6.31,
    "SEP": 5.81,
    "OCT": 5.28,
    "NOV": 4.88,
    "DEC": 4.75
  },
  {
    "LONG": -46.66,
    "LAT": -10.16,
    "CIDADE": "São Félix do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.55,
    "JAN": 5.13,
    "FEB": 5.13,
    "MAR": 5.02,
    "APR": 5.35,
    "MAY": 5.68,
    "JUN": 5.73,
    "JUL": 5.97,
    "AUG": 6.47,
    "SEP": 6.32,
    "OCT": 5.61,
    "NOV": 5.1,
    "DEC": 5.05
  },
  {
    "LONG": -49.15,
    "LAT": -10.14,
    "CIDADE": "Chapada de Areia",
    "UF": "TOCANTINS",
    "anual": 5.26,
    "JAN": 4.73,
    "FEB": 4.85,
    "MAR": 4.77,
    "APR": 5.05,
    "MAY": 5.41,
    "JUN": 5.6,
    "JUL": 5.84,
    "AUG": 6.36,
    "SEP": 5.75,
    "OCT": 5.2,
    "NOV": 4.84,
    "DEC": 4.72
  },
  {
    "LONG": -48.99,
    "LAT": -10.01,
    "CIDADE": "Monte Santo do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.22,
    "JAN": 4.66,
    "FEB": 4.8,
    "MAR": 4.66,
    "APR": 5.01,
    "MAY": 5.37,
    "JUN": 5.69,
    "JUL": 5.89,
    "AUG": 6.37,
    "SEP": 5.73,
    "OCT": 5.05,
    "NOV": 4.81,
    "DEC": 4.63
  },
  {
    "LONG": -4.8,
    "LAT": -9.95,
    "CIDADE": "Aparecida do Rio Negro",
    "UF": "TOCANTINS",
    "anual": 5.38,
    "JAN": 4.9,
    "FEB": 5.03,
    "MAR": 5.02,
    "APR": 5.23,
    "MAY": 5.54,
    "JUN": 5.57,
    "JUL": 5.78,
    "AUG": 6.43,
    "SEP": 5.95,
    "OCT": 5.31,
    "NOV": 4.88,
    "DEC": 4.86
  },
  {
    "LONG": -47.68,
    "LAT": -9.97,
    "CIDADE": "Novo Acordo",
    "UF": "TOCANTINS",
    "anual": 5.46,
    "JAN": 5,
    "FEB": 5.07,
    "MAR": 5.02,
    "APR": 5.31,
    "MAY": 5.59,
    "JUN": 5.64,
    "JUL": 5.89,
    "AUG": 6.55,
    "SEP": 6.07,
    "OCT": 5.39,
    "NOV": 5,
    "DEC": 4.98
  },
  {
    "LONG": -49.66,
    "LAT": -9.79,
    "CIDADE": "Marianópolis do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.19,
    "JAN": 4.72,
    "FEB": 4.86,
    "MAR": 4.78,
    "APR": 4.91,
    "MAY": 5.26,
    "JUN": 5.53,
    "JUL": 5.79,
    "AUG": 6.25,
    "SEP": 5.61,
    "OCT": 5.07,
    "NOV": 4.79,
    "DEC": 4.73
  },
  {
    "LONG": -49.22,
    "LAT": -9.8,
    "CIDADE": "Divinópolis do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.22,
    "JAN": 4.7,
    "FEB": 4.84,
    "MAR": 4.75,
    "APR": 4.94,
    "MAY": 5.32,
    "JUN": 5.57,
    "JUL": 5.85,
    "AUG": 6.3,
    "SEP": 5.69,
    "OCT": 5.12,
    "NOV": 4.84,
    "DEC": 4.66
  },
  {
    "LONG": -48.73,
    "LAT": -9.83,
    "CIDADE": "Barrolândia",
    "UF": "TOCANTINS",
    "anual": 5.22,
    "JAN": 4.68,
    "FEB": 4.86,
    "MAR": 4.74,
    "APR": 5.06,
    "MAY": 5.38,
    "JUN": 5.6,
    "JUL": 5.79,
    "AUG": 6.36,
    "SEP": 5.71,
    "OCT": 5.02,
    "NOV": 4.78,
    "DEC": 4.62
  },
  {
    "LONG": -48.36,
    "LAT": -9.75,
    "CIDADE": "Lajeado",
    "UF": "TOCANTINS",
    "anual": 5.29,
    "JAN": 4.71,
    "FEB": 4.85,
    "MAR": 4.81,
    "APR": 5.09,
    "MAY": 5.43,
    "JUN": 5.63,
    "JUL": 5.81,
    "AUG": 6.45,
    "SEP": 5.86,
    "OCT": 5.23,
    "NOV": 4.83,
    "DEC": 4.73
  },
  {
    "LONG": -49.16,
    "LAT": -9.62,
    "CIDADE": "Abreulândia",
    "UF": "TOCANTINS",
    "anual": 5.2,
    "JAN": 4.66,
    "FEB": 4.79,
    "MAR": 4.75,
    "APR": 4.94,
    "MAY": 5.34,
    "JUN": 5.57,
    "JUL": 5.82,
    "AUG": 6.34,
    "SEP": 5.69,
    "OCT": 5.05,
    "NOV": 4.82,
    "DEC": 4.64
  },
  {
    "LONG": -48.37,
    "LAT": -9.56,
    "CIDADE": "Tocantínia",
    "UF": "TOCANTINS",
    "anual": 5.32,
    "JAN": 4.75,
    "FEB": 4.91,
    "MAR": 4.9,
    "APR": 5.16,
    "MAY": 5.49,
    "JUN": 5.6,
    "JUL": 5.78,
    "AUG": 6.44,
    "SEP": 5.92,
    "OCT": 5.24,
    "NOV": 4.86,
    "DEC": 4.74
  },
  {
    "LONG": -48.39,
    "LAT": -0.96,
    "CIDADE": "Miracema do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.32,
    "JAN": 4.75,
    "FEB": 4.91,
    "MAR": 4.9,
    "APR": 5.16,
    "MAY": 5.49,
    "JUN": 5.6,
    "JUL": 5.78,
    "AUG": 6.44,
    "SEP": 5.92,
    "OCT": 5.24,
    "NOV": 4.86,
    "DEC": 4.74
  },
  {
    "LONG": -46.67,
    "LAT": -9.6,
    "CIDADE": "Lizarda",
    "UF": "TOCANTINS",
    "anual": 5.46,
    "JAN": 5.06,
    "FEB": 4.98,
    "MAR": 4.97,
    "APR": 5.31,
    "MAY": 5.7,
    "JUN": 5.59,
    "JUL": 5.73,
    "AUG": 6.32,
    "SEP": 6.36,
    "OCT": 5.58,
    "NOV": 5.03,
    "DEC": 4.94
  },
  {
    "LONG": -48.59,
    "LAT": -9.53,
    "CIDADE": "Miranorte",
    "UF": "TOCANTINS",
    "anual": 5.26,
    "JAN": 4.77,
    "FEB": 4.93,
    "MAR": 4.84,
    "APR": 5.11,
    "MAY": 5.43,
    "JUN": 5.56,
    "JUL": 5.73,
    "AUG": 6.36,
    "SEP": 5.85,
    "OCT": 5.08,
    "NOV": 4.78,
    "DEC": 4.66
  },
  {
    "LONG": -49.96,
    "LAT": -9.27,
    "CIDADE": "Caseara",
    "UF": "TOCANTINS",
    "anual": 5.18,
    "JAN": 4.82,
    "FEB": 4.86,
    "MAR": 4.85,
    "APR": 4.93,
    "MAY": 5.27,
    "JUN": 5.48,
    "JUL": 5.72,
    "AUG": 6.1,
    "SEP": 5.5,
    "OCT": 5.08,
    "NOV": 4.84,
    "DEC": 4.74
  },
  {
    "LONG": -49.06,
    "LAT": -9.26,
    "CIDADE": "Dois Irmãos do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.21,
    "JAN": 4.67,
    "FEB": 4.8,
    "MAR": 4.76,
    "APR": 4.93,
    "MAY": 5.41,
    "JUN": 5.56,
    "JUL": 5.76,
    "AUG": 6.3,
    "SEP": 5.74,
    "OCT": 5.11,
    "NOV": 4.81,
    "DEC": 4.62
  },
  {
    "LONG": -48.53,
    "LAT": -9.34,
    "CIDADE": "Rio dos Bois",
    "UF": "TOCANTINS",
    "anual": 5.23,
    "JAN": 4.7,
    "FEB": 4.9,
    "MAR": 4.85,
    "APR": 5.03,
    "MAY": 5.38,
    "JUN": 5.54,
    "JUL": 5.7,
    "AUG": 6.33,
    "SEP": 5.82,
    "OCT": 5.08,
    "NOV": 4.77,
    "DEC": 4.61
  },
  {
    "LONG": -47.89,
    "LAT": -9.35,
    "CIDADE": "Rio Sono",
    "UF": "TOCANTINS",
    "anual": 5.38,
    "JAN": 4.77,
    "FEB": 4.96,
    "MAR": 4.95,
    "APR": 5.24,
    "MAY": 5.57,
    "JUN": 5.68,
    "JUL": 5.82,
    "AUG": 6.53,
    "SEP": 6.05,
    "OCT": 5.3,
    "NOV": 4.9,
    "DEC": 4.77
  },
  {
    "LONG": -48.52,
    "LAT": -9.06,
    "CIDADE": "Fortaleza do Tabocão",
    "UF": "TOCANTINS",
    "anual": 5.21,
    "JAN": 4.66,
    "FEB": 4.88,
    "MAR": 4.82,
    "APR": 4.99,
    "MAY": 5.37,
    "JUN": 5.56,
    "JUL": 5.73,
    "AUG": 6.3,
    "SEP": 5.85,
    "OCT": 5.01,
    "NOV": 4.71,
    "DEC": 4.62
  },
  {
    "LONG": -48.17,
    "LAT": -8.96,
    "CIDADE": "Bom Jesus do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.3,
    "JAN": 4.82,
    "FEB": 5,
    "MAR": 4.96,
    "APR": 5.07,
    "MAY": 5.47,
    "JUN": 5.61,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 5.88,
    "OCT": 5.2,
    "NOV": 4.86,
    "DEC": 4.73
  },
  {
    "LONG": -48.17,
    "LAT": -8.97,
    "CIDADE": "Pedro Afonso",
    "UF": "TOCANTINS",
    "anual": 5.3,
    "JAN": 4.82,
    "FEB": 5,
    "MAR": 4.96,
    "APR": 5.07,
    "MAY": 5.47,
    "JUN": 5.61,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 5.88,
    "OCT": 5.2,
    "NOV": 4.86,
    "DEC": 4.73
  },
  {
    "LONG": -48.19,
    "LAT": -8.98,
    "CIDADE": "Tupirama",
    "UF": "TOCANTINS",
    "anual": 5.3,
    "JAN": 4.82,
    "FEB": 5,
    "MAR": 4.96,
    "APR": 5.07,
    "MAY": 5.47,
    "JUN": 5.61,
    "JUL": 5.69,
    "AUG": 6.3,
    "SEP": 5.88,
    "OCT": 5.2,
    "NOV": 4.86,
    "DEC": 4.73
  },
  {
    "LONG": -4.73,
    "LAT": -8.96,
    "CIDADE": "Centenário",
    "UF": "TOCANTINS",
    "anual": 5.35,
    "JAN": 4.81,
    "FEB": 4.85,
    "MAR": 4.84,
    "APR": 5.09,
    "MAY": 5.54,
    "JUN": 5.65,
    "JUL": 5.85,
    "AUG": 6.53,
    "SEP": 6.2,
    "OCT": 5.35,
    "NOV": 4.79,
    "DEC": 4.7
  },
  {
    "LONG": -49.56,
    "LAT": -0.88,
    "CIDADE": "Araguacema",
    "UF": "TOCANTINS",
    "anual": 5.17,
    "JAN": 4.65,
    "FEB": 4.84,
    "MAR": 4.82,
    "APR": 4.94,
    "MAY": 5.3,
    "JUN": 5.52,
    "JUL": 5.66,
    "AUG": 6.18,
    "SEP": 5.64,
    "OCT": 5,
    "NOV": 4.8,
    "DEC": 4.67
  },
  {
    "LONG": -4.89,
    "LAT": -8.77,
    "CIDADE": "Goianorte",
    "UF": "TOCANTINS",
    "anual": 5.18,
    "JAN": 4.61,
    "FEB": 4.78,
    "MAR": 4.71,
    "APR": 4.92,
    "MAY": 5.34,
    "JUN": 5.56,
    "JUL": 5.77,
    "AUG": 6.39,
    "SEP": 5.76,
    "OCT": 5.01,
    "NOV": 4.74,
    "DEC": 4.56
  },
  {
    "LONG": -48.51,
    "LAT": -8.84,
    "CIDADE": "Guaraí",
    "UF": "TOCANTINS",
    "anual": 5.15,
    "JAN": 4.61,
    "FEB": 4.78,
    "MAR": 4.78,
    "APR": 4.91,
    "MAY": 5.34,
    "JUN": 5.49,
    "JUL": 5.67,
    "AUG": 6.3,
    "SEP": 5.77,
    "OCT": 5.01,
    "NOV": 4.6,
    "DEC": 4.56
  },
  {
    "LONG": -47.75,
    "LAT": -0.88,
    "CIDADE": "Santa Maria do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.3,
    "JAN": 4.72,
    "FEB": 4.9,
    "MAR": 4.86,
    "APR": 5.08,
    "MAY": 5.48,
    "JUN": 5.69,
    "JUL": 5.72,
    "AUG": 6.41,
    "SEP": 6.05,
    "OCT": 5.25,
    "NOV": 4.72,
    "DEC": 4.71
  },
  {
    "LONG": -48.76,
    "LAT": -8.73,
    "CIDADE": "Colméia",
    "UF": "TOCANTINS",
    "anual": 5.13,
    "JAN": 4.56,
    "FEB": 4.67,
    "MAR": 4.65,
    "APR": 4.84,
    "MAY": 5.35,
    "JUN": 5.55,
    "JUL": 5.68,
    "AUG": 6.35,
    "SEP": 5.77,
    "OCT": 4.99,
    "NOV": 4.62,
    "DEC": 4.5
  },
  {
    "LONG": -47.24,
    "LAT": -8.74,
    "CIDADE": "Recursolândia",
    "UF": "TOCANTINS",
    "anual": 5.32,
    "JAN": 4.75,
    "FEB": 4.84,
    "MAR": 4.81,
    "APR": 5.09,
    "MAY": 5.61,
    "JUN": 5.69,
    "JUL": 5.79,
    "AUG": 6.44,
    "SEP": 6.13,
    "OCT": 5.29,
    "NOV": 4.77,
    "DEC": 4.67
  },
  {
    "LONG": -48.93,
    "LAT": -8.59,
    "CIDADE": "Pequizeiro",
    "UF": "TOCANTINS",
    "anual": 5.16,
    "JAN": 4.6,
    "FEB": 4.74,
    "MAR": 4.72,
    "APR": 4.86,
    "MAY": 5.31,
    "JUN": 5.63,
    "JUL": 5.77,
    "AUG": 6.32,
    "SEP": 5.74,
    "OCT": 4.98,
    "NOV": 4.69,
    "DEC": 4.49
  },
  {
    "LONG": -48.69,
    "LAT": -8.57,
    "CIDADE": "Itaporã do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.1,
    "JAN": 4.51,
    "FEB": 4.7,
    "MAR": 4.66,
    "APR": 4.85,
    "MAY": 5.33,
    "JUN": 5.61,
    "JUL": 5.65,
    "AUG": 6.25,
    "SEP": 5.73,
    "OCT": 4.91,
    "NOV": 4.56,
    "DEC": 4.45
  },
  {
    "LONG": -48.51,
    "LAT": -8.54,
    "CIDADE": "Presidente Kennedy",
    "UF": "TOCANTINS",
    "anual": 5.12,
    "JAN": 4.57,
    "FEB": 4.8,
    "MAR": 4.74,
    "APR": 4.9,
    "MAY": 5.31,
    "JUN": 5.61,
    "JUL": 5.65,
    "AUG": 6.16,
    "SEP": 5.72,
    "OCT": 4.95,
    "NOV": 4.59,
    "DEC": 4.49
  },
  {
    "LONG": -48.48,
    "LAT": -8.39,
    "CIDADE": "Brasilândia do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.13,
    "JAN": 4.59,
    "FEB": 4.78,
    "MAR": 4.74,
    "APR": 4.87,
    "MAY": 5.25,
    "JUN": 5.6,
    "JUL": 5.65,
    "AUG": 6.18,
    "SEP": 5.77,
    "OCT": 5.02,
    "NOV": 4.58,
    "DEC": 4.52
  },
  {
    "LONG": -48.13,
    "LAT": -8.4,
    "CIDADE": "Tupiratins",
    "UF": "TOCANTINS",
    "anual": 5.25,
    "JAN": 4.72,
    "FEB": 4.91,
    "MAR": 4.92,
    "APR": 5.09,
    "MAY": 5.45,
    "JUN": 5.62,
    "JUL": 5.56,
    "AUG": 6.31,
    "SEP": 5.9,
    "OCT": 5.2,
    "NOV": 4.69,
    "DEC": 4.63
  },
  {
    "LONG": -48.11,
    "LAT": -8.38,
    "CIDADE": "Itapiratins",
    "UF": "TOCANTINS",
    "anual": 5.25,
    "JAN": 4.72,
    "FEB": 4.91,
    "MAR": 4.92,
    "APR": 5.09,
    "MAY": 5.45,
    "JUN": 5.62,
    "JUL": 5.56,
    "AUG": 6.31,
    "SEP": 5.9,
    "OCT": 5.2,
    "NOV": 4.69,
    "DEC": 4.63
  },
  {
    "LONG": -4.78,
    "LAT": -8.39,
    "CIDADE": "Itacajá",
    "UF": "TOCANTINS",
    "anual": 5.26,
    "JAN": 4.74,
    "FEB": 4.83,
    "MAR": 4.85,
    "APR": 5.03,
    "MAY": 5.42,
    "JUN": 5.6,
    "JUL": 5.72,
    "AUG": 6.38,
    "SEP": 6.06,
    "OCT": 5.23,
    "NOV": 4.67,
    "DEC": 4.58
  },
  {
    "LONG": -4.92,
    "LAT": -8.28,
    "CIDADE": "Couto Magalhães",
    "UF": "TOCANTINS",
    "anual": 5.14,
    "JAN": 4.56,
    "FEB": 4.8,
    "MAR": 4.82,
    "APR": 4.88,
    "MAY": 5.24,
    "JUN": 5.55,
    "JUL": 5.65,
    "AUG": 6.18,
    "SEP": 5.73,
    "OCT": 5.04,
    "NOV": 4.68,
    "DEC": 4.52
  },
  {
    "LONG": -49.1,
    "LAT": -8.12,
    "CIDADE": "Juarina",
    "UF": "TOCANTINS",
    "anual": 5.06,
    "JAN": 4.46,
    "FEB": 4.66,
    "MAR": 4.7,
    "APR": 4.83,
    "MAY": 5.21,
    "JUN": 5.51,
    "JUL": 5.59,
    "AUG": 6.21,
    "SEP": 5.63,
    "OCT": 4.94,
    "NOV": 4.58,
    "DEC": 4.39
  },
  {
    "LONG": -48.48,
    "LAT": -8.06,
    "CIDADE": "Colinas do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.17,
    "JAN": 4.65,
    "FEB": 4.84,
    "MAR": 4.81,
    "APR": 4.99,
    "MAY": 5.27,
    "JUN": 5.52,
    "JUL": 5.62,
    "AUG": 6.29,
    "SEP": 5.82,
    "OCT": 5.03,
    "NOV": 4.65,
    "DEC": 4.52
  },
  {
    "LONG": -46.8,
    "LAT": -7.97,
    "CIDADE": "Campos Lindos",
    "UF": "TOCANTINS",
    "anual": 5.31,
    "JAN": 4.68,
    "FEB": 4.78,
    "MAR": 4.86,
    "APR": 5.11,
    "MAY": 5.49,
    "JUN": 5.72,
    "JUL": 5.78,
    "AUG": 6.22,
    "SEP": 6.34,
    "OCT": 5.38,
    "NOV": 4.73,
    "DEC": 4.59
  },
  {
    "LONG": -48.9,
    "LAT": -7.88,
    "CIDADE": "Bernardo Sayão",
    "UF": "TOCANTINS",
    "anual": 5.08,
    "JAN": 4.49,
    "FEB": 4.68,
    "MAR": 4.7,
    "APR": 4.86,
    "MAY": 5.28,
    "JUN": 5.52,
    "JUL": 5.58,
    "AUG": 6.19,
    "SEP": 5.72,
    "OCT": 5.01,
    "NOV": 4.58,
    "DEC": 4.4
  },
  {
    "LONG": -48.58,
    "LAT": -7.76,
    "CIDADE": "Bandeirantes do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.06,
    "JAN": 4.51,
    "FEB": 4.68,
    "MAR": 4.69,
    "APR": 4.83,
    "MAY": 5.25,
    "JUN": 5.51,
    "JUL": 5.54,
    "AUG": 6.18,
    "SEP": 5.73,
    "OCT": 4.93,
    "NOV": 4.52,
    "DEC": 4.33
  },
  {
    "LONG": -47.93,
    "LAT": -7.85,
    "CIDADE": "Palmeirante",
    "UF": "TOCANTINS",
    "anual": 5.21,
    "JAN": 4.62,
    "FEB": 4.81,
    "MAR": 4.93,
    "APR": 5.03,
    "MAY": 5.37,
    "JUN": 5.59,
    "JUL": 5.67,
    "AUG": 6.14,
    "SEP": 5.94,
    "OCT": 5.16,
    "NOV": 4.68,
    "DEC": 4.56
  },
  {
    "LONG": -49.06,
    "LAT": -0.77,
    "CIDADE": "Arapoema",
    "UF": "TOCANTINS",
    "anual": 5.03,
    "JAN": 4.47,
    "FEB": 4.69,
    "MAR": 4.67,
    "APR": 4.76,
    "MAY": 5.21,
    "JUN": 5.45,
    "JUL": 5.49,
    "AUG": 6.16,
    "SEP": 5.66,
    "OCT": 4.94,
    "NOV": 4.51,
    "DEC": 4.38
  },
  {
    "LONG": -4.77,
    "LAT": -7.7,
    "CIDADE": "Barra do Ouro",
    "UF": "TOCANTINS",
    "anual": 5.25,
    "JAN": 4.7,
    "FEB": 4.92,
    "MAR": 5.01,
    "APR": 5.07,
    "MAY": 5.42,
    "JUN": 5.61,
    "JUL": 5.59,
    "AUG": 6.15,
    "SEP": 6.02,
    "OCT": 5.21,
    "NOV": 4.74,
    "DEC": 4.57
  },
  {
    "LONG": -47.32,
    "LAT": -7.71,
    "CIDADE": "Goiatins",
    "UF": "TOCANTINS",
    "anual": 5.28,
    "JAN": 4.75,
    "FEB": 4.84,
    "MAR": 4.96,
    "APR": 5.08,
    "MAY": 5.46,
    "JUN": 5.68,
    "JUL": 5.7,
    "AUG": 6.29,
    "SEP": 6.13,
    "OCT": 5.21,
    "NOV": 4.71,
    "DEC": 4.53
  },
  {
    "LONG": -48.43,
    "LAT": -7.63,
    "CIDADE": "Nova Olinda",
    "UF": "TOCANTINS",
    "anual": 5.07,
    "JAN": 4.45,
    "FEB": 4.7,
    "MAR": 4.71,
    "APR": 4.9,
    "MAY": 5.21,
    "JUN": 5.56,
    "JUL": 5.58,
    "AUG": 6.2,
    "SEP": 5.77,
    "OCT": 4.92,
    "NOV": 4.5,
    "DEC": 4.38
  },
  {
    "LONG": -49.37,
    "LAT": -7.54,
    "CIDADE": "Pau D'Arco",
    "UF": "TOCANTINS",
    "anual": 5.07,
    "JAN": 4.57,
    "FEB": 4.79,
    "MAR": 4.69,
    "APR": 4.85,
    "MAY": 5.18,
    "JUN": 5.46,
    "JUL": 5.48,
    "AUG": 6.01,
    "SEP": 5.67,
    "OCT": 5.02,
    "NOV": 4.57,
    "DEC": 4.53
  },
  {
    "LONG": -47.5,
    "LAT": -7.34,
    "CIDADE": "Filadélfia",
    "UF": "TOCANTINS",
    "anual": 5.23,
    "JAN": 4.66,
    "FEB": 4.81,
    "MAR": 4.81,
    "APR": 4.99,
    "MAY": 5.39,
    "JUN": 5.73,
    "JUL": 5.66,
    "AUG": 6.19,
    "SEP": 6.09,
    "OCT": 5.24,
    "NOV": 4.65,
    "DEC": 4.54
  },
  {
    "LONG": -48.69,
    "LAT": -7.15,
    "CIDADE": "Santa Fé do Araguaia",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.47,
    "FEB": 4.7,
    "MAR": 4.63,
    "APR": 4.82,
    "MAY": 5.17,
    "JUN": 5.49,
    "JUL": 5.57,
    "AUG": 6.1,
    "SEP": 5.67,
    "OCT": 4.9,
    "NOV": 4.54,
    "DEC": 4.4
  },
  {
    "LONG": -48.53,
    "LAT": -7.17,
    "CIDADE": "Aragominas",
    "UF": "TOCANTINS",
    "anual": 5.03,
    "JAN": 4.45,
    "FEB": 4.66,
    "MAR": 4.58,
    "APR": 4.82,
    "MAY": 5.19,
    "JUN": 5.54,
    "JUL": 5.55,
    "AUG": 6.09,
    "SEP": 5.69,
    "OCT": 4.93,
    "NOV": 4.51,
    "DEC": 4.38
  },
  {
    "LONG": -48.2,
    "LAT": -7.19,
    "CIDADE": "Araguaína",
    "UF": "TOCANTINS",
    "anual": 5.07,
    "JAN": 4.46,
    "FEB": 4.72,
    "MAR": 4.6,
    "APR": 4.9,
    "MAY": 5.18,
    "JUN": 5.56,
    "JUL": 5.61,
    "AUG": 6.16,
    "SEP": 5.76,
    "OCT": 4.97,
    "NOV": 4.55,
    "DEC": 4.4
  },
  {
    "LONG": -47.76,
    "LAT": -7.2,
    "CIDADE": "Babaçulândia",
    "UF": "TOCANTINS",
    "anual": 5.24,
    "JAN": 4.67,
    "FEB": 4.84,
    "MAR": 4.94,
    "APR": 5.05,
    "MAY": 5.37,
    "JUN": 5.7,
    "JUL": 5.66,
    "AUG": 6.07,
    "SEP": 6.08,
    "OCT": 5.22,
    "NOV": 4.69,
    "DEC": 4.53
  },
  {
    "LONG": -48.61,
    "LAT": -7.15,
    "CIDADE": "Muricilândia",
    "UF": "TOCANTINS",
    "anual": 5.03,
    "JAN": 4.5,
    "FEB": 4.69,
    "MAR": 4.62,
    "APR": 4.85,
    "MAY": 5.15,
    "JUN": 5.45,
    "JUL": 5.55,
    "AUG": 6.09,
    "SEP": 5.66,
    "OCT": 4.9,
    "NOV": 4.51,
    "DEC": 4.41
  },
  {
    "LONG": -48.4,
    "LAT": -0.7,
    "CIDADE": "Carmolândia",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.57,
    "FEB": 4.69,
    "MAR": 4.59,
    "APR": 4.9,
    "MAY": 5.15,
    "JUN": 5.55,
    "JUL": 5.55,
    "AUG": 6.1,
    "SEP": 5.74,
    "OCT": 4.88,
    "NOV": 4.54,
    "DEC": 4.36
  },
  {
    "LONG": -47.96,
    "LAT": -6.85,
    "CIDADE": "Wanderlândia",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.42,
    "FEB": 4.6,
    "MAR": 4.63,
    "APR": 4.82,
    "MAY": 5.05,
    "JUN": 5.5,
    "JUL": 5.7,
    "AUG": 6.15,
    "SEP": 5.84,
    "OCT": 4.95,
    "NOV": 4.45,
    "DEC": 4.32
  },
  {
    "LONG": -48.29,
    "LAT": -6.77,
    "CIDADE": "Piraquê",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.53,
    "FEB": 4.66,
    "MAR": 4.65,
    "APR": 4.85,
    "MAY": 5.09,
    "JUN": 5.47,
    "JUL": 5.63,
    "AUG": 6.1,
    "SEP": 5.72,
    "OCT": 4.96,
    "NOV": 4.54,
    "DEC": 4.36
  },
  {
    "LONG": -47.75,
    "LAT": -6.71,
    "CIDADE": "Darcinópolis",
    "UF": "TOCANTINS",
    "anual": 5.12,
    "JAN": 4.53,
    "FEB": 4.71,
    "MAR": 4.76,
    "APR": 4.99,
    "MAY": 5.17,
    "JUN": 5.52,
    "JUL": 5.75,
    "AUG": 6.2,
    "SEP": 5.86,
    "OCT": 5.01,
    "NOV": 4.53,
    "DEC": 4.39
  },
  {
    "LONG": -48.64,
    "LAT": -6.58,
    "CIDADE": "Araguanã",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.58,
    "FEB": 4.72,
    "MAR": 4.76,
    "APR": 4.84,
    "MAY": 5.12,
    "JUN": 5.47,
    "JUL": 5.58,
    "AUG": 6.02,
    "SEP": 5.63,
    "OCT": 4.94,
    "NOV": 4.57,
    "DEC": 4.39
  },
  {
    "LONG": -47.55,
    "LAT": -6.61,
    "CIDADE": "Palmeiras do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.17,
    "JAN": 4.55,
    "FEB": 4.76,
    "MAR": 4.87,
    "APR": 5.02,
    "MAY": 5.22,
    "JUN": 5.61,
    "JUL": 5.74,
    "AUG": 6.15,
    "SEP": 5.9,
    "OCT": 5.1,
    "NOV": 4.61,
    "DEC": 4.53
  },
  {
    "LONG": -47.48,
    "LAT": -6.56,
    "CIDADE": "Aguiarnópolis",
    "UF": "TOCANTINS",
    "anual": 5.2,
    "JAN": 4.62,
    "FEB": 4.79,
    "MAR": 4.89,
    "APR": 5.04,
    "MAY": 5.17,
    "JUN": 5.55,
    "JUL": 5.73,
    "AUG": 6.1,
    "SEP": 5.95,
    "OCT": 5.25,
    "NOV": 4.69,
    "DEC": 4.56
  },
  {
    "LONG": -48.53,
    "LAT": -6.41,
    "CIDADE": "Xambioá",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.49,
    "FEB": 4.74,
    "MAR": 4.72,
    "APR": 4.89,
    "MAY": 5.11,
    "JUN": 5.47,
    "JUL": 5.53,
    "AUG": 5.94,
    "SEP": 5.66,
    "OCT": 4.97,
    "NOV": 4.56,
    "DEC": 4.39
  },
  {
    "LONG": -48.14,
    "LAT": -6.44,
    "CIDADE": "Riachinho",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.48,
    "FEB": 4.69,
    "MAR": 4.71,
    "APR": 4.85,
    "MAY": 5.11,
    "JUN": 5.52,
    "JUL": 5.61,
    "AUG": 6.02,
    "SEP": 5.67,
    "OCT": 4.94,
    "NOV": 4.52,
    "DEC": 4.37
  },
  {
    "LONG": -48.07,
    "LAT": -6.36,
    "CIDADE": "Ananás",
    "UF": "TOCANTINS",
    "anual": 4.99,
    "JAN": 4.35,
    "FEB": 4.6,
    "MAR": 4.62,
    "APR": 4.77,
    "MAY": 5.07,
    "JUN": 5.45,
    "JUL": 5.62,
    "AUG": 6.04,
    "SEP": 5.71,
    "OCT": 4.91,
    "NOV": 4.45,
    "DEC": 4.29
  },
  {
    "LONG": -47.86,
    "LAT": -6.39,
    "CIDADE": "Angico",
    "UF": "TOCANTINS",
    "anual": 5.06,
    "JAN": 4.43,
    "FEB": 4.71,
    "MAR": 4.74,
    "APR": 4.92,
    "MAY": 5.15,
    "JUN": 5.54,
    "JUL": 5.69,
    "AUG": 6.08,
    "SEP": 5.72,
    "OCT": 4.91,
    "NOV": 4.5,
    "DEC": 4.36
  },
  {
    "LONG": -47.66,
    "LAT": -6.38,
    "CIDADE": "Nazaré",
    "UF": "TOCANTINS",
    "anual": 5.12,
    "JAN": 4.47,
    "FEB": 4.75,
    "MAR": 4.79,
    "APR": 4.97,
    "MAY": 5.17,
    "JUN": 5.56,
    "JUL": 5.72,
    "AUG": 6.16,
    "SEP": 5.8,
    "OCT": 5,
    "NOV": 4.57,
    "DEC": 4.45
  },
  {
    "LONG": -47.67,
    "LAT": -6.44,
    "CIDADE": "Santa Terezinha do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.12,
    "JAN": 4.47,
    "FEB": 4.75,
    "MAR": 4.79,
    "APR": 4.97,
    "MAY": 5.17,
    "JUN": 5.56,
    "JUL": 5.72,
    "AUG": 6.16,
    "SEP": 5.8,
    "OCT": 5,
    "NOV": 4.57,
    "DEC": 4.45
  },
  {
    "LONG": -47.42,
    "LAT": -6.32,
    "CIDADE": "Tocantinópolis",
    "UF": "TOCANTINS",
    "anual": 5.18,
    "JAN": 4.58,
    "FEB": 4.78,
    "MAR": 4.89,
    "APR": 5.02,
    "MAY": 5.13,
    "JUN": 5.52,
    "JUL": 5.69,
    "AUG": 6.13,
    "SEP": 5.9,
    "OCT": 5.22,
    "NOV": 4.73,
    "DEC": 4.58
  },
  {
    "LONG": -47.86,
    "LAT": -6.19,
    "CIDADE": "Luzinópolis",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.43,
    "FEB": 4.68,
    "MAR": 4.74,
    "APR": 4.86,
    "MAY": 5.03,
    "JUN": 5.42,
    "JUL": 5.64,
    "AUG": 6.1,
    "SEP": 5.73,
    "OCT": 5.01,
    "NOV": 4.54,
    "DEC": 4.37
  },
  {
    "LONG": -47.92,
    "LAT": -0.61,
    "CIDADE": "Cachoeirinha",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.44,
    "FEB": 4.65,
    "MAR": 4.72,
    "APR": 4.84,
    "MAY": 5.03,
    "JUN": 5.4,
    "JUL": 5.57,
    "AUG": 6.09,
    "SEP": 5.7,
    "OCT": 5.01,
    "NOV": 4.62,
    "DEC": 4.45
  },
  {
    "LONG": -47.92,
    "LAT": -6.03,
    "CIDADE": "São Bento do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.4,
    "FEB": 4.64,
    "MAR": 4.73,
    "APR": 4.86,
    "MAY": 5.05,
    "JUN": 5.44,
    "JUL": 5.6,
    "AUG": 6.1,
    "SEP": 5.66,
    "OCT": 4.97,
    "NOV": 4.59,
    "DEC": 4.46
  },
  {
    "LONG": "#VALOR!",
    "LAT": -5.95,
    "CIDADE": "Maurilândia do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.14,
    "JAN": 4.52,
    "FEB": 4.75,
    "MAR": 4.81,
    "APR": 4.95,
    "MAY": 5.14,
    "JUN": 5.48,
    "JUL": 5.66,
    "AUG": 6.12,
    "SEP": 5.79,
    "OCT": 5.19,
    "NOV": 4.75,
    "DEC": 4.54
  },
  {
    "LONG": -47.48,
    "LAT": -5.78,
    "CIDADE": "Itaguatins",
    "UF": "TOCANTINS",
    "anual": 5.1,
    "JAN": 4.5,
    "FEB": 4.64,
    "MAR": 4.77,
    "APR": 4.92,
    "MAY": 5.08,
    "JUN": 5.42,
    "JUL": 5.6,
    "AUG": 6.02,
    "SEP": 5.64,
    "OCT": 5.18,
    "NOV": 4.78,
    "DEC": 4.59
  },
  {
    "LONG": -48.12,
    "LAT": -0.56,
    "CIDADE": "Araguatins",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.45,
    "FEB": 4.66,
    "MAR": 4.88,
    "APR": 4.99,
    "MAY": 5.03,
    "JUN": 5.38,
    "JUL": 5.47,
    "AUG": 5.92,
    "SEP": 5.51,
    "OCT": 4.97,
    "NOV": 4.7,
    "DEC": 4.51
  },
  {
    "LONG": -47.77,
    "LAT": -0.56,
    "CIDADE": "Axixá do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.04,
    "JAN": 4.41,
    "FEB": 4.63,
    "MAR": 4.78,
    "APR": 4.92,
    "MAY": 4.99,
    "JUN": 5.37,
    "JUL": 5.53,
    "AUG": 5.97,
    "SEP": 5.59,
    "OCT": 5.04,
    "NOV": 4.73,
    "DEC": 4.49
  },
  {
    "LONG": -47.64,
    "LAT": -5.6,
    "CIDADE": "Sítio Novo do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.43,
    "FEB": 4.64,
    "MAR": 4.8,
    "APR": 4.92,
    "MAY": 5.02,
    "JUN": 5.39,
    "JUL": 5.52,
    "AUG": 5.93,
    "SEP": 5.59,
    "OCT": 5.05,
    "NOV": 4.73,
    "DEC": 4.53
  },
  {
    "LONG": -47.58,
    "LAT": -5.55,
    "CIDADE": "São Miguel do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.06,
    "JAN": 4.46,
    "FEB": 4.66,
    "MAR": 4.83,
    "APR": 4.93,
    "MAY": 5.03,
    "JUN": 5.39,
    "JUL": 5.5,
    "AUG": 5.89,
    "SEP": 5.61,
    "OCT": 5.09,
    "NOV": 4.76,
    "DEC": 4.56
  },
  {
    "LONG": -47.89,
    "LAT": -0.55,
    "CIDADE": "Augustinópolis",
    "UF": "TOCANTINS",
    "anual": 5.06,
    "JAN": 4.46,
    "FEB": 4.72,
    "MAR": 4.85,
    "APR": 4.99,
    "MAY": 5.02,
    "JUN": 5.41,
    "JUL": 5.51,
    "AUG": 5.9,
    "SEP": 5.57,
    "OCT": 5.06,
    "NOV": 4.75,
    "DEC": 4.55
  },
  {
    "LONG": -48.54,
    "LAT": -5.37,
    "CIDADE": "Esperantina",
    "UF": "TOCANTINS",
    "anual": 5,
    "JAN": 4.4,
    "FEB": 4.66,
    "MAR": 4.79,
    "APR": 4.96,
    "MAY": 5.01,
    "JUN": 5.39,
    "JUL": 5.45,
    "AUG": 5.88,
    "SEP": 5.51,
    "OCT": 4.88,
    "NOV": 4.71,
    "DEC": 4.41
  },
  {
    "LONG": -47.81,
    "LAT": -5.39,
    "CIDADE": "Praia Norte",
    "UF": "TOCANTINS",
    "anual": 5.09,
    "JAN": 4.47,
    "FEB": 4.71,
    "MAR": 4.91,
    "APR": 5.05,
    "MAY": 5,
    "JUN": 5.4,
    "JUL": 5.51,
    "AUG": 5.89,
    "SEP": 5.62,
    "OCT": 5.15,
    "NOV": 4.78,
    "DEC": 4.58
  },
  {
    "LONG": -47.88,
    "LAT": -5.35,
    "CIDADE": "Sampaio",
    "UF": "TOCANTINS",
    "anual": 5.09,
    "JAN": 4.47,
    "FEB": 4.71,
    "MAR": 4.91,
    "APR": 5.05,
    "MAY": 5,
    "JUN": 5.4,
    "JUL": 5.51,
    "AUG": 5.89,
    "SEP": 5.62,
    "OCT": 5.15,
    "NOV": 4.78,
    "DEC": 4.58
  },
  {
    "LONG": -48.2,
    "LAT": -5.26,
    "CIDADE": "São Sebastião do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.03,
    "JAN": 4.41,
    "FEB": 4.64,
    "MAR": 4.82,
    "APR": 4.97,
    "MAY": 5,
    "JUN": 5.4,
    "JUL": 5.48,
    "AUG": 5.89,
    "SEP": 5.55,
    "OCT": 4.94,
    "NOV": 4.73,
    "DEC": 4.48
  },
  {
    "LONG": -48.23,
    "LAT": -5.31,
    "CIDADE": "Buriti do Tocantins",
    "UF": "TOCANTINS",
    "anual": 5.03,
    "JAN": 4.41,
    "FEB": 4.64,
    "MAR": 4.82,
    "APR": 4.97,
    "MAY": 5,
    "JUN": 5.4,
    "JUL": 5.48,
    "AUG": 5.89,
    "SEP": 5.55,
    "OCT": 4.94,
    "NOV": 4.73,
    "DEC": 4.48
  },
  {
    "LONG": -48.04,
    "LAT": -5.32,
    "CIDADE": "Carrasco Bonito",
    "UF": "TOCANTINS",
    "anual": 5.05,
    "JAN": 4.47,
    "FEB": 4.64,
    "MAR": 4.86,
    "APR": 5.03,
    "MAY": 5.04,
    "JUN": 5.39,
    "JUL": 5.49,
    "AUG": 5.87,
    "SEP": 5.58,
    "OCT": 5.03,
    "NOV": 4.75,
    "DEC": 4.51
  }
]
var yearsArray = [];
var makesArray = [];
var angulo = 0;
var p1 = 0;
var p2 =0;
var p3 =0
var menordelta =0;
var delta1 =0;
var delta2 = 0;
var detlta3 = 0;
var incline = 0.0;

var cidadesorder = new Array();
var radiacao = 0;
var $yearDropDown = $("#DropDown_Year");
var $makeDropDown = $("#DropDown_Make");
var  $radiacao = $("#radiacao_cidade");
var  $inc = $("#inclinacao");



	$makeDropDown.empty();

    var selectedyear = $yearDropDown.val();

    //filter based on  selected year.
    makesArray = jQuery.grep(ProductInfo, function (product, i) {
          return product.UF == selectedyear;
    });

    $makeDropDown.append('<option >Selecione</option>');
    for (var i = 0; i < makesArray.length; i++) {
        cidadesorder.push( makesArray[i].CIDADE );
				cidadesorder.sort()
    }

$.each(cidadesorder, function (i) {
    $makeDropDown.append('<option  value="' + cidadesorder[i] + '">' + cidadesorder[i] + '</option>');
});


;



$makeDropDown.change(function () {
    var selectedMake = this.value;
    //filter select based on selected Make
    selectedArray = jQuery.grep(ProductInfo, function (product, i) {
        return product.CIDADE == selectedMake;
    });
    for (var i = 0; i < selectedArray.length; i++) {
        $radiacao.val(parseFloat(selectedArray[i].anual));
        angulo = 3.7+ (0.69*Math.abs((parseFloat(selectedArray[i].LAT))))
        $inc.val(Math.round(angulo));
    }

});


var returnedData = jQuery.grep(ProductInfo, function (element, index) {
    return element.id == 1;
		});

//To update the table element with selected items
updateTable = function (collection) {
    $container.empty();
    for (var i = 0; i < collection.length; i++) {
        $container.append("<tr><td>" + collection[i].Year + "</td><td> " + collection[i].Make + "</td><td>" + collection[i].Model + "</td></tr>");
    }
}
return ($radiacao.val())
}


